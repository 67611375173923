import Shadow1 from "../../assets/img/flower/9C735D66.jpg";
import Shadow2 from "../../assets/img/flower/8E9B559D.png";
import Shadow3 from "../../assets/img/flower/8E9B558A.png";
import Shadow4 from "../../assets/img/flower/8E9B558B.png";
import Shadow5 from "../../assets/img/flower/8E9B558D.png";
import Shadow6 from "../../assets/img/flower/8E9B558F.png";
import Shadow7 from "../../assets/img/flower/8E9B559A.png";
import Shadow8 from "../../assets/img/flower/8E9B559B.png";
import Shadow9 from "../../assets/img/flower/8E9B5595.png";
import Shadow10 from "../../assets/img/flower/8E9B5597.png";
import { useEffect, useState } from "react";

export const Flower = () => {
  const [playAnimation, setPlayAnimation] = useState(false);

  // This will run one time after the component mounts
  useEffect(() => {
    const onPageLoad = () => {
      setTimeout(() => {
        setPlayAnimation(true);
      }, 3000); // Change the delay time to your desired value in milliseconds
    };
    // Check if the page has already loaded
    if (document.readyState === "complete") {
      onPageLoad();
    } else {
      window.addEventListener("load", onPageLoad);
      // Remove the event listener when component unmounts
      return () => window.removeEventListener("load", onPageLoad);
    }
  }, []);

  return (
    <>
      <div id="loader" className={playAnimation ? "Loaded" : "NotLoaded"}>
        {/*<!-- Generator: Adobe Illustrator 25.4.1, SVG Export Plug-In . SVG Version: 6.00 Build 0)  --> */}
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 595.3 595.3"
          enableBackground="new 0 0 595.3 595.3"
          xmlSpace="preserve"
        >
          <g id="Peddle16">
            <defs>
              <path
                id="SVGID_1_"
                d="M136.9,195.2c45.6,14.9,231,107.2,219.5,142.5C344.8,373.1,140.6,338,95,323.1
			C49.5,308.2-8.9,252.2,1.1,221.6C11.1,191,91.3,180.3,136.9,195.2z"
              />
            </defs>
            <use xlinkHref="#SVGID_1_" overflow="visible" fill="#003301" />
            <clipPath id="SVGID_00000076573836359548403660000013841212370985234568_">
              <use xlinkHref="#SVGID_1_" overflow="visible" />
            </clipPath>
            <g
              opacity="0.1"
              clipPath="url(#SVGID_00000076573836359548403660000013841212370985234568_)"
            >
              <g>
                <g>
                  <path
                    d="M312.1,219.6c8.1-1.2,15.4-3.8,20.8-7c5.4-3.2,8.8-6.9,8.1-10.4c-0.7-3.4-5.5-5.7-12.2-5.8c-6.7-0.1-15.4,2-22.9,6.8
					c-7.7,4.9-12.4,9-17.2,13c-4.7,4-9.4,8-16.3,13.7c8.1-3.8,13.9-5.6,19.8-6.9C297.9,221.7,303.8,220.8,312.1,219.6z"
                  />
                </g>
                <g>
                  <path
                    d="M314.5,239.9c7,1.8,13.9,2.1,19.5,1.1c5.5-1,9.7-3.2,10.5-6.6c0.8-3.4-2.4-7.3-8.1-9.9c-5.6-2.6-13.8-3.8-21.8-2.1
					c-8.2,1.8-13.5,3.9-18.8,6c-5.3,2.1-10.5,4.2-18,7.1c8-0.8,13.5-0.4,18.9,0.5C302,236.8,307.2,238.1,314.5,239.9z"
                  />
                </g>
                <g>
                  <path
                    d="M303.9,258.7c4.2,3.4,8.8,5.1,13,5.4c4.2,0.3,8.1-0.9,10-3.9c1.9-3,1.1-7.2-2.1-10.9c-3.1-3.7-8.6-6.8-14.9-7.1
					c-6.5-0.4-10.9,0.2-15.2,0.7c-4.3,0.5-8.6,1-14.6,1.5c5.8,1.7,9.5,3.7,12.9,5.9C296.4,252.6,299.5,255.2,303.9,258.7z"
                  />
                </g>
                <g>
                  <path
                    d="M282.6,276.1c1.5,4.7,4.5,8,8,9.7c3.4,1.7,7.3,1.9,10.2-0.1c2.9-2,4.1-5.7,3.3-9.8c-0.7-4-3.4-8.3-7.9-11
					c-4.6-2.8-8.1-4.2-11.5-5.8c-3.4-1.6-6.6-3.2-10.9-6.1c2.8,4.3,4.2,7.7,5.3,11.2C280.2,267.7,281.1,271.2,282.6,276.1z"
                  />
                </g>
                <g>
                  <path
                    d="M262,284.1c-1.5,5-1.3,9.6,0.1,13.5c1.3,3.8,3.8,7,7.3,7.6c3.4,0.7,7.1-1.5,9.3-5.8c2.2-4.2,3-10.5,0.8-16.4
					c-2.3-6-4.6-9.7-6.8-13.4c-2.3-3.6-4.5-7.1-7.6-12.2c1,5.8,0.8,9.9,0.1,13.8C264.5,275.2,263.5,279,262,284.1z"
                  />
                </g>
                <g>
                  <path
                    d="M238.4,276.8c-4.1,3.3-6.7,7-8.2,10.8c-0.3,1-0.7,1.9-0.9,2.9c-0.2,1-0.3,1.9-0.3,2.8c0,1.8,0.4,3.6,1.4,5
					c1,1.4,2.6,2.3,4.6,2.6c1,0.1,2.1,0.1,3.2-0.1c1.1-0.2,2.4-0.5,3.5-1.1c4.8-2.1,9.8-7.2,11.7-13.9c1.9-6.8,2-11.6,2-16.2
					c-0.1-4.6-0.3-9.1-0.8-15.3c-1.6,6-3.8,9.7-6.4,12.9C245.6,270.5,242.6,273.3,238.4,276.8z"
                  />
                </g>
                <g>
                  <path
                    d="M221.4,254.8c-5.9,0.7-10.8,2.7-14.6,5.4c-3.7,2.7-6.2,6.2-5.7,9.7c0.5,3.5,4.2,6.1,9.6,6.6c5.3,0.5,12.3-1.2,17.7-5.6
					c5.6-4.6,8.8-8.3,11.8-12c3-3.7,5.9-7.3,10.2-12.6c-5.6,3.6-10,5.2-14.3,6.3C231.8,253.5,227.4,254.1,221.4,254.8z"
                  />
                </g>
                <g>
                  <path
                    d="M214.7,232.3c-5.8-1.9-11.2-2.4-15.9-1.9c-1.2,0.2-2.3,0.3-3.3,0.7c-1.1,0.3-2,0.7-2.9,1.1c-1.7,0.9-3.1,2.2-3.7,3.8
					c-0.6,1.6-0.4,3.5,0.5,5.3c0.5,0.9,1.2,1.9,2,2.7c0.9,0.9,1.9,1.8,3.1,2.5c4.8,3.1,12.4,4.8,19.7,3.2c7.5-1.7,12.3-4,16.9-6.2
					c4.5-2.3,9-4.7,15-8.3c-6.9,1.6-11.7,1.4-16.4,0.8C225.1,235.5,220.6,234.3,214.7,232.3z"
                  />
                </g>
                <g>
                  <path
                    d="M221.6,214.1c-3.8-4.1-7-7.1-10.2-9.4c-1.6-1.2-3.2-2.2-4.8-3.1c-1.6-0.9-3.3-1.6-5.1-1.4c-1.7,0.1-3.3,1.2-4.4,3.3
					c-1.1,2.1-1.4,5.3-0.6,8.8c0.8,3.5,2.7,7.3,5.7,10.4c2.9,3.1,6.8,5.6,11,6.8c4.4,1.3,7.9,1.6,11.1,1.7c3.2,0.1,5.9-0.1,8.6-0.4
					c5.4-0.6,10.5-1.4,17.9-2.1c-7.3-1.5-12.2-3.2-16.6-5.4c-2.2-1.1-4.3-2.4-6.4-3.9C225.6,217.9,223.5,216.2,221.6,214.1z"
                  />
                </g>
                <g>
                  <path
                    d="M239.5,196.3c-0.1-5.5-0.9-9.8-2.3-13.6c-0.7-1.9-1.5-3.7-2.5-5.3c-1-1.6-2.2-2.9-3.9-3.6c-1.6-0.7-3.5-0.5-5.4,0.6
					c-1.9,1.1-3.7,3.3-4.9,6.3c-1.2,3-1.8,6.7-1.4,10.5c0.4,3.8,1.7,7.8,3.9,11.1c2.3,3.5,4.6,5.9,6.7,7.9c2.2,2,4.2,3.6,6.3,5.1
					c4.1,3,8.3,5.7,14,9.6c-4.3-5.5-6.5-9.9-8-14.2c-0.7-2.2-1.3-4.4-1.8-6.8C239.9,201.7,239.6,199.1,239.5,196.3z"
                  />
                </g>
                <g>
                  <path
                    d="M259.2,191.2c2.3-5.4,3.1-10.6,2.9-15.2c-0.1-1.2-0.2-2.2-0.4-3.3c-0.2-1.1-0.5-2-0.9-2.9c-0.8-1.8-1.9-3.2-3.5-4
					c-1.6-0.8-3.4-0.8-5.3,0c-1,0.4-2,1-2.9,1.8c-0.9,0.8-1.9,1.7-2.8,2.9c-3.5,4.5-5.8,11.9-4.6,19.3c1.3,7.6,3.3,12.4,5.3,17
					c2.1,4.6,4.3,9,7.7,15c-1.4-6.8-1-11.6-0.1-16C255.5,201.1,256.9,196.9,259.2,191.2z"
                  />
                </g>
                <g>
                  <path
                    d="M281.1,191.4c4.3-4.1,7.2-8,9.3-11.7c1-1.9,1.9-3.7,2.4-5.6c0.5-1.8,0.7-3.7,0-5.3c-0.6-1.6-2.1-2.9-4.3-3.4
					c-2.2-0.6-5.1-0.3-8.2,0.8c-3.1,1.2-6.3,3.3-8.9,6.2c-2.6,2.9-4.7,6.5-5.8,10.5c-1.2,4-1.6,7.4-1.8,10.4c-0.2,3-0.2,5.7-0.2,8.3
					c0.1,5.2,0.3,10.3,0,17.6c2.4-6.9,4.7-11.5,7.3-15.6c1.3-2.1,2.8-4,4.4-6C277.1,195.6,278.9,193.5,281.1,191.4z"
                  />
                </g>
                <g>
                  <path
                    d="M256.7,233.3c-1.5,3.2,1.5,6,5,5.6c3.5-0.4,5.8-3.7,3.7-6.5c-2.2-2.9-3.7-3.9-5.1-7C259.6,228.7,258.2,230,256.7,233.3z
					"
                  />
                </g>
                <g>
                  <path
                    d="M261.4,230.6c-1.4,1-1.8,2.3-1.5,3.7c0.3,1.4,1.3,2.8,2.8,3.6c1.5,0.9,3.3,0.9,4.7,0.2c1.4-0.7,2.3-2.2,2.1-4.1
					c-0.2-2-0.6-3.3-0.9-4.7c-0.3-1.3-0.6-2.6-0.7-4.4c-0.9,1.5-1.9,2.4-2.9,3.1C263.9,228.9,262.8,229.6,261.4,230.6z"
                  />
                </g>
                <g>
                  <path
                    d="M264.9,232.2c-1.3-0.6-2.1-0.2-2.9,0.7c-0.7,0.9-1.4,2.4-1.5,4.2c-0.1,1.7,0.7,3.3,2.1,4.1c1.4,0.8,3.3,0.7,4.8-0.7
					c1.5-1.4,2.2-2.7,2.8-3.8c0.6-1.2,1.2-2.3,2.1-3.6c-1.5,0.5-2.7,0.5-3.8,0.3C267.3,233.2,266.2,232.7,264.9,232.2z"
                  />
                </g>
                <g>
                  <path
                    d="M267.4,236.8c0-0.7-0.3-1.3-0.7-1.8c-0.5-0.5-1.1-1-1.9-1.2c-0.8-0.3-1.6-0.3-2.4-0.1c-0.8,0.2-1.5,0.6-2.1,1.3
					c-0.6,0.6-1.1,1.3-1.4,2c-0.3,0.7-0.6,1.4-0.6,2c0,0.7,0.1,1.3,0.5,2c0.4,0.6,1.1,1.3,2.1,1.7c1,0.5,2.1,0.8,3,1
					c0.9,0.2,1.7,0.2,2.5,0.1c1.6-0.1,3.1-0.5,4.5-1.6c-0.8,0.1-1.5-0.2-2-0.6c-0.5-0.4-0.8-0.8-1-1.3c-0.2-0.5-0.3-1-0.4-1.6
					C267.4,238,267.5,237.5,267.4,236.8z"
                  />
                </g>
                <g>
                  <path
                    d="M266.9,240c0.8-1.4,0.7-2.5-0.1-3.6c-0.7-1.1-2-2.2-3.8-2.6c-1.7-0.4-3.4,0.1-4.5,1.4c-1.1,1.3-1.4,3.5-0.4,5.4
					c1.1,2,2.1,3.1,3.2,4.1c1,1,2,2,3.2,3.4c-0.2-1.8,0-3.1,0.5-4.3C265.4,242.6,266.1,241.5,266.9,240z"
                  />
                </g>
                <g>
                  <path
                    d="M264.2,244.1c1.7-1.3,2.4-3.2,2.2-4.9c-0.2-1.7-1.4-3.1-3.1-3.7c-1.7-0.6-3.4-0.3-4.7,0.5c-1.3,0.8-2.1,2-2.1,3.8
					c0,1.9,0,3.3,0,4.7c-0.1,1.4-0.4,2.8-1.2,4.5c1.6-1,2.9-1.7,4.3-2.3C260.9,246.1,262.4,245.4,264.2,244.1z"
                  />
                </g>
                <g>
                  <path
                    d="M258.2,243.2c3.6,1.4,6.6-1.7,6.2-5.2c-0.4-3.5-4.2-5.7-7.3-3.5c-3.2,2.3-4.3,3.9-7.7,5.4
					C253,240.5,254.5,241.8,258.2,243.2z"
                  />
                </g>
                <g>
                  <path
                    d="M254.5,239.4c1.8,3.2,5.8,2.6,7.7-0.4c1.8-3,0.5-6.9-3.1-7c-3.7-0.1-5.5,0.5-8.9-0.3
					C252.5,234.3,252.7,236.1,254.5,239.4z"
                  />
                </g>
                <g>
                  <path
                    d="M255,235.1c0,1.7,1.1,2.7,2.4,3c1.3,0.3,3-0.2,4.3-1.3c1.4-1.1,2.1-2.6,2.3-3.8c0.1-1.2-0.4-2.3-1.8-2.8
					c-2.9-1-4.7-1.1-6.9-3C255.8,230.1,255,231.7,255,235.1z"
                  />
                </g>
                <g>
                  <path
                    d="M299.7,201.9c5.7-1.8,10.2-4.8,13.4-8.2c3.2-3.4,5-7.2,3.9-10.6c-1.1-3.3-5.3-5.2-10.8-4.6c-5.4,0.5-12,3.6-16.6,9.1
					c-4.7,5.7-7.1,10.1-9.4,14.4c-2.2,4.4-4.4,8.6-7.4,14.7c4.8-4.8,8.8-7.3,12.8-9.3C289.6,205.4,293.8,203.9,299.7,201.9z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M624.2,225.2c3.8,4.2,8.2,7.3,12.1,9.2c3.9,1.8,7.4,2.3,9.1,0.6c1.7-1.7,1.1-5.3-1.4-9.3c-2.6-3.9-7.1-8.1-12.8-10.5
					c-5.8-2.5-10-3.6-14.1-4.8c-4.1-1.1-8.3-2.3-14.2-4.1c5.3,3.1,8.6,5.8,11.6,8.7C617.5,217.9,620.3,220.9,624.2,225.2z"
                  />
                </g>
                <g>
                  <path
                    d="M613.5,234.5c1.7,4.7,4.2,8.8,6.9,11.6c2.7,2.8,5.6,4.4,7.9,3.4c2.2-0.9,3.3-4.2,2.6-8.5c-0.7-4.2-3.2-9.4-7.3-13.3
					c-4.2-4-7.5-6.2-10.7-8.5c-3.3-2.2-6.5-4.4-11.1-7.6c3.6,4.3,5.5,7.6,7.1,11C610.4,226.1,611.7,229.6,613.5,234.5z"
                  />
                </g>
                <g>
                  <path
                    d="M598.5,235.7c-0.3,3.7,0.5,7,2,9.6c1.5,2.5,3.7,4.3,6.1,4.2c2.4-0.1,4.5-2.2,5.4-5.4c0.9-3.2,0.5-7.6-1.7-11.3
					c-2.3-3.8-4.3-6.2-6.3-8.5c-2-2.3-3.9-4.5-6.5-7.8c1.3,4,1.6,6.8,1.6,9.7C599.1,229,598.9,231.8,598.5,235.7z"
                  />
                </g>
                <g>
                  <path
                    d="M580.4,230.2c-2.1,2.7-2.8,5.7-2.5,8.4c0.3,2.6,1.7,4.9,4,5.8c2.3,0.9,4.9,0.1,6.9-1.9c2-2,3.4-5.2,3.2-8.8
					c-0.2-3.7-0.7-6.3-1.1-8.9c-0.4-2.5-0.7-5.1-0.8-8.6c-1.4,3.3-2.8,5.4-4.3,7.4C584.2,225.6,582.5,227.5,580.4,230.2z"
                  />
                </g>
                <g>
                  <path
                    d="M567.7,221.5c-3.4,1.1-6,3-7.7,5.3c-1.7,2.3-2.5,4.9-1.5,7.2c0.9,2.2,3.6,3.5,7,3.1c3.3-0.4,7.2-2.4,9.7-5.9
					c2.6-3.6,3.8-6.4,5-9.1c1.2-2.7,2.3-5.4,4-9.1c-2.9,2.8-5.4,4.3-7.8,5.4C573.9,219.5,571.3,220.4,567.7,221.5z"
                  />
                </g>
                <g>
                  <path
                    d="M562.8,205.2c-3.5-1.1-6.6-1.1-9.4-0.5c-0.7,0.2-1.4,0.3-2,0.6c-0.6,0.3-1.2,0.6-1.7,0.9c-1.1,0.7-1.9,1.6-2.3,2.8
					c-0.4,1.1-0.3,2.4,0.3,3.6c0.3,0.6,0.7,1.2,1.3,1.8c0.6,0.6,1.2,1.1,2,1.6c3.1,1.9,8,2.8,12.5,1.3c4.6-1.6,7.4-3.3,10.1-5.1
					c2.6-1.8,5.1-3.7,8.4-6.4c-4.1,1.4-7.1,1.6-9.9,1.4C569.2,206.9,566.4,206.2,562.8,205.2z"
                  />
                </g>
                <g>
                  <path
                    d="M568.7,186.9c-2.6-3.1-5.7-5.2-8.7-6.3c-3-1.1-5.9-1.1-7.7,0.5c-1.8,1.6-1.9,4.8-0.1,8.1c1.8,3.3,5.4,6.6,10.1,8
					c4.8,1.4,8.2,1.8,11.5,2.1c3.3,0.3,6.5,0.5,11.2,0.9c-4.3-1.8-6.8-3.7-9.1-5.8C573.6,192.4,571.5,190.1,568.7,186.9z"
                  />
                </g>
                <g>
                  <path
                    d="M579.1,174.3c-1.1-4.1-3-7.4-5.1-9.9c-0.6-0.6-1.1-1.2-1.7-1.7c-0.6-0.5-1.2-0.9-1.8-1.2c-1.2-0.6-2.4-0.9-3.6-0.7
					c-1.2,0.3-2.2,1.1-2.8,2.4c-0.3,0.6-0.6,1.4-0.8,2.2c-0.2,0.8-0.3,1.8-0.2,2.8c0.1,4,2,9,5.8,12.6c3.9,3.7,7,5.5,10.1,7.2
					c3.1,1.7,6.2,3.3,10.6,5.4c-3.6-3.3-5.4-6.2-6.8-9.1C581.3,181.5,580.3,178.5,579.1,174.3z"
                  />
                </g>
                <g>
                  <path
                    d="M592.2,171.1c0.9-3.8,1.3-6.8,1.4-9.5c0.1-1.3,0-2.7-0.1-4c-0.1-1.3-0.4-2.5-1.2-3.5c-0.8-0.9-2-1.4-3.6-1.2
					c-1.6,0.2-3.6,1.2-5.3,3c-1.7,1.8-3.1,4.4-3.7,7.3c-0.6,2.9-0.6,6,0.3,8.9c0.9,3,2.1,5.2,3.3,7c1.2,1.8,2.4,3.3,3.6,4.8
					c2.4,2.9,4.9,5.5,8.1,9.5c-2-4.8-2.9-8.3-3.3-11.7c-0.2-1.7-0.3-3.4-0.3-5.2C591.6,175,591.7,173.1,592.2,171.1z"
                  />
                </g>
                <g>
                  <path
                    d="M609.4,174.5c3.1-2.2,5.3-4.3,6.9-6.6c0.8-1.1,1.5-2.3,2.1-3.5c0.5-1.2,0.8-2.4,0.6-3.6c-0.2-1.2-1.1-2.2-2.5-2.8
					c-1.4-0.6-3.3-0.8-5.5-0.4c-2.2,0.4-4.5,1.5-6.6,3.3c-2,1.7-3.8,4-4.8,6.5c-1.1,2.7-1.6,4.9-1.9,6.9c-0.3,2-0.4,3.8-0.5,5.6
					c-0.1,3.5,0,6.9-0.1,11.8c1.5-4.6,3.1-7.6,5.1-10.1c1-1.3,2-2.5,3.2-3.6C606.4,176.8,607.8,175.6,609.4,174.5z"
                  />
                </g>
                <g>
                  <path
                    d="M619.9,183.9c4-0.8,7.3-2.3,9.9-4.3c0.6-0.5,1.2-1,1.7-1.5c0.5-0.5,1-1.1,1.3-1.6c0.7-1.1,1.1-2.3,1-3.6
					c-0.2-1.2-0.9-2.3-2.1-3.1c-0.6-0.4-1.3-0.8-2.1-1c-0.8-0.2-1.7-0.4-2.7-0.5c-3.9-0.3-9.1,1.3-12.8,4.9
					c-3.8,3.7-5.8,6.7-7.7,9.6c-1.8,3-3.5,6-5.6,10.2c3.4-3.4,6.2-5.1,9.1-6.3C612.8,185.6,615.8,184.7,619.9,183.9z"
                  />
                </g>
                <g>
                  <path
                    d="M628.3,196.5c4,0.9,7.4,1,10.3,0.8c1.5-0.1,2.9-0.4,4.1-0.8c1.2-0.4,2.4-1,3.1-2c0.7-1,0.8-2.3,0.3-3.8
					c-0.5-1.5-1.8-3-3.7-4.4c-1.9-1.3-4.3-2.3-7-2.7c-2.7-0.4-5.6-0.2-8.3,0.7c-2.8,0.9-4.9,2-6.7,3c-1.8,1-3.3,2.1-4.8,3.1
					c-3,2.1-5.8,4.2-10.1,6.8c4.9-1.3,8.4-1.8,11.8-1.8c1.7,0,3.4,0,5.2,0.2C624.3,195.8,626.3,196,628.3,196.5z"
                  />
                </g>
                <g>
                  <path
                    d="M594.8,198.8c-2.4,0.4-2.8,3.2-1.3,5c1.6,1.9,4.4,1.9,5.2-0.4c0.8-2.4,0.8-3.7,2-5.7
					C598.6,198.6,597.3,198.3,594.8,198.8z"
                  />
                </g>
                <g>
                  <path
                    d="M598.2,200.4c-1.1-0.4-2-0.1-2.7,0.6c-0.7,0.7-1.1,1.8-1,3c0.1,1.2,0.8,2.2,1.7,2.7c0.9,0.5,2.2,0.5,3.2-0.4
					c1-0.9,1.7-1.6,2.3-2.3c0.6-0.7,1.3-1.4,2.2-2.1c-1.2,0-2.1-0.2-2.9-0.4C600.2,201.2,599.3,200.8,598.2,200.4z"
                  />
                </g>
                <g>
                  <path
                    d="M598.6,203c-0.2-0.9-0.7-1.3-1.5-1.4c-0.8-0.1-1.9,0.1-3,0.8c-1,0.6-1.7,1.7-1.6,2.8c0.1,1.1,0.9,2.2,2.2,2.5
					c1.4,0.3,2.4,0.2,3.3,0.1c0.9-0.1,1.8-0.2,2.9-0.2c-0.9-0.7-1.3-1.4-1.6-2.1C599,204.8,598.8,204,598.6,203z"
                  />
                </g>
                <g>
                  <path
                    d="M597,206.3c0.4-0.3,0.6-0.7,0.8-1.1c0.1-0.5,0.1-1,0-1.6c-0.1-0.5-0.4-1.1-0.9-1.4c-0.4-0.4-1-0.6-1.6-0.7
					c-0.6-0.1-1.2-0.1-1.7,0c-0.5,0.1-1,0.2-1.4,0.4c-0.4,0.2-0.7,0.6-0.9,1c-0.2,0.5-0.3,1.1-0.2,1.9c0.1,0.8,0.3,1.5,0.6,2.1
					c0.3,0.6,0.6,1.1,0.9,1.5c0.7,0.8,1.5,1.6,2.7,2c-0.4-0.5-0.5-1-0.4-1.4c0-0.4,0.2-0.8,0.4-1.1c0.2-0.3,0.5-0.6,0.7-0.9
					C596.3,206.7,596.6,206.6,597,206.3z"
                  />
                </g>
                <g>
                  <path
                    d="M594.9,207.2c1.1-0.1,1.7-0.6,2.1-1.5c0.3-0.8,0.5-2,0-3.2c-0.4-1.1-1.4-1.9-2.6-2c-1.2-0.1-2.5,0.5-3.2,1.9
					c-0.7,1.4-0.9,2.4-1.1,3.4c-0.2,1-0.4,1.9-0.7,3.2c0.9-0.8,1.8-1.2,2.7-1.4C592.9,207.4,593.8,207.3,594.9,207.2z"
                  />
                </g>
                <g>
                  <path
                    d="M591.5,207.3c1.4,0.5,2.8,0.1,3.7-0.7c0.9-0.8,1.2-2,0.9-3.2c-0.3-1.2-1.2-2.1-2.1-2.5c-0.9-0.4-2-0.4-3,0.3
					c-1.1,0.7-1.9,1.3-2.7,1.8c-0.8,0.5-1.7,0.9-3.1,1.1c1.2,0.5,2.1,1,3,1.6C589.1,206.1,590.1,206.7,591.5,207.3z"
                  />
                </g>
                <g>
                  <path
                    d="M589.7,203.4c0.6,2.6,3.6,3.1,5.4,1.5c1.8-1.6,1.7-4.6-0.8-5.5c-2.6-1-3.9-1-6.1-2.3
					C589.3,199.5,589.1,200.8,589.7,203.4z"
                  />
                </g>
                <g>
                  <path
                    d="M590.5,199.9c-1.1,2.2,0.8,4.3,3.2,4.2c2.4-0.1,4.2-2.4,2.8-4.5c-1.4-2.2-2.4-3-3.3-5.2
					C592.6,196.7,591.6,197.6,590.5,199.9z"
                  />
                </g>
                <g>
                  <path
                    d="M593.1,198.5c-0.9,0.7-1.1,1.7-0.8,2.5c0.4,0.9,1.3,1.6,2.4,2c1.2,0.3,2.3,0.2,3.1-0.2c0.8-0.4,1.2-1.1,0.9-2.1
					c-0.5-2-1.2-3.1-0.9-5.1C596.3,197,595.1,197.2,593.1,198.5z"
                  />
                </g>
                <g>
                  <path
                    d="M629.5,211.2c3.3,2.5,6.7,4,9.9,4.5c3.2,0.5,6.1,0.1,7.6-1.9c1.5-1.9,0.9-5.1-1.5-8c-2.4-2.9-6.8-5.5-11.7-6
					c-5.1-0.5-8.5-0.1-11.9,0.2c-3.4,0.4-6.7,0.8-11.3,1.5c4.6,0.9,7.6,2.2,10.3,3.7C623.6,206.8,626.1,208.6,629.5,211.2z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M241.1,523.3c3.8,4.2,8.2,7.3,12.1,9.2c3.9,1.8,7.4,2.3,9.1,0.6c1.7-1.7,1.1-5.3-1.4-9.3c-2.6-3.9-7.1-8.1-12.8-10.5
					c-5.8-2.5-10-3.6-14.1-4.8c-4.1-1.1-8.3-2.3-14.2-4.1c5.3,3.1,8.6,5.8,11.6,8.7C234.3,516,237.1,519,241.1,523.3z"
                  />
                </g>
                <g>
                  <path
                    d="M230.3,532.5c1.7,4.7,4.2,8.8,6.9,11.6c2.7,2.8,5.6,4.4,7.9,3.4c2.2-0.9,3.3-4.2,2.6-8.5c-0.7-4.2-3.2-9.4-7.3-13.3
					c-4.2-4-7.5-6.2-10.7-8.5c-3.3-2.2-6.5-4.4-11.1-7.6c3.6,4.3,5.5,7.6,7.1,11C227.3,524.1,228.6,527.6,230.3,532.5z"
                  />
                </g>
                <g>
                  <path
                    d="M215.4,533.7c-0.3,3.7,0.5,7,2,9.6c1.5,2.5,3.7,4.3,6.1,4.2c2.4-0.1,4.5-2.2,5.4-5.4c0.9-3.2,0.5-7.6-1.7-11.3
					c-2.3-3.8-4.3-6.2-6.3-8.5c-2-2.3-3.9-4.5-6.5-7.8c1.3,4,1.6,6.8,1.6,9.7C216,527.1,215.7,529.9,215.4,533.7z"
                  />
                </g>
                <g>
                  <path
                    d="M197.2,528.3c-2.1,2.7-2.8,5.7-2.5,8.4c0.3,2.6,1.7,4.9,4,5.8c2.3,0.9,4.9,0.1,6.9-1.9c2-2,3.4-5.2,3.2-8.8
					c-0.2-3.7-0.7-6.3-1.1-8.9c-0.4-2.5-0.7-5.1-0.8-8.6c-1.4,3.3-2.8,5.4-4.3,7.4C201.1,523.7,199.4,525.5,197.2,528.3z"
                  />
                </g>
                <g>
                  <path
                    d="M184.6,519.6c-3.4,1.1-6,3-7.7,5.3c-1.7,2.3-2.5,4.9-1.5,7.2c0.9,2.2,3.6,3.5,7,3.1c3.3-0.4,7.2-2.4,9.7-5.9
					c2.6-3.6,3.8-6.4,5-9.1c1.2-2.7,2.3-5.4,4-9.1c-2.9,2.8-5.4,4.3-7.8,5.4C190.7,517.6,188.1,518.4,184.6,519.6z"
                  />
                </g>
                <g>
                  <path
                    d="M179.7,503.2c-3.5-1.1-6.6-1.1-9.4-0.5c-0.7,0.2-1.4,0.3-2,0.6c-0.6,0.3-1.2,0.6-1.7,0.9c-1.1,0.7-1.9,1.6-2.3,2.8
					c-0.4,1.1-0.3,2.4,0.3,3.6c0.3,0.6,0.7,1.2,1.3,1.8c0.6,0.6,1.2,1.1,2,1.6c3.1,1.9,8,2.8,12.5,1.3c4.6-1.6,7.4-3.3,10.1-5.1
					c2.6-1.8,5.1-3.7,8.4-6.4c-4.1,1.4-7.1,1.6-9.9,1.4C186,504.9,183.3,504.3,179.7,503.2z"
                  />
                </g>
                <g>
                  <path
                    d="M185.6,484.9c-2.6-3.1-5.7-5.2-8.7-6.3c-3-1.1-5.9-1.1-7.7,0.5c-1.8,1.6-1.9,4.8-0.1,8.1c1.8,3.3,5.4,6.6,10.1,8
					c4.8,1.4,8.2,1.8,11.5,2.1c3.3,0.3,6.5,0.5,11.2,0.9c-4.3-1.8-6.8-3.7-9.1-5.8C190.4,490.4,188.4,488.1,185.6,484.9z"
                  />
                </g>
                <g>
                  <path
                    d="M195.9,472.3c-1.1-4.1-3-7.4-5.1-9.9c-0.6-0.6-1.1-1.2-1.7-1.7c-0.6-0.5-1.2-0.9-1.8-1.2c-1.2-0.6-2.4-0.9-3.6-0.7
					c-1.2,0.3-2.2,1.1-2.8,2.4c-0.3,0.6-0.6,1.4-0.8,2.2c-0.2,0.8-0.3,1.8-0.2,2.8c0.1,4,2,9,5.8,12.6c3.9,3.7,7,5.5,10.1,7.2
					c3.1,1.7,6.2,3.3,10.6,5.4c-3.6-3.3-5.4-6.2-6.8-9.1C198.2,479.5,197.1,476.5,195.9,472.3z"
                  />
                </g>
                <g>
                  <path
                    d="M209.1,469.2c0.9-3.8,1.3-6.8,1.4-9.5c0.1-1.3,0-2.7-0.1-4c-0.1-1.3-0.4-2.5-1.2-3.5c-0.8-0.9-2-1.4-3.6-1.2
					c-1.6,0.2-3.6,1.2-5.3,3c-1.7,1.8-3.1,4.4-3.7,7.3c-0.6,2.9-0.6,6,0.3,8.9c0.9,3,2.1,5.2,3.3,7c1.2,1.8,2.4,3.3,3.6,4.8
					c2.4,2.9,4.9,5.5,8.1,9.5c-2-4.8-2.9-8.3-3.3-11.7c-0.2-1.7-0.3-3.4-0.3-5.2C208.4,473,208.6,471.1,209.1,469.2z"
                  />
                </g>
                <g>
                  <path
                    d="M226.2,472.6c3.1-2.2,5.3-4.3,6.9-6.6c0.8-1.1,1.5-2.3,2.1-3.5c0.5-1.2,0.8-2.4,0.6-3.6c-0.2-1.2-1.1-2.2-2.5-2.8
					c-1.4-0.6-3.3-0.8-5.5-0.4c-2.2,0.4-4.5,1.5-6.6,3.3c-2,1.7-3.8,4-4.8,6.5c-1.1,2.7-1.6,4.9-1.9,6.9c-0.3,2-0.4,3.8-0.5,5.6
					c-0.1,3.5,0,6.9-0.1,11.8c1.5-4.6,3.1-7.6,5.1-10.1c1-1.3,2-2.5,3.2-3.6C223.3,474.9,224.6,473.7,226.2,472.6z"
                  />
                </g>
                <g>
                  <path
                    d="M236.8,481.9c4-0.8,7.3-2.3,9.9-4.3c0.6-0.5,1.2-1,1.7-1.5c0.5-0.5,1-1.1,1.3-1.6c0.7-1.1,1.1-2.3,1-3.6
					c-0.2-1.2-0.9-2.3-2.1-3.1c-0.6-0.4-1.3-0.8-2.1-1c-0.8-0.2-1.7-0.4-2.7-0.5c-3.9-0.3-9.1,1.3-12.8,4.9
					c-3.8,3.7-5.8,6.7-7.7,9.6c-1.8,3-3.5,6-5.6,10.2c3.4-3.4,6.2-5.1,9.1-6.3C229.7,483.6,232.7,482.8,236.8,481.9z"
                  />
                </g>
                <g>
                  <path
                    d="M245.2,494.6c4,0.9,7.4,1,10.3,0.8c1.5-0.1,2.9-0.4,4.1-0.8c1.2-0.4,2.4-1,3.1-2c0.7-1,0.8-2.3,0.3-3.8
					c-0.5-1.5-1.8-3-3.7-4.4c-1.9-1.3-4.3-2.3-7-2.7c-2.7-0.4-5.6-0.2-8.3,0.7c-2.8,0.9-4.9,2-6.7,3c-1.8,1-3.3,2.1-4.8,3.1
					c-3,2.1-5.8,4.2-10.1,6.8c4.9-1.3,8.4-1.8,11.8-1.8c1.7,0,3.4,0,5.2,0.2C241.2,493.9,243.1,494.1,245.2,494.6z"
                  />
                </g>
                <g>
                  <path
                    d="M211.7,496.8c-2.4,0.4-2.8,3.2-1.3,5c1.6,1.9,4.4,1.9,5.2-0.4c0.8-2.4,0.8-3.7,2-5.7
					C215.4,496.7,214.2,496.4,211.7,496.8z"
                  />
                </g>
                <g>
                  <path
                    d="M215,498.4c-1.1-0.4-2-0.1-2.7,0.6c-0.7,0.7-1.1,1.8-1,3c0.1,1.2,0.8,2.2,1.7,2.7c0.9,0.5,2.2,0.5,3.2-0.4
					c1-0.9,1.7-1.6,2.3-2.3c0.6-0.7,1.3-1.4,2.2-2.1c-1.2,0-2.1-0.2-2.9-0.4C217,499.2,216.2,498.9,215,498.4z"
                  />
                </g>
                <g>
                  <path
                    d="M215.5,501.1c-0.2-0.9-0.7-1.3-1.5-1.4c-0.8-0.1-1.9,0.1-3,0.8c-1,0.6-1.7,1.7-1.6,2.8c0.1,1.1,0.9,2.2,2.2,2.5
					c1.4,0.3,2.4,0.2,3.3,0.1c0.9-0.1,1.8-0.2,2.9-0.2c-0.9-0.7-1.3-1.4-1.6-2.1C215.9,502.9,215.7,502.1,215.5,501.1z"
                  />
                </g>
                <g>
                  <path
                    d="M213.9,504.3c0.4-0.3,0.6-0.7,0.8-1.1c0.1-0.5,0.1-1,0-1.6c-0.1-0.5-0.4-1.1-0.9-1.4c-0.4-0.4-1-0.6-1.6-0.7
					c-0.6-0.1-1.2-0.1-1.7,0c-0.5,0.1-1,0.2-1.4,0.4c-0.4,0.2-0.7,0.6-0.9,1c-0.2,0.5-0.3,1.1-0.2,1.9c0.1,0.8,0.3,1.5,0.6,2.1
					c0.3,0.6,0.6,1.1,0.9,1.5c0.7,0.8,1.5,1.6,2.7,2c-0.4-0.5-0.5-1-0.4-1.4c0-0.4,0.2-0.8,0.4-1.1c0.2-0.3,0.5-0.6,0.7-0.9
					C213.1,504.8,213.5,504.6,213.9,504.3z"
                  />
                </g>
                <g>
                  <path
                    d="M211.8,505.3c1.1-0.1,1.7-0.6,2.1-1.5c0.3-0.8,0.5-2,0-3.2c-0.4-1.1-1.4-1.9-2.6-2c-1.2-0.1-2.5,0.5-3.2,1.9
					c-0.7,1.4-0.9,2.4-1.1,3.4c-0.2,1-0.4,1.9-0.7,3.2c0.9-0.8,1.8-1.2,2.7-1.4C209.7,505.4,210.6,505.4,211.8,505.3z"
                  />
                </g>
                <g>
                  <path
                    d="M208.4,505.3c1.4,0.5,2.8,0.1,3.7-0.7c0.9-0.8,1.2-2,0.9-3.2c-0.3-1.2-1.2-2.1-2.1-2.5c-0.9-0.4-2-0.4-3,0.3
					c-1.1,0.7-1.9,1.3-2.7,1.8c-0.8,0.5-1.7,0.9-3.1,1.1c1.2,0.5,2.1,1,3,1.6C206,504.2,206.9,504.8,208.4,505.3z"
                  />
                </g>
                <g>
                  <path
                    d="M206.6,501.5c0.6,2.6,3.6,3.1,5.4,1.5c1.8-1.6,1.7-4.6-0.8-5.5c-2.6-1-3.9-1-6.1-2.3
					C206.1,497.5,205.9,498.8,206.6,501.5z"
                  />
                </g>
                <g>
                  <path
                    d="M207.3,497.9c-1.1,2.2,0.8,4.3,3.2,4.2c2.4-0.1,4.2-2.4,2.8-4.5c-1.4-2.2-2.4-3-3.3-5.2
					C209.5,494.8,208.5,495.6,207.3,497.9z"
                  />
                </g>
                <g>
                  <path
                    d="M210,496.6c-0.9,0.7-1.1,1.7-0.8,2.5c0.4,0.9,1.3,1.6,2.4,2c1.2,0.3,2.3,0.2,3.1-0.2c0.8-0.4,1.2-1.1,0.9-2.1
					c-0.5-2-1.2-3.1-0.9-5.1C213.2,495,211.9,495.2,210,496.6z"
                  />
                </g>
                <g>
                  <path
                    d="M246.4,509.3c3.3,2.5,6.7,4,9.9,4.5c3.2,0.5,6.1,0.1,7.6-1.9c1.5-1.9,0.9-5.1-1.5-8c-2.4-2.9-6.8-5.5-11.7-6
					c-5.1-0.5-8.5-0.1-11.9,0.2c-3.4,0.4-6.7,0.8-11.3,1.5c4.6,0.9,7.6,2.2,10.3,3.7C240.4,504.9,243,506.7,246.4,509.3z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M155.7,208.9c3.8,4.2,8.2,7.3,12.1,9.2c3.9,1.8,7.4,2.3,9.1,0.6c1.7-1.7,1.1-5.3-1.4-9.3c-2.6-3.9-7.1-8.1-12.8-10.5
					c-5.8-2.5-10-3.6-14.1-4.8c-4.1-1.1-8.3-2.3-14.2-4.1c5.3,3.1,8.6,5.8,11.6,8.7C148.9,201.6,151.7,204.6,155.7,208.9z"
                  />
                </g>
                <g>
                  <path
                    d="M144.9,218.2c1.7,4.7,4.2,8.8,6.9,11.6c2.7,2.8,5.6,4.4,7.9,3.4c2.2-0.9,3.3-4.2,2.6-8.5c-0.7-4.2-3.2-9.4-7.3-13.3
					c-4.2-4-7.5-6.2-10.7-8.5c-3.3-2.2-6.5-4.4-11.1-7.6c3.6,4.3,5.5,7.6,7.1,11C141.9,209.8,143.2,213.3,144.9,218.2z"
                  />
                </g>
                <g>
                  <path
                    d="M130,219.4c-0.3,3.7,0.5,7,2,9.6c1.5,2.5,3.7,4.3,6.1,4.2c2.4-0.1,4.5-2.2,5.4-5.4c0.9-3.2,0.5-7.6-1.7-11.3
					c-2.3-3.8-4.3-6.2-6.3-8.5c-2-2.3-3.9-4.5-6.5-7.8c1.3,4,1.6,6.8,1.6,9.7C130.6,212.7,130.3,215.5,130,219.4z"
                  />
                </g>
                <g>
                  <path
                    d="M111.8,214c-2.1,2.7-2.8,5.7-2.5,8.4c0.3,2.6,1.7,4.9,4,5.8c2.3,0.9,4.9,0.1,6.9-1.9c2-2,3.4-5.2,3.2-8.8
					c-0.2-3.7-0.7-6.3-1.1-8.9c-0.4-2.5-0.7-5.1-0.8-8.6c-1.4,3.3-2.8,5.4-4.3,7.4C115.7,209.3,114,211.2,111.8,214z"
                  />
                </g>
                <g>
                  <path
                    d="M99.2,205.2c-3.4,1.1-6,3-7.7,5.3c-1.7,2.3-2.5,4.9-1.5,7.2c0.9,2.2,3.6,3.5,7,3.1c3.3-0.4,7.2-2.4,9.7-5.9
					c2.6-3.6,3.8-6.4,5-9.1c1.2-2.7,2.3-5.4,4-9.1c-2.9,2.8-5.4,4.3-7.8,5.4C105.3,203.2,102.7,204.1,99.2,205.2z"
                  />
                </g>
                <g>
                  <path
                    d="M94.3,188.9c-3.5-1.1-6.6-1.1-9.4-0.5c-0.7,0.2-1.4,0.3-2,0.6c-0.6,0.3-1.2,0.6-1.7,0.9c-1.1,0.7-1.9,1.6-2.3,2.8
					c-0.4,1.1-0.3,2.4,0.3,3.6c0.3,0.6,0.7,1.2,1.3,1.8c0.6,0.6,1.2,1.1,2,1.6c3.1,1.9,8,2.8,12.5,1.3c4.6-1.6,7.4-3.3,10.1-5.1
					c2.6-1.8,5.1-3.7,8.4-6.4c-4.1,1.4-7.1,1.6-9.9,1.4C100.6,190.6,97.9,190,94.3,188.9z"
                  />
                </g>
                <g>
                  <path
                    d="M100.2,170.6c-2.6-3.1-5.7-5.2-8.7-6.3c-3-1.1-5.9-1.1-7.7,0.5c-1.8,1.6-1.9,4.8-0.1,8.1c1.8,3.3,5.4,6.6,10.1,8
					c4.8,1.4,8.2,1.8,11.5,2.1c3.3,0.3,6.5,0.5,11.2,0.9c-4.3-1.8-6.8-3.7-9.1-5.8C105,176.1,103,173.8,100.2,170.6z"
                  />
                </g>
                <g>
                  <path
                    d="M110.5,158c-1.1-4.1-3-7.4-5.1-9.9c-0.6-0.6-1.1-1.2-1.7-1.7c-0.6-0.5-1.2-0.9-1.8-1.2c-1.2-0.6-2.4-0.9-3.6-0.7
					c-1.2,0.3-2.2,1.1-2.8,2.4c-0.3,0.6-0.6,1.4-0.8,2.2c-0.2,0.8-0.3,1.8-0.2,2.8c0.1,4,2,9,5.8,12.6c3.9,3.7,7,5.5,10.1,7.2
					c3.1,1.7,6.2,3.3,10.6,5.4c-3.6-3.3-5.4-6.2-6.8-9.1C112.8,165.2,111.7,162.2,110.5,158z"
                  />
                </g>
                <g>
                  <path
                    d="M123.7,154.9c0.9-3.8,1.3-6.8,1.4-9.5c0.1-1.3,0-2.7-0.1-4c-0.1-1.3-0.4-2.5-1.2-3.5c-0.8-0.9-2-1.4-3.6-1.2
					c-1.6,0.2-3.6,1.2-5.3,3c-1.7,1.8-3.1,4.4-3.7,7.3c-0.6,2.9-0.6,6,0.3,8.9c0.9,3,2.1,5.2,3.3,7c1.2,1.8,2.4,3.3,3.6,4.8
					c2.4,2.9,4.9,5.5,8.1,9.5c-2-4.8-2.9-8.3-3.3-11.7c-0.2-1.7-0.3-3.4-0.3-5.2C123,158.7,123.2,156.8,123.7,154.9z"
                  />
                </g>
                <g>
                  <path
                    d="M140.8,158.2c3.1-2.2,5.3-4.3,6.9-6.6c0.8-1.1,1.5-2.3,2.1-3.5c0.5-1.2,0.8-2.4,0.6-3.6c-0.2-1.2-1.1-2.2-2.5-2.8
					c-1.4-0.6-3.3-0.8-5.5-0.4c-2.2,0.4-4.5,1.5-6.6,3.3c-2,1.7-3.8,4-4.8,6.5c-1.1,2.7-1.6,4.9-1.9,6.9c-0.3,2-0.4,3.8-0.5,5.6
					c-0.1,3.5,0,6.9-0.1,11.8c1.5-4.6,3.1-7.6,5.1-10.1c1-1.3,2-2.5,3.2-3.6C137.9,160.6,139.2,159.4,140.8,158.2z"
                  />
                </g>
                <g>
                  <path
                    d="M151.4,167.6c4-0.8,7.3-2.3,9.9-4.3c0.6-0.5,1.2-1,1.7-1.5c0.5-0.5,1-1.1,1.3-1.6c0.7-1.1,1.1-2.3,1-3.6
					c-0.2-1.2-0.9-2.3-2.1-3.1c-0.6-0.4-1.3-0.8-2.1-1c-0.8-0.2-1.7-0.4-2.7-0.5c-3.9-0.3-9.1,1.3-12.8,4.9
					c-3.8,3.7-5.8,6.7-7.7,9.6c-1.8,3-3.5,6-5.6,10.2c3.4-3.4,6.2-5.1,9.1-6.3C144.2,169.3,147.3,168.5,151.4,167.6z"
                  />
                </g>
                <g>
                  <path
                    d="M159.8,180.2c4,0.9,7.4,1,10.3,0.8c1.5-0.1,2.9-0.4,4.1-0.8c1.2-0.4,2.4-1,3.1-2c0.7-1,0.8-2.3,0.3-3.8
					c-0.5-1.5-1.8-3-3.7-4.4c-1.9-1.3-4.3-2.3-7-2.7c-2.7-0.4-5.6-0.2-8.3,0.7c-2.8,0.9-4.9,2-6.7,3c-1.8,1-3.3,2.1-4.8,3.1
					c-3,2.1-5.8,4.2-10.1,6.8c4.9-1.3,8.4-1.8,11.8-1.8c1.7,0,3.4,0,5.2,0.2C155.8,179.5,157.7,179.8,159.8,180.2z"
                  />
                </g>
                <g>
                  <path
                    d="M126.3,182.5c-2.4,0.4-2.8,3.2-1.3,5c1.6,1.9,4.4,1.9,5.2-0.4c0.8-2.4,0.8-3.7,2-5.7C130,182.3,128.8,182.1,126.3,182.5
					z"
                  />
                </g>
                <g>
                  <path
                    d="M129.6,184.1c-1.1-0.4-2-0.1-2.7,0.6c-0.7,0.7-1.1,1.8-1,3c0.1,1.2,0.8,2.2,1.7,2.7c0.9,0.5,2.2,0.5,3.2-0.4
					c1-0.9,1.7-1.6,2.3-2.3c0.6-0.7,1.3-1.4,2.2-2.1c-1.2,0-2.1-0.2-2.9-0.4C131.6,184.9,130.8,184.5,129.6,184.1z"
                  />
                </g>
                <g>
                  <path
                    d="M130.1,186.7c-0.2-0.9-0.7-1.3-1.5-1.4c-0.8-0.1-1.9,0.1-3,0.8c-1,0.6-1.7,1.7-1.6,2.8c0.1,1.1,0.9,2.2,2.2,2.5
					c1.4,0.3,2.4,0.2,3.3,0.1c0.9-0.1,1.8-0.2,2.9-0.2c-0.9-0.7-1.3-1.4-1.6-2.1C130.5,188.5,130.3,187.7,130.1,186.7z"
                  />
                </g>
                <g>
                  <path
                    d="M128.5,190c0.4-0.3,0.6-0.7,0.8-1.1c0.1-0.5,0.1-1,0-1.6c-0.1-0.5-0.4-1.1-0.9-1.4c-0.4-0.4-1-0.6-1.6-0.7
					c-0.6-0.1-1.2-0.1-1.7,0c-0.5,0.1-1,0.2-1.4,0.4c-0.4,0.2-0.7,0.6-0.9,1c-0.2,0.5-0.3,1.1-0.2,1.9c0.1,0.8,0.3,1.5,0.6,2.1
					c0.3,0.6,0.6,1.1,0.9,1.5c0.7,0.8,1.5,1.6,2.7,2c-0.4-0.5-0.5-1-0.4-1.4c0-0.4,0.2-0.8,0.4-1.1c0.2-0.3,0.5-0.6,0.7-0.9
					C127.7,190.4,128.1,190.3,128.5,190z"
                  />
                </g>
                <g>
                  <path
                    d="M126.4,190.9c1.1-0.1,1.7-0.6,2.1-1.5c0.3-0.8,0.5-2,0-3.2c-0.4-1.1-1.4-1.9-2.6-2c-1.2-0.1-2.5,0.5-3.2,1.9
					c-0.7,1.4-0.9,2.4-1.1,3.4c-0.2,1-0.4,1.9-0.7,3.2c0.9-0.8,1.8-1.2,2.7-1.4C124.3,191.1,125.2,191,126.4,190.9z"
                  />
                </g>
                <g>
                  <path
                    d="M123,191c1.4,0.5,2.8,0.1,3.7-0.7c0.9-0.8,1.2-2,0.9-3.2c-0.3-1.2-1.2-2.1-2.1-2.5c-0.9-0.4-2-0.4-3,0.3
					c-1.1,0.7-1.9,1.3-2.7,1.8c-0.8,0.5-1.7,0.9-3.1,1.1c1.2,0.5,2.1,1,3,1.6C120.6,189.9,121.5,190.4,123,191z"
                  />
                </g>
                <g>
                  <path
                    d="M121.2,187.2c0.6,2.6,3.6,3.1,5.4,1.5c1.8-1.6,1.7-4.6-0.8-5.5c-2.6-1-3.9-1-6.1-2.3
					C120.7,183.2,120.5,184.5,121.2,187.2z"
                  />
                </g>
                <g>
                  <path
                    d="M121.9,183.6c-1.1,2.2,0.8,4.3,3.2,4.2c2.4-0.1,4.2-2.4,2.8-4.5c-1.4-2.2-2.4-3-3.3-5.2
					C124.1,180.4,123.1,181.3,121.9,183.6z"
                  />
                </g>
                <g>
                  <path
                    d="M124.6,182.2c-0.9,0.7-1.1,1.7-0.8,2.5c0.4,0.9,1.3,1.6,2.4,2c1.2,0.3,2.3,0.2,3.1-0.2c0.8-0.4,1.2-1.1,0.9-2.1
					c-0.5-2-1.2-3.1-0.9-5.1C127.8,180.7,126.5,180.9,124.6,182.2z"
                  />
                </g>
                <g>
                  <path
                    d="M161,194.9c3.3,2.5,6.7,4,9.9,4.5c3.2,0.5,6.1,0.1,7.6-1.9c1.5-1.9,0.9-5.1-1.5-8c-2.4-2.9-6.8-5.5-11.7-6
					c-5.1-0.5-8.5-0.1-11.9,0.2c-3.4,0.4-6.7,0.8-11.3,1.5c4.6,0.9,7.6,2.2,10.3,3.7C155,190.5,157.6,192.3,161,194.9z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M353.7,131.6c-6.1,1.4-12.7,4.3-17.8,8c-2.6,1.8-4.9,3.9-7,6c-2,2.1-3.7,4.3-5.1,6.4c-2.8,4.3-4.2,8.4-4.6,11.5
					c-0.2,1.6-0.1,2.9,0.2,3.9c0.3,1,0.9,1.8,1.6,2.2c0.8,0.4,1.7,0.6,2.7,0.4c1-0.2,2.2-0.6,3.5-1.2c2.5-1.2,5.5-3.2,8.8-5.5
					c1.6-1.1,3.4-2.3,5.2-3.6c1.8-1.2,3.8-2.5,5.8-3.8c4-2.7,8.2-5.1,13.1-8c5-3,9.8-5.5,14-8.2c4.2-2.6,8-5.4,11.3-8.5
					c1.7-1.5,3.3-3.1,4.8-4.8c1.6-1.7,3.1-3.5,4.6-5.5c3-3.9,6-8.5,8.6-14.3c-3.6,5.3-7.4,9-11.1,11.8c-1.9,1.4-3.7,2.6-5.5,3.7
					c-1.8,1.1-3.7,2-5.5,2.7c-3.7,1.6-7.5,2.6-11.9,3.5C365,129.4,360,130.1,353.7,131.6z"
                  />
                </g>
                <g>
                  <path
                    d="M362.6,150.6c-5,2.2-10,4.1-14.7,5.9c-4.7,1.7-9.2,3.3-13.1,4.7c-4,1.4-7.4,2.8-9.9,4.2c-2.5,1.4-4.1,2.9-4.3,4.7
					c-0.1,1.7,1.3,3.5,4.2,5c2.8,1.5,7.1,2.5,12.3,2.7c5.2,0.2,11.2-0.6,17.4-2.6c6.1-2.1,12.3-5.3,17.6-9.8
					c5.4-4.6,9.3-9,12.4-13.3c3.1-4.3,5.4-8.3,7.5-12.4c2-4.1,3.8-8.4,5.4-13.2c1.6-4.8,3-10.2,4-16.7c-2,6.2-4.5,11.1-7.2,15.2
					c-2.6,4.1-5.4,7.4-8.4,10.3c-3,3-6.2,5.6-10,8.1C372,145.9,367.8,148.3,362.6,150.6z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M401.3,173.5c-6-1.9-13.1-2.8-19.4-2.2c-3.2,0.3-6.2,0.8-9,1.6c-2.8,0.8-5.4,1.8-7.7,2.9c-4.6,2.3-7.9,5-9.8,7.5
					c-1,1.2-1.6,2.4-1.8,3.5c-0.3,1.1-0.2,2,0.3,2.7c0.4,0.8,1.2,1.3,2.2,1.7c1,0.4,2.2,0.6,3.6,0.8c2.8,0.2,6.3,0.1,10.3-0.2
					c2-0.1,4.1-0.3,6.4-0.4c2.2-0.1,4.5-0.3,6.9-0.4c4.8-0.2,9.7-0.2,15.4-0.2c5.9,0,11.2,0.3,16.2,0.1c5-0.1,9.7-0.6,14.1-1.5
					c2.2-0.4,4.4-1,6.6-1.7c2.2-0.7,4.4-1.4,6.7-2.4c4.6-1.9,9.5-4.3,14.7-7.9c-5.8,2.7-10.9,3.9-15.6,4.5c-2.3,0.3-4.5,0.4-6.7,0.3
					c-2.1,0-4.2-0.2-6.2-0.5c-4-0.5-7.8-1.6-12-3C412.2,177.4,407.6,175.5,401.3,173.5z"
                  />
                </g>
                <g>
                  <path
                    d="M399.3,194.3c-5.4-0.7-10.7-1.6-15.6-2.5c-4.9-0.9-9.6-1.9-13.7-2.7c-4.1-0.8-7.8-1.4-10.7-1.5
					c-2.9-0.1-5.1,0.4-6.1,1.9c-1,1.4-0.6,3.7,1.1,6.4c1.7,2.7,4.8,5.8,9.2,8.6c4.3,2.8,9.9,5.3,16.3,6.7c6.3,1.4,13.3,1.8,20.1,0.6
					c7-1.1,12.6-3,17.5-5c4.8-2.1,8.9-4.4,12.8-6.9c3.9-2.5,7.6-5.3,11.4-8.6c3.8-3.3,7.8-7.3,12-12.3c-4.9,4.3-9.6,7.2-13.9,9.4
					c-4.4,2.2-8.4,3.5-12.5,4.6c-4.1,1-8.2,1.6-12.7,1.9C409.8,195.2,404.9,195.1,399.3,194.3z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M143.2,295.9c4-4.8,7.5-11.1,9.3-17.2c0.9-3.1,1.6-6.1,1.9-9c0.3-2.9,0.3-5.7,0.2-8.2c-0.4-5.1-1.7-9.2-3.3-11.9
					c-0.8-1.4-1.7-2.4-2.6-3c-0.9-0.6-1.8-0.9-2.6-0.8c-0.9,0.1-1.7,0.6-2.4,1.3c-0.7,0.8-1.4,1.8-2,3.1c-1.3,2.5-2.4,5.9-3.7,9.7
					c-0.6,1.9-1.3,3.9-2,6.1c-0.7,2.1-1.4,4.3-2.2,6.6c-1.6,4.6-3.4,9-5.5,14.3c-2.2,5.4-4.4,10.3-6.2,15c-1.7,4.7-3.1,9.2-3.8,13.6
					c-0.4,2.2-0.7,4.5-0.9,6.8c-0.2,2.3-0.3,4.7-0.3,7.1c0,4.9,0.4,10.4,1.8,16.6c-0.4-6.3,0.4-11.6,1.6-16.1
					c0.6-2.3,1.3-4.4,2.2-6.3c0.8-2,1.7-3.8,2.7-5.5c2-3.5,4.4-6.6,7.3-10C135.6,304.6,139.1,300.9,143.2,295.9z"
                  />
                </g>
                <g>
                  <path
                    d="M124.7,286.2c2.7-4.8,5.5-9.3,8.1-13.6c2.7-4.3,5.3-8.2,7.6-11.7c2.3-3.5,4.2-6.7,5.4-9.3c1.2-2.6,1.5-4.9,0.5-6.3
					c-1-1.5-3.2-2-6.4-1.4c-3.2,0.6-7.2,2.3-11.4,5.3c-4.2,3-8.6,7.3-12.2,12.6c-3.6,5.4-6.6,11.7-8.1,18.4
					c-1.5,6.9-1.9,12.8-1.8,18.1c0.1,5.3,0.7,9.9,1.6,14.4c0.9,4.5,2.1,9,3.7,13.8c1.7,4.8,3.8,9.9,7,15.7
					c-2.2-6.2-3.2-11.6-3.5-16.4c-0.4-4.8-0.2-9.2,0.4-13.3c0.6-4.2,1.6-8.2,3-12.5C120,295.7,121.9,291.1,124.7,286.2z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M453.4,473.1c-7.8,2.4-14.6,6.2-19.4,10.2c-4.8,4-7.6,8.2-6.4,11.6c1.2,3.3,6.3,4.8,13,3.8c6.7-1,14.9-4.4,21.5-10.4
					c6.8-6.1,10.9-10.9,14.9-15.6c4-4.7,8-9.4,14-16.1c-7.3,5.1-12.9,7.7-18.4,9.9C467.1,468.8,461.4,470.6,453.4,473.1z"
                  />
                </g>
                <g>
                  <path
                    d="M447.9,453.4c-7.2-0.7-14.1,0.2-19.4,2c-5.3,1.8-9.1,4.7-9.3,8.2c-0.2,3.5,3.5,6.8,9.5,8.5c6,1.7,14.2,1.6,21.8-1.4
					c7.8-3.1,12.7-6,17.6-8.9c4.9-2.9,9.7-5.8,16.7-9.9c-7.8,2.1-13.3,2.5-18.7,2.5C460.7,454.5,455.3,454.1,447.9,453.4z"
                  />
                </g>
                <g>
                  <path
                    d="M455.3,433.2c-4.7-2.7-9.5-3.7-13.7-3.3c-4.2,0.4-7.9,2.2-9.3,5.4c-1.4,3.2,0.1,7.2,3.8,10.4c3.7,3.1,9.6,5.3,15.9,4.7
					c6.4-0.7,10.7-1.9,14.9-3.1c4.2-1.2,8.3-2.4,14.1-3.8c-6-0.7-9.9-2.1-13.6-3.8C463.7,438,460.2,435.9,455.3,433.2z"
                  />
                </g>
                <g>
                  <path
                    d="M473.5,412.7c-2.2-4.4-5.7-7.2-9.4-8.4c-3.7-1.2-7.5-0.8-10.1,1.7c-2.6,2.4-3.1,6.3-1.8,10.2c1.4,3.9,4.7,7.6,9.6,9.6
					c5,2,8.7,2.9,12.3,3.9c3.6,1,7.1,2.1,11.8,4.3c-3.4-3.8-5.3-7-7-10.2C477.3,420.6,475.8,417.2,473.5,412.7z"
                  />
                </g>
                <g>
                  <path
                    d="M492.7,401.5c0.7-5.1-0.3-9.7-2.2-13.3c-1.9-3.6-4.9-6.3-8.4-6.4c-3.5-0.1-6.7,2.6-8.3,7.2c-1.5,4.5-1.3,10.8,1.8,16.3
					c3.2,5.6,6.1,8.9,8.9,12.1c2.8,3.2,5.6,6.3,9.4,10.8c-1.9-5.5-2.3-9.6-2.3-13.6C491.6,410.7,492,406.8,492.7,401.5z"
                  />
                </g>
                <g>
                  <path
                    d="M517.1,405c3.5-3.9,5.5-8,6.4-12c0.2-1,0.4-2,0.4-3c0-1,0-1.9-0.1-2.8c-0.3-1.8-1-3.5-2.2-4.7c-1.3-1.2-2.9-1.9-4.9-1.8
					c-1,0-2.1,0.2-3.1,0.6c-1.1,0.4-2.2,0.9-3.3,1.6c-4.4,2.8-8.5,8.7-9.3,15.5c-0.8,7-0.1,11.7,0.6,16.3c0.8,4.5,1.7,8.9,3.2,15
					c0.6-6.2,2.3-10.2,4.3-13.8C511,412.3,513.5,409,517.1,405z"
                  />
                </g>
                <g>
                  <path
                    d="M537.4,423.9c5.7-1.6,10.3-4.3,13.5-7.6c3.2-3.2,5.1-7.1,4.1-10.4c-1-3.3-5.1-5.3-10.5-5c-5.4,0.3-11.9,3.1-16.6,8.4
					c-4.8,5.4-7.3,9.6-9.8,13.8c-2.3,4.1-4.7,8.2-8.1,14.1c5-4.5,9-6.7,13.1-8.4C527.3,426.9,531.5,425.6,537.4,423.9z"
                  />
                </g>
                <g>
                  <path
                    d="M547.6,445.1c6,1,11.5,0.6,16-0.7c1.1-0.4,2.2-0.7,3.2-1.2c1-0.5,1.9-1,2.7-1.6c1.6-1.2,2.7-2.6,3.1-4.4
					c0.4-1.7-0.1-3.5-1.4-5.2c-0.6-0.8-1.4-1.7-2.5-2.4c-1-0.7-2.2-1.5-3.5-2c-5.2-2.3-13-2.8-20,0c-7.2,2.9-11.5,5.9-15.7,8.8
					c-4.1,3-8.1,6.1-13.5,10.5c6.6-2.7,11.4-3.3,16-3.4C536.8,443.6,541.4,444.1,547.6,445.1z"
                  />
                </g>
                <g>
                  <path
                    d="M543.7,464.2c4.4,3.5,8.1,5.9,11.5,7.7c1.7,0.9,3.5,1.7,5.2,2.3c1.7,0.6,3.5,1,5.3,0.6c1.7-0.4,3.1-1.7,3.8-4
					c0.7-2.2,0.6-5.4-0.8-8.8c-1.3-3.3-3.9-6.8-7.2-9.3c-3.4-2.6-7.5-4.4-11.9-5c-4.5-0.6-8.1-0.4-11.2,0c-3.1,0.4-5.8,1.1-8.4,1.8
					c-5.2,1.4-10.1,3.1-17.4,4.9c7.5,0.3,12.6,1.2,17.3,2.7c2.3,0.8,4.6,1.7,6.9,2.8C539.1,461,541.5,462.4,543.7,464.2z"
                  />
                </g>
                <g>
                  <path
                    d="M528.8,484.6c1,5.4,2.5,9.5,4.4,13.1c1,1.8,2.1,3.4,3.3,4.8c1.2,1.4,2.7,2.5,4.4,2.9c1.7,0.4,3.5,0,5.2-1.5
					c1.7-1.4,3.1-3.9,3.9-7c0.7-3.1,0.7-6.9-0.2-10.6c-1-3.7-2.9-7.4-5.6-10.3c-2.8-3.1-5.4-5.1-7.9-6.8c-2.5-1.7-4.7-2.9-7-4.1
					c-4.6-2.3-9.1-4.3-15.4-7.3c5.1,4.8,8,8.7,10.2,12.8c1.1,2,2,4.1,2.8,6.4C527.6,479.3,528.3,481.8,528.8,484.6z"
                  />
                </g>
                <g>
                  <path
                    d="M510.1,492.7c-1.4,5.7-1.4,11-0.4,15.5c0.3,1.1,0.5,2.2,0.9,3.2c0.4,1,0.8,1.9,1.3,2.7c1,1.6,2.4,2.9,4.1,3.4
					c1.7,0.5,3.5,0.2,5.3-0.9c0.9-0.5,1.8-1.3,2.6-2.2c0.8-0.9,1.6-2,2.3-3.3c2.7-5,3.8-12.7,1.5-19.8c-2.4-7.3-5.2-11.7-7.9-15.9
					c-2.8-4.2-5.7-8.2-10-13.6c2.4,6.5,2.8,11.3,2.7,15.8C512.2,482.3,511.5,486.8,510.1,492.7z"
                  />
                </g>
                <g>
                  <path
                    d="M488.5,496c-3.6,4.8-5.9,9-7.3,13c-0.7,2-1.3,4-1.5,5.9c-0.2,1.9-0.1,3.7,0.8,5.3c0.9,1.5,2.5,2.5,4.7,2.7
					c2.2,0.2,5.1-0.5,7.9-2.1c2.8-1.6,5.7-4.3,7.8-7.6c2.1-3.3,3.6-7.2,4.1-11.2c0.5-4.2,0.4-7.6,0.2-10.6c-0.3-3-0.7-5.6-1.1-8.2
					c-0.9-5.1-1.9-10.1-2.8-17.4c-1.3,7.2-2.8,12.1-4.8,16.5c-1,2.2-2.1,4.4-3.4,6.7C491.8,491.2,490.3,493.6,488.5,496z"
                  />
                </g>
                <g>
                  <path
                    d="M505.9,450.8c0.9-3.4-2.4-5.6-5.8-4.7c-3.4,0.9-5.2,4.5-2.7,7c2.6,2.5,4.3,3.3,6.2,6.1
					C503.8,455.8,505,454.3,505.9,450.8z"
                  />
                </g>
                <g>
                  <path
                    d="M501.8,454.2c1.2-1.2,1.4-2.6,0.9-3.9c-0.5-1.3-1.7-2.5-3.4-3.1c-1.7-0.6-3.4-0.4-4.6,0.6c-1.2,0.9-1.9,2.6-1.4,4.4
					c0.5,1.9,1.1,3.2,1.6,4.5c0.5,1.3,1,2.5,1.4,4.2c0.7-1.6,1.5-2.6,2.4-3.6C499.5,456.3,500.5,455.4,501.8,454.2z"
                  />
                </g>
                <g>
                  <path
                    d="M498,453.2c1.3,0.3,2.1-0.1,2.7-1.2c0.6-1,1-2.6,0.8-4.4c-0.2-1.7-1.2-3.2-2.7-3.8c-1.5-0.6-3.4-0.2-4.6,1.4
					c-1.2,1.6-1.7,3-2.2,4.2c-0.4,1.3-0.8,2.4-1.5,3.9c1.4-0.8,2.6-0.9,3.7-0.9C495.5,452.6,496.6,452.9,498,453.2z"
                  />
                </g>
                <g>
                  <path
                    d="M494.8,449.1c0.1,0.7,0.5,1.2,1,1.7c0.5,0.4,1.3,0.8,2.1,0.9c0.8,0.1,1.7,0.1,2.4-0.3c0.8-0.3,1.4-0.9,1.9-1.6
					c0.5-0.7,0.8-1.5,1.1-2.2c0.2-0.7,0.3-1.4,0.3-2.1c-0.1-0.7-0.3-1.3-0.8-1.8c-0.5-0.6-1.3-1.1-2.4-1.4c-1.1-0.3-2.2-0.5-3.1-0.5
					c-0.9,0-1.7,0.1-2.5,0.3c-1.5,0.4-3,0.9-4.2,2.3c0.8-0.2,1.5,0,2.1,0.3c0.5,0.3,0.9,0.7,1.2,1.2c0.3,0.5,0.5,1,0.7,1.5
					C494.6,447.8,494.6,448.4,494.8,449.1z"
                  />
                </g>
                <g>
                  <path
                    d="M494.8,445.7c-0.6,1.5-0.3,2.6,0.6,3.6c0.9,1,2.4,1.8,4.1,2c1.7,0.2,3.4-0.6,4.2-2.1c0.9-1.5,0.9-3.7-0.5-5.4
					c-1.4-1.8-2.6-2.7-3.8-3.6c-1.2-0.9-2.3-1.7-3.7-2.8c0.5,1.8,0.5,3.1,0.2,4.4C495.9,443,495.4,444.2,494.8,445.7z"
                  />
                </g>
                <g>
                  <path
                    d="M496.9,441.3c-1.5,1.5-1.9,3.6-1.4,5.2c0.5,1.6,1.9,2.8,3.6,3.1c1.7,0.3,3.4-0.2,4.5-1.2c1.1-1,1.7-2.3,1.5-4.1
					c-0.3-1.8-0.6-3.2-0.7-4.6c-0.1-1.4-0.1-2.8,0.5-4.7c-1.5,1.3-2.6,2.1-3.9,3C499.7,438.9,498.4,439.7,496.9,441.3z"
                  />
                </g>
                <g>
                  <path d="M502.9,441.2c-3.7-0.8-6.3,2.7-5.3,6.1c1,3.4,5,5,7.7,2.3c2.8-2.8,3.6-4.5,6.8-6.6C508.4,443.1,506.8,442,502.9,441.2z" />
                </g>
                <g>
                  <path
                    d="M507.1,444.4c-2.2-2.9-6.2-1.6-7.5,1.7c-1.3,3.3,0.6,6.9,4.2,6.5c3.7-0.5,5.4-1.4,8.9-1.1
					C510,449.2,509.4,447.3,507.1,444.4z"
                  />
                </g>
                <g>
                  <path
                    d="M507.3,448.7c-0.3-1.6-1.5-2.5-2.9-2.6c-1.4-0.1-2.9,0.6-4.1,2c-1.2,1.3-1.7,2.9-1.7,4.1c0.1,1.3,0.7,2.2,2.2,2.4
					c3,0.6,4.8,0.4,7.3,1.9C507.4,453.8,507.9,452.1,507.3,448.7z"
                  />
                </g>
                <g>
                  <path
                    d="M468.5,488.6c-5.3,2.7-9.3,6.4-11.9,10.2c-2.6,3.8-3.8,7.9-2.1,11.1c1.6,3.1,6.1,4.3,11.4,2.9c5.3-1.4,11.3-5.5,15-11.7
					c3.7-6.3,5.4-11.1,7-15.7c1.5-4.7,3-9.2,5-15.7c-3.9,5.5-7.5,8.6-11.2,11.3C477.9,483.5,474,485.7,468.5,488.6z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M426.3,566.6c5.8-2.4,11.9-6.2,16.3-10.7c2.3-2.2,4.3-4.6,5.9-7c1.7-2.4,3-4.8,4-7.2c2.1-4.7,2.9-8.9,2.7-12.1
					c-0.1-1.6-0.4-2.9-0.8-3.9c-0.5-1-1.1-1.6-2-1.9c-0.8-0.3-1.8-0.3-2.8,0c-1,0.3-2.1,0.9-3.2,1.7c-2.3,1.6-4.9,4-7.8,6.8
					c-1.4,1.4-3,2.8-4.6,4.4c-1.6,1.5-3.3,3.1-5.1,4.7c-3.5,3.3-7.3,6.4-11.7,10c-4.5,3.7-8.8,7-12.5,10.3c-3.8,3.3-7,6.6-9.9,10.2
					c-1.4,1.8-2.7,3.6-4,5.5c-1.3,1.9-2.5,4-3.7,6.1c-2.3,4.4-4.5,9.3-6.2,15.5c2.7-5.8,5.9-10,9.1-13.4c1.6-1.7,3.2-3.2,4.9-4.5
					c1.6-1.3,3.3-2.5,5-3.6c3.4-2.1,7-3.8,11.2-5.4C415.5,570.6,420.3,569,426.3,566.6z"
                  />
                </g>
                <g>
                  <path
                    d="M414.5,549.3c4.6-2.9,9.2-5.6,13.6-8.1c4.4-2.5,8.6-4.7,12.2-6.7c3.7-2,6.9-3.9,9.1-5.7c2.3-1.8,3.6-3.6,3.5-5.3
					c-0.2-1.7-1.9-3.3-4.9-4.3c-3-1-7.4-1.4-12.6-0.7c-5.1,0.6-11,2.3-16.8,5.3c-5.7,3-11.4,7.2-15.8,12.4
					c-4.6,5.4-7.8,10.4-10.2,15.1c-2.4,4.7-4.1,9.1-5.4,13.5c-1.4,4.4-2.4,8.9-3.2,13.9c-0.8,5-1.3,10.6-1.3,17.1
					c1-6.5,2.7-11.7,4.7-16.1c2-4.4,4.2-8.1,6.7-11.5c2.5-3.4,5.3-6.5,8.5-9.6C405.9,555.4,409.8,552.3,414.5,549.3z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M372.6,532.7c6.2,0.9,13.4,0.7,19.5-0.9c3.1-0.8,6-1.8,8.7-3c2.7-1.2,5-2.6,7.1-4.1c4.2-3,7-6.2,8.5-9
					c0.7-1.4,1.2-2.7,1.2-3.7c0.1-1.1-0.1-2-0.7-2.7c-0.5-0.7-1.4-1.1-2.4-1.4c-1-0.2-2.3-0.3-3.7-0.2c-2.8,0.2-6.3,0.9-10.2,1.8
					c-1.9,0.4-4,0.9-6.2,1.4c-2.2,0.5-4.4,1-6.8,1.5c-4.7,1-9.5,1.8-15.1,2.6c-5.8,0.9-11.1,1.5-16,2.4c-4.9,0.9-9.4,2.1-13.7,3.7
					c-2.1,0.8-4.2,1.7-6.3,2.7c-2.1,1-4.2,2.1-6.3,3.4c-4.2,2.6-8.7,5.7-13.3,10.1c5.3-3.6,10.2-5.6,14.7-6.9
					c2.3-0.6,4.4-1.1,6.5-1.4c2.1-0.3,4.1-0.5,6.2-0.5c4-0.1,7.9,0.3,12.4,1.1C361.2,530.7,366.2,531.8,372.6,532.7z"
                  />
                </g>
                <g>
                  <path
                    d="M371.3,511.9c5.5-0.2,10.8-0.1,15.8,0c5,0.1,9.8,0.3,14,0.5c4.2,0.1,7.9,0.1,10.8-0.2c2.9-0.4,4.9-1.2,5.7-2.8
					c0.7-1.6,0.1-3.8-2.1-6.2c-2.1-2.4-5.7-5-10.4-7.1c-4.7-2.1-10.7-3.6-17.1-4c-6.5-0.3-13.4,0.4-20,2.6c-6.7,2.2-12,4.9-16.5,7.7
					c-4.4,2.8-8.1,5.7-11.6,8.8c-3.4,3.1-6.6,6.4-9.9,10.3c-3.2,3.9-6.6,8.4-9.8,14c4.2-5,8.3-8.7,12.2-11.5c4-2.8,7.8-4.8,11.6-6.5
					c3.9-1.6,7.8-2.9,12.2-3.9C360.8,512.7,365.7,512,371.3,511.9z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M514.4,139c10.6-1.1,21.3-3.7,30.7-7.7c9.4-3.9,17.6-9.3,23.7-14.8c6.1-5.5,10.2-11.1,12.5-15.3
					c2.3-4.2,2.9-6.9,2.1-7.4c-1.6-1-7.7,7.5-19.9,16.2c-6.1,4.3-13.6,8.7-22.2,12.2c-8.6,3.5-18.2,6.3-28.2,8.1
					c-10.2,1.9-19.1,2.6-27.2,3.2c-8.1,0.6-15.5,1.1-23,1.9c-7.4,0.8-14.9,1.9-23.1,4.1c-4.1,1.1-8.3,2.5-12.7,4.4
					c-4.4,1.9-8.9,4.3-13.4,7.6c9.3-6,18.4-8.6,26.5-10.2c8.2-1.5,15.6-1.9,22.9-2c7.4-0.1,14.7,0.2,22.9,0.5
					C494.2,139.9,503.4,140.1,514.4,139z"
                  />
                </g>
                <g>
                  <path
                    d="M458.8,128.6c2.7-1.5,4.5-4,5.1-6.3c0.6-2.3,0-4.4-1.5-5.3c-1.5-0.9-3.5-0.6-5.3,0.4c-1.8,1-3.5,2.7-4.6,5.2
					c-1.1,2.5-2,4.3-3,6c-1,1.7-2.2,3.4-4.4,5.4c2.8-1.1,4.7-1.7,6.8-2.4C454,130.8,456,130.1,458.8,128.6z"
                  />
                </g>
                <g>
                  <path
                    d="M475.9,123.1c2.3-1.3,3.6-3.6,3.8-5.6c0.2-2.1-0.6-3.8-2.1-4.7c-1.6-0.8-3.4-0.6-5,0.2c-1.6,0.8-2.9,2.3-3.5,4.4
					c-0.7,2.2-1.1,3.8-1.7,5.4c-0.6,1.6-1.4,3.1-3,5c2.3-1,3.9-1.6,5.6-2.2C471.8,125,473.6,124.4,475.9,123.1z"
                  />
                </g>
                <g>
                  <path
                    d="M493,119.2c2.2-1.6,3.5-4,3.7-6.1c0.2-2.1-0.5-4-2.1-4.8c-1.5-0.8-3.5-0.5-5.1,0.5c-1.7,1.1-3.1,2.9-3.7,5.3
					c-0.6,2.5-1,4.3-1.5,6.1c-0.5,1.8-1.2,3.6-2.5,5.8c2.2-1.6,3.8-2.5,5.5-3.5C488.9,121.7,490.7,120.8,493,119.2z"
                  />
                </g>
                <g>
                  <path
                    d="M512.9,117.7c2.4-2.2,3.5-5.3,3.4-7.8c-0.1-2.5-1.3-4.3-3-4.6c-1.7-0.4-3.5,0.6-4.9,2c-1.4,1.4-2.5,3.4-2.9,5.9
					c-0.5,2.6-1,4.5-1.6,6.3c-0.7,1.8-1.6,3.7-3.7,5.8c2.7-1.3,4.5-2.3,6.5-3.3C508.4,121,510.4,119.9,512.9,117.7z"
                  />
                </g>
                <g>
                  <path
                    d="M529.5,112.5c2.1-2.5,3.1-5.6,2.9-8.1c-0.1-2.5-1.2-4.4-2.9-4.8c-1.7-0.5-3.6,0.5-5,2.2c-1.5,1.7-2.6,4.1-3,7
					c-0.4,3-0.6,5.1-1,7.2c-0.4,2.1-1,4.3-2.4,7.1c2.3-2.2,4-3.7,5.7-5.2C525.6,116.6,527.3,115.1,529.5,112.5z"
                  />
                </g>
                <g>
                  <path
                    d="M541.5,108.7c2-2,2.9-4.7,2.9-7c-0.1-2.2-1.2-4-2.8-4.5c-1.7-0.5-3.5,0.2-5,1.5c-1.5,1.4-2.6,3.4-2.9,6
					c-0.3,2.6-0.5,4.5-0.8,6.4c-0.3,1.9-0.8,3.8-2,6.3c2.1-1.9,3.6-3.1,5.2-4.3C537.8,112,539.4,110.8,541.5,108.7z"
                  />
                </g>
                <g>
                  <path
                    d="M558.6,100c2.1-2.3,3.1-5.3,3-7.7c0-2.4-1.1-4.3-2.7-4.9c-1.7-0.5-3.6,0.3-5.1,1.9c-1.5,1.6-2.7,4-3.1,6.8
					c-0.4,2.9-0.6,5-0.9,7.1c-0.4,2.1-0.9,4.2-2.2,7c2.2-2.2,3.8-3.7,5.5-5.1C554.7,103.8,556.5,102.4,558.6,100z"
                  />
                </g>
                <g>
                  <path
                    d="M573,93.6c2.2-1.8,3.6-4.1,4.1-6.2c0.5-2.2,0.2-4.2-1.2-5.3c-1.4-1.1-3.5-0.9-5.5,0.3c-2,1.2-3.9,3.5-4.7,6.5
					c-0.8,3-1.2,5.2-1.5,7.3c-0.4,2.1-0.8,4.2-1.6,7.1c1.8-2.4,3.3-3.9,4.9-5.2C569,96.6,570.7,95.4,573,93.6z"
                  />
                </g>
                <g>
                  <path
                    d="M587.3,86.9c2.3-1.1,3.9-2.9,4.8-4.7c0.8-1.8,0.9-3.8-0.1-5.2c-1-1.4-3.1-1.9-5.2-1.2c-2.2,0.6-4.5,2.4-5.7,5.1
					c-1.3,2.7-1.9,4.7-2.5,6.7c-0.6,2-1.2,3.9-2.3,6.5c1.9-2,3.6-3.2,5.3-4.1C583.1,88.9,584.9,88.1,587.3,86.9z"
                  />
                </g>
                <g>
                  <path
                    d="M602.4,93.3c2.3,0.6,4.4,0.3,6.1-0.6c1.7-0.9,3-2.3,3.1-4.1c0.1-1.7-1-3.4-3.1-4.3c-2-0.9-4.8-1-7.4,0.3
					c-2.6,1.3-4.2,2.6-5.7,3.7c-1.5,1.2-3.1,2.4-5.3,3.9c2.6-0.5,4.5-0.5,6.3-0.2C598.3,92.3,600,92.7,602.4,93.3z"
                  />
                </g>
                <g>
                  <path
                    d="M602.3,107.3c2.1,1.2,4.2,1.5,6.1,1.3c1.9-0.3,3.6-1.1,4.4-2.7c0.8-1.6,0.2-3.6-1.6-5.2c-1.8-1.6-4.7-2.6-7.7-2.1
					c-3.1,0.5-5.1,1.4-7,2.2c-2,0.9-3.8,1.7-6.3,2.8c2.8-0.1,4.7,0.4,6.5,1C598.4,105.2,600.1,106.1,602.3,107.3z"
                  />
                </g>
                <g>
                  <path
                    d="M598.1,120.2c2.4,1.8,5,2.6,7.4,2.6c2.3,0,4.2-0.9,4.9-2.5c0.7-1.6-0.1-3.6-1.8-5.3c-1.7-1.6-4.4-2.9-7.5-3.1
					c-6.3-0.3-8.9,0.5-15.1-0.2C591.6,114.5,593.3,116.5,598.1,120.2z"
                  />
                </g>
                <g>
                  <path
                    d="M455.6,157.1c1.3,2.2,2.9,3.7,4.7,4.7c1.8,0.9,3.8,1.2,5.3,0.3c1.5-0.9,2.1-3,1.3-5.4c-0.7-2.4-2.8-5-5.7-6.3
					c-3-1.3-5.3-1.7-7.4-2.1c-2.2-0.4-4.3-0.7-7.2-1.1c2.6,1.4,4.1,2.9,5.4,4.4C453.2,153.1,454.3,154.8,455.6,157.1z"
                  />
                </g>
                <g>
                  <path
                    d="M472.5,157.1c1.1,2.4,2.4,4.3,3.9,5.8c1.5,1.5,3.1,2.7,4.9,2.5c0.9-0.1,1.6-0.6,2.2-1.6c0.6-0.9,0.9-2.2,0.8-3.8
					c-0.1-1.5-0.5-3.3-1.5-4.9c-0.9-1.6-2.3-3.2-4-4.3c-1.8-1.1-3.4-1.8-4.8-2.2c-1.5-0.4-2.8-0.7-4.1-0.8c-2.6-0.3-5.1-0.3-8.6,0.2
					c3.4,0.6,5.5,1.9,7.1,3.3C470,152.9,471.3,154.7,472.5,157.1z"
                  />
                </g>
                <g>
                  <path
                    d="M487.8,156.6c1.5,2.4,3.4,4.2,5.4,5.3c1.9,1.1,4,1.5,5.5,0.6c1.5-0.9,2-3.1,1.1-5.7c-0.9-2.6-3.1-5.4-6.4-6.9
					c-3.3-1.5-5.7-2.1-8.1-2.5c-2.4-0.5-4.7-0.9-8-1.4c3,1.5,4.7,3.1,6.1,4.7C484.9,152.4,486.2,154.1,487.8,156.6z"
                  />
                </g>
                <g>
                  <path
                    d="M506.5,159.3c2.5,2.3,5.7,3.4,8.3,3.4c2.6,0,4.6-1.1,5.1-2.7c0.5-1.7-0.5-3.6-2.2-5.1c-1.7-1.6-4.2-2.8-7.2-3.3
					c-3-0.5-5.2-0.9-7.4-1.4c-2.2-0.5-4.3-1.3-7.2-2.9c2.2,2.5,3.6,4.3,5.1,6.1C502.5,155.1,504,157,506.5,159.3z"
                  />
                </g>
                <g>
                  <path
                    d="M525.3,155.5c3.6,1.7,7.5,2.2,10.5,1.7c3-0.5,5.1-1.9,5.2-3.6c0.2-1.8-1.5-3.5-4.1-4.7c-2.6-1.2-6-2-9.7-2
					c-3.8,0.1-6.5,0.2-9.2,0.1c-2.7-0.1-5.5-0.3-9.4-1.3c3.5,1.9,5.9,3.4,8.3,4.9C519.2,152.2,521.6,153.7,525.3,155.5z"
                  />
                </g>
                <g>
                  <path
                    d="M549,150.1c6.4,2.5,13.2,0.2,13.4-3.3c0.2-3.5-6-6.6-12.5-5.4c-6.7,1.1-9.4,2.3-16.4,2.3
					C540.3,145.7,542.5,147.5,549,150.1z"
                  />
                </g>
                <g>
                  <path
                    d="M568.8,144.1c3.4,1.2,7.1,1.2,9.7,0.4c2.7-0.8,4.5-2.3,4.5-4.1c0-1.8-1.7-3.3-4.2-4.3c-2.5-1-5.8-1.4-9.2-0.8
					c-3.5,0.6-6,1.1-8.5,1.5c-2.5,0.4-5.1,0.6-8.8,0.4c3.5,1.2,5.9,2.3,8.2,3.4C562.9,141.7,565.2,142.8,568.8,144.1z"
                  />
                </g>
                <g>
                  <path
                    d="M580.9,135.9c1.6,1.6,3.5,2.2,5.3,2.2c1.8,0,3.5-0.8,4.4-2.3c0.9-1.5,0.7-3.5-0.5-5.1c-1.2-1.6-3.4-2.9-6.1-3
					c-2.7,0-4.6,0.3-6.4,0.5c-1.8,0.3-3.6,0.5-6,0.6c2.3,0.9,3.7,1.9,5,3C578.1,133,579.3,134.2,580.9,135.9z"
                  />
                </g>
                <g>
                  <path
                    d="M591.4,129.9c3.6,4.4,9.6,5,11.5,2c1.9-3-1.3-8.1-6.8-9.4c-5.7-1.4-8.2-1.2-13.6-3.3
					C586.8,123.1,587.7,125.4,591.4,129.9z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M191.4,346.1c2,10.5,5.5,20.9,10.2,29.9c4.7,9.1,10.8,16.7,16.8,22.3c6,5.6,11.9,9.2,16.3,11.1c4.4,1.9,7.2,2.3,7.5,1.5
					c0.9-1.7-8.1-7.1-17.8-18.4c-4.8-5.7-9.8-12.8-14-21.1c-4.2-8.3-7.8-17.6-10.5-27.4c-2.8-10-4.2-18.8-5.5-26.9
					c-1.3-8.1-2.4-15.4-3.8-22.7c-1.4-7.3-3.2-14.7-6-22.6c-1.4-4-3.2-8.1-5.4-12.3c-2.2-4.2-5.1-8.5-8.7-12.7
					c6.8,8.8,10.2,17.6,12.4,25.5c2.2,8,3.2,15.3,4,22.6c0.7,7.3,1,14.6,1.5,22.8C188.8,326.1,189.3,335.3,191.4,346.1z"
                  />
                </g>
                <g>
                  <path
                    d="M197,289.9c1.7,2.6,4.4,4.1,6.8,4.5c2.4,0.4,4.4-0.3,5.2-1.9c0.8-1.6,0.3-3.5-0.8-5.3c-1.2-1.7-3-3.3-5.5-4.1
					c-2.6-0.9-4.5-1.6-6.3-2.5c-1.8-0.9-3.6-1.9-5.7-3.9c1.3,2.7,2.1,4.6,3,6.6C194.4,285.3,195.3,287.3,197,289.9z"
                  />
                </g>
                <g>
                  <path
                    d="M204,306.5c1.5,2.2,3.9,3.3,5.9,3.4c2.1,0.1,3.8-0.9,4.5-2.5c0.7-1.6,0.3-3.5-0.6-4.9c-1-1.5-2.5-2.6-4.7-3.1
					c-2.2-0.5-3.9-0.8-5.6-1.3c-1.6-0.5-3.2-1.2-5.2-2.6c1.2,2.2,1.9,3.8,2.7,5.4C201.7,302.5,202.5,304.3,204,306.5z"
                  />
                </g>
                <g>
                  <path
                    d="M209.3,323.1c1.8,2.1,4.2,3.1,6.4,3.2c2.1,0.1,3.9-0.8,4.6-2.5c0.7-1.6,0.2-3.5-1-5.1c-1.2-1.6-3.1-2.8-5.6-3.2
					c-2.5-0.4-4.4-0.6-6.2-1c-1.8-0.4-3.6-0.9-6-2c1.7,2,2.8,3.6,3.9,5.2C206.4,319.3,207.5,321,209.3,323.1z"
                  />
                </g>
                <g>
                  <path
                    d="M212.5,342.8c2.4,2.2,5.6,3,8,2.7c2.5-0.3,4.1-1.7,4.3-3.4c0.2-1.8-0.9-3.4-2.4-4.7c-1.5-1.3-3.6-2.2-6.1-2.4
					c-2.6-0.3-4.5-0.6-6.4-1.1c-1.9-0.5-3.8-1.3-6.1-3.2c1.5,2.5,2.6,4.3,3.8,6.2C208.8,338.7,210.1,340.6,212.5,342.8z"
                  />
                </g>
                <g>
                  <path
                    d="M219.1,359c2.6,1.9,5.8,2.6,8.3,2.2c2.5-0.3,4.3-1.6,4.6-3.3c0.3-1.7-0.8-3.5-2.7-4.8c-1.8-1.3-4.4-2.3-7.3-2.4
					c-3-0.1-5.1-0.1-7.3-0.4c-2.2-0.2-4.3-0.6-7.3-1.8c2.4,2.1,4,3.6,5.6,5.2C214.7,355.4,216.3,357,219.1,359z"
                  />
                </g>
                <g>
                  <path
                    d="M223.9,370.7c2.2,1.9,5,2.5,7.2,2.2c2.2-0.3,3.9-1.5,4.3-3.2c0.4-1.7-0.5-3.5-2-4.8c-1.5-1.4-3.7-2.3-6.2-2.3
					c-2.6-0.1-4.6-0.1-6.5-0.2c-1.9-0.2-3.8-0.5-6.4-1.5c2,1.9,3.4,3.4,4.7,4.9C220.3,367.2,221.6,368.7,223.9,370.7z"
                  />
                </g>
                <g>
                  <path
                    d="M234,386.9c2.5,1.9,5.5,2.6,7.9,2.4c2.4-0.2,4.2-1.4,4.6-3.1c0.4-1.7-0.6-3.5-2.4-4.9c-1.8-1.4-4.2-2.4-7.1-2.5
					c-3-0.1-5.1-0.2-7.2-0.3c-2.1-0.2-4.2-0.5-7.2-1.6c2.4,2,4,3.5,5.6,5C229.9,383.4,231.4,385,234,386.9z"
                  />
                </g>
                <g>
                  <path
                    d="M241.6,400.7c2,2.1,4.3,3.2,6.5,3.5c2.2,0.3,4.2-0.2,5.2-1.7c1-1.5,0.6-3.6-0.8-5.5c-1.4-1.9-3.8-3.6-6.8-4.2
					c-3.1-0.6-5.3-0.7-7.4-0.9c-2.1-0.2-4.3-0.4-7.2-1c2.6,1.6,4.2,3,5.6,4.5C238.2,397,239.6,398.6,241.6,400.7z"
                  />
                </g>
                <g>
                  <path
                    d="M249.5,414.4c1.3,2.2,3.2,3.7,5.1,4.3c1.9,0.7,3.9,0.6,5.2-0.6c1.3-1.2,1.6-3.2,0.7-5.3c-0.8-2.1-2.8-4.2-5.5-5.3
					c-2.8-1.1-4.9-1.5-6.9-1.9c-2-0.4-3.9-0.9-6.6-1.7c2.2,1.8,3.5,3.3,4.6,4.9C247.1,410.4,248.1,412.1,249.5,414.4z"
                  />
                </g>
                <g>
                  <path
                    d="M244.4,430c-0.4,2.4,0.1,4.4,1.1,6c1,1.6,2.6,2.7,4.3,2.7c1.8,0,3.3-1.3,4-3.4c0.7-2.1,0.6-4.9-0.9-7.3
					c-1.5-2.5-2.9-4-4.2-5.4c-1.3-1.4-2.6-2.9-4.3-4.9c0.7,2.6,0.8,4.4,0.7,6.2C245.1,425.8,244.8,427.6,244.4,430z"
                  />
                </g>
                <g>
                  <path
                    d="M230.5,431.1c-1,2.2-1.2,4.3-0.7,6.2c0.4,1.9,1.4,3.5,3.1,4.2c1.6,0.6,3.6-0.1,5-2.1c1.4-1.9,2.2-4.9,1.4-7.9
					c-0.8-3-1.8-4.9-2.8-6.8c-1-1.9-2-3.7-3.3-6.1c0.3,2.8,0,4.7-0.4,6.6C232.2,427,231.5,428.8,230.5,431.1z"
                  />
                </g>
                <g>
                  <path
                    d="M217.3,428c-1.6,2.5-2.2,5.3-1.9,7.5c0.2,2.3,1.2,4.1,2.9,4.7c1.7,0.5,3.6-0.4,5.1-2.2c1.5-1.9,2.5-4.7,2.4-7.7
					c-0.3-6.3-1.2-8.8-1.1-15C222.4,421,220.5,422.8,217.3,428z"
                  />
                </g>
                <g>
                  <path
                    d="M168.4,289.1c-2.1,1.5-3.5,3.2-4.2,5.1c-0.7,1.9-0.9,3.9,0.2,5.3c1,1.4,3.2,1.8,5.5,0.9c2.3-0.9,4.7-3.2,5.8-6.3
					c1.1-3.2,1.3-5.4,1.5-7.6c0.2-2.2,0.3-4.3,0.4-7.3c-1.2,2.7-2.5,4.3-3.9,5.7C172.1,286.4,170.5,287.6,168.4,289.1z"
                  />
                </g>
                <g>
                  <path
                    d="M169.7,305.9c-2.3,1.3-4,2.7-5.5,4.3c-1.4,1.6-2.5,3.4-2.1,5.1c0.2,0.9,0.8,1.6,1.7,2c1,0.5,2.3,0.7,3.8,0.5
					c1.5-0.2,3.2-0.8,4.8-1.9c1.5-1.1,3-2.6,3.9-4.4c1-1.9,1.5-3.5,1.8-5c0.3-1.5,0.4-2.8,0.5-4.1c0-2.6-0.1-5.2-0.9-8.6
					c-0.3,3.5-1.4,5.6-2.7,7.4C173.7,303.1,172.1,304.6,169.7,305.9z"
                  />
                </g>
                <g>
                  <path
                    d="M171.5,321.1c-2.3,1.7-3.9,3.7-4.8,5.8c-0.9,2-1.2,4.1-0.1,5.5c1,1.4,3.3,1.7,5.8,0.6c2.5-1.1,5.1-3.6,6.3-6.9
					c1.2-3.4,1.6-5.9,1.8-8.3c0.3-2.4,0.5-4.7,0.7-8.1c-1.3,3.1-2.6,4.9-4.1,6.5C175.6,317.9,173.9,319.3,171.5,321.1z"
                  />
                </g>
                <g>
                  <path
                    d="M170.5,340.1c-2.1,2.7-2.9,6-2.6,8.5c0.2,2.6,1.4,4.5,3.2,4.8c1.7,0.3,3.5-0.8,4.9-2.6c1.4-1.9,2.4-4.4,2.7-7.4
					c0.3-3.1,0.4-5.3,0.8-7.5c0.4-2.2,0.9-4.4,2.3-7.4c-2.3,2.4-3.9,4-5.6,5.6C174.4,335.7,172.6,337.3,170.5,340.1z"
                  />
                </g>
                <g>
                  <path
                    d="M175.9,358.4c-1.4,3.7-1.5,7.7-0.8,10.6c0.7,3,2.3,4.9,4,4.9c1.8,0,3.3-1.8,4.3-4.5c1-2.7,1.5-6.2,1.1-9.9
					c-0.4-3.8-0.7-6.5-0.9-9.2c-0.2-2.7-0.2-5.5,0.4-9.4c-1.6,3.7-2.9,6.2-4.2,8.7C178.7,352.1,177.4,354.6,175.9,358.4z"
                  />
                </g>
                <g>
                  <path
                    d="M183.3,381.6c-1.9,6.6,0.9,13.2,4.4,13.1c3.5-0.1,6-6.5,4.4-12.9c-1.7-6.6-3.1-9.1-3.7-16.1
					C186.9,372.5,185.3,374.9,183.3,381.6z"
                  />
                </g>
                <g>
                  <path
                    d="M190.9,400.8c-0.9,3.5-0.6,7.1,0.4,9.7c1,2.6,2.7,4.3,4.5,4.1c1.8-0.1,3.2-2,3.9-4.5c0.8-2.6,0.9-5.9,0-9.3
					c-0.9-3.5-1.6-5.9-2.2-8.4c-0.6-2.5-1-5-1.2-8.7c-0.9,3.6-1.8,6-2.6,8.5C192.8,394.7,191.9,397.2,190.9,400.8z"
                  />
                </g>
                <g>
                  <path
                    d="M200.2,412.2c-1.4,1.7-1.9,3.7-1.8,5.5c0.2,1.8,1.1,3.4,2.7,4.2c1.6,0.8,3.5,0.4,5-1c1.5-1.3,2.6-3.7,2.5-6.3
					c-0.2-2.7-0.6-4.5-1.1-6.3c-0.4-1.8-0.8-3.5-1.1-6c-0.7,2.4-1.6,3.9-2.6,5.3C202.8,409.1,201.7,410.4,200.2,412.2z"
                  />
                </g>
                <g>
                  <path d="M207,422.2c-4,4-4.1,10-1,11.6c3.1,1.6,8-2,8.8-7.6c0.9-5.8,0.5-8.2,2.1-13.8C213.4,417,211.1,418.1,207,422.2z" />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M378.5,8.1c9.5-5,18.3-11.4,25.5-18.6c7.3-7.2,12.8-15.2,16.3-22.6c3.6-7.4,5.3-14.1,5.8-18.9c0.5-4.8,0-7.5-0.8-7.6
					c-1.9-0.3-4.3,9.8-12.3,22.5c-4,6.3-9.3,13.2-16,19.6c-6.6,6.5-14.6,12.7-23,18.1c-8.7,5.6-16.7,9.6-24,13.2
					c-7.3,3.6-14,6.8-20.6,10.4c-6.6,3.6-13.1,7.4-19.8,12.5c-3.4,2.5-6.8,5.4-10.1,8.8c-3.4,3.4-6.6,7.4-9.6,12
					c6.4-9.1,13.8-14.9,20.7-19.4c7-4.5,13.7-7.6,20.5-10.5c6.8-2.8,13.7-5.3,21.4-8.2C360.1,16.5,368.7,13.3,378.5,8.1z"
                  />
                </g>
                <g>
                  <path
                    d="M323.1,19.3c2-2.4,2.7-5.4,2.3-7.8c-0.3-2.4-1.6-4.1-3.3-4.4c-1.7-0.3-3.5,0.7-4.8,2.3c-1.3,1.6-2.2,3.9-2.3,6.5
					c-0.1,2.7-0.2,4.7-0.5,6.7c-0.3,2-0.8,4-2.1,6.6c2.2-2,3.7-3.4,5.4-4.8C319.4,23.2,321.1,21.8,323.1,19.3z"
                  />
                </g>
                <g>
                  <path
                    d="M336.9,7.8c1.6-2.1,2-4.7,1.5-6.6c-0.5-2-2-3.4-3.7-3.5c-1.8-0.2-3.4,0.7-4.5,2.1c-1.1,1.4-1.8,3.2-1.6,5.4
					c0.2,2.3,0.4,4,0.4,5.7c0,1.7-0.1,3.4-1,5.8c1.7-1.8,3-3,4.4-4.2C333.8,11.1,335.2,9.9,336.9,7.8z"
                  />
                </g>
                <g>
                  <path
                    d="M351.2-2.2c1.5-2.3,1.7-5,1.1-7c-0.6-2.1-2-3.5-3.7-3.7c-1.8-0.2-3.4,0.8-4.6,2.4c-1.1,1.6-1.8,3.8-1.4,6.3
					c0.4,2.6,0.7,4.4,0.9,6.3c0.2,1.9,0.3,3.7-0.1,6.4c1.4-2.3,2.6-3.8,3.8-5.3C348.4,1.6,349.7,0.1,351.2-2.2z"
                  />
                </g>
                <g>
                  <path
                    d="M369.1-11.1c1.4-2.9,1.2-6.2,0.2-8.4c-1-2.3-2.9-3.4-4.6-3.1c-1.7,0.3-3,1.8-3.8,3.7c-0.8,1.9-1,4.1-0.5,6.5
					c0.5,2.6,0.8,4.5,0.9,6.5c0,2-0.1,4-1.2,6.8c2-2.2,3.4-3.8,4.8-5.5C366.2-6.4,367.6-8.1,369.1-11.1z"
                  />
                </g>
                <g>
                  <path
                    d="M382.6-22.2c1-3.1,0.7-6.3-0.3-8.6c-1-2.3-2.8-3.6-4.5-3.4c-1.8,0.2-3.1,1.8-3.8,4c-0.7,2.1-0.9,4.8-0.1,7.6
					c0.8,2.9,1.4,5,1.8,7.1c0.4,2.1,0.7,4.3,0.4,7.5c1.3-2.9,2.3-4.9,3.3-6.9C380.4-16.9,381.5-19,382.6-22.2z"
                  />
                </g>
                <g>
                  <path
                    d="M392.3-30.2c1.1-2.7,1-5.5,0-7.5c-0.9-2-2.6-3.3-4.3-3.1c-1.8,0.1-3.2,1.5-4,3.3c-0.8,1.8-1.1,4.2-0.4,6.6
					c0.7,2.5,1.3,4.4,1.7,6.3c0.4,1.9,0.7,3.8,0.5,6.6c1.2-2.5,2.2-4.2,3.2-5.9C390-25.7,391.1-27.5,392.3-30.2z"
                  />
                </g>
                <g>
                  <path
                    d="M404.8-44.7c1.1-3,0.9-6-0.1-8.3c-1-2.2-2.6-3.6-4.4-3.5c-1.8,0.1-3.2,1.6-4,3.7c-0.8,2.1-1,4.7-0.3,7.5
					c0.8,2.9,1.4,4.9,1.8,7c0.4,2.1,0.8,4.2,0.6,7.3c1.2-2.9,2.1-4.8,3.1-6.8C402.6-39.7,403.7-41.7,404.8-44.7z"
                  />
                </g>
                <g>
                  <path
                    d="M415.7-56.1c1.4-2.5,1.8-5.1,1.4-7.3c-0.3-2.2-1.4-3.9-3.1-4.4c-1.7-0.5-3.6,0.4-5,2.3c-1.4,1.9-2.3,4.7-1.9,7.8
					c0.4,3.1,0.9,5.2,1.3,7.3c0.5,2.1,0.9,4.2,1.2,7.2c0.8-2.9,1.6-4.9,2.6-6.7C413.2-51.8,414.3-53.5,415.7-56.1z"
                  />
                </g>
                <g>
                  <path
                    d="M426.4-67.6c1.7-1.9,2.5-4.1,2.6-6.1c0.1-2-0.6-3.9-2.1-4.8c-1.5-0.9-3.5-0.6-5.3,0.9c-1.8,1.4-3.2,3.9-3.4,6.8
					c-0.2,3,0,5.1,0.2,7.1c0.2,2,0.3,4,0.3,6.9c1-2.6,2.1-4.3,3.3-5.8C423.3-64.2,424.7-65.6,426.4-67.6z"
                  />
                </g>
                <g>
                  <path
                    d="M442.9-67.4c2.4-0.3,4.2-1.4,5.4-2.9c1.2-1.4,1.9-3.2,1.3-4.9c-0.5-1.7-2.2-2.8-4.4-2.8c-2.2-0.1-4.8,0.9-6.7,3.1
					c-1.9,2.2-2.9,3.9-3.9,5.6c-1,1.7-2,3.3-3.4,5.6c2.2-1.5,4-2.1,5.7-2.6C438.6-66.8,440.4-67.1,442.9-67.4z"
                  />
                </g>
                <g>
                  <path
                    d="M448-54.4c2.4,0.3,4.5-0.2,6.2-1.1c1.7-1,3-2.4,3.1-4.2c0.1-1.7-1.2-3.4-3.4-4.2c-2.2-0.8-5.3-0.6-7.9,1
					c-2.7,1.7-4.2,3.2-5.7,4.6c-1.5,1.6-2.9,3-4.8,5c2.6-1.1,4.5-1.4,6.4-1.5C443.6-54.9,445.5-54.7,448-54.4z"
                  />
                </g>
                <g>
                  <path
                    d="M449-40.9c2.9,0.8,5.7,0.5,7.8-0.4c2.1-0.9,3.6-2.4,3.6-4.2c0-1.8-1.4-3.3-3.6-4.2c-2.2-0.9-5.2-1-8.1,0
					c-6,2.1-8,3.8-14,5.5C440.8-43.7,443.1-42.4,449-40.9z"
                  />
                </g>
                <g>
                  <path
                    d="M330.8,46.9c2,1.6,4.1,2.4,6.1,2.5c2,0.2,3.9-0.3,5-1.7c1-1.4,0.8-3.5-0.8-5.5c-1.5-1.9-4.4-3.6-7.7-3.6
					c-3.3-0.1-5.5,0.4-7.7,0.8c-2.1,0.5-4.2,1-7.1,1.7c3,0.3,4.9,1.1,6.6,2C327.1,44.2,328.7,45.3,330.8,46.9z"
                  />
                </g>
                <g>
                  <path
                    d="M346.5,40.6c1.9,1.8,3.8,3.1,5.8,3.9c1.9,0.8,3.9,1.4,5.5,0.5c0.8-0.4,1.3-1.2,1.4-2.3c0.2-1.1,0-2.4-0.7-3.8
					c-0.6-1.4-1.7-2.8-3.2-4c-1.5-1.2-3.3-2.1-5.3-2.4c-2.1-0.4-3.8-0.4-5.3-0.3c-1.5,0.1-2.8,0.4-4.1,0.8c-2.5,0.7-4.9,1.6-7.9,3.4
					c3.4-0.8,5.8-0.3,7.9,0.4C342.6,37.7,344.5,38.8,346.5,40.6z"
                  />
                </g>
                <g>
                  <path
                    d="M360.5,34.4c2.3,1.7,4.7,2.6,6.9,2.9c2.2,0.3,4.3-0.1,5.3-1.5c1-1.4,0.7-3.6-1.1-5.7c-1.8-2.1-4.9-3.8-8.5-4
					c-3.6-0.2-6.1,0.3-8.5,0.7c-2.4,0.5-4.7,1-7.9,1.7c3.3,0.3,5.5,1.1,7.4,2C356.2,31.5,358.1,32.7,360.5,34.4z"
                  />
                </g>
                <g>
                  <path
                    d="M378.8,29.8c3.2,1.2,6.5,1,8.9,0c2.4-1,3.8-2.7,3.7-4.4c-0.2-1.8-1.8-3.1-4-3.9c-2.2-0.8-5-1-7.9-0.4
					c-3,0.7-5.2,1.2-7.4,1.5c-2.2,0.3-4.5,0.4-7.8,0c3,1.5,5,2.6,7,3.7C373.5,27.4,375.6,28.6,378.8,29.8z"
                  />
                </g>
                <g>
                  <path
                    d="M394.8,19.2c4,0.3,7.8-0.8,10.4-2.4c2.6-1.6,4-3.6,3.5-5.3c-0.5-1.7-2.7-2.6-5.6-2.8c-2.9-0.2-6.4,0.4-9.8,1.9
					c-3.5,1.5-6,2.6-8.5,3.6c-2.5,1-5.2,1.8-9.2,2.4c4,0.5,6.7,0.9,9.5,1.4C387.9,18.4,390.7,19,394.8,19.2z"
                  />
                </g>
                <g>
                  <path
                    d="M414.8,5.3c6.9-0.1,12.4-4.8,11.2-8.1c-1.2-3.3-8-3.8-13.6-0.3c-5.8,3.6-7.8,5.6-14.3,8.3C405,4.5,407.7,5.3,414.8,5.3z
					"
                  />
                </g>
                <g>
                  <path
                    d="M430.8-7.7c3.7-0.2,7-1.5,9.2-3.3c2.2-1.7,3.3-3.8,2.6-5.5c-0.7-1.6-2.8-2.4-5.5-2.4c-2.7,0-5.9,0.9-8.8,2.7
					c-3,1.9-5.1,3.3-7.3,4.6c-2.2,1.3-4.5,2.5-8,3.7c3.7-0.2,6.3-0.1,8.9,0C424.5-7.7,427.1-7.5,430.8-7.7z"
                  />
                </g>
                <g>
                  <path
                    d="M439-19.9c2.1,0.9,4.1,0.8,5.8,0c1.6-0.7,3-2.1,3.3-3.8c0.3-1.7-0.7-3.5-2.4-4.5c-1.7-1.1-4.3-1.4-6.7-0.5
					c-2.5,1-4.1,2-5.7,2.9c-1.6,0.9-3.1,1.8-5.4,2.9c2.5,0,4.2,0.4,5.8,0.9C435.2-21.5,436.8-20.8,439-19.9z"
                  />
                </g>
                <g>
                  <path d="M446.5-29.3c5,2.7,10.8,1,11.4-2.5c0.6-3.5-4.3-7-9.9-6.2c-5.8,0.9-8,2-13.8,2C439.6-33.9,441.3-32.1,446.5-29.3z" />
                </g>
              </g>
              <g>
                <path
                  d="M533.7,299.2c0.6-0.6,1.1-1.2,1.6-1.8c0.5-0.7,1-1.3,1.5-2c0.9-1.4,1.8-2.8,2.4-4.3c1.4-3,2.2-6.2,2.6-9.4
				c0.8-6.4-0.3-12.8-2.8-18.6c-1.2-2.9-3-5.7-5-8.1c-2-2.4-4.2-4.5-6.6-6.4c-4.8-3.6-10.2-6.1-15.6-7.6c-2.7-0.7-5.5-1.3-8.2-1.5
				c-2.7-0.3-5.5-0.3-8.1-0.2c-5.4,0.3-10.8,1.5-15.7,3.8c-5,2.3-9.4,5.8-12.6,10c-1.6,2.1-2.9,4.5-3.9,6.9
				c-0.9,2.4-1.5,5.1-1.6,7.7c-0.3,5.1,1,10,3.5,14.1c1.3,2,2.9,3.8,4.7,5.2c1.8,1.4,3.8,2.4,5.8,3.1c4,1.3,8,1.3,11.5,0.3
				c3.5-1,6.4-3.3,8-5.7c1.7-2.4,2.4-4.9,2.6-7c0.2-2.1-0.1-4-0.6-5.3c-0.5-1.4-1.2-2.3-1.7-2.8c-0.6-0.5-1-0.6-1.3-0.5
				c-0.6,0.3-0.4,1.7-0.7,3.8c-0.1,1-0.4,2.2-1.1,3.5c-0.6,1.2-1.5,2.6-2.7,3.6c-0.6,0.5-1.3,0.9-2,1.3c-0.7,0.3-1.6,0.5-2.5,0.6
				c-1.8,0.2-3.8-0.2-5.7-1.2c-0.9-0.5-1.8-1.1-2.5-1.9c-0.7-0.7-1.3-1.6-1.8-2.7c-1-2.1-1.4-4.7-1-7.3c0.2-1.3,0.6-2.5,1.2-3.8
				c0.6-1.2,1.4-2.4,2.4-3.6c1-1.1,2.1-2.2,3.4-3.1c1.3-0.9,2.7-1.7,4.3-2.3c6.2-2.5,14.7-2.8,22.4-0.6c3.8,1.1,7.5,2.8,10.6,5.1
				c1.5,1.2,3,2.5,4.2,3.9c0.6,0.7,1.1,1.5,1.7,2.2l0.7,1.2l0.6,1.3c1.6,3.4,2.4,7.4,2.2,11.1c-0.1,1.9-0.5,3.7-1.2,5.4
				c-0.3,0.9-0.7,1.6-1.2,2.4l-0.7,1.1c-0.3,0.4-0.5,0.7-0.9,1.1c-2.5,3-6.1,5.2-10.1,6.9c-2,0.9-4.1,1.6-6.1,2.2
				c-1.1,0.3-2.1,0.6-3.2,0.9c-1.1,0.3-2.1,0.6-3.1,0.8c-8.4,1.9-16.5,2.6-24.2,2.5c-7.7-0.1-15.1-1.1-22.2-2.7
				c-7.1-1.6-13.8-3.8-20.3-6.6c-13-5.7-25.3-13.9-36.8-25.5c-5.7-5.8-11.3-12.5-16.4-20.3c-5.2-7.7-9.9-16.7-13.9-26.8
				c3.7,10.3,8,19.4,12.8,27.5c4.8,8.1,10,15.2,15.6,21.4c11.1,12.5,23,21.8,36.3,28.8c6.6,3.5,13.5,6.4,20.9,8.7
				c7.4,2.3,15.2,3.9,23.5,4.7c8.4,0.8,17.3,0.7,26.8-0.7c1.2-0.1,2.4-0.4,3.6-0.6c1.2-0.2,2.4-0.5,3.6-0.7c2.5-0.6,5-1.2,7.6-2.1
				c2.6-0.9,5.2-2,7.8-3.5C528.7,303.5,531.3,301.6,533.7,299.2z"
                />
              </g>
              <g>
                <path
                  d="M454.1,257.7c3.8-5.9,5.1-13.2,3.9-20.1c-0.6-3.4-1.9-6.8-3.7-9.7c-1.8-2.9-4.1-5.5-6.7-7.6c-2.6-2.1-5.5-3.7-8.6-4.7
				c-3.1-1-6.3-1.5-9.5-1.3c-3.2,0.2-6.3,0.9-9.1,2.4c-0.7,0.3-1.4,0.8-2,1.2c-0.7,0.5-1.3,0.9-1.9,1.4c-1.2,1-2.2,2.1-3.1,3.3
				c-1.7,2.4-2.8,5-3.3,7.6c-0.5,2.6-0.3,5.3,0.4,7.7c0.8,2.5,2.1,4.4,3.6,5.7c1.5,1.4,3,2.3,4.5,2.9c1.5,0.6,2.9,0.8,4.1,0.8
				c0.6,0,1.2-0.1,1.7-0.2c0.5-0.1,0.9-0.3,1.3-0.4c0.8-0.4,1.3-0.8,1.5-1.2c0.3-0.4,0.4-0.7,0.3-1.1c-0.2-0.7-1-1-2-1.6
				c-1-0.6-2.2-1.3-3.3-2.6c-1.1-1.2-1.9-3.1-1.8-4.5c0.1-0.9,0.2-1.7,0.7-2.6c0.4-0.9,1.1-1.8,1.9-2.6c0.4-0.4,0.9-0.7,1.3-1
				c0.2-0.1,0.5-0.2,0.7-0.4c0.3-0.1,0.5-0.2,0.8-0.3c1.1-0.4,2.4-0.5,3.9-0.5c2.9,0.1,5.9,1.3,8.6,3.4c1.3,1.1,2.5,2.4,3.4,3.8
				c0.9,1.5,1.6,3,2,4.7c0.8,3.4,0.5,7.4-1.1,10.7c-1.6,3.5-4.6,6.3-7.9,8.3c-3.4,1.9-7,3-10.6,3.3c-3.7,0.4-7.3,0.1-10.9-0.6
				c-3.5-0.7-7-1.8-10.4-3.2c-3.4-1.4-6.6-3.1-9.9-5.2c-3.2-2.1-6.3-4.6-9.4-7.5c-3-2.9-6-6.3-8.7-10.3c-2.7-4-5.2-8.6-7.1-14
				c3.2,11,8.3,19.4,13.9,26.1c2.8,3.4,5.7,6.3,8.8,9c3.1,2.6,6.3,4.9,9.7,7c3.4,2.1,7,3.8,11,5.2c3.9,1.4,8.2,2.4,13,2.7
				c4.7,0.3,10-0.4,15.2-2.5c2.6-1,5.2-2.5,7.8-4.4C449.8,263.2,452.2,260.8,454.1,257.7z"
                />
              </g>
              <g>
                <path
                  d="M336.8,288.9c-1.6,6.2-2,12.8-1.5,18.8c0.5,6,2,11.6,3.9,16c1.9,4.5,4.2,7.8,6.2,9.9c2.1,2.1,3.9,2.9,5,2.3
				c1.2-0.6,1.7-2.5,1.6-5.1c0.1-2.7-0.4-6.2-0.8-10.4c-0.4-4.1-0.9-8.8-1.2-13.8c-0.3-5-0.4-10.4-0.3-15.8
				c0.1-5.6,0.8-10.4,1.7-14.7c0.9-4.3,2.2-8.3,3.4-12.5c1.2-4.1,2.4-8.3,3.2-13.2c0.4-2.4,0.6-5,0.6-7.8c0-2.8-0.4-5.7-1.3-8.7
				c0.5,3.1,0.5,6,0.1,8.6c-0.4,2.6-1.1,5-1.9,7.2c-1.6,4.4-3.7,8-5.9,11.6c-2.2,3.6-4.5,7.2-6.8,11.5
				C340.7,277.4,338.5,282.5,336.8,288.9z"
                />
              </g>
              <g>
                <path
                  d="M350.5,287.2c-2,4.9-4.1,10-5.6,15.3c-1.5,5.3-2.2,10.7-1.9,15.5c0.3,4.8,1.5,8.7,3,11.2c1.5,2.5,3.2,3.5,4.5,3.1
				c1.3-0.4,2-2,2.7-4.3c0.7-2.3,1.4-5.3,2.3-8.9c0.9-3.6,2.2-7.7,3.7-12.4c1.4-4.7,3-10.1,4-15.9c0.9-6,1.2-11.1,1.2-15.7
				c0-4.6-0.4-8.7-0.9-12.8c-0.5-4.1-1.2-8.1-2.1-12.5c-1-4.4-2.2-9.3-4.1-14.9c1.1,5.8,1.4,10.8,1.4,15.2c0,4.4-0.4,8.4-0.9,12.2
				c-0.6,3.8-1.4,7.6-2.5,11.6C354.1,277.9,352.6,282.2,350.5,287.2z"
                />
              </g>
              <g>
                <path
                  d="M393.3,330.8c-0.5,0.7-1,1.3-1.5,1.9c-0.5,0.6-1.1,1.1-1.6,1.6c-1.2,0.9-2.4,1.7-3.7,2.3c-1.3,0.6-2.5,0.8-3.7,0.9
				c-1.2,0.1-2.1,0-3.2-0.4c-2.1-0.6-3.9-2.2-4.8-3.6c-0.4-0.7-0.7-1.4-0.8-2.3c-0.1-0.9,0-1.8,0.2-2.8c0.5-1.9,1.6-3.7,2.7-4.9
				c1.2-1.2,2-1.6,2.4-2c0.3-0.1,0.8-0.1,1.2,0c0.4,0.1,0.8,0.3,1.1,0.5c0.7,0.4,1.1,1.1,1.7,1.4c0.3,0.1,0.7,0.1,1.1-0.1
				c0.4-0.3,0.8-0.8,1.1-1.6c0.2-0.8,0.2-2-0.2-3.1c-0.4-1.2-1.2-2.4-2.6-3.5c-1.6-1.1-3.4-1.5-5.4-1.5c-1.9,0-3.8,0.5-5.6,1.3
				c-3.5,1.6-6.7,4.5-9,8.7c-1.1,2.1-1.9,4.6-2.1,7.3c-0.2,2.7,0.2,5.8,1.4,8.5c1.1,2.8,2.9,5.2,5.1,7.2c2.2,2,4.8,3.6,7.7,4.6
				c2.9,1,6.3,1.4,9.3,1.1c3.1-0.3,6.1-1.2,8.7-2.6c2.7-1.4,5-3.2,7.1-5.3c1-1.1,1.9-2.2,2.8-3.4c0.8-1.2,1.5-2.4,2.1-3.7
				c2.5-5.3,3.2-10.4,3.2-14.9c0-4.6-0.8-8.7-1.8-12.3c-1-3.7-2.3-6.9-3.6-10c-1.3-3.1-2.7-6-4.2-8.9c-1.5-2.9-3-5.7-4.8-8.6
				c-1.7-2.9-3.5-5.8-5.6-8.7c-4.1-6-8.9-12.3-15.4-19.3c5.9,7.4,9.9,14.3,13.1,20.6c1.6,3.2,3,6.2,4.2,9.2c1.2,3,2.2,6,3.2,8.9
				c0.9,3,1.7,6,2.4,9c0.7,3.1,1.3,6.2,1.6,9.4c0.3,3.2,0.3,6.5-0.3,9.8C396.4,324.7,395.3,328,393.3,330.8z"
                />
              </g>
              <g>
                <path
                  d="M211.1,46.3c3.4-0.8,6.9-1,10.2-0.6c3.3,0.4,6.4,1.5,8.9,3.1c2.6,1.6,4.7,3.9,6.2,6.4c0.7,1.2,1.3,2.5,1.7,3.8
				c0.4,1.3,0.5,2.6,0.6,3.9c0.1,5.3-3,11-7.3,15.2c-1.1,1.1-2.2,2.1-3.4,2.9c-1.2,0.9-2.4,1.7-3.5,2.4c-2.4,1.4-4.7,2.1-6.6,2.3
				c-1,0.1-1.9,0-2.6-0.2c-0.8-0.2-1.6-0.5-2.3-1c-1.5-1-2.7-2.4-3.5-3.9c-0.4-0.8-0.6-1.5-0.8-2.3c-0.1-0.4-0.1-0.8-0.2-1.1
				c0-0.4,0-0.7,0-1.1c0.1-1.5,0.6-3,1.3-4.2c1.4-2.5,3.4-4.2,4.9-5.2c1.5-1,2.5-1.6,2.5-2.3c0-0.6-1.2-1.4-3.6-1.3
				c-1.2,0-2.7,0.3-4.3,0.9c-1.6,0.7-3.4,1.7-5.1,3.3c-1.7,1.6-3.3,3.8-4.3,6.8c-0.3,0.7-0.4,1.5-0.6,2.3c-0.1,0.8-0.2,1.6-0.2,2.5
				c-0.1,1.7,0.2,3.4,0.6,5.2c0.9,3.5,2.9,6.8,5.9,9.6c1.5,1.4,3.4,2.6,5.5,3.4c2.2,0.9,4.4,1.3,6.6,1.4c4.5,0.2,8.9-1,12.7-2.8
				c1.9-0.9,3.7-1.9,5.5-3.1c1.8-1.2,3.5-2.5,5.1-4c1.6-1.4,3.2-3.1,4.6-4.9c1.4-1.8,2.7-3.7,3.9-5.8c1.1-2.1,2.1-4.4,2.8-6.9
				c0.7-2.5,1.1-5.1,1.1-7.8c0-2.7-0.3-5.5-1.1-8.1c-0.8-2.6-1.9-5.1-3.3-7.4c-2.8-4.6-6.7-8.4-11.4-11.2c-4.7-2.8-9.9-4.2-15.1-4.6
				c-5.2-0.4-10.3,0.3-15.2,1.9c-5,1.6-9.3,4-13,6.7c-3.7,2.7-6.9,5.8-9.6,9c-5.3,6.4-9.1,13.2-11.6,19.9
				c-2.5,6.7-3.9,13.4-4.6,19.9c-0.7,6.5-0.7,12.8-0.1,19.1c0.6,6.3,1.8,12.4,3.6,18.6c1.8,6.2,4.1,12.4,6.9,18.7
				c5.7,12.7,13.8,25.8,24.4,40.6c-10-15.2-17.2-28.8-22-41.6c-2.4-6.4-4.2-12.6-5.4-18.7c-1.2-6.1-1.8-12-1.9-18
				c0-5.9,0.5-11.8,1.7-17.7c1.2-5.8,3-11.7,5.8-17.4c2.8-5.6,6.5-11.1,11.4-16c2.4-2.4,5.2-4.6,8.2-6.5
				C204.3,48.6,207.6,47.1,211.1,46.3z"
                />
              </g>
              <g>
                <path
                  d="M217.7,111.6c1.7-0.5,3.2-0.7,4.8-0.7c1.5,0,2.9,0.2,4,0.6c0.5,0.2,1,0.4,1.3,0.7c0.4,0.2,0.7,0.5,0.9,0.8
				c0.5,0.6,1,1.5,1.3,2.5c0.2,0.5,0.3,1.1,0.3,1.7c0.1,0.6,0.1,1.1,0,1.7c-0.1,1.1-0.4,2.2-0.7,3c-0.2,0.4-0.4,0.8-0.6,1.1
				c-0.2,0.3-0.4,0.6-0.7,0.8c-0.3,0.2-0.4,0.4-0.7,0.6c-0.3,0.2-0.6,0.3-1,0.4c-0.7,0.2-1.5,0.2-2.3,0.1c-0.7-0.1-1.4-0.3-2-0.7
				c-1.2-0.6-2.2-2-2.8-3.3c-0.6-1.3-0.9-2.6-1.1-3.6c-0.2-1-0.5-1.8-1-2.2c-0.5-0.3-1.4-0.2-2.5,0.7c-1,0.9-2.2,2.7-2.6,5.4
				c-0.2,1.3-0.2,2.9,0.1,4.5c0.3,1.7,1,3.5,2.3,5.2c2.5,3.5,7,6.2,12.4,6.3c1.3,0,2.7-0.1,4.2-0.4c1.4-0.3,2.9-0.9,4.2-1.7
				c1.3-0.8,2.5-1.7,3.6-2.8c1.1-1.1,2-2.3,2.8-3.5c1.5-2.5,2.5-5.3,3-8.3c0.2-1.5,0.3-3,0.2-4.5c-0.1-1.5-0.3-3-0.7-4.5
				c-0.8-3-2.3-6.1-4.8-8.7c-2.5-2.6-5.8-4.5-9-5.3c-3.2-0.9-6.4-1-9.4-0.6c-1.5,0.2-2.9,0.5-4.3,0.9c-1.4,0.4-2.7,1-4,1.5
				c-5.3,2.4-9.2,5.9-12.2,9.4c-1.5,1.8-2.8,3.6-3.9,5.4c-1.1,1.9-2,3.7-2.8,5.5c-3,7.5-3.6,14.5-3.3,21.3c0.3,6.8,1.7,13.4,4,20.4
				c2.2,7,5.4,14.5,9.3,23.3c-3.1-9.1-5.5-16.9-6.7-24c-1.3-7.1-1.6-13.5-0.8-19.6c0.7-6.1,2.5-12,5.9-17.3c0.8-1.3,1.8-2.6,2.9-3.9
				c1.1-1.2,2.3-2.4,3.5-3.5C211.2,114.4,214.2,112.5,217.7,111.6z"
                />
              </g>
              <g>
                <path
                  d="M252.1,32.8c3.2,0.6,6.4-0.3,8.5-1.8c2.1-1.5,3-3.4,2.5-5.1c-0.6-1.7-2.4-2.7-4.6-3c-2.2-0.3-4.8,0-7.4,1.2
				c-2.7,1.2-4.6,2.1-6.6,2.8c-2,0.7-4.2,1.2-7.3,1.3c3,1,5.1,1.7,7.3,2.5C246.6,31.4,248.8,32.2,252.1,32.8z"
                />
              </g>
              <g>
                <path
                  d="M267.3,45.6c1.8,2,3.7,3.3,5.6,4.1c1.9,0.8,4,1.1,5.5,0.2c1.5-0.9,1.9-3.2,0.7-5.8c-1.2-2.6-4-5.2-7.6-6.1
				c-3.7-0.9-6.2-0.8-8.7-0.6c-2.4,0.2-4.8,0.6-8,1.4c3.3,0.3,5.4,1.2,7.2,2.3C263.8,42.2,265.4,43.6,267.3,45.6z"
                />
              </g>
              <g>
                <path
                  d="M270,60.5c0.6,2.6,1.5,4.6,2.7,6.3c1.2,1.6,2.7,3,4.5,3.1c0.9,0,1.7-0.4,2.4-1.2c0.3-0.4,0.7-0.9,0.9-1.5
				c0.2-0.6,0.4-1.3,0.5-2c0.4-2.9-0.8-6.8-3.6-9.5c-2.9-2.7-5.2-3.8-7.5-4.7c-2.3-0.9-4.7-1.6-7.9-2.2c3,1.6,4.4,3.3,5.6,5.1
				C268.6,55.8,269.4,57.8,270,60.5z"
                />
              </g>
              <g>
                <path
                  d="M263.1,73.6c-0.7,2.7-0.6,5.1,0.1,7.2c0.7,2,1.9,3.7,3.7,4c1.7,0.3,3.5-0.9,4.7-3.2c1.1-2.3,1.5-5.6,0.3-8.6
				c-1.2-3.2-2.4-5.1-3.5-7.1c-1.2-1.9-2.3-3.8-3.9-6.5c0.6,3.1,0.5,5.2,0.2,7.3C264.3,68.8,263.9,70.8,263.1,73.6z"
                />
              </g>
              <g>
                <path
                  d="M251.6,86.6c-1.8,2-2.8,4.1-3.2,6.2c-0.4,2.1-0.1,4.1,1.2,5.3c1.3,1.2,3.5,1,5.7-0.5c2.1-1.5,4-4.4,4.4-7.7
				c0.4-3.4,0.1-5.8-0.2-8c-0.3-2.3-0.7-4.5-1.2-7.5c-0.5,3.1-1.4,5-2.5,6.8C254.7,82.9,253.5,84.5,251.6,86.6z"
                />
              </g>
              <g>
                <path
                  d="M241.2,20.8c6-2.2,9.5-7.9,7.4-10.8c-2.1-2.9-8.6-1.4-12.5,3.7c-2,2.6-3.3,4.5-4.6,6.4c-0.7,0.9-1.3,1.9-2.1,2.7
				c-0.4,0.4-0.9,0.8-1.5,1.1c-0.6,0.3-1.4,0.4-2.1,0.2c0.7,0.5,1.5,0.7,2.3,0.6c0.8,0,1.5-0.2,2.2-0.4c0.7-0.2,1.3-0.4,1.9-0.6
				c0.6-0.2,1.1-0.4,1.7-0.6C235.8,22.5,238.1,21.9,241.2,20.8z"
                />
              </g>
              <g>
                <path
                  d="M227.4,15.6c2.1-2.5,2.5-5.9,1.9-8.2c-0.7-2.4-2.4-3.8-4.1-3.6c-1.8,0.1-3.2,1.4-4.2,2.9c-1,1.5-1.6,3.3-1.6,5.5
				c0,2.4-0.2,4.2-0.7,5.9c-0.5,1.8-1.3,3.6-3.2,5.6c2.6-1.2,4.4-2.1,6.2-3.2C223.5,19.4,225.3,18.1,227.4,15.6z"
                />
              </g>
              <g>
                <path
                  d="M216.5,14.7c1.1-3.1,0.5-6.4-0.9-8.5c-1.4-2.1-3.4-2.9-5-2.3c-1.7,0.6-2.6,2.3-3.1,4.1c-0.5,1.8-0.5,3.9,0.3,6.1
				c0.8,2.4,1.2,4.2,1.4,6.1c0.2,1.9,0.1,3.9-1,6.6c1.9-2.1,3.3-3.8,4.6-5.5C214.1,19.6,215.4,17.8,216.5,14.7z"
                />
              </g>
              <g>
                <path
                  d="M204,15.5c-0.4-2.8-2.4-5-4.4-5.8c-2.1-0.8-4-0.4-5.1,1c-1.1,1.4-1.2,3.2-0.9,4.8c0.3,1.6,1.1,2.9,2.7,4.1
				c1.7,1.2,2.9,2.3,3.9,3.5c1,1.2,1.9,2.7,2.4,5.1c0.6-2.4,0.9-4.1,1.3-5.9C204.2,20.4,204.4,18.4,204,15.5z"
                />
              </g>
              <g>
                <path
                  d="M189.6,18.1c-1.2-0.9-2.6-1.3-4-1.3c-1.4,0-2.6,0.3-3.6,1c-1,0.6-1.6,1.4-1.9,2.3c-0.3,0.9-0.2,1.7,0.1,2.5
				c0.7,1.6,2.1,2.7,3.1,3.4c1.1,0.7,2,0.9,3.4,0.4c1.5-0.5,2.9-0.5,4.3-0.3c1.4,0.3,2.8,1,4.1,2.7c-0.3-2.2-0.8-3.8-1.5-5.5
				C193,21.7,192,19.9,189.6,18.1z"
                />
              </g>
              <g>
                <path
                  d="M174.9,28.6c-3.1,0-5.9,1.8-7.2,3.8c-1.3,2-1.2,4.2,0,5.4c1.2,1.3,3.1,1.6,4.8,1.4c1.8-0.2,3.4-0.8,5.1-2.3
				c1.7-1.5,3.2-2.6,4.8-3.4c1.6-0.8,3.4-1.4,6.1-1.4c-2.5-1.1-4.3-1.8-6.3-2.4C180.2,29.1,178.1,28.5,174.9,28.6z"
                />
              </g>
              <g>
                <path
                  d="M168.5,41.5c-2.5,0.6-4.3,2.4-4.8,4.3c-0.6,1.9-0.1,3.7,1.3,4.8c1.4,1.1,3.2,1.3,4.7,1c1.5-0.3,2.8-1.1,3.7-2.8
				c1-1.7,1.7-3,2.7-4.3c0.9-1.2,2.1-2.3,4.1-3.3c-2.2,0-3.8-0.1-5.6-0.2C172.8,41,171,40.9,168.5,41.5z"
                />
              </g>
              <g>
                <path
                  d="M158.7,62.1c-2.2,1.8-3.3,4.4-3.4,6.6c-0.1,2.2,0.9,4,2.5,4.7c1.6,0.7,3.5,0.1,5.1-1.1c1.6-1.2,2.8-3.1,3.3-5.6
				c0.5-2.6,0.8-4.4,1.3-6.3c0.5-1.8,1.1-3.6,2.6-6c-2.2,1.6-3.9,2.7-5.6,3.7C162.7,59.2,161,60.2,158.7,62.1z"
                />
              </g>
              <g>
                <path d="M155.7,90.1c-2.8,3.4-1.4,8,2,9c3.4,1,7.1-2.2,6.5-6.5c-0.6-4.5-1.6-6.4-1.4-10.8C160.4,85.5,158.6,86.6,155.7,90.1z" />
              </g>
              <g>
                <path
                  d="M157,113.8c-1.1,1.8-0.9,3.7-0.1,5.2c0.8,1.4,2.4,2.4,4.1,2.4c1.8,0,3.3-0.9,4.2-2.1c0.9-1.3,1.2-2.9,0.5-4.7
				c-1.5-3.7-2.8-5.3-3.2-9.2C160.8,108.9,159.2,110.1,157,113.8z"
                />
              </g>
              <g>
                <path
                  d="M191.5,102.9c2.1-4.3,3.5-8.8,3.9-12.4c0.5-3.6,0.1-6.5-1.5-7.2c-1.6-0.7-4.1,0.9-6.3,4.1c-2.2,3.2-4.1,8-4.6,13.1
				c-0.5,5.3-0.4,8.9-0.3,12.5c0.1,3.6,0.2,7.2,0.2,12.4c1-5.1,2.2-8.5,3.5-11.8C187.9,110.5,189.4,107.4,191.5,102.9z"
                />
              </g>
              <g>
                <g>
                  <path
                    d="M6.8,333.4c-3-1.7-5.7-4-7.9-6.6c-2.1-2.6-3.6-5.5-4.4-8.4c-0.8-2.9-0.8-6.1-0.2-8.9c0.3-1.4,0.8-2.7,1.4-3.9
					c0.6-1.2,1.4-2.2,2.3-3.2c3.6-3.9,9.7-6,15.7-6.1c1.5-0.1,3,0,4.5,0.1c1.5,0.1,2.9,0.4,4.2,0.7c2.7,0.6,4.8,1.6,6.3,2.8
					c0.8,0.6,1.4,1.3,1.8,1.9c0.5,0.7,0.8,1.5,1,2.3c0.4,1.7,0.4,3.6-0.2,5.2c-0.2,0.8-0.6,1.6-1,2.2c-0.2,0.4-0.4,0.6-0.7,0.9
					c-0.3,0.3-0.5,0.6-0.8,0.8c-1.1,1-2.5,1.7-3.9,2.2c-2.8,0.9-5.4,0.7-7.1,0.5c-1.8-0.3-3-0.5-3.4,0c-0.4,0.5-0.1,1.8,1.7,3.4
					c0.9,0.8,2.1,1.6,3.8,2.3c1.6,0.6,3.7,1.1,6,1.1c2.3,0,5.1-0.5,7.7-2c0.7-0.3,1.3-0.8,2-1.3c0.6-0.5,1.3-1,1.9-1.6
					c1.2-1.2,2.2-2.6,3.1-4.2c1.7-3.1,2.6-6.9,2.2-11c-0.2-2-0.7-4.2-1.7-6.3c-1-2.1-2.3-4-3.9-5.6c-3.2-3.2-7.2-5.3-11.2-6.6
					c-2-0.7-4.1-1.2-6.1-1.5c-2.1-0.4-4.2-0.5-6.4-0.6c-2.2-0.1-4.4,0.1-6.7,0.4c-2.3,0.3-4.6,0.9-6.8,1.6c-2.3,0.8-4.6,1.8-6.8,3.1
					c-2.2,1.4-4.3,3-6.1,4.9c-1.8,2-3.5,4.2-4.7,6.7c-1.2,2.4-2.1,5-2.7,7.6c-1.1,5.3-0.8,10.7,0.7,15.9c1.5,5.2,4.4,9.9,7.9,13.7
					c3.5,3.8,7.8,6.8,12.3,9c4.7,2.2,9.5,3.4,14.1,4c4.6,0.6,9,0.5,13.1,0c8.3-1,15.6-3.4,22.1-6.6c6.4-3.2,12-7.1,17-11.4
					c4.9-4.3,9.2-8.9,13.1-13.9c3.8-5,7.2-10.3,10.1-16c2.9-5.7,5.5-11.8,7.7-18.4c4.5-13.2,7.6-28.3,9.9-46.4
					c-3.1,18-7.1,32.8-12.3,45.4c-2.6,6.3-5.6,12.1-8.8,17.3c-3.3,5.3-6.9,10-10.9,14.4c-4,4.3-8.4,8.3-13.3,11.8
					c-4.9,3.5-10.2,6.5-16.1,8.7c-5.9,2.2-12.4,3.7-19.2,3.9c-3.4,0.1-6.9-0.1-10.4-0.9C13.3,336.4,9.9,335.2,6.8,333.4z"
                  />
                </g>
                <g>
                  <path
                    d="M46.6,281.2c-1.6-0.8-2.8-1.7-4-2.7c-1.1-1-2-2.1-2.5-3.1c-0.3-0.5-0.4-1-0.5-1.4c-0.1-0.4-0.1-0.8-0.1-1.2
					c0-0.8,0.3-1.7,0.8-2.7c0.3-0.5,0.6-1,0.9-1.5c0.4-0.5,0.7-0.9,1.1-1.2c0.8-0.8,1.8-1.4,2.6-1.7c0.4-0.2,0.8-0.3,1.2-0.4
					c0.4-0.1,0.7-0.1,1-0.1c0.3,0,0.6,0,0.9,0.1c0.3,0.1,0.6,0.2,1,0.4c0.7,0.3,1.3,0.9,1.7,1.5c0.5,0.6,0.8,1.2,1,1.8
					c0.4,1.3,0.2,2.9-0.2,4.3c-0.5,1.4-1.1,2.5-1.7,3.4c-0.5,0.9-0.9,1.6-0.7,2.3c0.2,0.6,0.9,1.1,2.3,1.2c1.4,0,3.5-0.5,5.6-2.2
					c1.1-0.8,2.1-1.9,3-3.4c0.9-1.4,1.6-3.3,1.9-5.4c0.6-4.3-0.9-9.3-4.7-13c-1-0.9-2.1-1.8-3.3-2.5c-1.3-0.7-2.7-1.3-4.2-1.6
					c-1.5-0.3-3-0.5-4.5-0.5c-1.5,0.1-3,0.3-4.4,0.7c-2.9,0.8-5.5,2.2-7.8,4c-1.2,0.9-2.3,2-3.2,3.2c-1,1.2-1.8,2.4-2.6,3.8
					c-1.5,2.7-2.5,6-2.5,9.6c0,3.6,1.2,7.2,2.9,10c1.7,2.9,4,5.1,6.4,6.8c1.2,0.9,2.5,1.6,3.8,2.3c1.3,0.7,2.7,1.2,4,1.6
					c5.5,1.8,10.7,2,15.4,1.5c2.3-0.3,4.5-0.7,6.6-1.3c2.1-0.6,4-1.3,5.8-2.2c7.3-3.4,12.6-8.1,17-13.2c4.4-5.2,7.9-10.9,11-17.6
					c3.2-6.7,5.9-14.3,9.1-23.4c-4,8.8-7.5,16.1-11.4,22.1c-3.9,6.1-8.1,10.9-12.8,14.9c-4.7,3.9-10,7-16.1,8.6
					c-1.5,0.4-3.1,0.7-4.7,0.9c-1.6,0.2-3.3,0.2-5,0.1C53.2,283.5,49.7,282.8,46.6,281.2z"
                  />
                </g>
                <g>
                  <path
                    d="M-32.4,315.2c-2-2.6-4.9-4.2-7.4-4.5c-2.5-0.4-4.6,0.4-5.3,2c-0.7,1.6-0.1,3.6,1.3,5.3c1.4,1.7,3.5,3.3,6.2,4.2
					c2.8,0.9,4.8,1.6,6.8,2.5c2,0.9,3.9,1.9,6.2,4c-1.5-2.8-2.6-4.7-3.6-6.8C-29.3,320-30.4,317.9-32.4,315.2z"
                  />
                </g>
                <g>
                  <path
                    d="M-34.7,295.5c0-2.7-0.4-4.9-1.3-6.9c-0.9-1.9-2.1-3.5-3.9-3.8c-1.7-0.3-3.6,1.1-4.5,3.8c-0.9,2.7-0.6,6.6,1.4,9.7
					c2.1,3.2,4,4.8,5.9,6.3c1.9,1.5,3.9,2.8,6.8,4.4c-2.2-2.4-3.1-4.5-3.7-6.6C-34.5,300.4-34.8,298.3-34.7,295.5z"
                  />
                </g>
                <g>
                  <path
                    d="M-26.6,282.8c1.3-2.3,2-4.4,2.3-6.5c0.2-2,0.1-4.1-1.2-5.3c-0.6-0.6-1.5-0.9-2.5-0.8c-0.5,0.1-1.1,0.2-1.6,0.5
					c-0.5,0.3-1.2,0.6-1.7,1.1c-2.3,1.9-4.1,5.5-3.9,9.3c0.3,3.9,1.2,6.3,2.3,8.6c1.1,2.2,2.3,4.4,4.3,7c-1.1-3.2-1-5.5-0.6-7.6
					C-28.7,287.1-27.9,285.2-26.6,282.8z"
                  />
                </g>
                <g>
                  <path
                    d="M-12.6,277.9c2.3-1.5,3.9-3.4,4.9-5.3c0.9-1.9,1.1-4,0.1-5.4c-1-1.4-3.2-1.8-5.6-0.9c-2.4,0.9-4.9,3.1-6.2,6.1
					c-1.3,3.1-1.8,5.4-2.3,7.6c-0.4,2.2-0.9,4.4-1.5,7.4c1.7-2.6,3.2-4.2,4.8-5.5C-16.7,280.6-15,279.4-12.6,277.9z"
                  />
                </g>
                <g>
                  <path
                    d="M4.7,276.2c2.7-0.3,4.9-1.1,6.6-2.4c1.7-1.3,2.9-2.9,2.7-4.7c-0.2-1.7-1.9-3.2-4.4-3.5c-2.5-0.4-5.9,0.4-8.5,2.6
					c-2.6,2.3-4,4.2-5.4,6c-1.3,1.9-2.6,3.7-4.3,6.3c2.5-1.9,4.5-2.7,6.5-3.3C-0.1,276.8,1.9,276.5,4.7,276.2z"
                  />
                </g>
                <g>
                  <path
                    d="M-32.6,331.5c-5.9-2.5-12.3-0.7-12.8,2.8c-0.4,3.5,5.4,6.9,11.7,5.9c3.2-0.5,5.5-1.1,7.8-1.5c1.2-0.2,2.2-0.5,3.4-0.5
					c0.6,0,1.2,0,1.8,0.2c0.6,0.2,1.3,0.6,1.7,1.3c-0.2-0.8-0.7-1.5-1.2-2c-0.6-0.5-1.2-0.9-1.8-1.2c-0.6-0.3-1.2-0.6-1.8-0.8
					c-0.6-0.2-1.1-0.5-1.6-0.7C-27.5,333.9-29.6,332.8-32.6,331.5z"
                  />
                </g>
                <g>
                  <path
                    d="M-26.1,344.6c-3.2,0.4-5.9,2.5-7,4.8c-1.2,2.2-0.8,4.4,0.5,5.5c1.4,1.1,3.3,1.1,5.1,0.8c1.8-0.4,3.4-1.3,4.9-3
					c1.6-1.7,3-2.9,4.6-3.9c1.6-1,3.4-1.7,6.2-1.9c-2.7-0.9-4.6-1.4-6.7-1.9C-20.6,344.6-22.8,344.2-26.1,344.6z"
                  />
                </g>
                <g>
                  <path
                    d="M-18.7,352.8c-2.9,1.5-4.7,4.4-5.1,6.8c-0.4,2.5,0.5,4.5,2.1,5.1c1.7,0.7,3.5,0.1,5.1-0.9c1.6-1,3-2.5,4-4.7
					c1-2.2,2-3.9,3.1-5.4c1.2-1.5,2.6-2.9,5.2-4.2c-2.9,0.2-5,0.5-7.1,0.9C-13.6,350.8-15.8,351.3-18.7,352.8z"
                  />
                </g>
                <g>
                  <path
                    d="M-9,360.7c-1.6,2.3-1.7,5.3-0.7,7.3c0.9,2,2.7,3,4.4,2.7c1.7-0.3,3.1-1.5,4-2.9c0.9-1.4,1.2-2.9,0.8-4.8
					c-0.4-2-0.6-3.6-0.5-5.2c0.1-1.6,0.4-3.3,1.7-5.4c-2,1.3-3.5,2.4-5,3.5C-5.8,357-7.4,358.3-9,360.7z"
                  />
                </g>
                <g>
                  <path
                    d="M3.3,368.7c0.3,1.5,1,2.7,2,3.7c1,0.9,2.1,1.5,3.2,1.7c1.1,0.2,2.1,0,2.9-0.4c0.8-0.4,1.3-1.1,1.6-1.9
					c0.6-1.7,0.4-3.4,0.1-4.6c-0.3-1.3-0.8-2.1-2.2-2.7c-1.4-0.7-2.5-1.6-3.3-2.7c-0.8-1.2-1.4-2.6-1.1-4.8c-1.3,1.8-2,3.4-2.6,5.1
					C3.3,363.7,2.7,365.7,3.3,368.7z"
                  />
                </g>
                <g>
                  <path
                    d="M21.2,371c2.3,2.1,5.5,2.7,7.9,2.1c2.4-0.6,3.8-2.2,3.7-3.9c0-1.8-1.2-3.3-2.6-4.4c-1.4-1.1-3.1-1.7-5.3-1.8
					c-2.3-0.1-4.1-0.3-5.8-0.8c-1.7-0.5-3.4-1.3-5.4-3.2c1.1,2.5,1.9,4.3,2.9,6.1C17.7,367,18.8,368.9,21.2,371z"
                  />
                </g>
                <g>
                  <path
                    d="M34.6,366c2.2,1.3,4.7,1.2,6.4,0.2c1.7-1,2.6-2.7,2.3-4.4c-0.3-1.7-1.4-3.1-2.7-4c-1.3-0.8-2.8-1.1-4.7-0.5
					c-1.9,0.6-3.3,1-4.8,1.3c-1.5,0.2-3.1,0.3-5.2-0.4c1.6,1.5,2.7,2.7,3.9,3.9C31.1,363.4,32.4,364.7,34.6,366z"
                  />
                </g>
                <g>
                  <path
                    d="M55.9,357.6c2.9,0.2,5.5-1,7-2.5c1.6-1.6,2.1-3.5,1.4-5.1c-0.7-1.6-2.5-2.5-4.5-2.7c-2-0.2-4.2,0.4-6.2,1.9
					c-2.1,1.5-3.6,2.7-5.2,3.7c-1.6,1-3.3,1.9-6,2.6c2.7,0.3,4.7,0.7,6.6,1.1C51,357,52.9,357.4,55.9,357.6z"
                  />
                </g>
                <g>
                  <path
                    d="M77.2,339.2c4.4-0.5,6.5-4.9,4.7-7.9c-1.8-3-6.6-3.2-9.2,0.4c-2.6,3.7-3.2,5.7-6.3,8.9
					C70.6,339.3,72.7,339.8,77.2,339.2z"
                  />
                </g>
                <g>
                  <path
                    d="M92.4,321c2-0.6,3.2-2.1,3.6-3.7c0.4-1.6-0.1-3.4-1.4-4.6c-1.3-1.2-3-1.6-4.5-1.3c-1.5,0.3-2.9,1.3-3.6,3.1
					c-1.4,3.7-1.5,5.7-3.9,8.9C86.3,321.9,88.3,322.2,92.4,321z"
                  />
                </g>
                <g>
                  <path
                    d="M59.8,305.3c-4.5,1.7-8.5,4.1-11.4,6.4c-2.8,2.3-4.5,4.7-3.8,6.3c0.7,1.6,3.6,2.1,7.4,1.3c3.8-0.8,8.5-3,12.3-6.5
					c3.9-3.5,6.3-6.2,8.8-8.9c2.4-2.7,4.8-5.4,8.3-9.3c-4.2,3.1-7.4,4.7-10.6,6.2C67.6,302.3,64.4,303.6,59.8,305.3z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M363.6,398.2c3.2,1.3,6.2,3.2,8.6,5.5c2.4,2.3,4.3,5,5.4,7.7c1.1,2.8,1.6,5.9,1.3,8.8c-0.1,1.4-0.4,2.8-0.9,4.1
					c-0.4,1.3-1.1,2.4-1.8,3.5c-3,4.3-8.8,7.2-14.8,8.1c-1.5,0.3-3,0.4-4.4,0.4c-1.5,0.1-2.9,0-4.3-0.1c-2.7-0.3-5-1-6.7-2
					c-0.8-0.5-1.5-1.1-2-1.7c-0.5-0.6-1-1.4-1.3-2.2c-0.6-1.7-0.8-3.6-0.5-5.2c0.1-0.8,0.4-1.6,0.7-2.3c0.1-0.4,0.4-0.7,0.5-1
					c0.2-0.3,0.4-0.6,0.7-0.9c1-1.2,2.2-2.1,3.6-2.7c2.6-1.2,5.2-1.4,7-1.4c1.8,0,3,0.1,3.4-0.4c0.4-0.5-0.2-1.8-2.1-3.2
					c-1-0.7-2.3-1.3-4-1.8c-1.7-0.4-3.8-0.6-6.1-0.3c-2.3,0.3-4.9,1.2-7.4,3c-0.6,0.4-1.2,1-1.8,1.5c-0.6,0.6-1.2,1.2-1.6,1.9
					c-1,1.3-1.9,2.9-2.5,4.5c-1.3,3.3-1.6,7.2-0.8,11.2c0.4,2,1.2,4.1,2.4,6c1.3,2,2.8,3.6,4.6,5c3.6,2.8,7.8,4.3,11.9,5.1
					c2.1,0.4,4.2,0.6,6.3,0.7c2.1,0.1,4.3,0,6.4-0.3c2.2-0.2,4.4-0.7,6.6-1.3c2.2-0.6,4.4-1.4,6.6-2.5c2.2-1.1,4.3-2.4,6.3-4
					c2-1.6,3.8-3.5,5.5-5.7c1.6-2.2,2.9-4.6,3.8-7.2c0.9-2.6,1.5-5.2,1.7-7.9c0.4-5.4-0.6-10.7-2.7-15.7c-2.2-5-5.6-9.2-9.6-12.6
					c-4-3.3-8.6-5.8-13.4-7.3c-5-1.6-9.9-2.2-14.5-2.1c-4.6,0-9,0.7-13,1.7c-8.1,2.1-15,5.4-21,9.4c-6,4-11,8.6-15.3,13.5
					c-4.3,4.9-8,10-11.1,15.5c-3.1,5.4-5.8,11.2-8,17.2c-2.2,6.1-3.9,12.4-5.3,19.3c-2.7,13.7-3.8,29-3.8,47.2
					c0.7-18.2,2.8-33.4,6.3-46.6c1.8-6.6,3.9-12.7,6.5-18.3c2.6-5.7,5.5-10.9,8.9-15.7c3.4-4.8,7.3-9.3,11.6-13.4
					c4.4-4.1,9.3-7.7,14.8-10.7c5.5-3,11.8-5.2,18.5-6.4c3.4-0.5,6.9-0.8,10.4-0.5C356.7,396.1,360.3,396.8,363.6,398.2z"
                  />
                </g>
                <g>
                  <path
                    d="M331,455.1c1.7,0.6,3,1.3,4.3,2.2c1.2,0.9,2.2,1.9,2.9,2.8c0.3,0.5,0.5,0.9,0.7,1.3c0.2,0.4,0.2,0.8,0.3,1.2
					c0.1,0.8,0,1.8-0.4,2.8c-0.2,0.5-0.4,1.1-0.7,1.6c-0.3,0.5-0.6,0.9-1,1.4c-0.7,0.9-1.6,1.6-2.4,2.1c-0.4,0.2-0.8,0.4-1.1,0.6
					c-0.4,0.1-0.7,0.2-1,0.3c-0.3,0-0.6,0.1-0.9,0c-0.3,0-0.7-0.1-1-0.2c-0.7-0.3-1.4-0.7-1.9-1.2c-0.5-0.5-1-1.1-1.2-1.7
					c-0.6-1.2-0.6-2.9-0.3-4.3c0.3-1.4,0.8-2.6,1.2-3.6c0.4-1,0.7-1.7,0.4-2.4c-0.2-0.6-1-1-2.5-0.9c-1.4,0.2-3.4,0.9-5.3,2.9
					c-0.9,1-1.9,2.2-2.6,3.7c-0.7,1.6-1.2,3.4-1.2,5.6c0,4.3,2.1,9.1,6.4,12.3c1.1,0.8,2.3,1.5,3.6,2.1c1.3,0.5,2.9,0.9,4.4,1.1
					c1.5,0.1,3,0.1,4.5-0.1c1.5-0.3,2.9-0.7,4.3-1.2c2.7-1.2,5.2-2.9,7.2-5c1-1.1,2-2.3,2.8-3.6c0.8-1.3,1.5-2.6,2-4.1
					c1.1-2.9,1.7-6.3,1.2-9.9c-0.5-3.6-2.1-7-4.2-9.5c-2.1-2.6-4.6-4.5-7.3-5.9c-1.3-0.7-2.7-1.3-4.1-1.8c-1.4-0.5-2.8-0.8-4.1-1.1
					c-5.7-1.1-10.9-0.6-15.4,0.5c-2.3,0.6-4.4,1.3-6.3,2.2c-2,0.9-3.8,1.8-5.5,2.9c-6.8,4.3-11.4,9.7-15.1,15.3
					c-3.7,5.7-6.4,11.9-8.7,18.9c-2.3,7-4,15-6,24.4c2.8-9.2,5.4-16.9,8.5-23.4c3.1-6.5,6.6-11.9,10.7-16.4c4.1-4.5,9-8.3,14.8-10.6
					c1.5-0.6,3-1.1,4.6-1.5c1.6-0.4,3.2-0.6,4.9-0.8C324,453.6,327.6,453.9,331,455.1z"
                  />
                </g>
                <g>
                  <path
                    d="M404.9,411.1c2.3,2.3,5.4,3.5,7.9,3.5c2.5,0,4.5-1,5-2.7c0.5-1.7-0.4-3.6-2-5.1c-1.6-1.6-3.9-2.8-6.7-3.3
					c-2.9-0.6-5-1-7-1.6c-2.1-0.6-4.1-1.4-6.7-3.2c1.9,2.5,3.2,4.4,4.4,6.3C401.2,406.8,402.5,408.7,404.9,411.1z"
                  />
                </g>
                <g>
                  <path
                    d="M409.7,430.4c0.3,2.7,1.1,4.8,2.2,6.6c1.1,1.8,2.6,3.2,4.3,3.3c1.7,0.1,3.4-1.5,4-4.4c0.6-2.8-0.2-6.6-2.6-9.4
					c-2.5-2.9-4.6-4.3-6.7-5.5c-2.1-1.2-4.2-2.3-7.3-3.5c2.5,2.1,3.7,4.1,4.5,6.1C408.9,425.6,409.4,427.6,409.7,430.4z"
                  />
                </g>
                <g>
                  <path
                    d="M403.3,444.1c-1,2.4-1.5,4.6-1.4,6.7c0,2,0.4,4,1.8,5.1c0.7,0.5,1.6,0.7,2.6,0.4c0.5-0.1,1.1-0.3,1.6-0.7
					c0.5-0.3,1.1-0.8,1.6-1.3c2-2.1,3.4-6,2.6-9.8c-0.8-3.8-2.1-6.1-3.4-8.2c-1.4-2.1-2.8-4-5.2-6.4c1.5,3,1.7,5.3,1.5,7.4
					C404.8,439.5,404.3,441.5,403.3,444.1z"
                  />
                </g>
                <g>
                  <path
                    d="M390,450.7c-2.1,1.7-3.5,3.8-4.1,5.9c-0.6,2-0.6,4.1,0.6,5.4c1.2,1.3,3.4,1.3,5.6,0.1c2.2-1.2,4.4-3.7,5.3-6.8
					c0.9-3.3,1.1-5.6,1.3-7.8c0.2-2.3,0.3-4.5,0.6-7.6c-1.3,2.8-2.6,4.5-4.1,6.1C393.8,447.5,392.2,448.9,390,450.7z"
                  />
                </g>
                <g>
                  <path
                    d="M373.1,454.6c-2.6,0.6-4.7,1.8-6.2,3.2c-1.5,1.5-2.5,3.3-2.1,5c0.4,1.7,2.3,2.9,4.9,2.9c2.6,0,5.8-1.2,8.1-3.7
					c2.3-2.6,3.4-4.6,4.5-6.7c1.1-2,2.1-4,3.4-6.8c-2.2,2.2-4.1,3.3-6,4.1C377.8,453.4,375.8,453.9,373.1,454.6z"
                  />
                </g>
                <g>
                  <path
                    d="M402.9,395c6.1,1.8,12.3-0.9,12.3-4.5c0-3.5-6.2-6.1-12.3-4.3c-3.2,0.9-5.3,1.8-7.5,2.5c-1.1,0.4-2.2,0.8-3.3,0.9
					c-0.6,0.1-1.2,0.1-1.8,0c-0.6-0.1-1.3-0.5-1.8-1.1c0.3,0.8,0.9,1.4,1.5,1.9c0.6,0.4,1.3,0.7,2,1c0.6,0.2,1.3,0.4,1.9,0.6
					c0.6,0.2,1.1,0.3,1.7,0.5C397.6,393.2,399.8,394.1,402.9,395z"
                  />
                </g>
                <g>
                  <path
                    d="M394.7,382.8c3.1-0.8,5.5-3.3,6.3-5.6c0.9-2.4,0.2-4.4-1.3-5.3c-1.5-0.9-3.4-0.7-5.1-0.1c-1.7,0.6-3.2,1.7-4.5,3.6
					c-1.4,1.9-2.6,3.3-4,4.4c-1.4,1.1-3.1,2.2-5.9,2.7c2.8,0.5,4.8,0.8,6.9,1C389.3,383.5,391.5,383.6,394.7,382.8z"
                  />
                </g>
                <g>
                  <path
                    d="M386.4,375.6c2.7-1.9,4.1-4.9,4.2-7.4c0.1-2.5-1.1-4.4-2.8-4.8c-1.7-0.4-3.5,0.4-5,1.5c-1.5,1.2-2.6,2.9-3.3,5.2
					c-0.7,2.4-1.5,4.1-2.4,5.8c-1,1.6-2.2,3.2-4.6,4.8c2.8-0.6,4.8-1.2,6.9-1.8C381.5,378.3,383.7,377.5,386.4,375.6z"
                  />
                </g>
                <g>
                  <path
                    d="M375.7,369.1c1.3-2.5,1-5.5-0.2-7.3c-1.2-1.9-3.1-2.6-4.7-2.1c-1.7,0.5-2.9,1.9-3.6,3.3c-0.7,1.5-0.8,3-0.2,4.9
					c0.6,1.9,1,3.5,1.2,5.1c0.1,1.6,0,3.3-1,5.5c1.8-1.6,3.2-2.8,4.5-4.1C373.1,373.1,374.4,371.7,375.7,369.1z"
                  />
                </g>
                <g>
                  <path
                    d="M362.5,362.8c-0.5-1.4-1.4-2.6-2.5-3.4c-1.1-0.8-2.3-1.2-3.4-1.3c-1.1-0.1-2.1,0.2-2.8,0.8c-0.7,0.5-1.2,1.3-1.4,2.1
					c-0.4,1.7,0.1,3.4,0.5,4.6c0.5,1.2,1.1,1.9,2.5,2.4c1.5,0.5,2.7,1.2,3.6,2.3c0.9,1,1.7,2.4,1.8,4.6c1-2,1.6-3.6,2-5.4
					C363.2,367.7,363.5,365.7,362.5,362.8z"
                  />
                </g>
                <g>
                  <path
                    d="M344.5,362.7c-2.6-1.8-5.9-2-8.1-1.1c-2.3,0.9-3.4,2.7-3.2,4.4c0.3,1.8,1.6,3.1,3.1,4c1.5,0.9,3.3,1.3,5.5,1.1
					c2.3-0.2,4.1-0.2,5.8,0c1.8,0.3,3.6,0.8,5.8,2.5c-1.4-2.3-2.5-4-3.7-5.7C348.5,366.3,347.1,364.6,344.5,362.7z"
                  />
                </g>
                <g>
                  <path
                    d="M331.8,369.5c-2.3-1-4.8-0.5-6.4,0.6c-1.6,1.2-2.2,3-1.8,4.7c0.5,1.7,1.8,2.9,3.2,3.6c1.4,0.6,3,0.7,4.7-0.1
					c1.8-0.8,3.2-1.5,4.6-1.9c1.5-0.4,3-0.7,5.2-0.3c-1.8-1.3-3.1-2.3-4.4-3.4C335.6,371.6,334.2,370.5,331.8,369.5z"
                  />
                </g>
                <g>
                  <path
                    d="M311.8,380.6c-2.9,0.2-5.3,1.7-6.6,3.4c-1.4,1.8-1.7,3.8-0.7,5.3c0.9,1.5,2.8,2.1,4.8,2c2-0.1,4.1-0.9,5.9-2.7
					c1.9-1.8,3.2-3.1,4.7-4.3c1.5-1.2,3-2.3,5.6-3.4c-2.8,0-4.7-0.1-6.7-0.3C316.8,380.6,314.7,380.4,311.8,380.6z"
                  />
                </g>
                <g>
                  <path
                    d="M293.1,401.5c-4.3,1.1-5.8,5.7-3.6,8.4c2.2,2.8,7,2.4,9-1.5c2.1-4,2.4-6.1,5.1-9.6C299.6,400.6,297.5,400.4,293.1,401.5
					z"
                  />
                </g>
                <g>
                  <path
                    d="M280.3,421.6c-1.9,0.9-2.9,2.5-3.1,4.2c-0.2,1.7,0.5,3.3,2,4.4c1.4,1,3.2,1.2,4.7,0.7c1.5-0.5,2.7-1.7,3.1-3.6
					c0.9-3.9,0.8-5.9,2.7-9.4C286.3,419.9,284.3,419.9,280.3,421.6z"
                  />
                </g>
                <g>
                  <path
                    d="M314.7,432.9c4.2-2.3,7.9-5.1,10.4-7.8c2.5-2.7,3.8-5.2,2.9-6.7c-0.9-1.5-3.8-1.6-7.5-0.3c-3.7,1.3-8,4.1-11.4,8
					c-3.5,4-5.5,7-7.5,10c-2,3-4,6-7,10.3c3.8-3.6,6.7-5.7,9.7-7.5C307.3,436.9,310.4,435.2,314.7,432.9z"
                  />
                </g>
              </g>
              <g>
                <path
                  d="M344.5,109.3c1.3-4.4,1.9-9.1,1.4-13.7c-0.4-4.6-1.8-9.3-4.3-13.4c-0.3-0.5-0.6-1-1-1.5c-0.4-0.5-0.7-1-1.1-1.5
				c-0.8-0.9-1.5-1.8-2.4-2.7c-1.7-1.7-3.6-3.1-5.6-4.3c-4-2.4-8.3-3.8-12.6-4.4c-4.3-0.6-8.7-0.6-12.9,0.4
				c-4.2,0.9-8.3,2.7-11.6,5.5c-3.3,2.7-5.9,6.2-7.2,10.2c-0.7,1.9-1.1,4.1-1.1,6.1c0,2,0.2,4,0.8,5.9c1.1,3.7,3.3,7,6.3,9.2
				c3,2.2,6.5,3.1,9.6,3c3.1-0.1,6.1-1.1,8.3-2.8c1.1-0.9,2-1.9,2.7-2.9c0.7-1.1,1.2-2.2,1.4-3.2c0.6-2.1,0.5-4.1,0.2-5.7
				c-0.3-1.6-1-2.9-1.6-3.8c-1.4-1.8-2.6-2.1-2.9-1.8c-0.3,0.3,0.2,1.3,0.6,2.9c0.2,0.8,0.3,1.8,0.2,3c-0.1,1.1-0.4,2.4-1,3.7
				c-0.3,0.6-0.7,1.2-1.2,1.7c-0.5,0.5-1.1,0.9-1.7,1.3c-1.3,0.7-3,1.1-4.8,0.9c-1.8-0.2-3.7-0.9-5-2.1c-1.4-1.3-2.5-3.2-2.9-5.3
				c-0.2-1.1-0.3-2.2-0.2-3.4c0.1-1.1,0.4-2.2,0.8-3.3c0.9-2.2,2.6-4.3,4.7-6c2.1-1.6,4.8-2.7,7.7-3.3c2.9-0.6,6.2-0.6,9.4-0.1
				c6.4,1,12.6,4.2,15.9,9.6c3.5,5.4,4.4,12.8,2.7,19.6c-0.4,1.7-1,3.4-1.8,5c-0.7,1.6-1.6,3.1-2.6,4.6c-2,2.9-4.2,5.4-6.7,7.6
				c-5,4.4-10.6,7.5-16.3,9.7c-5.7,2.3-11.4,3.6-17.1,4.6c-5.7,1-11.4,1.5-17.1,1.9c-5.7,0.4-11.5,0.5-17.5,0.9
				c-6,0.4-12.3,1.1-18.6,3c-3.2,0.9-6.4,2.1-9.5,3.8c-3.1,1.6-6.1,3.7-8.8,6.3c-2.7,2.6-5,5.8-6.7,9.4c-1.6,3.6-2.6,7.8-2.2,12.1
				c-0.2-4.3,0.9-8.4,2.6-12c1.8-3.5,4.1-6.6,6.9-9c2.7-2.4,5.7-4.3,8.8-5.8c3.1-1.4,6.2-2.5,9.4-3.2c6.3-1.5,12.4-1.9,18.3-1.9
				c5.9-0.1,11.7,0.2,17.5,0.2c5.8,0,11.6-0.2,17.6-0.8c6-0.7,12.2-1.7,18.5-3.8c6.3-2,12.9-5,19-9.8c3.1-2.4,6-5.2,8.6-8.6
				C340.9,117.8,343.1,113.9,344.5,109.3z"
                />
              </g>
              <g>
                <path
                  d="M278.5,96c-3.9-1.2-8.4-1.3-12.5,0.5c-2,0.9-3.8,2.1-5.3,3.7c-1.5,1.5-2.7,3.3-3.6,5.3c-0.8,2-1.2,4.2-1.1,6.4
				c0.1,2.2,0.7,4.2,1.6,6c1.8,3.5,4.9,6.1,8.4,7c3.5,1,6.9,0.3,9.4-1.4c1.2-0.8,2.2-1.9,2.8-3c0.6-1.1,0.9-2.2,1-3.1
				c0.2-1.9-0.3-3.4-0.9-4.2c-0.6-0.8-1.2-1-1.6-0.9c-0.8,0.3-0.9,2.1-2.2,3.6c-0.3,0.4-0.7,0.8-1.1,1c-0.4,0.3-0.8,0.4-1.3,0.5
				c-1,0.2-2.4,0.1-3.4-0.5c-1.1-0.6-2.2-1.8-2.7-3.2c-0.6-1.5-0.5-2.8,0.1-4.4c0.7-1.6,2.1-3.1,3.8-4c1.7-0.9,3.9-1.2,6.3-0.9
				c4.9,0.7,8.5,4.3,10.6,8.1c2.2,3.9,3,8.1,2.7,12.3c-0.2,2.1-0.6,4.2-1.5,6.2c-0.4,1-1,2-1.6,3c-0.7,0.9-1.4,1.8-2.4,2.7
				c-1.8,1.6-4.1,3-6.9,3.8c-2.7,0.7-6,0.9-9.2-0.3c3.1,1.4,6.5,1.6,9.4,1.1c3-0.5,5.6-1.7,7.8-3.3c1.1-0.8,2.1-1.7,3-2.6
				c0.9-1,1.7-2,2.3-3.1c1.4-2.1,2.2-4.5,2.9-6.9c1.1-4.8,1-10-0.9-15.4c-1-2.7-2.4-5.4-4.7-8C285.7,99.6,282.5,97.2,278.5,96z"
                />
              </g>
              <g>
                <path
                  d="M315.1,122.6c2.7-2.4,4.1-5.8,4.2-8.5c0.1-2.8-0.9-4.8-2.6-5.3c-1.7-0.5-3.6,0.5-5.2,2.2c-1.6,1.7-2.9,3.9-3.7,6.8
				c-0.8,2.9-1.5,5-2.5,7.1c-1,2-2.1,4-4.5,6.4c3-1.5,5.1-2.6,7.3-3.8C310.2,126.3,312.4,125.1,315.1,122.6z"
                />
              </g>
              <g>
                <path
                  d="M366,89.8c2.5-1.7,4.3-3.8,5.4-5.9c1.1-2.1,1.5-4.2,0.4-5.7c-1-1.4-3.3-1.7-5.9-0.7c-2.6,1.1-5.4,3.6-6.8,6.9
				c-1.5,3.5-2,6-2.5,8.4c-0.4,2.5-0.8,4.9-1.4,8.3c1.5-3.1,3.1-4.9,4.7-6.6C361.7,93,363.5,91.6,366,89.8z"
                />
              </g>
              <g>
                <path
                  d="M379.9,95.6c3-0.8,5.4-2.4,6.9-4.3c1.5-1.8,2.2-3.9,1.4-5.5c-0.8-1.6-2.9-2.2-5.4-1.9c-2.5,0.3-5.4,1.7-7.6,4.1
				c-2.3,2.5-3.6,4.4-5,6.2c-1.4,1.8-2.8,3.6-5.1,6c2.9-1.5,5.1-2.3,7.2-2.9C374.6,96.9,376.8,96.4,379.9,95.6z"
                />
              </g>
              <g>
                <path
                  d="M390.9,105.2c3.1,0.2,5.9-0.4,8-1.5c2.1-1.1,3.7-2.6,3.6-4.4c0-1.7-1.7-3.3-4.4-4c-2.6-0.7-6.2-0.4-9.3,1.3
				c-3.2,1.7-5.2,3.3-7.1,4.7c-1.9,1.5-3.8,3-6.6,5c3.3-1.1,5.6-1.4,7.9-1.5C385.4,104.9,387.7,105,390.9,105.2z"
                />
              </g>
              <g>
                <path
                  d="M360.3,116.8c2.7-0.8,4.8-2.3,6.1-4c1.3-1.7,2-3.7,1.2-5.3c-0.7-1.6-2.7-2.4-5.1-2.1c-2.4,0.3-5.2,1.7-7.2,4.2
				c-2,2.5-3.1,4.5-4.2,6.4c-1.1,1.9-2.2,3.8-3.9,6.3c2.5-1.8,4.4-2.7,6.3-3.5C355.5,118.1,357.5,117.6,360.3,116.8z"
                />
              </g>
              <g>
                <path
                  d="M373.4,121.3c2.6,0.1,4.9-0.6,6.6-1.8c1.7-1.2,2.8-2.9,2.6-4.6c-0.2-1.7-1.8-3.1-4.1-3.6c-2.3-0.5-5.2,0-7.7,1.7
				c-2.5,1.8-4.1,3.3-5.6,4.7c-1.5,1.5-3,2.9-5.3,4.7c2.8-0.9,4.8-1.1,6.7-1.2C368.7,121,370.7,121.2,373.4,121.3z"
                />
              </g>
              <g>
                <path
                  d="M293.2,147.9c-6.4,0.5-11.3,5.2-10.1,8.5c1.2,3.3,8,3.6,13.2-0.3c5.3-4,7-6.2,12.8-9.5
				C302.5,148,299.8,147.4,293.2,147.9z"
                />
              </g>
              <g>
                <path
                  d="M300.9,162.8c-1.7,2.4-2,5.5-1.2,7.6c0.7,2.2,2.4,3.5,4.1,3.4c1.8-0.1,3.2-1.3,4.2-2.8c1-1.5,1.5-3.4,1.3-5.6
				c-0.2-2.3-0.3-4.1-0.2-5.9c0.2-1.8,0.5-3.6,1.8-5.9c-2.1,1.6-3.6,2.8-5.1,4.1C304.2,159,302.7,160.4,300.9,162.8z"
                />
              </g>
              <g>
                <path
                  d="M250.7,158.6c5.2-0.2,10.2-0.8,14.1-1.6c3.9-0.8,6.7-2,7-3.7c0.2-1.7-2.4-3.7-6.9-4.6c-4.4-1-10.5-0.7-16.1,1.4
				c-5.7,2.2-9.2,4.4-12.7,6.7c-3.4,2.3-6.7,4.7-11.2,8.5c5.1-2.9,9-4.2,12.8-5.1C241.5,159.3,245.3,158.8,250.7,158.6z"
                />
              </g>
              <g>
                <path
                  d="M452.7,61.6c-0.5-3.2-1.3-6.4-2.5-9.5c-1.2-3.1-2.9-6.2-5.1-8.9c-2.2-2.7-5-5-8.1-6.7c-3.1-1.7-6.7-2.7-10.3-2.8l-1.4,0
				l-1.4,0.1c-0.9,0.1-1.8,0.3-2.7,0.5c-1.8,0.5-3.4,1.1-4.9,2c-3,1.7-5.6,4.2-7.3,7.3c-1.7,3-2.4,6.8-1.9,10
				c0.2,1.6,0.7,3.2,1.3,4.6c0.6,1.4,1.4,2.7,2.2,3.8c3.4,4.5,8.5,7,13.1,7.2c2.3,0.1,4.6-0.3,6.5-1.3c1-0.5,1.8-1.1,2.5-1.8
				c0.7-0.7,1.2-1.5,1.7-2.3c1.5-3.2,1-6,0-7.5c-0.5-0.8-1-1.2-1.4-1.4c-0.4-0.2-0.7-0.2-0.9,0c-0.3,0.3-0.1,1.1-0.1,2.2
				c0,0.6-0.1,1.2-0.4,1.9c-0.3,0.7-0.7,1.5-1.3,2.1c-0.3,0.3-0.7,0.6-1,0.8c-0.3,0.2-0.8,0.4-1.3,0.5c-1,0.2-2.2,0.2-3.5-0.1
				c-2.6-0.6-5.4-2.4-7.1-5.2c-0.4-0.7-0.8-1.4-1-2.2c-0.2-0.8-0.4-1.5-0.4-2.3c-0.1-0.8,0-1.5,0.2-2.2c0.2-0.7,0.5-1.4,0.9-2.1
				c0.8-1.4,2.2-2.6,3.9-3.5c0.8-0.4,1.7-0.8,2.6-1c0.4-0.1,0.9-0.2,1.4-0.2l0.7,0l0.7,0c1.9,0.1,4,0.6,5.9,1.7
				c1.9,1,3.8,2.5,5.3,4.2c3.1,3.5,5.2,8.4,6.4,13.7c1.2,5.5,1.6,10.9,1.4,15.9c-0.2,5-0.9,9.7-1.9,14.1c-2.1,8.8-5.4,16.4-9.6,23.5
				c-4.2,7.1-9.5,13.8-16.3,20.4c-6.8,6.6-15.2,13.2-26.1,19.6c11.2-6,20-12.1,27.3-18.3c7.3-6.2,13.3-12.6,18.3-19.6
				c5-7,9.2-14.7,12.3-23.9c1.5-4.6,2.7-9.6,3.4-15.1C453.5,74.1,453.6,68.1,452.7,61.6z"
                />
              </g>
              <g>
                <path
                  d="M420.9,70.1c-1.7-0.9-3.7-1.5-5.7-1.6c-2.1-0.2-4.2,0.2-6.2,0.9c-1.9,0.7-3.7,1.8-5.2,3.1c-1.5,1.4-2.7,3-3.5,5
				c-0.8,1.9-1.2,4.1-1.1,6c0.1,2,0.6,3.8,1.4,5.5c1.6,3.3,4.3,5.8,7.4,6.9c1.6,0.6,3.2,0.8,4.6,0.6c1.4-0.1,2.7-0.5,3.8-0.9
				c2.2-1,3.7-2.4,4.6-3.8c0.9-1.4,1.2-2.7,1.1-3.6c-0.1-0.9-0.4-1.4-0.8-1.5c-0.9-0.2-1.9,0.9-3.8,1.6c-0.9,0.4-2,0.7-3.2,0.8
				c-1.2,0-2.4-0.2-3.2-0.9c-0.9-0.6-1.7-1.7-2.1-3c-0.2-0.6-0.3-1.3-0.3-2c0-0.6,0.2-1.2,0.4-1.8c0.5-1.2,1.7-2.4,3.2-3
				c0.7-0.3,1.5-0.5,2.2-0.6c0.8,0,1.7,0.1,2.6,0.3c1.9,0.6,3.8,1.9,5.4,3.4c0.8,0.8,1.5,1.6,2.2,2.4c0.7,0.8,1.3,1.7,1.9,2.6
				c2.3,3.5,3.9,7.1,5,10.9c1.1,3.8,1.7,7.8,1.5,12.4c0,0.6-0.1,1.1-0.1,1.7c0,0.6-0.1,1.2-0.2,1.8c-0.1,1.2-0.4,2.4-0.6,3.7
				c-0.6,2.5-1.4,5.2-2.7,8c1.5-2.7,2.7-5.3,3.5-7.8c0.4-1.3,0.8-2.5,1.1-3.7c0.1-0.6,0.3-1.2,0.4-1.8c0.1-0.6,0.2-1.2,0.3-1.8
				c0.8-4.7,0.9-9,0.5-13.3c-0.5-4.3-1.4-8.6-3.3-13.1c-0.5-1.1-1-2.3-1.7-3.4c-0.6-1.2-1.4-2.4-2.2-3.5
				C426.7,74.4,424.4,72,420.9,70.1z"
                />
              </g>
              <g>
                <g>
                  <path
                    d="M503.8,68.5c5.7-0.7,11.4-1.1,16.9-1.7c5.5-0.5,10.7-1.1,15.1-2c4.5-0.8,8.2-1.9,10.7-2.9c2.5-1.1,3.9-2.2,3.7-3
					c-0.2-0.9-1.8-1.4-4.4-1.8c-2.6-0.4-6.3-0.5-10.8-0.6c-4.4-0.1-9.6,0-15.3,0.4c-5.6,0.4-11.8,1.3-17.7,2.9
					c-6.2,1.7-11.2,3.7-15.6,6c-4.3,2.3-8,4.8-11.3,7.8c-0.8,0.7-1.6,1.5-2.4,2.3c-0.8,0.8-1.5,1.6-2.3,2.5
					c-1.5,1.7-2.9,3.6-4.2,5.6c-2.6,4.1-4.9,9.2-5.6,15.5c1.2-6.2,4.1-10.9,7-14.5c1.5-1.8,3.1-3.4,4.7-4.7c0.8-0.7,1.7-1.3,2.5-2
					c0.9-0.6,1.7-1.2,2.6-1.7c3.5-2.2,7.2-3.8,11.4-5.1C493.2,70.2,498,69.2,503.8,68.5z"
                  />
                </g>
                <g>
                  <path
                    d="M512,85.7c5.6-1.4,11.1-3.6,15.9-6.1c4.8-2.5,9.1-5.5,12.4-8.3c6.7-5.7,9.8-10.9,8.6-12.1c-1.2-1.3-6.2,1.4-13.3,5.2
					c-7.1,3.9-16.5,8.8-26.3,13c-5.1,2.2-9.5,3.7-13.6,4.9c-4.1,1.2-8,2.1-11.9,3.3c-3.9,1.1-7.8,2.4-11.9,4.4c-2,1-4.2,2.2-6.2,3.8
					c-2.1,1.5-4.1,3.5-5.8,5.9c1.9-2.2,4.1-3.9,6.3-5.2c2.2-1.3,4.3-2.2,6.4-2.9c4.2-1.4,8.1-1.9,12-2.3c3.9-0.4,7.8-0.5,12.3-1
					C501.2,87.9,506.2,87.1,512,85.7z"
                  />
                </g>
                <g>
                  <path
                    d="M519.6,76.1c6.3-1,12.2-3.4,16.2-6c4.1-2.6,6.3-5.3,5.6-6.9c-0.7-1.6-4.1-1.8-8.7-0.9c-4.5,0.9-10.2,2.7-15.7,5.4
					c-5.7,2.7-9.6,4.6-13.6,6.3c-4.1,1.7-8.3,3.3-14.6,4.9c6.6-0.6,11-0.9,15.5-1.2C508.7,77.4,513.2,77.1,519.6,76.1z"
                  />
                </g>
                <g>
                  <path
                    d="M517.7,72.2c4.9-0.5,9.4-2.3,12.5-4.4c3.1-2.1,4.8-4.4,4.1-6c-0.7-1.6-3.4-2.2-7-1.8c-3.6,0.4-8,1.7-12.2,3.8
					c-4.3,2.3-7.3,3.9-10.4,5.4c-3.1,1.5-6.2,2.9-11.1,4.4c5-0.4,8.5-0.6,12-0.7C509.2,72.8,512.7,72.7,517.7,72.2z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M490.4,213.7c-5.6-1.1-11.2-2.4-16.6-3.5c-5.4-1.2-10.5-2.2-15-2.7c-4.5-0.6-8.4-0.7-11.1-0.5c-2.7,0.3-4.3,0.9-4.5,1.8
					c-0.1,0.9,1.2,1.9,3.6,3c2.4,1.1,5.9,2.4,10.1,3.8c4.2,1.4,9.2,2.9,14.7,4.2c5.5,1.3,11.6,2.3,17.8,2.6c6.4,0.2,11.8-0.1,16.7-1
					c4.8-0.9,9.1-2.1,13.1-4c1-0.4,2-1,3-1.4c1-0.5,2-1.1,2.9-1.7c1.9-1.2,3.8-2.5,5.7-4.1c3.7-3.1,7.4-7.3,10-13.1
					c-3.1,5.6-7.2,9.1-11.1,11.7c-2,1.3-3.9,2.3-5.9,3.1c-1,0.4-2,0.8-2.9,1.1c-1,0.3-2,0.6-3,0.8c-3.9,1-8,1.4-12.4,1.4
					C501.1,215.3,496.2,214.8,490.4,213.7z"
                  />
                </g>
                <g>
                  <path
                    d="M487.9,194.8c-5.8-0.4-11.6,0.1-17,1c-5.4,1-10.3,2.5-14.3,4.2c-8.1,3.4-12.6,7.4-11.8,8.9c0.8,1.6,6.3,0.6,14.3-0.9
					c8-1.5,18.4-3.4,29-4.4c5.5-0.6,10.2-0.7,14.5-0.6c4.3,0.1,8.3,0.4,12.3,0.5c4,0.1,8.2,0.1,12.7-0.6c2.3-0.4,4.6-0.9,7.1-1.7
					c2.4-0.9,4.9-2.1,7.3-3.9c-2.5,1.6-5.1,2.5-7.5,3.1c-2.4,0.6-4.8,0.8-7,0.8c-4.4,0-8.3-0.6-12.1-1.4c-3.8-0.8-7.6-1.9-12-2.8
					C498.8,196,493.9,195.2,487.9,194.8z"
                  />
                </g>
                <g>
                  <path
                    d="M477.7,201.6c-6.3-0.9-12.6-0.4-17.3,0.8c-4.7,1.2-7.6,3.1-7.4,4.9c0.2,1.8,3.4,2.9,8,3.5c4.6,0.5,10.6,0.5,16.6-0.4
					c6.2-0.9,10.5-1.5,14.9-1.9c4.4-0.4,8.9-0.6,15.4-0.3c-6.4-1.4-10.8-2.5-15.2-3.5C488.5,203.7,484.2,202.6,477.7,201.6z"
                  />
                </g>
                <g>
                  <path
                    d="M478.3,205.9c-4.8-1-9.7-0.7-13.3,0.4c-3.6,1-5.9,2.8-5.8,4.5c0.1,1.8,2.6,3.1,6.1,3.9c3.5,0.7,8.1,0.8,12.8,0
					c4.8-0.8,8.1-1.5,11.5-2c3.4-0.5,6.8-0.9,11.9-0.8c-4.9-1.1-8.3-2-11.6-3C486.6,207.9,483.3,206.9,478.3,205.9z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M295.7,319.1c4.1-3.9,8.4-7.8,12.5-11.5c4.1-3.7,7.9-7.3,10.9-10.7c3.1-3.3,5.4-6.4,6.8-8.8c1.4-2.4,1.8-4.1,1.2-4.7
					c-0.6-0.6-2.3-0.1-4.6,1.2c-2.3,1.3-5.4,3.4-9,6c-3.6,2.6-7.7,5.8-12,9.5c-4.2,3.7-8.6,8.1-12.4,13c-3.9,5.1-6.7,9.7-8.9,14.2
					c-2.1,4.4-3.5,8.7-4.4,13c-0.2,1.1-0.4,2.2-0.6,3.3c-0.1,1.1-0.3,2.2-0.3,3.3c-0.2,2.3-0.2,4.6,0,7c0.4,4.8,1.6,10.3,4.8,15.8
					c-2.8-5.7-3.3-11.1-3.1-15.8c0.1-2.3,0.4-4.5,0.9-6.6c0.2-1,0.5-2,0.8-3c0.3-1,0.6-2,1-2.9c1.5-3.8,3.5-7.3,6.1-11
					C288.2,326.9,291.4,323.2,295.7,319.1z"
                  />
                </g>
                <g>
                  <path
                    d="M312.5,328c3.7-4.5,6.7-9.5,9-14.5c2.3-4.9,4-9.8,4.9-14.1c1.9-8.6,1.3-14.5-0.4-14.8c-1.8-0.3-4.2,4.8-7.5,12.2
					c-3.4,7.4-7.9,16.9-13.3,26.2c-2.7,4.8-5.4,8.7-7.9,12.1c-2.6,3.4-5.1,6.5-7.5,9.7c-2.4,3.2-4.8,6.6-6.9,10.7
					c-1,2.1-2,4.3-2.7,6.8c-0.7,2.5-1.2,5.2-1.1,8.2c0.2-3,0.9-5.6,1.9-7.9c1-2.3,2.2-4.3,3.4-6.1c2.5-3.6,5.3-6.4,8.2-9
					c2.9-2.7,5.9-5.1,9.3-8.2C305.2,336.2,308.7,332.7,312.5,328z"
                  />
                </g>
                <g>
                  <path
                    d="M312.9,315.8c4.4-4.6,7.7-10,9.4-14.5c1.7-4.5,1.8-8,0.3-8.8c-1.6-0.9-4.4,1.1-7.5,4.5c-3.1,3.4-6.5,8.3-9.3,13.8
					c-2.9,5.6-4.9,9.4-7.1,13.3c-2.2,3.8-4.6,7.6-8.7,12.7c4.9-4.4,8.3-7.4,11.7-10.3C305,323.4,308.3,320.5,312.9,315.8z"
                  />
                </g>
                <g>
                  <path
                    d="M309,313.8c3.6-3.4,6.2-7.5,7.4-11c1.2-3.5,1.2-6.4-0.3-7.3c-1.5-0.9-4,0.3-6.7,2.7c-2.6,2.5-5.4,6.1-7.4,10.4
					c-2.1,4.4-3.5,7.5-5,10.5c-1.6,3-3.3,6.1-6.2,10.2c3.8-3.4,6.5-5.6,9.2-7.8C302.6,319.3,305.3,317.2,309,313.8z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M112.7,104.3c4.4,3.7,8.6,7.6,12.7,11.2c4.1,3.6,8.1,7,11.7,9.8c3.6,2.7,6.9,4.7,9.5,5.9c2.5,1.1,4.2,1.4,4.7,0.7
					c0.5-0.7-0.2-2.2-1.7-4.4c-1.5-2.2-3.9-5-6.9-8.3c-3-3.3-6.6-7.1-10.7-10.9c-4.2-3.8-8.9-7.7-14.2-11c-5.4-3.3-10.4-5.7-15-7.3
					c-4.6-1.6-9-2.6-13.4-3c-1.1-0.1-2.2-0.1-3.3-0.2c-1.1,0-2.2,0-3.4,0c-2.3,0.1-4.6,0.3-7,0.8c-4.8,0.9-10.1,2.7-15.2,6.4
					c5.4-3.3,10.7-4.4,15.4-4.7c2.3-0.1,4.6,0,6.7,0.2c1.1,0.1,2.1,0.3,3.1,0.5c1,0.2,2,0.4,3,0.7c3.9,1.1,7.6,2.7,11.5,4.9
					C104.2,97.6,108.2,100.5,112.7,104.3z"
                  />
                </g>
                <g>
                  <path
                    d="M105.7,121.9c4.9,3.2,10.2,5.7,15.3,7.5c5.2,1.8,10.2,2.9,14.5,3.4c8.7,1,14.6-0.3,14.7-2c0.1-1.8-5.2-3.6-12.9-6.2
					c-7.7-2.6-17.7-6.1-27.5-10.4c-5.1-2.2-9.2-4.4-12.9-6.6c-3.7-2.2-7-4.4-10.5-6.5C83,99,79.4,97,75.1,95.4c-2.1-0.8-4.5-1.5-7-2
					c-2.5-0.5-5.3-0.6-8.3-0.2c3-0.1,5.7,0.3,8.1,1c2.4,0.7,4.5,1.7,6.5,2.7c3.9,2.1,6.9,4.6,9.8,7.2c2.9,2.6,5.7,5.4,9.1,8.4
					C96.7,115.5,100.6,118.7,105.7,121.9z"
                  />
                </g>
                <g>
                  <path
                    d="M117.9,121c5,3.9,10.8,6.6,15.4,7.8c4.7,1.2,8.1,1,8.8-0.6c0.7-1.6-1.5-4.2-5.3-7c-3.7-2.7-9-5.6-14.7-7.8
					c-5.8-2.3-9.9-3.9-13.9-5.7c-4-1.8-8-3.8-13.6-7.4c4.9,4.4,8.2,7.5,11.5,10.5C109.5,113.9,112.7,117,117.9,121z"
                  />
                </g>
                <g>
                  <path
                    d="M119.5,117c3.7,3.2,8.1,5.3,11.7,6.2c3.6,0.9,6.5,0.5,7.2-1.1c0.8-1.6-0.7-4-3.4-6.4c-2.7-2.4-6.7-4.7-11.1-6.3
					c-4.6-1.6-7.8-2.7-11-3.9c-3.2-1.2-6.4-2.6-10.8-5.1c3.8,3.4,6.2,5.8,8.7,8.3C113.2,111.2,115.6,113.7,119.5,117z"
                  />
                </g>
              </g>
              <g>
                <path
                  d="M46.9,120.2c-4.8-3.3-10.4-5.5-16.3-6.1c-2.9-0.3-5.9-0.2-8.8,0.3c-2.9,0.5-5.8,1.4-8.4,2.9c-2.6,1.5-5.1,3.5-6.9,6.1
				c-0.5,0.7-0.9,1.3-1.2,2c-0.3,0.7-0.7,1.5-0.9,2.2L4,128.7l-0.2,1.1c-0.2,0.8-0.2,1.5-0.3,2.3c-0.1,3,0.5,5.8,1.6,8.3
				c1.1,2.5,2.6,4.6,4.5,6.3c1.8,1.7,4,3,6.2,3.6c2.1,0.7,4.2,1,6.1,0.9c1.9-0.1,3.6-0.4,5.2-0.9c1.5-0.5,2.8-1.1,3.9-1.8
				c2.2-1.3,3.6-2.9,4.3-4c0.7-1.2,0.8-2,0.5-2.2c-0.3-0.3-1.1-0.1-2.2,0.3c-1.1,0.4-2.6,1.1-4.6,1.5c-1,0.2-2,0.4-3.2,0.5
				c-1.1,0.1-2.4,0-3.6-0.2c-1.2-0.2-2.5-0.6-3.6-1.2c-1.2-0.6-2.1-1.4-3-2.4c-1.8-2.1-2.9-5.2-2.6-8.1c0.1-0.3,0.1-0.7,0.2-1
				l0.1-0.5l0.2-0.5c0.1-0.3,0.3-0.6,0.4-0.9c0.2-0.3,0.4-0.6,0.6-0.9c0.9-1.2,2.2-2.2,3.7-3.1c1.6-0.9,3.4-1.5,5.4-1.8
				c2-0.4,4.1-0.5,6.2-0.3c4.2,0.3,8.6,1.6,12.4,3.9c4,2.3,7.2,5.4,9.7,8.7c2.5,3.3,4.3,6.9,5.7,10.4c2.6,7.1,3.3,14.2,2.9,21.3
				c-0.4,7.1-1.8,14.3-4.7,22c-3,7.6-7.4,15.8-14.9,23.8c7.8-7.7,12.8-15.6,16.5-23.2c3.6-7.6,5.7-14.8,6.9-22.3
				c0.6-3.7,0.9-7.5,0.8-11.4c-0.1-3.9-0.5-8-1.6-12.1c-1.1-4.2-2.8-8.5-5.4-12.7C55.4,127.8,51.9,123.6,46.9,120.2z"
                />
              </g>
              <g>
                <path
                  d="M34,153.7c-3,0.6-5.5,2.1-7.5,4c-2,1.9-3.5,4.3-4.3,7c-0.4,1.3-0.6,2.7-0.6,4.1c0,1.4,0.2,2.8,0.7,4.1
				c0.8,2.5,2.4,4.7,4.4,6.1c1,0.7,2,1.2,3.1,1.5c1.1,0.3,2.1,0.4,3,0.4c1.9-0.1,3.4-0.7,4.4-1.5c1-0.8,1.6-1.6,1.8-2.3
				c0.2-0.7,0-1.2-0.3-1.5c-0.7-0.6-1.9-0.4-3.2-0.7c-0.7-0.1-1.4-0.4-2-0.7c-0.3-0.2-0.6-0.4-0.8-0.6c-0.2-0.2-0.4-0.5-0.6-0.8
				c-0.4-0.6-0.7-1.4-0.8-2.3c-0.1-0.4-0.1-0.9,0-1.3c0-0.5,0.2-1,0.3-1.5c0.6-2.1,2.6-4.5,4.7-5.5c1.1-0.5,2.1-0.7,3.3-0.5
				c1.2,0.2,2.3,0.8,3.3,1.6c1,0.8,1.8,1.7,2.5,2.8c0.7,1.1,1.2,2.3,1.6,3.6c0.8,2.6,1,5.5,0.9,8.8c-0.1,3.3-0.7,7-0.7,11.4
				c0.6-4.4,1.7-7.9,2.4-11.2c0.8-3.3,1.3-6.4,1.2-9.6c-0.1-1.6-0.3-3.3-0.8-4.9c-0.5-1.7-1.3-3.4-2.5-5c-1.2-1.6-2.8-3.2-5.1-4.3
				c-1.1-0.6-2.4-1-3.8-1.2C37.1,153.3,35.5,153.4,34,153.7z"
                />
              </g>
              <g>
                <path
                  d="M80.9,129c2-0.8,3.3-2.1,4-3.7c0.7-1.6,0.9-3.4,0.1-5c-0.8-1.5-2.7-2.3-4.8-1.9c-2.1,0.4-4.4,2-5.6,4.5
				c-1.2,2.6-1.6,4.5-1.9,6.4c-0.3,1.9-0.7,3.6-1.1,6.1c1.5-2,2.9-3.1,4.3-4C77.3,130.6,78.8,129.9,80.9,129z"
                />
              </g>
              <g>
                <path
                  d="M87.6,140.1c1.9,0,3.2-0.8,4.1-2.1c0.9-1.2,1.4-2.9,1-4.7c-0.4-1.7-1.8-3-3.7-3.3c-1.9-0.3-4.2,0.4-5.7,2.3
				c-1.5,2-2.3,3.5-3,5c-0.7,1.5-1.3,2.9-2.3,4.8c1.8-1.2,3.2-1.6,4.7-1.9C84.1,140.2,85.6,140.1,87.6,140.1z"
                />
              </g>
              <g>
                <path
                  d="M69.7,113.9c0.7-3.1,0.1-6.2-1.2-8.3c-1.3-2.1-3.2-3.1-4.9-2.7c-1.7,0.5-2.8,2.2-3.3,4.3c-0.5,2.1-0.3,4.6,0.7,7.2
				c1.1,2.6,1.8,4.6,2.5,6.6c0.6,2,1,4.1,1,7.1c1.1-2.9,1.9-4.9,2.8-6.9C68.1,119.2,69,117.1,69.7,113.9z"
                />
              </g>
              <g>
                <path d="M51.6,99.8c-0.9-5.8-6-9.3-9.1-7.5c-3,1.8-2.7,7.7,1.3,11.5c4.1,4,6.3,5.3,9.7,10.1C52.1,108.1,52.6,105.7,51.6,99.8z" />
              </g>
              <g>
                <path
                  d="M25.9,96.2c-2.9-2.2-6.5-3.1-9.2-2.9c-2.8,0.2-4.8,1.4-5.1,3.1c-0.3,1.7,0.9,3.6,3,5c2,1.5,4.9,2.6,8.1,2.9
				c3.3,0.4,5.7,0.6,8.1,1.1c2.4,0.5,4.7,1.1,7.9,2.7c-2.6-2.5-4.3-4.2-6.1-6C30.7,100.2,28.9,98.4,25.9,96.2z"
                />
              </g>
              <g>
                <path
                  d="M-1.6,103.7c-2.7-0.3-5.2,0.9-6.5,2.5c-1.4,1.7-1.5,3.6-0.6,5.1c1,1.5,2.6,2.2,4.2,2.4c1.6,0.2,3.2-0.2,4.8-1.5
				c1.6-1.3,3-2.3,4.4-3c1.4-0.8,3-1.3,5.4-1.4c-2.1-0.9-3.7-1.6-5.5-2.4C3,104.7,1.2,104-1.6,103.7z"
                />
              </g>
              <g>
                <path
                  d="M-13.1,124.3c-2.5,1.7-3.7,4.6-3.5,6.9c0.2,2.4,1.6,3.9,3.3,4.1c1.8,0.2,3.4-0.6,4.6-1.5c1.3-1,2.1-2.2,2.5-4.1
				c0.4-2,0.9-3.5,1.7-5c0.8-1.4,1.9-2.8,4.1-4c-2.5,0.2-4.3,0.5-6.2,0.9C-8.6,122.1-10.6,122.6-13.1,124.3z"
                />
              </g>
              <g>
                <path
                  d="M-9.6,149.9c-0.9,2.6-0.3,5.4,1.1,7.1c1.4,1.7,3.3,2.2,4.9,1.5c1.6-0.7,2.7-2.2,3.2-3.7c0.5-1.5,0.5-3.1-0.4-4.8
				c-0.9-1.8-1.5-3.3-1.9-4.9c-0.3-1.6-0.5-3.2,0.1-5.5c-1.6,1.8-2.7,3.2-3.8,4.6C-7.5,145.6-8.6,147.2-9.6,149.9z"
                />
              </g>
              <g>
                <path
                  d="M3.1,168.8c0.4,2.9,2.4,5.2,4.4,6.2c2.1,1,4.1,0.7,5.2-0.6c1.2-1.3,1.4-3.2,1.1-4.9c-0.3-1.7-1.2-3.3-2.9-4.7
				c-1.7-1.4-3-2.6-4.1-3.9c-1.1-1.3-2-2.9-2.7-5.4c-0.4,2.6-0.7,4.4-1,6.4C2.9,163.8,2.7,165.8,3.1,168.8z"
                />
              </g>
              <g>
                <path
                  d="M75.4,229.7c2.3,1.4,4,3.2,4.8,5.2c0.9,2,0.9,4.3,0.3,5.8c-0.6,1.5-2,2.8-3.6,3.4c-0.8,0.3-1.5,0.4-2.3,0.4
				c-0.8,0-1.6-0.2-2.3-0.6c-1.5-0.6-2.7-1.8-3.4-2.9c-0.7-1.1-0.9-2.1-0.8-2.9c0.1-0.8,0.6-1.6,1.1-2c0.4-0.5,0.9-0.8,1-1.2
				c0.1-0.4-0.3-1-1.5-1.3c-1.1-0.2-3,0.1-4.7,1.8c-0.4,0.4-0.8,1-1.1,1.6c-0.3,0.6-0.5,1.2-0.7,1.9c-0.3,1.4-0.3,2.8,0.1,4.3
				c0.7,2.9,2.5,5.8,5.5,7.9c1.5,1,3.3,1.8,5.3,2.3c2,0.4,4.2,0.4,6.3-0.2c2.1-0.5,4.1-1.5,5.9-3c1.8-1.4,3.3-3.4,4.2-5.7
				c0.9-2.3,1.3-4.7,1.1-7c-0.1-2.3-0.8-4.6-1.8-6.7c-1-2.1-2.5-4-4.2-5.5c-1.7-1.5-3.6-2.6-5.6-3.4c-4.1-1.7-8.1-2-11.5-1.8
				c-3.5,0.2-6.5,0.9-9.3,1.7c-5.5,1.7-10.2,3.7-14.6,6.1c-4.5,2.5-8.8,5.2-13,9c-2.1,1.9-4.3,4-6.3,6.4c-2.1,2.5-4.1,5.2-5.8,8.5
				c2-3.1,4.2-5.7,6.4-7.9c2.2-2.2,4.5-4.1,6.8-5.6c4.5-3.2,9-5.2,13.5-6.9c4.5-1.7,9.2-2.8,14.4-3.6c2.6-0.3,5.2-0.5,7.9-0.3
				c1.3,0.1,2.7,0.3,4,0.7C72.9,228.5,74.2,229,75.4,229.7z"
                />
              </g>
              <g>
                <path
                  d="M55.5,215c4.5-2.5,8.1-6.3,10.1-9.7c2-3.4,2.4-6.3,1-7.5c-1.4-1.1-4.1-0.2-7.1,1.7c-3.1,2-6.5,5-9.6,8.6
				c-3.2,3.8-5.4,6.3-7.9,8.8c-2.5,2.5-5.2,4.8-9.6,7.6c4.8-1.8,8.2-3.1,11.7-4.4C47.5,218.9,50.9,217.5,55.5,215z"
                />
              </g>
              <g>
                <path
                  d="M75.4,216.2c2.3,0.5,4.3,0.1,5.9-0.8c1.6-1,2.7-2.5,2.7-4.3c0-1.8-1.2-3.3-3.1-4.1c-1.9-0.8-4.5-0.8-6.8,0.5
				c-2.4,1.3-3.8,2.5-5.3,3.6c-1.5,1.1-2.9,2.2-5.1,3.5c2.5-0.3,4.3-0.1,6,0.2C71.3,215.2,73,215.7,75.4,216.2z"
                />
              </g>
              <g>
                <path
                  d="M35.3,208.6c0.9-2.4,1.3-5,1.3-7.4c0-0.6,0-1.2-0.1-1.8c-0.1-0.6-0.1-1.1-0.2-1.7c-0.2-1.2-0.6-2.3-1.1-3.4
				c-0.5-1.1-1.1-2-1.9-2.8c-0.7-0.8-1.6-1.3-2.3-1.6c-1.6-0.5-2.6,0-3,0.7c-0.4,0.8-0.3,1.8-0.5,2.6c-0.1,0.9-0.3,1.8-0.4,3
				c-0.1,0.7-0.1,1.4-0.2,2.1c0,0.4-0.1,0.9-0.1,1.3c0,0.5,0,0.9-0.1,1.4c-0.1,1.9-0.1,3.9,0,6c0.1,4.3-0.2,7.5-0.8,10.6
				c-0.7,3.1-1.8,6.3-4.7,10.3c3.7-3.3,5.9-5.9,8.1-8.7c1.1-1.4,2.1-2.8,3.1-4.5C33.4,213,34.4,211.1,35.3,208.6z"
                />
              </g>
              <g>
                <path
                  d="M124.7,53.8c3.3-0.9,6.7-1.7,9-1.4c1.2,0.1,2,0.4,2.7,0.9c0.6,0.4,1.2,1.1,1.6,2c0.9,1.8,1,4.3,0.5,6.2
				c-0.1,0.5-0.3,1-0.5,1.5c-0.2,0.5-0.5,0.9-0.7,1.4c-0.5,0.8-1.2,1.5-1.8,2.1c-1.2,1-2.9,1.5-4.2,1.4c-0.7,0-1.3-0.2-1.8-0.4
				c-0.5-0.3-1-0.6-1.4-1c-1.7-1.6-1.8-3.6-2.6-3.9c-0.4-0.1-1,0.2-1.5,1.2c-0.5,1-0.8,2.8-0.1,4.9c0.3,1,0.9,2.2,1.9,3.2
				c1,1.1,2.2,2,3.8,2.6c3,1.2,6.9,1.2,10.4-0.7c1.7-0.9,3.3-2.2,4.6-3.7c0.7-0.8,1.2-1.6,1.8-2.5c0.5-0.9,1-1.8,1.4-2.9
				c0.8-2.1,1.1-4.3,1.1-6.5c-0.1-2.3-0.5-4.6-1.5-6.9c-1.1-2.3-2.8-4.5-5.2-6c-2.4-1.5-5.1-2.2-7.6-2.3c-2.5-0.1-4.7,0.3-6.8,0.9
				c-2.1,0.6-3.9,1.2-5.7,2c-3.8,1.6-7.6,3.8-10.3,7c-1.3,1.6-2.3,3.5-2.9,5.3c-0.6,1.8-0.9,3.6-0.9,5.3c-0.1,3.4,0.5,6.4,1.3,9.2
				c0.9,2.8,2,5.4,3.4,7.8c2.8,4.8,6.6,9.1,11.7,12.4c2.6,1.7,5.5,3.1,8.7,4.2c3.3,1.1,6.9,1.7,10.9,1.8c-3.9-0.3-7.5-1.3-10.6-2.6
				c-3.1-1.3-5.7-3-8-4.8c-4.5-3.7-7.5-8.1-9.4-12.7c-1-2.3-1.6-4.7-2-7.2c-0.4-2.5-0.5-5,0-7.5c0.2-1.2,0.6-2.4,1.1-3.4
				c0.5-1,1.2-2,2.1-2.8C118.7,56,121.3,54.8,124.7,53.8z"
                />
              </g>
              <g>
                <path
                  d="M86,72c-3-1.1-6.3-0.6-8.4,0.7c-2.1,1.3-3.1,3.2-2.6,4.9c0.5,1.7,2.2,2.8,4,3.3c1.9,0.6,4,0.6,6.4-0.2
				c2.5-0.8,4.3-1.2,6.3-1.4c1.9-0.2,4-0.2,6.8,0.7c-2.3-1.8-4-3.1-5.7-4.3C90.9,74.4,89.1,73.1,86,72z"
                />
              </g>
              <g>
                <path
                  d="M99.5,62.1c0.8-1.3,0.8-2.3,0.3-3.6c-0.5-1.2-1.3-2.8-2.7-3.8c-0.7-0.5-1.6-0.7-2.5-0.6c-0.9,0.1-1.8,0.5-2.6,1.3
				c-0.8,0.8-1.4,1.9-1.7,3.2c-0.3,1.3-0.2,2.8,0.4,4.1c1.2,2.8,2.7,4.1,4.1,5.3c1.4,1.1,2.9,2,4.9,2.9c-1.3-1.8-1.6-3.3-1.5-4.7
				C98.2,64.8,98.7,63.5,99.5,62.1z"
                />
              </g>
              <g>
                <path
                  d="M152.6,18.3c3.1-2.4,6-4.9,8.2-6.9c2.2-2,3.6-3.6,3.1-4.4c-0.4-0.8-2.6-0.5-5.5,1c-2.8,1.4-6.3,4-8.9,7.3
				c-2.7,3.4-4.2,5.9-5.6,8.5c-1.4,2.6-2.7,5.2-4.4,9.2c2.2-3.7,4-6,6-8.1C147.4,22.8,149.4,20.8,152.6,18.3z"
                />
              </g>
              <g>
                <path
                  d="M161.5,23.3c3.2-1.8,6.3-3.8,8.5-5.5c2.2-1.7,3.6-3.2,3.2-4c-0.4-0.8-2.5-0.7-5.2,0.3c-2.8,1-6.2,3-9.1,5.6
				c-2.9,2.7-4.7,4.8-6.5,6.9c-1.7,2.1-3.5,4.3-5.9,7.5c2.8-2.9,5-4.6,7.2-6.1C155.9,26.6,158.1,25.2,161.5,23.3z"
                />
              </g>
              <g>
                <path
                  d="M160,33.4c2.1-0.6,3.9-1.6,5.2-2.7c1.3-1,2-2.1,1.7-3c-0.3-0.8-1.6-1.2-3.4-0.9c-1.7,0.3-3.9,1.2-5.7,2.7
				c-1.8,1.6-2.9,2.8-4,4c-1.1,1.2-2.2,2.4-3.9,4.1c2-1.3,3.5-1.9,4.9-2.5C156.3,34.6,157.8,34.1,160,33.4z"
                />
              </g>
              <g>
                <path
                  d="M207.1,151.4c1.6-3.8,3.4-7.5,4.8-10.2c1.4-2.8,2.4-4.8,1.8-5.4c-0.6-0.6-2.8,0.4-5.2,3c-2.4,2.6-4.7,6.8-5.6,11.4
				c-0.8,4.7-0.7,8-0.3,11.2c0.4,3.2,1,6.4,2.6,10.8c-1.1-4.5-1.1-7.7-0.7-10.8C204.8,158.3,205.5,155.3,207.1,151.4z"
                />
              </g>
              <g>
                <path
                  d="M186.8,193.3c-0.7,3.1-1.2,5.4-1.8,8c-0.5,2.2-0.7,3.9,0,4.4c0.7,0.5,2.3-0.5,3.8-2.8c1.4-2.2,2.6-5.8,2.3-9.3
				c-0.2-3.6-1-6-1.8-8.2c-0.9-2.2-1.9-4.4-3.8-7.3c1.5,3.1,1.8,5.5,2,7.7C187.6,188,187.5,190.3,186.8,193.3z"
                />
              </g>
              <g>
                <path
                  d="M166,263.2c-0.7,3.5-2,6.9-3.1,9.4c-1.1,2.5-2,4.4-1.3,5c0.6,0.6,2.7-0.2,4.9-2.8c1.1-1.3,2.1-2.9,2.8-4.9
				c0.7-1.9,1.2-4.2,1.1-6.4c0-2.3-0.4-4.1-0.9-5.8c-0.5-1.6-1-3-1.7-4.3c-1.4-2.6-3-5.2-6.2-8.1c2.8,3.3,3.8,6,4.4,8.7
				c0.3,1.3,0.4,2.7,0.5,4.2C166.5,259.8,166.4,261.4,166,263.2z"
                />
              </g>
              <g>
                <path
                  d="M249.5,317.1c1.2-0.7,2.4-1.2,3.5-1.4c0.6-0.1,1.1-0.1,1.6-0.1c0.5,0,1,0.2,1.5,0.3c2,0.7,4,2.6,5.2,4.8
				c0.3,0.5,0.5,1.1,0.8,1.7c0.2,0.6,0.4,1.2,0.5,1.8c0.2,1.2,0.3,2.3,0.2,3.3c-0.2,1.9-1.3,3.4-2.5,4.1c-0.1,0.1-0.3,0.2-0.4,0.2
				c-0.1,0.1-0.3,0.1-0.4,0.2c-0.3,0.1-0.6,0.1-0.9,0.2c-0.7,0-1.3-0.1-1.8-0.4c-0.5-0.2-0.9-0.6-1.2-0.9c-0.3-0.4-0.5-0.9-0.5-1.4
				c-0.1-1.1,0.1-2,0.4-2.8c0.3-0.7,0.6-1.3,0.4-1.7c-0.1-0.4-0.8-0.7-2-0.3c-1.1,0.4-2.7,1.6-3.5,3.8c-0.4,1.1-0.6,2.5-0.3,4.1
				c0.3,1.6,1.1,3.2,2.4,4.5c1.3,1.3,3,2.2,4.9,2.7c1,0.2,2,0.3,3.1,0.3c0.5,0,1.1-0.1,1.7-0.2c0.5-0.1,1.1-0.3,1.6-0.4
				c4.2-1.5,7.4-5.3,8.5-10c0.5-2.3,0.6-4.6,0.2-6.8c-0.2-1.1-0.4-2.2-0.8-3.3c-0.4-1.1-0.8-2.2-1.4-3.2c-1.1-2.1-2.5-4-4.4-5.6
				c-1.8-1.6-4.1-3-6.7-3.8c-2.6-0.8-5.5-0.8-8.1-0.1c-2.6,0.7-4.8,2-6.7,3.5c-3.8,3.3-5.9,7.3-7.1,11c-1.1,3.8-1.4,7.4-1.1,10.7
				c0.2,3.3,1,6.4,1.9,9.1c1,2.8,2.1,5.4,3.5,7.8c1.3,2.5,2.7,4.9,4.3,7.3c1.5,2.4,3.1,4.8,4.9,7.4c3.6,5,7.8,10.5,13.5,16.3
				c-5.3-6.2-9-11.9-12.1-17.2c-1.5-2.7-2.8-5.2-4-7.8c-1.2-2.5-2.3-5-3.2-7.5c-0.9-2.5-1.7-5-2.2-7.6c-0.5-2.6-0.7-5.2-0.6-7.9
				c0.2-2.7,0.8-5.5,2-8.1c0.6-1.3,1.3-2.5,2.2-3.7C247.3,318.8,248.3,317.8,249.5,317.1z"
                />
              </g>
              <g>
                <path
                  d="M264.6,361c0.7-2.6,1-4.8,0.9-6.9c-0.1-2.1-0.6-4.1-2-5.2c-0.7-0.5-1.7-0.6-2.7-0.3c-1,0.3-2.1,1.1-3.1,2.3
				c-1,1.2-1.7,2.8-2.1,4.7c-0.4,1.9-0.3,3.9,0.3,5.9c0.6,2,1.4,3.6,2.2,4.9c0.8,1.3,1.7,2.3,2.6,3.3c1.8,1.9,3.7,3.6,6.7,5.5
				c-2.3-2.7-3-5-3.3-7.2C263.8,365.9,263.9,363.7,264.6,361z"
                />
              </g>
              <g>
                <path
                  d="M284.7,370c-3.2,6-5.2,12.1-7.6,17c-1.2,2.5-2.4,4.7-3.7,6.6c-0.7,0.9-1.4,1.8-2.1,2.6c-0.7,0.8-1.4,1.4-2.1,2
				c-1.4,1.1-2.5,1.5-3.5,1.7c-0.3,0.1-0.8,0-1.2-0.1c-0.5-0.2-0.9-0.4-1.2-0.7c-0.7-0.5-1.1-1.1-1.5-1.7c-0.3-0.6-0.5-1.1-0.9-1.3
				c-0.2-0.1-0.4-0.1-0.7,0c-0.3,0.1-0.6,0.4-0.9,0.8c-0.6,0.9-1,2.7-0.3,4.6c0.3,1,0.9,1.9,1.8,2.8c0.9,0.9,2.1,1.8,3.7,2.2
				c1.4,0.4,3,0.5,4.6,0.3c1.6-0.2,3.1-0.7,4.5-1.4c1.4-0.7,2.7-1.6,3.9-2.6c1.2-1,2.3-2.1,3.4-3.3c2.1-2.4,3.9-5.2,5.3-8.2
				c2.9-5.9,4.6-12.1,6.6-17.7c1.1-2.9,2.2-5.3,3.6-7.5c1.4-2.2,2.8-4.2,4.4-5.9c0.7-0.9,1.6-1.7,2.4-2.5c0.9-0.7,1.7-1.5,2.5-2.2
				c0.4-0.3,0.9-0.7,1.3-1c0.4-0.3,0.9-0.6,1.3-0.9c0.9-0.6,1.8-1.2,2.8-1.7c3.8-2.2,8-3.8,13-4.7c1.2-0.2,2.5-0.4,3.9-0.4
				c1.3-0.1,2.7-0.1,4.1,0.1c2.8,0.3,5.9,1.2,8.4,3.6c-2.3-2.6-5.4-3.8-8.3-4.4c-1.5-0.3-2.9-0.5-4.3-0.6c-1.4-0.1-2.7,0-4.1,0
				c-5.2,0.3-9.9,1.3-14.4,3c-1.1,0.4-2.2,0.9-3.3,1.4c-0.5,0.3-1.1,0.5-1.6,0.8c-0.5,0.3-1.1,0.6-1.6,0.9c-1.1,0.6-2.1,1.3-3.2,2
				c-1,0.8-2.1,1.5-3.2,2.3c-2.1,1.7-4.2,3.7-6.2,6C288.2,364.1,286.3,366.9,284.7,370z"
                />
              </g>
              <g>
                <path
                  d="M311.3,353.5c-2.3,1-4.3,2.4-5.9,4.2c-1.6,1.7-2.9,3.8-3.4,5.9c-0.6,2.1-0.3,4.2,0.4,5.5c0.7,1.3,1.7,1.7,2.5,1.5
				c0.9-0.2,1.6-0.8,2.3-1.4c0.7-0.6,1.5-1.2,2.3-1.9c0.9-0.7,1.8-1.6,3-2.7c1.2-1.1,2.4-2.4,3.6-3.7c2.6-2.8,4.9-4.5,7.5-5.8
				c1.3-0.6,2.7-1.3,4.3-1.7c1.6-0.4,3.5-0.7,5.7-0.7c-2.2-0.6-4.1-0.9-5.9-1.1c-1.8-0.2-3.4-0.3-5-0.3c-1.6,0-3.3,0.1-5.1,0.4
				C315.7,352,313.7,352.5,311.3,353.5z"
                />
              </g>
              <g>
                <path
                  d="M333.5,332.9c0.1-2-0.4-3.5-1.3-5c-0.9-1.4-2.2-2.8-3.9-3.1c-0.9-0.2-1.7,0-2.5,0.5c-0.8,0.5-1.5,1.4-1.9,2.6
				c-0.4,1.2-0.6,2.6-0.4,4.2c0.3,1.5,0.9,3.1,2,4.3c2.2,2.6,4.3,3.7,6.2,4.6c1.9,0.9,3.9,1.5,6.5,2.1c-2.2-1.6-3.2-3.2-3.8-4.8
				C333.7,336.7,333.4,335,333.5,332.9z"
                />
              </g>
              <g>
                <path
                  d="M523.9,354.8c0.8-4,0.7-8.3-0.4-12.2c-1-3.9-2.9-7.6-5.4-10.8c-2.5-3.2-5.7-5.8-9.2-7.6c-3.5-1.8-7.3-3-11-3.4
				c-3.8-0.4-7.6-0.2-11.2,0.8c-3.6,0.9-7.2,2.6-10.1,5.5c-1.4,1.4-2.6,3.2-3.4,5c-0.8,1.8-1.3,3.7-1.4,5.5
				c-0.3,3.7,0.5,7.3,2.3,10.3c1.8,3,4.4,5.2,7.1,6.5c2.7,1.3,5.5,1.8,8.1,1.8c2.6-0.1,5-0.8,7-1.8c2-1,3.5-2.4,4.6-3.7
				c2.3-2.7,3.1-5.3,3.4-7.1c0.2-1.8-0.1-2.8-0.5-2.8c-0.5-0.1-1,0.7-1.9,1.9c-0.9,1.2-2.2,3-4.3,4.5c-1,0.7-2.3,1.4-3.7,1.8
				c-1.4,0.4-2.9,0.6-4.5,0.4c-1.6-0.2-3.2-0.7-4.7-1.7c-1.5-0.9-2.7-2.2-3.4-3.7c-0.7-1.5-1-3.5-0.7-5.3c0.1-0.9,0.4-1.8,0.8-2.6
				c0.4-0.8,0.9-1.4,1.6-2.1c2.8-2.6,8.4-3.9,13.5-3.2c5.3,0.6,10.4,3.1,13.9,7.3c3.5,4.2,5.4,9.9,4.7,15.4
				c-0.8,5.7-4.1,10.7-8.1,14.1c-4.1,3.4-9,5.5-13.8,6.4c-4.9,1-9.8,1-14.5,0.4c-4.7-0.6-9.3-1.9-13.8-3.5
				c-4.5-1.6-8.9-3.6-13.5-5.8c-4.5-2.2-9.2-4.6-14.3-6.8c-5.1-2.2-10.8-4.1-17.1-4.8c-3.1-0.3-6.4-0.3-9.8,0.1
				c-1.7,0.3-3.4,0.7-5,1.2c-1.7,0.6-3.3,1.3-4.9,2.2c1.6-0.8,3.3-1.5,5-2c1.7-0.5,3.4-0.8,5-1c3.3-0.3,6.6-0.2,9.6,0.3
				c6.2,0.9,11.5,3.2,16.4,5.6c4.9,2.4,9.4,5.1,13.8,7.7c4.4,2.5,8.8,4.9,13.3,7c4.6,2,9.3,3.8,14.4,4.8c5.1,1.1,10.6,1.5,16.4,0.8
				c5.7-0.7,11.9-2.6,17.5-6.7c2.7-2,5.3-4.5,7.5-7.6C521.4,362.6,523.1,358.9,523.9,354.8z"
                />
              </g>
              <g>
                <path
                  d="M447.8,308.1c-3.2-3.2-7.6-5.5-12.3-6.1c-4.7-0.7-9.8,0.3-13.9,3.4c-2,1.5-3.5,3.4-4.6,5.6c-1.1,2.1-1.7,4.5-1.7,7
				c0,0.6,0.1,1.2,0.1,1.8c0.1,0.6,0.2,1.2,0.4,1.8c0.3,1.2,0.9,2.2,1.4,3.2c1.2,1.9,2.7,3.4,4.5,4.5c1.8,1.1,3.7,1.7,5.7,1.8
				c1.9,0.1,3.9-0.4,5.4-1.3c1.5-0.9,2.6-2,3.3-3.1c0.8-1.1,1.2-2.3,1.5-3.3c0.5-2.1,0-3.9-0.6-4.9c-0.3-0.5-0.6-0.8-0.9-1
				c-0.3-0.2-0.5-0.2-0.7-0.1c-0.2,0.1-0.4,0.3-0.5,0.6c-0.1,0.3-0.2,0.6-0.4,1c-0.3,0.8-0.6,1.7-1.4,2.6c-0.7,0.9-1.9,1.8-3.1,2
				c-1.1,0.2-2.3-0.1-3.5-1.2c-0.6-0.5-1.1-1.2-1.4-2c-0.1-0.4-0.3-0.7-0.3-1.1c0-0.2,0-0.3,0-0.5c0-0.2,0-0.4,0-0.6
				c0.1-1.6,1.2-3.6,2.9-4.8c1.6-1.2,4-1.9,6.7-1.6c2.7,0.2,5.3,1.3,7.7,3.1c2.4,1.8,4.2,4.3,5.3,6.8c1.1,2.5,1.5,5.1,1.3,7.6
				c-0.3,5-2.7,9.6-6.2,12.9c-3.5,3.3-8.6,5.3-14.4,5.7c-5.9,0.5-12.6-0.9-20.1-3.5c7.3,3.1,13.9,5,20.1,5.2
				c6.2,0.2,12-1.1,16.8-4.5c2.4-1.7,4.5-3.8,6.2-6.3c1.7-2.5,3.1-5.4,3.7-8.8C456.1,323.3,454.5,314.8,447.8,308.1z"
                />
              </g>
              <g>
                <path
                  d="M458.9,328.3c-2.4,2.5-3.5,5.9-3.3,8.5c0.2,2.7,1.6,4.5,3.3,4.7c1.8,0.2,3.4-0.9,4.8-2.5c1.3-1.6,2.3-3.7,2.8-6.3
				c0.5-2.7,1-4.7,1.8-6.7c0.8-1.9,1.8-3.8,4.1-6.1c-2.9,1.3-4.9,2.4-6.9,3.5C463.4,324.6,461.4,325.8,458.9,328.3z"
                />
              </g>
              <g>
                <path
                  d="M463.1,362.7c0.5,3.2,2.7,5.9,5,6.9c2.3,1.1,4.4,0.7,5.5-0.7c1-1.4,1-3.4,0.6-5.1c-0.5-1.8-1.4-3.3-3.1-4.8
				c-1.7-1.6-3-3-3.9-4.5c-1-1.6-1.8-3.4-1.9-6.2c-0.9,2.7-1.4,4.7-1.9,6.8C462.9,357.2,462.6,359.4,463.1,362.7z"
                />
              </g>
              <g>
                <path
                  d="M490.6,369c1.7,0.6,3.5,0.7,5.2,0.5c1.7-0.3,3.1-0.9,4.2-1.7c2.3-1.7,2.9-3.9,2-5.4c-0.9-1.5-2.7-2.2-4.6-2.6
				c-1.9-0.3-3.8-0.2-6.1,0.5c-2.3,0.7-4.3,0.9-6.3,0.8c-2-0.2-4-0.7-6.5-2.5c1.7,2.6,3.2,4.3,4.9,5.9
				C485.2,366,487.1,367.7,490.6,369z"
                />
              </g>
              <g>
                <path
                  d="M448.5,349.2c-1.6-0.6-2.7-0.5-4,0.1c-1.3,0.6-2.7,1.6-3.5,3.2c-0.4,0.8-0.5,1.7-0.2,2.5c0.2,0.9,0.8,1.7,1.7,2.4
				c0.9,0.7,2.1,1.2,3.5,1.3c1.3,0.1,2.8-0.1,4.1-0.8c2.7-1.5,3.9-3.1,5-4.5c1.1-1.5,1.9-3.1,2.9-5.2c-1.8,1.5-3.4,1.9-4.9,1.9
				C451.6,350.1,450.2,349.9,448.5,349.2z"
                />
              </g>
              <g>
                <path
                  d="M441,389.9c-1.2,1.3-3,2.2-4.8,2.4c-1.7,0.3-3.6,0-5.4-0.8c-1.8-0.7-3.5-2-4.7-3.3c-1.2-1.3-1.9-2.6-2-3.5
				c-0.2-1,0.1-2.1,0.4-2.7c0.3-0.6,0.9-1,1.7-1.1c0.8-0.1,1.5,0,2.1,0.2c0.6,0.2,1.1,0.4,1.5,0.3c0.4-0.1,0.8-0.6,0.6-1.7
				c-0.2-1-1-2.4-2.7-3.4c-0.8-0.5-1.9-0.8-3.2-0.9c-1.3-0.1-2.7,0.3-4,1.1c-2.7,1.6-4.5,4.5-4.8,8c-0.3,3.6,1.1,7,3.1,9.7
				c2,2.7,4.7,4.9,8,6.4c3.3,1.5,7.3,2.2,11.4,1.2c3.9-0.9,7.6-3.3,9.9-6.8c0.6-0.9,1.1-1.9,1.4-2.9c0.4-1,0.6-2,0.7-2.9
				c0.3-1.9,0.2-3.7-0.1-5.3c-0.6-3.2-1.9-5.9-3.3-8.1c-1.5-2.2-3.2-4.1-5-5.6c-1.8-1.5-3.7-2.8-5.7-3.8c-4-2.1-8.4-3.3-13.2-3.8
				c-4.9-0.5-10.3-0.3-16.5,0.5c12.6-0.7,21.4,1.7,27.8,6.5c3.2,2.4,5.8,5.4,7.4,9.2c0.8,1.9,1.3,3.9,1.3,5.9
				C442.8,386.8,442.3,388.7,441,389.9z"
                />
              </g>
              <g>
                <path
                  d="M457.3,391.4c0,2.2,1.1,3.9,2.5,4.7c1.5,0.9,3.3,0.9,4.8,0c1.5-0.9,2.4-2.4,2.6-4c0.2-1.5-0.4-3.1-1.9-4.3
				c-1.5-1.2-2.7-2.1-3.9-3c-1.1-0.9-2.1-2-3.1-3.7c0,2-0.2,3.5-0.4,5C457.7,387.6,457.4,389.2,457.3,391.4z"
                />
              </g>
              <g>
                <path
                  d="M431.7,410.6c-2.8,1.3-4.6,3.9-5.2,6.2c-0.6,2.3,0.1,4.3,1.6,5.2c1.5,0.9,3.5,0.6,5.2-0.3c1.7-0.9,3.3-2.4,4.4-4.7
				c1.1-2.3,2-4,3.1-5.6c1.1-1.6,2.4-3.1,4.8-4.7c-2.7,0.6-4.7,1-6.8,1.5C436.6,408.7,434.5,409.2,431.7,410.6z"
                />
              </g>
              <g>
                <path
                  d="M389.6,226.7c1.7,0.3,3.4,0.1,5-0.1c1.5-0.3,2.9-0.8,4-1.3c2.2-1.1,3.4-2.3,3.2-3.1c-0.3-0.9-2-1.1-4.3-0.9
				c-2.3,0.2-5.1,0.7-7.8,1.1c-2.8,0.4-4.9,0.2-6.8-0.5c-1-0.3-1.9-0.8-2.9-1.6c-0.9-0.7-1.9-1.8-2.6-3.3c0.4,1.6,1.1,2.9,1.9,3.9
				c0.8,1,1.7,1.9,2.6,2.6c0.9,0.7,2,1.4,3.2,1.9C386.4,226,387.9,226.5,389.6,226.7z"
                />
              </g>
              <g>
                <path
                  d="M392.5,238.2c1.9,2.5,3.7,4.8,5.3,6.6c1.6,1.7,2.9,2.9,3.7,2.5c0.8-0.3,0.8-2.2-0.1-4.7c-1-2.4-3-5.4-5.8-7.5
				c-2.9-2.2-5-3.2-7.2-4.2c-2.2-1-4.4-1.9-7.8-2.8c3.1,1.5,5,2.9,6.7,4.4C389.1,234,390.6,235.6,392.5,238.2z"
                />
              </g>
              <g>
                <path
                  d="M351.7,217.7c-0.7,2.4-1.3,4.7-1.7,6.6c-0.4,1.9-0.5,3.4,0.2,3.9c0.7,0.5,2.2-0.2,3.6-2.1c1.4-1.8,2.4-4.8,2.3-7.9
				c-0.2-3.1-0.8-5.1-1.4-7.1c-0.7-1.9-1.5-3.8-3.1-6.3c1,2.7,1.2,4.7,1.2,6.6C352.7,213.3,352.5,215.2,351.7,217.7z"
                />
              </g>
              <g>
                <path
                  d="M446.3,284.3c3.7-0.6,7.2-2.4,9.4-4.3c2.2-1.9,3-3.7,2.5-4.4c-0.6-0.7-2.4-0.1-4.7,0.8c-2.3,1-5.3,2.3-8.3,3.6
				c-3.2,1.4-5.5,2-7.9,2.5c-2.4,0.4-5,0.7-8.7,0.1c3.6,1.1,6.2,1.5,8.8,1.8C439.8,284.8,442.4,284.9,446.3,284.3z"
                />
              </g>
              <g>
                <path
                  d="M455.1,296.1c2.5,0.2,5-0.7,6.5-1.9c1.5-1.2,2-2.6,1.4-3.3c-0.6-0.7-1.8-0.7-3.3-0.4c-1.5,0.2-3.2,0.7-5.1,1.3
				c-1.9,0.6-3.4,0.9-4.9,0.9c-1.5,0-3.1-0.1-5.3-1.1c1.9,1.4,3.3,2.3,4.9,3C450.8,295.2,452.5,295.8,455.1,296.1z"
                />
              </g>
              <g>
                <path
                  d="M512.7,278.9c-0.8,1.7-2.2,3.2-3.7,4.4c-1.5,1.2-3.3,2.3-4.8,3.4c-1.5,1.1-2.8,2.1-3.5,3c-0.8,0.9-1,1.6-0.8,2
				c0.3,0.4,1,0.3,2,0.1c1.1-0.2,2.5-0.7,4.2-1.4c1.7-0.7,3.8-1.6,5.9-3.2c2-1.6,4-3.9,4.8-6.9c0.4-1.5,0.4-3,0.2-4.4
				c-0.3-1.3-0.8-2.5-1.4-3.4c-1.2-1.9-2.8-3.1-4.4-4c-3.3-1.8-7-2.2-12.2-2.6c2.6,0.5,4.7,0.9,6.5,1.6c1.8,0.7,3.3,1.5,4.5,2.6
				c1.2,1.1,2.2,2.4,2.8,3.9C513.5,275.5,513.5,277.2,512.7,278.9z"
                />
              </g>
              <g>
                <path
                  d="M421,118.9c2.1-3.8,2.7-8.3,2.3-11.5c-0.4-3.3-1.6-5.2-2.4-5c-0.9,0.2-1.3,2.2-1.8,4.9c-0.5,2.8-1.2,6.3-2.2,9.9
				c-1.1,3.7-2.3,6.2-3.8,8.5c-1.5,2.3-3.4,4.6-6.9,6.9c3.8-1.9,6.2-3.7,8.4-5.7C416.7,125,418.8,122.8,421,118.9z"
                />
              </g>
              <g>
                <path
                  d="M423,162.8c5.4,0.3,10.9,0.4,15,0.3c4.1-0.1,6.9-0.6,7-1.5c0.1-0.9-2.6-2-6.7-2.7c-4.2-0.7-9.8-1.1-15.4-0.5
				c-5.7,0.6-9.6,1.3-13.5,2.1c-3.9,0.8-7.8,1.6-13.5,3c5.8-0.8,9.8-1,13.7-1.1C413.6,162.4,417.4,162.5,423,162.8z"
                />
              </g>
              <g>
                <path
                  d="M428.4,172.9c6.4,1.8,13.2,1.4,13.5-0.3c0.2-1.7-6.2-4-12.9-4c-6.9,0-9.5,0.3-16.5-0.1
				C419.4,169.9,421.8,171,428.4,172.9z"
                />
              </g>
              <g>
                <path
                  d="M465.2,185.4c3.3-1.4,6.4-3,8.7-4.4c2.3-1.4,3.8-2.7,3.5-3.5c-0.3-0.8-2.3-1-5.1-0.3c-2.8,0.7-6.3,2.2-9.3,4.5
				c-3.1,2.4-5,4.3-6.8,6.1c-1.8,1.9-3.7,3.9-6.2,6.8c2.9-2.6,5.1-4,7.4-5.3C459.5,188,461.8,186.9,465.2,185.4z"
                />
              </g>
              <g>
                <path
                  d="M479.4,189.5c3.5-0.2,7-0.5,9.6-1c2.6-0.4,4.5-1,4.6-1.9c0.1-0.9-1.7-1.8-4.6-2.3c-2.8-0.5-6.7-0.3-10.4,0.8
				c-3.7,1.2-6.2,2.3-8.5,3.5c-2.4,1.2-4.7,2.5-8,4.6c3.5-1.6,6.1-2.3,8.6-2.8C473.3,190.1,475.8,189.7,479.4,189.5z"
                />
              </g>
              <g>
                <path
                  d="M496,177.4c5.3-1.4,9.4-5.2,8.5-6.8c-0.9-1.5-6-0.2-10.3,2.8c-2.2,1.5-3.8,2.6-5.3,3.7c-1.6,1-3.2,2-5.8,3.2
				c2.7-0.7,4.6-1.1,6.5-1.5C491.4,178.5,493.3,178.1,496,177.4z"
                />
              </g>
              <g>
                <path
                  d="M508.6,181.3c2.3,0.5,4.6,0.6,6.3,0.4c1.8-0.2,3.1-0.7,3.3-1.5c0.2-0.9-0.9-1.9-2.7-2.6c-1.8-0.7-4.4-1.1-6.9-0.6
				c-2.6,0.4-4.3,1-6,1.5c-1.8,0.6-3.6,1.2-5.8,2c2.6-0.3,4.3-0.3,6-0.1C504.5,180.5,506.2,180.8,508.6,181.3z"
                />
              </g>
              <g>
                <path
                  d="M515.5,191.4c5.5,1.8,11.5,1.5,11.8-0.3c0.3-1.7-5.2-4-11-4.1c-6-0.1-8.3,0.3-14.4-0.2
				C507.8,188.4,509.8,189.5,515.5,191.4z"
                />
              </g>
              <g>
                <path
                  d="M537.8,184.7c2.8,0.1,5.6-0.6,7.4-1.6c1.9-1,2.9-2.2,2.6-3c-0.3-0.8-1.8-1.1-3.7-1c-2,0.1-4.4,0.5-6.9,1.3
				c-2.5,0.8-4.3,1.3-6.2,1.7c-1.9,0.4-3.8,0.6-6.6,0.6c2.8,0.6,4.7,1,6.6,1.3C533,184.3,535,184.6,537.8,184.7z"
                />
              </g>
              <g>
                <path
                  d="M537.5,176.3c2.8-0.1,5.5-1.1,7.4-2.2c1.8-1.1,2.8-2.4,2.4-3.2c-0.4-0.8-1.9-1-3.8-0.7c-2,0.2-4.4,0.9-6.9,1.9
				c-2.5,1-4.3,1.6-6.1,2.2c-1.8,0.5-3.7,1-6.6,1.2c2.9,0.3,4.8,0.5,6.8,0.7C532.6,176.2,534.6,176.4,537.5,176.3z"
                />
              </g>
              <g>
                <path
                  d="M535.8,166.5c2.7-0.6,5.2-1.8,6.8-3c1.7-1.3,2.5-2.6,2.1-3.4c-0.8-1.6-6.1-0.4-10.6,2.3c-2.3,1.4-4,2.4-5.6,3.4
				c-1.7,0.9-3.4,1.8-6.1,2.9c2.8-0.5,4.7-0.8,6.7-1C531.1,167.3,533,167.1,535.8,166.5z"
                />
              </g>
              <g>
                <path
                  d="M557.3,160.8c1.9-0.2,3.7-0.8,5-1.5c1.3-0.7,2.2-1.5,2.2-2.4c-0.1-0.9-1.2-1.6-2.9-1.7c-1.7-0.2-3.9,0.3-5.8,1.5
				c-1.9,1.2-3.1,2.3-4.2,3.4c-1.1,1.1-2.2,2.1-3.7,3.7c1.8-1.2,3.2-1.7,4.6-2.1C553.9,161.3,555.3,161,557.3,160.8z"
                />
              </g>
              <g>
                <path
                  d="M366.2,478.6c3.4-1,6.8-2.1,9.3-3.2c2.5-1.1,4.2-2.1,4-3c-0.1-0.9-2.1-1.3-5-1.1c-2.9,0.3-6.7,1.3-10,3.2
				c-3.4,2-5.6,3.6-7.7,5.3c-2.1,1.7-4.2,3.4-7,6.1c3.2-2.3,5.6-3.5,8-4.5C360.2,480.5,362.6,479.6,366.2,478.6z"
                />
              </g>
              <g>
                <path
                  d="M377.5,483.6c2.3,0.1,4.5-0.2,6.2-0.7c1.7-0.5,2.9-1.2,2.9-2.1c0-0.9-1.2-1.7-3.1-2.1c-1.9-0.4-4.4-0.3-6.8,0.6
				c-2.4,0.9-4,1.7-5.6,2.5c-1.7,0.9-3.3,1.8-5.3,2.9c2.4-0.7,4.2-1,5.9-1.1C373.5,483.5,375.1,483.5,377.5,483.6z"
                />
              </g>
              <g>
                <path
                  d="M400.9,489.4c2.5,0.8,5,1.3,7,1.3c2,0.1,3.5-0.3,3.7-1.1c0.3-0.8-0.8-2-2.7-2.9c-1.9-0.9-4.6-1.6-7.4-1.6
				c-2.9,0-4.8,0.3-6.8,0.5c-2.1,0.2-4.1,0.5-6.7,0.8c2.8,0.2,4.7,0.6,6.6,1.1C396.6,487.9,398.4,488.5,400.9,489.4z"
                />
              </g>
              <g>
                <path
                  d="M404,480.2c5.8,1.9,12.1,1.7,12.4,0c0.3-1.7-5.5-4.1-11.6-4.3c-6.3-0.2-8.7,0.1-15.2-0.5
				C395.8,477.1,398,478.2,404,480.2z"
                />
              </g>
              <g>
                <path
                  d="M405.2,471.3c1.9,0,3.8-0.8,4.9-1.8c1.1-1,1.6-2.1,1.2-2.9c-0.4-0.8-1.5-1.1-2.8-1c-1.3,0.1-2.9,0.5-4.3,1.4
				c-1.5,0.9-2.6,1.5-3.8,2c-1.2,0.5-2.4,0.9-4.3,1.2c1.7,0.3,3,0.5,4.5,0.7C401.8,471.2,403.2,471.4,405.2,471.3z"
                />
              </g>
              <g>
                <path
                  d="M326.4,598.7c3.6-0.2,7.2-0.8,10.9-1.8c3.6-1,7.3-2.7,10.5-5.2c1.6-1.3,3.1-2.8,4.3-4.4c1.2-1.7,2.2-3.5,3-5.4
				c1.5-3.8,2.1-7.8,1.7-11.8c-0.2-2-0.7-3.9-1.4-5.8c-0.7-1.9-1.7-3.6-2.8-5.1c-2.2-3.1-5.1-5.5-8.2-7.3c-1.6-0.8-3.2-1.5-4.9-2
				c-1.7-0.5-3.4-0.7-5.1-0.8c-3.4-0.1-6.7,0.5-9.7,1.8c-2.9,1.4-5.5,3.4-7.2,6.1c-1.7,2.6-2.4,5.5-2.4,8.1c0,2.7,0.8,5.3,2.2,7.2
				c0.7,1,1.5,1.8,2.4,2.5c0.9,0.6,1.8,1.1,2.7,1.4c3.6,1.3,6.6,0.5,8.1-0.6c1.6-1.1,1.9-2.1,1.6-2.4c-0.3-0.4-1.2-0.1-2.5,0
				c-0.7,0-1.5,0-2.4-0.2c-0.9-0.2-1.9-0.6-2.8-1.2c-0.9-0.6-1.7-1.5-2.1-2.5c-0.5-1.1-0.6-2.4-0.4-3.8c0.2-1.4,0.8-2.9,1.8-4.1
				c0.9-1.1,2.4-2.1,4.1-2.7c1.7-0.6,3.8-0.8,5.8-0.6c1,0.1,2,0.3,3,0.7c1,0.4,2,0.8,2.9,1.4c1.9,1.2,3.6,2.7,4.9,4.6
				c1.3,1.9,2,4,2.3,6.3c0.2,2.3-0.1,4.8-1,7.1c-0.4,1.1-1,2.2-1.7,3.2c-0.7,1-1.5,1.8-2.5,2.7c-3.9,3.3-9.9,5-16.1,5.8
				c-6.3,0.8-12.3,0.4-17.8-0.8c-5.5-1.2-10.3-3.2-14.8-5.6c-9-4.9-16.6-10.8-25.2-15.5c-8.6-4.7-18.1-7.9-29-9.2
				c-5.4-0.6-11.2-0.8-17.4-0.2c-6.1,0.6-12.7,2-19.4,4.5c6.8-2.2,13.3-3.3,19.4-3.6c6.1-0.3,11.8,0.2,17.1,1.1
				c10.5,1.9,19.4,5.7,27.3,10.8c3.9,2.6,7.7,5.5,11.5,8.5c3.9,3,7.9,6.1,12.5,9.1c4.6,3,9.9,5.7,16,7.5
				C312.1,598.2,319,599.2,326.4,598.7z"
                />
              </g>
              <g>
                <path
                  d="M279.9,561.6c4.2-1.3,8.3-3.6,11.6-6.8c1.7-1.6,3.1-3.5,4.3-5.6c1.2-2.1,2-4.4,2.3-7c0.7-5.2-1.5-10.8-6.2-13.8
				c-2.4-1.4-4.9-1.9-7.1-1.8c-2.2,0.1-4.1,0.5-6,1.3c-1.8,0.8-3.5,2-4.9,3.6c-1.3,1.6-2.1,3.7-2.1,5.7c0,3.9,2.4,6.6,4.7,7.6
				c2.3,1,4.2,0.5,5.3-0.1c1.1-0.6,1.3-1.3,1.2-1.6c-0.2-0.4-0.8-0.6-1.5-0.9c-0.7-0.3-1.7-0.8-2.4-1.6c-0.3-0.4-0.5-0.8-0.6-1.4
				c-0.1-0.5-0.1-1.1,0.1-1.5c0.2-0.4,0.5-0.7,1-1.1c0.5-0.3,1.2-0.7,2.1-0.9c0.9-0.2,2-0.3,2.9-0.2c1,0.1,1.7,0.4,2.2,0.8
				c0.5,0.4,0.9,1,1.2,1.8c0.2,0.4,0.3,0.9,0.3,1.3c0,0.4,0,0.9,0,1.5c-0.3,2.1-1.7,4.8-3.7,7c-2,2.2-4.7,4.1-7.8,5.4
				c-3.1,1.4-6.4,2.2-9.5,2.6c-3.2,0.4-6.2,0.5-9.2,0.4c-5.9-0.1-11.4-0.8-17-1.1c-5.6-0.3-11.4-0.3-17.6,0.8
				c-6.2,1.1-13,3.1-20.3,7c7.5-3.4,14.4-4.8,20.5-5.3c6.2-0.4,11.6,0.2,17,1.3c5.4,1,10.7,2.4,16.9,3.4c3.1,0.5,6.5,0.9,10.2,0.9
				C271.4,563.3,275.5,563,279.9,561.6z"
                />
              </g>
              <g>
                <path
                  d="M303.9,501.7c1.1-5.3,2.3-10.4,3.1-14.4c0.8-4.1,1.2-7.3-0.3-8.3c-1.4-1-4.6,0.8-7.4,4.9c-1.4,2.1-2.6,4.7-3.4,7.7
				c-0.8,3-1.1,6.3-0.8,9.6c0.3,3.4,0.9,6.2,1.7,8.6c0.7,2.4,1.6,4.5,2.6,6.5c1,2,2,4,3.3,6.1c1.3,2.1,2.8,4.3,4.9,6.8
				c-1.6-2.8-2.6-5.2-3.3-7.5c-0.7-2.3-1.1-4.3-1.3-6.4c-0.2-2.1-0.2-4.1-0.1-6.3C303,506.9,303.3,504.5,303.9,501.7z"
                />
              </g>
              <g>
                <path
                  d="M320.9,499.7c0.3-3.4-0.1-6.9-1.1-10c-0.9-3.1-2.4-5.8-4.1-7.9c-1.6-2.1-3.4-3.4-4.9-4.1c-1.5-0.7-2.6-0.7-3.3-0.1
				c-1.3,1.3-0.3,4.3,0.8,8.2c0.6,2,1.2,4.2,1.9,6.6c0.6,2.4,1.3,5,1.8,7.7c0.6,2.8,0.8,5.2,0.9,7.5c0.1,2.3,0,4.4-0.3,6.5
				c-0.5,4.2-1.6,8.5-4.5,14.4c3.8-5.3,6.1-9.2,8.1-13.3c1-2.1,1.9-4.2,2.7-6.7C319.9,506,320.6,503.2,320.9,499.7z"
                />
              </g>
              <g>
                <path
                  d="M312.1,497.4c0.7-3.6,0.6-7.3,0-10.1c-0.6-2.7-1.6-4.4-2.5-4.3c-0.9,0.1-1.4,1.9-1.7,4.6c-0.3,2.6-0.4,6-0.2,9.5
				c0.2,3.6,0.2,6.1,0.2,8.6c-0.1,2.5-0.3,5.1-1.2,8.8c1.3-3.6,2.2-6,3-8.5C310.5,503.6,311.3,501.1,312.1,497.4z"
                />
              </g>
              <g>
                <path
                  d="M310,493.7c0.9-3.1,1-6.3,0.6-8.7c-0.4-2.4-1.2-4-2.1-3.9c-0.9,0-1.6,1.6-2.1,3.8c-0.5,2.3-0.8,5.2-0.8,8.3
				c0,3.1,0,5.3-0.2,7.5c-0.2,2.2-0.4,4.4-1.2,7.6c1.2-2.9,2.1-5,3.1-7.2C308.2,499,309,496.9,310,493.7z"
                />
              </g>
              <g>
                <path
                  d="M302.6,488.7c1.5-2.6,2.9-5.1,3.9-7.2c1-2,1.6-3.6,1-4.3c-0.6-0.6-2.4,0-4.3,1.7c-1.9,1.8-3.9,4.8-4.7,8.1
				c-0.8,3.5-0.9,5.9-0.8,8.3c0.1,2.4,0.3,4.7,1,8.1c-0.2-3.4,0.2-5.7,0.8-7.9C300.3,493.4,301.1,491.4,302.6,488.7z"
                />
              </g>
              <g>
                <path
                  d="M305.8,559.5c1.5-1.7,2.7-3.7,3.5-5.7c0.8-2,1.3-4,1.4-5.7c0.3-3.4-0.5-5.7-1.4-5.7c-0.9,0-1.6,2-2.7,4.8
				c-1.1,2.8-2.5,6.3-4.4,9.7c-2,3.5-3.9,5.6-6.1,7.6c-2.2,1.9-4.8,3.6-9.1,4.9c4.4-0.8,7.4-1.9,10.1-3.3c1.4-0.7,2.7-1.5,4.2-2.5
				C302.7,562.6,304.2,561.3,305.8,559.5z"
                />
              </g>
              <g>
                <path
                  d="M341,541.1c4.9-0.6,9.7-1.1,13.4-1.5c3.7-0.4,6.3-0.9,6.4-1.8c0.1-0.8-2.4-2-6.4-2.4c-3.9-0.5-9.3-0.1-14.4,1.4
				c-5.2,1.6-8.5,3.3-11.7,5c-3.2,1.8-6.4,3.7-10.8,6.9c4.6-2.8,8.1-4.1,11.5-5.2C332.6,542.5,336,541.7,341,541.1z"
                />
              </g>
              <g>
                <path
                  d="M361.1,546c3.3,0.2,6.6,0,9.1-0.3c2.5-0.4,4.3-1,4.3-1.9c0.1-0.9-1.6-1.8-4.2-2.3c-2.6-0.5-6.1-0.6-9.6,0.1
				c-3.5,0.7-5.9,1.5-8.2,2.3c-2.5,0.8-4.8,1.6-8.1,2.8c3.6-0.7,6-0.8,8.5-0.9C355.2,545.7,357.6,545.8,361.1,546z"
                />
              </g>
              <g>
                <path
                  d="M368.5,552.6c2.6,0.8,5.2,1.2,7.2,1.2c2,0,3.6-0.3,3.8-1.2c0.2-0.8-0.9-2-2.8-2.9c-2-0.9-4.8-1.6-7.6-1.5
				c-2.9,0.1-4.9,0.4-6.9,0.7c-2.1,0.3-4.2,0.7-6.9,1.1c2.9,0.1,4.9,0.4,6.8,0.8C364,551.3,365.9,551.8,368.5,552.6z"
                />
              </g>
              <g>
                <path
                  d="M371.3,563.5c1.5,1.5,2.9,2.8,4,3.8c1.2,1,2.2,1.7,3.1,1.5c0.8-0.3,1.2-1.8,0.4-3.8c-0.7-2-2.7-4.1-5.1-5.1
				c-2.5-1.1-4.2-1.3-6-1.6c-1.7-0.3-3.4-0.5-5.9-1c2.3,1,3.8,1.8,5.2,2.7C368.4,561,369.8,562,371.3,563.5z"
                />
              </g>
              <g>
                <path
                  d="M370.1,580.9c0.2,2.4,0.6,4.7,1.1,6.5c0.5,1.8,1.1,3.1,2,3.2c0.9,0.1,1.8-1.2,2.2-3.3c0.4-2.1,0.2-5-1-7.6
				c-1.2-2.6-2.4-4.2-3.6-5.8c-1.2-1.5-2.5-3-4.4-5c1.5,2.3,2.2,4.1,2.7,5.8C369.5,576.6,369.8,578.4,370.1,580.9z"
                />
              </g>
              <g>
                <path
                  d="M364.6,598.8c-0.5,1.7-0.6,3.5-0.3,4.8c0.2,1.4,0.8,2.4,1.7,2.6c0.9,0.2,1.8-0.6,2.5-2c0.6-1.4,1-3.4,0.6-5.3
				c-0.4-2-0.9-3.3-1.3-4.7c-0.5-1.3-0.9-2.6-1.5-4.5c0.1,2-0.1,3.3-0.4,4.7C365.5,595.7,365.1,597,364.6,598.8z"
                />
              </g>
              <g>
                <path
                  d="M353.3,601c-3.3,3.3-5.1,7.7-3.7,8.8c1.4,1.1,5.4-1.9,7.3-6.4c1.9-4.6,2.2-6.5,4.2-11.1
				C358.2,596.4,356.7,597.5,353.3,601z"
                />
              </g>
              <g>
                <path
                  d="M340.2,602.7c-2.6,1.1-5,2.5-6.7,3.8c-1.7,1.3-2.7,2.6-2.3,3.4c0.4,0.8,2,0.9,4.2,0.3c2.2-0.6,4.8-1.9,7.1-3.8
				c2.3-2,3.8-3.5,5.2-5c1.5-1.5,2.9-3,5.1-5.1c-2.5,1.7-4.4,2.7-6.2,3.6C344.8,600.8,342.9,601.6,340.2,602.7z"
                />
              </g>
              <g>
                <path
                  d="M316.4,606.9c-2.8,0-5.6,0.4-7.6,1c-2.1,0.6-3.5,1.4-3.5,2.2c0,0.9,1.5,1.6,3.7,1.9c2.2,0.3,5.2,0.1,8.1-0.9
				c2.9-1,4.8-1.9,6.7-2.8c2-1,4-1.9,6.6-3.1c-3,0.8-5,1.1-7.1,1.3C321.3,606.8,319.3,606.9,316.4,606.9z"
                />
              </g>
              <g>
                <path
                  d="M233,569.1c5.4,0.9,10.6,2.2,14.6,3.2c4,1,6.8,1.7,7.2,0.8c0.4-0.7-1.8-2.8-5.8-4.8c-4-1.9-9.8-3.5-15.7-3.6
				c-6,0-10.1,0.7-14.1,1.6c-4,0.9-8,2.1-13.5,4.5c5.7-1.9,9.8-2.4,13.7-2.6C223.5,568,227.4,568.2,233,569.1z"
                />
              </g>
              <g>
                <path
                  d="M251.9,584.4c1.8,1.9,3.6,3.7,5.1,5.1c1.5,1.3,2.8,2.2,3.6,1.8c0.8-0.4,0.8-2.1-0.2-4.2c-1-2.1-3.1-4.6-5.8-6.1
				c-2.8-1.5-4.8-2.2-6.9-2.7c-2.1-0.5-4.1-0.9-7.1-1.1c2.9,0.8,4.7,1.8,6.4,2.9C248.6,581.1,250.1,582.4,251.9,584.4z"
                />
              </g>
              <g>
                <path
                  d="M68,396c1.6-0.2,3.2-0.3,4.9-0.5c1.6-0.1,3.3-0.2,4.8-0.2c3.1,0,6.1,0.2,8.8,0.7c2.7,0.5,5.1,1.4,6.9,2.6
				c0.9,0.6,1.7,1.2,2.3,1.9c0.3,0.3,0.6,0.7,0.9,1.1c0.2,0.4,0.4,0.7,0.6,1.1c0.7,1.6,1.1,3.6,0.9,5.5c-0.1,1-0.3,1.9-0.6,2.8
				c-0.3,0.9-0.8,1.8-1.3,2.6c-1.1,1.7-2.5,3.2-4.2,4.4c-1.7,1.2-3.4,2.1-5.2,2.7c-1.8,0.6-3.7,0.8-5.3,0.7c-1.7-0.1-3.2-0.4-4.5-1
				c-2.5-1-4.3-3.3-5.3-5.4c-1-2.1-1.2-4.1-1.3-5.6c0-0.7-0.1-1.4-0.1-1.9c-0.1-0.5-0.2-0.9-0.5-1.1c-0.3-0.2-0.7-0.2-1.2,0
				c-0.5,0.2-1.1,0.7-1.8,1.5c-0.6,0.8-1.3,1.9-1.7,3.4c-0.4,1.4-0.7,3.2-0.5,5.1c0.1,1.9,0.7,4.1,1.7,6.3c0.3,0.5,0.6,1.1,0.9,1.6
				c0.4,0.5,0.7,1.1,1.2,1.6c0.5,0.5,0.9,1.1,1.4,1.5c0.3,0.2,0.5,0.5,0.8,0.7c0.3,0.2,0.6,0.4,0.8,0.7c2.3,1.8,5.2,3,8.2,3.7
				c3.1,0.7,6.4,0.7,9.7,0.2c3.3-0.6,6.8-1.8,9.9-3.6c3.1-1.9,6.1-4.4,8.5-7.6c1.2-1.6,2.3-3.4,3.1-5.4c0.8-2,1.4-4.1,1.7-6.3
				c0.6-4.4,0.1-9-1.8-13.5c-0.5-1.1-1.1-2.2-1.7-3.2c-0.7-1-1.4-2-2.2-2.9c-0.8-0.9-1.7-1.7-2.6-2.5c-0.5-0.4-0.9-0.7-1.4-1.1
				c-0.5-0.4-1-0.7-1.5-1c-4-2.5-8.2-3.7-12.3-4.3c-4.1-0.6-8.1-0.7-12-0.3c-1.9,0.2-3.8,0.4-5.6,0.7c-1.9,0.3-3.7,0.6-5.5,1
				c-7.6,1.7-14.2,4-20.2,6.7c-6,2.7-11.3,5.7-16.1,8.9c-4.8,3.2-9.1,6.5-13,10.1c-3.9,3.5-7.6,7.2-10.9,11
				c-6.7,7.7-12.6,16.1-18,26.2c-5.4,10-10.4,21.7-14.4,36.1c4.8-14.2,10.6-25.4,16.7-34.7c6.1-9.4,12.7-16.9,19.9-23.6
				c3.6-3.3,7.4-6.3,11.4-9.2c4-2.9,8.4-5.5,13.1-7.9c4.7-2.4,9.8-4.6,15.4-6.4C55.2,398.3,61.3,396.8,68,396z"
                />
              </g>
              <g>
                <path
                  d="M53,455.6c0.5,1.9,0.5,3.8,0.3,5.6c-0.3,1.9-0.8,3.7-1.7,5.4c-0.9,1.7-2,3.1-3.3,4.3c-0.6,0.6-1.3,1.1-2,1.6
				c-0.7,0.5-1.5,0.9-2.3,1.2c-3.2,1.4-6.9,1.6-10,0.9c-1.6-0.3-2.9-0.9-3.9-1.6c-1-0.7-1.6-1.3-2.1-2.1c-0.2-0.4-0.4-0.8-0.5-1.1
				c-0.1-0.3-0.2-0.6-0.1-0.9c0-0.6,0.3-1.5,1-2.3c0.7-0.9,1.6-1.7,2.6-2.4c0.5-0.3,1-0.7,1.5-1c0.5-0.3,0.9-0.5,1.3-0.8
				c0.5-0.3,1-0.6,1.4-0.9c0.2-0.2,0.7-0.2,1.1-0.2c0.4,0.1,0.7,0.2,1,0.4c0.3,0.2,0.5,0.5,0.8,0.6c0.3,0.1,0.7,0.1,1.1-0.4
				c0.4-0.4,0.7-1.4,0.4-2.5c-0.2-1.1-0.9-2.3-2.5-3.3c-1.5-0.9-3.3-1.1-5-1c-0.8,0.1-1.6,0.2-2.4,0.3c-0.8,0.1-1.6,0.3-2.5,0.6
				c-1.7,0.5-3.6,1.3-5.5,2.6c-1.9,1.3-4,3.4-5.2,6.4c-0.6,1.5-1,3.2-1.1,4.8c-0.1,1.7,0.1,3.3,0.6,5c0.5,1.6,1.2,3.2,2.1,4.6
				c0.9,1.5,2.1,2.7,3.3,3.8c2.5,2.2,5.4,3.8,8.4,4.8c6.1,2,13.1,1.9,19.6-0.7c3.2-1.3,6.4-3.2,9-5.8c2.6-2.5,4.7-5.6,6.2-8.9
				c1.5-3.3,2.4-6.9,2.5-10.6c0.2-3.7-0.5-7.5-1.8-10.8c-2.7-7-7.7-12-13.2-14.9c-5.6-2.9-11.3-3.4-16.2-3.1
				c-4.9,0.3-9.3,1.4-13.4,2.8c-4.1,1.3-7.9,3-11.5,4.9c-3.7,1.9-7.1,4.1-10.6,6.6c-3.4,2.5-6.7,5.3-9.9,8.6
				c-3.2,3.3-6.3,6.9-9.3,11.1c-2.9,4.2-5.7,9-8,14.5c2.6-5.3,5.7-9.8,9-13.7c3.2-3.9,6.6-7.1,10-9.9c3.4-2.8,6.9-5.1,10.4-7
				c3.5-1.9,7-3.5,10.6-4.8c3.6-1.2,7.3-2.3,11.1-2.9c3.8-0.7,7.7-1.1,11.5-0.8c3.8,0.3,7.6,1.4,10.5,3.5
				C49.6,448.2,52,451.7,53,455.6z"
                />
              </g>
              <g>
                <path
                  d="M121.8,361.1c10.9,1.6,21.5,3.9,29.6,5.9c0.5,0.1,1,0.2,1.5,0.3c0.5,0.1,1,0.2,1.4,0.4c0.9,0.2,1.8,0.4,2.6,0.6
				c0.8,0.2,1.6,0.3,2.4,0.5c0.7,0.2,1.4,0.3,2,0.4c2.5,0.4,4.2,0.3,4.6-0.5c0.4-0.8-0.5-2.1-2.5-3.9c-0.5-0.4-1.1-0.9-1.7-1.4
				c-0.6-0.5-1.4-0.9-2.1-1.4c-0.8-0.5-1.6-1-2.5-1.5c-0.5-0.2-0.9-0.5-1.4-0.8c-0.5-0.2-1-0.5-1.5-0.7c-8.1-3.8-19.9-6.9-31.8-6.7
				c-3.1,0.1-5.9,0.1-8.5,0.4c-1.3,0.1-2.6,0.2-3.8,0.4c-1.2,0.2-2.4,0.4-3.5,0.5c-1.1,0.2-2.3,0.3-3.3,0.5
				c-1.1,0.2-2.1,0.4-3.2,0.7c-1,0.2-2.1,0.4-3.1,0.7c-1,0.3-2,0.5-3,0.8c-1,0.3-2,0.5-3,0.8c-1,0.3-2,0.6-3,1c-1,0.3-2,0.6-3.1,1
				c-1,0.4-2.1,0.8-3.2,1.2c-1.1,0.4-2.2,0.8-3.3,1.3c-1.1,0.5-2.3,1-3.5,1.5c-0.6,0.3-1.2,0.5-1.8,0.8c-0.6,0.3-1.2,0.6-1.9,0.9
				c-1.3,0.6-2.6,1.2-4,1.9c1.4-0.5,2.8-1,4.1-1.5c0.7-0.2,1.3-0.5,1.9-0.7c0.6-0.2,1.3-0.4,1.9-0.6c1.2-0.4,2.4-0.7,3.6-1
				c1.2-0.3,2.3-0.5,3.4-0.8c1.1-0.2,2.2-0.5,3.3-0.7c1.1-0.2,2.1-0.3,3.2-0.5c1-0.1,2-0.3,3-0.4c1-0.1,2-0.2,3-0.3
				c1-0.1,2-0.2,3-0.2c1-0.1,2-0.1,3-0.1c1,0,2.1,0,3.1-0.1c1.1,0,2.1,0.1,3.2,0.1c1.1,0,2.2,0.1,3.4,0.1c1.2,0.1,2.3,0.2,3.6,0.3
				C116.4,360.4,119,360.8,121.8,361.1z"
                />
              </g>
              <g>
                <path
                  d="M120.9,378.3c10.8,0.9,21.8-0.5,29.7-2.7c7.9-2.2,12.8-5.1,12.3-6.8c-0.4-1.8-5.9-1.9-13.7-1.5
				c-7.8,0.4-18.1,1.3-28.4,2.2c-10.6,0.9-18,1.1-25.5,0.9c-7.5-0.3-15.1-1-26.1-3.4c10.7,3.5,18.2,5.4,25.6,7.2
				C102.3,375.9,109.8,377.4,120.9,378.3z"
                />
              </g>
              <g>
                <path
                  d="M132.4,371.1c6.3,1.3,12.7,1.4,17.5,0.7c4.8-0.7,8-2.3,8-4c0-1.8-3.1-3.3-7.8-4.4c-4.7-1-10.9-1.5-17.2-1
				c-6.5,0.5-10.9,0.9-15.5,1.2c-4.5,0.3-9.1,0.4-15.8,0c6.6,1.4,11,2.6,15.4,3.8C121.5,368.5,125.9,369.7,132.4,371.1z"
                />
              </g>
              <g>
                <path
                  d="M166.3,420.2c1,0,2.1,0,3.1-0.2c1-0.2,2.1-0.4,3.1-0.7c2-0.7,4-1.7,5.8-3.3c1.8-1.5,3.3-3.7,4-6.1
				c0.7-2.4,0.7-4.8,0.3-6.9c-0.4-2.1-1.2-4-2.1-5.7c-0.9-1.7-2-3.1-3.3-4.5c-1.3-1.3-2.8-2.4-4.5-3.3c-1.6-0.8-3.6-1.3-5.3-1.4
				c-3.5-0.2-6.9,1.1-9,3.3c-1.1,1.1-1.8,2.4-2.2,3.6c-0.4,1.2-0.5,2.3-0.4,3.3c0.2,2,1.1,3.4,1.9,4c0.8,0.7,1.5,0.7,1.8,0.4
				c0.3-0.3,0.4-0.9,0.5-1.6c0.1-0.7,0.2-1.6,0.7-2.4c0.4-0.9,1.2-1.8,2.1-2.1c0.9-0.4,2.3-0.6,3.6-0.2c1.3,0.4,2.6,1.3,3.9,2.8
				c1.2,1.5,2.3,3.6,2.6,5.5c0.2,0.9,0.1,1.7-0.1,2.3c-0.2,0.6-0.5,1.2-1.1,1.8c-0.6,0.6-1.5,1.2-2.5,1.7c-0.5,0.2-1.1,0.4-1.6,0.6
				c-0.6,0.2-1.1,0.3-1.8,0.4c-2.6,0.3-5.2,0-7.6-0.7c-2.4-0.7-4.7-1.7-6.8-2.9c-4.1-2.4-7.6-5.4-11-8.5c-3.5-3.1-6.9-6.5-11.2-9.7
				c-2.2-1.6-4.5-3.1-7.3-4.5c-2.7-1.3-5.9-2.4-9.3-2.6c3.5,0.5,6.4,1.9,8.9,3.4c2.5,1.5,4.7,3.3,6.5,5.1c3.8,3.6,6.6,7.3,9.5,11
				c3,3.7,6,7.4,10.3,11c2.2,1.8,4.6,3.4,7.6,4.8C158.6,419,162.2,420.1,166.3,420.2z"
                />
              </g>
              <g>
                <path
                  d="M130.6,425.7c-0.7,1.3-1.4,2.2-2.3,3c-0.9,0.7-1.7,1.2-2.5,1.4c-0.6,0.2-1.4,0.2-2.1,0c-0.7-0.2-1.2-0.4-1.5-0.7
				c-0.3-0.1-0.6-0.7-0.7-1.3c-0.1-0.6-0.1-1.3,0-1.9c0.1-0.6,0.3-1.1,0.4-1.6c0.2-0.5,0.3-1,0.2-1.4c-0.1-0.4-0.4-0.8-1.1-1
				c-0.7-0.2-1.8-0.1-2.9,0.5c-1.2,0.6-2.4,1.7-3.2,3.4c-0.8,1.6-1.3,4-0.4,6.6c0.8,2.4,2.7,4.6,5.1,5.9c2.4,1.3,5.3,1.8,8.3,1.2
				c2.9-0.6,5.4-2.2,7.2-4.2c1.8-2,3.1-4.6,3.7-7c0.6-2.5,0.6-4.8,0.4-6.8c-0.2-2-0.6-3.8-1-5.4c-0.9-3.3-2.2-6.1-3.7-8.6
				c-1.5-2.6-3.3-4.9-5.6-7.3c-1.1-1.2-2.4-2.3-3.8-3.5c-1.4-1.1-3.1-2.2-4.9-3.2c1.7,1.2,3.1,2.6,4.3,3.8c1.2,1.3,2.2,2.6,3,3.9
				c1.7,2.6,2.7,5.1,3.5,7.7c0.7,2.6,1.1,5.2,1.1,8C132.2,419.9,131.9,423.1,130.6,425.7z"
                />
              </g>
              <g>
                <path
                  d="M612.6,399.5c-0.7-3.6-1.9-7.1-3.5-10.4c-1.6-3.3-3.6-6.4-5.8-9.1c-2.2-2.7-4.6-4.8-6.8-6.8c-2.2-1.9-4.2-3.6-5.9-5.2
				c-3.5-3.1-6-6-7.6-8.2c-1.7-2.2-2.6-3.7-3.5-3.5c-0.8,0.1-1.5,2-0.9,5.4c0.3,1.6,0.9,3.6,1.9,5.6c1,2,2.4,4.2,4,6.3
				c3.3,4.3,7.7,8.3,11.3,12.7c3.5,4.3,6.4,9.6,8.4,15.4c1,3,1.7,5.9,2.1,8.6c0.4,2.7,0.7,5.4,0.7,7.9c0,5-0.5,9.6-1.6,14.1
				c-1.1,4.5-2.6,9-4.9,13.8c-2.3,4.8-5.4,9.9-9.8,15.6c4.8-5.3,8.4-10.1,11.3-14.7c2.9-4.6,5.2-9,7-13.5c1.8-4.6,3.3-9.3,4.1-14.8
				c0.4-2.8,0.6-5.7,0.6-8.9C613.7,406.6,613.4,403.2,612.6,399.5z"
                />
              </g>
              <g>
                <path
                  d="M580.2,404.8c-0.8-5.7-1-11.4-0.9-16.8c0.1-5.4,0.4-10.6,0.8-15c0.7-8.9,0.9-15.2-0.8-15.7c-1.7-0.4-4.9,5.1-7.5,14.3
				c-1.2,4.6-2.2,10.1-2.4,16.2c-0.2,6,0.4,12.6,2.2,18.9c1.9,6.5,4.3,11.6,7.1,15.9c1.4,2.2,2.8,4.1,4.3,5.9
				c1.4,1.8,2.9,3.4,4.2,5.1c2.6,3.3,5,6.9,5.9,11.6c0.2,1.2,0.4,2.4,0.4,3.7c0,1.3,0,2.6-0.2,4c-0.4,2.7-1.3,5.6-3,8.4
				c1.9-2.7,3.1-5.5,3.8-8.2c0.4-1.4,0.6-2.7,0.7-4.1c0.1-1.3,0.1-2.6,0.1-3.9c-0.1-2.5-0.7-5-1.5-7.2c-0.8-2.2-1.8-4.3-2.9-6.2
				c-2.2-3.9-4.5-7.5-6.2-11.7C582.4,415.5,581,410.7,580.2,404.8z"
                />
              </g>
              <g>
                <path
                  d="M593.6,391.6c-1-7.1-3.4-13.9-5.9-18.7c-2.5-4.8-4.9-7.5-5.7-7.1c-0.8,0.4,0.2,3.8,1.8,8.7c1.6,4.9,3.7,11.4,5.5,17.9
				c1.8,6.8,2.7,11.6,3.3,16.5c0.5,4.9,0.8,9.9,0.1,17.3c1.2-7.3,1.6-12.4,1.8-17.4C594.6,403.9,594.6,398.9,593.6,391.6z"
                />
              </g>
              <g>
                <path
                  d="M588.3,385.7c-0.4-5.3-1.8-10.5-3.4-14.1c-1.6-3.7-3.4-5.8-4.2-5.5c-0.8,0.3-0.6,3,0.2,6.7c0.7,3.8,1.9,8.6,3.1,13.5
				c1.2,5.1,1.9,8.6,2.3,12.2c0.4,3.6,0.7,7.4,0.3,12.8c0.9-5.4,1.3-9.2,1.6-12.9C588.5,394.8,588.7,391.1,588.3,385.7z"
                />
              </g>
              <g>
                <path
                  d="M583.8,385.6c0.2-8.5-1.7-16.9-3.5-16.8c-1.8,0.1-2.4,8.7-0.9,17.1c1.6,8.6,2.6,11.8,3.8,20.8
				C583,397.7,583.5,394.4,583.8,385.6z"
                />
              </g>
              <g>
                <path
                  d="M38.3,418.2c3.6-0.5,7.1-1.2,9.7-2c2.6-0.8,4.4-1.7,4.3-2.5c-0.1-0.9-2-1.5-4.9-1.6c-2.9-0.1-6.7,0.5-10.3,1.9
				c-3.7,1.5-6,2.8-8.4,4.1c-2.3,1.3-4.7,2.7-8,4.8c3.6-1.6,6.2-2.4,8.7-3C32.1,419.3,34.6,418.8,38.3,418.2z"
                />
              </g>
              <g>
                <path
                  d="M37.7,425.6c3.2-0.1,6.2-0.5,8.6-1.1c2.3-0.5,4-1.2,4-2.1c0-0.9-1.6-1.7-4.2-2.1c-2.6-0.4-6-0.1-9.3,0.9
				c-3.3,1.1-5.5,2.1-7.6,3.2c-2.1,1.1-4.2,2.2-7.3,3.9c3.3-1.2,5.6-1.8,7.9-2.1C32.2,425.9,34.5,425.7,37.7,425.6z"
                />
              </g>
              <g>
                <path
                  d="M59.5,432.6c2.4,0.8,4.7,1.4,6.6,1.6c1.9,0.2,3.4,0.1,3.8-0.7c0.4-0.8-0.5-2.1-2.3-3.2c-1.8-1.1-4.5-2-7.3-2
				c-2.9,0-4.8,0.3-6.8,0.7c-1.9,0.4-3.8,0.8-6.5,1.5c2.8-0.2,4.7,0,6.5,0.4C55.3,431.3,57.1,431.8,59.5,432.6z"
                />
              </g>
              <g>
                <path
                  d="M80.6,446.8c4,0.5,8.1-0.5,10.7-1.9c2.7-1.4,4-3.1,3.6-3.8c-0.4-0.8-2.4-0.5-5-0.1c-2.6,0.4-5.9,1-9.4,1.5
				c-3.6,0.4-6.1,0.3-8.7,0c-2.5-0.4-5.2-1.1-8.6-3.1c3.1,2.4,5.6,3.7,8.1,4.9C73.9,445.3,76.5,446.2,80.6,446.8z"
                />
              </g>
              <g>
                <path
                  d="M84.1,455.5c2.2,0,4.3-0.8,5.6-1.8c1.3-1,1.9-2.2,1.5-3c-0.4-0.8-1.6-1-3.1-0.9c-1.5,0.1-3.2,0.6-4.9,1.4
				c-1.8,0.8-3.1,1.4-4.4,1.8c-1.4,0.4-2.8,0.8-4.9,0.8c2.1,0.5,3.5,0.8,5,1.1C80.4,455.3,81.9,455.5,84.1,455.5z"
                />
              </g>
              <g>
                <path
                  d="M-6.8,468.2c-3.8,3.9-6.3,9-7.3,13c-1,4-0.7,6.8,0.2,7c0.9,0.2,2.1-2.2,3.8-5.5c1.7-3.3,4-7.6,6.8-11.7
				c2.9-4.2,5.4-6.9,8.1-9.3c2.8-2.3,5.8-4.6,11-6.6c-5.3,1.5-8.8,3.2-12.1,5C0.3,462-2.9,464.1-6.8,468.2z"
                />
              </g>
              <g>
                <path
                  d="M-0.6,480.6c-1,1.9-1.8,3.9-2.4,5.8c-0.3,1-0.5,1.9-0.7,2.8c-0.2,0.9-0.3,1.9-0.3,2.8c0,0.9,0.1,1.8,0.4,2.6
				c0.3,0.8,0.7,1.5,1.2,1.9c0.5,0.4,1,0.6,1.4,0.6c0.4,0,0.6-0.1,0.7-0.3c0.2-0.4-0.1-1,0-1.6c0-0.6,0.1-1.5,0.5-2.7
				c0.2-0.6,0.4-1.2,0.7-2.1c0.3-0.8,0.6-1.7,0.8-2.5c0.6-1.8,1.1-3.7,1.7-5.6c1.1-4,2.1-6.7,3.2-9.3c1.2-2.7,2.5-5.3,5-8.9
				c-2.9,3.2-4.8,5.6-6.7,7.9C3.3,474.3,1.5,476.7-0.6,480.6z"
                />
              </g>
              <g>
                <path
                  d="M7.5,492.9c-0.1,2.4,0.6,4.8,1.6,6.4c1,1.6,2.2,2.3,3,2c0.8-0.4,1.1-1.7,1-3.3c-0.1-1.6-0.5-3.6-1.2-5.6
				c-0.7-2.1-1.2-3.6-1.5-5.1c-0.3-1.6-0.5-3.2-0.3-5.6c-0.7,2.3-1.2,3.9-1.6,5.5C8.1,488.7,7.7,490.4,7.5,492.9z"
                />
              </g>
              <g>
                <path
                  d="M19.5,498.4c0.8,2,2.3,3.6,3.7,4.4c1.4,0.8,2.7,0.9,3.3,0.2c0.6-0.7,0.3-1.9-0.3-3.1c-0.6-1.3-1.7-2.7-3-3.9
				c-1.4-1.3-2.4-2.3-3.2-3.3c-0.9-1.1-1.7-2.2-2.5-4.2c0.3,2.1,0.5,3.5,0.7,4.9C18.5,494.9,18.7,496.4,19.5,498.4z"
                />
              </g>
              <g>
                <path
                  d="M36.3,500.3c1.6,0.8,3.4,0.8,4.7,0.4c1.3-0.4,2-1.3,2-2.2c-0.1-0.9-0.9-1.6-1.9-2c-1.1-0.5-2.4-0.7-3.8-0.5
				c-1.5,0.2-2.6,0.2-3.7,0.2c-1.1,0-2.3-0.2-3.8-0.8c1.1,0.9,2.1,1.7,3.2,2.6C33.7,498.7,34.7,499.5,36.3,500.3z"
                />
              </g>
              <g>
                <path
                  d="M173.3,453.4c1.6-3.6,2.3-7.6,2.2-11.5c-0.1-3.9-1-7.8-2.9-11.2c-1-1.7-2.2-3.2-3.6-4.5c-1.5-1.3-3.2-2.3-5.1-2.8
				c-3.8-1.1-7.5-0.3-10.2,1.3c-2.8,1.6-4.7,4.3-5.4,6.7c-0.8,2.5-0.6,4.7-0.2,6.4c0.5,1.7,1.2,2.9,1.8,3.6c0.6,0.7,1.1,0.9,1.2,0.8
				c0.4-0.3-0.5-1.9-0.7-4.8c0-1.4,0.2-3.2,1-4.9c0.8-1.7,2.2-3.3,4.2-4.2c2-1,4.6-1.3,6.8-0.5c2.3,0.8,4.4,2.7,5.8,5.3
				c1.4,2.6,2.2,5.7,2.4,8.9c0.2,3.2-0.3,6.6-1.4,9.7c-1.2,3.2-3,6-4.8,8.5c-1.9,2.5-3.8,4.8-5.6,7.1c-0.9,1.2-1.7,2.3-2.5,3.5
				c-0.4,0.6-0.7,1.2-1.1,1.8l-0.5,0.9l-0.5,0.9c-0.3,0.6-0.6,1.2-0.9,1.8c-0.3,0.6-0.5,1.2-0.8,1.8c-0.6,1.2-0.9,2.5-1.4,3.7
				c-0.8,2.5-1.4,5.1-1.8,7.8c-0.2,1.3-0.3,2.7-0.4,4.1c0,0.7,0,1.4,0,2.1c0,0.7,0,1.4,0,2.2c0.1,2.9,0.5,6,1.3,9.3
				c0.8,3.2,2,6.6,3.8,10c-1.7-3.5-2.7-6.9-3.4-10.1c-0.6-3.2-0.9-6.3-0.8-9.2c0-0.7,0-1.4,0.1-2.1c0.1-0.7,0.1-1.4,0.2-2.1
				c0.2-1.4,0.3-2.7,0.6-4c0.5-2.6,1.3-5,2.2-7.4c1.9-4.7,4.5-9,8.3-13.1c1.9-2.1,4-4.2,6.1-6.7C169.4,460,171.7,457.1,173.3,453.4z
				"
                />
              </g>
              <g>
                <path
                  d="M154.7,445.2c-2.1,1.9-3.9,4-5.1,5.8c-1.2,1.8-1.8,3.3-1.2,4c0.6,0.7,2.2,0.3,4.2-0.9c1.9-1.2,4.1-3.4,5.6-6.1
				c0.8-1.4,1.3-2.5,1.8-3.6c0.5-1.1,0.9-2.1,1.2-3.2c0.3-1.1,0.5-2.2,0.4-3.5c-0.1-1.2-0.5-2.6-1.7-3.7c0.9,1.3,1,2.6,0.8,3.7
				c-0.2,1.1-0.7,2-1.2,2.7c-0.6,0.8-1.2,1.5-2,2.3C156.6,443.3,155.7,444.2,154.7,445.2z"
                />
              </g>
              <g>
                <path
                  d="M157.3,453.9c-1.7,1.7-3.1,3.6-4,5.2c-0.9,1.6-1.2,3-0.6,3.7c0.6,0.6,2.1,0.3,3.7-0.9c1.6-1.1,3.4-3.1,4.4-5.5
				c1.1-2.4,1.7-4.2,2.2-6c0.6-1.9,1.1-3.7,1.8-6c-1.2,2.3-2.3,3.8-3.5,5.1C160.3,450.9,159.1,452.2,157.3,453.9z"
                />
              </g>
              <g>
                <path
                  d="M187.3,434.6c0.8-2.6,0.7-5.4,0.1-7.4c-0.6-2-1.7-3.1-2.5-2.9c-0.9,0.2-1.4,1.5-1.6,3.3c-0.3,1.8-0.4,4-0.3,6.3
				c0.1,2.4,0,4.2-0.2,5.9c-0.3,1.7-0.7,3.5-1.9,5.9c1.6-2.1,2.7-3.7,3.6-5.2C185.5,438.9,186.5,437.3,187.3,434.6z"
                />
              </g>
              <g>
                <path
                  d="M196,440.1c1.4-0.9,2.5-2.1,3.2-3.2c0.7-1.1,1-2.2,0.6-3c-0.5-0.7-1.7-0.9-3.1-0.4c-1.4,0.5-3,1.8-4,3.6
				c-1,1.8-1.4,3.2-1.8,4.5c-0.4,1.3-0.7,2.6-1.2,4.5c1-1.7,1.9-2.6,2.8-3.5C193.5,441.8,194.5,441.1,196,440.1z"
                />
              </g>
              <g>
                <path
                  d="M204.1,446c1.7-0.2,3.1-0.8,4.2-1.5c1.1-0.7,1.8-1.6,1.7-2.5c-0.1-0.9-1.2-1.5-2.7-1.6c-1.5-0.1-3.4,0.4-5,1.6
				c-1.6,1.2-2.5,2.2-3.5,3.2c-1,1.1-1.9,2.2-3,3.4c1.6-1,2.8-1.5,4-1.8C201.1,446.4,202.3,446.2,204.1,446z"
                />
              </g>
              <g>
                <path
                  d="M255.9,442.9c-2.8,2-5.5,3.9-7.6,5.6c-2,1.6-3.4,3-3,3.8c0.3,0.8,2.4,0.8,5.1-0.2c2.7-1,6-3.1,8.4-6
				c2.5-3,3.9-5.3,5.2-7.6c1.2-2.3,2.4-4.7,3.8-8.3c-1.9,3.4-3.5,5.4-5.3,7.2C260.7,439.2,258.8,440.8,255.9,442.9z"
                />
              </g>
              <g>
                <path
                  d="M258,452.6c-2.4,3.6-3.9,7.7-4.5,10.9c-0.5,3.2-0.2,5.5,0.6,5.6c0.9,0.2,2-1.7,3.4-4.4c1.3-2.7,2.9-6.4,4.3-10.1
				c1.5-3.8,2.8-6.4,4.3-9c1.5-2.5,3.1-5,6.1-8.3c-3.4,2.9-5.5,5-7.6,7.1C262.6,446.7,260.5,448.9,258,452.6z"
                />
              </g>
              <g>
                <path
                  d="M264.1,459.8c-1.9,3.8-3,8-3.2,11.2c-0.3,3.2,0.2,5.4,1,5.5c0.9,0.1,1.9-1.8,3-4.6c1.1-2.9,2.3-6.7,3.3-10.6
				c1-4,1.9-6.8,2.9-9.6c1-2.8,2.2-5.5,4.4-9.3c-2.7,3.5-4.4,6-6.1,8.5C267.7,453.4,266.1,455.9,264.1,459.8z"
                />
              </g>
              <g>
                <path
                  d="M266.4,475.3c-1.1,3.9-1.3,8-0.8,11c0.4,3,1.4,5,2.2,4.9c0.9-0.1,1.5-2,2-4.9c0.5-2.8,0.8-6.6,1-10.3
				c0.2-3.9,0.5-6.6,0.9-9.3c0.5-2.7,1.1-5.5,2.6-9.4c-2,3.6-3.2,6.2-4.4,8.8C268.6,468.7,267.5,471.3,266.4,475.3z"
                />
              </g>
              <g>
                <path
                  d="M332.1,491.8c-2.3,0.4-4.4,1.4-5.7,2.6c-1.3,1.2-1.9,2.4-1.4,3.2c0.5,0.8,1.8,0.9,3.4,0.5c1.6-0.3,3.5-1.1,5.3-2.2
				c1.9-1.2,3.2-2,4.6-2.7c1.4-0.7,2.9-1.4,5.1-2c-2.1,0-3.8,0.1-5.6,0.1C336.1,491.3,334.5,491.4,332.1,491.8z"
                />
              </g>
              <g>
                <path
                  d="M338.2,497.8c-2,0.5-2.5,2.8-1.3,4.1c1.2,1.3,3.4,1.1,4.2-0.8c0.8-1.9,0.9-3,2-4.7C341.2,497.3,340.2,497.3,338.2,497.8z
				"
                />
              </g>
              <g>
                <path d="M432.9,506.6c-3.6,0.1-6.5,2.6-5.9,4.2c0.7,1.6,4.1,1.7,7,0c3-1.8,4.1-2.8,7.6-4C438.1,507,436.6,506.6,432.9,506.6z" />
              </g>
              <g>
                <path
                  d="M152,526.9c-2.4-3.5-5.6-6.8-9.9-9.1c-2.1-1.1-4.4-2-6.8-2.5c-2.4-0.5-5-0.6-7.6-0.1c-5.2,0.9-9.7,3.7-12.6,7.9
				c-0.7,1.1-1.3,2.2-1.8,3.4c-0.5,1.2-0.8,2.5-0.9,3.7c-0.3,2.4,0,4.7,0.7,6.8c0.7,2.1,1.8,4,3.3,5.5c1.5,1.5,3.3,2.6,5,3.2
				c3.6,1.1,6.6,0.4,8.6-0.7c2.1-1.1,3.2-2.7,3.5-3.9c0.2-0.6,0.2-1.1,0.1-1.5c-0.1-0.4-0.3-0.7-0.6-0.8c-0.3-0.2-0.6-0.3-1-0.3
				c-0.4,0-0.8,0-1.2-0.1c-0.8,0-1.8-0.1-2.7-0.4c-0.9-0.3-2-0.9-2.4-1.5c-0.2-0.3-0.3-0.7-0.4-1.1c-0.1-0.4-0.1-0.8-0.1-1.4
				c0-0.5,0.2-1,0.4-1.4c0.1-0.2,0.2-0.4,0.3-0.5c0.1-0.2,0.3-0.4,0.5-0.5c0.4-0.4,0.9-0.7,1.5-1c0.6-0.3,1.3-0.5,2-0.6
				c1.4-0.2,3.4,0.1,5.5,0.9c2,0.8,4.2,2.4,6.2,4.4c1.1,1,2.1,2.2,3,3.4c0.9,1.2,1.8,2.3,2.6,3.5c1.5,2.4,2.8,4.7,3.8,7.1
				c2,4.7,3.3,9.3,3.7,14.1c0.5,4.8,0.2,9.7-1.1,15.2c-1.3,5.4-3.8,11.3-8.4,17.4c5.2-5.5,8.6-11.1,10.9-16.5
				c2.3-5.4,3.7-10.6,4.3-15.9c0.6-5.3,0.5-10.8-0.6-16.8c-0.6-3-1.4-6.1-2.7-9.4c-0.7-1.6-1.4-3.3-2.3-5
				C154.2,530.4,153.2,528.7,152,526.9z"
                />
              </g>
              <g>
                <path
                  d="M180.5,567.9c3.9-1.9,7.3-4.1,9.8-6.3c2.5-2.1,4.1-4.2,3.6-5.9c-0.5-1.6-3.3-2.4-7.1-1.7c-3.7,0.7-8.4,3.2-11.7,7
				c-3.4,3.9-5.2,7-6.8,10c-1.6,3.1-3.1,6.2-4.9,10.7c2.8-4.1,5.3-6.3,7.8-8.3C173.8,571.6,176.5,569.9,180.5,567.9z"
                />
              </g>
              <g>
                <path
                  d="M174.5,542.4c0.4-3.8-1-7.4-2.8-9.6c-1.9-2.2-4.1-3-5.6-2.2c-1.6,0.9-2.2,2.9-2.2,5.3c0,2.4,0.6,5.1,1.9,7.8
				c1.3,2.9,2.2,5,2.8,7.3c0.6,2.3,1,4.7,0.6,8.2c1.4-3.3,2.3-5.6,3.2-8C173.2,548.8,174.1,546.3,174.5,542.4z"
                />
              </g>
              <g>
                <path
                  d="M59.8,573.1c-3.7,3.4-7.3,6.6-11.1,9.2c-3.7,2.5-7.5,4.5-10.6,4.9c-3.2,0.5-5.8-0.6-7.3-1.7c-1.6-1.1-2.1-2.3-2.6-2.2
				c-0.2,0.1-0.3,0.4-0.3,1.1c0.1,0.7,0.4,1.7,1.1,2.7c0.7,1.1,1.9,2.2,3.5,3c1.6,0.8,3.7,1.3,5.9,1.3c2.2-0.1,4.5-0.6,6.7-1.5
				c2.2-0.9,4.3-2.1,6.4-3.4c4.2-2.8,8-6.4,11.4-10.2c3.5-3.9,6.3-7.3,8.9-10.2c2.6-2.9,5.1-5.4,7.9-7.7c2.7-2.2,5.7-4.2,9.4-5.8
				c3.7-1.5,8.2-2.7,13.6-2c-5.3-1-10-0.1-13.9,1.2c-3.9,1.3-7.2,3.1-10.3,5.1c-3.1,2-5.9,4.3-8.9,7
				C66.7,566.4,63.5,569.6,59.8,573.1z"
                />
              </g>
              <g>
                <path
                  d="M73.8,568.7c-1.9,3-3.2,6.4-4,9.7c-0.8,3.3-0.9,6.4-0.8,9.1c0.4,5.4,2.1,8.6,2.9,8.4c0.9-0.2,0.8-3.6,1.3-8.4
				c0.3-2.4,0.7-5.1,1.4-8c0.7-2.9,1.7-5.9,3-8.8c1.4-3,2.8-5.4,4.4-7.4c1.6-2,3.2-3.7,4.9-5.3c1.8-1.5,3.7-3,6-4.3
				c2.3-1.3,5.1-2.5,8.6-3.4c-3.5,0.6-6.4,1.6-9,2.6c-2.5,1.1-4.7,2.2-6.8,3.5c-2.1,1.3-4,2.8-6.1,4.7
				C77.8,563.3,75.8,565.6,73.8,568.7z"
                />
              </g>
              <g>
                <path
                  d="M82.3,565.7c-1.2,2.3-2,4.8-2.4,7.2c-0.4,2.4-0.3,4.7,0,6.6c0.6,3.9,2.2,6.1,3,5.9c0.9-0.2,0.9-2.7,1.2-6
				c0.1-1.7,0.4-3.6,0.7-5.6c0.4-2,0.9-4.2,1.5-6.3c0.7-2.2,1.5-4,2.4-5.5c0.9-1.6,1.8-2.9,2.9-4.2c1.1-1.3,2.3-2.5,3.8-3.7
				c1.5-1.2,3.3-2.3,5.7-3.3c-2.4,0.8-4.4,1.7-6.1,2.6c-1.7,0.9-3.2,1.9-4.6,3c-1.4,1.1-2.7,2.3-4.1,3.8
				C84.9,561.6,83.5,563.4,82.3,565.7z"
                />
              </g>
              <g>
                <path
                  d="M126.8,555.4c3.6,0.7,7.5,0,9.9-1.3c2.5-1.3,3.7-2.8,3.3-3.6c-0.4-0.8-2.2-0.7-4.6-0.4c-2.4,0.3-5.3,0.6-8.5,0.9
				c-3.2,0.2-5.5,0-7.8-0.4c-2.3-0.5-4.6-1.3-7.6-3.4c2.6,2.5,4.7,3.9,6.9,5.1C120.8,553.5,123.1,554.6,126.8,555.4z"
                />
              </g>
              <g>
                <path
                  d="M138.6,563.1c1.7,1.4,3.4,2.4,4.9,3.1c1.5,0.7,2.8,0.9,3.4,0.3c0.6-0.6,0.4-2-0.8-3.5c-1.1-1.5-3.1-3.1-5.5-3.8
				c-2.4-0.7-4.2-0.9-5.9-1c-1.7-0.1-3.4-0.2-5.8-0.2c2.4,0.5,3.9,1.2,5.3,2C135.6,560.8,136.9,561.7,138.6,563.1z"
                />
              </g>
              <g>
                <path
                  d="M141,578.8c0.3,2.1,1,4.1,1.8,5.5c0.8,1.5,1.7,2.4,2.6,2.3c0.9-0.1,1.5-1.4,1.5-3.2c0.1-1.8-0.5-4.2-1.8-6.2
				c-1.3-2.1-2.4-3.4-3.5-4.6c-1.1-1.2-2.2-2.5-3.8-4.2c1.2,2.1,1.7,3.6,2.2,5.2C140.4,575.1,140.7,576.6,141,578.8z"
                />
              </g>
              <g>
                <path
                  d="M143.4,505.7c0.1-2,0.1-3.7-0.1-5.2c-0.2-1.5-0.5-2.7-1.3-3c-0.8-0.3-2,0.5-2.8,2.3c-0.8,1.7-1,4.4-0.2,6.7
				c0.9,2.4,1.9,3.8,3,5.1c1.1,1.3,2.2,2.5,4,4c-1.5-1.8-2-3.4-2.3-4.8C143.4,509.2,143.3,507.7,143.4,505.7z"
                />
              </g>
              <g>
                <path
                  d="M158.8,498.6c0.9-1.8,1.7-3.4,2.3-4.9c0.6-1.4,0.9-2.7,0.4-3.4c-0.5-0.7-2.1-0.5-3.6,0.8c-1.6,1.3-3.1,3.7-3.3,6.4
				c-0.2,2.8,0.3,4.6,0.9,6.3c0.6,1.7,1.4,3.3,2.8,5.3c-1-2.3-1.1-4-0.9-5.5C157.5,501.9,157.9,500.4,158.8,498.6z"
                />
              </g>
              <g>
                <path
                  d="M187.9,576c2.7-0.4,5.2-1.1,7.1-1.9c1.9-0.8,3.2-1.7,3.1-2.6c-0.1-0.9-1.6-1.5-3.8-1.5c-2.2,0-5.1,0.5-7.7,1.8
				c-2.7,1.3-4.4,2.5-6.1,3.6c-1.8,1.2-3.5,2.4-5.8,4c2.7-1.2,4.6-1.8,6.5-2.3C183.2,576.8,185.2,576.4,187.9,576z"
                />
              </g>
              <g>
                <path
                  d="M149,392.3c3,0.1,5.8-1,7.6-2.1c1.8-1.1,2.7-2.4,2.4-3.2c-0.3-0.8-1.9-1-3.9-0.7c-2,0.3-4.4,1-6.7,1.7
				c-2.4,0.8-4.2,1-6,0.9c-1.8-0.1-3.8-0.5-5.9-2.5c1.7,2.3,3.6,3.4,5.4,4.3C143.8,391.6,145.9,392.2,149,392.3z"
                />
              </g>
              <g>
                <path
                  d="M134,321c-1.8,2.8-3.2,6-3.8,9.2c-0.6,3.2-0.5,6.3,0.2,8.9c0.7,2.6,1.8,4.5,2.9,5.6c0.5,0.6,1,0.9,1.4,1.1
				c0.4,0.2,0.7,0.2,0.8,0.1c0.7-0.6-0.7-3.3-1-7.6c-0.1-2.1,0-4.6,0.5-7.2c0.5-2.6,1.5-5.3,2.8-8c1.3-2.8,2.7-5.1,4-7.1
				c1.3-2.1,2.6-3.9,3.9-5.7c1.3-1.9,2.6-3.7,4-5.8c1.4-2.1,2.9-4.5,4.3-7.6c-1.6,2.9-3.4,5.1-5,7.1c-1.6,1.9-3.2,3.5-4.8,5.2
				c-1.6,1.6-3.1,3.2-4.8,5.1C137.7,315.9,135.8,318.1,134,321z"
                />
              </g>
              <g>
                <path
                  d="M143.2,322.4c-0.7,5.5,0.4,11.2,2,15.1c1.6,3.9,3.6,6,4.4,5.6c0.8-0.4,0.3-3.1-0.4-6.8c-0.7-3.8-1.4-8.7-1.7-13.7
				c-0.2-5.2,0.2-8.9,1-12.5c0.8-3.6,2-7.3,5-12.1c-3.4,4.5-5.2,8-6.8,11.5C145.2,313,143.9,316.7,143.2,322.4z"
                />
              </g>
              <g>
                <path
                  d="M149.7,316.6c0,4,1.3,7.8,2.8,10.4c1.5,2.6,3.2,3.9,3.9,3.4c0.8-0.4,0.5-2.4-0.1-5c-0.6-2.6-1.5-6-2.3-9.4
				c-0.9-3.5-1.2-6.1-1.4-8.7c-0.1-2.6,0-5.3,1-9.2c-1.5,3.7-2.3,6.3-2.9,9C150.2,309.8,149.7,312.5,149.7,316.6z"
                />
              </g>
              <g>
                <path
                  d="M93.8,341.5c3.2-2.6,5.7-6,7.1-8.8c1.4-2.8,1.7-5,0.9-5.4c-0.8-0.4-2.4,0.9-4.4,3c-2,2.1-4.4,5-6.8,8.1
				c-2.4,3.1-4.3,5.2-6.3,7.2c-2,2-4.2,3.8-7.8,6.1c3.8-1.8,6.4-3.2,8.9-4.6C88,345.7,90.5,344.2,93.8,341.5z"
                />
              </g>
              <g>
                <path
                  d="M102.3,347.6c2.7-0.3,5.3-1,7.2-1.7c1.9-0.8,3.2-1.7,3.1-2.5c-0.1-0.9-1.6-1.5-3.8-1.6c-2.2-0.1-5.1,0.4-7.7,1.7
				c-2.7,1.3-4.4,2.4-6.2,3.5c-1.8,1.2-3.6,2.3-5.9,3.8c2.7-1.2,4.7-1.7,6.6-2.1C97.6,348.2,99.5,347.9,102.3,347.6z"
                />
              </g>
              <g>
                <path
                  d="M119.4,246.7c1.9-1.6,3.5-3.5,4.5-5.2c1-1.6,1.4-3,0.8-3.7c-0.6-0.6-2.1-0.3-3.8,0.8c-1.7,1.1-3.6,3-4.9,5.4
				c-1.3,2.4-2,4.2-2.8,5.9c-0.8,1.9-1.5,3.7-2.5,6c1.5-2.3,2.8-3.7,4.1-5C116.1,249.6,117.4,248.4,119.4,246.7z"
                />
              </g>
              <g>
                <path
                  d="M20.7,384.4c-7.8,3.2-14.9,7.9-19.7,12.1c-4.8,4.2-7.2,7.7-6.7,8.3c0.6,0.7,4.1-1.6,9.2-5c5.1-3.3,11.9-7.6,19-11.4
				c7.3-3.9,12.7-6.1,18.2-8c5.5-1.8,11.3-3.3,20-4.3c-8.8,0.4-14.7,1.3-20.5,2.4C34.5,379.7,28.7,381.1,20.7,384.4z"
                />
              </g>
              <g>
                <path
                  d="M27.1,386.9c-6.2,3.1-11.7,7.5-15.3,11.3c-3.6,3.8-5.4,6.9-4.8,7.4c0.7,0.6,3.5-1.3,7.5-4.3c4-3,9.3-6.9,14.8-10.6
				c5.7-3.8,9.8-6.1,14.1-8.2c4.3-2,8.9-3.9,15.8-5.7c-7.1,1.2-11.9,2.5-16.5,3.9C38.1,382.1,33.5,383.7,27.1,386.9z"
                />
              </g>
              <g>
                <path
                  d="M-19.8,372.5c-3.2-2.4-6.3-4.7-8.9-6.3c-2.5-1.6-4.5-2.5-5.1-1.9c-0.6,0.6,0.3,2.7,2.4,5.1c2.1,2.4,5.5,5.1,9.3,6.9
				c4,1.8,6.8,2.7,9.6,3.6c2.9,0.8,5.7,1.6,10,2.5c-4.1-1.4-6.8-2.8-9.3-4.2C-14.2,376.7-16.6,375-19.8,372.5z"
                />
              </g>
              <g>
                <path
                  d="M-14.5,384.4c-0.9,0-1.8,0.2-2.7,0.6c-0.9,0.3-1.9,1-2.5,2c-0.6,1-0.6,2.1-0.3,2.7c0.3,0.7,0.9,0.8,1.3,0.7
				c0.4-0.1,0.8-0.4,1.2-0.5c0.4-0.1,0.7-0.1,1-0.1c0.2,0.1,0.6,0,1.2-0.2c0.7-0.2,1.4-0.5,2.1-1c1.4-0.9,2.5-1.5,3.6-2
				c1.1-0.5,2.3-0.9,4.1-1.2c-1.6-0.2-2.9-0.5-4.4-0.7C-11.3,384.6-12.6,384.4-14.5,384.4z"
                />
              </g>
              <g>
                <path
                  d="M-13.6,395.2c-2.1,1.6-3.6,3.7-4.4,5.6c-0.7,1.8-0.7,3.3,0,3.8c0.8,0.5,2-0.2,3.4-1.4c1.4-1.2,2.9-3,4.1-5
				c1.3-2.1,2.3-3.5,3.4-4.9c1.1-1.4,2.3-2.7,4.3-4.4c-2.3,1.2-4,2-5.6,2.9C-9.8,392.7-11.4,393.6-13.6,395.2z"
                />
              </g>
              <g>
                <path d="M-9.5,421.5c-1.3,2.9-0.3,6,1.4,6.3c1.7,0.2,3.5-2.6,2.9-5.7c-0.5-3.2-1.1-4.4-1.2-7.7C-7.4,417.5-8.2,418.6-9.5,421.5z" />
              </g>
              <g>
                <path d="M-2.1,415.6c-1.2,2.5-0.2,5.3,1.5,5.5c1.7,0.2,3.4-2.2,2.8-4.9c-0.6-2.8-1.2-3.9-1.4-6.7C0,412.2-0.9,413.1-2.1,415.6z" />
              </g>
              <g>
                <path
                  d="M578.1,305.9c3.3-4.2,6.4-8.7,8.9-13.6c2.5-4.9,4.2-10.3,4.9-15.7c0.7-5.4,0.4-10.9-1.2-15.8c-0.4-1.2-0.8-2.4-1.3-3.6
				c-0.5-1.2-1.1-2.3-1.8-3.4c-1.4-2.2-3.1-4.2-5.3-5.7c-1.1-0.8-2.3-1.3-3.6-1.8c-1.3-0.4-2.7-0.6-4-0.5c-2.5,0.1-4.9,0.9-6.8,2.5
				c-0.9,0.8-1.7,1.7-2.3,2.7c-0.6,1-0.8,2.1-1,3.1c-0.2,2,0.2,3.7,0.7,5c1.1,2.5,2.5,3.9,3.5,4.8c1,0.9,1.7,1.1,2.1,0.9
				c0.4-0.2,0.3-1,0.1-2.2c-0.2-1.2-0.8-2.9-1-4.7c-0.1-0.9,0-1.8,0.3-2.5c0.2-0.3,0.4-0.7,0.6-0.9c0.2-0.2,0.5-0.4,0.9-0.6
				c0.7-0.3,1.7-0.5,2.7-0.3c0.5,0.1,1,0.2,1.4,0.4c0.4,0.2,0.9,0.6,1.4,1c0.9,0.8,1.8,2,2.5,3.4c0.7,1.4,1.3,3.2,1.8,5
				c0.9,3.6,1,7.7,0.4,12c-0.6,4.2-1.9,8.6-3.8,12.8c-1.9,4.3-4.4,8.5-7.2,12.6c-2.9,4.3-5.8,8.2-8.5,11.9
				c-2.7,3.7-5.2,7.3-7.5,10.8c-2.2,3.5-4.2,7-5.8,10.6c-1.6,3.6-2.8,7.2-3.7,10.9c-1.7,7.4-2.1,15.1-0.8,23.5
				c0.6,4.2,1.7,8.5,3.4,13c1.7,4.4,4,9,7.3,13.5c-3-4.6-5.1-9.3-6.5-13.7c-1.4-4.5-2.1-8.8-2.4-12.9c-0.6-8.2,0.5-15.5,2.8-22.3
				c1.2-3.4,2.6-6.6,4.4-9.7c1.8-3.1,4-6.2,6.4-9.3c2.4-3.1,5.2-6.3,8.2-9.7C571.4,313.8,574.7,310.2,578.1,305.9z"
                />
              </g>
              <g>
                <path
                  d="M561.9,353.7c1-1.7,2-3.3,3.1-4.8c1.1-1.5,2.2-2.8,3.4-4.1c1.1-1.3,2.4-2.4,3.5-3.4c0.3-0.2,0.6-0.5,0.9-0.7
				c0.3-0.2,0.5-0.4,0.8-0.6c0.5-0.4,1.1-0.7,1.6-1c2.2-1.2,4-1.8,5.4-2c1.5-0.3,2.7-0.1,3.3-0.7c0.3-0.3,0.4-0.8,0.2-1.5
				c-0.2-0.7-0.8-1.5-1.9-2.3c-1-0.8-2.5-1.4-4.3-1.6c-1.7-0.2-3.7-0.1-5.6,0.3c-1,0.2-2,0.6-3,1c-0.5,0.2-1,0.5-1.5,0.7
				c-0.5,0.3-0.9,0.5-1.4,0.8c-1.8,1.1-3.7,2.5-5.3,4.2c-1.6,1.7-3.1,3.6-4.4,5.6c-1.2,2.1-2.2,4.3-3,6.5c-1.6,4.7-2.1,8.7-2.3,12.3
				c-0.2,3.6,0,6.8,0.3,9.9c0.7,6.3,1.9,12.4,3.8,21.4c-0.9-9.1-0.8-15.4,0-21.4c0.4-3,1-5.9,1.9-9
				C558.6,360.4,559.9,357.1,561.9,353.7z"
                />
              </g>
              <g>
                <path
                  d="M574.5,279.9c-0.6-1.9-1.5-3.7-2.6-5.3c-0.6-0.8-1.2-1.6-1.9-2.3c-0.7-0.7-1.5-1.3-2.3-1.9c-1.7-1.1-3.9-1.8-6.2-1.5
				c-1.1,0.2-2.1,0.5-3,1.1c-0.9,0.6-1.6,1.4-2.1,2.4c-0.4,0.9-0.6,1.9-0.6,2.8c0,0.9,0.2,1.6,0.5,2.3c0.5,1.3,1.2,2.1,1.8,2.7
				c1.2,1.2,2.1,1.6,2.4,1.4c0.4-0.3,0.1-1.2-0.4-2.6c-0.2-0.7-0.5-1.5-0.6-2.4c-0.1-0.9,0.1-1.5,0.5-2c0.4-0.4,1.1-0.8,2-0.9
				c0.8-0.1,1.9,0.3,2.9,1c1.1,0.7,2.1,1.8,3.1,2.9c0.9,1.2,1.7,2.5,2.4,3.9c1.3,3,1.8,5.9,1.9,8.5c0.1,2.6-0.2,5.1-0.8,7.5
				c-1.1,4.8-3.2,9.6-6.8,16.2c4.1-6.4,6.7-10.8,8.6-15.6c0.9-2.4,1.7-4.9,2-7.9C575.6,287.2,575.6,283.8,574.5,279.9z"
                />
              </g>
              <g>
                <path
                  d="M568.9,290c-0.3-0.5-0.5-1.1-0.8-1.6c-0.3-0.5-0.7-1.1-1.2-1.5c-0.9-1-1.9-1.8-3.4-2.3c-0.7-0.3-1.5-0.4-2.3-0.4
				c-0.8,0-1.6,0.3-2.3,0.6c-1.4,0.7-2.2,1.8-2.6,2.8c-0.5,1-0.6,2-0.6,2.7c0,0.8,0.1,1.4,0.3,1.9c0.3,1,0.7,1.5,1.2,1.5
				c0.4,0,0.9-0.5,1.3-1.4c0.4-0.8,0.9-2,1.5-2.7c0.3-0.4,0.7-0.6,1-0.7c0.3,0,0.5,0,0.9,0.1c0.4,0.2,1,0.5,1.6,1.1
				c0.3,0.3,0.6,0.5,0.8,0.9c0.3,0.3,0.5,0.7,0.8,1.1c1.1,1.5,1.8,3,2.3,4.5c0.5,1.4,0.8,2.8,0.9,4.2c0.1,1.4,0.1,2.9-0.3,4.5
				c-0.3,1.6-0.9,3.4-2.1,5.3c1.4-1.7,2.3-3.4,3-5c0.7-1.6,1.1-3.1,1.3-4.7c0.2-1.6,0.4-3.1,0.2-4.9
				C570.3,294.2,569.9,292.2,568.9,290z"
                />
              </g>
              <g>
                <path
                  d="M548.8,301.3c-1.9-0.3-3.8-0.3-5.2,0c-1.5,0.3-2.6,0.9-2.7,1.8c-0.2,0.9,0.7,1.8,2.3,2.4c1.6,0.6,3.8,0.8,5.9,0.2
				c2.2-0.6,3.6-1.2,5-1.9c1.5-0.7,2.9-1.4,4.7-2.2c-2.1,0.4-3.6,0.5-5.1,0.4C552.1,301.9,550.7,301.7,548.8,301.3z"
                />
              </g>
              <g>
                <path
                  d="M536.3,308.9c-5.8,0.2-11.1,2.8-10.6,4.5c0.4,1.7,6.2,1.7,11.5-0.2c5.5-1.9,7.5-3,13.4-4.3
				C544.5,309.2,542.3,308.8,536.3,308.9z"
                />
              </g>
              <g>
                <path
                  d="M525.6,316.8c-3.9-0.7-7.6,0.8-7.6,2.5c0.1,1.8,4,2.9,7.8,1.8c3.9-1.1,5.3-1.9,9.3-2.6
				C531,318.2,529.6,317.5,525.6,316.8z"
                />
              </g>
              <g>
                <path
                  d="M537.6,319c-2.2,1.1-3.9,2.9-4.8,4.6c-0.9,1.6-1.1,3.1-0.4,3.6c0.7,0.6,2,0.2,3.4-0.7c1.5-0.9,3.1-2.3,4.5-4
				c1.5-1.7,2.6-2.9,3.8-4.1c1.2-1.1,2.5-2.2,4.6-3.4c-2.4,0.7-4,1.3-5.6,1.8C541.5,317.3,539.9,317.8,537.6,319z"
                />
              </g>
              <g>
                <path
                  d="M545.3,322.1c-1.5,1.4-2.5,3.1-2.9,4.6c-0.4,1.5-0.2,2.7,0.6,3.2c0.8,0.4,1.9,0,3-1c1.1-1,2.2-2.5,2.9-4.3
				c1.5-3.7,1.8-5.3,4.1-8.8C549.9,318.6,548.4,319.3,545.3,322.1z"
                />
              </g>
              <g>
                <path
                  d="M554.7,308c-1.3,0.9-2.1,2.2-2.4,3.4c-0.3,1.2,0,2.2,0.7,2.7c0.7,0.5,1.8,0.3,2.7-0.3c1-0.6,1.9-1.7,2.4-3
				c1.1-2.8,1.3-4.1,3.2-6.7C558.7,305.8,557.4,306.2,554.7,308z"
                />
              </g>
              <g>
                <path
                  d="M532.3,341c-1.2,1.5-1.8,3.3-1.8,4.8c0,1.5,0.5,2.5,1.4,2.7c0.9,0.2,1.8-0.4,2.6-1.5c0.8-1,1.5-2.5,1.8-4.2
				c0.3-1.7,0.5-3,0.9-4.2c0.3-1.2,0.8-2.4,1.7-4.1c-1.4,1.3-2.3,2.2-3.3,3.1C534.5,338.6,533.5,339.5,532.3,341z"
                />
              </g>
              <g>
                <path
                  d="M533.2,356.5c-2.1,3.9-1.8,8.5-0.1,8.9c1.7,0.4,3.9-3.4,4.3-7.6c0.4-4.4,0.2-6.1,1.4-10.5
				C536.7,351.3,535.4,352.5,533.2,356.5z"
                />
              </g>
              <g>
                <path
                  d="M533.9,379.3c-0.4,2.4,0.2,4.9,1,6.6c0.8,1.7,2,2.6,2.8,2.3c0.8-0.3,1.2-1.6,1.3-3.2c0.1-1.7-0.2-3.7-0.7-5.8
				c-0.5-2.2-0.8-3.7-1-5.4c-0.2-1.6-0.2-3.3,0.2-5.7c-0.9,2.3-1.5,3.8-2.1,5.5C534.8,375.2,534.2,376.8,533.9,379.3z"
                />
              </g>
              <g>
                <path
                  d="M570.2,359.3c1.9-2.2,3.7-4.2,5-6c1.3-1.7,2.2-3.2,1.7-3.9c-0.5-0.7-2.3-0.5-4.4,0.8c-2.2,1.3-4.6,3.7-6,6.8
				c-1.4,3.1-1.9,5.4-2.3,7.6c-0.4,2.2-0.6,4.5-0.6,7.8c0.5-3.2,1.4-5.3,2.4-7.2C567.1,363.2,568.3,361.5,570.2,359.3z"
                />
              </g>
              <g>
                <path
                  d="M580.7,319.9c1.7-0.7,3-1.7,4-2.7c0.9-1,1.4-2.1,1.1-2.9c-0.4-0.8-1.6-1.1-3.1-0.8c-1.5,0.3-3.3,1.3-4.6,2.8
				c-1.3,1.6-2.1,2.8-2.8,4c-0.8,1.3-1.5,2.6-2.4,4.1c1.4-1.4,2.6-2.1,3.8-2.7C577.8,321.1,579,320.6,580.7,319.9z"
                />
              </g>
              <g>
                <path
                  d="M-6.5,258.1c1.9-0.4,3.5-1.1,4.7-2c1.2-0.9,1.9-1.9,1.6-2.7c-0.2-0.8-1.4-1.3-3-1.2c-1.6,0.1-3.5,0.7-5.2,2
				c-1.7,1.3-2.7,2.3-3.8,3.3c-1,1-2.1,2-3.7,3.4c1.9-0.9,3.2-1.4,4.6-1.7C-9.8,258.7-8.5,258.5-6.5,258.1z"
                />
              </g>
              <g>
                <path
                  d="M8.4,246.2c-1.5,2.6-2.1,5.6-2,7.8c0.1,2.3,0.8,3.7,1.7,3.8c0.9,0.1,1.7-1.2,2.5-3.1c0.8-1.9,1.5-4.3,2-6.9
				c0.5-2.7,1.1-4.5,1.7-6.4c0.7-1.8,1.5-3.7,3.3-6.1c-2.1,2-3.5,3.5-4.9,5.1C11.2,242,9.9,243.6,8.4,246.2z"
                />
              </g>
              <g>
                <path
                  d="M544.8,223.7c-1.8-1.5-3.8-2.7-6-3.5c-2.1-0.9-4.5-1.4-6.9-1.4c-1.2,0-2.4,0.2-3.5,0.6c-1.2,0.4-2.3,0.9-3.2,1.6
				c-1.9,1.4-3.2,3.3-3.7,5.3c-0.3,1-0.3,2-0.2,3.1c0.2,1,0.5,1.8,0.9,2.5c0.9,1.4,2.1,2.3,3.1,2.7c1.1,0.4,2,0.4,2.6,0.2
				c0.6-0.2,0.8-0.4,0.8-0.6c-0.1-0.5-1.1-0.6-2.2-1.7c-1.1-1-2.1-3.1-1.2-5c0.4-1,1.3-2,2.4-2.7c0.6-0.4,1.2-0.6,1.9-0.8
				c0.7-0.2,1.5-0.3,2.2-0.3c3.2,0,7,1.4,10.2,3.5c3.3,2.2,5.7,4.8,7.4,7.5c0.9,1.3,1.6,2.7,2.2,4c0.6,1.4,1.1,2.7,1.5,4.1
				c0.8,2.8,1.2,5.8,1.2,9.2c0,3.4-0.4,7.1-1.6,11.5c1.4-4.3,2.2-8,2.5-11.5c0.3-3.4,0.2-6.5-0.3-9.6c-0.3-1.5-0.6-3.1-1-4.6
				c-0.5-1.5-1.1-3.1-1.9-4.7C550.7,230.1,548.4,226.7,544.8,223.7z"
                />
              </g>
              <g>
                <path
                  d="M547.9,236.8c-0.9-1.3-2.2-2.4-3.6-3.2c-1.5-0.8-3.3-1.2-5-1c-1.8,0.2-3.4,1.2-4.5,2.4c-1.1,1.2-1.7,2.9-1.6,4.4
				c0.1,1.7,1,2.9,1.8,3.6c0.9,0.7,1.9,1.1,2.7,1.1c0.8,0,1.4-0.2,1.8-0.5c0.3-0.3,0.4-0.6,0.3-0.8c-0.2-0.4-0.9-0.6-1.4-1.1
				c-0.3-0.3-0.6-0.6-0.8-1.1c-0.2-0.4-0.3-0.9-0.1-1.2c0.1-0.4,0.4-0.8,0.8-1.2c0.5-0.4,1.1-0.7,1.7-0.7c1.3-0.2,3.2,0.6,4.7,2
				c1.6,1.5,2.6,3.5,3.6,5.3c0.9,1.8,1.7,3.4,2.3,5.1c0.6,1.7,1.2,3.5,1.6,5.5c0.4,2,0.7,4.4,0.8,7.1c0.2-2.8,0.2-5.1,0.1-7.2
				c-0.1-2.1-0.3-4-0.6-5.9c-0.3-1.9-0.7-3.7-1.3-5.8c-0.3-1-0.6-2.1-1.1-3.3C549.5,239.4,548.9,238.1,547.9,236.8z"
                />
              </g>
              <g>
                <path
                  d="M613.1,514.1c0.5,1.2,0.7,2.4,0.8,3.5c0,1.1-0.1,2-0.4,2.7c-0.3,0.7-0.7,1.4-1.3,2.1c-0.6,0.6-1.4,1.3-2.4,1.7
				c-1.9,1-4,1.4-6.1,1.3c-2-0.1-3.6-0.8-4.7-1.5c-1.2-0.9-2-2.1-2.3-3.2c-0.4-1-0.3-2.3,0.1-3.4c0.4-1.1,0.9-2,1.4-2.8
				c0.5-0.8,0.9-1.4,0.9-2.1c0-0.6-0.6-1.3-1.9-1.6c-1.3-0.3-3.3,0-5.5,1.3c-1.1,0.7-2.1,1.6-3.1,2.9c-0.5,0.7-0.9,1.4-1.2,2.2
				c-0.4,0.8-0.6,1.8-0.8,2.7c-0.7,3.8,0.2,8.2,2.9,11.9c2.8,3.9,7.2,6.5,12,7.4c4.7,1,10.1,0.5,14.9-1.9c2.4-1.2,4.7-2.8,6.7-5
				c2-2.2,3.6-4.9,4.4-8c0.8-3,0.8-6.2,0.3-9c-0.6-2.9-1.7-5.4-3.1-7.7c-1.5-2.3-3.3-4.3-5.2-5.9c-1.9-1.6-4.1-2.9-6.1-3.7
				c-4.1-1.7-8-2.3-11.5-2.4c-7.1-0.1-12.9,1.4-18.6,3.1c-5.6,1.8-11.1,4.1-16.8,7.4c-5.7,3.2-11.6,7.4-17.6,13.4
				c6.6-5.4,12.9-8.7,18.8-11c5.9-2.3,11.4-3.5,16.8-4.2c5.4-0.6,11-0.7,16.3,0.5c2.7,0.6,5.3,1.6,7.5,3.1c1.2,0.8,2,1.6,2.8,2.6
				C611.9,511.7,612.6,512.9,613.1,514.1z"
                />
              </g>
              <g>
                <path
                  d="M586.9,487.9c2.8-1.8,5.2-4.2,7.1-6.8c1.9-2.6,3.2-5.3,3.9-7.8c0.7-2.5,0.8-4.7,0.6-6.3c-0.3-1.6-0.9-2.5-1.8-2.7
				c-1.8-0.3-3.7,2.1-6.3,5.3c-1.3,1.7-2.7,3.5-4.3,5.4c-1.6,2-3.3,4.1-5.1,6.3c-1.8,2.3-3.6,4.1-5.2,5.7c-1.7,1.6-3.2,3.1-4.9,4.4
				c-3.3,2.7-6.9,5.3-12.6,8.5c6.1-2.3,10.3-3.7,14.5-5.2c2.1-0.8,4.2-1.6,6.5-2.7C581.6,491.1,584.1,489.8,586.9,487.9z"
                />
              </g>
              <g>
                <path
                  d="M574,520.4c-1.2,2.3-1.3,5.1-0.8,7c0.5,1.9,1.5,3,2.4,2.8c0.9-0.2,1.4-1.4,1.8-3c0.4-1.6,0.7-3.5,0.8-5.6
				c0.2-2.2,0.5-3.7,1-5.3c0.5-1.5,1.3-3,2.9-4.9c-2,1.4-3.3,2.6-4.5,3.9C576.4,516.6,575.3,518,574,520.4z"
                />
              </g>
              <g>
                <path
                  d="M580.2,543.9c0.4,2.6,1.7,5,3.2,6.4c1.5,1.4,2.9,1.9,3.6,1.3c0.7-0.6,0.5-2,0-3.6c-0.5-1.6-1.4-3.6-2.5-5.5
				c-1.1-2-1.8-3.6-2.3-5.2c-0.5-1.6-0.9-3.3-0.8-5.9c-0.6,2.5-0.9,4.3-1.1,6.1C580,539.4,579.8,541.2,580.2,543.9z"
                />
              </g>
              <g>
                <path
                  d="M599.3,558c1.3,1.4,3,2.4,4.7,2.8c1.7,0.5,3.4,0.5,4.8,0.2c1.4-0.3,2.4-0.9,3-1.4c0.6-0.6,0.8-1.1,0.7-1.5
				c-0.4-0.8-2-0.9-3.9-1.2c-2-0.3-4.3-0.9-6.5-2.2c-1.2-0.7-2.1-1.5-2.9-2.3c-0.8-0.8-1.4-1.7-2-2.6c-1.2-1.8-2.1-3.9-3.1-7.1
				c0.5,3.3,0.8,5.6,1.4,7.8c0.3,1.1,0.6,2.3,1.2,3.5C597.2,555.2,598,556.6,599.3,558z"
                />
              </g>
              <g>
                <path
                  d="M613.7,553.2c2.2,1.1,4.7,1.3,6.5,1c1.8-0.3,3-1.2,2.9-2.1c-0.1-0.9-1.2-1.6-2.7-2.1c-1.5-0.5-3.5-0.9-5.5-1
				c-2.2-0.2-3.7-0.4-5.2-0.7c-1.5-0.4-3.1-0.9-5.1-2.2c1.7,1.7,2.9,2.8,4.2,3.9C610.1,551,611.5,552.1,613.7,553.2z"
                />
              </g>
              <g>
                <path
                  d="M623.6,545.9c1.5,0.1,2.9-0.6,3.8-1.4c0.8-0.8,1.1-1.9,0.7-2.6c-0.4-0.8-1.3-1.2-2.4-1.2c-1,0-2.2,0.3-3.3,1
				c-1.1,0.8-1.9,1.3-2.8,1.8c-0.9,0.5-1.8,0.9-3.2,1.2c1.4,0.3,2.5,0.5,3.5,0.7C620.9,545.6,622,545.8,623.6,545.9z"
                />
              </g>
              <g>
                <path
                  d="M584.7,454.4c0.8-4,0.4-8.1-0.6-11.1c-0.9-2.9-2.3-4.6-3.1-4.4c-0.9,0.3-1.1,2.3-1,5c0,2.8,0.2,6.4,0.3,10.1
				c0.1,3.8,0,6.5-0.4,9.1c-0.4,2.7-1,5.4-2.7,9.1c2.2-3.5,3.4-6,4.5-8.6C582.9,461.2,583.9,458.5,584.7,454.4z"
                />
              </g>
              <g>
                <path
                  d="M175.8,236.9c-0.3,3.3,0.7,6.7,2.2,8.7c1.4,2.1,3.1,2.9,3.7,2.4c0.7-0.5,0.5-2.1,0.1-4.1c-0.5-2.3-1-4.4-1.6-7.2
				c-0.5-2.8-0.6-4.8-0.4-6.9c0.2-2,0.7-4.2,2.3-6.9c-2.1,2.4-3.2,4.4-4.2,6.4C177,231.3,176.2,233.5,175.8,236.9z"
                />
              </g>
              <g>
                <path
                  d="M316.4,56.6c3.3,0.8,6.5,1.5,9,2c2.5,0.5,4.4,0.7,4.8-0.1c0.4-0.7-0.8-2.4-3.3-3.8c-2.5-1.5-6.3-2.6-10.1-2.5
				c-3.9,0.1-6.5,0.7-9.1,1.5c-2.5,0.8-5,1.6-8.5,3.4c3.6-1.2,6.2-1.4,8.7-1.5C310.5,55.6,313,55.9,316.4,56.6z"
                />
              </g>
              <g>
                <path
                  d="M166.4,149.7c1.4,3.4,3.9,6.3,6.1,7.9c2.2,1.7,4.1,2.1,4.7,1.5c0.6-0.7-0.3-2.3-1.7-4.4c-1.4-2.1-3.3-4.6-5.3-7.2
				c-2.1-2.7-3.3-4.7-4.4-6.9c-1.1-2.2-2-4.5-2.6-8.1c0.1,3.7,0.4,6.2,0.8,8.7C164.4,143.7,165,146.2,166.4,149.7z"
                />
              </g>
              <g>
                <path
                  d="M79.1,161.1c1.1-2.5,1.1-5.4,0.4-7.4c-0.7-2-1.8-3-2.6-2.8c-0.9,0.3-1.3,1.6-1.5,3.2c-0.3,1.7-0.4,3.7-0.5,5.9
				c-0.1,2.3-0.4,3.9-0.9,5.5c-0.5,1.6-1.3,3.2-3.1,5.1c2.1-1.5,3.4-2.8,4.7-4.2C76.7,165.2,77.9,163.7,79.1,161.1z"
                />
              </g>
              <g>
                <path
                  d="M340.8,260.3c3.5-6.8,4.5-14.6,2.8-15.1c-1.7-0.5-5.1,6.3-6.9,13.5c-0.9,3.8-1.5,6.4-2.2,9c-0.7,2.6-1.5,5.2-2.9,9
				c1.9-3.5,3.3-5.9,4.7-8.3C337.6,266.1,339,263.8,340.8,260.3z"
                />
              </g>
              <g>
                <path
                  d="M485,235.3c3.3,0,6.5-0.3,9-0.8c2.4-0.5,4.1-1.2,4.2-2.1c0-0.9-1.7-1.7-4.2-2.1c-2.6-0.4-6.1-0.3-9.4,0.6
				c-3.4,0.9-5.7,1.8-8,2.7c-2.4,1-4.7,1.9-7.8,3.1c3.5-0.8,5.9-1.1,8.3-1.3C479.3,235.3,481.6,235.3,485,235.3z"
                />
              </g>
              <g>
                <path
                  d="M596.5,141.5c-2.7,0.3-5.3,1.3-7,2.5c-1.7,1.2-2.6,2.4-2.2,3.2c0.4,0.8,1.9,0.9,3.8,0.6c1.9-0.3,4.3-1,6.5-2.1
				c2.4-1.1,4-1.9,5.8-2.5c1.7-0.7,3.5-1.2,6.3-1.7c-2.6-0.1-4.5-0.1-6.6-0.2C601.2,141.3,599.3,141.2,596.5,141.5z"
                />
              </g>
              <g>
                <path
                  d="M548.5,79c-2.4,1.8-4.6,3.6-6.2,5.2c-1.6,1.6-2.6,2.9-2.2,3.7c0.4,0.8,2.2,0.8,4.5-0.2c2.3-1,5.1-3,7.1-5.6
				c2-2.7,3.1-4.8,4-6.9c0.9-2.1,1.8-4.2,2.9-7.4c-1.5,3-3,4.7-4.5,6.3C552.6,75.7,550.9,77.1,548.5,79z"
                />
              </g>
              <g>
                <path
                  d="M482.8,101.2c3.5-0.6,7-1.5,9.5-2.4c2.6-0.9,4.3-1.9,4.2-2.8c-0.1-0.9-2.1-1.4-4.9-1.3c-2.8,0.1-6.6,0.8-10,2.3
				c-3.6,1.5-5.9,2.8-8.2,4.1c-2.3,1.3-4.6,2.7-7.9,4.7c3.6-1.5,6.1-2.3,8.6-2.9C476.6,102.3,479.2,101.9,482.8,101.2z"
                />
              </g>
              <g>
                <path
                  d="M503.3,526.1c2.5-1.7,3.3-4.9,2-6c-1.4-1.1-4.3,0.4-5.4,3.3c-1.2,2.9-1.3,4.3-2.8,7.1
				C499.4,528.3,500.7,527.9,503.3,526.1z"
                />
              </g>
              <g>
                <path
                  d="M281.3,518.4c2,0.3,3.8,0.4,5.3,0.2c1.5-0.1,2.7-0.5,3-1.3c0.3-0.8-0.5-2-2.2-2.8c-1.6-0.8-4.1-1.2-6.5-0.5
				c-2.4,0.7-3.9,1.5-5.3,2.3c-1.4,0.9-2.8,1.8-4.6,3.2c2.1-1,3.6-1.3,5.1-1.4C277.8,518,279.3,518.1,281.3,518.4z"
                />
              </g>
            </g>
          </g>
          <g id="Peddle15">
            <defs>
              <path
                id="SVGID_00000050641556846480765770000006073995150204346534_"
                d="M88.4,311.2c44.3-18.3,245.3-68.5,259.5-34.1
			c14.1,34.4-163.9,140.3-208.2,158.6C95.4,453.9,14.6,449.2,2.4,419.4C-9.9,389.6,44.1,329.4,88.4,311.2z"
              />
            </defs>
            <use
              xlinkHref="#SVGID_00000050641556846480765770000006073995150204346534_"
              overflow="visible"
              fill="#003301"
            />
            <clipPath id="SVGID_00000023267812373619166290000001408112097161702049_">
              <use
                xlinkHref="#SVGID_00000050641556846480765770000006073995150204346534_"
                overflow="visible"
              />
            </clipPath>
            <g
              opacity="0.1"
              clipPath="url(#SVGID_00000023267812373619166290000001408112097161702049_)"
            >
              <g>
                <g>
                  <path
                    d="M312.1,219.6c8.1-1.2,15.4-3.8,20.8-7c5.4-3.2,8.8-6.9,8.1-10.4c-0.7-3.4-5.5-5.7-12.2-5.8c-6.7-0.1-15.4,2-22.9,6.8
					c-7.7,4.9-12.4,9-17.2,13c-4.7,4-9.4,8-16.3,13.7c8.1-3.8,13.9-5.6,19.8-6.9C297.9,221.7,303.8,220.8,312.1,219.6z"
                  />
                </g>
                <g>
                  <path
                    d="M314.5,239.9c7,1.8,13.9,2.1,19.5,1.1c5.5-1,9.7-3.2,10.5-6.6c0.8-3.4-2.4-7.3-8.1-9.9c-5.6-2.6-13.8-3.8-21.8-2.1
					c-8.2,1.8-13.5,3.9-18.8,6c-5.3,2.1-10.5,4.2-18,7.1c8-0.8,13.5-0.4,18.9,0.5C302,236.8,307.2,238.1,314.5,239.9z"
                  />
                </g>
                <g>
                  <path
                    d="M303.9,258.7c4.2,3.4,8.8,5.1,13,5.4c4.2,0.3,8.1-0.9,10-3.9c1.9-3,1.1-7.2-2.1-10.9c-3.1-3.7-8.6-6.8-14.9-7.1
					c-6.5-0.4-10.9,0.2-15.2,0.7c-4.3,0.5-8.6,1-14.6,1.5c5.8,1.7,9.5,3.7,12.9,5.9C296.4,252.6,299.5,255.2,303.9,258.7z"
                  />
                </g>
                <g>
                  <path
                    d="M282.6,276.1c1.5,4.7,4.5,8,8,9.7c3.4,1.7,7.3,1.9,10.2-0.1c2.9-2,4.1-5.7,3.3-9.8c-0.7-4-3.4-8.3-7.9-11
					c-4.6-2.8-8.1-4.2-11.5-5.8c-3.4-1.6-6.6-3.2-10.9-6.1c2.8,4.3,4.2,7.7,5.3,11.2C280.2,267.7,281.1,271.2,282.6,276.1z"
                  />
                </g>
                <g>
                  <path
                    d="M262,284.1c-1.5,5-1.3,9.6,0.1,13.5c1.3,3.8,3.8,7,7.3,7.6c3.4,0.7,7.1-1.5,9.3-5.8c2.2-4.2,3-10.5,0.8-16.4
					c-2.3-6-4.6-9.7-6.8-13.4c-2.3-3.6-4.5-7.1-7.6-12.2c1,5.8,0.8,9.9,0.1,13.8C264.5,275.2,263.5,279,262,284.1z"
                  />
                </g>
                <g>
                  <path
                    d="M238.4,276.8c-4.1,3.3-6.7,7-8.2,10.8c-0.3,1-0.7,1.9-0.9,2.9c-0.2,1-0.3,1.9-0.3,2.8c0,1.8,0.4,3.6,1.4,5
					c1,1.4,2.6,2.3,4.6,2.6c1,0.1,2.1,0.1,3.2-0.1c1.1-0.2,2.4-0.5,3.5-1.1c4.8-2.1,9.8-7.2,11.7-13.9c1.9-6.8,2-11.6,2-16.2
					c-0.1-4.6-0.3-9.1-0.8-15.3c-1.6,6-3.8,9.7-6.4,12.9C245.6,270.5,242.6,273.3,238.4,276.8z"
                  />
                </g>
                <g>
                  <path
                    d="M221.4,254.8c-5.9,0.7-10.8,2.7-14.6,5.4c-3.7,2.7-6.2,6.2-5.7,9.7c0.5,3.5,4.2,6.1,9.6,6.6c5.3,0.5,12.3-1.2,17.7-5.6
					c5.6-4.6,8.8-8.3,11.8-12c3-3.7,5.9-7.3,10.2-12.6c-5.6,3.6-10,5.2-14.3,6.3C231.8,253.5,227.4,254.1,221.4,254.8z"
                  />
                </g>
                <g>
                  <path
                    d="M214.7,232.3c-5.8-1.9-11.2-2.4-15.9-1.9c-1.2,0.2-2.3,0.3-3.3,0.7c-1.1,0.3-2,0.7-2.9,1.1c-1.7,0.9-3.1,2.2-3.7,3.8
					c-0.6,1.6-0.4,3.5,0.5,5.3c0.5,0.9,1.2,1.9,2,2.7c0.9,0.9,1.9,1.8,3.1,2.5c4.8,3.1,12.4,4.8,19.7,3.2c7.5-1.7,12.3-4,16.9-6.2
					c4.5-2.3,9-4.7,15-8.3c-6.9,1.6-11.7,1.4-16.4,0.8C225.1,235.5,220.6,234.3,214.7,232.3z"
                  />
                </g>
                <g>
                  <path
                    d="M221.6,214.1c-3.8-4.1-7-7.1-10.2-9.4c-1.6-1.2-3.2-2.2-4.8-3.1c-1.6-0.9-3.3-1.6-5.1-1.4c-1.7,0.1-3.3,1.2-4.4,3.3
					c-1.1,2.1-1.4,5.3-0.6,8.8c0.8,3.5,2.7,7.3,5.7,10.4c2.9,3.1,6.8,5.6,11,6.8c4.4,1.3,7.9,1.6,11.1,1.7c3.2,0.1,5.9-0.1,8.6-0.4
					c5.4-0.6,10.5-1.4,17.9-2.1c-7.3-1.5-12.2-3.2-16.6-5.4c-2.2-1.1-4.3-2.4-6.4-3.9C225.6,217.9,223.5,216.2,221.6,214.1z"
                  />
                </g>
                <g>
                  <path
                    d="M239.5,196.3c-0.1-5.5-0.9-9.8-2.3-13.6c-0.7-1.9-1.5-3.7-2.5-5.3c-1-1.6-2.2-2.9-3.9-3.6c-1.6-0.7-3.5-0.5-5.4,0.6
					c-1.9,1.1-3.7,3.3-4.9,6.3c-1.2,3-1.8,6.7-1.4,10.5c0.4,3.8,1.7,7.8,3.9,11.1c2.3,3.5,4.6,5.9,6.7,7.9c2.2,2,4.2,3.6,6.3,5.1
					c4.1,3,8.3,5.7,14,9.6c-4.3-5.5-6.5-9.9-8-14.2c-0.7-2.2-1.3-4.4-1.8-6.8C239.9,201.7,239.6,199.1,239.5,196.3z"
                  />
                </g>
                <g>
                  <path
                    d="M259.2,191.2c2.3-5.4,3.1-10.6,2.9-15.2c-0.1-1.2-0.2-2.2-0.4-3.3c-0.2-1.1-0.5-2-0.9-2.9c-0.8-1.8-1.9-3.2-3.5-4
					c-1.6-0.8-3.4-0.8-5.3,0c-1,0.4-2,1-2.9,1.8c-0.9,0.8-1.9,1.7-2.8,2.9c-3.5,4.5-5.8,11.9-4.6,19.3c1.3,7.6,3.3,12.4,5.3,17
					c2.1,4.6,4.3,9,7.7,15c-1.4-6.8-1-11.6-0.1-16C255.5,201.1,256.9,196.9,259.2,191.2z"
                  />
                </g>
                <g>
                  <path
                    d="M281.1,191.4c4.3-4.1,7.2-8,9.3-11.7c1-1.9,1.9-3.7,2.4-5.6c0.5-1.8,0.7-3.7,0-5.3c-0.6-1.6-2.1-2.9-4.3-3.4
					c-2.2-0.6-5.1-0.3-8.2,0.8c-3.1,1.2-6.3,3.3-8.9,6.2c-2.6,2.9-4.7,6.5-5.8,10.5c-1.2,4-1.6,7.4-1.8,10.4c-0.2,3-0.2,5.7-0.2,8.3
					c0.1,5.2,0.3,10.3,0,17.6c2.4-6.9,4.7-11.5,7.3-15.6c1.3-2.1,2.8-4,4.4-6C277.1,195.6,278.9,193.5,281.1,191.4z"
                  />
                </g>
                <g>
                  <path
                    d="M256.7,233.3c-1.5,3.2,1.5,6,5,5.6c3.5-0.4,5.8-3.7,3.7-6.5c-2.2-2.9-3.7-3.9-5.1-7C259.6,228.7,258.2,230,256.7,233.3z
					"
                  />
                </g>
                <g>
                  <path
                    d="M261.4,230.6c-1.4,1-1.8,2.3-1.5,3.7c0.3,1.4,1.3,2.8,2.8,3.6c1.5,0.9,3.3,0.9,4.7,0.2c1.4-0.7,2.3-2.2,2.1-4.1
					c-0.2-2-0.6-3.3-0.9-4.7c-0.3-1.3-0.6-2.6-0.7-4.4c-0.9,1.5-1.9,2.4-2.9,3.1C263.9,228.9,262.8,229.6,261.4,230.6z"
                  />
                </g>
                <g>
                  <path
                    d="M264.9,232.2c-1.3-0.6-2.1-0.2-2.9,0.7c-0.7,0.9-1.4,2.4-1.5,4.2c-0.1,1.7,0.7,3.3,2.1,4.1c1.4,0.8,3.3,0.7,4.8-0.7
					c1.5-1.4,2.2-2.7,2.8-3.8c0.6-1.2,1.2-2.3,2.1-3.6c-1.5,0.5-2.7,0.5-3.8,0.3C267.3,233.2,266.2,232.7,264.9,232.2z"
                  />
                </g>
                <g>
                  <path
                    d="M267.4,236.8c0-0.7-0.3-1.3-0.7-1.8c-0.5-0.5-1.1-1-1.9-1.2c-0.8-0.3-1.6-0.3-2.4-0.1c-0.8,0.2-1.5,0.6-2.1,1.3
					c-0.6,0.6-1.1,1.3-1.4,2c-0.3,0.7-0.6,1.4-0.6,2c0,0.7,0.1,1.3,0.5,2c0.4,0.6,1.1,1.3,2.1,1.7c1,0.5,2.1,0.8,3,1
					c0.9,0.2,1.7,0.2,2.5,0.1c1.6-0.1,3.1-0.5,4.5-1.6c-0.8,0.1-1.5-0.2-2-0.6c-0.5-0.4-0.8-0.8-1-1.3c-0.2-0.5-0.3-1-0.4-1.6
					C267.4,238,267.5,237.5,267.4,236.8z"
                  />
                </g>
                <g>
                  <path
                    d="M266.9,240c0.8-1.4,0.7-2.5-0.1-3.6c-0.7-1.1-2-2.2-3.8-2.6c-1.7-0.4-3.4,0.1-4.5,1.4c-1.1,1.3-1.4,3.5-0.4,5.4
					c1.1,2,2.1,3.1,3.2,4.1c1,1,2,2,3.2,3.4c-0.2-1.8,0-3.1,0.5-4.3C265.4,242.6,266.1,241.5,266.9,240z"
                  />
                </g>
                <g>
                  <path
                    d="M264.2,244.1c1.7-1.3,2.4-3.2,2.2-4.9c-0.2-1.7-1.4-3.1-3.1-3.7c-1.7-0.6-3.4-0.3-4.7,0.5c-1.3,0.8-2.1,2-2.1,3.8
					c0,1.9,0,3.3,0,4.7c-0.1,1.4-0.4,2.8-1.2,4.5c1.6-1,2.9-1.7,4.3-2.3C260.9,246.1,262.4,245.4,264.2,244.1z"
                  />
                </g>
                <g>
                  <path
                    d="M258.2,243.2c3.6,1.4,6.6-1.7,6.2-5.2c-0.4-3.5-4.2-5.7-7.3-3.5c-3.2,2.3-4.3,3.9-7.7,5.4
					C253,240.5,254.5,241.8,258.2,243.2z"
                  />
                </g>
                <g>
                  <path
                    d="M254.5,239.4c1.8,3.2,5.8,2.6,7.7-0.4c1.8-3,0.5-6.9-3.1-7c-3.7-0.1-5.5,0.5-8.9-0.3
					C252.5,234.3,252.7,236.1,254.5,239.4z"
                  />
                </g>
                <g>
                  <path
                    d="M255,235.1c0,1.7,1.1,2.7,2.4,3c1.3,0.3,3-0.2,4.3-1.3c1.4-1.1,2.1-2.6,2.3-3.8c0.1-1.2-0.4-2.3-1.8-2.8
					c-2.9-1-4.7-1.1-6.9-3C255.8,230.1,255,231.7,255,235.1z"
                  />
                </g>
                <g>
                  <path
                    d="M299.7,201.9c5.7-1.8,10.2-4.8,13.4-8.2c3.2-3.4,5-7.2,3.9-10.6c-1.1-3.3-5.3-5.2-10.8-4.6c-5.4,0.5-12,3.6-16.6,9.1
					c-4.7,5.7-7.1,10.1-9.4,14.4c-2.2,4.4-4.4,8.6-7.4,14.7c4.8-4.8,8.8-7.3,12.8-9.3C289.6,205.4,293.8,203.9,299.7,201.9z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M624.2,225.2c3.8,4.2,8.2,7.3,12.1,9.2c3.9,1.8,7.4,2.3,9.1,0.6c1.7-1.7,1.1-5.3-1.4-9.3c-2.6-3.9-7.1-8.1-12.8-10.5
					c-5.8-2.5-10-3.6-14.1-4.8c-4.1-1.1-8.3-2.3-14.2-4.1c5.3,3.1,8.6,5.8,11.6,8.7C617.5,217.9,620.3,220.9,624.2,225.2z"
                  />
                </g>
                <g>
                  <path
                    d="M613.5,234.5c1.7,4.7,4.2,8.8,6.9,11.6c2.7,2.8,5.6,4.4,7.9,3.4c2.2-0.9,3.3-4.2,2.6-8.5c-0.7-4.2-3.2-9.4-7.3-13.3
					c-4.2-4-7.5-6.2-10.7-8.5c-3.3-2.2-6.5-4.4-11.1-7.6c3.6,4.3,5.5,7.6,7.1,11C610.4,226.1,611.7,229.6,613.5,234.5z"
                  />
                </g>
                <g>
                  <path
                    d="M598.5,235.7c-0.3,3.7,0.5,7,2,9.6c1.5,2.5,3.7,4.3,6.1,4.2c2.4-0.1,4.5-2.2,5.4-5.4c0.9-3.2,0.5-7.6-1.7-11.3
					c-2.3-3.8-4.3-6.2-6.3-8.5c-2-2.3-3.9-4.5-6.5-7.8c1.3,4,1.6,6.8,1.6,9.7C599.1,229,598.9,231.8,598.5,235.7z"
                  />
                </g>
                <g>
                  <path
                    d="M580.4,230.2c-2.1,2.7-2.8,5.7-2.5,8.4c0.3,2.6,1.7,4.9,4,5.8c2.3,0.9,4.9,0.1,6.9-1.9c2-2,3.4-5.2,3.2-8.8
					c-0.2-3.7-0.7-6.3-1.1-8.9c-0.4-2.5-0.7-5.1-0.8-8.6c-1.4,3.3-2.8,5.4-4.3,7.4C584.2,225.6,582.5,227.5,580.4,230.2z"
                  />
                </g>
                <g>
                  <path
                    d="M567.7,221.5c-3.4,1.1-6,3-7.7,5.3c-1.7,2.3-2.5,4.9-1.5,7.2c0.9,2.2,3.6,3.5,7,3.1c3.3-0.4,7.2-2.4,9.7-5.9
					c2.6-3.6,3.8-6.4,5-9.1c1.2-2.7,2.3-5.4,4-9.1c-2.9,2.8-5.4,4.3-7.8,5.4C573.9,219.5,571.3,220.4,567.7,221.5z"
                  />
                </g>
                <g>
                  <path
                    d="M562.8,205.2c-3.5-1.1-6.6-1.1-9.4-0.5c-0.7,0.2-1.4,0.3-2,0.6c-0.6,0.3-1.2,0.6-1.7,0.9c-1.1,0.7-1.9,1.6-2.3,2.8
					c-0.4,1.1-0.3,2.4,0.3,3.6c0.3,0.6,0.7,1.2,1.3,1.8c0.6,0.6,1.2,1.1,2,1.6c3.1,1.9,8,2.8,12.5,1.3c4.6-1.6,7.4-3.3,10.1-5.1
					c2.6-1.8,5.1-3.7,8.4-6.4c-4.1,1.4-7.1,1.6-9.9,1.4C569.2,206.9,566.4,206.2,562.8,205.2z"
                  />
                </g>
                <g>
                  <path
                    d="M568.7,186.9c-2.6-3.1-5.7-5.2-8.7-6.3c-3-1.1-5.9-1.1-7.7,0.5c-1.8,1.6-1.9,4.8-0.1,8.1c1.8,3.3,5.4,6.6,10.1,8
					c4.8,1.4,8.2,1.8,11.5,2.1c3.3,0.3,6.5,0.5,11.2,0.9c-4.3-1.8-6.8-3.7-9.1-5.8C573.6,192.4,571.5,190.1,568.7,186.9z"
                  />
                </g>
                <g>
                  <path
                    d="M579.1,174.3c-1.1-4.1-3-7.4-5.1-9.9c-0.6-0.6-1.1-1.2-1.7-1.7c-0.6-0.5-1.2-0.9-1.8-1.2c-1.2-0.6-2.4-0.9-3.6-0.7
					c-1.2,0.3-2.2,1.1-2.8,2.4c-0.3,0.6-0.6,1.4-0.8,2.2c-0.2,0.8-0.3,1.8-0.2,2.8c0.1,4,2,9,5.8,12.6c3.9,3.7,7,5.5,10.1,7.2
					c3.1,1.7,6.2,3.3,10.6,5.4c-3.6-3.3-5.4-6.2-6.8-9.1C581.3,181.5,580.3,178.5,579.1,174.3z"
                  />
                </g>
                <g>
                  <path
                    d="M592.2,171.1c0.9-3.8,1.3-6.8,1.4-9.5c0.1-1.3,0-2.7-0.1-4c-0.1-1.3-0.4-2.5-1.2-3.5c-0.8-0.9-2-1.4-3.6-1.2
					c-1.6,0.2-3.6,1.2-5.3,3c-1.7,1.8-3.1,4.4-3.7,7.3c-0.6,2.9-0.6,6,0.3,8.9c0.9,3,2.1,5.2,3.3,7c1.2,1.8,2.4,3.3,3.6,4.8
					c2.4,2.9,4.9,5.5,8.1,9.5c-2-4.8-2.9-8.3-3.3-11.7c-0.2-1.7-0.3-3.4-0.3-5.2C591.6,175,591.7,173.1,592.2,171.1z"
                  />
                </g>
                <g>
                  <path
                    d="M609.4,174.5c3.1-2.2,5.3-4.3,6.9-6.6c0.8-1.1,1.5-2.3,2.1-3.5c0.5-1.2,0.8-2.4,0.6-3.6c-0.2-1.2-1.1-2.2-2.5-2.8
					c-1.4-0.6-3.3-0.8-5.5-0.4c-2.2,0.4-4.5,1.5-6.6,3.3c-2,1.7-3.8,4-4.8,6.5c-1.1,2.7-1.6,4.9-1.9,6.9c-0.3,2-0.4,3.8-0.5,5.6
					c-0.1,3.5,0,6.9-0.1,11.8c1.5-4.6,3.1-7.6,5.1-10.1c1-1.3,2-2.5,3.2-3.6C606.4,176.8,607.8,175.6,609.4,174.5z"
                  />
                </g>
                <g>
                  <path
                    d="M619.9,183.9c4-0.8,7.3-2.3,9.9-4.3c0.6-0.5,1.2-1,1.7-1.5c0.5-0.5,1-1.1,1.3-1.6c0.7-1.1,1.1-2.3,1-3.6
					c-0.2-1.2-0.9-2.3-2.1-3.1c-0.6-0.4-1.3-0.8-2.1-1c-0.8-0.2-1.7-0.4-2.7-0.5c-3.9-0.3-9.1,1.3-12.8,4.9
					c-3.8,3.7-5.8,6.7-7.7,9.6c-1.8,3-3.5,6-5.6,10.2c3.4-3.4,6.2-5.1,9.1-6.3C612.8,185.6,615.8,184.7,619.9,183.9z"
                  />
                </g>
                <g>
                  <path
                    d="M628.3,196.5c4,0.9,7.4,1,10.3,0.8c1.5-0.1,2.9-0.4,4.1-0.8c1.2-0.4,2.4-1,3.1-2c0.7-1,0.8-2.3,0.3-3.8
					c-0.5-1.5-1.8-3-3.7-4.4c-1.9-1.3-4.3-2.3-7-2.7c-2.7-0.4-5.6-0.2-8.3,0.7c-2.8,0.9-4.9,2-6.7,3c-1.8,1-3.3,2.1-4.8,3.1
					c-3,2.1-5.8,4.2-10.1,6.8c4.9-1.3,8.4-1.8,11.8-1.8c1.7,0,3.4,0,5.2,0.2C624.3,195.8,626.3,196,628.3,196.5z"
                  />
                </g>
                <g>
                  <path
                    d="M594.8,198.8c-2.4,0.4-2.8,3.2-1.3,5c1.6,1.9,4.4,1.9,5.2-0.4c0.8-2.4,0.8-3.7,2-5.7
					C598.6,198.6,597.3,198.3,594.8,198.8z"
                  />
                </g>
                <g>
                  <path
                    d="M598.2,200.4c-1.1-0.4-2-0.1-2.7,0.6c-0.7,0.7-1.1,1.8-1,3c0.1,1.2,0.8,2.2,1.7,2.7c0.9,0.5,2.2,0.5,3.2-0.4
					c1-0.9,1.7-1.6,2.3-2.3c0.6-0.7,1.3-1.4,2.2-2.1c-1.2,0-2.1-0.2-2.9-0.4C600.2,201.2,599.3,200.8,598.2,200.4z"
                  />
                </g>
                <g>
                  <path
                    d="M598.6,203c-0.2-0.9-0.7-1.3-1.5-1.4c-0.8-0.1-1.9,0.1-3,0.8c-1,0.6-1.7,1.7-1.6,2.8c0.1,1.1,0.9,2.2,2.2,2.5
					c1.4,0.3,2.4,0.2,3.3,0.1c0.9-0.1,1.8-0.2,2.9-0.2c-0.9-0.7-1.3-1.4-1.6-2.1C599,204.8,598.8,204,598.6,203z"
                  />
                </g>
                <g>
                  <path
                    d="M597,206.3c0.4-0.3,0.6-0.7,0.8-1.1c0.1-0.5,0.1-1,0-1.6c-0.1-0.5-0.4-1.1-0.9-1.4c-0.4-0.4-1-0.6-1.6-0.7
					c-0.6-0.1-1.2-0.1-1.7,0c-0.5,0.1-1,0.2-1.4,0.4c-0.4,0.2-0.7,0.6-0.9,1c-0.2,0.5-0.3,1.1-0.2,1.9c0.1,0.8,0.3,1.5,0.6,2.1
					c0.3,0.6,0.6,1.1,0.9,1.5c0.7,0.8,1.5,1.6,2.7,2c-0.4-0.5-0.5-1-0.4-1.4c0-0.4,0.2-0.8,0.4-1.1c0.2-0.3,0.5-0.6,0.7-0.9
					C596.3,206.7,596.6,206.6,597,206.3z"
                  />
                </g>
                <g>
                  <path
                    d="M594.9,207.2c1.1-0.1,1.7-0.6,2.1-1.5c0.3-0.8,0.5-2,0-3.2c-0.4-1.1-1.4-1.9-2.6-2c-1.2-0.1-2.5,0.5-3.2,1.9
					c-0.7,1.4-0.9,2.4-1.1,3.4c-0.2,1-0.4,1.9-0.7,3.2c0.9-0.8,1.8-1.2,2.7-1.4C592.9,207.4,593.8,207.3,594.9,207.2z"
                  />
                </g>
                <g>
                  <path
                    d="M591.5,207.3c1.4,0.5,2.8,0.1,3.7-0.7c0.9-0.8,1.2-2,0.9-3.2c-0.3-1.2-1.2-2.1-2.1-2.5c-0.9-0.4-2-0.4-3,0.3
					c-1.1,0.7-1.9,1.3-2.7,1.8c-0.8,0.5-1.7,0.9-3.1,1.1c1.2,0.5,2.1,1,3,1.6C589.1,206.1,590.1,206.7,591.5,207.3z"
                  />
                </g>
                <g>
                  <path
                    d="M589.7,203.4c0.6,2.6,3.6,3.1,5.4,1.5c1.8-1.6,1.7-4.6-0.8-5.5c-2.6-1-3.9-1-6.1-2.3
					C589.3,199.5,589.1,200.8,589.7,203.4z"
                  />
                </g>
                <g>
                  <path
                    d="M590.5,199.9c-1.1,2.2,0.8,4.3,3.2,4.2c2.4-0.1,4.2-2.4,2.8-4.5c-1.4-2.2-2.4-3-3.3-5.2
					C592.6,196.7,591.6,197.6,590.5,199.9z"
                  />
                </g>
                <g>
                  <path
                    d="M593.1,198.5c-0.9,0.7-1.1,1.7-0.8,2.5c0.4,0.9,1.3,1.6,2.4,2c1.2,0.3,2.3,0.2,3.1-0.2c0.8-0.4,1.2-1.1,0.9-2.1
					c-0.5-2-1.2-3.1-0.9-5.1C596.3,197,595.1,197.2,593.1,198.5z"
                  />
                </g>
                <g>
                  <path
                    d="M629.5,211.2c3.3,2.5,6.7,4,9.9,4.5c3.2,0.5,6.1,0.1,7.6-1.9c1.5-1.9,0.9-5.1-1.5-8c-2.4-2.9-6.8-5.5-11.7-6
					c-5.1-0.5-8.5-0.1-11.9,0.2c-3.4,0.4-6.7,0.8-11.3,1.5c4.6,0.9,7.6,2.2,10.3,3.7C623.6,206.8,626.1,208.6,629.5,211.2z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M241.1,523.3c3.8,4.2,8.2,7.3,12.1,9.2c3.9,1.8,7.4,2.3,9.1,0.6c1.7-1.7,1.1-5.3-1.4-9.3c-2.6-3.9-7.1-8.1-12.8-10.5
					c-5.8-2.5-10-3.6-14.1-4.8c-4.1-1.1-8.3-2.3-14.2-4.1c5.3,3.1,8.6,5.8,11.6,8.7C234.3,516,237.1,519,241.1,523.3z"
                  />
                </g>
                <g>
                  <path
                    d="M230.3,532.5c1.7,4.7,4.2,8.8,6.9,11.6c2.7,2.8,5.6,4.4,7.9,3.4c2.2-0.9,3.3-4.2,2.6-8.5c-0.7-4.2-3.2-9.4-7.3-13.3
					c-4.2-4-7.5-6.2-10.7-8.5c-3.3-2.2-6.5-4.4-11.1-7.6c3.6,4.3,5.5,7.6,7.1,11C227.3,524.1,228.6,527.6,230.3,532.5z"
                  />
                </g>
                <g>
                  <path
                    d="M215.4,533.7c-0.3,3.7,0.5,7,2,9.6c1.5,2.5,3.7,4.3,6.1,4.2c2.4-0.1,4.5-2.2,5.4-5.4c0.9-3.2,0.5-7.6-1.7-11.3
					c-2.3-3.8-4.3-6.2-6.3-8.5c-2-2.3-3.9-4.5-6.5-7.8c1.3,4,1.6,6.8,1.6,9.7C216,527.1,215.7,529.9,215.4,533.7z"
                  />
                </g>
                <g>
                  <path
                    d="M197.2,528.3c-2.1,2.7-2.8,5.7-2.5,8.4c0.3,2.6,1.7,4.9,4,5.8c2.3,0.9,4.9,0.1,6.9-1.9c2-2,3.4-5.2,3.2-8.8
					c-0.2-3.7-0.7-6.3-1.1-8.9c-0.4-2.5-0.7-5.1-0.8-8.6c-1.4,3.3-2.8,5.4-4.3,7.4C201.1,523.7,199.4,525.5,197.2,528.3z"
                  />
                </g>
                <g>
                  <path
                    d="M184.6,519.6c-3.4,1.1-6,3-7.7,5.3c-1.7,2.3-2.5,4.9-1.5,7.2c0.9,2.2,3.6,3.5,7,3.1c3.3-0.4,7.2-2.4,9.7-5.9
					c2.6-3.6,3.8-6.4,5-9.1c1.2-2.7,2.3-5.4,4-9.1c-2.9,2.8-5.4,4.3-7.8,5.4C190.7,517.6,188.1,518.4,184.6,519.6z"
                  />
                </g>
                <g>
                  <path
                    d="M179.7,503.2c-3.5-1.1-6.6-1.1-9.4-0.5c-0.7,0.2-1.4,0.3-2,0.6c-0.6,0.3-1.2,0.6-1.7,0.9c-1.1,0.7-1.9,1.6-2.3,2.8
					c-0.4,1.1-0.3,2.4,0.3,3.6c0.3,0.6,0.7,1.2,1.3,1.8c0.6,0.6,1.2,1.1,2,1.6c3.1,1.9,8,2.8,12.5,1.3c4.6-1.6,7.4-3.3,10.1-5.1
					c2.6-1.8,5.1-3.7,8.4-6.4c-4.1,1.4-7.1,1.6-9.9,1.4C186,504.9,183.3,504.3,179.7,503.2z"
                  />
                </g>
                <g>
                  <path
                    d="M185.6,484.9c-2.6-3.1-5.7-5.2-8.7-6.3c-3-1.1-5.9-1.1-7.7,0.5c-1.8,1.6-1.9,4.8-0.1,8.1c1.8,3.3,5.4,6.6,10.1,8
					c4.8,1.4,8.2,1.8,11.5,2.1c3.3,0.3,6.5,0.5,11.2,0.9c-4.3-1.8-6.8-3.7-9.1-5.8C190.4,490.4,188.4,488.1,185.6,484.9z"
                  />
                </g>
                <g>
                  <path
                    d="M195.9,472.3c-1.1-4.1-3-7.4-5.1-9.9c-0.6-0.6-1.1-1.2-1.7-1.7c-0.6-0.5-1.2-0.9-1.8-1.2c-1.2-0.6-2.4-0.9-3.6-0.7
					c-1.2,0.3-2.2,1.1-2.8,2.4c-0.3,0.6-0.6,1.4-0.8,2.2c-0.2,0.8-0.3,1.8-0.2,2.8c0.1,4,2,9,5.8,12.6c3.9,3.7,7,5.5,10.1,7.2
					c3.1,1.7,6.2,3.3,10.6,5.4c-3.6-3.3-5.4-6.2-6.8-9.1C198.2,479.5,197.1,476.5,195.9,472.3z"
                  />
                </g>
                <g>
                  <path
                    d="M209.1,469.2c0.9-3.8,1.3-6.8,1.4-9.5c0.1-1.3,0-2.7-0.1-4c-0.1-1.3-0.4-2.5-1.2-3.5c-0.8-0.9-2-1.4-3.6-1.2
					c-1.6,0.2-3.6,1.2-5.3,3c-1.7,1.8-3.1,4.4-3.7,7.3c-0.6,2.9-0.6,6,0.3,8.9c0.9,3,2.1,5.2,3.3,7c1.2,1.8,2.4,3.3,3.6,4.8
					c2.4,2.9,4.9,5.5,8.1,9.5c-2-4.8-2.9-8.3-3.3-11.7c-0.2-1.7-0.3-3.4-0.3-5.2C208.4,473,208.6,471.1,209.1,469.2z"
                  />
                </g>
                <g>
                  <path
                    d="M226.2,472.6c3.1-2.2,5.3-4.3,6.9-6.6c0.8-1.1,1.5-2.3,2.1-3.5c0.5-1.2,0.8-2.4,0.6-3.6c-0.2-1.2-1.1-2.2-2.5-2.8
					c-1.4-0.6-3.3-0.8-5.5-0.4c-2.2,0.4-4.5,1.5-6.6,3.3c-2,1.7-3.8,4-4.8,6.5c-1.1,2.7-1.6,4.9-1.9,6.9c-0.3,2-0.4,3.8-0.5,5.6
					c-0.1,3.5,0,6.9-0.1,11.8c1.5-4.6,3.1-7.6,5.1-10.1c1-1.3,2-2.5,3.2-3.6C223.3,474.9,224.6,473.7,226.2,472.6z"
                  />
                </g>
                <g>
                  <path
                    d="M236.8,481.9c4-0.8,7.3-2.3,9.9-4.3c0.6-0.5,1.2-1,1.7-1.5c0.5-0.5,1-1.1,1.3-1.6c0.7-1.1,1.1-2.3,1-3.6
					c-0.2-1.2-0.9-2.3-2.1-3.1c-0.6-0.4-1.3-0.8-2.1-1c-0.8-0.2-1.7-0.4-2.7-0.5c-3.9-0.3-9.1,1.3-12.8,4.9
					c-3.8,3.7-5.8,6.7-7.7,9.6c-1.8,3-3.5,6-5.6,10.2c3.4-3.4,6.2-5.1,9.1-6.3C229.7,483.6,232.7,482.8,236.8,481.9z"
                  />
                </g>
                <g>
                  <path
                    d="M245.2,494.6c4,0.9,7.4,1,10.3,0.8c1.5-0.1,2.9-0.4,4.1-0.8c1.2-0.4,2.4-1,3.1-2c0.7-1,0.8-2.3,0.3-3.8
					c-0.5-1.5-1.8-3-3.7-4.4c-1.9-1.3-4.3-2.3-7-2.7c-2.7-0.4-5.6-0.2-8.3,0.7c-2.8,0.9-4.9,2-6.7,3c-1.8,1-3.3,2.1-4.8,3.1
					c-3,2.1-5.8,4.2-10.1,6.8c4.9-1.3,8.4-1.8,11.8-1.8c1.7,0,3.4,0,5.2,0.2C241.2,493.9,243.1,494.1,245.2,494.6z"
                  />
                </g>
                <g>
                  <path
                    d="M211.7,496.8c-2.4,0.4-2.8,3.2-1.3,5c1.6,1.9,4.4,1.9,5.2-0.4c0.8-2.4,0.8-3.7,2-5.7
					C215.4,496.7,214.2,496.4,211.7,496.8z"
                  />
                </g>
                <g>
                  <path
                    d="M215,498.4c-1.1-0.4-2-0.1-2.7,0.6c-0.7,0.7-1.1,1.8-1,3c0.1,1.2,0.8,2.2,1.7,2.7c0.9,0.5,2.2,0.5,3.2-0.4
					c1-0.9,1.7-1.6,2.3-2.3c0.6-0.7,1.3-1.4,2.2-2.1c-1.2,0-2.1-0.2-2.9-0.4C217,499.2,216.2,498.9,215,498.4z"
                  />
                </g>
                <g>
                  <path
                    d="M215.5,501.1c-0.2-0.9-0.7-1.3-1.5-1.4c-0.8-0.1-1.9,0.1-3,0.8c-1,0.6-1.7,1.7-1.6,2.8c0.1,1.1,0.9,2.2,2.2,2.5
					c1.4,0.3,2.4,0.2,3.3,0.1c0.9-0.1,1.8-0.2,2.9-0.2c-0.9-0.7-1.3-1.4-1.6-2.1C215.9,502.9,215.7,502.1,215.5,501.1z"
                  />
                </g>
                <g>
                  <path
                    d="M213.9,504.3c0.4-0.3,0.6-0.7,0.8-1.1c0.1-0.5,0.1-1,0-1.6c-0.1-0.5-0.4-1.1-0.9-1.4c-0.4-0.4-1-0.6-1.6-0.7
					c-0.6-0.1-1.2-0.1-1.7,0c-0.5,0.1-1,0.2-1.4,0.4c-0.4,0.2-0.7,0.6-0.9,1c-0.2,0.5-0.3,1.1-0.2,1.9c0.1,0.8,0.3,1.5,0.6,2.1
					c0.3,0.6,0.6,1.1,0.9,1.5c0.7,0.8,1.5,1.6,2.7,2c-0.4-0.5-0.5-1-0.4-1.4c0-0.4,0.2-0.8,0.4-1.1c0.2-0.3,0.5-0.6,0.7-0.9
					C213.1,504.8,213.5,504.6,213.9,504.3z"
                  />
                </g>
                <g>
                  <path
                    d="M211.8,505.3c1.1-0.1,1.7-0.6,2.1-1.5c0.3-0.8,0.5-2,0-3.2c-0.4-1.1-1.4-1.9-2.6-2c-1.2-0.1-2.5,0.5-3.2,1.9
					c-0.7,1.4-0.9,2.4-1.1,3.4c-0.2,1-0.4,1.9-0.7,3.2c0.9-0.8,1.8-1.2,2.7-1.4C209.7,505.4,210.6,505.4,211.8,505.3z"
                  />
                </g>
                <g>
                  <path
                    d="M208.4,505.3c1.4,0.5,2.8,0.1,3.7-0.7c0.9-0.8,1.2-2,0.9-3.2c-0.3-1.2-1.2-2.1-2.1-2.5c-0.9-0.4-2-0.4-3,0.3
					c-1.1,0.7-1.9,1.3-2.7,1.8c-0.8,0.5-1.7,0.9-3.1,1.1c1.2,0.5,2.1,1,3,1.6C206,504.2,206.9,504.8,208.4,505.3z"
                  />
                </g>
                <g>
                  <path
                    d="M206.6,501.5c0.6,2.6,3.6,3.1,5.4,1.5c1.8-1.6,1.7-4.6-0.8-5.5c-2.6-1-3.9-1-6.1-2.3
					C206.1,497.5,205.9,498.8,206.6,501.5z"
                  />
                </g>
                <g>
                  <path
                    d="M207.3,497.9c-1.1,2.2,0.8,4.3,3.2,4.2c2.4-0.1,4.2-2.4,2.8-4.5c-1.4-2.2-2.4-3-3.3-5.2
					C209.5,494.8,208.5,495.6,207.3,497.9z"
                  />
                </g>
                <g>
                  <path
                    d="M210,496.6c-0.9,0.7-1.1,1.7-0.8,2.5c0.4,0.9,1.3,1.6,2.4,2c1.2,0.3,2.3,0.2,3.1-0.2c0.8-0.4,1.2-1.1,0.9-2.1
					c-0.5-2-1.2-3.1-0.9-5.1C213.2,495,211.9,495.2,210,496.6z"
                  />
                </g>
                <g>
                  <path
                    d="M246.4,509.3c3.3,2.5,6.7,4,9.9,4.5c3.2,0.5,6.1,0.1,7.6-1.9c1.5-1.9,0.9-5.1-1.5-8c-2.4-2.9-6.8-5.5-11.7-6
					c-5.1-0.5-8.5-0.1-11.9,0.2c-3.4,0.4-6.7,0.8-11.3,1.5c4.6,0.9,7.6,2.2,10.3,3.7C240.4,504.9,243,506.7,246.4,509.3z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M155.7,208.9c3.8,4.2,8.2,7.3,12.1,9.2c3.9,1.8,7.4,2.3,9.1,0.6c1.7-1.7,1.1-5.3-1.4-9.3c-2.6-3.9-7.1-8.1-12.8-10.5
					c-5.8-2.5-10-3.6-14.1-4.8c-4.1-1.1-8.3-2.3-14.2-4.1c5.3,3.1,8.6,5.8,11.6,8.7C148.9,201.6,151.7,204.6,155.7,208.9z"
                  />
                </g>
                <g>
                  <path
                    d="M144.9,218.2c1.7,4.7,4.2,8.8,6.9,11.6c2.7,2.8,5.6,4.4,7.9,3.4c2.2-0.9,3.3-4.2,2.6-8.5c-0.7-4.2-3.2-9.4-7.3-13.3
					c-4.2-4-7.5-6.2-10.7-8.5c-3.3-2.2-6.5-4.4-11.1-7.6c3.6,4.3,5.5,7.6,7.1,11C141.9,209.8,143.2,213.3,144.9,218.2z"
                  />
                </g>
                <g>
                  <path
                    d="M130,219.4c-0.3,3.7,0.5,7,2,9.6c1.5,2.5,3.7,4.3,6.1,4.2c2.4-0.1,4.5-2.2,5.4-5.4c0.9-3.2,0.5-7.6-1.7-11.3
					c-2.3-3.8-4.3-6.2-6.3-8.5c-2-2.3-3.9-4.5-6.5-7.8c1.3,4,1.6,6.8,1.6,9.7C130.6,212.7,130.3,215.5,130,219.4z"
                  />
                </g>
                <g>
                  <path
                    d="M111.8,214c-2.1,2.7-2.8,5.7-2.5,8.4c0.3,2.6,1.7,4.9,4,5.8c2.3,0.9,4.9,0.1,6.9-1.9c2-2,3.4-5.2,3.2-8.8
					c-0.2-3.7-0.7-6.3-1.1-8.9c-0.4-2.5-0.7-5.1-0.8-8.6c-1.4,3.3-2.8,5.4-4.3,7.4C115.7,209.3,114,211.2,111.8,214z"
                  />
                </g>
                <g>
                  <path
                    d="M99.2,205.2c-3.4,1.1-6,3-7.7,5.3c-1.7,2.3-2.5,4.9-1.5,7.2c0.9,2.2,3.6,3.5,7,3.1c3.3-0.4,7.2-2.4,9.7-5.9
					c2.6-3.6,3.8-6.4,5-9.1c1.2-2.7,2.3-5.4,4-9.1c-2.9,2.8-5.4,4.3-7.8,5.4C105.3,203.2,102.7,204.1,99.2,205.2z"
                  />
                </g>
                <g>
                  <path
                    d="M94.3,188.9c-3.5-1.1-6.6-1.1-9.4-0.5c-0.7,0.2-1.4,0.3-2,0.6c-0.6,0.3-1.2,0.6-1.7,0.9c-1.1,0.7-1.9,1.6-2.3,2.8
					c-0.4,1.1-0.3,2.4,0.3,3.6c0.3,0.6,0.7,1.2,1.3,1.8c0.6,0.6,1.2,1.1,2,1.6c3.1,1.9,8,2.8,12.5,1.3c4.6-1.6,7.4-3.3,10.1-5.1
					c2.6-1.8,5.1-3.7,8.4-6.4c-4.1,1.4-7.1,1.6-9.9,1.4C100.6,190.6,97.9,190,94.3,188.9z"
                  />
                </g>
                <g>
                  <path
                    d="M100.2,170.6c-2.6-3.1-5.7-5.2-8.7-6.3c-3-1.1-5.9-1.1-7.7,0.5c-1.8,1.6-1.9,4.8-0.1,8.1c1.8,3.3,5.4,6.6,10.1,8
					c4.8,1.4,8.2,1.8,11.5,2.1c3.3,0.3,6.5,0.5,11.2,0.9c-4.3-1.8-6.8-3.7-9.1-5.8C105,176.1,103,173.8,100.2,170.6z"
                  />
                </g>
                <g>
                  <path
                    d="M110.5,158c-1.1-4.1-3-7.4-5.1-9.9c-0.6-0.6-1.1-1.2-1.7-1.7c-0.6-0.5-1.2-0.9-1.8-1.2c-1.2-0.6-2.4-0.9-3.6-0.7
					c-1.2,0.3-2.2,1.1-2.8,2.4c-0.3,0.6-0.6,1.4-0.8,2.2c-0.2,0.8-0.3,1.8-0.2,2.8c0.1,4,2,9,5.8,12.6c3.9,3.7,7,5.5,10.1,7.2
					c3.1,1.7,6.2,3.3,10.6,5.4c-3.6-3.3-5.4-6.2-6.8-9.1C112.8,165.2,111.7,162.2,110.5,158z"
                  />
                </g>
                <g>
                  <path
                    d="M123.7,154.9c0.9-3.8,1.3-6.8,1.4-9.5c0.1-1.3,0-2.7-0.1-4c-0.1-1.3-0.4-2.5-1.2-3.5c-0.8-0.9-2-1.4-3.6-1.2
					c-1.6,0.2-3.6,1.2-5.3,3c-1.7,1.8-3.1,4.4-3.7,7.3c-0.6,2.9-0.6,6,0.3,8.9c0.9,3,2.1,5.2,3.3,7c1.2,1.8,2.4,3.3,3.6,4.8
					c2.4,2.9,4.9,5.5,8.1,9.5c-2-4.8-2.9-8.3-3.3-11.7c-0.2-1.7-0.3-3.4-0.3-5.2C123,158.7,123.2,156.8,123.7,154.9z"
                  />
                </g>
                <g>
                  <path
                    d="M140.8,158.2c3.1-2.2,5.3-4.3,6.9-6.6c0.8-1.1,1.5-2.3,2.1-3.5c0.5-1.2,0.8-2.4,0.6-3.6c-0.2-1.2-1.1-2.2-2.5-2.8
					c-1.4-0.6-3.3-0.8-5.5-0.4c-2.2,0.4-4.5,1.5-6.6,3.3c-2,1.7-3.8,4-4.8,6.5c-1.1,2.7-1.6,4.9-1.9,6.9c-0.3,2-0.4,3.8-0.5,5.6
					c-0.1,3.5,0,6.9-0.1,11.8c1.5-4.6,3.1-7.6,5.1-10.1c1-1.3,2-2.5,3.2-3.6C137.9,160.6,139.2,159.4,140.8,158.2z"
                  />
                </g>
                <g>
                  <path
                    d="M151.4,167.6c4-0.8,7.3-2.3,9.9-4.3c0.6-0.5,1.2-1,1.7-1.5c0.5-0.5,1-1.1,1.3-1.6c0.7-1.1,1.1-2.3,1-3.6
					c-0.2-1.2-0.9-2.3-2.1-3.1c-0.6-0.4-1.3-0.8-2.1-1c-0.8-0.2-1.7-0.4-2.7-0.5c-3.9-0.3-9.1,1.3-12.8,4.9
					c-3.8,3.7-5.8,6.7-7.7,9.6c-1.8,3-3.5,6-5.6,10.2c3.4-3.4,6.2-5.1,9.1-6.3C144.2,169.3,147.3,168.5,151.4,167.6z"
                  />
                </g>
                <g>
                  <path
                    d="M159.8,180.2c4,0.9,7.4,1,10.3,0.8c1.5-0.1,2.9-0.4,4.1-0.8c1.2-0.4,2.4-1,3.1-2c0.7-1,0.8-2.3,0.3-3.8
					c-0.5-1.5-1.8-3-3.7-4.4c-1.9-1.3-4.3-2.3-7-2.7c-2.7-0.4-5.6-0.2-8.3,0.7c-2.8,0.9-4.9,2-6.7,3c-1.8,1-3.3,2.1-4.8,3.1
					c-3,2.1-5.8,4.2-10.1,6.8c4.9-1.3,8.4-1.8,11.8-1.8c1.7,0,3.4,0,5.2,0.2C155.8,179.5,157.7,179.8,159.8,180.2z"
                  />
                </g>
                <g>
                  <path
                    d="M126.3,182.5c-2.4,0.4-2.8,3.2-1.3,5c1.6,1.9,4.4,1.9,5.2-0.4c0.8-2.4,0.8-3.7,2-5.7C130,182.3,128.8,182.1,126.3,182.5
					z"
                  />
                </g>
                <g>
                  <path
                    d="M129.6,184.1c-1.1-0.4-2-0.1-2.7,0.6c-0.7,0.7-1.1,1.8-1,3c0.1,1.2,0.8,2.2,1.7,2.7c0.9,0.5,2.2,0.5,3.2-0.4
					c1-0.9,1.7-1.6,2.3-2.3c0.6-0.7,1.3-1.4,2.2-2.1c-1.2,0-2.1-0.2-2.9-0.4C131.6,184.9,130.8,184.5,129.6,184.1z"
                  />
                </g>
                <g>
                  <path
                    d="M130.1,186.7c-0.2-0.9-0.7-1.3-1.5-1.4c-0.8-0.1-1.9,0.1-3,0.8c-1,0.6-1.7,1.7-1.6,2.8c0.1,1.1,0.9,2.2,2.2,2.5
					c1.4,0.3,2.4,0.2,3.3,0.1c0.9-0.1,1.8-0.2,2.9-0.2c-0.9-0.7-1.3-1.4-1.6-2.1C130.5,188.5,130.3,187.7,130.1,186.7z"
                  />
                </g>
                <g>
                  <path
                    d="M128.5,190c0.4-0.3,0.6-0.7,0.8-1.1c0.1-0.5,0.1-1,0-1.6c-0.1-0.5-0.4-1.1-0.9-1.4c-0.4-0.4-1-0.6-1.6-0.7
					c-0.6-0.1-1.2-0.1-1.7,0c-0.5,0.1-1,0.2-1.4,0.4c-0.4,0.2-0.7,0.6-0.9,1c-0.2,0.5-0.3,1.1-0.2,1.9c0.1,0.8,0.3,1.5,0.6,2.1
					c0.3,0.6,0.6,1.1,0.9,1.5c0.7,0.8,1.5,1.6,2.7,2c-0.4-0.5-0.5-1-0.4-1.4c0-0.4,0.2-0.8,0.4-1.1c0.2-0.3,0.5-0.6,0.7-0.9
					C127.7,190.4,128.1,190.3,128.5,190z"
                  />
                </g>
                <g>
                  <path
                    d="M126.4,190.9c1.1-0.1,1.7-0.6,2.1-1.5c0.3-0.8,0.5-2,0-3.2c-0.4-1.1-1.4-1.9-2.6-2c-1.2-0.1-2.5,0.5-3.2,1.9
					c-0.7,1.4-0.9,2.4-1.1,3.4c-0.2,1-0.4,1.9-0.7,3.2c0.9-0.8,1.8-1.2,2.7-1.4C124.3,191.1,125.2,191,126.4,190.9z"
                  />
                </g>
                <g>
                  <path
                    d="M123,191c1.4,0.5,2.8,0.1,3.7-0.7c0.9-0.8,1.2-2,0.9-3.2c-0.3-1.2-1.2-2.1-2.1-2.5c-0.9-0.4-2-0.4-3,0.3
					c-1.1,0.7-1.9,1.3-2.7,1.8c-0.8,0.5-1.7,0.9-3.1,1.1c1.2,0.5,2.1,1,3,1.6C120.6,189.9,121.5,190.4,123,191z"
                  />
                </g>
                <g>
                  <path
                    d="M121.2,187.2c0.6,2.6,3.6,3.1,5.4,1.5c1.8-1.6,1.7-4.6-0.8-5.5c-2.6-1-3.9-1-6.1-2.3
					C120.7,183.2,120.5,184.5,121.2,187.2z"
                  />
                </g>
                <g>
                  <path
                    d="M121.9,183.6c-1.1,2.2,0.8,4.3,3.2,4.2c2.4-0.1,4.2-2.4,2.8-4.5c-1.4-2.2-2.4-3-3.3-5.2
					C124.1,180.4,123.1,181.3,121.9,183.6z"
                  />
                </g>
                <g>
                  <path
                    d="M124.6,182.2c-0.9,0.7-1.1,1.7-0.8,2.5c0.4,0.9,1.3,1.6,2.4,2c1.2,0.3,2.3,0.2,3.1-0.2c0.8-0.4,1.2-1.1,0.9-2.1
					c-0.5-2-1.2-3.1-0.9-5.1C127.8,180.7,126.5,180.9,124.6,182.2z"
                  />
                </g>
                <g>
                  <path
                    d="M161,194.9c3.3,2.5,6.7,4,9.9,4.5c3.2,0.5,6.1,0.1,7.6-1.9c1.5-1.9,0.9-5.1-1.5-8c-2.4-2.9-6.8-5.5-11.7-6
					c-5.1-0.5-8.5-0.1-11.9,0.2c-3.4,0.4-6.7,0.8-11.3,1.5c4.6,0.9,7.6,2.2,10.3,3.7C155,190.5,157.6,192.3,161,194.9z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M353.7,131.6c-6.1,1.4-12.7,4.3-17.8,8c-2.6,1.8-4.9,3.9-7,6c-2,2.1-3.7,4.3-5.1,6.4c-2.8,4.3-4.2,8.4-4.6,11.5
					c-0.2,1.6-0.1,2.9,0.2,3.9c0.3,1,0.9,1.8,1.6,2.2c0.8,0.4,1.7,0.6,2.7,0.4c1-0.2,2.2-0.6,3.5-1.2c2.5-1.2,5.5-3.2,8.8-5.5
					c1.6-1.1,3.4-2.3,5.2-3.6c1.8-1.2,3.8-2.5,5.8-3.8c4-2.7,8.2-5.1,13.1-8c5-3,9.8-5.5,14-8.2c4.2-2.6,8-5.4,11.3-8.5
					c1.7-1.5,3.3-3.1,4.8-4.8c1.6-1.7,3.1-3.5,4.6-5.5c3-3.9,6-8.5,8.6-14.3c-3.6,5.3-7.4,9-11.1,11.8c-1.9,1.4-3.7,2.6-5.5,3.7
					c-1.8,1.1-3.7,2-5.5,2.7c-3.7,1.6-7.5,2.6-11.9,3.5C365,129.4,360,130.1,353.7,131.6z"
                  />
                </g>
                <g>
                  <path
                    d="M362.6,150.6c-5,2.2-10,4.1-14.7,5.9c-4.7,1.7-9.2,3.3-13.1,4.7c-4,1.4-7.4,2.8-9.9,4.2c-2.5,1.4-4.1,2.9-4.3,4.7
					c-0.1,1.7,1.3,3.5,4.2,5c2.8,1.5,7.1,2.5,12.3,2.7c5.2,0.2,11.2-0.6,17.4-2.6c6.1-2.1,12.3-5.3,17.6-9.8
					c5.4-4.6,9.3-9,12.4-13.3c3.1-4.3,5.4-8.3,7.5-12.4c2-4.1,3.8-8.4,5.4-13.2c1.6-4.8,3-10.2,4-16.7c-2,6.2-4.5,11.1-7.2,15.2
					c-2.6,4.1-5.4,7.4-8.4,10.3c-3,3-6.2,5.6-10,8.1C372,145.9,367.8,148.3,362.6,150.6z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M401.3,173.5c-6-1.9-13.1-2.8-19.4-2.2c-3.2,0.3-6.2,0.8-9,1.6c-2.8,0.8-5.4,1.8-7.7,2.9c-4.6,2.3-7.9,5-9.8,7.5
					c-1,1.2-1.6,2.4-1.8,3.5c-0.3,1.1-0.2,2,0.3,2.7c0.4,0.8,1.2,1.3,2.2,1.7c1,0.4,2.2,0.6,3.6,0.8c2.8,0.2,6.3,0.1,10.3-0.2
					c2-0.1,4.1-0.3,6.4-0.4c2.2-0.1,4.5-0.3,6.9-0.4c4.8-0.2,9.7-0.2,15.4-0.2c5.9,0,11.2,0.3,16.2,0.1c5-0.1,9.7-0.6,14.1-1.5
					c2.2-0.4,4.4-1,6.6-1.7c2.2-0.7,4.4-1.4,6.7-2.4c4.6-1.9,9.5-4.3,14.7-7.9c-5.8,2.7-10.9,3.9-15.6,4.5c-2.3,0.3-4.5,0.4-6.7,0.3
					c-2.1,0-4.2-0.2-6.2-0.5c-4-0.5-7.8-1.6-12-3C412.2,177.4,407.6,175.5,401.3,173.5z"
                  />
                </g>
                <g>
                  <path
                    d="M399.3,194.3c-5.4-0.7-10.7-1.6-15.6-2.5c-4.9-0.9-9.6-1.9-13.7-2.7c-4.1-0.8-7.8-1.4-10.7-1.5
					c-2.9-0.1-5.1,0.4-6.1,1.9c-1,1.4-0.6,3.7,1.1,6.4c1.7,2.7,4.8,5.8,9.2,8.6c4.3,2.8,9.9,5.3,16.3,6.7c6.3,1.4,13.3,1.8,20.1,0.6
					c7-1.1,12.6-3,17.5-5c4.8-2.1,8.9-4.4,12.8-6.9c3.9-2.5,7.6-5.3,11.4-8.6c3.8-3.3,7.8-7.3,12-12.3c-4.9,4.3-9.6,7.2-13.9,9.4
					c-4.4,2.2-8.4,3.5-12.5,4.6c-4.1,1-8.2,1.6-12.7,1.9C409.8,195.2,404.9,195.1,399.3,194.3z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M143.2,295.9c4-4.8,7.5-11.1,9.3-17.2c0.9-3.1,1.6-6.1,1.9-9c0.3-2.9,0.3-5.7,0.2-8.2c-0.4-5.1-1.7-9.2-3.3-11.9
					c-0.8-1.4-1.7-2.4-2.6-3c-0.9-0.6-1.8-0.9-2.6-0.8c-0.9,0.1-1.7,0.6-2.4,1.3c-0.7,0.8-1.4,1.8-2,3.1c-1.3,2.5-2.4,5.9-3.7,9.7
					c-0.6,1.9-1.3,3.9-2,6.1c-0.7,2.1-1.4,4.3-2.2,6.6c-1.6,4.6-3.4,9-5.5,14.3c-2.2,5.4-4.4,10.3-6.2,15c-1.7,4.7-3.1,9.2-3.8,13.6
					c-0.4,2.2-0.7,4.5-0.9,6.8c-0.2,2.3-0.3,4.7-0.3,7.1c0,4.9,0.4,10.4,1.8,16.6c-0.4-6.3,0.4-11.6,1.6-16.1
					c0.6-2.3,1.3-4.4,2.2-6.3c0.8-2,1.7-3.8,2.7-5.5c2-3.5,4.4-6.6,7.3-10C135.6,304.6,139.1,300.9,143.2,295.9z"
                  />
                </g>
                <g>
                  <path
                    d="M124.7,286.2c2.7-4.8,5.5-9.3,8.1-13.6c2.7-4.3,5.3-8.2,7.6-11.7c2.3-3.5,4.2-6.7,5.4-9.3c1.2-2.6,1.5-4.9,0.5-6.3
					c-1-1.5-3.2-2-6.4-1.4c-3.2,0.6-7.2,2.3-11.4,5.3c-4.2,3-8.6,7.3-12.2,12.6c-3.6,5.4-6.6,11.7-8.1,18.4
					c-1.5,6.9-1.9,12.8-1.8,18.1c0.1,5.3,0.7,9.9,1.6,14.4c0.9,4.5,2.1,9,3.7,13.8c1.7,4.8,3.8,9.9,7,15.7
					c-2.2-6.2-3.2-11.6-3.5-16.4c-0.4-4.8-0.2-9.2,0.4-13.3c0.6-4.2,1.6-8.2,3-12.5C120,295.7,121.9,291.1,124.7,286.2z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M453.4,473.1c-7.8,2.4-14.6,6.2-19.4,10.2c-4.8,4-7.6,8.2-6.4,11.6c1.2,3.3,6.3,4.8,13,3.8c6.7-1,14.9-4.4,21.5-10.4
					c6.8-6.1,10.9-10.9,14.9-15.6c4-4.7,8-9.4,14-16.1c-7.3,5.1-12.9,7.7-18.4,9.9C467.1,468.8,461.4,470.6,453.4,473.1z"
                  />
                </g>
                <g>
                  <path
                    d="M447.9,453.4c-7.2-0.7-14.1,0.2-19.4,2c-5.3,1.8-9.1,4.7-9.3,8.2c-0.2,3.5,3.5,6.8,9.5,8.5c6,1.7,14.2,1.6,21.8-1.4
					c7.8-3.1,12.7-6,17.6-8.9c4.9-2.9,9.7-5.8,16.7-9.9c-7.8,2.1-13.3,2.5-18.7,2.5C460.7,454.5,455.3,454.1,447.9,453.4z"
                  />
                </g>
                <g>
                  <path
                    d="M455.3,433.2c-4.7-2.7-9.5-3.7-13.7-3.3c-4.2,0.4-7.9,2.2-9.3,5.4c-1.4,3.2,0.1,7.2,3.8,10.4c3.7,3.1,9.6,5.3,15.9,4.7
					c6.4-0.7,10.7-1.9,14.9-3.1c4.2-1.2,8.3-2.4,14.1-3.8c-6-0.7-9.9-2.1-13.6-3.8C463.7,438,460.2,435.9,455.3,433.2z"
                  />
                </g>
                <g>
                  <path
                    d="M473.5,412.7c-2.2-4.4-5.7-7.2-9.4-8.4c-3.7-1.2-7.5-0.8-10.1,1.7c-2.6,2.4-3.1,6.3-1.8,10.2c1.4,3.9,4.7,7.6,9.6,9.6
					c5,2,8.7,2.9,12.3,3.9c3.6,1,7.1,2.1,11.8,4.3c-3.4-3.8-5.3-7-7-10.2C477.3,420.6,475.8,417.2,473.5,412.7z"
                  />
                </g>
                <g>
                  <path
                    d="M492.7,401.5c0.7-5.1-0.3-9.7-2.2-13.3c-1.9-3.6-4.9-6.3-8.4-6.4c-3.5-0.1-6.7,2.6-8.3,7.2c-1.5,4.5-1.3,10.8,1.8,16.3
					c3.2,5.6,6.1,8.9,8.9,12.1c2.8,3.2,5.6,6.3,9.4,10.8c-1.9-5.5-2.3-9.6-2.3-13.6C491.6,410.7,492,406.8,492.7,401.5z"
                  />
                </g>
                <g>
                  <path
                    d="M517.1,405c3.5-3.9,5.5-8,6.4-12c0.2-1,0.4-2,0.4-3c0-1,0-1.9-0.1-2.8c-0.3-1.8-1-3.5-2.2-4.7c-1.3-1.2-2.9-1.9-4.9-1.8
					c-1,0-2.1,0.2-3.1,0.6c-1.1,0.4-2.2,0.9-3.3,1.6c-4.4,2.8-8.5,8.7-9.3,15.5c-0.8,7-0.1,11.7,0.6,16.3c0.8,4.5,1.7,8.9,3.2,15
					c0.6-6.2,2.3-10.2,4.3-13.8C511,412.3,513.5,409,517.1,405z"
                  />
                </g>
                <g>
                  <path
                    d="M537.4,423.9c5.7-1.6,10.3-4.3,13.5-7.6c3.2-3.2,5.1-7.1,4.1-10.4c-1-3.3-5.1-5.3-10.5-5c-5.4,0.3-11.9,3.1-16.6,8.4
					c-4.8,5.4-7.3,9.6-9.8,13.8c-2.3,4.1-4.7,8.2-8.1,14.1c5-4.5,9-6.7,13.1-8.4C527.3,426.9,531.5,425.6,537.4,423.9z"
                  />
                </g>
                <g>
                  <path
                    d="M547.6,445.1c6,1,11.5,0.6,16-0.7c1.1-0.4,2.2-0.7,3.2-1.2c1-0.5,1.9-1,2.7-1.6c1.6-1.2,2.7-2.6,3.1-4.4
					c0.4-1.7-0.1-3.5-1.4-5.2c-0.6-0.8-1.4-1.7-2.5-2.4c-1-0.7-2.2-1.5-3.5-2c-5.2-2.3-13-2.8-20,0c-7.2,2.9-11.5,5.9-15.7,8.8
					c-4.1,3-8.1,6.1-13.5,10.5c6.6-2.7,11.4-3.3,16-3.4C536.8,443.6,541.4,444.1,547.6,445.1z"
                  />
                </g>
                <g>
                  <path
                    d="M543.7,464.2c4.4,3.5,8.1,5.9,11.5,7.7c1.7,0.9,3.5,1.7,5.2,2.3c1.7,0.6,3.5,1,5.3,0.6c1.7-0.4,3.1-1.7,3.8-4
					c0.7-2.2,0.6-5.4-0.8-8.8c-1.3-3.3-3.9-6.8-7.2-9.3c-3.4-2.6-7.5-4.4-11.9-5c-4.5-0.6-8.1-0.4-11.2,0c-3.1,0.4-5.8,1.1-8.4,1.8
					c-5.2,1.4-10.1,3.1-17.4,4.9c7.5,0.3,12.6,1.2,17.3,2.7c2.3,0.8,4.6,1.7,6.9,2.8C539.1,461,541.5,462.4,543.7,464.2z"
                  />
                </g>
                <g>
                  <path
                    d="M528.8,484.6c1,5.4,2.5,9.5,4.4,13.1c1,1.8,2.1,3.4,3.3,4.8c1.2,1.4,2.7,2.5,4.4,2.9c1.7,0.4,3.5,0,5.2-1.5
					c1.7-1.4,3.1-3.9,3.9-7c0.7-3.1,0.7-6.9-0.2-10.6c-1-3.7-2.9-7.4-5.6-10.3c-2.8-3.1-5.4-5.1-7.9-6.8c-2.5-1.7-4.7-2.9-7-4.1
					c-4.6-2.3-9.1-4.3-15.4-7.3c5.1,4.8,8,8.7,10.2,12.8c1.1,2,2,4.1,2.8,6.4C527.6,479.3,528.3,481.8,528.8,484.6z"
                  />
                </g>
                <g>
                  <path
                    d="M510.1,492.7c-1.4,5.7-1.4,11-0.4,15.5c0.3,1.1,0.5,2.2,0.9,3.2c0.4,1,0.8,1.9,1.3,2.7c1,1.6,2.4,2.9,4.1,3.4
					c1.7,0.5,3.5,0.2,5.3-0.9c0.9-0.5,1.8-1.3,2.6-2.2c0.8-0.9,1.6-2,2.3-3.3c2.7-5,3.8-12.7,1.5-19.8c-2.4-7.3-5.2-11.7-7.9-15.9
					c-2.8-4.2-5.7-8.2-10-13.6c2.4,6.5,2.8,11.3,2.7,15.8C512.2,482.3,511.5,486.8,510.1,492.7z"
                  />
                </g>
                <g>
                  <path
                    d="M488.5,496c-3.6,4.8-5.9,9-7.3,13c-0.7,2-1.3,4-1.5,5.9c-0.2,1.9-0.1,3.7,0.8,5.3c0.9,1.5,2.5,2.5,4.7,2.7
					c2.2,0.2,5.1-0.5,7.9-2.1c2.8-1.6,5.7-4.3,7.8-7.6c2.1-3.3,3.6-7.2,4.1-11.2c0.5-4.2,0.4-7.6,0.2-10.6c-0.3-3-0.7-5.6-1.1-8.2
					c-0.9-5.1-1.9-10.1-2.8-17.4c-1.3,7.2-2.8,12.1-4.8,16.5c-1,2.2-2.1,4.4-3.4,6.7C491.8,491.2,490.3,493.6,488.5,496z"
                  />
                </g>
                <g>
                  <path
                    d="M505.9,450.8c0.9-3.4-2.4-5.6-5.8-4.7c-3.4,0.9-5.2,4.5-2.7,7c2.6,2.5,4.3,3.3,6.2,6.1
					C503.8,455.8,505,454.3,505.9,450.8z"
                  />
                </g>
                <g>
                  <path
                    d="M501.8,454.2c1.2-1.2,1.4-2.6,0.9-3.9c-0.5-1.3-1.7-2.5-3.4-3.1c-1.7-0.6-3.4-0.4-4.6,0.6c-1.2,0.9-1.9,2.6-1.4,4.4
					c0.5,1.9,1.1,3.2,1.6,4.5c0.5,1.3,1,2.5,1.4,4.2c0.7-1.6,1.5-2.6,2.4-3.6C499.5,456.3,500.5,455.4,501.8,454.2z"
                  />
                </g>
                <g>
                  <path
                    d="M498,453.2c1.3,0.3,2.1-0.1,2.7-1.2c0.6-1,1-2.6,0.8-4.4c-0.2-1.7-1.2-3.2-2.7-3.8c-1.5-0.6-3.4-0.2-4.6,1.4
					c-1.2,1.6-1.7,3-2.2,4.2c-0.4,1.3-0.8,2.4-1.5,3.9c1.4-0.8,2.6-0.9,3.7-0.9C495.5,452.6,496.6,452.9,498,453.2z"
                  />
                </g>
                <g>
                  <path
                    d="M494.8,449.1c0.1,0.7,0.5,1.2,1,1.7c0.5,0.4,1.3,0.8,2.1,0.9c0.8,0.1,1.7,0.1,2.4-0.3c0.8-0.3,1.4-0.9,1.9-1.6
					c0.5-0.7,0.8-1.5,1.1-2.2c0.2-0.7,0.3-1.4,0.3-2.1c-0.1-0.7-0.3-1.3-0.8-1.8c-0.5-0.6-1.3-1.1-2.4-1.4c-1.1-0.3-2.2-0.5-3.1-0.5
					c-0.9,0-1.7,0.1-2.5,0.3c-1.5,0.4-3,0.9-4.2,2.3c0.8-0.2,1.5,0,2.1,0.3c0.5,0.3,0.9,0.7,1.2,1.2c0.3,0.5,0.5,1,0.7,1.5
					C494.6,447.8,494.6,448.4,494.8,449.1z"
                  />
                </g>
                <g>
                  <path
                    d="M494.8,445.7c-0.6,1.5-0.3,2.6,0.6,3.6c0.9,1,2.4,1.8,4.1,2c1.7,0.2,3.4-0.6,4.2-2.1c0.9-1.5,0.9-3.7-0.5-5.4
					c-1.4-1.8-2.6-2.7-3.8-3.6c-1.2-0.9-2.3-1.7-3.7-2.8c0.5,1.8,0.5,3.1,0.2,4.4C495.9,443,495.4,444.2,494.8,445.7z"
                  />
                </g>
                <g>
                  <path
                    d="M496.9,441.3c-1.5,1.5-1.9,3.6-1.4,5.2c0.5,1.6,1.9,2.8,3.6,3.1c1.7,0.3,3.4-0.2,4.5-1.2c1.1-1,1.7-2.3,1.5-4.1
					c-0.3-1.8-0.6-3.2-0.7-4.6c-0.1-1.4-0.1-2.8,0.5-4.7c-1.5,1.3-2.6,2.1-3.9,3C499.7,438.9,498.4,439.7,496.9,441.3z"
                  />
                </g>
                <g>
                  <path d="M502.9,441.2c-3.7-0.8-6.3,2.7-5.3,6.1c1,3.4,5,5,7.7,2.3c2.8-2.8,3.6-4.5,6.8-6.6C508.4,443.1,506.8,442,502.9,441.2z" />
                </g>
                <g>
                  <path
                    d="M507.1,444.4c-2.2-2.9-6.2-1.6-7.5,1.7c-1.3,3.3,0.6,6.9,4.2,6.5c3.7-0.5,5.4-1.4,8.9-1.1
					C510,449.2,509.4,447.3,507.1,444.4z"
                  />
                </g>
                <g>
                  <path
                    d="M507.3,448.7c-0.3-1.6-1.5-2.5-2.9-2.6c-1.4-0.1-2.9,0.6-4.1,2c-1.2,1.3-1.7,2.9-1.7,4.1c0.1,1.3,0.7,2.2,2.2,2.4
					c3,0.6,4.8,0.4,7.3,1.9C507.4,453.8,507.9,452.1,507.3,448.7z"
                  />
                </g>
                <g>
                  <path
                    d="M468.5,488.6c-5.3,2.7-9.3,6.4-11.9,10.2c-2.6,3.8-3.8,7.9-2.1,11.1c1.6,3.1,6.1,4.3,11.4,2.9c5.3-1.4,11.3-5.5,15-11.7
					c3.7-6.3,5.4-11.1,7-15.7c1.5-4.7,3-9.2,5-15.7c-3.9,5.5-7.5,8.6-11.2,11.3C477.9,483.5,474,485.7,468.5,488.6z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M426.3,566.6c5.8-2.4,11.9-6.2,16.3-10.7c2.3-2.2,4.3-4.6,5.9-7c1.7-2.4,3-4.8,4-7.2c2.1-4.7,2.9-8.9,2.7-12.1
					c-0.1-1.6-0.4-2.9-0.8-3.9c-0.5-1-1.1-1.6-2-1.9c-0.8-0.3-1.8-0.3-2.8,0c-1,0.3-2.1,0.9-3.2,1.7c-2.3,1.6-4.9,4-7.8,6.8
					c-1.4,1.4-3,2.8-4.6,4.4c-1.6,1.5-3.3,3.1-5.1,4.7c-3.5,3.3-7.3,6.4-11.7,10c-4.5,3.7-8.8,7-12.5,10.3c-3.8,3.3-7,6.6-9.9,10.2
					c-1.4,1.8-2.7,3.6-4,5.5c-1.3,1.9-2.5,4-3.7,6.1c-2.3,4.4-4.5,9.3-6.2,15.5c2.7-5.8,5.9-10,9.1-13.4c1.6-1.7,3.2-3.2,4.9-4.5
					c1.6-1.3,3.3-2.5,5-3.6c3.4-2.1,7-3.8,11.2-5.4C415.5,570.6,420.3,569,426.3,566.6z"
                  />
                </g>
                <g>
                  <path
                    d="M414.5,549.3c4.6-2.9,9.2-5.6,13.6-8.1c4.4-2.5,8.6-4.7,12.2-6.7c3.7-2,6.9-3.9,9.1-5.7c2.3-1.8,3.6-3.6,3.5-5.3
					c-0.2-1.7-1.9-3.3-4.9-4.3c-3-1-7.4-1.4-12.6-0.7c-5.1,0.6-11,2.3-16.8,5.3c-5.7,3-11.4,7.2-15.8,12.4
					c-4.6,5.4-7.8,10.4-10.2,15.1c-2.4,4.7-4.1,9.1-5.4,13.5c-1.4,4.4-2.4,8.9-3.2,13.9c-0.8,5-1.3,10.6-1.3,17.1
					c1-6.5,2.7-11.7,4.7-16.1c2-4.4,4.2-8.1,6.7-11.5c2.5-3.4,5.3-6.5,8.5-9.6C405.9,555.4,409.8,552.3,414.5,549.3z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M372.6,532.7c6.2,0.9,13.4,0.7,19.5-0.9c3.1-0.8,6-1.8,8.7-3c2.7-1.2,5-2.6,7.1-4.1c4.2-3,7-6.2,8.5-9
					c0.7-1.4,1.2-2.7,1.2-3.7c0.1-1.1-0.1-2-0.7-2.7c-0.5-0.7-1.4-1.1-2.4-1.4c-1-0.2-2.3-0.3-3.7-0.2c-2.8,0.2-6.3,0.9-10.2,1.8
					c-1.9,0.4-4,0.9-6.2,1.4c-2.2,0.5-4.4,1-6.8,1.5c-4.7,1-9.5,1.8-15.1,2.6c-5.8,0.9-11.1,1.5-16,2.4c-4.9,0.9-9.4,2.1-13.7,3.7
					c-2.1,0.8-4.2,1.7-6.3,2.7c-2.1,1-4.2,2.1-6.3,3.4c-4.2,2.6-8.7,5.7-13.3,10.1c5.3-3.6,10.2-5.6,14.7-6.9
					c2.3-0.6,4.4-1.1,6.5-1.4c2.1-0.3,4.1-0.5,6.2-0.5c4-0.1,7.9,0.3,12.4,1.1C361.2,530.7,366.2,531.8,372.6,532.7z"
                  />
                </g>
                <g>
                  <path
                    d="M371.3,511.9c5.5-0.2,10.8-0.1,15.8,0c5,0.1,9.8,0.3,14,0.5c4.2,0.1,7.9,0.1,10.8-0.2c2.9-0.4,4.9-1.2,5.7-2.8
					c0.7-1.6,0.1-3.8-2.1-6.2c-2.1-2.4-5.7-5-10.4-7.1c-4.7-2.1-10.7-3.6-17.1-4c-6.5-0.3-13.4,0.4-20,2.6c-6.7,2.2-12,4.9-16.5,7.7
					c-4.4,2.8-8.1,5.7-11.6,8.8c-3.4,3.1-6.6,6.4-9.9,10.3c-3.2,3.9-6.6,8.4-9.8,14c4.2-5,8.3-8.7,12.2-11.5c4-2.8,7.8-4.8,11.6-6.5
					c3.9-1.6,7.8-2.9,12.2-3.9C360.8,512.7,365.7,512,371.3,511.9z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M514.4,139c10.6-1.1,21.3-3.7,30.7-7.7c9.4-3.9,17.6-9.3,23.7-14.8c6.1-5.5,10.2-11.1,12.5-15.3
					c2.3-4.2,2.9-6.9,2.1-7.4c-1.6-1-7.7,7.5-19.9,16.2c-6.1,4.3-13.6,8.7-22.2,12.2c-8.6,3.5-18.2,6.3-28.2,8.1
					c-10.2,1.9-19.1,2.6-27.2,3.2c-8.1,0.6-15.5,1.1-23,1.9c-7.4,0.8-14.9,1.9-23.1,4.1c-4.1,1.1-8.3,2.5-12.7,4.4
					c-4.4,1.9-8.9,4.3-13.4,7.6c9.3-6,18.4-8.6,26.5-10.2c8.2-1.5,15.6-1.9,22.9-2c7.4-0.1,14.7,0.2,22.9,0.5
					C494.2,139.9,503.4,140.1,514.4,139z"
                  />
                </g>
                <g>
                  <path
                    d="M458.8,128.6c2.7-1.5,4.5-4,5.1-6.3c0.6-2.3,0-4.4-1.5-5.3c-1.5-0.9-3.5-0.6-5.3,0.4c-1.8,1-3.5,2.7-4.6,5.2
					c-1.1,2.5-2,4.3-3,6c-1,1.7-2.2,3.4-4.4,5.4c2.8-1.1,4.7-1.7,6.8-2.4C454,130.8,456,130.1,458.8,128.6z"
                  />
                </g>
                <g>
                  <path
                    d="M475.9,123.1c2.3-1.3,3.6-3.6,3.8-5.6c0.2-2.1-0.6-3.8-2.1-4.7c-1.6-0.8-3.4-0.6-5,0.2c-1.6,0.8-2.9,2.3-3.5,4.4
					c-0.7,2.2-1.1,3.8-1.7,5.4c-0.6,1.6-1.4,3.1-3,5c2.3-1,3.9-1.6,5.6-2.2C471.8,125,473.6,124.4,475.9,123.1z"
                  />
                </g>
                <g>
                  <path
                    d="M493,119.2c2.2-1.6,3.5-4,3.7-6.1c0.2-2.1-0.5-4-2.1-4.8c-1.5-0.8-3.5-0.5-5.1,0.5c-1.7,1.1-3.1,2.9-3.7,5.3
					c-0.6,2.5-1,4.3-1.5,6.1c-0.5,1.8-1.2,3.6-2.5,5.8c2.2-1.6,3.8-2.5,5.5-3.5C488.9,121.7,490.7,120.8,493,119.2z"
                  />
                </g>
                <g>
                  <path
                    d="M512.9,117.7c2.4-2.2,3.5-5.3,3.4-7.8c-0.1-2.5-1.3-4.3-3-4.6c-1.7-0.4-3.5,0.6-4.9,2c-1.4,1.4-2.5,3.4-2.9,5.9
					c-0.5,2.6-1,4.5-1.6,6.3c-0.7,1.8-1.6,3.7-3.7,5.8c2.7-1.3,4.5-2.3,6.5-3.3C508.4,121,510.4,119.9,512.9,117.7z"
                  />
                </g>
                <g>
                  <path
                    d="M529.5,112.5c2.1-2.5,3.1-5.6,2.9-8.1c-0.1-2.5-1.2-4.4-2.9-4.8c-1.7-0.5-3.6,0.5-5,2.2c-1.5,1.7-2.6,4.1-3,7
					c-0.4,3-0.6,5.1-1,7.2c-0.4,2.1-1,4.3-2.4,7.1c2.3-2.2,4-3.7,5.7-5.2C525.6,116.6,527.3,115.1,529.5,112.5z"
                  />
                </g>
                <g>
                  <path
                    d="M541.5,108.7c2-2,2.9-4.7,2.9-7c-0.1-2.2-1.2-4-2.8-4.5c-1.7-0.5-3.5,0.2-5,1.5c-1.5,1.4-2.6,3.4-2.9,6
					c-0.3,2.6-0.5,4.5-0.8,6.4c-0.3,1.9-0.8,3.8-2,6.3c2.1-1.9,3.6-3.1,5.2-4.3C537.8,112,539.4,110.8,541.5,108.7z"
                  />
                </g>
                <g>
                  <path
                    d="M558.6,100c2.1-2.3,3.1-5.3,3-7.7c0-2.4-1.1-4.3-2.7-4.9c-1.7-0.5-3.6,0.3-5.1,1.9c-1.5,1.6-2.7,4-3.1,6.8
					c-0.4,2.9-0.6,5-0.9,7.1c-0.4,2.1-0.9,4.2-2.2,7c2.2-2.2,3.8-3.7,5.5-5.1C554.7,103.8,556.5,102.4,558.6,100z"
                  />
                </g>
                <g>
                  <path
                    d="M573,93.6c2.2-1.8,3.6-4.1,4.1-6.2c0.5-2.2,0.2-4.2-1.2-5.3c-1.4-1.1-3.5-0.9-5.5,0.3c-2,1.2-3.9,3.5-4.7,6.5
					c-0.8,3-1.2,5.2-1.5,7.3c-0.4,2.1-0.8,4.2-1.6,7.1c1.8-2.4,3.3-3.9,4.9-5.2C569,96.6,570.7,95.4,573,93.6z"
                  />
                </g>
                <g>
                  <path
                    d="M587.3,86.9c2.3-1.1,3.9-2.9,4.8-4.7c0.8-1.8,0.9-3.8-0.1-5.2c-1-1.4-3.1-1.9-5.2-1.2c-2.2,0.6-4.5,2.4-5.7,5.1
					c-1.3,2.7-1.9,4.7-2.5,6.7c-0.6,2-1.2,3.9-2.3,6.5c1.9-2,3.6-3.2,5.3-4.1C583.1,88.9,584.9,88.1,587.3,86.9z"
                  />
                </g>
                <g>
                  <path
                    d="M602.4,93.3c2.3,0.6,4.4,0.3,6.1-0.6c1.7-0.9,3-2.3,3.1-4.1c0.1-1.7-1-3.4-3.1-4.3c-2-0.9-4.8-1-7.4,0.3
					c-2.6,1.3-4.2,2.6-5.7,3.7c-1.5,1.2-3.1,2.4-5.3,3.9c2.6-0.5,4.5-0.5,6.3-0.2C598.3,92.3,600,92.7,602.4,93.3z"
                  />
                </g>
                <g>
                  <path
                    d="M602.3,107.3c2.1,1.2,4.2,1.5,6.1,1.3c1.9-0.3,3.6-1.1,4.4-2.7c0.8-1.6,0.2-3.6-1.6-5.2c-1.8-1.6-4.7-2.6-7.7-2.1
					c-3.1,0.5-5.1,1.4-7,2.2c-2,0.9-3.8,1.7-6.3,2.8c2.8-0.1,4.7,0.4,6.5,1C598.4,105.2,600.1,106.1,602.3,107.3z"
                  />
                </g>
                <g>
                  <path
                    d="M598.1,120.2c2.4,1.8,5,2.6,7.4,2.6c2.3,0,4.2-0.9,4.9-2.5c0.7-1.6-0.1-3.6-1.8-5.3c-1.7-1.6-4.4-2.9-7.5-3.1
					c-6.3-0.3-8.9,0.5-15.1-0.2C591.6,114.5,593.3,116.5,598.1,120.2z"
                  />
                </g>
                <g>
                  <path
                    d="M455.6,157.1c1.3,2.2,2.9,3.7,4.7,4.7c1.8,0.9,3.8,1.2,5.3,0.3c1.5-0.9,2.1-3,1.3-5.4c-0.7-2.4-2.8-5-5.7-6.3
					c-3-1.3-5.3-1.7-7.4-2.1c-2.2-0.4-4.3-0.7-7.2-1.1c2.6,1.4,4.1,2.9,5.4,4.4C453.2,153.1,454.3,154.8,455.6,157.1z"
                  />
                </g>
                <g>
                  <path
                    d="M472.5,157.1c1.1,2.4,2.4,4.3,3.9,5.8c1.5,1.5,3.1,2.7,4.9,2.5c0.9-0.1,1.6-0.6,2.2-1.6c0.6-0.9,0.9-2.2,0.8-3.8
					c-0.1-1.5-0.5-3.3-1.5-4.9c-0.9-1.6-2.3-3.2-4-4.3c-1.8-1.1-3.4-1.8-4.8-2.2c-1.5-0.4-2.8-0.7-4.1-0.8c-2.6-0.3-5.1-0.3-8.6,0.2
					c3.4,0.6,5.5,1.9,7.1,3.3C470,152.9,471.3,154.7,472.5,157.1z"
                  />
                </g>
                <g>
                  <path
                    d="M487.8,156.6c1.5,2.4,3.4,4.2,5.4,5.3c1.9,1.1,4,1.5,5.5,0.6c1.5-0.9,2-3.1,1.1-5.7c-0.9-2.6-3.1-5.4-6.4-6.9
					c-3.3-1.5-5.7-2.1-8.1-2.5c-2.4-0.5-4.7-0.9-8-1.4c3,1.5,4.7,3.1,6.1,4.7C484.9,152.4,486.2,154.1,487.8,156.6z"
                  />
                </g>
                <g>
                  <path
                    d="M506.5,159.3c2.5,2.3,5.7,3.4,8.3,3.4c2.6,0,4.6-1.1,5.1-2.7c0.5-1.7-0.5-3.6-2.2-5.1c-1.7-1.6-4.2-2.8-7.2-3.3
					c-3-0.5-5.2-0.9-7.4-1.4c-2.2-0.5-4.3-1.3-7.2-2.9c2.2,2.5,3.6,4.3,5.1,6.1C502.5,155.1,504,157,506.5,159.3z"
                  />
                </g>
                <g>
                  <path
                    d="M525.3,155.5c3.6,1.7,7.5,2.2,10.5,1.7c3-0.5,5.1-1.9,5.2-3.6c0.2-1.8-1.5-3.5-4.1-4.7c-2.6-1.2-6-2-9.7-2
					c-3.8,0.1-6.5,0.2-9.2,0.1c-2.7-0.1-5.5-0.3-9.4-1.3c3.5,1.9,5.9,3.4,8.3,4.9C519.2,152.2,521.6,153.7,525.3,155.5z"
                  />
                </g>
                <g>
                  <path
                    d="M549,150.1c6.4,2.5,13.2,0.2,13.4-3.3c0.2-3.5-6-6.6-12.5-5.4c-6.7,1.1-9.4,2.3-16.4,2.3
					C540.3,145.7,542.5,147.5,549,150.1z"
                  />
                </g>
                <g>
                  <path
                    d="M568.8,144.1c3.4,1.2,7.1,1.2,9.7,0.4c2.7-0.8,4.5-2.3,4.5-4.1c0-1.8-1.7-3.3-4.2-4.3c-2.5-1-5.8-1.4-9.2-0.8
					c-3.5,0.6-6,1.1-8.5,1.5c-2.5,0.4-5.1,0.6-8.8,0.4c3.5,1.2,5.9,2.3,8.2,3.4C562.9,141.7,565.2,142.8,568.8,144.1z"
                  />
                </g>
                <g>
                  <path
                    d="M580.9,135.9c1.6,1.6,3.5,2.2,5.3,2.2c1.8,0,3.5-0.8,4.4-2.3c0.9-1.5,0.7-3.5-0.5-5.1c-1.2-1.6-3.4-2.9-6.1-3
					c-2.7,0-4.6,0.3-6.4,0.5c-1.8,0.3-3.6,0.5-6,0.6c2.3,0.9,3.7,1.9,5,3C578.1,133,579.3,134.2,580.9,135.9z"
                  />
                </g>
                <g>
                  <path
                    d="M591.4,129.9c3.6,4.4,9.6,5,11.5,2c1.9-3-1.3-8.1-6.8-9.4c-5.7-1.4-8.2-1.2-13.6-3.3
					C586.8,123.1,587.7,125.4,591.4,129.9z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M191.4,346.1c2,10.5,5.5,20.9,10.2,29.9c4.7,9.1,10.8,16.7,16.8,22.3c6,5.6,11.9,9.2,16.3,11.1c4.4,1.9,7.2,2.3,7.5,1.5
					c0.9-1.7-8.1-7.1-17.8-18.4c-4.8-5.7-9.8-12.8-14-21.1c-4.2-8.3-7.8-17.6-10.5-27.4c-2.8-10-4.2-18.8-5.5-26.9
					c-1.3-8.1-2.4-15.4-3.8-22.7c-1.4-7.3-3.2-14.7-6-22.6c-1.4-4-3.2-8.1-5.4-12.3c-2.2-4.2-5.1-8.5-8.7-12.7
					c6.8,8.8,10.2,17.6,12.4,25.5c2.2,8,3.2,15.3,4,22.6c0.7,7.3,1,14.6,1.5,22.8C188.8,326.1,189.3,335.3,191.4,346.1z"
                  />
                </g>
                <g>
                  <path
                    d="M197,289.9c1.7,2.6,4.4,4.1,6.8,4.5c2.4,0.4,4.4-0.3,5.2-1.9c0.8-1.6,0.3-3.5-0.8-5.3c-1.2-1.7-3-3.3-5.5-4.1
					c-2.6-0.9-4.5-1.6-6.3-2.5c-1.8-0.9-3.6-1.9-5.7-3.9c1.3,2.7,2.1,4.6,3,6.6C194.4,285.3,195.3,287.3,197,289.9z"
                  />
                </g>
                <g>
                  <path
                    d="M204,306.5c1.5,2.2,3.9,3.3,5.9,3.4c2.1,0.1,3.8-0.9,4.5-2.5c0.7-1.6,0.3-3.5-0.6-4.9c-1-1.5-2.5-2.6-4.7-3.1
					c-2.2-0.5-3.9-0.8-5.6-1.3c-1.6-0.5-3.2-1.2-5.2-2.6c1.2,2.2,1.9,3.8,2.7,5.4C201.7,302.5,202.5,304.3,204,306.5z"
                  />
                </g>
                <g>
                  <path
                    d="M209.3,323.1c1.8,2.1,4.2,3.1,6.4,3.2c2.1,0.1,3.9-0.8,4.6-2.5c0.7-1.6,0.2-3.5-1-5.1c-1.2-1.6-3.1-2.8-5.6-3.2
					c-2.5-0.4-4.4-0.6-6.2-1c-1.8-0.4-3.6-0.9-6-2c1.7,2,2.8,3.6,3.9,5.2C206.4,319.3,207.5,321,209.3,323.1z"
                  />
                </g>
                <g>
                  <path
                    d="M212.5,342.8c2.4,2.2,5.6,3,8,2.7c2.5-0.3,4.1-1.7,4.3-3.4c0.2-1.8-0.9-3.4-2.4-4.7c-1.5-1.3-3.6-2.2-6.1-2.4
					c-2.6-0.3-4.5-0.6-6.4-1.1c-1.9-0.5-3.8-1.3-6.1-3.2c1.5,2.5,2.6,4.3,3.8,6.2C208.8,338.7,210.1,340.6,212.5,342.8z"
                  />
                </g>
                <g>
                  <path
                    d="M219.1,359c2.6,1.9,5.8,2.6,8.3,2.2c2.5-0.3,4.3-1.6,4.6-3.3c0.3-1.7-0.8-3.5-2.7-4.8c-1.8-1.3-4.4-2.3-7.3-2.4
					c-3-0.1-5.1-0.1-7.3-0.4c-2.2-0.2-4.3-0.6-7.3-1.8c2.4,2.1,4,3.6,5.6,5.2C214.7,355.4,216.3,357,219.1,359z"
                  />
                </g>
                <g>
                  <path
                    d="M223.9,370.7c2.2,1.9,5,2.5,7.2,2.2c2.2-0.3,3.9-1.5,4.3-3.2c0.4-1.7-0.5-3.5-2-4.8c-1.5-1.4-3.7-2.3-6.2-2.3
					c-2.6-0.1-4.6-0.1-6.5-0.2c-1.9-0.2-3.8-0.5-6.4-1.5c2,1.9,3.4,3.4,4.7,4.9C220.3,367.2,221.6,368.7,223.9,370.7z"
                  />
                </g>
                <g>
                  <path
                    d="M234,386.9c2.5,1.9,5.5,2.6,7.9,2.4c2.4-0.2,4.2-1.4,4.6-3.1c0.4-1.7-0.6-3.5-2.4-4.9c-1.8-1.4-4.2-2.4-7.1-2.5
					c-3-0.1-5.1-0.2-7.2-0.3c-2.1-0.2-4.2-0.5-7.2-1.6c2.4,2,4,3.5,5.6,5C229.9,383.4,231.4,385,234,386.9z"
                  />
                </g>
                <g>
                  <path
                    d="M241.6,400.7c2,2.1,4.3,3.2,6.5,3.5c2.2,0.3,4.2-0.2,5.2-1.7c1-1.5,0.6-3.6-0.8-5.5c-1.4-1.9-3.8-3.6-6.8-4.2
					c-3.1-0.6-5.3-0.7-7.4-0.9c-2.1-0.2-4.3-0.4-7.2-1c2.6,1.6,4.2,3,5.6,4.5C238.2,397,239.6,398.6,241.6,400.7z"
                  />
                </g>
                <g>
                  <path
                    d="M249.5,414.4c1.3,2.2,3.2,3.7,5.1,4.3c1.9,0.7,3.9,0.6,5.2-0.6c1.3-1.2,1.6-3.2,0.7-5.3c-0.8-2.1-2.8-4.2-5.5-5.3
					c-2.8-1.1-4.9-1.5-6.9-1.9c-2-0.4-3.9-0.9-6.6-1.7c2.2,1.8,3.5,3.3,4.6,4.9C247.1,410.4,248.1,412.1,249.5,414.4z"
                  />
                </g>
                <g>
                  <path
                    d="M244.4,430c-0.4,2.4,0.1,4.4,1.1,6c1,1.6,2.6,2.7,4.3,2.7c1.8,0,3.3-1.3,4-3.4c0.7-2.1,0.6-4.9-0.9-7.3
					c-1.5-2.5-2.9-4-4.2-5.4c-1.3-1.4-2.6-2.9-4.3-4.9c0.7,2.6,0.8,4.4,0.7,6.2C245.1,425.8,244.8,427.6,244.4,430z"
                  />
                </g>
                <g>
                  <path
                    d="M230.5,431.1c-1,2.2-1.2,4.3-0.7,6.2c0.4,1.9,1.4,3.5,3.1,4.2c1.6,0.6,3.6-0.1,5-2.1c1.4-1.9,2.2-4.9,1.4-7.9
					c-0.8-3-1.8-4.9-2.8-6.8c-1-1.9-2-3.7-3.3-6.1c0.3,2.8,0,4.7-0.4,6.6C232.2,427,231.5,428.8,230.5,431.1z"
                  />
                </g>
                <g>
                  <path
                    d="M217.3,428c-1.6,2.5-2.2,5.3-1.9,7.5c0.2,2.3,1.2,4.1,2.9,4.7c1.7,0.5,3.6-0.4,5.1-2.2c1.5-1.9,2.5-4.7,2.4-7.7
					c-0.3-6.3-1.2-8.8-1.1-15C222.4,421,220.5,422.8,217.3,428z"
                  />
                </g>
                <g>
                  <path
                    d="M168.4,289.1c-2.1,1.5-3.5,3.2-4.2,5.1c-0.7,1.9-0.9,3.9,0.2,5.3c1,1.4,3.2,1.8,5.5,0.9c2.3-0.9,4.7-3.2,5.8-6.3
					c1.1-3.2,1.3-5.4,1.5-7.6c0.2-2.2,0.3-4.3,0.4-7.3c-1.2,2.7-2.5,4.3-3.9,5.7C172.1,286.4,170.5,287.6,168.4,289.1z"
                  />
                </g>
                <g>
                  <path
                    d="M169.7,305.9c-2.3,1.3-4,2.7-5.5,4.3c-1.4,1.6-2.5,3.4-2.1,5.1c0.2,0.9,0.8,1.6,1.7,2c1,0.5,2.3,0.7,3.8,0.5
					c1.5-0.2,3.2-0.8,4.8-1.9c1.5-1.1,3-2.6,3.9-4.4c1-1.9,1.5-3.5,1.8-5c0.3-1.5,0.4-2.8,0.5-4.1c0-2.6-0.1-5.2-0.9-8.6
					c-0.3,3.5-1.4,5.6-2.7,7.4C173.7,303.1,172.1,304.6,169.7,305.9z"
                  />
                </g>
                <g>
                  <path
                    d="M171.5,321.1c-2.3,1.7-3.9,3.7-4.8,5.8c-0.9,2-1.2,4.1-0.1,5.5c1,1.4,3.3,1.7,5.8,0.6c2.5-1.1,5.1-3.6,6.3-6.9
					c1.2-3.4,1.6-5.9,1.8-8.3c0.3-2.4,0.5-4.7,0.7-8.1c-1.3,3.1-2.6,4.9-4.1,6.5C175.6,317.9,173.9,319.3,171.5,321.1z"
                  />
                </g>
                <g>
                  <path
                    d="M170.5,340.1c-2.1,2.7-2.9,6-2.6,8.5c0.2,2.6,1.4,4.5,3.2,4.8c1.7,0.3,3.5-0.8,4.9-2.6c1.4-1.9,2.4-4.4,2.7-7.4
					c0.3-3.1,0.4-5.3,0.8-7.5c0.4-2.2,0.9-4.4,2.3-7.4c-2.3,2.4-3.9,4-5.6,5.6C174.4,335.7,172.6,337.3,170.5,340.1z"
                  />
                </g>
                <g>
                  <path
                    d="M175.9,358.4c-1.4,3.7-1.5,7.7-0.8,10.6c0.7,3,2.3,4.9,4,4.9c1.8,0,3.3-1.8,4.3-4.5c1-2.7,1.5-6.2,1.1-9.9
					c-0.4-3.8-0.7-6.5-0.9-9.2c-0.2-2.7-0.2-5.5,0.4-9.4c-1.6,3.7-2.9,6.2-4.2,8.7C178.7,352.1,177.4,354.6,175.9,358.4z"
                  />
                </g>
                <g>
                  <path
                    d="M183.3,381.6c-1.9,6.6,0.9,13.2,4.4,13.1c3.5-0.1,6-6.5,4.4-12.9c-1.7-6.6-3.1-9.1-3.7-16.1
					C186.9,372.5,185.3,374.9,183.3,381.6z"
                  />
                </g>
                <g>
                  <path
                    d="M190.9,400.8c-0.9,3.5-0.6,7.1,0.4,9.7c1,2.6,2.7,4.3,4.5,4.1c1.8-0.1,3.2-2,3.9-4.5c0.8-2.6,0.9-5.9,0-9.3
					c-0.9-3.5-1.6-5.9-2.2-8.4c-0.6-2.5-1-5-1.2-8.7c-0.9,3.6-1.8,6-2.6,8.5C192.8,394.7,191.9,397.2,190.9,400.8z"
                  />
                </g>
                <g>
                  <path
                    d="M200.2,412.2c-1.4,1.7-1.9,3.7-1.8,5.5c0.2,1.8,1.1,3.4,2.7,4.2c1.6,0.8,3.5,0.4,5-1c1.5-1.3,2.6-3.7,2.5-6.3
					c-0.2-2.7-0.6-4.5-1.1-6.3c-0.4-1.8-0.8-3.5-1.1-6c-0.7,2.4-1.6,3.9-2.6,5.3C202.8,409.1,201.7,410.4,200.2,412.2z"
                  />
                </g>
                <g>
                  <path d="M207,422.2c-4,4-4.1,10-1,11.6c3.1,1.6,8-2,8.8-7.6c0.9-5.8,0.5-8.2,2.1-13.8C213.4,417,211.1,418.1,207,422.2z" />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M378.5,8.1c9.5-5,18.3-11.4,25.5-18.6c7.3-7.2,12.8-15.2,16.3-22.6c3.6-7.4,5.3-14.1,5.8-18.9c0.5-4.8,0-7.5-0.8-7.6
					c-1.9-0.3-4.3,9.8-12.3,22.5c-4,6.3-9.3,13.2-16,19.6c-6.6,6.5-14.6,12.7-23,18.1c-8.7,5.6-16.7,9.6-24,13.2
					c-7.3,3.6-14,6.8-20.6,10.4c-6.6,3.6-13.1,7.4-19.8,12.5c-3.4,2.5-6.8,5.4-10.1,8.8c-3.4,3.4-6.6,7.4-9.6,12
					c6.4-9.1,13.8-14.9,20.7-19.4c7-4.5,13.7-7.6,20.5-10.5c6.8-2.8,13.7-5.3,21.4-8.2C360.1,16.5,368.7,13.3,378.5,8.1z"
                  />
                </g>
                <g>
                  <path
                    d="M323.1,19.3c2-2.4,2.7-5.4,2.3-7.8c-0.3-2.4-1.6-4.1-3.3-4.4c-1.7-0.3-3.5,0.7-4.8,2.3c-1.3,1.6-2.2,3.9-2.3,6.5
					c-0.1,2.7-0.2,4.7-0.5,6.7c-0.3,2-0.8,4-2.1,6.6c2.2-2,3.7-3.4,5.4-4.8C319.4,23.2,321.1,21.8,323.1,19.3z"
                  />
                </g>
                <g>
                  <path
                    d="M336.9,7.8c1.6-2.1,2-4.7,1.5-6.6c-0.5-2-2-3.4-3.7-3.5c-1.8-0.2-3.4,0.7-4.5,2.1c-1.1,1.4-1.8,3.2-1.6,5.4
					c0.2,2.3,0.4,4,0.4,5.7c0,1.7-0.1,3.4-1,5.8c1.7-1.8,3-3,4.4-4.2C333.8,11.1,335.2,9.9,336.9,7.8z"
                  />
                </g>
                <g>
                  <path
                    d="M351.2-2.2c1.5-2.3,1.7-5,1.1-7c-0.6-2.1-2-3.5-3.7-3.7c-1.8-0.2-3.4,0.8-4.6,2.4c-1.1,1.6-1.8,3.8-1.4,6.3
					c0.4,2.6,0.7,4.4,0.9,6.3c0.2,1.9,0.3,3.7-0.1,6.4c1.4-2.3,2.6-3.8,3.8-5.3C348.4,1.6,349.7,0.1,351.2-2.2z"
                  />
                </g>
                <g>
                  <path
                    d="M369.1-11.1c1.4-2.9,1.2-6.2,0.2-8.4c-1-2.3-2.9-3.4-4.6-3.1c-1.7,0.3-3,1.8-3.8,3.7c-0.8,1.9-1,4.1-0.5,6.5
					c0.5,2.6,0.8,4.5,0.9,6.5c0,2-0.1,4-1.2,6.8c2-2.2,3.4-3.8,4.8-5.5C366.2-6.4,367.6-8.1,369.1-11.1z"
                  />
                </g>
                <g>
                  <path
                    d="M382.6-22.2c1-3.1,0.7-6.3-0.3-8.6c-1-2.3-2.8-3.6-4.5-3.4c-1.8,0.2-3.1,1.8-3.8,4c-0.7,2.1-0.9,4.8-0.1,7.6
					c0.8,2.9,1.4,5,1.8,7.1c0.4,2.1,0.7,4.3,0.4,7.5c1.3-2.9,2.3-4.9,3.3-6.9C380.4-16.9,381.5-19,382.6-22.2z"
                  />
                </g>
                <g>
                  <path
                    d="M392.3-30.2c1.1-2.7,1-5.5,0-7.5c-0.9-2-2.6-3.3-4.3-3.1c-1.8,0.1-3.2,1.5-4,3.3c-0.8,1.8-1.1,4.2-0.4,6.6
					c0.7,2.5,1.3,4.4,1.7,6.3c0.4,1.9,0.7,3.8,0.5,6.6c1.2-2.5,2.2-4.2,3.2-5.9C390-25.7,391.1-27.5,392.3-30.2z"
                  />
                </g>
                <g>
                  <path
                    d="M404.8-44.7c1.1-3,0.9-6-0.1-8.3c-1-2.2-2.6-3.6-4.4-3.5c-1.8,0.1-3.2,1.6-4,3.7c-0.8,2.1-1,4.7-0.3,7.5
					c0.8,2.9,1.4,4.9,1.8,7c0.4,2.1,0.8,4.2,0.6,7.3c1.2-2.9,2.1-4.8,3.1-6.8C402.6-39.7,403.7-41.7,404.8-44.7z"
                  />
                </g>
                <g>
                  <path
                    d="M415.7-56.1c1.4-2.5,1.8-5.1,1.4-7.3c-0.3-2.2-1.4-3.9-3.1-4.4c-1.7-0.5-3.6,0.4-5,2.3c-1.4,1.9-2.3,4.7-1.9,7.8
					c0.4,3.1,0.9,5.2,1.3,7.3c0.5,2.1,0.9,4.2,1.2,7.2c0.8-2.9,1.6-4.9,2.6-6.7C413.2-51.8,414.3-53.5,415.7-56.1z"
                  />
                </g>
                <g>
                  <path
                    d="M426.4-67.6c1.7-1.9,2.5-4.1,2.6-6.1c0.1-2-0.6-3.9-2.1-4.8c-1.5-0.9-3.5-0.6-5.3,0.9c-1.8,1.4-3.2,3.9-3.4,6.8
					c-0.2,3,0,5.1,0.2,7.1c0.2,2,0.3,4,0.3,6.9c1-2.6,2.1-4.3,3.3-5.8C423.3-64.2,424.7-65.6,426.4-67.6z"
                  />
                </g>
                <g>
                  <path
                    d="M442.9-67.4c2.4-0.3,4.2-1.4,5.4-2.9c1.2-1.4,1.9-3.2,1.3-4.9c-0.5-1.7-2.2-2.8-4.4-2.8c-2.2-0.1-4.8,0.9-6.7,3.1
					c-1.9,2.2-2.9,3.9-3.9,5.6c-1,1.7-2,3.3-3.4,5.6c2.2-1.5,4-2.1,5.7-2.6C438.6-66.8,440.4-67.1,442.9-67.4z"
                  />
                </g>
                <g>
                  <path
                    d="M448-54.4c2.4,0.3,4.5-0.2,6.2-1.1c1.7-1,3-2.4,3.1-4.2c0.1-1.7-1.2-3.4-3.4-4.2c-2.2-0.8-5.3-0.6-7.9,1
					c-2.7,1.7-4.2,3.2-5.7,4.6c-1.5,1.6-2.9,3-4.8,5c2.6-1.1,4.5-1.4,6.4-1.5C443.6-54.9,445.5-54.7,448-54.4z"
                  />
                </g>
                <g>
                  <path
                    d="M449-40.9c2.9,0.8,5.7,0.5,7.8-0.4c2.1-0.9,3.6-2.4,3.6-4.2c0-1.8-1.4-3.3-3.6-4.2c-2.2-0.9-5.2-1-8.1,0
					c-6,2.1-8,3.8-14,5.5C440.8-43.7,443.1-42.4,449-40.9z"
                  />
                </g>
                <g>
                  <path
                    d="M330.8,46.9c2,1.6,4.1,2.4,6.1,2.5c2,0.2,3.9-0.3,5-1.7c1-1.4,0.8-3.5-0.8-5.5c-1.5-1.9-4.4-3.6-7.7-3.6
					c-3.3-0.1-5.5,0.4-7.7,0.8c-2.1,0.5-4.2,1-7.1,1.7c3,0.3,4.9,1.1,6.6,2C327.1,44.2,328.7,45.3,330.8,46.9z"
                  />
                </g>
                <g>
                  <path
                    d="M346.5,40.6c1.9,1.8,3.8,3.1,5.8,3.9c1.9,0.8,3.9,1.4,5.5,0.5c0.8-0.4,1.3-1.2,1.4-2.3c0.2-1.1,0-2.4-0.7-3.8
					c-0.6-1.4-1.7-2.8-3.2-4c-1.5-1.2-3.3-2.1-5.3-2.4c-2.1-0.4-3.8-0.4-5.3-0.3c-1.5,0.1-2.8,0.4-4.1,0.8c-2.5,0.7-4.9,1.6-7.9,3.4
					c3.4-0.8,5.8-0.3,7.9,0.4C342.6,37.7,344.5,38.8,346.5,40.6z"
                  />
                </g>
                <g>
                  <path
                    d="M360.5,34.4c2.3,1.7,4.7,2.6,6.9,2.9c2.2,0.3,4.3-0.1,5.3-1.5c1-1.4,0.7-3.6-1.1-5.7c-1.8-2.1-4.9-3.8-8.5-4
					c-3.6-0.2-6.1,0.3-8.5,0.7c-2.4,0.5-4.7,1-7.9,1.7c3.3,0.3,5.5,1.1,7.4,2C356.2,31.5,358.1,32.7,360.5,34.4z"
                  />
                </g>
                <g>
                  <path
                    d="M378.8,29.8c3.2,1.2,6.5,1,8.9,0c2.4-1,3.8-2.7,3.7-4.4c-0.2-1.8-1.8-3.1-4-3.9c-2.2-0.8-5-1-7.9-0.4
					c-3,0.7-5.2,1.2-7.4,1.5c-2.2,0.3-4.5,0.4-7.8,0c3,1.5,5,2.6,7,3.7C373.5,27.4,375.6,28.6,378.8,29.8z"
                  />
                </g>
                <g>
                  <path
                    d="M394.8,19.2c4,0.3,7.8-0.8,10.4-2.4c2.6-1.6,4-3.6,3.5-5.3c-0.5-1.7-2.7-2.6-5.6-2.8c-2.9-0.2-6.4,0.4-9.8,1.9
					c-3.5,1.5-6,2.6-8.5,3.6c-2.5,1-5.2,1.8-9.2,2.4c4,0.5,6.7,0.9,9.5,1.4C387.9,18.4,390.7,19,394.8,19.2z"
                  />
                </g>
                <g>
                  <path
                    d="M414.8,5.3c6.9-0.1,12.4-4.8,11.2-8.1c-1.2-3.3-8-3.8-13.6-0.3c-5.8,3.6-7.8,5.6-14.3,8.3C405,4.5,407.7,5.3,414.8,5.3z
					"
                  />
                </g>
                <g>
                  <path
                    d="M430.8-7.7c3.7-0.2,7-1.5,9.2-3.3c2.2-1.7,3.3-3.8,2.6-5.5c-0.7-1.6-2.8-2.4-5.5-2.4c-2.7,0-5.9,0.9-8.8,2.7
					c-3,1.9-5.1,3.3-7.3,4.6c-2.2,1.3-4.5,2.5-8,3.7c3.7-0.2,6.3-0.1,8.9,0C424.5-7.7,427.1-7.5,430.8-7.7z"
                  />
                </g>
                <g>
                  <path
                    d="M439-19.9c2.1,0.9,4.1,0.8,5.8,0c1.6-0.7,3-2.1,3.3-3.8c0.3-1.7-0.7-3.5-2.4-4.5c-1.7-1.1-4.3-1.4-6.7-0.5
					c-2.5,1-4.1,2-5.7,2.9c-1.6,0.9-3.1,1.8-5.4,2.9c2.5,0,4.2,0.4,5.8,0.9C435.2-21.5,436.8-20.8,439-19.9z"
                  />
                </g>
                <g>
                  <path d="M446.5-29.3c5,2.7,10.8,1,11.4-2.5c0.6-3.5-4.3-7-9.9-6.2c-5.8,0.9-8,2-13.8,2C439.6-33.9,441.3-32.1,446.5-29.3z" />
                </g>
              </g>
              <g>
                <path
                  d="M533.7,299.2c0.6-0.6,1.1-1.2,1.6-1.8c0.5-0.7,1-1.3,1.5-2c0.9-1.4,1.8-2.8,2.4-4.3c1.4-3,2.2-6.2,2.6-9.4
				c0.8-6.4-0.3-12.8-2.8-18.6c-1.2-2.9-3-5.7-5-8.1c-2-2.4-4.2-4.5-6.6-6.4c-4.8-3.6-10.2-6.1-15.6-7.6c-2.7-0.7-5.5-1.3-8.2-1.5
				c-2.7-0.3-5.5-0.3-8.1-0.2c-5.4,0.3-10.8,1.5-15.7,3.8c-5,2.3-9.4,5.8-12.6,10c-1.6,2.1-2.9,4.5-3.9,6.9
				c-0.9,2.4-1.5,5.1-1.6,7.7c-0.3,5.1,1,10,3.5,14.1c1.3,2,2.9,3.8,4.7,5.2c1.8,1.4,3.8,2.4,5.8,3.1c4,1.3,8,1.3,11.5,0.3
				c3.5-1,6.4-3.3,8-5.7c1.7-2.4,2.4-4.9,2.6-7c0.2-2.1-0.1-4-0.6-5.3c-0.5-1.4-1.2-2.3-1.7-2.8c-0.6-0.5-1-0.6-1.3-0.5
				c-0.6,0.3-0.4,1.7-0.7,3.8c-0.1,1-0.4,2.2-1.1,3.5c-0.6,1.2-1.5,2.6-2.7,3.6c-0.6,0.5-1.3,0.9-2,1.3c-0.7,0.3-1.6,0.5-2.5,0.6
				c-1.8,0.2-3.8-0.2-5.7-1.2c-0.9-0.5-1.8-1.1-2.5-1.9c-0.7-0.7-1.3-1.6-1.8-2.7c-1-2.1-1.4-4.7-1-7.3c0.2-1.3,0.6-2.5,1.2-3.8
				c0.6-1.2,1.4-2.4,2.4-3.6c1-1.1,2.1-2.2,3.4-3.1c1.3-0.9,2.7-1.7,4.3-2.3c6.2-2.5,14.7-2.8,22.4-0.6c3.8,1.1,7.5,2.8,10.6,5.1
				c1.5,1.2,3,2.5,4.2,3.9c0.6,0.7,1.1,1.5,1.7,2.2l0.7,1.2l0.6,1.3c1.6,3.4,2.4,7.4,2.2,11.1c-0.1,1.9-0.5,3.7-1.2,5.4
				c-0.3,0.9-0.7,1.6-1.2,2.4l-0.7,1.1c-0.3,0.4-0.5,0.7-0.9,1.1c-2.5,3-6.1,5.2-10.1,6.9c-2,0.9-4.1,1.6-6.1,2.2
				c-1.1,0.3-2.1,0.6-3.2,0.9c-1.1,0.3-2.1,0.6-3.1,0.8c-8.4,1.9-16.5,2.6-24.2,2.5c-7.7-0.1-15.1-1.1-22.2-2.7
				c-7.1-1.6-13.8-3.8-20.3-6.6c-13-5.7-25.3-13.9-36.8-25.5c-5.7-5.8-11.3-12.5-16.4-20.3c-5.2-7.7-9.9-16.7-13.9-26.8
				c3.7,10.3,8,19.4,12.8,27.5c4.8,8.1,10,15.2,15.6,21.4c11.1,12.5,23,21.8,36.3,28.8c6.6,3.5,13.5,6.4,20.9,8.7
				c7.4,2.3,15.2,3.9,23.5,4.7c8.4,0.8,17.3,0.7,26.8-0.7c1.2-0.1,2.4-0.4,3.6-0.6c1.2-0.2,2.4-0.5,3.6-0.7c2.5-0.6,5-1.2,7.6-2.1
				c2.6-0.9,5.2-2,7.8-3.5C528.7,303.5,531.3,301.6,533.7,299.2z"
                />
              </g>
              <g>
                <path
                  d="M454.1,257.7c3.8-5.9,5.1-13.2,3.9-20.1c-0.6-3.4-1.9-6.8-3.7-9.7c-1.8-2.9-4.1-5.5-6.7-7.6c-2.6-2.1-5.5-3.7-8.6-4.7
				c-3.1-1-6.3-1.5-9.5-1.3c-3.2,0.2-6.3,0.9-9.1,2.4c-0.7,0.3-1.4,0.8-2,1.2c-0.7,0.5-1.3,0.9-1.9,1.4c-1.2,1-2.2,2.1-3.1,3.3
				c-1.7,2.4-2.8,5-3.3,7.6c-0.5,2.6-0.3,5.3,0.4,7.7c0.8,2.5,2.1,4.4,3.6,5.7c1.5,1.4,3,2.3,4.5,2.9c1.5,0.6,2.9,0.8,4.1,0.8
				c0.6,0,1.2-0.1,1.7-0.2c0.5-0.1,0.9-0.3,1.3-0.4c0.8-0.4,1.3-0.8,1.5-1.2c0.3-0.4,0.4-0.7,0.3-1.1c-0.2-0.7-1-1-2-1.6
				c-1-0.6-2.2-1.3-3.3-2.6c-1.1-1.2-1.9-3.1-1.8-4.5c0.1-0.9,0.2-1.7,0.7-2.6c0.4-0.9,1.1-1.8,1.9-2.6c0.4-0.4,0.9-0.7,1.3-1
				c0.2-0.1,0.5-0.2,0.7-0.4c0.3-0.1,0.5-0.2,0.8-0.3c1.1-0.4,2.4-0.5,3.9-0.5c2.9,0.1,5.9,1.3,8.6,3.4c1.3,1.1,2.5,2.4,3.4,3.8
				c0.9,1.5,1.6,3,2,4.7c0.8,3.4,0.5,7.4-1.1,10.7c-1.6,3.5-4.6,6.3-7.9,8.3c-3.4,1.9-7,3-10.6,3.3c-3.7,0.4-7.3,0.1-10.9-0.6
				c-3.5-0.7-7-1.8-10.4-3.2c-3.4-1.4-6.6-3.1-9.9-5.2c-3.2-2.1-6.3-4.6-9.4-7.5c-3-2.9-6-6.3-8.7-10.3c-2.7-4-5.2-8.6-7.1-14
				c3.2,11,8.3,19.4,13.9,26.1c2.8,3.4,5.7,6.3,8.8,9c3.1,2.6,6.3,4.9,9.7,7c3.4,2.1,7,3.8,11,5.2c3.9,1.4,8.2,2.4,13,2.7
				c4.7,0.3,10-0.4,15.2-2.5c2.6-1,5.2-2.5,7.8-4.4C449.8,263.2,452.2,260.8,454.1,257.7z"
                />
              </g>
              <g>
                <path
                  d="M336.8,288.9c-1.6,6.2-2,12.8-1.5,18.8c0.5,6,2,11.6,3.9,16c1.9,4.5,4.2,7.8,6.2,9.9c2.1,2.1,3.9,2.9,5,2.3
				c1.2-0.6,1.7-2.5,1.6-5.1c0.1-2.7-0.4-6.2-0.8-10.4c-0.4-4.1-0.9-8.8-1.2-13.8c-0.3-5-0.4-10.4-0.3-15.8
				c0.1-5.6,0.8-10.4,1.7-14.7c0.9-4.3,2.2-8.3,3.4-12.5c1.2-4.1,2.4-8.3,3.2-13.2c0.4-2.4,0.6-5,0.6-7.8c0-2.8-0.4-5.7-1.3-8.7
				c0.5,3.1,0.5,6,0.1,8.6c-0.4,2.6-1.1,5-1.9,7.2c-1.6,4.4-3.7,8-5.9,11.6c-2.2,3.6-4.5,7.2-6.8,11.5
				C340.7,277.4,338.5,282.5,336.8,288.9z"
                />
              </g>
              <g>
                <path
                  d="M350.5,287.2c-2,4.9-4.1,10-5.6,15.3c-1.5,5.3-2.2,10.7-1.9,15.5c0.3,4.8,1.5,8.7,3,11.2c1.5,2.5,3.2,3.5,4.5,3.1
				c1.3-0.4,2-2,2.7-4.3c0.7-2.3,1.4-5.3,2.3-8.9c0.9-3.6,2.2-7.7,3.7-12.4c1.4-4.7,3-10.1,4-15.9c0.9-6,1.2-11.1,1.2-15.7
				c0-4.6-0.4-8.7-0.9-12.8c-0.5-4.1-1.2-8.1-2.1-12.5c-1-4.4-2.2-9.3-4.1-14.9c1.1,5.8,1.4,10.8,1.4,15.2c0,4.4-0.4,8.4-0.9,12.2
				c-0.6,3.8-1.4,7.6-2.5,11.6C354.1,277.9,352.6,282.2,350.5,287.2z"
                />
              </g>
              <g>
                <path
                  d="M393.3,330.8c-0.5,0.7-1,1.3-1.5,1.9c-0.5,0.6-1.1,1.1-1.6,1.6c-1.2,0.9-2.4,1.7-3.7,2.3c-1.3,0.6-2.5,0.8-3.7,0.9
				c-1.2,0.1-2.1,0-3.2-0.4c-2.1-0.6-3.9-2.2-4.8-3.6c-0.4-0.7-0.7-1.4-0.8-2.3c-0.1-0.9,0-1.8,0.2-2.8c0.5-1.9,1.6-3.7,2.7-4.9
				c1.2-1.2,2-1.6,2.4-2c0.3-0.1,0.8-0.1,1.2,0c0.4,0.1,0.8,0.3,1.1,0.5c0.7,0.4,1.1,1.1,1.7,1.4c0.3,0.1,0.7,0.1,1.1-0.1
				c0.4-0.3,0.8-0.8,1.1-1.6c0.2-0.8,0.2-2-0.2-3.1c-0.4-1.2-1.2-2.4-2.6-3.5c-1.6-1.1-3.4-1.5-5.4-1.5c-1.9,0-3.8,0.5-5.6,1.3
				c-3.5,1.6-6.7,4.5-9,8.7c-1.1,2.1-1.9,4.6-2.1,7.3c-0.2,2.7,0.2,5.8,1.4,8.5c1.1,2.8,2.9,5.2,5.1,7.2c2.2,2,4.8,3.6,7.7,4.6
				c2.9,1,6.3,1.4,9.3,1.1c3.1-0.3,6.1-1.2,8.7-2.6c2.7-1.4,5-3.2,7.1-5.3c1-1.1,1.9-2.2,2.8-3.4c0.8-1.2,1.5-2.4,2.1-3.7
				c2.5-5.3,3.2-10.4,3.2-14.9c0-4.6-0.8-8.7-1.8-12.3c-1-3.7-2.3-6.9-3.6-10c-1.3-3.1-2.7-6-4.2-8.9c-1.5-2.9-3-5.7-4.8-8.6
				c-1.7-2.9-3.5-5.8-5.6-8.7c-4.1-6-8.9-12.3-15.4-19.3c5.9,7.4,9.9,14.3,13.1,20.6c1.6,3.2,3,6.2,4.2,9.2c1.2,3,2.2,6,3.2,8.9
				c0.9,3,1.7,6,2.4,9c0.7,3.1,1.3,6.2,1.6,9.4c0.3,3.2,0.3,6.5-0.3,9.8C396.4,324.7,395.3,328,393.3,330.8z"
                />
              </g>
              <g>
                <path
                  d="M211.1,46.3c3.4-0.8,6.9-1,10.2-0.6c3.3,0.4,6.4,1.5,8.9,3.1c2.6,1.6,4.7,3.9,6.2,6.4c0.7,1.2,1.3,2.5,1.7,3.8
				c0.4,1.3,0.5,2.6,0.6,3.9c0.1,5.3-3,11-7.3,15.2c-1.1,1.1-2.2,2.1-3.4,2.9c-1.2,0.9-2.4,1.7-3.5,2.4c-2.4,1.4-4.7,2.1-6.6,2.3
				c-1,0.1-1.9,0-2.6-0.2c-0.8-0.2-1.6-0.5-2.3-1c-1.5-1-2.7-2.4-3.5-3.9c-0.4-0.8-0.6-1.5-0.8-2.3c-0.1-0.4-0.1-0.8-0.2-1.1
				c0-0.4,0-0.7,0-1.1c0.1-1.5,0.6-3,1.3-4.2c1.4-2.5,3.4-4.2,4.9-5.2c1.5-1,2.5-1.6,2.5-2.3c0-0.6-1.2-1.4-3.6-1.3
				c-1.2,0-2.7,0.3-4.3,0.9c-1.6,0.7-3.4,1.7-5.1,3.3c-1.7,1.6-3.3,3.8-4.3,6.8c-0.3,0.7-0.4,1.5-0.6,2.3c-0.1,0.8-0.2,1.6-0.2,2.5
				c-0.1,1.7,0.2,3.4,0.6,5.2c0.9,3.5,2.9,6.8,5.9,9.6c1.5,1.4,3.4,2.6,5.5,3.4c2.2,0.9,4.4,1.3,6.6,1.4c4.5,0.2,8.9-1,12.7-2.8
				c1.9-0.9,3.7-1.9,5.5-3.1c1.8-1.2,3.5-2.5,5.1-4c1.6-1.4,3.2-3.1,4.6-4.9c1.4-1.8,2.7-3.7,3.9-5.8c1.1-2.1,2.1-4.4,2.8-6.9
				c0.7-2.5,1.1-5.1,1.1-7.8c0-2.7-0.3-5.5-1.1-8.1c-0.8-2.6-1.9-5.1-3.3-7.4c-2.8-4.6-6.7-8.4-11.4-11.2c-4.7-2.8-9.9-4.2-15.1-4.6
				c-5.2-0.4-10.3,0.3-15.2,1.9c-5,1.6-9.3,4-13,6.7c-3.7,2.7-6.9,5.8-9.6,9c-5.3,6.4-9.1,13.2-11.6,19.9
				c-2.5,6.7-3.9,13.4-4.6,19.9c-0.7,6.5-0.7,12.8-0.1,19.1c0.6,6.3,1.8,12.4,3.6,18.6c1.8,6.2,4.1,12.4,6.9,18.7
				c5.7,12.7,13.8,25.8,24.4,40.6c-10-15.2-17.2-28.8-22-41.6c-2.4-6.4-4.2-12.6-5.4-18.7c-1.2-6.1-1.8-12-1.9-18
				c0-5.9,0.5-11.8,1.7-17.7c1.2-5.8,3-11.7,5.8-17.4c2.8-5.6,6.5-11.1,11.4-16c2.4-2.4,5.2-4.6,8.2-6.5
				C204.3,48.6,207.6,47.1,211.1,46.3z"
                />
              </g>
              <g>
                <path
                  d="M217.7,111.6c1.7-0.5,3.2-0.7,4.8-0.7c1.5,0,2.9,0.2,4,0.6c0.5,0.2,1,0.4,1.3,0.7c0.4,0.2,0.7,0.5,0.9,0.8
				c0.5,0.6,1,1.5,1.3,2.5c0.2,0.5,0.3,1.1,0.3,1.7c0.1,0.6,0.1,1.1,0,1.7c-0.1,1.1-0.4,2.2-0.7,3c-0.2,0.4-0.4,0.8-0.6,1.1
				c-0.2,0.3-0.4,0.6-0.7,0.8c-0.3,0.2-0.4,0.4-0.7,0.6c-0.3,0.2-0.6,0.3-1,0.4c-0.7,0.2-1.5,0.2-2.3,0.1c-0.7-0.1-1.4-0.3-2-0.7
				c-1.2-0.6-2.2-2-2.8-3.3c-0.6-1.3-0.9-2.6-1.1-3.6c-0.2-1-0.5-1.8-1-2.2c-0.5-0.3-1.4-0.2-2.5,0.7c-1,0.9-2.2,2.7-2.6,5.4
				c-0.2,1.3-0.2,2.9,0.1,4.5c0.3,1.7,1,3.5,2.3,5.2c2.5,3.5,7,6.2,12.4,6.3c1.3,0,2.7-0.1,4.2-0.4c1.4-0.3,2.9-0.9,4.2-1.7
				c1.3-0.8,2.5-1.7,3.6-2.8c1.1-1.1,2-2.3,2.8-3.5c1.5-2.5,2.5-5.3,3-8.3c0.2-1.5,0.3-3,0.2-4.5c-0.1-1.5-0.3-3-0.7-4.5
				c-0.8-3-2.3-6.1-4.8-8.7c-2.5-2.6-5.8-4.5-9-5.3c-3.2-0.9-6.4-1-9.4-0.6c-1.5,0.2-2.9,0.5-4.3,0.9c-1.4,0.4-2.7,1-4,1.5
				c-5.3,2.4-9.2,5.9-12.2,9.4c-1.5,1.8-2.8,3.6-3.9,5.4c-1.1,1.9-2,3.7-2.8,5.5c-3,7.5-3.6,14.5-3.3,21.3c0.3,6.8,1.7,13.4,4,20.4
				c2.2,7,5.4,14.5,9.3,23.3c-3.1-9.1-5.5-16.9-6.7-24c-1.3-7.1-1.6-13.5-0.8-19.6c0.7-6.1,2.5-12,5.9-17.3c0.8-1.3,1.8-2.6,2.9-3.9
				c1.1-1.2,2.3-2.4,3.5-3.5C211.2,114.4,214.2,112.5,217.7,111.6z"
                />
              </g>
              <g>
                <path
                  d="M252.1,32.8c3.2,0.6,6.4-0.3,8.5-1.8c2.1-1.5,3-3.4,2.5-5.1c-0.6-1.7-2.4-2.7-4.6-3c-2.2-0.3-4.8,0-7.4,1.2
				c-2.7,1.2-4.6,2.1-6.6,2.8c-2,0.7-4.2,1.2-7.3,1.3c3,1,5.1,1.7,7.3,2.5C246.6,31.4,248.8,32.2,252.1,32.8z"
                />
              </g>
              <g>
                <path
                  d="M267.3,45.6c1.8,2,3.7,3.3,5.6,4.1c1.9,0.8,4,1.1,5.5,0.2c1.5-0.9,1.9-3.2,0.7-5.8c-1.2-2.6-4-5.2-7.6-6.1
				c-3.7-0.9-6.2-0.8-8.7-0.6c-2.4,0.2-4.8,0.6-8,1.4c3.3,0.3,5.4,1.2,7.2,2.3C263.8,42.2,265.4,43.6,267.3,45.6z"
                />
              </g>
              <g>
                <path
                  d="M270,60.5c0.6,2.6,1.5,4.6,2.7,6.3c1.2,1.6,2.7,3,4.5,3.1c0.9,0,1.7-0.4,2.4-1.2c0.3-0.4,0.7-0.9,0.9-1.5
				c0.2-0.6,0.4-1.3,0.5-2c0.4-2.9-0.8-6.8-3.6-9.5c-2.9-2.7-5.2-3.8-7.5-4.7c-2.3-0.9-4.7-1.6-7.9-2.2c3,1.6,4.4,3.3,5.6,5.1
				C268.6,55.8,269.4,57.8,270,60.5z"
                />
              </g>
              <g>
                <path
                  d="M263.1,73.6c-0.7,2.7-0.6,5.1,0.1,7.2c0.7,2,1.9,3.7,3.7,4c1.7,0.3,3.5-0.9,4.7-3.2c1.1-2.3,1.5-5.6,0.3-8.6
				c-1.2-3.2-2.4-5.1-3.5-7.1c-1.2-1.9-2.3-3.8-3.9-6.5c0.6,3.1,0.5,5.2,0.2,7.3C264.3,68.8,263.9,70.8,263.1,73.6z"
                />
              </g>
              <g>
                <path
                  d="M251.6,86.6c-1.8,2-2.8,4.1-3.2,6.2c-0.4,2.1-0.1,4.1,1.2,5.3c1.3,1.2,3.5,1,5.7-0.5c2.1-1.5,4-4.4,4.4-7.7
				c0.4-3.4,0.1-5.8-0.2-8c-0.3-2.3-0.7-4.5-1.2-7.5c-0.5,3.1-1.4,5-2.5,6.8C254.7,82.9,253.5,84.5,251.6,86.6z"
                />
              </g>
              <g>
                <path
                  d="M241.2,20.8c6-2.2,9.5-7.9,7.4-10.8c-2.1-2.9-8.6-1.4-12.5,3.7c-2,2.6-3.3,4.5-4.6,6.4c-0.7,0.9-1.3,1.9-2.1,2.7
				c-0.4,0.4-0.9,0.8-1.5,1.1c-0.6,0.3-1.4,0.4-2.1,0.2c0.7,0.5,1.5,0.7,2.3,0.6c0.8,0,1.5-0.2,2.2-0.4c0.7-0.2,1.3-0.4,1.9-0.6
				c0.6-0.2,1.1-0.4,1.7-0.6C235.8,22.5,238.1,21.9,241.2,20.8z"
                />
              </g>
              <g>
                <path
                  d="M227.4,15.6c2.1-2.5,2.5-5.9,1.9-8.2c-0.7-2.4-2.4-3.8-4.1-3.6c-1.8,0.1-3.2,1.4-4.2,2.9c-1,1.5-1.6,3.3-1.6,5.5
				c0,2.4-0.2,4.2-0.7,5.9c-0.5,1.8-1.3,3.6-3.2,5.6c2.6-1.2,4.4-2.1,6.2-3.2C223.5,19.4,225.3,18.1,227.4,15.6z"
                />
              </g>
              <g>
                <path
                  d="M216.5,14.7c1.1-3.1,0.5-6.4-0.9-8.5c-1.4-2.1-3.4-2.9-5-2.3c-1.7,0.6-2.6,2.3-3.1,4.1c-0.5,1.8-0.5,3.9,0.3,6.1
				c0.8,2.4,1.2,4.2,1.4,6.1c0.2,1.9,0.1,3.9-1,6.6c1.9-2.1,3.3-3.8,4.6-5.5C214.1,19.6,215.4,17.8,216.5,14.7z"
                />
              </g>
              <g>
                <path
                  d="M204,15.5c-0.4-2.8-2.4-5-4.4-5.8c-2.1-0.8-4-0.4-5.1,1c-1.1,1.4-1.2,3.2-0.9,4.8c0.3,1.6,1.1,2.9,2.7,4.1
				c1.7,1.2,2.9,2.3,3.9,3.5c1,1.2,1.9,2.7,2.4,5.1c0.6-2.4,0.9-4.1,1.3-5.9C204.2,20.4,204.4,18.4,204,15.5z"
                />
              </g>
              <g>
                <path
                  d="M189.6,18.1c-1.2-0.9-2.6-1.3-4-1.3c-1.4,0-2.6,0.3-3.6,1c-1,0.6-1.6,1.4-1.9,2.3c-0.3,0.9-0.2,1.7,0.1,2.5
				c0.7,1.6,2.1,2.7,3.1,3.4c1.1,0.7,2,0.9,3.4,0.4c1.5-0.5,2.9-0.5,4.3-0.3c1.4,0.3,2.8,1,4.1,2.7c-0.3-2.2-0.8-3.8-1.5-5.5
				C193,21.7,192,19.9,189.6,18.1z"
                />
              </g>
              <g>
                <path
                  d="M174.9,28.6c-3.1,0-5.9,1.8-7.2,3.8c-1.3,2-1.2,4.2,0,5.4c1.2,1.3,3.1,1.6,4.8,1.4c1.8-0.2,3.4-0.8,5.1-2.3
				c1.7-1.5,3.2-2.6,4.8-3.4c1.6-0.8,3.4-1.4,6.1-1.4c-2.5-1.1-4.3-1.8-6.3-2.4C180.2,29.1,178.1,28.5,174.9,28.6z"
                />
              </g>
              <g>
                <path
                  d="M168.5,41.5c-2.5,0.6-4.3,2.4-4.8,4.3c-0.6,1.9-0.1,3.7,1.3,4.8c1.4,1.1,3.2,1.3,4.7,1c1.5-0.3,2.8-1.1,3.7-2.8
				c1-1.7,1.7-3,2.7-4.3c0.9-1.2,2.1-2.3,4.1-3.3c-2.2,0-3.8-0.1-5.6-0.2C172.8,41,171,40.9,168.5,41.5z"
                />
              </g>
              <g>
                <path
                  d="M158.7,62.1c-2.2,1.8-3.3,4.4-3.4,6.6c-0.1,2.2,0.9,4,2.5,4.7c1.6,0.7,3.5,0.1,5.1-1.1c1.6-1.2,2.8-3.1,3.3-5.6
				c0.5-2.6,0.8-4.4,1.3-6.3c0.5-1.8,1.1-3.6,2.6-6c-2.2,1.6-3.9,2.7-5.6,3.7C162.7,59.2,161,60.2,158.7,62.1z"
                />
              </g>
              <g>
                <path d="M155.7,90.1c-2.8,3.4-1.4,8,2,9c3.4,1,7.1-2.2,6.5-6.5c-0.6-4.5-1.6-6.4-1.4-10.8C160.4,85.5,158.6,86.6,155.7,90.1z" />
              </g>
              <g>
                <path
                  d="M157,113.8c-1.1,1.8-0.9,3.7-0.1,5.2c0.8,1.4,2.4,2.4,4.1,2.4c1.8,0,3.3-0.9,4.2-2.1c0.9-1.3,1.2-2.9,0.5-4.7
				c-1.5-3.7-2.8-5.3-3.2-9.2C160.8,108.9,159.2,110.1,157,113.8z"
                />
              </g>
              <g>
                <path
                  d="M191.5,102.9c2.1-4.3,3.5-8.8,3.9-12.4c0.5-3.6,0.1-6.5-1.5-7.2c-1.6-0.7-4.1,0.9-6.3,4.1c-2.2,3.2-4.1,8-4.6,13.1
				c-0.5,5.3-0.4,8.9-0.3,12.5c0.1,3.6,0.2,7.2,0.2,12.4c1-5.1,2.2-8.5,3.5-11.8C187.9,110.5,189.4,107.4,191.5,102.9z"
                />
              </g>
              <g>
                <g>
                  <path
                    d="M6.8,333.4c-3-1.7-5.7-4-7.9-6.6c-2.1-2.6-3.6-5.5-4.4-8.4c-0.8-2.9-0.8-6.1-0.2-8.9c0.3-1.4,0.8-2.7,1.4-3.9
					c0.6-1.2,1.4-2.2,2.3-3.2c3.6-3.9,9.7-6,15.7-6.1c1.5-0.1,3,0,4.5,0.1c1.5,0.1,2.9,0.4,4.2,0.7c2.7,0.6,4.8,1.6,6.3,2.8
					c0.8,0.6,1.4,1.3,1.8,1.9c0.5,0.7,0.8,1.5,1,2.3c0.4,1.7,0.4,3.6-0.2,5.2c-0.2,0.8-0.6,1.6-1,2.2c-0.2,0.4-0.4,0.6-0.7,0.9
					c-0.3,0.3-0.5,0.6-0.8,0.8c-1.1,1-2.5,1.7-3.9,2.2c-2.8,0.9-5.4,0.7-7.1,0.5c-1.8-0.3-3-0.5-3.4,0c-0.4,0.5-0.1,1.8,1.7,3.4
					c0.9,0.8,2.1,1.6,3.8,2.3c1.6,0.6,3.7,1.1,6,1.1c2.3,0,5.1-0.5,7.7-2c0.7-0.3,1.3-0.8,2-1.3c0.6-0.5,1.3-1,1.9-1.6
					c1.2-1.2,2.2-2.6,3.1-4.2c1.7-3.1,2.6-6.9,2.2-11c-0.2-2-0.7-4.2-1.7-6.3c-1-2.1-2.3-4-3.9-5.6c-3.2-3.2-7.2-5.3-11.2-6.6
					c-2-0.7-4.1-1.2-6.1-1.5c-2.1-0.4-4.2-0.5-6.4-0.6c-2.2-0.1-4.4,0.1-6.7,0.4c-2.3,0.3-4.6,0.9-6.8,1.6c-2.3,0.8-4.6,1.8-6.8,3.1
					c-2.2,1.4-4.3,3-6.1,4.9c-1.8,2-3.5,4.2-4.7,6.7c-1.2,2.4-2.1,5-2.7,7.6c-1.1,5.3-0.8,10.7,0.7,15.9c1.5,5.2,4.4,9.9,7.9,13.7
					c3.5,3.8,7.8,6.8,12.3,9c4.7,2.2,9.5,3.4,14.1,4c4.6,0.6,9,0.5,13.1,0c8.3-1,15.6-3.4,22.1-6.6c6.4-3.2,12-7.1,17-11.4
					c4.9-4.3,9.2-8.9,13.1-13.9c3.8-5,7.2-10.3,10.1-16c2.9-5.7,5.5-11.8,7.7-18.4c4.5-13.2,7.6-28.3,9.9-46.4
					c-3.1,18-7.1,32.8-12.3,45.4c-2.6,6.3-5.6,12.1-8.8,17.3c-3.3,5.3-6.9,10-10.9,14.4c-4,4.3-8.4,8.3-13.3,11.8
					c-4.9,3.5-10.2,6.5-16.1,8.7c-5.9,2.2-12.4,3.7-19.2,3.9c-3.4,0.1-6.9-0.1-10.4-0.9C13.3,336.4,9.9,335.2,6.8,333.4z"
                  />
                </g>
                <g>
                  <path
                    d="M46.6,281.2c-1.6-0.8-2.8-1.7-4-2.7c-1.1-1-2-2.1-2.5-3.1c-0.3-0.5-0.4-1-0.5-1.4c-0.1-0.4-0.1-0.8-0.1-1.2
					c0-0.8,0.3-1.7,0.8-2.7c0.3-0.5,0.6-1,0.9-1.5c0.4-0.5,0.7-0.9,1.1-1.2c0.8-0.8,1.8-1.4,2.6-1.7c0.4-0.2,0.8-0.3,1.2-0.4
					c0.4-0.1,0.7-0.1,1-0.1c0.3,0,0.6,0,0.9,0.1c0.3,0.1,0.6,0.2,1,0.4c0.7,0.3,1.3,0.9,1.7,1.5c0.5,0.6,0.8,1.2,1,1.8
					c0.4,1.3,0.2,2.9-0.2,4.3c-0.5,1.4-1.1,2.5-1.7,3.4c-0.5,0.9-0.9,1.6-0.7,2.3c0.2,0.6,0.9,1.1,2.3,1.2c1.4,0,3.5-0.5,5.6-2.2
					c1.1-0.8,2.1-1.9,3-3.4c0.9-1.4,1.6-3.3,1.9-5.4c0.6-4.3-0.9-9.3-4.7-13c-1-0.9-2.1-1.8-3.3-2.5c-1.3-0.7-2.7-1.3-4.2-1.6
					c-1.5-0.3-3-0.5-4.5-0.5c-1.5,0.1-3,0.3-4.4,0.7c-2.9,0.8-5.5,2.2-7.8,4c-1.2,0.9-2.3,2-3.2,3.2c-1,1.2-1.8,2.4-2.6,3.8
					c-1.5,2.7-2.5,6-2.5,9.6c0,3.6,1.2,7.2,2.9,10c1.7,2.9,4,5.1,6.4,6.8c1.2,0.9,2.5,1.6,3.8,2.3c1.3,0.7,2.7,1.2,4,1.6
					c5.5,1.8,10.7,2,15.4,1.5c2.3-0.3,4.5-0.7,6.6-1.3c2.1-0.6,4-1.3,5.8-2.2c7.3-3.4,12.6-8.1,17-13.2c4.4-5.2,7.9-10.9,11-17.6
					c3.2-6.7,5.9-14.3,9.1-23.4c-4,8.8-7.5,16.1-11.4,22.1c-3.9,6.1-8.1,10.9-12.8,14.9c-4.7,3.9-10,7-16.1,8.6
					c-1.5,0.4-3.1,0.7-4.7,0.9c-1.6,0.2-3.3,0.2-5,0.1C53.2,283.5,49.7,282.8,46.6,281.2z"
                  />
                </g>
                <g>
                  <path
                    d="M-32.4,315.2c-2-2.6-4.9-4.2-7.4-4.5c-2.5-0.4-4.6,0.4-5.3,2c-0.7,1.6-0.1,3.6,1.3,5.3c1.4,1.7,3.5,3.3,6.2,4.2
					c2.8,0.9,4.8,1.6,6.8,2.5c2,0.9,3.9,1.9,6.2,4c-1.5-2.8-2.6-4.7-3.6-6.8C-29.3,320-30.4,317.9-32.4,315.2z"
                  />
                </g>
                <g>
                  <path
                    d="M-34.7,295.5c0-2.7-0.4-4.9-1.3-6.9c-0.9-1.9-2.1-3.5-3.9-3.8c-1.7-0.3-3.6,1.1-4.5,3.8c-0.9,2.7-0.6,6.6,1.4,9.7
					c2.1,3.2,4,4.8,5.9,6.3c1.9,1.5,3.9,2.8,6.8,4.4c-2.2-2.4-3.1-4.5-3.7-6.6C-34.5,300.4-34.8,298.3-34.7,295.5z"
                  />
                </g>
                <g>
                  <path
                    d="M-26.6,282.8c1.3-2.3,2-4.4,2.3-6.5c0.2-2,0.1-4.1-1.2-5.3c-0.6-0.6-1.5-0.9-2.5-0.8c-0.5,0.1-1.1,0.2-1.6,0.5
					c-0.5,0.3-1.2,0.6-1.7,1.1c-2.3,1.9-4.1,5.5-3.9,9.3c0.3,3.9,1.2,6.3,2.3,8.6c1.1,2.2,2.3,4.4,4.3,7c-1.1-3.2-1-5.5-0.6-7.6
					C-28.7,287.1-27.9,285.2-26.6,282.8z"
                  />
                </g>
                <g>
                  <path
                    d="M-12.6,277.9c2.3-1.5,3.9-3.4,4.9-5.3c0.9-1.9,1.1-4,0.1-5.4c-1-1.4-3.2-1.8-5.6-0.9c-2.4,0.9-4.9,3.1-6.2,6.1
					c-1.3,3.1-1.8,5.4-2.3,7.6c-0.4,2.2-0.9,4.4-1.5,7.4c1.7-2.6,3.2-4.2,4.8-5.5C-16.7,280.6-15,279.4-12.6,277.9z"
                  />
                </g>
                <g>
                  <path
                    d="M4.7,276.2c2.7-0.3,4.9-1.1,6.6-2.4c1.7-1.3,2.9-2.9,2.7-4.7c-0.2-1.7-1.9-3.2-4.4-3.5c-2.5-0.4-5.9,0.4-8.5,2.6
					c-2.6,2.3-4,4.2-5.4,6c-1.3,1.9-2.6,3.7-4.3,6.3c2.5-1.9,4.5-2.7,6.5-3.3C-0.1,276.8,1.9,276.5,4.7,276.2z"
                  />
                </g>
                <g>
                  <path
                    d="M-32.6,331.5c-5.9-2.5-12.3-0.7-12.8,2.8c-0.4,3.5,5.4,6.9,11.7,5.9c3.2-0.5,5.5-1.1,7.8-1.5c1.2-0.2,2.2-0.5,3.4-0.5
					c0.6,0,1.2,0,1.8,0.2c0.6,0.2,1.3,0.6,1.7,1.3c-0.2-0.8-0.7-1.5-1.2-2c-0.6-0.5-1.2-0.9-1.8-1.2c-0.6-0.3-1.2-0.6-1.8-0.8
					c-0.6-0.2-1.1-0.5-1.6-0.7C-27.5,333.9-29.6,332.8-32.6,331.5z"
                  />
                </g>
                <g>
                  <path
                    d="M-26.1,344.6c-3.2,0.4-5.9,2.5-7,4.8c-1.2,2.2-0.8,4.4,0.5,5.5c1.4,1.1,3.3,1.1,5.1,0.8c1.8-0.4,3.4-1.3,4.9-3
					c1.6-1.7,3-2.9,4.6-3.9c1.6-1,3.4-1.7,6.2-1.9c-2.7-0.9-4.6-1.4-6.7-1.9C-20.6,344.6-22.8,344.2-26.1,344.6z"
                  />
                </g>
                <g>
                  <path
                    d="M-18.7,352.8c-2.9,1.5-4.7,4.4-5.1,6.8c-0.4,2.5,0.5,4.5,2.1,5.1c1.7,0.7,3.5,0.1,5.1-0.9c1.6-1,3-2.5,4-4.7
					c1-2.2,2-3.9,3.1-5.4c1.2-1.5,2.6-2.9,5.2-4.2c-2.9,0.2-5,0.5-7.1,0.9C-13.6,350.8-15.8,351.3-18.7,352.8z"
                  />
                </g>
                <g>
                  <path
                    d="M-9,360.7c-1.6,2.3-1.7,5.3-0.7,7.3c0.9,2,2.7,3,4.4,2.7c1.7-0.3,3.1-1.5,4-2.9c0.9-1.4,1.2-2.9,0.8-4.8
					c-0.4-2-0.6-3.6-0.5-5.2c0.1-1.6,0.4-3.3,1.7-5.4c-2,1.3-3.5,2.4-5,3.5C-5.8,357-7.4,358.3-9,360.7z"
                  />
                </g>
                <g>
                  <path
                    d="M3.3,368.7c0.3,1.5,1,2.7,2,3.7c1,0.9,2.1,1.5,3.2,1.7c1.1,0.2,2.1,0,2.9-0.4c0.8-0.4,1.3-1.1,1.6-1.9
					c0.6-1.7,0.4-3.4,0.1-4.6c-0.3-1.3-0.8-2.1-2.2-2.7c-1.4-0.7-2.5-1.6-3.3-2.7c-0.8-1.2-1.4-2.6-1.1-4.8c-1.3,1.8-2,3.4-2.6,5.1
					C3.3,363.7,2.7,365.7,3.3,368.7z"
                  />
                </g>
                <g>
                  <path
                    d="M21.2,371c2.3,2.1,5.5,2.7,7.9,2.1c2.4-0.6,3.8-2.2,3.7-3.9c0-1.8-1.2-3.3-2.6-4.4c-1.4-1.1-3.1-1.7-5.3-1.8
					c-2.3-0.1-4.1-0.3-5.8-0.8c-1.7-0.5-3.4-1.3-5.4-3.2c1.1,2.5,1.9,4.3,2.9,6.1C17.7,367,18.8,368.9,21.2,371z"
                  />
                </g>
                <g>
                  <path
                    d="M34.6,366c2.2,1.3,4.7,1.2,6.4,0.2c1.7-1,2.6-2.7,2.3-4.4c-0.3-1.7-1.4-3.1-2.7-4c-1.3-0.8-2.8-1.1-4.7-0.5
					c-1.9,0.6-3.3,1-4.8,1.3c-1.5,0.2-3.1,0.3-5.2-0.4c1.6,1.5,2.7,2.7,3.9,3.9C31.1,363.4,32.4,364.7,34.6,366z"
                  />
                </g>
                <g>
                  <path
                    d="M55.9,357.6c2.9,0.2,5.5-1,7-2.5c1.6-1.6,2.1-3.5,1.4-5.1c-0.7-1.6-2.5-2.5-4.5-2.7c-2-0.2-4.2,0.4-6.2,1.9
					c-2.1,1.5-3.6,2.7-5.2,3.7c-1.6,1-3.3,1.9-6,2.6c2.7,0.3,4.7,0.7,6.6,1.1C51,357,52.9,357.4,55.9,357.6z"
                  />
                </g>
                <g>
                  <path
                    d="M77.2,339.2c4.4-0.5,6.5-4.9,4.7-7.9c-1.8-3-6.6-3.2-9.2,0.4c-2.6,3.7-3.2,5.7-6.3,8.9
					C70.6,339.3,72.7,339.8,77.2,339.2z"
                  />
                </g>
                <g>
                  <path
                    d="M92.4,321c2-0.6,3.2-2.1,3.6-3.7c0.4-1.6-0.1-3.4-1.4-4.6c-1.3-1.2-3-1.6-4.5-1.3c-1.5,0.3-2.9,1.3-3.6,3.1
					c-1.4,3.7-1.5,5.7-3.9,8.9C86.3,321.9,88.3,322.2,92.4,321z"
                  />
                </g>
                <g>
                  <path
                    d="M59.8,305.3c-4.5,1.7-8.5,4.1-11.4,6.4c-2.8,2.3-4.5,4.7-3.8,6.3c0.7,1.6,3.6,2.1,7.4,1.3c3.8-0.8,8.5-3,12.3-6.5
					c3.9-3.5,6.3-6.2,8.8-8.9c2.4-2.7,4.8-5.4,8.3-9.3c-4.2,3.1-7.4,4.7-10.6,6.2C67.6,302.3,64.4,303.6,59.8,305.3z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M363.6,398.2c3.2,1.3,6.2,3.2,8.6,5.5c2.4,2.3,4.3,5,5.4,7.7c1.1,2.8,1.6,5.9,1.3,8.8c-0.1,1.4-0.4,2.8-0.9,4.1
					c-0.4,1.3-1.1,2.4-1.8,3.5c-3,4.3-8.8,7.2-14.8,8.1c-1.5,0.3-3,0.4-4.4,0.4c-1.5,0.1-2.9,0-4.3-0.1c-2.7-0.3-5-1-6.7-2
					c-0.8-0.5-1.5-1.1-2-1.7c-0.5-0.6-1-1.4-1.3-2.2c-0.6-1.7-0.8-3.6-0.5-5.2c0.1-0.8,0.4-1.6,0.7-2.3c0.1-0.4,0.4-0.7,0.5-1
					c0.2-0.3,0.4-0.6,0.7-0.9c1-1.2,2.2-2.1,3.6-2.7c2.6-1.2,5.2-1.4,7-1.4c1.8,0,3,0.1,3.4-0.4c0.4-0.5-0.2-1.8-2.1-3.2
					c-1-0.7-2.3-1.3-4-1.8c-1.7-0.4-3.8-0.6-6.1-0.3c-2.3,0.3-4.9,1.2-7.4,3c-0.6,0.4-1.2,1-1.8,1.5c-0.6,0.6-1.2,1.2-1.6,1.9
					c-1,1.3-1.9,2.9-2.5,4.5c-1.3,3.3-1.6,7.2-0.8,11.2c0.4,2,1.2,4.1,2.4,6c1.3,2,2.8,3.6,4.6,5c3.6,2.8,7.8,4.3,11.9,5.1
					c2.1,0.4,4.2,0.6,6.3,0.7c2.1,0.1,4.3,0,6.4-0.3c2.2-0.2,4.4-0.7,6.6-1.3c2.2-0.6,4.4-1.4,6.6-2.5c2.2-1.1,4.3-2.4,6.3-4
					c2-1.6,3.8-3.5,5.5-5.7c1.6-2.2,2.9-4.6,3.8-7.2c0.9-2.6,1.5-5.2,1.7-7.9c0.4-5.4-0.6-10.7-2.7-15.7c-2.2-5-5.6-9.2-9.6-12.6
					c-4-3.3-8.6-5.8-13.4-7.3c-5-1.6-9.9-2.2-14.5-2.1c-4.6,0-9,0.7-13,1.7c-8.1,2.1-15,5.4-21,9.4c-6,4-11,8.6-15.3,13.5
					c-4.3,4.9-8,10-11.1,15.5c-3.1,5.4-5.8,11.2-8,17.2c-2.2,6.1-3.9,12.4-5.3,19.3c-2.7,13.7-3.8,29-3.8,47.2
					c0.7-18.2,2.8-33.4,6.3-46.6c1.8-6.6,3.9-12.7,6.5-18.3c2.6-5.7,5.5-10.9,8.9-15.7c3.4-4.8,7.3-9.3,11.6-13.4
					c4.4-4.1,9.3-7.7,14.8-10.7c5.5-3,11.8-5.2,18.5-6.4c3.4-0.5,6.9-0.8,10.4-0.5C356.7,396.1,360.3,396.8,363.6,398.2z"
                  />
                </g>
                <g>
                  <path
                    d="M331,455.1c1.7,0.6,3,1.3,4.3,2.2c1.2,0.9,2.2,1.9,2.9,2.8c0.3,0.5,0.5,0.9,0.7,1.3c0.2,0.4,0.2,0.8,0.3,1.2
					c0.1,0.8,0,1.8-0.4,2.8c-0.2,0.5-0.4,1.1-0.7,1.6c-0.3,0.5-0.6,0.9-1,1.4c-0.7,0.9-1.6,1.6-2.4,2.1c-0.4,0.2-0.8,0.4-1.1,0.6
					c-0.4,0.1-0.7,0.2-1,0.3c-0.3,0-0.6,0.1-0.9,0c-0.3,0-0.7-0.1-1-0.2c-0.7-0.3-1.4-0.7-1.9-1.2c-0.5-0.5-1-1.1-1.2-1.7
					c-0.6-1.2-0.6-2.9-0.3-4.3c0.3-1.4,0.8-2.6,1.2-3.6c0.4-1,0.7-1.7,0.4-2.4c-0.2-0.6-1-1-2.5-0.9c-1.4,0.2-3.4,0.9-5.3,2.9
					c-0.9,1-1.9,2.2-2.6,3.7c-0.7,1.6-1.2,3.4-1.2,5.6c0,4.3,2.1,9.1,6.4,12.3c1.1,0.8,2.3,1.5,3.6,2.1c1.3,0.5,2.9,0.9,4.4,1.1
					c1.5,0.1,3,0.1,4.5-0.1c1.5-0.3,2.9-0.7,4.3-1.2c2.7-1.2,5.2-2.9,7.2-5c1-1.1,2-2.3,2.8-3.6c0.8-1.3,1.5-2.6,2-4.1
					c1.1-2.9,1.7-6.3,1.2-9.9c-0.5-3.6-2.1-7-4.2-9.5c-2.1-2.6-4.6-4.5-7.3-5.9c-1.3-0.7-2.7-1.3-4.1-1.8c-1.4-0.5-2.8-0.8-4.1-1.1
					c-5.7-1.1-10.9-0.6-15.4,0.5c-2.3,0.6-4.4,1.3-6.3,2.2c-2,0.9-3.8,1.8-5.5,2.9c-6.8,4.3-11.4,9.7-15.1,15.3
					c-3.7,5.7-6.4,11.9-8.7,18.9c-2.3,7-4,15-6,24.4c2.8-9.2,5.4-16.9,8.5-23.4c3.1-6.5,6.6-11.9,10.7-16.4c4.1-4.5,9-8.3,14.8-10.6
					c1.5-0.6,3-1.1,4.6-1.5c1.6-0.4,3.2-0.6,4.9-0.8C324,453.6,327.6,453.9,331,455.1z"
                  />
                </g>
                <g>
                  <path
                    d="M404.9,411.1c2.3,2.3,5.4,3.5,7.9,3.5c2.5,0,4.5-1,5-2.7c0.5-1.7-0.4-3.6-2-5.1c-1.6-1.6-3.9-2.8-6.7-3.3
					c-2.9-0.6-5-1-7-1.6c-2.1-0.6-4.1-1.4-6.7-3.2c1.9,2.5,3.2,4.4,4.4,6.3C401.2,406.8,402.5,408.7,404.9,411.1z"
                  />
                </g>
                <g>
                  <path
                    d="M409.7,430.4c0.3,2.7,1.1,4.8,2.2,6.6c1.1,1.8,2.6,3.2,4.3,3.3c1.7,0.1,3.4-1.5,4-4.4c0.6-2.8-0.2-6.6-2.6-9.4
					c-2.5-2.9-4.6-4.3-6.7-5.5c-2.1-1.2-4.2-2.3-7.3-3.5c2.5,2.1,3.7,4.1,4.5,6.1C408.9,425.6,409.4,427.6,409.7,430.4z"
                  />
                </g>
                <g>
                  <path
                    d="M403.3,444.1c-1,2.4-1.5,4.6-1.4,6.7c0,2,0.4,4,1.8,5.1c0.7,0.5,1.6,0.7,2.6,0.4c0.5-0.1,1.1-0.3,1.6-0.7
					c0.5-0.3,1.1-0.8,1.6-1.3c2-2.1,3.4-6,2.6-9.8c-0.8-3.8-2.1-6.1-3.4-8.2c-1.4-2.1-2.8-4-5.2-6.4c1.5,3,1.7,5.3,1.5,7.4
					C404.8,439.5,404.3,441.5,403.3,444.1z"
                  />
                </g>
                <g>
                  <path
                    d="M390,450.7c-2.1,1.7-3.5,3.8-4.1,5.9c-0.6,2-0.6,4.1,0.6,5.4c1.2,1.3,3.4,1.3,5.6,0.1c2.2-1.2,4.4-3.7,5.3-6.8
					c0.9-3.3,1.1-5.6,1.3-7.8c0.2-2.3,0.3-4.5,0.6-7.6c-1.3,2.8-2.6,4.5-4.1,6.1C393.8,447.5,392.2,448.9,390,450.7z"
                  />
                </g>
                <g>
                  <path
                    d="M373.1,454.6c-2.6,0.6-4.7,1.8-6.2,3.2c-1.5,1.5-2.5,3.3-2.1,5c0.4,1.7,2.3,2.9,4.9,2.9c2.6,0,5.8-1.2,8.1-3.7
					c2.3-2.6,3.4-4.6,4.5-6.7c1.1-2,2.1-4,3.4-6.8c-2.2,2.2-4.1,3.3-6,4.1C377.8,453.4,375.8,453.9,373.1,454.6z"
                  />
                </g>
                <g>
                  <path
                    d="M402.9,395c6.1,1.8,12.3-0.9,12.3-4.5c0-3.5-6.2-6.1-12.3-4.3c-3.2,0.9-5.3,1.8-7.5,2.5c-1.1,0.4-2.2,0.8-3.3,0.9
					c-0.6,0.1-1.2,0.1-1.8,0c-0.6-0.1-1.3-0.5-1.8-1.1c0.3,0.8,0.9,1.4,1.5,1.9c0.6,0.4,1.3,0.7,2,1c0.6,0.2,1.3,0.4,1.9,0.6
					c0.6,0.2,1.1,0.3,1.7,0.5C397.6,393.2,399.8,394.1,402.9,395z"
                  />
                </g>
                <g>
                  <path
                    d="M394.7,382.8c3.1-0.8,5.5-3.3,6.3-5.6c0.9-2.4,0.2-4.4-1.3-5.3c-1.5-0.9-3.4-0.7-5.1-0.1c-1.7,0.6-3.2,1.7-4.5,3.6
					c-1.4,1.9-2.6,3.3-4,4.4c-1.4,1.1-3.1,2.2-5.9,2.7c2.8,0.5,4.8,0.8,6.9,1C389.3,383.5,391.5,383.6,394.7,382.8z"
                  />
                </g>
                <g>
                  <path
                    d="M386.4,375.6c2.7-1.9,4.1-4.9,4.2-7.4c0.1-2.5-1.1-4.4-2.8-4.8c-1.7-0.4-3.5,0.4-5,1.5c-1.5,1.2-2.6,2.9-3.3,5.2
					c-0.7,2.4-1.5,4.1-2.4,5.8c-1,1.6-2.2,3.2-4.6,4.8c2.8-0.6,4.8-1.2,6.9-1.8C381.5,378.3,383.7,377.5,386.4,375.6z"
                  />
                </g>
                <g>
                  <path
                    d="M375.7,369.1c1.3-2.5,1-5.5-0.2-7.3c-1.2-1.9-3.1-2.6-4.7-2.1c-1.7,0.5-2.9,1.9-3.6,3.3c-0.7,1.5-0.8,3-0.2,4.9
					c0.6,1.9,1,3.5,1.2,5.1c0.1,1.6,0,3.3-1,5.5c1.8-1.6,3.2-2.8,4.5-4.1C373.1,373.1,374.4,371.7,375.7,369.1z"
                  />
                </g>
                <g>
                  <path
                    d="M362.5,362.8c-0.5-1.4-1.4-2.6-2.5-3.4c-1.1-0.8-2.3-1.2-3.4-1.3c-1.1-0.1-2.1,0.2-2.8,0.8c-0.7,0.5-1.2,1.3-1.4,2.1
					c-0.4,1.7,0.1,3.4,0.5,4.6c0.5,1.2,1.1,1.9,2.5,2.4c1.5,0.5,2.7,1.2,3.6,2.3c0.9,1,1.7,2.4,1.8,4.6c1-2,1.6-3.6,2-5.4
					C363.2,367.7,363.5,365.7,362.5,362.8z"
                  />
                </g>
                <g>
                  <path
                    d="M344.5,362.7c-2.6-1.8-5.9-2-8.1-1.1c-2.3,0.9-3.4,2.7-3.2,4.4c0.3,1.8,1.6,3.1,3.1,4c1.5,0.9,3.3,1.3,5.5,1.1
					c2.3-0.2,4.1-0.2,5.8,0c1.8,0.3,3.6,0.8,5.8,2.5c-1.4-2.3-2.5-4-3.7-5.7C348.5,366.3,347.1,364.6,344.5,362.7z"
                  />
                </g>
                <g>
                  <path
                    d="M331.8,369.5c-2.3-1-4.8-0.5-6.4,0.6c-1.6,1.2-2.2,3-1.8,4.7c0.5,1.7,1.8,2.9,3.2,3.6c1.4,0.6,3,0.7,4.7-0.1
					c1.8-0.8,3.2-1.5,4.6-1.9c1.5-0.4,3-0.7,5.2-0.3c-1.8-1.3-3.1-2.3-4.4-3.4C335.6,371.6,334.2,370.5,331.8,369.5z"
                  />
                </g>
                <g>
                  <path
                    d="M311.8,380.6c-2.9,0.2-5.3,1.7-6.6,3.4c-1.4,1.8-1.7,3.8-0.7,5.3c0.9,1.5,2.8,2.1,4.8,2c2-0.1,4.1-0.9,5.9-2.7
					c1.9-1.8,3.2-3.1,4.7-4.3c1.5-1.2,3-2.3,5.6-3.4c-2.8,0-4.7-0.1-6.7-0.3C316.8,380.6,314.7,380.4,311.8,380.6z"
                  />
                </g>
                <g>
                  <path
                    d="M293.1,401.5c-4.3,1.1-5.8,5.7-3.6,8.4c2.2,2.8,7,2.4,9-1.5c2.1-4,2.4-6.1,5.1-9.6C299.6,400.6,297.5,400.4,293.1,401.5
					z"
                  />
                </g>
                <g>
                  <path
                    d="M280.3,421.6c-1.9,0.9-2.9,2.5-3.1,4.2c-0.2,1.7,0.5,3.3,2,4.4c1.4,1,3.2,1.2,4.7,0.7c1.5-0.5,2.7-1.7,3.1-3.6
					c0.9-3.9,0.8-5.9,2.7-9.4C286.3,419.9,284.3,419.9,280.3,421.6z"
                  />
                </g>
                <g>
                  <path
                    d="M314.7,432.9c4.2-2.3,7.9-5.1,10.4-7.8c2.5-2.7,3.8-5.2,2.9-6.7c-0.9-1.5-3.8-1.6-7.5-0.3c-3.7,1.3-8,4.1-11.4,8
					c-3.5,4-5.5,7-7.5,10c-2,3-4,6-7,10.3c3.8-3.6,6.7-5.7,9.7-7.5C307.3,436.9,310.4,435.2,314.7,432.9z"
                  />
                </g>
              </g>
              <g>
                <path
                  d="M344.5,109.3c1.3-4.4,1.9-9.1,1.4-13.7c-0.4-4.6-1.8-9.3-4.3-13.4c-0.3-0.5-0.6-1-1-1.5c-0.4-0.5-0.7-1-1.1-1.5
				c-0.8-0.9-1.5-1.8-2.4-2.7c-1.7-1.7-3.6-3.1-5.6-4.3c-4-2.4-8.3-3.8-12.6-4.4c-4.3-0.6-8.7-0.6-12.9,0.4
				c-4.2,0.9-8.3,2.7-11.6,5.5c-3.3,2.7-5.9,6.2-7.2,10.2c-0.7,1.9-1.1,4.1-1.1,6.1c0,2,0.2,4,0.8,5.9c1.1,3.7,3.3,7,6.3,9.2
				c3,2.2,6.5,3.1,9.6,3c3.1-0.1,6.1-1.1,8.3-2.8c1.1-0.9,2-1.9,2.7-2.9c0.7-1.1,1.2-2.2,1.4-3.2c0.6-2.1,0.5-4.1,0.2-5.7
				c-0.3-1.6-1-2.9-1.6-3.8c-1.4-1.8-2.6-2.1-2.9-1.8c-0.3,0.3,0.2,1.3,0.6,2.9c0.2,0.8,0.3,1.8,0.2,3c-0.1,1.1-0.4,2.4-1,3.7
				c-0.3,0.6-0.7,1.2-1.2,1.7c-0.5,0.5-1.1,0.9-1.7,1.3c-1.3,0.7-3,1.1-4.8,0.9c-1.8-0.2-3.7-0.9-5-2.1c-1.4-1.3-2.5-3.2-2.9-5.3
				c-0.2-1.1-0.3-2.2-0.2-3.4c0.1-1.1,0.4-2.2,0.8-3.3c0.9-2.2,2.6-4.3,4.7-6c2.1-1.6,4.8-2.7,7.7-3.3c2.9-0.6,6.2-0.6,9.4-0.1
				c6.4,1,12.6,4.2,15.9,9.6c3.5,5.4,4.4,12.8,2.7,19.6c-0.4,1.7-1,3.4-1.8,5c-0.7,1.6-1.6,3.1-2.6,4.6c-2,2.9-4.2,5.4-6.7,7.6
				c-5,4.4-10.6,7.5-16.3,9.7c-5.7,2.3-11.4,3.6-17.1,4.6c-5.7,1-11.4,1.5-17.1,1.9c-5.7,0.4-11.5,0.5-17.5,0.9
				c-6,0.4-12.3,1.1-18.6,3c-3.2,0.9-6.4,2.1-9.5,3.8c-3.1,1.6-6.1,3.7-8.8,6.3c-2.7,2.6-5,5.8-6.7,9.4c-1.6,3.6-2.6,7.8-2.2,12.1
				c-0.2-4.3,0.9-8.4,2.6-12c1.8-3.5,4.1-6.6,6.9-9c2.7-2.4,5.7-4.3,8.8-5.8c3.1-1.4,6.2-2.5,9.4-3.2c6.3-1.5,12.4-1.9,18.3-1.9
				c5.9-0.1,11.7,0.2,17.5,0.2c5.8,0,11.6-0.2,17.6-0.8c6-0.7,12.2-1.7,18.5-3.8c6.3-2,12.9-5,19-9.8c3.1-2.4,6-5.2,8.6-8.6
				C340.9,117.8,343.1,113.9,344.5,109.3z"
                />
              </g>
              <g>
                <path
                  d="M278.5,96c-3.9-1.2-8.4-1.3-12.5,0.5c-2,0.9-3.8,2.1-5.3,3.7c-1.5,1.5-2.7,3.3-3.6,5.3c-0.8,2-1.2,4.2-1.1,6.4
				c0.1,2.2,0.7,4.2,1.6,6c1.8,3.5,4.9,6.1,8.4,7c3.5,1,6.9,0.3,9.4-1.4c1.2-0.8,2.2-1.9,2.8-3c0.6-1.1,0.9-2.2,1-3.1
				c0.2-1.9-0.3-3.4-0.9-4.2c-0.6-0.8-1.2-1-1.6-0.9c-0.8,0.3-0.9,2.1-2.2,3.6c-0.3,0.4-0.7,0.8-1.1,1c-0.4,0.3-0.8,0.4-1.3,0.5
				c-1,0.2-2.4,0.1-3.4-0.5c-1.1-0.6-2.2-1.8-2.7-3.2c-0.6-1.5-0.5-2.8,0.1-4.4c0.7-1.6,2.1-3.1,3.8-4c1.7-0.9,3.9-1.2,6.3-0.9
				c4.9,0.7,8.5,4.3,10.6,8.1c2.2,3.9,3,8.1,2.7,12.3c-0.2,2.1-0.6,4.2-1.5,6.2c-0.4,1-1,2-1.6,3c-0.7,0.9-1.4,1.8-2.4,2.7
				c-1.8,1.6-4.1,3-6.9,3.8c-2.7,0.7-6,0.9-9.2-0.3c3.1,1.4,6.5,1.6,9.4,1.1c3-0.5,5.6-1.7,7.8-3.3c1.1-0.8,2.1-1.7,3-2.6
				c0.9-1,1.7-2,2.3-3.1c1.4-2.1,2.2-4.5,2.9-6.9c1.1-4.8,1-10-0.9-15.4c-1-2.7-2.4-5.4-4.7-8C285.7,99.6,282.5,97.2,278.5,96z"
                />
              </g>
              <g>
                <path
                  d="M315.1,122.6c2.7-2.4,4.1-5.8,4.2-8.5c0.1-2.8-0.9-4.8-2.6-5.3c-1.7-0.5-3.6,0.5-5.2,2.2c-1.6,1.7-2.9,3.9-3.7,6.8
				c-0.8,2.9-1.5,5-2.5,7.1c-1,2-2.1,4-4.5,6.4c3-1.5,5.1-2.6,7.3-3.8C310.2,126.3,312.4,125.1,315.1,122.6z"
                />
              </g>
              <g>
                <path
                  d="M366,89.8c2.5-1.7,4.3-3.8,5.4-5.9c1.1-2.1,1.5-4.2,0.4-5.7c-1-1.4-3.3-1.7-5.9-0.7c-2.6,1.1-5.4,3.6-6.8,6.9
				c-1.5,3.5-2,6-2.5,8.4c-0.4,2.5-0.8,4.9-1.4,8.3c1.5-3.1,3.1-4.9,4.7-6.6C361.7,93,363.5,91.6,366,89.8z"
                />
              </g>
              <g>
                <path
                  d="M379.9,95.6c3-0.8,5.4-2.4,6.9-4.3c1.5-1.8,2.2-3.9,1.4-5.5c-0.8-1.6-2.9-2.2-5.4-1.9c-2.5,0.3-5.4,1.7-7.6,4.1
				c-2.3,2.5-3.6,4.4-5,6.2c-1.4,1.8-2.8,3.6-5.1,6c2.9-1.5,5.1-2.3,7.2-2.9C374.6,96.9,376.8,96.4,379.9,95.6z"
                />
              </g>
              <g>
                <path
                  d="M390.9,105.2c3.1,0.2,5.9-0.4,8-1.5c2.1-1.1,3.7-2.6,3.6-4.4c0-1.7-1.7-3.3-4.4-4c-2.6-0.7-6.2-0.4-9.3,1.3
				c-3.2,1.7-5.2,3.3-7.1,4.7c-1.9,1.5-3.8,3-6.6,5c3.3-1.1,5.6-1.4,7.9-1.5C385.4,104.9,387.7,105,390.9,105.2z"
                />
              </g>
              <g>
                <path
                  d="M360.3,116.8c2.7-0.8,4.8-2.3,6.1-4c1.3-1.7,2-3.7,1.2-5.3c-0.7-1.6-2.7-2.4-5.1-2.1c-2.4,0.3-5.2,1.7-7.2,4.2
				c-2,2.5-3.1,4.5-4.2,6.4c-1.1,1.9-2.2,3.8-3.9,6.3c2.5-1.8,4.4-2.7,6.3-3.5C355.5,118.1,357.5,117.6,360.3,116.8z"
                />
              </g>
              <g>
                <path
                  d="M373.4,121.3c2.6,0.1,4.9-0.6,6.6-1.8c1.7-1.2,2.8-2.9,2.6-4.6c-0.2-1.7-1.8-3.1-4.1-3.6c-2.3-0.5-5.2,0-7.7,1.7
				c-2.5,1.8-4.1,3.3-5.6,4.7c-1.5,1.5-3,2.9-5.3,4.7c2.8-0.9,4.8-1.1,6.7-1.2C368.7,121,370.7,121.2,373.4,121.3z"
                />
              </g>
              <g>
                <path
                  d="M293.2,147.9c-6.4,0.5-11.3,5.2-10.1,8.5c1.2,3.3,8,3.6,13.2-0.3c5.3-4,7-6.2,12.8-9.5
				C302.5,148,299.8,147.4,293.2,147.9z"
                />
              </g>
              <g>
                <path
                  d="M300.9,162.8c-1.7,2.4-2,5.5-1.2,7.6c0.7,2.2,2.4,3.5,4.1,3.4c1.8-0.1,3.2-1.3,4.2-2.8c1-1.5,1.5-3.4,1.3-5.6
				c-0.2-2.3-0.3-4.1-0.2-5.9c0.2-1.8,0.5-3.6,1.8-5.9c-2.1,1.6-3.6,2.8-5.1,4.1C304.2,159,302.7,160.4,300.9,162.8z"
                />
              </g>
              <g>
                <path
                  d="M250.7,158.6c5.2-0.2,10.2-0.8,14.1-1.6c3.9-0.8,6.7-2,7-3.7c0.2-1.7-2.4-3.7-6.9-4.6c-4.4-1-10.5-0.7-16.1,1.4
				c-5.7,2.2-9.2,4.4-12.7,6.7c-3.4,2.3-6.7,4.7-11.2,8.5c5.1-2.9,9-4.2,12.8-5.1C241.5,159.3,245.3,158.8,250.7,158.6z"
                />
              </g>
              <g>
                <path
                  d="M452.7,61.6c-0.5-3.2-1.3-6.4-2.5-9.5c-1.2-3.1-2.9-6.2-5.1-8.9c-2.2-2.7-5-5-8.1-6.7c-3.1-1.7-6.7-2.7-10.3-2.8l-1.4,0
				l-1.4,0.1c-0.9,0.1-1.8,0.3-2.7,0.5c-1.8,0.5-3.4,1.1-4.9,2c-3,1.7-5.6,4.2-7.3,7.3c-1.7,3-2.4,6.8-1.9,10
				c0.2,1.6,0.7,3.2,1.3,4.6c0.6,1.4,1.4,2.7,2.2,3.8c3.4,4.5,8.5,7,13.1,7.2c2.3,0.1,4.6-0.3,6.5-1.3c1-0.5,1.8-1.1,2.5-1.8
				c0.7-0.7,1.2-1.5,1.7-2.3c1.5-3.2,1-6,0-7.5c-0.5-0.8-1-1.2-1.4-1.4c-0.4-0.2-0.7-0.2-0.9,0c-0.3,0.3-0.1,1.1-0.1,2.2
				c0,0.6-0.1,1.2-0.4,1.9c-0.3,0.7-0.7,1.5-1.3,2.1c-0.3,0.3-0.7,0.6-1,0.8c-0.3,0.2-0.8,0.4-1.3,0.5c-1,0.2-2.2,0.2-3.5-0.1
				c-2.6-0.6-5.4-2.4-7.1-5.2c-0.4-0.7-0.8-1.4-1-2.2c-0.2-0.8-0.4-1.5-0.4-2.3c-0.1-0.8,0-1.5,0.2-2.2c0.2-0.7,0.5-1.4,0.9-2.1
				c0.8-1.4,2.2-2.6,3.9-3.5c0.8-0.4,1.7-0.8,2.6-1c0.4-0.1,0.9-0.2,1.4-0.2l0.7,0l0.7,0c1.9,0.1,4,0.6,5.9,1.7
				c1.9,1,3.8,2.5,5.3,4.2c3.1,3.5,5.2,8.4,6.4,13.7c1.2,5.5,1.6,10.9,1.4,15.9c-0.2,5-0.9,9.7-1.9,14.1c-2.1,8.8-5.4,16.4-9.6,23.5
				c-4.2,7.1-9.5,13.8-16.3,20.4c-6.8,6.6-15.2,13.2-26.1,19.6c11.2-6,20-12.1,27.3-18.3c7.3-6.2,13.3-12.6,18.3-19.6
				c5-7,9.2-14.7,12.3-23.9c1.5-4.6,2.7-9.6,3.4-15.1C453.5,74.1,453.6,68.1,452.7,61.6z"
                />
              </g>
              <g>
                <path
                  d="M420.9,70.1c-1.7-0.9-3.7-1.5-5.7-1.6c-2.1-0.2-4.2,0.2-6.2,0.9c-1.9,0.7-3.7,1.8-5.2,3.1c-1.5,1.4-2.7,3-3.5,5
				c-0.8,1.9-1.2,4.1-1.1,6c0.1,2,0.6,3.8,1.4,5.5c1.6,3.3,4.3,5.8,7.4,6.9c1.6,0.6,3.2,0.8,4.6,0.6c1.4-0.1,2.7-0.5,3.8-0.9
				c2.2-1,3.7-2.4,4.6-3.8c0.9-1.4,1.2-2.7,1.1-3.6c-0.1-0.9-0.4-1.4-0.8-1.5c-0.9-0.2-1.9,0.9-3.8,1.6c-0.9,0.4-2,0.7-3.2,0.8
				c-1.2,0-2.4-0.2-3.2-0.9c-0.9-0.6-1.7-1.7-2.1-3c-0.2-0.6-0.3-1.3-0.3-2c0-0.6,0.2-1.2,0.4-1.8c0.5-1.2,1.7-2.4,3.2-3
				c0.7-0.3,1.5-0.5,2.2-0.6c0.8,0,1.7,0.1,2.6,0.3c1.9,0.6,3.8,1.9,5.4,3.4c0.8,0.8,1.5,1.6,2.2,2.4c0.7,0.8,1.3,1.7,1.9,2.6
				c2.3,3.5,3.9,7.1,5,10.9c1.1,3.8,1.7,7.8,1.5,12.4c0,0.6-0.1,1.1-0.1,1.7c0,0.6-0.1,1.2-0.2,1.8c-0.1,1.2-0.4,2.4-0.6,3.7
				c-0.6,2.5-1.4,5.2-2.7,8c1.5-2.7,2.7-5.3,3.5-7.8c0.4-1.3,0.8-2.5,1.1-3.7c0.1-0.6,0.3-1.2,0.4-1.8c0.1-0.6,0.2-1.2,0.3-1.8
				c0.8-4.7,0.9-9,0.5-13.3c-0.5-4.3-1.4-8.6-3.3-13.1c-0.5-1.1-1-2.3-1.7-3.4c-0.6-1.2-1.4-2.4-2.2-3.5
				C426.7,74.4,424.4,72,420.9,70.1z"
                />
              </g>
              <g>
                <g>
                  <path
                    d="M503.8,68.5c5.7-0.7,11.4-1.1,16.9-1.7c5.5-0.5,10.7-1.1,15.1-2c4.5-0.8,8.2-1.9,10.7-2.9c2.5-1.1,3.9-2.2,3.7-3
					c-0.2-0.9-1.8-1.4-4.4-1.8c-2.6-0.4-6.3-0.5-10.8-0.6c-4.4-0.1-9.6,0-15.3,0.4c-5.6,0.4-11.8,1.3-17.7,2.9
					c-6.2,1.7-11.2,3.7-15.6,6c-4.3,2.3-8,4.8-11.3,7.8c-0.8,0.7-1.6,1.5-2.4,2.3c-0.8,0.8-1.5,1.6-2.3,2.5
					c-1.5,1.7-2.9,3.6-4.2,5.6c-2.6,4.1-4.9,9.2-5.6,15.5c1.2-6.2,4.1-10.9,7-14.5c1.5-1.8,3.1-3.4,4.7-4.7c0.8-0.7,1.7-1.3,2.5-2
					c0.9-0.6,1.7-1.2,2.6-1.7c3.5-2.2,7.2-3.8,11.4-5.1C493.2,70.2,498,69.2,503.8,68.5z"
                  />
                </g>
                <g>
                  <path
                    d="M512,85.7c5.6-1.4,11.1-3.6,15.9-6.1c4.8-2.5,9.1-5.5,12.4-8.3c6.7-5.7,9.8-10.9,8.6-12.1c-1.2-1.3-6.2,1.4-13.3,5.2
					c-7.1,3.9-16.5,8.8-26.3,13c-5.1,2.2-9.5,3.7-13.6,4.9c-4.1,1.2-8,2.1-11.9,3.3c-3.9,1.1-7.8,2.4-11.9,4.4c-2,1-4.2,2.2-6.2,3.8
					c-2.1,1.5-4.1,3.5-5.8,5.9c1.9-2.2,4.1-3.9,6.3-5.2c2.2-1.3,4.3-2.2,6.4-2.9c4.2-1.4,8.1-1.9,12-2.3c3.9-0.4,7.8-0.5,12.3-1
					C501.2,87.9,506.2,87.1,512,85.7z"
                  />
                </g>
                <g>
                  <path
                    d="M519.6,76.1c6.3-1,12.2-3.4,16.2-6c4.1-2.6,6.3-5.3,5.6-6.9c-0.7-1.6-4.1-1.8-8.7-0.9c-4.5,0.9-10.2,2.7-15.7,5.4
					c-5.7,2.7-9.6,4.6-13.6,6.3c-4.1,1.7-8.3,3.3-14.6,4.9c6.6-0.6,11-0.9,15.5-1.2C508.7,77.4,513.2,77.1,519.6,76.1z"
                  />
                </g>
                <g>
                  <path
                    d="M517.7,72.2c4.9-0.5,9.4-2.3,12.5-4.4c3.1-2.1,4.8-4.4,4.1-6c-0.7-1.6-3.4-2.2-7-1.8c-3.6,0.4-8,1.7-12.2,3.8
					c-4.3,2.3-7.3,3.9-10.4,5.4c-3.1,1.5-6.2,2.9-11.1,4.4c5-0.4,8.5-0.6,12-0.7C509.2,72.8,512.7,72.7,517.7,72.2z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M490.4,213.7c-5.6-1.1-11.2-2.4-16.6-3.5c-5.4-1.2-10.5-2.2-15-2.7c-4.5-0.6-8.4-0.7-11.1-0.5c-2.7,0.3-4.3,0.9-4.5,1.8
					c-0.1,0.9,1.2,1.9,3.6,3c2.4,1.1,5.9,2.4,10.1,3.8c4.2,1.4,9.2,2.9,14.7,4.2c5.5,1.3,11.6,2.3,17.8,2.6c6.4,0.2,11.8-0.1,16.7-1
					c4.8-0.9,9.1-2.1,13.1-4c1-0.4,2-1,3-1.4c1-0.5,2-1.1,2.9-1.7c1.9-1.2,3.8-2.5,5.7-4.1c3.7-3.1,7.4-7.3,10-13.1
					c-3.1,5.6-7.2,9.1-11.1,11.7c-2,1.3-3.9,2.3-5.9,3.1c-1,0.4-2,0.8-2.9,1.1c-1,0.3-2,0.6-3,0.8c-3.9,1-8,1.4-12.4,1.4
					C501.1,215.3,496.2,214.8,490.4,213.7z"
                  />
                </g>
                <g>
                  <path
                    d="M487.9,194.8c-5.8-0.4-11.6,0.1-17,1c-5.4,1-10.3,2.5-14.3,4.2c-8.1,3.4-12.6,7.4-11.8,8.9c0.8,1.6,6.3,0.6,14.3-0.9
					c8-1.5,18.4-3.4,29-4.4c5.5-0.6,10.2-0.7,14.5-0.6c4.3,0.1,8.3,0.4,12.3,0.5c4,0.1,8.2,0.1,12.7-0.6c2.3-0.4,4.6-0.9,7.1-1.7
					c2.4-0.9,4.9-2.1,7.3-3.9c-2.5,1.6-5.1,2.5-7.5,3.1c-2.4,0.6-4.8,0.8-7,0.8c-4.4,0-8.3-0.6-12.1-1.4c-3.8-0.8-7.6-1.9-12-2.8
					C498.8,196,493.9,195.2,487.9,194.8z"
                  />
                </g>
                <g>
                  <path
                    d="M477.7,201.6c-6.3-0.9-12.6-0.4-17.3,0.8c-4.7,1.2-7.6,3.1-7.4,4.9c0.2,1.8,3.4,2.9,8,3.5c4.6,0.5,10.6,0.5,16.6-0.4
					c6.2-0.9,10.5-1.5,14.9-1.9c4.4-0.4,8.9-0.6,15.4-0.3c-6.4-1.4-10.8-2.5-15.2-3.5C488.5,203.7,484.2,202.6,477.7,201.6z"
                  />
                </g>
                <g>
                  <path
                    d="M478.3,205.9c-4.8-1-9.7-0.7-13.3,0.4c-3.6,1-5.9,2.8-5.8,4.5c0.1,1.8,2.6,3.1,6.1,3.9c3.5,0.7,8.1,0.8,12.8,0
					c4.8-0.8,8.1-1.5,11.5-2c3.4-0.5,6.8-0.9,11.9-0.8c-4.9-1.1-8.3-2-11.6-3C486.6,207.9,483.3,206.9,478.3,205.9z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M295.7,319.1c4.1-3.9,8.4-7.8,12.5-11.5c4.1-3.7,7.9-7.3,10.9-10.7c3.1-3.3,5.4-6.4,6.8-8.8c1.4-2.4,1.8-4.1,1.2-4.7
					c-0.6-0.6-2.3-0.1-4.6,1.2c-2.3,1.3-5.4,3.4-9,6c-3.6,2.6-7.7,5.8-12,9.5c-4.2,3.7-8.6,8.1-12.4,13c-3.9,5.1-6.7,9.7-8.9,14.2
					c-2.1,4.4-3.5,8.7-4.4,13c-0.2,1.1-0.4,2.2-0.6,3.3c-0.1,1.1-0.3,2.2-0.3,3.3c-0.2,2.3-0.2,4.6,0,7c0.4,4.8,1.6,10.3,4.8,15.8
					c-2.8-5.7-3.3-11.1-3.1-15.8c0.1-2.3,0.4-4.5,0.9-6.6c0.2-1,0.5-2,0.8-3c0.3-1,0.6-2,1-2.9c1.5-3.8,3.5-7.3,6.1-11
					C288.2,326.9,291.4,323.2,295.7,319.1z"
                  />
                </g>
                <g>
                  <path
                    d="M312.5,328c3.7-4.5,6.7-9.5,9-14.5c2.3-4.9,4-9.8,4.9-14.1c1.9-8.6,1.3-14.5-0.4-14.8c-1.8-0.3-4.2,4.8-7.5,12.2
					c-3.4,7.4-7.9,16.9-13.3,26.2c-2.7,4.8-5.4,8.7-7.9,12.1c-2.6,3.4-5.1,6.5-7.5,9.7c-2.4,3.2-4.8,6.6-6.9,10.7
					c-1,2.1-2,4.3-2.7,6.8c-0.7,2.5-1.2,5.2-1.1,8.2c0.2-3,0.9-5.6,1.9-7.9c1-2.3,2.2-4.3,3.4-6.1c2.5-3.6,5.3-6.4,8.2-9
					c2.9-2.7,5.9-5.1,9.3-8.2C305.2,336.2,308.7,332.7,312.5,328z"
                  />
                </g>
                <g>
                  <path
                    d="M312.9,315.8c4.4-4.6,7.7-10,9.4-14.5c1.7-4.5,1.8-8,0.3-8.8c-1.6-0.9-4.4,1.1-7.5,4.5c-3.1,3.4-6.5,8.3-9.3,13.8
					c-2.9,5.6-4.9,9.4-7.1,13.3c-2.2,3.8-4.6,7.6-8.7,12.7c4.9-4.4,8.3-7.4,11.7-10.3C305,323.4,308.3,320.5,312.9,315.8z"
                  />
                </g>
                <g>
                  <path
                    d="M309,313.8c3.6-3.4,6.2-7.5,7.4-11c1.2-3.5,1.2-6.4-0.3-7.3c-1.5-0.9-4,0.3-6.7,2.7c-2.6,2.5-5.4,6.1-7.4,10.4
					c-2.1,4.4-3.5,7.5-5,10.5c-1.6,3-3.3,6.1-6.2,10.2c3.8-3.4,6.5-5.6,9.2-7.8C302.6,319.3,305.3,317.2,309,313.8z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M112.7,104.3c4.4,3.7,8.6,7.6,12.7,11.2c4.1,3.6,8.1,7,11.7,9.8c3.6,2.7,6.9,4.7,9.5,5.9c2.5,1.1,4.2,1.4,4.7,0.7
					c0.5-0.7-0.2-2.2-1.7-4.4c-1.5-2.2-3.9-5-6.9-8.3c-3-3.3-6.6-7.1-10.7-10.9c-4.2-3.8-8.9-7.7-14.2-11c-5.4-3.3-10.4-5.7-15-7.3
					c-4.6-1.6-9-2.6-13.4-3c-1.1-0.1-2.2-0.1-3.3-0.2c-1.1,0-2.2,0-3.4,0c-2.3,0.1-4.6,0.3-7,0.8c-4.8,0.9-10.1,2.7-15.2,6.4
					c5.4-3.3,10.7-4.4,15.4-4.7c2.3-0.1,4.6,0,6.7,0.2c1.1,0.1,2.1,0.3,3.1,0.5c1,0.2,2,0.4,3,0.7c3.9,1.1,7.6,2.7,11.5,4.9
					C104.2,97.6,108.2,100.5,112.7,104.3z"
                  />
                </g>
                <g>
                  <path
                    d="M105.7,121.9c4.9,3.2,10.2,5.7,15.3,7.5c5.2,1.8,10.2,2.9,14.5,3.4c8.7,1,14.6-0.3,14.7-2c0.1-1.8-5.2-3.6-12.9-6.2
					c-7.7-2.6-17.7-6.1-27.5-10.4c-5.1-2.2-9.2-4.4-12.9-6.6c-3.7-2.2-7-4.4-10.5-6.5C83,99,79.4,97,75.1,95.4c-2.1-0.8-4.5-1.5-7-2
					c-2.5-0.5-5.3-0.6-8.3-0.2c3-0.1,5.7,0.3,8.1,1c2.4,0.7,4.5,1.7,6.5,2.7c3.9,2.1,6.9,4.6,9.8,7.2c2.9,2.6,5.7,5.4,9.1,8.4
					C96.7,115.5,100.6,118.7,105.7,121.9z"
                  />
                </g>
                <g>
                  <path
                    d="M117.9,121c5,3.9,10.8,6.6,15.4,7.8c4.7,1.2,8.1,1,8.8-0.6c0.7-1.6-1.5-4.2-5.3-7c-3.7-2.7-9-5.6-14.7-7.8
					c-5.8-2.3-9.9-3.9-13.9-5.7c-4-1.8-8-3.8-13.6-7.4c4.9,4.4,8.2,7.5,11.5,10.5C109.5,113.9,112.7,117,117.9,121z"
                  />
                </g>
                <g>
                  <path
                    d="M119.5,117c3.7,3.2,8.1,5.3,11.7,6.2c3.6,0.9,6.5,0.5,7.2-1.1c0.8-1.6-0.7-4-3.4-6.4c-2.7-2.4-6.7-4.7-11.1-6.3
					c-4.6-1.6-7.8-2.7-11-3.9c-3.2-1.2-6.4-2.6-10.8-5.1c3.8,3.4,6.2,5.8,8.7,8.3C113.2,111.2,115.6,113.7,119.5,117z"
                  />
                </g>
              </g>
              <g>
                <path
                  d="M46.9,120.2c-4.8-3.3-10.4-5.5-16.3-6.1c-2.9-0.3-5.9-0.2-8.8,0.3c-2.9,0.5-5.8,1.4-8.4,2.9c-2.6,1.5-5.1,3.5-6.9,6.1
				c-0.5,0.7-0.9,1.3-1.2,2c-0.3,0.7-0.7,1.5-0.9,2.2L4,128.7l-0.2,1.1c-0.2,0.8-0.2,1.5-0.3,2.3c-0.1,3,0.5,5.8,1.6,8.3
				c1.1,2.5,2.6,4.6,4.5,6.3c1.8,1.7,4,3,6.2,3.6c2.1,0.7,4.2,1,6.1,0.9c1.9-0.1,3.6-0.4,5.2-0.9c1.5-0.5,2.8-1.1,3.9-1.8
				c2.2-1.3,3.6-2.9,4.3-4c0.7-1.2,0.8-2,0.5-2.2c-0.3-0.3-1.1-0.1-2.2,0.3c-1.1,0.4-2.6,1.1-4.6,1.5c-1,0.2-2,0.4-3.2,0.5
				c-1.1,0.1-2.4,0-3.6-0.2c-1.2-0.2-2.5-0.6-3.6-1.2c-1.2-0.6-2.1-1.4-3-2.4c-1.8-2.1-2.9-5.2-2.6-8.1c0.1-0.3,0.1-0.7,0.2-1
				l0.1-0.5l0.2-0.5c0.1-0.3,0.3-0.6,0.4-0.9c0.2-0.3,0.4-0.6,0.6-0.9c0.9-1.2,2.2-2.2,3.7-3.1c1.6-0.9,3.4-1.5,5.4-1.8
				c2-0.4,4.1-0.5,6.2-0.3c4.2,0.3,8.6,1.6,12.4,3.9c4,2.3,7.2,5.4,9.7,8.7c2.5,3.3,4.3,6.9,5.7,10.4c2.6,7.1,3.3,14.2,2.9,21.3
				c-0.4,7.1-1.8,14.3-4.7,22c-3,7.6-7.4,15.8-14.9,23.8c7.8-7.7,12.8-15.6,16.5-23.2c3.6-7.6,5.7-14.8,6.9-22.3
				c0.6-3.7,0.9-7.5,0.8-11.4c-0.1-3.9-0.5-8-1.6-12.1c-1.1-4.2-2.8-8.5-5.4-12.7C55.4,127.8,51.9,123.6,46.9,120.2z"
                />
              </g>
              <g>
                <path
                  d="M34,153.7c-3,0.6-5.5,2.1-7.5,4c-2,1.9-3.5,4.3-4.3,7c-0.4,1.3-0.6,2.7-0.6,4.1c0,1.4,0.2,2.8,0.7,4.1
				c0.8,2.5,2.4,4.7,4.4,6.1c1,0.7,2,1.2,3.1,1.5c1.1,0.3,2.1,0.4,3,0.4c1.9-0.1,3.4-0.7,4.4-1.5c1-0.8,1.6-1.6,1.8-2.3
				c0.2-0.7,0-1.2-0.3-1.5c-0.7-0.6-1.9-0.4-3.2-0.7c-0.7-0.1-1.4-0.4-2-0.7c-0.3-0.2-0.6-0.4-0.8-0.6c-0.2-0.2-0.4-0.5-0.6-0.8
				c-0.4-0.6-0.7-1.4-0.8-2.3c-0.1-0.4-0.1-0.9,0-1.3c0-0.5,0.2-1,0.3-1.5c0.6-2.1,2.6-4.5,4.7-5.5c1.1-0.5,2.1-0.7,3.3-0.5
				c1.2,0.2,2.3,0.8,3.3,1.6c1,0.8,1.8,1.7,2.5,2.8c0.7,1.1,1.2,2.3,1.6,3.6c0.8,2.6,1,5.5,0.9,8.8c-0.1,3.3-0.7,7-0.7,11.4
				c0.6-4.4,1.7-7.9,2.4-11.2c0.8-3.3,1.3-6.4,1.2-9.6c-0.1-1.6-0.3-3.3-0.8-4.9c-0.5-1.7-1.3-3.4-2.5-5c-1.2-1.6-2.8-3.2-5.1-4.3
				c-1.1-0.6-2.4-1-3.8-1.2C37.1,153.3,35.5,153.4,34,153.7z"
                />
              </g>
              <g>
                <path
                  d="M80.9,129c2-0.8,3.3-2.1,4-3.7c0.7-1.6,0.9-3.4,0.1-5c-0.8-1.5-2.7-2.3-4.8-1.9c-2.1,0.4-4.4,2-5.6,4.5
				c-1.2,2.6-1.6,4.5-1.9,6.4c-0.3,1.9-0.7,3.6-1.1,6.1c1.5-2,2.9-3.1,4.3-4C77.3,130.6,78.8,129.9,80.9,129z"
                />
              </g>
              <g>
                <path
                  d="M87.6,140.1c1.9,0,3.2-0.8,4.1-2.1c0.9-1.2,1.4-2.9,1-4.7c-0.4-1.7-1.8-3-3.7-3.3c-1.9-0.3-4.2,0.4-5.7,2.3
				c-1.5,2-2.3,3.5-3,5c-0.7,1.5-1.3,2.9-2.3,4.8c1.8-1.2,3.2-1.6,4.7-1.9C84.1,140.2,85.6,140.1,87.6,140.1z"
                />
              </g>
              <g>
                <path
                  d="M69.7,113.9c0.7-3.1,0.1-6.2-1.2-8.3c-1.3-2.1-3.2-3.1-4.9-2.7c-1.7,0.5-2.8,2.2-3.3,4.3c-0.5,2.1-0.3,4.6,0.7,7.2
				c1.1,2.6,1.8,4.6,2.5,6.6c0.6,2,1,4.1,1,7.1c1.1-2.9,1.9-4.9,2.8-6.9C68.1,119.2,69,117.1,69.7,113.9z"
                />
              </g>
              <g>
                <path d="M51.6,99.8c-0.9-5.8-6-9.3-9.1-7.5c-3,1.8-2.7,7.7,1.3,11.5c4.1,4,6.3,5.3,9.7,10.1C52.1,108.1,52.6,105.7,51.6,99.8z" />
              </g>
              <g>
                <path
                  d="M25.9,96.2c-2.9-2.2-6.5-3.1-9.2-2.9c-2.8,0.2-4.8,1.4-5.1,3.1c-0.3,1.7,0.9,3.6,3,5c2,1.5,4.9,2.6,8.1,2.9
				c3.3,0.4,5.7,0.6,8.1,1.1c2.4,0.5,4.7,1.1,7.9,2.7c-2.6-2.5-4.3-4.2-6.1-6C30.7,100.2,28.9,98.4,25.9,96.2z"
                />
              </g>
              <g>
                <path
                  d="M-1.6,103.7c-2.7-0.3-5.2,0.9-6.5,2.5c-1.4,1.7-1.5,3.6-0.6,5.1c1,1.5,2.6,2.2,4.2,2.4c1.6,0.2,3.2-0.2,4.8-1.5
				c1.6-1.3,3-2.3,4.4-3c1.4-0.8,3-1.3,5.4-1.4c-2.1-0.9-3.7-1.6-5.5-2.4C3,104.7,1.2,104-1.6,103.7z"
                />
              </g>
              <g>
                <path
                  d="M-13.1,124.3c-2.5,1.7-3.7,4.6-3.5,6.9c0.2,2.4,1.6,3.9,3.3,4.1c1.8,0.2,3.4-0.6,4.6-1.5c1.3-1,2.1-2.2,2.5-4.1
				c0.4-2,0.9-3.5,1.7-5c0.8-1.4,1.9-2.8,4.1-4c-2.5,0.2-4.3,0.5-6.2,0.9C-8.6,122.1-10.6,122.6-13.1,124.3z"
                />
              </g>
              <g>
                <path
                  d="M-9.6,149.9c-0.9,2.6-0.3,5.4,1.1,7.1c1.4,1.7,3.3,2.2,4.9,1.5c1.6-0.7,2.7-2.2,3.2-3.7c0.5-1.5,0.5-3.1-0.4-4.8
				c-0.9-1.8-1.5-3.3-1.9-4.9c-0.3-1.6-0.5-3.2,0.1-5.5c-1.6,1.8-2.7,3.2-3.8,4.6C-7.5,145.6-8.6,147.2-9.6,149.9z"
                />
              </g>
              <g>
                <path
                  d="M3.1,168.8c0.4,2.9,2.4,5.2,4.4,6.2c2.1,1,4.1,0.7,5.2-0.6c1.2-1.3,1.4-3.2,1.1-4.9c-0.3-1.7-1.2-3.3-2.9-4.7
				c-1.7-1.4-3-2.6-4.1-3.9c-1.1-1.3-2-2.9-2.7-5.4c-0.4,2.6-0.7,4.4-1,6.4C2.9,163.8,2.7,165.8,3.1,168.8z"
                />
              </g>
              <g>
                <path
                  d="M75.4,229.7c2.3,1.4,4,3.2,4.8,5.2c0.9,2,0.9,4.3,0.3,5.8c-0.6,1.5-2,2.8-3.6,3.4c-0.8,0.3-1.5,0.4-2.3,0.4
				c-0.8,0-1.6-0.2-2.3-0.6c-1.5-0.6-2.7-1.8-3.4-2.9c-0.7-1.1-0.9-2.1-0.8-2.9c0.1-0.8,0.6-1.6,1.1-2c0.4-0.5,0.9-0.8,1-1.2
				c0.1-0.4-0.3-1-1.5-1.3c-1.1-0.2-3,0.1-4.7,1.8c-0.4,0.4-0.8,1-1.1,1.6c-0.3,0.6-0.5,1.2-0.7,1.9c-0.3,1.4-0.3,2.8,0.1,4.3
				c0.7,2.9,2.5,5.8,5.5,7.9c1.5,1,3.3,1.8,5.3,2.3c2,0.4,4.2,0.4,6.3-0.2c2.1-0.5,4.1-1.5,5.9-3c1.8-1.4,3.3-3.4,4.2-5.7
				c0.9-2.3,1.3-4.7,1.1-7c-0.1-2.3-0.8-4.6-1.8-6.7c-1-2.1-2.5-4-4.2-5.5c-1.7-1.5-3.6-2.6-5.6-3.4c-4.1-1.7-8.1-2-11.5-1.8
				c-3.5,0.2-6.5,0.9-9.3,1.7c-5.5,1.7-10.2,3.7-14.6,6.1c-4.5,2.5-8.8,5.2-13,9c-2.1,1.9-4.3,4-6.3,6.4c-2.1,2.5-4.1,5.2-5.8,8.5
				c2-3.1,4.2-5.7,6.4-7.9c2.2-2.2,4.5-4.1,6.8-5.6c4.5-3.2,9-5.2,13.5-6.9c4.5-1.7,9.2-2.8,14.4-3.6c2.6-0.3,5.2-0.5,7.9-0.3
				c1.3,0.1,2.7,0.3,4,0.7C72.9,228.5,74.2,229,75.4,229.7z"
                />
              </g>
              <g>
                <path
                  d="M55.5,215c4.5-2.5,8.1-6.3,10.1-9.7c2-3.4,2.4-6.3,1-7.5c-1.4-1.1-4.1-0.2-7.1,1.7c-3.1,2-6.5,5-9.6,8.6
				c-3.2,3.8-5.4,6.3-7.9,8.8c-2.5,2.5-5.2,4.8-9.6,7.6c4.8-1.8,8.2-3.1,11.7-4.4C47.5,218.9,50.9,217.5,55.5,215z"
                />
              </g>
              <g>
                <path
                  d="M75.4,216.2c2.3,0.5,4.3,0.1,5.9-0.8c1.6-1,2.7-2.5,2.7-4.3c0-1.8-1.2-3.3-3.1-4.1c-1.9-0.8-4.5-0.8-6.8,0.5
				c-2.4,1.3-3.8,2.5-5.3,3.6c-1.5,1.1-2.9,2.2-5.1,3.5c2.5-0.3,4.3-0.1,6,0.2C71.3,215.2,73,215.7,75.4,216.2z"
                />
              </g>
              <g>
                <path
                  d="M35.3,208.6c0.9-2.4,1.3-5,1.3-7.4c0-0.6,0-1.2-0.1-1.8c-0.1-0.6-0.1-1.1-0.2-1.7c-0.2-1.2-0.6-2.3-1.1-3.4
				c-0.5-1.1-1.1-2-1.9-2.8c-0.7-0.8-1.6-1.3-2.3-1.6c-1.6-0.5-2.6,0-3,0.7c-0.4,0.8-0.3,1.8-0.5,2.6c-0.1,0.9-0.3,1.8-0.4,3
				c-0.1,0.7-0.1,1.4-0.2,2.1c0,0.4-0.1,0.9-0.1,1.3c0,0.5,0,0.9-0.1,1.4c-0.1,1.9-0.1,3.9,0,6c0.1,4.3-0.2,7.5-0.8,10.6
				c-0.7,3.1-1.8,6.3-4.7,10.3c3.7-3.3,5.9-5.9,8.1-8.7c1.1-1.4,2.1-2.8,3.1-4.5C33.4,213,34.4,211.1,35.3,208.6z"
                />
              </g>
              <g>
                <path
                  d="M124.7,53.8c3.3-0.9,6.7-1.7,9-1.4c1.2,0.1,2,0.4,2.7,0.9c0.6,0.4,1.2,1.1,1.6,2c0.9,1.8,1,4.3,0.5,6.2
				c-0.1,0.5-0.3,1-0.5,1.5c-0.2,0.5-0.5,0.9-0.7,1.4c-0.5,0.8-1.2,1.5-1.8,2.1c-1.2,1-2.9,1.5-4.2,1.4c-0.7,0-1.3-0.2-1.8-0.4
				c-0.5-0.3-1-0.6-1.4-1c-1.7-1.6-1.8-3.6-2.6-3.9c-0.4-0.1-1,0.2-1.5,1.2c-0.5,1-0.8,2.8-0.1,4.9c0.3,1,0.9,2.2,1.9,3.2
				c1,1.1,2.2,2,3.8,2.6c3,1.2,6.9,1.2,10.4-0.7c1.7-0.9,3.3-2.2,4.6-3.7c0.7-0.8,1.2-1.6,1.8-2.5c0.5-0.9,1-1.8,1.4-2.9
				c0.8-2.1,1.1-4.3,1.1-6.5c-0.1-2.3-0.5-4.6-1.5-6.9c-1.1-2.3-2.8-4.5-5.2-6c-2.4-1.5-5.1-2.2-7.6-2.3c-2.5-0.1-4.7,0.3-6.8,0.9
				c-2.1,0.6-3.9,1.2-5.7,2c-3.8,1.6-7.6,3.8-10.3,7c-1.3,1.6-2.3,3.5-2.9,5.3c-0.6,1.8-0.9,3.6-0.9,5.3c-0.1,3.4,0.5,6.4,1.3,9.2
				c0.9,2.8,2,5.4,3.4,7.8c2.8,4.8,6.6,9.1,11.7,12.4c2.6,1.7,5.5,3.1,8.7,4.2c3.3,1.1,6.9,1.7,10.9,1.8c-3.9-0.3-7.5-1.3-10.6-2.6
				c-3.1-1.3-5.7-3-8-4.8c-4.5-3.7-7.5-8.1-9.4-12.7c-1-2.3-1.6-4.7-2-7.2c-0.4-2.5-0.5-5,0-7.5c0.2-1.2,0.6-2.4,1.1-3.4
				c0.5-1,1.2-2,2.1-2.8C118.7,56,121.3,54.8,124.7,53.8z"
                />
              </g>
              <g>
                <path
                  d="M86,72c-3-1.1-6.3-0.6-8.4,0.7c-2.1,1.3-3.1,3.2-2.6,4.9c0.5,1.7,2.2,2.8,4,3.3c1.9,0.6,4,0.6,6.4-0.2
				c2.5-0.8,4.3-1.2,6.3-1.4c1.9-0.2,4-0.2,6.8,0.7c-2.3-1.8-4-3.1-5.7-4.3C90.9,74.4,89.1,73.1,86,72z"
                />
              </g>
              <g>
                <path
                  d="M99.5,62.1c0.8-1.3,0.8-2.3,0.3-3.6c-0.5-1.2-1.3-2.8-2.7-3.8c-0.7-0.5-1.6-0.7-2.5-0.6c-0.9,0.1-1.8,0.5-2.6,1.3
				c-0.8,0.8-1.4,1.9-1.7,3.2c-0.3,1.3-0.2,2.8,0.4,4.1c1.2,2.8,2.7,4.1,4.1,5.3c1.4,1.1,2.9,2,4.9,2.9c-1.3-1.8-1.6-3.3-1.5-4.7
				C98.2,64.8,98.7,63.5,99.5,62.1z"
                />
              </g>
              <g>
                <path
                  d="M152.6,18.3c3.1-2.4,6-4.9,8.2-6.9c2.2-2,3.6-3.6,3.1-4.4c-0.4-0.8-2.6-0.5-5.5,1c-2.8,1.4-6.3,4-8.9,7.3
				c-2.7,3.4-4.2,5.9-5.6,8.5c-1.4,2.6-2.7,5.2-4.4,9.2c2.2-3.7,4-6,6-8.1C147.4,22.8,149.4,20.8,152.6,18.3z"
                />
              </g>
              <g>
                <path
                  d="M161.5,23.3c3.2-1.8,6.3-3.8,8.5-5.5c2.2-1.7,3.6-3.2,3.2-4c-0.4-0.8-2.5-0.7-5.2,0.3c-2.8,1-6.2,3-9.1,5.6
				c-2.9,2.7-4.7,4.8-6.5,6.9c-1.7,2.1-3.5,4.3-5.9,7.5c2.8-2.9,5-4.6,7.2-6.1C155.9,26.6,158.1,25.2,161.5,23.3z"
                />
              </g>
              <g>
                <path
                  d="M160,33.4c2.1-0.6,3.9-1.6,5.2-2.7c1.3-1,2-2.1,1.7-3c-0.3-0.8-1.6-1.2-3.4-0.9c-1.7,0.3-3.9,1.2-5.7,2.7
				c-1.8,1.6-2.9,2.8-4,4c-1.1,1.2-2.2,2.4-3.9,4.1c2-1.3,3.5-1.9,4.9-2.5C156.3,34.6,157.8,34.1,160,33.4z"
                />
              </g>
              <g>
                <path
                  d="M207.1,151.4c1.6-3.8,3.4-7.5,4.8-10.2c1.4-2.8,2.4-4.8,1.8-5.4c-0.6-0.6-2.8,0.4-5.2,3c-2.4,2.6-4.7,6.8-5.6,11.4
				c-0.8,4.7-0.7,8-0.3,11.2c0.4,3.2,1,6.4,2.6,10.8c-1.1-4.5-1.1-7.7-0.7-10.8C204.8,158.3,205.5,155.3,207.1,151.4z"
                />
              </g>
              <g>
                <path
                  d="M186.8,193.3c-0.7,3.1-1.2,5.4-1.8,8c-0.5,2.2-0.7,3.9,0,4.4c0.7,0.5,2.3-0.5,3.8-2.8c1.4-2.2,2.6-5.8,2.3-9.3
				c-0.2-3.6-1-6-1.8-8.2c-0.9-2.2-1.9-4.4-3.8-7.3c1.5,3.1,1.8,5.5,2,7.7C187.6,188,187.5,190.3,186.8,193.3z"
                />
              </g>
              <g>
                <path
                  d="M166,263.2c-0.7,3.5-2,6.9-3.1,9.4c-1.1,2.5-2,4.4-1.3,5c0.6,0.6,2.7-0.2,4.9-2.8c1.1-1.3,2.1-2.9,2.8-4.9
				c0.7-1.9,1.2-4.2,1.1-6.4c0-2.3-0.4-4.1-0.9-5.8c-0.5-1.6-1-3-1.7-4.3c-1.4-2.6-3-5.2-6.2-8.1c2.8,3.3,3.8,6,4.4,8.7
				c0.3,1.3,0.4,2.7,0.5,4.2C166.5,259.8,166.4,261.4,166,263.2z"
                />
              </g>
              <g>
                <path
                  d="M249.5,317.1c1.2-0.7,2.4-1.2,3.5-1.4c0.6-0.1,1.1-0.1,1.6-0.1c0.5,0,1,0.2,1.5,0.3c2,0.7,4,2.6,5.2,4.8
				c0.3,0.5,0.5,1.1,0.8,1.7c0.2,0.6,0.4,1.2,0.5,1.8c0.2,1.2,0.3,2.3,0.2,3.3c-0.2,1.9-1.3,3.4-2.5,4.1c-0.1,0.1-0.3,0.2-0.4,0.2
				c-0.1,0.1-0.3,0.1-0.4,0.2c-0.3,0.1-0.6,0.1-0.9,0.2c-0.7,0-1.3-0.1-1.8-0.4c-0.5-0.2-0.9-0.6-1.2-0.9c-0.3-0.4-0.5-0.9-0.5-1.4
				c-0.1-1.1,0.1-2,0.4-2.8c0.3-0.7,0.6-1.3,0.4-1.7c-0.1-0.4-0.8-0.7-2-0.3c-1.1,0.4-2.7,1.6-3.5,3.8c-0.4,1.1-0.6,2.5-0.3,4.1
				c0.3,1.6,1.1,3.2,2.4,4.5c1.3,1.3,3,2.2,4.9,2.7c1,0.2,2,0.3,3.1,0.3c0.5,0,1.1-0.1,1.7-0.2c0.5-0.1,1.1-0.3,1.6-0.4
				c4.2-1.5,7.4-5.3,8.5-10c0.5-2.3,0.6-4.6,0.2-6.8c-0.2-1.1-0.4-2.2-0.8-3.3c-0.4-1.1-0.8-2.2-1.4-3.2c-1.1-2.1-2.5-4-4.4-5.6
				c-1.8-1.6-4.1-3-6.7-3.8c-2.6-0.8-5.5-0.8-8.1-0.1c-2.6,0.7-4.8,2-6.7,3.5c-3.8,3.3-5.9,7.3-7.1,11c-1.1,3.8-1.4,7.4-1.1,10.7
				c0.2,3.3,1,6.4,1.9,9.1c1,2.8,2.1,5.4,3.5,7.8c1.3,2.5,2.7,4.9,4.3,7.3c1.5,2.4,3.1,4.8,4.9,7.4c3.6,5,7.8,10.5,13.5,16.3
				c-5.3-6.2-9-11.9-12.1-17.2c-1.5-2.7-2.8-5.2-4-7.8c-1.2-2.5-2.3-5-3.2-7.5c-0.9-2.5-1.7-5-2.2-7.6c-0.5-2.6-0.7-5.2-0.6-7.9
				c0.2-2.7,0.8-5.5,2-8.1c0.6-1.3,1.3-2.5,2.2-3.7C247.3,318.8,248.3,317.8,249.5,317.1z"
                />
              </g>
              <g>
                <path
                  d="M264.6,361c0.7-2.6,1-4.8,0.9-6.9c-0.1-2.1-0.6-4.1-2-5.2c-0.7-0.5-1.7-0.6-2.7-0.3c-1,0.3-2.1,1.1-3.1,2.3
				c-1,1.2-1.7,2.8-2.1,4.7c-0.4,1.9-0.3,3.9,0.3,5.9c0.6,2,1.4,3.6,2.2,4.9c0.8,1.3,1.7,2.3,2.6,3.3c1.8,1.9,3.7,3.6,6.7,5.5
				c-2.3-2.7-3-5-3.3-7.2C263.8,365.9,263.9,363.7,264.6,361z"
                />
              </g>
              <g>
                <path
                  d="M284.7,370c-3.2,6-5.2,12.1-7.6,17c-1.2,2.5-2.4,4.7-3.7,6.6c-0.7,0.9-1.4,1.8-2.1,2.6c-0.7,0.8-1.4,1.4-2.1,2
				c-1.4,1.1-2.5,1.5-3.5,1.7c-0.3,0.1-0.8,0-1.2-0.1c-0.5-0.2-0.9-0.4-1.2-0.7c-0.7-0.5-1.1-1.1-1.5-1.7c-0.3-0.6-0.5-1.1-0.9-1.3
				c-0.2-0.1-0.4-0.1-0.7,0c-0.3,0.1-0.6,0.4-0.9,0.8c-0.6,0.9-1,2.7-0.3,4.6c0.3,1,0.9,1.9,1.8,2.8c0.9,0.9,2.1,1.8,3.7,2.2
				c1.4,0.4,3,0.5,4.6,0.3c1.6-0.2,3.1-0.7,4.5-1.4c1.4-0.7,2.7-1.6,3.9-2.6c1.2-1,2.3-2.1,3.4-3.3c2.1-2.4,3.9-5.2,5.3-8.2
				c2.9-5.9,4.6-12.1,6.6-17.7c1.1-2.9,2.2-5.3,3.6-7.5c1.4-2.2,2.8-4.2,4.4-5.9c0.7-0.9,1.6-1.7,2.4-2.5c0.9-0.7,1.7-1.5,2.5-2.2
				c0.4-0.3,0.9-0.7,1.3-1c0.4-0.3,0.9-0.6,1.3-0.9c0.9-0.6,1.8-1.2,2.8-1.7c3.8-2.2,8-3.8,13-4.7c1.2-0.2,2.5-0.4,3.9-0.4
				c1.3-0.1,2.7-0.1,4.1,0.1c2.8,0.3,5.9,1.2,8.4,3.6c-2.3-2.6-5.4-3.8-8.3-4.4c-1.5-0.3-2.9-0.5-4.3-0.6c-1.4-0.1-2.7,0-4.1,0
				c-5.2,0.3-9.9,1.3-14.4,3c-1.1,0.4-2.2,0.9-3.3,1.4c-0.5,0.3-1.1,0.5-1.6,0.8c-0.5,0.3-1.1,0.6-1.6,0.9c-1.1,0.6-2.1,1.3-3.2,2
				c-1,0.8-2.1,1.5-3.2,2.3c-2.1,1.7-4.2,3.7-6.2,6C288.2,364.1,286.3,366.9,284.7,370z"
                />
              </g>
              <g>
                <path
                  d="M311.3,353.5c-2.3,1-4.3,2.4-5.9,4.2c-1.6,1.7-2.9,3.8-3.4,5.9c-0.6,2.1-0.3,4.2,0.4,5.5c0.7,1.3,1.7,1.7,2.5,1.5
				c0.9-0.2,1.6-0.8,2.3-1.4c0.7-0.6,1.5-1.2,2.3-1.9c0.9-0.7,1.8-1.6,3-2.7c1.2-1.1,2.4-2.4,3.6-3.7c2.6-2.8,4.9-4.5,7.5-5.8
				c1.3-0.6,2.7-1.3,4.3-1.7c1.6-0.4,3.5-0.7,5.7-0.7c-2.2-0.6-4.1-0.9-5.9-1.1c-1.8-0.2-3.4-0.3-5-0.3c-1.6,0-3.3,0.1-5.1,0.4
				C315.7,352,313.7,352.5,311.3,353.5z"
                />
              </g>
              <g>
                <path
                  d="M333.5,332.9c0.1-2-0.4-3.5-1.3-5c-0.9-1.4-2.2-2.8-3.9-3.1c-0.9-0.2-1.7,0-2.5,0.5c-0.8,0.5-1.5,1.4-1.9,2.6
				c-0.4,1.2-0.6,2.6-0.4,4.2c0.3,1.5,0.9,3.1,2,4.3c2.2,2.6,4.3,3.7,6.2,4.6c1.9,0.9,3.9,1.5,6.5,2.1c-2.2-1.6-3.2-3.2-3.8-4.8
				C333.7,336.7,333.4,335,333.5,332.9z"
                />
              </g>
              <g>
                <path
                  d="M523.9,354.8c0.8-4,0.7-8.3-0.4-12.2c-1-3.9-2.9-7.6-5.4-10.8c-2.5-3.2-5.7-5.8-9.2-7.6c-3.5-1.8-7.3-3-11-3.4
				c-3.8-0.4-7.6-0.2-11.2,0.8c-3.6,0.9-7.2,2.6-10.1,5.5c-1.4,1.4-2.6,3.2-3.4,5c-0.8,1.8-1.3,3.7-1.4,5.5
				c-0.3,3.7,0.5,7.3,2.3,10.3c1.8,3,4.4,5.2,7.1,6.5c2.7,1.3,5.5,1.8,8.1,1.8c2.6-0.1,5-0.8,7-1.8c2-1,3.5-2.4,4.6-3.7
				c2.3-2.7,3.1-5.3,3.4-7.1c0.2-1.8-0.1-2.8-0.5-2.8c-0.5-0.1-1,0.7-1.9,1.9c-0.9,1.2-2.2,3-4.3,4.5c-1,0.7-2.3,1.4-3.7,1.8
				c-1.4,0.4-2.9,0.6-4.5,0.4c-1.6-0.2-3.2-0.7-4.7-1.7c-1.5-0.9-2.7-2.2-3.4-3.7c-0.7-1.5-1-3.5-0.7-5.3c0.1-0.9,0.4-1.8,0.8-2.6
				c0.4-0.8,0.9-1.4,1.6-2.1c2.8-2.6,8.4-3.9,13.5-3.2c5.3,0.6,10.4,3.1,13.9,7.3c3.5,4.2,5.4,9.9,4.7,15.4
				c-0.8,5.7-4.1,10.7-8.1,14.1c-4.1,3.4-9,5.5-13.8,6.4c-4.9,1-9.8,1-14.5,0.4c-4.7-0.6-9.3-1.9-13.8-3.5
				c-4.5-1.6-8.9-3.6-13.5-5.8c-4.5-2.2-9.2-4.6-14.3-6.8c-5.1-2.2-10.8-4.1-17.1-4.8c-3.1-0.3-6.4-0.3-9.8,0.1
				c-1.7,0.3-3.4,0.7-5,1.2c-1.7,0.6-3.3,1.3-4.9,2.2c1.6-0.8,3.3-1.5,5-2c1.7-0.5,3.4-0.8,5-1c3.3-0.3,6.6-0.2,9.6,0.3
				c6.2,0.9,11.5,3.2,16.4,5.6c4.9,2.4,9.4,5.1,13.8,7.7c4.4,2.5,8.8,4.9,13.3,7c4.6,2,9.3,3.8,14.4,4.8c5.1,1.1,10.6,1.5,16.4,0.8
				c5.7-0.7,11.9-2.6,17.5-6.7c2.7-2,5.3-4.5,7.5-7.6C521.4,362.6,523.1,358.9,523.9,354.8z"
                />
              </g>
              <g>
                <path
                  d="M447.8,308.1c-3.2-3.2-7.6-5.5-12.3-6.1c-4.7-0.7-9.8,0.3-13.9,3.4c-2,1.5-3.5,3.4-4.6,5.6c-1.1,2.1-1.7,4.5-1.7,7
				c0,0.6,0.1,1.2,0.1,1.8c0.1,0.6,0.2,1.2,0.4,1.8c0.3,1.2,0.9,2.2,1.4,3.2c1.2,1.9,2.7,3.4,4.5,4.5c1.8,1.1,3.7,1.7,5.7,1.8
				c1.9,0.1,3.9-0.4,5.4-1.3c1.5-0.9,2.6-2,3.3-3.1c0.8-1.1,1.2-2.3,1.5-3.3c0.5-2.1,0-3.9-0.6-4.9c-0.3-0.5-0.6-0.8-0.9-1
				c-0.3-0.2-0.5-0.2-0.7-0.1c-0.2,0.1-0.4,0.3-0.5,0.6c-0.1,0.3-0.2,0.6-0.4,1c-0.3,0.8-0.6,1.7-1.4,2.6c-0.7,0.9-1.9,1.8-3.1,2
				c-1.1,0.2-2.3-0.1-3.5-1.2c-0.6-0.5-1.1-1.2-1.4-2c-0.1-0.4-0.3-0.7-0.3-1.1c0-0.2,0-0.3,0-0.5c0-0.2,0-0.4,0-0.6
				c0.1-1.6,1.2-3.6,2.9-4.8c1.6-1.2,4-1.9,6.7-1.6c2.7,0.2,5.3,1.3,7.7,3.1c2.4,1.8,4.2,4.3,5.3,6.8c1.1,2.5,1.5,5.1,1.3,7.6
				c-0.3,5-2.7,9.6-6.2,12.9c-3.5,3.3-8.6,5.3-14.4,5.7c-5.9,0.5-12.6-0.9-20.1-3.5c7.3,3.1,13.9,5,20.1,5.2
				c6.2,0.2,12-1.1,16.8-4.5c2.4-1.7,4.5-3.8,6.2-6.3c1.7-2.5,3.1-5.4,3.7-8.8C456.1,323.3,454.5,314.8,447.8,308.1z"
                />
              </g>
              <g>
                <path
                  d="M458.9,328.3c-2.4,2.5-3.5,5.9-3.3,8.5c0.2,2.7,1.6,4.5,3.3,4.7c1.8,0.2,3.4-0.9,4.8-2.5c1.3-1.6,2.3-3.7,2.8-6.3
				c0.5-2.7,1-4.7,1.8-6.7c0.8-1.9,1.8-3.8,4.1-6.1c-2.9,1.3-4.9,2.4-6.9,3.5C463.4,324.6,461.4,325.8,458.9,328.3z"
                />
              </g>
              <g>
                <path
                  d="M463.1,362.7c0.5,3.2,2.7,5.9,5,6.9c2.3,1.1,4.4,0.7,5.5-0.7c1-1.4,1-3.4,0.6-5.1c-0.5-1.8-1.4-3.3-3.1-4.8
				c-1.7-1.6-3-3-3.9-4.5c-1-1.6-1.8-3.4-1.9-6.2c-0.9,2.7-1.4,4.7-1.9,6.8C462.9,357.2,462.6,359.4,463.1,362.7z"
                />
              </g>
              <g>
                <path
                  d="M490.6,369c1.7,0.6,3.5,0.7,5.2,0.5c1.7-0.3,3.1-0.9,4.2-1.7c2.3-1.7,2.9-3.9,2-5.4c-0.9-1.5-2.7-2.2-4.6-2.6
				c-1.9-0.3-3.8-0.2-6.1,0.5c-2.3,0.7-4.3,0.9-6.3,0.8c-2-0.2-4-0.7-6.5-2.5c1.7,2.6,3.2,4.3,4.9,5.9
				C485.2,366,487.1,367.7,490.6,369z"
                />
              </g>
              <g>
                <path
                  d="M448.5,349.2c-1.6-0.6-2.7-0.5-4,0.1c-1.3,0.6-2.7,1.6-3.5,3.2c-0.4,0.8-0.5,1.7-0.2,2.5c0.2,0.9,0.8,1.7,1.7,2.4
				c0.9,0.7,2.1,1.2,3.5,1.3c1.3,0.1,2.8-0.1,4.1-0.8c2.7-1.5,3.9-3.1,5-4.5c1.1-1.5,1.9-3.1,2.9-5.2c-1.8,1.5-3.4,1.9-4.9,1.9
				C451.6,350.1,450.2,349.9,448.5,349.2z"
                />
              </g>
              <g>
                <path
                  d="M441,389.9c-1.2,1.3-3,2.2-4.8,2.4c-1.7,0.3-3.6,0-5.4-0.8c-1.8-0.7-3.5-2-4.7-3.3c-1.2-1.3-1.9-2.6-2-3.5
				c-0.2-1,0.1-2.1,0.4-2.7c0.3-0.6,0.9-1,1.7-1.1c0.8-0.1,1.5,0,2.1,0.2c0.6,0.2,1.1,0.4,1.5,0.3c0.4-0.1,0.8-0.6,0.6-1.7
				c-0.2-1-1-2.4-2.7-3.4c-0.8-0.5-1.9-0.8-3.2-0.9c-1.3-0.1-2.7,0.3-4,1.1c-2.7,1.6-4.5,4.5-4.8,8c-0.3,3.6,1.1,7,3.1,9.7
				c2,2.7,4.7,4.9,8,6.4c3.3,1.5,7.3,2.2,11.4,1.2c3.9-0.9,7.6-3.3,9.9-6.8c0.6-0.9,1.1-1.9,1.4-2.9c0.4-1,0.6-2,0.7-2.9
				c0.3-1.9,0.2-3.7-0.1-5.3c-0.6-3.2-1.9-5.9-3.3-8.1c-1.5-2.2-3.2-4.1-5-5.6c-1.8-1.5-3.7-2.8-5.7-3.8c-4-2.1-8.4-3.3-13.2-3.8
				c-4.9-0.5-10.3-0.3-16.5,0.5c12.6-0.7,21.4,1.7,27.8,6.5c3.2,2.4,5.8,5.4,7.4,9.2c0.8,1.9,1.3,3.9,1.3,5.9
				C442.8,386.8,442.3,388.7,441,389.9z"
                />
              </g>
              <g>
                <path
                  d="M457.3,391.4c0,2.2,1.1,3.9,2.5,4.7c1.5,0.9,3.3,0.9,4.8,0c1.5-0.9,2.4-2.4,2.6-4c0.2-1.5-0.4-3.1-1.9-4.3
				c-1.5-1.2-2.7-2.1-3.9-3c-1.1-0.9-2.1-2-3.1-3.7c0,2-0.2,3.5-0.4,5C457.7,387.6,457.4,389.2,457.3,391.4z"
                />
              </g>
              <g>
                <path
                  d="M431.7,410.6c-2.8,1.3-4.6,3.9-5.2,6.2c-0.6,2.3,0.1,4.3,1.6,5.2c1.5,0.9,3.5,0.6,5.2-0.3c1.7-0.9,3.3-2.4,4.4-4.7
				c1.1-2.3,2-4,3.1-5.6c1.1-1.6,2.4-3.1,4.8-4.7c-2.7,0.6-4.7,1-6.8,1.5C436.6,408.7,434.5,409.2,431.7,410.6z"
                />
              </g>
              <g>
                <path
                  d="M389.6,226.7c1.7,0.3,3.4,0.1,5-0.1c1.5-0.3,2.9-0.8,4-1.3c2.2-1.1,3.4-2.3,3.2-3.1c-0.3-0.9-2-1.1-4.3-0.9
				c-2.3,0.2-5.1,0.7-7.8,1.1c-2.8,0.4-4.9,0.2-6.8-0.5c-1-0.3-1.9-0.8-2.9-1.6c-0.9-0.7-1.9-1.8-2.6-3.3c0.4,1.6,1.1,2.9,1.9,3.9
				c0.8,1,1.7,1.9,2.6,2.6c0.9,0.7,2,1.4,3.2,1.9C386.4,226,387.9,226.5,389.6,226.7z"
                />
              </g>
              <g>
                <path
                  d="M392.5,238.2c1.9,2.5,3.7,4.8,5.3,6.6c1.6,1.7,2.9,2.9,3.7,2.5c0.8-0.3,0.8-2.2-0.1-4.7c-1-2.4-3-5.4-5.8-7.5
				c-2.9-2.2-5-3.2-7.2-4.2c-2.2-1-4.4-1.9-7.8-2.8c3.1,1.5,5,2.9,6.7,4.4C389.1,234,390.6,235.6,392.5,238.2z"
                />
              </g>
              <g>
                <path
                  d="M351.7,217.7c-0.7,2.4-1.3,4.7-1.7,6.6c-0.4,1.9-0.5,3.4,0.2,3.9c0.7,0.5,2.2-0.2,3.6-2.1c1.4-1.8,2.4-4.8,2.3-7.9
				c-0.2-3.1-0.8-5.1-1.4-7.1c-0.7-1.9-1.5-3.8-3.1-6.3c1,2.7,1.2,4.7,1.2,6.6C352.7,213.3,352.5,215.2,351.7,217.7z"
                />
              </g>
              <g>
                <path
                  d="M446.3,284.3c3.7-0.6,7.2-2.4,9.4-4.3c2.2-1.9,3-3.7,2.5-4.4c-0.6-0.7-2.4-0.1-4.7,0.8c-2.3,1-5.3,2.3-8.3,3.6
				c-3.2,1.4-5.5,2-7.9,2.5c-2.4,0.4-5,0.7-8.7,0.1c3.6,1.1,6.2,1.5,8.8,1.8C439.8,284.8,442.4,284.9,446.3,284.3z"
                />
              </g>
              <g>
                <path
                  d="M455.1,296.1c2.5,0.2,5-0.7,6.5-1.9c1.5-1.2,2-2.6,1.4-3.3c-0.6-0.7-1.8-0.7-3.3-0.4c-1.5,0.2-3.2,0.7-5.1,1.3
				c-1.9,0.6-3.4,0.9-4.9,0.9c-1.5,0-3.1-0.1-5.3-1.1c1.9,1.4,3.3,2.3,4.9,3C450.8,295.2,452.5,295.8,455.1,296.1z"
                />
              </g>
              <g>
                <path
                  d="M512.7,278.9c-0.8,1.7-2.2,3.2-3.7,4.4c-1.5,1.2-3.3,2.3-4.8,3.4c-1.5,1.1-2.8,2.1-3.5,3c-0.8,0.9-1,1.6-0.8,2
				c0.3,0.4,1,0.3,2,0.1c1.1-0.2,2.5-0.7,4.2-1.4c1.7-0.7,3.8-1.6,5.9-3.2c2-1.6,4-3.9,4.8-6.9c0.4-1.5,0.4-3,0.2-4.4
				c-0.3-1.3-0.8-2.5-1.4-3.4c-1.2-1.9-2.8-3.1-4.4-4c-3.3-1.8-7-2.2-12.2-2.6c2.6,0.5,4.7,0.9,6.5,1.6c1.8,0.7,3.3,1.5,4.5,2.6
				c1.2,1.1,2.2,2.4,2.8,3.9C513.5,275.5,513.5,277.2,512.7,278.9z"
                />
              </g>
              <g>
                <path
                  d="M421,118.9c2.1-3.8,2.7-8.3,2.3-11.5c-0.4-3.3-1.6-5.2-2.4-5c-0.9,0.2-1.3,2.2-1.8,4.9c-0.5,2.8-1.2,6.3-2.2,9.9
				c-1.1,3.7-2.3,6.2-3.8,8.5c-1.5,2.3-3.4,4.6-6.9,6.9c3.8-1.9,6.2-3.7,8.4-5.7C416.7,125,418.8,122.8,421,118.9z"
                />
              </g>
              <g>
                <path
                  d="M423,162.8c5.4,0.3,10.9,0.4,15,0.3c4.1-0.1,6.9-0.6,7-1.5c0.1-0.9-2.6-2-6.7-2.7c-4.2-0.7-9.8-1.1-15.4-0.5
				c-5.7,0.6-9.6,1.3-13.5,2.1c-3.9,0.8-7.8,1.6-13.5,3c5.8-0.8,9.8-1,13.7-1.1C413.6,162.4,417.4,162.5,423,162.8z"
                />
              </g>
              <g>
                <path
                  d="M428.4,172.9c6.4,1.8,13.2,1.4,13.5-0.3c0.2-1.7-6.2-4-12.9-4c-6.9,0-9.5,0.3-16.5-0.1
				C419.4,169.9,421.8,171,428.4,172.9z"
                />
              </g>
              <g>
                <path
                  d="M465.2,185.4c3.3-1.4,6.4-3,8.7-4.4c2.3-1.4,3.8-2.7,3.5-3.5c-0.3-0.8-2.3-1-5.1-0.3c-2.8,0.7-6.3,2.2-9.3,4.5
				c-3.1,2.4-5,4.3-6.8,6.1c-1.8,1.9-3.7,3.9-6.2,6.8c2.9-2.6,5.1-4,7.4-5.3C459.5,188,461.8,186.9,465.2,185.4z"
                />
              </g>
              <g>
                <path
                  d="M479.4,189.5c3.5-0.2,7-0.5,9.6-1c2.6-0.4,4.5-1,4.6-1.9c0.1-0.9-1.7-1.8-4.6-2.3c-2.8-0.5-6.7-0.3-10.4,0.8
				c-3.7,1.2-6.2,2.3-8.5,3.5c-2.4,1.2-4.7,2.5-8,4.6c3.5-1.6,6.1-2.3,8.6-2.8C473.3,190.1,475.8,189.7,479.4,189.5z"
                />
              </g>
              <g>
                <path
                  d="M496,177.4c5.3-1.4,9.4-5.2,8.5-6.8c-0.9-1.5-6-0.2-10.3,2.8c-2.2,1.5-3.8,2.6-5.3,3.7c-1.6,1-3.2,2-5.8,3.2
				c2.7-0.7,4.6-1.1,6.5-1.5C491.4,178.5,493.3,178.1,496,177.4z"
                />
              </g>
              <g>
                <path
                  d="M508.6,181.3c2.3,0.5,4.6,0.6,6.3,0.4c1.8-0.2,3.1-0.7,3.3-1.5c0.2-0.9-0.9-1.9-2.7-2.6c-1.8-0.7-4.4-1.1-6.9-0.6
				c-2.6,0.4-4.3,1-6,1.5c-1.8,0.6-3.6,1.2-5.8,2c2.6-0.3,4.3-0.3,6-0.1C504.5,180.5,506.2,180.8,508.6,181.3z"
                />
              </g>
              <g>
                <path
                  d="M515.5,191.4c5.5,1.8,11.5,1.5,11.8-0.3c0.3-1.7-5.2-4-11-4.1c-6-0.1-8.3,0.3-14.4-0.2
				C507.8,188.4,509.8,189.5,515.5,191.4z"
                />
              </g>
              <g>
                <path
                  d="M537.8,184.7c2.8,0.1,5.6-0.6,7.4-1.6c1.9-1,2.9-2.2,2.6-3c-0.3-0.8-1.8-1.1-3.7-1c-2,0.1-4.4,0.5-6.9,1.3
				c-2.5,0.8-4.3,1.3-6.2,1.7c-1.9,0.4-3.8,0.6-6.6,0.6c2.8,0.6,4.7,1,6.6,1.3C533,184.3,535,184.6,537.8,184.7z"
                />
              </g>
              <g>
                <path
                  d="M537.5,176.3c2.8-0.1,5.5-1.1,7.4-2.2c1.8-1.1,2.8-2.4,2.4-3.2c-0.4-0.8-1.9-1-3.8-0.7c-2,0.2-4.4,0.9-6.9,1.9
				c-2.5,1-4.3,1.6-6.1,2.2c-1.8,0.5-3.7,1-6.6,1.2c2.9,0.3,4.8,0.5,6.8,0.7C532.6,176.2,534.6,176.4,537.5,176.3z"
                />
              </g>
              <g>
                <path
                  d="M535.8,166.5c2.7-0.6,5.2-1.8,6.8-3c1.7-1.3,2.5-2.6,2.1-3.4c-0.8-1.6-6.1-0.4-10.6,2.3c-2.3,1.4-4,2.4-5.6,3.4
				c-1.7,0.9-3.4,1.8-6.1,2.9c2.8-0.5,4.7-0.8,6.7-1C531.1,167.3,533,167.1,535.8,166.5z"
                />
              </g>
              <g>
                <path
                  d="M557.3,160.8c1.9-0.2,3.7-0.8,5-1.5c1.3-0.7,2.2-1.5,2.2-2.4c-0.1-0.9-1.2-1.6-2.9-1.7c-1.7-0.2-3.9,0.3-5.8,1.5
				c-1.9,1.2-3.1,2.3-4.2,3.4c-1.1,1.1-2.2,2.1-3.7,3.7c1.8-1.2,3.2-1.7,4.6-2.1C553.9,161.3,555.3,161,557.3,160.8z"
                />
              </g>
              <g>
                <path
                  d="M366.2,478.6c3.4-1,6.8-2.1,9.3-3.2c2.5-1.1,4.2-2.1,4-3c-0.1-0.9-2.1-1.3-5-1.1c-2.9,0.3-6.7,1.3-10,3.2
				c-3.4,2-5.6,3.6-7.7,5.3c-2.1,1.7-4.2,3.4-7,6.1c3.2-2.3,5.6-3.5,8-4.5C360.2,480.5,362.6,479.6,366.2,478.6z"
                />
              </g>
              <g>
                <path
                  d="M377.5,483.6c2.3,0.1,4.5-0.2,6.2-0.7c1.7-0.5,2.9-1.2,2.9-2.1c0-0.9-1.2-1.7-3.1-2.1c-1.9-0.4-4.4-0.3-6.8,0.6
				c-2.4,0.9-4,1.7-5.6,2.5c-1.7,0.9-3.3,1.8-5.3,2.9c2.4-0.7,4.2-1,5.9-1.1C373.5,483.5,375.1,483.5,377.5,483.6z"
                />
              </g>
              <g>
                <path
                  d="M400.9,489.4c2.5,0.8,5,1.3,7,1.3c2,0.1,3.5-0.3,3.7-1.1c0.3-0.8-0.8-2-2.7-2.9c-1.9-0.9-4.6-1.6-7.4-1.6
				c-2.9,0-4.8,0.3-6.8,0.5c-2.1,0.2-4.1,0.5-6.7,0.8c2.8,0.2,4.7,0.6,6.6,1.1C396.6,487.9,398.4,488.5,400.9,489.4z"
                />
              </g>
              <g>
                <path
                  d="M404,480.2c5.8,1.9,12.1,1.7,12.4,0c0.3-1.7-5.5-4.1-11.6-4.3c-6.3-0.2-8.7,0.1-15.2-0.5
				C395.8,477.1,398,478.2,404,480.2z"
                />
              </g>
              <g>
                <path
                  d="M405.2,471.3c1.9,0,3.8-0.8,4.9-1.8c1.1-1,1.6-2.1,1.2-2.9c-0.4-0.8-1.5-1.1-2.8-1c-1.3,0.1-2.9,0.5-4.3,1.4
				c-1.5,0.9-2.6,1.5-3.8,2c-1.2,0.5-2.4,0.9-4.3,1.2c1.7,0.3,3,0.5,4.5,0.7C401.8,471.2,403.2,471.4,405.2,471.3z"
                />
              </g>
              <g>
                <path
                  d="M326.4,598.7c3.6-0.2,7.2-0.8,10.9-1.8c3.6-1,7.3-2.7,10.5-5.2c1.6-1.3,3.1-2.8,4.3-4.4c1.2-1.7,2.2-3.5,3-5.4
				c1.5-3.8,2.1-7.8,1.7-11.8c-0.2-2-0.7-3.9-1.4-5.8c-0.7-1.9-1.7-3.6-2.8-5.1c-2.2-3.1-5.1-5.5-8.2-7.3c-1.6-0.8-3.2-1.5-4.9-2
				c-1.7-0.5-3.4-0.7-5.1-0.8c-3.4-0.1-6.7,0.5-9.7,1.8c-2.9,1.4-5.5,3.4-7.2,6.1c-1.7,2.6-2.4,5.5-2.4,8.1c0,2.7,0.8,5.3,2.2,7.2
				c0.7,1,1.5,1.8,2.4,2.5c0.9,0.6,1.8,1.1,2.7,1.4c3.6,1.3,6.6,0.5,8.1-0.6c1.6-1.1,1.9-2.1,1.6-2.4c-0.3-0.4-1.2-0.1-2.5,0
				c-0.7,0-1.5,0-2.4-0.2c-0.9-0.2-1.9-0.6-2.8-1.2c-0.9-0.6-1.7-1.5-2.1-2.5c-0.5-1.1-0.6-2.4-0.4-3.8c0.2-1.4,0.8-2.9,1.8-4.1
				c0.9-1.1,2.4-2.1,4.1-2.7c1.7-0.6,3.8-0.8,5.8-0.6c1,0.1,2,0.3,3,0.7c1,0.4,2,0.8,2.9,1.4c1.9,1.2,3.6,2.7,4.9,4.6
				c1.3,1.9,2,4,2.3,6.3c0.2,2.3-0.1,4.8-1,7.1c-0.4,1.1-1,2.2-1.7,3.2c-0.7,1-1.5,1.8-2.5,2.7c-3.9,3.3-9.9,5-16.1,5.8
				c-6.3,0.8-12.3,0.4-17.8-0.8c-5.5-1.2-10.3-3.2-14.8-5.6c-9-4.9-16.6-10.8-25.2-15.5c-8.6-4.7-18.1-7.9-29-9.2
				c-5.4-0.6-11.2-0.8-17.4-0.2c-6.1,0.6-12.7,2-19.4,4.5c6.8-2.2,13.3-3.3,19.4-3.6c6.1-0.3,11.8,0.2,17.1,1.1
				c10.5,1.9,19.4,5.7,27.3,10.8c3.9,2.6,7.7,5.5,11.5,8.5c3.9,3,7.9,6.1,12.5,9.1c4.6,3,9.9,5.7,16,7.5
				C312.1,598.2,319,599.2,326.4,598.7z"
                />
              </g>
              <g>
                <path
                  d="M279.9,561.6c4.2-1.3,8.3-3.6,11.6-6.8c1.7-1.6,3.1-3.5,4.3-5.6c1.2-2.1,2-4.4,2.3-7c0.7-5.2-1.5-10.8-6.2-13.8
				c-2.4-1.4-4.9-1.9-7.1-1.8c-2.2,0.1-4.1,0.5-6,1.3c-1.8,0.8-3.5,2-4.9,3.6c-1.3,1.6-2.1,3.7-2.1,5.7c0,3.9,2.4,6.6,4.7,7.6
				c2.3,1,4.2,0.5,5.3-0.1c1.1-0.6,1.3-1.3,1.2-1.6c-0.2-0.4-0.8-0.6-1.5-0.9c-0.7-0.3-1.7-0.8-2.4-1.6c-0.3-0.4-0.5-0.8-0.6-1.4
				c-0.1-0.5-0.1-1.1,0.1-1.5c0.2-0.4,0.5-0.7,1-1.1c0.5-0.3,1.2-0.7,2.1-0.9c0.9-0.2,2-0.3,2.9-0.2c1,0.1,1.7,0.4,2.2,0.8
				c0.5,0.4,0.9,1,1.2,1.8c0.2,0.4,0.3,0.9,0.3,1.3c0,0.4,0,0.9,0,1.5c-0.3,2.1-1.7,4.8-3.7,7c-2,2.2-4.7,4.1-7.8,5.4
				c-3.1,1.4-6.4,2.2-9.5,2.6c-3.2,0.4-6.2,0.5-9.2,0.4c-5.9-0.1-11.4-0.8-17-1.1c-5.6-0.3-11.4-0.3-17.6,0.8
				c-6.2,1.1-13,3.1-20.3,7c7.5-3.4,14.4-4.8,20.5-5.3c6.2-0.4,11.6,0.2,17,1.3c5.4,1,10.7,2.4,16.9,3.4c3.1,0.5,6.5,0.9,10.2,0.9
				C271.4,563.3,275.5,563,279.9,561.6z"
                />
              </g>
              <g>
                <path
                  d="M303.9,501.7c1.1-5.3,2.3-10.4,3.1-14.4c0.8-4.1,1.2-7.3-0.3-8.3c-1.4-1-4.6,0.8-7.4,4.9c-1.4,2.1-2.6,4.7-3.4,7.7
				c-0.8,3-1.1,6.3-0.8,9.6c0.3,3.4,0.9,6.2,1.7,8.6c0.7,2.4,1.6,4.5,2.6,6.5c1,2,2,4,3.3,6.1c1.3,2.1,2.8,4.3,4.9,6.8
				c-1.6-2.8-2.6-5.2-3.3-7.5c-0.7-2.3-1.1-4.3-1.3-6.4c-0.2-2.1-0.2-4.1-0.1-6.3C303,506.9,303.3,504.5,303.9,501.7z"
                />
              </g>
              <g>
                <path
                  d="M320.9,499.7c0.3-3.4-0.1-6.9-1.1-10c-0.9-3.1-2.4-5.8-4.1-7.9c-1.6-2.1-3.4-3.4-4.9-4.1c-1.5-0.7-2.6-0.7-3.3-0.1
				c-1.3,1.3-0.3,4.3,0.8,8.2c0.6,2,1.2,4.2,1.9,6.6c0.6,2.4,1.3,5,1.8,7.7c0.6,2.8,0.8,5.2,0.9,7.5c0.1,2.3,0,4.4-0.3,6.5
				c-0.5,4.2-1.6,8.5-4.5,14.4c3.8-5.3,6.1-9.2,8.1-13.3c1-2.1,1.9-4.2,2.7-6.7C319.9,506,320.6,503.2,320.9,499.7z"
                />
              </g>
              <g>
                <path
                  d="M312.1,497.4c0.7-3.6,0.6-7.3,0-10.1c-0.6-2.7-1.6-4.4-2.5-4.3c-0.9,0.1-1.4,1.9-1.7,4.6c-0.3,2.6-0.4,6-0.2,9.5
				c0.2,3.6,0.2,6.1,0.2,8.6c-0.1,2.5-0.3,5.1-1.2,8.8c1.3-3.6,2.2-6,3-8.5C310.5,503.6,311.3,501.1,312.1,497.4z"
                />
              </g>
              <g>
                <path
                  d="M310,493.7c0.9-3.1,1-6.3,0.6-8.7c-0.4-2.4-1.2-4-2.1-3.9c-0.9,0-1.6,1.6-2.1,3.8c-0.5,2.3-0.8,5.2-0.8,8.3
				c0,3.1,0,5.3-0.2,7.5c-0.2,2.2-0.4,4.4-1.2,7.6c1.2-2.9,2.1-5,3.1-7.2C308.2,499,309,496.9,310,493.7z"
                />
              </g>
              <g>
                <path
                  d="M302.6,488.7c1.5-2.6,2.9-5.1,3.9-7.2c1-2,1.6-3.6,1-4.3c-0.6-0.6-2.4,0-4.3,1.7c-1.9,1.8-3.9,4.8-4.7,8.1
				c-0.8,3.5-0.9,5.9-0.8,8.3c0.1,2.4,0.3,4.7,1,8.1c-0.2-3.4,0.2-5.7,0.8-7.9C300.3,493.4,301.1,491.4,302.6,488.7z"
                />
              </g>
              <g>
                <path
                  d="M305.8,559.5c1.5-1.7,2.7-3.7,3.5-5.7c0.8-2,1.3-4,1.4-5.7c0.3-3.4-0.5-5.7-1.4-5.7c-0.9,0-1.6,2-2.7,4.8
				c-1.1,2.8-2.5,6.3-4.4,9.7c-2,3.5-3.9,5.6-6.1,7.6c-2.2,1.9-4.8,3.6-9.1,4.9c4.4-0.8,7.4-1.9,10.1-3.3c1.4-0.7,2.7-1.5,4.2-2.5
				C302.7,562.6,304.2,561.3,305.8,559.5z"
                />
              </g>
              <g>
                <path
                  d="M341,541.1c4.9-0.6,9.7-1.1,13.4-1.5c3.7-0.4,6.3-0.9,6.4-1.8c0.1-0.8-2.4-2-6.4-2.4c-3.9-0.5-9.3-0.1-14.4,1.4
				c-5.2,1.6-8.5,3.3-11.7,5c-3.2,1.8-6.4,3.7-10.8,6.9c4.6-2.8,8.1-4.1,11.5-5.2C332.6,542.5,336,541.7,341,541.1z"
                />
              </g>
              <g>
                <path
                  d="M361.1,546c3.3,0.2,6.6,0,9.1-0.3c2.5-0.4,4.3-1,4.3-1.9c0.1-0.9-1.6-1.8-4.2-2.3c-2.6-0.5-6.1-0.6-9.6,0.1
				c-3.5,0.7-5.9,1.5-8.2,2.3c-2.5,0.8-4.8,1.6-8.1,2.8c3.6-0.7,6-0.8,8.5-0.9C355.2,545.7,357.6,545.8,361.1,546z"
                />
              </g>
              <g>
                <path
                  d="M368.5,552.6c2.6,0.8,5.2,1.2,7.2,1.2c2,0,3.6-0.3,3.8-1.2c0.2-0.8-0.9-2-2.8-2.9c-2-0.9-4.8-1.6-7.6-1.5
				c-2.9,0.1-4.9,0.4-6.9,0.7c-2.1,0.3-4.2,0.7-6.9,1.1c2.9,0.1,4.9,0.4,6.8,0.8C364,551.3,365.9,551.8,368.5,552.6z"
                />
              </g>
              <g>
                <path
                  d="M371.3,563.5c1.5,1.5,2.9,2.8,4,3.8c1.2,1,2.2,1.7,3.1,1.5c0.8-0.3,1.2-1.8,0.4-3.8c-0.7-2-2.7-4.1-5.1-5.1
				c-2.5-1.1-4.2-1.3-6-1.6c-1.7-0.3-3.4-0.5-5.9-1c2.3,1,3.8,1.8,5.2,2.7C368.4,561,369.8,562,371.3,563.5z"
                />
              </g>
              <g>
                <path
                  d="M370.1,580.9c0.2,2.4,0.6,4.7,1.1,6.5c0.5,1.8,1.1,3.1,2,3.2c0.9,0.1,1.8-1.2,2.2-3.3c0.4-2.1,0.2-5-1-7.6
				c-1.2-2.6-2.4-4.2-3.6-5.8c-1.2-1.5-2.5-3-4.4-5c1.5,2.3,2.2,4.1,2.7,5.8C369.5,576.6,369.8,578.4,370.1,580.9z"
                />
              </g>
              <g>
                <path
                  d="M364.6,598.8c-0.5,1.7-0.6,3.5-0.3,4.8c0.2,1.4,0.8,2.4,1.7,2.6c0.9,0.2,1.8-0.6,2.5-2c0.6-1.4,1-3.4,0.6-5.3
				c-0.4-2-0.9-3.3-1.3-4.7c-0.5-1.3-0.9-2.6-1.5-4.5c0.1,2-0.1,3.3-0.4,4.7C365.5,595.7,365.1,597,364.6,598.8z"
                />
              </g>
              <g>
                <path
                  d="M353.3,601c-3.3,3.3-5.1,7.7-3.7,8.8c1.4,1.1,5.4-1.9,7.3-6.4c1.9-4.6,2.2-6.5,4.2-11.1
				C358.2,596.4,356.7,597.5,353.3,601z"
                />
              </g>
              <g>
                <path
                  d="M340.2,602.7c-2.6,1.1-5,2.5-6.7,3.8c-1.7,1.3-2.7,2.6-2.3,3.4c0.4,0.8,2,0.9,4.2,0.3c2.2-0.6,4.8-1.9,7.1-3.8
				c2.3-2,3.8-3.5,5.2-5c1.5-1.5,2.9-3,5.1-5.1c-2.5,1.7-4.4,2.7-6.2,3.6C344.8,600.8,342.9,601.6,340.2,602.7z"
                />
              </g>
              <g>
                <path
                  d="M316.4,606.9c-2.8,0-5.6,0.4-7.6,1c-2.1,0.6-3.5,1.4-3.5,2.2c0,0.9,1.5,1.6,3.7,1.9c2.2,0.3,5.2,0.1,8.1-0.9
				c2.9-1,4.8-1.9,6.7-2.8c2-1,4-1.9,6.6-3.1c-3,0.8-5,1.1-7.1,1.3C321.3,606.8,319.3,606.9,316.4,606.9z"
                />
              </g>
              <g>
                <path
                  d="M233,569.1c5.4,0.9,10.6,2.2,14.6,3.2c4,1,6.8,1.7,7.2,0.8c0.4-0.7-1.8-2.8-5.8-4.8c-4-1.9-9.8-3.5-15.7-3.6
				c-6,0-10.1,0.7-14.1,1.6c-4,0.9-8,2.1-13.5,4.5c5.7-1.9,9.8-2.4,13.7-2.6C223.5,568,227.4,568.2,233,569.1z"
                />
              </g>
              <g>
                <path
                  d="M251.9,584.4c1.8,1.9,3.6,3.7,5.1,5.1c1.5,1.3,2.8,2.2,3.6,1.8c0.8-0.4,0.8-2.1-0.2-4.2c-1-2.1-3.1-4.6-5.8-6.1
				c-2.8-1.5-4.8-2.2-6.9-2.7c-2.1-0.5-4.1-0.9-7.1-1.1c2.9,0.8,4.7,1.8,6.4,2.9C248.6,581.1,250.1,582.4,251.9,584.4z"
                />
              </g>
              <g>
                <path
                  d="M68,396c1.6-0.2,3.2-0.3,4.9-0.5c1.6-0.1,3.3-0.2,4.8-0.2c3.1,0,6.1,0.2,8.8,0.7c2.7,0.5,5.1,1.4,6.9,2.6
				c0.9,0.6,1.7,1.2,2.3,1.9c0.3,0.3,0.6,0.7,0.9,1.1c0.2,0.4,0.4,0.7,0.6,1.1c0.7,1.6,1.1,3.6,0.9,5.5c-0.1,1-0.3,1.9-0.6,2.8
				c-0.3,0.9-0.8,1.8-1.3,2.6c-1.1,1.7-2.5,3.2-4.2,4.4c-1.7,1.2-3.4,2.1-5.2,2.7c-1.8,0.6-3.7,0.8-5.3,0.7c-1.7-0.1-3.2-0.4-4.5-1
				c-2.5-1-4.3-3.3-5.3-5.4c-1-2.1-1.2-4.1-1.3-5.6c0-0.7-0.1-1.4-0.1-1.9c-0.1-0.5-0.2-0.9-0.5-1.1c-0.3-0.2-0.7-0.2-1.2,0
				c-0.5,0.2-1.1,0.7-1.8,1.5c-0.6,0.8-1.3,1.9-1.7,3.4c-0.4,1.4-0.7,3.2-0.5,5.1c0.1,1.9,0.7,4.1,1.7,6.3c0.3,0.5,0.6,1.1,0.9,1.6
				c0.4,0.5,0.7,1.1,1.2,1.6c0.5,0.5,0.9,1.1,1.4,1.5c0.3,0.2,0.5,0.5,0.8,0.7c0.3,0.2,0.6,0.4,0.8,0.7c2.3,1.8,5.2,3,8.2,3.7
				c3.1,0.7,6.4,0.7,9.7,0.2c3.3-0.6,6.8-1.8,9.9-3.6c3.1-1.9,6.1-4.4,8.5-7.6c1.2-1.6,2.3-3.4,3.1-5.4c0.8-2,1.4-4.1,1.7-6.3
				c0.6-4.4,0.1-9-1.8-13.5c-0.5-1.1-1.1-2.2-1.7-3.2c-0.7-1-1.4-2-2.2-2.9c-0.8-0.9-1.7-1.7-2.6-2.5c-0.5-0.4-0.9-0.7-1.4-1.1
				c-0.5-0.4-1-0.7-1.5-1c-4-2.5-8.2-3.7-12.3-4.3c-4.1-0.6-8.1-0.7-12-0.3c-1.9,0.2-3.8,0.4-5.6,0.7c-1.9,0.3-3.7,0.6-5.5,1
				c-7.6,1.7-14.2,4-20.2,6.7c-6,2.7-11.3,5.7-16.1,8.9c-4.8,3.2-9.1,6.5-13,10.1c-3.9,3.5-7.6,7.2-10.9,11
				c-6.7,7.7-12.6,16.1-18,26.2c-5.4,10-10.4,21.7-14.4,36.1c4.8-14.2,10.6-25.4,16.7-34.7c6.1-9.4,12.7-16.9,19.9-23.6
				c3.6-3.3,7.4-6.3,11.4-9.2c4-2.9,8.4-5.5,13.1-7.9c4.7-2.4,9.8-4.6,15.4-6.4C55.2,398.3,61.3,396.8,68,396z"
                />
              </g>
              <g>
                <path
                  d="M53,455.6c0.5,1.9,0.5,3.8,0.3,5.6c-0.3,1.9-0.8,3.7-1.7,5.4c-0.9,1.7-2,3.1-3.3,4.3c-0.6,0.6-1.3,1.1-2,1.6
				c-0.7,0.5-1.5,0.9-2.3,1.2c-3.2,1.4-6.9,1.6-10,0.9c-1.6-0.3-2.9-0.9-3.9-1.6c-1-0.7-1.6-1.3-2.1-2.1c-0.2-0.4-0.4-0.8-0.5-1.1
				c-0.1-0.3-0.2-0.6-0.1-0.9c0-0.6,0.3-1.5,1-2.3c0.7-0.9,1.6-1.7,2.6-2.4c0.5-0.3,1-0.7,1.5-1c0.5-0.3,0.9-0.5,1.3-0.8
				c0.5-0.3,1-0.6,1.4-0.9c0.2-0.2,0.7-0.2,1.1-0.2c0.4,0.1,0.7,0.2,1,0.4c0.3,0.2,0.5,0.5,0.8,0.6c0.3,0.1,0.7,0.1,1.1-0.4
				c0.4-0.4,0.7-1.4,0.4-2.5c-0.2-1.1-0.9-2.3-2.5-3.3c-1.5-0.9-3.3-1.1-5-1c-0.8,0.1-1.6,0.2-2.4,0.3c-0.8,0.1-1.6,0.3-2.5,0.6
				c-1.7,0.5-3.6,1.3-5.5,2.6c-1.9,1.3-4,3.4-5.2,6.4c-0.6,1.5-1,3.2-1.1,4.8c-0.1,1.7,0.1,3.3,0.6,5c0.5,1.6,1.2,3.2,2.1,4.6
				c0.9,1.5,2.1,2.7,3.3,3.8c2.5,2.2,5.4,3.8,8.4,4.8c6.1,2,13.1,1.9,19.6-0.7c3.2-1.3,6.4-3.2,9-5.8c2.6-2.5,4.7-5.6,6.2-8.9
				c1.5-3.3,2.4-6.9,2.5-10.6c0.2-3.7-0.5-7.5-1.8-10.8c-2.7-7-7.7-12-13.2-14.9c-5.6-2.9-11.3-3.4-16.2-3.1
				c-4.9,0.3-9.3,1.4-13.4,2.8c-4.1,1.3-7.9,3-11.5,4.9c-3.7,1.9-7.1,4.1-10.6,6.6c-3.4,2.5-6.7,5.3-9.9,8.6
				c-3.2,3.3-6.3,6.9-9.3,11.1c-2.9,4.2-5.7,9-8,14.5c2.6-5.3,5.7-9.8,9-13.7c3.2-3.9,6.6-7.1,10-9.9c3.4-2.8,6.9-5.1,10.4-7
				c3.5-1.9,7-3.5,10.6-4.8c3.6-1.2,7.3-2.3,11.1-2.9c3.8-0.7,7.7-1.1,11.5-0.8c3.8,0.3,7.6,1.4,10.5,3.5
				C49.6,448.2,52,451.7,53,455.6z"
                />
              </g>
              <g>
                <path
                  d="M121.8,361.1c10.9,1.6,21.5,3.9,29.6,5.9c0.5,0.1,1,0.2,1.5,0.3c0.5,0.1,1,0.2,1.4,0.4c0.9,0.2,1.8,0.4,2.6,0.6
				c0.8,0.2,1.6,0.3,2.4,0.5c0.7,0.2,1.4,0.3,2,0.4c2.5,0.4,4.2,0.3,4.6-0.5c0.4-0.8-0.5-2.1-2.5-3.9c-0.5-0.4-1.1-0.9-1.7-1.4
				c-0.6-0.5-1.4-0.9-2.1-1.4c-0.8-0.5-1.6-1-2.5-1.5c-0.5-0.2-0.9-0.5-1.4-0.8c-0.5-0.2-1-0.5-1.5-0.7c-8.1-3.8-19.9-6.9-31.8-6.7
				c-3.1,0.1-5.9,0.1-8.5,0.4c-1.3,0.1-2.6,0.2-3.8,0.4c-1.2,0.2-2.4,0.4-3.5,0.5c-1.1,0.2-2.3,0.3-3.3,0.5
				c-1.1,0.2-2.1,0.4-3.2,0.7c-1,0.2-2.1,0.4-3.1,0.7c-1,0.3-2,0.5-3,0.8c-1,0.3-2,0.5-3,0.8c-1,0.3-2,0.6-3,1c-1,0.3-2,0.6-3.1,1
				c-1,0.4-2.1,0.8-3.2,1.2c-1.1,0.4-2.2,0.8-3.3,1.3c-1.1,0.5-2.3,1-3.5,1.5c-0.6,0.3-1.2,0.5-1.8,0.8c-0.6,0.3-1.2,0.6-1.9,0.9
				c-1.3,0.6-2.6,1.2-4,1.9c1.4-0.5,2.8-1,4.1-1.5c0.7-0.2,1.3-0.5,1.9-0.7c0.6-0.2,1.3-0.4,1.9-0.6c1.2-0.4,2.4-0.7,3.6-1
				c1.2-0.3,2.3-0.5,3.4-0.8c1.1-0.2,2.2-0.5,3.3-0.7c1.1-0.2,2.1-0.3,3.2-0.5c1-0.1,2-0.3,3-0.4c1-0.1,2-0.2,3-0.3
				c1-0.1,2-0.2,3-0.2c1-0.1,2-0.1,3-0.1c1,0,2.1,0,3.1-0.1c1.1,0,2.1,0.1,3.2,0.1c1.1,0,2.2,0.1,3.4,0.1c1.2,0.1,2.3,0.2,3.6,0.3
				C116.4,360.4,119,360.8,121.8,361.1z"
                />
              </g>
              <g>
                <path
                  d="M120.9,378.3c10.8,0.9,21.8-0.5,29.7-2.7c7.9-2.2,12.8-5.1,12.3-6.8c-0.4-1.8-5.9-1.9-13.7-1.5
				c-7.8,0.4-18.1,1.3-28.4,2.2c-10.6,0.9-18,1.1-25.5,0.9c-7.5-0.3-15.1-1-26.1-3.4c10.7,3.5,18.2,5.4,25.6,7.2
				C102.3,375.9,109.8,377.4,120.9,378.3z"
                />
              </g>
              <g>
                <path
                  d="M132.4,371.1c6.3,1.3,12.7,1.4,17.5,0.7c4.8-0.7,8-2.3,8-4c0-1.8-3.1-3.3-7.8-4.4c-4.7-1-10.9-1.5-17.2-1
				c-6.5,0.5-10.9,0.9-15.5,1.2c-4.5,0.3-9.1,0.4-15.8,0c6.6,1.4,11,2.6,15.4,3.8C121.5,368.5,125.9,369.7,132.4,371.1z"
                />
              </g>
              <g>
                <path
                  d="M166.3,420.2c1,0,2.1,0,3.1-0.2c1-0.2,2.1-0.4,3.1-0.7c2-0.7,4-1.7,5.8-3.3c1.8-1.5,3.3-3.7,4-6.1
				c0.7-2.4,0.7-4.8,0.3-6.9c-0.4-2.1-1.2-4-2.1-5.7c-0.9-1.7-2-3.1-3.3-4.5c-1.3-1.3-2.8-2.4-4.5-3.3c-1.6-0.8-3.6-1.3-5.3-1.4
				c-3.5-0.2-6.9,1.1-9,3.3c-1.1,1.1-1.8,2.4-2.2,3.6c-0.4,1.2-0.5,2.3-0.4,3.3c0.2,2,1.1,3.4,1.9,4c0.8,0.7,1.5,0.7,1.8,0.4
				c0.3-0.3,0.4-0.9,0.5-1.6c0.1-0.7,0.2-1.6,0.7-2.4c0.4-0.9,1.2-1.8,2.1-2.1c0.9-0.4,2.3-0.6,3.6-0.2c1.3,0.4,2.6,1.3,3.9,2.8
				c1.2,1.5,2.3,3.6,2.6,5.5c0.2,0.9,0.1,1.7-0.1,2.3c-0.2,0.6-0.5,1.2-1.1,1.8c-0.6,0.6-1.5,1.2-2.5,1.7c-0.5,0.2-1.1,0.4-1.6,0.6
				c-0.6,0.2-1.1,0.3-1.8,0.4c-2.6,0.3-5.2,0-7.6-0.7c-2.4-0.7-4.7-1.7-6.8-2.9c-4.1-2.4-7.6-5.4-11-8.5c-3.5-3.1-6.9-6.5-11.2-9.7
				c-2.2-1.6-4.5-3.1-7.3-4.5c-2.7-1.3-5.9-2.4-9.3-2.6c3.5,0.5,6.4,1.9,8.9,3.4c2.5,1.5,4.7,3.3,6.5,5.1c3.8,3.6,6.6,7.3,9.5,11
				c3,3.7,6,7.4,10.3,11c2.2,1.8,4.6,3.4,7.6,4.8C158.6,419,162.2,420.1,166.3,420.2z"
                />
              </g>
              <g>
                <path
                  d="M130.6,425.7c-0.7,1.3-1.4,2.2-2.3,3c-0.9,0.7-1.7,1.2-2.5,1.4c-0.6,0.2-1.4,0.2-2.1,0c-0.7-0.2-1.2-0.4-1.5-0.7
				c-0.3-0.1-0.6-0.7-0.7-1.3c-0.1-0.6-0.1-1.3,0-1.9c0.1-0.6,0.3-1.1,0.4-1.6c0.2-0.5,0.3-1,0.2-1.4c-0.1-0.4-0.4-0.8-1.1-1
				c-0.7-0.2-1.8-0.1-2.9,0.5c-1.2,0.6-2.4,1.7-3.2,3.4c-0.8,1.6-1.3,4-0.4,6.6c0.8,2.4,2.7,4.6,5.1,5.9c2.4,1.3,5.3,1.8,8.3,1.2
				c2.9-0.6,5.4-2.2,7.2-4.2c1.8-2,3.1-4.6,3.7-7c0.6-2.5,0.6-4.8,0.4-6.8c-0.2-2-0.6-3.8-1-5.4c-0.9-3.3-2.2-6.1-3.7-8.6
				c-1.5-2.6-3.3-4.9-5.6-7.3c-1.1-1.2-2.4-2.3-3.8-3.5c-1.4-1.1-3.1-2.2-4.9-3.2c1.7,1.2,3.1,2.6,4.3,3.8c1.2,1.3,2.2,2.6,3,3.9
				c1.7,2.6,2.7,5.1,3.5,7.7c0.7,2.6,1.1,5.2,1.1,8C132.2,419.9,131.9,423.1,130.6,425.7z"
                />
              </g>
              <g>
                <path
                  d="M612.6,399.5c-0.7-3.6-1.9-7.1-3.5-10.4c-1.6-3.3-3.6-6.4-5.8-9.1c-2.2-2.7-4.6-4.8-6.8-6.8c-2.2-1.9-4.2-3.6-5.9-5.2
				c-3.5-3.1-6-6-7.6-8.2c-1.7-2.2-2.6-3.7-3.5-3.5c-0.8,0.1-1.5,2-0.9,5.4c0.3,1.6,0.9,3.6,1.9,5.6c1,2,2.4,4.2,4,6.3
				c3.3,4.3,7.7,8.3,11.3,12.7c3.5,4.3,6.4,9.6,8.4,15.4c1,3,1.7,5.9,2.1,8.6c0.4,2.7,0.7,5.4,0.7,7.9c0,5-0.5,9.6-1.6,14.1
				c-1.1,4.5-2.6,9-4.9,13.8c-2.3,4.8-5.4,9.9-9.8,15.6c4.8-5.3,8.4-10.1,11.3-14.7c2.9-4.6,5.2-9,7-13.5c1.8-4.6,3.3-9.3,4.1-14.8
				c0.4-2.8,0.6-5.7,0.6-8.9C613.7,406.6,613.4,403.2,612.6,399.5z"
                />
              </g>
              <g>
                <path
                  d="M580.2,404.8c-0.8-5.7-1-11.4-0.9-16.8c0.1-5.4,0.4-10.6,0.8-15c0.7-8.9,0.9-15.2-0.8-15.7c-1.7-0.4-4.9,5.1-7.5,14.3
				c-1.2,4.6-2.2,10.1-2.4,16.2c-0.2,6,0.4,12.6,2.2,18.9c1.9,6.5,4.3,11.6,7.1,15.9c1.4,2.2,2.8,4.1,4.3,5.9
				c1.4,1.8,2.9,3.4,4.2,5.1c2.6,3.3,5,6.9,5.9,11.6c0.2,1.2,0.4,2.4,0.4,3.7c0,1.3,0,2.6-0.2,4c-0.4,2.7-1.3,5.6-3,8.4
				c1.9-2.7,3.1-5.5,3.8-8.2c0.4-1.4,0.6-2.7,0.7-4.1c0.1-1.3,0.1-2.6,0.1-3.9c-0.1-2.5-0.7-5-1.5-7.2c-0.8-2.2-1.8-4.3-2.9-6.2
				c-2.2-3.9-4.5-7.5-6.2-11.7C582.4,415.5,581,410.7,580.2,404.8z"
                />
              </g>
              <g>
                <path
                  d="M593.6,391.6c-1-7.1-3.4-13.9-5.9-18.7c-2.5-4.8-4.9-7.5-5.7-7.1c-0.8,0.4,0.2,3.8,1.8,8.7c1.6,4.9,3.7,11.4,5.5,17.9
				c1.8,6.8,2.7,11.6,3.3,16.5c0.5,4.9,0.8,9.9,0.1,17.3c1.2-7.3,1.6-12.4,1.8-17.4C594.6,403.9,594.6,398.9,593.6,391.6z"
                />
              </g>
              <g>
                <path
                  d="M588.3,385.7c-0.4-5.3-1.8-10.5-3.4-14.1c-1.6-3.7-3.4-5.8-4.2-5.5c-0.8,0.3-0.6,3,0.2,6.7c0.7,3.8,1.9,8.6,3.1,13.5
				c1.2,5.1,1.9,8.6,2.3,12.2c0.4,3.6,0.7,7.4,0.3,12.8c0.9-5.4,1.3-9.2,1.6-12.9C588.5,394.8,588.7,391.1,588.3,385.7z"
                />
              </g>
              <g>
                <path
                  d="M583.8,385.6c0.2-8.5-1.7-16.9-3.5-16.8c-1.8,0.1-2.4,8.7-0.9,17.1c1.6,8.6,2.6,11.8,3.8,20.8
				C583,397.7,583.5,394.4,583.8,385.6z"
                />
              </g>
              <g>
                <path
                  d="M38.3,418.2c3.6-0.5,7.1-1.2,9.7-2c2.6-0.8,4.4-1.7,4.3-2.5c-0.1-0.9-2-1.5-4.9-1.6c-2.9-0.1-6.7,0.5-10.3,1.9
				c-3.7,1.5-6,2.8-8.4,4.1c-2.3,1.3-4.7,2.7-8,4.8c3.6-1.6,6.2-2.4,8.7-3C32.1,419.3,34.6,418.8,38.3,418.2z"
                />
              </g>
              <g>
                <path
                  d="M37.7,425.6c3.2-0.1,6.2-0.5,8.6-1.1c2.3-0.5,4-1.2,4-2.1c0-0.9-1.6-1.7-4.2-2.1c-2.6-0.4-6-0.1-9.3,0.9
				c-3.3,1.1-5.5,2.1-7.6,3.2c-2.1,1.1-4.2,2.2-7.3,3.9c3.3-1.2,5.6-1.8,7.9-2.1C32.2,425.9,34.5,425.7,37.7,425.6z"
                />
              </g>
              <g>
                <path
                  d="M59.5,432.6c2.4,0.8,4.7,1.4,6.6,1.6c1.9,0.2,3.4,0.1,3.8-0.7c0.4-0.8-0.5-2.1-2.3-3.2c-1.8-1.1-4.5-2-7.3-2
				c-2.9,0-4.8,0.3-6.8,0.7c-1.9,0.4-3.8,0.8-6.5,1.5c2.8-0.2,4.7,0,6.5,0.4C55.3,431.3,57.1,431.8,59.5,432.6z"
                />
              </g>
              <g>
                <path
                  d="M80.6,446.8c4,0.5,8.1-0.5,10.7-1.9c2.7-1.4,4-3.1,3.6-3.8c-0.4-0.8-2.4-0.5-5-0.1c-2.6,0.4-5.9,1-9.4,1.5
				c-3.6,0.4-6.1,0.3-8.7,0c-2.5-0.4-5.2-1.1-8.6-3.1c3.1,2.4,5.6,3.7,8.1,4.9C73.9,445.3,76.5,446.2,80.6,446.8z"
                />
              </g>
              <g>
                <path
                  d="M84.1,455.5c2.2,0,4.3-0.8,5.6-1.8c1.3-1,1.9-2.2,1.5-3c-0.4-0.8-1.6-1-3.1-0.9c-1.5,0.1-3.2,0.6-4.9,1.4
				c-1.8,0.8-3.1,1.4-4.4,1.8c-1.4,0.4-2.8,0.8-4.9,0.8c2.1,0.5,3.5,0.8,5,1.1C80.4,455.3,81.9,455.5,84.1,455.5z"
                />
              </g>
              <g>
                <path
                  d="M-6.8,468.2c-3.8,3.9-6.3,9-7.3,13c-1,4-0.7,6.8,0.2,7c0.9,0.2,2.1-2.2,3.8-5.5c1.7-3.3,4-7.6,6.8-11.7
				c2.9-4.2,5.4-6.9,8.1-9.3c2.8-2.3,5.8-4.6,11-6.6c-5.3,1.5-8.8,3.2-12.1,5C0.3,462-2.9,464.1-6.8,468.2z"
                />
              </g>
              <g>
                <path
                  d="M-0.6,480.6c-1,1.9-1.8,3.9-2.4,5.8c-0.3,1-0.5,1.9-0.7,2.8c-0.2,0.9-0.3,1.9-0.3,2.8c0,0.9,0.1,1.8,0.4,2.6
				c0.3,0.8,0.7,1.5,1.2,1.9c0.5,0.4,1,0.6,1.4,0.6c0.4,0,0.6-0.1,0.7-0.3c0.2-0.4-0.1-1,0-1.6c0-0.6,0.1-1.5,0.5-2.7
				c0.2-0.6,0.4-1.2,0.7-2.1c0.3-0.8,0.6-1.7,0.8-2.5c0.6-1.8,1.1-3.7,1.7-5.6c1.1-4,2.1-6.7,3.2-9.3c1.2-2.7,2.5-5.3,5-8.9
				c-2.9,3.2-4.8,5.6-6.7,7.9C3.3,474.3,1.5,476.7-0.6,480.6z"
                />
              </g>
              <g>
                <path
                  d="M7.5,492.9c-0.1,2.4,0.6,4.8,1.6,6.4c1,1.6,2.2,2.3,3,2c0.8-0.4,1.1-1.7,1-3.3c-0.1-1.6-0.5-3.6-1.2-5.6
				c-0.7-2.1-1.2-3.6-1.5-5.1c-0.3-1.6-0.5-3.2-0.3-5.6c-0.7,2.3-1.2,3.9-1.6,5.5C8.1,488.7,7.7,490.4,7.5,492.9z"
                />
              </g>
              <g>
                <path
                  d="M19.5,498.4c0.8,2,2.3,3.6,3.7,4.4c1.4,0.8,2.7,0.9,3.3,0.2c0.6-0.7,0.3-1.9-0.3-3.1c-0.6-1.3-1.7-2.7-3-3.9
				c-1.4-1.3-2.4-2.3-3.2-3.3c-0.9-1.1-1.7-2.2-2.5-4.2c0.3,2.1,0.5,3.5,0.7,4.9C18.5,494.9,18.7,496.4,19.5,498.4z"
                />
              </g>
              <g>
                <path
                  d="M36.3,500.3c1.6,0.8,3.4,0.8,4.7,0.4c1.3-0.4,2-1.3,2-2.2c-0.1-0.9-0.9-1.6-1.9-2c-1.1-0.5-2.4-0.7-3.8-0.5
				c-1.5,0.2-2.6,0.2-3.7,0.2c-1.1,0-2.3-0.2-3.8-0.8c1.1,0.9,2.1,1.7,3.2,2.6C33.7,498.7,34.7,499.5,36.3,500.3z"
                />
              </g>
              <g>
                <path
                  d="M173.3,453.4c1.6-3.6,2.3-7.6,2.2-11.5c-0.1-3.9-1-7.8-2.9-11.2c-1-1.7-2.2-3.2-3.6-4.5c-1.5-1.3-3.2-2.3-5.1-2.8
				c-3.8-1.1-7.5-0.3-10.2,1.3c-2.8,1.6-4.7,4.3-5.4,6.7c-0.8,2.5-0.6,4.7-0.2,6.4c0.5,1.7,1.2,2.9,1.8,3.6c0.6,0.7,1.1,0.9,1.2,0.8
				c0.4-0.3-0.5-1.9-0.7-4.8c0-1.4,0.2-3.2,1-4.9c0.8-1.7,2.2-3.3,4.2-4.2c2-1,4.6-1.3,6.8-0.5c2.3,0.8,4.4,2.7,5.8,5.3
				c1.4,2.6,2.2,5.7,2.4,8.9c0.2,3.2-0.3,6.6-1.4,9.7c-1.2,3.2-3,6-4.8,8.5c-1.9,2.5-3.8,4.8-5.6,7.1c-0.9,1.2-1.7,2.3-2.5,3.5
				c-0.4,0.6-0.7,1.2-1.1,1.8l-0.5,0.9l-0.5,0.9c-0.3,0.6-0.6,1.2-0.9,1.8c-0.3,0.6-0.5,1.2-0.8,1.8c-0.6,1.2-0.9,2.5-1.4,3.7
				c-0.8,2.5-1.4,5.1-1.8,7.8c-0.2,1.3-0.3,2.7-0.4,4.1c0,0.7,0,1.4,0,2.1c0,0.7,0,1.4,0,2.2c0.1,2.9,0.5,6,1.3,9.3
				c0.8,3.2,2,6.6,3.8,10c-1.7-3.5-2.7-6.9-3.4-10.1c-0.6-3.2-0.9-6.3-0.8-9.2c0-0.7,0-1.4,0.1-2.1c0.1-0.7,0.1-1.4,0.2-2.1
				c0.2-1.4,0.3-2.7,0.6-4c0.5-2.6,1.3-5,2.2-7.4c1.9-4.7,4.5-9,8.3-13.1c1.9-2.1,4-4.2,6.1-6.7C169.4,460,171.7,457.1,173.3,453.4z
				"
                />
              </g>
              <g>
                <path
                  d="M154.7,445.2c-2.1,1.9-3.9,4-5.1,5.8c-1.2,1.8-1.8,3.3-1.2,4c0.6,0.7,2.2,0.3,4.2-0.9c1.9-1.2,4.1-3.4,5.6-6.1
				c0.8-1.4,1.3-2.5,1.8-3.6c0.5-1.1,0.9-2.1,1.2-3.2c0.3-1.1,0.5-2.2,0.4-3.5c-0.1-1.2-0.5-2.6-1.7-3.7c0.9,1.3,1,2.6,0.8,3.7
				c-0.2,1.1-0.7,2-1.2,2.7c-0.6,0.8-1.2,1.5-2,2.3C156.6,443.3,155.7,444.2,154.7,445.2z"
                />
              </g>
              <g>
                <path
                  d="M157.3,453.9c-1.7,1.7-3.1,3.6-4,5.2c-0.9,1.6-1.2,3-0.6,3.7c0.6,0.6,2.1,0.3,3.7-0.9c1.6-1.1,3.4-3.1,4.4-5.5
				c1.1-2.4,1.7-4.2,2.2-6c0.6-1.9,1.1-3.7,1.8-6c-1.2,2.3-2.3,3.8-3.5,5.1C160.3,450.9,159.1,452.2,157.3,453.9z"
                />
              </g>
              <g>
                <path
                  d="M187.3,434.6c0.8-2.6,0.7-5.4,0.1-7.4c-0.6-2-1.7-3.1-2.5-2.9c-0.9,0.2-1.4,1.5-1.6,3.3c-0.3,1.8-0.4,4-0.3,6.3
				c0.1,2.4,0,4.2-0.2,5.9c-0.3,1.7-0.7,3.5-1.9,5.9c1.6-2.1,2.7-3.7,3.6-5.2C185.5,438.9,186.5,437.3,187.3,434.6z"
                />
              </g>
              <g>
                <path
                  d="M196,440.1c1.4-0.9,2.5-2.1,3.2-3.2c0.7-1.1,1-2.2,0.6-3c-0.5-0.7-1.7-0.9-3.1-0.4c-1.4,0.5-3,1.8-4,3.6
				c-1,1.8-1.4,3.2-1.8,4.5c-0.4,1.3-0.7,2.6-1.2,4.5c1-1.7,1.9-2.6,2.8-3.5C193.5,441.8,194.5,441.1,196,440.1z"
                />
              </g>
              <g>
                <path
                  d="M204.1,446c1.7-0.2,3.1-0.8,4.2-1.5c1.1-0.7,1.8-1.6,1.7-2.5c-0.1-0.9-1.2-1.5-2.7-1.6c-1.5-0.1-3.4,0.4-5,1.6
				c-1.6,1.2-2.5,2.2-3.5,3.2c-1,1.1-1.9,2.2-3,3.4c1.6-1,2.8-1.5,4-1.8C201.1,446.4,202.3,446.2,204.1,446z"
                />
              </g>
              <g>
                <path
                  d="M255.9,442.9c-2.8,2-5.5,3.9-7.6,5.6c-2,1.6-3.4,3-3,3.8c0.3,0.8,2.4,0.8,5.1-0.2c2.7-1,6-3.1,8.4-6
				c2.5-3,3.9-5.3,5.2-7.6c1.2-2.3,2.4-4.7,3.8-8.3c-1.9,3.4-3.5,5.4-5.3,7.2C260.7,439.2,258.8,440.8,255.9,442.9z"
                />
              </g>
              <g>
                <path
                  d="M258,452.6c-2.4,3.6-3.9,7.7-4.5,10.9c-0.5,3.2-0.2,5.5,0.6,5.6c0.9,0.2,2-1.7,3.4-4.4c1.3-2.7,2.9-6.4,4.3-10.1
				c1.5-3.8,2.8-6.4,4.3-9c1.5-2.5,3.1-5,6.1-8.3c-3.4,2.9-5.5,5-7.6,7.1C262.6,446.7,260.5,448.9,258,452.6z"
                />
              </g>
              <g>
                <path
                  d="M264.1,459.8c-1.9,3.8-3,8-3.2,11.2c-0.3,3.2,0.2,5.4,1,5.5c0.9,0.1,1.9-1.8,3-4.6c1.1-2.9,2.3-6.7,3.3-10.6
				c1-4,1.9-6.8,2.9-9.6c1-2.8,2.2-5.5,4.4-9.3c-2.7,3.5-4.4,6-6.1,8.5C267.7,453.4,266.1,455.9,264.1,459.8z"
                />
              </g>
              <g>
                <path
                  d="M266.4,475.3c-1.1,3.9-1.3,8-0.8,11c0.4,3,1.4,5,2.2,4.9c0.9-0.1,1.5-2,2-4.9c0.5-2.8,0.8-6.6,1-10.3
				c0.2-3.9,0.5-6.6,0.9-9.3c0.5-2.7,1.1-5.5,2.6-9.4c-2,3.6-3.2,6.2-4.4,8.8C268.6,468.7,267.5,471.3,266.4,475.3z"
                />
              </g>
              <g>
                <path
                  d="M332.1,491.8c-2.3,0.4-4.4,1.4-5.7,2.6c-1.3,1.2-1.9,2.4-1.4,3.2c0.5,0.8,1.8,0.9,3.4,0.5c1.6-0.3,3.5-1.1,5.3-2.2
				c1.9-1.2,3.2-2,4.6-2.7c1.4-0.7,2.9-1.4,5.1-2c-2.1,0-3.8,0.1-5.6,0.1C336.1,491.3,334.5,491.4,332.1,491.8z"
                />
              </g>
              <g>
                <path
                  d="M338.2,497.8c-2,0.5-2.5,2.8-1.3,4.1c1.2,1.3,3.4,1.1,4.2-0.8c0.8-1.9,0.9-3,2-4.7C341.2,497.3,340.2,497.3,338.2,497.8z
				"
                />
              </g>
              <g>
                <path d="M432.9,506.6c-3.6,0.1-6.5,2.6-5.9,4.2c0.7,1.6,4.1,1.7,7,0c3-1.8,4.1-2.8,7.6-4C438.1,507,436.6,506.6,432.9,506.6z" />
              </g>
              <g>
                <path
                  d="M152,526.9c-2.4-3.5-5.6-6.8-9.9-9.1c-2.1-1.1-4.4-2-6.8-2.5c-2.4-0.5-5-0.6-7.6-0.1c-5.2,0.9-9.7,3.7-12.6,7.9
				c-0.7,1.1-1.3,2.2-1.8,3.4c-0.5,1.2-0.8,2.5-0.9,3.7c-0.3,2.4,0,4.7,0.7,6.8c0.7,2.1,1.8,4,3.3,5.5c1.5,1.5,3.3,2.6,5,3.2
				c3.6,1.1,6.6,0.4,8.6-0.7c2.1-1.1,3.2-2.7,3.5-3.9c0.2-0.6,0.2-1.1,0.1-1.5c-0.1-0.4-0.3-0.7-0.6-0.8c-0.3-0.2-0.6-0.3-1-0.3
				c-0.4,0-0.8,0-1.2-0.1c-0.8,0-1.8-0.1-2.7-0.4c-0.9-0.3-2-0.9-2.4-1.5c-0.2-0.3-0.3-0.7-0.4-1.1c-0.1-0.4-0.1-0.8-0.1-1.4
				c0-0.5,0.2-1,0.4-1.4c0.1-0.2,0.2-0.4,0.3-0.5c0.1-0.2,0.3-0.4,0.5-0.5c0.4-0.4,0.9-0.7,1.5-1c0.6-0.3,1.3-0.5,2-0.6
				c1.4-0.2,3.4,0.1,5.5,0.9c2,0.8,4.2,2.4,6.2,4.4c1.1,1,2.1,2.2,3,3.4c0.9,1.2,1.8,2.3,2.6,3.5c1.5,2.4,2.8,4.7,3.8,7.1
				c2,4.7,3.3,9.3,3.7,14.1c0.5,4.8,0.2,9.7-1.1,15.2c-1.3,5.4-3.8,11.3-8.4,17.4c5.2-5.5,8.6-11.1,10.9-16.5
				c2.3-5.4,3.7-10.6,4.3-15.9c0.6-5.3,0.5-10.8-0.6-16.8c-0.6-3-1.4-6.1-2.7-9.4c-0.7-1.6-1.4-3.3-2.3-5
				C154.2,530.4,153.2,528.7,152,526.9z"
                />
              </g>
              <g>
                <path
                  d="M180.5,567.9c3.9-1.9,7.3-4.1,9.8-6.3c2.5-2.1,4.1-4.2,3.6-5.9c-0.5-1.6-3.3-2.4-7.1-1.7c-3.7,0.7-8.4,3.2-11.7,7
				c-3.4,3.9-5.2,7-6.8,10c-1.6,3.1-3.1,6.2-4.9,10.7c2.8-4.1,5.3-6.3,7.8-8.3C173.8,571.6,176.5,569.9,180.5,567.9z"
                />
              </g>
              <g>
                <path
                  d="M174.5,542.4c0.4-3.8-1-7.4-2.8-9.6c-1.9-2.2-4.1-3-5.6-2.2c-1.6,0.9-2.2,2.9-2.2,5.3c0,2.4,0.6,5.1,1.9,7.8
				c1.3,2.9,2.2,5,2.8,7.3c0.6,2.3,1,4.7,0.6,8.2c1.4-3.3,2.3-5.6,3.2-8C173.2,548.8,174.1,546.3,174.5,542.4z"
                />
              </g>
              <g>
                <path
                  d="M59.8,573.1c-3.7,3.4-7.3,6.6-11.1,9.2c-3.7,2.5-7.5,4.5-10.6,4.9c-3.2,0.5-5.8-0.6-7.3-1.7c-1.6-1.1-2.1-2.3-2.6-2.2
				c-0.2,0.1-0.3,0.4-0.3,1.1c0.1,0.7,0.4,1.7,1.1,2.7c0.7,1.1,1.9,2.2,3.5,3c1.6,0.8,3.7,1.3,5.9,1.3c2.2-0.1,4.5-0.6,6.7-1.5
				c2.2-0.9,4.3-2.1,6.4-3.4c4.2-2.8,8-6.4,11.4-10.2c3.5-3.9,6.3-7.3,8.9-10.2c2.6-2.9,5.1-5.4,7.9-7.7c2.7-2.2,5.7-4.2,9.4-5.8
				c3.7-1.5,8.2-2.7,13.6-2c-5.3-1-10-0.1-13.9,1.2c-3.9,1.3-7.2,3.1-10.3,5.1c-3.1,2-5.9,4.3-8.9,7
				C66.7,566.4,63.5,569.6,59.8,573.1z"
                />
              </g>
              <g>
                <path
                  d="M73.8,568.7c-1.9,3-3.2,6.4-4,9.7c-0.8,3.3-0.9,6.4-0.8,9.1c0.4,5.4,2.1,8.6,2.9,8.4c0.9-0.2,0.8-3.6,1.3-8.4
				c0.3-2.4,0.7-5.1,1.4-8c0.7-2.9,1.7-5.9,3-8.8c1.4-3,2.8-5.4,4.4-7.4c1.6-2,3.2-3.7,4.9-5.3c1.8-1.5,3.7-3,6-4.3
				c2.3-1.3,5.1-2.5,8.6-3.4c-3.5,0.6-6.4,1.6-9,2.6c-2.5,1.1-4.7,2.2-6.8,3.5c-2.1,1.3-4,2.8-6.1,4.7
				C77.8,563.3,75.8,565.6,73.8,568.7z"
                />
              </g>
              <g>
                <path
                  d="M82.3,565.7c-1.2,2.3-2,4.8-2.4,7.2c-0.4,2.4-0.3,4.7,0,6.6c0.6,3.9,2.2,6.1,3,5.9c0.9-0.2,0.9-2.7,1.2-6
				c0.1-1.7,0.4-3.6,0.7-5.6c0.4-2,0.9-4.2,1.5-6.3c0.7-2.2,1.5-4,2.4-5.5c0.9-1.6,1.8-2.9,2.9-4.2c1.1-1.3,2.3-2.5,3.8-3.7
				c1.5-1.2,3.3-2.3,5.7-3.3c-2.4,0.8-4.4,1.7-6.1,2.6c-1.7,0.9-3.2,1.9-4.6,3c-1.4,1.1-2.7,2.3-4.1,3.8
				C84.9,561.6,83.5,563.4,82.3,565.7z"
                />
              </g>
              <g>
                <path
                  d="M126.8,555.4c3.6,0.7,7.5,0,9.9-1.3c2.5-1.3,3.7-2.8,3.3-3.6c-0.4-0.8-2.2-0.7-4.6-0.4c-2.4,0.3-5.3,0.6-8.5,0.9
				c-3.2,0.2-5.5,0-7.8-0.4c-2.3-0.5-4.6-1.3-7.6-3.4c2.6,2.5,4.7,3.9,6.9,5.1C120.8,553.5,123.1,554.6,126.8,555.4z"
                />
              </g>
              <g>
                <path
                  d="M138.6,563.1c1.7,1.4,3.4,2.4,4.9,3.1c1.5,0.7,2.8,0.9,3.4,0.3c0.6-0.6,0.4-2-0.8-3.5c-1.1-1.5-3.1-3.1-5.5-3.8
				c-2.4-0.7-4.2-0.9-5.9-1c-1.7-0.1-3.4-0.2-5.8-0.2c2.4,0.5,3.9,1.2,5.3,2C135.6,560.8,136.9,561.7,138.6,563.1z"
                />
              </g>
              <g>
                <path
                  d="M141,578.8c0.3,2.1,1,4.1,1.8,5.5c0.8,1.5,1.7,2.4,2.6,2.3c0.9-0.1,1.5-1.4,1.5-3.2c0.1-1.8-0.5-4.2-1.8-6.2
				c-1.3-2.1-2.4-3.4-3.5-4.6c-1.1-1.2-2.2-2.5-3.8-4.2c1.2,2.1,1.7,3.6,2.2,5.2C140.4,575.1,140.7,576.6,141,578.8z"
                />
              </g>
              <g>
                <path
                  d="M143.4,505.7c0.1-2,0.1-3.7-0.1-5.2c-0.2-1.5-0.5-2.7-1.3-3c-0.8-0.3-2,0.5-2.8,2.3c-0.8,1.7-1,4.4-0.2,6.7
				c0.9,2.4,1.9,3.8,3,5.1c1.1,1.3,2.2,2.5,4,4c-1.5-1.8-2-3.4-2.3-4.8C143.4,509.2,143.3,507.7,143.4,505.7z"
                />
              </g>
              <g>
                <path
                  d="M158.8,498.6c0.9-1.8,1.7-3.4,2.3-4.9c0.6-1.4,0.9-2.7,0.4-3.4c-0.5-0.7-2.1-0.5-3.6,0.8c-1.6,1.3-3.1,3.7-3.3,6.4
				c-0.2,2.8,0.3,4.6,0.9,6.3c0.6,1.7,1.4,3.3,2.8,5.3c-1-2.3-1.1-4-0.9-5.5C157.5,501.9,157.9,500.4,158.8,498.6z"
                />
              </g>
              <g>
                <path
                  d="M187.9,576c2.7-0.4,5.2-1.1,7.1-1.9c1.9-0.8,3.2-1.7,3.1-2.6c-0.1-0.9-1.6-1.5-3.8-1.5c-2.2,0-5.1,0.5-7.7,1.8
				c-2.7,1.3-4.4,2.5-6.1,3.6c-1.8,1.2-3.5,2.4-5.8,4c2.7-1.2,4.6-1.8,6.5-2.3C183.2,576.8,185.2,576.4,187.9,576z"
                />
              </g>
              <g>
                <path
                  d="M149,392.3c3,0.1,5.8-1,7.6-2.1c1.8-1.1,2.7-2.4,2.4-3.2c-0.3-0.8-1.9-1-3.9-0.7c-2,0.3-4.4,1-6.7,1.7
				c-2.4,0.8-4.2,1-6,0.9c-1.8-0.1-3.8-0.5-5.9-2.5c1.7,2.3,3.6,3.4,5.4,4.3C143.8,391.6,145.9,392.2,149,392.3z"
                />
              </g>
              <g>
                <path
                  d="M134,321c-1.8,2.8-3.2,6-3.8,9.2c-0.6,3.2-0.5,6.3,0.2,8.9c0.7,2.6,1.8,4.5,2.9,5.6c0.5,0.6,1,0.9,1.4,1.1
				c0.4,0.2,0.7,0.2,0.8,0.1c0.7-0.6-0.7-3.3-1-7.6c-0.1-2.1,0-4.6,0.5-7.2c0.5-2.6,1.5-5.3,2.8-8c1.3-2.8,2.7-5.1,4-7.1
				c1.3-2.1,2.6-3.9,3.9-5.7c1.3-1.9,2.6-3.7,4-5.8c1.4-2.1,2.9-4.5,4.3-7.6c-1.6,2.9-3.4,5.1-5,7.1c-1.6,1.9-3.2,3.5-4.8,5.2
				c-1.6,1.6-3.1,3.2-4.8,5.1C137.7,315.9,135.8,318.1,134,321z"
                />
              </g>
              <g>
                <path
                  d="M143.2,322.4c-0.7,5.5,0.4,11.2,2,15.1c1.6,3.9,3.6,6,4.4,5.6c0.8-0.4,0.3-3.1-0.4-6.8c-0.7-3.8-1.4-8.7-1.7-13.7
				c-0.2-5.2,0.2-8.9,1-12.5c0.8-3.6,2-7.3,5-12.1c-3.4,4.5-5.2,8-6.8,11.5C145.2,313,143.9,316.7,143.2,322.4z"
                />
              </g>
              <g>
                <path
                  d="M149.7,316.6c0,4,1.3,7.8,2.8,10.4c1.5,2.6,3.2,3.9,3.9,3.4c0.8-0.4,0.5-2.4-0.1-5c-0.6-2.6-1.5-6-2.3-9.4
				c-0.9-3.5-1.2-6.1-1.4-8.7c-0.1-2.6,0-5.3,1-9.2c-1.5,3.7-2.3,6.3-2.9,9C150.2,309.8,149.7,312.5,149.7,316.6z"
                />
              </g>
              <g>
                <path
                  d="M93.8,341.5c3.2-2.6,5.7-6,7.1-8.8c1.4-2.8,1.7-5,0.9-5.4c-0.8-0.4-2.4,0.9-4.4,3c-2,2.1-4.4,5-6.8,8.1
				c-2.4,3.1-4.3,5.2-6.3,7.2c-2,2-4.2,3.8-7.8,6.1c3.8-1.8,6.4-3.2,8.9-4.6C88,345.7,90.5,344.2,93.8,341.5z"
                />
              </g>
              <g>
                <path
                  d="M102.3,347.6c2.7-0.3,5.3-1,7.2-1.7c1.9-0.8,3.2-1.7,3.1-2.5c-0.1-0.9-1.6-1.5-3.8-1.6c-2.2-0.1-5.1,0.4-7.7,1.7
				c-2.7,1.3-4.4,2.4-6.2,3.5c-1.8,1.2-3.6,2.3-5.9,3.8c2.7-1.2,4.7-1.7,6.6-2.1C97.6,348.2,99.5,347.9,102.3,347.6z"
                />
              </g>
              <g>
                <path
                  d="M119.4,246.7c1.9-1.6,3.5-3.5,4.5-5.2c1-1.6,1.4-3,0.8-3.7c-0.6-0.6-2.1-0.3-3.8,0.8c-1.7,1.1-3.6,3-4.9,5.4
				c-1.3,2.4-2,4.2-2.8,5.9c-0.8,1.9-1.5,3.7-2.5,6c1.5-2.3,2.8-3.7,4.1-5C116.1,249.6,117.4,248.4,119.4,246.7z"
                />
              </g>
              <g>
                <path
                  d="M20.7,384.4c-7.8,3.2-14.9,7.9-19.7,12.1c-4.8,4.2-7.2,7.7-6.7,8.3c0.6,0.7,4.1-1.6,9.2-5c5.1-3.3,11.9-7.6,19-11.4
				c7.3-3.9,12.7-6.1,18.2-8c5.5-1.8,11.3-3.3,20-4.3c-8.8,0.4-14.7,1.3-20.5,2.4C34.5,379.7,28.7,381.1,20.7,384.4z"
                />
              </g>
              <g>
                <path
                  d="M27.1,386.9c-6.2,3.1-11.7,7.5-15.3,11.3c-3.6,3.8-5.4,6.9-4.8,7.4c0.7,0.6,3.5-1.3,7.5-4.3c4-3,9.3-6.9,14.8-10.6
				c5.7-3.8,9.8-6.1,14.1-8.2c4.3-2,8.9-3.9,15.8-5.7c-7.1,1.2-11.9,2.5-16.5,3.9C38.1,382.1,33.5,383.7,27.1,386.9z"
                />
              </g>
              <g>
                <path
                  d="M-19.8,372.5c-3.2-2.4-6.3-4.7-8.9-6.3c-2.5-1.6-4.5-2.5-5.1-1.9c-0.6,0.6,0.3,2.7,2.4,5.1c2.1,2.4,5.5,5.1,9.3,6.9
				c4,1.8,6.8,2.7,9.6,3.6c2.9,0.8,5.7,1.6,10,2.5c-4.1-1.4-6.8-2.8-9.3-4.2C-14.2,376.7-16.6,375-19.8,372.5z"
                />
              </g>
              <g>
                <path
                  d="M-14.5,384.4c-0.9,0-1.8,0.2-2.7,0.6c-0.9,0.3-1.9,1-2.5,2c-0.6,1-0.6,2.1-0.3,2.7c0.3,0.7,0.9,0.8,1.3,0.7
				c0.4-0.1,0.8-0.4,1.2-0.5c0.4-0.1,0.7-0.1,1-0.1c0.2,0.1,0.6,0,1.2-0.2c0.7-0.2,1.4-0.5,2.1-1c1.4-0.9,2.5-1.5,3.6-2
				c1.1-0.5,2.3-0.9,4.1-1.2c-1.6-0.2-2.9-0.5-4.4-0.7C-11.3,384.6-12.6,384.4-14.5,384.4z"
                />
              </g>
              <g>
                <path
                  d="M-13.6,395.2c-2.1,1.6-3.6,3.7-4.4,5.6c-0.7,1.8-0.7,3.3,0,3.8c0.8,0.5,2-0.2,3.4-1.4c1.4-1.2,2.9-3,4.1-5
				c1.3-2.1,2.3-3.5,3.4-4.9c1.1-1.4,2.3-2.7,4.3-4.4c-2.3,1.2-4,2-5.6,2.9C-9.8,392.7-11.4,393.6-13.6,395.2z"
                />
              </g>
              <g>
                <path d="M-9.5,421.5c-1.3,2.9-0.3,6,1.4,6.3c1.7,0.2,3.5-2.6,2.9-5.7c-0.5-3.2-1.1-4.4-1.2-7.7C-7.4,417.5-8.2,418.6-9.5,421.5z" />
              </g>
              <g>
                <path d="M-2.1,415.6c-1.2,2.5-0.2,5.3,1.5,5.5c1.7,0.2,3.4-2.2,2.8-4.9c-0.6-2.8-1.2-3.9-1.4-6.7C0,412.2-0.9,413.1-2.1,415.6z" />
              </g>
              <g>
                <path
                  d="M578.1,305.9c3.3-4.2,6.4-8.7,8.9-13.6c2.5-4.9,4.2-10.3,4.9-15.7c0.7-5.4,0.4-10.9-1.2-15.8c-0.4-1.2-0.8-2.4-1.3-3.6
				c-0.5-1.2-1.1-2.3-1.8-3.4c-1.4-2.2-3.1-4.2-5.3-5.7c-1.1-0.8-2.3-1.3-3.6-1.8c-1.3-0.4-2.7-0.6-4-0.5c-2.5,0.1-4.9,0.9-6.8,2.5
				c-0.9,0.8-1.7,1.7-2.3,2.7c-0.6,1-0.8,2.1-1,3.1c-0.2,2,0.2,3.7,0.7,5c1.1,2.5,2.5,3.9,3.5,4.8c1,0.9,1.7,1.1,2.1,0.9
				c0.4-0.2,0.3-1,0.1-2.2c-0.2-1.2-0.8-2.9-1-4.7c-0.1-0.9,0-1.8,0.3-2.5c0.2-0.3,0.4-0.7,0.6-0.9c0.2-0.2,0.5-0.4,0.9-0.6
				c0.7-0.3,1.7-0.5,2.7-0.3c0.5,0.1,1,0.2,1.4,0.4c0.4,0.2,0.9,0.6,1.4,1c0.9,0.8,1.8,2,2.5,3.4c0.7,1.4,1.3,3.2,1.8,5
				c0.9,3.6,1,7.7,0.4,12c-0.6,4.2-1.9,8.6-3.8,12.8c-1.9,4.3-4.4,8.5-7.2,12.6c-2.9,4.3-5.8,8.2-8.5,11.9
				c-2.7,3.7-5.2,7.3-7.5,10.8c-2.2,3.5-4.2,7-5.8,10.6c-1.6,3.6-2.8,7.2-3.7,10.9c-1.7,7.4-2.1,15.1-0.8,23.5
				c0.6,4.2,1.7,8.5,3.4,13c1.7,4.4,4,9,7.3,13.5c-3-4.6-5.1-9.3-6.5-13.7c-1.4-4.5-2.1-8.8-2.4-12.9c-0.6-8.2,0.5-15.5,2.8-22.3
				c1.2-3.4,2.6-6.6,4.4-9.7c1.8-3.1,4-6.2,6.4-9.3c2.4-3.1,5.2-6.3,8.2-9.7C571.4,313.8,574.7,310.2,578.1,305.9z"
                />
              </g>
              <g>
                <path
                  d="M561.9,353.7c1-1.7,2-3.3,3.1-4.8c1.1-1.5,2.2-2.8,3.4-4.1c1.1-1.3,2.4-2.4,3.5-3.4c0.3-0.2,0.6-0.5,0.9-0.7
				c0.3-0.2,0.5-0.4,0.8-0.6c0.5-0.4,1.1-0.7,1.6-1c2.2-1.2,4-1.8,5.4-2c1.5-0.3,2.7-0.1,3.3-0.7c0.3-0.3,0.4-0.8,0.2-1.5
				c-0.2-0.7-0.8-1.5-1.9-2.3c-1-0.8-2.5-1.4-4.3-1.6c-1.7-0.2-3.7-0.1-5.6,0.3c-1,0.2-2,0.6-3,1c-0.5,0.2-1,0.5-1.5,0.7
				c-0.5,0.3-0.9,0.5-1.4,0.8c-1.8,1.1-3.7,2.5-5.3,4.2c-1.6,1.7-3.1,3.6-4.4,5.6c-1.2,2.1-2.2,4.3-3,6.5c-1.6,4.7-2.1,8.7-2.3,12.3
				c-0.2,3.6,0,6.8,0.3,9.9c0.7,6.3,1.9,12.4,3.8,21.4c-0.9-9.1-0.8-15.4,0-21.4c0.4-3,1-5.9,1.9-9
				C558.6,360.4,559.9,357.1,561.9,353.7z"
                />
              </g>
              <g>
                <path
                  d="M574.5,279.9c-0.6-1.9-1.5-3.7-2.6-5.3c-0.6-0.8-1.2-1.6-1.9-2.3c-0.7-0.7-1.5-1.3-2.3-1.9c-1.7-1.1-3.9-1.8-6.2-1.5
				c-1.1,0.2-2.1,0.5-3,1.1c-0.9,0.6-1.6,1.4-2.1,2.4c-0.4,0.9-0.6,1.9-0.6,2.8c0,0.9,0.2,1.6,0.5,2.3c0.5,1.3,1.2,2.1,1.8,2.7
				c1.2,1.2,2.1,1.6,2.4,1.4c0.4-0.3,0.1-1.2-0.4-2.6c-0.2-0.7-0.5-1.5-0.6-2.4c-0.1-0.9,0.1-1.5,0.5-2c0.4-0.4,1.1-0.8,2-0.9
				c0.8-0.1,1.9,0.3,2.9,1c1.1,0.7,2.1,1.8,3.1,2.9c0.9,1.2,1.7,2.5,2.4,3.9c1.3,3,1.8,5.9,1.9,8.5c0.1,2.6-0.2,5.1-0.8,7.5
				c-1.1,4.8-3.2,9.6-6.8,16.2c4.1-6.4,6.7-10.8,8.6-15.6c0.9-2.4,1.7-4.9,2-7.9C575.6,287.2,575.6,283.8,574.5,279.9z"
                />
              </g>
              <g>
                <path
                  d="M568.9,290c-0.3-0.5-0.5-1.1-0.8-1.6c-0.3-0.5-0.7-1.1-1.2-1.5c-0.9-1-1.9-1.8-3.4-2.3c-0.7-0.3-1.5-0.4-2.3-0.4
				c-0.8,0-1.6,0.3-2.3,0.6c-1.4,0.7-2.2,1.8-2.6,2.8c-0.5,1-0.6,2-0.6,2.7c0,0.8,0.1,1.4,0.3,1.9c0.3,1,0.7,1.5,1.2,1.5
				c0.4,0,0.9-0.5,1.3-1.4c0.4-0.8,0.9-2,1.5-2.7c0.3-0.4,0.7-0.6,1-0.7c0.3,0,0.5,0,0.9,0.1c0.4,0.2,1,0.5,1.6,1.1
				c0.3,0.3,0.6,0.5,0.8,0.9c0.3,0.3,0.5,0.7,0.8,1.1c1.1,1.5,1.8,3,2.3,4.5c0.5,1.4,0.8,2.8,0.9,4.2c0.1,1.4,0.1,2.9-0.3,4.5
				c-0.3,1.6-0.9,3.4-2.1,5.3c1.4-1.7,2.3-3.4,3-5c0.7-1.6,1.1-3.1,1.3-4.7c0.2-1.6,0.4-3.1,0.2-4.9
				C570.3,294.2,569.9,292.2,568.9,290z"
                />
              </g>
              <g>
                <path
                  d="M548.8,301.3c-1.9-0.3-3.8-0.3-5.2,0c-1.5,0.3-2.6,0.9-2.7,1.8c-0.2,0.9,0.7,1.8,2.3,2.4c1.6,0.6,3.8,0.8,5.9,0.2
				c2.2-0.6,3.6-1.2,5-1.9c1.5-0.7,2.9-1.4,4.7-2.2c-2.1,0.4-3.6,0.5-5.1,0.4C552.1,301.9,550.7,301.7,548.8,301.3z"
                />
              </g>
              <g>
                <path
                  d="M536.3,308.9c-5.8,0.2-11.1,2.8-10.6,4.5c0.4,1.7,6.2,1.7,11.5-0.2c5.5-1.9,7.5-3,13.4-4.3
				C544.5,309.2,542.3,308.8,536.3,308.9z"
                />
              </g>
              <g>
                <path
                  d="M525.6,316.8c-3.9-0.7-7.6,0.8-7.6,2.5c0.1,1.8,4,2.9,7.8,1.8c3.9-1.1,5.3-1.9,9.3-2.6
				C531,318.2,529.6,317.5,525.6,316.8z"
                />
              </g>
              <g>
                <path
                  d="M537.6,319c-2.2,1.1-3.9,2.9-4.8,4.6c-0.9,1.6-1.1,3.1-0.4,3.6c0.7,0.6,2,0.2,3.4-0.7c1.5-0.9,3.1-2.3,4.5-4
				c1.5-1.7,2.6-2.9,3.8-4.1c1.2-1.1,2.5-2.2,4.6-3.4c-2.4,0.7-4,1.3-5.6,1.8C541.5,317.3,539.9,317.8,537.6,319z"
                />
              </g>
              <g>
                <path
                  d="M545.3,322.1c-1.5,1.4-2.5,3.1-2.9,4.6c-0.4,1.5-0.2,2.7,0.6,3.2c0.8,0.4,1.9,0,3-1c1.1-1,2.2-2.5,2.9-4.3
				c1.5-3.7,1.8-5.3,4.1-8.8C549.9,318.6,548.4,319.3,545.3,322.1z"
                />
              </g>
              <g>
                <path
                  d="M554.7,308c-1.3,0.9-2.1,2.2-2.4,3.4c-0.3,1.2,0,2.2,0.7,2.7c0.7,0.5,1.8,0.3,2.7-0.3c1-0.6,1.9-1.7,2.4-3
				c1.1-2.8,1.3-4.1,3.2-6.7C558.7,305.8,557.4,306.2,554.7,308z"
                />
              </g>
              <g>
                <path
                  d="M532.3,341c-1.2,1.5-1.8,3.3-1.8,4.8c0,1.5,0.5,2.5,1.4,2.7c0.9,0.2,1.8-0.4,2.6-1.5c0.8-1,1.5-2.5,1.8-4.2
				c0.3-1.7,0.5-3,0.9-4.2c0.3-1.2,0.8-2.4,1.7-4.1c-1.4,1.3-2.3,2.2-3.3,3.1C534.5,338.6,533.5,339.5,532.3,341z"
                />
              </g>
              <g>
                <path
                  d="M533.2,356.5c-2.1,3.9-1.8,8.5-0.1,8.9c1.7,0.4,3.9-3.4,4.3-7.6c0.4-4.4,0.2-6.1,1.4-10.5
				C536.7,351.3,535.4,352.5,533.2,356.5z"
                />
              </g>
              <g>
                <path
                  d="M533.9,379.3c-0.4,2.4,0.2,4.9,1,6.6c0.8,1.7,2,2.6,2.8,2.3c0.8-0.3,1.2-1.6,1.3-3.2c0.1-1.7-0.2-3.7-0.7-5.8
				c-0.5-2.2-0.8-3.7-1-5.4c-0.2-1.6-0.2-3.3,0.2-5.7c-0.9,2.3-1.5,3.8-2.1,5.5C534.8,375.2,534.2,376.8,533.9,379.3z"
                />
              </g>
              <g>
                <path
                  d="M570.2,359.3c1.9-2.2,3.7-4.2,5-6c1.3-1.7,2.2-3.2,1.7-3.9c-0.5-0.7-2.3-0.5-4.4,0.8c-2.2,1.3-4.6,3.7-6,6.8
				c-1.4,3.1-1.9,5.4-2.3,7.6c-0.4,2.2-0.6,4.5-0.6,7.8c0.5-3.2,1.4-5.3,2.4-7.2C567.1,363.2,568.3,361.5,570.2,359.3z"
                />
              </g>
              <g>
                <path
                  d="M580.7,319.9c1.7-0.7,3-1.7,4-2.7c0.9-1,1.4-2.1,1.1-2.9c-0.4-0.8-1.6-1.1-3.1-0.8c-1.5,0.3-3.3,1.3-4.6,2.8
				c-1.3,1.6-2.1,2.8-2.8,4c-0.8,1.3-1.5,2.6-2.4,4.1c1.4-1.4,2.6-2.1,3.8-2.7C577.8,321.1,579,320.6,580.7,319.9z"
                />
              </g>
              <g>
                <path
                  d="M-6.5,258.1c1.9-0.4,3.5-1.1,4.7-2c1.2-0.9,1.9-1.9,1.6-2.7c-0.2-0.8-1.4-1.3-3-1.2c-1.6,0.1-3.5,0.7-5.2,2
				c-1.7,1.3-2.7,2.3-3.8,3.3c-1,1-2.1,2-3.7,3.4c1.9-0.9,3.2-1.4,4.6-1.7C-9.8,258.7-8.5,258.5-6.5,258.1z"
                />
              </g>
              <g>
                <path
                  d="M8.4,246.2c-1.5,2.6-2.1,5.6-2,7.8c0.1,2.3,0.8,3.7,1.7,3.8c0.9,0.1,1.7-1.2,2.5-3.1c0.8-1.9,1.5-4.3,2-6.9
				c0.5-2.7,1.1-4.5,1.7-6.4c0.7-1.8,1.5-3.7,3.3-6.1c-2.1,2-3.5,3.5-4.9,5.1C11.2,242,9.9,243.6,8.4,246.2z"
                />
              </g>
              <g>
                <path
                  d="M544.8,223.7c-1.8-1.5-3.8-2.7-6-3.5c-2.1-0.9-4.5-1.4-6.9-1.4c-1.2,0-2.4,0.2-3.5,0.6c-1.2,0.4-2.3,0.9-3.2,1.6
				c-1.9,1.4-3.2,3.3-3.7,5.3c-0.3,1-0.3,2-0.2,3.1c0.2,1,0.5,1.8,0.9,2.5c0.9,1.4,2.1,2.3,3.1,2.7c1.1,0.4,2,0.4,2.6,0.2
				c0.6-0.2,0.8-0.4,0.8-0.6c-0.1-0.5-1.1-0.6-2.2-1.7c-1.1-1-2.1-3.1-1.2-5c0.4-1,1.3-2,2.4-2.7c0.6-0.4,1.2-0.6,1.9-0.8
				c0.7-0.2,1.5-0.3,2.2-0.3c3.2,0,7,1.4,10.2,3.5c3.3,2.2,5.7,4.8,7.4,7.5c0.9,1.3,1.6,2.7,2.2,4c0.6,1.4,1.1,2.7,1.5,4.1
				c0.8,2.8,1.2,5.8,1.2,9.2c0,3.4-0.4,7.1-1.6,11.5c1.4-4.3,2.2-8,2.5-11.5c0.3-3.4,0.2-6.5-0.3-9.6c-0.3-1.5-0.6-3.1-1-4.6
				c-0.5-1.5-1.1-3.1-1.9-4.7C550.7,230.1,548.4,226.7,544.8,223.7z"
                />
              </g>
              <g>
                <path
                  d="M547.9,236.8c-0.9-1.3-2.2-2.4-3.6-3.2c-1.5-0.8-3.3-1.2-5-1c-1.8,0.2-3.4,1.2-4.5,2.4c-1.1,1.2-1.7,2.9-1.6,4.4
				c0.1,1.7,1,2.9,1.8,3.6c0.9,0.7,1.9,1.1,2.7,1.1c0.8,0,1.4-0.2,1.8-0.5c0.3-0.3,0.4-0.6,0.3-0.8c-0.2-0.4-0.9-0.6-1.4-1.1
				c-0.3-0.3-0.6-0.6-0.8-1.1c-0.2-0.4-0.3-0.9-0.1-1.2c0.1-0.4,0.4-0.8,0.8-1.2c0.5-0.4,1.1-0.7,1.7-0.7c1.3-0.2,3.2,0.6,4.7,2
				c1.6,1.5,2.6,3.5,3.6,5.3c0.9,1.8,1.7,3.4,2.3,5.1c0.6,1.7,1.2,3.5,1.6,5.5c0.4,2,0.7,4.4,0.8,7.1c0.2-2.8,0.2-5.1,0.1-7.2
				c-0.1-2.1-0.3-4-0.6-5.9c-0.3-1.9-0.7-3.7-1.3-5.8c-0.3-1-0.6-2.1-1.1-3.3C549.5,239.4,548.9,238.1,547.9,236.8z"
                />
              </g>
              <g>
                <path
                  d="M613.1,514.1c0.5,1.2,0.7,2.4,0.8,3.5c0,1.1-0.1,2-0.4,2.7c-0.3,0.7-0.7,1.4-1.3,2.1c-0.6,0.6-1.4,1.3-2.4,1.7
				c-1.9,1-4,1.4-6.1,1.3c-2-0.1-3.6-0.8-4.7-1.5c-1.2-0.9-2-2.1-2.3-3.2c-0.4-1-0.3-2.3,0.1-3.4c0.4-1.1,0.9-2,1.4-2.8
				c0.5-0.8,0.9-1.4,0.9-2.1c0-0.6-0.6-1.3-1.9-1.6c-1.3-0.3-3.3,0-5.5,1.3c-1.1,0.7-2.1,1.6-3.1,2.9c-0.5,0.7-0.9,1.4-1.2,2.2
				c-0.4,0.8-0.6,1.8-0.8,2.7c-0.7,3.8,0.2,8.2,2.9,11.9c2.8,3.9,7.2,6.5,12,7.4c4.7,1,10.1,0.5,14.9-1.9c2.4-1.2,4.7-2.8,6.7-5
				c2-2.2,3.6-4.9,4.4-8c0.8-3,0.8-6.2,0.3-9c-0.6-2.9-1.7-5.4-3.1-7.7c-1.5-2.3-3.3-4.3-5.2-5.9c-1.9-1.6-4.1-2.9-6.1-3.7
				c-4.1-1.7-8-2.3-11.5-2.4c-7.1-0.1-12.9,1.4-18.6,3.1c-5.6,1.8-11.1,4.1-16.8,7.4c-5.7,3.2-11.6,7.4-17.6,13.4
				c6.6-5.4,12.9-8.7,18.8-11c5.9-2.3,11.4-3.5,16.8-4.2c5.4-0.6,11-0.7,16.3,0.5c2.7,0.6,5.3,1.6,7.5,3.1c1.2,0.8,2,1.6,2.8,2.6
				C611.9,511.7,612.6,512.9,613.1,514.1z"
                />
              </g>
              <g>
                <path
                  d="M586.9,487.9c2.8-1.8,5.2-4.2,7.1-6.8c1.9-2.6,3.2-5.3,3.9-7.8c0.7-2.5,0.8-4.7,0.6-6.3c-0.3-1.6-0.9-2.5-1.8-2.7
				c-1.8-0.3-3.7,2.1-6.3,5.3c-1.3,1.7-2.7,3.5-4.3,5.4c-1.6,2-3.3,4.1-5.1,6.3c-1.8,2.3-3.6,4.1-5.2,5.7c-1.7,1.6-3.2,3.1-4.9,4.4
				c-3.3,2.7-6.9,5.3-12.6,8.5c6.1-2.3,10.3-3.7,14.5-5.2c2.1-0.8,4.2-1.6,6.5-2.7C581.6,491.1,584.1,489.8,586.9,487.9z"
                />
              </g>
              <g>
                <path
                  d="M574,520.4c-1.2,2.3-1.3,5.1-0.8,7c0.5,1.9,1.5,3,2.4,2.8c0.9-0.2,1.4-1.4,1.8-3c0.4-1.6,0.7-3.5,0.8-5.6
				c0.2-2.2,0.5-3.7,1-5.3c0.5-1.5,1.3-3,2.9-4.9c-2,1.4-3.3,2.6-4.5,3.9C576.4,516.6,575.3,518,574,520.4z"
                />
              </g>
              <g>
                <path
                  d="M580.2,543.9c0.4,2.6,1.7,5,3.2,6.4c1.5,1.4,2.9,1.9,3.6,1.3c0.7-0.6,0.5-2,0-3.6c-0.5-1.6-1.4-3.6-2.5-5.5
				c-1.1-2-1.8-3.6-2.3-5.2c-0.5-1.6-0.9-3.3-0.8-5.9c-0.6,2.5-0.9,4.3-1.1,6.1C580,539.4,579.8,541.2,580.2,543.9z"
                />
              </g>
              <g>
                <path
                  d="M599.3,558c1.3,1.4,3,2.4,4.7,2.8c1.7,0.5,3.4,0.5,4.8,0.2c1.4-0.3,2.4-0.9,3-1.4c0.6-0.6,0.8-1.1,0.7-1.5
				c-0.4-0.8-2-0.9-3.9-1.2c-2-0.3-4.3-0.9-6.5-2.2c-1.2-0.7-2.1-1.5-2.9-2.3c-0.8-0.8-1.4-1.7-2-2.6c-1.2-1.8-2.1-3.9-3.1-7.1
				c0.5,3.3,0.8,5.6,1.4,7.8c0.3,1.1,0.6,2.3,1.2,3.5C597.2,555.2,598,556.6,599.3,558z"
                />
              </g>
              <g>
                <path
                  d="M613.7,553.2c2.2,1.1,4.7,1.3,6.5,1c1.8-0.3,3-1.2,2.9-2.1c-0.1-0.9-1.2-1.6-2.7-2.1c-1.5-0.5-3.5-0.9-5.5-1
				c-2.2-0.2-3.7-0.4-5.2-0.7c-1.5-0.4-3.1-0.9-5.1-2.2c1.7,1.7,2.9,2.8,4.2,3.9C610.1,551,611.5,552.1,613.7,553.2z"
                />
              </g>
              <g>
                <path
                  d="M623.6,545.9c1.5,0.1,2.9-0.6,3.8-1.4c0.8-0.8,1.1-1.9,0.7-2.6c-0.4-0.8-1.3-1.2-2.4-1.2c-1,0-2.2,0.3-3.3,1
				c-1.1,0.8-1.9,1.3-2.8,1.8c-0.9,0.5-1.8,0.9-3.2,1.2c1.4,0.3,2.5,0.5,3.5,0.7C620.9,545.6,622,545.8,623.6,545.9z"
                />
              </g>
              <g>
                <path
                  d="M584.7,454.4c0.8-4,0.4-8.1-0.6-11.1c-0.9-2.9-2.3-4.6-3.1-4.4c-0.9,0.3-1.1,2.3-1,5c0,2.8,0.2,6.4,0.3,10.1
				c0.1,3.8,0,6.5-0.4,9.1c-0.4,2.7-1,5.4-2.7,9.1c2.2-3.5,3.4-6,4.5-8.6C582.9,461.2,583.9,458.5,584.7,454.4z"
                />
              </g>
              <g>
                <path
                  d="M175.8,236.9c-0.3,3.3,0.7,6.7,2.2,8.7c1.4,2.1,3.1,2.9,3.7,2.4c0.7-0.5,0.5-2.1,0.1-4.1c-0.5-2.3-1-4.4-1.6-7.2
				c-0.5-2.8-0.6-4.8-0.4-6.9c0.2-2,0.7-4.2,2.3-6.9c-2.1,2.4-3.2,4.4-4.2,6.4C177,231.3,176.2,233.5,175.8,236.9z"
                />
              </g>
              <g>
                <path
                  d="M316.4,56.6c3.3,0.8,6.5,1.5,9,2c2.5,0.5,4.4,0.7,4.8-0.1c0.4-0.7-0.8-2.4-3.3-3.8c-2.5-1.5-6.3-2.6-10.1-2.5
				c-3.9,0.1-6.5,0.7-9.1,1.5c-2.5,0.8-5,1.6-8.5,3.4c3.6-1.2,6.2-1.4,8.7-1.5C310.5,55.6,313,55.9,316.4,56.6z"
                />
              </g>
              <g>
                <path
                  d="M166.4,149.7c1.4,3.4,3.9,6.3,6.1,7.9c2.2,1.7,4.1,2.1,4.7,1.5c0.6-0.7-0.3-2.3-1.7-4.4c-1.4-2.1-3.3-4.6-5.3-7.2
				c-2.1-2.7-3.3-4.7-4.4-6.9c-1.1-2.2-2-4.5-2.6-8.1c0.1,3.7,0.4,6.2,0.8,8.7C164.4,143.7,165,146.2,166.4,149.7z"
                />
              </g>
              <g>
                <path
                  d="M79.1,161.1c1.1-2.5,1.1-5.4,0.4-7.4c-0.7-2-1.8-3-2.6-2.8c-0.9,0.3-1.3,1.6-1.5,3.2c-0.3,1.7-0.4,3.7-0.5,5.9
				c-0.1,2.3-0.4,3.9-0.9,5.5c-0.5,1.6-1.3,3.2-3.1,5.1c2.1-1.5,3.4-2.8,4.7-4.2C76.7,165.2,77.9,163.7,79.1,161.1z"
                />
              </g>
              <g>
                <path
                  d="M340.8,260.3c3.5-6.8,4.5-14.6,2.8-15.1c-1.7-0.5-5.1,6.3-6.9,13.5c-0.9,3.8-1.5,6.4-2.2,9c-0.7,2.6-1.5,5.2-2.9,9
				c1.9-3.5,3.3-5.9,4.7-8.3C337.6,266.1,339,263.8,340.8,260.3z"
                />
              </g>
              <g>
                <path
                  d="M485,235.3c3.3,0,6.5-0.3,9-0.8c2.4-0.5,4.1-1.2,4.2-2.1c0-0.9-1.7-1.7-4.2-2.1c-2.6-0.4-6.1-0.3-9.4,0.6
				c-3.4,0.9-5.7,1.8-8,2.7c-2.4,1-4.7,1.9-7.8,3.1c3.5-0.8,5.9-1.1,8.3-1.3C479.3,235.3,481.6,235.3,485,235.3z"
                />
              </g>
              <g>
                <path
                  d="M596.5,141.5c-2.7,0.3-5.3,1.3-7,2.5c-1.7,1.2-2.6,2.4-2.2,3.2c0.4,0.8,1.9,0.9,3.8,0.6c1.9-0.3,4.3-1,6.5-2.1
				c2.4-1.1,4-1.9,5.8-2.5c1.7-0.7,3.5-1.2,6.3-1.7c-2.6-0.1-4.5-0.1-6.6-0.2C601.2,141.3,599.3,141.2,596.5,141.5z"
                />
              </g>
              <g>
                <path
                  d="M548.5,79c-2.4,1.8-4.6,3.6-6.2,5.2c-1.6,1.6-2.6,2.9-2.2,3.7c0.4,0.8,2.2,0.8,4.5-0.2c2.3-1,5.1-3,7.1-5.6
				c2-2.7,3.1-4.8,4-6.9c0.9-2.1,1.8-4.2,2.9-7.4c-1.5,3-3,4.7-4.5,6.3C552.6,75.7,550.9,77.1,548.5,79z"
                />
              </g>
              <g>
                <path
                  d="M482.8,101.2c3.5-0.6,7-1.5,9.5-2.4c2.6-0.9,4.3-1.9,4.2-2.8c-0.1-0.9-2.1-1.4-4.9-1.3c-2.8,0.1-6.6,0.8-10,2.3
				c-3.6,1.5-5.9,2.8-8.2,4.1c-2.3,1.3-4.6,2.7-7.9,4.7c3.6-1.5,6.1-2.3,8.6-2.9C476.6,102.3,479.2,101.9,482.8,101.2z"
                />
              </g>
              <g>
                <path
                  d="M503.3,526.1c2.5-1.7,3.3-4.9,2-6c-1.4-1.1-4.3,0.4-5.4,3.3c-1.2,2.9-1.3,4.3-2.8,7.1
				C499.4,528.3,500.7,527.9,503.3,526.1z"
                />
              </g>
              <g>
                <path
                  d="M281.3,518.4c2,0.3,3.8,0.4,5.3,0.2c1.5-0.1,2.7-0.5,3-1.3c0.3-0.8-0.5-2-2.2-2.8c-1.6-0.8-4.1-1.2-6.5-0.5
				c-2.4,0.7-3.9,1.5-5.3,2.3c-1.4,0.9-2.8,1.8-4.6,3.2c2.1-1,3.6-1.3,5.1-1.4C277.8,518,279.3,518.1,281.3,518.4z"
                />
              </g>
            </g>
          </g>
          <g id="Peddle14">
            <defs>
              <path
                id="SVGID_00000156554892325812931150000007346138116813358487_"
                d="M159.4,455.4c18.4-44.3,125-221.9,159.4-207.6
			c34.3,14.3-16.7,215.1-35.1,259.3c-18.4,44.3-78.8,98-108.5,85.6C145.3,580.4,140.9,499.6,159.4,455.4z"
              />
            </defs>
            <use
              xlinkHref="#SVGID_00000156554892325812931150000007346138116813358487_"
              overflow="visible"
              fill="#003301"
            />
            <clipPath id="SVGID_00000050659091802175668270000004416120610202422708_">
              <use
                xlinkHref="#SVGID_00000156554892325812931150000007346138116813358487_"
                overflow="visible"
              />
            </clipPath>
            <g
              opacity="0.1"
              clipPath="url(#SVGID_00000050659091802175668270000004416120610202422708_)"
            >
              <g>
                <g>
                  <path
                    d="M312.1,219.6c8.1-1.2,15.4-3.8,20.8-7c5.4-3.2,8.8-6.9,8.1-10.4c-0.7-3.4-5.5-5.7-12.2-5.8c-6.7-0.1-15.4,2-22.9,6.8
					c-7.7,4.9-12.4,9-17.2,13c-4.7,4-9.4,8-16.3,13.7c8.1-3.8,13.9-5.6,19.8-6.9C297.9,221.7,303.8,220.8,312.1,219.6z"
                  />
                </g>
                <g>
                  <path
                    d="M314.5,239.9c7,1.8,13.9,2.1,19.5,1.1c5.5-1,9.7-3.2,10.5-6.6c0.8-3.4-2.4-7.3-8.1-9.9c-5.6-2.6-13.8-3.8-21.8-2.1
					c-8.2,1.8-13.5,3.9-18.8,6c-5.3,2.1-10.5,4.2-18,7.1c8-0.8,13.5-0.4,18.9,0.5C302,236.8,307.2,238.1,314.5,239.9z"
                  />
                </g>
                <g>
                  <path
                    d="M303.9,258.7c4.2,3.4,8.8,5.1,13,5.4c4.2,0.3,8.1-0.9,10-3.9c1.9-3,1.1-7.2-2.1-10.9c-3.1-3.7-8.6-6.8-14.9-7.1
					c-6.5-0.4-10.9,0.2-15.2,0.7c-4.3,0.5-8.6,1-14.6,1.5c5.8,1.7,9.5,3.7,12.9,5.9C296.4,252.6,299.5,255.2,303.9,258.7z"
                  />
                </g>
                <g>
                  <path
                    d="M282.6,276.1c1.5,4.7,4.5,8,8,9.7c3.4,1.7,7.3,1.9,10.2-0.1c2.9-2,4.1-5.7,3.3-9.8c-0.7-4-3.4-8.3-7.9-11
					c-4.6-2.8-8.1-4.2-11.5-5.8c-3.4-1.6-6.6-3.2-10.9-6.1c2.8,4.3,4.2,7.7,5.3,11.2C280.2,267.7,281.1,271.2,282.6,276.1z"
                  />
                </g>
                <g>
                  <path
                    d="M262,284.1c-1.5,5-1.3,9.6,0.1,13.5c1.3,3.8,3.8,7,7.3,7.6c3.4,0.7,7.1-1.5,9.3-5.8c2.2-4.2,3-10.5,0.8-16.4
					c-2.3-6-4.6-9.7-6.8-13.4c-2.3-3.6-4.5-7.1-7.6-12.2c1,5.8,0.8,9.9,0.1,13.8C264.5,275.2,263.5,279,262,284.1z"
                  />
                </g>
                <g>
                  <path
                    d="M238.4,276.8c-4.1,3.3-6.7,7-8.2,10.8c-0.3,1-0.7,1.9-0.9,2.9c-0.2,1-0.3,1.9-0.3,2.8c0,1.8,0.4,3.6,1.4,5
					c1,1.4,2.6,2.3,4.6,2.6c1,0.1,2.1,0.1,3.2-0.1c1.1-0.2,2.4-0.5,3.5-1.1c4.8-2.1,9.8-7.2,11.7-13.9c1.9-6.8,2-11.6,2-16.2
					c-0.1-4.6-0.3-9.1-0.8-15.3c-1.6,6-3.8,9.7-6.4,12.9C245.6,270.5,242.6,273.3,238.4,276.8z"
                  />
                </g>
                <g>
                  <path
                    d="M221.4,254.8c-5.9,0.7-10.8,2.7-14.6,5.4c-3.7,2.7-6.2,6.2-5.7,9.7c0.5,3.5,4.2,6.1,9.6,6.6c5.3,0.5,12.3-1.2,17.7-5.6
					c5.6-4.6,8.8-8.3,11.8-12c3-3.7,5.9-7.3,10.2-12.6c-5.6,3.6-10,5.2-14.3,6.3C231.8,253.5,227.4,254.1,221.4,254.8z"
                  />
                </g>
                <g>
                  <path
                    d="M214.7,232.3c-5.8-1.9-11.2-2.4-15.9-1.9c-1.2,0.2-2.3,0.3-3.3,0.7c-1.1,0.3-2,0.7-2.9,1.1c-1.7,0.9-3.1,2.2-3.7,3.8
					c-0.6,1.6-0.4,3.5,0.5,5.3c0.5,0.9,1.2,1.9,2,2.7c0.9,0.9,1.9,1.8,3.1,2.5c4.8,3.1,12.4,4.8,19.7,3.2c7.5-1.7,12.3-4,16.9-6.2
					c4.5-2.3,9-4.7,15-8.3c-6.9,1.6-11.7,1.4-16.4,0.8C225.1,235.5,220.6,234.3,214.7,232.3z"
                  />
                </g>
                <g>
                  <path
                    d="M221.6,214.1c-3.8-4.1-7-7.1-10.2-9.4c-1.6-1.2-3.2-2.2-4.8-3.1c-1.6-0.9-3.3-1.6-5.1-1.4c-1.7,0.1-3.3,1.2-4.4,3.3
					c-1.1,2.1-1.4,5.3-0.6,8.8c0.8,3.5,2.7,7.3,5.7,10.4c2.9,3.1,6.8,5.6,11,6.8c4.4,1.3,7.9,1.6,11.1,1.7c3.2,0.1,5.9-0.1,8.6-0.4
					c5.4-0.6,10.5-1.4,17.9-2.1c-7.3-1.5-12.2-3.2-16.6-5.4c-2.2-1.1-4.3-2.4-6.4-3.9C225.6,217.9,223.5,216.2,221.6,214.1z"
                  />
                </g>
                <g>
                  <path
                    d="M239.5,196.3c-0.1-5.5-0.9-9.8-2.3-13.6c-0.7-1.9-1.5-3.7-2.5-5.3c-1-1.6-2.2-2.9-3.9-3.6c-1.6-0.7-3.5-0.5-5.4,0.6
					c-1.9,1.1-3.7,3.3-4.9,6.3c-1.2,3-1.8,6.7-1.4,10.5c0.4,3.8,1.7,7.8,3.9,11.1c2.3,3.5,4.6,5.9,6.7,7.9c2.2,2,4.2,3.6,6.3,5.1
					c4.1,3,8.3,5.7,14,9.6c-4.3-5.5-6.5-9.9-8-14.2c-0.7-2.2-1.3-4.4-1.8-6.8C239.9,201.7,239.6,199.1,239.5,196.3z"
                  />
                </g>
                <g>
                  <path
                    d="M259.2,191.2c2.3-5.4,3.1-10.6,2.9-15.2c-0.1-1.2-0.2-2.2-0.4-3.3c-0.2-1.1-0.5-2-0.9-2.9c-0.8-1.8-1.9-3.2-3.5-4
					c-1.6-0.8-3.4-0.8-5.3,0c-1,0.4-2,1-2.9,1.8c-0.9,0.8-1.9,1.7-2.8,2.9c-3.5,4.5-5.8,11.9-4.6,19.3c1.3,7.6,3.3,12.4,5.3,17
					c2.1,4.6,4.3,9,7.7,15c-1.4-6.8-1-11.6-0.1-16C255.5,201.1,256.9,196.9,259.2,191.2z"
                  />
                </g>
                <g>
                  <path
                    d="M281.1,191.4c4.3-4.1,7.2-8,9.3-11.7c1-1.9,1.9-3.7,2.4-5.6c0.5-1.8,0.7-3.7,0-5.3c-0.6-1.6-2.1-2.9-4.3-3.4
					c-2.2-0.6-5.1-0.3-8.2,0.8c-3.1,1.2-6.3,3.3-8.9,6.2c-2.6,2.9-4.7,6.5-5.8,10.5c-1.2,4-1.6,7.4-1.8,10.4c-0.2,3-0.2,5.7-0.2,8.3
					c0.1,5.2,0.3,10.3,0,17.6c2.4-6.9,4.7-11.5,7.3-15.6c1.3-2.1,2.8-4,4.4-6C277.1,195.6,278.9,193.5,281.1,191.4z"
                  />
                </g>
                <g>
                  <path
                    d="M256.7,233.3c-1.5,3.2,1.5,6,5,5.6c3.5-0.4,5.8-3.7,3.7-6.5c-2.2-2.9-3.7-3.9-5.1-7C259.6,228.7,258.2,230,256.7,233.3z
					"
                  />
                </g>
                <g>
                  <path
                    d="M261.4,230.6c-1.4,1-1.8,2.3-1.5,3.7c0.3,1.4,1.3,2.8,2.8,3.6c1.5,0.9,3.3,0.9,4.7,0.2c1.4-0.7,2.3-2.2,2.1-4.1
					c-0.2-2-0.6-3.3-0.9-4.7c-0.3-1.3-0.6-2.6-0.7-4.4c-0.9,1.5-1.9,2.4-2.9,3.1C263.9,228.9,262.8,229.6,261.4,230.6z"
                  />
                </g>
                <g>
                  <path
                    d="M264.9,232.2c-1.3-0.6-2.1-0.2-2.9,0.7c-0.7,0.9-1.4,2.4-1.5,4.2c-0.1,1.7,0.7,3.3,2.1,4.1c1.4,0.8,3.3,0.7,4.8-0.7
					c1.5-1.4,2.2-2.7,2.8-3.8c0.6-1.2,1.2-2.3,2.1-3.6c-1.5,0.5-2.7,0.5-3.8,0.3C267.3,233.2,266.2,232.7,264.9,232.2z"
                  />
                </g>
                <g>
                  <path
                    d="M267.4,236.8c0-0.7-0.3-1.3-0.7-1.8c-0.5-0.5-1.1-1-1.9-1.2c-0.8-0.3-1.6-0.3-2.4-0.1c-0.8,0.2-1.5,0.6-2.1,1.3
					c-0.6,0.6-1.1,1.3-1.4,2c-0.3,0.7-0.6,1.4-0.6,2c0,0.7,0.1,1.3,0.5,2c0.4,0.6,1.1,1.3,2.1,1.7c1,0.5,2.1,0.8,3,1
					c0.9,0.2,1.7,0.2,2.5,0.1c1.6-0.1,3.1-0.5,4.5-1.6c-0.8,0.1-1.5-0.2-2-0.6c-0.5-0.4-0.8-0.8-1-1.3c-0.2-0.5-0.3-1-0.4-1.6
					C267.4,238,267.5,237.5,267.4,236.8z"
                  />
                </g>
                <g>
                  <path
                    d="M266.9,240c0.8-1.4,0.7-2.5-0.1-3.6c-0.7-1.1-2-2.2-3.8-2.6c-1.7-0.4-3.4,0.1-4.5,1.4c-1.1,1.3-1.4,3.5-0.4,5.4
					c1.1,2,2.1,3.1,3.2,4.1c1,1,2,2,3.2,3.4c-0.2-1.8,0-3.1,0.5-4.3C265.4,242.6,266.1,241.5,266.9,240z"
                  />
                </g>
                <g>
                  <path
                    d="M264.2,244.1c1.7-1.3,2.4-3.2,2.2-4.9c-0.2-1.7-1.4-3.1-3.1-3.7c-1.7-0.6-3.4-0.3-4.7,0.5c-1.3,0.8-2.1,2-2.1,3.8
					c0,1.9,0,3.3,0,4.7c-0.1,1.4-0.4,2.8-1.2,4.5c1.6-1,2.9-1.7,4.3-2.3C260.9,246.1,262.4,245.4,264.2,244.1z"
                  />
                </g>
                <g>
                  <path
                    d="M258.2,243.2c3.6,1.4,6.6-1.7,6.2-5.2c-0.4-3.5-4.2-5.7-7.3-3.5c-3.2,2.3-4.3,3.9-7.7,5.4
					C253,240.5,254.5,241.8,258.2,243.2z"
                  />
                </g>
                <g>
                  <path
                    d="M254.5,239.4c1.8,3.2,5.8,2.6,7.7-0.4c1.8-3,0.5-6.9-3.1-7c-3.7-0.1-5.5,0.5-8.9-0.3
					C252.5,234.3,252.7,236.1,254.5,239.4z"
                  />
                </g>
                <g>
                  <path
                    d="M255,235.1c0,1.7,1.1,2.7,2.4,3c1.3,0.3,3-0.2,4.3-1.3c1.4-1.1,2.1-2.6,2.3-3.8c0.1-1.2-0.4-2.3-1.8-2.8
					c-2.9-1-4.7-1.1-6.9-3C255.8,230.1,255,231.7,255,235.1z"
                  />
                </g>
                <g>
                  <path
                    d="M299.7,201.9c5.7-1.8,10.2-4.8,13.4-8.2c3.2-3.4,5-7.2,3.9-10.6c-1.1-3.3-5.3-5.2-10.8-4.6c-5.4,0.5-12,3.6-16.6,9.1
					c-4.7,5.7-7.1,10.1-9.4,14.4c-2.2,4.4-4.4,8.6-7.4,14.7c4.8-4.8,8.8-7.3,12.8-9.3C289.6,205.4,293.8,203.9,299.7,201.9z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M624.2,225.2c3.8,4.2,8.2,7.3,12.1,9.2c3.9,1.8,7.4,2.3,9.1,0.6c1.7-1.7,1.1-5.3-1.4-9.3c-2.6-3.9-7.1-8.1-12.8-10.5
					c-5.8-2.5-10-3.6-14.1-4.8c-4.1-1.1-8.3-2.3-14.2-4.1c5.3,3.1,8.6,5.8,11.6,8.7C617.5,217.9,620.3,220.9,624.2,225.2z"
                  />
                </g>
                <g>
                  <path
                    d="M613.5,234.5c1.7,4.7,4.2,8.8,6.9,11.6c2.7,2.8,5.6,4.4,7.9,3.4c2.2-0.9,3.3-4.2,2.6-8.5c-0.7-4.2-3.2-9.4-7.3-13.3
					c-4.2-4-7.5-6.2-10.7-8.5c-3.3-2.2-6.5-4.4-11.1-7.6c3.6,4.3,5.5,7.6,7.1,11C610.4,226.1,611.7,229.6,613.5,234.5z"
                  />
                </g>
                <g>
                  <path
                    d="M598.5,235.7c-0.3,3.7,0.5,7,2,9.6c1.5,2.5,3.7,4.3,6.1,4.2c2.4-0.1,4.5-2.2,5.4-5.4c0.9-3.2,0.5-7.6-1.7-11.3
					c-2.3-3.8-4.3-6.2-6.3-8.5c-2-2.3-3.9-4.5-6.5-7.8c1.3,4,1.6,6.8,1.6,9.7C599.1,229,598.9,231.8,598.5,235.7z"
                  />
                </g>
                <g>
                  <path
                    d="M580.4,230.2c-2.1,2.7-2.8,5.7-2.5,8.4c0.3,2.6,1.7,4.9,4,5.8c2.3,0.9,4.9,0.1,6.9-1.9c2-2,3.4-5.2,3.2-8.8
					c-0.2-3.7-0.7-6.3-1.1-8.9c-0.4-2.5-0.7-5.1-0.8-8.6c-1.4,3.3-2.8,5.4-4.3,7.4C584.2,225.6,582.5,227.5,580.4,230.2z"
                  />
                </g>
                <g>
                  <path
                    d="M567.7,221.5c-3.4,1.1-6,3-7.7,5.3c-1.7,2.3-2.5,4.9-1.5,7.2c0.9,2.2,3.6,3.5,7,3.1c3.3-0.4,7.2-2.4,9.7-5.9
					c2.6-3.6,3.8-6.4,5-9.1c1.2-2.7,2.3-5.4,4-9.1c-2.9,2.8-5.4,4.3-7.8,5.4C573.9,219.5,571.3,220.4,567.7,221.5z"
                  />
                </g>
                <g>
                  <path
                    d="M562.8,205.2c-3.5-1.1-6.6-1.1-9.4-0.5c-0.7,0.2-1.4,0.3-2,0.6c-0.6,0.3-1.2,0.6-1.7,0.9c-1.1,0.7-1.9,1.6-2.3,2.8
					c-0.4,1.1-0.3,2.4,0.3,3.6c0.3,0.6,0.7,1.2,1.3,1.8c0.6,0.6,1.2,1.1,2,1.6c3.1,1.9,8,2.8,12.5,1.3c4.6-1.6,7.4-3.3,10.1-5.1
					c2.6-1.8,5.1-3.7,8.4-6.4c-4.1,1.4-7.1,1.6-9.9,1.4C569.2,206.9,566.4,206.2,562.8,205.2z"
                  />
                </g>
                <g>
                  <path
                    d="M568.7,186.9c-2.6-3.1-5.7-5.2-8.7-6.3c-3-1.1-5.9-1.1-7.7,0.5c-1.8,1.6-1.9,4.8-0.1,8.1c1.8,3.3,5.4,6.6,10.1,8
					c4.8,1.4,8.2,1.8,11.5,2.1c3.3,0.3,6.5,0.5,11.2,0.9c-4.3-1.8-6.8-3.7-9.1-5.8C573.6,192.4,571.5,190.1,568.7,186.9z"
                  />
                </g>
                <g>
                  <path
                    d="M579.1,174.3c-1.1-4.1-3-7.4-5.1-9.9c-0.6-0.6-1.1-1.2-1.7-1.7c-0.6-0.5-1.2-0.9-1.8-1.2c-1.2-0.6-2.4-0.9-3.6-0.7
					c-1.2,0.3-2.2,1.1-2.8,2.4c-0.3,0.6-0.6,1.4-0.8,2.2c-0.2,0.8-0.3,1.8-0.2,2.8c0.1,4,2,9,5.8,12.6c3.9,3.7,7,5.5,10.1,7.2
					c3.1,1.7,6.2,3.3,10.6,5.4c-3.6-3.3-5.4-6.2-6.8-9.1C581.3,181.5,580.3,178.5,579.1,174.3z"
                  />
                </g>
                <g>
                  <path
                    d="M592.2,171.1c0.9-3.8,1.3-6.8,1.4-9.5c0.1-1.3,0-2.7-0.1-4c-0.1-1.3-0.4-2.5-1.2-3.5c-0.8-0.9-2-1.4-3.6-1.2
					c-1.6,0.2-3.6,1.2-5.3,3c-1.7,1.8-3.1,4.4-3.7,7.3c-0.6,2.9-0.6,6,0.3,8.9c0.9,3,2.1,5.2,3.3,7c1.2,1.8,2.4,3.3,3.6,4.8
					c2.4,2.9,4.9,5.5,8.1,9.5c-2-4.8-2.9-8.3-3.3-11.7c-0.2-1.7-0.3-3.4-0.3-5.2C591.6,175,591.7,173.1,592.2,171.1z"
                  />
                </g>
                <g>
                  <path
                    d="M609.4,174.5c3.1-2.2,5.3-4.3,6.9-6.6c0.8-1.1,1.5-2.3,2.1-3.5c0.5-1.2,0.8-2.4,0.6-3.6c-0.2-1.2-1.1-2.2-2.5-2.8
					c-1.4-0.6-3.3-0.8-5.5-0.4c-2.2,0.4-4.5,1.5-6.6,3.3c-2,1.7-3.8,4-4.8,6.5c-1.1,2.7-1.6,4.9-1.9,6.9c-0.3,2-0.4,3.8-0.5,5.6
					c-0.1,3.5,0,6.9-0.1,11.8c1.5-4.6,3.1-7.6,5.1-10.1c1-1.3,2-2.5,3.2-3.6C606.4,176.8,607.8,175.6,609.4,174.5z"
                  />
                </g>
                <g>
                  <path
                    d="M619.9,183.9c4-0.8,7.3-2.3,9.9-4.3c0.6-0.5,1.2-1,1.7-1.5c0.5-0.5,1-1.1,1.3-1.6c0.7-1.1,1.1-2.3,1-3.6
					c-0.2-1.2-0.9-2.3-2.1-3.1c-0.6-0.4-1.3-0.8-2.1-1c-0.8-0.2-1.7-0.4-2.7-0.5c-3.9-0.3-9.1,1.3-12.8,4.9
					c-3.8,3.7-5.8,6.7-7.7,9.6c-1.8,3-3.5,6-5.6,10.2c3.4-3.4,6.2-5.1,9.1-6.3C612.8,185.6,615.8,184.7,619.9,183.9z"
                  />
                </g>
                <g>
                  <path
                    d="M628.3,196.5c4,0.9,7.4,1,10.3,0.8c1.5-0.1,2.9-0.4,4.1-0.8c1.2-0.4,2.4-1,3.1-2c0.7-1,0.8-2.3,0.3-3.8
					c-0.5-1.5-1.8-3-3.7-4.4c-1.9-1.3-4.3-2.3-7-2.7c-2.7-0.4-5.6-0.2-8.3,0.7c-2.8,0.9-4.9,2-6.7,3c-1.8,1-3.3,2.1-4.8,3.1
					c-3,2.1-5.8,4.2-10.1,6.8c4.9-1.3,8.4-1.8,11.8-1.8c1.7,0,3.4,0,5.2,0.2C624.3,195.8,626.3,196,628.3,196.5z"
                  />
                </g>
                <g>
                  <path
                    d="M594.8,198.8c-2.4,0.4-2.8,3.2-1.3,5c1.6,1.9,4.4,1.9,5.2-0.4c0.8-2.4,0.8-3.7,2-5.7
					C598.6,198.6,597.3,198.3,594.8,198.8z"
                  />
                </g>
                <g>
                  <path
                    d="M598.2,200.4c-1.1-0.4-2-0.1-2.7,0.6c-0.7,0.7-1.1,1.8-1,3c0.1,1.2,0.8,2.2,1.7,2.7c0.9,0.5,2.2,0.5,3.2-0.4
					c1-0.9,1.7-1.6,2.3-2.3c0.6-0.7,1.3-1.4,2.2-2.1c-1.2,0-2.1-0.2-2.9-0.4C600.2,201.2,599.3,200.8,598.2,200.4z"
                  />
                </g>
                <g>
                  <path
                    d="M598.6,203c-0.2-0.9-0.7-1.3-1.5-1.4c-0.8-0.1-1.9,0.1-3,0.8c-1,0.6-1.7,1.7-1.6,2.8c0.1,1.1,0.9,2.2,2.2,2.5
					c1.4,0.3,2.4,0.2,3.3,0.1c0.9-0.1,1.8-0.2,2.9-0.2c-0.9-0.7-1.3-1.4-1.6-2.1C599,204.8,598.8,204,598.6,203z"
                  />
                </g>
                <g>
                  <path
                    d="M597,206.3c0.4-0.3,0.6-0.7,0.8-1.1c0.1-0.5,0.1-1,0-1.6c-0.1-0.5-0.4-1.1-0.9-1.4c-0.4-0.4-1-0.6-1.6-0.7
					c-0.6-0.1-1.2-0.1-1.7,0c-0.5,0.1-1,0.2-1.4,0.4c-0.4,0.2-0.7,0.6-0.9,1c-0.2,0.5-0.3,1.1-0.2,1.9c0.1,0.8,0.3,1.5,0.6,2.1
					c0.3,0.6,0.6,1.1,0.9,1.5c0.7,0.8,1.5,1.6,2.7,2c-0.4-0.5-0.5-1-0.4-1.4c0-0.4,0.2-0.8,0.4-1.1c0.2-0.3,0.5-0.6,0.7-0.9
					C596.3,206.7,596.6,206.6,597,206.3z"
                  />
                </g>
                <g>
                  <path
                    d="M594.9,207.2c1.1-0.1,1.7-0.6,2.1-1.5c0.3-0.8,0.5-2,0-3.2c-0.4-1.1-1.4-1.9-2.6-2c-1.2-0.1-2.5,0.5-3.2,1.9
					c-0.7,1.4-0.9,2.4-1.1,3.4c-0.2,1-0.4,1.9-0.7,3.2c0.9-0.8,1.8-1.2,2.7-1.4C592.9,207.4,593.8,207.3,594.9,207.2z"
                  />
                </g>
                <g>
                  <path
                    d="M591.5,207.3c1.4,0.5,2.8,0.1,3.7-0.7c0.9-0.8,1.2-2,0.9-3.2c-0.3-1.2-1.2-2.1-2.1-2.5c-0.9-0.4-2-0.4-3,0.3
					c-1.1,0.7-1.9,1.3-2.7,1.8c-0.8,0.5-1.7,0.9-3.1,1.1c1.2,0.5,2.1,1,3,1.6C589.1,206.1,590.1,206.7,591.5,207.3z"
                  />
                </g>
                <g>
                  <path
                    d="M589.7,203.4c0.6,2.6,3.6,3.1,5.4,1.5c1.8-1.6,1.7-4.6-0.8-5.5c-2.6-1-3.9-1-6.1-2.3
					C589.3,199.5,589.1,200.8,589.7,203.4z"
                  />
                </g>
                <g>
                  <path
                    d="M590.5,199.9c-1.1,2.2,0.8,4.3,3.2,4.2c2.4-0.1,4.2-2.4,2.8-4.5c-1.4-2.2-2.4-3-3.3-5.2
					C592.6,196.7,591.6,197.6,590.5,199.9z"
                  />
                </g>
                <g>
                  <path
                    d="M593.1,198.5c-0.9,0.7-1.1,1.7-0.8,2.5c0.4,0.9,1.3,1.6,2.4,2c1.2,0.3,2.3,0.2,3.1-0.2c0.8-0.4,1.2-1.1,0.9-2.1
					c-0.5-2-1.2-3.1-0.9-5.1C596.3,197,595.1,197.2,593.1,198.5z"
                  />
                </g>
                <g>
                  <path
                    d="M629.5,211.2c3.3,2.5,6.7,4,9.9,4.5c3.2,0.5,6.1,0.1,7.6-1.9c1.5-1.9,0.9-5.1-1.5-8c-2.4-2.9-6.8-5.5-11.7-6
					c-5.1-0.5-8.5-0.1-11.9,0.2c-3.4,0.4-6.7,0.8-11.3,1.5c4.6,0.9,7.6,2.2,10.3,3.7C623.6,206.8,626.1,208.6,629.5,211.2z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M241.1,523.3c3.8,4.2,8.2,7.3,12.1,9.2c3.9,1.8,7.4,2.3,9.1,0.6c1.7-1.7,1.1-5.3-1.4-9.3c-2.6-3.9-7.1-8.1-12.8-10.5
					c-5.8-2.5-10-3.6-14.1-4.8c-4.1-1.1-8.3-2.3-14.2-4.1c5.3,3.1,8.6,5.8,11.6,8.7C234.3,516,237.1,519,241.1,523.3z"
                  />
                </g>
                <g>
                  <path
                    d="M230.3,532.5c1.7,4.7,4.2,8.8,6.9,11.6c2.7,2.8,5.6,4.4,7.9,3.4c2.2-0.9,3.3-4.2,2.6-8.5c-0.7-4.2-3.2-9.4-7.3-13.3
					c-4.2-4-7.5-6.2-10.7-8.5c-3.3-2.2-6.5-4.4-11.1-7.6c3.6,4.3,5.5,7.6,7.1,11C227.3,524.1,228.6,527.6,230.3,532.5z"
                  />
                </g>
                <g>
                  <path
                    d="M215.4,533.7c-0.3,3.7,0.5,7,2,9.6c1.5,2.5,3.7,4.3,6.1,4.2c2.4-0.1,4.5-2.2,5.4-5.4c0.9-3.2,0.5-7.6-1.7-11.3
					c-2.3-3.8-4.3-6.2-6.3-8.5c-2-2.3-3.9-4.5-6.5-7.8c1.3,4,1.6,6.8,1.6,9.7C216,527.1,215.7,529.9,215.4,533.7z"
                  />
                </g>
                <g>
                  <path
                    d="M197.2,528.3c-2.1,2.7-2.8,5.7-2.5,8.4c0.3,2.6,1.7,4.9,4,5.8c2.3,0.9,4.9,0.1,6.9-1.9c2-2,3.4-5.2,3.2-8.8
					c-0.2-3.7-0.7-6.3-1.1-8.9c-0.4-2.5-0.7-5.1-0.8-8.6c-1.4,3.3-2.8,5.4-4.3,7.4C201.1,523.7,199.4,525.5,197.2,528.3z"
                  />
                </g>
                <g>
                  <path
                    d="M184.6,519.6c-3.4,1.1-6,3-7.7,5.3c-1.7,2.3-2.5,4.9-1.5,7.2c0.9,2.2,3.6,3.5,7,3.1c3.3-0.4,7.2-2.4,9.7-5.9
					c2.6-3.6,3.8-6.4,5-9.1c1.2-2.7,2.3-5.4,4-9.1c-2.9,2.8-5.4,4.3-7.8,5.4C190.7,517.6,188.1,518.4,184.6,519.6z"
                  />
                </g>
                <g>
                  <path
                    d="M179.7,503.2c-3.5-1.1-6.6-1.1-9.4-0.5c-0.7,0.2-1.4,0.3-2,0.6c-0.6,0.3-1.2,0.6-1.7,0.9c-1.1,0.7-1.9,1.6-2.3,2.8
					c-0.4,1.1-0.3,2.4,0.3,3.6c0.3,0.6,0.7,1.2,1.3,1.8c0.6,0.6,1.2,1.1,2,1.6c3.1,1.9,8,2.8,12.5,1.3c4.6-1.6,7.4-3.3,10.1-5.1
					c2.6-1.8,5.1-3.7,8.4-6.4c-4.1,1.4-7.1,1.6-9.9,1.4C186,504.9,183.3,504.3,179.7,503.2z"
                  />
                </g>
                <g>
                  <path
                    d="M185.6,484.9c-2.6-3.1-5.7-5.2-8.7-6.3c-3-1.1-5.9-1.1-7.7,0.5c-1.8,1.6-1.9,4.8-0.1,8.1c1.8,3.3,5.4,6.6,10.1,8
					c4.8,1.4,8.2,1.8,11.5,2.1c3.3,0.3,6.5,0.5,11.2,0.9c-4.3-1.8-6.8-3.7-9.1-5.8C190.4,490.4,188.4,488.1,185.6,484.9z"
                  />
                </g>
                <g>
                  <path
                    d="M195.9,472.3c-1.1-4.1-3-7.4-5.1-9.9c-0.6-0.6-1.1-1.2-1.7-1.7c-0.6-0.5-1.2-0.9-1.8-1.2c-1.2-0.6-2.4-0.9-3.6-0.7
					c-1.2,0.3-2.2,1.1-2.8,2.4c-0.3,0.6-0.6,1.4-0.8,2.2c-0.2,0.8-0.3,1.8-0.2,2.8c0.1,4,2,9,5.8,12.6c3.9,3.7,7,5.5,10.1,7.2
					c3.1,1.7,6.2,3.3,10.6,5.4c-3.6-3.3-5.4-6.2-6.8-9.1C198.2,479.5,197.1,476.5,195.9,472.3z"
                  />
                </g>
                <g>
                  <path
                    d="M209.1,469.2c0.9-3.8,1.3-6.8,1.4-9.5c0.1-1.3,0-2.7-0.1-4c-0.1-1.3-0.4-2.5-1.2-3.5c-0.8-0.9-2-1.4-3.6-1.2
					c-1.6,0.2-3.6,1.2-5.3,3c-1.7,1.8-3.1,4.4-3.7,7.3c-0.6,2.9-0.6,6,0.3,8.9c0.9,3,2.1,5.2,3.3,7c1.2,1.8,2.4,3.3,3.6,4.8
					c2.4,2.9,4.9,5.5,8.1,9.5c-2-4.8-2.9-8.3-3.3-11.7c-0.2-1.7-0.3-3.4-0.3-5.2C208.4,473,208.6,471.1,209.1,469.2z"
                  />
                </g>
                <g>
                  <path
                    d="M226.2,472.6c3.1-2.2,5.3-4.3,6.9-6.6c0.8-1.1,1.5-2.3,2.1-3.5c0.5-1.2,0.8-2.4,0.6-3.6c-0.2-1.2-1.1-2.2-2.5-2.8
					c-1.4-0.6-3.3-0.8-5.5-0.4c-2.2,0.4-4.5,1.5-6.6,3.3c-2,1.7-3.8,4-4.8,6.5c-1.1,2.7-1.6,4.9-1.9,6.9c-0.3,2-0.4,3.8-0.5,5.6
					c-0.1,3.5,0,6.9-0.1,11.8c1.5-4.6,3.1-7.6,5.1-10.1c1-1.3,2-2.5,3.2-3.6C223.3,474.9,224.6,473.7,226.2,472.6z"
                  />
                </g>
                <g>
                  <path
                    d="M236.8,481.9c4-0.8,7.3-2.3,9.9-4.3c0.6-0.5,1.2-1,1.7-1.5c0.5-0.5,1-1.1,1.3-1.6c0.7-1.1,1.1-2.3,1-3.6
					c-0.2-1.2-0.9-2.3-2.1-3.1c-0.6-0.4-1.3-0.8-2.1-1c-0.8-0.2-1.7-0.4-2.7-0.5c-3.9-0.3-9.1,1.3-12.8,4.9
					c-3.8,3.7-5.8,6.7-7.7,9.6c-1.8,3-3.5,6-5.6,10.2c3.4-3.4,6.2-5.1,9.1-6.3C229.7,483.6,232.7,482.8,236.8,481.9z"
                  />
                </g>
                <g>
                  <path
                    d="M245.2,494.6c4,0.9,7.4,1,10.3,0.8c1.5-0.1,2.9-0.4,4.1-0.8c1.2-0.4,2.4-1,3.1-2c0.7-1,0.8-2.3,0.3-3.8
					c-0.5-1.5-1.8-3-3.7-4.4c-1.9-1.3-4.3-2.3-7-2.7c-2.7-0.4-5.6-0.2-8.3,0.7c-2.8,0.9-4.9,2-6.7,3c-1.8,1-3.3,2.1-4.8,3.1
					c-3,2.1-5.8,4.2-10.1,6.8c4.9-1.3,8.4-1.8,11.8-1.8c1.7,0,3.4,0,5.2,0.2C241.2,493.9,243.1,494.1,245.2,494.6z"
                  />
                </g>
                <g>
                  <path
                    d="M211.7,496.8c-2.4,0.4-2.8,3.2-1.3,5c1.6,1.9,4.4,1.9,5.2-0.4c0.8-2.4,0.8-3.7,2-5.7
					C215.4,496.7,214.2,496.4,211.7,496.8z"
                  />
                </g>
                <g>
                  <path
                    d="M215,498.4c-1.1-0.4-2-0.1-2.7,0.6c-0.7,0.7-1.1,1.8-1,3c0.1,1.2,0.8,2.2,1.7,2.7c0.9,0.5,2.2,0.5,3.2-0.4
					c1-0.9,1.7-1.6,2.3-2.3c0.6-0.7,1.3-1.4,2.2-2.1c-1.2,0-2.1-0.2-2.9-0.4C217,499.2,216.2,498.9,215,498.4z"
                  />
                </g>
                <g>
                  <path
                    d="M215.5,501.1c-0.2-0.9-0.7-1.3-1.5-1.4c-0.8-0.1-1.9,0.1-3,0.8c-1,0.6-1.7,1.7-1.6,2.8c0.1,1.1,0.9,2.2,2.2,2.5
					c1.4,0.3,2.4,0.2,3.3,0.1c0.9-0.1,1.8-0.2,2.9-0.2c-0.9-0.7-1.3-1.4-1.6-2.1C215.9,502.9,215.7,502.1,215.5,501.1z"
                  />
                </g>
                <g>
                  <path
                    d="M213.9,504.3c0.4-0.3,0.6-0.7,0.8-1.1c0.1-0.5,0.1-1,0-1.6c-0.1-0.5-0.4-1.1-0.9-1.4c-0.4-0.4-1-0.6-1.6-0.7
					c-0.6-0.1-1.2-0.1-1.7,0c-0.5,0.1-1,0.2-1.4,0.4c-0.4,0.2-0.7,0.6-0.9,1c-0.2,0.5-0.3,1.1-0.2,1.9c0.1,0.8,0.3,1.5,0.6,2.1
					c0.3,0.6,0.6,1.1,0.9,1.5c0.7,0.8,1.5,1.6,2.7,2c-0.4-0.5-0.5-1-0.4-1.4c0-0.4,0.2-0.8,0.4-1.1c0.2-0.3,0.5-0.6,0.7-0.9
					C213.1,504.8,213.5,504.6,213.9,504.3z"
                  />
                </g>
                <g>
                  <path
                    d="M211.8,505.3c1.1-0.1,1.7-0.6,2.1-1.5c0.3-0.8,0.5-2,0-3.2c-0.4-1.1-1.4-1.9-2.6-2c-1.2-0.1-2.5,0.5-3.2,1.9
					c-0.7,1.4-0.9,2.4-1.1,3.4c-0.2,1-0.4,1.9-0.7,3.2c0.9-0.8,1.8-1.2,2.7-1.4C209.7,505.4,210.6,505.4,211.8,505.3z"
                  />
                </g>
                <g>
                  <path
                    d="M208.4,505.3c1.4,0.5,2.8,0.1,3.7-0.7c0.9-0.8,1.2-2,0.9-3.2c-0.3-1.2-1.2-2.1-2.1-2.5c-0.9-0.4-2-0.4-3,0.3
					c-1.1,0.7-1.9,1.3-2.7,1.8c-0.8,0.5-1.7,0.9-3.1,1.1c1.2,0.5,2.1,1,3,1.6C206,504.2,206.9,504.8,208.4,505.3z"
                  />
                </g>
                <g>
                  <path
                    d="M206.6,501.5c0.6,2.6,3.6,3.1,5.4,1.5c1.8-1.6,1.7-4.6-0.8-5.5c-2.6-1-3.9-1-6.1-2.3
					C206.1,497.5,205.9,498.8,206.6,501.5z"
                  />
                </g>
                <g>
                  <path
                    d="M207.3,497.9c-1.1,2.2,0.8,4.3,3.2,4.2c2.4-0.1,4.2-2.4,2.8-4.5c-1.4-2.2-2.4-3-3.3-5.2
					C209.5,494.8,208.5,495.6,207.3,497.9z"
                  />
                </g>
                <g>
                  <path
                    d="M210,496.6c-0.9,0.7-1.1,1.7-0.8,2.5c0.4,0.9,1.3,1.6,2.4,2c1.2,0.3,2.3,0.2,3.1-0.2c0.8-0.4,1.2-1.1,0.9-2.1
					c-0.5-2-1.2-3.1-0.9-5.1C213.2,495,211.9,495.2,210,496.6z"
                  />
                </g>
                <g>
                  <path
                    d="M246.4,509.3c3.3,2.5,6.7,4,9.9,4.5c3.2,0.5,6.1,0.1,7.6-1.9c1.5-1.9,0.9-5.1-1.5-8c-2.4-2.9-6.8-5.5-11.7-6
					c-5.1-0.5-8.5-0.1-11.9,0.2c-3.4,0.4-6.7,0.8-11.3,1.5c4.6,0.9,7.6,2.2,10.3,3.7C240.4,504.9,243,506.7,246.4,509.3z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M155.7,208.9c3.8,4.2,8.2,7.3,12.1,9.2c3.9,1.8,7.4,2.3,9.1,0.6c1.7-1.7,1.1-5.3-1.4-9.3c-2.6-3.9-7.1-8.1-12.8-10.5
					c-5.8-2.5-10-3.6-14.1-4.8c-4.1-1.1-8.3-2.3-14.2-4.1c5.3,3.1,8.6,5.8,11.6,8.7C148.9,201.6,151.7,204.6,155.7,208.9z"
                  />
                </g>
                <g>
                  <path
                    d="M144.9,218.2c1.7,4.7,4.2,8.8,6.9,11.6c2.7,2.8,5.6,4.4,7.9,3.4c2.2-0.9,3.3-4.2,2.6-8.5c-0.7-4.2-3.2-9.4-7.3-13.3
					c-4.2-4-7.5-6.2-10.7-8.5c-3.3-2.2-6.5-4.4-11.1-7.6c3.6,4.3,5.5,7.6,7.1,11C141.9,209.8,143.2,213.3,144.9,218.2z"
                  />
                </g>
                <g>
                  <path
                    d="M130,219.4c-0.3,3.7,0.5,7,2,9.6c1.5,2.5,3.7,4.3,6.1,4.2c2.4-0.1,4.5-2.2,5.4-5.4c0.9-3.2,0.5-7.6-1.7-11.3
					c-2.3-3.8-4.3-6.2-6.3-8.5c-2-2.3-3.9-4.5-6.5-7.8c1.3,4,1.6,6.8,1.6,9.7C130.6,212.7,130.3,215.5,130,219.4z"
                  />
                </g>
                <g>
                  <path
                    d="M111.8,214c-2.1,2.7-2.8,5.7-2.5,8.4c0.3,2.6,1.7,4.9,4,5.8c2.3,0.9,4.9,0.1,6.9-1.9c2-2,3.4-5.2,3.2-8.8
					c-0.2-3.7-0.7-6.3-1.1-8.9c-0.4-2.5-0.7-5.1-0.8-8.6c-1.4,3.3-2.8,5.4-4.3,7.4C115.7,209.3,114,211.2,111.8,214z"
                  />
                </g>
                <g>
                  <path
                    d="M99.2,205.2c-3.4,1.1-6,3-7.7,5.3c-1.7,2.3-2.5,4.9-1.5,7.2c0.9,2.2,3.6,3.5,7,3.1c3.3-0.4,7.2-2.4,9.7-5.9
					c2.6-3.6,3.8-6.4,5-9.1c1.2-2.7,2.3-5.4,4-9.1c-2.9,2.8-5.4,4.3-7.8,5.4C105.3,203.2,102.7,204.1,99.2,205.2z"
                  />
                </g>
                <g>
                  <path
                    d="M94.3,188.9c-3.5-1.1-6.6-1.1-9.4-0.5c-0.7,0.2-1.4,0.3-2,0.6c-0.6,0.3-1.2,0.6-1.7,0.9c-1.1,0.7-1.9,1.6-2.3,2.8
					c-0.4,1.1-0.3,2.4,0.3,3.6c0.3,0.6,0.7,1.2,1.3,1.8c0.6,0.6,1.2,1.1,2,1.6c3.1,1.9,8,2.8,12.5,1.3c4.6-1.6,7.4-3.3,10.1-5.1
					c2.6-1.8,5.1-3.7,8.4-6.4c-4.1,1.4-7.1,1.6-9.9,1.4C100.6,190.6,97.9,190,94.3,188.9z"
                  />
                </g>
                <g>
                  <path
                    d="M100.2,170.6c-2.6-3.1-5.7-5.2-8.7-6.3c-3-1.1-5.9-1.1-7.7,0.5c-1.8,1.6-1.9,4.8-0.1,8.1c1.8,3.3,5.4,6.6,10.1,8
					c4.8,1.4,8.2,1.8,11.5,2.1c3.3,0.3,6.5,0.5,11.2,0.9c-4.3-1.8-6.8-3.7-9.1-5.8C105,176.1,103,173.8,100.2,170.6z"
                  />
                </g>
                <g>
                  <path
                    d="M110.5,158c-1.1-4.1-3-7.4-5.1-9.9c-0.6-0.6-1.1-1.2-1.7-1.7c-0.6-0.5-1.2-0.9-1.8-1.2c-1.2-0.6-2.4-0.9-3.6-0.7
					c-1.2,0.3-2.2,1.1-2.8,2.4c-0.3,0.6-0.6,1.4-0.8,2.2c-0.2,0.8-0.3,1.8-0.2,2.8c0.1,4,2,9,5.8,12.6c3.9,3.7,7,5.5,10.1,7.2
					c3.1,1.7,6.2,3.3,10.6,5.4c-3.6-3.3-5.4-6.2-6.8-9.1C112.8,165.2,111.7,162.2,110.5,158z"
                  />
                </g>
                <g>
                  <path
                    d="M123.7,154.9c0.9-3.8,1.3-6.8,1.4-9.5c0.1-1.3,0-2.7-0.1-4c-0.1-1.3-0.4-2.5-1.2-3.5c-0.8-0.9-2-1.4-3.6-1.2
					c-1.6,0.2-3.6,1.2-5.3,3c-1.7,1.8-3.1,4.4-3.7,7.3c-0.6,2.9-0.6,6,0.3,8.9c0.9,3,2.1,5.2,3.3,7c1.2,1.8,2.4,3.3,3.6,4.8
					c2.4,2.9,4.9,5.5,8.1,9.5c-2-4.8-2.9-8.3-3.3-11.7c-0.2-1.7-0.3-3.4-0.3-5.2C123,158.7,123.2,156.8,123.7,154.9z"
                  />
                </g>
                <g>
                  <path
                    d="M140.8,158.2c3.1-2.2,5.3-4.3,6.9-6.6c0.8-1.1,1.5-2.3,2.1-3.5c0.5-1.2,0.8-2.4,0.6-3.6c-0.2-1.2-1.1-2.2-2.5-2.8
					c-1.4-0.6-3.3-0.8-5.5-0.4c-2.2,0.4-4.5,1.5-6.6,3.3c-2,1.7-3.8,4-4.8,6.5c-1.1,2.7-1.6,4.9-1.9,6.9c-0.3,2-0.4,3.8-0.5,5.6
					c-0.1,3.5,0,6.9-0.1,11.8c1.5-4.6,3.1-7.6,5.1-10.1c1-1.3,2-2.5,3.2-3.6C137.9,160.6,139.2,159.4,140.8,158.2z"
                  />
                </g>
                <g>
                  <path
                    d="M151.4,167.6c4-0.8,7.3-2.3,9.9-4.3c0.6-0.5,1.2-1,1.7-1.5c0.5-0.5,1-1.1,1.3-1.6c0.7-1.1,1.1-2.3,1-3.6
					c-0.2-1.2-0.9-2.3-2.1-3.1c-0.6-0.4-1.3-0.8-2.1-1c-0.8-0.2-1.7-0.4-2.7-0.5c-3.9-0.3-9.1,1.3-12.8,4.9
					c-3.8,3.7-5.8,6.7-7.7,9.6c-1.8,3-3.5,6-5.6,10.2c3.4-3.4,6.2-5.1,9.1-6.3C144.2,169.3,147.3,168.5,151.4,167.6z"
                  />
                </g>
                <g>
                  <path
                    d="M159.8,180.2c4,0.9,7.4,1,10.3,0.8c1.5-0.1,2.9-0.4,4.1-0.8c1.2-0.4,2.4-1,3.1-2c0.7-1,0.8-2.3,0.3-3.8
					c-0.5-1.5-1.8-3-3.7-4.4c-1.9-1.3-4.3-2.3-7-2.7c-2.7-0.4-5.6-0.2-8.3,0.7c-2.8,0.9-4.9,2-6.7,3c-1.8,1-3.3,2.1-4.8,3.1
					c-3,2.1-5.8,4.2-10.1,6.8c4.9-1.3,8.4-1.8,11.8-1.8c1.7,0,3.4,0,5.2,0.2C155.8,179.5,157.7,179.8,159.8,180.2z"
                  />
                </g>
                <g>
                  <path
                    d="M126.3,182.5c-2.4,0.4-2.8,3.2-1.3,5c1.6,1.9,4.4,1.9,5.2-0.4c0.8-2.4,0.8-3.7,2-5.7C130,182.3,128.8,182.1,126.3,182.5
					z"
                  />
                </g>
                <g>
                  <path
                    d="M129.6,184.1c-1.1-0.4-2-0.1-2.7,0.6c-0.7,0.7-1.1,1.8-1,3c0.1,1.2,0.8,2.2,1.7,2.7c0.9,0.5,2.2,0.5,3.2-0.4
					c1-0.9,1.7-1.6,2.3-2.3c0.6-0.7,1.3-1.4,2.2-2.1c-1.2,0-2.1-0.2-2.9-0.4C131.6,184.9,130.8,184.5,129.6,184.1z"
                  />
                </g>
                <g>
                  <path
                    d="M130.1,186.7c-0.2-0.9-0.7-1.3-1.5-1.4c-0.8-0.1-1.9,0.1-3,0.8c-1,0.6-1.7,1.7-1.6,2.8c0.1,1.1,0.9,2.2,2.2,2.5
					c1.4,0.3,2.4,0.2,3.3,0.1c0.9-0.1,1.8-0.2,2.9-0.2c-0.9-0.7-1.3-1.4-1.6-2.1C130.5,188.5,130.3,187.7,130.1,186.7z"
                  />
                </g>
                <g>
                  <path
                    d="M128.5,190c0.4-0.3,0.6-0.7,0.8-1.1c0.1-0.5,0.1-1,0-1.6c-0.1-0.5-0.4-1.1-0.9-1.4c-0.4-0.4-1-0.6-1.6-0.7
					c-0.6-0.1-1.2-0.1-1.7,0c-0.5,0.1-1,0.2-1.4,0.4c-0.4,0.2-0.7,0.6-0.9,1c-0.2,0.5-0.3,1.1-0.2,1.9c0.1,0.8,0.3,1.5,0.6,2.1
					c0.3,0.6,0.6,1.1,0.9,1.5c0.7,0.8,1.5,1.6,2.7,2c-0.4-0.5-0.5-1-0.4-1.4c0-0.4,0.2-0.8,0.4-1.1c0.2-0.3,0.5-0.6,0.7-0.9
					C127.7,190.4,128.1,190.3,128.5,190z"
                  />
                </g>
                <g>
                  <path
                    d="M126.4,190.9c1.1-0.1,1.7-0.6,2.1-1.5c0.3-0.8,0.5-2,0-3.2c-0.4-1.1-1.4-1.9-2.6-2c-1.2-0.1-2.5,0.5-3.2,1.9
					c-0.7,1.4-0.9,2.4-1.1,3.4c-0.2,1-0.4,1.9-0.7,3.2c0.9-0.8,1.8-1.2,2.7-1.4C124.3,191.1,125.2,191,126.4,190.9z"
                  />
                </g>
                <g>
                  <path
                    d="M123,191c1.4,0.5,2.8,0.1,3.7-0.7c0.9-0.8,1.2-2,0.9-3.2c-0.3-1.2-1.2-2.1-2.1-2.5c-0.9-0.4-2-0.4-3,0.3
					c-1.1,0.7-1.9,1.3-2.7,1.8c-0.8,0.5-1.7,0.9-3.1,1.1c1.2,0.5,2.1,1,3,1.6C120.6,189.9,121.5,190.4,123,191z"
                  />
                </g>
                <g>
                  <path
                    d="M121.2,187.2c0.6,2.6,3.6,3.1,5.4,1.5c1.8-1.6,1.7-4.6-0.8-5.5c-2.6-1-3.9-1-6.1-2.3
					C120.7,183.2,120.5,184.5,121.2,187.2z"
                  />
                </g>
                <g>
                  <path
                    d="M121.9,183.6c-1.1,2.2,0.8,4.3,3.2,4.2c2.4-0.1,4.2-2.4,2.8-4.5c-1.4-2.2-2.4-3-3.3-5.2
					C124.1,180.4,123.1,181.3,121.9,183.6z"
                  />
                </g>
                <g>
                  <path
                    d="M124.6,182.2c-0.9,0.7-1.1,1.7-0.8,2.5c0.4,0.9,1.3,1.6,2.4,2c1.2,0.3,2.3,0.2,3.1-0.2c0.8-0.4,1.2-1.1,0.9-2.1
					c-0.5-2-1.2-3.1-0.9-5.1C127.8,180.7,126.5,180.9,124.6,182.2z"
                  />
                </g>
                <g>
                  <path
                    d="M161,194.9c3.3,2.5,6.7,4,9.9,4.5c3.2,0.5,6.1,0.1,7.6-1.9c1.5-1.9,0.9-5.1-1.5-8c-2.4-2.9-6.8-5.5-11.7-6
					c-5.1-0.5-8.5-0.1-11.9,0.2c-3.4,0.4-6.7,0.8-11.3,1.5c4.6,0.9,7.6,2.2,10.3,3.7C155,190.5,157.6,192.3,161,194.9z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M353.7,131.6c-6.1,1.4-12.7,4.3-17.8,8c-2.6,1.8-4.9,3.9-7,6c-2,2.1-3.7,4.3-5.1,6.4c-2.8,4.3-4.2,8.4-4.6,11.5
					c-0.2,1.6-0.1,2.9,0.2,3.9c0.3,1,0.9,1.8,1.6,2.2c0.8,0.4,1.7,0.6,2.7,0.4c1-0.2,2.2-0.6,3.5-1.2c2.5-1.2,5.5-3.2,8.8-5.5
					c1.6-1.1,3.4-2.3,5.2-3.6c1.8-1.2,3.8-2.5,5.8-3.8c4-2.7,8.2-5.1,13.1-8c5-3,9.8-5.5,14-8.2c4.2-2.6,8-5.4,11.3-8.5
					c1.7-1.5,3.3-3.1,4.8-4.8c1.6-1.7,3.1-3.5,4.6-5.5c3-3.9,6-8.5,8.6-14.3c-3.6,5.3-7.4,9-11.1,11.8c-1.9,1.4-3.7,2.6-5.5,3.7
					c-1.8,1.1-3.7,2-5.5,2.7c-3.7,1.6-7.5,2.6-11.9,3.5C365,129.4,360,130.1,353.7,131.6z"
                  />
                </g>
                <g>
                  <path
                    d="M362.6,150.6c-5,2.2-10,4.1-14.7,5.9c-4.7,1.7-9.2,3.3-13.1,4.7c-4,1.4-7.4,2.8-9.9,4.2c-2.5,1.4-4.1,2.9-4.3,4.7
					c-0.1,1.7,1.3,3.5,4.2,5c2.8,1.5,7.1,2.5,12.3,2.7c5.2,0.2,11.2-0.6,17.4-2.6c6.1-2.1,12.3-5.3,17.6-9.8
					c5.4-4.6,9.3-9,12.4-13.3c3.1-4.3,5.4-8.3,7.5-12.4c2-4.1,3.8-8.4,5.4-13.2c1.6-4.8,3-10.2,4-16.7c-2,6.2-4.5,11.1-7.2,15.2
					c-2.6,4.1-5.4,7.4-8.4,10.3c-3,3-6.2,5.6-10,8.1C372,145.9,367.8,148.3,362.6,150.6z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M401.3,173.5c-6-1.9-13.1-2.8-19.4-2.2c-3.2,0.3-6.2,0.8-9,1.6c-2.8,0.8-5.4,1.8-7.7,2.9c-4.6,2.3-7.9,5-9.8,7.5
					c-1,1.2-1.6,2.4-1.8,3.5c-0.3,1.1-0.2,2,0.3,2.7c0.4,0.8,1.2,1.3,2.2,1.7c1,0.4,2.2,0.6,3.6,0.8c2.8,0.2,6.3,0.1,10.3-0.2
					c2-0.1,4.1-0.3,6.4-0.4c2.2-0.1,4.5-0.3,6.9-0.4c4.8-0.2,9.7-0.2,15.4-0.2c5.9,0,11.2,0.3,16.2,0.1c5-0.1,9.7-0.6,14.1-1.5
					c2.2-0.4,4.4-1,6.6-1.7c2.2-0.7,4.4-1.4,6.7-2.4c4.6-1.9,9.5-4.3,14.7-7.9c-5.8,2.7-10.9,3.9-15.6,4.5c-2.3,0.3-4.5,0.4-6.7,0.3
					c-2.1,0-4.2-0.2-6.2-0.5c-4-0.5-7.8-1.6-12-3C412.2,177.4,407.6,175.5,401.3,173.5z"
                  />
                </g>
                <g>
                  <path
                    d="M399.3,194.3c-5.4-0.7-10.7-1.6-15.6-2.5c-4.9-0.9-9.6-1.9-13.7-2.7c-4.1-0.8-7.8-1.4-10.7-1.5
					c-2.9-0.1-5.1,0.4-6.1,1.9c-1,1.4-0.6,3.7,1.1,6.4c1.7,2.7,4.8,5.8,9.2,8.6c4.3,2.8,9.9,5.3,16.3,6.7c6.3,1.4,13.3,1.8,20.1,0.6
					c7-1.1,12.6-3,17.5-5c4.8-2.1,8.9-4.4,12.8-6.9c3.9-2.5,7.6-5.3,11.4-8.6c3.8-3.3,7.8-7.3,12-12.3c-4.9,4.3-9.6,7.2-13.9,9.4
					c-4.4,2.2-8.4,3.5-12.5,4.6c-4.1,1-8.2,1.6-12.7,1.9C409.8,195.2,404.9,195.1,399.3,194.3z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M143.2,295.9c4-4.8,7.5-11.1,9.3-17.2c0.9-3.1,1.6-6.1,1.9-9c0.3-2.9,0.3-5.7,0.2-8.2c-0.4-5.1-1.7-9.2-3.3-11.9
					c-0.8-1.4-1.7-2.4-2.6-3c-0.9-0.6-1.8-0.9-2.6-0.8c-0.9,0.1-1.7,0.6-2.4,1.3c-0.7,0.8-1.4,1.8-2,3.1c-1.3,2.5-2.4,5.9-3.7,9.7
					c-0.6,1.9-1.3,3.9-2,6.1c-0.7,2.1-1.4,4.3-2.2,6.6c-1.6,4.6-3.4,9-5.5,14.3c-2.2,5.4-4.4,10.3-6.2,15c-1.7,4.7-3.1,9.2-3.8,13.6
					c-0.4,2.2-0.7,4.5-0.9,6.8c-0.2,2.3-0.3,4.7-0.3,7.1c0,4.9,0.4,10.4,1.8,16.6c-0.4-6.3,0.4-11.6,1.6-16.1
					c0.6-2.3,1.3-4.4,2.2-6.3c0.8-2,1.7-3.8,2.7-5.5c2-3.5,4.4-6.6,7.3-10C135.6,304.6,139.1,300.9,143.2,295.9z"
                  />
                </g>
                <g>
                  <path
                    d="M124.7,286.2c2.7-4.8,5.5-9.3,8.1-13.6c2.7-4.3,5.3-8.2,7.6-11.7c2.3-3.5,4.2-6.7,5.4-9.3c1.2-2.6,1.5-4.9,0.5-6.3
					c-1-1.5-3.2-2-6.4-1.4c-3.2,0.6-7.2,2.3-11.4,5.3c-4.2,3-8.6,7.3-12.2,12.6c-3.6,5.4-6.6,11.7-8.1,18.4
					c-1.5,6.9-1.9,12.8-1.8,18.1c0.1,5.3,0.7,9.9,1.6,14.4c0.9,4.5,2.1,9,3.7,13.8c1.7,4.8,3.8,9.9,7,15.7
					c-2.2-6.2-3.2-11.6-3.5-16.4c-0.4-4.8-0.2-9.2,0.4-13.3c0.6-4.2,1.6-8.2,3-12.5C120,295.7,121.9,291.1,124.7,286.2z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M453.4,473.1c-7.8,2.4-14.6,6.2-19.4,10.2c-4.8,4-7.6,8.2-6.4,11.6c1.2,3.3,6.3,4.8,13,3.8c6.7-1,14.9-4.4,21.5-10.4
					c6.8-6.1,10.9-10.9,14.9-15.6c4-4.7,8-9.4,14-16.1c-7.3,5.1-12.9,7.7-18.4,9.9C467.1,468.8,461.4,470.6,453.4,473.1z"
                  />
                </g>
                <g>
                  <path
                    d="M447.9,453.4c-7.2-0.7-14.1,0.2-19.4,2c-5.3,1.8-9.1,4.7-9.3,8.2c-0.2,3.5,3.5,6.8,9.5,8.5c6,1.7,14.2,1.6,21.8-1.4
					c7.8-3.1,12.7-6,17.6-8.9c4.9-2.9,9.7-5.8,16.7-9.9c-7.8,2.1-13.3,2.5-18.7,2.5C460.7,454.5,455.3,454.1,447.9,453.4z"
                  />
                </g>
                <g>
                  <path
                    d="M455.3,433.2c-4.7-2.7-9.5-3.7-13.7-3.3c-4.2,0.4-7.9,2.2-9.3,5.4c-1.4,3.2,0.1,7.2,3.8,10.4c3.7,3.1,9.6,5.3,15.9,4.7
					c6.4-0.7,10.7-1.9,14.9-3.1c4.2-1.2,8.3-2.4,14.1-3.8c-6-0.7-9.9-2.1-13.6-3.8C463.7,438,460.2,435.9,455.3,433.2z"
                  />
                </g>
                <g>
                  <path
                    d="M473.5,412.7c-2.2-4.4-5.7-7.2-9.4-8.4c-3.7-1.2-7.5-0.8-10.1,1.7c-2.6,2.4-3.1,6.3-1.8,10.2c1.4,3.9,4.7,7.6,9.6,9.6
					c5,2,8.7,2.9,12.3,3.9c3.6,1,7.1,2.1,11.8,4.3c-3.4-3.8-5.3-7-7-10.2C477.3,420.6,475.8,417.2,473.5,412.7z"
                  />
                </g>
                <g>
                  <path
                    d="M492.7,401.5c0.7-5.1-0.3-9.7-2.2-13.3c-1.9-3.6-4.9-6.3-8.4-6.4c-3.5-0.1-6.7,2.6-8.3,7.2c-1.5,4.5-1.3,10.8,1.8,16.3
					c3.2,5.6,6.1,8.9,8.9,12.1c2.8,3.2,5.6,6.3,9.4,10.8c-1.9-5.5-2.3-9.6-2.3-13.6C491.6,410.7,492,406.8,492.7,401.5z"
                  />
                </g>
                <g>
                  <path
                    d="M517.1,405c3.5-3.9,5.5-8,6.4-12c0.2-1,0.4-2,0.4-3c0-1,0-1.9-0.1-2.8c-0.3-1.8-1-3.5-2.2-4.7c-1.3-1.2-2.9-1.9-4.9-1.8
					c-1,0-2.1,0.2-3.1,0.6c-1.1,0.4-2.2,0.9-3.3,1.6c-4.4,2.8-8.5,8.7-9.3,15.5c-0.8,7-0.1,11.7,0.6,16.3c0.8,4.5,1.7,8.9,3.2,15
					c0.6-6.2,2.3-10.2,4.3-13.8C511,412.3,513.5,409,517.1,405z"
                  />
                </g>
                <g>
                  <path
                    d="M537.4,423.9c5.7-1.6,10.3-4.3,13.5-7.6c3.2-3.2,5.1-7.1,4.1-10.4c-1-3.3-5.1-5.3-10.5-5c-5.4,0.3-11.9,3.1-16.6,8.4
					c-4.8,5.4-7.3,9.6-9.8,13.8c-2.3,4.1-4.7,8.2-8.1,14.1c5-4.5,9-6.7,13.1-8.4C527.3,426.9,531.5,425.6,537.4,423.9z"
                  />
                </g>
                <g>
                  <path
                    d="M547.6,445.1c6,1,11.5,0.6,16-0.7c1.1-0.4,2.2-0.7,3.2-1.2c1-0.5,1.9-1,2.7-1.6c1.6-1.2,2.7-2.6,3.1-4.4
					c0.4-1.7-0.1-3.5-1.4-5.2c-0.6-0.8-1.4-1.7-2.5-2.4c-1-0.7-2.2-1.5-3.5-2c-5.2-2.3-13-2.8-20,0c-7.2,2.9-11.5,5.9-15.7,8.8
					c-4.1,3-8.1,6.1-13.5,10.5c6.6-2.7,11.4-3.3,16-3.4C536.8,443.6,541.4,444.1,547.6,445.1z"
                  />
                </g>
                <g>
                  <path
                    d="M543.7,464.2c4.4,3.5,8.1,5.9,11.5,7.7c1.7,0.9,3.5,1.7,5.2,2.3c1.7,0.6,3.5,1,5.3,0.6c1.7-0.4,3.1-1.7,3.8-4
					c0.7-2.2,0.6-5.4-0.8-8.8c-1.3-3.3-3.9-6.8-7.2-9.3c-3.4-2.6-7.5-4.4-11.9-5c-4.5-0.6-8.1-0.4-11.2,0c-3.1,0.4-5.8,1.1-8.4,1.8
					c-5.2,1.4-10.1,3.1-17.4,4.9c7.5,0.3,12.6,1.2,17.3,2.7c2.3,0.8,4.6,1.7,6.9,2.8C539.1,461,541.5,462.4,543.7,464.2z"
                  />
                </g>
                <g>
                  <path
                    d="M528.8,484.6c1,5.4,2.5,9.5,4.4,13.1c1,1.8,2.1,3.4,3.3,4.8c1.2,1.4,2.7,2.5,4.4,2.9c1.7,0.4,3.5,0,5.2-1.5
					c1.7-1.4,3.1-3.9,3.9-7c0.7-3.1,0.7-6.9-0.2-10.6c-1-3.7-2.9-7.4-5.6-10.3c-2.8-3.1-5.4-5.1-7.9-6.8c-2.5-1.7-4.7-2.9-7-4.1
					c-4.6-2.3-9.1-4.3-15.4-7.3c5.1,4.8,8,8.7,10.2,12.8c1.1,2,2,4.1,2.8,6.4C527.6,479.3,528.3,481.8,528.8,484.6z"
                  />
                </g>
                <g>
                  <path
                    d="M510.1,492.7c-1.4,5.7-1.4,11-0.4,15.5c0.3,1.1,0.5,2.2,0.9,3.2c0.4,1,0.8,1.9,1.3,2.7c1,1.6,2.4,2.9,4.1,3.4
					c1.7,0.5,3.5,0.2,5.3-0.9c0.9-0.5,1.8-1.3,2.6-2.2c0.8-0.9,1.6-2,2.3-3.3c2.7-5,3.8-12.7,1.5-19.8c-2.4-7.3-5.2-11.7-7.9-15.9
					c-2.8-4.2-5.7-8.2-10-13.6c2.4,6.5,2.8,11.3,2.7,15.8C512.2,482.3,511.5,486.8,510.1,492.7z"
                  />
                </g>
                <g>
                  <path
                    d="M488.5,496c-3.6,4.8-5.9,9-7.3,13c-0.7,2-1.3,4-1.5,5.9c-0.2,1.9-0.1,3.7,0.8,5.3c0.9,1.5,2.5,2.5,4.7,2.7
					c2.2,0.2,5.1-0.5,7.9-2.1c2.8-1.6,5.7-4.3,7.8-7.6c2.1-3.3,3.6-7.2,4.1-11.2c0.5-4.2,0.4-7.6,0.2-10.6c-0.3-3-0.7-5.6-1.1-8.2
					c-0.9-5.1-1.9-10.1-2.8-17.4c-1.3,7.2-2.8,12.1-4.8,16.5c-1,2.2-2.1,4.4-3.4,6.7C491.8,491.2,490.3,493.6,488.5,496z"
                  />
                </g>
                <g>
                  <path
                    d="M505.9,450.8c0.9-3.4-2.4-5.6-5.8-4.7c-3.4,0.9-5.2,4.5-2.7,7c2.6,2.5,4.3,3.3,6.2,6.1
					C503.8,455.8,505,454.3,505.9,450.8z"
                  />
                </g>
                <g>
                  <path
                    d="M501.8,454.2c1.2-1.2,1.4-2.6,0.9-3.9c-0.5-1.3-1.7-2.5-3.4-3.1c-1.7-0.6-3.4-0.4-4.6,0.6c-1.2,0.9-1.9,2.6-1.4,4.4
					c0.5,1.9,1.1,3.2,1.6,4.5c0.5,1.3,1,2.5,1.4,4.2c0.7-1.6,1.5-2.6,2.4-3.6C499.5,456.3,500.5,455.4,501.8,454.2z"
                  />
                </g>
                <g>
                  <path
                    d="M498,453.2c1.3,0.3,2.1-0.1,2.7-1.2c0.6-1,1-2.6,0.8-4.4c-0.2-1.7-1.2-3.2-2.7-3.8c-1.5-0.6-3.4-0.2-4.6,1.4
					c-1.2,1.6-1.7,3-2.2,4.2c-0.4,1.3-0.8,2.4-1.5,3.9c1.4-0.8,2.6-0.9,3.7-0.9C495.5,452.6,496.6,452.9,498,453.2z"
                  />
                </g>
                <g>
                  <path
                    d="M494.8,449.1c0.1,0.7,0.5,1.2,1,1.7c0.5,0.4,1.3,0.8,2.1,0.9c0.8,0.1,1.7,0.1,2.4-0.3c0.8-0.3,1.4-0.9,1.9-1.6
					c0.5-0.7,0.8-1.5,1.1-2.2c0.2-0.7,0.3-1.4,0.3-2.1c-0.1-0.7-0.3-1.3-0.8-1.8c-0.5-0.6-1.3-1.1-2.4-1.4c-1.1-0.3-2.2-0.5-3.1-0.5
					c-0.9,0-1.7,0.1-2.5,0.3c-1.5,0.4-3,0.9-4.2,2.3c0.8-0.2,1.5,0,2.1,0.3c0.5,0.3,0.9,0.7,1.2,1.2c0.3,0.5,0.5,1,0.7,1.5
					C494.6,447.8,494.6,448.4,494.8,449.1z"
                  />
                </g>
                <g>
                  <path
                    d="M494.8,445.7c-0.6,1.5-0.3,2.6,0.6,3.6c0.9,1,2.4,1.8,4.1,2c1.7,0.2,3.4-0.6,4.2-2.1c0.9-1.5,0.9-3.7-0.5-5.4
					c-1.4-1.8-2.6-2.7-3.8-3.6c-1.2-0.9-2.3-1.7-3.7-2.8c0.5,1.8,0.5,3.1,0.2,4.4C495.9,443,495.4,444.2,494.8,445.7z"
                  />
                </g>
                <g>
                  <path
                    d="M496.9,441.3c-1.5,1.5-1.9,3.6-1.4,5.2c0.5,1.6,1.9,2.8,3.6,3.1c1.7,0.3,3.4-0.2,4.5-1.2c1.1-1,1.7-2.3,1.5-4.1
					c-0.3-1.8-0.6-3.2-0.7-4.6c-0.1-1.4-0.1-2.8,0.5-4.7c-1.5,1.3-2.6,2.1-3.9,3C499.7,438.9,498.4,439.7,496.9,441.3z"
                  />
                </g>
                <g>
                  <path d="M502.9,441.2c-3.7-0.8-6.3,2.7-5.3,6.1c1,3.4,5,5,7.7,2.3c2.8-2.8,3.6-4.5,6.8-6.6C508.4,443.1,506.8,442,502.9,441.2z" />
                </g>
                <g>
                  <path
                    d="M507.1,444.4c-2.2-2.9-6.2-1.6-7.5,1.7c-1.3,3.3,0.6,6.9,4.2,6.5c3.7-0.5,5.4-1.4,8.9-1.1
					C510,449.2,509.4,447.3,507.1,444.4z"
                  />
                </g>
                <g>
                  <path
                    d="M507.3,448.7c-0.3-1.6-1.5-2.5-2.9-2.6c-1.4-0.1-2.9,0.6-4.1,2c-1.2,1.3-1.7,2.9-1.7,4.1c0.1,1.3,0.7,2.2,2.2,2.4
					c3,0.6,4.8,0.4,7.3,1.9C507.4,453.8,507.9,452.1,507.3,448.7z"
                  />
                </g>
                <g>
                  <path
                    d="M468.5,488.6c-5.3,2.7-9.3,6.4-11.9,10.2c-2.6,3.8-3.8,7.9-2.1,11.1c1.6,3.1,6.1,4.3,11.4,2.9c5.3-1.4,11.3-5.5,15-11.7
					c3.7-6.3,5.4-11.1,7-15.7c1.5-4.7,3-9.2,5-15.7c-3.9,5.5-7.5,8.6-11.2,11.3C477.9,483.5,474,485.7,468.5,488.6z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M426.3,566.6c5.8-2.4,11.9-6.2,16.3-10.7c2.3-2.2,4.3-4.6,5.9-7c1.7-2.4,3-4.8,4-7.2c2.1-4.7,2.9-8.9,2.7-12.1
					c-0.1-1.6-0.4-2.9-0.8-3.9c-0.5-1-1.1-1.6-2-1.9c-0.8-0.3-1.8-0.3-2.8,0c-1,0.3-2.1,0.9-3.2,1.7c-2.3,1.6-4.9,4-7.8,6.8
					c-1.4,1.4-3,2.8-4.6,4.4c-1.6,1.5-3.3,3.1-5.1,4.7c-3.5,3.3-7.3,6.4-11.7,10c-4.5,3.7-8.8,7-12.5,10.3c-3.8,3.3-7,6.6-9.9,10.2
					c-1.4,1.8-2.7,3.6-4,5.5c-1.3,1.9-2.5,4-3.7,6.1c-2.3,4.4-4.5,9.3-6.2,15.5c2.7-5.8,5.9-10,9.1-13.4c1.6-1.7,3.2-3.2,4.9-4.5
					c1.6-1.3,3.3-2.5,5-3.6c3.4-2.1,7-3.8,11.2-5.4C415.5,570.6,420.3,569,426.3,566.6z"
                  />
                </g>
                <g>
                  <path
                    d="M414.5,549.3c4.6-2.9,9.2-5.6,13.6-8.1c4.4-2.5,8.6-4.7,12.2-6.7c3.7-2,6.9-3.9,9.1-5.7c2.3-1.8,3.6-3.6,3.5-5.3
					c-0.2-1.7-1.9-3.3-4.9-4.3c-3-1-7.4-1.4-12.6-0.7c-5.1,0.6-11,2.3-16.8,5.3c-5.7,3-11.4,7.2-15.8,12.4
					c-4.6,5.4-7.8,10.4-10.2,15.1c-2.4,4.7-4.1,9.1-5.4,13.5c-1.4,4.4-2.4,8.9-3.2,13.9c-0.8,5-1.3,10.6-1.3,17.1
					c1-6.5,2.7-11.7,4.7-16.1c2-4.4,4.2-8.1,6.7-11.5c2.5-3.4,5.3-6.5,8.5-9.6C405.9,555.4,409.8,552.3,414.5,549.3z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M372.6,532.7c6.2,0.9,13.4,0.7,19.5-0.9c3.1-0.8,6-1.8,8.7-3c2.7-1.2,5-2.6,7.1-4.1c4.2-3,7-6.2,8.5-9
					c0.7-1.4,1.2-2.7,1.2-3.7c0.1-1.1-0.1-2-0.7-2.7c-0.5-0.7-1.4-1.1-2.4-1.4c-1-0.2-2.3-0.3-3.7-0.2c-2.8,0.2-6.3,0.9-10.2,1.8
					c-1.9,0.4-4,0.9-6.2,1.4c-2.2,0.5-4.4,1-6.8,1.5c-4.7,1-9.5,1.8-15.1,2.6c-5.8,0.9-11.1,1.5-16,2.4c-4.9,0.9-9.4,2.1-13.7,3.7
					c-2.1,0.8-4.2,1.7-6.3,2.7c-2.1,1-4.2,2.1-6.3,3.4c-4.2,2.6-8.7,5.7-13.3,10.1c5.3-3.6,10.2-5.6,14.7-6.9
					c2.3-0.6,4.4-1.1,6.5-1.4c2.1-0.3,4.1-0.5,6.2-0.5c4-0.1,7.9,0.3,12.4,1.1C361.2,530.7,366.2,531.8,372.6,532.7z"
                  />
                </g>
                <g>
                  <path
                    d="M371.3,511.9c5.5-0.2,10.8-0.1,15.8,0c5,0.1,9.8,0.3,14,0.5c4.2,0.1,7.9,0.1,10.8-0.2c2.9-0.4,4.9-1.2,5.7-2.8
					c0.7-1.6,0.1-3.8-2.1-6.2c-2.1-2.4-5.7-5-10.4-7.1c-4.7-2.1-10.7-3.6-17.1-4c-6.5-0.3-13.4,0.4-20,2.6c-6.7,2.2-12,4.9-16.5,7.7
					c-4.4,2.8-8.1,5.7-11.6,8.8c-3.4,3.1-6.6,6.4-9.9,10.3c-3.2,3.9-6.6,8.4-9.8,14c4.2-5,8.3-8.7,12.2-11.5c4-2.8,7.8-4.8,11.6-6.5
					c3.9-1.6,7.8-2.9,12.2-3.9C360.8,512.7,365.7,512,371.3,511.9z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M514.4,139c10.6-1.1,21.3-3.7,30.7-7.7c9.4-3.9,17.6-9.3,23.7-14.8c6.1-5.5,10.2-11.1,12.5-15.3
					c2.3-4.2,2.9-6.9,2.1-7.4c-1.6-1-7.7,7.5-19.9,16.2c-6.1,4.3-13.6,8.7-22.2,12.2c-8.6,3.5-18.2,6.3-28.2,8.1
					c-10.2,1.9-19.1,2.6-27.2,3.2c-8.1,0.6-15.5,1.1-23,1.9c-7.4,0.8-14.9,1.9-23.1,4.1c-4.1,1.1-8.3,2.5-12.7,4.4
					c-4.4,1.9-8.9,4.3-13.4,7.6c9.3-6,18.4-8.6,26.5-10.2c8.2-1.5,15.6-1.9,22.9-2c7.4-0.1,14.7,0.2,22.9,0.5
					C494.2,139.9,503.4,140.1,514.4,139z"
                  />
                </g>
                <g>
                  <path
                    d="M458.8,128.6c2.7-1.5,4.5-4,5.1-6.3c0.6-2.3,0-4.4-1.5-5.3c-1.5-0.9-3.5-0.6-5.3,0.4c-1.8,1-3.5,2.7-4.6,5.2
					c-1.1,2.5-2,4.3-3,6c-1,1.7-2.2,3.4-4.4,5.4c2.8-1.1,4.7-1.7,6.8-2.4C454,130.8,456,130.1,458.8,128.6z"
                  />
                </g>
                <g>
                  <path
                    d="M475.9,123.1c2.3-1.3,3.6-3.6,3.8-5.6c0.2-2.1-0.6-3.8-2.1-4.7c-1.6-0.8-3.4-0.6-5,0.2c-1.6,0.8-2.9,2.3-3.5,4.4
					c-0.7,2.2-1.1,3.8-1.7,5.4c-0.6,1.6-1.4,3.1-3,5c2.3-1,3.9-1.6,5.6-2.2C471.8,125,473.6,124.4,475.9,123.1z"
                  />
                </g>
                <g>
                  <path
                    d="M493,119.2c2.2-1.6,3.5-4,3.7-6.1c0.2-2.1-0.5-4-2.1-4.8c-1.5-0.8-3.5-0.5-5.1,0.5c-1.7,1.1-3.1,2.9-3.7,5.3
					c-0.6,2.5-1,4.3-1.5,6.1c-0.5,1.8-1.2,3.6-2.5,5.8c2.2-1.6,3.8-2.5,5.5-3.5C488.9,121.7,490.7,120.8,493,119.2z"
                  />
                </g>
                <g>
                  <path
                    d="M512.9,117.7c2.4-2.2,3.5-5.3,3.4-7.8c-0.1-2.5-1.3-4.3-3-4.6c-1.7-0.4-3.5,0.6-4.9,2c-1.4,1.4-2.5,3.4-2.9,5.9
					c-0.5,2.6-1,4.5-1.6,6.3c-0.7,1.8-1.6,3.7-3.7,5.8c2.7-1.3,4.5-2.3,6.5-3.3C508.4,121,510.4,119.9,512.9,117.7z"
                  />
                </g>
                <g>
                  <path
                    d="M529.5,112.5c2.1-2.5,3.1-5.6,2.9-8.1c-0.1-2.5-1.2-4.4-2.9-4.8c-1.7-0.5-3.6,0.5-5,2.2c-1.5,1.7-2.6,4.1-3,7
					c-0.4,3-0.6,5.1-1,7.2c-0.4,2.1-1,4.3-2.4,7.1c2.3-2.2,4-3.7,5.7-5.2C525.6,116.6,527.3,115.1,529.5,112.5z"
                  />
                </g>
                <g>
                  <path
                    d="M541.5,108.7c2-2,2.9-4.7,2.9-7c-0.1-2.2-1.2-4-2.8-4.5c-1.7-0.5-3.5,0.2-5,1.5c-1.5,1.4-2.6,3.4-2.9,6
					c-0.3,2.6-0.5,4.5-0.8,6.4c-0.3,1.9-0.8,3.8-2,6.3c2.1-1.9,3.6-3.1,5.2-4.3C537.8,112,539.4,110.8,541.5,108.7z"
                  />
                </g>
                <g>
                  <path
                    d="M558.6,100c2.1-2.3,3.1-5.3,3-7.7c0-2.4-1.1-4.3-2.7-4.9c-1.7-0.5-3.6,0.3-5.1,1.9c-1.5,1.6-2.7,4-3.1,6.8
					c-0.4,2.9-0.6,5-0.9,7.1c-0.4,2.1-0.9,4.2-2.2,7c2.2-2.2,3.8-3.7,5.5-5.1C554.7,103.8,556.5,102.4,558.6,100z"
                  />
                </g>
                <g>
                  <path
                    d="M573,93.6c2.2-1.8,3.6-4.1,4.1-6.2c0.5-2.2,0.2-4.2-1.2-5.3c-1.4-1.1-3.5-0.9-5.5,0.3c-2,1.2-3.9,3.5-4.7,6.5
					c-0.8,3-1.2,5.2-1.5,7.3c-0.4,2.1-0.8,4.2-1.6,7.1c1.8-2.4,3.3-3.9,4.9-5.2C569,96.6,570.7,95.4,573,93.6z"
                  />
                </g>
                <g>
                  <path
                    d="M587.3,86.9c2.3-1.1,3.9-2.9,4.8-4.7c0.8-1.8,0.9-3.8-0.1-5.2c-1-1.4-3.1-1.9-5.2-1.2c-2.2,0.6-4.5,2.4-5.7,5.1
					c-1.3,2.7-1.9,4.7-2.5,6.7c-0.6,2-1.2,3.9-2.3,6.5c1.9-2,3.6-3.2,5.3-4.1C583.1,88.9,584.9,88.1,587.3,86.9z"
                  />
                </g>
                <g>
                  <path
                    d="M602.4,93.3c2.3,0.6,4.4,0.3,6.1-0.6c1.7-0.9,3-2.3,3.1-4.1c0.1-1.7-1-3.4-3.1-4.3c-2-0.9-4.8-1-7.4,0.3
					c-2.6,1.3-4.2,2.6-5.7,3.7c-1.5,1.2-3.1,2.4-5.3,3.9c2.6-0.5,4.5-0.5,6.3-0.2C598.3,92.3,600,92.7,602.4,93.3z"
                  />
                </g>
                <g>
                  <path
                    d="M602.3,107.3c2.1,1.2,4.2,1.5,6.1,1.3c1.9-0.3,3.6-1.1,4.4-2.7c0.8-1.6,0.2-3.6-1.6-5.2c-1.8-1.6-4.7-2.6-7.7-2.1
					c-3.1,0.5-5.1,1.4-7,2.2c-2,0.9-3.8,1.7-6.3,2.8c2.8-0.1,4.7,0.4,6.5,1C598.4,105.2,600.1,106.1,602.3,107.3z"
                  />
                </g>
                <g>
                  <path
                    d="M598.1,120.2c2.4,1.8,5,2.6,7.4,2.6c2.3,0,4.2-0.9,4.9-2.5c0.7-1.6-0.1-3.6-1.8-5.3c-1.7-1.6-4.4-2.9-7.5-3.1
					c-6.3-0.3-8.9,0.5-15.1-0.2C591.6,114.5,593.3,116.5,598.1,120.2z"
                  />
                </g>
                <g>
                  <path
                    d="M455.6,157.1c1.3,2.2,2.9,3.7,4.7,4.7c1.8,0.9,3.8,1.2,5.3,0.3c1.5-0.9,2.1-3,1.3-5.4c-0.7-2.4-2.8-5-5.7-6.3
					c-3-1.3-5.3-1.7-7.4-2.1c-2.2-0.4-4.3-0.7-7.2-1.1c2.6,1.4,4.1,2.9,5.4,4.4C453.2,153.1,454.3,154.8,455.6,157.1z"
                  />
                </g>
                <g>
                  <path
                    d="M472.5,157.1c1.1,2.4,2.4,4.3,3.9,5.8c1.5,1.5,3.1,2.7,4.9,2.5c0.9-0.1,1.6-0.6,2.2-1.6c0.6-0.9,0.9-2.2,0.8-3.8
					c-0.1-1.5-0.5-3.3-1.5-4.9c-0.9-1.6-2.3-3.2-4-4.3c-1.8-1.1-3.4-1.8-4.8-2.2c-1.5-0.4-2.8-0.7-4.1-0.8c-2.6-0.3-5.1-0.3-8.6,0.2
					c3.4,0.6,5.5,1.9,7.1,3.3C470,152.9,471.3,154.7,472.5,157.1z"
                  />
                </g>
                <g>
                  <path
                    d="M487.8,156.6c1.5,2.4,3.4,4.2,5.4,5.3c1.9,1.1,4,1.5,5.5,0.6c1.5-0.9,2-3.1,1.1-5.7c-0.9-2.6-3.1-5.4-6.4-6.9
					c-3.3-1.5-5.7-2.1-8.1-2.5c-2.4-0.5-4.7-0.9-8-1.4c3,1.5,4.7,3.1,6.1,4.7C484.9,152.4,486.2,154.1,487.8,156.6z"
                  />
                </g>
                <g>
                  <path
                    d="M506.5,159.3c2.5,2.3,5.7,3.4,8.3,3.4c2.6,0,4.6-1.1,5.1-2.7c0.5-1.7-0.5-3.6-2.2-5.1c-1.7-1.6-4.2-2.8-7.2-3.3
					c-3-0.5-5.2-0.9-7.4-1.4c-2.2-0.5-4.3-1.3-7.2-2.9c2.2,2.5,3.6,4.3,5.1,6.1C502.5,155.1,504,157,506.5,159.3z"
                  />
                </g>
                <g>
                  <path
                    d="M525.3,155.5c3.6,1.7,7.5,2.2,10.5,1.7c3-0.5,5.1-1.9,5.2-3.6c0.2-1.8-1.5-3.5-4.1-4.7c-2.6-1.2-6-2-9.7-2
					c-3.8,0.1-6.5,0.2-9.2,0.1c-2.7-0.1-5.5-0.3-9.4-1.3c3.5,1.9,5.9,3.4,8.3,4.9C519.2,152.2,521.6,153.7,525.3,155.5z"
                  />
                </g>
                <g>
                  <path
                    d="M549,150.1c6.4,2.5,13.2,0.2,13.4-3.3c0.2-3.5-6-6.6-12.5-5.4c-6.7,1.1-9.4,2.3-16.4,2.3
					C540.3,145.7,542.5,147.5,549,150.1z"
                  />
                </g>
                <g>
                  <path
                    d="M568.8,144.1c3.4,1.2,7.1,1.2,9.7,0.4c2.7-0.8,4.5-2.3,4.5-4.1c0-1.8-1.7-3.3-4.2-4.3c-2.5-1-5.8-1.4-9.2-0.8
					c-3.5,0.6-6,1.1-8.5,1.5c-2.5,0.4-5.1,0.6-8.8,0.4c3.5,1.2,5.9,2.3,8.2,3.4C562.9,141.7,565.2,142.8,568.8,144.1z"
                  />
                </g>
                <g>
                  <path
                    d="M580.9,135.9c1.6,1.6,3.5,2.2,5.3,2.2c1.8,0,3.5-0.8,4.4-2.3c0.9-1.5,0.7-3.5-0.5-5.1c-1.2-1.6-3.4-2.9-6.1-3
					c-2.7,0-4.6,0.3-6.4,0.5c-1.8,0.3-3.6,0.5-6,0.6c2.3,0.9,3.7,1.9,5,3C578.1,133,579.3,134.2,580.9,135.9z"
                  />
                </g>
                <g>
                  <path
                    d="M591.4,129.9c3.6,4.4,9.6,5,11.5,2c1.9-3-1.3-8.1-6.8-9.4c-5.7-1.4-8.2-1.2-13.6-3.3
					C586.8,123.1,587.7,125.4,591.4,129.9z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M191.4,346.1c2,10.5,5.5,20.9,10.2,29.9c4.7,9.1,10.8,16.7,16.8,22.3c6,5.6,11.9,9.2,16.3,11.1c4.4,1.9,7.2,2.3,7.5,1.5
					c0.9-1.7-8.1-7.1-17.8-18.4c-4.8-5.7-9.8-12.8-14-21.1c-4.2-8.3-7.8-17.6-10.5-27.4c-2.8-10-4.2-18.8-5.5-26.9
					c-1.3-8.1-2.4-15.4-3.8-22.7c-1.4-7.3-3.2-14.7-6-22.6c-1.4-4-3.2-8.1-5.4-12.3c-2.2-4.2-5.1-8.5-8.7-12.7
					c6.8,8.8,10.2,17.6,12.4,25.5c2.2,8,3.2,15.3,4,22.6c0.7,7.3,1,14.6,1.5,22.8C188.8,326.1,189.3,335.3,191.4,346.1z"
                  />
                </g>
                <g>
                  <path
                    d="M197,289.9c1.7,2.6,4.4,4.1,6.8,4.5c2.4,0.4,4.4-0.3,5.2-1.9c0.8-1.6,0.3-3.5-0.8-5.3c-1.2-1.7-3-3.3-5.5-4.1
					c-2.6-0.9-4.5-1.6-6.3-2.5c-1.8-0.9-3.6-1.9-5.7-3.9c1.3,2.7,2.1,4.6,3,6.6C194.4,285.3,195.3,287.3,197,289.9z"
                  />
                </g>
                <g>
                  <path
                    d="M204,306.5c1.5,2.2,3.9,3.3,5.9,3.4c2.1,0.1,3.8-0.9,4.5-2.5c0.7-1.6,0.3-3.5-0.6-4.9c-1-1.5-2.5-2.6-4.7-3.1
					c-2.2-0.5-3.9-0.8-5.6-1.3c-1.6-0.5-3.2-1.2-5.2-2.6c1.2,2.2,1.9,3.8,2.7,5.4C201.7,302.5,202.5,304.3,204,306.5z"
                  />
                </g>
                <g>
                  <path
                    d="M209.3,323.1c1.8,2.1,4.2,3.1,6.4,3.2c2.1,0.1,3.9-0.8,4.6-2.5c0.7-1.6,0.2-3.5-1-5.1c-1.2-1.6-3.1-2.8-5.6-3.2
					c-2.5-0.4-4.4-0.6-6.2-1c-1.8-0.4-3.6-0.9-6-2c1.7,2,2.8,3.6,3.9,5.2C206.4,319.3,207.5,321,209.3,323.1z"
                  />
                </g>
                <g>
                  <path
                    d="M212.5,342.8c2.4,2.2,5.6,3,8,2.7c2.5-0.3,4.1-1.7,4.3-3.4c0.2-1.8-0.9-3.4-2.4-4.7c-1.5-1.3-3.6-2.2-6.1-2.4
					c-2.6-0.3-4.5-0.6-6.4-1.1c-1.9-0.5-3.8-1.3-6.1-3.2c1.5,2.5,2.6,4.3,3.8,6.2C208.8,338.7,210.1,340.6,212.5,342.8z"
                  />
                </g>
                <g>
                  <path
                    d="M219.1,359c2.6,1.9,5.8,2.6,8.3,2.2c2.5-0.3,4.3-1.6,4.6-3.3c0.3-1.7-0.8-3.5-2.7-4.8c-1.8-1.3-4.4-2.3-7.3-2.4
					c-3-0.1-5.1-0.1-7.3-0.4c-2.2-0.2-4.3-0.6-7.3-1.8c2.4,2.1,4,3.6,5.6,5.2C214.7,355.4,216.3,357,219.1,359z"
                  />
                </g>
                <g>
                  <path
                    d="M223.9,370.7c2.2,1.9,5,2.5,7.2,2.2c2.2-0.3,3.9-1.5,4.3-3.2c0.4-1.7-0.5-3.5-2-4.8c-1.5-1.4-3.7-2.3-6.2-2.3
					c-2.6-0.1-4.6-0.1-6.5-0.2c-1.9-0.2-3.8-0.5-6.4-1.5c2,1.9,3.4,3.4,4.7,4.9C220.3,367.2,221.6,368.7,223.9,370.7z"
                  />
                </g>
                <g>
                  <path
                    d="M234,386.9c2.5,1.9,5.5,2.6,7.9,2.4c2.4-0.2,4.2-1.4,4.6-3.1c0.4-1.7-0.6-3.5-2.4-4.9c-1.8-1.4-4.2-2.4-7.1-2.5
					c-3-0.1-5.1-0.2-7.2-0.3c-2.1-0.2-4.2-0.5-7.2-1.6c2.4,2,4,3.5,5.6,5C229.9,383.4,231.4,385,234,386.9z"
                  />
                </g>
                <g>
                  <path
                    d="M241.6,400.7c2,2.1,4.3,3.2,6.5,3.5c2.2,0.3,4.2-0.2,5.2-1.7c1-1.5,0.6-3.6-0.8-5.5c-1.4-1.9-3.8-3.6-6.8-4.2
					c-3.1-0.6-5.3-0.7-7.4-0.9c-2.1-0.2-4.3-0.4-7.2-1c2.6,1.6,4.2,3,5.6,4.5C238.2,397,239.6,398.6,241.6,400.7z"
                  />
                </g>
                <g>
                  <path
                    d="M249.5,414.4c1.3,2.2,3.2,3.7,5.1,4.3c1.9,0.7,3.9,0.6,5.2-0.6c1.3-1.2,1.6-3.2,0.7-5.3c-0.8-2.1-2.8-4.2-5.5-5.3
					c-2.8-1.1-4.9-1.5-6.9-1.9c-2-0.4-3.9-0.9-6.6-1.7c2.2,1.8,3.5,3.3,4.6,4.9C247.1,410.4,248.1,412.1,249.5,414.4z"
                  />
                </g>
                <g>
                  <path
                    d="M244.4,430c-0.4,2.4,0.1,4.4,1.1,6c1,1.6,2.6,2.7,4.3,2.7c1.8,0,3.3-1.3,4-3.4c0.7-2.1,0.6-4.9-0.9-7.3
					c-1.5-2.5-2.9-4-4.2-5.4c-1.3-1.4-2.6-2.9-4.3-4.9c0.7,2.6,0.8,4.4,0.7,6.2C245.1,425.8,244.8,427.6,244.4,430z"
                  />
                </g>
                <g>
                  <path
                    d="M230.5,431.1c-1,2.2-1.2,4.3-0.7,6.2c0.4,1.9,1.4,3.5,3.1,4.2c1.6,0.6,3.6-0.1,5-2.1c1.4-1.9,2.2-4.9,1.4-7.9
					c-0.8-3-1.8-4.9-2.8-6.8c-1-1.9-2-3.7-3.3-6.1c0.3,2.8,0,4.7-0.4,6.6C232.2,427,231.5,428.8,230.5,431.1z"
                  />
                </g>
                <g>
                  <path
                    d="M217.3,428c-1.6,2.5-2.2,5.3-1.9,7.5c0.2,2.3,1.2,4.1,2.9,4.7c1.7,0.5,3.6-0.4,5.1-2.2c1.5-1.9,2.5-4.7,2.4-7.7
					c-0.3-6.3-1.2-8.8-1.1-15C222.4,421,220.5,422.8,217.3,428z"
                  />
                </g>
                <g>
                  <path
                    d="M168.4,289.1c-2.1,1.5-3.5,3.2-4.2,5.1c-0.7,1.9-0.9,3.9,0.2,5.3c1,1.4,3.2,1.8,5.5,0.9c2.3-0.9,4.7-3.2,5.8-6.3
					c1.1-3.2,1.3-5.4,1.5-7.6c0.2-2.2,0.3-4.3,0.4-7.3c-1.2,2.7-2.5,4.3-3.9,5.7C172.1,286.4,170.5,287.6,168.4,289.1z"
                  />
                </g>
                <g>
                  <path
                    d="M169.7,305.9c-2.3,1.3-4,2.7-5.5,4.3c-1.4,1.6-2.5,3.4-2.1,5.1c0.2,0.9,0.8,1.6,1.7,2c1,0.5,2.3,0.7,3.8,0.5
					c1.5-0.2,3.2-0.8,4.8-1.9c1.5-1.1,3-2.6,3.9-4.4c1-1.9,1.5-3.5,1.8-5c0.3-1.5,0.4-2.8,0.5-4.1c0-2.6-0.1-5.2-0.9-8.6
					c-0.3,3.5-1.4,5.6-2.7,7.4C173.7,303.1,172.1,304.6,169.7,305.9z"
                  />
                </g>
                <g>
                  <path
                    d="M171.5,321.1c-2.3,1.7-3.9,3.7-4.8,5.8c-0.9,2-1.2,4.1-0.1,5.5c1,1.4,3.3,1.7,5.8,0.6c2.5-1.1,5.1-3.6,6.3-6.9
					c1.2-3.4,1.6-5.9,1.8-8.3c0.3-2.4,0.5-4.7,0.7-8.1c-1.3,3.1-2.6,4.9-4.1,6.5C175.6,317.9,173.9,319.3,171.5,321.1z"
                  />
                </g>
                <g>
                  <path
                    d="M170.5,340.1c-2.1,2.7-2.9,6-2.6,8.5c0.2,2.6,1.4,4.5,3.2,4.8c1.7,0.3,3.5-0.8,4.9-2.6c1.4-1.9,2.4-4.4,2.7-7.4
					c0.3-3.1,0.4-5.3,0.8-7.5c0.4-2.2,0.9-4.4,2.3-7.4c-2.3,2.4-3.9,4-5.6,5.6C174.4,335.7,172.6,337.3,170.5,340.1z"
                  />
                </g>
                <g>
                  <path
                    d="M175.9,358.4c-1.4,3.7-1.5,7.7-0.8,10.6c0.7,3,2.3,4.9,4,4.9c1.8,0,3.3-1.8,4.3-4.5c1-2.7,1.5-6.2,1.1-9.9
					c-0.4-3.8-0.7-6.5-0.9-9.2c-0.2-2.7-0.2-5.5,0.4-9.4c-1.6,3.7-2.9,6.2-4.2,8.7C178.7,352.1,177.4,354.6,175.9,358.4z"
                  />
                </g>
                <g>
                  <path
                    d="M183.3,381.6c-1.9,6.6,0.9,13.2,4.4,13.1c3.5-0.1,6-6.5,4.4-12.9c-1.7-6.6-3.1-9.1-3.7-16.1
					C186.9,372.5,185.3,374.9,183.3,381.6z"
                  />
                </g>
                <g>
                  <path
                    d="M190.9,400.8c-0.9,3.5-0.6,7.1,0.4,9.7c1,2.6,2.7,4.3,4.5,4.1c1.8-0.1,3.2-2,3.9-4.5c0.8-2.6,0.9-5.9,0-9.3
					c-0.9-3.5-1.6-5.9-2.2-8.4c-0.6-2.5-1-5-1.2-8.7c-0.9,3.6-1.8,6-2.6,8.5C192.8,394.7,191.9,397.2,190.9,400.8z"
                  />
                </g>
                <g>
                  <path
                    d="M200.2,412.2c-1.4,1.7-1.9,3.7-1.8,5.5c0.2,1.8,1.1,3.4,2.7,4.2c1.6,0.8,3.5,0.4,5-1c1.5-1.3,2.6-3.7,2.5-6.3
					c-0.2-2.7-0.6-4.5-1.1-6.3c-0.4-1.8-0.8-3.5-1.1-6c-0.7,2.4-1.6,3.9-2.6,5.3C202.8,409.1,201.7,410.4,200.2,412.2z"
                  />
                </g>
                <g>
                  <path d="M207,422.2c-4,4-4.1,10-1,11.6c3.1,1.6,8-2,8.8-7.6c0.9-5.8,0.5-8.2,2.1-13.8C213.4,417,211.1,418.1,207,422.2z" />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M378.5,8.1c9.5-5,18.3-11.4,25.5-18.6c7.3-7.2,12.8-15.2,16.3-22.6c3.6-7.4,5.3-14.1,5.8-18.9c0.5-4.8,0-7.5-0.8-7.6
					c-1.9-0.3-4.3,9.8-12.3,22.5c-4,6.3-9.3,13.2-16,19.6c-6.6,6.5-14.6,12.7-23,18.1c-8.7,5.6-16.7,9.6-24,13.2
					c-7.3,3.6-14,6.8-20.6,10.4c-6.6,3.6-13.1,7.4-19.8,12.5c-3.4,2.5-6.8,5.4-10.1,8.8c-3.4,3.4-6.6,7.4-9.6,12
					c6.4-9.1,13.8-14.9,20.7-19.4c7-4.5,13.7-7.6,20.5-10.5c6.8-2.8,13.7-5.3,21.4-8.2C360.1,16.5,368.7,13.3,378.5,8.1z"
                  />
                </g>
                <g>
                  <path
                    d="M323.1,19.3c2-2.4,2.7-5.4,2.3-7.8c-0.3-2.4-1.6-4.1-3.3-4.4c-1.7-0.3-3.5,0.7-4.8,2.3c-1.3,1.6-2.2,3.9-2.3,6.5
					c-0.1,2.7-0.2,4.7-0.5,6.7c-0.3,2-0.8,4-2.1,6.6c2.2-2,3.7-3.4,5.4-4.8C319.4,23.2,321.1,21.8,323.1,19.3z"
                  />
                </g>
                <g>
                  <path
                    d="M336.9,7.8c1.6-2.1,2-4.7,1.5-6.6c-0.5-2-2-3.4-3.7-3.5c-1.8-0.2-3.4,0.7-4.5,2.1c-1.1,1.4-1.8,3.2-1.6,5.4
					c0.2,2.3,0.4,4,0.4,5.7c0,1.7-0.1,3.4-1,5.8c1.7-1.8,3-3,4.4-4.2C333.8,11.1,335.2,9.9,336.9,7.8z"
                  />
                </g>
                <g>
                  <path
                    d="M351.2-2.2c1.5-2.3,1.7-5,1.1-7c-0.6-2.1-2-3.5-3.7-3.7c-1.8-0.2-3.4,0.8-4.6,2.4c-1.1,1.6-1.8,3.8-1.4,6.3
					c0.4,2.6,0.7,4.4,0.9,6.3c0.2,1.9,0.3,3.7-0.1,6.4c1.4-2.3,2.6-3.8,3.8-5.3C348.4,1.6,349.7,0.1,351.2-2.2z"
                  />
                </g>
                <g>
                  <path
                    d="M369.1-11.1c1.4-2.9,1.2-6.2,0.2-8.4c-1-2.3-2.9-3.4-4.6-3.1c-1.7,0.3-3,1.8-3.8,3.7c-0.8,1.9-1,4.1-0.5,6.5
					c0.5,2.6,0.8,4.5,0.9,6.5c0,2-0.1,4-1.2,6.8c2-2.2,3.4-3.8,4.8-5.5C366.2-6.4,367.6-8.1,369.1-11.1z"
                  />
                </g>
                <g>
                  <path
                    d="M382.6-22.2c1-3.1,0.7-6.3-0.3-8.6c-1-2.3-2.8-3.6-4.5-3.4c-1.8,0.2-3.1,1.8-3.8,4c-0.7,2.1-0.9,4.8-0.1,7.6
					c0.8,2.9,1.4,5,1.8,7.1c0.4,2.1,0.7,4.3,0.4,7.5c1.3-2.9,2.3-4.9,3.3-6.9C380.4-16.9,381.5-19,382.6-22.2z"
                  />
                </g>
                <g>
                  <path
                    d="M392.3-30.2c1.1-2.7,1-5.5,0-7.5c-0.9-2-2.6-3.3-4.3-3.1c-1.8,0.1-3.2,1.5-4,3.3c-0.8,1.8-1.1,4.2-0.4,6.6
					c0.7,2.5,1.3,4.4,1.7,6.3c0.4,1.9,0.7,3.8,0.5,6.6c1.2-2.5,2.2-4.2,3.2-5.9C390-25.7,391.1-27.5,392.3-30.2z"
                  />
                </g>
                <g>
                  <path
                    d="M404.8-44.7c1.1-3,0.9-6-0.1-8.3c-1-2.2-2.6-3.6-4.4-3.5c-1.8,0.1-3.2,1.6-4,3.7c-0.8,2.1-1,4.7-0.3,7.5
					c0.8,2.9,1.4,4.9,1.8,7c0.4,2.1,0.8,4.2,0.6,7.3c1.2-2.9,2.1-4.8,3.1-6.8C402.6-39.7,403.7-41.7,404.8-44.7z"
                  />
                </g>
                <g>
                  <path
                    d="M415.7-56.1c1.4-2.5,1.8-5.1,1.4-7.3c-0.3-2.2-1.4-3.9-3.1-4.4c-1.7-0.5-3.6,0.4-5,2.3c-1.4,1.9-2.3,4.7-1.9,7.8
					c0.4,3.1,0.9,5.2,1.3,7.3c0.5,2.1,0.9,4.2,1.2,7.2c0.8-2.9,1.6-4.9,2.6-6.7C413.2-51.8,414.3-53.5,415.7-56.1z"
                  />
                </g>
                <g>
                  <path
                    d="M426.4-67.6c1.7-1.9,2.5-4.1,2.6-6.1c0.1-2-0.6-3.9-2.1-4.8c-1.5-0.9-3.5-0.6-5.3,0.9c-1.8,1.4-3.2,3.9-3.4,6.8
					c-0.2,3,0,5.1,0.2,7.1c0.2,2,0.3,4,0.3,6.9c1-2.6,2.1-4.3,3.3-5.8C423.3-64.2,424.7-65.6,426.4-67.6z"
                  />
                </g>
                <g>
                  <path
                    d="M442.9-67.4c2.4-0.3,4.2-1.4,5.4-2.9c1.2-1.4,1.9-3.2,1.3-4.9c-0.5-1.7-2.2-2.8-4.4-2.8c-2.2-0.1-4.8,0.9-6.7,3.1
					c-1.9,2.2-2.9,3.9-3.9,5.6c-1,1.7-2,3.3-3.4,5.6c2.2-1.5,4-2.1,5.7-2.6C438.6-66.8,440.4-67.1,442.9-67.4z"
                  />
                </g>
                <g>
                  <path
                    d="M448-54.4c2.4,0.3,4.5-0.2,6.2-1.1c1.7-1,3-2.4,3.1-4.2c0.1-1.7-1.2-3.4-3.4-4.2c-2.2-0.8-5.3-0.6-7.9,1
					c-2.7,1.7-4.2,3.2-5.7,4.6c-1.5,1.6-2.9,3-4.8,5c2.6-1.1,4.5-1.4,6.4-1.5C443.6-54.9,445.5-54.7,448-54.4z"
                  />
                </g>
                <g>
                  <path
                    d="M449-40.9c2.9,0.8,5.7,0.5,7.8-0.4c2.1-0.9,3.6-2.4,3.6-4.2c0-1.8-1.4-3.3-3.6-4.2c-2.2-0.9-5.2-1-8.1,0
					c-6,2.1-8,3.8-14,5.5C440.8-43.7,443.1-42.4,449-40.9z"
                  />
                </g>
                <g>
                  <path
                    d="M330.8,46.9c2,1.6,4.1,2.4,6.1,2.5c2,0.2,3.9-0.3,5-1.7c1-1.4,0.8-3.5-0.8-5.5c-1.5-1.9-4.4-3.6-7.7-3.6
					c-3.3-0.1-5.5,0.4-7.7,0.8c-2.1,0.5-4.2,1-7.1,1.7c3,0.3,4.9,1.1,6.6,2C327.1,44.2,328.7,45.3,330.8,46.9z"
                  />
                </g>
                <g>
                  <path
                    d="M346.5,40.6c1.9,1.8,3.8,3.1,5.8,3.9c1.9,0.8,3.9,1.4,5.5,0.5c0.8-0.4,1.3-1.2,1.4-2.3c0.2-1.1,0-2.4-0.7-3.8
					c-0.6-1.4-1.7-2.8-3.2-4c-1.5-1.2-3.3-2.1-5.3-2.4c-2.1-0.4-3.8-0.4-5.3-0.3c-1.5,0.1-2.8,0.4-4.1,0.8c-2.5,0.7-4.9,1.6-7.9,3.4
					c3.4-0.8,5.8-0.3,7.9,0.4C342.6,37.7,344.5,38.8,346.5,40.6z"
                  />
                </g>
                <g>
                  <path
                    d="M360.5,34.4c2.3,1.7,4.7,2.6,6.9,2.9c2.2,0.3,4.3-0.1,5.3-1.5c1-1.4,0.7-3.6-1.1-5.7c-1.8-2.1-4.9-3.8-8.5-4
					c-3.6-0.2-6.1,0.3-8.5,0.7c-2.4,0.5-4.7,1-7.9,1.7c3.3,0.3,5.5,1.1,7.4,2C356.2,31.5,358.1,32.7,360.5,34.4z"
                  />
                </g>
                <g>
                  <path
                    d="M378.8,29.8c3.2,1.2,6.5,1,8.9,0c2.4-1,3.8-2.7,3.7-4.4c-0.2-1.8-1.8-3.1-4-3.9c-2.2-0.8-5-1-7.9-0.4
					c-3,0.7-5.2,1.2-7.4,1.5c-2.2,0.3-4.5,0.4-7.8,0c3,1.5,5,2.6,7,3.7C373.5,27.4,375.6,28.6,378.8,29.8z"
                  />
                </g>
                <g>
                  <path
                    d="M394.8,19.2c4,0.3,7.8-0.8,10.4-2.4c2.6-1.6,4-3.6,3.5-5.3c-0.5-1.7-2.7-2.6-5.6-2.8c-2.9-0.2-6.4,0.4-9.8,1.9
					c-3.5,1.5-6,2.6-8.5,3.6c-2.5,1-5.2,1.8-9.2,2.4c4,0.5,6.7,0.9,9.5,1.4C387.9,18.4,390.7,19,394.8,19.2z"
                  />
                </g>
                <g>
                  <path
                    d="M414.8,5.3c6.9-0.1,12.4-4.8,11.2-8.1c-1.2-3.3-8-3.8-13.6-0.3c-5.8,3.6-7.8,5.6-14.3,8.3C405,4.5,407.7,5.3,414.8,5.3z
					"
                  />
                </g>
                <g>
                  <path
                    d="M430.8-7.7c3.7-0.2,7-1.5,9.2-3.3c2.2-1.7,3.3-3.8,2.6-5.5c-0.7-1.6-2.8-2.4-5.5-2.4c-2.7,0-5.9,0.9-8.8,2.7
					c-3,1.9-5.1,3.3-7.3,4.6c-2.2,1.3-4.5,2.5-8,3.7c3.7-0.2,6.3-0.1,8.9,0C424.5-7.7,427.1-7.5,430.8-7.7z"
                  />
                </g>
                <g>
                  <path
                    d="M439-19.9c2.1,0.9,4.1,0.8,5.8,0c1.6-0.7,3-2.1,3.3-3.8c0.3-1.7-0.7-3.5-2.4-4.5c-1.7-1.1-4.3-1.4-6.7-0.5
					c-2.5,1-4.1,2-5.7,2.9c-1.6,0.9-3.1,1.8-5.4,2.9c2.5,0,4.2,0.4,5.8,0.9C435.2-21.5,436.8-20.8,439-19.9z"
                  />
                </g>
                <g>
                  <path d="M446.5-29.3c5,2.7,10.8,1,11.4-2.5c0.6-3.5-4.3-7-9.9-6.2c-5.8,0.9-8,2-13.8,2C439.6-33.9,441.3-32.1,446.5-29.3z" />
                </g>
              </g>
              <g>
                <path
                  d="M533.7,299.2c0.6-0.6,1.1-1.2,1.6-1.8c0.5-0.7,1-1.3,1.5-2c0.9-1.4,1.8-2.8,2.4-4.3c1.4-3,2.2-6.2,2.6-9.4
				c0.8-6.4-0.3-12.8-2.8-18.6c-1.2-2.9-3-5.7-5-8.1c-2-2.4-4.2-4.5-6.6-6.4c-4.8-3.6-10.2-6.1-15.6-7.6c-2.7-0.7-5.5-1.3-8.2-1.5
				c-2.7-0.3-5.5-0.3-8.1-0.2c-5.4,0.3-10.8,1.5-15.7,3.8c-5,2.3-9.4,5.8-12.6,10c-1.6,2.1-2.9,4.5-3.9,6.9
				c-0.9,2.4-1.5,5.1-1.6,7.7c-0.3,5.1,1,10,3.5,14.1c1.3,2,2.9,3.8,4.7,5.2c1.8,1.4,3.8,2.4,5.8,3.1c4,1.3,8,1.3,11.5,0.3
				c3.5-1,6.4-3.3,8-5.7c1.7-2.4,2.4-4.9,2.6-7c0.2-2.1-0.1-4-0.6-5.3c-0.5-1.4-1.2-2.3-1.7-2.8c-0.6-0.5-1-0.6-1.3-0.5
				c-0.6,0.3-0.4,1.7-0.7,3.8c-0.1,1-0.4,2.2-1.1,3.5c-0.6,1.2-1.5,2.6-2.7,3.6c-0.6,0.5-1.3,0.9-2,1.3c-0.7,0.3-1.6,0.5-2.5,0.6
				c-1.8,0.2-3.8-0.2-5.7-1.2c-0.9-0.5-1.8-1.1-2.5-1.9c-0.7-0.7-1.3-1.6-1.8-2.7c-1-2.1-1.4-4.7-1-7.3c0.2-1.3,0.6-2.5,1.2-3.8
				c0.6-1.2,1.4-2.4,2.4-3.6c1-1.1,2.1-2.2,3.4-3.1c1.3-0.9,2.7-1.7,4.3-2.3c6.2-2.5,14.7-2.8,22.4-0.6c3.8,1.1,7.5,2.8,10.6,5.1
				c1.5,1.2,3,2.5,4.2,3.9c0.6,0.7,1.1,1.5,1.7,2.2l0.7,1.2l0.6,1.3c1.6,3.4,2.4,7.4,2.2,11.1c-0.1,1.9-0.5,3.7-1.2,5.4
				c-0.3,0.9-0.7,1.6-1.2,2.4l-0.7,1.1c-0.3,0.4-0.5,0.7-0.9,1.1c-2.5,3-6.1,5.2-10.1,6.9c-2,0.9-4.1,1.6-6.1,2.2
				c-1.1,0.3-2.1,0.6-3.2,0.9c-1.1,0.3-2.1,0.6-3.1,0.8c-8.4,1.9-16.5,2.6-24.2,2.5c-7.7-0.1-15.1-1.1-22.2-2.7
				c-7.1-1.6-13.8-3.8-20.3-6.6c-13-5.7-25.3-13.9-36.8-25.5c-5.7-5.8-11.3-12.5-16.4-20.3c-5.2-7.7-9.9-16.7-13.9-26.8
				c3.7,10.3,8,19.4,12.8,27.5c4.8,8.1,10,15.2,15.6,21.4c11.1,12.5,23,21.8,36.3,28.8c6.6,3.5,13.5,6.4,20.9,8.7
				c7.4,2.3,15.2,3.9,23.5,4.7c8.4,0.8,17.3,0.7,26.8-0.7c1.2-0.1,2.4-0.4,3.6-0.6c1.2-0.2,2.4-0.5,3.6-0.7c2.5-0.6,5-1.2,7.6-2.1
				c2.6-0.9,5.2-2,7.8-3.5C528.7,303.5,531.3,301.6,533.7,299.2z"
                />
              </g>
              <g>
                <path
                  d="M454.1,257.7c3.8-5.9,5.1-13.2,3.9-20.1c-0.6-3.4-1.9-6.8-3.7-9.7c-1.8-2.9-4.1-5.5-6.7-7.6c-2.6-2.1-5.5-3.7-8.6-4.7
				c-3.1-1-6.3-1.5-9.5-1.3c-3.2,0.2-6.3,0.9-9.1,2.4c-0.7,0.3-1.4,0.8-2,1.2c-0.7,0.5-1.3,0.9-1.9,1.4c-1.2,1-2.2,2.1-3.1,3.3
				c-1.7,2.4-2.8,5-3.3,7.6c-0.5,2.6-0.3,5.3,0.4,7.7c0.8,2.5,2.1,4.4,3.6,5.7c1.5,1.4,3,2.3,4.5,2.9c1.5,0.6,2.9,0.8,4.1,0.8
				c0.6,0,1.2-0.1,1.7-0.2c0.5-0.1,0.9-0.3,1.3-0.4c0.8-0.4,1.3-0.8,1.5-1.2c0.3-0.4,0.4-0.7,0.3-1.1c-0.2-0.7-1-1-2-1.6
				c-1-0.6-2.2-1.3-3.3-2.6c-1.1-1.2-1.9-3.1-1.8-4.5c0.1-0.9,0.2-1.7,0.7-2.6c0.4-0.9,1.1-1.8,1.9-2.6c0.4-0.4,0.9-0.7,1.3-1
				c0.2-0.1,0.5-0.2,0.7-0.4c0.3-0.1,0.5-0.2,0.8-0.3c1.1-0.4,2.4-0.5,3.9-0.5c2.9,0.1,5.9,1.3,8.6,3.4c1.3,1.1,2.5,2.4,3.4,3.8
				c0.9,1.5,1.6,3,2,4.7c0.8,3.4,0.5,7.4-1.1,10.7c-1.6,3.5-4.6,6.3-7.9,8.3c-3.4,1.9-7,3-10.6,3.3c-3.7,0.4-7.3,0.1-10.9-0.6
				c-3.5-0.7-7-1.8-10.4-3.2c-3.4-1.4-6.6-3.1-9.9-5.2c-3.2-2.1-6.3-4.6-9.4-7.5c-3-2.9-6-6.3-8.7-10.3c-2.7-4-5.2-8.6-7.1-14
				c3.2,11,8.3,19.4,13.9,26.1c2.8,3.4,5.7,6.3,8.8,9c3.1,2.6,6.3,4.9,9.7,7c3.4,2.1,7,3.8,11,5.2c3.9,1.4,8.2,2.4,13,2.7
				c4.7,0.3,10-0.4,15.2-2.5c2.6-1,5.2-2.5,7.8-4.4C449.8,263.2,452.2,260.8,454.1,257.7z"
                />
              </g>
              <g>
                <path
                  d="M336.8,288.9c-1.6,6.2-2,12.8-1.5,18.8c0.5,6,2,11.6,3.9,16c1.9,4.5,4.2,7.8,6.2,9.9c2.1,2.1,3.9,2.9,5,2.3
				c1.2-0.6,1.7-2.5,1.6-5.1c0.1-2.7-0.4-6.2-0.8-10.4c-0.4-4.1-0.9-8.8-1.2-13.8c-0.3-5-0.4-10.4-0.3-15.8
				c0.1-5.6,0.8-10.4,1.7-14.7c0.9-4.3,2.2-8.3,3.4-12.5c1.2-4.1,2.4-8.3,3.2-13.2c0.4-2.4,0.6-5,0.6-7.8c0-2.8-0.4-5.7-1.3-8.7
				c0.5,3.1,0.5,6,0.1,8.6c-0.4,2.6-1.1,5-1.9,7.2c-1.6,4.4-3.7,8-5.9,11.6c-2.2,3.6-4.5,7.2-6.8,11.5
				C340.7,277.4,338.5,282.5,336.8,288.9z"
                />
              </g>
              <g>
                <path
                  d="M350.5,287.2c-2,4.9-4.1,10-5.6,15.3c-1.5,5.3-2.2,10.7-1.9,15.5c0.3,4.8,1.5,8.7,3,11.2c1.5,2.5,3.2,3.5,4.5,3.1
				c1.3-0.4,2-2,2.7-4.3c0.7-2.3,1.4-5.3,2.3-8.9c0.9-3.6,2.2-7.7,3.7-12.4c1.4-4.7,3-10.1,4-15.9c0.9-6,1.2-11.1,1.2-15.7
				c0-4.6-0.4-8.7-0.9-12.8c-0.5-4.1-1.2-8.1-2.1-12.5c-1-4.4-2.2-9.3-4.1-14.9c1.1,5.8,1.4,10.8,1.4,15.2c0,4.4-0.4,8.4-0.9,12.2
				c-0.6,3.8-1.4,7.6-2.5,11.6C354.1,277.9,352.6,282.2,350.5,287.2z"
                />
              </g>
              <g>
                <path
                  d="M393.3,330.8c-0.5,0.7-1,1.3-1.5,1.9c-0.5,0.6-1.1,1.1-1.6,1.6c-1.2,0.9-2.4,1.7-3.7,2.3c-1.3,0.6-2.5,0.8-3.7,0.9
				c-1.2,0.1-2.1,0-3.2-0.4c-2.1-0.6-3.9-2.2-4.8-3.6c-0.4-0.7-0.7-1.4-0.8-2.3c-0.1-0.9,0-1.8,0.2-2.8c0.5-1.9,1.6-3.7,2.7-4.9
				c1.2-1.2,2-1.6,2.4-2c0.3-0.1,0.8-0.1,1.2,0c0.4,0.1,0.8,0.3,1.1,0.5c0.7,0.4,1.1,1.1,1.7,1.4c0.3,0.1,0.7,0.1,1.1-0.1
				c0.4-0.3,0.8-0.8,1.1-1.6c0.2-0.8,0.2-2-0.2-3.1c-0.4-1.2-1.2-2.4-2.6-3.5c-1.6-1.1-3.4-1.5-5.4-1.5c-1.9,0-3.8,0.5-5.6,1.3
				c-3.5,1.6-6.7,4.5-9,8.7c-1.1,2.1-1.9,4.6-2.1,7.3c-0.2,2.7,0.2,5.8,1.4,8.5c1.1,2.8,2.9,5.2,5.1,7.2c2.2,2,4.8,3.6,7.7,4.6
				c2.9,1,6.3,1.4,9.3,1.1c3.1-0.3,6.1-1.2,8.7-2.6c2.7-1.4,5-3.2,7.1-5.3c1-1.1,1.9-2.2,2.8-3.4c0.8-1.2,1.5-2.4,2.1-3.7
				c2.5-5.3,3.2-10.4,3.2-14.9c0-4.6-0.8-8.7-1.8-12.3c-1-3.7-2.3-6.9-3.6-10c-1.3-3.1-2.7-6-4.2-8.9c-1.5-2.9-3-5.7-4.8-8.6
				c-1.7-2.9-3.5-5.8-5.6-8.7c-4.1-6-8.9-12.3-15.4-19.3c5.9,7.4,9.9,14.3,13.1,20.6c1.6,3.2,3,6.2,4.2,9.2c1.2,3,2.2,6,3.2,8.9
				c0.9,3,1.7,6,2.4,9c0.7,3.1,1.3,6.2,1.6,9.4c0.3,3.2,0.3,6.5-0.3,9.8C396.4,324.7,395.3,328,393.3,330.8z"
                />
              </g>
              <g>
                <path
                  d="M211.1,46.3c3.4-0.8,6.9-1,10.2-0.6c3.3,0.4,6.4,1.5,8.9,3.1c2.6,1.6,4.7,3.9,6.2,6.4c0.7,1.2,1.3,2.5,1.7,3.8
				c0.4,1.3,0.5,2.6,0.6,3.9c0.1,5.3-3,11-7.3,15.2c-1.1,1.1-2.2,2.1-3.4,2.9c-1.2,0.9-2.4,1.7-3.5,2.4c-2.4,1.4-4.7,2.1-6.6,2.3
				c-1,0.1-1.9,0-2.6-0.2c-0.8-0.2-1.6-0.5-2.3-1c-1.5-1-2.7-2.4-3.5-3.9c-0.4-0.8-0.6-1.5-0.8-2.3c-0.1-0.4-0.1-0.8-0.2-1.1
				c0-0.4,0-0.7,0-1.1c0.1-1.5,0.6-3,1.3-4.2c1.4-2.5,3.4-4.2,4.9-5.2c1.5-1,2.5-1.6,2.5-2.3c0-0.6-1.2-1.4-3.6-1.3
				c-1.2,0-2.7,0.3-4.3,0.9c-1.6,0.7-3.4,1.7-5.1,3.3c-1.7,1.6-3.3,3.8-4.3,6.8c-0.3,0.7-0.4,1.5-0.6,2.3c-0.1,0.8-0.2,1.6-0.2,2.5
				c-0.1,1.7,0.2,3.4,0.6,5.2c0.9,3.5,2.9,6.8,5.9,9.6c1.5,1.4,3.4,2.6,5.5,3.4c2.2,0.9,4.4,1.3,6.6,1.4c4.5,0.2,8.9-1,12.7-2.8
				c1.9-0.9,3.7-1.9,5.5-3.1c1.8-1.2,3.5-2.5,5.1-4c1.6-1.4,3.2-3.1,4.6-4.9c1.4-1.8,2.7-3.7,3.9-5.8c1.1-2.1,2.1-4.4,2.8-6.9
				c0.7-2.5,1.1-5.1,1.1-7.8c0-2.7-0.3-5.5-1.1-8.1c-0.8-2.6-1.9-5.1-3.3-7.4c-2.8-4.6-6.7-8.4-11.4-11.2c-4.7-2.8-9.9-4.2-15.1-4.6
				c-5.2-0.4-10.3,0.3-15.2,1.9c-5,1.6-9.3,4-13,6.7c-3.7,2.7-6.9,5.8-9.6,9c-5.3,6.4-9.1,13.2-11.6,19.9
				c-2.5,6.7-3.9,13.4-4.6,19.9c-0.7,6.5-0.7,12.8-0.1,19.1c0.6,6.3,1.8,12.4,3.6,18.6c1.8,6.2,4.1,12.4,6.9,18.7
				c5.7,12.7,13.8,25.8,24.4,40.6c-10-15.2-17.2-28.8-22-41.6c-2.4-6.4-4.2-12.6-5.4-18.7c-1.2-6.1-1.8-12-1.9-18
				c0-5.9,0.5-11.8,1.7-17.7c1.2-5.8,3-11.7,5.8-17.4c2.8-5.6,6.5-11.1,11.4-16c2.4-2.4,5.2-4.6,8.2-6.5
				C204.3,48.6,207.6,47.1,211.1,46.3z"
                />
              </g>
              <g>
                <path
                  d="M217.7,111.6c1.7-0.5,3.2-0.7,4.8-0.7c1.5,0,2.9,0.2,4,0.6c0.5,0.2,1,0.4,1.3,0.7c0.4,0.2,0.7,0.5,0.9,0.8
				c0.5,0.6,1,1.5,1.3,2.5c0.2,0.5,0.3,1.1,0.3,1.7c0.1,0.6,0.1,1.1,0,1.7c-0.1,1.1-0.4,2.2-0.7,3c-0.2,0.4-0.4,0.8-0.6,1.1
				c-0.2,0.3-0.4,0.6-0.7,0.8c-0.3,0.2-0.4,0.4-0.7,0.6c-0.3,0.2-0.6,0.3-1,0.4c-0.7,0.2-1.5,0.2-2.3,0.1c-0.7-0.1-1.4-0.3-2-0.7
				c-1.2-0.6-2.2-2-2.8-3.3c-0.6-1.3-0.9-2.6-1.1-3.6c-0.2-1-0.5-1.8-1-2.2c-0.5-0.3-1.4-0.2-2.5,0.7c-1,0.9-2.2,2.7-2.6,5.4
				c-0.2,1.3-0.2,2.9,0.1,4.5c0.3,1.7,1,3.5,2.3,5.2c2.5,3.5,7,6.2,12.4,6.3c1.3,0,2.7-0.1,4.2-0.4c1.4-0.3,2.9-0.9,4.2-1.7
				c1.3-0.8,2.5-1.7,3.6-2.8c1.1-1.1,2-2.3,2.8-3.5c1.5-2.5,2.5-5.3,3-8.3c0.2-1.5,0.3-3,0.2-4.5c-0.1-1.5-0.3-3-0.7-4.5
				c-0.8-3-2.3-6.1-4.8-8.7c-2.5-2.6-5.8-4.5-9-5.3c-3.2-0.9-6.4-1-9.4-0.6c-1.5,0.2-2.9,0.5-4.3,0.9c-1.4,0.4-2.7,1-4,1.5
				c-5.3,2.4-9.2,5.9-12.2,9.4c-1.5,1.8-2.8,3.6-3.9,5.4c-1.1,1.9-2,3.7-2.8,5.5c-3,7.5-3.6,14.5-3.3,21.3c0.3,6.8,1.7,13.4,4,20.4
				c2.2,7,5.4,14.5,9.3,23.3c-3.1-9.1-5.5-16.9-6.7-24c-1.3-7.1-1.6-13.5-0.8-19.6c0.7-6.1,2.5-12,5.9-17.3c0.8-1.3,1.8-2.6,2.9-3.9
				c1.1-1.2,2.3-2.4,3.5-3.5C211.2,114.4,214.2,112.5,217.7,111.6z"
                />
              </g>
              <g>
                <path
                  d="M252.1,32.8c3.2,0.6,6.4-0.3,8.5-1.8c2.1-1.5,3-3.4,2.5-5.1c-0.6-1.7-2.4-2.7-4.6-3c-2.2-0.3-4.8,0-7.4,1.2
				c-2.7,1.2-4.6,2.1-6.6,2.8c-2,0.7-4.2,1.2-7.3,1.3c3,1,5.1,1.7,7.3,2.5C246.6,31.4,248.8,32.2,252.1,32.8z"
                />
              </g>
              <g>
                <path
                  d="M267.3,45.6c1.8,2,3.7,3.3,5.6,4.1c1.9,0.8,4,1.1,5.5,0.2c1.5-0.9,1.9-3.2,0.7-5.8c-1.2-2.6-4-5.2-7.6-6.1
				c-3.7-0.9-6.2-0.8-8.7-0.6c-2.4,0.2-4.8,0.6-8,1.4c3.3,0.3,5.4,1.2,7.2,2.3C263.8,42.2,265.4,43.6,267.3,45.6z"
                />
              </g>
              <g>
                <path
                  d="M270,60.5c0.6,2.6,1.5,4.6,2.7,6.3c1.2,1.6,2.7,3,4.5,3.1c0.9,0,1.7-0.4,2.4-1.2c0.3-0.4,0.7-0.9,0.9-1.5
				c0.2-0.6,0.4-1.3,0.5-2c0.4-2.9-0.8-6.8-3.6-9.5c-2.9-2.7-5.2-3.8-7.5-4.7c-2.3-0.9-4.7-1.6-7.9-2.2c3,1.6,4.4,3.3,5.6,5.1
				C268.6,55.8,269.4,57.8,270,60.5z"
                />
              </g>
              <g>
                <path
                  d="M263.1,73.6c-0.7,2.7-0.6,5.1,0.1,7.2c0.7,2,1.9,3.7,3.7,4c1.7,0.3,3.5-0.9,4.7-3.2c1.1-2.3,1.5-5.6,0.3-8.6
				c-1.2-3.2-2.4-5.1-3.5-7.1c-1.2-1.9-2.3-3.8-3.9-6.5c0.6,3.1,0.5,5.2,0.2,7.3C264.3,68.8,263.9,70.8,263.1,73.6z"
                />
              </g>
              <g>
                <path
                  d="M251.6,86.6c-1.8,2-2.8,4.1-3.2,6.2c-0.4,2.1-0.1,4.1,1.2,5.3c1.3,1.2,3.5,1,5.7-0.5c2.1-1.5,4-4.4,4.4-7.7
				c0.4-3.4,0.1-5.8-0.2-8c-0.3-2.3-0.7-4.5-1.2-7.5c-0.5,3.1-1.4,5-2.5,6.8C254.7,82.9,253.5,84.5,251.6,86.6z"
                />
              </g>
              <g>
                <path
                  d="M241.2,20.8c6-2.2,9.5-7.9,7.4-10.8c-2.1-2.9-8.6-1.4-12.5,3.7c-2,2.6-3.3,4.5-4.6,6.4c-0.7,0.9-1.3,1.9-2.1,2.7
				c-0.4,0.4-0.9,0.8-1.5,1.1c-0.6,0.3-1.4,0.4-2.1,0.2c0.7,0.5,1.5,0.7,2.3,0.6c0.8,0,1.5-0.2,2.2-0.4c0.7-0.2,1.3-0.4,1.9-0.6
				c0.6-0.2,1.1-0.4,1.7-0.6C235.8,22.5,238.1,21.9,241.2,20.8z"
                />
              </g>
              <g>
                <path
                  d="M227.4,15.6c2.1-2.5,2.5-5.9,1.9-8.2c-0.7-2.4-2.4-3.8-4.1-3.6c-1.8,0.1-3.2,1.4-4.2,2.9c-1,1.5-1.6,3.3-1.6,5.5
				c0,2.4-0.2,4.2-0.7,5.9c-0.5,1.8-1.3,3.6-3.2,5.6c2.6-1.2,4.4-2.1,6.2-3.2C223.5,19.4,225.3,18.1,227.4,15.6z"
                />
              </g>
              <g>
                <path
                  d="M216.5,14.7c1.1-3.1,0.5-6.4-0.9-8.5c-1.4-2.1-3.4-2.9-5-2.3c-1.7,0.6-2.6,2.3-3.1,4.1c-0.5,1.8-0.5,3.9,0.3,6.1
				c0.8,2.4,1.2,4.2,1.4,6.1c0.2,1.9,0.1,3.9-1,6.6c1.9-2.1,3.3-3.8,4.6-5.5C214.1,19.6,215.4,17.8,216.5,14.7z"
                />
              </g>
              <g>
                <path
                  d="M204,15.5c-0.4-2.8-2.4-5-4.4-5.8c-2.1-0.8-4-0.4-5.1,1c-1.1,1.4-1.2,3.2-0.9,4.8c0.3,1.6,1.1,2.9,2.7,4.1
				c1.7,1.2,2.9,2.3,3.9,3.5c1,1.2,1.9,2.7,2.4,5.1c0.6-2.4,0.9-4.1,1.3-5.9C204.2,20.4,204.4,18.4,204,15.5z"
                />
              </g>
              <g>
                <path
                  d="M189.6,18.1c-1.2-0.9-2.6-1.3-4-1.3c-1.4,0-2.6,0.3-3.6,1c-1,0.6-1.6,1.4-1.9,2.3c-0.3,0.9-0.2,1.7,0.1,2.5
				c0.7,1.6,2.1,2.7,3.1,3.4c1.1,0.7,2,0.9,3.4,0.4c1.5-0.5,2.9-0.5,4.3-0.3c1.4,0.3,2.8,1,4.1,2.7c-0.3-2.2-0.8-3.8-1.5-5.5
				C193,21.7,192,19.9,189.6,18.1z"
                />
              </g>
              <g>
                <path
                  d="M174.9,28.6c-3.1,0-5.9,1.8-7.2,3.8c-1.3,2-1.2,4.2,0,5.4c1.2,1.3,3.1,1.6,4.8,1.4c1.8-0.2,3.4-0.8,5.1-2.3
				c1.7-1.5,3.2-2.6,4.8-3.4c1.6-0.8,3.4-1.4,6.1-1.4c-2.5-1.1-4.3-1.8-6.3-2.4C180.2,29.1,178.1,28.5,174.9,28.6z"
                />
              </g>
              <g>
                <path
                  d="M168.5,41.5c-2.5,0.6-4.3,2.4-4.8,4.3c-0.6,1.9-0.1,3.7,1.3,4.8c1.4,1.1,3.2,1.3,4.7,1c1.5-0.3,2.8-1.1,3.7-2.8
				c1-1.7,1.7-3,2.7-4.3c0.9-1.2,2.1-2.3,4.1-3.3c-2.2,0-3.8-0.1-5.6-0.2C172.8,41,171,40.9,168.5,41.5z"
                />
              </g>
              <g>
                <path
                  d="M158.7,62.1c-2.2,1.8-3.3,4.4-3.4,6.6c-0.1,2.2,0.9,4,2.5,4.7c1.6,0.7,3.5,0.1,5.1-1.1c1.6-1.2,2.8-3.1,3.3-5.6
				c0.5-2.6,0.8-4.4,1.3-6.3c0.5-1.8,1.1-3.6,2.6-6c-2.2,1.6-3.9,2.7-5.6,3.7C162.7,59.2,161,60.2,158.7,62.1z"
                />
              </g>
              <g>
                <path d="M155.7,90.1c-2.8,3.4-1.4,8,2,9c3.4,1,7.1-2.2,6.5-6.5c-0.6-4.5-1.6-6.4-1.4-10.8C160.4,85.5,158.6,86.6,155.7,90.1z" />
              </g>
              <g>
                <path
                  d="M157,113.8c-1.1,1.8-0.9,3.7-0.1,5.2c0.8,1.4,2.4,2.4,4.1,2.4c1.8,0,3.3-0.9,4.2-2.1c0.9-1.3,1.2-2.9,0.5-4.7
				c-1.5-3.7-2.8-5.3-3.2-9.2C160.8,108.9,159.2,110.1,157,113.8z"
                />
              </g>
              <g>
                <path
                  d="M191.5,102.9c2.1-4.3,3.5-8.8,3.9-12.4c0.5-3.6,0.1-6.5-1.5-7.2c-1.6-0.7-4.1,0.9-6.3,4.1c-2.2,3.2-4.1,8-4.6,13.1
				c-0.5,5.3-0.4,8.9-0.3,12.5c0.1,3.6,0.2,7.2,0.2,12.4c1-5.1,2.2-8.5,3.5-11.8C187.9,110.5,189.4,107.4,191.5,102.9z"
                />
              </g>
              <g>
                <g>
                  <path
                    d="M6.8,333.4c-3-1.7-5.7-4-7.9-6.6c-2.1-2.6-3.6-5.5-4.4-8.4c-0.8-2.9-0.8-6.1-0.2-8.9c0.3-1.4,0.8-2.7,1.4-3.9
					c0.6-1.2,1.4-2.2,2.3-3.2c3.6-3.9,9.7-6,15.7-6.1c1.5-0.1,3,0,4.5,0.1c1.5,0.1,2.9,0.4,4.2,0.7c2.7,0.6,4.8,1.6,6.3,2.8
					c0.8,0.6,1.4,1.3,1.8,1.9c0.5,0.7,0.8,1.5,1,2.3c0.4,1.7,0.4,3.6-0.2,5.2c-0.2,0.8-0.6,1.6-1,2.2c-0.2,0.4-0.4,0.6-0.7,0.9
					c-0.3,0.3-0.5,0.6-0.8,0.8c-1.1,1-2.5,1.7-3.9,2.2c-2.8,0.9-5.4,0.7-7.1,0.5c-1.8-0.3-3-0.5-3.4,0c-0.4,0.5-0.1,1.8,1.7,3.4
					c0.9,0.8,2.1,1.6,3.8,2.3c1.6,0.6,3.7,1.1,6,1.1c2.3,0,5.1-0.5,7.7-2c0.7-0.3,1.3-0.8,2-1.3c0.6-0.5,1.3-1,1.9-1.6
					c1.2-1.2,2.2-2.6,3.1-4.2c1.7-3.1,2.6-6.9,2.2-11c-0.2-2-0.7-4.2-1.7-6.3c-1-2.1-2.3-4-3.9-5.6c-3.2-3.2-7.2-5.3-11.2-6.6
					c-2-0.7-4.1-1.2-6.1-1.5c-2.1-0.4-4.2-0.5-6.4-0.6c-2.2-0.1-4.4,0.1-6.7,0.4c-2.3,0.3-4.6,0.9-6.8,1.6c-2.3,0.8-4.6,1.8-6.8,3.1
					c-2.2,1.4-4.3,3-6.1,4.9c-1.8,2-3.5,4.2-4.7,6.7c-1.2,2.4-2.1,5-2.7,7.6c-1.1,5.3-0.8,10.7,0.7,15.9c1.5,5.2,4.4,9.9,7.9,13.7
					c3.5,3.8,7.8,6.8,12.3,9c4.7,2.2,9.5,3.4,14.1,4c4.6,0.6,9,0.5,13.1,0c8.3-1,15.6-3.4,22.1-6.6c6.4-3.2,12-7.1,17-11.4
					c4.9-4.3,9.2-8.9,13.1-13.9c3.8-5,7.2-10.3,10.1-16c2.9-5.7,5.5-11.8,7.7-18.4c4.5-13.2,7.6-28.3,9.9-46.4
					c-3.1,18-7.1,32.8-12.3,45.4c-2.6,6.3-5.6,12.1-8.8,17.3c-3.3,5.3-6.9,10-10.9,14.4c-4,4.3-8.4,8.3-13.3,11.8
					c-4.9,3.5-10.2,6.5-16.1,8.7c-5.9,2.2-12.4,3.7-19.2,3.9c-3.4,0.1-6.9-0.1-10.4-0.9C13.3,336.4,9.9,335.2,6.8,333.4z"
                  />
                </g>
                <g>
                  <path
                    d="M46.6,281.2c-1.6-0.8-2.8-1.7-4-2.7c-1.1-1-2-2.1-2.5-3.1c-0.3-0.5-0.4-1-0.5-1.4c-0.1-0.4-0.1-0.8-0.1-1.2
					c0-0.8,0.3-1.7,0.8-2.7c0.3-0.5,0.6-1,0.9-1.5c0.4-0.5,0.7-0.9,1.1-1.2c0.8-0.8,1.8-1.4,2.6-1.7c0.4-0.2,0.8-0.3,1.2-0.4
					c0.4-0.1,0.7-0.1,1-0.1c0.3,0,0.6,0,0.9,0.1c0.3,0.1,0.6,0.2,1,0.4c0.7,0.3,1.3,0.9,1.7,1.5c0.5,0.6,0.8,1.2,1,1.8
					c0.4,1.3,0.2,2.9-0.2,4.3c-0.5,1.4-1.1,2.5-1.7,3.4c-0.5,0.9-0.9,1.6-0.7,2.3c0.2,0.6,0.9,1.1,2.3,1.2c1.4,0,3.5-0.5,5.6-2.2
					c1.1-0.8,2.1-1.9,3-3.4c0.9-1.4,1.6-3.3,1.9-5.4c0.6-4.3-0.9-9.3-4.7-13c-1-0.9-2.1-1.8-3.3-2.5c-1.3-0.7-2.7-1.3-4.2-1.6
					c-1.5-0.3-3-0.5-4.5-0.5c-1.5,0.1-3,0.3-4.4,0.7c-2.9,0.8-5.5,2.2-7.8,4c-1.2,0.9-2.3,2-3.2,3.2c-1,1.2-1.8,2.4-2.6,3.8
					c-1.5,2.7-2.5,6-2.5,9.6c0,3.6,1.2,7.2,2.9,10c1.7,2.9,4,5.1,6.4,6.8c1.2,0.9,2.5,1.6,3.8,2.3c1.3,0.7,2.7,1.2,4,1.6
					c5.5,1.8,10.7,2,15.4,1.5c2.3-0.3,4.5-0.7,6.6-1.3c2.1-0.6,4-1.3,5.8-2.2c7.3-3.4,12.6-8.1,17-13.2c4.4-5.2,7.9-10.9,11-17.6
					c3.2-6.7,5.9-14.3,9.1-23.4c-4,8.8-7.5,16.1-11.4,22.1c-3.9,6.1-8.1,10.9-12.8,14.9c-4.7,3.9-10,7-16.1,8.6
					c-1.5,0.4-3.1,0.7-4.7,0.9c-1.6,0.2-3.3,0.2-5,0.1C53.2,283.5,49.7,282.8,46.6,281.2z"
                  />
                </g>
                <g>
                  <path
                    d="M-32.4,315.2c-2-2.6-4.9-4.2-7.4-4.5c-2.5-0.4-4.6,0.4-5.3,2c-0.7,1.6-0.1,3.6,1.3,5.3c1.4,1.7,3.5,3.3,6.2,4.2
					c2.8,0.9,4.8,1.6,6.8,2.5c2,0.9,3.9,1.9,6.2,4c-1.5-2.8-2.6-4.7-3.6-6.8C-29.3,320-30.4,317.9-32.4,315.2z"
                  />
                </g>
                <g>
                  <path
                    d="M-34.7,295.5c0-2.7-0.4-4.9-1.3-6.9c-0.9-1.9-2.1-3.5-3.9-3.8c-1.7-0.3-3.6,1.1-4.5,3.8c-0.9,2.7-0.6,6.6,1.4,9.7
					c2.1,3.2,4,4.8,5.9,6.3c1.9,1.5,3.9,2.8,6.8,4.4c-2.2-2.4-3.1-4.5-3.7-6.6C-34.5,300.4-34.8,298.3-34.7,295.5z"
                  />
                </g>
                <g>
                  <path
                    d="M-26.6,282.8c1.3-2.3,2-4.4,2.3-6.5c0.2-2,0.1-4.1-1.2-5.3c-0.6-0.6-1.5-0.9-2.5-0.8c-0.5,0.1-1.1,0.2-1.6,0.5
					c-0.5,0.3-1.2,0.6-1.7,1.1c-2.3,1.9-4.1,5.5-3.9,9.3c0.3,3.9,1.2,6.3,2.3,8.6c1.1,2.2,2.3,4.4,4.3,7c-1.1-3.2-1-5.5-0.6-7.6
					C-28.7,287.1-27.9,285.2-26.6,282.8z"
                  />
                </g>
                <g>
                  <path
                    d="M-12.6,277.9c2.3-1.5,3.9-3.4,4.9-5.3c0.9-1.9,1.1-4,0.1-5.4c-1-1.4-3.2-1.8-5.6-0.9c-2.4,0.9-4.9,3.1-6.2,6.1
					c-1.3,3.1-1.8,5.4-2.3,7.6c-0.4,2.2-0.9,4.4-1.5,7.4c1.7-2.6,3.2-4.2,4.8-5.5C-16.7,280.6-15,279.4-12.6,277.9z"
                  />
                </g>
                <g>
                  <path
                    d="M4.7,276.2c2.7-0.3,4.9-1.1,6.6-2.4c1.7-1.3,2.9-2.9,2.7-4.7c-0.2-1.7-1.9-3.2-4.4-3.5c-2.5-0.4-5.9,0.4-8.5,2.6
					c-2.6,2.3-4,4.2-5.4,6c-1.3,1.9-2.6,3.7-4.3,6.3c2.5-1.9,4.5-2.7,6.5-3.3C-0.1,276.8,1.9,276.5,4.7,276.2z"
                  />
                </g>
                <g>
                  <path
                    d="M-32.6,331.5c-5.9-2.5-12.3-0.7-12.8,2.8c-0.4,3.5,5.4,6.9,11.7,5.9c3.2-0.5,5.5-1.1,7.8-1.5c1.2-0.2,2.2-0.5,3.4-0.5
					c0.6,0,1.2,0,1.8,0.2c0.6,0.2,1.3,0.6,1.7,1.3c-0.2-0.8-0.7-1.5-1.2-2c-0.6-0.5-1.2-0.9-1.8-1.2c-0.6-0.3-1.2-0.6-1.8-0.8
					c-0.6-0.2-1.1-0.5-1.6-0.7C-27.5,333.9-29.6,332.8-32.6,331.5z"
                  />
                </g>
                <g>
                  <path
                    d="M-26.1,344.6c-3.2,0.4-5.9,2.5-7,4.8c-1.2,2.2-0.8,4.4,0.5,5.5c1.4,1.1,3.3,1.1,5.1,0.8c1.8-0.4,3.4-1.3,4.9-3
					c1.6-1.7,3-2.9,4.6-3.9c1.6-1,3.4-1.7,6.2-1.9c-2.7-0.9-4.6-1.4-6.7-1.9C-20.6,344.6-22.8,344.2-26.1,344.6z"
                  />
                </g>
                <g>
                  <path
                    d="M-18.7,352.8c-2.9,1.5-4.7,4.4-5.1,6.8c-0.4,2.5,0.5,4.5,2.1,5.1c1.7,0.7,3.5,0.1,5.1-0.9c1.6-1,3-2.5,4-4.7
					c1-2.2,2-3.9,3.1-5.4c1.2-1.5,2.6-2.9,5.2-4.2c-2.9,0.2-5,0.5-7.1,0.9C-13.6,350.8-15.8,351.3-18.7,352.8z"
                  />
                </g>
                <g>
                  <path
                    d="M-9,360.7c-1.6,2.3-1.7,5.3-0.7,7.3c0.9,2,2.7,3,4.4,2.7c1.7-0.3,3.1-1.5,4-2.9c0.9-1.4,1.2-2.9,0.8-4.8
					c-0.4-2-0.6-3.6-0.5-5.2c0.1-1.6,0.4-3.3,1.7-5.4c-2,1.3-3.5,2.4-5,3.5C-5.8,357-7.4,358.3-9,360.7z"
                  />
                </g>
                <g>
                  <path
                    d="M3.3,368.7c0.3,1.5,1,2.7,2,3.7c1,0.9,2.1,1.5,3.2,1.7c1.1,0.2,2.1,0,2.9-0.4c0.8-0.4,1.3-1.1,1.6-1.9
					c0.6-1.7,0.4-3.4,0.1-4.6c-0.3-1.3-0.8-2.1-2.2-2.7c-1.4-0.7-2.5-1.6-3.3-2.7c-0.8-1.2-1.4-2.6-1.1-4.8c-1.3,1.8-2,3.4-2.6,5.1
					C3.3,363.7,2.7,365.7,3.3,368.7z"
                  />
                </g>
                <g>
                  <path
                    d="M21.2,371c2.3,2.1,5.5,2.7,7.9,2.1c2.4-0.6,3.8-2.2,3.7-3.9c0-1.8-1.2-3.3-2.6-4.4c-1.4-1.1-3.1-1.7-5.3-1.8
					c-2.3-0.1-4.1-0.3-5.8-0.8c-1.7-0.5-3.4-1.3-5.4-3.2c1.1,2.5,1.9,4.3,2.9,6.1C17.7,367,18.8,368.9,21.2,371z"
                  />
                </g>
                <g>
                  <path
                    d="M34.6,366c2.2,1.3,4.7,1.2,6.4,0.2c1.7-1,2.6-2.7,2.3-4.4c-0.3-1.7-1.4-3.1-2.7-4c-1.3-0.8-2.8-1.1-4.7-0.5
					c-1.9,0.6-3.3,1-4.8,1.3c-1.5,0.2-3.1,0.3-5.2-0.4c1.6,1.5,2.7,2.7,3.9,3.9C31.1,363.4,32.4,364.7,34.6,366z"
                  />
                </g>
                <g>
                  <path
                    d="M55.9,357.6c2.9,0.2,5.5-1,7-2.5c1.6-1.6,2.1-3.5,1.4-5.1c-0.7-1.6-2.5-2.5-4.5-2.7c-2-0.2-4.2,0.4-6.2,1.9
					c-2.1,1.5-3.6,2.7-5.2,3.7c-1.6,1-3.3,1.9-6,2.6c2.7,0.3,4.7,0.7,6.6,1.1C51,357,52.9,357.4,55.9,357.6z"
                  />
                </g>
                <g>
                  <path
                    d="M77.2,339.2c4.4-0.5,6.5-4.9,4.7-7.9c-1.8-3-6.6-3.2-9.2,0.4c-2.6,3.7-3.2,5.7-6.3,8.9
					C70.6,339.3,72.7,339.8,77.2,339.2z"
                  />
                </g>
                <g>
                  <path
                    d="M92.4,321c2-0.6,3.2-2.1,3.6-3.7c0.4-1.6-0.1-3.4-1.4-4.6c-1.3-1.2-3-1.6-4.5-1.3c-1.5,0.3-2.9,1.3-3.6,3.1
					c-1.4,3.7-1.5,5.7-3.9,8.9C86.3,321.9,88.3,322.2,92.4,321z"
                  />
                </g>
                <g>
                  <path
                    d="M59.8,305.3c-4.5,1.7-8.5,4.1-11.4,6.4c-2.8,2.3-4.5,4.7-3.8,6.3c0.7,1.6,3.6,2.1,7.4,1.3c3.8-0.8,8.5-3,12.3-6.5
					c3.9-3.5,6.3-6.2,8.8-8.9c2.4-2.7,4.8-5.4,8.3-9.3c-4.2,3.1-7.4,4.7-10.6,6.2C67.6,302.3,64.4,303.6,59.8,305.3z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M363.6,398.2c3.2,1.3,6.2,3.2,8.6,5.5c2.4,2.3,4.3,5,5.4,7.7c1.1,2.8,1.6,5.9,1.3,8.8c-0.1,1.4-0.4,2.8-0.9,4.1
					c-0.4,1.3-1.1,2.4-1.8,3.5c-3,4.3-8.8,7.2-14.8,8.1c-1.5,0.3-3,0.4-4.4,0.4c-1.5,0.1-2.9,0-4.3-0.1c-2.7-0.3-5-1-6.7-2
					c-0.8-0.5-1.5-1.1-2-1.7c-0.5-0.6-1-1.4-1.3-2.2c-0.6-1.7-0.8-3.6-0.5-5.2c0.1-0.8,0.4-1.6,0.7-2.3c0.1-0.4,0.4-0.7,0.5-1
					c0.2-0.3,0.4-0.6,0.7-0.9c1-1.2,2.2-2.1,3.6-2.7c2.6-1.2,5.2-1.4,7-1.4c1.8,0,3,0.1,3.4-0.4c0.4-0.5-0.2-1.8-2.1-3.2
					c-1-0.7-2.3-1.3-4-1.8c-1.7-0.4-3.8-0.6-6.1-0.3c-2.3,0.3-4.9,1.2-7.4,3c-0.6,0.4-1.2,1-1.8,1.5c-0.6,0.6-1.2,1.2-1.6,1.9
					c-1,1.3-1.9,2.9-2.5,4.5c-1.3,3.3-1.6,7.2-0.8,11.2c0.4,2,1.2,4.1,2.4,6c1.3,2,2.8,3.6,4.6,5c3.6,2.8,7.8,4.3,11.9,5.1
					c2.1,0.4,4.2,0.6,6.3,0.7c2.1,0.1,4.3,0,6.4-0.3c2.2-0.2,4.4-0.7,6.6-1.3c2.2-0.6,4.4-1.4,6.6-2.5c2.2-1.1,4.3-2.4,6.3-4
					c2-1.6,3.8-3.5,5.5-5.7c1.6-2.2,2.9-4.6,3.8-7.2c0.9-2.6,1.5-5.2,1.7-7.9c0.4-5.4-0.6-10.7-2.7-15.7c-2.2-5-5.6-9.2-9.6-12.6
					c-4-3.3-8.6-5.8-13.4-7.3c-5-1.6-9.9-2.2-14.5-2.1c-4.6,0-9,0.7-13,1.7c-8.1,2.1-15,5.4-21,9.4c-6,4-11,8.6-15.3,13.5
					c-4.3,4.9-8,10-11.1,15.5c-3.1,5.4-5.8,11.2-8,17.2c-2.2,6.1-3.9,12.4-5.3,19.3c-2.7,13.7-3.8,29-3.8,47.2
					c0.7-18.2,2.8-33.4,6.3-46.6c1.8-6.6,3.9-12.7,6.5-18.3c2.6-5.7,5.5-10.9,8.9-15.7c3.4-4.8,7.3-9.3,11.6-13.4
					c4.4-4.1,9.3-7.7,14.8-10.7c5.5-3,11.8-5.2,18.5-6.4c3.4-0.5,6.9-0.8,10.4-0.5C356.7,396.1,360.3,396.8,363.6,398.2z"
                  />
                </g>
                <g>
                  <path
                    d="M331,455.1c1.7,0.6,3,1.3,4.3,2.2c1.2,0.9,2.2,1.9,2.9,2.8c0.3,0.5,0.5,0.9,0.7,1.3c0.2,0.4,0.2,0.8,0.3,1.2
					c0.1,0.8,0,1.8-0.4,2.8c-0.2,0.5-0.4,1.1-0.7,1.6c-0.3,0.5-0.6,0.9-1,1.4c-0.7,0.9-1.6,1.6-2.4,2.1c-0.4,0.2-0.8,0.4-1.1,0.6
					c-0.4,0.1-0.7,0.2-1,0.3c-0.3,0-0.6,0.1-0.9,0c-0.3,0-0.7-0.1-1-0.2c-0.7-0.3-1.4-0.7-1.9-1.2c-0.5-0.5-1-1.1-1.2-1.7
					c-0.6-1.2-0.6-2.9-0.3-4.3c0.3-1.4,0.8-2.6,1.2-3.6c0.4-1,0.7-1.7,0.4-2.4c-0.2-0.6-1-1-2.5-0.9c-1.4,0.2-3.4,0.9-5.3,2.9
					c-0.9,1-1.9,2.2-2.6,3.7c-0.7,1.6-1.2,3.4-1.2,5.6c0,4.3,2.1,9.1,6.4,12.3c1.1,0.8,2.3,1.5,3.6,2.1c1.3,0.5,2.9,0.9,4.4,1.1
					c1.5,0.1,3,0.1,4.5-0.1c1.5-0.3,2.9-0.7,4.3-1.2c2.7-1.2,5.2-2.9,7.2-5c1-1.1,2-2.3,2.8-3.6c0.8-1.3,1.5-2.6,2-4.1
					c1.1-2.9,1.7-6.3,1.2-9.9c-0.5-3.6-2.1-7-4.2-9.5c-2.1-2.6-4.6-4.5-7.3-5.9c-1.3-0.7-2.7-1.3-4.1-1.8c-1.4-0.5-2.8-0.8-4.1-1.1
					c-5.7-1.1-10.9-0.6-15.4,0.5c-2.3,0.6-4.4,1.3-6.3,2.2c-2,0.9-3.8,1.8-5.5,2.9c-6.8,4.3-11.4,9.7-15.1,15.3
					c-3.7,5.7-6.4,11.9-8.7,18.9c-2.3,7-4,15-6,24.4c2.8-9.2,5.4-16.9,8.5-23.4c3.1-6.5,6.6-11.9,10.7-16.4c4.1-4.5,9-8.3,14.8-10.6
					c1.5-0.6,3-1.1,4.6-1.5c1.6-0.4,3.2-0.6,4.9-0.8C324,453.6,327.6,453.9,331,455.1z"
                  />
                </g>
                <g>
                  <path
                    d="M404.9,411.1c2.3,2.3,5.4,3.5,7.9,3.5c2.5,0,4.5-1,5-2.7c0.5-1.7-0.4-3.6-2-5.1c-1.6-1.6-3.9-2.8-6.7-3.3
					c-2.9-0.6-5-1-7-1.6c-2.1-0.6-4.1-1.4-6.7-3.2c1.9,2.5,3.2,4.4,4.4,6.3C401.2,406.8,402.5,408.7,404.9,411.1z"
                  />
                </g>
                <g>
                  <path
                    d="M409.7,430.4c0.3,2.7,1.1,4.8,2.2,6.6c1.1,1.8,2.6,3.2,4.3,3.3c1.7,0.1,3.4-1.5,4-4.4c0.6-2.8-0.2-6.6-2.6-9.4
					c-2.5-2.9-4.6-4.3-6.7-5.5c-2.1-1.2-4.2-2.3-7.3-3.5c2.5,2.1,3.7,4.1,4.5,6.1C408.9,425.6,409.4,427.6,409.7,430.4z"
                  />
                </g>
                <g>
                  <path
                    d="M403.3,444.1c-1,2.4-1.5,4.6-1.4,6.7c0,2,0.4,4,1.8,5.1c0.7,0.5,1.6,0.7,2.6,0.4c0.5-0.1,1.1-0.3,1.6-0.7
					c0.5-0.3,1.1-0.8,1.6-1.3c2-2.1,3.4-6,2.6-9.8c-0.8-3.8-2.1-6.1-3.4-8.2c-1.4-2.1-2.8-4-5.2-6.4c1.5,3,1.7,5.3,1.5,7.4
					C404.8,439.5,404.3,441.5,403.3,444.1z"
                  />
                </g>
                <g>
                  <path
                    d="M390,450.7c-2.1,1.7-3.5,3.8-4.1,5.9c-0.6,2-0.6,4.1,0.6,5.4c1.2,1.3,3.4,1.3,5.6,0.1c2.2-1.2,4.4-3.7,5.3-6.8
					c0.9-3.3,1.1-5.6,1.3-7.8c0.2-2.3,0.3-4.5,0.6-7.6c-1.3,2.8-2.6,4.5-4.1,6.1C393.8,447.5,392.2,448.9,390,450.7z"
                  />
                </g>
                <g>
                  <path
                    d="M373.1,454.6c-2.6,0.6-4.7,1.8-6.2,3.2c-1.5,1.5-2.5,3.3-2.1,5c0.4,1.7,2.3,2.9,4.9,2.9c2.6,0,5.8-1.2,8.1-3.7
					c2.3-2.6,3.4-4.6,4.5-6.7c1.1-2,2.1-4,3.4-6.8c-2.2,2.2-4.1,3.3-6,4.1C377.8,453.4,375.8,453.9,373.1,454.6z"
                  />
                </g>
                <g>
                  <path
                    d="M402.9,395c6.1,1.8,12.3-0.9,12.3-4.5c0-3.5-6.2-6.1-12.3-4.3c-3.2,0.9-5.3,1.8-7.5,2.5c-1.1,0.4-2.2,0.8-3.3,0.9
					c-0.6,0.1-1.2,0.1-1.8,0c-0.6-0.1-1.3-0.5-1.8-1.1c0.3,0.8,0.9,1.4,1.5,1.9c0.6,0.4,1.3,0.7,2,1c0.6,0.2,1.3,0.4,1.9,0.6
					c0.6,0.2,1.1,0.3,1.7,0.5C397.6,393.2,399.8,394.1,402.9,395z"
                  />
                </g>
                <g>
                  <path
                    d="M394.7,382.8c3.1-0.8,5.5-3.3,6.3-5.6c0.9-2.4,0.2-4.4-1.3-5.3c-1.5-0.9-3.4-0.7-5.1-0.1c-1.7,0.6-3.2,1.7-4.5,3.6
					c-1.4,1.9-2.6,3.3-4,4.4c-1.4,1.1-3.1,2.2-5.9,2.7c2.8,0.5,4.8,0.8,6.9,1C389.3,383.5,391.5,383.6,394.7,382.8z"
                  />
                </g>
                <g>
                  <path
                    d="M386.4,375.6c2.7-1.9,4.1-4.9,4.2-7.4c0.1-2.5-1.1-4.4-2.8-4.8c-1.7-0.4-3.5,0.4-5,1.5c-1.5,1.2-2.6,2.9-3.3,5.2
					c-0.7,2.4-1.5,4.1-2.4,5.8c-1,1.6-2.2,3.2-4.6,4.8c2.8-0.6,4.8-1.2,6.9-1.8C381.5,378.3,383.7,377.5,386.4,375.6z"
                  />
                </g>
                <g>
                  <path
                    d="M375.7,369.1c1.3-2.5,1-5.5-0.2-7.3c-1.2-1.9-3.1-2.6-4.7-2.1c-1.7,0.5-2.9,1.9-3.6,3.3c-0.7,1.5-0.8,3-0.2,4.9
					c0.6,1.9,1,3.5,1.2,5.1c0.1,1.6,0,3.3-1,5.5c1.8-1.6,3.2-2.8,4.5-4.1C373.1,373.1,374.4,371.7,375.7,369.1z"
                  />
                </g>
                <g>
                  <path
                    d="M362.5,362.8c-0.5-1.4-1.4-2.6-2.5-3.4c-1.1-0.8-2.3-1.2-3.4-1.3c-1.1-0.1-2.1,0.2-2.8,0.8c-0.7,0.5-1.2,1.3-1.4,2.1
					c-0.4,1.7,0.1,3.4,0.5,4.6c0.5,1.2,1.1,1.9,2.5,2.4c1.5,0.5,2.7,1.2,3.6,2.3c0.9,1,1.7,2.4,1.8,4.6c1-2,1.6-3.6,2-5.4
					C363.2,367.7,363.5,365.7,362.5,362.8z"
                  />
                </g>
                <g>
                  <path
                    d="M344.5,362.7c-2.6-1.8-5.9-2-8.1-1.1c-2.3,0.9-3.4,2.7-3.2,4.4c0.3,1.8,1.6,3.1,3.1,4c1.5,0.9,3.3,1.3,5.5,1.1
					c2.3-0.2,4.1-0.2,5.8,0c1.8,0.3,3.6,0.8,5.8,2.5c-1.4-2.3-2.5-4-3.7-5.7C348.5,366.3,347.1,364.6,344.5,362.7z"
                  />
                </g>
                <g>
                  <path
                    d="M331.8,369.5c-2.3-1-4.8-0.5-6.4,0.6c-1.6,1.2-2.2,3-1.8,4.7c0.5,1.7,1.8,2.9,3.2,3.6c1.4,0.6,3,0.7,4.7-0.1
					c1.8-0.8,3.2-1.5,4.6-1.9c1.5-0.4,3-0.7,5.2-0.3c-1.8-1.3-3.1-2.3-4.4-3.4C335.6,371.6,334.2,370.5,331.8,369.5z"
                  />
                </g>
                <g>
                  <path
                    d="M311.8,380.6c-2.9,0.2-5.3,1.7-6.6,3.4c-1.4,1.8-1.7,3.8-0.7,5.3c0.9,1.5,2.8,2.1,4.8,2c2-0.1,4.1-0.9,5.9-2.7
					c1.9-1.8,3.2-3.1,4.7-4.3c1.5-1.2,3-2.3,5.6-3.4c-2.8,0-4.7-0.1-6.7-0.3C316.8,380.6,314.7,380.4,311.8,380.6z"
                  />
                </g>
                <g>
                  <path
                    d="M293.1,401.5c-4.3,1.1-5.8,5.7-3.6,8.4c2.2,2.8,7,2.4,9-1.5c2.1-4,2.4-6.1,5.1-9.6C299.6,400.6,297.5,400.4,293.1,401.5
					z"
                  />
                </g>
                <g>
                  <path
                    d="M280.3,421.6c-1.9,0.9-2.9,2.5-3.1,4.2c-0.2,1.7,0.5,3.3,2,4.4c1.4,1,3.2,1.2,4.7,0.7c1.5-0.5,2.7-1.7,3.1-3.6
					c0.9-3.9,0.8-5.9,2.7-9.4C286.3,419.9,284.3,419.9,280.3,421.6z"
                  />
                </g>
                <g>
                  <path
                    d="M314.7,432.9c4.2-2.3,7.9-5.1,10.4-7.8c2.5-2.7,3.8-5.2,2.9-6.7c-0.9-1.5-3.8-1.6-7.5-0.3c-3.7,1.3-8,4.1-11.4,8
					c-3.5,4-5.5,7-7.5,10c-2,3-4,6-7,10.3c3.8-3.6,6.7-5.7,9.7-7.5C307.3,436.9,310.4,435.2,314.7,432.9z"
                  />
                </g>
              </g>
              <g>
                <path
                  d="M344.5,109.3c1.3-4.4,1.9-9.1,1.4-13.7c-0.4-4.6-1.8-9.3-4.3-13.4c-0.3-0.5-0.6-1-1-1.5c-0.4-0.5-0.7-1-1.1-1.5
				c-0.8-0.9-1.5-1.8-2.4-2.7c-1.7-1.7-3.6-3.1-5.6-4.3c-4-2.4-8.3-3.8-12.6-4.4c-4.3-0.6-8.7-0.6-12.9,0.4
				c-4.2,0.9-8.3,2.7-11.6,5.5c-3.3,2.7-5.9,6.2-7.2,10.2c-0.7,1.9-1.1,4.1-1.1,6.1c0,2,0.2,4,0.8,5.9c1.1,3.7,3.3,7,6.3,9.2
				c3,2.2,6.5,3.1,9.6,3c3.1-0.1,6.1-1.1,8.3-2.8c1.1-0.9,2-1.9,2.7-2.9c0.7-1.1,1.2-2.2,1.4-3.2c0.6-2.1,0.5-4.1,0.2-5.7
				c-0.3-1.6-1-2.9-1.6-3.8c-1.4-1.8-2.6-2.1-2.9-1.8c-0.3,0.3,0.2,1.3,0.6,2.9c0.2,0.8,0.3,1.8,0.2,3c-0.1,1.1-0.4,2.4-1,3.7
				c-0.3,0.6-0.7,1.2-1.2,1.7c-0.5,0.5-1.1,0.9-1.7,1.3c-1.3,0.7-3,1.1-4.8,0.9c-1.8-0.2-3.7-0.9-5-2.1c-1.4-1.3-2.5-3.2-2.9-5.3
				c-0.2-1.1-0.3-2.2-0.2-3.4c0.1-1.1,0.4-2.2,0.8-3.3c0.9-2.2,2.6-4.3,4.7-6c2.1-1.6,4.8-2.7,7.7-3.3c2.9-0.6,6.2-0.6,9.4-0.1
				c6.4,1,12.6,4.2,15.9,9.6c3.5,5.4,4.4,12.8,2.7,19.6c-0.4,1.7-1,3.4-1.8,5c-0.7,1.6-1.6,3.1-2.6,4.6c-2,2.9-4.2,5.4-6.7,7.6
				c-5,4.4-10.6,7.5-16.3,9.7c-5.7,2.3-11.4,3.6-17.1,4.6c-5.7,1-11.4,1.5-17.1,1.9c-5.7,0.4-11.5,0.5-17.5,0.9
				c-6,0.4-12.3,1.1-18.6,3c-3.2,0.9-6.4,2.1-9.5,3.8c-3.1,1.6-6.1,3.7-8.8,6.3c-2.7,2.6-5,5.8-6.7,9.4c-1.6,3.6-2.6,7.8-2.2,12.1
				c-0.2-4.3,0.9-8.4,2.6-12c1.8-3.5,4.1-6.6,6.9-9c2.7-2.4,5.7-4.3,8.8-5.8c3.1-1.4,6.2-2.5,9.4-3.2c6.3-1.5,12.4-1.9,18.3-1.9
				c5.9-0.1,11.7,0.2,17.5,0.2c5.8,0,11.6-0.2,17.6-0.8c6-0.7,12.2-1.7,18.5-3.8c6.3-2,12.9-5,19-9.8c3.1-2.4,6-5.2,8.6-8.6
				C340.9,117.8,343.1,113.9,344.5,109.3z"
                />
              </g>
              <g>
                <path
                  d="M278.5,96c-3.9-1.2-8.4-1.3-12.5,0.5c-2,0.9-3.8,2.1-5.3,3.7c-1.5,1.5-2.7,3.3-3.6,5.3c-0.8,2-1.2,4.2-1.1,6.4
				c0.1,2.2,0.7,4.2,1.6,6c1.8,3.5,4.9,6.1,8.4,7c3.5,1,6.9,0.3,9.4-1.4c1.2-0.8,2.2-1.9,2.8-3c0.6-1.1,0.9-2.2,1-3.1
				c0.2-1.9-0.3-3.4-0.9-4.2c-0.6-0.8-1.2-1-1.6-0.9c-0.8,0.3-0.9,2.1-2.2,3.6c-0.3,0.4-0.7,0.8-1.1,1c-0.4,0.3-0.8,0.4-1.3,0.5
				c-1,0.2-2.4,0.1-3.4-0.5c-1.1-0.6-2.2-1.8-2.7-3.2c-0.6-1.5-0.5-2.8,0.1-4.4c0.7-1.6,2.1-3.1,3.8-4c1.7-0.9,3.9-1.2,6.3-0.9
				c4.9,0.7,8.5,4.3,10.6,8.1c2.2,3.9,3,8.1,2.7,12.3c-0.2,2.1-0.6,4.2-1.5,6.2c-0.4,1-1,2-1.6,3c-0.7,0.9-1.4,1.8-2.4,2.7
				c-1.8,1.6-4.1,3-6.9,3.8c-2.7,0.7-6,0.9-9.2-0.3c3.1,1.4,6.5,1.6,9.4,1.1c3-0.5,5.6-1.7,7.8-3.3c1.1-0.8,2.1-1.7,3-2.6
				c0.9-1,1.7-2,2.3-3.1c1.4-2.1,2.2-4.5,2.9-6.9c1.1-4.8,1-10-0.9-15.4c-1-2.7-2.4-5.4-4.7-8C285.7,99.6,282.5,97.2,278.5,96z"
                />
              </g>
              <g>
                <path
                  d="M315.1,122.6c2.7-2.4,4.1-5.8,4.2-8.5c0.1-2.8-0.9-4.8-2.6-5.3c-1.7-0.5-3.6,0.5-5.2,2.2c-1.6,1.7-2.9,3.9-3.7,6.8
				c-0.8,2.9-1.5,5-2.5,7.1c-1,2-2.1,4-4.5,6.4c3-1.5,5.1-2.6,7.3-3.8C310.2,126.3,312.4,125.1,315.1,122.6z"
                />
              </g>
              <g>
                <path
                  d="M366,89.8c2.5-1.7,4.3-3.8,5.4-5.9c1.1-2.1,1.5-4.2,0.4-5.7c-1-1.4-3.3-1.7-5.9-0.7c-2.6,1.1-5.4,3.6-6.8,6.9
				c-1.5,3.5-2,6-2.5,8.4c-0.4,2.5-0.8,4.9-1.4,8.3c1.5-3.1,3.1-4.9,4.7-6.6C361.7,93,363.5,91.6,366,89.8z"
                />
              </g>
              <g>
                <path
                  d="M379.9,95.6c3-0.8,5.4-2.4,6.9-4.3c1.5-1.8,2.2-3.9,1.4-5.5c-0.8-1.6-2.9-2.2-5.4-1.9c-2.5,0.3-5.4,1.7-7.6,4.1
				c-2.3,2.5-3.6,4.4-5,6.2c-1.4,1.8-2.8,3.6-5.1,6c2.9-1.5,5.1-2.3,7.2-2.9C374.6,96.9,376.8,96.4,379.9,95.6z"
                />
              </g>
              <g>
                <path
                  d="M390.9,105.2c3.1,0.2,5.9-0.4,8-1.5c2.1-1.1,3.7-2.6,3.6-4.4c0-1.7-1.7-3.3-4.4-4c-2.6-0.7-6.2-0.4-9.3,1.3
				c-3.2,1.7-5.2,3.3-7.1,4.7c-1.9,1.5-3.8,3-6.6,5c3.3-1.1,5.6-1.4,7.9-1.5C385.4,104.9,387.7,105,390.9,105.2z"
                />
              </g>
              <g>
                <path
                  d="M360.3,116.8c2.7-0.8,4.8-2.3,6.1-4c1.3-1.7,2-3.7,1.2-5.3c-0.7-1.6-2.7-2.4-5.1-2.1c-2.4,0.3-5.2,1.7-7.2,4.2
				c-2,2.5-3.1,4.5-4.2,6.4c-1.1,1.9-2.2,3.8-3.9,6.3c2.5-1.8,4.4-2.7,6.3-3.5C355.5,118.1,357.5,117.6,360.3,116.8z"
                />
              </g>
              <g>
                <path
                  d="M373.4,121.3c2.6,0.1,4.9-0.6,6.6-1.8c1.7-1.2,2.8-2.9,2.6-4.6c-0.2-1.7-1.8-3.1-4.1-3.6c-2.3-0.5-5.2,0-7.7,1.7
				c-2.5,1.8-4.1,3.3-5.6,4.7c-1.5,1.5-3,2.9-5.3,4.7c2.8-0.9,4.8-1.1,6.7-1.2C368.7,121,370.7,121.2,373.4,121.3z"
                />
              </g>
              <g>
                <path
                  d="M293.2,147.9c-6.4,0.5-11.3,5.2-10.1,8.5c1.2,3.3,8,3.6,13.2-0.3c5.3-4,7-6.2,12.8-9.5
				C302.5,148,299.8,147.4,293.2,147.9z"
                />
              </g>
              <g>
                <path
                  d="M300.9,162.8c-1.7,2.4-2,5.5-1.2,7.6c0.7,2.2,2.4,3.5,4.1,3.4c1.8-0.1,3.2-1.3,4.2-2.8c1-1.5,1.5-3.4,1.3-5.6
				c-0.2-2.3-0.3-4.1-0.2-5.9c0.2-1.8,0.5-3.6,1.8-5.9c-2.1,1.6-3.6,2.8-5.1,4.1C304.2,159,302.7,160.4,300.9,162.8z"
                />
              </g>
              <g>
                <path
                  d="M250.7,158.6c5.2-0.2,10.2-0.8,14.1-1.6c3.9-0.8,6.7-2,7-3.7c0.2-1.7-2.4-3.7-6.9-4.6c-4.4-1-10.5-0.7-16.1,1.4
				c-5.7,2.2-9.2,4.4-12.7,6.7c-3.4,2.3-6.7,4.7-11.2,8.5c5.1-2.9,9-4.2,12.8-5.1C241.5,159.3,245.3,158.8,250.7,158.6z"
                />
              </g>
              <g>
                <path
                  d="M452.7,61.6c-0.5-3.2-1.3-6.4-2.5-9.5c-1.2-3.1-2.9-6.2-5.1-8.9c-2.2-2.7-5-5-8.1-6.7c-3.1-1.7-6.7-2.7-10.3-2.8l-1.4,0
				l-1.4,0.1c-0.9,0.1-1.8,0.3-2.7,0.5c-1.8,0.5-3.4,1.1-4.9,2c-3,1.7-5.6,4.2-7.3,7.3c-1.7,3-2.4,6.8-1.9,10
				c0.2,1.6,0.7,3.2,1.3,4.6c0.6,1.4,1.4,2.7,2.2,3.8c3.4,4.5,8.5,7,13.1,7.2c2.3,0.1,4.6-0.3,6.5-1.3c1-0.5,1.8-1.1,2.5-1.8
				c0.7-0.7,1.2-1.5,1.7-2.3c1.5-3.2,1-6,0-7.5c-0.5-0.8-1-1.2-1.4-1.4c-0.4-0.2-0.7-0.2-0.9,0c-0.3,0.3-0.1,1.1-0.1,2.2
				c0,0.6-0.1,1.2-0.4,1.9c-0.3,0.7-0.7,1.5-1.3,2.1c-0.3,0.3-0.7,0.6-1,0.8c-0.3,0.2-0.8,0.4-1.3,0.5c-1,0.2-2.2,0.2-3.5-0.1
				c-2.6-0.6-5.4-2.4-7.1-5.2c-0.4-0.7-0.8-1.4-1-2.2c-0.2-0.8-0.4-1.5-0.4-2.3c-0.1-0.8,0-1.5,0.2-2.2c0.2-0.7,0.5-1.4,0.9-2.1
				c0.8-1.4,2.2-2.6,3.9-3.5c0.8-0.4,1.7-0.8,2.6-1c0.4-0.1,0.9-0.2,1.4-0.2l0.7,0l0.7,0c1.9,0.1,4,0.6,5.9,1.7
				c1.9,1,3.8,2.5,5.3,4.2c3.1,3.5,5.2,8.4,6.4,13.7c1.2,5.5,1.6,10.9,1.4,15.9c-0.2,5-0.9,9.7-1.9,14.1c-2.1,8.8-5.4,16.4-9.6,23.5
				c-4.2,7.1-9.5,13.8-16.3,20.4c-6.8,6.6-15.2,13.2-26.1,19.6c11.2-6,20-12.1,27.3-18.3c7.3-6.2,13.3-12.6,18.3-19.6
				c5-7,9.2-14.7,12.3-23.9c1.5-4.6,2.7-9.6,3.4-15.1C453.5,74.1,453.6,68.1,452.7,61.6z"
                />
              </g>
              <g>
                <path
                  d="M420.9,70.1c-1.7-0.9-3.7-1.5-5.7-1.6c-2.1-0.2-4.2,0.2-6.2,0.9c-1.9,0.7-3.7,1.8-5.2,3.1c-1.5,1.4-2.7,3-3.5,5
				c-0.8,1.9-1.2,4.1-1.1,6c0.1,2,0.6,3.8,1.4,5.5c1.6,3.3,4.3,5.8,7.4,6.9c1.6,0.6,3.2,0.8,4.6,0.6c1.4-0.1,2.7-0.5,3.8-0.9
				c2.2-1,3.7-2.4,4.6-3.8c0.9-1.4,1.2-2.7,1.1-3.6c-0.1-0.9-0.4-1.4-0.8-1.5c-0.9-0.2-1.9,0.9-3.8,1.6c-0.9,0.4-2,0.7-3.2,0.8
				c-1.2,0-2.4-0.2-3.2-0.9c-0.9-0.6-1.7-1.7-2.1-3c-0.2-0.6-0.3-1.3-0.3-2c0-0.6,0.2-1.2,0.4-1.8c0.5-1.2,1.7-2.4,3.2-3
				c0.7-0.3,1.5-0.5,2.2-0.6c0.8,0,1.7,0.1,2.6,0.3c1.9,0.6,3.8,1.9,5.4,3.4c0.8,0.8,1.5,1.6,2.2,2.4c0.7,0.8,1.3,1.7,1.9,2.6
				c2.3,3.5,3.9,7.1,5,10.9c1.1,3.8,1.7,7.8,1.5,12.4c0,0.6-0.1,1.1-0.1,1.7c0,0.6-0.1,1.2-0.2,1.8c-0.1,1.2-0.4,2.4-0.6,3.7
				c-0.6,2.5-1.4,5.2-2.7,8c1.5-2.7,2.7-5.3,3.5-7.8c0.4-1.3,0.8-2.5,1.1-3.7c0.1-0.6,0.3-1.2,0.4-1.8c0.1-0.6,0.2-1.2,0.3-1.8
				c0.8-4.7,0.9-9,0.5-13.3c-0.5-4.3-1.4-8.6-3.3-13.1c-0.5-1.1-1-2.3-1.7-3.4c-0.6-1.2-1.4-2.4-2.2-3.5
				C426.7,74.4,424.4,72,420.9,70.1z"
                />
              </g>
              <g>
                <g>
                  <path
                    d="M503.8,68.5c5.7-0.7,11.4-1.1,16.9-1.7c5.5-0.5,10.7-1.1,15.1-2c4.5-0.8,8.2-1.9,10.7-2.9c2.5-1.1,3.9-2.2,3.7-3
					c-0.2-0.9-1.8-1.4-4.4-1.8c-2.6-0.4-6.3-0.5-10.8-0.6c-4.4-0.1-9.6,0-15.3,0.4c-5.6,0.4-11.8,1.3-17.7,2.9
					c-6.2,1.7-11.2,3.7-15.6,6c-4.3,2.3-8,4.8-11.3,7.8c-0.8,0.7-1.6,1.5-2.4,2.3c-0.8,0.8-1.5,1.6-2.3,2.5
					c-1.5,1.7-2.9,3.6-4.2,5.6c-2.6,4.1-4.9,9.2-5.6,15.5c1.2-6.2,4.1-10.9,7-14.5c1.5-1.8,3.1-3.4,4.7-4.7c0.8-0.7,1.7-1.3,2.5-2
					c0.9-0.6,1.7-1.2,2.6-1.7c3.5-2.2,7.2-3.8,11.4-5.1C493.2,70.2,498,69.2,503.8,68.5z"
                  />
                </g>
                <g>
                  <path
                    d="M512,85.7c5.6-1.4,11.1-3.6,15.9-6.1c4.8-2.5,9.1-5.5,12.4-8.3c6.7-5.7,9.8-10.9,8.6-12.1c-1.2-1.3-6.2,1.4-13.3,5.2
					c-7.1,3.9-16.5,8.8-26.3,13c-5.1,2.2-9.5,3.7-13.6,4.9c-4.1,1.2-8,2.1-11.9,3.3c-3.9,1.1-7.8,2.4-11.9,4.4c-2,1-4.2,2.2-6.2,3.8
					c-2.1,1.5-4.1,3.5-5.8,5.9c1.9-2.2,4.1-3.9,6.3-5.2c2.2-1.3,4.3-2.2,6.4-2.9c4.2-1.4,8.1-1.9,12-2.3c3.9-0.4,7.8-0.5,12.3-1
					C501.2,87.9,506.2,87.1,512,85.7z"
                  />
                </g>
                <g>
                  <path
                    d="M519.6,76.1c6.3-1,12.2-3.4,16.2-6c4.1-2.6,6.3-5.3,5.6-6.9c-0.7-1.6-4.1-1.8-8.7-0.9c-4.5,0.9-10.2,2.7-15.7,5.4
					c-5.7,2.7-9.6,4.6-13.6,6.3c-4.1,1.7-8.3,3.3-14.6,4.9c6.6-0.6,11-0.9,15.5-1.2C508.7,77.4,513.2,77.1,519.6,76.1z"
                  />
                </g>
                <g>
                  <path
                    d="M517.7,72.2c4.9-0.5,9.4-2.3,12.5-4.4c3.1-2.1,4.8-4.4,4.1-6c-0.7-1.6-3.4-2.2-7-1.8c-3.6,0.4-8,1.7-12.2,3.8
					c-4.3,2.3-7.3,3.9-10.4,5.4c-3.1,1.5-6.2,2.9-11.1,4.4c5-0.4,8.5-0.6,12-0.7C509.2,72.8,512.7,72.7,517.7,72.2z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M490.4,213.7c-5.6-1.1-11.2-2.4-16.6-3.5c-5.4-1.2-10.5-2.2-15-2.7c-4.5-0.6-8.4-0.7-11.1-0.5c-2.7,0.3-4.3,0.9-4.5,1.8
					c-0.1,0.9,1.2,1.9,3.6,3c2.4,1.1,5.9,2.4,10.1,3.8c4.2,1.4,9.2,2.9,14.7,4.2c5.5,1.3,11.6,2.3,17.8,2.6c6.4,0.2,11.8-0.1,16.7-1
					c4.8-0.9,9.1-2.1,13.1-4c1-0.4,2-1,3-1.4c1-0.5,2-1.1,2.9-1.7c1.9-1.2,3.8-2.5,5.7-4.1c3.7-3.1,7.4-7.3,10-13.1
					c-3.1,5.6-7.2,9.1-11.1,11.7c-2,1.3-3.9,2.3-5.9,3.1c-1,0.4-2,0.8-2.9,1.1c-1,0.3-2,0.6-3,0.8c-3.9,1-8,1.4-12.4,1.4
					C501.1,215.3,496.2,214.8,490.4,213.7z"
                  />
                </g>
                <g>
                  <path
                    d="M487.9,194.8c-5.8-0.4-11.6,0.1-17,1c-5.4,1-10.3,2.5-14.3,4.2c-8.1,3.4-12.6,7.4-11.8,8.9c0.8,1.6,6.3,0.6,14.3-0.9
					c8-1.5,18.4-3.4,29-4.4c5.5-0.6,10.2-0.7,14.5-0.6c4.3,0.1,8.3,0.4,12.3,0.5c4,0.1,8.2,0.1,12.7-0.6c2.3-0.4,4.6-0.9,7.1-1.7
					c2.4-0.9,4.9-2.1,7.3-3.9c-2.5,1.6-5.1,2.5-7.5,3.1c-2.4,0.6-4.8,0.8-7,0.8c-4.4,0-8.3-0.6-12.1-1.4c-3.8-0.8-7.6-1.9-12-2.8
					C498.8,196,493.9,195.2,487.9,194.8z"
                  />
                </g>
                <g>
                  <path
                    d="M477.7,201.6c-6.3-0.9-12.6-0.4-17.3,0.8c-4.7,1.2-7.6,3.1-7.4,4.9c0.2,1.8,3.4,2.9,8,3.5c4.6,0.5,10.6,0.5,16.6-0.4
					c6.2-0.9,10.5-1.5,14.9-1.9c4.4-0.4,8.9-0.6,15.4-0.3c-6.4-1.4-10.8-2.5-15.2-3.5C488.5,203.7,484.2,202.6,477.7,201.6z"
                  />
                </g>
                <g>
                  <path
                    d="M478.3,205.9c-4.8-1-9.7-0.7-13.3,0.4c-3.6,1-5.9,2.8-5.8,4.5c0.1,1.8,2.6,3.1,6.1,3.9c3.5,0.7,8.1,0.8,12.8,0
					c4.8-0.8,8.1-1.5,11.5-2c3.4-0.5,6.8-0.9,11.9-0.8c-4.9-1.1-8.3-2-11.6-3C486.6,207.9,483.3,206.9,478.3,205.9z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M295.7,319.1c4.1-3.9,8.4-7.8,12.5-11.5c4.1-3.7,7.9-7.3,10.9-10.7c3.1-3.3,5.4-6.4,6.8-8.8c1.4-2.4,1.8-4.1,1.2-4.7
					c-0.6-0.6-2.3-0.1-4.6,1.2c-2.3,1.3-5.4,3.4-9,6c-3.6,2.6-7.7,5.8-12,9.5c-4.2,3.7-8.6,8.1-12.4,13c-3.9,5.1-6.7,9.7-8.9,14.2
					c-2.1,4.4-3.5,8.7-4.4,13c-0.2,1.1-0.4,2.2-0.6,3.3c-0.1,1.1-0.3,2.2-0.3,3.3c-0.2,2.3-0.2,4.6,0,7c0.4,4.8,1.6,10.3,4.8,15.8
					c-2.8-5.7-3.3-11.1-3.1-15.8c0.1-2.3,0.4-4.5,0.9-6.6c0.2-1,0.5-2,0.8-3c0.3-1,0.6-2,1-2.9c1.5-3.8,3.5-7.3,6.1-11
					C288.2,326.9,291.4,323.2,295.7,319.1z"
                  />
                </g>
                <g>
                  <path
                    d="M312.5,328c3.7-4.5,6.7-9.5,9-14.5c2.3-4.9,4-9.8,4.9-14.1c1.9-8.6,1.3-14.5-0.4-14.8c-1.8-0.3-4.2,4.8-7.5,12.2
					c-3.4,7.4-7.9,16.9-13.3,26.2c-2.7,4.8-5.4,8.7-7.9,12.1c-2.6,3.4-5.1,6.5-7.5,9.7c-2.4,3.2-4.8,6.6-6.9,10.7
					c-1,2.1-2,4.3-2.7,6.8c-0.7,2.5-1.2,5.2-1.1,8.2c0.2-3,0.9-5.6,1.9-7.9c1-2.3,2.2-4.3,3.4-6.1c2.5-3.6,5.3-6.4,8.2-9
					c2.9-2.7,5.9-5.1,9.3-8.2C305.2,336.2,308.7,332.7,312.5,328z"
                  />
                </g>
                <g>
                  <path
                    d="M312.9,315.8c4.4-4.6,7.7-10,9.4-14.5c1.7-4.5,1.8-8,0.3-8.8c-1.6-0.9-4.4,1.1-7.5,4.5c-3.1,3.4-6.5,8.3-9.3,13.8
					c-2.9,5.6-4.9,9.4-7.1,13.3c-2.2,3.8-4.6,7.6-8.7,12.7c4.9-4.4,8.3-7.4,11.7-10.3C305,323.4,308.3,320.5,312.9,315.8z"
                  />
                </g>
                <g>
                  <path
                    d="M309,313.8c3.6-3.4,6.2-7.5,7.4-11c1.2-3.5,1.2-6.4-0.3-7.3c-1.5-0.9-4,0.3-6.7,2.7c-2.6,2.5-5.4,6.1-7.4,10.4
					c-2.1,4.4-3.5,7.5-5,10.5c-1.6,3-3.3,6.1-6.2,10.2c3.8-3.4,6.5-5.6,9.2-7.8C302.6,319.3,305.3,317.2,309,313.8z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M112.7,104.3c4.4,3.7,8.6,7.6,12.7,11.2c4.1,3.6,8.1,7,11.7,9.8c3.6,2.7,6.9,4.7,9.5,5.9c2.5,1.1,4.2,1.4,4.7,0.7
					c0.5-0.7-0.2-2.2-1.7-4.4c-1.5-2.2-3.9-5-6.9-8.3c-3-3.3-6.6-7.1-10.7-10.9c-4.2-3.8-8.9-7.7-14.2-11c-5.4-3.3-10.4-5.7-15-7.3
					c-4.6-1.6-9-2.6-13.4-3c-1.1-0.1-2.2-0.1-3.3-0.2c-1.1,0-2.2,0-3.4,0c-2.3,0.1-4.6,0.3-7,0.8c-4.8,0.9-10.1,2.7-15.2,6.4
					c5.4-3.3,10.7-4.4,15.4-4.7c2.3-0.1,4.6,0,6.7,0.2c1.1,0.1,2.1,0.3,3.1,0.5c1,0.2,2,0.4,3,0.7c3.9,1.1,7.6,2.7,11.5,4.9
					C104.2,97.6,108.2,100.5,112.7,104.3z"
                  />
                </g>
                <g>
                  <path
                    d="M105.7,121.9c4.9,3.2,10.2,5.7,15.3,7.5c5.2,1.8,10.2,2.9,14.5,3.4c8.7,1,14.6-0.3,14.7-2c0.1-1.8-5.2-3.6-12.9-6.2
					c-7.7-2.6-17.7-6.1-27.5-10.4c-5.1-2.2-9.2-4.4-12.9-6.6c-3.7-2.2-7-4.4-10.5-6.5C83,99,79.4,97,75.1,95.4c-2.1-0.8-4.5-1.5-7-2
					c-2.5-0.5-5.3-0.6-8.3-0.2c3-0.1,5.7,0.3,8.1,1c2.4,0.7,4.5,1.7,6.5,2.7c3.9,2.1,6.9,4.6,9.8,7.2c2.9,2.6,5.7,5.4,9.1,8.4
					C96.7,115.5,100.6,118.7,105.7,121.9z"
                  />
                </g>
                <g>
                  <path
                    d="M117.9,121c5,3.9,10.8,6.6,15.4,7.8c4.7,1.2,8.1,1,8.8-0.6c0.7-1.6-1.5-4.2-5.3-7c-3.7-2.7-9-5.6-14.7-7.8
					c-5.8-2.3-9.9-3.9-13.9-5.7c-4-1.8-8-3.8-13.6-7.4c4.9,4.4,8.2,7.5,11.5,10.5C109.5,113.9,112.7,117,117.9,121z"
                  />
                </g>
                <g>
                  <path
                    d="M119.5,117c3.7,3.2,8.1,5.3,11.7,6.2c3.6,0.9,6.5,0.5,7.2-1.1c0.8-1.6-0.7-4-3.4-6.4c-2.7-2.4-6.7-4.7-11.1-6.3
					c-4.6-1.6-7.8-2.7-11-3.9c-3.2-1.2-6.4-2.6-10.8-5.1c3.8,3.4,6.2,5.8,8.7,8.3C113.2,111.2,115.6,113.7,119.5,117z"
                  />
                </g>
              </g>
              <g>
                <path
                  d="M46.9,120.2c-4.8-3.3-10.4-5.5-16.3-6.1c-2.9-0.3-5.9-0.2-8.8,0.3c-2.9,0.5-5.8,1.4-8.4,2.9c-2.6,1.5-5.1,3.5-6.9,6.1
				c-0.5,0.7-0.9,1.3-1.2,2c-0.3,0.7-0.7,1.5-0.9,2.2L4,128.7l-0.2,1.1c-0.2,0.8-0.2,1.5-0.3,2.3c-0.1,3,0.5,5.8,1.6,8.3
				c1.1,2.5,2.6,4.6,4.5,6.3c1.8,1.7,4,3,6.2,3.6c2.1,0.7,4.2,1,6.1,0.9c1.9-0.1,3.6-0.4,5.2-0.9c1.5-0.5,2.8-1.1,3.9-1.8
				c2.2-1.3,3.6-2.9,4.3-4c0.7-1.2,0.8-2,0.5-2.2c-0.3-0.3-1.1-0.1-2.2,0.3c-1.1,0.4-2.6,1.1-4.6,1.5c-1,0.2-2,0.4-3.2,0.5
				c-1.1,0.1-2.4,0-3.6-0.2c-1.2-0.2-2.5-0.6-3.6-1.2c-1.2-0.6-2.1-1.4-3-2.4c-1.8-2.1-2.9-5.2-2.6-8.1c0.1-0.3,0.1-0.7,0.2-1
				l0.1-0.5l0.2-0.5c0.1-0.3,0.3-0.6,0.4-0.9c0.2-0.3,0.4-0.6,0.6-0.9c0.9-1.2,2.2-2.2,3.7-3.1c1.6-0.9,3.4-1.5,5.4-1.8
				c2-0.4,4.1-0.5,6.2-0.3c4.2,0.3,8.6,1.6,12.4,3.9c4,2.3,7.2,5.4,9.7,8.7c2.5,3.3,4.3,6.9,5.7,10.4c2.6,7.1,3.3,14.2,2.9,21.3
				c-0.4,7.1-1.8,14.3-4.7,22c-3,7.6-7.4,15.8-14.9,23.8c7.8-7.7,12.8-15.6,16.5-23.2c3.6-7.6,5.7-14.8,6.9-22.3
				c0.6-3.7,0.9-7.5,0.8-11.4c-0.1-3.9-0.5-8-1.6-12.1c-1.1-4.2-2.8-8.5-5.4-12.7C55.4,127.8,51.9,123.6,46.9,120.2z"
                />
              </g>
              <g>
                <path
                  d="M34,153.7c-3,0.6-5.5,2.1-7.5,4c-2,1.9-3.5,4.3-4.3,7c-0.4,1.3-0.6,2.7-0.6,4.1c0,1.4,0.2,2.8,0.7,4.1
				c0.8,2.5,2.4,4.7,4.4,6.1c1,0.7,2,1.2,3.1,1.5c1.1,0.3,2.1,0.4,3,0.4c1.9-0.1,3.4-0.7,4.4-1.5c1-0.8,1.6-1.6,1.8-2.3
				c0.2-0.7,0-1.2-0.3-1.5c-0.7-0.6-1.9-0.4-3.2-0.7c-0.7-0.1-1.4-0.4-2-0.7c-0.3-0.2-0.6-0.4-0.8-0.6c-0.2-0.2-0.4-0.5-0.6-0.8
				c-0.4-0.6-0.7-1.4-0.8-2.3c-0.1-0.4-0.1-0.9,0-1.3c0-0.5,0.2-1,0.3-1.5c0.6-2.1,2.6-4.5,4.7-5.5c1.1-0.5,2.1-0.7,3.3-0.5
				c1.2,0.2,2.3,0.8,3.3,1.6c1,0.8,1.8,1.7,2.5,2.8c0.7,1.1,1.2,2.3,1.6,3.6c0.8,2.6,1,5.5,0.9,8.8c-0.1,3.3-0.7,7-0.7,11.4
				c0.6-4.4,1.7-7.9,2.4-11.2c0.8-3.3,1.3-6.4,1.2-9.6c-0.1-1.6-0.3-3.3-0.8-4.9c-0.5-1.7-1.3-3.4-2.5-5c-1.2-1.6-2.8-3.2-5.1-4.3
				c-1.1-0.6-2.4-1-3.8-1.2C37.1,153.3,35.5,153.4,34,153.7z"
                />
              </g>
              <g>
                <path
                  d="M80.9,129c2-0.8,3.3-2.1,4-3.7c0.7-1.6,0.9-3.4,0.1-5c-0.8-1.5-2.7-2.3-4.8-1.9c-2.1,0.4-4.4,2-5.6,4.5
				c-1.2,2.6-1.6,4.5-1.9,6.4c-0.3,1.9-0.7,3.6-1.1,6.1c1.5-2,2.9-3.1,4.3-4C77.3,130.6,78.8,129.9,80.9,129z"
                />
              </g>
              <g>
                <path
                  d="M87.6,140.1c1.9,0,3.2-0.8,4.1-2.1c0.9-1.2,1.4-2.9,1-4.7c-0.4-1.7-1.8-3-3.7-3.3c-1.9-0.3-4.2,0.4-5.7,2.3
				c-1.5,2-2.3,3.5-3,5c-0.7,1.5-1.3,2.9-2.3,4.8c1.8-1.2,3.2-1.6,4.7-1.9C84.1,140.2,85.6,140.1,87.6,140.1z"
                />
              </g>
              <g>
                <path
                  d="M69.7,113.9c0.7-3.1,0.1-6.2-1.2-8.3c-1.3-2.1-3.2-3.1-4.9-2.7c-1.7,0.5-2.8,2.2-3.3,4.3c-0.5,2.1-0.3,4.6,0.7,7.2
				c1.1,2.6,1.8,4.6,2.5,6.6c0.6,2,1,4.1,1,7.1c1.1-2.9,1.9-4.9,2.8-6.9C68.1,119.2,69,117.1,69.7,113.9z"
                />
              </g>
              <g>
                <path d="M51.6,99.8c-0.9-5.8-6-9.3-9.1-7.5c-3,1.8-2.7,7.7,1.3,11.5c4.1,4,6.3,5.3,9.7,10.1C52.1,108.1,52.6,105.7,51.6,99.8z" />
              </g>
              <g>
                <path
                  d="M25.9,96.2c-2.9-2.2-6.5-3.1-9.2-2.9c-2.8,0.2-4.8,1.4-5.1,3.1c-0.3,1.7,0.9,3.6,3,5c2,1.5,4.9,2.6,8.1,2.9
				c3.3,0.4,5.7,0.6,8.1,1.1c2.4,0.5,4.7,1.1,7.9,2.7c-2.6-2.5-4.3-4.2-6.1-6C30.7,100.2,28.9,98.4,25.9,96.2z"
                />
              </g>
              <g>
                <path
                  d="M-1.6,103.7c-2.7-0.3-5.2,0.9-6.5,2.5c-1.4,1.7-1.5,3.6-0.6,5.1c1,1.5,2.6,2.2,4.2,2.4c1.6,0.2,3.2-0.2,4.8-1.5
				c1.6-1.3,3-2.3,4.4-3c1.4-0.8,3-1.3,5.4-1.4c-2.1-0.9-3.7-1.6-5.5-2.4C3,104.7,1.2,104-1.6,103.7z"
                />
              </g>
              <g>
                <path
                  d="M-13.1,124.3c-2.5,1.7-3.7,4.6-3.5,6.9c0.2,2.4,1.6,3.9,3.3,4.1c1.8,0.2,3.4-0.6,4.6-1.5c1.3-1,2.1-2.2,2.5-4.1
				c0.4-2,0.9-3.5,1.7-5c0.8-1.4,1.9-2.8,4.1-4c-2.5,0.2-4.3,0.5-6.2,0.9C-8.6,122.1-10.6,122.6-13.1,124.3z"
                />
              </g>
              <g>
                <path
                  d="M-9.6,149.9c-0.9,2.6-0.3,5.4,1.1,7.1c1.4,1.7,3.3,2.2,4.9,1.5c1.6-0.7,2.7-2.2,3.2-3.7c0.5-1.5,0.5-3.1-0.4-4.8
				c-0.9-1.8-1.5-3.3-1.9-4.9c-0.3-1.6-0.5-3.2,0.1-5.5c-1.6,1.8-2.7,3.2-3.8,4.6C-7.5,145.6-8.6,147.2-9.6,149.9z"
                />
              </g>
              <g>
                <path
                  d="M3.1,168.8c0.4,2.9,2.4,5.2,4.4,6.2c2.1,1,4.1,0.7,5.2-0.6c1.2-1.3,1.4-3.2,1.1-4.9c-0.3-1.7-1.2-3.3-2.9-4.7
				c-1.7-1.4-3-2.6-4.1-3.9c-1.1-1.3-2-2.9-2.7-5.4c-0.4,2.6-0.7,4.4-1,6.4C2.9,163.8,2.7,165.8,3.1,168.8z"
                />
              </g>
              <g>
                <path
                  d="M75.4,229.7c2.3,1.4,4,3.2,4.8,5.2c0.9,2,0.9,4.3,0.3,5.8c-0.6,1.5-2,2.8-3.6,3.4c-0.8,0.3-1.5,0.4-2.3,0.4
				c-0.8,0-1.6-0.2-2.3-0.6c-1.5-0.6-2.7-1.8-3.4-2.9c-0.7-1.1-0.9-2.1-0.8-2.9c0.1-0.8,0.6-1.6,1.1-2c0.4-0.5,0.9-0.8,1-1.2
				c0.1-0.4-0.3-1-1.5-1.3c-1.1-0.2-3,0.1-4.7,1.8c-0.4,0.4-0.8,1-1.1,1.6c-0.3,0.6-0.5,1.2-0.7,1.9c-0.3,1.4-0.3,2.8,0.1,4.3
				c0.7,2.9,2.5,5.8,5.5,7.9c1.5,1,3.3,1.8,5.3,2.3c2,0.4,4.2,0.4,6.3-0.2c2.1-0.5,4.1-1.5,5.9-3c1.8-1.4,3.3-3.4,4.2-5.7
				c0.9-2.3,1.3-4.7,1.1-7c-0.1-2.3-0.8-4.6-1.8-6.7c-1-2.1-2.5-4-4.2-5.5c-1.7-1.5-3.6-2.6-5.6-3.4c-4.1-1.7-8.1-2-11.5-1.8
				c-3.5,0.2-6.5,0.9-9.3,1.7c-5.5,1.7-10.2,3.7-14.6,6.1c-4.5,2.5-8.8,5.2-13,9c-2.1,1.9-4.3,4-6.3,6.4c-2.1,2.5-4.1,5.2-5.8,8.5
				c2-3.1,4.2-5.7,6.4-7.9c2.2-2.2,4.5-4.1,6.8-5.6c4.5-3.2,9-5.2,13.5-6.9c4.5-1.7,9.2-2.8,14.4-3.6c2.6-0.3,5.2-0.5,7.9-0.3
				c1.3,0.1,2.7,0.3,4,0.7C72.9,228.5,74.2,229,75.4,229.7z"
                />
              </g>
              <g>
                <path
                  d="M55.5,215c4.5-2.5,8.1-6.3,10.1-9.7c2-3.4,2.4-6.3,1-7.5c-1.4-1.1-4.1-0.2-7.1,1.7c-3.1,2-6.5,5-9.6,8.6
				c-3.2,3.8-5.4,6.3-7.9,8.8c-2.5,2.5-5.2,4.8-9.6,7.6c4.8-1.8,8.2-3.1,11.7-4.4C47.5,218.9,50.9,217.5,55.5,215z"
                />
              </g>
              <g>
                <path
                  d="M75.4,216.2c2.3,0.5,4.3,0.1,5.9-0.8c1.6-1,2.7-2.5,2.7-4.3c0-1.8-1.2-3.3-3.1-4.1c-1.9-0.8-4.5-0.8-6.8,0.5
				c-2.4,1.3-3.8,2.5-5.3,3.6c-1.5,1.1-2.9,2.2-5.1,3.5c2.5-0.3,4.3-0.1,6,0.2C71.3,215.2,73,215.7,75.4,216.2z"
                />
              </g>
              <g>
                <path
                  d="M35.3,208.6c0.9-2.4,1.3-5,1.3-7.4c0-0.6,0-1.2-0.1-1.8c-0.1-0.6-0.1-1.1-0.2-1.7c-0.2-1.2-0.6-2.3-1.1-3.4
				c-0.5-1.1-1.1-2-1.9-2.8c-0.7-0.8-1.6-1.3-2.3-1.6c-1.6-0.5-2.6,0-3,0.7c-0.4,0.8-0.3,1.8-0.5,2.6c-0.1,0.9-0.3,1.8-0.4,3
				c-0.1,0.7-0.1,1.4-0.2,2.1c0,0.4-0.1,0.9-0.1,1.3c0,0.5,0,0.9-0.1,1.4c-0.1,1.9-0.1,3.9,0,6c0.1,4.3-0.2,7.5-0.8,10.6
				c-0.7,3.1-1.8,6.3-4.7,10.3c3.7-3.3,5.9-5.9,8.1-8.7c1.1-1.4,2.1-2.8,3.1-4.5C33.4,213,34.4,211.1,35.3,208.6z"
                />
              </g>
              <g>
                <path
                  d="M124.7,53.8c3.3-0.9,6.7-1.7,9-1.4c1.2,0.1,2,0.4,2.7,0.9c0.6,0.4,1.2,1.1,1.6,2c0.9,1.8,1,4.3,0.5,6.2
				c-0.1,0.5-0.3,1-0.5,1.5c-0.2,0.5-0.5,0.9-0.7,1.4c-0.5,0.8-1.2,1.5-1.8,2.1c-1.2,1-2.9,1.5-4.2,1.4c-0.7,0-1.3-0.2-1.8-0.4
				c-0.5-0.3-1-0.6-1.4-1c-1.7-1.6-1.8-3.6-2.6-3.9c-0.4-0.1-1,0.2-1.5,1.2c-0.5,1-0.8,2.8-0.1,4.9c0.3,1,0.9,2.2,1.9,3.2
				c1,1.1,2.2,2,3.8,2.6c3,1.2,6.9,1.2,10.4-0.7c1.7-0.9,3.3-2.2,4.6-3.7c0.7-0.8,1.2-1.6,1.8-2.5c0.5-0.9,1-1.8,1.4-2.9
				c0.8-2.1,1.1-4.3,1.1-6.5c-0.1-2.3-0.5-4.6-1.5-6.9c-1.1-2.3-2.8-4.5-5.2-6c-2.4-1.5-5.1-2.2-7.6-2.3c-2.5-0.1-4.7,0.3-6.8,0.9
				c-2.1,0.6-3.9,1.2-5.7,2c-3.8,1.6-7.6,3.8-10.3,7c-1.3,1.6-2.3,3.5-2.9,5.3c-0.6,1.8-0.9,3.6-0.9,5.3c-0.1,3.4,0.5,6.4,1.3,9.2
				c0.9,2.8,2,5.4,3.4,7.8c2.8,4.8,6.6,9.1,11.7,12.4c2.6,1.7,5.5,3.1,8.7,4.2c3.3,1.1,6.9,1.7,10.9,1.8c-3.9-0.3-7.5-1.3-10.6-2.6
				c-3.1-1.3-5.7-3-8-4.8c-4.5-3.7-7.5-8.1-9.4-12.7c-1-2.3-1.6-4.7-2-7.2c-0.4-2.5-0.5-5,0-7.5c0.2-1.2,0.6-2.4,1.1-3.4
				c0.5-1,1.2-2,2.1-2.8C118.7,56,121.3,54.8,124.7,53.8z"
                />
              </g>
              <g>
                <path
                  d="M86,72c-3-1.1-6.3-0.6-8.4,0.7c-2.1,1.3-3.1,3.2-2.6,4.9c0.5,1.7,2.2,2.8,4,3.3c1.9,0.6,4,0.6,6.4-0.2
				c2.5-0.8,4.3-1.2,6.3-1.4c1.9-0.2,4-0.2,6.8,0.7c-2.3-1.8-4-3.1-5.7-4.3C90.9,74.4,89.1,73.1,86,72z"
                />
              </g>
              <g>
                <path
                  d="M99.5,62.1c0.8-1.3,0.8-2.3,0.3-3.6c-0.5-1.2-1.3-2.8-2.7-3.8c-0.7-0.5-1.6-0.7-2.5-0.6c-0.9,0.1-1.8,0.5-2.6,1.3
				c-0.8,0.8-1.4,1.9-1.7,3.2c-0.3,1.3-0.2,2.8,0.4,4.1c1.2,2.8,2.7,4.1,4.1,5.3c1.4,1.1,2.9,2,4.9,2.9c-1.3-1.8-1.6-3.3-1.5-4.7
				C98.2,64.8,98.7,63.5,99.5,62.1z"
                />
              </g>
              <g>
                <path
                  d="M152.6,18.3c3.1-2.4,6-4.9,8.2-6.9c2.2-2,3.6-3.6,3.1-4.4c-0.4-0.8-2.6-0.5-5.5,1c-2.8,1.4-6.3,4-8.9,7.3
				c-2.7,3.4-4.2,5.9-5.6,8.5c-1.4,2.6-2.7,5.2-4.4,9.2c2.2-3.7,4-6,6-8.1C147.4,22.8,149.4,20.8,152.6,18.3z"
                />
              </g>
              <g>
                <path
                  d="M161.5,23.3c3.2-1.8,6.3-3.8,8.5-5.5c2.2-1.7,3.6-3.2,3.2-4c-0.4-0.8-2.5-0.7-5.2,0.3c-2.8,1-6.2,3-9.1,5.6
				c-2.9,2.7-4.7,4.8-6.5,6.9c-1.7,2.1-3.5,4.3-5.9,7.5c2.8-2.9,5-4.6,7.2-6.1C155.9,26.6,158.1,25.2,161.5,23.3z"
                />
              </g>
              <g>
                <path
                  d="M160,33.4c2.1-0.6,3.9-1.6,5.2-2.7c1.3-1,2-2.1,1.7-3c-0.3-0.8-1.6-1.2-3.4-0.9c-1.7,0.3-3.9,1.2-5.7,2.7
				c-1.8,1.6-2.9,2.8-4,4c-1.1,1.2-2.2,2.4-3.9,4.1c2-1.3,3.5-1.9,4.9-2.5C156.3,34.6,157.8,34.1,160,33.4z"
                />
              </g>
              <g>
                <path
                  d="M207.1,151.4c1.6-3.8,3.4-7.5,4.8-10.2c1.4-2.8,2.4-4.8,1.8-5.4c-0.6-0.6-2.8,0.4-5.2,3c-2.4,2.6-4.7,6.8-5.6,11.4
				c-0.8,4.7-0.7,8-0.3,11.2c0.4,3.2,1,6.4,2.6,10.8c-1.1-4.5-1.1-7.7-0.7-10.8C204.8,158.3,205.5,155.3,207.1,151.4z"
                />
              </g>
              <g>
                <path
                  d="M186.8,193.3c-0.7,3.1-1.2,5.4-1.8,8c-0.5,2.2-0.7,3.9,0,4.4c0.7,0.5,2.3-0.5,3.8-2.8c1.4-2.2,2.6-5.8,2.3-9.3
				c-0.2-3.6-1-6-1.8-8.2c-0.9-2.2-1.9-4.4-3.8-7.3c1.5,3.1,1.8,5.5,2,7.7C187.6,188,187.5,190.3,186.8,193.3z"
                />
              </g>
              <g>
                <path
                  d="M166,263.2c-0.7,3.5-2,6.9-3.1,9.4c-1.1,2.5-2,4.4-1.3,5c0.6,0.6,2.7-0.2,4.9-2.8c1.1-1.3,2.1-2.9,2.8-4.9
				c0.7-1.9,1.2-4.2,1.1-6.4c0-2.3-0.4-4.1-0.9-5.8c-0.5-1.6-1-3-1.7-4.3c-1.4-2.6-3-5.2-6.2-8.1c2.8,3.3,3.8,6,4.4,8.7
				c0.3,1.3,0.4,2.7,0.5,4.2C166.5,259.8,166.4,261.4,166,263.2z"
                />
              </g>
              <g>
                <path
                  d="M249.5,317.1c1.2-0.7,2.4-1.2,3.5-1.4c0.6-0.1,1.1-0.1,1.6-0.1c0.5,0,1,0.2,1.5,0.3c2,0.7,4,2.6,5.2,4.8
				c0.3,0.5,0.5,1.1,0.8,1.7c0.2,0.6,0.4,1.2,0.5,1.8c0.2,1.2,0.3,2.3,0.2,3.3c-0.2,1.9-1.3,3.4-2.5,4.1c-0.1,0.1-0.3,0.2-0.4,0.2
				c-0.1,0.1-0.3,0.1-0.4,0.2c-0.3,0.1-0.6,0.1-0.9,0.2c-0.7,0-1.3-0.1-1.8-0.4c-0.5-0.2-0.9-0.6-1.2-0.9c-0.3-0.4-0.5-0.9-0.5-1.4
				c-0.1-1.1,0.1-2,0.4-2.8c0.3-0.7,0.6-1.3,0.4-1.7c-0.1-0.4-0.8-0.7-2-0.3c-1.1,0.4-2.7,1.6-3.5,3.8c-0.4,1.1-0.6,2.5-0.3,4.1
				c0.3,1.6,1.1,3.2,2.4,4.5c1.3,1.3,3,2.2,4.9,2.7c1,0.2,2,0.3,3.1,0.3c0.5,0,1.1-0.1,1.7-0.2c0.5-0.1,1.1-0.3,1.6-0.4
				c4.2-1.5,7.4-5.3,8.5-10c0.5-2.3,0.6-4.6,0.2-6.8c-0.2-1.1-0.4-2.2-0.8-3.3c-0.4-1.1-0.8-2.2-1.4-3.2c-1.1-2.1-2.5-4-4.4-5.6
				c-1.8-1.6-4.1-3-6.7-3.8c-2.6-0.8-5.5-0.8-8.1-0.1c-2.6,0.7-4.8,2-6.7,3.5c-3.8,3.3-5.9,7.3-7.1,11c-1.1,3.8-1.4,7.4-1.1,10.7
				c0.2,3.3,1,6.4,1.9,9.1c1,2.8,2.1,5.4,3.5,7.8c1.3,2.5,2.7,4.9,4.3,7.3c1.5,2.4,3.1,4.8,4.9,7.4c3.6,5,7.8,10.5,13.5,16.3
				c-5.3-6.2-9-11.9-12.1-17.2c-1.5-2.7-2.8-5.2-4-7.8c-1.2-2.5-2.3-5-3.2-7.5c-0.9-2.5-1.7-5-2.2-7.6c-0.5-2.6-0.7-5.2-0.6-7.9
				c0.2-2.7,0.8-5.5,2-8.1c0.6-1.3,1.3-2.5,2.2-3.7C247.3,318.8,248.3,317.8,249.5,317.1z"
                />
              </g>
              <g>
                <path
                  d="M264.6,361c0.7-2.6,1-4.8,0.9-6.9c-0.1-2.1-0.6-4.1-2-5.2c-0.7-0.5-1.7-0.6-2.7-0.3c-1,0.3-2.1,1.1-3.1,2.3
				c-1,1.2-1.7,2.8-2.1,4.7c-0.4,1.9-0.3,3.9,0.3,5.9c0.6,2,1.4,3.6,2.2,4.9c0.8,1.3,1.7,2.3,2.6,3.3c1.8,1.9,3.7,3.6,6.7,5.5
				c-2.3-2.7-3-5-3.3-7.2C263.8,365.9,263.9,363.7,264.6,361z"
                />
              </g>
              <g>
                <path
                  d="M284.7,370c-3.2,6-5.2,12.1-7.6,17c-1.2,2.5-2.4,4.7-3.7,6.6c-0.7,0.9-1.4,1.8-2.1,2.6c-0.7,0.8-1.4,1.4-2.1,2
				c-1.4,1.1-2.5,1.5-3.5,1.7c-0.3,0.1-0.8,0-1.2-0.1c-0.5-0.2-0.9-0.4-1.2-0.7c-0.7-0.5-1.1-1.1-1.5-1.7c-0.3-0.6-0.5-1.1-0.9-1.3
				c-0.2-0.1-0.4-0.1-0.7,0c-0.3,0.1-0.6,0.4-0.9,0.8c-0.6,0.9-1,2.7-0.3,4.6c0.3,1,0.9,1.9,1.8,2.8c0.9,0.9,2.1,1.8,3.7,2.2
				c1.4,0.4,3,0.5,4.6,0.3c1.6-0.2,3.1-0.7,4.5-1.4c1.4-0.7,2.7-1.6,3.9-2.6c1.2-1,2.3-2.1,3.4-3.3c2.1-2.4,3.9-5.2,5.3-8.2
				c2.9-5.9,4.6-12.1,6.6-17.7c1.1-2.9,2.2-5.3,3.6-7.5c1.4-2.2,2.8-4.2,4.4-5.9c0.7-0.9,1.6-1.7,2.4-2.5c0.9-0.7,1.7-1.5,2.5-2.2
				c0.4-0.3,0.9-0.7,1.3-1c0.4-0.3,0.9-0.6,1.3-0.9c0.9-0.6,1.8-1.2,2.8-1.7c3.8-2.2,8-3.8,13-4.7c1.2-0.2,2.5-0.4,3.9-0.4
				c1.3-0.1,2.7-0.1,4.1,0.1c2.8,0.3,5.9,1.2,8.4,3.6c-2.3-2.6-5.4-3.8-8.3-4.4c-1.5-0.3-2.9-0.5-4.3-0.6c-1.4-0.1-2.7,0-4.1,0
				c-5.2,0.3-9.9,1.3-14.4,3c-1.1,0.4-2.2,0.9-3.3,1.4c-0.5,0.3-1.1,0.5-1.6,0.8c-0.5,0.3-1.1,0.6-1.6,0.9c-1.1,0.6-2.1,1.3-3.2,2
				c-1,0.8-2.1,1.5-3.2,2.3c-2.1,1.7-4.2,3.7-6.2,6C288.2,364.1,286.3,366.9,284.7,370z"
                />
              </g>
              <g>
                <path
                  d="M311.3,353.5c-2.3,1-4.3,2.4-5.9,4.2c-1.6,1.7-2.9,3.8-3.4,5.9c-0.6,2.1-0.3,4.2,0.4,5.5c0.7,1.3,1.7,1.7,2.5,1.5
				c0.9-0.2,1.6-0.8,2.3-1.4c0.7-0.6,1.5-1.2,2.3-1.9c0.9-0.7,1.8-1.6,3-2.7c1.2-1.1,2.4-2.4,3.6-3.7c2.6-2.8,4.9-4.5,7.5-5.8
				c1.3-0.6,2.7-1.3,4.3-1.7c1.6-0.4,3.5-0.7,5.7-0.7c-2.2-0.6-4.1-0.9-5.9-1.1c-1.8-0.2-3.4-0.3-5-0.3c-1.6,0-3.3,0.1-5.1,0.4
				C315.7,352,313.7,352.5,311.3,353.5z"
                />
              </g>
              <g>
                <path
                  d="M333.5,332.9c0.1-2-0.4-3.5-1.3-5c-0.9-1.4-2.2-2.8-3.9-3.1c-0.9-0.2-1.7,0-2.5,0.5c-0.8,0.5-1.5,1.4-1.9,2.6
				c-0.4,1.2-0.6,2.6-0.4,4.2c0.3,1.5,0.9,3.1,2,4.3c2.2,2.6,4.3,3.7,6.2,4.6c1.9,0.9,3.9,1.5,6.5,2.1c-2.2-1.6-3.2-3.2-3.8-4.8
				C333.7,336.7,333.4,335,333.5,332.9z"
                />
              </g>
              <g>
                <path
                  d="M523.9,354.8c0.8-4,0.7-8.3-0.4-12.2c-1-3.9-2.9-7.6-5.4-10.8c-2.5-3.2-5.7-5.8-9.2-7.6c-3.5-1.8-7.3-3-11-3.4
				c-3.8-0.4-7.6-0.2-11.2,0.8c-3.6,0.9-7.2,2.6-10.1,5.5c-1.4,1.4-2.6,3.2-3.4,5c-0.8,1.8-1.3,3.7-1.4,5.5
				c-0.3,3.7,0.5,7.3,2.3,10.3c1.8,3,4.4,5.2,7.1,6.5c2.7,1.3,5.5,1.8,8.1,1.8c2.6-0.1,5-0.8,7-1.8c2-1,3.5-2.4,4.6-3.7
				c2.3-2.7,3.1-5.3,3.4-7.1c0.2-1.8-0.1-2.8-0.5-2.8c-0.5-0.1-1,0.7-1.9,1.9c-0.9,1.2-2.2,3-4.3,4.5c-1,0.7-2.3,1.4-3.7,1.8
				c-1.4,0.4-2.9,0.6-4.5,0.4c-1.6-0.2-3.2-0.7-4.7-1.7c-1.5-0.9-2.7-2.2-3.4-3.7c-0.7-1.5-1-3.5-0.7-5.3c0.1-0.9,0.4-1.8,0.8-2.6
				c0.4-0.8,0.9-1.4,1.6-2.1c2.8-2.6,8.4-3.9,13.5-3.2c5.3,0.6,10.4,3.1,13.9,7.3c3.5,4.2,5.4,9.9,4.7,15.4
				c-0.8,5.7-4.1,10.7-8.1,14.1c-4.1,3.4-9,5.5-13.8,6.4c-4.9,1-9.8,1-14.5,0.4c-4.7-0.6-9.3-1.9-13.8-3.5
				c-4.5-1.6-8.9-3.6-13.5-5.8c-4.5-2.2-9.2-4.6-14.3-6.8c-5.1-2.2-10.8-4.1-17.1-4.8c-3.1-0.3-6.4-0.3-9.8,0.1
				c-1.7,0.3-3.4,0.7-5,1.2c-1.7,0.6-3.3,1.3-4.9,2.2c1.6-0.8,3.3-1.5,5-2c1.7-0.5,3.4-0.8,5-1c3.3-0.3,6.6-0.2,9.6,0.3
				c6.2,0.9,11.5,3.2,16.4,5.6c4.9,2.4,9.4,5.1,13.8,7.7c4.4,2.5,8.8,4.9,13.3,7c4.6,2,9.3,3.8,14.4,4.8c5.1,1.1,10.6,1.5,16.4,0.8
				c5.7-0.7,11.9-2.6,17.5-6.7c2.7-2,5.3-4.5,7.5-7.6C521.4,362.6,523.1,358.9,523.9,354.8z"
                />
              </g>
              <g>
                <path
                  d="M447.8,308.1c-3.2-3.2-7.6-5.5-12.3-6.1c-4.7-0.7-9.8,0.3-13.9,3.4c-2,1.5-3.5,3.4-4.6,5.6c-1.1,2.1-1.7,4.5-1.7,7
				c0,0.6,0.1,1.2,0.1,1.8c0.1,0.6,0.2,1.2,0.4,1.8c0.3,1.2,0.9,2.2,1.4,3.2c1.2,1.9,2.7,3.4,4.5,4.5c1.8,1.1,3.7,1.7,5.7,1.8
				c1.9,0.1,3.9-0.4,5.4-1.3c1.5-0.9,2.6-2,3.3-3.1c0.8-1.1,1.2-2.3,1.5-3.3c0.5-2.1,0-3.9-0.6-4.9c-0.3-0.5-0.6-0.8-0.9-1
				c-0.3-0.2-0.5-0.2-0.7-0.1c-0.2,0.1-0.4,0.3-0.5,0.6c-0.1,0.3-0.2,0.6-0.4,1c-0.3,0.8-0.6,1.7-1.4,2.6c-0.7,0.9-1.9,1.8-3.1,2
				c-1.1,0.2-2.3-0.1-3.5-1.2c-0.6-0.5-1.1-1.2-1.4-2c-0.1-0.4-0.3-0.7-0.3-1.1c0-0.2,0-0.3,0-0.5c0-0.2,0-0.4,0-0.6
				c0.1-1.6,1.2-3.6,2.9-4.8c1.6-1.2,4-1.9,6.7-1.6c2.7,0.2,5.3,1.3,7.7,3.1c2.4,1.8,4.2,4.3,5.3,6.8c1.1,2.5,1.5,5.1,1.3,7.6
				c-0.3,5-2.7,9.6-6.2,12.9c-3.5,3.3-8.6,5.3-14.4,5.7c-5.9,0.5-12.6-0.9-20.1-3.5c7.3,3.1,13.9,5,20.1,5.2
				c6.2,0.2,12-1.1,16.8-4.5c2.4-1.7,4.5-3.8,6.2-6.3c1.7-2.5,3.1-5.4,3.7-8.8C456.1,323.3,454.5,314.8,447.8,308.1z"
                />
              </g>
              <g>
                <path
                  d="M458.9,328.3c-2.4,2.5-3.5,5.9-3.3,8.5c0.2,2.7,1.6,4.5,3.3,4.7c1.8,0.2,3.4-0.9,4.8-2.5c1.3-1.6,2.3-3.7,2.8-6.3
				c0.5-2.7,1-4.7,1.8-6.7c0.8-1.9,1.8-3.8,4.1-6.1c-2.9,1.3-4.9,2.4-6.9,3.5C463.4,324.6,461.4,325.8,458.9,328.3z"
                />
              </g>
              <g>
                <path
                  d="M463.1,362.7c0.5,3.2,2.7,5.9,5,6.9c2.3,1.1,4.4,0.7,5.5-0.7c1-1.4,1-3.4,0.6-5.1c-0.5-1.8-1.4-3.3-3.1-4.8
				c-1.7-1.6-3-3-3.9-4.5c-1-1.6-1.8-3.4-1.9-6.2c-0.9,2.7-1.4,4.7-1.9,6.8C462.9,357.2,462.6,359.4,463.1,362.7z"
                />
              </g>
              <g>
                <path
                  d="M490.6,369c1.7,0.6,3.5,0.7,5.2,0.5c1.7-0.3,3.1-0.9,4.2-1.7c2.3-1.7,2.9-3.9,2-5.4c-0.9-1.5-2.7-2.2-4.6-2.6
				c-1.9-0.3-3.8-0.2-6.1,0.5c-2.3,0.7-4.3,0.9-6.3,0.8c-2-0.2-4-0.7-6.5-2.5c1.7,2.6,3.2,4.3,4.9,5.9
				C485.2,366,487.1,367.7,490.6,369z"
                />
              </g>
              <g>
                <path
                  d="M448.5,349.2c-1.6-0.6-2.7-0.5-4,0.1c-1.3,0.6-2.7,1.6-3.5,3.2c-0.4,0.8-0.5,1.7-0.2,2.5c0.2,0.9,0.8,1.7,1.7,2.4
				c0.9,0.7,2.1,1.2,3.5,1.3c1.3,0.1,2.8-0.1,4.1-0.8c2.7-1.5,3.9-3.1,5-4.5c1.1-1.5,1.9-3.1,2.9-5.2c-1.8,1.5-3.4,1.9-4.9,1.9
				C451.6,350.1,450.2,349.9,448.5,349.2z"
                />
              </g>
              <g>
                <path
                  d="M441,389.9c-1.2,1.3-3,2.2-4.8,2.4c-1.7,0.3-3.6,0-5.4-0.8c-1.8-0.7-3.5-2-4.7-3.3c-1.2-1.3-1.9-2.6-2-3.5
				c-0.2-1,0.1-2.1,0.4-2.7c0.3-0.6,0.9-1,1.7-1.1c0.8-0.1,1.5,0,2.1,0.2c0.6,0.2,1.1,0.4,1.5,0.3c0.4-0.1,0.8-0.6,0.6-1.7
				c-0.2-1-1-2.4-2.7-3.4c-0.8-0.5-1.9-0.8-3.2-0.9c-1.3-0.1-2.7,0.3-4,1.1c-2.7,1.6-4.5,4.5-4.8,8c-0.3,3.6,1.1,7,3.1,9.7
				c2,2.7,4.7,4.9,8,6.4c3.3,1.5,7.3,2.2,11.4,1.2c3.9-0.9,7.6-3.3,9.9-6.8c0.6-0.9,1.1-1.9,1.4-2.9c0.4-1,0.6-2,0.7-2.9
				c0.3-1.9,0.2-3.7-0.1-5.3c-0.6-3.2-1.9-5.9-3.3-8.1c-1.5-2.2-3.2-4.1-5-5.6c-1.8-1.5-3.7-2.8-5.7-3.8c-4-2.1-8.4-3.3-13.2-3.8
				c-4.9-0.5-10.3-0.3-16.5,0.5c12.6-0.7,21.4,1.7,27.8,6.5c3.2,2.4,5.8,5.4,7.4,9.2c0.8,1.9,1.3,3.9,1.3,5.9
				C442.8,386.8,442.3,388.7,441,389.9z"
                />
              </g>
              <g>
                <path
                  d="M457.3,391.4c0,2.2,1.1,3.9,2.5,4.7c1.5,0.9,3.3,0.9,4.8,0c1.5-0.9,2.4-2.4,2.6-4c0.2-1.5-0.4-3.1-1.9-4.3
				c-1.5-1.2-2.7-2.1-3.9-3c-1.1-0.9-2.1-2-3.1-3.7c0,2-0.2,3.5-0.4,5C457.7,387.6,457.4,389.2,457.3,391.4z"
                />
              </g>
              <g>
                <path
                  d="M431.7,410.6c-2.8,1.3-4.6,3.9-5.2,6.2c-0.6,2.3,0.1,4.3,1.6,5.2c1.5,0.9,3.5,0.6,5.2-0.3c1.7-0.9,3.3-2.4,4.4-4.7
				c1.1-2.3,2-4,3.1-5.6c1.1-1.6,2.4-3.1,4.8-4.7c-2.7,0.6-4.7,1-6.8,1.5C436.6,408.7,434.5,409.2,431.7,410.6z"
                />
              </g>
              <g>
                <path
                  d="M389.6,226.7c1.7,0.3,3.4,0.1,5-0.1c1.5-0.3,2.9-0.8,4-1.3c2.2-1.1,3.4-2.3,3.2-3.1c-0.3-0.9-2-1.1-4.3-0.9
				c-2.3,0.2-5.1,0.7-7.8,1.1c-2.8,0.4-4.9,0.2-6.8-0.5c-1-0.3-1.9-0.8-2.9-1.6c-0.9-0.7-1.9-1.8-2.6-3.3c0.4,1.6,1.1,2.9,1.9,3.9
				c0.8,1,1.7,1.9,2.6,2.6c0.9,0.7,2,1.4,3.2,1.9C386.4,226,387.9,226.5,389.6,226.7z"
                />
              </g>
              <g>
                <path
                  d="M392.5,238.2c1.9,2.5,3.7,4.8,5.3,6.6c1.6,1.7,2.9,2.9,3.7,2.5c0.8-0.3,0.8-2.2-0.1-4.7c-1-2.4-3-5.4-5.8-7.5
				c-2.9-2.2-5-3.2-7.2-4.2c-2.2-1-4.4-1.9-7.8-2.8c3.1,1.5,5,2.9,6.7,4.4C389.1,234,390.6,235.6,392.5,238.2z"
                />
              </g>
              <g>
                <path
                  d="M351.7,217.7c-0.7,2.4-1.3,4.7-1.7,6.6c-0.4,1.9-0.5,3.4,0.2,3.9c0.7,0.5,2.2-0.2,3.6-2.1c1.4-1.8,2.4-4.8,2.3-7.9
				c-0.2-3.1-0.8-5.1-1.4-7.1c-0.7-1.9-1.5-3.8-3.1-6.3c1,2.7,1.2,4.7,1.2,6.6C352.7,213.3,352.5,215.2,351.7,217.7z"
                />
              </g>
              <g>
                <path
                  d="M446.3,284.3c3.7-0.6,7.2-2.4,9.4-4.3c2.2-1.9,3-3.7,2.5-4.4c-0.6-0.7-2.4-0.1-4.7,0.8c-2.3,1-5.3,2.3-8.3,3.6
				c-3.2,1.4-5.5,2-7.9,2.5c-2.4,0.4-5,0.7-8.7,0.1c3.6,1.1,6.2,1.5,8.8,1.8C439.8,284.8,442.4,284.9,446.3,284.3z"
                />
              </g>
              <g>
                <path
                  d="M455.1,296.1c2.5,0.2,5-0.7,6.5-1.9c1.5-1.2,2-2.6,1.4-3.3c-0.6-0.7-1.8-0.7-3.3-0.4c-1.5,0.2-3.2,0.7-5.1,1.3
				c-1.9,0.6-3.4,0.9-4.9,0.9c-1.5,0-3.1-0.1-5.3-1.1c1.9,1.4,3.3,2.3,4.9,3C450.8,295.2,452.5,295.8,455.1,296.1z"
                />
              </g>
              <g>
                <path
                  d="M512.7,278.9c-0.8,1.7-2.2,3.2-3.7,4.4c-1.5,1.2-3.3,2.3-4.8,3.4c-1.5,1.1-2.8,2.1-3.5,3c-0.8,0.9-1,1.6-0.8,2
				c0.3,0.4,1,0.3,2,0.1c1.1-0.2,2.5-0.7,4.2-1.4c1.7-0.7,3.8-1.6,5.9-3.2c2-1.6,4-3.9,4.8-6.9c0.4-1.5,0.4-3,0.2-4.4
				c-0.3-1.3-0.8-2.5-1.4-3.4c-1.2-1.9-2.8-3.1-4.4-4c-3.3-1.8-7-2.2-12.2-2.6c2.6,0.5,4.7,0.9,6.5,1.6c1.8,0.7,3.3,1.5,4.5,2.6
				c1.2,1.1,2.2,2.4,2.8,3.9C513.5,275.5,513.5,277.2,512.7,278.9z"
                />
              </g>
              <g>
                <path
                  d="M421,118.9c2.1-3.8,2.7-8.3,2.3-11.5c-0.4-3.3-1.6-5.2-2.4-5c-0.9,0.2-1.3,2.2-1.8,4.9c-0.5,2.8-1.2,6.3-2.2,9.9
				c-1.1,3.7-2.3,6.2-3.8,8.5c-1.5,2.3-3.4,4.6-6.9,6.9c3.8-1.9,6.2-3.7,8.4-5.7C416.7,125,418.8,122.8,421,118.9z"
                />
              </g>
              <g>
                <path
                  d="M423,162.8c5.4,0.3,10.9,0.4,15,0.3c4.1-0.1,6.9-0.6,7-1.5c0.1-0.9-2.6-2-6.7-2.7c-4.2-0.7-9.8-1.1-15.4-0.5
				c-5.7,0.6-9.6,1.3-13.5,2.1c-3.9,0.8-7.8,1.6-13.5,3c5.8-0.8,9.8-1,13.7-1.1C413.6,162.4,417.4,162.5,423,162.8z"
                />
              </g>
              <g>
                <path
                  d="M428.4,172.9c6.4,1.8,13.2,1.4,13.5-0.3c0.2-1.7-6.2-4-12.9-4c-6.9,0-9.5,0.3-16.5-0.1
				C419.4,169.9,421.8,171,428.4,172.9z"
                />
              </g>
              <g>
                <path
                  d="M465.2,185.4c3.3-1.4,6.4-3,8.7-4.4c2.3-1.4,3.8-2.7,3.5-3.5c-0.3-0.8-2.3-1-5.1-0.3c-2.8,0.7-6.3,2.2-9.3,4.5
				c-3.1,2.4-5,4.3-6.8,6.1c-1.8,1.9-3.7,3.9-6.2,6.8c2.9-2.6,5.1-4,7.4-5.3C459.5,188,461.8,186.9,465.2,185.4z"
                />
              </g>
              <g>
                <path
                  d="M479.4,189.5c3.5-0.2,7-0.5,9.6-1c2.6-0.4,4.5-1,4.6-1.9c0.1-0.9-1.7-1.8-4.6-2.3c-2.8-0.5-6.7-0.3-10.4,0.8
				c-3.7,1.2-6.2,2.3-8.5,3.5c-2.4,1.2-4.7,2.5-8,4.6c3.5-1.6,6.1-2.3,8.6-2.8C473.3,190.1,475.8,189.7,479.4,189.5z"
                />
              </g>
              <g>
                <path
                  d="M496,177.4c5.3-1.4,9.4-5.2,8.5-6.8c-0.9-1.5-6-0.2-10.3,2.8c-2.2,1.5-3.8,2.6-5.3,3.7c-1.6,1-3.2,2-5.8,3.2
				c2.7-0.7,4.6-1.1,6.5-1.5C491.4,178.5,493.3,178.1,496,177.4z"
                />
              </g>
              <g>
                <path
                  d="M508.6,181.3c2.3,0.5,4.6,0.6,6.3,0.4c1.8-0.2,3.1-0.7,3.3-1.5c0.2-0.9-0.9-1.9-2.7-2.6c-1.8-0.7-4.4-1.1-6.9-0.6
				c-2.6,0.4-4.3,1-6,1.5c-1.8,0.6-3.6,1.2-5.8,2c2.6-0.3,4.3-0.3,6-0.1C504.5,180.5,506.2,180.8,508.6,181.3z"
                />
              </g>
              <g>
                <path
                  d="M515.5,191.4c5.5,1.8,11.5,1.5,11.8-0.3c0.3-1.7-5.2-4-11-4.1c-6-0.1-8.3,0.3-14.4-0.2
				C507.8,188.4,509.8,189.5,515.5,191.4z"
                />
              </g>
              <g>
                <path
                  d="M537.8,184.7c2.8,0.1,5.6-0.6,7.4-1.6c1.9-1,2.9-2.2,2.6-3c-0.3-0.8-1.8-1.1-3.7-1c-2,0.1-4.4,0.5-6.9,1.3
				c-2.5,0.8-4.3,1.3-6.2,1.7c-1.9,0.4-3.8,0.6-6.6,0.6c2.8,0.6,4.7,1,6.6,1.3C533,184.3,535,184.6,537.8,184.7z"
                />
              </g>
              <g>
                <path
                  d="M537.5,176.3c2.8-0.1,5.5-1.1,7.4-2.2c1.8-1.1,2.8-2.4,2.4-3.2c-0.4-0.8-1.9-1-3.8-0.7c-2,0.2-4.4,0.9-6.9,1.9
				c-2.5,1-4.3,1.6-6.1,2.2c-1.8,0.5-3.7,1-6.6,1.2c2.9,0.3,4.8,0.5,6.8,0.7C532.6,176.2,534.6,176.4,537.5,176.3z"
                />
              </g>
              <g>
                <path
                  d="M535.8,166.5c2.7-0.6,5.2-1.8,6.8-3c1.7-1.3,2.5-2.6,2.1-3.4c-0.8-1.6-6.1-0.4-10.6,2.3c-2.3,1.4-4,2.4-5.6,3.4
				c-1.7,0.9-3.4,1.8-6.1,2.9c2.8-0.5,4.7-0.8,6.7-1C531.1,167.3,533,167.1,535.8,166.5z"
                />
              </g>
              <g>
                <path
                  d="M557.3,160.8c1.9-0.2,3.7-0.8,5-1.5c1.3-0.7,2.2-1.5,2.2-2.4c-0.1-0.9-1.2-1.6-2.9-1.7c-1.7-0.2-3.9,0.3-5.8,1.5
				c-1.9,1.2-3.1,2.3-4.2,3.4c-1.1,1.1-2.2,2.1-3.7,3.7c1.8-1.2,3.2-1.7,4.6-2.1C553.9,161.3,555.3,161,557.3,160.8z"
                />
              </g>
              <g>
                <path
                  d="M366.2,478.6c3.4-1,6.8-2.1,9.3-3.2c2.5-1.1,4.2-2.1,4-3c-0.1-0.9-2.1-1.3-5-1.1c-2.9,0.3-6.7,1.3-10,3.2
				c-3.4,2-5.6,3.6-7.7,5.3c-2.1,1.7-4.2,3.4-7,6.1c3.2-2.3,5.6-3.5,8-4.5C360.2,480.5,362.6,479.6,366.2,478.6z"
                />
              </g>
              <g>
                <path
                  d="M377.5,483.6c2.3,0.1,4.5-0.2,6.2-0.7c1.7-0.5,2.9-1.2,2.9-2.1c0-0.9-1.2-1.7-3.1-2.1c-1.9-0.4-4.4-0.3-6.8,0.6
				c-2.4,0.9-4,1.7-5.6,2.5c-1.7,0.9-3.3,1.8-5.3,2.9c2.4-0.7,4.2-1,5.9-1.1C373.5,483.5,375.1,483.5,377.5,483.6z"
                />
              </g>
              <g>
                <path
                  d="M400.9,489.4c2.5,0.8,5,1.3,7,1.3c2,0.1,3.5-0.3,3.7-1.1c0.3-0.8-0.8-2-2.7-2.9c-1.9-0.9-4.6-1.6-7.4-1.6
				c-2.9,0-4.8,0.3-6.8,0.5c-2.1,0.2-4.1,0.5-6.7,0.8c2.8,0.2,4.7,0.6,6.6,1.1C396.6,487.9,398.4,488.5,400.9,489.4z"
                />
              </g>
              <g>
                <path
                  d="M404,480.2c5.8,1.9,12.1,1.7,12.4,0c0.3-1.7-5.5-4.1-11.6-4.3c-6.3-0.2-8.7,0.1-15.2-0.5
				C395.8,477.1,398,478.2,404,480.2z"
                />
              </g>
              <g>
                <path
                  d="M405.2,471.3c1.9,0,3.8-0.8,4.9-1.8c1.1-1,1.6-2.1,1.2-2.9c-0.4-0.8-1.5-1.1-2.8-1c-1.3,0.1-2.9,0.5-4.3,1.4
				c-1.5,0.9-2.6,1.5-3.8,2c-1.2,0.5-2.4,0.9-4.3,1.2c1.7,0.3,3,0.5,4.5,0.7C401.8,471.2,403.2,471.4,405.2,471.3z"
                />
              </g>
              <g>
                <path
                  d="M326.4,598.7c3.6-0.2,7.2-0.8,10.9-1.8c3.6-1,7.3-2.7,10.5-5.2c1.6-1.3,3.1-2.8,4.3-4.4c1.2-1.7,2.2-3.5,3-5.4
				c1.5-3.8,2.1-7.8,1.7-11.8c-0.2-2-0.7-3.9-1.4-5.8c-0.7-1.9-1.7-3.6-2.8-5.1c-2.2-3.1-5.1-5.5-8.2-7.3c-1.6-0.8-3.2-1.5-4.9-2
				c-1.7-0.5-3.4-0.7-5.1-0.8c-3.4-0.1-6.7,0.5-9.7,1.8c-2.9,1.4-5.5,3.4-7.2,6.1c-1.7,2.6-2.4,5.5-2.4,8.1c0,2.7,0.8,5.3,2.2,7.2
				c0.7,1,1.5,1.8,2.4,2.5c0.9,0.6,1.8,1.1,2.7,1.4c3.6,1.3,6.6,0.5,8.1-0.6c1.6-1.1,1.9-2.1,1.6-2.4c-0.3-0.4-1.2-0.1-2.5,0
				c-0.7,0-1.5,0-2.4-0.2c-0.9-0.2-1.9-0.6-2.8-1.2c-0.9-0.6-1.7-1.5-2.1-2.5c-0.5-1.1-0.6-2.4-0.4-3.8c0.2-1.4,0.8-2.9,1.8-4.1
				c0.9-1.1,2.4-2.1,4.1-2.7c1.7-0.6,3.8-0.8,5.8-0.6c1,0.1,2,0.3,3,0.7c1,0.4,2,0.8,2.9,1.4c1.9,1.2,3.6,2.7,4.9,4.6
				c1.3,1.9,2,4,2.3,6.3c0.2,2.3-0.1,4.8-1,7.1c-0.4,1.1-1,2.2-1.7,3.2c-0.7,1-1.5,1.8-2.5,2.7c-3.9,3.3-9.9,5-16.1,5.8
				c-6.3,0.8-12.3,0.4-17.8-0.8c-5.5-1.2-10.3-3.2-14.8-5.6c-9-4.9-16.6-10.8-25.2-15.5c-8.6-4.7-18.1-7.9-29-9.2
				c-5.4-0.6-11.2-0.8-17.4-0.2c-6.1,0.6-12.7,2-19.4,4.5c6.8-2.2,13.3-3.3,19.4-3.6c6.1-0.3,11.8,0.2,17.1,1.1
				c10.5,1.9,19.4,5.7,27.3,10.8c3.9,2.6,7.7,5.5,11.5,8.5c3.9,3,7.9,6.1,12.5,9.1c4.6,3,9.9,5.7,16,7.5
				C312.1,598.2,319,599.2,326.4,598.7z"
                />
              </g>
              <g>
                <path
                  d="M279.9,561.6c4.2-1.3,8.3-3.6,11.6-6.8c1.7-1.6,3.1-3.5,4.3-5.6c1.2-2.1,2-4.4,2.3-7c0.7-5.2-1.5-10.8-6.2-13.8
				c-2.4-1.4-4.9-1.9-7.1-1.8c-2.2,0.1-4.1,0.5-6,1.3c-1.8,0.8-3.5,2-4.9,3.6c-1.3,1.6-2.1,3.7-2.1,5.7c0,3.9,2.4,6.6,4.7,7.6
				c2.3,1,4.2,0.5,5.3-0.1c1.1-0.6,1.3-1.3,1.2-1.6c-0.2-0.4-0.8-0.6-1.5-0.9c-0.7-0.3-1.7-0.8-2.4-1.6c-0.3-0.4-0.5-0.8-0.6-1.4
				c-0.1-0.5-0.1-1.1,0.1-1.5c0.2-0.4,0.5-0.7,1-1.1c0.5-0.3,1.2-0.7,2.1-0.9c0.9-0.2,2-0.3,2.9-0.2c1,0.1,1.7,0.4,2.2,0.8
				c0.5,0.4,0.9,1,1.2,1.8c0.2,0.4,0.3,0.9,0.3,1.3c0,0.4,0,0.9,0,1.5c-0.3,2.1-1.7,4.8-3.7,7c-2,2.2-4.7,4.1-7.8,5.4
				c-3.1,1.4-6.4,2.2-9.5,2.6c-3.2,0.4-6.2,0.5-9.2,0.4c-5.9-0.1-11.4-0.8-17-1.1c-5.6-0.3-11.4-0.3-17.6,0.8
				c-6.2,1.1-13,3.1-20.3,7c7.5-3.4,14.4-4.8,20.5-5.3c6.2-0.4,11.6,0.2,17,1.3c5.4,1,10.7,2.4,16.9,3.4c3.1,0.5,6.5,0.9,10.2,0.9
				C271.4,563.3,275.5,563,279.9,561.6z"
                />
              </g>
              <g>
                <path
                  d="M303.9,501.7c1.1-5.3,2.3-10.4,3.1-14.4c0.8-4.1,1.2-7.3-0.3-8.3c-1.4-1-4.6,0.8-7.4,4.9c-1.4,2.1-2.6,4.7-3.4,7.7
				c-0.8,3-1.1,6.3-0.8,9.6c0.3,3.4,0.9,6.2,1.7,8.6c0.7,2.4,1.6,4.5,2.6,6.5c1,2,2,4,3.3,6.1c1.3,2.1,2.8,4.3,4.9,6.8
				c-1.6-2.8-2.6-5.2-3.3-7.5c-0.7-2.3-1.1-4.3-1.3-6.4c-0.2-2.1-0.2-4.1-0.1-6.3C303,506.9,303.3,504.5,303.9,501.7z"
                />
              </g>
              <g>
                <path
                  d="M320.9,499.7c0.3-3.4-0.1-6.9-1.1-10c-0.9-3.1-2.4-5.8-4.1-7.9c-1.6-2.1-3.4-3.4-4.9-4.1c-1.5-0.7-2.6-0.7-3.3-0.1
				c-1.3,1.3-0.3,4.3,0.8,8.2c0.6,2,1.2,4.2,1.9,6.6c0.6,2.4,1.3,5,1.8,7.7c0.6,2.8,0.8,5.2,0.9,7.5c0.1,2.3,0,4.4-0.3,6.5
				c-0.5,4.2-1.6,8.5-4.5,14.4c3.8-5.3,6.1-9.2,8.1-13.3c1-2.1,1.9-4.2,2.7-6.7C319.9,506,320.6,503.2,320.9,499.7z"
                />
              </g>
              <g>
                <path
                  d="M312.1,497.4c0.7-3.6,0.6-7.3,0-10.1c-0.6-2.7-1.6-4.4-2.5-4.3c-0.9,0.1-1.4,1.9-1.7,4.6c-0.3,2.6-0.4,6-0.2,9.5
				c0.2,3.6,0.2,6.1,0.2,8.6c-0.1,2.5-0.3,5.1-1.2,8.8c1.3-3.6,2.2-6,3-8.5C310.5,503.6,311.3,501.1,312.1,497.4z"
                />
              </g>
              <g>
                <path
                  d="M310,493.7c0.9-3.1,1-6.3,0.6-8.7c-0.4-2.4-1.2-4-2.1-3.9c-0.9,0-1.6,1.6-2.1,3.8c-0.5,2.3-0.8,5.2-0.8,8.3
				c0,3.1,0,5.3-0.2,7.5c-0.2,2.2-0.4,4.4-1.2,7.6c1.2-2.9,2.1-5,3.1-7.2C308.2,499,309,496.9,310,493.7z"
                />
              </g>
              <g>
                <path
                  d="M302.6,488.7c1.5-2.6,2.9-5.1,3.9-7.2c1-2,1.6-3.6,1-4.3c-0.6-0.6-2.4,0-4.3,1.7c-1.9,1.8-3.9,4.8-4.7,8.1
				c-0.8,3.5-0.9,5.9-0.8,8.3c0.1,2.4,0.3,4.7,1,8.1c-0.2-3.4,0.2-5.7,0.8-7.9C300.3,493.4,301.1,491.4,302.6,488.7z"
                />
              </g>
              <g>
                <path
                  d="M305.8,559.5c1.5-1.7,2.7-3.7,3.5-5.7c0.8-2,1.3-4,1.4-5.7c0.3-3.4-0.5-5.7-1.4-5.7c-0.9,0-1.6,2-2.7,4.8
				c-1.1,2.8-2.5,6.3-4.4,9.7c-2,3.5-3.9,5.6-6.1,7.6c-2.2,1.9-4.8,3.6-9.1,4.9c4.4-0.8,7.4-1.9,10.1-3.3c1.4-0.7,2.7-1.5,4.2-2.5
				C302.7,562.6,304.2,561.3,305.8,559.5z"
                />
              </g>
              <g>
                <path
                  d="M341,541.1c4.9-0.6,9.7-1.1,13.4-1.5c3.7-0.4,6.3-0.9,6.4-1.8c0.1-0.8-2.4-2-6.4-2.4c-3.9-0.5-9.3-0.1-14.4,1.4
				c-5.2,1.6-8.5,3.3-11.7,5c-3.2,1.8-6.4,3.7-10.8,6.9c4.6-2.8,8.1-4.1,11.5-5.2C332.6,542.5,336,541.7,341,541.1z"
                />
              </g>
              <g>
                <path
                  d="M361.1,546c3.3,0.2,6.6,0,9.1-0.3c2.5-0.4,4.3-1,4.3-1.9c0.1-0.9-1.6-1.8-4.2-2.3c-2.6-0.5-6.1-0.6-9.6,0.1
				c-3.5,0.7-5.9,1.5-8.2,2.3c-2.5,0.8-4.8,1.6-8.1,2.8c3.6-0.7,6-0.8,8.5-0.9C355.2,545.7,357.6,545.8,361.1,546z"
                />
              </g>
              <g>
                <path
                  d="M368.5,552.6c2.6,0.8,5.2,1.2,7.2,1.2c2,0,3.6-0.3,3.8-1.2c0.2-0.8-0.9-2-2.8-2.9c-2-0.9-4.8-1.6-7.6-1.5
				c-2.9,0.1-4.9,0.4-6.9,0.7c-2.1,0.3-4.2,0.7-6.9,1.1c2.9,0.1,4.9,0.4,6.8,0.8C364,551.3,365.9,551.8,368.5,552.6z"
                />
              </g>
              <g>
                <path
                  d="M371.3,563.5c1.5,1.5,2.9,2.8,4,3.8c1.2,1,2.2,1.7,3.1,1.5c0.8-0.3,1.2-1.8,0.4-3.8c-0.7-2-2.7-4.1-5.1-5.1
				c-2.5-1.1-4.2-1.3-6-1.6c-1.7-0.3-3.4-0.5-5.9-1c2.3,1,3.8,1.8,5.2,2.7C368.4,561,369.8,562,371.3,563.5z"
                />
              </g>
              <g>
                <path
                  d="M370.1,580.9c0.2,2.4,0.6,4.7,1.1,6.5c0.5,1.8,1.1,3.1,2,3.2c0.9,0.1,1.8-1.2,2.2-3.3c0.4-2.1,0.2-5-1-7.6
				c-1.2-2.6-2.4-4.2-3.6-5.8c-1.2-1.5-2.5-3-4.4-5c1.5,2.3,2.2,4.1,2.7,5.8C369.5,576.6,369.8,578.4,370.1,580.9z"
                />
              </g>
              <g>
                <path
                  d="M364.6,598.8c-0.5,1.7-0.6,3.5-0.3,4.8c0.2,1.4,0.8,2.4,1.7,2.6c0.9,0.2,1.8-0.6,2.5-2c0.6-1.4,1-3.4,0.6-5.3
				c-0.4-2-0.9-3.3-1.3-4.7c-0.5-1.3-0.9-2.6-1.5-4.5c0.1,2-0.1,3.3-0.4,4.7C365.5,595.7,365.1,597,364.6,598.8z"
                />
              </g>
              <g>
                <path
                  d="M353.3,601c-3.3,3.3-5.1,7.7-3.7,8.8c1.4,1.1,5.4-1.9,7.3-6.4c1.9-4.6,2.2-6.5,4.2-11.1
				C358.2,596.4,356.7,597.5,353.3,601z"
                />
              </g>
              <g>
                <path
                  d="M340.2,602.7c-2.6,1.1-5,2.5-6.7,3.8c-1.7,1.3-2.7,2.6-2.3,3.4c0.4,0.8,2,0.9,4.2,0.3c2.2-0.6,4.8-1.9,7.1-3.8
				c2.3-2,3.8-3.5,5.2-5c1.5-1.5,2.9-3,5.1-5.1c-2.5,1.7-4.4,2.7-6.2,3.6C344.8,600.8,342.9,601.6,340.2,602.7z"
                />
              </g>
              <g>
                <path
                  d="M316.4,606.9c-2.8,0-5.6,0.4-7.6,1c-2.1,0.6-3.5,1.4-3.5,2.2c0,0.9,1.5,1.6,3.7,1.9c2.2,0.3,5.2,0.1,8.1-0.9
				c2.9-1,4.8-1.9,6.7-2.8c2-1,4-1.9,6.6-3.1c-3,0.8-5,1.1-7.1,1.3C321.3,606.8,319.3,606.9,316.4,606.9z"
                />
              </g>
              <g>
                <path
                  d="M233,569.1c5.4,0.9,10.6,2.2,14.6,3.2c4,1,6.8,1.7,7.2,0.8c0.4-0.7-1.8-2.8-5.8-4.8c-4-1.9-9.8-3.5-15.7-3.6
				c-6,0-10.1,0.7-14.1,1.6c-4,0.9-8,2.1-13.5,4.5c5.7-1.9,9.8-2.4,13.7-2.6C223.5,568,227.4,568.2,233,569.1z"
                />
              </g>
              <g>
                <path
                  d="M251.9,584.4c1.8,1.9,3.6,3.7,5.1,5.1c1.5,1.3,2.8,2.2,3.6,1.8c0.8-0.4,0.8-2.1-0.2-4.2c-1-2.1-3.1-4.6-5.8-6.1
				c-2.8-1.5-4.8-2.2-6.9-2.7c-2.1-0.5-4.1-0.9-7.1-1.1c2.9,0.8,4.7,1.8,6.4,2.9C248.6,581.1,250.1,582.4,251.9,584.4z"
                />
              </g>
              <g>
                <path
                  d="M68,396c1.6-0.2,3.2-0.3,4.9-0.5c1.6-0.1,3.3-0.2,4.8-0.2c3.1,0,6.1,0.2,8.8,0.7c2.7,0.5,5.1,1.4,6.9,2.6
				c0.9,0.6,1.7,1.2,2.3,1.9c0.3,0.3,0.6,0.7,0.9,1.1c0.2,0.4,0.4,0.7,0.6,1.1c0.7,1.6,1.1,3.6,0.9,5.5c-0.1,1-0.3,1.9-0.6,2.8
				c-0.3,0.9-0.8,1.8-1.3,2.6c-1.1,1.7-2.5,3.2-4.2,4.4c-1.7,1.2-3.4,2.1-5.2,2.7c-1.8,0.6-3.7,0.8-5.3,0.7c-1.7-0.1-3.2-0.4-4.5-1
				c-2.5-1-4.3-3.3-5.3-5.4c-1-2.1-1.2-4.1-1.3-5.6c0-0.7-0.1-1.4-0.1-1.9c-0.1-0.5-0.2-0.9-0.5-1.1c-0.3-0.2-0.7-0.2-1.2,0
				c-0.5,0.2-1.1,0.7-1.8,1.5c-0.6,0.8-1.3,1.9-1.7,3.4c-0.4,1.4-0.7,3.2-0.5,5.1c0.1,1.9,0.7,4.1,1.7,6.3c0.3,0.5,0.6,1.1,0.9,1.6
				c0.4,0.5,0.7,1.1,1.2,1.6c0.5,0.5,0.9,1.1,1.4,1.5c0.3,0.2,0.5,0.5,0.8,0.7c0.3,0.2,0.6,0.4,0.8,0.7c2.3,1.8,5.2,3,8.2,3.7
				c3.1,0.7,6.4,0.7,9.7,0.2c3.3-0.6,6.8-1.8,9.9-3.6c3.1-1.9,6.1-4.4,8.5-7.6c1.2-1.6,2.3-3.4,3.1-5.4c0.8-2,1.4-4.1,1.7-6.3
				c0.6-4.4,0.1-9-1.8-13.5c-0.5-1.1-1.1-2.2-1.7-3.2c-0.7-1-1.4-2-2.2-2.9c-0.8-0.9-1.7-1.7-2.6-2.5c-0.5-0.4-0.9-0.7-1.4-1.1
				c-0.5-0.4-1-0.7-1.5-1c-4-2.5-8.2-3.7-12.3-4.3c-4.1-0.6-8.1-0.7-12-0.3c-1.9,0.2-3.8,0.4-5.6,0.7c-1.9,0.3-3.7,0.6-5.5,1
				c-7.6,1.7-14.2,4-20.2,6.7c-6,2.7-11.3,5.7-16.1,8.9c-4.8,3.2-9.1,6.5-13,10.1c-3.9,3.5-7.6,7.2-10.9,11
				c-6.7,7.7-12.6,16.1-18,26.2c-5.4,10-10.4,21.7-14.4,36.1c4.8-14.2,10.6-25.4,16.7-34.7c6.1-9.4,12.7-16.9,19.9-23.6
				c3.6-3.3,7.4-6.3,11.4-9.2c4-2.9,8.4-5.5,13.1-7.9c4.7-2.4,9.8-4.6,15.4-6.4C55.2,398.3,61.3,396.8,68,396z"
                />
              </g>
              <g>
                <path
                  d="M53,455.6c0.5,1.9,0.5,3.8,0.3,5.6c-0.3,1.9-0.8,3.7-1.7,5.4c-0.9,1.7-2,3.1-3.3,4.3c-0.6,0.6-1.3,1.1-2,1.6
				c-0.7,0.5-1.5,0.9-2.3,1.2c-3.2,1.4-6.9,1.6-10,0.9c-1.6-0.3-2.9-0.9-3.9-1.6c-1-0.7-1.6-1.3-2.1-2.1c-0.2-0.4-0.4-0.8-0.5-1.1
				c-0.1-0.3-0.2-0.6-0.1-0.9c0-0.6,0.3-1.5,1-2.3c0.7-0.9,1.6-1.7,2.6-2.4c0.5-0.3,1-0.7,1.5-1c0.5-0.3,0.9-0.5,1.3-0.8
				c0.5-0.3,1-0.6,1.4-0.9c0.2-0.2,0.7-0.2,1.1-0.2c0.4,0.1,0.7,0.2,1,0.4c0.3,0.2,0.5,0.5,0.8,0.6c0.3,0.1,0.7,0.1,1.1-0.4
				c0.4-0.4,0.7-1.4,0.4-2.5c-0.2-1.1-0.9-2.3-2.5-3.3c-1.5-0.9-3.3-1.1-5-1c-0.8,0.1-1.6,0.2-2.4,0.3c-0.8,0.1-1.6,0.3-2.5,0.6
				c-1.7,0.5-3.6,1.3-5.5,2.6c-1.9,1.3-4,3.4-5.2,6.4c-0.6,1.5-1,3.2-1.1,4.8c-0.1,1.7,0.1,3.3,0.6,5c0.5,1.6,1.2,3.2,2.1,4.6
				c0.9,1.5,2.1,2.7,3.3,3.8c2.5,2.2,5.4,3.8,8.4,4.8c6.1,2,13.1,1.9,19.6-0.7c3.2-1.3,6.4-3.2,9-5.8c2.6-2.5,4.7-5.6,6.2-8.9
				c1.5-3.3,2.4-6.9,2.5-10.6c0.2-3.7-0.5-7.5-1.8-10.8c-2.7-7-7.7-12-13.2-14.9c-5.6-2.9-11.3-3.4-16.2-3.1
				c-4.9,0.3-9.3,1.4-13.4,2.8c-4.1,1.3-7.9,3-11.5,4.9c-3.7,1.9-7.1,4.1-10.6,6.6c-3.4,2.5-6.7,5.3-9.9,8.6
				c-3.2,3.3-6.3,6.9-9.3,11.1c-2.9,4.2-5.7,9-8,14.5c2.6-5.3,5.7-9.8,9-13.7c3.2-3.9,6.6-7.1,10-9.9c3.4-2.8,6.9-5.1,10.4-7
				c3.5-1.9,7-3.5,10.6-4.8c3.6-1.2,7.3-2.3,11.1-2.9c3.8-0.7,7.7-1.1,11.5-0.8c3.8,0.3,7.6,1.4,10.5,3.5
				C49.6,448.2,52,451.7,53,455.6z"
                />
              </g>
              <g>
                <path
                  d="M121.8,361.1c10.9,1.6,21.5,3.9,29.6,5.9c0.5,0.1,1,0.2,1.5,0.3c0.5,0.1,1,0.2,1.4,0.4c0.9,0.2,1.8,0.4,2.6,0.6
				c0.8,0.2,1.6,0.3,2.4,0.5c0.7,0.2,1.4,0.3,2,0.4c2.5,0.4,4.2,0.3,4.6-0.5c0.4-0.8-0.5-2.1-2.5-3.9c-0.5-0.4-1.1-0.9-1.7-1.4
				c-0.6-0.5-1.4-0.9-2.1-1.4c-0.8-0.5-1.6-1-2.5-1.5c-0.5-0.2-0.9-0.5-1.4-0.8c-0.5-0.2-1-0.5-1.5-0.7c-8.1-3.8-19.9-6.9-31.8-6.7
				c-3.1,0.1-5.9,0.1-8.5,0.4c-1.3,0.1-2.6,0.2-3.8,0.4c-1.2,0.2-2.4,0.4-3.5,0.5c-1.1,0.2-2.3,0.3-3.3,0.5
				c-1.1,0.2-2.1,0.4-3.2,0.7c-1,0.2-2.1,0.4-3.1,0.7c-1,0.3-2,0.5-3,0.8c-1,0.3-2,0.5-3,0.8c-1,0.3-2,0.6-3,1c-1,0.3-2,0.6-3.1,1
				c-1,0.4-2.1,0.8-3.2,1.2c-1.1,0.4-2.2,0.8-3.3,1.3c-1.1,0.5-2.3,1-3.5,1.5c-0.6,0.3-1.2,0.5-1.8,0.8c-0.6,0.3-1.2,0.6-1.9,0.9
				c-1.3,0.6-2.6,1.2-4,1.9c1.4-0.5,2.8-1,4.1-1.5c0.7-0.2,1.3-0.5,1.9-0.7c0.6-0.2,1.3-0.4,1.9-0.6c1.2-0.4,2.4-0.7,3.6-1
				c1.2-0.3,2.3-0.5,3.4-0.8c1.1-0.2,2.2-0.5,3.3-0.7c1.1-0.2,2.1-0.3,3.2-0.5c1-0.1,2-0.3,3-0.4c1-0.1,2-0.2,3-0.3
				c1-0.1,2-0.2,3-0.2c1-0.1,2-0.1,3-0.1c1,0,2.1,0,3.1-0.1c1.1,0,2.1,0.1,3.2,0.1c1.1,0,2.2,0.1,3.4,0.1c1.2,0.1,2.3,0.2,3.6,0.3
				C116.4,360.4,119,360.8,121.8,361.1z"
                />
              </g>
              <g>
                <path
                  d="M120.9,378.3c10.8,0.9,21.8-0.5,29.7-2.7c7.9-2.2,12.8-5.1,12.3-6.8c-0.4-1.8-5.9-1.9-13.7-1.5
				c-7.8,0.4-18.1,1.3-28.4,2.2c-10.6,0.9-18,1.1-25.5,0.9c-7.5-0.3-15.1-1-26.1-3.4c10.7,3.5,18.2,5.4,25.6,7.2
				C102.3,375.9,109.8,377.4,120.9,378.3z"
                />
              </g>
              <g>
                <path
                  d="M132.4,371.1c6.3,1.3,12.7,1.4,17.5,0.7c4.8-0.7,8-2.3,8-4c0-1.8-3.1-3.3-7.8-4.4c-4.7-1-10.9-1.5-17.2-1
				c-6.5,0.5-10.9,0.9-15.5,1.2c-4.5,0.3-9.1,0.4-15.8,0c6.6,1.4,11,2.6,15.4,3.8C121.5,368.5,125.9,369.7,132.4,371.1z"
                />
              </g>
              <g>
                <path
                  d="M166.3,420.2c1,0,2.1,0,3.1-0.2c1-0.2,2.1-0.4,3.1-0.7c2-0.7,4-1.7,5.8-3.3c1.8-1.5,3.3-3.7,4-6.1
				c0.7-2.4,0.7-4.8,0.3-6.9c-0.4-2.1-1.2-4-2.1-5.7c-0.9-1.7-2-3.1-3.3-4.5c-1.3-1.3-2.8-2.4-4.5-3.3c-1.6-0.8-3.6-1.3-5.3-1.4
				c-3.5-0.2-6.9,1.1-9,3.3c-1.1,1.1-1.8,2.4-2.2,3.6c-0.4,1.2-0.5,2.3-0.4,3.3c0.2,2,1.1,3.4,1.9,4c0.8,0.7,1.5,0.7,1.8,0.4
				c0.3-0.3,0.4-0.9,0.5-1.6c0.1-0.7,0.2-1.6,0.7-2.4c0.4-0.9,1.2-1.8,2.1-2.1c0.9-0.4,2.3-0.6,3.6-0.2c1.3,0.4,2.6,1.3,3.9,2.8
				c1.2,1.5,2.3,3.6,2.6,5.5c0.2,0.9,0.1,1.7-0.1,2.3c-0.2,0.6-0.5,1.2-1.1,1.8c-0.6,0.6-1.5,1.2-2.5,1.7c-0.5,0.2-1.1,0.4-1.6,0.6
				c-0.6,0.2-1.1,0.3-1.8,0.4c-2.6,0.3-5.2,0-7.6-0.7c-2.4-0.7-4.7-1.7-6.8-2.9c-4.1-2.4-7.6-5.4-11-8.5c-3.5-3.1-6.9-6.5-11.2-9.7
				c-2.2-1.6-4.5-3.1-7.3-4.5c-2.7-1.3-5.9-2.4-9.3-2.6c3.5,0.5,6.4,1.9,8.9,3.4c2.5,1.5,4.7,3.3,6.5,5.1c3.8,3.6,6.6,7.3,9.5,11
				c3,3.7,6,7.4,10.3,11c2.2,1.8,4.6,3.4,7.6,4.8C158.6,419,162.2,420.1,166.3,420.2z"
                />
              </g>
              <g>
                <path
                  d="M130.6,425.7c-0.7,1.3-1.4,2.2-2.3,3c-0.9,0.7-1.7,1.2-2.5,1.4c-0.6,0.2-1.4,0.2-2.1,0c-0.7-0.2-1.2-0.4-1.5-0.7
				c-0.3-0.1-0.6-0.7-0.7-1.3c-0.1-0.6-0.1-1.3,0-1.9c0.1-0.6,0.3-1.1,0.4-1.6c0.2-0.5,0.3-1,0.2-1.4c-0.1-0.4-0.4-0.8-1.1-1
				c-0.7-0.2-1.8-0.1-2.9,0.5c-1.2,0.6-2.4,1.7-3.2,3.4c-0.8,1.6-1.3,4-0.4,6.6c0.8,2.4,2.7,4.6,5.1,5.9c2.4,1.3,5.3,1.8,8.3,1.2
				c2.9-0.6,5.4-2.2,7.2-4.2c1.8-2,3.1-4.6,3.7-7c0.6-2.5,0.6-4.8,0.4-6.8c-0.2-2-0.6-3.8-1-5.4c-0.9-3.3-2.2-6.1-3.7-8.6
				c-1.5-2.6-3.3-4.9-5.6-7.3c-1.1-1.2-2.4-2.3-3.8-3.5c-1.4-1.1-3.1-2.2-4.9-3.2c1.7,1.2,3.1,2.6,4.3,3.8c1.2,1.3,2.2,2.6,3,3.9
				c1.7,2.6,2.7,5.1,3.5,7.7c0.7,2.6,1.1,5.2,1.1,8C132.2,419.9,131.9,423.1,130.6,425.7z"
                />
              </g>
              <g>
                <path
                  d="M612.6,399.5c-0.7-3.6-1.9-7.1-3.5-10.4c-1.6-3.3-3.6-6.4-5.8-9.1c-2.2-2.7-4.6-4.8-6.8-6.8c-2.2-1.9-4.2-3.6-5.9-5.2
				c-3.5-3.1-6-6-7.6-8.2c-1.7-2.2-2.6-3.7-3.5-3.5c-0.8,0.1-1.5,2-0.9,5.4c0.3,1.6,0.9,3.6,1.9,5.6c1,2,2.4,4.2,4,6.3
				c3.3,4.3,7.7,8.3,11.3,12.7c3.5,4.3,6.4,9.6,8.4,15.4c1,3,1.7,5.9,2.1,8.6c0.4,2.7,0.7,5.4,0.7,7.9c0,5-0.5,9.6-1.6,14.1
				c-1.1,4.5-2.6,9-4.9,13.8c-2.3,4.8-5.4,9.9-9.8,15.6c4.8-5.3,8.4-10.1,11.3-14.7c2.9-4.6,5.2-9,7-13.5c1.8-4.6,3.3-9.3,4.1-14.8
				c0.4-2.8,0.6-5.7,0.6-8.9C613.7,406.6,613.4,403.2,612.6,399.5z"
                />
              </g>
              <g>
                <path
                  d="M580.2,404.8c-0.8-5.7-1-11.4-0.9-16.8c0.1-5.4,0.4-10.6,0.8-15c0.7-8.9,0.9-15.2-0.8-15.7c-1.7-0.4-4.9,5.1-7.5,14.3
				c-1.2,4.6-2.2,10.1-2.4,16.2c-0.2,6,0.4,12.6,2.2,18.9c1.9,6.5,4.3,11.6,7.1,15.9c1.4,2.2,2.8,4.1,4.3,5.9
				c1.4,1.8,2.9,3.4,4.2,5.1c2.6,3.3,5,6.9,5.9,11.6c0.2,1.2,0.4,2.4,0.4,3.7c0,1.3,0,2.6-0.2,4c-0.4,2.7-1.3,5.6-3,8.4
				c1.9-2.7,3.1-5.5,3.8-8.2c0.4-1.4,0.6-2.7,0.7-4.1c0.1-1.3,0.1-2.6,0.1-3.9c-0.1-2.5-0.7-5-1.5-7.2c-0.8-2.2-1.8-4.3-2.9-6.2
				c-2.2-3.9-4.5-7.5-6.2-11.7C582.4,415.5,581,410.7,580.2,404.8z"
                />
              </g>
              <g>
                <path
                  d="M593.6,391.6c-1-7.1-3.4-13.9-5.9-18.7c-2.5-4.8-4.9-7.5-5.7-7.1c-0.8,0.4,0.2,3.8,1.8,8.7c1.6,4.9,3.7,11.4,5.5,17.9
				c1.8,6.8,2.7,11.6,3.3,16.5c0.5,4.9,0.8,9.9,0.1,17.3c1.2-7.3,1.6-12.4,1.8-17.4C594.6,403.9,594.6,398.9,593.6,391.6z"
                />
              </g>
              <g>
                <path
                  d="M588.3,385.7c-0.4-5.3-1.8-10.5-3.4-14.1c-1.6-3.7-3.4-5.8-4.2-5.5c-0.8,0.3-0.6,3,0.2,6.7c0.7,3.8,1.9,8.6,3.1,13.5
				c1.2,5.1,1.9,8.6,2.3,12.2c0.4,3.6,0.7,7.4,0.3,12.8c0.9-5.4,1.3-9.2,1.6-12.9C588.5,394.8,588.7,391.1,588.3,385.7z"
                />
              </g>
              <g>
                <path
                  d="M583.8,385.6c0.2-8.5-1.7-16.9-3.5-16.8c-1.8,0.1-2.4,8.7-0.9,17.1c1.6,8.6,2.6,11.8,3.8,20.8
				C583,397.7,583.5,394.4,583.8,385.6z"
                />
              </g>
              <g>
                <path
                  d="M38.3,418.2c3.6-0.5,7.1-1.2,9.7-2c2.6-0.8,4.4-1.7,4.3-2.5c-0.1-0.9-2-1.5-4.9-1.6c-2.9-0.1-6.7,0.5-10.3,1.9
				c-3.7,1.5-6,2.8-8.4,4.1c-2.3,1.3-4.7,2.7-8,4.8c3.6-1.6,6.2-2.4,8.7-3C32.1,419.3,34.6,418.8,38.3,418.2z"
                />
              </g>
              <g>
                <path
                  d="M37.7,425.6c3.2-0.1,6.2-0.5,8.6-1.1c2.3-0.5,4-1.2,4-2.1c0-0.9-1.6-1.7-4.2-2.1c-2.6-0.4-6-0.1-9.3,0.9
				c-3.3,1.1-5.5,2.1-7.6,3.2c-2.1,1.1-4.2,2.2-7.3,3.9c3.3-1.2,5.6-1.8,7.9-2.1C32.2,425.9,34.5,425.7,37.7,425.6z"
                />
              </g>
              <g>
                <path
                  d="M59.5,432.6c2.4,0.8,4.7,1.4,6.6,1.6c1.9,0.2,3.4,0.1,3.8-0.7c0.4-0.8-0.5-2.1-2.3-3.2c-1.8-1.1-4.5-2-7.3-2
				c-2.9,0-4.8,0.3-6.8,0.7c-1.9,0.4-3.8,0.8-6.5,1.5c2.8-0.2,4.7,0,6.5,0.4C55.3,431.3,57.1,431.8,59.5,432.6z"
                />
              </g>
              <g>
                <path
                  d="M80.6,446.8c4,0.5,8.1-0.5,10.7-1.9c2.7-1.4,4-3.1,3.6-3.8c-0.4-0.8-2.4-0.5-5-0.1c-2.6,0.4-5.9,1-9.4,1.5
				c-3.6,0.4-6.1,0.3-8.7,0c-2.5-0.4-5.2-1.1-8.6-3.1c3.1,2.4,5.6,3.7,8.1,4.9C73.9,445.3,76.5,446.2,80.6,446.8z"
                />
              </g>
              <g>
                <path
                  d="M84.1,455.5c2.2,0,4.3-0.8,5.6-1.8c1.3-1,1.9-2.2,1.5-3c-0.4-0.8-1.6-1-3.1-0.9c-1.5,0.1-3.2,0.6-4.9,1.4
				c-1.8,0.8-3.1,1.4-4.4,1.8c-1.4,0.4-2.8,0.8-4.9,0.8c2.1,0.5,3.5,0.8,5,1.1C80.4,455.3,81.9,455.5,84.1,455.5z"
                />
              </g>
              <g>
                <path
                  d="M-6.8,468.2c-3.8,3.9-6.3,9-7.3,13c-1,4-0.7,6.8,0.2,7c0.9,0.2,2.1-2.2,3.8-5.5c1.7-3.3,4-7.6,6.8-11.7
				c2.9-4.2,5.4-6.9,8.1-9.3c2.8-2.3,5.8-4.6,11-6.6c-5.3,1.5-8.8,3.2-12.1,5C0.3,462-2.9,464.1-6.8,468.2z"
                />
              </g>
              <g>
                <path
                  d="M-0.6,480.6c-1,1.9-1.8,3.9-2.4,5.8c-0.3,1-0.5,1.9-0.7,2.8c-0.2,0.9-0.3,1.9-0.3,2.8c0,0.9,0.1,1.8,0.4,2.6
				c0.3,0.8,0.7,1.5,1.2,1.9c0.5,0.4,1,0.6,1.4,0.6c0.4,0,0.6-0.1,0.7-0.3c0.2-0.4-0.1-1,0-1.6c0-0.6,0.1-1.5,0.5-2.7
				c0.2-0.6,0.4-1.2,0.7-2.1c0.3-0.8,0.6-1.7,0.8-2.5c0.6-1.8,1.1-3.7,1.7-5.6c1.1-4,2.1-6.7,3.2-9.3c1.2-2.7,2.5-5.3,5-8.9
				c-2.9,3.2-4.8,5.6-6.7,7.9C3.3,474.3,1.5,476.7-0.6,480.6z"
                />
              </g>
              <g>
                <path
                  d="M7.5,492.9c-0.1,2.4,0.6,4.8,1.6,6.4c1,1.6,2.2,2.3,3,2c0.8-0.4,1.1-1.7,1-3.3c-0.1-1.6-0.5-3.6-1.2-5.6
				c-0.7-2.1-1.2-3.6-1.5-5.1c-0.3-1.6-0.5-3.2-0.3-5.6c-0.7,2.3-1.2,3.9-1.6,5.5C8.1,488.7,7.7,490.4,7.5,492.9z"
                />
              </g>
              <g>
                <path
                  d="M19.5,498.4c0.8,2,2.3,3.6,3.7,4.4c1.4,0.8,2.7,0.9,3.3,0.2c0.6-0.7,0.3-1.9-0.3-3.1c-0.6-1.3-1.7-2.7-3-3.9
				c-1.4-1.3-2.4-2.3-3.2-3.3c-0.9-1.1-1.7-2.2-2.5-4.2c0.3,2.1,0.5,3.5,0.7,4.9C18.5,494.9,18.7,496.4,19.5,498.4z"
                />
              </g>
              <g>
                <path
                  d="M36.3,500.3c1.6,0.8,3.4,0.8,4.7,0.4c1.3-0.4,2-1.3,2-2.2c-0.1-0.9-0.9-1.6-1.9-2c-1.1-0.5-2.4-0.7-3.8-0.5
				c-1.5,0.2-2.6,0.2-3.7,0.2c-1.1,0-2.3-0.2-3.8-0.8c1.1,0.9,2.1,1.7,3.2,2.6C33.7,498.7,34.7,499.5,36.3,500.3z"
                />
              </g>
              <g>
                <path
                  d="M173.3,453.4c1.6-3.6,2.3-7.6,2.2-11.5c-0.1-3.9-1-7.8-2.9-11.2c-1-1.7-2.2-3.2-3.6-4.5c-1.5-1.3-3.2-2.3-5.1-2.8
				c-3.8-1.1-7.5-0.3-10.2,1.3c-2.8,1.6-4.7,4.3-5.4,6.7c-0.8,2.5-0.6,4.7-0.2,6.4c0.5,1.7,1.2,2.9,1.8,3.6c0.6,0.7,1.1,0.9,1.2,0.8
				c0.4-0.3-0.5-1.9-0.7-4.8c0-1.4,0.2-3.2,1-4.9c0.8-1.7,2.2-3.3,4.2-4.2c2-1,4.6-1.3,6.8-0.5c2.3,0.8,4.4,2.7,5.8,5.3
				c1.4,2.6,2.2,5.7,2.4,8.9c0.2,3.2-0.3,6.6-1.4,9.7c-1.2,3.2-3,6-4.8,8.5c-1.9,2.5-3.8,4.8-5.6,7.1c-0.9,1.2-1.7,2.3-2.5,3.5
				c-0.4,0.6-0.7,1.2-1.1,1.8l-0.5,0.9l-0.5,0.9c-0.3,0.6-0.6,1.2-0.9,1.8c-0.3,0.6-0.5,1.2-0.8,1.8c-0.6,1.2-0.9,2.5-1.4,3.7
				c-0.8,2.5-1.4,5.1-1.8,7.8c-0.2,1.3-0.3,2.7-0.4,4.1c0,0.7,0,1.4,0,2.1c0,0.7,0,1.4,0,2.2c0.1,2.9,0.5,6,1.3,9.3
				c0.8,3.2,2,6.6,3.8,10c-1.7-3.5-2.7-6.9-3.4-10.1c-0.6-3.2-0.9-6.3-0.8-9.2c0-0.7,0-1.4,0.1-2.1c0.1-0.7,0.1-1.4,0.2-2.1
				c0.2-1.4,0.3-2.7,0.6-4c0.5-2.6,1.3-5,2.2-7.4c1.9-4.7,4.5-9,8.3-13.1c1.9-2.1,4-4.2,6.1-6.7C169.4,460,171.7,457.1,173.3,453.4z
				"
                />
              </g>
              <g>
                <path
                  d="M154.7,445.2c-2.1,1.9-3.9,4-5.1,5.8c-1.2,1.8-1.8,3.3-1.2,4c0.6,0.7,2.2,0.3,4.2-0.9c1.9-1.2,4.1-3.4,5.6-6.1
				c0.8-1.4,1.3-2.5,1.8-3.6c0.5-1.1,0.9-2.1,1.2-3.2c0.3-1.1,0.5-2.2,0.4-3.5c-0.1-1.2-0.5-2.6-1.7-3.7c0.9,1.3,1,2.6,0.8,3.7
				c-0.2,1.1-0.7,2-1.2,2.7c-0.6,0.8-1.2,1.5-2,2.3C156.6,443.3,155.7,444.2,154.7,445.2z"
                />
              </g>
              <g>
                <path
                  d="M157.3,453.9c-1.7,1.7-3.1,3.6-4,5.2c-0.9,1.6-1.2,3-0.6,3.7c0.6,0.6,2.1,0.3,3.7-0.9c1.6-1.1,3.4-3.1,4.4-5.5
				c1.1-2.4,1.7-4.2,2.2-6c0.6-1.9,1.1-3.7,1.8-6c-1.2,2.3-2.3,3.8-3.5,5.1C160.3,450.9,159.1,452.2,157.3,453.9z"
                />
              </g>
              <g>
                <path
                  d="M187.3,434.6c0.8-2.6,0.7-5.4,0.1-7.4c-0.6-2-1.7-3.1-2.5-2.9c-0.9,0.2-1.4,1.5-1.6,3.3c-0.3,1.8-0.4,4-0.3,6.3
				c0.1,2.4,0,4.2-0.2,5.9c-0.3,1.7-0.7,3.5-1.9,5.9c1.6-2.1,2.7-3.7,3.6-5.2C185.5,438.9,186.5,437.3,187.3,434.6z"
                />
              </g>
              <g>
                <path
                  d="M196,440.1c1.4-0.9,2.5-2.1,3.2-3.2c0.7-1.1,1-2.2,0.6-3c-0.5-0.7-1.7-0.9-3.1-0.4c-1.4,0.5-3,1.8-4,3.6
				c-1,1.8-1.4,3.2-1.8,4.5c-0.4,1.3-0.7,2.6-1.2,4.5c1-1.7,1.9-2.6,2.8-3.5C193.5,441.8,194.5,441.1,196,440.1z"
                />
              </g>
              <g>
                <path
                  d="M204.1,446c1.7-0.2,3.1-0.8,4.2-1.5c1.1-0.7,1.8-1.6,1.7-2.5c-0.1-0.9-1.2-1.5-2.7-1.6c-1.5-0.1-3.4,0.4-5,1.6
				c-1.6,1.2-2.5,2.2-3.5,3.2c-1,1.1-1.9,2.2-3,3.4c1.6-1,2.8-1.5,4-1.8C201.1,446.4,202.3,446.2,204.1,446z"
                />
              </g>
              <g>
                <path
                  d="M255.9,442.9c-2.8,2-5.5,3.9-7.6,5.6c-2,1.6-3.4,3-3,3.8c0.3,0.8,2.4,0.8,5.1-0.2c2.7-1,6-3.1,8.4-6
				c2.5-3,3.9-5.3,5.2-7.6c1.2-2.3,2.4-4.7,3.8-8.3c-1.9,3.4-3.5,5.4-5.3,7.2C260.7,439.2,258.8,440.8,255.9,442.9z"
                />
              </g>
              <g>
                <path
                  d="M258,452.6c-2.4,3.6-3.9,7.7-4.5,10.9c-0.5,3.2-0.2,5.5,0.6,5.6c0.9,0.2,2-1.7,3.4-4.4c1.3-2.7,2.9-6.4,4.3-10.1
				c1.5-3.8,2.8-6.4,4.3-9c1.5-2.5,3.1-5,6.1-8.3c-3.4,2.9-5.5,5-7.6,7.1C262.6,446.7,260.5,448.9,258,452.6z"
                />
              </g>
              <g>
                <path
                  d="M264.1,459.8c-1.9,3.8-3,8-3.2,11.2c-0.3,3.2,0.2,5.4,1,5.5c0.9,0.1,1.9-1.8,3-4.6c1.1-2.9,2.3-6.7,3.3-10.6
				c1-4,1.9-6.8,2.9-9.6c1-2.8,2.2-5.5,4.4-9.3c-2.7,3.5-4.4,6-6.1,8.5C267.7,453.4,266.1,455.9,264.1,459.8z"
                />
              </g>
              <g>
                <path
                  d="M266.4,475.3c-1.1,3.9-1.3,8-0.8,11c0.4,3,1.4,5,2.2,4.9c0.9-0.1,1.5-2,2-4.9c0.5-2.8,0.8-6.6,1-10.3
				c0.2-3.9,0.5-6.6,0.9-9.3c0.5-2.7,1.1-5.5,2.6-9.4c-2,3.6-3.2,6.2-4.4,8.8C268.6,468.7,267.5,471.3,266.4,475.3z"
                />
              </g>
              <g>
                <path
                  d="M332.1,491.8c-2.3,0.4-4.4,1.4-5.7,2.6c-1.3,1.2-1.9,2.4-1.4,3.2c0.5,0.8,1.8,0.9,3.4,0.5c1.6-0.3,3.5-1.1,5.3-2.2
				c1.9-1.2,3.2-2,4.6-2.7c1.4-0.7,2.9-1.4,5.1-2c-2.1,0-3.8,0.1-5.6,0.1C336.1,491.3,334.5,491.4,332.1,491.8z"
                />
              </g>
              <g>
                <path
                  d="M338.2,497.8c-2,0.5-2.5,2.8-1.3,4.1c1.2,1.3,3.4,1.1,4.2-0.8c0.8-1.9,0.9-3,2-4.7C341.2,497.3,340.2,497.3,338.2,497.8z
				"
                />
              </g>
              <g>
                <path d="M432.9,506.6c-3.6,0.1-6.5,2.6-5.9,4.2c0.7,1.6,4.1,1.7,7,0c3-1.8,4.1-2.8,7.6-4C438.1,507,436.6,506.6,432.9,506.6z" />
              </g>
              <g>
                <path
                  d="M152,526.9c-2.4-3.5-5.6-6.8-9.9-9.1c-2.1-1.1-4.4-2-6.8-2.5c-2.4-0.5-5-0.6-7.6-0.1c-5.2,0.9-9.7,3.7-12.6,7.9
				c-0.7,1.1-1.3,2.2-1.8,3.4c-0.5,1.2-0.8,2.5-0.9,3.7c-0.3,2.4,0,4.7,0.7,6.8c0.7,2.1,1.8,4,3.3,5.5c1.5,1.5,3.3,2.6,5,3.2
				c3.6,1.1,6.6,0.4,8.6-0.7c2.1-1.1,3.2-2.7,3.5-3.9c0.2-0.6,0.2-1.1,0.1-1.5c-0.1-0.4-0.3-0.7-0.6-0.8c-0.3-0.2-0.6-0.3-1-0.3
				c-0.4,0-0.8,0-1.2-0.1c-0.8,0-1.8-0.1-2.7-0.4c-0.9-0.3-2-0.9-2.4-1.5c-0.2-0.3-0.3-0.7-0.4-1.1c-0.1-0.4-0.1-0.8-0.1-1.4
				c0-0.5,0.2-1,0.4-1.4c0.1-0.2,0.2-0.4,0.3-0.5c0.1-0.2,0.3-0.4,0.5-0.5c0.4-0.4,0.9-0.7,1.5-1c0.6-0.3,1.3-0.5,2-0.6
				c1.4-0.2,3.4,0.1,5.5,0.9c2,0.8,4.2,2.4,6.2,4.4c1.1,1,2.1,2.2,3,3.4c0.9,1.2,1.8,2.3,2.6,3.5c1.5,2.4,2.8,4.7,3.8,7.1
				c2,4.7,3.3,9.3,3.7,14.1c0.5,4.8,0.2,9.7-1.1,15.2c-1.3,5.4-3.8,11.3-8.4,17.4c5.2-5.5,8.6-11.1,10.9-16.5
				c2.3-5.4,3.7-10.6,4.3-15.9c0.6-5.3,0.5-10.8-0.6-16.8c-0.6-3-1.4-6.1-2.7-9.4c-0.7-1.6-1.4-3.3-2.3-5
				C154.2,530.4,153.2,528.7,152,526.9z"
                />
              </g>
              <g>
                <path
                  d="M180.5,567.9c3.9-1.9,7.3-4.1,9.8-6.3c2.5-2.1,4.1-4.2,3.6-5.9c-0.5-1.6-3.3-2.4-7.1-1.7c-3.7,0.7-8.4,3.2-11.7,7
				c-3.4,3.9-5.2,7-6.8,10c-1.6,3.1-3.1,6.2-4.9,10.7c2.8-4.1,5.3-6.3,7.8-8.3C173.8,571.6,176.5,569.9,180.5,567.9z"
                />
              </g>
              <g>
                <path
                  d="M174.5,542.4c0.4-3.8-1-7.4-2.8-9.6c-1.9-2.2-4.1-3-5.6-2.2c-1.6,0.9-2.2,2.9-2.2,5.3c0,2.4,0.6,5.1,1.9,7.8
				c1.3,2.9,2.2,5,2.8,7.3c0.6,2.3,1,4.7,0.6,8.2c1.4-3.3,2.3-5.6,3.2-8C173.2,548.8,174.1,546.3,174.5,542.4z"
                />
              </g>
              <g>
                <path
                  d="M59.8,573.1c-3.7,3.4-7.3,6.6-11.1,9.2c-3.7,2.5-7.5,4.5-10.6,4.9c-3.2,0.5-5.8-0.6-7.3-1.7c-1.6-1.1-2.1-2.3-2.6-2.2
				c-0.2,0.1-0.3,0.4-0.3,1.1c0.1,0.7,0.4,1.7,1.1,2.7c0.7,1.1,1.9,2.2,3.5,3c1.6,0.8,3.7,1.3,5.9,1.3c2.2-0.1,4.5-0.6,6.7-1.5
				c2.2-0.9,4.3-2.1,6.4-3.4c4.2-2.8,8-6.4,11.4-10.2c3.5-3.9,6.3-7.3,8.9-10.2c2.6-2.9,5.1-5.4,7.9-7.7c2.7-2.2,5.7-4.2,9.4-5.8
				c3.7-1.5,8.2-2.7,13.6-2c-5.3-1-10-0.1-13.9,1.2c-3.9,1.3-7.2,3.1-10.3,5.1c-3.1,2-5.9,4.3-8.9,7
				C66.7,566.4,63.5,569.6,59.8,573.1z"
                />
              </g>
              <g>
                <path
                  d="M73.8,568.7c-1.9,3-3.2,6.4-4,9.7c-0.8,3.3-0.9,6.4-0.8,9.1c0.4,5.4,2.1,8.6,2.9,8.4c0.9-0.2,0.8-3.6,1.3-8.4
				c0.3-2.4,0.7-5.1,1.4-8c0.7-2.9,1.7-5.9,3-8.8c1.4-3,2.8-5.4,4.4-7.4c1.6-2,3.2-3.7,4.9-5.3c1.8-1.5,3.7-3,6-4.3
				c2.3-1.3,5.1-2.5,8.6-3.4c-3.5,0.6-6.4,1.6-9,2.6c-2.5,1.1-4.7,2.2-6.8,3.5c-2.1,1.3-4,2.8-6.1,4.7
				C77.8,563.3,75.8,565.6,73.8,568.7z"
                />
              </g>
              <g>
                <path
                  d="M82.3,565.7c-1.2,2.3-2,4.8-2.4,7.2c-0.4,2.4-0.3,4.7,0,6.6c0.6,3.9,2.2,6.1,3,5.9c0.9-0.2,0.9-2.7,1.2-6
				c0.1-1.7,0.4-3.6,0.7-5.6c0.4-2,0.9-4.2,1.5-6.3c0.7-2.2,1.5-4,2.4-5.5c0.9-1.6,1.8-2.9,2.9-4.2c1.1-1.3,2.3-2.5,3.8-3.7
				c1.5-1.2,3.3-2.3,5.7-3.3c-2.4,0.8-4.4,1.7-6.1,2.6c-1.7,0.9-3.2,1.9-4.6,3c-1.4,1.1-2.7,2.3-4.1,3.8
				C84.9,561.6,83.5,563.4,82.3,565.7z"
                />
              </g>
              <g>
                <path
                  d="M126.8,555.4c3.6,0.7,7.5,0,9.9-1.3c2.5-1.3,3.7-2.8,3.3-3.6c-0.4-0.8-2.2-0.7-4.6-0.4c-2.4,0.3-5.3,0.6-8.5,0.9
				c-3.2,0.2-5.5,0-7.8-0.4c-2.3-0.5-4.6-1.3-7.6-3.4c2.6,2.5,4.7,3.9,6.9,5.1C120.8,553.5,123.1,554.6,126.8,555.4z"
                />
              </g>
              <g>
                <path
                  d="M138.6,563.1c1.7,1.4,3.4,2.4,4.9,3.1c1.5,0.7,2.8,0.9,3.4,0.3c0.6-0.6,0.4-2-0.8-3.5c-1.1-1.5-3.1-3.1-5.5-3.8
				c-2.4-0.7-4.2-0.9-5.9-1c-1.7-0.1-3.4-0.2-5.8-0.2c2.4,0.5,3.9,1.2,5.3,2C135.6,560.8,136.9,561.7,138.6,563.1z"
                />
              </g>
              <g>
                <path
                  d="M141,578.8c0.3,2.1,1,4.1,1.8,5.5c0.8,1.5,1.7,2.4,2.6,2.3c0.9-0.1,1.5-1.4,1.5-3.2c0.1-1.8-0.5-4.2-1.8-6.2
				c-1.3-2.1-2.4-3.4-3.5-4.6c-1.1-1.2-2.2-2.5-3.8-4.2c1.2,2.1,1.7,3.6,2.2,5.2C140.4,575.1,140.7,576.6,141,578.8z"
                />
              </g>
              <g>
                <path
                  d="M143.4,505.7c0.1-2,0.1-3.7-0.1-5.2c-0.2-1.5-0.5-2.7-1.3-3c-0.8-0.3-2,0.5-2.8,2.3c-0.8,1.7-1,4.4-0.2,6.7
				c0.9,2.4,1.9,3.8,3,5.1c1.1,1.3,2.2,2.5,4,4c-1.5-1.8-2-3.4-2.3-4.8C143.4,509.2,143.3,507.7,143.4,505.7z"
                />
              </g>
              <g>
                <path
                  d="M158.8,498.6c0.9-1.8,1.7-3.4,2.3-4.9c0.6-1.4,0.9-2.7,0.4-3.4c-0.5-0.7-2.1-0.5-3.6,0.8c-1.6,1.3-3.1,3.7-3.3,6.4
				c-0.2,2.8,0.3,4.6,0.9,6.3c0.6,1.7,1.4,3.3,2.8,5.3c-1-2.3-1.1-4-0.9-5.5C157.5,501.9,157.9,500.4,158.8,498.6z"
                />
              </g>
              <g>
                <path
                  d="M187.9,576c2.7-0.4,5.2-1.1,7.1-1.9c1.9-0.8,3.2-1.7,3.1-2.6c-0.1-0.9-1.6-1.5-3.8-1.5c-2.2,0-5.1,0.5-7.7,1.8
				c-2.7,1.3-4.4,2.5-6.1,3.6c-1.8,1.2-3.5,2.4-5.8,4c2.7-1.2,4.6-1.8,6.5-2.3C183.2,576.8,185.2,576.4,187.9,576z"
                />
              </g>
              <g>
                <path
                  d="M149,392.3c3,0.1,5.8-1,7.6-2.1c1.8-1.1,2.7-2.4,2.4-3.2c-0.3-0.8-1.9-1-3.9-0.7c-2,0.3-4.4,1-6.7,1.7
				c-2.4,0.8-4.2,1-6,0.9c-1.8-0.1-3.8-0.5-5.9-2.5c1.7,2.3,3.6,3.4,5.4,4.3C143.8,391.6,145.9,392.2,149,392.3z"
                />
              </g>
              <g>
                <path
                  d="M134,321c-1.8,2.8-3.2,6-3.8,9.2c-0.6,3.2-0.5,6.3,0.2,8.9c0.7,2.6,1.8,4.5,2.9,5.6c0.5,0.6,1,0.9,1.4,1.1
				c0.4,0.2,0.7,0.2,0.8,0.1c0.7-0.6-0.7-3.3-1-7.6c-0.1-2.1,0-4.6,0.5-7.2c0.5-2.6,1.5-5.3,2.8-8c1.3-2.8,2.7-5.1,4-7.1
				c1.3-2.1,2.6-3.9,3.9-5.7c1.3-1.9,2.6-3.7,4-5.8c1.4-2.1,2.9-4.5,4.3-7.6c-1.6,2.9-3.4,5.1-5,7.1c-1.6,1.9-3.2,3.5-4.8,5.2
				c-1.6,1.6-3.1,3.2-4.8,5.1C137.7,315.9,135.8,318.1,134,321z"
                />
              </g>
              <g>
                <path
                  d="M143.2,322.4c-0.7,5.5,0.4,11.2,2,15.1c1.6,3.9,3.6,6,4.4,5.6c0.8-0.4,0.3-3.1-0.4-6.8c-0.7-3.8-1.4-8.7-1.7-13.7
				c-0.2-5.2,0.2-8.9,1-12.5c0.8-3.6,2-7.3,5-12.1c-3.4,4.5-5.2,8-6.8,11.5C145.2,313,143.9,316.7,143.2,322.4z"
                />
              </g>
              <g>
                <path
                  d="M149.7,316.6c0,4,1.3,7.8,2.8,10.4c1.5,2.6,3.2,3.9,3.9,3.4c0.8-0.4,0.5-2.4-0.1-5c-0.6-2.6-1.5-6-2.3-9.4
				c-0.9-3.5-1.2-6.1-1.4-8.7c-0.1-2.6,0-5.3,1-9.2c-1.5,3.7-2.3,6.3-2.9,9C150.2,309.8,149.7,312.5,149.7,316.6z"
                />
              </g>
              <g>
                <path
                  d="M93.8,341.5c3.2-2.6,5.7-6,7.1-8.8c1.4-2.8,1.7-5,0.9-5.4c-0.8-0.4-2.4,0.9-4.4,3c-2,2.1-4.4,5-6.8,8.1
				c-2.4,3.1-4.3,5.2-6.3,7.2c-2,2-4.2,3.8-7.8,6.1c3.8-1.8,6.4-3.2,8.9-4.6C88,345.7,90.5,344.2,93.8,341.5z"
                />
              </g>
              <g>
                <path
                  d="M102.3,347.6c2.7-0.3,5.3-1,7.2-1.7c1.9-0.8,3.2-1.7,3.1-2.5c-0.1-0.9-1.6-1.5-3.8-1.6c-2.2-0.1-5.1,0.4-7.7,1.7
				c-2.7,1.3-4.4,2.4-6.2,3.5c-1.8,1.2-3.6,2.3-5.9,3.8c2.7-1.2,4.7-1.7,6.6-2.1C97.6,348.2,99.5,347.9,102.3,347.6z"
                />
              </g>
              <g>
                <path
                  d="M119.4,246.7c1.9-1.6,3.5-3.5,4.5-5.2c1-1.6,1.4-3,0.8-3.7c-0.6-0.6-2.1-0.3-3.8,0.8c-1.7,1.1-3.6,3-4.9,5.4
				c-1.3,2.4-2,4.2-2.8,5.9c-0.8,1.9-1.5,3.7-2.5,6c1.5-2.3,2.8-3.7,4.1-5C116.1,249.6,117.4,248.4,119.4,246.7z"
                />
              </g>
              <g>
                <path
                  d="M20.7,384.4c-7.8,3.2-14.9,7.9-19.7,12.1c-4.8,4.2-7.2,7.7-6.7,8.3c0.6,0.7,4.1-1.6,9.2-5c5.1-3.3,11.9-7.6,19-11.4
				c7.3-3.9,12.7-6.1,18.2-8c5.5-1.8,11.3-3.3,20-4.3c-8.8,0.4-14.7,1.3-20.5,2.4C34.5,379.7,28.7,381.1,20.7,384.4z"
                />
              </g>
              <g>
                <path
                  d="M27.1,386.9c-6.2,3.1-11.7,7.5-15.3,11.3c-3.6,3.8-5.4,6.9-4.8,7.4c0.7,0.6,3.5-1.3,7.5-4.3c4-3,9.3-6.9,14.8-10.6
				c5.7-3.8,9.8-6.1,14.1-8.2c4.3-2,8.9-3.9,15.8-5.7c-7.1,1.2-11.9,2.5-16.5,3.9C38.1,382.1,33.5,383.7,27.1,386.9z"
                />
              </g>
              <g>
                <path
                  d="M-19.8,372.5c-3.2-2.4-6.3-4.7-8.9-6.3c-2.5-1.6-4.5-2.5-5.1-1.9c-0.6,0.6,0.3,2.7,2.4,5.1c2.1,2.4,5.5,5.1,9.3,6.9
				c4,1.8,6.8,2.7,9.6,3.6c2.9,0.8,5.7,1.6,10,2.5c-4.1-1.4-6.8-2.8-9.3-4.2C-14.2,376.7-16.6,375-19.8,372.5z"
                />
              </g>
              <g>
                <path
                  d="M-14.5,384.4c-0.9,0-1.8,0.2-2.7,0.6c-0.9,0.3-1.9,1-2.5,2c-0.6,1-0.6,2.1-0.3,2.7c0.3,0.7,0.9,0.8,1.3,0.7
				c0.4-0.1,0.8-0.4,1.2-0.5c0.4-0.1,0.7-0.1,1-0.1c0.2,0.1,0.6,0,1.2-0.2c0.7-0.2,1.4-0.5,2.1-1c1.4-0.9,2.5-1.5,3.6-2
				c1.1-0.5,2.3-0.9,4.1-1.2c-1.6-0.2-2.9-0.5-4.4-0.7C-11.3,384.6-12.6,384.4-14.5,384.4z"
                />
              </g>
              <g>
                <path
                  d="M-13.6,395.2c-2.1,1.6-3.6,3.7-4.4,5.6c-0.7,1.8-0.7,3.3,0,3.8c0.8,0.5,2-0.2,3.4-1.4c1.4-1.2,2.9-3,4.1-5
				c1.3-2.1,2.3-3.5,3.4-4.9c1.1-1.4,2.3-2.7,4.3-4.4c-2.3,1.2-4,2-5.6,2.9C-9.8,392.7-11.4,393.6-13.6,395.2z"
                />
              </g>
              <g>
                <path d="M-9.5,421.5c-1.3,2.9-0.3,6,1.4,6.3c1.7,0.2,3.5-2.6,2.9-5.7c-0.5-3.2-1.1-4.4-1.2-7.7C-7.4,417.5-8.2,418.6-9.5,421.5z" />
              </g>
              <g>
                <path d="M-2.1,415.6c-1.2,2.5-0.2,5.3,1.5,5.5c1.7,0.2,3.4-2.2,2.8-4.9c-0.6-2.8-1.2-3.9-1.4-6.7C0,412.2-0.9,413.1-2.1,415.6z" />
              </g>
              <g>
                <path
                  d="M578.1,305.9c3.3-4.2,6.4-8.7,8.9-13.6c2.5-4.9,4.2-10.3,4.9-15.7c0.7-5.4,0.4-10.9-1.2-15.8c-0.4-1.2-0.8-2.4-1.3-3.6
				c-0.5-1.2-1.1-2.3-1.8-3.4c-1.4-2.2-3.1-4.2-5.3-5.7c-1.1-0.8-2.3-1.3-3.6-1.8c-1.3-0.4-2.7-0.6-4-0.5c-2.5,0.1-4.9,0.9-6.8,2.5
				c-0.9,0.8-1.7,1.7-2.3,2.7c-0.6,1-0.8,2.1-1,3.1c-0.2,2,0.2,3.7,0.7,5c1.1,2.5,2.5,3.9,3.5,4.8c1,0.9,1.7,1.1,2.1,0.9
				c0.4-0.2,0.3-1,0.1-2.2c-0.2-1.2-0.8-2.9-1-4.7c-0.1-0.9,0-1.8,0.3-2.5c0.2-0.3,0.4-0.7,0.6-0.9c0.2-0.2,0.5-0.4,0.9-0.6
				c0.7-0.3,1.7-0.5,2.7-0.3c0.5,0.1,1,0.2,1.4,0.4c0.4,0.2,0.9,0.6,1.4,1c0.9,0.8,1.8,2,2.5,3.4c0.7,1.4,1.3,3.2,1.8,5
				c0.9,3.6,1,7.7,0.4,12c-0.6,4.2-1.9,8.6-3.8,12.8c-1.9,4.3-4.4,8.5-7.2,12.6c-2.9,4.3-5.8,8.2-8.5,11.9
				c-2.7,3.7-5.2,7.3-7.5,10.8c-2.2,3.5-4.2,7-5.8,10.6c-1.6,3.6-2.8,7.2-3.7,10.9c-1.7,7.4-2.1,15.1-0.8,23.5
				c0.6,4.2,1.7,8.5,3.4,13c1.7,4.4,4,9,7.3,13.5c-3-4.6-5.1-9.3-6.5-13.7c-1.4-4.5-2.1-8.8-2.4-12.9c-0.6-8.2,0.5-15.5,2.8-22.3
				c1.2-3.4,2.6-6.6,4.4-9.7c1.8-3.1,4-6.2,6.4-9.3c2.4-3.1,5.2-6.3,8.2-9.7C571.4,313.8,574.7,310.2,578.1,305.9z"
                />
              </g>
              <g>
                <path
                  d="M561.9,353.7c1-1.7,2-3.3,3.1-4.8c1.1-1.5,2.2-2.8,3.4-4.1c1.1-1.3,2.4-2.4,3.5-3.4c0.3-0.2,0.6-0.5,0.9-0.7
				c0.3-0.2,0.5-0.4,0.8-0.6c0.5-0.4,1.1-0.7,1.6-1c2.2-1.2,4-1.8,5.4-2c1.5-0.3,2.7-0.1,3.3-0.7c0.3-0.3,0.4-0.8,0.2-1.5
				c-0.2-0.7-0.8-1.5-1.9-2.3c-1-0.8-2.5-1.4-4.3-1.6c-1.7-0.2-3.7-0.1-5.6,0.3c-1,0.2-2,0.6-3,1c-0.5,0.2-1,0.5-1.5,0.7
				c-0.5,0.3-0.9,0.5-1.4,0.8c-1.8,1.1-3.7,2.5-5.3,4.2c-1.6,1.7-3.1,3.6-4.4,5.6c-1.2,2.1-2.2,4.3-3,6.5c-1.6,4.7-2.1,8.7-2.3,12.3
				c-0.2,3.6,0,6.8,0.3,9.9c0.7,6.3,1.9,12.4,3.8,21.4c-0.9-9.1-0.8-15.4,0-21.4c0.4-3,1-5.9,1.9-9
				C558.6,360.4,559.9,357.1,561.9,353.7z"
                />
              </g>
              <g>
                <path
                  d="M574.5,279.9c-0.6-1.9-1.5-3.7-2.6-5.3c-0.6-0.8-1.2-1.6-1.9-2.3c-0.7-0.7-1.5-1.3-2.3-1.9c-1.7-1.1-3.9-1.8-6.2-1.5
				c-1.1,0.2-2.1,0.5-3,1.1c-0.9,0.6-1.6,1.4-2.1,2.4c-0.4,0.9-0.6,1.9-0.6,2.8c0,0.9,0.2,1.6,0.5,2.3c0.5,1.3,1.2,2.1,1.8,2.7
				c1.2,1.2,2.1,1.6,2.4,1.4c0.4-0.3,0.1-1.2-0.4-2.6c-0.2-0.7-0.5-1.5-0.6-2.4c-0.1-0.9,0.1-1.5,0.5-2c0.4-0.4,1.1-0.8,2-0.9
				c0.8-0.1,1.9,0.3,2.9,1c1.1,0.7,2.1,1.8,3.1,2.9c0.9,1.2,1.7,2.5,2.4,3.9c1.3,3,1.8,5.9,1.9,8.5c0.1,2.6-0.2,5.1-0.8,7.5
				c-1.1,4.8-3.2,9.6-6.8,16.2c4.1-6.4,6.7-10.8,8.6-15.6c0.9-2.4,1.7-4.9,2-7.9C575.6,287.2,575.6,283.8,574.5,279.9z"
                />
              </g>
              <g>
                <path
                  d="M568.9,290c-0.3-0.5-0.5-1.1-0.8-1.6c-0.3-0.5-0.7-1.1-1.2-1.5c-0.9-1-1.9-1.8-3.4-2.3c-0.7-0.3-1.5-0.4-2.3-0.4
				c-0.8,0-1.6,0.3-2.3,0.6c-1.4,0.7-2.2,1.8-2.6,2.8c-0.5,1-0.6,2-0.6,2.7c0,0.8,0.1,1.4,0.3,1.9c0.3,1,0.7,1.5,1.2,1.5
				c0.4,0,0.9-0.5,1.3-1.4c0.4-0.8,0.9-2,1.5-2.7c0.3-0.4,0.7-0.6,1-0.7c0.3,0,0.5,0,0.9,0.1c0.4,0.2,1,0.5,1.6,1.1
				c0.3,0.3,0.6,0.5,0.8,0.9c0.3,0.3,0.5,0.7,0.8,1.1c1.1,1.5,1.8,3,2.3,4.5c0.5,1.4,0.8,2.8,0.9,4.2c0.1,1.4,0.1,2.9-0.3,4.5
				c-0.3,1.6-0.9,3.4-2.1,5.3c1.4-1.7,2.3-3.4,3-5c0.7-1.6,1.1-3.1,1.3-4.7c0.2-1.6,0.4-3.1,0.2-4.9
				C570.3,294.2,569.9,292.2,568.9,290z"
                />
              </g>
              <g>
                <path
                  d="M548.8,301.3c-1.9-0.3-3.8-0.3-5.2,0c-1.5,0.3-2.6,0.9-2.7,1.8c-0.2,0.9,0.7,1.8,2.3,2.4c1.6,0.6,3.8,0.8,5.9,0.2
				c2.2-0.6,3.6-1.2,5-1.9c1.5-0.7,2.9-1.4,4.7-2.2c-2.1,0.4-3.6,0.5-5.1,0.4C552.1,301.9,550.7,301.7,548.8,301.3z"
                />
              </g>
              <g>
                <path
                  d="M536.3,308.9c-5.8,0.2-11.1,2.8-10.6,4.5c0.4,1.7,6.2,1.7,11.5-0.2c5.5-1.9,7.5-3,13.4-4.3
				C544.5,309.2,542.3,308.8,536.3,308.9z"
                />
              </g>
              <g>
                <path
                  d="M525.6,316.8c-3.9-0.7-7.6,0.8-7.6,2.5c0.1,1.8,4,2.9,7.8,1.8c3.9-1.1,5.3-1.9,9.3-2.6
				C531,318.2,529.6,317.5,525.6,316.8z"
                />
              </g>
              <g>
                <path
                  d="M537.6,319c-2.2,1.1-3.9,2.9-4.8,4.6c-0.9,1.6-1.1,3.1-0.4,3.6c0.7,0.6,2,0.2,3.4-0.7c1.5-0.9,3.1-2.3,4.5-4
				c1.5-1.7,2.6-2.9,3.8-4.1c1.2-1.1,2.5-2.2,4.6-3.4c-2.4,0.7-4,1.3-5.6,1.8C541.5,317.3,539.9,317.8,537.6,319z"
                />
              </g>
              <g>
                <path
                  d="M545.3,322.1c-1.5,1.4-2.5,3.1-2.9,4.6c-0.4,1.5-0.2,2.7,0.6,3.2c0.8,0.4,1.9,0,3-1c1.1-1,2.2-2.5,2.9-4.3
				c1.5-3.7,1.8-5.3,4.1-8.8C549.9,318.6,548.4,319.3,545.3,322.1z"
                />
              </g>
              <g>
                <path
                  d="M554.7,308c-1.3,0.9-2.1,2.2-2.4,3.4c-0.3,1.2,0,2.2,0.7,2.7c0.7,0.5,1.8,0.3,2.7-0.3c1-0.6,1.9-1.7,2.4-3
				c1.1-2.8,1.3-4.1,3.2-6.7C558.7,305.8,557.4,306.2,554.7,308z"
                />
              </g>
              <g>
                <path
                  d="M532.3,341c-1.2,1.5-1.8,3.3-1.8,4.8c0,1.5,0.5,2.5,1.4,2.7c0.9,0.2,1.8-0.4,2.6-1.5c0.8-1,1.5-2.5,1.8-4.2
				c0.3-1.7,0.5-3,0.9-4.2c0.3-1.2,0.8-2.4,1.7-4.1c-1.4,1.3-2.3,2.2-3.3,3.1C534.5,338.6,533.5,339.5,532.3,341z"
                />
              </g>
              <g>
                <path
                  d="M533.2,356.5c-2.1,3.9-1.8,8.5-0.1,8.9c1.7,0.4,3.9-3.4,4.3-7.6c0.4-4.4,0.2-6.1,1.4-10.5
				C536.7,351.3,535.4,352.5,533.2,356.5z"
                />
              </g>
              <g>
                <path
                  d="M533.9,379.3c-0.4,2.4,0.2,4.9,1,6.6c0.8,1.7,2,2.6,2.8,2.3c0.8-0.3,1.2-1.6,1.3-3.2c0.1-1.7-0.2-3.7-0.7-5.8
				c-0.5-2.2-0.8-3.7-1-5.4c-0.2-1.6-0.2-3.3,0.2-5.7c-0.9,2.3-1.5,3.8-2.1,5.5C534.8,375.2,534.2,376.8,533.9,379.3z"
                />
              </g>
              <g>
                <path
                  d="M570.2,359.3c1.9-2.2,3.7-4.2,5-6c1.3-1.7,2.2-3.2,1.7-3.9c-0.5-0.7-2.3-0.5-4.4,0.8c-2.2,1.3-4.6,3.7-6,6.8
				c-1.4,3.1-1.9,5.4-2.3,7.6c-0.4,2.2-0.6,4.5-0.6,7.8c0.5-3.2,1.4-5.3,2.4-7.2C567.1,363.2,568.3,361.5,570.2,359.3z"
                />
              </g>
              <g>
                <path
                  d="M580.7,319.9c1.7-0.7,3-1.7,4-2.7c0.9-1,1.4-2.1,1.1-2.9c-0.4-0.8-1.6-1.1-3.1-0.8c-1.5,0.3-3.3,1.3-4.6,2.8
				c-1.3,1.6-2.1,2.8-2.8,4c-0.8,1.3-1.5,2.6-2.4,4.1c1.4-1.4,2.6-2.1,3.8-2.7C577.8,321.1,579,320.6,580.7,319.9z"
                />
              </g>
              <g>
                <path
                  d="M-6.5,258.1c1.9-0.4,3.5-1.1,4.7-2c1.2-0.9,1.9-1.9,1.6-2.7c-0.2-0.8-1.4-1.3-3-1.2c-1.6,0.1-3.5,0.7-5.2,2
				c-1.7,1.3-2.7,2.3-3.8,3.3c-1,1-2.1,2-3.7,3.4c1.9-0.9,3.2-1.4,4.6-1.7C-9.8,258.7-8.5,258.5-6.5,258.1z"
                />
              </g>
              <g>
                <path
                  d="M8.4,246.2c-1.5,2.6-2.1,5.6-2,7.8c0.1,2.3,0.8,3.7,1.7,3.8c0.9,0.1,1.7-1.2,2.5-3.1c0.8-1.9,1.5-4.3,2-6.9
				c0.5-2.7,1.1-4.5,1.7-6.4c0.7-1.8,1.5-3.7,3.3-6.1c-2.1,2-3.5,3.5-4.9,5.1C11.2,242,9.9,243.6,8.4,246.2z"
                />
              </g>
              <g>
                <path
                  d="M544.8,223.7c-1.8-1.5-3.8-2.7-6-3.5c-2.1-0.9-4.5-1.4-6.9-1.4c-1.2,0-2.4,0.2-3.5,0.6c-1.2,0.4-2.3,0.9-3.2,1.6
				c-1.9,1.4-3.2,3.3-3.7,5.3c-0.3,1-0.3,2-0.2,3.1c0.2,1,0.5,1.8,0.9,2.5c0.9,1.4,2.1,2.3,3.1,2.7c1.1,0.4,2,0.4,2.6,0.2
				c0.6-0.2,0.8-0.4,0.8-0.6c-0.1-0.5-1.1-0.6-2.2-1.7c-1.1-1-2.1-3.1-1.2-5c0.4-1,1.3-2,2.4-2.7c0.6-0.4,1.2-0.6,1.9-0.8
				c0.7-0.2,1.5-0.3,2.2-0.3c3.2,0,7,1.4,10.2,3.5c3.3,2.2,5.7,4.8,7.4,7.5c0.9,1.3,1.6,2.7,2.2,4c0.6,1.4,1.1,2.7,1.5,4.1
				c0.8,2.8,1.2,5.8,1.2,9.2c0,3.4-0.4,7.1-1.6,11.5c1.4-4.3,2.2-8,2.5-11.5c0.3-3.4,0.2-6.5-0.3-9.6c-0.3-1.5-0.6-3.1-1-4.6
				c-0.5-1.5-1.1-3.1-1.9-4.7C550.7,230.1,548.4,226.7,544.8,223.7z"
                />
              </g>
              <g>
                <path
                  d="M547.9,236.8c-0.9-1.3-2.2-2.4-3.6-3.2c-1.5-0.8-3.3-1.2-5-1c-1.8,0.2-3.4,1.2-4.5,2.4c-1.1,1.2-1.7,2.9-1.6,4.4
				c0.1,1.7,1,2.9,1.8,3.6c0.9,0.7,1.9,1.1,2.7,1.1c0.8,0,1.4-0.2,1.8-0.5c0.3-0.3,0.4-0.6,0.3-0.8c-0.2-0.4-0.9-0.6-1.4-1.1
				c-0.3-0.3-0.6-0.6-0.8-1.1c-0.2-0.4-0.3-0.9-0.1-1.2c0.1-0.4,0.4-0.8,0.8-1.2c0.5-0.4,1.1-0.7,1.7-0.7c1.3-0.2,3.2,0.6,4.7,2
				c1.6,1.5,2.6,3.5,3.6,5.3c0.9,1.8,1.7,3.4,2.3,5.1c0.6,1.7,1.2,3.5,1.6,5.5c0.4,2,0.7,4.4,0.8,7.1c0.2-2.8,0.2-5.1,0.1-7.2
				c-0.1-2.1-0.3-4-0.6-5.9c-0.3-1.9-0.7-3.7-1.3-5.8c-0.3-1-0.6-2.1-1.1-3.3C549.5,239.4,548.9,238.1,547.9,236.8z"
                />
              </g>
              <g>
                <path
                  d="M613.1,514.1c0.5,1.2,0.7,2.4,0.8,3.5c0,1.1-0.1,2-0.4,2.7c-0.3,0.7-0.7,1.4-1.3,2.1c-0.6,0.6-1.4,1.3-2.4,1.7
				c-1.9,1-4,1.4-6.1,1.3c-2-0.1-3.6-0.8-4.7-1.5c-1.2-0.9-2-2.1-2.3-3.2c-0.4-1-0.3-2.3,0.1-3.4c0.4-1.1,0.9-2,1.4-2.8
				c0.5-0.8,0.9-1.4,0.9-2.1c0-0.6-0.6-1.3-1.9-1.6c-1.3-0.3-3.3,0-5.5,1.3c-1.1,0.7-2.1,1.6-3.1,2.9c-0.5,0.7-0.9,1.4-1.2,2.2
				c-0.4,0.8-0.6,1.8-0.8,2.7c-0.7,3.8,0.2,8.2,2.9,11.9c2.8,3.9,7.2,6.5,12,7.4c4.7,1,10.1,0.5,14.9-1.9c2.4-1.2,4.7-2.8,6.7-5
				c2-2.2,3.6-4.9,4.4-8c0.8-3,0.8-6.2,0.3-9c-0.6-2.9-1.7-5.4-3.1-7.7c-1.5-2.3-3.3-4.3-5.2-5.9c-1.9-1.6-4.1-2.9-6.1-3.7
				c-4.1-1.7-8-2.3-11.5-2.4c-7.1-0.1-12.9,1.4-18.6,3.1c-5.6,1.8-11.1,4.1-16.8,7.4c-5.7,3.2-11.6,7.4-17.6,13.4
				c6.6-5.4,12.9-8.7,18.8-11c5.9-2.3,11.4-3.5,16.8-4.2c5.4-0.6,11-0.7,16.3,0.5c2.7,0.6,5.3,1.6,7.5,3.1c1.2,0.8,2,1.6,2.8,2.6
				C611.9,511.7,612.6,512.9,613.1,514.1z"
                />
              </g>
              <g>
                <path
                  d="M586.9,487.9c2.8-1.8,5.2-4.2,7.1-6.8c1.9-2.6,3.2-5.3,3.9-7.8c0.7-2.5,0.8-4.7,0.6-6.3c-0.3-1.6-0.9-2.5-1.8-2.7
				c-1.8-0.3-3.7,2.1-6.3,5.3c-1.3,1.7-2.7,3.5-4.3,5.4c-1.6,2-3.3,4.1-5.1,6.3c-1.8,2.3-3.6,4.1-5.2,5.7c-1.7,1.6-3.2,3.1-4.9,4.4
				c-3.3,2.7-6.9,5.3-12.6,8.5c6.1-2.3,10.3-3.7,14.5-5.2c2.1-0.8,4.2-1.6,6.5-2.7C581.6,491.1,584.1,489.8,586.9,487.9z"
                />
              </g>
              <g>
                <path
                  d="M574,520.4c-1.2,2.3-1.3,5.1-0.8,7c0.5,1.9,1.5,3,2.4,2.8c0.9-0.2,1.4-1.4,1.8-3c0.4-1.6,0.7-3.5,0.8-5.6
				c0.2-2.2,0.5-3.7,1-5.3c0.5-1.5,1.3-3,2.9-4.9c-2,1.4-3.3,2.6-4.5,3.9C576.4,516.6,575.3,518,574,520.4z"
                />
              </g>
              <g>
                <path
                  d="M580.2,543.9c0.4,2.6,1.7,5,3.2,6.4c1.5,1.4,2.9,1.9,3.6,1.3c0.7-0.6,0.5-2,0-3.6c-0.5-1.6-1.4-3.6-2.5-5.5
				c-1.1-2-1.8-3.6-2.3-5.2c-0.5-1.6-0.9-3.3-0.8-5.9c-0.6,2.5-0.9,4.3-1.1,6.1C580,539.4,579.8,541.2,580.2,543.9z"
                />
              </g>
              <g>
                <path
                  d="M599.3,558c1.3,1.4,3,2.4,4.7,2.8c1.7,0.5,3.4,0.5,4.8,0.2c1.4-0.3,2.4-0.9,3-1.4c0.6-0.6,0.8-1.1,0.7-1.5
				c-0.4-0.8-2-0.9-3.9-1.2c-2-0.3-4.3-0.9-6.5-2.2c-1.2-0.7-2.1-1.5-2.9-2.3c-0.8-0.8-1.4-1.7-2-2.6c-1.2-1.8-2.1-3.9-3.1-7.1
				c0.5,3.3,0.8,5.6,1.4,7.8c0.3,1.1,0.6,2.3,1.2,3.5C597.2,555.2,598,556.6,599.3,558z"
                />
              </g>
              <g>
                <path
                  d="M613.7,553.2c2.2,1.1,4.7,1.3,6.5,1c1.8-0.3,3-1.2,2.9-2.1c-0.1-0.9-1.2-1.6-2.7-2.1c-1.5-0.5-3.5-0.9-5.5-1
				c-2.2-0.2-3.7-0.4-5.2-0.7c-1.5-0.4-3.1-0.9-5.1-2.2c1.7,1.7,2.9,2.8,4.2,3.9C610.1,551,611.5,552.1,613.7,553.2z"
                />
              </g>
              <g>
                <path
                  d="M623.6,545.9c1.5,0.1,2.9-0.6,3.8-1.4c0.8-0.8,1.1-1.9,0.7-2.6c-0.4-0.8-1.3-1.2-2.4-1.2c-1,0-2.2,0.3-3.3,1
				c-1.1,0.8-1.9,1.3-2.8,1.8c-0.9,0.5-1.8,0.9-3.2,1.2c1.4,0.3,2.5,0.5,3.5,0.7C620.9,545.6,622,545.8,623.6,545.9z"
                />
              </g>
              <g>
                <path
                  d="M584.7,454.4c0.8-4,0.4-8.1-0.6-11.1c-0.9-2.9-2.3-4.6-3.1-4.4c-0.9,0.3-1.1,2.3-1,5c0,2.8,0.2,6.4,0.3,10.1
				c0.1,3.8,0,6.5-0.4,9.1c-0.4,2.7-1,5.4-2.7,9.1c2.2-3.5,3.4-6,4.5-8.6C582.9,461.2,583.9,458.5,584.7,454.4z"
                />
              </g>
              <g>
                <path
                  d="M175.8,236.9c-0.3,3.3,0.7,6.7,2.2,8.7c1.4,2.1,3.1,2.9,3.7,2.4c0.7-0.5,0.5-2.1,0.1-4.1c-0.5-2.3-1-4.4-1.6-7.2
				c-0.5-2.8-0.6-4.8-0.4-6.9c0.2-2,0.7-4.2,2.3-6.9c-2.1,2.4-3.2,4.4-4.2,6.4C177,231.3,176.2,233.5,175.8,236.9z"
                />
              </g>
              <g>
                <path
                  d="M316.4,56.6c3.3,0.8,6.5,1.5,9,2c2.5,0.5,4.4,0.7,4.8-0.1c0.4-0.7-0.8-2.4-3.3-3.8c-2.5-1.5-6.3-2.6-10.1-2.5
				c-3.9,0.1-6.5,0.7-9.1,1.5c-2.5,0.8-5,1.6-8.5,3.4c3.6-1.2,6.2-1.4,8.7-1.5C310.5,55.6,313,55.9,316.4,56.6z"
                />
              </g>
              <g>
                <path
                  d="M166.4,149.7c1.4,3.4,3.9,6.3,6.1,7.9c2.2,1.7,4.1,2.1,4.7,1.5c0.6-0.7-0.3-2.3-1.7-4.4c-1.4-2.1-3.3-4.6-5.3-7.2
				c-2.1-2.7-3.3-4.7-4.4-6.9c-1.1-2.2-2-4.5-2.6-8.1c0.1,3.7,0.4,6.2,0.8,8.7C164.4,143.7,165,146.2,166.4,149.7z"
                />
              </g>
              <g>
                <path
                  d="M79.1,161.1c1.1-2.5,1.1-5.4,0.4-7.4c-0.7-2-1.8-3-2.6-2.8c-0.9,0.3-1.3,1.6-1.5,3.2c-0.3,1.7-0.4,3.7-0.5,5.9
				c-0.1,2.3-0.4,3.9-0.9,5.5c-0.5,1.6-1.3,3.2-3.1,5.1c2.1-1.5,3.4-2.8,4.7-4.2C76.7,165.2,77.9,163.7,79.1,161.1z"
                />
              </g>
              <g>
                <path
                  d="M340.8,260.3c3.5-6.8,4.5-14.6,2.8-15.1c-1.7-0.5-5.1,6.3-6.9,13.5c-0.9,3.8-1.5,6.4-2.2,9c-0.7,2.6-1.5,5.2-2.9,9
				c1.9-3.5,3.3-5.9,4.7-8.3C337.6,266.1,339,263.8,340.8,260.3z"
                />
              </g>
              <g>
                <path
                  d="M485,235.3c3.3,0,6.5-0.3,9-0.8c2.4-0.5,4.1-1.2,4.2-2.1c0-0.9-1.7-1.7-4.2-2.1c-2.6-0.4-6.1-0.3-9.4,0.6
				c-3.4,0.9-5.7,1.8-8,2.7c-2.4,1-4.7,1.9-7.8,3.1c3.5-0.8,5.9-1.1,8.3-1.3C479.3,235.3,481.6,235.3,485,235.3z"
                />
              </g>
              <g>
                <path
                  d="M596.5,141.5c-2.7,0.3-5.3,1.3-7,2.5c-1.7,1.2-2.6,2.4-2.2,3.2c0.4,0.8,1.9,0.9,3.8,0.6c1.9-0.3,4.3-1,6.5-2.1
				c2.4-1.1,4-1.9,5.8-2.5c1.7-0.7,3.5-1.2,6.3-1.7c-2.6-0.1-4.5-0.1-6.6-0.2C601.2,141.3,599.3,141.2,596.5,141.5z"
                />
              </g>
              <g>
                <path
                  d="M548.5,79c-2.4,1.8-4.6,3.6-6.2,5.2c-1.6,1.6-2.6,2.9-2.2,3.7c0.4,0.8,2.2,0.8,4.5-0.2c2.3-1,5.1-3,7.1-5.6
				c2-2.7,3.1-4.8,4-6.9c0.9-2.1,1.8-4.2,2.9-7.4c-1.5,3-3,4.7-4.5,6.3C552.6,75.7,550.9,77.1,548.5,79z"
                />
              </g>
              <g>
                <path
                  d="M482.8,101.2c3.5-0.6,7-1.5,9.5-2.4c2.6-0.9,4.3-1.9,4.2-2.8c-0.1-0.9-2.1-1.4-4.9-1.3c-2.8,0.1-6.6,0.8-10,2.3
				c-3.6,1.5-5.9,2.8-8.2,4.1c-2.3,1.3-4.6,2.7-7.9,4.7c3.6-1.5,6.1-2.3,8.6-2.9C476.6,102.3,479.2,101.9,482.8,101.2z"
                />
              </g>
              <g>
                <path
                  d="M503.3,526.1c2.5-1.7,3.3-4.9,2-6c-1.4-1.1-4.3,0.4-5.4,3.3c-1.2,2.9-1.3,4.3-2.8,7.1
				C499.4,528.3,500.7,527.9,503.3,526.1z"
                />
              </g>
              <g>
                <path
                  d="M281.3,518.4c2,0.3,3.8,0.4,5.3,0.2c1.5-0.1,2.7-0.5,3-1.3c0.3-0.8-0.5-2-2.2-2.8c-1.6-0.8-4.1-1.2-6.5-0.5
				c-2.4,0.7-3.9,1.5-5.3,2.3c-1.4,0.9-2.8,1.8-4.6,3.2c2.1-1,3.6-1.3,5.1-1.4C277.8,518,279.3,518.1,281.3,518.4z"
                />
              </g>
            </g>
          </g>
          <g id="Peddle13">
            <defs>
              <path
                id="SVGID_00000124136308722819254380000003708042319012961708_"
                d="M311.5,507.1c-18.3-44.3-68.5-245.3-34.1-259.5
			c34.4-14.2,140.3,163.9,158.6,208.2c18.3,44.3,13.6,125.1-16.2,137.3C390,605.5,329.8,551.5,311.5,507.1z"
              />
            </defs>
            <use
              xlinkHref="#SVGID_00000124136308722819254380000003708042319012961708_"
              overflow="visible"
              fill="#003301"
            />
            <clipPath id="SVGID_00000123428466541698317540000011023449789138076834_">
              <use
                xlinkHref="#SVGID_00000124136308722819254380000003708042319012961708_"
                overflow="visible"
              />
            </clipPath>
            <g
              opacity="0.1"
              clipPath="url(#SVGID_00000123428466541698317540000011023449789138076834_)"
            >
              <g>
                <g>
                  <path
                    d="M312.1,219.6c8.1-1.2,15.4-3.8,20.8-7c5.4-3.2,8.8-6.9,8.1-10.4c-0.7-3.4-5.5-5.7-12.2-5.8c-6.7-0.1-15.4,2-22.9,6.8
					c-7.7,4.9-12.4,9-17.2,13c-4.7,4-9.4,8-16.3,13.7c8.1-3.8,13.9-5.6,19.8-6.9C297.9,221.7,303.8,220.8,312.1,219.6z"
                  />
                </g>
                <g>
                  <path
                    d="M314.5,239.9c7,1.8,13.9,2.1,19.5,1.1c5.5-1,9.7-3.2,10.5-6.6c0.8-3.4-2.4-7.3-8.1-9.9c-5.6-2.6-13.8-3.8-21.8-2.1
					c-8.2,1.8-13.5,3.9-18.8,6c-5.3,2.1-10.5,4.2-18,7.1c8-0.8,13.5-0.4,18.9,0.5C302,236.8,307.2,238.1,314.5,239.9z"
                  />
                </g>
                <g>
                  <path
                    d="M303.9,258.7c4.2,3.4,8.8,5.1,13,5.4c4.2,0.3,8.1-0.9,10-3.9c1.9-3,1.1-7.2-2.1-10.9c-3.1-3.7-8.6-6.8-14.9-7.1
					c-6.5-0.4-10.9,0.2-15.2,0.7c-4.3,0.5-8.6,1-14.6,1.5c5.8,1.7,9.5,3.7,12.9,5.9C296.4,252.6,299.5,255.2,303.9,258.7z"
                  />
                </g>
                <g>
                  <path
                    d="M282.6,276.1c1.5,4.7,4.5,8,8,9.7c3.4,1.7,7.3,1.9,10.2-0.1c2.9-2,4.1-5.7,3.3-9.8c-0.7-4-3.4-8.3-7.9-11
					c-4.6-2.8-8.1-4.2-11.5-5.8c-3.4-1.6-6.6-3.2-10.9-6.1c2.8,4.3,4.2,7.7,5.3,11.2C280.2,267.7,281.1,271.2,282.6,276.1z"
                  />
                </g>
                <g>
                  <path
                    d="M262,284.1c-1.5,5-1.3,9.6,0.1,13.5c1.3,3.8,3.8,7,7.3,7.6c3.4,0.7,7.1-1.5,9.3-5.8c2.2-4.2,3-10.5,0.8-16.4
					c-2.3-6-4.6-9.7-6.8-13.4c-2.3-3.6-4.5-7.1-7.6-12.2c1,5.8,0.8,9.9,0.1,13.8C264.5,275.2,263.5,279,262,284.1z"
                  />
                </g>
                <g>
                  <path
                    d="M238.4,276.8c-4.1,3.3-6.7,7-8.2,10.8c-0.3,1-0.7,1.9-0.9,2.9c-0.2,1-0.3,1.9-0.3,2.8c0,1.8,0.4,3.6,1.4,5
					c1,1.4,2.6,2.3,4.6,2.6c1,0.1,2.1,0.1,3.2-0.1c1.1-0.2,2.4-0.5,3.5-1.1c4.8-2.1,9.8-7.2,11.7-13.9c1.9-6.8,2-11.6,2-16.2
					c-0.1-4.6-0.3-9.1-0.8-15.3c-1.6,6-3.8,9.7-6.4,12.9C245.6,270.5,242.6,273.3,238.4,276.8z"
                  />
                </g>
                <g>
                  <path
                    d="M221.4,254.8c-5.9,0.7-10.8,2.7-14.6,5.4c-3.7,2.7-6.2,6.2-5.7,9.7c0.5,3.5,4.2,6.1,9.6,6.6c5.3,0.5,12.3-1.2,17.7-5.6
					c5.6-4.6,8.8-8.3,11.8-12c3-3.7,5.9-7.3,10.2-12.6c-5.6,3.6-10,5.2-14.3,6.3C231.8,253.5,227.4,254.1,221.4,254.8z"
                  />
                </g>
                <g>
                  <path
                    d="M214.7,232.3c-5.8-1.9-11.2-2.4-15.9-1.9c-1.2,0.2-2.3,0.3-3.3,0.7c-1.1,0.3-2,0.7-2.9,1.1c-1.7,0.9-3.1,2.2-3.7,3.8
					c-0.6,1.6-0.4,3.5,0.5,5.3c0.5,0.9,1.2,1.9,2,2.7c0.9,0.9,1.9,1.8,3.1,2.5c4.8,3.1,12.4,4.8,19.7,3.2c7.5-1.7,12.3-4,16.9-6.2
					c4.5-2.3,9-4.7,15-8.3c-6.9,1.6-11.7,1.4-16.4,0.8C225.1,235.5,220.6,234.3,214.7,232.3z"
                  />
                </g>
                <g>
                  <path
                    d="M221.6,214.1c-3.8-4.1-7-7.1-10.2-9.4c-1.6-1.2-3.2-2.2-4.8-3.1c-1.6-0.9-3.3-1.6-5.1-1.4c-1.7,0.1-3.3,1.2-4.4,3.3
					c-1.1,2.1-1.4,5.3-0.6,8.8c0.8,3.5,2.7,7.3,5.7,10.4c2.9,3.1,6.8,5.6,11,6.8c4.4,1.3,7.9,1.6,11.1,1.7c3.2,0.1,5.9-0.1,8.6-0.4
					c5.4-0.6,10.5-1.4,17.9-2.1c-7.3-1.5-12.2-3.2-16.6-5.4c-2.2-1.1-4.3-2.4-6.4-3.9C225.6,217.9,223.5,216.2,221.6,214.1z"
                  />
                </g>
                <g>
                  <path
                    d="M239.5,196.3c-0.1-5.5-0.9-9.8-2.3-13.6c-0.7-1.9-1.5-3.7-2.5-5.3c-1-1.6-2.2-2.9-3.9-3.6c-1.6-0.7-3.5-0.5-5.4,0.6
					c-1.9,1.1-3.7,3.3-4.9,6.3c-1.2,3-1.8,6.7-1.4,10.5c0.4,3.8,1.7,7.8,3.9,11.1c2.3,3.5,4.6,5.9,6.7,7.9c2.2,2,4.2,3.6,6.3,5.1
					c4.1,3,8.3,5.7,14,9.6c-4.3-5.5-6.5-9.9-8-14.2c-0.7-2.2-1.3-4.4-1.8-6.8C239.9,201.7,239.6,199.1,239.5,196.3z"
                  />
                </g>
                <g>
                  <path
                    d="M259.2,191.2c2.3-5.4,3.1-10.6,2.9-15.2c-0.1-1.2-0.2-2.2-0.4-3.3c-0.2-1.1-0.5-2-0.9-2.9c-0.8-1.8-1.9-3.2-3.5-4
					c-1.6-0.8-3.4-0.8-5.3,0c-1,0.4-2,1-2.9,1.8c-0.9,0.8-1.9,1.7-2.8,2.9c-3.5,4.5-5.8,11.9-4.6,19.3c1.3,7.6,3.3,12.4,5.3,17
					c2.1,4.6,4.3,9,7.7,15c-1.4-6.8-1-11.6-0.1-16C255.5,201.1,256.9,196.9,259.2,191.2z"
                  />
                </g>
                <g>
                  <path
                    d="M281.1,191.4c4.3-4.1,7.2-8,9.3-11.7c1-1.9,1.9-3.7,2.4-5.6c0.5-1.8,0.7-3.7,0-5.3c-0.6-1.6-2.1-2.9-4.3-3.4
					c-2.2-0.6-5.1-0.3-8.2,0.8c-3.1,1.2-6.3,3.3-8.9,6.2c-2.6,2.9-4.7,6.5-5.8,10.5c-1.2,4-1.6,7.4-1.8,10.4c-0.2,3-0.2,5.7-0.2,8.3
					c0.1,5.2,0.3,10.3,0,17.6c2.4-6.9,4.7-11.5,7.3-15.6c1.3-2.1,2.8-4,4.4-6C277.1,195.6,278.9,193.5,281.1,191.4z"
                  />
                </g>
                <g>
                  <path
                    d="M256.7,233.3c-1.5,3.2,1.5,6,5,5.6c3.5-0.4,5.8-3.7,3.7-6.5c-2.2-2.9-3.7-3.9-5.1-7C259.6,228.7,258.2,230,256.7,233.3z
					"
                  />
                </g>
                <g>
                  <path
                    d="M261.4,230.6c-1.4,1-1.8,2.3-1.5,3.7c0.3,1.4,1.3,2.8,2.8,3.6c1.5,0.9,3.3,0.9,4.7,0.2c1.4-0.7,2.3-2.2,2.1-4.1
					c-0.2-2-0.6-3.3-0.9-4.7c-0.3-1.3-0.6-2.6-0.7-4.4c-0.9,1.5-1.9,2.4-2.9,3.1C263.9,228.9,262.8,229.6,261.4,230.6z"
                  />
                </g>
                <g>
                  <path
                    d="M264.9,232.2c-1.3-0.6-2.1-0.2-2.9,0.7c-0.7,0.9-1.4,2.4-1.5,4.2c-0.1,1.7,0.7,3.3,2.1,4.1c1.4,0.8,3.3,0.7,4.8-0.7
					c1.5-1.4,2.2-2.7,2.8-3.8c0.6-1.2,1.2-2.3,2.1-3.6c-1.5,0.5-2.7,0.5-3.8,0.3C267.3,233.2,266.2,232.7,264.9,232.2z"
                  />
                </g>
                <g>
                  <path
                    d="M267.4,236.8c0-0.7-0.3-1.3-0.7-1.8c-0.5-0.5-1.1-1-1.9-1.2c-0.8-0.3-1.6-0.3-2.4-0.1c-0.8,0.2-1.5,0.6-2.1,1.3
					c-0.6,0.6-1.1,1.3-1.4,2c-0.3,0.7-0.6,1.4-0.6,2c0,0.7,0.1,1.3,0.5,2c0.4,0.6,1.1,1.3,2.1,1.7c1,0.5,2.1,0.8,3,1
					c0.9,0.2,1.7,0.2,2.5,0.1c1.6-0.1,3.1-0.5,4.5-1.6c-0.8,0.1-1.5-0.2-2-0.6c-0.5-0.4-0.8-0.8-1-1.3c-0.2-0.5-0.3-1-0.4-1.6
					C267.4,238,267.5,237.5,267.4,236.8z"
                  />
                </g>
                <g>
                  <path
                    d="M266.9,240c0.8-1.4,0.7-2.5-0.1-3.6c-0.7-1.1-2-2.2-3.8-2.6c-1.7-0.4-3.4,0.1-4.5,1.4c-1.1,1.3-1.4,3.5-0.4,5.4
					c1.1,2,2.1,3.1,3.2,4.1c1,1,2,2,3.2,3.4c-0.2-1.8,0-3.1,0.5-4.3C265.4,242.6,266.1,241.5,266.9,240z"
                  />
                </g>
                <g>
                  <path
                    d="M264.2,244.1c1.7-1.3,2.4-3.2,2.2-4.9c-0.2-1.7-1.4-3.1-3.1-3.7c-1.7-0.6-3.4-0.3-4.7,0.5c-1.3,0.8-2.1,2-2.1,3.8
					c0,1.9,0,3.3,0,4.7c-0.1,1.4-0.4,2.8-1.2,4.5c1.6-1,2.9-1.7,4.3-2.3C260.9,246.1,262.4,245.4,264.2,244.1z"
                  />
                </g>
                <g>
                  <path
                    d="M258.2,243.2c3.6,1.4,6.6-1.7,6.2-5.2c-0.4-3.5-4.2-5.7-7.3-3.5c-3.2,2.3-4.3,3.9-7.7,5.4
					C253,240.5,254.5,241.8,258.2,243.2z"
                  />
                </g>
                <g>
                  <path
                    d="M254.5,239.4c1.8,3.2,5.8,2.6,7.7-0.4c1.8-3,0.5-6.9-3.1-7c-3.7-0.1-5.5,0.5-8.9-0.3
					C252.5,234.3,252.7,236.1,254.5,239.4z"
                  />
                </g>
                <g>
                  <path
                    d="M255,235.1c0,1.7,1.1,2.7,2.4,3c1.3,0.3,3-0.2,4.3-1.3c1.4-1.1,2.1-2.6,2.3-3.8c0.1-1.2-0.4-2.3-1.8-2.8
					c-2.9-1-4.7-1.1-6.9-3C255.8,230.1,255,231.7,255,235.1z"
                  />
                </g>
                <g>
                  <path
                    d="M299.7,201.9c5.7-1.8,10.2-4.8,13.4-8.2c3.2-3.4,5-7.2,3.9-10.6c-1.1-3.3-5.3-5.2-10.8-4.6c-5.4,0.5-12,3.6-16.6,9.1
					c-4.7,5.7-7.1,10.1-9.4,14.4c-2.2,4.4-4.4,8.6-7.4,14.7c4.8-4.8,8.8-7.3,12.8-9.3C289.6,205.4,293.8,203.9,299.7,201.9z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M624.2,225.2c3.8,4.2,8.2,7.3,12.1,9.2c3.9,1.8,7.4,2.3,9.1,0.6c1.7-1.7,1.1-5.3-1.4-9.3c-2.6-3.9-7.1-8.1-12.8-10.5
					c-5.8-2.5-10-3.6-14.1-4.8c-4.1-1.1-8.3-2.3-14.2-4.1c5.3,3.1,8.6,5.8,11.6,8.7C617.5,217.9,620.3,220.9,624.2,225.2z"
                  />
                </g>
                <g>
                  <path
                    d="M613.5,234.5c1.7,4.7,4.2,8.8,6.9,11.6c2.7,2.8,5.6,4.4,7.9,3.4c2.2-0.9,3.3-4.2,2.6-8.5c-0.7-4.2-3.2-9.4-7.3-13.3
					c-4.2-4-7.5-6.2-10.7-8.5c-3.3-2.2-6.5-4.4-11.1-7.6c3.6,4.3,5.5,7.6,7.1,11C610.4,226.1,611.7,229.6,613.5,234.5z"
                  />
                </g>
                <g>
                  <path
                    d="M598.5,235.7c-0.3,3.7,0.5,7,2,9.6c1.5,2.5,3.7,4.3,6.1,4.2c2.4-0.1,4.5-2.2,5.4-5.4c0.9-3.2,0.5-7.6-1.7-11.3
					c-2.3-3.8-4.3-6.2-6.3-8.5c-2-2.3-3.9-4.5-6.5-7.8c1.3,4,1.6,6.8,1.6,9.7C599.1,229,598.9,231.8,598.5,235.7z"
                  />
                </g>
                <g>
                  <path
                    d="M580.4,230.2c-2.1,2.7-2.8,5.7-2.5,8.4c0.3,2.6,1.7,4.9,4,5.8c2.3,0.9,4.9,0.1,6.9-1.9c2-2,3.4-5.2,3.2-8.8
					c-0.2-3.7-0.7-6.3-1.1-8.9c-0.4-2.5-0.7-5.1-0.8-8.6c-1.4,3.3-2.8,5.4-4.3,7.4C584.2,225.6,582.5,227.5,580.4,230.2z"
                  />
                </g>
                <g>
                  <path
                    d="M567.7,221.5c-3.4,1.1-6,3-7.7,5.3c-1.7,2.3-2.5,4.9-1.5,7.2c0.9,2.2,3.6,3.5,7,3.1c3.3-0.4,7.2-2.4,9.7-5.9
					c2.6-3.6,3.8-6.4,5-9.1c1.2-2.7,2.3-5.4,4-9.1c-2.9,2.8-5.4,4.3-7.8,5.4C573.9,219.5,571.3,220.4,567.7,221.5z"
                  />
                </g>
                <g>
                  <path
                    d="M562.8,205.2c-3.5-1.1-6.6-1.1-9.4-0.5c-0.7,0.2-1.4,0.3-2,0.6c-0.6,0.3-1.2,0.6-1.7,0.9c-1.1,0.7-1.9,1.6-2.3,2.8
					c-0.4,1.1-0.3,2.4,0.3,3.6c0.3,0.6,0.7,1.2,1.3,1.8c0.6,0.6,1.2,1.1,2,1.6c3.1,1.9,8,2.8,12.5,1.3c4.6-1.6,7.4-3.3,10.1-5.1
					c2.6-1.8,5.1-3.7,8.4-6.4c-4.1,1.4-7.1,1.6-9.9,1.4C569.2,206.9,566.4,206.2,562.8,205.2z"
                  />
                </g>
                <g>
                  <path
                    d="M568.7,186.9c-2.6-3.1-5.7-5.2-8.7-6.3c-3-1.1-5.9-1.1-7.7,0.5c-1.8,1.6-1.9,4.8-0.1,8.1c1.8,3.3,5.4,6.6,10.1,8
					c4.8,1.4,8.2,1.8,11.5,2.1c3.3,0.3,6.5,0.5,11.2,0.9c-4.3-1.8-6.8-3.7-9.1-5.8C573.6,192.4,571.5,190.1,568.7,186.9z"
                  />
                </g>
                <g>
                  <path
                    d="M579.1,174.3c-1.1-4.1-3-7.4-5.1-9.9c-0.6-0.6-1.1-1.2-1.7-1.7c-0.6-0.5-1.2-0.9-1.8-1.2c-1.2-0.6-2.4-0.9-3.6-0.7
					c-1.2,0.3-2.2,1.1-2.8,2.4c-0.3,0.6-0.6,1.4-0.8,2.2c-0.2,0.8-0.3,1.8-0.2,2.8c0.1,4,2,9,5.8,12.6c3.9,3.7,7,5.5,10.1,7.2
					c3.1,1.7,6.2,3.3,10.6,5.4c-3.6-3.3-5.4-6.2-6.8-9.1C581.3,181.5,580.3,178.5,579.1,174.3z"
                  />
                </g>
                <g>
                  <path
                    d="M592.2,171.1c0.9-3.8,1.3-6.8,1.4-9.5c0.1-1.3,0-2.7-0.1-4c-0.1-1.3-0.4-2.5-1.2-3.5c-0.8-0.9-2-1.4-3.6-1.2
					c-1.6,0.2-3.6,1.2-5.3,3c-1.7,1.8-3.1,4.4-3.7,7.3c-0.6,2.9-0.6,6,0.3,8.9c0.9,3,2.1,5.2,3.3,7c1.2,1.8,2.4,3.3,3.6,4.8
					c2.4,2.9,4.9,5.5,8.1,9.5c-2-4.8-2.9-8.3-3.3-11.7c-0.2-1.7-0.3-3.4-0.3-5.2C591.6,175,591.7,173.1,592.2,171.1z"
                  />
                </g>
                <g>
                  <path
                    d="M609.4,174.5c3.1-2.2,5.3-4.3,6.9-6.6c0.8-1.1,1.5-2.3,2.1-3.5c0.5-1.2,0.8-2.4,0.6-3.6c-0.2-1.2-1.1-2.2-2.5-2.8
					c-1.4-0.6-3.3-0.8-5.5-0.4c-2.2,0.4-4.5,1.5-6.6,3.3c-2,1.7-3.8,4-4.8,6.5c-1.1,2.7-1.6,4.9-1.9,6.9c-0.3,2-0.4,3.8-0.5,5.6
					c-0.1,3.5,0,6.9-0.1,11.8c1.5-4.6,3.1-7.6,5.1-10.1c1-1.3,2-2.5,3.2-3.6C606.4,176.8,607.8,175.6,609.4,174.5z"
                  />
                </g>
                <g>
                  <path
                    d="M619.9,183.9c4-0.8,7.3-2.3,9.9-4.3c0.6-0.5,1.2-1,1.7-1.5c0.5-0.5,1-1.1,1.3-1.6c0.7-1.1,1.1-2.3,1-3.6
					c-0.2-1.2-0.9-2.3-2.1-3.1c-0.6-0.4-1.3-0.8-2.1-1c-0.8-0.2-1.7-0.4-2.7-0.5c-3.9-0.3-9.1,1.3-12.8,4.9
					c-3.8,3.7-5.8,6.7-7.7,9.6c-1.8,3-3.5,6-5.6,10.2c3.4-3.4,6.2-5.1,9.1-6.3C612.8,185.6,615.8,184.7,619.9,183.9z"
                  />
                </g>
                <g>
                  <path
                    d="M628.3,196.5c4,0.9,7.4,1,10.3,0.8c1.5-0.1,2.9-0.4,4.1-0.8c1.2-0.4,2.4-1,3.1-2c0.7-1,0.8-2.3,0.3-3.8
					c-0.5-1.5-1.8-3-3.7-4.4c-1.9-1.3-4.3-2.3-7-2.7c-2.7-0.4-5.6-0.2-8.3,0.7c-2.8,0.9-4.9,2-6.7,3c-1.8,1-3.3,2.1-4.8,3.1
					c-3,2.1-5.8,4.2-10.1,6.8c4.9-1.3,8.4-1.8,11.8-1.8c1.7,0,3.4,0,5.2,0.2C624.3,195.8,626.3,196,628.3,196.5z"
                  />
                </g>
                <g>
                  <path
                    d="M594.8,198.8c-2.4,0.4-2.8,3.2-1.3,5c1.6,1.9,4.4,1.9,5.2-0.4c0.8-2.4,0.8-3.7,2-5.7
					C598.6,198.6,597.3,198.3,594.8,198.8z"
                  />
                </g>
                <g>
                  <path
                    d="M598.2,200.4c-1.1-0.4-2-0.1-2.7,0.6c-0.7,0.7-1.1,1.8-1,3c0.1,1.2,0.8,2.2,1.7,2.7c0.9,0.5,2.2,0.5,3.2-0.4
					c1-0.9,1.7-1.6,2.3-2.3c0.6-0.7,1.3-1.4,2.2-2.1c-1.2,0-2.1-0.2-2.9-0.4C600.2,201.2,599.3,200.8,598.2,200.4z"
                  />
                </g>
                <g>
                  <path
                    d="M598.6,203c-0.2-0.9-0.7-1.3-1.5-1.4c-0.8-0.1-1.9,0.1-3,0.8c-1,0.6-1.7,1.7-1.6,2.8c0.1,1.1,0.9,2.2,2.2,2.5
					c1.4,0.3,2.4,0.2,3.3,0.1c0.9-0.1,1.8-0.2,2.9-0.2c-0.9-0.7-1.3-1.4-1.6-2.1C599,204.8,598.8,204,598.6,203z"
                  />
                </g>
                <g>
                  <path
                    d="M597,206.3c0.4-0.3,0.6-0.7,0.8-1.1c0.1-0.5,0.1-1,0-1.6c-0.1-0.5-0.4-1.1-0.9-1.4c-0.4-0.4-1-0.6-1.6-0.7
					c-0.6-0.1-1.2-0.1-1.7,0c-0.5,0.1-1,0.2-1.4,0.4c-0.4,0.2-0.7,0.6-0.9,1c-0.2,0.5-0.3,1.1-0.2,1.9c0.1,0.8,0.3,1.5,0.6,2.1
					c0.3,0.6,0.6,1.1,0.9,1.5c0.7,0.8,1.5,1.6,2.7,2c-0.4-0.5-0.5-1-0.4-1.4c0-0.4,0.2-0.8,0.4-1.1c0.2-0.3,0.5-0.6,0.7-0.9
					C596.3,206.7,596.6,206.6,597,206.3z"
                  />
                </g>
                <g>
                  <path
                    d="M594.9,207.2c1.1-0.1,1.7-0.6,2.1-1.5c0.3-0.8,0.5-2,0-3.2c-0.4-1.1-1.4-1.9-2.6-2c-1.2-0.1-2.5,0.5-3.2,1.9
					c-0.7,1.4-0.9,2.4-1.1,3.4c-0.2,1-0.4,1.9-0.7,3.2c0.9-0.8,1.8-1.2,2.7-1.4C592.9,207.4,593.8,207.3,594.9,207.2z"
                  />
                </g>
                <g>
                  <path
                    d="M591.5,207.3c1.4,0.5,2.8,0.1,3.7-0.7c0.9-0.8,1.2-2,0.9-3.2c-0.3-1.2-1.2-2.1-2.1-2.5c-0.9-0.4-2-0.4-3,0.3
					c-1.1,0.7-1.9,1.3-2.7,1.8c-0.8,0.5-1.7,0.9-3.1,1.1c1.2,0.5,2.1,1,3,1.6C589.1,206.1,590.1,206.7,591.5,207.3z"
                  />
                </g>
                <g>
                  <path
                    d="M589.7,203.4c0.6,2.6,3.6,3.1,5.4,1.5c1.8-1.6,1.7-4.6-0.8-5.5c-2.6-1-3.9-1-6.1-2.3
					C589.3,199.5,589.1,200.8,589.7,203.4z"
                  />
                </g>
                <g>
                  <path
                    d="M590.5,199.9c-1.1,2.2,0.8,4.3,3.2,4.2c2.4-0.1,4.2-2.4,2.8-4.5c-1.4-2.2-2.4-3-3.3-5.2
					C592.6,196.7,591.6,197.6,590.5,199.9z"
                  />
                </g>
                <g>
                  <path
                    d="M593.1,198.5c-0.9,0.7-1.1,1.7-0.8,2.5c0.4,0.9,1.3,1.6,2.4,2c1.2,0.3,2.3,0.2,3.1-0.2c0.8-0.4,1.2-1.1,0.9-2.1
					c-0.5-2-1.2-3.1-0.9-5.1C596.3,197,595.1,197.2,593.1,198.5z"
                  />
                </g>
                <g>
                  <path
                    d="M629.5,211.2c3.3,2.5,6.7,4,9.9,4.5c3.2,0.5,6.1,0.1,7.6-1.9c1.5-1.9,0.9-5.1-1.5-8c-2.4-2.9-6.8-5.5-11.7-6
					c-5.1-0.5-8.5-0.1-11.9,0.2c-3.4,0.4-6.7,0.8-11.3,1.5c4.6,0.9,7.6,2.2,10.3,3.7C623.6,206.8,626.1,208.6,629.5,211.2z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M241.1,523.3c3.8,4.2,8.2,7.3,12.1,9.2c3.9,1.8,7.4,2.3,9.1,0.6c1.7-1.7,1.1-5.3-1.4-9.3c-2.6-3.9-7.1-8.1-12.8-10.5
					c-5.8-2.5-10-3.6-14.1-4.8c-4.1-1.1-8.3-2.3-14.2-4.1c5.3,3.1,8.6,5.8,11.6,8.7C234.3,516,237.1,519,241.1,523.3z"
                  />
                </g>
                <g>
                  <path
                    d="M230.3,532.5c1.7,4.7,4.2,8.8,6.9,11.6c2.7,2.8,5.6,4.4,7.9,3.4c2.2-0.9,3.3-4.2,2.6-8.5c-0.7-4.2-3.2-9.4-7.3-13.3
					c-4.2-4-7.5-6.2-10.7-8.5c-3.3-2.2-6.5-4.4-11.1-7.6c3.6,4.3,5.5,7.6,7.1,11C227.3,524.1,228.6,527.6,230.3,532.5z"
                  />
                </g>
                <g>
                  <path
                    d="M215.4,533.7c-0.3,3.7,0.5,7,2,9.6c1.5,2.5,3.7,4.3,6.1,4.2c2.4-0.1,4.5-2.2,5.4-5.4c0.9-3.2,0.5-7.6-1.7-11.3
					c-2.3-3.8-4.3-6.2-6.3-8.5c-2-2.3-3.9-4.5-6.5-7.8c1.3,4,1.6,6.8,1.6,9.7C216,527.1,215.7,529.9,215.4,533.7z"
                  />
                </g>
                <g>
                  <path
                    d="M197.2,528.3c-2.1,2.7-2.8,5.7-2.5,8.4c0.3,2.6,1.7,4.9,4,5.8c2.3,0.9,4.9,0.1,6.9-1.9c2-2,3.4-5.2,3.2-8.8
					c-0.2-3.7-0.7-6.3-1.1-8.9c-0.4-2.5-0.7-5.1-0.8-8.6c-1.4,3.3-2.8,5.4-4.3,7.4C201.1,523.7,199.4,525.5,197.2,528.3z"
                  />
                </g>
                <g>
                  <path
                    d="M184.6,519.6c-3.4,1.1-6,3-7.7,5.3c-1.7,2.3-2.5,4.9-1.5,7.2c0.9,2.2,3.6,3.5,7,3.1c3.3-0.4,7.2-2.4,9.7-5.9
					c2.6-3.6,3.8-6.4,5-9.1c1.2-2.7,2.3-5.4,4-9.1c-2.9,2.8-5.4,4.3-7.8,5.4C190.7,517.6,188.1,518.4,184.6,519.6z"
                  />
                </g>
                <g>
                  <path
                    d="M179.7,503.2c-3.5-1.1-6.6-1.1-9.4-0.5c-0.7,0.2-1.4,0.3-2,0.6c-0.6,0.3-1.2,0.6-1.7,0.9c-1.1,0.7-1.9,1.6-2.3,2.8
					c-0.4,1.1-0.3,2.4,0.3,3.6c0.3,0.6,0.7,1.2,1.3,1.8c0.6,0.6,1.2,1.1,2,1.6c3.1,1.9,8,2.8,12.5,1.3c4.6-1.6,7.4-3.3,10.1-5.1
					c2.6-1.8,5.1-3.7,8.4-6.4c-4.1,1.4-7.1,1.6-9.9,1.4C186,504.9,183.3,504.3,179.7,503.2z"
                  />
                </g>
                <g>
                  <path
                    d="M185.6,484.9c-2.6-3.1-5.7-5.2-8.7-6.3c-3-1.1-5.9-1.1-7.7,0.5c-1.8,1.6-1.9,4.8-0.1,8.1c1.8,3.3,5.4,6.6,10.1,8
					c4.8,1.4,8.2,1.8,11.5,2.1c3.3,0.3,6.5,0.5,11.2,0.9c-4.3-1.8-6.8-3.7-9.1-5.8C190.4,490.4,188.4,488.1,185.6,484.9z"
                  />
                </g>
                <g>
                  <path
                    d="M195.9,472.3c-1.1-4.1-3-7.4-5.1-9.9c-0.6-0.6-1.1-1.2-1.7-1.7c-0.6-0.5-1.2-0.9-1.8-1.2c-1.2-0.6-2.4-0.9-3.6-0.7
					c-1.2,0.3-2.2,1.1-2.8,2.4c-0.3,0.6-0.6,1.4-0.8,2.2c-0.2,0.8-0.3,1.8-0.2,2.8c0.1,4,2,9,5.8,12.6c3.9,3.7,7,5.5,10.1,7.2
					c3.1,1.7,6.2,3.3,10.6,5.4c-3.6-3.3-5.4-6.2-6.8-9.1C198.2,479.5,197.1,476.5,195.9,472.3z"
                  />
                </g>
                <g>
                  <path
                    d="M209.1,469.2c0.9-3.8,1.3-6.8,1.4-9.5c0.1-1.3,0-2.7-0.1-4c-0.1-1.3-0.4-2.5-1.2-3.5c-0.8-0.9-2-1.4-3.6-1.2
					c-1.6,0.2-3.6,1.2-5.3,3c-1.7,1.8-3.1,4.4-3.7,7.3c-0.6,2.9-0.6,6,0.3,8.9c0.9,3,2.1,5.2,3.3,7c1.2,1.8,2.4,3.3,3.6,4.8
					c2.4,2.9,4.9,5.5,8.1,9.5c-2-4.8-2.9-8.3-3.3-11.7c-0.2-1.7-0.3-3.4-0.3-5.2C208.4,473,208.6,471.1,209.1,469.2z"
                  />
                </g>
                <g>
                  <path
                    d="M226.2,472.6c3.1-2.2,5.3-4.3,6.9-6.6c0.8-1.1,1.5-2.3,2.1-3.5c0.5-1.2,0.8-2.4,0.6-3.6c-0.2-1.2-1.1-2.2-2.5-2.8
					c-1.4-0.6-3.3-0.8-5.5-0.4c-2.2,0.4-4.5,1.5-6.6,3.3c-2,1.7-3.8,4-4.8,6.5c-1.1,2.7-1.6,4.9-1.9,6.9c-0.3,2-0.4,3.8-0.5,5.6
					c-0.1,3.5,0,6.9-0.1,11.8c1.5-4.6,3.1-7.6,5.1-10.1c1-1.3,2-2.5,3.2-3.6C223.3,474.9,224.6,473.7,226.2,472.6z"
                  />
                </g>
                <g>
                  <path
                    d="M236.8,481.9c4-0.8,7.3-2.3,9.9-4.3c0.6-0.5,1.2-1,1.7-1.5c0.5-0.5,1-1.1,1.3-1.6c0.7-1.1,1.1-2.3,1-3.6
					c-0.2-1.2-0.9-2.3-2.1-3.1c-0.6-0.4-1.3-0.8-2.1-1c-0.8-0.2-1.7-0.4-2.7-0.5c-3.9-0.3-9.1,1.3-12.8,4.9
					c-3.8,3.7-5.8,6.7-7.7,9.6c-1.8,3-3.5,6-5.6,10.2c3.4-3.4,6.2-5.1,9.1-6.3C229.7,483.6,232.7,482.8,236.8,481.9z"
                  />
                </g>
                <g>
                  <path
                    d="M245.2,494.6c4,0.9,7.4,1,10.3,0.8c1.5-0.1,2.9-0.4,4.1-0.8c1.2-0.4,2.4-1,3.1-2c0.7-1,0.8-2.3,0.3-3.8
					c-0.5-1.5-1.8-3-3.7-4.4c-1.9-1.3-4.3-2.3-7-2.7c-2.7-0.4-5.6-0.2-8.3,0.7c-2.8,0.9-4.9,2-6.7,3c-1.8,1-3.3,2.1-4.8,3.1
					c-3,2.1-5.8,4.2-10.1,6.8c4.9-1.3,8.4-1.8,11.8-1.8c1.7,0,3.4,0,5.2,0.2C241.2,493.9,243.1,494.1,245.2,494.6z"
                  />
                </g>
                <g>
                  <path
                    d="M211.7,496.8c-2.4,0.4-2.8,3.2-1.3,5c1.6,1.9,4.4,1.9,5.2-0.4c0.8-2.4,0.8-3.7,2-5.7
					C215.4,496.7,214.2,496.4,211.7,496.8z"
                  />
                </g>
                <g>
                  <path
                    d="M215,498.4c-1.1-0.4-2-0.1-2.7,0.6c-0.7,0.7-1.1,1.8-1,3c0.1,1.2,0.8,2.2,1.7,2.7c0.9,0.5,2.2,0.5,3.2-0.4
					c1-0.9,1.7-1.6,2.3-2.3c0.6-0.7,1.3-1.4,2.2-2.1c-1.2,0-2.1-0.2-2.9-0.4C217,499.2,216.2,498.9,215,498.4z"
                  />
                </g>
                <g>
                  <path
                    d="M215.5,501.1c-0.2-0.9-0.7-1.3-1.5-1.4c-0.8-0.1-1.9,0.1-3,0.8c-1,0.6-1.7,1.7-1.6,2.8c0.1,1.1,0.9,2.2,2.2,2.5
					c1.4,0.3,2.4,0.2,3.3,0.1c0.9-0.1,1.8-0.2,2.9-0.2c-0.9-0.7-1.3-1.4-1.6-2.1C215.9,502.9,215.7,502.1,215.5,501.1z"
                  />
                </g>
                <g>
                  <path
                    d="M213.9,504.3c0.4-0.3,0.6-0.7,0.8-1.1c0.1-0.5,0.1-1,0-1.6c-0.1-0.5-0.4-1.1-0.9-1.4c-0.4-0.4-1-0.6-1.6-0.7
					c-0.6-0.1-1.2-0.1-1.7,0c-0.5,0.1-1,0.2-1.4,0.4c-0.4,0.2-0.7,0.6-0.9,1c-0.2,0.5-0.3,1.1-0.2,1.9c0.1,0.8,0.3,1.5,0.6,2.1
					c0.3,0.6,0.6,1.1,0.9,1.5c0.7,0.8,1.5,1.6,2.7,2c-0.4-0.5-0.5-1-0.4-1.4c0-0.4,0.2-0.8,0.4-1.1c0.2-0.3,0.5-0.6,0.7-0.9
					C213.1,504.8,213.5,504.6,213.9,504.3z"
                  />
                </g>
                <g>
                  <path
                    d="M211.8,505.3c1.1-0.1,1.7-0.6,2.1-1.5c0.3-0.8,0.5-2,0-3.2c-0.4-1.1-1.4-1.9-2.6-2c-1.2-0.1-2.5,0.5-3.2,1.9
					c-0.7,1.4-0.9,2.4-1.1,3.4c-0.2,1-0.4,1.9-0.7,3.2c0.9-0.8,1.8-1.2,2.7-1.4C209.7,505.4,210.6,505.4,211.8,505.3z"
                  />
                </g>
                <g>
                  <path
                    d="M208.4,505.3c1.4,0.5,2.8,0.1,3.7-0.7c0.9-0.8,1.2-2,0.9-3.2c-0.3-1.2-1.2-2.1-2.1-2.5c-0.9-0.4-2-0.4-3,0.3
					c-1.1,0.7-1.9,1.3-2.7,1.8c-0.8,0.5-1.7,0.9-3.1,1.1c1.2,0.5,2.1,1,3,1.6C206,504.2,206.9,504.8,208.4,505.3z"
                  />
                </g>
                <g>
                  <path
                    d="M206.6,501.5c0.6,2.6,3.6,3.1,5.4,1.5c1.8-1.6,1.7-4.6-0.8-5.5c-2.6-1-3.9-1-6.1-2.3
					C206.1,497.5,205.9,498.8,206.6,501.5z"
                  />
                </g>
                <g>
                  <path
                    d="M207.3,497.9c-1.1,2.2,0.8,4.3,3.2,4.2c2.4-0.1,4.2-2.4,2.8-4.5c-1.4-2.2-2.4-3-3.3-5.2
					C209.5,494.8,208.5,495.6,207.3,497.9z"
                  />
                </g>
                <g>
                  <path
                    d="M210,496.6c-0.9,0.7-1.1,1.7-0.8,2.5c0.4,0.9,1.3,1.6,2.4,2c1.2,0.3,2.3,0.2,3.1-0.2c0.8-0.4,1.2-1.1,0.9-2.1
					c-0.5-2-1.2-3.1-0.9-5.1C213.2,495,211.9,495.2,210,496.6z"
                  />
                </g>
                <g>
                  <path
                    d="M246.4,509.3c3.3,2.5,6.7,4,9.9,4.5c3.2,0.5,6.1,0.1,7.6-1.9c1.5-1.9,0.9-5.1-1.5-8c-2.4-2.9-6.8-5.5-11.7-6
					c-5.1-0.5-8.5-0.1-11.9,0.2c-3.4,0.4-6.7,0.8-11.3,1.5c4.6,0.9,7.6,2.2,10.3,3.7C240.4,504.9,243,506.7,246.4,509.3z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M155.7,208.9c3.8,4.2,8.2,7.3,12.1,9.2c3.9,1.8,7.4,2.3,9.1,0.6c1.7-1.7,1.1-5.3-1.4-9.3c-2.6-3.9-7.1-8.1-12.8-10.5
					c-5.8-2.5-10-3.6-14.1-4.8c-4.1-1.1-8.3-2.3-14.2-4.1c5.3,3.1,8.6,5.8,11.6,8.7C148.9,201.6,151.7,204.6,155.7,208.9z"
                  />
                </g>
                <g>
                  <path
                    d="M144.9,218.2c1.7,4.7,4.2,8.8,6.9,11.6c2.7,2.8,5.6,4.4,7.9,3.4c2.2-0.9,3.3-4.2,2.6-8.5c-0.7-4.2-3.2-9.4-7.3-13.3
					c-4.2-4-7.5-6.2-10.7-8.5c-3.3-2.2-6.5-4.4-11.1-7.6c3.6,4.3,5.5,7.6,7.1,11C141.9,209.8,143.2,213.3,144.9,218.2z"
                  />
                </g>
                <g>
                  <path
                    d="M130,219.4c-0.3,3.7,0.5,7,2,9.6c1.5,2.5,3.7,4.3,6.1,4.2c2.4-0.1,4.5-2.2,5.4-5.4c0.9-3.2,0.5-7.6-1.7-11.3
					c-2.3-3.8-4.3-6.2-6.3-8.5c-2-2.3-3.9-4.5-6.5-7.8c1.3,4,1.6,6.8,1.6,9.7C130.6,212.7,130.3,215.5,130,219.4z"
                  />
                </g>
                <g>
                  <path
                    d="M111.8,214c-2.1,2.7-2.8,5.7-2.5,8.4c0.3,2.6,1.7,4.9,4,5.8c2.3,0.9,4.9,0.1,6.9-1.9c2-2,3.4-5.2,3.2-8.8
					c-0.2-3.7-0.7-6.3-1.1-8.9c-0.4-2.5-0.7-5.1-0.8-8.6c-1.4,3.3-2.8,5.4-4.3,7.4C115.7,209.3,114,211.2,111.8,214z"
                  />
                </g>
                <g>
                  <path
                    d="M99.2,205.2c-3.4,1.1-6,3-7.7,5.3c-1.7,2.3-2.5,4.9-1.5,7.2c0.9,2.2,3.6,3.5,7,3.1c3.3-0.4,7.2-2.4,9.7-5.9
					c2.6-3.6,3.8-6.4,5-9.1c1.2-2.7,2.3-5.4,4-9.1c-2.9,2.8-5.4,4.3-7.8,5.4C105.3,203.2,102.7,204.1,99.2,205.2z"
                  />
                </g>
                <g>
                  <path
                    d="M94.3,188.9c-3.5-1.1-6.6-1.1-9.4-0.5c-0.7,0.2-1.4,0.3-2,0.6c-0.6,0.3-1.2,0.6-1.7,0.9c-1.1,0.7-1.9,1.6-2.3,2.8
					c-0.4,1.1-0.3,2.4,0.3,3.6c0.3,0.6,0.7,1.2,1.3,1.8c0.6,0.6,1.2,1.1,2,1.6c3.1,1.9,8,2.8,12.5,1.3c4.6-1.6,7.4-3.3,10.1-5.1
					c2.6-1.8,5.1-3.7,8.4-6.4c-4.1,1.4-7.1,1.6-9.9,1.4C100.6,190.6,97.9,190,94.3,188.9z"
                  />
                </g>
                <g>
                  <path
                    d="M100.2,170.6c-2.6-3.1-5.7-5.2-8.7-6.3c-3-1.1-5.9-1.1-7.7,0.5c-1.8,1.6-1.9,4.8-0.1,8.1c1.8,3.3,5.4,6.6,10.1,8
					c4.8,1.4,8.2,1.8,11.5,2.1c3.3,0.3,6.5,0.5,11.2,0.9c-4.3-1.8-6.8-3.7-9.1-5.8C105,176.1,103,173.8,100.2,170.6z"
                  />
                </g>
                <g>
                  <path
                    d="M110.5,158c-1.1-4.1-3-7.4-5.1-9.9c-0.6-0.6-1.1-1.2-1.7-1.7c-0.6-0.5-1.2-0.9-1.8-1.2c-1.2-0.6-2.4-0.9-3.6-0.7
					c-1.2,0.3-2.2,1.1-2.8,2.4c-0.3,0.6-0.6,1.4-0.8,2.2c-0.2,0.8-0.3,1.8-0.2,2.8c0.1,4,2,9,5.8,12.6c3.9,3.7,7,5.5,10.1,7.2
					c3.1,1.7,6.2,3.3,10.6,5.4c-3.6-3.3-5.4-6.2-6.8-9.1C112.8,165.2,111.7,162.2,110.5,158z"
                  />
                </g>
                <g>
                  <path
                    d="M123.7,154.9c0.9-3.8,1.3-6.8,1.4-9.5c0.1-1.3,0-2.7-0.1-4c-0.1-1.3-0.4-2.5-1.2-3.5c-0.8-0.9-2-1.4-3.6-1.2
					c-1.6,0.2-3.6,1.2-5.3,3c-1.7,1.8-3.1,4.4-3.7,7.3c-0.6,2.9-0.6,6,0.3,8.9c0.9,3,2.1,5.2,3.3,7c1.2,1.8,2.4,3.3,3.6,4.8
					c2.4,2.9,4.9,5.5,8.1,9.5c-2-4.8-2.9-8.3-3.3-11.7c-0.2-1.7-0.3-3.4-0.3-5.2C123,158.7,123.2,156.8,123.7,154.9z"
                  />
                </g>
                <g>
                  <path
                    d="M140.8,158.2c3.1-2.2,5.3-4.3,6.9-6.6c0.8-1.1,1.5-2.3,2.1-3.5c0.5-1.2,0.8-2.4,0.6-3.6c-0.2-1.2-1.1-2.2-2.5-2.8
					c-1.4-0.6-3.3-0.8-5.5-0.4c-2.2,0.4-4.5,1.5-6.6,3.3c-2,1.7-3.8,4-4.8,6.5c-1.1,2.7-1.6,4.9-1.9,6.9c-0.3,2-0.4,3.8-0.5,5.6
					c-0.1,3.5,0,6.9-0.1,11.8c1.5-4.6,3.1-7.6,5.1-10.1c1-1.3,2-2.5,3.2-3.6C137.9,160.6,139.2,159.4,140.8,158.2z"
                  />
                </g>
                <g>
                  <path
                    d="M151.4,167.6c4-0.8,7.3-2.3,9.9-4.3c0.6-0.5,1.2-1,1.7-1.5c0.5-0.5,1-1.1,1.3-1.6c0.7-1.1,1.1-2.3,1-3.6
					c-0.2-1.2-0.9-2.3-2.1-3.1c-0.6-0.4-1.3-0.8-2.1-1c-0.8-0.2-1.7-0.4-2.7-0.5c-3.9-0.3-9.1,1.3-12.8,4.9
					c-3.8,3.7-5.8,6.7-7.7,9.6c-1.8,3-3.5,6-5.6,10.2c3.4-3.4,6.2-5.1,9.1-6.3C144.2,169.3,147.3,168.5,151.4,167.6z"
                  />
                </g>
                <g>
                  <path
                    d="M159.8,180.2c4,0.9,7.4,1,10.3,0.8c1.5-0.1,2.9-0.4,4.1-0.8c1.2-0.4,2.4-1,3.1-2c0.7-1,0.8-2.3,0.3-3.8
					c-0.5-1.5-1.8-3-3.7-4.4c-1.9-1.3-4.3-2.3-7-2.7c-2.7-0.4-5.6-0.2-8.3,0.7c-2.8,0.9-4.9,2-6.7,3c-1.8,1-3.3,2.1-4.8,3.1
					c-3,2.1-5.8,4.2-10.1,6.8c4.9-1.3,8.4-1.8,11.8-1.8c1.7,0,3.4,0,5.2,0.2C155.8,179.5,157.7,179.8,159.8,180.2z"
                  />
                </g>
                <g>
                  <path
                    d="M126.3,182.5c-2.4,0.4-2.8,3.2-1.3,5c1.6,1.9,4.4,1.9,5.2-0.4c0.8-2.4,0.8-3.7,2-5.7C130,182.3,128.8,182.1,126.3,182.5
					z"
                  />
                </g>
                <g>
                  <path
                    d="M129.6,184.1c-1.1-0.4-2-0.1-2.7,0.6c-0.7,0.7-1.1,1.8-1,3c0.1,1.2,0.8,2.2,1.7,2.7c0.9,0.5,2.2,0.5,3.2-0.4
					c1-0.9,1.7-1.6,2.3-2.3c0.6-0.7,1.3-1.4,2.2-2.1c-1.2,0-2.1-0.2-2.9-0.4C131.6,184.9,130.8,184.5,129.6,184.1z"
                  />
                </g>
                <g>
                  <path
                    d="M130.1,186.7c-0.2-0.9-0.7-1.3-1.5-1.4c-0.8-0.1-1.9,0.1-3,0.8c-1,0.6-1.7,1.7-1.6,2.8c0.1,1.1,0.9,2.2,2.2,2.5
					c1.4,0.3,2.4,0.2,3.3,0.1c0.9-0.1,1.8-0.2,2.9-0.2c-0.9-0.7-1.3-1.4-1.6-2.1C130.5,188.5,130.3,187.7,130.1,186.7z"
                  />
                </g>
                <g>
                  <path
                    d="M128.5,190c0.4-0.3,0.6-0.7,0.8-1.1c0.1-0.5,0.1-1,0-1.6c-0.1-0.5-0.4-1.1-0.9-1.4c-0.4-0.4-1-0.6-1.6-0.7
					c-0.6-0.1-1.2-0.1-1.7,0c-0.5,0.1-1,0.2-1.4,0.4c-0.4,0.2-0.7,0.6-0.9,1c-0.2,0.5-0.3,1.1-0.2,1.9c0.1,0.8,0.3,1.5,0.6,2.1
					c0.3,0.6,0.6,1.1,0.9,1.5c0.7,0.8,1.5,1.6,2.7,2c-0.4-0.5-0.5-1-0.4-1.4c0-0.4,0.2-0.8,0.4-1.1c0.2-0.3,0.5-0.6,0.7-0.9
					C127.7,190.4,128.1,190.3,128.5,190z"
                  />
                </g>
                <g>
                  <path
                    d="M126.4,190.9c1.1-0.1,1.7-0.6,2.1-1.5c0.3-0.8,0.5-2,0-3.2c-0.4-1.1-1.4-1.9-2.6-2c-1.2-0.1-2.5,0.5-3.2,1.9
					c-0.7,1.4-0.9,2.4-1.1,3.4c-0.2,1-0.4,1.9-0.7,3.2c0.9-0.8,1.8-1.2,2.7-1.4C124.3,191.1,125.2,191,126.4,190.9z"
                  />
                </g>
                <g>
                  <path
                    d="M123,191c1.4,0.5,2.8,0.1,3.7-0.7c0.9-0.8,1.2-2,0.9-3.2c-0.3-1.2-1.2-2.1-2.1-2.5c-0.9-0.4-2-0.4-3,0.3
					c-1.1,0.7-1.9,1.3-2.7,1.8c-0.8,0.5-1.7,0.9-3.1,1.1c1.2,0.5,2.1,1,3,1.6C120.6,189.9,121.5,190.4,123,191z"
                  />
                </g>
                <g>
                  <path
                    d="M121.2,187.2c0.6,2.6,3.6,3.1,5.4,1.5c1.8-1.6,1.7-4.6-0.8-5.5c-2.6-1-3.9-1-6.1-2.3
					C120.7,183.2,120.5,184.5,121.2,187.2z"
                  />
                </g>
                <g>
                  <path
                    d="M121.9,183.6c-1.1,2.2,0.8,4.3,3.2,4.2c2.4-0.1,4.2-2.4,2.8-4.5c-1.4-2.2-2.4-3-3.3-5.2
					C124.1,180.4,123.1,181.3,121.9,183.6z"
                  />
                </g>
                <g>
                  <path
                    d="M124.6,182.2c-0.9,0.7-1.1,1.7-0.8,2.5c0.4,0.9,1.3,1.6,2.4,2c1.2,0.3,2.3,0.2,3.1-0.2c0.8-0.4,1.2-1.1,0.9-2.1
					c-0.5-2-1.2-3.1-0.9-5.1C127.8,180.7,126.5,180.9,124.6,182.2z"
                  />
                </g>
                <g>
                  <path
                    d="M161,194.9c3.3,2.5,6.7,4,9.9,4.5c3.2,0.5,6.1,0.1,7.6-1.9c1.5-1.9,0.9-5.1-1.5-8c-2.4-2.9-6.8-5.5-11.7-6
					c-5.1-0.5-8.5-0.1-11.9,0.2c-3.4,0.4-6.7,0.8-11.3,1.5c4.6,0.9,7.6,2.2,10.3,3.7C155,190.5,157.6,192.3,161,194.9z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M353.7,131.6c-6.1,1.4-12.7,4.3-17.8,8c-2.6,1.8-4.9,3.9-7,6c-2,2.1-3.7,4.3-5.1,6.4c-2.8,4.3-4.2,8.4-4.6,11.5
					c-0.2,1.6-0.1,2.9,0.2,3.9c0.3,1,0.9,1.8,1.6,2.2c0.8,0.4,1.7,0.6,2.7,0.4c1-0.2,2.2-0.6,3.5-1.2c2.5-1.2,5.5-3.2,8.8-5.5
					c1.6-1.1,3.4-2.3,5.2-3.6c1.8-1.2,3.8-2.5,5.8-3.8c4-2.7,8.2-5.1,13.1-8c5-3,9.8-5.5,14-8.2c4.2-2.6,8-5.4,11.3-8.5
					c1.7-1.5,3.3-3.1,4.8-4.8c1.6-1.7,3.1-3.5,4.6-5.5c3-3.9,6-8.5,8.6-14.3c-3.6,5.3-7.4,9-11.1,11.8c-1.9,1.4-3.7,2.6-5.5,3.7
					c-1.8,1.1-3.7,2-5.5,2.7c-3.7,1.6-7.5,2.6-11.9,3.5C365,129.4,360,130.1,353.7,131.6z"
                  />
                </g>
                <g>
                  <path
                    d="M362.6,150.6c-5,2.2-10,4.1-14.7,5.9c-4.7,1.7-9.2,3.3-13.1,4.7c-4,1.4-7.4,2.8-9.9,4.2c-2.5,1.4-4.1,2.9-4.3,4.7
					c-0.1,1.7,1.3,3.5,4.2,5c2.8,1.5,7.1,2.5,12.3,2.7c5.2,0.2,11.2-0.6,17.4-2.6c6.1-2.1,12.3-5.3,17.6-9.8
					c5.4-4.6,9.3-9,12.4-13.3c3.1-4.3,5.4-8.3,7.5-12.4c2-4.1,3.8-8.4,5.4-13.2c1.6-4.8,3-10.2,4-16.7c-2,6.2-4.5,11.1-7.2,15.2
					c-2.6,4.1-5.4,7.4-8.4,10.3c-3,3-6.2,5.6-10,8.1C372,145.9,367.8,148.3,362.6,150.6z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M401.3,173.5c-6-1.9-13.1-2.8-19.4-2.2c-3.2,0.3-6.2,0.8-9,1.6c-2.8,0.8-5.4,1.8-7.7,2.9c-4.6,2.3-7.9,5-9.8,7.5
					c-1,1.2-1.6,2.4-1.8,3.5c-0.3,1.1-0.2,2,0.3,2.7c0.4,0.8,1.2,1.3,2.2,1.7c1,0.4,2.2,0.6,3.6,0.8c2.8,0.2,6.3,0.1,10.3-0.2
					c2-0.1,4.1-0.3,6.4-0.4c2.2-0.1,4.5-0.3,6.9-0.4c4.8-0.2,9.7-0.2,15.4-0.2c5.9,0,11.2,0.3,16.2,0.1c5-0.1,9.7-0.6,14.1-1.5
					c2.2-0.4,4.4-1,6.6-1.7c2.2-0.7,4.4-1.4,6.7-2.4c4.6-1.9,9.5-4.3,14.7-7.9c-5.8,2.7-10.9,3.9-15.6,4.5c-2.3,0.3-4.5,0.4-6.7,0.3
					c-2.1,0-4.2-0.2-6.2-0.5c-4-0.5-7.8-1.6-12-3C412.2,177.4,407.6,175.5,401.3,173.5z"
                  />
                </g>
                <g>
                  <path
                    d="M399.3,194.3c-5.4-0.7-10.7-1.6-15.6-2.5c-4.9-0.9-9.6-1.9-13.7-2.7c-4.1-0.8-7.8-1.4-10.7-1.5
					c-2.9-0.1-5.1,0.4-6.1,1.9c-1,1.4-0.6,3.7,1.1,6.4c1.7,2.7,4.8,5.8,9.2,8.6c4.3,2.8,9.9,5.3,16.3,6.7c6.3,1.4,13.3,1.8,20.1,0.6
					c7-1.1,12.6-3,17.5-5c4.8-2.1,8.9-4.4,12.8-6.9c3.9-2.5,7.6-5.3,11.4-8.6c3.8-3.3,7.8-7.3,12-12.3c-4.9,4.3-9.6,7.2-13.9,9.4
					c-4.4,2.2-8.4,3.5-12.5,4.6c-4.1,1-8.2,1.6-12.7,1.9C409.8,195.2,404.9,195.1,399.3,194.3z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M143.2,295.9c4-4.8,7.5-11.1,9.3-17.2c0.9-3.1,1.6-6.1,1.9-9c0.3-2.9,0.3-5.7,0.2-8.2c-0.4-5.1-1.7-9.2-3.3-11.9
					c-0.8-1.4-1.7-2.4-2.6-3c-0.9-0.6-1.8-0.9-2.6-0.8c-0.9,0.1-1.7,0.6-2.4,1.3c-0.7,0.8-1.4,1.8-2,3.1c-1.3,2.5-2.4,5.9-3.7,9.7
					c-0.6,1.9-1.3,3.9-2,6.1c-0.7,2.1-1.4,4.3-2.2,6.6c-1.6,4.6-3.4,9-5.5,14.3c-2.2,5.4-4.4,10.3-6.2,15c-1.7,4.7-3.1,9.2-3.8,13.6
					c-0.4,2.2-0.7,4.5-0.9,6.8c-0.2,2.3-0.3,4.7-0.3,7.1c0,4.9,0.4,10.4,1.8,16.6c-0.4-6.3,0.4-11.6,1.6-16.1
					c0.6-2.3,1.3-4.4,2.2-6.3c0.8-2,1.7-3.8,2.7-5.5c2-3.5,4.4-6.6,7.3-10C135.6,304.6,139.1,300.9,143.2,295.9z"
                  />
                </g>
                <g>
                  <path
                    d="M124.7,286.2c2.7-4.8,5.5-9.3,8.1-13.6c2.7-4.3,5.3-8.2,7.6-11.7c2.3-3.5,4.2-6.7,5.4-9.3c1.2-2.6,1.5-4.9,0.5-6.3
					c-1-1.5-3.2-2-6.4-1.4c-3.2,0.6-7.2,2.3-11.4,5.3c-4.2,3-8.6,7.3-12.2,12.6c-3.6,5.4-6.6,11.7-8.1,18.4
					c-1.5,6.9-1.9,12.8-1.8,18.1c0.1,5.3,0.7,9.9,1.6,14.4c0.9,4.5,2.1,9,3.7,13.8c1.7,4.8,3.8,9.9,7,15.7
					c-2.2-6.2-3.2-11.6-3.5-16.4c-0.4-4.8-0.2-9.2,0.4-13.3c0.6-4.2,1.6-8.2,3-12.5C120,295.7,121.9,291.1,124.7,286.2z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M453.4,473.1c-7.8,2.4-14.6,6.2-19.4,10.2c-4.8,4-7.6,8.2-6.4,11.6c1.2,3.3,6.3,4.8,13,3.8c6.7-1,14.9-4.4,21.5-10.4
					c6.8-6.1,10.9-10.9,14.9-15.6c4-4.7,8-9.4,14-16.1c-7.3,5.1-12.9,7.7-18.4,9.9C467.1,468.8,461.4,470.6,453.4,473.1z"
                  />
                </g>
                <g>
                  <path
                    d="M447.9,453.4c-7.2-0.7-14.1,0.2-19.4,2c-5.3,1.8-9.1,4.7-9.3,8.2c-0.2,3.5,3.5,6.8,9.5,8.5c6,1.7,14.2,1.6,21.8-1.4
					c7.8-3.1,12.7-6,17.6-8.9c4.9-2.9,9.7-5.8,16.7-9.9c-7.8,2.1-13.3,2.5-18.7,2.5C460.7,454.5,455.3,454.1,447.9,453.4z"
                  />
                </g>
                <g>
                  <path
                    d="M455.3,433.2c-4.7-2.7-9.5-3.7-13.7-3.3c-4.2,0.4-7.9,2.2-9.3,5.4c-1.4,3.2,0.1,7.2,3.8,10.4c3.7,3.1,9.6,5.3,15.9,4.7
					c6.4-0.7,10.7-1.9,14.9-3.1c4.2-1.2,8.3-2.4,14.1-3.8c-6-0.7-9.9-2.1-13.6-3.8C463.7,438,460.2,435.9,455.3,433.2z"
                  />
                </g>
                <g>
                  <path
                    d="M473.5,412.7c-2.2-4.4-5.7-7.2-9.4-8.4c-3.7-1.2-7.5-0.8-10.1,1.7c-2.6,2.4-3.1,6.3-1.8,10.2c1.4,3.9,4.7,7.6,9.6,9.6
					c5,2,8.7,2.9,12.3,3.9c3.6,1,7.1,2.1,11.8,4.3c-3.4-3.8-5.3-7-7-10.2C477.3,420.6,475.8,417.2,473.5,412.7z"
                  />
                </g>
                <g>
                  <path
                    d="M492.7,401.5c0.7-5.1-0.3-9.7-2.2-13.3c-1.9-3.6-4.9-6.3-8.4-6.4c-3.5-0.1-6.7,2.6-8.3,7.2c-1.5,4.5-1.3,10.8,1.8,16.3
					c3.2,5.6,6.1,8.9,8.9,12.1c2.8,3.2,5.6,6.3,9.4,10.8c-1.9-5.5-2.3-9.6-2.3-13.6C491.6,410.7,492,406.8,492.7,401.5z"
                  />
                </g>
                <g>
                  <path
                    d="M517.1,405c3.5-3.9,5.5-8,6.4-12c0.2-1,0.4-2,0.4-3c0-1,0-1.9-0.1-2.8c-0.3-1.8-1-3.5-2.2-4.7c-1.3-1.2-2.9-1.9-4.9-1.8
					c-1,0-2.1,0.2-3.1,0.6c-1.1,0.4-2.2,0.9-3.3,1.6c-4.4,2.8-8.5,8.7-9.3,15.5c-0.8,7-0.1,11.7,0.6,16.3c0.8,4.5,1.7,8.9,3.2,15
					c0.6-6.2,2.3-10.2,4.3-13.8C511,412.3,513.5,409,517.1,405z"
                  />
                </g>
                <g>
                  <path
                    d="M537.4,423.9c5.7-1.6,10.3-4.3,13.5-7.6c3.2-3.2,5.1-7.1,4.1-10.4c-1-3.3-5.1-5.3-10.5-5c-5.4,0.3-11.9,3.1-16.6,8.4
					c-4.8,5.4-7.3,9.6-9.8,13.8c-2.3,4.1-4.7,8.2-8.1,14.1c5-4.5,9-6.7,13.1-8.4C527.3,426.9,531.5,425.6,537.4,423.9z"
                  />
                </g>
                <g>
                  <path
                    d="M547.6,445.1c6,1,11.5,0.6,16-0.7c1.1-0.4,2.2-0.7,3.2-1.2c1-0.5,1.9-1,2.7-1.6c1.6-1.2,2.7-2.6,3.1-4.4
					c0.4-1.7-0.1-3.5-1.4-5.2c-0.6-0.8-1.4-1.7-2.5-2.4c-1-0.7-2.2-1.5-3.5-2c-5.2-2.3-13-2.8-20,0c-7.2,2.9-11.5,5.9-15.7,8.8
					c-4.1,3-8.1,6.1-13.5,10.5c6.6-2.7,11.4-3.3,16-3.4C536.8,443.6,541.4,444.1,547.6,445.1z"
                  />
                </g>
                <g>
                  <path
                    d="M543.7,464.2c4.4,3.5,8.1,5.9,11.5,7.7c1.7,0.9,3.5,1.7,5.2,2.3c1.7,0.6,3.5,1,5.3,0.6c1.7-0.4,3.1-1.7,3.8-4
					c0.7-2.2,0.6-5.4-0.8-8.8c-1.3-3.3-3.9-6.8-7.2-9.3c-3.4-2.6-7.5-4.4-11.9-5c-4.5-0.6-8.1-0.4-11.2,0c-3.1,0.4-5.8,1.1-8.4,1.8
					c-5.2,1.4-10.1,3.1-17.4,4.9c7.5,0.3,12.6,1.2,17.3,2.7c2.3,0.8,4.6,1.7,6.9,2.8C539.1,461,541.5,462.4,543.7,464.2z"
                  />
                </g>
                <g>
                  <path
                    d="M528.8,484.6c1,5.4,2.5,9.5,4.4,13.1c1,1.8,2.1,3.4,3.3,4.8c1.2,1.4,2.7,2.5,4.4,2.9c1.7,0.4,3.5,0,5.2-1.5
					c1.7-1.4,3.1-3.9,3.9-7c0.7-3.1,0.7-6.9-0.2-10.6c-1-3.7-2.9-7.4-5.6-10.3c-2.8-3.1-5.4-5.1-7.9-6.8c-2.5-1.7-4.7-2.9-7-4.1
					c-4.6-2.3-9.1-4.3-15.4-7.3c5.1,4.8,8,8.7,10.2,12.8c1.1,2,2,4.1,2.8,6.4C527.6,479.3,528.3,481.8,528.8,484.6z"
                  />
                </g>
                <g>
                  <path
                    d="M510.1,492.7c-1.4,5.7-1.4,11-0.4,15.5c0.3,1.1,0.5,2.2,0.9,3.2c0.4,1,0.8,1.9,1.3,2.7c1,1.6,2.4,2.9,4.1,3.4
					c1.7,0.5,3.5,0.2,5.3-0.9c0.9-0.5,1.8-1.3,2.6-2.2c0.8-0.9,1.6-2,2.3-3.3c2.7-5,3.8-12.7,1.5-19.8c-2.4-7.3-5.2-11.7-7.9-15.9
					c-2.8-4.2-5.7-8.2-10-13.6c2.4,6.5,2.8,11.3,2.7,15.8C512.2,482.3,511.5,486.8,510.1,492.7z"
                  />
                </g>
                <g>
                  <path
                    d="M488.5,496c-3.6,4.8-5.9,9-7.3,13c-0.7,2-1.3,4-1.5,5.9c-0.2,1.9-0.1,3.7,0.8,5.3c0.9,1.5,2.5,2.5,4.7,2.7
					c2.2,0.2,5.1-0.5,7.9-2.1c2.8-1.6,5.7-4.3,7.8-7.6c2.1-3.3,3.6-7.2,4.1-11.2c0.5-4.2,0.4-7.6,0.2-10.6c-0.3-3-0.7-5.6-1.1-8.2
					c-0.9-5.1-1.9-10.1-2.8-17.4c-1.3,7.2-2.8,12.1-4.8,16.5c-1,2.2-2.1,4.4-3.4,6.7C491.8,491.2,490.3,493.6,488.5,496z"
                  />
                </g>
                <g>
                  <path
                    d="M505.9,450.8c0.9-3.4-2.4-5.6-5.8-4.7c-3.4,0.9-5.2,4.5-2.7,7c2.6,2.5,4.3,3.3,6.2,6.1
					C503.8,455.8,505,454.3,505.9,450.8z"
                  />
                </g>
                <g>
                  <path
                    d="M501.8,454.2c1.2-1.2,1.4-2.6,0.9-3.9c-0.5-1.3-1.7-2.5-3.4-3.1c-1.7-0.6-3.4-0.4-4.6,0.6c-1.2,0.9-1.9,2.6-1.4,4.4
					c0.5,1.9,1.1,3.2,1.6,4.5c0.5,1.3,1,2.5,1.4,4.2c0.7-1.6,1.5-2.6,2.4-3.6C499.5,456.3,500.5,455.4,501.8,454.2z"
                  />
                </g>
                <g>
                  <path
                    d="M498,453.2c1.3,0.3,2.1-0.1,2.7-1.2c0.6-1,1-2.6,0.8-4.4c-0.2-1.7-1.2-3.2-2.7-3.8c-1.5-0.6-3.4-0.2-4.6,1.4
					c-1.2,1.6-1.7,3-2.2,4.2c-0.4,1.3-0.8,2.4-1.5,3.9c1.4-0.8,2.6-0.9,3.7-0.9C495.5,452.6,496.6,452.9,498,453.2z"
                  />
                </g>
                <g>
                  <path
                    d="M494.8,449.1c0.1,0.7,0.5,1.2,1,1.7c0.5,0.4,1.3,0.8,2.1,0.9c0.8,0.1,1.7,0.1,2.4-0.3c0.8-0.3,1.4-0.9,1.9-1.6
					c0.5-0.7,0.8-1.5,1.1-2.2c0.2-0.7,0.3-1.4,0.3-2.1c-0.1-0.7-0.3-1.3-0.8-1.8c-0.5-0.6-1.3-1.1-2.4-1.4c-1.1-0.3-2.2-0.5-3.1-0.5
					c-0.9,0-1.7,0.1-2.5,0.3c-1.5,0.4-3,0.9-4.2,2.3c0.8-0.2,1.5,0,2.1,0.3c0.5,0.3,0.9,0.7,1.2,1.2c0.3,0.5,0.5,1,0.7,1.5
					C494.6,447.8,494.6,448.4,494.8,449.1z"
                  />
                </g>
                <g>
                  <path
                    d="M494.8,445.7c-0.6,1.5-0.3,2.6,0.6,3.6c0.9,1,2.4,1.8,4.1,2c1.7,0.2,3.4-0.6,4.2-2.1c0.9-1.5,0.9-3.7-0.5-5.4
					c-1.4-1.8-2.6-2.7-3.8-3.6c-1.2-0.9-2.3-1.7-3.7-2.8c0.5,1.8,0.5,3.1,0.2,4.4C495.9,443,495.4,444.2,494.8,445.7z"
                  />
                </g>
                <g>
                  <path
                    d="M496.9,441.3c-1.5,1.5-1.9,3.6-1.4,5.2c0.5,1.6,1.9,2.8,3.6,3.1c1.7,0.3,3.4-0.2,4.5-1.2c1.1-1,1.7-2.3,1.5-4.1
					c-0.3-1.8-0.6-3.2-0.7-4.6c-0.1-1.4-0.1-2.8,0.5-4.7c-1.5,1.3-2.6,2.1-3.9,3C499.7,438.9,498.4,439.7,496.9,441.3z"
                  />
                </g>
                <g>
                  <path d="M502.9,441.2c-3.7-0.8-6.3,2.7-5.3,6.1c1,3.4,5,5,7.7,2.3c2.8-2.8,3.6-4.5,6.8-6.6C508.4,443.1,506.8,442,502.9,441.2z" />
                </g>
                <g>
                  <path
                    d="M507.1,444.4c-2.2-2.9-6.2-1.6-7.5,1.7c-1.3,3.3,0.6,6.9,4.2,6.5c3.7-0.5,5.4-1.4,8.9-1.1
					C510,449.2,509.4,447.3,507.1,444.4z"
                  />
                </g>
                <g>
                  <path
                    d="M507.3,448.7c-0.3-1.6-1.5-2.5-2.9-2.6c-1.4-0.1-2.9,0.6-4.1,2c-1.2,1.3-1.7,2.9-1.7,4.1c0.1,1.3,0.7,2.2,2.2,2.4
					c3,0.6,4.8,0.4,7.3,1.9C507.4,453.8,507.9,452.1,507.3,448.7z"
                  />
                </g>
                <g>
                  <path
                    d="M468.5,488.6c-5.3,2.7-9.3,6.4-11.9,10.2c-2.6,3.8-3.8,7.9-2.1,11.1c1.6,3.1,6.1,4.3,11.4,2.9c5.3-1.4,11.3-5.5,15-11.7
					c3.7-6.3,5.4-11.1,7-15.7c1.5-4.7,3-9.2,5-15.7c-3.9,5.5-7.5,8.6-11.2,11.3C477.9,483.5,474,485.7,468.5,488.6z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M426.3,566.6c5.8-2.4,11.9-6.2,16.3-10.7c2.3-2.2,4.3-4.6,5.9-7c1.7-2.4,3-4.8,4-7.2c2.1-4.7,2.9-8.9,2.7-12.1
					c-0.1-1.6-0.4-2.9-0.8-3.9c-0.5-1-1.1-1.6-2-1.9c-0.8-0.3-1.8-0.3-2.8,0c-1,0.3-2.1,0.9-3.2,1.7c-2.3,1.6-4.9,4-7.8,6.8
					c-1.4,1.4-3,2.8-4.6,4.4c-1.6,1.5-3.3,3.1-5.1,4.7c-3.5,3.3-7.3,6.4-11.7,10c-4.5,3.7-8.8,7-12.5,10.3c-3.8,3.3-7,6.6-9.9,10.2
					c-1.4,1.8-2.7,3.6-4,5.5c-1.3,1.9-2.5,4-3.7,6.1c-2.3,4.4-4.5,9.3-6.2,15.5c2.7-5.8,5.9-10,9.1-13.4c1.6-1.7,3.2-3.2,4.9-4.5
					c1.6-1.3,3.3-2.5,5-3.6c3.4-2.1,7-3.8,11.2-5.4C415.5,570.6,420.3,569,426.3,566.6z"
                  />
                </g>
                <g>
                  <path
                    d="M414.5,549.3c4.6-2.9,9.2-5.6,13.6-8.1c4.4-2.5,8.6-4.7,12.2-6.7c3.7-2,6.9-3.9,9.1-5.7c2.3-1.8,3.6-3.6,3.5-5.3
					c-0.2-1.7-1.9-3.3-4.9-4.3c-3-1-7.4-1.4-12.6-0.7c-5.1,0.6-11,2.3-16.8,5.3c-5.7,3-11.4,7.2-15.8,12.4
					c-4.6,5.4-7.8,10.4-10.2,15.1c-2.4,4.7-4.1,9.1-5.4,13.5c-1.4,4.4-2.4,8.9-3.2,13.9c-0.8,5-1.3,10.6-1.3,17.1
					c1-6.5,2.7-11.7,4.7-16.1c2-4.4,4.2-8.1,6.7-11.5c2.5-3.4,5.3-6.5,8.5-9.6C405.9,555.4,409.8,552.3,414.5,549.3z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M372.6,532.7c6.2,0.9,13.4,0.7,19.5-0.9c3.1-0.8,6-1.8,8.7-3c2.7-1.2,5-2.6,7.1-4.1c4.2-3,7-6.2,8.5-9
					c0.7-1.4,1.2-2.7,1.2-3.7c0.1-1.1-0.1-2-0.7-2.7c-0.5-0.7-1.4-1.1-2.4-1.4c-1-0.2-2.3-0.3-3.7-0.2c-2.8,0.2-6.3,0.9-10.2,1.8
					c-1.9,0.4-4,0.9-6.2,1.4c-2.2,0.5-4.4,1-6.8,1.5c-4.7,1-9.5,1.8-15.1,2.6c-5.8,0.9-11.1,1.5-16,2.4c-4.9,0.9-9.4,2.1-13.7,3.7
					c-2.1,0.8-4.2,1.7-6.3,2.7c-2.1,1-4.2,2.1-6.3,3.4c-4.2,2.6-8.7,5.7-13.3,10.1c5.3-3.6,10.2-5.6,14.7-6.9
					c2.3-0.6,4.4-1.1,6.5-1.4c2.1-0.3,4.1-0.5,6.2-0.5c4-0.1,7.9,0.3,12.4,1.1C361.2,530.7,366.2,531.8,372.6,532.7z"
                  />
                </g>
                <g>
                  <path
                    d="M371.3,511.9c5.5-0.2,10.8-0.1,15.8,0c5,0.1,9.8,0.3,14,0.5c4.2,0.1,7.9,0.1,10.8-0.2c2.9-0.4,4.9-1.2,5.7-2.8
					c0.7-1.6,0.1-3.8-2.1-6.2c-2.1-2.4-5.7-5-10.4-7.1c-4.7-2.1-10.7-3.6-17.1-4c-6.5-0.3-13.4,0.4-20,2.6c-6.7,2.2-12,4.9-16.5,7.7
					c-4.4,2.8-8.1,5.7-11.6,8.8c-3.4,3.1-6.6,6.4-9.9,10.3c-3.2,3.9-6.6,8.4-9.8,14c4.2-5,8.3-8.7,12.2-11.5c4-2.8,7.8-4.8,11.6-6.5
					c3.9-1.6,7.8-2.9,12.2-3.9C360.8,512.7,365.7,512,371.3,511.9z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M514.4,139c10.6-1.1,21.3-3.7,30.7-7.7c9.4-3.9,17.6-9.3,23.7-14.8c6.1-5.5,10.2-11.1,12.5-15.3
					c2.3-4.2,2.9-6.9,2.1-7.4c-1.6-1-7.7,7.5-19.9,16.2c-6.1,4.3-13.6,8.7-22.2,12.2c-8.6,3.5-18.2,6.3-28.2,8.1
					c-10.2,1.9-19.1,2.6-27.2,3.2c-8.1,0.6-15.5,1.1-23,1.9c-7.4,0.8-14.9,1.9-23.1,4.1c-4.1,1.1-8.3,2.5-12.7,4.4
					c-4.4,1.9-8.9,4.3-13.4,7.6c9.3-6,18.4-8.6,26.5-10.2c8.2-1.5,15.6-1.9,22.9-2c7.4-0.1,14.7,0.2,22.9,0.5
					C494.2,139.9,503.4,140.1,514.4,139z"
                  />
                </g>
                <g>
                  <path
                    d="M458.8,128.6c2.7-1.5,4.5-4,5.1-6.3c0.6-2.3,0-4.4-1.5-5.3c-1.5-0.9-3.5-0.6-5.3,0.4c-1.8,1-3.5,2.7-4.6,5.2
					c-1.1,2.5-2,4.3-3,6c-1,1.7-2.2,3.4-4.4,5.4c2.8-1.1,4.7-1.7,6.8-2.4C454,130.8,456,130.1,458.8,128.6z"
                  />
                </g>
                <g>
                  <path
                    d="M475.9,123.1c2.3-1.3,3.6-3.6,3.8-5.6c0.2-2.1-0.6-3.8-2.1-4.7c-1.6-0.8-3.4-0.6-5,0.2c-1.6,0.8-2.9,2.3-3.5,4.4
					c-0.7,2.2-1.1,3.8-1.7,5.4c-0.6,1.6-1.4,3.1-3,5c2.3-1,3.9-1.6,5.6-2.2C471.8,125,473.6,124.4,475.9,123.1z"
                  />
                </g>
                <g>
                  <path
                    d="M493,119.2c2.2-1.6,3.5-4,3.7-6.1c0.2-2.1-0.5-4-2.1-4.8c-1.5-0.8-3.5-0.5-5.1,0.5c-1.7,1.1-3.1,2.9-3.7,5.3
					c-0.6,2.5-1,4.3-1.5,6.1c-0.5,1.8-1.2,3.6-2.5,5.8c2.2-1.6,3.8-2.5,5.5-3.5C488.9,121.7,490.7,120.8,493,119.2z"
                  />
                </g>
                <g>
                  <path
                    d="M512.9,117.7c2.4-2.2,3.5-5.3,3.4-7.8c-0.1-2.5-1.3-4.3-3-4.6c-1.7-0.4-3.5,0.6-4.9,2c-1.4,1.4-2.5,3.4-2.9,5.9
					c-0.5,2.6-1,4.5-1.6,6.3c-0.7,1.8-1.6,3.7-3.7,5.8c2.7-1.3,4.5-2.3,6.5-3.3C508.4,121,510.4,119.9,512.9,117.7z"
                  />
                </g>
                <g>
                  <path
                    d="M529.5,112.5c2.1-2.5,3.1-5.6,2.9-8.1c-0.1-2.5-1.2-4.4-2.9-4.8c-1.7-0.5-3.6,0.5-5,2.2c-1.5,1.7-2.6,4.1-3,7
					c-0.4,3-0.6,5.1-1,7.2c-0.4,2.1-1,4.3-2.4,7.1c2.3-2.2,4-3.7,5.7-5.2C525.6,116.6,527.3,115.1,529.5,112.5z"
                  />
                </g>
                <g>
                  <path
                    d="M541.5,108.7c2-2,2.9-4.7,2.9-7c-0.1-2.2-1.2-4-2.8-4.5c-1.7-0.5-3.5,0.2-5,1.5c-1.5,1.4-2.6,3.4-2.9,6
					c-0.3,2.6-0.5,4.5-0.8,6.4c-0.3,1.9-0.8,3.8-2,6.3c2.1-1.9,3.6-3.1,5.2-4.3C537.8,112,539.4,110.8,541.5,108.7z"
                  />
                </g>
                <g>
                  <path
                    d="M558.6,100c2.1-2.3,3.1-5.3,3-7.7c0-2.4-1.1-4.3-2.7-4.9c-1.7-0.5-3.6,0.3-5.1,1.9c-1.5,1.6-2.7,4-3.1,6.8
					c-0.4,2.9-0.6,5-0.9,7.1c-0.4,2.1-0.9,4.2-2.2,7c2.2-2.2,3.8-3.7,5.5-5.1C554.7,103.8,556.5,102.4,558.6,100z"
                  />
                </g>
                <g>
                  <path
                    d="M573,93.6c2.2-1.8,3.6-4.1,4.1-6.2c0.5-2.2,0.2-4.2-1.2-5.3c-1.4-1.1-3.5-0.9-5.5,0.3c-2,1.2-3.9,3.5-4.7,6.5
					c-0.8,3-1.2,5.2-1.5,7.3c-0.4,2.1-0.8,4.2-1.6,7.1c1.8-2.4,3.3-3.9,4.9-5.2C569,96.6,570.7,95.4,573,93.6z"
                  />
                </g>
                <g>
                  <path
                    d="M587.3,86.9c2.3-1.1,3.9-2.9,4.8-4.7c0.8-1.8,0.9-3.8-0.1-5.2c-1-1.4-3.1-1.9-5.2-1.2c-2.2,0.6-4.5,2.4-5.7,5.1
					c-1.3,2.7-1.9,4.7-2.5,6.7c-0.6,2-1.2,3.9-2.3,6.5c1.9-2,3.6-3.2,5.3-4.1C583.1,88.9,584.9,88.1,587.3,86.9z"
                  />
                </g>
                <g>
                  <path
                    d="M602.4,93.3c2.3,0.6,4.4,0.3,6.1-0.6c1.7-0.9,3-2.3,3.1-4.1c0.1-1.7-1-3.4-3.1-4.3c-2-0.9-4.8-1-7.4,0.3
					c-2.6,1.3-4.2,2.6-5.7,3.7c-1.5,1.2-3.1,2.4-5.3,3.9c2.6-0.5,4.5-0.5,6.3-0.2C598.3,92.3,600,92.7,602.4,93.3z"
                  />
                </g>
                <g>
                  <path
                    d="M602.3,107.3c2.1,1.2,4.2,1.5,6.1,1.3c1.9-0.3,3.6-1.1,4.4-2.7c0.8-1.6,0.2-3.6-1.6-5.2c-1.8-1.6-4.7-2.6-7.7-2.1
					c-3.1,0.5-5.1,1.4-7,2.2c-2,0.9-3.8,1.7-6.3,2.8c2.8-0.1,4.7,0.4,6.5,1C598.4,105.2,600.1,106.1,602.3,107.3z"
                  />
                </g>
                <g>
                  <path
                    d="M598.1,120.2c2.4,1.8,5,2.6,7.4,2.6c2.3,0,4.2-0.9,4.9-2.5c0.7-1.6-0.1-3.6-1.8-5.3c-1.7-1.6-4.4-2.9-7.5-3.1
					c-6.3-0.3-8.9,0.5-15.1-0.2C591.6,114.5,593.3,116.5,598.1,120.2z"
                  />
                </g>
                <g>
                  <path
                    d="M455.6,157.1c1.3,2.2,2.9,3.7,4.7,4.7c1.8,0.9,3.8,1.2,5.3,0.3c1.5-0.9,2.1-3,1.3-5.4c-0.7-2.4-2.8-5-5.7-6.3
					c-3-1.3-5.3-1.7-7.4-2.1c-2.2-0.4-4.3-0.7-7.2-1.1c2.6,1.4,4.1,2.9,5.4,4.4C453.2,153.1,454.3,154.8,455.6,157.1z"
                  />
                </g>
                <g>
                  <path
                    d="M472.5,157.1c1.1,2.4,2.4,4.3,3.9,5.8c1.5,1.5,3.1,2.7,4.9,2.5c0.9-0.1,1.6-0.6,2.2-1.6c0.6-0.9,0.9-2.2,0.8-3.8
					c-0.1-1.5-0.5-3.3-1.5-4.9c-0.9-1.6-2.3-3.2-4-4.3c-1.8-1.1-3.4-1.8-4.8-2.2c-1.5-0.4-2.8-0.7-4.1-0.8c-2.6-0.3-5.1-0.3-8.6,0.2
					c3.4,0.6,5.5,1.9,7.1,3.3C470,152.9,471.3,154.7,472.5,157.1z"
                  />
                </g>
                <g>
                  <path
                    d="M487.8,156.6c1.5,2.4,3.4,4.2,5.4,5.3c1.9,1.1,4,1.5,5.5,0.6c1.5-0.9,2-3.1,1.1-5.7c-0.9-2.6-3.1-5.4-6.4-6.9
					c-3.3-1.5-5.7-2.1-8.1-2.5c-2.4-0.5-4.7-0.9-8-1.4c3,1.5,4.7,3.1,6.1,4.7C484.9,152.4,486.2,154.1,487.8,156.6z"
                  />
                </g>
                <g>
                  <path
                    d="M506.5,159.3c2.5,2.3,5.7,3.4,8.3,3.4c2.6,0,4.6-1.1,5.1-2.7c0.5-1.7-0.5-3.6-2.2-5.1c-1.7-1.6-4.2-2.8-7.2-3.3
					c-3-0.5-5.2-0.9-7.4-1.4c-2.2-0.5-4.3-1.3-7.2-2.9c2.2,2.5,3.6,4.3,5.1,6.1C502.5,155.1,504,157,506.5,159.3z"
                  />
                </g>
                <g>
                  <path
                    d="M525.3,155.5c3.6,1.7,7.5,2.2,10.5,1.7c3-0.5,5.1-1.9,5.2-3.6c0.2-1.8-1.5-3.5-4.1-4.7c-2.6-1.2-6-2-9.7-2
					c-3.8,0.1-6.5,0.2-9.2,0.1c-2.7-0.1-5.5-0.3-9.4-1.3c3.5,1.9,5.9,3.4,8.3,4.9C519.2,152.2,521.6,153.7,525.3,155.5z"
                  />
                </g>
                <g>
                  <path
                    d="M549,150.1c6.4,2.5,13.2,0.2,13.4-3.3c0.2-3.5-6-6.6-12.5-5.4c-6.7,1.1-9.4,2.3-16.4,2.3
					C540.3,145.7,542.5,147.5,549,150.1z"
                  />
                </g>
                <g>
                  <path
                    d="M568.8,144.1c3.4,1.2,7.1,1.2,9.7,0.4c2.7-0.8,4.5-2.3,4.5-4.1c0-1.8-1.7-3.3-4.2-4.3c-2.5-1-5.8-1.4-9.2-0.8
					c-3.5,0.6-6,1.1-8.5,1.5c-2.5,0.4-5.1,0.6-8.8,0.4c3.5,1.2,5.9,2.3,8.2,3.4C562.9,141.7,565.2,142.8,568.8,144.1z"
                  />
                </g>
                <g>
                  <path
                    d="M580.9,135.9c1.6,1.6,3.5,2.2,5.3,2.2c1.8,0,3.5-0.8,4.4-2.3c0.9-1.5,0.7-3.5-0.5-5.1c-1.2-1.6-3.4-2.9-6.1-3
					c-2.7,0-4.6,0.3-6.4,0.5c-1.8,0.3-3.6,0.5-6,0.6c2.3,0.9,3.7,1.9,5,3C578.1,133,579.3,134.2,580.9,135.9z"
                  />
                </g>
                <g>
                  <path
                    d="M591.4,129.9c3.6,4.4,9.6,5,11.5,2c1.9-3-1.3-8.1-6.8-9.4c-5.7-1.4-8.2-1.2-13.6-3.3
					C586.8,123.1,587.7,125.4,591.4,129.9z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M191.4,346.1c2,10.5,5.5,20.9,10.2,29.9c4.7,9.1,10.8,16.7,16.8,22.3c6,5.6,11.9,9.2,16.3,11.1c4.4,1.9,7.2,2.3,7.5,1.5
					c0.9-1.7-8.1-7.1-17.8-18.4c-4.8-5.7-9.8-12.8-14-21.1c-4.2-8.3-7.8-17.6-10.5-27.4c-2.8-10-4.2-18.8-5.5-26.9
					c-1.3-8.1-2.4-15.4-3.8-22.7c-1.4-7.3-3.2-14.7-6-22.6c-1.4-4-3.2-8.1-5.4-12.3c-2.2-4.2-5.1-8.5-8.7-12.7
					c6.8,8.8,10.2,17.6,12.4,25.5c2.2,8,3.2,15.3,4,22.6c0.7,7.3,1,14.6,1.5,22.8C188.8,326.1,189.3,335.3,191.4,346.1z"
                  />
                </g>
                <g>
                  <path
                    d="M197,289.9c1.7,2.6,4.4,4.1,6.8,4.5c2.4,0.4,4.4-0.3,5.2-1.9c0.8-1.6,0.3-3.5-0.8-5.3c-1.2-1.7-3-3.3-5.5-4.1
					c-2.6-0.9-4.5-1.6-6.3-2.5c-1.8-0.9-3.6-1.9-5.7-3.9c1.3,2.7,2.1,4.6,3,6.6C194.4,285.3,195.3,287.3,197,289.9z"
                  />
                </g>
                <g>
                  <path
                    d="M204,306.5c1.5,2.2,3.9,3.3,5.9,3.4c2.1,0.1,3.8-0.9,4.5-2.5c0.7-1.6,0.3-3.5-0.6-4.9c-1-1.5-2.5-2.6-4.7-3.1
					c-2.2-0.5-3.9-0.8-5.6-1.3c-1.6-0.5-3.2-1.2-5.2-2.6c1.2,2.2,1.9,3.8,2.7,5.4C201.7,302.5,202.5,304.3,204,306.5z"
                  />
                </g>
                <g>
                  <path
                    d="M209.3,323.1c1.8,2.1,4.2,3.1,6.4,3.2c2.1,0.1,3.9-0.8,4.6-2.5c0.7-1.6,0.2-3.5-1-5.1c-1.2-1.6-3.1-2.8-5.6-3.2
					c-2.5-0.4-4.4-0.6-6.2-1c-1.8-0.4-3.6-0.9-6-2c1.7,2,2.8,3.6,3.9,5.2C206.4,319.3,207.5,321,209.3,323.1z"
                  />
                </g>
                <g>
                  <path
                    d="M212.5,342.8c2.4,2.2,5.6,3,8,2.7c2.5-0.3,4.1-1.7,4.3-3.4c0.2-1.8-0.9-3.4-2.4-4.7c-1.5-1.3-3.6-2.2-6.1-2.4
					c-2.6-0.3-4.5-0.6-6.4-1.1c-1.9-0.5-3.8-1.3-6.1-3.2c1.5,2.5,2.6,4.3,3.8,6.2C208.8,338.7,210.1,340.6,212.5,342.8z"
                  />
                </g>
                <g>
                  <path
                    d="M219.1,359c2.6,1.9,5.8,2.6,8.3,2.2c2.5-0.3,4.3-1.6,4.6-3.3c0.3-1.7-0.8-3.5-2.7-4.8c-1.8-1.3-4.4-2.3-7.3-2.4
					c-3-0.1-5.1-0.1-7.3-0.4c-2.2-0.2-4.3-0.6-7.3-1.8c2.4,2.1,4,3.6,5.6,5.2C214.7,355.4,216.3,357,219.1,359z"
                  />
                </g>
                <g>
                  <path
                    d="M223.9,370.7c2.2,1.9,5,2.5,7.2,2.2c2.2-0.3,3.9-1.5,4.3-3.2c0.4-1.7-0.5-3.5-2-4.8c-1.5-1.4-3.7-2.3-6.2-2.3
					c-2.6-0.1-4.6-0.1-6.5-0.2c-1.9-0.2-3.8-0.5-6.4-1.5c2,1.9,3.4,3.4,4.7,4.9C220.3,367.2,221.6,368.7,223.9,370.7z"
                  />
                </g>
                <g>
                  <path
                    d="M234,386.9c2.5,1.9,5.5,2.6,7.9,2.4c2.4-0.2,4.2-1.4,4.6-3.1c0.4-1.7-0.6-3.5-2.4-4.9c-1.8-1.4-4.2-2.4-7.1-2.5
					c-3-0.1-5.1-0.2-7.2-0.3c-2.1-0.2-4.2-0.5-7.2-1.6c2.4,2,4,3.5,5.6,5C229.9,383.4,231.4,385,234,386.9z"
                  />
                </g>
                <g>
                  <path
                    d="M241.6,400.7c2,2.1,4.3,3.2,6.5,3.5c2.2,0.3,4.2-0.2,5.2-1.7c1-1.5,0.6-3.6-0.8-5.5c-1.4-1.9-3.8-3.6-6.8-4.2
					c-3.1-0.6-5.3-0.7-7.4-0.9c-2.1-0.2-4.3-0.4-7.2-1c2.6,1.6,4.2,3,5.6,4.5C238.2,397,239.6,398.6,241.6,400.7z"
                  />
                </g>
                <g>
                  <path
                    d="M249.5,414.4c1.3,2.2,3.2,3.7,5.1,4.3c1.9,0.7,3.9,0.6,5.2-0.6c1.3-1.2,1.6-3.2,0.7-5.3c-0.8-2.1-2.8-4.2-5.5-5.3
					c-2.8-1.1-4.9-1.5-6.9-1.9c-2-0.4-3.9-0.9-6.6-1.7c2.2,1.8,3.5,3.3,4.6,4.9C247.1,410.4,248.1,412.1,249.5,414.4z"
                  />
                </g>
                <g>
                  <path
                    d="M244.4,430c-0.4,2.4,0.1,4.4,1.1,6c1,1.6,2.6,2.7,4.3,2.7c1.8,0,3.3-1.3,4-3.4c0.7-2.1,0.6-4.9-0.9-7.3
					c-1.5-2.5-2.9-4-4.2-5.4c-1.3-1.4-2.6-2.9-4.3-4.9c0.7,2.6,0.8,4.4,0.7,6.2C245.1,425.8,244.8,427.6,244.4,430z"
                  />
                </g>
                <g>
                  <path
                    d="M230.5,431.1c-1,2.2-1.2,4.3-0.7,6.2c0.4,1.9,1.4,3.5,3.1,4.2c1.6,0.6,3.6-0.1,5-2.1c1.4-1.9,2.2-4.9,1.4-7.9
					c-0.8-3-1.8-4.9-2.8-6.8c-1-1.9-2-3.7-3.3-6.1c0.3,2.8,0,4.7-0.4,6.6C232.2,427,231.5,428.8,230.5,431.1z"
                  />
                </g>
                <g>
                  <path
                    d="M217.3,428c-1.6,2.5-2.2,5.3-1.9,7.5c0.2,2.3,1.2,4.1,2.9,4.7c1.7,0.5,3.6-0.4,5.1-2.2c1.5-1.9,2.5-4.7,2.4-7.7
					c-0.3-6.3-1.2-8.8-1.1-15C222.4,421,220.5,422.8,217.3,428z"
                  />
                </g>
                <g>
                  <path
                    d="M168.4,289.1c-2.1,1.5-3.5,3.2-4.2,5.1c-0.7,1.9-0.9,3.9,0.2,5.3c1,1.4,3.2,1.8,5.5,0.9c2.3-0.9,4.7-3.2,5.8-6.3
					c1.1-3.2,1.3-5.4,1.5-7.6c0.2-2.2,0.3-4.3,0.4-7.3c-1.2,2.7-2.5,4.3-3.9,5.7C172.1,286.4,170.5,287.6,168.4,289.1z"
                  />
                </g>
                <g>
                  <path
                    d="M169.7,305.9c-2.3,1.3-4,2.7-5.5,4.3c-1.4,1.6-2.5,3.4-2.1,5.1c0.2,0.9,0.8,1.6,1.7,2c1,0.5,2.3,0.7,3.8,0.5
					c1.5-0.2,3.2-0.8,4.8-1.9c1.5-1.1,3-2.6,3.9-4.4c1-1.9,1.5-3.5,1.8-5c0.3-1.5,0.4-2.8,0.5-4.1c0-2.6-0.1-5.2-0.9-8.6
					c-0.3,3.5-1.4,5.6-2.7,7.4C173.7,303.1,172.1,304.6,169.7,305.9z"
                  />
                </g>
                <g>
                  <path
                    d="M171.5,321.1c-2.3,1.7-3.9,3.7-4.8,5.8c-0.9,2-1.2,4.1-0.1,5.5c1,1.4,3.3,1.7,5.8,0.6c2.5-1.1,5.1-3.6,6.3-6.9
					c1.2-3.4,1.6-5.9,1.8-8.3c0.3-2.4,0.5-4.7,0.7-8.1c-1.3,3.1-2.6,4.9-4.1,6.5C175.6,317.9,173.9,319.3,171.5,321.1z"
                  />
                </g>
                <g>
                  <path
                    d="M170.5,340.1c-2.1,2.7-2.9,6-2.6,8.5c0.2,2.6,1.4,4.5,3.2,4.8c1.7,0.3,3.5-0.8,4.9-2.6c1.4-1.9,2.4-4.4,2.7-7.4
					c0.3-3.1,0.4-5.3,0.8-7.5c0.4-2.2,0.9-4.4,2.3-7.4c-2.3,2.4-3.9,4-5.6,5.6C174.4,335.7,172.6,337.3,170.5,340.1z"
                  />
                </g>
                <g>
                  <path
                    d="M175.9,358.4c-1.4,3.7-1.5,7.7-0.8,10.6c0.7,3,2.3,4.9,4,4.9c1.8,0,3.3-1.8,4.3-4.5c1-2.7,1.5-6.2,1.1-9.9
					c-0.4-3.8-0.7-6.5-0.9-9.2c-0.2-2.7-0.2-5.5,0.4-9.4c-1.6,3.7-2.9,6.2-4.2,8.7C178.7,352.1,177.4,354.6,175.9,358.4z"
                  />
                </g>
                <g>
                  <path
                    d="M183.3,381.6c-1.9,6.6,0.9,13.2,4.4,13.1c3.5-0.1,6-6.5,4.4-12.9c-1.7-6.6-3.1-9.1-3.7-16.1
					C186.9,372.5,185.3,374.9,183.3,381.6z"
                  />
                </g>
                <g>
                  <path
                    d="M190.9,400.8c-0.9,3.5-0.6,7.1,0.4,9.7c1,2.6,2.7,4.3,4.5,4.1c1.8-0.1,3.2-2,3.9-4.5c0.8-2.6,0.9-5.9,0-9.3
					c-0.9-3.5-1.6-5.9-2.2-8.4c-0.6-2.5-1-5-1.2-8.7c-0.9,3.6-1.8,6-2.6,8.5C192.8,394.7,191.9,397.2,190.9,400.8z"
                  />
                </g>
                <g>
                  <path
                    d="M200.2,412.2c-1.4,1.7-1.9,3.7-1.8,5.5c0.2,1.8,1.1,3.4,2.7,4.2c1.6,0.8,3.5,0.4,5-1c1.5-1.3,2.6-3.7,2.5-6.3
					c-0.2-2.7-0.6-4.5-1.1-6.3c-0.4-1.8-0.8-3.5-1.1-6c-0.7,2.4-1.6,3.9-2.6,5.3C202.8,409.1,201.7,410.4,200.2,412.2z"
                  />
                </g>
                <g>
                  <path d="M207,422.2c-4,4-4.1,10-1,11.6c3.1,1.6,8-2,8.8-7.6c0.9-5.8,0.5-8.2,2.1-13.8C213.4,417,211.1,418.1,207,422.2z" />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M378.5,8.1c9.5-5,18.3-11.4,25.5-18.6c7.3-7.2,12.8-15.2,16.3-22.6c3.6-7.4,5.3-14.1,5.8-18.9c0.5-4.8,0-7.5-0.8-7.6
					c-1.9-0.3-4.3,9.8-12.3,22.5c-4,6.3-9.3,13.2-16,19.6c-6.6,6.5-14.6,12.7-23,18.1c-8.7,5.6-16.7,9.6-24,13.2
					c-7.3,3.6-14,6.8-20.6,10.4c-6.6,3.6-13.1,7.4-19.8,12.5c-3.4,2.5-6.8,5.4-10.1,8.8c-3.4,3.4-6.6,7.4-9.6,12
					c6.4-9.1,13.8-14.9,20.7-19.4c7-4.5,13.7-7.6,20.5-10.5c6.8-2.8,13.7-5.3,21.4-8.2C360.1,16.5,368.7,13.3,378.5,8.1z"
                  />
                </g>
                <g>
                  <path
                    d="M323.1,19.3c2-2.4,2.7-5.4,2.3-7.8c-0.3-2.4-1.6-4.1-3.3-4.4c-1.7-0.3-3.5,0.7-4.8,2.3c-1.3,1.6-2.2,3.9-2.3,6.5
					c-0.1,2.7-0.2,4.7-0.5,6.7c-0.3,2-0.8,4-2.1,6.6c2.2-2,3.7-3.4,5.4-4.8C319.4,23.2,321.1,21.8,323.1,19.3z"
                  />
                </g>
                <g>
                  <path
                    d="M336.9,7.8c1.6-2.1,2-4.7,1.5-6.6c-0.5-2-2-3.4-3.7-3.5c-1.8-0.2-3.4,0.7-4.5,2.1c-1.1,1.4-1.8,3.2-1.6,5.4
					c0.2,2.3,0.4,4,0.4,5.7c0,1.7-0.1,3.4-1,5.8c1.7-1.8,3-3,4.4-4.2C333.8,11.1,335.2,9.9,336.9,7.8z"
                  />
                </g>
                <g>
                  <path
                    d="M351.2-2.2c1.5-2.3,1.7-5,1.1-7c-0.6-2.1-2-3.5-3.7-3.7c-1.8-0.2-3.4,0.8-4.6,2.4c-1.1,1.6-1.8,3.8-1.4,6.3
					c0.4,2.6,0.7,4.4,0.9,6.3c0.2,1.9,0.3,3.7-0.1,6.4c1.4-2.3,2.6-3.8,3.8-5.3C348.4,1.6,349.7,0.1,351.2-2.2z"
                  />
                </g>
                <g>
                  <path
                    d="M369.1-11.1c1.4-2.9,1.2-6.2,0.2-8.4c-1-2.3-2.9-3.4-4.6-3.1c-1.7,0.3-3,1.8-3.8,3.7c-0.8,1.9-1,4.1-0.5,6.5
					c0.5,2.6,0.8,4.5,0.9,6.5c0,2-0.1,4-1.2,6.8c2-2.2,3.4-3.8,4.8-5.5C366.2-6.4,367.6-8.1,369.1-11.1z"
                  />
                </g>
                <g>
                  <path
                    d="M382.6-22.2c1-3.1,0.7-6.3-0.3-8.6c-1-2.3-2.8-3.6-4.5-3.4c-1.8,0.2-3.1,1.8-3.8,4c-0.7,2.1-0.9,4.8-0.1,7.6
					c0.8,2.9,1.4,5,1.8,7.1c0.4,2.1,0.7,4.3,0.4,7.5c1.3-2.9,2.3-4.9,3.3-6.9C380.4-16.9,381.5-19,382.6-22.2z"
                  />
                </g>
                <g>
                  <path
                    d="M392.3-30.2c1.1-2.7,1-5.5,0-7.5c-0.9-2-2.6-3.3-4.3-3.1c-1.8,0.1-3.2,1.5-4,3.3c-0.8,1.8-1.1,4.2-0.4,6.6
					c0.7,2.5,1.3,4.4,1.7,6.3c0.4,1.9,0.7,3.8,0.5,6.6c1.2-2.5,2.2-4.2,3.2-5.9C390-25.7,391.1-27.5,392.3-30.2z"
                  />
                </g>
                <g>
                  <path
                    d="M404.8-44.7c1.1-3,0.9-6-0.1-8.3c-1-2.2-2.6-3.6-4.4-3.5c-1.8,0.1-3.2,1.6-4,3.7c-0.8,2.1-1,4.7-0.3,7.5
					c0.8,2.9,1.4,4.9,1.8,7c0.4,2.1,0.8,4.2,0.6,7.3c1.2-2.9,2.1-4.8,3.1-6.8C402.6-39.7,403.7-41.7,404.8-44.7z"
                  />
                </g>
                <g>
                  <path
                    d="M415.7-56.1c1.4-2.5,1.8-5.1,1.4-7.3c-0.3-2.2-1.4-3.9-3.1-4.4c-1.7-0.5-3.6,0.4-5,2.3c-1.4,1.9-2.3,4.7-1.9,7.8
					c0.4,3.1,0.9,5.2,1.3,7.3c0.5,2.1,0.9,4.2,1.2,7.2c0.8-2.9,1.6-4.9,2.6-6.7C413.2-51.8,414.3-53.5,415.7-56.1z"
                  />
                </g>
                <g>
                  <path
                    d="M426.4-67.6c1.7-1.9,2.5-4.1,2.6-6.1c0.1-2-0.6-3.9-2.1-4.8c-1.5-0.9-3.5-0.6-5.3,0.9c-1.8,1.4-3.2,3.9-3.4,6.8
					c-0.2,3,0,5.1,0.2,7.1c0.2,2,0.3,4,0.3,6.9c1-2.6,2.1-4.3,3.3-5.8C423.3-64.2,424.7-65.6,426.4-67.6z"
                  />
                </g>
                <g>
                  <path
                    d="M442.9-67.4c2.4-0.3,4.2-1.4,5.4-2.9c1.2-1.4,1.9-3.2,1.3-4.9c-0.5-1.7-2.2-2.8-4.4-2.8c-2.2-0.1-4.8,0.9-6.7,3.1
					c-1.9,2.2-2.9,3.9-3.9,5.6c-1,1.7-2,3.3-3.4,5.6c2.2-1.5,4-2.1,5.7-2.6C438.6-66.8,440.4-67.1,442.9-67.4z"
                  />
                </g>
                <g>
                  <path
                    d="M448-54.4c2.4,0.3,4.5-0.2,6.2-1.1c1.7-1,3-2.4,3.1-4.2c0.1-1.7-1.2-3.4-3.4-4.2c-2.2-0.8-5.3-0.6-7.9,1
					c-2.7,1.7-4.2,3.2-5.7,4.6c-1.5,1.6-2.9,3-4.8,5c2.6-1.1,4.5-1.4,6.4-1.5C443.6-54.9,445.5-54.7,448-54.4z"
                  />
                </g>
                <g>
                  <path
                    d="M449-40.9c2.9,0.8,5.7,0.5,7.8-0.4c2.1-0.9,3.6-2.4,3.6-4.2c0-1.8-1.4-3.3-3.6-4.2c-2.2-0.9-5.2-1-8.1,0
					c-6,2.1-8,3.8-14,5.5C440.8-43.7,443.1-42.4,449-40.9z"
                  />
                </g>
                <g>
                  <path
                    d="M330.8,46.9c2,1.6,4.1,2.4,6.1,2.5c2,0.2,3.9-0.3,5-1.7c1-1.4,0.8-3.5-0.8-5.5c-1.5-1.9-4.4-3.6-7.7-3.6
					c-3.3-0.1-5.5,0.4-7.7,0.8c-2.1,0.5-4.2,1-7.1,1.7c3,0.3,4.9,1.1,6.6,2C327.1,44.2,328.7,45.3,330.8,46.9z"
                  />
                </g>
                <g>
                  <path
                    d="M346.5,40.6c1.9,1.8,3.8,3.1,5.8,3.9c1.9,0.8,3.9,1.4,5.5,0.5c0.8-0.4,1.3-1.2,1.4-2.3c0.2-1.1,0-2.4-0.7-3.8
					c-0.6-1.4-1.7-2.8-3.2-4c-1.5-1.2-3.3-2.1-5.3-2.4c-2.1-0.4-3.8-0.4-5.3-0.3c-1.5,0.1-2.8,0.4-4.1,0.8c-2.5,0.7-4.9,1.6-7.9,3.4
					c3.4-0.8,5.8-0.3,7.9,0.4C342.6,37.7,344.5,38.8,346.5,40.6z"
                  />
                </g>
                <g>
                  <path
                    d="M360.5,34.4c2.3,1.7,4.7,2.6,6.9,2.9c2.2,0.3,4.3-0.1,5.3-1.5c1-1.4,0.7-3.6-1.1-5.7c-1.8-2.1-4.9-3.8-8.5-4
					c-3.6-0.2-6.1,0.3-8.5,0.7c-2.4,0.5-4.7,1-7.9,1.7c3.3,0.3,5.5,1.1,7.4,2C356.2,31.5,358.1,32.7,360.5,34.4z"
                  />
                </g>
                <g>
                  <path
                    d="M378.8,29.8c3.2,1.2,6.5,1,8.9,0c2.4-1,3.8-2.7,3.7-4.4c-0.2-1.8-1.8-3.1-4-3.9c-2.2-0.8-5-1-7.9-0.4
					c-3,0.7-5.2,1.2-7.4,1.5c-2.2,0.3-4.5,0.4-7.8,0c3,1.5,5,2.6,7,3.7C373.5,27.4,375.6,28.6,378.8,29.8z"
                  />
                </g>
                <g>
                  <path
                    d="M394.8,19.2c4,0.3,7.8-0.8,10.4-2.4c2.6-1.6,4-3.6,3.5-5.3c-0.5-1.7-2.7-2.6-5.6-2.8c-2.9-0.2-6.4,0.4-9.8,1.9
					c-3.5,1.5-6,2.6-8.5,3.6c-2.5,1-5.2,1.8-9.2,2.4c4,0.5,6.7,0.9,9.5,1.4C387.9,18.4,390.7,19,394.8,19.2z"
                  />
                </g>
                <g>
                  <path
                    d="M414.8,5.3c6.9-0.1,12.4-4.8,11.2-8.1c-1.2-3.3-8-3.8-13.6-0.3c-5.8,3.6-7.8,5.6-14.3,8.3C405,4.5,407.7,5.3,414.8,5.3z
					"
                  />
                </g>
                <g>
                  <path
                    d="M430.8-7.7c3.7-0.2,7-1.5,9.2-3.3c2.2-1.7,3.3-3.8,2.6-5.5c-0.7-1.6-2.8-2.4-5.5-2.4c-2.7,0-5.9,0.9-8.8,2.7
					c-3,1.9-5.1,3.3-7.3,4.6c-2.2,1.3-4.5,2.5-8,3.7c3.7-0.2,6.3-0.1,8.9,0C424.5-7.7,427.1-7.5,430.8-7.7z"
                  />
                </g>
                <g>
                  <path
                    d="M439-19.9c2.1,0.9,4.1,0.8,5.8,0c1.6-0.7,3-2.1,3.3-3.8c0.3-1.7-0.7-3.5-2.4-4.5c-1.7-1.1-4.3-1.4-6.7-0.5
					c-2.5,1-4.1,2-5.7,2.9c-1.6,0.9-3.1,1.8-5.4,2.9c2.5,0,4.2,0.4,5.8,0.9C435.2-21.5,436.8-20.8,439-19.9z"
                  />
                </g>
                <g>
                  <path d="M446.5-29.3c5,2.7,10.8,1,11.4-2.5c0.6-3.5-4.3-7-9.9-6.2c-5.8,0.9-8,2-13.8,2C439.6-33.9,441.3-32.1,446.5-29.3z" />
                </g>
              </g>
              <g>
                <path
                  d="M533.7,299.2c0.6-0.6,1.1-1.2,1.6-1.8c0.5-0.7,1-1.3,1.5-2c0.9-1.4,1.8-2.8,2.4-4.3c1.4-3,2.2-6.2,2.6-9.4
				c0.8-6.4-0.3-12.8-2.8-18.6c-1.2-2.9-3-5.7-5-8.1c-2-2.4-4.2-4.5-6.6-6.4c-4.8-3.6-10.2-6.1-15.6-7.6c-2.7-0.7-5.5-1.3-8.2-1.5
				c-2.7-0.3-5.5-0.3-8.1-0.2c-5.4,0.3-10.8,1.5-15.7,3.8c-5,2.3-9.4,5.8-12.6,10c-1.6,2.1-2.9,4.5-3.9,6.9
				c-0.9,2.4-1.5,5.1-1.6,7.7c-0.3,5.1,1,10,3.5,14.1c1.3,2,2.9,3.8,4.7,5.2c1.8,1.4,3.8,2.4,5.8,3.1c4,1.3,8,1.3,11.5,0.3
				c3.5-1,6.4-3.3,8-5.7c1.7-2.4,2.4-4.9,2.6-7c0.2-2.1-0.1-4-0.6-5.3c-0.5-1.4-1.2-2.3-1.7-2.8c-0.6-0.5-1-0.6-1.3-0.5
				c-0.6,0.3-0.4,1.7-0.7,3.8c-0.1,1-0.4,2.2-1.1,3.5c-0.6,1.2-1.5,2.6-2.7,3.6c-0.6,0.5-1.3,0.9-2,1.3c-0.7,0.3-1.6,0.5-2.5,0.6
				c-1.8,0.2-3.8-0.2-5.7-1.2c-0.9-0.5-1.8-1.1-2.5-1.9c-0.7-0.7-1.3-1.6-1.8-2.7c-1-2.1-1.4-4.7-1-7.3c0.2-1.3,0.6-2.5,1.2-3.8
				c0.6-1.2,1.4-2.4,2.4-3.6c1-1.1,2.1-2.2,3.4-3.1c1.3-0.9,2.7-1.7,4.3-2.3c6.2-2.5,14.7-2.8,22.4-0.6c3.8,1.1,7.5,2.8,10.6,5.1
				c1.5,1.2,3,2.5,4.2,3.9c0.6,0.7,1.1,1.5,1.7,2.2l0.7,1.2l0.6,1.3c1.6,3.4,2.4,7.4,2.2,11.1c-0.1,1.9-0.5,3.7-1.2,5.4
				c-0.3,0.9-0.7,1.6-1.2,2.4l-0.7,1.1c-0.3,0.4-0.5,0.7-0.9,1.1c-2.5,3-6.1,5.2-10.1,6.9c-2,0.9-4.1,1.6-6.1,2.2
				c-1.1,0.3-2.1,0.6-3.2,0.9c-1.1,0.3-2.1,0.6-3.1,0.8c-8.4,1.9-16.5,2.6-24.2,2.5c-7.7-0.1-15.1-1.1-22.2-2.7
				c-7.1-1.6-13.8-3.8-20.3-6.6c-13-5.7-25.3-13.9-36.8-25.5c-5.7-5.8-11.3-12.5-16.4-20.3c-5.2-7.7-9.9-16.7-13.9-26.8
				c3.7,10.3,8,19.4,12.8,27.5c4.8,8.1,10,15.2,15.6,21.4c11.1,12.5,23,21.8,36.3,28.8c6.6,3.5,13.5,6.4,20.9,8.7
				c7.4,2.3,15.2,3.9,23.5,4.7c8.4,0.8,17.3,0.7,26.8-0.7c1.2-0.1,2.4-0.4,3.6-0.6c1.2-0.2,2.4-0.5,3.6-0.7c2.5-0.6,5-1.2,7.6-2.1
				c2.6-0.9,5.2-2,7.8-3.5C528.7,303.5,531.3,301.6,533.7,299.2z"
                />
              </g>
              <g>
                <path
                  d="M454.1,257.7c3.8-5.9,5.1-13.2,3.9-20.1c-0.6-3.4-1.9-6.8-3.7-9.7c-1.8-2.9-4.1-5.5-6.7-7.6c-2.6-2.1-5.5-3.7-8.6-4.7
				c-3.1-1-6.3-1.5-9.5-1.3c-3.2,0.2-6.3,0.9-9.1,2.4c-0.7,0.3-1.4,0.8-2,1.2c-0.7,0.5-1.3,0.9-1.9,1.4c-1.2,1-2.2,2.1-3.1,3.3
				c-1.7,2.4-2.8,5-3.3,7.6c-0.5,2.6-0.3,5.3,0.4,7.7c0.8,2.5,2.1,4.4,3.6,5.7c1.5,1.4,3,2.3,4.5,2.9c1.5,0.6,2.9,0.8,4.1,0.8
				c0.6,0,1.2-0.1,1.7-0.2c0.5-0.1,0.9-0.3,1.3-0.4c0.8-0.4,1.3-0.8,1.5-1.2c0.3-0.4,0.4-0.7,0.3-1.1c-0.2-0.7-1-1-2-1.6
				c-1-0.6-2.2-1.3-3.3-2.6c-1.1-1.2-1.9-3.1-1.8-4.5c0.1-0.9,0.2-1.7,0.7-2.6c0.4-0.9,1.1-1.8,1.9-2.6c0.4-0.4,0.9-0.7,1.3-1
				c0.2-0.1,0.5-0.2,0.7-0.4c0.3-0.1,0.5-0.2,0.8-0.3c1.1-0.4,2.4-0.5,3.9-0.5c2.9,0.1,5.9,1.3,8.6,3.4c1.3,1.1,2.5,2.4,3.4,3.8
				c0.9,1.5,1.6,3,2,4.7c0.8,3.4,0.5,7.4-1.1,10.7c-1.6,3.5-4.6,6.3-7.9,8.3c-3.4,1.9-7,3-10.6,3.3c-3.7,0.4-7.3,0.1-10.9-0.6
				c-3.5-0.7-7-1.8-10.4-3.2c-3.4-1.4-6.6-3.1-9.9-5.2c-3.2-2.1-6.3-4.6-9.4-7.5c-3-2.9-6-6.3-8.7-10.3c-2.7-4-5.2-8.6-7.1-14
				c3.2,11,8.3,19.4,13.9,26.1c2.8,3.4,5.7,6.3,8.8,9c3.1,2.6,6.3,4.9,9.7,7c3.4,2.1,7,3.8,11,5.2c3.9,1.4,8.2,2.4,13,2.7
				c4.7,0.3,10-0.4,15.2-2.5c2.6-1,5.2-2.5,7.8-4.4C449.8,263.2,452.2,260.8,454.1,257.7z"
                />
              </g>
              <g>
                <path
                  d="M336.8,288.9c-1.6,6.2-2,12.8-1.5,18.8c0.5,6,2,11.6,3.9,16c1.9,4.5,4.2,7.8,6.2,9.9c2.1,2.1,3.9,2.9,5,2.3
				c1.2-0.6,1.7-2.5,1.6-5.1c0.1-2.7-0.4-6.2-0.8-10.4c-0.4-4.1-0.9-8.8-1.2-13.8c-0.3-5-0.4-10.4-0.3-15.8
				c0.1-5.6,0.8-10.4,1.7-14.7c0.9-4.3,2.2-8.3,3.4-12.5c1.2-4.1,2.4-8.3,3.2-13.2c0.4-2.4,0.6-5,0.6-7.8c0-2.8-0.4-5.7-1.3-8.7
				c0.5,3.1,0.5,6,0.1,8.6c-0.4,2.6-1.1,5-1.9,7.2c-1.6,4.4-3.7,8-5.9,11.6c-2.2,3.6-4.5,7.2-6.8,11.5
				C340.7,277.4,338.5,282.5,336.8,288.9z"
                />
              </g>
              <g>
                <path
                  d="M350.5,287.2c-2,4.9-4.1,10-5.6,15.3c-1.5,5.3-2.2,10.7-1.9,15.5c0.3,4.8,1.5,8.7,3,11.2c1.5,2.5,3.2,3.5,4.5,3.1
				c1.3-0.4,2-2,2.7-4.3c0.7-2.3,1.4-5.3,2.3-8.9c0.9-3.6,2.2-7.7,3.7-12.4c1.4-4.7,3-10.1,4-15.9c0.9-6,1.2-11.1,1.2-15.7
				c0-4.6-0.4-8.7-0.9-12.8c-0.5-4.1-1.2-8.1-2.1-12.5c-1-4.4-2.2-9.3-4.1-14.9c1.1,5.8,1.4,10.8,1.4,15.2c0,4.4-0.4,8.4-0.9,12.2
				c-0.6,3.8-1.4,7.6-2.5,11.6C354.1,277.9,352.6,282.2,350.5,287.2z"
                />
              </g>
              <g>
                <path
                  d="M393.3,330.8c-0.5,0.7-1,1.3-1.5,1.9c-0.5,0.6-1.1,1.1-1.6,1.6c-1.2,0.9-2.4,1.7-3.7,2.3c-1.3,0.6-2.5,0.8-3.7,0.9
				c-1.2,0.1-2.1,0-3.2-0.4c-2.1-0.6-3.9-2.2-4.8-3.6c-0.4-0.7-0.7-1.4-0.8-2.3c-0.1-0.9,0-1.8,0.2-2.8c0.5-1.9,1.6-3.7,2.7-4.9
				c1.2-1.2,2-1.6,2.4-2c0.3-0.1,0.8-0.1,1.2,0c0.4,0.1,0.8,0.3,1.1,0.5c0.7,0.4,1.1,1.1,1.7,1.4c0.3,0.1,0.7,0.1,1.1-0.1
				c0.4-0.3,0.8-0.8,1.1-1.6c0.2-0.8,0.2-2-0.2-3.1c-0.4-1.2-1.2-2.4-2.6-3.5c-1.6-1.1-3.4-1.5-5.4-1.5c-1.9,0-3.8,0.5-5.6,1.3
				c-3.5,1.6-6.7,4.5-9,8.7c-1.1,2.1-1.9,4.6-2.1,7.3c-0.2,2.7,0.2,5.8,1.4,8.5c1.1,2.8,2.9,5.2,5.1,7.2c2.2,2,4.8,3.6,7.7,4.6
				c2.9,1,6.3,1.4,9.3,1.1c3.1-0.3,6.1-1.2,8.7-2.6c2.7-1.4,5-3.2,7.1-5.3c1-1.1,1.9-2.2,2.8-3.4c0.8-1.2,1.5-2.4,2.1-3.7
				c2.5-5.3,3.2-10.4,3.2-14.9c0-4.6-0.8-8.7-1.8-12.3c-1-3.7-2.3-6.9-3.6-10c-1.3-3.1-2.7-6-4.2-8.9c-1.5-2.9-3-5.7-4.8-8.6
				c-1.7-2.9-3.5-5.8-5.6-8.7c-4.1-6-8.9-12.3-15.4-19.3c5.9,7.4,9.9,14.3,13.1,20.6c1.6,3.2,3,6.2,4.2,9.2c1.2,3,2.2,6,3.2,8.9
				c0.9,3,1.7,6,2.4,9c0.7,3.1,1.3,6.2,1.6,9.4c0.3,3.2,0.3,6.5-0.3,9.8C396.4,324.7,395.3,328,393.3,330.8z"
                />
              </g>
              <g>
                <path
                  d="M211.1,46.3c3.4-0.8,6.9-1,10.2-0.6c3.3,0.4,6.4,1.5,8.9,3.1c2.6,1.6,4.7,3.9,6.2,6.4c0.7,1.2,1.3,2.5,1.7,3.8
				c0.4,1.3,0.5,2.6,0.6,3.9c0.1,5.3-3,11-7.3,15.2c-1.1,1.1-2.2,2.1-3.4,2.9c-1.2,0.9-2.4,1.7-3.5,2.4c-2.4,1.4-4.7,2.1-6.6,2.3
				c-1,0.1-1.9,0-2.6-0.2c-0.8-0.2-1.6-0.5-2.3-1c-1.5-1-2.7-2.4-3.5-3.9c-0.4-0.8-0.6-1.5-0.8-2.3c-0.1-0.4-0.1-0.8-0.2-1.1
				c0-0.4,0-0.7,0-1.1c0.1-1.5,0.6-3,1.3-4.2c1.4-2.5,3.4-4.2,4.9-5.2c1.5-1,2.5-1.6,2.5-2.3c0-0.6-1.2-1.4-3.6-1.3
				c-1.2,0-2.7,0.3-4.3,0.9c-1.6,0.7-3.4,1.7-5.1,3.3c-1.7,1.6-3.3,3.8-4.3,6.8c-0.3,0.7-0.4,1.5-0.6,2.3c-0.1,0.8-0.2,1.6-0.2,2.5
				c-0.1,1.7,0.2,3.4,0.6,5.2c0.9,3.5,2.9,6.8,5.9,9.6c1.5,1.4,3.4,2.6,5.5,3.4c2.2,0.9,4.4,1.3,6.6,1.4c4.5,0.2,8.9-1,12.7-2.8
				c1.9-0.9,3.7-1.9,5.5-3.1c1.8-1.2,3.5-2.5,5.1-4c1.6-1.4,3.2-3.1,4.6-4.9c1.4-1.8,2.7-3.7,3.9-5.8c1.1-2.1,2.1-4.4,2.8-6.9
				c0.7-2.5,1.1-5.1,1.1-7.8c0-2.7-0.3-5.5-1.1-8.1c-0.8-2.6-1.9-5.1-3.3-7.4c-2.8-4.6-6.7-8.4-11.4-11.2c-4.7-2.8-9.9-4.2-15.1-4.6
				c-5.2-0.4-10.3,0.3-15.2,1.9c-5,1.6-9.3,4-13,6.7c-3.7,2.7-6.9,5.8-9.6,9c-5.3,6.4-9.1,13.2-11.6,19.9
				c-2.5,6.7-3.9,13.4-4.6,19.9c-0.7,6.5-0.7,12.8-0.1,19.1c0.6,6.3,1.8,12.4,3.6,18.6c1.8,6.2,4.1,12.4,6.9,18.7
				c5.7,12.7,13.8,25.8,24.4,40.6c-10-15.2-17.2-28.8-22-41.6c-2.4-6.4-4.2-12.6-5.4-18.7c-1.2-6.1-1.8-12-1.9-18
				c0-5.9,0.5-11.8,1.7-17.7c1.2-5.8,3-11.7,5.8-17.4c2.8-5.6,6.5-11.1,11.4-16c2.4-2.4,5.2-4.6,8.2-6.5
				C204.3,48.6,207.6,47.1,211.1,46.3z"
                />
              </g>
              <g>
                <path
                  d="M217.7,111.6c1.7-0.5,3.2-0.7,4.8-0.7c1.5,0,2.9,0.2,4,0.6c0.5,0.2,1,0.4,1.3,0.7c0.4,0.2,0.7,0.5,0.9,0.8
				c0.5,0.6,1,1.5,1.3,2.5c0.2,0.5,0.3,1.1,0.3,1.7c0.1,0.6,0.1,1.1,0,1.7c-0.1,1.1-0.4,2.2-0.7,3c-0.2,0.4-0.4,0.8-0.6,1.1
				c-0.2,0.3-0.4,0.6-0.7,0.8c-0.3,0.2-0.4,0.4-0.7,0.6c-0.3,0.2-0.6,0.3-1,0.4c-0.7,0.2-1.5,0.2-2.3,0.1c-0.7-0.1-1.4-0.3-2-0.7
				c-1.2-0.6-2.2-2-2.8-3.3c-0.6-1.3-0.9-2.6-1.1-3.6c-0.2-1-0.5-1.8-1-2.2c-0.5-0.3-1.4-0.2-2.5,0.7c-1,0.9-2.2,2.7-2.6,5.4
				c-0.2,1.3-0.2,2.9,0.1,4.5c0.3,1.7,1,3.5,2.3,5.2c2.5,3.5,7,6.2,12.4,6.3c1.3,0,2.7-0.1,4.2-0.4c1.4-0.3,2.9-0.9,4.2-1.7
				c1.3-0.8,2.5-1.7,3.6-2.8c1.1-1.1,2-2.3,2.8-3.5c1.5-2.5,2.5-5.3,3-8.3c0.2-1.5,0.3-3,0.2-4.5c-0.1-1.5-0.3-3-0.7-4.5
				c-0.8-3-2.3-6.1-4.8-8.7c-2.5-2.6-5.8-4.5-9-5.3c-3.2-0.9-6.4-1-9.4-0.6c-1.5,0.2-2.9,0.5-4.3,0.9c-1.4,0.4-2.7,1-4,1.5
				c-5.3,2.4-9.2,5.9-12.2,9.4c-1.5,1.8-2.8,3.6-3.9,5.4c-1.1,1.9-2,3.7-2.8,5.5c-3,7.5-3.6,14.5-3.3,21.3c0.3,6.8,1.7,13.4,4,20.4
				c2.2,7,5.4,14.5,9.3,23.3c-3.1-9.1-5.5-16.9-6.7-24c-1.3-7.1-1.6-13.5-0.8-19.6c0.7-6.1,2.5-12,5.9-17.3c0.8-1.3,1.8-2.6,2.9-3.9
				c1.1-1.2,2.3-2.4,3.5-3.5C211.2,114.4,214.2,112.5,217.7,111.6z"
                />
              </g>
              <g>
                <path
                  d="M252.1,32.8c3.2,0.6,6.4-0.3,8.5-1.8c2.1-1.5,3-3.4,2.5-5.1c-0.6-1.7-2.4-2.7-4.6-3c-2.2-0.3-4.8,0-7.4,1.2
				c-2.7,1.2-4.6,2.1-6.6,2.8c-2,0.7-4.2,1.2-7.3,1.3c3,1,5.1,1.7,7.3,2.5C246.6,31.4,248.8,32.2,252.1,32.8z"
                />
              </g>
              <g>
                <path
                  d="M267.3,45.6c1.8,2,3.7,3.3,5.6,4.1c1.9,0.8,4,1.1,5.5,0.2c1.5-0.9,1.9-3.2,0.7-5.8c-1.2-2.6-4-5.2-7.6-6.1
				c-3.7-0.9-6.2-0.8-8.7-0.6c-2.4,0.2-4.8,0.6-8,1.4c3.3,0.3,5.4,1.2,7.2,2.3C263.8,42.2,265.4,43.6,267.3,45.6z"
                />
              </g>
              <g>
                <path
                  d="M270,60.5c0.6,2.6,1.5,4.6,2.7,6.3c1.2,1.6,2.7,3,4.5,3.1c0.9,0,1.7-0.4,2.4-1.2c0.3-0.4,0.7-0.9,0.9-1.5
				c0.2-0.6,0.4-1.3,0.5-2c0.4-2.9-0.8-6.8-3.6-9.5c-2.9-2.7-5.2-3.8-7.5-4.7c-2.3-0.9-4.7-1.6-7.9-2.2c3,1.6,4.4,3.3,5.6,5.1
				C268.6,55.8,269.4,57.8,270,60.5z"
                />
              </g>
              <g>
                <path
                  d="M263.1,73.6c-0.7,2.7-0.6,5.1,0.1,7.2c0.7,2,1.9,3.7,3.7,4c1.7,0.3,3.5-0.9,4.7-3.2c1.1-2.3,1.5-5.6,0.3-8.6
				c-1.2-3.2-2.4-5.1-3.5-7.1c-1.2-1.9-2.3-3.8-3.9-6.5c0.6,3.1,0.5,5.2,0.2,7.3C264.3,68.8,263.9,70.8,263.1,73.6z"
                />
              </g>
              <g>
                <path
                  d="M251.6,86.6c-1.8,2-2.8,4.1-3.2,6.2c-0.4,2.1-0.1,4.1,1.2,5.3c1.3,1.2,3.5,1,5.7-0.5c2.1-1.5,4-4.4,4.4-7.7
				c0.4-3.4,0.1-5.8-0.2-8c-0.3-2.3-0.7-4.5-1.2-7.5c-0.5,3.1-1.4,5-2.5,6.8C254.7,82.9,253.5,84.5,251.6,86.6z"
                />
              </g>
              <g>
                <path
                  d="M241.2,20.8c6-2.2,9.5-7.9,7.4-10.8c-2.1-2.9-8.6-1.4-12.5,3.7c-2,2.6-3.3,4.5-4.6,6.4c-0.7,0.9-1.3,1.9-2.1,2.7
				c-0.4,0.4-0.9,0.8-1.5,1.1c-0.6,0.3-1.4,0.4-2.1,0.2c0.7,0.5,1.5,0.7,2.3,0.6c0.8,0,1.5-0.2,2.2-0.4c0.7-0.2,1.3-0.4,1.9-0.6
				c0.6-0.2,1.1-0.4,1.7-0.6C235.8,22.5,238.1,21.9,241.2,20.8z"
                />
              </g>
              <g>
                <path
                  d="M227.4,15.6c2.1-2.5,2.5-5.9,1.9-8.2c-0.7-2.4-2.4-3.8-4.1-3.6c-1.8,0.1-3.2,1.4-4.2,2.9c-1,1.5-1.6,3.3-1.6,5.5
				c0,2.4-0.2,4.2-0.7,5.9c-0.5,1.8-1.3,3.6-3.2,5.6c2.6-1.2,4.4-2.1,6.2-3.2C223.5,19.4,225.3,18.1,227.4,15.6z"
                />
              </g>
              <g>
                <path
                  d="M216.5,14.7c1.1-3.1,0.5-6.4-0.9-8.5c-1.4-2.1-3.4-2.9-5-2.3c-1.7,0.6-2.6,2.3-3.1,4.1c-0.5,1.8-0.5,3.9,0.3,6.1
				c0.8,2.4,1.2,4.2,1.4,6.1c0.2,1.9,0.1,3.9-1,6.6c1.9-2.1,3.3-3.8,4.6-5.5C214.1,19.6,215.4,17.8,216.5,14.7z"
                />
              </g>
              <g>
                <path
                  d="M204,15.5c-0.4-2.8-2.4-5-4.4-5.8c-2.1-0.8-4-0.4-5.1,1c-1.1,1.4-1.2,3.2-0.9,4.8c0.3,1.6,1.1,2.9,2.7,4.1
				c1.7,1.2,2.9,2.3,3.9,3.5c1,1.2,1.9,2.7,2.4,5.1c0.6-2.4,0.9-4.1,1.3-5.9C204.2,20.4,204.4,18.4,204,15.5z"
                />
              </g>
              <g>
                <path
                  d="M189.6,18.1c-1.2-0.9-2.6-1.3-4-1.3c-1.4,0-2.6,0.3-3.6,1c-1,0.6-1.6,1.4-1.9,2.3c-0.3,0.9-0.2,1.7,0.1,2.5
				c0.7,1.6,2.1,2.7,3.1,3.4c1.1,0.7,2,0.9,3.4,0.4c1.5-0.5,2.9-0.5,4.3-0.3c1.4,0.3,2.8,1,4.1,2.7c-0.3-2.2-0.8-3.8-1.5-5.5
				C193,21.7,192,19.9,189.6,18.1z"
                />
              </g>
              <g>
                <path
                  d="M174.9,28.6c-3.1,0-5.9,1.8-7.2,3.8c-1.3,2-1.2,4.2,0,5.4c1.2,1.3,3.1,1.6,4.8,1.4c1.8-0.2,3.4-0.8,5.1-2.3
				c1.7-1.5,3.2-2.6,4.8-3.4c1.6-0.8,3.4-1.4,6.1-1.4c-2.5-1.1-4.3-1.8-6.3-2.4C180.2,29.1,178.1,28.5,174.9,28.6z"
                />
              </g>
              <g>
                <path
                  d="M168.5,41.5c-2.5,0.6-4.3,2.4-4.8,4.3c-0.6,1.9-0.1,3.7,1.3,4.8c1.4,1.1,3.2,1.3,4.7,1c1.5-0.3,2.8-1.1,3.7-2.8
				c1-1.7,1.7-3,2.7-4.3c0.9-1.2,2.1-2.3,4.1-3.3c-2.2,0-3.8-0.1-5.6-0.2C172.8,41,171,40.9,168.5,41.5z"
                />
              </g>
              <g>
                <path
                  d="M158.7,62.1c-2.2,1.8-3.3,4.4-3.4,6.6c-0.1,2.2,0.9,4,2.5,4.7c1.6,0.7,3.5,0.1,5.1-1.1c1.6-1.2,2.8-3.1,3.3-5.6
				c0.5-2.6,0.8-4.4,1.3-6.3c0.5-1.8,1.1-3.6,2.6-6c-2.2,1.6-3.9,2.7-5.6,3.7C162.7,59.2,161,60.2,158.7,62.1z"
                />
              </g>
              <g>
                <path d="M155.7,90.1c-2.8,3.4-1.4,8,2,9c3.4,1,7.1-2.2,6.5-6.5c-0.6-4.5-1.6-6.4-1.4-10.8C160.4,85.5,158.6,86.6,155.7,90.1z" />
              </g>
              <g>
                <path
                  d="M157,113.8c-1.1,1.8-0.9,3.7-0.1,5.2c0.8,1.4,2.4,2.4,4.1,2.4c1.8,0,3.3-0.9,4.2-2.1c0.9-1.3,1.2-2.9,0.5-4.7
				c-1.5-3.7-2.8-5.3-3.2-9.2C160.8,108.9,159.2,110.1,157,113.8z"
                />
              </g>
              <g>
                <path
                  d="M191.5,102.9c2.1-4.3,3.5-8.8,3.9-12.4c0.5-3.6,0.1-6.5-1.5-7.2c-1.6-0.7-4.1,0.9-6.3,4.1c-2.2,3.2-4.1,8-4.6,13.1
				c-0.5,5.3-0.4,8.9-0.3,12.5c0.1,3.6,0.2,7.2,0.2,12.4c1-5.1,2.2-8.5,3.5-11.8C187.9,110.5,189.4,107.4,191.5,102.9z"
                />
              </g>
              <g>
                <g>
                  <path
                    d="M6.8,333.4c-3-1.7-5.7-4-7.9-6.6c-2.1-2.6-3.6-5.5-4.4-8.4c-0.8-2.9-0.8-6.1-0.2-8.9c0.3-1.4,0.8-2.7,1.4-3.9
					c0.6-1.2,1.4-2.2,2.3-3.2c3.6-3.9,9.7-6,15.7-6.1c1.5-0.1,3,0,4.5,0.1c1.5,0.1,2.9,0.4,4.2,0.7c2.7,0.6,4.8,1.6,6.3,2.8
					c0.8,0.6,1.4,1.3,1.8,1.9c0.5,0.7,0.8,1.5,1,2.3c0.4,1.7,0.4,3.6-0.2,5.2c-0.2,0.8-0.6,1.6-1,2.2c-0.2,0.4-0.4,0.6-0.7,0.9
					c-0.3,0.3-0.5,0.6-0.8,0.8c-1.1,1-2.5,1.7-3.9,2.2c-2.8,0.9-5.4,0.7-7.1,0.5c-1.8-0.3-3-0.5-3.4,0c-0.4,0.5-0.1,1.8,1.7,3.4
					c0.9,0.8,2.1,1.6,3.8,2.3c1.6,0.6,3.7,1.1,6,1.1c2.3,0,5.1-0.5,7.7-2c0.7-0.3,1.3-0.8,2-1.3c0.6-0.5,1.3-1,1.9-1.6
					c1.2-1.2,2.2-2.6,3.1-4.2c1.7-3.1,2.6-6.9,2.2-11c-0.2-2-0.7-4.2-1.7-6.3c-1-2.1-2.3-4-3.9-5.6c-3.2-3.2-7.2-5.3-11.2-6.6
					c-2-0.7-4.1-1.2-6.1-1.5c-2.1-0.4-4.2-0.5-6.4-0.6c-2.2-0.1-4.4,0.1-6.7,0.4c-2.3,0.3-4.6,0.9-6.8,1.6c-2.3,0.8-4.6,1.8-6.8,3.1
					c-2.2,1.4-4.3,3-6.1,4.9c-1.8,2-3.5,4.2-4.7,6.7c-1.2,2.4-2.1,5-2.7,7.6c-1.1,5.3-0.8,10.7,0.7,15.9c1.5,5.2,4.4,9.9,7.9,13.7
					c3.5,3.8,7.8,6.8,12.3,9c4.7,2.2,9.5,3.4,14.1,4c4.6,0.6,9,0.5,13.1,0c8.3-1,15.6-3.4,22.1-6.6c6.4-3.2,12-7.1,17-11.4
					c4.9-4.3,9.2-8.9,13.1-13.9c3.8-5,7.2-10.3,10.1-16c2.9-5.7,5.5-11.8,7.7-18.4c4.5-13.2,7.6-28.3,9.9-46.4
					c-3.1,18-7.1,32.8-12.3,45.4c-2.6,6.3-5.6,12.1-8.8,17.3c-3.3,5.3-6.9,10-10.9,14.4c-4,4.3-8.4,8.3-13.3,11.8
					c-4.9,3.5-10.2,6.5-16.1,8.7c-5.9,2.2-12.4,3.7-19.2,3.9c-3.4,0.1-6.9-0.1-10.4-0.9C13.3,336.4,9.9,335.2,6.8,333.4z"
                  />
                </g>
                <g>
                  <path
                    d="M46.6,281.2c-1.6-0.8-2.8-1.7-4-2.7c-1.1-1-2-2.1-2.5-3.1c-0.3-0.5-0.4-1-0.5-1.4c-0.1-0.4-0.1-0.8-0.1-1.2
					c0-0.8,0.3-1.7,0.8-2.7c0.3-0.5,0.6-1,0.9-1.5c0.4-0.5,0.7-0.9,1.1-1.2c0.8-0.8,1.8-1.4,2.6-1.7c0.4-0.2,0.8-0.3,1.2-0.4
					c0.4-0.1,0.7-0.1,1-0.1c0.3,0,0.6,0,0.9,0.1c0.3,0.1,0.6,0.2,1,0.4c0.7,0.3,1.3,0.9,1.7,1.5c0.5,0.6,0.8,1.2,1,1.8
					c0.4,1.3,0.2,2.9-0.2,4.3c-0.5,1.4-1.1,2.5-1.7,3.4c-0.5,0.9-0.9,1.6-0.7,2.3c0.2,0.6,0.9,1.1,2.3,1.2c1.4,0,3.5-0.5,5.6-2.2
					c1.1-0.8,2.1-1.9,3-3.4c0.9-1.4,1.6-3.3,1.9-5.4c0.6-4.3-0.9-9.3-4.7-13c-1-0.9-2.1-1.8-3.3-2.5c-1.3-0.7-2.7-1.3-4.2-1.6
					c-1.5-0.3-3-0.5-4.5-0.5c-1.5,0.1-3,0.3-4.4,0.7c-2.9,0.8-5.5,2.2-7.8,4c-1.2,0.9-2.3,2-3.2,3.2c-1,1.2-1.8,2.4-2.6,3.8
					c-1.5,2.7-2.5,6-2.5,9.6c0,3.6,1.2,7.2,2.9,10c1.7,2.9,4,5.1,6.4,6.8c1.2,0.9,2.5,1.6,3.8,2.3c1.3,0.7,2.7,1.2,4,1.6
					c5.5,1.8,10.7,2,15.4,1.5c2.3-0.3,4.5-0.7,6.6-1.3c2.1-0.6,4-1.3,5.8-2.2c7.3-3.4,12.6-8.1,17-13.2c4.4-5.2,7.9-10.9,11-17.6
					c3.2-6.7,5.9-14.3,9.1-23.4c-4,8.8-7.5,16.1-11.4,22.1c-3.9,6.1-8.1,10.9-12.8,14.9c-4.7,3.9-10,7-16.1,8.6
					c-1.5,0.4-3.1,0.7-4.7,0.9c-1.6,0.2-3.3,0.2-5,0.1C53.2,283.5,49.7,282.8,46.6,281.2z"
                  />
                </g>
                <g>
                  <path
                    d="M-32.4,315.2c-2-2.6-4.9-4.2-7.4-4.5c-2.5-0.4-4.6,0.4-5.3,2c-0.7,1.6-0.1,3.6,1.3,5.3c1.4,1.7,3.5,3.3,6.2,4.2
					c2.8,0.9,4.8,1.6,6.8,2.5c2,0.9,3.9,1.9,6.2,4c-1.5-2.8-2.6-4.7-3.6-6.8C-29.3,320-30.4,317.9-32.4,315.2z"
                  />
                </g>
                <g>
                  <path
                    d="M-34.7,295.5c0-2.7-0.4-4.9-1.3-6.9c-0.9-1.9-2.1-3.5-3.9-3.8c-1.7-0.3-3.6,1.1-4.5,3.8c-0.9,2.7-0.6,6.6,1.4,9.7
					c2.1,3.2,4,4.8,5.9,6.3c1.9,1.5,3.9,2.8,6.8,4.4c-2.2-2.4-3.1-4.5-3.7-6.6C-34.5,300.4-34.8,298.3-34.7,295.5z"
                  />
                </g>
                <g>
                  <path
                    d="M-26.6,282.8c1.3-2.3,2-4.4,2.3-6.5c0.2-2,0.1-4.1-1.2-5.3c-0.6-0.6-1.5-0.9-2.5-0.8c-0.5,0.1-1.1,0.2-1.6,0.5
					c-0.5,0.3-1.2,0.6-1.7,1.1c-2.3,1.9-4.1,5.5-3.9,9.3c0.3,3.9,1.2,6.3,2.3,8.6c1.1,2.2,2.3,4.4,4.3,7c-1.1-3.2-1-5.5-0.6-7.6
					C-28.7,287.1-27.9,285.2-26.6,282.8z"
                  />
                </g>
                <g>
                  <path
                    d="M-12.6,277.9c2.3-1.5,3.9-3.4,4.9-5.3c0.9-1.9,1.1-4,0.1-5.4c-1-1.4-3.2-1.8-5.6-0.9c-2.4,0.9-4.9,3.1-6.2,6.1
					c-1.3,3.1-1.8,5.4-2.3,7.6c-0.4,2.2-0.9,4.4-1.5,7.4c1.7-2.6,3.2-4.2,4.8-5.5C-16.7,280.6-15,279.4-12.6,277.9z"
                  />
                </g>
                <g>
                  <path
                    d="M4.7,276.2c2.7-0.3,4.9-1.1,6.6-2.4c1.7-1.3,2.9-2.9,2.7-4.7c-0.2-1.7-1.9-3.2-4.4-3.5c-2.5-0.4-5.9,0.4-8.5,2.6
					c-2.6,2.3-4,4.2-5.4,6c-1.3,1.9-2.6,3.7-4.3,6.3c2.5-1.9,4.5-2.7,6.5-3.3C-0.1,276.8,1.9,276.5,4.7,276.2z"
                  />
                </g>
                <g>
                  <path
                    d="M-32.6,331.5c-5.9-2.5-12.3-0.7-12.8,2.8c-0.4,3.5,5.4,6.9,11.7,5.9c3.2-0.5,5.5-1.1,7.8-1.5c1.2-0.2,2.2-0.5,3.4-0.5
					c0.6,0,1.2,0,1.8,0.2c0.6,0.2,1.3,0.6,1.7,1.3c-0.2-0.8-0.7-1.5-1.2-2c-0.6-0.5-1.2-0.9-1.8-1.2c-0.6-0.3-1.2-0.6-1.8-0.8
					c-0.6-0.2-1.1-0.5-1.6-0.7C-27.5,333.9-29.6,332.8-32.6,331.5z"
                  />
                </g>
                <g>
                  <path
                    d="M-26.1,344.6c-3.2,0.4-5.9,2.5-7,4.8c-1.2,2.2-0.8,4.4,0.5,5.5c1.4,1.1,3.3,1.1,5.1,0.8c1.8-0.4,3.4-1.3,4.9-3
					c1.6-1.7,3-2.9,4.6-3.9c1.6-1,3.4-1.7,6.2-1.9c-2.7-0.9-4.6-1.4-6.7-1.9C-20.6,344.6-22.8,344.2-26.1,344.6z"
                  />
                </g>
                <g>
                  <path
                    d="M-18.7,352.8c-2.9,1.5-4.7,4.4-5.1,6.8c-0.4,2.5,0.5,4.5,2.1,5.1c1.7,0.7,3.5,0.1,5.1-0.9c1.6-1,3-2.5,4-4.7
					c1-2.2,2-3.9,3.1-5.4c1.2-1.5,2.6-2.9,5.2-4.2c-2.9,0.2-5,0.5-7.1,0.9C-13.6,350.8-15.8,351.3-18.7,352.8z"
                  />
                </g>
                <g>
                  <path
                    d="M-9,360.7c-1.6,2.3-1.7,5.3-0.7,7.3c0.9,2,2.7,3,4.4,2.7c1.7-0.3,3.1-1.5,4-2.9c0.9-1.4,1.2-2.9,0.8-4.8
					c-0.4-2-0.6-3.6-0.5-5.2c0.1-1.6,0.4-3.3,1.7-5.4c-2,1.3-3.5,2.4-5,3.5C-5.8,357-7.4,358.3-9,360.7z"
                  />
                </g>
                <g>
                  <path
                    d="M3.3,368.7c0.3,1.5,1,2.7,2,3.7c1,0.9,2.1,1.5,3.2,1.7c1.1,0.2,2.1,0,2.9-0.4c0.8-0.4,1.3-1.1,1.6-1.9
					c0.6-1.7,0.4-3.4,0.1-4.6c-0.3-1.3-0.8-2.1-2.2-2.7c-1.4-0.7-2.5-1.6-3.3-2.7c-0.8-1.2-1.4-2.6-1.1-4.8c-1.3,1.8-2,3.4-2.6,5.1
					C3.3,363.7,2.7,365.7,3.3,368.7z"
                  />
                </g>
                <g>
                  <path
                    d="M21.2,371c2.3,2.1,5.5,2.7,7.9,2.1c2.4-0.6,3.8-2.2,3.7-3.9c0-1.8-1.2-3.3-2.6-4.4c-1.4-1.1-3.1-1.7-5.3-1.8
					c-2.3-0.1-4.1-0.3-5.8-0.8c-1.7-0.5-3.4-1.3-5.4-3.2c1.1,2.5,1.9,4.3,2.9,6.1C17.7,367,18.8,368.9,21.2,371z"
                  />
                </g>
                <g>
                  <path
                    d="M34.6,366c2.2,1.3,4.7,1.2,6.4,0.2c1.7-1,2.6-2.7,2.3-4.4c-0.3-1.7-1.4-3.1-2.7-4c-1.3-0.8-2.8-1.1-4.7-0.5
					c-1.9,0.6-3.3,1-4.8,1.3c-1.5,0.2-3.1,0.3-5.2-0.4c1.6,1.5,2.7,2.7,3.9,3.9C31.1,363.4,32.4,364.7,34.6,366z"
                  />
                </g>
                <g>
                  <path
                    d="M55.9,357.6c2.9,0.2,5.5-1,7-2.5c1.6-1.6,2.1-3.5,1.4-5.1c-0.7-1.6-2.5-2.5-4.5-2.7c-2-0.2-4.2,0.4-6.2,1.9
					c-2.1,1.5-3.6,2.7-5.2,3.7c-1.6,1-3.3,1.9-6,2.6c2.7,0.3,4.7,0.7,6.6,1.1C51,357,52.9,357.4,55.9,357.6z"
                  />
                </g>
                <g>
                  <path
                    d="M77.2,339.2c4.4-0.5,6.5-4.9,4.7-7.9c-1.8-3-6.6-3.2-9.2,0.4c-2.6,3.7-3.2,5.7-6.3,8.9
					C70.6,339.3,72.7,339.8,77.2,339.2z"
                  />
                </g>
                <g>
                  <path
                    d="M92.4,321c2-0.6,3.2-2.1,3.6-3.7c0.4-1.6-0.1-3.4-1.4-4.6c-1.3-1.2-3-1.6-4.5-1.3c-1.5,0.3-2.9,1.3-3.6,3.1
					c-1.4,3.7-1.5,5.7-3.9,8.9C86.3,321.9,88.3,322.2,92.4,321z"
                  />
                </g>
                <g>
                  <path
                    d="M59.8,305.3c-4.5,1.7-8.5,4.1-11.4,6.4c-2.8,2.3-4.5,4.7-3.8,6.3c0.7,1.6,3.6,2.1,7.4,1.3c3.8-0.8,8.5-3,12.3-6.5
					c3.9-3.5,6.3-6.2,8.8-8.9c2.4-2.7,4.8-5.4,8.3-9.3c-4.2,3.1-7.4,4.7-10.6,6.2C67.6,302.3,64.4,303.6,59.8,305.3z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M363.6,398.2c3.2,1.3,6.2,3.2,8.6,5.5c2.4,2.3,4.3,5,5.4,7.7c1.1,2.8,1.6,5.9,1.3,8.8c-0.1,1.4-0.4,2.8-0.9,4.1
					c-0.4,1.3-1.1,2.4-1.8,3.5c-3,4.3-8.8,7.2-14.8,8.1c-1.5,0.3-3,0.4-4.4,0.4c-1.5,0.1-2.9,0-4.3-0.1c-2.7-0.3-5-1-6.7-2
					c-0.8-0.5-1.5-1.1-2-1.7c-0.5-0.6-1-1.4-1.3-2.2c-0.6-1.7-0.8-3.6-0.5-5.2c0.1-0.8,0.4-1.6,0.7-2.3c0.1-0.4,0.4-0.7,0.5-1
					c0.2-0.3,0.4-0.6,0.7-0.9c1-1.2,2.2-2.1,3.6-2.7c2.6-1.2,5.2-1.4,7-1.4c1.8,0,3,0.1,3.4-0.4c0.4-0.5-0.2-1.8-2.1-3.2
					c-1-0.7-2.3-1.3-4-1.8c-1.7-0.4-3.8-0.6-6.1-0.3c-2.3,0.3-4.9,1.2-7.4,3c-0.6,0.4-1.2,1-1.8,1.5c-0.6,0.6-1.2,1.2-1.6,1.9
					c-1,1.3-1.9,2.9-2.5,4.5c-1.3,3.3-1.6,7.2-0.8,11.2c0.4,2,1.2,4.1,2.4,6c1.3,2,2.8,3.6,4.6,5c3.6,2.8,7.8,4.3,11.9,5.1
					c2.1,0.4,4.2,0.6,6.3,0.7c2.1,0.1,4.3,0,6.4-0.3c2.2-0.2,4.4-0.7,6.6-1.3c2.2-0.6,4.4-1.4,6.6-2.5c2.2-1.1,4.3-2.4,6.3-4
					c2-1.6,3.8-3.5,5.5-5.7c1.6-2.2,2.9-4.6,3.8-7.2c0.9-2.6,1.5-5.2,1.7-7.9c0.4-5.4-0.6-10.7-2.7-15.7c-2.2-5-5.6-9.2-9.6-12.6
					c-4-3.3-8.6-5.8-13.4-7.3c-5-1.6-9.9-2.2-14.5-2.1c-4.6,0-9,0.7-13,1.7c-8.1,2.1-15,5.4-21,9.4c-6,4-11,8.6-15.3,13.5
					c-4.3,4.9-8,10-11.1,15.5c-3.1,5.4-5.8,11.2-8,17.2c-2.2,6.1-3.9,12.4-5.3,19.3c-2.7,13.7-3.8,29-3.8,47.2
					c0.7-18.2,2.8-33.4,6.3-46.6c1.8-6.6,3.9-12.7,6.5-18.3c2.6-5.7,5.5-10.9,8.9-15.7c3.4-4.8,7.3-9.3,11.6-13.4
					c4.4-4.1,9.3-7.7,14.8-10.7c5.5-3,11.8-5.2,18.5-6.4c3.4-0.5,6.9-0.8,10.4-0.5C356.7,396.1,360.3,396.8,363.6,398.2z"
                  />
                </g>
                <g>
                  <path
                    d="M331,455.1c1.7,0.6,3,1.3,4.3,2.2c1.2,0.9,2.2,1.9,2.9,2.8c0.3,0.5,0.5,0.9,0.7,1.3c0.2,0.4,0.2,0.8,0.3,1.2
					c0.1,0.8,0,1.8-0.4,2.8c-0.2,0.5-0.4,1.1-0.7,1.6c-0.3,0.5-0.6,0.9-1,1.4c-0.7,0.9-1.6,1.6-2.4,2.1c-0.4,0.2-0.8,0.4-1.1,0.6
					c-0.4,0.1-0.7,0.2-1,0.3c-0.3,0-0.6,0.1-0.9,0c-0.3,0-0.7-0.1-1-0.2c-0.7-0.3-1.4-0.7-1.9-1.2c-0.5-0.5-1-1.1-1.2-1.7
					c-0.6-1.2-0.6-2.9-0.3-4.3c0.3-1.4,0.8-2.6,1.2-3.6c0.4-1,0.7-1.7,0.4-2.4c-0.2-0.6-1-1-2.5-0.9c-1.4,0.2-3.4,0.9-5.3,2.9
					c-0.9,1-1.9,2.2-2.6,3.7c-0.7,1.6-1.2,3.4-1.2,5.6c0,4.3,2.1,9.1,6.4,12.3c1.1,0.8,2.3,1.5,3.6,2.1c1.3,0.5,2.9,0.9,4.4,1.1
					c1.5,0.1,3,0.1,4.5-0.1c1.5-0.3,2.9-0.7,4.3-1.2c2.7-1.2,5.2-2.9,7.2-5c1-1.1,2-2.3,2.8-3.6c0.8-1.3,1.5-2.6,2-4.1
					c1.1-2.9,1.7-6.3,1.2-9.9c-0.5-3.6-2.1-7-4.2-9.5c-2.1-2.6-4.6-4.5-7.3-5.9c-1.3-0.7-2.7-1.3-4.1-1.8c-1.4-0.5-2.8-0.8-4.1-1.1
					c-5.7-1.1-10.9-0.6-15.4,0.5c-2.3,0.6-4.4,1.3-6.3,2.2c-2,0.9-3.8,1.8-5.5,2.9c-6.8,4.3-11.4,9.7-15.1,15.3
					c-3.7,5.7-6.4,11.9-8.7,18.9c-2.3,7-4,15-6,24.4c2.8-9.2,5.4-16.9,8.5-23.4c3.1-6.5,6.6-11.9,10.7-16.4c4.1-4.5,9-8.3,14.8-10.6
					c1.5-0.6,3-1.1,4.6-1.5c1.6-0.4,3.2-0.6,4.9-0.8C324,453.6,327.6,453.9,331,455.1z"
                  />
                </g>
                <g>
                  <path
                    d="M404.9,411.1c2.3,2.3,5.4,3.5,7.9,3.5c2.5,0,4.5-1,5-2.7c0.5-1.7-0.4-3.6-2-5.1c-1.6-1.6-3.9-2.8-6.7-3.3
					c-2.9-0.6-5-1-7-1.6c-2.1-0.6-4.1-1.4-6.7-3.2c1.9,2.5,3.2,4.4,4.4,6.3C401.2,406.8,402.5,408.7,404.9,411.1z"
                  />
                </g>
                <g>
                  <path
                    d="M409.7,430.4c0.3,2.7,1.1,4.8,2.2,6.6c1.1,1.8,2.6,3.2,4.3,3.3c1.7,0.1,3.4-1.5,4-4.4c0.6-2.8-0.2-6.6-2.6-9.4
					c-2.5-2.9-4.6-4.3-6.7-5.5c-2.1-1.2-4.2-2.3-7.3-3.5c2.5,2.1,3.7,4.1,4.5,6.1C408.9,425.6,409.4,427.6,409.7,430.4z"
                  />
                </g>
                <g>
                  <path
                    d="M403.3,444.1c-1,2.4-1.5,4.6-1.4,6.7c0,2,0.4,4,1.8,5.1c0.7,0.5,1.6,0.7,2.6,0.4c0.5-0.1,1.1-0.3,1.6-0.7
					c0.5-0.3,1.1-0.8,1.6-1.3c2-2.1,3.4-6,2.6-9.8c-0.8-3.8-2.1-6.1-3.4-8.2c-1.4-2.1-2.8-4-5.2-6.4c1.5,3,1.7,5.3,1.5,7.4
					C404.8,439.5,404.3,441.5,403.3,444.1z"
                  />
                </g>
                <g>
                  <path
                    d="M390,450.7c-2.1,1.7-3.5,3.8-4.1,5.9c-0.6,2-0.6,4.1,0.6,5.4c1.2,1.3,3.4,1.3,5.6,0.1c2.2-1.2,4.4-3.7,5.3-6.8
					c0.9-3.3,1.1-5.6,1.3-7.8c0.2-2.3,0.3-4.5,0.6-7.6c-1.3,2.8-2.6,4.5-4.1,6.1C393.8,447.5,392.2,448.9,390,450.7z"
                  />
                </g>
                <g>
                  <path
                    d="M373.1,454.6c-2.6,0.6-4.7,1.8-6.2,3.2c-1.5,1.5-2.5,3.3-2.1,5c0.4,1.7,2.3,2.9,4.9,2.9c2.6,0,5.8-1.2,8.1-3.7
					c2.3-2.6,3.4-4.6,4.5-6.7c1.1-2,2.1-4,3.4-6.8c-2.2,2.2-4.1,3.3-6,4.1C377.8,453.4,375.8,453.9,373.1,454.6z"
                  />
                </g>
                <g>
                  <path
                    d="M402.9,395c6.1,1.8,12.3-0.9,12.3-4.5c0-3.5-6.2-6.1-12.3-4.3c-3.2,0.9-5.3,1.8-7.5,2.5c-1.1,0.4-2.2,0.8-3.3,0.9
					c-0.6,0.1-1.2,0.1-1.8,0c-0.6-0.1-1.3-0.5-1.8-1.1c0.3,0.8,0.9,1.4,1.5,1.9c0.6,0.4,1.3,0.7,2,1c0.6,0.2,1.3,0.4,1.9,0.6
					c0.6,0.2,1.1,0.3,1.7,0.5C397.6,393.2,399.8,394.1,402.9,395z"
                  />
                </g>
                <g>
                  <path
                    d="M394.7,382.8c3.1-0.8,5.5-3.3,6.3-5.6c0.9-2.4,0.2-4.4-1.3-5.3c-1.5-0.9-3.4-0.7-5.1-0.1c-1.7,0.6-3.2,1.7-4.5,3.6
					c-1.4,1.9-2.6,3.3-4,4.4c-1.4,1.1-3.1,2.2-5.9,2.7c2.8,0.5,4.8,0.8,6.9,1C389.3,383.5,391.5,383.6,394.7,382.8z"
                  />
                </g>
                <g>
                  <path
                    d="M386.4,375.6c2.7-1.9,4.1-4.9,4.2-7.4c0.1-2.5-1.1-4.4-2.8-4.8c-1.7-0.4-3.5,0.4-5,1.5c-1.5,1.2-2.6,2.9-3.3,5.2
					c-0.7,2.4-1.5,4.1-2.4,5.8c-1,1.6-2.2,3.2-4.6,4.8c2.8-0.6,4.8-1.2,6.9-1.8C381.5,378.3,383.7,377.5,386.4,375.6z"
                  />
                </g>
                <g>
                  <path
                    d="M375.7,369.1c1.3-2.5,1-5.5-0.2-7.3c-1.2-1.9-3.1-2.6-4.7-2.1c-1.7,0.5-2.9,1.9-3.6,3.3c-0.7,1.5-0.8,3-0.2,4.9
					c0.6,1.9,1,3.5,1.2,5.1c0.1,1.6,0,3.3-1,5.5c1.8-1.6,3.2-2.8,4.5-4.1C373.1,373.1,374.4,371.7,375.7,369.1z"
                  />
                </g>
                <g>
                  <path
                    d="M362.5,362.8c-0.5-1.4-1.4-2.6-2.5-3.4c-1.1-0.8-2.3-1.2-3.4-1.3c-1.1-0.1-2.1,0.2-2.8,0.8c-0.7,0.5-1.2,1.3-1.4,2.1
					c-0.4,1.7,0.1,3.4,0.5,4.6c0.5,1.2,1.1,1.9,2.5,2.4c1.5,0.5,2.7,1.2,3.6,2.3c0.9,1,1.7,2.4,1.8,4.6c1-2,1.6-3.6,2-5.4
					C363.2,367.7,363.5,365.7,362.5,362.8z"
                  />
                </g>
                <g>
                  <path
                    d="M344.5,362.7c-2.6-1.8-5.9-2-8.1-1.1c-2.3,0.9-3.4,2.7-3.2,4.4c0.3,1.8,1.6,3.1,3.1,4c1.5,0.9,3.3,1.3,5.5,1.1
					c2.3-0.2,4.1-0.2,5.8,0c1.8,0.3,3.6,0.8,5.8,2.5c-1.4-2.3-2.5-4-3.7-5.7C348.5,366.3,347.1,364.6,344.5,362.7z"
                  />
                </g>
                <g>
                  <path
                    d="M331.8,369.5c-2.3-1-4.8-0.5-6.4,0.6c-1.6,1.2-2.2,3-1.8,4.7c0.5,1.7,1.8,2.9,3.2,3.6c1.4,0.6,3,0.7,4.7-0.1
					c1.8-0.8,3.2-1.5,4.6-1.9c1.5-0.4,3-0.7,5.2-0.3c-1.8-1.3-3.1-2.3-4.4-3.4C335.6,371.6,334.2,370.5,331.8,369.5z"
                  />
                </g>
                <g>
                  <path
                    d="M311.8,380.6c-2.9,0.2-5.3,1.7-6.6,3.4c-1.4,1.8-1.7,3.8-0.7,5.3c0.9,1.5,2.8,2.1,4.8,2c2-0.1,4.1-0.9,5.9-2.7
					c1.9-1.8,3.2-3.1,4.7-4.3c1.5-1.2,3-2.3,5.6-3.4c-2.8,0-4.7-0.1-6.7-0.3C316.8,380.6,314.7,380.4,311.8,380.6z"
                  />
                </g>
                <g>
                  <path
                    d="M293.1,401.5c-4.3,1.1-5.8,5.7-3.6,8.4c2.2,2.8,7,2.4,9-1.5c2.1-4,2.4-6.1,5.1-9.6C299.6,400.6,297.5,400.4,293.1,401.5
					z"
                  />
                </g>
                <g>
                  <path
                    d="M280.3,421.6c-1.9,0.9-2.9,2.5-3.1,4.2c-0.2,1.7,0.5,3.3,2,4.4c1.4,1,3.2,1.2,4.7,0.7c1.5-0.5,2.7-1.7,3.1-3.6
					c0.9-3.9,0.8-5.9,2.7-9.4C286.3,419.9,284.3,419.9,280.3,421.6z"
                  />
                </g>
                <g>
                  <path
                    d="M314.7,432.9c4.2-2.3,7.9-5.1,10.4-7.8c2.5-2.7,3.8-5.2,2.9-6.7c-0.9-1.5-3.8-1.6-7.5-0.3c-3.7,1.3-8,4.1-11.4,8
					c-3.5,4-5.5,7-7.5,10c-2,3-4,6-7,10.3c3.8-3.6,6.7-5.7,9.7-7.5C307.3,436.9,310.4,435.2,314.7,432.9z"
                  />
                </g>
              </g>
              <g>
                <path
                  d="M344.5,109.3c1.3-4.4,1.9-9.1,1.4-13.7c-0.4-4.6-1.8-9.3-4.3-13.4c-0.3-0.5-0.6-1-1-1.5c-0.4-0.5-0.7-1-1.1-1.5
				c-0.8-0.9-1.5-1.8-2.4-2.7c-1.7-1.7-3.6-3.1-5.6-4.3c-4-2.4-8.3-3.8-12.6-4.4c-4.3-0.6-8.7-0.6-12.9,0.4
				c-4.2,0.9-8.3,2.7-11.6,5.5c-3.3,2.7-5.9,6.2-7.2,10.2c-0.7,1.9-1.1,4.1-1.1,6.1c0,2,0.2,4,0.8,5.9c1.1,3.7,3.3,7,6.3,9.2
				c3,2.2,6.5,3.1,9.6,3c3.1-0.1,6.1-1.1,8.3-2.8c1.1-0.9,2-1.9,2.7-2.9c0.7-1.1,1.2-2.2,1.4-3.2c0.6-2.1,0.5-4.1,0.2-5.7
				c-0.3-1.6-1-2.9-1.6-3.8c-1.4-1.8-2.6-2.1-2.9-1.8c-0.3,0.3,0.2,1.3,0.6,2.9c0.2,0.8,0.3,1.8,0.2,3c-0.1,1.1-0.4,2.4-1,3.7
				c-0.3,0.6-0.7,1.2-1.2,1.7c-0.5,0.5-1.1,0.9-1.7,1.3c-1.3,0.7-3,1.1-4.8,0.9c-1.8-0.2-3.7-0.9-5-2.1c-1.4-1.3-2.5-3.2-2.9-5.3
				c-0.2-1.1-0.3-2.2-0.2-3.4c0.1-1.1,0.4-2.2,0.8-3.3c0.9-2.2,2.6-4.3,4.7-6c2.1-1.6,4.8-2.7,7.7-3.3c2.9-0.6,6.2-0.6,9.4-0.1
				c6.4,1,12.6,4.2,15.9,9.6c3.5,5.4,4.4,12.8,2.7,19.6c-0.4,1.7-1,3.4-1.8,5c-0.7,1.6-1.6,3.1-2.6,4.6c-2,2.9-4.2,5.4-6.7,7.6
				c-5,4.4-10.6,7.5-16.3,9.7c-5.7,2.3-11.4,3.6-17.1,4.6c-5.7,1-11.4,1.5-17.1,1.9c-5.7,0.4-11.5,0.5-17.5,0.9
				c-6,0.4-12.3,1.1-18.6,3c-3.2,0.9-6.4,2.1-9.5,3.8c-3.1,1.6-6.1,3.7-8.8,6.3c-2.7,2.6-5,5.8-6.7,9.4c-1.6,3.6-2.6,7.8-2.2,12.1
				c-0.2-4.3,0.9-8.4,2.6-12c1.8-3.5,4.1-6.6,6.9-9c2.7-2.4,5.7-4.3,8.8-5.8c3.1-1.4,6.2-2.5,9.4-3.2c6.3-1.5,12.4-1.9,18.3-1.9
				c5.9-0.1,11.7,0.2,17.5,0.2c5.8,0,11.6-0.2,17.6-0.8c6-0.7,12.2-1.7,18.5-3.8c6.3-2,12.9-5,19-9.8c3.1-2.4,6-5.2,8.6-8.6
				C340.9,117.8,343.1,113.9,344.5,109.3z"
                />
              </g>
              <g>
                <path
                  d="M278.5,96c-3.9-1.2-8.4-1.3-12.5,0.5c-2,0.9-3.8,2.1-5.3,3.7c-1.5,1.5-2.7,3.3-3.6,5.3c-0.8,2-1.2,4.2-1.1,6.4
				c0.1,2.2,0.7,4.2,1.6,6c1.8,3.5,4.9,6.1,8.4,7c3.5,1,6.9,0.3,9.4-1.4c1.2-0.8,2.2-1.9,2.8-3c0.6-1.1,0.9-2.2,1-3.1
				c0.2-1.9-0.3-3.4-0.9-4.2c-0.6-0.8-1.2-1-1.6-0.9c-0.8,0.3-0.9,2.1-2.2,3.6c-0.3,0.4-0.7,0.8-1.1,1c-0.4,0.3-0.8,0.4-1.3,0.5
				c-1,0.2-2.4,0.1-3.4-0.5c-1.1-0.6-2.2-1.8-2.7-3.2c-0.6-1.5-0.5-2.8,0.1-4.4c0.7-1.6,2.1-3.1,3.8-4c1.7-0.9,3.9-1.2,6.3-0.9
				c4.9,0.7,8.5,4.3,10.6,8.1c2.2,3.9,3,8.1,2.7,12.3c-0.2,2.1-0.6,4.2-1.5,6.2c-0.4,1-1,2-1.6,3c-0.7,0.9-1.4,1.8-2.4,2.7
				c-1.8,1.6-4.1,3-6.9,3.8c-2.7,0.7-6,0.9-9.2-0.3c3.1,1.4,6.5,1.6,9.4,1.1c3-0.5,5.6-1.7,7.8-3.3c1.1-0.8,2.1-1.7,3-2.6
				c0.9-1,1.7-2,2.3-3.1c1.4-2.1,2.2-4.5,2.9-6.9c1.1-4.8,1-10-0.9-15.4c-1-2.7-2.4-5.4-4.7-8C285.7,99.6,282.5,97.2,278.5,96z"
                />
              </g>
              <g>
                <path
                  d="M315.1,122.6c2.7-2.4,4.1-5.8,4.2-8.5c0.1-2.8-0.9-4.8-2.6-5.3c-1.7-0.5-3.6,0.5-5.2,2.2c-1.6,1.7-2.9,3.9-3.7,6.8
				c-0.8,2.9-1.5,5-2.5,7.1c-1,2-2.1,4-4.5,6.4c3-1.5,5.1-2.6,7.3-3.8C310.2,126.3,312.4,125.1,315.1,122.6z"
                />
              </g>
              <g>
                <path
                  d="M366,89.8c2.5-1.7,4.3-3.8,5.4-5.9c1.1-2.1,1.5-4.2,0.4-5.7c-1-1.4-3.3-1.7-5.9-0.7c-2.6,1.1-5.4,3.6-6.8,6.9
				c-1.5,3.5-2,6-2.5,8.4c-0.4,2.5-0.8,4.9-1.4,8.3c1.5-3.1,3.1-4.9,4.7-6.6C361.7,93,363.5,91.6,366,89.8z"
                />
              </g>
              <g>
                <path
                  d="M379.9,95.6c3-0.8,5.4-2.4,6.9-4.3c1.5-1.8,2.2-3.9,1.4-5.5c-0.8-1.6-2.9-2.2-5.4-1.9c-2.5,0.3-5.4,1.7-7.6,4.1
				c-2.3,2.5-3.6,4.4-5,6.2c-1.4,1.8-2.8,3.6-5.1,6c2.9-1.5,5.1-2.3,7.2-2.9C374.6,96.9,376.8,96.4,379.9,95.6z"
                />
              </g>
              <g>
                <path
                  d="M390.9,105.2c3.1,0.2,5.9-0.4,8-1.5c2.1-1.1,3.7-2.6,3.6-4.4c0-1.7-1.7-3.3-4.4-4c-2.6-0.7-6.2-0.4-9.3,1.3
				c-3.2,1.7-5.2,3.3-7.1,4.7c-1.9,1.5-3.8,3-6.6,5c3.3-1.1,5.6-1.4,7.9-1.5C385.4,104.9,387.7,105,390.9,105.2z"
                />
              </g>
              <g>
                <path
                  d="M360.3,116.8c2.7-0.8,4.8-2.3,6.1-4c1.3-1.7,2-3.7,1.2-5.3c-0.7-1.6-2.7-2.4-5.1-2.1c-2.4,0.3-5.2,1.7-7.2,4.2
				c-2,2.5-3.1,4.5-4.2,6.4c-1.1,1.9-2.2,3.8-3.9,6.3c2.5-1.8,4.4-2.7,6.3-3.5C355.5,118.1,357.5,117.6,360.3,116.8z"
                />
              </g>
              <g>
                <path
                  d="M373.4,121.3c2.6,0.1,4.9-0.6,6.6-1.8c1.7-1.2,2.8-2.9,2.6-4.6c-0.2-1.7-1.8-3.1-4.1-3.6c-2.3-0.5-5.2,0-7.7,1.7
				c-2.5,1.8-4.1,3.3-5.6,4.7c-1.5,1.5-3,2.9-5.3,4.7c2.8-0.9,4.8-1.1,6.7-1.2C368.7,121,370.7,121.2,373.4,121.3z"
                />
              </g>
              <g>
                <path
                  d="M293.2,147.9c-6.4,0.5-11.3,5.2-10.1,8.5c1.2,3.3,8,3.6,13.2-0.3c5.3-4,7-6.2,12.8-9.5
				C302.5,148,299.8,147.4,293.2,147.9z"
                />
              </g>
              <g>
                <path
                  d="M300.9,162.8c-1.7,2.4-2,5.5-1.2,7.6c0.7,2.2,2.4,3.5,4.1,3.4c1.8-0.1,3.2-1.3,4.2-2.8c1-1.5,1.5-3.4,1.3-5.6
				c-0.2-2.3-0.3-4.1-0.2-5.9c0.2-1.8,0.5-3.6,1.8-5.9c-2.1,1.6-3.6,2.8-5.1,4.1C304.2,159,302.7,160.4,300.9,162.8z"
                />
              </g>
              <g>
                <path
                  d="M250.7,158.6c5.2-0.2,10.2-0.8,14.1-1.6c3.9-0.8,6.7-2,7-3.7c0.2-1.7-2.4-3.7-6.9-4.6c-4.4-1-10.5-0.7-16.1,1.4
				c-5.7,2.2-9.2,4.4-12.7,6.7c-3.4,2.3-6.7,4.7-11.2,8.5c5.1-2.9,9-4.2,12.8-5.1C241.5,159.3,245.3,158.8,250.7,158.6z"
                />
              </g>
              <g>
                <path
                  d="M452.7,61.6c-0.5-3.2-1.3-6.4-2.5-9.5c-1.2-3.1-2.9-6.2-5.1-8.9c-2.2-2.7-5-5-8.1-6.7c-3.1-1.7-6.7-2.7-10.3-2.8l-1.4,0
				l-1.4,0.1c-0.9,0.1-1.8,0.3-2.7,0.5c-1.8,0.5-3.4,1.1-4.9,2c-3,1.7-5.6,4.2-7.3,7.3c-1.7,3-2.4,6.8-1.9,10
				c0.2,1.6,0.7,3.2,1.3,4.6c0.6,1.4,1.4,2.7,2.2,3.8c3.4,4.5,8.5,7,13.1,7.2c2.3,0.1,4.6-0.3,6.5-1.3c1-0.5,1.8-1.1,2.5-1.8
				c0.7-0.7,1.2-1.5,1.7-2.3c1.5-3.2,1-6,0-7.5c-0.5-0.8-1-1.2-1.4-1.4c-0.4-0.2-0.7-0.2-0.9,0c-0.3,0.3-0.1,1.1-0.1,2.2
				c0,0.6-0.1,1.2-0.4,1.9c-0.3,0.7-0.7,1.5-1.3,2.1c-0.3,0.3-0.7,0.6-1,0.8c-0.3,0.2-0.8,0.4-1.3,0.5c-1,0.2-2.2,0.2-3.5-0.1
				c-2.6-0.6-5.4-2.4-7.1-5.2c-0.4-0.7-0.8-1.4-1-2.2c-0.2-0.8-0.4-1.5-0.4-2.3c-0.1-0.8,0-1.5,0.2-2.2c0.2-0.7,0.5-1.4,0.9-2.1
				c0.8-1.4,2.2-2.6,3.9-3.5c0.8-0.4,1.7-0.8,2.6-1c0.4-0.1,0.9-0.2,1.4-0.2l0.7,0l0.7,0c1.9,0.1,4,0.6,5.9,1.7
				c1.9,1,3.8,2.5,5.3,4.2c3.1,3.5,5.2,8.4,6.4,13.7c1.2,5.5,1.6,10.9,1.4,15.9c-0.2,5-0.9,9.7-1.9,14.1c-2.1,8.8-5.4,16.4-9.6,23.5
				c-4.2,7.1-9.5,13.8-16.3,20.4c-6.8,6.6-15.2,13.2-26.1,19.6c11.2-6,20-12.1,27.3-18.3c7.3-6.2,13.3-12.6,18.3-19.6
				c5-7,9.2-14.7,12.3-23.9c1.5-4.6,2.7-9.6,3.4-15.1C453.5,74.1,453.6,68.1,452.7,61.6z"
                />
              </g>
              <g>
                <path
                  d="M420.9,70.1c-1.7-0.9-3.7-1.5-5.7-1.6c-2.1-0.2-4.2,0.2-6.2,0.9c-1.9,0.7-3.7,1.8-5.2,3.1c-1.5,1.4-2.7,3-3.5,5
				c-0.8,1.9-1.2,4.1-1.1,6c0.1,2,0.6,3.8,1.4,5.5c1.6,3.3,4.3,5.8,7.4,6.9c1.6,0.6,3.2,0.8,4.6,0.6c1.4-0.1,2.7-0.5,3.8-0.9
				c2.2-1,3.7-2.4,4.6-3.8c0.9-1.4,1.2-2.7,1.1-3.6c-0.1-0.9-0.4-1.4-0.8-1.5c-0.9-0.2-1.9,0.9-3.8,1.6c-0.9,0.4-2,0.7-3.2,0.8
				c-1.2,0-2.4-0.2-3.2-0.9c-0.9-0.6-1.7-1.7-2.1-3c-0.2-0.6-0.3-1.3-0.3-2c0-0.6,0.2-1.2,0.4-1.8c0.5-1.2,1.7-2.4,3.2-3
				c0.7-0.3,1.5-0.5,2.2-0.6c0.8,0,1.7,0.1,2.6,0.3c1.9,0.6,3.8,1.9,5.4,3.4c0.8,0.8,1.5,1.6,2.2,2.4c0.7,0.8,1.3,1.7,1.9,2.6
				c2.3,3.5,3.9,7.1,5,10.9c1.1,3.8,1.7,7.8,1.5,12.4c0,0.6-0.1,1.1-0.1,1.7c0,0.6-0.1,1.2-0.2,1.8c-0.1,1.2-0.4,2.4-0.6,3.7
				c-0.6,2.5-1.4,5.2-2.7,8c1.5-2.7,2.7-5.3,3.5-7.8c0.4-1.3,0.8-2.5,1.1-3.7c0.1-0.6,0.3-1.2,0.4-1.8c0.1-0.6,0.2-1.2,0.3-1.8
				c0.8-4.7,0.9-9,0.5-13.3c-0.5-4.3-1.4-8.6-3.3-13.1c-0.5-1.1-1-2.3-1.7-3.4c-0.6-1.2-1.4-2.4-2.2-3.5
				C426.7,74.4,424.4,72,420.9,70.1z"
                />
              </g>
              <g>
                <g>
                  <path
                    d="M503.8,68.5c5.7-0.7,11.4-1.1,16.9-1.7c5.5-0.5,10.7-1.1,15.1-2c4.5-0.8,8.2-1.9,10.7-2.9c2.5-1.1,3.9-2.2,3.7-3
					c-0.2-0.9-1.8-1.4-4.4-1.8c-2.6-0.4-6.3-0.5-10.8-0.6c-4.4-0.1-9.6,0-15.3,0.4c-5.6,0.4-11.8,1.3-17.7,2.9
					c-6.2,1.7-11.2,3.7-15.6,6c-4.3,2.3-8,4.8-11.3,7.8c-0.8,0.7-1.6,1.5-2.4,2.3c-0.8,0.8-1.5,1.6-2.3,2.5
					c-1.5,1.7-2.9,3.6-4.2,5.6c-2.6,4.1-4.9,9.2-5.6,15.5c1.2-6.2,4.1-10.9,7-14.5c1.5-1.8,3.1-3.4,4.7-4.7c0.8-0.7,1.7-1.3,2.5-2
					c0.9-0.6,1.7-1.2,2.6-1.7c3.5-2.2,7.2-3.8,11.4-5.1C493.2,70.2,498,69.2,503.8,68.5z"
                  />
                </g>
                <g>
                  <path
                    d="M512,85.7c5.6-1.4,11.1-3.6,15.9-6.1c4.8-2.5,9.1-5.5,12.4-8.3c6.7-5.7,9.8-10.9,8.6-12.1c-1.2-1.3-6.2,1.4-13.3,5.2
					c-7.1,3.9-16.5,8.8-26.3,13c-5.1,2.2-9.5,3.7-13.6,4.9c-4.1,1.2-8,2.1-11.9,3.3c-3.9,1.1-7.8,2.4-11.9,4.4c-2,1-4.2,2.2-6.2,3.8
					c-2.1,1.5-4.1,3.5-5.8,5.9c1.9-2.2,4.1-3.9,6.3-5.2c2.2-1.3,4.3-2.2,6.4-2.9c4.2-1.4,8.1-1.9,12-2.3c3.9-0.4,7.8-0.5,12.3-1
					C501.2,87.9,506.2,87.1,512,85.7z"
                  />
                </g>
                <g>
                  <path
                    d="M519.6,76.1c6.3-1,12.2-3.4,16.2-6c4.1-2.6,6.3-5.3,5.6-6.9c-0.7-1.6-4.1-1.8-8.7-0.9c-4.5,0.9-10.2,2.7-15.7,5.4
					c-5.7,2.7-9.6,4.6-13.6,6.3c-4.1,1.7-8.3,3.3-14.6,4.9c6.6-0.6,11-0.9,15.5-1.2C508.7,77.4,513.2,77.1,519.6,76.1z"
                  />
                </g>
                <g>
                  <path
                    d="M517.7,72.2c4.9-0.5,9.4-2.3,12.5-4.4c3.1-2.1,4.8-4.4,4.1-6c-0.7-1.6-3.4-2.2-7-1.8c-3.6,0.4-8,1.7-12.2,3.8
					c-4.3,2.3-7.3,3.9-10.4,5.4c-3.1,1.5-6.2,2.9-11.1,4.4c5-0.4,8.5-0.6,12-0.7C509.2,72.8,512.7,72.7,517.7,72.2z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M490.4,213.7c-5.6-1.1-11.2-2.4-16.6-3.5c-5.4-1.2-10.5-2.2-15-2.7c-4.5-0.6-8.4-0.7-11.1-0.5c-2.7,0.3-4.3,0.9-4.5,1.8
					c-0.1,0.9,1.2,1.9,3.6,3c2.4,1.1,5.9,2.4,10.1,3.8c4.2,1.4,9.2,2.9,14.7,4.2c5.5,1.3,11.6,2.3,17.8,2.6c6.4,0.2,11.8-0.1,16.7-1
					c4.8-0.9,9.1-2.1,13.1-4c1-0.4,2-1,3-1.4c1-0.5,2-1.1,2.9-1.7c1.9-1.2,3.8-2.5,5.7-4.1c3.7-3.1,7.4-7.3,10-13.1
					c-3.1,5.6-7.2,9.1-11.1,11.7c-2,1.3-3.9,2.3-5.9,3.1c-1,0.4-2,0.8-2.9,1.1c-1,0.3-2,0.6-3,0.8c-3.9,1-8,1.4-12.4,1.4
					C501.1,215.3,496.2,214.8,490.4,213.7z"
                  />
                </g>
                <g>
                  <path
                    d="M487.9,194.8c-5.8-0.4-11.6,0.1-17,1c-5.4,1-10.3,2.5-14.3,4.2c-8.1,3.4-12.6,7.4-11.8,8.9c0.8,1.6,6.3,0.6,14.3-0.9
					c8-1.5,18.4-3.4,29-4.4c5.5-0.6,10.2-0.7,14.5-0.6c4.3,0.1,8.3,0.4,12.3,0.5c4,0.1,8.2,0.1,12.7-0.6c2.3-0.4,4.6-0.9,7.1-1.7
					c2.4-0.9,4.9-2.1,7.3-3.9c-2.5,1.6-5.1,2.5-7.5,3.1c-2.4,0.6-4.8,0.8-7,0.8c-4.4,0-8.3-0.6-12.1-1.4c-3.8-0.8-7.6-1.9-12-2.8
					C498.8,196,493.9,195.2,487.9,194.8z"
                  />
                </g>
                <g>
                  <path
                    d="M477.7,201.6c-6.3-0.9-12.6-0.4-17.3,0.8c-4.7,1.2-7.6,3.1-7.4,4.9c0.2,1.8,3.4,2.9,8,3.5c4.6,0.5,10.6,0.5,16.6-0.4
					c6.2-0.9,10.5-1.5,14.9-1.9c4.4-0.4,8.9-0.6,15.4-0.3c-6.4-1.4-10.8-2.5-15.2-3.5C488.5,203.7,484.2,202.6,477.7,201.6z"
                  />
                </g>
                <g>
                  <path
                    d="M478.3,205.9c-4.8-1-9.7-0.7-13.3,0.4c-3.6,1-5.9,2.8-5.8,4.5c0.1,1.8,2.6,3.1,6.1,3.9c3.5,0.7,8.1,0.8,12.8,0
					c4.8-0.8,8.1-1.5,11.5-2c3.4-0.5,6.8-0.9,11.9-0.8c-4.9-1.1-8.3-2-11.6-3C486.6,207.9,483.3,206.9,478.3,205.9z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M295.7,319.1c4.1-3.9,8.4-7.8,12.5-11.5c4.1-3.7,7.9-7.3,10.9-10.7c3.1-3.3,5.4-6.4,6.8-8.8c1.4-2.4,1.8-4.1,1.2-4.7
					c-0.6-0.6-2.3-0.1-4.6,1.2c-2.3,1.3-5.4,3.4-9,6c-3.6,2.6-7.7,5.8-12,9.5c-4.2,3.7-8.6,8.1-12.4,13c-3.9,5.1-6.7,9.7-8.9,14.2
					c-2.1,4.4-3.5,8.7-4.4,13c-0.2,1.1-0.4,2.2-0.6,3.3c-0.1,1.1-0.3,2.2-0.3,3.3c-0.2,2.3-0.2,4.6,0,7c0.4,4.8,1.6,10.3,4.8,15.8
					c-2.8-5.7-3.3-11.1-3.1-15.8c0.1-2.3,0.4-4.5,0.9-6.6c0.2-1,0.5-2,0.8-3c0.3-1,0.6-2,1-2.9c1.5-3.8,3.5-7.3,6.1-11
					C288.2,326.9,291.4,323.2,295.7,319.1z"
                  />
                </g>
                <g>
                  <path
                    d="M312.5,328c3.7-4.5,6.7-9.5,9-14.5c2.3-4.9,4-9.8,4.9-14.1c1.9-8.6,1.3-14.5-0.4-14.8c-1.8-0.3-4.2,4.8-7.5,12.2
					c-3.4,7.4-7.9,16.9-13.3,26.2c-2.7,4.8-5.4,8.7-7.9,12.1c-2.6,3.4-5.1,6.5-7.5,9.7c-2.4,3.2-4.8,6.6-6.9,10.7
					c-1,2.1-2,4.3-2.7,6.8c-0.7,2.5-1.2,5.2-1.1,8.2c0.2-3,0.9-5.6,1.9-7.9c1-2.3,2.2-4.3,3.4-6.1c2.5-3.6,5.3-6.4,8.2-9
					c2.9-2.7,5.9-5.1,9.3-8.2C305.2,336.2,308.7,332.7,312.5,328z"
                  />
                </g>
                <g>
                  <path
                    d="M312.9,315.8c4.4-4.6,7.7-10,9.4-14.5c1.7-4.5,1.8-8,0.3-8.8c-1.6-0.9-4.4,1.1-7.5,4.5c-3.1,3.4-6.5,8.3-9.3,13.8
					c-2.9,5.6-4.9,9.4-7.1,13.3c-2.2,3.8-4.6,7.6-8.7,12.7c4.9-4.4,8.3-7.4,11.7-10.3C305,323.4,308.3,320.5,312.9,315.8z"
                  />
                </g>
                <g>
                  <path
                    d="M309,313.8c3.6-3.4,6.2-7.5,7.4-11c1.2-3.5,1.2-6.4-0.3-7.3c-1.5-0.9-4,0.3-6.7,2.7c-2.6,2.5-5.4,6.1-7.4,10.4
					c-2.1,4.4-3.5,7.5-5,10.5c-1.6,3-3.3,6.1-6.2,10.2c3.8-3.4,6.5-5.6,9.2-7.8C302.6,319.3,305.3,317.2,309,313.8z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M112.7,104.3c4.4,3.7,8.6,7.6,12.7,11.2c4.1,3.6,8.1,7,11.7,9.8c3.6,2.7,6.9,4.7,9.5,5.9c2.5,1.1,4.2,1.4,4.7,0.7
					c0.5-0.7-0.2-2.2-1.7-4.4c-1.5-2.2-3.9-5-6.9-8.3c-3-3.3-6.6-7.1-10.7-10.9c-4.2-3.8-8.9-7.7-14.2-11c-5.4-3.3-10.4-5.7-15-7.3
					c-4.6-1.6-9-2.6-13.4-3c-1.1-0.1-2.2-0.1-3.3-0.2c-1.1,0-2.2,0-3.4,0c-2.3,0.1-4.6,0.3-7,0.8c-4.8,0.9-10.1,2.7-15.2,6.4
					c5.4-3.3,10.7-4.4,15.4-4.7c2.3-0.1,4.6,0,6.7,0.2c1.1,0.1,2.1,0.3,3.1,0.5c1,0.2,2,0.4,3,0.7c3.9,1.1,7.6,2.7,11.5,4.9
					C104.2,97.6,108.2,100.5,112.7,104.3z"
                  />
                </g>
                <g>
                  <path
                    d="M105.7,121.9c4.9,3.2,10.2,5.7,15.3,7.5c5.2,1.8,10.2,2.9,14.5,3.4c8.7,1,14.6-0.3,14.7-2c0.1-1.8-5.2-3.6-12.9-6.2
					c-7.7-2.6-17.7-6.1-27.5-10.4c-5.1-2.2-9.2-4.4-12.9-6.6c-3.7-2.2-7-4.4-10.5-6.5C83,99,79.4,97,75.1,95.4c-2.1-0.8-4.5-1.5-7-2
					c-2.5-0.5-5.3-0.6-8.3-0.2c3-0.1,5.7,0.3,8.1,1c2.4,0.7,4.5,1.7,6.5,2.7c3.9,2.1,6.9,4.6,9.8,7.2c2.9,2.6,5.7,5.4,9.1,8.4
					C96.7,115.5,100.6,118.7,105.7,121.9z"
                  />
                </g>
                <g>
                  <path
                    d="M117.9,121c5,3.9,10.8,6.6,15.4,7.8c4.7,1.2,8.1,1,8.8-0.6c0.7-1.6-1.5-4.2-5.3-7c-3.7-2.7-9-5.6-14.7-7.8
					c-5.8-2.3-9.9-3.9-13.9-5.7c-4-1.8-8-3.8-13.6-7.4c4.9,4.4,8.2,7.5,11.5,10.5C109.5,113.9,112.7,117,117.9,121z"
                  />
                </g>
                <g>
                  <path
                    d="M119.5,117c3.7,3.2,8.1,5.3,11.7,6.2c3.6,0.9,6.5,0.5,7.2-1.1c0.8-1.6-0.7-4-3.4-6.4c-2.7-2.4-6.7-4.7-11.1-6.3
					c-4.6-1.6-7.8-2.7-11-3.9c-3.2-1.2-6.4-2.6-10.8-5.1c3.8,3.4,6.2,5.8,8.7,8.3C113.2,111.2,115.6,113.7,119.5,117z"
                  />
                </g>
              </g>
              <g>
                <path
                  d="M46.9,120.2c-4.8-3.3-10.4-5.5-16.3-6.1c-2.9-0.3-5.9-0.2-8.8,0.3c-2.9,0.5-5.8,1.4-8.4,2.9c-2.6,1.5-5.1,3.5-6.9,6.1
				c-0.5,0.7-0.9,1.3-1.2,2c-0.3,0.7-0.7,1.5-0.9,2.2L4,128.7l-0.2,1.1c-0.2,0.8-0.2,1.5-0.3,2.3c-0.1,3,0.5,5.8,1.6,8.3
				c1.1,2.5,2.6,4.6,4.5,6.3c1.8,1.7,4,3,6.2,3.6c2.1,0.7,4.2,1,6.1,0.9c1.9-0.1,3.6-0.4,5.2-0.9c1.5-0.5,2.8-1.1,3.9-1.8
				c2.2-1.3,3.6-2.9,4.3-4c0.7-1.2,0.8-2,0.5-2.2c-0.3-0.3-1.1-0.1-2.2,0.3c-1.1,0.4-2.6,1.1-4.6,1.5c-1,0.2-2,0.4-3.2,0.5
				c-1.1,0.1-2.4,0-3.6-0.2c-1.2-0.2-2.5-0.6-3.6-1.2c-1.2-0.6-2.1-1.4-3-2.4c-1.8-2.1-2.9-5.2-2.6-8.1c0.1-0.3,0.1-0.7,0.2-1
				l0.1-0.5l0.2-0.5c0.1-0.3,0.3-0.6,0.4-0.9c0.2-0.3,0.4-0.6,0.6-0.9c0.9-1.2,2.2-2.2,3.7-3.1c1.6-0.9,3.4-1.5,5.4-1.8
				c2-0.4,4.1-0.5,6.2-0.3c4.2,0.3,8.6,1.6,12.4,3.9c4,2.3,7.2,5.4,9.7,8.7c2.5,3.3,4.3,6.9,5.7,10.4c2.6,7.1,3.3,14.2,2.9,21.3
				c-0.4,7.1-1.8,14.3-4.7,22c-3,7.6-7.4,15.8-14.9,23.8c7.8-7.7,12.8-15.6,16.5-23.2c3.6-7.6,5.7-14.8,6.9-22.3
				c0.6-3.7,0.9-7.5,0.8-11.4c-0.1-3.9-0.5-8-1.6-12.1c-1.1-4.2-2.8-8.5-5.4-12.7C55.4,127.8,51.9,123.6,46.9,120.2z"
                />
              </g>
              <g>
                <path
                  d="M34,153.7c-3,0.6-5.5,2.1-7.5,4c-2,1.9-3.5,4.3-4.3,7c-0.4,1.3-0.6,2.7-0.6,4.1c0,1.4,0.2,2.8,0.7,4.1
				c0.8,2.5,2.4,4.7,4.4,6.1c1,0.7,2,1.2,3.1,1.5c1.1,0.3,2.1,0.4,3,0.4c1.9-0.1,3.4-0.7,4.4-1.5c1-0.8,1.6-1.6,1.8-2.3
				c0.2-0.7,0-1.2-0.3-1.5c-0.7-0.6-1.9-0.4-3.2-0.7c-0.7-0.1-1.4-0.4-2-0.7c-0.3-0.2-0.6-0.4-0.8-0.6c-0.2-0.2-0.4-0.5-0.6-0.8
				c-0.4-0.6-0.7-1.4-0.8-2.3c-0.1-0.4-0.1-0.9,0-1.3c0-0.5,0.2-1,0.3-1.5c0.6-2.1,2.6-4.5,4.7-5.5c1.1-0.5,2.1-0.7,3.3-0.5
				c1.2,0.2,2.3,0.8,3.3,1.6c1,0.8,1.8,1.7,2.5,2.8c0.7,1.1,1.2,2.3,1.6,3.6c0.8,2.6,1,5.5,0.9,8.8c-0.1,3.3-0.7,7-0.7,11.4
				c0.6-4.4,1.7-7.9,2.4-11.2c0.8-3.3,1.3-6.4,1.2-9.6c-0.1-1.6-0.3-3.3-0.8-4.9c-0.5-1.7-1.3-3.4-2.5-5c-1.2-1.6-2.8-3.2-5.1-4.3
				c-1.1-0.6-2.4-1-3.8-1.2C37.1,153.3,35.5,153.4,34,153.7z"
                />
              </g>
              <g>
                <path
                  d="M80.9,129c2-0.8,3.3-2.1,4-3.7c0.7-1.6,0.9-3.4,0.1-5c-0.8-1.5-2.7-2.3-4.8-1.9c-2.1,0.4-4.4,2-5.6,4.5
				c-1.2,2.6-1.6,4.5-1.9,6.4c-0.3,1.9-0.7,3.6-1.1,6.1c1.5-2,2.9-3.1,4.3-4C77.3,130.6,78.8,129.9,80.9,129z"
                />
              </g>
              <g>
                <path
                  d="M87.6,140.1c1.9,0,3.2-0.8,4.1-2.1c0.9-1.2,1.4-2.9,1-4.7c-0.4-1.7-1.8-3-3.7-3.3c-1.9-0.3-4.2,0.4-5.7,2.3
				c-1.5,2-2.3,3.5-3,5c-0.7,1.5-1.3,2.9-2.3,4.8c1.8-1.2,3.2-1.6,4.7-1.9C84.1,140.2,85.6,140.1,87.6,140.1z"
                />
              </g>
              <g>
                <path
                  d="M69.7,113.9c0.7-3.1,0.1-6.2-1.2-8.3c-1.3-2.1-3.2-3.1-4.9-2.7c-1.7,0.5-2.8,2.2-3.3,4.3c-0.5,2.1-0.3,4.6,0.7,7.2
				c1.1,2.6,1.8,4.6,2.5,6.6c0.6,2,1,4.1,1,7.1c1.1-2.9,1.9-4.9,2.8-6.9C68.1,119.2,69,117.1,69.7,113.9z"
                />
              </g>
              <g>
                <path d="M51.6,99.8c-0.9-5.8-6-9.3-9.1-7.5c-3,1.8-2.7,7.7,1.3,11.5c4.1,4,6.3,5.3,9.7,10.1C52.1,108.1,52.6,105.7,51.6,99.8z" />
              </g>
              <g>
                <path
                  d="M25.9,96.2c-2.9-2.2-6.5-3.1-9.2-2.9c-2.8,0.2-4.8,1.4-5.1,3.1c-0.3,1.7,0.9,3.6,3,5c2,1.5,4.9,2.6,8.1,2.9
				c3.3,0.4,5.7,0.6,8.1,1.1c2.4,0.5,4.7,1.1,7.9,2.7c-2.6-2.5-4.3-4.2-6.1-6C30.7,100.2,28.9,98.4,25.9,96.2z"
                />
              </g>
              <g>
                <path
                  d="M-1.6,103.7c-2.7-0.3-5.2,0.9-6.5,2.5c-1.4,1.7-1.5,3.6-0.6,5.1c1,1.5,2.6,2.2,4.2,2.4c1.6,0.2,3.2-0.2,4.8-1.5
				c1.6-1.3,3-2.3,4.4-3c1.4-0.8,3-1.3,5.4-1.4c-2.1-0.9-3.7-1.6-5.5-2.4C3,104.7,1.2,104-1.6,103.7z"
                />
              </g>
              <g>
                <path
                  d="M-13.1,124.3c-2.5,1.7-3.7,4.6-3.5,6.9c0.2,2.4,1.6,3.9,3.3,4.1c1.8,0.2,3.4-0.6,4.6-1.5c1.3-1,2.1-2.2,2.5-4.1
				c0.4-2,0.9-3.5,1.7-5c0.8-1.4,1.9-2.8,4.1-4c-2.5,0.2-4.3,0.5-6.2,0.9C-8.6,122.1-10.6,122.6-13.1,124.3z"
                />
              </g>
              <g>
                <path
                  d="M-9.6,149.9c-0.9,2.6-0.3,5.4,1.1,7.1c1.4,1.7,3.3,2.2,4.9,1.5c1.6-0.7,2.7-2.2,3.2-3.7c0.5-1.5,0.5-3.1-0.4-4.8
				c-0.9-1.8-1.5-3.3-1.9-4.9c-0.3-1.6-0.5-3.2,0.1-5.5c-1.6,1.8-2.7,3.2-3.8,4.6C-7.5,145.6-8.6,147.2-9.6,149.9z"
                />
              </g>
              <g>
                <path
                  d="M3.1,168.8c0.4,2.9,2.4,5.2,4.4,6.2c2.1,1,4.1,0.7,5.2-0.6c1.2-1.3,1.4-3.2,1.1-4.9c-0.3-1.7-1.2-3.3-2.9-4.7
				c-1.7-1.4-3-2.6-4.1-3.9c-1.1-1.3-2-2.9-2.7-5.4c-0.4,2.6-0.7,4.4-1,6.4C2.9,163.8,2.7,165.8,3.1,168.8z"
                />
              </g>
              <g>
                <path
                  d="M75.4,229.7c2.3,1.4,4,3.2,4.8,5.2c0.9,2,0.9,4.3,0.3,5.8c-0.6,1.5-2,2.8-3.6,3.4c-0.8,0.3-1.5,0.4-2.3,0.4
				c-0.8,0-1.6-0.2-2.3-0.6c-1.5-0.6-2.7-1.8-3.4-2.9c-0.7-1.1-0.9-2.1-0.8-2.9c0.1-0.8,0.6-1.6,1.1-2c0.4-0.5,0.9-0.8,1-1.2
				c0.1-0.4-0.3-1-1.5-1.3c-1.1-0.2-3,0.1-4.7,1.8c-0.4,0.4-0.8,1-1.1,1.6c-0.3,0.6-0.5,1.2-0.7,1.9c-0.3,1.4-0.3,2.8,0.1,4.3
				c0.7,2.9,2.5,5.8,5.5,7.9c1.5,1,3.3,1.8,5.3,2.3c2,0.4,4.2,0.4,6.3-0.2c2.1-0.5,4.1-1.5,5.9-3c1.8-1.4,3.3-3.4,4.2-5.7
				c0.9-2.3,1.3-4.7,1.1-7c-0.1-2.3-0.8-4.6-1.8-6.7c-1-2.1-2.5-4-4.2-5.5c-1.7-1.5-3.6-2.6-5.6-3.4c-4.1-1.7-8.1-2-11.5-1.8
				c-3.5,0.2-6.5,0.9-9.3,1.7c-5.5,1.7-10.2,3.7-14.6,6.1c-4.5,2.5-8.8,5.2-13,9c-2.1,1.9-4.3,4-6.3,6.4c-2.1,2.5-4.1,5.2-5.8,8.5
				c2-3.1,4.2-5.7,6.4-7.9c2.2-2.2,4.5-4.1,6.8-5.6c4.5-3.2,9-5.2,13.5-6.9c4.5-1.7,9.2-2.8,14.4-3.6c2.6-0.3,5.2-0.5,7.9-0.3
				c1.3,0.1,2.7,0.3,4,0.7C72.9,228.5,74.2,229,75.4,229.7z"
                />
              </g>
              <g>
                <path
                  d="M55.5,215c4.5-2.5,8.1-6.3,10.1-9.7c2-3.4,2.4-6.3,1-7.5c-1.4-1.1-4.1-0.2-7.1,1.7c-3.1,2-6.5,5-9.6,8.6
				c-3.2,3.8-5.4,6.3-7.9,8.8c-2.5,2.5-5.2,4.8-9.6,7.6c4.8-1.8,8.2-3.1,11.7-4.4C47.5,218.9,50.9,217.5,55.5,215z"
                />
              </g>
              <g>
                <path
                  d="M75.4,216.2c2.3,0.5,4.3,0.1,5.9-0.8c1.6-1,2.7-2.5,2.7-4.3c0-1.8-1.2-3.3-3.1-4.1c-1.9-0.8-4.5-0.8-6.8,0.5
				c-2.4,1.3-3.8,2.5-5.3,3.6c-1.5,1.1-2.9,2.2-5.1,3.5c2.5-0.3,4.3-0.1,6,0.2C71.3,215.2,73,215.7,75.4,216.2z"
                />
              </g>
              <g>
                <path
                  d="M35.3,208.6c0.9-2.4,1.3-5,1.3-7.4c0-0.6,0-1.2-0.1-1.8c-0.1-0.6-0.1-1.1-0.2-1.7c-0.2-1.2-0.6-2.3-1.1-3.4
				c-0.5-1.1-1.1-2-1.9-2.8c-0.7-0.8-1.6-1.3-2.3-1.6c-1.6-0.5-2.6,0-3,0.7c-0.4,0.8-0.3,1.8-0.5,2.6c-0.1,0.9-0.3,1.8-0.4,3
				c-0.1,0.7-0.1,1.4-0.2,2.1c0,0.4-0.1,0.9-0.1,1.3c0,0.5,0,0.9-0.1,1.4c-0.1,1.9-0.1,3.9,0,6c0.1,4.3-0.2,7.5-0.8,10.6
				c-0.7,3.1-1.8,6.3-4.7,10.3c3.7-3.3,5.9-5.9,8.1-8.7c1.1-1.4,2.1-2.8,3.1-4.5C33.4,213,34.4,211.1,35.3,208.6z"
                />
              </g>
              <g>
                <path
                  d="M124.7,53.8c3.3-0.9,6.7-1.7,9-1.4c1.2,0.1,2,0.4,2.7,0.9c0.6,0.4,1.2,1.1,1.6,2c0.9,1.8,1,4.3,0.5,6.2
				c-0.1,0.5-0.3,1-0.5,1.5c-0.2,0.5-0.5,0.9-0.7,1.4c-0.5,0.8-1.2,1.5-1.8,2.1c-1.2,1-2.9,1.5-4.2,1.4c-0.7,0-1.3-0.2-1.8-0.4
				c-0.5-0.3-1-0.6-1.4-1c-1.7-1.6-1.8-3.6-2.6-3.9c-0.4-0.1-1,0.2-1.5,1.2c-0.5,1-0.8,2.8-0.1,4.9c0.3,1,0.9,2.2,1.9,3.2
				c1,1.1,2.2,2,3.8,2.6c3,1.2,6.9,1.2,10.4-0.7c1.7-0.9,3.3-2.2,4.6-3.7c0.7-0.8,1.2-1.6,1.8-2.5c0.5-0.9,1-1.8,1.4-2.9
				c0.8-2.1,1.1-4.3,1.1-6.5c-0.1-2.3-0.5-4.6-1.5-6.9c-1.1-2.3-2.8-4.5-5.2-6c-2.4-1.5-5.1-2.2-7.6-2.3c-2.5-0.1-4.7,0.3-6.8,0.9
				c-2.1,0.6-3.9,1.2-5.7,2c-3.8,1.6-7.6,3.8-10.3,7c-1.3,1.6-2.3,3.5-2.9,5.3c-0.6,1.8-0.9,3.6-0.9,5.3c-0.1,3.4,0.5,6.4,1.3,9.2
				c0.9,2.8,2,5.4,3.4,7.8c2.8,4.8,6.6,9.1,11.7,12.4c2.6,1.7,5.5,3.1,8.7,4.2c3.3,1.1,6.9,1.7,10.9,1.8c-3.9-0.3-7.5-1.3-10.6-2.6
				c-3.1-1.3-5.7-3-8-4.8c-4.5-3.7-7.5-8.1-9.4-12.7c-1-2.3-1.6-4.7-2-7.2c-0.4-2.5-0.5-5,0-7.5c0.2-1.2,0.6-2.4,1.1-3.4
				c0.5-1,1.2-2,2.1-2.8C118.7,56,121.3,54.8,124.7,53.8z"
                />
              </g>
              <g>
                <path
                  d="M86,72c-3-1.1-6.3-0.6-8.4,0.7c-2.1,1.3-3.1,3.2-2.6,4.9c0.5,1.7,2.2,2.8,4,3.3c1.9,0.6,4,0.6,6.4-0.2
				c2.5-0.8,4.3-1.2,6.3-1.4c1.9-0.2,4-0.2,6.8,0.7c-2.3-1.8-4-3.1-5.7-4.3C90.9,74.4,89.1,73.1,86,72z"
                />
              </g>
              <g>
                <path
                  d="M99.5,62.1c0.8-1.3,0.8-2.3,0.3-3.6c-0.5-1.2-1.3-2.8-2.7-3.8c-0.7-0.5-1.6-0.7-2.5-0.6c-0.9,0.1-1.8,0.5-2.6,1.3
				c-0.8,0.8-1.4,1.9-1.7,3.2c-0.3,1.3-0.2,2.8,0.4,4.1c1.2,2.8,2.7,4.1,4.1,5.3c1.4,1.1,2.9,2,4.9,2.9c-1.3-1.8-1.6-3.3-1.5-4.7
				C98.2,64.8,98.7,63.5,99.5,62.1z"
                />
              </g>
              <g>
                <path
                  d="M152.6,18.3c3.1-2.4,6-4.9,8.2-6.9c2.2-2,3.6-3.6,3.1-4.4c-0.4-0.8-2.6-0.5-5.5,1c-2.8,1.4-6.3,4-8.9,7.3
				c-2.7,3.4-4.2,5.9-5.6,8.5c-1.4,2.6-2.7,5.2-4.4,9.2c2.2-3.7,4-6,6-8.1C147.4,22.8,149.4,20.8,152.6,18.3z"
                />
              </g>
              <g>
                <path
                  d="M161.5,23.3c3.2-1.8,6.3-3.8,8.5-5.5c2.2-1.7,3.6-3.2,3.2-4c-0.4-0.8-2.5-0.7-5.2,0.3c-2.8,1-6.2,3-9.1,5.6
				c-2.9,2.7-4.7,4.8-6.5,6.9c-1.7,2.1-3.5,4.3-5.9,7.5c2.8-2.9,5-4.6,7.2-6.1C155.9,26.6,158.1,25.2,161.5,23.3z"
                />
              </g>
              <g>
                <path
                  d="M160,33.4c2.1-0.6,3.9-1.6,5.2-2.7c1.3-1,2-2.1,1.7-3c-0.3-0.8-1.6-1.2-3.4-0.9c-1.7,0.3-3.9,1.2-5.7,2.7
				c-1.8,1.6-2.9,2.8-4,4c-1.1,1.2-2.2,2.4-3.9,4.1c2-1.3,3.5-1.9,4.9-2.5C156.3,34.6,157.8,34.1,160,33.4z"
                />
              </g>
              <g>
                <path
                  d="M207.1,151.4c1.6-3.8,3.4-7.5,4.8-10.2c1.4-2.8,2.4-4.8,1.8-5.4c-0.6-0.6-2.8,0.4-5.2,3c-2.4,2.6-4.7,6.8-5.6,11.4
				c-0.8,4.7-0.7,8-0.3,11.2c0.4,3.2,1,6.4,2.6,10.8c-1.1-4.5-1.1-7.7-0.7-10.8C204.8,158.3,205.5,155.3,207.1,151.4z"
                />
              </g>
              <g>
                <path
                  d="M186.8,193.3c-0.7,3.1-1.2,5.4-1.8,8c-0.5,2.2-0.7,3.9,0,4.4c0.7,0.5,2.3-0.5,3.8-2.8c1.4-2.2,2.6-5.8,2.3-9.3
				c-0.2-3.6-1-6-1.8-8.2c-0.9-2.2-1.9-4.4-3.8-7.3c1.5,3.1,1.8,5.5,2,7.7C187.6,188,187.5,190.3,186.8,193.3z"
                />
              </g>
              <g>
                <path
                  d="M166,263.2c-0.7,3.5-2,6.9-3.1,9.4c-1.1,2.5-2,4.4-1.3,5c0.6,0.6,2.7-0.2,4.9-2.8c1.1-1.3,2.1-2.9,2.8-4.9
				c0.7-1.9,1.2-4.2,1.1-6.4c0-2.3-0.4-4.1-0.9-5.8c-0.5-1.6-1-3-1.7-4.3c-1.4-2.6-3-5.2-6.2-8.1c2.8,3.3,3.8,6,4.4,8.7
				c0.3,1.3,0.4,2.7,0.5,4.2C166.5,259.8,166.4,261.4,166,263.2z"
                />
              </g>
              <g>
                <path
                  d="M249.5,317.1c1.2-0.7,2.4-1.2,3.5-1.4c0.6-0.1,1.1-0.1,1.6-0.1c0.5,0,1,0.2,1.5,0.3c2,0.7,4,2.6,5.2,4.8
				c0.3,0.5,0.5,1.1,0.8,1.7c0.2,0.6,0.4,1.2,0.5,1.8c0.2,1.2,0.3,2.3,0.2,3.3c-0.2,1.9-1.3,3.4-2.5,4.1c-0.1,0.1-0.3,0.2-0.4,0.2
				c-0.1,0.1-0.3,0.1-0.4,0.2c-0.3,0.1-0.6,0.1-0.9,0.2c-0.7,0-1.3-0.1-1.8-0.4c-0.5-0.2-0.9-0.6-1.2-0.9c-0.3-0.4-0.5-0.9-0.5-1.4
				c-0.1-1.1,0.1-2,0.4-2.8c0.3-0.7,0.6-1.3,0.4-1.7c-0.1-0.4-0.8-0.7-2-0.3c-1.1,0.4-2.7,1.6-3.5,3.8c-0.4,1.1-0.6,2.5-0.3,4.1
				c0.3,1.6,1.1,3.2,2.4,4.5c1.3,1.3,3,2.2,4.9,2.7c1,0.2,2,0.3,3.1,0.3c0.5,0,1.1-0.1,1.7-0.2c0.5-0.1,1.1-0.3,1.6-0.4
				c4.2-1.5,7.4-5.3,8.5-10c0.5-2.3,0.6-4.6,0.2-6.8c-0.2-1.1-0.4-2.2-0.8-3.3c-0.4-1.1-0.8-2.2-1.4-3.2c-1.1-2.1-2.5-4-4.4-5.6
				c-1.8-1.6-4.1-3-6.7-3.8c-2.6-0.8-5.5-0.8-8.1-0.1c-2.6,0.7-4.8,2-6.7,3.5c-3.8,3.3-5.9,7.3-7.1,11c-1.1,3.8-1.4,7.4-1.1,10.7
				c0.2,3.3,1,6.4,1.9,9.1c1,2.8,2.1,5.4,3.5,7.8c1.3,2.5,2.7,4.9,4.3,7.3c1.5,2.4,3.1,4.8,4.9,7.4c3.6,5,7.8,10.5,13.5,16.3
				c-5.3-6.2-9-11.9-12.1-17.2c-1.5-2.7-2.8-5.2-4-7.8c-1.2-2.5-2.3-5-3.2-7.5c-0.9-2.5-1.7-5-2.2-7.6c-0.5-2.6-0.7-5.2-0.6-7.9
				c0.2-2.7,0.8-5.5,2-8.1c0.6-1.3,1.3-2.5,2.2-3.7C247.3,318.8,248.3,317.8,249.5,317.1z"
                />
              </g>
              <g>
                <path
                  d="M264.6,361c0.7-2.6,1-4.8,0.9-6.9c-0.1-2.1-0.6-4.1-2-5.2c-0.7-0.5-1.7-0.6-2.7-0.3c-1,0.3-2.1,1.1-3.1,2.3
				c-1,1.2-1.7,2.8-2.1,4.7c-0.4,1.9-0.3,3.9,0.3,5.9c0.6,2,1.4,3.6,2.2,4.9c0.8,1.3,1.7,2.3,2.6,3.3c1.8,1.9,3.7,3.6,6.7,5.5
				c-2.3-2.7-3-5-3.3-7.2C263.8,365.9,263.9,363.7,264.6,361z"
                />
              </g>
              <g>
                <path
                  d="M284.7,370c-3.2,6-5.2,12.1-7.6,17c-1.2,2.5-2.4,4.7-3.7,6.6c-0.7,0.9-1.4,1.8-2.1,2.6c-0.7,0.8-1.4,1.4-2.1,2
				c-1.4,1.1-2.5,1.5-3.5,1.7c-0.3,0.1-0.8,0-1.2-0.1c-0.5-0.2-0.9-0.4-1.2-0.7c-0.7-0.5-1.1-1.1-1.5-1.7c-0.3-0.6-0.5-1.1-0.9-1.3
				c-0.2-0.1-0.4-0.1-0.7,0c-0.3,0.1-0.6,0.4-0.9,0.8c-0.6,0.9-1,2.7-0.3,4.6c0.3,1,0.9,1.9,1.8,2.8c0.9,0.9,2.1,1.8,3.7,2.2
				c1.4,0.4,3,0.5,4.6,0.3c1.6-0.2,3.1-0.7,4.5-1.4c1.4-0.7,2.7-1.6,3.9-2.6c1.2-1,2.3-2.1,3.4-3.3c2.1-2.4,3.9-5.2,5.3-8.2
				c2.9-5.9,4.6-12.1,6.6-17.7c1.1-2.9,2.2-5.3,3.6-7.5c1.4-2.2,2.8-4.2,4.4-5.9c0.7-0.9,1.6-1.7,2.4-2.5c0.9-0.7,1.7-1.5,2.5-2.2
				c0.4-0.3,0.9-0.7,1.3-1c0.4-0.3,0.9-0.6,1.3-0.9c0.9-0.6,1.8-1.2,2.8-1.7c3.8-2.2,8-3.8,13-4.7c1.2-0.2,2.5-0.4,3.9-0.4
				c1.3-0.1,2.7-0.1,4.1,0.1c2.8,0.3,5.9,1.2,8.4,3.6c-2.3-2.6-5.4-3.8-8.3-4.4c-1.5-0.3-2.9-0.5-4.3-0.6c-1.4-0.1-2.7,0-4.1,0
				c-5.2,0.3-9.9,1.3-14.4,3c-1.1,0.4-2.2,0.9-3.3,1.4c-0.5,0.3-1.1,0.5-1.6,0.8c-0.5,0.3-1.1,0.6-1.6,0.9c-1.1,0.6-2.1,1.3-3.2,2
				c-1,0.8-2.1,1.5-3.2,2.3c-2.1,1.7-4.2,3.7-6.2,6C288.2,364.1,286.3,366.9,284.7,370z"
                />
              </g>
              <g>
                <path
                  d="M311.3,353.5c-2.3,1-4.3,2.4-5.9,4.2c-1.6,1.7-2.9,3.8-3.4,5.9c-0.6,2.1-0.3,4.2,0.4,5.5c0.7,1.3,1.7,1.7,2.5,1.5
				c0.9-0.2,1.6-0.8,2.3-1.4c0.7-0.6,1.5-1.2,2.3-1.9c0.9-0.7,1.8-1.6,3-2.7c1.2-1.1,2.4-2.4,3.6-3.7c2.6-2.8,4.9-4.5,7.5-5.8
				c1.3-0.6,2.7-1.3,4.3-1.7c1.6-0.4,3.5-0.7,5.7-0.7c-2.2-0.6-4.1-0.9-5.9-1.1c-1.8-0.2-3.4-0.3-5-0.3c-1.6,0-3.3,0.1-5.1,0.4
				C315.7,352,313.7,352.5,311.3,353.5z"
                />
              </g>
              <g>
                <path
                  d="M333.5,332.9c0.1-2-0.4-3.5-1.3-5c-0.9-1.4-2.2-2.8-3.9-3.1c-0.9-0.2-1.7,0-2.5,0.5c-0.8,0.5-1.5,1.4-1.9,2.6
				c-0.4,1.2-0.6,2.6-0.4,4.2c0.3,1.5,0.9,3.1,2,4.3c2.2,2.6,4.3,3.7,6.2,4.6c1.9,0.9,3.9,1.5,6.5,2.1c-2.2-1.6-3.2-3.2-3.8-4.8
				C333.7,336.7,333.4,335,333.5,332.9z"
                />
              </g>
              <g>
                <path
                  d="M523.9,354.8c0.8-4,0.7-8.3-0.4-12.2c-1-3.9-2.9-7.6-5.4-10.8c-2.5-3.2-5.7-5.8-9.2-7.6c-3.5-1.8-7.3-3-11-3.4
				c-3.8-0.4-7.6-0.2-11.2,0.8c-3.6,0.9-7.2,2.6-10.1,5.5c-1.4,1.4-2.6,3.2-3.4,5c-0.8,1.8-1.3,3.7-1.4,5.5
				c-0.3,3.7,0.5,7.3,2.3,10.3c1.8,3,4.4,5.2,7.1,6.5c2.7,1.3,5.5,1.8,8.1,1.8c2.6-0.1,5-0.8,7-1.8c2-1,3.5-2.4,4.6-3.7
				c2.3-2.7,3.1-5.3,3.4-7.1c0.2-1.8-0.1-2.8-0.5-2.8c-0.5-0.1-1,0.7-1.9,1.9c-0.9,1.2-2.2,3-4.3,4.5c-1,0.7-2.3,1.4-3.7,1.8
				c-1.4,0.4-2.9,0.6-4.5,0.4c-1.6-0.2-3.2-0.7-4.7-1.7c-1.5-0.9-2.7-2.2-3.4-3.7c-0.7-1.5-1-3.5-0.7-5.3c0.1-0.9,0.4-1.8,0.8-2.6
				c0.4-0.8,0.9-1.4,1.6-2.1c2.8-2.6,8.4-3.9,13.5-3.2c5.3,0.6,10.4,3.1,13.9,7.3c3.5,4.2,5.4,9.9,4.7,15.4
				c-0.8,5.7-4.1,10.7-8.1,14.1c-4.1,3.4-9,5.5-13.8,6.4c-4.9,1-9.8,1-14.5,0.4c-4.7-0.6-9.3-1.9-13.8-3.5
				c-4.5-1.6-8.9-3.6-13.5-5.8c-4.5-2.2-9.2-4.6-14.3-6.8c-5.1-2.2-10.8-4.1-17.1-4.8c-3.1-0.3-6.4-0.3-9.8,0.1
				c-1.7,0.3-3.4,0.7-5,1.2c-1.7,0.6-3.3,1.3-4.9,2.2c1.6-0.8,3.3-1.5,5-2c1.7-0.5,3.4-0.8,5-1c3.3-0.3,6.6-0.2,9.6,0.3
				c6.2,0.9,11.5,3.2,16.4,5.6c4.9,2.4,9.4,5.1,13.8,7.7c4.4,2.5,8.8,4.9,13.3,7c4.6,2,9.3,3.8,14.4,4.8c5.1,1.1,10.6,1.5,16.4,0.8
				c5.7-0.7,11.9-2.6,17.5-6.7c2.7-2,5.3-4.5,7.5-7.6C521.4,362.6,523.1,358.9,523.9,354.8z"
                />
              </g>
              <g>
                <path
                  d="M447.8,308.1c-3.2-3.2-7.6-5.5-12.3-6.1c-4.7-0.7-9.8,0.3-13.9,3.4c-2,1.5-3.5,3.4-4.6,5.6c-1.1,2.1-1.7,4.5-1.7,7
				c0,0.6,0.1,1.2,0.1,1.8c0.1,0.6,0.2,1.2,0.4,1.8c0.3,1.2,0.9,2.2,1.4,3.2c1.2,1.9,2.7,3.4,4.5,4.5c1.8,1.1,3.7,1.7,5.7,1.8
				c1.9,0.1,3.9-0.4,5.4-1.3c1.5-0.9,2.6-2,3.3-3.1c0.8-1.1,1.2-2.3,1.5-3.3c0.5-2.1,0-3.9-0.6-4.9c-0.3-0.5-0.6-0.8-0.9-1
				c-0.3-0.2-0.5-0.2-0.7-0.1c-0.2,0.1-0.4,0.3-0.5,0.6c-0.1,0.3-0.2,0.6-0.4,1c-0.3,0.8-0.6,1.7-1.4,2.6c-0.7,0.9-1.9,1.8-3.1,2
				c-1.1,0.2-2.3-0.1-3.5-1.2c-0.6-0.5-1.1-1.2-1.4-2c-0.1-0.4-0.3-0.7-0.3-1.1c0-0.2,0-0.3,0-0.5c0-0.2,0-0.4,0-0.6
				c0.1-1.6,1.2-3.6,2.9-4.8c1.6-1.2,4-1.9,6.7-1.6c2.7,0.2,5.3,1.3,7.7,3.1c2.4,1.8,4.2,4.3,5.3,6.8c1.1,2.5,1.5,5.1,1.3,7.6
				c-0.3,5-2.7,9.6-6.2,12.9c-3.5,3.3-8.6,5.3-14.4,5.7c-5.9,0.5-12.6-0.9-20.1-3.5c7.3,3.1,13.9,5,20.1,5.2
				c6.2,0.2,12-1.1,16.8-4.5c2.4-1.7,4.5-3.8,6.2-6.3c1.7-2.5,3.1-5.4,3.7-8.8C456.1,323.3,454.5,314.8,447.8,308.1z"
                />
              </g>
              <g>
                <path
                  d="M458.9,328.3c-2.4,2.5-3.5,5.9-3.3,8.5c0.2,2.7,1.6,4.5,3.3,4.7c1.8,0.2,3.4-0.9,4.8-2.5c1.3-1.6,2.3-3.7,2.8-6.3
				c0.5-2.7,1-4.7,1.8-6.7c0.8-1.9,1.8-3.8,4.1-6.1c-2.9,1.3-4.9,2.4-6.9,3.5C463.4,324.6,461.4,325.8,458.9,328.3z"
                />
              </g>
              <g>
                <path
                  d="M463.1,362.7c0.5,3.2,2.7,5.9,5,6.9c2.3,1.1,4.4,0.7,5.5-0.7c1-1.4,1-3.4,0.6-5.1c-0.5-1.8-1.4-3.3-3.1-4.8
				c-1.7-1.6-3-3-3.9-4.5c-1-1.6-1.8-3.4-1.9-6.2c-0.9,2.7-1.4,4.7-1.9,6.8C462.9,357.2,462.6,359.4,463.1,362.7z"
                />
              </g>
              <g>
                <path
                  d="M490.6,369c1.7,0.6,3.5,0.7,5.2,0.5c1.7-0.3,3.1-0.9,4.2-1.7c2.3-1.7,2.9-3.9,2-5.4c-0.9-1.5-2.7-2.2-4.6-2.6
				c-1.9-0.3-3.8-0.2-6.1,0.5c-2.3,0.7-4.3,0.9-6.3,0.8c-2-0.2-4-0.7-6.5-2.5c1.7,2.6,3.2,4.3,4.9,5.9
				C485.2,366,487.1,367.7,490.6,369z"
                />
              </g>
              <g>
                <path
                  d="M448.5,349.2c-1.6-0.6-2.7-0.5-4,0.1c-1.3,0.6-2.7,1.6-3.5,3.2c-0.4,0.8-0.5,1.7-0.2,2.5c0.2,0.9,0.8,1.7,1.7,2.4
				c0.9,0.7,2.1,1.2,3.5,1.3c1.3,0.1,2.8-0.1,4.1-0.8c2.7-1.5,3.9-3.1,5-4.5c1.1-1.5,1.9-3.1,2.9-5.2c-1.8,1.5-3.4,1.9-4.9,1.9
				C451.6,350.1,450.2,349.9,448.5,349.2z"
                />
              </g>
              <g>
                <path
                  d="M441,389.9c-1.2,1.3-3,2.2-4.8,2.4c-1.7,0.3-3.6,0-5.4-0.8c-1.8-0.7-3.5-2-4.7-3.3c-1.2-1.3-1.9-2.6-2-3.5
				c-0.2-1,0.1-2.1,0.4-2.7c0.3-0.6,0.9-1,1.7-1.1c0.8-0.1,1.5,0,2.1,0.2c0.6,0.2,1.1,0.4,1.5,0.3c0.4-0.1,0.8-0.6,0.6-1.7
				c-0.2-1-1-2.4-2.7-3.4c-0.8-0.5-1.9-0.8-3.2-0.9c-1.3-0.1-2.7,0.3-4,1.1c-2.7,1.6-4.5,4.5-4.8,8c-0.3,3.6,1.1,7,3.1,9.7
				c2,2.7,4.7,4.9,8,6.4c3.3,1.5,7.3,2.2,11.4,1.2c3.9-0.9,7.6-3.3,9.9-6.8c0.6-0.9,1.1-1.9,1.4-2.9c0.4-1,0.6-2,0.7-2.9
				c0.3-1.9,0.2-3.7-0.1-5.3c-0.6-3.2-1.9-5.9-3.3-8.1c-1.5-2.2-3.2-4.1-5-5.6c-1.8-1.5-3.7-2.8-5.7-3.8c-4-2.1-8.4-3.3-13.2-3.8
				c-4.9-0.5-10.3-0.3-16.5,0.5c12.6-0.7,21.4,1.7,27.8,6.5c3.2,2.4,5.8,5.4,7.4,9.2c0.8,1.9,1.3,3.9,1.3,5.9
				C442.8,386.8,442.3,388.7,441,389.9z"
                />
              </g>
              <g>
                <path
                  d="M457.3,391.4c0,2.2,1.1,3.9,2.5,4.7c1.5,0.9,3.3,0.9,4.8,0c1.5-0.9,2.4-2.4,2.6-4c0.2-1.5-0.4-3.1-1.9-4.3
				c-1.5-1.2-2.7-2.1-3.9-3c-1.1-0.9-2.1-2-3.1-3.7c0,2-0.2,3.5-0.4,5C457.7,387.6,457.4,389.2,457.3,391.4z"
                />
              </g>
              <g>
                <path
                  d="M431.7,410.6c-2.8,1.3-4.6,3.9-5.2,6.2c-0.6,2.3,0.1,4.3,1.6,5.2c1.5,0.9,3.5,0.6,5.2-0.3c1.7-0.9,3.3-2.4,4.4-4.7
				c1.1-2.3,2-4,3.1-5.6c1.1-1.6,2.4-3.1,4.8-4.7c-2.7,0.6-4.7,1-6.8,1.5C436.6,408.7,434.5,409.2,431.7,410.6z"
                />
              </g>
              <g>
                <path
                  d="M389.6,226.7c1.7,0.3,3.4,0.1,5-0.1c1.5-0.3,2.9-0.8,4-1.3c2.2-1.1,3.4-2.3,3.2-3.1c-0.3-0.9-2-1.1-4.3-0.9
				c-2.3,0.2-5.1,0.7-7.8,1.1c-2.8,0.4-4.9,0.2-6.8-0.5c-1-0.3-1.9-0.8-2.9-1.6c-0.9-0.7-1.9-1.8-2.6-3.3c0.4,1.6,1.1,2.9,1.9,3.9
				c0.8,1,1.7,1.9,2.6,2.6c0.9,0.7,2,1.4,3.2,1.9C386.4,226,387.9,226.5,389.6,226.7z"
                />
              </g>
              <g>
                <path
                  d="M392.5,238.2c1.9,2.5,3.7,4.8,5.3,6.6c1.6,1.7,2.9,2.9,3.7,2.5c0.8-0.3,0.8-2.2-0.1-4.7c-1-2.4-3-5.4-5.8-7.5
				c-2.9-2.2-5-3.2-7.2-4.2c-2.2-1-4.4-1.9-7.8-2.8c3.1,1.5,5,2.9,6.7,4.4C389.1,234,390.6,235.6,392.5,238.2z"
                />
              </g>
              <g>
                <path
                  d="M351.7,217.7c-0.7,2.4-1.3,4.7-1.7,6.6c-0.4,1.9-0.5,3.4,0.2,3.9c0.7,0.5,2.2-0.2,3.6-2.1c1.4-1.8,2.4-4.8,2.3-7.9
				c-0.2-3.1-0.8-5.1-1.4-7.1c-0.7-1.9-1.5-3.8-3.1-6.3c1,2.7,1.2,4.7,1.2,6.6C352.7,213.3,352.5,215.2,351.7,217.7z"
                />
              </g>
              <g>
                <path
                  d="M446.3,284.3c3.7-0.6,7.2-2.4,9.4-4.3c2.2-1.9,3-3.7,2.5-4.4c-0.6-0.7-2.4-0.1-4.7,0.8c-2.3,1-5.3,2.3-8.3,3.6
				c-3.2,1.4-5.5,2-7.9,2.5c-2.4,0.4-5,0.7-8.7,0.1c3.6,1.1,6.2,1.5,8.8,1.8C439.8,284.8,442.4,284.9,446.3,284.3z"
                />
              </g>
              <g>
                <path
                  d="M455.1,296.1c2.5,0.2,5-0.7,6.5-1.9c1.5-1.2,2-2.6,1.4-3.3c-0.6-0.7-1.8-0.7-3.3-0.4c-1.5,0.2-3.2,0.7-5.1,1.3
				c-1.9,0.6-3.4,0.9-4.9,0.9c-1.5,0-3.1-0.1-5.3-1.1c1.9,1.4,3.3,2.3,4.9,3C450.8,295.2,452.5,295.8,455.1,296.1z"
                />
              </g>
              <g>
                <path
                  d="M512.7,278.9c-0.8,1.7-2.2,3.2-3.7,4.4c-1.5,1.2-3.3,2.3-4.8,3.4c-1.5,1.1-2.8,2.1-3.5,3c-0.8,0.9-1,1.6-0.8,2
				c0.3,0.4,1,0.3,2,0.1c1.1-0.2,2.5-0.7,4.2-1.4c1.7-0.7,3.8-1.6,5.9-3.2c2-1.6,4-3.9,4.8-6.9c0.4-1.5,0.4-3,0.2-4.4
				c-0.3-1.3-0.8-2.5-1.4-3.4c-1.2-1.9-2.8-3.1-4.4-4c-3.3-1.8-7-2.2-12.2-2.6c2.6,0.5,4.7,0.9,6.5,1.6c1.8,0.7,3.3,1.5,4.5,2.6
				c1.2,1.1,2.2,2.4,2.8,3.9C513.5,275.5,513.5,277.2,512.7,278.9z"
                />
              </g>
              <g>
                <path
                  d="M421,118.9c2.1-3.8,2.7-8.3,2.3-11.5c-0.4-3.3-1.6-5.2-2.4-5c-0.9,0.2-1.3,2.2-1.8,4.9c-0.5,2.8-1.2,6.3-2.2,9.9
				c-1.1,3.7-2.3,6.2-3.8,8.5c-1.5,2.3-3.4,4.6-6.9,6.9c3.8-1.9,6.2-3.7,8.4-5.7C416.7,125,418.8,122.8,421,118.9z"
                />
              </g>
              <g>
                <path
                  d="M423,162.8c5.4,0.3,10.9,0.4,15,0.3c4.1-0.1,6.9-0.6,7-1.5c0.1-0.9-2.6-2-6.7-2.7c-4.2-0.7-9.8-1.1-15.4-0.5
				c-5.7,0.6-9.6,1.3-13.5,2.1c-3.9,0.8-7.8,1.6-13.5,3c5.8-0.8,9.8-1,13.7-1.1C413.6,162.4,417.4,162.5,423,162.8z"
                />
              </g>
              <g>
                <path
                  d="M428.4,172.9c6.4,1.8,13.2,1.4,13.5-0.3c0.2-1.7-6.2-4-12.9-4c-6.9,0-9.5,0.3-16.5-0.1
				C419.4,169.9,421.8,171,428.4,172.9z"
                />
              </g>
              <g>
                <path
                  d="M465.2,185.4c3.3-1.4,6.4-3,8.7-4.4c2.3-1.4,3.8-2.7,3.5-3.5c-0.3-0.8-2.3-1-5.1-0.3c-2.8,0.7-6.3,2.2-9.3,4.5
				c-3.1,2.4-5,4.3-6.8,6.1c-1.8,1.9-3.7,3.9-6.2,6.8c2.9-2.6,5.1-4,7.4-5.3C459.5,188,461.8,186.9,465.2,185.4z"
                />
              </g>
              <g>
                <path
                  d="M479.4,189.5c3.5-0.2,7-0.5,9.6-1c2.6-0.4,4.5-1,4.6-1.9c0.1-0.9-1.7-1.8-4.6-2.3c-2.8-0.5-6.7-0.3-10.4,0.8
				c-3.7,1.2-6.2,2.3-8.5,3.5c-2.4,1.2-4.7,2.5-8,4.6c3.5-1.6,6.1-2.3,8.6-2.8C473.3,190.1,475.8,189.7,479.4,189.5z"
                />
              </g>
              <g>
                <path
                  d="M496,177.4c5.3-1.4,9.4-5.2,8.5-6.8c-0.9-1.5-6-0.2-10.3,2.8c-2.2,1.5-3.8,2.6-5.3,3.7c-1.6,1-3.2,2-5.8,3.2
				c2.7-0.7,4.6-1.1,6.5-1.5C491.4,178.5,493.3,178.1,496,177.4z"
                />
              </g>
              <g>
                <path
                  d="M508.6,181.3c2.3,0.5,4.6,0.6,6.3,0.4c1.8-0.2,3.1-0.7,3.3-1.5c0.2-0.9-0.9-1.9-2.7-2.6c-1.8-0.7-4.4-1.1-6.9-0.6
				c-2.6,0.4-4.3,1-6,1.5c-1.8,0.6-3.6,1.2-5.8,2c2.6-0.3,4.3-0.3,6-0.1C504.5,180.5,506.2,180.8,508.6,181.3z"
                />
              </g>
              <g>
                <path
                  d="M515.5,191.4c5.5,1.8,11.5,1.5,11.8-0.3c0.3-1.7-5.2-4-11-4.1c-6-0.1-8.3,0.3-14.4-0.2
				C507.8,188.4,509.8,189.5,515.5,191.4z"
                />
              </g>
              <g>
                <path
                  d="M537.8,184.7c2.8,0.1,5.6-0.6,7.4-1.6c1.9-1,2.9-2.2,2.6-3c-0.3-0.8-1.8-1.1-3.7-1c-2,0.1-4.4,0.5-6.9,1.3
				c-2.5,0.8-4.3,1.3-6.2,1.7c-1.9,0.4-3.8,0.6-6.6,0.6c2.8,0.6,4.7,1,6.6,1.3C533,184.3,535,184.6,537.8,184.7z"
                />
              </g>
              <g>
                <path
                  d="M537.5,176.3c2.8-0.1,5.5-1.1,7.4-2.2c1.8-1.1,2.8-2.4,2.4-3.2c-0.4-0.8-1.9-1-3.8-0.7c-2,0.2-4.4,0.9-6.9,1.9
				c-2.5,1-4.3,1.6-6.1,2.2c-1.8,0.5-3.7,1-6.6,1.2c2.9,0.3,4.8,0.5,6.8,0.7C532.6,176.2,534.6,176.4,537.5,176.3z"
                />
              </g>
              <g>
                <path
                  d="M535.8,166.5c2.7-0.6,5.2-1.8,6.8-3c1.7-1.3,2.5-2.6,2.1-3.4c-0.8-1.6-6.1-0.4-10.6,2.3c-2.3,1.4-4,2.4-5.6,3.4
				c-1.7,0.9-3.4,1.8-6.1,2.9c2.8-0.5,4.7-0.8,6.7-1C531.1,167.3,533,167.1,535.8,166.5z"
                />
              </g>
              <g>
                <path
                  d="M557.3,160.8c1.9-0.2,3.7-0.8,5-1.5c1.3-0.7,2.2-1.5,2.2-2.4c-0.1-0.9-1.2-1.6-2.9-1.7c-1.7-0.2-3.9,0.3-5.8,1.5
				c-1.9,1.2-3.1,2.3-4.2,3.4c-1.1,1.1-2.2,2.1-3.7,3.7c1.8-1.2,3.2-1.7,4.6-2.1C553.9,161.3,555.3,161,557.3,160.8z"
                />
              </g>
              <g>
                <path
                  d="M366.2,478.6c3.4-1,6.8-2.1,9.3-3.2c2.5-1.1,4.2-2.1,4-3c-0.1-0.9-2.1-1.3-5-1.1c-2.9,0.3-6.7,1.3-10,3.2
				c-3.4,2-5.6,3.6-7.7,5.3c-2.1,1.7-4.2,3.4-7,6.1c3.2-2.3,5.6-3.5,8-4.5C360.2,480.5,362.6,479.6,366.2,478.6z"
                />
              </g>
              <g>
                <path
                  d="M377.5,483.6c2.3,0.1,4.5-0.2,6.2-0.7c1.7-0.5,2.9-1.2,2.9-2.1c0-0.9-1.2-1.7-3.1-2.1c-1.9-0.4-4.4-0.3-6.8,0.6
				c-2.4,0.9-4,1.7-5.6,2.5c-1.7,0.9-3.3,1.8-5.3,2.9c2.4-0.7,4.2-1,5.9-1.1C373.5,483.5,375.1,483.5,377.5,483.6z"
                />
              </g>
              <g>
                <path
                  d="M400.9,489.4c2.5,0.8,5,1.3,7,1.3c2,0.1,3.5-0.3,3.7-1.1c0.3-0.8-0.8-2-2.7-2.9c-1.9-0.9-4.6-1.6-7.4-1.6
				c-2.9,0-4.8,0.3-6.8,0.5c-2.1,0.2-4.1,0.5-6.7,0.8c2.8,0.2,4.7,0.6,6.6,1.1C396.6,487.9,398.4,488.5,400.9,489.4z"
                />
              </g>
              <g>
                <path
                  d="M404,480.2c5.8,1.9,12.1,1.7,12.4,0c0.3-1.7-5.5-4.1-11.6-4.3c-6.3-0.2-8.7,0.1-15.2-0.5
				C395.8,477.1,398,478.2,404,480.2z"
                />
              </g>
              <g>
                <path
                  d="M405.2,471.3c1.9,0,3.8-0.8,4.9-1.8c1.1-1,1.6-2.1,1.2-2.9c-0.4-0.8-1.5-1.1-2.8-1c-1.3,0.1-2.9,0.5-4.3,1.4
				c-1.5,0.9-2.6,1.5-3.8,2c-1.2,0.5-2.4,0.9-4.3,1.2c1.7,0.3,3,0.5,4.5,0.7C401.8,471.2,403.2,471.4,405.2,471.3z"
                />
              </g>
              <g>
                <path
                  d="M326.4,598.7c3.6-0.2,7.2-0.8,10.9-1.8c3.6-1,7.3-2.7,10.5-5.2c1.6-1.3,3.1-2.8,4.3-4.4c1.2-1.7,2.2-3.5,3-5.4
				c1.5-3.8,2.1-7.8,1.7-11.8c-0.2-2-0.7-3.9-1.4-5.8c-0.7-1.9-1.7-3.6-2.8-5.1c-2.2-3.1-5.1-5.5-8.2-7.3c-1.6-0.8-3.2-1.5-4.9-2
				c-1.7-0.5-3.4-0.7-5.1-0.8c-3.4-0.1-6.7,0.5-9.7,1.8c-2.9,1.4-5.5,3.4-7.2,6.1c-1.7,2.6-2.4,5.5-2.4,8.1c0,2.7,0.8,5.3,2.2,7.2
				c0.7,1,1.5,1.8,2.4,2.5c0.9,0.6,1.8,1.1,2.7,1.4c3.6,1.3,6.6,0.5,8.1-0.6c1.6-1.1,1.9-2.1,1.6-2.4c-0.3-0.4-1.2-0.1-2.5,0
				c-0.7,0-1.5,0-2.4-0.2c-0.9-0.2-1.9-0.6-2.8-1.2c-0.9-0.6-1.7-1.5-2.1-2.5c-0.5-1.1-0.6-2.4-0.4-3.8c0.2-1.4,0.8-2.9,1.8-4.1
				c0.9-1.1,2.4-2.1,4.1-2.7c1.7-0.6,3.8-0.8,5.8-0.6c1,0.1,2,0.3,3,0.7c1,0.4,2,0.8,2.9,1.4c1.9,1.2,3.6,2.7,4.9,4.6
				c1.3,1.9,2,4,2.3,6.3c0.2,2.3-0.1,4.8-1,7.1c-0.4,1.1-1,2.2-1.7,3.2c-0.7,1-1.5,1.8-2.5,2.7c-3.9,3.3-9.9,5-16.1,5.8
				c-6.3,0.8-12.3,0.4-17.8-0.8c-5.5-1.2-10.3-3.2-14.8-5.6c-9-4.9-16.6-10.8-25.2-15.5c-8.6-4.7-18.1-7.9-29-9.2
				c-5.4-0.6-11.2-0.8-17.4-0.2c-6.1,0.6-12.7,2-19.4,4.5c6.8-2.2,13.3-3.3,19.4-3.6c6.1-0.3,11.8,0.2,17.1,1.1
				c10.5,1.9,19.4,5.7,27.3,10.8c3.9,2.6,7.7,5.5,11.5,8.5c3.9,3,7.9,6.1,12.5,9.1c4.6,3,9.9,5.7,16,7.5
				C312.1,598.2,319,599.2,326.4,598.7z"
                />
              </g>
              <g>
                <path
                  d="M279.9,561.6c4.2-1.3,8.3-3.6,11.6-6.8c1.7-1.6,3.1-3.5,4.3-5.6c1.2-2.1,2-4.4,2.3-7c0.7-5.2-1.5-10.8-6.2-13.8
				c-2.4-1.4-4.9-1.9-7.1-1.8c-2.2,0.1-4.1,0.5-6,1.3c-1.8,0.8-3.5,2-4.9,3.6c-1.3,1.6-2.1,3.7-2.1,5.7c0,3.9,2.4,6.6,4.7,7.6
				c2.3,1,4.2,0.5,5.3-0.1c1.1-0.6,1.3-1.3,1.2-1.6c-0.2-0.4-0.8-0.6-1.5-0.9c-0.7-0.3-1.7-0.8-2.4-1.6c-0.3-0.4-0.5-0.8-0.6-1.4
				c-0.1-0.5-0.1-1.1,0.1-1.5c0.2-0.4,0.5-0.7,1-1.1c0.5-0.3,1.2-0.7,2.1-0.9c0.9-0.2,2-0.3,2.9-0.2c1,0.1,1.7,0.4,2.2,0.8
				c0.5,0.4,0.9,1,1.2,1.8c0.2,0.4,0.3,0.9,0.3,1.3c0,0.4,0,0.9,0,1.5c-0.3,2.1-1.7,4.8-3.7,7c-2,2.2-4.7,4.1-7.8,5.4
				c-3.1,1.4-6.4,2.2-9.5,2.6c-3.2,0.4-6.2,0.5-9.2,0.4c-5.9-0.1-11.4-0.8-17-1.1c-5.6-0.3-11.4-0.3-17.6,0.8
				c-6.2,1.1-13,3.1-20.3,7c7.5-3.4,14.4-4.8,20.5-5.3c6.2-0.4,11.6,0.2,17,1.3c5.4,1,10.7,2.4,16.9,3.4c3.1,0.5,6.5,0.9,10.2,0.9
				C271.4,563.3,275.5,563,279.9,561.6z"
                />
              </g>
              <g>
                <path
                  d="M303.9,501.7c1.1-5.3,2.3-10.4,3.1-14.4c0.8-4.1,1.2-7.3-0.3-8.3c-1.4-1-4.6,0.8-7.4,4.9c-1.4,2.1-2.6,4.7-3.4,7.7
				c-0.8,3-1.1,6.3-0.8,9.6c0.3,3.4,0.9,6.2,1.7,8.6c0.7,2.4,1.6,4.5,2.6,6.5c1,2,2,4,3.3,6.1c1.3,2.1,2.8,4.3,4.9,6.8
				c-1.6-2.8-2.6-5.2-3.3-7.5c-0.7-2.3-1.1-4.3-1.3-6.4c-0.2-2.1-0.2-4.1-0.1-6.3C303,506.9,303.3,504.5,303.9,501.7z"
                />
              </g>
              <g>
                <path
                  d="M320.9,499.7c0.3-3.4-0.1-6.9-1.1-10c-0.9-3.1-2.4-5.8-4.1-7.9c-1.6-2.1-3.4-3.4-4.9-4.1c-1.5-0.7-2.6-0.7-3.3-0.1
				c-1.3,1.3-0.3,4.3,0.8,8.2c0.6,2,1.2,4.2,1.9,6.6c0.6,2.4,1.3,5,1.8,7.7c0.6,2.8,0.8,5.2,0.9,7.5c0.1,2.3,0,4.4-0.3,6.5
				c-0.5,4.2-1.6,8.5-4.5,14.4c3.8-5.3,6.1-9.2,8.1-13.3c1-2.1,1.9-4.2,2.7-6.7C319.9,506,320.6,503.2,320.9,499.7z"
                />
              </g>
              <g>
                <path
                  d="M312.1,497.4c0.7-3.6,0.6-7.3,0-10.1c-0.6-2.7-1.6-4.4-2.5-4.3c-0.9,0.1-1.4,1.9-1.7,4.6c-0.3,2.6-0.4,6-0.2,9.5
				c0.2,3.6,0.2,6.1,0.2,8.6c-0.1,2.5-0.3,5.1-1.2,8.8c1.3-3.6,2.2-6,3-8.5C310.5,503.6,311.3,501.1,312.1,497.4z"
                />
              </g>
              <g>
                <path
                  d="M310,493.7c0.9-3.1,1-6.3,0.6-8.7c-0.4-2.4-1.2-4-2.1-3.9c-0.9,0-1.6,1.6-2.1,3.8c-0.5,2.3-0.8,5.2-0.8,8.3
				c0,3.1,0,5.3-0.2,7.5c-0.2,2.2-0.4,4.4-1.2,7.6c1.2-2.9,2.1-5,3.1-7.2C308.2,499,309,496.9,310,493.7z"
                />
              </g>
              <g>
                <path
                  d="M302.6,488.7c1.5-2.6,2.9-5.1,3.9-7.2c1-2,1.6-3.6,1-4.3c-0.6-0.6-2.4,0-4.3,1.7c-1.9,1.8-3.9,4.8-4.7,8.1
				c-0.8,3.5-0.9,5.9-0.8,8.3c0.1,2.4,0.3,4.7,1,8.1c-0.2-3.4,0.2-5.7,0.8-7.9C300.3,493.4,301.1,491.4,302.6,488.7z"
                />
              </g>
              <g>
                <path
                  d="M305.8,559.5c1.5-1.7,2.7-3.7,3.5-5.7c0.8-2,1.3-4,1.4-5.7c0.3-3.4-0.5-5.7-1.4-5.7c-0.9,0-1.6,2-2.7,4.8
				c-1.1,2.8-2.5,6.3-4.4,9.7c-2,3.5-3.9,5.6-6.1,7.6c-2.2,1.9-4.8,3.6-9.1,4.9c4.4-0.8,7.4-1.9,10.1-3.3c1.4-0.7,2.7-1.5,4.2-2.5
				C302.7,562.6,304.2,561.3,305.8,559.5z"
                />
              </g>
              <g>
                <path
                  d="M341,541.1c4.9-0.6,9.7-1.1,13.4-1.5c3.7-0.4,6.3-0.9,6.4-1.8c0.1-0.8-2.4-2-6.4-2.4c-3.9-0.5-9.3-0.1-14.4,1.4
				c-5.2,1.6-8.5,3.3-11.7,5c-3.2,1.8-6.4,3.7-10.8,6.9c4.6-2.8,8.1-4.1,11.5-5.2C332.6,542.5,336,541.7,341,541.1z"
                />
              </g>
              <g>
                <path
                  d="M361.1,546c3.3,0.2,6.6,0,9.1-0.3c2.5-0.4,4.3-1,4.3-1.9c0.1-0.9-1.6-1.8-4.2-2.3c-2.6-0.5-6.1-0.6-9.6,0.1
				c-3.5,0.7-5.9,1.5-8.2,2.3c-2.5,0.8-4.8,1.6-8.1,2.8c3.6-0.7,6-0.8,8.5-0.9C355.2,545.7,357.6,545.8,361.1,546z"
                />
              </g>
              <g>
                <path
                  d="M368.5,552.6c2.6,0.8,5.2,1.2,7.2,1.2c2,0,3.6-0.3,3.8-1.2c0.2-0.8-0.9-2-2.8-2.9c-2-0.9-4.8-1.6-7.6-1.5
				c-2.9,0.1-4.9,0.4-6.9,0.7c-2.1,0.3-4.2,0.7-6.9,1.1c2.9,0.1,4.9,0.4,6.8,0.8C364,551.3,365.9,551.8,368.5,552.6z"
                />
              </g>
              <g>
                <path
                  d="M371.3,563.5c1.5,1.5,2.9,2.8,4,3.8c1.2,1,2.2,1.7,3.1,1.5c0.8-0.3,1.2-1.8,0.4-3.8c-0.7-2-2.7-4.1-5.1-5.1
				c-2.5-1.1-4.2-1.3-6-1.6c-1.7-0.3-3.4-0.5-5.9-1c2.3,1,3.8,1.8,5.2,2.7C368.4,561,369.8,562,371.3,563.5z"
                />
              </g>
              <g>
                <path
                  d="M370.1,580.9c0.2,2.4,0.6,4.7,1.1,6.5c0.5,1.8,1.1,3.1,2,3.2c0.9,0.1,1.8-1.2,2.2-3.3c0.4-2.1,0.2-5-1-7.6
				c-1.2-2.6-2.4-4.2-3.6-5.8c-1.2-1.5-2.5-3-4.4-5c1.5,2.3,2.2,4.1,2.7,5.8C369.5,576.6,369.8,578.4,370.1,580.9z"
                />
              </g>
              <g>
                <path
                  d="M364.6,598.8c-0.5,1.7-0.6,3.5-0.3,4.8c0.2,1.4,0.8,2.4,1.7,2.6c0.9,0.2,1.8-0.6,2.5-2c0.6-1.4,1-3.4,0.6-5.3
				c-0.4-2-0.9-3.3-1.3-4.7c-0.5-1.3-0.9-2.6-1.5-4.5c0.1,2-0.1,3.3-0.4,4.7C365.5,595.7,365.1,597,364.6,598.8z"
                />
              </g>
              <g>
                <path
                  d="M353.3,601c-3.3,3.3-5.1,7.7-3.7,8.8c1.4,1.1,5.4-1.9,7.3-6.4c1.9-4.6,2.2-6.5,4.2-11.1
				C358.2,596.4,356.7,597.5,353.3,601z"
                />
              </g>
              <g>
                <path
                  d="M340.2,602.7c-2.6,1.1-5,2.5-6.7,3.8c-1.7,1.3-2.7,2.6-2.3,3.4c0.4,0.8,2,0.9,4.2,0.3c2.2-0.6,4.8-1.9,7.1-3.8
				c2.3-2,3.8-3.5,5.2-5c1.5-1.5,2.9-3,5.1-5.1c-2.5,1.7-4.4,2.7-6.2,3.6C344.8,600.8,342.9,601.6,340.2,602.7z"
                />
              </g>
              <g>
                <path
                  d="M316.4,606.9c-2.8,0-5.6,0.4-7.6,1c-2.1,0.6-3.5,1.4-3.5,2.2c0,0.9,1.5,1.6,3.7,1.9c2.2,0.3,5.2,0.1,8.1-0.9
				c2.9-1,4.8-1.9,6.7-2.8c2-1,4-1.9,6.6-3.1c-3,0.8-5,1.1-7.1,1.3C321.3,606.8,319.3,606.9,316.4,606.9z"
                />
              </g>
              <g>
                <path
                  d="M233,569.1c5.4,0.9,10.6,2.2,14.6,3.2c4,1,6.8,1.7,7.2,0.8c0.4-0.7-1.8-2.8-5.8-4.8c-4-1.9-9.8-3.5-15.7-3.6
				c-6,0-10.1,0.7-14.1,1.6c-4,0.9-8,2.1-13.5,4.5c5.7-1.9,9.8-2.4,13.7-2.6C223.5,568,227.4,568.2,233,569.1z"
                />
              </g>
              <g>
                <path
                  d="M251.9,584.4c1.8,1.9,3.6,3.7,5.1,5.1c1.5,1.3,2.8,2.2,3.6,1.8c0.8-0.4,0.8-2.1-0.2-4.2c-1-2.1-3.1-4.6-5.8-6.1
				c-2.8-1.5-4.8-2.2-6.9-2.7c-2.1-0.5-4.1-0.9-7.1-1.1c2.9,0.8,4.7,1.8,6.4,2.9C248.6,581.1,250.1,582.4,251.9,584.4z"
                />
              </g>
              <g>
                <path
                  d="M68,396c1.6-0.2,3.2-0.3,4.9-0.5c1.6-0.1,3.3-0.2,4.8-0.2c3.1,0,6.1,0.2,8.8,0.7c2.7,0.5,5.1,1.4,6.9,2.6
				c0.9,0.6,1.7,1.2,2.3,1.9c0.3,0.3,0.6,0.7,0.9,1.1c0.2,0.4,0.4,0.7,0.6,1.1c0.7,1.6,1.1,3.6,0.9,5.5c-0.1,1-0.3,1.9-0.6,2.8
				c-0.3,0.9-0.8,1.8-1.3,2.6c-1.1,1.7-2.5,3.2-4.2,4.4c-1.7,1.2-3.4,2.1-5.2,2.7c-1.8,0.6-3.7,0.8-5.3,0.7c-1.7-0.1-3.2-0.4-4.5-1
				c-2.5-1-4.3-3.3-5.3-5.4c-1-2.1-1.2-4.1-1.3-5.6c0-0.7-0.1-1.4-0.1-1.9c-0.1-0.5-0.2-0.9-0.5-1.1c-0.3-0.2-0.7-0.2-1.2,0
				c-0.5,0.2-1.1,0.7-1.8,1.5c-0.6,0.8-1.3,1.9-1.7,3.4c-0.4,1.4-0.7,3.2-0.5,5.1c0.1,1.9,0.7,4.1,1.7,6.3c0.3,0.5,0.6,1.1,0.9,1.6
				c0.4,0.5,0.7,1.1,1.2,1.6c0.5,0.5,0.9,1.1,1.4,1.5c0.3,0.2,0.5,0.5,0.8,0.7c0.3,0.2,0.6,0.4,0.8,0.7c2.3,1.8,5.2,3,8.2,3.7
				c3.1,0.7,6.4,0.7,9.7,0.2c3.3-0.6,6.8-1.8,9.9-3.6c3.1-1.9,6.1-4.4,8.5-7.6c1.2-1.6,2.3-3.4,3.1-5.4c0.8-2,1.4-4.1,1.7-6.3
				c0.6-4.4,0.1-9-1.8-13.5c-0.5-1.1-1.1-2.2-1.7-3.2c-0.7-1-1.4-2-2.2-2.9c-0.8-0.9-1.7-1.7-2.6-2.5c-0.5-0.4-0.9-0.7-1.4-1.1
				c-0.5-0.4-1-0.7-1.5-1c-4-2.5-8.2-3.7-12.3-4.3c-4.1-0.6-8.1-0.7-12-0.3c-1.9,0.2-3.8,0.4-5.6,0.7c-1.9,0.3-3.7,0.6-5.5,1
				c-7.6,1.7-14.2,4-20.2,6.7c-6,2.7-11.3,5.7-16.1,8.9c-4.8,3.2-9.1,6.5-13,10.1c-3.9,3.5-7.6,7.2-10.9,11
				c-6.7,7.7-12.6,16.1-18,26.2c-5.4,10-10.4,21.7-14.4,36.1c4.8-14.2,10.6-25.4,16.7-34.7c6.1-9.4,12.7-16.9,19.9-23.6
				c3.6-3.3,7.4-6.3,11.4-9.2c4-2.9,8.4-5.5,13.1-7.9c4.7-2.4,9.8-4.6,15.4-6.4C55.2,398.3,61.3,396.8,68,396z"
                />
              </g>
              <g>
                <path
                  d="M53,455.6c0.5,1.9,0.5,3.8,0.3,5.6c-0.3,1.9-0.8,3.7-1.7,5.4c-0.9,1.7-2,3.1-3.3,4.3c-0.6,0.6-1.3,1.1-2,1.6
				c-0.7,0.5-1.5,0.9-2.3,1.2c-3.2,1.4-6.9,1.6-10,0.9c-1.6-0.3-2.9-0.9-3.9-1.6c-1-0.7-1.6-1.3-2.1-2.1c-0.2-0.4-0.4-0.8-0.5-1.1
				c-0.1-0.3-0.2-0.6-0.1-0.9c0-0.6,0.3-1.5,1-2.3c0.7-0.9,1.6-1.7,2.6-2.4c0.5-0.3,1-0.7,1.5-1c0.5-0.3,0.9-0.5,1.3-0.8
				c0.5-0.3,1-0.6,1.4-0.9c0.2-0.2,0.7-0.2,1.1-0.2c0.4,0.1,0.7,0.2,1,0.4c0.3,0.2,0.5,0.5,0.8,0.6c0.3,0.1,0.7,0.1,1.1-0.4
				c0.4-0.4,0.7-1.4,0.4-2.5c-0.2-1.1-0.9-2.3-2.5-3.3c-1.5-0.9-3.3-1.1-5-1c-0.8,0.1-1.6,0.2-2.4,0.3c-0.8,0.1-1.6,0.3-2.5,0.6
				c-1.7,0.5-3.6,1.3-5.5,2.6c-1.9,1.3-4,3.4-5.2,6.4c-0.6,1.5-1,3.2-1.1,4.8c-0.1,1.7,0.1,3.3,0.6,5c0.5,1.6,1.2,3.2,2.1,4.6
				c0.9,1.5,2.1,2.7,3.3,3.8c2.5,2.2,5.4,3.8,8.4,4.8c6.1,2,13.1,1.9,19.6-0.7c3.2-1.3,6.4-3.2,9-5.8c2.6-2.5,4.7-5.6,6.2-8.9
				c1.5-3.3,2.4-6.9,2.5-10.6c0.2-3.7-0.5-7.5-1.8-10.8c-2.7-7-7.7-12-13.2-14.9c-5.6-2.9-11.3-3.4-16.2-3.1
				c-4.9,0.3-9.3,1.4-13.4,2.8c-4.1,1.3-7.9,3-11.5,4.9c-3.7,1.9-7.1,4.1-10.6,6.6c-3.4,2.5-6.7,5.3-9.9,8.6
				c-3.2,3.3-6.3,6.9-9.3,11.1c-2.9,4.2-5.7,9-8,14.5c2.6-5.3,5.7-9.8,9-13.7c3.2-3.9,6.6-7.1,10-9.9c3.4-2.8,6.9-5.1,10.4-7
				c3.5-1.9,7-3.5,10.6-4.8c3.6-1.2,7.3-2.3,11.1-2.9c3.8-0.7,7.7-1.1,11.5-0.8c3.8,0.3,7.6,1.4,10.5,3.5
				C49.6,448.2,52,451.7,53,455.6z"
                />
              </g>
              <g>
                <path
                  d="M121.8,361.1c10.9,1.6,21.5,3.9,29.6,5.9c0.5,0.1,1,0.2,1.5,0.3c0.5,0.1,1,0.2,1.4,0.4c0.9,0.2,1.8,0.4,2.6,0.6
				c0.8,0.2,1.6,0.3,2.4,0.5c0.7,0.2,1.4,0.3,2,0.4c2.5,0.4,4.2,0.3,4.6-0.5c0.4-0.8-0.5-2.1-2.5-3.9c-0.5-0.4-1.1-0.9-1.7-1.4
				c-0.6-0.5-1.4-0.9-2.1-1.4c-0.8-0.5-1.6-1-2.5-1.5c-0.5-0.2-0.9-0.5-1.4-0.8c-0.5-0.2-1-0.5-1.5-0.7c-8.1-3.8-19.9-6.9-31.8-6.7
				c-3.1,0.1-5.9,0.1-8.5,0.4c-1.3,0.1-2.6,0.2-3.8,0.4c-1.2,0.2-2.4,0.4-3.5,0.5c-1.1,0.2-2.3,0.3-3.3,0.5
				c-1.1,0.2-2.1,0.4-3.2,0.7c-1,0.2-2.1,0.4-3.1,0.7c-1,0.3-2,0.5-3,0.8c-1,0.3-2,0.5-3,0.8c-1,0.3-2,0.6-3,1c-1,0.3-2,0.6-3.1,1
				c-1,0.4-2.1,0.8-3.2,1.2c-1.1,0.4-2.2,0.8-3.3,1.3c-1.1,0.5-2.3,1-3.5,1.5c-0.6,0.3-1.2,0.5-1.8,0.8c-0.6,0.3-1.2,0.6-1.9,0.9
				c-1.3,0.6-2.6,1.2-4,1.9c1.4-0.5,2.8-1,4.1-1.5c0.7-0.2,1.3-0.5,1.9-0.7c0.6-0.2,1.3-0.4,1.9-0.6c1.2-0.4,2.4-0.7,3.6-1
				c1.2-0.3,2.3-0.5,3.4-0.8c1.1-0.2,2.2-0.5,3.3-0.7c1.1-0.2,2.1-0.3,3.2-0.5c1-0.1,2-0.3,3-0.4c1-0.1,2-0.2,3-0.3
				c1-0.1,2-0.2,3-0.2c1-0.1,2-0.1,3-0.1c1,0,2.1,0,3.1-0.1c1.1,0,2.1,0.1,3.2,0.1c1.1,0,2.2,0.1,3.4,0.1c1.2,0.1,2.3,0.2,3.6,0.3
				C116.4,360.4,119,360.8,121.8,361.1z"
                />
              </g>
              <g>
                <path
                  d="M120.9,378.3c10.8,0.9,21.8-0.5,29.7-2.7c7.9-2.2,12.8-5.1,12.3-6.8c-0.4-1.8-5.9-1.9-13.7-1.5
				c-7.8,0.4-18.1,1.3-28.4,2.2c-10.6,0.9-18,1.1-25.5,0.9c-7.5-0.3-15.1-1-26.1-3.4c10.7,3.5,18.2,5.4,25.6,7.2
				C102.3,375.9,109.8,377.4,120.9,378.3z"
                />
              </g>
              <g>
                <path
                  d="M132.4,371.1c6.3,1.3,12.7,1.4,17.5,0.7c4.8-0.7,8-2.3,8-4c0-1.8-3.1-3.3-7.8-4.4c-4.7-1-10.9-1.5-17.2-1
				c-6.5,0.5-10.9,0.9-15.5,1.2c-4.5,0.3-9.1,0.4-15.8,0c6.6,1.4,11,2.6,15.4,3.8C121.5,368.5,125.9,369.7,132.4,371.1z"
                />
              </g>
              <g>
                <path
                  d="M166.3,420.2c1,0,2.1,0,3.1-0.2c1-0.2,2.1-0.4,3.1-0.7c2-0.7,4-1.7,5.8-3.3c1.8-1.5,3.3-3.7,4-6.1
				c0.7-2.4,0.7-4.8,0.3-6.9c-0.4-2.1-1.2-4-2.1-5.7c-0.9-1.7-2-3.1-3.3-4.5c-1.3-1.3-2.8-2.4-4.5-3.3c-1.6-0.8-3.6-1.3-5.3-1.4
				c-3.5-0.2-6.9,1.1-9,3.3c-1.1,1.1-1.8,2.4-2.2,3.6c-0.4,1.2-0.5,2.3-0.4,3.3c0.2,2,1.1,3.4,1.9,4c0.8,0.7,1.5,0.7,1.8,0.4
				c0.3-0.3,0.4-0.9,0.5-1.6c0.1-0.7,0.2-1.6,0.7-2.4c0.4-0.9,1.2-1.8,2.1-2.1c0.9-0.4,2.3-0.6,3.6-0.2c1.3,0.4,2.6,1.3,3.9,2.8
				c1.2,1.5,2.3,3.6,2.6,5.5c0.2,0.9,0.1,1.7-0.1,2.3c-0.2,0.6-0.5,1.2-1.1,1.8c-0.6,0.6-1.5,1.2-2.5,1.7c-0.5,0.2-1.1,0.4-1.6,0.6
				c-0.6,0.2-1.1,0.3-1.8,0.4c-2.6,0.3-5.2,0-7.6-0.7c-2.4-0.7-4.7-1.7-6.8-2.9c-4.1-2.4-7.6-5.4-11-8.5c-3.5-3.1-6.9-6.5-11.2-9.7
				c-2.2-1.6-4.5-3.1-7.3-4.5c-2.7-1.3-5.9-2.4-9.3-2.6c3.5,0.5,6.4,1.9,8.9,3.4c2.5,1.5,4.7,3.3,6.5,5.1c3.8,3.6,6.6,7.3,9.5,11
				c3,3.7,6,7.4,10.3,11c2.2,1.8,4.6,3.4,7.6,4.8C158.6,419,162.2,420.1,166.3,420.2z"
                />
              </g>
              <g>
                <path
                  d="M130.6,425.7c-0.7,1.3-1.4,2.2-2.3,3c-0.9,0.7-1.7,1.2-2.5,1.4c-0.6,0.2-1.4,0.2-2.1,0c-0.7-0.2-1.2-0.4-1.5-0.7
				c-0.3-0.1-0.6-0.7-0.7-1.3c-0.1-0.6-0.1-1.3,0-1.9c0.1-0.6,0.3-1.1,0.4-1.6c0.2-0.5,0.3-1,0.2-1.4c-0.1-0.4-0.4-0.8-1.1-1
				c-0.7-0.2-1.8-0.1-2.9,0.5c-1.2,0.6-2.4,1.7-3.2,3.4c-0.8,1.6-1.3,4-0.4,6.6c0.8,2.4,2.7,4.6,5.1,5.9c2.4,1.3,5.3,1.8,8.3,1.2
				c2.9-0.6,5.4-2.2,7.2-4.2c1.8-2,3.1-4.6,3.7-7c0.6-2.5,0.6-4.8,0.4-6.8c-0.2-2-0.6-3.8-1-5.4c-0.9-3.3-2.2-6.1-3.7-8.6
				c-1.5-2.6-3.3-4.9-5.6-7.3c-1.1-1.2-2.4-2.3-3.8-3.5c-1.4-1.1-3.1-2.2-4.9-3.2c1.7,1.2,3.1,2.6,4.3,3.8c1.2,1.3,2.2,2.6,3,3.9
				c1.7,2.6,2.7,5.1,3.5,7.7c0.7,2.6,1.1,5.2,1.1,8C132.2,419.9,131.9,423.1,130.6,425.7z"
                />
              </g>
              <g>
                <path
                  d="M612.6,399.5c-0.7-3.6-1.9-7.1-3.5-10.4c-1.6-3.3-3.6-6.4-5.8-9.1c-2.2-2.7-4.6-4.8-6.8-6.8c-2.2-1.9-4.2-3.6-5.9-5.2
				c-3.5-3.1-6-6-7.6-8.2c-1.7-2.2-2.6-3.7-3.5-3.5c-0.8,0.1-1.5,2-0.9,5.4c0.3,1.6,0.9,3.6,1.9,5.6c1,2,2.4,4.2,4,6.3
				c3.3,4.3,7.7,8.3,11.3,12.7c3.5,4.3,6.4,9.6,8.4,15.4c1,3,1.7,5.9,2.1,8.6c0.4,2.7,0.7,5.4,0.7,7.9c0,5-0.5,9.6-1.6,14.1
				c-1.1,4.5-2.6,9-4.9,13.8c-2.3,4.8-5.4,9.9-9.8,15.6c4.8-5.3,8.4-10.1,11.3-14.7c2.9-4.6,5.2-9,7-13.5c1.8-4.6,3.3-9.3,4.1-14.8
				c0.4-2.8,0.6-5.7,0.6-8.9C613.7,406.6,613.4,403.2,612.6,399.5z"
                />
              </g>
              <g>
                <path
                  d="M580.2,404.8c-0.8-5.7-1-11.4-0.9-16.8c0.1-5.4,0.4-10.6,0.8-15c0.7-8.9,0.9-15.2-0.8-15.7c-1.7-0.4-4.9,5.1-7.5,14.3
				c-1.2,4.6-2.2,10.1-2.4,16.2c-0.2,6,0.4,12.6,2.2,18.9c1.9,6.5,4.3,11.6,7.1,15.9c1.4,2.2,2.8,4.1,4.3,5.9
				c1.4,1.8,2.9,3.4,4.2,5.1c2.6,3.3,5,6.9,5.9,11.6c0.2,1.2,0.4,2.4,0.4,3.7c0,1.3,0,2.6-0.2,4c-0.4,2.7-1.3,5.6-3,8.4
				c1.9-2.7,3.1-5.5,3.8-8.2c0.4-1.4,0.6-2.7,0.7-4.1c0.1-1.3,0.1-2.6,0.1-3.9c-0.1-2.5-0.7-5-1.5-7.2c-0.8-2.2-1.8-4.3-2.9-6.2
				c-2.2-3.9-4.5-7.5-6.2-11.7C582.4,415.5,581,410.7,580.2,404.8z"
                />
              </g>
              <g>
                <path
                  d="M593.6,391.6c-1-7.1-3.4-13.9-5.9-18.7c-2.5-4.8-4.9-7.5-5.7-7.1c-0.8,0.4,0.2,3.8,1.8,8.7c1.6,4.9,3.7,11.4,5.5,17.9
				c1.8,6.8,2.7,11.6,3.3,16.5c0.5,4.9,0.8,9.9,0.1,17.3c1.2-7.3,1.6-12.4,1.8-17.4C594.6,403.9,594.6,398.9,593.6,391.6z"
                />
              </g>
              <g>
                <path
                  d="M588.3,385.7c-0.4-5.3-1.8-10.5-3.4-14.1c-1.6-3.7-3.4-5.8-4.2-5.5c-0.8,0.3-0.6,3,0.2,6.7c0.7,3.8,1.9,8.6,3.1,13.5
				c1.2,5.1,1.9,8.6,2.3,12.2c0.4,3.6,0.7,7.4,0.3,12.8c0.9-5.4,1.3-9.2,1.6-12.9C588.5,394.8,588.7,391.1,588.3,385.7z"
                />
              </g>
              <g>
                <path
                  d="M583.8,385.6c0.2-8.5-1.7-16.9-3.5-16.8c-1.8,0.1-2.4,8.7-0.9,17.1c1.6,8.6,2.6,11.8,3.8,20.8
				C583,397.7,583.5,394.4,583.8,385.6z"
                />
              </g>
              <g>
                <path
                  d="M38.3,418.2c3.6-0.5,7.1-1.2,9.7-2c2.6-0.8,4.4-1.7,4.3-2.5c-0.1-0.9-2-1.5-4.9-1.6c-2.9-0.1-6.7,0.5-10.3,1.9
				c-3.7,1.5-6,2.8-8.4,4.1c-2.3,1.3-4.7,2.7-8,4.8c3.6-1.6,6.2-2.4,8.7-3C32.1,419.3,34.6,418.8,38.3,418.2z"
                />
              </g>
              <g>
                <path
                  d="M37.7,425.6c3.2-0.1,6.2-0.5,8.6-1.1c2.3-0.5,4-1.2,4-2.1c0-0.9-1.6-1.7-4.2-2.1c-2.6-0.4-6-0.1-9.3,0.9
				c-3.3,1.1-5.5,2.1-7.6,3.2c-2.1,1.1-4.2,2.2-7.3,3.9c3.3-1.2,5.6-1.8,7.9-2.1C32.2,425.9,34.5,425.7,37.7,425.6z"
                />
              </g>
              <g>
                <path
                  d="M59.5,432.6c2.4,0.8,4.7,1.4,6.6,1.6c1.9,0.2,3.4,0.1,3.8-0.7c0.4-0.8-0.5-2.1-2.3-3.2c-1.8-1.1-4.5-2-7.3-2
				c-2.9,0-4.8,0.3-6.8,0.7c-1.9,0.4-3.8,0.8-6.5,1.5c2.8-0.2,4.7,0,6.5,0.4C55.3,431.3,57.1,431.8,59.5,432.6z"
                />
              </g>
              <g>
                <path
                  d="M80.6,446.8c4,0.5,8.1-0.5,10.7-1.9c2.7-1.4,4-3.1,3.6-3.8c-0.4-0.8-2.4-0.5-5-0.1c-2.6,0.4-5.9,1-9.4,1.5
				c-3.6,0.4-6.1,0.3-8.7,0c-2.5-0.4-5.2-1.1-8.6-3.1c3.1,2.4,5.6,3.7,8.1,4.9C73.9,445.3,76.5,446.2,80.6,446.8z"
                />
              </g>
              <g>
                <path
                  d="M84.1,455.5c2.2,0,4.3-0.8,5.6-1.8c1.3-1,1.9-2.2,1.5-3c-0.4-0.8-1.6-1-3.1-0.9c-1.5,0.1-3.2,0.6-4.9,1.4
				c-1.8,0.8-3.1,1.4-4.4,1.8c-1.4,0.4-2.8,0.8-4.9,0.8c2.1,0.5,3.5,0.8,5,1.1C80.4,455.3,81.9,455.5,84.1,455.5z"
                />
              </g>
              <g>
                <path
                  d="M-6.8,468.2c-3.8,3.9-6.3,9-7.3,13c-1,4-0.7,6.8,0.2,7c0.9,0.2,2.1-2.2,3.8-5.5c1.7-3.3,4-7.6,6.8-11.7
				c2.9-4.2,5.4-6.9,8.1-9.3c2.8-2.3,5.8-4.6,11-6.6c-5.3,1.5-8.8,3.2-12.1,5C0.3,462-2.9,464.1-6.8,468.2z"
                />
              </g>
              <g>
                <path
                  d="M-0.6,480.6c-1,1.9-1.8,3.9-2.4,5.8c-0.3,1-0.5,1.9-0.7,2.8c-0.2,0.9-0.3,1.9-0.3,2.8c0,0.9,0.1,1.8,0.4,2.6
				c0.3,0.8,0.7,1.5,1.2,1.9c0.5,0.4,1,0.6,1.4,0.6c0.4,0,0.6-0.1,0.7-0.3c0.2-0.4-0.1-1,0-1.6c0-0.6,0.1-1.5,0.5-2.7
				c0.2-0.6,0.4-1.2,0.7-2.1c0.3-0.8,0.6-1.7,0.8-2.5c0.6-1.8,1.1-3.7,1.7-5.6c1.1-4,2.1-6.7,3.2-9.3c1.2-2.7,2.5-5.3,5-8.9
				c-2.9,3.2-4.8,5.6-6.7,7.9C3.3,474.3,1.5,476.7-0.6,480.6z"
                />
              </g>
              <g>
                <path
                  d="M7.5,492.9c-0.1,2.4,0.6,4.8,1.6,6.4c1,1.6,2.2,2.3,3,2c0.8-0.4,1.1-1.7,1-3.3c-0.1-1.6-0.5-3.6-1.2-5.6
				c-0.7-2.1-1.2-3.6-1.5-5.1c-0.3-1.6-0.5-3.2-0.3-5.6c-0.7,2.3-1.2,3.9-1.6,5.5C8.1,488.7,7.7,490.4,7.5,492.9z"
                />
              </g>
              <g>
                <path
                  d="M19.5,498.4c0.8,2,2.3,3.6,3.7,4.4c1.4,0.8,2.7,0.9,3.3,0.2c0.6-0.7,0.3-1.9-0.3-3.1c-0.6-1.3-1.7-2.7-3-3.9
				c-1.4-1.3-2.4-2.3-3.2-3.3c-0.9-1.1-1.7-2.2-2.5-4.2c0.3,2.1,0.5,3.5,0.7,4.9C18.5,494.9,18.7,496.4,19.5,498.4z"
                />
              </g>
              <g>
                <path
                  d="M36.3,500.3c1.6,0.8,3.4,0.8,4.7,0.4c1.3-0.4,2-1.3,2-2.2c-0.1-0.9-0.9-1.6-1.9-2c-1.1-0.5-2.4-0.7-3.8-0.5
				c-1.5,0.2-2.6,0.2-3.7,0.2c-1.1,0-2.3-0.2-3.8-0.8c1.1,0.9,2.1,1.7,3.2,2.6C33.7,498.7,34.7,499.5,36.3,500.3z"
                />
              </g>
              <g>
                <path
                  d="M173.3,453.4c1.6-3.6,2.3-7.6,2.2-11.5c-0.1-3.9-1-7.8-2.9-11.2c-1-1.7-2.2-3.2-3.6-4.5c-1.5-1.3-3.2-2.3-5.1-2.8
				c-3.8-1.1-7.5-0.3-10.2,1.3c-2.8,1.6-4.7,4.3-5.4,6.7c-0.8,2.5-0.6,4.7-0.2,6.4c0.5,1.7,1.2,2.9,1.8,3.6c0.6,0.7,1.1,0.9,1.2,0.8
				c0.4-0.3-0.5-1.9-0.7-4.8c0-1.4,0.2-3.2,1-4.9c0.8-1.7,2.2-3.3,4.2-4.2c2-1,4.6-1.3,6.8-0.5c2.3,0.8,4.4,2.7,5.8,5.3
				c1.4,2.6,2.2,5.7,2.4,8.9c0.2,3.2-0.3,6.6-1.4,9.7c-1.2,3.2-3,6-4.8,8.5c-1.9,2.5-3.8,4.8-5.6,7.1c-0.9,1.2-1.7,2.3-2.5,3.5
				c-0.4,0.6-0.7,1.2-1.1,1.8l-0.5,0.9l-0.5,0.9c-0.3,0.6-0.6,1.2-0.9,1.8c-0.3,0.6-0.5,1.2-0.8,1.8c-0.6,1.2-0.9,2.5-1.4,3.7
				c-0.8,2.5-1.4,5.1-1.8,7.8c-0.2,1.3-0.3,2.7-0.4,4.1c0,0.7,0,1.4,0,2.1c0,0.7,0,1.4,0,2.2c0.1,2.9,0.5,6,1.3,9.3
				c0.8,3.2,2,6.6,3.8,10c-1.7-3.5-2.7-6.9-3.4-10.1c-0.6-3.2-0.9-6.3-0.8-9.2c0-0.7,0-1.4,0.1-2.1c0.1-0.7,0.1-1.4,0.2-2.1
				c0.2-1.4,0.3-2.7,0.6-4c0.5-2.6,1.3-5,2.2-7.4c1.9-4.7,4.5-9,8.3-13.1c1.9-2.1,4-4.2,6.1-6.7C169.4,460,171.7,457.1,173.3,453.4z
				"
                />
              </g>
              <g>
                <path
                  d="M154.7,445.2c-2.1,1.9-3.9,4-5.1,5.8c-1.2,1.8-1.8,3.3-1.2,4c0.6,0.7,2.2,0.3,4.2-0.9c1.9-1.2,4.1-3.4,5.6-6.1
				c0.8-1.4,1.3-2.5,1.8-3.6c0.5-1.1,0.9-2.1,1.2-3.2c0.3-1.1,0.5-2.2,0.4-3.5c-0.1-1.2-0.5-2.6-1.7-3.7c0.9,1.3,1,2.6,0.8,3.7
				c-0.2,1.1-0.7,2-1.2,2.7c-0.6,0.8-1.2,1.5-2,2.3C156.6,443.3,155.7,444.2,154.7,445.2z"
                />
              </g>
              <g>
                <path
                  d="M157.3,453.9c-1.7,1.7-3.1,3.6-4,5.2c-0.9,1.6-1.2,3-0.6,3.7c0.6,0.6,2.1,0.3,3.7-0.9c1.6-1.1,3.4-3.1,4.4-5.5
				c1.1-2.4,1.7-4.2,2.2-6c0.6-1.9,1.1-3.7,1.8-6c-1.2,2.3-2.3,3.8-3.5,5.1C160.3,450.9,159.1,452.2,157.3,453.9z"
                />
              </g>
              <g>
                <path
                  d="M187.3,434.6c0.8-2.6,0.7-5.4,0.1-7.4c-0.6-2-1.7-3.1-2.5-2.9c-0.9,0.2-1.4,1.5-1.6,3.3c-0.3,1.8-0.4,4-0.3,6.3
				c0.1,2.4,0,4.2-0.2,5.9c-0.3,1.7-0.7,3.5-1.9,5.9c1.6-2.1,2.7-3.7,3.6-5.2C185.5,438.9,186.5,437.3,187.3,434.6z"
                />
              </g>
              <g>
                <path
                  d="M196,440.1c1.4-0.9,2.5-2.1,3.2-3.2c0.7-1.1,1-2.2,0.6-3c-0.5-0.7-1.7-0.9-3.1-0.4c-1.4,0.5-3,1.8-4,3.6
				c-1,1.8-1.4,3.2-1.8,4.5c-0.4,1.3-0.7,2.6-1.2,4.5c1-1.7,1.9-2.6,2.8-3.5C193.5,441.8,194.5,441.1,196,440.1z"
                />
              </g>
              <g>
                <path
                  d="M204.1,446c1.7-0.2,3.1-0.8,4.2-1.5c1.1-0.7,1.8-1.6,1.7-2.5c-0.1-0.9-1.2-1.5-2.7-1.6c-1.5-0.1-3.4,0.4-5,1.6
				c-1.6,1.2-2.5,2.2-3.5,3.2c-1,1.1-1.9,2.2-3,3.4c1.6-1,2.8-1.5,4-1.8C201.1,446.4,202.3,446.2,204.1,446z"
                />
              </g>
              <g>
                <path
                  d="M255.9,442.9c-2.8,2-5.5,3.9-7.6,5.6c-2,1.6-3.4,3-3,3.8c0.3,0.8,2.4,0.8,5.1-0.2c2.7-1,6-3.1,8.4-6
				c2.5-3,3.9-5.3,5.2-7.6c1.2-2.3,2.4-4.7,3.8-8.3c-1.9,3.4-3.5,5.4-5.3,7.2C260.7,439.2,258.8,440.8,255.9,442.9z"
                />
              </g>
              <g>
                <path
                  d="M258,452.6c-2.4,3.6-3.9,7.7-4.5,10.9c-0.5,3.2-0.2,5.5,0.6,5.6c0.9,0.2,2-1.7,3.4-4.4c1.3-2.7,2.9-6.4,4.3-10.1
				c1.5-3.8,2.8-6.4,4.3-9c1.5-2.5,3.1-5,6.1-8.3c-3.4,2.9-5.5,5-7.6,7.1C262.6,446.7,260.5,448.9,258,452.6z"
                />
              </g>
              <g>
                <path
                  d="M264.1,459.8c-1.9,3.8-3,8-3.2,11.2c-0.3,3.2,0.2,5.4,1,5.5c0.9,0.1,1.9-1.8,3-4.6c1.1-2.9,2.3-6.7,3.3-10.6
				c1-4,1.9-6.8,2.9-9.6c1-2.8,2.2-5.5,4.4-9.3c-2.7,3.5-4.4,6-6.1,8.5C267.7,453.4,266.1,455.9,264.1,459.8z"
                />
              </g>
              <g>
                <path
                  d="M266.4,475.3c-1.1,3.9-1.3,8-0.8,11c0.4,3,1.4,5,2.2,4.9c0.9-0.1,1.5-2,2-4.9c0.5-2.8,0.8-6.6,1-10.3
				c0.2-3.9,0.5-6.6,0.9-9.3c0.5-2.7,1.1-5.5,2.6-9.4c-2,3.6-3.2,6.2-4.4,8.8C268.6,468.7,267.5,471.3,266.4,475.3z"
                />
              </g>
              <g>
                <path
                  d="M332.1,491.8c-2.3,0.4-4.4,1.4-5.7,2.6c-1.3,1.2-1.9,2.4-1.4,3.2c0.5,0.8,1.8,0.9,3.4,0.5c1.6-0.3,3.5-1.1,5.3-2.2
				c1.9-1.2,3.2-2,4.6-2.7c1.4-0.7,2.9-1.4,5.1-2c-2.1,0-3.8,0.1-5.6,0.1C336.1,491.3,334.5,491.4,332.1,491.8z"
                />
              </g>
              <g>
                <path
                  d="M338.2,497.8c-2,0.5-2.5,2.8-1.3,4.1c1.2,1.3,3.4,1.1,4.2-0.8c0.8-1.9,0.9-3,2-4.7C341.2,497.3,340.2,497.3,338.2,497.8z
				"
                />
              </g>
              <g>
                <path d="M432.9,506.6c-3.6,0.1-6.5,2.6-5.9,4.2c0.7,1.6,4.1,1.7,7,0c3-1.8,4.1-2.8,7.6-4C438.1,507,436.6,506.6,432.9,506.6z" />
              </g>
              <g>
                <path
                  d="M152,526.9c-2.4-3.5-5.6-6.8-9.9-9.1c-2.1-1.1-4.4-2-6.8-2.5c-2.4-0.5-5-0.6-7.6-0.1c-5.2,0.9-9.7,3.7-12.6,7.9
				c-0.7,1.1-1.3,2.2-1.8,3.4c-0.5,1.2-0.8,2.5-0.9,3.7c-0.3,2.4,0,4.7,0.7,6.8c0.7,2.1,1.8,4,3.3,5.5c1.5,1.5,3.3,2.6,5,3.2
				c3.6,1.1,6.6,0.4,8.6-0.7c2.1-1.1,3.2-2.7,3.5-3.9c0.2-0.6,0.2-1.1,0.1-1.5c-0.1-0.4-0.3-0.7-0.6-0.8c-0.3-0.2-0.6-0.3-1-0.3
				c-0.4,0-0.8,0-1.2-0.1c-0.8,0-1.8-0.1-2.7-0.4c-0.9-0.3-2-0.9-2.4-1.5c-0.2-0.3-0.3-0.7-0.4-1.1c-0.1-0.4-0.1-0.8-0.1-1.4
				c0-0.5,0.2-1,0.4-1.4c0.1-0.2,0.2-0.4,0.3-0.5c0.1-0.2,0.3-0.4,0.5-0.5c0.4-0.4,0.9-0.7,1.5-1c0.6-0.3,1.3-0.5,2-0.6
				c1.4-0.2,3.4,0.1,5.5,0.9c2,0.8,4.2,2.4,6.2,4.4c1.1,1,2.1,2.2,3,3.4c0.9,1.2,1.8,2.3,2.6,3.5c1.5,2.4,2.8,4.7,3.8,7.1
				c2,4.7,3.3,9.3,3.7,14.1c0.5,4.8,0.2,9.7-1.1,15.2c-1.3,5.4-3.8,11.3-8.4,17.4c5.2-5.5,8.6-11.1,10.9-16.5
				c2.3-5.4,3.7-10.6,4.3-15.9c0.6-5.3,0.5-10.8-0.6-16.8c-0.6-3-1.4-6.1-2.7-9.4c-0.7-1.6-1.4-3.3-2.3-5
				C154.2,530.4,153.2,528.7,152,526.9z"
                />
              </g>
              <g>
                <path
                  d="M180.5,567.9c3.9-1.9,7.3-4.1,9.8-6.3c2.5-2.1,4.1-4.2,3.6-5.9c-0.5-1.6-3.3-2.4-7.1-1.7c-3.7,0.7-8.4,3.2-11.7,7
				c-3.4,3.9-5.2,7-6.8,10c-1.6,3.1-3.1,6.2-4.9,10.7c2.8-4.1,5.3-6.3,7.8-8.3C173.8,571.6,176.5,569.9,180.5,567.9z"
                />
              </g>
              <g>
                <path
                  d="M174.5,542.4c0.4-3.8-1-7.4-2.8-9.6c-1.9-2.2-4.1-3-5.6-2.2c-1.6,0.9-2.2,2.9-2.2,5.3c0,2.4,0.6,5.1,1.9,7.8
				c1.3,2.9,2.2,5,2.8,7.3c0.6,2.3,1,4.7,0.6,8.2c1.4-3.3,2.3-5.6,3.2-8C173.2,548.8,174.1,546.3,174.5,542.4z"
                />
              </g>
              <g>
                <path
                  d="M59.8,573.1c-3.7,3.4-7.3,6.6-11.1,9.2c-3.7,2.5-7.5,4.5-10.6,4.9c-3.2,0.5-5.8-0.6-7.3-1.7c-1.6-1.1-2.1-2.3-2.6-2.2
				c-0.2,0.1-0.3,0.4-0.3,1.1c0.1,0.7,0.4,1.7,1.1,2.7c0.7,1.1,1.9,2.2,3.5,3c1.6,0.8,3.7,1.3,5.9,1.3c2.2-0.1,4.5-0.6,6.7-1.5
				c2.2-0.9,4.3-2.1,6.4-3.4c4.2-2.8,8-6.4,11.4-10.2c3.5-3.9,6.3-7.3,8.9-10.2c2.6-2.9,5.1-5.4,7.9-7.7c2.7-2.2,5.7-4.2,9.4-5.8
				c3.7-1.5,8.2-2.7,13.6-2c-5.3-1-10-0.1-13.9,1.2c-3.9,1.3-7.2,3.1-10.3,5.1c-3.1,2-5.9,4.3-8.9,7
				C66.7,566.4,63.5,569.6,59.8,573.1z"
                />
              </g>
              <g>
                <path
                  d="M73.8,568.7c-1.9,3-3.2,6.4-4,9.7c-0.8,3.3-0.9,6.4-0.8,9.1c0.4,5.4,2.1,8.6,2.9,8.4c0.9-0.2,0.8-3.6,1.3-8.4
				c0.3-2.4,0.7-5.1,1.4-8c0.7-2.9,1.7-5.9,3-8.8c1.4-3,2.8-5.4,4.4-7.4c1.6-2,3.2-3.7,4.9-5.3c1.8-1.5,3.7-3,6-4.3
				c2.3-1.3,5.1-2.5,8.6-3.4c-3.5,0.6-6.4,1.6-9,2.6c-2.5,1.1-4.7,2.2-6.8,3.5c-2.1,1.3-4,2.8-6.1,4.7
				C77.8,563.3,75.8,565.6,73.8,568.7z"
                />
              </g>
              <g>
                <path
                  d="M82.3,565.7c-1.2,2.3-2,4.8-2.4,7.2c-0.4,2.4-0.3,4.7,0,6.6c0.6,3.9,2.2,6.1,3,5.9c0.9-0.2,0.9-2.7,1.2-6
				c0.1-1.7,0.4-3.6,0.7-5.6c0.4-2,0.9-4.2,1.5-6.3c0.7-2.2,1.5-4,2.4-5.5c0.9-1.6,1.8-2.9,2.9-4.2c1.1-1.3,2.3-2.5,3.8-3.7
				c1.5-1.2,3.3-2.3,5.7-3.3c-2.4,0.8-4.4,1.7-6.1,2.6c-1.7,0.9-3.2,1.9-4.6,3c-1.4,1.1-2.7,2.3-4.1,3.8
				C84.9,561.6,83.5,563.4,82.3,565.7z"
                />
              </g>
              <g>
                <path
                  d="M126.8,555.4c3.6,0.7,7.5,0,9.9-1.3c2.5-1.3,3.7-2.8,3.3-3.6c-0.4-0.8-2.2-0.7-4.6-0.4c-2.4,0.3-5.3,0.6-8.5,0.9
				c-3.2,0.2-5.5,0-7.8-0.4c-2.3-0.5-4.6-1.3-7.6-3.4c2.6,2.5,4.7,3.9,6.9,5.1C120.8,553.5,123.1,554.6,126.8,555.4z"
                />
              </g>
              <g>
                <path
                  d="M138.6,563.1c1.7,1.4,3.4,2.4,4.9,3.1c1.5,0.7,2.8,0.9,3.4,0.3c0.6-0.6,0.4-2-0.8-3.5c-1.1-1.5-3.1-3.1-5.5-3.8
				c-2.4-0.7-4.2-0.9-5.9-1c-1.7-0.1-3.4-0.2-5.8-0.2c2.4,0.5,3.9,1.2,5.3,2C135.6,560.8,136.9,561.7,138.6,563.1z"
                />
              </g>
              <g>
                <path
                  d="M141,578.8c0.3,2.1,1,4.1,1.8,5.5c0.8,1.5,1.7,2.4,2.6,2.3c0.9-0.1,1.5-1.4,1.5-3.2c0.1-1.8-0.5-4.2-1.8-6.2
				c-1.3-2.1-2.4-3.4-3.5-4.6c-1.1-1.2-2.2-2.5-3.8-4.2c1.2,2.1,1.7,3.6,2.2,5.2C140.4,575.1,140.7,576.6,141,578.8z"
                />
              </g>
              <g>
                <path
                  d="M143.4,505.7c0.1-2,0.1-3.7-0.1-5.2c-0.2-1.5-0.5-2.7-1.3-3c-0.8-0.3-2,0.5-2.8,2.3c-0.8,1.7-1,4.4-0.2,6.7
				c0.9,2.4,1.9,3.8,3,5.1c1.1,1.3,2.2,2.5,4,4c-1.5-1.8-2-3.4-2.3-4.8C143.4,509.2,143.3,507.7,143.4,505.7z"
                />
              </g>
              <g>
                <path
                  d="M158.8,498.6c0.9-1.8,1.7-3.4,2.3-4.9c0.6-1.4,0.9-2.7,0.4-3.4c-0.5-0.7-2.1-0.5-3.6,0.8c-1.6,1.3-3.1,3.7-3.3,6.4
				c-0.2,2.8,0.3,4.6,0.9,6.3c0.6,1.7,1.4,3.3,2.8,5.3c-1-2.3-1.1-4-0.9-5.5C157.5,501.9,157.9,500.4,158.8,498.6z"
                />
              </g>
              <g>
                <path
                  d="M187.9,576c2.7-0.4,5.2-1.1,7.1-1.9c1.9-0.8,3.2-1.7,3.1-2.6c-0.1-0.9-1.6-1.5-3.8-1.5c-2.2,0-5.1,0.5-7.7,1.8
				c-2.7,1.3-4.4,2.5-6.1,3.6c-1.8,1.2-3.5,2.4-5.8,4c2.7-1.2,4.6-1.8,6.5-2.3C183.2,576.8,185.2,576.4,187.9,576z"
                />
              </g>
              <g>
                <path
                  d="M149,392.3c3,0.1,5.8-1,7.6-2.1c1.8-1.1,2.7-2.4,2.4-3.2c-0.3-0.8-1.9-1-3.9-0.7c-2,0.3-4.4,1-6.7,1.7
				c-2.4,0.8-4.2,1-6,0.9c-1.8-0.1-3.8-0.5-5.9-2.5c1.7,2.3,3.6,3.4,5.4,4.3C143.8,391.6,145.9,392.2,149,392.3z"
                />
              </g>
              <g>
                <path
                  d="M134,321c-1.8,2.8-3.2,6-3.8,9.2c-0.6,3.2-0.5,6.3,0.2,8.9c0.7,2.6,1.8,4.5,2.9,5.6c0.5,0.6,1,0.9,1.4,1.1
				c0.4,0.2,0.7,0.2,0.8,0.1c0.7-0.6-0.7-3.3-1-7.6c-0.1-2.1,0-4.6,0.5-7.2c0.5-2.6,1.5-5.3,2.8-8c1.3-2.8,2.7-5.1,4-7.1
				c1.3-2.1,2.6-3.9,3.9-5.7c1.3-1.9,2.6-3.7,4-5.8c1.4-2.1,2.9-4.5,4.3-7.6c-1.6,2.9-3.4,5.1-5,7.1c-1.6,1.9-3.2,3.5-4.8,5.2
				c-1.6,1.6-3.1,3.2-4.8,5.1C137.7,315.9,135.8,318.1,134,321z"
                />
              </g>
              <g>
                <path
                  d="M143.2,322.4c-0.7,5.5,0.4,11.2,2,15.1c1.6,3.9,3.6,6,4.4,5.6c0.8-0.4,0.3-3.1-0.4-6.8c-0.7-3.8-1.4-8.7-1.7-13.7
				c-0.2-5.2,0.2-8.9,1-12.5c0.8-3.6,2-7.3,5-12.1c-3.4,4.5-5.2,8-6.8,11.5C145.2,313,143.9,316.7,143.2,322.4z"
                />
              </g>
              <g>
                <path
                  d="M149.7,316.6c0,4,1.3,7.8,2.8,10.4c1.5,2.6,3.2,3.9,3.9,3.4c0.8-0.4,0.5-2.4-0.1-5c-0.6-2.6-1.5-6-2.3-9.4
				c-0.9-3.5-1.2-6.1-1.4-8.7c-0.1-2.6,0-5.3,1-9.2c-1.5,3.7-2.3,6.3-2.9,9C150.2,309.8,149.7,312.5,149.7,316.6z"
                />
              </g>
              <g>
                <path
                  d="M93.8,341.5c3.2-2.6,5.7-6,7.1-8.8c1.4-2.8,1.7-5,0.9-5.4c-0.8-0.4-2.4,0.9-4.4,3c-2,2.1-4.4,5-6.8,8.1
				c-2.4,3.1-4.3,5.2-6.3,7.2c-2,2-4.2,3.8-7.8,6.1c3.8-1.8,6.4-3.2,8.9-4.6C88,345.7,90.5,344.2,93.8,341.5z"
                />
              </g>
              <g>
                <path
                  d="M102.3,347.6c2.7-0.3,5.3-1,7.2-1.7c1.9-0.8,3.2-1.7,3.1-2.5c-0.1-0.9-1.6-1.5-3.8-1.6c-2.2-0.1-5.1,0.4-7.7,1.7
				c-2.7,1.3-4.4,2.4-6.2,3.5c-1.8,1.2-3.6,2.3-5.9,3.8c2.7-1.2,4.7-1.7,6.6-2.1C97.6,348.2,99.5,347.9,102.3,347.6z"
                />
              </g>
              <g>
                <path
                  d="M119.4,246.7c1.9-1.6,3.5-3.5,4.5-5.2c1-1.6,1.4-3,0.8-3.7c-0.6-0.6-2.1-0.3-3.8,0.8c-1.7,1.1-3.6,3-4.9,5.4
				c-1.3,2.4-2,4.2-2.8,5.9c-0.8,1.9-1.5,3.7-2.5,6c1.5-2.3,2.8-3.7,4.1-5C116.1,249.6,117.4,248.4,119.4,246.7z"
                />
              </g>
              <g>
                <path
                  d="M20.7,384.4c-7.8,3.2-14.9,7.9-19.7,12.1c-4.8,4.2-7.2,7.7-6.7,8.3c0.6,0.7,4.1-1.6,9.2-5c5.1-3.3,11.9-7.6,19-11.4
				c7.3-3.9,12.7-6.1,18.2-8c5.5-1.8,11.3-3.3,20-4.3c-8.8,0.4-14.7,1.3-20.5,2.4C34.5,379.7,28.7,381.1,20.7,384.4z"
                />
              </g>
              <g>
                <path
                  d="M27.1,386.9c-6.2,3.1-11.7,7.5-15.3,11.3c-3.6,3.8-5.4,6.9-4.8,7.4c0.7,0.6,3.5-1.3,7.5-4.3c4-3,9.3-6.9,14.8-10.6
				c5.7-3.8,9.8-6.1,14.1-8.2c4.3-2,8.9-3.9,15.8-5.7c-7.1,1.2-11.9,2.5-16.5,3.9C38.1,382.1,33.5,383.7,27.1,386.9z"
                />
              </g>
              <g>
                <path
                  d="M-19.8,372.5c-3.2-2.4-6.3-4.7-8.9-6.3c-2.5-1.6-4.5-2.5-5.1-1.9c-0.6,0.6,0.3,2.7,2.4,5.1c2.1,2.4,5.5,5.1,9.3,6.9
				c4,1.8,6.8,2.7,9.6,3.6c2.9,0.8,5.7,1.6,10,2.5c-4.1-1.4-6.8-2.8-9.3-4.2C-14.2,376.7-16.6,375-19.8,372.5z"
                />
              </g>
              <g>
                <path
                  d="M-14.5,384.4c-0.9,0-1.8,0.2-2.7,0.6c-0.9,0.3-1.9,1-2.5,2c-0.6,1-0.6,2.1-0.3,2.7c0.3,0.7,0.9,0.8,1.3,0.7
				c0.4-0.1,0.8-0.4,1.2-0.5c0.4-0.1,0.7-0.1,1-0.1c0.2,0.1,0.6,0,1.2-0.2c0.7-0.2,1.4-0.5,2.1-1c1.4-0.9,2.5-1.5,3.6-2
				c1.1-0.5,2.3-0.9,4.1-1.2c-1.6-0.2-2.9-0.5-4.4-0.7C-11.3,384.6-12.6,384.4-14.5,384.4z"
                />
              </g>
              <g>
                <path
                  d="M-13.6,395.2c-2.1,1.6-3.6,3.7-4.4,5.6c-0.7,1.8-0.7,3.3,0,3.8c0.8,0.5,2-0.2,3.4-1.4c1.4-1.2,2.9-3,4.1-5
				c1.3-2.1,2.3-3.5,3.4-4.9c1.1-1.4,2.3-2.7,4.3-4.4c-2.3,1.2-4,2-5.6,2.9C-9.8,392.7-11.4,393.6-13.6,395.2z"
                />
              </g>
              <g>
                <path d="M-9.5,421.5c-1.3,2.9-0.3,6,1.4,6.3c1.7,0.2,3.5-2.6,2.9-5.7c-0.5-3.2-1.1-4.4-1.2-7.7C-7.4,417.5-8.2,418.6-9.5,421.5z" />
              </g>
              <g>
                <path d="M-2.1,415.6c-1.2,2.5-0.2,5.3,1.5,5.5c1.7,0.2,3.4-2.2,2.8-4.9c-0.6-2.8-1.2-3.9-1.4-6.7C0,412.2-0.9,413.1-2.1,415.6z" />
              </g>
              <g>
                <path
                  d="M578.1,305.9c3.3-4.2,6.4-8.7,8.9-13.6c2.5-4.9,4.2-10.3,4.9-15.7c0.7-5.4,0.4-10.9-1.2-15.8c-0.4-1.2-0.8-2.4-1.3-3.6
				c-0.5-1.2-1.1-2.3-1.8-3.4c-1.4-2.2-3.1-4.2-5.3-5.7c-1.1-0.8-2.3-1.3-3.6-1.8c-1.3-0.4-2.7-0.6-4-0.5c-2.5,0.1-4.9,0.9-6.8,2.5
				c-0.9,0.8-1.7,1.7-2.3,2.7c-0.6,1-0.8,2.1-1,3.1c-0.2,2,0.2,3.7,0.7,5c1.1,2.5,2.5,3.9,3.5,4.8c1,0.9,1.7,1.1,2.1,0.9
				c0.4-0.2,0.3-1,0.1-2.2c-0.2-1.2-0.8-2.9-1-4.7c-0.1-0.9,0-1.8,0.3-2.5c0.2-0.3,0.4-0.7,0.6-0.9c0.2-0.2,0.5-0.4,0.9-0.6
				c0.7-0.3,1.7-0.5,2.7-0.3c0.5,0.1,1,0.2,1.4,0.4c0.4,0.2,0.9,0.6,1.4,1c0.9,0.8,1.8,2,2.5,3.4c0.7,1.4,1.3,3.2,1.8,5
				c0.9,3.6,1,7.7,0.4,12c-0.6,4.2-1.9,8.6-3.8,12.8c-1.9,4.3-4.4,8.5-7.2,12.6c-2.9,4.3-5.8,8.2-8.5,11.9
				c-2.7,3.7-5.2,7.3-7.5,10.8c-2.2,3.5-4.2,7-5.8,10.6c-1.6,3.6-2.8,7.2-3.7,10.9c-1.7,7.4-2.1,15.1-0.8,23.5
				c0.6,4.2,1.7,8.5,3.4,13c1.7,4.4,4,9,7.3,13.5c-3-4.6-5.1-9.3-6.5-13.7c-1.4-4.5-2.1-8.8-2.4-12.9c-0.6-8.2,0.5-15.5,2.8-22.3
				c1.2-3.4,2.6-6.6,4.4-9.7c1.8-3.1,4-6.2,6.4-9.3c2.4-3.1,5.2-6.3,8.2-9.7C571.4,313.8,574.7,310.2,578.1,305.9z"
                />
              </g>
              <g>
                <path
                  d="M561.9,353.7c1-1.7,2-3.3,3.1-4.8c1.1-1.5,2.2-2.8,3.4-4.1c1.1-1.3,2.4-2.4,3.5-3.4c0.3-0.2,0.6-0.5,0.9-0.7
				c0.3-0.2,0.5-0.4,0.8-0.6c0.5-0.4,1.1-0.7,1.6-1c2.2-1.2,4-1.8,5.4-2c1.5-0.3,2.7-0.1,3.3-0.7c0.3-0.3,0.4-0.8,0.2-1.5
				c-0.2-0.7-0.8-1.5-1.9-2.3c-1-0.8-2.5-1.4-4.3-1.6c-1.7-0.2-3.7-0.1-5.6,0.3c-1,0.2-2,0.6-3,1c-0.5,0.2-1,0.5-1.5,0.7
				c-0.5,0.3-0.9,0.5-1.4,0.8c-1.8,1.1-3.7,2.5-5.3,4.2c-1.6,1.7-3.1,3.6-4.4,5.6c-1.2,2.1-2.2,4.3-3,6.5c-1.6,4.7-2.1,8.7-2.3,12.3
				c-0.2,3.6,0,6.8,0.3,9.9c0.7,6.3,1.9,12.4,3.8,21.4c-0.9-9.1-0.8-15.4,0-21.4c0.4-3,1-5.9,1.9-9
				C558.6,360.4,559.9,357.1,561.9,353.7z"
                />
              </g>
              <g>
                <path
                  d="M574.5,279.9c-0.6-1.9-1.5-3.7-2.6-5.3c-0.6-0.8-1.2-1.6-1.9-2.3c-0.7-0.7-1.5-1.3-2.3-1.9c-1.7-1.1-3.9-1.8-6.2-1.5
				c-1.1,0.2-2.1,0.5-3,1.1c-0.9,0.6-1.6,1.4-2.1,2.4c-0.4,0.9-0.6,1.9-0.6,2.8c0,0.9,0.2,1.6,0.5,2.3c0.5,1.3,1.2,2.1,1.8,2.7
				c1.2,1.2,2.1,1.6,2.4,1.4c0.4-0.3,0.1-1.2-0.4-2.6c-0.2-0.7-0.5-1.5-0.6-2.4c-0.1-0.9,0.1-1.5,0.5-2c0.4-0.4,1.1-0.8,2-0.9
				c0.8-0.1,1.9,0.3,2.9,1c1.1,0.7,2.1,1.8,3.1,2.9c0.9,1.2,1.7,2.5,2.4,3.9c1.3,3,1.8,5.9,1.9,8.5c0.1,2.6-0.2,5.1-0.8,7.5
				c-1.1,4.8-3.2,9.6-6.8,16.2c4.1-6.4,6.7-10.8,8.6-15.6c0.9-2.4,1.7-4.9,2-7.9C575.6,287.2,575.6,283.8,574.5,279.9z"
                />
              </g>
              <g>
                <path
                  d="M568.9,290c-0.3-0.5-0.5-1.1-0.8-1.6c-0.3-0.5-0.7-1.1-1.2-1.5c-0.9-1-1.9-1.8-3.4-2.3c-0.7-0.3-1.5-0.4-2.3-0.4
				c-0.8,0-1.6,0.3-2.3,0.6c-1.4,0.7-2.2,1.8-2.6,2.8c-0.5,1-0.6,2-0.6,2.7c0,0.8,0.1,1.4,0.3,1.9c0.3,1,0.7,1.5,1.2,1.5
				c0.4,0,0.9-0.5,1.3-1.4c0.4-0.8,0.9-2,1.5-2.7c0.3-0.4,0.7-0.6,1-0.7c0.3,0,0.5,0,0.9,0.1c0.4,0.2,1,0.5,1.6,1.1
				c0.3,0.3,0.6,0.5,0.8,0.9c0.3,0.3,0.5,0.7,0.8,1.1c1.1,1.5,1.8,3,2.3,4.5c0.5,1.4,0.8,2.8,0.9,4.2c0.1,1.4,0.1,2.9-0.3,4.5
				c-0.3,1.6-0.9,3.4-2.1,5.3c1.4-1.7,2.3-3.4,3-5c0.7-1.6,1.1-3.1,1.3-4.7c0.2-1.6,0.4-3.1,0.2-4.9
				C570.3,294.2,569.9,292.2,568.9,290z"
                />
              </g>
              <g>
                <path
                  d="M548.8,301.3c-1.9-0.3-3.8-0.3-5.2,0c-1.5,0.3-2.6,0.9-2.7,1.8c-0.2,0.9,0.7,1.8,2.3,2.4c1.6,0.6,3.8,0.8,5.9,0.2
				c2.2-0.6,3.6-1.2,5-1.9c1.5-0.7,2.9-1.4,4.7-2.2c-2.1,0.4-3.6,0.5-5.1,0.4C552.1,301.9,550.7,301.7,548.8,301.3z"
                />
              </g>
              <g>
                <path
                  d="M536.3,308.9c-5.8,0.2-11.1,2.8-10.6,4.5c0.4,1.7,6.2,1.7,11.5-0.2c5.5-1.9,7.5-3,13.4-4.3
				C544.5,309.2,542.3,308.8,536.3,308.9z"
                />
              </g>
              <g>
                <path
                  d="M525.6,316.8c-3.9-0.7-7.6,0.8-7.6,2.5c0.1,1.8,4,2.9,7.8,1.8c3.9-1.1,5.3-1.9,9.3-2.6
				C531,318.2,529.6,317.5,525.6,316.8z"
                />
              </g>
              <g>
                <path
                  d="M537.6,319c-2.2,1.1-3.9,2.9-4.8,4.6c-0.9,1.6-1.1,3.1-0.4,3.6c0.7,0.6,2,0.2,3.4-0.7c1.5-0.9,3.1-2.3,4.5-4
				c1.5-1.7,2.6-2.9,3.8-4.1c1.2-1.1,2.5-2.2,4.6-3.4c-2.4,0.7-4,1.3-5.6,1.8C541.5,317.3,539.9,317.8,537.6,319z"
                />
              </g>
              <g>
                <path
                  d="M545.3,322.1c-1.5,1.4-2.5,3.1-2.9,4.6c-0.4,1.5-0.2,2.7,0.6,3.2c0.8,0.4,1.9,0,3-1c1.1-1,2.2-2.5,2.9-4.3
				c1.5-3.7,1.8-5.3,4.1-8.8C549.9,318.6,548.4,319.3,545.3,322.1z"
                />
              </g>
              <g>
                <path
                  d="M554.7,308c-1.3,0.9-2.1,2.2-2.4,3.4c-0.3,1.2,0,2.2,0.7,2.7c0.7,0.5,1.8,0.3,2.7-0.3c1-0.6,1.9-1.7,2.4-3
				c1.1-2.8,1.3-4.1,3.2-6.7C558.7,305.8,557.4,306.2,554.7,308z"
                />
              </g>
              <g>
                <path
                  d="M532.3,341c-1.2,1.5-1.8,3.3-1.8,4.8c0,1.5,0.5,2.5,1.4,2.7c0.9,0.2,1.8-0.4,2.6-1.5c0.8-1,1.5-2.5,1.8-4.2
				c0.3-1.7,0.5-3,0.9-4.2c0.3-1.2,0.8-2.4,1.7-4.1c-1.4,1.3-2.3,2.2-3.3,3.1C534.5,338.6,533.5,339.5,532.3,341z"
                />
              </g>
              <g>
                <path
                  d="M533.2,356.5c-2.1,3.9-1.8,8.5-0.1,8.9c1.7,0.4,3.9-3.4,4.3-7.6c0.4-4.4,0.2-6.1,1.4-10.5
				C536.7,351.3,535.4,352.5,533.2,356.5z"
                />
              </g>
              <g>
                <path
                  d="M533.9,379.3c-0.4,2.4,0.2,4.9,1,6.6c0.8,1.7,2,2.6,2.8,2.3c0.8-0.3,1.2-1.6,1.3-3.2c0.1-1.7-0.2-3.7-0.7-5.8
				c-0.5-2.2-0.8-3.7-1-5.4c-0.2-1.6-0.2-3.3,0.2-5.7c-0.9,2.3-1.5,3.8-2.1,5.5C534.8,375.2,534.2,376.8,533.9,379.3z"
                />
              </g>
              <g>
                <path
                  d="M570.2,359.3c1.9-2.2,3.7-4.2,5-6c1.3-1.7,2.2-3.2,1.7-3.9c-0.5-0.7-2.3-0.5-4.4,0.8c-2.2,1.3-4.6,3.7-6,6.8
				c-1.4,3.1-1.9,5.4-2.3,7.6c-0.4,2.2-0.6,4.5-0.6,7.8c0.5-3.2,1.4-5.3,2.4-7.2C567.1,363.2,568.3,361.5,570.2,359.3z"
                />
              </g>
              <g>
                <path
                  d="M580.7,319.9c1.7-0.7,3-1.7,4-2.7c0.9-1,1.4-2.1,1.1-2.9c-0.4-0.8-1.6-1.1-3.1-0.8c-1.5,0.3-3.3,1.3-4.6,2.8
				c-1.3,1.6-2.1,2.8-2.8,4c-0.8,1.3-1.5,2.6-2.4,4.1c1.4-1.4,2.6-2.1,3.8-2.7C577.8,321.1,579,320.6,580.7,319.9z"
                />
              </g>
              <g>
                <path
                  d="M-6.5,258.1c1.9-0.4,3.5-1.1,4.7-2c1.2-0.9,1.9-1.9,1.6-2.7c-0.2-0.8-1.4-1.3-3-1.2c-1.6,0.1-3.5,0.7-5.2,2
				c-1.7,1.3-2.7,2.3-3.8,3.3c-1,1-2.1,2-3.7,3.4c1.9-0.9,3.2-1.4,4.6-1.7C-9.8,258.7-8.5,258.5-6.5,258.1z"
                />
              </g>
              <g>
                <path
                  d="M8.4,246.2c-1.5,2.6-2.1,5.6-2,7.8c0.1,2.3,0.8,3.7,1.7,3.8c0.9,0.1,1.7-1.2,2.5-3.1c0.8-1.9,1.5-4.3,2-6.9
				c0.5-2.7,1.1-4.5,1.7-6.4c0.7-1.8,1.5-3.7,3.3-6.1c-2.1,2-3.5,3.5-4.9,5.1C11.2,242,9.9,243.6,8.4,246.2z"
                />
              </g>
              <g>
                <path
                  d="M544.8,223.7c-1.8-1.5-3.8-2.7-6-3.5c-2.1-0.9-4.5-1.4-6.9-1.4c-1.2,0-2.4,0.2-3.5,0.6c-1.2,0.4-2.3,0.9-3.2,1.6
				c-1.9,1.4-3.2,3.3-3.7,5.3c-0.3,1-0.3,2-0.2,3.1c0.2,1,0.5,1.8,0.9,2.5c0.9,1.4,2.1,2.3,3.1,2.7c1.1,0.4,2,0.4,2.6,0.2
				c0.6-0.2,0.8-0.4,0.8-0.6c-0.1-0.5-1.1-0.6-2.2-1.7c-1.1-1-2.1-3.1-1.2-5c0.4-1,1.3-2,2.4-2.7c0.6-0.4,1.2-0.6,1.9-0.8
				c0.7-0.2,1.5-0.3,2.2-0.3c3.2,0,7,1.4,10.2,3.5c3.3,2.2,5.7,4.8,7.4,7.5c0.9,1.3,1.6,2.7,2.2,4c0.6,1.4,1.1,2.7,1.5,4.1
				c0.8,2.8,1.2,5.8,1.2,9.2c0,3.4-0.4,7.1-1.6,11.5c1.4-4.3,2.2-8,2.5-11.5c0.3-3.4,0.2-6.5-0.3-9.6c-0.3-1.5-0.6-3.1-1-4.6
				c-0.5-1.5-1.1-3.1-1.9-4.7C550.7,230.1,548.4,226.7,544.8,223.7z"
                />
              </g>
              <g>
                <path
                  d="M547.9,236.8c-0.9-1.3-2.2-2.4-3.6-3.2c-1.5-0.8-3.3-1.2-5-1c-1.8,0.2-3.4,1.2-4.5,2.4c-1.1,1.2-1.7,2.9-1.6,4.4
				c0.1,1.7,1,2.9,1.8,3.6c0.9,0.7,1.9,1.1,2.7,1.1c0.8,0,1.4-0.2,1.8-0.5c0.3-0.3,0.4-0.6,0.3-0.8c-0.2-0.4-0.9-0.6-1.4-1.1
				c-0.3-0.3-0.6-0.6-0.8-1.1c-0.2-0.4-0.3-0.9-0.1-1.2c0.1-0.4,0.4-0.8,0.8-1.2c0.5-0.4,1.1-0.7,1.7-0.7c1.3-0.2,3.2,0.6,4.7,2
				c1.6,1.5,2.6,3.5,3.6,5.3c0.9,1.8,1.7,3.4,2.3,5.1c0.6,1.7,1.2,3.5,1.6,5.5c0.4,2,0.7,4.4,0.8,7.1c0.2-2.8,0.2-5.1,0.1-7.2
				c-0.1-2.1-0.3-4-0.6-5.9c-0.3-1.9-0.7-3.7-1.3-5.8c-0.3-1-0.6-2.1-1.1-3.3C549.5,239.4,548.9,238.1,547.9,236.8z"
                />
              </g>
              <g>
                <path
                  d="M613.1,514.1c0.5,1.2,0.7,2.4,0.8,3.5c0,1.1-0.1,2-0.4,2.7c-0.3,0.7-0.7,1.4-1.3,2.1c-0.6,0.6-1.4,1.3-2.4,1.7
				c-1.9,1-4,1.4-6.1,1.3c-2-0.1-3.6-0.8-4.7-1.5c-1.2-0.9-2-2.1-2.3-3.2c-0.4-1-0.3-2.3,0.1-3.4c0.4-1.1,0.9-2,1.4-2.8
				c0.5-0.8,0.9-1.4,0.9-2.1c0-0.6-0.6-1.3-1.9-1.6c-1.3-0.3-3.3,0-5.5,1.3c-1.1,0.7-2.1,1.6-3.1,2.9c-0.5,0.7-0.9,1.4-1.2,2.2
				c-0.4,0.8-0.6,1.8-0.8,2.7c-0.7,3.8,0.2,8.2,2.9,11.9c2.8,3.9,7.2,6.5,12,7.4c4.7,1,10.1,0.5,14.9-1.9c2.4-1.2,4.7-2.8,6.7-5
				c2-2.2,3.6-4.9,4.4-8c0.8-3,0.8-6.2,0.3-9c-0.6-2.9-1.7-5.4-3.1-7.7c-1.5-2.3-3.3-4.3-5.2-5.9c-1.9-1.6-4.1-2.9-6.1-3.7
				c-4.1-1.7-8-2.3-11.5-2.4c-7.1-0.1-12.9,1.4-18.6,3.1c-5.6,1.8-11.1,4.1-16.8,7.4c-5.7,3.2-11.6,7.4-17.6,13.4
				c6.6-5.4,12.9-8.7,18.8-11c5.9-2.3,11.4-3.5,16.8-4.2c5.4-0.6,11-0.7,16.3,0.5c2.7,0.6,5.3,1.6,7.5,3.1c1.2,0.8,2,1.6,2.8,2.6
				C611.9,511.7,612.6,512.9,613.1,514.1z"
                />
              </g>
              <g>
                <path
                  d="M586.9,487.9c2.8-1.8,5.2-4.2,7.1-6.8c1.9-2.6,3.2-5.3,3.9-7.8c0.7-2.5,0.8-4.7,0.6-6.3c-0.3-1.6-0.9-2.5-1.8-2.7
				c-1.8-0.3-3.7,2.1-6.3,5.3c-1.3,1.7-2.7,3.5-4.3,5.4c-1.6,2-3.3,4.1-5.1,6.3c-1.8,2.3-3.6,4.1-5.2,5.7c-1.7,1.6-3.2,3.1-4.9,4.4
				c-3.3,2.7-6.9,5.3-12.6,8.5c6.1-2.3,10.3-3.7,14.5-5.2c2.1-0.8,4.2-1.6,6.5-2.7C581.6,491.1,584.1,489.8,586.9,487.9z"
                />
              </g>
              <g>
                <path
                  d="M574,520.4c-1.2,2.3-1.3,5.1-0.8,7c0.5,1.9,1.5,3,2.4,2.8c0.9-0.2,1.4-1.4,1.8-3c0.4-1.6,0.7-3.5,0.8-5.6
				c0.2-2.2,0.5-3.7,1-5.3c0.5-1.5,1.3-3,2.9-4.9c-2,1.4-3.3,2.6-4.5,3.9C576.4,516.6,575.3,518,574,520.4z"
                />
              </g>
              <g>
                <path
                  d="M580.2,543.9c0.4,2.6,1.7,5,3.2,6.4c1.5,1.4,2.9,1.9,3.6,1.3c0.7-0.6,0.5-2,0-3.6c-0.5-1.6-1.4-3.6-2.5-5.5
				c-1.1-2-1.8-3.6-2.3-5.2c-0.5-1.6-0.9-3.3-0.8-5.9c-0.6,2.5-0.9,4.3-1.1,6.1C580,539.4,579.8,541.2,580.2,543.9z"
                />
              </g>
              <g>
                <path
                  d="M599.3,558c1.3,1.4,3,2.4,4.7,2.8c1.7,0.5,3.4,0.5,4.8,0.2c1.4-0.3,2.4-0.9,3-1.4c0.6-0.6,0.8-1.1,0.7-1.5
				c-0.4-0.8-2-0.9-3.9-1.2c-2-0.3-4.3-0.9-6.5-2.2c-1.2-0.7-2.1-1.5-2.9-2.3c-0.8-0.8-1.4-1.7-2-2.6c-1.2-1.8-2.1-3.9-3.1-7.1
				c0.5,3.3,0.8,5.6,1.4,7.8c0.3,1.1,0.6,2.3,1.2,3.5C597.2,555.2,598,556.6,599.3,558z"
                />
              </g>
              <g>
                <path
                  d="M613.7,553.2c2.2,1.1,4.7,1.3,6.5,1c1.8-0.3,3-1.2,2.9-2.1c-0.1-0.9-1.2-1.6-2.7-2.1c-1.5-0.5-3.5-0.9-5.5-1
				c-2.2-0.2-3.7-0.4-5.2-0.7c-1.5-0.4-3.1-0.9-5.1-2.2c1.7,1.7,2.9,2.8,4.2,3.9C610.1,551,611.5,552.1,613.7,553.2z"
                />
              </g>
              <g>
                <path
                  d="M623.6,545.9c1.5,0.1,2.9-0.6,3.8-1.4c0.8-0.8,1.1-1.9,0.7-2.6c-0.4-0.8-1.3-1.2-2.4-1.2c-1,0-2.2,0.3-3.3,1
				c-1.1,0.8-1.9,1.3-2.8,1.8c-0.9,0.5-1.8,0.9-3.2,1.2c1.4,0.3,2.5,0.5,3.5,0.7C620.9,545.6,622,545.8,623.6,545.9z"
                />
              </g>
              <g>
                <path
                  d="M584.7,454.4c0.8-4,0.4-8.1-0.6-11.1c-0.9-2.9-2.3-4.6-3.1-4.4c-0.9,0.3-1.1,2.3-1,5c0,2.8,0.2,6.4,0.3,10.1
				c0.1,3.8,0,6.5-0.4,9.1c-0.4,2.7-1,5.4-2.7,9.1c2.2-3.5,3.4-6,4.5-8.6C582.9,461.2,583.9,458.5,584.7,454.4z"
                />
              </g>
              <g>
                <path
                  d="M175.8,236.9c-0.3,3.3,0.7,6.7,2.2,8.7c1.4,2.1,3.1,2.9,3.7,2.4c0.7-0.5,0.5-2.1,0.1-4.1c-0.5-2.3-1-4.4-1.6-7.2
				c-0.5-2.8-0.6-4.8-0.4-6.9c0.2-2,0.7-4.2,2.3-6.9c-2.1,2.4-3.2,4.4-4.2,6.4C177,231.3,176.2,233.5,175.8,236.9z"
                />
              </g>
              <g>
                <path
                  d="M316.4,56.6c3.3,0.8,6.5,1.5,9,2c2.5,0.5,4.4,0.7,4.8-0.1c0.4-0.7-0.8-2.4-3.3-3.8c-2.5-1.5-6.3-2.6-10.1-2.5
				c-3.9,0.1-6.5,0.7-9.1,1.5c-2.5,0.8-5,1.6-8.5,3.4c3.6-1.2,6.2-1.4,8.7-1.5C310.5,55.6,313,55.9,316.4,56.6z"
                />
              </g>
              <g>
                <path
                  d="M166.4,149.7c1.4,3.4,3.9,6.3,6.1,7.9c2.2,1.7,4.1,2.1,4.7,1.5c0.6-0.7-0.3-2.3-1.7-4.4c-1.4-2.1-3.3-4.6-5.3-7.2
				c-2.1-2.7-3.3-4.7-4.4-6.9c-1.1-2.2-2-4.5-2.6-8.1c0.1,3.7,0.4,6.2,0.8,8.7C164.4,143.7,165,146.2,166.4,149.7z"
                />
              </g>
              <g>
                <path
                  d="M79.1,161.1c1.1-2.5,1.1-5.4,0.4-7.4c-0.7-2-1.8-3-2.6-2.8c-0.9,0.3-1.3,1.6-1.5,3.2c-0.3,1.7-0.4,3.7-0.5,5.9
				c-0.1,2.3-0.4,3.9-0.9,5.5c-0.5,1.6-1.3,3.2-3.1,5.1c2.1-1.5,3.4-2.8,4.7-4.2C76.7,165.2,77.9,163.7,79.1,161.1z"
                />
              </g>
              <g>
                <path
                  d="M340.8,260.3c3.5-6.8,4.5-14.6,2.8-15.1c-1.7-0.5-5.1,6.3-6.9,13.5c-0.9,3.8-1.5,6.4-2.2,9c-0.7,2.6-1.5,5.2-2.9,9
				c1.9-3.5,3.3-5.9,4.7-8.3C337.6,266.1,339,263.8,340.8,260.3z"
                />
              </g>
              <g>
                <path
                  d="M485,235.3c3.3,0,6.5-0.3,9-0.8c2.4-0.5,4.1-1.2,4.2-2.1c0-0.9-1.7-1.7-4.2-2.1c-2.6-0.4-6.1-0.3-9.4,0.6
				c-3.4,0.9-5.7,1.8-8,2.7c-2.4,1-4.7,1.9-7.8,3.1c3.5-0.8,5.9-1.1,8.3-1.3C479.3,235.3,481.6,235.3,485,235.3z"
                />
              </g>
              <g>
                <path
                  d="M596.5,141.5c-2.7,0.3-5.3,1.3-7,2.5c-1.7,1.2-2.6,2.4-2.2,3.2c0.4,0.8,1.9,0.9,3.8,0.6c1.9-0.3,4.3-1,6.5-2.1
				c2.4-1.1,4-1.9,5.8-2.5c1.7-0.7,3.5-1.2,6.3-1.7c-2.6-0.1-4.5-0.1-6.6-0.2C601.2,141.3,599.3,141.2,596.5,141.5z"
                />
              </g>
              <g>
                <path
                  d="M548.5,79c-2.4,1.8-4.6,3.6-6.2,5.2c-1.6,1.6-2.6,2.9-2.2,3.7c0.4,0.8,2.2,0.8,4.5-0.2c2.3-1,5.1-3,7.1-5.6
				c2-2.7,3.1-4.8,4-6.9c0.9-2.1,1.8-4.2,2.9-7.4c-1.5,3-3,4.7-4.5,6.3C552.6,75.7,550.9,77.1,548.5,79z"
                />
              </g>
              <g>
                <path
                  d="M482.8,101.2c3.5-0.6,7-1.5,9.5-2.4c2.6-0.9,4.3-1.9,4.2-2.8c-0.1-0.9-2.1-1.4-4.9-1.3c-2.8,0.1-6.6,0.8-10,2.3
				c-3.6,1.5-5.9,2.8-8.2,4.1c-2.3,1.3-4.6,2.7-7.9,4.7c3.6-1.5,6.1-2.3,8.6-2.9C476.6,102.3,479.2,101.9,482.8,101.2z"
                />
              </g>
              <g>
                <path
                  d="M503.3,526.1c2.5-1.7,3.3-4.9,2-6c-1.4-1.1-4.3,0.4-5.4,3.3c-1.2,2.9-1.3,4.3-2.8,7.1
				C499.4,528.3,500.7,527.9,503.3,526.1z"
                />
              </g>
              <g>
                <path
                  d="M281.3,518.4c2,0.3,3.8,0.4,5.3,0.2c1.5-0.1,2.7-0.5,3-1.3c0.3-0.8-0.5-2-2.2-2.8c-1.6-0.8-4.1-1.2-6.5-0.5
				c-2.4,0.7-3.9,1.5-5.3,2.3c-1.4,0.9-2.8,1.8-4.6,3.2c2.1-1,3.6-1.3,5.1-1.4C277.8,518,279.3,518.1,281.3,518.4z"
                />
              </g>
            </g>
          </g>
          <g id="Peddle12">
            <defs>
              <path
                id="SVGID_00000020391111603453578460000011580443181844165273_"
                d="M455.7,436.2c-44.3-18.4-221.9-125-207.6-159.3
			c14.3-34.3,215.1,16.7,259.3,35.1c44.3,18.4,98,78.8,85.7,108.6C580.7,450.2,499.9,454.6,455.7,436.2z"
              />
            </defs>
            <use
              xlinkHref="#SVGID_00000020391111603453578460000011580443181844165273_"
              overflow="visible"
              fill="#003301"
            />
            <clipPath id="SVGID_00000155141126847873483670000000603943339385171842_">
              <use
                xlinkHref="#SVGID_00000020391111603453578460000011580443181844165273_"
                overflow="visible"
              />
            </clipPath>
            <g
              opacity="0.1"
              clipPath="url(#SVGID_00000155141126847873483670000000603943339385171842_)"
            >
              <g>
                <g>
                  <path
                    d="M312.1,219.6c8.1-1.2,15.4-3.8,20.8-7c5.4-3.2,8.8-6.9,8.1-10.4c-0.7-3.4-5.5-5.7-12.2-5.8c-6.7-0.1-15.4,2-22.9,6.8
					c-7.7,4.9-12.4,9-17.2,13c-4.7,4-9.4,8-16.3,13.7c8.1-3.8,13.9-5.6,19.8-6.9C297.9,221.7,303.8,220.8,312.1,219.6z"
                  />
                </g>
                <g>
                  <path
                    d="M314.5,239.9c7,1.8,13.9,2.1,19.5,1.1c5.5-1,9.7-3.2,10.5-6.6c0.8-3.4-2.4-7.3-8.1-9.9c-5.6-2.6-13.8-3.8-21.8-2.1
					c-8.2,1.8-13.5,3.9-18.8,6c-5.3,2.1-10.5,4.2-18,7.1c8-0.8,13.5-0.4,18.9,0.5C302,236.8,307.2,238.1,314.5,239.9z"
                  />
                </g>
                <g>
                  <path
                    d="M303.9,258.7c4.2,3.4,8.8,5.1,13,5.4c4.2,0.3,8.1-0.9,10-3.9c1.9-3,1.1-7.2-2.1-10.9c-3.1-3.7-8.6-6.8-14.9-7.1
					c-6.5-0.4-10.9,0.2-15.2,0.7c-4.3,0.5-8.6,1-14.6,1.5c5.8,1.7,9.5,3.7,12.9,5.9C296.4,252.6,299.5,255.2,303.9,258.7z"
                  />
                </g>
                <g>
                  <path
                    d="M282.6,276.1c1.5,4.7,4.5,8,8,9.7c3.4,1.7,7.3,1.9,10.2-0.1c2.9-2,4.1-5.7,3.3-9.8c-0.7-4-3.4-8.3-7.9-11
					c-4.6-2.8-8.1-4.2-11.5-5.8c-3.4-1.6-6.6-3.2-10.9-6.1c2.8,4.3,4.2,7.7,5.3,11.2C280.2,267.7,281.1,271.2,282.6,276.1z"
                  />
                </g>
                <g>
                  <path
                    d="M262,284.1c-1.5,5-1.3,9.6,0.1,13.5c1.3,3.8,3.8,7,7.3,7.6c3.4,0.7,7.1-1.5,9.3-5.8c2.2-4.2,3-10.5,0.8-16.4
					c-2.3-6-4.6-9.7-6.8-13.4c-2.3-3.6-4.5-7.1-7.6-12.2c1,5.8,0.8,9.9,0.1,13.8C264.5,275.2,263.5,279,262,284.1z"
                  />
                </g>
                <g>
                  <path
                    d="M238.4,276.8c-4.1,3.3-6.7,7-8.2,10.8c-0.3,1-0.7,1.9-0.9,2.9c-0.2,1-0.3,1.9-0.3,2.8c0,1.8,0.4,3.6,1.4,5
					c1,1.4,2.6,2.3,4.6,2.6c1,0.1,2.1,0.1,3.2-0.1c1.1-0.2,2.4-0.5,3.5-1.1c4.8-2.1,9.8-7.2,11.7-13.9c1.9-6.8,2-11.6,2-16.2
					c-0.1-4.6-0.3-9.1-0.8-15.3c-1.6,6-3.8,9.7-6.4,12.9C245.6,270.5,242.6,273.3,238.4,276.8z"
                  />
                </g>
                <g>
                  <path
                    d="M221.4,254.8c-5.9,0.7-10.8,2.7-14.6,5.4c-3.7,2.7-6.2,6.2-5.7,9.7c0.5,3.5,4.2,6.1,9.6,6.6c5.3,0.5,12.3-1.2,17.7-5.6
					c5.6-4.6,8.8-8.3,11.8-12c3-3.7,5.9-7.3,10.2-12.6c-5.6,3.6-10,5.2-14.3,6.3C231.8,253.5,227.4,254.1,221.4,254.8z"
                  />
                </g>
                <g>
                  <path
                    d="M214.7,232.3c-5.8-1.9-11.2-2.4-15.9-1.9c-1.2,0.2-2.3,0.3-3.3,0.7c-1.1,0.3-2,0.7-2.9,1.1c-1.7,0.9-3.1,2.2-3.7,3.8
					c-0.6,1.6-0.4,3.5,0.5,5.3c0.5,0.9,1.2,1.9,2,2.7c0.9,0.9,1.9,1.8,3.1,2.5c4.8,3.1,12.4,4.8,19.7,3.2c7.5-1.7,12.3-4,16.9-6.2
					c4.5-2.3,9-4.7,15-8.3c-6.9,1.6-11.7,1.4-16.4,0.8C225.1,235.5,220.6,234.3,214.7,232.3z"
                  />
                </g>
                <g>
                  <path
                    d="M221.6,214.1c-3.8-4.1-7-7.1-10.2-9.4c-1.6-1.2-3.2-2.2-4.8-3.1c-1.6-0.9-3.3-1.6-5.1-1.4c-1.7,0.1-3.3,1.2-4.4,3.3
					c-1.1,2.1-1.4,5.3-0.6,8.8c0.8,3.5,2.7,7.3,5.7,10.4c2.9,3.1,6.8,5.6,11,6.8c4.4,1.3,7.9,1.6,11.1,1.7c3.2,0.1,5.9-0.1,8.6-0.4
					c5.4-0.6,10.5-1.4,17.9-2.1c-7.3-1.5-12.2-3.2-16.6-5.4c-2.2-1.1-4.3-2.4-6.4-3.9C225.6,217.9,223.5,216.2,221.6,214.1z"
                  />
                </g>
                <g>
                  <path
                    d="M239.5,196.3c-0.1-5.5-0.9-9.8-2.3-13.6c-0.7-1.9-1.5-3.7-2.5-5.3c-1-1.6-2.2-2.9-3.9-3.6c-1.6-0.7-3.5-0.5-5.4,0.6
					c-1.9,1.1-3.7,3.3-4.9,6.3c-1.2,3-1.8,6.7-1.4,10.5c0.4,3.8,1.7,7.8,3.9,11.1c2.3,3.5,4.6,5.9,6.7,7.9c2.2,2,4.2,3.6,6.3,5.1
					c4.1,3,8.3,5.7,14,9.6c-4.3-5.5-6.5-9.9-8-14.2c-0.7-2.2-1.3-4.4-1.8-6.8C239.9,201.7,239.6,199.1,239.5,196.3z"
                  />
                </g>
                <g>
                  <path
                    d="M259.2,191.2c2.3-5.4,3.1-10.6,2.9-15.2c-0.1-1.2-0.2-2.2-0.4-3.3c-0.2-1.1-0.5-2-0.9-2.9c-0.8-1.8-1.9-3.2-3.5-4
					c-1.6-0.8-3.4-0.8-5.3,0c-1,0.4-2,1-2.9,1.8c-0.9,0.8-1.9,1.7-2.8,2.9c-3.5,4.5-5.8,11.9-4.6,19.3c1.3,7.6,3.3,12.4,5.3,17
					c2.1,4.6,4.3,9,7.7,15c-1.4-6.8-1-11.6-0.1-16C255.5,201.1,256.9,196.9,259.2,191.2z"
                  />
                </g>
                <g>
                  <path
                    d="M281.1,191.4c4.3-4.1,7.2-8,9.3-11.7c1-1.9,1.9-3.7,2.4-5.6c0.5-1.8,0.7-3.7,0-5.3c-0.6-1.6-2.1-2.9-4.3-3.4
					c-2.2-0.6-5.1-0.3-8.2,0.8c-3.1,1.2-6.3,3.3-8.9,6.2c-2.6,2.9-4.7,6.5-5.8,10.5c-1.2,4-1.6,7.4-1.8,10.4c-0.2,3-0.2,5.7-0.2,8.3
					c0.1,5.2,0.3,10.3,0,17.6c2.4-6.9,4.7-11.5,7.3-15.6c1.3-2.1,2.8-4,4.4-6C277.1,195.6,278.9,193.5,281.1,191.4z"
                  />
                </g>
                <g>
                  <path
                    d="M256.7,233.3c-1.5,3.2,1.5,6,5,5.6c3.5-0.4,5.8-3.7,3.7-6.5c-2.2-2.9-3.7-3.9-5.1-7C259.6,228.7,258.2,230,256.7,233.3z
					"
                  />
                </g>
                <g>
                  <path
                    d="M261.4,230.6c-1.4,1-1.8,2.3-1.5,3.7c0.3,1.4,1.3,2.8,2.8,3.6c1.5,0.9,3.3,0.9,4.7,0.2c1.4-0.7,2.3-2.2,2.1-4.1
					c-0.2-2-0.6-3.3-0.9-4.7c-0.3-1.3-0.6-2.6-0.7-4.4c-0.9,1.5-1.9,2.4-2.9,3.1C263.9,228.9,262.8,229.6,261.4,230.6z"
                  />
                </g>
                <g>
                  <path
                    d="M264.9,232.2c-1.3-0.6-2.1-0.2-2.9,0.7c-0.7,0.9-1.4,2.4-1.5,4.2c-0.1,1.7,0.7,3.3,2.1,4.1c1.4,0.8,3.3,0.7,4.8-0.7
					c1.5-1.4,2.2-2.7,2.8-3.8c0.6-1.2,1.2-2.3,2.1-3.6c-1.5,0.5-2.7,0.5-3.8,0.3C267.3,233.2,266.2,232.7,264.9,232.2z"
                  />
                </g>
                <g>
                  <path
                    d="M267.4,236.8c0-0.7-0.3-1.3-0.7-1.8c-0.5-0.5-1.1-1-1.9-1.2c-0.8-0.3-1.6-0.3-2.4-0.1c-0.8,0.2-1.5,0.6-2.1,1.3
					c-0.6,0.6-1.1,1.3-1.4,2c-0.3,0.7-0.6,1.4-0.6,2c0,0.7,0.1,1.3,0.5,2c0.4,0.6,1.1,1.3,2.1,1.7c1,0.5,2.1,0.8,3,1
					c0.9,0.2,1.7,0.2,2.5,0.1c1.6-0.1,3.1-0.5,4.5-1.6c-0.8,0.1-1.5-0.2-2-0.6c-0.5-0.4-0.8-0.8-1-1.3c-0.2-0.5-0.3-1-0.4-1.6
					C267.4,238,267.5,237.5,267.4,236.8z"
                  />
                </g>
                <g>
                  <path
                    d="M266.9,240c0.8-1.4,0.7-2.5-0.1-3.6c-0.7-1.1-2-2.2-3.8-2.6c-1.7-0.4-3.4,0.1-4.5,1.4c-1.1,1.3-1.4,3.5-0.4,5.4
					c1.1,2,2.1,3.1,3.2,4.1c1,1,2,2,3.2,3.4c-0.2-1.8,0-3.1,0.5-4.3C265.4,242.6,266.1,241.5,266.9,240z"
                  />
                </g>
                <g>
                  <path
                    d="M264.2,244.1c1.7-1.3,2.4-3.2,2.2-4.9c-0.2-1.7-1.4-3.1-3.1-3.7c-1.7-0.6-3.4-0.3-4.7,0.5c-1.3,0.8-2.1,2-2.1,3.8
					c0,1.9,0,3.3,0,4.7c-0.1,1.4-0.4,2.8-1.2,4.5c1.6-1,2.9-1.7,4.3-2.3C260.9,246.1,262.4,245.4,264.2,244.1z"
                  />
                </g>
                <g>
                  <path
                    d="M258.2,243.2c3.6,1.4,6.6-1.7,6.2-5.2c-0.4-3.5-4.2-5.7-7.3-3.5c-3.2,2.3-4.3,3.9-7.7,5.4
					C253,240.5,254.5,241.8,258.2,243.2z"
                  />
                </g>
                <g>
                  <path
                    d="M254.5,239.4c1.8,3.2,5.8,2.6,7.7-0.4c1.8-3,0.5-6.9-3.1-7c-3.7-0.1-5.5,0.5-8.9-0.3
					C252.5,234.3,252.7,236.1,254.5,239.4z"
                  />
                </g>
                <g>
                  <path
                    d="M255,235.1c0,1.7,1.1,2.7,2.4,3c1.3,0.3,3-0.2,4.3-1.3c1.4-1.1,2.1-2.6,2.3-3.8c0.1-1.2-0.4-2.3-1.8-2.8
					c-2.9-1-4.7-1.1-6.9-3C255.8,230.1,255,231.7,255,235.1z"
                  />
                </g>
                <g>
                  <path
                    d="M299.7,201.9c5.7-1.8,10.2-4.8,13.4-8.2c3.2-3.4,5-7.2,3.9-10.6c-1.1-3.3-5.3-5.2-10.8-4.6c-5.4,0.5-12,3.6-16.6,9.1
					c-4.7,5.7-7.1,10.1-9.4,14.4c-2.2,4.4-4.4,8.6-7.4,14.7c4.8-4.8,8.8-7.3,12.8-9.3C289.6,205.4,293.8,203.9,299.7,201.9z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M624.2,225.2c3.8,4.2,8.2,7.3,12.1,9.2c3.9,1.8,7.4,2.3,9.1,0.6c1.7-1.7,1.1-5.3-1.4-9.3c-2.6-3.9-7.1-8.1-12.8-10.5
					c-5.8-2.5-10-3.6-14.1-4.8c-4.1-1.1-8.3-2.3-14.2-4.1c5.3,3.1,8.6,5.8,11.6,8.7C617.5,217.9,620.3,220.9,624.2,225.2z"
                  />
                </g>
                <g>
                  <path
                    d="M613.5,234.5c1.7,4.7,4.2,8.8,6.9,11.6c2.7,2.8,5.6,4.4,7.9,3.4c2.2-0.9,3.3-4.2,2.6-8.5c-0.7-4.2-3.2-9.4-7.3-13.3
					c-4.2-4-7.5-6.2-10.7-8.5c-3.3-2.2-6.5-4.4-11.1-7.6c3.6,4.3,5.5,7.6,7.1,11C610.4,226.1,611.7,229.6,613.5,234.5z"
                  />
                </g>
                <g>
                  <path
                    d="M598.5,235.7c-0.3,3.7,0.5,7,2,9.6c1.5,2.5,3.7,4.3,6.1,4.2c2.4-0.1,4.5-2.2,5.4-5.4c0.9-3.2,0.5-7.6-1.7-11.3
					c-2.3-3.8-4.3-6.2-6.3-8.5c-2-2.3-3.9-4.5-6.5-7.8c1.3,4,1.6,6.8,1.6,9.7C599.1,229,598.9,231.8,598.5,235.7z"
                  />
                </g>
                <g>
                  <path
                    d="M580.4,230.2c-2.1,2.7-2.8,5.7-2.5,8.4c0.3,2.6,1.7,4.9,4,5.8c2.3,0.9,4.9,0.1,6.9-1.9c2-2,3.4-5.2,3.2-8.8
					c-0.2-3.7-0.7-6.3-1.1-8.9c-0.4-2.5-0.7-5.1-0.8-8.6c-1.4,3.3-2.8,5.4-4.3,7.4C584.2,225.6,582.5,227.5,580.4,230.2z"
                  />
                </g>
                <g>
                  <path
                    d="M567.7,221.5c-3.4,1.1-6,3-7.7,5.3c-1.7,2.3-2.5,4.9-1.5,7.2c0.9,2.2,3.6,3.5,7,3.1c3.3-0.4,7.2-2.4,9.7-5.9
					c2.6-3.6,3.8-6.4,5-9.1c1.2-2.7,2.3-5.4,4-9.1c-2.9,2.8-5.4,4.3-7.8,5.4C573.9,219.5,571.3,220.4,567.7,221.5z"
                  />
                </g>
                <g>
                  <path
                    d="M562.8,205.2c-3.5-1.1-6.6-1.1-9.4-0.5c-0.7,0.2-1.4,0.3-2,0.6c-0.6,0.3-1.2,0.6-1.7,0.9c-1.1,0.7-1.9,1.6-2.3,2.8
					c-0.4,1.1-0.3,2.4,0.3,3.6c0.3,0.6,0.7,1.2,1.3,1.8c0.6,0.6,1.2,1.1,2,1.6c3.1,1.9,8,2.8,12.5,1.3c4.6-1.6,7.4-3.3,10.1-5.1
					c2.6-1.8,5.1-3.7,8.4-6.4c-4.1,1.4-7.1,1.6-9.9,1.4C569.2,206.9,566.4,206.2,562.8,205.2z"
                  />
                </g>
                <g>
                  <path
                    d="M568.7,186.9c-2.6-3.1-5.7-5.2-8.7-6.3c-3-1.1-5.9-1.1-7.7,0.5c-1.8,1.6-1.9,4.8-0.1,8.1c1.8,3.3,5.4,6.6,10.1,8
					c4.8,1.4,8.2,1.8,11.5,2.1c3.3,0.3,6.5,0.5,11.2,0.9c-4.3-1.8-6.8-3.7-9.1-5.8C573.6,192.4,571.5,190.1,568.7,186.9z"
                  />
                </g>
                <g>
                  <path
                    d="M579.1,174.3c-1.1-4.1-3-7.4-5.1-9.9c-0.6-0.6-1.1-1.2-1.7-1.7c-0.6-0.5-1.2-0.9-1.8-1.2c-1.2-0.6-2.4-0.9-3.6-0.7
					c-1.2,0.3-2.2,1.1-2.8,2.4c-0.3,0.6-0.6,1.4-0.8,2.2c-0.2,0.8-0.3,1.8-0.2,2.8c0.1,4,2,9,5.8,12.6c3.9,3.7,7,5.5,10.1,7.2
					c3.1,1.7,6.2,3.3,10.6,5.4c-3.6-3.3-5.4-6.2-6.8-9.1C581.3,181.5,580.3,178.5,579.1,174.3z"
                  />
                </g>
                <g>
                  <path
                    d="M592.2,171.1c0.9-3.8,1.3-6.8,1.4-9.5c0.1-1.3,0-2.7-0.1-4c-0.1-1.3-0.4-2.5-1.2-3.5c-0.8-0.9-2-1.4-3.6-1.2
					c-1.6,0.2-3.6,1.2-5.3,3c-1.7,1.8-3.1,4.4-3.7,7.3c-0.6,2.9-0.6,6,0.3,8.9c0.9,3,2.1,5.2,3.3,7c1.2,1.8,2.4,3.3,3.6,4.8
					c2.4,2.9,4.9,5.5,8.1,9.5c-2-4.8-2.9-8.3-3.3-11.7c-0.2-1.7-0.3-3.4-0.3-5.2C591.6,175,591.7,173.1,592.2,171.1z"
                  />
                </g>
                <g>
                  <path
                    d="M609.4,174.5c3.1-2.2,5.3-4.3,6.9-6.6c0.8-1.1,1.5-2.3,2.1-3.5c0.5-1.2,0.8-2.4,0.6-3.6c-0.2-1.2-1.1-2.2-2.5-2.8
					c-1.4-0.6-3.3-0.8-5.5-0.4c-2.2,0.4-4.5,1.5-6.6,3.3c-2,1.7-3.8,4-4.8,6.5c-1.1,2.7-1.6,4.9-1.9,6.9c-0.3,2-0.4,3.8-0.5,5.6
					c-0.1,3.5,0,6.9-0.1,11.8c1.5-4.6,3.1-7.6,5.1-10.1c1-1.3,2-2.5,3.2-3.6C606.4,176.8,607.8,175.6,609.4,174.5z"
                  />
                </g>
                <g>
                  <path
                    d="M619.9,183.9c4-0.8,7.3-2.3,9.9-4.3c0.6-0.5,1.2-1,1.7-1.5c0.5-0.5,1-1.1,1.3-1.6c0.7-1.1,1.1-2.3,1-3.6
					c-0.2-1.2-0.9-2.3-2.1-3.1c-0.6-0.4-1.3-0.8-2.1-1c-0.8-0.2-1.7-0.4-2.7-0.5c-3.9-0.3-9.1,1.3-12.8,4.9
					c-3.8,3.7-5.8,6.7-7.7,9.6c-1.8,3-3.5,6-5.6,10.2c3.4-3.4,6.2-5.1,9.1-6.3C612.8,185.6,615.8,184.7,619.9,183.9z"
                  />
                </g>
                <g>
                  <path
                    d="M628.3,196.5c4,0.9,7.4,1,10.3,0.8c1.5-0.1,2.9-0.4,4.1-0.8c1.2-0.4,2.4-1,3.1-2c0.7-1,0.8-2.3,0.3-3.8
					c-0.5-1.5-1.8-3-3.7-4.4c-1.9-1.3-4.3-2.3-7-2.7c-2.7-0.4-5.6-0.2-8.3,0.7c-2.8,0.9-4.9,2-6.7,3c-1.8,1-3.3,2.1-4.8,3.1
					c-3,2.1-5.8,4.2-10.1,6.8c4.9-1.3,8.4-1.8,11.8-1.8c1.7,0,3.4,0,5.2,0.2C624.3,195.8,626.3,196,628.3,196.5z"
                  />
                </g>
                <g>
                  <path
                    d="M594.8,198.8c-2.4,0.4-2.8,3.2-1.3,5c1.6,1.9,4.4,1.9,5.2-0.4c0.8-2.4,0.8-3.7,2-5.7
					C598.6,198.6,597.3,198.3,594.8,198.8z"
                  />
                </g>
                <g>
                  <path
                    d="M598.2,200.4c-1.1-0.4-2-0.1-2.7,0.6c-0.7,0.7-1.1,1.8-1,3c0.1,1.2,0.8,2.2,1.7,2.7c0.9,0.5,2.2,0.5,3.2-0.4
					c1-0.9,1.7-1.6,2.3-2.3c0.6-0.7,1.3-1.4,2.2-2.1c-1.2,0-2.1-0.2-2.9-0.4C600.2,201.2,599.3,200.8,598.2,200.4z"
                  />
                </g>
                <g>
                  <path
                    d="M598.6,203c-0.2-0.9-0.7-1.3-1.5-1.4c-0.8-0.1-1.9,0.1-3,0.8c-1,0.6-1.7,1.7-1.6,2.8c0.1,1.1,0.9,2.2,2.2,2.5
					c1.4,0.3,2.4,0.2,3.3,0.1c0.9-0.1,1.8-0.2,2.9-0.2c-0.9-0.7-1.3-1.4-1.6-2.1C599,204.8,598.8,204,598.6,203z"
                  />
                </g>
                <g>
                  <path
                    d="M597,206.3c0.4-0.3,0.6-0.7,0.8-1.1c0.1-0.5,0.1-1,0-1.6c-0.1-0.5-0.4-1.1-0.9-1.4c-0.4-0.4-1-0.6-1.6-0.7
					c-0.6-0.1-1.2-0.1-1.7,0c-0.5,0.1-1,0.2-1.4,0.4c-0.4,0.2-0.7,0.6-0.9,1c-0.2,0.5-0.3,1.1-0.2,1.9c0.1,0.8,0.3,1.5,0.6,2.1
					c0.3,0.6,0.6,1.1,0.9,1.5c0.7,0.8,1.5,1.6,2.7,2c-0.4-0.5-0.5-1-0.4-1.4c0-0.4,0.2-0.8,0.4-1.1c0.2-0.3,0.5-0.6,0.7-0.9
					C596.3,206.7,596.6,206.6,597,206.3z"
                  />
                </g>
                <g>
                  <path
                    d="M594.9,207.2c1.1-0.1,1.7-0.6,2.1-1.5c0.3-0.8,0.5-2,0-3.2c-0.4-1.1-1.4-1.9-2.6-2c-1.2-0.1-2.5,0.5-3.2,1.9
					c-0.7,1.4-0.9,2.4-1.1,3.4c-0.2,1-0.4,1.9-0.7,3.2c0.9-0.8,1.8-1.2,2.7-1.4C592.9,207.4,593.8,207.3,594.9,207.2z"
                  />
                </g>
                <g>
                  <path
                    d="M591.5,207.3c1.4,0.5,2.8,0.1,3.7-0.7c0.9-0.8,1.2-2,0.9-3.2c-0.3-1.2-1.2-2.1-2.1-2.5c-0.9-0.4-2-0.4-3,0.3
					c-1.1,0.7-1.9,1.3-2.7,1.8c-0.8,0.5-1.7,0.9-3.1,1.1c1.2,0.5,2.1,1,3,1.6C589.1,206.1,590.1,206.7,591.5,207.3z"
                  />
                </g>
                <g>
                  <path
                    d="M589.7,203.4c0.6,2.6,3.6,3.1,5.4,1.5c1.8-1.6,1.7-4.6-0.8-5.5c-2.6-1-3.9-1-6.1-2.3
					C589.3,199.5,589.1,200.8,589.7,203.4z"
                  />
                </g>
                <g>
                  <path
                    d="M590.5,199.9c-1.1,2.2,0.8,4.3,3.2,4.2c2.4-0.1,4.2-2.4,2.8-4.5c-1.4-2.2-2.4-3-3.3-5.2
					C592.6,196.7,591.6,197.6,590.5,199.9z"
                  />
                </g>
                <g>
                  <path
                    d="M593.1,198.5c-0.9,0.7-1.1,1.7-0.8,2.5c0.4,0.9,1.3,1.6,2.4,2c1.2,0.3,2.3,0.2,3.1-0.2c0.8-0.4,1.2-1.1,0.9-2.1
					c-0.5-2-1.2-3.1-0.9-5.1C596.3,197,595.1,197.2,593.1,198.5z"
                  />
                </g>
                <g>
                  <path
                    d="M629.5,211.2c3.3,2.5,6.7,4,9.9,4.5c3.2,0.5,6.1,0.1,7.6-1.9c1.5-1.9,0.9-5.1-1.5-8c-2.4-2.9-6.8-5.5-11.7-6
					c-5.1-0.5-8.5-0.1-11.9,0.2c-3.4,0.4-6.7,0.8-11.3,1.5c4.6,0.9,7.6,2.2,10.3,3.7C623.6,206.8,626.1,208.6,629.5,211.2z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M241.1,523.3c3.8,4.2,8.2,7.3,12.1,9.2c3.9,1.8,7.4,2.3,9.1,0.6c1.7-1.7,1.1-5.3-1.4-9.3c-2.6-3.9-7.1-8.1-12.8-10.5
					c-5.8-2.5-10-3.6-14.1-4.8c-4.1-1.1-8.3-2.3-14.2-4.1c5.3,3.1,8.6,5.8,11.6,8.7C234.3,516,237.1,519,241.1,523.3z"
                  />
                </g>
                <g>
                  <path
                    d="M230.3,532.5c1.7,4.7,4.2,8.8,6.9,11.6c2.7,2.8,5.6,4.4,7.9,3.4c2.2-0.9,3.3-4.2,2.6-8.5c-0.7-4.2-3.2-9.4-7.3-13.3
					c-4.2-4-7.5-6.2-10.7-8.5c-3.3-2.2-6.5-4.4-11.1-7.6c3.6,4.3,5.5,7.6,7.1,11C227.3,524.1,228.6,527.6,230.3,532.5z"
                  />
                </g>
                <g>
                  <path
                    d="M215.4,533.7c-0.3,3.7,0.5,7,2,9.6c1.5,2.5,3.7,4.3,6.1,4.2c2.4-0.1,4.5-2.2,5.4-5.4c0.9-3.2,0.5-7.6-1.7-11.3
					c-2.3-3.8-4.3-6.2-6.3-8.5c-2-2.3-3.9-4.5-6.5-7.8c1.3,4,1.6,6.8,1.6,9.7C216,527.1,215.7,529.9,215.4,533.7z"
                  />
                </g>
                <g>
                  <path
                    d="M197.2,528.3c-2.1,2.7-2.8,5.7-2.5,8.4c0.3,2.6,1.7,4.9,4,5.8c2.3,0.9,4.9,0.1,6.9-1.9c2-2,3.4-5.2,3.2-8.8
					c-0.2-3.7-0.7-6.3-1.1-8.9c-0.4-2.5-0.7-5.1-0.8-8.6c-1.4,3.3-2.8,5.4-4.3,7.4C201.1,523.7,199.4,525.5,197.2,528.3z"
                  />
                </g>
                <g>
                  <path
                    d="M184.6,519.6c-3.4,1.1-6,3-7.7,5.3c-1.7,2.3-2.5,4.9-1.5,7.2c0.9,2.2,3.6,3.5,7,3.1c3.3-0.4,7.2-2.4,9.7-5.9
					c2.6-3.6,3.8-6.4,5-9.1c1.2-2.7,2.3-5.4,4-9.1c-2.9,2.8-5.4,4.3-7.8,5.4C190.7,517.6,188.1,518.4,184.6,519.6z"
                  />
                </g>
                <g>
                  <path
                    d="M179.7,503.2c-3.5-1.1-6.6-1.1-9.4-0.5c-0.7,0.2-1.4,0.3-2,0.6c-0.6,0.3-1.2,0.6-1.7,0.9c-1.1,0.7-1.9,1.6-2.3,2.8
					c-0.4,1.1-0.3,2.4,0.3,3.6c0.3,0.6,0.7,1.2,1.3,1.8c0.6,0.6,1.2,1.1,2,1.6c3.1,1.9,8,2.8,12.5,1.3c4.6-1.6,7.4-3.3,10.1-5.1
					c2.6-1.8,5.1-3.7,8.4-6.4c-4.1,1.4-7.1,1.6-9.9,1.4C186,504.9,183.3,504.3,179.7,503.2z"
                  />
                </g>
                <g>
                  <path
                    d="M185.6,484.9c-2.6-3.1-5.7-5.2-8.7-6.3c-3-1.1-5.9-1.1-7.7,0.5c-1.8,1.6-1.9,4.8-0.1,8.1c1.8,3.3,5.4,6.6,10.1,8
					c4.8,1.4,8.2,1.8,11.5,2.1c3.3,0.3,6.5,0.5,11.2,0.9c-4.3-1.8-6.8-3.7-9.1-5.8C190.4,490.4,188.4,488.1,185.6,484.9z"
                  />
                </g>
                <g>
                  <path
                    d="M195.9,472.3c-1.1-4.1-3-7.4-5.1-9.9c-0.6-0.6-1.1-1.2-1.7-1.7c-0.6-0.5-1.2-0.9-1.8-1.2c-1.2-0.6-2.4-0.9-3.6-0.7
					c-1.2,0.3-2.2,1.1-2.8,2.4c-0.3,0.6-0.6,1.4-0.8,2.2c-0.2,0.8-0.3,1.8-0.2,2.8c0.1,4,2,9,5.8,12.6c3.9,3.7,7,5.5,10.1,7.2
					c3.1,1.7,6.2,3.3,10.6,5.4c-3.6-3.3-5.4-6.2-6.8-9.1C198.2,479.5,197.1,476.5,195.9,472.3z"
                  />
                </g>
                <g>
                  <path
                    d="M209.1,469.2c0.9-3.8,1.3-6.8,1.4-9.5c0.1-1.3,0-2.7-0.1-4c-0.1-1.3-0.4-2.5-1.2-3.5c-0.8-0.9-2-1.4-3.6-1.2
					c-1.6,0.2-3.6,1.2-5.3,3c-1.7,1.8-3.1,4.4-3.7,7.3c-0.6,2.9-0.6,6,0.3,8.9c0.9,3,2.1,5.2,3.3,7c1.2,1.8,2.4,3.3,3.6,4.8
					c2.4,2.9,4.9,5.5,8.1,9.5c-2-4.8-2.9-8.3-3.3-11.7c-0.2-1.7-0.3-3.4-0.3-5.2C208.4,473,208.6,471.1,209.1,469.2z"
                  />
                </g>
                <g>
                  <path
                    d="M226.2,472.6c3.1-2.2,5.3-4.3,6.9-6.6c0.8-1.1,1.5-2.3,2.1-3.5c0.5-1.2,0.8-2.4,0.6-3.6c-0.2-1.2-1.1-2.2-2.5-2.8
					c-1.4-0.6-3.3-0.8-5.5-0.4c-2.2,0.4-4.5,1.5-6.6,3.3c-2,1.7-3.8,4-4.8,6.5c-1.1,2.7-1.6,4.9-1.9,6.9c-0.3,2-0.4,3.8-0.5,5.6
					c-0.1,3.5,0,6.9-0.1,11.8c1.5-4.6,3.1-7.6,5.1-10.1c1-1.3,2-2.5,3.2-3.6C223.3,474.9,224.6,473.7,226.2,472.6z"
                  />
                </g>
                <g>
                  <path
                    d="M236.8,481.9c4-0.8,7.3-2.3,9.9-4.3c0.6-0.5,1.2-1,1.7-1.5c0.5-0.5,1-1.1,1.3-1.6c0.7-1.1,1.1-2.3,1-3.6
					c-0.2-1.2-0.9-2.3-2.1-3.1c-0.6-0.4-1.3-0.8-2.1-1c-0.8-0.2-1.7-0.4-2.7-0.5c-3.9-0.3-9.1,1.3-12.8,4.9
					c-3.8,3.7-5.8,6.7-7.7,9.6c-1.8,3-3.5,6-5.6,10.2c3.4-3.4,6.2-5.1,9.1-6.3C229.7,483.6,232.7,482.8,236.8,481.9z"
                  />
                </g>
                <g>
                  <path
                    d="M245.2,494.6c4,0.9,7.4,1,10.3,0.8c1.5-0.1,2.9-0.4,4.1-0.8c1.2-0.4,2.4-1,3.1-2c0.7-1,0.8-2.3,0.3-3.8
					c-0.5-1.5-1.8-3-3.7-4.4c-1.9-1.3-4.3-2.3-7-2.7c-2.7-0.4-5.6-0.2-8.3,0.7c-2.8,0.9-4.9,2-6.7,3c-1.8,1-3.3,2.1-4.8,3.1
					c-3,2.1-5.8,4.2-10.1,6.8c4.9-1.3,8.4-1.8,11.8-1.8c1.7,0,3.4,0,5.2,0.2C241.2,493.9,243.1,494.1,245.2,494.6z"
                  />
                </g>
                <g>
                  <path
                    d="M211.7,496.8c-2.4,0.4-2.8,3.2-1.3,5c1.6,1.9,4.4,1.9,5.2-0.4c0.8-2.4,0.8-3.7,2-5.7
					C215.4,496.7,214.2,496.4,211.7,496.8z"
                  />
                </g>
                <g>
                  <path
                    d="M215,498.4c-1.1-0.4-2-0.1-2.7,0.6c-0.7,0.7-1.1,1.8-1,3c0.1,1.2,0.8,2.2,1.7,2.7c0.9,0.5,2.2,0.5,3.2-0.4
					c1-0.9,1.7-1.6,2.3-2.3c0.6-0.7,1.3-1.4,2.2-2.1c-1.2,0-2.1-0.2-2.9-0.4C217,499.2,216.2,498.9,215,498.4z"
                  />
                </g>
                <g>
                  <path
                    d="M215.5,501.1c-0.2-0.9-0.7-1.3-1.5-1.4c-0.8-0.1-1.9,0.1-3,0.8c-1,0.6-1.7,1.7-1.6,2.8c0.1,1.1,0.9,2.2,2.2,2.5
					c1.4,0.3,2.4,0.2,3.3,0.1c0.9-0.1,1.8-0.2,2.9-0.2c-0.9-0.7-1.3-1.4-1.6-2.1C215.9,502.9,215.7,502.1,215.5,501.1z"
                  />
                </g>
                <g>
                  <path
                    d="M213.9,504.3c0.4-0.3,0.6-0.7,0.8-1.1c0.1-0.5,0.1-1,0-1.6c-0.1-0.5-0.4-1.1-0.9-1.4c-0.4-0.4-1-0.6-1.6-0.7
					c-0.6-0.1-1.2-0.1-1.7,0c-0.5,0.1-1,0.2-1.4,0.4c-0.4,0.2-0.7,0.6-0.9,1c-0.2,0.5-0.3,1.1-0.2,1.9c0.1,0.8,0.3,1.5,0.6,2.1
					c0.3,0.6,0.6,1.1,0.9,1.5c0.7,0.8,1.5,1.6,2.7,2c-0.4-0.5-0.5-1-0.4-1.4c0-0.4,0.2-0.8,0.4-1.1c0.2-0.3,0.5-0.6,0.7-0.9
					C213.1,504.8,213.5,504.6,213.9,504.3z"
                  />
                </g>
                <g>
                  <path
                    d="M211.8,505.3c1.1-0.1,1.7-0.6,2.1-1.5c0.3-0.8,0.5-2,0-3.2c-0.4-1.1-1.4-1.9-2.6-2c-1.2-0.1-2.5,0.5-3.2,1.9
					c-0.7,1.4-0.9,2.4-1.1,3.4c-0.2,1-0.4,1.9-0.7,3.2c0.9-0.8,1.8-1.2,2.7-1.4C209.7,505.4,210.6,505.4,211.8,505.3z"
                  />
                </g>
                <g>
                  <path
                    d="M208.4,505.3c1.4,0.5,2.8,0.1,3.7-0.7c0.9-0.8,1.2-2,0.9-3.2c-0.3-1.2-1.2-2.1-2.1-2.5c-0.9-0.4-2-0.4-3,0.3
					c-1.1,0.7-1.9,1.3-2.7,1.8c-0.8,0.5-1.7,0.9-3.1,1.1c1.2,0.5,2.1,1,3,1.6C206,504.2,206.9,504.8,208.4,505.3z"
                  />
                </g>
                <g>
                  <path
                    d="M206.6,501.5c0.6,2.6,3.6,3.1,5.4,1.5c1.8-1.6,1.7-4.6-0.8-5.5c-2.6-1-3.9-1-6.1-2.3
					C206.1,497.5,205.9,498.8,206.6,501.5z"
                  />
                </g>
                <g>
                  <path
                    d="M207.3,497.9c-1.1,2.2,0.8,4.3,3.2,4.2c2.4-0.1,4.2-2.4,2.8-4.5c-1.4-2.2-2.4-3-3.3-5.2
					C209.5,494.8,208.5,495.6,207.3,497.9z"
                  />
                </g>
                <g>
                  <path
                    d="M210,496.6c-0.9,0.7-1.1,1.7-0.8,2.5c0.4,0.9,1.3,1.6,2.4,2c1.2,0.3,2.3,0.2,3.1-0.2c0.8-0.4,1.2-1.1,0.9-2.1
					c-0.5-2-1.2-3.1-0.9-5.1C213.2,495,211.9,495.2,210,496.6z"
                  />
                </g>
                <g>
                  <path
                    d="M246.4,509.3c3.3,2.5,6.7,4,9.9,4.5c3.2,0.5,6.1,0.1,7.6-1.9c1.5-1.9,0.9-5.1-1.5-8c-2.4-2.9-6.8-5.5-11.7-6
					c-5.1-0.5-8.5-0.1-11.9,0.2c-3.4,0.4-6.7,0.8-11.3,1.5c4.6,0.9,7.6,2.2,10.3,3.7C240.4,504.9,243,506.7,246.4,509.3z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M155.7,208.9c3.8,4.2,8.2,7.3,12.1,9.2c3.9,1.8,7.4,2.3,9.1,0.6c1.7-1.7,1.1-5.3-1.4-9.3c-2.6-3.9-7.1-8.1-12.8-10.5
					c-5.8-2.5-10-3.6-14.1-4.8c-4.1-1.1-8.3-2.3-14.2-4.1c5.3,3.1,8.6,5.8,11.6,8.7C148.9,201.6,151.7,204.6,155.7,208.9z"
                  />
                </g>
                <g>
                  <path
                    d="M144.9,218.2c1.7,4.7,4.2,8.8,6.9,11.6c2.7,2.8,5.6,4.4,7.9,3.4c2.2-0.9,3.3-4.2,2.6-8.5c-0.7-4.2-3.2-9.4-7.3-13.3
					c-4.2-4-7.5-6.2-10.7-8.5c-3.3-2.2-6.5-4.4-11.1-7.6c3.6,4.3,5.5,7.6,7.1,11C141.9,209.8,143.2,213.3,144.9,218.2z"
                  />
                </g>
                <g>
                  <path
                    d="M130,219.4c-0.3,3.7,0.5,7,2,9.6c1.5,2.5,3.7,4.3,6.1,4.2c2.4-0.1,4.5-2.2,5.4-5.4c0.9-3.2,0.5-7.6-1.7-11.3
					c-2.3-3.8-4.3-6.2-6.3-8.5c-2-2.3-3.9-4.5-6.5-7.8c1.3,4,1.6,6.8,1.6,9.7C130.6,212.7,130.3,215.5,130,219.4z"
                  />
                </g>
                <g>
                  <path
                    d="M111.8,214c-2.1,2.7-2.8,5.7-2.5,8.4c0.3,2.6,1.7,4.9,4,5.8c2.3,0.9,4.9,0.1,6.9-1.9c2-2,3.4-5.2,3.2-8.8
					c-0.2-3.7-0.7-6.3-1.1-8.9c-0.4-2.5-0.7-5.1-0.8-8.6c-1.4,3.3-2.8,5.4-4.3,7.4C115.7,209.3,114,211.2,111.8,214z"
                  />
                </g>
                <g>
                  <path
                    d="M99.2,205.2c-3.4,1.1-6,3-7.7,5.3c-1.7,2.3-2.5,4.9-1.5,7.2c0.9,2.2,3.6,3.5,7,3.1c3.3-0.4,7.2-2.4,9.7-5.9
					c2.6-3.6,3.8-6.4,5-9.1c1.2-2.7,2.3-5.4,4-9.1c-2.9,2.8-5.4,4.3-7.8,5.4C105.3,203.2,102.7,204.1,99.2,205.2z"
                  />
                </g>
                <g>
                  <path
                    d="M94.3,188.9c-3.5-1.1-6.6-1.1-9.4-0.5c-0.7,0.2-1.4,0.3-2,0.6c-0.6,0.3-1.2,0.6-1.7,0.9c-1.1,0.7-1.9,1.6-2.3,2.8
					c-0.4,1.1-0.3,2.4,0.3,3.6c0.3,0.6,0.7,1.2,1.3,1.8c0.6,0.6,1.2,1.1,2,1.6c3.1,1.9,8,2.8,12.5,1.3c4.6-1.6,7.4-3.3,10.1-5.1
					c2.6-1.8,5.1-3.7,8.4-6.4c-4.1,1.4-7.1,1.6-9.9,1.4C100.6,190.6,97.9,190,94.3,188.9z"
                  />
                </g>
                <g>
                  <path
                    d="M100.2,170.6c-2.6-3.1-5.7-5.2-8.7-6.3c-3-1.1-5.9-1.1-7.7,0.5c-1.8,1.6-1.9,4.8-0.1,8.1c1.8,3.3,5.4,6.6,10.1,8
					c4.8,1.4,8.2,1.8,11.5,2.1c3.3,0.3,6.5,0.5,11.2,0.9c-4.3-1.8-6.8-3.7-9.1-5.8C105,176.1,103,173.8,100.2,170.6z"
                  />
                </g>
                <g>
                  <path
                    d="M110.5,158c-1.1-4.1-3-7.4-5.1-9.9c-0.6-0.6-1.1-1.2-1.7-1.7c-0.6-0.5-1.2-0.9-1.8-1.2c-1.2-0.6-2.4-0.9-3.6-0.7
					c-1.2,0.3-2.2,1.1-2.8,2.4c-0.3,0.6-0.6,1.4-0.8,2.2c-0.2,0.8-0.3,1.8-0.2,2.8c0.1,4,2,9,5.8,12.6c3.9,3.7,7,5.5,10.1,7.2
					c3.1,1.7,6.2,3.3,10.6,5.4c-3.6-3.3-5.4-6.2-6.8-9.1C112.8,165.2,111.7,162.2,110.5,158z"
                  />
                </g>
                <g>
                  <path
                    d="M123.7,154.9c0.9-3.8,1.3-6.8,1.4-9.5c0.1-1.3,0-2.7-0.1-4c-0.1-1.3-0.4-2.5-1.2-3.5c-0.8-0.9-2-1.4-3.6-1.2
					c-1.6,0.2-3.6,1.2-5.3,3c-1.7,1.8-3.1,4.4-3.7,7.3c-0.6,2.9-0.6,6,0.3,8.9c0.9,3,2.1,5.2,3.3,7c1.2,1.8,2.4,3.3,3.6,4.8
					c2.4,2.9,4.9,5.5,8.1,9.5c-2-4.8-2.9-8.3-3.3-11.7c-0.2-1.7-0.3-3.4-0.3-5.2C123,158.7,123.2,156.8,123.7,154.9z"
                  />
                </g>
                <g>
                  <path
                    d="M140.8,158.2c3.1-2.2,5.3-4.3,6.9-6.6c0.8-1.1,1.5-2.3,2.1-3.5c0.5-1.2,0.8-2.4,0.6-3.6c-0.2-1.2-1.1-2.2-2.5-2.8
					c-1.4-0.6-3.3-0.8-5.5-0.4c-2.2,0.4-4.5,1.5-6.6,3.3c-2,1.7-3.8,4-4.8,6.5c-1.1,2.7-1.6,4.9-1.9,6.9c-0.3,2-0.4,3.8-0.5,5.6
					c-0.1,3.5,0,6.9-0.1,11.8c1.5-4.6,3.1-7.6,5.1-10.1c1-1.3,2-2.5,3.2-3.6C137.9,160.6,139.2,159.4,140.8,158.2z"
                  />
                </g>
                <g>
                  <path
                    d="M151.4,167.6c4-0.8,7.3-2.3,9.9-4.3c0.6-0.5,1.2-1,1.7-1.5c0.5-0.5,1-1.1,1.3-1.6c0.7-1.1,1.1-2.3,1-3.6
					c-0.2-1.2-0.9-2.3-2.1-3.1c-0.6-0.4-1.3-0.8-2.1-1c-0.8-0.2-1.7-0.4-2.7-0.5c-3.9-0.3-9.1,1.3-12.8,4.9
					c-3.8,3.7-5.8,6.7-7.7,9.6c-1.8,3-3.5,6-5.6,10.2c3.4-3.4,6.2-5.1,9.1-6.3C144.2,169.3,147.3,168.5,151.4,167.6z"
                  />
                </g>
                <g>
                  <path
                    d="M159.8,180.2c4,0.9,7.4,1,10.3,0.8c1.5-0.1,2.9-0.4,4.1-0.8c1.2-0.4,2.4-1,3.1-2c0.7-1,0.8-2.3,0.3-3.8
					c-0.5-1.5-1.8-3-3.7-4.4c-1.9-1.3-4.3-2.3-7-2.7c-2.7-0.4-5.6-0.2-8.3,0.7c-2.8,0.9-4.9,2-6.7,3c-1.8,1-3.3,2.1-4.8,3.1
					c-3,2.1-5.8,4.2-10.1,6.8c4.9-1.3,8.4-1.8,11.8-1.8c1.7,0,3.4,0,5.2,0.2C155.8,179.5,157.7,179.8,159.8,180.2z"
                  />
                </g>
                <g>
                  <path
                    d="M126.3,182.5c-2.4,0.4-2.8,3.2-1.3,5c1.6,1.9,4.4,1.9,5.2-0.4c0.8-2.4,0.8-3.7,2-5.7C130,182.3,128.8,182.1,126.3,182.5
					z"
                  />
                </g>
                <g>
                  <path
                    d="M129.6,184.1c-1.1-0.4-2-0.1-2.7,0.6c-0.7,0.7-1.1,1.8-1,3c0.1,1.2,0.8,2.2,1.7,2.7c0.9,0.5,2.2,0.5,3.2-0.4
					c1-0.9,1.7-1.6,2.3-2.3c0.6-0.7,1.3-1.4,2.2-2.1c-1.2,0-2.1-0.2-2.9-0.4C131.6,184.9,130.8,184.5,129.6,184.1z"
                  />
                </g>
                <g>
                  <path
                    d="M130.1,186.7c-0.2-0.9-0.7-1.3-1.5-1.4c-0.8-0.1-1.9,0.1-3,0.8c-1,0.6-1.7,1.7-1.6,2.8c0.1,1.1,0.9,2.2,2.2,2.5
					c1.4,0.3,2.4,0.2,3.3,0.1c0.9-0.1,1.8-0.2,2.9-0.2c-0.9-0.7-1.3-1.4-1.6-2.1C130.5,188.5,130.3,187.7,130.1,186.7z"
                  />
                </g>
                <g>
                  <path
                    d="M128.5,190c0.4-0.3,0.6-0.7,0.8-1.1c0.1-0.5,0.1-1,0-1.6c-0.1-0.5-0.4-1.1-0.9-1.4c-0.4-0.4-1-0.6-1.6-0.7
					c-0.6-0.1-1.2-0.1-1.7,0c-0.5,0.1-1,0.2-1.4,0.4c-0.4,0.2-0.7,0.6-0.9,1c-0.2,0.5-0.3,1.1-0.2,1.9c0.1,0.8,0.3,1.5,0.6,2.1
					c0.3,0.6,0.6,1.1,0.9,1.5c0.7,0.8,1.5,1.6,2.7,2c-0.4-0.5-0.5-1-0.4-1.4c0-0.4,0.2-0.8,0.4-1.1c0.2-0.3,0.5-0.6,0.7-0.9
					C127.7,190.4,128.1,190.3,128.5,190z"
                  />
                </g>
                <g>
                  <path
                    d="M126.4,190.9c1.1-0.1,1.7-0.6,2.1-1.5c0.3-0.8,0.5-2,0-3.2c-0.4-1.1-1.4-1.9-2.6-2c-1.2-0.1-2.5,0.5-3.2,1.9
					c-0.7,1.4-0.9,2.4-1.1,3.4c-0.2,1-0.4,1.9-0.7,3.2c0.9-0.8,1.8-1.2,2.7-1.4C124.3,191.1,125.2,191,126.4,190.9z"
                  />
                </g>
                <g>
                  <path
                    d="M123,191c1.4,0.5,2.8,0.1,3.7-0.7c0.9-0.8,1.2-2,0.9-3.2c-0.3-1.2-1.2-2.1-2.1-2.5c-0.9-0.4-2-0.4-3,0.3
					c-1.1,0.7-1.9,1.3-2.7,1.8c-0.8,0.5-1.7,0.9-3.1,1.1c1.2,0.5,2.1,1,3,1.6C120.6,189.9,121.5,190.4,123,191z"
                  />
                </g>
                <g>
                  <path
                    d="M121.2,187.2c0.6,2.6,3.6,3.1,5.4,1.5c1.8-1.6,1.7-4.6-0.8-5.5c-2.6-1-3.9-1-6.1-2.3
					C120.7,183.2,120.5,184.5,121.2,187.2z"
                  />
                </g>
                <g>
                  <path
                    d="M121.9,183.6c-1.1,2.2,0.8,4.3,3.2,4.2c2.4-0.1,4.2-2.4,2.8-4.5c-1.4-2.2-2.4-3-3.3-5.2
					C124.1,180.4,123.1,181.3,121.9,183.6z"
                  />
                </g>
                <g>
                  <path
                    d="M124.6,182.2c-0.9,0.7-1.1,1.7-0.8,2.5c0.4,0.9,1.3,1.6,2.4,2c1.2,0.3,2.3,0.2,3.1-0.2c0.8-0.4,1.2-1.1,0.9-2.1
					c-0.5-2-1.2-3.1-0.9-5.1C127.8,180.7,126.5,180.9,124.6,182.2z"
                  />
                </g>
                <g>
                  <path
                    d="M161,194.9c3.3,2.5,6.7,4,9.9,4.5c3.2,0.5,6.1,0.1,7.6-1.9c1.5-1.9,0.9-5.1-1.5-8c-2.4-2.9-6.8-5.5-11.7-6
					c-5.1-0.5-8.5-0.1-11.9,0.2c-3.4,0.4-6.7,0.8-11.3,1.5c4.6,0.9,7.6,2.2,10.3,3.7C155,190.5,157.6,192.3,161,194.9z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M353.7,131.6c-6.1,1.4-12.7,4.3-17.8,8c-2.6,1.8-4.9,3.9-7,6c-2,2.1-3.7,4.3-5.1,6.4c-2.8,4.3-4.2,8.4-4.6,11.5
					c-0.2,1.6-0.1,2.9,0.2,3.9c0.3,1,0.9,1.8,1.6,2.2c0.8,0.4,1.7,0.6,2.7,0.4c1-0.2,2.2-0.6,3.5-1.2c2.5-1.2,5.5-3.2,8.8-5.5
					c1.6-1.1,3.4-2.3,5.2-3.6c1.8-1.2,3.8-2.5,5.8-3.8c4-2.7,8.2-5.1,13.1-8c5-3,9.8-5.5,14-8.2c4.2-2.6,8-5.4,11.3-8.5
					c1.7-1.5,3.3-3.1,4.8-4.8c1.6-1.7,3.1-3.5,4.6-5.5c3-3.9,6-8.5,8.6-14.3c-3.6,5.3-7.4,9-11.1,11.8c-1.9,1.4-3.7,2.6-5.5,3.7
					c-1.8,1.1-3.7,2-5.5,2.7c-3.7,1.6-7.5,2.6-11.9,3.5C365,129.4,360,130.1,353.7,131.6z"
                  />
                </g>
                <g>
                  <path
                    d="M362.6,150.6c-5,2.2-10,4.1-14.7,5.9c-4.7,1.7-9.2,3.3-13.1,4.7c-4,1.4-7.4,2.8-9.9,4.2c-2.5,1.4-4.1,2.9-4.3,4.7
					c-0.1,1.7,1.3,3.5,4.2,5c2.8,1.5,7.1,2.5,12.3,2.7c5.2,0.2,11.2-0.6,17.4-2.6c6.1-2.1,12.3-5.3,17.6-9.8
					c5.4-4.6,9.3-9,12.4-13.3c3.1-4.3,5.4-8.3,7.5-12.4c2-4.1,3.8-8.4,5.4-13.2c1.6-4.8,3-10.2,4-16.7c-2,6.2-4.5,11.1-7.2,15.2
					c-2.6,4.1-5.4,7.4-8.4,10.3c-3,3-6.2,5.6-10,8.1C372,145.9,367.8,148.3,362.6,150.6z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M401.3,173.5c-6-1.9-13.1-2.8-19.4-2.2c-3.2,0.3-6.2,0.8-9,1.6c-2.8,0.8-5.4,1.8-7.7,2.9c-4.6,2.3-7.9,5-9.8,7.5
					c-1,1.2-1.6,2.4-1.8,3.5c-0.3,1.1-0.2,2,0.3,2.7c0.4,0.8,1.2,1.3,2.2,1.7c1,0.4,2.2,0.6,3.6,0.8c2.8,0.2,6.3,0.1,10.3-0.2
					c2-0.1,4.1-0.3,6.4-0.4c2.2-0.1,4.5-0.3,6.9-0.4c4.8-0.2,9.7-0.2,15.4-0.2c5.9,0,11.2,0.3,16.2,0.1c5-0.1,9.7-0.6,14.1-1.5
					c2.2-0.4,4.4-1,6.6-1.7c2.2-0.7,4.4-1.4,6.7-2.4c4.6-1.9,9.5-4.3,14.7-7.9c-5.8,2.7-10.9,3.9-15.6,4.5c-2.3,0.3-4.5,0.4-6.7,0.3
					c-2.1,0-4.2-0.2-6.2-0.5c-4-0.5-7.8-1.6-12-3C412.2,177.4,407.6,175.5,401.3,173.5z"
                  />
                </g>
                <g>
                  <path
                    d="M399.3,194.3c-5.4-0.7-10.7-1.6-15.6-2.5c-4.9-0.9-9.6-1.9-13.7-2.7c-4.1-0.8-7.8-1.4-10.7-1.5
					c-2.9-0.1-5.1,0.4-6.1,1.9c-1,1.4-0.6,3.7,1.1,6.4c1.7,2.7,4.8,5.8,9.2,8.6c4.3,2.8,9.9,5.3,16.3,6.7c6.3,1.4,13.3,1.8,20.1,0.6
					c7-1.1,12.6-3,17.5-5c4.8-2.1,8.9-4.4,12.8-6.9c3.9-2.5,7.6-5.3,11.4-8.6c3.8-3.3,7.8-7.3,12-12.3c-4.9,4.3-9.6,7.2-13.9,9.4
					c-4.4,2.2-8.4,3.5-12.5,4.6c-4.1,1-8.2,1.6-12.7,1.9C409.8,195.2,404.9,195.1,399.3,194.3z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M143.2,295.9c4-4.8,7.5-11.1,9.3-17.2c0.9-3.1,1.6-6.1,1.9-9c0.3-2.9,0.3-5.7,0.2-8.2c-0.4-5.1-1.7-9.2-3.3-11.9
					c-0.8-1.4-1.7-2.4-2.6-3c-0.9-0.6-1.8-0.9-2.6-0.8c-0.9,0.1-1.7,0.6-2.4,1.3c-0.7,0.8-1.4,1.8-2,3.1c-1.3,2.5-2.4,5.9-3.7,9.7
					c-0.6,1.9-1.3,3.9-2,6.1c-0.7,2.1-1.4,4.3-2.2,6.6c-1.6,4.6-3.4,9-5.5,14.3c-2.2,5.4-4.4,10.3-6.2,15c-1.7,4.7-3.1,9.2-3.8,13.6
					c-0.4,2.2-0.7,4.5-0.9,6.8c-0.2,2.3-0.3,4.7-0.3,7.1c0,4.9,0.4,10.4,1.8,16.6c-0.4-6.3,0.4-11.6,1.6-16.1
					c0.6-2.3,1.3-4.4,2.2-6.3c0.8-2,1.7-3.8,2.7-5.5c2-3.5,4.4-6.6,7.3-10C135.6,304.6,139.1,300.9,143.2,295.9z"
                  />
                </g>
                <g>
                  <path
                    d="M124.7,286.2c2.7-4.8,5.5-9.3,8.1-13.6c2.7-4.3,5.3-8.2,7.6-11.7c2.3-3.5,4.2-6.7,5.4-9.3c1.2-2.6,1.5-4.9,0.5-6.3
					c-1-1.5-3.2-2-6.4-1.4c-3.2,0.6-7.2,2.3-11.4,5.3c-4.2,3-8.6,7.3-12.2,12.6c-3.6,5.4-6.6,11.7-8.1,18.4
					c-1.5,6.9-1.9,12.8-1.8,18.1c0.1,5.3,0.7,9.9,1.6,14.4c0.9,4.5,2.1,9,3.7,13.8c1.7,4.8,3.8,9.9,7,15.7
					c-2.2-6.2-3.2-11.6-3.5-16.4c-0.4-4.8-0.2-9.2,0.4-13.3c0.6-4.2,1.6-8.2,3-12.5C120,295.7,121.9,291.1,124.7,286.2z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M453.4,473.1c-7.8,2.4-14.6,6.2-19.4,10.2c-4.8,4-7.6,8.2-6.4,11.6c1.2,3.3,6.3,4.8,13,3.8c6.7-1,14.9-4.4,21.5-10.4
					c6.8-6.1,10.9-10.9,14.9-15.6c4-4.7,8-9.4,14-16.1c-7.3,5.1-12.9,7.7-18.4,9.9C467.1,468.8,461.4,470.6,453.4,473.1z"
                  />
                </g>
                <g>
                  <path
                    d="M447.9,453.4c-7.2-0.7-14.1,0.2-19.4,2c-5.3,1.8-9.1,4.7-9.3,8.2c-0.2,3.5,3.5,6.8,9.5,8.5c6,1.7,14.2,1.6,21.8-1.4
					c7.8-3.1,12.7-6,17.6-8.9c4.9-2.9,9.7-5.8,16.7-9.9c-7.8,2.1-13.3,2.5-18.7,2.5C460.7,454.5,455.3,454.1,447.9,453.4z"
                  />
                </g>
                <g>
                  <path
                    d="M455.3,433.2c-4.7-2.7-9.5-3.7-13.7-3.3c-4.2,0.4-7.9,2.2-9.3,5.4c-1.4,3.2,0.1,7.2,3.8,10.4c3.7,3.1,9.6,5.3,15.9,4.7
					c6.4-0.7,10.7-1.9,14.9-3.1c4.2-1.2,8.3-2.4,14.1-3.8c-6-0.7-9.9-2.1-13.6-3.8C463.7,438,460.2,435.9,455.3,433.2z"
                  />
                </g>
                <g>
                  <path
                    d="M473.5,412.7c-2.2-4.4-5.7-7.2-9.4-8.4c-3.7-1.2-7.5-0.8-10.1,1.7c-2.6,2.4-3.1,6.3-1.8,10.2c1.4,3.9,4.7,7.6,9.6,9.6
					c5,2,8.7,2.9,12.3,3.9c3.6,1,7.1,2.1,11.8,4.3c-3.4-3.8-5.3-7-7-10.2C477.3,420.6,475.8,417.2,473.5,412.7z"
                  />
                </g>
                <g>
                  <path
                    d="M492.7,401.5c0.7-5.1-0.3-9.7-2.2-13.3c-1.9-3.6-4.9-6.3-8.4-6.4c-3.5-0.1-6.7,2.6-8.3,7.2c-1.5,4.5-1.3,10.8,1.8,16.3
					c3.2,5.6,6.1,8.9,8.9,12.1c2.8,3.2,5.6,6.3,9.4,10.8c-1.9-5.5-2.3-9.6-2.3-13.6C491.6,410.7,492,406.8,492.7,401.5z"
                  />
                </g>
                <g>
                  <path
                    d="M517.1,405c3.5-3.9,5.5-8,6.4-12c0.2-1,0.4-2,0.4-3c0-1,0-1.9-0.1-2.8c-0.3-1.8-1-3.5-2.2-4.7c-1.3-1.2-2.9-1.9-4.9-1.8
					c-1,0-2.1,0.2-3.1,0.6c-1.1,0.4-2.2,0.9-3.3,1.6c-4.4,2.8-8.5,8.7-9.3,15.5c-0.8,7-0.1,11.7,0.6,16.3c0.8,4.5,1.7,8.9,3.2,15
					c0.6-6.2,2.3-10.2,4.3-13.8C511,412.3,513.5,409,517.1,405z"
                  />
                </g>
                <g>
                  <path
                    d="M537.4,423.9c5.7-1.6,10.3-4.3,13.5-7.6c3.2-3.2,5.1-7.1,4.1-10.4c-1-3.3-5.1-5.3-10.5-5c-5.4,0.3-11.9,3.1-16.6,8.4
					c-4.8,5.4-7.3,9.6-9.8,13.8c-2.3,4.1-4.7,8.2-8.1,14.1c5-4.5,9-6.7,13.1-8.4C527.3,426.9,531.5,425.6,537.4,423.9z"
                  />
                </g>
                <g>
                  <path
                    d="M547.6,445.1c6,1,11.5,0.6,16-0.7c1.1-0.4,2.2-0.7,3.2-1.2c1-0.5,1.9-1,2.7-1.6c1.6-1.2,2.7-2.6,3.1-4.4
					c0.4-1.7-0.1-3.5-1.4-5.2c-0.6-0.8-1.4-1.7-2.5-2.4c-1-0.7-2.2-1.5-3.5-2c-5.2-2.3-13-2.8-20,0c-7.2,2.9-11.5,5.9-15.7,8.8
					c-4.1,3-8.1,6.1-13.5,10.5c6.6-2.7,11.4-3.3,16-3.4C536.8,443.6,541.4,444.1,547.6,445.1z"
                  />
                </g>
                <g>
                  <path
                    d="M543.7,464.2c4.4,3.5,8.1,5.9,11.5,7.7c1.7,0.9,3.5,1.7,5.2,2.3c1.7,0.6,3.5,1,5.3,0.6c1.7-0.4,3.1-1.7,3.8-4
					c0.7-2.2,0.6-5.4-0.8-8.8c-1.3-3.3-3.9-6.8-7.2-9.3c-3.4-2.6-7.5-4.4-11.9-5c-4.5-0.6-8.1-0.4-11.2,0c-3.1,0.4-5.8,1.1-8.4,1.8
					c-5.2,1.4-10.1,3.1-17.4,4.9c7.5,0.3,12.6,1.2,17.3,2.7c2.3,0.8,4.6,1.7,6.9,2.8C539.1,461,541.5,462.4,543.7,464.2z"
                  />
                </g>
                <g>
                  <path
                    d="M528.8,484.6c1,5.4,2.5,9.5,4.4,13.1c1,1.8,2.1,3.4,3.3,4.8c1.2,1.4,2.7,2.5,4.4,2.9c1.7,0.4,3.5,0,5.2-1.5
					c1.7-1.4,3.1-3.9,3.9-7c0.7-3.1,0.7-6.9-0.2-10.6c-1-3.7-2.9-7.4-5.6-10.3c-2.8-3.1-5.4-5.1-7.9-6.8c-2.5-1.7-4.7-2.9-7-4.1
					c-4.6-2.3-9.1-4.3-15.4-7.3c5.1,4.8,8,8.7,10.2,12.8c1.1,2,2,4.1,2.8,6.4C527.6,479.3,528.3,481.8,528.8,484.6z"
                  />
                </g>
                <g>
                  <path
                    d="M510.1,492.7c-1.4,5.7-1.4,11-0.4,15.5c0.3,1.1,0.5,2.2,0.9,3.2c0.4,1,0.8,1.9,1.3,2.7c1,1.6,2.4,2.9,4.1,3.4
					c1.7,0.5,3.5,0.2,5.3-0.9c0.9-0.5,1.8-1.3,2.6-2.2c0.8-0.9,1.6-2,2.3-3.3c2.7-5,3.8-12.7,1.5-19.8c-2.4-7.3-5.2-11.7-7.9-15.9
					c-2.8-4.2-5.7-8.2-10-13.6c2.4,6.5,2.8,11.3,2.7,15.8C512.2,482.3,511.5,486.8,510.1,492.7z"
                  />
                </g>
                <g>
                  <path
                    d="M488.5,496c-3.6,4.8-5.9,9-7.3,13c-0.7,2-1.3,4-1.5,5.9c-0.2,1.9-0.1,3.7,0.8,5.3c0.9,1.5,2.5,2.5,4.7,2.7
					c2.2,0.2,5.1-0.5,7.9-2.1c2.8-1.6,5.7-4.3,7.8-7.6c2.1-3.3,3.6-7.2,4.1-11.2c0.5-4.2,0.4-7.6,0.2-10.6c-0.3-3-0.7-5.6-1.1-8.2
					c-0.9-5.1-1.9-10.1-2.8-17.4c-1.3,7.2-2.8,12.1-4.8,16.5c-1,2.2-2.1,4.4-3.4,6.7C491.8,491.2,490.3,493.6,488.5,496z"
                  />
                </g>
                <g>
                  <path
                    d="M505.9,450.8c0.9-3.4-2.4-5.6-5.8-4.7c-3.4,0.9-5.2,4.5-2.7,7c2.6,2.5,4.3,3.3,6.2,6.1
					C503.8,455.8,505,454.3,505.9,450.8z"
                  />
                </g>
                <g>
                  <path
                    d="M501.8,454.2c1.2-1.2,1.4-2.6,0.9-3.9c-0.5-1.3-1.7-2.5-3.4-3.1c-1.7-0.6-3.4-0.4-4.6,0.6c-1.2,0.9-1.9,2.6-1.4,4.4
					c0.5,1.9,1.1,3.2,1.6,4.5c0.5,1.3,1,2.5,1.4,4.2c0.7-1.6,1.5-2.6,2.4-3.6C499.5,456.3,500.5,455.4,501.8,454.2z"
                  />
                </g>
                <g>
                  <path
                    d="M498,453.2c1.3,0.3,2.1-0.1,2.7-1.2c0.6-1,1-2.6,0.8-4.4c-0.2-1.7-1.2-3.2-2.7-3.8c-1.5-0.6-3.4-0.2-4.6,1.4
					c-1.2,1.6-1.7,3-2.2,4.2c-0.4,1.3-0.8,2.4-1.5,3.9c1.4-0.8,2.6-0.9,3.7-0.9C495.5,452.6,496.6,452.9,498,453.2z"
                  />
                </g>
                <g>
                  <path
                    d="M494.8,449.1c0.1,0.7,0.5,1.2,1,1.7c0.5,0.4,1.3,0.8,2.1,0.9c0.8,0.1,1.7,0.1,2.4-0.3c0.8-0.3,1.4-0.9,1.9-1.6
					c0.5-0.7,0.8-1.5,1.1-2.2c0.2-0.7,0.3-1.4,0.3-2.1c-0.1-0.7-0.3-1.3-0.8-1.8c-0.5-0.6-1.3-1.1-2.4-1.4c-1.1-0.3-2.2-0.5-3.1-0.5
					c-0.9,0-1.7,0.1-2.5,0.3c-1.5,0.4-3,0.9-4.2,2.3c0.8-0.2,1.5,0,2.1,0.3c0.5,0.3,0.9,0.7,1.2,1.2c0.3,0.5,0.5,1,0.7,1.5
					C494.6,447.8,494.6,448.4,494.8,449.1z"
                  />
                </g>
                <g>
                  <path
                    d="M494.8,445.7c-0.6,1.5-0.3,2.6,0.6,3.6c0.9,1,2.4,1.8,4.1,2c1.7,0.2,3.4-0.6,4.2-2.1c0.9-1.5,0.9-3.7-0.5-5.4
					c-1.4-1.8-2.6-2.7-3.8-3.6c-1.2-0.9-2.3-1.7-3.7-2.8c0.5,1.8,0.5,3.1,0.2,4.4C495.9,443,495.4,444.2,494.8,445.7z"
                  />
                </g>
                <g>
                  <path
                    d="M496.9,441.3c-1.5,1.5-1.9,3.6-1.4,5.2c0.5,1.6,1.9,2.8,3.6,3.1c1.7,0.3,3.4-0.2,4.5-1.2c1.1-1,1.7-2.3,1.5-4.1
					c-0.3-1.8-0.6-3.2-0.7-4.6c-0.1-1.4-0.1-2.8,0.5-4.7c-1.5,1.3-2.6,2.1-3.9,3C499.7,438.9,498.4,439.7,496.9,441.3z"
                  />
                </g>
                <g>
                  <path d="M502.9,441.2c-3.7-0.8-6.3,2.7-5.3,6.1c1,3.4,5,5,7.7,2.3c2.8-2.8,3.6-4.5,6.8-6.6C508.4,443.1,506.8,442,502.9,441.2z" />
                </g>
                <g>
                  <path
                    d="M507.1,444.4c-2.2-2.9-6.2-1.6-7.5,1.7c-1.3,3.3,0.6,6.9,4.2,6.5c3.7-0.5,5.4-1.4,8.9-1.1
					C510,449.2,509.4,447.3,507.1,444.4z"
                  />
                </g>
                <g>
                  <path
                    d="M507.3,448.7c-0.3-1.6-1.5-2.5-2.9-2.6c-1.4-0.1-2.9,0.6-4.1,2c-1.2,1.3-1.7,2.9-1.7,4.1c0.1,1.3,0.7,2.2,2.2,2.4
					c3,0.6,4.8,0.4,7.3,1.9C507.4,453.8,507.9,452.1,507.3,448.7z"
                  />
                </g>
                <g>
                  <path
                    d="M468.5,488.6c-5.3,2.7-9.3,6.4-11.9,10.2c-2.6,3.8-3.8,7.9-2.1,11.1c1.6,3.1,6.1,4.3,11.4,2.9c5.3-1.4,11.3-5.5,15-11.7
					c3.7-6.3,5.4-11.1,7-15.7c1.5-4.7,3-9.2,5-15.7c-3.9,5.5-7.5,8.6-11.2,11.3C477.9,483.5,474,485.7,468.5,488.6z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M426.3,566.6c5.8-2.4,11.9-6.2,16.3-10.7c2.3-2.2,4.3-4.6,5.9-7c1.7-2.4,3-4.8,4-7.2c2.1-4.7,2.9-8.9,2.7-12.1
					c-0.1-1.6-0.4-2.9-0.8-3.9c-0.5-1-1.1-1.6-2-1.9c-0.8-0.3-1.8-0.3-2.8,0c-1,0.3-2.1,0.9-3.2,1.7c-2.3,1.6-4.9,4-7.8,6.8
					c-1.4,1.4-3,2.8-4.6,4.4c-1.6,1.5-3.3,3.1-5.1,4.7c-3.5,3.3-7.3,6.4-11.7,10c-4.5,3.7-8.8,7-12.5,10.3c-3.8,3.3-7,6.6-9.9,10.2
					c-1.4,1.8-2.7,3.6-4,5.5c-1.3,1.9-2.5,4-3.7,6.1c-2.3,4.4-4.5,9.3-6.2,15.5c2.7-5.8,5.9-10,9.1-13.4c1.6-1.7,3.2-3.2,4.9-4.5
					c1.6-1.3,3.3-2.5,5-3.6c3.4-2.1,7-3.8,11.2-5.4C415.5,570.6,420.3,569,426.3,566.6z"
                  />
                </g>
                <g>
                  <path
                    d="M414.5,549.3c4.6-2.9,9.2-5.6,13.6-8.1c4.4-2.5,8.6-4.7,12.2-6.7c3.7-2,6.9-3.9,9.1-5.7c2.3-1.8,3.6-3.6,3.5-5.3
					c-0.2-1.7-1.9-3.3-4.9-4.3c-3-1-7.4-1.4-12.6-0.7c-5.1,0.6-11,2.3-16.8,5.3c-5.7,3-11.4,7.2-15.8,12.4
					c-4.6,5.4-7.8,10.4-10.2,15.1c-2.4,4.7-4.1,9.1-5.4,13.5c-1.4,4.4-2.4,8.9-3.2,13.9c-0.8,5-1.3,10.6-1.3,17.1
					c1-6.5,2.7-11.7,4.7-16.1c2-4.4,4.2-8.1,6.7-11.5c2.5-3.4,5.3-6.5,8.5-9.6C405.9,555.4,409.8,552.3,414.5,549.3z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M372.6,532.7c6.2,0.9,13.4,0.7,19.5-0.9c3.1-0.8,6-1.8,8.7-3c2.7-1.2,5-2.6,7.1-4.1c4.2-3,7-6.2,8.5-9
					c0.7-1.4,1.2-2.7,1.2-3.7c0.1-1.1-0.1-2-0.7-2.7c-0.5-0.7-1.4-1.1-2.4-1.4c-1-0.2-2.3-0.3-3.7-0.2c-2.8,0.2-6.3,0.9-10.2,1.8
					c-1.9,0.4-4,0.9-6.2,1.4c-2.2,0.5-4.4,1-6.8,1.5c-4.7,1-9.5,1.8-15.1,2.6c-5.8,0.9-11.1,1.5-16,2.4c-4.9,0.9-9.4,2.1-13.7,3.7
					c-2.1,0.8-4.2,1.7-6.3,2.7c-2.1,1-4.2,2.1-6.3,3.4c-4.2,2.6-8.7,5.7-13.3,10.1c5.3-3.6,10.2-5.6,14.7-6.9
					c2.3-0.6,4.4-1.1,6.5-1.4c2.1-0.3,4.1-0.5,6.2-0.5c4-0.1,7.9,0.3,12.4,1.1C361.2,530.7,366.2,531.8,372.6,532.7z"
                  />
                </g>
                <g>
                  <path
                    d="M371.3,511.9c5.5-0.2,10.8-0.1,15.8,0c5,0.1,9.8,0.3,14,0.5c4.2,0.1,7.9,0.1,10.8-0.2c2.9-0.4,4.9-1.2,5.7-2.8
					c0.7-1.6,0.1-3.8-2.1-6.2c-2.1-2.4-5.7-5-10.4-7.1c-4.7-2.1-10.7-3.6-17.1-4c-6.5-0.3-13.4,0.4-20,2.6c-6.7,2.2-12,4.9-16.5,7.7
					c-4.4,2.8-8.1,5.7-11.6,8.8c-3.4,3.1-6.6,6.4-9.9,10.3c-3.2,3.9-6.6,8.4-9.8,14c4.2-5,8.3-8.7,12.2-11.5c4-2.8,7.8-4.8,11.6-6.5
					c3.9-1.6,7.8-2.9,12.2-3.9C360.8,512.7,365.7,512,371.3,511.9z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M514.4,139c10.6-1.1,21.3-3.7,30.7-7.7c9.4-3.9,17.6-9.3,23.7-14.8c6.1-5.5,10.2-11.1,12.5-15.3
					c2.3-4.2,2.9-6.9,2.1-7.4c-1.6-1-7.7,7.5-19.9,16.2c-6.1,4.3-13.6,8.7-22.2,12.2c-8.6,3.5-18.2,6.3-28.2,8.1
					c-10.2,1.9-19.1,2.6-27.2,3.2c-8.1,0.6-15.5,1.1-23,1.9c-7.4,0.8-14.9,1.9-23.1,4.1c-4.1,1.1-8.3,2.5-12.7,4.4
					c-4.4,1.9-8.9,4.3-13.4,7.6c9.3-6,18.4-8.6,26.5-10.2c8.2-1.5,15.6-1.9,22.9-2c7.4-0.1,14.7,0.2,22.9,0.5
					C494.2,139.9,503.4,140.1,514.4,139z"
                  />
                </g>
                <g>
                  <path
                    d="M458.8,128.6c2.7-1.5,4.5-4,5.1-6.3c0.6-2.3,0-4.4-1.5-5.3c-1.5-0.9-3.5-0.6-5.3,0.4c-1.8,1-3.5,2.7-4.6,5.2
					c-1.1,2.5-2,4.3-3,6c-1,1.7-2.2,3.4-4.4,5.4c2.8-1.1,4.7-1.7,6.8-2.4C454,130.8,456,130.1,458.8,128.6z"
                  />
                </g>
                <g>
                  <path
                    d="M475.9,123.1c2.3-1.3,3.6-3.6,3.8-5.6c0.2-2.1-0.6-3.8-2.1-4.7c-1.6-0.8-3.4-0.6-5,0.2c-1.6,0.8-2.9,2.3-3.5,4.4
					c-0.7,2.2-1.1,3.8-1.7,5.4c-0.6,1.6-1.4,3.1-3,5c2.3-1,3.9-1.6,5.6-2.2C471.8,125,473.6,124.4,475.9,123.1z"
                  />
                </g>
                <g>
                  <path
                    d="M493,119.2c2.2-1.6,3.5-4,3.7-6.1c0.2-2.1-0.5-4-2.1-4.8c-1.5-0.8-3.5-0.5-5.1,0.5c-1.7,1.1-3.1,2.9-3.7,5.3
					c-0.6,2.5-1,4.3-1.5,6.1c-0.5,1.8-1.2,3.6-2.5,5.8c2.2-1.6,3.8-2.5,5.5-3.5C488.9,121.7,490.7,120.8,493,119.2z"
                  />
                </g>
                <g>
                  <path
                    d="M512.9,117.7c2.4-2.2,3.5-5.3,3.4-7.8c-0.1-2.5-1.3-4.3-3-4.6c-1.7-0.4-3.5,0.6-4.9,2c-1.4,1.4-2.5,3.4-2.9,5.9
					c-0.5,2.6-1,4.5-1.6,6.3c-0.7,1.8-1.6,3.7-3.7,5.8c2.7-1.3,4.5-2.3,6.5-3.3C508.4,121,510.4,119.9,512.9,117.7z"
                  />
                </g>
                <g>
                  <path
                    d="M529.5,112.5c2.1-2.5,3.1-5.6,2.9-8.1c-0.1-2.5-1.2-4.4-2.9-4.8c-1.7-0.5-3.6,0.5-5,2.2c-1.5,1.7-2.6,4.1-3,7
					c-0.4,3-0.6,5.1-1,7.2c-0.4,2.1-1,4.3-2.4,7.1c2.3-2.2,4-3.7,5.7-5.2C525.6,116.6,527.3,115.1,529.5,112.5z"
                  />
                </g>
                <g>
                  <path
                    d="M541.5,108.7c2-2,2.9-4.7,2.9-7c-0.1-2.2-1.2-4-2.8-4.5c-1.7-0.5-3.5,0.2-5,1.5c-1.5,1.4-2.6,3.4-2.9,6
					c-0.3,2.6-0.5,4.5-0.8,6.4c-0.3,1.9-0.8,3.8-2,6.3c2.1-1.9,3.6-3.1,5.2-4.3C537.8,112,539.4,110.8,541.5,108.7z"
                  />
                </g>
                <g>
                  <path
                    d="M558.6,100c2.1-2.3,3.1-5.3,3-7.7c0-2.4-1.1-4.3-2.7-4.9c-1.7-0.5-3.6,0.3-5.1,1.9c-1.5,1.6-2.7,4-3.1,6.8
					c-0.4,2.9-0.6,5-0.9,7.1c-0.4,2.1-0.9,4.2-2.2,7c2.2-2.2,3.8-3.7,5.5-5.1C554.7,103.8,556.5,102.4,558.6,100z"
                  />
                </g>
                <g>
                  <path
                    d="M573,93.6c2.2-1.8,3.6-4.1,4.1-6.2c0.5-2.2,0.2-4.2-1.2-5.3c-1.4-1.1-3.5-0.9-5.5,0.3c-2,1.2-3.9,3.5-4.7,6.5
					c-0.8,3-1.2,5.2-1.5,7.3c-0.4,2.1-0.8,4.2-1.6,7.1c1.8-2.4,3.3-3.9,4.9-5.2C569,96.6,570.7,95.4,573,93.6z"
                  />
                </g>
                <g>
                  <path
                    d="M587.3,86.9c2.3-1.1,3.9-2.9,4.8-4.7c0.8-1.8,0.9-3.8-0.1-5.2c-1-1.4-3.1-1.9-5.2-1.2c-2.2,0.6-4.5,2.4-5.7,5.1
					c-1.3,2.7-1.9,4.7-2.5,6.7c-0.6,2-1.2,3.9-2.3,6.5c1.9-2,3.6-3.2,5.3-4.1C583.1,88.9,584.9,88.1,587.3,86.9z"
                  />
                </g>
                <g>
                  <path
                    d="M602.4,93.3c2.3,0.6,4.4,0.3,6.1-0.6c1.7-0.9,3-2.3,3.1-4.1c0.1-1.7-1-3.4-3.1-4.3c-2-0.9-4.8-1-7.4,0.3
					c-2.6,1.3-4.2,2.6-5.7,3.7c-1.5,1.2-3.1,2.4-5.3,3.9c2.6-0.5,4.5-0.5,6.3-0.2C598.3,92.3,600,92.7,602.4,93.3z"
                  />
                </g>
                <g>
                  <path
                    d="M602.3,107.3c2.1,1.2,4.2,1.5,6.1,1.3c1.9-0.3,3.6-1.1,4.4-2.7c0.8-1.6,0.2-3.6-1.6-5.2c-1.8-1.6-4.7-2.6-7.7-2.1
					c-3.1,0.5-5.1,1.4-7,2.2c-2,0.9-3.8,1.7-6.3,2.8c2.8-0.1,4.7,0.4,6.5,1C598.4,105.2,600.1,106.1,602.3,107.3z"
                  />
                </g>
                <g>
                  <path
                    d="M598.1,120.2c2.4,1.8,5,2.6,7.4,2.6c2.3,0,4.2-0.9,4.9-2.5c0.7-1.6-0.1-3.6-1.8-5.3c-1.7-1.6-4.4-2.9-7.5-3.1
					c-6.3-0.3-8.9,0.5-15.1-0.2C591.6,114.5,593.3,116.5,598.1,120.2z"
                  />
                </g>
                <g>
                  <path
                    d="M455.6,157.1c1.3,2.2,2.9,3.7,4.7,4.7c1.8,0.9,3.8,1.2,5.3,0.3c1.5-0.9,2.1-3,1.3-5.4c-0.7-2.4-2.8-5-5.7-6.3
					c-3-1.3-5.3-1.7-7.4-2.1c-2.2-0.4-4.3-0.7-7.2-1.1c2.6,1.4,4.1,2.9,5.4,4.4C453.2,153.1,454.3,154.8,455.6,157.1z"
                  />
                </g>
                <g>
                  <path
                    d="M472.5,157.1c1.1,2.4,2.4,4.3,3.9,5.8c1.5,1.5,3.1,2.7,4.9,2.5c0.9-0.1,1.6-0.6,2.2-1.6c0.6-0.9,0.9-2.2,0.8-3.8
					c-0.1-1.5-0.5-3.3-1.5-4.9c-0.9-1.6-2.3-3.2-4-4.3c-1.8-1.1-3.4-1.8-4.8-2.2c-1.5-0.4-2.8-0.7-4.1-0.8c-2.6-0.3-5.1-0.3-8.6,0.2
					c3.4,0.6,5.5,1.9,7.1,3.3C470,152.9,471.3,154.7,472.5,157.1z"
                  />
                </g>
                <g>
                  <path
                    d="M487.8,156.6c1.5,2.4,3.4,4.2,5.4,5.3c1.9,1.1,4,1.5,5.5,0.6c1.5-0.9,2-3.1,1.1-5.7c-0.9-2.6-3.1-5.4-6.4-6.9
					c-3.3-1.5-5.7-2.1-8.1-2.5c-2.4-0.5-4.7-0.9-8-1.4c3,1.5,4.7,3.1,6.1,4.7C484.9,152.4,486.2,154.1,487.8,156.6z"
                  />
                </g>
                <g>
                  <path
                    d="M506.5,159.3c2.5,2.3,5.7,3.4,8.3,3.4c2.6,0,4.6-1.1,5.1-2.7c0.5-1.7-0.5-3.6-2.2-5.1c-1.7-1.6-4.2-2.8-7.2-3.3
					c-3-0.5-5.2-0.9-7.4-1.4c-2.2-0.5-4.3-1.3-7.2-2.9c2.2,2.5,3.6,4.3,5.1,6.1C502.5,155.1,504,157,506.5,159.3z"
                  />
                </g>
                <g>
                  <path
                    d="M525.3,155.5c3.6,1.7,7.5,2.2,10.5,1.7c3-0.5,5.1-1.9,5.2-3.6c0.2-1.8-1.5-3.5-4.1-4.7c-2.6-1.2-6-2-9.7-2
					c-3.8,0.1-6.5,0.2-9.2,0.1c-2.7-0.1-5.5-0.3-9.4-1.3c3.5,1.9,5.9,3.4,8.3,4.9C519.2,152.2,521.6,153.7,525.3,155.5z"
                  />
                </g>
                <g>
                  <path
                    d="M549,150.1c6.4,2.5,13.2,0.2,13.4-3.3c0.2-3.5-6-6.6-12.5-5.4c-6.7,1.1-9.4,2.3-16.4,2.3
					C540.3,145.7,542.5,147.5,549,150.1z"
                  />
                </g>
                <g>
                  <path
                    d="M568.8,144.1c3.4,1.2,7.1,1.2,9.7,0.4c2.7-0.8,4.5-2.3,4.5-4.1c0-1.8-1.7-3.3-4.2-4.3c-2.5-1-5.8-1.4-9.2-0.8
					c-3.5,0.6-6,1.1-8.5,1.5c-2.5,0.4-5.1,0.6-8.8,0.4c3.5,1.2,5.9,2.3,8.2,3.4C562.9,141.7,565.2,142.8,568.8,144.1z"
                  />
                </g>
                <g>
                  <path
                    d="M580.9,135.9c1.6,1.6,3.5,2.2,5.3,2.2c1.8,0,3.5-0.8,4.4-2.3c0.9-1.5,0.7-3.5-0.5-5.1c-1.2-1.6-3.4-2.9-6.1-3
					c-2.7,0-4.6,0.3-6.4,0.5c-1.8,0.3-3.6,0.5-6,0.6c2.3,0.9,3.7,1.9,5,3C578.1,133,579.3,134.2,580.9,135.9z"
                  />
                </g>
                <g>
                  <path
                    d="M591.4,129.9c3.6,4.4,9.6,5,11.5,2c1.9-3-1.3-8.1-6.8-9.4c-5.7-1.4-8.2-1.2-13.6-3.3
					C586.8,123.1,587.7,125.4,591.4,129.9z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M191.4,346.1c2,10.5,5.5,20.9,10.2,29.9c4.7,9.1,10.8,16.7,16.8,22.3c6,5.6,11.9,9.2,16.3,11.1c4.4,1.9,7.2,2.3,7.5,1.5
					c0.9-1.7-8.1-7.1-17.8-18.4c-4.8-5.7-9.8-12.8-14-21.1c-4.2-8.3-7.8-17.6-10.5-27.4c-2.8-10-4.2-18.8-5.5-26.9
					c-1.3-8.1-2.4-15.4-3.8-22.7c-1.4-7.3-3.2-14.7-6-22.6c-1.4-4-3.2-8.1-5.4-12.3c-2.2-4.2-5.1-8.5-8.7-12.7
					c6.8,8.8,10.2,17.6,12.4,25.5c2.2,8,3.2,15.3,4,22.6c0.7,7.3,1,14.6,1.5,22.8C188.8,326.1,189.3,335.3,191.4,346.1z"
                  />
                </g>
                <g>
                  <path
                    d="M197,289.9c1.7,2.6,4.4,4.1,6.8,4.5c2.4,0.4,4.4-0.3,5.2-1.9c0.8-1.6,0.3-3.5-0.8-5.3c-1.2-1.7-3-3.3-5.5-4.1
					c-2.6-0.9-4.5-1.6-6.3-2.5c-1.8-0.9-3.6-1.9-5.7-3.9c1.3,2.7,2.1,4.6,3,6.6C194.4,285.3,195.3,287.3,197,289.9z"
                  />
                </g>
                <g>
                  <path
                    d="M204,306.5c1.5,2.2,3.9,3.3,5.9,3.4c2.1,0.1,3.8-0.9,4.5-2.5c0.7-1.6,0.3-3.5-0.6-4.9c-1-1.5-2.5-2.6-4.7-3.1
					c-2.2-0.5-3.9-0.8-5.6-1.3c-1.6-0.5-3.2-1.2-5.2-2.6c1.2,2.2,1.9,3.8,2.7,5.4C201.7,302.5,202.5,304.3,204,306.5z"
                  />
                </g>
                <g>
                  <path
                    d="M209.3,323.1c1.8,2.1,4.2,3.1,6.4,3.2c2.1,0.1,3.9-0.8,4.6-2.5c0.7-1.6,0.2-3.5-1-5.1c-1.2-1.6-3.1-2.8-5.6-3.2
					c-2.5-0.4-4.4-0.6-6.2-1c-1.8-0.4-3.6-0.9-6-2c1.7,2,2.8,3.6,3.9,5.2C206.4,319.3,207.5,321,209.3,323.1z"
                  />
                </g>
                <g>
                  <path
                    d="M212.5,342.8c2.4,2.2,5.6,3,8,2.7c2.5-0.3,4.1-1.7,4.3-3.4c0.2-1.8-0.9-3.4-2.4-4.7c-1.5-1.3-3.6-2.2-6.1-2.4
					c-2.6-0.3-4.5-0.6-6.4-1.1c-1.9-0.5-3.8-1.3-6.1-3.2c1.5,2.5,2.6,4.3,3.8,6.2C208.8,338.7,210.1,340.6,212.5,342.8z"
                  />
                </g>
                <g>
                  <path
                    d="M219.1,359c2.6,1.9,5.8,2.6,8.3,2.2c2.5-0.3,4.3-1.6,4.6-3.3c0.3-1.7-0.8-3.5-2.7-4.8c-1.8-1.3-4.4-2.3-7.3-2.4
					c-3-0.1-5.1-0.1-7.3-0.4c-2.2-0.2-4.3-0.6-7.3-1.8c2.4,2.1,4,3.6,5.6,5.2C214.7,355.4,216.3,357,219.1,359z"
                  />
                </g>
                <g>
                  <path
                    d="M223.9,370.7c2.2,1.9,5,2.5,7.2,2.2c2.2-0.3,3.9-1.5,4.3-3.2c0.4-1.7-0.5-3.5-2-4.8c-1.5-1.4-3.7-2.3-6.2-2.3
					c-2.6-0.1-4.6-0.1-6.5-0.2c-1.9-0.2-3.8-0.5-6.4-1.5c2,1.9,3.4,3.4,4.7,4.9C220.3,367.2,221.6,368.7,223.9,370.7z"
                  />
                </g>
                <g>
                  <path
                    d="M234,386.9c2.5,1.9,5.5,2.6,7.9,2.4c2.4-0.2,4.2-1.4,4.6-3.1c0.4-1.7-0.6-3.5-2.4-4.9c-1.8-1.4-4.2-2.4-7.1-2.5
					c-3-0.1-5.1-0.2-7.2-0.3c-2.1-0.2-4.2-0.5-7.2-1.6c2.4,2,4,3.5,5.6,5C229.9,383.4,231.4,385,234,386.9z"
                  />
                </g>
                <g>
                  <path
                    d="M241.6,400.7c2,2.1,4.3,3.2,6.5,3.5c2.2,0.3,4.2-0.2,5.2-1.7c1-1.5,0.6-3.6-0.8-5.5c-1.4-1.9-3.8-3.6-6.8-4.2
					c-3.1-0.6-5.3-0.7-7.4-0.9c-2.1-0.2-4.3-0.4-7.2-1c2.6,1.6,4.2,3,5.6,4.5C238.2,397,239.6,398.6,241.6,400.7z"
                  />
                </g>
                <g>
                  <path
                    d="M249.5,414.4c1.3,2.2,3.2,3.7,5.1,4.3c1.9,0.7,3.9,0.6,5.2-0.6c1.3-1.2,1.6-3.2,0.7-5.3c-0.8-2.1-2.8-4.2-5.5-5.3
					c-2.8-1.1-4.9-1.5-6.9-1.9c-2-0.4-3.9-0.9-6.6-1.7c2.2,1.8,3.5,3.3,4.6,4.9C247.1,410.4,248.1,412.1,249.5,414.4z"
                  />
                </g>
                <g>
                  <path
                    d="M244.4,430c-0.4,2.4,0.1,4.4,1.1,6c1,1.6,2.6,2.7,4.3,2.7c1.8,0,3.3-1.3,4-3.4c0.7-2.1,0.6-4.9-0.9-7.3
					c-1.5-2.5-2.9-4-4.2-5.4c-1.3-1.4-2.6-2.9-4.3-4.9c0.7,2.6,0.8,4.4,0.7,6.2C245.1,425.8,244.8,427.6,244.4,430z"
                  />
                </g>
                <g>
                  <path
                    d="M230.5,431.1c-1,2.2-1.2,4.3-0.7,6.2c0.4,1.9,1.4,3.5,3.1,4.2c1.6,0.6,3.6-0.1,5-2.1c1.4-1.9,2.2-4.9,1.4-7.9
					c-0.8-3-1.8-4.9-2.8-6.8c-1-1.9-2-3.7-3.3-6.1c0.3,2.8,0,4.7-0.4,6.6C232.2,427,231.5,428.8,230.5,431.1z"
                  />
                </g>
                <g>
                  <path
                    d="M217.3,428c-1.6,2.5-2.2,5.3-1.9,7.5c0.2,2.3,1.2,4.1,2.9,4.7c1.7,0.5,3.6-0.4,5.1-2.2c1.5-1.9,2.5-4.7,2.4-7.7
					c-0.3-6.3-1.2-8.8-1.1-15C222.4,421,220.5,422.8,217.3,428z"
                  />
                </g>
                <g>
                  <path
                    d="M168.4,289.1c-2.1,1.5-3.5,3.2-4.2,5.1c-0.7,1.9-0.9,3.9,0.2,5.3c1,1.4,3.2,1.8,5.5,0.9c2.3-0.9,4.7-3.2,5.8-6.3
					c1.1-3.2,1.3-5.4,1.5-7.6c0.2-2.2,0.3-4.3,0.4-7.3c-1.2,2.7-2.5,4.3-3.9,5.7C172.1,286.4,170.5,287.6,168.4,289.1z"
                  />
                </g>
                <g>
                  <path
                    d="M169.7,305.9c-2.3,1.3-4,2.7-5.5,4.3c-1.4,1.6-2.5,3.4-2.1,5.1c0.2,0.9,0.8,1.6,1.7,2c1,0.5,2.3,0.7,3.8,0.5
					c1.5-0.2,3.2-0.8,4.8-1.9c1.5-1.1,3-2.6,3.9-4.4c1-1.9,1.5-3.5,1.8-5c0.3-1.5,0.4-2.8,0.5-4.1c0-2.6-0.1-5.2-0.9-8.6
					c-0.3,3.5-1.4,5.6-2.7,7.4C173.7,303.1,172.1,304.6,169.7,305.9z"
                  />
                </g>
                <g>
                  <path
                    d="M171.5,321.1c-2.3,1.7-3.9,3.7-4.8,5.8c-0.9,2-1.2,4.1-0.1,5.5c1,1.4,3.3,1.7,5.8,0.6c2.5-1.1,5.1-3.6,6.3-6.9
					c1.2-3.4,1.6-5.9,1.8-8.3c0.3-2.4,0.5-4.7,0.7-8.1c-1.3,3.1-2.6,4.9-4.1,6.5C175.6,317.9,173.9,319.3,171.5,321.1z"
                  />
                </g>
                <g>
                  <path
                    d="M170.5,340.1c-2.1,2.7-2.9,6-2.6,8.5c0.2,2.6,1.4,4.5,3.2,4.8c1.7,0.3,3.5-0.8,4.9-2.6c1.4-1.9,2.4-4.4,2.7-7.4
					c0.3-3.1,0.4-5.3,0.8-7.5c0.4-2.2,0.9-4.4,2.3-7.4c-2.3,2.4-3.9,4-5.6,5.6C174.4,335.7,172.6,337.3,170.5,340.1z"
                  />
                </g>
                <g>
                  <path
                    d="M175.9,358.4c-1.4,3.7-1.5,7.7-0.8,10.6c0.7,3,2.3,4.9,4,4.9c1.8,0,3.3-1.8,4.3-4.5c1-2.7,1.5-6.2,1.1-9.9
					c-0.4-3.8-0.7-6.5-0.9-9.2c-0.2-2.7-0.2-5.5,0.4-9.4c-1.6,3.7-2.9,6.2-4.2,8.7C178.7,352.1,177.4,354.6,175.9,358.4z"
                  />
                </g>
                <g>
                  <path
                    d="M183.3,381.6c-1.9,6.6,0.9,13.2,4.4,13.1c3.5-0.1,6-6.5,4.4-12.9c-1.7-6.6-3.1-9.1-3.7-16.1
					C186.9,372.5,185.3,374.9,183.3,381.6z"
                  />
                </g>
                <g>
                  <path
                    d="M190.9,400.8c-0.9,3.5-0.6,7.1,0.4,9.7c1,2.6,2.7,4.3,4.5,4.1c1.8-0.1,3.2-2,3.9-4.5c0.8-2.6,0.9-5.9,0-9.3
					c-0.9-3.5-1.6-5.9-2.2-8.4c-0.6-2.5-1-5-1.2-8.7c-0.9,3.6-1.8,6-2.6,8.5C192.8,394.7,191.9,397.2,190.9,400.8z"
                  />
                </g>
                <g>
                  <path
                    d="M200.2,412.2c-1.4,1.7-1.9,3.7-1.8,5.5c0.2,1.8,1.1,3.4,2.7,4.2c1.6,0.8,3.5,0.4,5-1c1.5-1.3,2.6-3.7,2.5-6.3
					c-0.2-2.7-0.6-4.5-1.1-6.3c-0.4-1.8-0.8-3.5-1.1-6c-0.7,2.4-1.6,3.9-2.6,5.3C202.8,409.1,201.7,410.4,200.2,412.2z"
                  />
                </g>
                <g>
                  <path d="M207,422.2c-4,4-4.1,10-1,11.6c3.1,1.6,8-2,8.8-7.6c0.9-5.8,0.5-8.2,2.1-13.8C213.4,417,211.1,418.1,207,422.2z" />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M378.5,8.1c9.5-5,18.3-11.4,25.5-18.6c7.3-7.2,12.8-15.2,16.3-22.6c3.6-7.4,5.3-14.1,5.8-18.9c0.5-4.8,0-7.5-0.8-7.6
					c-1.9-0.3-4.3,9.8-12.3,22.5c-4,6.3-9.3,13.2-16,19.6c-6.6,6.5-14.6,12.7-23,18.1c-8.7,5.6-16.7,9.6-24,13.2
					c-7.3,3.6-14,6.8-20.6,10.4c-6.6,3.6-13.1,7.4-19.8,12.5c-3.4,2.5-6.8,5.4-10.1,8.8c-3.4,3.4-6.6,7.4-9.6,12
					c6.4-9.1,13.8-14.9,20.7-19.4c7-4.5,13.7-7.6,20.5-10.5c6.8-2.8,13.7-5.3,21.4-8.2C360.1,16.5,368.7,13.3,378.5,8.1z"
                  />
                </g>
                <g>
                  <path
                    d="M323.1,19.3c2-2.4,2.7-5.4,2.3-7.8c-0.3-2.4-1.6-4.1-3.3-4.4c-1.7-0.3-3.5,0.7-4.8,2.3c-1.3,1.6-2.2,3.9-2.3,6.5
					c-0.1,2.7-0.2,4.7-0.5,6.7c-0.3,2-0.8,4-2.1,6.6c2.2-2,3.7-3.4,5.4-4.8C319.4,23.2,321.1,21.8,323.1,19.3z"
                  />
                </g>
                <g>
                  <path
                    d="M336.9,7.8c1.6-2.1,2-4.7,1.5-6.6c-0.5-2-2-3.4-3.7-3.5c-1.8-0.2-3.4,0.7-4.5,2.1c-1.1,1.4-1.8,3.2-1.6,5.4
					c0.2,2.3,0.4,4,0.4,5.7c0,1.7-0.1,3.4-1,5.8c1.7-1.8,3-3,4.4-4.2C333.8,11.1,335.2,9.9,336.9,7.8z"
                  />
                </g>
                <g>
                  <path
                    d="M351.2-2.2c1.5-2.3,1.7-5,1.1-7c-0.6-2.1-2-3.5-3.7-3.7c-1.8-0.2-3.4,0.8-4.6,2.4c-1.1,1.6-1.8,3.8-1.4,6.3
					c0.4,2.6,0.7,4.4,0.9,6.3c0.2,1.9,0.3,3.7-0.1,6.4c1.4-2.3,2.6-3.8,3.8-5.3C348.4,1.6,349.7,0.1,351.2-2.2z"
                  />
                </g>
                <g>
                  <path
                    d="M369.1-11.1c1.4-2.9,1.2-6.2,0.2-8.4c-1-2.3-2.9-3.4-4.6-3.1c-1.7,0.3-3,1.8-3.8,3.7c-0.8,1.9-1,4.1-0.5,6.5
					c0.5,2.6,0.8,4.5,0.9,6.5c0,2-0.1,4-1.2,6.8c2-2.2,3.4-3.8,4.8-5.5C366.2-6.4,367.6-8.1,369.1-11.1z"
                  />
                </g>
                <g>
                  <path
                    d="M382.6-22.2c1-3.1,0.7-6.3-0.3-8.6c-1-2.3-2.8-3.6-4.5-3.4c-1.8,0.2-3.1,1.8-3.8,4c-0.7,2.1-0.9,4.8-0.1,7.6
					c0.8,2.9,1.4,5,1.8,7.1c0.4,2.1,0.7,4.3,0.4,7.5c1.3-2.9,2.3-4.9,3.3-6.9C380.4-16.9,381.5-19,382.6-22.2z"
                  />
                </g>
                <g>
                  <path
                    d="M392.3-30.2c1.1-2.7,1-5.5,0-7.5c-0.9-2-2.6-3.3-4.3-3.1c-1.8,0.1-3.2,1.5-4,3.3c-0.8,1.8-1.1,4.2-0.4,6.6
					c0.7,2.5,1.3,4.4,1.7,6.3c0.4,1.9,0.7,3.8,0.5,6.6c1.2-2.5,2.2-4.2,3.2-5.9C390-25.7,391.1-27.5,392.3-30.2z"
                  />
                </g>
                <g>
                  <path
                    d="M404.8-44.7c1.1-3,0.9-6-0.1-8.3c-1-2.2-2.6-3.6-4.4-3.5c-1.8,0.1-3.2,1.6-4,3.7c-0.8,2.1-1,4.7-0.3,7.5
					c0.8,2.9,1.4,4.9,1.8,7c0.4,2.1,0.8,4.2,0.6,7.3c1.2-2.9,2.1-4.8,3.1-6.8C402.6-39.7,403.7-41.7,404.8-44.7z"
                  />
                </g>
                <g>
                  <path
                    d="M415.7-56.1c1.4-2.5,1.8-5.1,1.4-7.3c-0.3-2.2-1.4-3.9-3.1-4.4c-1.7-0.5-3.6,0.4-5,2.3c-1.4,1.9-2.3,4.7-1.9,7.8
					c0.4,3.1,0.9,5.2,1.3,7.3c0.5,2.1,0.9,4.2,1.2,7.2c0.8-2.9,1.6-4.9,2.6-6.7C413.2-51.8,414.3-53.5,415.7-56.1z"
                  />
                </g>
                <g>
                  <path
                    d="M426.4-67.6c1.7-1.9,2.5-4.1,2.6-6.1c0.1-2-0.6-3.9-2.1-4.8c-1.5-0.9-3.5-0.6-5.3,0.9c-1.8,1.4-3.2,3.9-3.4,6.8
					c-0.2,3,0,5.1,0.2,7.1c0.2,2,0.3,4,0.3,6.9c1-2.6,2.1-4.3,3.3-5.8C423.3-64.2,424.7-65.6,426.4-67.6z"
                  />
                </g>
                <g>
                  <path
                    d="M442.9-67.4c2.4-0.3,4.2-1.4,5.4-2.9c1.2-1.4,1.9-3.2,1.3-4.9c-0.5-1.7-2.2-2.8-4.4-2.8c-2.2-0.1-4.8,0.9-6.7,3.1
					c-1.9,2.2-2.9,3.9-3.9,5.6c-1,1.7-2,3.3-3.4,5.6c2.2-1.5,4-2.1,5.7-2.6C438.6-66.8,440.4-67.1,442.9-67.4z"
                  />
                </g>
                <g>
                  <path
                    d="M448-54.4c2.4,0.3,4.5-0.2,6.2-1.1c1.7-1,3-2.4,3.1-4.2c0.1-1.7-1.2-3.4-3.4-4.2c-2.2-0.8-5.3-0.6-7.9,1
					c-2.7,1.7-4.2,3.2-5.7,4.6c-1.5,1.6-2.9,3-4.8,5c2.6-1.1,4.5-1.4,6.4-1.5C443.6-54.9,445.5-54.7,448-54.4z"
                  />
                </g>
                <g>
                  <path
                    d="M449-40.9c2.9,0.8,5.7,0.5,7.8-0.4c2.1-0.9,3.6-2.4,3.6-4.2c0-1.8-1.4-3.3-3.6-4.2c-2.2-0.9-5.2-1-8.1,0
					c-6,2.1-8,3.8-14,5.5C440.8-43.7,443.1-42.4,449-40.9z"
                  />
                </g>
                <g>
                  <path
                    d="M330.8,46.9c2,1.6,4.1,2.4,6.1,2.5c2,0.2,3.9-0.3,5-1.7c1-1.4,0.8-3.5-0.8-5.5c-1.5-1.9-4.4-3.6-7.7-3.6
					c-3.3-0.1-5.5,0.4-7.7,0.8c-2.1,0.5-4.2,1-7.1,1.7c3,0.3,4.9,1.1,6.6,2C327.1,44.2,328.7,45.3,330.8,46.9z"
                  />
                </g>
                <g>
                  <path
                    d="M346.5,40.6c1.9,1.8,3.8,3.1,5.8,3.9c1.9,0.8,3.9,1.4,5.5,0.5c0.8-0.4,1.3-1.2,1.4-2.3c0.2-1.1,0-2.4-0.7-3.8
					c-0.6-1.4-1.7-2.8-3.2-4c-1.5-1.2-3.3-2.1-5.3-2.4c-2.1-0.4-3.8-0.4-5.3-0.3c-1.5,0.1-2.8,0.4-4.1,0.8c-2.5,0.7-4.9,1.6-7.9,3.4
					c3.4-0.8,5.8-0.3,7.9,0.4C342.6,37.7,344.5,38.8,346.5,40.6z"
                  />
                </g>
                <g>
                  <path
                    d="M360.5,34.4c2.3,1.7,4.7,2.6,6.9,2.9c2.2,0.3,4.3-0.1,5.3-1.5c1-1.4,0.7-3.6-1.1-5.7c-1.8-2.1-4.9-3.8-8.5-4
					c-3.6-0.2-6.1,0.3-8.5,0.7c-2.4,0.5-4.7,1-7.9,1.7c3.3,0.3,5.5,1.1,7.4,2C356.2,31.5,358.1,32.7,360.5,34.4z"
                  />
                </g>
                <g>
                  <path
                    d="M378.8,29.8c3.2,1.2,6.5,1,8.9,0c2.4-1,3.8-2.7,3.7-4.4c-0.2-1.8-1.8-3.1-4-3.9c-2.2-0.8-5-1-7.9-0.4
					c-3,0.7-5.2,1.2-7.4,1.5c-2.2,0.3-4.5,0.4-7.8,0c3,1.5,5,2.6,7,3.7C373.5,27.4,375.6,28.6,378.8,29.8z"
                  />
                </g>
                <g>
                  <path
                    d="M394.8,19.2c4,0.3,7.8-0.8,10.4-2.4c2.6-1.6,4-3.6,3.5-5.3c-0.5-1.7-2.7-2.6-5.6-2.8c-2.9-0.2-6.4,0.4-9.8,1.9
					c-3.5,1.5-6,2.6-8.5,3.6c-2.5,1-5.2,1.8-9.2,2.4c4,0.5,6.7,0.9,9.5,1.4C387.9,18.4,390.7,19,394.8,19.2z"
                  />
                </g>
                <g>
                  <path
                    d="M414.8,5.3c6.9-0.1,12.4-4.8,11.2-8.1c-1.2-3.3-8-3.8-13.6-0.3c-5.8,3.6-7.8,5.6-14.3,8.3C405,4.5,407.7,5.3,414.8,5.3z
					"
                  />
                </g>
                <g>
                  <path
                    d="M430.8-7.7c3.7-0.2,7-1.5,9.2-3.3c2.2-1.7,3.3-3.8,2.6-5.5c-0.7-1.6-2.8-2.4-5.5-2.4c-2.7,0-5.9,0.9-8.8,2.7
					c-3,1.9-5.1,3.3-7.3,4.6c-2.2,1.3-4.5,2.5-8,3.7c3.7-0.2,6.3-0.1,8.9,0C424.5-7.7,427.1-7.5,430.8-7.7z"
                  />
                </g>
                <g>
                  <path
                    d="M439-19.9c2.1,0.9,4.1,0.8,5.8,0c1.6-0.7,3-2.1,3.3-3.8c0.3-1.7-0.7-3.5-2.4-4.5c-1.7-1.1-4.3-1.4-6.7-0.5
					c-2.5,1-4.1,2-5.7,2.9c-1.6,0.9-3.1,1.8-5.4,2.9c2.5,0,4.2,0.4,5.8,0.9C435.2-21.5,436.8-20.8,439-19.9z"
                  />
                </g>
                <g>
                  <path d="M446.5-29.3c5,2.7,10.8,1,11.4-2.5c0.6-3.5-4.3-7-9.9-6.2c-5.8,0.9-8,2-13.8,2C439.6-33.9,441.3-32.1,446.5-29.3z" />
                </g>
              </g>
              <g>
                <path
                  d="M533.7,299.2c0.6-0.6,1.1-1.2,1.6-1.8c0.5-0.7,1-1.3,1.5-2c0.9-1.4,1.8-2.8,2.4-4.3c1.4-3,2.2-6.2,2.6-9.4
				c0.8-6.4-0.3-12.8-2.8-18.6c-1.2-2.9-3-5.7-5-8.1c-2-2.4-4.2-4.5-6.6-6.4c-4.8-3.6-10.2-6.1-15.6-7.6c-2.7-0.7-5.5-1.3-8.2-1.5
				c-2.7-0.3-5.5-0.3-8.1-0.2c-5.4,0.3-10.8,1.5-15.7,3.8c-5,2.3-9.4,5.8-12.6,10c-1.6,2.1-2.9,4.5-3.9,6.9
				c-0.9,2.4-1.5,5.1-1.6,7.7c-0.3,5.1,1,10,3.5,14.1c1.3,2,2.9,3.8,4.7,5.2c1.8,1.4,3.8,2.4,5.8,3.1c4,1.3,8,1.3,11.5,0.3
				c3.5-1,6.4-3.3,8-5.7c1.7-2.4,2.4-4.9,2.6-7c0.2-2.1-0.1-4-0.6-5.3c-0.5-1.4-1.2-2.3-1.7-2.8c-0.6-0.5-1-0.6-1.3-0.5
				c-0.6,0.3-0.4,1.7-0.7,3.8c-0.1,1-0.4,2.2-1.1,3.5c-0.6,1.2-1.5,2.6-2.7,3.6c-0.6,0.5-1.3,0.9-2,1.3c-0.7,0.3-1.6,0.5-2.5,0.6
				c-1.8,0.2-3.8-0.2-5.7-1.2c-0.9-0.5-1.8-1.1-2.5-1.9c-0.7-0.7-1.3-1.6-1.8-2.7c-1-2.1-1.4-4.7-1-7.3c0.2-1.3,0.6-2.5,1.2-3.8
				c0.6-1.2,1.4-2.4,2.4-3.6c1-1.1,2.1-2.2,3.4-3.1c1.3-0.9,2.7-1.7,4.3-2.3c6.2-2.5,14.7-2.8,22.4-0.6c3.8,1.1,7.5,2.8,10.6,5.1
				c1.5,1.2,3,2.5,4.2,3.9c0.6,0.7,1.1,1.5,1.7,2.2l0.7,1.2l0.6,1.3c1.6,3.4,2.4,7.4,2.2,11.1c-0.1,1.9-0.5,3.7-1.2,5.4
				c-0.3,0.9-0.7,1.6-1.2,2.4l-0.7,1.1c-0.3,0.4-0.5,0.7-0.9,1.1c-2.5,3-6.1,5.2-10.1,6.9c-2,0.9-4.1,1.6-6.1,2.2
				c-1.1,0.3-2.1,0.6-3.2,0.9c-1.1,0.3-2.1,0.6-3.1,0.8c-8.4,1.9-16.5,2.6-24.2,2.5c-7.7-0.1-15.1-1.1-22.2-2.7
				c-7.1-1.6-13.8-3.8-20.3-6.6c-13-5.7-25.3-13.9-36.8-25.5c-5.7-5.8-11.3-12.5-16.4-20.3c-5.2-7.7-9.9-16.7-13.9-26.8
				c3.7,10.3,8,19.4,12.8,27.5c4.8,8.1,10,15.2,15.6,21.4c11.1,12.5,23,21.8,36.3,28.8c6.6,3.5,13.5,6.4,20.9,8.7
				c7.4,2.3,15.2,3.9,23.5,4.7c8.4,0.8,17.3,0.7,26.8-0.7c1.2-0.1,2.4-0.4,3.6-0.6c1.2-0.2,2.4-0.5,3.6-0.7c2.5-0.6,5-1.2,7.6-2.1
				c2.6-0.9,5.2-2,7.8-3.5C528.7,303.5,531.3,301.6,533.7,299.2z"
                />
              </g>
              <g>
                <path
                  d="M454.1,257.7c3.8-5.9,5.1-13.2,3.9-20.1c-0.6-3.4-1.9-6.8-3.7-9.7c-1.8-2.9-4.1-5.5-6.7-7.6c-2.6-2.1-5.5-3.7-8.6-4.7
				c-3.1-1-6.3-1.5-9.5-1.3c-3.2,0.2-6.3,0.9-9.1,2.4c-0.7,0.3-1.4,0.8-2,1.2c-0.7,0.5-1.3,0.9-1.9,1.4c-1.2,1-2.2,2.1-3.1,3.3
				c-1.7,2.4-2.8,5-3.3,7.6c-0.5,2.6-0.3,5.3,0.4,7.7c0.8,2.5,2.1,4.4,3.6,5.7c1.5,1.4,3,2.3,4.5,2.9c1.5,0.6,2.9,0.8,4.1,0.8
				c0.6,0,1.2-0.1,1.7-0.2c0.5-0.1,0.9-0.3,1.3-0.4c0.8-0.4,1.3-0.8,1.5-1.2c0.3-0.4,0.4-0.7,0.3-1.1c-0.2-0.7-1-1-2-1.6
				c-1-0.6-2.2-1.3-3.3-2.6c-1.1-1.2-1.9-3.1-1.8-4.5c0.1-0.9,0.2-1.7,0.7-2.6c0.4-0.9,1.1-1.8,1.9-2.6c0.4-0.4,0.9-0.7,1.3-1
				c0.2-0.1,0.5-0.2,0.7-0.4c0.3-0.1,0.5-0.2,0.8-0.3c1.1-0.4,2.4-0.5,3.9-0.5c2.9,0.1,5.9,1.3,8.6,3.4c1.3,1.1,2.5,2.4,3.4,3.8
				c0.9,1.5,1.6,3,2,4.7c0.8,3.4,0.5,7.4-1.1,10.7c-1.6,3.5-4.6,6.3-7.9,8.3c-3.4,1.9-7,3-10.6,3.3c-3.7,0.4-7.3,0.1-10.9-0.6
				c-3.5-0.7-7-1.8-10.4-3.2c-3.4-1.4-6.6-3.1-9.9-5.2c-3.2-2.1-6.3-4.6-9.4-7.5c-3-2.9-6-6.3-8.7-10.3c-2.7-4-5.2-8.6-7.1-14
				c3.2,11,8.3,19.4,13.9,26.1c2.8,3.4,5.7,6.3,8.8,9c3.1,2.6,6.3,4.9,9.7,7c3.4,2.1,7,3.8,11,5.2c3.9,1.4,8.2,2.4,13,2.7
				c4.7,0.3,10-0.4,15.2-2.5c2.6-1,5.2-2.5,7.8-4.4C449.8,263.2,452.2,260.8,454.1,257.7z"
                />
              </g>
              <g>
                <path
                  d="M336.8,288.9c-1.6,6.2-2,12.8-1.5,18.8c0.5,6,2,11.6,3.9,16c1.9,4.5,4.2,7.8,6.2,9.9c2.1,2.1,3.9,2.9,5,2.3
				c1.2-0.6,1.7-2.5,1.6-5.1c0.1-2.7-0.4-6.2-0.8-10.4c-0.4-4.1-0.9-8.8-1.2-13.8c-0.3-5-0.4-10.4-0.3-15.8
				c0.1-5.6,0.8-10.4,1.7-14.7c0.9-4.3,2.2-8.3,3.4-12.5c1.2-4.1,2.4-8.3,3.2-13.2c0.4-2.4,0.6-5,0.6-7.8c0-2.8-0.4-5.7-1.3-8.7
				c0.5,3.1,0.5,6,0.1,8.6c-0.4,2.6-1.1,5-1.9,7.2c-1.6,4.4-3.7,8-5.9,11.6c-2.2,3.6-4.5,7.2-6.8,11.5
				C340.7,277.4,338.5,282.5,336.8,288.9z"
                />
              </g>
              <g>
                <path
                  d="M350.5,287.2c-2,4.9-4.1,10-5.6,15.3c-1.5,5.3-2.2,10.7-1.9,15.5c0.3,4.8,1.5,8.7,3,11.2c1.5,2.5,3.2,3.5,4.5,3.1
				c1.3-0.4,2-2,2.7-4.3c0.7-2.3,1.4-5.3,2.3-8.9c0.9-3.6,2.2-7.7,3.7-12.4c1.4-4.7,3-10.1,4-15.9c0.9-6,1.2-11.1,1.2-15.7
				c0-4.6-0.4-8.7-0.9-12.8c-0.5-4.1-1.2-8.1-2.1-12.5c-1-4.4-2.2-9.3-4.1-14.9c1.1,5.8,1.4,10.8,1.4,15.2c0,4.4-0.4,8.4-0.9,12.2
				c-0.6,3.8-1.4,7.6-2.5,11.6C354.1,277.9,352.6,282.2,350.5,287.2z"
                />
              </g>
              <g>
                <path
                  d="M393.3,330.8c-0.5,0.7-1,1.3-1.5,1.9c-0.5,0.6-1.1,1.1-1.6,1.6c-1.2,0.9-2.4,1.7-3.7,2.3c-1.3,0.6-2.5,0.8-3.7,0.9
				c-1.2,0.1-2.1,0-3.2-0.4c-2.1-0.6-3.9-2.2-4.8-3.6c-0.4-0.7-0.7-1.4-0.8-2.3c-0.1-0.9,0-1.8,0.2-2.8c0.5-1.9,1.6-3.7,2.7-4.9
				c1.2-1.2,2-1.6,2.4-2c0.3-0.1,0.8-0.1,1.2,0c0.4,0.1,0.8,0.3,1.1,0.5c0.7,0.4,1.1,1.1,1.7,1.4c0.3,0.1,0.7,0.1,1.1-0.1
				c0.4-0.3,0.8-0.8,1.1-1.6c0.2-0.8,0.2-2-0.2-3.1c-0.4-1.2-1.2-2.4-2.6-3.5c-1.6-1.1-3.4-1.5-5.4-1.5c-1.9,0-3.8,0.5-5.6,1.3
				c-3.5,1.6-6.7,4.5-9,8.7c-1.1,2.1-1.9,4.6-2.1,7.3c-0.2,2.7,0.2,5.8,1.4,8.5c1.1,2.8,2.9,5.2,5.1,7.2c2.2,2,4.8,3.6,7.7,4.6
				c2.9,1,6.3,1.4,9.3,1.1c3.1-0.3,6.1-1.2,8.7-2.6c2.7-1.4,5-3.2,7.1-5.3c1-1.1,1.9-2.2,2.8-3.4c0.8-1.2,1.5-2.4,2.1-3.7
				c2.5-5.3,3.2-10.4,3.2-14.9c0-4.6-0.8-8.7-1.8-12.3c-1-3.7-2.3-6.9-3.6-10c-1.3-3.1-2.7-6-4.2-8.9c-1.5-2.9-3-5.7-4.8-8.6
				c-1.7-2.9-3.5-5.8-5.6-8.7c-4.1-6-8.9-12.3-15.4-19.3c5.9,7.4,9.9,14.3,13.1,20.6c1.6,3.2,3,6.2,4.2,9.2c1.2,3,2.2,6,3.2,8.9
				c0.9,3,1.7,6,2.4,9c0.7,3.1,1.3,6.2,1.6,9.4c0.3,3.2,0.3,6.5-0.3,9.8C396.4,324.7,395.3,328,393.3,330.8z"
                />
              </g>
              <g>
                <path
                  d="M211.1,46.3c3.4-0.8,6.9-1,10.2-0.6c3.3,0.4,6.4,1.5,8.9,3.1c2.6,1.6,4.7,3.9,6.2,6.4c0.7,1.2,1.3,2.5,1.7,3.8
				c0.4,1.3,0.5,2.6,0.6,3.9c0.1,5.3-3,11-7.3,15.2c-1.1,1.1-2.2,2.1-3.4,2.9c-1.2,0.9-2.4,1.7-3.5,2.4c-2.4,1.4-4.7,2.1-6.6,2.3
				c-1,0.1-1.9,0-2.6-0.2c-0.8-0.2-1.6-0.5-2.3-1c-1.5-1-2.7-2.4-3.5-3.9c-0.4-0.8-0.6-1.5-0.8-2.3c-0.1-0.4-0.1-0.8-0.2-1.1
				c0-0.4,0-0.7,0-1.1c0.1-1.5,0.6-3,1.3-4.2c1.4-2.5,3.4-4.2,4.9-5.2c1.5-1,2.5-1.6,2.5-2.3c0-0.6-1.2-1.4-3.6-1.3
				c-1.2,0-2.7,0.3-4.3,0.9c-1.6,0.7-3.4,1.7-5.1,3.3c-1.7,1.6-3.3,3.8-4.3,6.8c-0.3,0.7-0.4,1.5-0.6,2.3c-0.1,0.8-0.2,1.6-0.2,2.5
				c-0.1,1.7,0.2,3.4,0.6,5.2c0.9,3.5,2.9,6.8,5.9,9.6c1.5,1.4,3.4,2.6,5.5,3.4c2.2,0.9,4.4,1.3,6.6,1.4c4.5,0.2,8.9-1,12.7-2.8
				c1.9-0.9,3.7-1.9,5.5-3.1c1.8-1.2,3.5-2.5,5.1-4c1.6-1.4,3.2-3.1,4.6-4.9c1.4-1.8,2.7-3.7,3.9-5.8c1.1-2.1,2.1-4.4,2.8-6.9
				c0.7-2.5,1.1-5.1,1.1-7.8c0-2.7-0.3-5.5-1.1-8.1c-0.8-2.6-1.9-5.1-3.3-7.4c-2.8-4.6-6.7-8.4-11.4-11.2c-4.7-2.8-9.9-4.2-15.1-4.6
				c-5.2-0.4-10.3,0.3-15.2,1.9c-5,1.6-9.3,4-13,6.7c-3.7,2.7-6.9,5.8-9.6,9c-5.3,6.4-9.1,13.2-11.6,19.9
				c-2.5,6.7-3.9,13.4-4.6,19.9c-0.7,6.5-0.7,12.8-0.1,19.1c0.6,6.3,1.8,12.4,3.6,18.6c1.8,6.2,4.1,12.4,6.9,18.7
				c5.7,12.7,13.8,25.8,24.4,40.6c-10-15.2-17.2-28.8-22-41.6c-2.4-6.4-4.2-12.6-5.4-18.7c-1.2-6.1-1.8-12-1.9-18
				c0-5.9,0.5-11.8,1.7-17.7c1.2-5.8,3-11.7,5.8-17.4c2.8-5.6,6.5-11.1,11.4-16c2.4-2.4,5.2-4.6,8.2-6.5
				C204.3,48.6,207.6,47.1,211.1,46.3z"
                />
              </g>
              <g>
                <path
                  d="M217.7,111.6c1.7-0.5,3.2-0.7,4.8-0.7c1.5,0,2.9,0.2,4,0.6c0.5,0.2,1,0.4,1.3,0.7c0.4,0.2,0.7,0.5,0.9,0.8
				c0.5,0.6,1,1.5,1.3,2.5c0.2,0.5,0.3,1.1,0.3,1.7c0.1,0.6,0.1,1.1,0,1.7c-0.1,1.1-0.4,2.2-0.7,3c-0.2,0.4-0.4,0.8-0.6,1.1
				c-0.2,0.3-0.4,0.6-0.7,0.8c-0.3,0.2-0.4,0.4-0.7,0.6c-0.3,0.2-0.6,0.3-1,0.4c-0.7,0.2-1.5,0.2-2.3,0.1c-0.7-0.1-1.4-0.3-2-0.7
				c-1.2-0.6-2.2-2-2.8-3.3c-0.6-1.3-0.9-2.6-1.1-3.6c-0.2-1-0.5-1.8-1-2.2c-0.5-0.3-1.4-0.2-2.5,0.7c-1,0.9-2.2,2.7-2.6,5.4
				c-0.2,1.3-0.2,2.9,0.1,4.5c0.3,1.7,1,3.5,2.3,5.2c2.5,3.5,7,6.2,12.4,6.3c1.3,0,2.7-0.1,4.2-0.4c1.4-0.3,2.9-0.9,4.2-1.7
				c1.3-0.8,2.5-1.7,3.6-2.8c1.1-1.1,2-2.3,2.8-3.5c1.5-2.5,2.5-5.3,3-8.3c0.2-1.5,0.3-3,0.2-4.5c-0.1-1.5-0.3-3-0.7-4.5
				c-0.8-3-2.3-6.1-4.8-8.7c-2.5-2.6-5.8-4.5-9-5.3c-3.2-0.9-6.4-1-9.4-0.6c-1.5,0.2-2.9,0.5-4.3,0.9c-1.4,0.4-2.7,1-4,1.5
				c-5.3,2.4-9.2,5.9-12.2,9.4c-1.5,1.8-2.8,3.6-3.9,5.4c-1.1,1.9-2,3.7-2.8,5.5c-3,7.5-3.6,14.5-3.3,21.3c0.3,6.8,1.7,13.4,4,20.4
				c2.2,7,5.4,14.5,9.3,23.3c-3.1-9.1-5.5-16.9-6.7-24c-1.3-7.1-1.6-13.5-0.8-19.6c0.7-6.1,2.5-12,5.9-17.3c0.8-1.3,1.8-2.6,2.9-3.9
				c1.1-1.2,2.3-2.4,3.5-3.5C211.2,114.4,214.2,112.5,217.7,111.6z"
                />
              </g>
              <g>
                <path
                  d="M252.1,32.8c3.2,0.6,6.4-0.3,8.5-1.8c2.1-1.5,3-3.4,2.5-5.1c-0.6-1.7-2.4-2.7-4.6-3c-2.2-0.3-4.8,0-7.4,1.2
				c-2.7,1.2-4.6,2.1-6.6,2.8c-2,0.7-4.2,1.2-7.3,1.3c3,1,5.1,1.7,7.3,2.5C246.6,31.4,248.8,32.2,252.1,32.8z"
                />
              </g>
              <g>
                <path
                  d="M267.3,45.6c1.8,2,3.7,3.3,5.6,4.1c1.9,0.8,4,1.1,5.5,0.2c1.5-0.9,1.9-3.2,0.7-5.8c-1.2-2.6-4-5.2-7.6-6.1
				c-3.7-0.9-6.2-0.8-8.7-0.6c-2.4,0.2-4.8,0.6-8,1.4c3.3,0.3,5.4,1.2,7.2,2.3C263.8,42.2,265.4,43.6,267.3,45.6z"
                />
              </g>
              <g>
                <path
                  d="M270,60.5c0.6,2.6,1.5,4.6,2.7,6.3c1.2,1.6,2.7,3,4.5,3.1c0.9,0,1.7-0.4,2.4-1.2c0.3-0.4,0.7-0.9,0.9-1.5
				c0.2-0.6,0.4-1.3,0.5-2c0.4-2.9-0.8-6.8-3.6-9.5c-2.9-2.7-5.2-3.8-7.5-4.7c-2.3-0.9-4.7-1.6-7.9-2.2c3,1.6,4.4,3.3,5.6,5.1
				C268.6,55.8,269.4,57.8,270,60.5z"
                />
              </g>
              <g>
                <path
                  d="M263.1,73.6c-0.7,2.7-0.6,5.1,0.1,7.2c0.7,2,1.9,3.7,3.7,4c1.7,0.3,3.5-0.9,4.7-3.2c1.1-2.3,1.5-5.6,0.3-8.6
				c-1.2-3.2-2.4-5.1-3.5-7.1c-1.2-1.9-2.3-3.8-3.9-6.5c0.6,3.1,0.5,5.2,0.2,7.3C264.3,68.8,263.9,70.8,263.1,73.6z"
                />
              </g>
              <g>
                <path
                  d="M251.6,86.6c-1.8,2-2.8,4.1-3.2,6.2c-0.4,2.1-0.1,4.1,1.2,5.3c1.3,1.2,3.5,1,5.7-0.5c2.1-1.5,4-4.4,4.4-7.7
				c0.4-3.4,0.1-5.8-0.2-8c-0.3-2.3-0.7-4.5-1.2-7.5c-0.5,3.1-1.4,5-2.5,6.8C254.7,82.9,253.5,84.5,251.6,86.6z"
                />
              </g>
              <g>
                <path
                  d="M241.2,20.8c6-2.2,9.5-7.9,7.4-10.8c-2.1-2.9-8.6-1.4-12.5,3.7c-2,2.6-3.3,4.5-4.6,6.4c-0.7,0.9-1.3,1.9-2.1,2.7
				c-0.4,0.4-0.9,0.8-1.5,1.1c-0.6,0.3-1.4,0.4-2.1,0.2c0.7,0.5,1.5,0.7,2.3,0.6c0.8,0,1.5-0.2,2.2-0.4c0.7-0.2,1.3-0.4,1.9-0.6
				c0.6-0.2,1.1-0.4,1.7-0.6C235.8,22.5,238.1,21.9,241.2,20.8z"
                />
              </g>
              <g>
                <path
                  d="M227.4,15.6c2.1-2.5,2.5-5.9,1.9-8.2c-0.7-2.4-2.4-3.8-4.1-3.6c-1.8,0.1-3.2,1.4-4.2,2.9c-1,1.5-1.6,3.3-1.6,5.5
				c0,2.4-0.2,4.2-0.7,5.9c-0.5,1.8-1.3,3.6-3.2,5.6c2.6-1.2,4.4-2.1,6.2-3.2C223.5,19.4,225.3,18.1,227.4,15.6z"
                />
              </g>
              <g>
                <path
                  d="M216.5,14.7c1.1-3.1,0.5-6.4-0.9-8.5c-1.4-2.1-3.4-2.9-5-2.3c-1.7,0.6-2.6,2.3-3.1,4.1c-0.5,1.8-0.5,3.9,0.3,6.1
				c0.8,2.4,1.2,4.2,1.4,6.1c0.2,1.9,0.1,3.9-1,6.6c1.9-2.1,3.3-3.8,4.6-5.5C214.1,19.6,215.4,17.8,216.5,14.7z"
                />
              </g>
              <g>
                <path
                  d="M204,15.5c-0.4-2.8-2.4-5-4.4-5.8c-2.1-0.8-4-0.4-5.1,1c-1.1,1.4-1.2,3.2-0.9,4.8c0.3,1.6,1.1,2.9,2.7,4.1
				c1.7,1.2,2.9,2.3,3.9,3.5c1,1.2,1.9,2.7,2.4,5.1c0.6-2.4,0.9-4.1,1.3-5.9C204.2,20.4,204.4,18.4,204,15.5z"
                />
              </g>
              <g>
                <path
                  d="M189.6,18.1c-1.2-0.9-2.6-1.3-4-1.3c-1.4,0-2.6,0.3-3.6,1c-1,0.6-1.6,1.4-1.9,2.3c-0.3,0.9-0.2,1.7,0.1,2.5
				c0.7,1.6,2.1,2.7,3.1,3.4c1.1,0.7,2,0.9,3.4,0.4c1.5-0.5,2.9-0.5,4.3-0.3c1.4,0.3,2.8,1,4.1,2.7c-0.3-2.2-0.8-3.8-1.5-5.5
				C193,21.7,192,19.9,189.6,18.1z"
                />
              </g>
              <g>
                <path
                  d="M174.9,28.6c-3.1,0-5.9,1.8-7.2,3.8c-1.3,2-1.2,4.2,0,5.4c1.2,1.3,3.1,1.6,4.8,1.4c1.8-0.2,3.4-0.8,5.1-2.3
				c1.7-1.5,3.2-2.6,4.8-3.4c1.6-0.8,3.4-1.4,6.1-1.4c-2.5-1.1-4.3-1.8-6.3-2.4C180.2,29.1,178.1,28.5,174.9,28.6z"
                />
              </g>
              <g>
                <path
                  d="M168.5,41.5c-2.5,0.6-4.3,2.4-4.8,4.3c-0.6,1.9-0.1,3.7,1.3,4.8c1.4,1.1,3.2,1.3,4.7,1c1.5-0.3,2.8-1.1,3.7-2.8
				c1-1.7,1.7-3,2.7-4.3c0.9-1.2,2.1-2.3,4.1-3.3c-2.2,0-3.8-0.1-5.6-0.2C172.8,41,171,40.9,168.5,41.5z"
                />
              </g>
              <g>
                <path
                  d="M158.7,62.1c-2.2,1.8-3.3,4.4-3.4,6.6c-0.1,2.2,0.9,4,2.5,4.7c1.6,0.7,3.5,0.1,5.1-1.1c1.6-1.2,2.8-3.1,3.3-5.6
				c0.5-2.6,0.8-4.4,1.3-6.3c0.5-1.8,1.1-3.6,2.6-6c-2.2,1.6-3.9,2.7-5.6,3.7C162.7,59.2,161,60.2,158.7,62.1z"
                />
              </g>
              <g>
                <path d="M155.7,90.1c-2.8,3.4-1.4,8,2,9c3.4,1,7.1-2.2,6.5-6.5c-0.6-4.5-1.6-6.4-1.4-10.8C160.4,85.5,158.6,86.6,155.7,90.1z" />
              </g>
              <g>
                <path
                  d="M157,113.8c-1.1,1.8-0.9,3.7-0.1,5.2c0.8,1.4,2.4,2.4,4.1,2.4c1.8,0,3.3-0.9,4.2-2.1c0.9-1.3,1.2-2.9,0.5-4.7
				c-1.5-3.7-2.8-5.3-3.2-9.2C160.8,108.9,159.2,110.1,157,113.8z"
                />
              </g>
              <g>
                <path
                  d="M191.5,102.9c2.1-4.3,3.5-8.8,3.9-12.4c0.5-3.6,0.1-6.5-1.5-7.2c-1.6-0.7-4.1,0.9-6.3,4.1c-2.2,3.2-4.1,8-4.6,13.1
				c-0.5,5.3-0.4,8.9-0.3,12.5c0.1,3.6,0.2,7.2,0.2,12.4c1-5.1,2.2-8.5,3.5-11.8C187.9,110.5,189.4,107.4,191.5,102.9z"
                />
              </g>
              <g>
                <g>
                  <path
                    d="M6.8,333.4c-3-1.7-5.7-4-7.9-6.6c-2.1-2.6-3.6-5.5-4.4-8.4c-0.8-2.9-0.8-6.1-0.2-8.9c0.3-1.4,0.8-2.7,1.4-3.9
					c0.6-1.2,1.4-2.2,2.3-3.2c3.6-3.9,9.7-6,15.7-6.1c1.5-0.1,3,0,4.5,0.1c1.5,0.1,2.9,0.4,4.2,0.7c2.7,0.6,4.8,1.6,6.3,2.8
					c0.8,0.6,1.4,1.3,1.8,1.9c0.5,0.7,0.8,1.5,1,2.3c0.4,1.7,0.4,3.6-0.2,5.2c-0.2,0.8-0.6,1.6-1,2.2c-0.2,0.4-0.4,0.6-0.7,0.9
					c-0.3,0.3-0.5,0.6-0.8,0.8c-1.1,1-2.5,1.7-3.9,2.2c-2.8,0.9-5.4,0.7-7.1,0.5c-1.8-0.3-3-0.5-3.4,0c-0.4,0.5-0.1,1.8,1.7,3.4
					c0.9,0.8,2.1,1.6,3.8,2.3c1.6,0.6,3.7,1.1,6,1.1c2.3,0,5.1-0.5,7.7-2c0.7-0.3,1.3-0.8,2-1.3c0.6-0.5,1.3-1,1.9-1.6
					c1.2-1.2,2.2-2.6,3.1-4.2c1.7-3.1,2.6-6.9,2.2-11c-0.2-2-0.7-4.2-1.7-6.3c-1-2.1-2.3-4-3.9-5.6c-3.2-3.2-7.2-5.3-11.2-6.6
					c-2-0.7-4.1-1.2-6.1-1.5c-2.1-0.4-4.2-0.5-6.4-0.6c-2.2-0.1-4.4,0.1-6.7,0.4c-2.3,0.3-4.6,0.9-6.8,1.6c-2.3,0.8-4.6,1.8-6.8,3.1
					c-2.2,1.4-4.3,3-6.1,4.9c-1.8,2-3.5,4.2-4.7,6.7c-1.2,2.4-2.1,5-2.7,7.6c-1.1,5.3-0.8,10.7,0.7,15.9c1.5,5.2,4.4,9.9,7.9,13.7
					c3.5,3.8,7.8,6.8,12.3,9c4.7,2.2,9.5,3.4,14.1,4c4.6,0.6,9,0.5,13.1,0c8.3-1,15.6-3.4,22.1-6.6c6.4-3.2,12-7.1,17-11.4
					c4.9-4.3,9.2-8.9,13.1-13.9c3.8-5,7.2-10.3,10.1-16c2.9-5.7,5.5-11.8,7.7-18.4c4.5-13.2,7.6-28.3,9.9-46.4
					c-3.1,18-7.1,32.8-12.3,45.4c-2.6,6.3-5.6,12.1-8.8,17.3c-3.3,5.3-6.9,10-10.9,14.4c-4,4.3-8.4,8.3-13.3,11.8
					c-4.9,3.5-10.2,6.5-16.1,8.7c-5.9,2.2-12.4,3.7-19.2,3.9c-3.4,0.1-6.9-0.1-10.4-0.9C13.3,336.4,9.9,335.2,6.8,333.4z"
                  />
                </g>
                <g>
                  <path
                    d="M46.6,281.2c-1.6-0.8-2.8-1.7-4-2.7c-1.1-1-2-2.1-2.5-3.1c-0.3-0.5-0.4-1-0.5-1.4c-0.1-0.4-0.1-0.8-0.1-1.2
					c0-0.8,0.3-1.7,0.8-2.7c0.3-0.5,0.6-1,0.9-1.5c0.4-0.5,0.7-0.9,1.1-1.2c0.8-0.8,1.8-1.4,2.6-1.7c0.4-0.2,0.8-0.3,1.2-0.4
					c0.4-0.1,0.7-0.1,1-0.1c0.3,0,0.6,0,0.9,0.1c0.3,0.1,0.6,0.2,1,0.4c0.7,0.3,1.3,0.9,1.7,1.5c0.5,0.6,0.8,1.2,1,1.8
					c0.4,1.3,0.2,2.9-0.2,4.3c-0.5,1.4-1.1,2.5-1.7,3.4c-0.5,0.9-0.9,1.6-0.7,2.3c0.2,0.6,0.9,1.1,2.3,1.2c1.4,0,3.5-0.5,5.6-2.2
					c1.1-0.8,2.1-1.9,3-3.4c0.9-1.4,1.6-3.3,1.9-5.4c0.6-4.3-0.9-9.3-4.7-13c-1-0.9-2.1-1.8-3.3-2.5c-1.3-0.7-2.7-1.3-4.2-1.6
					c-1.5-0.3-3-0.5-4.5-0.5c-1.5,0.1-3,0.3-4.4,0.7c-2.9,0.8-5.5,2.2-7.8,4c-1.2,0.9-2.3,2-3.2,3.2c-1,1.2-1.8,2.4-2.6,3.8
					c-1.5,2.7-2.5,6-2.5,9.6c0,3.6,1.2,7.2,2.9,10c1.7,2.9,4,5.1,6.4,6.8c1.2,0.9,2.5,1.6,3.8,2.3c1.3,0.7,2.7,1.2,4,1.6
					c5.5,1.8,10.7,2,15.4,1.5c2.3-0.3,4.5-0.7,6.6-1.3c2.1-0.6,4-1.3,5.8-2.2c7.3-3.4,12.6-8.1,17-13.2c4.4-5.2,7.9-10.9,11-17.6
					c3.2-6.7,5.9-14.3,9.1-23.4c-4,8.8-7.5,16.1-11.4,22.1c-3.9,6.1-8.1,10.9-12.8,14.9c-4.7,3.9-10,7-16.1,8.6
					c-1.5,0.4-3.1,0.7-4.7,0.9c-1.6,0.2-3.3,0.2-5,0.1C53.2,283.5,49.7,282.8,46.6,281.2z"
                  />
                </g>
                <g>
                  <path
                    d="M-32.4,315.2c-2-2.6-4.9-4.2-7.4-4.5c-2.5-0.4-4.6,0.4-5.3,2c-0.7,1.6-0.1,3.6,1.3,5.3c1.4,1.7,3.5,3.3,6.2,4.2
					c2.8,0.9,4.8,1.6,6.8,2.5c2,0.9,3.9,1.9,6.2,4c-1.5-2.8-2.6-4.7-3.6-6.8C-29.3,320-30.4,317.9-32.4,315.2z"
                  />
                </g>
                <g>
                  <path
                    d="M-34.7,295.5c0-2.7-0.4-4.9-1.3-6.9c-0.9-1.9-2.1-3.5-3.9-3.8c-1.7-0.3-3.6,1.1-4.5,3.8c-0.9,2.7-0.6,6.6,1.4,9.7
					c2.1,3.2,4,4.8,5.9,6.3c1.9,1.5,3.9,2.8,6.8,4.4c-2.2-2.4-3.1-4.5-3.7-6.6C-34.5,300.4-34.8,298.3-34.7,295.5z"
                  />
                </g>
                <g>
                  <path
                    d="M-26.6,282.8c1.3-2.3,2-4.4,2.3-6.5c0.2-2,0.1-4.1-1.2-5.3c-0.6-0.6-1.5-0.9-2.5-0.8c-0.5,0.1-1.1,0.2-1.6,0.5
					c-0.5,0.3-1.2,0.6-1.7,1.1c-2.3,1.9-4.1,5.5-3.9,9.3c0.3,3.9,1.2,6.3,2.3,8.6c1.1,2.2,2.3,4.4,4.3,7c-1.1-3.2-1-5.5-0.6-7.6
					C-28.7,287.1-27.9,285.2-26.6,282.8z"
                  />
                </g>
                <g>
                  <path
                    d="M-12.6,277.9c2.3-1.5,3.9-3.4,4.9-5.3c0.9-1.9,1.1-4,0.1-5.4c-1-1.4-3.2-1.8-5.6-0.9c-2.4,0.9-4.9,3.1-6.2,6.1
					c-1.3,3.1-1.8,5.4-2.3,7.6c-0.4,2.2-0.9,4.4-1.5,7.4c1.7-2.6,3.2-4.2,4.8-5.5C-16.7,280.6-15,279.4-12.6,277.9z"
                  />
                </g>
                <g>
                  <path
                    d="M4.7,276.2c2.7-0.3,4.9-1.1,6.6-2.4c1.7-1.3,2.9-2.9,2.7-4.7c-0.2-1.7-1.9-3.2-4.4-3.5c-2.5-0.4-5.9,0.4-8.5,2.6
					c-2.6,2.3-4,4.2-5.4,6c-1.3,1.9-2.6,3.7-4.3,6.3c2.5-1.9,4.5-2.7,6.5-3.3C-0.1,276.8,1.9,276.5,4.7,276.2z"
                  />
                </g>
                <g>
                  <path
                    d="M-32.6,331.5c-5.9-2.5-12.3-0.7-12.8,2.8c-0.4,3.5,5.4,6.9,11.7,5.9c3.2-0.5,5.5-1.1,7.8-1.5c1.2-0.2,2.2-0.5,3.4-0.5
					c0.6,0,1.2,0,1.8,0.2c0.6,0.2,1.3,0.6,1.7,1.3c-0.2-0.8-0.7-1.5-1.2-2c-0.6-0.5-1.2-0.9-1.8-1.2c-0.6-0.3-1.2-0.6-1.8-0.8
					c-0.6-0.2-1.1-0.5-1.6-0.7C-27.5,333.9-29.6,332.8-32.6,331.5z"
                  />
                </g>
                <g>
                  <path
                    d="M-26.1,344.6c-3.2,0.4-5.9,2.5-7,4.8c-1.2,2.2-0.8,4.4,0.5,5.5c1.4,1.1,3.3,1.1,5.1,0.8c1.8-0.4,3.4-1.3,4.9-3
					c1.6-1.7,3-2.9,4.6-3.9c1.6-1,3.4-1.7,6.2-1.9c-2.7-0.9-4.6-1.4-6.7-1.9C-20.6,344.6-22.8,344.2-26.1,344.6z"
                  />
                </g>
                <g>
                  <path
                    d="M-18.7,352.8c-2.9,1.5-4.7,4.4-5.1,6.8c-0.4,2.5,0.5,4.5,2.1,5.1c1.7,0.7,3.5,0.1,5.1-0.9c1.6-1,3-2.5,4-4.7
					c1-2.2,2-3.9,3.1-5.4c1.2-1.5,2.6-2.9,5.2-4.2c-2.9,0.2-5,0.5-7.1,0.9C-13.6,350.8-15.8,351.3-18.7,352.8z"
                  />
                </g>
                <g>
                  <path
                    d="M-9,360.7c-1.6,2.3-1.7,5.3-0.7,7.3c0.9,2,2.7,3,4.4,2.7c1.7-0.3,3.1-1.5,4-2.9c0.9-1.4,1.2-2.9,0.8-4.8
					c-0.4-2-0.6-3.6-0.5-5.2c0.1-1.6,0.4-3.3,1.7-5.4c-2,1.3-3.5,2.4-5,3.5C-5.8,357-7.4,358.3-9,360.7z"
                  />
                </g>
                <g>
                  <path
                    d="M3.3,368.7c0.3,1.5,1,2.7,2,3.7c1,0.9,2.1,1.5,3.2,1.7c1.1,0.2,2.1,0,2.9-0.4c0.8-0.4,1.3-1.1,1.6-1.9
					c0.6-1.7,0.4-3.4,0.1-4.6c-0.3-1.3-0.8-2.1-2.2-2.7c-1.4-0.7-2.5-1.6-3.3-2.7c-0.8-1.2-1.4-2.6-1.1-4.8c-1.3,1.8-2,3.4-2.6,5.1
					C3.3,363.7,2.7,365.7,3.3,368.7z"
                  />
                </g>
                <g>
                  <path
                    d="M21.2,371c2.3,2.1,5.5,2.7,7.9,2.1c2.4-0.6,3.8-2.2,3.7-3.9c0-1.8-1.2-3.3-2.6-4.4c-1.4-1.1-3.1-1.7-5.3-1.8
					c-2.3-0.1-4.1-0.3-5.8-0.8c-1.7-0.5-3.4-1.3-5.4-3.2c1.1,2.5,1.9,4.3,2.9,6.1C17.7,367,18.8,368.9,21.2,371z"
                  />
                </g>
                <g>
                  <path
                    d="M34.6,366c2.2,1.3,4.7,1.2,6.4,0.2c1.7-1,2.6-2.7,2.3-4.4c-0.3-1.7-1.4-3.1-2.7-4c-1.3-0.8-2.8-1.1-4.7-0.5
					c-1.9,0.6-3.3,1-4.8,1.3c-1.5,0.2-3.1,0.3-5.2-0.4c1.6,1.5,2.7,2.7,3.9,3.9C31.1,363.4,32.4,364.7,34.6,366z"
                  />
                </g>
                <g>
                  <path
                    d="M55.9,357.6c2.9,0.2,5.5-1,7-2.5c1.6-1.6,2.1-3.5,1.4-5.1c-0.7-1.6-2.5-2.5-4.5-2.7c-2-0.2-4.2,0.4-6.2,1.9
					c-2.1,1.5-3.6,2.7-5.2,3.7c-1.6,1-3.3,1.9-6,2.6c2.7,0.3,4.7,0.7,6.6,1.1C51,357,52.9,357.4,55.9,357.6z"
                  />
                </g>
                <g>
                  <path
                    d="M77.2,339.2c4.4-0.5,6.5-4.9,4.7-7.9c-1.8-3-6.6-3.2-9.2,0.4c-2.6,3.7-3.2,5.7-6.3,8.9
					C70.6,339.3,72.7,339.8,77.2,339.2z"
                  />
                </g>
                <g>
                  <path
                    d="M92.4,321c2-0.6,3.2-2.1,3.6-3.7c0.4-1.6-0.1-3.4-1.4-4.6c-1.3-1.2-3-1.6-4.5-1.3c-1.5,0.3-2.9,1.3-3.6,3.1
					c-1.4,3.7-1.5,5.7-3.9,8.9C86.3,321.9,88.3,322.2,92.4,321z"
                  />
                </g>
                <g>
                  <path
                    d="M59.8,305.3c-4.5,1.7-8.5,4.1-11.4,6.4c-2.8,2.3-4.5,4.7-3.8,6.3c0.7,1.6,3.6,2.1,7.4,1.3c3.8-0.8,8.5-3,12.3-6.5
					c3.9-3.5,6.3-6.2,8.8-8.9c2.4-2.7,4.8-5.4,8.3-9.3c-4.2,3.1-7.4,4.7-10.6,6.2C67.6,302.3,64.4,303.6,59.8,305.3z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M363.6,398.2c3.2,1.3,6.2,3.2,8.6,5.5c2.4,2.3,4.3,5,5.4,7.7c1.1,2.8,1.6,5.9,1.3,8.8c-0.1,1.4-0.4,2.8-0.9,4.1
					c-0.4,1.3-1.1,2.4-1.8,3.5c-3,4.3-8.8,7.2-14.8,8.1c-1.5,0.3-3,0.4-4.4,0.4c-1.5,0.1-2.9,0-4.3-0.1c-2.7-0.3-5-1-6.7-2
					c-0.8-0.5-1.5-1.1-2-1.7c-0.5-0.6-1-1.4-1.3-2.2c-0.6-1.7-0.8-3.6-0.5-5.2c0.1-0.8,0.4-1.6,0.7-2.3c0.1-0.4,0.4-0.7,0.5-1
					c0.2-0.3,0.4-0.6,0.7-0.9c1-1.2,2.2-2.1,3.6-2.7c2.6-1.2,5.2-1.4,7-1.4c1.8,0,3,0.1,3.4-0.4c0.4-0.5-0.2-1.8-2.1-3.2
					c-1-0.7-2.3-1.3-4-1.8c-1.7-0.4-3.8-0.6-6.1-0.3c-2.3,0.3-4.9,1.2-7.4,3c-0.6,0.4-1.2,1-1.8,1.5c-0.6,0.6-1.2,1.2-1.6,1.9
					c-1,1.3-1.9,2.9-2.5,4.5c-1.3,3.3-1.6,7.2-0.8,11.2c0.4,2,1.2,4.1,2.4,6c1.3,2,2.8,3.6,4.6,5c3.6,2.8,7.8,4.3,11.9,5.1
					c2.1,0.4,4.2,0.6,6.3,0.7c2.1,0.1,4.3,0,6.4-0.3c2.2-0.2,4.4-0.7,6.6-1.3c2.2-0.6,4.4-1.4,6.6-2.5c2.2-1.1,4.3-2.4,6.3-4
					c2-1.6,3.8-3.5,5.5-5.7c1.6-2.2,2.9-4.6,3.8-7.2c0.9-2.6,1.5-5.2,1.7-7.9c0.4-5.4-0.6-10.7-2.7-15.7c-2.2-5-5.6-9.2-9.6-12.6
					c-4-3.3-8.6-5.8-13.4-7.3c-5-1.6-9.9-2.2-14.5-2.1c-4.6,0-9,0.7-13,1.7c-8.1,2.1-15,5.4-21,9.4c-6,4-11,8.6-15.3,13.5
					c-4.3,4.9-8,10-11.1,15.5c-3.1,5.4-5.8,11.2-8,17.2c-2.2,6.1-3.9,12.4-5.3,19.3c-2.7,13.7-3.8,29-3.8,47.2
					c0.7-18.2,2.8-33.4,6.3-46.6c1.8-6.6,3.9-12.7,6.5-18.3c2.6-5.7,5.5-10.9,8.9-15.7c3.4-4.8,7.3-9.3,11.6-13.4
					c4.4-4.1,9.3-7.7,14.8-10.7c5.5-3,11.8-5.2,18.5-6.4c3.4-0.5,6.9-0.8,10.4-0.5C356.7,396.1,360.3,396.8,363.6,398.2z"
                  />
                </g>
                <g>
                  <path
                    d="M331,455.1c1.7,0.6,3,1.3,4.3,2.2c1.2,0.9,2.2,1.9,2.9,2.8c0.3,0.5,0.5,0.9,0.7,1.3c0.2,0.4,0.2,0.8,0.3,1.2
					c0.1,0.8,0,1.8-0.4,2.8c-0.2,0.5-0.4,1.1-0.7,1.6c-0.3,0.5-0.6,0.9-1,1.4c-0.7,0.9-1.6,1.6-2.4,2.1c-0.4,0.2-0.8,0.4-1.1,0.6
					c-0.4,0.1-0.7,0.2-1,0.3c-0.3,0-0.6,0.1-0.9,0c-0.3,0-0.7-0.1-1-0.2c-0.7-0.3-1.4-0.7-1.9-1.2c-0.5-0.5-1-1.1-1.2-1.7
					c-0.6-1.2-0.6-2.9-0.3-4.3c0.3-1.4,0.8-2.6,1.2-3.6c0.4-1,0.7-1.7,0.4-2.4c-0.2-0.6-1-1-2.5-0.9c-1.4,0.2-3.4,0.9-5.3,2.9
					c-0.9,1-1.9,2.2-2.6,3.7c-0.7,1.6-1.2,3.4-1.2,5.6c0,4.3,2.1,9.1,6.4,12.3c1.1,0.8,2.3,1.5,3.6,2.1c1.3,0.5,2.9,0.9,4.4,1.1
					c1.5,0.1,3,0.1,4.5-0.1c1.5-0.3,2.9-0.7,4.3-1.2c2.7-1.2,5.2-2.9,7.2-5c1-1.1,2-2.3,2.8-3.6c0.8-1.3,1.5-2.6,2-4.1
					c1.1-2.9,1.7-6.3,1.2-9.9c-0.5-3.6-2.1-7-4.2-9.5c-2.1-2.6-4.6-4.5-7.3-5.9c-1.3-0.7-2.7-1.3-4.1-1.8c-1.4-0.5-2.8-0.8-4.1-1.1
					c-5.7-1.1-10.9-0.6-15.4,0.5c-2.3,0.6-4.4,1.3-6.3,2.2c-2,0.9-3.8,1.8-5.5,2.9c-6.8,4.3-11.4,9.7-15.1,15.3
					c-3.7,5.7-6.4,11.9-8.7,18.9c-2.3,7-4,15-6,24.4c2.8-9.2,5.4-16.9,8.5-23.4c3.1-6.5,6.6-11.9,10.7-16.4c4.1-4.5,9-8.3,14.8-10.6
					c1.5-0.6,3-1.1,4.6-1.5c1.6-0.4,3.2-0.6,4.9-0.8C324,453.6,327.6,453.9,331,455.1z"
                  />
                </g>
                <g>
                  <path
                    d="M404.9,411.1c2.3,2.3,5.4,3.5,7.9,3.5c2.5,0,4.5-1,5-2.7c0.5-1.7-0.4-3.6-2-5.1c-1.6-1.6-3.9-2.8-6.7-3.3
					c-2.9-0.6-5-1-7-1.6c-2.1-0.6-4.1-1.4-6.7-3.2c1.9,2.5,3.2,4.4,4.4,6.3C401.2,406.8,402.5,408.7,404.9,411.1z"
                  />
                </g>
                <g>
                  <path
                    d="M409.7,430.4c0.3,2.7,1.1,4.8,2.2,6.6c1.1,1.8,2.6,3.2,4.3,3.3c1.7,0.1,3.4-1.5,4-4.4c0.6-2.8-0.2-6.6-2.6-9.4
					c-2.5-2.9-4.6-4.3-6.7-5.5c-2.1-1.2-4.2-2.3-7.3-3.5c2.5,2.1,3.7,4.1,4.5,6.1C408.9,425.6,409.4,427.6,409.7,430.4z"
                  />
                </g>
                <g>
                  <path
                    d="M403.3,444.1c-1,2.4-1.5,4.6-1.4,6.7c0,2,0.4,4,1.8,5.1c0.7,0.5,1.6,0.7,2.6,0.4c0.5-0.1,1.1-0.3,1.6-0.7
					c0.5-0.3,1.1-0.8,1.6-1.3c2-2.1,3.4-6,2.6-9.8c-0.8-3.8-2.1-6.1-3.4-8.2c-1.4-2.1-2.8-4-5.2-6.4c1.5,3,1.7,5.3,1.5,7.4
					C404.8,439.5,404.3,441.5,403.3,444.1z"
                  />
                </g>
                <g>
                  <path
                    d="M390,450.7c-2.1,1.7-3.5,3.8-4.1,5.9c-0.6,2-0.6,4.1,0.6,5.4c1.2,1.3,3.4,1.3,5.6,0.1c2.2-1.2,4.4-3.7,5.3-6.8
					c0.9-3.3,1.1-5.6,1.3-7.8c0.2-2.3,0.3-4.5,0.6-7.6c-1.3,2.8-2.6,4.5-4.1,6.1C393.8,447.5,392.2,448.9,390,450.7z"
                  />
                </g>
                <g>
                  <path
                    d="M373.1,454.6c-2.6,0.6-4.7,1.8-6.2,3.2c-1.5,1.5-2.5,3.3-2.1,5c0.4,1.7,2.3,2.9,4.9,2.9c2.6,0,5.8-1.2,8.1-3.7
					c2.3-2.6,3.4-4.6,4.5-6.7c1.1-2,2.1-4,3.4-6.8c-2.2,2.2-4.1,3.3-6,4.1C377.8,453.4,375.8,453.9,373.1,454.6z"
                  />
                </g>
                <g>
                  <path
                    d="M402.9,395c6.1,1.8,12.3-0.9,12.3-4.5c0-3.5-6.2-6.1-12.3-4.3c-3.2,0.9-5.3,1.8-7.5,2.5c-1.1,0.4-2.2,0.8-3.3,0.9
					c-0.6,0.1-1.2,0.1-1.8,0c-0.6-0.1-1.3-0.5-1.8-1.1c0.3,0.8,0.9,1.4,1.5,1.9c0.6,0.4,1.3,0.7,2,1c0.6,0.2,1.3,0.4,1.9,0.6
					c0.6,0.2,1.1,0.3,1.7,0.5C397.6,393.2,399.8,394.1,402.9,395z"
                  />
                </g>
                <g>
                  <path
                    d="M394.7,382.8c3.1-0.8,5.5-3.3,6.3-5.6c0.9-2.4,0.2-4.4-1.3-5.3c-1.5-0.9-3.4-0.7-5.1-0.1c-1.7,0.6-3.2,1.7-4.5,3.6
					c-1.4,1.9-2.6,3.3-4,4.4c-1.4,1.1-3.1,2.2-5.9,2.7c2.8,0.5,4.8,0.8,6.9,1C389.3,383.5,391.5,383.6,394.7,382.8z"
                  />
                </g>
                <g>
                  <path
                    d="M386.4,375.6c2.7-1.9,4.1-4.9,4.2-7.4c0.1-2.5-1.1-4.4-2.8-4.8c-1.7-0.4-3.5,0.4-5,1.5c-1.5,1.2-2.6,2.9-3.3,5.2
					c-0.7,2.4-1.5,4.1-2.4,5.8c-1,1.6-2.2,3.2-4.6,4.8c2.8-0.6,4.8-1.2,6.9-1.8C381.5,378.3,383.7,377.5,386.4,375.6z"
                  />
                </g>
                <g>
                  <path
                    d="M375.7,369.1c1.3-2.5,1-5.5-0.2-7.3c-1.2-1.9-3.1-2.6-4.7-2.1c-1.7,0.5-2.9,1.9-3.6,3.3c-0.7,1.5-0.8,3-0.2,4.9
					c0.6,1.9,1,3.5,1.2,5.1c0.1,1.6,0,3.3-1,5.5c1.8-1.6,3.2-2.8,4.5-4.1C373.1,373.1,374.4,371.7,375.7,369.1z"
                  />
                </g>
                <g>
                  <path
                    d="M362.5,362.8c-0.5-1.4-1.4-2.6-2.5-3.4c-1.1-0.8-2.3-1.2-3.4-1.3c-1.1-0.1-2.1,0.2-2.8,0.8c-0.7,0.5-1.2,1.3-1.4,2.1
					c-0.4,1.7,0.1,3.4,0.5,4.6c0.5,1.2,1.1,1.9,2.5,2.4c1.5,0.5,2.7,1.2,3.6,2.3c0.9,1,1.7,2.4,1.8,4.6c1-2,1.6-3.6,2-5.4
					C363.2,367.7,363.5,365.7,362.5,362.8z"
                  />
                </g>
                <g>
                  <path
                    d="M344.5,362.7c-2.6-1.8-5.9-2-8.1-1.1c-2.3,0.9-3.4,2.7-3.2,4.4c0.3,1.8,1.6,3.1,3.1,4c1.5,0.9,3.3,1.3,5.5,1.1
					c2.3-0.2,4.1-0.2,5.8,0c1.8,0.3,3.6,0.8,5.8,2.5c-1.4-2.3-2.5-4-3.7-5.7C348.5,366.3,347.1,364.6,344.5,362.7z"
                  />
                </g>
                <g>
                  <path
                    d="M331.8,369.5c-2.3-1-4.8-0.5-6.4,0.6c-1.6,1.2-2.2,3-1.8,4.7c0.5,1.7,1.8,2.9,3.2,3.6c1.4,0.6,3,0.7,4.7-0.1
					c1.8-0.8,3.2-1.5,4.6-1.9c1.5-0.4,3-0.7,5.2-0.3c-1.8-1.3-3.1-2.3-4.4-3.4C335.6,371.6,334.2,370.5,331.8,369.5z"
                  />
                </g>
                <g>
                  <path
                    d="M311.8,380.6c-2.9,0.2-5.3,1.7-6.6,3.4c-1.4,1.8-1.7,3.8-0.7,5.3c0.9,1.5,2.8,2.1,4.8,2c2-0.1,4.1-0.9,5.9-2.7
					c1.9-1.8,3.2-3.1,4.7-4.3c1.5-1.2,3-2.3,5.6-3.4c-2.8,0-4.7-0.1-6.7-0.3C316.8,380.6,314.7,380.4,311.8,380.6z"
                  />
                </g>
                <g>
                  <path
                    d="M293.1,401.5c-4.3,1.1-5.8,5.7-3.6,8.4c2.2,2.8,7,2.4,9-1.5c2.1-4,2.4-6.1,5.1-9.6C299.6,400.6,297.5,400.4,293.1,401.5
					z"
                  />
                </g>
                <g>
                  <path
                    d="M280.3,421.6c-1.9,0.9-2.9,2.5-3.1,4.2c-0.2,1.7,0.5,3.3,2,4.4c1.4,1,3.2,1.2,4.7,0.7c1.5-0.5,2.7-1.7,3.1-3.6
					c0.9-3.9,0.8-5.9,2.7-9.4C286.3,419.9,284.3,419.9,280.3,421.6z"
                  />
                </g>
                <g>
                  <path
                    d="M314.7,432.9c4.2-2.3,7.9-5.1,10.4-7.8c2.5-2.7,3.8-5.2,2.9-6.7c-0.9-1.5-3.8-1.6-7.5-0.3c-3.7,1.3-8,4.1-11.4,8
					c-3.5,4-5.5,7-7.5,10c-2,3-4,6-7,10.3c3.8-3.6,6.7-5.7,9.7-7.5C307.3,436.9,310.4,435.2,314.7,432.9z"
                  />
                </g>
              </g>
              <g>
                <path
                  d="M344.5,109.3c1.3-4.4,1.9-9.1,1.4-13.7c-0.4-4.6-1.8-9.3-4.3-13.4c-0.3-0.5-0.6-1-1-1.5c-0.4-0.5-0.7-1-1.1-1.5
				c-0.8-0.9-1.5-1.8-2.4-2.7c-1.7-1.7-3.6-3.1-5.6-4.3c-4-2.4-8.3-3.8-12.6-4.4c-4.3-0.6-8.7-0.6-12.9,0.4
				c-4.2,0.9-8.3,2.7-11.6,5.5c-3.3,2.7-5.9,6.2-7.2,10.2c-0.7,1.9-1.1,4.1-1.1,6.1c0,2,0.2,4,0.8,5.9c1.1,3.7,3.3,7,6.3,9.2
				c3,2.2,6.5,3.1,9.6,3c3.1-0.1,6.1-1.1,8.3-2.8c1.1-0.9,2-1.9,2.7-2.9c0.7-1.1,1.2-2.2,1.4-3.2c0.6-2.1,0.5-4.1,0.2-5.7
				c-0.3-1.6-1-2.9-1.6-3.8c-1.4-1.8-2.6-2.1-2.9-1.8c-0.3,0.3,0.2,1.3,0.6,2.9c0.2,0.8,0.3,1.8,0.2,3c-0.1,1.1-0.4,2.4-1,3.7
				c-0.3,0.6-0.7,1.2-1.2,1.7c-0.5,0.5-1.1,0.9-1.7,1.3c-1.3,0.7-3,1.1-4.8,0.9c-1.8-0.2-3.7-0.9-5-2.1c-1.4-1.3-2.5-3.2-2.9-5.3
				c-0.2-1.1-0.3-2.2-0.2-3.4c0.1-1.1,0.4-2.2,0.8-3.3c0.9-2.2,2.6-4.3,4.7-6c2.1-1.6,4.8-2.7,7.7-3.3c2.9-0.6,6.2-0.6,9.4-0.1
				c6.4,1,12.6,4.2,15.9,9.6c3.5,5.4,4.4,12.8,2.7,19.6c-0.4,1.7-1,3.4-1.8,5c-0.7,1.6-1.6,3.1-2.6,4.6c-2,2.9-4.2,5.4-6.7,7.6
				c-5,4.4-10.6,7.5-16.3,9.7c-5.7,2.3-11.4,3.6-17.1,4.6c-5.7,1-11.4,1.5-17.1,1.9c-5.7,0.4-11.5,0.5-17.5,0.9
				c-6,0.4-12.3,1.1-18.6,3c-3.2,0.9-6.4,2.1-9.5,3.8c-3.1,1.6-6.1,3.7-8.8,6.3c-2.7,2.6-5,5.8-6.7,9.4c-1.6,3.6-2.6,7.8-2.2,12.1
				c-0.2-4.3,0.9-8.4,2.6-12c1.8-3.5,4.1-6.6,6.9-9c2.7-2.4,5.7-4.3,8.8-5.8c3.1-1.4,6.2-2.5,9.4-3.2c6.3-1.5,12.4-1.9,18.3-1.9
				c5.9-0.1,11.7,0.2,17.5,0.2c5.8,0,11.6-0.2,17.6-0.8c6-0.7,12.2-1.7,18.5-3.8c6.3-2,12.9-5,19-9.8c3.1-2.4,6-5.2,8.6-8.6
				C340.9,117.8,343.1,113.9,344.5,109.3z"
                />
              </g>
              <g>
                <path
                  d="M278.5,96c-3.9-1.2-8.4-1.3-12.5,0.5c-2,0.9-3.8,2.1-5.3,3.7c-1.5,1.5-2.7,3.3-3.6,5.3c-0.8,2-1.2,4.2-1.1,6.4
				c0.1,2.2,0.7,4.2,1.6,6c1.8,3.5,4.9,6.1,8.4,7c3.5,1,6.9,0.3,9.4-1.4c1.2-0.8,2.2-1.9,2.8-3c0.6-1.1,0.9-2.2,1-3.1
				c0.2-1.9-0.3-3.4-0.9-4.2c-0.6-0.8-1.2-1-1.6-0.9c-0.8,0.3-0.9,2.1-2.2,3.6c-0.3,0.4-0.7,0.8-1.1,1c-0.4,0.3-0.8,0.4-1.3,0.5
				c-1,0.2-2.4,0.1-3.4-0.5c-1.1-0.6-2.2-1.8-2.7-3.2c-0.6-1.5-0.5-2.8,0.1-4.4c0.7-1.6,2.1-3.1,3.8-4c1.7-0.9,3.9-1.2,6.3-0.9
				c4.9,0.7,8.5,4.3,10.6,8.1c2.2,3.9,3,8.1,2.7,12.3c-0.2,2.1-0.6,4.2-1.5,6.2c-0.4,1-1,2-1.6,3c-0.7,0.9-1.4,1.8-2.4,2.7
				c-1.8,1.6-4.1,3-6.9,3.8c-2.7,0.7-6,0.9-9.2-0.3c3.1,1.4,6.5,1.6,9.4,1.1c3-0.5,5.6-1.7,7.8-3.3c1.1-0.8,2.1-1.7,3-2.6
				c0.9-1,1.7-2,2.3-3.1c1.4-2.1,2.2-4.5,2.9-6.9c1.1-4.8,1-10-0.9-15.4c-1-2.7-2.4-5.4-4.7-8C285.7,99.6,282.5,97.2,278.5,96z"
                />
              </g>
              <g>
                <path
                  d="M315.1,122.6c2.7-2.4,4.1-5.8,4.2-8.5c0.1-2.8-0.9-4.8-2.6-5.3c-1.7-0.5-3.6,0.5-5.2,2.2c-1.6,1.7-2.9,3.9-3.7,6.8
				c-0.8,2.9-1.5,5-2.5,7.1c-1,2-2.1,4-4.5,6.4c3-1.5,5.1-2.6,7.3-3.8C310.2,126.3,312.4,125.1,315.1,122.6z"
                />
              </g>
              <g>
                <path
                  d="M366,89.8c2.5-1.7,4.3-3.8,5.4-5.9c1.1-2.1,1.5-4.2,0.4-5.7c-1-1.4-3.3-1.7-5.9-0.7c-2.6,1.1-5.4,3.6-6.8,6.9
				c-1.5,3.5-2,6-2.5,8.4c-0.4,2.5-0.8,4.9-1.4,8.3c1.5-3.1,3.1-4.9,4.7-6.6C361.7,93,363.5,91.6,366,89.8z"
                />
              </g>
              <g>
                <path
                  d="M379.9,95.6c3-0.8,5.4-2.4,6.9-4.3c1.5-1.8,2.2-3.9,1.4-5.5c-0.8-1.6-2.9-2.2-5.4-1.9c-2.5,0.3-5.4,1.7-7.6,4.1
				c-2.3,2.5-3.6,4.4-5,6.2c-1.4,1.8-2.8,3.6-5.1,6c2.9-1.5,5.1-2.3,7.2-2.9C374.6,96.9,376.8,96.4,379.9,95.6z"
                />
              </g>
              <g>
                <path
                  d="M390.9,105.2c3.1,0.2,5.9-0.4,8-1.5c2.1-1.1,3.7-2.6,3.6-4.4c0-1.7-1.7-3.3-4.4-4c-2.6-0.7-6.2-0.4-9.3,1.3
				c-3.2,1.7-5.2,3.3-7.1,4.7c-1.9,1.5-3.8,3-6.6,5c3.3-1.1,5.6-1.4,7.9-1.5C385.4,104.9,387.7,105,390.9,105.2z"
                />
              </g>
              <g>
                <path
                  d="M360.3,116.8c2.7-0.8,4.8-2.3,6.1-4c1.3-1.7,2-3.7,1.2-5.3c-0.7-1.6-2.7-2.4-5.1-2.1c-2.4,0.3-5.2,1.7-7.2,4.2
				c-2,2.5-3.1,4.5-4.2,6.4c-1.1,1.9-2.2,3.8-3.9,6.3c2.5-1.8,4.4-2.7,6.3-3.5C355.5,118.1,357.5,117.6,360.3,116.8z"
                />
              </g>
              <g>
                <path
                  d="M373.4,121.3c2.6,0.1,4.9-0.6,6.6-1.8c1.7-1.2,2.8-2.9,2.6-4.6c-0.2-1.7-1.8-3.1-4.1-3.6c-2.3-0.5-5.2,0-7.7,1.7
				c-2.5,1.8-4.1,3.3-5.6,4.7c-1.5,1.5-3,2.9-5.3,4.7c2.8-0.9,4.8-1.1,6.7-1.2C368.7,121,370.7,121.2,373.4,121.3z"
                />
              </g>
              <g>
                <path
                  d="M293.2,147.9c-6.4,0.5-11.3,5.2-10.1,8.5c1.2,3.3,8,3.6,13.2-0.3c5.3-4,7-6.2,12.8-9.5
				C302.5,148,299.8,147.4,293.2,147.9z"
                />
              </g>
              <g>
                <path
                  d="M300.9,162.8c-1.7,2.4-2,5.5-1.2,7.6c0.7,2.2,2.4,3.5,4.1,3.4c1.8-0.1,3.2-1.3,4.2-2.8c1-1.5,1.5-3.4,1.3-5.6
				c-0.2-2.3-0.3-4.1-0.2-5.9c0.2-1.8,0.5-3.6,1.8-5.9c-2.1,1.6-3.6,2.8-5.1,4.1C304.2,159,302.7,160.4,300.9,162.8z"
                />
              </g>
              <g>
                <path
                  d="M250.7,158.6c5.2-0.2,10.2-0.8,14.1-1.6c3.9-0.8,6.7-2,7-3.7c0.2-1.7-2.4-3.7-6.9-4.6c-4.4-1-10.5-0.7-16.1,1.4
				c-5.7,2.2-9.2,4.4-12.7,6.7c-3.4,2.3-6.7,4.7-11.2,8.5c5.1-2.9,9-4.2,12.8-5.1C241.5,159.3,245.3,158.8,250.7,158.6z"
                />
              </g>
              <g>
                <path
                  d="M452.7,61.6c-0.5-3.2-1.3-6.4-2.5-9.5c-1.2-3.1-2.9-6.2-5.1-8.9c-2.2-2.7-5-5-8.1-6.7c-3.1-1.7-6.7-2.7-10.3-2.8l-1.4,0
				l-1.4,0.1c-0.9,0.1-1.8,0.3-2.7,0.5c-1.8,0.5-3.4,1.1-4.9,2c-3,1.7-5.6,4.2-7.3,7.3c-1.7,3-2.4,6.8-1.9,10
				c0.2,1.6,0.7,3.2,1.3,4.6c0.6,1.4,1.4,2.7,2.2,3.8c3.4,4.5,8.5,7,13.1,7.2c2.3,0.1,4.6-0.3,6.5-1.3c1-0.5,1.8-1.1,2.5-1.8
				c0.7-0.7,1.2-1.5,1.7-2.3c1.5-3.2,1-6,0-7.5c-0.5-0.8-1-1.2-1.4-1.4c-0.4-0.2-0.7-0.2-0.9,0c-0.3,0.3-0.1,1.1-0.1,2.2
				c0,0.6-0.1,1.2-0.4,1.9c-0.3,0.7-0.7,1.5-1.3,2.1c-0.3,0.3-0.7,0.6-1,0.8c-0.3,0.2-0.8,0.4-1.3,0.5c-1,0.2-2.2,0.2-3.5-0.1
				c-2.6-0.6-5.4-2.4-7.1-5.2c-0.4-0.7-0.8-1.4-1-2.2c-0.2-0.8-0.4-1.5-0.4-2.3c-0.1-0.8,0-1.5,0.2-2.2c0.2-0.7,0.5-1.4,0.9-2.1
				c0.8-1.4,2.2-2.6,3.9-3.5c0.8-0.4,1.7-0.8,2.6-1c0.4-0.1,0.9-0.2,1.4-0.2l0.7,0l0.7,0c1.9,0.1,4,0.6,5.9,1.7
				c1.9,1,3.8,2.5,5.3,4.2c3.1,3.5,5.2,8.4,6.4,13.7c1.2,5.5,1.6,10.9,1.4,15.9c-0.2,5-0.9,9.7-1.9,14.1c-2.1,8.8-5.4,16.4-9.6,23.5
				c-4.2,7.1-9.5,13.8-16.3,20.4c-6.8,6.6-15.2,13.2-26.1,19.6c11.2-6,20-12.1,27.3-18.3c7.3-6.2,13.3-12.6,18.3-19.6
				c5-7,9.2-14.7,12.3-23.9c1.5-4.6,2.7-9.6,3.4-15.1C453.5,74.1,453.6,68.1,452.7,61.6z"
                />
              </g>
              <g>
                <path
                  d="M420.9,70.1c-1.7-0.9-3.7-1.5-5.7-1.6c-2.1-0.2-4.2,0.2-6.2,0.9c-1.9,0.7-3.7,1.8-5.2,3.1c-1.5,1.4-2.7,3-3.5,5
				c-0.8,1.9-1.2,4.1-1.1,6c0.1,2,0.6,3.8,1.4,5.5c1.6,3.3,4.3,5.8,7.4,6.9c1.6,0.6,3.2,0.8,4.6,0.6c1.4-0.1,2.7-0.5,3.8-0.9
				c2.2-1,3.7-2.4,4.6-3.8c0.9-1.4,1.2-2.7,1.1-3.6c-0.1-0.9-0.4-1.4-0.8-1.5c-0.9-0.2-1.9,0.9-3.8,1.6c-0.9,0.4-2,0.7-3.2,0.8
				c-1.2,0-2.4-0.2-3.2-0.9c-0.9-0.6-1.7-1.7-2.1-3c-0.2-0.6-0.3-1.3-0.3-2c0-0.6,0.2-1.2,0.4-1.8c0.5-1.2,1.7-2.4,3.2-3
				c0.7-0.3,1.5-0.5,2.2-0.6c0.8,0,1.7,0.1,2.6,0.3c1.9,0.6,3.8,1.9,5.4,3.4c0.8,0.8,1.5,1.6,2.2,2.4c0.7,0.8,1.3,1.7,1.9,2.6
				c2.3,3.5,3.9,7.1,5,10.9c1.1,3.8,1.7,7.8,1.5,12.4c0,0.6-0.1,1.1-0.1,1.7c0,0.6-0.1,1.2-0.2,1.8c-0.1,1.2-0.4,2.4-0.6,3.7
				c-0.6,2.5-1.4,5.2-2.7,8c1.5-2.7,2.7-5.3,3.5-7.8c0.4-1.3,0.8-2.5,1.1-3.7c0.1-0.6,0.3-1.2,0.4-1.8c0.1-0.6,0.2-1.2,0.3-1.8
				c0.8-4.7,0.9-9,0.5-13.3c-0.5-4.3-1.4-8.6-3.3-13.1c-0.5-1.1-1-2.3-1.7-3.4c-0.6-1.2-1.4-2.4-2.2-3.5
				C426.7,74.4,424.4,72,420.9,70.1z"
                />
              </g>
              <g>
                <g>
                  <path
                    d="M503.8,68.5c5.7-0.7,11.4-1.1,16.9-1.7c5.5-0.5,10.7-1.1,15.1-2c4.5-0.8,8.2-1.9,10.7-2.9c2.5-1.1,3.9-2.2,3.7-3
					c-0.2-0.9-1.8-1.4-4.4-1.8c-2.6-0.4-6.3-0.5-10.8-0.6c-4.4-0.1-9.6,0-15.3,0.4c-5.6,0.4-11.8,1.3-17.7,2.9
					c-6.2,1.7-11.2,3.7-15.6,6c-4.3,2.3-8,4.8-11.3,7.8c-0.8,0.7-1.6,1.5-2.4,2.3c-0.8,0.8-1.5,1.6-2.3,2.5
					c-1.5,1.7-2.9,3.6-4.2,5.6c-2.6,4.1-4.9,9.2-5.6,15.5c1.2-6.2,4.1-10.9,7-14.5c1.5-1.8,3.1-3.4,4.7-4.7c0.8-0.7,1.7-1.3,2.5-2
					c0.9-0.6,1.7-1.2,2.6-1.7c3.5-2.2,7.2-3.8,11.4-5.1C493.2,70.2,498,69.2,503.8,68.5z"
                  />
                </g>
                <g>
                  <path
                    d="M512,85.7c5.6-1.4,11.1-3.6,15.9-6.1c4.8-2.5,9.1-5.5,12.4-8.3c6.7-5.7,9.8-10.9,8.6-12.1c-1.2-1.3-6.2,1.4-13.3,5.2
					c-7.1,3.9-16.5,8.8-26.3,13c-5.1,2.2-9.5,3.7-13.6,4.9c-4.1,1.2-8,2.1-11.9,3.3c-3.9,1.1-7.8,2.4-11.9,4.4c-2,1-4.2,2.2-6.2,3.8
					c-2.1,1.5-4.1,3.5-5.8,5.9c1.9-2.2,4.1-3.9,6.3-5.2c2.2-1.3,4.3-2.2,6.4-2.9c4.2-1.4,8.1-1.9,12-2.3c3.9-0.4,7.8-0.5,12.3-1
					C501.2,87.9,506.2,87.1,512,85.7z"
                  />
                </g>
                <g>
                  <path
                    d="M519.6,76.1c6.3-1,12.2-3.4,16.2-6c4.1-2.6,6.3-5.3,5.6-6.9c-0.7-1.6-4.1-1.8-8.7-0.9c-4.5,0.9-10.2,2.7-15.7,5.4
					c-5.7,2.7-9.6,4.6-13.6,6.3c-4.1,1.7-8.3,3.3-14.6,4.9c6.6-0.6,11-0.9,15.5-1.2C508.7,77.4,513.2,77.1,519.6,76.1z"
                  />
                </g>
                <g>
                  <path
                    d="M517.7,72.2c4.9-0.5,9.4-2.3,12.5-4.4c3.1-2.1,4.8-4.4,4.1-6c-0.7-1.6-3.4-2.2-7-1.8c-3.6,0.4-8,1.7-12.2,3.8
					c-4.3,2.3-7.3,3.9-10.4,5.4c-3.1,1.5-6.2,2.9-11.1,4.4c5-0.4,8.5-0.6,12-0.7C509.2,72.8,512.7,72.7,517.7,72.2z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M490.4,213.7c-5.6-1.1-11.2-2.4-16.6-3.5c-5.4-1.2-10.5-2.2-15-2.7c-4.5-0.6-8.4-0.7-11.1-0.5c-2.7,0.3-4.3,0.9-4.5,1.8
					c-0.1,0.9,1.2,1.9,3.6,3c2.4,1.1,5.9,2.4,10.1,3.8c4.2,1.4,9.2,2.9,14.7,4.2c5.5,1.3,11.6,2.3,17.8,2.6c6.4,0.2,11.8-0.1,16.7-1
					c4.8-0.9,9.1-2.1,13.1-4c1-0.4,2-1,3-1.4c1-0.5,2-1.1,2.9-1.7c1.9-1.2,3.8-2.5,5.7-4.1c3.7-3.1,7.4-7.3,10-13.1
					c-3.1,5.6-7.2,9.1-11.1,11.7c-2,1.3-3.9,2.3-5.9,3.1c-1,0.4-2,0.8-2.9,1.1c-1,0.3-2,0.6-3,0.8c-3.9,1-8,1.4-12.4,1.4
					C501.1,215.3,496.2,214.8,490.4,213.7z"
                  />
                </g>
                <g>
                  <path
                    d="M487.9,194.8c-5.8-0.4-11.6,0.1-17,1c-5.4,1-10.3,2.5-14.3,4.2c-8.1,3.4-12.6,7.4-11.8,8.9c0.8,1.6,6.3,0.6,14.3-0.9
					c8-1.5,18.4-3.4,29-4.4c5.5-0.6,10.2-0.7,14.5-0.6c4.3,0.1,8.3,0.4,12.3,0.5c4,0.1,8.2,0.1,12.7-0.6c2.3-0.4,4.6-0.9,7.1-1.7
					c2.4-0.9,4.9-2.1,7.3-3.9c-2.5,1.6-5.1,2.5-7.5,3.1c-2.4,0.6-4.8,0.8-7,0.8c-4.4,0-8.3-0.6-12.1-1.4c-3.8-0.8-7.6-1.9-12-2.8
					C498.8,196,493.9,195.2,487.9,194.8z"
                  />
                </g>
                <g>
                  <path
                    d="M477.7,201.6c-6.3-0.9-12.6-0.4-17.3,0.8c-4.7,1.2-7.6,3.1-7.4,4.9c0.2,1.8,3.4,2.9,8,3.5c4.6,0.5,10.6,0.5,16.6-0.4
					c6.2-0.9,10.5-1.5,14.9-1.9c4.4-0.4,8.9-0.6,15.4-0.3c-6.4-1.4-10.8-2.5-15.2-3.5C488.5,203.7,484.2,202.6,477.7,201.6z"
                  />
                </g>
                <g>
                  <path
                    d="M478.3,205.9c-4.8-1-9.7-0.7-13.3,0.4c-3.6,1-5.9,2.8-5.8,4.5c0.1,1.8,2.6,3.1,6.1,3.9c3.5,0.7,8.1,0.8,12.8,0
					c4.8-0.8,8.1-1.5,11.5-2c3.4-0.5,6.8-0.9,11.9-0.8c-4.9-1.1-8.3-2-11.6-3C486.6,207.9,483.3,206.9,478.3,205.9z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M295.7,319.1c4.1-3.9,8.4-7.8,12.5-11.5c4.1-3.7,7.9-7.3,10.9-10.7c3.1-3.3,5.4-6.4,6.8-8.8c1.4-2.4,1.8-4.1,1.2-4.7
					c-0.6-0.6-2.3-0.1-4.6,1.2c-2.3,1.3-5.4,3.4-9,6c-3.6,2.6-7.7,5.8-12,9.5c-4.2,3.7-8.6,8.1-12.4,13c-3.9,5.1-6.7,9.7-8.9,14.2
					c-2.1,4.4-3.5,8.7-4.4,13c-0.2,1.1-0.4,2.2-0.6,3.3c-0.1,1.1-0.3,2.2-0.3,3.3c-0.2,2.3-0.2,4.6,0,7c0.4,4.8,1.6,10.3,4.8,15.8
					c-2.8-5.7-3.3-11.1-3.1-15.8c0.1-2.3,0.4-4.5,0.9-6.6c0.2-1,0.5-2,0.8-3c0.3-1,0.6-2,1-2.9c1.5-3.8,3.5-7.3,6.1-11
					C288.2,326.9,291.4,323.2,295.7,319.1z"
                  />
                </g>
                <g>
                  <path
                    d="M312.5,328c3.7-4.5,6.7-9.5,9-14.5c2.3-4.9,4-9.8,4.9-14.1c1.9-8.6,1.3-14.5-0.4-14.8c-1.8-0.3-4.2,4.8-7.5,12.2
					c-3.4,7.4-7.9,16.9-13.3,26.2c-2.7,4.8-5.4,8.7-7.9,12.1c-2.6,3.4-5.1,6.5-7.5,9.7c-2.4,3.2-4.8,6.6-6.9,10.7
					c-1,2.1-2,4.3-2.7,6.8c-0.7,2.5-1.2,5.2-1.1,8.2c0.2-3,0.9-5.6,1.9-7.9c1-2.3,2.2-4.3,3.4-6.1c2.5-3.6,5.3-6.4,8.2-9
					c2.9-2.7,5.9-5.1,9.3-8.2C305.2,336.2,308.7,332.7,312.5,328z"
                  />
                </g>
                <g>
                  <path
                    d="M312.9,315.8c4.4-4.6,7.7-10,9.4-14.5c1.7-4.5,1.8-8,0.3-8.8c-1.6-0.9-4.4,1.1-7.5,4.5c-3.1,3.4-6.5,8.3-9.3,13.8
					c-2.9,5.6-4.9,9.4-7.1,13.3c-2.2,3.8-4.6,7.6-8.7,12.7c4.9-4.4,8.3-7.4,11.7-10.3C305,323.4,308.3,320.5,312.9,315.8z"
                  />
                </g>
                <g>
                  <path
                    d="M309,313.8c3.6-3.4,6.2-7.5,7.4-11c1.2-3.5,1.2-6.4-0.3-7.3c-1.5-0.9-4,0.3-6.7,2.7c-2.6,2.5-5.4,6.1-7.4,10.4
					c-2.1,4.4-3.5,7.5-5,10.5c-1.6,3-3.3,6.1-6.2,10.2c3.8-3.4,6.5-5.6,9.2-7.8C302.6,319.3,305.3,317.2,309,313.8z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M112.7,104.3c4.4,3.7,8.6,7.6,12.7,11.2c4.1,3.6,8.1,7,11.7,9.8c3.6,2.7,6.9,4.7,9.5,5.9c2.5,1.1,4.2,1.4,4.7,0.7
					c0.5-0.7-0.2-2.2-1.7-4.4c-1.5-2.2-3.9-5-6.9-8.3c-3-3.3-6.6-7.1-10.7-10.9c-4.2-3.8-8.9-7.7-14.2-11c-5.4-3.3-10.4-5.7-15-7.3
					c-4.6-1.6-9-2.6-13.4-3c-1.1-0.1-2.2-0.1-3.3-0.2c-1.1,0-2.2,0-3.4,0c-2.3,0.1-4.6,0.3-7,0.8c-4.8,0.9-10.1,2.7-15.2,6.4
					c5.4-3.3,10.7-4.4,15.4-4.7c2.3-0.1,4.6,0,6.7,0.2c1.1,0.1,2.1,0.3,3.1,0.5c1,0.2,2,0.4,3,0.7c3.9,1.1,7.6,2.7,11.5,4.9
					C104.2,97.6,108.2,100.5,112.7,104.3z"
                  />
                </g>
                <g>
                  <path
                    d="M105.7,121.9c4.9,3.2,10.2,5.7,15.3,7.5c5.2,1.8,10.2,2.9,14.5,3.4c8.7,1,14.6-0.3,14.7-2c0.1-1.8-5.2-3.6-12.9-6.2
					c-7.7-2.6-17.7-6.1-27.5-10.4c-5.1-2.2-9.2-4.4-12.9-6.6c-3.7-2.2-7-4.4-10.5-6.5C83,99,79.4,97,75.1,95.4c-2.1-0.8-4.5-1.5-7-2
					c-2.5-0.5-5.3-0.6-8.3-0.2c3-0.1,5.7,0.3,8.1,1c2.4,0.7,4.5,1.7,6.5,2.7c3.9,2.1,6.9,4.6,9.8,7.2c2.9,2.6,5.7,5.4,9.1,8.4
					C96.7,115.5,100.6,118.7,105.7,121.9z"
                  />
                </g>
                <g>
                  <path
                    d="M117.9,121c5,3.9,10.8,6.6,15.4,7.8c4.7,1.2,8.1,1,8.8-0.6c0.7-1.6-1.5-4.2-5.3-7c-3.7-2.7-9-5.6-14.7-7.8
					c-5.8-2.3-9.9-3.9-13.9-5.7c-4-1.8-8-3.8-13.6-7.4c4.9,4.4,8.2,7.5,11.5,10.5C109.5,113.9,112.7,117,117.9,121z"
                  />
                </g>
                <g>
                  <path
                    d="M119.5,117c3.7,3.2,8.1,5.3,11.7,6.2c3.6,0.9,6.5,0.5,7.2-1.1c0.8-1.6-0.7-4-3.4-6.4c-2.7-2.4-6.7-4.7-11.1-6.3
					c-4.6-1.6-7.8-2.7-11-3.9c-3.2-1.2-6.4-2.6-10.8-5.1c3.8,3.4,6.2,5.8,8.7,8.3C113.2,111.2,115.6,113.7,119.5,117z"
                  />
                </g>
              </g>
              <g>
                <path
                  d="M46.9,120.2c-4.8-3.3-10.4-5.5-16.3-6.1c-2.9-0.3-5.9-0.2-8.8,0.3c-2.9,0.5-5.8,1.4-8.4,2.9c-2.6,1.5-5.1,3.5-6.9,6.1
				c-0.5,0.7-0.9,1.3-1.2,2c-0.3,0.7-0.7,1.5-0.9,2.2L4,128.7l-0.2,1.1c-0.2,0.8-0.2,1.5-0.3,2.3c-0.1,3,0.5,5.8,1.6,8.3
				c1.1,2.5,2.6,4.6,4.5,6.3c1.8,1.7,4,3,6.2,3.6c2.1,0.7,4.2,1,6.1,0.9c1.9-0.1,3.6-0.4,5.2-0.9c1.5-0.5,2.8-1.1,3.9-1.8
				c2.2-1.3,3.6-2.9,4.3-4c0.7-1.2,0.8-2,0.5-2.2c-0.3-0.3-1.1-0.1-2.2,0.3c-1.1,0.4-2.6,1.1-4.6,1.5c-1,0.2-2,0.4-3.2,0.5
				c-1.1,0.1-2.4,0-3.6-0.2c-1.2-0.2-2.5-0.6-3.6-1.2c-1.2-0.6-2.1-1.4-3-2.4c-1.8-2.1-2.9-5.2-2.6-8.1c0.1-0.3,0.1-0.7,0.2-1
				l0.1-0.5l0.2-0.5c0.1-0.3,0.3-0.6,0.4-0.9c0.2-0.3,0.4-0.6,0.6-0.9c0.9-1.2,2.2-2.2,3.7-3.1c1.6-0.9,3.4-1.5,5.4-1.8
				c2-0.4,4.1-0.5,6.2-0.3c4.2,0.3,8.6,1.6,12.4,3.9c4,2.3,7.2,5.4,9.7,8.7c2.5,3.3,4.3,6.9,5.7,10.4c2.6,7.1,3.3,14.2,2.9,21.3
				c-0.4,7.1-1.8,14.3-4.7,22c-3,7.6-7.4,15.8-14.9,23.8c7.8-7.7,12.8-15.6,16.5-23.2c3.6-7.6,5.7-14.8,6.9-22.3
				c0.6-3.7,0.9-7.5,0.8-11.4c-0.1-3.9-0.5-8-1.6-12.1c-1.1-4.2-2.8-8.5-5.4-12.7C55.4,127.8,51.9,123.6,46.9,120.2z"
                />
              </g>
              <g>
                <path
                  d="M34,153.7c-3,0.6-5.5,2.1-7.5,4c-2,1.9-3.5,4.3-4.3,7c-0.4,1.3-0.6,2.7-0.6,4.1c0,1.4,0.2,2.8,0.7,4.1
				c0.8,2.5,2.4,4.7,4.4,6.1c1,0.7,2,1.2,3.1,1.5c1.1,0.3,2.1,0.4,3,0.4c1.9-0.1,3.4-0.7,4.4-1.5c1-0.8,1.6-1.6,1.8-2.3
				c0.2-0.7,0-1.2-0.3-1.5c-0.7-0.6-1.9-0.4-3.2-0.7c-0.7-0.1-1.4-0.4-2-0.7c-0.3-0.2-0.6-0.4-0.8-0.6c-0.2-0.2-0.4-0.5-0.6-0.8
				c-0.4-0.6-0.7-1.4-0.8-2.3c-0.1-0.4-0.1-0.9,0-1.3c0-0.5,0.2-1,0.3-1.5c0.6-2.1,2.6-4.5,4.7-5.5c1.1-0.5,2.1-0.7,3.3-0.5
				c1.2,0.2,2.3,0.8,3.3,1.6c1,0.8,1.8,1.7,2.5,2.8c0.7,1.1,1.2,2.3,1.6,3.6c0.8,2.6,1,5.5,0.9,8.8c-0.1,3.3-0.7,7-0.7,11.4
				c0.6-4.4,1.7-7.9,2.4-11.2c0.8-3.3,1.3-6.4,1.2-9.6c-0.1-1.6-0.3-3.3-0.8-4.9c-0.5-1.7-1.3-3.4-2.5-5c-1.2-1.6-2.8-3.2-5.1-4.3
				c-1.1-0.6-2.4-1-3.8-1.2C37.1,153.3,35.5,153.4,34,153.7z"
                />
              </g>
              <g>
                <path
                  d="M80.9,129c2-0.8,3.3-2.1,4-3.7c0.7-1.6,0.9-3.4,0.1-5c-0.8-1.5-2.7-2.3-4.8-1.9c-2.1,0.4-4.4,2-5.6,4.5
				c-1.2,2.6-1.6,4.5-1.9,6.4c-0.3,1.9-0.7,3.6-1.1,6.1c1.5-2,2.9-3.1,4.3-4C77.3,130.6,78.8,129.9,80.9,129z"
                />
              </g>
              <g>
                <path
                  d="M87.6,140.1c1.9,0,3.2-0.8,4.1-2.1c0.9-1.2,1.4-2.9,1-4.7c-0.4-1.7-1.8-3-3.7-3.3c-1.9-0.3-4.2,0.4-5.7,2.3
				c-1.5,2-2.3,3.5-3,5c-0.7,1.5-1.3,2.9-2.3,4.8c1.8-1.2,3.2-1.6,4.7-1.9C84.1,140.2,85.6,140.1,87.6,140.1z"
                />
              </g>
              <g>
                <path
                  d="M69.7,113.9c0.7-3.1,0.1-6.2-1.2-8.3c-1.3-2.1-3.2-3.1-4.9-2.7c-1.7,0.5-2.8,2.2-3.3,4.3c-0.5,2.1-0.3,4.6,0.7,7.2
				c1.1,2.6,1.8,4.6,2.5,6.6c0.6,2,1,4.1,1,7.1c1.1-2.9,1.9-4.9,2.8-6.9C68.1,119.2,69,117.1,69.7,113.9z"
                />
              </g>
              <g>
                <path d="M51.6,99.8c-0.9-5.8-6-9.3-9.1-7.5c-3,1.8-2.7,7.7,1.3,11.5c4.1,4,6.3,5.3,9.7,10.1C52.1,108.1,52.6,105.7,51.6,99.8z" />
              </g>
              <g>
                <path
                  d="M25.9,96.2c-2.9-2.2-6.5-3.1-9.2-2.9c-2.8,0.2-4.8,1.4-5.1,3.1c-0.3,1.7,0.9,3.6,3,5c2,1.5,4.9,2.6,8.1,2.9
				c3.3,0.4,5.7,0.6,8.1,1.1c2.4,0.5,4.7,1.1,7.9,2.7c-2.6-2.5-4.3-4.2-6.1-6C30.7,100.2,28.9,98.4,25.9,96.2z"
                />
              </g>
              <g>
                <path
                  d="M-1.6,103.7c-2.7-0.3-5.2,0.9-6.5,2.5c-1.4,1.7-1.5,3.6-0.6,5.1c1,1.5,2.6,2.2,4.2,2.4c1.6,0.2,3.2-0.2,4.8-1.5
				c1.6-1.3,3-2.3,4.4-3c1.4-0.8,3-1.3,5.4-1.4c-2.1-0.9-3.7-1.6-5.5-2.4C3,104.7,1.2,104-1.6,103.7z"
                />
              </g>
              <g>
                <path
                  d="M-13.1,124.3c-2.5,1.7-3.7,4.6-3.5,6.9c0.2,2.4,1.6,3.9,3.3,4.1c1.8,0.2,3.4-0.6,4.6-1.5c1.3-1,2.1-2.2,2.5-4.1
				c0.4-2,0.9-3.5,1.7-5c0.8-1.4,1.9-2.8,4.1-4c-2.5,0.2-4.3,0.5-6.2,0.9C-8.6,122.1-10.6,122.6-13.1,124.3z"
                />
              </g>
              <g>
                <path
                  d="M-9.6,149.9c-0.9,2.6-0.3,5.4,1.1,7.1c1.4,1.7,3.3,2.2,4.9,1.5c1.6-0.7,2.7-2.2,3.2-3.7c0.5-1.5,0.5-3.1-0.4-4.8
				c-0.9-1.8-1.5-3.3-1.9-4.9c-0.3-1.6-0.5-3.2,0.1-5.5c-1.6,1.8-2.7,3.2-3.8,4.6C-7.5,145.6-8.6,147.2-9.6,149.9z"
                />
              </g>
              <g>
                <path
                  d="M3.1,168.8c0.4,2.9,2.4,5.2,4.4,6.2c2.1,1,4.1,0.7,5.2-0.6c1.2-1.3,1.4-3.2,1.1-4.9c-0.3-1.7-1.2-3.3-2.9-4.7
				c-1.7-1.4-3-2.6-4.1-3.9c-1.1-1.3-2-2.9-2.7-5.4c-0.4,2.6-0.7,4.4-1,6.4C2.9,163.8,2.7,165.8,3.1,168.8z"
                />
              </g>
              <g>
                <path
                  d="M75.4,229.7c2.3,1.4,4,3.2,4.8,5.2c0.9,2,0.9,4.3,0.3,5.8c-0.6,1.5-2,2.8-3.6,3.4c-0.8,0.3-1.5,0.4-2.3,0.4
				c-0.8,0-1.6-0.2-2.3-0.6c-1.5-0.6-2.7-1.8-3.4-2.9c-0.7-1.1-0.9-2.1-0.8-2.9c0.1-0.8,0.6-1.6,1.1-2c0.4-0.5,0.9-0.8,1-1.2
				c0.1-0.4-0.3-1-1.5-1.3c-1.1-0.2-3,0.1-4.7,1.8c-0.4,0.4-0.8,1-1.1,1.6c-0.3,0.6-0.5,1.2-0.7,1.9c-0.3,1.4-0.3,2.8,0.1,4.3
				c0.7,2.9,2.5,5.8,5.5,7.9c1.5,1,3.3,1.8,5.3,2.3c2,0.4,4.2,0.4,6.3-0.2c2.1-0.5,4.1-1.5,5.9-3c1.8-1.4,3.3-3.4,4.2-5.7
				c0.9-2.3,1.3-4.7,1.1-7c-0.1-2.3-0.8-4.6-1.8-6.7c-1-2.1-2.5-4-4.2-5.5c-1.7-1.5-3.6-2.6-5.6-3.4c-4.1-1.7-8.1-2-11.5-1.8
				c-3.5,0.2-6.5,0.9-9.3,1.7c-5.5,1.7-10.2,3.7-14.6,6.1c-4.5,2.5-8.8,5.2-13,9c-2.1,1.9-4.3,4-6.3,6.4c-2.1,2.5-4.1,5.2-5.8,8.5
				c2-3.1,4.2-5.7,6.4-7.9c2.2-2.2,4.5-4.1,6.8-5.6c4.5-3.2,9-5.2,13.5-6.9c4.5-1.7,9.2-2.8,14.4-3.6c2.6-0.3,5.2-0.5,7.9-0.3
				c1.3,0.1,2.7,0.3,4,0.7C72.9,228.5,74.2,229,75.4,229.7z"
                />
              </g>
              <g>
                <path
                  d="M55.5,215c4.5-2.5,8.1-6.3,10.1-9.7c2-3.4,2.4-6.3,1-7.5c-1.4-1.1-4.1-0.2-7.1,1.7c-3.1,2-6.5,5-9.6,8.6
				c-3.2,3.8-5.4,6.3-7.9,8.8c-2.5,2.5-5.2,4.8-9.6,7.6c4.8-1.8,8.2-3.1,11.7-4.4C47.5,218.9,50.9,217.5,55.5,215z"
                />
              </g>
              <g>
                <path
                  d="M75.4,216.2c2.3,0.5,4.3,0.1,5.9-0.8c1.6-1,2.7-2.5,2.7-4.3c0-1.8-1.2-3.3-3.1-4.1c-1.9-0.8-4.5-0.8-6.8,0.5
				c-2.4,1.3-3.8,2.5-5.3,3.6c-1.5,1.1-2.9,2.2-5.1,3.5c2.5-0.3,4.3-0.1,6,0.2C71.3,215.2,73,215.7,75.4,216.2z"
                />
              </g>
              <g>
                <path
                  d="M35.3,208.6c0.9-2.4,1.3-5,1.3-7.4c0-0.6,0-1.2-0.1-1.8c-0.1-0.6-0.1-1.1-0.2-1.7c-0.2-1.2-0.6-2.3-1.1-3.4
				c-0.5-1.1-1.1-2-1.9-2.8c-0.7-0.8-1.6-1.3-2.3-1.6c-1.6-0.5-2.6,0-3,0.7c-0.4,0.8-0.3,1.8-0.5,2.6c-0.1,0.9-0.3,1.8-0.4,3
				c-0.1,0.7-0.1,1.4-0.2,2.1c0,0.4-0.1,0.9-0.1,1.3c0,0.5,0,0.9-0.1,1.4c-0.1,1.9-0.1,3.9,0,6c0.1,4.3-0.2,7.5-0.8,10.6
				c-0.7,3.1-1.8,6.3-4.7,10.3c3.7-3.3,5.9-5.9,8.1-8.7c1.1-1.4,2.1-2.8,3.1-4.5C33.4,213,34.4,211.1,35.3,208.6z"
                />
              </g>
              <g>
                <path
                  d="M124.7,53.8c3.3-0.9,6.7-1.7,9-1.4c1.2,0.1,2,0.4,2.7,0.9c0.6,0.4,1.2,1.1,1.6,2c0.9,1.8,1,4.3,0.5,6.2
				c-0.1,0.5-0.3,1-0.5,1.5c-0.2,0.5-0.5,0.9-0.7,1.4c-0.5,0.8-1.2,1.5-1.8,2.1c-1.2,1-2.9,1.5-4.2,1.4c-0.7,0-1.3-0.2-1.8-0.4
				c-0.5-0.3-1-0.6-1.4-1c-1.7-1.6-1.8-3.6-2.6-3.9c-0.4-0.1-1,0.2-1.5,1.2c-0.5,1-0.8,2.8-0.1,4.9c0.3,1,0.9,2.2,1.9,3.2
				c1,1.1,2.2,2,3.8,2.6c3,1.2,6.9,1.2,10.4-0.7c1.7-0.9,3.3-2.2,4.6-3.7c0.7-0.8,1.2-1.6,1.8-2.5c0.5-0.9,1-1.8,1.4-2.9
				c0.8-2.1,1.1-4.3,1.1-6.5c-0.1-2.3-0.5-4.6-1.5-6.9c-1.1-2.3-2.8-4.5-5.2-6c-2.4-1.5-5.1-2.2-7.6-2.3c-2.5-0.1-4.7,0.3-6.8,0.9
				c-2.1,0.6-3.9,1.2-5.7,2c-3.8,1.6-7.6,3.8-10.3,7c-1.3,1.6-2.3,3.5-2.9,5.3c-0.6,1.8-0.9,3.6-0.9,5.3c-0.1,3.4,0.5,6.4,1.3,9.2
				c0.9,2.8,2,5.4,3.4,7.8c2.8,4.8,6.6,9.1,11.7,12.4c2.6,1.7,5.5,3.1,8.7,4.2c3.3,1.1,6.9,1.7,10.9,1.8c-3.9-0.3-7.5-1.3-10.6-2.6
				c-3.1-1.3-5.7-3-8-4.8c-4.5-3.7-7.5-8.1-9.4-12.7c-1-2.3-1.6-4.7-2-7.2c-0.4-2.5-0.5-5,0-7.5c0.2-1.2,0.6-2.4,1.1-3.4
				c0.5-1,1.2-2,2.1-2.8C118.7,56,121.3,54.8,124.7,53.8z"
                />
              </g>
              <g>
                <path
                  d="M86,72c-3-1.1-6.3-0.6-8.4,0.7c-2.1,1.3-3.1,3.2-2.6,4.9c0.5,1.7,2.2,2.8,4,3.3c1.9,0.6,4,0.6,6.4-0.2
				c2.5-0.8,4.3-1.2,6.3-1.4c1.9-0.2,4-0.2,6.8,0.7c-2.3-1.8-4-3.1-5.7-4.3C90.9,74.4,89.1,73.1,86,72z"
                />
              </g>
              <g>
                <path
                  d="M99.5,62.1c0.8-1.3,0.8-2.3,0.3-3.6c-0.5-1.2-1.3-2.8-2.7-3.8c-0.7-0.5-1.6-0.7-2.5-0.6c-0.9,0.1-1.8,0.5-2.6,1.3
				c-0.8,0.8-1.4,1.9-1.7,3.2c-0.3,1.3-0.2,2.8,0.4,4.1c1.2,2.8,2.7,4.1,4.1,5.3c1.4,1.1,2.9,2,4.9,2.9c-1.3-1.8-1.6-3.3-1.5-4.7
				C98.2,64.8,98.7,63.5,99.5,62.1z"
                />
              </g>
              <g>
                <path
                  d="M152.6,18.3c3.1-2.4,6-4.9,8.2-6.9c2.2-2,3.6-3.6,3.1-4.4c-0.4-0.8-2.6-0.5-5.5,1c-2.8,1.4-6.3,4-8.9,7.3
				c-2.7,3.4-4.2,5.9-5.6,8.5c-1.4,2.6-2.7,5.2-4.4,9.2c2.2-3.7,4-6,6-8.1C147.4,22.8,149.4,20.8,152.6,18.3z"
                />
              </g>
              <g>
                <path
                  d="M161.5,23.3c3.2-1.8,6.3-3.8,8.5-5.5c2.2-1.7,3.6-3.2,3.2-4c-0.4-0.8-2.5-0.7-5.2,0.3c-2.8,1-6.2,3-9.1,5.6
				c-2.9,2.7-4.7,4.8-6.5,6.9c-1.7,2.1-3.5,4.3-5.9,7.5c2.8-2.9,5-4.6,7.2-6.1C155.9,26.6,158.1,25.2,161.5,23.3z"
                />
              </g>
              <g>
                <path
                  d="M160,33.4c2.1-0.6,3.9-1.6,5.2-2.7c1.3-1,2-2.1,1.7-3c-0.3-0.8-1.6-1.2-3.4-0.9c-1.7,0.3-3.9,1.2-5.7,2.7
				c-1.8,1.6-2.9,2.8-4,4c-1.1,1.2-2.2,2.4-3.9,4.1c2-1.3,3.5-1.9,4.9-2.5C156.3,34.6,157.8,34.1,160,33.4z"
                />
              </g>
              <g>
                <path
                  d="M207.1,151.4c1.6-3.8,3.4-7.5,4.8-10.2c1.4-2.8,2.4-4.8,1.8-5.4c-0.6-0.6-2.8,0.4-5.2,3c-2.4,2.6-4.7,6.8-5.6,11.4
				c-0.8,4.7-0.7,8-0.3,11.2c0.4,3.2,1,6.4,2.6,10.8c-1.1-4.5-1.1-7.7-0.7-10.8C204.8,158.3,205.5,155.3,207.1,151.4z"
                />
              </g>
              <g>
                <path
                  d="M186.8,193.3c-0.7,3.1-1.2,5.4-1.8,8c-0.5,2.2-0.7,3.9,0,4.4c0.7,0.5,2.3-0.5,3.8-2.8c1.4-2.2,2.6-5.8,2.3-9.3
				c-0.2-3.6-1-6-1.8-8.2c-0.9-2.2-1.9-4.4-3.8-7.3c1.5,3.1,1.8,5.5,2,7.7C187.6,188,187.5,190.3,186.8,193.3z"
                />
              </g>
              <g>
                <path
                  d="M166,263.2c-0.7,3.5-2,6.9-3.1,9.4c-1.1,2.5-2,4.4-1.3,5c0.6,0.6,2.7-0.2,4.9-2.8c1.1-1.3,2.1-2.9,2.8-4.9
				c0.7-1.9,1.2-4.2,1.1-6.4c0-2.3-0.4-4.1-0.9-5.8c-0.5-1.6-1-3-1.7-4.3c-1.4-2.6-3-5.2-6.2-8.1c2.8,3.3,3.8,6,4.4,8.7
				c0.3,1.3,0.4,2.7,0.5,4.2C166.5,259.8,166.4,261.4,166,263.2z"
                />
              </g>
              <g>
                <path
                  d="M249.5,317.1c1.2-0.7,2.4-1.2,3.5-1.4c0.6-0.1,1.1-0.1,1.6-0.1c0.5,0,1,0.2,1.5,0.3c2,0.7,4,2.6,5.2,4.8
				c0.3,0.5,0.5,1.1,0.8,1.7c0.2,0.6,0.4,1.2,0.5,1.8c0.2,1.2,0.3,2.3,0.2,3.3c-0.2,1.9-1.3,3.4-2.5,4.1c-0.1,0.1-0.3,0.2-0.4,0.2
				c-0.1,0.1-0.3,0.1-0.4,0.2c-0.3,0.1-0.6,0.1-0.9,0.2c-0.7,0-1.3-0.1-1.8-0.4c-0.5-0.2-0.9-0.6-1.2-0.9c-0.3-0.4-0.5-0.9-0.5-1.4
				c-0.1-1.1,0.1-2,0.4-2.8c0.3-0.7,0.6-1.3,0.4-1.7c-0.1-0.4-0.8-0.7-2-0.3c-1.1,0.4-2.7,1.6-3.5,3.8c-0.4,1.1-0.6,2.5-0.3,4.1
				c0.3,1.6,1.1,3.2,2.4,4.5c1.3,1.3,3,2.2,4.9,2.7c1,0.2,2,0.3,3.1,0.3c0.5,0,1.1-0.1,1.7-0.2c0.5-0.1,1.1-0.3,1.6-0.4
				c4.2-1.5,7.4-5.3,8.5-10c0.5-2.3,0.6-4.6,0.2-6.8c-0.2-1.1-0.4-2.2-0.8-3.3c-0.4-1.1-0.8-2.2-1.4-3.2c-1.1-2.1-2.5-4-4.4-5.6
				c-1.8-1.6-4.1-3-6.7-3.8c-2.6-0.8-5.5-0.8-8.1-0.1c-2.6,0.7-4.8,2-6.7,3.5c-3.8,3.3-5.9,7.3-7.1,11c-1.1,3.8-1.4,7.4-1.1,10.7
				c0.2,3.3,1,6.4,1.9,9.1c1,2.8,2.1,5.4,3.5,7.8c1.3,2.5,2.7,4.9,4.3,7.3c1.5,2.4,3.1,4.8,4.9,7.4c3.6,5,7.8,10.5,13.5,16.3
				c-5.3-6.2-9-11.9-12.1-17.2c-1.5-2.7-2.8-5.2-4-7.8c-1.2-2.5-2.3-5-3.2-7.5c-0.9-2.5-1.7-5-2.2-7.6c-0.5-2.6-0.7-5.2-0.6-7.9
				c0.2-2.7,0.8-5.5,2-8.1c0.6-1.3,1.3-2.5,2.2-3.7C247.3,318.8,248.3,317.8,249.5,317.1z"
                />
              </g>
              <g>
                <path
                  d="M264.6,361c0.7-2.6,1-4.8,0.9-6.9c-0.1-2.1-0.6-4.1-2-5.2c-0.7-0.5-1.7-0.6-2.7-0.3c-1,0.3-2.1,1.1-3.1,2.3
				c-1,1.2-1.7,2.8-2.1,4.7c-0.4,1.9-0.3,3.9,0.3,5.9c0.6,2,1.4,3.6,2.2,4.9c0.8,1.3,1.7,2.3,2.6,3.3c1.8,1.9,3.7,3.6,6.7,5.5
				c-2.3-2.7-3-5-3.3-7.2C263.8,365.9,263.9,363.7,264.6,361z"
                />
              </g>
              <g>
                <path
                  d="M284.7,370c-3.2,6-5.2,12.1-7.6,17c-1.2,2.5-2.4,4.7-3.7,6.6c-0.7,0.9-1.4,1.8-2.1,2.6c-0.7,0.8-1.4,1.4-2.1,2
				c-1.4,1.1-2.5,1.5-3.5,1.7c-0.3,0.1-0.8,0-1.2-0.1c-0.5-0.2-0.9-0.4-1.2-0.7c-0.7-0.5-1.1-1.1-1.5-1.7c-0.3-0.6-0.5-1.1-0.9-1.3
				c-0.2-0.1-0.4-0.1-0.7,0c-0.3,0.1-0.6,0.4-0.9,0.8c-0.6,0.9-1,2.7-0.3,4.6c0.3,1,0.9,1.9,1.8,2.8c0.9,0.9,2.1,1.8,3.7,2.2
				c1.4,0.4,3,0.5,4.6,0.3c1.6-0.2,3.1-0.7,4.5-1.4c1.4-0.7,2.7-1.6,3.9-2.6c1.2-1,2.3-2.1,3.4-3.3c2.1-2.4,3.9-5.2,5.3-8.2
				c2.9-5.9,4.6-12.1,6.6-17.7c1.1-2.9,2.2-5.3,3.6-7.5c1.4-2.2,2.8-4.2,4.4-5.9c0.7-0.9,1.6-1.7,2.4-2.5c0.9-0.7,1.7-1.5,2.5-2.2
				c0.4-0.3,0.9-0.7,1.3-1c0.4-0.3,0.9-0.6,1.3-0.9c0.9-0.6,1.8-1.2,2.8-1.7c3.8-2.2,8-3.8,13-4.7c1.2-0.2,2.5-0.4,3.9-0.4
				c1.3-0.1,2.7-0.1,4.1,0.1c2.8,0.3,5.9,1.2,8.4,3.6c-2.3-2.6-5.4-3.8-8.3-4.4c-1.5-0.3-2.9-0.5-4.3-0.6c-1.4-0.1-2.7,0-4.1,0
				c-5.2,0.3-9.9,1.3-14.4,3c-1.1,0.4-2.2,0.9-3.3,1.4c-0.5,0.3-1.1,0.5-1.6,0.8c-0.5,0.3-1.1,0.6-1.6,0.9c-1.1,0.6-2.1,1.3-3.2,2
				c-1,0.8-2.1,1.5-3.2,2.3c-2.1,1.7-4.2,3.7-6.2,6C288.2,364.1,286.3,366.9,284.7,370z"
                />
              </g>
              <g>
                <path
                  d="M311.3,353.5c-2.3,1-4.3,2.4-5.9,4.2c-1.6,1.7-2.9,3.8-3.4,5.9c-0.6,2.1-0.3,4.2,0.4,5.5c0.7,1.3,1.7,1.7,2.5,1.5
				c0.9-0.2,1.6-0.8,2.3-1.4c0.7-0.6,1.5-1.2,2.3-1.9c0.9-0.7,1.8-1.6,3-2.7c1.2-1.1,2.4-2.4,3.6-3.7c2.6-2.8,4.9-4.5,7.5-5.8
				c1.3-0.6,2.7-1.3,4.3-1.7c1.6-0.4,3.5-0.7,5.7-0.7c-2.2-0.6-4.1-0.9-5.9-1.1c-1.8-0.2-3.4-0.3-5-0.3c-1.6,0-3.3,0.1-5.1,0.4
				C315.7,352,313.7,352.5,311.3,353.5z"
                />
              </g>
              <g>
                <path
                  d="M333.5,332.9c0.1-2-0.4-3.5-1.3-5c-0.9-1.4-2.2-2.8-3.9-3.1c-0.9-0.2-1.7,0-2.5,0.5c-0.8,0.5-1.5,1.4-1.9,2.6
				c-0.4,1.2-0.6,2.6-0.4,4.2c0.3,1.5,0.9,3.1,2,4.3c2.2,2.6,4.3,3.7,6.2,4.6c1.9,0.9,3.9,1.5,6.5,2.1c-2.2-1.6-3.2-3.2-3.8-4.8
				C333.7,336.7,333.4,335,333.5,332.9z"
                />
              </g>
              <g>
                <path
                  d="M523.9,354.8c0.8-4,0.7-8.3-0.4-12.2c-1-3.9-2.9-7.6-5.4-10.8c-2.5-3.2-5.7-5.8-9.2-7.6c-3.5-1.8-7.3-3-11-3.4
				c-3.8-0.4-7.6-0.2-11.2,0.8c-3.6,0.9-7.2,2.6-10.1,5.5c-1.4,1.4-2.6,3.2-3.4,5c-0.8,1.8-1.3,3.7-1.4,5.5
				c-0.3,3.7,0.5,7.3,2.3,10.3c1.8,3,4.4,5.2,7.1,6.5c2.7,1.3,5.5,1.8,8.1,1.8c2.6-0.1,5-0.8,7-1.8c2-1,3.5-2.4,4.6-3.7
				c2.3-2.7,3.1-5.3,3.4-7.1c0.2-1.8-0.1-2.8-0.5-2.8c-0.5-0.1-1,0.7-1.9,1.9c-0.9,1.2-2.2,3-4.3,4.5c-1,0.7-2.3,1.4-3.7,1.8
				c-1.4,0.4-2.9,0.6-4.5,0.4c-1.6-0.2-3.2-0.7-4.7-1.7c-1.5-0.9-2.7-2.2-3.4-3.7c-0.7-1.5-1-3.5-0.7-5.3c0.1-0.9,0.4-1.8,0.8-2.6
				c0.4-0.8,0.9-1.4,1.6-2.1c2.8-2.6,8.4-3.9,13.5-3.2c5.3,0.6,10.4,3.1,13.9,7.3c3.5,4.2,5.4,9.9,4.7,15.4
				c-0.8,5.7-4.1,10.7-8.1,14.1c-4.1,3.4-9,5.5-13.8,6.4c-4.9,1-9.8,1-14.5,0.4c-4.7-0.6-9.3-1.9-13.8-3.5
				c-4.5-1.6-8.9-3.6-13.5-5.8c-4.5-2.2-9.2-4.6-14.3-6.8c-5.1-2.2-10.8-4.1-17.1-4.8c-3.1-0.3-6.4-0.3-9.8,0.1
				c-1.7,0.3-3.4,0.7-5,1.2c-1.7,0.6-3.3,1.3-4.9,2.2c1.6-0.8,3.3-1.5,5-2c1.7-0.5,3.4-0.8,5-1c3.3-0.3,6.6-0.2,9.6,0.3
				c6.2,0.9,11.5,3.2,16.4,5.6c4.9,2.4,9.4,5.1,13.8,7.7c4.4,2.5,8.8,4.9,13.3,7c4.6,2,9.3,3.8,14.4,4.8c5.1,1.1,10.6,1.5,16.4,0.8
				c5.7-0.7,11.9-2.6,17.5-6.7c2.7-2,5.3-4.5,7.5-7.6C521.4,362.6,523.1,358.9,523.9,354.8z"
                />
              </g>
              <g>
                <path
                  d="M447.8,308.1c-3.2-3.2-7.6-5.5-12.3-6.1c-4.7-0.7-9.8,0.3-13.9,3.4c-2,1.5-3.5,3.4-4.6,5.6c-1.1,2.1-1.7,4.5-1.7,7
				c0,0.6,0.1,1.2,0.1,1.8c0.1,0.6,0.2,1.2,0.4,1.8c0.3,1.2,0.9,2.2,1.4,3.2c1.2,1.9,2.7,3.4,4.5,4.5c1.8,1.1,3.7,1.7,5.7,1.8
				c1.9,0.1,3.9-0.4,5.4-1.3c1.5-0.9,2.6-2,3.3-3.1c0.8-1.1,1.2-2.3,1.5-3.3c0.5-2.1,0-3.9-0.6-4.9c-0.3-0.5-0.6-0.8-0.9-1
				c-0.3-0.2-0.5-0.2-0.7-0.1c-0.2,0.1-0.4,0.3-0.5,0.6c-0.1,0.3-0.2,0.6-0.4,1c-0.3,0.8-0.6,1.7-1.4,2.6c-0.7,0.9-1.9,1.8-3.1,2
				c-1.1,0.2-2.3-0.1-3.5-1.2c-0.6-0.5-1.1-1.2-1.4-2c-0.1-0.4-0.3-0.7-0.3-1.1c0-0.2,0-0.3,0-0.5c0-0.2,0-0.4,0-0.6
				c0.1-1.6,1.2-3.6,2.9-4.8c1.6-1.2,4-1.9,6.7-1.6c2.7,0.2,5.3,1.3,7.7,3.1c2.4,1.8,4.2,4.3,5.3,6.8c1.1,2.5,1.5,5.1,1.3,7.6
				c-0.3,5-2.7,9.6-6.2,12.9c-3.5,3.3-8.6,5.3-14.4,5.7c-5.9,0.5-12.6-0.9-20.1-3.5c7.3,3.1,13.9,5,20.1,5.2
				c6.2,0.2,12-1.1,16.8-4.5c2.4-1.7,4.5-3.8,6.2-6.3c1.7-2.5,3.1-5.4,3.7-8.8C456.1,323.3,454.5,314.8,447.8,308.1z"
                />
              </g>
              <g>
                <path
                  d="M458.9,328.3c-2.4,2.5-3.5,5.9-3.3,8.5c0.2,2.7,1.6,4.5,3.3,4.7c1.8,0.2,3.4-0.9,4.8-2.5c1.3-1.6,2.3-3.7,2.8-6.3
				c0.5-2.7,1-4.7,1.8-6.7c0.8-1.9,1.8-3.8,4.1-6.1c-2.9,1.3-4.9,2.4-6.9,3.5C463.4,324.6,461.4,325.8,458.9,328.3z"
                />
              </g>
              <g>
                <path
                  d="M463.1,362.7c0.5,3.2,2.7,5.9,5,6.9c2.3,1.1,4.4,0.7,5.5-0.7c1-1.4,1-3.4,0.6-5.1c-0.5-1.8-1.4-3.3-3.1-4.8
				c-1.7-1.6-3-3-3.9-4.5c-1-1.6-1.8-3.4-1.9-6.2c-0.9,2.7-1.4,4.7-1.9,6.8C462.9,357.2,462.6,359.4,463.1,362.7z"
                />
              </g>
              <g>
                <path
                  d="M490.6,369c1.7,0.6,3.5,0.7,5.2,0.5c1.7-0.3,3.1-0.9,4.2-1.7c2.3-1.7,2.9-3.9,2-5.4c-0.9-1.5-2.7-2.2-4.6-2.6
				c-1.9-0.3-3.8-0.2-6.1,0.5c-2.3,0.7-4.3,0.9-6.3,0.8c-2-0.2-4-0.7-6.5-2.5c1.7,2.6,3.2,4.3,4.9,5.9
				C485.2,366,487.1,367.7,490.6,369z"
                />
              </g>
              <g>
                <path
                  d="M448.5,349.2c-1.6-0.6-2.7-0.5-4,0.1c-1.3,0.6-2.7,1.6-3.5,3.2c-0.4,0.8-0.5,1.7-0.2,2.5c0.2,0.9,0.8,1.7,1.7,2.4
				c0.9,0.7,2.1,1.2,3.5,1.3c1.3,0.1,2.8-0.1,4.1-0.8c2.7-1.5,3.9-3.1,5-4.5c1.1-1.5,1.9-3.1,2.9-5.2c-1.8,1.5-3.4,1.9-4.9,1.9
				C451.6,350.1,450.2,349.9,448.5,349.2z"
                />
              </g>
              <g>
                <path
                  d="M441,389.9c-1.2,1.3-3,2.2-4.8,2.4c-1.7,0.3-3.6,0-5.4-0.8c-1.8-0.7-3.5-2-4.7-3.3c-1.2-1.3-1.9-2.6-2-3.5
				c-0.2-1,0.1-2.1,0.4-2.7c0.3-0.6,0.9-1,1.7-1.1c0.8-0.1,1.5,0,2.1,0.2c0.6,0.2,1.1,0.4,1.5,0.3c0.4-0.1,0.8-0.6,0.6-1.7
				c-0.2-1-1-2.4-2.7-3.4c-0.8-0.5-1.9-0.8-3.2-0.9c-1.3-0.1-2.7,0.3-4,1.1c-2.7,1.6-4.5,4.5-4.8,8c-0.3,3.6,1.1,7,3.1,9.7
				c2,2.7,4.7,4.9,8,6.4c3.3,1.5,7.3,2.2,11.4,1.2c3.9-0.9,7.6-3.3,9.9-6.8c0.6-0.9,1.1-1.9,1.4-2.9c0.4-1,0.6-2,0.7-2.9
				c0.3-1.9,0.2-3.7-0.1-5.3c-0.6-3.2-1.9-5.9-3.3-8.1c-1.5-2.2-3.2-4.1-5-5.6c-1.8-1.5-3.7-2.8-5.7-3.8c-4-2.1-8.4-3.3-13.2-3.8
				c-4.9-0.5-10.3-0.3-16.5,0.5c12.6-0.7,21.4,1.7,27.8,6.5c3.2,2.4,5.8,5.4,7.4,9.2c0.8,1.9,1.3,3.9,1.3,5.9
				C442.8,386.8,442.3,388.7,441,389.9z"
                />
              </g>
              <g>
                <path
                  d="M457.3,391.4c0,2.2,1.1,3.9,2.5,4.7c1.5,0.9,3.3,0.9,4.8,0c1.5-0.9,2.4-2.4,2.6-4c0.2-1.5-0.4-3.1-1.9-4.3
				c-1.5-1.2-2.7-2.1-3.9-3c-1.1-0.9-2.1-2-3.1-3.7c0,2-0.2,3.5-0.4,5C457.7,387.6,457.4,389.2,457.3,391.4z"
                />
              </g>
              <g>
                <path
                  d="M431.7,410.6c-2.8,1.3-4.6,3.9-5.2,6.2c-0.6,2.3,0.1,4.3,1.6,5.2c1.5,0.9,3.5,0.6,5.2-0.3c1.7-0.9,3.3-2.4,4.4-4.7
				c1.1-2.3,2-4,3.1-5.6c1.1-1.6,2.4-3.1,4.8-4.7c-2.7,0.6-4.7,1-6.8,1.5C436.6,408.7,434.5,409.2,431.7,410.6z"
                />
              </g>
              <g>
                <path
                  d="M389.6,226.7c1.7,0.3,3.4,0.1,5-0.1c1.5-0.3,2.9-0.8,4-1.3c2.2-1.1,3.4-2.3,3.2-3.1c-0.3-0.9-2-1.1-4.3-0.9
				c-2.3,0.2-5.1,0.7-7.8,1.1c-2.8,0.4-4.9,0.2-6.8-0.5c-1-0.3-1.9-0.8-2.9-1.6c-0.9-0.7-1.9-1.8-2.6-3.3c0.4,1.6,1.1,2.9,1.9,3.9
				c0.8,1,1.7,1.9,2.6,2.6c0.9,0.7,2,1.4,3.2,1.9C386.4,226,387.9,226.5,389.6,226.7z"
                />
              </g>
              <g>
                <path
                  d="M392.5,238.2c1.9,2.5,3.7,4.8,5.3,6.6c1.6,1.7,2.9,2.9,3.7,2.5c0.8-0.3,0.8-2.2-0.1-4.7c-1-2.4-3-5.4-5.8-7.5
				c-2.9-2.2-5-3.2-7.2-4.2c-2.2-1-4.4-1.9-7.8-2.8c3.1,1.5,5,2.9,6.7,4.4C389.1,234,390.6,235.6,392.5,238.2z"
                />
              </g>
              <g>
                <path
                  d="M351.7,217.7c-0.7,2.4-1.3,4.7-1.7,6.6c-0.4,1.9-0.5,3.4,0.2,3.9c0.7,0.5,2.2-0.2,3.6-2.1c1.4-1.8,2.4-4.8,2.3-7.9
				c-0.2-3.1-0.8-5.1-1.4-7.1c-0.7-1.9-1.5-3.8-3.1-6.3c1,2.7,1.2,4.7,1.2,6.6C352.7,213.3,352.5,215.2,351.7,217.7z"
                />
              </g>
              <g>
                <path
                  d="M446.3,284.3c3.7-0.6,7.2-2.4,9.4-4.3c2.2-1.9,3-3.7,2.5-4.4c-0.6-0.7-2.4-0.1-4.7,0.8c-2.3,1-5.3,2.3-8.3,3.6
				c-3.2,1.4-5.5,2-7.9,2.5c-2.4,0.4-5,0.7-8.7,0.1c3.6,1.1,6.2,1.5,8.8,1.8C439.8,284.8,442.4,284.9,446.3,284.3z"
                />
              </g>
              <g>
                <path
                  d="M455.1,296.1c2.5,0.2,5-0.7,6.5-1.9c1.5-1.2,2-2.6,1.4-3.3c-0.6-0.7-1.8-0.7-3.3-0.4c-1.5,0.2-3.2,0.7-5.1,1.3
				c-1.9,0.6-3.4,0.9-4.9,0.9c-1.5,0-3.1-0.1-5.3-1.1c1.9,1.4,3.3,2.3,4.9,3C450.8,295.2,452.5,295.8,455.1,296.1z"
                />
              </g>
              <g>
                <path
                  d="M512.7,278.9c-0.8,1.7-2.2,3.2-3.7,4.4c-1.5,1.2-3.3,2.3-4.8,3.4c-1.5,1.1-2.8,2.1-3.5,3c-0.8,0.9-1,1.6-0.8,2
				c0.3,0.4,1,0.3,2,0.1c1.1-0.2,2.5-0.7,4.2-1.4c1.7-0.7,3.8-1.6,5.9-3.2c2-1.6,4-3.9,4.8-6.9c0.4-1.5,0.4-3,0.2-4.4
				c-0.3-1.3-0.8-2.5-1.4-3.4c-1.2-1.9-2.8-3.1-4.4-4c-3.3-1.8-7-2.2-12.2-2.6c2.6,0.5,4.7,0.9,6.5,1.6c1.8,0.7,3.3,1.5,4.5,2.6
				c1.2,1.1,2.2,2.4,2.8,3.9C513.5,275.5,513.5,277.2,512.7,278.9z"
                />
              </g>
              <g>
                <path
                  d="M421,118.9c2.1-3.8,2.7-8.3,2.3-11.5c-0.4-3.3-1.6-5.2-2.4-5c-0.9,0.2-1.3,2.2-1.8,4.9c-0.5,2.8-1.2,6.3-2.2,9.9
				c-1.1,3.7-2.3,6.2-3.8,8.5c-1.5,2.3-3.4,4.6-6.9,6.9c3.8-1.9,6.2-3.7,8.4-5.7C416.7,125,418.8,122.8,421,118.9z"
                />
              </g>
              <g>
                <path
                  d="M423,162.8c5.4,0.3,10.9,0.4,15,0.3c4.1-0.1,6.9-0.6,7-1.5c0.1-0.9-2.6-2-6.7-2.7c-4.2-0.7-9.8-1.1-15.4-0.5
				c-5.7,0.6-9.6,1.3-13.5,2.1c-3.9,0.8-7.8,1.6-13.5,3c5.8-0.8,9.8-1,13.7-1.1C413.6,162.4,417.4,162.5,423,162.8z"
                />
              </g>
              <g>
                <path
                  d="M428.4,172.9c6.4,1.8,13.2,1.4,13.5-0.3c0.2-1.7-6.2-4-12.9-4c-6.9,0-9.5,0.3-16.5-0.1
				C419.4,169.9,421.8,171,428.4,172.9z"
                />
              </g>
              <g>
                <path
                  d="M465.2,185.4c3.3-1.4,6.4-3,8.7-4.4c2.3-1.4,3.8-2.7,3.5-3.5c-0.3-0.8-2.3-1-5.1-0.3c-2.8,0.7-6.3,2.2-9.3,4.5
				c-3.1,2.4-5,4.3-6.8,6.1c-1.8,1.9-3.7,3.9-6.2,6.8c2.9-2.6,5.1-4,7.4-5.3C459.5,188,461.8,186.9,465.2,185.4z"
                />
              </g>
              <g>
                <path
                  d="M479.4,189.5c3.5-0.2,7-0.5,9.6-1c2.6-0.4,4.5-1,4.6-1.9c0.1-0.9-1.7-1.8-4.6-2.3c-2.8-0.5-6.7-0.3-10.4,0.8
				c-3.7,1.2-6.2,2.3-8.5,3.5c-2.4,1.2-4.7,2.5-8,4.6c3.5-1.6,6.1-2.3,8.6-2.8C473.3,190.1,475.8,189.7,479.4,189.5z"
                />
              </g>
              <g>
                <path
                  d="M496,177.4c5.3-1.4,9.4-5.2,8.5-6.8c-0.9-1.5-6-0.2-10.3,2.8c-2.2,1.5-3.8,2.6-5.3,3.7c-1.6,1-3.2,2-5.8,3.2
				c2.7-0.7,4.6-1.1,6.5-1.5C491.4,178.5,493.3,178.1,496,177.4z"
                />
              </g>
              <g>
                <path
                  d="M508.6,181.3c2.3,0.5,4.6,0.6,6.3,0.4c1.8-0.2,3.1-0.7,3.3-1.5c0.2-0.9-0.9-1.9-2.7-2.6c-1.8-0.7-4.4-1.1-6.9-0.6
				c-2.6,0.4-4.3,1-6,1.5c-1.8,0.6-3.6,1.2-5.8,2c2.6-0.3,4.3-0.3,6-0.1C504.5,180.5,506.2,180.8,508.6,181.3z"
                />
              </g>
              <g>
                <path
                  d="M515.5,191.4c5.5,1.8,11.5,1.5,11.8-0.3c0.3-1.7-5.2-4-11-4.1c-6-0.1-8.3,0.3-14.4-0.2
				C507.8,188.4,509.8,189.5,515.5,191.4z"
                />
              </g>
              <g>
                <path
                  d="M537.8,184.7c2.8,0.1,5.6-0.6,7.4-1.6c1.9-1,2.9-2.2,2.6-3c-0.3-0.8-1.8-1.1-3.7-1c-2,0.1-4.4,0.5-6.9,1.3
				c-2.5,0.8-4.3,1.3-6.2,1.7c-1.9,0.4-3.8,0.6-6.6,0.6c2.8,0.6,4.7,1,6.6,1.3C533,184.3,535,184.6,537.8,184.7z"
                />
              </g>
              <g>
                <path
                  d="M537.5,176.3c2.8-0.1,5.5-1.1,7.4-2.2c1.8-1.1,2.8-2.4,2.4-3.2c-0.4-0.8-1.9-1-3.8-0.7c-2,0.2-4.4,0.9-6.9,1.9
				c-2.5,1-4.3,1.6-6.1,2.2c-1.8,0.5-3.7,1-6.6,1.2c2.9,0.3,4.8,0.5,6.8,0.7C532.6,176.2,534.6,176.4,537.5,176.3z"
                />
              </g>
              <g>
                <path
                  d="M535.8,166.5c2.7-0.6,5.2-1.8,6.8-3c1.7-1.3,2.5-2.6,2.1-3.4c-0.8-1.6-6.1-0.4-10.6,2.3c-2.3,1.4-4,2.4-5.6,3.4
				c-1.7,0.9-3.4,1.8-6.1,2.9c2.8-0.5,4.7-0.8,6.7-1C531.1,167.3,533,167.1,535.8,166.5z"
                />
              </g>
              <g>
                <path
                  d="M557.3,160.8c1.9-0.2,3.7-0.8,5-1.5c1.3-0.7,2.2-1.5,2.2-2.4c-0.1-0.9-1.2-1.6-2.9-1.7c-1.7-0.2-3.9,0.3-5.8,1.5
				c-1.9,1.2-3.1,2.3-4.2,3.4c-1.1,1.1-2.2,2.1-3.7,3.7c1.8-1.2,3.2-1.7,4.6-2.1C553.9,161.3,555.3,161,557.3,160.8z"
                />
              </g>
              <g>
                <path
                  d="M366.2,478.6c3.4-1,6.8-2.1,9.3-3.2c2.5-1.1,4.2-2.1,4-3c-0.1-0.9-2.1-1.3-5-1.1c-2.9,0.3-6.7,1.3-10,3.2
				c-3.4,2-5.6,3.6-7.7,5.3c-2.1,1.7-4.2,3.4-7,6.1c3.2-2.3,5.6-3.5,8-4.5C360.2,480.5,362.6,479.6,366.2,478.6z"
                />
              </g>
              <g>
                <path
                  d="M377.5,483.6c2.3,0.1,4.5-0.2,6.2-0.7c1.7-0.5,2.9-1.2,2.9-2.1c0-0.9-1.2-1.7-3.1-2.1c-1.9-0.4-4.4-0.3-6.8,0.6
				c-2.4,0.9-4,1.7-5.6,2.5c-1.7,0.9-3.3,1.8-5.3,2.9c2.4-0.7,4.2-1,5.9-1.1C373.5,483.5,375.1,483.5,377.5,483.6z"
                />
              </g>
              <g>
                <path
                  d="M400.9,489.4c2.5,0.8,5,1.3,7,1.3c2,0.1,3.5-0.3,3.7-1.1c0.3-0.8-0.8-2-2.7-2.9c-1.9-0.9-4.6-1.6-7.4-1.6
				c-2.9,0-4.8,0.3-6.8,0.5c-2.1,0.2-4.1,0.5-6.7,0.8c2.8,0.2,4.7,0.6,6.6,1.1C396.6,487.9,398.4,488.5,400.9,489.4z"
                />
              </g>
              <g>
                <path
                  d="M404,480.2c5.8,1.9,12.1,1.7,12.4,0c0.3-1.7-5.5-4.1-11.6-4.3c-6.3-0.2-8.7,0.1-15.2-0.5
				C395.8,477.1,398,478.2,404,480.2z"
                />
              </g>
              <g>
                <path
                  d="M405.2,471.3c1.9,0,3.8-0.8,4.9-1.8c1.1-1,1.6-2.1,1.2-2.9c-0.4-0.8-1.5-1.1-2.8-1c-1.3,0.1-2.9,0.5-4.3,1.4
				c-1.5,0.9-2.6,1.5-3.8,2c-1.2,0.5-2.4,0.9-4.3,1.2c1.7,0.3,3,0.5,4.5,0.7C401.8,471.2,403.2,471.4,405.2,471.3z"
                />
              </g>
              <g>
                <path
                  d="M326.4,598.7c3.6-0.2,7.2-0.8,10.9-1.8c3.6-1,7.3-2.7,10.5-5.2c1.6-1.3,3.1-2.8,4.3-4.4c1.2-1.7,2.2-3.5,3-5.4
				c1.5-3.8,2.1-7.8,1.7-11.8c-0.2-2-0.7-3.9-1.4-5.8c-0.7-1.9-1.7-3.6-2.8-5.1c-2.2-3.1-5.1-5.5-8.2-7.3c-1.6-0.8-3.2-1.5-4.9-2
				c-1.7-0.5-3.4-0.7-5.1-0.8c-3.4-0.1-6.7,0.5-9.7,1.8c-2.9,1.4-5.5,3.4-7.2,6.1c-1.7,2.6-2.4,5.5-2.4,8.1c0,2.7,0.8,5.3,2.2,7.2
				c0.7,1,1.5,1.8,2.4,2.5c0.9,0.6,1.8,1.1,2.7,1.4c3.6,1.3,6.6,0.5,8.1-0.6c1.6-1.1,1.9-2.1,1.6-2.4c-0.3-0.4-1.2-0.1-2.5,0
				c-0.7,0-1.5,0-2.4-0.2c-0.9-0.2-1.9-0.6-2.8-1.2c-0.9-0.6-1.7-1.5-2.1-2.5c-0.5-1.1-0.6-2.4-0.4-3.8c0.2-1.4,0.8-2.9,1.8-4.1
				c0.9-1.1,2.4-2.1,4.1-2.7c1.7-0.6,3.8-0.8,5.8-0.6c1,0.1,2,0.3,3,0.7c1,0.4,2,0.8,2.9,1.4c1.9,1.2,3.6,2.7,4.9,4.6
				c1.3,1.9,2,4,2.3,6.3c0.2,2.3-0.1,4.8-1,7.1c-0.4,1.1-1,2.2-1.7,3.2c-0.7,1-1.5,1.8-2.5,2.7c-3.9,3.3-9.9,5-16.1,5.8
				c-6.3,0.8-12.3,0.4-17.8-0.8c-5.5-1.2-10.3-3.2-14.8-5.6c-9-4.9-16.6-10.8-25.2-15.5c-8.6-4.7-18.1-7.9-29-9.2
				c-5.4-0.6-11.2-0.8-17.4-0.2c-6.1,0.6-12.7,2-19.4,4.5c6.8-2.2,13.3-3.3,19.4-3.6c6.1-0.3,11.8,0.2,17.1,1.1
				c10.5,1.9,19.4,5.7,27.3,10.8c3.9,2.6,7.7,5.5,11.5,8.5c3.9,3,7.9,6.1,12.5,9.1c4.6,3,9.9,5.7,16,7.5
				C312.1,598.2,319,599.2,326.4,598.7z"
                />
              </g>
              <g>
                <path
                  d="M279.9,561.6c4.2-1.3,8.3-3.6,11.6-6.8c1.7-1.6,3.1-3.5,4.3-5.6c1.2-2.1,2-4.4,2.3-7c0.7-5.2-1.5-10.8-6.2-13.8
				c-2.4-1.4-4.9-1.9-7.1-1.8c-2.2,0.1-4.1,0.5-6,1.3c-1.8,0.8-3.5,2-4.9,3.6c-1.3,1.6-2.1,3.7-2.1,5.7c0,3.9,2.4,6.6,4.7,7.6
				c2.3,1,4.2,0.5,5.3-0.1c1.1-0.6,1.3-1.3,1.2-1.6c-0.2-0.4-0.8-0.6-1.5-0.9c-0.7-0.3-1.7-0.8-2.4-1.6c-0.3-0.4-0.5-0.8-0.6-1.4
				c-0.1-0.5-0.1-1.1,0.1-1.5c0.2-0.4,0.5-0.7,1-1.1c0.5-0.3,1.2-0.7,2.1-0.9c0.9-0.2,2-0.3,2.9-0.2c1,0.1,1.7,0.4,2.2,0.8
				c0.5,0.4,0.9,1,1.2,1.8c0.2,0.4,0.3,0.9,0.3,1.3c0,0.4,0,0.9,0,1.5c-0.3,2.1-1.7,4.8-3.7,7c-2,2.2-4.7,4.1-7.8,5.4
				c-3.1,1.4-6.4,2.2-9.5,2.6c-3.2,0.4-6.2,0.5-9.2,0.4c-5.9-0.1-11.4-0.8-17-1.1c-5.6-0.3-11.4-0.3-17.6,0.8
				c-6.2,1.1-13,3.1-20.3,7c7.5-3.4,14.4-4.8,20.5-5.3c6.2-0.4,11.6,0.2,17,1.3c5.4,1,10.7,2.4,16.9,3.4c3.1,0.5,6.5,0.9,10.2,0.9
				C271.4,563.3,275.5,563,279.9,561.6z"
                />
              </g>
              <g>
                <path
                  d="M303.9,501.7c1.1-5.3,2.3-10.4,3.1-14.4c0.8-4.1,1.2-7.3-0.3-8.3c-1.4-1-4.6,0.8-7.4,4.9c-1.4,2.1-2.6,4.7-3.4,7.7
				c-0.8,3-1.1,6.3-0.8,9.6c0.3,3.4,0.9,6.2,1.7,8.6c0.7,2.4,1.6,4.5,2.6,6.5c1,2,2,4,3.3,6.1c1.3,2.1,2.8,4.3,4.9,6.8
				c-1.6-2.8-2.6-5.2-3.3-7.5c-0.7-2.3-1.1-4.3-1.3-6.4c-0.2-2.1-0.2-4.1-0.1-6.3C303,506.9,303.3,504.5,303.9,501.7z"
                />
              </g>
              <g>
                <path
                  d="M320.9,499.7c0.3-3.4-0.1-6.9-1.1-10c-0.9-3.1-2.4-5.8-4.1-7.9c-1.6-2.1-3.4-3.4-4.9-4.1c-1.5-0.7-2.6-0.7-3.3-0.1
				c-1.3,1.3-0.3,4.3,0.8,8.2c0.6,2,1.2,4.2,1.9,6.6c0.6,2.4,1.3,5,1.8,7.7c0.6,2.8,0.8,5.2,0.9,7.5c0.1,2.3,0,4.4-0.3,6.5
				c-0.5,4.2-1.6,8.5-4.5,14.4c3.8-5.3,6.1-9.2,8.1-13.3c1-2.1,1.9-4.2,2.7-6.7C319.9,506,320.6,503.2,320.9,499.7z"
                />
              </g>
              <g>
                <path
                  d="M312.1,497.4c0.7-3.6,0.6-7.3,0-10.1c-0.6-2.7-1.6-4.4-2.5-4.3c-0.9,0.1-1.4,1.9-1.7,4.6c-0.3,2.6-0.4,6-0.2,9.5
				c0.2,3.6,0.2,6.1,0.2,8.6c-0.1,2.5-0.3,5.1-1.2,8.8c1.3-3.6,2.2-6,3-8.5C310.5,503.6,311.3,501.1,312.1,497.4z"
                />
              </g>
              <g>
                <path
                  d="M310,493.7c0.9-3.1,1-6.3,0.6-8.7c-0.4-2.4-1.2-4-2.1-3.9c-0.9,0-1.6,1.6-2.1,3.8c-0.5,2.3-0.8,5.2-0.8,8.3
				c0,3.1,0,5.3-0.2,7.5c-0.2,2.2-0.4,4.4-1.2,7.6c1.2-2.9,2.1-5,3.1-7.2C308.2,499,309,496.9,310,493.7z"
                />
              </g>
              <g>
                <path
                  d="M302.6,488.7c1.5-2.6,2.9-5.1,3.9-7.2c1-2,1.6-3.6,1-4.3c-0.6-0.6-2.4,0-4.3,1.7c-1.9,1.8-3.9,4.8-4.7,8.1
				c-0.8,3.5-0.9,5.9-0.8,8.3c0.1,2.4,0.3,4.7,1,8.1c-0.2-3.4,0.2-5.7,0.8-7.9C300.3,493.4,301.1,491.4,302.6,488.7z"
                />
              </g>
              <g>
                <path
                  d="M305.8,559.5c1.5-1.7,2.7-3.7,3.5-5.7c0.8-2,1.3-4,1.4-5.7c0.3-3.4-0.5-5.7-1.4-5.7c-0.9,0-1.6,2-2.7,4.8
				c-1.1,2.8-2.5,6.3-4.4,9.7c-2,3.5-3.9,5.6-6.1,7.6c-2.2,1.9-4.8,3.6-9.1,4.9c4.4-0.8,7.4-1.9,10.1-3.3c1.4-0.7,2.7-1.5,4.2-2.5
				C302.7,562.6,304.2,561.3,305.8,559.5z"
                />
              </g>
              <g>
                <path
                  d="M341,541.1c4.9-0.6,9.7-1.1,13.4-1.5c3.7-0.4,6.3-0.9,6.4-1.8c0.1-0.8-2.4-2-6.4-2.4c-3.9-0.5-9.3-0.1-14.4,1.4
				c-5.2,1.6-8.5,3.3-11.7,5c-3.2,1.8-6.4,3.7-10.8,6.9c4.6-2.8,8.1-4.1,11.5-5.2C332.6,542.5,336,541.7,341,541.1z"
                />
              </g>
              <g>
                <path
                  d="M361.1,546c3.3,0.2,6.6,0,9.1-0.3c2.5-0.4,4.3-1,4.3-1.9c0.1-0.9-1.6-1.8-4.2-2.3c-2.6-0.5-6.1-0.6-9.6,0.1
				c-3.5,0.7-5.9,1.5-8.2,2.3c-2.5,0.8-4.8,1.6-8.1,2.8c3.6-0.7,6-0.8,8.5-0.9C355.2,545.7,357.6,545.8,361.1,546z"
                />
              </g>
              <g>
                <path
                  d="M368.5,552.6c2.6,0.8,5.2,1.2,7.2,1.2c2,0,3.6-0.3,3.8-1.2c0.2-0.8-0.9-2-2.8-2.9c-2-0.9-4.8-1.6-7.6-1.5
				c-2.9,0.1-4.9,0.4-6.9,0.7c-2.1,0.3-4.2,0.7-6.9,1.1c2.9,0.1,4.9,0.4,6.8,0.8C364,551.3,365.9,551.8,368.5,552.6z"
                />
              </g>
              <g>
                <path
                  d="M371.3,563.5c1.5,1.5,2.9,2.8,4,3.8c1.2,1,2.2,1.7,3.1,1.5c0.8-0.3,1.2-1.8,0.4-3.8c-0.7-2-2.7-4.1-5.1-5.1
				c-2.5-1.1-4.2-1.3-6-1.6c-1.7-0.3-3.4-0.5-5.9-1c2.3,1,3.8,1.8,5.2,2.7C368.4,561,369.8,562,371.3,563.5z"
                />
              </g>
              <g>
                <path
                  d="M370.1,580.9c0.2,2.4,0.6,4.7,1.1,6.5c0.5,1.8,1.1,3.1,2,3.2c0.9,0.1,1.8-1.2,2.2-3.3c0.4-2.1,0.2-5-1-7.6
				c-1.2-2.6-2.4-4.2-3.6-5.8c-1.2-1.5-2.5-3-4.4-5c1.5,2.3,2.2,4.1,2.7,5.8C369.5,576.6,369.8,578.4,370.1,580.9z"
                />
              </g>
              <g>
                <path
                  d="M364.6,598.8c-0.5,1.7-0.6,3.5-0.3,4.8c0.2,1.4,0.8,2.4,1.7,2.6c0.9,0.2,1.8-0.6,2.5-2c0.6-1.4,1-3.4,0.6-5.3
				c-0.4-2-0.9-3.3-1.3-4.7c-0.5-1.3-0.9-2.6-1.5-4.5c0.1,2-0.1,3.3-0.4,4.7C365.5,595.7,365.1,597,364.6,598.8z"
                />
              </g>
              <g>
                <path
                  d="M353.3,601c-3.3,3.3-5.1,7.7-3.7,8.8c1.4,1.1,5.4-1.9,7.3-6.4c1.9-4.6,2.2-6.5,4.2-11.1
				C358.2,596.4,356.7,597.5,353.3,601z"
                />
              </g>
              <g>
                <path
                  d="M340.2,602.7c-2.6,1.1-5,2.5-6.7,3.8c-1.7,1.3-2.7,2.6-2.3,3.4c0.4,0.8,2,0.9,4.2,0.3c2.2-0.6,4.8-1.9,7.1-3.8
				c2.3-2,3.8-3.5,5.2-5c1.5-1.5,2.9-3,5.1-5.1c-2.5,1.7-4.4,2.7-6.2,3.6C344.8,600.8,342.9,601.6,340.2,602.7z"
                />
              </g>
              <g>
                <path
                  d="M316.4,606.9c-2.8,0-5.6,0.4-7.6,1c-2.1,0.6-3.5,1.4-3.5,2.2c0,0.9,1.5,1.6,3.7,1.9c2.2,0.3,5.2,0.1,8.1-0.9
				c2.9-1,4.8-1.9,6.7-2.8c2-1,4-1.9,6.6-3.1c-3,0.8-5,1.1-7.1,1.3C321.3,606.8,319.3,606.9,316.4,606.9z"
                />
              </g>
              <g>
                <path
                  d="M233,569.1c5.4,0.9,10.6,2.2,14.6,3.2c4,1,6.8,1.7,7.2,0.8c0.4-0.7-1.8-2.8-5.8-4.8c-4-1.9-9.8-3.5-15.7-3.6
				c-6,0-10.1,0.7-14.1,1.6c-4,0.9-8,2.1-13.5,4.5c5.7-1.9,9.8-2.4,13.7-2.6C223.5,568,227.4,568.2,233,569.1z"
                />
              </g>
              <g>
                <path
                  d="M251.9,584.4c1.8,1.9,3.6,3.7,5.1,5.1c1.5,1.3,2.8,2.2,3.6,1.8c0.8-0.4,0.8-2.1-0.2-4.2c-1-2.1-3.1-4.6-5.8-6.1
				c-2.8-1.5-4.8-2.2-6.9-2.7c-2.1-0.5-4.1-0.9-7.1-1.1c2.9,0.8,4.7,1.8,6.4,2.9C248.6,581.1,250.1,582.4,251.9,584.4z"
                />
              </g>
              <g>
                <path
                  d="M68,396c1.6-0.2,3.2-0.3,4.9-0.5c1.6-0.1,3.3-0.2,4.8-0.2c3.1,0,6.1,0.2,8.8,0.7c2.7,0.5,5.1,1.4,6.9,2.6
				c0.9,0.6,1.7,1.2,2.3,1.9c0.3,0.3,0.6,0.7,0.9,1.1c0.2,0.4,0.4,0.7,0.6,1.1c0.7,1.6,1.1,3.6,0.9,5.5c-0.1,1-0.3,1.9-0.6,2.8
				c-0.3,0.9-0.8,1.8-1.3,2.6c-1.1,1.7-2.5,3.2-4.2,4.4c-1.7,1.2-3.4,2.1-5.2,2.7c-1.8,0.6-3.7,0.8-5.3,0.7c-1.7-0.1-3.2-0.4-4.5-1
				c-2.5-1-4.3-3.3-5.3-5.4c-1-2.1-1.2-4.1-1.3-5.6c0-0.7-0.1-1.4-0.1-1.9c-0.1-0.5-0.2-0.9-0.5-1.1c-0.3-0.2-0.7-0.2-1.2,0
				c-0.5,0.2-1.1,0.7-1.8,1.5c-0.6,0.8-1.3,1.9-1.7,3.4c-0.4,1.4-0.7,3.2-0.5,5.1c0.1,1.9,0.7,4.1,1.7,6.3c0.3,0.5,0.6,1.1,0.9,1.6
				c0.4,0.5,0.7,1.1,1.2,1.6c0.5,0.5,0.9,1.1,1.4,1.5c0.3,0.2,0.5,0.5,0.8,0.7c0.3,0.2,0.6,0.4,0.8,0.7c2.3,1.8,5.2,3,8.2,3.7
				c3.1,0.7,6.4,0.7,9.7,0.2c3.3-0.6,6.8-1.8,9.9-3.6c3.1-1.9,6.1-4.4,8.5-7.6c1.2-1.6,2.3-3.4,3.1-5.4c0.8-2,1.4-4.1,1.7-6.3
				c0.6-4.4,0.1-9-1.8-13.5c-0.5-1.1-1.1-2.2-1.7-3.2c-0.7-1-1.4-2-2.2-2.9c-0.8-0.9-1.7-1.7-2.6-2.5c-0.5-0.4-0.9-0.7-1.4-1.1
				c-0.5-0.4-1-0.7-1.5-1c-4-2.5-8.2-3.7-12.3-4.3c-4.1-0.6-8.1-0.7-12-0.3c-1.9,0.2-3.8,0.4-5.6,0.7c-1.9,0.3-3.7,0.6-5.5,1
				c-7.6,1.7-14.2,4-20.2,6.7c-6,2.7-11.3,5.7-16.1,8.9c-4.8,3.2-9.1,6.5-13,10.1c-3.9,3.5-7.6,7.2-10.9,11
				c-6.7,7.7-12.6,16.1-18,26.2c-5.4,10-10.4,21.7-14.4,36.1c4.8-14.2,10.6-25.4,16.7-34.7c6.1-9.4,12.7-16.9,19.9-23.6
				c3.6-3.3,7.4-6.3,11.4-9.2c4-2.9,8.4-5.5,13.1-7.9c4.7-2.4,9.8-4.6,15.4-6.4C55.2,398.3,61.3,396.8,68,396z"
                />
              </g>
              <g>
                <path
                  d="M53,455.6c0.5,1.9,0.5,3.8,0.3,5.6c-0.3,1.9-0.8,3.7-1.7,5.4c-0.9,1.7-2,3.1-3.3,4.3c-0.6,0.6-1.3,1.1-2,1.6
				c-0.7,0.5-1.5,0.9-2.3,1.2c-3.2,1.4-6.9,1.6-10,0.9c-1.6-0.3-2.9-0.9-3.9-1.6c-1-0.7-1.6-1.3-2.1-2.1c-0.2-0.4-0.4-0.8-0.5-1.1
				c-0.1-0.3-0.2-0.6-0.1-0.9c0-0.6,0.3-1.5,1-2.3c0.7-0.9,1.6-1.7,2.6-2.4c0.5-0.3,1-0.7,1.5-1c0.5-0.3,0.9-0.5,1.3-0.8
				c0.5-0.3,1-0.6,1.4-0.9c0.2-0.2,0.7-0.2,1.1-0.2c0.4,0.1,0.7,0.2,1,0.4c0.3,0.2,0.5,0.5,0.8,0.6c0.3,0.1,0.7,0.1,1.1-0.4
				c0.4-0.4,0.7-1.4,0.4-2.5c-0.2-1.1-0.9-2.3-2.5-3.3c-1.5-0.9-3.3-1.1-5-1c-0.8,0.1-1.6,0.2-2.4,0.3c-0.8,0.1-1.6,0.3-2.5,0.6
				c-1.7,0.5-3.6,1.3-5.5,2.6c-1.9,1.3-4,3.4-5.2,6.4c-0.6,1.5-1,3.2-1.1,4.8c-0.1,1.7,0.1,3.3,0.6,5c0.5,1.6,1.2,3.2,2.1,4.6
				c0.9,1.5,2.1,2.7,3.3,3.8c2.5,2.2,5.4,3.8,8.4,4.8c6.1,2,13.1,1.9,19.6-0.7c3.2-1.3,6.4-3.2,9-5.8c2.6-2.5,4.7-5.6,6.2-8.9
				c1.5-3.3,2.4-6.9,2.5-10.6c0.2-3.7-0.5-7.5-1.8-10.8c-2.7-7-7.7-12-13.2-14.9c-5.6-2.9-11.3-3.4-16.2-3.1
				c-4.9,0.3-9.3,1.4-13.4,2.8c-4.1,1.3-7.9,3-11.5,4.9c-3.7,1.9-7.1,4.1-10.6,6.6c-3.4,2.5-6.7,5.3-9.9,8.6
				c-3.2,3.3-6.3,6.9-9.3,11.1c-2.9,4.2-5.7,9-8,14.5c2.6-5.3,5.7-9.8,9-13.7c3.2-3.9,6.6-7.1,10-9.9c3.4-2.8,6.9-5.1,10.4-7
				c3.5-1.9,7-3.5,10.6-4.8c3.6-1.2,7.3-2.3,11.1-2.9c3.8-0.7,7.7-1.1,11.5-0.8c3.8,0.3,7.6,1.4,10.5,3.5
				C49.6,448.2,52,451.7,53,455.6z"
                />
              </g>
              <g>
                <path
                  d="M121.8,361.1c10.9,1.6,21.5,3.9,29.6,5.9c0.5,0.1,1,0.2,1.5,0.3c0.5,0.1,1,0.2,1.4,0.4c0.9,0.2,1.8,0.4,2.6,0.6
				c0.8,0.2,1.6,0.3,2.4,0.5c0.7,0.2,1.4,0.3,2,0.4c2.5,0.4,4.2,0.3,4.6-0.5c0.4-0.8-0.5-2.1-2.5-3.9c-0.5-0.4-1.1-0.9-1.7-1.4
				c-0.6-0.5-1.4-0.9-2.1-1.4c-0.8-0.5-1.6-1-2.5-1.5c-0.5-0.2-0.9-0.5-1.4-0.8c-0.5-0.2-1-0.5-1.5-0.7c-8.1-3.8-19.9-6.9-31.8-6.7
				c-3.1,0.1-5.9,0.1-8.5,0.4c-1.3,0.1-2.6,0.2-3.8,0.4c-1.2,0.2-2.4,0.4-3.5,0.5c-1.1,0.2-2.3,0.3-3.3,0.5
				c-1.1,0.2-2.1,0.4-3.2,0.7c-1,0.2-2.1,0.4-3.1,0.7c-1,0.3-2,0.5-3,0.8c-1,0.3-2,0.5-3,0.8c-1,0.3-2,0.6-3,1c-1,0.3-2,0.6-3.1,1
				c-1,0.4-2.1,0.8-3.2,1.2c-1.1,0.4-2.2,0.8-3.3,1.3c-1.1,0.5-2.3,1-3.5,1.5c-0.6,0.3-1.2,0.5-1.8,0.8c-0.6,0.3-1.2,0.6-1.9,0.9
				c-1.3,0.6-2.6,1.2-4,1.9c1.4-0.5,2.8-1,4.1-1.5c0.7-0.2,1.3-0.5,1.9-0.7c0.6-0.2,1.3-0.4,1.9-0.6c1.2-0.4,2.4-0.7,3.6-1
				c1.2-0.3,2.3-0.5,3.4-0.8c1.1-0.2,2.2-0.5,3.3-0.7c1.1-0.2,2.1-0.3,3.2-0.5c1-0.1,2-0.3,3-0.4c1-0.1,2-0.2,3-0.3
				c1-0.1,2-0.2,3-0.2c1-0.1,2-0.1,3-0.1c1,0,2.1,0,3.1-0.1c1.1,0,2.1,0.1,3.2,0.1c1.1,0,2.2,0.1,3.4,0.1c1.2,0.1,2.3,0.2,3.6,0.3
				C116.4,360.4,119,360.8,121.8,361.1z"
                />
              </g>
              <g>
                <path
                  d="M120.9,378.3c10.8,0.9,21.8-0.5,29.7-2.7c7.9-2.2,12.8-5.1,12.3-6.8c-0.4-1.8-5.9-1.9-13.7-1.5
				c-7.8,0.4-18.1,1.3-28.4,2.2c-10.6,0.9-18,1.1-25.5,0.9c-7.5-0.3-15.1-1-26.1-3.4c10.7,3.5,18.2,5.4,25.6,7.2
				C102.3,375.9,109.8,377.4,120.9,378.3z"
                />
              </g>
              <g>
                <path
                  d="M132.4,371.1c6.3,1.3,12.7,1.4,17.5,0.7c4.8-0.7,8-2.3,8-4c0-1.8-3.1-3.3-7.8-4.4c-4.7-1-10.9-1.5-17.2-1
				c-6.5,0.5-10.9,0.9-15.5,1.2c-4.5,0.3-9.1,0.4-15.8,0c6.6,1.4,11,2.6,15.4,3.8C121.5,368.5,125.9,369.7,132.4,371.1z"
                />
              </g>
              <g>
                <path
                  d="M166.3,420.2c1,0,2.1,0,3.1-0.2c1-0.2,2.1-0.4,3.1-0.7c2-0.7,4-1.7,5.8-3.3c1.8-1.5,3.3-3.7,4-6.1
				c0.7-2.4,0.7-4.8,0.3-6.9c-0.4-2.1-1.2-4-2.1-5.7c-0.9-1.7-2-3.1-3.3-4.5c-1.3-1.3-2.8-2.4-4.5-3.3c-1.6-0.8-3.6-1.3-5.3-1.4
				c-3.5-0.2-6.9,1.1-9,3.3c-1.1,1.1-1.8,2.4-2.2,3.6c-0.4,1.2-0.5,2.3-0.4,3.3c0.2,2,1.1,3.4,1.9,4c0.8,0.7,1.5,0.7,1.8,0.4
				c0.3-0.3,0.4-0.9,0.5-1.6c0.1-0.7,0.2-1.6,0.7-2.4c0.4-0.9,1.2-1.8,2.1-2.1c0.9-0.4,2.3-0.6,3.6-0.2c1.3,0.4,2.6,1.3,3.9,2.8
				c1.2,1.5,2.3,3.6,2.6,5.5c0.2,0.9,0.1,1.7-0.1,2.3c-0.2,0.6-0.5,1.2-1.1,1.8c-0.6,0.6-1.5,1.2-2.5,1.7c-0.5,0.2-1.1,0.4-1.6,0.6
				c-0.6,0.2-1.1,0.3-1.8,0.4c-2.6,0.3-5.2,0-7.6-0.7c-2.4-0.7-4.7-1.7-6.8-2.9c-4.1-2.4-7.6-5.4-11-8.5c-3.5-3.1-6.9-6.5-11.2-9.7
				c-2.2-1.6-4.5-3.1-7.3-4.5c-2.7-1.3-5.9-2.4-9.3-2.6c3.5,0.5,6.4,1.9,8.9,3.4c2.5,1.5,4.7,3.3,6.5,5.1c3.8,3.6,6.6,7.3,9.5,11
				c3,3.7,6,7.4,10.3,11c2.2,1.8,4.6,3.4,7.6,4.8C158.6,419,162.2,420.1,166.3,420.2z"
                />
              </g>
              <g>
                <path
                  d="M130.6,425.7c-0.7,1.3-1.4,2.2-2.3,3c-0.9,0.7-1.7,1.2-2.5,1.4c-0.6,0.2-1.4,0.2-2.1,0c-0.7-0.2-1.2-0.4-1.5-0.7
				c-0.3-0.1-0.6-0.7-0.7-1.3c-0.1-0.6-0.1-1.3,0-1.9c0.1-0.6,0.3-1.1,0.4-1.6c0.2-0.5,0.3-1,0.2-1.4c-0.1-0.4-0.4-0.8-1.1-1
				c-0.7-0.2-1.8-0.1-2.9,0.5c-1.2,0.6-2.4,1.7-3.2,3.4c-0.8,1.6-1.3,4-0.4,6.6c0.8,2.4,2.7,4.6,5.1,5.9c2.4,1.3,5.3,1.8,8.3,1.2
				c2.9-0.6,5.4-2.2,7.2-4.2c1.8-2,3.1-4.6,3.7-7c0.6-2.5,0.6-4.8,0.4-6.8c-0.2-2-0.6-3.8-1-5.4c-0.9-3.3-2.2-6.1-3.7-8.6
				c-1.5-2.6-3.3-4.9-5.6-7.3c-1.1-1.2-2.4-2.3-3.8-3.5c-1.4-1.1-3.1-2.2-4.9-3.2c1.7,1.2,3.1,2.6,4.3,3.8c1.2,1.3,2.2,2.6,3,3.9
				c1.7,2.6,2.7,5.1,3.5,7.7c0.7,2.6,1.1,5.2,1.1,8C132.2,419.9,131.9,423.1,130.6,425.7z"
                />
              </g>
              <g>
                <path
                  d="M612.6,399.5c-0.7-3.6-1.9-7.1-3.5-10.4c-1.6-3.3-3.6-6.4-5.8-9.1c-2.2-2.7-4.6-4.8-6.8-6.8c-2.2-1.9-4.2-3.6-5.9-5.2
				c-3.5-3.1-6-6-7.6-8.2c-1.7-2.2-2.6-3.7-3.5-3.5c-0.8,0.1-1.5,2-0.9,5.4c0.3,1.6,0.9,3.6,1.9,5.6c1,2,2.4,4.2,4,6.3
				c3.3,4.3,7.7,8.3,11.3,12.7c3.5,4.3,6.4,9.6,8.4,15.4c1,3,1.7,5.9,2.1,8.6c0.4,2.7,0.7,5.4,0.7,7.9c0,5-0.5,9.6-1.6,14.1
				c-1.1,4.5-2.6,9-4.9,13.8c-2.3,4.8-5.4,9.9-9.8,15.6c4.8-5.3,8.4-10.1,11.3-14.7c2.9-4.6,5.2-9,7-13.5c1.8-4.6,3.3-9.3,4.1-14.8
				c0.4-2.8,0.6-5.7,0.6-8.9C613.7,406.6,613.4,403.2,612.6,399.5z"
                />
              </g>
              <g>
                <path
                  d="M580.2,404.8c-0.8-5.7-1-11.4-0.9-16.8c0.1-5.4,0.4-10.6,0.8-15c0.7-8.9,0.9-15.2-0.8-15.7c-1.7-0.4-4.9,5.1-7.5,14.3
				c-1.2,4.6-2.2,10.1-2.4,16.2c-0.2,6,0.4,12.6,2.2,18.9c1.9,6.5,4.3,11.6,7.1,15.9c1.4,2.2,2.8,4.1,4.3,5.9
				c1.4,1.8,2.9,3.4,4.2,5.1c2.6,3.3,5,6.9,5.9,11.6c0.2,1.2,0.4,2.4,0.4,3.7c0,1.3,0,2.6-0.2,4c-0.4,2.7-1.3,5.6-3,8.4
				c1.9-2.7,3.1-5.5,3.8-8.2c0.4-1.4,0.6-2.7,0.7-4.1c0.1-1.3,0.1-2.6,0.1-3.9c-0.1-2.5-0.7-5-1.5-7.2c-0.8-2.2-1.8-4.3-2.9-6.2
				c-2.2-3.9-4.5-7.5-6.2-11.7C582.4,415.5,581,410.7,580.2,404.8z"
                />
              </g>
              <g>
                <path
                  d="M593.6,391.6c-1-7.1-3.4-13.9-5.9-18.7c-2.5-4.8-4.9-7.5-5.7-7.1c-0.8,0.4,0.2,3.8,1.8,8.7c1.6,4.9,3.7,11.4,5.5,17.9
				c1.8,6.8,2.7,11.6,3.3,16.5c0.5,4.9,0.8,9.9,0.1,17.3c1.2-7.3,1.6-12.4,1.8-17.4C594.6,403.9,594.6,398.9,593.6,391.6z"
                />
              </g>
              <g>
                <path
                  d="M588.3,385.7c-0.4-5.3-1.8-10.5-3.4-14.1c-1.6-3.7-3.4-5.8-4.2-5.5c-0.8,0.3-0.6,3,0.2,6.7c0.7,3.8,1.9,8.6,3.1,13.5
				c1.2,5.1,1.9,8.6,2.3,12.2c0.4,3.6,0.7,7.4,0.3,12.8c0.9-5.4,1.3-9.2,1.6-12.9C588.5,394.8,588.7,391.1,588.3,385.7z"
                />
              </g>
              <g>
                <path
                  d="M583.8,385.6c0.2-8.5-1.7-16.9-3.5-16.8c-1.8,0.1-2.4,8.7-0.9,17.1c1.6,8.6,2.6,11.8,3.8,20.8
				C583,397.7,583.5,394.4,583.8,385.6z"
                />
              </g>
              <g>
                <path
                  d="M38.3,418.2c3.6-0.5,7.1-1.2,9.7-2c2.6-0.8,4.4-1.7,4.3-2.5c-0.1-0.9-2-1.5-4.9-1.6c-2.9-0.1-6.7,0.5-10.3,1.9
				c-3.7,1.5-6,2.8-8.4,4.1c-2.3,1.3-4.7,2.7-8,4.8c3.6-1.6,6.2-2.4,8.7-3C32.1,419.3,34.6,418.8,38.3,418.2z"
                />
              </g>
              <g>
                <path
                  d="M37.7,425.6c3.2-0.1,6.2-0.5,8.6-1.1c2.3-0.5,4-1.2,4-2.1c0-0.9-1.6-1.7-4.2-2.1c-2.6-0.4-6-0.1-9.3,0.9
				c-3.3,1.1-5.5,2.1-7.6,3.2c-2.1,1.1-4.2,2.2-7.3,3.9c3.3-1.2,5.6-1.8,7.9-2.1C32.2,425.9,34.5,425.7,37.7,425.6z"
                />
              </g>
              <g>
                <path
                  d="M59.5,432.6c2.4,0.8,4.7,1.4,6.6,1.6c1.9,0.2,3.4,0.1,3.8-0.7c0.4-0.8-0.5-2.1-2.3-3.2c-1.8-1.1-4.5-2-7.3-2
				c-2.9,0-4.8,0.3-6.8,0.7c-1.9,0.4-3.8,0.8-6.5,1.5c2.8-0.2,4.7,0,6.5,0.4C55.3,431.3,57.1,431.8,59.5,432.6z"
                />
              </g>
              <g>
                <path
                  d="M80.6,446.8c4,0.5,8.1-0.5,10.7-1.9c2.7-1.4,4-3.1,3.6-3.8c-0.4-0.8-2.4-0.5-5-0.1c-2.6,0.4-5.9,1-9.4,1.5
				c-3.6,0.4-6.1,0.3-8.7,0c-2.5-0.4-5.2-1.1-8.6-3.1c3.1,2.4,5.6,3.7,8.1,4.9C73.9,445.3,76.5,446.2,80.6,446.8z"
                />
              </g>
              <g>
                <path
                  d="M84.1,455.5c2.2,0,4.3-0.8,5.6-1.8c1.3-1,1.9-2.2,1.5-3c-0.4-0.8-1.6-1-3.1-0.9c-1.5,0.1-3.2,0.6-4.9,1.4
				c-1.8,0.8-3.1,1.4-4.4,1.8c-1.4,0.4-2.8,0.8-4.9,0.8c2.1,0.5,3.5,0.8,5,1.1C80.4,455.3,81.9,455.5,84.1,455.5z"
                />
              </g>
              <g>
                <path
                  d="M-6.8,468.2c-3.8,3.9-6.3,9-7.3,13c-1,4-0.7,6.8,0.2,7c0.9,0.2,2.1-2.2,3.8-5.5c1.7-3.3,4-7.6,6.8-11.7
				c2.9-4.2,5.4-6.9,8.1-9.3c2.8-2.3,5.8-4.6,11-6.6c-5.3,1.5-8.8,3.2-12.1,5C0.3,462-2.9,464.1-6.8,468.2z"
                />
              </g>
              <g>
                <path
                  d="M-0.6,480.6c-1,1.9-1.8,3.9-2.4,5.8c-0.3,1-0.5,1.9-0.7,2.8c-0.2,0.9-0.3,1.9-0.3,2.8c0,0.9,0.1,1.8,0.4,2.6
				c0.3,0.8,0.7,1.5,1.2,1.9c0.5,0.4,1,0.6,1.4,0.6c0.4,0,0.6-0.1,0.7-0.3c0.2-0.4-0.1-1,0-1.6c0-0.6,0.1-1.5,0.5-2.7
				c0.2-0.6,0.4-1.2,0.7-2.1c0.3-0.8,0.6-1.7,0.8-2.5c0.6-1.8,1.1-3.7,1.7-5.6c1.1-4,2.1-6.7,3.2-9.3c1.2-2.7,2.5-5.3,5-8.9
				c-2.9,3.2-4.8,5.6-6.7,7.9C3.3,474.3,1.5,476.7-0.6,480.6z"
                />
              </g>
              <g>
                <path
                  d="M7.5,492.9c-0.1,2.4,0.6,4.8,1.6,6.4c1,1.6,2.2,2.3,3,2c0.8-0.4,1.1-1.7,1-3.3c-0.1-1.6-0.5-3.6-1.2-5.6
				c-0.7-2.1-1.2-3.6-1.5-5.1c-0.3-1.6-0.5-3.2-0.3-5.6c-0.7,2.3-1.2,3.9-1.6,5.5C8.1,488.7,7.7,490.4,7.5,492.9z"
                />
              </g>
              <g>
                <path
                  d="M19.5,498.4c0.8,2,2.3,3.6,3.7,4.4c1.4,0.8,2.7,0.9,3.3,0.2c0.6-0.7,0.3-1.9-0.3-3.1c-0.6-1.3-1.7-2.7-3-3.9
				c-1.4-1.3-2.4-2.3-3.2-3.3c-0.9-1.1-1.7-2.2-2.5-4.2c0.3,2.1,0.5,3.5,0.7,4.9C18.5,494.9,18.7,496.4,19.5,498.4z"
                />
              </g>
              <g>
                <path
                  d="M36.3,500.3c1.6,0.8,3.4,0.8,4.7,0.4c1.3-0.4,2-1.3,2-2.2c-0.1-0.9-0.9-1.6-1.9-2c-1.1-0.5-2.4-0.7-3.8-0.5
				c-1.5,0.2-2.6,0.2-3.7,0.2c-1.1,0-2.3-0.2-3.8-0.8c1.1,0.9,2.1,1.7,3.2,2.6C33.7,498.7,34.7,499.5,36.3,500.3z"
                />
              </g>
              <g>
                <path
                  d="M173.3,453.4c1.6-3.6,2.3-7.6,2.2-11.5c-0.1-3.9-1-7.8-2.9-11.2c-1-1.7-2.2-3.2-3.6-4.5c-1.5-1.3-3.2-2.3-5.1-2.8
				c-3.8-1.1-7.5-0.3-10.2,1.3c-2.8,1.6-4.7,4.3-5.4,6.7c-0.8,2.5-0.6,4.7-0.2,6.4c0.5,1.7,1.2,2.9,1.8,3.6c0.6,0.7,1.1,0.9,1.2,0.8
				c0.4-0.3-0.5-1.9-0.7-4.8c0-1.4,0.2-3.2,1-4.9c0.8-1.7,2.2-3.3,4.2-4.2c2-1,4.6-1.3,6.8-0.5c2.3,0.8,4.4,2.7,5.8,5.3
				c1.4,2.6,2.2,5.7,2.4,8.9c0.2,3.2-0.3,6.6-1.4,9.7c-1.2,3.2-3,6-4.8,8.5c-1.9,2.5-3.8,4.8-5.6,7.1c-0.9,1.2-1.7,2.3-2.5,3.5
				c-0.4,0.6-0.7,1.2-1.1,1.8l-0.5,0.9l-0.5,0.9c-0.3,0.6-0.6,1.2-0.9,1.8c-0.3,0.6-0.5,1.2-0.8,1.8c-0.6,1.2-0.9,2.5-1.4,3.7
				c-0.8,2.5-1.4,5.1-1.8,7.8c-0.2,1.3-0.3,2.7-0.4,4.1c0,0.7,0,1.4,0,2.1c0,0.7,0,1.4,0,2.2c0.1,2.9,0.5,6,1.3,9.3
				c0.8,3.2,2,6.6,3.8,10c-1.7-3.5-2.7-6.9-3.4-10.1c-0.6-3.2-0.9-6.3-0.8-9.2c0-0.7,0-1.4,0.1-2.1c0.1-0.7,0.1-1.4,0.2-2.1
				c0.2-1.4,0.3-2.7,0.6-4c0.5-2.6,1.3-5,2.2-7.4c1.9-4.7,4.5-9,8.3-13.1c1.9-2.1,4-4.2,6.1-6.7C169.4,460,171.7,457.1,173.3,453.4z
				"
                />
              </g>
              <g>
                <path
                  d="M154.7,445.2c-2.1,1.9-3.9,4-5.1,5.8c-1.2,1.8-1.8,3.3-1.2,4c0.6,0.7,2.2,0.3,4.2-0.9c1.9-1.2,4.1-3.4,5.6-6.1
				c0.8-1.4,1.3-2.5,1.8-3.6c0.5-1.1,0.9-2.1,1.2-3.2c0.3-1.1,0.5-2.2,0.4-3.5c-0.1-1.2-0.5-2.6-1.7-3.7c0.9,1.3,1,2.6,0.8,3.7
				c-0.2,1.1-0.7,2-1.2,2.7c-0.6,0.8-1.2,1.5-2,2.3C156.6,443.3,155.7,444.2,154.7,445.2z"
                />
              </g>
              <g>
                <path
                  d="M157.3,453.9c-1.7,1.7-3.1,3.6-4,5.2c-0.9,1.6-1.2,3-0.6,3.7c0.6,0.6,2.1,0.3,3.7-0.9c1.6-1.1,3.4-3.1,4.4-5.5
				c1.1-2.4,1.7-4.2,2.2-6c0.6-1.9,1.1-3.7,1.8-6c-1.2,2.3-2.3,3.8-3.5,5.1C160.3,450.9,159.1,452.2,157.3,453.9z"
                />
              </g>
              <g>
                <path
                  d="M187.3,434.6c0.8-2.6,0.7-5.4,0.1-7.4c-0.6-2-1.7-3.1-2.5-2.9c-0.9,0.2-1.4,1.5-1.6,3.3c-0.3,1.8-0.4,4-0.3,6.3
				c0.1,2.4,0,4.2-0.2,5.9c-0.3,1.7-0.7,3.5-1.9,5.9c1.6-2.1,2.7-3.7,3.6-5.2C185.5,438.9,186.5,437.3,187.3,434.6z"
                />
              </g>
              <g>
                <path
                  d="M196,440.1c1.4-0.9,2.5-2.1,3.2-3.2c0.7-1.1,1-2.2,0.6-3c-0.5-0.7-1.7-0.9-3.1-0.4c-1.4,0.5-3,1.8-4,3.6
				c-1,1.8-1.4,3.2-1.8,4.5c-0.4,1.3-0.7,2.6-1.2,4.5c1-1.7,1.9-2.6,2.8-3.5C193.5,441.8,194.5,441.1,196,440.1z"
                />
              </g>
              <g>
                <path
                  d="M204.1,446c1.7-0.2,3.1-0.8,4.2-1.5c1.1-0.7,1.8-1.6,1.7-2.5c-0.1-0.9-1.2-1.5-2.7-1.6c-1.5-0.1-3.4,0.4-5,1.6
				c-1.6,1.2-2.5,2.2-3.5,3.2c-1,1.1-1.9,2.2-3,3.4c1.6-1,2.8-1.5,4-1.8C201.1,446.4,202.3,446.2,204.1,446z"
                />
              </g>
              <g>
                <path
                  d="M255.9,442.9c-2.8,2-5.5,3.9-7.6,5.6c-2,1.6-3.4,3-3,3.8c0.3,0.8,2.4,0.8,5.1-0.2c2.7-1,6-3.1,8.4-6
				c2.5-3,3.9-5.3,5.2-7.6c1.2-2.3,2.4-4.7,3.8-8.3c-1.9,3.4-3.5,5.4-5.3,7.2C260.7,439.2,258.8,440.8,255.9,442.9z"
                />
              </g>
              <g>
                <path
                  d="M258,452.6c-2.4,3.6-3.9,7.7-4.5,10.9c-0.5,3.2-0.2,5.5,0.6,5.6c0.9,0.2,2-1.7,3.4-4.4c1.3-2.7,2.9-6.4,4.3-10.1
				c1.5-3.8,2.8-6.4,4.3-9c1.5-2.5,3.1-5,6.1-8.3c-3.4,2.9-5.5,5-7.6,7.1C262.6,446.7,260.5,448.9,258,452.6z"
                />
              </g>
              <g>
                <path
                  d="M264.1,459.8c-1.9,3.8-3,8-3.2,11.2c-0.3,3.2,0.2,5.4,1,5.5c0.9,0.1,1.9-1.8,3-4.6c1.1-2.9,2.3-6.7,3.3-10.6
				c1-4,1.9-6.8,2.9-9.6c1-2.8,2.2-5.5,4.4-9.3c-2.7,3.5-4.4,6-6.1,8.5C267.7,453.4,266.1,455.9,264.1,459.8z"
                />
              </g>
              <g>
                <path
                  d="M266.4,475.3c-1.1,3.9-1.3,8-0.8,11c0.4,3,1.4,5,2.2,4.9c0.9-0.1,1.5-2,2-4.9c0.5-2.8,0.8-6.6,1-10.3
				c0.2-3.9,0.5-6.6,0.9-9.3c0.5-2.7,1.1-5.5,2.6-9.4c-2,3.6-3.2,6.2-4.4,8.8C268.6,468.7,267.5,471.3,266.4,475.3z"
                />
              </g>
              <g>
                <path
                  d="M332.1,491.8c-2.3,0.4-4.4,1.4-5.7,2.6c-1.3,1.2-1.9,2.4-1.4,3.2c0.5,0.8,1.8,0.9,3.4,0.5c1.6-0.3,3.5-1.1,5.3-2.2
				c1.9-1.2,3.2-2,4.6-2.7c1.4-0.7,2.9-1.4,5.1-2c-2.1,0-3.8,0.1-5.6,0.1C336.1,491.3,334.5,491.4,332.1,491.8z"
                />
              </g>
              <g>
                <path
                  d="M338.2,497.8c-2,0.5-2.5,2.8-1.3,4.1c1.2,1.3,3.4,1.1,4.2-0.8c0.8-1.9,0.9-3,2-4.7C341.2,497.3,340.2,497.3,338.2,497.8z
				"
                />
              </g>
              <g>
                <path d="M432.9,506.6c-3.6,0.1-6.5,2.6-5.9,4.2c0.7,1.6,4.1,1.7,7,0c3-1.8,4.1-2.8,7.6-4C438.1,507,436.6,506.6,432.9,506.6z" />
              </g>
              <g>
                <path
                  d="M152,526.9c-2.4-3.5-5.6-6.8-9.9-9.1c-2.1-1.1-4.4-2-6.8-2.5c-2.4-0.5-5-0.6-7.6-0.1c-5.2,0.9-9.7,3.7-12.6,7.9
				c-0.7,1.1-1.3,2.2-1.8,3.4c-0.5,1.2-0.8,2.5-0.9,3.7c-0.3,2.4,0,4.7,0.7,6.8c0.7,2.1,1.8,4,3.3,5.5c1.5,1.5,3.3,2.6,5,3.2
				c3.6,1.1,6.6,0.4,8.6-0.7c2.1-1.1,3.2-2.7,3.5-3.9c0.2-0.6,0.2-1.1,0.1-1.5c-0.1-0.4-0.3-0.7-0.6-0.8c-0.3-0.2-0.6-0.3-1-0.3
				c-0.4,0-0.8,0-1.2-0.1c-0.8,0-1.8-0.1-2.7-0.4c-0.9-0.3-2-0.9-2.4-1.5c-0.2-0.3-0.3-0.7-0.4-1.1c-0.1-0.4-0.1-0.8-0.1-1.4
				c0-0.5,0.2-1,0.4-1.4c0.1-0.2,0.2-0.4,0.3-0.5c0.1-0.2,0.3-0.4,0.5-0.5c0.4-0.4,0.9-0.7,1.5-1c0.6-0.3,1.3-0.5,2-0.6
				c1.4-0.2,3.4,0.1,5.5,0.9c2,0.8,4.2,2.4,6.2,4.4c1.1,1,2.1,2.2,3,3.4c0.9,1.2,1.8,2.3,2.6,3.5c1.5,2.4,2.8,4.7,3.8,7.1
				c2,4.7,3.3,9.3,3.7,14.1c0.5,4.8,0.2,9.7-1.1,15.2c-1.3,5.4-3.8,11.3-8.4,17.4c5.2-5.5,8.6-11.1,10.9-16.5
				c2.3-5.4,3.7-10.6,4.3-15.9c0.6-5.3,0.5-10.8-0.6-16.8c-0.6-3-1.4-6.1-2.7-9.4c-0.7-1.6-1.4-3.3-2.3-5
				C154.2,530.4,153.2,528.7,152,526.9z"
                />
              </g>
              <g>
                <path
                  d="M180.5,567.9c3.9-1.9,7.3-4.1,9.8-6.3c2.5-2.1,4.1-4.2,3.6-5.9c-0.5-1.6-3.3-2.4-7.1-1.7c-3.7,0.7-8.4,3.2-11.7,7
				c-3.4,3.9-5.2,7-6.8,10c-1.6,3.1-3.1,6.2-4.9,10.7c2.8-4.1,5.3-6.3,7.8-8.3C173.8,571.6,176.5,569.9,180.5,567.9z"
                />
              </g>
              <g>
                <path
                  d="M174.5,542.4c0.4-3.8-1-7.4-2.8-9.6c-1.9-2.2-4.1-3-5.6-2.2c-1.6,0.9-2.2,2.9-2.2,5.3c0,2.4,0.6,5.1,1.9,7.8
				c1.3,2.9,2.2,5,2.8,7.3c0.6,2.3,1,4.7,0.6,8.2c1.4-3.3,2.3-5.6,3.2-8C173.2,548.8,174.1,546.3,174.5,542.4z"
                />
              </g>
              <g>
                <path
                  d="M59.8,573.1c-3.7,3.4-7.3,6.6-11.1,9.2c-3.7,2.5-7.5,4.5-10.6,4.9c-3.2,0.5-5.8-0.6-7.3-1.7c-1.6-1.1-2.1-2.3-2.6-2.2
				c-0.2,0.1-0.3,0.4-0.3,1.1c0.1,0.7,0.4,1.7,1.1,2.7c0.7,1.1,1.9,2.2,3.5,3c1.6,0.8,3.7,1.3,5.9,1.3c2.2-0.1,4.5-0.6,6.7-1.5
				c2.2-0.9,4.3-2.1,6.4-3.4c4.2-2.8,8-6.4,11.4-10.2c3.5-3.9,6.3-7.3,8.9-10.2c2.6-2.9,5.1-5.4,7.9-7.7c2.7-2.2,5.7-4.2,9.4-5.8
				c3.7-1.5,8.2-2.7,13.6-2c-5.3-1-10-0.1-13.9,1.2c-3.9,1.3-7.2,3.1-10.3,5.1c-3.1,2-5.9,4.3-8.9,7
				C66.7,566.4,63.5,569.6,59.8,573.1z"
                />
              </g>
              <g>
                <path
                  d="M73.8,568.7c-1.9,3-3.2,6.4-4,9.7c-0.8,3.3-0.9,6.4-0.8,9.1c0.4,5.4,2.1,8.6,2.9,8.4c0.9-0.2,0.8-3.6,1.3-8.4
				c0.3-2.4,0.7-5.1,1.4-8c0.7-2.9,1.7-5.9,3-8.8c1.4-3,2.8-5.4,4.4-7.4c1.6-2,3.2-3.7,4.9-5.3c1.8-1.5,3.7-3,6-4.3
				c2.3-1.3,5.1-2.5,8.6-3.4c-3.5,0.6-6.4,1.6-9,2.6c-2.5,1.1-4.7,2.2-6.8,3.5c-2.1,1.3-4,2.8-6.1,4.7
				C77.8,563.3,75.8,565.6,73.8,568.7z"
                />
              </g>
              <g>
                <path
                  d="M82.3,565.7c-1.2,2.3-2,4.8-2.4,7.2c-0.4,2.4-0.3,4.7,0,6.6c0.6,3.9,2.2,6.1,3,5.9c0.9-0.2,0.9-2.7,1.2-6
				c0.1-1.7,0.4-3.6,0.7-5.6c0.4-2,0.9-4.2,1.5-6.3c0.7-2.2,1.5-4,2.4-5.5c0.9-1.6,1.8-2.9,2.9-4.2c1.1-1.3,2.3-2.5,3.8-3.7
				c1.5-1.2,3.3-2.3,5.7-3.3c-2.4,0.8-4.4,1.7-6.1,2.6c-1.7,0.9-3.2,1.9-4.6,3c-1.4,1.1-2.7,2.3-4.1,3.8
				C84.9,561.6,83.5,563.4,82.3,565.7z"
                />
              </g>
              <g>
                <path
                  d="M126.8,555.4c3.6,0.7,7.5,0,9.9-1.3c2.5-1.3,3.7-2.8,3.3-3.6c-0.4-0.8-2.2-0.7-4.6-0.4c-2.4,0.3-5.3,0.6-8.5,0.9
				c-3.2,0.2-5.5,0-7.8-0.4c-2.3-0.5-4.6-1.3-7.6-3.4c2.6,2.5,4.7,3.9,6.9,5.1C120.8,553.5,123.1,554.6,126.8,555.4z"
                />
              </g>
              <g>
                <path
                  d="M138.6,563.1c1.7,1.4,3.4,2.4,4.9,3.1c1.5,0.7,2.8,0.9,3.4,0.3c0.6-0.6,0.4-2-0.8-3.5c-1.1-1.5-3.1-3.1-5.5-3.8
				c-2.4-0.7-4.2-0.9-5.9-1c-1.7-0.1-3.4-0.2-5.8-0.2c2.4,0.5,3.9,1.2,5.3,2C135.6,560.8,136.9,561.7,138.6,563.1z"
                />
              </g>
              <g>
                <path
                  d="M141,578.8c0.3,2.1,1,4.1,1.8,5.5c0.8,1.5,1.7,2.4,2.6,2.3c0.9-0.1,1.5-1.4,1.5-3.2c0.1-1.8-0.5-4.2-1.8-6.2
				c-1.3-2.1-2.4-3.4-3.5-4.6c-1.1-1.2-2.2-2.5-3.8-4.2c1.2,2.1,1.7,3.6,2.2,5.2C140.4,575.1,140.7,576.6,141,578.8z"
                />
              </g>
              <g>
                <path
                  d="M143.4,505.7c0.1-2,0.1-3.7-0.1-5.2c-0.2-1.5-0.5-2.7-1.3-3c-0.8-0.3-2,0.5-2.8,2.3c-0.8,1.7-1,4.4-0.2,6.7
				c0.9,2.4,1.9,3.8,3,5.1c1.1,1.3,2.2,2.5,4,4c-1.5-1.8-2-3.4-2.3-4.8C143.4,509.2,143.3,507.7,143.4,505.7z"
                />
              </g>
              <g>
                <path
                  d="M158.8,498.6c0.9-1.8,1.7-3.4,2.3-4.9c0.6-1.4,0.9-2.7,0.4-3.4c-0.5-0.7-2.1-0.5-3.6,0.8c-1.6,1.3-3.1,3.7-3.3,6.4
				c-0.2,2.8,0.3,4.6,0.9,6.3c0.6,1.7,1.4,3.3,2.8,5.3c-1-2.3-1.1-4-0.9-5.5C157.5,501.9,157.9,500.4,158.8,498.6z"
                />
              </g>
              <g>
                <path
                  d="M187.9,576c2.7-0.4,5.2-1.1,7.1-1.9c1.9-0.8,3.2-1.7,3.1-2.6c-0.1-0.9-1.6-1.5-3.8-1.5c-2.2,0-5.1,0.5-7.7,1.8
				c-2.7,1.3-4.4,2.5-6.1,3.6c-1.8,1.2-3.5,2.4-5.8,4c2.7-1.2,4.6-1.8,6.5-2.3C183.2,576.8,185.2,576.4,187.9,576z"
                />
              </g>
              <g>
                <path
                  d="M149,392.3c3,0.1,5.8-1,7.6-2.1c1.8-1.1,2.7-2.4,2.4-3.2c-0.3-0.8-1.9-1-3.9-0.7c-2,0.3-4.4,1-6.7,1.7
				c-2.4,0.8-4.2,1-6,0.9c-1.8-0.1-3.8-0.5-5.9-2.5c1.7,2.3,3.6,3.4,5.4,4.3C143.8,391.6,145.9,392.2,149,392.3z"
                />
              </g>
              <g>
                <path
                  d="M134,321c-1.8,2.8-3.2,6-3.8,9.2c-0.6,3.2-0.5,6.3,0.2,8.9c0.7,2.6,1.8,4.5,2.9,5.6c0.5,0.6,1,0.9,1.4,1.1
				c0.4,0.2,0.7,0.2,0.8,0.1c0.7-0.6-0.7-3.3-1-7.6c-0.1-2.1,0-4.6,0.5-7.2c0.5-2.6,1.5-5.3,2.8-8c1.3-2.8,2.7-5.1,4-7.1
				c1.3-2.1,2.6-3.9,3.9-5.7c1.3-1.9,2.6-3.7,4-5.8c1.4-2.1,2.9-4.5,4.3-7.6c-1.6,2.9-3.4,5.1-5,7.1c-1.6,1.9-3.2,3.5-4.8,5.2
				c-1.6,1.6-3.1,3.2-4.8,5.1C137.7,315.9,135.8,318.1,134,321z"
                />
              </g>
              <g>
                <path
                  d="M143.2,322.4c-0.7,5.5,0.4,11.2,2,15.1c1.6,3.9,3.6,6,4.4,5.6c0.8-0.4,0.3-3.1-0.4-6.8c-0.7-3.8-1.4-8.7-1.7-13.7
				c-0.2-5.2,0.2-8.9,1-12.5c0.8-3.6,2-7.3,5-12.1c-3.4,4.5-5.2,8-6.8,11.5C145.2,313,143.9,316.7,143.2,322.4z"
                />
              </g>
              <g>
                <path
                  d="M149.7,316.6c0,4,1.3,7.8,2.8,10.4c1.5,2.6,3.2,3.9,3.9,3.4c0.8-0.4,0.5-2.4-0.1-5c-0.6-2.6-1.5-6-2.3-9.4
				c-0.9-3.5-1.2-6.1-1.4-8.7c-0.1-2.6,0-5.3,1-9.2c-1.5,3.7-2.3,6.3-2.9,9C150.2,309.8,149.7,312.5,149.7,316.6z"
                />
              </g>
              <g>
                <path
                  d="M93.8,341.5c3.2-2.6,5.7-6,7.1-8.8c1.4-2.8,1.7-5,0.9-5.4c-0.8-0.4-2.4,0.9-4.4,3c-2,2.1-4.4,5-6.8,8.1
				c-2.4,3.1-4.3,5.2-6.3,7.2c-2,2-4.2,3.8-7.8,6.1c3.8-1.8,6.4-3.2,8.9-4.6C88,345.7,90.5,344.2,93.8,341.5z"
                />
              </g>
              <g>
                <path
                  d="M102.3,347.6c2.7-0.3,5.3-1,7.2-1.7c1.9-0.8,3.2-1.7,3.1-2.5c-0.1-0.9-1.6-1.5-3.8-1.6c-2.2-0.1-5.1,0.4-7.7,1.7
				c-2.7,1.3-4.4,2.4-6.2,3.5c-1.8,1.2-3.6,2.3-5.9,3.8c2.7-1.2,4.7-1.7,6.6-2.1C97.6,348.2,99.5,347.9,102.3,347.6z"
                />
              </g>
              <g>
                <path
                  d="M119.4,246.7c1.9-1.6,3.5-3.5,4.5-5.2c1-1.6,1.4-3,0.8-3.7c-0.6-0.6-2.1-0.3-3.8,0.8c-1.7,1.1-3.6,3-4.9,5.4
				c-1.3,2.4-2,4.2-2.8,5.9c-0.8,1.9-1.5,3.7-2.5,6c1.5-2.3,2.8-3.7,4.1-5C116.1,249.6,117.4,248.4,119.4,246.7z"
                />
              </g>
              <g>
                <path
                  d="M20.7,384.4c-7.8,3.2-14.9,7.9-19.7,12.1c-4.8,4.2-7.2,7.7-6.7,8.3c0.6,0.7,4.1-1.6,9.2-5c5.1-3.3,11.9-7.6,19-11.4
				c7.3-3.9,12.7-6.1,18.2-8c5.5-1.8,11.3-3.3,20-4.3c-8.8,0.4-14.7,1.3-20.5,2.4C34.5,379.7,28.7,381.1,20.7,384.4z"
                />
              </g>
              <g>
                <path
                  d="M27.1,386.9c-6.2,3.1-11.7,7.5-15.3,11.3c-3.6,3.8-5.4,6.9-4.8,7.4c0.7,0.6,3.5-1.3,7.5-4.3c4-3,9.3-6.9,14.8-10.6
				c5.7-3.8,9.8-6.1,14.1-8.2c4.3-2,8.9-3.9,15.8-5.7c-7.1,1.2-11.9,2.5-16.5,3.9C38.1,382.1,33.5,383.7,27.1,386.9z"
                />
              </g>
              <g>
                <path
                  d="M-19.8,372.5c-3.2-2.4-6.3-4.7-8.9-6.3c-2.5-1.6-4.5-2.5-5.1-1.9c-0.6,0.6,0.3,2.7,2.4,5.1c2.1,2.4,5.5,5.1,9.3,6.9
				c4,1.8,6.8,2.7,9.6,3.6c2.9,0.8,5.7,1.6,10,2.5c-4.1-1.4-6.8-2.8-9.3-4.2C-14.2,376.7-16.6,375-19.8,372.5z"
                />
              </g>
              <g>
                <path
                  d="M-14.5,384.4c-0.9,0-1.8,0.2-2.7,0.6c-0.9,0.3-1.9,1-2.5,2c-0.6,1-0.6,2.1-0.3,2.7c0.3,0.7,0.9,0.8,1.3,0.7
				c0.4-0.1,0.8-0.4,1.2-0.5c0.4-0.1,0.7-0.1,1-0.1c0.2,0.1,0.6,0,1.2-0.2c0.7-0.2,1.4-0.5,2.1-1c1.4-0.9,2.5-1.5,3.6-2
				c1.1-0.5,2.3-0.9,4.1-1.2c-1.6-0.2-2.9-0.5-4.4-0.7C-11.3,384.6-12.6,384.4-14.5,384.4z"
                />
              </g>
              <g>
                <path
                  d="M-13.6,395.2c-2.1,1.6-3.6,3.7-4.4,5.6c-0.7,1.8-0.7,3.3,0,3.8c0.8,0.5,2-0.2,3.4-1.4c1.4-1.2,2.9-3,4.1-5
				c1.3-2.1,2.3-3.5,3.4-4.9c1.1-1.4,2.3-2.7,4.3-4.4c-2.3,1.2-4,2-5.6,2.9C-9.8,392.7-11.4,393.6-13.6,395.2z"
                />
              </g>
              <g>
                <path d="M-9.5,421.5c-1.3,2.9-0.3,6,1.4,6.3c1.7,0.2,3.5-2.6,2.9-5.7c-0.5-3.2-1.1-4.4-1.2-7.7C-7.4,417.5-8.2,418.6-9.5,421.5z" />
              </g>
              <g>
                <path d="M-2.1,415.6c-1.2,2.5-0.2,5.3,1.5,5.5c1.7,0.2,3.4-2.2,2.8-4.9c-0.6-2.8-1.2-3.9-1.4-6.7C0,412.2-0.9,413.1-2.1,415.6z" />
              </g>
              <g>
                <path
                  d="M578.1,305.9c3.3-4.2,6.4-8.7,8.9-13.6c2.5-4.9,4.2-10.3,4.9-15.7c0.7-5.4,0.4-10.9-1.2-15.8c-0.4-1.2-0.8-2.4-1.3-3.6
				c-0.5-1.2-1.1-2.3-1.8-3.4c-1.4-2.2-3.1-4.2-5.3-5.7c-1.1-0.8-2.3-1.3-3.6-1.8c-1.3-0.4-2.7-0.6-4-0.5c-2.5,0.1-4.9,0.9-6.8,2.5
				c-0.9,0.8-1.7,1.7-2.3,2.7c-0.6,1-0.8,2.1-1,3.1c-0.2,2,0.2,3.7,0.7,5c1.1,2.5,2.5,3.9,3.5,4.8c1,0.9,1.7,1.1,2.1,0.9
				c0.4-0.2,0.3-1,0.1-2.2c-0.2-1.2-0.8-2.9-1-4.7c-0.1-0.9,0-1.8,0.3-2.5c0.2-0.3,0.4-0.7,0.6-0.9c0.2-0.2,0.5-0.4,0.9-0.6
				c0.7-0.3,1.7-0.5,2.7-0.3c0.5,0.1,1,0.2,1.4,0.4c0.4,0.2,0.9,0.6,1.4,1c0.9,0.8,1.8,2,2.5,3.4c0.7,1.4,1.3,3.2,1.8,5
				c0.9,3.6,1,7.7,0.4,12c-0.6,4.2-1.9,8.6-3.8,12.8c-1.9,4.3-4.4,8.5-7.2,12.6c-2.9,4.3-5.8,8.2-8.5,11.9
				c-2.7,3.7-5.2,7.3-7.5,10.8c-2.2,3.5-4.2,7-5.8,10.6c-1.6,3.6-2.8,7.2-3.7,10.9c-1.7,7.4-2.1,15.1-0.8,23.5
				c0.6,4.2,1.7,8.5,3.4,13c1.7,4.4,4,9,7.3,13.5c-3-4.6-5.1-9.3-6.5-13.7c-1.4-4.5-2.1-8.8-2.4-12.9c-0.6-8.2,0.5-15.5,2.8-22.3
				c1.2-3.4,2.6-6.6,4.4-9.7c1.8-3.1,4-6.2,6.4-9.3c2.4-3.1,5.2-6.3,8.2-9.7C571.4,313.8,574.7,310.2,578.1,305.9z"
                />
              </g>
              <g>
                <path
                  d="M561.9,353.7c1-1.7,2-3.3,3.1-4.8c1.1-1.5,2.2-2.8,3.4-4.1c1.1-1.3,2.4-2.4,3.5-3.4c0.3-0.2,0.6-0.5,0.9-0.7
				c0.3-0.2,0.5-0.4,0.8-0.6c0.5-0.4,1.1-0.7,1.6-1c2.2-1.2,4-1.8,5.4-2c1.5-0.3,2.7-0.1,3.3-0.7c0.3-0.3,0.4-0.8,0.2-1.5
				c-0.2-0.7-0.8-1.5-1.9-2.3c-1-0.8-2.5-1.4-4.3-1.6c-1.7-0.2-3.7-0.1-5.6,0.3c-1,0.2-2,0.6-3,1c-0.5,0.2-1,0.5-1.5,0.7
				c-0.5,0.3-0.9,0.5-1.4,0.8c-1.8,1.1-3.7,2.5-5.3,4.2c-1.6,1.7-3.1,3.6-4.4,5.6c-1.2,2.1-2.2,4.3-3,6.5c-1.6,4.7-2.1,8.7-2.3,12.3
				c-0.2,3.6,0,6.8,0.3,9.9c0.7,6.3,1.9,12.4,3.8,21.4c-0.9-9.1-0.8-15.4,0-21.4c0.4-3,1-5.9,1.9-9
				C558.6,360.4,559.9,357.1,561.9,353.7z"
                />
              </g>
              <g>
                <path
                  d="M574.5,279.9c-0.6-1.9-1.5-3.7-2.6-5.3c-0.6-0.8-1.2-1.6-1.9-2.3c-0.7-0.7-1.5-1.3-2.3-1.9c-1.7-1.1-3.9-1.8-6.2-1.5
				c-1.1,0.2-2.1,0.5-3,1.1c-0.9,0.6-1.6,1.4-2.1,2.4c-0.4,0.9-0.6,1.9-0.6,2.8c0,0.9,0.2,1.6,0.5,2.3c0.5,1.3,1.2,2.1,1.8,2.7
				c1.2,1.2,2.1,1.6,2.4,1.4c0.4-0.3,0.1-1.2-0.4-2.6c-0.2-0.7-0.5-1.5-0.6-2.4c-0.1-0.9,0.1-1.5,0.5-2c0.4-0.4,1.1-0.8,2-0.9
				c0.8-0.1,1.9,0.3,2.9,1c1.1,0.7,2.1,1.8,3.1,2.9c0.9,1.2,1.7,2.5,2.4,3.9c1.3,3,1.8,5.9,1.9,8.5c0.1,2.6-0.2,5.1-0.8,7.5
				c-1.1,4.8-3.2,9.6-6.8,16.2c4.1-6.4,6.7-10.8,8.6-15.6c0.9-2.4,1.7-4.9,2-7.9C575.6,287.2,575.6,283.8,574.5,279.9z"
                />
              </g>
              <g>
                <path
                  d="M568.9,290c-0.3-0.5-0.5-1.1-0.8-1.6c-0.3-0.5-0.7-1.1-1.2-1.5c-0.9-1-1.9-1.8-3.4-2.3c-0.7-0.3-1.5-0.4-2.3-0.4
				c-0.8,0-1.6,0.3-2.3,0.6c-1.4,0.7-2.2,1.8-2.6,2.8c-0.5,1-0.6,2-0.6,2.7c0,0.8,0.1,1.4,0.3,1.9c0.3,1,0.7,1.5,1.2,1.5
				c0.4,0,0.9-0.5,1.3-1.4c0.4-0.8,0.9-2,1.5-2.7c0.3-0.4,0.7-0.6,1-0.7c0.3,0,0.5,0,0.9,0.1c0.4,0.2,1,0.5,1.6,1.1
				c0.3,0.3,0.6,0.5,0.8,0.9c0.3,0.3,0.5,0.7,0.8,1.1c1.1,1.5,1.8,3,2.3,4.5c0.5,1.4,0.8,2.8,0.9,4.2c0.1,1.4,0.1,2.9-0.3,4.5
				c-0.3,1.6-0.9,3.4-2.1,5.3c1.4-1.7,2.3-3.4,3-5c0.7-1.6,1.1-3.1,1.3-4.7c0.2-1.6,0.4-3.1,0.2-4.9
				C570.3,294.2,569.9,292.2,568.9,290z"
                />
              </g>
              <g>
                <path
                  d="M548.8,301.3c-1.9-0.3-3.8-0.3-5.2,0c-1.5,0.3-2.6,0.9-2.7,1.8c-0.2,0.9,0.7,1.8,2.3,2.4c1.6,0.6,3.8,0.8,5.9,0.2
				c2.2-0.6,3.6-1.2,5-1.9c1.5-0.7,2.9-1.4,4.7-2.2c-2.1,0.4-3.6,0.5-5.1,0.4C552.1,301.9,550.7,301.7,548.8,301.3z"
                />
              </g>
              <g>
                <path
                  d="M536.3,308.9c-5.8,0.2-11.1,2.8-10.6,4.5c0.4,1.7,6.2,1.7,11.5-0.2c5.5-1.9,7.5-3,13.4-4.3
				C544.5,309.2,542.3,308.8,536.3,308.9z"
                />
              </g>
              <g>
                <path
                  d="M525.6,316.8c-3.9-0.7-7.6,0.8-7.6,2.5c0.1,1.8,4,2.9,7.8,1.8c3.9-1.1,5.3-1.9,9.3-2.6
				C531,318.2,529.6,317.5,525.6,316.8z"
                />
              </g>
              <g>
                <path
                  d="M537.6,319c-2.2,1.1-3.9,2.9-4.8,4.6c-0.9,1.6-1.1,3.1-0.4,3.6c0.7,0.6,2,0.2,3.4-0.7c1.5-0.9,3.1-2.3,4.5-4
				c1.5-1.7,2.6-2.9,3.8-4.1c1.2-1.1,2.5-2.2,4.6-3.4c-2.4,0.7-4,1.3-5.6,1.8C541.5,317.3,539.9,317.8,537.6,319z"
                />
              </g>
              <g>
                <path
                  d="M545.3,322.1c-1.5,1.4-2.5,3.1-2.9,4.6c-0.4,1.5-0.2,2.7,0.6,3.2c0.8,0.4,1.9,0,3-1c1.1-1,2.2-2.5,2.9-4.3
				c1.5-3.7,1.8-5.3,4.1-8.8C549.9,318.6,548.4,319.3,545.3,322.1z"
                />
              </g>
              <g>
                <path
                  d="M554.7,308c-1.3,0.9-2.1,2.2-2.4,3.4c-0.3,1.2,0,2.2,0.7,2.7c0.7,0.5,1.8,0.3,2.7-0.3c1-0.6,1.9-1.7,2.4-3
				c1.1-2.8,1.3-4.1,3.2-6.7C558.7,305.8,557.4,306.2,554.7,308z"
                />
              </g>
              <g>
                <path
                  d="M532.3,341c-1.2,1.5-1.8,3.3-1.8,4.8c0,1.5,0.5,2.5,1.4,2.7c0.9,0.2,1.8-0.4,2.6-1.5c0.8-1,1.5-2.5,1.8-4.2
				c0.3-1.7,0.5-3,0.9-4.2c0.3-1.2,0.8-2.4,1.7-4.1c-1.4,1.3-2.3,2.2-3.3,3.1C534.5,338.6,533.5,339.5,532.3,341z"
                />
              </g>
              <g>
                <path
                  d="M533.2,356.5c-2.1,3.9-1.8,8.5-0.1,8.9c1.7,0.4,3.9-3.4,4.3-7.6c0.4-4.4,0.2-6.1,1.4-10.5
				C536.7,351.3,535.4,352.5,533.2,356.5z"
                />
              </g>
              <g>
                <path
                  d="M533.9,379.3c-0.4,2.4,0.2,4.9,1,6.6c0.8,1.7,2,2.6,2.8,2.3c0.8-0.3,1.2-1.6,1.3-3.2c0.1-1.7-0.2-3.7-0.7-5.8
				c-0.5-2.2-0.8-3.7-1-5.4c-0.2-1.6-0.2-3.3,0.2-5.7c-0.9,2.3-1.5,3.8-2.1,5.5C534.8,375.2,534.2,376.8,533.9,379.3z"
                />
              </g>
              <g>
                <path
                  d="M570.2,359.3c1.9-2.2,3.7-4.2,5-6c1.3-1.7,2.2-3.2,1.7-3.9c-0.5-0.7-2.3-0.5-4.4,0.8c-2.2,1.3-4.6,3.7-6,6.8
				c-1.4,3.1-1.9,5.4-2.3,7.6c-0.4,2.2-0.6,4.5-0.6,7.8c0.5-3.2,1.4-5.3,2.4-7.2C567.1,363.2,568.3,361.5,570.2,359.3z"
                />
              </g>
              <g>
                <path
                  d="M580.7,319.9c1.7-0.7,3-1.7,4-2.7c0.9-1,1.4-2.1,1.1-2.9c-0.4-0.8-1.6-1.1-3.1-0.8c-1.5,0.3-3.3,1.3-4.6,2.8
				c-1.3,1.6-2.1,2.8-2.8,4c-0.8,1.3-1.5,2.6-2.4,4.1c1.4-1.4,2.6-2.1,3.8-2.7C577.8,321.1,579,320.6,580.7,319.9z"
                />
              </g>
              <g>
                <path
                  d="M-6.5,258.1c1.9-0.4,3.5-1.1,4.7-2c1.2-0.9,1.9-1.9,1.6-2.7c-0.2-0.8-1.4-1.3-3-1.2c-1.6,0.1-3.5,0.7-5.2,2
				c-1.7,1.3-2.7,2.3-3.8,3.3c-1,1-2.1,2-3.7,3.4c1.9-0.9,3.2-1.4,4.6-1.7C-9.8,258.7-8.5,258.5-6.5,258.1z"
                />
              </g>
              <g>
                <path
                  d="M8.4,246.2c-1.5,2.6-2.1,5.6-2,7.8c0.1,2.3,0.8,3.7,1.7,3.8c0.9,0.1,1.7-1.2,2.5-3.1c0.8-1.9,1.5-4.3,2-6.9
				c0.5-2.7,1.1-4.5,1.7-6.4c0.7-1.8,1.5-3.7,3.3-6.1c-2.1,2-3.5,3.5-4.9,5.1C11.2,242,9.9,243.6,8.4,246.2z"
                />
              </g>
              <g>
                <path
                  d="M544.8,223.7c-1.8-1.5-3.8-2.7-6-3.5c-2.1-0.9-4.5-1.4-6.9-1.4c-1.2,0-2.4,0.2-3.5,0.6c-1.2,0.4-2.3,0.9-3.2,1.6
				c-1.9,1.4-3.2,3.3-3.7,5.3c-0.3,1-0.3,2-0.2,3.1c0.2,1,0.5,1.8,0.9,2.5c0.9,1.4,2.1,2.3,3.1,2.7c1.1,0.4,2,0.4,2.6,0.2
				c0.6-0.2,0.8-0.4,0.8-0.6c-0.1-0.5-1.1-0.6-2.2-1.7c-1.1-1-2.1-3.1-1.2-5c0.4-1,1.3-2,2.4-2.7c0.6-0.4,1.2-0.6,1.9-0.8
				c0.7-0.2,1.5-0.3,2.2-0.3c3.2,0,7,1.4,10.2,3.5c3.3,2.2,5.7,4.8,7.4,7.5c0.9,1.3,1.6,2.7,2.2,4c0.6,1.4,1.1,2.7,1.5,4.1
				c0.8,2.8,1.2,5.8,1.2,9.2c0,3.4-0.4,7.1-1.6,11.5c1.4-4.3,2.2-8,2.5-11.5c0.3-3.4,0.2-6.5-0.3-9.6c-0.3-1.5-0.6-3.1-1-4.6
				c-0.5-1.5-1.1-3.1-1.9-4.7C550.7,230.1,548.4,226.7,544.8,223.7z"
                />
              </g>
              <g>
                <path
                  d="M547.9,236.8c-0.9-1.3-2.2-2.4-3.6-3.2c-1.5-0.8-3.3-1.2-5-1c-1.8,0.2-3.4,1.2-4.5,2.4c-1.1,1.2-1.7,2.9-1.6,4.4
				c0.1,1.7,1,2.9,1.8,3.6c0.9,0.7,1.9,1.1,2.7,1.1c0.8,0,1.4-0.2,1.8-0.5c0.3-0.3,0.4-0.6,0.3-0.8c-0.2-0.4-0.9-0.6-1.4-1.1
				c-0.3-0.3-0.6-0.6-0.8-1.1c-0.2-0.4-0.3-0.9-0.1-1.2c0.1-0.4,0.4-0.8,0.8-1.2c0.5-0.4,1.1-0.7,1.7-0.7c1.3-0.2,3.2,0.6,4.7,2
				c1.6,1.5,2.6,3.5,3.6,5.3c0.9,1.8,1.7,3.4,2.3,5.1c0.6,1.7,1.2,3.5,1.6,5.5c0.4,2,0.7,4.4,0.8,7.1c0.2-2.8,0.2-5.1,0.1-7.2
				c-0.1-2.1-0.3-4-0.6-5.9c-0.3-1.9-0.7-3.7-1.3-5.8c-0.3-1-0.6-2.1-1.1-3.3C549.5,239.4,548.9,238.1,547.9,236.8z"
                />
              </g>
              <g>
                <path
                  d="M613.1,514.1c0.5,1.2,0.7,2.4,0.8,3.5c0,1.1-0.1,2-0.4,2.7c-0.3,0.7-0.7,1.4-1.3,2.1c-0.6,0.6-1.4,1.3-2.4,1.7
				c-1.9,1-4,1.4-6.1,1.3c-2-0.1-3.6-0.8-4.7-1.5c-1.2-0.9-2-2.1-2.3-3.2c-0.4-1-0.3-2.3,0.1-3.4c0.4-1.1,0.9-2,1.4-2.8
				c0.5-0.8,0.9-1.4,0.9-2.1c0-0.6-0.6-1.3-1.9-1.6c-1.3-0.3-3.3,0-5.5,1.3c-1.1,0.7-2.1,1.6-3.1,2.9c-0.5,0.7-0.9,1.4-1.2,2.2
				c-0.4,0.8-0.6,1.8-0.8,2.7c-0.7,3.8,0.2,8.2,2.9,11.9c2.8,3.9,7.2,6.5,12,7.4c4.7,1,10.1,0.5,14.9-1.9c2.4-1.2,4.7-2.8,6.7-5
				c2-2.2,3.6-4.9,4.4-8c0.8-3,0.8-6.2,0.3-9c-0.6-2.9-1.7-5.4-3.1-7.7c-1.5-2.3-3.3-4.3-5.2-5.9c-1.9-1.6-4.1-2.9-6.1-3.7
				c-4.1-1.7-8-2.3-11.5-2.4c-7.1-0.1-12.9,1.4-18.6,3.1c-5.6,1.8-11.1,4.1-16.8,7.4c-5.7,3.2-11.6,7.4-17.6,13.4
				c6.6-5.4,12.9-8.7,18.8-11c5.9-2.3,11.4-3.5,16.8-4.2c5.4-0.6,11-0.7,16.3,0.5c2.7,0.6,5.3,1.6,7.5,3.1c1.2,0.8,2,1.6,2.8,2.6
				C611.9,511.7,612.6,512.9,613.1,514.1z"
                />
              </g>
              <g>
                <path
                  d="M586.9,487.9c2.8-1.8,5.2-4.2,7.1-6.8c1.9-2.6,3.2-5.3,3.9-7.8c0.7-2.5,0.8-4.7,0.6-6.3c-0.3-1.6-0.9-2.5-1.8-2.7
				c-1.8-0.3-3.7,2.1-6.3,5.3c-1.3,1.7-2.7,3.5-4.3,5.4c-1.6,2-3.3,4.1-5.1,6.3c-1.8,2.3-3.6,4.1-5.2,5.7c-1.7,1.6-3.2,3.1-4.9,4.4
				c-3.3,2.7-6.9,5.3-12.6,8.5c6.1-2.3,10.3-3.7,14.5-5.2c2.1-0.8,4.2-1.6,6.5-2.7C581.6,491.1,584.1,489.8,586.9,487.9z"
                />
              </g>
              <g>
                <path
                  d="M574,520.4c-1.2,2.3-1.3,5.1-0.8,7c0.5,1.9,1.5,3,2.4,2.8c0.9-0.2,1.4-1.4,1.8-3c0.4-1.6,0.7-3.5,0.8-5.6
				c0.2-2.2,0.5-3.7,1-5.3c0.5-1.5,1.3-3,2.9-4.9c-2,1.4-3.3,2.6-4.5,3.9C576.4,516.6,575.3,518,574,520.4z"
                />
              </g>
              <g>
                <path
                  d="M580.2,543.9c0.4,2.6,1.7,5,3.2,6.4c1.5,1.4,2.9,1.9,3.6,1.3c0.7-0.6,0.5-2,0-3.6c-0.5-1.6-1.4-3.6-2.5-5.5
				c-1.1-2-1.8-3.6-2.3-5.2c-0.5-1.6-0.9-3.3-0.8-5.9c-0.6,2.5-0.9,4.3-1.1,6.1C580,539.4,579.8,541.2,580.2,543.9z"
                />
              </g>
              <g>
                <path
                  d="M599.3,558c1.3,1.4,3,2.4,4.7,2.8c1.7,0.5,3.4,0.5,4.8,0.2c1.4-0.3,2.4-0.9,3-1.4c0.6-0.6,0.8-1.1,0.7-1.5
				c-0.4-0.8-2-0.9-3.9-1.2c-2-0.3-4.3-0.9-6.5-2.2c-1.2-0.7-2.1-1.5-2.9-2.3c-0.8-0.8-1.4-1.7-2-2.6c-1.2-1.8-2.1-3.9-3.1-7.1
				c0.5,3.3,0.8,5.6,1.4,7.8c0.3,1.1,0.6,2.3,1.2,3.5C597.2,555.2,598,556.6,599.3,558z"
                />
              </g>
              <g>
                <path
                  d="M613.7,553.2c2.2,1.1,4.7,1.3,6.5,1c1.8-0.3,3-1.2,2.9-2.1c-0.1-0.9-1.2-1.6-2.7-2.1c-1.5-0.5-3.5-0.9-5.5-1
				c-2.2-0.2-3.7-0.4-5.2-0.7c-1.5-0.4-3.1-0.9-5.1-2.2c1.7,1.7,2.9,2.8,4.2,3.9C610.1,551,611.5,552.1,613.7,553.2z"
                />
              </g>
              <g>
                <path
                  d="M623.6,545.9c1.5,0.1,2.9-0.6,3.8-1.4c0.8-0.8,1.1-1.9,0.7-2.6c-0.4-0.8-1.3-1.2-2.4-1.2c-1,0-2.2,0.3-3.3,1
				c-1.1,0.8-1.9,1.3-2.8,1.8c-0.9,0.5-1.8,0.9-3.2,1.2c1.4,0.3,2.5,0.5,3.5,0.7C620.9,545.6,622,545.8,623.6,545.9z"
                />
              </g>
              <g>
                <path
                  d="M584.7,454.4c0.8-4,0.4-8.1-0.6-11.1c-0.9-2.9-2.3-4.6-3.1-4.4c-0.9,0.3-1.1,2.3-1,5c0,2.8,0.2,6.4,0.3,10.1
				c0.1,3.8,0,6.5-0.4,9.1c-0.4,2.7-1,5.4-2.7,9.1c2.2-3.5,3.4-6,4.5-8.6C582.9,461.2,583.9,458.5,584.7,454.4z"
                />
              </g>
              <g>
                <path
                  d="M175.8,236.9c-0.3,3.3,0.7,6.7,2.2,8.7c1.4,2.1,3.1,2.9,3.7,2.4c0.7-0.5,0.5-2.1,0.1-4.1c-0.5-2.3-1-4.4-1.6-7.2
				c-0.5-2.8-0.6-4.8-0.4-6.9c0.2-2,0.7-4.2,2.3-6.9c-2.1,2.4-3.2,4.4-4.2,6.4C177,231.3,176.2,233.5,175.8,236.9z"
                />
              </g>
              <g>
                <path
                  d="M316.4,56.6c3.3,0.8,6.5,1.5,9,2c2.5,0.5,4.4,0.7,4.8-0.1c0.4-0.7-0.8-2.4-3.3-3.8c-2.5-1.5-6.3-2.6-10.1-2.5
				c-3.9,0.1-6.5,0.7-9.1,1.5c-2.5,0.8-5,1.6-8.5,3.4c3.6-1.2,6.2-1.4,8.7-1.5C310.5,55.6,313,55.9,316.4,56.6z"
                />
              </g>
              <g>
                <path
                  d="M166.4,149.7c1.4,3.4,3.9,6.3,6.1,7.9c2.2,1.7,4.1,2.1,4.7,1.5c0.6-0.7-0.3-2.3-1.7-4.4c-1.4-2.1-3.3-4.6-5.3-7.2
				c-2.1-2.7-3.3-4.7-4.4-6.9c-1.1-2.2-2-4.5-2.6-8.1c0.1,3.7,0.4,6.2,0.8,8.7C164.4,143.7,165,146.2,166.4,149.7z"
                />
              </g>
              <g>
                <path
                  d="M79.1,161.1c1.1-2.5,1.1-5.4,0.4-7.4c-0.7-2-1.8-3-2.6-2.8c-0.9,0.3-1.3,1.6-1.5,3.2c-0.3,1.7-0.4,3.7-0.5,5.9
				c-0.1,2.3-0.4,3.9-0.9,5.5c-0.5,1.6-1.3,3.2-3.1,5.1c2.1-1.5,3.4-2.8,4.7-4.2C76.7,165.2,77.9,163.7,79.1,161.1z"
                />
              </g>
              <g>
                <path
                  d="M340.8,260.3c3.5-6.8,4.5-14.6,2.8-15.1c-1.7-0.5-5.1,6.3-6.9,13.5c-0.9,3.8-1.5,6.4-2.2,9c-0.7,2.6-1.5,5.2-2.9,9
				c1.9-3.5,3.3-5.9,4.7-8.3C337.6,266.1,339,263.8,340.8,260.3z"
                />
              </g>
              <g>
                <path
                  d="M485,235.3c3.3,0,6.5-0.3,9-0.8c2.4-0.5,4.1-1.2,4.2-2.1c0-0.9-1.7-1.7-4.2-2.1c-2.6-0.4-6.1-0.3-9.4,0.6
				c-3.4,0.9-5.7,1.8-8,2.7c-2.4,1-4.7,1.9-7.8,3.1c3.5-0.8,5.9-1.1,8.3-1.3C479.3,235.3,481.6,235.3,485,235.3z"
                />
              </g>
              <g>
                <path
                  d="M596.5,141.5c-2.7,0.3-5.3,1.3-7,2.5c-1.7,1.2-2.6,2.4-2.2,3.2c0.4,0.8,1.9,0.9,3.8,0.6c1.9-0.3,4.3-1,6.5-2.1
				c2.4-1.1,4-1.9,5.8-2.5c1.7-0.7,3.5-1.2,6.3-1.7c-2.6-0.1-4.5-0.1-6.6-0.2C601.2,141.3,599.3,141.2,596.5,141.5z"
                />
              </g>
              <g>
                <path
                  d="M548.5,79c-2.4,1.8-4.6,3.6-6.2,5.2c-1.6,1.6-2.6,2.9-2.2,3.7c0.4,0.8,2.2,0.8,4.5-0.2c2.3-1,5.1-3,7.1-5.6
				c2-2.7,3.1-4.8,4-6.9c0.9-2.1,1.8-4.2,2.9-7.4c-1.5,3-3,4.7-4.5,6.3C552.6,75.7,550.9,77.1,548.5,79z"
                />
              </g>
              <g>
                <path
                  d="M482.8,101.2c3.5-0.6,7-1.5,9.5-2.4c2.6-0.9,4.3-1.9,4.2-2.8c-0.1-0.9-2.1-1.4-4.9-1.3c-2.8,0.1-6.6,0.8-10,2.3
				c-3.6,1.5-5.9,2.8-8.2,4.1c-2.3,1.3-4.6,2.7-7.9,4.7c3.6-1.5,6.1-2.3,8.6-2.9C476.6,102.3,479.2,101.9,482.8,101.2z"
                />
              </g>
              <g>
                <path
                  d="M503.3,526.1c2.5-1.7,3.3-4.9,2-6c-1.4-1.1-4.3,0.4-5.4,3.3c-1.2,2.9-1.3,4.3-2.8,7.1
				C499.4,528.3,500.7,527.9,503.3,526.1z"
                />
              </g>
              <g>
                <path
                  d="M281.3,518.4c2,0.3,3.8,0.4,5.3,0.2c1.5-0.1,2.7-0.5,3-1.3c0.3-0.8-0.5-2-2.2-2.8c-1.6-0.8-4.1-1.2-6.5-0.5
				c-2.4,0.7-3.9,1.5-5.3,2.3c-1.4,0.9-2.8,1.8-4.6,3.2c2.1-1,3.6-1.3,5.1-1.4C277.8,518,279.3,518.1,281.3,518.4z"
                />
              </g>
            </g>
          </g>
          <g id="Peddle11">
            <defs>
              <path
                id="SVGID_00000176032988032479108920000003934926142208819628_"
                d="M507.5,284.1c-44.3,18.3-245.3,68.5-259.5,34.1
			c-14.2-34.4,163.9-140.3,208.2-158.6c44.3-18.3,125.1-13.6,137.3,16.2C605.8,205.6,551.8,265.8,507.5,284.1z"
              />
            </defs>
            <use
              xlinkHref="#SVGID_00000176032988032479108920000003934926142208819628_"
              overflow="visible"
              fill="#003301"
            />
            <clipPath id="SVGID_00000135678907654106786630000013468308659798770081_">
              <use
                xlinkHref="#SVGID_00000176032988032479108920000003934926142208819628_"
                overflow="visible"
              />
            </clipPath>
            <g
              opacity="0.1"
              clipPath="url(#SVGID_00000135678907654106786630000013468308659798770081_)"
            >
              <g>
                <g>
                  <path
                    d="M312.1,219.6c8.1-1.2,15.4-3.8,20.8-7c5.4-3.2,8.8-6.9,8.1-10.4c-0.7-3.4-5.5-5.7-12.2-5.8c-6.7-0.1-15.4,2-22.9,6.8
					c-7.7,4.9-12.4,9-17.2,13c-4.7,4-9.4,8-16.3,13.7c8.1-3.8,13.9-5.6,19.8-6.9C297.9,221.7,303.8,220.8,312.1,219.6z"
                  />
                </g>
                <g>
                  <path
                    d="M314.5,239.9c7,1.8,13.9,2.1,19.5,1.1c5.5-1,9.7-3.2,10.5-6.6c0.8-3.4-2.4-7.3-8.1-9.9c-5.6-2.6-13.8-3.8-21.8-2.1
					c-8.2,1.8-13.5,3.9-18.8,6c-5.3,2.1-10.5,4.2-18,7.1c8-0.8,13.5-0.4,18.9,0.5C302,236.8,307.2,238.1,314.5,239.9z"
                  />
                </g>
                <g>
                  <path
                    d="M303.9,258.7c4.2,3.4,8.8,5.1,13,5.4c4.2,0.3,8.1-0.9,10-3.9c1.9-3,1.1-7.2-2.1-10.9c-3.1-3.7-8.6-6.8-14.9-7.1
					c-6.5-0.4-10.9,0.2-15.2,0.7c-4.3,0.5-8.6,1-14.6,1.5c5.8,1.7,9.5,3.7,12.9,5.9C296.4,252.6,299.5,255.2,303.9,258.7z"
                  />
                </g>
                <g>
                  <path
                    d="M282.6,276.1c1.5,4.7,4.5,8,8,9.7c3.4,1.7,7.3,1.9,10.2-0.1c2.9-2,4.1-5.7,3.3-9.8c-0.7-4-3.4-8.3-7.9-11
					c-4.6-2.8-8.1-4.2-11.5-5.8c-3.4-1.6-6.6-3.2-10.9-6.1c2.8,4.3,4.2,7.7,5.3,11.2C280.2,267.7,281.1,271.2,282.6,276.1z"
                  />
                </g>
                <g>
                  <path
                    d="M262,284.1c-1.5,5-1.3,9.6,0.1,13.5c1.3,3.8,3.8,7,7.3,7.6c3.4,0.7,7.1-1.5,9.3-5.8c2.2-4.2,3-10.5,0.8-16.4
					c-2.3-6-4.6-9.7-6.8-13.4c-2.3-3.6-4.5-7.1-7.6-12.2c1,5.8,0.8,9.9,0.1,13.8C264.5,275.2,263.5,279,262,284.1z"
                  />
                </g>
                <g>
                  <path
                    d="M238.4,276.8c-4.1,3.3-6.7,7-8.2,10.8c-0.3,1-0.7,1.9-0.9,2.9c-0.2,1-0.3,1.9-0.3,2.8c0,1.8,0.4,3.6,1.4,5
					c1,1.4,2.6,2.3,4.6,2.6c1,0.1,2.1,0.1,3.2-0.1c1.1-0.2,2.4-0.5,3.5-1.1c4.8-2.1,9.8-7.2,11.7-13.9c1.9-6.8,2-11.6,2-16.2
					c-0.1-4.6-0.3-9.1-0.8-15.3c-1.6,6-3.8,9.7-6.4,12.9C245.6,270.5,242.6,273.3,238.4,276.8z"
                  />
                </g>
                <g>
                  <path
                    d="M221.4,254.8c-5.9,0.7-10.8,2.7-14.6,5.4c-3.7,2.7-6.2,6.2-5.7,9.7c0.5,3.5,4.2,6.1,9.6,6.6c5.3,0.5,12.3-1.2,17.7-5.6
					c5.6-4.6,8.8-8.3,11.8-12c3-3.7,5.9-7.3,10.2-12.6c-5.6,3.6-10,5.2-14.3,6.3C231.8,253.5,227.4,254.1,221.4,254.8z"
                  />
                </g>
                <g>
                  <path
                    d="M214.7,232.3c-5.8-1.9-11.2-2.4-15.9-1.9c-1.2,0.2-2.3,0.3-3.3,0.7c-1.1,0.3-2,0.7-2.9,1.1c-1.7,0.9-3.1,2.2-3.7,3.8
					c-0.6,1.6-0.4,3.5,0.5,5.3c0.5,0.9,1.2,1.9,2,2.7c0.9,0.9,1.9,1.8,3.1,2.5c4.8,3.1,12.4,4.8,19.7,3.2c7.5-1.7,12.3-4,16.9-6.2
					c4.5-2.3,9-4.7,15-8.3c-6.9,1.6-11.7,1.4-16.4,0.8C225.1,235.5,220.6,234.3,214.7,232.3z"
                  />
                </g>
                <g>
                  <path
                    d="M221.6,214.1c-3.8-4.1-7-7.1-10.2-9.4c-1.6-1.2-3.2-2.2-4.8-3.1c-1.6-0.9-3.3-1.6-5.1-1.4c-1.7,0.1-3.3,1.2-4.4,3.3
					c-1.1,2.1-1.4,5.3-0.6,8.8c0.8,3.5,2.7,7.3,5.7,10.4c2.9,3.1,6.8,5.6,11,6.8c4.4,1.3,7.9,1.6,11.1,1.7c3.2,0.1,5.9-0.1,8.6-0.4
					c5.4-0.6,10.5-1.4,17.9-2.1c-7.3-1.5-12.2-3.2-16.6-5.4c-2.2-1.1-4.3-2.4-6.4-3.9C225.6,217.9,223.5,216.2,221.6,214.1z"
                  />
                </g>
                <g>
                  <path
                    d="M239.5,196.3c-0.1-5.5-0.9-9.8-2.3-13.6c-0.7-1.9-1.5-3.7-2.5-5.3c-1-1.6-2.2-2.9-3.9-3.6c-1.6-0.7-3.5-0.5-5.4,0.6
					c-1.9,1.1-3.7,3.3-4.9,6.3c-1.2,3-1.8,6.7-1.4,10.5c0.4,3.8,1.7,7.8,3.9,11.1c2.3,3.5,4.6,5.9,6.7,7.9c2.2,2,4.2,3.6,6.3,5.1
					c4.1,3,8.3,5.7,14,9.6c-4.3-5.5-6.5-9.9-8-14.2c-0.7-2.2-1.3-4.4-1.8-6.8C239.9,201.7,239.6,199.1,239.5,196.3z"
                  />
                </g>
                <g>
                  <path
                    d="M259.2,191.2c2.3-5.4,3.1-10.6,2.9-15.2c-0.1-1.2-0.2-2.2-0.4-3.3c-0.2-1.1-0.5-2-0.9-2.9c-0.8-1.8-1.9-3.2-3.5-4
					c-1.6-0.8-3.4-0.8-5.3,0c-1,0.4-2,1-2.9,1.8c-0.9,0.8-1.9,1.7-2.8,2.9c-3.5,4.5-5.8,11.9-4.6,19.3c1.3,7.6,3.3,12.4,5.3,17
					c2.1,4.6,4.3,9,7.7,15c-1.4-6.8-1-11.6-0.1-16C255.5,201.1,256.9,196.9,259.2,191.2z"
                  />
                </g>
                <g>
                  <path
                    d="M281.1,191.4c4.3-4.1,7.2-8,9.3-11.7c1-1.9,1.9-3.7,2.4-5.6c0.5-1.8,0.7-3.7,0-5.3c-0.6-1.6-2.1-2.9-4.3-3.4
					c-2.2-0.6-5.1-0.3-8.2,0.8c-3.1,1.2-6.3,3.3-8.9,6.2c-2.6,2.9-4.7,6.5-5.8,10.5c-1.2,4-1.6,7.4-1.8,10.4c-0.2,3-0.2,5.7-0.2,8.3
					c0.1,5.2,0.3,10.3,0,17.6c2.4-6.9,4.7-11.5,7.3-15.6c1.3-2.1,2.8-4,4.4-6C277.1,195.6,278.9,193.5,281.1,191.4z"
                  />
                </g>
                <g>
                  <path
                    d="M256.7,233.3c-1.5,3.2,1.5,6,5,5.6c3.5-0.4,5.8-3.7,3.7-6.5c-2.2-2.9-3.7-3.9-5.1-7C259.6,228.7,258.2,230,256.7,233.3z
					"
                  />
                </g>
                <g>
                  <path
                    d="M261.4,230.6c-1.4,1-1.8,2.3-1.5,3.7c0.3,1.4,1.3,2.8,2.8,3.6c1.5,0.9,3.3,0.9,4.7,0.2c1.4-0.7,2.3-2.2,2.1-4.1
					c-0.2-2-0.6-3.3-0.9-4.7c-0.3-1.3-0.6-2.6-0.7-4.4c-0.9,1.5-1.9,2.4-2.9,3.1C263.9,228.9,262.8,229.6,261.4,230.6z"
                  />
                </g>
                <g>
                  <path
                    d="M264.9,232.2c-1.3-0.6-2.1-0.2-2.9,0.7c-0.7,0.9-1.4,2.4-1.5,4.2c-0.1,1.7,0.7,3.3,2.1,4.1c1.4,0.8,3.3,0.7,4.8-0.7
					c1.5-1.4,2.2-2.7,2.8-3.8c0.6-1.2,1.2-2.3,2.1-3.6c-1.5,0.5-2.7,0.5-3.8,0.3C267.3,233.2,266.2,232.7,264.9,232.2z"
                  />
                </g>
                <g>
                  <path
                    d="M267.4,236.8c0-0.7-0.3-1.3-0.7-1.8c-0.5-0.5-1.1-1-1.9-1.2c-0.8-0.3-1.6-0.3-2.4-0.1c-0.8,0.2-1.5,0.6-2.1,1.3
					c-0.6,0.6-1.1,1.3-1.4,2c-0.3,0.7-0.6,1.4-0.6,2c0,0.7,0.1,1.3,0.5,2c0.4,0.6,1.1,1.3,2.1,1.7c1,0.5,2.1,0.8,3,1
					c0.9,0.2,1.7,0.2,2.5,0.1c1.6-0.1,3.1-0.5,4.5-1.6c-0.8,0.1-1.5-0.2-2-0.6c-0.5-0.4-0.8-0.8-1-1.3c-0.2-0.5-0.3-1-0.4-1.6
					C267.4,238,267.5,237.5,267.4,236.8z"
                  />
                </g>
                <g>
                  <path
                    d="M266.9,240c0.8-1.4,0.7-2.5-0.1-3.6c-0.7-1.1-2-2.2-3.8-2.6c-1.7-0.4-3.4,0.1-4.5,1.4c-1.1,1.3-1.4,3.5-0.4,5.4
					c1.1,2,2.1,3.1,3.2,4.1c1,1,2,2,3.2,3.4c-0.2-1.8,0-3.1,0.5-4.3C265.4,242.6,266.1,241.5,266.9,240z"
                  />
                </g>
                <g>
                  <path
                    d="M264.2,244.1c1.7-1.3,2.4-3.2,2.2-4.9c-0.2-1.7-1.4-3.1-3.1-3.7c-1.7-0.6-3.4-0.3-4.7,0.5c-1.3,0.8-2.1,2-2.1,3.8
					c0,1.9,0,3.3,0,4.7c-0.1,1.4-0.4,2.8-1.2,4.5c1.6-1,2.9-1.7,4.3-2.3C260.9,246.1,262.4,245.4,264.2,244.1z"
                  />
                </g>
                <g>
                  <path
                    d="M258.2,243.2c3.6,1.4,6.6-1.7,6.2-5.2c-0.4-3.5-4.2-5.7-7.3-3.5c-3.2,2.3-4.3,3.9-7.7,5.4
					C253,240.5,254.5,241.8,258.2,243.2z"
                  />
                </g>
                <g>
                  <path
                    d="M254.5,239.4c1.8,3.2,5.8,2.6,7.7-0.4c1.8-3,0.5-6.9-3.1-7c-3.7-0.1-5.5,0.5-8.9-0.3
					C252.5,234.3,252.7,236.1,254.5,239.4z"
                  />
                </g>
                <g>
                  <path
                    d="M255,235.1c0,1.7,1.1,2.7,2.4,3c1.3,0.3,3-0.2,4.3-1.3c1.4-1.1,2.1-2.6,2.3-3.8c0.1-1.2-0.4-2.3-1.8-2.8
					c-2.9-1-4.7-1.1-6.9-3C255.8,230.1,255,231.7,255,235.1z"
                  />
                </g>
                <g>
                  <path
                    d="M299.7,201.9c5.7-1.8,10.2-4.8,13.4-8.2c3.2-3.4,5-7.2,3.9-10.6c-1.1-3.3-5.3-5.2-10.8-4.6c-5.4,0.5-12,3.6-16.6,9.1
					c-4.7,5.7-7.1,10.1-9.4,14.4c-2.2,4.4-4.4,8.6-7.4,14.7c4.8-4.8,8.8-7.3,12.8-9.3C289.6,205.4,293.8,203.9,299.7,201.9z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M624.2,225.2c3.8,4.2,8.2,7.3,12.1,9.2c3.9,1.8,7.4,2.3,9.1,0.6c1.7-1.7,1.1-5.3-1.4-9.3c-2.6-3.9-7.1-8.1-12.8-10.5
					c-5.8-2.5-10-3.6-14.1-4.8c-4.1-1.1-8.3-2.3-14.2-4.1c5.3,3.1,8.6,5.8,11.6,8.7C617.5,217.9,620.3,220.9,624.2,225.2z"
                  />
                </g>
                <g>
                  <path
                    d="M613.5,234.5c1.7,4.7,4.2,8.8,6.9,11.6c2.7,2.8,5.6,4.4,7.9,3.4c2.2-0.9,3.3-4.2,2.6-8.5c-0.7-4.2-3.2-9.4-7.3-13.3
					c-4.2-4-7.5-6.2-10.7-8.5c-3.3-2.2-6.5-4.4-11.1-7.6c3.6,4.3,5.5,7.6,7.1,11C610.4,226.1,611.7,229.6,613.5,234.5z"
                  />
                </g>
                <g>
                  <path
                    d="M598.5,235.7c-0.3,3.7,0.5,7,2,9.6c1.5,2.5,3.7,4.3,6.1,4.2c2.4-0.1,4.5-2.2,5.4-5.4c0.9-3.2,0.5-7.6-1.7-11.3
					c-2.3-3.8-4.3-6.2-6.3-8.5c-2-2.3-3.9-4.5-6.5-7.8c1.3,4,1.6,6.8,1.6,9.7C599.1,229,598.9,231.8,598.5,235.7z"
                  />
                </g>
                <g>
                  <path
                    d="M580.4,230.2c-2.1,2.7-2.8,5.7-2.5,8.4c0.3,2.6,1.7,4.9,4,5.8c2.3,0.9,4.9,0.1,6.9-1.9c2-2,3.4-5.2,3.2-8.8
					c-0.2-3.7-0.7-6.3-1.1-8.9c-0.4-2.5-0.7-5.1-0.8-8.6c-1.4,3.3-2.8,5.4-4.3,7.4C584.2,225.6,582.5,227.5,580.4,230.2z"
                  />
                </g>
                <g>
                  <path
                    d="M567.7,221.5c-3.4,1.1-6,3-7.7,5.3c-1.7,2.3-2.5,4.9-1.5,7.2c0.9,2.2,3.6,3.5,7,3.1c3.3-0.4,7.2-2.4,9.7-5.9
					c2.6-3.6,3.8-6.4,5-9.1c1.2-2.7,2.3-5.4,4-9.1c-2.9,2.8-5.4,4.3-7.8,5.4C573.9,219.5,571.3,220.4,567.7,221.5z"
                  />
                </g>
                <g>
                  <path
                    d="M562.8,205.2c-3.5-1.1-6.6-1.1-9.4-0.5c-0.7,0.2-1.4,0.3-2,0.6c-0.6,0.3-1.2,0.6-1.7,0.9c-1.1,0.7-1.9,1.6-2.3,2.8
					c-0.4,1.1-0.3,2.4,0.3,3.6c0.3,0.6,0.7,1.2,1.3,1.8c0.6,0.6,1.2,1.1,2,1.6c3.1,1.9,8,2.8,12.5,1.3c4.6-1.6,7.4-3.3,10.1-5.1
					c2.6-1.8,5.1-3.7,8.4-6.4c-4.1,1.4-7.1,1.6-9.9,1.4C569.2,206.9,566.4,206.2,562.8,205.2z"
                  />
                </g>
                <g>
                  <path
                    d="M568.7,186.9c-2.6-3.1-5.7-5.2-8.7-6.3c-3-1.1-5.9-1.1-7.7,0.5c-1.8,1.6-1.9,4.8-0.1,8.1c1.8,3.3,5.4,6.6,10.1,8
					c4.8,1.4,8.2,1.8,11.5,2.1c3.3,0.3,6.5,0.5,11.2,0.9c-4.3-1.8-6.8-3.7-9.1-5.8C573.6,192.4,571.5,190.1,568.7,186.9z"
                  />
                </g>
                <g>
                  <path
                    d="M579.1,174.3c-1.1-4.1-3-7.4-5.1-9.9c-0.6-0.6-1.1-1.2-1.7-1.7c-0.6-0.5-1.2-0.9-1.8-1.2c-1.2-0.6-2.4-0.9-3.6-0.7
					c-1.2,0.3-2.2,1.1-2.8,2.4c-0.3,0.6-0.6,1.4-0.8,2.2c-0.2,0.8-0.3,1.8-0.2,2.8c0.1,4,2,9,5.8,12.6c3.9,3.7,7,5.5,10.1,7.2
					c3.1,1.7,6.2,3.3,10.6,5.4c-3.6-3.3-5.4-6.2-6.8-9.1C581.3,181.5,580.3,178.5,579.1,174.3z"
                  />
                </g>
                <g>
                  <path
                    d="M592.2,171.1c0.9-3.8,1.3-6.8,1.4-9.5c0.1-1.3,0-2.7-0.1-4c-0.1-1.3-0.4-2.5-1.2-3.5c-0.8-0.9-2-1.4-3.6-1.2
					c-1.6,0.2-3.6,1.2-5.3,3c-1.7,1.8-3.1,4.4-3.7,7.3c-0.6,2.9-0.6,6,0.3,8.9c0.9,3,2.1,5.2,3.3,7c1.2,1.8,2.4,3.3,3.6,4.8
					c2.4,2.9,4.9,5.5,8.1,9.5c-2-4.8-2.9-8.3-3.3-11.7c-0.2-1.7-0.3-3.4-0.3-5.2C591.6,175,591.7,173.1,592.2,171.1z"
                  />
                </g>
                <g>
                  <path
                    d="M609.4,174.5c3.1-2.2,5.3-4.3,6.9-6.6c0.8-1.1,1.5-2.3,2.1-3.5c0.5-1.2,0.8-2.4,0.6-3.6c-0.2-1.2-1.1-2.2-2.5-2.8
					c-1.4-0.6-3.3-0.8-5.5-0.4c-2.2,0.4-4.5,1.5-6.6,3.3c-2,1.7-3.8,4-4.8,6.5c-1.1,2.7-1.6,4.9-1.9,6.9c-0.3,2-0.4,3.8-0.5,5.6
					c-0.1,3.5,0,6.9-0.1,11.8c1.5-4.6,3.1-7.6,5.1-10.1c1-1.3,2-2.5,3.2-3.6C606.4,176.8,607.8,175.6,609.4,174.5z"
                  />
                </g>
                <g>
                  <path
                    d="M619.9,183.9c4-0.8,7.3-2.3,9.9-4.3c0.6-0.5,1.2-1,1.7-1.5c0.5-0.5,1-1.1,1.3-1.6c0.7-1.1,1.1-2.3,1-3.6
					c-0.2-1.2-0.9-2.3-2.1-3.1c-0.6-0.4-1.3-0.8-2.1-1c-0.8-0.2-1.7-0.4-2.7-0.5c-3.9-0.3-9.1,1.3-12.8,4.9
					c-3.8,3.7-5.8,6.7-7.7,9.6c-1.8,3-3.5,6-5.6,10.2c3.4-3.4,6.2-5.1,9.1-6.3C612.8,185.6,615.8,184.7,619.9,183.9z"
                  />
                </g>
                <g>
                  <path
                    d="M628.3,196.5c4,0.9,7.4,1,10.3,0.8c1.5-0.1,2.9-0.4,4.1-0.8c1.2-0.4,2.4-1,3.1-2c0.7-1,0.8-2.3,0.3-3.8
					c-0.5-1.5-1.8-3-3.7-4.4c-1.9-1.3-4.3-2.3-7-2.7c-2.7-0.4-5.6-0.2-8.3,0.7c-2.8,0.9-4.9,2-6.7,3c-1.8,1-3.3,2.1-4.8,3.1
					c-3,2.1-5.8,4.2-10.1,6.8c4.9-1.3,8.4-1.8,11.8-1.8c1.7,0,3.4,0,5.2,0.2C624.3,195.8,626.3,196,628.3,196.5z"
                  />
                </g>
                <g>
                  <path
                    d="M594.8,198.8c-2.4,0.4-2.8,3.2-1.3,5c1.6,1.9,4.4,1.9,5.2-0.4c0.8-2.4,0.8-3.7,2-5.7
					C598.6,198.6,597.3,198.3,594.8,198.8z"
                  />
                </g>
                <g>
                  <path
                    d="M598.2,200.4c-1.1-0.4-2-0.1-2.7,0.6c-0.7,0.7-1.1,1.8-1,3c0.1,1.2,0.8,2.2,1.7,2.7c0.9,0.5,2.2,0.5,3.2-0.4
					c1-0.9,1.7-1.6,2.3-2.3c0.6-0.7,1.3-1.4,2.2-2.1c-1.2,0-2.1-0.2-2.9-0.4C600.2,201.2,599.3,200.8,598.2,200.4z"
                  />
                </g>
                <g>
                  <path
                    d="M598.6,203c-0.2-0.9-0.7-1.3-1.5-1.4c-0.8-0.1-1.9,0.1-3,0.8c-1,0.6-1.7,1.7-1.6,2.8c0.1,1.1,0.9,2.2,2.2,2.5
					c1.4,0.3,2.4,0.2,3.3,0.1c0.9-0.1,1.8-0.2,2.9-0.2c-0.9-0.7-1.3-1.4-1.6-2.1C599,204.8,598.8,204,598.6,203z"
                  />
                </g>
                <g>
                  <path
                    d="M597,206.3c0.4-0.3,0.6-0.7,0.8-1.1c0.1-0.5,0.1-1,0-1.6c-0.1-0.5-0.4-1.1-0.9-1.4c-0.4-0.4-1-0.6-1.6-0.7
					c-0.6-0.1-1.2-0.1-1.7,0c-0.5,0.1-1,0.2-1.4,0.4c-0.4,0.2-0.7,0.6-0.9,1c-0.2,0.5-0.3,1.1-0.2,1.9c0.1,0.8,0.3,1.5,0.6,2.1
					c0.3,0.6,0.6,1.1,0.9,1.5c0.7,0.8,1.5,1.6,2.7,2c-0.4-0.5-0.5-1-0.4-1.4c0-0.4,0.2-0.8,0.4-1.1c0.2-0.3,0.5-0.6,0.7-0.9
					C596.3,206.7,596.6,206.6,597,206.3z"
                  />
                </g>
                <g>
                  <path
                    d="M594.9,207.2c1.1-0.1,1.7-0.6,2.1-1.5c0.3-0.8,0.5-2,0-3.2c-0.4-1.1-1.4-1.9-2.6-2c-1.2-0.1-2.5,0.5-3.2,1.9
					c-0.7,1.4-0.9,2.4-1.1,3.4c-0.2,1-0.4,1.9-0.7,3.2c0.9-0.8,1.8-1.2,2.7-1.4C592.9,207.4,593.8,207.3,594.9,207.2z"
                  />
                </g>
                <g>
                  <path
                    d="M591.5,207.3c1.4,0.5,2.8,0.1,3.7-0.7c0.9-0.8,1.2-2,0.9-3.2c-0.3-1.2-1.2-2.1-2.1-2.5c-0.9-0.4-2-0.4-3,0.3
					c-1.1,0.7-1.9,1.3-2.7,1.8c-0.8,0.5-1.7,0.9-3.1,1.1c1.2,0.5,2.1,1,3,1.6C589.1,206.1,590.1,206.7,591.5,207.3z"
                  />
                </g>
                <g>
                  <path
                    d="M589.7,203.4c0.6,2.6,3.6,3.1,5.4,1.5c1.8-1.6,1.7-4.6-0.8-5.5c-2.6-1-3.9-1-6.1-2.3
					C589.3,199.5,589.1,200.8,589.7,203.4z"
                  />
                </g>
                <g>
                  <path
                    d="M590.5,199.9c-1.1,2.2,0.8,4.3,3.2,4.2c2.4-0.1,4.2-2.4,2.8-4.5c-1.4-2.2-2.4-3-3.3-5.2
					C592.6,196.7,591.6,197.6,590.5,199.9z"
                  />
                </g>
                <g>
                  <path
                    d="M593.1,198.5c-0.9,0.7-1.1,1.7-0.8,2.5c0.4,0.9,1.3,1.6,2.4,2c1.2,0.3,2.3,0.2,3.1-0.2c0.8-0.4,1.2-1.1,0.9-2.1
					c-0.5-2-1.2-3.1-0.9-5.1C596.3,197,595.1,197.2,593.1,198.5z"
                  />
                </g>
                <g>
                  <path
                    d="M629.5,211.2c3.3,2.5,6.7,4,9.9,4.5c3.2,0.5,6.1,0.1,7.6-1.9c1.5-1.9,0.9-5.1-1.5-8c-2.4-2.9-6.8-5.5-11.7-6
					c-5.1-0.5-8.5-0.1-11.9,0.2c-3.4,0.4-6.7,0.8-11.3,1.5c4.6,0.9,7.6,2.2,10.3,3.7C623.6,206.8,626.1,208.6,629.5,211.2z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M241.1,523.3c3.8,4.2,8.2,7.3,12.1,9.2c3.9,1.8,7.4,2.3,9.1,0.6c1.7-1.7,1.1-5.3-1.4-9.3c-2.6-3.9-7.1-8.1-12.8-10.5
					c-5.8-2.5-10-3.6-14.1-4.8c-4.1-1.1-8.3-2.3-14.2-4.1c5.3,3.1,8.6,5.8,11.6,8.7C234.3,516,237.1,519,241.1,523.3z"
                  />
                </g>
                <g>
                  <path
                    d="M230.3,532.5c1.7,4.7,4.2,8.8,6.9,11.6c2.7,2.8,5.6,4.4,7.9,3.4c2.2-0.9,3.3-4.2,2.6-8.5c-0.7-4.2-3.2-9.4-7.3-13.3
					c-4.2-4-7.5-6.2-10.7-8.5c-3.3-2.2-6.5-4.4-11.1-7.6c3.6,4.3,5.5,7.6,7.1,11C227.3,524.1,228.6,527.6,230.3,532.5z"
                  />
                </g>
                <g>
                  <path
                    d="M215.4,533.7c-0.3,3.7,0.5,7,2,9.6c1.5,2.5,3.7,4.3,6.1,4.2c2.4-0.1,4.5-2.2,5.4-5.4c0.9-3.2,0.5-7.6-1.7-11.3
					c-2.3-3.8-4.3-6.2-6.3-8.5c-2-2.3-3.9-4.5-6.5-7.8c1.3,4,1.6,6.8,1.6,9.7C216,527.1,215.7,529.9,215.4,533.7z"
                  />
                </g>
                <g>
                  <path
                    d="M197.2,528.3c-2.1,2.7-2.8,5.7-2.5,8.4c0.3,2.6,1.7,4.9,4,5.8c2.3,0.9,4.9,0.1,6.9-1.9c2-2,3.4-5.2,3.2-8.8
					c-0.2-3.7-0.7-6.3-1.1-8.9c-0.4-2.5-0.7-5.1-0.8-8.6c-1.4,3.3-2.8,5.4-4.3,7.4C201.1,523.7,199.4,525.5,197.2,528.3z"
                  />
                </g>
                <g>
                  <path
                    d="M184.6,519.6c-3.4,1.1-6,3-7.7,5.3c-1.7,2.3-2.5,4.9-1.5,7.2c0.9,2.2,3.6,3.5,7,3.1c3.3-0.4,7.2-2.4,9.7-5.9
					c2.6-3.6,3.8-6.4,5-9.1c1.2-2.7,2.3-5.4,4-9.1c-2.9,2.8-5.4,4.3-7.8,5.4C190.7,517.6,188.1,518.4,184.6,519.6z"
                  />
                </g>
                <g>
                  <path
                    d="M179.7,503.2c-3.5-1.1-6.6-1.1-9.4-0.5c-0.7,0.2-1.4,0.3-2,0.6c-0.6,0.3-1.2,0.6-1.7,0.9c-1.1,0.7-1.9,1.6-2.3,2.8
					c-0.4,1.1-0.3,2.4,0.3,3.6c0.3,0.6,0.7,1.2,1.3,1.8c0.6,0.6,1.2,1.1,2,1.6c3.1,1.9,8,2.8,12.5,1.3c4.6-1.6,7.4-3.3,10.1-5.1
					c2.6-1.8,5.1-3.7,8.4-6.4c-4.1,1.4-7.1,1.6-9.9,1.4C186,504.9,183.3,504.3,179.7,503.2z"
                  />
                </g>
                <g>
                  <path
                    d="M185.6,484.9c-2.6-3.1-5.7-5.2-8.7-6.3c-3-1.1-5.9-1.1-7.7,0.5c-1.8,1.6-1.9,4.8-0.1,8.1c1.8,3.3,5.4,6.6,10.1,8
					c4.8,1.4,8.2,1.8,11.5,2.1c3.3,0.3,6.5,0.5,11.2,0.9c-4.3-1.8-6.8-3.7-9.1-5.8C190.4,490.4,188.4,488.1,185.6,484.9z"
                  />
                </g>
                <g>
                  <path
                    d="M195.9,472.3c-1.1-4.1-3-7.4-5.1-9.9c-0.6-0.6-1.1-1.2-1.7-1.7c-0.6-0.5-1.2-0.9-1.8-1.2c-1.2-0.6-2.4-0.9-3.6-0.7
					c-1.2,0.3-2.2,1.1-2.8,2.4c-0.3,0.6-0.6,1.4-0.8,2.2c-0.2,0.8-0.3,1.8-0.2,2.8c0.1,4,2,9,5.8,12.6c3.9,3.7,7,5.5,10.1,7.2
					c3.1,1.7,6.2,3.3,10.6,5.4c-3.6-3.3-5.4-6.2-6.8-9.1C198.2,479.5,197.1,476.5,195.9,472.3z"
                  />
                </g>
                <g>
                  <path
                    d="M209.1,469.2c0.9-3.8,1.3-6.8,1.4-9.5c0.1-1.3,0-2.7-0.1-4c-0.1-1.3-0.4-2.5-1.2-3.5c-0.8-0.9-2-1.4-3.6-1.2
					c-1.6,0.2-3.6,1.2-5.3,3c-1.7,1.8-3.1,4.4-3.7,7.3c-0.6,2.9-0.6,6,0.3,8.9c0.9,3,2.1,5.2,3.3,7c1.2,1.8,2.4,3.3,3.6,4.8
					c2.4,2.9,4.9,5.5,8.1,9.5c-2-4.8-2.9-8.3-3.3-11.7c-0.2-1.7-0.3-3.4-0.3-5.2C208.4,473,208.6,471.1,209.1,469.2z"
                  />
                </g>
                <g>
                  <path
                    d="M226.2,472.6c3.1-2.2,5.3-4.3,6.9-6.6c0.8-1.1,1.5-2.3,2.1-3.5c0.5-1.2,0.8-2.4,0.6-3.6c-0.2-1.2-1.1-2.2-2.5-2.8
					c-1.4-0.6-3.3-0.8-5.5-0.4c-2.2,0.4-4.5,1.5-6.6,3.3c-2,1.7-3.8,4-4.8,6.5c-1.1,2.7-1.6,4.9-1.9,6.9c-0.3,2-0.4,3.8-0.5,5.6
					c-0.1,3.5,0,6.9-0.1,11.8c1.5-4.6,3.1-7.6,5.1-10.1c1-1.3,2-2.5,3.2-3.6C223.3,474.9,224.6,473.7,226.2,472.6z"
                  />
                </g>
                <g>
                  <path
                    d="M236.8,481.9c4-0.8,7.3-2.3,9.9-4.3c0.6-0.5,1.2-1,1.7-1.5c0.5-0.5,1-1.1,1.3-1.6c0.7-1.1,1.1-2.3,1-3.6
					c-0.2-1.2-0.9-2.3-2.1-3.1c-0.6-0.4-1.3-0.8-2.1-1c-0.8-0.2-1.7-0.4-2.7-0.5c-3.9-0.3-9.1,1.3-12.8,4.9
					c-3.8,3.7-5.8,6.7-7.7,9.6c-1.8,3-3.5,6-5.6,10.2c3.4-3.4,6.2-5.1,9.1-6.3C229.7,483.6,232.7,482.8,236.8,481.9z"
                  />
                </g>
                <g>
                  <path
                    d="M245.2,494.6c4,0.9,7.4,1,10.3,0.8c1.5-0.1,2.9-0.4,4.1-0.8c1.2-0.4,2.4-1,3.1-2c0.7-1,0.8-2.3,0.3-3.8
					c-0.5-1.5-1.8-3-3.7-4.4c-1.9-1.3-4.3-2.3-7-2.7c-2.7-0.4-5.6-0.2-8.3,0.7c-2.8,0.9-4.9,2-6.7,3c-1.8,1-3.3,2.1-4.8,3.1
					c-3,2.1-5.8,4.2-10.1,6.8c4.9-1.3,8.4-1.8,11.8-1.8c1.7,0,3.4,0,5.2,0.2C241.2,493.9,243.1,494.1,245.2,494.6z"
                  />
                </g>
                <g>
                  <path
                    d="M211.7,496.8c-2.4,0.4-2.8,3.2-1.3,5c1.6,1.9,4.4,1.9,5.2-0.4c0.8-2.4,0.8-3.7,2-5.7
					C215.4,496.7,214.2,496.4,211.7,496.8z"
                  />
                </g>
                <g>
                  <path
                    d="M215,498.4c-1.1-0.4-2-0.1-2.7,0.6c-0.7,0.7-1.1,1.8-1,3c0.1,1.2,0.8,2.2,1.7,2.7c0.9,0.5,2.2,0.5,3.2-0.4
					c1-0.9,1.7-1.6,2.3-2.3c0.6-0.7,1.3-1.4,2.2-2.1c-1.2,0-2.1-0.2-2.9-0.4C217,499.2,216.2,498.9,215,498.4z"
                  />
                </g>
                <g>
                  <path
                    d="M215.5,501.1c-0.2-0.9-0.7-1.3-1.5-1.4c-0.8-0.1-1.9,0.1-3,0.8c-1,0.6-1.7,1.7-1.6,2.8c0.1,1.1,0.9,2.2,2.2,2.5
					c1.4,0.3,2.4,0.2,3.3,0.1c0.9-0.1,1.8-0.2,2.9-0.2c-0.9-0.7-1.3-1.4-1.6-2.1C215.9,502.9,215.7,502.1,215.5,501.1z"
                  />
                </g>
                <g>
                  <path
                    d="M213.9,504.3c0.4-0.3,0.6-0.7,0.8-1.1c0.1-0.5,0.1-1,0-1.6c-0.1-0.5-0.4-1.1-0.9-1.4c-0.4-0.4-1-0.6-1.6-0.7
					c-0.6-0.1-1.2-0.1-1.7,0c-0.5,0.1-1,0.2-1.4,0.4c-0.4,0.2-0.7,0.6-0.9,1c-0.2,0.5-0.3,1.1-0.2,1.9c0.1,0.8,0.3,1.5,0.6,2.1
					c0.3,0.6,0.6,1.1,0.9,1.5c0.7,0.8,1.5,1.6,2.7,2c-0.4-0.5-0.5-1-0.4-1.4c0-0.4,0.2-0.8,0.4-1.1c0.2-0.3,0.5-0.6,0.7-0.9
					C213.1,504.8,213.5,504.6,213.9,504.3z"
                  />
                </g>
                <g>
                  <path
                    d="M211.8,505.3c1.1-0.1,1.7-0.6,2.1-1.5c0.3-0.8,0.5-2,0-3.2c-0.4-1.1-1.4-1.9-2.6-2c-1.2-0.1-2.5,0.5-3.2,1.9
					c-0.7,1.4-0.9,2.4-1.1,3.4c-0.2,1-0.4,1.9-0.7,3.2c0.9-0.8,1.8-1.2,2.7-1.4C209.7,505.4,210.6,505.4,211.8,505.3z"
                  />
                </g>
                <g>
                  <path
                    d="M208.4,505.3c1.4,0.5,2.8,0.1,3.7-0.7c0.9-0.8,1.2-2,0.9-3.2c-0.3-1.2-1.2-2.1-2.1-2.5c-0.9-0.4-2-0.4-3,0.3
					c-1.1,0.7-1.9,1.3-2.7,1.8c-0.8,0.5-1.7,0.9-3.1,1.1c1.2,0.5,2.1,1,3,1.6C206,504.2,206.9,504.8,208.4,505.3z"
                  />
                </g>
                <g>
                  <path
                    d="M206.6,501.5c0.6,2.6,3.6,3.1,5.4,1.5c1.8-1.6,1.7-4.6-0.8-5.5c-2.6-1-3.9-1-6.1-2.3
					C206.1,497.5,205.9,498.8,206.6,501.5z"
                  />
                </g>
                <g>
                  <path
                    d="M207.3,497.9c-1.1,2.2,0.8,4.3,3.2,4.2c2.4-0.1,4.2-2.4,2.8-4.5c-1.4-2.2-2.4-3-3.3-5.2
					C209.5,494.8,208.5,495.6,207.3,497.9z"
                  />
                </g>
                <g>
                  <path
                    d="M210,496.6c-0.9,0.7-1.1,1.7-0.8,2.5c0.4,0.9,1.3,1.6,2.4,2c1.2,0.3,2.3,0.2,3.1-0.2c0.8-0.4,1.2-1.1,0.9-2.1
					c-0.5-2-1.2-3.1-0.9-5.1C213.2,495,211.9,495.2,210,496.6z"
                  />
                </g>
                <g>
                  <path
                    d="M246.4,509.3c3.3,2.5,6.7,4,9.9,4.5c3.2,0.5,6.1,0.1,7.6-1.9c1.5-1.9,0.9-5.1-1.5-8c-2.4-2.9-6.8-5.5-11.7-6
					c-5.1-0.5-8.5-0.1-11.9,0.2c-3.4,0.4-6.7,0.8-11.3,1.5c4.6,0.9,7.6,2.2,10.3,3.7C240.4,504.9,243,506.7,246.4,509.3z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M155.7,208.9c3.8,4.2,8.2,7.3,12.1,9.2c3.9,1.8,7.4,2.3,9.1,0.6c1.7-1.7,1.1-5.3-1.4-9.3c-2.6-3.9-7.1-8.1-12.8-10.5
					c-5.8-2.5-10-3.6-14.1-4.8c-4.1-1.1-8.3-2.3-14.2-4.1c5.3,3.1,8.6,5.8,11.6,8.7C148.9,201.6,151.7,204.6,155.7,208.9z"
                  />
                </g>
                <g>
                  <path
                    d="M144.9,218.2c1.7,4.7,4.2,8.8,6.9,11.6c2.7,2.8,5.6,4.4,7.9,3.4c2.2-0.9,3.3-4.2,2.6-8.5c-0.7-4.2-3.2-9.4-7.3-13.3
					c-4.2-4-7.5-6.2-10.7-8.5c-3.3-2.2-6.5-4.4-11.1-7.6c3.6,4.3,5.5,7.6,7.1,11C141.9,209.8,143.2,213.3,144.9,218.2z"
                  />
                </g>
                <g>
                  <path
                    d="M130,219.4c-0.3,3.7,0.5,7,2,9.6c1.5,2.5,3.7,4.3,6.1,4.2c2.4-0.1,4.5-2.2,5.4-5.4c0.9-3.2,0.5-7.6-1.7-11.3
					c-2.3-3.8-4.3-6.2-6.3-8.5c-2-2.3-3.9-4.5-6.5-7.8c1.3,4,1.6,6.8,1.6,9.7C130.6,212.7,130.3,215.5,130,219.4z"
                  />
                </g>
                <g>
                  <path
                    d="M111.8,214c-2.1,2.7-2.8,5.7-2.5,8.4c0.3,2.6,1.7,4.9,4,5.8c2.3,0.9,4.9,0.1,6.9-1.9c2-2,3.4-5.2,3.2-8.8
					c-0.2-3.7-0.7-6.3-1.1-8.9c-0.4-2.5-0.7-5.1-0.8-8.6c-1.4,3.3-2.8,5.4-4.3,7.4C115.7,209.3,114,211.2,111.8,214z"
                  />
                </g>
                <g>
                  <path
                    d="M99.2,205.2c-3.4,1.1-6,3-7.7,5.3c-1.7,2.3-2.5,4.9-1.5,7.2c0.9,2.2,3.6,3.5,7,3.1c3.3-0.4,7.2-2.4,9.7-5.9
					c2.6-3.6,3.8-6.4,5-9.1c1.2-2.7,2.3-5.4,4-9.1c-2.9,2.8-5.4,4.3-7.8,5.4C105.3,203.2,102.7,204.1,99.2,205.2z"
                  />
                </g>
                <g>
                  <path
                    d="M94.3,188.9c-3.5-1.1-6.6-1.1-9.4-0.5c-0.7,0.2-1.4,0.3-2,0.6c-0.6,0.3-1.2,0.6-1.7,0.9c-1.1,0.7-1.9,1.6-2.3,2.8
					c-0.4,1.1-0.3,2.4,0.3,3.6c0.3,0.6,0.7,1.2,1.3,1.8c0.6,0.6,1.2,1.1,2,1.6c3.1,1.9,8,2.8,12.5,1.3c4.6-1.6,7.4-3.3,10.1-5.1
					c2.6-1.8,5.1-3.7,8.4-6.4c-4.1,1.4-7.1,1.6-9.9,1.4C100.6,190.6,97.9,190,94.3,188.9z"
                  />
                </g>
                <g>
                  <path
                    d="M100.2,170.6c-2.6-3.1-5.7-5.2-8.7-6.3c-3-1.1-5.9-1.1-7.7,0.5c-1.8,1.6-1.9,4.8-0.1,8.1c1.8,3.3,5.4,6.6,10.1,8
					c4.8,1.4,8.2,1.8,11.5,2.1c3.3,0.3,6.5,0.5,11.2,0.9c-4.3-1.8-6.8-3.7-9.1-5.8C105,176.1,103,173.8,100.2,170.6z"
                  />
                </g>
                <g>
                  <path
                    d="M110.5,158c-1.1-4.1-3-7.4-5.1-9.9c-0.6-0.6-1.1-1.2-1.7-1.7c-0.6-0.5-1.2-0.9-1.8-1.2c-1.2-0.6-2.4-0.9-3.6-0.7
					c-1.2,0.3-2.2,1.1-2.8,2.4c-0.3,0.6-0.6,1.4-0.8,2.2c-0.2,0.8-0.3,1.8-0.2,2.8c0.1,4,2,9,5.8,12.6c3.9,3.7,7,5.5,10.1,7.2
					c3.1,1.7,6.2,3.3,10.6,5.4c-3.6-3.3-5.4-6.2-6.8-9.1C112.8,165.2,111.7,162.2,110.5,158z"
                  />
                </g>
                <g>
                  <path
                    d="M123.7,154.9c0.9-3.8,1.3-6.8,1.4-9.5c0.1-1.3,0-2.7-0.1-4c-0.1-1.3-0.4-2.5-1.2-3.5c-0.8-0.9-2-1.4-3.6-1.2
					c-1.6,0.2-3.6,1.2-5.3,3c-1.7,1.8-3.1,4.4-3.7,7.3c-0.6,2.9-0.6,6,0.3,8.9c0.9,3,2.1,5.2,3.3,7c1.2,1.8,2.4,3.3,3.6,4.8
					c2.4,2.9,4.9,5.5,8.1,9.5c-2-4.8-2.9-8.3-3.3-11.7c-0.2-1.7-0.3-3.4-0.3-5.2C123,158.7,123.2,156.8,123.7,154.9z"
                  />
                </g>
                <g>
                  <path
                    d="M140.8,158.2c3.1-2.2,5.3-4.3,6.9-6.6c0.8-1.1,1.5-2.3,2.1-3.5c0.5-1.2,0.8-2.4,0.6-3.6c-0.2-1.2-1.1-2.2-2.5-2.8
					c-1.4-0.6-3.3-0.8-5.5-0.4c-2.2,0.4-4.5,1.5-6.6,3.3c-2,1.7-3.8,4-4.8,6.5c-1.1,2.7-1.6,4.9-1.9,6.9c-0.3,2-0.4,3.8-0.5,5.6
					c-0.1,3.5,0,6.9-0.1,11.8c1.5-4.6,3.1-7.6,5.1-10.1c1-1.3,2-2.5,3.2-3.6C137.9,160.6,139.2,159.4,140.8,158.2z"
                  />
                </g>
                <g>
                  <path
                    d="M151.4,167.6c4-0.8,7.3-2.3,9.9-4.3c0.6-0.5,1.2-1,1.7-1.5c0.5-0.5,1-1.1,1.3-1.6c0.7-1.1,1.1-2.3,1-3.6
					c-0.2-1.2-0.9-2.3-2.1-3.1c-0.6-0.4-1.3-0.8-2.1-1c-0.8-0.2-1.7-0.4-2.7-0.5c-3.9-0.3-9.1,1.3-12.8,4.9
					c-3.8,3.7-5.8,6.7-7.7,9.6c-1.8,3-3.5,6-5.6,10.2c3.4-3.4,6.2-5.1,9.1-6.3C144.2,169.3,147.3,168.5,151.4,167.6z"
                  />
                </g>
                <g>
                  <path
                    d="M159.8,180.2c4,0.9,7.4,1,10.3,0.8c1.5-0.1,2.9-0.4,4.1-0.8c1.2-0.4,2.4-1,3.1-2c0.7-1,0.8-2.3,0.3-3.8
					c-0.5-1.5-1.8-3-3.7-4.4c-1.9-1.3-4.3-2.3-7-2.7c-2.7-0.4-5.6-0.2-8.3,0.7c-2.8,0.9-4.9,2-6.7,3c-1.8,1-3.3,2.1-4.8,3.1
					c-3,2.1-5.8,4.2-10.1,6.8c4.9-1.3,8.4-1.8,11.8-1.8c1.7,0,3.4,0,5.2,0.2C155.8,179.5,157.7,179.8,159.8,180.2z"
                  />
                </g>
                <g>
                  <path
                    d="M126.3,182.5c-2.4,0.4-2.8,3.2-1.3,5c1.6,1.9,4.4,1.9,5.2-0.4c0.8-2.4,0.8-3.7,2-5.7C130,182.3,128.8,182.1,126.3,182.5
					z"
                  />
                </g>
                <g>
                  <path
                    d="M129.6,184.1c-1.1-0.4-2-0.1-2.7,0.6c-0.7,0.7-1.1,1.8-1,3c0.1,1.2,0.8,2.2,1.7,2.7c0.9,0.5,2.2,0.5,3.2-0.4
					c1-0.9,1.7-1.6,2.3-2.3c0.6-0.7,1.3-1.4,2.2-2.1c-1.2,0-2.1-0.2-2.9-0.4C131.6,184.9,130.8,184.5,129.6,184.1z"
                  />
                </g>
                <g>
                  <path
                    d="M130.1,186.7c-0.2-0.9-0.7-1.3-1.5-1.4c-0.8-0.1-1.9,0.1-3,0.8c-1,0.6-1.7,1.7-1.6,2.8c0.1,1.1,0.9,2.2,2.2,2.5
					c1.4,0.3,2.4,0.2,3.3,0.1c0.9-0.1,1.8-0.2,2.9-0.2c-0.9-0.7-1.3-1.4-1.6-2.1C130.5,188.5,130.3,187.7,130.1,186.7z"
                  />
                </g>
                <g>
                  <path
                    d="M128.5,190c0.4-0.3,0.6-0.7,0.8-1.1c0.1-0.5,0.1-1,0-1.6c-0.1-0.5-0.4-1.1-0.9-1.4c-0.4-0.4-1-0.6-1.6-0.7
					c-0.6-0.1-1.2-0.1-1.7,0c-0.5,0.1-1,0.2-1.4,0.4c-0.4,0.2-0.7,0.6-0.9,1c-0.2,0.5-0.3,1.1-0.2,1.9c0.1,0.8,0.3,1.5,0.6,2.1
					c0.3,0.6,0.6,1.1,0.9,1.5c0.7,0.8,1.5,1.6,2.7,2c-0.4-0.5-0.5-1-0.4-1.4c0-0.4,0.2-0.8,0.4-1.1c0.2-0.3,0.5-0.6,0.7-0.9
					C127.7,190.4,128.1,190.3,128.5,190z"
                  />
                </g>
                <g>
                  <path
                    d="M126.4,190.9c1.1-0.1,1.7-0.6,2.1-1.5c0.3-0.8,0.5-2,0-3.2c-0.4-1.1-1.4-1.9-2.6-2c-1.2-0.1-2.5,0.5-3.2,1.9
					c-0.7,1.4-0.9,2.4-1.1,3.4c-0.2,1-0.4,1.9-0.7,3.2c0.9-0.8,1.8-1.2,2.7-1.4C124.3,191.1,125.2,191,126.4,190.9z"
                  />
                </g>
                <g>
                  <path
                    d="M123,191c1.4,0.5,2.8,0.1,3.7-0.7c0.9-0.8,1.2-2,0.9-3.2c-0.3-1.2-1.2-2.1-2.1-2.5c-0.9-0.4-2-0.4-3,0.3
					c-1.1,0.7-1.9,1.3-2.7,1.8c-0.8,0.5-1.7,0.9-3.1,1.1c1.2,0.5,2.1,1,3,1.6C120.6,189.9,121.5,190.4,123,191z"
                  />
                </g>
                <g>
                  <path
                    d="M121.2,187.2c0.6,2.6,3.6,3.1,5.4,1.5c1.8-1.6,1.7-4.6-0.8-5.5c-2.6-1-3.9-1-6.1-2.3
					C120.7,183.2,120.5,184.5,121.2,187.2z"
                  />
                </g>
                <g>
                  <path
                    d="M121.9,183.6c-1.1,2.2,0.8,4.3,3.2,4.2c2.4-0.1,4.2-2.4,2.8-4.5c-1.4-2.2-2.4-3-3.3-5.2
					C124.1,180.4,123.1,181.3,121.9,183.6z"
                  />
                </g>
                <g>
                  <path
                    d="M124.6,182.2c-0.9,0.7-1.1,1.7-0.8,2.5c0.4,0.9,1.3,1.6,2.4,2c1.2,0.3,2.3,0.2,3.1-0.2c0.8-0.4,1.2-1.1,0.9-2.1
					c-0.5-2-1.2-3.1-0.9-5.1C127.8,180.7,126.5,180.9,124.6,182.2z"
                  />
                </g>
                <g>
                  <path
                    d="M161,194.9c3.3,2.5,6.7,4,9.9,4.5c3.2,0.5,6.1,0.1,7.6-1.9c1.5-1.9,0.9-5.1-1.5-8c-2.4-2.9-6.8-5.5-11.7-6
					c-5.1-0.5-8.5-0.1-11.9,0.2c-3.4,0.4-6.7,0.8-11.3,1.5c4.6,0.9,7.6,2.2,10.3,3.7C155,190.5,157.6,192.3,161,194.9z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M353.7,131.6c-6.1,1.4-12.7,4.3-17.8,8c-2.6,1.8-4.9,3.9-7,6c-2,2.1-3.7,4.3-5.1,6.4c-2.8,4.3-4.2,8.4-4.6,11.5
					c-0.2,1.6-0.1,2.9,0.2,3.9c0.3,1,0.9,1.8,1.6,2.2c0.8,0.4,1.7,0.6,2.7,0.4c1-0.2,2.2-0.6,3.5-1.2c2.5-1.2,5.5-3.2,8.8-5.5
					c1.6-1.1,3.4-2.3,5.2-3.6c1.8-1.2,3.8-2.5,5.8-3.8c4-2.7,8.2-5.1,13.1-8c5-3,9.8-5.5,14-8.2c4.2-2.6,8-5.4,11.3-8.5
					c1.7-1.5,3.3-3.1,4.8-4.8c1.6-1.7,3.1-3.5,4.6-5.5c3-3.9,6-8.5,8.6-14.3c-3.6,5.3-7.4,9-11.1,11.8c-1.9,1.4-3.7,2.6-5.5,3.7
					c-1.8,1.1-3.7,2-5.5,2.7c-3.7,1.6-7.5,2.6-11.9,3.5C365,129.4,360,130.1,353.7,131.6z"
                  />
                </g>
                <g>
                  <path
                    d="M362.6,150.6c-5,2.2-10,4.1-14.7,5.9c-4.7,1.7-9.2,3.3-13.1,4.7c-4,1.4-7.4,2.8-9.9,4.2c-2.5,1.4-4.1,2.9-4.3,4.7
					c-0.1,1.7,1.3,3.5,4.2,5c2.8,1.5,7.1,2.5,12.3,2.7c5.2,0.2,11.2-0.6,17.4-2.6c6.1-2.1,12.3-5.3,17.6-9.8
					c5.4-4.6,9.3-9,12.4-13.3c3.1-4.3,5.4-8.3,7.5-12.4c2-4.1,3.8-8.4,5.4-13.2c1.6-4.8,3-10.2,4-16.7c-2,6.2-4.5,11.1-7.2,15.2
					c-2.6,4.1-5.4,7.4-8.4,10.3c-3,3-6.2,5.6-10,8.1C372,145.9,367.8,148.3,362.6,150.6z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M401.3,173.5c-6-1.9-13.1-2.8-19.4-2.2c-3.2,0.3-6.2,0.8-9,1.6c-2.8,0.8-5.4,1.8-7.7,2.9c-4.6,2.3-7.9,5-9.8,7.5
					c-1,1.2-1.6,2.4-1.8,3.5c-0.3,1.1-0.2,2,0.3,2.7c0.4,0.8,1.2,1.3,2.2,1.7c1,0.4,2.2,0.6,3.6,0.8c2.8,0.2,6.3,0.1,10.3-0.2
					c2-0.1,4.1-0.3,6.4-0.4c2.2-0.1,4.5-0.3,6.9-0.4c4.8-0.2,9.7-0.2,15.4-0.2c5.9,0,11.2,0.3,16.2,0.1c5-0.1,9.7-0.6,14.1-1.5
					c2.2-0.4,4.4-1,6.6-1.7c2.2-0.7,4.4-1.4,6.7-2.4c4.6-1.9,9.5-4.3,14.7-7.9c-5.8,2.7-10.9,3.9-15.6,4.5c-2.3,0.3-4.5,0.4-6.7,0.3
					c-2.1,0-4.2-0.2-6.2-0.5c-4-0.5-7.8-1.6-12-3C412.2,177.4,407.6,175.5,401.3,173.5z"
                  />
                </g>
                <g>
                  <path
                    d="M399.3,194.3c-5.4-0.7-10.7-1.6-15.6-2.5c-4.9-0.9-9.6-1.9-13.7-2.7c-4.1-0.8-7.8-1.4-10.7-1.5
					c-2.9-0.1-5.1,0.4-6.1,1.9c-1,1.4-0.6,3.7,1.1,6.4c1.7,2.7,4.8,5.8,9.2,8.6c4.3,2.8,9.9,5.3,16.3,6.7c6.3,1.4,13.3,1.8,20.1,0.6
					c7-1.1,12.6-3,17.5-5c4.8-2.1,8.9-4.4,12.8-6.9c3.9-2.5,7.6-5.3,11.4-8.6c3.8-3.3,7.8-7.3,12-12.3c-4.9,4.3-9.6,7.2-13.9,9.4
					c-4.4,2.2-8.4,3.5-12.5,4.6c-4.1,1-8.2,1.6-12.7,1.9C409.8,195.2,404.9,195.1,399.3,194.3z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M143.2,295.9c4-4.8,7.5-11.1,9.3-17.2c0.9-3.1,1.6-6.1,1.9-9c0.3-2.9,0.3-5.7,0.2-8.2c-0.4-5.1-1.7-9.2-3.3-11.9
					c-0.8-1.4-1.7-2.4-2.6-3c-0.9-0.6-1.8-0.9-2.6-0.8c-0.9,0.1-1.7,0.6-2.4,1.3c-0.7,0.8-1.4,1.8-2,3.1c-1.3,2.5-2.4,5.9-3.7,9.7
					c-0.6,1.9-1.3,3.9-2,6.1c-0.7,2.1-1.4,4.3-2.2,6.6c-1.6,4.6-3.4,9-5.5,14.3c-2.2,5.4-4.4,10.3-6.2,15c-1.7,4.7-3.1,9.2-3.8,13.6
					c-0.4,2.2-0.7,4.5-0.9,6.8c-0.2,2.3-0.3,4.7-0.3,7.1c0,4.9,0.4,10.4,1.8,16.6c-0.4-6.3,0.4-11.6,1.6-16.1
					c0.6-2.3,1.3-4.4,2.2-6.3c0.8-2,1.7-3.8,2.7-5.5c2-3.5,4.4-6.6,7.3-10C135.6,304.6,139.1,300.9,143.2,295.9z"
                  />
                </g>
                <g>
                  <path
                    d="M124.7,286.2c2.7-4.8,5.5-9.3,8.1-13.6c2.7-4.3,5.3-8.2,7.6-11.7c2.3-3.5,4.2-6.7,5.4-9.3c1.2-2.6,1.5-4.9,0.5-6.3
					c-1-1.5-3.2-2-6.4-1.4c-3.2,0.6-7.2,2.3-11.4,5.3c-4.2,3-8.6,7.3-12.2,12.6c-3.6,5.4-6.6,11.7-8.1,18.4
					c-1.5,6.9-1.9,12.8-1.8,18.1c0.1,5.3,0.7,9.9,1.6,14.4c0.9,4.5,2.1,9,3.7,13.8c1.7,4.8,3.8,9.9,7,15.7
					c-2.2-6.2-3.2-11.6-3.5-16.4c-0.4-4.8-0.2-9.2,0.4-13.3c0.6-4.2,1.6-8.2,3-12.5C120,295.7,121.9,291.1,124.7,286.2z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M453.4,473.1c-7.8,2.4-14.6,6.2-19.4,10.2c-4.8,4-7.6,8.2-6.4,11.6c1.2,3.3,6.3,4.8,13,3.8c6.7-1,14.9-4.4,21.5-10.4
					c6.8-6.1,10.9-10.9,14.9-15.6c4-4.7,8-9.4,14-16.1c-7.3,5.1-12.9,7.7-18.4,9.9C467.1,468.8,461.4,470.6,453.4,473.1z"
                  />
                </g>
                <g>
                  <path
                    d="M447.9,453.4c-7.2-0.7-14.1,0.2-19.4,2c-5.3,1.8-9.1,4.7-9.3,8.2c-0.2,3.5,3.5,6.8,9.5,8.5c6,1.7,14.2,1.6,21.8-1.4
					c7.8-3.1,12.7-6,17.6-8.9c4.9-2.9,9.7-5.8,16.7-9.9c-7.8,2.1-13.3,2.5-18.7,2.5C460.7,454.5,455.3,454.1,447.9,453.4z"
                  />
                </g>
                <g>
                  <path
                    d="M455.3,433.2c-4.7-2.7-9.5-3.7-13.7-3.3c-4.2,0.4-7.9,2.2-9.3,5.4c-1.4,3.2,0.1,7.2,3.8,10.4c3.7,3.1,9.6,5.3,15.9,4.7
					c6.4-0.7,10.7-1.9,14.9-3.1c4.2-1.2,8.3-2.4,14.1-3.8c-6-0.7-9.9-2.1-13.6-3.8C463.7,438,460.2,435.9,455.3,433.2z"
                  />
                </g>
                <g>
                  <path
                    d="M473.5,412.7c-2.2-4.4-5.7-7.2-9.4-8.4c-3.7-1.2-7.5-0.8-10.1,1.7c-2.6,2.4-3.1,6.3-1.8,10.2c1.4,3.9,4.7,7.6,9.6,9.6
					c5,2,8.7,2.9,12.3,3.9c3.6,1,7.1,2.1,11.8,4.3c-3.4-3.8-5.3-7-7-10.2C477.3,420.6,475.8,417.2,473.5,412.7z"
                  />
                </g>
                <g>
                  <path
                    d="M492.7,401.5c0.7-5.1-0.3-9.7-2.2-13.3c-1.9-3.6-4.9-6.3-8.4-6.4c-3.5-0.1-6.7,2.6-8.3,7.2c-1.5,4.5-1.3,10.8,1.8,16.3
					c3.2,5.6,6.1,8.9,8.9,12.1c2.8,3.2,5.6,6.3,9.4,10.8c-1.9-5.5-2.3-9.6-2.3-13.6C491.6,410.7,492,406.8,492.7,401.5z"
                  />
                </g>
                <g>
                  <path
                    d="M517.1,405c3.5-3.9,5.5-8,6.4-12c0.2-1,0.4-2,0.4-3c0-1,0-1.9-0.1-2.8c-0.3-1.8-1-3.5-2.2-4.7c-1.3-1.2-2.9-1.9-4.9-1.8
					c-1,0-2.1,0.2-3.1,0.6c-1.1,0.4-2.2,0.9-3.3,1.6c-4.4,2.8-8.5,8.7-9.3,15.5c-0.8,7-0.1,11.7,0.6,16.3c0.8,4.5,1.7,8.9,3.2,15
					c0.6-6.2,2.3-10.2,4.3-13.8C511,412.3,513.5,409,517.1,405z"
                  />
                </g>
                <g>
                  <path
                    d="M537.4,423.9c5.7-1.6,10.3-4.3,13.5-7.6c3.2-3.2,5.1-7.1,4.1-10.4c-1-3.3-5.1-5.3-10.5-5c-5.4,0.3-11.9,3.1-16.6,8.4
					c-4.8,5.4-7.3,9.6-9.8,13.8c-2.3,4.1-4.7,8.2-8.1,14.1c5-4.5,9-6.7,13.1-8.4C527.3,426.9,531.5,425.6,537.4,423.9z"
                  />
                </g>
                <g>
                  <path
                    d="M547.6,445.1c6,1,11.5,0.6,16-0.7c1.1-0.4,2.2-0.7,3.2-1.2c1-0.5,1.9-1,2.7-1.6c1.6-1.2,2.7-2.6,3.1-4.4
					c0.4-1.7-0.1-3.5-1.4-5.2c-0.6-0.8-1.4-1.7-2.5-2.4c-1-0.7-2.2-1.5-3.5-2c-5.2-2.3-13-2.8-20,0c-7.2,2.9-11.5,5.9-15.7,8.8
					c-4.1,3-8.1,6.1-13.5,10.5c6.6-2.7,11.4-3.3,16-3.4C536.8,443.6,541.4,444.1,547.6,445.1z"
                  />
                </g>
                <g>
                  <path
                    d="M543.7,464.2c4.4,3.5,8.1,5.9,11.5,7.7c1.7,0.9,3.5,1.7,5.2,2.3c1.7,0.6,3.5,1,5.3,0.6c1.7-0.4,3.1-1.7,3.8-4
					c0.7-2.2,0.6-5.4-0.8-8.8c-1.3-3.3-3.9-6.8-7.2-9.3c-3.4-2.6-7.5-4.4-11.9-5c-4.5-0.6-8.1-0.4-11.2,0c-3.1,0.4-5.8,1.1-8.4,1.8
					c-5.2,1.4-10.1,3.1-17.4,4.9c7.5,0.3,12.6,1.2,17.3,2.7c2.3,0.8,4.6,1.7,6.9,2.8C539.1,461,541.5,462.4,543.7,464.2z"
                  />
                </g>
                <g>
                  <path
                    d="M528.8,484.6c1,5.4,2.5,9.5,4.4,13.1c1,1.8,2.1,3.4,3.3,4.8c1.2,1.4,2.7,2.5,4.4,2.9c1.7,0.4,3.5,0,5.2-1.5
					c1.7-1.4,3.1-3.9,3.9-7c0.7-3.1,0.7-6.9-0.2-10.6c-1-3.7-2.9-7.4-5.6-10.3c-2.8-3.1-5.4-5.1-7.9-6.8c-2.5-1.7-4.7-2.9-7-4.1
					c-4.6-2.3-9.1-4.3-15.4-7.3c5.1,4.8,8,8.7,10.2,12.8c1.1,2,2,4.1,2.8,6.4C527.6,479.3,528.3,481.8,528.8,484.6z"
                  />
                </g>
                <g>
                  <path
                    d="M510.1,492.7c-1.4,5.7-1.4,11-0.4,15.5c0.3,1.1,0.5,2.2,0.9,3.2c0.4,1,0.8,1.9,1.3,2.7c1,1.6,2.4,2.9,4.1,3.4
					c1.7,0.5,3.5,0.2,5.3-0.9c0.9-0.5,1.8-1.3,2.6-2.2c0.8-0.9,1.6-2,2.3-3.3c2.7-5,3.8-12.7,1.5-19.8c-2.4-7.3-5.2-11.7-7.9-15.9
					c-2.8-4.2-5.7-8.2-10-13.6c2.4,6.5,2.8,11.3,2.7,15.8C512.2,482.3,511.5,486.8,510.1,492.7z"
                  />
                </g>
                <g>
                  <path
                    d="M488.5,496c-3.6,4.8-5.9,9-7.3,13c-0.7,2-1.3,4-1.5,5.9c-0.2,1.9-0.1,3.7,0.8,5.3c0.9,1.5,2.5,2.5,4.7,2.7
					c2.2,0.2,5.1-0.5,7.9-2.1c2.8-1.6,5.7-4.3,7.8-7.6c2.1-3.3,3.6-7.2,4.1-11.2c0.5-4.2,0.4-7.6,0.2-10.6c-0.3-3-0.7-5.6-1.1-8.2
					c-0.9-5.1-1.9-10.1-2.8-17.4c-1.3,7.2-2.8,12.1-4.8,16.5c-1,2.2-2.1,4.4-3.4,6.7C491.8,491.2,490.3,493.6,488.5,496z"
                  />
                </g>
                <g>
                  <path
                    d="M505.9,450.8c0.9-3.4-2.4-5.6-5.8-4.7c-3.4,0.9-5.2,4.5-2.7,7c2.6,2.5,4.3,3.3,6.2,6.1
					C503.8,455.8,505,454.3,505.9,450.8z"
                  />
                </g>
                <g>
                  <path
                    d="M501.8,454.2c1.2-1.2,1.4-2.6,0.9-3.9c-0.5-1.3-1.7-2.5-3.4-3.1c-1.7-0.6-3.4-0.4-4.6,0.6c-1.2,0.9-1.9,2.6-1.4,4.4
					c0.5,1.9,1.1,3.2,1.6,4.5c0.5,1.3,1,2.5,1.4,4.2c0.7-1.6,1.5-2.6,2.4-3.6C499.5,456.3,500.5,455.4,501.8,454.2z"
                  />
                </g>
                <g>
                  <path
                    d="M498,453.2c1.3,0.3,2.1-0.1,2.7-1.2c0.6-1,1-2.6,0.8-4.4c-0.2-1.7-1.2-3.2-2.7-3.8c-1.5-0.6-3.4-0.2-4.6,1.4
					c-1.2,1.6-1.7,3-2.2,4.2c-0.4,1.3-0.8,2.4-1.5,3.9c1.4-0.8,2.6-0.9,3.7-0.9C495.5,452.6,496.6,452.9,498,453.2z"
                  />
                </g>
                <g>
                  <path
                    d="M494.8,449.1c0.1,0.7,0.5,1.2,1,1.7c0.5,0.4,1.3,0.8,2.1,0.9c0.8,0.1,1.7,0.1,2.4-0.3c0.8-0.3,1.4-0.9,1.9-1.6
					c0.5-0.7,0.8-1.5,1.1-2.2c0.2-0.7,0.3-1.4,0.3-2.1c-0.1-0.7-0.3-1.3-0.8-1.8c-0.5-0.6-1.3-1.1-2.4-1.4c-1.1-0.3-2.2-0.5-3.1-0.5
					c-0.9,0-1.7,0.1-2.5,0.3c-1.5,0.4-3,0.9-4.2,2.3c0.8-0.2,1.5,0,2.1,0.3c0.5,0.3,0.9,0.7,1.2,1.2c0.3,0.5,0.5,1,0.7,1.5
					C494.6,447.8,494.6,448.4,494.8,449.1z"
                  />
                </g>
                <g>
                  <path
                    d="M494.8,445.7c-0.6,1.5-0.3,2.6,0.6,3.6c0.9,1,2.4,1.8,4.1,2c1.7,0.2,3.4-0.6,4.2-2.1c0.9-1.5,0.9-3.7-0.5-5.4
					c-1.4-1.8-2.6-2.7-3.8-3.6c-1.2-0.9-2.3-1.7-3.7-2.8c0.5,1.8,0.5,3.1,0.2,4.4C495.9,443,495.4,444.2,494.8,445.7z"
                  />
                </g>
                <g>
                  <path
                    d="M496.9,441.3c-1.5,1.5-1.9,3.6-1.4,5.2c0.5,1.6,1.9,2.8,3.6,3.1c1.7,0.3,3.4-0.2,4.5-1.2c1.1-1,1.7-2.3,1.5-4.1
					c-0.3-1.8-0.6-3.2-0.7-4.6c-0.1-1.4-0.1-2.8,0.5-4.7c-1.5,1.3-2.6,2.1-3.9,3C499.7,438.9,498.4,439.7,496.9,441.3z"
                  />
                </g>
                <g>
                  <path d="M502.9,441.2c-3.7-0.8-6.3,2.7-5.3,6.1c1,3.4,5,5,7.7,2.3c2.8-2.8,3.6-4.5,6.8-6.6C508.4,443.1,506.8,442,502.9,441.2z" />
                </g>
                <g>
                  <path
                    d="M507.1,444.4c-2.2-2.9-6.2-1.6-7.5,1.7c-1.3,3.3,0.6,6.9,4.2,6.5c3.7-0.5,5.4-1.4,8.9-1.1
					C510,449.2,509.4,447.3,507.1,444.4z"
                  />
                </g>
                <g>
                  <path
                    d="M507.3,448.7c-0.3-1.6-1.5-2.5-2.9-2.6c-1.4-0.1-2.9,0.6-4.1,2c-1.2,1.3-1.7,2.9-1.7,4.1c0.1,1.3,0.7,2.2,2.2,2.4
					c3,0.6,4.8,0.4,7.3,1.9C507.4,453.8,507.9,452.1,507.3,448.7z"
                  />
                </g>
                <g>
                  <path
                    d="M468.5,488.6c-5.3,2.7-9.3,6.4-11.9,10.2c-2.6,3.8-3.8,7.9-2.1,11.1c1.6,3.1,6.1,4.3,11.4,2.9c5.3-1.4,11.3-5.5,15-11.7
					c3.7-6.3,5.4-11.1,7-15.7c1.5-4.7,3-9.2,5-15.7c-3.9,5.5-7.5,8.6-11.2,11.3C477.9,483.5,474,485.7,468.5,488.6z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M426.3,566.6c5.8-2.4,11.9-6.2,16.3-10.7c2.3-2.2,4.3-4.6,5.9-7c1.7-2.4,3-4.8,4-7.2c2.1-4.7,2.9-8.9,2.7-12.1
					c-0.1-1.6-0.4-2.9-0.8-3.9c-0.5-1-1.1-1.6-2-1.9c-0.8-0.3-1.8-0.3-2.8,0c-1,0.3-2.1,0.9-3.2,1.7c-2.3,1.6-4.9,4-7.8,6.8
					c-1.4,1.4-3,2.8-4.6,4.4c-1.6,1.5-3.3,3.1-5.1,4.7c-3.5,3.3-7.3,6.4-11.7,10c-4.5,3.7-8.8,7-12.5,10.3c-3.8,3.3-7,6.6-9.9,10.2
					c-1.4,1.8-2.7,3.6-4,5.5c-1.3,1.9-2.5,4-3.7,6.1c-2.3,4.4-4.5,9.3-6.2,15.5c2.7-5.8,5.9-10,9.1-13.4c1.6-1.7,3.2-3.2,4.9-4.5
					c1.6-1.3,3.3-2.5,5-3.6c3.4-2.1,7-3.8,11.2-5.4C415.5,570.6,420.3,569,426.3,566.6z"
                  />
                </g>
                <g>
                  <path
                    d="M414.5,549.3c4.6-2.9,9.2-5.6,13.6-8.1c4.4-2.5,8.6-4.7,12.2-6.7c3.7-2,6.9-3.9,9.1-5.7c2.3-1.8,3.6-3.6,3.5-5.3
					c-0.2-1.7-1.9-3.3-4.9-4.3c-3-1-7.4-1.4-12.6-0.7c-5.1,0.6-11,2.3-16.8,5.3c-5.7,3-11.4,7.2-15.8,12.4
					c-4.6,5.4-7.8,10.4-10.2,15.1c-2.4,4.7-4.1,9.1-5.4,13.5c-1.4,4.4-2.4,8.9-3.2,13.9c-0.8,5-1.3,10.6-1.3,17.1
					c1-6.5,2.7-11.7,4.7-16.1c2-4.4,4.2-8.1,6.7-11.5c2.5-3.4,5.3-6.5,8.5-9.6C405.9,555.4,409.8,552.3,414.5,549.3z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M372.6,532.7c6.2,0.9,13.4,0.7,19.5-0.9c3.1-0.8,6-1.8,8.7-3c2.7-1.2,5-2.6,7.1-4.1c4.2-3,7-6.2,8.5-9
					c0.7-1.4,1.2-2.7,1.2-3.7c0.1-1.1-0.1-2-0.7-2.7c-0.5-0.7-1.4-1.1-2.4-1.4c-1-0.2-2.3-0.3-3.7-0.2c-2.8,0.2-6.3,0.9-10.2,1.8
					c-1.9,0.4-4,0.9-6.2,1.4c-2.2,0.5-4.4,1-6.8,1.5c-4.7,1-9.5,1.8-15.1,2.6c-5.8,0.9-11.1,1.5-16,2.4c-4.9,0.9-9.4,2.1-13.7,3.7
					c-2.1,0.8-4.2,1.7-6.3,2.7c-2.1,1-4.2,2.1-6.3,3.4c-4.2,2.6-8.7,5.7-13.3,10.1c5.3-3.6,10.2-5.6,14.7-6.9
					c2.3-0.6,4.4-1.1,6.5-1.4c2.1-0.3,4.1-0.5,6.2-0.5c4-0.1,7.9,0.3,12.4,1.1C361.2,530.7,366.2,531.8,372.6,532.7z"
                  />
                </g>
                <g>
                  <path
                    d="M371.3,511.9c5.5-0.2,10.8-0.1,15.8,0c5,0.1,9.8,0.3,14,0.5c4.2,0.1,7.9,0.1,10.8-0.2c2.9-0.4,4.9-1.2,5.7-2.8
					c0.7-1.6,0.1-3.8-2.1-6.2c-2.1-2.4-5.7-5-10.4-7.1c-4.7-2.1-10.7-3.6-17.1-4c-6.5-0.3-13.4,0.4-20,2.6c-6.7,2.2-12,4.9-16.5,7.7
					c-4.4,2.8-8.1,5.7-11.6,8.8c-3.4,3.1-6.6,6.4-9.9,10.3c-3.2,3.9-6.6,8.4-9.8,14c4.2-5,8.3-8.7,12.2-11.5c4-2.8,7.8-4.8,11.6-6.5
					c3.9-1.6,7.8-2.9,12.2-3.9C360.8,512.7,365.7,512,371.3,511.9z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M514.4,139c10.6-1.1,21.3-3.7,30.7-7.7c9.4-3.9,17.6-9.3,23.7-14.8c6.1-5.5,10.2-11.1,12.5-15.3
					c2.3-4.2,2.9-6.9,2.1-7.4c-1.6-1-7.7,7.5-19.9,16.2c-6.1,4.3-13.6,8.7-22.2,12.2c-8.6,3.5-18.2,6.3-28.2,8.1
					c-10.2,1.9-19.1,2.6-27.2,3.2c-8.1,0.6-15.5,1.1-23,1.9c-7.4,0.8-14.9,1.9-23.1,4.1c-4.1,1.1-8.3,2.5-12.7,4.4
					c-4.4,1.9-8.9,4.3-13.4,7.6c9.3-6,18.4-8.6,26.5-10.2c8.2-1.5,15.6-1.9,22.9-2c7.4-0.1,14.7,0.2,22.9,0.5
					C494.2,139.9,503.4,140.1,514.4,139z"
                  />
                </g>
                <g>
                  <path
                    d="M458.8,128.6c2.7-1.5,4.5-4,5.1-6.3c0.6-2.3,0-4.4-1.5-5.3c-1.5-0.9-3.5-0.6-5.3,0.4c-1.8,1-3.5,2.7-4.6,5.2
					c-1.1,2.5-2,4.3-3,6c-1,1.7-2.2,3.4-4.4,5.4c2.8-1.1,4.7-1.7,6.8-2.4C454,130.8,456,130.1,458.8,128.6z"
                  />
                </g>
                <g>
                  <path
                    d="M475.9,123.1c2.3-1.3,3.6-3.6,3.8-5.6c0.2-2.1-0.6-3.8-2.1-4.7c-1.6-0.8-3.4-0.6-5,0.2c-1.6,0.8-2.9,2.3-3.5,4.4
					c-0.7,2.2-1.1,3.8-1.7,5.4c-0.6,1.6-1.4,3.1-3,5c2.3-1,3.9-1.6,5.6-2.2C471.8,125,473.6,124.4,475.9,123.1z"
                  />
                </g>
                <g>
                  <path
                    d="M493,119.2c2.2-1.6,3.5-4,3.7-6.1c0.2-2.1-0.5-4-2.1-4.8c-1.5-0.8-3.5-0.5-5.1,0.5c-1.7,1.1-3.1,2.9-3.7,5.3
					c-0.6,2.5-1,4.3-1.5,6.1c-0.5,1.8-1.2,3.6-2.5,5.8c2.2-1.6,3.8-2.5,5.5-3.5C488.9,121.7,490.7,120.8,493,119.2z"
                  />
                </g>
                <g>
                  <path
                    d="M512.9,117.7c2.4-2.2,3.5-5.3,3.4-7.8c-0.1-2.5-1.3-4.3-3-4.6c-1.7-0.4-3.5,0.6-4.9,2c-1.4,1.4-2.5,3.4-2.9,5.9
					c-0.5,2.6-1,4.5-1.6,6.3c-0.7,1.8-1.6,3.7-3.7,5.8c2.7-1.3,4.5-2.3,6.5-3.3C508.4,121,510.4,119.9,512.9,117.7z"
                  />
                </g>
                <g>
                  <path
                    d="M529.5,112.5c2.1-2.5,3.1-5.6,2.9-8.1c-0.1-2.5-1.2-4.4-2.9-4.8c-1.7-0.5-3.6,0.5-5,2.2c-1.5,1.7-2.6,4.1-3,7
					c-0.4,3-0.6,5.1-1,7.2c-0.4,2.1-1,4.3-2.4,7.1c2.3-2.2,4-3.7,5.7-5.2C525.6,116.6,527.3,115.1,529.5,112.5z"
                  />
                </g>
                <g>
                  <path
                    d="M541.5,108.7c2-2,2.9-4.7,2.9-7c-0.1-2.2-1.2-4-2.8-4.5c-1.7-0.5-3.5,0.2-5,1.5c-1.5,1.4-2.6,3.4-2.9,6
					c-0.3,2.6-0.5,4.5-0.8,6.4c-0.3,1.9-0.8,3.8-2,6.3c2.1-1.9,3.6-3.1,5.2-4.3C537.8,112,539.4,110.8,541.5,108.7z"
                  />
                </g>
                <g>
                  <path
                    d="M558.6,100c2.1-2.3,3.1-5.3,3-7.7c0-2.4-1.1-4.3-2.7-4.9c-1.7-0.5-3.6,0.3-5.1,1.9c-1.5,1.6-2.7,4-3.1,6.8
					c-0.4,2.9-0.6,5-0.9,7.1c-0.4,2.1-0.9,4.2-2.2,7c2.2-2.2,3.8-3.7,5.5-5.1C554.7,103.8,556.5,102.4,558.6,100z"
                  />
                </g>
                <g>
                  <path
                    d="M573,93.6c2.2-1.8,3.6-4.1,4.1-6.2c0.5-2.2,0.2-4.2-1.2-5.3c-1.4-1.1-3.5-0.9-5.5,0.3c-2,1.2-3.9,3.5-4.7,6.5
					c-0.8,3-1.2,5.2-1.5,7.3c-0.4,2.1-0.8,4.2-1.6,7.1c1.8-2.4,3.3-3.9,4.9-5.2C569,96.6,570.7,95.4,573,93.6z"
                  />
                </g>
                <g>
                  <path
                    d="M587.3,86.9c2.3-1.1,3.9-2.9,4.8-4.7c0.8-1.8,0.9-3.8-0.1-5.2c-1-1.4-3.1-1.9-5.2-1.2c-2.2,0.6-4.5,2.4-5.7,5.1
					c-1.3,2.7-1.9,4.7-2.5,6.7c-0.6,2-1.2,3.9-2.3,6.5c1.9-2,3.6-3.2,5.3-4.1C583.1,88.9,584.9,88.1,587.3,86.9z"
                  />
                </g>
                <g>
                  <path
                    d="M602.4,93.3c2.3,0.6,4.4,0.3,6.1-0.6c1.7-0.9,3-2.3,3.1-4.1c0.1-1.7-1-3.4-3.1-4.3c-2-0.9-4.8-1-7.4,0.3
					c-2.6,1.3-4.2,2.6-5.7,3.7c-1.5,1.2-3.1,2.4-5.3,3.9c2.6-0.5,4.5-0.5,6.3-0.2C598.3,92.3,600,92.7,602.4,93.3z"
                  />
                </g>
                <g>
                  <path
                    d="M602.3,107.3c2.1,1.2,4.2,1.5,6.1,1.3c1.9-0.3,3.6-1.1,4.4-2.7c0.8-1.6,0.2-3.6-1.6-5.2c-1.8-1.6-4.7-2.6-7.7-2.1
					c-3.1,0.5-5.1,1.4-7,2.2c-2,0.9-3.8,1.7-6.3,2.8c2.8-0.1,4.7,0.4,6.5,1C598.4,105.2,600.1,106.1,602.3,107.3z"
                  />
                </g>
                <g>
                  <path
                    d="M598.1,120.2c2.4,1.8,5,2.6,7.4,2.6c2.3,0,4.2-0.9,4.9-2.5c0.7-1.6-0.1-3.6-1.8-5.3c-1.7-1.6-4.4-2.9-7.5-3.1
					c-6.3-0.3-8.9,0.5-15.1-0.2C591.6,114.5,593.3,116.5,598.1,120.2z"
                  />
                </g>
                <g>
                  <path
                    d="M455.6,157.1c1.3,2.2,2.9,3.7,4.7,4.7c1.8,0.9,3.8,1.2,5.3,0.3c1.5-0.9,2.1-3,1.3-5.4c-0.7-2.4-2.8-5-5.7-6.3
					c-3-1.3-5.3-1.7-7.4-2.1c-2.2-0.4-4.3-0.7-7.2-1.1c2.6,1.4,4.1,2.9,5.4,4.4C453.2,153.1,454.3,154.8,455.6,157.1z"
                  />
                </g>
                <g>
                  <path
                    d="M472.5,157.1c1.1,2.4,2.4,4.3,3.9,5.8c1.5,1.5,3.1,2.7,4.9,2.5c0.9-0.1,1.6-0.6,2.2-1.6c0.6-0.9,0.9-2.2,0.8-3.8
					c-0.1-1.5-0.5-3.3-1.5-4.9c-0.9-1.6-2.3-3.2-4-4.3c-1.8-1.1-3.4-1.8-4.8-2.2c-1.5-0.4-2.8-0.7-4.1-0.8c-2.6-0.3-5.1-0.3-8.6,0.2
					c3.4,0.6,5.5,1.9,7.1,3.3C470,152.9,471.3,154.7,472.5,157.1z"
                  />
                </g>
                <g>
                  <path
                    d="M487.8,156.6c1.5,2.4,3.4,4.2,5.4,5.3c1.9,1.1,4,1.5,5.5,0.6c1.5-0.9,2-3.1,1.1-5.7c-0.9-2.6-3.1-5.4-6.4-6.9
					c-3.3-1.5-5.7-2.1-8.1-2.5c-2.4-0.5-4.7-0.9-8-1.4c3,1.5,4.7,3.1,6.1,4.7C484.9,152.4,486.2,154.1,487.8,156.6z"
                  />
                </g>
                <g>
                  <path
                    d="M506.5,159.3c2.5,2.3,5.7,3.4,8.3,3.4c2.6,0,4.6-1.1,5.1-2.7c0.5-1.7-0.5-3.6-2.2-5.1c-1.7-1.6-4.2-2.8-7.2-3.3
					c-3-0.5-5.2-0.9-7.4-1.4c-2.2-0.5-4.3-1.3-7.2-2.9c2.2,2.5,3.6,4.3,5.1,6.1C502.5,155.1,504,157,506.5,159.3z"
                  />
                </g>
                <g>
                  <path
                    d="M525.3,155.5c3.6,1.7,7.5,2.2,10.5,1.7c3-0.5,5.1-1.9,5.2-3.6c0.2-1.8-1.5-3.5-4.1-4.7c-2.6-1.2-6-2-9.7-2
					c-3.8,0.1-6.5,0.2-9.2,0.1c-2.7-0.1-5.5-0.3-9.4-1.3c3.5,1.9,5.9,3.4,8.3,4.9C519.2,152.2,521.6,153.7,525.3,155.5z"
                  />
                </g>
                <g>
                  <path
                    d="M549,150.1c6.4,2.5,13.2,0.2,13.4-3.3c0.2-3.5-6-6.6-12.5-5.4c-6.7,1.1-9.4,2.3-16.4,2.3
					C540.3,145.7,542.5,147.5,549,150.1z"
                  />
                </g>
                <g>
                  <path
                    d="M568.8,144.1c3.4,1.2,7.1,1.2,9.7,0.4c2.7-0.8,4.5-2.3,4.5-4.1c0-1.8-1.7-3.3-4.2-4.3c-2.5-1-5.8-1.4-9.2-0.8
					c-3.5,0.6-6,1.1-8.5,1.5c-2.5,0.4-5.1,0.6-8.8,0.4c3.5,1.2,5.9,2.3,8.2,3.4C562.9,141.7,565.2,142.8,568.8,144.1z"
                  />
                </g>
                <g>
                  <path
                    d="M580.9,135.9c1.6,1.6,3.5,2.2,5.3,2.2c1.8,0,3.5-0.8,4.4-2.3c0.9-1.5,0.7-3.5-0.5-5.1c-1.2-1.6-3.4-2.9-6.1-3
					c-2.7,0-4.6,0.3-6.4,0.5c-1.8,0.3-3.6,0.5-6,0.6c2.3,0.9,3.7,1.9,5,3C578.1,133,579.3,134.2,580.9,135.9z"
                  />
                </g>
                <g>
                  <path
                    d="M591.4,129.9c3.6,4.4,9.6,5,11.5,2c1.9-3-1.3-8.1-6.8-9.4c-5.7-1.4-8.2-1.2-13.6-3.3
					C586.8,123.1,587.7,125.4,591.4,129.9z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M191.4,346.1c2,10.5,5.5,20.9,10.2,29.9c4.7,9.1,10.8,16.7,16.8,22.3c6,5.6,11.9,9.2,16.3,11.1c4.4,1.9,7.2,2.3,7.5,1.5
					c0.9-1.7-8.1-7.1-17.8-18.4c-4.8-5.7-9.8-12.8-14-21.1c-4.2-8.3-7.8-17.6-10.5-27.4c-2.8-10-4.2-18.8-5.5-26.9
					c-1.3-8.1-2.4-15.4-3.8-22.7c-1.4-7.3-3.2-14.7-6-22.6c-1.4-4-3.2-8.1-5.4-12.3c-2.2-4.2-5.1-8.5-8.7-12.7
					c6.8,8.8,10.2,17.6,12.4,25.5c2.2,8,3.2,15.3,4,22.6c0.7,7.3,1,14.6,1.5,22.8C188.8,326.1,189.3,335.3,191.4,346.1z"
                  />
                </g>
                <g>
                  <path
                    d="M197,289.9c1.7,2.6,4.4,4.1,6.8,4.5c2.4,0.4,4.4-0.3,5.2-1.9c0.8-1.6,0.3-3.5-0.8-5.3c-1.2-1.7-3-3.3-5.5-4.1
					c-2.6-0.9-4.5-1.6-6.3-2.5c-1.8-0.9-3.6-1.9-5.7-3.9c1.3,2.7,2.1,4.6,3,6.6C194.4,285.3,195.3,287.3,197,289.9z"
                  />
                </g>
                <g>
                  <path
                    d="M204,306.5c1.5,2.2,3.9,3.3,5.9,3.4c2.1,0.1,3.8-0.9,4.5-2.5c0.7-1.6,0.3-3.5-0.6-4.9c-1-1.5-2.5-2.6-4.7-3.1
					c-2.2-0.5-3.9-0.8-5.6-1.3c-1.6-0.5-3.2-1.2-5.2-2.6c1.2,2.2,1.9,3.8,2.7,5.4C201.7,302.5,202.5,304.3,204,306.5z"
                  />
                </g>
                <g>
                  <path
                    d="M209.3,323.1c1.8,2.1,4.2,3.1,6.4,3.2c2.1,0.1,3.9-0.8,4.6-2.5c0.7-1.6,0.2-3.5-1-5.1c-1.2-1.6-3.1-2.8-5.6-3.2
					c-2.5-0.4-4.4-0.6-6.2-1c-1.8-0.4-3.6-0.9-6-2c1.7,2,2.8,3.6,3.9,5.2C206.4,319.3,207.5,321,209.3,323.1z"
                  />
                </g>
                <g>
                  <path
                    d="M212.5,342.8c2.4,2.2,5.6,3,8,2.7c2.5-0.3,4.1-1.7,4.3-3.4c0.2-1.8-0.9-3.4-2.4-4.7c-1.5-1.3-3.6-2.2-6.1-2.4
					c-2.6-0.3-4.5-0.6-6.4-1.1c-1.9-0.5-3.8-1.3-6.1-3.2c1.5,2.5,2.6,4.3,3.8,6.2C208.8,338.7,210.1,340.6,212.5,342.8z"
                  />
                </g>
                <g>
                  <path
                    d="M219.1,359c2.6,1.9,5.8,2.6,8.3,2.2c2.5-0.3,4.3-1.6,4.6-3.3c0.3-1.7-0.8-3.5-2.7-4.8c-1.8-1.3-4.4-2.3-7.3-2.4
					c-3-0.1-5.1-0.1-7.3-0.4c-2.2-0.2-4.3-0.6-7.3-1.8c2.4,2.1,4,3.6,5.6,5.2C214.7,355.4,216.3,357,219.1,359z"
                  />
                </g>
                <g>
                  <path
                    d="M223.9,370.7c2.2,1.9,5,2.5,7.2,2.2c2.2-0.3,3.9-1.5,4.3-3.2c0.4-1.7-0.5-3.5-2-4.8c-1.5-1.4-3.7-2.3-6.2-2.3
					c-2.6-0.1-4.6-0.1-6.5-0.2c-1.9-0.2-3.8-0.5-6.4-1.5c2,1.9,3.4,3.4,4.7,4.9C220.3,367.2,221.6,368.7,223.9,370.7z"
                  />
                </g>
                <g>
                  <path
                    d="M234,386.9c2.5,1.9,5.5,2.6,7.9,2.4c2.4-0.2,4.2-1.4,4.6-3.1c0.4-1.7-0.6-3.5-2.4-4.9c-1.8-1.4-4.2-2.4-7.1-2.5
					c-3-0.1-5.1-0.2-7.2-0.3c-2.1-0.2-4.2-0.5-7.2-1.6c2.4,2,4,3.5,5.6,5C229.9,383.4,231.4,385,234,386.9z"
                  />
                </g>
                <g>
                  <path
                    d="M241.6,400.7c2,2.1,4.3,3.2,6.5,3.5c2.2,0.3,4.2-0.2,5.2-1.7c1-1.5,0.6-3.6-0.8-5.5c-1.4-1.9-3.8-3.6-6.8-4.2
					c-3.1-0.6-5.3-0.7-7.4-0.9c-2.1-0.2-4.3-0.4-7.2-1c2.6,1.6,4.2,3,5.6,4.5C238.2,397,239.6,398.6,241.6,400.7z"
                  />
                </g>
                <g>
                  <path
                    d="M249.5,414.4c1.3,2.2,3.2,3.7,5.1,4.3c1.9,0.7,3.9,0.6,5.2-0.6c1.3-1.2,1.6-3.2,0.7-5.3c-0.8-2.1-2.8-4.2-5.5-5.3
					c-2.8-1.1-4.9-1.5-6.9-1.9c-2-0.4-3.9-0.9-6.6-1.7c2.2,1.8,3.5,3.3,4.6,4.9C247.1,410.4,248.1,412.1,249.5,414.4z"
                  />
                </g>
                <g>
                  <path
                    d="M244.4,430c-0.4,2.4,0.1,4.4,1.1,6c1,1.6,2.6,2.7,4.3,2.7c1.8,0,3.3-1.3,4-3.4c0.7-2.1,0.6-4.9-0.9-7.3
					c-1.5-2.5-2.9-4-4.2-5.4c-1.3-1.4-2.6-2.9-4.3-4.9c0.7,2.6,0.8,4.4,0.7,6.2C245.1,425.8,244.8,427.6,244.4,430z"
                  />
                </g>
                <g>
                  <path
                    d="M230.5,431.1c-1,2.2-1.2,4.3-0.7,6.2c0.4,1.9,1.4,3.5,3.1,4.2c1.6,0.6,3.6-0.1,5-2.1c1.4-1.9,2.2-4.9,1.4-7.9
					c-0.8-3-1.8-4.9-2.8-6.8c-1-1.9-2-3.7-3.3-6.1c0.3,2.8,0,4.7-0.4,6.6C232.2,427,231.5,428.8,230.5,431.1z"
                  />
                </g>
                <g>
                  <path
                    d="M217.3,428c-1.6,2.5-2.2,5.3-1.9,7.5c0.2,2.3,1.2,4.1,2.9,4.7c1.7,0.5,3.6-0.4,5.1-2.2c1.5-1.9,2.5-4.7,2.4-7.7
					c-0.3-6.3-1.2-8.8-1.1-15C222.4,421,220.5,422.8,217.3,428z"
                  />
                </g>
                <g>
                  <path
                    d="M168.4,289.1c-2.1,1.5-3.5,3.2-4.2,5.1c-0.7,1.9-0.9,3.9,0.2,5.3c1,1.4,3.2,1.8,5.5,0.9c2.3-0.9,4.7-3.2,5.8-6.3
					c1.1-3.2,1.3-5.4,1.5-7.6c0.2-2.2,0.3-4.3,0.4-7.3c-1.2,2.7-2.5,4.3-3.9,5.7C172.1,286.4,170.5,287.6,168.4,289.1z"
                  />
                </g>
                <g>
                  <path
                    d="M169.7,305.9c-2.3,1.3-4,2.7-5.5,4.3c-1.4,1.6-2.5,3.4-2.1,5.1c0.2,0.9,0.8,1.6,1.7,2c1,0.5,2.3,0.7,3.8,0.5
					c1.5-0.2,3.2-0.8,4.8-1.9c1.5-1.1,3-2.6,3.9-4.4c1-1.9,1.5-3.5,1.8-5c0.3-1.5,0.4-2.8,0.5-4.1c0-2.6-0.1-5.2-0.9-8.6
					c-0.3,3.5-1.4,5.6-2.7,7.4C173.7,303.1,172.1,304.6,169.7,305.9z"
                  />
                </g>
                <g>
                  <path
                    d="M171.5,321.1c-2.3,1.7-3.9,3.7-4.8,5.8c-0.9,2-1.2,4.1-0.1,5.5c1,1.4,3.3,1.7,5.8,0.6c2.5-1.1,5.1-3.6,6.3-6.9
					c1.2-3.4,1.6-5.9,1.8-8.3c0.3-2.4,0.5-4.7,0.7-8.1c-1.3,3.1-2.6,4.9-4.1,6.5C175.6,317.9,173.9,319.3,171.5,321.1z"
                  />
                </g>
                <g>
                  <path
                    d="M170.5,340.1c-2.1,2.7-2.9,6-2.6,8.5c0.2,2.6,1.4,4.5,3.2,4.8c1.7,0.3,3.5-0.8,4.9-2.6c1.4-1.9,2.4-4.4,2.7-7.4
					c0.3-3.1,0.4-5.3,0.8-7.5c0.4-2.2,0.9-4.4,2.3-7.4c-2.3,2.4-3.9,4-5.6,5.6C174.4,335.7,172.6,337.3,170.5,340.1z"
                  />
                </g>
                <g>
                  <path
                    d="M175.9,358.4c-1.4,3.7-1.5,7.7-0.8,10.6c0.7,3,2.3,4.9,4,4.9c1.8,0,3.3-1.8,4.3-4.5c1-2.7,1.5-6.2,1.1-9.9
					c-0.4-3.8-0.7-6.5-0.9-9.2c-0.2-2.7-0.2-5.5,0.4-9.4c-1.6,3.7-2.9,6.2-4.2,8.7C178.7,352.1,177.4,354.6,175.9,358.4z"
                  />
                </g>
                <g>
                  <path
                    d="M183.3,381.6c-1.9,6.6,0.9,13.2,4.4,13.1c3.5-0.1,6-6.5,4.4-12.9c-1.7-6.6-3.1-9.1-3.7-16.1
					C186.9,372.5,185.3,374.9,183.3,381.6z"
                  />
                </g>
                <g>
                  <path
                    d="M190.9,400.8c-0.9,3.5-0.6,7.1,0.4,9.7c1,2.6,2.7,4.3,4.5,4.1c1.8-0.1,3.2-2,3.9-4.5c0.8-2.6,0.9-5.9,0-9.3
					c-0.9-3.5-1.6-5.9-2.2-8.4c-0.6-2.5-1-5-1.2-8.7c-0.9,3.6-1.8,6-2.6,8.5C192.8,394.7,191.9,397.2,190.9,400.8z"
                  />
                </g>
                <g>
                  <path
                    d="M200.2,412.2c-1.4,1.7-1.9,3.7-1.8,5.5c0.2,1.8,1.1,3.4,2.7,4.2c1.6,0.8,3.5,0.4,5-1c1.5-1.3,2.6-3.7,2.5-6.3
					c-0.2-2.7-0.6-4.5-1.1-6.3c-0.4-1.8-0.8-3.5-1.1-6c-0.7,2.4-1.6,3.9-2.6,5.3C202.8,409.1,201.7,410.4,200.2,412.2z"
                  />
                </g>
                <g>
                  <path d="M207,422.2c-4,4-4.1,10-1,11.6c3.1,1.6,8-2,8.8-7.6c0.9-5.8,0.5-8.2,2.1-13.8C213.4,417,211.1,418.1,207,422.2z" />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M378.5,8.1c9.5-5,18.3-11.4,25.5-18.6c7.3-7.2,12.8-15.2,16.3-22.6c3.6-7.4,5.3-14.1,5.8-18.9c0.5-4.8,0-7.5-0.8-7.6
					c-1.9-0.3-4.3,9.8-12.3,22.5c-4,6.3-9.3,13.2-16,19.6c-6.6,6.5-14.6,12.7-23,18.1c-8.7,5.6-16.7,9.6-24,13.2
					c-7.3,3.6-14,6.8-20.6,10.4c-6.6,3.6-13.1,7.4-19.8,12.5c-3.4,2.5-6.8,5.4-10.1,8.8c-3.4,3.4-6.6,7.4-9.6,12
					c6.4-9.1,13.8-14.9,20.7-19.4c7-4.5,13.7-7.6,20.5-10.5c6.8-2.8,13.7-5.3,21.4-8.2C360.1,16.5,368.7,13.3,378.5,8.1z"
                  />
                </g>
                <g>
                  <path
                    d="M323.1,19.3c2-2.4,2.7-5.4,2.3-7.8c-0.3-2.4-1.6-4.1-3.3-4.4c-1.7-0.3-3.5,0.7-4.8,2.3c-1.3,1.6-2.2,3.9-2.3,6.5
					c-0.1,2.7-0.2,4.7-0.5,6.7c-0.3,2-0.8,4-2.1,6.6c2.2-2,3.7-3.4,5.4-4.8C319.4,23.2,321.1,21.8,323.1,19.3z"
                  />
                </g>
                <g>
                  <path
                    d="M336.9,7.8c1.6-2.1,2-4.7,1.5-6.6c-0.5-2-2-3.4-3.7-3.5c-1.8-0.2-3.4,0.7-4.5,2.1c-1.1,1.4-1.8,3.2-1.6,5.4
					c0.2,2.3,0.4,4,0.4,5.7c0,1.7-0.1,3.4-1,5.8c1.7-1.8,3-3,4.4-4.2C333.8,11.1,335.2,9.9,336.9,7.8z"
                  />
                </g>
                <g>
                  <path
                    d="M351.2-2.2c1.5-2.3,1.7-5,1.1-7c-0.6-2.1-2-3.5-3.7-3.7c-1.8-0.2-3.4,0.8-4.6,2.4c-1.1,1.6-1.8,3.8-1.4,6.3
					c0.4,2.6,0.7,4.4,0.9,6.3c0.2,1.9,0.3,3.7-0.1,6.4c1.4-2.3,2.6-3.8,3.8-5.3C348.4,1.6,349.7,0.1,351.2-2.2z"
                  />
                </g>
                <g>
                  <path
                    d="M369.1-11.1c1.4-2.9,1.2-6.2,0.2-8.4c-1-2.3-2.9-3.4-4.6-3.1c-1.7,0.3-3,1.8-3.8,3.7c-0.8,1.9-1,4.1-0.5,6.5
					c0.5,2.6,0.8,4.5,0.9,6.5c0,2-0.1,4-1.2,6.8c2-2.2,3.4-3.8,4.8-5.5C366.2-6.4,367.6-8.1,369.1-11.1z"
                  />
                </g>
                <g>
                  <path
                    d="M382.6-22.2c1-3.1,0.7-6.3-0.3-8.6c-1-2.3-2.8-3.6-4.5-3.4c-1.8,0.2-3.1,1.8-3.8,4c-0.7,2.1-0.9,4.8-0.1,7.6
					c0.8,2.9,1.4,5,1.8,7.1c0.4,2.1,0.7,4.3,0.4,7.5c1.3-2.9,2.3-4.9,3.3-6.9C380.4-16.9,381.5-19,382.6-22.2z"
                  />
                </g>
                <g>
                  <path
                    d="M392.3-30.2c1.1-2.7,1-5.5,0-7.5c-0.9-2-2.6-3.3-4.3-3.1c-1.8,0.1-3.2,1.5-4,3.3c-0.8,1.8-1.1,4.2-0.4,6.6
					c0.7,2.5,1.3,4.4,1.7,6.3c0.4,1.9,0.7,3.8,0.5,6.6c1.2-2.5,2.2-4.2,3.2-5.9C390-25.7,391.1-27.5,392.3-30.2z"
                  />
                </g>
                <g>
                  <path
                    d="M404.8-44.7c1.1-3,0.9-6-0.1-8.3c-1-2.2-2.6-3.6-4.4-3.5c-1.8,0.1-3.2,1.6-4,3.7c-0.8,2.1-1,4.7-0.3,7.5
					c0.8,2.9,1.4,4.9,1.8,7c0.4,2.1,0.8,4.2,0.6,7.3c1.2-2.9,2.1-4.8,3.1-6.8C402.6-39.7,403.7-41.7,404.8-44.7z"
                  />
                </g>
                <g>
                  <path
                    d="M415.7-56.1c1.4-2.5,1.8-5.1,1.4-7.3c-0.3-2.2-1.4-3.9-3.1-4.4c-1.7-0.5-3.6,0.4-5,2.3c-1.4,1.9-2.3,4.7-1.9,7.8
					c0.4,3.1,0.9,5.2,1.3,7.3c0.5,2.1,0.9,4.2,1.2,7.2c0.8-2.9,1.6-4.9,2.6-6.7C413.2-51.8,414.3-53.5,415.7-56.1z"
                  />
                </g>
                <g>
                  <path
                    d="M426.4-67.6c1.7-1.9,2.5-4.1,2.6-6.1c0.1-2-0.6-3.9-2.1-4.8c-1.5-0.9-3.5-0.6-5.3,0.9c-1.8,1.4-3.2,3.9-3.4,6.8
					c-0.2,3,0,5.1,0.2,7.1c0.2,2,0.3,4,0.3,6.9c1-2.6,2.1-4.3,3.3-5.8C423.3-64.2,424.7-65.6,426.4-67.6z"
                  />
                </g>
                <g>
                  <path
                    d="M442.9-67.4c2.4-0.3,4.2-1.4,5.4-2.9c1.2-1.4,1.9-3.2,1.3-4.9c-0.5-1.7-2.2-2.8-4.4-2.8c-2.2-0.1-4.8,0.9-6.7,3.1
					c-1.9,2.2-2.9,3.9-3.9,5.6c-1,1.7-2,3.3-3.4,5.6c2.2-1.5,4-2.1,5.7-2.6C438.6-66.8,440.4-67.1,442.9-67.4z"
                  />
                </g>
                <g>
                  <path
                    d="M448-54.4c2.4,0.3,4.5-0.2,6.2-1.1c1.7-1,3-2.4,3.1-4.2c0.1-1.7-1.2-3.4-3.4-4.2c-2.2-0.8-5.3-0.6-7.9,1
					c-2.7,1.7-4.2,3.2-5.7,4.6c-1.5,1.6-2.9,3-4.8,5c2.6-1.1,4.5-1.4,6.4-1.5C443.6-54.9,445.5-54.7,448-54.4z"
                  />
                </g>
                <g>
                  <path
                    d="M449-40.9c2.9,0.8,5.7,0.5,7.8-0.4c2.1-0.9,3.6-2.4,3.6-4.2c0-1.8-1.4-3.3-3.6-4.2c-2.2-0.9-5.2-1-8.1,0
					c-6,2.1-8,3.8-14,5.5C440.8-43.7,443.1-42.4,449-40.9z"
                  />
                </g>
                <g>
                  <path
                    d="M330.8,46.9c2,1.6,4.1,2.4,6.1,2.5c2,0.2,3.9-0.3,5-1.7c1-1.4,0.8-3.5-0.8-5.5c-1.5-1.9-4.4-3.6-7.7-3.6
					c-3.3-0.1-5.5,0.4-7.7,0.8c-2.1,0.5-4.2,1-7.1,1.7c3,0.3,4.9,1.1,6.6,2C327.1,44.2,328.7,45.3,330.8,46.9z"
                  />
                </g>
                <g>
                  <path
                    d="M346.5,40.6c1.9,1.8,3.8,3.1,5.8,3.9c1.9,0.8,3.9,1.4,5.5,0.5c0.8-0.4,1.3-1.2,1.4-2.3c0.2-1.1,0-2.4-0.7-3.8
					c-0.6-1.4-1.7-2.8-3.2-4c-1.5-1.2-3.3-2.1-5.3-2.4c-2.1-0.4-3.8-0.4-5.3-0.3c-1.5,0.1-2.8,0.4-4.1,0.8c-2.5,0.7-4.9,1.6-7.9,3.4
					c3.4-0.8,5.8-0.3,7.9,0.4C342.6,37.7,344.5,38.8,346.5,40.6z"
                  />
                </g>
                <g>
                  <path
                    d="M360.5,34.4c2.3,1.7,4.7,2.6,6.9,2.9c2.2,0.3,4.3-0.1,5.3-1.5c1-1.4,0.7-3.6-1.1-5.7c-1.8-2.1-4.9-3.8-8.5-4
					c-3.6-0.2-6.1,0.3-8.5,0.7c-2.4,0.5-4.7,1-7.9,1.7c3.3,0.3,5.5,1.1,7.4,2C356.2,31.5,358.1,32.7,360.5,34.4z"
                  />
                </g>
                <g>
                  <path
                    d="M378.8,29.8c3.2,1.2,6.5,1,8.9,0c2.4-1,3.8-2.7,3.7-4.4c-0.2-1.8-1.8-3.1-4-3.9c-2.2-0.8-5-1-7.9-0.4
					c-3,0.7-5.2,1.2-7.4,1.5c-2.2,0.3-4.5,0.4-7.8,0c3,1.5,5,2.6,7,3.7C373.5,27.4,375.6,28.6,378.8,29.8z"
                  />
                </g>
                <g>
                  <path
                    d="M394.8,19.2c4,0.3,7.8-0.8,10.4-2.4c2.6-1.6,4-3.6,3.5-5.3c-0.5-1.7-2.7-2.6-5.6-2.8c-2.9-0.2-6.4,0.4-9.8,1.9
					c-3.5,1.5-6,2.6-8.5,3.6c-2.5,1-5.2,1.8-9.2,2.4c4,0.5,6.7,0.9,9.5,1.4C387.9,18.4,390.7,19,394.8,19.2z"
                  />
                </g>
                <g>
                  <path
                    d="M414.8,5.3c6.9-0.1,12.4-4.8,11.2-8.1c-1.2-3.3-8-3.8-13.6-0.3c-5.8,3.6-7.8,5.6-14.3,8.3C405,4.5,407.7,5.3,414.8,5.3z
					"
                  />
                </g>
                <g>
                  <path
                    d="M430.8-7.7c3.7-0.2,7-1.5,9.2-3.3c2.2-1.7,3.3-3.8,2.6-5.5c-0.7-1.6-2.8-2.4-5.5-2.4c-2.7,0-5.9,0.9-8.8,2.7
					c-3,1.9-5.1,3.3-7.3,4.6c-2.2,1.3-4.5,2.5-8,3.7c3.7-0.2,6.3-0.1,8.9,0C424.5-7.7,427.1-7.5,430.8-7.7z"
                  />
                </g>
                <g>
                  <path
                    d="M439-19.9c2.1,0.9,4.1,0.8,5.8,0c1.6-0.7,3-2.1,3.3-3.8c0.3-1.7-0.7-3.5-2.4-4.5c-1.7-1.1-4.3-1.4-6.7-0.5
					c-2.5,1-4.1,2-5.7,2.9c-1.6,0.9-3.1,1.8-5.4,2.9c2.5,0,4.2,0.4,5.8,0.9C435.2-21.5,436.8-20.8,439-19.9z"
                  />
                </g>
                <g>
                  <path d="M446.5-29.3c5,2.7,10.8,1,11.4-2.5c0.6-3.5-4.3-7-9.9-6.2c-5.8,0.9-8,2-13.8,2C439.6-33.9,441.3-32.1,446.5-29.3z" />
                </g>
              </g>
              <g>
                <path
                  d="M533.7,299.2c0.6-0.6,1.1-1.2,1.6-1.8c0.5-0.7,1-1.3,1.5-2c0.9-1.4,1.8-2.8,2.4-4.3c1.4-3,2.2-6.2,2.6-9.4
				c0.8-6.4-0.3-12.8-2.8-18.6c-1.2-2.9-3-5.7-5-8.1c-2-2.4-4.2-4.5-6.6-6.4c-4.8-3.6-10.2-6.1-15.6-7.6c-2.7-0.7-5.5-1.3-8.2-1.5
				c-2.7-0.3-5.5-0.3-8.1-0.2c-5.4,0.3-10.8,1.5-15.7,3.8c-5,2.3-9.4,5.8-12.6,10c-1.6,2.1-2.9,4.5-3.9,6.9
				c-0.9,2.4-1.5,5.1-1.6,7.7c-0.3,5.1,1,10,3.5,14.1c1.3,2,2.9,3.8,4.7,5.2c1.8,1.4,3.8,2.4,5.8,3.1c4,1.3,8,1.3,11.5,0.3
				c3.5-1,6.4-3.3,8-5.7c1.7-2.4,2.4-4.9,2.6-7c0.2-2.1-0.1-4-0.6-5.3c-0.5-1.4-1.2-2.3-1.7-2.8c-0.6-0.5-1-0.6-1.3-0.5
				c-0.6,0.3-0.4,1.7-0.7,3.8c-0.1,1-0.4,2.2-1.1,3.5c-0.6,1.2-1.5,2.6-2.7,3.6c-0.6,0.5-1.3,0.9-2,1.3c-0.7,0.3-1.6,0.5-2.5,0.6
				c-1.8,0.2-3.8-0.2-5.7-1.2c-0.9-0.5-1.8-1.1-2.5-1.9c-0.7-0.7-1.3-1.6-1.8-2.7c-1-2.1-1.4-4.7-1-7.3c0.2-1.3,0.6-2.5,1.2-3.8
				c0.6-1.2,1.4-2.4,2.4-3.6c1-1.1,2.1-2.2,3.4-3.1c1.3-0.9,2.7-1.7,4.3-2.3c6.2-2.5,14.7-2.8,22.4-0.6c3.8,1.1,7.5,2.8,10.6,5.1
				c1.5,1.2,3,2.5,4.2,3.9c0.6,0.7,1.1,1.5,1.7,2.2l0.7,1.2l0.6,1.3c1.6,3.4,2.4,7.4,2.2,11.1c-0.1,1.9-0.5,3.7-1.2,5.4
				c-0.3,0.9-0.7,1.6-1.2,2.4l-0.7,1.1c-0.3,0.4-0.5,0.7-0.9,1.1c-2.5,3-6.1,5.2-10.1,6.9c-2,0.9-4.1,1.6-6.1,2.2
				c-1.1,0.3-2.1,0.6-3.2,0.9c-1.1,0.3-2.1,0.6-3.1,0.8c-8.4,1.9-16.5,2.6-24.2,2.5c-7.7-0.1-15.1-1.1-22.2-2.7
				c-7.1-1.6-13.8-3.8-20.3-6.6c-13-5.7-25.3-13.9-36.8-25.5c-5.7-5.8-11.3-12.5-16.4-20.3c-5.2-7.7-9.9-16.7-13.9-26.8
				c3.7,10.3,8,19.4,12.8,27.5c4.8,8.1,10,15.2,15.6,21.4c11.1,12.5,23,21.8,36.3,28.8c6.6,3.5,13.5,6.4,20.9,8.7
				c7.4,2.3,15.2,3.9,23.5,4.7c8.4,0.8,17.3,0.7,26.8-0.7c1.2-0.1,2.4-0.4,3.6-0.6c1.2-0.2,2.4-0.5,3.6-0.7c2.5-0.6,5-1.2,7.6-2.1
				c2.6-0.9,5.2-2,7.8-3.5C528.7,303.5,531.3,301.6,533.7,299.2z"
                />
              </g>
              <g>
                <path
                  d="M454.1,257.7c3.8-5.9,5.1-13.2,3.9-20.1c-0.6-3.4-1.9-6.8-3.7-9.7c-1.8-2.9-4.1-5.5-6.7-7.6c-2.6-2.1-5.5-3.7-8.6-4.7
				c-3.1-1-6.3-1.5-9.5-1.3c-3.2,0.2-6.3,0.9-9.1,2.4c-0.7,0.3-1.4,0.8-2,1.2c-0.7,0.5-1.3,0.9-1.9,1.4c-1.2,1-2.2,2.1-3.1,3.3
				c-1.7,2.4-2.8,5-3.3,7.6c-0.5,2.6-0.3,5.3,0.4,7.7c0.8,2.5,2.1,4.4,3.6,5.7c1.5,1.4,3,2.3,4.5,2.9c1.5,0.6,2.9,0.8,4.1,0.8
				c0.6,0,1.2-0.1,1.7-0.2c0.5-0.1,0.9-0.3,1.3-0.4c0.8-0.4,1.3-0.8,1.5-1.2c0.3-0.4,0.4-0.7,0.3-1.1c-0.2-0.7-1-1-2-1.6
				c-1-0.6-2.2-1.3-3.3-2.6c-1.1-1.2-1.9-3.1-1.8-4.5c0.1-0.9,0.2-1.7,0.7-2.6c0.4-0.9,1.1-1.8,1.9-2.6c0.4-0.4,0.9-0.7,1.3-1
				c0.2-0.1,0.5-0.2,0.7-0.4c0.3-0.1,0.5-0.2,0.8-0.3c1.1-0.4,2.4-0.5,3.9-0.5c2.9,0.1,5.9,1.3,8.6,3.4c1.3,1.1,2.5,2.4,3.4,3.8
				c0.9,1.5,1.6,3,2,4.7c0.8,3.4,0.5,7.4-1.1,10.7c-1.6,3.5-4.6,6.3-7.9,8.3c-3.4,1.9-7,3-10.6,3.3c-3.7,0.4-7.3,0.1-10.9-0.6
				c-3.5-0.7-7-1.8-10.4-3.2c-3.4-1.4-6.6-3.1-9.9-5.2c-3.2-2.1-6.3-4.6-9.4-7.5c-3-2.9-6-6.3-8.7-10.3c-2.7-4-5.2-8.6-7.1-14
				c3.2,11,8.3,19.4,13.9,26.1c2.8,3.4,5.7,6.3,8.8,9c3.1,2.6,6.3,4.9,9.7,7c3.4,2.1,7,3.8,11,5.2c3.9,1.4,8.2,2.4,13,2.7
				c4.7,0.3,10-0.4,15.2-2.5c2.6-1,5.2-2.5,7.8-4.4C449.8,263.2,452.2,260.8,454.1,257.7z"
                />
              </g>
              <g>
                <path
                  d="M336.8,288.9c-1.6,6.2-2,12.8-1.5,18.8c0.5,6,2,11.6,3.9,16c1.9,4.5,4.2,7.8,6.2,9.9c2.1,2.1,3.9,2.9,5,2.3
				c1.2-0.6,1.7-2.5,1.6-5.1c0.1-2.7-0.4-6.2-0.8-10.4c-0.4-4.1-0.9-8.8-1.2-13.8c-0.3-5-0.4-10.4-0.3-15.8
				c0.1-5.6,0.8-10.4,1.7-14.7c0.9-4.3,2.2-8.3,3.4-12.5c1.2-4.1,2.4-8.3,3.2-13.2c0.4-2.4,0.6-5,0.6-7.8c0-2.8-0.4-5.7-1.3-8.7
				c0.5,3.1,0.5,6,0.1,8.6c-0.4,2.6-1.1,5-1.9,7.2c-1.6,4.4-3.7,8-5.9,11.6c-2.2,3.6-4.5,7.2-6.8,11.5
				C340.7,277.4,338.5,282.5,336.8,288.9z"
                />
              </g>
              <g>
                <path
                  d="M350.5,287.2c-2,4.9-4.1,10-5.6,15.3c-1.5,5.3-2.2,10.7-1.9,15.5c0.3,4.8,1.5,8.7,3,11.2c1.5,2.5,3.2,3.5,4.5,3.1
				c1.3-0.4,2-2,2.7-4.3c0.7-2.3,1.4-5.3,2.3-8.9c0.9-3.6,2.2-7.7,3.7-12.4c1.4-4.7,3-10.1,4-15.9c0.9-6,1.2-11.1,1.2-15.7
				c0-4.6-0.4-8.7-0.9-12.8c-0.5-4.1-1.2-8.1-2.1-12.5c-1-4.4-2.2-9.3-4.1-14.9c1.1,5.8,1.4,10.8,1.4,15.2c0,4.4-0.4,8.4-0.9,12.2
				c-0.6,3.8-1.4,7.6-2.5,11.6C354.1,277.9,352.6,282.2,350.5,287.2z"
                />
              </g>
              <g>
                <path
                  d="M393.3,330.8c-0.5,0.7-1,1.3-1.5,1.9c-0.5,0.6-1.1,1.1-1.6,1.6c-1.2,0.9-2.4,1.7-3.7,2.3c-1.3,0.6-2.5,0.8-3.7,0.9
				c-1.2,0.1-2.1,0-3.2-0.4c-2.1-0.6-3.9-2.2-4.8-3.6c-0.4-0.7-0.7-1.4-0.8-2.3c-0.1-0.9,0-1.8,0.2-2.8c0.5-1.9,1.6-3.7,2.7-4.9
				c1.2-1.2,2-1.6,2.4-2c0.3-0.1,0.8-0.1,1.2,0c0.4,0.1,0.8,0.3,1.1,0.5c0.7,0.4,1.1,1.1,1.7,1.4c0.3,0.1,0.7,0.1,1.1-0.1
				c0.4-0.3,0.8-0.8,1.1-1.6c0.2-0.8,0.2-2-0.2-3.1c-0.4-1.2-1.2-2.4-2.6-3.5c-1.6-1.1-3.4-1.5-5.4-1.5c-1.9,0-3.8,0.5-5.6,1.3
				c-3.5,1.6-6.7,4.5-9,8.7c-1.1,2.1-1.9,4.6-2.1,7.3c-0.2,2.7,0.2,5.8,1.4,8.5c1.1,2.8,2.9,5.2,5.1,7.2c2.2,2,4.8,3.6,7.7,4.6
				c2.9,1,6.3,1.4,9.3,1.1c3.1-0.3,6.1-1.2,8.7-2.6c2.7-1.4,5-3.2,7.1-5.3c1-1.1,1.9-2.2,2.8-3.4c0.8-1.2,1.5-2.4,2.1-3.7
				c2.5-5.3,3.2-10.4,3.2-14.9c0-4.6-0.8-8.7-1.8-12.3c-1-3.7-2.3-6.9-3.6-10c-1.3-3.1-2.7-6-4.2-8.9c-1.5-2.9-3-5.7-4.8-8.6
				c-1.7-2.9-3.5-5.8-5.6-8.7c-4.1-6-8.9-12.3-15.4-19.3c5.9,7.4,9.9,14.3,13.1,20.6c1.6,3.2,3,6.2,4.2,9.2c1.2,3,2.2,6,3.2,8.9
				c0.9,3,1.7,6,2.4,9c0.7,3.1,1.3,6.2,1.6,9.4c0.3,3.2,0.3,6.5-0.3,9.8C396.4,324.7,395.3,328,393.3,330.8z"
                />
              </g>
              <g>
                <path
                  d="M211.1,46.3c3.4-0.8,6.9-1,10.2-0.6c3.3,0.4,6.4,1.5,8.9,3.1c2.6,1.6,4.7,3.9,6.2,6.4c0.7,1.2,1.3,2.5,1.7,3.8
				c0.4,1.3,0.5,2.6,0.6,3.9c0.1,5.3-3,11-7.3,15.2c-1.1,1.1-2.2,2.1-3.4,2.9c-1.2,0.9-2.4,1.7-3.5,2.4c-2.4,1.4-4.7,2.1-6.6,2.3
				c-1,0.1-1.9,0-2.6-0.2c-0.8-0.2-1.6-0.5-2.3-1c-1.5-1-2.7-2.4-3.5-3.9c-0.4-0.8-0.6-1.5-0.8-2.3c-0.1-0.4-0.1-0.8-0.2-1.1
				c0-0.4,0-0.7,0-1.1c0.1-1.5,0.6-3,1.3-4.2c1.4-2.5,3.4-4.2,4.9-5.2c1.5-1,2.5-1.6,2.5-2.3c0-0.6-1.2-1.4-3.6-1.3
				c-1.2,0-2.7,0.3-4.3,0.9c-1.6,0.7-3.4,1.7-5.1,3.3c-1.7,1.6-3.3,3.8-4.3,6.8c-0.3,0.7-0.4,1.5-0.6,2.3c-0.1,0.8-0.2,1.6-0.2,2.5
				c-0.1,1.7,0.2,3.4,0.6,5.2c0.9,3.5,2.9,6.8,5.9,9.6c1.5,1.4,3.4,2.6,5.5,3.4c2.2,0.9,4.4,1.3,6.6,1.4c4.5,0.2,8.9-1,12.7-2.8
				c1.9-0.9,3.7-1.9,5.5-3.1c1.8-1.2,3.5-2.5,5.1-4c1.6-1.4,3.2-3.1,4.6-4.9c1.4-1.8,2.7-3.7,3.9-5.8c1.1-2.1,2.1-4.4,2.8-6.9
				c0.7-2.5,1.1-5.1,1.1-7.8c0-2.7-0.3-5.5-1.1-8.1c-0.8-2.6-1.9-5.1-3.3-7.4c-2.8-4.6-6.7-8.4-11.4-11.2c-4.7-2.8-9.9-4.2-15.1-4.6
				c-5.2-0.4-10.3,0.3-15.2,1.9c-5,1.6-9.3,4-13,6.7c-3.7,2.7-6.9,5.8-9.6,9c-5.3,6.4-9.1,13.2-11.6,19.9
				c-2.5,6.7-3.9,13.4-4.6,19.9c-0.7,6.5-0.7,12.8-0.1,19.1c0.6,6.3,1.8,12.4,3.6,18.6c1.8,6.2,4.1,12.4,6.9,18.7
				c5.7,12.7,13.8,25.8,24.4,40.6c-10-15.2-17.2-28.8-22-41.6c-2.4-6.4-4.2-12.6-5.4-18.7c-1.2-6.1-1.8-12-1.9-18
				c0-5.9,0.5-11.8,1.7-17.7c1.2-5.8,3-11.7,5.8-17.4c2.8-5.6,6.5-11.1,11.4-16c2.4-2.4,5.2-4.6,8.2-6.5
				C204.3,48.6,207.6,47.1,211.1,46.3z"
                />
              </g>
              <g>
                <path
                  d="M217.7,111.6c1.7-0.5,3.2-0.7,4.8-0.7c1.5,0,2.9,0.2,4,0.6c0.5,0.2,1,0.4,1.3,0.7c0.4,0.2,0.7,0.5,0.9,0.8
				c0.5,0.6,1,1.5,1.3,2.5c0.2,0.5,0.3,1.1,0.3,1.7c0.1,0.6,0.1,1.1,0,1.7c-0.1,1.1-0.4,2.2-0.7,3c-0.2,0.4-0.4,0.8-0.6,1.1
				c-0.2,0.3-0.4,0.6-0.7,0.8c-0.3,0.2-0.4,0.4-0.7,0.6c-0.3,0.2-0.6,0.3-1,0.4c-0.7,0.2-1.5,0.2-2.3,0.1c-0.7-0.1-1.4-0.3-2-0.7
				c-1.2-0.6-2.2-2-2.8-3.3c-0.6-1.3-0.9-2.6-1.1-3.6c-0.2-1-0.5-1.8-1-2.2c-0.5-0.3-1.4-0.2-2.5,0.7c-1,0.9-2.2,2.7-2.6,5.4
				c-0.2,1.3-0.2,2.9,0.1,4.5c0.3,1.7,1,3.5,2.3,5.2c2.5,3.5,7,6.2,12.4,6.3c1.3,0,2.7-0.1,4.2-0.4c1.4-0.3,2.9-0.9,4.2-1.7
				c1.3-0.8,2.5-1.7,3.6-2.8c1.1-1.1,2-2.3,2.8-3.5c1.5-2.5,2.5-5.3,3-8.3c0.2-1.5,0.3-3,0.2-4.5c-0.1-1.5-0.3-3-0.7-4.5
				c-0.8-3-2.3-6.1-4.8-8.7c-2.5-2.6-5.8-4.5-9-5.3c-3.2-0.9-6.4-1-9.4-0.6c-1.5,0.2-2.9,0.5-4.3,0.9c-1.4,0.4-2.7,1-4,1.5
				c-5.3,2.4-9.2,5.9-12.2,9.4c-1.5,1.8-2.8,3.6-3.9,5.4c-1.1,1.9-2,3.7-2.8,5.5c-3,7.5-3.6,14.5-3.3,21.3c0.3,6.8,1.7,13.4,4,20.4
				c2.2,7,5.4,14.5,9.3,23.3c-3.1-9.1-5.5-16.9-6.7-24c-1.3-7.1-1.6-13.5-0.8-19.6c0.7-6.1,2.5-12,5.9-17.3c0.8-1.3,1.8-2.6,2.9-3.9
				c1.1-1.2,2.3-2.4,3.5-3.5C211.2,114.4,214.2,112.5,217.7,111.6z"
                />
              </g>
              <g>
                <path
                  d="M252.1,32.8c3.2,0.6,6.4-0.3,8.5-1.8c2.1-1.5,3-3.4,2.5-5.1c-0.6-1.7-2.4-2.7-4.6-3c-2.2-0.3-4.8,0-7.4,1.2
				c-2.7,1.2-4.6,2.1-6.6,2.8c-2,0.7-4.2,1.2-7.3,1.3c3,1,5.1,1.7,7.3,2.5C246.6,31.4,248.8,32.2,252.1,32.8z"
                />
              </g>
              <g>
                <path
                  d="M267.3,45.6c1.8,2,3.7,3.3,5.6,4.1c1.9,0.8,4,1.1,5.5,0.2c1.5-0.9,1.9-3.2,0.7-5.8c-1.2-2.6-4-5.2-7.6-6.1
				c-3.7-0.9-6.2-0.8-8.7-0.6c-2.4,0.2-4.8,0.6-8,1.4c3.3,0.3,5.4,1.2,7.2,2.3C263.8,42.2,265.4,43.6,267.3,45.6z"
                />
              </g>
              <g>
                <path
                  d="M270,60.5c0.6,2.6,1.5,4.6,2.7,6.3c1.2,1.6,2.7,3,4.5,3.1c0.9,0,1.7-0.4,2.4-1.2c0.3-0.4,0.7-0.9,0.9-1.5
				c0.2-0.6,0.4-1.3,0.5-2c0.4-2.9-0.8-6.8-3.6-9.5c-2.9-2.7-5.2-3.8-7.5-4.7c-2.3-0.9-4.7-1.6-7.9-2.2c3,1.6,4.4,3.3,5.6,5.1
				C268.6,55.8,269.4,57.8,270,60.5z"
                />
              </g>
              <g>
                <path
                  d="M263.1,73.6c-0.7,2.7-0.6,5.1,0.1,7.2c0.7,2,1.9,3.7,3.7,4c1.7,0.3,3.5-0.9,4.7-3.2c1.1-2.3,1.5-5.6,0.3-8.6
				c-1.2-3.2-2.4-5.1-3.5-7.1c-1.2-1.9-2.3-3.8-3.9-6.5c0.6,3.1,0.5,5.2,0.2,7.3C264.3,68.8,263.9,70.8,263.1,73.6z"
                />
              </g>
              <g>
                <path
                  d="M251.6,86.6c-1.8,2-2.8,4.1-3.2,6.2c-0.4,2.1-0.1,4.1,1.2,5.3c1.3,1.2,3.5,1,5.7-0.5c2.1-1.5,4-4.4,4.4-7.7
				c0.4-3.4,0.1-5.8-0.2-8c-0.3-2.3-0.7-4.5-1.2-7.5c-0.5,3.1-1.4,5-2.5,6.8C254.7,82.9,253.5,84.5,251.6,86.6z"
                />
              </g>
              <g>
                <path
                  d="M241.2,20.8c6-2.2,9.5-7.9,7.4-10.8c-2.1-2.9-8.6-1.4-12.5,3.7c-2,2.6-3.3,4.5-4.6,6.4c-0.7,0.9-1.3,1.9-2.1,2.7
				c-0.4,0.4-0.9,0.8-1.5,1.1c-0.6,0.3-1.4,0.4-2.1,0.2c0.7,0.5,1.5,0.7,2.3,0.6c0.8,0,1.5-0.2,2.2-0.4c0.7-0.2,1.3-0.4,1.9-0.6
				c0.6-0.2,1.1-0.4,1.7-0.6C235.8,22.5,238.1,21.9,241.2,20.8z"
                />
              </g>
              <g>
                <path
                  d="M227.4,15.6c2.1-2.5,2.5-5.9,1.9-8.2c-0.7-2.4-2.4-3.8-4.1-3.6c-1.8,0.1-3.2,1.4-4.2,2.9c-1,1.5-1.6,3.3-1.6,5.5
				c0,2.4-0.2,4.2-0.7,5.9c-0.5,1.8-1.3,3.6-3.2,5.6c2.6-1.2,4.4-2.1,6.2-3.2C223.5,19.4,225.3,18.1,227.4,15.6z"
                />
              </g>
              <g>
                <path
                  d="M216.5,14.7c1.1-3.1,0.5-6.4-0.9-8.5c-1.4-2.1-3.4-2.9-5-2.3c-1.7,0.6-2.6,2.3-3.1,4.1c-0.5,1.8-0.5,3.9,0.3,6.1
				c0.8,2.4,1.2,4.2,1.4,6.1c0.2,1.9,0.1,3.9-1,6.6c1.9-2.1,3.3-3.8,4.6-5.5C214.1,19.6,215.4,17.8,216.5,14.7z"
                />
              </g>
              <g>
                <path
                  d="M204,15.5c-0.4-2.8-2.4-5-4.4-5.8c-2.1-0.8-4-0.4-5.1,1c-1.1,1.4-1.2,3.2-0.9,4.8c0.3,1.6,1.1,2.9,2.7,4.1
				c1.7,1.2,2.9,2.3,3.9,3.5c1,1.2,1.9,2.7,2.4,5.1c0.6-2.4,0.9-4.1,1.3-5.9C204.2,20.4,204.4,18.4,204,15.5z"
                />
              </g>
              <g>
                <path
                  d="M189.6,18.1c-1.2-0.9-2.6-1.3-4-1.3c-1.4,0-2.6,0.3-3.6,1c-1,0.6-1.6,1.4-1.9,2.3c-0.3,0.9-0.2,1.7,0.1,2.5
				c0.7,1.6,2.1,2.7,3.1,3.4c1.1,0.7,2,0.9,3.4,0.4c1.5-0.5,2.9-0.5,4.3-0.3c1.4,0.3,2.8,1,4.1,2.7c-0.3-2.2-0.8-3.8-1.5-5.5
				C193,21.7,192,19.9,189.6,18.1z"
                />
              </g>
              <g>
                <path
                  d="M174.9,28.6c-3.1,0-5.9,1.8-7.2,3.8c-1.3,2-1.2,4.2,0,5.4c1.2,1.3,3.1,1.6,4.8,1.4c1.8-0.2,3.4-0.8,5.1-2.3
				c1.7-1.5,3.2-2.6,4.8-3.4c1.6-0.8,3.4-1.4,6.1-1.4c-2.5-1.1-4.3-1.8-6.3-2.4C180.2,29.1,178.1,28.5,174.9,28.6z"
                />
              </g>
              <g>
                <path
                  d="M168.5,41.5c-2.5,0.6-4.3,2.4-4.8,4.3c-0.6,1.9-0.1,3.7,1.3,4.8c1.4,1.1,3.2,1.3,4.7,1c1.5-0.3,2.8-1.1,3.7-2.8
				c1-1.7,1.7-3,2.7-4.3c0.9-1.2,2.1-2.3,4.1-3.3c-2.2,0-3.8-0.1-5.6-0.2C172.8,41,171,40.9,168.5,41.5z"
                />
              </g>
              <g>
                <path
                  d="M158.7,62.1c-2.2,1.8-3.3,4.4-3.4,6.6c-0.1,2.2,0.9,4,2.5,4.7c1.6,0.7,3.5,0.1,5.1-1.1c1.6-1.2,2.8-3.1,3.3-5.6
				c0.5-2.6,0.8-4.4,1.3-6.3c0.5-1.8,1.1-3.6,2.6-6c-2.2,1.6-3.9,2.7-5.6,3.7C162.7,59.2,161,60.2,158.7,62.1z"
                />
              </g>
              <g>
                <path d="M155.7,90.1c-2.8,3.4-1.4,8,2,9c3.4,1,7.1-2.2,6.5-6.5c-0.6-4.5-1.6-6.4-1.4-10.8C160.4,85.5,158.6,86.6,155.7,90.1z" />
              </g>
              <g>
                <path
                  d="M157,113.8c-1.1,1.8-0.9,3.7-0.1,5.2c0.8,1.4,2.4,2.4,4.1,2.4c1.8,0,3.3-0.9,4.2-2.1c0.9-1.3,1.2-2.9,0.5-4.7
				c-1.5-3.7-2.8-5.3-3.2-9.2C160.8,108.9,159.2,110.1,157,113.8z"
                />
              </g>
              <g>
                <path
                  d="M191.5,102.9c2.1-4.3,3.5-8.8,3.9-12.4c0.5-3.6,0.1-6.5-1.5-7.2c-1.6-0.7-4.1,0.9-6.3,4.1c-2.2,3.2-4.1,8-4.6,13.1
				c-0.5,5.3-0.4,8.9-0.3,12.5c0.1,3.6,0.2,7.2,0.2,12.4c1-5.1,2.2-8.5,3.5-11.8C187.9,110.5,189.4,107.4,191.5,102.9z"
                />
              </g>
              <g>
                <g>
                  <path
                    d="M6.8,333.4c-3-1.7-5.7-4-7.9-6.6c-2.1-2.6-3.6-5.5-4.4-8.4c-0.8-2.9-0.8-6.1-0.2-8.9c0.3-1.4,0.8-2.7,1.4-3.9
					c0.6-1.2,1.4-2.2,2.3-3.2c3.6-3.9,9.7-6,15.7-6.1c1.5-0.1,3,0,4.5,0.1c1.5,0.1,2.9,0.4,4.2,0.7c2.7,0.6,4.8,1.6,6.3,2.8
					c0.8,0.6,1.4,1.3,1.8,1.9c0.5,0.7,0.8,1.5,1,2.3c0.4,1.7,0.4,3.6-0.2,5.2c-0.2,0.8-0.6,1.6-1,2.2c-0.2,0.4-0.4,0.6-0.7,0.9
					c-0.3,0.3-0.5,0.6-0.8,0.8c-1.1,1-2.5,1.7-3.9,2.2c-2.8,0.9-5.4,0.7-7.1,0.5c-1.8-0.3-3-0.5-3.4,0c-0.4,0.5-0.1,1.8,1.7,3.4
					c0.9,0.8,2.1,1.6,3.8,2.3c1.6,0.6,3.7,1.1,6,1.1c2.3,0,5.1-0.5,7.7-2c0.7-0.3,1.3-0.8,2-1.3c0.6-0.5,1.3-1,1.9-1.6
					c1.2-1.2,2.2-2.6,3.1-4.2c1.7-3.1,2.6-6.9,2.2-11c-0.2-2-0.7-4.2-1.7-6.3c-1-2.1-2.3-4-3.9-5.6c-3.2-3.2-7.2-5.3-11.2-6.6
					c-2-0.7-4.1-1.2-6.1-1.5c-2.1-0.4-4.2-0.5-6.4-0.6c-2.2-0.1-4.4,0.1-6.7,0.4c-2.3,0.3-4.6,0.9-6.8,1.6c-2.3,0.8-4.6,1.8-6.8,3.1
					c-2.2,1.4-4.3,3-6.1,4.9c-1.8,2-3.5,4.2-4.7,6.7c-1.2,2.4-2.1,5-2.7,7.6c-1.1,5.3-0.8,10.7,0.7,15.9c1.5,5.2,4.4,9.9,7.9,13.7
					c3.5,3.8,7.8,6.8,12.3,9c4.7,2.2,9.5,3.4,14.1,4c4.6,0.6,9,0.5,13.1,0c8.3-1,15.6-3.4,22.1-6.6c6.4-3.2,12-7.1,17-11.4
					c4.9-4.3,9.2-8.9,13.1-13.9c3.8-5,7.2-10.3,10.1-16c2.9-5.7,5.5-11.8,7.7-18.4c4.5-13.2,7.6-28.3,9.9-46.4
					c-3.1,18-7.1,32.8-12.3,45.4c-2.6,6.3-5.6,12.1-8.8,17.3c-3.3,5.3-6.9,10-10.9,14.4c-4,4.3-8.4,8.3-13.3,11.8
					c-4.9,3.5-10.2,6.5-16.1,8.7c-5.9,2.2-12.4,3.7-19.2,3.9c-3.4,0.1-6.9-0.1-10.4-0.9C13.3,336.4,9.9,335.2,6.8,333.4z"
                  />
                </g>
                <g>
                  <path
                    d="M46.6,281.2c-1.6-0.8-2.8-1.7-4-2.7c-1.1-1-2-2.1-2.5-3.1c-0.3-0.5-0.4-1-0.5-1.4c-0.1-0.4-0.1-0.8-0.1-1.2
					c0-0.8,0.3-1.7,0.8-2.7c0.3-0.5,0.6-1,0.9-1.5c0.4-0.5,0.7-0.9,1.1-1.2c0.8-0.8,1.8-1.4,2.6-1.7c0.4-0.2,0.8-0.3,1.2-0.4
					c0.4-0.1,0.7-0.1,1-0.1c0.3,0,0.6,0,0.9,0.1c0.3,0.1,0.6,0.2,1,0.4c0.7,0.3,1.3,0.9,1.7,1.5c0.5,0.6,0.8,1.2,1,1.8
					c0.4,1.3,0.2,2.9-0.2,4.3c-0.5,1.4-1.1,2.5-1.7,3.4c-0.5,0.9-0.9,1.6-0.7,2.3c0.2,0.6,0.9,1.1,2.3,1.2c1.4,0,3.5-0.5,5.6-2.2
					c1.1-0.8,2.1-1.9,3-3.4c0.9-1.4,1.6-3.3,1.9-5.4c0.6-4.3-0.9-9.3-4.7-13c-1-0.9-2.1-1.8-3.3-2.5c-1.3-0.7-2.7-1.3-4.2-1.6
					c-1.5-0.3-3-0.5-4.5-0.5c-1.5,0.1-3,0.3-4.4,0.7c-2.9,0.8-5.5,2.2-7.8,4c-1.2,0.9-2.3,2-3.2,3.2c-1,1.2-1.8,2.4-2.6,3.8
					c-1.5,2.7-2.5,6-2.5,9.6c0,3.6,1.2,7.2,2.9,10c1.7,2.9,4,5.1,6.4,6.8c1.2,0.9,2.5,1.6,3.8,2.3c1.3,0.7,2.7,1.2,4,1.6
					c5.5,1.8,10.7,2,15.4,1.5c2.3-0.3,4.5-0.7,6.6-1.3c2.1-0.6,4-1.3,5.8-2.2c7.3-3.4,12.6-8.1,17-13.2c4.4-5.2,7.9-10.9,11-17.6
					c3.2-6.7,5.9-14.3,9.1-23.4c-4,8.8-7.5,16.1-11.4,22.1c-3.9,6.1-8.1,10.9-12.8,14.9c-4.7,3.9-10,7-16.1,8.6
					c-1.5,0.4-3.1,0.7-4.7,0.9c-1.6,0.2-3.3,0.2-5,0.1C53.2,283.5,49.7,282.8,46.6,281.2z"
                  />
                </g>
                <g>
                  <path
                    d="M-32.4,315.2c-2-2.6-4.9-4.2-7.4-4.5c-2.5-0.4-4.6,0.4-5.3,2c-0.7,1.6-0.1,3.6,1.3,5.3c1.4,1.7,3.5,3.3,6.2,4.2
					c2.8,0.9,4.8,1.6,6.8,2.5c2,0.9,3.9,1.9,6.2,4c-1.5-2.8-2.6-4.7-3.6-6.8C-29.3,320-30.4,317.9-32.4,315.2z"
                  />
                </g>
                <g>
                  <path
                    d="M-34.7,295.5c0-2.7-0.4-4.9-1.3-6.9c-0.9-1.9-2.1-3.5-3.9-3.8c-1.7-0.3-3.6,1.1-4.5,3.8c-0.9,2.7-0.6,6.6,1.4,9.7
					c2.1,3.2,4,4.8,5.9,6.3c1.9,1.5,3.9,2.8,6.8,4.4c-2.2-2.4-3.1-4.5-3.7-6.6C-34.5,300.4-34.8,298.3-34.7,295.5z"
                  />
                </g>
                <g>
                  <path
                    d="M-26.6,282.8c1.3-2.3,2-4.4,2.3-6.5c0.2-2,0.1-4.1-1.2-5.3c-0.6-0.6-1.5-0.9-2.5-0.8c-0.5,0.1-1.1,0.2-1.6,0.5
					c-0.5,0.3-1.2,0.6-1.7,1.1c-2.3,1.9-4.1,5.5-3.9,9.3c0.3,3.9,1.2,6.3,2.3,8.6c1.1,2.2,2.3,4.4,4.3,7c-1.1-3.2-1-5.5-0.6-7.6
					C-28.7,287.1-27.9,285.2-26.6,282.8z"
                  />
                </g>
                <g>
                  <path
                    d="M-12.6,277.9c2.3-1.5,3.9-3.4,4.9-5.3c0.9-1.9,1.1-4,0.1-5.4c-1-1.4-3.2-1.8-5.6-0.9c-2.4,0.9-4.9,3.1-6.2,6.1
					c-1.3,3.1-1.8,5.4-2.3,7.6c-0.4,2.2-0.9,4.4-1.5,7.4c1.7-2.6,3.2-4.2,4.8-5.5C-16.7,280.6-15,279.4-12.6,277.9z"
                  />
                </g>
                <g>
                  <path
                    d="M4.7,276.2c2.7-0.3,4.9-1.1,6.6-2.4c1.7-1.3,2.9-2.9,2.7-4.7c-0.2-1.7-1.9-3.2-4.4-3.5c-2.5-0.4-5.9,0.4-8.5,2.6
					c-2.6,2.3-4,4.2-5.4,6c-1.3,1.9-2.6,3.7-4.3,6.3c2.5-1.9,4.5-2.7,6.5-3.3C-0.1,276.8,1.9,276.5,4.7,276.2z"
                  />
                </g>
                <g>
                  <path
                    d="M-32.6,331.5c-5.9-2.5-12.3-0.7-12.8,2.8c-0.4,3.5,5.4,6.9,11.7,5.9c3.2-0.5,5.5-1.1,7.8-1.5c1.2-0.2,2.2-0.5,3.4-0.5
					c0.6,0,1.2,0,1.8,0.2c0.6,0.2,1.3,0.6,1.7,1.3c-0.2-0.8-0.7-1.5-1.2-2c-0.6-0.5-1.2-0.9-1.8-1.2c-0.6-0.3-1.2-0.6-1.8-0.8
					c-0.6-0.2-1.1-0.5-1.6-0.7C-27.5,333.9-29.6,332.8-32.6,331.5z"
                  />
                </g>
                <g>
                  <path
                    d="M-26.1,344.6c-3.2,0.4-5.9,2.5-7,4.8c-1.2,2.2-0.8,4.4,0.5,5.5c1.4,1.1,3.3,1.1,5.1,0.8c1.8-0.4,3.4-1.3,4.9-3
					c1.6-1.7,3-2.9,4.6-3.9c1.6-1,3.4-1.7,6.2-1.9c-2.7-0.9-4.6-1.4-6.7-1.9C-20.6,344.6-22.8,344.2-26.1,344.6z"
                  />
                </g>
                <g>
                  <path
                    d="M-18.7,352.8c-2.9,1.5-4.7,4.4-5.1,6.8c-0.4,2.5,0.5,4.5,2.1,5.1c1.7,0.7,3.5,0.1,5.1-0.9c1.6-1,3-2.5,4-4.7
					c1-2.2,2-3.9,3.1-5.4c1.2-1.5,2.6-2.9,5.2-4.2c-2.9,0.2-5,0.5-7.1,0.9C-13.6,350.8-15.8,351.3-18.7,352.8z"
                  />
                </g>
                <g>
                  <path
                    d="M-9,360.7c-1.6,2.3-1.7,5.3-0.7,7.3c0.9,2,2.7,3,4.4,2.7c1.7-0.3,3.1-1.5,4-2.9c0.9-1.4,1.2-2.9,0.8-4.8
					c-0.4-2-0.6-3.6-0.5-5.2c0.1-1.6,0.4-3.3,1.7-5.4c-2,1.3-3.5,2.4-5,3.5C-5.8,357-7.4,358.3-9,360.7z"
                  />
                </g>
                <g>
                  <path
                    d="M3.3,368.7c0.3,1.5,1,2.7,2,3.7c1,0.9,2.1,1.5,3.2,1.7c1.1,0.2,2.1,0,2.9-0.4c0.8-0.4,1.3-1.1,1.6-1.9
					c0.6-1.7,0.4-3.4,0.1-4.6c-0.3-1.3-0.8-2.1-2.2-2.7c-1.4-0.7-2.5-1.6-3.3-2.7c-0.8-1.2-1.4-2.6-1.1-4.8c-1.3,1.8-2,3.4-2.6,5.1
					C3.3,363.7,2.7,365.7,3.3,368.7z"
                  />
                </g>
                <g>
                  <path
                    d="M21.2,371c2.3,2.1,5.5,2.7,7.9,2.1c2.4-0.6,3.8-2.2,3.7-3.9c0-1.8-1.2-3.3-2.6-4.4c-1.4-1.1-3.1-1.7-5.3-1.8
					c-2.3-0.1-4.1-0.3-5.8-0.8c-1.7-0.5-3.4-1.3-5.4-3.2c1.1,2.5,1.9,4.3,2.9,6.1C17.7,367,18.8,368.9,21.2,371z"
                  />
                </g>
                <g>
                  <path
                    d="M34.6,366c2.2,1.3,4.7,1.2,6.4,0.2c1.7-1,2.6-2.7,2.3-4.4c-0.3-1.7-1.4-3.1-2.7-4c-1.3-0.8-2.8-1.1-4.7-0.5
					c-1.9,0.6-3.3,1-4.8,1.3c-1.5,0.2-3.1,0.3-5.2-0.4c1.6,1.5,2.7,2.7,3.9,3.9C31.1,363.4,32.4,364.7,34.6,366z"
                  />
                </g>
                <g>
                  <path
                    d="M55.9,357.6c2.9,0.2,5.5-1,7-2.5c1.6-1.6,2.1-3.5,1.4-5.1c-0.7-1.6-2.5-2.5-4.5-2.7c-2-0.2-4.2,0.4-6.2,1.9
					c-2.1,1.5-3.6,2.7-5.2,3.7c-1.6,1-3.3,1.9-6,2.6c2.7,0.3,4.7,0.7,6.6,1.1C51,357,52.9,357.4,55.9,357.6z"
                  />
                </g>
                <g>
                  <path
                    d="M77.2,339.2c4.4-0.5,6.5-4.9,4.7-7.9c-1.8-3-6.6-3.2-9.2,0.4c-2.6,3.7-3.2,5.7-6.3,8.9
					C70.6,339.3,72.7,339.8,77.2,339.2z"
                  />
                </g>
                <g>
                  <path
                    d="M92.4,321c2-0.6,3.2-2.1,3.6-3.7c0.4-1.6-0.1-3.4-1.4-4.6c-1.3-1.2-3-1.6-4.5-1.3c-1.5,0.3-2.9,1.3-3.6,3.1
					c-1.4,3.7-1.5,5.7-3.9,8.9C86.3,321.9,88.3,322.2,92.4,321z"
                  />
                </g>
                <g>
                  <path
                    d="M59.8,305.3c-4.5,1.7-8.5,4.1-11.4,6.4c-2.8,2.3-4.5,4.7-3.8,6.3c0.7,1.6,3.6,2.1,7.4,1.3c3.8-0.8,8.5-3,12.3-6.5
					c3.9-3.5,6.3-6.2,8.8-8.9c2.4-2.7,4.8-5.4,8.3-9.3c-4.2,3.1-7.4,4.7-10.6,6.2C67.6,302.3,64.4,303.6,59.8,305.3z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M363.6,398.2c3.2,1.3,6.2,3.2,8.6,5.5c2.4,2.3,4.3,5,5.4,7.7c1.1,2.8,1.6,5.9,1.3,8.8c-0.1,1.4-0.4,2.8-0.9,4.1
					c-0.4,1.3-1.1,2.4-1.8,3.5c-3,4.3-8.8,7.2-14.8,8.1c-1.5,0.3-3,0.4-4.4,0.4c-1.5,0.1-2.9,0-4.3-0.1c-2.7-0.3-5-1-6.7-2
					c-0.8-0.5-1.5-1.1-2-1.7c-0.5-0.6-1-1.4-1.3-2.2c-0.6-1.7-0.8-3.6-0.5-5.2c0.1-0.8,0.4-1.6,0.7-2.3c0.1-0.4,0.4-0.7,0.5-1
					c0.2-0.3,0.4-0.6,0.7-0.9c1-1.2,2.2-2.1,3.6-2.7c2.6-1.2,5.2-1.4,7-1.4c1.8,0,3,0.1,3.4-0.4c0.4-0.5-0.2-1.8-2.1-3.2
					c-1-0.7-2.3-1.3-4-1.8c-1.7-0.4-3.8-0.6-6.1-0.3c-2.3,0.3-4.9,1.2-7.4,3c-0.6,0.4-1.2,1-1.8,1.5c-0.6,0.6-1.2,1.2-1.6,1.9
					c-1,1.3-1.9,2.9-2.5,4.5c-1.3,3.3-1.6,7.2-0.8,11.2c0.4,2,1.2,4.1,2.4,6c1.3,2,2.8,3.6,4.6,5c3.6,2.8,7.8,4.3,11.9,5.1
					c2.1,0.4,4.2,0.6,6.3,0.7c2.1,0.1,4.3,0,6.4-0.3c2.2-0.2,4.4-0.7,6.6-1.3c2.2-0.6,4.4-1.4,6.6-2.5c2.2-1.1,4.3-2.4,6.3-4
					c2-1.6,3.8-3.5,5.5-5.7c1.6-2.2,2.9-4.6,3.8-7.2c0.9-2.6,1.5-5.2,1.7-7.9c0.4-5.4-0.6-10.7-2.7-15.7c-2.2-5-5.6-9.2-9.6-12.6
					c-4-3.3-8.6-5.8-13.4-7.3c-5-1.6-9.9-2.2-14.5-2.1c-4.6,0-9,0.7-13,1.7c-8.1,2.1-15,5.4-21,9.4c-6,4-11,8.6-15.3,13.5
					c-4.3,4.9-8,10-11.1,15.5c-3.1,5.4-5.8,11.2-8,17.2c-2.2,6.1-3.9,12.4-5.3,19.3c-2.7,13.7-3.8,29-3.8,47.2
					c0.7-18.2,2.8-33.4,6.3-46.6c1.8-6.6,3.9-12.7,6.5-18.3c2.6-5.7,5.5-10.9,8.9-15.7c3.4-4.8,7.3-9.3,11.6-13.4
					c4.4-4.1,9.3-7.7,14.8-10.7c5.5-3,11.8-5.2,18.5-6.4c3.4-0.5,6.9-0.8,10.4-0.5C356.7,396.1,360.3,396.8,363.6,398.2z"
                  />
                </g>
                <g>
                  <path
                    d="M331,455.1c1.7,0.6,3,1.3,4.3,2.2c1.2,0.9,2.2,1.9,2.9,2.8c0.3,0.5,0.5,0.9,0.7,1.3c0.2,0.4,0.2,0.8,0.3,1.2
					c0.1,0.8,0,1.8-0.4,2.8c-0.2,0.5-0.4,1.1-0.7,1.6c-0.3,0.5-0.6,0.9-1,1.4c-0.7,0.9-1.6,1.6-2.4,2.1c-0.4,0.2-0.8,0.4-1.1,0.6
					c-0.4,0.1-0.7,0.2-1,0.3c-0.3,0-0.6,0.1-0.9,0c-0.3,0-0.7-0.1-1-0.2c-0.7-0.3-1.4-0.7-1.9-1.2c-0.5-0.5-1-1.1-1.2-1.7
					c-0.6-1.2-0.6-2.9-0.3-4.3c0.3-1.4,0.8-2.6,1.2-3.6c0.4-1,0.7-1.7,0.4-2.4c-0.2-0.6-1-1-2.5-0.9c-1.4,0.2-3.4,0.9-5.3,2.9
					c-0.9,1-1.9,2.2-2.6,3.7c-0.7,1.6-1.2,3.4-1.2,5.6c0,4.3,2.1,9.1,6.4,12.3c1.1,0.8,2.3,1.5,3.6,2.1c1.3,0.5,2.9,0.9,4.4,1.1
					c1.5,0.1,3,0.1,4.5-0.1c1.5-0.3,2.9-0.7,4.3-1.2c2.7-1.2,5.2-2.9,7.2-5c1-1.1,2-2.3,2.8-3.6c0.8-1.3,1.5-2.6,2-4.1
					c1.1-2.9,1.7-6.3,1.2-9.9c-0.5-3.6-2.1-7-4.2-9.5c-2.1-2.6-4.6-4.5-7.3-5.9c-1.3-0.7-2.7-1.3-4.1-1.8c-1.4-0.5-2.8-0.8-4.1-1.1
					c-5.7-1.1-10.9-0.6-15.4,0.5c-2.3,0.6-4.4,1.3-6.3,2.2c-2,0.9-3.8,1.8-5.5,2.9c-6.8,4.3-11.4,9.7-15.1,15.3
					c-3.7,5.7-6.4,11.9-8.7,18.9c-2.3,7-4,15-6,24.4c2.8-9.2,5.4-16.9,8.5-23.4c3.1-6.5,6.6-11.9,10.7-16.4c4.1-4.5,9-8.3,14.8-10.6
					c1.5-0.6,3-1.1,4.6-1.5c1.6-0.4,3.2-0.6,4.9-0.8C324,453.6,327.6,453.9,331,455.1z"
                  />
                </g>
                <g>
                  <path
                    d="M404.9,411.1c2.3,2.3,5.4,3.5,7.9,3.5c2.5,0,4.5-1,5-2.7c0.5-1.7-0.4-3.6-2-5.1c-1.6-1.6-3.9-2.8-6.7-3.3
					c-2.9-0.6-5-1-7-1.6c-2.1-0.6-4.1-1.4-6.7-3.2c1.9,2.5,3.2,4.4,4.4,6.3C401.2,406.8,402.5,408.7,404.9,411.1z"
                  />
                </g>
                <g>
                  <path
                    d="M409.7,430.4c0.3,2.7,1.1,4.8,2.2,6.6c1.1,1.8,2.6,3.2,4.3,3.3c1.7,0.1,3.4-1.5,4-4.4c0.6-2.8-0.2-6.6-2.6-9.4
					c-2.5-2.9-4.6-4.3-6.7-5.5c-2.1-1.2-4.2-2.3-7.3-3.5c2.5,2.1,3.7,4.1,4.5,6.1C408.9,425.6,409.4,427.6,409.7,430.4z"
                  />
                </g>
                <g>
                  <path
                    d="M403.3,444.1c-1,2.4-1.5,4.6-1.4,6.7c0,2,0.4,4,1.8,5.1c0.7,0.5,1.6,0.7,2.6,0.4c0.5-0.1,1.1-0.3,1.6-0.7
					c0.5-0.3,1.1-0.8,1.6-1.3c2-2.1,3.4-6,2.6-9.8c-0.8-3.8-2.1-6.1-3.4-8.2c-1.4-2.1-2.8-4-5.2-6.4c1.5,3,1.7,5.3,1.5,7.4
					C404.8,439.5,404.3,441.5,403.3,444.1z"
                  />
                </g>
                <g>
                  <path
                    d="M390,450.7c-2.1,1.7-3.5,3.8-4.1,5.9c-0.6,2-0.6,4.1,0.6,5.4c1.2,1.3,3.4,1.3,5.6,0.1c2.2-1.2,4.4-3.7,5.3-6.8
					c0.9-3.3,1.1-5.6,1.3-7.8c0.2-2.3,0.3-4.5,0.6-7.6c-1.3,2.8-2.6,4.5-4.1,6.1C393.8,447.5,392.2,448.9,390,450.7z"
                  />
                </g>
                <g>
                  <path
                    d="M373.1,454.6c-2.6,0.6-4.7,1.8-6.2,3.2c-1.5,1.5-2.5,3.3-2.1,5c0.4,1.7,2.3,2.9,4.9,2.9c2.6,0,5.8-1.2,8.1-3.7
					c2.3-2.6,3.4-4.6,4.5-6.7c1.1-2,2.1-4,3.4-6.8c-2.2,2.2-4.1,3.3-6,4.1C377.8,453.4,375.8,453.9,373.1,454.6z"
                  />
                </g>
                <g>
                  <path
                    d="M402.9,395c6.1,1.8,12.3-0.9,12.3-4.5c0-3.5-6.2-6.1-12.3-4.3c-3.2,0.9-5.3,1.8-7.5,2.5c-1.1,0.4-2.2,0.8-3.3,0.9
					c-0.6,0.1-1.2,0.1-1.8,0c-0.6-0.1-1.3-0.5-1.8-1.1c0.3,0.8,0.9,1.4,1.5,1.9c0.6,0.4,1.3,0.7,2,1c0.6,0.2,1.3,0.4,1.9,0.6
					c0.6,0.2,1.1,0.3,1.7,0.5C397.6,393.2,399.8,394.1,402.9,395z"
                  />
                </g>
                <g>
                  <path
                    d="M394.7,382.8c3.1-0.8,5.5-3.3,6.3-5.6c0.9-2.4,0.2-4.4-1.3-5.3c-1.5-0.9-3.4-0.7-5.1-0.1c-1.7,0.6-3.2,1.7-4.5,3.6
					c-1.4,1.9-2.6,3.3-4,4.4c-1.4,1.1-3.1,2.2-5.9,2.7c2.8,0.5,4.8,0.8,6.9,1C389.3,383.5,391.5,383.6,394.7,382.8z"
                  />
                </g>
                <g>
                  <path
                    d="M386.4,375.6c2.7-1.9,4.1-4.9,4.2-7.4c0.1-2.5-1.1-4.4-2.8-4.8c-1.7-0.4-3.5,0.4-5,1.5c-1.5,1.2-2.6,2.9-3.3,5.2
					c-0.7,2.4-1.5,4.1-2.4,5.8c-1,1.6-2.2,3.2-4.6,4.8c2.8-0.6,4.8-1.2,6.9-1.8C381.5,378.3,383.7,377.5,386.4,375.6z"
                  />
                </g>
                <g>
                  <path
                    d="M375.7,369.1c1.3-2.5,1-5.5-0.2-7.3c-1.2-1.9-3.1-2.6-4.7-2.1c-1.7,0.5-2.9,1.9-3.6,3.3c-0.7,1.5-0.8,3-0.2,4.9
					c0.6,1.9,1,3.5,1.2,5.1c0.1,1.6,0,3.3-1,5.5c1.8-1.6,3.2-2.8,4.5-4.1C373.1,373.1,374.4,371.7,375.7,369.1z"
                  />
                </g>
                <g>
                  <path
                    d="M362.5,362.8c-0.5-1.4-1.4-2.6-2.5-3.4c-1.1-0.8-2.3-1.2-3.4-1.3c-1.1-0.1-2.1,0.2-2.8,0.8c-0.7,0.5-1.2,1.3-1.4,2.1
					c-0.4,1.7,0.1,3.4,0.5,4.6c0.5,1.2,1.1,1.9,2.5,2.4c1.5,0.5,2.7,1.2,3.6,2.3c0.9,1,1.7,2.4,1.8,4.6c1-2,1.6-3.6,2-5.4
					C363.2,367.7,363.5,365.7,362.5,362.8z"
                  />
                </g>
                <g>
                  <path
                    d="M344.5,362.7c-2.6-1.8-5.9-2-8.1-1.1c-2.3,0.9-3.4,2.7-3.2,4.4c0.3,1.8,1.6,3.1,3.1,4c1.5,0.9,3.3,1.3,5.5,1.1
					c2.3-0.2,4.1-0.2,5.8,0c1.8,0.3,3.6,0.8,5.8,2.5c-1.4-2.3-2.5-4-3.7-5.7C348.5,366.3,347.1,364.6,344.5,362.7z"
                  />
                </g>
                <g>
                  <path
                    d="M331.8,369.5c-2.3-1-4.8-0.5-6.4,0.6c-1.6,1.2-2.2,3-1.8,4.7c0.5,1.7,1.8,2.9,3.2,3.6c1.4,0.6,3,0.7,4.7-0.1
					c1.8-0.8,3.2-1.5,4.6-1.9c1.5-0.4,3-0.7,5.2-0.3c-1.8-1.3-3.1-2.3-4.4-3.4C335.6,371.6,334.2,370.5,331.8,369.5z"
                  />
                </g>
                <g>
                  <path
                    d="M311.8,380.6c-2.9,0.2-5.3,1.7-6.6,3.4c-1.4,1.8-1.7,3.8-0.7,5.3c0.9,1.5,2.8,2.1,4.8,2c2-0.1,4.1-0.9,5.9-2.7
					c1.9-1.8,3.2-3.1,4.7-4.3c1.5-1.2,3-2.3,5.6-3.4c-2.8,0-4.7-0.1-6.7-0.3C316.8,380.6,314.7,380.4,311.8,380.6z"
                  />
                </g>
                <g>
                  <path
                    d="M293.1,401.5c-4.3,1.1-5.8,5.7-3.6,8.4c2.2,2.8,7,2.4,9-1.5c2.1-4,2.4-6.1,5.1-9.6C299.6,400.6,297.5,400.4,293.1,401.5
					z"
                  />
                </g>
                <g>
                  <path
                    d="M280.3,421.6c-1.9,0.9-2.9,2.5-3.1,4.2c-0.2,1.7,0.5,3.3,2,4.4c1.4,1,3.2,1.2,4.7,0.7c1.5-0.5,2.7-1.7,3.1-3.6
					c0.9-3.9,0.8-5.9,2.7-9.4C286.3,419.9,284.3,419.9,280.3,421.6z"
                  />
                </g>
                <g>
                  <path
                    d="M314.7,432.9c4.2-2.3,7.9-5.1,10.4-7.8c2.5-2.7,3.8-5.2,2.9-6.7c-0.9-1.5-3.8-1.6-7.5-0.3c-3.7,1.3-8,4.1-11.4,8
					c-3.5,4-5.5,7-7.5,10c-2,3-4,6-7,10.3c3.8-3.6,6.7-5.7,9.7-7.5C307.3,436.9,310.4,435.2,314.7,432.9z"
                  />
                </g>
              </g>
              <g>
                <path
                  d="M344.5,109.3c1.3-4.4,1.9-9.1,1.4-13.7c-0.4-4.6-1.8-9.3-4.3-13.4c-0.3-0.5-0.6-1-1-1.5c-0.4-0.5-0.7-1-1.1-1.5
				c-0.8-0.9-1.5-1.8-2.4-2.7c-1.7-1.7-3.6-3.1-5.6-4.3c-4-2.4-8.3-3.8-12.6-4.4c-4.3-0.6-8.7-0.6-12.9,0.4
				c-4.2,0.9-8.3,2.7-11.6,5.5c-3.3,2.7-5.9,6.2-7.2,10.2c-0.7,1.9-1.1,4.1-1.1,6.1c0,2,0.2,4,0.8,5.9c1.1,3.7,3.3,7,6.3,9.2
				c3,2.2,6.5,3.1,9.6,3c3.1-0.1,6.1-1.1,8.3-2.8c1.1-0.9,2-1.9,2.7-2.9c0.7-1.1,1.2-2.2,1.4-3.2c0.6-2.1,0.5-4.1,0.2-5.7
				c-0.3-1.6-1-2.9-1.6-3.8c-1.4-1.8-2.6-2.1-2.9-1.8c-0.3,0.3,0.2,1.3,0.6,2.9c0.2,0.8,0.3,1.8,0.2,3c-0.1,1.1-0.4,2.4-1,3.7
				c-0.3,0.6-0.7,1.2-1.2,1.7c-0.5,0.5-1.1,0.9-1.7,1.3c-1.3,0.7-3,1.1-4.8,0.9c-1.8-0.2-3.7-0.9-5-2.1c-1.4-1.3-2.5-3.2-2.9-5.3
				c-0.2-1.1-0.3-2.2-0.2-3.4c0.1-1.1,0.4-2.2,0.8-3.3c0.9-2.2,2.6-4.3,4.7-6c2.1-1.6,4.8-2.7,7.7-3.3c2.9-0.6,6.2-0.6,9.4-0.1
				c6.4,1,12.6,4.2,15.9,9.6c3.5,5.4,4.4,12.8,2.7,19.6c-0.4,1.7-1,3.4-1.8,5c-0.7,1.6-1.6,3.1-2.6,4.6c-2,2.9-4.2,5.4-6.7,7.6
				c-5,4.4-10.6,7.5-16.3,9.7c-5.7,2.3-11.4,3.6-17.1,4.6c-5.7,1-11.4,1.5-17.1,1.9c-5.7,0.4-11.5,0.5-17.5,0.9
				c-6,0.4-12.3,1.1-18.6,3c-3.2,0.9-6.4,2.1-9.5,3.8c-3.1,1.6-6.1,3.7-8.8,6.3c-2.7,2.6-5,5.8-6.7,9.4c-1.6,3.6-2.6,7.8-2.2,12.1
				c-0.2-4.3,0.9-8.4,2.6-12c1.8-3.5,4.1-6.6,6.9-9c2.7-2.4,5.7-4.3,8.8-5.8c3.1-1.4,6.2-2.5,9.4-3.2c6.3-1.5,12.4-1.9,18.3-1.9
				c5.9-0.1,11.7,0.2,17.5,0.2c5.8,0,11.6-0.2,17.6-0.8c6-0.7,12.2-1.7,18.5-3.8c6.3-2,12.9-5,19-9.8c3.1-2.4,6-5.2,8.6-8.6
				C340.9,117.8,343.1,113.9,344.5,109.3z"
                />
              </g>
              <g>
                <path
                  d="M278.5,96c-3.9-1.2-8.4-1.3-12.5,0.5c-2,0.9-3.8,2.1-5.3,3.7c-1.5,1.5-2.7,3.3-3.6,5.3c-0.8,2-1.2,4.2-1.1,6.4
				c0.1,2.2,0.7,4.2,1.6,6c1.8,3.5,4.9,6.1,8.4,7c3.5,1,6.9,0.3,9.4-1.4c1.2-0.8,2.2-1.9,2.8-3c0.6-1.1,0.9-2.2,1-3.1
				c0.2-1.9-0.3-3.4-0.9-4.2c-0.6-0.8-1.2-1-1.6-0.9c-0.8,0.3-0.9,2.1-2.2,3.6c-0.3,0.4-0.7,0.8-1.1,1c-0.4,0.3-0.8,0.4-1.3,0.5
				c-1,0.2-2.4,0.1-3.4-0.5c-1.1-0.6-2.2-1.8-2.7-3.2c-0.6-1.5-0.5-2.8,0.1-4.4c0.7-1.6,2.1-3.1,3.8-4c1.7-0.9,3.9-1.2,6.3-0.9
				c4.9,0.7,8.5,4.3,10.6,8.1c2.2,3.9,3,8.1,2.7,12.3c-0.2,2.1-0.6,4.2-1.5,6.2c-0.4,1-1,2-1.6,3c-0.7,0.9-1.4,1.8-2.4,2.7
				c-1.8,1.6-4.1,3-6.9,3.8c-2.7,0.7-6,0.9-9.2-0.3c3.1,1.4,6.5,1.6,9.4,1.1c3-0.5,5.6-1.7,7.8-3.3c1.1-0.8,2.1-1.7,3-2.6
				c0.9-1,1.7-2,2.3-3.1c1.4-2.1,2.2-4.5,2.9-6.9c1.1-4.8,1-10-0.9-15.4c-1-2.7-2.4-5.4-4.7-8C285.7,99.6,282.5,97.2,278.5,96z"
                />
              </g>
              <g>
                <path
                  d="M315.1,122.6c2.7-2.4,4.1-5.8,4.2-8.5c0.1-2.8-0.9-4.8-2.6-5.3c-1.7-0.5-3.6,0.5-5.2,2.2c-1.6,1.7-2.9,3.9-3.7,6.8
				c-0.8,2.9-1.5,5-2.5,7.1c-1,2-2.1,4-4.5,6.4c3-1.5,5.1-2.6,7.3-3.8C310.2,126.3,312.4,125.1,315.1,122.6z"
                />
              </g>
              <g>
                <path
                  d="M366,89.8c2.5-1.7,4.3-3.8,5.4-5.9c1.1-2.1,1.5-4.2,0.4-5.7c-1-1.4-3.3-1.7-5.9-0.7c-2.6,1.1-5.4,3.6-6.8,6.9
				c-1.5,3.5-2,6-2.5,8.4c-0.4,2.5-0.8,4.9-1.4,8.3c1.5-3.1,3.1-4.9,4.7-6.6C361.7,93,363.5,91.6,366,89.8z"
                />
              </g>
              <g>
                <path
                  d="M379.9,95.6c3-0.8,5.4-2.4,6.9-4.3c1.5-1.8,2.2-3.9,1.4-5.5c-0.8-1.6-2.9-2.2-5.4-1.9c-2.5,0.3-5.4,1.7-7.6,4.1
				c-2.3,2.5-3.6,4.4-5,6.2c-1.4,1.8-2.8,3.6-5.1,6c2.9-1.5,5.1-2.3,7.2-2.9C374.6,96.9,376.8,96.4,379.9,95.6z"
                />
              </g>
              <g>
                <path
                  d="M390.9,105.2c3.1,0.2,5.9-0.4,8-1.5c2.1-1.1,3.7-2.6,3.6-4.4c0-1.7-1.7-3.3-4.4-4c-2.6-0.7-6.2-0.4-9.3,1.3
				c-3.2,1.7-5.2,3.3-7.1,4.7c-1.9,1.5-3.8,3-6.6,5c3.3-1.1,5.6-1.4,7.9-1.5C385.4,104.9,387.7,105,390.9,105.2z"
                />
              </g>
              <g>
                <path
                  d="M360.3,116.8c2.7-0.8,4.8-2.3,6.1-4c1.3-1.7,2-3.7,1.2-5.3c-0.7-1.6-2.7-2.4-5.1-2.1c-2.4,0.3-5.2,1.7-7.2,4.2
				c-2,2.5-3.1,4.5-4.2,6.4c-1.1,1.9-2.2,3.8-3.9,6.3c2.5-1.8,4.4-2.7,6.3-3.5C355.5,118.1,357.5,117.6,360.3,116.8z"
                />
              </g>
              <g>
                <path
                  d="M373.4,121.3c2.6,0.1,4.9-0.6,6.6-1.8c1.7-1.2,2.8-2.9,2.6-4.6c-0.2-1.7-1.8-3.1-4.1-3.6c-2.3-0.5-5.2,0-7.7,1.7
				c-2.5,1.8-4.1,3.3-5.6,4.7c-1.5,1.5-3,2.9-5.3,4.7c2.8-0.9,4.8-1.1,6.7-1.2C368.7,121,370.7,121.2,373.4,121.3z"
                />
              </g>
              <g>
                <path
                  d="M293.2,147.9c-6.4,0.5-11.3,5.2-10.1,8.5c1.2,3.3,8,3.6,13.2-0.3c5.3-4,7-6.2,12.8-9.5
				C302.5,148,299.8,147.4,293.2,147.9z"
                />
              </g>
              <g>
                <path
                  d="M300.9,162.8c-1.7,2.4-2,5.5-1.2,7.6c0.7,2.2,2.4,3.5,4.1,3.4c1.8-0.1,3.2-1.3,4.2-2.8c1-1.5,1.5-3.4,1.3-5.6
				c-0.2-2.3-0.3-4.1-0.2-5.9c0.2-1.8,0.5-3.6,1.8-5.9c-2.1,1.6-3.6,2.8-5.1,4.1C304.2,159,302.7,160.4,300.9,162.8z"
                />
              </g>
              <g>
                <path
                  d="M250.7,158.6c5.2-0.2,10.2-0.8,14.1-1.6c3.9-0.8,6.7-2,7-3.7c0.2-1.7-2.4-3.7-6.9-4.6c-4.4-1-10.5-0.7-16.1,1.4
				c-5.7,2.2-9.2,4.4-12.7,6.7c-3.4,2.3-6.7,4.7-11.2,8.5c5.1-2.9,9-4.2,12.8-5.1C241.5,159.3,245.3,158.8,250.7,158.6z"
                />
              </g>
              <g>
                <path
                  d="M452.7,61.6c-0.5-3.2-1.3-6.4-2.5-9.5c-1.2-3.1-2.9-6.2-5.1-8.9c-2.2-2.7-5-5-8.1-6.7c-3.1-1.7-6.7-2.7-10.3-2.8l-1.4,0
				l-1.4,0.1c-0.9,0.1-1.8,0.3-2.7,0.5c-1.8,0.5-3.4,1.1-4.9,2c-3,1.7-5.6,4.2-7.3,7.3c-1.7,3-2.4,6.8-1.9,10
				c0.2,1.6,0.7,3.2,1.3,4.6c0.6,1.4,1.4,2.7,2.2,3.8c3.4,4.5,8.5,7,13.1,7.2c2.3,0.1,4.6-0.3,6.5-1.3c1-0.5,1.8-1.1,2.5-1.8
				c0.7-0.7,1.2-1.5,1.7-2.3c1.5-3.2,1-6,0-7.5c-0.5-0.8-1-1.2-1.4-1.4c-0.4-0.2-0.7-0.2-0.9,0c-0.3,0.3-0.1,1.1-0.1,2.2
				c0,0.6-0.1,1.2-0.4,1.9c-0.3,0.7-0.7,1.5-1.3,2.1c-0.3,0.3-0.7,0.6-1,0.8c-0.3,0.2-0.8,0.4-1.3,0.5c-1,0.2-2.2,0.2-3.5-0.1
				c-2.6-0.6-5.4-2.4-7.1-5.2c-0.4-0.7-0.8-1.4-1-2.2c-0.2-0.8-0.4-1.5-0.4-2.3c-0.1-0.8,0-1.5,0.2-2.2c0.2-0.7,0.5-1.4,0.9-2.1
				c0.8-1.4,2.2-2.6,3.9-3.5c0.8-0.4,1.7-0.8,2.6-1c0.4-0.1,0.9-0.2,1.4-0.2l0.7,0l0.7,0c1.9,0.1,4,0.6,5.9,1.7
				c1.9,1,3.8,2.5,5.3,4.2c3.1,3.5,5.2,8.4,6.4,13.7c1.2,5.5,1.6,10.9,1.4,15.9c-0.2,5-0.9,9.7-1.9,14.1c-2.1,8.8-5.4,16.4-9.6,23.5
				c-4.2,7.1-9.5,13.8-16.3,20.4c-6.8,6.6-15.2,13.2-26.1,19.6c11.2-6,20-12.1,27.3-18.3c7.3-6.2,13.3-12.6,18.3-19.6
				c5-7,9.2-14.7,12.3-23.9c1.5-4.6,2.7-9.6,3.4-15.1C453.5,74.1,453.6,68.1,452.7,61.6z"
                />
              </g>
              <g>
                <path
                  d="M420.9,70.1c-1.7-0.9-3.7-1.5-5.7-1.6c-2.1-0.2-4.2,0.2-6.2,0.9c-1.9,0.7-3.7,1.8-5.2,3.1c-1.5,1.4-2.7,3-3.5,5
				c-0.8,1.9-1.2,4.1-1.1,6c0.1,2,0.6,3.8,1.4,5.5c1.6,3.3,4.3,5.8,7.4,6.9c1.6,0.6,3.2,0.8,4.6,0.6c1.4-0.1,2.7-0.5,3.8-0.9
				c2.2-1,3.7-2.4,4.6-3.8c0.9-1.4,1.2-2.7,1.1-3.6c-0.1-0.9-0.4-1.4-0.8-1.5c-0.9-0.2-1.9,0.9-3.8,1.6c-0.9,0.4-2,0.7-3.2,0.8
				c-1.2,0-2.4-0.2-3.2-0.9c-0.9-0.6-1.7-1.7-2.1-3c-0.2-0.6-0.3-1.3-0.3-2c0-0.6,0.2-1.2,0.4-1.8c0.5-1.2,1.7-2.4,3.2-3
				c0.7-0.3,1.5-0.5,2.2-0.6c0.8,0,1.7,0.1,2.6,0.3c1.9,0.6,3.8,1.9,5.4,3.4c0.8,0.8,1.5,1.6,2.2,2.4c0.7,0.8,1.3,1.7,1.9,2.6
				c2.3,3.5,3.9,7.1,5,10.9c1.1,3.8,1.7,7.8,1.5,12.4c0,0.6-0.1,1.1-0.1,1.7c0,0.6-0.1,1.2-0.2,1.8c-0.1,1.2-0.4,2.4-0.6,3.7
				c-0.6,2.5-1.4,5.2-2.7,8c1.5-2.7,2.7-5.3,3.5-7.8c0.4-1.3,0.8-2.5,1.1-3.7c0.1-0.6,0.3-1.2,0.4-1.8c0.1-0.6,0.2-1.2,0.3-1.8
				c0.8-4.7,0.9-9,0.5-13.3c-0.5-4.3-1.4-8.6-3.3-13.1c-0.5-1.1-1-2.3-1.7-3.4c-0.6-1.2-1.4-2.4-2.2-3.5
				C426.7,74.4,424.4,72,420.9,70.1z"
                />
              </g>
              <g>
                <g>
                  <path
                    d="M503.8,68.5c5.7-0.7,11.4-1.1,16.9-1.7c5.5-0.5,10.7-1.1,15.1-2c4.5-0.8,8.2-1.9,10.7-2.9c2.5-1.1,3.9-2.2,3.7-3
					c-0.2-0.9-1.8-1.4-4.4-1.8c-2.6-0.4-6.3-0.5-10.8-0.6c-4.4-0.1-9.6,0-15.3,0.4c-5.6,0.4-11.8,1.3-17.7,2.9
					c-6.2,1.7-11.2,3.7-15.6,6c-4.3,2.3-8,4.8-11.3,7.8c-0.8,0.7-1.6,1.5-2.4,2.3c-0.8,0.8-1.5,1.6-2.3,2.5
					c-1.5,1.7-2.9,3.6-4.2,5.6c-2.6,4.1-4.9,9.2-5.6,15.5c1.2-6.2,4.1-10.9,7-14.5c1.5-1.8,3.1-3.4,4.7-4.7c0.8-0.7,1.7-1.3,2.5-2
					c0.9-0.6,1.7-1.2,2.6-1.7c3.5-2.2,7.2-3.8,11.4-5.1C493.2,70.2,498,69.2,503.8,68.5z"
                  />
                </g>
                <g>
                  <path
                    d="M512,85.7c5.6-1.4,11.1-3.6,15.9-6.1c4.8-2.5,9.1-5.5,12.4-8.3c6.7-5.7,9.8-10.9,8.6-12.1c-1.2-1.3-6.2,1.4-13.3,5.2
					c-7.1,3.9-16.5,8.8-26.3,13c-5.1,2.2-9.5,3.7-13.6,4.9c-4.1,1.2-8,2.1-11.9,3.3c-3.9,1.1-7.8,2.4-11.9,4.4c-2,1-4.2,2.2-6.2,3.8
					c-2.1,1.5-4.1,3.5-5.8,5.9c1.9-2.2,4.1-3.9,6.3-5.2c2.2-1.3,4.3-2.2,6.4-2.9c4.2-1.4,8.1-1.9,12-2.3c3.9-0.4,7.8-0.5,12.3-1
					C501.2,87.9,506.2,87.1,512,85.7z"
                  />
                </g>
                <g>
                  <path
                    d="M519.6,76.1c6.3-1,12.2-3.4,16.2-6c4.1-2.6,6.3-5.3,5.6-6.9c-0.7-1.6-4.1-1.8-8.7-0.9c-4.5,0.9-10.2,2.7-15.7,5.4
					c-5.7,2.7-9.6,4.6-13.6,6.3c-4.1,1.7-8.3,3.3-14.6,4.9c6.6-0.6,11-0.9,15.5-1.2C508.7,77.4,513.2,77.1,519.6,76.1z"
                  />
                </g>
                <g>
                  <path
                    d="M517.7,72.2c4.9-0.5,9.4-2.3,12.5-4.4c3.1-2.1,4.8-4.4,4.1-6c-0.7-1.6-3.4-2.2-7-1.8c-3.6,0.4-8,1.7-12.2,3.8
					c-4.3,2.3-7.3,3.9-10.4,5.4c-3.1,1.5-6.2,2.9-11.1,4.4c5-0.4,8.5-0.6,12-0.7C509.2,72.8,512.7,72.7,517.7,72.2z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M490.4,213.7c-5.6-1.1-11.2-2.4-16.6-3.5c-5.4-1.2-10.5-2.2-15-2.7c-4.5-0.6-8.4-0.7-11.1-0.5c-2.7,0.3-4.3,0.9-4.5,1.8
					c-0.1,0.9,1.2,1.9,3.6,3c2.4,1.1,5.9,2.4,10.1,3.8c4.2,1.4,9.2,2.9,14.7,4.2c5.5,1.3,11.6,2.3,17.8,2.6c6.4,0.2,11.8-0.1,16.7-1
					c4.8-0.9,9.1-2.1,13.1-4c1-0.4,2-1,3-1.4c1-0.5,2-1.1,2.9-1.7c1.9-1.2,3.8-2.5,5.7-4.1c3.7-3.1,7.4-7.3,10-13.1
					c-3.1,5.6-7.2,9.1-11.1,11.7c-2,1.3-3.9,2.3-5.9,3.1c-1,0.4-2,0.8-2.9,1.1c-1,0.3-2,0.6-3,0.8c-3.9,1-8,1.4-12.4,1.4
					C501.1,215.3,496.2,214.8,490.4,213.7z"
                  />
                </g>
                <g>
                  <path
                    d="M487.9,194.8c-5.8-0.4-11.6,0.1-17,1c-5.4,1-10.3,2.5-14.3,4.2c-8.1,3.4-12.6,7.4-11.8,8.9c0.8,1.6,6.3,0.6,14.3-0.9
					c8-1.5,18.4-3.4,29-4.4c5.5-0.6,10.2-0.7,14.5-0.6c4.3,0.1,8.3,0.4,12.3,0.5c4,0.1,8.2,0.1,12.7-0.6c2.3-0.4,4.6-0.9,7.1-1.7
					c2.4-0.9,4.9-2.1,7.3-3.9c-2.5,1.6-5.1,2.5-7.5,3.1c-2.4,0.6-4.8,0.8-7,0.8c-4.4,0-8.3-0.6-12.1-1.4c-3.8-0.8-7.6-1.9-12-2.8
					C498.8,196,493.9,195.2,487.9,194.8z"
                  />
                </g>
                <g>
                  <path
                    d="M477.7,201.6c-6.3-0.9-12.6-0.4-17.3,0.8c-4.7,1.2-7.6,3.1-7.4,4.9c0.2,1.8,3.4,2.9,8,3.5c4.6,0.5,10.6,0.5,16.6-0.4
					c6.2-0.9,10.5-1.5,14.9-1.9c4.4-0.4,8.9-0.6,15.4-0.3c-6.4-1.4-10.8-2.5-15.2-3.5C488.5,203.7,484.2,202.6,477.7,201.6z"
                  />
                </g>
                <g>
                  <path
                    d="M478.3,205.9c-4.8-1-9.7-0.7-13.3,0.4c-3.6,1-5.9,2.8-5.8,4.5c0.1,1.8,2.6,3.1,6.1,3.9c3.5,0.7,8.1,0.8,12.8,0
					c4.8-0.8,8.1-1.5,11.5-2c3.4-0.5,6.8-0.9,11.9-0.8c-4.9-1.1-8.3-2-11.6-3C486.6,207.9,483.3,206.9,478.3,205.9z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M295.7,319.1c4.1-3.9,8.4-7.8,12.5-11.5c4.1-3.7,7.9-7.3,10.9-10.7c3.1-3.3,5.4-6.4,6.8-8.8c1.4-2.4,1.8-4.1,1.2-4.7
					c-0.6-0.6-2.3-0.1-4.6,1.2c-2.3,1.3-5.4,3.4-9,6c-3.6,2.6-7.7,5.8-12,9.5c-4.2,3.7-8.6,8.1-12.4,13c-3.9,5.1-6.7,9.7-8.9,14.2
					c-2.1,4.4-3.5,8.7-4.4,13c-0.2,1.1-0.4,2.2-0.6,3.3c-0.1,1.1-0.3,2.2-0.3,3.3c-0.2,2.3-0.2,4.6,0,7c0.4,4.8,1.6,10.3,4.8,15.8
					c-2.8-5.7-3.3-11.1-3.1-15.8c0.1-2.3,0.4-4.5,0.9-6.6c0.2-1,0.5-2,0.8-3c0.3-1,0.6-2,1-2.9c1.5-3.8,3.5-7.3,6.1-11
					C288.2,326.9,291.4,323.2,295.7,319.1z"
                  />
                </g>
                <g>
                  <path
                    d="M312.5,328c3.7-4.5,6.7-9.5,9-14.5c2.3-4.9,4-9.8,4.9-14.1c1.9-8.6,1.3-14.5-0.4-14.8c-1.8-0.3-4.2,4.8-7.5,12.2
					c-3.4,7.4-7.9,16.9-13.3,26.2c-2.7,4.8-5.4,8.7-7.9,12.1c-2.6,3.4-5.1,6.5-7.5,9.7c-2.4,3.2-4.8,6.6-6.9,10.7
					c-1,2.1-2,4.3-2.7,6.8c-0.7,2.5-1.2,5.2-1.1,8.2c0.2-3,0.9-5.6,1.9-7.9c1-2.3,2.2-4.3,3.4-6.1c2.5-3.6,5.3-6.4,8.2-9
					c2.9-2.7,5.9-5.1,9.3-8.2C305.2,336.2,308.7,332.7,312.5,328z"
                  />
                </g>
                <g>
                  <path
                    d="M312.9,315.8c4.4-4.6,7.7-10,9.4-14.5c1.7-4.5,1.8-8,0.3-8.8c-1.6-0.9-4.4,1.1-7.5,4.5c-3.1,3.4-6.5,8.3-9.3,13.8
					c-2.9,5.6-4.9,9.4-7.1,13.3c-2.2,3.8-4.6,7.6-8.7,12.7c4.9-4.4,8.3-7.4,11.7-10.3C305,323.4,308.3,320.5,312.9,315.8z"
                  />
                </g>
                <g>
                  <path
                    d="M309,313.8c3.6-3.4,6.2-7.5,7.4-11c1.2-3.5,1.2-6.4-0.3-7.3c-1.5-0.9-4,0.3-6.7,2.7c-2.6,2.5-5.4,6.1-7.4,10.4
					c-2.1,4.4-3.5,7.5-5,10.5c-1.6,3-3.3,6.1-6.2,10.2c3.8-3.4,6.5-5.6,9.2-7.8C302.6,319.3,305.3,317.2,309,313.8z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M112.7,104.3c4.4,3.7,8.6,7.6,12.7,11.2c4.1,3.6,8.1,7,11.7,9.8c3.6,2.7,6.9,4.7,9.5,5.9c2.5,1.1,4.2,1.4,4.7,0.7
					c0.5-0.7-0.2-2.2-1.7-4.4c-1.5-2.2-3.9-5-6.9-8.3c-3-3.3-6.6-7.1-10.7-10.9c-4.2-3.8-8.9-7.7-14.2-11c-5.4-3.3-10.4-5.7-15-7.3
					c-4.6-1.6-9-2.6-13.4-3c-1.1-0.1-2.2-0.1-3.3-0.2c-1.1,0-2.2,0-3.4,0c-2.3,0.1-4.6,0.3-7,0.8c-4.8,0.9-10.1,2.7-15.2,6.4
					c5.4-3.3,10.7-4.4,15.4-4.7c2.3-0.1,4.6,0,6.7,0.2c1.1,0.1,2.1,0.3,3.1,0.5c1,0.2,2,0.4,3,0.7c3.9,1.1,7.6,2.7,11.5,4.9
					C104.2,97.6,108.2,100.5,112.7,104.3z"
                  />
                </g>
                <g>
                  <path
                    d="M105.7,121.9c4.9,3.2,10.2,5.7,15.3,7.5c5.2,1.8,10.2,2.9,14.5,3.4c8.7,1,14.6-0.3,14.7-2c0.1-1.8-5.2-3.6-12.9-6.2
					c-7.7-2.6-17.7-6.1-27.5-10.4c-5.1-2.2-9.2-4.4-12.9-6.6c-3.7-2.2-7-4.4-10.5-6.5C83,99,79.4,97,75.1,95.4c-2.1-0.8-4.5-1.5-7-2
					c-2.5-0.5-5.3-0.6-8.3-0.2c3-0.1,5.7,0.3,8.1,1c2.4,0.7,4.5,1.7,6.5,2.7c3.9,2.1,6.9,4.6,9.8,7.2c2.9,2.6,5.7,5.4,9.1,8.4
					C96.7,115.5,100.6,118.7,105.7,121.9z"
                  />
                </g>
                <g>
                  <path
                    d="M117.9,121c5,3.9,10.8,6.6,15.4,7.8c4.7,1.2,8.1,1,8.8-0.6c0.7-1.6-1.5-4.2-5.3-7c-3.7-2.7-9-5.6-14.7-7.8
					c-5.8-2.3-9.9-3.9-13.9-5.7c-4-1.8-8-3.8-13.6-7.4c4.9,4.4,8.2,7.5,11.5,10.5C109.5,113.9,112.7,117,117.9,121z"
                  />
                </g>
                <g>
                  <path
                    d="M119.5,117c3.7,3.2,8.1,5.3,11.7,6.2c3.6,0.9,6.5,0.5,7.2-1.1c0.8-1.6-0.7-4-3.4-6.4c-2.7-2.4-6.7-4.7-11.1-6.3
					c-4.6-1.6-7.8-2.7-11-3.9c-3.2-1.2-6.4-2.6-10.8-5.1c3.8,3.4,6.2,5.8,8.7,8.3C113.2,111.2,115.6,113.7,119.5,117z"
                  />
                </g>
              </g>
              <g>
                <path
                  d="M46.9,120.2c-4.8-3.3-10.4-5.5-16.3-6.1c-2.9-0.3-5.9-0.2-8.8,0.3c-2.9,0.5-5.8,1.4-8.4,2.9c-2.6,1.5-5.1,3.5-6.9,6.1
				c-0.5,0.7-0.9,1.3-1.2,2c-0.3,0.7-0.7,1.5-0.9,2.2L4,128.7l-0.2,1.1c-0.2,0.8-0.2,1.5-0.3,2.3c-0.1,3,0.5,5.8,1.6,8.3
				c1.1,2.5,2.6,4.6,4.5,6.3c1.8,1.7,4,3,6.2,3.6c2.1,0.7,4.2,1,6.1,0.9c1.9-0.1,3.6-0.4,5.2-0.9c1.5-0.5,2.8-1.1,3.9-1.8
				c2.2-1.3,3.6-2.9,4.3-4c0.7-1.2,0.8-2,0.5-2.2c-0.3-0.3-1.1-0.1-2.2,0.3c-1.1,0.4-2.6,1.1-4.6,1.5c-1,0.2-2,0.4-3.2,0.5
				c-1.1,0.1-2.4,0-3.6-0.2c-1.2-0.2-2.5-0.6-3.6-1.2c-1.2-0.6-2.1-1.4-3-2.4c-1.8-2.1-2.9-5.2-2.6-8.1c0.1-0.3,0.1-0.7,0.2-1
				l0.1-0.5l0.2-0.5c0.1-0.3,0.3-0.6,0.4-0.9c0.2-0.3,0.4-0.6,0.6-0.9c0.9-1.2,2.2-2.2,3.7-3.1c1.6-0.9,3.4-1.5,5.4-1.8
				c2-0.4,4.1-0.5,6.2-0.3c4.2,0.3,8.6,1.6,12.4,3.9c4,2.3,7.2,5.4,9.7,8.7c2.5,3.3,4.3,6.9,5.7,10.4c2.6,7.1,3.3,14.2,2.9,21.3
				c-0.4,7.1-1.8,14.3-4.7,22c-3,7.6-7.4,15.8-14.9,23.8c7.8-7.7,12.8-15.6,16.5-23.2c3.6-7.6,5.7-14.8,6.9-22.3
				c0.6-3.7,0.9-7.5,0.8-11.4c-0.1-3.9-0.5-8-1.6-12.1c-1.1-4.2-2.8-8.5-5.4-12.7C55.4,127.8,51.9,123.6,46.9,120.2z"
                />
              </g>
              <g>
                <path
                  d="M34,153.7c-3,0.6-5.5,2.1-7.5,4c-2,1.9-3.5,4.3-4.3,7c-0.4,1.3-0.6,2.7-0.6,4.1c0,1.4,0.2,2.8,0.7,4.1
				c0.8,2.5,2.4,4.7,4.4,6.1c1,0.7,2,1.2,3.1,1.5c1.1,0.3,2.1,0.4,3,0.4c1.9-0.1,3.4-0.7,4.4-1.5c1-0.8,1.6-1.6,1.8-2.3
				c0.2-0.7,0-1.2-0.3-1.5c-0.7-0.6-1.9-0.4-3.2-0.7c-0.7-0.1-1.4-0.4-2-0.7c-0.3-0.2-0.6-0.4-0.8-0.6c-0.2-0.2-0.4-0.5-0.6-0.8
				c-0.4-0.6-0.7-1.4-0.8-2.3c-0.1-0.4-0.1-0.9,0-1.3c0-0.5,0.2-1,0.3-1.5c0.6-2.1,2.6-4.5,4.7-5.5c1.1-0.5,2.1-0.7,3.3-0.5
				c1.2,0.2,2.3,0.8,3.3,1.6c1,0.8,1.8,1.7,2.5,2.8c0.7,1.1,1.2,2.3,1.6,3.6c0.8,2.6,1,5.5,0.9,8.8c-0.1,3.3-0.7,7-0.7,11.4
				c0.6-4.4,1.7-7.9,2.4-11.2c0.8-3.3,1.3-6.4,1.2-9.6c-0.1-1.6-0.3-3.3-0.8-4.9c-0.5-1.7-1.3-3.4-2.5-5c-1.2-1.6-2.8-3.2-5.1-4.3
				c-1.1-0.6-2.4-1-3.8-1.2C37.1,153.3,35.5,153.4,34,153.7z"
                />
              </g>
              <g>
                <path
                  d="M80.9,129c2-0.8,3.3-2.1,4-3.7c0.7-1.6,0.9-3.4,0.1-5c-0.8-1.5-2.7-2.3-4.8-1.9c-2.1,0.4-4.4,2-5.6,4.5
				c-1.2,2.6-1.6,4.5-1.9,6.4c-0.3,1.9-0.7,3.6-1.1,6.1c1.5-2,2.9-3.1,4.3-4C77.3,130.6,78.8,129.9,80.9,129z"
                />
              </g>
              <g>
                <path
                  d="M87.6,140.1c1.9,0,3.2-0.8,4.1-2.1c0.9-1.2,1.4-2.9,1-4.7c-0.4-1.7-1.8-3-3.7-3.3c-1.9-0.3-4.2,0.4-5.7,2.3
				c-1.5,2-2.3,3.5-3,5c-0.7,1.5-1.3,2.9-2.3,4.8c1.8-1.2,3.2-1.6,4.7-1.9C84.1,140.2,85.6,140.1,87.6,140.1z"
                />
              </g>
              <g>
                <path
                  d="M69.7,113.9c0.7-3.1,0.1-6.2-1.2-8.3c-1.3-2.1-3.2-3.1-4.9-2.7c-1.7,0.5-2.8,2.2-3.3,4.3c-0.5,2.1-0.3,4.6,0.7,7.2
				c1.1,2.6,1.8,4.6,2.5,6.6c0.6,2,1,4.1,1,7.1c1.1-2.9,1.9-4.9,2.8-6.9C68.1,119.2,69,117.1,69.7,113.9z"
                />
              </g>
              <g>
                <path d="M51.6,99.8c-0.9-5.8-6-9.3-9.1-7.5c-3,1.8-2.7,7.7,1.3,11.5c4.1,4,6.3,5.3,9.7,10.1C52.1,108.1,52.6,105.7,51.6,99.8z" />
              </g>
              <g>
                <path
                  d="M25.9,96.2c-2.9-2.2-6.5-3.1-9.2-2.9c-2.8,0.2-4.8,1.4-5.1,3.1c-0.3,1.7,0.9,3.6,3,5c2,1.5,4.9,2.6,8.1,2.9
				c3.3,0.4,5.7,0.6,8.1,1.1c2.4,0.5,4.7,1.1,7.9,2.7c-2.6-2.5-4.3-4.2-6.1-6C30.7,100.2,28.9,98.4,25.9,96.2z"
                />
              </g>
              <g>
                <path
                  d="M-1.6,103.7c-2.7-0.3-5.2,0.9-6.5,2.5c-1.4,1.7-1.5,3.6-0.6,5.1c1,1.5,2.6,2.2,4.2,2.4c1.6,0.2,3.2-0.2,4.8-1.5
				c1.6-1.3,3-2.3,4.4-3c1.4-0.8,3-1.3,5.4-1.4c-2.1-0.9-3.7-1.6-5.5-2.4C3,104.7,1.2,104-1.6,103.7z"
                />
              </g>
              <g>
                <path
                  d="M-13.1,124.3c-2.5,1.7-3.7,4.6-3.5,6.9c0.2,2.4,1.6,3.9,3.3,4.1c1.8,0.2,3.4-0.6,4.6-1.5c1.3-1,2.1-2.2,2.5-4.1
				c0.4-2,0.9-3.5,1.7-5c0.8-1.4,1.9-2.8,4.1-4c-2.5,0.2-4.3,0.5-6.2,0.9C-8.6,122.1-10.6,122.6-13.1,124.3z"
                />
              </g>
              <g>
                <path
                  d="M-9.6,149.9c-0.9,2.6-0.3,5.4,1.1,7.1c1.4,1.7,3.3,2.2,4.9,1.5c1.6-0.7,2.7-2.2,3.2-3.7c0.5-1.5,0.5-3.1-0.4-4.8
				c-0.9-1.8-1.5-3.3-1.9-4.9c-0.3-1.6-0.5-3.2,0.1-5.5c-1.6,1.8-2.7,3.2-3.8,4.6C-7.5,145.6-8.6,147.2-9.6,149.9z"
                />
              </g>
              <g>
                <path
                  d="M3.1,168.8c0.4,2.9,2.4,5.2,4.4,6.2c2.1,1,4.1,0.7,5.2-0.6c1.2-1.3,1.4-3.2,1.1-4.9c-0.3-1.7-1.2-3.3-2.9-4.7
				c-1.7-1.4-3-2.6-4.1-3.9c-1.1-1.3-2-2.9-2.7-5.4c-0.4,2.6-0.7,4.4-1,6.4C2.9,163.8,2.7,165.8,3.1,168.8z"
                />
              </g>
              <g>
                <path
                  d="M75.4,229.7c2.3,1.4,4,3.2,4.8,5.2c0.9,2,0.9,4.3,0.3,5.8c-0.6,1.5-2,2.8-3.6,3.4c-0.8,0.3-1.5,0.4-2.3,0.4
				c-0.8,0-1.6-0.2-2.3-0.6c-1.5-0.6-2.7-1.8-3.4-2.9c-0.7-1.1-0.9-2.1-0.8-2.9c0.1-0.8,0.6-1.6,1.1-2c0.4-0.5,0.9-0.8,1-1.2
				c0.1-0.4-0.3-1-1.5-1.3c-1.1-0.2-3,0.1-4.7,1.8c-0.4,0.4-0.8,1-1.1,1.6c-0.3,0.6-0.5,1.2-0.7,1.9c-0.3,1.4-0.3,2.8,0.1,4.3
				c0.7,2.9,2.5,5.8,5.5,7.9c1.5,1,3.3,1.8,5.3,2.3c2,0.4,4.2,0.4,6.3-0.2c2.1-0.5,4.1-1.5,5.9-3c1.8-1.4,3.3-3.4,4.2-5.7
				c0.9-2.3,1.3-4.7,1.1-7c-0.1-2.3-0.8-4.6-1.8-6.7c-1-2.1-2.5-4-4.2-5.5c-1.7-1.5-3.6-2.6-5.6-3.4c-4.1-1.7-8.1-2-11.5-1.8
				c-3.5,0.2-6.5,0.9-9.3,1.7c-5.5,1.7-10.2,3.7-14.6,6.1c-4.5,2.5-8.8,5.2-13,9c-2.1,1.9-4.3,4-6.3,6.4c-2.1,2.5-4.1,5.2-5.8,8.5
				c2-3.1,4.2-5.7,6.4-7.9c2.2-2.2,4.5-4.1,6.8-5.6c4.5-3.2,9-5.2,13.5-6.9c4.5-1.7,9.2-2.8,14.4-3.6c2.6-0.3,5.2-0.5,7.9-0.3
				c1.3,0.1,2.7,0.3,4,0.7C72.9,228.5,74.2,229,75.4,229.7z"
                />
              </g>
              <g>
                <path
                  d="M55.5,215c4.5-2.5,8.1-6.3,10.1-9.7c2-3.4,2.4-6.3,1-7.5c-1.4-1.1-4.1-0.2-7.1,1.7c-3.1,2-6.5,5-9.6,8.6
				c-3.2,3.8-5.4,6.3-7.9,8.8c-2.5,2.5-5.2,4.8-9.6,7.6c4.8-1.8,8.2-3.1,11.7-4.4C47.5,218.9,50.9,217.5,55.5,215z"
                />
              </g>
              <g>
                <path
                  d="M75.4,216.2c2.3,0.5,4.3,0.1,5.9-0.8c1.6-1,2.7-2.5,2.7-4.3c0-1.8-1.2-3.3-3.1-4.1c-1.9-0.8-4.5-0.8-6.8,0.5
				c-2.4,1.3-3.8,2.5-5.3,3.6c-1.5,1.1-2.9,2.2-5.1,3.5c2.5-0.3,4.3-0.1,6,0.2C71.3,215.2,73,215.7,75.4,216.2z"
                />
              </g>
              <g>
                <path
                  d="M35.3,208.6c0.9-2.4,1.3-5,1.3-7.4c0-0.6,0-1.2-0.1-1.8c-0.1-0.6-0.1-1.1-0.2-1.7c-0.2-1.2-0.6-2.3-1.1-3.4
				c-0.5-1.1-1.1-2-1.9-2.8c-0.7-0.8-1.6-1.3-2.3-1.6c-1.6-0.5-2.6,0-3,0.7c-0.4,0.8-0.3,1.8-0.5,2.6c-0.1,0.9-0.3,1.8-0.4,3
				c-0.1,0.7-0.1,1.4-0.2,2.1c0,0.4-0.1,0.9-0.1,1.3c0,0.5,0,0.9-0.1,1.4c-0.1,1.9-0.1,3.9,0,6c0.1,4.3-0.2,7.5-0.8,10.6
				c-0.7,3.1-1.8,6.3-4.7,10.3c3.7-3.3,5.9-5.9,8.1-8.7c1.1-1.4,2.1-2.8,3.1-4.5C33.4,213,34.4,211.1,35.3,208.6z"
                />
              </g>
              <g>
                <path
                  d="M124.7,53.8c3.3-0.9,6.7-1.7,9-1.4c1.2,0.1,2,0.4,2.7,0.9c0.6,0.4,1.2,1.1,1.6,2c0.9,1.8,1,4.3,0.5,6.2
				c-0.1,0.5-0.3,1-0.5,1.5c-0.2,0.5-0.5,0.9-0.7,1.4c-0.5,0.8-1.2,1.5-1.8,2.1c-1.2,1-2.9,1.5-4.2,1.4c-0.7,0-1.3-0.2-1.8-0.4
				c-0.5-0.3-1-0.6-1.4-1c-1.7-1.6-1.8-3.6-2.6-3.9c-0.4-0.1-1,0.2-1.5,1.2c-0.5,1-0.8,2.8-0.1,4.9c0.3,1,0.9,2.2,1.9,3.2
				c1,1.1,2.2,2,3.8,2.6c3,1.2,6.9,1.2,10.4-0.7c1.7-0.9,3.3-2.2,4.6-3.7c0.7-0.8,1.2-1.6,1.8-2.5c0.5-0.9,1-1.8,1.4-2.9
				c0.8-2.1,1.1-4.3,1.1-6.5c-0.1-2.3-0.5-4.6-1.5-6.9c-1.1-2.3-2.8-4.5-5.2-6c-2.4-1.5-5.1-2.2-7.6-2.3c-2.5-0.1-4.7,0.3-6.8,0.9
				c-2.1,0.6-3.9,1.2-5.7,2c-3.8,1.6-7.6,3.8-10.3,7c-1.3,1.6-2.3,3.5-2.9,5.3c-0.6,1.8-0.9,3.6-0.9,5.3c-0.1,3.4,0.5,6.4,1.3,9.2
				c0.9,2.8,2,5.4,3.4,7.8c2.8,4.8,6.6,9.1,11.7,12.4c2.6,1.7,5.5,3.1,8.7,4.2c3.3,1.1,6.9,1.7,10.9,1.8c-3.9-0.3-7.5-1.3-10.6-2.6
				c-3.1-1.3-5.7-3-8-4.8c-4.5-3.7-7.5-8.1-9.4-12.7c-1-2.3-1.6-4.7-2-7.2c-0.4-2.5-0.5-5,0-7.5c0.2-1.2,0.6-2.4,1.1-3.4
				c0.5-1,1.2-2,2.1-2.8C118.7,56,121.3,54.8,124.7,53.8z"
                />
              </g>
              <g>
                <path
                  d="M86,72c-3-1.1-6.3-0.6-8.4,0.7c-2.1,1.3-3.1,3.2-2.6,4.9c0.5,1.7,2.2,2.8,4,3.3c1.9,0.6,4,0.6,6.4-0.2
				c2.5-0.8,4.3-1.2,6.3-1.4c1.9-0.2,4-0.2,6.8,0.7c-2.3-1.8-4-3.1-5.7-4.3C90.9,74.4,89.1,73.1,86,72z"
                />
              </g>
              <g>
                <path
                  d="M99.5,62.1c0.8-1.3,0.8-2.3,0.3-3.6c-0.5-1.2-1.3-2.8-2.7-3.8c-0.7-0.5-1.6-0.7-2.5-0.6c-0.9,0.1-1.8,0.5-2.6,1.3
				c-0.8,0.8-1.4,1.9-1.7,3.2c-0.3,1.3-0.2,2.8,0.4,4.1c1.2,2.8,2.7,4.1,4.1,5.3c1.4,1.1,2.9,2,4.9,2.9c-1.3-1.8-1.6-3.3-1.5-4.7
				C98.2,64.8,98.7,63.5,99.5,62.1z"
                />
              </g>
              <g>
                <path
                  d="M152.6,18.3c3.1-2.4,6-4.9,8.2-6.9c2.2-2,3.6-3.6,3.1-4.4c-0.4-0.8-2.6-0.5-5.5,1c-2.8,1.4-6.3,4-8.9,7.3
				c-2.7,3.4-4.2,5.9-5.6,8.5c-1.4,2.6-2.7,5.2-4.4,9.2c2.2-3.7,4-6,6-8.1C147.4,22.8,149.4,20.8,152.6,18.3z"
                />
              </g>
              <g>
                <path
                  d="M161.5,23.3c3.2-1.8,6.3-3.8,8.5-5.5c2.2-1.7,3.6-3.2,3.2-4c-0.4-0.8-2.5-0.7-5.2,0.3c-2.8,1-6.2,3-9.1,5.6
				c-2.9,2.7-4.7,4.8-6.5,6.9c-1.7,2.1-3.5,4.3-5.9,7.5c2.8-2.9,5-4.6,7.2-6.1C155.9,26.6,158.1,25.2,161.5,23.3z"
                />
              </g>
              <g>
                <path
                  d="M160,33.4c2.1-0.6,3.9-1.6,5.2-2.7c1.3-1,2-2.1,1.7-3c-0.3-0.8-1.6-1.2-3.4-0.9c-1.7,0.3-3.9,1.2-5.7,2.7
				c-1.8,1.6-2.9,2.8-4,4c-1.1,1.2-2.2,2.4-3.9,4.1c2-1.3,3.5-1.9,4.9-2.5C156.3,34.6,157.8,34.1,160,33.4z"
                />
              </g>
              <g>
                <path
                  d="M207.1,151.4c1.6-3.8,3.4-7.5,4.8-10.2c1.4-2.8,2.4-4.8,1.8-5.4c-0.6-0.6-2.8,0.4-5.2,3c-2.4,2.6-4.7,6.8-5.6,11.4
				c-0.8,4.7-0.7,8-0.3,11.2c0.4,3.2,1,6.4,2.6,10.8c-1.1-4.5-1.1-7.7-0.7-10.8C204.8,158.3,205.5,155.3,207.1,151.4z"
                />
              </g>
              <g>
                <path
                  d="M186.8,193.3c-0.7,3.1-1.2,5.4-1.8,8c-0.5,2.2-0.7,3.9,0,4.4c0.7,0.5,2.3-0.5,3.8-2.8c1.4-2.2,2.6-5.8,2.3-9.3
				c-0.2-3.6-1-6-1.8-8.2c-0.9-2.2-1.9-4.4-3.8-7.3c1.5,3.1,1.8,5.5,2,7.7C187.6,188,187.5,190.3,186.8,193.3z"
                />
              </g>
              <g>
                <path
                  d="M166,263.2c-0.7,3.5-2,6.9-3.1,9.4c-1.1,2.5-2,4.4-1.3,5c0.6,0.6,2.7-0.2,4.9-2.8c1.1-1.3,2.1-2.9,2.8-4.9
				c0.7-1.9,1.2-4.2,1.1-6.4c0-2.3-0.4-4.1-0.9-5.8c-0.5-1.6-1-3-1.7-4.3c-1.4-2.6-3-5.2-6.2-8.1c2.8,3.3,3.8,6,4.4,8.7
				c0.3,1.3,0.4,2.7,0.5,4.2C166.5,259.8,166.4,261.4,166,263.2z"
                />
              </g>
              <g>
                <path
                  d="M249.5,317.1c1.2-0.7,2.4-1.2,3.5-1.4c0.6-0.1,1.1-0.1,1.6-0.1c0.5,0,1,0.2,1.5,0.3c2,0.7,4,2.6,5.2,4.8
				c0.3,0.5,0.5,1.1,0.8,1.7c0.2,0.6,0.4,1.2,0.5,1.8c0.2,1.2,0.3,2.3,0.2,3.3c-0.2,1.9-1.3,3.4-2.5,4.1c-0.1,0.1-0.3,0.2-0.4,0.2
				c-0.1,0.1-0.3,0.1-0.4,0.2c-0.3,0.1-0.6,0.1-0.9,0.2c-0.7,0-1.3-0.1-1.8-0.4c-0.5-0.2-0.9-0.6-1.2-0.9c-0.3-0.4-0.5-0.9-0.5-1.4
				c-0.1-1.1,0.1-2,0.4-2.8c0.3-0.7,0.6-1.3,0.4-1.7c-0.1-0.4-0.8-0.7-2-0.3c-1.1,0.4-2.7,1.6-3.5,3.8c-0.4,1.1-0.6,2.5-0.3,4.1
				c0.3,1.6,1.1,3.2,2.4,4.5c1.3,1.3,3,2.2,4.9,2.7c1,0.2,2,0.3,3.1,0.3c0.5,0,1.1-0.1,1.7-0.2c0.5-0.1,1.1-0.3,1.6-0.4
				c4.2-1.5,7.4-5.3,8.5-10c0.5-2.3,0.6-4.6,0.2-6.8c-0.2-1.1-0.4-2.2-0.8-3.3c-0.4-1.1-0.8-2.2-1.4-3.2c-1.1-2.1-2.5-4-4.4-5.6
				c-1.8-1.6-4.1-3-6.7-3.8c-2.6-0.8-5.5-0.8-8.1-0.1c-2.6,0.7-4.8,2-6.7,3.5c-3.8,3.3-5.9,7.3-7.1,11c-1.1,3.8-1.4,7.4-1.1,10.7
				c0.2,3.3,1,6.4,1.9,9.1c1,2.8,2.1,5.4,3.5,7.8c1.3,2.5,2.7,4.9,4.3,7.3c1.5,2.4,3.1,4.8,4.9,7.4c3.6,5,7.8,10.5,13.5,16.3
				c-5.3-6.2-9-11.9-12.1-17.2c-1.5-2.7-2.8-5.2-4-7.8c-1.2-2.5-2.3-5-3.2-7.5c-0.9-2.5-1.7-5-2.2-7.6c-0.5-2.6-0.7-5.2-0.6-7.9
				c0.2-2.7,0.8-5.5,2-8.1c0.6-1.3,1.3-2.5,2.2-3.7C247.3,318.8,248.3,317.8,249.5,317.1z"
                />
              </g>
              <g>
                <path
                  d="M264.6,361c0.7-2.6,1-4.8,0.9-6.9c-0.1-2.1-0.6-4.1-2-5.2c-0.7-0.5-1.7-0.6-2.7-0.3c-1,0.3-2.1,1.1-3.1,2.3
				c-1,1.2-1.7,2.8-2.1,4.7c-0.4,1.9-0.3,3.9,0.3,5.9c0.6,2,1.4,3.6,2.2,4.9c0.8,1.3,1.7,2.3,2.6,3.3c1.8,1.9,3.7,3.6,6.7,5.5
				c-2.3-2.7-3-5-3.3-7.2C263.8,365.9,263.9,363.7,264.6,361z"
                />
              </g>
              <g>
                <path
                  d="M284.7,370c-3.2,6-5.2,12.1-7.6,17c-1.2,2.5-2.4,4.7-3.7,6.6c-0.7,0.9-1.4,1.8-2.1,2.6c-0.7,0.8-1.4,1.4-2.1,2
				c-1.4,1.1-2.5,1.5-3.5,1.7c-0.3,0.1-0.8,0-1.2-0.1c-0.5-0.2-0.9-0.4-1.2-0.7c-0.7-0.5-1.1-1.1-1.5-1.7c-0.3-0.6-0.5-1.1-0.9-1.3
				c-0.2-0.1-0.4-0.1-0.7,0c-0.3,0.1-0.6,0.4-0.9,0.8c-0.6,0.9-1,2.7-0.3,4.6c0.3,1,0.9,1.9,1.8,2.8c0.9,0.9,2.1,1.8,3.7,2.2
				c1.4,0.4,3,0.5,4.6,0.3c1.6-0.2,3.1-0.7,4.5-1.4c1.4-0.7,2.7-1.6,3.9-2.6c1.2-1,2.3-2.1,3.4-3.3c2.1-2.4,3.9-5.2,5.3-8.2
				c2.9-5.9,4.6-12.1,6.6-17.7c1.1-2.9,2.2-5.3,3.6-7.5c1.4-2.2,2.8-4.2,4.4-5.9c0.7-0.9,1.6-1.7,2.4-2.5c0.9-0.7,1.7-1.5,2.5-2.2
				c0.4-0.3,0.9-0.7,1.3-1c0.4-0.3,0.9-0.6,1.3-0.9c0.9-0.6,1.8-1.2,2.8-1.7c3.8-2.2,8-3.8,13-4.7c1.2-0.2,2.5-0.4,3.9-0.4
				c1.3-0.1,2.7-0.1,4.1,0.1c2.8,0.3,5.9,1.2,8.4,3.6c-2.3-2.6-5.4-3.8-8.3-4.4c-1.5-0.3-2.9-0.5-4.3-0.6c-1.4-0.1-2.7,0-4.1,0
				c-5.2,0.3-9.9,1.3-14.4,3c-1.1,0.4-2.2,0.9-3.3,1.4c-0.5,0.3-1.1,0.5-1.6,0.8c-0.5,0.3-1.1,0.6-1.6,0.9c-1.1,0.6-2.1,1.3-3.2,2
				c-1,0.8-2.1,1.5-3.2,2.3c-2.1,1.7-4.2,3.7-6.2,6C288.2,364.1,286.3,366.9,284.7,370z"
                />
              </g>
              <g>
                <path
                  d="M311.3,353.5c-2.3,1-4.3,2.4-5.9,4.2c-1.6,1.7-2.9,3.8-3.4,5.9c-0.6,2.1-0.3,4.2,0.4,5.5c0.7,1.3,1.7,1.7,2.5,1.5
				c0.9-0.2,1.6-0.8,2.3-1.4c0.7-0.6,1.5-1.2,2.3-1.9c0.9-0.7,1.8-1.6,3-2.7c1.2-1.1,2.4-2.4,3.6-3.7c2.6-2.8,4.9-4.5,7.5-5.8
				c1.3-0.6,2.7-1.3,4.3-1.7c1.6-0.4,3.5-0.7,5.7-0.7c-2.2-0.6-4.1-0.9-5.9-1.1c-1.8-0.2-3.4-0.3-5-0.3c-1.6,0-3.3,0.1-5.1,0.4
				C315.7,352,313.7,352.5,311.3,353.5z"
                />
              </g>
              <g>
                <path
                  d="M333.5,332.9c0.1-2-0.4-3.5-1.3-5c-0.9-1.4-2.2-2.8-3.9-3.1c-0.9-0.2-1.7,0-2.5,0.5c-0.8,0.5-1.5,1.4-1.9,2.6
				c-0.4,1.2-0.6,2.6-0.4,4.2c0.3,1.5,0.9,3.1,2,4.3c2.2,2.6,4.3,3.7,6.2,4.6c1.9,0.9,3.9,1.5,6.5,2.1c-2.2-1.6-3.2-3.2-3.8-4.8
				C333.7,336.7,333.4,335,333.5,332.9z"
                />
              </g>
              <g>
                <path
                  d="M523.9,354.8c0.8-4,0.7-8.3-0.4-12.2c-1-3.9-2.9-7.6-5.4-10.8c-2.5-3.2-5.7-5.8-9.2-7.6c-3.5-1.8-7.3-3-11-3.4
				c-3.8-0.4-7.6-0.2-11.2,0.8c-3.6,0.9-7.2,2.6-10.1,5.5c-1.4,1.4-2.6,3.2-3.4,5c-0.8,1.8-1.3,3.7-1.4,5.5
				c-0.3,3.7,0.5,7.3,2.3,10.3c1.8,3,4.4,5.2,7.1,6.5c2.7,1.3,5.5,1.8,8.1,1.8c2.6-0.1,5-0.8,7-1.8c2-1,3.5-2.4,4.6-3.7
				c2.3-2.7,3.1-5.3,3.4-7.1c0.2-1.8-0.1-2.8-0.5-2.8c-0.5-0.1-1,0.7-1.9,1.9c-0.9,1.2-2.2,3-4.3,4.5c-1,0.7-2.3,1.4-3.7,1.8
				c-1.4,0.4-2.9,0.6-4.5,0.4c-1.6-0.2-3.2-0.7-4.7-1.7c-1.5-0.9-2.7-2.2-3.4-3.7c-0.7-1.5-1-3.5-0.7-5.3c0.1-0.9,0.4-1.8,0.8-2.6
				c0.4-0.8,0.9-1.4,1.6-2.1c2.8-2.6,8.4-3.9,13.5-3.2c5.3,0.6,10.4,3.1,13.9,7.3c3.5,4.2,5.4,9.9,4.7,15.4
				c-0.8,5.7-4.1,10.7-8.1,14.1c-4.1,3.4-9,5.5-13.8,6.4c-4.9,1-9.8,1-14.5,0.4c-4.7-0.6-9.3-1.9-13.8-3.5
				c-4.5-1.6-8.9-3.6-13.5-5.8c-4.5-2.2-9.2-4.6-14.3-6.8c-5.1-2.2-10.8-4.1-17.1-4.8c-3.1-0.3-6.4-0.3-9.8,0.1
				c-1.7,0.3-3.4,0.7-5,1.2c-1.7,0.6-3.3,1.3-4.9,2.2c1.6-0.8,3.3-1.5,5-2c1.7-0.5,3.4-0.8,5-1c3.3-0.3,6.6-0.2,9.6,0.3
				c6.2,0.9,11.5,3.2,16.4,5.6c4.9,2.4,9.4,5.1,13.8,7.7c4.4,2.5,8.8,4.9,13.3,7c4.6,2,9.3,3.8,14.4,4.8c5.1,1.1,10.6,1.5,16.4,0.8
				c5.7-0.7,11.9-2.6,17.5-6.7c2.7-2,5.3-4.5,7.5-7.6C521.4,362.6,523.1,358.9,523.9,354.8z"
                />
              </g>
              <g>
                <path
                  d="M447.8,308.1c-3.2-3.2-7.6-5.5-12.3-6.1c-4.7-0.7-9.8,0.3-13.9,3.4c-2,1.5-3.5,3.4-4.6,5.6c-1.1,2.1-1.7,4.5-1.7,7
				c0,0.6,0.1,1.2,0.1,1.8c0.1,0.6,0.2,1.2,0.4,1.8c0.3,1.2,0.9,2.2,1.4,3.2c1.2,1.9,2.7,3.4,4.5,4.5c1.8,1.1,3.7,1.7,5.7,1.8
				c1.9,0.1,3.9-0.4,5.4-1.3c1.5-0.9,2.6-2,3.3-3.1c0.8-1.1,1.2-2.3,1.5-3.3c0.5-2.1,0-3.9-0.6-4.9c-0.3-0.5-0.6-0.8-0.9-1
				c-0.3-0.2-0.5-0.2-0.7-0.1c-0.2,0.1-0.4,0.3-0.5,0.6c-0.1,0.3-0.2,0.6-0.4,1c-0.3,0.8-0.6,1.7-1.4,2.6c-0.7,0.9-1.9,1.8-3.1,2
				c-1.1,0.2-2.3-0.1-3.5-1.2c-0.6-0.5-1.1-1.2-1.4-2c-0.1-0.4-0.3-0.7-0.3-1.1c0-0.2,0-0.3,0-0.5c0-0.2,0-0.4,0-0.6
				c0.1-1.6,1.2-3.6,2.9-4.8c1.6-1.2,4-1.9,6.7-1.6c2.7,0.2,5.3,1.3,7.7,3.1c2.4,1.8,4.2,4.3,5.3,6.8c1.1,2.5,1.5,5.1,1.3,7.6
				c-0.3,5-2.7,9.6-6.2,12.9c-3.5,3.3-8.6,5.3-14.4,5.7c-5.9,0.5-12.6-0.9-20.1-3.5c7.3,3.1,13.9,5,20.1,5.2
				c6.2,0.2,12-1.1,16.8-4.5c2.4-1.7,4.5-3.8,6.2-6.3c1.7-2.5,3.1-5.4,3.7-8.8C456.1,323.3,454.5,314.8,447.8,308.1z"
                />
              </g>
              <g>
                <path
                  d="M458.9,328.3c-2.4,2.5-3.5,5.9-3.3,8.5c0.2,2.7,1.6,4.5,3.3,4.7c1.8,0.2,3.4-0.9,4.8-2.5c1.3-1.6,2.3-3.7,2.8-6.3
				c0.5-2.7,1-4.7,1.8-6.7c0.8-1.9,1.8-3.8,4.1-6.1c-2.9,1.3-4.9,2.4-6.9,3.5C463.4,324.6,461.4,325.8,458.9,328.3z"
                />
              </g>
              <g>
                <path
                  d="M463.1,362.7c0.5,3.2,2.7,5.9,5,6.9c2.3,1.1,4.4,0.7,5.5-0.7c1-1.4,1-3.4,0.6-5.1c-0.5-1.8-1.4-3.3-3.1-4.8
				c-1.7-1.6-3-3-3.9-4.5c-1-1.6-1.8-3.4-1.9-6.2c-0.9,2.7-1.4,4.7-1.9,6.8C462.9,357.2,462.6,359.4,463.1,362.7z"
                />
              </g>
              <g>
                <path
                  d="M490.6,369c1.7,0.6,3.5,0.7,5.2,0.5c1.7-0.3,3.1-0.9,4.2-1.7c2.3-1.7,2.9-3.9,2-5.4c-0.9-1.5-2.7-2.2-4.6-2.6
				c-1.9-0.3-3.8-0.2-6.1,0.5c-2.3,0.7-4.3,0.9-6.3,0.8c-2-0.2-4-0.7-6.5-2.5c1.7,2.6,3.2,4.3,4.9,5.9
				C485.2,366,487.1,367.7,490.6,369z"
                />
              </g>
              <g>
                <path
                  d="M448.5,349.2c-1.6-0.6-2.7-0.5-4,0.1c-1.3,0.6-2.7,1.6-3.5,3.2c-0.4,0.8-0.5,1.7-0.2,2.5c0.2,0.9,0.8,1.7,1.7,2.4
				c0.9,0.7,2.1,1.2,3.5,1.3c1.3,0.1,2.8-0.1,4.1-0.8c2.7-1.5,3.9-3.1,5-4.5c1.1-1.5,1.9-3.1,2.9-5.2c-1.8,1.5-3.4,1.9-4.9,1.9
				C451.6,350.1,450.2,349.9,448.5,349.2z"
                />
              </g>
              <g>
                <path
                  d="M441,389.9c-1.2,1.3-3,2.2-4.8,2.4c-1.7,0.3-3.6,0-5.4-0.8c-1.8-0.7-3.5-2-4.7-3.3c-1.2-1.3-1.9-2.6-2-3.5
				c-0.2-1,0.1-2.1,0.4-2.7c0.3-0.6,0.9-1,1.7-1.1c0.8-0.1,1.5,0,2.1,0.2c0.6,0.2,1.1,0.4,1.5,0.3c0.4-0.1,0.8-0.6,0.6-1.7
				c-0.2-1-1-2.4-2.7-3.4c-0.8-0.5-1.9-0.8-3.2-0.9c-1.3-0.1-2.7,0.3-4,1.1c-2.7,1.6-4.5,4.5-4.8,8c-0.3,3.6,1.1,7,3.1,9.7
				c2,2.7,4.7,4.9,8,6.4c3.3,1.5,7.3,2.2,11.4,1.2c3.9-0.9,7.6-3.3,9.9-6.8c0.6-0.9,1.1-1.9,1.4-2.9c0.4-1,0.6-2,0.7-2.9
				c0.3-1.9,0.2-3.7-0.1-5.3c-0.6-3.2-1.9-5.9-3.3-8.1c-1.5-2.2-3.2-4.1-5-5.6c-1.8-1.5-3.7-2.8-5.7-3.8c-4-2.1-8.4-3.3-13.2-3.8
				c-4.9-0.5-10.3-0.3-16.5,0.5c12.6-0.7,21.4,1.7,27.8,6.5c3.2,2.4,5.8,5.4,7.4,9.2c0.8,1.9,1.3,3.9,1.3,5.9
				C442.8,386.8,442.3,388.7,441,389.9z"
                />
              </g>
              <g>
                <path
                  d="M457.3,391.4c0,2.2,1.1,3.9,2.5,4.7c1.5,0.9,3.3,0.9,4.8,0c1.5-0.9,2.4-2.4,2.6-4c0.2-1.5-0.4-3.1-1.9-4.3
				c-1.5-1.2-2.7-2.1-3.9-3c-1.1-0.9-2.1-2-3.1-3.7c0,2-0.2,3.5-0.4,5C457.7,387.6,457.4,389.2,457.3,391.4z"
                />
              </g>
              <g>
                <path
                  d="M431.7,410.6c-2.8,1.3-4.6,3.9-5.2,6.2c-0.6,2.3,0.1,4.3,1.6,5.2c1.5,0.9,3.5,0.6,5.2-0.3c1.7-0.9,3.3-2.4,4.4-4.7
				c1.1-2.3,2-4,3.1-5.6c1.1-1.6,2.4-3.1,4.8-4.7c-2.7,0.6-4.7,1-6.8,1.5C436.6,408.7,434.5,409.2,431.7,410.6z"
                />
              </g>
              <g>
                <path
                  d="M389.6,226.7c1.7,0.3,3.4,0.1,5-0.1c1.5-0.3,2.9-0.8,4-1.3c2.2-1.1,3.4-2.3,3.2-3.1c-0.3-0.9-2-1.1-4.3-0.9
				c-2.3,0.2-5.1,0.7-7.8,1.1c-2.8,0.4-4.9,0.2-6.8-0.5c-1-0.3-1.9-0.8-2.9-1.6c-0.9-0.7-1.9-1.8-2.6-3.3c0.4,1.6,1.1,2.9,1.9,3.9
				c0.8,1,1.7,1.9,2.6,2.6c0.9,0.7,2,1.4,3.2,1.9C386.4,226,387.9,226.5,389.6,226.7z"
                />
              </g>
              <g>
                <path
                  d="M392.5,238.2c1.9,2.5,3.7,4.8,5.3,6.6c1.6,1.7,2.9,2.9,3.7,2.5c0.8-0.3,0.8-2.2-0.1-4.7c-1-2.4-3-5.4-5.8-7.5
				c-2.9-2.2-5-3.2-7.2-4.2c-2.2-1-4.4-1.9-7.8-2.8c3.1,1.5,5,2.9,6.7,4.4C389.1,234,390.6,235.6,392.5,238.2z"
                />
              </g>
              <g>
                <path
                  d="M351.7,217.7c-0.7,2.4-1.3,4.7-1.7,6.6c-0.4,1.9-0.5,3.4,0.2,3.9c0.7,0.5,2.2-0.2,3.6-2.1c1.4-1.8,2.4-4.8,2.3-7.9
				c-0.2-3.1-0.8-5.1-1.4-7.1c-0.7-1.9-1.5-3.8-3.1-6.3c1,2.7,1.2,4.7,1.2,6.6C352.7,213.3,352.5,215.2,351.7,217.7z"
                />
              </g>
              <g>
                <path
                  d="M446.3,284.3c3.7-0.6,7.2-2.4,9.4-4.3c2.2-1.9,3-3.7,2.5-4.4c-0.6-0.7-2.4-0.1-4.7,0.8c-2.3,1-5.3,2.3-8.3,3.6
				c-3.2,1.4-5.5,2-7.9,2.5c-2.4,0.4-5,0.7-8.7,0.1c3.6,1.1,6.2,1.5,8.8,1.8C439.8,284.8,442.4,284.9,446.3,284.3z"
                />
              </g>
              <g>
                <path
                  d="M455.1,296.1c2.5,0.2,5-0.7,6.5-1.9c1.5-1.2,2-2.6,1.4-3.3c-0.6-0.7-1.8-0.7-3.3-0.4c-1.5,0.2-3.2,0.7-5.1,1.3
				c-1.9,0.6-3.4,0.9-4.9,0.9c-1.5,0-3.1-0.1-5.3-1.1c1.9,1.4,3.3,2.3,4.9,3C450.8,295.2,452.5,295.8,455.1,296.1z"
                />
              </g>
              <g>
                <path
                  d="M512.7,278.9c-0.8,1.7-2.2,3.2-3.7,4.4c-1.5,1.2-3.3,2.3-4.8,3.4c-1.5,1.1-2.8,2.1-3.5,3c-0.8,0.9-1,1.6-0.8,2
				c0.3,0.4,1,0.3,2,0.1c1.1-0.2,2.5-0.7,4.2-1.4c1.7-0.7,3.8-1.6,5.9-3.2c2-1.6,4-3.9,4.8-6.9c0.4-1.5,0.4-3,0.2-4.4
				c-0.3-1.3-0.8-2.5-1.4-3.4c-1.2-1.9-2.8-3.1-4.4-4c-3.3-1.8-7-2.2-12.2-2.6c2.6,0.5,4.7,0.9,6.5,1.6c1.8,0.7,3.3,1.5,4.5,2.6
				c1.2,1.1,2.2,2.4,2.8,3.9C513.5,275.5,513.5,277.2,512.7,278.9z"
                />
              </g>
              <g>
                <path
                  d="M421,118.9c2.1-3.8,2.7-8.3,2.3-11.5c-0.4-3.3-1.6-5.2-2.4-5c-0.9,0.2-1.3,2.2-1.8,4.9c-0.5,2.8-1.2,6.3-2.2,9.9
				c-1.1,3.7-2.3,6.2-3.8,8.5c-1.5,2.3-3.4,4.6-6.9,6.9c3.8-1.9,6.2-3.7,8.4-5.7C416.7,125,418.8,122.8,421,118.9z"
                />
              </g>
              <g>
                <path
                  d="M423,162.8c5.4,0.3,10.9,0.4,15,0.3c4.1-0.1,6.9-0.6,7-1.5c0.1-0.9-2.6-2-6.7-2.7c-4.2-0.7-9.8-1.1-15.4-0.5
				c-5.7,0.6-9.6,1.3-13.5,2.1c-3.9,0.8-7.8,1.6-13.5,3c5.8-0.8,9.8-1,13.7-1.1C413.6,162.4,417.4,162.5,423,162.8z"
                />
              </g>
              <g>
                <path
                  d="M428.4,172.9c6.4,1.8,13.2,1.4,13.5-0.3c0.2-1.7-6.2-4-12.9-4c-6.9,0-9.5,0.3-16.5-0.1
				C419.4,169.9,421.8,171,428.4,172.9z"
                />
              </g>
              <g>
                <path
                  d="M465.2,185.4c3.3-1.4,6.4-3,8.7-4.4c2.3-1.4,3.8-2.7,3.5-3.5c-0.3-0.8-2.3-1-5.1-0.3c-2.8,0.7-6.3,2.2-9.3,4.5
				c-3.1,2.4-5,4.3-6.8,6.1c-1.8,1.9-3.7,3.9-6.2,6.8c2.9-2.6,5.1-4,7.4-5.3C459.5,188,461.8,186.9,465.2,185.4z"
                />
              </g>
              <g>
                <path
                  d="M479.4,189.5c3.5-0.2,7-0.5,9.6-1c2.6-0.4,4.5-1,4.6-1.9c0.1-0.9-1.7-1.8-4.6-2.3c-2.8-0.5-6.7-0.3-10.4,0.8
				c-3.7,1.2-6.2,2.3-8.5,3.5c-2.4,1.2-4.7,2.5-8,4.6c3.5-1.6,6.1-2.3,8.6-2.8C473.3,190.1,475.8,189.7,479.4,189.5z"
                />
              </g>
              <g>
                <path
                  d="M496,177.4c5.3-1.4,9.4-5.2,8.5-6.8c-0.9-1.5-6-0.2-10.3,2.8c-2.2,1.5-3.8,2.6-5.3,3.7c-1.6,1-3.2,2-5.8,3.2
				c2.7-0.7,4.6-1.1,6.5-1.5C491.4,178.5,493.3,178.1,496,177.4z"
                />
              </g>
              <g>
                <path
                  d="M508.6,181.3c2.3,0.5,4.6,0.6,6.3,0.4c1.8-0.2,3.1-0.7,3.3-1.5c0.2-0.9-0.9-1.9-2.7-2.6c-1.8-0.7-4.4-1.1-6.9-0.6
				c-2.6,0.4-4.3,1-6,1.5c-1.8,0.6-3.6,1.2-5.8,2c2.6-0.3,4.3-0.3,6-0.1C504.5,180.5,506.2,180.8,508.6,181.3z"
                />
              </g>
              <g>
                <path
                  d="M515.5,191.4c5.5,1.8,11.5,1.5,11.8-0.3c0.3-1.7-5.2-4-11-4.1c-6-0.1-8.3,0.3-14.4-0.2
				C507.8,188.4,509.8,189.5,515.5,191.4z"
                />
              </g>
              <g>
                <path
                  d="M537.8,184.7c2.8,0.1,5.6-0.6,7.4-1.6c1.9-1,2.9-2.2,2.6-3c-0.3-0.8-1.8-1.1-3.7-1c-2,0.1-4.4,0.5-6.9,1.3
				c-2.5,0.8-4.3,1.3-6.2,1.7c-1.9,0.4-3.8,0.6-6.6,0.6c2.8,0.6,4.7,1,6.6,1.3C533,184.3,535,184.6,537.8,184.7z"
                />
              </g>
              <g>
                <path
                  d="M537.5,176.3c2.8-0.1,5.5-1.1,7.4-2.2c1.8-1.1,2.8-2.4,2.4-3.2c-0.4-0.8-1.9-1-3.8-0.7c-2,0.2-4.4,0.9-6.9,1.9
				c-2.5,1-4.3,1.6-6.1,2.2c-1.8,0.5-3.7,1-6.6,1.2c2.9,0.3,4.8,0.5,6.8,0.7C532.6,176.2,534.6,176.4,537.5,176.3z"
                />
              </g>
              <g>
                <path
                  d="M535.8,166.5c2.7-0.6,5.2-1.8,6.8-3c1.7-1.3,2.5-2.6,2.1-3.4c-0.8-1.6-6.1-0.4-10.6,2.3c-2.3,1.4-4,2.4-5.6,3.4
				c-1.7,0.9-3.4,1.8-6.1,2.9c2.8-0.5,4.7-0.8,6.7-1C531.1,167.3,533,167.1,535.8,166.5z"
                />
              </g>
              <g>
                <path
                  d="M557.3,160.8c1.9-0.2,3.7-0.8,5-1.5c1.3-0.7,2.2-1.5,2.2-2.4c-0.1-0.9-1.2-1.6-2.9-1.7c-1.7-0.2-3.9,0.3-5.8,1.5
				c-1.9,1.2-3.1,2.3-4.2,3.4c-1.1,1.1-2.2,2.1-3.7,3.7c1.8-1.2,3.2-1.7,4.6-2.1C553.9,161.3,555.3,161,557.3,160.8z"
                />
              </g>
              <g>
                <path
                  d="M366.2,478.6c3.4-1,6.8-2.1,9.3-3.2c2.5-1.1,4.2-2.1,4-3c-0.1-0.9-2.1-1.3-5-1.1c-2.9,0.3-6.7,1.3-10,3.2
				c-3.4,2-5.6,3.6-7.7,5.3c-2.1,1.7-4.2,3.4-7,6.1c3.2-2.3,5.6-3.5,8-4.5C360.2,480.5,362.6,479.6,366.2,478.6z"
                />
              </g>
              <g>
                <path
                  d="M377.5,483.6c2.3,0.1,4.5-0.2,6.2-0.7c1.7-0.5,2.9-1.2,2.9-2.1c0-0.9-1.2-1.7-3.1-2.1c-1.9-0.4-4.4-0.3-6.8,0.6
				c-2.4,0.9-4,1.7-5.6,2.5c-1.7,0.9-3.3,1.8-5.3,2.9c2.4-0.7,4.2-1,5.9-1.1C373.5,483.5,375.1,483.5,377.5,483.6z"
                />
              </g>
              <g>
                <path
                  d="M400.9,489.4c2.5,0.8,5,1.3,7,1.3c2,0.1,3.5-0.3,3.7-1.1c0.3-0.8-0.8-2-2.7-2.9c-1.9-0.9-4.6-1.6-7.4-1.6
				c-2.9,0-4.8,0.3-6.8,0.5c-2.1,0.2-4.1,0.5-6.7,0.8c2.8,0.2,4.7,0.6,6.6,1.1C396.6,487.9,398.4,488.5,400.9,489.4z"
                />
              </g>
              <g>
                <path
                  d="M404,480.2c5.8,1.9,12.1,1.7,12.4,0c0.3-1.7-5.5-4.1-11.6-4.3c-6.3-0.2-8.7,0.1-15.2-0.5
				C395.8,477.1,398,478.2,404,480.2z"
                />
              </g>
              <g>
                <path
                  d="M405.2,471.3c1.9,0,3.8-0.8,4.9-1.8c1.1-1,1.6-2.1,1.2-2.9c-0.4-0.8-1.5-1.1-2.8-1c-1.3,0.1-2.9,0.5-4.3,1.4
				c-1.5,0.9-2.6,1.5-3.8,2c-1.2,0.5-2.4,0.9-4.3,1.2c1.7,0.3,3,0.5,4.5,0.7C401.8,471.2,403.2,471.4,405.2,471.3z"
                />
              </g>
              <g>
                <path
                  d="M326.4,598.7c3.6-0.2,7.2-0.8,10.9-1.8c3.6-1,7.3-2.7,10.5-5.2c1.6-1.3,3.1-2.8,4.3-4.4c1.2-1.7,2.2-3.5,3-5.4
				c1.5-3.8,2.1-7.8,1.7-11.8c-0.2-2-0.7-3.9-1.4-5.8c-0.7-1.9-1.7-3.6-2.8-5.1c-2.2-3.1-5.1-5.5-8.2-7.3c-1.6-0.8-3.2-1.5-4.9-2
				c-1.7-0.5-3.4-0.7-5.1-0.8c-3.4-0.1-6.7,0.5-9.7,1.8c-2.9,1.4-5.5,3.4-7.2,6.1c-1.7,2.6-2.4,5.5-2.4,8.1c0,2.7,0.8,5.3,2.2,7.2
				c0.7,1,1.5,1.8,2.4,2.5c0.9,0.6,1.8,1.1,2.7,1.4c3.6,1.3,6.6,0.5,8.1-0.6c1.6-1.1,1.9-2.1,1.6-2.4c-0.3-0.4-1.2-0.1-2.5,0
				c-0.7,0-1.5,0-2.4-0.2c-0.9-0.2-1.9-0.6-2.8-1.2c-0.9-0.6-1.7-1.5-2.1-2.5c-0.5-1.1-0.6-2.4-0.4-3.8c0.2-1.4,0.8-2.9,1.8-4.1
				c0.9-1.1,2.4-2.1,4.1-2.7c1.7-0.6,3.8-0.8,5.8-0.6c1,0.1,2,0.3,3,0.7c1,0.4,2,0.8,2.9,1.4c1.9,1.2,3.6,2.7,4.9,4.6
				c1.3,1.9,2,4,2.3,6.3c0.2,2.3-0.1,4.8-1,7.1c-0.4,1.1-1,2.2-1.7,3.2c-0.7,1-1.5,1.8-2.5,2.7c-3.9,3.3-9.9,5-16.1,5.8
				c-6.3,0.8-12.3,0.4-17.8-0.8c-5.5-1.2-10.3-3.2-14.8-5.6c-9-4.9-16.6-10.8-25.2-15.5c-8.6-4.7-18.1-7.9-29-9.2
				c-5.4-0.6-11.2-0.8-17.4-0.2c-6.1,0.6-12.7,2-19.4,4.5c6.8-2.2,13.3-3.3,19.4-3.6c6.1-0.3,11.8,0.2,17.1,1.1
				c10.5,1.9,19.4,5.7,27.3,10.8c3.9,2.6,7.7,5.5,11.5,8.5c3.9,3,7.9,6.1,12.5,9.1c4.6,3,9.9,5.7,16,7.5
				C312.1,598.2,319,599.2,326.4,598.7z"
                />
              </g>
              <g>
                <path
                  d="M279.9,561.6c4.2-1.3,8.3-3.6,11.6-6.8c1.7-1.6,3.1-3.5,4.3-5.6c1.2-2.1,2-4.4,2.3-7c0.7-5.2-1.5-10.8-6.2-13.8
				c-2.4-1.4-4.9-1.9-7.1-1.8c-2.2,0.1-4.1,0.5-6,1.3c-1.8,0.8-3.5,2-4.9,3.6c-1.3,1.6-2.1,3.7-2.1,5.7c0,3.9,2.4,6.6,4.7,7.6
				c2.3,1,4.2,0.5,5.3-0.1c1.1-0.6,1.3-1.3,1.2-1.6c-0.2-0.4-0.8-0.6-1.5-0.9c-0.7-0.3-1.7-0.8-2.4-1.6c-0.3-0.4-0.5-0.8-0.6-1.4
				c-0.1-0.5-0.1-1.1,0.1-1.5c0.2-0.4,0.5-0.7,1-1.1c0.5-0.3,1.2-0.7,2.1-0.9c0.9-0.2,2-0.3,2.9-0.2c1,0.1,1.7,0.4,2.2,0.8
				c0.5,0.4,0.9,1,1.2,1.8c0.2,0.4,0.3,0.9,0.3,1.3c0,0.4,0,0.9,0,1.5c-0.3,2.1-1.7,4.8-3.7,7c-2,2.2-4.7,4.1-7.8,5.4
				c-3.1,1.4-6.4,2.2-9.5,2.6c-3.2,0.4-6.2,0.5-9.2,0.4c-5.9-0.1-11.4-0.8-17-1.1c-5.6-0.3-11.4-0.3-17.6,0.8
				c-6.2,1.1-13,3.1-20.3,7c7.5-3.4,14.4-4.8,20.5-5.3c6.2-0.4,11.6,0.2,17,1.3c5.4,1,10.7,2.4,16.9,3.4c3.1,0.5,6.5,0.9,10.2,0.9
				C271.4,563.3,275.5,563,279.9,561.6z"
                />
              </g>
              <g>
                <path
                  d="M303.9,501.7c1.1-5.3,2.3-10.4,3.1-14.4c0.8-4.1,1.2-7.3-0.3-8.3c-1.4-1-4.6,0.8-7.4,4.9c-1.4,2.1-2.6,4.7-3.4,7.7
				c-0.8,3-1.1,6.3-0.8,9.6c0.3,3.4,0.9,6.2,1.7,8.6c0.7,2.4,1.6,4.5,2.6,6.5c1,2,2,4,3.3,6.1c1.3,2.1,2.8,4.3,4.9,6.8
				c-1.6-2.8-2.6-5.2-3.3-7.5c-0.7-2.3-1.1-4.3-1.3-6.4c-0.2-2.1-0.2-4.1-0.1-6.3C303,506.9,303.3,504.5,303.9,501.7z"
                />
              </g>
              <g>
                <path
                  d="M320.9,499.7c0.3-3.4-0.1-6.9-1.1-10c-0.9-3.1-2.4-5.8-4.1-7.9c-1.6-2.1-3.4-3.4-4.9-4.1c-1.5-0.7-2.6-0.7-3.3-0.1
				c-1.3,1.3-0.3,4.3,0.8,8.2c0.6,2,1.2,4.2,1.9,6.6c0.6,2.4,1.3,5,1.8,7.7c0.6,2.8,0.8,5.2,0.9,7.5c0.1,2.3,0,4.4-0.3,6.5
				c-0.5,4.2-1.6,8.5-4.5,14.4c3.8-5.3,6.1-9.2,8.1-13.3c1-2.1,1.9-4.2,2.7-6.7C319.9,506,320.6,503.2,320.9,499.7z"
                />
              </g>
              <g>
                <path
                  d="M312.1,497.4c0.7-3.6,0.6-7.3,0-10.1c-0.6-2.7-1.6-4.4-2.5-4.3c-0.9,0.1-1.4,1.9-1.7,4.6c-0.3,2.6-0.4,6-0.2,9.5
				c0.2,3.6,0.2,6.1,0.2,8.6c-0.1,2.5-0.3,5.1-1.2,8.8c1.3-3.6,2.2-6,3-8.5C310.5,503.6,311.3,501.1,312.1,497.4z"
                />
              </g>
              <g>
                <path
                  d="M310,493.7c0.9-3.1,1-6.3,0.6-8.7c-0.4-2.4-1.2-4-2.1-3.9c-0.9,0-1.6,1.6-2.1,3.8c-0.5,2.3-0.8,5.2-0.8,8.3
				c0,3.1,0,5.3-0.2,7.5c-0.2,2.2-0.4,4.4-1.2,7.6c1.2-2.9,2.1-5,3.1-7.2C308.2,499,309,496.9,310,493.7z"
                />
              </g>
              <g>
                <path
                  d="M302.6,488.7c1.5-2.6,2.9-5.1,3.9-7.2c1-2,1.6-3.6,1-4.3c-0.6-0.6-2.4,0-4.3,1.7c-1.9,1.8-3.9,4.8-4.7,8.1
				c-0.8,3.5-0.9,5.9-0.8,8.3c0.1,2.4,0.3,4.7,1,8.1c-0.2-3.4,0.2-5.7,0.8-7.9C300.3,493.4,301.1,491.4,302.6,488.7z"
                />
              </g>
              <g>
                <path
                  d="M305.8,559.5c1.5-1.7,2.7-3.7,3.5-5.7c0.8-2,1.3-4,1.4-5.7c0.3-3.4-0.5-5.7-1.4-5.7c-0.9,0-1.6,2-2.7,4.8
				c-1.1,2.8-2.5,6.3-4.4,9.7c-2,3.5-3.9,5.6-6.1,7.6c-2.2,1.9-4.8,3.6-9.1,4.9c4.4-0.8,7.4-1.9,10.1-3.3c1.4-0.7,2.7-1.5,4.2-2.5
				C302.7,562.6,304.2,561.3,305.8,559.5z"
                />
              </g>
              <g>
                <path
                  d="M341,541.1c4.9-0.6,9.7-1.1,13.4-1.5c3.7-0.4,6.3-0.9,6.4-1.8c0.1-0.8-2.4-2-6.4-2.4c-3.9-0.5-9.3-0.1-14.4,1.4
				c-5.2,1.6-8.5,3.3-11.7,5c-3.2,1.8-6.4,3.7-10.8,6.9c4.6-2.8,8.1-4.1,11.5-5.2C332.6,542.5,336,541.7,341,541.1z"
                />
              </g>
              <g>
                <path
                  d="M361.1,546c3.3,0.2,6.6,0,9.1-0.3c2.5-0.4,4.3-1,4.3-1.9c0.1-0.9-1.6-1.8-4.2-2.3c-2.6-0.5-6.1-0.6-9.6,0.1
				c-3.5,0.7-5.9,1.5-8.2,2.3c-2.5,0.8-4.8,1.6-8.1,2.8c3.6-0.7,6-0.8,8.5-0.9C355.2,545.7,357.6,545.8,361.1,546z"
                />
              </g>
              <g>
                <path
                  d="M368.5,552.6c2.6,0.8,5.2,1.2,7.2,1.2c2,0,3.6-0.3,3.8-1.2c0.2-0.8-0.9-2-2.8-2.9c-2-0.9-4.8-1.6-7.6-1.5
				c-2.9,0.1-4.9,0.4-6.9,0.7c-2.1,0.3-4.2,0.7-6.9,1.1c2.9,0.1,4.9,0.4,6.8,0.8C364,551.3,365.9,551.8,368.5,552.6z"
                />
              </g>
              <g>
                <path
                  d="M371.3,563.5c1.5,1.5,2.9,2.8,4,3.8c1.2,1,2.2,1.7,3.1,1.5c0.8-0.3,1.2-1.8,0.4-3.8c-0.7-2-2.7-4.1-5.1-5.1
				c-2.5-1.1-4.2-1.3-6-1.6c-1.7-0.3-3.4-0.5-5.9-1c2.3,1,3.8,1.8,5.2,2.7C368.4,561,369.8,562,371.3,563.5z"
                />
              </g>
              <g>
                <path
                  d="M370.1,580.9c0.2,2.4,0.6,4.7,1.1,6.5c0.5,1.8,1.1,3.1,2,3.2c0.9,0.1,1.8-1.2,2.2-3.3c0.4-2.1,0.2-5-1-7.6
				c-1.2-2.6-2.4-4.2-3.6-5.8c-1.2-1.5-2.5-3-4.4-5c1.5,2.3,2.2,4.1,2.7,5.8C369.5,576.6,369.8,578.4,370.1,580.9z"
                />
              </g>
              <g>
                <path
                  d="M364.6,598.8c-0.5,1.7-0.6,3.5-0.3,4.8c0.2,1.4,0.8,2.4,1.7,2.6c0.9,0.2,1.8-0.6,2.5-2c0.6-1.4,1-3.4,0.6-5.3
				c-0.4-2-0.9-3.3-1.3-4.7c-0.5-1.3-0.9-2.6-1.5-4.5c0.1,2-0.1,3.3-0.4,4.7C365.5,595.7,365.1,597,364.6,598.8z"
                />
              </g>
              <g>
                <path
                  d="M353.3,601c-3.3,3.3-5.1,7.7-3.7,8.8c1.4,1.1,5.4-1.9,7.3-6.4c1.9-4.6,2.2-6.5,4.2-11.1
				C358.2,596.4,356.7,597.5,353.3,601z"
                />
              </g>
              <g>
                <path
                  d="M340.2,602.7c-2.6,1.1-5,2.5-6.7,3.8c-1.7,1.3-2.7,2.6-2.3,3.4c0.4,0.8,2,0.9,4.2,0.3c2.2-0.6,4.8-1.9,7.1-3.8
				c2.3-2,3.8-3.5,5.2-5c1.5-1.5,2.9-3,5.1-5.1c-2.5,1.7-4.4,2.7-6.2,3.6C344.8,600.8,342.9,601.6,340.2,602.7z"
                />
              </g>
              <g>
                <path
                  d="M316.4,606.9c-2.8,0-5.6,0.4-7.6,1c-2.1,0.6-3.5,1.4-3.5,2.2c0,0.9,1.5,1.6,3.7,1.9c2.2,0.3,5.2,0.1,8.1-0.9
				c2.9-1,4.8-1.9,6.7-2.8c2-1,4-1.9,6.6-3.1c-3,0.8-5,1.1-7.1,1.3C321.3,606.8,319.3,606.9,316.4,606.9z"
                />
              </g>
              <g>
                <path
                  d="M233,569.1c5.4,0.9,10.6,2.2,14.6,3.2c4,1,6.8,1.7,7.2,0.8c0.4-0.7-1.8-2.8-5.8-4.8c-4-1.9-9.8-3.5-15.7-3.6
				c-6,0-10.1,0.7-14.1,1.6c-4,0.9-8,2.1-13.5,4.5c5.7-1.9,9.8-2.4,13.7-2.6C223.5,568,227.4,568.2,233,569.1z"
                />
              </g>
              <g>
                <path
                  d="M251.9,584.4c1.8,1.9,3.6,3.7,5.1,5.1c1.5,1.3,2.8,2.2,3.6,1.8c0.8-0.4,0.8-2.1-0.2-4.2c-1-2.1-3.1-4.6-5.8-6.1
				c-2.8-1.5-4.8-2.2-6.9-2.7c-2.1-0.5-4.1-0.9-7.1-1.1c2.9,0.8,4.7,1.8,6.4,2.9C248.6,581.1,250.1,582.4,251.9,584.4z"
                />
              </g>
              <g>
                <path
                  d="M68,396c1.6-0.2,3.2-0.3,4.9-0.5c1.6-0.1,3.3-0.2,4.8-0.2c3.1,0,6.1,0.2,8.8,0.7c2.7,0.5,5.1,1.4,6.9,2.6
				c0.9,0.6,1.7,1.2,2.3,1.9c0.3,0.3,0.6,0.7,0.9,1.1c0.2,0.4,0.4,0.7,0.6,1.1c0.7,1.6,1.1,3.6,0.9,5.5c-0.1,1-0.3,1.9-0.6,2.8
				c-0.3,0.9-0.8,1.8-1.3,2.6c-1.1,1.7-2.5,3.2-4.2,4.4c-1.7,1.2-3.4,2.1-5.2,2.7c-1.8,0.6-3.7,0.8-5.3,0.7c-1.7-0.1-3.2-0.4-4.5-1
				c-2.5-1-4.3-3.3-5.3-5.4c-1-2.1-1.2-4.1-1.3-5.6c0-0.7-0.1-1.4-0.1-1.9c-0.1-0.5-0.2-0.9-0.5-1.1c-0.3-0.2-0.7-0.2-1.2,0
				c-0.5,0.2-1.1,0.7-1.8,1.5c-0.6,0.8-1.3,1.9-1.7,3.4c-0.4,1.4-0.7,3.2-0.5,5.1c0.1,1.9,0.7,4.1,1.7,6.3c0.3,0.5,0.6,1.1,0.9,1.6
				c0.4,0.5,0.7,1.1,1.2,1.6c0.5,0.5,0.9,1.1,1.4,1.5c0.3,0.2,0.5,0.5,0.8,0.7c0.3,0.2,0.6,0.4,0.8,0.7c2.3,1.8,5.2,3,8.2,3.7
				c3.1,0.7,6.4,0.7,9.7,0.2c3.3-0.6,6.8-1.8,9.9-3.6c3.1-1.9,6.1-4.4,8.5-7.6c1.2-1.6,2.3-3.4,3.1-5.4c0.8-2,1.4-4.1,1.7-6.3
				c0.6-4.4,0.1-9-1.8-13.5c-0.5-1.1-1.1-2.2-1.7-3.2c-0.7-1-1.4-2-2.2-2.9c-0.8-0.9-1.7-1.7-2.6-2.5c-0.5-0.4-0.9-0.7-1.4-1.1
				c-0.5-0.4-1-0.7-1.5-1c-4-2.5-8.2-3.7-12.3-4.3c-4.1-0.6-8.1-0.7-12-0.3c-1.9,0.2-3.8,0.4-5.6,0.7c-1.9,0.3-3.7,0.6-5.5,1
				c-7.6,1.7-14.2,4-20.2,6.7c-6,2.7-11.3,5.7-16.1,8.9c-4.8,3.2-9.1,6.5-13,10.1c-3.9,3.5-7.6,7.2-10.9,11
				c-6.7,7.7-12.6,16.1-18,26.2c-5.4,10-10.4,21.7-14.4,36.1c4.8-14.2,10.6-25.4,16.7-34.7c6.1-9.4,12.7-16.9,19.9-23.6
				c3.6-3.3,7.4-6.3,11.4-9.2c4-2.9,8.4-5.5,13.1-7.9c4.7-2.4,9.8-4.6,15.4-6.4C55.2,398.3,61.3,396.8,68,396z"
                />
              </g>
              <g>
                <path
                  d="M53,455.6c0.5,1.9,0.5,3.8,0.3,5.6c-0.3,1.9-0.8,3.7-1.7,5.4c-0.9,1.7-2,3.1-3.3,4.3c-0.6,0.6-1.3,1.1-2,1.6
				c-0.7,0.5-1.5,0.9-2.3,1.2c-3.2,1.4-6.9,1.6-10,0.9c-1.6-0.3-2.9-0.9-3.9-1.6c-1-0.7-1.6-1.3-2.1-2.1c-0.2-0.4-0.4-0.8-0.5-1.1
				c-0.1-0.3-0.2-0.6-0.1-0.9c0-0.6,0.3-1.5,1-2.3c0.7-0.9,1.6-1.7,2.6-2.4c0.5-0.3,1-0.7,1.5-1c0.5-0.3,0.9-0.5,1.3-0.8
				c0.5-0.3,1-0.6,1.4-0.9c0.2-0.2,0.7-0.2,1.1-0.2c0.4,0.1,0.7,0.2,1,0.4c0.3,0.2,0.5,0.5,0.8,0.6c0.3,0.1,0.7,0.1,1.1-0.4
				c0.4-0.4,0.7-1.4,0.4-2.5c-0.2-1.1-0.9-2.3-2.5-3.3c-1.5-0.9-3.3-1.1-5-1c-0.8,0.1-1.6,0.2-2.4,0.3c-0.8,0.1-1.6,0.3-2.5,0.6
				c-1.7,0.5-3.6,1.3-5.5,2.6c-1.9,1.3-4,3.4-5.2,6.4c-0.6,1.5-1,3.2-1.1,4.8c-0.1,1.7,0.1,3.3,0.6,5c0.5,1.6,1.2,3.2,2.1,4.6
				c0.9,1.5,2.1,2.7,3.3,3.8c2.5,2.2,5.4,3.8,8.4,4.8c6.1,2,13.1,1.9,19.6-0.7c3.2-1.3,6.4-3.2,9-5.8c2.6-2.5,4.7-5.6,6.2-8.9
				c1.5-3.3,2.4-6.9,2.5-10.6c0.2-3.7-0.5-7.5-1.8-10.8c-2.7-7-7.7-12-13.2-14.9c-5.6-2.9-11.3-3.4-16.2-3.1
				c-4.9,0.3-9.3,1.4-13.4,2.8c-4.1,1.3-7.9,3-11.5,4.9c-3.7,1.9-7.1,4.1-10.6,6.6c-3.4,2.5-6.7,5.3-9.9,8.6
				c-3.2,3.3-6.3,6.9-9.3,11.1c-2.9,4.2-5.7,9-8,14.5c2.6-5.3,5.7-9.8,9-13.7c3.2-3.9,6.6-7.1,10-9.9c3.4-2.8,6.9-5.1,10.4-7
				c3.5-1.9,7-3.5,10.6-4.8c3.6-1.2,7.3-2.3,11.1-2.9c3.8-0.7,7.7-1.1,11.5-0.8c3.8,0.3,7.6,1.4,10.5,3.5
				C49.6,448.2,52,451.7,53,455.6z"
                />
              </g>
              <g>
                <path
                  d="M121.8,361.1c10.9,1.6,21.5,3.9,29.6,5.9c0.5,0.1,1,0.2,1.5,0.3c0.5,0.1,1,0.2,1.4,0.4c0.9,0.2,1.8,0.4,2.6,0.6
				c0.8,0.2,1.6,0.3,2.4,0.5c0.7,0.2,1.4,0.3,2,0.4c2.5,0.4,4.2,0.3,4.6-0.5c0.4-0.8-0.5-2.1-2.5-3.9c-0.5-0.4-1.1-0.9-1.7-1.4
				c-0.6-0.5-1.4-0.9-2.1-1.4c-0.8-0.5-1.6-1-2.5-1.5c-0.5-0.2-0.9-0.5-1.4-0.8c-0.5-0.2-1-0.5-1.5-0.7c-8.1-3.8-19.9-6.9-31.8-6.7
				c-3.1,0.1-5.9,0.1-8.5,0.4c-1.3,0.1-2.6,0.2-3.8,0.4c-1.2,0.2-2.4,0.4-3.5,0.5c-1.1,0.2-2.3,0.3-3.3,0.5
				c-1.1,0.2-2.1,0.4-3.2,0.7c-1,0.2-2.1,0.4-3.1,0.7c-1,0.3-2,0.5-3,0.8c-1,0.3-2,0.5-3,0.8c-1,0.3-2,0.6-3,1c-1,0.3-2,0.6-3.1,1
				c-1,0.4-2.1,0.8-3.2,1.2c-1.1,0.4-2.2,0.8-3.3,1.3c-1.1,0.5-2.3,1-3.5,1.5c-0.6,0.3-1.2,0.5-1.8,0.8c-0.6,0.3-1.2,0.6-1.9,0.9
				c-1.3,0.6-2.6,1.2-4,1.9c1.4-0.5,2.8-1,4.1-1.5c0.7-0.2,1.3-0.5,1.9-0.7c0.6-0.2,1.3-0.4,1.9-0.6c1.2-0.4,2.4-0.7,3.6-1
				c1.2-0.3,2.3-0.5,3.4-0.8c1.1-0.2,2.2-0.5,3.3-0.7c1.1-0.2,2.1-0.3,3.2-0.5c1-0.1,2-0.3,3-0.4c1-0.1,2-0.2,3-0.3
				c1-0.1,2-0.2,3-0.2c1-0.1,2-0.1,3-0.1c1,0,2.1,0,3.1-0.1c1.1,0,2.1,0.1,3.2,0.1c1.1,0,2.2,0.1,3.4,0.1c1.2,0.1,2.3,0.2,3.6,0.3
				C116.4,360.4,119,360.8,121.8,361.1z"
                />
              </g>
              <g>
                <path
                  d="M120.9,378.3c10.8,0.9,21.8-0.5,29.7-2.7c7.9-2.2,12.8-5.1,12.3-6.8c-0.4-1.8-5.9-1.9-13.7-1.5
				c-7.8,0.4-18.1,1.3-28.4,2.2c-10.6,0.9-18,1.1-25.5,0.9c-7.5-0.3-15.1-1-26.1-3.4c10.7,3.5,18.2,5.4,25.6,7.2
				C102.3,375.9,109.8,377.4,120.9,378.3z"
                />
              </g>
              <g>
                <path
                  d="M132.4,371.1c6.3,1.3,12.7,1.4,17.5,0.7c4.8-0.7,8-2.3,8-4c0-1.8-3.1-3.3-7.8-4.4c-4.7-1-10.9-1.5-17.2-1
				c-6.5,0.5-10.9,0.9-15.5,1.2c-4.5,0.3-9.1,0.4-15.8,0c6.6,1.4,11,2.6,15.4,3.8C121.5,368.5,125.9,369.7,132.4,371.1z"
                />
              </g>
              <g>
                <path
                  d="M166.3,420.2c1,0,2.1,0,3.1-0.2c1-0.2,2.1-0.4,3.1-0.7c2-0.7,4-1.7,5.8-3.3c1.8-1.5,3.3-3.7,4-6.1
				c0.7-2.4,0.7-4.8,0.3-6.9c-0.4-2.1-1.2-4-2.1-5.7c-0.9-1.7-2-3.1-3.3-4.5c-1.3-1.3-2.8-2.4-4.5-3.3c-1.6-0.8-3.6-1.3-5.3-1.4
				c-3.5-0.2-6.9,1.1-9,3.3c-1.1,1.1-1.8,2.4-2.2,3.6c-0.4,1.2-0.5,2.3-0.4,3.3c0.2,2,1.1,3.4,1.9,4c0.8,0.7,1.5,0.7,1.8,0.4
				c0.3-0.3,0.4-0.9,0.5-1.6c0.1-0.7,0.2-1.6,0.7-2.4c0.4-0.9,1.2-1.8,2.1-2.1c0.9-0.4,2.3-0.6,3.6-0.2c1.3,0.4,2.6,1.3,3.9,2.8
				c1.2,1.5,2.3,3.6,2.6,5.5c0.2,0.9,0.1,1.7-0.1,2.3c-0.2,0.6-0.5,1.2-1.1,1.8c-0.6,0.6-1.5,1.2-2.5,1.7c-0.5,0.2-1.1,0.4-1.6,0.6
				c-0.6,0.2-1.1,0.3-1.8,0.4c-2.6,0.3-5.2,0-7.6-0.7c-2.4-0.7-4.7-1.7-6.8-2.9c-4.1-2.4-7.6-5.4-11-8.5c-3.5-3.1-6.9-6.5-11.2-9.7
				c-2.2-1.6-4.5-3.1-7.3-4.5c-2.7-1.3-5.9-2.4-9.3-2.6c3.5,0.5,6.4,1.9,8.9,3.4c2.5,1.5,4.7,3.3,6.5,5.1c3.8,3.6,6.6,7.3,9.5,11
				c3,3.7,6,7.4,10.3,11c2.2,1.8,4.6,3.4,7.6,4.8C158.6,419,162.2,420.1,166.3,420.2z"
                />
              </g>
              <g>
                <path
                  d="M130.6,425.7c-0.7,1.3-1.4,2.2-2.3,3c-0.9,0.7-1.7,1.2-2.5,1.4c-0.6,0.2-1.4,0.2-2.1,0c-0.7-0.2-1.2-0.4-1.5-0.7
				c-0.3-0.1-0.6-0.7-0.7-1.3c-0.1-0.6-0.1-1.3,0-1.9c0.1-0.6,0.3-1.1,0.4-1.6c0.2-0.5,0.3-1,0.2-1.4c-0.1-0.4-0.4-0.8-1.1-1
				c-0.7-0.2-1.8-0.1-2.9,0.5c-1.2,0.6-2.4,1.7-3.2,3.4c-0.8,1.6-1.3,4-0.4,6.6c0.8,2.4,2.7,4.6,5.1,5.9c2.4,1.3,5.3,1.8,8.3,1.2
				c2.9-0.6,5.4-2.2,7.2-4.2c1.8-2,3.1-4.6,3.7-7c0.6-2.5,0.6-4.8,0.4-6.8c-0.2-2-0.6-3.8-1-5.4c-0.9-3.3-2.2-6.1-3.7-8.6
				c-1.5-2.6-3.3-4.9-5.6-7.3c-1.1-1.2-2.4-2.3-3.8-3.5c-1.4-1.1-3.1-2.2-4.9-3.2c1.7,1.2,3.1,2.6,4.3,3.8c1.2,1.3,2.2,2.6,3,3.9
				c1.7,2.6,2.7,5.1,3.5,7.7c0.7,2.6,1.1,5.2,1.1,8C132.2,419.9,131.9,423.1,130.6,425.7z"
                />
              </g>
              <g>
                <path
                  d="M612.6,399.5c-0.7-3.6-1.9-7.1-3.5-10.4c-1.6-3.3-3.6-6.4-5.8-9.1c-2.2-2.7-4.6-4.8-6.8-6.8c-2.2-1.9-4.2-3.6-5.9-5.2
				c-3.5-3.1-6-6-7.6-8.2c-1.7-2.2-2.6-3.7-3.5-3.5c-0.8,0.1-1.5,2-0.9,5.4c0.3,1.6,0.9,3.6,1.9,5.6c1,2,2.4,4.2,4,6.3
				c3.3,4.3,7.7,8.3,11.3,12.7c3.5,4.3,6.4,9.6,8.4,15.4c1,3,1.7,5.9,2.1,8.6c0.4,2.7,0.7,5.4,0.7,7.9c0,5-0.5,9.6-1.6,14.1
				c-1.1,4.5-2.6,9-4.9,13.8c-2.3,4.8-5.4,9.9-9.8,15.6c4.8-5.3,8.4-10.1,11.3-14.7c2.9-4.6,5.2-9,7-13.5c1.8-4.6,3.3-9.3,4.1-14.8
				c0.4-2.8,0.6-5.7,0.6-8.9C613.7,406.6,613.4,403.2,612.6,399.5z"
                />
              </g>
              <g>
                <path
                  d="M580.2,404.8c-0.8-5.7-1-11.4-0.9-16.8c0.1-5.4,0.4-10.6,0.8-15c0.7-8.9,0.9-15.2-0.8-15.7c-1.7-0.4-4.9,5.1-7.5,14.3
				c-1.2,4.6-2.2,10.1-2.4,16.2c-0.2,6,0.4,12.6,2.2,18.9c1.9,6.5,4.3,11.6,7.1,15.9c1.4,2.2,2.8,4.1,4.3,5.9
				c1.4,1.8,2.9,3.4,4.2,5.1c2.6,3.3,5,6.9,5.9,11.6c0.2,1.2,0.4,2.4,0.4,3.7c0,1.3,0,2.6-0.2,4c-0.4,2.7-1.3,5.6-3,8.4
				c1.9-2.7,3.1-5.5,3.8-8.2c0.4-1.4,0.6-2.7,0.7-4.1c0.1-1.3,0.1-2.6,0.1-3.9c-0.1-2.5-0.7-5-1.5-7.2c-0.8-2.2-1.8-4.3-2.9-6.2
				c-2.2-3.9-4.5-7.5-6.2-11.7C582.4,415.5,581,410.7,580.2,404.8z"
                />
              </g>
              <g>
                <path
                  d="M593.6,391.6c-1-7.1-3.4-13.9-5.9-18.7c-2.5-4.8-4.9-7.5-5.7-7.1c-0.8,0.4,0.2,3.8,1.8,8.7c1.6,4.9,3.7,11.4,5.5,17.9
				c1.8,6.8,2.7,11.6,3.3,16.5c0.5,4.9,0.8,9.9,0.1,17.3c1.2-7.3,1.6-12.4,1.8-17.4C594.6,403.9,594.6,398.9,593.6,391.6z"
                />
              </g>
              <g>
                <path
                  d="M588.3,385.7c-0.4-5.3-1.8-10.5-3.4-14.1c-1.6-3.7-3.4-5.8-4.2-5.5c-0.8,0.3-0.6,3,0.2,6.7c0.7,3.8,1.9,8.6,3.1,13.5
				c1.2,5.1,1.9,8.6,2.3,12.2c0.4,3.6,0.7,7.4,0.3,12.8c0.9-5.4,1.3-9.2,1.6-12.9C588.5,394.8,588.7,391.1,588.3,385.7z"
                />
              </g>
              <g>
                <path
                  d="M583.8,385.6c0.2-8.5-1.7-16.9-3.5-16.8c-1.8,0.1-2.4,8.7-0.9,17.1c1.6,8.6,2.6,11.8,3.8,20.8
				C583,397.7,583.5,394.4,583.8,385.6z"
                />
              </g>
              <g>
                <path
                  d="M38.3,418.2c3.6-0.5,7.1-1.2,9.7-2c2.6-0.8,4.4-1.7,4.3-2.5c-0.1-0.9-2-1.5-4.9-1.6c-2.9-0.1-6.7,0.5-10.3,1.9
				c-3.7,1.5-6,2.8-8.4,4.1c-2.3,1.3-4.7,2.7-8,4.8c3.6-1.6,6.2-2.4,8.7-3C32.1,419.3,34.6,418.8,38.3,418.2z"
                />
              </g>
              <g>
                <path
                  d="M37.7,425.6c3.2-0.1,6.2-0.5,8.6-1.1c2.3-0.5,4-1.2,4-2.1c0-0.9-1.6-1.7-4.2-2.1c-2.6-0.4-6-0.1-9.3,0.9
				c-3.3,1.1-5.5,2.1-7.6,3.2c-2.1,1.1-4.2,2.2-7.3,3.9c3.3-1.2,5.6-1.8,7.9-2.1C32.2,425.9,34.5,425.7,37.7,425.6z"
                />
              </g>
              <g>
                <path
                  d="M59.5,432.6c2.4,0.8,4.7,1.4,6.6,1.6c1.9,0.2,3.4,0.1,3.8-0.7c0.4-0.8-0.5-2.1-2.3-3.2c-1.8-1.1-4.5-2-7.3-2
				c-2.9,0-4.8,0.3-6.8,0.7c-1.9,0.4-3.8,0.8-6.5,1.5c2.8-0.2,4.7,0,6.5,0.4C55.3,431.3,57.1,431.8,59.5,432.6z"
                />
              </g>
              <g>
                <path
                  d="M80.6,446.8c4,0.5,8.1-0.5,10.7-1.9c2.7-1.4,4-3.1,3.6-3.8c-0.4-0.8-2.4-0.5-5-0.1c-2.6,0.4-5.9,1-9.4,1.5
				c-3.6,0.4-6.1,0.3-8.7,0c-2.5-0.4-5.2-1.1-8.6-3.1c3.1,2.4,5.6,3.7,8.1,4.9C73.9,445.3,76.5,446.2,80.6,446.8z"
                />
              </g>
              <g>
                <path
                  d="M84.1,455.5c2.2,0,4.3-0.8,5.6-1.8c1.3-1,1.9-2.2,1.5-3c-0.4-0.8-1.6-1-3.1-0.9c-1.5,0.1-3.2,0.6-4.9,1.4
				c-1.8,0.8-3.1,1.4-4.4,1.8c-1.4,0.4-2.8,0.8-4.9,0.8c2.1,0.5,3.5,0.8,5,1.1C80.4,455.3,81.9,455.5,84.1,455.5z"
                />
              </g>
              <g>
                <path
                  d="M-6.8,468.2c-3.8,3.9-6.3,9-7.3,13c-1,4-0.7,6.8,0.2,7c0.9,0.2,2.1-2.2,3.8-5.5c1.7-3.3,4-7.6,6.8-11.7
				c2.9-4.2,5.4-6.9,8.1-9.3c2.8-2.3,5.8-4.6,11-6.6c-5.3,1.5-8.8,3.2-12.1,5C0.3,462-2.9,464.1-6.8,468.2z"
                />
              </g>
              <g>
                <path
                  d="M-0.6,480.6c-1,1.9-1.8,3.9-2.4,5.8c-0.3,1-0.5,1.9-0.7,2.8c-0.2,0.9-0.3,1.9-0.3,2.8c0,0.9,0.1,1.8,0.4,2.6
				c0.3,0.8,0.7,1.5,1.2,1.9c0.5,0.4,1,0.6,1.4,0.6c0.4,0,0.6-0.1,0.7-0.3c0.2-0.4-0.1-1,0-1.6c0-0.6,0.1-1.5,0.5-2.7
				c0.2-0.6,0.4-1.2,0.7-2.1c0.3-0.8,0.6-1.7,0.8-2.5c0.6-1.8,1.1-3.7,1.7-5.6c1.1-4,2.1-6.7,3.2-9.3c1.2-2.7,2.5-5.3,5-8.9
				c-2.9,3.2-4.8,5.6-6.7,7.9C3.3,474.3,1.5,476.7-0.6,480.6z"
                />
              </g>
              <g>
                <path
                  d="M7.5,492.9c-0.1,2.4,0.6,4.8,1.6,6.4c1,1.6,2.2,2.3,3,2c0.8-0.4,1.1-1.7,1-3.3c-0.1-1.6-0.5-3.6-1.2-5.6
				c-0.7-2.1-1.2-3.6-1.5-5.1c-0.3-1.6-0.5-3.2-0.3-5.6c-0.7,2.3-1.2,3.9-1.6,5.5C8.1,488.7,7.7,490.4,7.5,492.9z"
                />
              </g>
              <g>
                <path
                  d="M19.5,498.4c0.8,2,2.3,3.6,3.7,4.4c1.4,0.8,2.7,0.9,3.3,0.2c0.6-0.7,0.3-1.9-0.3-3.1c-0.6-1.3-1.7-2.7-3-3.9
				c-1.4-1.3-2.4-2.3-3.2-3.3c-0.9-1.1-1.7-2.2-2.5-4.2c0.3,2.1,0.5,3.5,0.7,4.9C18.5,494.9,18.7,496.4,19.5,498.4z"
                />
              </g>
              <g>
                <path
                  d="M36.3,500.3c1.6,0.8,3.4,0.8,4.7,0.4c1.3-0.4,2-1.3,2-2.2c-0.1-0.9-0.9-1.6-1.9-2c-1.1-0.5-2.4-0.7-3.8-0.5
				c-1.5,0.2-2.6,0.2-3.7,0.2c-1.1,0-2.3-0.2-3.8-0.8c1.1,0.9,2.1,1.7,3.2,2.6C33.7,498.7,34.7,499.5,36.3,500.3z"
                />
              </g>
              <g>
                <path
                  d="M173.3,453.4c1.6-3.6,2.3-7.6,2.2-11.5c-0.1-3.9-1-7.8-2.9-11.2c-1-1.7-2.2-3.2-3.6-4.5c-1.5-1.3-3.2-2.3-5.1-2.8
				c-3.8-1.1-7.5-0.3-10.2,1.3c-2.8,1.6-4.7,4.3-5.4,6.7c-0.8,2.5-0.6,4.7-0.2,6.4c0.5,1.7,1.2,2.9,1.8,3.6c0.6,0.7,1.1,0.9,1.2,0.8
				c0.4-0.3-0.5-1.9-0.7-4.8c0-1.4,0.2-3.2,1-4.9c0.8-1.7,2.2-3.3,4.2-4.2c2-1,4.6-1.3,6.8-0.5c2.3,0.8,4.4,2.7,5.8,5.3
				c1.4,2.6,2.2,5.7,2.4,8.9c0.2,3.2-0.3,6.6-1.4,9.7c-1.2,3.2-3,6-4.8,8.5c-1.9,2.5-3.8,4.8-5.6,7.1c-0.9,1.2-1.7,2.3-2.5,3.5
				c-0.4,0.6-0.7,1.2-1.1,1.8l-0.5,0.9l-0.5,0.9c-0.3,0.6-0.6,1.2-0.9,1.8c-0.3,0.6-0.5,1.2-0.8,1.8c-0.6,1.2-0.9,2.5-1.4,3.7
				c-0.8,2.5-1.4,5.1-1.8,7.8c-0.2,1.3-0.3,2.7-0.4,4.1c0,0.7,0,1.4,0,2.1c0,0.7,0,1.4,0,2.2c0.1,2.9,0.5,6,1.3,9.3
				c0.8,3.2,2,6.6,3.8,10c-1.7-3.5-2.7-6.9-3.4-10.1c-0.6-3.2-0.9-6.3-0.8-9.2c0-0.7,0-1.4,0.1-2.1c0.1-0.7,0.1-1.4,0.2-2.1
				c0.2-1.4,0.3-2.7,0.6-4c0.5-2.6,1.3-5,2.2-7.4c1.9-4.7,4.5-9,8.3-13.1c1.9-2.1,4-4.2,6.1-6.7C169.4,460,171.7,457.1,173.3,453.4z
				"
                />
              </g>
              <g>
                <path
                  d="M154.7,445.2c-2.1,1.9-3.9,4-5.1,5.8c-1.2,1.8-1.8,3.3-1.2,4c0.6,0.7,2.2,0.3,4.2-0.9c1.9-1.2,4.1-3.4,5.6-6.1
				c0.8-1.4,1.3-2.5,1.8-3.6c0.5-1.1,0.9-2.1,1.2-3.2c0.3-1.1,0.5-2.2,0.4-3.5c-0.1-1.2-0.5-2.6-1.7-3.7c0.9,1.3,1,2.6,0.8,3.7
				c-0.2,1.1-0.7,2-1.2,2.7c-0.6,0.8-1.2,1.5-2,2.3C156.6,443.3,155.7,444.2,154.7,445.2z"
                />
              </g>
              <g>
                <path
                  d="M157.3,453.9c-1.7,1.7-3.1,3.6-4,5.2c-0.9,1.6-1.2,3-0.6,3.7c0.6,0.6,2.1,0.3,3.7-0.9c1.6-1.1,3.4-3.1,4.4-5.5
				c1.1-2.4,1.7-4.2,2.2-6c0.6-1.9,1.1-3.7,1.8-6c-1.2,2.3-2.3,3.8-3.5,5.1C160.3,450.9,159.1,452.2,157.3,453.9z"
                />
              </g>
              <g>
                <path
                  d="M187.3,434.6c0.8-2.6,0.7-5.4,0.1-7.4c-0.6-2-1.7-3.1-2.5-2.9c-0.9,0.2-1.4,1.5-1.6,3.3c-0.3,1.8-0.4,4-0.3,6.3
				c0.1,2.4,0,4.2-0.2,5.9c-0.3,1.7-0.7,3.5-1.9,5.9c1.6-2.1,2.7-3.7,3.6-5.2C185.5,438.9,186.5,437.3,187.3,434.6z"
                />
              </g>
              <g>
                <path
                  d="M196,440.1c1.4-0.9,2.5-2.1,3.2-3.2c0.7-1.1,1-2.2,0.6-3c-0.5-0.7-1.7-0.9-3.1-0.4c-1.4,0.5-3,1.8-4,3.6
				c-1,1.8-1.4,3.2-1.8,4.5c-0.4,1.3-0.7,2.6-1.2,4.5c1-1.7,1.9-2.6,2.8-3.5C193.5,441.8,194.5,441.1,196,440.1z"
                />
              </g>
              <g>
                <path
                  d="M204.1,446c1.7-0.2,3.1-0.8,4.2-1.5c1.1-0.7,1.8-1.6,1.7-2.5c-0.1-0.9-1.2-1.5-2.7-1.6c-1.5-0.1-3.4,0.4-5,1.6
				c-1.6,1.2-2.5,2.2-3.5,3.2c-1,1.1-1.9,2.2-3,3.4c1.6-1,2.8-1.5,4-1.8C201.1,446.4,202.3,446.2,204.1,446z"
                />
              </g>
              <g>
                <path
                  d="M255.9,442.9c-2.8,2-5.5,3.9-7.6,5.6c-2,1.6-3.4,3-3,3.8c0.3,0.8,2.4,0.8,5.1-0.2c2.7-1,6-3.1,8.4-6
				c2.5-3,3.9-5.3,5.2-7.6c1.2-2.3,2.4-4.7,3.8-8.3c-1.9,3.4-3.5,5.4-5.3,7.2C260.7,439.2,258.8,440.8,255.9,442.9z"
                />
              </g>
              <g>
                <path
                  d="M258,452.6c-2.4,3.6-3.9,7.7-4.5,10.9c-0.5,3.2-0.2,5.5,0.6,5.6c0.9,0.2,2-1.7,3.4-4.4c1.3-2.7,2.9-6.4,4.3-10.1
				c1.5-3.8,2.8-6.4,4.3-9c1.5-2.5,3.1-5,6.1-8.3c-3.4,2.9-5.5,5-7.6,7.1C262.6,446.7,260.5,448.9,258,452.6z"
                />
              </g>
              <g>
                <path
                  d="M264.1,459.8c-1.9,3.8-3,8-3.2,11.2c-0.3,3.2,0.2,5.4,1,5.5c0.9,0.1,1.9-1.8,3-4.6c1.1-2.9,2.3-6.7,3.3-10.6
				c1-4,1.9-6.8,2.9-9.6c1-2.8,2.2-5.5,4.4-9.3c-2.7,3.5-4.4,6-6.1,8.5C267.7,453.4,266.1,455.9,264.1,459.8z"
                />
              </g>
              <g>
                <path
                  d="M266.4,475.3c-1.1,3.9-1.3,8-0.8,11c0.4,3,1.4,5,2.2,4.9c0.9-0.1,1.5-2,2-4.9c0.5-2.8,0.8-6.6,1-10.3
				c0.2-3.9,0.5-6.6,0.9-9.3c0.5-2.7,1.1-5.5,2.6-9.4c-2,3.6-3.2,6.2-4.4,8.8C268.6,468.7,267.5,471.3,266.4,475.3z"
                />
              </g>
              <g>
                <path
                  d="M332.1,491.8c-2.3,0.4-4.4,1.4-5.7,2.6c-1.3,1.2-1.9,2.4-1.4,3.2c0.5,0.8,1.8,0.9,3.4,0.5c1.6-0.3,3.5-1.1,5.3-2.2
				c1.9-1.2,3.2-2,4.6-2.7c1.4-0.7,2.9-1.4,5.1-2c-2.1,0-3.8,0.1-5.6,0.1C336.1,491.3,334.5,491.4,332.1,491.8z"
                />
              </g>
              <g>
                <path
                  d="M338.2,497.8c-2,0.5-2.5,2.8-1.3,4.1c1.2,1.3,3.4,1.1,4.2-0.8c0.8-1.9,0.9-3,2-4.7C341.2,497.3,340.2,497.3,338.2,497.8z
				"
                />
              </g>
              <g>
                <path d="M432.9,506.6c-3.6,0.1-6.5,2.6-5.9,4.2c0.7,1.6,4.1,1.7,7,0c3-1.8,4.1-2.8,7.6-4C438.1,507,436.6,506.6,432.9,506.6z" />
              </g>
              <g>
                <path
                  d="M152,526.9c-2.4-3.5-5.6-6.8-9.9-9.1c-2.1-1.1-4.4-2-6.8-2.5c-2.4-0.5-5-0.6-7.6-0.1c-5.2,0.9-9.7,3.7-12.6,7.9
				c-0.7,1.1-1.3,2.2-1.8,3.4c-0.5,1.2-0.8,2.5-0.9,3.7c-0.3,2.4,0,4.7,0.7,6.8c0.7,2.1,1.8,4,3.3,5.5c1.5,1.5,3.3,2.6,5,3.2
				c3.6,1.1,6.6,0.4,8.6-0.7c2.1-1.1,3.2-2.7,3.5-3.9c0.2-0.6,0.2-1.1,0.1-1.5c-0.1-0.4-0.3-0.7-0.6-0.8c-0.3-0.2-0.6-0.3-1-0.3
				c-0.4,0-0.8,0-1.2-0.1c-0.8,0-1.8-0.1-2.7-0.4c-0.9-0.3-2-0.9-2.4-1.5c-0.2-0.3-0.3-0.7-0.4-1.1c-0.1-0.4-0.1-0.8-0.1-1.4
				c0-0.5,0.2-1,0.4-1.4c0.1-0.2,0.2-0.4,0.3-0.5c0.1-0.2,0.3-0.4,0.5-0.5c0.4-0.4,0.9-0.7,1.5-1c0.6-0.3,1.3-0.5,2-0.6
				c1.4-0.2,3.4,0.1,5.5,0.9c2,0.8,4.2,2.4,6.2,4.4c1.1,1,2.1,2.2,3,3.4c0.9,1.2,1.8,2.3,2.6,3.5c1.5,2.4,2.8,4.7,3.8,7.1
				c2,4.7,3.3,9.3,3.7,14.1c0.5,4.8,0.2,9.7-1.1,15.2c-1.3,5.4-3.8,11.3-8.4,17.4c5.2-5.5,8.6-11.1,10.9-16.5
				c2.3-5.4,3.7-10.6,4.3-15.9c0.6-5.3,0.5-10.8-0.6-16.8c-0.6-3-1.4-6.1-2.7-9.4c-0.7-1.6-1.4-3.3-2.3-5
				C154.2,530.4,153.2,528.7,152,526.9z"
                />
              </g>
              <g>
                <path
                  d="M180.5,567.9c3.9-1.9,7.3-4.1,9.8-6.3c2.5-2.1,4.1-4.2,3.6-5.9c-0.5-1.6-3.3-2.4-7.1-1.7c-3.7,0.7-8.4,3.2-11.7,7
				c-3.4,3.9-5.2,7-6.8,10c-1.6,3.1-3.1,6.2-4.9,10.7c2.8-4.1,5.3-6.3,7.8-8.3C173.8,571.6,176.5,569.9,180.5,567.9z"
                />
              </g>
              <g>
                <path
                  d="M174.5,542.4c0.4-3.8-1-7.4-2.8-9.6c-1.9-2.2-4.1-3-5.6-2.2c-1.6,0.9-2.2,2.9-2.2,5.3c0,2.4,0.6,5.1,1.9,7.8
				c1.3,2.9,2.2,5,2.8,7.3c0.6,2.3,1,4.7,0.6,8.2c1.4-3.3,2.3-5.6,3.2-8C173.2,548.8,174.1,546.3,174.5,542.4z"
                />
              </g>
              <g>
                <path
                  d="M59.8,573.1c-3.7,3.4-7.3,6.6-11.1,9.2c-3.7,2.5-7.5,4.5-10.6,4.9c-3.2,0.5-5.8-0.6-7.3-1.7c-1.6-1.1-2.1-2.3-2.6-2.2
				c-0.2,0.1-0.3,0.4-0.3,1.1c0.1,0.7,0.4,1.7,1.1,2.7c0.7,1.1,1.9,2.2,3.5,3c1.6,0.8,3.7,1.3,5.9,1.3c2.2-0.1,4.5-0.6,6.7-1.5
				c2.2-0.9,4.3-2.1,6.4-3.4c4.2-2.8,8-6.4,11.4-10.2c3.5-3.9,6.3-7.3,8.9-10.2c2.6-2.9,5.1-5.4,7.9-7.7c2.7-2.2,5.7-4.2,9.4-5.8
				c3.7-1.5,8.2-2.7,13.6-2c-5.3-1-10-0.1-13.9,1.2c-3.9,1.3-7.2,3.1-10.3,5.1c-3.1,2-5.9,4.3-8.9,7
				C66.7,566.4,63.5,569.6,59.8,573.1z"
                />
              </g>
              <g>
                <path
                  d="M73.8,568.7c-1.9,3-3.2,6.4-4,9.7c-0.8,3.3-0.9,6.4-0.8,9.1c0.4,5.4,2.1,8.6,2.9,8.4c0.9-0.2,0.8-3.6,1.3-8.4
				c0.3-2.4,0.7-5.1,1.4-8c0.7-2.9,1.7-5.9,3-8.8c1.4-3,2.8-5.4,4.4-7.4c1.6-2,3.2-3.7,4.9-5.3c1.8-1.5,3.7-3,6-4.3
				c2.3-1.3,5.1-2.5,8.6-3.4c-3.5,0.6-6.4,1.6-9,2.6c-2.5,1.1-4.7,2.2-6.8,3.5c-2.1,1.3-4,2.8-6.1,4.7
				C77.8,563.3,75.8,565.6,73.8,568.7z"
                />
              </g>
              <g>
                <path
                  d="M82.3,565.7c-1.2,2.3-2,4.8-2.4,7.2c-0.4,2.4-0.3,4.7,0,6.6c0.6,3.9,2.2,6.1,3,5.9c0.9-0.2,0.9-2.7,1.2-6
				c0.1-1.7,0.4-3.6,0.7-5.6c0.4-2,0.9-4.2,1.5-6.3c0.7-2.2,1.5-4,2.4-5.5c0.9-1.6,1.8-2.9,2.9-4.2c1.1-1.3,2.3-2.5,3.8-3.7
				c1.5-1.2,3.3-2.3,5.7-3.3c-2.4,0.8-4.4,1.7-6.1,2.6c-1.7,0.9-3.2,1.9-4.6,3c-1.4,1.1-2.7,2.3-4.1,3.8
				C84.9,561.6,83.5,563.4,82.3,565.7z"
                />
              </g>
              <g>
                <path
                  d="M126.8,555.4c3.6,0.7,7.5,0,9.9-1.3c2.5-1.3,3.7-2.8,3.3-3.6c-0.4-0.8-2.2-0.7-4.6-0.4c-2.4,0.3-5.3,0.6-8.5,0.9
				c-3.2,0.2-5.5,0-7.8-0.4c-2.3-0.5-4.6-1.3-7.6-3.4c2.6,2.5,4.7,3.9,6.9,5.1C120.8,553.5,123.1,554.6,126.8,555.4z"
                />
              </g>
              <g>
                <path
                  d="M138.6,563.1c1.7,1.4,3.4,2.4,4.9,3.1c1.5,0.7,2.8,0.9,3.4,0.3c0.6-0.6,0.4-2-0.8-3.5c-1.1-1.5-3.1-3.1-5.5-3.8
				c-2.4-0.7-4.2-0.9-5.9-1c-1.7-0.1-3.4-0.2-5.8-0.2c2.4,0.5,3.9,1.2,5.3,2C135.6,560.8,136.9,561.7,138.6,563.1z"
                />
              </g>
              <g>
                <path
                  d="M141,578.8c0.3,2.1,1,4.1,1.8,5.5c0.8,1.5,1.7,2.4,2.6,2.3c0.9-0.1,1.5-1.4,1.5-3.2c0.1-1.8-0.5-4.2-1.8-6.2
				c-1.3-2.1-2.4-3.4-3.5-4.6c-1.1-1.2-2.2-2.5-3.8-4.2c1.2,2.1,1.7,3.6,2.2,5.2C140.4,575.1,140.7,576.6,141,578.8z"
                />
              </g>
              <g>
                <path
                  d="M143.4,505.7c0.1-2,0.1-3.7-0.1-5.2c-0.2-1.5-0.5-2.7-1.3-3c-0.8-0.3-2,0.5-2.8,2.3c-0.8,1.7-1,4.4-0.2,6.7
				c0.9,2.4,1.9,3.8,3,5.1c1.1,1.3,2.2,2.5,4,4c-1.5-1.8-2-3.4-2.3-4.8C143.4,509.2,143.3,507.7,143.4,505.7z"
                />
              </g>
              <g>
                <path
                  d="M158.8,498.6c0.9-1.8,1.7-3.4,2.3-4.9c0.6-1.4,0.9-2.7,0.4-3.4c-0.5-0.7-2.1-0.5-3.6,0.8c-1.6,1.3-3.1,3.7-3.3,6.4
				c-0.2,2.8,0.3,4.6,0.9,6.3c0.6,1.7,1.4,3.3,2.8,5.3c-1-2.3-1.1-4-0.9-5.5C157.5,501.9,157.9,500.4,158.8,498.6z"
                />
              </g>
              <g>
                <path
                  d="M187.9,576c2.7-0.4,5.2-1.1,7.1-1.9c1.9-0.8,3.2-1.7,3.1-2.6c-0.1-0.9-1.6-1.5-3.8-1.5c-2.2,0-5.1,0.5-7.7,1.8
				c-2.7,1.3-4.4,2.5-6.1,3.6c-1.8,1.2-3.5,2.4-5.8,4c2.7-1.2,4.6-1.8,6.5-2.3C183.2,576.8,185.2,576.4,187.9,576z"
                />
              </g>
              <g>
                <path
                  d="M149,392.3c3,0.1,5.8-1,7.6-2.1c1.8-1.1,2.7-2.4,2.4-3.2c-0.3-0.8-1.9-1-3.9-0.7c-2,0.3-4.4,1-6.7,1.7
				c-2.4,0.8-4.2,1-6,0.9c-1.8-0.1-3.8-0.5-5.9-2.5c1.7,2.3,3.6,3.4,5.4,4.3C143.8,391.6,145.9,392.2,149,392.3z"
                />
              </g>
              <g>
                <path
                  d="M134,321c-1.8,2.8-3.2,6-3.8,9.2c-0.6,3.2-0.5,6.3,0.2,8.9c0.7,2.6,1.8,4.5,2.9,5.6c0.5,0.6,1,0.9,1.4,1.1
				c0.4,0.2,0.7,0.2,0.8,0.1c0.7-0.6-0.7-3.3-1-7.6c-0.1-2.1,0-4.6,0.5-7.2c0.5-2.6,1.5-5.3,2.8-8c1.3-2.8,2.7-5.1,4-7.1
				c1.3-2.1,2.6-3.9,3.9-5.7c1.3-1.9,2.6-3.7,4-5.8c1.4-2.1,2.9-4.5,4.3-7.6c-1.6,2.9-3.4,5.1-5,7.1c-1.6,1.9-3.2,3.5-4.8,5.2
				c-1.6,1.6-3.1,3.2-4.8,5.1C137.7,315.9,135.8,318.1,134,321z"
                />
              </g>
              <g>
                <path
                  d="M143.2,322.4c-0.7,5.5,0.4,11.2,2,15.1c1.6,3.9,3.6,6,4.4,5.6c0.8-0.4,0.3-3.1-0.4-6.8c-0.7-3.8-1.4-8.7-1.7-13.7
				c-0.2-5.2,0.2-8.9,1-12.5c0.8-3.6,2-7.3,5-12.1c-3.4,4.5-5.2,8-6.8,11.5C145.2,313,143.9,316.7,143.2,322.4z"
                />
              </g>
              <g>
                <path
                  d="M149.7,316.6c0,4,1.3,7.8,2.8,10.4c1.5,2.6,3.2,3.9,3.9,3.4c0.8-0.4,0.5-2.4-0.1-5c-0.6-2.6-1.5-6-2.3-9.4
				c-0.9-3.5-1.2-6.1-1.4-8.7c-0.1-2.6,0-5.3,1-9.2c-1.5,3.7-2.3,6.3-2.9,9C150.2,309.8,149.7,312.5,149.7,316.6z"
                />
              </g>
              <g>
                <path
                  d="M93.8,341.5c3.2-2.6,5.7-6,7.1-8.8c1.4-2.8,1.7-5,0.9-5.4c-0.8-0.4-2.4,0.9-4.4,3c-2,2.1-4.4,5-6.8,8.1
				c-2.4,3.1-4.3,5.2-6.3,7.2c-2,2-4.2,3.8-7.8,6.1c3.8-1.8,6.4-3.2,8.9-4.6C88,345.7,90.5,344.2,93.8,341.5z"
                />
              </g>
              <g>
                <path
                  d="M102.3,347.6c2.7-0.3,5.3-1,7.2-1.7c1.9-0.8,3.2-1.7,3.1-2.5c-0.1-0.9-1.6-1.5-3.8-1.6c-2.2-0.1-5.1,0.4-7.7,1.7
				c-2.7,1.3-4.4,2.4-6.2,3.5c-1.8,1.2-3.6,2.3-5.9,3.8c2.7-1.2,4.7-1.7,6.6-2.1C97.6,348.2,99.5,347.9,102.3,347.6z"
                />
              </g>
              <g>
                <path
                  d="M119.4,246.7c1.9-1.6,3.5-3.5,4.5-5.2c1-1.6,1.4-3,0.8-3.7c-0.6-0.6-2.1-0.3-3.8,0.8c-1.7,1.1-3.6,3-4.9,5.4
				c-1.3,2.4-2,4.2-2.8,5.9c-0.8,1.9-1.5,3.7-2.5,6c1.5-2.3,2.8-3.7,4.1-5C116.1,249.6,117.4,248.4,119.4,246.7z"
                />
              </g>
              <g>
                <path
                  d="M20.7,384.4c-7.8,3.2-14.9,7.9-19.7,12.1c-4.8,4.2-7.2,7.7-6.7,8.3c0.6,0.7,4.1-1.6,9.2-5c5.1-3.3,11.9-7.6,19-11.4
				c7.3-3.9,12.7-6.1,18.2-8c5.5-1.8,11.3-3.3,20-4.3c-8.8,0.4-14.7,1.3-20.5,2.4C34.5,379.7,28.7,381.1,20.7,384.4z"
                />
              </g>
              <g>
                <path
                  d="M27.1,386.9c-6.2,3.1-11.7,7.5-15.3,11.3c-3.6,3.8-5.4,6.9-4.8,7.4c0.7,0.6,3.5-1.3,7.5-4.3c4-3,9.3-6.9,14.8-10.6
				c5.7-3.8,9.8-6.1,14.1-8.2c4.3-2,8.9-3.9,15.8-5.7c-7.1,1.2-11.9,2.5-16.5,3.9C38.1,382.1,33.5,383.7,27.1,386.9z"
                />
              </g>
              <g>
                <path
                  d="M-19.8,372.5c-3.2-2.4-6.3-4.7-8.9-6.3c-2.5-1.6-4.5-2.5-5.1-1.9c-0.6,0.6,0.3,2.7,2.4,5.1c2.1,2.4,5.5,5.1,9.3,6.9
				c4,1.8,6.8,2.7,9.6,3.6c2.9,0.8,5.7,1.6,10,2.5c-4.1-1.4-6.8-2.8-9.3-4.2C-14.2,376.7-16.6,375-19.8,372.5z"
                />
              </g>
              <g>
                <path
                  d="M-14.5,384.4c-0.9,0-1.8,0.2-2.7,0.6c-0.9,0.3-1.9,1-2.5,2c-0.6,1-0.6,2.1-0.3,2.7c0.3,0.7,0.9,0.8,1.3,0.7
				c0.4-0.1,0.8-0.4,1.2-0.5c0.4-0.1,0.7-0.1,1-0.1c0.2,0.1,0.6,0,1.2-0.2c0.7-0.2,1.4-0.5,2.1-1c1.4-0.9,2.5-1.5,3.6-2
				c1.1-0.5,2.3-0.9,4.1-1.2c-1.6-0.2-2.9-0.5-4.4-0.7C-11.3,384.6-12.6,384.4-14.5,384.4z"
                />
              </g>
              <g>
                <path
                  d="M-13.6,395.2c-2.1,1.6-3.6,3.7-4.4,5.6c-0.7,1.8-0.7,3.3,0,3.8c0.8,0.5,2-0.2,3.4-1.4c1.4-1.2,2.9-3,4.1-5
				c1.3-2.1,2.3-3.5,3.4-4.9c1.1-1.4,2.3-2.7,4.3-4.4c-2.3,1.2-4,2-5.6,2.9C-9.8,392.7-11.4,393.6-13.6,395.2z"
                />
              </g>
              <g>
                <path d="M-9.5,421.5c-1.3,2.9-0.3,6,1.4,6.3c1.7,0.2,3.5-2.6,2.9-5.7c-0.5-3.2-1.1-4.4-1.2-7.7C-7.4,417.5-8.2,418.6-9.5,421.5z" />
              </g>
              <g>
                <path d="M-2.1,415.6c-1.2,2.5-0.2,5.3,1.5,5.5c1.7,0.2,3.4-2.2,2.8-4.9c-0.6-2.8-1.2-3.9-1.4-6.7C0,412.2-0.9,413.1-2.1,415.6z" />
              </g>
              <g>
                <path
                  d="M578.1,305.9c3.3-4.2,6.4-8.7,8.9-13.6c2.5-4.9,4.2-10.3,4.9-15.7c0.7-5.4,0.4-10.9-1.2-15.8c-0.4-1.2-0.8-2.4-1.3-3.6
				c-0.5-1.2-1.1-2.3-1.8-3.4c-1.4-2.2-3.1-4.2-5.3-5.7c-1.1-0.8-2.3-1.3-3.6-1.8c-1.3-0.4-2.7-0.6-4-0.5c-2.5,0.1-4.9,0.9-6.8,2.5
				c-0.9,0.8-1.7,1.7-2.3,2.7c-0.6,1-0.8,2.1-1,3.1c-0.2,2,0.2,3.7,0.7,5c1.1,2.5,2.5,3.9,3.5,4.8c1,0.9,1.7,1.1,2.1,0.9
				c0.4-0.2,0.3-1,0.1-2.2c-0.2-1.2-0.8-2.9-1-4.7c-0.1-0.9,0-1.8,0.3-2.5c0.2-0.3,0.4-0.7,0.6-0.9c0.2-0.2,0.5-0.4,0.9-0.6
				c0.7-0.3,1.7-0.5,2.7-0.3c0.5,0.1,1,0.2,1.4,0.4c0.4,0.2,0.9,0.6,1.4,1c0.9,0.8,1.8,2,2.5,3.4c0.7,1.4,1.3,3.2,1.8,5
				c0.9,3.6,1,7.7,0.4,12c-0.6,4.2-1.9,8.6-3.8,12.8c-1.9,4.3-4.4,8.5-7.2,12.6c-2.9,4.3-5.8,8.2-8.5,11.9
				c-2.7,3.7-5.2,7.3-7.5,10.8c-2.2,3.5-4.2,7-5.8,10.6c-1.6,3.6-2.8,7.2-3.7,10.9c-1.7,7.4-2.1,15.1-0.8,23.5
				c0.6,4.2,1.7,8.5,3.4,13c1.7,4.4,4,9,7.3,13.5c-3-4.6-5.1-9.3-6.5-13.7c-1.4-4.5-2.1-8.8-2.4-12.9c-0.6-8.2,0.5-15.5,2.8-22.3
				c1.2-3.4,2.6-6.6,4.4-9.7c1.8-3.1,4-6.2,6.4-9.3c2.4-3.1,5.2-6.3,8.2-9.7C571.4,313.8,574.7,310.2,578.1,305.9z"
                />
              </g>
              <g>
                <path
                  d="M561.9,353.7c1-1.7,2-3.3,3.1-4.8c1.1-1.5,2.2-2.8,3.4-4.1c1.1-1.3,2.4-2.4,3.5-3.4c0.3-0.2,0.6-0.5,0.9-0.7
				c0.3-0.2,0.5-0.4,0.8-0.6c0.5-0.4,1.1-0.7,1.6-1c2.2-1.2,4-1.8,5.4-2c1.5-0.3,2.7-0.1,3.3-0.7c0.3-0.3,0.4-0.8,0.2-1.5
				c-0.2-0.7-0.8-1.5-1.9-2.3c-1-0.8-2.5-1.4-4.3-1.6c-1.7-0.2-3.7-0.1-5.6,0.3c-1,0.2-2,0.6-3,1c-0.5,0.2-1,0.5-1.5,0.7
				c-0.5,0.3-0.9,0.5-1.4,0.8c-1.8,1.1-3.7,2.5-5.3,4.2c-1.6,1.7-3.1,3.6-4.4,5.6c-1.2,2.1-2.2,4.3-3,6.5c-1.6,4.7-2.1,8.7-2.3,12.3
				c-0.2,3.6,0,6.8,0.3,9.9c0.7,6.3,1.9,12.4,3.8,21.4c-0.9-9.1-0.8-15.4,0-21.4c0.4-3,1-5.9,1.9-9
				C558.6,360.4,559.9,357.1,561.9,353.7z"
                />
              </g>
              <g>
                <path
                  d="M574.5,279.9c-0.6-1.9-1.5-3.7-2.6-5.3c-0.6-0.8-1.2-1.6-1.9-2.3c-0.7-0.7-1.5-1.3-2.3-1.9c-1.7-1.1-3.9-1.8-6.2-1.5
				c-1.1,0.2-2.1,0.5-3,1.1c-0.9,0.6-1.6,1.4-2.1,2.4c-0.4,0.9-0.6,1.9-0.6,2.8c0,0.9,0.2,1.6,0.5,2.3c0.5,1.3,1.2,2.1,1.8,2.7
				c1.2,1.2,2.1,1.6,2.4,1.4c0.4-0.3,0.1-1.2-0.4-2.6c-0.2-0.7-0.5-1.5-0.6-2.4c-0.1-0.9,0.1-1.5,0.5-2c0.4-0.4,1.1-0.8,2-0.9
				c0.8-0.1,1.9,0.3,2.9,1c1.1,0.7,2.1,1.8,3.1,2.9c0.9,1.2,1.7,2.5,2.4,3.9c1.3,3,1.8,5.9,1.9,8.5c0.1,2.6-0.2,5.1-0.8,7.5
				c-1.1,4.8-3.2,9.6-6.8,16.2c4.1-6.4,6.7-10.8,8.6-15.6c0.9-2.4,1.7-4.9,2-7.9C575.6,287.2,575.6,283.8,574.5,279.9z"
                />
              </g>
              <g>
                <path
                  d="M568.9,290c-0.3-0.5-0.5-1.1-0.8-1.6c-0.3-0.5-0.7-1.1-1.2-1.5c-0.9-1-1.9-1.8-3.4-2.3c-0.7-0.3-1.5-0.4-2.3-0.4
				c-0.8,0-1.6,0.3-2.3,0.6c-1.4,0.7-2.2,1.8-2.6,2.8c-0.5,1-0.6,2-0.6,2.7c0,0.8,0.1,1.4,0.3,1.9c0.3,1,0.7,1.5,1.2,1.5
				c0.4,0,0.9-0.5,1.3-1.4c0.4-0.8,0.9-2,1.5-2.7c0.3-0.4,0.7-0.6,1-0.7c0.3,0,0.5,0,0.9,0.1c0.4,0.2,1,0.5,1.6,1.1
				c0.3,0.3,0.6,0.5,0.8,0.9c0.3,0.3,0.5,0.7,0.8,1.1c1.1,1.5,1.8,3,2.3,4.5c0.5,1.4,0.8,2.8,0.9,4.2c0.1,1.4,0.1,2.9-0.3,4.5
				c-0.3,1.6-0.9,3.4-2.1,5.3c1.4-1.7,2.3-3.4,3-5c0.7-1.6,1.1-3.1,1.3-4.7c0.2-1.6,0.4-3.1,0.2-4.9
				C570.3,294.2,569.9,292.2,568.9,290z"
                />
              </g>
              <g>
                <path
                  d="M548.8,301.3c-1.9-0.3-3.8-0.3-5.2,0c-1.5,0.3-2.6,0.9-2.7,1.8c-0.2,0.9,0.7,1.8,2.3,2.4c1.6,0.6,3.8,0.8,5.9,0.2
				c2.2-0.6,3.6-1.2,5-1.9c1.5-0.7,2.9-1.4,4.7-2.2c-2.1,0.4-3.6,0.5-5.1,0.4C552.1,301.9,550.7,301.7,548.8,301.3z"
                />
              </g>
              <g>
                <path
                  d="M536.3,308.9c-5.8,0.2-11.1,2.8-10.6,4.5c0.4,1.7,6.2,1.7,11.5-0.2c5.5-1.9,7.5-3,13.4-4.3
				C544.5,309.2,542.3,308.8,536.3,308.9z"
                />
              </g>
              <g>
                <path
                  d="M525.6,316.8c-3.9-0.7-7.6,0.8-7.6,2.5c0.1,1.8,4,2.9,7.8,1.8c3.9-1.1,5.3-1.9,9.3-2.6
				C531,318.2,529.6,317.5,525.6,316.8z"
                />
              </g>
              <g>
                <path
                  d="M537.6,319c-2.2,1.1-3.9,2.9-4.8,4.6c-0.9,1.6-1.1,3.1-0.4,3.6c0.7,0.6,2,0.2,3.4-0.7c1.5-0.9,3.1-2.3,4.5-4
				c1.5-1.7,2.6-2.9,3.8-4.1c1.2-1.1,2.5-2.2,4.6-3.4c-2.4,0.7-4,1.3-5.6,1.8C541.5,317.3,539.9,317.8,537.6,319z"
                />
              </g>
              <g>
                <path
                  d="M545.3,322.1c-1.5,1.4-2.5,3.1-2.9,4.6c-0.4,1.5-0.2,2.7,0.6,3.2c0.8,0.4,1.9,0,3-1c1.1-1,2.2-2.5,2.9-4.3
				c1.5-3.7,1.8-5.3,4.1-8.8C549.9,318.6,548.4,319.3,545.3,322.1z"
                />
              </g>
              <g>
                <path
                  d="M554.7,308c-1.3,0.9-2.1,2.2-2.4,3.4c-0.3,1.2,0,2.2,0.7,2.7c0.7,0.5,1.8,0.3,2.7-0.3c1-0.6,1.9-1.7,2.4-3
				c1.1-2.8,1.3-4.1,3.2-6.7C558.7,305.8,557.4,306.2,554.7,308z"
                />
              </g>
              <g>
                <path
                  d="M532.3,341c-1.2,1.5-1.8,3.3-1.8,4.8c0,1.5,0.5,2.5,1.4,2.7c0.9,0.2,1.8-0.4,2.6-1.5c0.8-1,1.5-2.5,1.8-4.2
				c0.3-1.7,0.5-3,0.9-4.2c0.3-1.2,0.8-2.4,1.7-4.1c-1.4,1.3-2.3,2.2-3.3,3.1C534.5,338.6,533.5,339.5,532.3,341z"
                />
              </g>
              <g>
                <path
                  d="M533.2,356.5c-2.1,3.9-1.8,8.5-0.1,8.9c1.7,0.4,3.9-3.4,4.3-7.6c0.4-4.4,0.2-6.1,1.4-10.5
				C536.7,351.3,535.4,352.5,533.2,356.5z"
                />
              </g>
              <g>
                <path
                  d="M533.9,379.3c-0.4,2.4,0.2,4.9,1,6.6c0.8,1.7,2,2.6,2.8,2.3c0.8-0.3,1.2-1.6,1.3-3.2c0.1-1.7-0.2-3.7-0.7-5.8
				c-0.5-2.2-0.8-3.7-1-5.4c-0.2-1.6-0.2-3.3,0.2-5.7c-0.9,2.3-1.5,3.8-2.1,5.5C534.8,375.2,534.2,376.8,533.9,379.3z"
                />
              </g>
              <g>
                <path
                  d="M570.2,359.3c1.9-2.2,3.7-4.2,5-6c1.3-1.7,2.2-3.2,1.7-3.9c-0.5-0.7-2.3-0.5-4.4,0.8c-2.2,1.3-4.6,3.7-6,6.8
				c-1.4,3.1-1.9,5.4-2.3,7.6c-0.4,2.2-0.6,4.5-0.6,7.8c0.5-3.2,1.4-5.3,2.4-7.2C567.1,363.2,568.3,361.5,570.2,359.3z"
                />
              </g>
              <g>
                <path
                  d="M580.7,319.9c1.7-0.7,3-1.7,4-2.7c0.9-1,1.4-2.1,1.1-2.9c-0.4-0.8-1.6-1.1-3.1-0.8c-1.5,0.3-3.3,1.3-4.6,2.8
				c-1.3,1.6-2.1,2.8-2.8,4c-0.8,1.3-1.5,2.6-2.4,4.1c1.4-1.4,2.6-2.1,3.8-2.7C577.8,321.1,579,320.6,580.7,319.9z"
                />
              </g>
              <g>
                <path
                  d="M-6.5,258.1c1.9-0.4,3.5-1.1,4.7-2c1.2-0.9,1.9-1.9,1.6-2.7c-0.2-0.8-1.4-1.3-3-1.2c-1.6,0.1-3.5,0.7-5.2,2
				c-1.7,1.3-2.7,2.3-3.8,3.3c-1,1-2.1,2-3.7,3.4c1.9-0.9,3.2-1.4,4.6-1.7C-9.8,258.7-8.5,258.5-6.5,258.1z"
                />
              </g>
              <g>
                <path
                  d="M8.4,246.2c-1.5,2.6-2.1,5.6-2,7.8c0.1,2.3,0.8,3.7,1.7,3.8c0.9,0.1,1.7-1.2,2.5-3.1c0.8-1.9,1.5-4.3,2-6.9
				c0.5-2.7,1.1-4.5,1.7-6.4c0.7-1.8,1.5-3.7,3.3-6.1c-2.1,2-3.5,3.5-4.9,5.1C11.2,242,9.9,243.6,8.4,246.2z"
                />
              </g>
              <g>
                <path
                  d="M544.8,223.7c-1.8-1.5-3.8-2.7-6-3.5c-2.1-0.9-4.5-1.4-6.9-1.4c-1.2,0-2.4,0.2-3.5,0.6c-1.2,0.4-2.3,0.9-3.2,1.6
				c-1.9,1.4-3.2,3.3-3.7,5.3c-0.3,1-0.3,2-0.2,3.1c0.2,1,0.5,1.8,0.9,2.5c0.9,1.4,2.1,2.3,3.1,2.7c1.1,0.4,2,0.4,2.6,0.2
				c0.6-0.2,0.8-0.4,0.8-0.6c-0.1-0.5-1.1-0.6-2.2-1.7c-1.1-1-2.1-3.1-1.2-5c0.4-1,1.3-2,2.4-2.7c0.6-0.4,1.2-0.6,1.9-0.8
				c0.7-0.2,1.5-0.3,2.2-0.3c3.2,0,7,1.4,10.2,3.5c3.3,2.2,5.7,4.8,7.4,7.5c0.9,1.3,1.6,2.7,2.2,4c0.6,1.4,1.1,2.7,1.5,4.1
				c0.8,2.8,1.2,5.8,1.2,9.2c0,3.4-0.4,7.1-1.6,11.5c1.4-4.3,2.2-8,2.5-11.5c0.3-3.4,0.2-6.5-0.3-9.6c-0.3-1.5-0.6-3.1-1-4.6
				c-0.5-1.5-1.1-3.1-1.9-4.7C550.7,230.1,548.4,226.7,544.8,223.7z"
                />
              </g>
              <g>
                <path
                  d="M547.9,236.8c-0.9-1.3-2.2-2.4-3.6-3.2c-1.5-0.8-3.3-1.2-5-1c-1.8,0.2-3.4,1.2-4.5,2.4c-1.1,1.2-1.7,2.9-1.6,4.4
				c0.1,1.7,1,2.9,1.8,3.6c0.9,0.7,1.9,1.1,2.7,1.1c0.8,0,1.4-0.2,1.8-0.5c0.3-0.3,0.4-0.6,0.3-0.8c-0.2-0.4-0.9-0.6-1.4-1.1
				c-0.3-0.3-0.6-0.6-0.8-1.1c-0.2-0.4-0.3-0.9-0.1-1.2c0.1-0.4,0.4-0.8,0.8-1.2c0.5-0.4,1.1-0.7,1.7-0.7c1.3-0.2,3.2,0.6,4.7,2
				c1.6,1.5,2.6,3.5,3.6,5.3c0.9,1.8,1.7,3.4,2.3,5.1c0.6,1.7,1.2,3.5,1.6,5.5c0.4,2,0.7,4.4,0.8,7.1c0.2-2.8,0.2-5.1,0.1-7.2
				c-0.1-2.1-0.3-4-0.6-5.9c-0.3-1.9-0.7-3.7-1.3-5.8c-0.3-1-0.6-2.1-1.1-3.3C549.5,239.4,548.9,238.1,547.9,236.8z"
                />
              </g>
              <g>
                <path
                  d="M613.1,514.1c0.5,1.2,0.7,2.4,0.8,3.5c0,1.1-0.1,2-0.4,2.7c-0.3,0.7-0.7,1.4-1.3,2.1c-0.6,0.6-1.4,1.3-2.4,1.7
				c-1.9,1-4,1.4-6.1,1.3c-2-0.1-3.6-0.8-4.7-1.5c-1.2-0.9-2-2.1-2.3-3.2c-0.4-1-0.3-2.3,0.1-3.4c0.4-1.1,0.9-2,1.4-2.8
				c0.5-0.8,0.9-1.4,0.9-2.1c0-0.6-0.6-1.3-1.9-1.6c-1.3-0.3-3.3,0-5.5,1.3c-1.1,0.7-2.1,1.6-3.1,2.9c-0.5,0.7-0.9,1.4-1.2,2.2
				c-0.4,0.8-0.6,1.8-0.8,2.7c-0.7,3.8,0.2,8.2,2.9,11.9c2.8,3.9,7.2,6.5,12,7.4c4.7,1,10.1,0.5,14.9-1.9c2.4-1.2,4.7-2.8,6.7-5
				c2-2.2,3.6-4.9,4.4-8c0.8-3,0.8-6.2,0.3-9c-0.6-2.9-1.7-5.4-3.1-7.7c-1.5-2.3-3.3-4.3-5.2-5.9c-1.9-1.6-4.1-2.9-6.1-3.7
				c-4.1-1.7-8-2.3-11.5-2.4c-7.1-0.1-12.9,1.4-18.6,3.1c-5.6,1.8-11.1,4.1-16.8,7.4c-5.7,3.2-11.6,7.4-17.6,13.4
				c6.6-5.4,12.9-8.7,18.8-11c5.9-2.3,11.4-3.5,16.8-4.2c5.4-0.6,11-0.7,16.3,0.5c2.7,0.6,5.3,1.6,7.5,3.1c1.2,0.8,2,1.6,2.8,2.6
				C611.9,511.7,612.6,512.9,613.1,514.1z"
                />
              </g>
              <g>
                <path
                  d="M586.9,487.9c2.8-1.8,5.2-4.2,7.1-6.8c1.9-2.6,3.2-5.3,3.9-7.8c0.7-2.5,0.8-4.7,0.6-6.3c-0.3-1.6-0.9-2.5-1.8-2.7
				c-1.8-0.3-3.7,2.1-6.3,5.3c-1.3,1.7-2.7,3.5-4.3,5.4c-1.6,2-3.3,4.1-5.1,6.3c-1.8,2.3-3.6,4.1-5.2,5.7c-1.7,1.6-3.2,3.1-4.9,4.4
				c-3.3,2.7-6.9,5.3-12.6,8.5c6.1-2.3,10.3-3.7,14.5-5.2c2.1-0.8,4.2-1.6,6.5-2.7C581.6,491.1,584.1,489.8,586.9,487.9z"
                />
              </g>
              <g>
                <path
                  d="M574,520.4c-1.2,2.3-1.3,5.1-0.8,7c0.5,1.9,1.5,3,2.4,2.8c0.9-0.2,1.4-1.4,1.8-3c0.4-1.6,0.7-3.5,0.8-5.6
				c0.2-2.2,0.5-3.7,1-5.3c0.5-1.5,1.3-3,2.9-4.9c-2,1.4-3.3,2.6-4.5,3.9C576.4,516.6,575.3,518,574,520.4z"
                />
              </g>
              <g>
                <path
                  d="M580.2,543.9c0.4,2.6,1.7,5,3.2,6.4c1.5,1.4,2.9,1.9,3.6,1.3c0.7-0.6,0.5-2,0-3.6c-0.5-1.6-1.4-3.6-2.5-5.5
				c-1.1-2-1.8-3.6-2.3-5.2c-0.5-1.6-0.9-3.3-0.8-5.9c-0.6,2.5-0.9,4.3-1.1,6.1C580,539.4,579.8,541.2,580.2,543.9z"
                />
              </g>
              <g>
                <path
                  d="M599.3,558c1.3,1.4,3,2.4,4.7,2.8c1.7,0.5,3.4,0.5,4.8,0.2c1.4-0.3,2.4-0.9,3-1.4c0.6-0.6,0.8-1.1,0.7-1.5
				c-0.4-0.8-2-0.9-3.9-1.2c-2-0.3-4.3-0.9-6.5-2.2c-1.2-0.7-2.1-1.5-2.9-2.3c-0.8-0.8-1.4-1.7-2-2.6c-1.2-1.8-2.1-3.9-3.1-7.1
				c0.5,3.3,0.8,5.6,1.4,7.8c0.3,1.1,0.6,2.3,1.2,3.5C597.2,555.2,598,556.6,599.3,558z"
                />
              </g>
              <g>
                <path
                  d="M613.7,553.2c2.2,1.1,4.7,1.3,6.5,1c1.8-0.3,3-1.2,2.9-2.1c-0.1-0.9-1.2-1.6-2.7-2.1c-1.5-0.5-3.5-0.9-5.5-1
				c-2.2-0.2-3.7-0.4-5.2-0.7c-1.5-0.4-3.1-0.9-5.1-2.2c1.7,1.7,2.9,2.8,4.2,3.9C610.1,551,611.5,552.1,613.7,553.2z"
                />
              </g>
              <g>
                <path
                  d="M623.6,545.9c1.5,0.1,2.9-0.6,3.8-1.4c0.8-0.8,1.1-1.9,0.7-2.6c-0.4-0.8-1.3-1.2-2.4-1.2c-1,0-2.2,0.3-3.3,1
				c-1.1,0.8-1.9,1.3-2.8,1.8c-0.9,0.5-1.8,0.9-3.2,1.2c1.4,0.3,2.5,0.5,3.5,0.7C620.9,545.6,622,545.8,623.6,545.9z"
                />
              </g>
              <g>
                <path
                  d="M584.7,454.4c0.8-4,0.4-8.1-0.6-11.1c-0.9-2.9-2.3-4.6-3.1-4.4c-0.9,0.3-1.1,2.3-1,5c0,2.8,0.2,6.4,0.3,10.1
				c0.1,3.8,0,6.5-0.4,9.1c-0.4,2.7-1,5.4-2.7,9.1c2.2-3.5,3.4-6,4.5-8.6C582.9,461.2,583.9,458.5,584.7,454.4z"
                />
              </g>
              <g>
                <path
                  d="M175.8,236.9c-0.3,3.3,0.7,6.7,2.2,8.7c1.4,2.1,3.1,2.9,3.7,2.4c0.7-0.5,0.5-2.1,0.1-4.1c-0.5-2.3-1-4.4-1.6-7.2
				c-0.5-2.8-0.6-4.8-0.4-6.9c0.2-2,0.7-4.2,2.3-6.9c-2.1,2.4-3.2,4.4-4.2,6.4C177,231.3,176.2,233.5,175.8,236.9z"
                />
              </g>
              <g>
                <path
                  d="M316.4,56.6c3.3,0.8,6.5,1.5,9,2c2.5,0.5,4.4,0.7,4.8-0.1c0.4-0.7-0.8-2.4-3.3-3.8c-2.5-1.5-6.3-2.6-10.1-2.5
				c-3.9,0.1-6.5,0.7-9.1,1.5c-2.5,0.8-5,1.6-8.5,3.4c3.6-1.2,6.2-1.4,8.7-1.5C310.5,55.6,313,55.9,316.4,56.6z"
                />
              </g>
              <g>
                <path
                  d="M166.4,149.7c1.4,3.4,3.9,6.3,6.1,7.9c2.2,1.7,4.1,2.1,4.7,1.5c0.6-0.7-0.3-2.3-1.7-4.4c-1.4-2.1-3.3-4.6-5.3-7.2
				c-2.1-2.7-3.3-4.7-4.4-6.9c-1.1-2.2-2-4.5-2.6-8.1c0.1,3.7,0.4,6.2,0.8,8.7C164.4,143.7,165,146.2,166.4,149.7z"
                />
              </g>
              <g>
                <path
                  d="M79.1,161.1c1.1-2.5,1.1-5.4,0.4-7.4c-0.7-2-1.8-3-2.6-2.8c-0.9,0.3-1.3,1.6-1.5,3.2c-0.3,1.7-0.4,3.7-0.5,5.9
				c-0.1,2.3-0.4,3.9-0.9,5.5c-0.5,1.6-1.3,3.2-3.1,5.1c2.1-1.5,3.4-2.8,4.7-4.2C76.7,165.2,77.9,163.7,79.1,161.1z"
                />
              </g>
              <g>
                <path
                  d="M340.8,260.3c3.5-6.8,4.5-14.6,2.8-15.1c-1.7-0.5-5.1,6.3-6.9,13.5c-0.9,3.8-1.5,6.4-2.2,9c-0.7,2.6-1.5,5.2-2.9,9
				c1.9-3.5,3.3-5.9,4.7-8.3C337.6,266.1,339,263.8,340.8,260.3z"
                />
              </g>
              <g>
                <path
                  d="M485,235.3c3.3,0,6.5-0.3,9-0.8c2.4-0.5,4.1-1.2,4.2-2.1c0-0.9-1.7-1.7-4.2-2.1c-2.6-0.4-6.1-0.3-9.4,0.6
				c-3.4,0.9-5.7,1.8-8,2.7c-2.4,1-4.7,1.9-7.8,3.1c3.5-0.8,5.9-1.1,8.3-1.3C479.3,235.3,481.6,235.3,485,235.3z"
                />
              </g>
              <g>
                <path
                  d="M596.5,141.5c-2.7,0.3-5.3,1.3-7,2.5c-1.7,1.2-2.6,2.4-2.2,3.2c0.4,0.8,1.9,0.9,3.8,0.6c1.9-0.3,4.3-1,6.5-2.1
				c2.4-1.1,4-1.9,5.8-2.5c1.7-0.7,3.5-1.2,6.3-1.7c-2.6-0.1-4.5-0.1-6.6-0.2C601.2,141.3,599.3,141.2,596.5,141.5z"
                />
              </g>
              <g>
                <path
                  d="M548.5,79c-2.4,1.8-4.6,3.6-6.2,5.2c-1.6,1.6-2.6,2.9-2.2,3.7c0.4,0.8,2.2,0.8,4.5-0.2c2.3-1,5.1-3,7.1-5.6
				c2-2.7,3.1-4.8,4-6.9c0.9-2.1,1.8-4.2,2.9-7.4c-1.5,3-3,4.7-4.5,6.3C552.6,75.7,550.9,77.1,548.5,79z"
                />
              </g>
              <g>
                <path
                  d="M482.8,101.2c3.5-0.6,7-1.5,9.5-2.4c2.6-0.9,4.3-1.9,4.2-2.8c-0.1-0.9-2.1-1.4-4.9-1.3c-2.8,0.1-6.6,0.8-10,2.3
				c-3.6,1.5-5.9,2.8-8.2,4.1c-2.3,1.3-4.6,2.7-7.9,4.7c3.6-1.5,6.1-2.3,8.6-2.9C476.6,102.3,479.2,101.9,482.8,101.2z"
                />
              </g>
              <g>
                <path
                  d="M503.3,526.1c2.5-1.7,3.3-4.9,2-6c-1.4-1.1-4.3,0.4-5.4,3.3c-1.2,2.9-1.3,4.3-2.8,7.1
				C499.4,528.3,500.7,527.9,503.3,526.1z"
                />
              </g>
              <g>
                <path
                  d="M281.3,518.4c2,0.3,3.8,0.4,5.3,0.2c1.5-0.1,2.7-0.5,3-1.3c0.3-0.8-0.5-2-2.2-2.8c-1.6-0.8-4.1-1.2-6.5-0.5
				c-2.4,0.7-3.9,1.5-5.3,2.3c-1.4,0.9-2.8,1.8-4.6,3.2c2.1-1,3.6-1.3,5.1-1.4C277.8,518,279.3,518.1,281.3,518.4z"
                />
              </g>
            </g>
          </g>
          <g id="Peddle10">
            <defs>
              <path
                id="SVGID_00000143589410881028119580000010702619693161348259_"
                d="M436.5,139.9c-18.4,44.3-125,221.9-159.4,207.6
			c-34.3-14.3,16.7-215.1,35.1-259.4c18.4-44.3,78.8-98,108.6-85.6C450.5,14.9,454.9,95.6,436.5,139.9z"
              />
            </defs>
            <use
              xlinkHref="#SVGID_00000143589410881028119580000010702619693161348259_"
              overflow="visible"
              fill="#003301"
            />
            <clipPath id="SVGID_00000027602900306344679350000005422911552837306800_">
              <use
                xlinkHref="#SVGID_00000143589410881028119580000010702619693161348259_"
                overflow="visible"
              />
            </clipPath>
            <g
              opacity="0.1"
              clipPath="url(#SVGID_00000027602900306344679350000005422911552837306800_)"
            >
              <g>
                <g>
                  <path
                    d="M312.1,219.6c8.1-1.2,15.4-3.8,20.8-7c5.4-3.2,8.8-6.9,8.1-10.4c-0.7-3.4-5.5-5.7-12.2-5.8c-6.7-0.1-15.4,2-22.9,6.8
					c-7.7,4.9-12.4,9-17.2,13c-4.7,4-9.4,8-16.3,13.7c8.1-3.8,13.9-5.6,19.8-6.9C297.9,221.7,303.8,220.8,312.1,219.6z"
                  />
                </g>
                <g>
                  <path
                    d="M314.5,239.9c7,1.8,13.9,2.1,19.5,1.1c5.5-1,9.7-3.2,10.5-6.6c0.8-3.4-2.4-7.3-8.1-9.9c-5.6-2.6-13.8-3.8-21.8-2.1
					c-8.2,1.8-13.5,3.9-18.8,6c-5.3,2.1-10.5,4.2-18,7.1c8-0.8,13.5-0.4,18.9,0.5C302,236.8,307.2,238.1,314.5,239.9z"
                  />
                </g>
                <g>
                  <path
                    d="M303.9,258.7c4.2,3.4,8.8,5.1,13,5.4c4.2,0.3,8.1-0.9,10-3.9c1.9-3,1.1-7.2-2.1-10.9c-3.1-3.7-8.6-6.8-14.9-7.1
					c-6.5-0.4-10.9,0.2-15.2,0.7c-4.3,0.5-8.6,1-14.6,1.5c5.8,1.7,9.5,3.7,12.9,5.9C296.4,252.6,299.5,255.2,303.9,258.7z"
                  />
                </g>
                <g>
                  <path
                    d="M282.6,276.1c1.5,4.7,4.5,8,8,9.7c3.4,1.7,7.3,1.9,10.2-0.1c2.9-2,4.1-5.7,3.3-9.8c-0.7-4-3.4-8.3-7.9-11
					c-4.6-2.8-8.1-4.2-11.5-5.8c-3.4-1.6-6.6-3.2-10.9-6.1c2.8,4.3,4.2,7.7,5.3,11.2C280.2,267.7,281.1,271.2,282.6,276.1z"
                  />
                </g>
                <g>
                  <path
                    d="M262,284.1c-1.5,5-1.3,9.6,0.1,13.5c1.3,3.8,3.8,7,7.3,7.6c3.4,0.7,7.1-1.5,9.3-5.8c2.2-4.2,3-10.5,0.8-16.4
					c-2.3-6-4.6-9.7-6.8-13.4c-2.3-3.6-4.5-7.1-7.6-12.2c1,5.8,0.8,9.9,0.1,13.8C264.5,275.2,263.5,279,262,284.1z"
                  />
                </g>
                <g>
                  <path
                    d="M238.4,276.8c-4.1,3.3-6.7,7-8.2,10.8c-0.3,1-0.7,1.9-0.9,2.9c-0.2,1-0.3,1.9-0.3,2.8c0,1.8,0.4,3.6,1.4,5
					c1,1.4,2.6,2.3,4.6,2.6c1,0.1,2.1,0.1,3.2-0.1c1.1-0.2,2.4-0.5,3.5-1.1c4.8-2.1,9.8-7.2,11.7-13.9c1.9-6.8,2-11.6,2-16.2
					c-0.1-4.6-0.3-9.1-0.8-15.3c-1.6,6-3.8,9.7-6.4,12.9C245.6,270.5,242.6,273.3,238.4,276.8z"
                  />
                </g>
                <g>
                  <path
                    d="M221.4,254.8c-5.9,0.7-10.8,2.7-14.6,5.4c-3.7,2.7-6.2,6.2-5.7,9.7c0.5,3.5,4.2,6.1,9.6,6.6c5.3,0.5,12.3-1.2,17.7-5.6
					c5.6-4.6,8.8-8.3,11.8-12c3-3.7,5.9-7.3,10.2-12.6c-5.6,3.6-10,5.2-14.3,6.3C231.8,253.5,227.4,254.1,221.4,254.8z"
                  />
                </g>
                <g>
                  <path
                    d="M214.7,232.3c-5.8-1.9-11.2-2.4-15.9-1.9c-1.2,0.2-2.3,0.3-3.3,0.7c-1.1,0.3-2,0.7-2.9,1.1c-1.7,0.9-3.1,2.2-3.7,3.8
					c-0.6,1.6-0.4,3.5,0.5,5.3c0.5,0.9,1.2,1.9,2,2.7c0.9,0.9,1.9,1.8,3.1,2.5c4.8,3.1,12.4,4.8,19.7,3.2c7.5-1.7,12.3-4,16.9-6.2
					c4.5-2.3,9-4.7,15-8.3c-6.9,1.6-11.7,1.4-16.4,0.8C225.1,235.5,220.6,234.3,214.7,232.3z"
                  />
                </g>
                <g>
                  <path
                    d="M221.6,214.1c-3.8-4.1-7-7.1-10.2-9.4c-1.6-1.2-3.2-2.2-4.8-3.1c-1.6-0.9-3.3-1.6-5.1-1.4c-1.7,0.1-3.3,1.2-4.4,3.3
					c-1.1,2.1-1.4,5.3-0.6,8.8c0.8,3.5,2.7,7.3,5.7,10.4c2.9,3.1,6.8,5.6,11,6.8c4.4,1.3,7.9,1.6,11.1,1.7c3.2,0.1,5.9-0.1,8.6-0.4
					c5.4-0.6,10.5-1.4,17.9-2.1c-7.3-1.5-12.2-3.2-16.6-5.4c-2.2-1.1-4.3-2.4-6.4-3.9C225.6,217.9,223.5,216.2,221.6,214.1z"
                  />
                </g>
                <g>
                  <path
                    d="M239.5,196.3c-0.1-5.5-0.9-9.8-2.3-13.6c-0.7-1.9-1.5-3.7-2.5-5.3c-1-1.6-2.2-2.9-3.9-3.6c-1.6-0.7-3.5-0.5-5.4,0.6
					c-1.9,1.1-3.7,3.3-4.9,6.3c-1.2,3-1.8,6.7-1.4,10.5c0.4,3.8,1.7,7.8,3.9,11.1c2.3,3.5,4.6,5.9,6.7,7.9c2.2,2,4.2,3.6,6.3,5.1
					c4.1,3,8.3,5.7,14,9.6c-4.3-5.5-6.5-9.9-8-14.2c-0.7-2.2-1.3-4.4-1.8-6.8C239.9,201.7,239.6,199.1,239.5,196.3z"
                  />
                </g>
                <g>
                  <path
                    d="M259.2,191.2c2.3-5.4,3.1-10.6,2.9-15.2c-0.1-1.2-0.2-2.2-0.4-3.3c-0.2-1.1-0.5-2-0.9-2.9c-0.8-1.8-1.9-3.2-3.5-4
					c-1.6-0.8-3.4-0.8-5.3,0c-1,0.4-2,1-2.9,1.8c-0.9,0.8-1.9,1.7-2.8,2.9c-3.5,4.5-5.8,11.9-4.6,19.3c1.3,7.6,3.3,12.4,5.3,17
					c2.1,4.6,4.3,9,7.7,15c-1.4-6.8-1-11.6-0.1-16C255.5,201.1,256.9,196.9,259.2,191.2z"
                  />
                </g>
                <g>
                  <path
                    d="M281.1,191.4c4.3-4.1,7.2-8,9.3-11.7c1-1.9,1.9-3.7,2.4-5.6c0.5-1.8,0.7-3.7,0-5.3c-0.6-1.6-2.1-2.9-4.3-3.4
					c-2.2-0.6-5.1-0.3-8.2,0.8c-3.1,1.2-6.3,3.3-8.9,6.2c-2.6,2.9-4.7,6.5-5.8,10.5c-1.2,4-1.6,7.4-1.8,10.4c-0.2,3-0.2,5.7-0.2,8.3
					c0.1,5.2,0.3,10.3,0,17.6c2.4-6.9,4.7-11.5,7.3-15.6c1.3-2.1,2.8-4,4.4-6C277.1,195.6,278.9,193.5,281.1,191.4z"
                  />
                </g>
                <g>
                  <path
                    d="M256.7,233.3c-1.5,3.2,1.5,6,5,5.6c3.5-0.4,5.8-3.7,3.7-6.5c-2.2-2.9-3.7-3.9-5.1-7C259.6,228.7,258.2,230,256.7,233.3z
					"
                  />
                </g>
                <g>
                  <path
                    d="M261.4,230.6c-1.4,1-1.8,2.3-1.5,3.7c0.3,1.4,1.3,2.8,2.8,3.6c1.5,0.9,3.3,0.9,4.7,0.2c1.4-0.7,2.3-2.2,2.1-4.1
					c-0.2-2-0.6-3.3-0.9-4.7c-0.3-1.3-0.6-2.6-0.7-4.4c-0.9,1.5-1.9,2.4-2.9,3.1C263.9,228.9,262.8,229.6,261.4,230.6z"
                  />
                </g>
                <g>
                  <path
                    d="M264.9,232.2c-1.3-0.6-2.1-0.2-2.9,0.7c-0.7,0.9-1.4,2.4-1.5,4.2c-0.1,1.7,0.7,3.3,2.1,4.1c1.4,0.8,3.3,0.7,4.8-0.7
					c1.5-1.4,2.2-2.7,2.8-3.8c0.6-1.2,1.2-2.3,2.1-3.6c-1.5,0.5-2.7,0.5-3.8,0.3C267.3,233.2,266.2,232.7,264.9,232.2z"
                  />
                </g>
                <g>
                  <path
                    d="M267.4,236.8c0-0.7-0.3-1.3-0.7-1.8c-0.5-0.5-1.1-1-1.9-1.2c-0.8-0.3-1.6-0.3-2.4-0.1c-0.8,0.2-1.5,0.6-2.1,1.3
					c-0.6,0.6-1.1,1.3-1.4,2c-0.3,0.7-0.6,1.4-0.6,2c0,0.7,0.1,1.3,0.5,2c0.4,0.6,1.1,1.3,2.1,1.7c1,0.5,2.1,0.8,3,1
					c0.9,0.2,1.7,0.2,2.5,0.1c1.6-0.1,3.1-0.5,4.5-1.6c-0.8,0.1-1.5-0.2-2-0.6c-0.5-0.4-0.8-0.8-1-1.3c-0.2-0.5-0.3-1-0.4-1.6
					C267.4,238,267.5,237.5,267.4,236.8z"
                  />
                </g>
                <g>
                  <path
                    d="M266.9,240c0.8-1.4,0.7-2.5-0.1-3.6c-0.7-1.1-2-2.2-3.8-2.6c-1.7-0.4-3.4,0.1-4.5,1.4c-1.1,1.3-1.4,3.5-0.4,5.4
					c1.1,2,2.1,3.1,3.2,4.1c1,1,2,2,3.2,3.4c-0.2-1.8,0-3.1,0.5-4.3C265.4,242.6,266.1,241.5,266.9,240z"
                  />
                </g>
                <g>
                  <path
                    d="M264.2,244.1c1.7-1.3,2.4-3.2,2.2-4.9c-0.2-1.7-1.4-3.1-3.1-3.7c-1.7-0.6-3.4-0.3-4.7,0.5c-1.3,0.8-2.1,2-2.1,3.8
					c0,1.9,0,3.3,0,4.7c-0.1,1.4-0.4,2.8-1.2,4.5c1.6-1,2.9-1.7,4.3-2.3C260.9,246.1,262.4,245.4,264.2,244.1z"
                  />
                </g>
                <g>
                  <path
                    d="M258.2,243.2c3.6,1.4,6.6-1.7,6.2-5.2c-0.4-3.5-4.2-5.7-7.3-3.5c-3.2,2.3-4.3,3.9-7.7,5.4
					C253,240.5,254.5,241.8,258.2,243.2z"
                  />
                </g>
                <g>
                  <path
                    d="M254.5,239.4c1.8,3.2,5.8,2.6,7.7-0.4c1.8-3,0.5-6.9-3.1-7c-3.7-0.1-5.5,0.5-8.9-0.3
					C252.5,234.3,252.7,236.1,254.5,239.4z"
                  />
                </g>
                <g>
                  <path
                    d="M255,235.1c0,1.7,1.1,2.7,2.4,3c1.3,0.3,3-0.2,4.3-1.3c1.4-1.1,2.1-2.6,2.3-3.8c0.1-1.2-0.4-2.3-1.8-2.8
					c-2.9-1-4.7-1.1-6.9-3C255.8,230.1,255,231.7,255,235.1z"
                  />
                </g>
                <g>
                  <path
                    d="M299.7,201.9c5.7-1.8,10.2-4.8,13.4-8.2c3.2-3.4,5-7.2,3.9-10.6c-1.1-3.3-5.3-5.2-10.8-4.6c-5.4,0.5-12,3.6-16.6,9.1
					c-4.7,5.7-7.1,10.1-9.4,14.4c-2.2,4.4-4.4,8.6-7.4,14.7c4.8-4.8,8.8-7.3,12.8-9.3C289.6,205.4,293.8,203.9,299.7,201.9z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M624.2,225.2c3.8,4.2,8.2,7.3,12.1,9.2c3.9,1.8,7.4,2.3,9.1,0.6c1.7-1.7,1.1-5.3-1.4-9.3c-2.6-3.9-7.1-8.1-12.8-10.5
					c-5.8-2.5-10-3.6-14.1-4.8c-4.1-1.1-8.3-2.3-14.2-4.1c5.3,3.1,8.6,5.8,11.6,8.7C617.5,217.9,620.3,220.9,624.2,225.2z"
                  />
                </g>
                <g>
                  <path
                    d="M613.5,234.5c1.7,4.7,4.2,8.8,6.9,11.6c2.7,2.8,5.6,4.4,7.9,3.4c2.2-0.9,3.3-4.2,2.6-8.5c-0.7-4.2-3.2-9.4-7.3-13.3
					c-4.2-4-7.5-6.2-10.7-8.5c-3.3-2.2-6.5-4.4-11.1-7.6c3.6,4.3,5.5,7.6,7.1,11C610.4,226.1,611.7,229.6,613.5,234.5z"
                  />
                </g>
                <g>
                  <path
                    d="M598.5,235.7c-0.3,3.7,0.5,7,2,9.6c1.5,2.5,3.7,4.3,6.1,4.2c2.4-0.1,4.5-2.2,5.4-5.4c0.9-3.2,0.5-7.6-1.7-11.3
					c-2.3-3.8-4.3-6.2-6.3-8.5c-2-2.3-3.9-4.5-6.5-7.8c1.3,4,1.6,6.8,1.6,9.7C599.1,229,598.9,231.8,598.5,235.7z"
                  />
                </g>
                <g>
                  <path
                    d="M580.4,230.2c-2.1,2.7-2.8,5.7-2.5,8.4c0.3,2.6,1.7,4.9,4,5.8c2.3,0.9,4.9,0.1,6.9-1.9c2-2,3.4-5.2,3.2-8.8
					c-0.2-3.7-0.7-6.3-1.1-8.9c-0.4-2.5-0.7-5.1-0.8-8.6c-1.4,3.3-2.8,5.4-4.3,7.4C584.2,225.6,582.5,227.5,580.4,230.2z"
                  />
                </g>
                <g>
                  <path
                    d="M567.7,221.5c-3.4,1.1-6,3-7.7,5.3c-1.7,2.3-2.5,4.9-1.5,7.2c0.9,2.2,3.6,3.5,7,3.1c3.3-0.4,7.2-2.4,9.7-5.9
					c2.6-3.6,3.8-6.4,5-9.1c1.2-2.7,2.3-5.4,4-9.1c-2.9,2.8-5.4,4.3-7.8,5.4C573.9,219.5,571.3,220.4,567.7,221.5z"
                  />
                </g>
                <g>
                  <path
                    d="M562.8,205.2c-3.5-1.1-6.6-1.1-9.4-0.5c-0.7,0.2-1.4,0.3-2,0.6c-0.6,0.3-1.2,0.6-1.7,0.9c-1.1,0.7-1.9,1.6-2.3,2.8
					c-0.4,1.1-0.3,2.4,0.3,3.6c0.3,0.6,0.7,1.2,1.3,1.8c0.6,0.6,1.2,1.1,2,1.6c3.1,1.9,8,2.8,12.5,1.3c4.6-1.6,7.4-3.3,10.1-5.1
					c2.6-1.8,5.1-3.7,8.4-6.4c-4.1,1.4-7.1,1.6-9.9,1.4C569.2,206.9,566.4,206.2,562.8,205.2z"
                  />
                </g>
                <g>
                  <path
                    d="M568.7,186.9c-2.6-3.1-5.7-5.2-8.7-6.3c-3-1.1-5.9-1.1-7.7,0.5c-1.8,1.6-1.9,4.8-0.1,8.1c1.8,3.3,5.4,6.6,10.1,8
					c4.8,1.4,8.2,1.8,11.5,2.1c3.3,0.3,6.5,0.5,11.2,0.9c-4.3-1.8-6.8-3.7-9.1-5.8C573.6,192.4,571.5,190.1,568.7,186.9z"
                  />
                </g>
                <g>
                  <path
                    d="M579.1,174.3c-1.1-4.1-3-7.4-5.1-9.9c-0.6-0.6-1.1-1.2-1.7-1.7c-0.6-0.5-1.2-0.9-1.8-1.2c-1.2-0.6-2.4-0.9-3.6-0.7
					c-1.2,0.3-2.2,1.1-2.8,2.4c-0.3,0.6-0.6,1.4-0.8,2.2c-0.2,0.8-0.3,1.8-0.2,2.8c0.1,4,2,9,5.8,12.6c3.9,3.7,7,5.5,10.1,7.2
					c3.1,1.7,6.2,3.3,10.6,5.4c-3.6-3.3-5.4-6.2-6.8-9.1C581.3,181.5,580.3,178.5,579.1,174.3z"
                  />
                </g>
                <g>
                  <path
                    d="M592.2,171.1c0.9-3.8,1.3-6.8,1.4-9.5c0.1-1.3,0-2.7-0.1-4c-0.1-1.3-0.4-2.5-1.2-3.5c-0.8-0.9-2-1.4-3.6-1.2
					c-1.6,0.2-3.6,1.2-5.3,3c-1.7,1.8-3.1,4.4-3.7,7.3c-0.6,2.9-0.6,6,0.3,8.9c0.9,3,2.1,5.2,3.3,7c1.2,1.8,2.4,3.3,3.6,4.8
					c2.4,2.9,4.9,5.5,8.1,9.5c-2-4.8-2.9-8.3-3.3-11.7c-0.2-1.7-0.3-3.4-0.3-5.2C591.6,175,591.7,173.1,592.2,171.1z"
                  />
                </g>
                <g>
                  <path
                    d="M609.4,174.5c3.1-2.2,5.3-4.3,6.9-6.6c0.8-1.1,1.5-2.3,2.1-3.5c0.5-1.2,0.8-2.4,0.6-3.6c-0.2-1.2-1.1-2.2-2.5-2.8
					c-1.4-0.6-3.3-0.8-5.5-0.4c-2.2,0.4-4.5,1.5-6.6,3.3c-2,1.7-3.8,4-4.8,6.5c-1.1,2.7-1.6,4.9-1.9,6.9c-0.3,2-0.4,3.8-0.5,5.6
					c-0.1,3.5,0,6.9-0.1,11.8c1.5-4.6,3.1-7.6,5.1-10.1c1-1.3,2-2.5,3.2-3.6C606.4,176.8,607.8,175.6,609.4,174.5z"
                  />
                </g>
                <g>
                  <path
                    d="M619.9,183.9c4-0.8,7.3-2.3,9.9-4.3c0.6-0.5,1.2-1,1.7-1.5c0.5-0.5,1-1.1,1.3-1.6c0.7-1.1,1.1-2.3,1-3.6
					c-0.2-1.2-0.9-2.3-2.1-3.1c-0.6-0.4-1.3-0.8-2.1-1c-0.8-0.2-1.7-0.4-2.7-0.5c-3.9-0.3-9.1,1.3-12.8,4.9
					c-3.8,3.7-5.8,6.7-7.7,9.6c-1.8,3-3.5,6-5.6,10.2c3.4-3.4,6.2-5.1,9.1-6.3C612.8,185.6,615.8,184.7,619.9,183.9z"
                  />
                </g>
                <g>
                  <path
                    d="M628.3,196.5c4,0.9,7.4,1,10.3,0.8c1.5-0.1,2.9-0.4,4.1-0.8c1.2-0.4,2.4-1,3.1-2c0.7-1,0.8-2.3,0.3-3.8
					c-0.5-1.5-1.8-3-3.7-4.4c-1.9-1.3-4.3-2.3-7-2.7c-2.7-0.4-5.6-0.2-8.3,0.7c-2.8,0.9-4.9,2-6.7,3c-1.8,1-3.3,2.1-4.8,3.1
					c-3,2.1-5.8,4.2-10.1,6.8c4.9-1.3,8.4-1.8,11.8-1.8c1.7,0,3.4,0,5.2,0.2C624.3,195.8,626.3,196,628.3,196.5z"
                  />
                </g>
                <g>
                  <path
                    d="M594.8,198.8c-2.4,0.4-2.8,3.2-1.3,5c1.6,1.9,4.4,1.9,5.2-0.4c0.8-2.4,0.8-3.7,2-5.7
					C598.6,198.6,597.3,198.3,594.8,198.8z"
                  />
                </g>
                <g>
                  <path
                    d="M598.2,200.4c-1.1-0.4-2-0.1-2.7,0.6c-0.7,0.7-1.1,1.8-1,3c0.1,1.2,0.8,2.2,1.7,2.7c0.9,0.5,2.2,0.5,3.2-0.4
					c1-0.9,1.7-1.6,2.3-2.3c0.6-0.7,1.3-1.4,2.2-2.1c-1.2,0-2.1-0.2-2.9-0.4C600.2,201.2,599.3,200.8,598.2,200.4z"
                  />
                </g>
                <g>
                  <path
                    d="M598.6,203c-0.2-0.9-0.7-1.3-1.5-1.4c-0.8-0.1-1.9,0.1-3,0.8c-1,0.6-1.7,1.7-1.6,2.8c0.1,1.1,0.9,2.2,2.2,2.5
					c1.4,0.3,2.4,0.2,3.3,0.1c0.9-0.1,1.8-0.2,2.9-0.2c-0.9-0.7-1.3-1.4-1.6-2.1C599,204.8,598.8,204,598.6,203z"
                  />
                </g>
                <g>
                  <path
                    d="M597,206.3c0.4-0.3,0.6-0.7,0.8-1.1c0.1-0.5,0.1-1,0-1.6c-0.1-0.5-0.4-1.1-0.9-1.4c-0.4-0.4-1-0.6-1.6-0.7
					c-0.6-0.1-1.2-0.1-1.7,0c-0.5,0.1-1,0.2-1.4,0.4c-0.4,0.2-0.7,0.6-0.9,1c-0.2,0.5-0.3,1.1-0.2,1.9c0.1,0.8,0.3,1.5,0.6,2.1
					c0.3,0.6,0.6,1.1,0.9,1.5c0.7,0.8,1.5,1.6,2.7,2c-0.4-0.5-0.5-1-0.4-1.4c0-0.4,0.2-0.8,0.4-1.1c0.2-0.3,0.5-0.6,0.7-0.9
					C596.3,206.7,596.6,206.6,597,206.3z"
                  />
                </g>
                <g>
                  <path
                    d="M594.9,207.2c1.1-0.1,1.7-0.6,2.1-1.5c0.3-0.8,0.5-2,0-3.2c-0.4-1.1-1.4-1.9-2.6-2c-1.2-0.1-2.5,0.5-3.2,1.9
					c-0.7,1.4-0.9,2.4-1.1,3.4c-0.2,1-0.4,1.9-0.7,3.2c0.9-0.8,1.8-1.2,2.7-1.4C592.9,207.4,593.8,207.3,594.9,207.2z"
                  />
                </g>
                <g>
                  <path
                    d="M591.5,207.3c1.4,0.5,2.8,0.1,3.7-0.7c0.9-0.8,1.2-2,0.9-3.2c-0.3-1.2-1.2-2.1-2.1-2.5c-0.9-0.4-2-0.4-3,0.3
					c-1.1,0.7-1.9,1.3-2.7,1.8c-0.8,0.5-1.7,0.9-3.1,1.1c1.2,0.5,2.1,1,3,1.6C589.1,206.1,590.1,206.7,591.5,207.3z"
                  />
                </g>
                <g>
                  <path
                    d="M589.7,203.4c0.6,2.6,3.6,3.1,5.4,1.5c1.8-1.6,1.7-4.6-0.8-5.5c-2.6-1-3.9-1-6.1-2.3
					C589.3,199.5,589.1,200.8,589.7,203.4z"
                  />
                </g>
                <g>
                  <path
                    d="M590.5,199.9c-1.1,2.2,0.8,4.3,3.2,4.2c2.4-0.1,4.2-2.4,2.8-4.5c-1.4-2.2-2.4-3-3.3-5.2
					C592.6,196.7,591.6,197.6,590.5,199.9z"
                  />
                </g>
                <g>
                  <path
                    d="M593.1,198.5c-0.9,0.7-1.1,1.7-0.8,2.5c0.4,0.9,1.3,1.6,2.4,2c1.2,0.3,2.3,0.2,3.1-0.2c0.8-0.4,1.2-1.1,0.9-2.1
					c-0.5-2-1.2-3.1-0.9-5.1C596.3,197,595.1,197.2,593.1,198.5z"
                  />
                </g>
                <g>
                  <path
                    d="M629.5,211.2c3.3,2.5,6.7,4,9.9,4.5c3.2,0.5,6.1,0.1,7.6-1.9c1.5-1.9,0.9-5.1-1.5-8c-2.4-2.9-6.8-5.5-11.7-6
					c-5.1-0.5-8.5-0.1-11.9,0.2c-3.4,0.4-6.7,0.8-11.3,1.5c4.6,0.9,7.6,2.2,10.3,3.7C623.6,206.8,626.1,208.6,629.5,211.2z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M241.1,523.3c3.8,4.2,8.2,7.3,12.1,9.2c3.9,1.8,7.4,2.3,9.1,0.6c1.7-1.7,1.1-5.3-1.4-9.3c-2.6-3.9-7.1-8.1-12.8-10.5
					c-5.8-2.5-10-3.6-14.1-4.8c-4.1-1.1-8.3-2.3-14.2-4.1c5.3,3.1,8.6,5.8,11.6,8.7C234.3,516,237.1,519,241.1,523.3z"
                  />
                </g>
                <g>
                  <path
                    d="M230.3,532.5c1.7,4.7,4.2,8.8,6.9,11.6c2.7,2.8,5.6,4.4,7.9,3.4c2.2-0.9,3.3-4.2,2.6-8.5c-0.7-4.2-3.2-9.4-7.3-13.3
					c-4.2-4-7.5-6.2-10.7-8.5c-3.3-2.2-6.5-4.4-11.1-7.6c3.6,4.3,5.5,7.6,7.1,11C227.3,524.1,228.6,527.6,230.3,532.5z"
                  />
                </g>
                <g>
                  <path
                    d="M215.4,533.7c-0.3,3.7,0.5,7,2,9.6c1.5,2.5,3.7,4.3,6.1,4.2c2.4-0.1,4.5-2.2,5.4-5.4c0.9-3.2,0.5-7.6-1.7-11.3
					c-2.3-3.8-4.3-6.2-6.3-8.5c-2-2.3-3.9-4.5-6.5-7.8c1.3,4,1.6,6.8,1.6,9.7C216,527.1,215.7,529.9,215.4,533.7z"
                  />
                </g>
                <g>
                  <path
                    d="M197.2,528.3c-2.1,2.7-2.8,5.7-2.5,8.4c0.3,2.6,1.7,4.9,4,5.8c2.3,0.9,4.9,0.1,6.9-1.9c2-2,3.4-5.2,3.2-8.8
					c-0.2-3.7-0.7-6.3-1.1-8.9c-0.4-2.5-0.7-5.1-0.8-8.6c-1.4,3.3-2.8,5.4-4.3,7.4C201.1,523.7,199.4,525.5,197.2,528.3z"
                  />
                </g>
                <g>
                  <path
                    d="M184.6,519.6c-3.4,1.1-6,3-7.7,5.3c-1.7,2.3-2.5,4.9-1.5,7.2c0.9,2.2,3.6,3.5,7,3.1c3.3-0.4,7.2-2.4,9.7-5.9
					c2.6-3.6,3.8-6.4,5-9.1c1.2-2.7,2.3-5.4,4-9.1c-2.9,2.8-5.4,4.3-7.8,5.4C190.7,517.6,188.1,518.4,184.6,519.6z"
                  />
                </g>
                <g>
                  <path
                    d="M179.7,503.2c-3.5-1.1-6.6-1.1-9.4-0.5c-0.7,0.2-1.4,0.3-2,0.6c-0.6,0.3-1.2,0.6-1.7,0.9c-1.1,0.7-1.9,1.6-2.3,2.8
					c-0.4,1.1-0.3,2.4,0.3,3.6c0.3,0.6,0.7,1.2,1.3,1.8c0.6,0.6,1.2,1.1,2,1.6c3.1,1.9,8,2.8,12.5,1.3c4.6-1.6,7.4-3.3,10.1-5.1
					c2.6-1.8,5.1-3.7,8.4-6.4c-4.1,1.4-7.1,1.6-9.9,1.4C186,504.9,183.3,504.3,179.7,503.2z"
                  />
                </g>
                <g>
                  <path
                    d="M185.6,484.9c-2.6-3.1-5.7-5.2-8.7-6.3c-3-1.1-5.9-1.1-7.7,0.5c-1.8,1.6-1.9,4.8-0.1,8.1c1.8,3.3,5.4,6.6,10.1,8
					c4.8,1.4,8.2,1.8,11.5,2.1c3.3,0.3,6.5,0.5,11.2,0.9c-4.3-1.8-6.8-3.7-9.1-5.8C190.4,490.4,188.4,488.1,185.6,484.9z"
                  />
                </g>
                <g>
                  <path
                    d="M195.9,472.3c-1.1-4.1-3-7.4-5.1-9.9c-0.6-0.6-1.1-1.2-1.7-1.7c-0.6-0.5-1.2-0.9-1.8-1.2c-1.2-0.6-2.4-0.9-3.6-0.7
					c-1.2,0.3-2.2,1.1-2.8,2.4c-0.3,0.6-0.6,1.4-0.8,2.2c-0.2,0.8-0.3,1.8-0.2,2.8c0.1,4,2,9,5.8,12.6c3.9,3.7,7,5.5,10.1,7.2
					c3.1,1.7,6.2,3.3,10.6,5.4c-3.6-3.3-5.4-6.2-6.8-9.1C198.2,479.5,197.1,476.5,195.9,472.3z"
                  />
                </g>
                <g>
                  <path
                    d="M209.1,469.2c0.9-3.8,1.3-6.8,1.4-9.5c0.1-1.3,0-2.7-0.1-4c-0.1-1.3-0.4-2.5-1.2-3.5c-0.8-0.9-2-1.4-3.6-1.2
					c-1.6,0.2-3.6,1.2-5.3,3c-1.7,1.8-3.1,4.4-3.7,7.3c-0.6,2.9-0.6,6,0.3,8.9c0.9,3,2.1,5.2,3.3,7c1.2,1.8,2.4,3.3,3.6,4.8
					c2.4,2.9,4.9,5.5,8.1,9.5c-2-4.8-2.9-8.3-3.3-11.7c-0.2-1.7-0.3-3.4-0.3-5.2C208.4,473,208.6,471.1,209.1,469.2z"
                  />
                </g>
                <g>
                  <path
                    d="M226.2,472.6c3.1-2.2,5.3-4.3,6.9-6.6c0.8-1.1,1.5-2.3,2.1-3.5c0.5-1.2,0.8-2.4,0.6-3.6c-0.2-1.2-1.1-2.2-2.5-2.8
					c-1.4-0.6-3.3-0.8-5.5-0.4c-2.2,0.4-4.5,1.5-6.6,3.3c-2,1.7-3.8,4-4.8,6.5c-1.1,2.7-1.6,4.9-1.9,6.9c-0.3,2-0.4,3.8-0.5,5.6
					c-0.1,3.5,0,6.9-0.1,11.8c1.5-4.6,3.1-7.6,5.1-10.1c1-1.3,2-2.5,3.2-3.6C223.3,474.9,224.6,473.7,226.2,472.6z"
                  />
                </g>
                <g>
                  <path
                    d="M236.8,481.9c4-0.8,7.3-2.3,9.9-4.3c0.6-0.5,1.2-1,1.7-1.5c0.5-0.5,1-1.1,1.3-1.6c0.7-1.1,1.1-2.3,1-3.6
					c-0.2-1.2-0.9-2.3-2.1-3.1c-0.6-0.4-1.3-0.8-2.1-1c-0.8-0.2-1.7-0.4-2.7-0.5c-3.9-0.3-9.1,1.3-12.8,4.9
					c-3.8,3.7-5.8,6.7-7.7,9.6c-1.8,3-3.5,6-5.6,10.2c3.4-3.4,6.2-5.1,9.1-6.3C229.7,483.6,232.7,482.8,236.8,481.9z"
                  />
                </g>
                <g>
                  <path
                    d="M245.2,494.6c4,0.9,7.4,1,10.3,0.8c1.5-0.1,2.9-0.4,4.1-0.8c1.2-0.4,2.4-1,3.1-2c0.7-1,0.8-2.3,0.3-3.8
					c-0.5-1.5-1.8-3-3.7-4.4c-1.9-1.3-4.3-2.3-7-2.7c-2.7-0.4-5.6-0.2-8.3,0.7c-2.8,0.9-4.9,2-6.7,3c-1.8,1-3.3,2.1-4.8,3.1
					c-3,2.1-5.8,4.2-10.1,6.8c4.9-1.3,8.4-1.8,11.8-1.8c1.7,0,3.4,0,5.2,0.2C241.2,493.9,243.1,494.1,245.2,494.6z"
                  />
                </g>
                <g>
                  <path
                    d="M211.7,496.8c-2.4,0.4-2.8,3.2-1.3,5c1.6,1.9,4.4,1.9,5.2-0.4c0.8-2.4,0.8-3.7,2-5.7
					C215.4,496.7,214.2,496.4,211.7,496.8z"
                  />
                </g>
                <g>
                  <path
                    d="M215,498.4c-1.1-0.4-2-0.1-2.7,0.6c-0.7,0.7-1.1,1.8-1,3c0.1,1.2,0.8,2.2,1.7,2.7c0.9,0.5,2.2,0.5,3.2-0.4
					c1-0.9,1.7-1.6,2.3-2.3c0.6-0.7,1.3-1.4,2.2-2.1c-1.2,0-2.1-0.2-2.9-0.4C217,499.2,216.2,498.9,215,498.4z"
                  />
                </g>
                <g>
                  <path
                    d="M215.5,501.1c-0.2-0.9-0.7-1.3-1.5-1.4c-0.8-0.1-1.9,0.1-3,0.8c-1,0.6-1.7,1.7-1.6,2.8c0.1,1.1,0.9,2.2,2.2,2.5
					c1.4,0.3,2.4,0.2,3.3,0.1c0.9-0.1,1.8-0.2,2.9-0.2c-0.9-0.7-1.3-1.4-1.6-2.1C215.9,502.9,215.7,502.1,215.5,501.1z"
                  />
                </g>
                <g>
                  <path
                    d="M213.9,504.3c0.4-0.3,0.6-0.7,0.8-1.1c0.1-0.5,0.1-1,0-1.6c-0.1-0.5-0.4-1.1-0.9-1.4c-0.4-0.4-1-0.6-1.6-0.7
					c-0.6-0.1-1.2-0.1-1.7,0c-0.5,0.1-1,0.2-1.4,0.4c-0.4,0.2-0.7,0.6-0.9,1c-0.2,0.5-0.3,1.1-0.2,1.9c0.1,0.8,0.3,1.5,0.6,2.1
					c0.3,0.6,0.6,1.1,0.9,1.5c0.7,0.8,1.5,1.6,2.7,2c-0.4-0.5-0.5-1-0.4-1.4c0-0.4,0.2-0.8,0.4-1.1c0.2-0.3,0.5-0.6,0.7-0.9
					C213.1,504.8,213.5,504.6,213.9,504.3z"
                  />
                </g>
                <g>
                  <path
                    d="M211.8,505.3c1.1-0.1,1.7-0.6,2.1-1.5c0.3-0.8,0.5-2,0-3.2c-0.4-1.1-1.4-1.9-2.6-2c-1.2-0.1-2.5,0.5-3.2,1.9
					c-0.7,1.4-0.9,2.4-1.1,3.4c-0.2,1-0.4,1.9-0.7,3.2c0.9-0.8,1.8-1.2,2.7-1.4C209.7,505.4,210.6,505.4,211.8,505.3z"
                  />
                </g>
                <g>
                  <path
                    d="M208.4,505.3c1.4,0.5,2.8,0.1,3.7-0.7c0.9-0.8,1.2-2,0.9-3.2c-0.3-1.2-1.2-2.1-2.1-2.5c-0.9-0.4-2-0.4-3,0.3
					c-1.1,0.7-1.9,1.3-2.7,1.8c-0.8,0.5-1.7,0.9-3.1,1.1c1.2,0.5,2.1,1,3,1.6C206,504.2,206.9,504.8,208.4,505.3z"
                  />
                </g>
                <g>
                  <path
                    d="M206.6,501.5c0.6,2.6,3.6,3.1,5.4,1.5c1.8-1.6,1.7-4.6-0.8-5.5c-2.6-1-3.9-1-6.1-2.3
					C206.1,497.5,205.9,498.8,206.6,501.5z"
                  />
                </g>
                <g>
                  <path
                    d="M207.3,497.9c-1.1,2.2,0.8,4.3,3.2,4.2c2.4-0.1,4.2-2.4,2.8-4.5c-1.4-2.2-2.4-3-3.3-5.2
					C209.5,494.8,208.5,495.6,207.3,497.9z"
                  />
                </g>
                <g>
                  <path
                    d="M210,496.6c-0.9,0.7-1.1,1.7-0.8,2.5c0.4,0.9,1.3,1.6,2.4,2c1.2,0.3,2.3,0.2,3.1-0.2c0.8-0.4,1.2-1.1,0.9-2.1
					c-0.5-2-1.2-3.1-0.9-5.1C213.2,495,211.9,495.2,210,496.6z"
                  />
                </g>
                <g>
                  <path
                    d="M246.4,509.3c3.3,2.5,6.7,4,9.9,4.5c3.2,0.5,6.1,0.1,7.6-1.9c1.5-1.9,0.9-5.1-1.5-8c-2.4-2.9-6.8-5.5-11.7-6
					c-5.1-0.5-8.5-0.1-11.9,0.2c-3.4,0.4-6.7,0.8-11.3,1.5c4.6,0.9,7.6,2.2,10.3,3.7C240.4,504.9,243,506.7,246.4,509.3z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M155.7,208.9c3.8,4.2,8.2,7.3,12.1,9.2c3.9,1.8,7.4,2.3,9.1,0.6c1.7-1.7,1.1-5.3-1.4-9.3c-2.6-3.9-7.1-8.1-12.8-10.5
					c-5.8-2.5-10-3.6-14.1-4.8c-4.1-1.1-8.3-2.3-14.2-4.1c5.3,3.1,8.6,5.8,11.6,8.7C148.9,201.6,151.7,204.6,155.7,208.9z"
                  />
                </g>
                <g>
                  <path
                    d="M144.9,218.2c1.7,4.7,4.2,8.8,6.9,11.6c2.7,2.8,5.6,4.4,7.9,3.4c2.2-0.9,3.3-4.2,2.6-8.5c-0.7-4.2-3.2-9.4-7.3-13.3
					c-4.2-4-7.5-6.2-10.7-8.5c-3.3-2.2-6.5-4.4-11.1-7.6c3.6,4.3,5.5,7.6,7.1,11C141.9,209.8,143.2,213.3,144.9,218.2z"
                  />
                </g>
                <g>
                  <path
                    d="M130,219.4c-0.3,3.7,0.5,7,2,9.6c1.5,2.5,3.7,4.3,6.1,4.2c2.4-0.1,4.5-2.2,5.4-5.4c0.9-3.2,0.5-7.6-1.7-11.3
					c-2.3-3.8-4.3-6.2-6.3-8.5c-2-2.3-3.9-4.5-6.5-7.8c1.3,4,1.6,6.8,1.6,9.7C130.6,212.7,130.3,215.5,130,219.4z"
                  />
                </g>
                <g>
                  <path
                    d="M111.8,214c-2.1,2.7-2.8,5.7-2.5,8.4c0.3,2.6,1.7,4.9,4,5.8c2.3,0.9,4.9,0.1,6.9-1.9c2-2,3.4-5.2,3.2-8.8
					c-0.2-3.7-0.7-6.3-1.1-8.9c-0.4-2.5-0.7-5.1-0.8-8.6c-1.4,3.3-2.8,5.4-4.3,7.4C115.7,209.3,114,211.2,111.8,214z"
                  />
                </g>
                <g>
                  <path
                    d="M99.2,205.2c-3.4,1.1-6,3-7.7,5.3c-1.7,2.3-2.5,4.9-1.5,7.2c0.9,2.2,3.6,3.5,7,3.1c3.3-0.4,7.2-2.4,9.7-5.9
					c2.6-3.6,3.8-6.4,5-9.1c1.2-2.7,2.3-5.4,4-9.1c-2.9,2.8-5.4,4.3-7.8,5.4C105.3,203.2,102.7,204.1,99.2,205.2z"
                  />
                </g>
                <g>
                  <path
                    d="M94.3,188.9c-3.5-1.1-6.6-1.1-9.4-0.5c-0.7,0.2-1.4,0.3-2,0.6c-0.6,0.3-1.2,0.6-1.7,0.9c-1.1,0.7-1.9,1.6-2.3,2.8
					c-0.4,1.1-0.3,2.4,0.3,3.6c0.3,0.6,0.7,1.2,1.3,1.8c0.6,0.6,1.2,1.1,2,1.6c3.1,1.9,8,2.8,12.5,1.3c4.6-1.6,7.4-3.3,10.1-5.1
					c2.6-1.8,5.1-3.7,8.4-6.4c-4.1,1.4-7.1,1.6-9.9,1.4C100.6,190.6,97.9,190,94.3,188.9z"
                  />
                </g>
                <g>
                  <path
                    d="M100.2,170.6c-2.6-3.1-5.7-5.2-8.7-6.3c-3-1.1-5.9-1.1-7.7,0.5c-1.8,1.6-1.9,4.8-0.1,8.1c1.8,3.3,5.4,6.6,10.1,8
					c4.8,1.4,8.2,1.8,11.5,2.1c3.3,0.3,6.5,0.5,11.2,0.9c-4.3-1.8-6.8-3.7-9.1-5.8C105,176.1,103,173.8,100.2,170.6z"
                  />
                </g>
                <g>
                  <path
                    d="M110.5,158c-1.1-4.1-3-7.4-5.1-9.9c-0.6-0.6-1.1-1.2-1.7-1.7c-0.6-0.5-1.2-0.9-1.8-1.2c-1.2-0.6-2.4-0.9-3.6-0.7
					c-1.2,0.3-2.2,1.1-2.8,2.4c-0.3,0.6-0.6,1.4-0.8,2.2c-0.2,0.8-0.3,1.8-0.2,2.8c0.1,4,2,9,5.8,12.6c3.9,3.7,7,5.5,10.1,7.2
					c3.1,1.7,6.2,3.3,10.6,5.4c-3.6-3.3-5.4-6.2-6.8-9.1C112.8,165.2,111.7,162.2,110.5,158z"
                  />
                </g>
                <g>
                  <path
                    d="M123.7,154.9c0.9-3.8,1.3-6.8,1.4-9.5c0.1-1.3,0-2.7-0.1-4c-0.1-1.3-0.4-2.5-1.2-3.5c-0.8-0.9-2-1.4-3.6-1.2
					c-1.6,0.2-3.6,1.2-5.3,3c-1.7,1.8-3.1,4.4-3.7,7.3c-0.6,2.9-0.6,6,0.3,8.9c0.9,3,2.1,5.2,3.3,7c1.2,1.8,2.4,3.3,3.6,4.8
					c2.4,2.9,4.9,5.5,8.1,9.5c-2-4.8-2.9-8.3-3.3-11.7c-0.2-1.7-0.3-3.4-0.3-5.2C123,158.7,123.2,156.8,123.7,154.9z"
                  />
                </g>
                <g>
                  <path
                    d="M140.8,158.2c3.1-2.2,5.3-4.3,6.9-6.6c0.8-1.1,1.5-2.3,2.1-3.5c0.5-1.2,0.8-2.4,0.6-3.6c-0.2-1.2-1.1-2.2-2.5-2.8
					c-1.4-0.6-3.3-0.8-5.5-0.4c-2.2,0.4-4.5,1.5-6.6,3.3c-2,1.7-3.8,4-4.8,6.5c-1.1,2.7-1.6,4.9-1.9,6.9c-0.3,2-0.4,3.8-0.5,5.6
					c-0.1,3.5,0,6.9-0.1,11.8c1.5-4.6,3.1-7.6,5.1-10.1c1-1.3,2-2.5,3.2-3.6C137.9,160.6,139.2,159.4,140.8,158.2z"
                  />
                </g>
                <g>
                  <path
                    d="M151.4,167.6c4-0.8,7.3-2.3,9.9-4.3c0.6-0.5,1.2-1,1.7-1.5c0.5-0.5,1-1.1,1.3-1.6c0.7-1.1,1.1-2.3,1-3.6
					c-0.2-1.2-0.9-2.3-2.1-3.1c-0.6-0.4-1.3-0.8-2.1-1c-0.8-0.2-1.7-0.4-2.7-0.5c-3.9-0.3-9.1,1.3-12.8,4.9
					c-3.8,3.7-5.8,6.7-7.7,9.6c-1.8,3-3.5,6-5.6,10.2c3.4-3.4,6.2-5.1,9.1-6.3C144.2,169.3,147.3,168.5,151.4,167.6z"
                  />
                </g>
                <g>
                  <path
                    d="M159.8,180.2c4,0.9,7.4,1,10.3,0.8c1.5-0.1,2.9-0.4,4.1-0.8c1.2-0.4,2.4-1,3.1-2c0.7-1,0.8-2.3,0.3-3.8
					c-0.5-1.5-1.8-3-3.7-4.4c-1.9-1.3-4.3-2.3-7-2.7c-2.7-0.4-5.6-0.2-8.3,0.7c-2.8,0.9-4.9,2-6.7,3c-1.8,1-3.3,2.1-4.8,3.1
					c-3,2.1-5.8,4.2-10.1,6.8c4.9-1.3,8.4-1.8,11.8-1.8c1.7,0,3.4,0,5.2,0.2C155.8,179.5,157.7,179.8,159.8,180.2z"
                  />
                </g>
                <g>
                  <path
                    d="M126.3,182.5c-2.4,0.4-2.8,3.2-1.3,5c1.6,1.9,4.4,1.9,5.2-0.4c0.8-2.4,0.8-3.7,2-5.7C130,182.3,128.8,182.1,126.3,182.5
					z"
                  />
                </g>
                <g>
                  <path
                    d="M129.6,184.1c-1.1-0.4-2-0.1-2.7,0.6c-0.7,0.7-1.1,1.8-1,3c0.1,1.2,0.8,2.2,1.7,2.7c0.9,0.5,2.2,0.5,3.2-0.4
					c1-0.9,1.7-1.6,2.3-2.3c0.6-0.7,1.3-1.4,2.2-2.1c-1.2,0-2.1-0.2-2.9-0.4C131.6,184.9,130.8,184.5,129.6,184.1z"
                  />
                </g>
                <g>
                  <path
                    d="M130.1,186.7c-0.2-0.9-0.7-1.3-1.5-1.4c-0.8-0.1-1.9,0.1-3,0.8c-1,0.6-1.7,1.7-1.6,2.8c0.1,1.1,0.9,2.2,2.2,2.5
					c1.4,0.3,2.4,0.2,3.3,0.1c0.9-0.1,1.8-0.2,2.9-0.2c-0.9-0.7-1.3-1.4-1.6-2.1C130.5,188.5,130.3,187.7,130.1,186.7z"
                  />
                </g>
                <g>
                  <path
                    d="M128.5,190c0.4-0.3,0.6-0.7,0.8-1.1c0.1-0.5,0.1-1,0-1.6c-0.1-0.5-0.4-1.1-0.9-1.4c-0.4-0.4-1-0.6-1.6-0.7
					c-0.6-0.1-1.2-0.1-1.7,0c-0.5,0.1-1,0.2-1.4,0.4c-0.4,0.2-0.7,0.6-0.9,1c-0.2,0.5-0.3,1.1-0.2,1.9c0.1,0.8,0.3,1.5,0.6,2.1
					c0.3,0.6,0.6,1.1,0.9,1.5c0.7,0.8,1.5,1.6,2.7,2c-0.4-0.5-0.5-1-0.4-1.4c0-0.4,0.2-0.8,0.4-1.1c0.2-0.3,0.5-0.6,0.7-0.9
					C127.7,190.4,128.1,190.3,128.5,190z"
                  />
                </g>
                <g>
                  <path
                    d="M126.4,190.9c1.1-0.1,1.7-0.6,2.1-1.5c0.3-0.8,0.5-2,0-3.2c-0.4-1.1-1.4-1.9-2.6-2c-1.2-0.1-2.5,0.5-3.2,1.9
					c-0.7,1.4-0.9,2.4-1.1,3.4c-0.2,1-0.4,1.9-0.7,3.2c0.9-0.8,1.8-1.2,2.7-1.4C124.3,191.1,125.2,191,126.4,190.9z"
                  />
                </g>
                <g>
                  <path
                    d="M123,191c1.4,0.5,2.8,0.1,3.7-0.7c0.9-0.8,1.2-2,0.9-3.2c-0.3-1.2-1.2-2.1-2.1-2.5c-0.9-0.4-2-0.4-3,0.3
					c-1.1,0.7-1.9,1.3-2.7,1.8c-0.8,0.5-1.7,0.9-3.1,1.1c1.2,0.5,2.1,1,3,1.6C120.6,189.9,121.5,190.4,123,191z"
                  />
                </g>
                <g>
                  <path
                    d="M121.2,187.2c0.6,2.6,3.6,3.1,5.4,1.5c1.8-1.6,1.7-4.6-0.8-5.5c-2.6-1-3.9-1-6.1-2.3
					C120.7,183.2,120.5,184.5,121.2,187.2z"
                  />
                </g>
                <g>
                  <path
                    d="M121.9,183.6c-1.1,2.2,0.8,4.3,3.2,4.2c2.4-0.1,4.2-2.4,2.8-4.5c-1.4-2.2-2.4-3-3.3-5.2
					C124.1,180.4,123.1,181.3,121.9,183.6z"
                  />
                </g>
                <g>
                  <path
                    d="M124.6,182.2c-0.9,0.7-1.1,1.7-0.8,2.5c0.4,0.9,1.3,1.6,2.4,2c1.2,0.3,2.3,0.2,3.1-0.2c0.8-0.4,1.2-1.1,0.9-2.1
					c-0.5-2-1.2-3.1-0.9-5.1C127.8,180.7,126.5,180.9,124.6,182.2z"
                  />
                </g>
                <g>
                  <path
                    d="M161,194.9c3.3,2.5,6.7,4,9.9,4.5c3.2,0.5,6.1,0.1,7.6-1.9c1.5-1.9,0.9-5.1-1.5-8c-2.4-2.9-6.8-5.5-11.7-6
					c-5.1-0.5-8.5-0.1-11.9,0.2c-3.4,0.4-6.7,0.8-11.3,1.5c4.6,0.9,7.6,2.2,10.3,3.7C155,190.5,157.6,192.3,161,194.9z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M353.7,131.6c-6.1,1.4-12.7,4.3-17.8,8c-2.6,1.8-4.9,3.9-7,6c-2,2.1-3.7,4.3-5.1,6.4c-2.8,4.3-4.2,8.4-4.6,11.5
					c-0.2,1.6-0.1,2.9,0.2,3.9c0.3,1,0.9,1.8,1.6,2.2c0.8,0.4,1.7,0.6,2.7,0.4c1-0.2,2.2-0.6,3.5-1.2c2.5-1.2,5.5-3.2,8.8-5.5
					c1.6-1.1,3.4-2.3,5.2-3.6c1.8-1.2,3.8-2.5,5.8-3.8c4-2.7,8.2-5.1,13.1-8c5-3,9.8-5.5,14-8.2c4.2-2.6,8-5.4,11.3-8.5
					c1.7-1.5,3.3-3.1,4.8-4.8c1.6-1.7,3.1-3.5,4.6-5.5c3-3.9,6-8.5,8.6-14.3c-3.6,5.3-7.4,9-11.1,11.8c-1.9,1.4-3.7,2.6-5.5,3.7
					c-1.8,1.1-3.7,2-5.5,2.7c-3.7,1.6-7.5,2.6-11.9,3.5C365,129.4,360,130.1,353.7,131.6z"
                  />
                </g>
                <g>
                  <path
                    d="M362.6,150.6c-5,2.2-10,4.1-14.7,5.9c-4.7,1.7-9.2,3.3-13.1,4.7c-4,1.4-7.4,2.8-9.9,4.2c-2.5,1.4-4.1,2.9-4.3,4.7
					c-0.1,1.7,1.3,3.5,4.2,5c2.8,1.5,7.1,2.5,12.3,2.7c5.2,0.2,11.2-0.6,17.4-2.6c6.1-2.1,12.3-5.3,17.6-9.8
					c5.4-4.6,9.3-9,12.4-13.3c3.1-4.3,5.4-8.3,7.5-12.4c2-4.1,3.8-8.4,5.4-13.2c1.6-4.8,3-10.2,4-16.7c-2,6.2-4.5,11.1-7.2,15.2
					c-2.6,4.1-5.4,7.4-8.4,10.3c-3,3-6.2,5.6-10,8.1C372,145.9,367.8,148.3,362.6,150.6z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M401.3,173.5c-6-1.9-13.1-2.8-19.4-2.2c-3.2,0.3-6.2,0.8-9,1.6c-2.8,0.8-5.4,1.8-7.7,2.9c-4.6,2.3-7.9,5-9.8,7.5
					c-1,1.2-1.6,2.4-1.8,3.5c-0.3,1.1-0.2,2,0.3,2.7c0.4,0.8,1.2,1.3,2.2,1.7c1,0.4,2.2,0.6,3.6,0.8c2.8,0.2,6.3,0.1,10.3-0.2
					c2-0.1,4.1-0.3,6.4-0.4c2.2-0.1,4.5-0.3,6.9-0.4c4.8-0.2,9.7-0.2,15.4-0.2c5.9,0,11.2,0.3,16.2,0.1c5-0.1,9.7-0.6,14.1-1.5
					c2.2-0.4,4.4-1,6.6-1.7c2.2-0.7,4.4-1.4,6.7-2.4c4.6-1.9,9.5-4.3,14.7-7.9c-5.8,2.7-10.9,3.9-15.6,4.5c-2.3,0.3-4.5,0.4-6.7,0.3
					c-2.1,0-4.2-0.2-6.2-0.5c-4-0.5-7.8-1.6-12-3C412.2,177.4,407.6,175.5,401.3,173.5z"
                  />
                </g>
                <g>
                  <path
                    d="M399.3,194.3c-5.4-0.7-10.7-1.6-15.6-2.5c-4.9-0.9-9.6-1.9-13.7-2.7c-4.1-0.8-7.8-1.4-10.7-1.5
					c-2.9-0.1-5.1,0.4-6.1,1.9c-1,1.4-0.6,3.7,1.1,6.4c1.7,2.7,4.8,5.8,9.2,8.6c4.3,2.8,9.9,5.3,16.3,6.7c6.3,1.4,13.3,1.8,20.1,0.6
					c7-1.1,12.6-3,17.5-5c4.8-2.1,8.9-4.4,12.8-6.9c3.9-2.5,7.6-5.3,11.4-8.6c3.8-3.3,7.8-7.3,12-12.3c-4.9,4.3-9.6,7.2-13.9,9.4
					c-4.4,2.2-8.4,3.5-12.5,4.6c-4.1,1-8.2,1.6-12.7,1.9C409.8,195.2,404.9,195.1,399.3,194.3z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M143.2,295.9c4-4.8,7.5-11.1,9.3-17.2c0.9-3.1,1.6-6.1,1.9-9c0.3-2.9,0.3-5.7,0.2-8.2c-0.4-5.1-1.7-9.2-3.3-11.9
					c-0.8-1.4-1.7-2.4-2.6-3c-0.9-0.6-1.8-0.9-2.6-0.8c-0.9,0.1-1.7,0.6-2.4,1.3c-0.7,0.8-1.4,1.8-2,3.1c-1.3,2.5-2.4,5.9-3.7,9.7
					c-0.6,1.9-1.3,3.9-2,6.1c-0.7,2.1-1.4,4.3-2.2,6.6c-1.6,4.6-3.4,9-5.5,14.3c-2.2,5.4-4.4,10.3-6.2,15c-1.7,4.7-3.1,9.2-3.8,13.6
					c-0.4,2.2-0.7,4.5-0.9,6.8c-0.2,2.3-0.3,4.7-0.3,7.1c0,4.9,0.4,10.4,1.8,16.6c-0.4-6.3,0.4-11.6,1.6-16.1
					c0.6-2.3,1.3-4.4,2.2-6.3c0.8-2,1.7-3.8,2.7-5.5c2-3.5,4.4-6.6,7.3-10C135.6,304.6,139.1,300.9,143.2,295.9z"
                  />
                </g>
                <g>
                  <path
                    d="M124.7,286.2c2.7-4.8,5.5-9.3,8.1-13.6c2.7-4.3,5.3-8.2,7.6-11.7c2.3-3.5,4.2-6.7,5.4-9.3c1.2-2.6,1.5-4.9,0.5-6.3
					c-1-1.5-3.2-2-6.4-1.4c-3.2,0.6-7.2,2.3-11.4,5.3c-4.2,3-8.6,7.3-12.2,12.6c-3.6,5.4-6.6,11.7-8.1,18.4
					c-1.5,6.9-1.9,12.8-1.8,18.1c0.1,5.3,0.7,9.9,1.6,14.4c0.9,4.5,2.1,9,3.7,13.8c1.7,4.8,3.8,9.9,7,15.7
					c-2.2-6.2-3.2-11.6-3.5-16.4c-0.4-4.8-0.2-9.2,0.4-13.3c0.6-4.2,1.6-8.2,3-12.5C120,295.7,121.9,291.1,124.7,286.2z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M453.4,473.1c-7.8,2.4-14.6,6.2-19.4,10.2c-4.8,4-7.6,8.2-6.4,11.6c1.2,3.3,6.3,4.8,13,3.8c6.7-1,14.9-4.4,21.5-10.4
					c6.8-6.1,10.9-10.9,14.9-15.6c4-4.7,8-9.4,14-16.1c-7.3,5.1-12.9,7.7-18.4,9.9C467.1,468.8,461.4,470.6,453.4,473.1z"
                  />
                </g>
                <g>
                  <path
                    d="M447.9,453.4c-7.2-0.7-14.1,0.2-19.4,2c-5.3,1.8-9.1,4.7-9.3,8.2c-0.2,3.5,3.5,6.8,9.5,8.5c6,1.7,14.2,1.6,21.8-1.4
					c7.8-3.1,12.7-6,17.6-8.9c4.9-2.9,9.7-5.8,16.7-9.9c-7.8,2.1-13.3,2.5-18.7,2.5C460.7,454.5,455.3,454.1,447.9,453.4z"
                  />
                </g>
                <g>
                  <path
                    d="M455.3,433.2c-4.7-2.7-9.5-3.7-13.7-3.3c-4.2,0.4-7.9,2.2-9.3,5.4c-1.4,3.2,0.1,7.2,3.8,10.4c3.7,3.1,9.6,5.3,15.9,4.7
					c6.4-0.7,10.7-1.9,14.9-3.1c4.2-1.2,8.3-2.4,14.1-3.8c-6-0.7-9.9-2.1-13.6-3.8C463.7,438,460.2,435.9,455.3,433.2z"
                  />
                </g>
                <g>
                  <path
                    d="M473.5,412.7c-2.2-4.4-5.7-7.2-9.4-8.4c-3.7-1.2-7.5-0.8-10.1,1.7c-2.6,2.4-3.1,6.3-1.8,10.2c1.4,3.9,4.7,7.6,9.6,9.6
					c5,2,8.7,2.9,12.3,3.9c3.6,1,7.1,2.1,11.8,4.3c-3.4-3.8-5.3-7-7-10.2C477.3,420.6,475.8,417.2,473.5,412.7z"
                  />
                </g>
                <g>
                  <path
                    d="M492.7,401.5c0.7-5.1-0.3-9.7-2.2-13.3c-1.9-3.6-4.9-6.3-8.4-6.4c-3.5-0.1-6.7,2.6-8.3,7.2c-1.5,4.5-1.3,10.8,1.8,16.3
					c3.2,5.6,6.1,8.9,8.9,12.1c2.8,3.2,5.6,6.3,9.4,10.8c-1.9-5.5-2.3-9.6-2.3-13.6C491.6,410.7,492,406.8,492.7,401.5z"
                  />
                </g>
                <g>
                  <path
                    d="M517.1,405c3.5-3.9,5.5-8,6.4-12c0.2-1,0.4-2,0.4-3c0-1,0-1.9-0.1-2.8c-0.3-1.8-1-3.5-2.2-4.7c-1.3-1.2-2.9-1.9-4.9-1.8
					c-1,0-2.1,0.2-3.1,0.6c-1.1,0.4-2.2,0.9-3.3,1.6c-4.4,2.8-8.5,8.7-9.3,15.5c-0.8,7-0.1,11.7,0.6,16.3c0.8,4.5,1.7,8.9,3.2,15
					c0.6-6.2,2.3-10.2,4.3-13.8C511,412.3,513.5,409,517.1,405z"
                  />
                </g>
                <g>
                  <path
                    d="M537.4,423.9c5.7-1.6,10.3-4.3,13.5-7.6c3.2-3.2,5.1-7.1,4.1-10.4c-1-3.3-5.1-5.3-10.5-5c-5.4,0.3-11.9,3.1-16.6,8.4
					c-4.8,5.4-7.3,9.6-9.8,13.8c-2.3,4.1-4.7,8.2-8.1,14.1c5-4.5,9-6.7,13.1-8.4C527.3,426.9,531.5,425.6,537.4,423.9z"
                  />
                </g>
                <g>
                  <path
                    d="M547.6,445.1c6,1,11.5,0.6,16-0.7c1.1-0.4,2.2-0.7,3.2-1.2c1-0.5,1.9-1,2.7-1.6c1.6-1.2,2.7-2.6,3.1-4.4
					c0.4-1.7-0.1-3.5-1.4-5.2c-0.6-0.8-1.4-1.7-2.5-2.4c-1-0.7-2.2-1.5-3.5-2c-5.2-2.3-13-2.8-20,0c-7.2,2.9-11.5,5.9-15.7,8.8
					c-4.1,3-8.1,6.1-13.5,10.5c6.6-2.7,11.4-3.3,16-3.4C536.8,443.6,541.4,444.1,547.6,445.1z"
                  />
                </g>
                <g>
                  <path
                    d="M543.7,464.2c4.4,3.5,8.1,5.9,11.5,7.7c1.7,0.9,3.5,1.7,5.2,2.3c1.7,0.6,3.5,1,5.3,0.6c1.7-0.4,3.1-1.7,3.8-4
					c0.7-2.2,0.6-5.4-0.8-8.8c-1.3-3.3-3.9-6.8-7.2-9.3c-3.4-2.6-7.5-4.4-11.9-5c-4.5-0.6-8.1-0.4-11.2,0c-3.1,0.4-5.8,1.1-8.4,1.8
					c-5.2,1.4-10.1,3.1-17.4,4.9c7.5,0.3,12.6,1.2,17.3,2.7c2.3,0.8,4.6,1.7,6.9,2.8C539.1,461,541.5,462.4,543.7,464.2z"
                  />
                </g>
                <g>
                  <path
                    d="M528.8,484.6c1,5.4,2.5,9.5,4.4,13.1c1,1.8,2.1,3.4,3.3,4.8c1.2,1.4,2.7,2.5,4.4,2.9c1.7,0.4,3.5,0,5.2-1.5
					c1.7-1.4,3.1-3.9,3.9-7c0.7-3.1,0.7-6.9-0.2-10.6c-1-3.7-2.9-7.4-5.6-10.3c-2.8-3.1-5.4-5.1-7.9-6.8c-2.5-1.7-4.7-2.9-7-4.1
					c-4.6-2.3-9.1-4.3-15.4-7.3c5.1,4.8,8,8.7,10.2,12.8c1.1,2,2,4.1,2.8,6.4C527.6,479.3,528.3,481.8,528.8,484.6z"
                  />
                </g>
                <g>
                  <path
                    d="M510.1,492.7c-1.4,5.7-1.4,11-0.4,15.5c0.3,1.1,0.5,2.2,0.9,3.2c0.4,1,0.8,1.9,1.3,2.7c1,1.6,2.4,2.9,4.1,3.4
					c1.7,0.5,3.5,0.2,5.3-0.9c0.9-0.5,1.8-1.3,2.6-2.2c0.8-0.9,1.6-2,2.3-3.3c2.7-5,3.8-12.7,1.5-19.8c-2.4-7.3-5.2-11.7-7.9-15.9
					c-2.8-4.2-5.7-8.2-10-13.6c2.4,6.5,2.8,11.3,2.7,15.8C512.2,482.3,511.5,486.8,510.1,492.7z"
                  />
                </g>
                <g>
                  <path
                    d="M488.5,496c-3.6,4.8-5.9,9-7.3,13c-0.7,2-1.3,4-1.5,5.9c-0.2,1.9-0.1,3.7,0.8,5.3c0.9,1.5,2.5,2.5,4.7,2.7
					c2.2,0.2,5.1-0.5,7.9-2.1c2.8-1.6,5.7-4.3,7.8-7.6c2.1-3.3,3.6-7.2,4.1-11.2c0.5-4.2,0.4-7.6,0.2-10.6c-0.3-3-0.7-5.6-1.1-8.2
					c-0.9-5.1-1.9-10.1-2.8-17.4c-1.3,7.2-2.8,12.1-4.8,16.5c-1,2.2-2.1,4.4-3.4,6.7C491.8,491.2,490.3,493.6,488.5,496z"
                  />
                </g>
                <g>
                  <path
                    d="M505.9,450.8c0.9-3.4-2.4-5.6-5.8-4.7c-3.4,0.9-5.2,4.5-2.7,7c2.6,2.5,4.3,3.3,6.2,6.1
					C503.8,455.8,505,454.3,505.9,450.8z"
                  />
                </g>
                <g>
                  <path
                    d="M501.8,454.2c1.2-1.2,1.4-2.6,0.9-3.9c-0.5-1.3-1.7-2.5-3.4-3.1c-1.7-0.6-3.4-0.4-4.6,0.6c-1.2,0.9-1.9,2.6-1.4,4.4
					c0.5,1.9,1.1,3.2,1.6,4.5c0.5,1.3,1,2.5,1.4,4.2c0.7-1.6,1.5-2.6,2.4-3.6C499.5,456.3,500.5,455.4,501.8,454.2z"
                  />
                </g>
                <g>
                  <path
                    d="M498,453.2c1.3,0.3,2.1-0.1,2.7-1.2c0.6-1,1-2.6,0.8-4.4c-0.2-1.7-1.2-3.2-2.7-3.8c-1.5-0.6-3.4-0.2-4.6,1.4
					c-1.2,1.6-1.7,3-2.2,4.2c-0.4,1.3-0.8,2.4-1.5,3.9c1.4-0.8,2.6-0.9,3.7-0.9C495.5,452.6,496.6,452.9,498,453.2z"
                  />
                </g>
                <g>
                  <path
                    d="M494.8,449.1c0.1,0.7,0.5,1.2,1,1.7c0.5,0.4,1.3,0.8,2.1,0.9c0.8,0.1,1.7,0.1,2.4-0.3c0.8-0.3,1.4-0.9,1.9-1.6
					c0.5-0.7,0.8-1.5,1.1-2.2c0.2-0.7,0.3-1.4,0.3-2.1c-0.1-0.7-0.3-1.3-0.8-1.8c-0.5-0.6-1.3-1.1-2.4-1.4c-1.1-0.3-2.2-0.5-3.1-0.5
					c-0.9,0-1.7,0.1-2.5,0.3c-1.5,0.4-3,0.9-4.2,2.3c0.8-0.2,1.5,0,2.1,0.3c0.5,0.3,0.9,0.7,1.2,1.2c0.3,0.5,0.5,1,0.7,1.5
					C494.6,447.8,494.6,448.4,494.8,449.1z"
                  />
                </g>
                <g>
                  <path
                    d="M494.8,445.7c-0.6,1.5-0.3,2.6,0.6,3.6c0.9,1,2.4,1.8,4.1,2c1.7,0.2,3.4-0.6,4.2-2.1c0.9-1.5,0.9-3.7-0.5-5.4
					c-1.4-1.8-2.6-2.7-3.8-3.6c-1.2-0.9-2.3-1.7-3.7-2.8c0.5,1.8,0.5,3.1,0.2,4.4C495.9,443,495.4,444.2,494.8,445.7z"
                  />
                </g>
                <g>
                  <path
                    d="M496.9,441.3c-1.5,1.5-1.9,3.6-1.4,5.2c0.5,1.6,1.9,2.8,3.6,3.1c1.7,0.3,3.4-0.2,4.5-1.2c1.1-1,1.7-2.3,1.5-4.1
					c-0.3-1.8-0.6-3.2-0.7-4.6c-0.1-1.4-0.1-2.8,0.5-4.7c-1.5,1.3-2.6,2.1-3.9,3C499.7,438.9,498.4,439.7,496.9,441.3z"
                  />
                </g>
                <g>
                  <path d="M502.9,441.2c-3.7-0.8-6.3,2.7-5.3,6.1c1,3.4,5,5,7.7,2.3c2.8-2.8,3.6-4.5,6.8-6.6C508.4,443.1,506.8,442,502.9,441.2z" />
                </g>
                <g>
                  <path
                    d="M507.1,444.4c-2.2-2.9-6.2-1.6-7.5,1.7c-1.3,3.3,0.6,6.9,4.2,6.5c3.7-0.5,5.4-1.4,8.9-1.1
					C510,449.2,509.4,447.3,507.1,444.4z"
                  />
                </g>
                <g>
                  <path
                    d="M507.3,448.7c-0.3-1.6-1.5-2.5-2.9-2.6c-1.4-0.1-2.9,0.6-4.1,2c-1.2,1.3-1.7,2.9-1.7,4.1c0.1,1.3,0.7,2.2,2.2,2.4
					c3,0.6,4.8,0.4,7.3,1.9C507.4,453.8,507.9,452.1,507.3,448.7z"
                  />
                </g>
                <g>
                  <path
                    d="M468.5,488.6c-5.3,2.7-9.3,6.4-11.9,10.2c-2.6,3.8-3.8,7.9-2.1,11.1c1.6,3.1,6.1,4.3,11.4,2.9c5.3-1.4,11.3-5.5,15-11.7
					c3.7-6.3,5.4-11.1,7-15.7c1.5-4.7,3-9.2,5-15.7c-3.9,5.5-7.5,8.6-11.2,11.3C477.9,483.5,474,485.7,468.5,488.6z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M426.3,566.6c5.8-2.4,11.9-6.2,16.3-10.7c2.3-2.2,4.3-4.6,5.9-7c1.7-2.4,3-4.8,4-7.2c2.1-4.7,2.9-8.9,2.7-12.1
					c-0.1-1.6-0.4-2.9-0.8-3.9c-0.5-1-1.1-1.6-2-1.9c-0.8-0.3-1.8-0.3-2.8,0c-1,0.3-2.1,0.9-3.2,1.7c-2.3,1.6-4.9,4-7.8,6.8
					c-1.4,1.4-3,2.8-4.6,4.4c-1.6,1.5-3.3,3.1-5.1,4.7c-3.5,3.3-7.3,6.4-11.7,10c-4.5,3.7-8.8,7-12.5,10.3c-3.8,3.3-7,6.6-9.9,10.2
					c-1.4,1.8-2.7,3.6-4,5.5c-1.3,1.9-2.5,4-3.7,6.1c-2.3,4.4-4.5,9.3-6.2,15.5c2.7-5.8,5.9-10,9.1-13.4c1.6-1.7,3.2-3.2,4.9-4.5
					c1.6-1.3,3.3-2.5,5-3.6c3.4-2.1,7-3.8,11.2-5.4C415.5,570.6,420.3,569,426.3,566.6z"
                  />
                </g>
                <g>
                  <path
                    d="M414.5,549.3c4.6-2.9,9.2-5.6,13.6-8.1c4.4-2.5,8.6-4.7,12.2-6.7c3.7-2,6.9-3.9,9.1-5.7c2.3-1.8,3.6-3.6,3.5-5.3
					c-0.2-1.7-1.9-3.3-4.9-4.3c-3-1-7.4-1.4-12.6-0.7c-5.1,0.6-11,2.3-16.8,5.3c-5.7,3-11.4,7.2-15.8,12.4
					c-4.6,5.4-7.8,10.4-10.2,15.1c-2.4,4.7-4.1,9.1-5.4,13.5c-1.4,4.4-2.4,8.9-3.2,13.9c-0.8,5-1.3,10.6-1.3,17.1
					c1-6.5,2.7-11.7,4.7-16.1c2-4.4,4.2-8.1,6.7-11.5c2.5-3.4,5.3-6.5,8.5-9.6C405.9,555.4,409.8,552.3,414.5,549.3z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M372.6,532.7c6.2,0.9,13.4,0.7,19.5-0.9c3.1-0.8,6-1.8,8.7-3c2.7-1.2,5-2.6,7.1-4.1c4.2-3,7-6.2,8.5-9
					c0.7-1.4,1.2-2.7,1.2-3.7c0.1-1.1-0.1-2-0.7-2.7c-0.5-0.7-1.4-1.1-2.4-1.4c-1-0.2-2.3-0.3-3.7-0.2c-2.8,0.2-6.3,0.9-10.2,1.8
					c-1.9,0.4-4,0.9-6.2,1.4c-2.2,0.5-4.4,1-6.8,1.5c-4.7,1-9.5,1.8-15.1,2.6c-5.8,0.9-11.1,1.5-16,2.4c-4.9,0.9-9.4,2.1-13.7,3.7
					c-2.1,0.8-4.2,1.7-6.3,2.7c-2.1,1-4.2,2.1-6.3,3.4c-4.2,2.6-8.7,5.7-13.3,10.1c5.3-3.6,10.2-5.6,14.7-6.9
					c2.3-0.6,4.4-1.1,6.5-1.4c2.1-0.3,4.1-0.5,6.2-0.5c4-0.1,7.9,0.3,12.4,1.1C361.2,530.7,366.2,531.8,372.6,532.7z"
                  />
                </g>
                <g>
                  <path
                    d="M371.3,511.9c5.5-0.2,10.8-0.1,15.8,0c5,0.1,9.8,0.3,14,0.5c4.2,0.1,7.9,0.1,10.8-0.2c2.9-0.4,4.9-1.2,5.7-2.8
					c0.7-1.6,0.1-3.8-2.1-6.2c-2.1-2.4-5.7-5-10.4-7.1c-4.7-2.1-10.7-3.6-17.1-4c-6.5-0.3-13.4,0.4-20,2.6c-6.7,2.2-12,4.9-16.5,7.7
					c-4.4,2.8-8.1,5.7-11.6,8.8c-3.4,3.1-6.6,6.4-9.9,10.3c-3.2,3.9-6.6,8.4-9.8,14c4.2-5,8.3-8.7,12.2-11.5c4-2.8,7.8-4.8,11.6-6.5
					c3.9-1.6,7.8-2.9,12.2-3.9C360.8,512.7,365.7,512,371.3,511.9z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M514.4,139c10.6-1.1,21.3-3.7,30.7-7.7c9.4-3.9,17.6-9.3,23.7-14.8c6.1-5.5,10.2-11.1,12.5-15.3
					c2.3-4.2,2.9-6.9,2.1-7.4c-1.6-1-7.7,7.5-19.9,16.2c-6.1,4.3-13.6,8.7-22.2,12.2c-8.6,3.5-18.2,6.3-28.2,8.1
					c-10.2,1.9-19.1,2.6-27.2,3.2c-8.1,0.6-15.5,1.1-23,1.9c-7.4,0.8-14.9,1.9-23.1,4.1c-4.1,1.1-8.3,2.5-12.7,4.4
					c-4.4,1.9-8.9,4.3-13.4,7.6c9.3-6,18.4-8.6,26.5-10.2c8.2-1.5,15.6-1.9,22.9-2c7.4-0.1,14.7,0.2,22.9,0.5
					C494.2,139.9,503.4,140.1,514.4,139z"
                  />
                </g>
                <g>
                  <path
                    d="M458.8,128.6c2.7-1.5,4.5-4,5.1-6.3c0.6-2.3,0-4.4-1.5-5.3c-1.5-0.9-3.5-0.6-5.3,0.4c-1.8,1-3.5,2.7-4.6,5.2
					c-1.1,2.5-2,4.3-3,6c-1,1.7-2.2,3.4-4.4,5.4c2.8-1.1,4.7-1.7,6.8-2.4C454,130.8,456,130.1,458.8,128.6z"
                  />
                </g>
                <g>
                  <path
                    d="M475.9,123.1c2.3-1.3,3.6-3.6,3.8-5.6c0.2-2.1-0.6-3.8-2.1-4.7c-1.6-0.8-3.4-0.6-5,0.2c-1.6,0.8-2.9,2.3-3.5,4.4
					c-0.7,2.2-1.1,3.8-1.7,5.4c-0.6,1.6-1.4,3.1-3,5c2.3-1,3.9-1.6,5.6-2.2C471.8,125,473.6,124.4,475.9,123.1z"
                  />
                </g>
                <g>
                  <path
                    d="M493,119.2c2.2-1.6,3.5-4,3.7-6.1c0.2-2.1-0.5-4-2.1-4.8c-1.5-0.8-3.5-0.5-5.1,0.5c-1.7,1.1-3.1,2.9-3.7,5.3
					c-0.6,2.5-1,4.3-1.5,6.1c-0.5,1.8-1.2,3.6-2.5,5.8c2.2-1.6,3.8-2.5,5.5-3.5C488.9,121.7,490.7,120.8,493,119.2z"
                  />
                </g>
                <g>
                  <path
                    d="M512.9,117.7c2.4-2.2,3.5-5.3,3.4-7.8c-0.1-2.5-1.3-4.3-3-4.6c-1.7-0.4-3.5,0.6-4.9,2c-1.4,1.4-2.5,3.4-2.9,5.9
					c-0.5,2.6-1,4.5-1.6,6.3c-0.7,1.8-1.6,3.7-3.7,5.8c2.7-1.3,4.5-2.3,6.5-3.3C508.4,121,510.4,119.9,512.9,117.7z"
                  />
                </g>
                <g>
                  <path
                    d="M529.5,112.5c2.1-2.5,3.1-5.6,2.9-8.1c-0.1-2.5-1.2-4.4-2.9-4.8c-1.7-0.5-3.6,0.5-5,2.2c-1.5,1.7-2.6,4.1-3,7
					c-0.4,3-0.6,5.1-1,7.2c-0.4,2.1-1,4.3-2.4,7.1c2.3-2.2,4-3.7,5.7-5.2C525.6,116.6,527.3,115.1,529.5,112.5z"
                  />
                </g>
                <g>
                  <path
                    d="M541.5,108.7c2-2,2.9-4.7,2.9-7c-0.1-2.2-1.2-4-2.8-4.5c-1.7-0.5-3.5,0.2-5,1.5c-1.5,1.4-2.6,3.4-2.9,6
					c-0.3,2.6-0.5,4.5-0.8,6.4c-0.3,1.9-0.8,3.8-2,6.3c2.1-1.9,3.6-3.1,5.2-4.3C537.8,112,539.4,110.8,541.5,108.7z"
                  />
                </g>
                <g>
                  <path
                    d="M558.6,100c2.1-2.3,3.1-5.3,3-7.7c0-2.4-1.1-4.3-2.7-4.9c-1.7-0.5-3.6,0.3-5.1,1.9c-1.5,1.6-2.7,4-3.1,6.8
					c-0.4,2.9-0.6,5-0.9,7.1c-0.4,2.1-0.9,4.2-2.2,7c2.2-2.2,3.8-3.7,5.5-5.1C554.7,103.8,556.5,102.4,558.6,100z"
                  />
                </g>
                <g>
                  <path
                    d="M573,93.6c2.2-1.8,3.6-4.1,4.1-6.2c0.5-2.2,0.2-4.2-1.2-5.3c-1.4-1.1-3.5-0.9-5.5,0.3c-2,1.2-3.9,3.5-4.7,6.5
					c-0.8,3-1.2,5.2-1.5,7.3c-0.4,2.1-0.8,4.2-1.6,7.1c1.8-2.4,3.3-3.9,4.9-5.2C569,96.6,570.7,95.4,573,93.6z"
                  />
                </g>
                <g>
                  <path
                    d="M587.3,86.9c2.3-1.1,3.9-2.9,4.8-4.7c0.8-1.8,0.9-3.8-0.1-5.2c-1-1.4-3.1-1.9-5.2-1.2c-2.2,0.6-4.5,2.4-5.7,5.1
					c-1.3,2.7-1.9,4.7-2.5,6.7c-0.6,2-1.2,3.9-2.3,6.5c1.9-2,3.6-3.2,5.3-4.1C583.1,88.9,584.9,88.1,587.3,86.9z"
                  />
                </g>
                <g>
                  <path
                    d="M602.4,93.3c2.3,0.6,4.4,0.3,6.1-0.6c1.7-0.9,3-2.3,3.1-4.1c0.1-1.7-1-3.4-3.1-4.3c-2-0.9-4.8-1-7.4,0.3
					c-2.6,1.3-4.2,2.6-5.7,3.7c-1.5,1.2-3.1,2.4-5.3,3.9c2.6-0.5,4.5-0.5,6.3-0.2C598.3,92.3,600,92.7,602.4,93.3z"
                  />
                </g>
                <g>
                  <path
                    d="M602.3,107.3c2.1,1.2,4.2,1.5,6.1,1.3c1.9-0.3,3.6-1.1,4.4-2.7c0.8-1.6,0.2-3.6-1.6-5.2c-1.8-1.6-4.7-2.6-7.7-2.1
					c-3.1,0.5-5.1,1.4-7,2.2c-2,0.9-3.8,1.7-6.3,2.8c2.8-0.1,4.7,0.4,6.5,1C598.4,105.2,600.1,106.1,602.3,107.3z"
                  />
                </g>
                <g>
                  <path
                    d="M598.1,120.2c2.4,1.8,5,2.6,7.4,2.6c2.3,0,4.2-0.9,4.9-2.5c0.7-1.6-0.1-3.6-1.8-5.3c-1.7-1.6-4.4-2.9-7.5-3.1
					c-6.3-0.3-8.9,0.5-15.1-0.2C591.6,114.5,593.3,116.5,598.1,120.2z"
                  />
                </g>
                <g>
                  <path
                    d="M455.6,157.1c1.3,2.2,2.9,3.7,4.7,4.7c1.8,0.9,3.8,1.2,5.3,0.3c1.5-0.9,2.1-3,1.3-5.4c-0.7-2.4-2.8-5-5.7-6.3
					c-3-1.3-5.3-1.7-7.4-2.1c-2.2-0.4-4.3-0.7-7.2-1.1c2.6,1.4,4.1,2.9,5.4,4.4C453.2,153.1,454.3,154.8,455.6,157.1z"
                  />
                </g>
                <g>
                  <path
                    d="M472.5,157.1c1.1,2.4,2.4,4.3,3.9,5.8c1.5,1.5,3.1,2.7,4.9,2.5c0.9-0.1,1.6-0.6,2.2-1.6c0.6-0.9,0.9-2.2,0.8-3.8
					c-0.1-1.5-0.5-3.3-1.5-4.9c-0.9-1.6-2.3-3.2-4-4.3c-1.8-1.1-3.4-1.8-4.8-2.2c-1.5-0.4-2.8-0.7-4.1-0.8c-2.6-0.3-5.1-0.3-8.6,0.2
					c3.4,0.6,5.5,1.9,7.1,3.3C470,152.9,471.3,154.7,472.5,157.1z"
                  />
                </g>
                <g>
                  <path
                    d="M487.8,156.6c1.5,2.4,3.4,4.2,5.4,5.3c1.9,1.1,4,1.5,5.5,0.6c1.5-0.9,2-3.1,1.1-5.7c-0.9-2.6-3.1-5.4-6.4-6.9
					c-3.3-1.5-5.7-2.1-8.1-2.5c-2.4-0.5-4.7-0.9-8-1.4c3,1.5,4.7,3.1,6.1,4.7C484.9,152.4,486.2,154.1,487.8,156.6z"
                  />
                </g>
                <g>
                  <path
                    d="M506.5,159.3c2.5,2.3,5.7,3.4,8.3,3.4c2.6,0,4.6-1.1,5.1-2.7c0.5-1.7-0.5-3.6-2.2-5.1c-1.7-1.6-4.2-2.8-7.2-3.3
					c-3-0.5-5.2-0.9-7.4-1.4c-2.2-0.5-4.3-1.3-7.2-2.9c2.2,2.5,3.6,4.3,5.1,6.1C502.5,155.1,504,157,506.5,159.3z"
                  />
                </g>
                <g>
                  <path
                    d="M525.3,155.5c3.6,1.7,7.5,2.2,10.5,1.7c3-0.5,5.1-1.9,5.2-3.6c0.2-1.8-1.5-3.5-4.1-4.7c-2.6-1.2-6-2-9.7-2
					c-3.8,0.1-6.5,0.2-9.2,0.1c-2.7-0.1-5.5-0.3-9.4-1.3c3.5,1.9,5.9,3.4,8.3,4.9C519.2,152.2,521.6,153.7,525.3,155.5z"
                  />
                </g>
                <g>
                  <path
                    d="M549,150.1c6.4,2.5,13.2,0.2,13.4-3.3c0.2-3.5-6-6.6-12.5-5.4c-6.7,1.1-9.4,2.3-16.4,2.3
					C540.3,145.7,542.5,147.5,549,150.1z"
                  />
                </g>
                <g>
                  <path
                    d="M568.8,144.1c3.4,1.2,7.1,1.2,9.7,0.4c2.7-0.8,4.5-2.3,4.5-4.1c0-1.8-1.7-3.3-4.2-4.3c-2.5-1-5.8-1.4-9.2-0.8
					c-3.5,0.6-6,1.1-8.5,1.5c-2.5,0.4-5.1,0.6-8.8,0.4c3.5,1.2,5.9,2.3,8.2,3.4C562.9,141.7,565.2,142.8,568.8,144.1z"
                  />
                </g>
                <g>
                  <path
                    d="M580.9,135.9c1.6,1.6,3.5,2.2,5.3,2.2c1.8,0,3.5-0.8,4.4-2.3c0.9-1.5,0.7-3.5-0.5-5.1c-1.2-1.6-3.4-2.9-6.1-3
					c-2.7,0-4.6,0.3-6.4,0.5c-1.8,0.3-3.6,0.5-6,0.6c2.3,0.9,3.7,1.9,5,3C578.1,133,579.3,134.2,580.9,135.9z"
                  />
                </g>
                <g>
                  <path
                    d="M591.4,129.9c3.6,4.4,9.6,5,11.5,2c1.9-3-1.3-8.1-6.8-9.4c-5.7-1.4-8.2-1.2-13.6-3.3
					C586.8,123.1,587.7,125.4,591.4,129.9z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M191.4,346.1c2,10.5,5.5,20.9,10.2,29.9c4.7,9.1,10.8,16.7,16.8,22.3c6,5.6,11.9,9.2,16.3,11.1c4.4,1.9,7.2,2.3,7.5,1.5
					c0.9-1.7-8.1-7.1-17.8-18.4c-4.8-5.7-9.8-12.8-14-21.1c-4.2-8.3-7.8-17.6-10.5-27.4c-2.8-10-4.2-18.8-5.5-26.9
					c-1.3-8.1-2.4-15.4-3.8-22.7c-1.4-7.3-3.2-14.7-6-22.6c-1.4-4-3.2-8.1-5.4-12.3c-2.2-4.2-5.1-8.5-8.7-12.7
					c6.8,8.8,10.2,17.6,12.4,25.5c2.2,8,3.2,15.3,4,22.6c0.7,7.3,1,14.6,1.5,22.8C188.8,326.1,189.3,335.3,191.4,346.1z"
                  />
                </g>
                <g>
                  <path
                    d="M197,289.9c1.7,2.6,4.4,4.1,6.8,4.5c2.4,0.4,4.4-0.3,5.2-1.9c0.8-1.6,0.3-3.5-0.8-5.3c-1.2-1.7-3-3.3-5.5-4.1
					c-2.6-0.9-4.5-1.6-6.3-2.5c-1.8-0.9-3.6-1.9-5.7-3.9c1.3,2.7,2.1,4.6,3,6.6C194.4,285.3,195.3,287.3,197,289.9z"
                  />
                </g>
                <g>
                  <path
                    d="M204,306.5c1.5,2.2,3.9,3.3,5.9,3.4c2.1,0.1,3.8-0.9,4.5-2.5c0.7-1.6,0.3-3.5-0.6-4.9c-1-1.5-2.5-2.6-4.7-3.1
					c-2.2-0.5-3.9-0.8-5.6-1.3c-1.6-0.5-3.2-1.2-5.2-2.6c1.2,2.2,1.9,3.8,2.7,5.4C201.7,302.5,202.5,304.3,204,306.5z"
                  />
                </g>
                <g>
                  <path
                    d="M209.3,323.1c1.8,2.1,4.2,3.1,6.4,3.2c2.1,0.1,3.9-0.8,4.6-2.5c0.7-1.6,0.2-3.5-1-5.1c-1.2-1.6-3.1-2.8-5.6-3.2
					c-2.5-0.4-4.4-0.6-6.2-1c-1.8-0.4-3.6-0.9-6-2c1.7,2,2.8,3.6,3.9,5.2C206.4,319.3,207.5,321,209.3,323.1z"
                  />
                </g>
                <g>
                  <path
                    d="M212.5,342.8c2.4,2.2,5.6,3,8,2.7c2.5-0.3,4.1-1.7,4.3-3.4c0.2-1.8-0.9-3.4-2.4-4.7c-1.5-1.3-3.6-2.2-6.1-2.4
					c-2.6-0.3-4.5-0.6-6.4-1.1c-1.9-0.5-3.8-1.3-6.1-3.2c1.5,2.5,2.6,4.3,3.8,6.2C208.8,338.7,210.1,340.6,212.5,342.8z"
                  />
                </g>
                <g>
                  <path
                    d="M219.1,359c2.6,1.9,5.8,2.6,8.3,2.2c2.5-0.3,4.3-1.6,4.6-3.3c0.3-1.7-0.8-3.5-2.7-4.8c-1.8-1.3-4.4-2.3-7.3-2.4
					c-3-0.1-5.1-0.1-7.3-0.4c-2.2-0.2-4.3-0.6-7.3-1.8c2.4,2.1,4,3.6,5.6,5.2C214.7,355.4,216.3,357,219.1,359z"
                  />
                </g>
                <g>
                  <path
                    d="M223.9,370.7c2.2,1.9,5,2.5,7.2,2.2c2.2-0.3,3.9-1.5,4.3-3.2c0.4-1.7-0.5-3.5-2-4.8c-1.5-1.4-3.7-2.3-6.2-2.3
					c-2.6-0.1-4.6-0.1-6.5-0.2c-1.9-0.2-3.8-0.5-6.4-1.5c2,1.9,3.4,3.4,4.7,4.9C220.3,367.2,221.6,368.7,223.9,370.7z"
                  />
                </g>
                <g>
                  <path
                    d="M234,386.9c2.5,1.9,5.5,2.6,7.9,2.4c2.4-0.2,4.2-1.4,4.6-3.1c0.4-1.7-0.6-3.5-2.4-4.9c-1.8-1.4-4.2-2.4-7.1-2.5
					c-3-0.1-5.1-0.2-7.2-0.3c-2.1-0.2-4.2-0.5-7.2-1.6c2.4,2,4,3.5,5.6,5C229.9,383.4,231.4,385,234,386.9z"
                  />
                </g>
                <g>
                  <path
                    d="M241.6,400.7c2,2.1,4.3,3.2,6.5,3.5c2.2,0.3,4.2-0.2,5.2-1.7c1-1.5,0.6-3.6-0.8-5.5c-1.4-1.9-3.8-3.6-6.8-4.2
					c-3.1-0.6-5.3-0.7-7.4-0.9c-2.1-0.2-4.3-0.4-7.2-1c2.6,1.6,4.2,3,5.6,4.5C238.2,397,239.6,398.6,241.6,400.7z"
                  />
                </g>
                <g>
                  <path
                    d="M249.5,414.4c1.3,2.2,3.2,3.7,5.1,4.3c1.9,0.7,3.9,0.6,5.2-0.6c1.3-1.2,1.6-3.2,0.7-5.3c-0.8-2.1-2.8-4.2-5.5-5.3
					c-2.8-1.1-4.9-1.5-6.9-1.9c-2-0.4-3.9-0.9-6.6-1.7c2.2,1.8,3.5,3.3,4.6,4.9C247.1,410.4,248.1,412.1,249.5,414.4z"
                  />
                </g>
                <g>
                  <path
                    d="M244.4,430c-0.4,2.4,0.1,4.4,1.1,6c1,1.6,2.6,2.7,4.3,2.7c1.8,0,3.3-1.3,4-3.4c0.7-2.1,0.6-4.9-0.9-7.3
					c-1.5-2.5-2.9-4-4.2-5.4c-1.3-1.4-2.6-2.9-4.3-4.9c0.7,2.6,0.8,4.4,0.7,6.2C245.1,425.8,244.8,427.6,244.4,430z"
                  />
                </g>
                <g>
                  <path
                    d="M230.5,431.1c-1,2.2-1.2,4.3-0.7,6.2c0.4,1.9,1.4,3.5,3.1,4.2c1.6,0.6,3.6-0.1,5-2.1c1.4-1.9,2.2-4.9,1.4-7.9
					c-0.8-3-1.8-4.9-2.8-6.8c-1-1.9-2-3.7-3.3-6.1c0.3,2.8,0,4.7-0.4,6.6C232.2,427,231.5,428.8,230.5,431.1z"
                  />
                </g>
                <g>
                  <path
                    d="M217.3,428c-1.6,2.5-2.2,5.3-1.9,7.5c0.2,2.3,1.2,4.1,2.9,4.7c1.7,0.5,3.6-0.4,5.1-2.2c1.5-1.9,2.5-4.7,2.4-7.7
					c-0.3-6.3-1.2-8.8-1.1-15C222.4,421,220.5,422.8,217.3,428z"
                  />
                </g>
                <g>
                  <path
                    d="M168.4,289.1c-2.1,1.5-3.5,3.2-4.2,5.1c-0.7,1.9-0.9,3.9,0.2,5.3c1,1.4,3.2,1.8,5.5,0.9c2.3-0.9,4.7-3.2,5.8-6.3
					c1.1-3.2,1.3-5.4,1.5-7.6c0.2-2.2,0.3-4.3,0.4-7.3c-1.2,2.7-2.5,4.3-3.9,5.7C172.1,286.4,170.5,287.6,168.4,289.1z"
                  />
                </g>
                <g>
                  <path
                    d="M169.7,305.9c-2.3,1.3-4,2.7-5.5,4.3c-1.4,1.6-2.5,3.4-2.1,5.1c0.2,0.9,0.8,1.6,1.7,2c1,0.5,2.3,0.7,3.8,0.5
					c1.5-0.2,3.2-0.8,4.8-1.9c1.5-1.1,3-2.6,3.9-4.4c1-1.9,1.5-3.5,1.8-5c0.3-1.5,0.4-2.8,0.5-4.1c0-2.6-0.1-5.2-0.9-8.6
					c-0.3,3.5-1.4,5.6-2.7,7.4C173.7,303.1,172.1,304.6,169.7,305.9z"
                  />
                </g>
                <g>
                  <path
                    d="M171.5,321.1c-2.3,1.7-3.9,3.7-4.8,5.8c-0.9,2-1.2,4.1-0.1,5.5c1,1.4,3.3,1.7,5.8,0.6c2.5-1.1,5.1-3.6,6.3-6.9
					c1.2-3.4,1.6-5.9,1.8-8.3c0.3-2.4,0.5-4.7,0.7-8.1c-1.3,3.1-2.6,4.9-4.1,6.5C175.6,317.9,173.9,319.3,171.5,321.1z"
                  />
                </g>
                <g>
                  <path
                    d="M170.5,340.1c-2.1,2.7-2.9,6-2.6,8.5c0.2,2.6,1.4,4.5,3.2,4.8c1.7,0.3,3.5-0.8,4.9-2.6c1.4-1.9,2.4-4.4,2.7-7.4
					c0.3-3.1,0.4-5.3,0.8-7.5c0.4-2.2,0.9-4.4,2.3-7.4c-2.3,2.4-3.9,4-5.6,5.6C174.4,335.7,172.6,337.3,170.5,340.1z"
                  />
                </g>
                <g>
                  <path
                    d="M175.9,358.4c-1.4,3.7-1.5,7.7-0.8,10.6c0.7,3,2.3,4.9,4,4.9c1.8,0,3.3-1.8,4.3-4.5c1-2.7,1.5-6.2,1.1-9.9
					c-0.4-3.8-0.7-6.5-0.9-9.2c-0.2-2.7-0.2-5.5,0.4-9.4c-1.6,3.7-2.9,6.2-4.2,8.7C178.7,352.1,177.4,354.6,175.9,358.4z"
                  />
                </g>
                <g>
                  <path
                    d="M183.3,381.6c-1.9,6.6,0.9,13.2,4.4,13.1c3.5-0.1,6-6.5,4.4-12.9c-1.7-6.6-3.1-9.1-3.7-16.1
					C186.9,372.5,185.3,374.9,183.3,381.6z"
                  />
                </g>
                <g>
                  <path
                    d="M190.9,400.8c-0.9,3.5-0.6,7.1,0.4,9.7c1,2.6,2.7,4.3,4.5,4.1c1.8-0.1,3.2-2,3.9-4.5c0.8-2.6,0.9-5.9,0-9.3
					c-0.9-3.5-1.6-5.9-2.2-8.4c-0.6-2.5-1-5-1.2-8.7c-0.9,3.6-1.8,6-2.6,8.5C192.8,394.7,191.9,397.2,190.9,400.8z"
                  />
                </g>
                <g>
                  <path
                    d="M200.2,412.2c-1.4,1.7-1.9,3.7-1.8,5.5c0.2,1.8,1.1,3.4,2.7,4.2c1.6,0.8,3.5,0.4,5-1c1.5-1.3,2.6-3.7,2.5-6.3
					c-0.2-2.7-0.6-4.5-1.1-6.3c-0.4-1.8-0.8-3.5-1.1-6c-0.7,2.4-1.6,3.9-2.6,5.3C202.8,409.1,201.7,410.4,200.2,412.2z"
                  />
                </g>
                <g>
                  <path d="M207,422.2c-4,4-4.1,10-1,11.6c3.1,1.6,8-2,8.8-7.6c0.9-5.8,0.5-8.2,2.1-13.8C213.4,417,211.1,418.1,207,422.2z" />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M378.5,8.1c9.5-5,18.3-11.4,25.5-18.6c7.3-7.2,12.8-15.2,16.3-22.6c3.6-7.4,5.3-14.1,5.8-18.9c0.5-4.8,0-7.5-0.8-7.6
					c-1.9-0.3-4.3,9.8-12.3,22.5c-4,6.3-9.3,13.2-16,19.6c-6.6,6.5-14.6,12.7-23,18.1c-8.7,5.6-16.7,9.6-24,13.2
					c-7.3,3.6-14,6.8-20.6,10.4c-6.6,3.6-13.1,7.4-19.8,12.5c-3.4,2.5-6.8,5.4-10.1,8.8c-3.4,3.4-6.6,7.4-9.6,12
					c6.4-9.1,13.8-14.9,20.7-19.4c7-4.5,13.7-7.6,20.5-10.5c6.8-2.8,13.7-5.3,21.4-8.2C360.1,16.5,368.7,13.3,378.5,8.1z"
                  />
                </g>
                <g>
                  <path
                    d="M323.1,19.3c2-2.4,2.7-5.4,2.3-7.8c-0.3-2.4-1.6-4.1-3.3-4.4c-1.7-0.3-3.5,0.7-4.8,2.3c-1.3,1.6-2.2,3.9-2.3,6.5
					c-0.1,2.7-0.2,4.7-0.5,6.7c-0.3,2-0.8,4-2.1,6.6c2.2-2,3.7-3.4,5.4-4.8C319.4,23.2,321.1,21.8,323.1,19.3z"
                  />
                </g>
                <g>
                  <path
                    d="M336.9,7.8c1.6-2.1,2-4.7,1.5-6.6c-0.5-2-2-3.4-3.7-3.5c-1.8-0.2-3.4,0.7-4.5,2.1c-1.1,1.4-1.8,3.2-1.6,5.4
					c0.2,2.3,0.4,4,0.4,5.7c0,1.7-0.1,3.4-1,5.8c1.7-1.8,3-3,4.4-4.2C333.8,11.1,335.2,9.9,336.9,7.8z"
                  />
                </g>
                <g>
                  <path
                    d="M351.2-2.2c1.5-2.3,1.7-5,1.1-7c-0.6-2.1-2-3.5-3.7-3.7c-1.8-0.2-3.4,0.8-4.6,2.4c-1.1,1.6-1.8,3.8-1.4,6.3
					c0.4,2.6,0.7,4.4,0.9,6.3c0.2,1.9,0.3,3.7-0.1,6.4c1.4-2.3,2.6-3.8,3.8-5.3C348.4,1.6,349.7,0.1,351.2-2.2z"
                  />
                </g>
                <g>
                  <path
                    d="M369.1-11.1c1.4-2.9,1.2-6.2,0.2-8.4c-1-2.3-2.9-3.4-4.6-3.1c-1.7,0.3-3,1.8-3.8,3.7c-0.8,1.9-1,4.1-0.5,6.5
					c0.5,2.6,0.8,4.5,0.9,6.5c0,2-0.1,4-1.2,6.8c2-2.2,3.4-3.8,4.8-5.5C366.2-6.4,367.6-8.1,369.1-11.1z"
                  />
                </g>
                <g>
                  <path
                    d="M382.6-22.2c1-3.1,0.7-6.3-0.3-8.6c-1-2.3-2.8-3.6-4.5-3.4c-1.8,0.2-3.1,1.8-3.8,4c-0.7,2.1-0.9,4.8-0.1,7.6
					c0.8,2.9,1.4,5,1.8,7.1c0.4,2.1,0.7,4.3,0.4,7.5c1.3-2.9,2.3-4.9,3.3-6.9C380.4-16.9,381.5-19,382.6-22.2z"
                  />
                </g>
                <g>
                  <path
                    d="M392.3-30.2c1.1-2.7,1-5.5,0-7.5c-0.9-2-2.6-3.3-4.3-3.1c-1.8,0.1-3.2,1.5-4,3.3c-0.8,1.8-1.1,4.2-0.4,6.6
					c0.7,2.5,1.3,4.4,1.7,6.3c0.4,1.9,0.7,3.8,0.5,6.6c1.2-2.5,2.2-4.2,3.2-5.9C390-25.7,391.1-27.5,392.3-30.2z"
                  />
                </g>
                <g>
                  <path
                    d="M404.8-44.7c1.1-3,0.9-6-0.1-8.3c-1-2.2-2.6-3.6-4.4-3.5c-1.8,0.1-3.2,1.6-4,3.7c-0.8,2.1-1,4.7-0.3,7.5
					c0.8,2.9,1.4,4.9,1.8,7c0.4,2.1,0.8,4.2,0.6,7.3c1.2-2.9,2.1-4.8,3.1-6.8C402.6-39.7,403.7-41.7,404.8-44.7z"
                  />
                </g>
                <g>
                  <path
                    d="M415.7-56.1c1.4-2.5,1.8-5.1,1.4-7.3c-0.3-2.2-1.4-3.9-3.1-4.4c-1.7-0.5-3.6,0.4-5,2.3c-1.4,1.9-2.3,4.7-1.9,7.8
					c0.4,3.1,0.9,5.2,1.3,7.3c0.5,2.1,0.9,4.2,1.2,7.2c0.8-2.9,1.6-4.9,2.6-6.7C413.2-51.8,414.3-53.5,415.7-56.1z"
                  />
                </g>
                <g>
                  <path
                    d="M426.4-67.6c1.7-1.9,2.5-4.1,2.6-6.1c0.1-2-0.6-3.9-2.1-4.8c-1.5-0.9-3.5-0.6-5.3,0.9c-1.8,1.4-3.2,3.9-3.4,6.8
					c-0.2,3,0,5.1,0.2,7.1c0.2,2,0.3,4,0.3,6.9c1-2.6,2.1-4.3,3.3-5.8C423.3-64.2,424.7-65.6,426.4-67.6z"
                  />
                </g>
                <g>
                  <path
                    d="M442.9-67.4c2.4-0.3,4.2-1.4,5.4-2.9c1.2-1.4,1.9-3.2,1.3-4.9c-0.5-1.7-2.2-2.8-4.4-2.8c-2.2-0.1-4.8,0.9-6.7,3.1
					c-1.9,2.2-2.9,3.9-3.9,5.6c-1,1.7-2,3.3-3.4,5.6c2.2-1.5,4-2.1,5.7-2.6C438.6-66.8,440.4-67.1,442.9-67.4z"
                  />
                </g>
                <g>
                  <path
                    d="M448-54.4c2.4,0.3,4.5-0.2,6.2-1.1c1.7-1,3-2.4,3.1-4.2c0.1-1.7-1.2-3.4-3.4-4.2c-2.2-0.8-5.3-0.6-7.9,1
					c-2.7,1.7-4.2,3.2-5.7,4.6c-1.5,1.6-2.9,3-4.8,5c2.6-1.1,4.5-1.4,6.4-1.5C443.6-54.9,445.5-54.7,448-54.4z"
                  />
                </g>
                <g>
                  <path
                    d="M449-40.9c2.9,0.8,5.7,0.5,7.8-0.4c2.1-0.9,3.6-2.4,3.6-4.2c0-1.8-1.4-3.3-3.6-4.2c-2.2-0.9-5.2-1-8.1,0
					c-6,2.1-8,3.8-14,5.5C440.8-43.7,443.1-42.4,449-40.9z"
                  />
                </g>
                <g>
                  <path
                    d="M330.8,46.9c2,1.6,4.1,2.4,6.1,2.5c2,0.2,3.9-0.3,5-1.7c1-1.4,0.8-3.5-0.8-5.5c-1.5-1.9-4.4-3.6-7.7-3.6
					c-3.3-0.1-5.5,0.4-7.7,0.8c-2.1,0.5-4.2,1-7.1,1.7c3,0.3,4.9,1.1,6.6,2C327.1,44.2,328.7,45.3,330.8,46.9z"
                  />
                </g>
                <g>
                  <path
                    d="M346.5,40.6c1.9,1.8,3.8,3.1,5.8,3.9c1.9,0.8,3.9,1.4,5.5,0.5c0.8-0.4,1.3-1.2,1.4-2.3c0.2-1.1,0-2.4-0.7-3.8
					c-0.6-1.4-1.7-2.8-3.2-4c-1.5-1.2-3.3-2.1-5.3-2.4c-2.1-0.4-3.8-0.4-5.3-0.3c-1.5,0.1-2.8,0.4-4.1,0.8c-2.5,0.7-4.9,1.6-7.9,3.4
					c3.4-0.8,5.8-0.3,7.9,0.4C342.6,37.7,344.5,38.8,346.5,40.6z"
                  />
                </g>
                <g>
                  <path
                    d="M360.5,34.4c2.3,1.7,4.7,2.6,6.9,2.9c2.2,0.3,4.3-0.1,5.3-1.5c1-1.4,0.7-3.6-1.1-5.7c-1.8-2.1-4.9-3.8-8.5-4
					c-3.6-0.2-6.1,0.3-8.5,0.7c-2.4,0.5-4.7,1-7.9,1.7c3.3,0.3,5.5,1.1,7.4,2C356.2,31.5,358.1,32.7,360.5,34.4z"
                  />
                </g>
                <g>
                  <path
                    d="M378.8,29.8c3.2,1.2,6.5,1,8.9,0c2.4-1,3.8-2.7,3.7-4.4c-0.2-1.8-1.8-3.1-4-3.9c-2.2-0.8-5-1-7.9-0.4
					c-3,0.7-5.2,1.2-7.4,1.5c-2.2,0.3-4.5,0.4-7.8,0c3,1.5,5,2.6,7,3.7C373.5,27.4,375.6,28.6,378.8,29.8z"
                  />
                </g>
                <g>
                  <path
                    d="M394.8,19.2c4,0.3,7.8-0.8,10.4-2.4c2.6-1.6,4-3.6,3.5-5.3c-0.5-1.7-2.7-2.6-5.6-2.8c-2.9-0.2-6.4,0.4-9.8,1.9
					c-3.5,1.5-6,2.6-8.5,3.6c-2.5,1-5.2,1.8-9.2,2.4c4,0.5,6.7,0.9,9.5,1.4C387.9,18.4,390.7,19,394.8,19.2z"
                  />
                </g>
                <g>
                  <path
                    d="M414.8,5.3c6.9-0.1,12.4-4.8,11.2-8.1c-1.2-3.3-8-3.8-13.6-0.3c-5.8,3.6-7.8,5.6-14.3,8.3C405,4.5,407.7,5.3,414.8,5.3z
					"
                  />
                </g>
                <g>
                  <path
                    d="M430.8-7.7c3.7-0.2,7-1.5,9.2-3.3c2.2-1.7,3.3-3.8,2.6-5.5c-0.7-1.6-2.8-2.4-5.5-2.4c-2.7,0-5.9,0.9-8.8,2.7
					c-3,1.9-5.1,3.3-7.3,4.6c-2.2,1.3-4.5,2.5-8,3.7c3.7-0.2,6.3-0.1,8.9,0C424.5-7.7,427.1-7.5,430.8-7.7z"
                  />
                </g>
                <g>
                  <path
                    d="M439-19.9c2.1,0.9,4.1,0.8,5.8,0c1.6-0.7,3-2.1,3.3-3.8c0.3-1.7-0.7-3.5-2.4-4.5c-1.7-1.1-4.3-1.4-6.7-0.5
					c-2.5,1-4.1,2-5.7,2.9c-1.6,0.9-3.1,1.8-5.4,2.9c2.5,0,4.2,0.4,5.8,0.9C435.2-21.5,436.8-20.8,439-19.9z"
                  />
                </g>
                <g>
                  <path d="M446.5-29.3c5,2.7,10.8,1,11.4-2.5c0.6-3.5-4.3-7-9.9-6.2c-5.8,0.9-8,2-13.8,2C439.6-33.9,441.3-32.1,446.5-29.3z" />
                </g>
              </g>
              <g>
                <path
                  d="M533.7,299.2c0.6-0.6,1.1-1.2,1.6-1.8c0.5-0.7,1-1.3,1.5-2c0.9-1.4,1.8-2.8,2.4-4.3c1.4-3,2.2-6.2,2.6-9.4
				c0.8-6.4-0.3-12.8-2.8-18.6c-1.2-2.9-3-5.7-5-8.1c-2-2.4-4.2-4.5-6.6-6.4c-4.8-3.6-10.2-6.1-15.6-7.6c-2.7-0.7-5.5-1.3-8.2-1.5
				c-2.7-0.3-5.5-0.3-8.1-0.2c-5.4,0.3-10.8,1.5-15.7,3.8c-5,2.3-9.4,5.8-12.6,10c-1.6,2.1-2.9,4.5-3.9,6.9
				c-0.9,2.4-1.5,5.1-1.6,7.7c-0.3,5.1,1,10,3.5,14.1c1.3,2,2.9,3.8,4.7,5.2c1.8,1.4,3.8,2.4,5.8,3.1c4,1.3,8,1.3,11.5,0.3
				c3.5-1,6.4-3.3,8-5.7c1.7-2.4,2.4-4.9,2.6-7c0.2-2.1-0.1-4-0.6-5.3c-0.5-1.4-1.2-2.3-1.7-2.8c-0.6-0.5-1-0.6-1.3-0.5
				c-0.6,0.3-0.4,1.7-0.7,3.8c-0.1,1-0.4,2.2-1.1,3.5c-0.6,1.2-1.5,2.6-2.7,3.6c-0.6,0.5-1.3,0.9-2,1.3c-0.7,0.3-1.6,0.5-2.5,0.6
				c-1.8,0.2-3.8-0.2-5.7-1.2c-0.9-0.5-1.8-1.1-2.5-1.9c-0.7-0.7-1.3-1.6-1.8-2.7c-1-2.1-1.4-4.7-1-7.3c0.2-1.3,0.6-2.5,1.2-3.8
				c0.6-1.2,1.4-2.4,2.4-3.6c1-1.1,2.1-2.2,3.4-3.1c1.3-0.9,2.7-1.7,4.3-2.3c6.2-2.5,14.7-2.8,22.4-0.6c3.8,1.1,7.5,2.8,10.6,5.1
				c1.5,1.2,3,2.5,4.2,3.9c0.6,0.7,1.1,1.5,1.7,2.2l0.7,1.2l0.6,1.3c1.6,3.4,2.4,7.4,2.2,11.1c-0.1,1.9-0.5,3.7-1.2,5.4
				c-0.3,0.9-0.7,1.6-1.2,2.4l-0.7,1.1c-0.3,0.4-0.5,0.7-0.9,1.1c-2.5,3-6.1,5.2-10.1,6.9c-2,0.9-4.1,1.6-6.1,2.2
				c-1.1,0.3-2.1,0.6-3.2,0.9c-1.1,0.3-2.1,0.6-3.1,0.8c-8.4,1.9-16.5,2.6-24.2,2.5c-7.7-0.1-15.1-1.1-22.2-2.7
				c-7.1-1.6-13.8-3.8-20.3-6.6c-13-5.7-25.3-13.9-36.8-25.5c-5.7-5.8-11.3-12.5-16.4-20.3c-5.2-7.7-9.9-16.7-13.9-26.8
				c3.7,10.3,8,19.4,12.8,27.5c4.8,8.1,10,15.2,15.6,21.4c11.1,12.5,23,21.8,36.3,28.8c6.6,3.5,13.5,6.4,20.9,8.7
				c7.4,2.3,15.2,3.9,23.5,4.7c8.4,0.8,17.3,0.7,26.8-0.7c1.2-0.1,2.4-0.4,3.6-0.6c1.2-0.2,2.4-0.5,3.6-0.7c2.5-0.6,5-1.2,7.6-2.1
				c2.6-0.9,5.2-2,7.8-3.5C528.7,303.5,531.3,301.6,533.7,299.2z"
                />
              </g>
              <g>
                <path
                  d="M454.1,257.7c3.8-5.9,5.1-13.2,3.9-20.1c-0.6-3.4-1.9-6.8-3.7-9.7c-1.8-2.9-4.1-5.5-6.7-7.6c-2.6-2.1-5.5-3.7-8.6-4.7
				c-3.1-1-6.3-1.5-9.5-1.3c-3.2,0.2-6.3,0.9-9.1,2.4c-0.7,0.3-1.4,0.8-2,1.2c-0.7,0.5-1.3,0.9-1.9,1.4c-1.2,1-2.2,2.1-3.1,3.3
				c-1.7,2.4-2.8,5-3.3,7.6c-0.5,2.6-0.3,5.3,0.4,7.7c0.8,2.5,2.1,4.4,3.6,5.7c1.5,1.4,3,2.3,4.5,2.9c1.5,0.6,2.9,0.8,4.1,0.8
				c0.6,0,1.2-0.1,1.7-0.2c0.5-0.1,0.9-0.3,1.3-0.4c0.8-0.4,1.3-0.8,1.5-1.2c0.3-0.4,0.4-0.7,0.3-1.1c-0.2-0.7-1-1-2-1.6
				c-1-0.6-2.2-1.3-3.3-2.6c-1.1-1.2-1.9-3.1-1.8-4.5c0.1-0.9,0.2-1.7,0.7-2.6c0.4-0.9,1.1-1.8,1.9-2.6c0.4-0.4,0.9-0.7,1.3-1
				c0.2-0.1,0.5-0.2,0.7-0.4c0.3-0.1,0.5-0.2,0.8-0.3c1.1-0.4,2.4-0.5,3.9-0.5c2.9,0.1,5.9,1.3,8.6,3.4c1.3,1.1,2.5,2.4,3.4,3.8
				c0.9,1.5,1.6,3,2,4.7c0.8,3.4,0.5,7.4-1.1,10.7c-1.6,3.5-4.6,6.3-7.9,8.3c-3.4,1.9-7,3-10.6,3.3c-3.7,0.4-7.3,0.1-10.9-0.6
				c-3.5-0.7-7-1.8-10.4-3.2c-3.4-1.4-6.6-3.1-9.9-5.2c-3.2-2.1-6.3-4.6-9.4-7.5c-3-2.9-6-6.3-8.7-10.3c-2.7-4-5.2-8.6-7.1-14
				c3.2,11,8.3,19.4,13.9,26.1c2.8,3.4,5.7,6.3,8.8,9c3.1,2.6,6.3,4.9,9.7,7c3.4,2.1,7,3.8,11,5.2c3.9,1.4,8.2,2.4,13,2.7
				c4.7,0.3,10-0.4,15.2-2.5c2.6-1,5.2-2.5,7.8-4.4C449.8,263.2,452.2,260.8,454.1,257.7z"
                />
              </g>
              <g>
                <path
                  d="M336.8,288.9c-1.6,6.2-2,12.8-1.5,18.8c0.5,6,2,11.6,3.9,16c1.9,4.5,4.2,7.8,6.2,9.9c2.1,2.1,3.9,2.9,5,2.3
				c1.2-0.6,1.7-2.5,1.6-5.1c0.1-2.7-0.4-6.2-0.8-10.4c-0.4-4.1-0.9-8.8-1.2-13.8c-0.3-5-0.4-10.4-0.3-15.8
				c0.1-5.6,0.8-10.4,1.7-14.7c0.9-4.3,2.2-8.3,3.4-12.5c1.2-4.1,2.4-8.3,3.2-13.2c0.4-2.4,0.6-5,0.6-7.8c0-2.8-0.4-5.7-1.3-8.7
				c0.5,3.1,0.5,6,0.1,8.6c-0.4,2.6-1.1,5-1.9,7.2c-1.6,4.4-3.7,8-5.9,11.6c-2.2,3.6-4.5,7.2-6.8,11.5
				C340.7,277.4,338.5,282.5,336.8,288.9z"
                />
              </g>
              <g>
                <path
                  d="M350.5,287.2c-2,4.9-4.1,10-5.6,15.3c-1.5,5.3-2.2,10.7-1.9,15.5c0.3,4.8,1.5,8.7,3,11.2c1.5,2.5,3.2,3.5,4.5,3.1
				c1.3-0.4,2-2,2.7-4.3c0.7-2.3,1.4-5.3,2.3-8.9c0.9-3.6,2.2-7.7,3.7-12.4c1.4-4.7,3-10.1,4-15.9c0.9-6,1.2-11.1,1.2-15.7
				c0-4.6-0.4-8.7-0.9-12.8c-0.5-4.1-1.2-8.1-2.1-12.5c-1-4.4-2.2-9.3-4.1-14.9c1.1,5.8,1.4,10.8,1.4,15.2c0,4.4-0.4,8.4-0.9,12.2
				c-0.6,3.8-1.4,7.6-2.5,11.6C354.1,277.9,352.6,282.2,350.5,287.2z"
                />
              </g>
              <g>
                <path
                  d="M393.3,330.8c-0.5,0.7-1,1.3-1.5,1.9c-0.5,0.6-1.1,1.1-1.6,1.6c-1.2,0.9-2.4,1.7-3.7,2.3c-1.3,0.6-2.5,0.8-3.7,0.9
				c-1.2,0.1-2.1,0-3.2-0.4c-2.1-0.6-3.9-2.2-4.8-3.6c-0.4-0.7-0.7-1.4-0.8-2.3c-0.1-0.9,0-1.8,0.2-2.8c0.5-1.9,1.6-3.7,2.7-4.9
				c1.2-1.2,2-1.6,2.4-2c0.3-0.1,0.8-0.1,1.2,0c0.4,0.1,0.8,0.3,1.1,0.5c0.7,0.4,1.1,1.1,1.7,1.4c0.3,0.1,0.7,0.1,1.1-0.1
				c0.4-0.3,0.8-0.8,1.1-1.6c0.2-0.8,0.2-2-0.2-3.1c-0.4-1.2-1.2-2.4-2.6-3.5c-1.6-1.1-3.4-1.5-5.4-1.5c-1.9,0-3.8,0.5-5.6,1.3
				c-3.5,1.6-6.7,4.5-9,8.7c-1.1,2.1-1.9,4.6-2.1,7.3c-0.2,2.7,0.2,5.8,1.4,8.5c1.1,2.8,2.9,5.2,5.1,7.2c2.2,2,4.8,3.6,7.7,4.6
				c2.9,1,6.3,1.4,9.3,1.1c3.1-0.3,6.1-1.2,8.7-2.6c2.7-1.4,5-3.2,7.1-5.3c1-1.1,1.9-2.2,2.8-3.4c0.8-1.2,1.5-2.4,2.1-3.7
				c2.5-5.3,3.2-10.4,3.2-14.9c0-4.6-0.8-8.7-1.8-12.3c-1-3.7-2.3-6.9-3.6-10c-1.3-3.1-2.7-6-4.2-8.9c-1.5-2.9-3-5.7-4.8-8.6
				c-1.7-2.9-3.5-5.8-5.6-8.7c-4.1-6-8.9-12.3-15.4-19.3c5.9,7.4,9.9,14.3,13.1,20.6c1.6,3.2,3,6.2,4.2,9.2c1.2,3,2.2,6,3.2,8.9
				c0.9,3,1.7,6,2.4,9c0.7,3.1,1.3,6.2,1.6,9.4c0.3,3.2,0.3,6.5-0.3,9.8C396.4,324.7,395.3,328,393.3,330.8z"
                />
              </g>
              <g>
                <path
                  d="M211.1,46.3c3.4-0.8,6.9-1,10.2-0.6c3.3,0.4,6.4,1.5,8.9,3.1c2.6,1.6,4.7,3.9,6.2,6.4c0.7,1.2,1.3,2.5,1.7,3.8
				c0.4,1.3,0.5,2.6,0.6,3.9c0.1,5.3-3,11-7.3,15.2c-1.1,1.1-2.2,2.1-3.4,2.9c-1.2,0.9-2.4,1.7-3.5,2.4c-2.4,1.4-4.7,2.1-6.6,2.3
				c-1,0.1-1.9,0-2.6-0.2c-0.8-0.2-1.6-0.5-2.3-1c-1.5-1-2.7-2.4-3.5-3.9c-0.4-0.8-0.6-1.5-0.8-2.3c-0.1-0.4-0.1-0.8-0.2-1.1
				c0-0.4,0-0.7,0-1.1c0.1-1.5,0.6-3,1.3-4.2c1.4-2.5,3.4-4.2,4.9-5.2c1.5-1,2.5-1.6,2.5-2.3c0-0.6-1.2-1.4-3.6-1.3
				c-1.2,0-2.7,0.3-4.3,0.9c-1.6,0.7-3.4,1.7-5.1,3.3c-1.7,1.6-3.3,3.8-4.3,6.8c-0.3,0.7-0.4,1.5-0.6,2.3c-0.1,0.8-0.2,1.6-0.2,2.5
				c-0.1,1.7,0.2,3.4,0.6,5.2c0.9,3.5,2.9,6.8,5.9,9.6c1.5,1.4,3.4,2.6,5.5,3.4c2.2,0.9,4.4,1.3,6.6,1.4c4.5,0.2,8.9-1,12.7-2.8
				c1.9-0.9,3.7-1.9,5.5-3.1c1.8-1.2,3.5-2.5,5.1-4c1.6-1.4,3.2-3.1,4.6-4.9c1.4-1.8,2.7-3.7,3.9-5.8c1.1-2.1,2.1-4.4,2.8-6.9
				c0.7-2.5,1.1-5.1,1.1-7.8c0-2.7-0.3-5.5-1.1-8.1c-0.8-2.6-1.9-5.1-3.3-7.4c-2.8-4.6-6.7-8.4-11.4-11.2c-4.7-2.8-9.9-4.2-15.1-4.6
				c-5.2-0.4-10.3,0.3-15.2,1.9c-5,1.6-9.3,4-13,6.7c-3.7,2.7-6.9,5.8-9.6,9c-5.3,6.4-9.1,13.2-11.6,19.9
				c-2.5,6.7-3.9,13.4-4.6,19.9c-0.7,6.5-0.7,12.8-0.1,19.1c0.6,6.3,1.8,12.4,3.6,18.6c1.8,6.2,4.1,12.4,6.9,18.7
				c5.7,12.7,13.8,25.8,24.4,40.6c-10-15.2-17.2-28.8-22-41.6c-2.4-6.4-4.2-12.6-5.4-18.7c-1.2-6.1-1.8-12-1.9-18
				c0-5.9,0.5-11.8,1.7-17.7c1.2-5.8,3-11.7,5.8-17.4c2.8-5.6,6.5-11.1,11.4-16c2.4-2.4,5.2-4.6,8.2-6.5
				C204.3,48.6,207.6,47.1,211.1,46.3z"
                />
              </g>
              <g>
                <path
                  d="M217.7,111.6c1.7-0.5,3.2-0.7,4.8-0.7c1.5,0,2.9,0.2,4,0.6c0.5,0.2,1,0.4,1.3,0.7c0.4,0.2,0.7,0.5,0.9,0.8
				c0.5,0.6,1,1.5,1.3,2.5c0.2,0.5,0.3,1.1,0.3,1.7c0.1,0.6,0.1,1.1,0,1.7c-0.1,1.1-0.4,2.2-0.7,3c-0.2,0.4-0.4,0.8-0.6,1.1
				c-0.2,0.3-0.4,0.6-0.7,0.8c-0.3,0.2-0.4,0.4-0.7,0.6c-0.3,0.2-0.6,0.3-1,0.4c-0.7,0.2-1.5,0.2-2.3,0.1c-0.7-0.1-1.4-0.3-2-0.7
				c-1.2-0.6-2.2-2-2.8-3.3c-0.6-1.3-0.9-2.6-1.1-3.6c-0.2-1-0.5-1.8-1-2.2c-0.5-0.3-1.4-0.2-2.5,0.7c-1,0.9-2.2,2.7-2.6,5.4
				c-0.2,1.3-0.2,2.9,0.1,4.5c0.3,1.7,1,3.5,2.3,5.2c2.5,3.5,7,6.2,12.4,6.3c1.3,0,2.7-0.1,4.2-0.4c1.4-0.3,2.9-0.9,4.2-1.7
				c1.3-0.8,2.5-1.7,3.6-2.8c1.1-1.1,2-2.3,2.8-3.5c1.5-2.5,2.5-5.3,3-8.3c0.2-1.5,0.3-3,0.2-4.5c-0.1-1.5-0.3-3-0.7-4.5
				c-0.8-3-2.3-6.1-4.8-8.7c-2.5-2.6-5.8-4.5-9-5.3c-3.2-0.9-6.4-1-9.4-0.6c-1.5,0.2-2.9,0.5-4.3,0.9c-1.4,0.4-2.7,1-4,1.5
				c-5.3,2.4-9.2,5.9-12.2,9.4c-1.5,1.8-2.8,3.6-3.9,5.4c-1.1,1.9-2,3.7-2.8,5.5c-3,7.5-3.6,14.5-3.3,21.3c0.3,6.8,1.7,13.4,4,20.4
				c2.2,7,5.4,14.5,9.3,23.3c-3.1-9.1-5.5-16.9-6.7-24c-1.3-7.1-1.6-13.5-0.8-19.6c0.7-6.1,2.5-12,5.9-17.3c0.8-1.3,1.8-2.6,2.9-3.9
				c1.1-1.2,2.3-2.4,3.5-3.5C211.2,114.4,214.2,112.5,217.7,111.6z"
                />
              </g>
              <g>
                <path
                  d="M252.1,32.8c3.2,0.6,6.4-0.3,8.5-1.8c2.1-1.5,3-3.4,2.5-5.1c-0.6-1.7-2.4-2.7-4.6-3c-2.2-0.3-4.8,0-7.4,1.2
				c-2.7,1.2-4.6,2.1-6.6,2.8c-2,0.7-4.2,1.2-7.3,1.3c3,1,5.1,1.7,7.3,2.5C246.6,31.4,248.8,32.2,252.1,32.8z"
                />
              </g>
              <g>
                <path
                  d="M267.3,45.6c1.8,2,3.7,3.3,5.6,4.1c1.9,0.8,4,1.1,5.5,0.2c1.5-0.9,1.9-3.2,0.7-5.8c-1.2-2.6-4-5.2-7.6-6.1
				c-3.7-0.9-6.2-0.8-8.7-0.6c-2.4,0.2-4.8,0.6-8,1.4c3.3,0.3,5.4,1.2,7.2,2.3C263.8,42.2,265.4,43.6,267.3,45.6z"
                />
              </g>
              <g>
                <path
                  d="M270,60.5c0.6,2.6,1.5,4.6,2.7,6.3c1.2,1.6,2.7,3,4.5,3.1c0.9,0,1.7-0.4,2.4-1.2c0.3-0.4,0.7-0.9,0.9-1.5
				c0.2-0.6,0.4-1.3,0.5-2c0.4-2.9-0.8-6.8-3.6-9.5c-2.9-2.7-5.2-3.8-7.5-4.7c-2.3-0.9-4.7-1.6-7.9-2.2c3,1.6,4.4,3.3,5.6,5.1
				C268.6,55.8,269.4,57.8,270,60.5z"
                />
              </g>
              <g>
                <path
                  d="M263.1,73.6c-0.7,2.7-0.6,5.1,0.1,7.2c0.7,2,1.9,3.7,3.7,4c1.7,0.3,3.5-0.9,4.7-3.2c1.1-2.3,1.5-5.6,0.3-8.6
				c-1.2-3.2-2.4-5.1-3.5-7.1c-1.2-1.9-2.3-3.8-3.9-6.5c0.6,3.1,0.5,5.2,0.2,7.3C264.3,68.8,263.9,70.8,263.1,73.6z"
                />
              </g>
              <g>
                <path
                  d="M251.6,86.6c-1.8,2-2.8,4.1-3.2,6.2c-0.4,2.1-0.1,4.1,1.2,5.3c1.3,1.2,3.5,1,5.7-0.5c2.1-1.5,4-4.4,4.4-7.7
				c0.4-3.4,0.1-5.8-0.2-8c-0.3-2.3-0.7-4.5-1.2-7.5c-0.5,3.1-1.4,5-2.5,6.8C254.7,82.9,253.5,84.5,251.6,86.6z"
                />
              </g>
              <g>
                <path
                  d="M241.2,20.8c6-2.2,9.5-7.9,7.4-10.8c-2.1-2.9-8.6-1.4-12.5,3.7c-2,2.6-3.3,4.5-4.6,6.4c-0.7,0.9-1.3,1.9-2.1,2.7
				c-0.4,0.4-0.9,0.8-1.5,1.1c-0.6,0.3-1.4,0.4-2.1,0.2c0.7,0.5,1.5,0.7,2.3,0.6c0.8,0,1.5-0.2,2.2-0.4c0.7-0.2,1.3-0.4,1.9-0.6
				c0.6-0.2,1.1-0.4,1.7-0.6C235.8,22.5,238.1,21.9,241.2,20.8z"
                />
              </g>
              <g>
                <path
                  d="M227.4,15.6c2.1-2.5,2.5-5.9,1.9-8.2c-0.7-2.4-2.4-3.8-4.1-3.6c-1.8,0.1-3.2,1.4-4.2,2.9c-1,1.5-1.6,3.3-1.6,5.5
				c0,2.4-0.2,4.2-0.7,5.9c-0.5,1.8-1.3,3.6-3.2,5.6c2.6-1.2,4.4-2.1,6.2-3.2C223.5,19.4,225.3,18.1,227.4,15.6z"
                />
              </g>
              <g>
                <path
                  d="M216.5,14.7c1.1-3.1,0.5-6.4-0.9-8.5c-1.4-2.1-3.4-2.9-5-2.3c-1.7,0.6-2.6,2.3-3.1,4.1c-0.5,1.8-0.5,3.9,0.3,6.1
				c0.8,2.4,1.2,4.2,1.4,6.1c0.2,1.9,0.1,3.9-1,6.6c1.9-2.1,3.3-3.8,4.6-5.5C214.1,19.6,215.4,17.8,216.5,14.7z"
                />
              </g>
              <g>
                <path
                  d="M204,15.5c-0.4-2.8-2.4-5-4.4-5.8c-2.1-0.8-4-0.4-5.1,1c-1.1,1.4-1.2,3.2-0.9,4.8c0.3,1.6,1.1,2.9,2.7,4.1
				c1.7,1.2,2.9,2.3,3.9,3.5c1,1.2,1.9,2.7,2.4,5.1c0.6-2.4,0.9-4.1,1.3-5.9C204.2,20.4,204.4,18.4,204,15.5z"
                />
              </g>
              <g>
                <path
                  d="M189.6,18.1c-1.2-0.9-2.6-1.3-4-1.3c-1.4,0-2.6,0.3-3.6,1c-1,0.6-1.6,1.4-1.9,2.3c-0.3,0.9-0.2,1.7,0.1,2.5
				c0.7,1.6,2.1,2.7,3.1,3.4c1.1,0.7,2,0.9,3.4,0.4c1.5-0.5,2.9-0.5,4.3-0.3c1.4,0.3,2.8,1,4.1,2.7c-0.3-2.2-0.8-3.8-1.5-5.5
				C193,21.7,192,19.9,189.6,18.1z"
                />
              </g>
              <g>
                <path
                  d="M174.9,28.6c-3.1,0-5.9,1.8-7.2,3.8c-1.3,2-1.2,4.2,0,5.4c1.2,1.3,3.1,1.6,4.8,1.4c1.8-0.2,3.4-0.8,5.1-2.3
				c1.7-1.5,3.2-2.6,4.8-3.4c1.6-0.8,3.4-1.4,6.1-1.4c-2.5-1.1-4.3-1.8-6.3-2.4C180.2,29.1,178.1,28.5,174.9,28.6z"
                />
              </g>
              <g>
                <path
                  d="M168.5,41.5c-2.5,0.6-4.3,2.4-4.8,4.3c-0.6,1.9-0.1,3.7,1.3,4.8c1.4,1.1,3.2,1.3,4.7,1c1.5-0.3,2.8-1.1,3.7-2.8
				c1-1.7,1.7-3,2.7-4.3c0.9-1.2,2.1-2.3,4.1-3.3c-2.2,0-3.8-0.1-5.6-0.2C172.8,41,171,40.9,168.5,41.5z"
                />
              </g>
              <g>
                <path
                  d="M158.7,62.1c-2.2,1.8-3.3,4.4-3.4,6.6c-0.1,2.2,0.9,4,2.5,4.7c1.6,0.7,3.5,0.1,5.1-1.1c1.6-1.2,2.8-3.1,3.3-5.6
				c0.5-2.6,0.8-4.4,1.3-6.3c0.5-1.8,1.1-3.6,2.6-6c-2.2,1.6-3.9,2.7-5.6,3.7C162.7,59.2,161,60.2,158.7,62.1z"
                />
              </g>
              <g>
                <path d="M155.7,90.1c-2.8,3.4-1.4,8,2,9c3.4,1,7.1-2.2,6.5-6.5c-0.6-4.5-1.6-6.4-1.4-10.8C160.4,85.5,158.6,86.6,155.7,90.1z" />
              </g>
              <g>
                <path
                  d="M157,113.8c-1.1,1.8-0.9,3.7-0.1,5.2c0.8,1.4,2.4,2.4,4.1,2.4c1.8,0,3.3-0.9,4.2-2.1c0.9-1.3,1.2-2.9,0.5-4.7
				c-1.5-3.7-2.8-5.3-3.2-9.2C160.8,108.9,159.2,110.1,157,113.8z"
                />
              </g>
              <g>
                <path
                  d="M191.5,102.9c2.1-4.3,3.5-8.8,3.9-12.4c0.5-3.6,0.1-6.5-1.5-7.2c-1.6-0.7-4.1,0.9-6.3,4.1c-2.2,3.2-4.1,8-4.6,13.1
				c-0.5,5.3-0.4,8.9-0.3,12.5c0.1,3.6,0.2,7.2,0.2,12.4c1-5.1,2.2-8.5,3.5-11.8C187.9,110.5,189.4,107.4,191.5,102.9z"
                />
              </g>
              <g>
                <g>
                  <path
                    d="M6.8,333.4c-3-1.7-5.7-4-7.9-6.6c-2.1-2.6-3.6-5.5-4.4-8.4c-0.8-2.9-0.8-6.1-0.2-8.9c0.3-1.4,0.8-2.7,1.4-3.9
					c0.6-1.2,1.4-2.2,2.3-3.2c3.6-3.9,9.7-6,15.7-6.1c1.5-0.1,3,0,4.5,0.1c1.5,0.1,2.9,0.4,4.2,0.7c2.7,0.6,4.8,1.6,6.3,2.8
					c0.8,0.6,1.4,1.3,1.8,1.9c0.5,0.7,0.8,1.5,1,2.3c0.4,1.7,0.4,3.6-0.2,5.2c-0.2,0.8-0.6,1.6-1,2.2c-0.2,0.4-0.4,0.6-0.7,0.9
					c-0.3,0.3-0.5,0.6-0.8,0.8c-1.1,1-2.5,1.7-3.9,2.2c-2.8,0.9-5.4,0.7-7.1,0.5c-1.8-0.3-3-0.5-3.4,0c-0.4,0.5-0.1,1.8,1.7,3.4
					c0.9,0.8,2.1,1.6,3.8,2.3c1.6,0.6,3.7,1.1,6,1.1c2.3,0,5.1-0.5,7.7-2c0.7-0.3,1.3-0.8,2-1.3c0.6-0.5,1.3-1,1.9-1.6
					c1.2-1.2,2.2-2.6,3.1-4.2c1.7-3.1,2.6-6.9,2.2-11c-0.2-2-0.7-4.2-1.7-6.3c-1-2.1-2.3-4-3.9-5.6c-3.2-3.2-7.2-5.3-11.2-6.6
					c-2-0.7-4.1-1.2-6.1-1.5c-2.1-0.4-4.2-0.5-6.4-0.6c-2.2-0.1-4.4,0.1-6.7,0.4c-2.3,0.3-4.6,0.9-6.8,1.6c-2.3,0.8-4.6,1.8-6.8,3.1
					c-2.2,1.4-4.3,3-6.1,4.9c-1.8,2-3.5,4.2-4.7,6.7c-1.2,2.4-2.1,5-2.7,7.6c-1.1,5.3-0.8,10.7,0.7,15.9c1.5,5.2,4.4,9.9,7.9,13.7
					c3.5,3.8,7.8,6.8,12.3,9c4.7,2.2,9.5,3.4,14.1,4c4.6,0.6,9,0.5,13.1,0c8.3-1,15.6-3.4,22.1-6.6c6.4-3.2,12-7.1,17-11.4
					c4.9-4.3,9.2-8.9,13.1-13.9c3.8-5,7.2-10.3,10.1-16c2.9-5.7,5.5-11.8,7.7-18.4c4.5-13.2,7.6-28.3,9.9-46.4
					c-3.1,18-7.1,32.8-12.3,45.4c-2.6,6.3-5.6,12.1-8.8,17.3c-3.3,5.3-6.9,10-10.9,14.4c-4,4.3-8.4,8.3-13.3,11.8
					c-4.9,3.5-10.2,6.5-16.1,8.7c-5.9,2.2-12.4,3.7-19.2,3.9c-3.4,0.1-6.9-0.1-10.4-0.9C13.3,336.4,9.9,335.2,6.8,333.4z"
                  />
                </g>
                <g>
                  <path
                    d="M46.6,281.2c-1.6-0.8-2.8-1.7-4-2.7c-1.1-1-2-2.1-2.5-3.1c-0.3-0.5-0.4-1-0.5-1.4c-0.1-0.4-0.1-0.8-0.1-1.2
					c0-0.8,0.3-1.7,0.8-2.7c0.3-0.5,0.6-1,0.9-1.5c0.4-0.5,0.7-0.9,1.1-1.2c0.8-0.8,1.8-1.4,2.6-1.7c0.4-0.2,0.8-0.3,1.2-0.4
					c0.4-0.1,0.7-0.1,1-0.1c0.3,0,0.6,0,0.9,0.1c0.3,0.1,0.6,0.2,1,0.4c0.7,0.3,1.3,0.9,1.7,1.5c0.5,0.6,0.8,1.2,1,1.8
					c0.4,1.3,0.2,2.9-0.2,4.3c-0.5,1.4-1.1,2.5-1.7,3.4c-0.5,0.9-0.9,1.6-0.7,2.3c0.2,0.6,0.9,1.1,2.3,1.2c1.4,0,3.5-0.5,5.6-2.2
					c1.1-0.8,2.1-1.9,3-3.4c0.9-1.4,1.6-3.3,1.9-5.4c0.6-4.3-0.9-9.3-4.7-13c-1-0.9-2.1-1.8-3.3-2.5c-1.3-0.7-2.7-1.3-4.2-1.6
					c-1.5-0.3-3-0.5-4.5-0.5c-1.5,0.1-3,0.3-4.4,0.7c-2.9,0.8-5.5,2.2-7.8,4c-1.2,0.9-2.3,2-3.2,3.2c-1,1.2-1.8,2.4-2.6,3.8
					c-1.5,2.7-2.5,6-2.5,9.6c0,3.6,1.2,7.2,2.9,10c1.7,2.9,4,5.1,6.4,6.8c1.2,0.9,2.5,1.6,3.8,2.3c1.3,0.7,2.7,1.2,4,1.6
					c5.5,1.8,10.7,2,15.4,1.5c2.3-0.3,4.5-0.7,6.6-1.3c2.1-0.6,4-1.3,5.8-2.2c7.3-3.4,12.6-8.1,17-13.2c4.4-5.2,7.9-10.9,11-17.6
					c3.2-6.7,5.9-14.3,9.1-23.4c-4,8.8-7.5,16.1-11.4,22.1c-3.9,6.1-8.1,10.9-12.8,14.9c-4.7,3.9-10,7-16.1,8.6
					c-1.5,0.4-3.1,0.7-4.7,0.9c-1.6,0.2-3.3,0.2-5,0.1C53.2,283.5,49.7,282.8,46.6,281.2z"
                  />
                </g>
                <g>
                  <path
                    d="M-32.4,315.2c-2-2.6-4.9-4.2-7.4-4.5c-2.5-0.4-4.6,0.4-5.3,2c-0.7,1.6-0.1,3.6,1.3,5.3c1.4,1.7,3.5,3.3,6.2,4.2
					c2.8,0.9,4.8,1.6,6.8,2.5c2,0.9,3.9,1.9,6.2,4c-1.5-2.8-2.6-4.7-3.6-6.8C-29.3,320-30.4,317.9-32.4,315.2z"
                  />
                </g>
                <g>
                  <path
                    d="M-34.7,295.5c0-2.7-0.4-4.9-1.3-6.9c-0.9-1.9-2.1-3.5-3.9-3.8c-1.7-0.3-3.6,1.1-4.5,3.8c-0.9,2.7-0.6,6.6,1.4,9.7
					c2.1,3.2,4,4.8,5.9,6.3c1.9,1.5,3.9,2.8,6.8,4.4c-2.2-2.4-3.1-4.5-3.7-6.6C-34.5,300.4-34.8,298.3-34.7,295.5z"
                  />
                </g>
                <g>
                  <path
                    d="M-26.6,282.8c1.3-2.3,2-4.4,2.3-6.5c0.2-2,0.1-4.1-1.2-5.3c-0.6-0.6-1.5-0.9-2.5-0.8c-0.5,0.1-1.1,0.2-1.6,0.5
					c-0.5,0.3-1.2,0.6-1.7,1.1c-2.3,1.9-4.1,5.5-3.9,9.3c0.3,3.9,1.2,6.3,2.3,8.6c1.1,2.2,2.3,4.4,4.3,7c-1.1-3.2-1-5.5-0.6-7.6
					C-28.7,287.1-27.9,285.2-26.6,282.8z"
                  />
                </g>
                <g>
                  <path
                    d="M-12.6,277.9c2.3-1.5,3.9-3.4,4.9-5.3c0.9-1.9,1.1-4,0.1-5.4c-1-1.4-3.2-1.8-5.6-0.9c-2.4,0.9-4.9,3.1-6.2,6.1
					c-1.3,3.1-1.8,5.4-2.3,7.6c-0.4,2.2-0.9,4.4-1.5,7.4c1.7-2.6,3.2-4.2,4.8-5.5C-16.7,280.6-15,279.4-12.6,277.9z"
                  />
                </g>
                <g>
                  <path
                    d="M4.7,276.2c2.7-0.3,4.9-1.1,6.6-2.4c1.7-1.3,2.9-2.9,2.7-4.7c-0.2-1.7-1.9-3.2-4.4-3.5c-2.5-0.4-5.9,0.4-8.5,2.6
					c-2.6,2.3-4,4.2-5.4,6c-1.3,1.9-2.6,3.7-4.3,6.3c2.5-1.9,4.5-2.7,6.5-3.3C-0.1,276.8,1.9,276.5,4.7,276.2z"
                  />
                </g>
                <g>
                  <path
                    d="M-32.6,331.5c-5.9-2.5-12.3-0.7-12.8,2.8c-0.4,3.5,5.4,6.9,11.7,5.9c3.2-0.5,5.5-1.1,7.8-1.5c1.2-0.2,2.2-0.5,3.4-0.5
					c0.6,0,1.2,0,1.8,0.2c0.6,0.2,1.3,0.6,1.7,1.3c-0.2-0.8-0.7-1.5-1.2-2c-0.6-0.5-1.2-0.9-1.8-1.2c-0.6-0.3-1.2-0.6-1.8-0.8
					c-0.6-0.2-1.1-0.5-1.6-0.7C-27.5,333.9-29.6,332.8-32.6,331.5z"
                  />
                </g>
                <g>
                  <path
                    d="M-26.1,344.6c-3.2,0.4-5.9,2.5-7,4.8c-1.2,2.2-0.8,4.4,0.5,5.5c1.4,1.1,3.3,1.1,5.1,0.8c1.8-0.4,3.4-1.3,4.9-3
					c1.6-1.7,3-2.9,4.6-3.9c1.6-1,3.4-1.7,6.2-1.9c-2.7-0.9-4.6-1.4-6.7-1.9C-20.6,344.6-22.8,344.2-26.1,344.6z"
                  />
                </g>
                <g>
                  <path
                    d="M-18.7,352.8c-2.9,1.5-4.7,4.4-5.1,6.8c-0.4,2.5,0.5,4.5,2.1,5.1c1.7,0.7,3.5,0.1,5.1-0.9c1.6-1,3-2.5,4-4.7
					c1-2.2,2-3.9,3.1-5.4c1.2-1.5,2.6-2.9,5.2-4.2c-2.9,0.2-5,0.5-7.1,0.9C-13.6,350.8-15.8,351.3-18.7,352.8z"
                  />
                </g>
                <g>
                  <path
                    d="M-9,360.7c-1.6,2.3-1.7,5.3-0.7,7.3c0.9,2,2.7,3,4.4,2.7c1.7-0.3,3.1-1.5,4-2.9c0.9-1.4,1.2-2.9,0.8-4.8
					c-0.4-2-0.6-3.6-0.5-5.2c0.1-1.6,0.4-3.3,1.7-5.4c-2,1.3-3.5,2.4-5,3.5C-5.8,357-7.4,358.3-9,360.7z"
                  />
                </g>
                <g>
                  <path
                    d="M3.3,368.7c0.3,1.5,1,2.7,2,3.7c1,0.9,2.1,1.5,3.2,1.7c1.1,0.2,2.1,0,2.9-0.4c0.8-0.4,1.3-1.1,1.6-1.9
					c0.6-1.7,0.4-3.4,0.1-4.6c-0.3-1.3-0.8-2.1-2.2-2.7c-1.4-0.7-2.5-1.6-3.3-2.7c-0.8-1.2-1.4-2.6-1.1-4.8c-1.3,1.8-2,3.4-2.6,5.1
					C3.3,363.7,2.7,365.7,3.3,368.7z"
                  />
                </g>
                <g>
                  <path
                    d="M21.2,371c2.3,2.1,5.5,2.7,7.9,2.1c2.4-0.6,3.8-2.2,3.7-3.9c0-1.8-1.2-3.3-2.6-4.4c-1.4-1.1-3.1-1.7-5.3-1.8
					c-2.3-0.1-4.1-0.3-5.8-0.8c-1.7-0.5-3.4-1.3-5.4-3.2c1.1,2.5,1.9,4.3,2.9,6.1C17.7,367,18.8,368.9,21.2,371z"
                  />
                </g>
                <g>
                  <path
                    d="M34.6,366c2.2,1.3,4.7,1.2,6.4,0.2c1.7-1,2.6-2.7,2.3-4.4c-0.3-1.7-1.4-3.1-2.7-4c-1.3-0.8-2.8-1.1-4.7-0.5
					c-1.9,0.6-3.3,1-4.8,1.3c-1.5,0.2-3.1,0.3-5.2-0.4c1.6,1.5,2.7,2.7,3.9,3.9C31.1,363.4,32.4,364.7,34.6,366z"
                  />
                </g>
                <g>
                  <path
                    d="M55.9,357.6c2.9,0.2,5.5-1,7-2.5c1.6-1.6,2.1-3.5,1.4-5.1c-0.7-1.6-2.5-2.5-4.5-2.7c-2-0.2-4.2,0.4-6.2,1.9
					c-2.1,1.5-3.6,2.7-5.2,3.7c-1.6,1-3.3,1.9-6,2.6c2.7,0.3,4.7,0.7,6.6,1.1C51,357,52.9,357.4,55.9,357.6z"
                  />
                </g>
                <g>
                  <path
                    d="M77.2,339.2c4.4-0.5,6.5-4.9,4.7-7.9c-1.8-3-6.6-3.2-9.2,0.4c-2.6,3.7-3.2,5.7-6.3,8.9
					C70.6,339.3,72.7,339.8,77.2,339.2z"
                  />
                </g>
                <g>
                  <path
                    d="M92.4,321c2-0.6,3.2-2.1,3.6-3.7c0.4-1.6-0.1-3.4-1.4-4.6c-1.3-1.2-3-1.6-4.5-1.3c-1.5,0.3-2.9,1.3-3.6,3.1
					c-1.4,3.7-1.5,5.7-3.9,8.9C86.3,321.9,88.3,322.2,92.4,321z"
                  />
                </g>
                <g>
                  <path
                    d="M59.8,305.3c-4.5,1.7-8.5,4.1-11.4,6.4c-2.8,2.3-4.5,4.7-3.8,6.3c0.7,1.6,3.6,2.1,7.4,1.3c3.8-0.8,8.5-3,12.3-6.5
					c3.9-3.5,6.3-6.2,8.8-8.9c2.4-2.7,4.8-5.4,8.3-9.3c-4.2,3.1-7.4,4.7-10.6,6.2C67.6,302.3,64.4,303.6,59.8,305.3z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M363.6,398.2c3.2,1.3,6.2,3.2,8.6,5.5c2.4,2.3,4.3,5,5.4,7.7c1.1,2.8,1.6,5.9,1.3,8.8c-0.1,1.4-0.4,2.8-0.9,4.1
					c-0.4,1.3-1.1,2.4-1.8,3.5c-3,4.3-8.8,7.2-14.8,8.1c-1.5,0.3-3,0.4-4.4,0.4c-1.5,0.1-2.9,0-4.3-0.1c-2.7-0.3-5-1-6.7-2
					c-0.8-0.5-1.5-1.1-2-1.7c-0.5-0.6-1-1.4-1.3-2.2c-0.6-1.7-0.8-3.6-0.5-5.2c0.1-0.8,0.4-1.6,0.7-2.3c0.1-0.4,0.4-0.7,0.5-1
					c0.2-0.3,0.4-0.6,0.7-0.9c1-1.2,2.2-2.1,3.6-2.7c2.6-1.2,5.2-1.4,7-1.4c1.8,0,3,0.1,3.4-0.4c0.4-0.5-0.2-1.8-2.1-3.2
					c-1-0.7-2.3-1.3-4-1.8c-1.7-0.4-3.8-0.6-6.1-0.3c-2.3,0.3-4.9,1.2-7.4,3c-0.6,0.4-1.2,1-1.8,1.5c-0.6,0.6-1.2,1.2-1.6,1.9
					c-1,1.3-1.9,2.9-2.5,4.5c-1.3,3.3-1.6,7.2-0.8,11.2c0.4,2,1.2,4.1,2.4,6c1.3,2,2.8,3.6,4.6,5c3.6,2.8,7.8,4.3,11.9,5.1
					c2.1,0.4,4.2,0.6,6.3,0.7c2.1,0.1,4.3,0,6.4-0.3c2.2-0.2,4.4-0.7,6.6-1.3c2.2-0.6,4.4-1.4,6.6-2.5c2.2-1.1,4.3-2.4,6.3-4
					c2-1.6,3.8-3.5,5.5-5.7c1.6-2.2,2.9-4.6,3.8-7.2c0.9-2.6,1.5-5.2,1.7-7.9c0.4-5.4-0.6-10.7-2.7-15.7c-2.2-5-5.6-9.2-9.6-12.6
					c-4-3.3-8.6-5.8-13.4-7.3c-5-1.6-9.9-2.2-14.5-2.1c-4.6,0-9,0.7-13,1.7c-8.1,2.1-15,5.4-21,9.4c-6,4-11,8.6-15.3,13.5
					c-4.3,4.9-8,10-11.1,15.5c-3.1,5.4-5.8,11.2-8,17.2c-2.2,6.1-3.9,12.4-5.3,19.3c-2.7,13.7-3.8,29-3.8,47.2
					c0.7-18.2,2.8-33.4,6.3-46.6c1.8-6.6,3.9-12.7,6.5-18.3c2.6-5.7,5.5-10.9,8.9-15.7c3.4-4.8,7.3-9.3,11.6-13.4
					c4.4-4.1,9.3-7.7,14.8-10.7c5.5-3,11.8-5.2,18.5-6.4c3.4-0.5,6.9-0.8,10.4-0.5C356.7,396.1,360.3,396.8,363.6,398.2z"
                  />
                </g>
                <g>
                  <path
                    d="M331,455.1c1.7,0.6,3,1.3,4.3,2.2c1.2,0.9,2.2,1.9,2.9,2.8c0.3,0.5,0.5,0.9,0.7,1.3c0.2,0.4,0.2,0.8,0.3,1.2
					c0.1,0.8,0,1.8-0.4,2.8c-0.2,0.5-0.4,1.1-0.7,1.6c-0.3,0.5-0.6,0.9-1,1.4c-0.7,0.9-1.6,1.6-2.4,2.1c-0.4,0.2-0.8,0.4-1.1,0.6
					c-0.4,0.1-0.7,0.2-1,0.3c-0.3,0-0.6,0.1-0.9,0c-0.3,0-0.7-0.1-1-0.2c-0.7-0.3-1.4-0.7-1.9-1.2c-0.5-0.5-1-1.1-1.2-1.7
					c-0.6-1.2-0.6-2.9-0.3-4.3c0.3-1.4,0.8-2.6,1.2-3.6c0.4-1,0.7-1.7,0.4-2.4c-0.2-0.6-1-1-2.5-0.9c-1.4,0.2-3.4,0.9-5.3,2.9
					c-0.9,1-1.9,2.2-2.6,3.7c-0.7,1.6-1.2,3.4-1.2,5.6c0,4.3,2.1,9.1,6.4,12.3c1.1,0.8,2.3,1.5,3.6,2.1c1.3,0.5,2.9,0.9,4.4,1.1
					c1.5,0.1,3,0.1,4.5-0.1c1.5-0.3,2.9-0.7,4.3-1.2c2.7-1.2,5.2-2.9,7.2-5c1-1.1,2-2.3,2.8-3.6c0.8-1.3,1.5-2.6,2-4.1
					c1.1-2.9,1.7-6.3,1.2-9.9c-0.5-3.6-2.1-7-4.2-9.5c-2.1-2.6-4.6-4.5-7.3-5.9c-1.3-0.7-2.7-1.3-4.1-1.8c-1.4-0.5-2.8-0.8-4.1-1.1
					c-5.7-1.1-10.9-0.6-15.4,0.5c-2.3,0.6-4.4,1.3-6.3,2.2c-2,0.9-3.8,1.8-5.5,2.9c-6.8,4.3-11.4,9.7-15.1,15.3
					c-3.7,5.7-6.4,11.9-8.7,18.9c-2.3,7-4,15-6,24.4c2.8-9.2,5.4-16.9,8.5-23.4c3.1-6.5,6.6-11.9,10.7-16.4c4.1-4.5,9-8.3,14.8-10.6
					c1.5-0.6,3-1.1,4.6-1.5c1.6-0.4,3.2-0.6,4.9-0.8C324,453.6,327.6,453.9,331,455.1z"
                  />
                </g>
                <g>
                  <path
                    d="M404.9,411.1c2.3,2.3,5.4,3.5,7.9,3.5c2.5,0,4.5-1,5-2.7c0.5-1.7-0.4-3.6-2-5.1c-1.6-1.6-3.9-2.8-6.7-3.3
					c-2.9-0.6-5-1-7-1.6c-2.1-0.6-4.1-1.4-6.7-3.2c1.9,2.5,3.2,4.4,4.4,6.3C401.2,406.8,402.5,408.7,404.9,411.1z"
                  />
                </g>
                <g>
                  <path
                    d="M409.7,430.4c0.3,2.7,1.1,4.8,2.2,6.6c1.1,1.8,2.6,3.2,4.3,3.3c1.7,0.1,3.4-1.5,4-4.4c0.6-2.8-0.2-6.6-2.6-9.4
					c-2.5-2.9-4.6-4.3-6.7-5.5c-2.1-1.2-4.2-2.3-7.3-3.5c2.5,2.1,3.7,4.1,4.5,6.1C408.9,425.6,409.4,427.6,409.7,430.4z"
                  />
                </g>
                <g>
                  <path
                    d="M403.3,444.1c-1,2.4-1.5,4.6-1.4,6.7c0,2,0.4,4,1.8,5.1c0.7,0.5,1.6,0.7,2.6,0.4c0.5-0.1,1.1-0.3,1.6-0.7
					c0.5-0.3,1.1-0.8,1.6-1.3c2-2.1,3.4-6,2.6-9.8c-0.8-3.8-2.1-6.1-3.4-8.2c-1.4-2.1-2.8-4-5.2-6.4c1.5,3,1.7,5.3,1.5,7.4
					C404.8,439.5,404.3,441.5,403.3,444.1z"
                  />
                </g>
                <g>
                  <path
                    d="M390,450.7c-2.1,1.7-3.5,3.8-4.1,5.9c-0.6,2-0.6,4.1,0.6,5.4c1.2,1.3,3.4,1.3,5.6,0.1c2.2-1.2,4.4-3.7,5.3-6.8
					c0.9-3.3,1.1-5.6,1.3-7.8c0.2-2.3,0.3-4.5,0.6-7.6c-1.3,2.8-2.6,4.5-4.1,6.1C393.8,447.5,392.2,448.9,390,450.7z"
                  />
                </g>
                <g>
                  <path
                    d="M373.1,454.6c-2.6,0.6-4.7,1.8-6.2,3.2c-1.5,1.5-2.5,3.3-2.1,5c0.4,1.7,2.3,2.9,4.9,2.9c2.6,0,5.8-1.2,8.1-3.7
					c2.3-2.6,3.4-4.6,4.5-6.7c1.1-2,2.1-4,3.4-6.8c-2.2,2.2-4.1,3.3-6,4.1C377.8,453.4,375.8,453.9,373.1,454.6z"
                  />
                </g>
                <g>
                  <path
                    d="M402.9,395c6.1,1.8,12.3-0.9,12.3-4.5c0-3.5-6.2-6.1-12.3-4.3c-3.2,0.9-5.3,1.8-7.5,2.5c-1.1,0.4-2.2,0.8-3.3,0.9
					c-0.6,0.1-1.2,0.1-1.8,0c-0.6-0.1-1.3-0.5-1.8-1.1c0.3,0.8,0.9,1.4,1.5,1.9c0.6,0.4,1.3,0.7,2,1c0.6,0.2,1.3,0.4,1.9,0.6
					c0.6,0.2,1.1,0.3,1.7,0.5C397.6,393.2,399.8,394.1,402.9,395z"
                  />
                </g>
                <g>
                  <path
                    d="M394.7,382.8c3.1-0.8,5.5-3.3,6.3-5.6c0.9-2.4,0.2-4.4-1.3-5.3c-1.5-0.9-3.4-0.7-5.1-0.1c-1.7,0.6-3.2,1.7-4.5,3.6
					c-1.4,1.9-2.6,3.3-4,4.4c-1.4,1.1-3.1,2.2-5.9,2.7c2.8,0.5,4.8,0.8,6.9,1C389.3,383.5,391.5,383.6,394.7,382.8z"
                  />
                </g>
                <g>
                  <path
                    d="M386.4,375.6c2.7-1.9,4.1-4.9,4.2-7.4c0.1-2.5-1.1-4.4-2.8-4.8c-1.7-0.4-3.5,0.4-5,1.5c-1.5,1.2-2.6,2.9-3.3,5.2
					c-0.7,2.4-1.5,4.1-2.4,5.8c-1,1.6-2.2,3.2-4.6,4.8c2.8-0.6,4.8-1.2,6.9-1.8C381.5,378.3,383.7,377.5,386.4,375.6z"
                  />
                </g>
                <g>
                  <path
                    d="M375.7,369.1c1.3-2.5,1-5.5-0.2-7.3c-1.2-1.9-3.1-2.6-4.7-2.1c-1.7,0.5-2.9,1.9-3.6,3.3c-0.7,1.5-0.8,3-0.2,4.9
					c0.6,1.9,1,3.5,1.2,5.1c0.1,1.6,0,3.3-1,5.5c1.8-1.6,3.2-2.8,4.5-4.1C373.1,373.1,374.4,371.7,375.7,369.1z"
                  />
                </g>
                <g>
                  <path
                    d="M362.5,362.8c-0.5-1.4-1.4-2.6-2.5-3.4c-1.1-0.8-2.3-1.2-3.4-1.3c-1.1-0.1-2.1,0.2-2.8,0.8c-0.7,0.5-1.2,1.3-1.4,2.1
					c-0.4,1.7,0.1,3.4,0.5,4.6c0.5,1.2,1.1,1.9,2.5,2.4c1.5,0.5,2.7,1.2,3.6,2.3c0.9,1,1.7,2.4,1.8,4.6c1-2,1.6-3.6,2-5.4
					C363.2,367.7,363.5,365.7,362.5,362.8z"
                  />
                </g>
                <g>
                  <path
                    d="M344.5,362.7c-2.6-1.8-5.9-2-8.1-1.1c-2.3,0.9-3.4,2.7-3.2,4.4c0.3,1.8,1.6,3.1,3.1,4c1.5,0.9,3.3,1.3,5.5,1.1
					c2.3-0.2,4.1-0.2,5.8,0c1.8,0.3,3.6,0.8,5.8,2.5c-1.4-2.3-2.5-4-3.7-5.7C348.5,366.3,347.1,364.6,344.5,362.7z"
                  />
                </g>
                <g>
                  <path
                    d="M331.8,369.5c-2.3-1-4.8-0.5-6.4,0.6c-1.6,1.2-2.2,3-1.8,4.7c0.5,1.7,1.8,2.9,3.2,3.6c1.4,0.6,3,0.7,4.7-0.1
					c1.8-0.8,3.2-1.5,4.6-1.9c1.5-0.4,3-0.7,5.2-0.3c-1.8-1.3-3.1-2.3-4.4-3.4C335.6,371.6,334.2,370.5,331.8,369.5z"
                  />
                </g>
                <g>
                  <path
                    d="M311.8,380.6c-2.9,0.2-5.3,1.7-6.6,3.4c-1.4,1.8-1.7,3.8-0.7,5.3c0.9,1.5,2.8,2.1,4.8,2c2-0.1,4.1-0.9,5.9-2.7
					c1.9-1.8,3.2-3.1,4.7-4.3c1.5-1.2,3-2.3,5.6-3.4c-2.8,0-4.7-0.1-6.7-0.3C316.8,380.6,314.7,380.4,311.8,380.6z"
                  />
                </g>
                <g>
                  <path
                    d="M293.1,401.5c-4.3,1.1-5.8,5.7-3.6,8.4c2.2,2.8,7,2.4,9-1.5c2.1-4,2.4-6.1,5.1-9.6C299.6,400.6,297.5,400.4,293.1,401.5
					z"
                  />
                </g>
                <g>
                  <path
                    d="M280.3,421.6c-1.9,0.9-2.9,2.5-3.1,4.2c-0.2,1.7,0.5,3.3,2,4.4c1.4,1,3.2,1.2,4.7,0.7c1.5-0.5,2.7-1.7,3.1-3.6
					c0.9-3.9,0.8-5.9,2.7-9.4C286.3,419.9,284.3,419.9,280.3,421.6z"
                  />
                </g>
                <g>
                  <path
                    d="M314.7,432.9c4.2-2.3,7.9-5.1,10.4-7.8c2.5-2.7,3.8-5.2,2.9-6.7c-0.9-1.5-3.8-1.6-7.5-0.3c-3.7,1.3-8,4.1-11.4,8
					c-3.5,4-5.5,7-7.5,10c-2,3-4,6-7,10.3c3.8-3.6,6.7-5.7,9.7-7.5C307.3,436.9,310.4,435.2,314.7,432.9z"
                  />
                </g>
              </g>
              <g>
                <path
                  d="M344.5,109.3c1.3-4.4,1.9-9.1,1.4-13.7c-0.4-4.6-1.8-9.3-4.3-13.4c-0.3-0.5-0.6-1-1-1.5c-0.4-0.5-0.7-1-1.1-1.5
				c-0.8-0.9-1.5-1.8-2.4-2.7c-1.7-1.7-3.6-3.1-5.6-4.3c-4-2.4-8.3-3.8-12.6-4.4c-4.3-0.6-8.7-0.6-12.9,0.4
				c-4.2,0.9-8.3,2.7-11.6,5.5c-3.3,2.7-5.9,6.2-7.2,10.2c-0.7,1.9-1.1,4.1-1.1,6.1c0,2,0.2,4,0.8,5.9c1.1,3.7,3.3,7,6.3,9.2
				c3,2.2,6.5,3.1,9.6,3c3.1-0.1,6.1-1.1,8.3-2.8c1.1-0.9,2-1.9,2.7-2.9c0.7-1.1,1.2-2.2,1.4-3.2c0.6-2.1,0.5-4.1,0.2-5.7
				c-0.3-1.6-1-2.9-1.6-3.8c-1.4-1.8-2.6-2.1-2.9-1.8c-0.3,0.3,0.2,1.3,0.6,2.9c0.2,0.8,0.3,1.8,0.2,3c-0.1,1.1-0.4,2.4-1,3.7
				c-0.3,0.6-0.7,1.2-1.2,1.7c-0.5,0.5-1.1,0.9-1.7,1.3c-1.3,0.7-3,1.1-4.8,0.9c-1.8-0.2-3.7-0.9-5-2.1c-1.4-1.3-2.5-3.2-2.9-5.3
				c-0.2-1.1-0.3-2.2-0.2-3.4c0.1-1.1,0.4-2.2,0.8-3.3c0.9-2.2,2.6-4.3,4.7-6c2.1-1.6,4.8-2.7,7.7-3.3c2.9-0.6,6.2-0.6,9.4-0.1
				c6.4,1,12.6,4.2,15.9,9.6c3.5,5.4,4.4,12.8,2.7,19.6c-0.4,1.7-1,3.4-1.8,5c-0.7,1.6-1.6,3.1-2.6,4.6c-2,2.9-4.2,5.4-6.7,7.6
				c-5,4.4-10.6,7.5-16.3,9.7c-5.7,2.3-11.4,3.6-17.1,4.6c-5.7,1-11.4,1.5-17.1,1.9c-5.7,0.4-11.5,0.5-17.5,0.9
				c-6,0.4-12.3,1.1-18.6,3c-3.2,0.9-6.4,2.1-9.5,3.8c-3.1,1.6-6.1,3.7-8.8,6.3c-2.7,2.6-5,5.8-6.7,9.4c-1.6,3.6-2.6,7.8-2.2,12.1
				c-0.2-4.3,0.9-8.4,2.6-12c1.8-3.5,4.1-6.6,6.9-9c2.7-2.4,5.7-4.3,8.8-5.8c3.1-1.4,6.2-2.5,9.4-3.2c6.3-1.5,12.4-1.9,18.3-1.9
				c5.9-0.1,11.7,0.2,17.5,0.2c5.8,0,11.6-0.2,17.6-0.8c6-0.7,12.2-1.7,18.5-3.8c6.3-2,12.9-5,19-9.8c3.1-2.4,6-5.2,8.6-8.6
				C340.9,117.8,343.1,113.9,344.5,109.3z"
                />
              </g>
              <g>
                <path
                  d="M278.5,96c-3.9-1.2-8.4-1.3-12.5,0.5c-2,0.9-3.8,2.1-5.3,3.7c-1.5,1.5-2.7,3.3-3.6,5.3c-0.8,2-1.2,4.2-1.1,6.4
				c0.1,2.2,0.7,4.2,1.6,6c1.8,3.5,4.9,6.1,8.4,7c3.5,1,6.9,0.3,9.4-1.4c1.2-0.8,2.2-1.9,2.8-3c0.6-1.1,0.9-2.2,1-3.1
				c0.2-1.9-0.3-3.4-0.9-4.2c-0.6-0.8-1.2-1-1.6-0.9c-0.8,0.3-0.9,2.1-2.2,3.6c-0.3,0.4-0.7,0.8-1.1,1c-0.4,0.3-0.8,0.4-1.3,0.5
				c-1,0.2-2.4,0.1-3.4-0.5c-1.1-0.6-2.2-1.8-2.7-3.2c-0.6-1.5-0.5-2.8,0.1-4.4c0.7-1.6,2.1-3.1,3.8-4c1.7-0.9,3.9-1.2,6.3-0.9
				c4.9,0.7,8.5,4.3,10.6,8.1c2.2,3.9,3,8.1,2.7,12.3c-0.2,2.1-0.6,4.2-1.5,6.2c-0.4,1-1,2-1.6,3c-0.7,0.9-1.4,1.8-2.4,2.7
				c-1.8,1.6-4.1,3-6.9,3.8c-2.7,0.7-6,0.9-9.2-0.3c3.1,1.4,6.5,1.6,9.4,1.1c3-0.5,5.6-1.7,7.8-3.3c1.1-0.8,2.1-1.7,3-2.6
				c0.9-1,1.7-2,2.3-3.1c1.4-2.1,2.2-4.5,2.9-6.9c1.1-4.8,1-10-0.9-15.4c-1-2.7-2.4-5.4-4.7-8C285.7,99.6,282.5,97.2,278.5,96z"
                />
              </g>
              <g>
                <path
                  d="M315.1,122.6c2.7-2.4,4.1-5.8,4.2-8.5c0.1-2.8-0.9-4.8-2.6-5.3c-1.7-0.5-3.6,0.5-5.2,2.2c-1.6,1.7-2.9,3.9-3.7,6.8
				c-0.8,2.9-1.5,5-2.5,7.1c-1,2-2.1,4-4.5,6.4c3-1.5,5.1-2.6,7.3-3.8C310.2,126.3,312.4,125.1,315.1,122.6z"
                />
              </g>
              <g>
                <path
                  d="M366,89.8c2.5-1.7,4.3-3.8,5.4-5.9c1.1-2.1,1.5-4.2,0.4-5.7c-1-1.4-3.3-1.7-5.9-0.7c-2.6,1.1-5.4,3.6-6.8,6.9
				c-1.5,3.5-2,6-2.5,8.4c-0.4,2.5-0.8,4.9-1.4,8.3c1.5-3.1,3.1-4.9,4.7-6.6C361.7,93,363.5,91.6,366,89.8z"
                />
              </g>
              <g>
                <path
                  d="M379.9,95.6c3-0.8,5.4-2.4,6.9-4.3c1.5-1.8,2.2-3.9,1.4-5.5c-0.8-1.6-2.9-2.2-5.4-1.9c-2.5,0.3-5.4,1.7-7.6,4.1
				c-2.3,2.5-3.6,4.4-5,6.2c-1.4,1.8-2.8,3.6-5.1,6c2.9-1.5,5.1-2.3,7.2-2.9C374.6,96.9,376.8,96.4,379.9,95.6z"
                />
              </g>
              <g>
                <path
                  d="M390.9,105.2c3.1,0.2,5.9-0.4,8-1.5c2.1-1.1,3.7-2.6,3.6-4.4c0-1.7-1.7-3.3-4.4-4c-2.6-0.7-6.2-0.4-9.3,1.3
				c-3.2,1.7-5.2,3.3-7.1,4.7c-1.9,1.5-3.8,3-6.6,5c3.3-1.1,5.6-1.4,7.9-1.5C385.4,104.9,387.7,105,390.9,105.2z"
                />
              </g>
              <g>
                <path
                  d="M360.3,116.8c2.7-0.8,4.8-2.3,6.1-4c1.3-1.7,2-3.7,1.2-5.3c-0.7-1.6-2.7-2.4-5.1-2.1c-2.4,0.3-5.2,1.7-7.2,4.2
				c-2,2.5-3.1,4.5-4.2,6.4c-1.1,1.9-2.2,3.8-3.9,6.3c2.5-1.8,4.4-2.7,6.3-3.5C355.5,118.1,357.5,117.6,360.3,116.8z"
                />
              </g>
              <g>
                <path
                  d="M373.4,121.3c2.6,0.1,4.9-0.6,6.6-1.8c1.7-1.2,2.8-2.9,2.6-4.6c-0.2-1.7-1.8-3.1-4.1-3.6c-2.3-0.5-5.2,0-7.7,1.7
				c-2.5,1.8-4.1,3.3-5.6,4.7c-1.5,1.5-3,2.9-5.3,4.7c2.8-0.9,4.8-1.1,6.7-1.2C368.7,121,370.7,121.2,373.4,121.3z"
                />
              </g>
              <g>
                <path
                  d="M293.2,147.9c-6.4,0.5-11.3,5.2-10.1,8.5c1.2,3.3,8,3.6,13.2-0.3c5.3-4,7-6.2,12.8-9.5
				C302.5,148,299.8,147.4,293.2,147.9z"
                />
              </g>
              <g>
                <path
                  d="M300.9,162.8c-1.7,2.4-2,5.5-1.2,7.6c0.7,2.2,2.4,3.5,4.1,3.4c1.8-0.1,3.2-1.3,4.2-2.8c1-1.5,1.5-3.4,1.3-5.6
				c-0.2-2.3-0.3-4.1-0.2-5.9c0.2-1.8,0.5-3.6,1.8-5.9c-2.1,1.6-3.6,2.8-5.1,4.1C304.2,159,302.7,160.4,300.9,162.8z"
                />
              </g>
              <g>
                <path
                  d="M250.7,158.6c5.2-0.2,10.2-0.8,14.1-1.6c3.9-0.8,6.7-2,7-3.7c0.2-1.7-2.4-3.7-6.9-4.6c-4.4-1-10.5-0.7-16.1,1.4
				c-5.7,2.2-9.2,4.4-12.7,6.7c-3.4,2.3-6.7,4.7-11.2,8.5c5.1-2.9,9-4.2,12.8-5.1C241.5,159.3,245.3,158.8,250.7,158.6z"
                />
              </g>
              <g>
                <path
                  d="M452.7,61.6c-0.5-3.2-1.3-6.4-2.5-9.5c-1.2-3.1-2.9-6.2-5.1-8.9c-2.2-2.7-5-5-8.1-6.7c-3.1-1.7-6.7-2.7-10.3-2.8l-1.4,0
				l-1.4,0.1c-0.9,0.1-1.8,0.3-2.7,0.5c-1.8,0.5-3.4,1.1-4.9,2c-3,1.7-5.6,4.2-7.3,7.3c-1.7,3-2.4,6.8-1.9,10
				c0.2,1.6,0.7,3.2,1.3,4.6c0.6,1.4,1.4,2.7,2.2,3.8c3.4,4.5,8.5,7,13.1,7.2c2.3,0.1,4.6-0.3,6.5-1.3c1-0.5,1.8-1.1,2.5-1.8
				c0.7-0.7,1.2-1.5,1.7-2.3c1.5-3.2,1-6,0-7.5c-0.5-0.8-1-1.2-1.4-1.4c-0.4-0.2-0.7-0.2-0.9,0c-0.3,0.3-0.1,1.1-0.1,2.2
				c0,0.6-0.1,1.2-0.4,1.9c-0.3,0.7-0.7,1.5-1.3,2.1c-0.3,0.3-0.7,0.6-1,0.8c-0.3,0.2-0.8,0.4-1.3,0.5c-1,0.2-2.2,0.2-3.5-0.1
				c-2.6-0.6-5.4-2.4-7.1-5.2c-0.4-0.7-0.8-1.4-1-2.2c-0.2-0.8-0.4-1.5-0.4-2.3c-0.1-0.8,0-1.5,0.2-2.2c0.2-0.7,0.5-1.4,0.9-2.1
				c0.8-1.4,2.2-2.6,3.9-3.5c0.8-0.4,1.7-0.8,2.6-1c0.4-0.1,0.9-0.2,1.4-0.2l0.7,0l0.7,0c1.9,0.1,4,0.6,5.9,1.7
				c1.9,1,3.8,2.5,5.3,4.2c3.1,3.5,5.2,8.4,6.4,13.7c1.2,5.5,1.6,10.9,1.4,15.9c-0.2,5-0.9,9.7-1.9,14.1c-2.1,8.8-5.4,16.4-9.6,23.5
				c-4.2,7.1-9.5,13.8-16.3,20.4c-6.8,6.6-15.2,13.2-26.1,19.6c11.2-6,20-12.1,27.3-18.3c7.3-6.2,13.3-12.6,18.3-19.6
				c5-7,9.2-14.7,12.3-23.9c1.5-4.6,2.7-9.6,3.4-15.1C453.5,74.1,453.6,68.1,452.7,61.6z"
                />
              </g>
              <g>
                <path
                  d="M420.9,70.1c-1.7-0.9-3.7-1.5-5.7-1.6c-2.1-0.2-4.2,0.2-6.2,0.9c-1.9,0.7-3.7,1.8-5.2,3.1c-1.5,1.4-2.7,3-3.5,5
				c-0.8,1.9-1.2,4.1-1.1,6c0.1,2,0.6,3.8,1.4,5.5c1.6,3.3,4.3,5.8,7.4,6.9c1.6,0.6,3.2,0.8,4.6,0.6c1.4-0.1,2.7-0.5,3.8-0.9
				c2.2-1,3.7-2.4,4.6-3.8c0.9-1.4,1.2-2.7,1.1-3.6c-0.1-0.9-0.4-1.4-0.8-1.5c-0.9-0.2-1.9,0.9-3.8,1.6c-0.9,0.4-2,0.7-3.2,0.8
				c-1.2,0-2.4-0.2-3.2-0.9c-0.9-0.6-1.7-1.7-2.1-3c-0.2-0.6-0.3-1.3-0.3-2c0-0.6,0.2-1.2,0.4-1.8c0.5-1.2,1.7-2.4,3.2-3
				c0.7-0.3,1.5-0.5,2.2-0.6c0.8,0,1.7,0.1,2.6,0.3c1.9,0.6,3.8,1.9,5.4,3.4c0.8,0.8,1.5,1.6,2.2,2.4c0.7,0.8,1.3,1.7,1.9,2.6
				c2.3,3.5,3.9,7.1,5,10.9c1.1,3.8,1.7,7.8,1.5,12.4c0,0.6-0.1,1.1-0.1,1.7c0,0.6-0.1,1.2-0.2,1.8c-0.1,1.2-0.4,2.4-0.6,3.7
				c-0.6,2.5-1.4,5.2-2.7,8c1.5-2.7,2.7-5.3,3.5-7.8c0.4-1.3,0.8-2.5,1.1-3.7c0.1-0.6,0.3-1.2,0.4-1.8c0.1-0.6,0.2-1.2,0.3-1.8
				c0.8-4.7,0.9-9,0.5-13.3c-0.5-4.3-1.4-8.6-3.3-13.1c-0.5-1.1-1-2.3-1.7-3.4c-0.6-1.2-1.4-2.4-2.2-3.5
				C426.7,74.4,424.4,72,420.9,70.1z"
                />
              </g>
              <g>
                <g>
                  <path
                    d="M503.8,68.5c5.7-0.7,11.4-1.1,16.9-1.7c5.5-0.5,10.7-1.1,15.1-2c4.5-0.8,8.2-1.9,10.7-2.9c2.5-1.1,3.9-2.2,3.7-3
					c-0.2-0.9-1.8-1.4-4.4-1.8c-2.6-0.4-6.3-0.5-10.8-0.6c-4.4-0.1-9.6,0-15.3,0.4c-5.6,0.4-11.8,1.3-17.7,2.9
					c-6.2,1.7-11.2,3.7-15.6,6c-4.3,2.3-8,4.8-11.3,7.8c-0.8,0.7-1.6,1.5-2.4,2.3c-0.8,0.8-1.5,1.6-2.3,2.5
					c-1.5,1.7-2.9,3.6-4.2,5.6c-2.6,4.1-4.9,9.2-5.6,15.5c1.2-6.2,4.1-10.9,7-14.5c1.5-1.8,3.1-3.4,4.7-4.7c0.8-0.7,1.7-1.3,2.5-2
					c0.9-0.6,1.7-1.2,2.6-1.7c3.5-2.2,7.2-3.8,11.4-5.1C493.2,70.2,498,69.2,503.8,68.5z"
                  />
                </g>
                <g>
                  <path
                    d="M512,85.7c5.6-1.4,11.1-3.6,15.9-6.1c4.8-2.5,9.1-5.5,12.4-8.3c6.7-5.7,9.8-10.9,8.6-12.1c-1.2-1.3-6.2,1.4-13.3,5.2
					c-7.1,3.9-16.5,8.8-26.3,13c-5.1,2.2-9.5,3.7-13.6,4.9c-4.1,1.2-8,2.1-11.9,3.3c-3.9,1.1-7.8,2.4-11.9,4.4c-2,1-4.2,2.2-6.2,3.8
					c-2.1,1.5-4.1,3.5-5.8,5.9c1.9-2.2,4.1-3.9,6.3-5.2c2.2-1.3,4.3-2.2,6.4-2.9c4.2-1.4,8.1-1.9,12-2.3c3.9-0.4,7.8-0.5,12.3-1
					C501.2,87.9,506.2,87.1,512,85.7z"
                  />
                </g>
                <g>
                  <path
                    d="M519.6,76.1c6.3-1,12.2-3.4,16.2-6c4.1-2.6,6.3-5.3,5.6-6.9c-0.7-1.6-4.1-1.8-8.7-0.9c-4.5,0.9-10.2,2.7-15.7,5.4
					c-5.7,2.7-9.6,4.6-13.6,6.3c-4.1,1.7-8.3,3.3-14.6,4.9c6.6-0.6,11-0.9,15.5-1.2C508.7,77.4,513.2,77.1,519.6,76.1z"
                  />
                </g>
                <g>
                  <path
                    d="M517.7,72.2c4.9-0.5,9.4-2.3,12.5-4.4c3.1-2.1,4.8-4.4,4.1-6c-0.7-1.6-3.4-2.2-7-1.8c-3.6,0.4-8,1.7-12.2,3.8
					c-4.3,2.3-7.3,3.9-10.4,5.4c-3.1,1.5-6.2,2.9-11.1,4.4c5-0.4,8.5-0.6,12-0.7C509.2,72.8,512.7,72.7,517.7,72.2z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M490.4,213.7c-5.6-1.1-11.2-2.4-16.6-3.5c-5.4-1.2-10.5-2.2-15-2.7c-4.5-0.6-8.4-0.7-11.1-0.5c-2.7,0.3-4.3,0.9-4.5,1.8
					c-0.1,0.9,1.2,1.9,3.6,3c2.4,1.1,5.9,2.4,10.1,3.8c4.2,1.4,9.2,2.9,14.7,4.2c5.5,1.3,11.6,2.3,17.8,2.6c6.4,0.2,11.8-0.1,16.7-1
					c4.8-0.9,9.1-2.1,13.1-4c1-0.4,2-1,3-1.4c1-0.5,2-1.1,2.9-1.7c1.9-1.2,3.8-2.5,5.7-4.1c3.7-3.1,7.4-7.3,10-13.1
					c-3.1,5.6-7.2,9.1-11.1,11.7c-2,1.3-3.9,2.3-5.9,3.1c-1,0.4-2,0.8-2.9,1.1c-1,0.3-2,0.6-3,0.8c-3.9,1-8,1.4-12.4,1.4
					C501.1,215.3,496.2,214.8,490.4,213.7z"
                  />
                </g>
                <g>
                  <path
                    d="M487.9,194.8c-5.8-0.4-11.6,0.1-17,1c-5.4,1-10.3,2.5-14.3,4.2c-8.1,3.4-12.6,7.4-11.8,8.9c0.8,1.6,6.3,0.6,14.3-0.9
					c8-1.5,18.4-3.4,29-4.4c5.5-0.6,10.2-0.7,14.5-0.6c4.3,0.1,8.3,0.4,12.3,0.5c4,0.1,8.2,0.1,12.7-0.6c2.3-0.4,4.6-0.9,7.1-1.7
					c2.4-0.9,4.9-2.1,7.3-3.9c-2.5,1.6-5.1,2.5-7.5,3.1c-2.4,0.6-4.8,0.8-7,0.8c-4.4,0-8.3-0.6-12.1-1.4c-3.8-0.8-7.6-1.9-12-2.8
					C498.8,196,493.9,195.2,487.9,194.8z"
                  />
                </g>
                <g>
                  <path
                    d="M477.7,201.6c-6.3-0.9-12.6-0.4-17.3,0.8c-4.7,1.2-7.6,3.1-7.4,4.9c0.2,1.8,3.4,2.9,8,3.5c4.6,0.5,10.6,0.5,16.6-0.4
					c6.2-0.9,10.5-1.5,14.9-1.9c4.4-0.4,8.9-0.6,15.4-0.3c-6.4-1.4-10.8-2.5-15.2-3.5C488.5,203.7,484.2,202.6,477.7,201.6z"
                  />
                </g>
                <g>
                  <path
                    d="M478.3,205.9c-4.8-1-9.7-0.7-13.3,0.4c-3.6,1-5.9,2.8-5.8,4.5c0.1,1.8,2.6,3.1,6.1,3.9c3.5,0.7,8.1,0.8,12.8,0
					c4.8-0.8,8.1-1.5,11.5-2c3.4-0.5,6.8-0.9,11.9-0.8c-4.9-1.1-8.3-2-11.6-3C486.6,207.9,483.3,206.9,478.3,205.9z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M295.7,319.1c4.1-3.9,8.4-7.8,12.5-11.5c4.1-3.7,7.9-7.3,10.9-10.7c3.1-3.3,5.4-6.4,6.8-8.8c1.4-2.4,1.8-4.1,1.2-4.7
					c-0.6-0.6-2.3-0.1-4.6,1.2c-2.3,1.3-5.4,3.4-9,6c-3.6,2.6-7.7,5.8-12,9.5c-4.2,3.7-8.6,8.1-12.4,13c-3.9,5.1-6.7,9.7-8.9,14.2
					c-2.1,4.4-3.5,8.7-4.4,13c-0.2,1.1-0.4,2.2-0.6,3.3c-0.1,1.1-0.3,2.2-0.3,3.3c-0.2,2.3-0.2,4.6,0,7c0.4,4.8,1.6,10.3,4.8,15.8
					c-2.8-5.7-3.3-11.1-3.1-15.8c0.1-2.3,0.4-4.5,0.9-6.6c0.2-1,0.5-2,0.8-3c0.3-1,0.6-2,1-2.9c1.5-3.8,3.5-7.3,6.1-11
					C288.2,326.9,291.4,323.2,295.7,319.1z"
                  />
                </g>
                <g>
                  <path
                    d="M312.5,328c3.7-4.5,6.7-9.5,9-14.5c2.3-4.9,4-9.8,4.9-14.1c1.9-8.6,1.3-14.5-0.4-14.8c-1.8-0.3-4.2,4.8-7.5,12.2
					c-3.4,7.4-7.9,16.9-13.3,26.2c-2.7,4.8-5.4,8.7-7.9,12.1c-2.6,3.4-5.1,6.5-7.5,9.7c-2.4,3.2-4.8,6.6-6.9,10.7
					c-1,2.1-2,4.3-2.7,6.8c-0.7,2.5-1.2,5.2-1.1,8.2c0.2-3,0.9-5.6,1.9-7.9c1-2.3,2.2-4.3,3.4-6.1c2.5-3.6,5.3-6.4,8.2-9
					c2.9-2.7,5.9-5.1,9.3-8.2C305.2,336.2,308.7,332.7,312.5,328z"
                  />
                </g>
                <g>
                  <path
                    d="M312.9,315.8c4.4-4.6,7.7-10,9.4-14.5c1.7-4.5,1.8-8,0.3-8.8c-1.6-0.9-4.4,1.1-7.5,4.5c-3.1,3.4-6.5,8.3-9.3,13.8
					c-2.9,5.6-4.9,9.4-7.1,13.3c-2.2,3.8-4.6,7.6-8.7,12.7c4.9-4.4,8.3-7.4,11.7-10.3C305,323.4,308.3,320.5,312.9,315.8z"
                  />
                </g>
                <g>
                  <path
                    d="M309,313.8c3.6-3.4,6.2-7.5,7.4-11c1.2-3.5,1.2-6.4-0.3-7.3c-1.5-0.9-4,0.3-6.7,2.7c-2.6,2.5-5.4,6.1-7.4,10.4
					c-2.1,4.4-3.5,7.5-5,10.5c-1.6,3-3.3,6.1-6.2,10.2c3.8-3.4,6.5-5.6,9.2-7.8C302.6,319.3,305.3,317.2,309,313.8z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M112.7,104.3c4.4,3.7,8.6,7.6,12.7,11.2c4.1,3.6,8.1,7,11.7,9.8c3.6,2.7,6.9,4.7,9.5,5.9c2.5,1.1,4.2,1.4,4.7,0.7
					c0.5-0.7-0.2-2.2-1.7-4.4c-1.5-2.2-3.9-5-6.9-8.3c-3-3.3-6.6-7.1-10.7-10.9c-4.2-3.8-8.9-7.7-14.2-11c-5.4-3.3-10.4-5.7-15-7.3
					c-4.6-1.6-9-2.6-13.4-3c-1.1-0.1-2.2-0.1-3.3-0.2c-1.1,0-2.2,0-3.4,0c-2.3,0.1-4.6,0.3-7,0.8c-4.8,0.9-10.1,2.7-15.2,6.4
					c5.4-3.3,10.7-4.4,15.4-4.7c2.3-0.1,4.6,0,6.7,0.2c1.1,0.1,2.1,0.3,3.1,0.5c1,0.2,2,0.4,3,0.7c3.9,1.1,7.6,2.7,11.5,4.9
					C104.2,97.6,108.2,100.5,112.7,104.3z"
                  />
                </g>
                <g>
                  <path
                    d="M105.7,121.9c4.9,3.2,10.2,5.7,15.3,7.5c5.2,1.8,10.2,2.9,14.5,3.4c8.7,1,14.6-0.3,14.7-2c0.1-1.8-5.2-3.6-12.9-6.2
					c-7.7-2.6-17.7-6.1-27.5-10.4c-5.1-2.2-9.2-4.4-12.9-6.6c-3.7-2.2-7-4.4-10.5-6.5C83,99,79.4,97,75.1,95.4c-2.1-0.8-4.5-1.5-7-2
					c-2.5-0.5-5.3-0.6-8.3-0.2c3-0.1,5.7,0.3,8.1,1c2.4,0.7,4.5,1.7,6.5,2.7c3.9,2.1,6.9,4.6,9.8,7.2c2.9,2.6,5.7,5.4,9.1,8.4
					C96.7,115.5,100.6,118.7,105.7,121.9z"
                  />
                </g>
                <g>
                  <path
                    d="M117.9,121c5,3.9,10.8,6.6,15.4,7.8c4.7,1.2,8.1,1,8.8-0.6c0.7-1.6-1.5-4.2-5.3-7c-3.7-2.7-9-5.6-14.7-7.8
					c-5.8-2.3-9.9-3.9-13.9-5.7c-4-1.8-8-3.8-13.6-7.4c4.9,4.4,8.2,7.5,11.5,10.5C109.5,113.9,112.7,117,117.9,121z"
                  />
                </g>
                <g>
                  <path
                    d="M119.5,117c3.7,3.2,8.1,5.3,11.7,6.2c3.6,0.9,6.5,0.5,7.2-1.1c0.8-1.6-0.7-4-3.4-6.4c-2.7-2.4-6.7-4.7-11.1-6.3
					c-4.6-1.6-7.8-2.7-11-3.9c-3.2-1.2-6.4-2.6-10.8-5.1c3.8,3.4,6.2,5.8,8.7,8.3C113.2,111.2,115.6,113.7,119.5,117z"
                  />
                </g>
              </g>
              <g>
                <path
                  d="M46.9,120.2c-4.8-3.3-10.4-5.5-16.3-6.1c-2.9-0.3-5.9-0.2-8.8,0.3c-2.9,0.5-5.8,1.4-8.4,2.9c-2.6,1.5-5.1,3.5-6.9,6.1
				c-0.5,0.7-0.9,1.3-1.2,2c-0.3,0.7-0.7,1.5-0.9,2.2L4,128.7l-0.2,1.1c-0.2,0.8-0.2,1.5-0.3,2.3c-0.1,3,0.5,5.8,1.6,8.3
				c1.1,2.5,2.6,4.6,4.5,6.3c1.8,1.7,4,3,6.2,3.6c2.1,0.7,4.2,1,6.1,0.9c1.9-0.1,3.6-0.4,5.2-0.9c1.5-0.5,2.8-1.1,3.9-1.8
				c2.2-1.3,3.6-2.9,4.3-4c0.7-1.2,0.8-2,0.5-2.2c-0.3-0.3-1.1-0.1-2.2,0.3c-1.1,0.4-2.6,1.1-4.6,1.5c-1,0.2-2,0.4-3.2,0.5
				c-1.1,0.1-2.4,0-3.6-0.2c-1.2-0.2-2.5-0.6-3.6-1.2c-1.2-0.6-2.1-1.4-3-2.4c-1.8-2.1-2.9-5.2-2.6-8.1c0.1-0.3,0.1-0.7,0.2-1
				l0.1-0.5l0.2-0.5c0.1-0.3,0.3-0.6,0.4-0.9c0.2-0.3,0.4-0.6,0.6-0.9c0.9-1.2,2.2-2.2,3.7-3.1c1.6-0.9,3.4-1.5,5.4-1.8
				c2-0.4,4.1-0.5,6.2-0.3c4.2,0.3,8.6,1.6,12.4,3.9c4,2.3,7.2,5.4,9.7,8.7c2.5,3.3,4.3,6.9,5.7,10.4c2.6,7.1,3.3,14.2,2.9,21.3
				c-0.4,7.1-1.8,14.3-4.7,22c-3,7.6-7.4,15.8-14.9,23.8c7.8-7.7,12.8-15.6,16.5-23.2c3.6-7.6,5.7-14.8,6.9-22.3
				c0.6-3.7,0.9-7.5,0.8-11.4c-0.1-3.9-0.5-8-1.6-12.1c-1.1-4.2-2.8-8.5-5.4-12.7C55.4,127.8,51.9,123.6,46.9,120.2z"
                />
              </g>
              <g>
                <path
                  d="M34,153.7c-3,0.6-5.5,2.1-7.5,4c-2,1.9-3.5,4.3-4.3,7c-0.4,1.3-0.6,2.7-0.6,4.1c0,1.4,0.2,2.8,0.7,4.1
				c0.8,2.5,2.4,4.7,4.4,6.1c1,0.7,2,1.2,3.1,1.5c1.1,0.3,2.1,0.4,3,0.4c1.9-0.1,3.4-0.7,4.4-1.5c1-0.8,1.6-1.6,1.8-2.3
				c0.2-0.7,0-1.2-0.3-1.5c-0.7-0.6-1.9-0.4-3.2-0.7c-0.7-0.1-1.4-0.4-2-0.7c-0.3-0.2-0.6-0.4-0.8-0.6c-0.2-0.2-0.4-0.5-0.6-0.8
				c-0.4-0.6-0.7-1.4-0.8-2.3c-0.1-0.4-0.1-0.9,0-1.3c0-0.5,0.2-1,0.3-1.5c0.6-2.1,2.6-4.5,4.7-5.5c1.1-0.5,2.1-0.7,3.3-0.5
				c1.2,0.2,2.3,0.8,3.3,1.6c1,0.8,1.8,1.7,2.5,2.8c0.7,1.1,1.2,2.3,1.6,3.6c0.8,2.6,1,5.5,0.9,8.8c-0.1,3.3-0.7,7-0.7,11.4
				c0.6-4.4,1.7-7.9,2.4-11.2c0.8-3.3,1.3-6.4,1.2-9.6c-0.1-1.6-0.3-3.3-0.8-4.9c-0.5-1.7-1.3-3.4-2.5-5c-1.2-1.6-2.8-3.2-5.1-4.3
				c-1.1-0.6-2.4-1-3.8-1.2C37.1,153.3,35.5,153.4,34,153.7z"
                />
              </g>
              <g>
                <path
                  d="M80.9,129c2-0.8,3.3-2.1,4-3.7c0.7-1.6,0.9-3.4,0.1-5c-0.8-1.5-2.7-2.3-4.8-1.9c-2.1,0.4-4.4,2-5.6,4.5
				c-1.2,2.6-1.6,4.5-1.9,6.4c-0.3,1.9-0.7,3.6-1.1,6.1c1.5-2,2.9-3.1,4.3-4C77.3,130.6,78.8,129.9,80.9,129z"
                />
              </g>
              <g>
                <path
                  d="M87.6,140.1c1.9,0,3.2-0.8,4.1-2.1c0.9-1.2,1.4-2.9,1-4.7c-0.4-1.7-1.8-3-3.7-3.3c-1.9-0.3-4.2,0.4-5.7,2.3
				c-1.5,2-2.3,3.5-3,5c-0.7,1.5-1.3,2.9-2.3,4.8c1.8-1.2,3.2-1.6,4.7-1.9C84.1,140.2,85.6,140.1,87.6,140.1z"
                />
              </g>
              <g>
                <path
                  d="M69.7,113.9c0.7-3.1,0.1-6.2-1.2-8.3c-1.3-2.1-3.2-3.1-4.9-2.7c-1.7,0.5-2.8,2.2-3.3,4.3c-0.5,2.1-0.3,4.6,0.7,7.2
				c1.1,2.6,1.8,4.6,2.5,6.6c0.6,2,1,4.1,1,7.1c1.1-2.9,1.9-4.9,2.8-6.9C68.1,119.2,69,117.1,69.7,113.9z"
                />
              </g>
              <g>
                <path d="M51.6,99.8c-0.9-5.8-6-9.3-9.1-7.5c-3,1.8-2.7,7.7,1.3,11.5c4.1,4,6.3,5.3,9.7,10.1C52.1,108.1,52.6,105.7,51.6,99.8z" />
              </g>
              <g>
                <path
                  d="M25.9,96.2c-2.9-2.2-6.5-3.1-9.2-2.9c-2.8,0.2-4.8,1.4-5.1,3.1c-0.3,1.7,0.9,3.6,3,5c2,1.5,4.9,2.6,8.1,2.9
				c3.3,0.4,5.7,0.6,8.1,1.1c2.4,0.5,4.7,1.1,7.9,2.7c-2.6-2.5-4.3-4.2-6.1-6C30.7,100.2,28.9,98.4,25.9,96.2z"
                />
              </g>
              <g>
                <path
                  d="M-1.6,103.7c-2.7-0.3-5.2,0.9-6.5,2.5c-1.4,1.7-1.5,3.6-0.6,5.1c1,1.5,2.6,2.2,4.2,2.4c1.6,0.2,3.2-0.2,4.8-1.5
				c1.6-1.3,3-2.3,4.4-3c1.4-0.8,3-1.3,5.4-1.4c-2.1-0.9-3.7-1.6-5.5-2.4C3,104.7,1.2,104-1.6,103.7z"
                />
              </g>
              <g>
                <path
                  d="M-13.1,124.3c-2.5,1.7-3.7,4.6-3.5,6.9c0.2,2.4,1.6,3.9,3.3,4.1c1.8,0.2,3.4-0.6,4.6-1.5c1.3-1,2.1-2.2,2.5-4.1
				c0.4-2,0.9-3.5,1.7-5c0.8-1.4,1.9-2.8,4.1-4c-2.5,0.2-4.3,0.5-6.2,0.9C-8.6,122.1-10.6,122.6-13.1,124.3z"
                />
              </g>
              <g>
                <path
                  d="M-9.6,149.9c-0.9,2.6-0.3,5.4,1.1,7.1c1.4,1.7,3.3,2.2,4.9,1.5c1.6-0.7,2.7-2.2,3.2-3.7c0.5-1.5,0.5-3.1-0.4-4.8
				c-0.9-1.8-1.5-3.3-1.9-4.9c-0.3-1.6-0.5-3.2,0.1-5.5c-1.6,1.8-2.7,3.2-3.8,4.6C-7.5,145.6-8.6,147.2-9.6,149.9z"
                />
              </g>
              <g>
                <path
                  d="M3.1,168.8c0.4,2.9,2.4,5.2,4.4,6.2c2.1,1,4.1,0.7,5.2-0.6c1.2-1.3,1.4-3.2,1.1-4.9c-0.3-1.7-1.2-3.3-2.9-4.7
				c-1.7-1.4-3-2.6-4.1-3.9c-1.1-1.3-2-2.9-2.7-5.4c-0.4,2.6-0.7,4.4-1,6.4C2.9,163.8,2.7,165.8,3.1,168.8z"
                />
              </g>
              <g>
                <path
                  d="M75.4,229.7c2.3,1.4,4,3.2,4.8,5.2c0.9,2,0.9,4.3,0.3,5.8c-0.6,1.5-2,2.8-3.6,3.4c-0.8,0.3-1.5,0.4-2.3,0.4
				c-0.8,0-1.6-0.2-2.3-0.6c-1.5-0.6-2.7-1.8-3.4-2.9c-0.7-1.1-0.9-2.1-0.8-2.9c0.1-0.8,0.6-1.6,1.1-2c0.4-0.5,0.9-0.8,1-1.2
				c0.1-0.4-0.3-1-1.5-1.3c-1.1-0.2-3,0.1-4.7,1.8c-0.4,0.4-0.8,1-1.1,1.6c-0.3,0.6-0.5,1.2-0.7,1.9c-0.3,1.4-0.3,2.8,0.1,4.3
				c0.7,2.9,2.5,5.8,5.5,7.9c1.5,1,3.3,1.8,5.3,2.3c2,0.4,4.2,0.4,6.3-0.2c2.1-0.5,4.1-1.5,5.9-3c1.8-1.4,3.3-3.4,4.2-5.7
				c0.9-2.3,1.3-4.7,1.1-7c-0.1-2.3-0.8-4.6-1.8-6.7c-1-2.1-2.5-4-4.2-5.5c-1.7-1.5-3.6-2.6-5.6-3.4c-4.1-1.7-8.1-2-11.5-1.8
				c-3.5,0.2-6.5,0.9-9.3,1.7c-5.5,1.7-10.2,3.7-14.6,6.1c-4.5,2.5-8.8,5.2-13,9c-2.1,1.9-4.3,4-6.3,6.4c-2.1,2.5-4.1,5.2-5.8,8.5
				c2-3.1,4.2-5.7,6.4-7.9c2.2-2.2,4.5-4.1,6.8-5.6c4.5-3.2,9-5.2,13.5-6.9c4.5-1.7,9.2-2.8,14.4-3.6c2.6-0.3,5.2-0.5,7.9-0.3
				c1.3,0.1,2.7,0.3,4,0.7C72.9,228.5,74.2,229,75.4,229.7z"
                />
              </g>
              <g>
                <path
                  d="M55.5,215c4.5-2.5,8.1-6.3,10.1-9.7c2-3.4,2.4-6.3,1-7.5c-1.4-1.1-4.1-0.2-7.1,1.7c-3.1,2-6.5,5-9.6,8.6
				c-3.2,3.8-5.4,6.3-7.9,8.8c-2.5,2.5-5.2,4.8-9.6,7.6c4.8-1.8,8.2-3.1,11.7-4.4C47.5,218.9,50.9,217.5,55.5,215z"
                />
              </g>
              <g>
                <path
                  d="M75.4,216.2c2.3,0.5,4.3,0.1,5.9-0.8c1.6-1,2.7-2.5,2.7-4.3c0-1.8-1.2-3.3-3.1-4.1c-1.9-0.8-4.5-0.8-6.8,0.5
				c-2.4,1.3-3.8,2.5-5.3,3.6c-1.5,1.1-2.9,2.2-5.1,3.5c2.5-0.3,4.3-0.1,6,0.2C71.3,215.2,73,215.7,75.4,216.2z"
                />
              </g>
              <g>
                <path
                  d="M35.3,208.6c0.9-2.4,1.3-5,1.3-7.4c0-0.6,0-1.2-0.1-1.8c-0.1-0.6-0.1-1.1-0.2-1.7c-0.2-1.2-0.6-2.3-1.1-3.4
				c-0.5-1.1-1.1-2-1.9-2.8c-0.7-0.8-1.6-1.3-2.3-1.6c-1.6-0.5-2.6,0-3,0.7c-0.4,0.8-0.3,1.8-0.5,2.6c-0.1,0.9-0.3,1.8-0.4,3
				c-0.1,0.7-0.1,1.4-0.2,2.1c0,0.4-0.1,0.9-0.1,1.3c0,0.5,0,0.9-0.1,1.4c-0.1,1.9-0.1,3.9,0,6c0.1,4.3-0.2,7.5-0.8,10.6
				c-0.7,3.1-1.8,6.3-4.7,10.3c3.7-3.3,5.9-5.9,8.1-8.7c1.1-1.4,2.1-2.8,3.1-4.5C33.4,213,34.4,211.1,35.3,208.6z"
                />
              </g>
              <g>
                <path
                  d="M124.7,53.8c3.3-0.9,6.7-1.7,9-1.4c1.2,0.1,2,0.4,2.7,0.9c0.6,0.4,1.2,1.1,1.6,2c0.9,1.8,1,4.3,0.5,6.2
				c-0.1,0.5-0.3,1-0.5,1.5c-0.2,0.5-0.5,0.9-0.7,1.4c-0.5,0.8-1.2,1.5-1.8,2.1c-1.2,1-2.9,1.5-4.2,1.4c-0.7,0-1.3-0.2-1.8-0.4
				c-0.5-0.3-1-0.6-1.4-1c-1.7-1.6-1.8-3.6-2.6-3.9c-0.4-0.1-1,0.2-1.5,1.2c-0.5,1-0.8,2.8-0.1,4.9c0.3,1,0.9,2.2,1.9,3.2
				c1,1.1,2.2,2,3.8,2.6c3,1.2,6.9,1.2,10.4-0.7c1.7-0.9,3.3-2.2,4.6-3.7c0.7-0.8,1.2-1.6,1.8-2.5c0.5-0.9,1-1.8,1.4-2.9
				c0.8-2.1,1.1-4.3,1.1-6.5c-0.1-2.3-0.5-4.6-1.5-6.9c-1.1-2.3-2.8-4.5-5.2-6c-2.4-1.5-5.1-2.2-7.6-2.3c-2.5-0.1-4.7,0.3-6.8,0.9
				c-2.1,0.6-3.9,1.2-5.7,2c-3.8,1.6-7.6,3.8-10.3,7c-1.3,1.6-2.3,3.5-2.9,5.3c-0.6,1.8-0.9,3.6-0.9,5.3c-0.1,3.4,0.5,6.4,1.3,9.2
				c0.9,2.8,2,5.4,3.4,7.8c2.8,4.8,6.6,9.1,11.7,12.4c2.6,1.7,5.5,3.1,8.7,4.2c3.3,1.1,6.9,1.7,10.9,1.8c-3.9-0.3-7.5-1.3-10.6-2.6
				c-3.1-1.3-5.7-3-8-4.8c-4.5-3.7-7.5-8.1-9.4-12.7c-1-2.3-1.6-4.7-2-7.2c-0.4-2.5-0.5-5,0-7.5c0.2-1.2,0.6-2.4,1.1-3.4
				c0.5-1,1.2-2,2.1-2.8C118.7,56,121.3,54.8,124.7,53.8z"
                />
              </g>
              <g>
                <path
                  d="M86,72c-3-1.1-6.3-0.6-8.4,0.7c-2.1,1.3-3.1,3.2-2.6,4.9c0.5,1.7,2.2,2.8,4,3.3c1.9,0.6,4,0.6,6.4-0.2
				c2.5-0.8,4.3-1.2,6.3-1.4c1.9-0.2,4-0.2,6.8,0.7c-2.3-1.8-4-3.1-5.7-4.3C90.9,74.4,89.1,73.1,86,72z"
                />
              </g>
              <g>
                <path
                  d="M99.5,62.1c0.8-1.3,0.8-2.3,0.3-3.6c-0.5-1.2-1.3-2.8-2.7-3.8c-0.7-0.5-1.6-0.7-2.5-0.6c-0.9,0.1-1.8,0.5-2.6,1.3
				c-0.8,0.8-1.4,1.9-1.7,3.2c-0.3,1.3-0.2,2.8,0.4,4.1c1.2,2.8,2.7,4.1,4.1,5.3c1.4,1.1,2.9,2,4.9,2.9c-1.3-1.8-1.6-3.3-1.5-4.7
				C98.2,64.8,98.7,63.5,99.5,62.1z"
                />
              </g>
              <g>
                <path
                  d="M152.6,18.3c3.1-2.4,6-4.9,8.2-6.9c2.2-2,3.6-3.6,3.1-4.4c-0.4-0.8-2.6-0.5-5.5,1c-2.8,1.4-6.3,4-8.9,7.3
				c-2.7,3.4-4.2,5.9-5.6,8.5c-1.4,2.6-2.7,5.2-4.4,9.2c2.2-3.7,4-6,6-8.1C147.4,22.8,149.4,20.8,152.6,18.3z"
                />
              </g>
              <g>
                <path
                  d="M161.5,23.3c3.2-1.8,6.3-3.8,8.5-5.5c2.2-1.7,3.6-3.2,3.2-4c-0.4-0.8-2.5-0.7-5.2,0.3c-2.8,1-6.2,3-9.1,5.6
				c-2.9,2.7-4.7,4.8-6.5,6.9c-1.7,2.1-3.5,4.3-5.9,7.5c2.8-2.9,5-4.6,7.2-6.1C155.9,26.6,158.1,25.2,161.5,23.3z"
                />
              </g>
              <g>
                <path
                  d="M160,33.4c2.1-0.6,3.9-1.6,5.2-2.7c1.3-1,2-2.1,1.7-3c-0.3-0.8-1.6-1.2-3.4-0.9c-1.7,0.3-3.9,1.2-5.7,2.7
				c-1.8,1.6-2.9,2.8-4,4c-1.1,1.2-2.2,2.4-3.9,4.1c2-1.3,3.5-1.9,4.9-2.5C156.3,34.6,157.8,34.1,160,33.4z"
                />
              </g>
              <g>
                <path
                  d="M207.1,151.4c1.6-3.8,3.4-7.5,4.8-10.2c1.4-2.8,2.4-4.8,1.8-5.4c-0.6-0.6-2.8,0.4-5.2,3c-2.4,2.6-4.7,6.8-5.6,11.4
				c-0.8,4.7-0.7,8-0.3,11.2c0.4,3.2,1,6.4,2.6,10.8c-1.1-4.5-1.1-7.7-0.7-10.8C204.8,158.3,205.5,155.3,207.1,151.4z"
                />
              </g>
              <g>
                <path
                  d="M186.8,193.3c-0.7,3.1-1.2,5.4-1.8,8c-0.5,2.2-0.7,3.9,0,4.4c0.7,0.5,2.3-0.5,3.8-2.8c1.4-2.2,2.6-5.8,2.3-9.3
				c-0.2-3.6-1-6-1.8-8.2c-0.9-2.2-1.9-4.4-3.8-7.3c1.5,3.1,1.8,5.5,2,7.7C187.6,188,187.5,190.3,186.8,193.3z"
                />
              </g>
              <g>
                <path
                  d="M166,263.2c-0.7,3.5-2,6.9-3.1,9.4c-1.1,2.5-2,4.4-1.3,5c0.6,0.6,2.7-0.2,4.9-2.8c1.1-1.3,2.1-2.9,2.8-4.9
				c0.7-1.9,1.2-4.2,1.1-6.4c0-2.3-0.4-4.1-0.9-5.8c-0.5-1.6-1-3-1.7-4.3c-1.4-2.6-3-5.2-6.2-8.1c2.8,3.3,3.8,6,4.4,8.7
				c0.3,1.3,0.4,2.7,0.5,4.2C166.5,259.8,166.4,261.4,166,263.2z"
                />
              </g>
              <g>
                <path
                  d="M249.5,317.1c1.2-0.7,2.4-1.2,3.5-1.4c0.6-0.1,1.1-0.1,1.6-0.1c0.5,0,1,0.2,1.5,0.3c2,0.7,4,2.6,5.2,4.8
				c0.3,0.5,0.5,1.1,0.8,1.7c0.2,0.6,0.4,1.2,0.5,1.8c0.2,1.2,0.3,2.3,0.2,3.3c-0.2,1.9-1.3,3.4-2.5,4.1c-0.1,0.1-0.3,0.2-0.4,0.2
				c-0.1,0.1-0.3,0.1-0.4,0.2c-0.3,0.1-0.6,0.1-0.9,0.2c-0.7,0-1.3-0.1-1.8-0.4c-0.5-0.2-0.9-0.6-1.2-0.9c-0.3-0.4-0.5-0.9-0.5-1.4
				c-0.1-1.1,0.1-2,0.4-2.8c0.3-0.7,0.6-1.3,0.4-1.7c-0.1-0.4-0.8-0.7-2-0.3c-1.1,0.4-2.7,1.6-3.5,3.8c-0.4,1.1-0.6,2.5-0.3,4.1
				c0.3,1.6,1.1,3.2,2.4,4.5c1.3,1.3,3,2.2,4.9,2.7c1,0.2,2,0.3,3.1,0.3c0.5,0,1.1-0.1,1.7-0.2c0.5-0.1,1.1-0.3,1.6-0.4
				c4.2-1.5,7.4-5.3,8.5-10c0.5-2.3,0.6-4.6,0.2-6.8c-0.2-1.1-0.4-2.2-0.8-3.3c-0.4-1.1-0.8-2.2-1.4-3.2c-1.1-2.1-2.5-4-4.4-5.6
				c-1.8-1.6-4.1-3-6.7-3.8c-2.6-0.8-5.5-0.8-8.1-0.1c-2.6,0.7-4.8,2-6.7,3.5c-3.8,3.3-5.9,7.3-7.1,11c-1.1,3.8-1.4,7.4-1.1,10.7
				c0.2,3.3,1,6.4,1.9,9.1c1,2.8,2.1,5.4,3.5,7.8c1.3,2.5,2.7,4.9,4.3,7.3c1.5,2.4,3.1,4.8,4.9,7.4c3.6,5,7.8,10.5,13.5,16.3
				c-5.3-6.2-9-11.9-12.1-17.2c-1.5-2.7-2.8-5.2-4-7.8c-1.2-2.5-2.3-5-3.2-7.5c-0.9-2.5-1.7-5-2.2-7.6c-0.5-2.6-0.7-5.2-0.6-7.9
				c0.2-2.7,0.8-5.5,2-8.1c0.6-1.3,1.3-2.5,2.2-3.7C247.3,318.8,248.3,317.8,249.5,317.1z"
                />
              </g>
              <g>
                <path
                  d="M264.6,361c0.7-2.6,1-4.8,0.9-6.9c-0.1-2.1-0.6-4.1-2-5.2c-0.7-0.5-1.7-0.6-2.7-0.3c-1,0.3-2.1,1.1-3.1,2.3
				c-1,1.2-1.7,2.8-2.1,4.7c-0.4,1.9-0.3,3.9,0.3,5.9c0.6,2,1.4,3.6,2.2,4.9c0.8,1.3,1.7,2.3,2.6,3.3c1.8,1.9,3.7,3.6,6.7,5.5
				c-2.3-2.7-3-5-3.3-7.2C263.8,365.9,263.9,363.7,264.6,361z"
                />
              </g>
              <g>
                <path
                  d="M284.7,370c-3.2,6-5.2,12.1-7.6,17c-1.2,2.5-2.4,4.7-3.7,6.6c-0.7,0.9-1.4,1.8-2.1,2.6c-0.7,0.8-1.4,1.4-2.1,2
				c-1.4,1.1-2.5,1.5-3.5,1.7c-0.3,0.1-0.8,0-1.2-0.1c-0.5-0.2-0.9-0.4-1.2-0.7c-0.7-0.5-1.1-1.1-1.5-1.7c-0.3-0.6-0.5-1.1-0.9-1.3
				c-0.2-0.1-0.4-0.1-0.7,0c-0.3,0.1-0.6,0.4-0.9,0.8c-0.6,0.9-1,2.7-0.3,4.6c0.3,1,0.9,1.9,1.8,2.8c0.9,0.9,2.1,1.8,3.7,2.2
				c1.4,0.4,3,0.5,4.6,0.3c1.6-0.2,3.1-0.7,4.5-1.4c1.4-0.7,2.7-1.6,3.9-2.6c1.2-1,2.3-2.1,3.4-3.3c2.1-2.4,3.9-5.2,5.3-8.2
				c2.9-5.9,4.6-12.1,6.6-17.7c1.1-2.9,2.2-5.3,3.6-7.5c1.4-2.2,2.8-4.2,4.4-5.9c0.7-0.9,1.6-1.7,2.4-2.5c0.9-0.7,1.7-1.5,2.5-2.2
				c0.4-0.3,0.9-0.7,1.3-1c0.4-0.3,0.9-0.6,1.3-0.9c0.9-0.6,1.8-1.2,2.8-1.7c3.8-2.2,8-3.8,13-4.7c1.2-0.2,2.5-0.4,3.9-0.4
				c1.3-0.1,2.7-0.1,4.1,0.1c2.8,0.3,5.9,1.2,8.4,3.6c-2.3-2.6-5.4-3.8-8.3-4.4c-1.5-0.3-2.9-0.5-4.3-0.6c-1.4-0.1-2.7,0-4.1,0
				c-5.2,0.3-9.9,1.3-14.4,3c-1.1,0.4-2.2,0.9-3.3,1.4c-0.5,0.3-1.1,0.5-1.6,0.8c-0.5,0.3-1.1,0.6-1.6,0.9c-1.1,0.6-2.1,1.3-3.2,2
				c-1,0.8-2.1,1.5-3.2,2.3c-2.1,1.7-4.2,3.7-6.2,6C288.2,364.1,286.3,366.9,284.7,370z"
                />
              </g>
              <g>
                <path
                  d="M311.3,353.5c-2.3,1-4.3,2.4-5.9,4.2c-1.6,1.7-2.9,3.8-3.4,5.9c-0.6,2.1-0.3,4.2,0.4,5.5c0.7,1.3,1.7,1.7,2.5,1.5
				c0.9-0.2,1.6-0.8,2.3-1.4c0.7-0.6,1.5-1.2,2.3-1.9c0.9-0.7,1.8-1.6,3-2.7c1.2-1.1,2.4-2.4,3.6-3.7c2.6-2.8,4.9-4.5,7.5-5.8
				c1.3-0.6,2.7-1.3,4.3-1.7c1.6-0.4,3.5-0.7,5.7-0.7c-2.2-0.6-4.1-0.9-5.9-1.1c-1.8-0.2-3.4-0.3-5-0.3c-1.6,0-3.3,0.1-5.1,0.4
				C315.7,352,313.7,352.5,311.3,353.5z"
                />
              </g>
              <g>
                <path
                  d="M333.5,332.9c0.1-2-0.4-3.5-1.3-5c-0.9-1.4-2.2-2.8-3.9-3.1c-0.9-0.2-1.7,0-2.5,0.5c-0.8,0.5-1.5,1.4-1.9,2.6
				c-0.4,1.2-0.6,2.6-0.4,4.2c0.3,1.5,0.9,3.1,2,4.3c2.2,2.6,4.3,3.7,6.2,4.6c1.9,0.9,3.9,1.5,6.5,2.1c-2.2-1.6-3.2-3.2-3.8-4.8
				C333.7,336.7,333.4,335,333.5,332.9z"
                />
              </g>
              <g>
                <path
                  d="M523.9,354.8c0.8-4,0.7-8.3-0.4-12.2c-1-3.9-2.9-7.6-5.4-10.8c-2.5-3.2-5.7-5.8-9.2-7.6c-3.5-1.8-7.3-3-11-3.4
				c-3.8-0.4-7.6-0.2-11.2,0.8c-3.6,0.9-7.2,2.6-10.1,5.5c-1.4,1.4-2.6,3.2-3.4,5c-0.8,1.8-1.3,3.7-1.4,5.5
				c-0.3,3.7,0.5,7.3,2.3,10.3c1.8,3,4.4,5.2,7.1,6.5c2.7,1.3,5.5,1.8,8.1,1.8c2.6-0.1,5-0.8,7-1.8c2-1,3.5-2.4,4.6-3.7
				c2.3-2.7,3.1-5.3,3.4-7.1c0.2-1.8-0.1-2.8-0.5-2.8c-0.5-0.1-1,0.7-1.9,1.9c-0.9,1.2-2.2,3-4.3,4.5c-1,0.7-2.3,1.4-3.7,1.8
				c-1.4,0.4-2.9,0.6-4.5,0.4c-1.6-0.2-3.2-0.7-4.7-1.7c-1.5-0.9-2.7-2.2-3.4-3.7c-0.7-1.5-1-3.5-0.7-5.3c0.1-0.9,0.4-1.8,0.8-2.6
				c0.4-0.8,0.9-1.4,1.6-2.1c2.8-2.6,8.4-3.9,13.5-3.2c5.3,0.6,10.4,3.1,13.9,7.3c3.5,4.2,5.4,9.9,4.7,15.4
				c-0.8,5.7-4.1,10.7-8.1,14.1c-4.1,3.4-9,5.5-13.8,6.4c-4.9,1-9.8,1-14.5,0.4c-4.7-0.6-9.3-1.9-13.8-3.5
				c-4.5-1.6-8.9-3.6-13.5-5.8c-4.5-2.2-9.2-4.6-14.3-6.8c-5.1-2.2-10.8-4.1-17.1-4.8c-3.1-0.3-6.4-0.3-9.8,0.1
				c-1.7,0.3-3.4,0.7-5,1.2c-1.7,0.6-3.3,1.3-4.9,2.2c1.6-0.8,3.3-1.5,5-2c1.7-0.5,3.4-0.8,5-1c3.3-0.3,6.6-0.2,9.6,0.3
				c6.2,0.9,11.5,3.2,16.4,5.6c4.9,2.4,9.4,5.1,13.8,7.7c4.4,2.5,8.8,4.9,13.3,7c4.6,2,9.3,3.8,14.4,4.8c5.1,1.1,10.6,1.5,16.4,0.8
				c5.7-0.7,11.9-2.6,17.5-6.7c2.7-2,5.3-4.5,7.5-7.6C521.4,362.6,523.1,358.9,523.9,354.8z"
                />
              </g>
              <g>
                <path
                  d="M447.8,308.1c-3.2-3.2-7.6-5.5-12.3-6.1c-4.7-0.7-9.8,0.3-13.9,3.4c-2,1.5-3.5,3.4-4.6,5.6c-1.1,2.1-1.7,4.5-1.7,7
				c0,0.6,0.1,1.2,0.1,1.8c0.1,0.6,0.2,1.2,0.4,1.8c0.3,1.2,0.9,2.2,1.4,3.2c1.2,1.9,2.7,3.4,4.5,4.5c1.8,1.1,3.7,1.7,5.7,1.8
				c1.9,0.1,3.9-0.4,5.4-1.3c1.5-0.9,2.6-2,3.3-3.1c0.8-1.1,1.2-2.3,1.5-3.3c0.5-2.1,0-3.9-0.6-4.9c-0.3-0.5-0.6-0.8-0.9-1
				c-0.3-0.2-0.5-0.2-0.7-0.1c-0.2,0.1-0.4,0.3-0.5,0.6c-0.1,0.3-0.2,0.6-0.4,1c-0.3,0.8-0.6,1.7-1.4,2.6c-0.7,0.9-1.9,1.8-3.1,2
				c-1.1,0.2-2.3-0.1-3.5-1.2c-0.6-0.5-1.1-1.2-1.4-2c-0.1-0.4-0.3-0.7-0.3-1.1c0-0.2,0-0.3,0-0.5c0-0.2,0-0.4,0-0.6
				c0.1-1.6,1.2-3.6,2.9-4.8c1.6-1.2,4-1.9,6.7-1.6c2.7,0.2,5.3,1.3,7.7,3.1c2.4,1.8,4.2,4.3,5.3,6.8c1.1,2.5,1.5,5.1,1.3,7.6
				c-0.3,5-2.7,9.6-6.2,12.9c-3.5,3.3-8.6,5.3-14.4,5.7c-5.9,0.5-12.6-0.9-20.1-3.5c7.3,3.1,13.9,5,20.1,5.2
				c6.2,0.2,12-1.1,16.8-4.5c2.4-1.7,4.5-3.8,6.2-6.3c1.7-2.5,3.1-5.4,3.7-8.8C456.1,323.3,454.5,314.8,447.8,308.1z"
                />
              </g>
              <g>
                <path
                  d="M458.9,328.3c-2.4,2.5-3.5,5.9-3.3,8.5c0.2,2.7,1.6,4.5,3.3,4.7c1.8,0.2,3.4-0.9,4.8-2.5c1.3-1.6,2.3-3.7,2.8-6.3
				c0.5-2.7,1-4.7,1.8-6.7c0.8-1.9,1.8-3.8,4.1-6.1c-2.9,1.3-4.9,2.4-6.9,3.5C463.4,324.6,461.4,325.8,458.9,328.3z"
                />
              </g>
              <g>
                <path
                  d="M463.1,362.7c0.5,3.2,2.7,5.9,5,6.9c2.3,1.1,4.4,0.7,5.5-0.7c1-1.4,1-3.4,0.6-5.1c-0.5-1.8-1.4-3.3-3.1-4.8
				c-1.7-1.6-3-3-3.9-4.5c-1-1.6-1.8-3.4-1.9-6.2c-0.9,2.7-1.4,4.7-1.9,6.8C462.9,357.2,462.6,359.4,463.1,362.7z"
                />
              </g>
              <g>
                <path
                  d="M490.6,369c1.7,0.6,3.5,0.7,5.2,0.5c1.7-0.3,3.1-0.9,4.2-1.7c2.3-1.7,2.9-3.9,2-5.4c-0.9-1.5-2.7-2.2-4.6-2.6
				c-1.9-0.3-3.8-0.2-6.1,0.5c-2.3,0.7-4.3,0.9-6.3,0.8c-2-0.2-4-0.7-6.5-2.5c1.7,2.6,3.2,4.3,4.9,5.9
				C485.2,366,487.1,367.7,490.6,369z"
                />
              </g>
              <g>
                <path
                  d="M448.5,349.2c-1.6-0.6-2.7-0.5-4,0.1c-1.3,0.6-2.7,1.6-3.5,3.2c-0.4,0.8-0.5,1.7-0.2,2.5c0.2,0.9,0.8,1.7,1.7,2.4
				c0.9,0.7,2.1,1.2,3.5,1.3c1.3,0.1,2.8-0.1,4.1-0.8c2.7-1.5,3.9-3.1,5-4.5c1.1-1.5,1.9-3.1,2.9-5.2c-1.8,1.5-3.4,1.9-4.9,1.9
				C451.6,350.1,450.2,349.9,448.5,349.2z"
                />
              </g>
              <g>
                <path
                  d="M441,389.9c-1.2,1.3-3,2.2-4.8,2.4c-1.7,0.3-3.6,0-5.4-0.8c-1.8-0.7-3.5-2-4.7-3.3c-1.2-1.3-1.9-2.6-2-3.5
				c-0.2-1,0.1-2.1,0.4-2.7c0.3-0.6,0.9-1,1.7-1.1c0.8-0.1,1.5,0,2.1,0.2c0.6,0.2,1.1,0.4,1.5,0.3c0.4-0.1,0.8-0.6,0.6-1.7
				c-0.2-1-1-2.4-2.7-3.4c-0.8-0.5-1.9-0.8-3.2-0.9c-1.3-0.1-2.7,0.3-4,1.1c-2.7,1.6-4.5,4.5-4.8,8c-0.3,3.6,1.1,7,3.1,9.7
				c2,2.7,4.7,4.9,8,6.4c3.3,1.5,7.3,2.2,11.4,1.2c3.9-0.9,7.6-3.3,9.9-6.8c0.6-0.9,1.1-1.9,1.4-2.9c0.4-1,0.6-2,0.7-2.9
				c0.3-1.9,0.2-3.7-0.1-5.3c-0.6-3.2-1.9-5.9-3.3-8.1c-1.5-2.2-3.2-4.1-5-5.6c-1.8-1.5-3.7-2.8-5.7-3.8c-4-2.1-8.4-3.3-13.2-3.8
				c-4.9-0.5-10.3-0.3-16.5,0.5c12.6-0.7,21.4,1.7,27.8,6.5c3.2,2.4,5.8,5.4,7.4,9.2c0.8,1.9,1.3,3.9,1.3,5.9
				C442.8,386.8,442.3,388.7,441,389.9z"
                />
              </g>
              <g>
                <path
                  d="M457.3,391.4c0,2.2,1.1,3.9,2.5,4.7c1.5,0.9,3.3,0.9,4.8,0c1.5-0.9,2.4-2.4,2.6-4c0.2-1.5-0.4-3.1-1.9-4.3
				c-1.5-1.2-2.7-2.1-3.9-3c-1.1-0.9-2.1-2-3.1-3.7c0,2-0.2,3.5-0.4,5C457.7,387.6,457.4,389.2,457.3,391.4z"
                />
              </g>
              <g>
                <path
                  d="M431.7,410.6c-2.8,1.3-4.6,3.9-5.2,6.2c-0.6,2.3,0.1,4.3,1.6,5.2c1.5,0.9,3.5,0.6,5.2-0.3c1.7-0.9,3.3-2.4,4.4-4.7
				c1.1-2.3,2-4,3.1-5.6c1.1-1.6,2.4-3.1,4.8-4.7c-2.7,0.6-4.7,1-6.8,1.5C436.6,408.7,434.5,409.2,431.7,410.6z"
                />
              </g>
              <g>
                <path
                  d="M389.6,226.7c1.7,0.3,3.4,0.1,5-0.1c1.5-0.3,2.9-0.8,4-1.3c2.2-1.1,3.4-2.3,3.2-3.1c-0.3-0.9-2-1.1-4.3-0.9
				c-2.3,0.2-5.1,0.7-7.8,1.1c-2.8,0.4-4.9,0.2-6.8-0.5c-1-0.3-1.9-0.8-2.9-1.6c-0.9-0.7-1.9-1.8-2.6-3.3c0.4,1.6,1.1,2.9,1.9,3.9
				c0.8,1,1.7,1.9,2.6,2.6c0.9,0.7,2,1.4,3.2,1.9C386.4,226,387.9,226.5,389.6,226.7z"
                />
              </g>
              <g>
                <path
                  d="M392.5,238.2c1.9,2.5,3.7,4.8,5.3,6.6c1.6,1.7,2.9,2.9,3.7,2.5c0.8-0.3,0.8-2.2-0.1-4.7c-1-2.4-3-5.4-5.8-7.5
				c-2.9-2.2-5-3.2-7.2-4.2c-2.2-1-4.4-1.9-7.8-2.8c3.1,1.5,5,2.9,6.7,4.4C389.1,234,390.6,235.6,392.5,238.2z"
                />
              </g>
              <g>
                <path
                  d="M351.7,217.7c-0.7,2.4-1.3,4.7-1.7,6.6c-0.4,1.9-0.5,3.4,0.2,3.9c0.7,0.5,2.2-0.2,3.6-2.1c1.4-1.8,2.4-4.8,2.3-7.9
				c-0.2-3.1-0.8-5.1-1.4-7.1c-0.7-1.9-1.5-3.8-3.1-6.3c1,2.7,1.2,4.7,1.2,6.6C352.7,213.3,352.5,215.2,351.7,217.7z"
                />
              </g>
              <g>
                <path
                  d="M446.3,284.3c3.7-0.6,7.2-2.4,9.4-4.3c2.2-1.9,3-3.7,2.5-4.4c-0.6-0.7-2.4-0.1-4.7,0.8c-2.3,1-5.3,2.3-8.3,3.6
				c-3.2,1.4-5.5,2-7.9,2.5c-2.4,0.4-5,0.7-8.7,0.1c3.6,1.1,6.2,1.5,8.8,1.8C439.8,284.8,442.4,284.9,446.3,284.3z"
                />
              </g>
              <g>
                <path
                  d="M455.1,296.1c2.5,0.2,5-0.7,6.5-1.9c1.5-1.2,2-2.6,1.4-3.3c-0.6-0.7-1.8-0.7-3.3-0.4c-1.5,0.2-3.2,0.7-5.1,1.3
				c-1.9,0.6-3.4,0.9-4.9,0.9c-1.5,0-3.1-0.1-5.3-1.1c1.9,1.4,3.3,2.3,4.9,3C450.8,295.2,452.5,295.8,455.1,296.1z"
                />
              </g>
              <g>
                <path
                  d="M512.7,278.9c-0.8,1.7-2.2,3.2-3.7,4.4c-1.5,1.2-3.3,2.3-4.8,3.4c-1.5,1.1-2.8,2.1-3.5,3c-0.8,0.9-1,1.6-0.8,2
				c0.3,0.4,1,0.3,2,0.1c1.1-0.2,2.5-0.7,4.2-1.4c1.7-0.7,3.8-1.6,5.9-3.2c2-1.6,4-3.9,4.8-6.9c0.4-1.5,0.4-3,0.2-4.4
				c-0.3-1.3-0.8-2.5-1.4-3.4c-1.2-1.9-2.8-3.1-4.4-4c-3.3-1.8-7-2.2-12.2-2.6c2.6,0.5,4.7,0.9,6.5,1.6c1.8,0.7,3.3,1.5,4.5,2.6
				c1.2,1.1,2.2,2.4,2.8,3.9C513.5,275.5,513.5,277.2,512.7,278.9z"
                />
              </g>
              <g>
                <path
                  d="M421,118.9c2.1-3.8,2.7-8.3,2.3-11.5c-0.4-3.3-1.6-5.2-2.4-5c-0.9,0.2-1.3,2.2-1.8,4.9c-0.5,2.8-1.2,6.3-2.2,9.9
				c-1.1,3.7-2.3,6.2-3.8,8.5c-1.5,2.3-3.4,4.6-6.9,6.9c3.8-1.9,6.2-3.7,8.4-5.7C416.7,125,418.8,122.8,421,118.9z"
                />
              </g>
              <g>
                <path
                  d="M423,162.8c5.4,0.3,10.9,0.4,15,0.3c4.1-0.1,6.9-0.6,7-1.5c0.1-0.9-2.6-2-6.7-2.7c-4.2-0.7-9.8-1.1-15.4-0.5
				c-5.7,0.6-9.6,1.3-13.5,2.1c-3.9,0.8-7.8,1.6-13.5,3c5.8-0.8,9.8-1,13.7-1.1C413.6,162.4,417.4,162.5,423,162.8z"
                />
              </g>
              <g>
                <path
                  d="M428.4,172.9c6.4,1.8,13.2,1.4,13.5-0.3c0.2-1.7-6.2-4-12.9-4c-6.9,0-9.5,0.3-16.5-0.1
				C419.4,169.9,421.8,171,428.4,172.9z"
                />
              </g>
              <g>
                <path
                  d="M465.2,185.4c3.3-1.4,6.4-3,8.7-4.4c2.3-1.4,3.8-2.7,3.5-3.5c-0.3-0.8-2.3-1-5.1-0.3c-2.8,0.7-6.3,2.2-9.3,4.5
				c-3.1,2.4-5,4.3-6.8,6.1c-1.8,1.9-3.7,3.9-6.2,6.8c2.9-2.6,5.1-4,7.4-5.3C459.5,188,461.8,186.9,465.2,185.4z"
                />
              </g>
              <g>
                <path
                  d="M479.4,189.5c3.5-0.2,7-0.5,9.6-1c2.6-0.4,4.5-1,4.6-1.9c0.1-0.9-1.7-1.8-4.6-2.3c-2.8-0.5-6.7-0.3-10.4,0.8
				c-3.7,1.2-6.2,2.3-8.5,3.5c-2.4,1.2-4.7,2.5-8,4.6c3.5-1.6,6.1-2.3,8.6-2.8C473.3,190.1,475.8,189.7,479.4,189.5z"
                />
              </g>
              <g>
                <path
                  d="M496,177.4c5.3-1.4,9.4-5.2,8.5-6.8c-0.9-1.5-6-0.2-10.3,2.8c-2.2,1.5-3.8,2.6-5.3,3.7c-1.6,1-3.2,2-5.8,3.2
				c2.7-0.7,4.6-1.1,6.5-1.5C491.4,178.5,493.3,178.1,496,177.4z"
                />
              </g>
              <g>
                <path
                  d="M508.6,181.3c2.3,0.5,4.6,0.6,6.3,0.4c1.8-0.2,3.1-0.7,3.3-1.5c0.2-0.9-0.9-1.9-2.7-2.6c-1.8-0.7-4.4-1.1-6.9-0.6
				c-2.6,0.4-4.3,1-6,1.5c-1.8,0.6-3.6,1.2-5.8,2c2.6-0.3,4.3-0.3,6-0.1C504.5,180.5,506.2,180.8,508.6,181.3z"
                />
              </g>
              <g>
                <path
                  d="M515.5,191.4c5.5,1.8,11.5,1.5,11.8-0.3c0.3-1.7-5.2-4-11-4.1c-6-0.1-8.3,0.3-14.4-0.2
				C507.8,188.4,509.8,189.5,515.5,191.4z"
                />
              </g>
              <g>
                <path
                  d="M537.8,184.7c2.8,0.1,5.6-0.6,7.4-1.6c1.9-1,2.9-2.2,2.6-3c-0.3-0.8-1.8-1.1-3.7-1c-2,0.1-4.4,0.5-6.9,1.3
				c-2.5,0.8-4.3,1.3-6.2,1.7c-1.9,0.4-3.8,0.6-6.6,0.6c2.8,0.6,4.7,1,6.6,1.3C533,184.3,535,184.6,537.8,184.7z"
                />
              </g>
              <g>
                <path
                  d="M537.5,176.3c2.8-0.1,5.5-1.1,7.4-2.2c1.8-1.1,2.8-2.4,2.4-3.2c-0.4-0.8-1.9-1-3.8-0.7c-2,0.2-4.4,0.9-6.9,1.9
				c-2.5,1-4.3,1.6-6.1,2.2c-1.8,0.5-3.7,1-6.6,1.2c2.9,0.3,4.8,0.5,6.8,0.7C532.6,176.2,534.6,176.4,537.5,176.3z"
                />
              </g>
              <g>
                <path
                  d="M535.8,166.5c2.7-0.6,5.2-1.8,6.8-3c1.7-1.3,2.5-2.6,2.1-3.4c-0.8-1.6-6.1-0.4-10.6,2.3c-2.3,1.4-4,2.4-5.6,3.4
				c-1.7,0.9-3.4,1.8-6.1,2.9c2.8-0.5,4.7-0.8,6.7-1C531.1,167.3,533,167.1,535.8,166.5z"
                />
              </g>
              <g>
                <path
                  d="M557.3,160.8c1.9-0.2,3.7-0.8,5-1.5c1.3-0.7,2.2-1.5,2.2-2.4c-0.1-0.9-1.2-1.6-2.9-1.7c-1.7-0.2-3.9,0.3-5.8,1.5
				c-1.9,1.2-3.1,2.3-4.2,3.4c-1.1,1.1-2.2,2.1-3.7,3.7c1.8-1.2,3.2-1.7,4.6-2.1C553.9,161.3,555.3,161,557.3,160.8z"
                />
              </g>
              <g>
                <path
                  d="M366.2,478.6c3.4-1,6.8-2.1,9.3-3.2c2.5-1.1,4.2-2.1,4-3c-0.1-0.9-2.1-1.3-5-1.1c-2.9,0.3-6.7,1.3-10,3.2
				c-3.4,2-5.6,3.6-7.7,5.3c-2.1,1.7-4.2,3.4-7,6.1c3.2-2.3,5.6-3.5,8-4.5C360.2,480.5,362.6,479.6,366.2,478.6z"
                />
              </g>
              <g>
                <path
                  d="M377.5,483.6c2.3,0.1,4.5-0.2,6.2-0.7c1.7-0.5,2.9-1.2,2.9-2.1c0-0.9-1.2-1.7-3.1-2.1c-1.9-0.4-4.4-0.3-6.8,0.6
				c-2.4,0.9-4,1.7-5.6,2.5c-1.7,0.9-3.3,1.8-5.3,2.9c2.4-0.7,4.2-1,5.9-1.1C373.5,483.5,375.1,483.5,377.5,483.6z"
                />
              </g>
              <g>
                <path
                  d="M400.9,489.4c2.5,0.8,5,1.3,7,1.3c2,0.1,3.5-0.3,3.7-1.1c0.3-0.8-0.8-2-2.7-2.9c-1.9-0.9-4.6-1.6-7.4-1.6
				c-2.9,0-4.8,0.3-6.8,0.5c-2.1,0.2-4.1,0.5-6.7,0.8c2.8,0.2,4.7,0.6,6.6,1.1C396.6,487.9,398.4,488.5,400.9,489.4z"
                />
              </g>
              <g>
                <path
                  d="M404,480.2c5.8,1.9,12.1,1.7,12.4,0c0.3-1.7-5.5-4.1-11.6-4.3c-6.3-0.2-8.7,0.1-15.2-0.5
				C395.8,477.1,398,478.2,404,480.2z"
                />
              </g>
              <g>
                <path
                  d="M405.2,471.3c1.9,0,3.8-0.8,4.9-1.8c1.1-1,1.6-2.1,1.2-2.9c-0.4-0.8-1.5-1.1-2.8-1c-1.3,0.1-2.9,0.5-4.3,1.4
				c-1.5,0.9-2.6,1.5-3.8,2c-1.2,0.5-2.4,0.9-4.3,1.2c1.7,0.3,3,0.5,4.5,0.7C401.8,471.2,403.2,471.4,405.2,471.3z"
                />
              </g>
              <g>
                <path
                  d="M326.4,598.7c3.6-0.2,7.2-0.8,10.9-1.8c3.6-1,7.3-2.7,10.5-5.2c1.6-1.3,3.1-2.8,4.3-4.4c1.2-1.7,2.2-3.5,3-5.4
				c1.5-3.8,2.1-7.8,1.7-11.8c-0.2-2-0.7-3.9-1.4-5.8c-0.7-1.9-1.7-3.6-2.8-5.1c-2.2-3.1-5.1-5.5-8.2-7.3c-1.6-0.8-3.2-1.5-4.9-2
				c-1.7-0.5-3.4-0.7-5.1-0.8c-3.4-0.1-6.7,0.5-9.7,1.8c-2.9,1.4-5.5,3.4-7.2,6.1c-1.7,2.6-2.4,5.5-2.4,8.1c0,2.7,0.8,5.3,2.2,7.2
				c0.7,1,1.5,1.8,2.4,2.5c0.9,0.6,1.8,1.1,2.7,1.4c3.6,1.3,6.6,0.5,8.1-0.6c1.6-1.1,1.9-2.1,1.6-2.4c-0.3-0.4-1.2-0.1-2.5,0
				c-0.7,0-1.5,0-2.4-0.2c-0.9-0.2-1.9-0.6-2.8-1.2c-0.9-0.6-1.7-1.5-2.1-2.5c-0.5-1.1-0.6-2.4-0.4-3.8c0.2-1.4,0.8-2.9,1.8-4.1
				c0.9-1.1,2.4-2.1,4.1-2.7c1.7-0.6,3.8-0.8,5.8-0.6c1,0.1,2,0.3,3,0.7c1,0.4,2,0.8,2.9,1.4c1.9,1.2,3.6,2.7,4.9,4.6
				c1.3,1.9,2,4,2.3,6.3c0.2,2.3-0.1,4.8-1,7.1c-0.4,1.1-1,2.2-1.7,3.2c-0.7,1-1.5,1.8-2.5,2.7c-3.9,3.3-9.9,5-16.1,5.8
				c-6.3,0.8-12.3,0.4-17.8-0.8c-5.5-1.2-10.3-3.2-14.8-5.6c-9-4.9-16.6-10.8-25.2-15.5c-8.6-4.7-18.1-7.9-29-9.2
				c-5.4-0.6-11.2-0.8-17.4-0.2c-6.1,0.6-12.7,2-19.4,4.5c6.8-2.2,13.3-3.3,19.4-3.6c6.1-0.3,11.8,0.2,17.1,1.1
				c10.5,1.9,19.4,5.7,27.3,10.8c3.9,2.6,7.7,5.5,11.5,8.5c3.9,3,7.9,6.1,12.5,9.1c4.6,3,9.9,5.7,16,7.5
				C312.1,598.2,319,599.2,326.4,598.7z"
                />
              </g>
              <g>
                <path
                  d="M279.9,561.6c4.2-1.3,8.3-3.6,11.6-6.8c1.7-1.6,3.1-3.5,4.3-5.6c1.2-2.1,2-4.4,2.3-7c0.7-5.2-1.5-10.8-6.2-13.8
				c-2.4-1.4-4.9-1.9-7.1-1.8c-2.2,0.1-4.1,0.5-6,1.3c-1.8,0.8-3.5,2-4.9,3.6c-1.3,1.6-2.1,3.7-2.1,5.7c0,3.9,2.4,6.6,4.7,7.6
				c2.3,1,4.2,0.5,5.3-0.1c1.1-0.6,1.3-1.3,1.2-1.6c-0.2-0.4-0.8-0.6-1.5-0.9c-0.7-0.3-1.7-0.8-2.4-1.6c-0.3-0.4-0.5-0.8-0.6-1.4
				c-0.1-0.5-0.1-1.1,0.1-1.5c0.2-0.4,0.5-0.7,1-1.1c0.5-0.3,1.2-0.7,2.1-0.9c0.9-0.2,2-0.3,2.9-0.2c1,0.1,1.7,0.4,2.2,0.8
				c0.5,0.4,0.9,1,1.2,1.8c0.2,0.4,0.3,0.9,0.3,1.3c0,0.4,0,0.9,0,1.5c-0.3,2.1-1.7,4.8-3.7,7c-2,2.2-4.7,4.1-7.8,5.4
				c-3.1,1.4-6.4,2.2-9.5,2.6c-3.2,0.4-6.2,0.5-9.2,0.4c-5.9-0.1-11.4-0.8-17-1.1c-5.6-0.3-11.4-0.3-17.6,0.8
				c-6.2,1.1-13,3.1-20.3,7c7.5-3.4,14.4-4.8,20.5-5.3c6.2-0.4,11.6,0.2,17,1.3c5.4,1,10.7,2.4,16.9,3.4c3.1,0.5,6.5,0.9,10.2,0.9
				C271.4,563.3,275.5,563,279.9,561.6z"
                />
              </g>
              <g>
                <path
                  d="M303.9,501.7c1.1-5.3,2.3-10.4,3.1-14.4c0.8-4.1,1.2-7.3-0.3-8.3c-1.4-1-4.6,0.8-7.4,4.9c-1.4,2.1-2.6,4.7-3.4,7.7
				c-0.8,3-1.1,6.3-0.8,9.6c0.3,3.4,0.9,6.2,1.7,8.6c0.7,2.4,1.6,4.5,2.6,6.5c1,2,2,4,3.3,6.1c1.3,2.1,2.8,4.3,4.9,6.8
				c-1.6-2.8-2.6-5.2-3.3-7.5c-0.7-2.3-1.1-4.3-1.3-6.4c-0.2-2.1-0.2-4.1-0.1-6.3C303,506.9,303.3,504.5,303.9,501.7z"
                />
              </g>
              <g>
                <path
                  d="M320.9,499.7c0.3-3.4-0.1-6.9-1.1-10c-0.9-3.1-2.4-5.8-4.1-7.9c-1.6-2.1-3.4-3.4-4.9-4.1c-1.5-0.7-2.6-0.7-3.3-0.1
				c-1.3,1.3-0.3,4.3,0.8,8.2c0.6,2,1.2,4.2,1.9,6.6c0.6,2.4,1.3,5,1.8,7.7c0.6,2.8,0.8,5.2,0.9,7.5c0.1,2.3,0,4.4-0.3,6.5
				c-0.5,4.2-1.6,8.5-4.5,14.4c3.8-5.3,6.1-9.2,8.1-13.3c1-2.1,1.9-4.2,2.7-6.7C319.9,506,320.6,503.2,320.9,499.7z"
                />
              </g>
              <g>
                <path
                  d="M312.1,497.4c0.7-3.6,0.6-7.3,0-10.1c-0.6-2.7-1.6-4.4-2.5-4.3c-0.9,0.1-1.4,1.9-1.7,4.6c-0.3,2.6-0.4,6-0.2,9.5
				c0.2,3.6,0.2,6.1,0.2,8.6c-0.1,2.5-0.3,5.1-1.2,8.8c1.3-3.6,2.2-6,3-8.5C310.5,503.6,311.3,501.1,312.1,497.4z"
                />
              </g>
              <g>
                <path
                  d="M310,493.7c0.9-3.1,1-6.3,0.6-8.7c-0.4-2.4-1.2-4-2.1-3.9c-0.9,0-1.6,1.6-2.1,3.8c-0.5,2.3-0.8,5.2-0.8,8.3
				c0,3.1,0,5.3-0.2,7.5c-0.2,2.2-0.4,4.4-1.2,7.6c1.2-2.9,2.1-5,3.1-7.2C308.2,499,309,496.9,310,493.7z"
                />
              </g>
              <g>
                <path
                  d="M302.6,488.7c1.5-2.6,2.9-5.1,3.9-7.2c1-2,1.6-3.6,1-4.3c-0.6-0.6-2.4,0-4.3,1.7c-1.9,1.8-3.9,4.8-4.7,8.1
				c-0.8,3.5-0.9,5.9-0.8,8.3c0.1,2.4,0.3,4.7,1,8.1c-0.2-3.4,0.2-5.7,0.8-7.9C300.3,493.4,301.1,491.4,302.6,488.7z"
                />
              </g>
              <g>
                <path
                  d="M305.8,559.5c1.5-1.7,2.7-3.7,3.5-5.7c0.8-2,1.3-4,1.4-5.7c0.3-3.4-0.5-5.7-1.4-5.7c-0.9,0-1.6,2-2.7,4.8
				c-1.1,2.8-2.5,6.3-4.4,9.7c-2,3.5-3.9,5.6-6.1,7.6c-2.2,1.9-4.8,3.6-9.1,4.9c4.4-0.8,7.4-1.9,10.1-3.3c1.4-0.7,2.7-1.5,4.2-2.5
				C302.7,562.6,304.2,561.3,305.8,559.5z"
                />
              </g>
              <g>
                <path
                  d="M341,541.1c4.9-0.6,9.7-1.1,13.4-1.5c3.7-0.4,6.3-0.9,6.4-1.8c0.1-0.8-2.4-2-6.4-2.4c-3.9-0.5-9.3-0.1-14.4,1.4
				c-5.2,1.6-8.5,3.3-11.7,5c-3.2,1.8-6.4,3.7-10.8,6.9c4.6-2.8,8.1-4.1,11.5-5.2C332.6,542.5,336,541.7,341,541.1z"
                />
              </g>
              <g>
                <path
                  d="M361.1,546c3.3,0.2,6.6,0,9.1-0.3c2.5-0.4,4.3-1,4.3-1.9c0.1-0.9-1.6-1.8-4.2-2.3c-2.6-0.5-6.1-0.6-9.6,0.1
				c-3.5,0.7-5.9,1.5-8.2,2.3c-2.5,0.8-4.8,1.6-8.1,2.8c3.6-0.7,6-0.8,8.5-0.9C355.2,545.7,357.6,545.8,361.1,546z"
                />
              </g>
              <g>
                <path
                  d="M368.5,552.6c2.6,0.8,5.2,1.2,7.2,1.2c2,0,3.6-0.3,3.8-1.2c0.2-0.8-0.9-2-2.8-2.9c-2-0.9-4.8-1.6-7.6-1.5
				c-2.9,0.1-4.9,0.4-6.9,0.7c-2.1,0.3-4.2,0.7-6.9,1.1c2.9,0.1,4.9,0.4,6.8,0.8C364,551.3,365.9,551.8,368.5,552.6z"
                />
              </g>
              <g>
                <path
                  d="M371.3,563.5c1.5,1.5,2.9,2.8,4,3.8c1.2,1,2.2,1.7,3.1,1.5c0.8-0.3,1.2-1.8,0.4-3.8c-0.7-2-2.7-4.1-5.1-5.1
				c-2.5-1.1-4.2-1.3-6-1.6c-1.7-0.3-3.4-0.5-5.9-1c2.3,1,3.8,1.8,5.2,2.7C368.4,561,369.8,562,371.3,563.5z"
                />
              </g>
              <g>
                <path
                  d="M370.1,580.9c0.2,2.4,0.6,4.7,1.1,6.5c0.5,1.8,1.1,3.1,2,3.2c0.9,0.1,1.8-1.2,2.2-3.3c0.4-2.1,0.2-5-1-7.6
				c-1.2-2.6-2.4-4.2-3.6-5.8c-1.2-1.5-2.5-3-4.4-5c1.5,2.3,2.2,4.1,2.7,5.8C369.5,576.6,369.8,578.4,370.1,580.9z"
                />
              </g>
              <g>
                <path
                  d="M364.6,598.8c-0.5,1.7-0.6,3.5-0.3,4.8c0.2,1.4,0.8,2.4,1.7,2.6c0.9,0.2,1.8-0.6,2.5-2c0.6-1.4,1-3.4,0.6-5.3
				c-0.4-2-0.9-3.3-1.3-4.7c-0.5-1.3-0.9-2.6-1.5-4.5c0.1,2-0.1,3.3-0.4,4.7C365.5,595.7,365.1,597,364.6,598.8z"
                />
              </g>
              <g>
                <path
                  d="M353.3,601c-3.3,3.3-5.1,7.7-3.7,8.8c1.4,1.1,5.4-1.9,7.3-6.4c1.9-4.6,2.2-6.5,4.2-11.1
				C358.2,596.4,356.7,597.5,353.3,601z"
                />
              </g>
              <g>
                <path
                  d="M340.2,602.7c-2.6,1.1-5,2.5-6.7,3.8c-1.7,1.3-2.7,2.6-2.3,3.4c0.4,0.8,2,0.9,4.2,0.3c2.2-0.6,4.8-1.9,7.1-3.8
				c2.3-2,3.8-3.5,5.2-5c1.5-1.5,2.9-3,5.1-5.1c-2.5,1.7-4.4,2.7-6.2,3.6C344.8,600.8,342.9,601.6,340.2,602.7z"
                />
              </g>
              <g>
                <path
                  d="M316.4,606.9c-2.8,0-5.6,0.4-7.6,1c-2.1,0.6-3.5,1.4-3.5,2.2c0,0.9,1.5,1.6,3.7,1.9c2.2,0.3,5.2,0.1,8.1-0.9
				c2.9-1,4.8-1.9,6.7-2.8c2-1,4-1.9,6.6-3.1c-3,0.8-5,1.1-7.1,1.3C321.3,606.8,319.3,606.9,316.4,606.9z"
                />
              </g>
              <g>
                <path
                  d="M233,569.1c5.4,0.9,10.6,2.2,14.6,3.2c4,1,6.8,1.7,7.2,0.8c0.4-0.7-1.8-2.8-5.8-4.8c-4-1.9-9.8-3.5-15.7-3.6
				c-6,0-10.1,0.7-14.1,1.6c-4,0.9-8,2.1-13.5,4.5c5.7-1.9,9.8-2.4,13.7-2.6C223.5,568,227.4,568.2,233,569.1z"
                />
              </g>
              <g>
                <path
                  d="M251.9,584.4c1.8,1.9,3.6,3.7,5.1,5.1c1.5,1.3,2.8,2.2,3.6,1.8c0.8-0.4,0.8-2.1-0.2-4.2c-1-2.1-3.1-4.6-5.8-6.1
				c-2.8-1.5-4.8-2.2-6.9-2.7c-2.1-0.5-4.1-0.9-7.1-1.1c2.9,0.8,4.7,1.8,6.4,2.9C248.6,581.1,250.1,582.4,251.9,584.4z"
                />
              </g>
              <g>
                <path
                  d="M68,396c1.6-0.2,3.2-0.3,4.9-0.5c1.6-0.1,3.3-0.2,4.8-0.2c3.1,0,6.1,0.2,8.8,0.7c2.7,0.5,5.1,1.4,6.9,2.6
				c0.9,0.6,1.7,1.2,2.3,1.9c0.3,0.3,0.6,0.7,0.9,1.1c0.2,0.4,0.4,0.7,0.6,1.1c0.7,1.6,1.1,3.6,0.9,5.5c-0.1,1-0.3,1.9-0.6,2.8
				c-0.3,0.9-0.8,1.8-1.3,2.6c-1.1,1.7-2.5,3.2-4.2,4.4c-1.7,1.2-3.4,2.1-5.2,2.7c-1.8,0.6-3.7,0.8-5.3,0.7c-1.7-0.1-3.2-0.4-4.5-1
				c-2.5-1-4.3-3.3-5.3-5.4c-1-2.1-1.2-4.1-1.3-5.6c0-0.7-0.1-1.4-0.1-1.9c-0.1-0.5-0.2-0.9-0.5-1.1c-0.3-0.2-0.7-0.2-1.2,0
				c-0.5,0.2-1.1,0.7-1.8,1.5c-0.6,0.8-1.3,1.9-1.7,3.4c-0.4,1.4-0.7,3.2-0.5,5.1c0.1,1.9,0.7,4.1,1.7,6.3c0.3,0.5,0.6,1.1,0.9,1.6
				c0.4,0.5,0.7,1.1,1.2,1.6c0.5,0.5,0.9,1.1,1.4,1.5c0.3,0.2,0.5,0.5,0.8,0.7c0.3,0.2,0.6,0.4,0.8,0.7c2.3,1.8,5.2,3,8.2,3.7
				c3.1,0.7,6.4,0.7,9.7,0.2c3.3-0.6,6.8-1.8,9.9-3.6c3.1-1.9,6.1-4.4,8.5-7.6c1.2-1.6,2.3-3.4,3.1-5.4c0.8-2,1.4-4.1,1.7-6.3
				c0.6-4.4,0.1-9-1.8-13.5c-0.5-1.1-1.1-2.2-1.7-3.2c-0.7-1-1.4-2-2.2-2.9c-0.8-0.9-1.7-1.7-2.6-2.5c-0.5-0.4-0.9-0.7-1.4-1.1
				c-0.5-0.4-1-0.7-1.5-1c-4-2.5-8.2-3.7-12.3-4.3c-4.1-0.6-8.1-0.7-12-0.3c-1.9,0.2-3.8,0.4-5.6,0.7c-1.9,0.3-3.7,0.6-5.5,1
				c-7.6,1.7-14.2,4-20.2,6.7c-6,2.7-11.3,5.7-16.1,8.9c-4.8,3.2-9.1,6.5-13,10.1c-3.9,3.5-7.6,7.2-10.9,11
				c-6.7,7.7-12.6,16.1-18,26.2c-5.4,10-10.4,21.7-14.4,36.1c4.8-14.2,10.6-25.4,16.7-34.7c6.1-9.4,12.7-16.9,19.9-23.6
				c3.6-3.3,7.4-6.3,11.4-9.2c4-2.9,8.4-5.5,13.1-7.9c4.7-2.4,9.8-4.6,15.4-6.4C55.2,398.3,61.3,396.8,68,396z"
                />
              </g>
              <g>
                <path
                  d="M53,455.6c0.5,1.9,0.5,3.8,0.3,5.6c-0.3,1.9-0.8,3.7-1.7,5.4c-0.9,1.7-2,3.1-3.3,4.3c-0.6,0.6-1.3,1.1-2,1.6
				c-0.7,0.5-1.5,0.9-2.3,1.2c-3.2,1.4-6.9,1.6-10,0.9c-1.6-0.3-2.9-0.9-3.9-1.6c-1-0.7-1.6-1.3-2.1-2.1c-0.2-0.4-0.4-0.8-0.5-1.1
				c-0.1-0.3-0.2-0.6-0.1-0.9c0-0.6,0.3-1.5,1-2.3c0.7-0.9,1.6-1.7,2.6-2.4c0.5-0.3,1-0.7,1.5-1c0.5-0.3,0.9-0.5,1.3-0.8
				c0.5-0.3,1-0.6,1.4-0.9c0.2-0.2,0.7-0.2,1.1-0.2c0.4,0.1,0.7,0.2,1,0.4c0.3,0.2,0.5,0.5,0.8,0.6c0.3,0.1,0.7,0.1,1.1-0.4
				c0.4-0.4,0.7-1.4,0.4-2.5c-0.2-1.1-0.9-2.3-2.5-3.3c-1.5-0.9-3.3-1.1-5-1c-0.8,0.1-1.6,0.2-2.4,0.3c-0.8,0.1-1.6,0.3-2.5,0.6
				c-1.7,0.5-3.6,1.3-5.5,2.6c-1.9,1.3-4,3.4-5.2,6.4c-0.6,1.5-1,3.2-1.1,4.8c-0.1,1.7,0.1,3.3,0.6,5c0.5,1.6,1.2,3.2,2.1,4.6
				c0.9,1.5,2.1,2.7,3.3,3.8c2.5,2.2,5.4,3.8,8.4,4.8c6.1,2,13.1,1.9,19.6-0.7c3.2-1.3,6.4-3.2,9-5.8c2.6-2.5,4.7-5.6,6.2-8.9
				c1.5-3.3,2.4-6.9,2.5-10.6c0.2-3.7-0.5-7.5-1.8-10.8c-2.7-7-7.7-12-13.2-14.9c-5.6-2.9-11.3-3.4-16.2-3.1
				c-4.9,0.3-9.3,1.4-13.4,2.8c-4.1,1.3-7.9,3-11.5,4.9c-3.7,1.9-7.1,4.1-10.6,6.6c-3.4,2.5-6.7,5.3-9.9,8.6
				c-3.2,3.3-6.3,6.9-9.3,11.1c-2.9,4.2-5.7,9-8,14.5c2.6-5.3,5.7-9.8,9-13.7c3.2-3.9,6.6-7.1,10-9.9c3.4-2.8,6.9-5.1,10.4-7
				c3.5-1.9,7-3.5,10.6-4.8c3.6-1.2,7.3-2.3,11.1-2.9c3.8-0.7,7.7-1.1,11.5-0.8c3.8,0.3,7.6,1.4,10.5,3.5
				C49.6,448.2,52,451.7,53,455.6z"
                />
              </g>
              <g>
                <path
                  d="M121.8,361.1c10.9,1.6,21.5,3.9,29.6,5.9c0.5,0.1,1,0.2,1.5,0.3c0.5,0.1,1,0.2,1.4,0.4c0.9,0.2,1.8,0.4,2.6,0.6
				c0.8,0.2,1.6,0.3,2.4,0.5c0.7,0.2,1.4,0.3,2,0.4c2.5,0.4,4.2,0.3,4.6-0.5c0.4-0.8-0.5-2.1-2.5-3.9c-0.5-0.4-1.1-0.9-1.7-1.4
				c-0.6-0.5-1.4-0.9-2.1-1.4c-0.8-0.5-1.6-1-2.5-1.5c-0.5-0.2-0.9-0.5-1.4-0.8c-0.5-0.2-1-0.5-1.5-0.7c-8.1-3.8-19.9-6.9-31.8-6.7
				c-3.1,0.1-5.9,0.1-8.5,0.4c-1.3,0.1-2.6,0.2-3.8,0.4c-1.2,0.2-2.4,0.4-3.5,0.5c-1.1,0.2-2.3,0.3-3.3,0.5
				c-1.1,0.2-2.1,0.4-3.2,0.7c-1,0.2-2.1,0.4-3.1,0.7c-1,0.3-2,0.5-3,0.8c-1,0.3-2,0.5-3,0.8c-1,0.3-2,0.6-3,1c-1,0.3-2,0.6-3.1,1
				c-1,0.4-2.1,0.8-3.2,1.2c-1.1,0.4-2.2,0.8-3.3,1.3c-1.1,0.5-2.3,1-3.5,1.5c-0.6,0.3-1.2,0.5-1.8,0.8c-0.6,0.3-1.2,0.6-1.9,0.9
				c-1.3,0.6-2.6,1.2-4,1.9c1.4-0.5,2.8-1,4.1-1.5c0.7-0.2,1.3-0.5,1.9-0.7c0.6-0.2,1.3-0.4,1.9-0.6c1.2-0.4,2.4-0.7,3.6-1
				c1.2-0.3,2.3-0.5,3.4-0.8c1.1-0.2,2.2-0.5,3.3-0.7c1.1-0.2,2.1-0.3,3.2-0.5c1-0.1,2-0.3,3-0.4c1-0.1,2-0.2,3-0.3
				c1-0.1,2-0.2,3-0.2c1-0.1,2-0.1,3-0.1c1,0,2.1,0,3.1-0.1c1.1,0,2.1,0.1,3.2,0.1c1.1,0,2.2,0.1,3.4,0.1c1.2,0.1,2.3,0.2,3.6,0.3
				C116.4,360.4,119,360.8,121.8,361.1z"
                />
              </g>
              <g>
                <path
                  d="M120.9,378.3c10.8,0.9,21.8-0.5,29.7-2.7c7.9-2.2,12.8-5.1,12.3-6.8c-0.4-1.8-5.9-1.9-13.7-1.5
				c-7.8,0.4-18.1,1.3-28.4,2.2c-10.6,0.9-18,1.1-25.5,0.9c-7.5-0.3-15.1-1-26.1-3.4c10.7,3.5,18.2,5.4,25.6,7.2
				C102.3,375.9,109.8,377.4,120.9,378.3z"
                />
              </g>
              <g>
                <path
                  d="M132.4,371.1c6.3,1.3,12.7,1.4,17.5,0.7c4.8-0.7,8-2.3,8-4c0-1.8-3.1-3.3-7.8-4.4c-4.7-1-10.9-1.5-17.2-1
				c-6.5,0.5-10.9,0.9-15.5,1.2c-4.5,0.3-9.1,0.4-15.8,0c6.6,1.4,11,2.6,15.4,3.8C121.5,368.5,125.9,369.7,132.4,371.1z"
                />
              </g>
              <g>
                <path
                  d="M166.3,420.2c1,0,2.1,0,3.1-0.2c1-0.2,2.1-0.4,3.1-0.7c2-0.7,4-1.7,5.8-3.3c1.8-1.5,3.3-3.7,4-6.1
				c0.7-2.4,0.7-4.8,0.3-6.9c-0.4-2.1-1.2-4-2.1-5.7c-0.9-1.7-2-3.1-3.3-4.5c-1.3-1.3-2.8-2.4-4.5-3.3c-1.6-0.8-3.6-1.3-5.3-1.4
				c-3.5-0.2-6.9,1.1-9,3.3c-1.1,1.1-1.8,2.4-2.2,3.6c-0.4,1.2-0.5,2.3-0.4,3.3c0.2,2,1.1,3.4,1.9,4c0.8,0.7,1.5,0.7,1.8,0.4
				c0.3-0.3,0.4-0.9,0.5-1.6c0.1-0.7,0.2-1.6,0.7-2.4c0.4-0.9,1.2-1.8,2.1-2.1c0.9-0.4,2.3-0.6,3.6-0.2c1.3,0.4,2.6,1.3,3.9,2.8
				c1.2,1.5,2.3,3.6,2.6,5.5c0.2,0.9,0.1,1.7-0.1,2.3c-0.2,0.6-0.5,1.2-1.1,1.8c-0.6,0.6-1.5,1.2-2.5,1.7c-0.5,0.2-1.1,0.4-1.6,0.6
				c-0.6,0.2-1.1,0.3-1.8,0.4c-2.6,0.3-5.2,0-7.6-0.7c-2.4-0.7-4.7-1.7-6.8-2.9c-4.1-2.4-7.6-5.4-11-8.5c-3.5-3.1-6.9-6.5-11.2-9.7
				c-2.2-1.6-4.5-3.1-7.3-4.5c-2.7-1.3-5.9-2.4-9.3-2.6c3.5,0.5,6.4,1.9,8.9,3.4c2.5,1.5,4.7,3.3,6.5,5.1c3.8,3.6,6.6,7.3,9.5,11
				c3,3.7,6,7.4,10.3,11c2.2,1.8,4.6,3.4,7.6,4.8C158.6,419,162.2,420.1,166.3,420.2z"
                />
              </g>
              <g>
                <path
                  d="M130.6,425.7c-0.7,1.3-1.4,2.2-2.3,3c-0.9,0.7-1.7,1.2-2.5,1.4c-0.6,0.2-1.4,0.2-2.1,0c-0.7-0.2-1.2-0.4-1.5-0.7
				c-0.3-0.1-0.6-0.7-0.7-1.3c-0.1-0.6-0.1-1.3,0-1.9c0.1-0.6,0.3-1.1,0.4-1.6c0.2-0.5,0.3-1,0.2-1.4c-0.1-0.4-0.4-0.8-1.1-1
				c-0.7-0.2-1.8-0.1-2.9,0.5c-1.2,0.6-2.4,1.7-3.2,3.4c-0.8,1.6-1.3,4-0.4,6.6c0.8,2.4,2.7,4.6,5.1,5.9c2.4,1.3,5.3,1.8,8.3,1.2
				c2.9-0.6,5.4-2.2,7.2-4.2c1.8-2,3.1-4.6,3.7-7c0.6-2.5,0.6-4.8,0.4-6.8c-0.2-2-0.6-3.8-1-5.4c-0.9-3.3-2.2-6.1-3.7-8.6
				c-1.5-2.6-3.3-4.9-5.6-7.3c-1.1-1.2-2.4-2.3-3.8-3.5c-1.4-1.1-3.1-2.2-4.9-3.2c1.7,1.2,3.1,2.6,4.3,3.8c1.2,1.3,2.2,2.6,3,3.9
				c1.7,2.6,2.7,5.1,3.5,7.7c0.7,2.6,1.1,5.2,1.1,8C132.2,419.9,131.9,423.1,130.6,425.7z"
                />
              </g>
              <g>
                <path
                  d="M612.6,399.5c-0.7-3.6-1.9-7.1-3.5-10.4c-1.6-3.3-3.6-6.4-5.8-9.1c-2.2-2.7-4.6-4.8-6.8-6.8c-2.2-1.9-4.2-3.6-5.9-5.2
				c-3.5-3.1-6-6-7.6-8.2c-1.7-2.2-2.6-3.7-3.5-3.5c-0.8,0.1-1.5,2-0.9,5.4c0.3,1.6,0.9,3.6,1.9,5.6c1,2,2.4,4.2,4,6.3
				c3.3,4.3,7.7,8.3,11.3,12.7c3.5,4.3,6.4,9.6,8.4,15.4c1,3,1.7,5.9,2.1,8.6c0.4,2.7,0.7,5.4,0.7,7.9c0,5-0.5,9.6-1.6,14.1
				c-1.1,4.5-2.6,9-4.9,13.8c-2.3,4.8-5.4,9.9-9.8,15.6c4.8-5.3,8.4-10.1,11.3-14.7c2.9-4.6,5.2-9,7-13.5c1.8-4.6,3.3-9.3,4.1-14.8
				c0.4-2.8,0.6-5.7,0.6-8.9C613.7,406.6,613.4,403.2,612.6,399.5z"
                />
              </g>
              <g>
                <path
                  d="M580.2,404.8c-0.8-5.7-1-11.4-0.9-16.8c0.1-5.4,0.4-10.6,0.8-15c0.7-8.9,0.9-15.2-0.8-15.7c-1.7-0.4-4.9,5.1-7.5,14.3
				c-1.2,4.6-2.2,10.1-2.4,16.2c-0.2,6,0.4,12.6,2.2,18.9c1.9,6.5,4.3,11.6,7.1,15.9c1.4,2.2,2.8,4.1,4.3,5.9
				c1.4,1.8,2.9,3.4,4.2,5.1c2.6,3.3,5,6.9,5.9,11.6c0.2,1.2,0.4,2.4,0.4,3.7c0,1.3,0,2.6-0.2,4c-0.4,2.7-1.3,5.6-3,8.4
				c1.9-2.7,3.1-5.5,3.8-8.2c0.4-1.4,0.6-2.7,0.7-4.1c0.1-1.3,0.1-2.6,0.1-3.9c-0.1-2.5-0.7-5-1.5-7.2c-0.8-2.2-1.8-4.3-2.9-6.2
				c-2.2-3.9-4.5-7.5-6.2-11.7C582.4,415.5,581,410.7,580.2,404.8z"
                />
              </g>
              <g>
                <path
                  d="M593.6,391.6c-1-7.1-3.4-13.9-5.9-18.7c-2.5-4.8-4.9-7.5-5.7-7.1c-0.8,0.4,0.2,3.8,1.8,8.7c1.6,4.9,3.7,11.4,5.5,17.9
				c1.8,6.8,2.7,11.6,3.3,16.5c0.5,4.9,0.8,9.9,0.1,17.3c1.2-7.3,1.6-12.4,1.8-17.4C594.6,403.9,594.6,398.9,593.6,391.6z"
                />
              </g>
              <g>
                <path
                  d="M588.3,385.7c-0.4-5.3-1.8-10.5-3.4-14.1c-1.6-3.7-3.4-5.8-4.2-5.5c-0.8,0.3-0.6,3,0.2,6.7c0.7,3.8,1.9,8.6,3.1,13.5
				c1.2,5.1,1.9,8.6,2.3,12.2c0.4,3.6,0.7,7.4,0.3,12.8c0.9-5.4,1.3-9.2,1.6-12.9C588.5,394.8,588.7,391.1,588.3,385.7z"
                />
              </g>
              <g>
                <path
                  d="M583.8,385.6c0.2-8.5-1.7-16.9-3.5-16.8c-1.8,0.1-2.4,8.7-0.9,17.1c1.6,8.6,2.6,11.8,3.8,20.8
				C583,397.7,583.5,394.4,583.8,385.6z"
                />
              </g>
              <g>
                <path
                  d="M38.3,418.2c3.6-0.5,7.1-1.2,9.7-2c2.6-0.8,4.4-1.7,4.3-2.5c-0.1-0.9-2-1.5-4.9-1.6c-2.9-0.1-6.7,0.5-10.3,1.9
				c-3.7,1.5-6,2.8-8.4,4.1c-2.3,1.3-4.7,2.7-8,4.8c3.6-1.6,6.2-2.4,8.7-3C32.1,419.3,34.6,418.8,38.3,418.2z"
                />
              </g>
              <g>
                <path
                  d="M37.7,425.6c3.2-0.1,6.2-0.5,8.6-1.1c2.3-0.5,4-1.2,4-2.1c0-0.9-1.6-1.7-4.2-2.1c-2.6-0.4-6-0.1-9.3,0.9
				c-3.3,1.1-5.5,2.1-7.6,3.2c-2.1,1.1-4.2,2.2-7.3,3.9c3.3-1.2,5.6-1.8,7.9-2.1C32.2,425.9,34.5,425.7,37.7,425.6z"
                />
              </g>
              <g>
                <path
                  d="M59.5,432.6c2.4,0.8,4.7,1.4,6.6,1.6c1.9,0.2,3.4,0.1,3.8-0.7c0.4-0.8-0.5-2.1-2.3-3.2c-1.8-1.1-4.5-2-7.3-2
				c-2.9,0-4.8,0.3-6.8,0.7c-1.9,0.4-3.8,0.8-6.5,1.5c2.8-0.2,4.7,0,6.5,0.4C55.3,431.3,57.1,431.8,59.5,432.6z"
                />
              </g>
              <g>
                <path
                  d="M80.6,446.8c4,0.5,8.1-0.5,10.7-1.9c2.7-1.4,4-3.1,3.6-3.8c-0.4-0.8-2.4-0.5-5-0.1c-2.6,0.4-5.9,1-9.4,1.5
				c-3.6,0.4-6.1,0.3-8.7,0c-2.5-0.4-5.2-1.1-8.6-3.1c3.1,2.4,5.6,3.7,8.1,4.9C73.9,445.3,76.5,446.2,80.6,446.8z"
                />
              </g>
              <g>
                <path
                  d="M84.1,455.5c2.2,0,4.3-0.8,5.6-1.8c1.3-1,1.9-2.2,1.5-3c-0.4-0.8-1.6-1-3.1-0.9c-1.5,0.1-3.2,0.6-4.9,1.4
				c-1.8,0.8-3.1,1.4-4.4,1.8c-1.4,0.4-2.8,0.8-4.9,0.8c2.1,0.5,3.5,0.8,5,1.1C80.4,455.3,81.9,455.5,84.1,455.5z"
                />
              </g>
              <g>
                <path
                  d="M-6.8,468.2c-3.8,3.9-6.3,9-7.3,13c-1,4-0.7,6.8,0.2,7c0.9,0.2,2.1-2.2,3.8-5.5c1.7-3.3,4-7.6,6.8-11.7
				c2.9-4.2,5.4-6.9,8.1-9.3c2.8-2.3,5.8-4.6,11-6.6c-5.3,1.5-8.8,3.2-12.1,5C0.3,462-2.9,464.1-6.8,468.2z"
                />
              </g>
              <g>
                <path
                  d="M-0.6,480.6c-1,1.9-1.8,3.9-2.4,5.8c-0.3,1-0.5,1.9-0.7,2.8c-0.2,0.9-0.3,1.9-0.3,2.8c0,0.9,0.1,1.8,0.4,2.6
				c0.3,0.8,0.7,1.5,1.2,1.9c0.5,0.4,1,0.6,1.4,0.6c0.4,0,0.6-0.1,0.7-0.3c0.2-0.4-0.1-1,0-1.6c0-0.6,0.1-1.5,0.5-2.7
				c0.2-0.6,0.4-1.2,0.7-2.1c0.3-0.8,0.6-1.7,0.8-2.5c0.6-1.8,1.1-3.7,1.7-5.6c1.1-4,2.1-6.7,3.2-9.3c1.2-2.7,2.5-5.3,5-8.9
				c-2.9,3.2-4.8,5.6-6.7,7.9C3.3,474.3,1.5,476.7-0.6,480.6z"
                />
              </g>
              <g>
                <path
                  d="M7.5,492.9c-0.1,2.4,0.6,4.8,1.6,6.4c1,1.6,2.2,2.3,3,2c0.8-0.4,1.1-1.7,1-3.3c-0.1-1.6-0.5-3.6-1.2-5.6
				c-0.7-2.1-1.2-3.6-1.5-5.1c-0.3-1.6-0.5-3.2-0.3-5.6c-0.7,2.3-1.2,3.9-1.6,5.5C8.1,488.7,7.7,490.4,7.5,492.9z"
                />
              </g>
              <g>
                <path
                  d="M19.5,498.4c0.8,2,2.3,3.6,3.7,4.4c1.4,0.8,2.7,0.9,3.3,0.2c0.6-0.7,0.3-1.9-0.3-3.1c-0.6-1.3-1.7-2.7-3-3.9
				c-1.4-1.3-2.4-2.3-3.2-3.3c-0.9-1.1-1.7-2.2-2.5-4.2c0.3,2.1,0.5,3.5,0.7,4.9C18.5,494.9,18.7,496.4,19.5,498.4z"
                />
              </g>
              <g>
                <path
                  d="M36.3,500.3c1.6,0.8,3.4,0.8,4.7,0.4c1.3-0.4,2-1.3,2-2.2c-0.1-0.9-0.9-1.6-1.9-2c-1.1-0.5-2.4-0.7-3.8-0.5
				c-1.5,0.2-2.6,0.2-3.7,0.2c-1.1,0-2.3-0.2-3.8-0.8c1.1,0.9,2.1,1.7,3.2,2.6C33.7,498.7,34.7,499.5,36.3,500.3z"
                />
              </g>
              <g>
                <path
                  d="M173.3,453.4c1.6-3.6,2.3-7.6,2.2-11.5c-0.1-3.9-1-7.8-2.9-11.2c-1-1.7-2.2-3.2-3.6-4.5c-1.5-1.3-3.2-2.3-5.1-2.8
				c-3.8-1.1-7.5-0.3-10.2,1.3c-2.8,1.6-4.7,4.3-5.4,6.7c-0.8,2.5-0.6,4.7-0.2,6.4c0.5,1.7,1.2,2.9,1.8,3.6c0.6,0.7,1.1,0.9,1.2,0.8
				c0.4-0.3-0.5-1.9-0.7-4.8c0-1.4,0.2-3.2,1-4.9c0.8-1.7,2.2-3.3,4.2-4.2c2-1,4.6-1.3,6.8-0.5c2.3,0.8,4.4,2.7,5.8,5.3
				c1.4,2.6,2.2,5.7,2.4,8.9c0.2,3.2-0.3,6.6-1.4,9.7c-1.2,3.2-3,6-4.8,8.5c-1.9,2.5-3.8,4.8-5.6,7.1c-0.9,1.2-1.7,2.3-2.5,3.5
				c-0.4,0.6-0.7,1.2-1.1,1.8l-0.5,0.9l-0.5,0.9c-0.3,0.6-0.6,1.2-0.9,1.8c-0.3,0.6-0.5,1.2-0.8,1.8c-0.6,1.2-0.9,2.5-1.4,3.7
				c-0.8,2.5-1.4,5.1-1.8,7.8c-0.2,1.3-0.3,2.7-0.4,4.1c0,0.7,0,1.4,0,2.1c0,0.7,0,1.4,0,2.2c0.1,2.9,0.5,6,1.3,9.3
				c0.8,3.2,2,6.6,3.8,10c-1.7-3.5-2.7-6.9-3.4-10.1c-0.6-3.2-0.9-6.3-0.8-9.2c0-0.7,0-1.4,0.1-2.1c0.1-0.7,0.1-1.4,0.2-2.1
				c0.2-1.4,0.3-2.7,0.6-4c0.5-2.6,1.3-5,2.2-7.4c1.9-4.7,4.5-9,8.3-13.1c1.9-2.1,4-4.2,6.1-6.7C169.4,460,171.7,457.1,173.3,453.4z
				"
                />
              </g>
              <g>
                <path
                  d="M154.7,445.2c-2.1,1.9-3.9,4-5.1,5.8c-1.2,1.8-1.8,3.3-1.2,4c0.6,0.7,2.2,0.3,4.2-0.9c1.9-1.2,4.1-3.4,5.6-6.1
				c0.8-1.4,1.3-2.5,1.8-3.6c0.5-1.1,0.9-2.1,1.2-3.2c0.3-1.1,0.5-2.2,0.4-3.5c-0.1-1.2-0.5-2.6-1.7-3.7c0.9,1.3,1,2.6,0.8,3.7
				c-0.2,1.1-0.7,2-1.2,2.7c-0.6,0.8-1.2,1.5-2,2.3C156.6,443.3,155.7,444.2,154.7,445.2z"
                />
              </g>
              <g>
                <path
                  d="M157.3,453.9c-1.7,1.7-3.1,3.6-4,5.2c-0.9,1.6-1.2,3-0.6,3.7c0.6,0.6,2.1,0.3,3.7-0.9c1.6-1.1,3.4-3.1,4.4-5.5
				c1.1-2.4,1.7-4.2,2.2-6c0.6-1.9,1.1-3.7,1.8-6c-1.2,2.3-2.3,3.8-3.5,5.1C160.3,450.9,159.1,452.2,157.3,453.9z"
                />
              </g>
              <g>
                <path
                  d="M187.3,434.6c0.8-2.6,0.7-5.4,0.1-7.4c-0.6-2-1.7-3.1-2.5-2.9c-0.9,0.2-1.4,1.5-1.6,3.3c-0.3,1.8-0.4,4-0.3,6.3
				c0.1,2.4,0,4.2-0.2,5.9c-0.3,1.7-0.7,3.5-1.9,5.9c1.6-2.1,2.7-3.7,3.6-5.2C185.5,438.9,186.5,437.3,187.3,434.6z"
                />
              </g>
              <g>
                <path
                  d="M196,440.1c1.4-0.9,2.5-2.1,3.2-3.2c0.7-1.1,1-2.2,0.6-3c-0.5-0.7-1.7-0.9-3.1-0.4c-1.4,0.5-3,1.8-4,3.6
				c-1,1.8-1.4,3.2-1.8,4.5c-0.4,1.3-0.7,2.6-1.2,4.5c1-1.7,1.9-2.6,2.8-3.5C193.5,441.8,194.5,441.1,196,440.1z"
                />
              </g>
              <g>
                <path
                  d="M204.1,446c1.7-0.2,3.1-0.8,4.2-1.5c1.1-0.7,1.8-1.6,1.7-2.5c-0.1-0.9-1.2-1.5-2.7-1.6c-1.5-0.1-3.4,0.4-5,1.6
				c-1.6,1.2-2.5,2.2-3.5,3.2c-1,1.1-1.9,2.2-3,3.4c1.6-1,2.8-1.5,4-1.8C201.1,446.4,202.3,446.2,204.1,446z"
                />
              </g>
              <g>
                <path
                  d="M255.9,442.9c-2.8,2-5.5,3.9-7.6,5.6c-2,1.6-3.4,3-3,3.8c0.3,0.8,2.4,0.8,5.1-0.2c2.7-1,6-3.1,8.4-6
				c2.5-3,3.9-5.3,5.2-7.6c1.2-2.3,2.4-4.7,3.8-8.3c-1.9,3.4-3.5,5.4-5.3,7.2C260.7,439.2,258.8,440.8,255.9,442.9z"
                />
              </g>
              <g>
                <path
                  d="M258,452.6c-2.4,3.6-3.9,7.7-4.5,10.9c-0.5,3.2-0.2,5.5,0.6,5.6c0.9,0.2,2-1.7,3.4-4.4c1.3-2.7,2.9-6.4,4.3-10.1
				c1.5-3.8,2.8-6.4,4.3-9c1.5-2.5,3.1-5,6.1-8.3c-3.4,2.9-5.5,5-7.6,7.1C262.6,446.7,260.5,448.9,258,452.6z"
                />
              </g>
              <g>
                <path
                  d="M264.1,459.8c-1.9,3.8-3,8-3.2,11.2c-0.3,3.2,0.2,5.4,1,5.5c0.9,0.1,1.9-1.8,3-4.6c1.1-2.9,2.3-6.7,3.3-10.6
				c1-4,1.9-6.8,2.9-9.6c1-2.8,2.2-5.5,4.4-9.3c-2.7,3.5-4.4,6-6.1,8.5C267.7,453.4,266.1,455.9,264.1,459.8z"
                />
              </g>
              <g>
                <path
                  d="M266.4,475.3c-1.1,3.9-1.3,8-0.8,11c0.4,3,1.4,5,2.2,4.9c0.9-0.1,1.5-2,2-4.9c0.5-2.8,0.8-6.6,1-10.3
				c0.2-3.9,0.5-6.6,0.9-9.3c0.5-2.7,1.1-5.5,2.6-9.4c-2,3.6-3.2,6.2-4.4,8.8C268.6,468.7,267.5,471.3,266.4,475.3z"
                />
              </g>
              <g>
                <path
                  d="M332.1,491.8c-2.3,0.4-4.4,1.4-5.7,2.6c-1.3,1.2-1.9,2.4-1.4,3.2c0.5,0.8,1.8,0.9,3.4,0.5c1.6-0.3,3.5-1.1,5.3-2.2
				c1.9-1.2,3.2-2,4.6-2.7c1.4-0.7,2.9-1.4,5.1-2c-2.1,0-3.8,0.1-5.6,0.1C336.1,491.3,334.5,491.4,332.1,491.8z"
                />
              </g>
              <g>
                <path
                  d="M338.2,497.8c-2,0.5-2.5,2.8-1.3,4.1c1.2,1.3,3.4,1.1,4.2-0.8c0.8-1.9,0.9-3,2-4.7C341.2,497.3,340.2,497.3,338.2,497.8z
				"
                />
              </g>
              <g>
                <path d="M432.9,506.6c-3.6,0.1-6.5,2.6-5.9,4.2c0.7,1.6,4.1,1.7,7,0c3-1.8,4.1-2.8,7.6-4C438.1,507,436.6,506.6,432.9,506.6z" />
              </g>
              <g>
                <path
                  d="M152,526.9c-2.4-3.5-5.6-6.8-9.9-9.1c-2.1-1.1-4.4-2-6.8-2.5c-2.4-0.5-5-0.6-7.6-0.1c-5.2,0.9-9.7,3.7-12.6,7.9
				c-0.7,1.1-1.3,2.2-1.8,3.4c-0.5,1.2-0.8,2.5-0.9,3.7c-0.3,2.4,0,4.7,0.7,6.8c0.7,2.1,1.8,4,3.3,5.5c1.5,1.5,3.3,2.6,5,3.2
				c3.6,1.1,6.6,0.4,8.6-0.7c2.1-1.1,3.2-2.7,3.5-3.9c0.2-0.6,0.2-1.1,0.1-1.5c-0.1-0.4-0.3-0.7-0.6-0.8c-0.3-0.2-0.6-0.3-1-0.3
				c-0.4,0-0.8,0-1.2-0.1c-0.8,0-1.8-0.1-2.7-0.4c-0.9-0.3-2-0.9-2.4-1.5c-0.2-0.3-0.3-0.7-0.4-1.1c-0.1-0.4-0.1-0.8-0.1-1.4
				c0-0.5,0.2-1,0.4-1.4c0.1-0.2,0.2-0.4,0.3-0.5c0.1-0.2,0.3-0.4,0.5-0.5c0.4-0.4,0.9-0.7,1.5-1c0.6-0.3,1.3-0.5,2-0.6
				c1.4-0.2,3.4,0.1,5.5,0.9c2,0.8,4.2,2.4,6.2,4.4c1.1,1,2.1,2.2,3,3.4c0.9,1.2,1.8,2.3,2.6,3.5c1.5,2.4,2.8,4.7,3.8,7.1
				c2,4.7,3.3,9.3,3.7,14.1c0.5,4.8,0.2,9.7-1.1,15.2c-1.3,5.4-3.8,11.3-8.4,17.4c5.2-5.5,8.6-11.1,10.9-16.5
				c2.3-5.4,3.7-10.6,4.3-15.9c0.6-5.3,0.5-10.8-0.6-16.8c-0.6-3-1.4-6.1-2.7-9.4c-0.7-1.6-1.4-3.3-2.3-5
				C154.2,530.4,153.2,528.7,152,526.9z"
                />
              </g>
              <g>
                <path
                  d="M180.5,567.9c3.9-1.9,7.3-4.1,9.8-6.3c2.5-2.1,4.1-4.2,3.6-5.9c-0.5-1.6-3.3-2.4-7.1-1.7c-3.7,0.7-8.4,3.2-11.7,7
				c-3.4,3.9-5.2,7-6.8,10c-1.6,3.1-3.1,6.2-4.9,10.7c2.8-4.1,5.3-6.3,7.8-8.3C173.8,571.6,176.5,569.9,180.5,567.9z"
                />
              </g>
              <g>
                <path
                  d="M174.5,542.4c0.4-3.8-1-7.4-2.8-9.6c-1.9-2.2-4.1-3-5.6-2.2c-1.6,0.9-2.2,2.9-2.2,5.3c0,2.4,0.6,5.1,1.9,7.8
				c1.3,2.9,2.2,5,2.8,7.3c0.6,2.3,1,4.7,0.6,8.2c1.4-3.3,2.3-5.6,3.2-8C173.2,548.8,174.1,546.3,174.5,542.4z"
                />
              </g>
              <g>
                <path
                  d="M59.8,573.1c-3.7,3.4-7.3,6.6-11.1,9.2c-3.7,2.5-7.5,4.5-10.6,4.9c-3.2,0.5-5.8-0.6-7.3-1.7c-1.6-1.1-2.1-2.3-2.6-2.2
				c-0.2,0.1-0.3,0.4-0.3,1.1c0.1,0.7,0.4,1.7,1.1,2.7c0.7,1.1,1.9,2.2,3.5,3c1.6,0.8,3.7,1.3,5.9,1.3c2.2-0.1,4.5-0.6,6.7-1.5
				c2.2-0.9,4.3-2.1,6.4-3.4c4.2-2.8,8-6.4,11.4-10.2c3.5-3.9,6.3-7.3,8.9-10.2c2.6-2.9,5.1-5.4,7.9-7.7c2.7-2.2,5.7-4.2,9.4-5.8
				c3.7-1.5,8.2-2.7,13.6-2c-5.3-1-10-0.1-13.9,1.2c-3.9,1.3-7.2,3.1-10.3,5.1c-3.1,2-5.9,4.3-8.9,7
				C66.7,566.4,63.5,569.6,59.8,573.1z"
                />
              </g>
              <g>
                <path
                  d="M73.8,568.7c-1.9,3-3.2,6.4-4,9.7c-0.8,3.3-0.9,6.4-0.8,9.1c0.4,5.4,2.1,8.6,2.9,8.4c0.9-0.2,0.8-3.6,1.3-8.4
				c0.3-2.4,0.7-5.1,1.4-8c0.7-2.9,1.7-5.9,3-8.8c1.4-3,2.8-5.4,4.4-7.4c1.6-2,3.2-3.7,4.9-5.3c1.8-1.5,3.7-3,6-4.3
				c2.3-1.3,5.1-2.5,8.6-3.4c-3.5,0.6-6.4,1.6-9,2.6c-2.5,1.1-4.7,2.2-6.8,3.5c-2.1,1.3-4,2.8-6.1,4.7
				C77.8,563.3,75.8,565.6,73.8,568.7z"
                />
              </g>
              <g>
                <path
                  d="M82.3,565.7c-1.2,2.3-2,4.8-2.4,7.2c-0.4,2.4-0.3,4.7,0,6.6c0.6,3.9,2.2,6.1,3,5.9c0.9-0.2,0.9-2.7,1.2-6
				c0.1-1.7,0.4-3.6,0.7-5.6c0.4-2,0.9-4.2,1.5-6.3c0.7-2.2,1.5-4,2.4-5.5c0.9-1.6,1.8-2.9,2.9-4.2c1.1-1.3,2.3-2.5,3.8-3.7
				c1.5-1.2,3.3-2.3,5.7-3.3c-2.4,0.8-4.4,1.7-6.1,2.6c-1.7,0.9-3.2,1.9-4.6,3c-1.4,1.1-2.7,2.3-4.1,3.8
				C84.9,561.6,83.5,563.4,82.3,565.7z"
                />
              </g>
              <g>
                <path
                  d="M126.8,555.4c3.6,0.7,7.5,0,9.9-1.3c2.5-1.3,3.7-2.8,3.3-3.6c-0.4-0.8-2.2-0.7-4.6-0.4c-2.4,0.3-5.3,0.6-8.5,0.9
				c-3.2,0.2-5.5,0-7.8-0.4c-2.3-0.5-4.6-1.3-7.6-3.4c2.6,2.5,4.7,3.9,6.9,5.1C120.8,553.5,123.1,554.6,126.8,555.4z"
                />
              </g>
              <g>
                <path
                  d="M138.6,563.1c1.7,1.4,3.4,2.4,4.9,3.1c1.5,0.7,2.8,0.9,3.4,0.3c0.6-0.6,0.4-2-0.8-3.5c-1.1-1.5-3.1-3.1-5.5-3.8
				c-2.4-0.7-4.2-0.9-5.9-1c-1.7-0.1-3.4-0.2-5.8-0.2c2.4,0.5,3.9,1.2,5.3,2C135.6,560.8,136.9,561.7,138.6,563.1z"
                />
              </g>
              <g>
                <path
                  d="M141,578.8c0.3,2.1,1,4.1,1.8,5.5c0.8,1.5,1.7,2.4,2.6,2.3c0.9-0.1,1.5-1.4,1.5-3.2c0.1-1.8-0.5-4.2-1.8-6.2
				c-1.3-2.1-2.4-3.4-3.5-4.6c-1.1-1.2-2.2-2.5-3.8-4.2c1.2,2.1,1.7,3.6,2.2,5.2C140.4,575.1,140.7,576.6,141,578.8z"
                />
              </g>
              <g>
                <path
                  d="M143.4,505.7c0.1-2,0.1-3.7-0.1-5.2c-0.2-1.5-0.5-2.7-1.3-3c-0.8-0.3-2,0.5-2.8,2.3c-0.8,1.7-1,4.4-0.2,6.7
				c0.9,2.4,1.9,3.8,3,5.1c1.1,1.3,2.2,2.5,4,4c-1.5-1.8-2-3.4-2.3-4.8C143.4,509.2,143.3,507.7,143.4,505.7z"
                />
              </g>
              <g>
                <path
                  d="M158.8,498.6c0.9-1.8,1.7-3.4,2.3-4.9c0.6-1.4,0.9-2.7,0.4-3.4c-0.5-0.7-2.1-0.5-3.6,0.8c-1.6,1.3-3.1,3.7-3.3,6.4
				c-0.2,2.8,0.3,4.6,0.9,6.3c0.6,1.7,1.4,3.3,2.8,5.3c-1-2.3-1.1-4-0.9-5.5C157.5,501.9,157.9,500.4,158.8,498.6z"
                />
              </g>
              <g>
                <path
                  d="M187.9,576c2.7-0.4,5.2-1.1,7.1-1.9c1.9-0.8,3.2-1.7,3.1-2.6c-0.1-0.9-1.6-1.5-3.8-1.5c-2.2,0-5.1,0.5-7.7,1.8
				c-2.7,1.3-4.4,2.5-6.1,3.6c-1.8,1.2-3.5,2.4-5.8,4c2.7-1.2,4.6-1.8,6.5-2.3C183.2,576.8,185.2,576.4,187.9,576z"
                />
              </g>
              <g>
                <path
                  d="M149,392.3c3,0.1,5.8-1,7.6-2.1c1.8-1.1,2.7-2.4,2.4-3.2c-0.3-0.8-1.9-1-3.9-0.7c-2,0.3-4.4,1-6.7,1.7
				c-2.4,0.8-4.2,1-6,0.9c-1.8-0.1-3.8-0.5-5.9-2.5c1.7,2.3,3.6,3.4,5.4,4.3C143.8,391.6,145.9,392.2,149,392.3z"
                />
              </g>
              <g>
                <path
                  d="M134,321c-1.8,2.8-3.2,6-3.8,9.2c-0.6,3.2-0.5,6.3,0.2,8.9c0.7,2.6,1.8,4.5,2.9,5.6c0.5,0.6,1,0.9,1.4,1.1
				c0.4,0.2,0.7,0.2,0.8,0.1c0.7-0.6-0.7-3.3-1-7.6c-0.1-2.1,0-4.6,0.5-7.2c0.5-2.6,1.5-5.3,2.8-8c1.3-2.8,2.7-5.1,4-7.1
				c1.3-2.1,2.6-3.9,3.9-5.7c1.3-1.9,2.6-3.7,4-5.8c1.4-2.1,2.9-4.5,4.3-7.6c-1.6,2.9-3.4,5.1-5,7.1c-1.6,1.9-3.2,3.5-4.8,5.2
				c-1.6,1.6-3.1,3.2-4.8,5.1C137.7,315.9,135.8,318.1,134,321z"
                />
              </g>
              <g>
                <path
                  d="M143.2,322.4c-0.7,5.5,0.4,11.2,2,15.1c1.6,3.9,3.6,6,4.4,5.6c0.8-0.4,0.3-3.1-0.4-6.8c-0.7-3.8-1.4-8.7-1.7-13.7
				c-0.2-5.2,0.2-8.9,1-12.5c0.8-3.6,2-7.3,5-12.1c-3.4,4.5-5.2,8-6.8,11.5C145.2,313,143.9,316.7,143.2,322.4z"
                />
              </g>
              <g>
                <path
                  d="M149.7,316.6c0,4,1.3,7.8,2.8,10.4c1.5,2.6,3.2,3.9,3.9,3.4c0.8-0.4,0.5-2.4-0.1-5c-0.6-2.6-1.5-6-2.3-9.4
				c-0.9-3.5-1.2-6.1-1.4-8.7c-0.1-2.6,0-5.3,1-9.2c-1.5,3.7-2.3,6.3-2.9,9C150.2,309.8,149.7,312.5,149.7,316.6z"
                />
              </g>
              <g>
                <path
                  d="M93.8,341.5c3.2-2.6,5.7-6,7.1-8.8c1.4-2.8,1.7-5,0.9-5.4c-0.8-0.4-2.4,0.9-4.4,3c-2,2.1-4.4,5-6.8,8.1
				c-2.4,3.1-4.3,5.2-6.3,7.2c-2,2-4.2,3.8-7.8,6.1c3.8-1.8,6.4-3.2,8.9-4.6C88,345.7,90.5,344.2,93.8,341.5z"
                />
              </g>
              <g>
                <path
                  d="M102.3,347.6c2.7-0.3,5.3-1,7.2-1.7c1.9-0.8,3.2-1.7,3.1-2.5c-0.1-0.9-1.6-1.5-3.8-1.6c-2.2-0.1-5.1,0.4-7.7,1.7
				c-2.7,1.3-4.4,2.4-6.2,3.5c-1.8,1.2-3.6,2.3-5.9,3.8c2.7-1.2,4.7-1.7,6.6-2.1C97.6,348.2,99.5,347.9,102.3,347.6z"
                />
              </g>
              <g>
                <path
                  d="M119.4,246.7c1.9-1.6,3.5-3.5,4.5-5.2c1-1.6,1.4-3,0.8-3.7c-0.6-0.6-2.1-0.3-3.8,0.8c-1.7,1.1-3.6,3-4.9,5.4
				c-1.3,2.4-2,4.2-2.8,5.9c-0.8,1.9-1.5,3.7-2.5,6c1.5-2.3,2.8-3.7,4.1-5C116.1,249.6,117.4,248.4,119.4,246.7z"
                />
              </g>
              <g>
                <path
                  d="M20.7,384.4c-7.8,3.2-14.9,7.9-19.7,12.1c-4.8,4.2-7.2,7.7-6.7,8.3c0.6,0.7,4.1-1.6,9.2-5c5.1-3.3,11.9-7.6,19-11.4
				c7.3-3.9,12.7-6.1,18.2-8c5.5-1.8,11.3-3.3,20-4.3c-8.8,0.4-14.7,1.3-20.5,2.4C34.5,379.7,28.7,381.1,20.7,384.4z"
                />
              </g>
              <g>
                <path
                  d="M27.1,386.9c-6.2,3.1-11.7,7.5-15.3,11.3c-3.6,3.8-5.4,6.9-4.8,7.4c0.7,0.6,3.5-1.3,7.5-4.3c4-3,9.3-6.9,14.8-10.6
				c5.7-3.8,9.8-6.1,14.1-8.2c4.3-2,8.9-3.9,15.8-5.7c-7.1,1.2-11.9,2.5-16.5,3.9C38.1,382.1,33.5,383.7,27.1,386.9z"
                />
              </g>
              <g>
                <path
                  d="M-19.8,372.5c-3.2-2.4-6.3-4.7-8.9-6.3c-2.5-1.6-4.5-2.5-5.1-1.9c-0.6,0.6,0.3,2.7,2.4,5.1c2.1,2.4,5.5,5.1,9.3,6.9
				c4,1.8,6.8,2.7,9.6,3.6c2.9,0.8,5.7,1.6,10,2.5c-4.1-1.4-6.8-2.8-9.3-4.2C-14.2,376.7-16.6,375-19.8,372.5z"
                />
              </g>
              <g>
                <path
                  d="M-14.5,384.4c-0.9,0-1.8,0.2-2.7,0.6c-0.9,0.3-1.9,1-2.5,2c-0.6,1-0.6,2.1-0.3,2.7c0.3,0.7,0.9,0.8,1.3,0.7
				c0.4-0.1,0.8-0.4,1.2-0.5c0.4-0.1,0.7-0.1,1-0.1c0.2,0.1,0.6,0,1.2-0.2c0.7-0.2,1.4-0.5,2.1-1c1.4-0.9,2.5-1.5,3.6-2
				c1.1-0.5,2.3-0.9,4.1-1.2c-1.6-0.2-2.9-0.5-4.4-0.7C-11.3,384.6-12.6,384.4-14.5,384.4z"
                />
              </g>
              <g>
                <path
                  d="M-13.6,395.2c-2.1,1.6-3.6,3.7-4.4,5.6c-0.7,1.8-0.7,3.3,0,3.8c0.8,0.5,2-0.2,3.4-1.4c1.4-1.2,2.9-3,4.1-5
				c1.3-2.1,2.3-3.5,3.4-4.9c1.1-1.4,2.3-2.7,4.3-4.4c-2.3,1.2-4,2-5.6,2.9C-9.8,392.7-11.4,393.6-13.6,395.2z"
                />
              </g>
              <g>
                <path d="M-9.5,421.5c-1.3,2.9-0.3,6,1.4,6.3c1.7,0.2,3.5-2.6,2.9-5.7c-0.5-3.2-1.1-4.4-1.2-7.7C-7.4,417.5-8.2,418.6-9.5,421.5z" />
              </g>
              <g>
                <path d="M-2.1,415.6c-1.2,2.5-0.2,5.3,1.5,5.5c1.7,0.2,3.4-2.2,2.8-4.9c-0.6-2.8-1.2-3.9-1.4-6.7C0,412.2-0.9,413.1-2.1,415.6z" />
              </g>
              <g>
                <path
                  d="M578.1,305.9c3.3-4.2,6.4-8.7,8.9-13.6c2.5-4.9,4.2-10.3,4.9-15.7c0.7-5.4,0.4-10.9-1.2-15.8c-0.4-1.2-0.8-2.4-1.3-3.6
				c-0.5-1.2-1.1-2.3-1.8-3.4c-1.4-2.2-3.1-4.2-5.3-5.7c-1.1-0.8-2.3-1.3-3.6-1.8c-1.3-0.4-2.7-0.6-4-0.5c-2.5,0.1-4.9,0.9-6.8,2.5
				c-0.9,0.8-1.7,1.7-2.3,2.7c-0.6,1-0.8,2.1-1,3.1c-0.2,2,0.2,3.7,0.7,5c1.1,2.5,2.5,3.9,3.5,4.8c1,0.9,1.7,1.1,2.1,0.9
				c0.4-0.2,0.3-1,0.1-2.2c-0.2-1.2-0.8-2.9-1-4.7c-0.1-0.9,0-1.8,0.3-2.5c0.2-0.3,0.4-0.7,0.6-0.9c0.2-0.2,0.5-0.4,0.9-0.6
				c0.7-0.3,1.7-0.5,2.7-0.3c0.5,0.1,1,0.2,1.4,0.4c0.4,0.2,0.9,0.6,1.4,1c0.9,0.8,1.8,2,2.5,3.4c0.7,1.4,1.3,3.2,1.8,5
				c0.9,3.6,1,7.7,0.4,12c-0.6,4.2-1.9,8.6-3.8,12.8c-1.9,4.3-4.4,8.5-7.2,12.6c-2.9,4.3-5.8,8.2-8.5,11.9
				c-2.7,3.7-5.2,7.3-7.5,10.8c-2.2,3.5-4.2,7-5.8,10.6c-1.6,3.6-2.8,7.2-3.7,10.9c-1.7,7.4-2.1,15.1-0.8,23.5
				c0.6,4.2,1.7,8.5,3.4,13c1.7,4.4,4,9,7.3,13.5c-3-4.6-5.1-9.3-6.5-13.7c-1.4-4.5-2.1-8.8-2.4-12.9c-0.6-8.2,0.5-15.5,2.8-22.3
				c1.2-3.4,2.6-6.6,4.4-9.7c1.8-3.1,4-6.2,6.4-9.3c2.4-3.1,5.2-6.3,8.2-9.7C571.4,313.8,574.7,310.2,578.1,305.9z"
                />
              </g>
              <g>
                <path
                  d="M561.9,353.7c1-1.7,2-3.3,3.1-4.8c1.1-1.5,2.2-2.8,3.4-4.1c1.1-1.3,2.4-2.4,3.5-3.4c0.3-0.2,0.6-0.5,0.9-0.7
				c0.3-0.2,0.5-0.4,0.8-0.6c0.5-0.4,1.1-0.7,1.6-1c2.2-1.2,4-1.8,5.4-2c1.5-0.3,2.7-0.1,3.3-0.7c0.3-0.3,0.4-0.8,0.2-1.5
				c-0.2-0.7-0.8-1.5-1.9-2.3c-1-0.8-2.5-1.4-4.3-1.6c-1.7-0.2-3.7-0.1-5.6,0.3c-1,0.2-2,0.6-3,1c-0.5,0.2-1,0.5-1.5,0.7
				c-0.5,0.3-0.9,0.5-1.4,0.8c-1.8,1.1-3.7,2.5-5.3,4.2c-1.6,1.7-3.1,3.6-4.4,5.6c-1.2,2.1-2.2,4.3-3,6.5c-1.6,4.7-2.1,8.7-2.3,12.3
				c-0.2,3.6,0,6.8,0.3,9.9c0.7,6.3,1.9,12.4,3.8,21.4c-0.9-9.1-0.8-15.4,0-21.4c0.4-3,1-5.9,1.9-9
				C558.6,360.4,559.9,357.1,561.9,353.7z"
                />
              </g>
              <g>
                <path
                  d="M574.5,279.9c-0.6-1.9-1.5-3.7-2.6-5.3c-0.6-0.8-1.2-1.6-1.9-2.3c-0.7-0.7-1.5-1.3-2.3-1.9c-1.7-1.1-3.9-1.8-6.2-1.5
				c-1.1,0.2-2.1,0.5-3,1.1c-0.9,0.6-1.6,1.4-2.1,2.4c-0.4,0.9-0.6,1.9-0.6,2.8c0,0.9,0.2,1.6,0.5,2.3c0.5,1.3,1.2,2.1,1.8,2.7
				c1.2,1.2,2.1,1.6,2.4,1.4c0.4-0.3,0.1-1.2-0.4-2.6c-0.2-0.7-0.5-1.5-0.6-2.4c-0.1-0.9,0.1-1.5,0.5-2c0.4-0.4,1.1-0.8,2-0.9
				c0.8-0.1,1.9,0.3,2.9,1c1.1,0.7,2.1,1.8,3.1,2.9c0.9,1.2,1.7,2.5,2.4,3.9c1.3,3,1.8,5.9,1.9,8.5c0.1,2.6-0.2,5.1-0.8,7.5
				c-1.1,4.8-3.2,9.6-6.8,16.2c4.1-6.4,6.7-10.8,8.6-15.6c0.9-2.4,1.7-4.9,2-7.9C575.6,287.2,575.6,283.8,574.5,279.9z"
                />
              </g>
              <g>
                <path
                  d="M568.9,290c-0.3-0.5-0.5-1.1-0.8-1.6c-0.3-0.5-0.7-1.1-1.2-1.5c-0.9-1-1.9-1.8-3.4-2.3c-0.7-0.3-1.5-0.4-2.3-0.4
				c-0.8,0-1.6,0.3-2.3,0.6c-1.4,0.7-2.2,1.8-2.6,2.8c-0.5,1-0.6,2-0.6,2.7c0,0.8,0.1,1.4,0.3,1.9c0.3,1,0.7,1.5,1.2,1.5
				c0.4,0,0.9-0.5,1.3-1.4c0.4-0.8,0.9-2,1.5-2.7c0.3-0.4,0.7-0.6,1-0.7c0.3,0,0.5,0,0.9,0.1c0.4,0.2,1,0.5,1.6,1.1
				c0.3,0.3,0.6,0.5,0.8,0.9c0.3,0.3,0.5,0.7,0.8,1.1c1.1,1.5,1.8,3,2.3,4.5c0.5,1.4,0.8,2.8,0.9,4.2c0.1,1.4,0.1,2.9-0.3,4.5
				c-0.3,1.6-0.9,3.4-2.1,5.3c1.4-1.7,2.3-3.4,3-5c0.7-1.6,1.1-3.1,1.3-4.7c0.2-1.6,0.4-3.1,0.2-4.9
				C570.3,294.2,569.9,292.2,568.9,290z"
                />
              </g>
              <g>
                <path
                  d="M548.8,301.3c-1.9-0.3-3.8-0.3-5.2,0c-1.5,0.3-2.6,0.9-2.7,1.8c-0.2,0.9,0.7,1.8,2.3,2.4c1.6,0.6,3.8,0.8,5.9,0.2
				c2.2-0.6,3.6-1.2,5-1.9c1.5-0.7,2.9-1.4,4.7-2.2c-2.1,0.4-3.6,0.5-5.1,0.4C552.1,301.9,550.7,301.7,548.8,301.3z"
                />
              </g>
              <g>
                <path
                  d="M536.3,308.9c-5.8,0.2-11.1,2.8-10.6,4.5c0.4,1.7,6.2,1.7,11.5-0.2c5.5-1.9,7.5-3,13.4-4.3
				C544.5,309.2,542.3,308.8,536.3,308.9z"
                />
              </g>
              <g>
                <path
                  d="M525.6,316.8c-3.9-0.7-7.6,0.8-7.6,2.5c0.1,1.8,4,2.9,7.8,1.8c3.9-1.1,5.3-1.9,9.3-2.6
				C531,318.2,529.6,317.5,525.6,316.8z"
                />
              </g>
              <g>
                <path
                  d="M537.6,319c-2.2,1.1-3.9,2.9-4.8,4.6c-0.9,1.6-1.1,3.1-0.4,3.6c0.7,0.6,2,0.2,3.4-0.7c1.5-0.9,3.1-2.3,4.5-4
				c1.5-1.7,2.6-2.9,3.8-4.1c1.2-1.1,2.5-2.2,4.6-3.4c-2.4,0.7-4,1.3-5.6,1.8C541.5,317.3,539.9,317.8,537.6,319z"
                />
              </g>
              <g>
                <path
                  d="M545.3,322.1c-1.5,1.4-2.5,3.1-2.9,4.6c-0.4,1.5-0.2,2.7,0.6,3.2c0.8,0.4,1.9,0,3-1c1.1-1,2.2-2.5,2.9-4.3
				c1.5-3.7,1.8-5.3,4.1-8.8C549.9,318.6,548.4,319.3,545.3,322.1z"
                />
              </g>
              <g>
                <path
                  d="M554.7,308c-1.3,0.9-2.1,2.2-2.4,3.4c-0.3,1.2,0,2.2,0.7,2.7c0.7,0.5,1.8,0.3,2.7-0.3c1-0.6,1.9-1.7,2.4-3
				c1.1-2.8,1.3-4.1,3.2-6.7C558.7,305.8,557.4,306.2,554.7,308z"
                />
              </g>
              <g>
                <path
                  d="M532.3,341c-1.2,1.5-1.8,3.3-1.8,4.8c0,1.5,0.5,2.5,1.4,2.7c0.9,0.2,1.8-0.4,2.6-1.5c0.8-1,1.5-2.5,1.8-4.2
				c0.3-1.7,0.5-3,0.9-4.2c0.3-1.2,0.8-2.4,1.7-4.1c-1.4,1.3-2.3,2.2-3.3,3.1C534.5,338.6,533.5,339.5,532.3,341z"
                />
              </g>
              <g>
                <path
                  d="M533.2,356.5c-2.1,3.9-1.8,8.5-0.1,8.9c1.7,0.4,3.9-3.4,4.3-7.6c0.4-4.4,0.2-6.1,1.4-10.5
				C536.7,351.3,535.4,352.5,533.2,356.5z"
                />
              </g>
              <g>
                <path
                  d="M533.9,379.3c-0.4,2.4,0.2,4.9,1,6.6c0.8,1.7,2,2.6,2.8,2.3c0.8-0.3,1.2-1.6,1.3-3.2c0.1-1.7-0.2-3.7-0.7-5.8
				c-0.5-2.2-0.8-3.7-1-5.4c-0.2-1.6-0.2-3.3,0.2-5.7c-0.9,2.3-1.5,3.8-2.1,5.5C534.8,375.2,534.2,376.8,533.9,379.3z"
                />
              </g>
              <g>
                <path
                  d="M570.2,359.3c1.9-2.2,3.7-4.2,5-6c1.3-1.7,2.2-3.2,1.7-3.9c-0.5-0.7-2.3-0.5-4.4,0.8c-2.2,1.3-4.6,3.7-6,6.8
				c-1.4,3.1-1.9,5.4-2.3,7.6c-0.4,2.2-0.6,4.5-0.6,7.8c0.5-3.2,1.4-5.3,2.4-7.2C567.1,363.2,568.3,361.5,570.2,359.3z"
                />
              </g>
              <g>
                <path
                  d="M580.7,319.9c1.7-0.7,3-1.7,4-2.7c0.9-1,1.4-2.1,1.1-2.9c-0.4-0.8-1.6-1.1-3.1-0.8c-1.5,0.3-3.3,1.3-4.6,2.8
				c-1.3,1.6-2.1,2.8-2.8,4c-0.8,1.3-1.5,2.6-2.4,4.1c1.4-1.4,2.6-2.1,3.8-2.7C577.8,321.1,579,320.6,580.7,319.9z"
                />
              </g>
              <g>
                <path
                  d="M-6.5,258.1c1.9-0.4,3.5-1.1,4.7-2c1.2-0.9,1.9-1.9,1.6-2.7c-0.2-0.8-1.4-1.3-3-1.2c-1.6,0.1-3.5,0.7-5.2,2
				c-1.7,1.3-2.7,2.3-3.8,3.3c-1,1-2.1,2-3.7,3.4c1.9-0.9,3.2-1.4,4.6-1.7C-9.8,258.7-8.5,258.5-6.5,258.1z"
                />
              </g>
              <g>
                <path
                  d="M8.4,246.2c-1.5,2.6-2.1,5.6-2,7.8c0.1,2.3,0.8,3.7,1.7,3.8c0.9,0.1,1.7-1.2,2.5-3.1c0.8-1.9,1.5-4.3,2-6.9
				c0.5-2.7,1.1-4.5,1.7-6.4c0.7-1.8,1.5-3.7,3.3-6.1c-2.1,2-3.5,3.5-4.9,5.1C11.2,242,9.9,243.6,8.4,246.2z"
                />
              </g>
              <g>
                <path
                  d="M544.8,223.7c-1.8-1.5-3.8-2.7-6-3.5c-2.1-0.9-4.5-1.4-6.9-1.4c-1.2,0-2.4,0.2-3.5,0.6c-1.2,0.4-2.3,0.9-3.2,1.6
				c-1.9,1.4-3.2,3.3-3.7,5.3c-0.3,1-0.3,2-0.2,3.1c0.2,1,0.5,1.8,0.9,2.5c0.9,1.4,2.1,2.3,3.1,2.7c1.1,0.4,2,0.4,2.6,0.2
				c0.6-0.2,0.8-0.4,0.8-0.6c-0.1-0.5-1.1-0.6-2.2-1.7c-1.1-1-2.1-3.1-1.2-5c0.4-1,1.3-2,2.4-2.7c0.6-0.4,1.2-0.6,1.9-0.8
				c0.7-0.2,1.5-0.3,2.2-0.3c3.2,0,7,1.4,10.2,3.5c3.3,2.2,5.7,4.8,7.4,7.5c0.9,1.3,1.6,2.7,2.2,4c0.6,1.4,1.1,2.7,1.5,4.1
				c0.8,2.8,1.2,5.8,1.2,9.2c0,3.4-0.4,7.1-1.6,11.5c1.4-4.3,2.2-8,2.5-11.5c0.3-3.4,0.2-6.5-0.3-9.6c-0.3-1.5-0.6-3.1-1-4.6
				c-0.5-1.5-1.1-3.1-1.9-4.7C550.7,230.1,548.4,226.7,544.8,223.7z"
                />
              </g>
              <g>
                <path
                  d="M547.9,236.8c-0.9-1.3-2.2-2.4-3.6-3.2c-1.5-0.8-3.3-1.2-5-1c-1.8,0.2-3.4,1.2-4.5,2.4c-1.1,1.2-1.7,2.9-1.6,4.4
				c0.1,1.7,1,2.9,1.8,3.6c0.9,0.7,1.9,1.1,2.7,1.1c0.8,0,1.4-0.2,1.8-0.5c0.3-0.3,0.4-0.6,0.3-0.8c-0.2-0.4-0.9-0.6-1.4-1.1
				c-0.3-0.3-0.6-0.6-0.8-1.1c-0.2-0.4-0.3-0.9-0.1-1.2c0.1-0.4,0.4-0.8,0.8-1.2c0.5-0.4,1.1-0.7,1.7-0.7c1.3-0.2,3.2,0.6,4.7,2
				c1.6,1.5,2.6,3.5,3.6,5.3c0.9,1.8,1.7,3.4,2.3,5.1c0.6,1.7,1.2,3.5,1.6,5.5c0.4,2,0.7,4.4,0.8,7.1c0.2-2.8,0.2-5.1,0.1-7.2
				c-0.1-2.1-0.3-4-0.6-5.9c-0.3-1.9-0.7-3.7-1.3-5.8c-0.3-1-0.6-2.1-1.1-3.3C549.5,239.4,548.9,238.1,547.9,236.8z"
                />
              </g>
              <g>
                <path
                  d="M613.1,514.1c0.5,1.2,0.7,2.4,0.8,3.5c0,1.1-0.1,2-0.4,2.7c-0.3,0.7-0.7,1.4-1.3,2.1c-0.6,0.6-1.4,1.3-2.4,1.7
				c-1.9,1-4,1.4-6.1,1.3c-2-0.1-3.6-0.8-4.7-1.5c-1.2-0.9-2-2.1-2.3-3.2c-0.4-1-0.3-2.3,0.1-3.4c0.4-1.1,0.9-2,1.4-2.8
				c0.5-0.8,0.9-1.4,0.9-2.1c0-0.6-0.6-1.3-1.9-1.6c-1.3-0.3-3.3,0-5.5,1.3c-1.1,0.7-2.1,1.6-3.1,2.9c-0.5,0.7-0.9,1.4-1.2,2.2
				c-0.4,0.8-0.6,1.8-0.8,2.7c-0.7,3.8,0.2,8.2,2.9,11.9c2.8,3.9,7.2,6.5,12,7.4c4.7,1,10.1,0.5,14.9-1.9c2.4-1.2,4.7-2.8,6.7-5
				c2-2.2,3.6-4.9,4.4-8c0.8-3,0.8-6.2,0.3-9c-0.6-2.9-1.7-5.4-3.1-7.7c-1.5-2.3-3.3-4.3-5.2-5.9c-1.9-1.6-4.1-2.9-6.1-3.7
				c-4.1-1.7-8-2.3-11.5-2.4c-7.1-0.1-12.9,1.4-18.6,3.1c-5.6,1.8-11.1,4.1-16.8,7.4c-5.7,3.2-11.6,7.4-17.6,13.4
				c6.6-5.4,12.9-8.7,18.8-11c5.9-2.3,11.4-3.5,16.8-4.2c5.4-0.6,11-0.7,16.3,0.5c2.7,0.6,5.3,1.6,7.5,3.1c1.2,0.8,2,1.6,2.8,2.6
				C611.9,511.7,612.6,512.9,613.1,514.1z"
                />
              </g>
              <g>
                <path
                  d="M586.9,487.9c2.8-1.8,5.2-4.2,7.1-6.8c1.9-2.6,3.2-5.3,3.9-7.8c0.7-2.5,0.8-4.7,0.6-6.3c-0.3-1.6-0.9-2.5-1.8-2.7
				c-1.8-0.3-3.7,2.1-6.3,5.3c-1.3,1.7-2.7,3.5-4.3,5.4c-1.6,2-3.3,4.1-5.1,6.3c-1.8,2.3-3.6,4.1-5.2,5.7c-1.7,1.6-3.2,3.1-4.9,4.4
				c-3.3,2.7-6.9,5.3-12.6,8.5c6.1-2.3,10.3-3.7,14.5-5.2c2.1-0.8,4.2-1.6,6.5-2.7C581.6,491.1,584.1,489.8,586.9,487.9z"
                />
              </g>
              <g>
                <path
                  d="M574,520.4c-1.2,2.3-1.3,5.1-0.8,7c0.5,1.9,1.5,3,2.4,2.8c0.9-0.2,1.4-1.4,1.8-3c0.4-1.6,0.7-3.5,0.8-5.6
				c0.2-2.2,0.5-3.7,1-5.3c0.5-1.5,1.3-3,2.9-4.9c-2,1.4-3.3,2.6-4.5,3.9C576.4,516.6,575.3,518,574,520.4z"
                />
              </g>
              <g>
                <path
                  d="M580.2,543.9c0.4,2.6,1.7,5,3.2,6.4c1.5,1.4,2.9,1.9,3.6,1.3c0.7-0.6,0.5-2,0-3.6c-0.5-1.6-1.4-3.6-2.5-5.5
				c-1.1-2-1.8-3.6-2.3-5.2c-0.5-1.6-0.9-3.3-0.8-5.9c-0.6,2.5-0.9,4.3-1.1,6.1C580,539.4,579.8,541.2,580.2,543.9z"
                />
              </g>
              <g>
                <path
                  d="M599.3,558c1.3,1.4,3,2.4,4.7,2.8c1.7,0.5,3.4,0.5,4.8,0.2c1.4-0.3,2.4-0.9,3-1.4c0.6-0.6,0.8-1.1,0.7-1.5
				c-0.4-0.8-2-0.9-3.9-1.2c-2-0.3-4.3-0.9-6.5-2.2c-1.2-0.7-2.1-1.5-2.9-2.3c-0.8-0.8-1.4-1.7-2-2.6c-1.2-1.8-2.1-3.9-3.1-7.1
				c0.5,3.3,0.8,5.6,1.4,7.8c0.3,1.1,0.6,2.3,1.2,3.5C597.2,555.2,598,556.6,599.3,558z"
                />
              </g>
              <g>
                <path
                  d="M613.7,553.2c2.2,1.1,4.7,1.3,6.5,1c1.8-0.3,3-1.2,2.9-2.1c-0.1-0.9-1.2-1.6-2.7-2.1c-1.5-0.5-3.5-0.9-5.5-1
				c-2.2-0.2-3.7-0.4-5.2-0.7c-1.5-0.4-3.1-0.9-5.1-2.2c1.7,1.7,2.9,2.8,4.2,3.9C610.1,551,611.5,552.1,613.7,553.2z"
                />
              </g>
              <g>
                <path
                  d="M623.6,545.9c1.5,0.1,2.9-0.6,3.8-1.4c0.8-0.8,1.1-1.9,0.7-2.6c-0.4-0.8-1.3-1.2-2.4-1.2c-1,0-2.2,0.3-3.3,1
				c-1.1,0.8-1.9,1.3-2.8,1.8c-0.9,0.5-1.8,0.9-3.2,1.2c1.4,0.3,2.5,0.5,3.5,0.7C620.9,545.6,622,545.8,623.6,545.9z"
                />
              </g>
              <g>
                <path
                  d="M584.7,454.4c0.8-4,0.4-8.1-0.6-11.1c-0.9-2.9-2.3-4.6-3.1-4.4c-0.9,0.3-1.1,2.3-1,5c0,2.8,0.2,6.4,0.3,10.1
				c0.1,3.8,0,6.5-0.4,9.1c-0.4,2.7-1,5.4-2.7,9.1c2.2-3.5,3.4-6,4.5-8.6C582.9,461.2,583.9,458.5,584.7,454.4z"
                />
              </g>
              <g>
                <path
                  d="M175.8,236.9c-0.3,3.3,0.7,6.7,2.2,8.7c1.4,2.1,3.1,2.9,3.7,2.4c0.7-0.5,0.5-2.1,0.1-4.1c-0.5-2.3-1-4.4-1.6-7.2
				c-0.5-2.8-0.6-4.8-0.4-6.9c0.2-2,0.7-4.2,2.3-6.9c-2.1,2.4-3.2,4.4-4.2,6.4C177,231.3,176.2,233.5,175.8,236.9z"
                />
              </g>
              <g>
                <path
                  d="M316.4,56.6c3.3,0.8,6.5,1.5,9,2c2.5,0.5,4.4,0.7,4.8-0.1c0.4-0.7-0.8-2.4-3.3-3.8c-2.5-1.5-6.3-2.6-10.1-2.5
				c-3.9,0.1-6.5,0.7-9.1,1.5c-2.5,0.8-5,1.6-8.5,3.4c3.6-1.2,6.2-1.4,8.7-1.5C310.5,55.6,313,55.9,316.4,56.6z"
                />
              </g>
              <g>
                <path
                  d="M166.4,149.7c1.4,3.4,3.9,6.3,6.1,7.9c2.2,1.7,4.1,2.1,4.7,1.5c0.6-0.7-0.3-2.3-1.7-4.4c-1.4-2.1-3.3-4.6-5.3-7.2
				c-2.1-2.7-3.3-4.7-4.4-6.9c-1.1-2.2-2-4.5-2.6-8.1c0.1,3.7,0.4,6.2,0.8,8.7C164.4,143.7,165,146.2,166.4,149.7z"
                />
              </g>
              <g>
                <path
                  d="M79.1,161.1c1.1-2.5,1.1-5.4,0.4-7.4c-0.7-2-1.8-3-2.6-2.8c-0.9,0.3-1.3,1.6-1.5,3.2c-0.3,1.7-0.4,3.7-0.5,5.9
				c-0.1,2.3-0.4,3.9-0.9,5.5c-0.5,1.6-1.3,3.2-3.1,5.1c2.1-1.5,3.4-2.8,4.7-4.2C76.7,165.2,77.9,163.7,79.1,161.1z"
                />
              </g>
              <g>
                <path
                  d="M340.8,260.3c3.5-6.8,4.5-14.6,2.8-15.1c-1.7-0.5-5.1,6.3-6.9,13.5c-0.9,3.8-1.5,6.4-2.2,9c-0.7,2.6-1.5,5.2-2.9,9
				c1.9-3.5,3.3-5.9,4.7-8.3C337.6,266.1,339,263.8,340.8,260.3z"
                />
              </g>
              <g>
                <path
                  d="M485,235.3c3.3,0,6.5-0.3,9-0.8c2.4-0.5,4.1-1.2,4.2-2.1c0-0.9-1.7-1.7-4.2-2.1c-2.6-0.4-6.1-0.3-9.4,0.6
				c-3.4,0.9-5.7,1.8-8,2.7c-2.4,1-4.7,1.9-7.8,3.1c3.5-0.8,5.9-1.1,8.3-1.3C479.3,235.3,481.6,235.3,485,235.3z"
                />
              </g>
              <g>
                <path
                  d="M596.5,141.5c-2.7,0.3-5.3,1.3-7,2.5c-1.7,1.2-2.6,2.4-2.2,3.2c0.4,0.8,1.9,0.9,3.8,0.6c1.9-0.3,4.3-1,6.5-2.1
				c2.4-1.1,4-1.9,5.8-2.5c1.7-0.7,3.5-1.2,6.3-1.7c-2.6-0.1-4.5-0.1-6.6-0.2C601.2,141.3,599.3,141.2,596.5,141.5z"
                />
              </g>
              <g>
                <path
                  d="M548.5,79c-2.4,1.8-4.6,3.6-6.2,5.2c-1.6,1.6-2.6,2.9-2.2,3.7c0.4,0.8,2.2,0.8,4.5-0.2c2.3-1,5.1-3,7.1-5.6
				c2-2.7,3.1-4.8,4-6.9c0.9-2.1,1.8-4.2,2.9-7.4c-1.5,3-3,4.7-4.5,6.3C552.6,75.7,550.9,77.1,548.5,79z"
                />
              </g>
              <g>
                <path
                  d="M482.8,101.2c3.5-0.6,7-1.5,9.5-2.4c2.6-0.9,4.3-1.9,4.2-2.8c-0.1-0.9-2.1-1.4-4.9-1.3c-2.8,0.1-6.6,0.8-10,2.3
				c-3.6,1.5-5.9,2.8-8.2,4.1c-2.3,1.3-4.6,2.7-7.9,4.7c3.6-1.5,6.1-2.3,8.6-2.9C476.6,102.3,479.2,101.9,482.8,101.2z"
                />
              </g>
              <g>
                <path
                  d="M503.3,526.1c2.5-1.7,3.3-4.9,2-6c-1.4-1.1-4.3,0.4-5.4,3.3c-1.2,2.9-1.3,4.3-2.8,7.1
				C499.4,528.3,500.7,527.9,503.3,526.1z"
                />
              </g>
              <g>
                <path
                  d="M281.3,518.4c2,0.3,3.8,0.4,5.3,0.2c1.5-0.1,2.7-0.5,3-1.3c0.3-0.8-0.5-2-2.2-2.8c-1.6-0.8-4.1-1.2-6.5-0.5
				c-2.4,0.7-3.9,1.5-5.3,2.3c-1.4,0.9-2.8,1.8-4.6,3.2c2.1-1,3.6-1.3,5.1-1.4C277.8,518,279.3,518.1,281.3,518.4z"
                />
              </g>
            </g>
          </g>
          <g id="Peddle9">
            <defs>
              <path
                id="SVGID_00000039814778879062576940000018199349081275424907_"
                d="M284.4,88.1c18.3,44.3,68.5,245.3,34.1,259.5
			C284.2,361.8,178.2,183.7,160,139.4C141.7,95.1,146.4,14.3,176.2,2.1C205.9-10.2,266.1,43.8,284.4,88.1z"
              />
            </defs>
            <use
              xlinkHref="#SVGID_00000039814778879062576940000018199349081275424907_"
              overflow="visible"
              fill="#003301"
            />
            <clipPath id="SVGID_00000082353223036526118060000011671372476561447050_">
              <use
                xlinkHref="#SVGID_00000039814778879062576940000018199349081275424907_"
                overflow="visible"
              />
            </clipPath>
            <g
              opacity="0.1"
              clipPath="url(#SVGID_00000082353223036526118060000011671372476561447050_)"
            >
              <g>
                <g>
                  <path
                    d="M312.1,219.6c8.1-1.2,15.4-3.8,20.8-7c5.4-3.2,8.8-6.9,8.1-10.4c-0.7-3.4-5.5-5.7-12.2-5.8c-6.7-0.1-15.4,2-22.9,6.8
					c-7.7,4.9-12.4,9-17.2,13c-4.7,4-9.4,8-16.3,13.7c8.1-3.8,13.9-5.6,19.8-6.9C297.9,221.7,303.8,220.8,312.1,219.6z"
                  />
                </g>
                <g>
                  <path
                    d="M314.5,239.9c7,1.8,13.9,2.1,19.5,1.1c5.5-1,9.7-3.2,10.5-6.6c0.8-3.4-2.4-7.3-8.1-9.9c-5.6-2.6-13.8-3.8-21.8-2.1
					c-8.2,1.8-13.5,3.9-18.8,6c-5.3,2.1-10.5,4.2-18,7.1c8-0.8,13.5-0.4,18.9,0.5C302,236.8,307.2,238.1,314.5,239.9z"
                  />
                </g>
                <g>
                  <path
                    d="M303.9,258.7c4.2,3.4,8.8,5.1,13,5.4c4.2,0.3,8.1-0.9,10-3.9c1.9-3,1.1-7.2-2.1-10.9c-3.1-3.7-8.6-6.8-14.9-7.1
					c-6.5-0.4-10.9,0.2-15.2,0.7c-4.3,0.5-8.6,1-14.6,1.5c5.8,1.7,9.5,3.7,12.9,5.9C296.4,252.6,299.5,255.2,303.9,258.7z"
                  />
                </g>
                <g>
                  <path
                    d="M282.6,276.1c1.5,4.7,4.5,8,8,9.7c3.4,1.7,7.3,1.9,10.2-0.1c2.9-2,4.1-5.7,3.3-9.8c-0.7-4-3.4-8.3-7.9-11
					c-4.6-2.8-8.1-4.2-11.5-5.8c-3.4-1.6-6.6-3.2-10.9-6.1c2.8,4.3,4.2,7.7,5.3,11.2C280.2,267.7,281.1,271.2,282.6,276.1z"
                  />
                </g>
                <g>
                  <path
                    d="M262,284.1c-1.5,5-1.3,9.6,0.1,13.5c1.3,3.8,3.8,7,7.3,7.6c3.4,0.7,7.1-1.5,9.3-5.8c2.2-4.2,3-10.5,0.8-16.4
					c-2.3-6-4.6-9.7-6.8-13.4c-2.3-3.6-4.5-7.1-7.6-12.2c1,5.8,0.8,9.9,0.1,13.8C264.5,275.2,263.5,279,262,284.1z"
                  />
                </g>
                <g>
                  <path
                    d="M238.4,276.8c-4.1,3.3-6.7,7-8.2,10.8c-0.3,1-0.7,1.9-0.9,2.9c-0.2,1-0.3,1.9-0.3,2.8c0,1.8,0.4,3.6,1.4,5
					c1,1.4,2.6,2.3,4.6,2.6c1,0.1,2.1,0.1,3.2-0.1c1.1-0.2,2.4-0.5,3.5-1.1c4.8-2.1,9.8-7.2,11.7-13.9c1.9-6.8,2-11.6,2-16.2
					c-0.1-4.6-0.3-9.1-0.8-15.3c-1.6,6-3.8,9.7-6.4,12.9C245.6,270.5,242.6,273.3,238.4,276.8z"
                  />
                </g>
                <g>
                  <path
                    d="M221.4,254.8c-5.9,0.7-10.8,2.7-14.6,5.4c-3.7,2.7-6.2,6.2-5.7,9.7c0.5,3.5,4.2,6.1,9.6,6.6c5.3,0.5,12.3-1.2,17.7-5.6
					c5.6-4.6,8.8-8.3,11.8-12c3-3.7,5.9-7.3,10.2-12.6c-5.6,3.6-10,5.2-14.3,6.3C231.8,253.5,227.4,254.1,221.4,254.8z"
                  />
                </g>
                <g>
                  <path
                    d="M214.7,232.3c-5.8-1.9-11.2-2.4-15.9-1.9c-1.2,0.2-2.3,0.3-3.3,0.7c-1.1,0.3-2,0.7-2.9,1.1c-1.7,0.9-3.1,2.2-3.7,3.8
					c-0.6,1.6-0.4,3.5,0.5,5.3c0.5,0.9,1.2,1.9,2,2.7c0.9,0.9,1.9,1.8,3.1,2.5c4.8,3.1,12.4,4.8,19.7,3.2c7.5-1.7,12.3-4,16.9-6.2
					c4.5-2.3,9-4.7,15-8.3c-6.9,1.6-11.7,1.4-16.4,0.8C225.1,235.5,220.6,234.3,214.7,232.3z"
                  />
                </g>
                <g>
                  <path
                    d="M221.6,214.1c-3.8-4.1-7-7.1-10.2-9.4c-1.6-1.2-3.2-2.2-4.8-3.1c-1.6-0.9-3.3-1.6-5.1-1.4c-1.7,0.1-3.3,1.2-4.4,3.3
					c-1.1,2.1-1.4,5.3-0.6,8.8c0.8,3.5,2.7,7.3,5.7,10.4c2.9,3.1,6.8,5.6,11,6.8c4.4,1.3,7.9,1.6,11.1,1.7c3.2,0.1,5.9-0.1,8.6-0.4
					c5.4-0.6,10.5-1.4,17.9-2.1c-7.3-1.5-12.2-3.2-16.6-5.4c-2.2-1.1-4.3-2.4-6.4-3.9C225.6,217.9,223.5,216.2,221.6,214.1z"
                  />
                </g>
                <g>
                  <path
                    d="M239.5,196.3c-0.1-5.5-0.9-9.8-2.3-13.6c-0.7-1.9-1.5-3.7-2.5-5.3c-1-1.6-2.2-2.9-3.9-3.6c-1.6-0.7-3.5-0.5-5.4,0.6
					c-1.9,1.1-3.7,3.3-4.9,6.3c-1.2,3-1.8,6.7-1.4,10.5c0.4,3.8,1.7,7.8,3.9,11.1c2.3,3.5,4.6,5.9,6.7,7.9c2.2,2,4.2,3.6,6.3,5.1
					c4.1,3,8.3,5.7,14,9.6c-4.3-5.5-6.5-9.9-8-14.2c-0.7-2.2-1.3-4.4-1.8-6.8C239.9,201.7,239.6,199.1,239.5,196.3z"
                  />
                </g>
                <g>
                  <path
                    d="M259.2,191.2c2.3-5.4,3.1-10.6,2.9-15.2c-0.1-1.2-0.2-2.2-0.4-3.3c-0.2-1.1-0.5-2-0.9-2.9c-0.8-1.8-1.9-3.2-3.5-4
					c-1.6-0.8-3.4-0.8-5.3,0c-1,0.4-2,1-2.9,1.8c-0.9,0.8-1.9,1.7-2.8,2.9c-3.5,4.5-5.8,11.9-4.6,19.3c1.3,7.6,3.3,12.4,5.3,17
					c2.1,4.6,4.3,9,7.7,15c-1.4-6.8-1-11.6-0.1-16C255.5,201.1,256.9,196.9,259.2,191.2z"
                  />
                </g>
                <g>
                  <path
                    d="M281.1,191.4c4.3-4.1,7.2-8,9.3-11.7c1-1.9,1.9-3.7,2.4-5.6c0.5-1.8,0.7-3.7,0-5.3c-0.6-1.6-2.1-2.9-4.3-3.4
					c-2.2-0.6-5.1-0.3-8.2,0.8c-3.1,1.2-6.3,3.3-8.9,6.2c-2.6,2.9-4.7,6.5-5.8,10.5c-1.2,4-1.6,7.4-1.8,10.4c-0.2,3-0.2,5.7-0.2,8.3
					c0.1,5.2,0.3,10.3,0,17.6c2.4-6.9,4.7-11.5,7.3-15.6c1.3-2.1,2.8-4,4.4-6C277.1,195.6,278.9,193.5,281.1,191.4z"
                  />
                </g>
                <g>
                  <path
                    d="M256.7,233.3c-1.5,3.2,1.5,6,5,5.6c3.5-0.4,5.8-3.7,3.7-6.5c-2.2-2.9-3.7-3.9-5.1-7C259.6,228.7,258.2,230,256.7,233.3z
					"
                  />
                </g>
                <g>
                  <path
                    d="M261.4,230.6c-1.4,1-1.8,2.3-1.5,3.7c0.3,1.4,1.3,2.8,2.8,3.6c1.5,0.9,3.3,0.9,4.7,0.2c1.4-0.7,2.3-2.2,2.1-4.1
					c-0.2-2-0.6-3.3-0.9-4.7c-0.3-1.3-0.6-2.6-0.7-4.4c-0.9,1.5-1.9,2.4-2.9,3.1C263.9,228.9,262.8,229.6,261.4,230.6z"
                  />
                </g>
                <g>
                  <path
                    d="M264.9,232.2c-1.3-0.6-2.1-0.2-2.9,0.7c-0.7,0.9-1.4,2.4-1.5,4.2c-0.1,1.7,0.7,3.3,2.1,4.1c1.4,0.8,3.3,0.7,4.8-0.7
					c1.5-1.4,2.2-2.7,2.8-3.8c0.6-1.2,1.2-2.3,2.1-3.6c-1.5,0.5-2.7,0.5-3.8,0.3C267.3,233.2,266.2,232.7,264.9,232.2z"
                  />
                </g>
                <g>
                  <path
                    d="M267.4,236.8c0-0.7-0.3-1.3-0.7-1.8c-0.5-0.5-1.1-1-1.9-1.2c-0.8-0.3-1.6-0.3-2.4-0.1c-0.8,0.2-1.5,0.6-2.1,1.3
					c-0.6,0.6-1.1,1.3-1.4,2c-0.3,0.7-0.6,1.4-0.6,2c0,0.7,0.1,1.3,0.5,2c0.4,0.6,1.1,1.3,2.1,1.7c1,0.5,2.1,0.8,3,1
					c0.9,0.2,1.7,0.2,2.5,0.1c1.6-0.1,3.1-0.5,4.5-1.6c-0.8,0.1-1.5-0.2-2-0.6c-0.5-0.4-0.8-0.8-1-1.3c-0.2-0.5-0.3-1-0.4-1.6
					C267.4,238,267.5,237.5,267.4,236.8z"
                  />
                </g>
                <g>
                  <path
                    d="M266.9,240c0.8-1.4,0.7-2.5-0.1-3.6c-0.7-1.1-2-2.2-3.8-2.6c-1.7-0.4-3.4,0.1-4.5,1.4c-1.1,1.3-1.4,3.5-0.4,5.4
					c1.1,2,2.1,3.1,3.2,4.1c1,1,2,2,3.2,3.4c-0.2-1.8,0-3.1,0.5-4.3C265.4,242.6,266.1,241.5,266.9,240z"
                  />
                </g>
                <g>
                  <path
                    d="M264.2,244.1c1.7-1.3,2.4-3.2,2.2-4.9c-0.2-1.7-1.4-3.1-3.1-3.7c-1.7-0.6-3.4-0.3-4.7,0.5c-1.3,0.8-2.1,2-2.1,3.8
					c0,1.9,0,3.3,0,4.7c-0.1,1.4-0.4,2.8-1.2,4.5c1.6-1,2.9-1.7,4.3-2.3C260.9,246.1,262.4,245.4,264.2,244.1z"
                  />
                </g>
                <g>
                  <path
                    d="M258.2,243.2c3.6,1.4,6.6-1.7,6.2-5.2c-0.4-3.5-4.2-5.7-7.3-3.5c-3.2,2.3-4.3,3.9-7.7,5.4
					C253,240.5,254.5,241.8,258.2,243.2z"
                  />
                </g>
                <g>
                  <path
                    d="M254.5,239.4c1.8,3.2,5.8,2.6,7.7-0.4c1.8-3,0.5-6.9-3.1-7c-3.7-0.1-5.5,0.5-8.9-0.3
					C252.5,234.3,252.7,236.1,254.5,239.4z"
                  />
                </g>
                <g>
                  <path
                    d="M255,235.1c0,1.7,1.1,2.7,2.4,3c1.3,0.3,3-0.2,4.3-1.3c1.4-1.1,2.1-2.6,2.3-3.8c0.1-1.2-0.4-2.3-1.8-2.8
					c-2.9-1-4.7-1.1-6.9-3C255.8,230.1,255,231.7,255,235.1z"
                  />
                </g>
                <g>
                  <path
                    d="M299.7,201.9c5.7-1.8,10.2-4.8,13.4-8.2c3.2-3.4,5-7.2,3.9-10.6c-1.1-3.3-5.3-5.2-10.8-4.6c-5.4,0.5-12,3.6-16.6,9.1
					c-4.7,5.7-7.1,10.1-9.4,14.4c-2.2,4.4-4.4,8.6-7.4,14.7c4.8-4.8,8.8-7.3,12.8-9.3C289.6,205.4,293.8,203.9,299.7,201.9z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M624.2,225.2c3.8,4.2,8.2,7.3,12.1,9.2c3.9,1.8,7.4,2.3,9.1,0.6c1.7-1.7,1.1-5.3-1.4-9.3c-2.6-3.9-7.1-8.1-12.8-10.5
					c-5.8-2.5-10-3.6-14.1-4.8c-4.1-1.1-8.3-2.3-14.2-4.1c5.3,3.1,8.6,5.8,11.6,8.7C617.5,217.9,620.3,220.9,624.2,225.2z"
                  />
                </g>
                <g>
                  <path
                    d="M613.5,234.5c1.7,4.7,4.2,8.8,6.9,11.6c2.7,2.8,5.6,4.4,7.9,3.4c2.2-0.9,3.3-4.2,2.6-8.5c-0.7-4.2-3.2-9.4-7.3-13.3
					c-4.2-4-7.5-6.2-10.7-8.5c-3.3-2.2-6.5-4.4-11.1-7.6c3.6,4.3,5.5,7.6,7.1,11C610.4,226.1,611.7,229.6,613.5,234.5z"
                  />
                </g>
                <g>
                  <path
                    d="M598.5,235.7c-0.3,3.7,0.5,7,2,9.6c1.5,2.5,3.7,4.3,6.1,4.2c2.4-0.1,4.5-2.2,5.4-5.4c0.9-3.2,0.5-7.6-1.7-11.3
					c-2.3-3.8-4.3-6.2-6.3-8.5c-2-2.3-3.9-4.5-6.5-7.8c1.3,4,1.6,6.8,1.6,9.7C599.1,229,598.9,231.8,598.5,235.7z"
                  />
                </g>
                <g>
                  <path
                    d="M580.4,230.2c-2.1,2.7-2.8,5.7-2.5,8.4c0.3,2.6,1.7,4.9,4,5.8c2.3,0.9,4.9,0.1,6.9-1.9c2-2,3.4-5.2,3.2-8.8
					c-0.2-3.7-0.7-6.3-1.1-8.9c-0.4-2.5-0.7-5.1-0.8-8.6c-1.4,3.3-2.8,5.4-4.3,7.4C584.2,225.6,582.5,227.5,580.4,230.2z"
                  />
                </g>
                <g>
                  <path
                    d="M567.7,221.5c-3.4,1.1-6,3-7.7,5.3c-1.7,2.3-2.5,4.9-1.5,7.2c0.9,2.2,3.6,3.5,7,3.1c3.3-0.4,7.2-2.4,9.7-5.9
					c2.6-3.6,3.8-6.4,5-9.1c1.2-2.7,2.3-5.4,4-9.1c-2.9,2.8-5.4,4.3-7.8,5.4C573.9,219.5,571.3,220.4,567.7,221.5z"
                  />
                </g>
                <g>
                  <path
                    d="M562.8,205.2c-3.5-1.1-6.6-1.1-9.4-0.5c-0.7,0.2-1.4,0.3-2,0.6c-0.6,0.3-1.2,0.6-1.7,0.9c-1.1,0.7-1.9,1.6-2.3,2.8
					c-0.4,1.1-0.3,2.4,0.3,3.6c0.3,0.6,0.7,1.2,1.3,1.8c0.6,0.6,1.2,1.1,2,1.6c3.1,1.9,8,2.8,12.5,1.3c4.6-1.6,7.4-3.3,10.1-5.1
					c2.6-1.8,5.1-3.7,8.4-6.4c-4.1,1.4-7.1,1.6-9.9,1.4C569.2,206.9,566.4,206.2,562.8,205.2z"
                  />
                </g>
                <g>
                  <path
                    d="M568.7,186.9c-2.6-3.1-5.7-5.2-8.7-6.3c-3-1.1-5.9-1.1-7.7,0.5c-1.8,1.6-1.9,4.8-0.1,8.1c1.8,3.3,5.4,6.6,10.1,8
					c4.8,1.4,8.2,1.8,11.5,2.1c3.3,0.3,6.5,0.5,11.2,0.9c-4.3-1.8-6.8-3.7-9.1-5.8C573.6,192.4,571.5,190.1,568.7,186.9z"
                  />
                </g>
                <g>
                  <path
                    d="M579.1,174.3c-1.1-4.1-3-7.4-5.1-9.9c-0.6-0.6-1.1-1.2-1.7-1.7c-0.6-0.5-1.2-0.9-1.8-1.2c-1.2-0.6-2.4-0.9-3.6-0.7
					c-1.2,0.3-2.2,1.1-2.8,2.4c-0.3,0.6-0.6,1.4-0.8,2.2c-0.2,0.8-0.3,1.8-0.2,2.8c0.1,4,2,9,5.8,12.6c3.9,3.7,7,5.5,10.1,7.2
					c3.1,1.7,6.2,3.3,10.6,5.4c-3.6-3.3-5.4-6.2-6.8-9.1C581.3,181.5,580.3,178.5,579.1,174.3z"
                  />
                </g>
                <g>
                  <path
                    d="M592.2,171.1c0.9-3.8,1.3-6.8,1.4-9.5c0.1-1.3,0-2.7-0.1-4c-0.1-1.3-0.4-2.5-1.2-3.5c-0.8-0.9-2-1.4-3.6-1.2
					c-1.6,0.2-3.6,1.2-5.3,3c-1.7,1.8-3.1,4.4-3.7,7.3c-0.6,2.9-0.6,6,0.3,8.9c0.9,3,2.1,5.2,3.3,7c1.2,1.8,2.4,3.3,3.6,4.8
					c2.4,2.9,4.9,5.5,8.1,9.5c-2-4.8-2.9-8.3-3.3-11.7c-0.2-1.7-0.3-3.4-0.3-5.2C591.6,175,591.7,173.1,592.2,171.1z"
                  />
                </g>
                <g>
                  <path
                    d="M609.4,174.5c3.1-2.2,5.3-4.3,6.9-6.6c0.8-1.1,1.5-2.3,2.1-3.5c0.5-1.2,0.8-2.4,0.6-3.6c-0.2-1.2-1.1-2.2-2.5-2.8
					c-1.4-0.6-3.3-0.8-5.5-0.4c-2.2,0.4-4.5,1.5-6.6,3.3c-2,1.7-3.8,4-4.8,6.5c-1.1,2.7-1.6,4.9-1.9,6.9c-0.3,2-0.4,3.8-0.5,5.6
					c-0.1,3.5,0,6.9-0.1,11.8c1.5-4.6,3.1-7.6,5.1-10.1c1-1.3,2-2.5,3.2-3.6C606.4,176.8,607.8,175.6,609.4,174.5z"
                  />
                </g>
                <g>
                  <path
                    d="M619.9,183.9c4-0.8,7.3-2.3,9.9-4.3c0.6-0.5,1.2-1,1.7-1.5c0.5-0.5,1-1.1,1.3-1.6c0.7-1.1,1.1-2.3,1-3.6
					c-0.2-1.2-0.9-2.3-2.1-3.1c-0.6-0.4-1.3-0.8-2.1-1c-0.8-0.2-1.7-0.4-2.7-0.5c-3.9-0.3-9.1,1.3-12.8,4.9
					c-3.8,3.7-5.8,6.7-7.7,9.6c-1.8,3-3.5,6-5.6,10.2c3.4-3.4,6.2-5.1,9.1-6.3C612.8,185.6,615.8,184.7,619.9,183.9z"
                  />
                </g>
                <g>
                  <path
                    d="M628.3,196.5c4,0.9,7.4,1,10.3,0.8c1.5-0.1,2.9-0.4,4.1-0.8c1.2-0.4,2.4-1,3.1-2c0.7-1,0.8-2.3,0.3-3.8
					c-0.5-1.5-1.8-3-3.7-4.4c-1.9-1.3-4.3-2.3-7-2.7c-2.7-0.4-5.6-0.2-8.3,0.7c-2.8,0.9-4.9,2-6.7,3c-1.8,1-3.3,2.1-4.8,3.1
					c-3,2.1-5.8,4.2-10.1,6.8c4.9-1.3,8.4-1.8,11.8-1.8c1.7,0,3.4,0,5.2,0.2C624.3,195.8,626.3,196,628.3,196.5z"
                  />
                </g>
                <g>
                  <path
                    d="M594.8,198.8c-2.4,0.4-2.8,3.2-1.3,5c1.6,1.9,4.4,1.9,5.2-0.4c0.8-2.4,0.8-3.7,2-5.7
					C598.6,198.6,597.3,198.3,594.8,198.8z"
                  />
                </g>
                <g>
                  <path
                    d="M598.2,200.4c-1.1-0.4-2-0.1-2.7,0.6c-0.7,0.7-1.1,1.8-1,3c0.1,1.2,0.8,2.2,1.7,2.7c0.9,0.5,2.2,0.5,3.2-0.4
					c1-0.9,1.7-1.6,2.3-2.3c0.6-0.7,1.3-1.4,2.2-2.1c-1.2,0-2.1-0.2-2.9-0.4C600.2,201.2,599.3,200.8,598.2,200.4z"
                  />
                </g>
                <g>
                  <path
                    d="M598.6,203c-0.2-0.9-0.7-1.3-1.5-1.4c-0.8-0.1-1.9,0.1-3,0.8c-1,0.6-1.7,1.7-1.6,2.8c0.1,1.1,0.9,2.2,2.2,2.5
					c1.4,0.3,2.4,0.2,3.3,0.1c0.9-0.1,1.8-0.2,2.9-0.2c-0.9-0.7-1.3-1.4-1.6-2.1C599,204.8,598.8,204,598.6,203z"
                  />
                </g>
                <g>
                  <path
                    d="M597,206.3c0.4-0.3,0.6-0.7,0.8-1.1c0.1-0.5,0.1-1,0-1.6c-0.1-0.5-0.4-1.1-0.9-1.4c-0.4-0.4-1-0.6-1.6-0.7
					c-0.6-0.1-1.2-0.1-1.7,0c-0.5,0.1-1,0.2-1.4,0.4c-0.4,0.2-0.7,0.6-0.9,1c-0.2,0.5-0.3,1.1-0.2,1.9c0.1,0.8,0.3,1.5,0.6,2.1
					c0.3,0.6,0.6,1.1,0.9,1.5c0.7,0.8,1.5,1.6,2.7,2c-0.4-0.5-0.5-1-0.4-1.4c0-0.4,0.2-0.8,0.4-1.1c0.2-0.3,0.5-0.6,0.7-0.9
					C596.3,206.7,596.6,206.6,597,206.3z"
                  />
                </g>
                <g>
                  <path
                    d="M594.9,207.2c1.1-0.1,1.7-0.6,2.1-1.5c0.3-0.8,0.5-2,0-3.2c-0.4-1.1-1.4-1.9-2.6-2c-1.2-0.1-2.5,0.5-3.2,1.9
					c-0.7,1.4-0.9,2.4-1.1,3.4c-0.2,1-0.4,1.9-0.7,3.2c0.9-0.8,1.8-1.2,2.7-1.4C592.9,207.4,593.8,207.3,594.9,207.2z"
                  />
                </g>
                <g>
                  <path
                    d="M591.5,207.3c1.4,0.5,2.8,0.1,3.7-0.7c0.9-0.8,1.2-2,0.9-3.2c-0.3-1.2-1.2-2.1-2.1-2.5c-0.9-0.4-2-0.4-3,0.3
					c-1.1,0.7-1.9,1.3-2.7,1.8c-0.8,0.5-1.7,0.9-3.1,1.1c1.2,0.5,2.1,1,3,1.6C589.1,206.1,590.1,206.7,591.5,207.3z"
                  />
                </g>
                <g>
                  <path
                    d="M589.7,203.4c0.6,2.6,3.6,3.1,5.4,1.5c1.8-1.6,1.7-4.6-0.8-5.5c-2.6-1-3.9-1-6.1-2.3
					C589.3,199.5,589.1,200.8,589.7,203.4z"
                  />
                </g>
                <g>
                  <path
                    d="M590.5,199.9c-1.1,2.2,0.8,4.3,3.2,4.2c2.4-0.1,4.2-2.4,2.8-4.5c-1.4-2.2-2.4-3-3.3-5.2
					C592.6,196.7,591.6,197.6,590.5,199.9z"
                  />
                </g>
                <g>
                  <path
                    d="M593.1,198.5c-0.9,0.7-1.1,1.7-0.8,2.5c0.4,0.9,1.3,1.6,2.4,2c1.2,0.3,2.3,0.2,3.1-0.2c0.8-0.4,1.2-1.1,0.9-2.1
					c-0.5-2-1.2-3.1-0.9-5.1C596.3,197,595.1,197.2,593.1,198.5z"
                  />
                </g>
                <g>
                  <path
                    d="M629.5,211.2c3.3,2.5,6.7,4,9.9,4.5c3.2,0.5,6.1,0.1,7.6-1.9c1.5-1.9,0.9-5.1-1.5-8c-2.4-2.9-6.8-5.5-11.7-6
					c-5.1-0.5-8.5-0.1-11.9,0.2c-3.4,0.4-6.7,0.8-11.3,1.5c4.6,0.9,7.6,2.2,10.3,3.7C623.6,206.8,626.1,208.6,629.5,211.2z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M241.1,523.3c3.8,4.2,8.2,7.3,12.1,9.2c3.9,1.8,7.4,2.3,9.1,0.6c1.7-1.7,1.1-5.3-1.4-9.3c-2.6-3.9-7.1-8.1-12.8-10.5
					c-5.8-2.5-10-3.6-14.1-4.8c-4.1-1.1-8.3-2.3-14.2-4.1c5.3,3.1,8.6,5.8,11.6,8.7C234.3,516,237.1,519,241.1,523.3z"
                  />
                </g>
                <g>
                  <path
                    d="M230.3,532.5c1.7,4.7,4.2,8.8,6.9,11.6c2.7,2.8,5.6,4.4,7.9,3.4c2.2-0.9,3.3-4.2,2.6-8.5c-0.7-4.2-3.2-9.4-7.3-13.3
					c-4.2-4-7.5-6.2-10.7-8.5c-3.3-2.2-6.5-4.4-11.1-7.6c3.6,4.3,5.5,7.6,7.1,11C227.3,524.1,228.6,527.6,230.3,532.5z"
                  />
                </g>
                <g>
                  <path
                    d="M215.4,533.7c-0.3,3.7,0.5,7,2,9.6c1.5,2.5,3.7,4.3,6.1,4.2c2.4-0.1,4.5-2.2,5.4-5.4c0.9-3.2,0.5-7.6-1.7-11.3
					c-2.3-3.8-4.3-6.2-6.3-8.5c-2-2.3-3.9-4.5-6.5-7.8c1.3,4,1.6,6.8,1.6,9.7C216,527.1,215.7,529.9,215.4,533.7z"
                  />
                </g>
                <g>
                  <path
                    d="M197.2,528.3c-2.1,2.7-2.8,5.7-2.5,8.4c0.3,2.6,1.7,4.9,4,5.8c2.3,0.9,4.9,0.1,6.9-1.9c2-2,3.4-5.2,3.2-8.8
					c-0.2-3.7-0.7-6.3-1.1-8.9c-0.4-2.5-0.7-5.1-0.8-8.6c-1.4,3.3-2.8,5.4-4.3,7.4C201.1,523.7,199.4,525.5,197.2,528.3z"
                  />
                </g>
                <g>
                  <path
                    d="M184.6,519.6c-3.4,1.1-6,3-7.7,5.3c-1.7,2.3-2.5,4.9-1.5,7.2c0.9,2.2,3.6,3.5,7,3.1c3.3-0.4,7.2-2.4,9.7-5.9
					c2.6-3.6,3.8-6.4,5-9.1c1.2-2.7,2.3-5.4,4-9.1c-2.9,2.8-5.4,4.3-7.8,5.4C190.7,517.6,188.1,518.4,184.6,519.6z"
                  />
                </g>
                <g>
                  <path
                    d="M179.7,503.2c-3.5-1.1-6.6-1.1-9.4-0.5c-0.7,0.2-1.4,0.3-2,0.6c-0.6,0.3-1.2,0.6-1.7,0.9c-1.1,0.7-1.9,1.6-2.3,2.8
					c-0.4,1.1-0.3,2.4,0.3,3.6c0.3,0.6,0.7,1.2,1.3,1.8c0.6,0.6,1.2,1.1,2,1.6c3.1,1.9,8,2.8,12.5,1.3c4.6-1.6,7.4-3.3,10.1-5.1
					c2.6-1.8,5.1-3.7,8.4-6.4c-4.1,1.4-7.1,1.6-9.9,1.4C186,504.9,183.3,504.3,179.7,503.2z"
                  />
                </g>
                <g>
                  <path
                    d="M185.6,484.9c-2.6-3.1-5.7-5.2-8.7-6.3c-3-1.1-5.9-1.1-7.7,0.5c-1.8,1.6-1.9,4.8-0.1,8.1c1.8,3.3,5.4,6.6,10.1,8
					c4.8,1.4,8.2,1.8,11.5,2.1c3.3,0.3,6.5,0.5,11.2,0.9c-4.3-1.8-6.8-3.7-9.1-5.8C190.4,490.4,188.4,488.1,185.6,484.9z"
                  />
                </g>
                <g>
                  <path
                    d="M195.9,472.3c-1.1-4.1-3-7.4-5.1-9.9c-0.6-0.6-1.1-1.2-1.7-1.7c-0.6-0.5-1.2-0.9-1.8-1.2c-1.2-0.6-2.4-0.9-3.6-0.7
					c-1.2,0.3-2.2,1.1-2.8,2.4c-0.3,0.6-0.6,1.4-0.8,2.2c-0.2,0.8-0.3,1.8-0.2,2.8c0.1,4,2,9,5.8,12.6c3.9,3.7,7,5.5,10.1,7.2
					c3.1,1.7,6.2,3.3,10.6,5.4c-3.6-3.3-5.4-6.2-6.8-9.1C198.2,479.5,197.1,476.5,195.9,472.3z"
                  />
                </g>
                <g>
                  <path
                    d="M209.1,469.2c0.9-3.8,1.3-6.8,1.4-9.5c0.1-1.3,0-2.7-0.1-4c-0.1-1.3-0.4-2.5-1.2-3.5c-0.8-0.9-2-1.4-3.6-1.2
					c-1.6,0.2-3.6,1.2-5.3,3c-1.7,1.8-3.1,4.4-3.7,7.3c-0.6,2.9-0.6,6,0.3,8.9c0.9,3,2.1,5.2,3.3,7c1.2,1.8,2.4,3.3,3.6,4.8
					c2.4,2.9,4.9,5.5,8.1,9.5c-2-4.8-2.9-8.3-3.3-11.7c-0.2-1.7-0.3-3.4-0.3-5.2C208.4,473,208.6,471.1,209.1,469.2z"
                  />
                </g>
                <g>
                  <path
                    d="M226.2,472.6c3.1-2.2,5.3-4.3,6.9-6.6c0.8-1.1,1.5-2.3,2.1-3.5c0.5-1.2,0.8-2.4,0.6-3.6c-0.2-1.2-1.1-2.2-2.5-2.8
					c-1.4-0.6-3.3-0.8-5.5-0.4c-2.2,0.4-4.5,1.5-6.6,3.3c-2,1.7-3.8,4-4.8,6.5c-1.1,2.7-1.6,4.9-1.9,6.9c-0.3,2-0.4,3.8-0.5,5.6
					c-0.1,3.5,0,6.9-0.1,11.8c1.5-4.6,3.1-7.6,5.1-10.1c1-1.3,2-2.5,3.2-3.6C223.3,474.9,224.6,473.7,226.2,472.6z"
                  />
                </g>
                <g>
                  <path
                    d="M236.8,481.9c4-0.8,7.3-2.3,9.9-4.3c0.6-0.5,1.2-1,1.7-1.5c0.5-0.5,1-1.1,1.3-1.6c0.7-1.1,1.1-2.3,1-3.6
					c-0.2-1.2-0.9-2.3-2.1-3.1c-0.6-0.4-1.3-0.8-2.1-1c-0.8-0.2-1.7-0.4-2.7-0.5c-3.9-0.3-9.1,1.3-12.8,4.9
					c-3.8,3.7-5.8,6.7-7.7,9.6c-1.8,3-3.5,6-5.6,10.2c3.4-3.4,6.2-5.1,9.1-6.3C229.7,483.6,232.7,482.8,236.8,481.9z"
                  />
                </g>
                <g>
                  <path
                    d="M245.2,494.6c4,0.9,7.4,1,10.3,0.8c1.5-0.1,2.9-0.4,4.1-0.8c1.2-0.4,2.4-1,3.1-2c0.7-1,0.8-2.3,0.3-3.8
					c-0.5-1.5-1.8-3-3.7-4.4c-1.9-1.3-4.3-2.3-7-2.7c-2.7-0.4-5.6-0.2-8.3,0.7c-2.8,0.9-4.9,2-6.7,3c-1.8,1-3.3,2.1-4.8,3.1
					c-3,2.1-5.8,4.2-10.1,6.8c4.9-1.3,8.4-1.8,11.8-1.8c1.7,0,3.4,0,5.2,0.2C241.2,493.9,243.1,494.1,245.2,494.6z"
                  />
                </g>
                <g>
                  <path
                    d="M211.7,496.8c-2.4,0.4-2.8,3.2-1.3,5c1.6,1.9,4.4,1.9,5.2-0.4c0.8-2.4,0.8-3.7,2-5.7
					C215.4,496.7,214.2,496.4,211.7,496.8z"
                  />
                </g>
                <g>
                  <path
                    d="M215,498.4c-1.1-0.4-2-0.1-2.7,0.6c-0.7,0.7-1.1,1.8-1,3c0.1,1.2,0.8,2.2,1.7,2.7c0.9,0.5,2.2,0.5,3.2-0.4
					c1-0.9,1.7-1.6,2.3-2.3c0.6-0.7,1.3-1.4,2.2-2.1c-1.2,0-2.1-0.2-2.9-0.4C217,499.2,216.2,498.9,215,498.4z"
                  />
                </g>
                <g>
                  <path
                    d="M215.5,501.1c-0.2-0.9-0.7-1.3-1.5-1.4c-0.8-0.1-1.9,0.1-3,0.8c-1,0.6-1.7,1.7-1.6,2.8c0.1,1.1,0.9,2.2,2.2,2.5
					c1.4,0.3,2.4,0.2,3.3,0.1c0.9-0.1,1.8-0.2,2.9-0.2c-0.9-0.7-1.3-1.4-1.6-2.1C215.9,502.9,215.7,502.1,215.5,501.1z"
                  />
                </g>
                <g>
                  <path
                    d="M213.9,504.3c0.4-0.3,0.6-0.7,0.8-1.1c0.1-0.5,0.1-1,0-1.6c-0.1-0.5-0.4-1.1-0.9-1.4c-0.4-0.4-1-0.6-1.6-0.7
					c-0.6-0.1-1.2-0.1-1.7,0c-0.5,0.1-1,0.2-1.4,0.4c-0.4,0.2-0.7,0.6-0.9,1c-0.2,0.5-0.3,1.1-0.2,1.9c0.1,0.8,0.3,1.5,0.6,2.1
					c0.3,0.6,0.6,1.1,0.9,1.5c0.7,0.8,1.5,1.6,2.7,2c-0.4-0.5-0.5-1-0.4-1.4c0-0.4,0.2-0.8,0.4-1.1c0.2-0.3,0.5-0.6,0.7-0.9
					C213.1,504.8,213.5,504.6,213.9,504.3z"
                  />
                </g>
                <g>
                  <path
                    d="M211.8,505.3c1.1-0.1,1.7-0.6,2.1-1.5c0.3-0.8,0.5-2,0-3.2c-0.4-1.1-1.4-1.9-2.6-2c-1.2-0.1-2.5,0.5-3.2,1.9
					c-0.7,1.4-0.9,2.4-1.1,3.4c-0.2,1-0.4,1.9-0.7,3.2c0.9-0.8,1.8-1.2,2.7-1.4C209.7,505.4,210.6,505.4,211.8,505.3z"
                  />
                </g>
                <g>
                  <path
                    d="M208.4,505.3c1.4,0.5,2.8,0.1,3.7-0.7c0.9-0.8,1.2-2,0.9-3.2c-0.3-1.2-1.2-2.1-2.1-2.5c-0.9-0.4-2-0.4-3,0.3
					c-1.1,0.7-1.9,1.3-2.7,1.8c-0.8,0.5-1.7,0.9-3.1,1.1c1.2,0.5,2.1,1,3,1.6C206,504.2,206.9,504.8,208.4,505.3z"
                  />
                </g>
                <g>
                  <path
                    d="M206.6,501.5c0.6,2.6,3.6,3.1,5.4,1.5c1.8-1.6,1.7-4.6-0.8-5.5c-2.6-1-3.9-1-6.1-2.3
					C206.1,497.5,205.9,498.8,206.6,501.5z"
                  />
                </g>
                <g>
                  <path
                    d="M207.3,497.9c-1.1,2.2,0.8,4.3,3.2,4.2c2.4-0.1,4.2-2.4,2.8-4.5c-1.4-2.2-2.4-3-3.3-5.2
					C209.5,494.8,208.5,495.6,207.3,497.9z"
                  />
                </g>
                <g>
                  <path
                    d="M210,496.6c-0.9,0.7-1.1,1.7-0.8,2.5c0.4,0.9,1.3,1.6,2.4,2c1.2,0.3,2.3,0.2,3.1-0.2c0.8-0.4,1.2-1.1,0.9-2.1
					c-0.5-2-1.2-3.1-0.9-5.1C213.2,495,211.9,495.2,210,496.6z"
                  />
                </g>
                <g>
                  <path
                    d="M246.4,509.3c3.3,2.5,6.7,4,9.9,4.5c3.2,0.5,6.1,0.1,7.6-1.9c1.5-1.9,0.9-5.1-1.5-8c-2.4-2.9-6.8-5.5-11.7-6
					c-5.1-0.5-8.5-0.1-11.9,0.2c-3.4,0.4-6.7,0.8-11.3,1.5c4.6,0.9,7.6,2.2,10.3,3.7C240.4,504.9,243,506.7,246.4,509.3z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M155.7,208.9c3.8,4.2,8.2,7.3,12.1,9.2c3.9,1.8,7.4,2.3,9.1,0.6c1.7-1.7,1.1-5.3-1.4-9.3c-2.6-3.9-7.1-8.1-12.8-10.5
					c-5.8-2.5-10-3.6-14.1-4.8c-4.1-1.1-8.3-2.3-14.2-4.1c5.3,3.1,8.6,5.8,11.6,8.7C148.9,201.6,151.7,204.6,155.7,208.9z"
                  />
                </g>
                <g>
                  <path
                    d="M144.9,218.2c1.7,4.7,4.2,8.8,6.9,11.6c2.7,2.8,5.6,4.4,7.9,3.4c2.2-0.9,3.3-4.2,2.6-8.5c-0.7-4.2-3.2-9.4-7.3-13.3
					c-4.2-4-7.5-6.2-10.7-8.5c-3.3-2.2-6.5-4.4-11.1-7.6c3.6,4.3,5.5,7.6,7.1,11C141.9,209.8,143.2,213.3,144.9,218.2z"
                  />
                </g>
                <g>
                  <path
                    d="M130,219.4c-0.3,3.7,0.5,7,2,9.6c1.5,2.5,3.7,4.3,6.1,4.2c2.4-0.1,4.5-2.2,5.4-5.4c0.9-3.2,0.5-7.6-1.7-11.3
					c-2.3-3.8-4.3-6.2-6.3-8.5c-2-2.3-3.9-4.5-6.5-7.8c1.3,4,1.6,6.8,1.6,9.7C130.6,212.7,130.3,215.5,130,219.4z"
                  />
                </g>
                <g>
                  <path
                    d="M111.8,214c-2.1,2.7-2.8,5.7-2.5,8.4c0.3,2.6,1.7,4.9,4,5.8c2.3,0.9,4.9,0.1,6.9-1.9c2-2,3.4-5.2,3.2-8.8
					c-0.2-3.7-0.7-6.3-1.1-8.9c-0.4-2.5-0.7-5.1-0.8-8.6c-1.4,3.3-2.8,5.4-4.3,7.4C115.7,209.3,114,211.2,111.8,214z"
                  />
                </g>
                <g>
                  <path
                    d="M99.2,205.2c-3.4,1.1-6,3-7.7,5.3c-1.7,2.3-2.5,4.9-1.5,7.2c0.9,2.2,3.6,3.5,7,3.1c3.3-0.4,7.2-2.4,9.7-5.9
					c2.6-3.6,3.8-6.4,5-9.1c1.2-2.7,2.3-5.4,4-9.1c-2.9,2.8-5.4,4.3-7.8,5.4C105.3,203.2,102.7,204.1,99.2,205.2z"
                  />
                </g>
                <g>
                  <path
                    d="M94.3,188.9c-3.5-1.1-6.6-1.1-9.4-0.5c-0.7,0.2-1.4,0.3-2,0.6c-0.6,0.3-1.2,0.6-1.7,0.9c-1.1,0.7-1.9,1.6-2.3,2.8
					c-0.4,1.1-0.3,2.4,0.3,3.6c0.3,0.6,0.7,1.2,1.3,1.8c0.6,0.6,1.2,1.1,2,1.6c3.1,1.9,8,2.8,12.5,1.3c4.6-1.6,7.4-3.3,10.1-5.1
					c2.6-1.8,5.1-3.7,8.4-6.4c-4.1,1.4-7.1,1.6-9.9,1.4C100.6,190.6,97.9,190,94.3,188.9z"
                  />
                </g>
                <g>
                  <path
                    d="M100.2,170.6c-2.6-3.1-5.7-5.2-8.7-6.3c-3-1.1-5.9-1.1-7.7,0.5c-1.8,1.6-1.9,4.8-0.1,8.1c1.8,3.3,5.4,6.6,10.1,8
					c4.8,1.4,8.2,1.8,11.5,2.1c3.3,0.3,6.5,0.5,11.2,0.9c-4.3-1.8-6.8-3.7-9.1-5.8C105,176.1,103,173.8,100.2,170.6z"
                  />
                </g>
                <g>
                  <path
                    d="M110.5,158c-1.1-4.1-3-7.4-5.1-9.9c-0.6-0.6-1.1-1.2-1.7-1.7c-0.6-0.5-1.2-0.9-1.8-1.2c-1.2-0.6-2.4-0.9-3.6-0.7
					c-1.2,0.3-2.2,1.1-2.8,2.4c-0.3,0.6-0.6,1.4-0.8,2.2c-0.2,0.8-0.3,1.8-0.2,2.8c0.1,4,2,9,5.8,12.6c3.9,3.7,7,5.5,10.1,7.2
					c3.1,1.7,6.2,3.3,10.6,5.4c-3.6-3.3-5.4-6.2-6.8-9.1C112.8,165.2,111.7,162.2,110.5,158z"
                  />
                </g>
                <g>
                  <path
                    d="M123.7,154.9c0.9-3.8,1.3-6.8,1.4-9.5c0.1-1.3,0-2.7-0.1-4c-0.1-1.3-0.4-2.5-1.2-3.5c-0.8-0.9-2-1.4-3.6-1.2
					c-1.6,0.2-3.6,1.2-5.3,3c-1.7,1.8-3.1,4.4-3.7,7.3c-0.6,2.9-0.6,6,0.3,8.9c0.9,3,2.1,5.2,3.3,7c1.2,1.8,2.4,3.3,3.6,4.8
					c2.4,2.9,4.9,5.5,8.1,9.5c-2-4.8-2.9-8.3-3.3-11.7c-0.2-1.7-0.3-3.4-0.3-5.2C123,158.7,123.2,156.8,123.7,154.9z"
                  />
                </g>
                <g>
                  <path
                    d="M140.8,158.2c3.1-2.2,5.3-4.3,6.9-6.6c0.8-1.1,1.5-2.3,2.1-3.5c0.5-1.2,0.8-2.4,0.6-3.6c-0.2-1.2-1.1-2.2-2.5-2.8
					c-1.4-0.6-3.3-0.8-5.5-0.4c-2.2,0.4-4.5,1.5-6.6,3.3c-2,1.7-3.8,4-4.8,6.5c-1.1,2.7-1.6,4.9-1.9,6.9c-0.3,2-0.4,3.8-0.5,5.6
					c-0.1,3.5,0,6.9-0.1,11.8c1.5-4.6,3.1-7.6,5.1-10.1c1-1.3,2-2.5,3.2-3.6C137.9,160.6,139.2,159.4,140.8,158.2z"
                  />
                </g>
                <g>
                  <path
                    d="M151.4,167.6c4-0.8,7.3-2.3,9.9-4.3c0.6-0.5,1.2-1,1.7-1.5c0.5-0.5,1-1.1,1.3-1.6c0.7-1.1,1.1-2.3,1-3.6
					c-0.2-1.2-0.9-2.3-2.1-3.1c-0.6-0.4-1.3-0.8-2.1-1c-0.8-0.2-1.7-0.4-2.7-0.5c-3.9-0.3-9.1,1.3-12.8,4.9
					c-3.8,3.7-5.8,6.7-7.7,9.6c-1.8,3-3.5,6-5.6,10.2c3.4-3.4,6.2-5.1,9.1-6.3C144.2,169.3,147.3,168.5,151.4,167.6z"
                  />
                </g>
                <g>
                  <path
                    d="M159.8,180.2c4,0.9,7.4,1,10.3,0.8c1.5-0.1,2.9-0.4,4.1-0.8c1.2-0.4,2.4-1,3.1-2c0.7-1,0.8-2.3,0.3-3.8
					c-0.5-1.5-1.8-3-3.7-4.4c-1.9-1.3-4.3-2.3-7-2.7c-2.7-0.4-5.6-0.2-8.3,0.7c-2.8,0.9-4.9,2-6.7,3c-1.8,1-3.3,2.1-4.8,3.1
					c-3,2.1-5.8,4.2-10.1,6.8c4.9-1.3,8.4-1.8,11.8-1.8c1.7,0,3.4,0,5.2,0.2C155.8,179.5,157.7,179.8,159.8,180.2z"
                  />
                </g>
                <g>
                  <path
                    d="M126.3,182.5c-2.4,0.4-2.8,3.2-1.3,5c1.6,1.9,4.4,1.9,5.2-0.4c0.8-2.4,0.8-3.7,2-5.7C130,182.3,128.8,182.1,126.3,182.5
					z"
                  />
                </g>
                <g>
                  <path
                    d="M129.6,184.1c-1.1-0.4-2-0.1-2.7,0.6c-0.7,0.7-1.1,1.8-1,3c0.1,1.2,0.8,2.2,1.7,2.7c0.9,0.5,2.2,0.5,3.2-0.4
					c1-0.9,1.7-1.6,2.3-2.3c0.6-0.7,1.3-1.4,2.2-2.1c-1.2,0-2.1-0.2-2.9-0.4C131.6,184.9,130.8,184.5,129.6,184.1z"
                  />
                </g>
                <g>
                  <path
                    d="M130.1,186.7c-0.2-0.9-0.7-1.3-1.5-1.4c-0.8-0.1-1.9,0.1-3,0.8c-1,0.6-1.7,1.7-1.6,2.8c0.1,1.1,0.9,2.2,2.2,2.5
					c1.4,0.3,2.4,0.2,3.3,0.1c0.9-0.1,1.8-0.2,2.9-0.2c-0.9-0.7-1.3-1.4-1.6-2.1C130.5,188.5,130.3,187.7,130.1,186.7z"
                  />
                </g>
                <g>
                  <path
                    d="M128.5,190c0.4-0.3,0.6-0.7,0.8-1.1c0.1-0.5,0.1-1,0-1.6c-0.1-0.5-0.4-1.1-0.9-1.4c-0.4-0.4-1-0.6-1.6-0.7
					c-0.6-0.1-1.2-0.1-1.7,0c-0.5,0.1-1,0.2-1.4,0.4c-0.4,0.2-0.7,0.6-0.9,1c-0.2,0.5-0.3,1.1-0.2,1.9c0.1,0.8,0.3,1.5,0.6,2.1
					c0.3,0.6,0.6,1.1,0.9,1.5c0.7,0.8,1.5,1.6,2.7,2c-0.4-0.5-0.5-1-0.4-1.4c0-0.4,0.2-0.8,0.4-1.1c0.2-0.3,0.5-0.6,0.7-0.9
					C127.7,190.4,128.1,190.3,128.5,190z"
                  />
                </g>
                <g>
                  <path
                    d="M126.4,190.9c1.1-0.1,1.7-0.6,2.1-1.5c0.3-0.8,0.5-2,0-3.2c-0.4-1.1-1.4-1.9-2.6-2c-1.2-0.1-2.5,0.5-3.2,1.9
					c-0.7,1.4-0.9,2.4-1.1,3.4c-0.2,1-0.4,1.9-0.7,3.2c0.9-0.8,1.8-1.2,2.7-1.4C124.3,191.1,125.2,191,126.4,190.9z"
                  />
                </g>
                <g>
                  <path
                    d="M123,191c1.4,0.5,2.8,0.1,3.7-0.7c0.9-0.8,1.2-2,0.9-3.2c-0.3-1.2-1.2-2.1-2.1-2.5c-0.9-0.4-2-0.4-3,0.3
					c-1.1,0.7-1.9,1.3-2.7,1.8c-0.8,0.5-1.7,0.9-3.1,1.1c1.2,0.5,2.1,1,3,1.6C120.6,189.9,121.5,190.4,123,191z"
                  />
                </g>
                <g>
                  <path
                    d="M121.2,187.2c0.6,2.6,3.6,3.1,5.4,1.5c1.8-1.6,1.7-4.6-0.8-5.5c-2.6-1-3.9-1-6.1-2.3
					C120.7,183.2,120.5,184.5,121.2,187.2z"
                  />
                </g>
                <g>
                  <path
                    d="M121.9,183.6c-1.1,2.2,0.8,4.3,3.2,4.2c2.4-0.1,4.2-2.4,2.8-4.5c-1.4-2.2-2.4-3-3.3-5.2
					C124.1,180.4,123.1,181.3,121.9,183.6z"
                  />
                </g>
                <g>
                  <path
                    d="M124.6,182.2c-0.9,0.7-1.1,1.7-0.8,2.5c0.4,0.9,1.3,1.6,2.4,2c1.2,0.3,2.3,0.2,3.1-0.2c0.8-0.4,1.2-1.1,0.9-2.1
					c-0.5-2-1.2-3.1-0.9-5.1C127.8,180.7,126.5,180.9,124.6,182.2z"
                  />
                </g>
                <g>
                  <path
                    d="M161,194.9c3.3,2.5,6.7,4,9.9,4.5c3.2,0.5,6.1,0.1,7.6-1.9c1.5-1.9,0.9-5.1-1.5-8c-2.4-2.9-6.8-5.5-11.7-6
					c-5.1-0.5-8.5-0.1-11.9,0.2c-3.4,0.4-6.7,0.8-11.3,1.5c4.6,0.9,7.6,2.2,10.3,3.7C155,190.5,157.6,192.3,161,194.9z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M353.7,131.6c-6.1,1.4-12.7,4.3-17.8,8c-2.6,1.8-4.9,3.9-7,6c-2,2.1-3.7,4.3-5.1,6.4c-2.8,4.3-4.2,8.4-4.6,11.5
					c-0.2,1.6-0.1,2.9,0.2,3.9c0.3,1,0.9,1.8,1.6,2.2c0.8,0.4,1.7,0.6,2.7,0.4c1-0.2,2.2-0.6,3.5-1.2c2.5-1.2,5.5-3.2,8.8-5.5
					c1.6-1.1,3.4-2.3,5.2-3.6c1.8-1.2,3.8-2.5,5.8-3.8c4-2.7,8.2-5.1,13.1-8c5-3,9.8-5.5,14-8.2c4.2-2.6,8-5.4,11.3-8.5
					c1.7-1.5,3.3-3.1,4.8-4.8c1.6-1.7,3.1-3.5,4.6-5.5c3-3.9,6-8.5,8.6-14.3c-3.6,5.3-7.4,9-11.1,11.8c-1.9,1.4-3.7,2.6-5.5,3.7
					c-1.8,1.1-3.7,2-5.5,2.7c-3.7,1.6-7.5,2.6-11.9,3.5C365,129.4,360,130.1,353.7,131.6z"
                  />
                </g>
                <g>
                  <path
                    d="M362.6,150.6c-5,2.2-10,4.1-14.7,5.9c-4.7,1.7-9.2,3.3-13.1,4.7c-4,1.4-7.4,2.8-9.9,4.2c-2.5,1.4-4.1,2.9-4.3,4.7
					c-0.1,1.7,1.3,3.5,4.2,5c2.8,1.5,7.1,2.5,12.3,2.7c5.2,0.2,11.2-0.6,17.4-2.6c6.1-2.1,12.3-5.3,17.6-9.8
					c5.4-4.6,9.3-9,12.4-13.3c3.1-4.3,5.4-8.3,7.5-12.4c2-4.1,3.8-8.4,5.4-13.2c1.6-4.8,3-10.2,4-16.7c-2,6.2-4.5,11.1-7.2,15.2
					c-2.6,4.1-5.4,7.4-8.4,10.3c-3,3-6.2,5.6-10,8.1C372,145.9,367.8,148.3,362.6,150.6z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M401.3,173.5c-6-1.9-13.1-2.8-19.4-2.2c-3.2,0.3-6.2,0.8-9,1.6c-2.8,0.8-5.4,1.8-7.7,2.9c-4.6,2.3-7.9,5-9.8,7.5
					c-1,1.2-1.6,2.4-1.8,3.5c-0.3,1.1-0.2,2,0.3,2.7c0.4,0.8,1.2,1.3,2.2,1.7c1,0.4,2.2,0.6,3.6,0.8c2.8,0.2,6.3,0.1,10.3-0.2
					c2-0.1,4.1-0.3,6.4-0.4c2.2-0.1,4.5-0.3,6.9-0.4c4.8-0.2,9.7-0.2,15.4-0.2c5.9,0,11.2,0.3,16.2,0.1c5-0.1,9.7-0.6,14.1-1.5
					c2.2-0.4,4.4-1,6.6-1.7c2.2-0.7,4.4-1.4,6.7-2.4c4.6-1.9,9.5-4.3,14.7-7.9c-5.8,2.7-10.9,3.9-15.6,4.5c-2.3,0.3-4.5,0.4-6.7,0.3
					c-2.1,0-4.2-0.2-6.2-0.5c-4-0.5-7.8-1.6-12-3C412.2,177.4,407.6,175.5,401.3,173.5z"
                  />
                </g>
                <g>
                  <path
                    d="M399.3,194.3c-5.4-0.7-10.7-1.6-15.6-2.5c-4.9-0.9-9.6-1.9-13.7-2.7c-4.1-0.8-7.8-1.4-10.7-1.5
					c-2.9-0.1-5.1,0.4-6.1,1.9c-1,1.4-0.6,3.7,1.1,6.4c1.7,2.7,4.8,5.8,9.2,8.6c4.3,2.8,9.9,5.3,16.3,6.7c6.3,1.4,13.3,1.8,20.1,0.6
					c7-1.1,12.6-3,17.5-5c4.8-2.1,8.9-4.4,12.8-6.9c3.9-2.5,7.6-5.3,11.4-8.6c3.8-3.3,7.8-7.3,12-12.3c-4.9,4.3-9.6,7.2-13.9,9.4
					c-4.4,2.2-8.4,3.5-12.5,4.6c-4.1,1-8.2,1.6-12.7,1.9C409.8,195.2,404.9,195.1,399.3,194.3z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M143.2,295.9c4-4.8,7.5-11.1,9.3-17.2c0.9-3.1,1.6-6.1,1.9-9c0.3-2.9,0.3-5.7,0.2-8.2c-0.4-5.1-1.7-9.2-3.3-11.9
					c-0.8-1.4-1.7-2.4-2.6-3c-0.9-0.6-1.8-0.9-2.6-0.8c-0.9,0.1-1.7,0.6-2.4,1.3c-0.7,0.8-1.4,1.8-2,3.1c-1.3,2.5-2.4,5.9-3.7,9.7
					c-0.6,1.9-1.3,3.9-2,6.1c-0.7,2.1-1.4,4.3-2.2,6.6c-1.6,4.6-3.4,9-5.5,14.3c-2.2,5.4-4.4,10.3-6.2,15c-1.7,4.7-3.1,9.2-3.8,13.6
					c-0.4,2.2-0.7,4.5-0.9,6.8c-0.2,2.3-0.3,4.7-0.3,7.1c0,4.9,0.4,10.4,1.8,16.6c-0.4-6.3,0.4-11.6,1.6-16.1
					c0.6-2.3,1.3-4.4,2.2-6.3c0.8-2,1.7-3.8,2.7-5.5c2-3.5,4.4-6.6,7.3-10C135.6,304.6,139.1,300.9,143.2,295.9z"
                  />
                </g>
                <g>
                  <path
                    d="M124.7,286.2c2.7-4.8,5.5-9.3,8.1-13.6c2.7-4.3,5.3-8.2,7.6-11.7c2.3-3.5,4.2-6.7,5.4-9.3c1.2-2.6,1.5-4.9,0.5-6.3
					c-1-1.5-3.2-2-6.4-1.4c-3.2,0.6-7.2,2.3-11.4,5.3c-4.2,3-8.6,7.3-12.2,12.6c-3.6,5.4-6.6,11.7-8.1,18.4
					c-1.5,6.9-1.9,12.8-1.8,18.1c0.1,5.3,0.7,9.9,1.6,14.4c0.9,4.5,2.1,9,3.7,13.8c1.7,4.8,3.8,9.9,7,15.7
					c-2.2-6.2-3.2-11.6-3.5-16.4c-0.4-4.8-0.2-9.2,0.4-13.3c0.6-4.2,1.6-8.2,3-12.5C120,295.7,121.9,291.1,124.7,286.2z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M453.4,473.1c-7.8,2.4-14.6,6.2-19.4,10.2c-4.8,4-7.6,8.2-6.4,11.6c1.2,3.3,6.3,4.8,13,3.8c6.7-1,14.9-4.4,21.5-10.4
					c6.8-6.1,10.9-10.9,14.9-15.6c4-4.7,8-9.4,14-16.1c-7.3,5.1-12.9,7.7-18.4,9.9C467.1,468.8,461.4,470.6,453.4,473.1z"
                  />
                </g>
                <g>
                  <path
                    d="M447.9,453.4c-7.2-0.7-14.1,0.2-19.4,2c-5.3,1.8-9.1,4.7-9.3,8.2c-0.2,3.5,3.5,6.8,9.5,8.5c6,1.7,14.2,1.6,21.8-1.4
					c7.8-3.1,12.7-6,17.6-8.9c4.9-2.9,9.7-5.8,16.7-9.9c-7.8,2.1-13.3,2.5-18.7,2.5C460.7,454.5,455.3,454.1,447.9,453.4z"
                  />
                </g>
                <g>
                  <path
                    d="M455.3,433.2c-4.7-2.7-9.5-3.7-13.7-3.3c-4.2,0.4-7.9,2.2-9.3,5.4c-1.4,3.2,0.1,7.2,3.8,10.4c3.7,3.1,9.6,5.3,15.9,4.7
					c6.4-0.7,10.7-1.9,14.9-3.1c4.2-1.2,8.3-2.4,14.1-3.8c-6-0.7-9.9-2.1-13.6-3.8C463.7,438,460.2,435.9,455.3,433.2z"
                  />
                </g>
                <g>
                  <path
                    d="M473.5,412.7c-2.2-4.4-5.7-7.2-9.4-8.4c-3.7-1.2-7.5-0.8-10.1,1.7c-2.6,2.4-3.1,6.3-1.8,10.2c1.4,3.9,4.7,7.6,9.6,9.6
					c5,2,8.7,2.9,12.3,3.9c3.6,1,7.1,2.1,11.8,4.3c-3.4-3.8-5.3-7-7-10.2C477.3,420.6,475.8,417.2,473.5,412.7z"
                  />
                </g>
                <g>
                  <path
                    d="M492.7,401.5c0.7-5.1-0.3-9.7-2.2-13.3c-1.9-3.6-4.9-6.3-8.4-6.4c-3.5-0.1-6.7,2.6-8.3,7.2c-1.5,4.5-1.3,10.8,1.8,16.3
					c3.2,5.6,6.1,8.9,8.9,12.1c2.8,3.2,5.6,6.3,9.4,10.8c-1.9-5.5-2.3-9.6-2.3-13.6C491.6,410.7,492,406.8,492.7,401.5z"
                  />
                </g>
                <g>
                  <path
                    d="M517.1,405c3.5-3.9,5.5-8,6.4-12c0.2-1,0.4-2,0.4-3c0-1,0-1.9-0.1-2.8c-0.3-1.8-1-3.5-2.2-4.7c-1.3-1.2-2.9-1.9-4.9-1.8
					c-1,0-2.1,0.2-3.1,0.6c-1.1,0.4-2.2,0.9-3.3,1.6c-4.4,2.8-8.5,8.7-9.3,15.5c-0.8,7-0.1,11.7,0.6,16.3c0.8,4.5,1.7,8.9,3.2,15
					c0.6-6.2,2.3-10.2,4.3-13.8C511,412.3,513.5,409,517.1,405z"
                  />
                </g>
                <g>
                  <path
                    d="M537.4,423.9c5.7-1.6,10.3-4.3,13.5-7.6c3.2-3.2,5.1-7.1,4.1-10.4c-1-3.3-5.1-5.3-10.5-5c-5.4,0.3-11.9,3.1-16.6,8.4
					c-4.8,5.4-7.3,9.6-9.8,13.8c-2.3,4.1-4.7,8.2-8.1,14.1c5-4.5,9-6.7,13.1-8.4C527.3,426.9,531.5,425.6,537.4,423.9z"
                  />
                </g>
                <g>
                  <path
                    d="M547.6,445.1c6,1,11.5,0.6,16-0.7c1.1-0.4,2.2-0.7,3.2-1.2c1-0.5,1.9-1,2.7-1.6c1.6-1.2,2.7-2.6,3.1-4.4
					c0.4-1.7-0.1-3.5-1.4-5.2c-0.6-0.8-1.4-1.7-2.5-2.4c-1-0.7-2.2-1.5-3.5-2c-5.2-2.3-13-2.8-20,0c-7.2,2.9-11.5,5.9-15.7,8.8
					c-4.1,3-8.1,6.1-13.5,10.5c6.6-2.7,11.4-3.3,16-3.4C536.8,443.6,541.4,444.1,547.6,445.1z"
                  />
                </g>
                <g>
                  <path
                    d="M543.7,464.2c4.4,3.5,8.1,5.9,11.5,7.7c1.7,0.9,3.5,1.7,5.2,2.3c1.7,0.6,3.5,1,5.3,0.6c1.7-0.4,3.1-1.7,3.8-4
					c0.7-2.2,0.6-5.4-0.8-8.8c-1.3-3.3-3.9-6.8-7.2-9.3c-3.4-2.6-7.5-4.4-11.9-5c-4.5-0.6-8.1-0.4-11.2,0c-3.1,0.4-5.8,1.1-8.4,1.8
					c-5.2,1.4-10.1,3.1-17.4,4.9c7.5,0.3,12.6,1.2,17.3,2.7c2.3,0.8,4.6,1.7,6.9,2.8C539.1,461,541.5,462.4,543.7,464.2z"
                  />
                </g>
                <g>
                  <path
                    d="M528.8,484.6c1,5.4,2.5,9.5,4.4,13.1c1,1.8,2.1,3.4,3.3,4.8c1.2,1.4,2.7,2.5,4.4,2.9c1.7,0.4,3.5,0,5.2-1.5
					c1.7-1.4,3.1-3.9,3.9-7c0.7-3.1,0.7-6.9-0.2-10.6c-1-3.7-2.9-7.4-5.6-10.3c-2.8-3.1-5.4-5.1-7.9-6.8c-2.5-1.7-4.7-2.9-7-4.1
					c-4.6-2.3-9.1-4.3-15.4-7.3c5.1,4.8,8,8.7,10.2,12.8c1.1,2,2,4.1,2.8,6.4C527.6,479.3,528.3,481.8,528.8,484.6z"
                  />
                </g>
                <g>
                  <path
                    d="M510.1,492.7c-1.4,5.7-1.4,11-0.4,15.5c0.3,1.1,0.5,2.2,0.9,3.2c0.4,1,0.8,1.9,1.3,2.7c1,1.6,2.4,2.9,4.1,3.4
					c1.7,0.5,3.5,0.2,5.3-0.9c0.9-0.5,1.8-1.3,2.6-2.2c0.8-0.9,1.6-2,2.3-3.3c2.7-5,3.8-12.7,1.5-19.8c-2.4-7.3-5.2-11.7-7.9-15.9
					c-2.8-4.2-5.7-8.2-10-13.6c2.4,6.5,2.8,11.3,2.7,15.8C512.2,482.3,511.5,486.8,510.1,492.7z"
                  />
                </g>
                <g>
                  <path
                    d="M488.5,496c-3.6,4.8-5.9,9-7.3,13c-0.7,2-1.3,4-1.5,5.9c-0.2,1.9-0.1,3.7,0.8,5.3c0.9,1.5,2.5,2.5,4.7,2.7
					c2.2,0.2,5.1-0.5,7.9-2.1c2.8-1.6,5.7-4.3,7.8-7.6c2.1-3.3,3.6-7.2,4.1-11.2c0.5-4.2,0.4-7.6,0.2-10.6c-0.3-3-0.7-5.6-1.1-8.2
					c-0.9-5.1-1.9-10.1-2.8-17.4c-1.3,7.2-2.8,12.1-4.8,16.5c-1,2.2-2.1,4.4-3.4,6.7C491.8,491.2,490.3,493.6,488.5,496z"
                  />
                </g>
                <g>
                  <path
                    d="M505.9,450.8c0.9-3.4-2.4-5.6-5.8-4.7c-3.4,0.9-5.2,4.5-2.7,7c2.6,2.5,4.3,3.3,6.2,6.1
					C503.8,455.8,505,454.3,505.9,450.8z"
                  />
                </g>
                <g>
                  <path
                    d="M501.8,454.2c1.2-1.2,1.4-2.6,0.9-3.9c-0.5-1.3-1.7-2.5-3.4-3.1c-1.7-0.6-3.4-0.4-4.6,0.6c-1.2,0.9-1.9,2.6-1.4,4.4
					c0.5,1.9,1.1,3.2,1.6,4.5c0.5,1.3,1,2.5,1.4,4.2c0.7-1.6,1.5-2.6,2.4-3.6C499.5,456.3,500.5,455.4,501.8,454.2z"
                  />
                </g>
                <g>
                  <path
                    d="M498,453.2c1.3,0.3,2.1-0.1,2.7-1.2c0.6-1,1-2.6,0.8-4.4c-0.2-1.7-1.2-3.2-2.7-3.8c-1.5-0.6-3.4-0.2-4.6,1.4
					c-1.2,1.6-1.7,3-2.2,4.2c-0.4,1.3-0.8,2.4-1.5,3.9c1.4-0.8,2.6-0.9,3.7-0.9C495.5,452.6,496.6,452.9,498,453.2z"
                  />
                </g>
                <g>
                  <path
                    d="M494.8,449.1c0.1,0.7,0.5,1.2,1,1.7c0.5,0.4,1.3,0.8,2.1,0.9c0.8,0.1,1.7,0.1,2.4-0.3c0.8-0.3,1.4-0.9,1.9-1.6
					c0.5-0.7,0.8-1.5,1.1-2.2c0.2-0.7,0.3-1.4,0.3-2.1c-0.1-0.7-0.3-1.3-0.8-1.8c-0.5-0.6-1.3-1.1-2.4-1.4c-1.1-0.3-2.2-0.5-3.1-0.5
					c-0.9,0-1.7,0.1-2.5,0.3c-1.5,0.4-3,0.9-4.2,2.3c0.8-0.2,1.5,0,2.1,0.3c0.5,0.3,0.9,0.7,1.2,1.2c0.3,0.5,0.5,1,0.7,1.5
					C494.6,447.8,494.6,448.4,494.8,449.1z"
                  />
                </g>
                <g>
                  <path
                    d="M494.8,445.7c-0.6,1.5-0.3,2.6,0.6,3.6c0.9,1,2.4,1.8,4.1,2c1.7,0.2,3.4-0.6,4.2-2.1c0.9-1.5,0.9-3.7-0.5-5.4
					c-1.4-1.8-2.6-2.7-3.8-3.6c-1.2-0.9-2.3-1.7-3.7-2.8c0.5,1.8,0.5,3.1,0.2,4.4C495.9,443,495.4,444.2,494.8,445.7z"
                  />
                </g>
                <g>
                  <path
                    d="M496.9,441.3c-1.5,1.5-1.9,3.6-1.4,5.2c0.5,1.6,1.9,2.8,3.6,3.1c1.7,0.3,3.4-0.2,4.5-1.2c1.1-1,1.7-2.3,1.5-4.1
					c-0.3-1.8-0.6-3.2-0.7-4.6c-0.1-1.4-0.1-2.8,0.5-4.7c-1.5,1.3-2.6,2.1-3.9,3C499.7,438.9,498.4,439.7,496.9,441.3z"
                  />
                </g>
                <g>
                  <path d="M502.9,441.2c-3.7-0.8-6.3,2.7-5.3,6.1c1,3.4,5,5,7.7,2.3c2.8-2.8,3.6-4.5,6.8-6.6C508.4,443.1,506.8,442,502.9,441.2z" />
                </g>
                <g>
                  <path
                    d="M507.1,444.4c-2.2-2.9-6.2-1.6-7.5,1.7c-1.3,3.3,0.6,6.9,4.2,6.5c3.7-0.5,5.4-1.4,8.9-1.1
					C510,449.2,509.4,447.3,507.1,444.4z"
                  />
                </g>
                <g>
                  <path
                    d="M507.3,448.7c-0.3-1.6-1.5-2.5-2.9-2.6c-1.4-0.1-2.9,0.6-4.1,2c-1.2,1.3-1.7,2.9-1.7,4.1c0.1,1.3,0.7,2.2,2.2,2.4
					c3,0.6,4.8,0.4,7.3,1.9C507.4,453.8,507.9,452.1,507.3,448.7z"
                  />
                </g>
                <g>
                  <path
                    d="M468.5,488.6c-5.3,2.7-9.3,6.4-11.9,10.2c-2.6,3.8-3.8,7.9-2.1,11.1c1.6,3.1,6.1,4.3,11.4,2.9c5.3-1.4,11.3-5.5,15-11.7
					c3.7-6.3,5.4-11.1,7-15.7c1.5-4.7,3-9.2,5-15.7c-3.9,5.5-7.5,8.6-11.2,11.3C477.9,483.5,474,485.7,468.5,488.6z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M426.3,566.6c5.8-2.4,11.9-6.2,16.3-10.7c2.3-2.2,4.3-4.6,5.9-7c1.7-2.4,3-4.8,4-7.2c2.1-4.7,2.9-8.9,2.7-12.1
					c-0.1-1.6-0.4-2.9-0.8-3.9c-0.5-1-1.1-1.6-2-1.9c-0.8-0.3-1.8-0.3-2.8,0c-1,0.3-2.1,0.9-3.2,1.7c-2.3,1.6-4.9,4-7.8,6.8
					c-1.4,1.4-3,2.8-4.6,4.4c-1.6,1.5-3.3,3.1-5.1,4.7c-3.5,3.3-7.3,6.4-11.7,10c-4.5,3.7-8.8,7-12.5,10.3c-3.8,3.3-7,6.6-9.9,10.2
					c-1.4,1.8-2.7,3.6-4,5.5c-1.3,1.9-2.5,4-3.7,6.1c-2.3,4.4-4.5,9.3-6.2,15.5c2.7-5.8,5.9-10,9.1-13.4c1.6-1.7,3.2-3.2,4.9-4.5
					c1.6-1.3,3.3-2.5,5-3.6c3.4-2.1,7-3.8,11.2-5.4C415.5,570.6,420.3,569,426.3,566.6z"
                  />
                </g>
                <g>
                  <path
                    d="M414.5,549.3c4.6-2.9,9.2-5.6,13.6-8.1c4.4-2.5,8.6-4.7,12.2-6.7c3.7-2,6.9-3.9,9.1-5.7c2.3-1.8,3.6-3.6,3.5-5.3
					c-0.2-1.7-1.9-3.3-4.9-4.3c-3-1-7.4-1.4-12.6-0.7c-5.1,0.6-11,2.3-16.8,5.3c-5.7,3-11.4,7.2-15.8,12.4
					c-4.6,5.4-7.8,10.4-10.2,15.1c-2.4,4.7-4.1,9.1-5.4,13.5c-1.4,4.4-2.4,8.9-3.2,13.9c-0.8,5-1.3,10.6-1.3,17.1
					c1-6.5,2.7-11.7,4.7-16.1c2-4.4,4.2-8.1,6.7-11.5c2.5-3.4,5.3-6.5,8.5-9.6C405.9,555.4,409.8,552.3,414.5,549.3z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M372.6,532.7c6.2,0.9,13.4,0.7,19.5-0.9c3.1-0.8,6-1.8,8.7-3c2.7-1.2,5-2.6,7.1-4.1c4.2-3,7-6.2,8.5-9
					c0.7-1.4,1.2-2.7,1.2-3.7c0.1-1.1-0.1-2-0.7-2.7c-0.5-0.7-1.4-1.1-2.4-1.4c-1-0.2-2.3-0.3-3.7-0.2c-2.8,0.2-6.3,0.9-10.2,1.8
					c-1.9,0.4-4,0.9-6.2,1.4c-2.2,0.5-4.4,1-6.8,1.5c-4.7,1-9.5,1.8-15.1,2.6c-5.8,0.9-11.1,1.5-16,2.4c-4.9,0.9-9.4,2.1-13.7,3.7
					c-2.1,0.8-4.2,1.7-6.3,2.7c-2.1,1-4.2,2.1-6.3,3.4c-4.2,2.6-8.7,5.7-13.3,10.1c5.3-3.6,10.2-5.6,14.7-6.9
					c2.3-0.6,4.4-1.1,6.5-1.4c2.1-0.3,4.1-0.5,6.2-0.5c4-0.1,7.9,0.3,12.4,1.1C361.2,530.7,366.2,531.8,372.6,532.7z"
                  />
                </g>
                <g>
                  <path
                    d="M371.3,511.9c5.5-0.2,10.8-0.1,15.8,0c5,0.1,9.8,0.3,14,0.5c4.2,0.1,7.9,0.1,10.8-0.2c2.9-0.4,4.9-1.2,5.7-2.8
					c0.7-1.6,0.1-3.8-2.1-6.2c-2.1-2.4-5.7-5-10.4-7.1c-4.7-2.1-10.7-3.6-17.1-4c-6.5-0.3-13.4,0.4-20,2.6c-6.7,2.2-12,4.9-16.5,7.7
					c-4.4,2.8-8.1,5.7-11.6,8.8c-3.4,3.1-6.6,6.4-9.9,10.3c-3.2,3.9-6.6,8.4-9.8,14c4.2-5,8.3-8.7,12.2-11.5c4-2.8,7.8-4.8,11.6-6.5
					c3.9-1.6,7.8-2.9,12.2-3.9C360.8,512.7,365.7,512,371.3,511.9z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M514.4,139c10.6-1.1,21.3-3.7,30.7-7.7c9.4-3.9,17.6-9.3,23.7-14.8c6.1-5.5,10.2-11.1,12.5-15.3
					c2.3-4.2,2.9-6.9,2.1-7.4c-1.6-1-7.7,7.5-19.9,16.2c-6.1,4.3-13.6,8.7-22.2,12.2c-8.6,3.5-18.2,6.3-28.2,8.1
					c-10.2,1.9-19.1,2.6-27.2,3.2c-8.1,0.6-15.5,1.1-23,1.9c-7.4,0.8-14.9,1.9-23.1,4.1c-4.1,1.1-8.3,2.5-12.7,4.4
					c-4.4,1.9-8.9,4.3-13.4,7.6c9.3-6,18.4-8.6,26.5-10.2c8.2-1.5,15.6-1.9,22.9-2c7.4-0.1,14.7,0.2,22.9,0.5
					C494.2,139.9,503.4,140.1,514.4,139z"
                  />
                </g>
                <g>
                  <path
                    d="M458.8,128.6c2.7-1.5,4.5-4,5.1-6.3c0.6-2.3,0-4.4-1.5-5.3c-1.5-0.9-3.5-0.6-5.3,0.4c-1.8,1-3.5,2.7-4.6,5.2
					c-1.1,2.5-2,4.3-3,6c-1,1.7-2.2,3.4-4.4,5.4c2.8-1.1,4.7-1.7,6.8-2.4C454,130.8,456,130.1,458.8,128.6z"
                  />
                </g>
                <g>
                  <path
                    d="M475.9,123.1c2.3-1.3,3.6-3.6,3.8-5.6c0.2-2.1-0.6-3.8-2.1-4.7c-1.6-0.8-3.4-0.6-5,0.2c-1.6,0.8-2.9,2.3-3.5,4.4
					c-0.7,2.2-1.1,3.8-1.7,5.4c-0.6,1.6-1.4,3.1-3,5c2.3-1,3.9-1.6,5.6-2.2C471.8,125,473.6,124.4,475.9,123.1z"
                  />
                </g>
                <g>
                  <path
                    d="M493,119.2c2.2-1.6,3.5-4,3.7-6.1c0.2-2.1-0.5-4-2.1-4.8c-1.5-0.8-3.5-0.5-5.1,0.5c-1.7,1.1-3.1,2.9-3.7,5.3
					c-0.6,2.5-1,4.3-1.5,6.1c-0.5,1.8-1.2,3.6-2.5,5.8c2.2-1.6,3.8-2.5,5.5-3.5C488.9,121.7,490.7,120.8,493,119.2z"
                  />
                </g>
                <g>
                  <path
                    d="M512.9,117.7c2.4-2.2,3.5-5.3,3.4-7.8c-0.1-2.5-1.3-4.3-3-4.6c-1.7-0.4-3.5,0.6-4.9,2c-1.4,1.4-2.5,3.4-2.9,5.9
					c-0.5,2.6-1,4.5-1.6,6.3c-0.7,1.8-1.6,3.7-3.7,5.8c2.7-1.3,4.5-2.3,6.5-3.3C508.4,121,510.4,119.9,512.9,117.7z"
                  />
                </g>
                <g>
                  <path
                    d="M529.5,112.5c2.1-2.5,3.1-5.6,2.9-8.1c-0.1-2.5-1.2-4.4-2.9-4.8c-1.7-0.5-3.6,0.5-5,2.2c-1.5,1.7-2.6,4.1-3,7
					c-0.4,3-0.6,5.1-1,7.2c-0.4,2.1-1,4.3-2.4,7.1c2.3-2.2,4-3.7,5.7-5.2C525.6,116.6,527.3,115.1,529.5,112.5z"
                  />
                </g>
                <g>
                  <path
                    d="M541.5,108.7c2-2,2.9-4.7,2.9-7c-0.1-2.2-1.2-4-2.8-4.5c-1.7-0.5-3.5,0.2-5,1.5c-1.5,1.4-2.6,3.4-2.9,6
					c-0.3,2.6-0.5,4.5-0.8,6.4c-0.3,1.9-0.8,3.8-2,6.3c2.1-1.9,3.6-3.1,5.2-4.3C537.8,112,539.4,110.8,541.5,108.7z"
                  />
                </g>
                <g>
                  <path
                    d="M558.6,100c2.1-2.3,3.1-5.3,3-7.7c0-2.4-1.1-4.3-2.7-4.9c-1.7-0.5-3.6,0.3-5.1,1.9c-1.5,1.6-2.7,4-3.1,6.8
					c-0.4,2.9-0.6,5-0.9,7.1c-0.4,2.1-0.9,4.2-2.2,7c2.2-2.2,3.8-3.7,5.5-5.1C554.7,103.8,556.5,102.4,558.6,100z"
                  />
                </g>
                <g>
                  <path
                    d="M573,93.6c2.2-1.8,3.6-4.1,4.1-6.2c0.5-2.2,0.2-4.2-1.2-5.3c-1.4-1.1-3.5-0.9-5.5,0.3c-2,1.2-3.9,3.5-4.7,6.5
					c-0.8,3-1.2,5.2-1.5,7.3c-0.4,2.1-0.8,4.2-1.6,7.1c1.8-2.4,3.3-3.9,4.9-5.2C569,96.6,570.7,95.4,573,93.6z"
                  />
                </g>
                <g>
                  <path
                    d="M587.3,86.9c2.3-1.1,3.9-2.9,4.8-4.7c0.8-1.8,0.9-3.8-0.1-5.2c-1-1.4-3.1-1.9-5.2-1.2c-2.2,0.6-4.5,2.4-5.7,5.1
					c-1.3,2.7-1.9,4.7-2.5,6.7c-0.6,2-1.2,3.9-2.3,6.5c1.9-2,3.6-3.2,5.3-4.1C583.1,88.9,584.9,88.1,587.3,86.9z"
                  />
                </g>
                <g>
                  <path
                    d="M602.4,93.3c2.3,0.6,4.4,0.3,6.1-0.6c1.7-0.9,3-2.3,3.1-4.1c0.1-1.7-1-3.4-3.1-4.3c-2-0.9-4.8-1-7.4,0.3
					c-2.6,1.3-4.2,2.6-5.7,3.7c-1.5,1.2-3.1,2.4-5.3,3.9c2.6-0.5,4.5-0.5,6.3-0.2C598.3,92.3,600,92.7,602.4,93.3z"
                  />
                </g>
                <g>
                  <path
                    d="M602.3,107.3c2.1,1.2,4.2,1.5,6.1,1.3c1.9-0.3,3.6-1.1,4.4-2.7c0.8-1.6,0.2-3.6-1.6-5.2c-1.8-1.6-4.7-2.6-7.7-2.1
					c-3.1,0.5-5.1,1.4-7,2.2c-2,0.9-3.8,1.7-6.3,2.8c2.8-0.1,4.7,0.4,6.5,1C598.4,105.2,600.1,106.1,602.3,107.3z"
                  />
                </g>
                <g>
                  <path
                    d="M598.1,120.2c2.4,1.8,5,2.6,7.4,2.6c2.3,0,4.2-0.9,4.9-2.5c0.7-1.6-0.1-3.6-1.8-5.3c-1.7-1.6-4.4-2.9-7.5-3.1
					c-6.3-0.3-8.9,0.5-15.1-0.2C591.6,114.5,593.3,116.5,598.1,120.2z"
                  />
                </g>
                <g>
                  <path
                    d="M455.6,157.1c1.3,2.2,2.9,3.7,4.7,4.7c1.8,0.9,3.8,1.2,5.3,0.3c1.5-0.9,2.1-3,1.3-5.4c-0.7-2.4-2.8-5-5.7-6.3
					c-3-1.3-5.3-1.7-7.4-2.1c-2.2-0.4-4.3-0.7-7.2-1.1c2.6,1.4,4.1,2.9,5.4,4.4C453.2,153.1,454.3,154.8,455.6,157.1z"
                  />
                </g>
                <g>
                  <path
                    d="M472.5,157.1c1.1,2.4,2.4,4.3,3.9,5.8c1.5,1.5,3.1,2.7,4.9,2.5c0.9-0.1,1.6-0.6,2.2-1.6c0.6-0.9,0.9-2.2,0.8-3.8
					c-0.1-1.5-0.5-3.3-1.5-4.9c-0.9-1.6-2.3-3.2-4-4.3c-1.8-1.1-3.4-1.8-4.8-2.2c-1.5-0.4-2.8-0.7-4.1-0.8c-2.6-0.3-5.1-0.3-8.6,0.2
					c3.4,0.6,5.5,1.9,7.1,3.3C470,152.9,471.3,154.7,472.5,157.1z"
                  />
                </g>
                <g>
                  <path
                    d="M487.8,156.6c1.5,2.4,3.4,4.2,5.4,5.3c1.9,1.1,4,1.5,5.5,0.6c1.5-0.9,2-3.1,1.1-5.7c-0.9-2.6-3.1-5.4-6.4-6.9
					c-3.3-1.5-5.7-2.1-8.1-2.5c-2.4-0.5-4.7-0.9-8-1.4c3,1.5,4.7,3.1,6.1,4.7C484.9,152.4,486.2,154.1,487.8,156.6z"
                  />
                </g>
                <g>
                  <path
                    d="M506.5,159.3c2.5,2.3,5.7,3.4,8.3,3.4c2.6,0,4.6-1.1,5.1-2.7c0.5-1.7-0.5-3.6-2.2-5.1c-1.7-1.6-4.2-2.8-7.2-3.3
					c-3-0.5-5.2-0.9-7.4-1.4c-2.2-0.5-4.3-1.3-7.2-2.9c2.2,2.5,3.6,4.3,5.1,6.1C502.5,155.1,504,157,506.5,159.3z"
                  />
                </g>
                <g>
                  <path
                    d="M525.3,155.5c3.6,1.7,7.5,2.2,10.5,1.7c3-0.5,5.1-1.9,5.2-3.6c0.2-1.8-1.5-3.5-4.1-4.7c-2.6-1.2-6-2-9.7-2
					c-3.8,0.1-6.5,0.2-9.2,0.1c-2.7-0.1-5.5-0.3-9.4-1.3c3.5,1.9,5.9,3.4,8.3,4.9C519.2,152.2,521.6,153.7,525.3,155.5z"
                  />
                </g>
                <g>
                  <path
                    d="M549,150.1c6.4,2.5,13.2,0.2,13.4-3.3c0.2-3.5-6-6.6-12.5-5.4c-6.7,1.1-9.4,2.3-16.4,2.3
					C540.3,145.7,542.5,147.5,549,150.1z"
                  />
                </g>
                <g>
                  <path
                    d="M568.8,144.1c3.4,1.2,7.1,1.2,9.7,0.4c2.7-0.8,4.5-2.3,4.5-4.1c0-1.8-1.7-3.3-4.2-4.3c-2.5-1-5.8-1.4-9.2-0.8
					c-3.5,0.6-6,1.1-8.5,1.5c-2.5,0.4-5.1,0.6-8.8,0.4c3.5,1.2,5.9,2.3,8.2,3.4C562.9,141.7,565.2,142.8,568.8,144.1z"
                  />
                </g>
                <g>
                  <path
                    d="M580.9,135.9c1.6,1.6,3.5,2.2,5.3,2.2c1.8,0,3.5-0.8,4.4-2.3c0.9-1.5,0.7-3.5-0.5-5.1c-1.2-1.6-3.4-2.9-6.1-3
					c-2.7,0-4.6,0.3-6.4,0.5c-1.8,0.3-3.6,0.5-6,0.6c2.3,0.9,3.7,1.9,5,3C578.1,133,579.3,134.2,580.9,135.9z"
                  />
                </g>
                <g>
                  <path
                    d="M591.4,129.9c3.6,4.4,9.6,5,11.5,2c1.9-3-1.3-8.1-6.8-9.4c-5.7-1.4-8.2-1.2-13.6-3.3
					C586.8,123.1,587.7,125.4,591.4,129.9z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M191.4,346.1c2,10.5,5.5,20.9,10.2,29.9c4.7,9.1,10.8,16.7,16.8,22.3c6,5.6,11.9,9.2,16.3,11.1c4.4,1.9,7.2,2.3,7.5,1.5
					c0.9-1.7-8.1-7.1-17.8-18.4c-4.8-5.7-9.8-12.8-14-21.1c-4.2-8.3-7.8-17.6-10.5-27.4c-2.8-10-4.2-18.8-5.5-26.9
					c-1.3-8.1-2.4-15.4-3.8-22.7c-1.4-7.3-3.2-14.7-6-22.6c-1.4-4-3.2-8.1-5.4-12.3c-2.2-4.2-5.1-8.5-8.7-12.7
					c6.8,8.8,10.2,17.6,12.4,25.5c2.2,8,3.2,15.3,4,22.6c0.7,7.3,1,14.6,1.5,22.8C188.8,326.1,189.3,335.3,191.4,346.1z"
                  />
                </g>
                <g>
                  <path
                    d="M197,289.9c1.7,2.6,4.4,4.1,6.8,4.5c2.4,0.4,4.4-0.3,5.2-1.9c0.8-1.6,0.3-3.5-0.8-5.3c-1.2-1.7-3-3.3-5.5-4.1
					c-2.6-0.9-4.5-1.6-6.3-2.5c-1.8-0.9-3.6-1.9-5.7-3.9c1.3,2.7,2.1,4.6,3,6.6C194.4,285.3,195.3,287.3,197,289.9z"
                  />
                </g>
                <g>
                  <path
                    d="M204,306.5c1.5,2.2,3.9,3.3,5.9,3.4c2.1,0.1,3.8-0.9,4.5-2.5c0.7-1.6,0.3-3.5-0.6-4.9c-1-1.5-2.5-2.6-4.7-3.1
					c-2.2-0.5-3.9-0.8-5.6-1.3c-1.6-0.5-3.2-1.2-5.2-2.6c1.2,2.2,1.9,3.8,2.7,5.4C201.7,302.5,202.5,304.3,204,306.5z"
                  />
                </g>
                <g>
                  <path
                    d="M209.3,323.1c1.8,2.1,4.2,3.1,6.4,3.2c2.1,0.1,3.9-0.8,4.6-2.5c0.7-1.6,0.2-3.5-1-5.1c-1.2-1.6-3.1-2.8-5.6-3.2
					c-2.5-0.4-4.4-0.6-6.2-1c-1.8-0.4-3.6-0.9-6-2c1.7,2,2.8,3.6,3.9,5.2C206.4,319.3,207.5,321,209.3,323.1z"
                  />
                </g>
                <g>
                  <path
                    d="M212.5,342.8c2.4,2.2,5.6,3,8,2.7c2.5-0.3,4.1-1.7,4.3-3.4c0.2-1.8-0.9-3.4-2.4-4.7c-1.5-1.3-3.6-2.2-6.1-2.4
					c-2.6-0.3-4.5-0.6-6.4-1.1c-1.9-0.5-3.8-1.3-6.1-3.2c1.5,2.5,2.6,4.3,3.8,6.2C208.8,338.7,210.1,340.6,212.5,342.8z"
                  />
                </g>
                <g>
                  <path
                    d="M219.1,359c2.6,1.9,5.8,2.6,8.3,2.2c2.5-0.3,4.3-1.6,4.6-3.3c0.3-1.7-0.8-3.5-2.7-4.8c-1.8-1.3-4.4-2.3-7.3-2.4
					c-3-0.1-5.1-0.1-7.3-0.4c-2.2-0.2-4.3-0.6-7.3-1.8c2.4,2.1,4,3.6,5.6,5.2C214.7,355.4,216.3,357,219.1,359z"
                  />
                </g>
                <g>
                  <path
                    d="M223.9,370.7c2.2,1.9,5,2.5,7.2,2.2c2.2-0.3,3.9-1.5,4.3-3.2c0.4-1.7-0.5-3.5-2-4.8c-1.5-1.4-3.7-2.3-6.2-2.3
					c-2.6-0.1-4.6-0.1-6.5-0.2c-1.9-0.2-3.8-0.5-6.4-1.5c2,1.9,3.4,3.4,4.7,4.9C220.3,367.2,221.6,368.7,223.9,370.7z"
                  />
                </g>
                <g>
                  <path
                    d="M234,386.9c2.5,1.9,5.5,2.6,7.9,2.4c2.4-0.2,4.2-1.4,4.6-3.1c0.4-1.7-0.6-3.5-2.4-4.9c-1.8-1.4-4.2-2.4-7.1-2.5
					c-3-0.1-5.1-0.2-7.2-0.3c-2.1-0.2-4.2-0.5-7.2-1.6c2.4,2,4,3.5,5.6,5C229.9,383.4,231.4,385,234,386.9z"
                  />
                </g>
                <g>
                  <path
                    d="M241.6,400.7c2,2.1,4.3,3.2,6.5,3.5c2.2,0.3,4.2-0.2,5.2-1.7c1-1.5,0.6-3.6-0.8-5.5c-1.4-1.9-3.8-3.6-6.8-4.2
					c-3.1-0.6-5.3-0.7-7.4-0.9c-2.1-0.2-4.3-0.4-7.2-1c2.6,1.6,4.2,3,5.6,4.5C238.2,397,239.6,398.6,241.6,400.7z"
                  />
                </g>
                <g>
                  <path
                    d="M249.5,414.4c1.3,2.2,3.2,3.7,5.1,4.3c1.9,0.7,3.9,0.6,5.2-0.6c1.3-1.2,1.6-3.2,0.7-5.3c-0.8-2.1-2.8-4.2-5.5-5.3
					c-2.8-1.1-4.9-1.5-6.9-1.9c-2-0.4-3.9-0.9-6.6-1.7c2.2,1.8,3.5,3.3,4.6,4.9C247.1,410.4,248.1,412.1,249.5,414.4z"
                  />
                </g>
                <g>
                  <path
                    d="M244.4,430c-0.4,2.4,0.1,4.4,1.1,6c1,1.6,2.6,2.7,4.3,2.7c1.8,0,3.3-1.3,4-3.4c0.7-2.1,0.6-4.9-0.9-7.3
					c-1.5-2.5-2.9-4-4.2-5.4c-1.3-1.4-2.6-2.9-4.3-4.9c0.7,2.6,0.8,4.4,0.7,6.2C245.1,425.8,244.8,427.6,244.4,430z"
                  />
                </g>
                <g>
                  <path
                    d="M230.5,431.1c-1,2.2-1.2,4.3-0.7,6.2c0.4,1.9,1.4,3.5,3.1,4.2c1.6,0.6,3.6-0.1,5-2.1c1.4-1.9,2.2-4.9,1.4-7.9
					c-0.8-3-1.8-4.9-2.8-6.8c-1-1.9-2-3.7-3.3-6.1c0.3,2.8,0,4.7-0.4,6.6C232.2,427,231.5,428.8,230.5,431.1z"
                  />
                </g>
                <g>
                  <path
                    d="M217.3,428c-1.6,2.5-2.2,5.3-1.9,7.5c0.2,2.3,1.2,4.1,2.9,4.7c1.7,0.5,3.6-0.4,5.1-2.2c1.5-1.9,2.5-4.7,2.4-7.7
					c-0.3-6.3-1.2-8.8-1.1-15C222.4,421,220.5,422.8,217.3,428z"
                  />
                </g>
                <g>
                  <path
                    d="M168.4,289.1c-2.1,1.5-3.5,3.2-4.2,5.1c-0.7,1.9-0.9,3.9,0.2,5.3c1,1.4,3.2,1.8,5.5,0.9c2.3-0.9,4.7-3.2,5.8-6.3
					c1.1-3.2,1.3-5.4,1.5-7.6c0.2-2.2,0.3-4.3,0.4-7.3c-1.2,2.7-2.5,4.3-3.9,5.7C172.1,286.4,170.5,287.6,168.4,289.1z"
                  />
                </g>
                <g>
                  <path
                    d="M169.7,305.9c-2.3,1.3-4,2.7-5.5,4.3c-1.4,1.6-2.5,3.4-2.1,5.1c0.2,0.9,0.8,1.6,1.7,2c1,0.5,2.3,0.7,3.8,0.5
					c1.5-0.2,3.2-0.8,4.8-1.9c1.5-1.1,3-2.6,3.9-4.4c1-1.9,1.5-3.5,1.8-5c0.3-1.5,0.4-2.8,0.5-4.1c0-2.6-0.1-5.2-0.9-8.6
					c-0.3,3.5-1.4,5.6-2.7,7.4C173.7,303.1,172.1,304.6,169.7,305.9z"
                  />
                </g>
                <g>
                  <path
                    d="M171.5,321.1c-2.3,1.7-3.9,3.7-4.8,5.8c-0.9,2-1.2,4.1-0.1,5.5c1,1.4,3.3,1.7,5.8,0.6c2.5-1.1,5.1-3.6,6.3-6.9
					c1.2-3.4,1.6-5.9,1.8-8.3c0.3-2.4,0.5-4.7,0.7-8.1c-1.3,3.1-2.6,4.9-4.1,6.5C175.6,317.9,173.9,319.3,171.5,321.1z"
                  />
                </g>
                <g>
                  <path
                    d="M170.5,340.1c-2.1,2.7-2.9,6-2.6,8.5c0.2,2.6,1.4,4.5,3.2,4.8c1.7,0.3,3.5-0.8,4.9-2.6c1.4-1.9,2.4-4.4,2.7-7.4
					c0.3-3.1,0.4-5.3,0.8-7.5c0.4-2.2,0.9-4.4,2.3-7.4c-2.3,2.4-3.9,4-5.6,5.6C174.4,335.7,172.6,337.3,170.5,340.1z"
                  />
                </g>
                <g>
                  <path
                    d="M175.9,358.4c-1.4,3.7-1.5,7.7-0.8,10.6c0.7,3,2.3,4.9,4,4.9c1.8,0,3.3-1.8,4.3-4.5c1-2.7,1.5-6.2,1.1-9.9
					c-0.4-3.8-0.7-6.5-0.9-9.2c-0.2-2.7-0.2-5.5,0.4-9.4c-1.6,3.7-2.9,6.2-4.2,8.7C178.7,352.1,177.4,354.6,175.9,358.4z"
                  />
                </g>
                <g>
                  <path
                    d="M183.3,381.6c-1.9,6.6,0.9,13.2,4.4,13.1c3.5-0.1,6-6.5,4.4-12.9c-1.7-6.6-3.1-9.1-3.7-16.1
					C186.9,372.5,185.3,374.9,183.3,381.6z"
                  />
                </g>
                <g>
                  <path
                    d="M190.9,400.8c-0.9,3.5-0.6,7.1,0.4,9.7c1,2.6,2.7,4.3,4.5,4.1c1.8-0.1,3.2-2,3.9-4.5c0.8-2.6,0.9-5.9,0-9.3
					c-0.9-3.5-1.6-5.9-2.2-8.4c-0.6-2.5-1-5-1.2-8.7c-0.9,3.6-1.8,6-2.6,8.5C192.8,394.7,191.9,397.2,190.9,400.8z"
                  />
                </g>
                <g>
                  <path
                    d="M200.2,412.2c-1.4,1.7-1.9,3.7-1.8,5.5c0.2,1.8,1.1,3.4,2.7,4.2c1.6,0.8,3.5,0.4,5-1c1.5-1.3,2.6-3.7,2.5-6.3
					c-0.2-2.7-0.6-4.5-1.1-6.3c-0.4-1.8-0.8-3.5-1.1-6c-0.7,2.4-1.6,3.9-2.6,5.3C202.8,409.1,201.7,410.4,200.2,412.2z"
                  />
                </g>
                <g>
                  <path d="M207,422.2c-4,4-4.1,10-1,11.6c3.1,1.6,8-2,8.8-7.6c0.9-5.8,0.5-8.2,2.1-13.8C213.4,417,211.1,418.1,207,422.2z" />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M378.5,8.1c9.5-5,18.3-11.4,25.5-18.6c7.3-7.2,12.8-15.2,16.3-22.6c3.6-7.4,5.3-14.1,5.8-18.9c0.5-4.8,0-7.5-0.8-7.6
					c-1.9-0.3-4.3,9.8-12.3,22.5c-4,6.3-9.3,13.2-16,19.6c-6.6,6.5-14.6,12.7-23,18.1c-8.7,5.6-16.7,9.6-24,13.2
					c-7.3,3.6-14,6.8-20.6,10.4c-6.6,3.6-13.1,7.4-19.8,12.5c-3.4,2.5-6.8,5.4-10.1,8.8c-3.4,3.4-6.6,7.4-9.6,12
					c6.4-9.1,13.8-14.9,20.7-19.4c7-4.5,13.7-7.6,20.5-10.5c6.8-2.8,13.7-5.3,21.4-8.2C360.1,16.5,368.7,13.3,378.5,8.1z"
                  />
                </g>
                <g>
                  <path
                    d="M323.1,19.3c2-2.4,2.7-5.4,2.3-7.8c-0.3-2.4-1.6-4.1-3.3-4.4c-1.7-0.3-3.5,0.7-4.8,2.3c-1.3,1.6-2.2,3.9-2.3,6.5
					c-0.1,2.7-0.2,4.7-0.5,6.7c-0.3,2-0.8,4-2.1,6.6c2.2-2,3.7-3.4,5.4-4.8C319.4,23.2,321.1,21.8,323.1,19.3z"
                  />
                </g>
                <g>
                  <path
                    d="M336.9,7.8c1.6-2.1,2-4.7,1.5-6.6c-0.5-2-2-3.4-3.7-3.5c-1.8-0.2-3.4,0.7-4.5,2.1c-1.1,1.4-1.8,3.2-1.6,5.4
					c0.2,2.3,0.4,4,0.4,5.7c0,1.7-0.1,3.4-1,5.8c1.7-1.8,3-3,4.4-4.2C333.8,11.1,335.2,9.9,336.9,7.8z"
                  />
                </g>
                <g>
                  <path
                    d="M351.2-2.2c1.5-2.3,1.7-5,1.1-7c-0.6-2.1-2-3.5-3.7-3.7c-1.8-0.2-3.4,0.8-4.6,2.4c-1.1,1.6-1.8,3.8-1.4,6.3
					c0.4,2.6,0.7,4.4,0.9,6.3c0.2,1.9,0.3,3.7-0.1,6.4c1.4-2.3,2.6-3.8,3.8-5.3C348.4,1.6,349.7,0.1,351.2-2.2z"
                  />
                </g>
                <g>
                  <path
                    d="M369.1-11.1c1.4-2.9,1.2-6.2,0.2-8.4c-1-2.3-2.9-3.4-4.6-3.1c-1.7,0.3-3,1.8-3.8,3.7c-0.8,1.9-1,4.1-0.5,6.5
					c0.5,2.6,0.8,4.5,0.9,6.5c0,2-0.1,4-1.2,6.8c2-2.2,3.4-3.8,4.8-5.5C366.2-6.4,367.6-8.1,369.1-11.1z"
                  />
                </g>
                <g>
                  <path
                    d="M382.6-22.2c1-3.1,0.7-6.3-0.3-8.6c-1-2.3-2.8-3.6-4.5-3.4c-1.8,0.2-3.1,1.8-3.8,4c-0.7,2.1-0.9,4.8-0.1,7.6
					c0.8,2.9,1.4,5,1.8,7.1c0.4,2.1,0.7,4.3,0.4,7.5c1.3-2.9,2.3-4.9,3.3-6.9C380.4-16.9,381.5-19,382.6-22.2z"
                  />
                </g>
                <g>
                  <path
                    d="M392.3-30.2c1.1-2.7,1-5.5,0-7.5c-0.9-2-2.6-3.3-4.3-3.1c-1.8,0.1-3.2,1.5-4,3.3c-0.8,1.8-1.1,4.2-0.4,6.6
					c0.7,2.5,1.3,4.4,1.7,6.3c0.4,1.9,0.7,3.8,0.5,6.6c1.2-2.5,2.2-4.2,3.2-5.9C390-25.7,391.1-27.5,392.3-30.2z"
                  />
                </g>
                <g>
                  <path
                    d="M404.8-44.7c1.1-3,0.9-6-0.1-8.3c-1-2.2-2.6-3.6-4.4-3.5c-1.8,0.1-3.2,1.6-4,3.7c-0.8,2.1-1,4.7-0.3,7.5
					c0.8,2.9,1.4,4.9,1.8,7c0.4,2.1,0.8,4.2,0.6,7.3c1.2-2.9,2.1-4.8,3.1-6.8C402.6-39.7,403.7-41.7,404.8-44.7z"
                  />
                </g>
                <g>
                  <path
                    d="M415.7-56.1c1.4-2.5,1.8-5.1,1.4-7.3c-0.3-2.2-1.4-3.9-3.1-4.4c-1.7-0.5-3.6,0.4-5,2.3c-1.4,1.9-2.3,4.7-1.9,7.8
					c0.4,3.1,0.9,5.2,1.3,7.3c0.5,2.1,0.9,4.2,1.2,7.2c0.8-2.9,1.6-4.9,2.6-6.7C413.2-51.8,414.3-53.5,415.7-56.1z"
                  />
                </g>
                <g>
                  <path
                    d="M426.4-67.6c1.7-1.9,2.5-4.1,2.6-6.1c0.1-2-0.6-3.9-2.1-4.8c-1.5-0.9-3.5-0.6-5.3,0.9c-1.8,1.4-3.2,3.9-3.4,6.8
					c-0.2,3,0,5.1,0.2,7.1c0.2,2,0.3,4,0.3,6.9c1-2.6,2.1-4.3,3.3-5.8C423.3-64.2,424.7-65.6,426.4-67.6z"
                  />
                </g>
                <g>
                  <path
                    d="M442.9-67.4c2.4-0.3,4.2-1.4,5.4-2.9c1.2-1.4,1.9-3.2,1.3-4.9c-0.5-1.7-2.2-2.8-4.4-2.8c-2.2-0.1-4.8,0.9-6.7,3.1
					c-1.9,2.2-2.9,3.9-3.9,5.6c-1,1.7-2,3.3-3.4,5.6c2.2-1.5,4-2.1,5.7-2.6C438.6-66.8,440.4-67.1,442.9-67.4z"
                  />
                </g>
                <g>
                  <path
                    d="M448-54.4c2.4,0.3,4.5-0.2,6.2-1.1c1.7-1,3-2.4,3.1-4.2c0.1-1.7-1.2-3.4-3.4-4.2c-2.2-0.8-5.3-0.6-7.9,1
					c-2.7,1.7-4.2,3.2-5.7,4.6c-1.5,1.6-2.9,3-4.8,5c2.6-1.1,4.5-1.4,6.4-1.5C443.6-54.9,445.5-54.7,448-54.4z"
                  />
                </g>
                <g>
                  <path
                    d="M449-40.9c2.9,0.8,5.7,0.5,7.8-0.4c2.1-0.9,3.6-2.4,3.6-4.2c0-1.8-1.4-3.3-3.6-4.2c-2.2-0.9-5.2-1-8.1,0
					c-6,2.1-8,3.8-14,5.5C440.8-43.7,443.1-42.4,449-40.9z"
                  />
                </g>
                <g>
                  <path
                    d="M330.8,46.9c2,1.6,4.1,2.4,6.1,2.5c2,0.2,3.9-0.3,5-1.7c1-1.4,0.8-3.5-0.8-5.5c-1.5-1.9-4.4-3.6-7.7-3.6
					c-3.3-0.1-5.5,0.4-7.7,0.8c-2.1,0.5-4.2,1-7.1,1.7c3,0.3,4.9,1.1,6.6,2C327.1,44.2,328.7,45.3,330.8,46.9z"
                  />
                </g>
                <g>
                  <path
                    d="M346.5,40.6c1.9,1.8,3.8,3.1,5.8,3.9c1.9,0.8,3.9,1.4,5.5,0.5c0.8-0.4,1.3-1.2,1.4-2.3c0.2-1.1,0-2.4-0.7-3.8
					c-0.6-1.4-1.7-2.8-3.2-4c-1.5-1.2-3.3-2.1-5.3-2.4c-2.1-0.4-3.8-0.4-5.3-0.3c-1.5,0.1-2.8,0.4-4.1,0.8c-2.5,0.7-4.9,1.6-7.9,3.4
					c3.4-0.8,5.8-0.3,7.9,0.4C342.6,37.7,344.5,38.8,346.5,40.6z"
                  />
                </g>
                <g>
                  <path
                    d="M360.5,34.4c2.3,1.7,4.7,2.6,6.9,2.9c2.2,0.3,4.3-0.1,5.3-1.5c1-1.4,0.7-3.6-1.1-5.7c-1.8-2.1-4.9-3.8-8.5-4
					c-3.6-0.2-6.1,0.3-8.5,0.7c-2.4,0.5-4.7,1-7.9,1.7c3.3,0.3,5.5,1.1,7.4,2C356.2,31.5,358.1,32.7,360.5,34.4z"
                  />
                </g>
                <g>
                  <path
                    d="M378.8,29.8c3.2,1.2,6.5,1,8.9,0c2.4-1,3.8-2.7,3.7-4.4c-0.2-1.8-1.8-3.1-4-3.9c-2.2-0.8-5-1-7.9-0.4
					c-3,0.7-5.2,1.2-7.4,1.5c-2.2,0.3-4.5,0.4-7.8,0c3,1.5,5,2.6,7,3.7C373.5,27.4,375.6,28.6,378.8,29.8z"
                  />
                </g>
                <g>
                  <path
                    d="M394.8,19.2c4,0.3,7.8-0.8,10.4-2.4c2.6-1.6,4-3.6,3.5-5.3c-0.5-1.7-2.7-2.6-5.6-2.8c-2.9-0.2-6.4,0.4-9.8,1.9
					c-3.5,1.5-6,2.6-8.5,3.6c-2.5,1-5.2,1.8-9.2,2.4c4,0.5,6.7,0.9,9.5,1.4C387.9,18.4,390.7,19,394.8,19.2z"
                  />
                </g>
                <g>
                  <path
                    d="M414.8,5.3c6.9-0.1,12.4-4.8,11.2-8.1c-1.2-3.3-8-3.8-13.6-0.3c-5.8,3.6-7.8,5.6-14.3,8.3C405,4.5,407.7,5.3,414.8,5.3z
					"
                  />
                </g>
                <g>
                  <path
                    d="M430.8-7.7c3.7-0.2,7-1.5,9.2-3.3c2.2-1.7,3.3-3.8,2.6-5.5c-0.7-1.6-2.8-2.4-5.5-2.4c-2.7,0-5.9,0.9-8.8,2.7
					c-3,1.9-5.1,3.3-7.3,4.6c-2.2,1.3-4.5,2.5-8,3.7c3.7-0.2,6.3-0.1,8.9,0C424.5-7.7,427.1-7.5,430.8-7.7z"
                  />
                </g>
                <g>
                  <path
                    d="M439-19.9c2.1,0.9,4.1,0.8,5.8,0c1.6-0.7,3-2.1,3.3-3.8c0.3-1.7-0.7-3.5-2.4-4.5c-1.7-1.1-4.3-1.4-6.7-0.5
					c-2.5,1-4.1,2-5.7,2.9c-1.6,0.9-3.1,1.8-5.4,2.9c2.5,0,4.2,0.4,5.8,0.9C435.2-21.5,436.8-20.8,439-19.9z"
                  />
                </g>
                <g>
                  <path d="M446.5-29.3c5,2.7,10.8,1,11.4-2.5c0.6-3.5-4.3-7-9.9-6.2c-5.8,0.9-8,2-13.8,2C439.6-33.9,441.3-32.1,446.5-29.3z" />
                </g>
              </g>
              <g>
                <path
                  d="M533.7,299.2c0.6-0.6,1.1-1.2,1.6-1.8c0.5-0.7,1-1.3,1.5-2c0.9-1.4,1.8-2.8,2.4-4.3c1.4-3,2.2-6.2,2.6-9.4
				c0.8-6.4-0.3-12.8-2.8-18.6c-1.2-2.9-3-5.7-5-8.1c-2-2.4-4.2-4.5-6.6-6.4c-4.8-3.6-10.2-6.1-15.6-7.6c-2.7-0.7-5.5-1.3-8.2-1.5
				c-2.7-0.3-5.5-0.3-8.1-0.2c-5.4,0.3-10.8,1.5-15.7,3.8c-5,2.3-9.4,5.8-12.6,10c-1.6,2.1-2.9,4.5-3.9,6.9
				c-0.9,2.4-1.5,5.1-1.6,7.7c-0.3,5.1,1,10,3.5,14.1c1.3,2,2.9,3.8,4.7,5.2c1.8,1.4,3.8,2.4,5.8,3.1c4,1.3,8,1.3,11.5,0.3
				c3.5-1,6.4-3.3,8-5.7c1.7-2.4,2.4-4.9,2.6-7c0.2-2.1-0.1-4-0.6-5.3c-0.5-1.4-1.2-2.3-1.7-2.8c-0.6-0.5-1-0.6-1.3-0.5
				c-0.6,0.3-0.4,1.7-0.7,3.8c-0.1,1-0.4,2.2-1.1,3.5c-0.6,1.2-1.5,2.6-2.7,3.6c-0.6,0.5-1.3,0.9-2,1.3c-0.7,0.3-1.6,0.5-2.5,0.6
				c-1.8,0.2-3.8-0.2-5.7-1.2c-0.9-0.5-1.8-1.1-2.5-1.9c-0.7-0.7-1.3-1.6-1.8-2.7c-1-2.1-1.4-4.7-1-7.3c0.2-1.3,0.6-2.5,1.2-3.8
				c0.6-1.2,1.4-2.4,2.4-3.6c1-1.1,2.1-2.2,3.4-3.1c1.3-0.9,2.7-1.7,4.3-2.3c6.2-2.5,14.7-2.8,22.4-0.6c3.8,1.1,7.5,2.8,10.6,5.1
				c1.5,1.2,3,2.5,4.2,3.9c0.6,0.7,1.1,1.5,1.7,2.2l0.7,1.2l0.6,1.3c1.6,3.4,2.4,7.4,2.2,11.1c-0.1,1.9-0.5,3.7-1.2,5.4
				c-0.3,0.9-0.7,1.6-1.2,2.4l-0.7,1.1c-0.3,0.4-0.5,0.7-0.9,1.1c-2.5,3-6.1,5.2-10.1,6.9c-2,0.9-4.1,1.6-6.1,2.2
				c-1.1,0.3-2.1,0.6-3.2,0.9c-1.1,0.3-2.1,0.6-3.1,0.8c-8.4,1.9-16.5,2.6-24.2,2.5c-7.7-0.1-15.1-1.1-22.2-2.7
				c-7.1-1.6-13.8-3.8-20.3-6.6c-13-5.7-25.3-13.9-36.8-25.5c-5.7-5.8-11.3-12.5-16.4-20.3c-5.2-7.7-9.9-16.7-13.9-26.8
				c3.7,10.3,8,19.4,12.8,27.5c4.8,8.1,10,15.2,15.6,21.4c11.1,12.5,23,21.8,36.3,28.8c6.6,3.5,13.5,6.4,20.9,8.7
				c7.4,2.3,15.2,3.9,23.5,4.7c8.4,0.8,17.3,0.7,26.8-0.7c1.2-0.1,2.4-0.4,3.6-0.6c1.2-0.2,2.4-0.5,3.6-0.7c2.5-0.6,5-1.2,7.6-2.1
				c2.6-0.9,5.2-2,7.8-3.5C528.7,303.5,531.3,301.6,533.7,299.2z"
                />
              </g>
              <g>
                <path
                  d="M454.1,257.7c3.8-5.9,5.1-13.2,3.9-20.1c-0.6-3.4-1.9-6.8-3.7-9.7c-1.8-2.9-4.1-5.5-6.7-7.6c-2.6-2.1-5.5-3.7-8.6-4.7
				c-3.1-1-6.3-1.5-9.5-1.3c-3.2,0.2-6.3,0.9-9.1,2.4c-0.7,0.3-1.4,0.8-2,1.2c-0.7,0.5-1.3,0.9-1.9,1.4c-1.2,1-2.2,2.1-3.1,3.3
				c-1.7,2.4-2.8,5-3.3,7.6c-0.5,2.6-0.3,5.3,0.4,7.7c0.8,2.5,2.1,4.4,3.6,5.7c1.5,1.4,3,2.3,4.5,2.9c1.5,0.6,2.9,0.8,4.1,0.8
				c0.6,0,1.2-0.1,1.7-0.2c0.5-0.1,0.9-0.3,1.3-0.4c0.8-0.4,1.3-0.8,1.5-1.2c0.3-0.4,0.4-0.7,0.3-1.1c-0.2-0.7-1-1-2-1.6
				c-1-0.6-2.2-1.3-3.3-2.6c-1.1-1.2-1.9-3.1-1.8-4.5c0.1-0.9,0.2-1.7,0.7-2.6c0.4-0.9,1.1-1.8,1.9-2.6c0.4-0.4,0.9-0.7,1.3-1
				c0.2-0.1,0.5-0.2,0.7-0.4c0.3-0.1,0.5-0.2,0.8-0.3c1.1-0.4,2.4-0.5,3.9-0.5c2.9,0.1,5.9,1.3,8.6,3.4c1.3,1.1,2.5,2.4,3.4,3.8
				c0.9,1.5,1.6,3,2,4.7c0.8,3.4,0.5,7.4-1.1,10.7c-1.6,3.5-4.6,6.3-7.9,8.3c-3.4,1.9-7,3-10.6,3.3c-3.7,0.4-7.3,0.1-10.9-0.6
				c-3.5-0.7-7-1.8-10.4-3.2c-3.4-1.4-6.6-3.1-9.9-5.2c-3.2-2.1-6.3-4.6-9.4-7.5c-3-2.9-6-6.3-8.7-10.3c-2.7-4-5.2-8.6-7.1-14
				c3.2,11,8.3,19.4,13.9,26.1c2.8,3.4,5.7,6.3,8.8,9c3.1,2.6,6.3,4.9,9.7,7c3.4,2.1,7,3.8,11,5.2c3.9,1.4,8.2,2.4,13,2.7
				c4.7,0.3,10-0.4,15.2-2.5c2.6-1,5.2-2.5,7.8-4.4C449.8,263.2,452.2,260.8,454.1,257.7z"
                />
              </g>
              <g>
                <path
                  d="M336.8,288.9c-1.6,6.2-2,12.8-1.5,18.8c0.5,6,2,11.6,3.9,16c1.9,4.5,4.2,7.8,6.2,9.9c2.1,2.1,3.9,2.9,5,2.3
				c1.2-0.6,1.7-2.5,1.6-5.1c0.1-2.7-0.4-6.2-0.8-10.4c-0.4-4.1-0.9-8.8-1.2-13.8c-0.3-5-0.4-10.4-0.3-15.8
				c0.1-5.6,0.8-10.4,1.7-14.7c0.9-4.3,2.2-8.3,3.4-12.5c1.2-4.1,2.4-8.3,3.2-13.2c0.4-2.4,0.6-5,0.6-7.8c0-2.8-0.4-5.7-1.3-8.7
				c0.5,3.1,0.5,6,0.1,8.6c-0.4,2.6-1.1,5-1.9,7.2c-1.6,4.4-3.7,8-5.9,11.6c-2.2,3.6-4.5,7.2-6.8,11.5
				C340.7,277.4,338.5,282.5,336.8,288.9z"
                />
              </g>
              <g>
                <path
                  d="M350.5,287.2c-2,4.9-4.1,10-5.6,15.3c-1.5,5.3-2.2,10.7-1.9,15.5c0.3,4.8,1.5,8.7,3,11.2c1.5,2.5,3.2,3.5,4.5,3.1
				c1.3-0.4,2-2,2.7-4.3c0.7-2.3,1.4-5.3,2.3-8.9c0.9-3.6,2.2-7.7,3.7-12.4c1.4-4.7,3-10.1,4-15.9c0.9-6,1.2-11.1,1.2-15.7
				c0-4.6-0.4-8.7-0.9-12.8c-0.5-4.1-1.2-8.1-2.1-12.5c-1-4.4-2.2-9.3-4.1-14.9c1.1,5.8,1.4,10.8,1.4,15.2c0,4.4-0.4,8.4-0.9,12.2
				c-0.6,3.8-1.4,7.6-2.5,11.6C354.1,277.9,352.6,282.2,350.5,287.2z"
                />
              </g>
              <g>
                <path
                  d="M393.3,330.8c-0.5,0.7-1,1.3-1.5,1.9c-0.5,0.6-1.1,1.1-1.6,1.6c-1.2,0.9-2.4,1.7-3.7,2.3c-1.3,0.6-2.5,0.8-3.7,0.9
				c-1.2,0.1-2.1,0-3.2-0.4c-2.1-0.6-3.9-2.2-4.8-3.6c-0.4-0.7-0.7-1.4-0.8-2.3c-0.1-0.9,0-1.8,0.2-2.8c0.5-1.9,1.6-3.7,2.7-4.9
				c1.2-1.2,2-1.6,2.4-2c0.3-0.1,0.8-0.1,1.2,0c0.4,0.1,0.8,0.3,1.1,0.5c0.7,0.4,1.1,1.1,1.7,1.4c0.3,0.1,0.7,0.1,1.1-0.1
				c0.4-0.3,0.8-0.8,1.1-1.6c0.2-0.8,0.2-2-0.2-3.1c-0.4-1.2-1.2-2.4-2.6-3.5c-1.6-1.1-3.4-1.5-5.4-1.5c-1.9,0-3.8,0.5-5.6,1.3
				c-3.5,1.6-6.7,4.5-9,8.7c-1.1,2.1-1.9,4.6-2.1,7.3c-0.2,2.7,0.2,5.8,1.4,8.5c1.1,2.8,2.9,5.2,5.1,7.2c2.2,2,4.8,3.6,7.7,4.6
				c2.9,1,6.3,1.4,9.3,1.1c3.1-0.3,6.1-1.2,8.7-2.6c2.7-1.4,5-3.2,7.1-5.3c1-1.1,1.9-2.2,2.8-3.4c0.8-1.2,1.5-2.4,2.1-3.7
				c2.5-5.3,3.2-10.4,3.2-14.9c0-4.6-0.8-8.7-1.8-12.3c-1-3.7-2.3-6.9-3.6-10c-1.3-3.1-2.7-6-4.2-8.9c-1.5-2.9-3-5.7-4.8-8.6
				c-1.7-2.9-3.5-5.8-5.6-8.7c-4.1-6-8.9-12.3-15.4-19.3c5.9,7.4,9.9,14.3,13.1,20.6c1.6,3.2,3,6.2,4.2,9.2c1.2,3,2.2,6,3.2,8.9
				c0.9,3,1.7,6,2.4,9c0.7,3.1,1.3,6.2,1.6,9.4c0.3,3.2,0.3,6.5-0.3,9.8C396.4,324.7,395.3,328,393.3,330.8z"
                />
              </g>
              <g>
                <path
                  d="M211.1,46.3c3.4-0.8,6.9-1,10.2-0.6c3.3,0.4,6.4,1.5,8.9,3.1c2.6,1.6,4.7,3.9,6.2,6.4c0.7,1.2,1.3,2.5,1.7,3.8
				c0.4,1.3,0.5,2.6,0.6,3.9c0.1,5.3-3,11-7.3,15.2c-1.1,1.1-2.2,2.1-3.4,2.9c-1.2,0.9-2.4,1.7-3.5,2.4c-2.4,1.4-4.7,2.1-6.6,2.3
				c-1,0.1-1.9,0-2.6-0.2c-0.8-0.2-1.6-0.5-2.3-1c-1.5-1-2.7-2.4-3.5-3.9c-0.4-0.8-0.6-1.5-0.8-2.3c-0.1-0.4-0.1-0.8-0.2-1.1
				c0-0.4,0-0.7,0-1.1c0.1-1.5,0.6-3,1.3-4.2c1.4-2.5,3.4-4.2,4.9-5.2c1.5-1,2.5-1.6,2.5-2.3c0-0.6-1.2-1.4-3.6-1.3
				c-1.2,0-2.7,0.3-4.3,0.9c-1.6,0.7-3.4,1.7-5.1,3.3c-1.7,1.6-3.3,3.8-4.3,6.8c-0.3,0.7-0.4,1.5-0.6,2.3c-0.1,0.8-0.2,1.6-0.2,2.5
				c-0.1,1.7,0.2,3.4,0.6,5.2c0.9,3.5,2.9,6.8,5.9,9.6c1.5,1.4,3.4,2.6,5.5,3.4c2.2,0.9,4.4,1.3,6.6,1.4c4.5,0.2,8.9-1,12.7-2.8
				c1.9-0.9,3.7-1.9,5.5-3.1c1.8-1.2,3.5-2.5,5.1-4c1.6-1.4,3.2-3.1,4.6-4.9c1.4-1.8,2.7-3.7,3.9-5.8c1.1-2.1,2.1-4.4,2.8-6.9
				c0.7-2.5,1.1-5.1,1.1-7.8c0-2.7-0.3-5.5-1.1-8.1c-0.8-2.6-1.9-5.1-3.3-7.4c-2.8-4.6-6.7-8.4-11.4-11.2c-4.7-2.8-9.9-4.2-15.1-4.6
				c-5.2-0.4-10.3,0.3-15.2,1.9c-5,1.6-9.3,4-13,6.7c-3.7,2.7-6.9,5.8-9.6,9c-5.3,6.4-9.1,13.2-11.6,19.9
				c-2.5,6.7-3.9,13.4-4.6,19.9c-0.7,6.5-0.7,12.8-0.1,19.1c0.6,6.3,1.8,12.4,3.6,18.6c1.8,6.2,4.1,12.4,6.9,18.7
				c5.7,12.7,13.8,25.8,24.4,40.6c-10-15.2-17.2-28.8-22-41.6c-2.4-6.4-4.2-12.6-5.4-18.7c-1.2-6.1-1.8-12-1.9-18
				c0-5.9,0.5-11.8,1.7-17.7c1.2-5.8,3-11.7,5.8-17.4c2.8-5.6,6.5-11.1,11.4-16c2.4-2.4,5.2-4.6,8.2-6.5
				C204.3,48.6,207.6,47.1,211.1,46.3z"
                />
              </g>
              <g>
                <path
                  d="M217.7,111.6c1.7-0.5,3.2-0.7,4.8-0.7c1.5,0,2.9,0.2,4,0.6c0.5,0.2,1,0.4,1.3,0.7c0.4,0.2,0.7,0.5,0.9,0.8
				c0.5,0.6,1,1.5,1.3,2.5c0.2,0.5,0.3,1.1,0.3,1.7c0.1,0.6,0.1,1.1,0,1.7c-0.1,1.1-0.4,2.2-0.7,3c-0.2,0.4-0.4,0.8-0.6,1.1
				c-0.2,0.3-0.4,0.6-0.7,0.8c-0.3,0.2-0.4,0.4-0.7,0.6c-0.3,0.2-0.6,0.3-1,0.4c-0.7,0.2-1.5,0.2-2.3,0.1c-0.7-0.1-1.4-0.3-2-0.7
				c-1.2-0.6-2.2-2-2.8-3.3c-0.6-1.3-0.9-2.6-1.1-3.6c-0.2-1-0.5-1.8-1-2.2c-0.5-0.3-1.4-0.2-2.5,0.7c-1,0.9-2.2,2.7-2.6,5.4
				c-0.2,1.3-0.2,2.9,0.1,4.5c0.3,1.7,1,3.5,2.3,5.2c2.5,3.5,7,6.2,12.4,6.3c1.3,0,2.7-0.1,4.2-0.4c1.4-0.3,2.9-0.9,4.2-1.7
				c1.3-0.8,2.5-1.7,3.6-2.8c1.1-1.1,2-2.3,2.8-3.5c1.5-2.5,2.5-5.3,3-8.3c0.2-1.5,0.3-3,0.2-4.5c-0.1-1.5-0.3-3-0.7-4.5
				c-0.8-3-2.3-6.1-4.8-8.7c-2.5-2.6-5.8-4.5-9-5.3c-3.2-0.9-6.4-1-9.4-0.6c-1.5,0.2-2.9,0.5-4.3,0.9c-1.4,0.4-2.7,1-4,1.5
				c-5.3,2.4-9.2,5.9-12.2,9.4c-1.5,1.8-2.8,3.6-3.9,5.4c-1.1,1.9-2,3.7-2.8,5.5c-3,7.5-3.6,14.5-3.3,21.3c0.3,6.8,1.7,13.4,4,20.4
				c2.2,7,5.4,14.5,9.3,23.3c-3.1-9.1-5.5-16.9-6.7-24c-1.3-7.1-1.6-13.5-0.8-19.6c0.7-6.1,2.5-12,5.9-17.3c0.8-1.3,1.8-2.6,2.9-3.9
				c1.1-1.2,2.3-2.4,3.5-3.5C211.2,114.4,214.2,112.5,217.7,111.6z"
                />
              </g>
              <g>
                <path
                  d="M252.1,32.8c3.2,0.6,6.4-0.3,8.5-1.8c2.1-1.5,3-3.4,2.5-5.1c-0.6-1.7-2.4-2.7-4.6-3c-2.2-0.3-4.8,0-7.4,1.2
				c-2.7,1.2-4.6,2.1-6.6,2.8c-2,0.7-4.2,1.2-7.3,1.3c3,1,5.1,1.7,7.3,2.5C246.6,31.4,248.8,32.2,252.1,32.8z"
                />
              </g>
              <g>
                <path
                  d="M267.3,45.6c1.8,2,3.7,3.3,5.6,4.1c1.9,0.8,4,1.1,5.5,0.2c1.5-0.9,1.9-3.2,0.7-5.8c-1.2-2.6-4-5.2-7.6-6.1
				c-3.7-0.9-6.2-0.8-8.7-0.6c-2.4,0.2-4.8,0.6-8,1.4c3.3,0.3,5.4,1.2,7.2,2.3C263.8,42.2,265.4,43.6,267.3,45.6z"
                />
              </g>
              <g>
                <path
                  d="M270,60.5c0.6,2.6,1.5,4.6,2.7,6.3c1.2,1.6,2.7,3,4.5,3.1c0.9,0,1.7-0.4,2.4-1.2c0.3-0.4,0.7-0.9,0.9-1.5
				c0.2-0.6,0.4-1.3,0.5-2c0.4-2.9-0.8-6.8-3.6-9.5c-2.9-2.7-5.2-3.8-7.5-4.7c-2.3-0.9-4.7-1.6-7.9-2.2c3,1.6,4.4,3.3,5.6,5.1
				C268.6,55.8,269.4,57.8,270,60.5z"
                />
              </g>
              <g>
                <path
                  d="M263.1,73.6c-0.7,2.7-0.6,5.1,0.1,7.2c0.7,2,1.9,3.7,3.7,4c1.7,0.3,3.5-0.9,4.7-3.2c1.1-2.3,1.5-5.6,0.3-8.6
				c-1.2-3.2-2.4-5.1-3.5-7.1c-1.2-1.9-2.3-3.8-3.9-6.5c0.6,3.1,0.5,5.2,0.2,7.3C264.3,68.8,263.9,70.8,263.1,73.6z"
                />
              </g>
              <g>
                <path
                  d="M251.6,86.6c-1.8,2-2.8,4.1-3.2,6.2c-0.4,2.1-0.1,4.1,1.2,5.3c1.3,1.2,3.5,1,5.7-0.5c2.1-1.5,4-4.4,4.4-7.7
				c0.4-3.4,0.1-5.8-0.2-8c-0.3-2.3-0.7-4.5-1.2-7.5c-0.5,3.1-1.4,5-2.5,6.8C254.7,82.9,253.5,84.5,251.6,86.6z"
                />
              </g>
              <g>
                <path
                  d="M241.2,20.8c6-2.2,9.5-7.9,7.4-10.8c-2.1-2.9-8.6-1.4-12.5,3.7c-2,2.6-3.3,4.5-4.6,6.4c-0.7,0.9-1.3,1.9-2.1,2.7
				c-0.4,0.4-0.9,0.8-1.5,1.1c-0.6,0.3-1.4,0.4-2.1,0.2c0.7,0.5,1.5,0.7,2.3,0.6c0.8,0,1.5-0.2,2.2-0.4c0.7-0.2,1.3-0.4,1.9-0.6
				c0.6-0.2,1.1-0.4,1.7-0.6C235.8,22.5,238.1,21.9,241.2,20.8z"
                />
              </g>
              <g>
                <path
                  d="M227.4,15.6c2.1-2.5,2.5-5.9,1.9-8.2c-0.7-2.4-2.4-3.8-4.1-3.6c-1.8,0.1-3.2,1.4-4.2,2.9c-1,1.5-1.6,3.3-1.6,5.5
				c0,2.4-0.2,4.2-0.7,5.9c-0.5,1.8-1.3,3.6-3.2,5.6c2.6-1.2,4.4-2.1,6.2-3.2C223.5,19.4,225.3,18.1,227.4,15.6z"
                />
              </g>
              <g>
                <path
                  d="M216.5,14.7c1.1-3.1,0.5-6.4-0.9-8.5c-1.4-2.1-3.4-2.9-5-2.3c-1.7,0.6-2.6,2.3-3.1,4.1c-0.5,1.8-0.5,3.9,0.3,6.1
				c0.8,2.4,1.2,4.2,1.4,6.1c0.2,1.9,0.1,3.9-1,6.6c1.9-2.1,3.3-3.8,4.6-5.5C214.1,19.6,215.4,17.8,216.5,14.7z"
                />
              </g>
              <g>
                <path
                  d="M204,15.5c-0.4-2.8-2.4-5-4.4-5.8c-2.1-0.8-4-0.4-5.1,1c-1.1,1.4-1.2,3.2-0.9,4.8c0.3,1.6,1.1,2.9,2.7,4.1
				c1.7,1.2,2.9,2.3,3.9,3.5c1,1.2,1.9,2.7,2.4,5.1c0.6-2.4,0.9-4.1,1.3-5.9C204.2,20.4,204.4,18.4,204,15.5z"
                />
              </g>
              <g>
                <path
                  d="M189.6,18.1c-1.2-0.9-2.6-1.3-4-1.3c-1.4,0-2.6,0.3-3.6,1c-1,0.6-1.6,1.4-1.9,2.3c-0.3,0.9-0.2,1.7,0.1,2.5
				c0.7,1.6,2.1,2.7,3.1,3.4c1.1,0.7,2,0.9,3.4,0.4c1.5-0.5,2.9-0.5,4.3-0.3c1.4,0.3,2.8,1,4.1,2.7c-0.3-2.2-0.8-3.8-1.5-5.5
				C193,21.7,192,19.9,189.6,18.1z"
                />
              </g>
              <g>
                <path
                  d="M174.9,28.6c-3.1,0-5.9,1.8-7.2,3.8c-1.3,2-1.2,4.2,0,5.4c1.2,1.3,3.1,1.6,4.8,1.4c1.8-0.2,3.4-0.8,5.1-2.3
				c1.7-1.5,3.2-2.6,4.8-3.4c1.6-0.8,3.4-1.4,6.1-1.4c-2.5-1.1-4.3-1.8-6.3-2.4C180.2,29.1,178.1,28.5,174.9,28.6z"
                />
              </g>
              <g>
                <path
                  d="M168.5,41.5c-2.5,0.6-4.3,2.4-4.8,4.3c-0.6,1.9-0.1,3.7,1.3,4.8c1.4,1.1,3.2,1.3,4.7,1c1.5-0.3,2.8-1.1,3.7-2.8
				c1-1.7,1.7-3,2.7-4.3c0.9-1.2,2.1-2.3,4.1-3.3c-2.2,0-3.8-0.1-5.6-0.2C172.8,41,171,40.9,168.5,41.5z"
                />
              </g>
              <g>
                <path
                  d="M158.7,62.1c-2.2,1.8-3.3,4.4-3.4,6.6c-0.1,2.2,0.9,4,2.5,4.7c1.6,0.7,3.5,0.1,5.1-1.1c1.6-1.2,2.8-3.1,3.3-5.6
				c0.5-2.6,0.8-4.4,1.3-6.3c0.5-1.8,1.1-3.6,2.6-6c-2.2,1.6-3.9,2.7-5.6,3.7C162.7,59.2,161,60.2,158.7,62.1z"
                />
              </g>
              <g>
                <path d="M155.7,90.1c-2.8,3.4-1.4,8,2,9c3.4,1,7.1-2.2,6.5-6.5c-0.6-4.5-1.6-6.4-1.4-10.8C160.4,85.5,158.6,86.6,155.7,90.1z" />
              </g>
              <g>
                <path
                  d="M157,113.8c-1.1,1.8-0.9,3.7-0.1,5.2c0.8,1.4,2.4,2.4,4.1,2.4c1.8,0,3.3-0.9,4.2-2.1c0.9-1.3,1.2-2.9,0.5-4.7
				c-1.5-3.7-2.8-5.3-3.2-9.2C160.8,108.9,159.2,110.1,157,113.8z"
                />
              </g>
              <g>
                <path
                  d="M191.5,102.9c2.1-4.3,3.5-8.8,3.9-12.4c0.5-3.6,0.1-6.5-1.5-7.2c-1.6-0.7-4.1,0.9-6.3,4.1c-2.2,3.2-4.1,8-4.6,13.1
				c-0.5,5.3-0.4,8.9-0.3,12.5c0.1,3.6,0.2,7.2,0.2,12.4c1-5.1,2.2-8.5,3.5-11.8C187.9,110.5,189.4,107.4,191.5,102.9z"
                />
              </g>
              <g>
                <g>
                  <path
                    d="M6.8,333.4c-3-1.7-5.7-4-7.9-6.6c-2.1-2.6-3.6-5.5-4.4-8.4c-0.8-2.9-0.8-6.1-0.2-8.9c0.3-1.4,0.8-2.7,1.4-3.9
					c0.6-1.2,1.4-2.2,2.3-3.2c3.6-3.9,9.7-6,15.7-6.1c1.5-0.1,3,0,4.5,0.1c1.5,0.1,2.9,0.4,4.2,0.7c2.7,0.6,4.8,1.6,6.3,2.8
					c0.8,0.6,1.4,1.3,1.8,1.9c0.5,0.7,0.8,1.5,1,2.3c0.4,1.7,0.4,3.6-0.2,5.2c-0.2,0.8-0.6,1.6-1,2.2c-0.2,0.4-0.4,0.6-0.7,0.9
					c-0.3,0.3-0.5,0.6-0.8,0.8c-1.1,1-2.5,1.7-3.9,2.2c-2.8,0.9-5.4,0.7-7.1,0.5c-1.8-0.3-3-0.5-3.4,0c-0.4,0.5-0.1,1.8,1.7,3.4
					c0.9,0.8,2.1,1.6,3.8,2.3c1.6,0.6,3.7,1.1,6,1.1c2.3,0,5.1-0.5,7.7-2c0.7-0.3,1.3-0.8,2-1.3c0.6-0.5,1.3-1,1.9-1.6
					c1.2-1.2,2.2-2.6,3.1-4.2c1.7-3.1,2.6-6.9,2.2-11c-0.2-2-0.7-4.2-1.7-6.3c-1-2.1-2.3-4-3.9-5.6c-3.2-3.2-7.2-5.3-11.2-6.6
					c-2-0.7-4.1-1.2-6.1-1.5c-2.1-0.4-4.2-0.5-6.4-0.6c-2.2-0.1-4.4,0.1-6.7,0.4c-2.3,0.3-4.6,0.9-6.8,1.6c-2.3,0.8-4.6,1.8-6.8,3.1
					c-2.2,1.4-4.3,3-6.1,4.9c-1.8,2-3.5,4.2-4.7,6.7c-1.2,2.4-2.1,5-2.7,7.6c-1.1,5.3-0.8,10.7,0.7,15.9c1.5,5.2,4.4,9.9,7.9,13.7
					c3.5,3.8,7.8,6.8,12.3,9c4.7,2.2,9.5,3.4,14.1,4c4.6,0.6,9,0.5,13.1,0c8.3-1,15.6-3.4,22.1-6.6c6.4-3.2,12-7.1,17-11.4
					c4.9-4.3,9.2-8.9,13.1-13.9c3.8-5,7.2-10.3,10.1-16c2.9-5.7,5.5-11.8,7.7-18.4c4.5-13.2,7.6-28.3,9.9-46.4
					c-3.1,18-7.1,32.8-12.3,45.4c-2.6,6.3-5.6,12.1-8.8,17.3c-3.3,5.3-6.9,10-10.9,14.4c-4,4.3-8.4,8.3-13.3,11.8
					c-4.9,3.5-10.2,6.5-16.1,8.7c-5.9,2.2-12.4,3.7-19.2,3.9c-3.4,0.1-6.9-0.1-10.4-0.9C13.3,336.4,9.9,335.2,6.8,333.4z"
                  />
                </g>
                <g>
                  <path
                    d="M46.6,281.2c-1.6-0.8-2.8-1.7-4-2.7c-1.1-1-2-2.1-2.5-3.1c-0.3-0.5-0.4-1-0.5-1.4c-0.1-0.4-0.1-0.8-0.1-1.2
					c0-0.8,0.3-1.7,0.8-2.7c0.3-0.5,0.6-1,0.9-1.5c0.4-0.5,0.7-0.9,1.1-1.2c0.8-0.8,1.8-1.4,2.6-1.7c0.4-0.2,0.8-0.3,1.2-0.4
					c0.4-0.1,0.7-0.1,1-0.1c0.3,0,0.6,0,0.9,0.1c0.3,0.1,0.6,0.2,1,0.4c0.7,0.3,1.3,0.9,1.7,1.5c0.5,0.6,0.8,1.2,1,1.8
					c0.4,1.3,0.2,2.9-0.2,4.3c-0.5,1.4-1.1,2.5-1.7,3.4c-0.5,0.9-0.9,1.6-0.7,2.3c0.2,0.6,0.9,1.1,2.3,1.2c1.4,0,3.5-0.5,5.6-2.2
					c1.1-0.8,2.1-1.9,3-3.4c0.9-1.4,1.6-3.3,1.9-5.4c0.6-4.3-0.9-9.3-4.7-13c-1-0.9-2.1-1.8-3.3-2.5c-1.3-0.7-2.7-1.3-4.2-1.6
					c-1.5-0.3-3-0.5-4.5-0.5c-1.5,0.1-3,0.3-4.4,0.7c-2.9,0.8-5.5,2.2-7.8,4c-1.2,0.9-2.3,2-3.2,3.2c-1,1.2-1.8,2.4-2.6,3.8
					c-1.5,2.7-2.5,6-2.5,9.6c0,3.6,1.2,7.2,2.9,10c1.7,2.9,4,5.1,6.4,6.8c1.2,0.9,2.5,1.6,3.8,2.3c1.3,0.7,2.7,1.2,4,1.6
					c5.5,1.8,10.7,2,15.4,1.5c2.3-0.3,4.5-0.7,6.6-1.3c2.1-0.6,4-1.3,5.8-2.2c7.3-3.4,12.6-8.1,17-13.2c4.4-5.2,7.9-10.9,11-17.6
					c3.2-6.7,5.9-14.3,9.1-23.4c-4,8.8-7.5,16.1-11.4,22.1c-3.9,6.1-8.1,10.9-12.8,14.9c-4.7,3.9-10,7-16.1,8.6
					c-1.5,0.4-3.1,0.7-4.7,0.9c-1.6,0.2-3.3,0.2-5,0.1C53.2,283.5,49.7,282.8,46.6,281.2z"
                  />
                </g>
                <g>
                  <path
                    d="M-32.4,315.2c-2-2.6-4.9-4.2-7.4-4.5c-2.5-0.4-4.6,0.4-5.3,2c-0.7,1.6-0.1,3.6,1.3,5.3c1.4,1.7,3.5,3.3,6.2,4.2
					c2.8,0.9,4.8,1.6,6.8,2.5c2,0.9,3.9,1.9,6.2,4c-1.5-2.8-2.6-4.7-3.6-6.8C-29.3,320-30.4,317.9-32.4,315.2z"
                  />
                </g>
                <g>
                  <path
                    d="M-34.7,295.5c0-2.7-0.4-4.9-1.3-6.9c-0.9-1.9-2.1-3.5-3.9-3.8c-1.7-0.3-3.6,1.1-4.5,3.8c-0.9,2.7-0.6,6.6,1.4,9.7
					c2.1,3.2,4,4.8,5.9,6.3c1.9,1.5,3.9,2.8,6.8,4.4c-2.2-2.4-3.1-4.5-3.7-6.6C-34.5,300.4-34.8,298.3-34.7,295.5z"
                  />
                </g>
                <g>
                  <path
                    d="M-26.6,282.8c1.3-2.3,2-4.4,2.3-6.5c0.2-2,0.1-4.1-1.2-5.3c-0.6-0.6-1.5-0.9-2.5-0.8c-0.5,0.1-1.1,0.2-1.6,0.5
					c-0.5,0.3-1.2,0.6-1.7,1.1c-2.3,1.9-4.1,5.5-3.9,9.3c0.3,3.9,1.2,6.3,2.3,8.6c1.1,2.2,2.3,4.4,4.3,7c-1.1-3.2-1-5.5-0.6-7.6
					C-28.7,287.1-27.9,285.2-26.6,282.8z"
                  />
                </g>
                <g>
                  <path
                    d="M-12.6,277.9c2.3-1.5,3.9-3.4,4.9-5.3c0.9-1.9,1.1-4,0.1-5.4c-1-1.4-3.2-1.8-5.6-0.9c-2.4,0.9-4.9,3.1-6.2,6.1
					c-1.3,3.1-1.8,5.4-2.3,7.6c-0.4,2.2-0.9,4.4-1.5,7.4c1.7-2.6,3.2-4.2,4.8-5.5C-16.7,280.6-15,279.4-12.6,277.9z"
                  />
                </g>
                <g>
                  <path
                    d="M4.7,276.2c2.7-0.3,4.9-1.1,6.6-2.4c1.7-1.3,2.9-2.9,2.7-4.7c-0.2-1.7-1.9-3.2-4.4-3.5c-2.5-0.4-5.9,0.4-8.5,2.6
					c-2.6,2.3-4,4.2-5.4,6c-1.3,1.9-2.6,3.7-4.3,6.3c2.5-1.9,4.5-2.7,6.5-3.3C-0.1,276.8,1.9,276.5,4.7,276.2z"
                  />
                </g>
                <g>
                  <path
                    d="M-32.6,331.5c-5.9-2.5-12.3-0.7-12.8,2.8c-0.4,3.5,5.4,6.9,11.7,5.9c3.2-0.5,5.5-1.1,7.8-1.5c1.2-0.2,2.2-0.5,3.4-0.5
					c0.6,0,1.2,0,1.8,0.2c0.6,0.2,1.3,0.6,1.7,1.3c-0.2-0.8-0.7-1.5-1.2-2c-0.6-0.5-1.2-0.9-1.8-1.2c-0.6-0.3-1.2-0.6-1.8-0.8
					c-0.6-0.2-1.1-0.5-1.6-0.7C-27.5,333.9-29.6,332.8-32.6,331.5z"
                  />
                </g>
                <g>
                  <path
                    d="M-26.1,344.6c-3.2,0.4-5.9,2.5-7,4.8c-1.2,2.2-0.8,4.4,0.5,5.5c1.4,1.1,3.3,1.1,5.1,0.8c1.8-0.4,3.4-1.3,4.9-3
					c1.6-1.7,3-2.9,4.6-3.9c1.6-1,3.4-1.7,6.2-1.9c-2.7-0.9-4.6-1.4-6.7-1.9C-20.6,344.6-22.8,344.2-26.1,344.6z"
                  />
                </g>
                <g>
                  <path
                    d="M-18.7,352.8c-2.9,1.5-4.7,4.4-5.1,6.8c-0.4,2.5,0.5,4.5,2.1,5.1c1.7,0.7,3.5,0.1,5.1-0.9c1.6-1,3-2.5,4-4.7
					c1-2.2,2-3.9,3.1-5.4c1.2-1.5,2.6-2.9,5.2-4.2c-2.9,0.2-5,0.5-7.1,0.9C-13.6,350.8-15.8,351.3-18.7,352.8z"
                  />
                </g>
                <g>
                  <path
                    d="M-9,360.7c-1.6,2.3-1.7,5.3-0.7,7.3c0.9,2,2.7,3,4.4,2.7c1.7-0.3,3.1-1.5,4-2.9c0.9-1.4,1.2-2.9,0.8-4.8
					c-0.4-2-0.6-3.6-0.5-5.2c0.1-1.6,0.4-3.3,1.7-5.4c-2,1.3-3.5,2.4-5,3.5C-5.8,357-7.4,358.3-9,360.7z"
                  />
                </g>
                <g>
                  <path
                    d="M3.3,368.7c0.3,1.5,1,2.7,2,3.7c1,0.9,2.1,1.5,3.2,1.7c1.1,0.2,2.1,0,2.9-0.4c0.8-0.4,1.3-1.1,1.6-1.9
					c0.6-1.7,0.4-3.4,0.1-4.6c-0.3-1.3-0.8-2.1-2.2-2.7c-1.4-0.7-2.5-1.6-3.3-2.7c-0.8-1.2-1.4-2.6-1.1-4.8c-1.3,1.8-2,3.4-2.6,5.1
					C3.3,363.7,2.7,365.7,3.3,368.7z"
                  />
                </g>
                <g>
                  <path
                    d="M21.2,371c2.3,2.1,5.5,2.7,7.9,2.1c2.4-0.6,3.8-2.2,3.7-3.9c0-1.8-1.2-3.3-2.6-4.4c-1.4-1.1-3.1-1.7-5.3-1.8
					c-2.3-0.1-4.1-0.3-5.8-0.8c-1.7-0.5-3.4-1.3-5.4-3.2c1.1,2.5,1.9,4.3,2.9,6.1C17.7,367,18.8,368.9,21.2,371z"
                  />
                </g>
                <g>
                  <path
                    d="M34.6,366c2.2,1.3,4.7,1.2,6.4,0.2c1.7-1,2.6-2.7,2.3-4.4c-0.3-1.7-1.4-3.1-2.7-4c-1.3-0.8-2.8-1.1-4.7-0.5
					c-1.9,0.6-3.3,1-4.8,1.3c-1.5,0.2-3.1,0.3-5.2-0.4c1.6,1.5,2.7,2.7,3.9,3.9C31.1,363.4,32.4,364.7,34.6,366z"
                  />
                </g>
                <g>
                  <path
                    d="M55.9,357.6c2.9,0.2,5.5-1,7-2.5c1.6-1.6,2.1-3.5,1.4-5.1c-0.7-1.6-2.5-2.5-4.5-2.7c-2-0.2-4.2,0.4-6.2,1.9
					c-2.1,1.5-3.6,2.7-5.2,3.7c-1.6,1-3.3,1.9-6,2.6c2.7,0.3,4.7,0.7,6.6,1.1C51,357,52.9,357.4,55.9,357.6z"
                  />
                </g>
                <g>
                  <path
                    d="M77.2,339.2c4.4-0.5,6.5-4.9,4.7-7.9c-1.8-3-6.6-3.2-9.2,0.4c-2.6,3.7-3.2,5.7-6.3,8.9
					C70.6,339.3,72.7,339.8,77.2,339.2z"
                  />
                </g>
                <g>
                  <path
                    d="M92.4,321c2-0.6,3.2-2.1,3.6-3.7c0.4-1.6-0.1-3.4-1.4-4.6c-1.3-1.2-3-1.6-4.5-1.3c-1.5,0.3-2.9,1.3-3.6,3.1
					c-1.4,3.7-1.5,5.7-3.9,8.9C86.3,321.9,88.3,322.2,92.4,321z"
                  />
                </g>
                <g>
                  <path
                    d="M59.8,305.3c-4.5,1.7-8.5,4.1-11.4,6.4c-2.8,2.3-4.5,4.7-3.8,6.3c0.7,1.6,3.6,2.1,7.4,1.3c3.8-0.8,8.5-3,12.3-6.5
					c3.9-3.5,6.3-6.2,8.8-8.9c2.4-2.7,4.8-5.4,8.3-9.3c-4.2,3.1-7.4,4.7-10.6,6.2C67.6,302.3,64.4,303.6,59.8,305.3z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M363.6,398.2c3.2,1.3,6.2,3.2,8.6,5.5c2.4,2.3,4.3,5,5.4,7.7c1.1,2.8,1.6,5.9,1.3,8.8c-0.1,1.4-0.4,2.8-0.9,4.1
					c-0.4,1.3-1.1,2.4-1.8,3.5c-3,4.3-8.8,7.2-14.8,8.1c-1.5,0.3-3,0.4-4.4,0.4c-1.5,0.1-2.9,0-4.3-0.1c-2.7-0.3-5-1-6.7-2
					c-0.8-0.5-1.5-1.1-2-1.7c-0.5-0.6-1-1.4-1.3-2.2c-0.6-1.7-0.8-3.6-0.5-5.2c0.1-0.8,0.4-1.6,0.7-2.3c0.1-0.4,0.4-0.7,0.5-1
					c0.2-0.3,0.4-0.6,0.7-0.9c1-1.2,2.2-2.1,3.6-2.7c2.6-1.2,5.2-1.4,7-1.4c1.8,0,3,0.1,3.4-0.4c0.4-0.5-0.2-1.8-2.1-3.2
					c-1-0.7-2.3-1.3-4-1.8c-1.7-0.4-3.8-0.6-6.1-0.3c-2.3,0.3-4.9,1.2-7.4,3c-0.6,0.4-1.2,1-1.8,1.5c-0.6,0.6-1.2,1.2-1.6,1.9
					c-1,1.3-1.9,2.9-2.5,4.5c-1.3,3.3-1.6,7.2-0.8,11.2c0.4,2,1.2,4.1,2.4,6c1.3,2,2.8,3.6,4.6,5c3.6,2.8,7.8,4.3,11.9,5.1
					c2.1,0.4,4.2,0.6,6.3,0.7c2.1,0.1,4.3,0,6.4-0.3c2.2-0.2,4.4-0.7,6.6-1.3c2.2-0.6,4.4-1.4,6.6-2.5c2.2-1.1,4.3-2.4,6.3-4
					c2-1.6,3.8-3.5,5.5-5.7c1.6-2.2,2.9-4.6,3.8-7.2c0.9-2.6,1.5-5.2,1.7-7.9c0.4-5.4-0.6-10.7-2.7-15.7c-2.2-5-5.6-9.2-9.6-12.6
					c-4-3.3-8.6-5.8-13.4-7.3c-5-1.6-9.9-2.2-14.5-2.1c-4.6,0-9,0.7-13,1.7c-8.1,2.1-15,5.4-21,9.4c-6,4-11,8.6-15.3,13.5
					c-4.3,4.9-8,10-11.1,15.5c-3.1,5.4-5.8,11.2-8,17.2c-2.2,6.1-3.9,12.4-5.3,19.3c-2.7,13.7-3.8,29-3.8,47.2
					c0.7-18.2,2.8-33.4,6.3-46.6c1.8-6.6,3.9-12.7,6.5-18.3c2.6-5.7,5.5-10.9,8.9-15.7c3.4-4.8,7.3-9.3,11.6-13.4
					c4.4-4.1,9.3-7.7,14.8-10.7c5.5-3,11.8-5.2,18.5-6.4c3.4-0.5,6.9-0.8,10.4-0.5C356.7,396.1,360.3,396.8,363.6,398.2z"
                  />
                </g>
                <g>
                  <path
                    d="M331,455.1c1.7,0.6,3,1.3,4.3,2.2c1.2,0.9,2.2,1.9,2.9,2.8c0.3,0.5,0.5,0.9,0.7,1.3c0.2,0.4,0.2,0.8,0.3,1.2
					c0.1,0.8,0,1.8-0.4,2.8c-0.2,0.5-0.4,1.1-0.7,1.6c-0.3,0.5-0.6,0.9-1,1.4c-0.7,0.9-1.6,1.6-2.4,2.1c-0.4,0.2-0.8,0.4-1.1,0.6
					c-0.4,0.1-0.7,0.2-1,0.3c-0.3,0-0.6,0.1-0.9,0c-0.3,0-0.7-0.1-1-0.2c-0.7-0.3-1.4-0.7-1.9-1.2c-0.5-0.5-1-1.1-1.2-1.7
					c-0.6-1.2-0.6-2.9-0.3-4.3c0.3-1.4,0.8-2.6,1.2-3.6c0.4-1,0.7-1.7,0.4-2.4c-0.2-0.6-1-1-2.5-0.9c-1.4,0.2-3.4,0.9-5.3,2.9
					c-0.9,1-1.9,2.2-2.6,3.7c-0.7,1.6-1.2,3.4-1.2,5.6c0,4.3,2.1,9.1,6.4,12.3c1.1,0.8,2.3,1.5,3.6,2.1c1.3,0.5,2.9,0.9,4.4,1.1
					c1.5,0.1,3,0.1,4.5-0.1c1.5-0.3,2.9-0.7,4.3-1.2c2.7-1.2,5.2-2.9,7.2-5c1-1.1,2-2.3,2.8-3.6c0.8-1.3,1.5-2.6,2-4.1
					c1.1-2.9,1.7-6.3,1.2-9.9c-0.5-3.6-2.1-7-4.2-9.5c-2.1-2.6-4.6-4.5-7.3-5.9c-1.3-0.7-2.7-1.3-4.1-1.8c-1.4-0.5-2.8-0.8-4.1-1.1
					c-5.7-1.1-10.9-0.6-15.4,0.5c-2.3,0.6-4.4,1.3-6.3,2.2c-2,0.9-3.8,1.8-5.5,2.9c-6.8,4.3-11.4,9.7-15.1,15.3
					c-3.7,5.7-6.4,11.9-8.7,18.9c-2.3,7-4,15-6,24.4c2.8-9.2,5.4-16.9,8.5-23.4c3.1-6.5,6.6-11.9,10.7-16.4c4.1-4.5,9-8.3,14.8-10.6
					c1.5-0.6,3-1.1,4.6-1.5c1.6-0.4,3.2-0.6,4.9-0.8C324,453.6,327.6,453.9,331,455.1z"
                  />
                </g>
                <g>
                  <path
                    d="M404.9,411.1c2.3,2.3,5.4,3.5,7.9,3.5c2.5,0,4.5-1,5-2.7c0.5-1.7-0.4-3.6-2-5.1c-1.6-1.6-3.9-2.8-6.7-3.3
					c-2.9-0.6-5-1-7-1.6c-2.1-0.6-4.1-1.4-6.7-3.2c1.9,2.5,3.2,4.4,4.4,6.3C401.2,406.8,402.5,408.7,404.9,411.1z"
                  />
                </g>
                <g>
                  <path
                    d="M409.7,430.4c0.3,2.7,1.1,4.8,2.2,6.6c1.1,1.8,2.6,3.2,4.3,3.3c1.7,0.1,3.4-1.5,4-4.4c0.6-2.8-0.2-6.6-2.6-9.4
					c-2.5-2.9-4.6-4.3-6.7-5.5c-2.1-1.2-4.2-2.3-7.3-3.5c2.5,2.1,3.7,4.1,4.5,6.1C408.9,425.6,409.4,427.6,409.7,430.4z"
                  />
                </g>
                <g>
                  <path
                    d="M403.3,444.1c-1,2.4-1.5,4.6-1.4,6.7c0,2,0.4,4,1.8,5.1c0.7,0.5,1.6,0.7,2.6,0.4c0.5-0.1,1.1-0.3,1.6-0.7
					c0.5-0.3,1.1-0.8,1.6-1.3c2-2.1,3.4-6,2.6-9.8c-0.8-3.8-2.1-6.1-3.4-8.2c-1.4-2.1-2.8-4-5.2-6.4c1.5,3,1.7,5.3,1.5,7.4
					C404.8,439.5,404.3,441.5,403.3,444.1z"
                  />
                </g>
                <g>
                  <path
                    d="M390,450.7c-2.1,1.7-3.5,3.8-4.1,5.9c-0.6,2-0.6,4.1,0.6,5.4c1.2,1.3,3.4,1.3,5.6,0.1c2.2-1.2,4.4-3.7,5.3-6.8
					c0.9-3.3,1.1-5.6,1.3-7.8c0.2-2.3,0.3-4.5,0.6-7.6c-1.3,2.8-2.6,4.5-4.1,6.1C393.8,447.5,392.2,448.9,390,450.7z"
                  />
                </g>
                <g>
                  <path
                    d="M373.1,454.6c-2.6,0.6-4.7,1.8-6.2,3.2c-1.5,1.5-2.5,3.3-2.1,5c0.4,1.7,2.3,2.9,4.9,2.9c2.6,0,5.8-1.2,8.1-3.7
					c2.3-2.6,3.4-4.6,4.5-6.7c1.1-2,2.1-4,3.4-6.8c-2.2,2.2-4.1,3.3-6,4.1C377.8,453.4,375.8,453.9,373.1,454.6z"
                  />
                </g>
                <g>
                  <path
                    d="M402.9,395c6.1,1.8,12.3-0.9,12.3-4.5c0-3.5-6.2-6.1-12.3-4.3c-3.2,0.9-5.3,1.8-7.5,2.5c-1.1,0.4-2.2,0.8-3.3,0.9
					c-0.6,0.1-1.2,0.1-1.8,0c-0.6-0.1-1.3-0.5-1.8-1.1c0.3,0.8,0.9,1.4,1.5,1.9c0.6,0.4,1.3,0.7,2,1c0.6,0.2,1.3,0.4,1.9,0.6
					c0.6,0.2,1.1,0.3,1.7,0.5C397.6,393.2,399.8,394.1,402.9,395z"
                  />
                </g>
                <g>
                  <path
                    d="M394.7,382.8c3.1-0.8,5.5-3.3,6.3-5.6c0.9-2.4,0.2-4.4-1.3-5.3c-1.5-0.9-3.4-0.7-5.1-0.1c-1.7,0.6-3.2,1.7-4.5,3.6
					c-1.4,1.9-2.6,3.3-4,4.4c-1.4,1.1-3.1,2.2-5.9,2.7c2.8,0.5,4.8,0.8,6.9,1C389.3,383.5,391.5,383.6,394.7,382.8z"
                  />
                </g>
                <g>
                  <path
                    d="M386.4,375.6c2.7-1.9,4.1-4.9,4.2-7.4c0.1-2.5-1.1-4.4-2.8-4.8c-1.7-0.4-3.5,0.4-5,1.5c-1.5,1.2-2.6,2.9-3.3,5.2
					c-0.7,2.4-1.5,4.1-2.4,5.8c-1,1.6-2.2,3.2-4.6,4.8c2.8-0.6,4.8-1.2,6.9-1.8C381.5,378.3,383.7,377.5,386.4,375.6z"
                  />
                </g>
                <g>
                  <path
                    d="M375.7,369.1c1.3-2.5,1-5.5-0.2-7.3c-1.2-1.9-3.1-2.6-4.7-2.1c-1.7,0.5-2.9,1.9-3.6,3.3c-0.7,1.5-0.8,3-0.2,4.9
					c0.6,1.9,1,3.5,1.2,5.1c0.1,1.6,0,3.3-1,5.5c1.8-1.6,3.2-2.8,4.5-4.1C373.1,373.1,374.4,371.7,375.7,369.1z"
                  />
                </g>
                <g>
                  <path
                    d="M362.5,362.8c-0.5-1.4-1.4-2.6-2.5-3.4c-1.1-0.8-2.3-1.2-3.4-1.3c-1.1-0.1-2.1,0.2-2.8,0.8c-0.7,0.5-1.2,1.3-1.4,2.1
					c-0.4,1.7,0.1,3.4,0.5,4.6c0.5,1.2,1.1,1.9,2.5,2.4c1.5,0.5,2.7,1.2,3.6,2.3c0.9,1,1.7,2.4,1.8,4.6c1-2,1.6-3.6,2-5.4
					C363.2,367.7,363.5,365.7,362.5,362.8z"
                  />
                </g>
                <g>
                  <path
                    d="M344.5,362.7c-2.6-1.8-5.9-2-8.1-1.1c-2.3,0.9-3.4,2.7-3.2,4.4c0.3,1.8,1.6,3.1,3.1,4c1.5,0.9,3.3,1.3,5.5,1.1
					c2.3-0.2,4.1-0.2,5.8,0c1.8,0.3,3.6,0.8,5.8,2.5c-1.4-2.3-2.5-4-3.7-5.7C348.5,366.3,347.1,364.6,344.5,362.7z"
                  />
                </g>
                <g>
                  <path
                    d="M331.8,369.5c-2.3-1-4.8-0.5-6.4,0.6c-1.6,1.2-2.2,3-1.8,4.7c0.5,1.7,1.8,2.9,3.2,3.6c1.4,0.6,3,0.7,4.7-0.1
					c1.8-0.8,3.2-1.5,4.6-1.9c1.5-0.4,3-0.7,5.2-0.3c-1.8-1.3-3.1-2.3-4.4-3.4C335.6,371.6,334.2,370.5,331.8,369.5z"
                  />
                </g>
                <g>
                  <path
                    d="M311.8,380.6c-2.9,0.2-5.3,1.7-6.6,3.4c-1.4,1.8-1.7,3.8-0.7,5.3c0.9,1.5,2.8,2.1,4.8,2c2-0.1,4.1-0.9,5.9-2.7
					c1.9-1.8,3.2-3.1,4.7-4.3c1.5-1.2,3-2.3,5.6-3.4c-2.8,0-4.7-0.1-6.7-0.3C316.8,380.6,314.7,380.4,311.8,380.6z"
                  />
                </g>
                <g>
                  <path
                    d="M293.1,401.5c-4.3,1.1-5.8,5.7-3.6,8.4c2.2,2.8,7,2.4,9-1.5c2.1-4,2.4-6.1,5.1-9.6C299.6,400.6,297.5,400.4,293.1,401.5
					z"
                  />
                </g>
                <g>
                  <path
                    d="M280.3,421.6c-1.9,0.9-2.9,2.5-3.1,4.2c-0.2,1.7,0.5,3.3,2,4.4c1.4,1,3.2,1.2,4.7,0.7c1.5-0.5,2.7-1.7,3.1-3.6
					c0.9-3.9,0.8-5.9,2.7-9.4C286.3,419.9,284.3,419.9,280.3,421.6z"
                  />
                </g>
                <g>
                  <path
                    d="M314.7,432.9c4.2-2.3,7.9-5.1,10.4-7.8c2.5-2.7,3.8-5.2,2.9-6.7c-0.9-1.5-3.8-1.6-7.5-0.3c-3.7,1.3-8,4.1-11.4,8
					c-3.5,4-5.5,7-7.5,10c-2,3-4,6-7,10.3c3.8-3.6,6.7-5.7,9.7-7.5C307.3,436.9,310.4,435.2,314.7,432.9z"
                  />
                </g>
              </g>
              <g>
                <path
                  d="M344.5,109.3c1.3-4.4,1.9-9.1,1.4-13.7c-0.4-4.6-1.8-9.3-4.3-13.4c-0.3-0.5-0.6-1-1-1.5c-0.4-0.5-0.7-1-1.1-1.5
				c-0.8-0.9-1.5-1.8-2.4-2.7c-1.7-1.7-3.6-3.1-5.6-4.3c-4-2.4-8.3-3.8-12.6-4.4c-4.3-0.6-8.7-0.6-12.9,0.4
				c-4.2,0.9-8.3,2.7-11.6,5.5c-3.3,2.7-5.9,6.2-7.2,10.2c-0.7,1.9-1.1,4.1-1.1,6.1c0,2,0.2,4,0.8,5.9c1.1,3.7,3.3,7,6.3,9.2
				c3,2.2,6.5,3.1,9.6,3c3.1-0.1,6.1-1.1,8.3-2.8c1.1-0.9,2-1.9,2.7-2.9c0.7-1.1,1.2-2.2,1.4-3.2c0.6-2.1,0.5-4.1,0.2-5.7
				c-0.3-1.6-1-2.9-1.6-3.8c-1.4-1.8-2.6-2.1-2.9-1.8c-0.3,0.3,0.2,1.3,0.6,2.9c0.2,0.8,0.3,1.8,0.2,3c-0.1,1.1-0.4,2.4-1,3.7
				c-0.3,0.6-0.7,1.2-1.2,1.7c-0.5,0.5-1.1,0.9-1.7,1.3c-1.3,0.7-3,1.1-4.8,0.9c-1.8-0.2-3.7-0.9-5-2.1c-1.4-1.3-2.5-3.2-2.9-5.3
				c-0.2-1.1-0.3-2.2-0.2-3.4c0.1-1.1,0.4-2.2,0.8-3.3c0.9-2.2,2.6-4.3,4.7-6c2.1-1.6,4.8-2.7,7.7-3.3c2.9-0.6,6.2-0.6,9.4-0.1
				c6.4,1,12.6,4.2,15.9,9.6c3.5,5.4,4.4,12.8,2.7,19.6c-0.4,1.7-1,3.4-1.8,5c-0.7,1.6-1.6,3.1-2.6,4.6c-2,2.9-4.2,5.4-6.7,7.6
				c-5,4.4-10.6,7.5-16.3,9.7c-5.7,2.3-11.4,3.6-17.1,4.6c-5.7,1-11.4,1.5-17.1,1.9c-5.7,0.4-11.5,0.5-17.5,0.9
				c-6,0.4-12.3,1.1-18.6,3c-3.2,0.9-6.4,2.1-9.5,3.8c-3.1,1.6-6.1,3.7-8.8,6.3c-2.7,2.6-5,5.8-6.7,9.4c-1.6,3.6-2.6,7.8-2.2,12.1
				c-0.2-4.3,0.9-8.4,2.6-12c1.8-3.5,4.1-6.6,6.9-9c2.7-2.4,5.7-4.3,8.8-5.8c3.1-1.4,6.2-2.5,9.4-3.2c6.3-1.5,12.4-1.9,18.3-1.9
				c5.9-0.1,11.7,0.2,17.5,0.2c5.8,0,11.6-0.2,17.6-0.8c6-0.7,12.2-1.7,18.5-3.8c6.3-2,12.9-5,19-9.8c3.1-2.4,6-5.2,8.6-8.6
				C340.9,117.8,343.1,113.9,344.5,109.3z"
                />
              </g>
              <g>
                <path
                  d="M278.5,96c-3.9-1.2-8.4-1.3-12.5,0.5c-2,0.9-3.8,2.1-5.3,3.7c-1.5,1.5-2.7,3.3-3.6,5.3c-0.8,2-1.2,4.2-1.1,6.4
				c0.1,2.2,0.7,4.2,1.6,6c1.8,3.5,4.9,6.1,8.4,7c3.5,1,6.9,0.3,9.4-1.4c1.2-0.8,2.2-1.9,2.8-3c0.6-1.1,0.9-2.2,1-3.1
				c0.2-1.9-0.3-3.4-0.9-4.2c-0.6-0.8-1.2-1-1.6-0.9c-0.8,0.3-0.9,2.1-2.2,3.6c-0.3,0.4-0.7,0.8-1.1,1c-0.4,0.3-0.8,0.4-1.3,0.5
				c-1,0.2-2.4,0.1-3.4-0.5c-1.1-0.6-2.2-1.8-2.7-3.2c-0.6-1.5-0.5-2.8,0.1-4.4c0.7-1.6,2.1-3.1,3.8-4c1.7-0.9,3.9-1.2,6.3-0.9
				c4.9,0.7,8.5,4.3,10.6,8.1c2.2,3.9,3,8.1,2.7,12.3c-0.2,2.1-0.6,4.2-1.5,6.2c-0.4,1-1,2-1.6,3c-0.7,0.9-1.4,1.8-2.4,2.7
				c-1.8,1.6-4.1,3-6.9,3.8c-2.7,0.7-6,0.9-9.2-0.3c3.1,1.4,6.5,1.6,9.4,1.1c3-0.5,5.6-1.7,7.8-3.3c1.1-0.8,2.1-1.7,3-2.6
				c0.9-1,1.7-2,2.3-3.1c1.4-2.1,2.2-4.5,2.9-6.9c1.1-4.8,1-10-0.9-15.4c-1-2.7-2.4-5.4-4.7-8C285.7,99.6,282.5,97.2,278.5,96z"
                />
              </g>
              <g>
                <path
                  d="M315.1,122.6c2.7-2.4,4.1-5.8,4.2-8.5c0.1-2.8-0.9-4.8-2.6-5.3c-1.7-0.5-3.6,0.5-5.2,2.2c-1.6,1.7-2.9,3.9-3.7,6.8
				c-0.8,2.9-1.5,5-2.5,7.1c-1,2-2.1,4-4.5,6.4c3-1.5,5.1-2.6,7.3-3.8C310.2,126.3,312.4,125.1,315.1,122.6z"
                />
              </g>
              <g>
                <path
                  d="M366,89.8c2.5-1.7,4.3-3.8,5.4-5.9c1.1-2.1,1.5-4.2,0.4-5.7c-1-1.4-3.3-1.7-5.9-0.7c-2.6,1.1-5.4,3.6-6.8,6.9
				c-1.5,3.5-2,6-2.5,8.4c-0.4,2.5-0.8,4.9-1.4,8.3c1.5-3.1,3.1-4.9,4.7-6.6C361.7,93,363.5,91.6,366,89.8z"
                />
              </g>
              <g>
                <path
                  d="M379.9,95.6c3-0.8,5.4-2.4,6.9-4.3c1.5-1.8,2.2-3.9,1.4-5.5c-0.8-1.6-2.9-2.2-5.4-1.9c-2.5,0.3-5.4,1.7-7.6,4.1
				c-2.3,2.5-3.6,4.4-5,6.2c-1.4,1.8-2.8,3.6-5.1,6c2.9-1.5,5.1-2.3,7.2-2.9C374.6,96.9,376.8,96.4,379.9,95.6z"
                />
              </g>
              <g>
                <path
                  d="M390.9,105.2c3.1,0.2,5.9-0.4,8-1.5c2.1-1.1,3.7-2.6,3.6-4.4c0-1.7-1.7-3.3-4.4-4c-2.6-0.7-6.2-0.4-9.3,1.3
				c-3.2,1.7-5.2,3.3-7.1,4.7c-1.9,1.5-3.8,3-6.6,5c3.3-1.1,5.6-1.4,7.9-1.5C385.4,104.9,387.7,105,390.9,105.2z"
                />
              </g>
              <g>
                <path
                  d="M360.3,116.8c2.7-0.8,4.8-2.3,6.1-4c1.3-1.7,2-3.7,1.2-5.3c-0.7-1.6-2.7-2.4-5.1-2.1c-2.4,0.3-5.2,1.7-7.2,4.2
				c-2,2.5-3.1,4.5-4.2,6.4c-1.1,1.9-2.2,3.8-3.9,6.3c2.5-1.8,4.4-2.7,6.3-3.5C355.5,118.1,357.5,117.6,360.3,116.8z"
                />
              </g>
              <g>
                <path
                  d="M373.4,121.3c2.6,0.1,4.9-0.6,6.6-1.8c1.7-1.2,2.8-2.9,2.6-4.6c-0.2-1.7-1.8-3.1-4.1-3.6c-2.3-0.5-5.2,0-7.7,1.7
				c-2.5,1.8-4.1,3.3-5.6,4.7c-1.5,1.5-3,2.9-5.3,4.7c2.8-0.9,4.8-1.1,6.7-1.2C368.7,121,370.7,121.2,373.4,121.3z"
                />
              </g>
              <g>
                <path
                  d="M293.2,147.9c-6.4,0.5-11.3,5.2-10.1,8.5c1.2,3.3,8,3.6,13.2-0.3c5.3-4,7-6.2,12.8-9.5
				C302.5,148,299.8,147.4,293.2,147.9z"
                />
              </g>
              <g>
                <path
                  d="M300.9,162.8c-1.7,2.4-2,5.5-1.2,7.6c0.7,2.2,2.4,3.5,4.1,3.4c1.8-0.1,3.2-1.3,4.2-2.8c1-1.5,1.5-3.4,1.3-5.6
				c-0.2-2.3-0.3-4.1-0.2-5.9c0.2-1.8,0.5-3.6,1.8-5.9c-2.1,1.6-3.6,2.8-5.1,4.1C304.2,159,302.7,160.4,300.9,162.8z"
                />
              </g>
              <g>
                <path
                  d="M250.7,158.6c5.2-0.2,10.2-0.8,14.1-1.6c3.9-0.8,6.7-2,7-3.7c0.2-1.7-2.4-3.7-6.9-4.6c-4.4-1-10.5-0.7-16.1,1.4
				c-5.7,2.2-9.2,4.4-12.7,6.7c-3.4,2.3-6.7,4.7-11.2,8.5c5.1-2.9,9-4.2,12.8-5.1C241.5,159.3,245.3,158.8,250.7,158.6z"
                />
              </g>
              <g>
                <path
                  d="M452.7,61.6c-0.5-3.2-1.3-6.4-2.5-9.5c-1.2-3.1-2.9-6.2-5.1-8.9c-2.2-2.7-5-5-8.1-6.7c-3.1-1.7-6.7-2.7-10.3-2.8l-1.4,0
				l-1.4,0.1c-0.9,0.1-1.8,0.3-2.7,0.5c-1.8,0.5-3.4,1.1-4.9,2c-3,1.7-5.6,4.2-7.3,7.3c-1.7,3-2.4,6.8-1.9,10
				c0.2,1.6,0.7,3.2,1.3,4.6c0.6,1.4,1.4,2.7,2.2,3.8c3.4,4.5,8.5,7,13.1,7.2c2.3,0.1,4.6-0.3,6.5-1.3c1-0.5,1.8-1.1,2.5-1.8
				c0.7-0.7,1.2-1.5,1.7-2.3c1.5-3.2,1-6,0-7.5c-0.5-0.8-1-1.2-1.4-1.4c-0.4-0.2-0.7-0.2-0.9,0c-0.3,0.3-0.1,1.1-0.1,2.2
				c0,0.6-0.1,1.2-0.4,1.9c-0.3,0.7-0.7,1.5-1.3,2.1c-0.3,0.3-0.7,0.6-1,0.8c-0.3,0.2-0.8,0.4-1.3,0.5c-1,0.2-2.2,0.2-3.5-0.1
				c-2.6-0.6-5.4-2.4-7.1-5.2c-0.4-0.7-0.8-1.4-1-2.2c-0.2-0.8-0.4-1.5-0.4-2.3c-0.1-0.8,0-1.5,0.2-2.2c0.2-0.7,0.5-1.4,0.9-2.1
				c0.8-1.4,2.2-2.6,3.9-3.5c0.8-0.4,1.7-0.8,2.6-1c0.4-0.1,0.9-0.2,1.4-0.2l0.7,0l0.7,0c1.9,0.1,4,0.6,5.9,1.7
				c1.9,1,3.8,2.5,5.3,4.2c3.1,3.5,5.2,8.4,6.4,13.7c1.2,5.5,1.6,10.9,1.4,15.9c-0.2,5-0.9,9.7-1.9,14.1c-2.1,8.8-5.4,16.4-9.6,23.5
				c-4.2,7.1-9.5,13.8-16.3,20.4c-6.8,6.6-15.2,13.2-26.1,19.6c11.2-6,20-12.1,27.3-18.3c7.3-6.2,13.3-12.6,18.3-19.6
				c5-7,9.2-14.7,12.3-23.9c1.5-4.6,2.7-9.6,3.4-15.1C453.5,74.1,453.6,68.1,452.7,61.6z"
                />
              </g>
              <g>
                <path
                  d="M420.9,70.1c-1.7-0.9-3.7-1.5-5.7-1.6c-2.1-0.2-4.2,0.2-6.2,0.9c-1.9,0.7-3.7,1.8-5.2,3.1c-1.5,1.4-2.7,3-3.5,5
				c-0.8,1.9-1.2,4.1-1.1,6c0.1,2,0.6,3.8,1.4,5.5c1.6,3.3,4.3,5.8,7.4,6.9c1.6,0.6,3.2,0.8,4.6,0.6c1.4-0.1,2.7-0.5,3.8-0.9
				c2.2-1,3.7-2.4,4.6-3.8c0.9-1.4,1.2-2.7,1.1-3.6c-0.1-0.9-0.4-1.4-0.8-1.5c-0.9-0.2-1.9,0.9-3.8,1.6c-0.9,0.4-2,0.7-3.2,0.8
				c-1.2,0-2.4-0.2-3.2-0.9c-0.9-0.6-1.7-1.7-2.1-3c-0.2-0.6-0.3-1.3-0.3-2c0-0.6,0.2-1.2,0.4-1.8c0.5-1.2,1.7-2.4,3.2-3
				c0.7-0.3,1.5-0.5,2.2-0.6c0.8,0,1.7,0.1,2.6,0.3c1.9,0.6,3.8,1.9,5.4,3.4c0.8,0.8,1.5,1.6,2.2,2.4c0.7,0.8,1.3,1.7,1.9,2.6
				c2.3,3.5,3.9,7.1,5,10.9c1.1,3.8,1.7,7.8,1.5,12.4c0,0.6-0.1,1.1-0.1,1.7c0,0.6-0.1,1.2-0.2,1.8c-0.1,1.2-0.4,2.4-0.6,3.7
				c-0.6,2.5-1.4,5.2-2.7,8c1.5-2.7,2.7-5.3,3.5-7.8c0.4-1.3,0.8-2.5,1.1-3.7c0.1-0.6,0.3-1.2,0.4-1.8c0.1-0.6,0.2-1.2,0.3-1.8
				c0.8-4.7,0.9-9,0.5-13.3c-0.5-4.3-1.4-8.6-3.3-13.1c-0.5-1.1-1-2.3-1.7-3.4c-0.6-1.2-1.4-2.4-2.2-3.5
				C426.7,74.4,424.4,72,420.9,70.1z"
                />
              </g>
              <g>
                <g>
                  <path
                    d="M503.8,68.5c5.7-0.7,11.4-1.1,16.9-1.7c5.5-0.5,10.7-1.1,15.1-2c4.5-0.8,8.2-1.9,10.7-2.9c2.5-1.1,3.9-2.2,3.7-3
					c-0.2-0.9-1.8-1.4-4.4-1.8c-2.6-0.4-6.3-0.5-10.8-0.6c-4.4-0.1-9.6,0-15.3,0.4c-5.6,0.4-11.8,1.3-17.7,2.9
					c-6.2,1.7-11.2,3.7-15.6,6c-4.3,2.3-8,4.8-11.3,7.8c-0.8,0.7-1.6,1.5-2.4,2.3c-0.8,0.8-1.5,1.6-2.3,2.5
					c-1.5,1.7-2.9,3.6-4.2,5.6c-2.6,4.1-4.9,9.2-5.6,15.5c1.2-6.2,4.1-10.9,7-14.5c1.5-1.8,3.1-3.4,4.7-4.7c0.8-0.7,1.7-1.3,2.5-2
					c0.9-0.6,1.7-1.2,2.6-1.7c3.5-2.2,7.2-3.8,11.4-5.1C493.2,70.2,498,69.2,503.8,68.5z"
                  />
                </g>
                <g>
                  <path
                    d="M512,85.7c5.6-1.4,11.1-3.6,15.9-6.1c4.8-2.5,9.1-5.5,12.4-8.3c6.7-5.7,9.8-10.9,8.6-12.1c-1.2-1.3-6.2,1.4-13.3,5.2
					c-7.1,3.9-16.5,8.8-26.3,13c-5.1,2.2-9.5,3.7-13.6,4.9c-4.1,1.2-8,2.1-11.9,3.3c-3.9,1.1-7.8,2.4-11.9,4.4c-2,1-4.2,2.2-6.2,3.8
					c-2.1,1.5-4.1,3.5-5.8,5.9c1.9-2.2,4.1-3.9,6.3-5.2c2.2-1.3,4.3-2.2,6.4-2.9c4.2-1.4,8.1-1.9,12-2.3c3.9-0.4,7.8-0.5,12.3-1
					C501.2,87.9,506.2,87.1,512,85.7z"
                  />
                </g>
                <g>
                  <path
                    d="M519.6,76.1c6.3-1,12.2-3.4,16.2-6c4.1-2.6,6.3-5.3,5.6-6.9c-0.7-1.6-4.1-1.8-8.7-0.9c-4.5,0.9-10.2,2.7-15.7,5.4
					c-5.7,2.7-9.6,4.6-13.6,6.3c-4.1,1.7-8.3,3.3-14.6,4.9c6.6-0.6,11-0.9,15.5-1.2C508.7,77.4,513.2,77.1,519.6,76.1z"
                  />
                </g>
                <g>
                  <path
                    d="M517.7,72.2c4.9-0.5,9.4-2.3,12.5-4.4c3.1-2.1,4.8-4.4,4.1-6c-0.7-1.6-3.4-2.2-7-1.8c-3.6,0.4-8,1.7-12.2,3.8
					c-4.3,2.3-7.3,3.9-10.4,5.4c-3.1,1.5-6.2,2.9-11.1,4.4c5-0.4,8.5-0.6,12-0.7C509.2,72.8,512.7,72.7,517.7,72.2z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M490.4,213.7c-5.6-1.1-11.2-2.4-16.6-3.5c-5.4-1.2-10.5-2.2-15-2.7c-4.5-0.6-8.4-0.7-11.1-0.5c-2.7,0.3-4.3,0.9-4.5,1.8
					c-0.1,0.9,1.2,1.9,3.6,3c2.4,1.1,5.9,2.4,10.1,3.8c4.2,1.4,9.2,2.9,14.7,4.2c5.5,1.3,11.6,2.3,17.8,2.6c6.4,0.2,11.8-0.1,16.7-1
					c4.8-0.9,9.1-2.1,13.1-4c1-0.4,2-1,3-1.4c1-0.5,2-1.1,2.9-1.7c1.9-1.2,3.8-2.5,5.7-4.1c3.7-3.1,7.4-7.3,10-13.1
					c-3.1,5.6-7.2,9.1-11.1,11.7c-2,1.3-3.9,2.3-5.9,3.1c-1,0.4-2,0.8-2.9,1.1c-1,0.3-2,0.6-3,0.8c-3.9,1-8,1.4-12.4,1.4
					C501.1,215.3,496.2,214.8,490.4,213.7z"
                  />
                </g>
                <g>
                  <path
                    d="M487.9,194.8c-5.8-0.4-11.6,0.1-17,1c-5.4,1-10.3,2.5-14.3,4.2c-8.1,3.4-12.6,7.4-11.8,8.9c0.8,1.6,6.3,0.6,14.3-0.9
					c8-1.5,18.4-3.4,29-4.4c5.5-0.6,10.2-0.7,14.5-0.6c4.3,0.1,8.3,0.4,12.3,0.5c4,0.1,8.2,0.1,12.7-0.6c2.3-0.4,4.6-0.9,7.1-1.7
					c2.4-0.9,4.9-2.1,7.3-3.9c-2.5,1.6-5.1,2.5-7.5,3.1c-2.4,0.6-4.8,0.8-7,0.8c-4.4,0-8.3-0.6-12.1-1.4c-3.8-0.8-7.6-1.9-12-2.8
					C498.8,196,493.9,195.2,487.9,194.8z"
                  />
                </g>
                <g>
                  <path
                    d="M477.7,201.6c-6.3-0.9-12.6-0.4-17.3,0.8c-4.7,1.2-7.6,3.1-7.4,4.9c0.2,1.8,3.4,2.9,8,3.5c4.6,0.5,10.6,0.5,16.6-0.4
					c6.2-0.9,10.5-1.5,14.9-1.9c4.4-0.4,8.9-0.6,15.4-0.3c-6.4-1.4-10.8-2.5-15.2-3.5C488.5,203.7,484.2,202.6,477.7,201.6z"
                  />
                </g>
                <g>
                  <path
                    d="M478.3,205.9c-4.8-1-9.7-0.7-13.3,0.4c-3.6,1-5.9,2.8-5.8,4.5c0.1,1.8,2.6,3.1,6.1,3.9c3.5,0.7,8.1,0.8,12.8,0
					c4.8-0.8,8.1-1.5,11.5-2c3.4-0.5,6.8-0.9,11.9-0.8c-4.9-1.1-8.3-2-11.6-3C486.6,207.9,483.3,206.9,478.3,205.9z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M295.7,319.1c4.1-3.9,8.4-7.8,12.5-11.5c4.1-3.7,7.9-7.3,10.9-10.7c3.1-3.3,5.4-6.4,6.8-8.8c1.4-2.4,1.8-4.1,1.2-4.7
					c-0.6-0.6-2.3-0.1-4.6,1.2c-2.3,1.3-5.4,3.4-9,6c-3.6,2.6-7.7,5.8-12,9.5c-4.2,3.7-8.6,8.1-12.4,13c-3.9,5.1-6.7,9.7-8.9,14.2
					c-2.1,4.4-3.5,8.7-4.4,13c-0.2,1.1-0.4,2.2-0.6,3.3c-0.1,1.1-0.3,2.2-0.3,3.3c-0.2,2.3-0.2,4.6,0,7c0.4,4.8,1.6,10.3,4.8,15.8
					c-2.8-5.7-3.3-11.1-3.1-15.8c0.1-2.3,0.4-4.5,0.9-6.6c0.2-1,0.5-2,0.8-3c0.3-1,0.6-2,1-2.9c1.5-3.8,3.5-7.3,6.1-11
					C288.2,326.9,291.4,323.2,295.7,319.1z"
                  />
                </g>
                <g>
                  <path
                    d="M312.5,328c3.7-4.5,6.7-9.5,9-14.5c2.3-4.9,4-9.8,4.9-14.1c1.9-8.6,1.3-14.5-0.4-14.8c-1.8-0.3-4.2,4.8-7.5,12.2
					c-3.4,7.4-7.9,16.9-13.3,26.2c-2.7,4.8-5.4,8.7-7.9,12.1c-2.6,3.4-5.1,6.5-7.5,9.7c-2.4,3.2-4.8,6.6-6.9,10.7
					c-1,2.1-2,4.3-2.7,6.8c-0.7,2.5-1.2,5.2-1.1,8.2c0.2-3,0.9-5.6,1.9-7.9c1-2.3,2.2-4.3,3.4-6.1c2.5-3.6,5.3-6.4,8.2-9
					c2.9-2.7,5.9-5.1,9.3-8.2C305.2,336.2,308.7,332.7,312.5,328z"
                  />
                </g>
                <g>
                  <path
                    d="M312.9,315.8c4.4-4.6,7.7-10,9.4-14.5c1.7-4.5,1.8-8,0.3-8.8c-1.6-0.9-4.4,1.1-7.5,4.5c-3.1,3.4-6.5,8.3-9.3,13.8
					c-2.9,5.6-4.9,9.4-7.1,13.3c-2.2,3.8-4.6,7.6-8.7,12.7c4.9-4.4,8.3-7.4,11.7-10.3C305,323.4,308.3,320.5,312.9,315.8z"
                  />
                </g>
                <g>
                  <path
                    d="M309,313.8c3.6-3.4,6.2-7.5,7.4-11c1.2-3.5,1.2-6.4-0.3-7.3c-1.5-0.9-4,0.3-6.7,2.7c-2.6,2.5-5.4,6.1-7.4,10.4
					c-2.1,4.4-3.5,7.5-5,10.5c-1.6,3-3.3,6.1-6.2,10.2c3.8-3.4,6.5-5.6,9.2-7.8C302.6,319.3,305.3,317.2,309,313.8z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M112.7,104.3c4.4,3.7,8.6,7.6,12.7,11.2c4.1,3.6,8.1,7,11.7,9.8c3.6,2.7,6.9,4.7,9.5,5.9c2.5,1.1,4.2,1.4,4.7,0.7
					c0.5-0.7-0.2-2.2-1.7-4.4c-1.5-2.2-3.9-5-6.9-8.3c-3-3.3-6.6-7.1-10.7-10.9c-4.2-3.8-8.9-7.7-14.2-11c-5.4-3.3-10.4-5.7-15-7.3
					c-4.6-1.6-9-2.6-13.4-3c-1.1-0.1-2.2-0.1-3.3-0.2c-1.1,0-2.2,0-3.4,0c-2.3,0.1-4.6,0.3-7,0.8c-4.8,0.9-10.1,2.7-15.2,6.4
					c5.4-3.3,10.7-4.4,15.4-4.7c2.3-0.1,4.6,0,6.7,0.2c1.1,0.1,2.1,0.3,3.1,0.5c1,0.2,2,0.4,3,0.7c3.9,1.1,7.6,2.7,11.5,4.9
					C104.2,97.6,108.2,100.5,112.7,104.3z"
                  />
                </g>
                <g>
                  <path
                    d="M105.7,121.9c4.9,3.2,10.2,5.7,15.3,7.5c5.2,1.8,10.2,2.9,14.5,3.4c8.7,1,14.6-0.3,14.7-2c0.1-1.8-5.2-3.6-12.9-6.2
					c-7.7-2.6-17.7-6.1-27.5-10.4c-5.1-2.2-9.2-4.4-12.9-6.6c-3.7-2.2-7-4.4-10.5-6.5C83,99,79.4,97,75.1,95.4c-2.1-0.8-4.5-1.5-7-2
					c-2.5-0.5-5.3-0.6-8.3-0.2c3-0.1,5.7,0.3,8.1,1c2.4,0.7,4.5,1.7,6.5,2.7c3.9,2.1,6.9,4.6,9.8,7.2c2.9,2.6,5.7,5.4,9.1,8.4
					C96.7,115.5,100.6,118.7,105.7,121.9z"
                  />
                </g>
                <g>
                  <path
                    d="M117.9,121c5,3.9,10.8,6.6,15.4,7.8c4.7,1.2,8.1,1,8.8-0.6c0.7-1.6-1.5-4.2-5.3-7c-3.7-2.7-9-5.6-14.7-7.8
					c-5.8-2.3-9.9-3.9-13.9-5.7c-4-1.8-8-3.8-13.6-7.4c4.9,4.4,8.2,7.5,11.5,10.5C109.5,113.9,112.7,117,117.9,121z"
                  />
                </g>
                <g>
                  <path
                    d="M119.5,117c3.7,3.2,8.1,5.3,11.7,6.2c3.6,0.9,6.5,0.5,7.2-1.1c0.8-1.6-0.7-4-3.4-6.4c-2.7-2.4-6.7-4.7-11.1-6.3
					c-4.6-1.6-7.8-2.7-11-3.9c-3.2-1.2-6.4-2.6-10.8-5.1c3.8,3.4,6.2,5.8,8.7,8.3C113.2,111.2,115.6,113.7,119.5,117z"
                  />
                </g>
              </g>
              <g>
                <path
                  d="M46.9,120.2c-4.8-3.3-10.4-5.5-16.3-6.1c-2.9-0.3-5.9-0.2-8.8,0.3c-2.9,0.5-5.8,1.4-8.4,2.9c-2.6,1.5-5.1,3.5-6.9,6.1
				c-0.5,0.7-0.9,1.3-1.2,2c-0.3,0.7-0.7,1.5-0.9,2.2L4,128.7l-0.2,1.1c-0.2,0.8-0.2,1.5-0.3,2.3c-0.1,3,0.5,5.8,1.6,8.3
				c1.1,2.5,2.6,4.6,4.5,6.3c1.8,1.7,4,3,6.2,3.6c2.1,0.7,4.2,1,6.1,0.9c1.9-0.1,3.6-0.4,5.2-0.9c1.5-0.5,2.8-1.1,3.9-1.8
				c2.2-1.3,3.6-2.9,4.3-4c0.7-1.2,0.8-2,0.5-2.2c-0.3-0.3-1.1-0.1-2.2,0.3c-1.1,0.4-2.6,1.1-4.6,1.5c-1,0.2-2,0.4-3.2,0.5
				c-1.1,0.1-2.4,0-3.6-0.2c-1.2-0.2-2.5-0.6-3.6-1.2c-1.2-0.6-2.1-1.4-3-2.4c-1.8-2.1-2.9-5.2-2.6-8.1c0.1-0.3,0.1-0.7,0.2-1
				l0.1-0.5l0.2-0.5c0.1-0.3,0.3-0.6,0.4-0.9c0.2-0.3,0.4-0.6,0.6-0.9c0.9-1.2,2.2-2.2,3.7-3.1c1.6-0.9,3.4-1.5,5.4-1.8
				c2-0.4,4.1-0.5,6.2-0.3c4.2,0.3,8.6,1.6,12.4,3.9c4,2.3,7.2,5.4,9.7,8.7c2.5,3.3,4.3,6.9,5.7,10.4c2.6,7.1,3.3,14.2,2.9,21.3
				c-0.4,7.1-1.8,14.3-4.7,22c-3,7.6-7.4,15.8-14.9,23.8c7.8-7.7,12.8-15.6,16.5-23.2c3.6-7.6,5.7-14.8,6.9-22.3
				c0.6-3.7,0.9-7.5,0.8-11.4c-0.1-3.9-0.5-8-1.6-12.1c-1.1-4.2-2.8-8.5-5.4-12.7C55.4,127.8,51.9,123.6,46.9,120.2z"
                />
              </g>
              <g>
                <path
                  d="M34,153.7c-3,0.6-5.5,2.1-7.5,4c-2,1.9-3.5,4.3-4.3,7c-0.4,1.3-0.6,2.7-0.6,4.1c0,1.4,0.2,2.8,0.7,4.1
				c0.8,2.5,2.4,4.7,4.4,6.1c1,0.7,2,1.2,3.1,1.5c1.1,0.3,2.1,0.4,3,0.4c1.9-0.1,3.4-0.7,4.4-1.5c1-0.8,1.6-1.6,1.8-2.3
				c0.2-0.7,0-1.2-0.3-1.5c-0.7-0.6-1.9-0.4-3.2-0.7c-0.7-0.1-1.4-0.4-2-0.7c-0.3-0.2-0.6-0.4-0.8-0.6c-0.2-0.2-0.4-0.5-0.6-0.8
				c-0.4-0.6-0.7-1.4-0.8-2.3c-0.1-0.4-0.1-0.9,0-1.3c0-0.5,0.2-1,0.3-1.5c0.6-2.1,2.6-4.5,4.7-5.5c1.1-0.5,2.1-0.7,3.3-0.5
				c1.2,0.2,2.3,0.8,3.3,1.6c1,0.8,1.8,1.7,2.5,2.8c0.7,1.1,1.2,2.3,1.6,3.6c0.8,2.6,1,5.5,0.9,8.8c-0.1,3.3-0.7,7-0.7,11.4
				c0.6-4.4,1.7-7.9,2.4-11.2c0.8-3.3,1.3-6.4,1.2-9.6c-0.1-1.6-0.3-3.3-0.8-4.9c-0.5-1.7-1.3-3.4-2.5-5c-1.2-1.6-2.8-3.2-5.1-4.3
				c-1.1-0.6-2.4-1-3.8-1.2C37.1,153.3,35.5,153.4,34,153.7z"
                />
              </g>
              <g>
                <path
                  d="M80.9,129c2-0.8,3.3-2.1,4-3.7c0.7-1.6,0.9-3.4,0.1-5c-0.8-1.5-2.7-2.3-4.8-1.9c-2.1,0.4-4.4,2-5.6,4.5
				c-1.2,2.6-1.6,4.5-1.9,6.4c-0.3,1.9-0.7,3.6-1.1,6.1c1.5-2,2.9-3.1,4.3-4C77.3,130.6,78.8,129.9,80.9,129z"
                />
              </g>
              <g>
                <path
                  d="M87.6,140.1c1.9,0,3.2-0.8,4.1-2.1c0.9-1.2,1.4-2.9,1-4.7c-0.4-1.7-1.8-3-3.7-3.3c-1.9-0.3-4.2,0.4-5.7,2.3
				c-1.5,2-2.3,3.5-3,5c-0.7,1.5-1.3,2.9-2.3,4.8c1.8-1.2,3.2-1.6,4.7-1.9C84.1,140.2,85.6,140.1,87.6,140.1z"
                />
              </g>
              <g>
                <path
                  d="M69.7,113.9c0.7-3.1,0.1-6.2-1.2-8.3c-1.3-2.1-3.2-3.1-4.9-2.7c-1.7,0.5-2.8,2.2-3.3,4.3c-0.5,2.1-0.3,4.6,0.7,7.2
				c1.1,2.6,1.8,4.6,2.5,6.6c0.6,2,1,4.1,1,7.1c1.1-2.9,1.9-4.9,2.8-6.9C68.1,119.2,69,117.1,69.7,113.9z"
                />
              </g>
              <g>
                <path d="M51.6,99.8c-0.9-5.8-6-9.3-9.1-7.5c-3,1.8-2.7,7.7,1.3,11.5c4.1,4,6.3,5.3,9.7,10.1C52.1,108.1,52.6,105.7,51.6,99.8z" />
              </g>
              <g>
                <path
                  d="M25.9,96.2c-2.9-2.2-6.5-3.1-9.2-2.9c-2.8,0.2-4.8,1.4-5.1,3.1c-0.3,1.7,0.9,3.6,3,5c2,1.5,4.9,2.6,8.1,2.9
				c3.3,0.4,5.7,0.6,8.1,1.1c2.4,0.5,4.7,1.1,7.9,2.7c-2.6-2.5-4.3-4.2-6.1-6C30.7,100.2,28.9,98.4,25.9,96.2z"
                />
              </g>
              <g>
                <path
                  d="M-1.6,103.7c-2.7-0.3-5.2,0.9-6.5,2.5c-1.4,1.7-1.5,3.6-0.6,5.1c1,1.5,2.6,2.2,4.2,2.4c1.6,0.2,3.2-0.2,4.8-1.5
				c1.6-1.3,3-2.3,4.4-3c1.4-0.8,3-1.3,5.4-1.4c-2.1-0.9-3.7-1.6-5.5-2.4C3,104.7,1.2,104-1.6,103.7z"
                />
              </g>
              <g>
                <path
                  d="M-13.1,124.3c-2.5,1.7-3.7,4.6-3.5,6.9c0.2,2.4,1.6,3.9,3.3,4.1c1.8,0.2,3.4-0.6,4.6-1.5c1.3-1,2.1-2.2,2.5-4.1
				c0.4-2,0.9-3.5,1.7-5c0.8-1.4,1.9-2.8,4.1-4c-2.5,0.2-4.3,0.5-6.2,0.9C-8.6,122.1-10.6,122.6-13.1,124.3z"
                />
              </g>
              <g>
                <path
                  d="M-9.6,149.9c-0.9,2.6-0.3,5.4,1.1,7.1c1.4,1.7,3.3,2.2,4.9,1.5c1.6-0.7,2.7-2.2,3.2-3.7c0.5-1.5,0.5-3.1-0.4-4.8
				c-0.9-1.8-1.5-3.3-1.9-4.9c-0.3-1.6-0.5-3.2,0.1-5.5c-1.6,1.8-2.7,3.2-3.8,4.6C-7.5,145.6-8.6,147.2-9.6,149.9z"
                />
              </g>
              <g>
                <path
                  d="M3.1,168.8c0.4,2.9,2.4,5.2,4.4,6.2c2.1,1,4.1,0.7,5.2-0.6c1.2-1.3,1.4-3.2,1.1-4.9c-0.3-1.7-1.2-3.3-2.9-4.7
				c-1.7-1.4-3-2.6-4.1-3.9c-1.1-1.3-2-2.9-2.7-5.4c-0.4,2.6-0.7,4.4-1,6.4C2.9,163.8,2.7,165.8,3.1,168.8z"
                />
              </g>
              <g>
                <path
                  d="M75.4,229.7c2.3,1.4,4,3.2,4.8,5.2c0.9,2,0.9,4.3,0.3,5.8c-0.6,1.5-2,2.8-3.6,3.4c-0.8,0.3-1.5,0.4-2.3,0.4
				c-0.8,0-1.6-0.2-2.3-0.6c-1.5-0.6-2.7-1.8-3.4-2.9c-0.7-1.1-0.9-2.1-0.8-2.9c0.1-0.8,0.6-1.6,1.1-2c0.4-0.5,0.9-0.8,1-1.2
				c0.1-0.4-0.3-1-1.5-1.3c-1.1-0.2-3,0.1-4.7,1.8c-0.4,0.4-0.8,1-1.1,1.6c-0.3,0.6-0.5,1.2-0.7,1.9c-0.3,1.4-0.3,2.8,0.1,4.3
				c0.7,2.9,2.5,5.8,5.5,7.9c1.5,1,3.3,1.8,5.3,2.3c2,0.4,4.2,0.4,6.3-0.2c2.1-0.5,4.1-1.5,5.9-3c1.8-1.4,3.3-3.4,4.2-5.7
				c0.9-2.3,1.3-4.7,1.1-7c-0.1-2.3-0.8-4.6-1.8-6.7c-1-2.1-2.5-4-4.2-5.5c-1.7-1.5-3.6-2.6-5.6-3.4c-4.1-1.7-8.1-2-11.5-1.8
				c-3.5,0.2-6.5,0.9-9.3,1.7c-5.5,1.7-10.2,3.7-14.6,6.1c-4.5,2.5-8.8,5.2-13,9c-2.1,1.9-4.3,4-6.3,6.4c-2.1,2.5-4.1,5.2-5.8,8.5
				c2-3.1,4.2-5.7,6.4-7.9c2.2-2.2,4.5-4.1,6.8-5.6c4.5-3.2,9-5.2,13.5-6.9c4.5-1.7,9.2-2.8,14.4-3.6c2.6-0.3,5.2-0.5,7.9-0.3
				c1.3,0.1,2.7,0.3,4,0.7C72.9,228.5,74.2,229,75.4,229.7z"
                />
              </g>
              <g>
                <path
                  d="M55.5,215c4.5-2.5,8.1-6.3,10.1-9.7c2-3.4,2.4-6.3,1-7.5c-1.4-1.1-4.1-0.2-7.1,1.7c-3.1,2-6.5,5-9.6,8.6
				c-3.2,3.8-5.4,6.3-7.9,8.8c-2.5,2.5-5.2,4.8-9.6,7.6c4.8-1.8,8.2-3.1,11.7-4.4C47.5,218.9,50.9,217.5,55.5,215z"
                />
              </g>
              <g>
                <path
                  d="M75.4,216.2c2.3,0.5,4.3,0.1,5.9-0.8c1.6-1,2.7-2.5,2.7-4.3c0-1.8-1.2-3.3-3.1-4.1c-1.9-0.8-4.5-0.8-6.8,0.5
				c-2.4,1.3-3.8,2.5-5.3,3.6c-1.5,1.1-2.9,2.2-5.1,3.5c2.5-0.3,4.3-0.1,6,0.2C71.3,215.2,73,215.7,75.4,216.2z"
                />
              </g>
              <g>
                <path
                  d="M35.3,208.6c0.9-2.4,1.3-5,1.3-7.4c0-0.6,0-1.2-0.1-1.8c-0.1-0.6-0.1-1.1-0.2-1.7c-0.2-1.2-0.6-2.3-1.1-3.4
				c-0.5-1.1-1.1-2-1.9-2.8c-0.7-0.8-1.6-1.3-2.3-1.6c-1.6-0.5-2.6,0-3,0.7c-0.4,0.8-0.3,1.8-0.5,2.6c-0.1,0.9-0.3,1.8-0.4,3
				c-0.1,0.7-0.1,1.4-0.2,2.1c0,0.4-0.1,0.9-0.1,1.3c0,0.5,0,0.9-0.1,1.4c-0.1,1.9-0.1,3.9,0,6c0.1,4.3-0.2,7.5-0.8,10.6
				c-0.7,3.1-1.8,6.3-4.7,10.3c3.7-3.3,5.9-5.9,8.1-8.7c1.1-1.4,2.1-2.8,3.1-4.5C33.4,213,34.4,211.1,35.3,208.6z"
                />
              </g>
              <g>
                <path
                  d="M124.7,53.8c3.3-0.9,6.7-1.7,9-1.4c1.2,0.1,2,0.4,2.7,0.9c0.6,0.4,1.2,1.1,1.6,2c0.9,1.8,1,4.3,0.5,6.2
				c-0.1,0.5-0.3,1-0.5,1.5c-0.2,0.5-0.5,0.9-0.7,1.4c-0.5,0.8-1.2,1.5-1.8,2.1c-1.2,1-2.9,1.5-4.2,1.4c-0.7,0-1.3-0.2-1.8-0.4
				c-0.5-0.3-1-0.6-1.4-1c-1.7-1.6-1.8-3.6-2.6-3.9c-0.4-0.1-1,0.2-1.5,1.2c-0.5,1-0.8,2.8-0.1,4.9c0.3,1,0.9,2.2,1.9,3.2
				c1,1.1,2.2,2,3.8,2.6c3,1.2,6.9,1.2,10.4-0.7c1.7-0.9,3.3-2.2,4.6-3.7c0.7-0.8,1.2-1.6,1.8-2.5c0.5-0.9,1-1.8,1.4-2.9
				c0.8-2.1,1.1-4.3,1.1-6.5c-0.1-2.3-0.5-4.6-1.5-6.9c-1.1-2.3-2.8-4.5-5.2-6c-2.4-1.5-5.1-2.2-7.6-2.3c-2.5-0.1-4.7,0.3-6.8,0.9
				c-2.1,0.6-3.9,1.2-5.7,2c-3.8,1.6-7.6,3.8-10.3,7c-1.3,1.6-2.3,3.5-2.9,5.3c-0.6,1.8-0.9,3.6-0.9,5.3c-0.1,3.4,0.5,6.4,1.3,9.2
				c0.9,2.8,2,5.4,3.4,7.8c2.8,4.8,6.6,9.1,11.7,12.4c2.6,1.7,5.5,3.1,8.7,4.2c3.3,1.1,6.9,1.7,10.9,1.8c-3.9-0.3-7.5-1.3-10.6-2.6
				c-3.1-1.3-5.7-3-8-4.8c-4.5-3.7-7.5-8.1-9.4-12.7c-1-2.3-1.6-4.7-2-7.2c-0.4-2.5-0.5-5,0-7.5c0.2-1.2,0.6-2.4,1.1-3.4
				c0.5-1,1.2-2,2.1-2.8C118.7,56,121.3,54.8,124.7,53.8z"
                />
              </g>
              <g>
                <path
                  d="M86,72c-3-1.1-6.3-0.6-8.4,0.7c-2.1,1.3-3.1,3.2-2.6,4.9c0.5,1.7,2.2,2.8,4,3.3c1.9,0.6,4,0.6,6.4-0.2
				c2.5-0.8,4.3-1.2,6.3-1.4c1.9-0.2,4-0.2,6.8,0.7c-2.3-1.8-4-3.1-5.7-4.3C90.9,74.4,89.1,73.1,86,72z"
                />
              </g>
              <g>
                <path
                  d="M99.5,62.1c0.8-1.3,0.8-2.3,0.3-3.6c-0.5-1.2-1.3-2.8-2.7-3.8c-0.7-0.5-1.6-0.7-2.5-0.6c-0.9,0.1-1.8,0.5-2.6,1.3
				c-0.8,0.8-1.4,1.9-1.7,3.2c-0.3,1.3-0.2,2.8,0.4,4.1c1.2,2.8,2.7,4.1,4.1,5.3c1.4,1.1,2.9,2,4.9,2.9c-1.3-1.8-1.6-3.3-1.5-4.7
				C98.2,64.8,98.7,63.5,99.5,62.1z"
                />
              </g>
              <g>
                <path
                  d="M152.6,18.3c3.1-2.4,6-4.9,8.2-6.9c2.2-2,3.6-3.6,3.1-4.4c-0.4-0.8-2.6-0.5-5.5,1c-2.8,1.4-6.3,4-8.9,7.3
				c-2.7,3.4-4.2,5.9-5.6,8.5c-1.4,2.6-2.7,5.2-4.4,9.2c2.2-3.7,4-6,6-8.1C147.4,22.8,149.4,20.8,152.6,18.3z"
                />
              </g>
              <g>
                <path
                  d="M161.5,23.3c3.2-1.8,6.3-3.8,8.5-5.5c2.2-1.7,3.6-3.2,3.2-4c-0.4-0.8-2.5-0.7-5.2,0.3c-2.8,1-6.2,3-9.1,5.6
				c-2.9,2.7-4.7,4.8-6.5,6.9c-1.7,2.1-3.5,4.3-5.9,7.5c2.8-2.9,5-4.6,7.2-6.1C155.9,26.6,158.1,25.2,161.5,23.3z"
                />
              </g>
              <g>
                <path
                  d="M160,33.4c2.1-0.6,3.9-1.6,5.2-2.7c1.3-1,2-2.1,1.7-3c-0.3-0.8-1.6-1.2-3.4-0.9c-1.7,0.3-3.9,1.2-5.7,2.7
				c-1.8,1.6-2.9,2.8-4,4c-1.1,1.2-2.2,2.4-3.9,4.1c2-1.3,3.5-1.9,4.9-2.5C156.3,34.6,157.8,34.1,160,33.4z"
                />
              </g>
              <g>
                <path
                  d="M207.1,151.4c1.6-3.8,3.4-7.5,4.8-10.2c1.4-2.8,2.4-4.8,1.8-5.4c-0.6-0.6-2.8,0.4-5.2,3c-2.4,2.6-4.7,6.8-5.6,11.4
				c-0.8,4.7-0.7,8-0.3,11.2c0.4,3.2,1,6.4,2.6,10.8c-1.1-4.5-1.1-7.7-0.7-10.8C204.8,158.3,205.5,155.3,207.1,151.4z"
                />
              </g>
              <g>
                <path
                  d="M186.8,193.3c-0.7,3.1-1.2,5.4-1.8,8c-0.5,2.2-0.7,3.9,0,4.4c0.7,0.5,2.3-0.5,3.8-2.8c1.4-2.2,2.6-5.8,2.3-9.3
				c-0.2-3.6-1-6-1.8-8.2c-0.9-2.2-1.9-4.4-3.8-7.3c1.5,3.1,1.8,5.5,2,7.7C187.6,188,187.5,190.3,186.8,193.3z"
                />
              </g>
              <g>
                <path
                  d="M166,263.2c-0.7,3.5-2,6.9-3.1,9.4c-1.1,2.5-2,4.4-1.3,5c0.6,0.6,2.7-0.2,4.9-2.8c1.1-1.3,2.1-2.9,2.8-4.9
				c0.7-1.9,1.2-4.2,1.1-6.4c0-2.3-0.4-4.1-0.9-5.8c-0.5-1.6-1-3-1.7-4.3c-1.4-2.6-3-5.2-6.2-8.1c2.8,3.3,3.8,6,4.4,8.7
				c0.3,1.3,0.4,2.7,0.5,4.2C166.5,259.8,166.4,261.4,166,263.2z"
                />
              </g>
              <g>
                <path
                  d="M249.5,317.1c1.2-0.7,2.4-1.2,3.5-1.4c0.6-0.1,1.1-0.1,1.6-0.1c0.5,0,1,0.2,1.5,0.3c2,0.7,4,2.6,5.2,4.8
				c0.3,0.5,0.5,1.1,0.8,1.7c0.2,0.6,0.4,1.2,0.5,1.8c0.2,1.2,0.3,2.3,0.2,3.3c-0.2,1.9-1.3,3.4-2.5,4.1c-0.1,0.1-0.3,0.2-0.4,0.2
				c-0.1,0.1-0.3,0.1-0.4,0.2c-0.3,0.1-0.6,0.1-0.9,0.2c-0.7,0-1.3-0.1-1.8-0.4c-0.5-0.2-0.9-0.6-1.2-0.9c-0.3-0.4-0.5-0.9-0.5-1.4
				c-0.1-1.1,0.1-2,0.4-2.8c0.3-0.7,0.6-1.3,0.4-1.7c-0.1-0.4-0.8-0.7-2-0.3c-1.1,0.4-2.7,1.6-3.5,3.8c-0.4,1.1-0.6,2.5-0.3,4.1
				c0.3,1.6,1.1,3.2,2.4,4.5c1.3,1.3,3,2.2,4.9,2.7c1,0.2,2,0.3,3.1,0.3c0.5,0,1.1-0.1,1.7-0.2c0.5-0.1,1.1-0.3,1.6-0.4
				c4.2-1.5,7.4-5.3,8.5-10c0.5-2.3,0.6-4.6,0.2-6.8c-0.2-1.1-0.4-2.2-0.8-3.3c-0.4-1.1-0.8-2.2-1.4-3.2c-1.1-2.1-2.5-4-4.4-5.6
				c-1.8-1.6-4.1-3-6.7-3.8c-2.6-0.8-5.5-0.8-8.1-0.1c-2.6,0.7-4.8,2-6.7,3.5c-3.8,3.3-5.9,7.3-7.1,11c-1.1,3.8-1.4,7.4-1.1,10.7
				c0.2,3.3,1,6.4,1.9,9.1c1,2.8,2.1,5.4,3.5,7.8c1.3,2.5,2.7,4.9,4.3,7.3c1.5,2.4,3.1,4.8,4.9,7.4c3.6,5,7.8,10.5,13.5,16.3
				c-5.3-6.2-9-11.9-12.1-17.2c-1.5-2.7-2.8-5.2-4-7.8c-1.2-2.5-2.3-5-3.2-7.5c-0.9-2.5-1.7-5-2.2-7.6c-0.5-2.6-0.7-5.2-0.6-7.9
				c0.2-2.7,0.8-5.5,2-8.1c0.6-1.3,1.3-2.5,2.2-3.7C247.3,318.8,248.3,317.8,249.5,317.1z"
                />
              </g>
              <g>
                <path
                  d="M264.6,361c0.7-2.6,1-4.8,0.9-6.9c-0.1-2.1-0.6-4.1-2-5.2c-0.7-0.5-1.7-0.6-2.7-0.3c-1,0.3-2.1,1.1-3.1,2.3
				c-1,1.2-1.7,2.8-2.1,4.7c-0.4,1.9-0.3,3.9,0.3,5.9c0.6,2,1.4,3.6,2.2,4.9c0.8,1.3,1.7,2.3,2.6,3.3c1.8,1.9,3.7,3.6,6.7,5.5
				c-2.3-2.7-3-5-3.3-7.2C263.8,365.9,263.9,363.7,264.6,361z"
                />
              </g>
              <g>
                <path
                  d="M284.7,370c-3.2,6-5.2,12.1-7.6,17c-1.2,2.5-2.4,4.7-3.7,6.6c-0.7,0.9-1.4,1.8-2.1,2.6c-0.7,0.8-1.4,1.4-2.1,2
				c-1.4,1.1-2.5,1.5-3.5,1.7c-0.3,0.1-0.8,0-1.2-0.1c-0.5-0.2-0.9-0.4-1.2-0.7c-0.7-0.5-1.1-1.1-1.5-1.7c-0.3-0.6-0.5-1.1-0.9-1.3
				c-0.2-0.1-0.4-0.1-0.7,0c-0.3,0.1-0.6,0.4-0.9,0.8c-0.6,0.9-1,2.7-0.3,4.6c0.3,1,0.9,1.9,1.8,2.8c0.9,0.9,2.1,1.8,3.7,2.2
				c1.4,0.4,3,0.5,4.6,0.3c1.6-0.2,3.1-0.7,4.5-1.4c1.4-0.7,2.7-1.6,3.9-2.6c1.2-1,2.3-2.1,3.4-3.3c2.1-2.4,3.9-5.2,5.3-8.2
				c2.9-5.9,4.6-12.1,6.6-17.7c1.1-2.9,2.2-5.3,3.6-7.5c1.4-2.2,2.8-4.2,4.4-5.9c0.7-0.9,1.6-1.7,2.4-2.5c0.9-0.7,1.7-1.5,2.5-2.2
				c0.4-0.3,0.9-0.7,1.3-1c0.4-0.3,0.9-0.6,1.3-0.9c0.9-0.6,1.8-1.2,2.8-1.7c3.8-2.2,8-3.8,13-4.7c1.2-0.2,2.5-0.4,3.9-0.4
				c1.3-0.1,2.7-0.1,4.1,0.1c2.8,0.3,5.9,1.2,8.4,3.6c-2.3-2.6-5.4-3.8-8.3-4.4c-1.5-0.3-2.9-0.5-4.3-0.6c-1.4-0.1-2.7,0-4.1,0
				c-5.2,0.3-9.9,1.3-14.4,3c-1.1,0.4-2.2,0.9-3.3,1.4c-0.5,0.3-1.1,0.5-1.6,0.8c-0.5,0.3-1.1,0.6-1.6,0.9c-1.1,0.6-2.1,1.3-3.2,2
				c-1,0.8-2.1,1.5-3.2,2.3c-2.1,1.7-4.2,3.7-6.2,6C288.2,364.1,286.3,366.9,284.7,370z"
                />
              </g>
              <g>
                <path
                  d="M311.3,353.5c-2.3,1-4.3,2.4-5.9,4.2c-1.6,1.7-2.9,3.8-3.4,5.9c-0.6,2.1-0.3,4.2,0.4,5.5c0.7,1.3,1.7,1.7,2.5,1.5
				c0.9-0.2,1.6-0.8,2.3-1.4c0.7-0.6,1.5-1.2,2.3-1.9c0.9-0.7,1.8-1.6,3-2.7c1.2-1.1,2.4-2.4,3.6-3.7c2.6-2.8,4.9-4.5,7.5-5.8
				c1.3-0.6,2.7-1.3,4.3-1.7c1.6-0.4,3.5-0.7,5.7-0.7c-2.2-0.6-4.1-0.9-5.9-1.1c-1.8-0.2-3.4-0.3-5-0.3c-1.6,0-3.3,0.1-5.1,0.4
				C315.7,352,313.7,352.5,311.3,353.5z"
                />
              </g>
              <g>
                <path
                  d="M333.5,332.9c0.1-2-0.4-3.5-1.3-5c-0.9-1.4-2.2-2.8-3.9-3.1c-0.9-0.2-1.7,0-2.5,0.5c-0.8,0.5-1.5,1.4-1.9,2.6
				c-0.4,1.2-0.6,2.6-0.4,4.2c0.3,1.5,0.9,3.1,2,4.3c2.2,2.6,4.3,3.7,6.2,4.6c1.9,0.9,3.9,1.5,6.5,2.1c-2.2-1.6-3.2-3.2-3.8-4.8
				C333.7,336.7,333.4,335,333.5,332.9z"
                />
              </g>
              <g>
                <path
                  d="M523.9,354.8c0.8-4,0.7-8.3-0.4-12.2c-1-3.9-2.9-7.6-5.4-10.8c-2.5-3.2-5.7-5.8-9.2-7.6c-3.5-1.8-7.3-3-11-3.4
				c-3.8-0.4-7.6-0.2-11.2,0.8c-3.6,0.9-7.2,2.6-10.1,5.5c-1.4,1.4-2.6,3.2-3.4,5c-0.8,1.8-1.3,3.7-1.4,5.5
				c-0.3,3.7,0.5,7.3,2.3,10.3c1.8,3,4.4,5.2,7.1,6.5c2.7,1.3,5.5,1.8,8.1,1.8c2.6-0.1,5-0.8,7-1.8c2-1,3.5-2.4,4.6-3.7
				c2.3-2.7,3.1-5.3,3.4-7.1c0.2-1.8-0.1-2.8-0.5-2.8c-0.5-0.1-1,0.7-1.9,1.9c-0.9,1.2-2.2,3-4.3,4.5c-1,0.7-2.3,1.4-3.7,1.8
				c-1.4,0.4-2.9,0.6-4.5,0.4c-1.6-0.2-3.2-0.7-4.7-1.7c-1.5-0.9-2.7-2.2-3.4-3.7c-0.7-1.5-1-3.5-0.7-5.3c0.1-0.9,0.4-1.8,0.8-2.6
				c0.4-0.8,0.9-1.4,1.6-2.1c2.8-2.6,8.4-3.9,13.5-3.2c5.3,0.6,10.4,3.1,13.9,7.3c3.5,4.2,5.4,9.9,4.7,15.4
				c-0.8,5.7-4.1,10.7-8.1,14.1c-4.1,3.4-9,5.5-13.8,6.4c-4.9,1-9.8,1-14.5,0.4c-4.7-0.6-9.3-1.9-13.8-3.5
				c-4.5-1.6-8.9-3.6-13.5-5.8c-4.5-2.2-9.2-4.6-14.3-6.8c-5.1-2.2-10.8-4.1-17.1-4.8c-3.1-0.3-6.4-0.3-9.8,0.1
				c-1.7,0.3-3.4,0.7-5,1.2c-1.7,0.6-3.3,1.3-4.9,2.2c1.6-0.8,3.3-1.5,5-2c1.7-0.5,3.4-0.8,5-1c3.3-0.3,6.6-0.2,9.6,0.3
				c6.2,0.9,11.5,3.2,16.4,5.6c4.9,2.4,9.4,5.1,13.8,7.7c4.4,2.5,8.8,4.9,13.3,7c4.6,2,9.3,3.8,14.4,4.8c5.1,1.1,10.6,1.5,16.4,0.8
				c5.7-0.7,11.9-2.6,17.5-6.7c2.7-2,5.3-4.5,7.5-7.6C521.4,362.6,523.1,358.9,523.9,354.8z"
                />
              </g>
              <g>
                <path
                  d="M447.8,308.1c-3.2-3.2-7.6-5.5-12.3-6.1c-4.7-0.7-9.8,0.3-13.9,3.4c-2,1.5-3.5,3.4-4.6,5.6c-1.1,2.1-1.7,4.5-1.7,7
				c0,0.6,0.1,1.2,0.1,1.8c0.1,0.6,0.2,1.2,0.4,1.8c0.3,1.2,0.9,2.2,1.4,3.2c1.2,1.9,2.7,3.4,4.5,4.5c1.8,1.1,3.7,1.7,5.7,1.8
				c1.9,0.1,3.9-0.4,5.4-1.3c1.5-0.9,2.6-2,3.3-3.1c0.8-1.1,1.2-2.3,1.5-3.3c0.5-2.1,0-3.9-0.6-4.9c-0.3-0.5-0.6-0.8-0.9-1
				c-0.3-0.2-0.5-0.2-0.7-0.1c-0.2,0.1-0.4,0.3-0.5,0.6c-0.1,0.3-0.2,0.6-0.4,1c-0.3,0.8-0.6,1.7-1.4,2.6c-0.7,0.9-1.9,1.8-3.1,2
				c-1.1,0.2-2.3-0.1-3.5-1.2c-0.6-0.5-1.1-1.2-1.4-2c-0.1-0.4-0.3-0.7-0.3-1.1c0-0.2,0-0.3,0-0.5c0-0.2,0-0.4,0-0.6
				c0.1-1.6,1.2-3.6,2.9-4.8c1.6-1.2,4-1.9,6.7-1.6c2.7,0.2,5.3,1.3,7.7,3.1c2.4,1.8,4.2,4.3,5.3,6.8c1.1,2.5,1.5,5.1,1.3,7.6
				c-0.3,5-2.7,9.6-6.2,12.9c-3.5,3.3-8.6,5.3-14.4,5.7c-5.9,0.5-12.6-0.9-20.1-3.5c7.3,3.1,13.9,5,20.1,5.2
				c6.2,0.2,12-1.1,16.8-4.5c2.4-1.7,4.5-3.8,6.2-6.3c1.7-2.5,3.1-5.4,3.7-8.8C456.1,323.3,454.5,314.8,447.8,308.1z"
                />
              </g>
              <g>
                <path
                  d="M458.9,328.3c-2.4,2.5-3.5,5.9-3.3,8.5c0.2,2.7,1.6,4.5,3.3,4.7c1.8,0.2,3.4-0.9,4.8-2.5c1.3-1.6,2.3-3.7,2.8-6.3
				c0.5-2.7,1-4.7,1.8-6.7c0.8-1.9,1.8-3.8,4.1-6.1c-2.9,1.3-4.9,2.4-6.9,3.5C463.4,324.6,461.4,325.8,458.9,328.3z"
                />
              </g>
              <g>
                <path
                  d="M463.1,362.7c0.5,3.2,2.7,5.9,5,6.9c2.3,1.1,4.4,0.7,5.5-0.7c1-1.4,1-3.4,0.6-5.1c-0.5-1.8-1.4-3.3-3.1-4.8
				c-1.7-1.6-3-3-3.9-4.5c-1-1.6-1.8-3.4-1.9-6.2c-0.9,2.7-1.4,4.7-1.9,6.8C462.9,357.2,462.6,359.4,463.1,362.7z"
                />
              </g>
              <g>
                <path
                  d="M490.6,369c1.7,0.6,3.5,0.7,5.2,0.5c1.7-0.3,3.1-0.9,4.2-1.7c2.3-1.7,2.9-3.9,2-5.4c-0.9-1.5-2.7-2.2-4.6-2.6
				c-1.9-0.3-3.8-0.2-6.1,0.5c-2.3,0.7-4.3,0.9-6.3,0.8c-2-0.2-4-0.7-6.5-2.5c1.7,2.6,3.2,4.3,4.9,5.9
				C485.2,366,487.1,367.7,490.6,369z"
                />
              </g>
              <g>
                <path
                  d="M448.5,349.2c-1.6-0.6-2.7-0.5-4,0.1c-1.3,0.6-2.7,1.6-3.5,3.2c-0.4,0.8-0.5,1.7-0.2,2.5c0.2,0.9,0.8,1.7,1.7,2.4
				c0.9,0.7,2.1,1.2,3.5,1.3c1.3,0.1,2.8-0.1,4.1-0.8c2.7-1.5,3.9-3.1,5-4.5c1.1-1.5,1.9-3.1,2.9-5.2c-1.8,1.5-3.4,1.9-4.9,1.9
				C451.6,350.1,450.2,349.9,448.5,349.2z"
                />
              </g>
              <g>
                <path
                  d="M441,389.9c-1.2,1.3-3,2.2-4.8,2.4c-1.7,0.3-3.6,0-5.4-0.8c-1.8-0.7-3.5-2-4.7-3.3c-1.2-1.3-1.9-2.6-2-3.5
				c-0.2-1,0.1-2.1,0.4-2.7c0.3-0.6,0.9-1,1.7-1.1c0.8-0.1,1.5,0,2.1,0.2c0.6,0.2,1.1,0.4,1.5,0.3c0.4-0.1,0.8-0.6,0.6-1.7
				c-0.2-1-1-2.4-2.7-3.4c-0.8-0.5-1.9-0.8-3.2-0.9c-1.3-0.1-2.7,0.3-4,1.1c-2.7,1.6-4.5,4.5-4.8,8c-0.3,3.6,1.1,7,3.1,9.7
				c2,2.7,4.7,4.9,8,6.4c3.3,1.5,7.3,2.2,11.4,1.2c3.9-0.9,7.6-3.3,9.9-6.8c0.6-0.9,1.1-1.9,1.4-2.9c0.4-1,0.6-2,0.7-2.9
				c0.3-1.9,0.2-3.7-0.1-5.3c-0.6-3.2-1.9-5.9-3.3-8.1c-1.5-2.2-3.2-4.1-5-5.6c-1.8-1.5-3.7-2.8-5.7-3.8c-4-2.1-8.4-3.3-13.2-3.8
				c-4.9-0.5-10.3-0.3-16.5,0.5c12.6-0.7,21.4,1.7,27.8,6.5c3.2,2.4,5.8,5.4,7.4,9.2c0.8,1.9,1.3,3.9,1.3,5.9
				C442.8,386.8,442.3,388.7,441,389.9z"
                />
              </g>
              <g>
                <path
                  d="M457.3,391.4c0,2.2,1.1,3.9,2.5,4.7c1.5,0.9,3.3,0.9,4.8,0c1.5-0.9,2.4-2.4,2.6-4c0.2-1.5-0.4-3.1-1.9-4.3
				c-1.5-1.2-2.7-2.1-3.9-3c-1.1-0.9-2.1-2-3.1-3.7c0,2-0.2,3.5-0.4,5C457.7,387.6,457.4,389.2,457.3,391.4z"
                />
              </g>
              <g>
                <path
                  d="M431.7,410.6c-2.8,1.3-4.6,3.9-5.2,6.2c-0.6,2.3,0.1,4.3,1.6,5.2c1.5,0.9,3.5,0.6,5.2-0.3c1.7-0.9,3.3-2.4,4.4-4.7
				c1.1-2.3,2-4,3.1-5.6c1.1-1.6,2.4-3.1,4.8-4.7c-2.7,0.6-4.7,1-6.8,1.5C436.6,408.7,434.5,409.2,431.7,410.6z"
                />
              </g>
              <g>
                <path
                  d="M389.6,226.7c1.7,0.3,3.4,0.1,5-0.1c1.5-0.3,2.9-0.8,4-1.3c2.2-1.1,3.4-2.3,3.2-3.1c-0.3-0.9-2-1.1-4.3-0.9
				c-2.3,0.2-5.1,0.7-7.8,1.1c-2.8,0.4-4.9,0.2-6.8-0.5c-1-0.3-1.9-0.8-2.9-1.6c-0.9-0.7-1.9-1.8-2.6-3.3c0.4,1.6,1.1,2.9,1.9,3.9
				c0.8,1,1.7,1.9,2.6,2.6c0.9,0.7,2,1.4,3.2,1.9C386.4,226,387.9,226.5,389.6,226.7z"
                />
              </g>
              <g>
                <path
                  d="M392.5,238.2c1.9,2.5,3.7,4.8,5.3,6.6c1.6,1.7,2.9,2.9,3.7,2.5c0.8-0.3,0.8-2.2-0.1-4.7c-1-2.4-3-5.4-5.8-7.5
				c-2.9-2.2-5-3.2-7.2-4.2c-2.2-1-4.4-1.9-7.8-2.8c3.1,1.5,5,2.9,6.7,4.4C389.1,234,390.6,235.6,392.5,238.2z"
                />
              </g>
              <g>
                <path
                  d="M351.7,217.7c-0.7,2.4-1.3,4.7-1.7,6.6c-0.4,1.9-0.5,3.4,0.2,3.9c0.7,0.5,2.2-0.2,3.6-2.1c1.4-1.8,2.4-4.8,2.3-7.9
				c-0.2-3.1-0.8-5.1-1.4-7.1c-0.7-1.9-1.5-3.8-3.1-6.3c1,2.7,1.2,4.7,1.2,6.6C352.7,213.3,352.5,215.2,351.7,217.7z"
                />
              </g>
              <g>
                <path
                  d="M446.3,284.3c3.7-0.6,7.2-2.4,9.4-4.3c2.2-1.9,3-3.7,2.5-4.4c-0.6-0.7-2.4-0.1-4.7,0.8c-2.3,1-5.3,2.3-8.3,3.6
				c-3.2,1.4-5.5,2-7.9,2.5c-2.4,0.4-5,0.7-8.7,0.1c3.6,1.1,6.2,1.5,8.8,1.8C439.8,284.8,442.4,284.9,446.3,284.3z"
                />
              </g>
              <g>
                <path
                  d="M455.1,296.1c2.5,0.2,5-0.7,6.5-1.9c1.5-1.2,2-2.6,1.4-3.3c-0.6-0.7-1.8-0.7-3.3-0.4c-1.5,0.2-3.2,0.7-5.1,1.3
				c-1.9,0.6-3.4,0.9-4.9,0.9c-1.5,0-3.1-0.1-5.3-1.1c1.9,1.4,3.3,2.3,4.9,3C450.8,295.2,452.5,295.8,455.1,296.1z"
                />
              </g>
              <g>
                <path
                  d="M512.7,278.9c-0.8,1.7-2.2,3.2-3.7,4.4c-1.5,1.2-3.3,2.3-4.8,3.4c-1.5,1.1-2.8,2.1-3.5,3c-0.8,0.9-1,1.6-0.8,2
				c0.3,0.4,1,0.3,2,0.1c1.1-0.2,2.5-0.7,4.2-1.4c1.7-0.7,3.8-1.6,5.9-3.2c2-1.6,4-3.9,4.8-6.9c0.4-1.5,0.4-3,0.2-4.4
				c-0.3-1.3-0.8-2.5-1.4-3.4c-1.2-1.9-2.8-3.1-4.4-4c-3.3-1.8-7-2.2-12.2-2.6c2.6,0.5,4.7,0.9,6.5,1.6c1.8,0.7,3.3,1.5,4.5,2.6
				c1.2,1.1,2.2,2.4,2.8,3.9C513.5,275.5,513.5,277.2,512.7,278.9z"
                />
              </g>
              <g>
                <path
                  d="M421,118.9c2.1-3.8,2.7-8.3,2.3-11.5c-0.4-3.3-1.6-5.2-2.4-5c-0.9,0.2-1.3,2.2-1.8,4.9c-0.5,2.8-1.2,6.3-2.2,9.9
				c-1.1,3.7-2.3,6.2-3.8,8.5c-1.5,2.3-3.4,4.6-6.9,6.9c3.8-1.9,6.2-3.7,8.4-5.7C416.7,125,418.8,122.8,421,118.9z"
                />
              </g>
              <g>
                <path
                  d="M423,162.8c5.4,0.3,10.9,0.4,15,0.3c4.1-0.1,6.9-0.6,7-1.5c0.1-0.9-2.6-2-6.7-2.7c-4.2-0.7-9.8-1.1-15.4-0.5
				c-5.7,0.6-9.6,1.3-13.5,2.1c-3.9,0.8-7.8,1.6-13.5,3c5.8-0.8,9.8-1,13.7-1.1C413.6,162.4,417.4,162.5,423,162.8z"
                />
              </g>
              <g>
                <path
                  d="M428.4,172.9c6.4,1.8,13.2,1.4,13.5-0.3c0.2-1.7-6.2-4-12.9-4c-6.9,0-9.5,0.3-16.5-0.1
				C419.4,169.9,421.8,171,428.4,172.9z"
                />
              </g>
              <g>
                <path
                  d="M465.2,185.4c3.3-1.4,6.4-3,8.7-4.4c2.3-1.4,3.8-2.7,3.5-3.5c-0.3-0.8-2.3-1-5.1-0.3c-2.8,0.7-6.3,2.2-9.3,4.5
				c-3.1,2.4-5,4.3-6.8,6.1c-1.8,1.9-3.7,3.9-6.2,6.8c2.9-2.6,5.1-4,7.4-5.3C459.5,188,461.8,186.9,465.2,185.4z"
                />
              </g>
              <g>
                <path
                  d="M479.4,189.5c3.5-0.2,7-0.5,9.6-1c2.6-0.4,4.5-1,4.6-1.9c0.1-0.9-1.7-1.8-4.6-2.3c-2.8-0.5-6.7-0.3-10.4,0.8
				c-3.7,1.2-6.2,2.3-8.5,3.5c-2.4,1.2-4.7,2.5-8,4.6c3.5-1.6,6.1-2.3,8.6-2.8C473.3,190.1,475.8,189.7,479.4,189.5z"
                />
              </g>
              <g>
                <path
                  d="M496,177.4c5.3-1.4,9.4-5.2,8.5-6.8c-0.9-1.5-6-0.2-10.3,2.8c-2.2,1.5-3.8,2.6-5.3,3.7c-1.6,1-3.2,2-5.8,3.2
				c2.7-0.7,4.6-1.1,6.5-1.5C491.4,178.5,493.3,178.1,496,177.4z"
                />
              </g>
              <g>
                <path
                  d="M508.6,181.3c2.3,0.5,4.6,0.6,6.3,0.4c1.8-0.2,3.1-0.7,3.3-1.5c0.2-0.9-0.9-1.9-2.7-2.6c-1.8-0.7-4.4-1.1-6.9-0.6
				c-2.6,0.4-4.3,1-6,1.5c-1.8,0.6-3.6,1.2-5.8,2c2.6-0.3,4.3-0.3,6-0.1C504.5,180.5,506.2,180.8,508.6,181.3z"
                />
              </g>
              <g>
                <path
                  d="M515.5,191.4c5.5,1.8,11.5,1.5,11.8-0.3c0.3-1.7-5.2-4-11-4.1c-6-0.1-8.3,0.3-14.4-0.2
				C507.8,188.4,509.8,189.5,515.5,191.4z"
                />
              </g>
              <g>
                <path
                  d="M537.8,184.7c2.8,0.1,5.6-0.6,7.4-1.6c1.9-1,2.9-2.2,2.6-3c-0.3-0.8-1.8-1.1-3.7-1c-2,0.1-4.4,0.5-6.9,1.3
				c-2.5,0.8-4.3,1.3-6.2,1.7c-1.9,0.4-3.8,0.6-6.6,0.6c2.8,0.6,4.7,1,6.6,1.3C533,184.3,535,184.6,537.8,184.7z"
                />
              </g>
              <g>
                <path
                  d="M537.5,176.3c2.8-0.1,5.5-1.1,7.4-2.2c1.8-1.1,2.8-2.4,2.4-3.2c-0.4-0.8-1.9-1-3.8-0.7c-2,0.2-4.4,0.9-6.9,1.9
				c-2.5,1-4.3,1.6-6.1,2.2c-1.8,0.5-3.7,1-6.6,1.2c2.9,0.3,4.8,0.5,6.8,0.7C532.6,176.2,534.6,176.4,537.5,176.3z"
                />
              </g>
              <g>
                <path
                  d="M535.8,166.5c2.7-0.6,5.2-1.8,6.8-3c1.7-1.3,2.5-2.6,2.1-3.4c-0.8-1.6-6.1-0.4-10.6,2.3c-2.3,1.4-4,2.4-5.6,3.4
				c-1.7,0.9-3.4,1.8-6.1,2.9c2.8-0.5,4.7-0.8,6.7-1C531.1,167.3,533,167.1,535.8,166.5z"
                />
              </g>
              <g>
                <path
                  d="M557.3,160.8c1.9-0.2,3.7-0.8,5-1.5c1.3-0.7,2.2-1.5,2.2-2.4c-0.1-0.9-1.2-1.6-2.9-1.7c-1.7-0.2-3.9,0.3-5.8,1.5
				c-1.9,1.2-3.1,2.3-4.2,3.4c-1.1,1.1-2.2,2.1-3.7,3.7c1.8-1.2,3.2-1.7,4.6-2.1C553.9,161.3,555.3,161,557.3,160.8z"
                />
              </g>
              <g>
                <path
                  d="M366.2,478.6c3.4-1,6.8-2.1,9.3-3.2c2.5-1.1,4.2-2.1,4-3c-0.1-0.9-2.1-1.3-5-1.1c-2.9,0.3-6.7,1.3-10,3.2
				c-3.4,2-5.6,3.6-7.7,5.3c-2.1,1.7-4.2,3.4-7,6.1c3.2-2.3,5.6-3.5,8-4.5C360.2,480.5,362.6,479.6,366.2,478.6z"
                />
              </g>
              <g>
                <path
                  d="M377.5,483.6c2.3,0.1,4.5-0.2,6.2-0.7c1.7-0.5,2.9-1.2,2.9-2.1c0-0.9-1.2-1.7-3.1-2.1c-1.9-0.4-4.4-0.3-6.8,0.6
				c-2.4,0.9-4,1.7-5.6,2.5c-1.7,0.9-3.3,1.8-5.3,2.9c2.4-0.7,4.2-1,5.9-1.1C373.5,483.5,375.1,483.5,377.5,483.6z"
                />
              </g>
              <g>
                <path
                  d="M400.9,489.4c2.5,0.8,5,1.3,7,1.3c2,0.1,3.5-0.3,3.7-1.1c0.3-0.8-0.8-2-2.7-2.9c-1.9-0.9-4.6-1.6-7.4-1.6
				c-2.9,0-4.8,0.3-6.8,0.5c-2.1,0.2-4.1,0.5-6.7,0.8c2.8,0.2,4.7,0.6,6.6,1.1C396.6,487.9,398.4,488.5,400.9,489.4z"
                />
              </g>
              <g>
                <path
                  d="M404,480.2c5.8,1.9,12.1,1.7,12.4,0c0.3-1.7-5.5-4.1-11.6-4.3c-6.3-0.2-8.7,0.1-15.2-0.5
				C395.8,477.1,398,478.2,404,480.2z"
                />
              </g>
              <g>
                <path
                  d="M405.2,471.3c1.9,0,3.8-0.8,4.9-1.8c1.1-1,1.6-2.1,1.2-2.9c-0.4-0.8-1.5-1.1-2.8-1c-1.3,0.1-2.9,0.5-4.3,1.4
				c-1.5,0.9-2.6,1.5-3.8,2c-1.2,0.5-2.4,0.9-4.3,1.2c1.7,0.3,3,0.5,4.5,0.7C401.8,471.2,403.2,471.4,405.2,471.3z"
                />
              </g>
              <g>
                <path
                  d="M326.4,598.7c3.6-0.2,7.2-0.8,10.9-1.8c3.6-1,7.3-2.7,10.5-5.2c1.6-1.3,3.1-2.8,4.3-4.4c1.2-1.7,2.2-3.5,3-5.4
				c1.5-3.8,2.1-7.8,1.7-11.8c-0.2-2-0.7-3.9-1.4-5.8c-0.7-1.9-1.7-3.6-2.8-5.1c-2.2-3.1-5.1-5.5-8.2-7.3c-1.6-0.8-3.2-1.5-4.9-2
				c-1.7-0.5-3.4-0.7-5.1-0.8c-3.4-0.1-6.7,0.5-9.7,1.8c-2.9,1.4-5.5,3.4-7.2,6.1c-1.7,2.6-2.4,5.5-2.4,8.1c0,2.7,0.8,5.3,2.2,7.2
				c0.7,1,1.5,1.8,2.4,2.5c0.9,0.6,1.8,1.1,2.7,1.4c3.6,1.3,6.6,0.5,8.1-0.6c1.6-1.1,1.9-2.1,1.6-2.4c-0.3-0.4-1.2-0.1-2.5,0
				c-0.7,0-1.5,0-2.4-0.2c-0.9-0.2-1.9-0.6-2.8-1.2c-0.9-0.6-1.7-1.5-2.1-2.5c-0.5-1.1-0.6-2.4-0.4-3.8c0.2-1.4,0.8-2.9,1.8-4.1
				c0.9-1.1,2.4-2.1,4.1-2.7c1.7-0.6,3.8-0.8,5.8-0.6c1,0.1,2,0.3,3,0.7c1,0.4,2,0.8,2.9,1.4c1.9,1.2,3.6,2.7,4.9,4.6
				c1.3,1.9,2,4,2.3,6.3c0.2,2.3-0.1,4.8-1,7.1c-0.4,1.1-1,2.2-1.7,3.2c-0.7,1-1.5,1.8-2.5,2.7c-3.9,3.3-9.9,5-16.1,5.8
				c-6.3,0.8-12.3,0.4-17.8-0.8c-5.5-1.2-10.3-3.2-14.8-5.6c-9-4.9-16.6-10.8-25.2-15.5c-8.6-4.7-18.1-7.9-29-9.2
				c-5.4-0.6-11.2-0.8-17.4-0.2c-6.1,0.6-12.7,2-19.4,4.5c6.8-2.2,13.3-3.3,19.4-3.6c6.1-0.3,11.8,0.2,17.1,1.1
				c10.5,1.9,19.4,5.7,27.3,10.8c3.9,2.6,7.7,5.5,11.5,8.5c3.9,3,7.9,6.1,12.5,9.1c4.6,3,9.9,5.7,16,7.5
				C312.1,598.2,319,599.2,326.4,598.7z"
                />
              </g>
              <g>
                <path
                  d="M279.9,561.6c4.2-1.3,8.3-3.6,11.6-6.8c1.7-1.6,3.1-3.5,4.3-5.6c1.2-2.1,2-4.4,2.3-7c0.7-5.2-1.5-10.8-6.2-13.8
				c-2.4-1.4-4.9-1.9-7.1-1.8c-2.2,0.1-4.1,0.5-6,1.3c-1.8,0.8-3.5,2-4.9,3.6c-1.3,1.6-2.1,3.7-2.1,5.7c0,3.9,2.4,6.6,4.7,7.6
				c2.3,1,4.2,0.5,5.3-0.1c1.1-0.6,1.3-1.3,1.2-1.6c-0.2-0.4-0.8-0.6-1.5-0.9c-0.7-0.3-1.7-0.8-2.4-1.6c-0.3-0.4-0.5-0.8-0.6-1.4
				c-0.1-0.5-0.1-1.1,0.1-1.5c0.2-0.4,0.5-0.7,1-1.1c0.5-0.3,1.2-0.7,2.1-0.9c0.9-0.2,2-0.3,2.9-0.2c1,0.1,1.7,0.4,2.2,0.8
				c0.5,0.4,0.9,1,1.2,1.8c0.2,0.4,0.3,0.9,0.3,1.3c0,0.4,0,0.9,0,1.5c-0.3,2.1-1.7,4.8-3.7,7c-2,2.2-4.7,4.1-7.8,5.4
				c-3.1,1.4-6.4,2.2-9.5,2.6c-3.2,0.4-6.2,0.5-9.2,0.4c-5.9-0.1-11.4-0.8-17-1.1c-5.6-0.3-11.4-0.3-17.6,0.8
				c-6.2,1.1-13,3.1-20.3,7c7.5-3.4,14.4-4.8,20.5-5.3c6.2-0.4,11.6,0.2,17,1.3c5.4,1,10.7,2.4,16.9,3.4c3.1,0.5,6.5,0.9,10.2,0.9
				C271.4,563.3,275.5,563,279.9,561.6z"
                />
              </g>
              <g>
                <path
                  d="M303.9,501.7c1.1-5.3,2.3-10.4,3.1-14.4c0.8-4.1,1.2-7.3-0.3-8.3c-1.4-1-4.6,0.8-7.4,4.9c-1.4,2.1-2.6,4.7-3.4,7.7
				c-0.8,3-1.1,6.3-0.8,9.6c0.3,3.4,0.9,6.2,1.7,8.6c0.7,2.4,1.6,4.5,2.6,6.5c1,2,2,4,3.3,6.1c1.3,2.1,2.8,4.3,4.9,6.8
				c-1.6-2.8-2.6-5.2-3.3-7.5c-0.7-2.3-1.1-4.3-1.3-6.4c-0.2-2.1-0.2-4.1-0.1-6.3C303,506.9,303.3,504.5,303.9,501.7z"
                />
              </g>
              <g>
                <path
                  d="M320.9,499.7c0.3-3.4-0.1-6.9-1.1-10c-0.9-3.1-2.4-5.8-4.1-7.9c-1.6-2.1-3.4-3.4-4.9-4.1c-1.5-0.7-2.6-0.7-3.3-0.1
				c-1.3,1.3-0.3,4.3,0.8,8.2c0.6,2,1.2,4.2,1.9,6.6c0.6,2.4,1.3,5,1.8,7.7c0.6,2.8,0.8,5.2,0.9,7.5c0.1,2.3,0,4.4-0.3,6.5
				c-0.5,4.2-1.6,8.5-4.5,14.4c3.8-5.3,6.1-9.2,8.1-13.3c1-2.1,1.9-4.2,2.7-6.7C319.9,506,320.6,503.2,320.9,499.7z"
                />
              </g>
              <g>
                <path
                  d="M312.1,497.4c0.7-3.6,0.6-7.3,0-10.1c-0.6-2.7-1.6-4.4-2.5-4.3c-0.9,0.1-1.4,1.9-1.7,4.6c-0.3,2.6-0.4,6-0.2,9.5
				c0.2,3.6,0.2,6.1,0.2,8.6c-0.1,2.5-0.3,5.1-1.2,8.8c1.3-3.6,2.2-6,3-8.5C310.5,503.6,311.3,501.1,312.1,497.4z"
                />
              </g>
              <g>
                <path
                  d="M310,493.7c0.9-3.1,1-6.3,0.6-8.7c-0.4-2.4-1.2-4-2.1-3.9c-0.9,0-1.6,1.6-2.1,3.8c-0.5,2.3-0.8,5.2-0.8,8.3
				c0,3.1,0,5.3-0.2,7.5c-0.2,2.2-0.4,4.4-1.2,7.6c1.2-2.9,2.1-5,3.1-7.2C308.2,499,309,496.9,310,493.7z"
                />
              </g>
              <g>
                <path
                  d="M302.6,488.7c1.5-2.6,2.9-5.1,3.9-7.2c1-2,1.6-3.6,1-4.3c-0.6-0.6-2.4,0-4.3,1.7c-1.9,1.8-3.9,4.8-4.7,8.1
				c-0.8,3.5-0.9,5.9-0.8,8.3c0.1,2.4,0.3,4.7,1,8.1c-0.2-3.4,0.2-5.7,0.8-7.9C300.3,493.4,301.1,491.4,302.6,488.7z"
                />
              </g>
              <g>
                <path
                  d="M305.8,559.5c1.5-1.7,2.7-3.7,3.5-5.7c0.8-2,1.3-4,1.4-5.7c0.3-3.4-0.5-5.7-1.4-5.7c-0.9,0-1.6,2-2.7,4.8
				c-1.1,2.8-2.5,6.3-4.4,9.7c-2,3.5-3.9,5.6-6.1,7.6c-2.2,1.9-4.8,3.6-9.1,4.9c4.4-0.8,7.4-1.9,10.1-3.3c1.4-0.7,2.7-1.5,4.2-2.5
				C302.7,562.6,304.2,561.3,305.8,559.5z"
                />
              </g>
              <g>
                <path
                  d="M341,541.1c4.9-0.6,9.7-1.1,13.4-1.5c3.7-0.4,6.3-0.9,6.4-1.8c0.1-0.8-2.4-2-6.4-2.4c-3.9-0.5-9.3-0.1-14.4,1.4
				c-5.2,1.6-8.5,3.3-11.7,5c-3.2,1.8-6.4,3.7-10.8,6.9c4.6-2.8,8.1-4.1,11.5-5.2C332.6,542.5,336,541.7,341,541.1z"
                />
              </g>
              <g>
                <path
                  d="M361.1,546c3.3,0.2,6.6,0,9.1-0.3c2.5-0.4,4.3-1,4.3-1.9c0.1-0.9-1.6-1.8-4.2-2.3c-2.6-0.5-6.1-0.6-9.6,0.1
				c-3.5,0.7-5.9,1.5-8.2,2.3c-2.5,0.8-4.8,1.6-8.1,2.8c3.6-0.7,6-0.8,8.5-0.9C355.2,545.7,357.6,545.8,361.1,546z"
                />
              </g>
              <g>
                <path
                  d="M368.5,552.6c2.6,0.8,5.2,1.2,7.2,1.2c2,0,3.6-0.3,3.8-1.2c0.2-0.8-0.9-2-2.8-2.9c-2-0.9-4.8-1.6-7.6-1.5
				c-2.9,0.1-4.9,0.4-6.9,0.7c-2.1,0.3-4.2,0.7-6.9,1.1c2.9,0.1,4.9,0.4,6.8,0.8C364,551.3,365.9,551.8,368.5,552.6z"
                />
              </g>
              <g>
                <path
                  d="M371.3,563.5c1.5,1.5,2.9,2.8,4,3.8c1.2,1,2.2,1.7,3.1,1.5c0.8-0.3,1.2-1.8,0.4-3.8c-0.7-2-2.7-4.1-5.1-5.1
				c-2.5-1.1-4.2-1.3-6-1.6c-1.7-0.3-3.4-0.5-5.9-1c2.3,1,3.8,1.8,5.2,2.7C368.4,561,369.8,562,371.3,563.5z"
                />
              </g>
              <g>
                <path
                  d="M370.1,580.9c0.2,2.4,0.6,4.7,1.1,6.5c0.5,1.8,1.1,3.1,2,3.2c0.9,0.1,1.8-1.2,2.2-3.3c0.4-2.1,0.2-5-1-7.6
				c-1.2-2.6-2.4-4.2-3.6-5.8c-1.2-1.5-2.5-3-4.4-5c1.5,2.3,2.2,4.1,2.7,5.8C369.5,576.6,369.8,578.4,370.1,580.9z"
                />
              </g>
              <g>
                <path
                  d="M364.6,598.8c-0.5,1.7-0.6,3.5-0.3,4.8c0.2,1.4,0.8,2.4,1.7,2.6c0.9,0.2,1.8-0.6,2.5-2c0.6-1.4,1-3.4,0.6-5.3
				c-0.4-2-0.9-3.3-1.3-4.7c-0.5-1.3-0.9-2.6-1.5-4.5c0.1,2-0.1,3.3-0.4,4.7C365.5,595.7,365.1,597,364.6,598.8z"
                />
              </g>
              <g>
                <path
                  d="M353.3,601c-3.3,3.3-5.1,7.7-3.7,8.8c1.4,1.1,5.4-1.9,7.3-6.4c1.9-4.6,2.2-6.5,4.2-11.1
				C358.2,596.4,356.7,597.5,353.3,601z"
                />
              </g>
              <g>
                <path
                  d="M340.2,602.7c-2.6,1.1-5,2.5-6.7,3.8c-1.7,1.3-2.7,2.6-2.3,3.4c0.4,0.8,2,0.9,4.2,0.3c2.2-0.6,4.8-1.9,7.1-3.8
				c2.3-2,3.8-3.5,5.2-5c1.5-1.5,2.9-3,5.1-5.1c-2.5,1.7-4.4,2.7-6.2,3.6C344.8,600.8,342.9,601.6,340.2,602.7z"
                />
              </g>
              <g>
                <path
                  d="M316.4,606.9c-2.8,0-5.6,0.4-7.6,1c-2.1,0.6-3.5,1.4-3.5,2.2c0,0.9,1.5,1.6,3.7,1.9c2.2,0.3,5.2,0.1,8.1-0.9
				c2.9-1,4.8-1.9,6.7-2.8c2-1,4-1.9,6.6-3.1c-3,0.8-5,1.1-7.1,1.3C321.3,606.8,319.3,606.9,316.4,606.9z"
                />
              </g>
              <g>
                <path
                  d="M233,569.1c5.4,0.9,10.6,2.2,14.6,3.2c4,1,6.8,1.7,7.2,0.8c0.4-0.7-1.8-2.8-5.8-4.8c-4-1.9-9.8-3.5-15.7-3.6
				c-6,0-10.1,0.7-14.1,1.6c-4,0.9-8,2.1-13.5,4.5c5.7-1.9,9.8-2.4,13.7-2.6C223.5,568,227.4,568.2,233,569.1z"
                />
              </g>
              <g>
                <path
                  d="M251.9,584.4c1.8,1.9,3.6,3.7,5.1,5.1c1.5,1.3,2.8,2.2,3.6,1.8c0.8-0.4,0.8-2.1-0.2-4.2c-1-2.1-3.1-4.6-5.8-6.1
				c-2.8-1.5-4.8-2.2-6.9-2.7c-2.1-0.5-4.1-0.9-7.1-1.1c2.9,0.8,4.7,1.8,6.4,2.9C248.6,581.1,250.1,582.4,251.9,584.4z"
                />
              </g>
              <g>
                <path
                  d="M68,396c1.6-0.2,3.2-0.3,4.9-0.5c1.6-0.1,3.3-0.2,4.8-0.2c3.1,0,6.1,0.2,8.8,0.7c2.7,0.5,5.1,1.4,6.9,2.6
				c0.9,0.6,1.7,1.2,2.3,1.9c0.3,0.3,0.6,0.7,0.9,1.1c0.2,0.4,0.4,0.7,0.6,1.1c0.7,1.6,1.1,3.6,0.9,5.5c-0.1,1-0.3,1.9-0.6,2.8
				c-0.3,0.9-0.8,1.8-1.3,2.6c-1.1,1.7-2.5,3.2-4.2,4.4c-1.7,1.2-3.4,2.1-5.2,2.7c-1.8,0.6-3.7,0.8-5.3,0.7c-1.7-0.1-3.2-0.4-4.5-1
				c-2.5-1-4.3-3.3-5.3-5.4c-1-2.1-1.2-4.1-1.3-5.6c0-0.7-0.1-1.4-0.1-1.9c-0.1-0.5-0.2-0.9-0.5-1.1c-0.3-0.2-0.7-0.2-1.2,0
				c-0.5,0.2-1.1,0.7-1.8,1.5c-0.6,0.8-1.3,1.9-1.7,3.4c-0.4,1.4-0.7,3.2-0.5,5.1c0.1,1.9,0.7,4.1,1.7,6.3c0.3,0.5,0.6,1.1,0.9,1.6
				c0.4,0.5,0.7,1.1,1.2,1.6c0.5,0.5,0.9,1.1,1.4,1.5c0.3,0.2,0.5,0.5,0.8,0.7c0.3,0.2,0.6,0.4,0.8,0.7c2.3,1.8,5.2,3,8.2,3.7
				c3.1,0.7,6.4,0.7,9.7,0.2c3.3-0.6,6.8-1.8,9.9-3.6c3.1-1.9,6.1-4.4,8.5-7.6c1.2-1.6,2.3-3.4,3.1-5.4c0.8-2,1.4-4.1,1.7-6.3
				c0.6-4.4,0.1-9-1.8-13.5c-0.5-1.1-1.1-2.2-1.7-3.2c-0.7-1-1.4-2-2.2-2.9c-0.8-0.9-1.7-1.7-2.6-2.5c-0.5-0.4-0.9-0.7-1.4-1.1
				c-0.5-0.4-1-0.7-1.5-1c-4-2.5-8.2-3.7-12.3-4.3c-4.1-0.6-8.1-0.7-12-0.3c-1.9,0.2-3.8,0.4-5.6,0.7c-1.9,0.3-3.7,0.6-5.5,1
				c-7.6,1.7-14.2,4-20.2,6.7c-6,2.7-11.3,5.7-16.1,8.9c-4.8,3.2-9.1,6.5-13,10.1c-3.9,3.5-7.6,7.2-10.9,11
				c-6.7,7.7-12.6,16.1-18,26.2c-5.4,10-10.4,21.7-14.4,36.1c4.8-14.2,10.6-25.4,16.7-34.7c6.1-9.4,12.7-16.9,19.9-23.6
				c3.6-3.3,7.4-6.3,11.4-9.2c4-2.9,8.4-5.5,13.1-7.9c4.7-2.4,9.8-4.6,15.4-6.4C55.2,398.3,61.3,396.8,68,396z"
                />
              </g>
              <g>
                <path
                  d="M53,455.6c0.5,1.9,0.5,3.8,0.3,5.6c-0.3,1.9-0.8,3.7-1.7,5.4c-0.9,1.7-2,3.1-3.3,4.3c-0.6,0.6-1.3,1.1-2,1.6
				c-0.7,0.5-1.5,0.9-2.3,1.2c-3.2,1.4-6.9,1.6-10,0.9c-1.6-0.3-2.9-0.9-3.9-1.6c-1-0.7-1.6-1.3-2.1-2.1c-0.2-0.4-0.4-0.8-0.5-1.1
				c-0.1-0.3-0.2-0.6-0.1-0.9c0-0.6,0.3-1.5,1-2.3c0.7-0.9,1.6-1.7,2.6-2.4c0.5-0.3,1-0.7,1.5-1c0.5-0.3,0.9-0.5,1.3-0.8
				c0.5-0.3,1-0.6,1.4-0.9c0.2-0.2,0.7-0.2,1.1-0.2c0.4,0.1,0.7,0.2,1,0.4c0.3,0.2,0.5,0.5,0.8,0.6c0.3,0.1,0.7,0.1,1.1-0.4
				c0.4-0.4,0.7-1.4,0.4-2.5c-0.2-1.1-0.9-2.3-2.5-3.3c-1.5-0.9-3.3-1.1-5-1c-0.8,0.1-1.6,0.2-2.4,0.3c-0.8,0.1-1.6,0.3-2.5,0.6
				c-1.7,0.5-3.6,1.3-5.5,2.6c-1.9,1.3-4,3.4-5.2,6.4c-0.6,1.5-1,3.2-1.1,4.8c-0.1,1.7,0.1,3.3,0.6,5c0.5,1.6,1.2,3.2,2.1,4.6
				c0.9,1.5,2.1,2.7,3.3,3.8c2.5,2.2,5.4,3.8,8.4,4.8c6.1,2,13.1,1.9,19.6-0.7c3.2-1.3,6.4-3.2,9-5.8c2.6-2.5,4.7-5.6,6.2-8.9
				c1.5-3.3,2.4-6.9,2.5-10.6c0.2-3.7-0.5-7.5-1.8-10.8c-2.7-7-7.7-12-13.2-14.9c-5.6-2.9-11.3-3.4-16.2-3.1
				c-4.9,0.3-9.3,1.4-13.4,2.8c-4.1,1.3-7.9,3-11.5,4.9c-3.7,1.9-7.1,4.1-10.6,6.6c-3.4,2.5-6.7,5.3-9.9,8.6
				c-3.2,3.3-6.3,6.9-9.3,11.1c-2.9,4.2-5.7,9-8,14.5c2.6-5.3,5.7-9.8,9-13.7c3.2-3.9,6.6-7.1,10-9.9c3.4-2.8,6.9-5.1,10.4-7
				c3.5-1.9,7-3.5,10.6-4.8c3.6-1.2,7.3-2.3,11.1-2.9c3.8-0.7,7.7-1.1,11.5-0.8c3.8,0.3,7.6,1.4,10.5,3.5
				C49.6,448.2,52,451.7,53,455.6z"
                />
              </g>
              <g>
                <path
                  d="M121.8,361.1c10.9,1.6,21.5,3.9,29.6,5.9c0.5,0.1,1,0.2,1.5,0.3c0.5,0.1,1,0.2,1.4,0.4c0.9,0.2,1.8,0.4,2.6,0.6
				c0.8,0.2,1.6,0.3,2.4,0.5c0.7,0.2,1.4,0.3,2,0.4c2.5,0.4,4.2,0.3,4.6-0.5c0.4-0.8-0.5-2.1-2.5-3.9c-0.5-0.4-1.1-0.9-1.7-1.4
				c-0.6-0.5-1.4-0.9-2.1-1.4c-0.8-0.5-1.6-1-2.5-1.5c-0.5-0.2-0.9-0.5-1.4-0.8c-0.5-0.2-1-0.5-1.5-0.7c-8.1-3.8-19.9-6.9-31.8-6.7
				c-3.1,0.1-5.9,0.1-8.5,0.4c-1.3,0.1-2.6,0.2-3.8,0.4c-1.2,0.2-2.4,0.4-3.5,0.5c-1.1,0.2-2.3,0.3-3.3,0.5
				c-1.1,0.2-2.1,0.4-3.2,0.7c-1,0.2-2.1,0.4-3.1,0.7c-1,0.3-2,0.5-3,0.8c-1,0.3-2,0.5-3,0.8c-1,0.3-2,0.6-3,1c-1,0.3-2,0.6-3.1,1
				c-1,0.4-2.1,0.8-3.2,1.2c-1.1,0.4-2.2,0.8-3.3,1.3c-1.1,0.5-2.3,1-3.5,1.5c-0.6,0.3-1.2,0.5-1.8,0.8c-0.6,0.3-1.2,0.6-1.9,0.9
				c-1.3,0.6-2.6,1.2-4,1.9c1.4-0.5,2.8-1,4.1-1.5c0.7-0.2,1.3-0.5,1.9-0.7c0.6-0.2,1.3-0.4,1.9-0.6c1.2-0.4,2.4-0.7,3.6-1
				c1.2-0.3,2.3-0.5,3.4-0.8c1.1-0.2,2.2-0.5,3.3-0.7c1.1-0.2,2.1-0.3,3.2-0.5c1-0.1,2-0.3,3-0.4c1-0.1,2-0.2,3-0.3
				c1-0.1,2-0.2,3-0.2c1-0.1,2-0.1,3-0.1c1,0,2.1,0,3.1-0.1c1.1,0,2.1,0.1,3.2,0.1c1.1,0,2.2,0.1,3.4,0.1c1.2,0.1,2.3,0.2,3.6,0.3
				C116.4,360.4,119,360.8,121.8,361.1z"
                />
              </g>
              <g>
                <path
                  d="M120.9,378.3c10.8,0.9,21.8-0.5,29.7-2.7c7.9-2.2,12.8-5.1,12.3-6.8c-0.4-1.8-5.9-1.9-13.7-1.5
				c-7.8,0.4-18.1,1.3-28.4,2.2c-10.6,0.9-18,1.1-25.5,0.9c-7.5-0.3-15.1-1-26.1-3.4c10.7,3.5,18.2,5.4,25.6,7.2
				C102.3,375.9,109.8,377.4,120.9,378.3z"
                />
              </g>
              <g>
                <path
                  d="M132.4,371.1c6.3,1.3,12.7,1.4,17.5,0.7c4.8-0.7,8-2.3,8-4c0-1.8-3.1-3.3-7.8-4.4c-4.7-1-10.9-1.5-17.2-1
				c-6.5,0.5-10.9,0.9-15.5,1.2c-4.5,0.3-9.1,0.4-15.8,0c6.6,1.4,11,2.6,15.4,3.8C121.5,368.5,125.9,369.7,132.4,371.1z"
                />
              </g>
              <g>
                <path
                  d="M166.3,420.2c1,0,2.1,0,3.1-0.2c1-0.2,2.1-0.4,3.1-0.7c2-0.7,4-1.7,5.8-3.3c1.8-1.5,3.3-3.7,4-6.1
				c0.7-2.4,0.7-4.8,0.3-6.9c-0.4-2.1-1.2-4-2.1-5.7c-0.9-1.7-2-3.1-3.3-4.5c-1.3-1.3-2.8-2.4-4.5-3.3c-1.6-0.8-3.6-1.3-5.3-1.4
				c-3.5-0.2-6.9,1.1-9,3.3c-1.1,1.1-1.8,2.4-2.2,3.6c-0.4,1.2-0.5,2.3-0.4,3.3c0.2,2,1.1,3.4,1.9,4c0.8,0.7,1.5,0.7,1.8,0.4
				c0.3-0.3,0.4-0.9,0.5-1.6c0.1-0.7,0.2-1.6,0.7-2.4c0.4-0.9,1.2-1.8,2.1-2.1c0.9-0.4,2.3-0.6,3.6-0.2c1.3,0.4,2.6,1.3,3.9,2.8
				c1.2,1.5,2.3,3.6,2.6,5.5c0.2,0.9,0.1,1.7-0.1,2.3c-0.2,0.6-0.5,1.2-1.1,1.8c-0.6,0.6-1.5,1.2-2.5,1.7c-0.5,0.2-1.1,0.4-1.6,0.6
				c-0.6,0.2-1.1,0.3-1.8,0.4c-2.6,0.3-5.2,0-7.6-0.7c-2.4-0.7-4.7-1.7-6.8-2.9c-4.1-2.4-7.6-5.4-11-8.5c-3.5-3.1-6.9-6.5-11.2-9.7
				c-2.2-1.6-4.5-3.1-7.3-4.5c-2.7-1.3-5.9-2.4-9.3-2.6c3.5,0.5,6.4,1.9,8.9,3.4c2.5,1.5,4.7,3.3,6.5,5.1c3.8,3.6,6.6,7.3,9.5,11
				c3,3.7,6,7.4,10.3,11c2.2,1.8,4.6,3.4,7.6,4.8C158.6,419,162.2,420.1,166.3,420.2z"
                />
              </g>
              <g>
                <path
                  d="M130.6,425.7c-0.7,1.3-1.4,2.2-2.3,3c-0.9,0.7-1.7,1.2-2.5,1.4c-0.6,0.2-1.4,0.2-2.1,0c-0.7-0.2-1.2-0.4-1.5-0.7
				c-0.3-0.1-0.6-0.7-0.7-1.3c-0.1-0.6-0.1-1.3,0-1.9c0.1-0.6,0.3-1.1,0.4-1.6c0.2-0.5,0.3-1,0.2-1.4c-0.1-0.4-0.4-0.8-1.1-1
				c-0.7-0.2-1.8-0.1-2.9,0.5c-1.2,0.6-2.4,1.7-3.2,3.4c-0.8,1.6-1.3,4-0.4,6.6c0.8,2.4,2.7,4.6,5.1,5.9c2.4,1.3,5.3,1.8,8.3,1.2
				c2.9-0.6,5.4-2.2,7.2-4.2c1.8-2,3.1-4.6,3.7-7c0.6-2.5,0.6-4.8,0.4-6.8c-0.2-2-0.6-3.8-1-5.4c-0.9-3.3-2.2-6.1-3.7-8.6
				c-1.5-2.6-3.3-4.9-5.6-7.3c-1.1-1.2-2.4-2.3-3.8-3.5c-1.4-1.1-3.1-2.2-4.9-3.2c1.7,1.2,3.1,2.6,4.3,3.8c1.2,1.3,2.2,2.6,3,3.9
				c1.7,2.6,2.7,5.1,3.5,7.7c0.7,2.6,1.1,5.2,1.1,8C132.2,419.9,131.9,423.1,130.6,425.7z"
                />
              </g>
              <g>
                <path
                  d="M612.6,399.5c-0.7-3.6-1.9-7.1-3.5-10.4c-1.6-3.3-3.6-6.4-5.8-9.1c-2.2-2.7-4.6-4.8-6.8-6.8c-2.2-1.9-4.2-3.6-5.9-5.2
				c-3.5-3.1-6-6-7.6-8.2c-1.7-2.2-2.6-3.7-3.5-3.5c-0.8,0.1-1.5,2-0.9,5.4c0.3,1.6,0.9,3.6,1.9,5.6c1,2,2.4,4.2,4,6.3
				c3.3,4.3,7.7,8.3,11.3,12.7c3.5,4.3,6.4,9.6,8.4,15.4c1,3,1.7,5.9,2.1,8.6c0.4,2.7,0.7,5.4,0.7,7.9c0,5-0.5,9.6-1.6,14.1
				c-1.1,4.5-2.6,9-4.9,13.8c-2.3,4.8-5.4,9.9-9.8,15.6c4.8-5.3,8.4-10.1,11.3-14.7c2.9-4.6,5.2-9,7-13.5c1.8-4.6,3.3-9.3,4.1-14.8
				c0.4-2.8,0.6-5.7,0.6-8.9C613.7,406.6,613.4,403.2,612.6,399.5z"
                />
              </g>
              <g>
                <path
                  d="M580.2,404.8c-0.8-5.7-1-11.4-0.9-16.8c0.1-5.4,0.4-10.6,0.8-15c0.7-8.9,0.9-15.2-0.8-15.7c-1.7-0.4-4.9,5.1-7.5,14.3
				c-1.2,4.6-2.2,10.1-2.4,16.2c-0.2,6,0.4,12.6,2.2,18.9c1.9,6.5,4.3,11.6,7.1,15.9c1.4,2.2,2.8,4.1,4.3,5.9
				c1.4,1.8,2.9,3.4,4.2,5.1c2.6,3.3,5,6.9,5.9,11.6c0.2,1.2,0.4,2.4,0.4,3.7c0,1.3,0,2.6-0.2,4c-0.4,2.7-1.3,5.6-3,8.4
				c1.9-2.7,3.1-5.5,3.8-8.2c0.4-1.4,0.6-2.7,0.7-4.1c0.1-1.3,0.1-2.6,0.1-3.9c-0.1-2.5-0.7-5-1.5-7.2c-0.8-2.2-1.8-4.3-2.9-6.2
				c-2.2-3.9-4.5-7.5-6.2-11.7C582.4,415.5,581,410.7,580.2,404.8z"
                />
              </g>
              <g>
                <path
                  d="M593.6,391.6c-1-7.1-3.4-13.9-5.9-18.7c-2.5-4.8-4.9-7.5-5.7-7.1c-0.8,0.4,0.2,3.8,1.8,8.7c1.6,4.9,3.7,11.4,5.5,17.9
				c1.8,6.8,2.7,11.6,3.3,16.5c0.5,4.9,0.8,9.9,0.1,17.3c1.2-7.3,1.6-12.4,1.8-17.4C594.6,403.9,594.6,398.9,593.6,391.6z"
                />
              </g>
              <g>
                <path
                  d="M588.3,385.7c-0.4-5.3-1.8-10.5-3.4-14.1c-1.6-3.7-3.4-5.8-4.2-5.5c-0.8,0.3-0.6,3,0.2,6.7c0.7,3.8,1.9,8.6,3.1,13.5
				c1.2,5.1,1.9,8.6,2.3,12.2c0.4,3.6,0.7,7.4,0.3,12.8c0.9-5.4,1.3-9.2,1.6-12.9C588.5,394.8,588.7,391.1,588.3,385.7z"
                />
              </g>
              <g>
                <path
                  d="M583.8,385.6c0.2-8.5-1.7-16.9-3.5-16.8c-1.8,0.1-2.4,8.7-0.9,17.1c1.6,8.6,2.6,11.8,3.8,20.8
				C583,397.7,583.5,394.4,583.8,385.6z"
                />
              </g>
              <g>
                <path
                  d="M38.3,418.2c3.6-0.5,7.1-1.2,9.7-2c2.6-0.8,4.4-1.7,4.3-2.5c-0.1-0.9-2-1.5-4.9-1.6c-2.9-0.1-6.7,0.5-10.3,1.9
				c-3.7,1.5-6,2.8-8.4,4.1c-2.3,1.3-4.7,2.7-8,4.8c3.6-1.6,6.2-2.4,8.7-3C32.1,419.3,34.6,418.8,38.3,418.2z"
                />
              </g>
              <g>
                <path
                  d="M37.7,425.6c3.2-0.1,6.2-0.5,8.6-1.1c2.3-0.5,4-1.2,4-2.1c0-0.9-1.6-1.7-4.2-2.1c-2.6-0.4-6-0.1-9.3,0.9
				c-3.3,1.1-5.5,2.1-7.6,3.2c-2.1,1.1-4.2,2.2-7.3,3.9c3.3-1.2,5.6-1.8,7.9-2.1C32.2,425.9,34.5,425.7,37.7,425.6z"
                />
              </g>
              <g>
                <path
                  d="M59.5,432.6c2.4,0.8,4.7,1.4,6.6,1.6c1.9,0.2,3.4,0.1,3.8-0.7c0.4-0.8-0.5-2.1-2.3-3.2c-1.8-1.1-4.5-2-7.3-2
				c-2.9,0-4.8,0.3-6.8,0.7c-1.9,0.4-3.8,0.8-6.5,1.5c2.8-0.2,4.7,0,6.5,0.4C55.3,431.3,57.1,431.8,59.5,432.6z"
                />
              </g>
              <g>
                <path
                  d="M80.6,446.8c4,0.5,8.1-0.5,10.7-1.9c2.7-1.4,4-3.1,3.6-3.8c-0.4-0.8-2.4-0.5-5-0.1c-2.6,0.4-5.9,1-9.4,1.5
				c-3.6,0.4-6.1,0.3-8.7,0c-2.5-0.4-5.2-1.1-8.6-3.1c3.1,2.4,5.6,3.7,8.1,4.9C73.9,445.3,76.5,446.2,80.6,446.8z"
                />
              </g>
              <g>
                <path
                  d="M84.1,455.5c2.2,0,4.3-0.8,5.6-1.8c1.3-1,1.9-2.2,1.5-3c-0.4-0.8-1.6-1-3.1-0.9c-1.5,0.1-3.2,0.6-4.9,1.4
				c-1.8,0.8-3.1,1.4-4.4,1.8c-1.4,0.4-2.8,0.8-4.9,0.8c2.1,0.5,3.5,0.8,5,1.1C80.4,455.3,81.9,455.5,84.1,455.5z"
                />
              </g>
              <g>
                <path
                  d="M-6.8,468.2c-3.8,3.9-6.3,9-7.3,13c-1,4-0.7,6.8,0.2,7c0.9,0.2,2.1-2.2,3.8-5.5c1.7-3.3,4-7.6,6.8-11.7
				c2.9-4.2,5.4-6.9,8.1-9.3c2.8-2.3,5.8-4.6,11-6.6c-5.3,1.5-8.8,3.2-12.1,5C0.3,462-2.9,464.1-6.8,468.2z"
                />
              </g>
              <g>
                <path
                  d="M-0.6,480.6c-1,1.9-1.8,3.9-2.4,5.8c-0.3,1-0.5,1.9-0.7,2.8c-0.2,0.9-0.3,1.9-0.3,2.8c0,0.9,0.1,1.8,0.4,2.6
				c0.3,0.8,0.7,1.5,1.2,1.9c0.5,0.4,1,0.6,1.4,0.6c0.4,0,0.6-0.1,0.7-0.3c0.2-0.4-0.1-1,0-1.6c0-0.6,0.1-1.5,0.5-2.7
				c0.2-0.6,0.4-1.2,0.7-2.1c0.3-0.8,0.6-1.7,0.8-2.5c0.6-1.8,1.1-3.7,1.7-5.6c1.1-4,2.1-6.7,3.2-9.3c1.2-2.7,2.5-5.3,5-8.9
				c-2.9,3.2-4.8,5.6-6.7,7.9C3.3,474.3,1.5,476.7-0.6,480.6z"
                />
              </g>
              <g>
                <path
                  d="M7.5,492.9c-0.1,2.4,0.6,4.8,1.6,6.4c1,1.6,2.2,2.3,3,2c0.8-0.4,1.1-1.7,1-3.3c-0.1-1.6-0.5-3.6-1.2-5.6
				c-0.7-2.1-1.2-3.6-1.5-5.1c-0.3-1.6-0.5-3.2-0.3-5.6c-0.7,2.3-1.2,3.9-1.6,5.5C8.1,488.7,7.7,490.4,7.5,492.9z"
                />
              </g>
              <g>
                <path
                  d="M19.5,498.4c0.8,2,2.3,3.6,3.7,4.4c1.4,0.8,2.7,0.9,3.3,0.2c0.6-0.7,0.3-1.9-0.3-3.1c-0.6-1.3-1.7-2.7-3-3.9
				c-1.4-1.3-2.4-2.3-3.2-3.3c-0.9-1.1-1.7-2.2-2.5-4.2c0.3,2.1,0.5,3.5,0.7,4.9C18.5,494.9,18.7,496.4,19.5,498.4z"
                />
              </g>
              <g>
                <path
                  d="M36.3,500.3c1.6,0.8,3.4,0.8,4.7,0.4c1.3-0.4,2-1.3,2-2.2c-0.1-0.9-0.9-1.6-1.9-2c-1.1-0.5-2.4-0.7-3.8-0.5
				c-1.5,0.2-2.6,0.2-3.7,0.2c-1.1,0-2.3-0.2-3.8-0.8c1.1,0.9,2.1,1.7,3.2,2.6C33.7,498.7,34.7,499.5,36.3,500.3z"
                />
              </g>
              <g>
                <path
                  d="M173.3,453.4c1.6-3.6,2.3-7.6,2.2-11.5c-0.1-3.9-1-7.8-2.9-11.2c-1-1.7-2.2-3.2-3.6-4.5c-1.5-1.3-3.2-2.3-5.1-2.8
				c-3.8-1.1-7.5-0.3-10.2,1.3c-2.8,1.6-4.7,4.3-5.4,6.7c-0.8,2.5-0.6,4.7-0.2,6.4c0.5,1.7,1.2,2.9,1.8,3.6c0.6,0.7,1.1,0.9,1.2,0.8
				c0.4-0.3-0.5-1.9-0.7-4.8c0-1.4,0.2-3.2,1-4.9c0.8-1.7,2.2-3.3,4.2-4.2c2-1,4.6-1.3,6.8-0.5c2.3,0.8,4.4,2.7,5.8,5.3
				c1.4,2.6,2.2,5.7,2.4,8.9c0.2,3.2-0.3,6.6-1.4,9.7c-1.2,3.2-3,6-4.8,8.5c-1.9,2.5-3.8,4.8-5.6,7.1c-0.9,1.2-1.7,2.3-2.5,3.5
				c-0.4,0.6-0.7,1.2-1.1,1.8l-0.5,0.9l-0.5,0.9c-0.3,0.6-0.6,1.2-0.9,1.8c-0.3,0.6-0.5,1.2-0.8,1.8c-0.6,1.2-0.9,2.5-1.4,3.7
				c-0.8,2.5-1.4,5.1-1.8,7.8c-0.2,1.3-0.3,2.7-0.4,4.1c0,0.7,0,1.4,0,2.1c0,0.7,0,1.4,0,2.2c0.1,2.9,0.5,6,1.3,9.3
				c0.8,3.2,2,6.6,3.8,10c-1.7-3.5-2.7-6.9-3.4-10.1c-0.6-3.2-0.9-6.3-0.8-9.2c0-0.7,0-1.4,0.1-2.1c0.1-0.7,0.1-1.4,0.2-2.1
				c0.2-1.4,0.3-2.7,0.6-4c0.5-2.6,1.3-5,2.2-7.4c1.9-4.7,4.5-9,8.3-13.1c1.9-2.1,4-4.2,6.1-6.7C169.4,460,171.7,457.1,173.3,453.4z
				"
                />
              </g>
              <g>
                <path
                  d="M154.7,445.2c-2.1,1.9-3.9,4-5.1,5.8c-1.2,1.8-1.8,3.3-1.2,4c0.6,0.7,2.2,0.3,4.2-0.9c1.9-1.2,4.1-3.4,5.6-6.1
				c0.8-1.4,1.3-2.5,1.8-3.6c0.5-1.1,0.9-2.1,1.2-3.2c0.3-1.1,0.5-2.2,0.4-3.5c-0.1-1.2-0.5-2.6-1.7-3.7c0.9,1.3,1,2.6,0.8,3.7
				c-0.2,1.1-0.7,2-1.2,2.7c-0.6,0.8-1.2,1.5-2,2.3C156.6,443.3,155.7,444.2,154.7,445.2z"
                />
              </g>
              <g>
                <path
                  d="M157.3,453.9c-1.7,1.7-3.1,3.6-4,5.2c-0.9,1.6-1.2,3-0.6,3.7c0.6,0.6,2.1,0.3,3.7-0.9c1.6-1.1,3.4-3.1,4.4-5.5
				c1.1-2.4,1.7-4.2,2.2-6c0.6-1.9,1.1-3.7,1.8-6c-1.2,2.3-2.3,3.8-3.5,5.1C160.3,450.9,159.1,452.2,157.3,453.9z"
                />
              </g>
              <g>
                <path
                  d="M187.3,434.6c0.8-2.6,0.7-5.4,0.1-7.4c-0.6-2-1.7-3.1-2.5-2.9c-0.9,0.2-1.4,1.5-1.6,3.3c-0.3,1.8-0.4,4-0.3,6.3
				c0.1,2.4,0,4.2-0.2,5.9c-0.3,1.7-0.7,3.5-1.9,5.9c1.6-2.1,2.7-3.7,3.6-5.2C185.5,438.9,186.5,437.3,187.3,434.6z"
                />
              </g>
              <g>
                <path
                  d="M196,440.1c1.4-0.9,2.5-2.1,3.2-3.2c0.7-1.1,1-2.2,0.6-3c-0.5-0.7-1.7-0.9-3.1-0.4c-1.4,0.5-3,1.8-4,3.6
				c-1,1.8-1.4,3.2-1.8,4.5c-0.4,1.3-0.7,2.6-1.2,4.5c1-1.7,1.9-2.6,2.8-3.5C193.5,441.8,194.5,441.1,196,440.1z"
                />
              </g>
              <g>
                <path
                  d="M204.1,446c1.7-0.2,3.1-0.8,4.2-1.5c1.1-0.7,1.8-1.6,1.7-2.5c-0.1-0.9-1.2-1.5-2.7-1.6c-1.5-0.1-3.4,0.4-5,1.6
				c-1.6,1.2-2.5,2.2-3.5,3.2c-1,1.1-1.9,2.2-3,3.4c1.6-1,2.8-1.5,4-1.8C201.1,446.4,202.3,446.2,204.1,446z"
                />
              </g>
              <g>
                <path
                  d="M255.9,442.9c-2.8,2-5.5,3.9-7.6,5.6c-2,1.6-3.4,3-3,3.8c0.3,0.8,2.4,0.8,5.1-0.2c2.7-1,6-3.1,8.4-6
				c2.5-3,3.9-5.3,5.2-7.6c1.2-2.3,2.4-4.7,3.8-8.3c-1.9,3.4-3.5,5.4-5.3,7.2C260.7,439.2,258.8,440.8,255.9,442.9z"
                />
              </g>
              <g>
                <path
                  d="M258,452.6c-2.4,3.6-3.9,7.7-4.5,10.9c-0.5,3.2-0.2,5.5,0.6,5.6c0.9,0.2,2-1.7,3.4-4.4c1.3-2.7,2.9-6.4,4.3-10.1
				c1.5-3.8,2.8-6.4,4.3-9c1.5-2.5,3.1-5,6.1-8.3c-3.4,2.9-5.5,5-7.6,7.1C262.6,446.7,260.5,448.9,258,452.6z"
                />
              </g>
              <g>
                <path
                  d="M264.1,459.8c-1.9,3.8-3,8-3.2,11.2c-0.3,3.2,0.2,5.4,1,5.5c0.9,0.1,1.9-1.8,3-4.6c1.1-2.9,2.3-6.7,3.3-10.6
				c1-4,1.9-6.8,2.9-9.6c1-2.8,2.2-5.5,4.4-9.3c-2.7,3.5-4.4,6-6.1,8.5C267.7,453.4,266.1,455.9,264.1,459.8z"
                />
              </g>
              <g>
                <path
                  d="M266.4,475.3c-1.1,3.9-1.3,8-0.8,11c0.4,3,1.4,5,2.2,4.9c0.9-0.1,1.5-2,2-4.9c0.5-2.8,0.8-6.6,1-10.3
				c0.2-3.9,0.5-6.6,0.9-9.3c0.5-2.7,1.1-5.5,2.6-9.4c-2,3.6-3.2,6.2-4.4,8.8C268.6,468.7,267.5,471.3,266.4,475.3z"
                />
              </g>
              <g>
                <path
                  d="M332.1,491.8c-2.3,0.4-4.4,1.4-5.7,2.6c-1.3,1.2-1.9,2.4-1.4,3.2c0.5,0.8,1.8,0.9,3.4,0.5c1.6-0.3,3.5-1.1,5.3-2.2
				c1.9-1.2,3.2-2,4.6-2.7c1.4-0.7,2.9-1.4,5.1-2c-2.1,0-3.8,0.1-5.6,0.1C336.1,491.3,334.5,491.4,332.1,491.8z"
                />
              </g>
              <g>
                <path
                  d="M338.2,497.8c-2,0.5-2.5,2.8-1.3,4.1c1.2,1.3,3.4,1.1,4.2-0.8c0.8-1.9,0.9-3,2-4.7C341.2,497.3,340.2,497.3,338.2,497.8z
				"
                />
              </g>
              <g>
                <path d="M432.9,506.6c-3.6,0.1-6.5,2.6-5.9,4.2c0.7,1.6,4.1,1.7,7,0c3-1.8,4.1-2.8,7.6-4C438.1,507,436.6,506.6,432.9,506.6z" />
              </g>
              <g>
                <path
                  d="M152,526.9c-2.4-3.5-5.6-6.8-9.9-9.1c-2.1-1.1-4.4-2-6.8-2.5c-2.4-0.5-5-0.6-7.6-0.1c-5.2,0.9-9.7,3.7-12.6,7.9
				c-0.7,1.1-1.3,2.2-1.8,3.4c-0.5,1.2-0.8,2.5-0.9,3.7c-0.3,2.4,0,4.7,0.7,6.8c0.7,2.1,1.8,4,3.3,5.5c1.5,1.5,3.3,2.6,5,3.2
				c3.6,1.1,6.6,0.4,8.6-0.7c2.1-1.1,3.2-2.7,3.5-3.9c0.2-0.6,0.2-1.1,0.1-1.5c-0.1-0.4-0.3-0.7-0.6-0.8c-0.3-0.2-0.6-0.3-1-0.3
				c-0.4,0-0.8,0-1.2-0.1c-0.8,0-1.8-0.1-2.7-0.4c-0.9-0.3-2-0.9-2.4-1.5c-0.2-0.3-0.3-0.7-0.4-1.1c-0.1-0.4-0.1-0.8-0.1-1.4
				c0-0.5,0.2-1,0.4-1.4c0.1-0.2,0.2-0.4,0.3-0.5c0.1-0.2,0.3-0.4,0.5-0.5c0.4-0.4,0.9-0.7,1.5-1c0.6-0.3,1.3-0.5,2-0.6
				c1.4-0.2,3.4,0.1,5.5,0.9c2,0.8,4.2,2.4,6.2,4.4c1.1,1,2.1,2.2,3,3.4c0.9,1.2,1.8,2.3,2.6,3.5c1.5,2.4,2.8,4.7,3.8,7.1
				c2,4.7,3.3,9.3,3.7,14.1c0.5,4.8,0.2,9.7-1.1,15.2c-1.3,5.4-3.8,11.3-8.4,17.4c5.2-5.5,8.6-11.1,10.9-16.5
				c2.3-5.4,3.7-10.6,4.3-15.9c0.6-5.3,0.5-10.8-0.6-16.8c-0.6-3-1.4-6.1-2.7-9.4c-0.7-1.6-1.4-3.3-2.3-5
				C154.2,530.4,153.2,528.7,152,526.9z"
                />
              </g>
              <g>
                <path
                  d="M180.5,567.9c3.9-1.9,7.3-4.1,9.8-6.3c2.5-2.1,4.1-4.2,3.6-5.9c-0.5-1.6-3.3-2.4-7.1-1.7c-3.7,0.7-8.4,3.2-11.7,7
				c-3.4,3.9-5.2,7-6.8,10c-1.6,3.1-3.1,6.2-4.9,10.7c2.8-4.1,5.3-6.3,7.8-8.3C173.8,571.6,176.5,569.9,180.5,567.9z"
                />
              </g>
              <g>
                <path
                  d="M174.5,542.4c0.4-3.8-1-7.4-2.8-9.6c-1.9-2.2-4.1-3-5.6-2.2c-1.6,0.9-2.2,2.9-2.2,5.3c0,2.4,0.6,5.1,1.9,7.8
				c1.3,2.9,2.2,5,2.8,7.3c0.6,2.3,1,4.7,0.6,8.2c1.4-3.3,2.3-5.6,3.2-8C173.2,548.8,174.1,546.3,174.5,542.4z"
                />
              </g>
              <g>
                <path
                  d="M59.8,573.1c-3.7,3.4-7.3,6.6-11.1,9.2c-3.7,2.5-7.5,4.5-10.6,4.9c-3.2,0.5-5.8-0.6-7.3-1.7c-1.6-1.1-2.1-2.3-2.6-2.2
				c-0.2,0.1-0.3,0.4-0.3,1.1c0.1,0.7,0.4,1.7,1.1,2.7c0.7,1.1,1.9,2.2,3.5,3c1.6,0.8,3.7,1.3,5.9,1.3c2.2-0.1,4.5-0.6,6.7-1.5
				c2.2-0.9,4.3-2.1,6.4-3.4c4.2-2.8,8-6.4,11.4-10.2c3.5-3.9,6.3-7.3,8.9-10.2c2.6-2.9,5.1-5.4,7.9-7.7c2.7-2.2,5.7-4.2,9.4-5.8
				c3.7-1.5,8.2-2.7,13.6-2c-5.3-1-10-0.1-13.9,1.2c-3.9,1.3-7.2,3.1-10.3,5.1c-3.1,2-5.9,4.3-8.9,7
				C66.7,566.4,63.5,569.6,59.8,573.1z"
                />
              </g>
              <g>
                <path
                  d="M73.8,568.7c-1.9,3-3.2,6.4-4,9.7c-0.8,3.3-0.9,6.4-0.8,9.1c0.4,5.4,2.1,8.6,2.9,8.4c0.9-0.2,0.8-3.6,1.3-8.4
				c0.3-2.4,0.7-5.1,1.4-8c0.7-2.9,1.7-5.9,3-8.8c1.4-3,2.8-5.4,4.4-7.4c1.6-2,3.2-3.7,4.9-5.3c1.8-1.5,3.7-3,6-4.3
				c2.3-1.3,5.1-2.5,8.6-3.4c-3.5,0.6-6.4,1.6-9,2.6c-2.5,1.1-4.7,2.2-6.8,3.5c-2.1,1.3-4,2.8-6.1,4.7
				C77.8,563.3,75.8,565.6,73.8,568.7z"
                />
              </g>
              <g>
                <path
                  d="M82.3,565.7c-1.2,2.3-2,4.8-2.4,7.2c-0.4,2.4-0.3,4.7,0,6.6c0.6,3.9,2.2,6.1,3,5.9c0.9-0.2,0.9-2.7,1.2-6
				c0.1-1.7,0.4-3.6,0.7-5.6c0.4-2,0.9-4.2,1.5-6.3c0.7-2.2,1.5-4,2.4-5.5c0.9-1.6,1.8-2.9,2.9-4.2c1.1-1.3,2.3-2.5,3.8-3.7
				c1.5-1.2,3.3-2.3,5.7-3.3c-2.4,0.8-4.4,1.7-6.1,2.6c-1.7,0.9-3.2,1.9-4.6,3c-1.4,1.1-2.7,2.3-4.1,3.8
				C84.9,561.6,83.5,563.4,82.3,565.7z"
                />
              </g>
              <g>
                <path
                  d="M126.8,555.4c3.6,0.7,7.5,0,9.9-1.3c2.5-1.3,3.7-2.8,3.3-3.6c-0.4-0.8-2.2-0.7-4.6-0.4c-2.4,0.3-5.3,0.6-8.5,0.9
				c-3.2,0.2-5.5,0-7.8-0.4c-2.3-0.5-4.6-1.3-7.6-3.4c2.6,2.5,4.7,3.9,6.9,5.1C120.8,553.5,123.1,554.6,126.8,555.4z"
                />
              </g>
              <g>
                <path
                  d="M138.6,563.1c1.7,1.4,3.4,2.4,4.9,3.1c1.5,0.7,2.8,0.9,3.4,0.3c0.6-0.6,0.4-2-0.8-3.5c-1.1-1.5-3.1-3.1-5.5-3.8
				c-2.4-0.7-4.2-0.9-5.9-1c-1.7-0.1-3.4-0.2-5.8-0.2c2.4,0.5,3.9,1.2,5.3,2C135.6,560.8,136.9,561.7,138.6,563.1z"
                />
              </g>
              <g>
                <path
                  d="M141,578.8c0.3,2.1,1,4.1,1.8,5.5c0.8,1.5,1.7,2.4,2.6,2.3c0.9-0.1,1.5-1.4,1.5-3.2c0.1-1.8-0.5-4.2-1.8-6.2
				c-1.3-2.1-2.4-3.4-3.5-4.6c-1.1-1.2-2.2-2.5-3.8-4.2c1.2,2.1,1.7,3.6,2.2,5.2C140.4,575.1,140.7,576.6,141,578.8z"
                />
              </g>
              <g>
                <path
                  d="M143.4,505.7c0.1-2,0.1-3.7-0.1-5.2c-0.2-1.5-0.5-2.7-1.3-3c-0.8-0.3-2,0.5-2.8,2.3c-0.8,1.7-1,4.4-0.2,6.7
				c0.9,2.4,1.9,3.8,3,5.1c1.1,1.3,2.2,2.5,4,4c-1.5-1.8-2-3.4-2.3-4.8C143.4,509.2,143.3,507.7,143.4,505.7z"
                />
              </g>
              <g>
                <path
                  d="M158.8,498.6c0.9-1.8,1.7-3.4,2.3-4.9c0.6-1.4,0.9-2.7,0.4-3.4c-0.5-0.7-2.1-0.5-3.6,0.8c-1.6,1.3-3.1,3.7-3.3,6.4
				c-0.2,2.8,0.3,4.6,0.9,6.3c0.6,1.7,1.4,3.3,2.8,5.3c-1-2.3-1.1-4-0.9-5.5C157.5,501.9,157.9,500.4,158.8,498.6z"
                />
              </g>
              <g>
                <path
                  d="M187.9,576c2.7-0.4,5.2-1.1,7.1-1.9c1.9-0.8,3.2-1.7,3.1-2.6c-0.1-0.9-1.6-1.5-3.8-1.5c-2.2,0-5.1,0.5-7.7,1.8
				c-2.7,1.3-4.4,2.5-6.1,3.6c-1.8,1.2-3.5,2.4-5.8,4c2.7-1.2,4.6-1.8,6.5-2.3C183.2,576.8,185.2,576.4,187.9,576z"
                />
              </g>
              <g>
                <path
                  d="M149,392.3c3,0.1,5.8-1,7.6-2.1c1.8-1.1,2.7-2.4,2.4-3.2c-0.3-0.8-1.9-1-3.9-0.7c-2,0.3-4.4,1-6.7,1.7
				c-2.4,0.8-4.2,1-6,0.9c-1.8-0.1-3.8-0.5-5.9-2.5c1.7,2.3,3.6,3.4,5.4,4.3C143.8,391.6,145.9,392.2,149,392.3z"
                />
              </g>
              <g>
                <path
                  d="M134,321c-1.8,2.8-3.2,6-3.8,9.2c-0.6,3.2-0.5,6.3,0.2,8.9c0.7,2.6,1.8,4.5,2.9,5.6c0.5,0.6,1,0.9,1.4,1.1
				c0.4,0.2,0.7,0.2,0.8,0.1c0.7-0.6-0.7-3.3-1-7.6c-0.1-2.1,0-4.6,0.5-7.2c0.5-2.6,1.5-5.3,2.8-8c1.3-2.8,2.7-5.1,4-7.1
				c1.3-2.1,2.6-3.9,3.9-5.7c1.3-1.9,2.6-3.7,4-5.8c1.4-2.1,2.9-4.5,4.3-7.6c-1.6,2.9-3.4,5.1-5,7.1c-1.6,1.9-3.2,3.5-4.8,5.2
				c-1.6,1.6-3.1,3.2-4.8,5.1C137.7,315.9,135.8,318.1,134,321z"
                />
              </g>
              <g>
                <path
                  d="M143.2,322.4c-0.7,5.5,0.4,11.2,2,15.1c1.6,3.9,3.6,6,4.4,5.6c0.8-0.4,0.3-3.1-0.4-6.8c-0.7-3.8-1.4-8.7-1.7-13.7
				c-0.2-5.2,0.2-8.9,1-12.5c0.8-3.6,2-7.3,5-12.1c-3.4,4.5-5.2,8-6.8,11.5C145.2,313,143.9,316.7,143.2,322.4z"
                />
              </g>
              <g>
                <path
                  d="M149.7,316.6c0,4,1.3,7.8,2.8,10.4c1.5,2.6,3.2,3.9,3.9,3.4c0.8-0.4,0.5-2.4-0.1-5c-0.6-2.6-1.5-6-2.3-9.4
				c-0.9-3.5-1.2-6.1-1.4-8.7c-0.1-2.6,0-5.3,1-9.2c-1.5,3.7-2.3,6.3-2.9,9C150.2,309.8,149.7,312.5,149.7,316.6z"
                />
              </g>
              <g>
                <path
                  d="M93.8,341.5c3.2-2.6,5.7-6,7.1-8.8c1.4-2.8,1.7-5,0.9-5.4c-0.8-0.4-2.4,0.9-4.4,3c-2,2.1-4.4,5-6.8,8.1
				c-2.4,3.1-4.3,5.2-6.3,7.2c-2,2-4.2,3.8-7.8,6.1c3.8-1.8,6.4-3.2,8.9-4.6C88,345.7,90.5,344.2,93.8,341.5z"
                />
              </g>
              <g>
                <path
                  d="M102.3,347.6c2.7-0.3,5.3-1,7.2-1.7c1.9-0.8,3.2-1.7,3.1-2.5c-0.1-0.9-1.6-1.5-3.8-1.6c-2.2-0.1-5.1,0.4-7.7,1.7
				c-2.7,1.3-4.4,2.4-6.2,3.5c-1.8,1.2-3.6,2.3-5.9,3.8c2.7-1.2,4.7-1.7,6.6-2.1C97.6,348.2,99.5,347.9,102.3,347.6z"
                />
              </g>
              <g>
                <path
                  d="M119.4,246.7c1.9-1.6,3.5-3.5,4.5-5.2c1-1.6,1.4-3,0.8-3.7c-0.6-0.6-2.1-0.3-3.8,0.8c-1.7,1.1-3.6,3-4.9,5.4
				c-1.3,2.4-2,4.2-2.8,5.9c-0.8,1.9-1.5,3.7-2.5,6c1.5-2.3,2.8-3.7,4.1-5C116.1,249.6,117.4,248.4,119.4,246.7z"
                />
              </g>
              <g>
                <path
                  d="M20.7,384.4c-7.8,3.2-14.9,7.9-19.7,12.1c-4.8,4.2-7.2,7.7-6.7,8.3c0.6,0.7,4.1-1.6,9.2-5c5.1-3.3,11.9-7.6,19-11.4
				c7.3-3.9,12.7-6.1,18.2-8c5.5-1.8,11.3-3.3,20-4.3c-8.8,0.4-14.7,1.3-20.5,2.4C34.5,379.7,28.7,381.1,20.7,384.4z"
                />
              </g>
              <g>
                <path
                  d="M27.1,386.9c-6.2,3.1-11.7,7.5-15.3,11.3c-3.6,3.8-5.4,6.9-4.8,7.4c0.7,0.6,3.5-1.3,7.5-4.3c4-3,9.3-6.9,14.8-10.6
				c5.7-3.8,9.8-6.1,14.1-8.2c4.3-2,8.9-3.9,15.8-5.7c-7.1,1.2-11.9,2.5-16.5,3.9C38.1,382.1,33.5,383.7,27.1,386.9z"
                />
              </g>
              <g>
                <path
                  d="M-19.8,372.5c-3.2-2.4-6.3-4.7-8.9-6.3c-2.5-1.6-4.5-2.5-5.1-1.9c-0.6,0.6,0.3,2.7,2.4,5.1c2.1,2.4,5.5,5.1,9.3,6.9
				c4,1.8,6.8,2.7,9.6,3.6c2.9,0.8,5.7,1.6,10,2.5c-4.1-1.4-6.8-2.8-9.3-4.2C-14.2,376.7-16.6,375-19.8,372.5z"
                />
              </g>
              <g>
                <path
                  d="M-14.5,384.4c-0.9,0-1.8,0.2-2.7,0.6c-0.9,0.3-1.9,1-2.5,2c-0.6,1-0.6,2.1-0.3,2.7c0.3,0.7,0.9,0.8,1.3,0.7
				c0.4-0.1,0.8-0.4,1.2-0.5c0.4-0.1,0.7-0.1,1-0.1c0.2,0.1,0.6,0,1.2-0.2c0.7-0.2,1.4-0.5,2.1-1c1.4-0.9,2.5-1.5,3.6-2
				c1.1-0.5,2.3-0.9,4.1-1.2c-1.6-0.2-2.9-0.5-4.4-0.7C-11.3,384.6-12.6,384.4-14.5,384.4z"
                />
              </g>
              <g>
                <path
                  d="M-13.6,395.2c-2.1,1.6-3.6,3.7-4.4,5.6c-0.7,1.8-0.7,3.3,0,3.8c0.8,0.5,2-0.2,3.4-1.4c1.4-1.2,2.9-3,4.1-5
				c1.3-2.1,2.3-3.5,3.4-4.9c1.1-1.4,2.3-2.7,4.3-4.4c-2.3,1.2-4,2-5.6,2.9C-9.8,392.7-11.4,393.6-13.6,395.2z"
                />
              </g>
              <g>
                <path d="M-9.5,421.5c-1.3,2.9-0.3,6,1.4,6.3c1.7,0.2,3.5-2.6,2.9-5.7c-0.5-3.2-1.1-4.4-1.2-7.7C-7.4,417.5-8.2,418.6-9.5,421.5z" />
              </g>
              <g>
                <path d="M-2.1,415.6c-1.2,2.5-0.2,5.3,1.5,5.5c1.7,0.2,3.4-2.2,2.8-4.9c-0.6-2.8-1.2-3.9-1.4-6.7C0,412.2-0.9,413.1-2.1,415.6z" />
              </g>
              <g>
                <path
                  d="M578.1,305.9c3.3-4.2,6.4-8.7,8.9-13.6c2.5-4.9,4.2-10.3,4.9-15.7c0.7-5.4,0.4-10.9-1.2-15.8c-0.4-1.2-0.8-2.4-1.3-3.6
				c-0.5-1.2-1.1-2.3-1.8-3.4c-1.4-2.2-3.1-4.2-5.3-5.7c-1.1-0.8-2.3-1.3-3.6-1.8c-1.3-0.4-2.7-0.6-4-0.5c-2.5,0.1-4.9,0.9-6.8,2.5
				c-0.9,0.8-1.7,1.7-2.3,2.7c-0.6,1-0.8,2.1-1,3.1c-0.2,2,0.2,3.7,0.7,5c1.1,2.5,2.5,3.9,3.5,4.8c1,0.9,1.7,1.1,2.1,0.9
				c0.4-0.2,0.3-1,0.1-2.2c-0.2-1.2-0.8-2.9-1-4.7c-0.1-0.9,0-1.8,0.3-2.5c0.2-0.3,0.4-0.7,0.6-0.9c0.2-0.2,0.5-0.4,0.9-0.6
				c0.7-0.3,1.7-0.5,2.7-0.3c0.5,0.1,1,0.2,1.4,0.4c0.4,0.2,0.9,0.6,1.4,1c0.9,0.8,1.8,2,2.5,3.4c0.7,1.4,1.3,3.2,1.8,5
				c0.9,3.6,1,7.7,0.4,12c-0.6,4.2-1.9,8.6-3.8,12.8c-1.9,4.3-4.4,8.5-7.2,12.6c-2.9,4.3-5.8,8.2-8.5,11.9
				c-2.7,3.7-5.2,7.3-7.5,10.8c-2.2,3.5-4.2,7-5.8,10.6c-1.6,3.6-2.8,7.2-3.7,10.9c-1.7,7.4-2.1,15.1-0.8,23.5
				c0.6,4.2,1.7,8.5,3.4,13c1.7,4.4,4,9,7.3,13.5c-3-4.6-5.1-9.3-6.5-13.7c-1.4-4.5-2.1-8.8-2.4-12.9c-0.6-8.2,0.5-15.5,2.8-22.3
				c1.2-3.4,2.6-6.6,4.4-9.7c1.8-3.1,4-6.2,6.4-9.3c2.4-3.1,5.2-6.3,8.2-9.7C571.4,313.8,574.7,310.2,578.1,305.9z"
                />
              </g>
              <g>
                <path
                  d="M561.9,353.7c1-1.7,2-3.3,3.1-4.8c1.1-1.5,2.2-2.8,3.4-4.1c1.1-1.3,2.4-2.4,3.5-3.4c0.3-0.2,0.6-0.5,0.9-0.7
				c0.3-0.2,0.5-0.4,0.8-0.6c0.5-0.4,1.1-0.7,1.6-1c2.2-1.2,4-1.8,5.4-2c1.5-0.3,2.7-0.1,3.3-0.7c0.3-0.3,0.4-0.8,0.2-1.5
				c-0.2-0.7-0.8-1.5-1.9-2.3c-1-0.8-2.5-1.4-4.3-1.6c-1.7-0.2-3.7-0.1-5.6,0.3c-1,0.2-2,0.6-3,1c-0.5,0.2-1,0.5-1.5,0.7
				c-0.5,0.3-0.9,0.5-1.4,0.8c-1.8,1.1-3.7,2.5-5.3,4.2c-1.6,1.7-3.1,3.6-4.4,5.6c-1.2,2.1-2.2,4.3-3,6.5c-1.6,4.7-2.1,8.7-2.3,12.3
				c-0.2,3.6,0,6.8,0.3,9.9c0.7,6.3,1.9,12.4,3.8,21.4c-0.9-9.1-0.8-15.4,0-21.4c0.4-3,1-5.9,1.9-9
				C558.6,360.4,559.9,357.1,561.9,353.7z"
                />
              </g>
              <g>
                <path
                  d="M574.5,279.9c-0.6-1.9-1.5-3.7-2.6-5.3c-0.6-0.8-1.2-1.6-1.9-2.3c-0.7-0.7-1.5-1.3-2.3-1.9c-1.7-1.1-3.9-1.8-6.2-1.5
				c-1.1,0.2-2.1,0.5-3,1.1c-0.9,0.6-1.6,1.4-2.1,2.4c-0.4,0.9-0.6,1.9-0.6,2.8c0,0.9,0.2,1.6,0.5,2.3c0.5,1.3,1.2,2.1,1.8,2.7
				c1.2,1.2,2.1,1.6,2.4,1.4c0.4-0.3,0.1-1.2-0.4-2.6c-0.2-0.7-0.5-1.5-0.6-2.4c-0.1-0.9,0.1-1.5,0.5-2c0.4-0.4,1.1-0.8,2-0.9
				c0.8-0.1,1.9,0.3,2.9,1c1.1,0.7,2.1,1.8,3.1,2.9c0.9,1.2,1.7,2.5,2.4,3.9c1.3,3,1.8,5.9,1.9,8.5c0.1,2.6-0.2,5.1-0.8,7.5
				c-1.1,4.8-3.2,9.6-6.8,16.2c4.1-6.4,6.7-10.8,8.6-15.6c0.9-2.4,1.7-4.9,2-7.9C575.6,287.2,575.6,283.8,574.5,279.9z"
                />
              </g>
              <g>
                <path
                  d="M568.9,290c-0.3-0.5-0.5-1.1-0.8-1.6c-0.3-0.5-0.7-1.1-1.2-1.5c-0.9-1-1.9-1.8-3.4-2.3c-0.7-0.3-1.5-0.4-2.3-0.4
				c-0.8,0-1.6,0.3-2.3,0.6c-1.4,0.7-2.2,1.8-2.6,2.8c-0.5,1-0.6,2-0.6,2.7c0,0.8,0.1,1.4,0.3,1.9c0.3,1,0.7,1.5,1.2,1.5
				c0.4,0,0.9-0.5,1.3-1.4c0.4-0.8,0.9-2,1.5-2.7c0.3-0.4,0.7-0.6,1-0.7c0.3,0,0.5,0,0.9,0.1c0.4,0.2,1,0.5,1.6,1.1
				c0.3,0.3,0.6,0.5,0.8,0.9c0.3,0.3,0.5,0.7,0.8,1.1c1.1,1.5,1.8,3,2.3,4.5c0.5,1.4,0.8,2.8,0.9,4.2c0.1,1.4,0.1,2.9-0.3,4.5
				c-0.3,1.6-0.9,3.4-2.1,5.3c1.4-1.7,2.3-3.4,3-5c0.7-1.6,1.1-3.1,1.3-4.7c0.2-1.6,0.4-3.1,0.2-4.9
				C570.3,294.2,569.9,292.2,568.9,290z"
                />
              </g>
              <g>
                <path
                  d="M548.8,301.3c-1.9-0.3-3.8-0.3-5.2,0c-1.5,0.3-2.6,0.9-2.7,1.8c-0.2,0.9,0.7,1.8,2.3,2.4c1.6,0.6,3.8,0.8,5.9,0.2
				c2.2-0.6,3.6-1.2,5-1.9c1.5-0.7,2.9-1.4,4.7-2.2c-2.1,0.4-3.6,0.5-5.1,0.4C552.1,301.9,550.7,301.7,548.8,301.3z"
                />
              </g>
              <g>
                <path
                  d="M536.3,308.9c-5.8,0.2-11.1,2.8-10.6,4.5c0.4,1.7,6.2,1.7,11.5-0.2c5.5-1.9,7.5-3,13.4-4.3
				C544.5,309.2,542.3,308.8,536.3,308.9z"
                />
              </g>
              <g>
                <path
                  d="M525.6,316.8c-3.9-0.7-7.6,0.8-7.6,2.5c0.1,1.8,4,2.9,7.8,1.8c3.9-1.1,5.3-1.9,9.3-2.6
				C531,318.2,529.6,317.5,525.6,316.8z"
                />
              </g>
              <g>
                <path
                  d="M537.6,319c-2.2,1.1-3.9,2.9-4.8,4.6c-0.9,1.6-1.1,3.1-0.4,3.6c0.7,0.6,2,0.2,3.4-0.7c1.5-0.9,3.1-2.3,4.5-4
				c1.5-1.7,2.6-2.9,3.8-4.1c1.2-1.1,2.5-2.2,4.6-3.4c-2.4,0.7-4,1.3-5.6,1.8C541.5,317.3,539.9,317.8,537.6,319z"
                />
              </g>
              <g>
                <path
                  d="M545.3,322.1c-1.5,1.4-2.5,3.1-2.9,4.6c-0.4,1.5-0.2,2.7,0.6,3.2c0.8,0.4,1.9,0,3-1c1.1-1,2.2-2.5,2.9-4.3
				c1.5-3.7,1.8-5.3,4.1-8.8C549.9,318.6,548.4,319.3,545.3,322.1z"
                />
              </g>
              <g>
                <path
                  d="M554.7,308c-1.3,0.9-2.1,2.2-2.4,3.4c-0.3,1.2,0,2.2,0.7,2.7c0.7,0.5,1.8,0.3,2.7-0.3c1-0.6,1.9-1.7,2.4-3
				c1.1-2.8,1.3-4.1,3.2-6.7C558.7,305.8,557.4,306.2,554.7,308z"
                />
              </g>
              <g>
                <path
                  d="M532.3,341c-1.2,1.5-1.8,3.3-1.8,4.8c0,1.5,0.5,2.5,1.4,2.7c0.9,0.2,1.8-0.4,2.6-1.5c0.8-1,1.5-2.5,1.8-4.2
				c0.3-1.7,0.5-3,0.9-4.2c0.3-1.2,0.8-2.4,1.7-4.1c-1.4,1.3-2.3,2.2-3.3,3.1C534.5,338.6,533.5,339.5,532.3,341z"
                />
              </g>
              <g>
                <path
                  d="M533.2,356.5c-2.1,3.9-1.8,8.5-0.1,8.9c1.7,0.4,3.9-3.4,4.3-7.6c0.4-4.4,0.2-6.1,1.4-10.5
				C536.7,351.3,535.4,352.5,533.2,356.5z"
                />
              </g>
              <g>
                <path
                  d="M533.9,379.3c-0.4,2.4,0.2,4.9,1,6.6c0.8,1.7,2,2.6,2.8,2.3c0.8-0.3,1.2-1.6,1.3-3.2c0.1-1.7-0.2-3.7-0.7-5.8
				c-0.5-2.2-0.8-3.7-1-5.4c-0.2-1.6-0.2-3.3,0.2-5.7c-0.9,2.3-1.5,3.8-2.1,5.5C534.8,375.2,534.2,376.8,533.9,379.3z"
                />
              </g>
              <g>
                <path
                  d="M570.2,359.3c1.9-2.2,3.7-4.2,5-6c1.3-1.7,2.2-3.2,1.7-3.9c-0.5-0.7-2.3-0.5-4.4,0.8c-2.2,1.3-4.6,3.7-6,6.8
				c-1.4,3.1-1.9,5.4-2.3,7.6c-0.4,2.2-0.6,4.5-0.6,7.8c0.5-3.2,1.4-5.3,2.4-7.2C567.1,363.2,568.3,361.5,570.2,359.3z"
                />
              </g>
              <g>
                <path
                  d="M580.7,319.9c1.7-0.7,3-1.7,4-2.7c0.9-1,1.4-2.1,1.1-2.9c-0.4-0.8-1.6-1.1-3.1-0.8c-1.5,0.3-3.3,1.3-4.6,2.8
				c-1.3,1.6-2.1,2.8-2.8,4c-0.8,1.3-1.5,2.6-2.4,4.1c1.4-1.4,2.6-2.1,3.8-2.7C577.8,321.1,579,320.6,580.7,319.9z"
                />
              </g>
              <g>
                <path
                  d="M-6.5,258.1c1.9-0.4,3.5-1.1,4.7-2c1.2-0.9,1.9-1.9,1.6-2.7c-0.2-0.8-1.4-1.3-3-1.2c-1.6,0.1-3.5,0.7-5.2,2
				c-1.7,1.3-2.7,2.3-3.8,3.3c-1,1-2.1,2-3.7,3.4c1.9-0.9,3.2-1.4,4.6-1.7C-9.8,258.7-8.5,258.5-6.5,258.1z"
                />
              </g>
              <g>
                <path
                  d="M8.4,246.2c-1.5,2.6-2.1,5.6-2,7.8c0.1,2.3,0.8,3.7,1.7,3.8c0.9,0.1,1.7-1.2,2.5-3.1c0.8-1.9,1.5-4.3,2-6.9
				c0.5-2.7,1.1-4.5,1.7-6.4c0.7-1.8,1.5-3.7,3.3-6.1c-2.1,2-3.5,3.5-4.9,5.1C11.2,242,9.9,243.6,8.4,246.2z"
                />
              </g>
              <g>
                <path
                  d="M544.8,223.7c-1.8-1.5-3.8-2.7-6-3.5c-2.1-0.9-4.5-1.4-6.9-1.4c-1.2,0-2.4,0.2-3.5,0.6c-1.2,0.4-2.3,0.9-3.2,1.6
				c-1.9,1.4-3.2,3.3-3.7,5.3c-0.3,1-0.3,2-0.2,3.1c0.2,1,0.5,1.8,0.9,2.5c0.9,1.4,2.1,2.3,3.1,2.7c1.1,0.4,2,0.4,2.6,0.2
				c0.6-0.2,0.8-0.4,0.8-0.6c-0.1-0.5-1.1-0.6-2.2-1.7c-1.1-1-2.1-3.1-1.2-5c0.4-1,1.3-2,2.4-2.7c0.6-0.4,1.2-0.6,1.9-0.8
				c0.7-0.2,1.5-0.3,2.2-0.3c3.2,0,7,1.4,10.2,3.5c3.3,2.2,5.7,4.8,7.4,7.5c0.9,1.3,1.6,2.7,2.2,4c0.6,1.4,1.1,2.7,1.5,4.1
				c0.8,2.8,1.2,5.8,1.2,9.2c0,3.4-0.4,7.1-1.6,11.5c1.4-4.3,2.2-8,2.5-11.5c0.3-3.4,0.2-6.5-0.3-9.6c-0.3-1.5-0.6-3.1-1-4.6
				c-0.5-1.5-1.1-3.1-1.9-4.7C550.7,230.1,548.4,226.7,544.8,223.7z"
                />
              </g>
              <g>
                <path
                  d="M547.9,236.8c-0.9-1.3-2.2-2.4-3.6-3.2c-1.5-0.8-3.3-1.2-5-1c-1.8,0.2-3.4,1.2-4.5,2.4c-1.1,1.2-1.7,2.9-1.6,4.4
				c0.1,1.7,1,2.9,1.8,3.6c0.9,0.7,1.9,1.1,2.7,1.1c0.8,0,1.4-0.2,1.8-0.5c0.3-0.3,0.4-0.6,0.3-0.8c-0.2-0.4-0.9-0.6-1.4-1.1
				c-0.3-0.3-0.6-0.6-0.8-1.1c-0.2-0.4-0.3-0.9-0.1-1.2c0.1-0.4,0.4-0.8,0.8-1.2c0.5-0.4,1.1-0.7,1.7-0.7c1.3-0.2,3.2,0.6,4.7,2
				c1.6,1.5,2.6,3.5,3.6,5.3c0.9,1.8,1.7,3.4,2.3,5.1c0.6,1.7,1.2,3.5,1.6,5.5c0.4,2,0.7,4.4,0.8,7.1c0.2-2.8,0.2-5.1,0.1-7.2
				c-0.1-2.1-0.3-4-0.6-5.9c-0.3-1.9-0.7-3.7-1.3-5.8c-0.3-1-0.6-2.1-1.1-3.3C549.5,239.4,548.9,238.1,547.9,236.8z"
                />
              </g>
              <g>
                <path
                  d="M613.1,514.1c0.5,1.2,0.7,2.4,0.8,3.5c0,1.1-0.1,2-0.4,2.7c-0.3,0.7-0.7,1.4-1.3,2.1c-0.6,0.6-1.4,1.3-2.4,1.7
				c-1.9,1-4,1.4-6.1,1.3c-2-0.1-3.6-0.8-4.7-1.5c-1.2-0.9-2-2.1-2.3-3.2c-0.4-1-0.3-2.3,0.1-3.4c0.4-1.1,0.9-2,1.4-2.8
				c0.5-0.8,0.9-1.4,0.9-2.1c0-0.6-0.6-1.3-1.9-1.6c-1.3-0.3-3.3,0-5.5,1.3c-1.1,0.7-2.1,1.6-3.1,2.9c-0.5,0.7-0.9,1.4-1.2,2.2
				c-0.4,0.8-0.6,1.8-0.8,2.7c-0.7,3.8,0.2,8.2,2.9,11.9c2.8,3.9,7.2,6.5,12,7.4c4.7,1,10.1,0.5,14.9-1.9c2.4-1.2,4.7-2.8,6.7-5
				c2-2.2,3.6-4.9,4.4-8c0.8-3,0.8-6.2,0.3-9c-0.6-2.9-1.7-5.4-3.1-7.7c-1.5-2.3-3.3-4.3-5.2-5.9c-1.9-1.6-4.1-2.9-6.1-3.7
				c-4.1-1.7-8-2.3-11.5-2.4c-7.1-0.1-12.9,1.4-18.6,3.1c-5.6,1.8-11.1,4.1-16.8,7.4c-5.7,3.2-11.6,7.4-17.6,13.4
				c6.6-5.4,12.9-8.7,18.8-11c5.9-2.3,11.4-3.5,16.8-4.2c5.4-0.6,11-0.7,16.3,0.5c2.7,0.6,5.3,1.6,7.5,3.1c1.2,0.8,2,1.6,2.8,2.6
				C611.9,511.7,612.6,512.9,613.1,514.1z"
                />
              </g>
              <g>
                <path
                  d="M586.9,487.9c2.8-1.8,5.2-4.2,7.1-6.8c1.9-2.6,3.2-5.3,3.9-7.8c0.7-2.5,0.8-4.7,0.6-6.3c-0.3-1.6-0.9-2.5-1.8-2.7
				c-1.8-0.3-3.7,2.1-6.3,5.3c-1.3,1.7-2.7,3.5-4.3,5.4c-1.6,2-3.3,4.1-5.1,6.3c-1.8,2.3-3.6,4.1-5.2,5.7c-1.7,1.6-3.2,3.1-4.9,4.4
				c-3.3,2.7-6.9,5.3-12.6,8.5c6.1-2.3,10.3-3.7,14.5-5.2c2.1-0.8,4.2-1.6,6.5-2.7C581.6,491.1,584.1,489.8,586.9,487.9z"
                />
              </g>
              <g>
                <path
                  d="M574,520.4c-1.2,2.3-1.3,5.1-0.8,7c0.5,1.9,1.5,3,2.4,2.8c0.9-0.2,1.4-1.4,1.8-3c0.4-1.6,0.7-3.5,0.8-5.6
				c0.2-2.2,0.5-3.7,1-5.3c0.5-1.5,1.3-3,2.9-4.9c-2,1.4-3.3,2.6-4.5,3.9C576.4,516.6,575.3,518,574,520.4z"
                />
              </g>
              <g>
                <path
                  d="M580.2,543.9c0.4,2.6,1.7,5,3.2,6.4c1.5,1.4,2.9,1.9,3.6,1.3c0.7-0.6,0.5-2,0-3.6c-0.5-1.6-1.4-3.6-2.5-5.5
				c-1.1-2-1.8-3.6-2.3-5.2c-0.5-1.6-0.9-3.3-0.8-5.9c-0.6,2.5-0.9,4.3-1.1,6.1C580,539.4,579.8,541.2,580.2,543.9z"
                />
              </g>
              <g>
                <path
                  d="M599.3,558c1.3,1.4,3,2.4,4.7,2.8c1.7,0.5,3.4,0.5,4.8,0.2c1.4-0.3,2.4-0.9,3-1.4c0.6-0.6,0.8-1.1,0.7-1.5
				c-0.4-0.8-2-0.9-3.9-1.2c-2-0.3-4.3-0.9-6.5-2.2c-1.2-0.7-2.1-1.5-2.9-2.3c-0.8-0.8-1.4-1.7-2-2.6c-1.2-1.8-2.1-3.9-3.1-7.1
				c0.5,3.3,0.8,5.6,1.4,7.8c0.3,1.1,0.6,2.3,1.2,3.5C597.2,555.2,598,556.6,599.3,558z"
                />
              </g>
              <g>
                <path
                  d="M613.7,553.2c2.2,1.1,4.7,1.3,6.5,1c1.8-0.3,3-1.2,2.9-2.1c-0.1-0.9-1.2-1.6-2.7-2.1c-1.5-0.5-3.5-0.9-5.5-1
				c-2.2-0.2-3.7-0.4-5.2-0.7c-1.5-0.4-3.1-0.9-5.1-2.2c1.7,1.7,2.9,2.8,4.2,3.9C610.1,551,611.5,552.1,613.7,553.2z"
                />
              </g>
              <g>
                <path
                  d="M623.6,545.9c1.5,0.1,2.9-0.6,3.8-1.4c0.8-0.8,1.1-1.9,0.7-2.6c-0.4-0.8-1.3-1.2-2.4-1.2c-1,0-2.2,0.3-3.3,1
				c-1.1,0.8-1.9,1.3-2.8,1.8c-0.9,0.5-1.8,0.9-3.2,1.2c1.4,0.3,2.5,0.5,3.5,0.7C620.9,545.6,622,545.8,623.6,545.9z"
                />
              </g>
              <g>
                <path
                  d="M584.7,454.4c0.8-4,0.4-8.1-0.6-11.1c-0.9-2.9-2.3-4.6-3.1-4.4c-0.9,0.3-1.1,2.3-1,5c0,2.8,0.2,6.4,0.3,10.1
				c0.1,3.8,0,6.5-0.4,9.1c-0.4,2.7-1,5.4-2.7,9.1c2.2-3.5,3.4-6,4.5-8.6C582.9,461.2,583.9,458.5,584.7,454.4z"
                />
              </g>
              <g>
                <path
                  d="M175.8,236.9c-0.3,3.3,0.7,6.7,2.2,8.7c1.4,2.1,3.1,2.9,3.7,2.4c0.7-0.5,0.5-2.1,0.1-4.1c-0.5-2.3-1-4.4-1.6-7.2
				c-0.5-2.8-0.6-4.8-0.4-6.9c0.2-2,0.7-4.2,2.3-6.9c-2.1,2.4-3.2,4.4-4.2,6.4C177,231.3,176.2,233.5,175.8,236.9z"
                />
              </g>
              <g>
                <path
                  d="M316.4,56.6c3.3,0.8,6.5,1.5,9,2c2.5,0.5,4.4,0.7,4.8-0.1c0.4-0.7-0.8-2.4-3.3-3.8c-2.5-1.5-6.3-2.6-10.1-2.5
				c-3.9,0.1-6.5,0.7-9.1,1.5c-2.5,0.8-5,1.6-8.5,3.4c3.6-1.2,6.2-1.4,8.7-1.5C310.5,55.6,313,55.9,316.4,56.6z"
                />
              </g>
              <g>
                <path
                  d="M166.4,149.7c1.4,3.4,3.9,6.3,6.1,7.9c2.2,1.7,4.1,2.1,4.7,1.5c0.6-0.7-0.3-2.3-1.7-4.4c-1.4-2.1-3.3-4.6-5.3-7.2
				c-2.1-2.7-3.3-4.7-4.4-6.9c-1.1-2.2-2-4.5-2.6-8.1c0.1,3.7,0.4,6.2,0.8,8.7C164.4,143.7,165,146.2,166.4,149.7z"
                />
              </g>
              <g>
                <path
                  d="M79.1,161.1c1.1-2.5,1.1-5.4,0.4-7.4c-0.7-2-1.8-3-2.6-2.8c-0.9,0.3-1.3,1.6-1.5,3.2c-0.3,1.7-0.4,3.7-0.5,5.9
				c-0.1,2.3-0.4,3.9-0.9,5.5c-0.5,1.6-1.3,3.2-3.1,5.1c2.1-1.5,3.4-2.8,4.7-4.2C76.7,165.2,77.9,163.7,79.1,161.1z"
                />
              </g>
              <g>
                <path
                  d="M340.8,260.3c3.5-6.8,4.5-14.6,2.8-15.1c-1.7-0.5-5.1,6.3-6.9,13.5c-0.9,3.8-1.5,6.4-2.2,9c-0.7,2.6-1.5,5.2-2.9,9
				c1.9-3.5,3.3-5.9,4.7-8.3C337.6,266.1,339,263.8,340.8,260.3z"
                />
              </g>
              <g>
                <path
                  d="M485,235.3c3.3,0,6.5-0.3,9-0.8c2.4-0.5,4.1-1.2,4.2-2.1c0-0.9-1.7-1.7-4.2-2.1c-2.6-0.4-6.1-0.3-9.4,0.6
				c-3.4,0.9-5.7,1.8-8,2.7c-2.4,1-4.7,1.9-7.8,3.1c3.5-0.8,5.9-1.1,8.3-1.3C479.3,235.3,481.6,235.3,485,235.3z"
                />
              </g>
              <g>
                <path
                  d="M596.5,141.5c-2.7,0.3-5.3,1.3-7,2.5c-1.7,1.2-2.6,2.4-2.2,3.2c0.4,0.8,1.9,0.9,3.8,0.6c1.9-0.3,4.3-1,6.5-2.1
				c2.4-1.1,4-1.9,5.8-2.5c1.7-0.7,3.5-1.2,6.3-1.7c-2.6-0.1-4.5-0.1-6.6-0.2C601.2,141.3,599.3,141.2,596.5,141.5z"
                />
              </g>
              <g>
                <path
                  d="M548.5,79c-2.4,1.8-4.6,3.6-6.2,5.2c-1.6,1.6-2.6,2.9-2.2,3.7c0.4,0.8,2.2,0.8,4.5-0.2c2.3-1,5.1-3,7.1-5.6
				c2-2.7,3.1-4.8,4-6.9c0.9-2.1,1.8-4.2,2.9-7.4c-1.5,3-3,4.7-4.5,6.3C552.6,75.7,550.9,77.1,548.5,79z"
                />
              </g>
              <g>
                <path
                  d="M482.8,101.2c3.5-0.6,7-1.5,9.5-2.4c2.6-0.9,4.3-1.9,4.2-2.8c-0.1-0.9-2.1-1.4-4.9-1.3c-2.8,0.1-6.6,0.8-10,2.3
				c-3.6,1.5-5.9,2.8-8.2,4.1c-2.3,1.3-4.6,2.7-7.9,4.7c3.6-1.5,6.1-2.3,8.6-2.9C476.6,102.3,479.2,101.9,482.8,101.2z"
                />
              </g>
              <g>
                <path
                  d="M503.3,526.1c2.5-1.7,3.3-4.9,2-6c-1.4-1.1-4.3,0.4-5.4,3.3c-1.2,2.9-1.3,4.3-2.8,7.1
				C499.4,528.3,500.7,527.9,503.3,526.1z"
                />
              </g>
              <g>
                <path
                  d="M281.3,518.4c2,0.3,3.8,0.4,5.3,0.2c1.5-0.1,2.7-0.5,3-1.3c0.3-0.8-0.5-2-2.2-2.8c-1.6-0.8-4.1-1.2-6.5-0.5
				c-2.4,0.7-3.9,1.5-5.3,2.3c-1.4,0.9-2.8,1.8-4.6,3.2c2.1-1,3.6-1.3,5.1-1.4C277.8,518,279.3,518.1,281.3,518.4z"
                />
              </g>
            </g>
          </g>
          <g id="Peddle8">
            <image
              overflow="visible"
              opacity="0.7"
              width="1460"
              height="1390"
              xlinkHref={Shadow5}
              transform="matrix(0.24 0 0 0.24 33.2693 66.3364)"
            ></image>
            <g>
              <g>
                <defs>
                  <path
                    id="SVGID_00000047755384654676544710000011147558475423264144_"
                    d="M207.5,153.7c30.7,28.1,144,167.6,122.2,191.4
					c-21.8,23.8-170.5-77.2-201.2-105.4c-30.7-28.1-56.6-93.3-37.7-113.9C109.6,105.3,176.8,125.6,207.5,153.7z"
                  />
                </defs>
                <clipPath id="SVGID_00000142894594086785239000000003396031046384564610_">
                  <use
                    xlinkHref="#SVGID_00000047755384654676544710000011147558475423264144_"
                    overflow="visible"
                  />
                </clipPath>
              </g>
              <g>
                <defs>
                  <path
                    id="SVGID_00000121274187953629864700000003335067637584479160_"
                    d="M207.5,153.7c30.7,28.1,144,167.6,122.2,191.4
					c-21.8,23.8-170.5-77.2-201.2-105.4c-30.7-28.1-56.6-93.3-37.7-113.9C109.6,105.3,176.8,125.6,207.5,153.7z"
                  />
                </defs>
                <use
                  xlinkHref="#SVGID_00000121274187953629864700000003335067637584479160_"
                  overflow="visible"
                  fill="#36B657"
                />
                <clipPath id="SVGID_00000083079947362289613660000006015101685951327400_">
                  <use
                    xlinkHref="#SVGID_00000121274187953629864700000003335067637584479160_"
                    overflow="visible"
                  />
                </clipPath>
                <g
                  opacity="0.2"
                  clipPath="url(#SVGID_00000083079947362289613660000006015101685951327400_)"
                >
                  <g>
                    <g>
                      <path
                        d="M312.1,219.6c8.1-1.2,15.4-3.8,20.8-7c5.4-3.2,8.8-6.9,8.1-10.4c-0.7-3.4-5.5-5.7-12.2-5.8c-6.7-0.1-15.4,2-22.9,6.8
							c-7.7,4.9-12.4,9-17.2,13c-4.7,4-9.4,8-16.3,13.7c8.1-3.8,13.9-5.6,19.8-6.9C297.9,221.7,303.8,220.8,312.1,219.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M314.5,239.9c7,1.8,13.9,2.1,19.5,1.1c5.5-1,9.7-3.2,10.5-6.6c0.8-3.4-2.4-7.3-8.1-9.9c-5.6-2.6-13.8-3.8-21.8-2.1
							c-8.2,1.8-13.5,3.9-18.8,6c-5.3,2.1-10.5,4.2-18,7.1c8-0.8,13.5-0.4,18.9,0.5C302,236.8,307.2,238.1,314.5,239.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M303.9,258.7c4.2,3.4,8.8,5.1,13,5.4c4.2,0.3,8.1-0.9,10-3.9c1.9-3,1.1-7.2-2.1-10.9c-3.1-3.7-8.6-6.8-14.9-7.1
							c-6.5-0.4-10.9,0.2-15.2,0.7c-4.3,0.5-8.6,1-14.6,1.5c5.8,1.7,9.5,3.7,12.9,5.9C296.4,252.6,299.5,255.2,303.9,258.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M282.6,276.1c1.5,4.7,4.5,8,8,9.7c3.4,1.7,7.3,1.9,10.2-0.1c2.9-2,4.1-5.7,3.3-9.8c-0.7-4-3.4-8.3-7.9-11
							c-4.6-2.8-8.1-4.2-11.5-5.8c-3.4-1.6-6.6-3.2-10.9-6.1c2.8,4.3,4.2,7.7,5.3,11.2C280.2,267.7,281.1,271.2,282.6,276.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M262,284.1c-1.5,5-1.3,9.6,0.1,13.5c1.3,3.8,3.8,7,7.3,7.6c3.4,0.7,7.1-1.5,9.3-5.8c2.2-4.2,3-10.5,0.8-16.4
							c-2.3-6-4.6-9.7-6.8-13.4c-2.3-3.6-4.5-7.1-7.6-12.2c1,5.8,0.8,9.9,0.1,13.8C264.5,275.2,263.5,279,262,284.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M238.4,276.8c-4.1,3.3-6.7,7-8.2,10.8c-0.3,1-0.7,1.9-0.9,2.9c-0.2,1-0.3,1.9-0.3,2.8c0,1.8,0.4,3.6,1.4,5
							c1,1.4,2.6,2.3,4.6,2.6c1,0.1,2.1,0.1,3.2-0.1c1.1-0.2,2.4-0.5,3.5-1.1c4.8-2.1,9.8-7.2,11.7-13.9c1.9-6.8,2-11.6,2-16.2
							c-0.1-4.6-0.3-9.1-0.8-15.3c-1.6,6-3.8,9.7-6.4,12.9C245.6,270.5,242.6,273.3,238.4,276.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M221.4,254.8c-5.9,0.7-10.8,2.7-14.6,5.4c-3.7,2.7-6.2,6.2-5.7,9.7c0.5,3.5,4.2,6.1,9.6,6.6
							c5.3,0.5,12.3-1.2,17.7-5.6c5.6-4.6,8.8-8.3,11.8-12c3-3.7,5.9-7.3,10.2-12.6c-5.6,3.6-10,5.2-14.3,6.3
							C231.8,253.5,227.4,254.1,221.4,254.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M214.7,232.3c-5.8-1.9-11.2-2.4-15.9-1.9c-1.2,0.2-2.3,0.3-3.3,0.7c-1.1,0.3-2,0.7-2.9,1.1c-1.7,0.9-3.1,2.2-3.7,3.8
							c-0.6,1.6-0.4,3.5,0.5,5.3c0.5,0.9,1.2,1.9,2,2.7c0.9,0.9,1.9,1.8,3.1,2.5c4.8,3.1,12.4,4.8,19.7,3.2c7.5-1.7,12.3-4,16.9-6.2
							c4.5-2.3,9-4.7,15-8.3c-6.9,1.6-11.7,1.4-16.4,0.8C225.1,235.5,220.6,234.3,214.7,232.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M221.6,214.1c-3.8-4.1-7-7.1-10.2-9.4c-1.6-1.2-3.2-2.2-4.8-3.1c-1.6-0.9-3.3-1.6-5.1-1.4c-1.7,0.1-3.3,1.2-4.4,3.3
							c-1.1,2.1-1.4,5.3-0.6,8.8c0.8,3.5,2.7,7.3,5.7,10.4c2.9,3.1,6.8,5.6,11,6.8c4.4,1.3,7.9,1.6,11.1,1.7
							c3.2,0.1,5.9-0.1,8.6-0.4c5.4-0.6,10.5-1.4,17.9-2.1c-7.3-1.5-12.2-3.2-16.6-5.4c-2.2-1.1-4.3-2.4-6.4-3.9
							C225.6,217.9,223.5,216.2,221.6,214.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M239.5,196.3c-0.1-5.5-0.9-9.8-2.3-13.6c-0.7-1.9-1.5-3.7-2.5-5.3c-1-1.6-2.2-2.9-3.9-3.6c-1.6-0.7-3.5-0.5-5.4,0.6
							c-1.9,1.1-3.7,3.3-4.9,6.3c-1.2,3-1.8,6.7-1.4,10.5c0.4,3.8,1.7,7.8,3.9,11.1c2.3,3.5,4.6,5.9,6.7,7.9c2.2,2,4.2,3.6,6.3,5.1
							c4.1,3,8.3,5.7,14,9.6c-4.3-5.5-6.5-9.9-8-14.2c-0.7-2.2-1.3-4.4-1.8-6.8C239.9,201.7,239.6,199.1,239.5,196.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M259.2,191.2c2.3-5.4,3.1-10.6,2.9-15.2c-0.1-1.2-0.2-2.2-0.4-3.3c-0.2-1.1-0.5-2-0.9-2.9c-0.8-1.8-1.9-3.2-3.5-4
							c-1.6-0.8-3.4-0.8-5.3,0c-1,0.4-2,1-2.9,1.8c-0.9,0.8-1.9,1.7-2.8,2.9c-3.5,4.5-5.8,11.9-4.6,19.3c1.3,7.6,3.3,12.4,5.3,17
							c2.1,4.6,4.3,9,7.7,15c-1.4-6.8-1-11.6-0.1-16C255.5,201.1,256.9,196.9,259.2,191.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M281.1,191.4c4.3-4.1,7.2-8,9.3-11.7c1-1.9,1.9-3.7,2.4-5.6c0.5-1.8,0.7-3.7,0-5.3c-0.6-1.6-2.1-2.9-4.3-3.4
							c-2.2-0.6-5.1-0.3-8.2,0.8c-3.1,1.2-6.3,3.3-8.9,6.2c-2.6,2.9-4.7,6.5-5.8,10.5c-1.2,4-1.6,7.4-1.8,10.4
							c-0.2,3-0.2,5.7-0.2,8.3c0.1,5.2,0.3,10.3,0,17.6c2.4-6.9,4.7-11.5,7.3-15.6c1.3-2.1,2.8-4,4.4-6
							C277.1,195.6,278.9,193.5,281.1,191.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M256.7,233.3c-1.5,3.2,1.5,6,5,5.6c3.5-0.4,5.8-3.7,3.7-6.5c-2.2-2.9-3.7-3.9-5.1-7
							C259.6,228.7,258.2,230,256.7,233.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M261.4,230.6c-1.4,1-1.8,2.3-1.5,3.7c0.3,1.4,1.3,2.8,2.8,3.6c1.5,0.9,3.3,0.9,4.7,0.2c1.4-0.7,2.3-2.2,2.1-4.1
							c-0.2-2-0.6-3.3-0.9-4.7c-0.3-1.3-0.6-2.6-0.7-4.4c-0.9,1.5-1.9,2.4-2.9,3.1C263.9,228.9,262.8,229.6,261.4,230.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M264.9,232.2c-1.3-0.6-2.1-0.2-2.9,0.7c-0.7,0.9-1.4,2.4-1.5,4.2c-0.1,1.7,0.7,3.3,2.1,4.1c1.4,0.8,3.3,0.7,4.8-0.7
							c1.5-1.4,2.2-2.7,2.8-3.8c0.6-1.2,1.2-2.3,2.1-3.6c-1.5,0.5-2.7,0.5-3.8,0.3C267.3,233.2,266.2,232.7,264.9,232.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M267.4,236.8c0-0.7-0.3-1.3-0.7-1.8c-0.5-0.5-1.1-1-1.9-1.2c-0.8-0.3-1.6-0.3-2.4-0.1c-0.8,0.2-1.5,0.6-2.1,1.3
							c-0.6,0.6-1.1,1.3-1.4,2c-0.3,0.7-0.6,1.4-0.6,2c0,0.7,0.1,1.3,0.5,2c0.4,0.6,1.1,1.3,2.1,1.7c1,0.5,2.1,0.8,3,1
							c0.9,0.2,1.7,0.2,2.5,0.1c1.6-0.1,3.1-0.5,4.5-1.6c-0.8,0.1-1.5-0.2-2-0.6c-0.5-0.4-0.8-0.8-1-1.3c-0.2-0.5-0.3-1-0.4-1.6
							C267.4,238,267.5,237.5,267.4,236.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M266.9,240c0.8-1.4,0.7-2.5-0.1-3.6c-0.7-1.1-2-2.2-3.8-2.6c-1.7-0.4-3.4,0.1-4.5,1.4c-1.1,1.3-1.4,3.5-0.4,5.4
							c1.1,2,2.1,3.1,3.2,4.1c1,1,2,2,3.2,3.4c-0.2-1.8,0-3.1,0.5-4.3C265.4,242.6,266.1,241.5,266.9,240z"
                      />
                    </g>
                    <g>
                      <path
                        d="M264.2,244.1c1.7-1.3,2.4-3.2,2.2-4.9c-0.2-1.7-1.4-3.1-3.1-3.7c-1.7-0.6-3.4-0.3-4.7,0.5c-1.3,0.8-2.1,2-2.1,3.8
							c0,1.9,0,3.3,0,4.7c-0.1,1.4-0.4,2.8-1.2,4.5c1.6-1,2.9-1.7,4.3-2.3C260.9,246.1,262.4,245.4,264.2,244.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M258.2,243.2c3.6,1.4,6.6-1.7,6.2-5.2c-0.4-3.5-4.2-5.7-7.3-3.5c-3.2,2.3-4.3,3.9-7.7,5.4
							C253,240.5,254.5,241.8,258.2,243.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M254.5,239.4c1.8,3.2,5.8,2.6,7.7-0.4c1.8-3,0.5-6.9-3.1-7c-3.7-0.1-5.5,0.5-8.9-0.3
							C252.5,234.3,252.7,236.1,254.5,239.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M255,235.1c0,1.7,1.1,2.7,2.4,3c1.3,0.3,3-0.2,4.3-1.3c1.4-1.1,2.1-2.6,2.3-3.8c0.1-1.2-0.4-2.3-1.8-2.8
							c-2.9-1-4.7-1.1-6.9-3C255.8,230.1,255,231.7,255,235.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M299.7,201.9c5.7-1.8,10.2-4.8,13.4-8.2c3.2-3.4,5-7.2,3.9-10.6c-1.1-3.3-5.3-5.2-10.8-4.6c-5.4,0.5-12,3.6-16.6,9.1
							c-4.7,5.7-7.1,10.1-9.4,14.4c-2.2,4.4-4.4,8.6-7.4,14.7c4.8-4.8,8.8-7.3,12.8-9.3C289.6,205.4,293.8,203.9,299.7,201.9z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M624.2,225.2c3.8,4.2,8.2,7.3,12.1,9.2c3.9,1.8,7.4,2.3,9.1,0.6c1.7-1.7,1.1-5.3-1.4-9.3c-2.6-3.9-7.1-8.1-12.8-10.5
							c-5.8-2.5-10-3.6-14.1-4.8c-4.1-1.1-8.3-2.3-14.2-4.1c5.3,3.1,8.6,5.8,11.6,8.7C617.5,217.9,620.3,220.9,624.2,225.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M613.5,234.5c1.7,4.7,4.2,8.8,6.9,11.6c2.7,2.8,5.6,4.4,7.9,3.4c2.2-0.9,3.3-4.2,2.6-8.5c-0.7-4.2-3.2-9.4-7.3-13.3
							c-4.2-4-7.5-6.2-10.7-8.5c-3.3-2.2-6.5-4.4-11.1-7.6c3.6,4.3,5.5,7.6,7.1,11C610.4,226.1,611.7,229.6,613.5,234.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M598.5,235.7c-0.3,3.7,0.5,7,2,9.6c1.5,2.5,3.7,4.3,6.1,4.2c2.4-0.1,4.5-2.2,5.4-5.4c0.9-3.2,0.5-7.6-1.7-11.3
							c-2.3-3.8-4.3-6.2-6.3-8.5c-2-2.3-3.9-4.5-6.5-7.8c1.3,4,1.6,6.8,1.6,9.7C599.1,229,598.9,231.8,598.5,235.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M580.4,230.2c-2.1,2.7-2.8,5.7-2.5,8.4c0.3,2.6,1.7,4.9,4,5.8c2.3,0.9,4.9,0.1,6.9-1.9c2-2,3.4-5.2,3.2-8.8
							c-0.2-3.7-0.7-6.3-1.1-8.9c-0.4-2.5-0.7-5.1-0.8-8.6c-1.4,3.3-2.8,5.4-4.3,7.4C584.2,225.6,582.5,227.5,580.4,230.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M567.7,221.5c-3.4,1.1-6,3-7.7,5.3c-1.7,2.3-2.5,4.9-1.5,7.2c0.9,2.2,3.6,3.5,7,3.1c3.3-0.4,7.2-2.4,9.7-5.9
							c2.6-3.6,3.8-6.4,5-9.1c1.2-2.7,2.3-5.4,4-9.1c-2.9,2.8-5.4,4.3-7.8,5.4C573.9,219.5,571.3,220.4,567.7,221.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M562.8,205.2c-3.5-1.1-6.6-1.1-9.4-0.5c-0.7,0.2-1.4,0.3-2,0.6c-0.6,0.3-1.2,0.6-1.7,0.9c-1.1,0.7-1.9,1.6-2.3,2.8
							c-0.4,1.1-0.3,2.4,0.3,3.6c0.3,0.6,0.7,1.2,1.3,1.8c0.6,0.6,1.2,1.1,2,1.6c3.1,1.9,8,2.8,12.5,1.3c4.6-1.6,7.4-3.3,10.1-5.1
							c2.6-1.8,5.1-3.7,8.4-6.4c-4.1,1.4-7.1,1.6-9.9,1.4C569.2,206.9,566.4,206.2,562.8,205.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M568.7,186.9c-2.6-3.1-5.7-5.2-8.7-6.3c-3-1.1-5.9-1.1-7.7,0.5c-1.8,1.6-1.9,4.8-0.1,8.1c1.8,3.3,5.4,6.6,10.1,8
							c4.8,1.4,8.2,1.8,11.5,2.1c3.3,0.3,6.5,0.5,11.2,0.9c-4.3-1.8-6.8-3.7-9.1-5.8C573.6,192.4,571.5,190.1,568.7,186.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M579.1,174.3c-1.1-4.1-3-7.4-5.1-9.9c-0.6-0.6-1.1-1.2-1.7-1.7c-0.6-0.5-1.2-0.9-1.8-1.2c-1.2-0.6-2.4-0.9-3.6-0.7
							c-1.2,0.3-2.2,1.1-2.8,2.4c-0.3,0.6-0.6,1.4-0.8,2.2c-0.2,0.8-0.3,1.8-0.2,2.8c0.1,4,2,9,5.8,12.6c3.9,3.7,7,5.5,10.1,7.2
							c3.1,1.7,6.2,3.3,10.6,5.4c-3.6-3.3-5.4-6.2-6.8-9.1C581.3,181.5,580.3,178.5,579.1,174.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M592.2,171.1c0.9-3.8,1.3-6.8,1.4-9.5c0.1-1.3,0-2.7-0.1-4c-0.1-1.3-0.4-2.5-1.2-3.5c-0.8-0.9-2-1.4-3.6-1.2
							c-1.6,0.2-3.6,1.2-5.3,3c-1.7,1.8-3.1,4.4-3.7,7.3c-0.6,2.9-0.6,6,0.3,8.9c0.9,3,2.1,5.2,3.3,7c1.2,1.8,2.4,3.3,3.6,4.8
							c2.4,2.9,4.9,5.5,8.1,9.5c-2-4.8-2.9-8.3-3.3-11.7c-0.2-1.7-0.3-3.4-0.3-5.2C591.6,175,591.7,173.1,592.2,171.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M609.4,174.5c3.1-2.2,5.3-4.3,6.9-6.6c0.8-1.1,1.5-2.3,2.1-3.5c0.5-1.2,0.8-2.4,0.6-3.6c-0.2-1.2-1.1-2.2-2.5-2.8
							c-1.4-0.6-3.3-0.8-5.5-0.4c-2.2,0.4-4.5,1.5-6.6,3.3c-2,1.7-3.8,4-4.8,6.5c-1.1,2.7-1.6,4.9-1.9,6.9c-0.3,2-0.4,3.8-0.5,5.6
							c-0.1,3.5,0,6.9-0.1,11.8c1.5-4.6,3.1-7.6,5.1-10.1c1-1.3,2-2.5,3.2-3.6C606.4,176.8,607.8,175.6,609.4,174.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M619.9,183.9c4-0.8,7.3-2.3,9.9-4.3c0.6-0.5,1.2-1,1.7-1.5c0.5-0.5,1-1.1,1.3-1.6c0.7-1.1,1.1-2.3,1-3.6
							c-0.2-1.2-0.9-2.3-2.1-3.1c-0.6-0.4-1.3-0.8-2.1-1c-0.8-0.2-1.7-0.4-2.7-0.5c-3.9-0.3-9.1,1.3-12.8,4.9
							c-3.8,3.7-5.8,6.7-7.7,9.6c-1.8,3-3.5,6-5.6,10.2c3.4-3.4,6.2-5.1,9.1-6.3C612.8,185.6,615.8,184.7,619.9,183.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M628.3,196.5c4,0.9,7.4,1,10.3,0.8c1.5-0.1,2.9-0.4,4.1-0.8c1.2-0.4,2.4-1,3.1-2c0.7-1,0.8-2.3,0.3-3.8
							c-0.5-1.5-1.8-3-3.7-4.4c-1.9-1.3-4.3-2.3-7-2.7c-2.7-0.4-5.6-0.2-8.3,0.7c-2.8,0.9-4.9,2-6.7,3c-1.8,1-3.3,2.1-4.8,3.1
							c-3,2.1-5.8,4.2-10.1,6.8c4.9-1.3,8.4-1.8,11.8-1.8c1.7,0,3.4,0,5.2,0.2C624.3,195.8,626.3,196,628.3,196.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M594.8,198.8c-2.4,0.4-2.8,3.2-1.3,5c1.6,1.9,4.4,1.9,5.2-0.4c0.8-2.4,0.8-3.7,2-5.7
							C598.6,198.6,597.3,198.3,594.8,198.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M598.2,200.4c-1.1-0.4-2-0.1-2.7,0.6c-0.7,0.7-1.1,1.8-1,3c0.1,1.2,0.8,2.2,1.7,2.7c0.9,0.5,2.2,0.5,3.2-0.4
							c1-0.9,1.7-1.6,2.3-2.3c0.6-0.7,1.3-1.4,2.2-2.1c-1.2,0-2.1-0.2-2.9-0.4C600.2,201.2,599.3,200.8,598.2,200.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M598.6,203c-0.2-0.9-0.7-1.3-1.5-1.4c-0.8-0.1-1.9,0.1-3,0.8c-1,0.6-1.7,1.7-1.6,2.8c0.1,1.1,0.9,2.2,2.2,2.5
							c1.4,0.3,2.4,0.2,3.3,0.1c0.9-0.1,1.8-0.2,2.9-0.2c-0.9-0.7-1.3-1.4-1.6-2.1C599,204.8,598.8,204,598.6,203z"
                      />
                    </g>
                    <g>
                      <path
                        d="M597,206.3c0.4-0.3,0.6-0.7,0.8-1.1c0.1-0.5,0.1-1,0-1.6c-0.1-0.5-0.4-1.1-0.9-1.4c-0.4-0.4-1-0.6-1.6-0.7
							c-0.6-0.1-1.2-0.1-1.7,0c-0.5,0.1-1,0.2-1.4,0.4c-0.4,0.2-0.7,0.6-0.9,1c-0.2,0.5-0.3,1.1-0.2,1.9c0.1,0.8,0.3,1.5,0.6,2.1
							c0.3,0.6,0.6,1.1,0.9,1.5c0.7,0.8,1.5,1.6,2.7,2c-0.4-0.5-0.5-1-0.4-1.4c0-0.4,0.2-0.8,0.4-1.1c0.2-0.3,0.5-0.6,0.7-0.9
							C596.3,206.7,596.6,206.6,597,206.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M594.9,207.2c1.1-0.1,1.7-0.6,2.1-1.5c0.3-0.8,0.5-2,0-3.2c-0.4-1.1-1.4-1.9-2.6-2c-1.2-0.1-2.5,0.5-3.2,1.9
							c-0.7,1.4-0.9,2.4-1.1,3.4c-0.2,1-0.4,1.9-0.7,3.2c0.9-0.8,1.8-1.2,2.7-1.4C592.9,207.4,593.8,207.3,594.9,207.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M591.5,207.3c1.4,0.5,2.8,0.1,3.7-0.7c0.9-0.8,1.2-2,0.9-3.2c-0.3-1.2-1.2-2.1-2.1-2.5c-0.9-0.4-2-0.4-3,0.3
							c-1.1,0.7-1.9,1.3-2.7,1.8c-0.8,0.5-1.7,0.9-3.1,1.1c1.2,0.5,2.1,1,3,1.6C589.1,206.1,590.1,206.7,591.5,207.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M589.7,203.4c0.6,2.6,3.6,3.1,5.4,1.5c1.8-1.6,1.7-4.6-0.8-5.5c-2.6-1-3.9-1-6.1-2.3
							C589.3,199.5,589.1,200.8,589.7,203.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M590.5,199.9c-1.1,2.2,0.8,4.3,3.2,4.2c2.4-0.1,4.2-2.4,2.8-4.5c-1.4-2.2-2.4-3-3.3-5.2
							C592.6,196.7,591.6,197.6,590.5,199.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M593.1,198.5c-0.9,0.7-1.1,1.7-0.8,2.5c0.4,0.9,1.3,1.6,2.4,2c1.2,0.3,2.3,0.2,3.1-0.2c0.8-0.4,1.2-1.1,0.9-2.1
							c-0.5-2-1.2-3.1-0.9-5.1C596.3,197,595.1,197.2,593.1,198.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M629.5,211.2c3.3,2.5,6.7,4,9.9,4.5c3.2,0.5,6.1,0.1,7.6-1.9c1.5-1.9,0.9-5.1-1.5-8c-2.4-2.9-6.8-5.5-11.7-6
							c-5.1-0.5-8.5-0.1-11.9,0.2c-3.4,0.4-6.7,0.8-11.3,1.5c4.6,0.9,7.6,2.2,10.3,3.7C623.6,206.8,626.1,208.6,629.5,211.2z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M241.1,523.3c3.8,4.2,8.2,7.3,12.1,9.2c3.9,1.8,7.4,2.3,9.1,0.6c1.7-1.7,1.1-5.3-1.4-9.3c-2.6-3.9-7.1-8.1-12.8-10.5
							c-5.8-2.5-10-3.6-14.1-4.8c-4.1-1.1-8.3-2.3-14.2-4.1c5.3,3.1,8.6,5.8,11.6,8.7C234.3,516,237.1,519,241.1,523.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M230.3,532.5c1.7,4.7,4.2,8.8,6.9,11.6c2.7,2.8,5.6,4.4,7.9,3.4c2.2-0.9,3.3-4.2,2.6-8.5c-0.7-4.2-3.2-9.4-7.3-13.3
							c-4.2-4-7.5-6.2-10.7-8.5c-3.3-2.2-6.5-4.4-11.1-7.6c3.6,4.3,5.5,7.6,7.1,11C227.3,524.1,228.6,527.6,230.3,532.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M215.4,533.7c-0.3,3.7,0.5,7,2,9.6c1.5,2.5,3.7,4.3,6.1,4.2c2.4-0.1,4.5-2.2,5.4-5.4c0.9-3.2,0.5-7.6-1.7-11.3
							c-2.3-3.8-4.3-6.2-6.3-8.5c-2-2.3-3.9-4.5-6.5-7.8c1.3,4,1.6,6.8,1.6,9.7C216,527.1,215.7,529.9,215.4,533.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M197.2,528.3c-2.1,2.7-2.8,5.7-2.5,8.4c0.3,2.6,1.7,4.9,4,5.8c2.3,0.9,4.9,0.1,6.9-1.9c2-2,3.4-5.2,3.2-8.8
							c-0.2-3.7-0.7-6.3-1.1-8.9c-0.4-2.5-0.7-5.1-0.8-8.6c-1.4,3.3-2.8,5.4-4.3,7.4C201.1,523.7,199.4,525.5,197.2,528.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M184.6,519.6c-3.4,1.1-6,3-7.7,5.3c-1.7,2.3-2.5,4.9-1.5,7.2c0.9,2.2,3.6,3.5,7,3.1c3.3-0.4,7.2-2.4,9.7-5.9
							c2.6-3.6,3.8-6.4,5-9.1c1.2-2.7,2.3-5.4,4-9.1c-2.9,2.8-5.4,4.3-7.8,5.4C190.7,517.6,188.1,518.4,184.6,519.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M179.7,503.2c-3.5-1.1-6.6-1.1-9.4-0.5c-0.7,0.2-1.4,0.3-2,0.6c-0.6,0.3-1.2,0.6-1.7,0.9c-1.1,0.7-1.9,1.6-2.3,2.8
							c-0.4,1.1-0.3,2.4,0.3,3.6c0.3,0.6,0.7,1.2,1.3,1.8c0.6,0.6,1.2,1.1,2,1.6c3.1,1.9,8,2.8,12.5,1.3c4.6-1.6,7.4-3.3,10.1-5.1
							c2.6-1.8,5.1-3.7,8.4-6.4c-4.1,1.4-7.1,1.6-9.9,1.4C186,504.9,183.3,504.3,179.7,503.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M185.6,484.9c-2.6-3.1-5.7-5.2-8.7-6.3c-3-1.1-5.9-1.1-7.7,0.5c-1.8,1.6-1.9,4.8-0.1,8.1c1.8,3.3,5.4,6.6,10.1,8
							c4.8,1.4,8.2,1.8,11.5,2.1c3.3,0.3,6.5,0.5,11.2,0.9c-4.3-1.8-6.8-3.7-9.1-5.8C190.4,490.4,188.4,488.1,185.6,484.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M195.9,472.3c-1.1-4.1-3-7.4-5.1-9.9c-0.6-0.6-1.1-1.2-1.7-1.7c-0.6-0.5-1.2-0.9-1.8-1.2c-1.2-0.6-2.4-0.9-3.6-0.7
							c-1.2,0.3-2.2,1.1-2.8,2.4c-0.3,0.6-0.6,1.4-0.8,2.2c-0.2,0.8-0.3,1.8-0.2,2.8c0.1,4,2,9,5.8,12.6c3.9,3.7,7,5.5,10.1,7.2
							c3.1,1.7,6.2,3.3,10.6,5.4c-3.6-3.3-5.4-6.2-6.8-9.1C198.2,479.5,197.1,476.5,195.9,472.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M209.1,469.2c0.9-3.8,1.3-6.8,1.4-9.5c0.1-1.3,0-2.7-0.1-4c-0.1-1.3-0.4-2.5-1.2-3.5c-0.8-0.9-2-1.4-3.6-1.2
							c-1.6,0.2-3.6,1.2-5.3,3c-1.7,1.8-3.1,4.4-3.7,7.3c-0.6,2.9-0.6,6,0.3,8.9c0.9,3,2.1,5.2,3.3,7c1.2,1.8,2.4,3.3,3.6,4.8
							c2.4,2.9,4.9,5.5,8.1,9.5c-2-4.8-2.9-8.3-3.3-11.7c-0.2-1.7-0.3-3.4-0.3-5.2C208.4,473,208.6,471.1,209.1,469.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M226.2,472.6c3.1-2.2,5.3-4.3,6.9-6.6c0.8-1.1,1.5-2.3,2.1-3.5c0.5-1.2,0.8-2.4,0.6-3.6c-0.2-1.2-1.1-2.2-2.5-2.8
							c-1.4-0.6-3.3-0.8-5.5-0.4c-2.2,0.4-4.5,1.5-6.6,3.3c-2,1.7-3.8,4-4.8,6.5c-1.1,2.7-1.6,4.9-1.9,6.9c-0.3,2-0.4,3.8-0.5,5.6
							c-0.1,3.5,0,6.9-0.1,11.8c1.5-4.6,3.1-7.6,5.1-10.1c1-1.3,2-2.5,3.2-3.6C223.3,474.9,224.6,473.7,226.2,472.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M236.8,481.9c4-0.8,7.3-2.3,9.9-4.3c0.6-0.5,1.2-1,1.7-1.5c0.5-0.5,1-1.1,1.3-1.6c0.7-1.1,1.1-2.3,1-3.6
							c-0.2-1.2-0.9-2.3-2.1-3.1c-0.6-0.4-1.3-0.8-2.1-1c-0.8-0.2-1.7-0.4-2.7-0.5c-3.9-0.3-9.1,1.3-12.8,4.9
							c-3.8,3.7-5.8,6.7-7.7,9.6c-1.8,3-3.5,6-5.6,10.2c3.4-3.4,6.2-5.1,9.1-6.3C229.7,483.6,232.7,482.8,236.8,481.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M245.2,494.6c4,0.9,7.4,1,10.3,0.8c1.5-0.1,2.9-0.4,4.1-0.8c1.2-0.4,2.4-1,3.1-2c0.7-1,0.8-2.3,0.3-3.8
							c-0.5-1.5-1.8-3-3.7-4.4c-1.9-1.3-4.3-2.3-7-2.7c-2.7-0.4-5.6-0.2-8.3,0.7c-2.8,0.9-4.9,2-6.7,3c-1.8,1-3.3,2.1-4.8,3.1
							c-3,2.1-5.8,4.2-10.1,6.8c4.9-1.3,8.4-1.8,11.8-1.8c1.7,0,3.4,0,5.2,0.2C241.2,493.9,243.1,494.1,245.2,494.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M211.7,496.8c-2.4,0.4-2.8,3.2-1.3,5c1.6,1.9,4.4,1.9,5.2-0.4c0.8-2.4,0.8-3.7,2-5.7
							C215.4,496.7,214.2,496.4,211.7,496.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M215,498.4c-1.1-0.4-2-0.1-2.7,0.6c-0.7,0.7-1.1,1.8-1,3c0.1,1.2,0.8,2.2,1.7,2.7c0.9,0.5,2.2,0.5,3.2-0.4
							c1-0.9,1.7-1.6,2.3-2.3c0.6-0.7,1.3-1.4,2.2-2.1c-1.2,0-2.1-0.2-2.9-0.4C217,499.2,216.2,498.9,215,498.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M215.5,501.1c-0.2-0.9-0.7-1.3-1.5-1.4c-0.8-0.1-1.9,0.1-3,0.8c-1,0.6-1.7,1.7-1.6,2.8c0.1,1.1,0.9,2.2,2.2,2.5
							c1.4,0.3,2.4,0.2,3.3,0.1c0.9-0.1,1.8-0.2,2.9-0.2c-0.9-0.7-1.3-1.4-1.6-2.1C215.9,502.9,215.7,502.1,215.5,501.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M213.9,504.3c0.4-0.3,0.6-0.7,0.8-1.1c0.1-0.5,0.1-1,0-1.6c-0.1-0.5-0.4-1.1-0.9-1.4c-0.4-0.4-1-0.6-1.6-0.7
							c-0.6-0.1-1.2-0.1-1.7,0c-0.5,0.1-1,0.2-1.4,0.4c-0.4,0.2-0.7,0.6-0.9,1c-0.2,0.5-0.3,1.1-0.2,1.9c0.1,0.8,0.3,1.5,0.6,2.1
							c0.3,0.6,0.6,1.1,0.9,1.5c0.7,0.8,1.5,1.6,2.7,2c-0.4-0.5-0.5-1-0.4-1.4c0-0.4,0.2-0.8,0.4-1.1c0.2-0.3,0.5-0.6,0.7-0.9
							C213.1,504.8,213.5,504.6,213.9,504.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M211.8,505.3c1.1-0.1,1.7-0.6,2.1-1.5c0.3-0.8,0.5-2,0-3.2c-0.4-1.1-1.4-1.9-2.6-2c-1.2-0.1-2.5,0.5-3.2,1.9
							c-0.7,1.4-0.9,2.4-1.1,3.4c-0.2,1-0.4,1.9-0.7,3.2c0.9-0.8,1.8-1.2,2.7-1.4C209.7,505.4,210.6,505.4,211.8,505.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M208.4,505.3c1.4,0.5,2.8,0.1,3.7-0.7c0.9-0.8,1.2-2,0.9-3.2c-0.3-1.2-1.2-2.1-2.1-2.5c-0.9-0.4-2-0.4-3,0.3
							c-1.1,0.7-1.9,1.3-2.7,1.8c-0.8,0.5-1.7,0.9-3.1,1.1c1.2,0.5,2.1,1,3,1.6C206,504.2,206.9,504.8,208.4,505.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M206.6,501.5c0.6,2.6,3.6,3.1,5.4,1.5c1.8-1.6,1.7-4.6-0.8-5.5c-2.6-1-3.9-1-6.1-2.3
							C206.1,497.5,205.9,498.8,206.6,501.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M207.3,497.9c-1.1,2.2,0.8,4.3,3.2,4.2c2.4-0.1,4.2-2.4,2.8-4.5c-1.4-2.2-2.4-3-3.3-5.2
							C209.5,494.8,208.5,495.6,207.3,497.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M210,496.6c-0.9,0.7-1.1,1.7-0.8,2.5c0.4,0.9,1.3,1.6,2.4,2c1.2,0.3,2.3,0.2,3.1-0.2c0.8-0.4,1.2-1.1,0.9-2.1
							c-0.5-2-1.2-3.1-0.9-5.1C213.2,495,211.9,495.2,210,496.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M246.4,509.3c3.3,2.5,6.7,4,9.9,4.5c3.2,0.5,6.1,0.1,7.6-1.9c1.5-1.9,0.9-5.1-1.5-8c-2.4-2.9-6.8-5.5-11.7-6
							c-5.1-0.5-8.5-0.1-11.9,0.2c-3.4,0.4-6.7,0.8-11.3,1.5c4.6,0.9,7.6,2.2,10.3,3.7C240.4,504.9,243,506.7,246.4,509.3z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M155.7,208.9c3.8,4.2,8.2,7.3,12.1,9.2c3.9,1.8,7.4,2.3,9.1,0.6c1.7-1.7,1.1-5.3-1.4-9.3c-2.6-3.9-7.1-8.1-12.8-10.5
							c-5.8-2.5-10-3.6-14.1-4.8c-4.1-1.1-8.3-2.3-14.2-4.1c5.3,3.1,8.6,5.8,11.6,8.7C148.9,201.6,151.7,204.6,155.7,208.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M144.9,218.2c1.7,4.7,4.2,8.8,6.9,11.6c2.7,2.8,5.6,4.4,7.9,3.4c2.2-0.9,3.3-4.2,2.6-8.5c-0.7-4.2-3.2-9.4-7.3-13.3
							c-4.2-4-7.5-6.2-10.7-8.5c-3.3-2.2-6.5-4.4-11.1-7.6c3.6,4.3,5.5,7.6,7.1,11C141.9,209.8,143.2,213.3,144.9,218.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M130,219.4c-0.3,3.7,0.5,7,2,9.6c1.5,2.5,3.7,4.3,6.1,4.2c2.4-0.1,4.5-2.2,5.4-5.4c0.9-3.2,0.5-7.6-1.7-11.3
							c-2.3-3.8-4.3-6.2-6.3-8.5c-2-2.3-3.9-4.5-6.5-7.8c1.3,4,1.6,6.8,1.6,9.7C130.6,212.7,130.3,215.5,130,219.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M111.8,214c-2.1,2.7-2.8,5.7-2.5,8.4c0.3,2.6,1.7,4.9,4,5.8c2.3,0.9,4.9,0.1,6.9-1.9c2-2,3.4-5.2,3.2-8.8
							c-0.2-3.7-0.7-6.3-1.1-8.9c-0.4-2.5-0.7-5.1-0.8-8.6c-1.4,3.3-2.8,5.4-4.3,7.4C115.7,209.3,114,211.2,111.8,214z"
                      />
                    </g>
                    <g>
                      <path
                        d="M99.2,205.2c-3.4,1.1-6,3-7.7,5.3c-1.7,2.3-2.5,4.9-1.5,7.2c0.9,2.2,3.6,3.5,7,3.1c3.3-0.4,7.2-2.4,9.7-5.9
							c2.6-3.6,3.8-6.4,5-9.1c1.2-2.7,2.3-5.4,4-9.1c-2.9,2.8-5.4,4.3-7.8,5.4C105.3,203.2,102.7,204.1,99.2,205.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M94.3,188.9c-3.5-1.1-6.6-1.1-9.4-0.5c-0.7,0.2-1.4,0.3-2,0.6c-0.6,0.3-1.2,0.6-1.7,0.9c-1.1,0.7-1.9,1.6-2.3,2.8
							c-0.4,1.1-0.3,2.4,0.3,3.6c0.3,0.6,0.7,1.2,1.3,1.8c0.6,0.6,1.2,1.1,2,1.6c3.1,1.9,8,2.8,12.5,1.3c4.6-1.6,7.4-3.3,10.1-5.1
							c2.6-1.8,5.1-3.7,8.4-6.4c-4.1,1.4-7.1,1.6-9.9,1.4C100.6,190.6,97.9,190,94.3,188.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M100.2,170.6c-2.6-3.1-5.7-5.2-8.7-6.3c-3-1.1-5.9-1.1-7.7,0.5c-1.8,1.6-1.9,4.8-0.1,8.1c1.8,3.3,5.4,6.6,10.1,8
							c4.8,1.4,8.2,1.8,11.5,2.1c3.3,0.3,6.5,0.5,11.2,0.9c-4.3-1.8-6.8-3.7-9.1-5.8C105,176.1,103,173.8,100.2,170.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M110.5,158c-1.1-4.1-3-7.4-5.1-9.9c-0.6-0.6-1.1-1.2-1.7-1.7c-0.6-0.5-1.2-0.9-1.8-1.2c-1.2-0.6-2.4-0.9-3.6-0.7
							c-1.2,0.3-2.2,1.1-2.8,2.4c-0.3,0.6-0.6,1.4-0.8,2.2c-0.2,0.8-0.3,1.8-0.2,2.8c0.1,4,2,9,5.8,12.6c3.9,3.7,7,5.5,10.1,7.2
							c3.1,1.7,6.2,3.3,10.6,5.4c-3.6-3.3-5.4-6.2-6.8-9.1C112.8,165.2,111.7,162.2,110.5,158z"
                      />
                    </g>
                    <g>
                      <path
                        d="M123.7,154.9c0.9-3.8,1.3-6.8,1.4-9.5c0.1-1.3,0-2.7-0.1-4c-0.1-1.3-0.4-2.5-1.2-3.5c-0.8-0.9-2-1.4-3.6-1.2
							c-1.6,0.2-3.6,1.2-5.3,3c-1.7,1.8-3.1,4.4-3.7,7.3c-0.6,2.9-0.6,6,0.3,8.9c0.9,3,2.1,5.2,3.3,7c1.2,1.8,2.4,3.3,3.6,4.8
							c2.4,2.9,4.9,5.5,8.1,9.5c-2-4.8-2.9-8.3-3.3-11.7c-0.2-1.7-0.3-3.4-0.3-5.2C123,158.7,123.2,156.8,123.7,154.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M140.8,158.2c3.1-2.2,5.3-4.3,6.9-6.6c0.8-1.1,1.5-2.3,2.1-3.5c0.5-1.2,0.8-2.4,0.6-3.6c-0.2-1.2-1.1-2.2-2.5-2.8
							c-1.4-0.6-3.3-0.8-5.5-0.4c-2.2,0.4-4.5,1.5-6.6,3.3c-2,1.7-3.8,4-4.8,6.5c-1.1,2.7-1.6,4.9-1.9,6.9c-0.3,2-0.4,3.8-0.5,5.6
							c-0.1,3.5,0,6.9-0.1,11.8c1.5-4.6,3.1-7.6,5.1-10.1c1-1.3,2-2.5,3.2-3.6C137.9,160.6,139.2,159.4,140.8,158.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M151.4,167.6c4-0.8,7.3-2.3,9.9-4.3c0.6-0.5,1.2-1,1.7-1.5c0.5-0.5,1-1.1,1.3-1.6c0.7-1.1,1.1-2.3,1-3.6
							c-0.2-1.2-0.9-2.3-2.1-3.1c-0.6-0.4-1.3-0.8-2.1-1c-0.8-0.2-1.7-0.4-2.7-0.5c-3.9-0.3-9.1,1.3-12.8,4.9
							c-3.8,3.7-5.8,6.7-7.7,9.6c-1.8,3-3.5,6-5.6,10.2c3.4-3.4,6.2-5.1,9.1-6.3C144.2,169.3,147.3,168.5,151.4,167.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M159.8,180.2c4,0.9,7.4,1,10.3,0.8c1.5-0.1,2.9-0.4,4.1-0.8c1.2-0.4,2.4-1,3.1-2c0.7-1,0.8-2.3,0.3-3.8
							c-0.5-1.5-1.8-3-3.7-4.4c-1.9-1.3-4.3-2.3-7-2.7c-2.7-0.4-5.6-0.2-8.3,0.7c-2.8,0.9-4.9,2-6.7,3c-1.8,1-3.3,2.1-4.8,3.1
							c-3,2.1-5.8,4.2-10.1,6.8c4.9-1.3,8.4-1.8,11.8-1.8c1.7,0,3.4,0,5.2,0.2C155.8,179.5,157.7,179.8,159.8,180.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M126.3,182.5c-2.4,0.4-2.8,3.2-1.3,5c1.6,1.9,4.4,1.9,5.2-0.4c0.8-2.4,0.8-3.7,2-5.7
							C130,182.3,128.8,182.1,126.3,182.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M129.6,184.1c-1.1-0.4-2-0.1-2.7,0.6c-0.7,0.7-1.1,1.8-1,3c0.1,1.2,0.8,2.2,1.7,2.7c0.9,0.5,2.2,0.5,3.2-0.4
							c1-0.9,1.7-1.6,2.3-2.3c0.6-0.7,1.3-1.4,2.2-2.1c-1.2,0-2.1-0.2-2.9-0.4C131.6,184.9,130.8,184.5,129.6,184.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M130.1,186.7c-0.2-0.9-0.7-1.3-1.5-1.4c-0.8-0.1-1.9,0.1-3,0.8c-1,0.6-1.7,1.7-1.6,2.8c0.1,1.1,0.9,2.2,2.2,2.5
							c1.4,0.3,2.4,0.2,3.3,0.1c0.9-0.1,1.8-0.2,2.9-0.2c-0.9-0.7-1.3-1.4-1.6-2.1C130.5,188.5,130.3,187.7,130.1,186.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M128.5,190c0.4-0.3,0.6-0.7,0.8-1.1c0.1-0.5,0.1-1,0-1.6c-0.1-0.5-0.4-1.1-0.9-1.4c-0.4-0.4-1-0.6-1.6-0.7
							c-0.6-0.1-1.2-0.1-1.7,0c-0.5,0.1-1,0.2-1.4,0.4c-0.4,0.2-0.7,0.6-0.9,1c-0.2,0.5-0.3,1.1-0.2,1.9c0.1,0.8,0.3,1.5,0.6,2.1
							c0.3,0.6,0.6,1.1,0.9,1.5c0.7,0.8,1.5,1.6,2.7,2c-0.4-0.5-0.5-1-0.4-1.4c0-0.4,0.2-0.8,0.4-1.1c0.2-0.3,0.5-0.6,0.7-0.9
							C127.7,190.4,128.1,190.3,128.5,190z"
                      />
                    </g>
                    <g>
                      <path
                        d="M126.4,190.9c1.1-0.1,1.7-0.6,2.1-1.5c0.3-0.8,0.5-2,0-3.2c-0.4-1.1-1.4-1.9-2.6-2c-1.2-0.1-2.5,0.5-3.2,1.9
							c-0.7,1.4-0.9,2.4-1.1,3.4c-0.2,1-0.4,1.9-0.7,3.2c0.9-0.8,1.8-1.2,2.7-1.4C124.3,191.1,125.2,191,126.4,190.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M123,191c1.4,0.5,2.8,0.1,3.7-0.7c0.9-0.8,1.2-2,0.9-3.2c-0.3-1.2-1.2-2.1-2.1-2.5c-0.9-0.4-2-0.4-3,0.3
							c-1.1,0.7-1.9,1.3-2.7,1.8c-0.8,0.5-1.7,0.9-3.1,1.1c1.2,0.5,2.1,1,3,1.6C120.6,189.9,121.5,190.4,123,191z"
                      />
                    </g>
                    <g>
                      <path
                        d="M121.2,187.2c0.6,2.6,3.6,3.1,5.4,1.5c1.8-1.6,1.7-4.6-0.8-5.5c-2.6-1-3.9-1-6.1-2.3
							C120.7,183.2,120.5,184.5,121.2,187.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M121.9,183.6c-1.1,2.2,0.8,4.3,3.2,4.2c2.4-0.1,4.2-2.4,2.8-4.5c-1.4-2.2-2.4-3-3.3-5.2
							C124.1,180.4,123.1,181.3,121.9,183.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M124.6,182.2c-0.9,0.7-1.1,1.7-0.8,2.5c0.4,0.9,1.3,1.6,2.4,2c1.2,0.3,2.3,0.2,3.1-0.2c0.8-0.4,1.2-1.1,0.9-2.1
							c-0.5-2-1.2-3.1-0.9-5.1C127.8,180.7,126.5,180.9,124.6,182.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M161,194.9c3.3,2.5,6.7,4,9.9,4.5c3.2,0.5,6.1,0.1,7.6-1.9c1.5-1.9,0.9-5.1-1.5-8c-2.4-2.9-6.8-5.5-11.7-6
							c-5.1-0.5-8.5-0.1-11.9,0.2c-3.4,0.4-6.7,0.8-11.3,1.5c4.6,0.9,7.6,2.2,10.3,3.7C155,190.5,157.6,192.3,161,194.9z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M353.7,131.6c-6.1,1.4-12.7,4.3-17.8,8c-2.6,1.8-4.9,3.9-7,6c-2,2.1-3.7,4.3-5.1,6.4c-2.8,4.3-4.2,8.4-4.6,11.5
							c-0.2,1.6-0.1,2.9,0.2,3.9c0.3,1,0.9,1.8,1.6,2.2c0.8,0.4,1.7,0.6,2.7,0.4c1-0.2,2.2-0.6,3.5-1.2c2.5-1.2,5.5-3.2,8.8-5.5
							c1.6-1.1,3.4-2.3,5.2-3.6c1.8-1.2,3.8-2.5,5.8-3.8c4-2.7,8.2-5.1,13.1-8c5-3,9.8-5.5,14-8.2c4.2-2.6,8-5.4,11.3-8.5
							c1.7-1.5,3.3-3.1,4.8-4.8c1.6-1.7,3.1-3.5,4.6-5.5c3-3.9,6-8.5,8.6-14.3c-3.6,5.3-7.4,9-11.1,11.8c-1.9,1.4-3.7,2.6-5.5,3.7
							c-1.8,1.1-3.7,2-5.5,2.7c-3.7,1.6-7.5,2.6-11.9,3.5C365,129.4,360,130.1,353.7,131.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M362.6,150.6c-5,2.2-10,4.1-14.7,5.9c-4.7,1.7-9.2,3.3-13.1,4.7c-4,1.4-7.4,2.8-9.9,4.2c-2.5,1.4-4.1,2.9-4.3,4.7
							c-0.1,1.7,1.3,3.5,4.2,5c2.8,1.5,7.1,2.5,12.3,2.7c5.2,0.2,11.2-0.6,17.4-2.6c6.1-2.1,12.3-5.3,17.6-9.8
							c5.4-4.6,9.3-9,12.4-13.3c3.1-4.3,5.4-8.3,7.5-12.4c2-4.1,3.8-8.4,5.4-13.2c1.6-4.8,3-10.2,4-16.7c-2,6.2-4.5,11.1-7.2,15.2
							c-2.6,4.1-5.4,7.4-8.4,10.3c-3,3-6.2,5.6-10,8.1C372,145.9,367.8,148.3,362.6,150.6z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M401.3,173.5c-6-1.9-13.1-2.8-19.4-2.2c-3.2,0.3-6.2,0.8-9,1.6c-2.8,0.8-5.4,1.8-7.7,2.9c-4.6,2.3-7.9,5-9.8,7.5
							c-1,1.2-1.6,2.4-1.8,3.5c-0.3,1.1-0.2,2,0.3,2.7c0.4,0.8,1.2,1.3,2.2,1.7c1,0.4,2.2,0.6,3.6,0.8c2.8,0.2,6.3,0.1,10.3-0.2
							c2-0.1,4.1-0.3,6.4-0.4c2.2-0.1,4.5-0.3,6.9-0.4c4.8-0.2,9.7-0.2,15.4-0.2c5.9,0,11.2,0.3,16.2,0.1c5-0.1,9.7-0.6,14.1-1.5
							c2.2-0.4,4.4-1,6.6-1.7c2.2-0.7,4.4-1.4,6.7-2.4c4.6-1.9,9.5-4.3,14.7-7.9c-5.8,2.7-10.9,3.9-15.6,4.5
							c-2.3,0.3-4.5,0.4-6.7,0.3c-2.1,0-4.2-0.2-6.2-0.5c-4-0.5-7.8-1.6-12-3C412.2,177.4,407.6,175.5,401.3,173.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M399.3,194.3c-5.4-0.7-10.7-1.6-15.6-2.5c-4.9-0.9-9.6-1.9-13.7-2.7c-4.1-0.8-7.8-1.4-10.7-1.5
							c-2.9-0.1-5.1,0.4-6.1,1.9c-1,1.4-0.6,3.7,1.1,6.4c1.7,2.7,4.8,5.8,9.2,8.6c4.3,2.8,9.9,5.3,16.3,6.7
							c6.3,1.4,13.3,1.8,20.1,0.6c7-1.1,12.6-3,17.5-5c4.8-2.1,8.9-4.4,12.8-6.9c3.9-2.5,7.6-5.3,11.4-8.6c3.8-3.3,7.8-7.3,12-12.3
							c-4.9,4.3-9.6,7.2-13.9,9.4c-4.4,2.2-8.4,3.5-12.5,4.6c-4.1,1-8.2,1.6-12.7,1.9C409.8,195.2,404.9,195.1,399.3,194.3z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M143.2,295.9c4-4.8,7.5-11.1,9.3-17.2c0.9-3.1,1.6-6.1,1.9-9c0.3-2.9,0.3-5.7,0.2-8.2c-0.4-5.1-1.7-9.2-3.3-11.9
							c-0.8-1.4-1.7-2.4-2.6-3c-0.9-0.6-1.8-0.9-2.6-0.8c-0.9,0.1-1.7,0.6-2.4,1.3c-0.7,0.8-1.4,1.8-2,3.1c-1.3,2.5-2.4,5.9-3.7,9.7
							c-0.6,1.9-1.3,3.9-2,6.1c-0.7,2.1-1.4,4.3-2.2,6.6c-1.6,4.6-3.4,9-5.5,14.3c-2.2,5.4-4.4,10.3-6.2,15
							c-1.7,4.7-3.1,9.2-3.8,13.6c-0.4,2.2-0.7,4.5-0.9,6.8c-0.2,2.3-0.3,4.7-0.3,7.1c0,4.9,0.4,10.4,1.8,16.6
							c-0.4-6.3,0.4-11.6,1.6-16.1c0.6-2.3,1.3-4.4,2.2-6.3c0.8-2,1.7-3.8,2.7-5.5c2-3.5,4.4-6.6,7.3-10
							C135.6,304.6,139.1,300.9,143.2,295.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M124.7,286.2c2.7-4.8,5.5-9.3,8.1-13.6c2.7-4.3,5.3-8.2,7.6-11.7c2.3-3.5,4.2-6.7,5.4-9.3c1.2-2.6,1.5-4.9,0.5-6.3
							c-1-1.5-3.2-2-6.4-1.4c-3.2,0.6-7.2,2.3-11.4,5.3c-4.2,3-8.6,7.3-12.2,12.6c-3.6,5.4-6.6,11.7-8.1,18.4
							c-1.5,6.9-1.9,12.8-1.8,18.1c0.1,5.3,0.7,9.9,1.6,14.4c0.9,4.5,2.1,9,3.7,13.8c1.7,4.8,3.8,9.9,7,15.7
							c-2.2-6.2-3.2-11.6-3.5-16.4c-0.4-4.8-0.2-9.2,0.4-13.3c0.6-4.2,1.6-8.2,3-12.5C120,295.7,121.9,291.1,124.7,286.2z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M453.4,473.1c-7.8,2.4-14.6,6.2-19.4,10.2c-4.8,4-7.6,8.2-6.4,11.6c1.2,3.3,6.3,4.8,13,3.8c6.7-1,14.9-4.4,21.5-10.4
							c6.8-6.1,10.9-10.9,14.9-15.6c4-4.7,8-9.4,14-16.1c-7.3,5.1-12.9,7.7-18.4,9.9C467.1,468.8,461.4,470.6,453.4,473.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M447.9,453.4c-7.2-0.7-14.1,0.2-19.4,2c-5.3,1.8-9.1,4.7-9.3,8.2c-0.2,3.5,3.5,6.8,9.5,8.5c6,1.7,14.2,1.6,21.8-1.4
							c7.8-3.1,12.7-6,17.6-8.9c4.9-2.9,9.7-5.8,16.7-9.9c-7.8,2.1-13.3,2.5-18.7,2.5C460.7,454.5,455.3,454.1,447.9,453.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M455.3,433.2c-4.7-2.7-9.5-3.7-13.7-3.3c-4.2,0.4-7.9,2.2-9.3,5.4c-1.4,3.2,0.1,7.2,3.8,10.4
							c3.7,3.1,9.6,5.3,15.9,4.7c6.4-0.7,10.7-1.9,14.9-3.1c4.2-1.2,8.3-2.4,14.1-3.8c-6-0.7-9.9-2.1-13.6-3.8
							C463.7,438,460.2,435.9,455.3,433.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M473.5,412.7c-2.2-4.4-5.7-7.2-9.4-8.4c-3.7-1.2-7.5-0.8-10.1,1.7c-2.6,2.4-3.1,6.3-1.8,10.2c1.4,3.9,4.7,7.6,9.6,9.6
							c5,2,8.7,2.9,12.3,3.9c3.6,1,7.1,2.1,11.8,4.3c-3.4-3.8-5.3-7-7-10.2C477.3,420.6,475.8,417.2,473.5,412.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M492.7,401.5c0.7-5.1-0.3-9.7-2.2-13.3c-1.9-3.6-4.9-6.3-8.4-6.4c-3.5-0.1-6.7,2.6-8.3,7.2
							c-1.5,4.5-1.3,10.8,1.8,16.3c3.2,5.6,6.1,8.9,8.9,12.1c2.8,3.2,5.6,6.3,9.4,10.8c-1.9-5.5-2.3-9.6-2.3-13.6
							C491.6,410.7,492,406.8,492.7,401.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M517.1,405c3.5-3.9,5.5-8,6.4-12c0.2-1,0.4-2,0.4-3c0-1,0-1.9-0.1-2.8c-0.3-1.8-1-3.5-2.2-4.7
							c-1.3-1.2-2.9-1.9-4.9-1.8c-1,0-2.1,0.2-3.1,0.6c-1.1,0.4-2.2,0.9-3.3,1.6c-4.4,2.8-8.5,8.7-9.3,15.5
							c-0.8,7-0.1,11.7,0.6,16.3c0.8,4.5,1.7,8.9,3.2,15c0.6-6.2,2.3-10.2,4.3-13.8C511,412.3,513.5,409,517.1,405z"
                      />
                    </g>
                    <g>
                      <path
                        d="M537.4,423.9c5.7-1.6,10.3-4.3,13.5-7.6c3.2-3.2,5.1-7.1,4.1-10.4c-1-3.3-5.1-5.3-10.5-5c-5.4,0.3-11.9,3.1-16.6,8.4
							c-4.8,5.4-7.3,9.6-9.8,13.8c-2.3,4.1-4.7,8.2-8.1,14.1c5-4.5,9-6.7,13.1-8.4C527.3,426.9,531.5,425.6,537.4,423.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M547.6,445.1c6,1,11.5,0.6,16-0.7c1.1-0.4,2.2-0.7,3.2-1.2c1-0.5,1.9-1,2.7-1.6c1.6-1.2,2.7-2.6,3.1-4.4
							c0.4-1.7-0.1-3.5-1.4-5.2c-0.6-0.8-1.4-1.7-2.5-2.4c-1-0.7-2.2-1.5-3.5-2c-5.2-2.3-13-2.8-20,0c-7.2,2.9-11.5,5.9-15.7,8.8
							c-4.1,3-8.1,6.1-13.5,10.5c6.6-2.7,11.4-3.3,16-3.4C536.8,443.6,541.4,444.1,547.6,445.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M543.7,464.2c4.4,3.5,8.1,5.9,11.5,7.7c1.7,0.9,3.5,1.7,5.2,2.3c1.7,0.6,3.5,1,5.3,0.6c1.7-0.4,3.1-1.7,3.8-4
							c0.7-2.2,0.6-5.4-0.8-8.8c-1.3-3.3-3.9-6.8-7.2-9.3c-3.4-2.6-7.5-4.4-11.9-5c-4.5-0.6-8.1-0.4-11.2,0
							c-3.1,0.4-5.8,1.1-8.4,1.8c-5.2,1.4-10.1,3.1-17.4,4.9c7.5,0.3,12.6,1.2,17.3,2.7c2.3,0.8,4.6,1.7,6.9,2.8
							C539.1,461,541.5,462.4,543.7,464.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M528.8,484.6c1,5.4,2.5,9.5,4.4,13.1c1,1.8,2.1,3.4,3.3,4.8c1.2,1.4,2.7,2.5,4.4,2.9c1.7,0.4,3.5,0,5.2-1.5
							c1.7-1.4,3.1-3.9,3.9-7c0.7-3.1,0.7-6.9-0.2-10.6c-1-3.7-2.9-7.4-5.6-10.3c-2.8-3.1-5.4-5.1-7.9-6.8c-2.5-1.7-4.7-2.9-7-4.1
							c-4.6-2.3-9.1-4.3-15.4-7.3c5.1,4.8,8,8.7,10.2,12.8c1.1,2,2,4.1,2.8,6.4C527.6,479.3,528.3,481.8,528.8,484.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M510.1,492.7c-1.4,5.7-1.4,11-0.4,15.5c0.3,1.1,0.5,2.2,0.9,3.2c0.4,1,0.8,1.9,1.3,2.7c1,1.6,2.4,2.9,4.1,3.4
							c1.7,0.5,3.5,0.2,5.3-0.9c0.9-0.5,1.8-1.3,2.6-2.2c0.8-0.9,1.6-2,2.3-3.3c2.7-5,3.8-12.7,1.5-19.8c-2.4-7.3-5.2-11.7-7.9-15.9
							c-2.8-4.2-5.7-8.2-10-13.6c2.4,6.5,2.8,11.3,2.7,15.8C512.2,482.3,511.5,486.8,510.1,492.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M488.5,496c-3.6,4.8-5.9,9-7.3,13c-0.7,2-1.3,4-1.5,5.9c-0.2,1.9-0.1,3.7,0.8,5.3c0.9,1.5,2.5,2.5,4.7,2.7
							c2.2,0.2,5.1-0.5,7.9-2.1c2.8-1.6,5.7-4.3,7.8-7.6c2.1-3.3,3.6-7.2,4.1-11.2c0.5-4.2,0.4-7.6,0.2-10.6c-0.3-3-0.7-5.6-1.1-8.2
							c-0.9-5.1-1.9-10.1-2.8-17.4c-1.3,7.2-2.8,12.1-4.8,16.5c-1,2.2-2.1,4.4-3.4,6.7C491.8,491.2,490.3,493.6,488.5,496z"
                      />
                    </g>
                    <g>
                      <path
                        d="M505.9,450.8c0.9-3.4-2.4-5.6-5.8-4.7c-3.4,0.9-5.2,4.5-2.7,7c2.6,2.5,4.3,3.3,6.2,6.1
							C503.8,455.8,505,454.3,505.9,450.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M501.8,454.2c1.2-1.2,1.4-2.6,0.9-3.9c-0.5-1.3-1.7-2.5-3.4-3.1c-1.7-0.6-3.4-0.4-4.6,0.6c-1.2,0.9-1.9,2.6-1.4,4.4
							c0.5,1.9,1.1,3.2,1.6,4.5c0.5,1.3,1,2.5,1.4,4.2c0.7-1.6,1.5-2.6,2.4-3.6C499.5,456.3,500.5,455.4,501.8,454.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M498,453.2c1.3,0.3,2.1-0.1,2.7-1.2c0.6-1,1-2.6,0.8-4.4c-0.2-1.7-1.2-3.2-2.7-3.8c-1.5-0.6-3.4-0.2-4.6,1.4
							c-1.2,1.6-1.7,3-2.2,4.2c-0.4,1.3-0.8,2.4-1.5,3.9c1.4-0.8,2.6-0.9,3.7-0.9C495.5,452.6,496.6,452.9,498,453.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M494.8,449.1c0.1,0.7,0.5,1.2,1,1.7c0.5,0.4,1.3,0.8,2.1,0.9c0.8,0.1,1.7,0.1,2.4-0.3c0.8-0.3,1.4-0.9,1.9-1.6
							c0.5-0.7,0.8-1.5,1.1-2.2c0.2-0.7,0.3-1.4,0.3-2.1c-0.1-0.7-0.3-1.3-0.8-1.8c-0.5-0.6-1.3-1.1-2.4-1.4
							c-1.1-0.3-2.2-0.5-3.1-0.5c-0.9,0-1.7,0.1-2.5,0.3c-1.5,0.4-3,0.9-4.2,2.3c0.8-0.2,1.5,0,2.1,0.3c0.5,0.3,0.9,0.7,1.2,1.2
							c0.3,0.5,0.5,1,0.7,1.5C494.6,447.8,494.6,448.4,494.8,449.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M494.8,445.7c-0.6,1.5-0.3,2.6,0.6,3.6c0.9,1,2.4,1.8,4.1,2c1.7,0.2,3.4-0.6,4.2-2.1c0.9-1.5,0.9-3.7-0.5-5.4
							c-1.4-1.8-2.6-2.7-3.8-3.6c-1.2-0.9-2.3-1.7-3.7-2.8c0.5,1.8,0.5,3.1,0.2,4.4C495.9,443,495.4,444.2,494.8,445.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M496.9,441.3c-1.5,1.5-1.9,3.6-1.4,5.2c0.5,1.6,1.9,2.8,3.6,3.1c1.7,0.3,3.4-0.2,4.5-1.2c1.1-1,1.7-2.3,1.5-4.1
							c-0.3-1.8-0.6-3.2-0.7-4.6c-0.1-1.4-0.1-2.8,0.5-4.7c-1.5,1.3-2.6,2.1-3.9,3C499.7,438.9,498.4,439.7,496.9,441.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M502.9,441.2c-3.7-0.8-6.3,2.7-5.3,6.1c1,3.4,5,5,7.7,2.3c2.8-2.8,3.6-4.5,6.8-6.6C508.4,443.1,506.8,442,502.9,441.2
							z"
                      />
                    </g>
                    <g>
                      <path
                        d="M507.1,444.4c-2.2-2.9-6.2-1.6-7.5,1.7c-1.3,3.3,0.6,6.9,4.2,6.5c3.7-0.5,5.4-1.4,8.9-1.1
							C510,449.2,509.4,447.3,507.1,444.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M507.3,448.7c-0.3-1.6-1.5-2.5-2.9-2.6c-1.4-0.1-2.9,0.6-4.1,2c-1.2,1.3-1.7,2.9-1.7,4.1c0.1,1.3,0.7,2.2,2.2,2.4
							c3,0.6,4.8,0.4,7.3,1.9C507.4,453.8,507.9,452.1,507.3,448.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M468.5,488.6c-5.3,2.7-9.3,6.4-11.9,10.2c-2.6,3.8-3.8,7.9-2.1,11.1c1.6,3.1,6.1,4.3,11.4,2.9
							c5.3-1.4,11.3-5.5,15-11.7c3.7-6.3,5.4-11.1,7-15.7c1.5-4.7,3-9.2,5-15.7c-3.9,5.5-7.5,8.6-11.2,11.3
							C477.9,483.5,474,485.7,468.5,488.6z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M426.3,566.6c5.8-2.4,11.9-6.2,16.3-10.7c2.3-2.2,4.3-4.6,5.9-7c1.7-2.4,3-4.8,4-7.2c2.1-4.7,2.9-8.9,2.7-12.1
							c-0.1-1.6-0.4-2.9-0.8-3.9c-0.5-1-1.1-1.6-2-1.9c-0.8-0.3-1.8-0.3-2.8,0c-1,0.3-2.1,0.9-3.2,1.7c-2.3,1.6-4.9,4-7.8,6.8
							c-1.4,1.4-3,2.8-4.6,4.4c-1.6,1.5-3.3,3.1-5.1,4.7c-3.5,3.3-7.3,6.4-11.7,10c-4.5,3.7-8.8,7-12.5,10.3
							c-3.8,3.3-7,6.6-9.9,10.2c-1.4,1.8-2.7,3.6-4,5.5c-1.3,1.9-2.5,4-3.7,6.1c-2.3,4.4-4.5,9.3-6.2,15.5c2.7-5.8,5.9-10,9.1-13.4
							c1.6-1.7,3.2-3.2,4.9-4.5c1.6-1.3,3.3-2.5,5-3.6c3.4-2.1,7-3.8,11.2-5.4C415.5,570.6,420.3,569,426.3,566.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M414.5,549.3c4.6-2.9,9.2-5.6,13.6-8.1c4.4-2.5,8.6-4.7,12.2-6.7c3.7-2,6.9-3.9,9.1-5.7c2.3-1.8,3.6-3.6,3.5-5.3
							c-0.2-1.7-1.9-3.3-4.9-4.3c-3-1-7.4-1.4-12.6-0.7c-5.1,0.6-11,2.3-16.8,5.3c-5.7,3-11.4,7.2-15.8,12.4
							c-4.6,5.4-7.8,10.4-10.2,15.1c-2.4,4.7-4.1,9.1-5.4,13.5c-1.4,4.4-2.4,8.9-3.2,13.9c-0.8,5-1.3,10.6-1.3,17.1
							c1-6.5,2.7-11.7,4.7-16.1c2-4.4,4.2-8.1,6.7-11.5c2.5-3.4,5.3-6.5,8.5-9.6C405.9,555.4,409.8,552.3,414.5,549.3z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M372.6,532.7c6.2,0.9,13.4,0.7,19.5-0.9c3.1-0.8,6-1.8,8.7-3c2.7-1.2,5-2.6,7.1-4.1c4.2-3,7-6.2,8.5-9
							c0.7-1.4,1.2-2.7,1.2-3.7c0.1-1.1-0.1-2-0.7-2.7c-0.5-0.7-1.4-1.1-2.4-1.4c-1-0.2-2.3-0.3-3.7-0.2c-2.8,0.2-6.3,0.9-10.2,1.8
							c-1.9,0.4-4,0.9-6.2,1.4c-2.2,0.5-4.4,1-6.8,1.5c-4.7,1-9.5,1.8-15.1,2.6c-5.8,0.9-11.1,1.5-16,2.4c-4.9,0.9-9.4,2.1-13.7,3.7
							c-2.1,0.8-4.2,1.7-6.3,2.7c-2.1,1-4.2,2.1-6.3,3.4c-4.2,2.6-8.7,5.7-13.3,10.1c5.3-3.6,10.2-5.6,14.7-6.9
							c2.3-0.6,4.4-1.1,6.5-1.4c2.1-0.3,4.1-0.5,6.2-0.5c4-0.1,7.9,0.3,12.4,1.1C361.2,530.7,366.2,531.8,372.6,532.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M371.3,511.9c5.5-0.2,10.8-0.1,15.8,0c5,0.1,9.8,0.3,14,0.5c4.2,0.1,7.9,0.1,10.8-0.2c2.9-0.4,4.9-1.2,5.7-2.8
							c0.7-1.6,0.1-3.8-2.1-6.2c-2.1-2.4-5.7-5-10.4-7.1c-4.7-2.1-10.7-3.6-17.1-4c-6.5-0.3-13.4,0.4-20,2.6
							c-6.7,2.2-12,4.9-16.5,7.7c-4.4,2.8-8.1,5.7-11.6,8.8c-3.4,3.1-6.6,6.4-9.9,10.3c-3.2,3.9-6.6,8.4-9.8,14
							c4.2-5,8.3-8.7,12.2-11.5c4-2.8,7.8-4.8,11.6-6.5c3.9-1.6,7.8-2.9,12.2-3.9C360.8,512.7,365.7,512,371.3,511.9z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M514.4,139c10.6-1.1,21.3-3.7,30.7-7.7c9.4-3.9,17.6-9.3,23.7-14.8c6.1-5.5,10.2-11.1,12.5-15.3
							c2.3-4.2,2.9-6.9,2.1-7.4c-1.6-1-7.7,7.5-19.9,16.2c-6.1,4.3-13.6,8.7-22.2,12.2c-8.6,3.5-18.2,6.3-28.2,8.1
							c-10.2,1.9-19.1,2.6-27.2,3.2c-8.1,0.6-15.5,1.1-23,1.9c-7.4,0.8-14.9,1.9-23.1,4.1c-4.1,1.1-8.3,2.5-12.7,4.4
							c-4.4,1.9-8.9,4.3-13.4,7.6c9.3-6,18.4-8.6,26.5-10.2c8.2-1.5,15.6-1.9,22.9-2c7.4-0.1,14.7,0.2,22.9,0.5
							C494.2,139.9,503.4,140.1,514.4,139z"
                      />
                    </g>
                    <g>
                      <path
                        d="M458.8,128.6c2.7-1.5,4.5-4,5.1-6.3c0.6-2.3,0-4.4-1.5-5.3c-1.5-0.9-3.5-0.6-5.3,0.4c-1.8,1-3.5,2.7-4.6,5.2
							c-1.1,2.5-2,4.3-3,6c-1,1.7-2.2,3.4-4.4,5.4c2.8-1.1,4.7-1.7,6.8-2.4C454,130.8,456,130.1,458.8,128.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M475.9,123.1c2.3-1.3,3.6-3.6,3.8-5.6c0.2-2.1-0.6-3.8-2.1-4.7c-1.6-0.8-3.4-0.6-5,0.2c-1.6,0.8-2.9,2.3-3.5,4.4
							c-0.7,2.2-1.1,3.8-1.7,5.4c-0.6,1.6-1.4,3.1-3,5c2.3-1,3.9-1.6,5.6-2.2C471.8,125,473.6,124.4,475.9,123.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M493,119.2c2.2-1.6,3.5-4,3.7-6.1c0.2-2.1-0.5-4-2.1-4.8c-1.5-0.8-3.5-0.5-5.1,0.5c-1.7,1.1-3.1,2.9-3.7,5.3
							c-0.6,2.5-1,4.3-1.5,6.1c-0.5,1.8-1.2,3.6-2.5,5.8c2.2-1.6,3.8-2.5,5.5-3.5C488.9,121.7,490.7,120.8,493,119.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M512.9,117.7c2.4-2.2,3.5-5.3,3.4-7.8c-0.1-2.5-1.3-4.3-3-4.6c-1.7-0.4-3.5,0.6-4.9,2c-1.4,1.4-2.5,3.4-2.9,5.9
							c-0.5,2.6-1,4.5-1.6,6.3c-0.7,1.8-1.6,3.7-3.7,5.8c2.7-1.3,4.5-2.3,6.5-3.3C508.4,121,510.4,119.9,512.9,117.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M529.5,112.5c2.1-2.5,3.1-5.6,2.9-8.1c-0.1-2.5-1.2-4.4-2.9-4.8c-1.7-0.5-3.6,0.5-5,2.2c-1.5,1.7-2.6,4.1-3,7
							c-0.4,3-0.6,5.1-1,7.2c-0.4,2.1-1,4.3-2.4,7.1c2.3-2.2,4-3.7,5.7-5.2C525.6,116.6,527.3,115.1,529.5,112.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M541.5,108.7c2-2,2.9-4.7,2.9-7c-0.1-2.2-1.2-4-2.8-4.5c-1.7-0.5-3.5,0.2-5,1.5c-1.5,1.4-2.6,3.4-2.9,6
							c-0.3,2.6-0.5,4.5-0.8,6.4c-0.3,1.9-0.8,3.8-2,6.3c2.1-1.9,3.6-3.1,5.2-4.3C537.8,112,539.4,110.8,541.5,108.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M558.6,100c2.1-2.3,3.1-5.3,3-7.7c0-2.4-1.1-4.3-2.7-4.9c-1.7-0.5-3.6,0.3-5.1,1.9c-1.5,1.6-2.7,4-3.1,6.8
							c-0.4,2.9-0.6,5-0.9,7.1c-0.4,2.1-0.9,4.2-2.2,7c2.2-2.2,3.8-3.7,5.5-5.1C554.7,103.8,556.5,102.4,558.6,100z"
                      />
                    </g>
                    <g>
                      <path
                        d="M573,93.6c2.2-1.8,3.6-4.1,4.1-6.2c0.5-2.2,0.2-4.2-1.2-5.3c-1.4-1.1-3.5-0.9-5.5,0.3c-2,1.2-3.9,3.5-4.7,6.5
							c-0.8,3-1.2,5.2-1.5,7.3c-0.4,2.1-0.8,4.2-1.6,7.1c1.8-2.4,3.3-3.9,4.9-5.2C569,96.6,570.7,95.4,573,93.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M587.3,86.9c2.3-1.1,3.9-2.9,4.8-4.7c0.8-1.8,0.9-3.8-0.1-5.2c-1-1.4-3.1-1.9-5.2-1.2c-2.2,0.6-4.5,2.4-5.7,5.1
							c-1.3,2.7-1.9,4.7-2.5,6.7c-0.6,2-1.2,3.9-2.3,6.5c1.9-2,3.6-3.2,5.3-4.1C583.1,88.9,584.9,88.1,587.3,86.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M602.4,93.3c2.3,0.6,4.4,0.3,6.1-0.6c1.7-0.9,3-2.3,3.1-4.1c0.1-1.7-1-3.4-3.1-4.3c-2-0.9-4.8-1-7.4,0.3
							c-2.6,1.3-4.2,2.6-5.7,3.7c-1.5,1.2-3.1,2.4-5.3,3.9c2.6-0.5,4.5-0.5,6.3-0.2C598.3,92.3,600,92.7,602.4,93.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M602.3,107.3c2.1,1.2,4.2,1.5,6.1,1.3c1.9-0.3,3.6-1.1,4.4-2.7c0.8-1.6,0.2-3.6-1.6-5.2c-1.8-1.6-4.7-2.6-7.7-2.1
							c-3.1,0.5-5.1,1.4-7,2.2c-2,0.9-3.8,1.7-6.3,2.8c2.8-0.1,4.7,0.4,6.5,1C598.4,105.2,600.1,106.1,602.3,107.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M598.1,120.2c2.4,1.8,5,2.6,7.4,2.6c2.3,0,4.2-0.9,4.9-2.5c0.7-1.6-0.1-3.6-1.8-5.3c-1.7-1.6-4.4-2.9-7.5-3.1
							c-6.3-0.3-8.9,0.5-15.1-0.2C591.6,114.5,593.3,116.5,598.1,120.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M455.6,157.1c1.3,2.2,2.9,3.7,4.7,4.7c1.8,0.9,3.8,1.2,5.3,0.3c1.5-0.9,2.1-3,1.3-5.4c-0.7-2.4-2.8-5-5.7-6.3
							c-3-1.3-5.3-1.7-7.4-2.1c-2.2-0.4-4.3-0.7-7.2-1.1c2.6,1.4,4.1,2.9,5.4,4.4C453.2,153.1,454.3,154.8,455.6,157.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M472.5,157.1c1.1,2.4,2.4,4.3,3.9,5.8c1.5,1.5,3.1,2.7,4.9,2.5c0.9-0.1,1.6-0.6,2.2-1.6c0.6-0.9,0.9-2.2,0.8-3.8
							c-0.1-1.5-0.5-3.3-1.5-4.9c-0.9-1.6-2.3-3.2-4-4.3c-1.8-1.1-3.4-1.8-4.8-2.2c-1.5-0.4-2.8-0.7-4.1-0.8
							c-2.6-0.3-5.1-0.3-8.6,0.2c3.4,0.6,5.5,1.9,7.1,3.3C470,152.9,471.3,154.7,472.5,157.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M487.8,156.6c1.5,2.4,3.4,4.2,5.4,5.3c1.9,1.1,4,1.5,5.5,0.6c1.5-0.9,2-3.1,1.1-5.7c-0.9-2.6-3.1-5.4-6.4-6.9
							c-3.3-1.5-5.7-2.1-8.1-2.5c-2.4-0.5-4.7-0.9-8-1.4c3,1.5,4.7,3.1,6.1,4.7C484.9,152.4,486.2,154.1,487.8,156.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M506.5,159.3c2.5,2.3,5.7,3.4,8.3,3.4c2.6,0,4.6-1.1,5.1-2.7c0.5-1.7-0.5-3.6-2.2-5.1c-1.7-1.6-4.2-2.8-7.2-3.3
							c-3-0.5-5.2-0.9-7.4-1.4c-2.2-0.5-4.3-1.3-7.2-2.9c2.2,2.5,3.6,4.3,5.1,6.1C502.5,155.1,504,157,506.5,159.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M525.3,155.5c3.6,1.7,7.5,2.2,10.5,1.7c3-0.5,5.1-1.9,5.2-3.6c0.2-1.8-1.5-3.5-4.1-4.7c-2.6-1.2-6-2-9.7-2
							c-3.8,0.1-6.5,0.2-9.2,0.1c-2.7-0.1-5.5-0.3-9.4-1.3c3.5,1.9,5.9,3.4,8.3,4.9C519.2,152.2,521.6,153.7,525.3,155.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M549,150.1c6.4,2.5,13.2,0.2,13.4-3.3c0.2-3.5-6-6.6-12.5-5.4c-6.7,1.1-9.4,2.3-16.4,2.3
							C540.3,145.7,542.5,147.5,549,150.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M568.8,144.1c3.4,1.2,7.1,1.2,9.7,0.4c2.7-0.8,4.5-2.3,4.5-4.1c0-1.8-1.7-3.3-4.2-4.3c-2.5-1-5.8-1.4-9.2-0.8
							c-3.5,0.6-6,1.1-8.5,1.5c-2.5,0.4-5.1,0.6-8.8,0.4c3.5,1.2,5.9,2.3,8.2,3.4C562.9,141.7,565.2,142.8,568.8,144.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M580.9,135.9c1.6,1.6,3.5,2.2,5.3,2.2c1.8,0,3.5-0.8,4.4-2.3c0.9-1.5,0.7-3.5-0.5-5.1c-1.2-1.6-3.4-2.9-6.1-3
							c-2.7,0-4.6,0.3-6.4,0.5c-1.8,0.3-3.6,0.5-6,0.6c2.3,0.9,3.7,1.9,5,3C578.1,133,579.3,134.2,580.9,135.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M591.4,129.9c3.6,4.4,9.6,5,11.5,2c1.9-3-1.3-8.1-6.8-9.4c-5.7-1.4-8.2-1.2-13.6-3.3
							C586.8,123.1,587.7,125.4,591.4,129.9z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M191.4,346.1c2,10.5,5.5,20.9,10.2,29.9c4.7,9.1,10.8,16.7,16.8,22.3c6,5.6,11.9,9.2,16.3,11.1
							c4.4,1.9,7.2,2.3,7.5,1.5c0.9-1.7-8.1-7.1-17.8-18.4c-4.8-5.7-9.8-12.8-14-21.1c-4.2-8.3-7.8-17.6-10.5-27.4
							c-2.8-10-4.2-18.8-5.5-26.9c-1.3-8.1-2.4-15.4-3.8-22.7c-1.4-7.3-3.2-14.7-6-22.6c-1.4-4-3.2-8.1-5.4-12.3
							c-2.2-4.2-5.1-8.5-8.7-12.7c6.8,8.8,10.2,17.6,12.4,25.5c2.2,8,3.2,15.3,4,22.6c0.7,7.3,1,14.6,1.5,22.8
							C188.8,326.1,189.3,335.3,191.4,346.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M197,289.9c1.7,2.6,4.4,4.1,6.8,4.5c2.4,0.4,4.4-0.3,5.2-1.9c0.8-1.6,0.3-3.5-0.8-5.3c-1.2-1.7-3-3.3-5.5-4.1
							c-2.6-0.9-4.5-1.6-6.3-2.5c-1.8-0.9-3.6-1.9-5.7-3.9c1.3,2.7,2.1,4.6,3,6.6C194.4,285.3,195.3,287.3,197,289.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M204,306.5c1.5,2.2,3.9,3.3,5.9,3.4c2.1,0.1,3.8-0.9,4.5-2.5c0.7-1.6,0.3-3.5-0.6-4.9c-1-1.5-2.5-2.6-4.7-3.1
							c-2.2-0.5-3.9-0.8-5.6-1.3c-1.6-0.5-3.2-1.2-5.2-2.6c1.2,2.2,1.9,3.8,2.7,5.4C201.7,302.5,202.5,304.3,204,306.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M209.3,323.1c1.8,2.1,4.2,3.1,6.4,3.2c2.1,0.1,3.9-0.8,4.6-2.5c0.7-1.6,0.2-3.5-1-5.1c-1.2-1.6-3.1-2.8-5.6-3.2
							c-2.5-0.4-4.4-0.6-6.2-1c-1.8-0.4-3.6-0.9-6-2c1.7,2,2.8,3.6,3.9,5.2C206.4,319.3,207.5,321,209.3,323.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M212.5,342.8c2.4,2.2,5.6,3,8,2.7c2.5-0.3,4.1-1.7,4.3-3.4c0.2-1.8-0.9-3.4-2.4-4.7c-1.5-1.3-3.6-2.2-6.1-2.4
							c-2.6-0.3-4.5-0.6-6.4-1.1c-1.9-0.5-3.8-1.3-6.1-3.2c1.5,2.5,2.6,4.3,3.8,6.2C208.8,338.7,210.1,340.6,212.5,342.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M219.1,359c2.6,1.9,5.8,2.6,8.3,2.2c2.5-0.3,4.3-1.6,4.6-3.3c0.3-1.7-0.8-3.5-2.7-4.8c-1.8-1.3-4.4-2.3-7.3-2.4
							c-3-0.1-5.1-0.1-7.3-0.4c-2.2-0.2-4.3-0.6-7.3-1.8c2.4,2.1,4,3.6,5.6,5.2C214.7,355.4,216.3,357,219.1,359z"
                      />
                    </g>
                    <g>
                      <path
                        d="M223.9,370.7c2.2,1.9,5,2.5,7.2,2.2c2.2-0.3,3.9-1.5,4.3-3.2c0.4-1.7-0.5-3.5-2-4.8c-1.5-1.4-3.7-2.3-6.2-2.3
							c-2.6-0.1-4.6-0.1-6.5-0.2c-1.9-0.2-3.8-0.5-6.4-1.5c2,1.9,3.4,3.4,4.7,4.9C220.3,367.2,221.6,368.7,223.9,370.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M234,386.9c2.5,1.9,5.5,2.6,7.9,2.4c2.4-0.2,4.2-1.4,4.6-3.1c0.4-1.7-0.6-3.5-2.4-4.9c-1.8-1.4-4.2-2.4-7.1-2.5
							c-3-0.1-5.1-0.2-7.2-0.3c-2.1-0.2-4.2-0.5-7.2-1.6c2.4,2,4,3.5,5.6,5C229.9,383.4,231.4,385,234,386.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M241.6,400.7c2,2.1,4.3,3.2,6.5,3.5c2.2,0.3,4.2-0.2,5.2-1.7c1-1.5,0.6-3.6-0.8-5.5c-1.4-1.9-3.8-3.6-6.8-4.2
							c-3.1-0.6-5.3-0.7-7.4-0.9c-2.1-0.2-4.3-0.4-7.2-1c2.6,1.6,4.2,3,5.6,4.5C238.2,397,239.6,398.6,241.6,400.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M249.5,414.4c1.3,2.2,3.2,3.7,5.1,4.3c1.9,0.7,3.9,0.6,5.2-0.6c1.3-1.2,1.6-3.2,0.7-5.3c-0.8-2.1-2.8-4.2-5.5-5.3
							c-2.8-1.1-4.9-1.5-6.9-1.9c-2-0.4-3.9-0.9-6.6-1.7c2.2,1.8,3.5,3.3,4.6,4.9C247.1,410.4,248.1,412.1,249.5,414.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M244.4,430c-0.4,2.4,0.1,4.4,1.1,6c1,1.6,2.6,2.7,4.3,2.7c1.8,0,3.3-1.3,4-3.4c0.7-2.1,0.6-4.9-0.9-7.3
							c-1.5-2.5-2.9-4-4.2-5.4c-1.3-1.4-2.6-2.9-4.3-4.9c0.7,2.6,0.8,4.4,0.7,6.2C245.1,425.8,244.8,427.6,244.4,430z"
                      />
                    </g>
                    <g>
                      <path
                        d="M230.5,431.1c-1,2.2-1.2,4.3-0.7,6.2c0.4,1.9,1.4,3.5,3.1,4.2c1.6,0.6,3.6-0.1,5-2.1c1.4-1.9,2.2-4.9,1.4-7.9
							c-0.8-3-1.8-4.9-2.8-6.8c-1-1.9-2-3.7-3.3-6.1c0.3,2.8,0,4.7-0.4,6.6C232.2,427,231.5,428.8,230.5,431.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M217.3,428c-1.6,2.5-2.2,5.3-1.9,7.5c0.2,2.3,1.2,4.1,2.9,4.7c1.7,0.5,3.6-0.4,5.1-2.2c1.5-1.9,2.5-4.7,2.4-7.7
							c-0.3-6.3-1.2-8.8-1.1-15C222.4,421,220.5,422.8,217.3,428z"
                      />
                    </g>
                    <g>
                      <path
                        d="M168.4,289.1c-2.1,1.5-3.5,3.2-4.2,5.1c-0.7,1.9-0.9,3.9,0.2,5.3c1,1.4,3.2,1.8,5.5,0.9c2.3-0.9,4.7-3.2,5.8-6.3
							c1.1-3.2,1.3-5.4,1.5-7.6c0.2-2.2,0.3-4.3,0.4-7.3c-1.2,2.7-2.5,4.3-3.9,5.7C172.1,286.4,170.5,287.6,168.4,289.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M169.7,305.9c-2.3,1.3-4,2.7-5.5,4.3c-1.4,1.6-2.5,3.4-2.1,5.1c0.2,0.9,0.8,1.6,1.7,2c1,0.5,2.3,0.7,3.8,0.5
							c1.5-0.2,3.2-0.8,4.8-1.9c1.5-1.1,3-2.6,3.9-4.4c1-1.9,1.5-3.5,1.8-5c0.3-1.5,0.4-2.8,0.5-4.1c0-2.6-0.1-5.2-0.9-8.6
							c-0.3,3.5-1.4,5.6-2.7,7.4C173.7,303.1,172.1,304.6,169.7,305.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M171.5,321.1c-2.3,1.7-3.9,3.7-4.8,5.8c-0.9,2-1.2,4.1-0.1,5.5c1,1.4,3.3,1.7,5.8,0.6c2.5-1.1,5.1-3.6,6.3-6.9
							c1.2-3.4,1.6-5.9,1.8-8.3c0.3-2.4,0.5-4.7,0.7-8.1c-1.3,3.1-2.6,4.9-4.1,6.5C175.6,317.9,173.9,319.3,171.5,321.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M170.5,340.1c-2.1,2.7-2.9,6-2.6,8.5c0.2,2.6,1.4,4.5,3.2,4.8c1.7,0.3,3.5-0.8,4.9-2.6c1.4-1.9,2.4-4.4,2.7-7.4
							c0.3-3.1,0.4-5.3,0.8-7.5c0.4-2.2,0.9-4.4,2.3-7.4c-2.3,2.4-3.9,4-5.6,5.6C174.4,335.7,172.6,337.3,170.5,340.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M175.9,358.4c-1.4,3.7-1.5,7.7-0.8,10.6c0.7,3,2.3,4.9,4,4.9c1.8,0,3.3-1.8,4.3-4.5c1-2.7,1.5-6.2,1.1-9.9
							c-0.4-3.8-0.7-6.5-0.9-9.2c-0.2-2.7-0.2-5.5,0.4-9.4c-1.6,3.7-2.9,6.2-4.2,8.7C178.7,352.1,177.4,354.6,175.9,358.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M183.3,381.6c-1.9,6.6,0.9,13.2,4.4,13.1c3.5-0.1,6-6.5,4.4-12.9c-1.7-6.6-3.1-9.1-3.7-16.1
							C186.9,372.5,185.3,374.9,183.3,381.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M190.9,400.8c-0.9,3.5-0.6,7.1,0.4,9.7c1,2.6,2.7,4.3,4.5,4.1c1.8-0.1,3.2-2,3.9-4.5c0.8-2.6,0.9-5.9,0-9.3
							c-0.9-3.5-1.6-5.9-2.2-8.4c-0.6-2.5-1-5-1.2-8.7c-0.9,3.6-1.8,6-2.6,8.5C192.8,394.7,191.9,397.2,190.9,400.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M200.2,412.2c-1.4,1.7-1.9,3.7-1.8,5.5c0.2,1.8,1.1,3.4,2.7,4.2c1.6,0.8,3.5,0.4,5-1c1.5-1.3,2.6-3.7,2.5-6.3
							c-0.2-2.7-0.6-4.5-1.1-6.3c-0.4-1.8-0.8-3.5-1.1-6c-0.7,2.4-1.6,3.9-2.6,5.3C202.8,409.1,201.7,410.4,200.2,412.2z"
                      />
                    </g>
                    <g>
                      <path d="M207,422.2c-4,4-4.1,10-1,11.6c3.1,1.6,8-2,8.8-7.6c0.9-5.8,0.5-8.2,2.1-13.8C213.4,417,211.1,418.1,207,422.2z" />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M378.5,8.1c9.5-5,18.3-11.4,25.5-18.6c7.3-7.2,12.8-15.2,16.3-22.6c3.6-7.4,5.3-14.1,5.8-18.9c0.5-4.8,0-7.5-0.8-7.6
							c-1.9-0.3-4.3,9.8-12.3,22.5c-4,6.3-9.3,13.2-16,19.6c-6.6,6.5-14.6,12.7-23,18.1c-8.7,5.6-16.7,9.6-24,13.2
							c-7.3,3.6-14,6.8-20.6,10.4c-6.6,3.6-13.1,7.4-19.8,12.5c-3.4,2.5-6.8,5.4-10.1,8.8c-3.4,3.4-6.6,7.4-9.6,12
							c6.4-9.1,13.8-14.9,20.7-19.4c7-4.5,13.7-7.6,20.5-10.5c6.8-2.8,13.7-5.3,21.4-8.2C360.1,16.5,368.7,13.3,378.5,8.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M323.1,19.3c2-2.4,2.7-5.4,2.3-7.8c-0.3-2.4-1.6-4.1-3.3-4.4c-1.7-0.3-3.5,0.7-4.8,2.3c-1.3,1.6-2.2,3.9-2.3,6.5
							c-0.1,2.7-0.2,4.7-0.5,6.7c-0.3,2-0.8,4-2.1,6.6c2.2-2,3.7-3.4,5.4-4.8C319.4,23.2,321.1,21.8,323.1,19.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M336.9,7.8c1.6-2.1,2-4.7,1.5-6.6c-0.5-2-2-3.4-3.7-3.5c-1.8-0.2-3.4,0.7-4.5,2.1c-1.1,1.4-1.8,3.2-1.6,5.4
							c0.2,2.3,0.4,4,0.4,5.7c0,1.7-0.1,3.4-1,5.8c1.7-1.8,3-3,4.4-4.2C333.8,11.1,335.2,9.9,336.9,7.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M351.2-2.2c1.5-2.3,1.7-5,1.1-7c-0.6-2.1-2-3.5-3.7-3.7c-1.8-0.2-3.4,0.8-4.6,2.4c-1.1,1.6-1.8,3.8-1.4,6.3
							c0.4,2.6,0.7,4.4,0.9,6.3c0.2,1.9,0.3,3.7-0.1,6.4c1.4-2.3,2.6-3.8,3.8-5.3C348.4,1.6,349.7,0.1,351.2-2.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M369.1-11.1c1.4-2.9,1.2-6.2,0.2-8.4c-1-2.3-2.9-3.4-4.6-3.1c-1.7,0.3-3,1.8-3.8,3.7c-0.8,1.9-1,4.1-0.5,6.5
							c0.5,2.6,0.8,4.5,0.9,6.5c0,2-0.1,4-1.2,6.8c2-2.2,3.4-3.8,4.8-5.5C366.2-6.4,367.6-8.1,369.1-11.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M382.6-22.2c1-3.1,0.7-6.3-0.3-8.6c-1-2.3-2.8-3.6-4.5-3.4c-1.8,0.2-3.1,1.8-3.8,4c-0.7,2.1-0.9,4.8-0.1,7.6
							c0.8,2.9,1.4,5,1.8,7.1c0.4,2.1,0.7,4.3,0.4,7.5c1.3-2.9,2.3-4.9,3.3-6.9C380.4-16.9,381.5-19,382.6-22.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M392.3-30.2c1.1-2.7,1-5.5,0-7.5c-0.9-2-2.6-3.3-4.3-3.1c-1.8,0.1-3.2,1.5-4,3.3c-0.8,1.8-1.1,4.2-0.4,6.6
							c0.7,2.5,1.3,4.4,1.7,6.3c0.4,1.9,0.7,3.8,0.5,6.6c1.2-2.5,2.2-4.2,3.2-5.9C390-25.7,391.1-27.5,392.3-30.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M404.8-44.7c1.1-3,0.9-6-0.1-8.3c-1-2.2-2.6-3.6-4.4-3.5c-1.8,0.1-3.2,1.6-4,3.7c-0.8,2.1-1,4.7-0.3,7.5
							c0.8,2.9,1.4,4.9,1.8,7c0.4,2.1,0.8,4.2,0.6,7.3c1.2-2.9,2.1-4.8,3.1-6.8C402.6-39.7,403.7-41.7,404.8-44.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M415.7-56.1c1.4-2.5,1.8-5.1,1.4-7.3c-0.3-2.2-1.4-3.9-3.1-4.4c-1.7-0.5-3.6,0.4-5,2.3c-1.4,1.9-2.3,4.7-1.9,7.8
							c0.4,3.1,0.9,5.2,1.3,7.3c0.5,2.1,0.9,4.2,1.2,7.2c0.8-2.9,1.6-4.9,2.6-6.7C413.2-51.8,414.3-53.5,415.7-56.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M426.4-67.6c1.7-1.9,2.5-4.1,2.6-6.1c0.1-2-0.6-3.9-2.1-4.8c-1.5-0.9-3.5-0.6-5.3,0.9c-1.8,1.4-3.2,3.9-3.4,6.8
							c-0.2,3,0,5.1,0.2,7.1c0.2,2,0.3,4,0.3,6.9c1-2.6,2.1-4.3,3.3-5.8C423.3-64.2,424.7-65.6,426.4-67.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M442.9-67.4c2.4-0.3,4.2-1.4,5.4-2.9c1.2-1.4,1.9-3.2,1.3-4.9c-0.5-1.7-2.2-2.8-4.4-2.8c-2.2-0.1-4.8,0.9-6.7,3.1
							c-1.9,2.2-2.9,3.9-3.9,5.6c-1,1.7-2,3.3-3.4,5.6c2.2-1.5,4-2.1,5.7-2.6C438.6-66.8,440.4-67.1,442.9-67.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M448-54.4c2.4,0.3,4.5-0.2,6.2-1.1c1.7-1,3-2.4,3.1-4.2c0.1-1.7-1.2-3.4-3.4-4.2c-2.2-0.8-5.3-0.6-7.9,1
							c-2.7,1.7-4.2,3.2-5.7,4.6c-1.5,1.6-2.9,3-4.8,5c2.6-1.1,4.5-1.4,6.4-1.5C443.6-54.9,445.5-54.7,448-54.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M449-40.9c2.9,0.8,5.7,0.5,7.8-0.4c2.1-0.9,3.6-2.4,3.6-4.2c0-1.8-1.4-3.3-3.6-4.2c-2.2-0.9-5.2-1-8.1,0
							c-6,2.1-8,3.8-14,5.5C440.8-43.7,443.1-42.4,449-40.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M330.8,46.9c2,1.6,4.1,2.4,6.1,2.5c2,0.2,3.9-0.3,5-1.7c1-1.4,0.8-3.5-0.8-5.5c-1.5-1.9-4.4-3.6-7.7-3.6
							c-3.3-0.1-5.5,0.4-7.7,0.8c-2.1,0.5-4.2,1-7.1,1.7c3,0.3,4.9,1.1,6.6,2C327.1,44.2,328.7,45.3,330.8,46.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M346.5,40.6c1.9,1.8,3.8,3.1,5.8,3.9c1.9,0.8,3.9,1.4,5.5,0.5c0.8-0.4,1.3-1.2,1.4-2.3c0.2-1.1,0-2.4-0.7-3.8
							c-0.6-1.4-1.7-2.8-3.2-4c-1.5-1.2-3.3-2.1-5.3-2.4c-2.1-0.4-3.8-0.4-5.3-0.3c-1.5,0.1-2.8,0.4-4.1,0.8
							c-2.5,0.7-4.9,1.6-7.9,3.4c3.4-0.8,5.8-0.3,7.9,0.4C342.6,37.7,344.5,38.8,346.5,40.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M360.5,34.4c2.3,1.7,4.7,2.6,6.9,2.9c2.2,0.3,4.3-0.1,5.3-1.5c1-1.4,0.7-3.6-1.1-5.7c-1.8-2.1-4.9-3.8-8.5-4
							c-3.6-0.2-6.1,0.3-8.5,0.7c-2.4,0.5-4.7,1-7.9,1.7c3.3,0.3,5.5,1.1,7.4,2C356.2,31.5,358.1,32.7,360.5,34.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M378.8,29.8c3.2,1.2,6.5,1,8.9,0c2.4-1,3.8-2.7,3.7-4.4c-0.2-1.8-1.8-3.1-4-3.9c-2.2-0.8-5-1-7.9-0.4
							c-3,0.7-5.2,1.2-7.4,1.5c-2.2,0.3-4.5,0.4-7.8,0c3,1.5,5,2.6,7,3.7C373.5,27.4,375.6,28.6,378.8,29.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M394.8,19.2c4,0.3,7.8-0.8,10.4-2.4c2.6-1.6,4-3.6,3.5-5.3c-0.5-1.7-2.7-2.6-5.6-2.8c-2.9-0.2-6.4,0.4-9.8,1.9
							c-3.5,1.5-6,2.6-8.5,3.6c-2.5,1-5.2,1.8-9.2,2.4c4,0.5,6.7,0.9,9.5,1.4C387.9,18.4,390.7,19,394.8,19.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M414.8,5.3c6.9-0.1,12.4-4.8,11.2-8.1c-1.2-3.3-8-3.8-13.6-0.3c-5.8,3.6-7.8,5.6-14.3,8.3
							C405,4.5,407.7,5.3,414.8,5.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M430.8-7.7c3.7-0.2,7-1.5,9.2-3.3c2.2-1.7,3.3-3.8,2.6-5.5c-0.7-1.6-2.8-2.4-5.5-2.4c-2.7,0-5.9,0.9-8.8,2.7
							c-3,1.9-5.1,3.3-7.3,4.6c-2.2,1.3-4.5,2.5-8,3.7c3.7-0.2,6.3-0.1,8.9,0C424.5-7.7,427.1-7.5,430.8-7.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M439-19.9c2.1,0.9,4.1,0.8,5.8,0c1.6-0.7,3-2.1,3.3-3.8c0.3-1.7-0.7-3.5-2.4-4.5c-1.7-1.1-4.3-1.4-6.7-0.5
							c-2.5,1-4.1,2-5.7,2.9c-1.6,0.9-3.1,1.8-5.4,2.9c2.5,0,4.2,0.4,5.8,0.9C435.2-21.5,436.8-20.8,439-19.9z"
                      />
                    </g>
                    <g>
                      <path d="M446.5-29.3c5,2.7,10.8,1,11.4-2.5c0.6-3.5-4.3-7-9.9-6.2c-5.8,0.9-8,2-13.8,2C439.6-33.9,441.3-32.1,446.5-29.3z" />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M533.7,299.2c0.6-0.6,1.1-1.2,1.6-1.8c0.5-0.7,1-1.3,1.5-2c0.9-1.4,1.8-2.8,2.4-4.3c1.4-3,2.2-6.2,2.6-9.4
						c0.8-6.4-0.3-12.8-2.8-18.6c-1.2-2.9-3-5.7-5-8.1c-2-2.4-4.2-4.5-6.6-6.4c-4.8-3.6-10.2-6.1-15.6-7.6c-2.7-0.7-5.5-1.3-8.2-1.5
						c-2.7-0.3-5.5-0.3-8.1-0.2c-5.4,0.3-10.8,1.5-15.7,3.8c-5,2.3-9.4,5.8-12.6,10c-1.6,2.1-2.9,4.5-3.9,6.9
						c-0.9,2.4-1.5,5.1-1.6,7.7c-0.3,5.1,1,10,3.5,14.1c1.3,2,2.9,3.8,4.7,5.2c1.8,1.4,3.8,2.4,5.8,3.1c4,1.3,8,1.3,11.5,0.3
						c3.5-1,6.4-3.3,8-5.7c1.7-2.4,2.4-4.9,2.6-7c0.2-2.1-0.1-4-0.6-5.3c-0.5-1.4-1.2-2.3-1.7-2.8c-0.6-0.5-1-0.6-1.3-0.5
						c-0.6,0.3-0.4,1.7-0.7,3.8c-0.1,1-0.4,2.2-1.1,3.5c-0.6,1.2-1.5,2.6-2.7,3.6c-0.6,0.5-1.3,0.9-2,1.3c-0.7,0.3-1.6,0.5-2.5,0.6
						c-1.8,0.2-3.8-0.2-5.7-1.2c-0.9-0.5-1.8-1.1-2.5-1.9c-0.7-0.7-1.3-1.6-1.8-2.7c-1-2.1-1.4-4.7-1-7.3c0.2-1.3,0.6-2.5,1.2-3.8
						c0.6-1.2,1.4-2.4,2.4-3.6c1-1.1,2.1-2.2,3.4-3.1c1.3-0.9,2.7-1.7,4.3-2.3c6.2-2.5,14.7-2.8,22.4-0.6c3.8,1.1,7.5,2.8,10.6,5.1
						c1.5,1.2,3,2.5,4.2,3.9c0.6,0.7,1.1,1.5,1.7,2.2l0.7,1.2l0.6,1.3c1.6,3.4,2.4,7.4,2.2,11.1c-0.1,1.9-0.5,3.7-1.2,5.4
						c-0.3,0.9-0.7,1.6-1.2,2.4l-0.7,1.1c-0.3,0.4-0.5,0.7-0.9,1.1c-2.5,3-6.1,5.2-10.1,6.9c-2,0.9-4.1,1.6-6.1,2.2
						c-1.1,0.3-2.1,0.6-3.2,0.9c-1.1,0.3-2.1,0.6-3.1,0.8c-8.4,1.9-16.5,2.6-24.2,2.5c-7.7-0.1-15.1-1.1-22.2-2.7
						c-7.1-1.6-13.8-3.8-20.3-6.6c-13-5.7-25.3-13.9-36.8-25.5c-5.7-5.8-11.3-12.5-16.4-20.3c-5.2-7.7-9.9-16.7-13.9-26.8
						c3.7,10.3,8,19.4,12.8,27.5c4.8,8.1,10,15.2,15.6,21.4c11.1,12.5,23,21.8,36.3,28.8c6.6,3.5,13.5,6.4,20.9,8.7
						c7.4,2.3,15.2,3.9,23.5,4.7c8.4,0.8,17.3,0.7,26.8-0.7c1.2-0.1,2.4-0.4,3.6-0.6c1.2-0.2,2.4-0.5,3.6-0.7c2.5-0.6,5-1.2,7.6-2.1
						c2.6-0.9,5.2-2,7.8-3.5C528.7,303.5,531.3,301.6,533.7,299.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M454.1,257.7c3.8-5.9,5.1-13.2,3.9-20.1c-0.6-3.4-1.9-6.8-3.7-9.7c-1.8-2.9-4.1-5.5-6.7-7.6c-2.6-2.1-5.5-3.7-8.6-4.7
						c-3.1-1-6.3-1.5-9.5-1.3c-3.2,0.2-6.3,0.9-9.1,2.4c-0.7,0.3-1.4,0.8-2,1.2c-0.7,0.5-1.3,0.9-1.9,1.4c-1.2,1-2.2,2.1-3.1,3.3
						c-1.7,2.4-2.8,5-3.3,7.6c-0.5,2.6-0.3,5.3,0.4,7.7c0.8,2.5,2.1,4.4,3.6,5.7c1.5,1.4,3,2.3,4.5,2.9c1.5,0.6,2.9,0.8,4.1,0.8
						c0.6,0,1.2-0.1,1.7-0.2c0.5-0.1,0.9-0.3,1.3-0.4c0.8-0.4,1.3-0.8,1.5-1.2c0.3-0.4,0.4-0.7,0.3-1.1c-0.2-0.7-1-1-2-1.6
						c-1-0.6-2.2-1.3-3.3-2.6c-1.1-1.2-1.9-3.1-1.8-4.5c0.1-0.9,0.2-1.7,0.7-2.6c0.4-0.9,1.1-1.8,1.9-2.6c0.4-0.4,0.9-0.7,1.3-1
						c0.2-0.1,0.5-0.2,0.7-0.4c0.3-0.1,0.5-0.2,0.8-0.3c1.1-0.4,2.4-0.5,3.9-0.5c2.9,0.1,5.9,1.3,8.6,3.4c1.3,1.1,2.5,2.4,3.4,3.8
						c0.9,1.5,1.6,3,2,4.7c0.8,3.4,0.5,7.4-1.1,10.7c-1.6,3.5-4.6,6.3-7.9,8.3c-3.4,1.9-7,3-10.6,3.3c-3.7,0.4-7.3,0.1-10.9-0.6
						c-3.5-0.7-7-1.8-10.4-3.2c-3.4-1.4-6.6-3.1-9.9-5.2c-3.2-2.1-6.3-4.6-9.4-7.5c-3-2.9-6-6.3-8.7-10.3c-2.7-4-5.2-8.6-7.1-14
						c3.2,11,8.3,19.4,13.9,26.1c2.8,3.4,5.7,6.3,8.8,9c3.1,2.6,6.3,4.9,9.7,7c3.4,2.1,7,3.8,11,5.2c3.9,1.4,8.2,2.4,13,2.7
						c4.7,0.3,10-0.4,15.2-2.5c2.6-1,5.2-2.5,7.8-4.4C449.8,263.2,452.2,260.8,454.1,257.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M336.8,288.9c-1.6,6.2-2,12.8-1.5,18.8c0.5,6,2,11.6,3.9,16c1.9,4.5,4.2,7.8,6.2,9.9c2.1,2.1,3.9,2.9,5,2.3
						c1.2-0.6,1.7-2.5,1.6-5.1c0.1-2.7-0.4-6.2-0.8-10.4c-0.4-4.1-0.9-8.8-1.2-13.8c-0.3-5-0.4-10.4-0.3-15.8
						c0.1-5.6,0.8-10.4,1.7-14.7c0.9-4.3,2.2-8.3,3.4-12.5c1.2-4.1,2.4-8.3,3.2-13.2c0.4-2.4,0.6-5,0.6-7.8c0-2.8-0.4-5.7-1.3-8.7
						c0.5,3.1,0.5,6,0.1,8.6c-0.4,2.6-1.1,5-1.9,7.2c-1.6,4.4-3.7,8-5.9,11.6c-2.2,3.6-4.5,7.2-6.8,11.5
						C340.7,277.4,338.5,282.5,336.8,288.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M350.5,287.2c-2,4.9-4.1,10-5.6,15.3c-1.5,5.3-2.2,10.7-1.9,15.5c0.3,4.8,1.5,8.7,3,11.2c1.5,2.5,3.2,3.5,4.5,3.1
						c1.3-0.4,2-2,2.7-4.3c0.7-2.3,1.4-5.3,2.3-8.9c0.9-3.6,2.2-7.7,3.7-12.4c1.4-4.7,3-10.1,4-15.9c0.9-6,1.2-11.1,1.2-15.7
						c0-4.6-0.4-8.7-0.9-12.8c-0.5-4.1-1.2-8.1-2.1-12.5c-1-4.4-2.2-9.3-4.1-14.9c1.1,5.8,1.4,10.8,1.4,15.2c0,4.4-0.4,8.4-0.9,12.2
						c-0.6,3.8-1.4,7.6-2.5,11.6C354.1,277.9,352.6,282.2,350.5,287.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M393.3,330.8c-0.5,0.7-1,1.3-1.5,1.9c-0.5,0.6-1.1,1.1-1.6,1.6c-1.2,0.9-2.4,1.7-3.7,2.3c-1.3,0.6-2.5,0.8-3.7,0.9
						c-1.2,0.1-2.1,0-3.2-0.4c-2.1-0.6-3.9-2.2-4.8-3.6c-0.4-0.7-0.7-1.4-0.8-2.3c-0.1-0.9,0-1.8,0.2-2.8c0.5-1.9,1.6-3.7,2.7-4.9
						c1.2-1.2,2-1.6,2.4-2c0.3-0.1,0.8-0.1,1.2,0c0.4,0.1,0.8,0.3,1.1,0.5c0.7,0.4,1.1,1.1,1.7,1.4c0.3,0.1,0.7,0.1,1.1-0.1
						c0.4-0.3,0.8-0.8,1.1-1.6c0.2-0.8,0.2-2-0.2-3.1c-0.4-1.2-1.2-2.4-2.6-3.5c-1.6-1.1-3.4-1.5-5.4-1.5c-1.9,0-3.8,0.5-5.6,1.3
						c-3.5,1.6-6.7,4.5-9,8.7c-1.1,2.1-1.9,4.6-2.1,7.3c-0.2,2.7,0.2,5.8,1.4,8.5c1.1,2.8,2.9,5.2,5.1,7.2c2.2,2,4.8,3.6,7.7,4.6
						c2.9,1,6.3,1.4,9.3,1.1c3.1-0.3,6.1-1.2,8.7-2.6c2.7-1.4,5-3.2,7.1-5.3c1-1.1,1.9-2.2,2.8-3.4c0.8-1.2,1.5-2.4,2.1-3.7
						c2.5-5.3,3.2-10.4,3.2-14.9c0-4.6-0.8-8.7-1.8-12.3c-1-3.7-2.3-6.9-3.6-10c-1.3-3.1-2.7-6-4.2-8.9c-1.5-2.9-3-5.7-4.8-8.6
						c-1.7-2.9-3.5-5.8-5.6-8.7c-4.1-6-8.9-12.3-15.4-19.3c5.9,7.4,9.9,14.3,13.1,20.6c1.6,3.2,3,6.2,4.2,9.2c1.2,3,2.2,6,3.2,8.9
						c0.9,3,1.7,6,2.4,9c0.7,3.1,1.3,6.2,1.6,9.4c0.3,3.2,0.3,6.5-0.3,9.8C396.4,324.7,395.3,328,393.3,330.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M211.1,46.3c3.4-0.8,6.9-1,10.2-0.6c3.3,0.4,6.4,1.5,8.9,3.1c2.6,1.6,4.7,3.9,6.2,6.4c0.7,1.2,1.3,2.5,1.7,3.8
						c0.4,1.3,0.5,2.6,0.6,3.9c0.1,5.3-3,11-7.3,15.2c-1.1,1.1-2.2,2.1-3.4,2.9c-1.2,0.9-2.4,1.7-3.5,2.4c-2.4,1.4-4.7,2.1-6.6,2.3
						c-1,0.1-1.9,0-2.6-0.2c-0.8-0.2-1.6-0.5-2.3-1c-1.5-1-2.7-2.4-3.5-3.9c-0.4-0.8-0.6-1.5-0.8-2.3c-0.1-0.4-0.1-0.8-0.2-1.1
						c0-0.4,0-0.7,0-1.1c0.1-1.5,0.6-3,1.3-4.2c1.4-2.5,3.4-4.2,4.9-5.2c1.5-1,2.5-1.6,2.5-2.3c0-0.6-1.2-1.4-3.6-1.3
						c-1.2,0-2.7,0.3-4.3,0.9c-1.6,0.7-3.4,1.7-5.1,3.3c-1.7,1.6-3.3,3.8-4.3,6.8c-0.3,0.7-0.4,1.5-0.6,2.3
						c-0.1,0.8-0.2,1.6-0.2,2.5c-0.1,1.7,0.2,3.4,0.6,5.2c0.9,3.5,2.9,6.8,5.9,9.6c1.5,1.4,3.4,2.6,5.5,3.4c2.2,0.9,4.4,1.3,6.6,1.4
						c4.5,0.2,8.9-1,12.7-2.8c1.9-0.9,3.7-1.9,5.5-3.1c1.8-1.2,3.5-2.5,5.1-4c1.6-1.4,3.2-3.1,4.6-4.9c1.4-1.8,2.7-3.7,3.9-5.8
						c1.1-2.1,2.1-4.4,2.8-6.9c0.7-2.5,1.1-5.1,1.1-7.8c0-2.7-0.3-5.5-1.1-8.1c-0.8-2.6-1.9-5.1-3.3-7.4c-2.8-4.6-6.7-8.4-11.4-11.2
						c-4.7-2.8-9.9-4.2-15.1-4.6c-5.2-0.4-10.3,0.3-15.2,1.9c-5,1.6-9.3,4-13,6.7c-3.7,2.7-6.9,5.8-9.6,9
						c-5.3,6.4-9.1,13.2-11.6,19.9c-2.5,6.7-3.9,13.4-4.6,19.9c-0.7,6.5-0.7,12.8-0.1,19.1c0.6,6.3,1.8,12.4,3.6,18.6
						c1.8,6.2,4.1,12.4,6.9,18.7c5.7,12.7,13.8,25.8,24.4,40.6c-10-15.2-17.2-28.8-22-41.6c-2.4-6.4-4.2-12.6-5.4-18.7
						c-1.2-6.1-1.8-12-1.9-18c0-5.9,0.5-11.8,1.7-17.7c1.2-5.8,3-11.7,5.8-17.4c2.8-5.6,6.5-11.1,11.4-16c2.4-2.4,5.2-4.6,8.2-6.5
						C204.3,48.6,207.6,47.1,211.1,46.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M217.7,111.6c1.7-0.5,3.2-0.7,4.8-0.7c1.5,0,2.9,0.2,4,0.6c0.5,0.2,1,0.4,1.3,0.7c0.4,0.2,0.7,0.5,0.9,0.8
						c0.5,0.6,1,1.5,1.3,2.5c0.2,0.5,0.3,1.1,0.3,1.7c0.1,0.6,0.1,1.1,0,1.7c-0.1,1.1-0.4,2.2-0.7,3c-0.2,0.4-0.4,0.8-0.6,1.1
						c-0.2,0.3-0.4,0.6-0.7,0.8c-0.3,0.2-0.4,0.4-0.7,0.6c-0.3,0.2-0.6,0.3-1,0.4c-0.7,0.2-1.5,0.2-2.3,0.1c-0.7-0.1-1.4-0.3-2-0.7
						c-1.2-0.6-2.2-2-2.8-3.3c-0.6-1.3-0.9-2.6-1.1-3.6c-0.2-1-0.5-1.8-1-2.2c-0.5-0.3-1.4-0.2-2.5,0.7c-1,0.9-2.2,2.7-2.6,5.4
						c-0.2,1.3-0.2,2.9,0.1,4.5c0.3,1.7,1,3.5,2.3,5.2c2.5,3.5,7,6.2,12.4,6.3c1.3,0,2.7-0.1,4.2-0.4c1.4-0.3,2.9-0.9,4.2-1.7
						c1.3-0.8,2.5-1.7,3.6-2.8c1.1-1.1,2-2.3,2.8-3.5c1.5-2.5,2.5-5.3,3-8.3c0.2-1.5,0.3-3,0.2-4.5c-0.1-1.5-0.3-3-0.7-4.5
						c-0.8-3-2.3-6.1-4.8-8.7c-2.5-2.6-5.8-4.5-9-5.3c-3.2-0.9-6.4-1-9.4-0.6c-1.5,0.2-2.9,0.5-4.3,0.9c-1.4,0.4-2.7,1-4,1.5
						c-5.3,2.4-9.2,5.9-12.2,9.4c-1.5,1.8-2.8,3.6-3.9,5.4c-1.1,1.9-2,3.7-2.8,5.5c-3,7.5-3.6,14.5-3.3,21.3
						c0.3,6.8,1.7,13.4,4,20.4c2.2,7,5.4,14.5,9.3,23.3c-3.1-9.1-5.5-16.9-6.7-24c-1.3-7.1-1.6-13.5-0.8-19.6
						c0.7-6.1,2.5-12,5.9-17.3c0.8-1.3,1.8-2.6,2.9-3.9c1.1-1.2,2.3-2.4,3.5-3.5C211.2,114.4,214.2,112.5,217.7,111.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M252.1,32.8c3.2,0.6,6.4-0.3,8.5-1.8c2.1-1.5,3-3.4,2.5-5.1c-0.6-1.7-2.4-2.7-4.6-3c-2.2-0.3-4.8,0-7.4,1.2
						c-2.7,1.2-4.6,2.1-6.6,2.8c-2,0.7-4.2,1.2-7.3,1.3c3,1,5.1,1.7,7.3,2.5C246.6,31.4,248.8,32.2,252.1,32.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M267.3,45.6c1.8,2,3.7,3.3,5.6,4.1c1.9,0.8,4,1.1,5.5,0.2c1.5-0.9,1.9-3.2,0.7-5.8c-1.2-2.6-4-5.2-7.6-6.1
						c-3.7-0.9-6.2-0.8-8.7-0.6c-2.4,0.2-4.8,0.6-8,1.4c3.3,0.3,5.4,1.2,7.2,2.3C263.8,42.2,265.4,43.6,267.3,45.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M270,60.5c0.6,2.6,1.5,4.6,2.7,6.3c1.2,1.6,2.7,3,4.5,3.1c0.9,0,1.7-0.4,2.4-1.2c0.3-0.4,0.7-0.9,0.9-1.5
						c0.2-0.6,0.4-1.3,0.5-2c0.4-2.9-0.8-6.8-3.6-9.5c-2.9-2.7-5.2-3.8-7.5-4.7c-2.3-0.9-4.7-1.6-7.9-2.2c3,1.6,4.4,3.3,5.6,5.1
						C268.6,55.8,269.4,57.8,270,60.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M263.1,73.6c-0.7,2.7-0.6,5.1,0.1,7.2c0.7,2,1.9,3.7,3.7,4c1.7,0.3,3.5-0.9,4.7-3.2c1.1-2.3,1.5-5.6,0.3-8.6
						c-1.2-3.2-2.4-5.1-3.5-7.1c-1.2-1.9-2.3-3.8-3.9-6.5c0.6,3.1,0.5,5.2,0.2,7.3C264.3,68.8,263.9,70.8,263.1,73.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M251.6,86.6c-1.8,2-2.8,4.1-3.2,6.2c-0.4,2.1-0.1,4.1,1.2,5.3c1.3,1.2,3.5,1,5.7-0.5c2.1-1.5,4-4.4,4.4-7.7
						c0.4-3.4,0.1-5.8-0.2-8c-0.3-2.3-0.7-4.5-1.2-7.5c-0.5,3.1-1.4,5-2.5,6.8C254.7,82.9,253.5,84.5,251.6,86.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M241.2,20.8c6-2.2,9.5-7.9,7.4-10.8c-2.1-2.9-8.6-1.4-12.5,3.7c-2,2.6-3.3,4.5-4.6,6.4c-0.7,0.9-1.3,1.9-2.1,2.7
						c-0.4,0.4-0.9,0.8-1.5,1.1c-0.6,0.3-1.4,0.4-2.1,0.2c0.7,0.5,1.5,0.7,2.3,0.6c0.8,0,1.5-0.2,2.2-0.4c0.7-0.2,1.3-0.4,1.9-0.6
						c0.6-0.2,1.1-0.4,1.7-0.6C235.8,22.5,238.1,21.9,241.2,20.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M227.4,15.6c2.1-2.5,2.5-5.9,1.9-8.2c-0.7-2.4-2.4-3.8-4.1-3.6c-1.8,0.1-3.2,1.4-4.2,2.9c-1,1.5-1.6,3.3-1.6,5.5
						c0,2.4-0.2,4.2-0.7,5.9c-0.5,1.8-1.3,3.6-3.2,5.6c2.6-1.2,4.4-2.1,6.2-3.2C223.5,19.4,225.3,18.1,227.4,15.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M216.5,14.7c1.1-3.1,0.5-6.4-0.9-8.5c-1.4-2.1-3.4-2.9-5-2.3c-1.7,0.6-2.6,2.3-3.1,4.1c-0.5,1.8-0.5,3.9,0.3,6.1
						c0.8,2.4,1.2,4.2,1.4,6.1c0.2,1.9,0.1,3.9-1,6.6c1.9-2.1,3.3-3.8,4.6-5.5C214.1,19.6,215.4,17.8,216.5,14.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M204,15.5c-0.4-2.8-2.4-5-4.4-5.8c-2.1-0.8-4-0.4-5.1,1c-1.1,1.4-1.2,3.2-0.9,4.8c0.3,1.6,1.1,2.9,2.7,4.1
						c1.7,1.2,2.9,2.3,3.9,3.5c1,1.2,1.9,2.7,2.4,5.1c0.6-2.4,0.9-4.1,1.3-5.9C204.2,20.4,204.4,18.4,204,15.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M189.6,18.1c-1.2-0.9-2.6-1.3-4-1.3c-1.4,0-2.6,0.3-3.6,1c-1,0.6-1.6,1.4-1.9,2.3c-0.3,0.9-0.2,1.7,0.1,2.5
						c0.7,1.6,2.1,2.7,3.1,3.4c1.1,0.7,2,0.9,3.4,0.4c1.5-0.5,2.9-0.5,4.3-0.3c1.4,0.3,2.8,1,4.1,2.7c-0.3-2.2-0.8-3.8-1.5-5.5
						C193,21.7,192,19.9,189.6,18.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M174.9,28.6c-3.1,0-5.9,1.8-7.2,3.8c-1.3,2-1.2,4.2,0,5.4c1.2,1.3,3.1,1.6,4.8,1.4c1.8-0.2,3.4-0.8,5.1-2.3
						c1.7-1.5,3.2-2.6,4.8-3.4c1.6-0.8,3.4-1.4,6.1-1.4c-2.5-1.1-4.3-1.8-6.3-2.4C180.2,29.1,178.1,28.5,174.9,28.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M168.5,41.5c-2.5,0.6-4.3,2.4-4.8,4.3c-0.6,1.9-0.1,3.7,1.3,4.8c1.4,1.1,3.2,1.3,4.7,1c1.5-0.3,2.8-1.1,3.7-2.8
						c1-1.7,1.7-3,2.7-4.3c0.9-1.2,2.1-2.3,4.1-3.3c-2.2,0-3.8-0.1-5.6-0.2C172.8,41,171,40.9,168.5,41.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M158.7,62.1c-2.2,1.8-3.3,4.4-3.4,6.6c-0.1,2.2,0.9,4,2.5,4.7c1.6,0.7,3.5,0.1,5.1-1.1c1.6-1.2,2.8-3.1,3.3-5.6
						c0.5-2.6,0.8-4.4,1.3-6.3c0.5-1.8,1.1-3.6,2.6-6c-2.2,1.6-3.9,2.7-5.6,3.7C162.7,59.2,161,60.2,158.7,62.1z"
                    />
                  </g>
                  <g>
                    <path d="M155.7,90.1c-2.8,3.4-1.4,8,2,9c3.4,1,7.1-2.2,6.5-6.5c-0.6-4.5-1.6-6.4-1.4-10.8C160.4,85.5,158.6,86.6,155.7,90.1z" />
                  </g>
                  <g>
                    <path
                      d="M157,113.8c-1.1,1.8-0.9,3.7-0.1,5.2c0.8,1.4,2.4,2.4,4.1,2.4c1.8,0,3.3-0.9,4.2-2.1c0.9-1.3,1.2-2.9,0.5-4.7
						c-1.5-3.7-2.8-5.3-3.2-9.2C160.8,108.9,159.2,110.1,157,113.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M191.5,102.9c2.1-4.3,3.5-8.8,3.9-12.4c0.5-3.6,0.1-6.5-1.5-7.2c-1.6-0.7-4.1,0.9-6.3,4.1c-2.2,3.2-4.1,8-4.6,13.1
						c-0.5,5.3-0.4,8.9-0.3,12.5c0.1,3.6,0.2,7.2,0.2,12.4c1-5.1,2.2-8.5,3.5-11.8C187.9,110.5,189.4,107.4,191.5,102.9z"
                    />
                  </g>
                  <g>
                    <g>
                      <path
                        d="M6.8,333.4c-3-1.7-5.7-4-7.9-6.6c-2.1-2.6-3.6-5.5-4.4-8.4c-0.8-2.9-0.8-6.1-0.2-8.9c0.3-1.4,0.8-2.7,1.4-3.9
							c0.6-1.2,1.4-2.2,2.3-3.2c3.6-3.9,9.7-6,15.7-6.1c1.5-0.1,3,0,4.5,0.1c1.5,0.1,2.9,0.4,4.2,0.7c2.7,0.6,4.8,1.6,6.3,2.8
							c0.8,0.6,1.4,1.3,1.8,1.9c0.5,0.7,0.8,1.5,1,2.3c0.4,1.7,0.4,3.6-0.2,5.2c-0.2,0.8-0.6,1.6-1,2.2c-0.2,0.4-0.4,0.6-0.7,0.9
							c-0.3,0.3-0.5,0.6-0.8,0.8c-1.1,1-2.5,1.7-3.9,2.2c-2.8,0.9-5.4,0.7-7.1,0.5c-1.8-0.3-3-0.5-3.4,0c-0.4,0.5-0.1,1.8,1.7,3.4
							c0.9,0.8,2.1,1.6,3.8,2.3c1.6,0.6,3.7,1.1,6,1.1c2.3,0,5.1-0.5,7.7-2c0.7-0.3,1.3-0.8,2-1.3c0.6-0.5,1.3-1,1.9-1.6
							c1.2-1.2,2.2-2.6,3.1-4.2c1.7-3.1,2.6-6.9,2.2-11c-0.2-2-0.7-4.2-1.7-6.3c-1-2.1-2.3-4-3.9-5.6c-3.2-3.2-7.2-5.3-11.2-6.6
							c-2-0.7-4.1-1.2-6.1-1.5c-2.1-0.4-4.2-0.5-6.4-0.6c-2.2-0.1-4.4,0.1-6.7,0.4c-2.3,0.3-4.6,0.9-6.8,1.6
							c-2.3,0.8-4.6,1.8-6.8,3.1c-2.2,1.4-4.3,3-6.1,4.9c-1.8,2-3.5,4.2-4.7,6.7c-1.2,2.4-2.1,5-2.7,7.6c-1.1,5.3-0.8,10.7,0.7,15.9
							c1.5,5.2,4.4,9.9,7.9,13.7c3.5,3.8,7.8,6.8,12.3,9c4.7,2.2,9.5,3.4,14.1,4c4.6,0.6,9,0.5,13.1,0c8.3-1,15.6-3.4,22.1-6.6
							c6.4-3.2,12-7.1,17-11.4c4.9-4.3,9.2-8.9,13.1-13.9c3.8-5,7.2-10.3,10.1-16c2.9-5.7,5.5-11.8,7.7-18.4
							c4.5-13.2,7.6-28.3,9.9-46.4c-3.1,18-7.1,32.8-12.3,45.4c-2.6,6.3-5.6,12.1-8.8,17.3c-3.3,5.3-6.9,10-10.9,14.4
							c-4,4.3-8.4,8.3-13.3,11.8c-4.9,3.5-10.2,6.5-16.1,8.7c-5.9,2.2-12.4,3.7-19.2,3.9c-3.4,0.1-6.9-0.1-10.4-0.9
							C13.3,336.4,9.9,335.2,6.8,333.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M46.6,281.2c-1.6-0.8-2.8-1.7-4-2.7c-1.1-1-2-2.1-2.5-3.1c-0.3-0.5-0.4-1-0.5-1.4c-0.1-0.4-0.1-0.8-0.1-1.2
							c0-0.8,0.3-1.7,0.8-2.7c0.3-0.5,0.6-1,0.9-1.5c0.4-0.5,0.7-0.9,1.1-1.2c0.8-0.8,1.8-1.4,2.6-1.7c0.4-0.2,0.8-0.3,1.2-0.4
							c0.4-0.1,0.7-0.1,1-0.1c0.3,0,0.6,0,0.9,0.1c0.3,0.1,0.6,0.2,1,0.4c0.7,0.3,1.3,0.9,1.7,1.5c0.5,0.6,0.8,1.2,1,1.8
							c0.4,1.3,0.2,2.9-0.2,4.3c-0.5,1.4-1.1,2.5-1.7,3.4c-0.5,0.9-0.9,1.6-0.7,2.3c0.2,0.6,0.9,1.1,2.3,1.2c1.4,0,3.5-0.5,5.6-2.2
							c1.1-0.8,2.1-1.9,3-3.4c0.9-1.4,1.6-3.3,1.9-5.4c0.6-4.3-0.9-9.3-4.7-13c-1-0.9-2.1-1.8-3.3-2.5c-1.3-0.7-2.7-1.3-4.2-1.6
							c-1.5-0.3-3-0.5-4.5-0.5c-1.5,0.1-3,0.3-4.4,0.7c-2.9,0.8-5.5,2.2-7.8,4c-1.2,0.9-2.3,2-3.2,3.2c-1,1.2-1.8,2.4-2.6,3.8
							c-1.5,2.7-2.5,6-2.5,9.6c0,3.6,1.2,7.2,2.9,10c1.7,2.9,4,5.1,6.4,6.8c1.2,0.9,2.5,1.6,3.8,2.3c1.3,0.7,2.7,1.2,4,1.6
							c5.5,1.8,10.7,2,15.4,1.5c2.3-0.3,4.5-0.7,6.6-1.3c2.1-0.6,4-1.3,5.8-2.2c7.3-3.4,12.6-8.1,17-13.2c4.4-5.2,7.9-10.9,11-17.6
							c3.2-6.7,5.9-14.3,9.1-23.4c-4,8.8-7.5,16.1-11.4,22.1c-3.9,6.1-8.1,10.9-12.8,14.9c-4.7,3.9-10,7-16.1,8.6
							c-1.5,0.4-3.1,0.7-4.7,0.9c-1.6,0.2-3.3,0.2-5,0.1C53.2,283.5,49.7,282.8,46.6,281.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M-32.4,315.2c-2-2.6-4.9-4.2-7.4-4.5c-2.5-0.4-4.6,0.4-5.3,2c-0.7,1.6-0.1,3.6,1.3,5.3c1.4,1.7,3.5,3.3,6.2,4.2
							c2.8,0.9,4.8,1.6,6.8,2.5c2,0.9,3.9,1.9,6.2,4c-1.5-2.8-2.6-4.7-3.6-6.8C-29.3,320-30.4,317.9-32.4,315.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M-34.7,295.5c0-2.7-0.4-4.9-1.3-6.9c-0.9-1.9-2.1-3.5-3.9-3.8c-1.7-0.3-3.6,1.1-4.5,3.8c-0.9,2.7-0.6,6.6,1.4,9.7
							c2.1,3.2,4,4.8,5.9,6.3c1.9,1.5,3.9,2.8,6.8,4.4c-2.2-2.4-3.1-4.5-3.7-6.6C-34.5,300.4-34.8,298.3-34.7,295.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M-26.6,282.8c1.3-2.3,2-4.4,2.3-6.5c0.2-2,0.1-4.1-1.2-5.3c-0.6-0.6-1.5-0.9-2.5-0.8c-0.5,0.1-1.1,0.2-1.6,0.5
							c-0.5,0.3-1.2,0.6-1.7,1.1c-2.3,1.9-4.1,5.5-3.9,9.3c0.3,3.9,1.2,6.3,2.3,8.6c1.1,2.2,2.3,4.4,4.3,7c-1.1-3.2-1-5.5-0.6-7.6
							C-28.7,287.1-27.9,285.2-26.6,282.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M-12.6,277.9c2.3-1.5,3.9-3.4,4.9-5.3c0.9-1.9,1.1-4,0.1-5.4c-1-1.4-3.2-1.8-5.6-0.9c-2.4,0.9-4.9,3.1-6.2,6.1
							c-1.3,3.1-1.8,5.4-2.3,7.6c-0.4,2.2-0.9,4.4-1.5,7.4c1.7-2.6,3.2-4.2,4.8-5.5C-16.7,280.6-15,279.4-12.6,277.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M4.7,276.2c2.7-0.3,4.9-1.1,6.6-2.4c1.7-1.3,2.9-2.9,2.7-4.7c-0.2-1.7-1.9-3.2-4.4-3.5c-2.5-0.4-5.9,0.4-8.5,2.6
							c-2.6,2.3-4,4.2-5.4,6c-1.3,1.9-2.6,3.7-4.3,6.3c2.5-1.9,4.5-2.7,6.5-3.3C-0.1,276.8,1.9,276.5,4.7,276.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M-32.6,331.5c-5.9-2.5-12.3-0.7-12.8,2.8c-0.4,3.5,5.4,6.9,11.7,5.9c3.2-0.5,5.5-1.1,7.8-1.5c1.2-0.2,2.2-0.5,3.4-0.5
							c0.6,0,1.2,0,1.8,0.2c0.6,0.2,1.3,0.6,1.7,1.3c-0.2-0.8-0.7-1.5-1.2-2c-0.6-0.5-1.2-0.9-1.8-1.2c-0.6-0.3-1.2-0.6-1.8-0.8
							c-0.6-0.2-1.1-0.5-1.6-0.7C-27.5,333.9-29.6,332.8-32.6,331.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M-26.1,344.6c-3.2,0.4-5.9,2.5-7,4.8c-1.2,2.2-0.8,4.4,0.5,5.5c1.4,1.1,3.3,1.1,5.1,0.8c1.8-0.4,3.4-1.3,4.9-3
							c1.6-1.7,3-2.9,4.6-3.9c1.6-1,3.4-1.7,6.2-1.9c-2.7-0.9-4.6-1.4-6.7-1.9C-20.6,344.6-22.8,344.2-26.1,344.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M-18.7,352.8c-2.9,1.5-4.7,4.4-5.1,6.8c-0.4,2.5,0.5,4.5,2.1,5.1c1.7,0.7,3.5,0.1,5.1-0.9c1.6-1,3-2.5,4-4.7
							c1-2.2,2-3.9,3.1-5.4c1.2-1.5,2.6-2.9,5.2-4.2c-2.9,0.2-5,0.5-7.1,0.9C-13.6,350.8-15.8,351.3-18.7,352.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M-9,360.7c-1.6,2.3-1.7,5.3-0.7,7.3c0.9,2,2.7,3,4.4,2.7c1.7-0.3,3.1-1.5,4-2.9c0.9-1.4,1.2-2.9,0.8-4.8
							c-0.4-2-0.6-3.6-0.5-5.2c0.1-1.6,0.4-3.3,1.7-5.4c-2,1.3-3.5,2.4-5,3.5C-5.8,357-7.4,358.3-9,360.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M3.3,368.7c0.3,1.5,1,2.7,2,3.7c1,0.9,2.1,1.5,3.2,1.7c1.1,0.2,2.1,0,2.9-0.4c0.8-0.4,1.3-1.1,1.6-1.9
							c0.6-1.7,0.4-3.4,0.1-4.6c-0.3-1.3-0.8-2.1-2.2-2.7c-1.4-0.7-2.5-1.6-3.3-2.7c-0.8-1.2-1.4-2.6-1.1-4.8
							c-1.3,1.8-2,3.4-2.6,5.1C3.3,363.7,2.7,365.7,3.3,368.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M21.2,371c2.3,2.1,5.5,2.7,7.9,2.1c2.4-0.6,3.8-2.2,3.7-3.9c0-1.8-1.2-3.3-2.6-4.4c-1.4-1.1-3.1-1.7-5.3-1.8
							c-2.3-0.1-4.1-0.3-5.8-0.8c-1.7-0.5-3.4-1.3-5.4-3.2c1.1,2.5,1.9,4.3,2.9,6.1C17.7,367,18.8,368.9,21.2,371z"
                      />
                    </g>
                    <g>
                      <path
                        d="M34.6,366c2.2,1.3,4.7,1.2,6.4,0.2c1.7-1,2.6-2.7,2.3-4.4c-0.3-1.7-1.4-3.1-2.7-4c-1.3-0.8-2.8-1.1-4.7-0.5
							c-1.9,0.6-3.3,1-4.8,1.3c-1.5,0.2-3.1,0.3-5.2-0.4c1.6,1.5,2.7,2.7,3.9,3.9C31.1,363.4,32.4,364.7,34.6,366z"
                      />
                    </g>
                    <g>
                      <path
                        d="M55.9,357.6c2.9,0.2,5.5-1,7-2.5c1.6-1.6,2.1-3.5,1.4-5.1c-0.7-1.6-2.5-2.5-4.5-2.7c-2-0.2-4.2,0.4-6.2,1.9
							c-2.1,1.5-3.6,2.7-5.2,3.7c-1.6,1-3.3,1.9-6,2.6c2.7,0.3,4.7,0.7,6.6,1.1C51,357,52.9,357.4,55.9,357.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M77.2,339.2c4.4-0.5,6.5-4.9,4.7-7.9c-1.8-3-6.6-3.2-9.2,0.4c-2.6,3.7-3.2,5.7-6.3,8.9
							C70.6,339.3,72.7,339.8,77.2,339.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M92.4,321c2-0.6,3.2-2.1,3.6-3.7c0.4-1.6-0.1-3.4-1.4-4.6c-1.3-1.2-3-1.6-4.5-1.3c-1.5,0.3-2.9,1.3-3.6,3.1
							c-1.4,3.7-1.5,5.7-3.9,8.9C86.3,321.9,88.3,322.2,92.4,321z"
                      />
                    </g>
                    <g>
                      <path
                        d="M59.8,305.3c-4.5,1.7-8.5,4.1-11.4,6.4c-2.8,2.3-4.5,4.7-3.8,6.3c0.7,1.6,3.6,2.1,7.4,1.3c3.8-0.8,8.5-3,12.3-6.5
							c3.9-3.5,6.3-6.2,8.8-8.9c2.4-2.7,4.8-5.4,8.3-9.3c-4.2,3.1-7.4,4.7-10.6,6.2C67.6,302.3,64.4,303.6,59.8,305.3z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M363.6,398.2c3.2,1.3,6.2,3.2,8.6,5.5c2.4,2.3,4.3,5,5.4,7.7c1.1,2.8,1.6,5.9,1.3,8.8c-0.1,1.4-0.4,2.8-0.9,4.1
							c-0.4,1.3-1.1,2.4-1.8,3.5c-3,4.3-8.8,7.2-14.8,8.1c-1.5,0.3-3,0.4-4.4,0.4c-1.5,0.1-2.9,0-4.3-0.1c-2.7-0.3-5-1-6.7-2
							c-0.8-0.5-1.5-1.1-2-1.7c-0.5-0.6-1-1.4-1.3-2.2c-0.6-1.7-0.8-3.6-0.5-5.2c0.1-0.8,0.4-1.6,0.7-2.3c0.1-0.4,0.4-0.7,0.5-1
							c0.2-0.3,0.4-0.6,0.7-0.9c1-1.2,2.2-2.1,3.6-2.7c2.6-1.2,5.2-1.4,7-1.4c1.8,0,3,0.1,3.4-0.4c0.4-0.5-0.2-1.8-2.1-3.2
							c-1-0.7-2.3-1.3-4-1.8c-1.7-0.4-3.8-0.6-6.1-0.3c-2.3,0.3-4.9,1.2-7.4,3c-0.6,0.4-1.2,1-1.8,1.5c-0.6,0.6-1.2,1.2-1.6,1.9
							c-1,1.3-1.9,2.9-2.5,4.5c-1.3,3.3-1.6,7.2-0.8,11.2c0.4,2,1.2,4.1,2.4,6c1.3,2,2.8,3.6,4.6,5c3.6,2.8,7.8,4.3,11.9,5.1
							c2.1,0.4,4.2,0.6,6.3,0.7c2.1,0.1,4.3,0,6.4-0.3c2.2-0.2,4.4-0.7,6.6-1.3c2.2-0.6,4.4-1.4,6.6-2.5c2.2-1.1,4.3-2.4,6.3-4
							c2-1.6,3.8-3.5,5.5-5.7c1.6-2.2,2.9-4.6,3.8-7.2c0.9-2.6,1.5-5.2,1.7-7.9c0.4-5.4-0.6-10.7-2.7-15.7c-2.2-5-5.6-9.2-9.6-12.6
							c-4-3.3-8.6-5.8-13.4-7.3c-5-1.6-9.9-2.2-14.5-2.1c-4.6,0-9,0.7-13,1.7c-8.1,2.1-15,5.4-21,9.4c-6,4-11,8.6-15.3,13.5
							c-4.3,4.9-8,10-11.1,15.5c-3.1,5.4-5.8,11.2-8,17.2c-2.2,6.1-3.9,12.4-5.3,19.3c-2.7,13.7-3.8,29-3.8,47.2
							c0.7-18.2,2.8-33.4,6.3-46.6c1.8-6.6,3.9-12.7,6.5-18.3c2.6-5.7,5.5-10.9,8.9-15.7c3.4-4.8,7.3-9.3,11.6-13.4
							c4.4-4.1,9.3-7.7,14.8-10.7c5.5-3,11.8-5.2,18.5-6.4c3.4-0.5,6.9-0.8,10.4-0.5C356.7,396.1,360.3,396.8,363.6,398.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M331,455.1c1.7,0.6,3,1.3,4.3,2.2c1.2,0.9,2.2,1.9,2.9,2.8c0.3,0.5,0.5,0.9,0.7,1.3c0.2,0.4,0.2,0.8,0.3,1.2
							c0.1,0.8,0,1.8-0.4,2.8c-0.2,0.5-0.4,1.1-0.7,1.6c-0.3,0.5-0.6,0.9-1,1.4c-0.7,0.9-1.6,1.6-2.4,2.1c-0.4,0.2-0.8,0.4-1.1,0.6
							c-0.4,0.1-0.7,0.2-1,0.3c-0.3,0-0.6,0.1-0.9,0c-0.3,0-0.7-0.1-1-0.2c-0.7-0.3-1.4-0.7-1.9-1.2c-0.5-0.5-1-1.1-1.2-1.7
							c-0.6-1.2-0.6-2.9-0.3-4.3c0.3-1.4,0.8-2.6,1.2-3.6c0.4-1,0.7-1.7,0.4-2.4c-0.2-0.6-1-1-2.5-0.9c-1.4,0.2-3.4,0.9-5.3,2.9
							c-0.9,1-1.9,2.2-2.6,3.7c-0.7,1.6-1.2,3.4-1.2,5.6c0,4.3,2.1,9.1,6.4,12.3c1.1,0.8,2.3,1.5,3.6,2.1c1.3,0.5,2.9,0.9,4.4,1.1
							c1.5,0.1,3,0.1,4.5-0.1c1.5-0.3,2.9-0.7,4.3-1.2c2.7-1.2,5.2-2.9,7.2-5c1-1.1,2-2.3,2.8-3.6c0.8-1.3,1.5-2.6,2-4.1
							c1.1-2.9,1.7-6.3,1.2-9.9c-0.5-3.6-2.1-7-4.2-9.5c-2.1-2.6-4.6-4.5-7.3-5.9c-1.3-0.7-2.7-1.3-4.1-1.8
							c-1.4-0.5-2.8-0.8-4.1-1.1c-5.7-1.1-10.9-0.6-15.4,0.5c-2.3,0.6-4.4,1.3-6.3,2.2c-2,0.9-3.8,1.8-5.5,2.9
							c-6.8,4.3-11.4,9.7-15.1,15.3c-3.7,5.7-6.4,11.9-8.7,18.9c-2.3,7-4,15-6,24.4c2.8-9.2,5.4-16.9,8.5-23.4
							c3.1-6.5,6.6-11.9,10.7-16.4c4.1-4.5,9-8.3,14.8-10.6c1.5-0.6,3-1.1,4.6-1.5c1.6-0.4,3.2-0.6,4.9-0.8
							C324,453.6,327.6,453.9,331,455.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M404.9,411.1c2.3,2.3,5.4,3.5,7.9,3.5c2.5,0,4.5-1,5-2.7c0.5-1.7-0.4-3.6-2-5.1c-1.6-1.6-3.9-2.8-6.7-3.3
							c-2.9-0.6-5-1-7-1.6c-2.1-0.6-4.1-1.4-6.7-3.2c1.9,2.5,3.2,4.4,4.4,6.3C401.2,406.8,402.5,408.7,404.9,411.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M409.7,430.4c0.3,2.7,1.1,4.8,2.2,6.6c1.1,1.8,2.6,3.2,4.3,3.3c1.7,0.1,3.4-1.5,4-4.4c0.6-2.8-0.2-6.6-2.6-9.4
							c-2.5-2.9-4.6-4.3-6.7-5.5c-2.1-1.2-4.2-2.3-7.3-3.5c2.5,2.1,3.7,4.1,4.5,6.1C408.9,425.6,409.4,427.6,409.7,430.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M403.3,444.1c-1,2.4-1.5,4.6-1.4,6.7c0,2,0.4,4,1.8,5.1c0.7,0.5,1.6,0.7,2.6,0.4c0.5-0.1,1.1-0.3,1.6-0.7
							c0.5-0.3,1.1-0.8,1.6-1.3c2-2.1,3.4-6,2.6-9.8c-0.8-3.8-2.1-6.1-3.4-8.2c-1.4-2.1-2.8-4-5.2-6.4c1.5,3,1.7,5.3,1.5,7.4
							C404.8,439.5,404.3,441.5,403.3,444.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M390,450.7c-2.1,1.7-3.5,3.8-4.1,5.9c-0.6,2-0.6,4.1,0.6,5.4c1.2,1.3,3.4,1.3,5.6,0.1c2.2-1.2,4.4-3.7,5.3-6.8
							c0.9-3.3,1.1-5.6,1.3-7.8c0.2-2.3,0.3-4.5,0.6-7.6c-1.3,2.8-2.6,4.5-4.1,6.1C393.8,447.5,392.2,448.9,390,450.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M373.1,454.6c-2.6,0.6-4.7,1.8-6.2,3.2c-1.5,1.5-2.5,3.3-2.1,5c0.4,1.7,2.3,2.9,4.9,2.9c2.6,0,5.8-1.2,8.1-3.7
							c2.3-2.6,3.4-4.6,4.5-6.7c1.1-2,2.1-4,3.4-6.8c-2.2,2.2-4.1,3.3-6,4.1C377.8,453.4,375.8,453.9,373.1,454.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M402.9,395c6.1,1.8,12.3-0.9,12.3-4.5c0-3.5-6.2-6.1-12.3-4.3c-3.2,0.9-5.3,1.8-7.5,2.5c-1.1,0.4-2.2,0.8-3.3,0.9
							c-0.6,0.1-1.2,0.1-1.8,0c-0.6-0.1-1.3-0.5-1.8-1.1c0.3,0.8,0.9,1.4,1.5,1.9c0.6,0.4,1.3,0.7,2,1c0.6,0.2,1.3,0.4,1.9,0.6
							c0.6,0.2,1.1,0.3,1.7,0.5C397.6,393.2,399.8,394.1,402.9,395z"
                      />
                    </g>
                    <g>
                      <path
                        d="M394.7,382.8c3.1-0.8,5.5-3.3,6.3-5.6c0.9-2.4,0.2-4.4-1.3-5.3c-1.5-0.9-3.4-0.7-5.1-0.1c-1.7,0.6-3.2,1.7-4.5,3.6
							c-1.4,1.9-2.6,3.3-4,4.4c-1.4,1.1-3.1,2.2-5.9,2.7c2.8,0.5,4.8,0.8,6.9,1C389.3,383.5,391.5,383.6,394.7,382.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M386.4,375.6c2.7-1.9,4.1-4.9,4.2-7.4c0.1-2.5-1.1-4.4-2.8-4.8c-1.7-0.4-3.5,0.4-5,1.5c-1.5,1.2-2.6,2.9-3.3,5.2
							c-0.7,2.4-1.5,4.1-2.4,5.8c-1,1.6-2.2,3.2-4.6,4.8c2.8-0.6,4.8-1.2,6.9-1.8C381.5,378.3,383.7,377.5,386.4,375.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M375.7,369.1c1.3-2.5,1-5.5-0.2-7.3c-1.2-1.9-3.1-2.6-4.7-2.1c-1.7,0.5-2.9,1.9-3.6,3.3c-0.7,1.5-0.8,3-0.2,4.9
							c0.6,1.9,1,3.5,1.2,5.1c0.1,1.6,0,3.3-1,5.5c1.8-1.6,3.2-2.8,4.5-4.1C373.1,373.1,374.4,371.7,375.7,369.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M362.5,362.8c-0.5-1.4-1.4-2.6-2.5-3.4c-1.1-0.8-2.3-1.2-3.4-1.3c-1.1-0.1-2.1,0.2-2.8,0.8c-0.7,0.5-1.2,1.3-1.4,2.1
							c-0.4,1.7,0.1,3.4,0.5,4.6c0.5,1.2,1.1,1.9,2.5,2.4c1.5,0.5,2.7,1.2,3.6,2.3c0.9,1,1.7,2.4,1.8,4.6c1-2,1.6-3.6,2-5.4
							C363.2,367.7,363.5,365.7,362.5,362.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M344.5,362.7c-2.6-1.8-5.9-2-8.1-1.1c-2.3,0.9-3.4,2.7-3.2,4.4c0.3,1.8,1.6,3.1,3.1,4c1.5,0.9,3.3,1.3,5.5,1.1
							c2.3-0.2,4.1-0.2,5.8,0c1.8,0.3,3.6,0.8,5.8,2.5c-1.4-2.3-2.5-4-3.7-5.7C348.5,366.3,347.1,364.6,344.5,362.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M331.8,369.5c-2.3-1-4.8-0.5-6.4,0.6c-1.6,1.2-2.2,3-1.8,4.7c0.5,1.7,1.8,2.9,3.2,3.6c1.4,0.6,3,0.7,4.7-0.1
							c1.8-0.8,3.2-1.5,4.6-1.9c1.5-0.4,3-0.7,5.2-0.3c-1.8-1.3-3.1-2.3-4.4-3.4C335.6,371.6,334.2,370.5,331.8,369.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M311.8,380.6c-2.9,0.2-5.3,1.7-6.6,3.4c-1.4,1.8-1.7,3.8-0.7,5.3c0.9,1.5,2.8,2.1,4.8,2c2-0.1,4.1-0.9,5.9-2.7
							c1.9-1.8,3.2-3.1,4.7-4.3c1.5-1.2,3-2.3,5.6-3.4c-2.8,0-4.7-0.1-6.7-0.3C316.8,380.6,314.7,380.4,311.8,380.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M293.1,401.5c-4.3,1.1-5.8,5.7-3.6,8.4c2.2,2.8,7,2.4,9-1.5c2.1-4,2.4-6.1,5.1-9.6
							C299.6,400.6,297.5,400.4,293.1,401.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M280.3,421.6c-1.9,0.9-2.9,2.5-3.1,4.2c-0.2,1.7,0.5,3.3,2,4.4c1.4,1,3.2,1.2,4.7,0.7c1.5-0.5,2.7-1.7,3.1-3.6
							c0.9-3.9,0.8-5.9,2.7-9.4C286.3,419.9,284.3,419.9,280.3,421.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M314.7,432.9c4.2-2.3,7.9-5.1,10.4-7.8c2.5-2.7,3.8-5.2,2.9-6.7c-0.9-1.5-3.8-1.6-7.5-0.3c-3.7,1.3-8,4.1-11.4,8
							c-3.5,4-5.5,7-7.5,10c-2,3-4,6-7,10.3c3.8-3.6,6.7-5.7,9.7-7.5C307.3,436.9,310.4,435.2,314.7,432.9z"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M344.5,109.3c1.3-4.4,1.9-9.1,1.4-13.7c-0.4-4.6-1.8-9.3-4.3-13.4c-0.3-0.5-0.6-1-1-1.5c-0.4-0.5-0.7-1-1.1-1.5
						c-0.8-0.9-1.5-1.8-2.4-2.7c-1.7-1.7-3.6-3.1-5.6-4.3c-4-2.4-8.3-3.8-12.6-4.4c-4.3-0.6-8.7-0.6-12.9,0.4
						c-4.2,0.9-8.3,2.7-11.6,5.5c-3.3,2.7-5.9,6.2-7.2,10.2c-0.7,1.9-1.1,4.1-1.1,6.1c0,2,0.2,4,0.8,5.9c1.1,3.7,3.3,7,6.3,9.2
						c3,2.2,6.5,3.1,9.6,3c3.1-0.1,6.1-1.1,8.3-2.8c1.1-0.9,2-1.9,2.7-2.9c0.7-1.1,1.2-2.2,1.4-3.2c0.6-2.1,0.5-4.1,0.2-5.7
						c-0.3-1.6-1-2.9-1.6-3.8c-1.4-1.8-2.6-2.1-2.9-1.8c-0.3,0.3,0.2,1.3,0.6,2.9c0.2,0.8,0.3,1.8,0.2,3c-0.1,1.1-0.4,2.4-1,3.7
						c-0.3,0.6-0.7,1.2-1.2,1.7c-0.5,0.5-1.1,0.9-1.7,1.3c-1.3,0.7-3,1.1-4.8,0.9c-1.8-0.2-3.7-0.9-5-2.1c-1.4-1.3-2.5-3.2-2.9-5.3
						c-0.2-1.1-0.3-2.2-0.2-3.4c0.1-1.1,0.4-2.2,0.8-3.3c0.9-2.2,2.6-4.3,4.7-6c2.1-1.6,4.8-2.7,7.7-3.3c2.9-0.6,6.2-0.6,9.4-0.1
						c6.4,1,12.6,4.2,15.9,9.6c3.5,5.4,4.4,12.8,2.7,19.6c-0.4,1.7-1,3.4-1.8,5c-0.7,1.6-1.6,3.1-2.6,4.6c-2,2.9-4.2,5.4-6.7,7.6
						c-5,4.4-10.6,7.5-16.3,9.7c-5.7,2.3-11.4,3.6-17.1,4.6c-5.7,1-11.4,1.5-17.1,1.9c-5.7,0.4-11.5,0.5-17.5,0.9
						c-6,0.4-12.3,1.1-18.6,3c-3.2,0.9-6.4,2.1-9.5,3.8c-3.1,1.6-6.1,3.7-8.8,6.3c-2.7,2.6-5,5.8-6.7,9.4c-1.6,3.6-2.6,7.8-2.2,12.1
						c-0.2-4.3,0.9-8.4,2.6-12c1.8-3.5,4.1-6.6,6.9-9c2.7-2.4,5.7-4.3,8.8-5.8c3.1-1.4,6.2-2.5,9.4-3.2c6.3-1.5,12.4-1.9,18.3-1.9
						c5.9-0.1,11.7,0.2,17.5,0.2c5.8,0,11.6-0.2,17.6-0.8c6-0.7,12.2-1.7,18.5-3.8c6.3-2,12.9-5,19-9.8c3.1-2.4,6-5.2,8.6-8.6
						C340.9,117.8,343.1,113.9,344.5,109.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M278.5,96c-3.9-1.2-8.4-1.3-12.5,0.5c-2,0.9-3.8,2.1-5.3,3.7c-1.5,1.5-2.7,3.3-3.6,5.3c-0.8,2-1.2,4.2-1.1,6.4
						c0.1,2.2,0.7,4.2,1.6,6c1.8,3.5,4.9,6.1,8.4,7c3.5,1,6.9,0.3,9.4-1.4c1.2-0.8,2.2-1.9,2.8-3c0.6-1.1,0.9-2.2,1-3.1
						c0.2-1.9-0.3-3.4-0.9-4.2c-0.6-0.8-1.2-1-1.6-0.9c-0.8,0.3-0.9,2.1-2.2,3.6c-0.3,0.4-0.7,0.8-1.1,1c-0.4,0.3-0.8,0.4-1.3,0.5
						c-1,0.2-2.4,0.1-3.4-0.5c-1.1-0.6-2.2-1.8-2.7-3.2c-0.6-1.5-0.5-2.8,0.1-4.4c0.7-1.6,2.1-3.1,3.8-4c1.7-0.9,3.9-1.2,6.3-0.9
						c4.9,0.7,8.5,4.3,10.6,8.1c2.2,3.9,3,8.1,2.7,12.3c-0.2,2.1-0.6,4.2-1.5,6.2c-0.4,1-1,2-1.6,3c-0.7,0.9-1.4,1.8-2.4,2.7
						c-1.8,1.6-4.1,3-6.9,3.8c-2.7,0.7-6,0.9-9.2-0.3c3.1,1.4,6.5,1.6,9.4,1.1c3-0.5,5.6-1.7,7.8-3.3c1.1-0.8,2.1-1.7,3-2.6
						c0.9-1,1.7-2,2.3-3.1c1.4-2.1,2.2-4.5,2.9-6.9c1.1-4.8,1-10-0.9-15.4c-1-2.7-2.4-5.4-4.7-8C285.7,99.6,282.5,97.2,278.5,96z"
                    />
                  </g>
                  <g>
                    <path
                      d="M315.1,122.6c2.7-2.4,4.1-5.8,4.2-8.5c0.1-2.8-0.9-4.8-2.6-5.3c-1.7-0.5-3.6,0.5-5.2,2.2c-1.6,1.7-2.9,3.9-3.7,6.8
						c-0.8,2.9-1.5,5-2.5,7.1c-1,2-2.1,4-4.5,6.4c3-1.5,5.1-2.6,7.3-3.8C310.2,126.3,312.4,125.1,315.1,122.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M366,89.8c2.5-1.7,4.3-3.8,5.4-5.9c1.1-2.1,1.5-4.2,0.4-5.7c-1-1.4-3.3-1.7-5.9-0.7c-2.6,1.1-5.4,3.6-6.8,6.9
						c-1.5,3.5-2,6-2.5,8.4c-0.4,2.5-0.8,4.9-1.4,8.3c1.5-3.1,3.1-4.9,4.7-6.6C361.7,93,363.5,91.6,366,89.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M379.9,95.6c3-0.8,5.4-2.4,6.9-4.3c1.5-1.8,2.2-3.9,1.4-5.5c-0.8-1.6-2.9-2.2-5.4-1.9c-2.5,0.3-5.4,1.7-7.6,4.1
						c-2.3,2.5-3.6,4.4-5,6.2c-1.4,1.8-2.8,3.6-5.1,6c2.9-1.5,5.1-2.3,7.2-2.9C374.6,96.9,376.8,96.4,379.9,95.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M390.9,105.2c3.1,0.2,5.9-0.4,8-1.5c2.1-1.1,3.7-2.6,3.6-4.4c0-1.7-1.7-3.3-4.4-4c-2.6-0.7-6.2-0.4-9.3,1.3
						c-3.2,1.7-5.2,3.3-7.1,4.7c-1.9,1.5-3.8,3-6.6,5c3.3-1.1,5.6-1.4,7.9-1.5C385.4,104.9,387.7,105,390.9,105.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M360.3,116.8c2.7-0.8,4.8-2.3,6.1-4c1.3-1.7,2-3.7,1.2-5.3c-0.7-1.6-2.7-2.4-5.1-2.1c-2.4,0.3-5.2,1.7-7.2,4.2
						c-2,2.5-3.1,4.5-4.2,6.4c-1.1,1.9-2.2,3.8-3.9,6.3c2.5-1.8,4.4-2.7,6.3-3.5C355.5,118.1,357.5,117.6,360.3,116.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M373.4,121.3c2.6,0.1,4.9-0.6,6.6-1.8c1.7-1.2,2.8-2.9,2.6-4.6c-0.2-1.7-1.8-3.1-4.1-3.6c-2.3-0.5-5.2,0-7.7,1.7
						c-2.5,1.8-4.1,3.3-5.6,4.7c-1.5,1.5-3,2.9-5.3,4.7c2.8-0.9,4.8-1.1,6.7-1.2C368.7,121,370.7,121.2,373.4,121.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M293.2,147.9c-6.4,0.5-11.3,5.2-10.1,8.5c1.2,3.3,8,3.6,13.2-0.3c5.3-4,7-6.2,12.8-9.5
						C302.5,148,299.8,147.4,293.2,147.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M300.9,162.8c-1.7,2.4-2,5.5-1.2,7.6c0.7,2.2,2.4,3.5,4.1,3.4c1.8-0.1,3.2-1.3,4.2-2.8c1-1.5,1.5-3.4,1.3-5.6
						c-0.2-2.3-0.3-4.1-0.2-5.9c0.2-1.8,0.5-3.6,1.8-5.9c-2.1,1.6-3.6,2.8-5.1,4.1C304.2,159,302.7,160.4,300.9,162.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M250.7,158.6c5.2-0.2,10.2-0.8,14.1-1.6c3.9-0.8,6.7-2,7-3.7c0.2-1.7-2.4-3.7-6.9-4.6c-4.4-1-10.5-0.7-16.1,1.4
						c-5.7,2.2-9.2,4.4-12.7,6.7c-3.4,2.3-6.7,4.7-11.2,8.5c5.1-2.9,9-4.2,12.8-5.1C241.5,159.3,245.3,158.8,250.7,158.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M452.7,61.6c-0.5-3.2-1.3-6.4-2.5-9.5c-1.2-3.1-2.9-6.2-5.1-8.9c-2.2-2.7-5-5-8.1-6.7c-3.1-1.7-6.7-2.7-10.3-2.8
						l-1.4,0l-1.4,0.1c-0.9,0.1-1.8,0.3-2.7,0.5c-1.8,0.5-3.4,1.1-4.9,2c-3,1.7-5.6,4.2-7.3,7.3c-1.7,3-2.4,6.8-1.9,10
						c0.2,1.6,0.7,3.2,1.3,4.6c0.6,1.4,1.4,2.7,2.2,3.8c3.4,4.5,8.5,7,13.1,7.2c2.3,0.1,4.6-0.3,6.5-1.3c1-0.5,1.8-1.1,2.5-1.8
						c0.7-0.7,1.2-1.5,1.7-2.3c1.5-3.2,1-6,0-7.5c-0.5-0.8-1-1.2-1.4-1.4c-0.4-0.2-0.7-0.2-0.9,0c-0.3,0.3-0.1,1.1-0.1,2.2
						c0,0.6-0.1,1.2-0.4,1.9c-0.3,0.7-0.7,1.5-1.3,2.1c-0.3,0.3-0.7,0.6-1,0.8c-0.3,0.2-0.8,0.4-1.3,0.5c-1,0.2-2.2,0.2-3.5-0.1
						c-2.6-0.6-5.4-2.4-7.1-5.2c-0.4-0.7-0.8-1.4-1-2.2c-0.2-0.8-0.4-1.5-0.4-2.3c-0.1-0.8,0-1.5,0.2-2.2c0.2-0.7,0.5-1.4,0.9-2.1
						c0.8-1.4,2.2-2.6,3.9-3.5c0.8-0.4,1.7-0.8,2.6-1c0.4-0.1,0.9-0.2,1.4-0.2l0.7,0l0.7,0c1.9,0.1,4,0.6,5.9,1.7
						c1.9,1,3.8,2.5,5.3,4.2c3.1,3.5,5.2,8.4,6.4,13.7c1.2,5.5,1.6,10.9,1.4,15.9c-0.2,5-0.9,9.7-1.9,14.1
						c-2.1,8.8-5.4,16.4-9.6,23.5c-4.2,7.1-9.5,13.8-16.3,20.4c-6.8,6.6-15.2,13.2-26.1,19.6c11.2-6,20-12.1,27.3-18.3
						c7.3-6.2,13.3-12.6,18.3-19.6c5-7,9.2-14.7,12.3-23.9c1.5-4.6,2.7-9.6,3.4-15.1C453.5,74.1,453.6,68.1,452.7,61.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M420.9,70.1c-1.7-0.9-3.7-1.5-5.7-1.6c-2.1-0.2-4.2,0.2-6.2,0.9c-1.9,0.7-3.7,1.8-5.2,3.1c-1.5,1.4-2.7,3-3.5,5
						c-0.8,1.9-1.2,4.1-1.1,6c0.1,2,0.6,3.8,1.4,5.5c1.6,3.3,4.3,5.8,7.4,6.9c1.6,0.6,3.2,0.8,4.6,0.6c1.4-0.1,2.7-0.5,3.8-0.9
						c2.2-1,3.7-2.4,4.6-3.8c0.9-1.4,1.2-2.7,1.1-3.6c-0.1-0.9-0.4-1.4-0.8-1.5c-0.9-0.2-1.9,0.9-3.8,1.6c-0.9,0.4-2,0.7-3.2,0.8
						c-1.2,0-2.4-0.2-3.2-0.9c-0.9-0.6-1.7-1.7-2.1-3c-0.2-0.6-0.3-1.3-0.3-2c0-0.6,0.2-1.2,0.4-1.8c0.5-1.2,1.7-2.4,3.2-3
						c0.7-0.3,1.5-0.5,2.2-0.6c0.8,0,1.7,0.1,2.6,0.3c1.9,0.6,3.8,1.9,5.4,3.4c0.8,0.8,1.5,1.6,2.2,2.4c0.7,0.8,1.3,1.7,1.9,2.6
						c2.3,3.5,3.9,7.1,5,10.9c1.1,3.8,1.7,7.8,1.5,12.4c0,0.6-0.1,1.1-0.1,1.7c0,0.6-0.1,1.2-0.2,1.8c-0.1,1.2-0.4,2.4-0.6,3.7
						c-0.6,2.5-1.4,5.2-2.7,8c1.5-2.7,2.7-5.3,3.5-7.8c0.4-1.3,0.8-2.5,1.1-3.7c0.1-0.6,0.3-1.2,0.4-1.8c0.1-0.6,0.2-1.2,0.3-1.8
						c0.8-4.7,0.9-9,0.5-13.3c-0.5-4.3-1.4-8.6-3.3-13.1c-0.5-1.1-1-2.3-1.7-3.4c-0.6-1.2-1.4-2.4-2.2-3.5
						C426.7,74.4,424.4,72,420.9,70.1z"
                    />
                  </g>
                  <g>
                    <g>
                      <path
                        d="M503.8,68.5c5.7-0.7,11.4-1.1,16.9-1.7c5.5-0.5,10.7-1.1,15.1-2c4.5-0.8,8.2-1.9,10.7-2.9c2.5-1.1,3.9-2.2,3.7-3
							c-0.2-0.9-1.8-1.4-4.4-1.8c-2.6-0.4-6.3-0.5-10.8-0.6c-4.4-0.1-9.6,0-15.3,0.4c-5.6,0.4-11.8,1.3-17.7,2.9
							c-6.2,1.7-11.2,3.7-15.6,6c-4.3,2.3-8,4.8-11.3,7.8c-0.8,0.7-1.6,1.5-2.4,2.3c-0.8,0.8-1.5,1.6-2.3,2.5
							c-1.5,1.7-2.9,3.6-4.2,5.6c-2.6,4.1-4.9,9.2-5.6,15.5c1.2-6.2,4.1-10.9,7-14.5c1.5-1.8,3.1-3.4,4.7-4.7c0.8-0.7,1.7-1.3,2.5-2
							c0.9-0.6,1.7-1.2,2.6-1.7c3.5-2.2,7.2-3.8,11.4-5.1C493.2,70.2,498,69.2,503.8,68.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M512,85.7c5.6-1.4,11.1-3.6,15.9-6.1c4.8-2.5,9.1-5.5,12.4-8.3c6.7-5.7,9.8-10.9,8.6-12.1c-1.2-1.3-6.2,1.4-13.3,5.2
							c-7.1,3.9-16.5,8.8-26.3,13c-5.1,2.2-9.5,3.7-13.6,4.9c-4.1,1.2-8,2.1-11.9,3.3c-3.9,1.1-7.8,2.4-11.9,4.4
							c-2,1-4.2,2.2-6.2,3.8c-2.1,1.5-4.1,3.5-5.8,5.9c1.9-2.2,4.1-3.9,6.3-5.2c2.2-1.3,4.3-2.2,6.4-2.9c4.2-1.4,8.1-1.9,12-2.3
							c3.9-0.4,7.8-0.5,12.3-1C501.2,87.9,506.2,87.1,512,85.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M519.6,76.1c6.3-1,12.2-3.4,16.2-6c4.1-2.6,6.3-5.3,5.6-6.9c-0.7-1.6-4.1-1.8-8.7-0.9c-4.5,0.9-10.2,2.7-15.7,5.4
							c-5.7,2.7-9.6,4.6-13.6,6.3c-4.1,1.7-8.3,3.3-14.6,4.9c6.6-0.6,11-0.9,15.5-1.2C508.7,77.4,513.2,77.1,519.6,76.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M517.7,72.2c4.9-0.5,9.4-2.3,12.5-4.4c3.1-2.1,4.8-4.4,4.1-6c-0.7-1.6-3.4-2.2-7-1.8c-3.6,0.4-8,1.7-12.2,3.8
							c-4.3,2.3-7.3,3.9-10.4,5.4c-3.1,1.5-6.2,2.9-11.1,4.4c5-0.4,8.5-0.6,12-0.7C509.2,72.8,512.7,72.7,517.7,72.2z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M490.4,213.7c-5.6-1.1-11.2-2.4-16.6-3.5c-5.4-1.2-10.5-2.2-15-2.7c-4.5-0.6-8.4-0.7-11.1-0.5
							c-2.7,0.3-4.3,0.9-4.5,1.8c-0.1,0.9,1.2,1.9,3.6,3c2.4,1.1,5.9,2.4,10.1,3.8c4.2,1.4,9.2,2.9,14.7,4.2
							c5.5,1.3,11.6,2.3,17.8,2.6c6.4,0.2,11.8-0.1,16.7-1c4.8-0.9,9.1-2.1,13.1-4c1-0.4,2-1,3-1.4c1-0.5,2-1.1,2.9-1.7
							c1.9-1.2,3.8-2.5,5.7-4.1c3.7-3.1,7.4-7.3,10-13.1c-3.1,5.6-7.2,9.1-11.1,11.7c-2,1.3-3.9,2.3-5.9,3.1c-1,0.4-2,0.8-2.9,1.1
							c-1,0.3-2,0.6-3,0.8c-3.9,1-8,1.4-12.4,1.4C501.1,215.3,496.2,214.8,490.4,213.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M487.9,194.8c-5.8-0.4-11.6,0.1-17,1c-5.4,1-10.3,2.5-14.3,4.2c-8.1,3.4-12.6,7.4-11.8,8.9c0.8,1.6,6.3,0.6,14.3-0.9
							c8-1.5,18.4-3.4,29-4.4c5.5-0.6,10.2-0.7,14.5-0.6c4.3,0.1,8.3,0.4,12.3,0.5c4,0.1,8.2,0.1,12.7-0.6c2.3-0.4,4.6-0.9,7.1-1.7
							c2.4-0.9,4.9-2.1,7.3-3.9c-2.5,1.6-5.1,2.5-7.5,3.1c-2.4,0.6-4.8,0.8-7,0.8c-4.4,0-8.3-0.6-12.1-1.4c-3.8-0.8-7.6-1.9-12-2.8
							C498.8,196,493.9,195.2,487.9,194.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M477.7,201.6c-6.3-0.9-12.6-0.4-17.3,0.8c-4.7,1.2-7.6,3.1-7.4,4.9c0.2,1.8,3.4,2.9,8,3.5c4.6,0.5,10.6,0.5,16.6-0.4
							c6.2-0.9,10.5-1.5,14.9-1.9c4.4-0.4,8.9-0.6,15.4-0.3c-6.4-1.4-10.8-2.5-15.2-3.5C488.5,203.7,484.2,202.6,477.7,201.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M478.3,205.9c-4.8-1-9.7-0.7-13.3,0.4c-3.6,1-5.9,2.8-5.8,4.5c0.1,1.8,2.6,3.1,6.1,3.9c3.5,0.7,8.1,0.8,12.8,0
							c4.8-0.8,8.1-1.5,11.5-2c3.4-0.5,6.8-0.9,11.9-0.8c-4.9-1.1-8.3-2-11.6-3C486.6,207.9,483.3,206.9,478.3,205.9z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M295.7,319.1c4.1-3.9,8.4-7.8,12.5-11.5c4.1-3.7,7.9-7.3,10.9-10.7c3.1-3.3,5.4-6.4,6.8-8.8c1.4-2.4,1.8-4.1,1.2-4.7
							c-0.6-0.6-2.3-0.1-4.6,1.2c-2.3,1.3-5.4,3.4-9,6c-3.6,2.6-7.7,5.8-12,9.5c-4.2,3.7-8.6,8.1-12.4,13c-3.9,5.1-6.7,9.7-8.9,14.2
							c-2.1,4.4-3.5,8.7-4.4,13c-0.2,1.1-0.4,2.2-0.6,3.3c-0.1,1.1-0.3,2.2-0.3,3.3c-0.2,2.3-0.2,4.6,0,7c0.4,4.8,1.6,10.3,4.8,15.8
							c-2.8-5.7-3.3-11.1-3.1-15.8c0.1-2.3,0.4-4.5,0.9-6.6c0.2-1,0.5-2,0.8-3c0.3-1,0.6-2,1-2.9c1.5-3.8,3.5-7.3,6.1-11
							C288.2,326.9,291.4,323.2,295.7,319.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M312.5,328c3.7-4.5,6.7-9.5,9-14.5c2.3-4.9,4-9.8,4.9-14.1c1.9-8.6,1.3-14.5-0.4-14.8c-1.8-0.3-4.2,4.8-7.5,12.2
							c-3.4,7.4-7.9,16.9-13.3,26.2c-2.7,4.8-5.4,8.7-7.9,12.1c-2.6,3.4-5.1,6.5-7.5,9.7c-2.4,3.2-4.8,6.6-6.9,10.7
							c-1,2.1-2,4.3-2.7,6.8c-0.7,2.5-1.2,5.2-1.1,8.2c0.2-3,0.9-5.6,1.9-7.9c1-2.3,2.2-4.3,3.4-6.1c2.5-3.6,5.3-6.4,8.2-9
							c2.9-2.7,5.9-5.1,9.3-8.2C305.2,336.2,308.7,332.7,312.5,328z"
                      />
                    </g>
                    <g>
                      <path
                        d="M312.9,315.8c4.4-4.6,7.7-10,9.4-14.5c1.7-4.5,1.8-8,0.3-8.8c-1.6-0.9-4.4,1.1-7.5,4.5c-3.1,3.4-6.5,8.3-9.3,13.8
							c-2.9,5.6-4.9,9.4-7.1,13.3c-2.2,3.8-4.6,7.6-8.7,12.7c4.9-4.4,8.3-7.4,11.7-10.3C305,323.4,308.3,320.5,312.9,315.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M309,313.8c3.6-3.4,6.2-7.5,7.4-11c1.2-3.5,1.2-6.4-0.3-7.3c-1.5-0.9-4,0.3-6.7,2.7c-2.6,2.5-5.4,6.1-7.4,10.4
							c-2.1,4.4-3.5,7.5-5,10.5c-1.6,3-3.3,6.1-6.2,10.2c3.8-3.4,6.5-5.6,9.2-7.8C302.6,319.3,305.3,317.2,309,313.8z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M112.7,104.3c4.4,3.7,8.6,7.6,12.7,11.2c4.1,3.6,8.1,7,11.7,9.8c3.6,2.7,6.9,4.7,9.5,5.9c2.5,1.1,4.2,1.4,4.7,0.7
							c0.5-0.7-0.2-2.2-1.7-4.4c-1.5-2.2-3.9-5-6.9-8.3c-3-3.3-6.6-7.1-10.7-10.9c-4.2-3.8-8.9-7.7-14.2-11
							c-5.4-3.3-10.4-5.7-15-7.3c-4.6-1.6-9-2.6-13.4-3c-1.1-0.1-2.2-0.1-3.3-0.2c-1.1,0-2.2,0-3.4,0c-2.3,0.1-4.6,0.3-7,0.8
							c-4.8,0.9-10.1,2.7-15.2,6.4c5.4-3.3,10.7-4.4,15.4-4.7c2.3-0.1,4.6,0,6.7,0.2c1.1,0.1,2.1,0.3,3.1,0.5c1,0.2,2,0.4,3,0.7
							c3.9,1.1,7.6,2.7,11.5,4.9C104.2,97.6,108.2,100.5,112.7,104.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M105.7,121.9c4.9,3.2,10.2,5.7,15.3,7.5c5.2,1.8,10.2,2.9,14.5,3.4c8.7,1,14.6-0.3,14.7-2c0.1-1.8-5.2-3.6-12.9-6.2
							c-7.7-2.6-17.7-6.1-27.5-10.4c-5.1-2.2-9.2-4.4-12.9-6.6c-3.7-2.2-7-4.4-10.5-6.5C83,99,79.4,97,75.1,95.4
							c-2.1-0.8-4.5-1.5-7-2c-2.5-0.5-5.3-0.6-8.3-0.2c3-0.1,5.7,0.3,8.1,1c2.4,0.7,4.5,1.7,6.5,2.7c3.9,2.1,6.9,4.6,9.8,7.2
							c2.9,2.6,5.7,5.4,9.1,8.4C96.7,115.5,100.6,118.7,105.7,121.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M117.9,121c5,3.9,10.8,6.6,15.4,7.8c4.7,1.2,8.1,1,8.8-0.6c0.7-1.6-1.5-4.2-5.3-7c-3.7-2.7-9-5.6-14.7-7.8
							c-5.8-2.3-9.9-3.9-13.9-5.7c-4-1.8-8-3.8-13.6-7.4c4.9,4.4,8.2,7.5,11.5,10.5C109.5,113.9,112.7,117,117.9,121z"
                      />
                    </g>
                    <g>
                      <path
                        d="M119.5,117c3.7,3.2,8.1,5.3,11.7,6.2c3.6,0.9,6.5,0.5,7.2-1.1c0.8-1.6-0.7-4-3.4-6.4c-2.7-2.4-6.7-4.7-11.1-6.3
							c-4.6-1.6-7.8-2.7-11-3.9c-3.2-1.2-6.4-2.6-10.8-5.1c3.8,3.4,6.2,5.8,8.7,8.3C113.2,111.2,115.6,113.7,119.5,117z"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M46.9,120.2c-4.8-3.3-10.4-5.5-16.3-6.1c-2.9-0.3-5.9-0.2-8.8,0.3c-2.9,0.5-5.8,1.4-8.4,2.9c-2.6,1.5-5.1,3.5-6.9,6.1
						c-0.5,0.7-0.9,1.3-1.2,2c-0.3,0.7-0.7,1.5-0.9,2.2L4,128.7l-0.2,1.1c-0.2,0.8-0.2,1.5-0.3,2.3c-0.1,3,0.5,5.8,1.6,8.3
						c1.1,2.5,2.6,4.6,4.5,6.3c1.8,1.7,4,3,6.2,3.6c2.1,0.7,4.2,1,6.1,0.9c1.9-0.1,3.6-0.4,5.2-0.9c1.5-0.5,2.8-1.1,3.9-1.8
						c2.2-1.3,3.6-2.9,4.3-4c0.7-1.2,0.8-2,0.5-2.2c-0.3-0.3-1.1-0.1-2.2,0.3c-1.1,0.4-2.6,1.1-4.6,1.5c-1,0.2-2,0.4-3.2,0.5
						c-1.1,0.1-2.4,0-3.6-0.2c-1.2-0.2-2.5-0.6-3.6-1.2c-1.2-0.6-2.1-1.4-3-2.4c-1.8-2.1-2.9-5.2-2.6-8.1c0.1-0.3,0.1-0.7,0.2-1
						l0.1-0.5l0.2-0.5c0.1-0.3,0.3-0.6,0.4-0.9c0.2-0.3,0.4-0.6,0.6-0.9c0.9-1.2,2.2-2.2,3.7-3.1c1.6-0.9,3.4-1.5,5.4-1.8
						c2-0.4,4.1-0.5,6.2-0.3c4.2,0.3,8.6,1.6,12.4,3.9c4,2.3,7.2,5.4,9.7,8.7c2.5,3.3,4.3,6.9,5.7,10.4c2.6,7.1,3.3,14.2,2.9,21.3
						c-0.4,7.1-1.8,14.3-4.7,22c-3,7.6-7.4,15.8-14.9,23.8c7.8-7.7,12.8-15.6,16.5-23.2c3.6-7.6,5.7-14.8,6.9-22.3
						c0.6-3.7,0.9-7.5,0.8-11.4c-0.1-3.9-0.5-8-1.6-12.1c-1.1-4.2-2.8-8.5-5.4-12.7C55.4,127.8,51.9,123.6,46.9,120.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M34,153.7c-3,0.6-5.5,2.1-7.5,4c-2,1.9-3.5,4.3-4.3,7c-0.4,1.3-0.6,2.7-0.6,4.1c0,1.4,0.2,2.8,0.7,4.1
						c0.8,2.5,2.4,4.7,4.4,6.1c1,0.7,2,1.2,3.1,1.5c1.1,0.3,2.1,0.4,3,0.4c1.9-0.1,3.4-0.7,4.4-1.5c1-0.8,1.6-1.6,1.8-2.3
						c0.2-0.7,0-1.2-0.3-1.5c-0.7-0.6-1.9-0.4-3.2-0.7c-0.7-0.1-1.4-0.4-2-0.7c-0.3-0.2-0.6-0.4-0.8-0.6c-0.2-0.2-0.4-0.5-0.6-0.8
						c-0.4-0.6-0.7-1.4-0.8-2.3c-0.1-0.4-0.1-0.9,0-1.3c0-0.5,0.2-1,0.3-1.5c0.6-2.1,2.6-4.5,4.7-5.5c1.1-0.5,2.1-0.7,3.3-0.5
						c1.2,0.2,2.3,0.8,3.3,1.6c1,0.8,1.8,1.7,2.5,2.8c0.7,1.1,1.2,2.3,1.6,3.6c0.8,2.6,1,5.5,0.9,8.8c-0.1,3.3-0.7,7-0.7,11.4
						c0.6-4.4,1.7-7.9,2.4-11.2c0.8-3.3,1.3-6.4,1.2-9.6c-0.1-1.6-0.3-3.3-0.8-4.9c-0.5-1.7-1.3-3.4-2.5-5c-1.2-1.6-2.8-3.2-5.1-4.3
						c-1.1-0.6-2.4-1-3.8-1.2C37.1,153.3,35.5,153.4,34,153.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M80.9,129c2-0.8,3.3-2.1,4-3.7c0.7-1.6,0.9-3.4,0.1-5c-0.8-1.5-2.7-2.3-4.8-1.9c-2.1,0.4-4.4,2-5.6,4.5
						c-1.2,2.6-1.6,4.5-1.9,6.4c-0.3,1.9-0.7,3.6-1.1,6.1c1.5-2,2.9-3.1,4.3-4C77.3,130.6,78.8,129.9,80.9,129z"
                    />
                  </g>
                  <g>
                    <path
                      d="M87.6,140.1c1.9,0,3.2-0.8,4.1-2.1c0.9-1.2,1.4-2.9,1-4.7c-0.4-1.7-1.8-3-3.7-3.3c-1.9-0.3-4.2,0.4-5.7,2.3
						c-1.5,2-2.3,3.5-3,5c-0.7,1.5-1.3,2.9-2.3,4.8c1.8-1.2,3.2-1.6,4.7-1.9C84.1,140.2,85.6,140.1,87.6,140.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M69.7,113.9c0.7-3.1,0.1-6.2-1.2-8.3c-1.3-2.1-3.2-3.1-4.9-2.7c-1.7,0.5-2.8,2.2-3.3,4.3c-0.5,2.1-0.3,4.6,0.7,7.2
						c1.1,2.6,1.8,4.6,2.5,6.6c0.6,2,1,4.1,1,7.1c1.1-2.9,1.9-4.9,2.8-6.9C68.1,119.2,69,117.1,69.7,113.9z"
                    />
                  </g>
                  <g>
                    <path d="M51.6,99.8c-0.9-5.8-6-9.3-9.1-7.5c-3,1.8-2.7,7.7,1.3,11.5c4.1,4,6.3,5.3,9.7,10.1C52.1,108.1,52.6,105.7,51.6,99.8z" />
                  </g>
                  <g>
                    <path
                      d="M25.9,96.2c-2.9-2.2-6.5-3.1-9.2-2.9c-2.8,0.2-4.8,1.4-5.1,3.1c-0.3,1.7,0.9,3.6,3,5c2,1.5,4.9,2.6,8.1,2.9
						c3.3,0.4,5.7,0.6,8.1,1.1c2.4,0.5,4.7,1.1,7.9,2.7c-2.6-2.5-4.3-4.2-6.1-6C30.7,100.2,28.9,98.4,25.9,96.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-1.6,103.7c-2.7-0.3-5.2,0.9-6.5,2.5c-1.4,1.7-1.5,3.6-0.6,5.1c1,1.5,2.6,2.2,4.2,2.4c1.6,0.2,3.2-0.2,4.8-1.5
						c1.6-1.3,3-2.3,4.4-3c1.4-0.8,3-1.3,5.4-1.4c-2.1-0.9-3.7-1.6-5.5-2.4C3,104.7,1.2,104-1.6,103.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-13.1,124.3c-2.5,1.7-3.7,4.6-3.5,6.9c0.2,2.4,1.6,3.9,3.3,4.1c1.8,0.2,3.4-0.6,4.6-1.5c1.3-1,2.1-2.2,2.5-4.1
						c0.4-2,0.9-3.5,1.7-5c0.8-1.4,1.9-2.8,4.1-4c-2.5,0.2-4.3,0.5-6.2,0.9C-8.6,122.1-10.6,122.6-13.1,124.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-9.6,149.9c-0.9,2.6-0.3,5.4,1.1,7.1c1.4,1.7,3.3,2.2,4.9,1.5c1.6-0.7,2.7-2.2,3.2-3.7c0.5-1.5,0.5-3.1-0.4-4.8
						c-0.9-1.8-1.5-3.3-1.9-4.9c-0.3-1.6-0.5-3.2,0.1-5.5c-1.6,1.8-2.7,3.2-3.8,4.6C-7.5,145.6-8.6,147.2-9.6,149.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M3.1,168.8c0.4,2.9,2.4,5.2,4.4,6.2c2.1,1,4.1,0.7,5.2-0.6c1.2-1.3,1.4-3.2,1.1-4.9c-0.3-1.7-1.2-3.3-2.9-4.7
						c-1.7-1.4-3-2.6-4.1-3.9c-1.1-1.3-2-2.9-2.7-5.4c-0.4,2.6-0.7,4.4-1,6.4C2.9,163.8,2.7,165.8,3.1,168.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M75.4,229.7c2.3,1.4,4,3.2,4.8,5.2c0.9,2,0.9,4.3,0.3,5.8c-0.6,1.5-2,2.8-3.6,3.4c-0.8,0.3-1.5,0.4-2.3,0.4
						c-0.8,0-1.6-0.2-2.3-0.6c-1.5-0.6-2.7-1.8-3.4-2.9c-0.7-1.1-0.9-2.1-0.8-2.9c0.1-0.8,0.6-1.6,1.1-2c0.4-0.5,0.9-0.8,1-1.2
						c0.1-0.4-0.3-1-1.5-1.3c-1.1-0.2-3,0.1-4.7,1.8c-0.4,0.4-0.8,1-1.1,1.6c-0.3,0.6-0.5,1.2-0.7,1.9c-0.3,1.4-0.3,2.8,0.1,4.3
						c0.7,2.9,2.5,5.8,5.5,7.9c1.5,1,3.3,1.8,5.3,2.3c2,0.4,4.2,0.4,6.3-0.2c2.1-0.5,4.1-1.5,5.9-3c1.8-1.4,3.3-3.4,4.2-5.7
						c0.9-2.3,1.3-4.7,1.1-7c-0.1-2.3-0.8-4.6-1.8-6.7c-1-2.1-2.5-4-4.2-5.5c-1.7-1.5-3.6-2.6-5.6-3.4c-4.1-1.7-8.1-2-11.5-1.8
						c-3.5,0.2-6.5,0.9-9.3,1.7c-5.5,1.7-10.2,3.7-14.6,6.1c-4.5,2.5-8.8,5.2-13,9c-2.1,1.9-4.3,4-6.3,6.4c-2.1,2.5-4.1,5.2-5.8,8.5
						c2-3.1,4.2-5.7,6.4-7.9c2.2-2.2,4.5-4.1,6.8-5.6c4.5-3.2,9-5.2,13.5-6.9c4.5-1.7,9.2-2.8,14.4-3.6c2.6-0.3,5.2-0.5,7.9-0.3
						c1.3,0.1,2.7,0.3,4,0.7C72.9,228.5,74.2,229,75.4,229.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M55.5,215c4.5-2.5,8.1-6.3,10.1-9.7c2-3.4,2.4-6.3,1-7.5c-1.4-1.1-4.1-0.2-7.1,1.7c-3.1,2-6.5,5-9.6,8.6
						c-3.2,3.8-5.4,6.3-7.9,8.8c-2.5,2.5-5.2,4.8-9.6,7.6c4.8-1.8,8.2-3.1,11.7-4.4C47.5,218.9,50.9,217.5,55.5,215z"
                    />
                  </g>
                  <g>
                    <path
                      d="M75.4,216.2c2.3,0.5,4.3,0.1,5.9-0.8c1.6-1,2.7-2.5,2.7-4.3c0-1.8-1.2-3.3-3.1-4.1c-1.9-0.8-4.5-0.8-6.8,0.5
						c-2.4,1.3-3.8,2.5-5.3,3.6c-1.5,1.1-2.9,2.2-5.1,3.5c2.5-0.3,4.3-0.1,6,0.2C71.3,215.2,73,215.7,75.4,216.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M35.3,208.6c0.9-2.4,1.3-5,1.3-7.4c0-0.6,0-1.2-0.1-1.8c-0.1-0.6-0.1-1.1-0.2-1.7c-0.2-1.2-0.6-2.3-1.1-3.4
						c-0.5-1.1-1.1-2-1.9-2.8c-0.7-0.8-1.6-1.3-2.3-1.6c-1.6-0.5-2.6,0-3,0.7c-0.4,0.8-0.3,1.8-0.5,2.6c-0.1,0.9-0.3,1.8-0.4,3
						c-0.1,0.7-0.1,1.4-0.2,2.1c0,0.4-0.1,0.9-0.1,1.3c0,0.5,0,0.9-0.1,1.4c-0.1,1.9-0.1,3.9,0,6c0.1,4.3-0.2,7.5-0.8,10.6
						c-0.7,3.1-1.8,6.3-4.7,10.3c3.7-3.3,5.9-5.9,8.1-8.7c1.1-1.4,2.1-2.8,3.1-4.5C33.4,213,34.4,211.1,35.3,208.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M124.7,53.8c3.3-0.9,6.7-1.7,9-1.4c1.2,0.1,2,0.4,2.7,0.9c0.6,0.4,1.2,1.1,1.6,2c0.9,1.8,1,4.3,0.5,6.2
						c-0.1,0.5-0.3,1-0.5,1.5c-0.2,0.5-0.5,0.9-0.7,1.4c-0.5,0.8-1.2,1.5-1.8,2.1c-1.2,1-2.9,1.5-4.2,1.4c-0.7,0-1.3-0.2-1.8-0.4
						c-0.5-0.3-1-0.6-1.4-1c-1.7-1.6-1.8-3.6-2.6-3.9c-0.4-0.1-1,0.2-1.5,1.2c-0.5,1-0.8,2.8-0.1,4.9c0.3,1,0.9,2.2,1.9,3.2
						c1,1.1,2.2,2,3.8,2.6c3,1.2,6.9,1.2,10.4-0.7c1.7-0.9,3.3-2.2,4.6-3.7c0.7-0.8,1.2-1.6,1.8-2.5c0.5-0.9,1-1.8,1.4-2.9
						c0.8-2.1,1.1-4.3,1.1-6.5c-0.1-2.3-0.5-4.6-1.5-6.9c-1.1-2.3-2.8-4.5-5.2-6c-2.4-1.5-5.1-2.2-7.6-2.3c-2.5-0.1-4.7,0.3-6.8,0.9
						c-2.1,0.6-3.9,1.2-5.7,2c-3.8,1.6-7.6,3.8-10.3,7c-1.3,1.6-2.3,3.5-2.9,5.3c-0.6,1.8-0.9,3.6-0.9,5.3c-0.1,3.4,0.5,6.4,1.3,9.2
						c0.9,2.8,2,5.4,3.4,7.8c2.8,4.8,6.6,9.1,11.7,12.4c2.6,1.7,5.5,3.1,8.7,4.2c3.3,1.1,6.9,1.7,10.9,1.8
						c-3.9-0.3-7.5-1.3-10.6-2.6c-3.1-1.3-5.7-3-8-4.8c-4.5-3.7-7.5-8.1-9.4-12.7c-1-2.3-1.6-4.7-2-7.2c-0.4-2.5-0.5-5,0-7.5
						c0.2-1.2,0.6-2.4,1.1-3.4c0.5-1,1.2-2,2.1-2.8C118.7,56,121.3,54.8,124.7,53.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M86,72c-3-1.1-6.3-0.6-8.4,0.7c-2.1,1.3-3.1,3.2-2.6,4.9c0.5,1.7,2.2,2.8,4,3.3c1.9,0.6,4,0.6,6.4-0.2
						c2.5-0.8,4.3-1.2,6.3-1.4c1.9-0.2,4-0.2,6.8,0.7c-2.3-1.8-4-3.1-5.7-4.3C90.9,74.4,89.1,73.1,86,72z"
                    />
                  </g>
                  <g>
                    <path
                      d="M99.5,62.1c0.8-1.3,0.8-2.3,0.3-3.6c-0.5-1.2-1.3-2.8-2.7-3.8c-0.7-0.5-1.6-0.7-2.5-0.6c-0.9,0.1-1.8,0.5-2.6,1.3
						c-0.8,0.8-1.4,1.9-1.7,3.2c-0.3,1.3-0.2,2.8,0.4,4.1c1.2,2.8,2.7,4.1,4.1,5.3c1.4,1.1,2.9,2,4.9,2.9c-1.3-1.8-1.6-3.3-1.5-4.7
						C98.2,64.8,98.7,63.5,99.5,62.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M152.6,18.3c3.1-2.4,6-4.9,8.2-6.9c2.2-2,3.6-3.6,3.1-4.4c-0.4-0.8-2.6-0.5-5.5,1c-2.8,1.4-6.3,4-8.9,7.3
						c-2.7,3.4-4.2,5.9-5.6,8.5c-1.4,2.6-2.7,5.2-4.4,9.2c2.2-3.7,4-6,6-8.1C147.4,22.8,149.4,20.8,152.6,18.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M161.5,23.3c3.2-1.8,6.3-3.8,8.5-5.5c2.2-1.7,3.6-3.2,3.2-4c-0.4-0.8-2.5-0.7-5.2,0.3c-2.8,1-6.2,3-9.1,5.6
						c-2.9,2.7-4.7,4.8-6.5,6.9c-1.7,2.1-3.5,4.3-5.9,7.5c2.8-2.9,5-4.6,7.2-6.1C155.9,26.6,158.1,25.2,161.5,23.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M160,33.4c2.1-0.6,3.9-1.6,5.2-2.7c1.3-1,2-2.1,1.7-3c-0.3-0.8-1.6-1.2-3.4-0.9c-1.7,0.3-3.9,1.2-5.7,2.7
						c-1.8,1.6-2.9,2.8-4,4c-1.1,1.2-2.2,2.4-3.9,4.1c2-1.3,3.5-1.9,4.9-2.5C156.3,34.6,157.8,34.1,160,33.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M207.1,151.4c1.6-3.8,3.4-7.5,4.8-10.2c1.4-2.8,2.4-4.8,1.8-5.4c-0.6-0.6-2.8,0.4-5.2,3c-2.4,2.6-4.7,6.8-5.6,11.4
						c-0.8,4.7-0.7,8-0.3,11.2c0.4,3.2,1,6.4,2.6,10.8c-1.1-4.5-1.1-7.7-0.7-10.8C204.8,158.3,205.5,155.3,207.1,151.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M186.8,193.3c-0.7,3.1-1.2,5.4-1.8,8c-0.5,2.2-0.7,3.9,0,4.4c0.7,0.5,2.3-0.5,3.8-2.8c1.4-2.2,2.6-5.8,2.3-9.3
						c-0.2-3.6-1-6-1.8-8.2c-0.9-2.2-1.9-4.4-3.8-7.3c1.5,3.1,1.8,5.5,2,7.7C187.6,188,187.5,190.3,186.8,193.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M166,263.2c-0.7,3.5-2,6.9-3.1,9.4c-1.1,2.5-2,4.4-1.3,5c0.6,0.6,2.7-0.2,4.9-2.8c1.1-1.3,2.1-2.9,2.8-4.9
						c0.7-1.9,1.2-4.2,1.1-6.4c0-2.3-0.4-4.1-0.9-5.8c-0.5-1.6-1-3-1.7-4.3c-1.4-2.6-3-5.2-6.2-8.1c2.8,3.3,3.8,6,4.4,8.7
						c0.3,1.3,0.4,2.7,0.5,4.2C166.5,259.8,166.4,261.4,166,263.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M249.5,317.1c1.2-0.7,2.4-1.2,3.5-1.4c0.6-0.1,1.1-0.1,1.6-0.1c0.5,0,1,0.2,1.5,0.3c2,0.7,4,2.6,5.2,4.8
						c0.3,0.5,0.5,1.1,0.8,1.7c0.2,0.6,0.4,1.2,0.5,1.8c0.2,1.2,0.3,2.3,0.2,3.3c-0.2,1.9-1.3,3.4-2.5,4.1c-0.1,0.1-0.3,0.2-0.4,0.2
						c-0.1,0.1-0.3,0.1-0.4,0.2c-0.3,0.1-0.6,0.1-0.9,0.2c-0.7,0-1.3-0.1-1.8-0.4c-0.5-0.2-0.9-0.6-1.2-0.9
						c-0.3-0.4-0.5-0.9-0.5-1.4c-0.1-1.1,0.1-2,0.4-2.8c0.3-0.7,0.6-1.3,0.4-1.7c-0.1-0.4-0.8-0.7-2-0.3c-1.1,0.4-2.7,1.6-3.5,3.8
						c-0.4,1.1-0.6,2.5-0.3,4.1c0.3,1.6,1.1,3.2,2.4,4.5c1.3,1.3,3,2.2,4.9,2.7c1,0.2,2,0.3,3.1,0.3c0.5,0,1.1-0.1,1.7-0.2
						c0.5-0.1,1.1-0.3,1.6-0.4c4.2-1.5,7.4-5.3,8.5-10c0.5-2.3,0.6-4.6,0.2-6.8c-0.2-1.1-0.4-2.2-0.8-3.3c-0.4-1.1-0.8-2.2-1.4-3.2
						c-1.1-2.1-2.5-4-4.4-5.6c-1.8-1.6-4.1-3-6.7-3.8c-2.6-0.8-5.5-0.8-8.1-0.1c-2.6,0.7-4.8,2-6.7,3.5c-3.8,3.3-5.9,7.3-7.1,11
						c-1.1,3.8-1.4,7.4-1.1,10.7c0.2,3.3,1,6.4,1.9,9.1c1,2.8,2.1,5.4,3.5,7.8c1.3,2.5,2.7,4.9,4.3,7.3c1.5,2.4,3.1,4.8,4.9,7.4
						c3.6,5,7.8,10.5,13.5,16.3c-5.3-6.2-9-11.9-12.1-17.2c-1.5-2.7-2.8-5.2-4-7.8c-1.2-2.5-2.3-5-3.2-7.5c-0.9-2.5-1.7-5-2.2-7.6
						c-0.5-2.6-0.7-5.2-0.6-7.9c0.2-2.7,0.8-5.5,2-8.1c0.6-1.3,1.3-2.5,2.2-3.7C247.3,318.8,248.3,317.8,249.5,317.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M264.6,361c0.7-2.6,1-4.8,0.9-6.9c-0.1-2.1-0.6-4.1-2-5.2c-0.7-0.5-1.7-0.6-2.7-0.3c-1,0.3-2.1,1.1-3.1,2.3
						c-1,1.2-1.7,2.8-2.1,4.7c-0.4,1.9-0.3,3.9,0.3,5.9c0.6,2,1.4,3.6,2.2,4.9c0.8,1.3,1.7,2.3,2.6,3.3c1.8,1.9,3.7,3.6,6.7,5.5
						c-2.3-2.7-3-5-3.3-7.2C263.8,365.9,263.9,363.7,264.6,361z"
                    />
                  </g>
                  <g>
                    <path
                      d="M284.7,370c-3.2,6-5.2,12.1-7.6,17c-1.2,2.5-2.4,4.7-3.7,6.6c-0.7,0.9-1.4,1.8-2.1,2.6c-0.7,0.8-1.4,1.4-2.1,2
						c-1.4,1.1-2.5,1.5-3.5,1.7c-0.3,0.1-0.8,0-1.2-0.1c-0.5-0.2-0.9-0.4-1.2-0.7c-0.7-0.5-1.1-1.1-1.5-1.7
						c-0.3-0.6-0.5-1.1-0.9-1.3c-0.2-0.1-0.4-0.1-0.7,0c-0.3,0.1-0.6,0.4-0.9,0.8c-0.6,0.9-1,2.7-0.3,4.6c0.3,1,0.9,1.9,1.8,2.8
						c0.9,0.9,2.1,1.8,3.7,2.2c1.4,0.4,3,0.5,4.6,0.3c1.6-0.2,3.1-0.7,4.5-1.4c1.4-0.7,2.7-1.6,3.9-2.6c1.2-1,2.3-2.1,3.4-3.3
						c2.1-2.4,3.9-5.2,5.3-8.2c2.9-5.9,4.6-12.1,6.6-17.7c1.1-2.9,2.2-5.3,3.6-7.5c1.4-2.2,2.8-4.2,4.4-5.9c0.7-0.9,1.6-1.7,2.4-2.5
						c0.9-0.7,1.7-1.5,2.5-2.2c0.4-0.3,0.9-0.7,1.3-1c0.4-0.3,0.9-0.6,1.3-0.9c0.9-0.6,1.8-1.2,2.8-1.7c3.8-2.2,8-3.8,13-4.7
						c1.2-0.2,2.5-0.4,3.9-0.4c1.3-0.1,2.7-0.1,4.1,0.1c2.8,0.3,5.9,1.2,8.4,3.6c-2.3-2.6-5.4-3.8-8.3-4.4c-1.5-0.3-2.9-0.5-4.3-0.6
						c-1.4-0.1-2.7,0-4.1,0c-5.2,0.3-9.9,1.3-14.4,3c-1.1,0.4-2.2,0.9-3.3,1.4c-0.5,0.3-1.1,0.5-1.6,0.8c-0.5,0.3-1.1,0.6-1.6,0.9
						c-1.1,0.6-2.1,1.3-3.2,2c-1,0.8-2.1,1.5-3.2,2.3c-2.1,1.7-4.2,3.7-6.2,6C288.2,364.1,286.3,366.9,284.7,370z"
                    />
                  </g>
                  <g>
                    <path
                      d="M311.3,353.5c-2.3,1-4.3,2.4-5.9,4.2c-1.6,1.7-2.9,3.8-3.4,5.9c-0.6,2.1-0.3,4.2,0.4,5.5c0.7,1.3,1.7,1.7,2.5,1.5
						c0.9-0.2,1.6-0.8,2.3-1.4c0.7-0.6,1.5-1.2,2.3-1.9c0.9-0.7,1.8-1.6,3-2.7c1.2-1.1,2.4-2.4,3.6-3.7c2.6-2.8,4.9-4.5,7.5-5.8
						c1.3-0.6,2.7-1.3,4.3-1.7c1.6-0.4,3.5-0.7,5.7-0.7c-2.2-0.6-4.1-0.9-5.9-1.1c-1.8-0.2-3.4-0.3-5-0.3c-1.6,0-3.3,0.1-5.1,0.4
						C315.7,352,313.7,352.5,311.3,353.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M333.5,332.9c0.1-2-0.4-3.5-1.3-5c-0.9-1.4-2.2-2.8-3.9-3.1c-0.9-0.2-1.7,0-2.5,0.5c-0.8,0.5-1.5,1.4-1.9,2.6
						c-0.4,1.2-0.6,2.6-0.4,4.2c0.3,1.5,0.9,3.1,2,4.3c2.2,2.6,4.3,3.7,6.2,4.6c1.9,0.9,3.9,1.5,6.5,2.1c-2.2-1.6-3.2-3.2-3.8-4.8
						C333.7,336.7,333.4,335,333.5,332.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M523.9,354.8c0.8-4,0.7-8.3-0.4-12.2c-1-3.9-2.9-7.6-5.4-10.8c-2.5-3.2-5.7-5.8-9.2-7.6c-3.5-1.8-7.3-3-11-3.4
						c-3.8-0.4-7.6-0.2-11.2,0.8c-3.6,0.9-7.2,2.6-10.1,5.5c-1.4,1.4-2.6,3.2-3.4,5c-0.8,1.8-1.3,3.7-1.4,5.5
						c-0.3,3.7,0.5,7.3,2.3,10.3c1.8,3,4.4,5.2,7.1,6.5c2.7,1.3,5.5,1.8,8.1,1.8c2.6-0.1,5-0.8,7-1.8c2-1,3.5-2.4,4.6-3.7
						c2.3-2.7,3.1-5.3,3.4-7.1c0.2-1.8-0.1-2.8-0.5-2.8c-0.5-0.1-1,0.7-1.9,1.9c-0.9,1.2-2.2,3-4.3,4.5c-1,0.7-2.3,1.4-3.7,1.8
						c-1.4,0.4-2.9,0.6-4.5,0.4c-1.6-0.2-3.2-0.7-4.7-1.7c-1.5-0.9-2.7-2.2-3.4-3.7c-0.7-1.5-1-3.5-0.7-5.3c0.1-0.9,0.4-1.8,0.8-2.6
						c0.4-0.8,0.9-1.4,1.6-2.1c2.8-2.6,8.4-3.9,13.5-3.2c5.3,0.6,10.4,3.1,13.9,7.3c3.5,4.2,5.4,9.9,4.7,15.4
						c-0.8,5.7-4.1,10.7-8.1,14.1c-4.1,3.4-9,5.5-13.8,6.4c-4.9,1-9.8,1-14.5,0.4c-4.7-0.6-9.3-1.9-13.8-3.5
						c-4.5-1.6-8.9-3.6-13.5-5.8c-4.5-2.2-9.2-4.6-14.3-6.8c-5.1-2.2-10.8-4.1-17.1-4.8c-3.1-0.3-6.4-0.3-9.8,0.1
						c-1.7,0.3-3.4,0.7-5,1.2c-1.7,0.6-3.3,1.3-4.9,2.2c1.6-0.8,3.3-1.5,5-2c1.7-0.5,3.4-0.8,5-1c3.3-0.3,6.6-0.2,9.6,0.3
						c6.2,0.9,11.5,3.2,16.4,5.6c4.9,2.4,9.4,5.1,13.8,7.7c4.4,2.5,8.8,4.9,13.3,7c4.6,2,9.3,3.8,14.4,4.8
						c5.1,1.1,10.6,1.5,16.4,0.8c5.7-0.7,11.9-2.6,17.5-6.7c2.7-2,5.3-4.5,7.5-7.6C521.4,362.6,523.1,358.9,523.9,354.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M447.8,308.1c-3.2-3.2-7.6-5.5-12.3-6.1c-4.7-0.7-9.8,0.3-13.9,3.4c-2,1.5-3.5,3.4-4.6,5.6c-1.1,2.1-1.7,4.5-1.7,7
						c0,0.6,0.1,1.2,0.1,1.8c0.1,0.6,0.2,1.2,0.4,1.8c0.3,1.2,0.9,2.2,1.4,3.2c1.2,1.9,2.7,3.4,4.5,4.5c1.8,1.1,3.7,1.7,5.7,1.8
						c1.9,0.1,3.9-0.4,5.4-1.3c1.5-0.9,2.6-2,3.3-3.1c0.8-1.1,1.2-2.3,1.5-3.3c0.5-2.1,0-3.9-0.6-4.9c-0.3-0.5-0.6-0.8-0.9-1
						c-0.3-0.2-0.5-0.2-0.7-0.1c-0.2,0.1-0.4,0.3-0.5,0.6c-0.1,0.3-0.2,0.6-0.4,1c-0.3,0.8-0.6,1.7-1.4,2.6c-0.7,0.9-1.9,1.8-3.1,2
						c-1.1,0.2-2.3-0.1-3.5-1.2c-0.6-0.5-1.1-1.2-1.4-2c-0.1-0.4-0.3-0.7-0.3-1.1c0-0.2,0-0.3,0-0.5c0-0.2,0-0.4,0-0.6
						c0.1-1.6,1.2-3.6,2.9-4.8c1.6-1.2,4-1.9,6.7-1.6c2.7,0.2,5.3,1.3,7.7,3.1c2.4,1.8,4.2,4.3,5.3,6.8c1.1,2.5,1.5,5.1,1.3,7.6
						c-0.3,5-2.7,9.6-6.2,12.9c-3.5,3.3-8.6,5.3-14.4,5.7c-5.9,0.5-12.6-0.9-20.1-3.5c7.3,3.1,13.9,5,20.1,5.2
						c6.2,0.2,12-1.1,16.8-4.5c2.4-1.7,4.5-3.8,6.2-6.3c1.7-2.5,3.1-5.4,3.7-8.8C456.1,323.3,454.5,314.8,447.8,308.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M458.9,328.3c-2.4,2.5-3.5,5.9-3.3,8.5c0.2,2.7,1.6,4.5,3.3,4.7c1.8,0.2,3.4-0.9,4.8-2.5c1.3-1.6,2.3-3.7,2.8-6.3
						c0.5-2.7,1-4.7,1.8-6.7c0.8-1.9,1.8-3.8,4.1-6.1c-2.9,1.3-4.9,2.4-6.9,3.5C463.4,324.6,461.4,325.8,458.9,328.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M463.1,362.7c0.5,3.2,2.7,5.9,5,6.9c2.3,1.1,4.4,0.7,5.5-0.7c1-1.4,1-3.4,0.6-5.1c-0.5-1.8-1.4-3.3-3.1-4.8
						c-1.7-1.6-3-3-3.9-4.5c-1-1.6-1.8-3.4-1.9-6.2c-0.9,2.7-1.4,4.7-1.9,6.8C462.9,357.2,462.6,359.4,463.1,362.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M490.6,369c1.7,0.6,3.5,0.7,5.2,0.5c1.7-0.3,3.1-0.9,4.2-1.7c2.3-1.7,2.9-3.9,2-5.4c-0.9-1.5-2.7-2.2-4.6-2.6
						c-1.9-0.3-3.8-0.2-6.1,0.5c-2.3,0.7-4.3,0.9-6.3,0.8c-2-0.2-4-0.7-6.5-2.5c1.7,2.6,3.2,4.3,4.9,5.9
						C485.2,366,487.1,367.7,490.6,369z"
                    />
                  </g>
                  <g>
                    <path
                      d="M448.5,349.2c-1.6-0.6-2.7-0.5-4,0.1c-1.3,0.6-2.7,1.6-3.5,3.2c-0.4,0.8-0.5,1.7-0.2,2.5c0.2,0.9,0.8,1.7,1.7,2.4
						c0.9,0.7,2.1,1.2,3.5,1.3c1.3,0.1,2.8-0.1,4.1-0.8c2.7-1.5,3.9-3.1,5-4.5c1.1-1.5,1.9-3.1,2.9-5.2c-1.8,1.5-3.4,1.9-4.9,1.9
						C451.6,350.1,450.2,349.9,448.5,349.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M441,389.9c-1.2,1.3-3,2.2-4.8,2.4c-1.7,0.3-3.6,0-5.4-0.8c-1.8-0.7-3.5-2-4.7-3.3c-1.2-1.3-1.9-2.6-2-3.5
						c-0.2-1,0.1-2.1,0.4-2.7c0.3-0.6,0.9-1,1.7-1.1c0.8-0.1,1.5,0,2.1,0.2c0.6,0.2,1.1,0.4,1.5,0.3c0.4-0.1,0.8-0.6,0.6-1.7
						c-0.2-1-1-2.4-2.7-3.4c-0.8-0.5-1.9-0.8-3.2-0.9c-1.3-0.1-2.7,0.3-4,1.1c-2.7,1.6-4.5,4.5-4.8,8c-0.3,3.6,1.1,7,3.1,9.7
						c2,2.7,4.7,4.9,8,6.4c3.3,1.5,7.3,2.2,11.4,1.2c3.9-0.9,7.6-3.3,9.9-6.8c0.6-0.9,1.1-1.9,1.4-2.9c0.4-1,0.6-2,0.7-2.9
						c0.3-1.9,0.2-3.7-0.1-5.3c-0.6-3.2-1.9-5.9-3.3-8.1c-1.5-2.2-3.2-4.1-5-5.6c-1.8-1.5-3.7-2.8-5.7-3.8c-4-2.1-8.4-3.3-13.2-3.8
						c-4.9-0.5-10.3-0.3-16.5,0.5c12.6-0.7,21.4,1.7,27.8,6.5c3.2,2.4,5.8,5.4,7.4,9.2c0.8,1.9,1.3,3.9,1.3,5.9
						C442.8,386.8,442.3,388.7,441,389.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M457.3,391.4c0,2.2,1.1,3.9,2.5,4.7c1.5,0.9,3.3,0.9,4.8,0c1.5-0.9,2.4-2.4,2.6-4c0.2-1.5-0.4-3.1-1.9-4.3
						c-1.5-1.2-2.7-2.1-3.9-3c-1.1-0.9-2.1-2-3.1-3.7c0,2-0.2,3.5-0.4,5C457.7,387.6,457.4,389.2,457.3,391.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M431.7,410.6c-2.8,1.3-4.6,3.9-5.2,6.2c-0.6,2.3,0.1,4.3,1.6,5.2c1.5,0.9,3.5,0.6,5.2-0.3c1.7-0.9,3.3-2.4,4.4-4.7
						c1.1-2.3,2-4,3.1-5.6c1.1-1.6,2.4-3.1,4.8-4.7c-2.7,0.6-4.7,1-6.8,1.5C436.6,408.7,434.5,409.2,431.7,410.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M389.6,226.7c1.7,0.3,3.4,0.1,5-0.1c1.5-0.3,2.9-0.8,4-1.3c2.2-1.1,3.4-2.3,3.2-3.1c-0.3-0.9-2-1.1-4.3-0.9
						c-2.3,0.2-5.1,0.7-7.8,1.1c-2.8,0.4-4.9,0.2-6.8-0.5c-1-0.3-1.9-0.8-2.9-1.6c-0.9-0.7-1.9-1.8-2.6-3.3c0.4,1.6,1.1,2.9,1.9,3.9
						c0.8,1,1.7,1.9,2.6,2.6c0.9,0.7,2,1.4,3.2,1.9C386.4,226,387.9,226.5,389.6,226.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M392.5,238.2c1.9,2.5,3.7,4.8,5.3,6.6c1.6,1.7,2.9,2.9,3.7,2.5c0.8-0.3,0.8-2.2-0.1-4.7c-1-2.4-3-5.4-5.8-7.5
						c-2.9-2.2-5-3.2-7.2-4.2c-2.2-1-4.4-1.9-7.8-2.8c3.1,1.5,5,2.9,6.7,4.4C389.1,234,390.6,235.6,392.5,238.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M351.7,217.7c-0.7,2.4-1.3,4.7-1.7,6.6c-0.4,1.9-0.5,3.4,0.2,3.9c0.7,0.5,2.2-0.2,3.6-2.1c1.4-1.8,2.4-4.8,2.3-7.9
						c-0.2-3.1-0.8-5.1-1.4-7.1c-0.7-1.9-1.5-3.8-3.1-6.3c1,2.7,1.2,4.7,1.2,6.6C352.7,213.3,352.5,215.2,351.7,217.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M446.3,284.3c3.7-0.6,7.2-2.4,9.4-4.3c2.2-1.9,3-3.7,2.5-4.4c-0.6-0.7-2.4-0.1-4.7,0.8c-2.3,1-5.3,2.3-8.3,3.6
						c-3.2,1.4-5.5,2-7.9,2.5c-2.4,0.4-5,0.7-8.7,0.1c3.6,1.1,6.2,1.5,8.8,1.8C439.8,284.8,442.4,284.9,446.3,284.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M455.1,296.1c2.5,0.2,5-0.7,6.5-1.9c1.5-1.2,2-2.6,1.4-3.3c-0.6-0.7-1.8-0.7-3.3-0.4c-1.5,0.2-3.2,0.7-5.1,1.3
						c-1.9,0.6-3.4,0.9-4.9,0.9c-1.5,0-3.1-0.1-5.3-1.1c1.9,1.4,3.3,2.3,4.9,3C450.8,295.2,452.5,295.8,455.1,296.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M512.7,278.9c-0.8,1.7-2.2,3.2-3.7,4.4c-1.5,1.2-3.3,2.3-4.8,3.4c-1.5,1.1-2.8,2.1-3.5,3c-0.8,0.9-1,1.6-0.8,2
						c0.3,0.4,1,0.3,2,0.1c1.1-0.2,2.5-0.7,4.2-1.4c1.7-0.7,3.8-1.6,5.9-3.2c2-1.6,4-3.9,4.8-6.9c0.4-1.5,0.4-3,0.2-4.4
						c-0.3-1.3-0.8-2.5-1.4-3.4c-1.2-1.9-2.8-3.1-4.4-4c-3.3-1.8-7-2.2-12.2-2.6c2.6,0.5,4.7,0.9,6.5,1.6c1.8,0.7,3.3,1.5,4.5,2.6
						c1.2,1.1,2.2,2.4,2.8,3.9C513.5,275.5,513.5,277.2,512.7,278.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M421,118.9c2.1-3.8,2.7-8.3,2.3-11.5c-0.4-3.3-1.6-5.2-2.4-5c-0.9,0.2-1.3,2.2-1.8,4.9c-0.5,2.8-1.2,6.3-2.2,9.9
						c-1.1,3.7-2.3,6.2-3.8,8.5c-1.5,2.3-3.4,4.6-6.9,6.9c3.8-1.9,6.2-3.7,8.4-5.7C416.7,125,418.8,122.8,421,118.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M423,162.8c5.4,0.3,10.9,0.4,15,0.3c4.1-0.1,6.9-0.6,7-1.5c0.1-0.9-2.6-2-6.7-2.7c-4.2-0.7-9.8-1.1-15.4-0.5
						c-5.7,0.6-9.6,1.3-13.5,2.1c-3.9,0.8-7.8,1.6-13.5,3c5.8-0.8,9.8-1,13.7-1.1C413.6,162.4,417.4,162.5,423,162.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M428.4,172.9c6.4,1.8,13.2,1.4,13.5-0.3c0.2-1.7-6.2-4-12.9-4c-6.9,0-9.5,0.3-16.5-0.1
						C419.4,169.9,421.8,171,428.4,172.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M465.2,185.4c3.3-1.4,6.4-3,8.7-4.4c2.3-1.4,3.8-2.7,3.5-3.5c-0.3-0.8-2.3-1-5.1-0.3c-2.8,0.7-6.3,2.2-9.3,4.5
						c-3.1,2.4-5,4.3-6.8,6.1c-1.8,1.9-3.7,3.9-6.2,6.8c2.9-2.6,5.1-4,7.4-5.3C459.5,188,461.8,186.9,465.2,185.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M479.4,189.5c3.5-0.2,7-0.5,9.6-1c2.6-0.4,4.5-1,4.6-1.9c0.1-0.9-1.7-1.8-4.6-2.3c-2.8-0.5-6.7-0.3-10.4,0.8
						c-3.7,1.2-6.2,2.3-8.5,3.5c-2.4,1.2-4.7,2.5-8,4.6c3.5-1.6,6.1-2.3,8.6-2.8C473.3,190.1,475.8,189.7,479.4,189.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M496,177.4c5.3-1.4,9.4-5.2,8.5-6.8c-0.9-1.5-6-0.2-10.3,2.8c-2.2,1.5-3.8,2.6-5.3,3.7c-1.6,1-3.2,2-5.8,3.2
						c2.7-0.7,4.6-1.1,6.5-1.5C491.4,178.5,493.3,178.1,496,177.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M508.6,181.3c2.3,0.5,4.6,0.6,6.3,0.4c1.8-0.2,3.1-0.7,3.3-1.5c0.2-0.9-0.9-1.9-2.7-2.6c-1.8-0.7-4.4-1.1-6.9-0.6
						c-2.6,0.4-4.3,1-6,1.5c-1.8,0.6-3.6,1.2-5.8,2c2.6-0.3,4.3-0.3,6-0.1C504.5,180.5,506.2,180.8,508.6,181.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M515.5,191.4c5.5,1.8,11.5,1.5,11.8-0.3c0.3-1.7-5.2-4-11-4.1c-6-0.1-8.3,0.3-14.4-0.2
						C507.8,188.4,509.8,189.5,515.5,191.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M537.8,184.7c2.8,0.1,5.6-0.6,7.4-1.6c1.9-1,2.9-2.2,2.6-3c-0.3-0.8-1.8-1.1-3.7-1c-2,0.1-4.4,0.5-6.9,1.3
						c-2.5,0.8-4.3,1.3-6.2,1.7c-1.9,0.4-3.8,0.6-6.6,0.6c2.8,0.6,4.7,1,6.6,1.3C533,184.3,535,184.6,537.8,184.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M537.5,176.3c2.8-0.1,5.5-1.1,7.4-2.2c1.8-1.1,2.8-2.4,2.4-3.2c-0.4-0.8-1.9-1-3.8-0.7c-2,0.2-4.4,0.9-6.9,1.9
						c-2.5,1-4.3,1.6-6.1,2.2c-1.8,0.5-3.7,1-6.6,1.2c2.9,0.3,4.8,0.5,6.8,0.7C532.6,176.2,534.6,176.4,537.5,176.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M535.8,166.5c2.7-0.6,5.2-1.8,6.8-3c1.7-1.3,2.5-2.6,2.1-3.4c-0.8-1.6-6.1-0.4-10.6,2.3c-2.3,1.4-4,2.4-5.6,3.4
						c-1.7,0.9-3.4,1.8-6.1,2.9c2.8-0.5,4.7-0.8,6.7-1C531.1,167.3,533,167.1,535.8,166.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M557.3,160.8c1.9-0.2,3.7-0.8,5-1.5c1.3-0.7,2.2-1.5,2.2-2.4c-0.1-0.9-1.2-1.6-2.9-1.7c-1.7-0.2-3.9,0.3-5.8,1.5
						c-1.9,1.2-3.1,2.3-4.2,3.4c-1.1,1.1-2.2,2.1-3.7,3.7c1.8-1.2,3.2-1.7,4.6-2.1C553.9,161.3,555.3,161,557.3,160.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M366.2,478.6c3.4-1,6.8-2.1,9.3-3.2c2.5-1.1,4.2-2.1,4-3c-0.1-0.9-2.1-1.3-5-1.1c-2.9,0.3-6.7,1.3-10,3.2
						c-3.4,2-5.6,3.6-7.7,5.3c-2.1,1.7-4.2,3.4-7,6.1c3.2-2.3,5.6-3.5,8-4.5C360.2,480.5,362.6,479.6,366.2,478.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M377.5,483.6c2.3,0.1,4.5-0.2,6.2-0.7c1.7-0.5,2.9-1.2,2.9-2.1c0-0.9-1.2-1.7-3.1-2.1c-1.9-0.4-4.4-0.3-6.8,0.6
						c-2.4,0.9-4,1.7-5.6,2.5c-1.7,0.9-3.3,1.8-5.3,2.9c2.4-0.7,4.2-1,5.9-1.1C373.5,483.5,375.1,483.5,377.5,483.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M400.9,489.4c2.5,0.8,5,1.3,7,1.3c2,0.1,3.5-0.3,3.7-1.1c0.3-0.8-0.8-2-2.7-2.9c-1.9-0.9-4.6-1.6-7.4-1.6
						c-2.9,0-4.8,0.3-6.8,0.5c-2.1,0.2-4.1,0.5-6.7,0.8c2.8,0.2,4.7,0.6,6.6,1.1C396.6,487.9,398.4,488.5,400.9,489.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M404,480.2c5.8,1.9,12.1,1.7,12.4,0c0.3-1.7-5.5-4.1-11.6-4.3c-6.3-0.2-8.7,0.1-15.2-0.5
						C395.8,477.1,398,478.2,404,480.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M405.2,471.3c1.9,0,3.8-0.8,4.9-1.8c1.1-1,1.6-2.1,1.2-2.9c-0.4-0.8-1.5-1.1-2.8-1c-1.3,0.1-2.9,0.5-4.3,1.4
						c-1.5,0.9-2.6,1.5-3.8,2c-1.2,0.5-2.4,0.9-4.3,1.2c1.7,0.3,3,0.5,4.5,0.7C401.8,471.2,403.2,471.4,405.2,471.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M326.4,598.7c3.6-0.2,7.2-0.8,10.9-1.8c3.6-1,7.3-2.7,10.5-5.2c1.6-1.3,3.1-2.8,4.3-4.4c1.2-1.7,2.2-3.5,3-5.4
						c1.5-3.8,2.1-7.8,1.7-11.8c-0.2-2-0.7-3.9-1.4-5.8c-0.7-1.9-1.7-3.6-2.8-5.1c-2.2-3.1-5.1-5.5-8.2-7.3c-1.6-0.8-3.2-1.5-4.9-2
						c-1.7-0.5-3.4-0.7-5.1-0.8c-3.4-0.1-6.7,0.5-9.7,1.8c-2.9,1.4-5.5,3.4-7.2,6.1c-1.7,2.6-2.4,5.5-2.4,8.1c0,2.7,0.8,5.3,2.2,7.2
						c0.7,1,1.5,1.8,2.4,2.5c0.9,0.6,1.8,1.1,2.7,1.4c3.6,1.3,6.6,0.5,8.1-0.6c1.6-1.1,1.9-2.1,1.6-2.4c-0.3-0.4-1.2-0.1-2.5,0
						c-0.7,0-1.5,0-2.4-0.2c-0.9-0.2-1.9-0.6-2.8-1.2c-0.9-0.6-1.7-1.5-2.1-2.5c-0.5-1.1-0.6-2.4-0.4-3.8c0.2-1.4,0.8-2.9,1.8-4.1
						c0.9-1.1,2.4-2.1,4.1-2.7c1.7-0.6,3.8-0.8,5.8-0.6c1,0.1,2,0.3,3,0.7c1,0.4,2,0.8,2.9,1.4c1.9,1.2,3.6,2.7,4.9,4.6
						c1.3,1.9,2,4,2.3,6.3c0.2,2.3-0.1,4.8-1,7.1c-0.4,1.1-1,2.2-1.7,3.2c-0.7,1-1.5,1.8-2.5,2.7c-3.9,3.3-9.9,5-16.1,5.8
						c-6.3,0.8-12.3,0.4-17.8-0.8c-5.5-1.2-10.3-3.2-14.8-5.6c-9-4.9-16.6-10.8-25.2-15.5c-8.6-4.7-18.1-7.9-29-9.2
						c-5.4-0.6-11.2-0.8-17.4-0.2c-6.1,0.6-12.7,2-19.4,4.5c6.8-2.2,13.3-3.3,19.4-3.6c6.1-0.3,11.8,0.2,17.1,1.1
						c10.5,1.9,19.4,5.7,27.3,10.8c3.9,2.6,7.7,5.5,11.5,8.5c3.9,3,7.9,6.1,12.5,9.1c4.6,3,9.9,5.7,16,7.5
						C312.1,598.2,319,599.2,326.4,598.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M279.9,561.6c4.2-1.3,8.3-3.6,11.6-6.8c1.7-1.6,3.1-3.5,4.3-5.6c1.2-2.1,2-4.4,2.3-7c0.7-5.2-1.5-10.8-6.2-13.8
						c-2.4-1.4-4.9-1.9-7.1-1.8c-2.2,0.1-4.1,0.5-6,1.3c-1.8,0.8-3.5,2-4.9,3.6c-1.3,1.6-2.1,3.7-2.1,5.7c0,3.9,2.4,6.6,4.7,7.6
						c2.3,1,4.2,0.5,5.3-0.1c1.1-0.6,1.3-1.3,1.2-1.6c-0.2-0.4-0.8-0.6-1.5-0.9c-0.7-0.3-1.7-0.8-2.4-1.6c-0.3-0.4-0.5-0.8-0.6-1.4
						c-0.1-0.5-0.1-1.1,0.1-1.5c0.2-0.4,0.5-0.7,1-1.1c0.5-0.3,1.2-0.7,2.1-0.9c0.9-0.2,2-0.3,2.9-0.2c1,0.1,1.7,0.4,2.2,0.8
						c0.5,0.4,0.9,1,1.2,1.8c0.2,0.4,0.3,0.9,0.3,1.3c0,0.4,0,0.9,0,1.5c-0.3,2.1-1.7,4.8-3.7,7c-2,2.2-4.7,4.1-7.8,5.4
						c-3.1,1.4-6.4,2.2-9.5,2.6c-3.2,0.4-6.2,0.5-9.2,0.4c-5.9-0.1-11.4-0.8-17-1.1c-5.6-0.3-11.4-0.3-17.6,0.8
						c-6.2,1.1-13,3.1-20.3,7c7.5-3.4,14.4-4.8,20.5-5.3c6.2-0.4,11.6,0.2,17,1.3c5.4,1,10.7,2.4,16.9,3.4c3.1,0.5,6.5,0.9,10.2,0.9
						C271.4,563.3,275.5,563,279.9,561.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M303.9,501.7c1.1-5.3,2.3-10.4,3.1-14.4c0.8-4.1,1.2-7.3-0.3-8.3c-1.4-1-4.6,0.8-7.4,4.9c-1.4,2.1-2.6,4.7-3.4,7.7
						c-0.8,3-1.1,6.3-0.8,9.6c0.3,3.4,0.9,6.2,1.7,8.6c0.7,2.4,1.6,4.5,2.6,6.5c1,2,2,4,3.3,6.1c1.3,2.1,2.8,4.3,4.9,6.8
						c-1.6-2.8-2.6-5.2-3.3-7.5c-0.7-2.3-1.1-4.3-1.3-6.4c-0.2-2.1-0.2-4.1-0.1-6.3C303,506.9,303.3,504.5,303.9,501.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M320.9,499.7c0.3-3.4-0.1-6.9-1.1-10c-0.9-3.1-2.4-5.8-4.1-7.9c-1.6-2.1-3.4-3.4-4.9-4.1c-1.5-0.7-2.6-0.7-3.3-0.1
						c-1.3,1.3-0.3,4.3,0.8,8.2c0.6,2,1.2,4.2,1.9,6.6c0.6,2.4,1.3,5,1.8,7.7c0.6,2.8,0.8,5.2,0.9,7.5c0.1,2.3,0,4.4-0.3,6.5
						c-0.5,4.2-1.6,8.5-4.5,14.4c3.8-5.3,6.1-9.2,8.1-13.3c1-2.1,1.9-4.2,2.7-6.7C319.9,506,320.6,503.2,320.9,499.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M312.1,497.4c0.7-3.6,0.6-7.3,0-10.1c-0.6-2.7-1.6-4.4-2.5-4.3c-0.9,0.1-1.4,1.9-1.7,4.6c-0.3,2.6-0.4,6-0.2,9.5
						c0.2,3.6,0.2,6.1,0.2,8.6c-0.1,2.5-0.3,5.1-1.2,8.8c1.3-3.6,2.2-6,3-8.5C310.5,503.6,311.3,501.1,312.1,497.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M310,493.7c0.9-3.1,1-6.3,0.6-8.7c-0.4-2.4-1.2-4-2.1-3.9c-0.9,0-1.6,1.6-2.1,3.8c-0.5,2.3-0.8,5.2-0.8,8.3
						c0,3.1,0,5.3-0.2,7.5c-0.2,2.2-0.4,4.4-1.2,7.6c1.2-2.9,2.1-5,3.1-7.2C308.2,499,309,496.9,310,493.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M302.6,488.7c1.5-2.6,2.9-5.1,3.9-7.2c1-2,1.6-3.6,1-4.3c-0.6-0.6-2.4,0-4.3,1.7c-1.9,1.8-3.9,4.8-4.7,8.1
						c-0.8,3.5-0.9,5.9-0.8,8.3c0.1,2.4,0.3,4.7,1,8.1c-0.2-3.4,0.2-5.7,0.8-7.9C300.3,493.4,301.1,491.4,302.6,488.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M305.8,559.5c1.5-1.7,2.7-3.7,3.5-5.7c0.8-2,1.3-4,1.4-5.7c0.3-3.4-0.5-5.7-1.4-5.7c-0.9,0-1.6,2-2.7,4.8
						c-1.1,2.8-2.5,6.3-4.4,9.7c-2,3.5-3.9,5.6-6.1,7.6c-2.2,1.9-4.8,3.6-9.1,4.9c4.4-0.8,7.4-1.9,10.1-3.3c1.4-0.7,2.7-1.5,4.2-2.5
						C302.7,562.6,304.2,561.3,305.8,559.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M341,541.1c4.9-0.6,9.7-1.1,13.4-1.5c3.7-0.4,6.3-0.9,6.4-1.8c0.1-0.8-2.4-2-6.4-2.4c-3.9-0.5-9.3-0.1-14.4,1.4
						c-5.2,1.6-8.5,3.3-11.7,5c-3.2,1.8-6.4,3.7-10.8,6.9c4.6-2.8,8.1-4.1,11.5-5.2C332.6,542.5,336,541.7,341,541.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M361.1,546c3.3,0.2,6.6,0,9.1-0.3c2.5-0.4,4.3-1,4.3-1.9c0.1-0.9-1.6-1.8-4.2-2.3c-2.6-0.5-6.1-0.6-9.6,0.1
						c-3.5,0.7-5.9,1.5-8.2,2.3c-2.5,0.8-4.8,1.6-8.1,2.8c3.6-0.7,6-0.8,8.5-0.9C355.2,545.7,357.6,545.8,361.1,546z"
                    />
                  </g>
                  <g>
                    <path
                      d="M368.5,552.6c2.6,0.8,5.2,1.2,7.2,1.2c2,0,3.6-0.3,3.8-1.2c0.2-0.8-0.9-2-2.8-2.9c-2-0.9-4.8-1.6-7.6-1.5
						c-2.9,0.1-4.9,0.4-6.9,0.7c-2.1,0.3-4.2,0.7-6.9,1.1c2.9,0.1,4.9,0.4,6.8,0.8C364,551.3,365.9,551.8,368.5,552.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M371.3,563.5c1.5,1.5,2.9,2.8,4,3.8c1.2,1,2.2,1.7,3.1,1.5c0.8-0.3,1.2-1.8,0.4-3.8c-0.7-2-2.7-4.1-5.1-5.1
						c-2.5-1.1-4.2-1.3-6-1.6c-1.7-0.3-3.4-0.5-5.9-1c2.3,1,3.8,1.8,5.2,2.7C368.4,561,369.8,562,371.3,563.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M370.1,580.9c0.2,2.4,0.6,4.7,1.1,6.5c0.5,1.8,1.1,3.1,2,3.2c0.9,0.1,1.8-1.2,2.2-3.3c0.4-2.1,0.2-5-1-7.6
						c-1.2-2.6-2.4-4.2-3.6-5.8c-1.2-1.5-2.5-3-4.4-5c1.5,2.3,2.2,4.1,2.7,5.8C369.5,576.6,369.8,578.4,370.1,580.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M364.6,598.8c-0.5,1.7-0.6,3.5-0.3,4.8c0.2,1.4,0.8,2.4,1.7,2.6c0.9,0.2,1.8-0.6,2.5-2c0.6-1.4,1-3.4,0.6-5.3
						c-0.4-2-0.9-3.3-1.3-4.7c-0.5-1.3-0.9-2.6-1.5-4.5c0.1,2-0.1,3.3-0.4,4.7C365.5,595.7,365.1,597,364.6,598.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M353.3,601c-3.3,3.3-5.1,7.7-3.7,8.8c1.4,1.1,5.4-1.9,7.3-6.4c1.9-4.6,2.2-6.5,4.2-11.1
						C358.2,596.4,356.7,597.5,353.3,601z"
                    />
                  </g>
                  <g>
                    <path
                      d="M340.2,602.7c-2.6,1.1-5,2.5-6.7,3.8c-1.7,1.3-2.7,2.6-2.3,3.4c0.4,0.8,2,0.9,4.2,0.3c2.2-0.6,4.8-1.9,7.1-3.8
						c2.3-2,3.8-3.5,5.2-5c1.5-1.5,2.9-3,5.1-5.1c-2.5,1.7-4.4,2.7-6.2,3.6C344.8,600.8,342.9,601.6,340.2,602.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M316.4,606.9c-2.8,0-5.6,0.4-7.6,1c-2.1,0.6-3.5,1.4-3.5,2.2c0,0.9,1.5,1.6,3.7,1.9c2.2,0.3,5.2,0.1,8.1-0.9
						c2.9-1,4.8-1.9,6.7-2.8c2-1,4-1.9,6.6-3.1c-3,0.8-5,1.1-7.1,1.3C321.3,606.8,319.3,606.9,316.4,606.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M233,569.1c5.4,0.9,10.6,2.2,14.6,3.2c4,1,6.8,1.7,7.2,0.8c0.4-0.7-1.8-2.8-5.8-4.8c-4-1.9-9.8-3.5-15.7-3.6
						c-6,0-10.1,0.7-14.1,1.6c-4,0.9-8,2.1-13.5,4.5c5.7-1.9,9.8-2.4,13.7-2.6C223.5,568,227.4,568.2,233,569.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M251.9,584.4c1.8,1.9,3.6,3.7,5.1,5.1c1.5,1.3,2.8,2.2,3.6,1.8c0.8-0.4,0.8-2.1-0.2-4.2c-1-2.1-3.1-4.6-5.8-6.1
						c-2.8-1.5-4.8-2.2-6.9-2.7c-2.1-0.5-4.1-0.9-7.1-1.1c2.9,0.8,4.7,1.8,6.4,2.9C248.6,581.1,250.1,582.4,251.9,584.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M68,396c1.6-0.2,3.2-0.3,4.9-0.5c1.6-0.1,3.3-0.2,4.8-0.2c3.1,0,6.1,0.2,8.8,0.7c2.7,0.5,5.1,1.4,6.9,2.6
						c0.9,0.6,1.7,1.2,2.3,1.9c0.3,0.3,0.6,0.7,0.9,1.1c0.2,0.4,0.4,0.7,0.6,1.1c0.7,1.6,1.1,3.6,0.9,5.5c-0.1,1-0.3,1.9-0.6,2.8
						c-0.3,0.9-0.8,1.8-1.3,2.6c-1.1,1.7-2.5,3.2-4.2,4.4c-1.7,1.2-3.4,2.1-5.2,2.7c-1.8,0.6-3.7,0.8-5.3,0.7
						c-1.7-0.1-3.2-0.4-4.5-1c-2.5-1-4.3-3.3-5.3-5.4c-1-2.1-1.2-4.1-1.3-5.6c0-0.7-0.1-1.4-0.1-1.9c-0.1-0.5-0.2-0.9-0.5-1.1
						c-0.3-0.2-0.7-0.2-1.2,0c-0.5,0.2-1.1,0.7-1.8,1.5c-0.6,0.8-1.3,1.9-1.7,3.4c-0.4,1.4-0.7,3.2-0.5,5.1c0.1,1.9,0.7,4.1,1.7,6.3
						c0.3,0.5,0.6,1.1,0.9,1.6c0.4,0.5,0.7,1.1,1.2,1.6c0.5,0.5,0.9,1.1,1.4,1.5c0.3,0.2,0.5,0.5,0.8,0.7c0.3,0.2,0.6,0.4,0.8,0.7
						c2.3,1.8,5.2,3,8.2,3.7c3.1,0.7,6.4,0.7,9.7,0.2c3.3-0.6,6.8-1.8,9.9-3.6c3.1-1.9,6.1-4.4,8.5-7.6c1.2-1.6,2.3-3.4,3.1-5.4
						c0.8-2,1.4-4.1,1.7-6.3c0.6-4.4,0.1-9-1.8-13.5c-0.5-1.1-1.1-2.2-1.7-3.2c-0.7-1-1.4-2-2.2-2.9c-0.8-0.9-1.7-1.7-2.6-2.5
						c-0.5-0.4-0.9-0.7-1.4-1.1c-0.5-0.4-1-0.7-1.5-1c-4-2.5-8.2-3.7-12.3-4.3c-4.1-0.6-8.1-0.7-12-0.3c-1.9,0.2-3.8,0.4-5.6,0.7
						c-1.9,0.3-3.7,0.6-5.5,1c-7.6,1.7-14.2,4-20.2,6.7c-6,2.7-11.3,5.7-16.1,8.9c-4.8,3.2-9.1,6.5-13,10.1
						c-3.9,3.5-7.6,7.2-10.9,11c-6.7,7.7-12.6,16.1-18,26.2c-5.4,10-10.4,21.7-14.4,36.1c4.8-14.2,10.6-25.4,16.7-34.7
						c6.1-9.4,12.7-16.9,19.9-23.6c3.6-3.3,7.4-6.3,11.4-9.2c4-2.9,8.4-5.5,13.1-7.9c4.7-2.4,9.8-4.6,15.4-6.4
						C55.2,398.3,61.3,396.8,68,396z"
                    />
                  </g>
                  <g>
                    <path
                      d="M53,455.6c0.5,1.9,0.5,3.8,0.3,5.6c-0.3,1.9-0.8,3.7-1.7,5.4c-0.9,1.7-2,3.1-3.3,4.3c-0.6,0.6-1.3,1.1-2,1.6
						c-0.7,0.5-1.5,0.9-2.3,1.2c-3.2,1.4-6.9,1.6-10,0.9c-1.6-0.3-2.9-0.9-3.9-1.6c-1-0.7-1.6-1.3-2.1-2.1c-0.2-0.4-0.4-0.8-0.5-1.1
						c-0.1-0.3-0.2-0.6-0.1-0.9c0-0.6,0.3-1.5,1-2.3c0.7-0.9,1.6-1.7,2.6-2.4c0.5-0.3,1-0.7,1.5-1c0.5-0.3,0.9-0.5,1.3-0.8
						c0.5-0.3,1-0.6,1.4-0.9c0.2-0.2,0.7-0.2,1.1-0.2c0.4,0.1,0.7,0.2,1,0.4c0.3,0.2,0.5,0.5,0.8,0.6c0.3,0.1,0.7,0.1,1.1-0.4
						c0.4-0.4,0.7-1.4,0.4-2.5c-0.2-1.1-0.9-2.3-2.5-3.3c-1.5-0.9-3.3-1.1-5-1c-0.8,0.1-1.6,0.2-2.4,0.3c-0.8,0.1-1.6,0.3-2.5,0.6
						c-1.7,0.5-3.6,1.3-5.5,2.6c-1.9,1.3-4,3.4-5.2,6.4c-0.6,1.5-1,3.2-1.1,4.8c-0.1,1.7,0.1,3.3,0.6,5c0.5,1.6,1.2,3.2,2.1,4.6
						c0.9,1.5,2.1,2.7,3.3,3.8c2.5,2.2,5.4,3.8,8.4,4.8c6.1,2,13.1,1.9,19.6-0.7c3.2-1.3,6.4-3.2,9-5.8c2.6-2.5,4.7-5.6,6.2-8.9
						c1.5-3.3,2.4-6.9,2.5-10.6c0.2-3.7-0.5-7.5-1.8-10.8c-2.7-7-7.7-12-13.2-14.9c-5.6-2.9-11.3-3.4-16.2-3.1
						c-4.9,0.3-9.3,1.4-13.4,2.8c-4.1,1.3-7.9,3-11.5,4.9c-3.7,1.9-7.1,4.1-10.6,6.6c-3.4,2.5-6.7,5.3-9.9,8.6
						c-3.2,3.3-6.3,6.9-9.3,11.1c-2.9,4.2-5.7,9-8,14.5c2.6-5.3,5.7-9.8,9-13.7c3.2-3.9,6.6-7.1,10-9.9c3.4-2.8,6.9-5.1,10.4-7
						c3.5-1.9,7-3.5,10.6-4.8c3.6-1.2,7.3-2.3,11.1-2.9c3.8-0.7,7.7-1.1,11.5-0.8c3.8,0.3,7.6,1.4,10.5,3.5
						C49.6,448.2,52,451.7,53,455.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M121.8,361.1c10.9,1.6,21.5,3.9,29.6,5.9c0.5,0.1,1,0.2,1.5,0.3c0.5,0.1,1,0.2,1.4,0.4c0.9,0.2,1.8,0.4,2.6,0.6
						c0.8,0.2,1.6,0.3,2.4,0.5c0.7,0.2,1.4,0.3,2,0.4c2.5,0.4,4.2,0.3,4.6-0.5c0.4-0.8-0.5-2.1-2.5-3.9c-0.5-0.4-1.1-0.9-1.7-1.4
						c-0.6-0.5-1.4-0.9-2.1-1.4c-0.8-0.5-1.6-1-2.5-1.5c-0.5-0.2-0.9-0.5-1.4-0.8c-0.5-0.2-1-0.5-1.5-0.7
						c-8.1-3.8-19.9-6.9-31.8-6.7c-3.1,0.1-5.9,0.1-8.5,0.4c-1.3,0.1-2.6,0.2-3.8,0.4c-1.2,0.2-2.4,0.4-3.5,0.5
						c-1.1,0.2-2.3,0.3-3.3,0.5c-1.1,0.2-2.1,0.4-3.2,0.7c-1,0.2-2.1,0.4-3.1,0.7c-1,0.3-2,0.5-3,0.8c-1,0.3-2,0.5-3,0.8
						c-1,0.3-2,0.6-3,1c-1,0.3-2,0.6-3.1,1c-1,0.4-2.1,0.8-3.2,1.2c-1.1,0.4-2.2,0.8-3.3,1.3c-1.1,0.5-2.3,1-3.5,1.5
						c-0.6,0.3-1.2,0.5-1.8,0.8c-0.6,0.3-1.2,0.6-1.9,0.9c-1.3,0.6-2.6,1.2-4,1.9c1.4-0.5,2.8-1,4.1-1.5c0.7-0.2,1.3-0.5,1.9-0.7
						c0.6-0.2,1.3-0.4,1.9-0.6c1.2-0.4,2.4-0.7,3.6-1c1.2-0.3,2.3-0.5,3.4-0.8c1.1-0.2,2.2-0.5,3.3-0.7c1.1-0.2,2.1-0.3,3.2-0.5
						c1-0.1,2-0.3,3-0.4c1-0.1,2-0.2,3-0.3c1-0.1,2-0.2,3-0.2c1-0.1,2-0.1,3-0.1c1,0,2.1,0,3.1-0.1c1.1,0,2.1,0.1,3.2,0.1
						c1.1,0,2.2,0.1,3.4,0.1c1.2,0.1,2.3,0.2,3.6,0.3C116.4,360.4,119,360.8,121.8,361.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M120.9,378.3c10.8,0.9,21.8-0.5,29.7-2.7c7.9-2.2,12.8-5.1,12.3-6.8c-0.4-1.8-5.9-1.9-13.7-1.5
						c-7.8,0.4-18.1,1.3-28.4,2.2c-10.6,0.9-18,1.1-25.5,0.9c-7.5-0.3-15.1-1-26.1-3.4c10.7,3.5,18.2,5.4,25.6,7.2
						C102.3,375.9,109.8,377.4,120.9,378.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M132.4,371.1c6.3,1.3,12.7,1.4,17.5,0.7c4.8-0.7,8-2.3,8-4c0-1.8-3.1-3.3-7.8-4.4c-4.7-1-10.9-1.5-17.2-1
						c-6.5,0.5-10.9,0.9-15.5,1.2c-4.5,0.3-9.1,0.4-15.8,0c6.6,1.4,11,2.6,15.4,3.8C121.5,368.5,125.9,369.7,132.4,371.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M166.3,420.2c1,0,2.1,0,3.1-0.2c1-0.2,2.1-0.4,3.1-0.7c2-0.7,4-1.7,5.8-3.3c1.8-1.5,3.3-3.7,4-6.1
						c0.7-2.4,0.7-4.8,0.3-6.9c-0.4-2.1-1.2-4-2.1-5.7c-0.9-1.7-2-3.1-3.3-4.5c-1.3-1.3-2.8-2.4-4.5-3.3c-1.6-0.8-3.6-1.3-5.3-1.4
						c-3.5-0.2-6.9,1.1-9,3.3c-1.1,1.1-1.8,2.4-2.2,3.6c-0.4,1.2-0.5,2.3-0.4,3.3c0.2,2,1.1,3.4,1.9,4c0.8,0.7,1.5,0.7,1.8,0.4
						c0.3-0.3,0.4-0.9,0.5-1.6c0.1-0.7,0.2-1.6,0.7-2.4c0.4-0.9,1.2-1.8,2.1-2.1c0.9-0.4,2.3-0.6,3.6-0.2c1.3,0.4,2.6,1.3,3.9,2.8
						c1.2,1.5,2.3,3.6,2.6,5.5c0.2,0.9,0.1,1.7-0.1,2.3c-0.2,0.6-0.5,1.2-1.1,1.8c-0.6,0.6-1.5,1.2-2.5,1.7
						c-0.5,0.2-1.1,0.4-1.6,0.6c-0.6,0.2-1.1,0.3-1.8,0.4c-2.6,0.3-5.2,0-7.6-0.7c-2.4-0.7-4.7-1.7-6.8-2.9c-4.1-2.4-7.6-5.4-11-8.5
						c-3.5-3.1-6.9-6.5-11.2-9.7c-2.2-1.6-4.5-3.1-7.3-4.5c-2.7-1.3-5.9-2.4-9.3-2.6c3.5,0.5,6.4,1.9,8.9,3.4
						c2.5,1.5,4.7,3.3,6.5,5.1c3.8,3.6,6.6,7.3,9.5,11c3,3.7,6,7.4,10.3,11c2.2,1.8,4.6,3.4,7.6,4.8
						C158.6,419,162.2,420.1,166.3,420.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M130.6,425.7c-0.7,1.3-1.4,2.2-2.3,3c-0.9,0.7-1.7,1.2-2.5,1.4c-0.6,0.2-1.4,0.2-2.1,0c-0.7-0.2-1.2-0.4-1.5-0.7
						c-0.3-0.1-0.6-0.7-0.7-1.3c-0.1-0.6-0.1-1.3,0-1.9c0.1-0.6,0.3-1.1,0.4-1.6c0.2-0.5,0.3-1,0.2-1.4c-0.1-0.4-0.4-0.8-1.1-1
						c-0.7-0.2-1.8-0.1-2.9,0.5c-1.2,0.6-2.4,1.7-3.2,3.4c-0.8,1.6-1.3,4-0.4,6.6c0.8,2.4,2.7,4.6,5.1,5.9c2.4,1.3,5.3,1.8,8.3,1.2
						c2.9-0.6,5.4-2.2,7.2-4.2c1.8-2,3.1-4.6,3.7-7c0.6-2.5,0.6-4.8,0.4-6.8c-0.2-2-0.6-3.8-1-5.4c-0.9-3.3-2.2-6.1-3.7-8.6
						c-1.5-2.6-3.3-4.9-5.6-7.3c-1.1-1.2-2.4-2.3-3.8-3.5c-1.4-1.1-3.1-2.2-4.9-3.2c1.7,1.2,3.1,2.6,4.3,3.8c1.2,1.3,2.2,2.6,3,3.9
						c1.7,2.6,2.7,5.1,3.5,7.7c0.7,2.6,1.1,5.2,1.1,8C132.2,419.9,131.9,423.1,130.6,425.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M612.6,399.5c-0.7-3.6-1.9-7.1-3.5-10.4c-1.6-3.3-3.6-6.4-5.8-9.1c-2.2-2.7-4.6-4.8-6.8-6.8c-2.2-1.9-4.2-3.6-5.9-5.2
						c-3.5-3.1-6-6-7.6-8.2c-1.7-2.2-2.6-3.7-3.5-3.5c-0.8,0.1-1.5,2-0.9,5.4c0.3,1.6,0.9,3.6,1.9,5.6c1,2,2.4,4.2,4,6.3
						c3.3,4.3,7.7,8.3,11.3,12.7c3.5,4.3,6.4,9.6,8.4,15.4c1,3,1.7,5.9,2.1,8.6c0.4,2.7,0.7,5.4,0.7,7.9c0,5-0.5,9.6-1.6,14.1
						c-1.1,4.5-2.6,9-4.9,13.8c-2.3,4.8-5.4,9.9-9.8,15.6c4.8-5.3,8.4-10.1,11.3-14.7c2.9-4.6,5.2-9,7-13.5
						c1.8-4.6,3.3-9.3,4.1-14.8c0.4-2.8,0.6-5.7,0.6-8.9C613.7,406.6,613.4,403.2,612.6,399.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M580.2,404.8c-0.8-5.7-1-11.4-0.9-16.8c0.1-5.4,0.4-10.6,0.8-15c0.7-8.9,0.9-15.2-0.8-15.7c-1.7-0.4-4.9,5.1-7.5,14.3
						c-1.2,4.6-2.2,10.1-2.4,16.2c-0.2,6,0.4,12.6,2.2,18.9c1.9,6.5,4.3,11.6,7.1,15.9c1.4,2.2,2.8,4.1,4.3,5.9
						c1.4,1.8,2.9,3.4,4.2,5.1c2.6,3.3,5,6.9,5.9,11.6c0.2,1.2,0.4,2.4,0.4,3.7c0,1.3,0,2.6-0.2,4c-0.4,2.7-1.3,5.6-3,8.4
						c1.9-2.7,3.1-5.5,3.8-8.2c0.4-1.4,0.6-2.7,0.7-4.1c0.1-1.3,0.1-2.6,0.1-3.9c-0.1-2.5-0.7-5-1.5-7.2c-0.8-2.2-1.8-4.3-2.9-6.2
						c-2.2-3.9-4.5-7.5-6.2-11.7C582.4,415.5,581,410.7,580.2,404.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M593.6,391.6c-1-7.1-3.4-13.9-5.9-18.7c-2.5-4.8-4.9-7.5-5.7-7.1c-0.8,0.4,0.2,3.8,1.8,8.7c1.6,4.9,3.7,11.4,5.5,17.9
						c1.8,6.8,2.7,11.6,3.3,16.5c0.5,4.9,0.8,9.9,0.1,17.3c1.2-7.3,1.6-12.4,1.8-17.4C594.6,403.9,594.6,398.9,593.6,391.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M588.3,385.7c-0.4-5.3-1.8-10.5-3.4-14.1c-1.6-3.7-3.4-5.8-4.2-5.5c-0.8,0.3-0.6,3,0.2,6.7c0.7,3.8,1.9,8.6,3.1,13.5
						c1.2,5.1,1.9,8.6,2.3,12.2c0.4,3.6,0.7,7.4,0.3,12.8c0.9-5.4,1.3-9.2,1.6-12.9C588.5,394.8,588.7,391.1,588.3,385.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M583.8,385.6c0.2-8.5-1.7-16.9-3.5-16.8c-1.8,0.1-2.4,8.7-0.9,17.1c1.6,8.6,2.6,11.8,3.8,20.8
						C583,397.7,583.5,394.4,583.8,385.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M38.3,418.2c3.6-0.5,7.1-1.2,9.7-2c2.6-0.8,4.4-1.7,4.3-2.5c-0.1-0.9-2-1.5-4.9-1.6c-2.9-0.1-6.7,0.5-10.3,1.9
						c-3.7,1.5-6,2.8-8.4,4.1c-2.3,1.3-4.7,2.7-8,4.8c3.6-1.6,6.2-2.4,8.7-3C32.1,419.3,34.6,418.8,38.3,418.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M37.7,425.6c3.2-0.1,6.2-0.5,8.6-1.1c2.3-0.5,4-1.2,4-2.1c0-0.9-1.6-1.7-4.2-2.1c-2.6-0.4-6-0.1-9.3,0.9
						c-3.3,1.1-5.5,2.1-7.6,3.2c-2.1,1.1-4.2,2.2-7.3,3.9c3.3-1.2,5.6-1.8,7.9-2.1C32.2,425.9,34.5,425.7,37.7,425.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M59.5,432.6c2.4,0.8,4.7,1.4,6.6,1.6c1.9,0.2,3.4,0.1,3.8-0.7c0.4-0.8-0.5-2.1-2.3-3.2c-1.8-1.1-4.5-2-7.3-2
						c-2.9,0-4.8,0.3-6.8,0.7c-1.9,0.4-3.8,0.8-6.5,1.5c2.8-0.2,4.7,0,6.5,0.4C55.3,431.3,57.1,431.8,59.5,432.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M80.6,446.8c4,0.5,8.1-0.5,10.7-1.9c2.7-1.4,4-3.1,3.6-3.8c-0.4-0.8-2.4-0.5-5-0.1c-2.6,0.4-5.9,1-9.4,1.5
						c-3.6,0.4-6.1,0.3-8.7,0c-2.5-0.4-5.2-1.1-8.6-3.1c3.1,2.4,5.6,3.7,8.1,4.9C73.9,445.3,76.5,446.2,80.6,446.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M84.1,455.5c2.2,0,4.3-0.8,5.6-1.8c1.3-1,1.9-2.2,1.5-3c-0.4-0.8-1.6-1-3.1-0.9c-1.5,0.1-3.2,0.6-4.9,1.4
						c-1.8,0.8-3.1,1.4-4.4,1.8c-1.4,0.4-2.8,0.8-4.9,0.8c2.1,0.5,3.5,0.8,5,1.1C80.4,455.3,81.9,455.5,84.1,455.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-6.8,468.2c-3.8,3.9-6.3,9-7.3,13c-1,4-0.7,6.8,0.2,7c0.9,0.2,2.1-2.2,3.8-5.5c1.7-3.3,4-7.6,6.8-11.7
						c2.9-4.2,5.4-6.9,8.1-9.3c2.8-2.3,5.8-4.6,11-6.6c-5.3,1.5-8.8,3.2-12.1,5C0.3,462-2.9,464.1-6.8,468.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-0.6,480.6c-1,1.9-1.8,3.9-2.4,5.8c-0.3,1-0.5,1.9-0.7,2.8c-0.2,0.9-0.3,1.9-0.3,2.8c0,0.9,0.1,1.8,0.4,2.6
						c0.3,0.8,0.7,1.5,1.2,1.9c0.5,0.4,1,0.6,1.4,0.6c0.4,0,0.6-0.1,0.7-0.3c0.2-0.4-0.1-1,0-1.6c0-0.6,0.1-1.5,0.5-2.7
						c0.2-0.6,0.4-1.2,0.7-2.1c0.3-0.8,0.6-1.7,0.8-2.5c0.6-1.8,1.1-3.7,1.7-5.6c1.1-4,2.1-6.7,3.2-9.3c1.2-2.7,2.5-5.3,5-8.9
						c-2.9,3.2-4.8,5.6-6.7,7.9C3.3,474.3,1.5,476.7-0.6,480.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M7.5,492.9c-0.1,2.4,0.6,4.8,1.6,6.4c1,1.6,2.2,2.3,3,2c0.8-0.4,1.1-1.7,1-3.3c-0.1-1.6-0.5-3.6-1.2-5.6
						c-0.7-2.1-1.2-3.6-1.5-5.1c-0.3-1.6-0.5-3.2-0.3-5.6c-0.7,2.3-1.2,3.9-1.6,5.5C8.1,488.7,7.7,490.4,7.5,492.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M19.5,498.4c0.8,2,2.3,3.6,3.7,4.4c1.4,0.8,2.7,0.9,3.3,0.2c0.6-0.7,0.3-1.9-0.3-3.1c-0.6-1.3-1.7-2.7-3-3.9
						c-1.4-1.3-2.4-2.3-3.2-3.3c-0.9-1.1-1.7-2.2-2.5-4.2c0.3,2.1,0.5,3.5,0.7,4.9C18.5,494.9,18.7,496.4,19.5,498.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M36.3,500.3c1.6,0.8,3.4,0.8,4.7,0.4c1.3-0.4,2-1.3,2-2.2c-0.1-0.9-0.9-1.6-1.9-2c-1.1-0.5-2.4-0.7-3.8-0.5
						c-1.5,0.2-2.6,0.2-3.7,0.2c-1.1,0-2.3-0.2-3.8-0.8c1.1,0.9,2.1,1.7,3.2,2.6C33.7,498.7,34.7,499.5,36.3,500.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M173.3,453.4c1.6-3.6,2.3-7.6,2.2-11.5c-0.1-3.9-1-7.8-2.9-11.2c-1-1.7-2.2-3.2-3.6-4.5c-1.5-1.3-3.2-2.3-5.1-2.8
						c-3.8-1.1-7.5-0.3-10.2,1.3c-2.8,1.6-4.7,4.3-5.4,6.7c-0.8,2.5-0.6,4.7-0.2,6.4c0.5,1.7,1.2,2.9,1.8,3.6
						c0.6,0.7,1.1,0.9,1.2,0.8c0.4-0.3-0.5-1.9-0.7-4.8c0-1.4,0.2-3.2,1-4.9c0.8-1.7,2.2-3.3,4.2-4.2c2-1,4.6-1.3,6.8-0.5
						c2.3,0.8,4.4,2.7,5.8,5.3c1.4,2.6,2.2,5.7,2.4,8.9c0.2,3.2-0.3,6.6-1.4,9.7c-1.2,3.2-3,6-4.8,8.5c-1.9,2.5-3.8,4.8-5.6,7.1
						c-0.9,1.2-1.7,2.3-2.5,3.5c-0.4,0.6-0.7,1.2-1.1,1.8l-0.5,0.9l-0.5,0.9c-0.3,0.6-0.6,1.2-0.9,1.8c-0.3,0.6-0.5,1.2-0.8,1.8
						c-0.6,1.2-0.9,2.5-1.4,3.7c-0.8,2.5-1.4,5.1-1.8,7.8c-0.2,1.3-0.3,2.7-0.4,4.1c0,0.7,0,1.4,0,2.1c0,0.7,0,1.4,0,2.2
						c0.1,2.9,0.5,6,1.3,9.3c0.8,3.2,2,6.6,3.8,10c-1.7-3.5-2.7-6.9-3.4-10.1c-0.6-3.2-0.9-6.3-0.8-9.2c0-0.7,0-1.4,0.1-2.1
						c0.1-0.7,0.1-1.4,0.2-2.1c0.2-1.4,0.3-2.7,0.6-4c0.5-2.6,1.3-5,2.2-7.4c1.9-4.7,4.5-9,8.3-13.1c1.9-2.1,4-4.2,6.1-6.7
						C169.4,460,171.7,457.1,173.3,453.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M154.7,445.2c-2.1,1.9-3.9,4-5.1,5.8c-1.2,1.8-1.8,3.3-1.2,4c0.6,0.7,2.2,0.3,4.2-0.9c1.9-1.2,4.1-3.4,5.6-6.1
						c0.8-1.4,1.3-2.5,1.8-3.6c0.5-1.1,0.9-2.1,1.2-3.2c0.3-1.1,0.5-2.2,0.4-3.5c-0.1-1.2-0.5-2.6-1.7-3.7c0.9,1.3,1,2.6,0.8,3.7
						c-0.2,1.1-0.7,2-1.2,2.7c-0.6,0.8-1.2,1.5-2,2.3C156.6,443.3,155.7,444.2,154.7,445.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M157.3,453.9c-1.7,1.7-3.1,3.6-4,5.2c-0.9,1.6-1.2,3-0.6,3.7c0.6,0.6,2.1,0.3,3.7-0.9c1.6-1.1,3.4-3.1,4.4-5.5
						c1.1-2.4,1.7-4.2,2.2-6c0.6-1.9,1.1-3.7,1.8-6c-1.2,2.3-2.3,3.8-3.5,5.1C160.3,450.9,159.1,452.2,157.3,453.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M187.3,434.6c0.8-2.6,0.7-5.4,0.1-7.4c-0.6-2-1.7-3.1-2.5-2.9c-0.9,0.2-1.4,1.5-1.6,3.3c-0.3,1.8-0.4,4-0.3,6.3
						c0.1,2.4,0,4.2-0.2,5.9c-0.3,1.7-0.7,3.5-1.9,5.9c1.6-2.1,2.7-3.7,3.6-5.2C185.5,438.9,186.5,437.3,187.3,434.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M196,440.1c1.4-0.9,2.5-2.1,3.2-3.2c0.7-1.1,1-2.2,0.6-3c-0.5-0.7-1.7-0.9-3.1-0.4c-1.4,0.5-3,1.8-4,3.6
						c-1,1.8-1.4,3.2-1.8,4.5c-0.4,1.3-0.7,2.6-1.2,4.5c1-1.7,1.9-2.6,2.8-3.5C193.5,441.8,194.5,441.1,196,440.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M204.1,446c1.7-0.2,3.1-0.8,4.2-1.5c1.1-0.7,1.8-1.6,1.7-2.5c-0.1-0.9-1.2-1.5-2.7-1.6c-1.5-0.1-3.4,0.4-5,1.6
						c-1.6,1.2-2.5,2.2-3.5,3.2c-1,1.1-1.9,2.2-3,3.4c1.6-1,2.8-1.5,4-1.8C201.1,446.4,202.3,446.2,204.1,446z"
                    />
                  </g>
                  <g>
                    <path
                      d="M255.9,442.9c-2.8,2-5.5,3.9-7.6,5.6c-2,1.6-3.4,3-3,3.8c0.3,0.8,2.4,0.8,5.1-0.2c2.7-1,6-3.1,8.4-6
						c2.5-3,3.9-5.3,5.2-7.6c1.2-2.3,2.4-4.7,3.8-8.3c-1.9,3.4-3.5,5.4-5.3,7.2C260.7,439.2,258.8,440.8,255.9,442.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M258,452.6c-2.4,3.6-3.9,7.7-4.5,10.9c-0.5,3.2-0.2,5.5,0.6,5.6c0.9,0.2,2-1.7,3.4-4.4c1.3-2.7,2.9-6.4,4.3-10.1
						c1.5-3.8,2.8-6.4,4.3-9c1.5-2.5,3.1-5,6.1-8.3c-3.4,2.9-5.5,5-7.6,7.1C262.6,446.7,260.5,448.9,258,452.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M264.1,459.8c-1.9,3.8-3,8-3.2,11.2c-0.3,3.2,0.2,5.4,1,5.5c0.9,0.1,1.9-1.8,3-4.6c1.1-2.9,2.3-6.7,3.3-10.6
						c1-4,1.9-6.8,2.9-9.6c1-2.8,2.2-5.5,4.4-9.3c-2.7,3.5-4.4,6-6.1,8.5C267.7,453.4,266.1,455.9,264.1,459.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M266.4,475.3c-1.1,3.9-1.3,8-0.8,11c0.4,3,1.4,5,2.2,4.9c0.9-0.1,1.5-2,2-4.9c0.5-2.8,0.8-6.6,1-10.3
						c0.2-3.9,0.5-6.6,0.9-9.3c0.5-2.7,1.1-5.5,2.6-9.4c-2,3.6-3.2,6.2-4.4,8.8C268.6,468.7,267.5,471.3,266.4,475.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M332.1,491.8c-2.3,0.4-4.4,1.4-5.7,2.6c-1.3,1.2-1.9,2.4-1.4,3.2c0.5,0.8,1.8,0.9,3.4,0.5c1.6-0.3,3.5-1.1,5.3-2.2
						c1.9-1.2,3.2-2,4.6-2.7c1.4-0.7,2.9-1.4,5.1-2c-2.1,0-3.8,0.1-5.6,0.1C336.1,491.3,334.5,491.4,332.1,491.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M338.2,497.8c-2,0.5-2.5,2.8-1.3,4.1c1.2,1.3,3.4,1.1,4.2-0.8c0.8-1.9,0.9-3,2-4.7
						C341.2,497.3,340.2,497.3,338.2,497.8z"
                    />
                  </g>
                  <g>
                    <path d="M432.9,506.6c-3.6,0.1-6.5,2.6-5.9,4.2c0.7,1.6,4.1,1.7,7,0c3-1.8,4.1-2.8,7.6-4C438.1,507,436.6,506.6,432.9,506.6z" />
                  </g>
                  <g>
                    <path
                      d="M152,526.9c-2.4-3.5-5.6-6.8-9.9-9.1c-2.1-1.1-4.4-2-6.8-2.5c-2.4-0.5-5-0.6-7.6-0.1c-5.2,0.9-9.7,3.7-12.6,7.9
						c-0.7,1.1-1.3,2.2-1.8,3.4c-0.5,1.2-0.8,2.5-0.9,3.7c-0.3,2.4,0,4.7,0.7,6.8c0.7,2.1,1.8,4,3.3,5.5c1.5,1.5,3.3,2.6,5,3.2
						c3.6,1.1,6.6,0.4,8.6-0.7c2.1-1.1,3.2-2.7,3.5-3.9c0.2-0.6,0.2-1.1,0.1-1.5c-0.1-0.4-0.3-0.7-0.6-0.8c-0.3-0.2-0.6-0.3-1-0.3
						c-0.4,0-0.8,0-1.2-0.1c-0.8,0-1.8-0.1-2.7-0.4c-0.9-0.3-2-0.9-2.4-1.5c-0.2-0.3-0.3-0.7-0.4-1.1c-0.1-0.4-0.1-0.8-0.1-1.4
						c0-0.5,0.2-1,0.4-1.4c0.1-0.2,0.2-0.4,0.3-0.5c0.1-0.2,0.3-0.4,0.5-0.5c0.4-0.4,0.9-0.7,1.5-1c0.6-0.3,1.3-0.5,2-0.6
						c1.4-0.2,3.4,0.1,5.5,0.9c2,0.8,4.2,2.4,6.2,4.4c1.1,1,2.1,2.2,3,3.4c0.9,1.2,1.8,2.3,2.6,3.5c1.5,2.4,2.8,4.7,3.8,7.1
						c2,4.7,3.3,9.3,3.7,14.1c0.5,4.8,0.2,9.7-1.1,15.2c-1.3,5.4-3.8,11.3-8.4,17.4c5.2-5.5,8.6-11.1,10.9-16.5
						c2.3-5.4,3.7-10.6,4.3-15.9c0.6-5.3,0.5-10.8-0.6-16.8c-0.6-3-1.4-6.1-2.7-9.4c-0.7-1.6-1.4-3.3-2.3-5
						C154.2,530.4,153.2,528.7,152,526.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M180.5,567.9c3.9-1.9,7.3-4.1,9.8-6.3c2.5-2.1,4.1-4.2,3.6-5.9c-0.5-1.6-3.3-2.4-7.1-1.7c-3.7,0.7-8.4,3.2-11.7,7
						c-3.4,3.9-5.2,7-6.8,10c-1.6,3.1-3.1,6.2-4.9,10.7c2.8-4.1,5.3-6.3,7.8-8.3C173.8,571.6,176.5,569.9,180.5,567.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M174.5,542.4c0.4-3.8-1-7.4-2.8-9.6c-1.9-2.2-4.1-3-5.6-2.2c-1.6,0.9-2.2,2.9-2.2,5.3c0,2.4,0.6,5.1,1.9,7.8
						c1.3,2.9,2.2,5,2.8,7.3c0.6,2.3,1,4.7,0.6,8.2c1.4-3.3,2.3-5.6,3.2-8C173.2,548.8,174.1,546.3,174.5,542.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M59.8,573.1c-3.7,3.4-7.3,6.6-11.1,9.2c-3.7,2.5-7.5,4.5-10.6,4.9c-3.2,0.5-5.8-0.6-7.3-1.7c-1.6-1.1-2.1-2.3-2.6-2.2
						c-0.2,0.1-0.3,0.4-0.3,1.1c0.1,0.7,0.4,1.7,1.1,2.7c0.7,1.1,1.9,2.2,3.5,3c1.6,0.8,3.7,1.3,5.9,1.3c2.2-0.1,4.5-0.6,6.7-1.5
						c2.2-0.9,4.3-2.1,6.4-3.4c4.2-2.8,8-6.4,11.4-10.2c3.5-3.9,6.3-7.3,8.9-10.2c2.6-2.9,5.1-5.4,7.9-7.7c2.7-2.2,5.7-4.2,9.4-5.8
						c3.7-1.5,8.2-2.7,13.6-2c-5.3-1-10-0.1-13.9,1.2c-3.9,1.3-7.2,3.1-10.3,5.1c-3.1,2-5.9,4.3-8.9,7
						C66.7,566.4,63.5,569.6,59.8,573.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M73.8,568.7c-1.9,3-3.2,6.4-4,9.7c-0.8,3.3-0.9,6.4-0.8,9.1c0.4,5.4,2.1,8.6,2.9,8.4c0.9-0.2,0.8-3.6,1.3-8.4
						c0.3-2.4,0.7-5.1,1.4-8c0.7-2.9,1.7-5.9,3-8.8c1.4-3,2.8-5.4,4.4-7.4c1.6-2,3.2-3.7,4.9-5.3c1.8-1.5,3.7-3,6-4.3
						c2.3-1.3,5.1-2.5,8.6-3.4c-3.5,0.6-6.4,1.6-9,2.6c-2.5,1.1-4.7,2.2-6.8,3.5c-2.1,1.3-4,2.8-6.1,4.7
						C77.8,563.3,75.8,565.6,73.8,568.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M82.3,565.7c-1.2,2.3-2,4.8-2.4,7.2c-0.4,2.4-0.3,4.7,0,6.6c0.6,3.9,2.2,6.1,3,5.9c0.9-0.2,0.9-2.7,1.2-6
						c0.1-1.7,0.4-3.6,0.7-5.6c0.4-2,0.9-4.2,1.5-6.3c0.7-2.2,1.5-4,2.4-5.5c0.9-1.6,1.8-2.9,2.9-4.2c1.1-1.3,2.3-2.5,3.8-3.7
						c1.5-1.2,3.3-2.3,5.7-3.3c-2.4,0.8-4.4,1.7-6.1,2.6c-1.7,0.9-3.2,1.9-4.6,3c-1.4,1.1-2.7,2.3-4.1,3.8
						C84.9,561.6,83.5,563.4,82.3,565.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M126.8,555.4c3.6,0.7,7.5,0,9.9-1.3c2.5-1.3,3.7-2.8,3.3-3.6c-0.4-0.8-2.2-0.7-4.6-0.4c-2.4,0.3-5.3,0.6-8.5,0.9
						c-3.2,0.2-5.5,0-7.8-0.4c-2.3-0.5-4.6-1.3-7.6-3.4c2.6,2.5,4.7,3.9,6.9,5.1C120.8,553.5,123.1,554.6,126.8,555.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M138.6,563.1c1.7,1.4,3.4,2.4,4.9,3.1c1.5,0.7,2.8,0.9,3.4,0.3c0.6-0.6,0.4-2-0.8-3.5c-1.1-1.5-3.1-3.1-5.5-3.8
						c-2.4-0.7-4.2-0.9-5.9-1c-1.7-0.1-3.4-0.2-5.8-0.2c2.4,0.5,3.9,1.2,5.3,2C135.6,560.8,136.9,561.7,138.6,563.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M141,578.8c0.3,2.1,1,4.1,1.8,5.5c0.8,1.5,1.7,2.4,2.6,2.3c0.9-0.1,1.5-1.4,1.5-3.2c0.1-1.8-0.5-4.2-1.8-6.2
						c-1.3-2.1-2.4-3.4-3.5-4.6c-1.1-1.2-2.2-2.5-3.8-4.2c1.2,2.1,1.7,3.6,2.2,5.2C140.4,575.1,140.7,576.6,141,578.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M143.4,505.7c0.1-2,0.1-3.7-0.1-5.2c-0.2-1.5-0.5-2.7-1.3-3c-0.8-0.3-2,0.5-2.8,2.3c-0.8,1.7-1,4.4-0.2,6.7
						c0.9,2.4,1.9,3.8,3,5.1c1.1,1.3,2.2,2.5,4,4c-1.5-1.8-2-3.4-2.3-4.8C143.4,509.2,143.3,507.7,143.4,505.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M158.8,498.6c0.9-1.8,1.7-3.4,2.3-4.9c0.6-1.4,0.9-2.7,0.4-3.4c-0.5-0.7-2.1-0.5-3.6,0.8c-1.6,1.3-3.1,3.7-3.3,6.4
						c-0.2,2.8,0.3,4.6,0.9,6.3c0.6,1.7,1.4,3.3,2.8,5.3c-1-2.3-1.1-4-0.9-5.5C157.5,501.9,157.9,500.4,158.8,498.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M187.9,576c2.7-0.4,5.2-1.1,7.1-1.9c1.9-0.8,3.2-1.7,3.1-2.6c-0.1-0.9-1.6-1.5-3.8-1.5c-2.2,0-5.1,0.5-7.7,1.8
						c-2.7,1.3-4.4,2.5-6.1,3.6c-1.8,1.2-3.5,2.4-5.8,4c2.7-1.2,4.6-1.8,6.5-2.3C183.2,576.8,185.2,576.4,187.9,576z"
                    />
                  </g>
                  <g>
                    <path
                      d="M149,392.3c3,0.1,5.8-1,7.6-2.1c1.8-1.1,2.7-2.4,2.4-3.2c-0.3-0.8-1.9-1-3.9-0.7c-2,0.3-4.4,1-6.7,1.7
						c-2.4,0.8-4.2,1-6,0.9c-1.8-0.1-3.8-0.5-5.9-2.5c1.7,2.3,3.6,3.4,5.4,4.3C143.8,391.6,145.9,392.2,149,392.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M134,321c-1.8,2.8-3.2,6-3.8,9.2c-0.6,3.2-0.5,6.3,0.2,8.9c0.7,2.6,1.8,4.5,2.9,5.6c0.5,0.6,1,0.9,1.4,1.1
						c0.4,0.2,0.7,0.2,0.8,0.1c0.7-0.6-0.7-3.3-1-7.6c-0.1-2.1,0-4.6,0.5-7.2c0.5-2.6,1.5-5.3,2.8-8c1.3-2.8,2.7-5.1,4-7.1
						c1.3-2.1,2.6-3.9,3.9-5.7c1.3-1.9,2.6-3.7,4-5.8c1.4-2.1,2.9-4.5,4.3-7.6c-1.6,2.9-3.4,5.1-5,7.1c-1.6,1.9-3.2,3.5-4.8,5.2
						c-1.6,1.6-3.1,3.2-4.8,5.1C137.7,315.9,135.8,318.1,134,321z"
                    />
                  </g>
                  <g>
                    <path
                      d="M143.2,322.4c-0.7,5.5,0.4,11.2,2,15.1c1.6,3.9,3.6,6,4.4,5.6c0.8-0.4,0.3-3.1-0.4-6.8c-0.7-3.8-1.4-8.7-1.7-13.7
						c-0.2-5.2,0.2-8.9,1-12.5c0.8-3.6,2-7.3,5-12.1c-3.4,4.5-5.2,8-6.8,11.5C145.2,313,143.9,316.7,143.2,322.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M149.7,316.6c0,4,1.3,7.8,2.8,10.4c1.5,2.6,3.2,3.9,3.9,3.4c0.8-0.4,0.5-2.4-0.1-5c-0.6-2.6-1.5-6-2.3-9.4
						c-0.9-3.5-1.2-6.1-1.4-8.7c-0.1-2.6,0-5.3,1-9.2c-1.5,3.7-2.3,6.3-2.9,9C150.2,309.8,149.7,312.5,149.7,316.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M93.8,341.5c3.2-2.6,5.7-6,7.1-8.8c1.4-2.8,1.7-5,0.9-5.4c-0.8-0.4-2.4,0.9-4.4,3c-2,2.1-4.4,5-6.8,8.1
						c-2.4,3.1-4.3,5.2-6.3,7.2c-2,2-4.2,3.8-7.8,6.1c3.8-1.8,6.4-3.2,8.9-4.6C88,345.7,90.5,344.2,93.8,341.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M102.3,347.6c2.7-0.3,5.3-1,7.2-1.7c1.9-0.8,3.2-1.7,3.1-2.5c-0.1-0.9-1.6-1.5-3.8-1.6c-2.2-0.1-5.1,0.4-7.7,1.7
						c-2.7,1.3-4.4,2.4-6.2,3.5c-1.8,1.2-3.6,2.3-5.9,3.8c2.7-1.2,4.7-1.7,6.6-2.1C97.6,348.2,99.5,347.9,102.3,347.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M119.4,246.7c1.9-1.6,3.5-3.5,4.5-5.2c1-1.6,1.4-3,0.8-3.7c-0.6-0.6-2.1-0.3-3.8,0.8c-1.7,1.1-3.6,3-4.9,5.4
						c-1.3,2.4-2,4.2-2.8,5.9c-0.8,1.9-1.5,3.7-2.5,6c1.5-2.3,2.8-3.7,4.1-5C116.1,249.6,117.4,248.4,119.4,246.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M20.7,384.4c-7.8,3.2-14.9,7.9-19.7,12.1c-4.8,4.2-7.2,7.7-6.7,8.3c0.6,0.7,4.1-1.6,9.2-5c5.1-3.3,11.9-7.6,19-11.4
						c7.3-3.9,12.7-6.1,18.2-8c5.5-1.8,11.3-3.3,20-4.3c-8.8,0.4-14.7,1.3-20.5,2.4C34.5,379.7,28.7,381.1,20.7,384.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M27.1,386.9c-6.2,3.1-11.7,7.5-15.3,11.3c-3.6,3.8-5.4,6.9-4.8,7.4c0.7,0.6,3.5-1.3,7.5-4.3c4-3,9.3-6.9,14.8-10.6
						c5.7-3.8,9.8-6.1,14.1-8.2c4.3-2,8.9-3.9,15.8-5.7c-7.1,1.2-11.9,2.5-16.5,3.9C38.1,382.1,33.5,383.7,27.1,386.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-19.8,372.5c-3.2-2.4-6.3-4.7-8.9-6.3c-2.5-1.6-4.5-2.5-5.1-1.9c-0.6,0.6,0.3,2.7,2.4,5.1c2.1,2.4,5.5,5.1,9.3,6.9
						c4,1.8,6.8,2.7,9.6,3.6c2.9,0.8,5.7,1.6,10,2.5c-4.1-1.4-6.8-2.8-9.3-4.2C-14.2,376.7-16.6,375-19.8,372.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-14.5,384.4c-0.9,0-1.8,0.2-2.7,0.6c-0.9,0.3-1.9,1-2.5,2c-0.6,1-0.6,2.1-0.3,2.7c0.3,0.7,0.9,0.8,1.3,0.7
						c0.4-0.1,0.8-0.4,1.2-0.5c0.4-0.1,0.7-0.1,1-0.1c0.2,0.1,0.6,0,1.2-0.2c0.7-0.2,1.4-0.5,2.1-1c1.4-0.9,2.5-1.5,3.6-2
						c1.1-0.5,2.3-0.9,4.1-1.2c-1.6-0.2-2.9-0.5-4.4-0.7C-11.3,384.6-12.6,384.4-14.5,384.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-13.6,395.2c-2.1,1.6-3.6,3.7-4.4,5.6c-0.7,1.8-0.7,3.3,0,3.8c0.8,0.5,2-0.2,3.4-1.4c1.4-1.2,2.9-3,4.1-5
						c1.3-2.1,2.3-3.5,3.4-4.9c1.1-1.4,2.3-2.7,4.3-4.4c-2.3,1.2-4,2-5.6,2.9C-9.8,392.7-11.4,393.6-13.6,395.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-9.5,421.5c-1.3,2.9-0.3,6,1.4,6.3c1.7,0.2,3.5-2.6,2.9-5.7c-0.5-3.2-1.1-4.4-1.2-7.7C-7.4,417.5-8.2,418.6-9.5,421.5
						z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-2.1,415.6c-1.2,2.5-0.2,5.3,1.5,5.5c1.7,0.2,3.4-2.2,2.8-4.9c-0.6-2.8-1.2-3.9-1.4-6.7C0,412.2-0.9,413.1-2.1,415.6z
						"
                    />
                  </g>
                  <g>
                    <path
                      d="M578.1,305.9c3.3-4.2,6.4-8.7,8.9-13.6c2.5-4.9,4.2-10.3,4.9-15.7c0.7-5.4,0.4-10.9-1.2-15.8c-0.4-1.2-0.8-2.4-1.3-3.6
						c-0.5-1.2-1.1-2.3-1.8-3.4c-1.4-2.2-3.1-4.2-5.3-5.7c-1.1-0.8-2.3-1.3-3.6-1.8c-1.3-0.4-2.7-0.6-4-0.5
						c-2.5,0.1-4.9,0.9-6.8,2.5c-0.9,0.8-1.7,1.7-2.3,2.7c-0.6,1-0.8,2.1-1,3.1c-0.2,2,0.2,3.7,0.7,5c1.1,2.5,2.5,3.9,3.5,4.8
						c1,0.9,1.7,1.1,2.1,0.9c0.4-0.2,0.3-1,0.1-2.2c-0.2-1.2-0.8-2.9-1-4.7c-0.1-0.9,0-1.8,0.3-2.5c0.2-0.3,0.4-0.7,0.6-0.9
						c0.2-0.2,0.5-0.4,0.9-0.6c0.7-0.3,1.7-0.5,2.7-0.3c0.5,0.1,1,0.2,1.4,0.4c0.4,0.2,0.9,0.6,1.4,1c0.9,0.8,1.8,2,2.5,3.4
						c0.7,1.4,1.3,3.2,1.8,5c0.9,3.6,1,7.7,0.4,12c-0.6,4.2-1.9,8.6-3.8,12.8c-1.9,4.3-4.4,8.5-7.2,12.6c-2.9,4.3-5.8,8.2-8.5,11.9
						c-2.7,3.7-5.2,7.3-7.5,10.8c-2.2,3.5-4.2,7-5.8,10.6c-1.6,3.6-2.8,7.2-3.7,10.9c-1.7,7.4-2.1,15.1-0.8,23.5
						c0.6,4.2,1.7,8.5,3.4,13c1.7,4.4,4,9,7.3,13.5c-3-4.6-5.1-9.3-6.5-13.7c-1.4-4.5-2.1-8.8-2.4-12.9c-0.6-8.2,0.5-15.5,2.8-22.3
						c1.2-3.4,2.6-6.6,4.4-9.7c1.8-3.1,4-6.2,6.4-9.3c2.4-3.1,5.2-6.3,8.2-9.7C571.4,313.8,574.7,310.2,578.1,305.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M561.9,353.7c1-1.7,2-3.3,3.1-4.8c1.1-1.5,2.2-2.8,3.4-4.1c1.1-1.3,2.4-2.4,3.5-3.4c0.3-0.2,0.6-0.5,0.9-0.7
						c0.3-0.2,0.5-0.4,0.8-0.6c0.5-0.4,1.1-0.7,1.6-1c2.2-1.2,4-1.8,5.4-2c1.5-0.3,2.7-0.1,3.3-0.7c0.3-0.3,0.4-0.8,0.2-1.5
						c-0.2-0.7-0.8-1.5-1.9-2.3c-1-0.8-2.5-1.4-4.3-1.6c-1.7-0.2-3.7-0.1-5.6,0.3c-1,0.2-2,0.6-3,1c-0.5,0.2-1,0.5-1.5,0.7
						c-0.5,0.3-0.9,0.5-1.4,0.8c-1.8,1.1-3.7,2.5-5.3,4.2c-1.6,1.7-3.1,3.6-4.4,5.6c-1.2,2.1-2.2,4.3-3,6.5
						c-1.6,4.7-2.1,8.7-2.3,12.3c-0.2,3.6,0,6.8,0.3,9.9c0.7,6.3,1.9,12.4,3.8,21.4c-0.9-9.1-0.8-15.4,0-21.4c0.4-3,1-5.9,1.9-9
						C558.6,360.4,559.9,357.1,561.9,353.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M574.5,279.9c-0.6-1.9-1.5-3.7-2.6-5.3c-0.6-0.8-1.2-1.6-1.9-2.3c-0.7-0.7-1.5-1.3-2.3-1.9c-1.7-1.1-3.9-1.8-6.2-1.5
						c-1.1,0.2-2.1,0.5-3,1.1c-0.9,0.6-1.6,1.4-2.1,2.4c-0.4,0.9-0.6,1.9-0.6,2.8c0,0.9,0.2,1.6,0.5,2.3c0.5,1.3,1.2,2.1,1.8,2.7
						c1.2,1.2,2.1,1.6,2.4,1.4c0.4-0.3,0.1-1.2-0.4-2.6c-0.2-0.7-0.5-1.5-0.6-2.4c-0.1-0.9,0.1-1.5,0.5-2c0.4-0.4,1.1-0.8,2-0.9
						c0.8-0.1,1.9,0.3,2.9,1c1.1,0.7,2.1,1.8,3.1,2.9c0.9,1.2,1.7,2.5,2.4,3.9c1.3,3,1.8,5.9,1.9,8.5c0.1,2.6-0.2,5.1-0.8,7.5
						c-1.1,4.8-3.2,9.6-6.8,16.2c4.1-6.4,6.7-10.8,8.6-15.6c0.9-2.4,1.7-4.9,2-7.9C575.6,287.2,575.6,283.8,574.5,279.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M568.9,290c-0.3-0.5-0.5-1.1-0.8-1.6c-0.3-0.5-0.7-1.1-1.2-1.5c-0.9-1-1.9-1.8-3.4-2.3c-0.7-0.3-1.5-0.4-2.3-0.4
						c-0.8,0-1.6,0.3-2.3,0.6c-1.4,0.7-2.2,1.8-2.6,2.8c-0.5,1-0.6,2-0.6,2.7c0,0.8,0.1,1.4,0.3,1.9c0.3,1,0.7,1.5,1.2,1.5
						c0.4,0,0.9-0.5,1.3-1.4c0.4-0.8,0.9-2,1.5-2.7c0.3-0.4,0.7-0.6,1-0.7c0.3,0,0.5,0,0.9,0.1c0.4,0.2,1,0.5,1.6,1.1
						c0.3,0.3,0.6,0.5,0.8,0.9c0.3,0.3,0.5,0.7,0.8,1.1c1.1,1.5,1.8,3,2.3,4.5c0.5,1.4,0.8,2.8,0.9,4.2c0.1,1.4,0.1,2.9-0.3,4.5
						c-0.3,1.6-0.9,3.4-2.1,5.3c1.4-1.7,2.3-3.4,3-5c0.7-1.6,1.1-3.1,1.3-4.7c0.2-1.6,0.4-3.1,0.2-4.9
						C570.3,294.2,569.9,292.2,568.9,290z"
                    />
                  </g>
                  <g>
                    <path
                      d="M548.8,301.3c-1.9-0.3-3.8-0.3-5.2,0c-1.5,0.3-2.6,0.9-2.7,1.8c-0.2,0.9,0.7,1.8,2.3,2.4c1.6,0.6,3.8,0.8,5.9,0.2
						c2.2-0.6,3.6-1.2,5-1.9c1.5-0.7,2.9-1.4,4.7-2.2c-2.1,0.4-3.6,0.5-5.1,0.4C552.1,301.9,550.7,301.7,548.8,301.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M536.3,308.9c-5.8,0.2-11.1,2.8-10.6,4.5c0.4,1.7,6.2,1.7,11.5-0.2c5.5-1.9,7.5-3,13.4-4.3
						C544.5,309.2,542.3,308.8,536.3,308.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M525.6,316.8c-3.9-0.7-7.6,0.8-7.6,2.5c0.1,1.8,4,2.9,7.8,1.8c3.9-1.1,5.3-1.9,9.3-2.6
						C531,318.2,529.6,317.5,525.6,316.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M537.6,319c-2.2,1.1-3.9,2.9-4.8,4.6c-0.9,1.6-1.1,3.1-0.4,3.6c0.7,0.6,2,0.2,3.4-0.7c1.5-0.9,3.1-2.3,4.5-4
						c1.5-1.7,2.6-2.9,3.8-4.1c1.2-1.1,2.5-2.2,4.6-3.4c-2.4,0.7-4,1.3-5.6,1.8C541.5,317.3,539.9,317.8,537.6,319z"
                    />
                  </g>
                  <g>
                    <path
                      d="M545.3,322.1c-1.5,1.4-2.5,3.1-2.9,4.6c-0.4,1.5-0.2,2.7,0.6,3.2c0.8,0.4,1.9,0,3-1c1.1-1,2.2-2.5,2.9-4.3
						c1.5-3.7,1.8-5.3,4.1-8.8C549.9,318.6,548.4,319.3,545.3,322.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M554.7,308c-1.3,0.9-2.1,2.2-2.4,3.4c-0.3,1.2,0,2.2,0.7,2.7c0.7,0.5,1.8,0.3,2.7-0.3c1-0.6,1.9-1.7,2.4-3
						c1.1-2.8,1.3-4.1,3.2-6.7C558.7,305.8,557.4,306.2,554.7,308z"
                    />
                  </g>
                  <g>
                    <path
                      d="M532.3,341c-1.2,1.5-1.8,3.3-1.8,4.8c0,1.5,0.5,2.5,1.4,2.7c0.9,0.2,1.8-0.4,2.6-1.5c0.8-1,1.5-2.5,1.8-4.2
						c0.3-1.7,0.5-3,0.9-4.2c0.3-1.2,0.8-2.4,1.7-4.1c-1.4,1.3-2.3,2.2-3.3,3.1C534.5,338.6,533.5,339.5,532.3,341z"
                    />
                  </g>
                  <g>
                    <path
                      d="M533.2,356.5c-2.1,3.9-1.8,8.5-0.1,8.9c1.7,0.4,3.9-3.4,4.3-7.6c0.4-4.4,0.2-6.1,1.4-10.5
						C536.7,351.3,535.4,352.5,533.2,356.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M533.9,379.3c-0.4,2.4,0.2,4.9,1,6.6c0.8,1.7,2,2.6,2.8,2.3c0.8-0.3,1.2-1.6,1.3-3.2c0.1-1.7-0.2-3.7-0.7-5.8
						c-0.5-2.2-0.8-3.7-1-5.4c-0.2-1.6-0.2-3.3,0.2-5.7c-0.9,2.3-1.5,3.8-2.1,5.5C534.8,375.2,534.2,376.8,533.9,379.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M570.2,359.3c1.9-2.2,3.7-4.2,5-6c1.3-1.7,2.2-3.2,1.7-3.9c-0.5-0.7-2.3-0.5-4.4,0.8c-2.2,1.3-4.6,3.7-6,6.8
						c-1.4,3.1-1.9,5.4-2.3,7.6c-0.4,2.2-0.6,4.5-0.6,7.8c0.5-3.2,1.4-5.3,2.4-7.2C567.1,363.2,568.3,361.5,570.2,359.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M580.7,319.9c1.7-0.7,3-1.7,4-2.7c0.9-1,1.4-2.1,1.1-2.9c-0.4-0.8-1.6-1.1-3.1-0.8c-1.5,0.3-3.3,1.3-4.6,2.8
						c-1.3,1.6-2.1,2.8-2.8,4c-0.8,1.3-1.5,2.6-2.4,4.1c1.4-1.4,2.6-2.1,3.8-2.7C577.8,321.1,579,320.6,580.7,319.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-6.5,258.1c1.9-0.4,3.5-1.1,4.7-2c1.2-0.9,1.9-1.9,1.6-2.7c-0.2-0.8-1.4-1.3-3-1.2c-1.6,0.1-3.5,0.7-5.2,2
						c-1.7,1.3-2.7,2.3-3.8,3.3c-1,1-2.1,2-3.7,3.4c1.9-0.9,3.2-1.4,4.6-1.7C-9.8,258.7-8.5,258.5-6.5,258.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M8.4,246.2c-1.5,2.6-2.1,5.6-2,7.8c0.1,2.3,0.8,3.7,1.7,3.8c0.9,0.1,1.7-1.2,2.5-3.1c0.8-1.9,1.5-4.3,2-6.9
						c0.5-2.7,1.1-4.5,1.7-6.4c0.7-1.8,1.5-3.7,3.3-6.1c-2.1,2-3.5,3.5-4.9,5.1C11.2,242,9.9,243.6,8.4,246.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M544.8,223.7c-1.8-1.5-3.8-2.7-6-3.5c-2.1-0.9-4.5-1.4-6.9-1.4c-1.2,0-2.4,0.2-3.5,0.6c-1.2,0.4-2.3,0.9-3.2,1.6
						c-1.9,1.4-3.2,3.3-3.7,5.3c-0.3,1-0.3,2-0.2,3.1c0.2,1,0.5,1.8,0.9,2.5c0.9,1.4,2.1,2.3,3.1,2.7c1.1,0.4,2,0.4,2.6,0.2
						c0.6-0.2,0.8-0.4,0.8-0.6c-0.1-0.5-1.1-0.6-2.2-1.7c-1.1-1-2.1-3.1-1.2-5c0.4-1,1.3-2,2.4-2.7c0.6-0.4,1.2-0.6,1.9-0.8
						c0.7-0.2,1.5-0.3,2.2-0.3c3.2,0,7,1.4,10.2,3.5c3.3,2.2,5.7,4.8,7.4,7.5c0.9,1.3,1.6,2.7,2.2,4c0.6,1.4,1.1,2.7,1.5,4.1
						c0.8,2.8,1.2,5.8,1.2,9.2c0,3.4-0.4,7.1-1.6,11.5c1.4-4.3,2.2-8,2.5-11.5c0.3-3.4,0.2-6.5-0.3-9.6c-0.3-1.5-0.6-3.1-1-4.6
						c-0.5-1.5-1.1-3.1-1.9-4.7C550.7,230.1,548.4,226.7,544.8,223.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M547.9,236.8c-0.9-1.3-2.2-2.4-3.6-3.2c-1.5-0.8-3.3-1.2-5-1c-1.8,0.2-3.4,1.2-4.5,2.4c-1.1,1.2-1.7,2.9-1.6,4.4
						c0.1,1.7,1,2.9,1.8,3.6c0.9,0.7,1.9,1.1,2.7,1.1c0.8,0,1.4-0.2,1.8-0.5c0.3-0.3,0.4-0.6,0.3-0.8c-0.2-0.4-0.9-0.6-1.4-1.1
						c-0.3-0.3-0.6-0.6-0.8-1.1c-0.2-0.4-0.3-0.9-0.1-1.2c0.1-0.4,0.4-0.8,0.8-1.2c0.5-0.4,1.1-0.7,1.7-0.7c1.3-0.2,3.2,0.6,4.7,2
						c1.6,1.5,2.6,3.5,3.6,5.3c0.9,1.8,1.7,3.4,2.3,5.1c0.6,1.7,1.2,3.5,1.6,5.5c0.4,2,0.7,4.4,0.8,7.1c0.2-2.8,0.2-5.1,0.1-7.2
						c-0.1-2.1-0.3-4-0.6-5.9c-0.3-1.9-0.7-3.7-1.3-5.8c-0.3-1-0.6-2.1-1.1-3.3C549.5,239.4,548.9,238.1,547.9,236.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M613.1,514.1c0.5,1.2,0.7,2.4,0.8,3.5c0,1.1-0.1,2-0.4,2.7c-0.3,0.7-0.7,1.4-1.3,2.1c-0.6,0.6-1.4,1.3-2.4,1.7
						c-1.9,1-4,1.4-6.1,1.3c-2-0.1-3.6-0.8-4.7-1.5c-1.2-0.9-2-2.1-2.3-3.2c-0.4-1-0.3-2.3,0.1-3.4c0.4-1.1,0.9-2,1.4-2.8
						c0.5-0.8,0.9-1.4,0.9-2.1c0-0.6-0.6-1.3-1.9-1.6c-1.3-0.3-3.3,0-5.5,1.3c-1.1,0.7-2.1,1.6-3.1,2.9c-0.5,0.7-0.9,1.4-1.2,2.2
						c-0.4,0.8-0.6,1.8-0.8,2.7c-0.7,3.8,0.2,8.2,2.9,11.9c2.8,3.9,7.2,6.5,12,7.4c4.7,1,10.1,0.5,14.9-1.9c2.4-1.2,4.7-2.8,6.7-5
						c2-2.2,3.6-4.9,4.4-8c0.8-3,0.8-6.2,0.3-9c-0.6-2.9-1.7-5.4-3.1-7.7c-1.5-2.3-3.3-4.3-5.2-5.9c-1.9-1.6-4.1-2.9-6.1-3.7
						c-4.1-1.7-8-2.3-11.5-2.4c-7.1-0.1-12.9,1.4-18.6,3.1c-5.6,1.8-11.1,4.1-16.8,7.4c-5.7,3.2-11.6,7.4-17.6,13.4
						c6.6-5.4,12.9-8.7,18.8-11c5.9-2.3,11.4-3.5,16.8-4.2c5.4-0.6,11-0.7,16.3,0.5c2.7,0.6,5.3,1.6,7.5,3.1c1.2,0.8,2,1.6,2.8,2.6
						C611.9,511.7,612.6,512.9,613.1,514.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M586.9,487.9c2.8-1.8,5.2-4.2,7.1-6.8c1.9-2.6,3.2-5.3,3.9-7.8c0.7-2.5,0.8-4.7,0.6-6.3c-0.3-1.6-0.9-2.5-1.8-2.7
						c-1.8-0.3-3.7,2.1-6.3,5.3c-1.3,1.7-2.7,3.5-4.3,5.4c-1.6,2-3.3,4.1-5.1,6.3c-1.8,2.3-3.6,4.1-5.2,5.7
						c-1.7,1.6-3.2,3.1-4.9,4.4c-3.3,2.7-6.9,5.3-12.6,8.5c6.1-2.3,10.3-3.7,14.5-5.2c2.1-0.8,4.2-1.6,6.5-2.7
						C581.6,491.1,584.1,489.8,586.9,487.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M574,520.4c-1.2,2.3-1.3,5.1-0.8,7c0.5,1.9,1.5,3,2.4,2.8c0.9-0.2,1.4-1.4,1.8-3c0.4-1.6,0.7-3.5,0.8-5.6
						c0.2-2.2,0.5-3.7,1-5.3c0.5-1.5,1.3-3,2.9-4.9c-2,1.4-3.3,2.6-4.5,3.9C576.4,516.6,575.3,518,574,520.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M580.2,543.9c0.4,2.6,1.7,5,3.2,6.4c1.5,1.4,2.9,1.9,3.6,1.3c0.7-0.6,0.5-2,0-3.6c-0.5-1.6-1.4-3.6-2.5-5.5
						c-1.1-2-1.8-3.6-2.3-5.2c-0.5-1.6-0.9-3.3-0.8-5.9c-0.6,2.5-0.9,4.3-1.1,6.1C580,539.4,579.8,541.2,580.2,543.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M599.3,558c1.3,1.4,3,2.4,4.7,2.8c1.7,0.5,3.4,0.5,4.8,0.2c1.4-0.3,2.4-0.9,3-1.4c0.6-0.6,0.8-1.1,0.7-1.5
						c-0.4-0.8-2-0.9-3.9-1.2c-2-0.3-4.3-0.9-6.5-2.2c-1.2-0.7-2.1-1.5-2.9-2.3c-0.8-0.8-1.4-1.7-2-2.6c-1.2-1.8-2.1-3.9-3.1-7.1
						c0.5,3.3,0.8,5.6,1.4,7.8c0.3,1.1,0.6,2.3,1.2,3.5C597.2,555.2,598,556.6,599.3,558z"
                    />
                  </g>
                  <g>
                    <path
                      d="M613.7,553.2c2.2,1.1,4.7,1.3,6.5,1c1.8-0.3,3-1.2,2.9-2.1c-0.1-0.9-1.2-1.6-2.7-2.1c-1.5-0.5-3.5-0.9-5.5-1
						c-2.2-0.2-3.7-0.4-5.2-0.7c-1.5-0.4-3.1-0.9-5.1-2.2c1.7,1.7,2.9,2.8,4.2,3.9C610.1,551,611.5,552.1,613.7,553.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M623.6,545.9c1.5,0.1,2.9-0.6,3.8-1.4c0.8-0.8,1.1-1.9,0.7-2.6c-0.4-0.8-1.3-1.2-2.4-1.2c-1,0-2.2,0.3-3.3,1
						c-1.1,0.8-1.9,1.3-2.8,1.8c-0.9,0.5-1.8,0.9-3.2,1.2c1.4,0.3,2.5,0.5,3.5,0.7C620.9,545.6,622,545.8,623.6,545.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M584.7,454.4c0.8-4,0.4-8.1-0.6-11.1c-0.9-2.9-2.3-4.6-3.1-4.4c-0.9,0.3-1.1,2.3-1,5c0,2.8,0.2,6.4,0.3,10.1
						c0.1,3.8,0,6.5-0.4,9.1c-0.4,2.7-1,5.4-2.7,9.1c2.2-3.5,3.4-6,4.5-8.6C582.9,461.2,583.9,458.5,584.7,454.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M175.8,236.9c-0.3,3.3,0.7,6.7,2.2,8.7c1.4,2.1,3.1,2.9,3.7,2.4c0.7-0.5,0.5-2.1,0.1-4.1c-0.5-2.3-1-4.4-1.6-7.2
						c-0.5-2.8-0.6-4.8-0.4-6.9c0.2-2,0.7-4.2,2.3-6.9c-2.1,2.4-3.2,4.4-4.2,6.4C177,231.3,176.2,233.5,175.8,236.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M316.4,56.6c3.3,0.8,6.5,1.5,9,2c2.5,0.5,4.4,0.7,4.8-0.1c0.4-0.7-0.8-2.4-3.3-3.8c-2.5-1.5-6.3-2.6-10.1-2.5
						c-3.9,0.1-6.5,0.7-9.1,1.5c-2.5,0.8-5,1.6-8.5,3.4c3.6-1.2,6.2-1.4,8.7-1.5C310.5,55.6,313,55.9,316.4,56.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M166.4,149.7c1.4,3.4,3.9,6.3,6.1,7.9c2.2,1.7,4.1,2.1,4.7,1.5c0.6-0.7-0.3-2.3-1.7-4.4c-1.4-2.1-3.3-4.6-5.3-7.2
						c-2.1-2.7-3.3-4.7-4.4-6.9c-1.1-2.2-2-4.5-2.6-8.1c0.1,3.7,0.4,6.2,0.8,8.7C164.4,143.7,165,146.2,166.4,149.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M79.1,161.1c1.1-2.5,1.1-5.4,0.4-7.4c-0.7-2-1.8-3-2.6-2.8c-0.9,0.3-1.3,1.6-1.5,3.2c-0.3,1.7-0.4,3.7-0.5,5.9
						c-0.1,2.3-0.4,3.9-0.9,5.5c-0.5,1.6-1.3,3.2-3.1,5.1c2.1-1.5,3.4-2.8,4.7-4.2C76.7,165.2,77.9,163.7,79.1,161.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M340.8,260.3c3.5-6.8,4.5-14.6,2.8-15.1c-1.7-0.5-5.1,6.3-6.9,13.5c-0.9,3.8-1.5,6.4-2.2,9c-0.7,2.6-1.5,5.2-2.9,9
						c1.9-3.5,3.3-5.9,4.7-8.3C337.6,266.1,339,263.8,340.8,260.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M485,235.3c3.3,0,6.5-0.3,9-0.8c2.4-0.5,4.1-1.2,4.2-2.1c0-0.9-1.7-1.7-4.2-2.1c-2.6-0.4-6.1-0.3-9.4,0.6
						c-3.4,0.9-5.7,1.8-8,2.7c-2.4,1-4.7,1.9-7.8,3.1c3.5-0.8,5.9-1.1,8.3-1.3C479.3,235.3,481.6,235.3,485,235.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M596.5,141.5c-2.7,0.3-5.3,1.3-7,2.5c-1.7,1.2-2.6,2.4-2.2,3.2c0.4,0.8,1.9,0.9,3.8,0.6c1.9-0.3,4.3-1,6.5-2.1
						c2.4-1.1,4-1.9,5.8-2.5c1.7-0.7,3.5-1.2,6.3-1.7c-2.6-0.1-4.5-0.1-6.6-0.2C601.2,141.3,599.3,141.2,596.5,141.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M548.5,79c-2.4,1.8-4.6,3.6-6.2,5.2c-1.6,1.6-2.6,2.9-2.2,3.7c0.4,0.8,2.2,0.8,4.5-0.2c2.3-1,5.1-3,7.1-5.6
						c2-2.7,3.1-4.8,4-6.9c0.9-2.1,1.8-4.2,2.9-7.4c-1.5,3-3,4.7-4.5,6.3C552.6,75.7,550.9,77.1,548.5,79z"
                    />
                  </g>
                  <g>
                    <path
                      d="M482.8,101.2c3.5-0.6,7-1.5,9.5-2.4c2.6-0.9,4.3-1.9,4.2-2.8c-0.1-0.9-2.1-1.4-4.9-1.3c-2.8,0.1-6.6,0.8-10,2.3
						c-3.6,1.5-5.9,2.8-8.2,4.1c-2.3,1.3-4.6,2.7-7.9,4.7c3.6-1.5,6.1-2.3,8.6-2.9C476.6,102.3,479.2,101.9,482.8,101.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M503.3,526.1c2.5-1.7,3.3-4.9,2-6c-1.4-1.1-4.3,0.4-5.4,3.3c-1.2,2.9-1.3,4.3-2.8,7.1
						C499.4,528.3,500.7,527.9,503.3,526.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M281.3,518.4c2,0.3,3.8,0.4,5.3,0.2c1.5-0.1,2.7-0.5,3-1.3c0.3-0.8-0.5-2-2.2-2.8c-1.6-0.8-4.1-1.2-6.5-0.5
						c-2.4,0.7-3.9,1.5-5.3,2.3c-1.4,0.9-2.8,1.8-4.6,3.2c2.1-1,3.6-1.3,5.1-1.4C277.8,518,279.3,518.1,281.3,518.4z"
                    />
                  </g>
                </g>
              </g>
              <g>
                <defs>
                  <path
                    id="SVGID_00000065075374244430516710000014446267288283259536_"
                    d="M207.5,153.7c30.7,28.1,144,167.6,122.2,191.4
					c-21.8,23.8-170.5-77.2-201.2-105.4c-30.7-28.1-56.6-93.3-37.7-113.9C109.6,105.3,176.8,125.6,207.5,153.7z"
                  />
                </defs>
                <clipPath id="SVGID_00000046325989595480751360000017558212872582778264_">
                  <use
                    xlinkHref="#SVGID_00000065075374244430516710000014446267288283259536_"
                    overflow="visible"
                  />
                </clipPath>
              </g>
            </g>
          </g>
          <g id="Peddle7">
            <image
              overflow="visible"
              opacity="0.7"
              width="1777"
              height="914"
              xlinkHref={Shadow6}
              transform="matrix(0.24 0 0 0.24 -33.2107 211.2964)"
            ></image>
            <g>
              <g>
                <defs>
                  <path
                    id="SVGID_00000165193611625849579510000009498287214017916087_"
                    d="M120.2,262.5c41.6-1.8,220.4,16.7,221.8,48.9
					c1.4,32.2-175.2,66-216.8,67.7C83.7,381,19.2,353.2,18,325.3C16.8,297.4,78.7,264.3,120.2,262.5z"
                  />
                </defs>
                <clipPath id="SVGID_00000065040591005596857320000007814853253438045118_">
                  <use
                    xlinkHref="#SVGID_00000165193611625849579510000009498287214017916087_"
                    overflow="visible"
                  />
                </clipPath>
              </g>
              <g>
                <defs>
                  <path
                    id="SVGID_00000039122599420489734720000009055048282304022182_"
                    d="M120.2,262.5c41.6-1.8,220.4,16.7,221.8,48.9
					c1.4,32.2-175.2,66-216.8,67.7C83.7,381,19.2,353.2,18,325.3C16.8,297.4,78.7,264.3,120.2,262.5z"
                  />
                </defs>
                <use
                  xlinkHref="#SVGID_00000039122599420489734720000009055048282304022182_"
                  overflow="visible"
                  fill="#36B657"
                />
                <clipPath id="SVGID_00000006687630332671239140000011604142448911339685_">
                  <use
                    xlinkHref="#SVGID_00000039122599420489734720000009055048282304022182_"
                    overflow="visible"
                  />
                </clipPath>
                <g
                  opacity="0.2"
                  clipPath="url(#SVGID_00000006687630332671239140000011604142448911339685_)"
                >
                  <g>
                    <g>
                      <path
                        d="M312.1,219.6c8.1-1.2,15.4-3.8,20.8-7c5.4-3.2,8.8-6.9,8.1-10.4c-0.7-3.4-5.5-5.7-12.2-5.8c-6.7-0.1-15.4,2-22.9,6.8
							c-7.7,4.9-12.4,9-17.2,13c-4.7,4-9.4,8-16.3,13.7c8.1-3.8,13.9-5.6,19.8-6.9C297.9,221.7,303.8,220.8,312.1,219.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M314.5,239.9c7,1.8,13.9,2.1,19.5,1.1c5.5-1,9.7-3.2,10.5-6.6c0.8-3.4-2.4-7.3-8.1-9.9c-5.6-2.6-13.8-3.8-21.8-2.1
							c-8.2,1.8-13.5,3.9-18.8,6c-5.3,2.1-10.5,4.2-18,7.1c8-0.8,13.5-0.4,18.9,0.5C302,236.8,307.2,238.1,314.5,239.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M303.9,258.7c4.2,3.4,8.8,5.1,13,5.4c4.2,0.3,8.1-0.9,10-3.9c1.9-3,1.1-7.2-2.1-10.9c-3.1-3.7-8.6-6.8-14.9-7.1
							c-6.5-0.4-10.9,0.2-15.2,0.7c-4.3,0.5-8.6,1-14.6,1.5c5.8,1.7,9.5,3.7,12.9,5.9C296.4,252.6,299.5,255.2,303.9,258.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M282.6,276.1c1.5,4.7,4.5,8,8,9.7c3.4,1.7,7.3,1.9,10.2-0.1c2.9-2,4.1-5.7,3.3-9.8c-0.7-4-3.4-8.3-7.9-11
							c-4.6-2.8-8.1-4.2-11.5-5.8c-3.4-1.6-6.6-3.2-10.9-6.1c2.8,4.3,4.2,7.7,5.3,11.2C280.2,267.7,281.1,271.2,282.6,276.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M262,284.1c-1.5,5-1.3,9.6,0.1,13.5c1.3,3.8,3.8,7,7.3,7.6c3.4,0.7,7.1-1.5,9.3-5.8c2.2-4.2,3-10.5,0.8-16.4
							c-2.3-6-4.6-9.7-6.8-13.4c-2.3-3.6-4.5-7.1-7.6-12.2c1,5.8,0.8,9.9,0.1,13.8C264.5,275.2,263.5,279,262,284.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M238.4,276.8c-4.1,3.3-6.7,7-8.2,10.8c-0.3,1-0.7,1.9-0.9,2.9c-0.2,1-0.3,1.9-0.3,2.8c0,1.8,0.4,3.6,1.4,5
							c1,1.4,2.6,2.3,4.6,2.6c1,0.1,2.1,0.1,3.2-0.1c1.1-0.2,2.4-0.5,3.5-1.1c4.8-2.1,9.8-7.2,11.7-13.9c1.9-6.8,2-11.6,2-16.2
							c-0.1-4.6-0.3-9.1-0.8-15.3c-1.6,6-3.8,9.7-6.4,12.9C245.6,270.5,242.6,273.3,238.4,276.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M221.4,254.8c-5.9,0.7-10.8,2.7-14.6,5.4c-3.7,2.7-6.2,6.2-5.7,9.7c0.5,3.5,4.2,6.1,9.6,6.6
							c5.3,0.5,12.3-1.2,17.7-5.6c5.6-4.6,8.8-8.3,11.8-12c3-3.7,5.9-7.3,10.2-12.6c-5.6,3.6-10,5.2-14.3,6.3
							C231.8,253.5,227.4,254.1,221.4,254.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M214.7,232.3c-5.8-1.9-11.2-2.4-15.9-1.9c-1.2,0.2-2.3,0.3-3.3,0.7c-1.1,0.3-2,0.7-2.9,1.1c-1.7,0.9-3.1,2.2-3.7,3.8
							c-0.6,1.6-0.4,3.5,0.5,5.3c0.5,0.9,1.2,1.9,2,2.7c0.9,0.9,1.9,1.8,3.1,2.5c4.8,3.1,12.4,4.8,19.7,3.2c7.5-1.7,12.3-4,16.9-6.2
							c4.5-2.3,9-4.7,15-8.3c-6.9,1.6-11.7,1.4-16.4,0.8C225.1,235.5,220.6,234.3,214.7,232.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M221.6,214.1c-3.8-4.1-7-7.1-10.2-9.4c-1.6-1.2-3.2-2.2-4.8-3.1c-1.6-0.9-3.3-1.6-5.1-1.4c-1.7,0.1-3.3,1.2-4.4,3.3
							c-1.1,2.1-1.4,5.3-0.6,8.8c0.8,3.5,2.7,7.3,5.7,10.4c2.9,3.1,6.8,5.6,11,6.8c4.4,1.3,7.9,1.6,11.1,1.7
							c3.2,0.1,5.9-0.1,8.6-0.4c5.4-0.6,10.5-1.4,17.9-2.1c-7.3-1.5-12.2-3.2-16.6-5.4c-2.2-1.1-4.3-2.4-6.4-3.9
							C225.6,217.9,223.5,216.2,221.6,214.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M239.5,196.3c-0.1-5.5-0.9-9.8-2.3-13.6c-0.7-1.9-1.5-3.7-2.5-5.3c-1-1.6-2.2-2.9-3.9-3.6c-1.6-0.7-3.5-0.5-5.4,0.6
							c-1.9,1.1-3.7,3.3-4.9,6.3c-1.2,3-1.8,6.7-1.4,10.5c0.4,3.8,1.7,7.8,3.9,11.1c2.3,3.5,4.6,5.9,6.7,7.9c2.2,2,4.2,3.6,6.3,5.1
							c4.1,3,8.3,5.7,14,9.6c-4.3-5.5-6.5-9.9-8-14.2c-0.7-2.2-1.3-4.4-1.8-6.8C239.9,201.7,239.6,199.1,239.5,196.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M259.2,191.2c2.3-5.4,3.1-10.6,2.9-15.2c-0.1-1.2-0.2-2.2-0.4-3.3c-0.2-1.1-0.5-2-0.9-2.9c-0.8-1.8-1.9-3.2-3.5-4
							c-1.6-0.8-3.4-0.8-5.3,0c-1,0.4-2,1-2.9,1.8c-0.9,0.8-1.9,1.7-2.8,2.9c-3.5,4.5-5.8,11.9-4.6,19.3c1.3,7.6,3.3,12.4,5.3,17
							c2.1,4.6,4.3,9,7.7,15c-1.4-6.8-1-11.6-0.1-16C255.5,201.1,256.9,196.9,259.2,191.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M281.1,191.4c4.3-4.1,7.2-8,9.3-11.7c1-1.9,1.9-3.7,2.4-5.6c0.5-1.8,0.7-3.7,0-5.3c-0.6-1.6-2.1-2.9-4.3-3.4
							c-2.2-0.6-5.1-0.3-8.2,0.8c-3.1,1.2-6.3,3.3-8.9,6.2c-2.6,2.9-4.7,6.5-5.8,10.5c-1.2,4-1.6,7.4-1.8,10.4
							c-0.2,3-0.2,5.7-0.2,8.3c0.1,5.2,0.3,10.3,0,17.6c2.4-6.9,4.7-11.5,7.3-15.6c1.3-2.1,2.8-4,4.4-6
							C277.1,195.6,278.9,193.5,281.1,191.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M256.7,233.3c-1.5,3.2,1.5,6,5,5.6c3.5-0.4,5.8-3.7,3.7-6.5c-2.2-2.9-3.7-3.9-5.1-7
							C259.6,228.7,258.2,230,256.7,233.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M261.4,230.6c-1.4,1-1.8,2.3-1.5,3.7c0.3,1.4,1.3,2.8,2.8,3.6c1.5,0.9,3.3,0.9,4.7,0.2c1.4-0.7,2.3-2.2,2.1-4.1
							c-0.2-2-0.6-3.3-0.9-4.7c-0.3-1.3-0.6-2.6-0.7-4.4c-0.9,1.5-1.9,2.4-2.9,3.1C263.9,228.9,262.8,229.6,261.4,230.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M264.9,232.2c-1.3-0.6-2.1-0.2-2.9,0.7c-0.7,0.9-1.4,2.4-1.5,4.2c-0.1,1.7,0.7,3.3,2.1,4.1c1.4,0.8,3.3,0.7,4.8-0.7
							c1.5-1.4,2.2-2.7,2.8-3.8c0.6-1.2,1.2-2.3,2.1-3.6c-1.5,0.5-2.7,0.5-3.8,0.3C267.3,233.2,266.2,232.7,264.9,232.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M267.4,236.8c0-0.7-0.3-1.3-0.7-1.8c-0.5-0.5-1.1-1-1.9-1.2c-0.8-0.3-1.6-0.3-2.4-0.1c-0.8,0.2-1.5,0.6-2.1,1.3
							c-0.6,0.6-1.1,1.3-1.4,2c-0.3,0.7-0.6,1.4-0.6,2c0,0.7,0.1,1.3,0.5,2c0.4,0.6,1.1,1.3,2.1,1.7c1,0.5,2.1,0.8,3,1
							c0.9,0.2,1.7,0.2,2.5,0.1c1.6-0.1,3.1-0.5,4.5-1.6c-0.8,0.1-1.5-0.2-2-0.6c-0.5-0.4-0.8-0.8-1-1.3c-0.2-0.5-0.3-1-0.4-1.6
							C267.4,238,267.5,237.5,267.4,236.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M266.9,240c0.8-1.4,0.7-2.5-0.1-3.6c-0.7-1.1-2-2.2-3.8-2.6c-1.7-0.4-3.4,0.1-4.5,1.4c-1.1,1.3-1.4,3.5-0.4,5.4
							c1.1,2,2.1,3.1,3.2,4.1c1,1,2,2,3.2,3.4c-0.2-1.8,0-3.1,0.5-4.3C265.4,242.6,266.1,241.5,266.9,240z"
                      />
                    </g>
                    <g>
                      <path
                        d="M264.2,244.1c1.7-1.3,2.4-3.2,2.2-4.9c-0.2-1.7-1.4-3.1-3.1-3.7c-1.7-0.6-3.4-0.3-4.7,0.5c-1.3,0.8-2.1,2-2.1,3.8
							c0,1.9,0,3.3,0,4.7c-0.1,1.4-0.4,2.8-1.2,4.5c1.6-1,2.9-1.7,4.3-2.3C260.9,246.1,262.4,245.4,264.2,244.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M258.2,243.2c3.6,1.4,6.6-1.7,6.2-5.2c-0.4-3.5-4.2-5.7-7.3-3.5c-3.2,2.3-4.3,3.9-7.7,5.4
							C253,240.5,254.5,241.8,258.2,243.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M254.5,239.4c1.8,3.2,5.8,2.6,7.7-0.4c1.8-3,0.5-6.9-3.1-7c-3.7-0.1-5.5,0.5-8.9-0.3
							C252.5,234.3,252.7,236.1,254.5,239.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M255,235.1c0,1.7,1.1,2.7,2.4,3c1.3,0.3,3-0.2,4.3-1.3c1.4-1.1,2.1-2.6,2.3-3.8c0.1-1.2-0.4-2.3-1.8-2.8
							c-2.9-1-4.7-1.1-6.9-3C255.8,230.1,255,231.7,255,235.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M299.7,201.9c5.7-1.8,10.2-4.8,13.4-8.2c3.2-3.4,5-7.2,3.9-10.6c-1.1-3.3-5.3-5.2-10.8-4.6c-5.4,0.5-12,3.6-16.6,9.1
							c-4.7,5.7-7.1,10.1-9.4,14.4c-2.2,4.4-4.4,8.6-7.4,14.7c4.8-4.8,8.8-7.3,12.8-9.3C289.6,205.4,293.8,203.9,299.7,201.9z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M624.2,225.2c3.8,4.2,8.2,7.3,12.1,9.2c3.9,1.8,7.4,2.3,9.1,0.6c1.7-1.7,1.1-5.3-1.4-9.3c-2.6-3.9-7.1-8.1-12.8-10.5
							c-5.8-2.5-10-3.6-14.1-4.8c-4.1-1.1-8.3-2.3-14.2-4.1c5.3,3.1,8.6,5.8,11.6,8.7C617.5,217.9,620.3,220.9,624.2,225.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M613.5,234.5c1.7,4.7,4.2,8.8,6.9,11.6c2.7,2.8,5.6,4.4,7.9,3.4c2.2-0.9,3.3-4.2,2.6-8.5c-0.7-4.2-3.2-9.4-7.3-13.3
							c-4.2-4-7.5-6.2-10.7-8.5c-3.3-2.2-6.5-4.4-11.1-7.6c3.6,4.3,5.5,7.6,7.1,11C610.4,226.1,611.7,229.6,613.5,234.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M598.5,235.7c-0.3,3.7,0.5,7,2,9.6c1.5,2.5,3.7,4.3,6.1,4.2c2.4-0.1,4.5-2.2,5.4-5.4c0.9-3.2,0.5-7.6-1.7-11.3
							c-2.3-3.8-4.3-6.2-6.3-8.5c-2-2.3-3.9-4.5-6.5-7.8c1.3,4,1.6,6.8,1.6,9.7C599.1,229,598.9,231.8,598.5,235.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M580.4,230.2c-2.1,2.7-2.8,5.7-2.5,8.4c0.3,2.6,1.7,4.9,4,5.8c2.3,0.9,4.9,0.1,6.9-1.9c2-2,3.4-5.2,3.2-8.8
							c-0.2-3.7-0.7-6.3-1.1-8.9c-0.4-2.5-0.7-5.1-0.8-8.6c-1.4,3.3-2.8,5.4-4.3,7.4C584.2,225.6,582.5,227.5,580.4,230.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M567.7,221.5c-3.4,1.1-6,3-7.7,5.3c-1.7,2.3-2.5,4.9-1.5,7.2c0.9,2.2,3.6,3.5,7,3.1c3.3-0.4,7.2-2.4,9.7-5.9
							c2.6-3.6,3.8-6.4,5-9.1c1.2-2.7,2.3-5.4,4-9.1c-2.9,2.8-5.4,4.3-7.8,5.4C573.9,219.5,571.3,220.4,567.7,221.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M562.8,205.2c-3.5-1.1-6.6-1.1-9.4-0.5c-0.7,0.2-1.4,0.3-2,0.6c-0.6,0.3-1.2,0.6-1.7,0.9c-1.1,0.7-1.9,1.6-2.3,2.8
							c-0.4,1.1-0.3,2.4,0.3,3.6c0.3,0.6,0.7,1.2,1.3,1.8c0.6,0.6,1.2,1.1,2,1.6c3.1,1.9,8,2.8,12.5,1.3c4.6-1.6,7.4-3.3,10.1-5.1
							c2.6-1.8,5.1-3.7,8.4-6.4c-4.1,1.4-7.1,1.6-9.9,1.4C569.2,206.9,566.4,206.2,562.8,205.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M568.7,186.9c-2.6-3.1-5.7-5.2-8.7-6.3c-3-1.1-5.9-1.1-7.7,0.5c-1.8,1.6-1.9,4.8-0.1,8.1c1.8,3.3,5.4,6.6,10.1,8
							c4.8,1.4,8.2,1.8,11.5,2.1c3.3,0.3,6.5,0.5,11.2,0.9c-4.3-1.8-6.8-3.7-9.1-5.8C573.6,192.4,571.5,190.1,568.7,186.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M579.1,174.3c-1.1-4.1-3-7.4-5.1-9.9c-0.6-0.6-1.1-1.2-1.7-1.7c-0.6-0.5-1.2-0.9-1.8-1.2c-1.2-0.6-2.4-0.9-3.6-0.7
							c-1.2,0.3-2.2,1.1-2.8,2.4c-0.3,0.6-0.6,1.4-0.8,2.2c-0.2,0.8-0.3,1.8-0.2,2.8c0.1,4,2,9,5.8,12.6c3.9,3.7,7,5.5,10.1,7.2
							c3.1,1.7,6.2,3.3,10.6,5.4c-3.6-3.3-5.4-6.2-6.8-9.1C581.3,181.5,580.3,178.5,579.1,174.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M592.2,171.1c0.9-3.8,1.3-6.8,1.4-9.5c0.1-1.3,0-2.7-0.1-4c-0.1-1.3-0.4-2.5-1.2-3.5c-0.8-0.9-2-1.4-3.6-1.2
							c-1.6,0.2-3.6,1.2-5.3,3c-1.7,1.8-3.1,4.4-3.7,7.3c-0.6,2.9-0.6,6,0.3,8.9c0.9,3,2.1,5.2,3.3,7c1.2,1.8,2.4,3.3,3.6,4.8
							c2.4,2.9,4.9,5.5,8.1,9.5c-2-4.8-2.9-8.3-3.3-11.7c-0.2-1.7-0.3-3.4-0.3-5.2C591.6,175,591.7,173.1,592.2,171.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M609.4,174.5c3.1-2.2,5.3-4.3,6.9-6.6c0.8-1.1,1.5-2.3,2.1-3.5c0.5-1.2,0.8-2.4,0.6-3.6c-0.2-1.2-1.1-2.2-2.5-2.8
							c-1.4-0.6-3.3-0.8-5.5-0.4c-2.2,0.4-4.5,1.5-6.6,3.3c-2,1.7-3.8,4-4.8,6.5c-1.1,2.7-1.6,4.9-1.9,6.9c-0.3,2-0.4,3.8-0.5,5.6
							c-0.1,3.5,0,6.9-0.1,11.8c1.5-4.6,3.1-7.6,5.1-10.1c1-1.3,2-2.5,3.2-3.6C606.4,176.8,607.8,175.6,609.4,174.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M619.9,183.9c4-0.8,7.3-2.3,9.9-4.3c0.6-0.5,1.2-1,1.7-1.5c0.5-0.5,1-1.1,1.3-1.6c0.7-1.1,1.1-2.3,1-3.6
							c-0.2-1.2-0.9-2.3-2.1-3.1c-0.6-0.4-1.3-0.8-2.1-1c-0.8-0.2-1.7-0.4-2.7-0.5c-3.9-0.3-9.1,1.3-12.8,4.9
							c-3.8,3.7-5.8,6.7-7.7,9.6c-1.8,3-3.5,6-5.6,10.2c3.4-3.4,6.2-5.1,9.1-6.3C612.8,185.6,615.8,184.7,619.9,183.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M628.3,196.5c4,0.9,7.4,1,10.3,0.8c1.5-0.1,2.9-0.4,4.1-0.8c1.2-0.4,2.4-1,3.1-2c0.7-1,0.8-2.3,0.3-3.8
							c-0.5-1.5-1.8-3-3.7-4.4c-1.9-1.3-4.3-2.3-7-2.7c-2.7-0.4-5.6-0.2-8.3,0.7c-2.8,0.9-4.9,2-6.7,3c-1.8,1-3.3,2.1-4.8,3.1
							c-3,2.1-5.8,4.2-10.1,6.8c4.9-1.3,8.4-1.8,11.8-1.8c1.7,0,3.4,0,5.2,0.2C624.3,195.8,626.3,196,628.3,196.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M594.8,198.8c-2.4,0.4-2.8,3.2-1.3,5c1.6,1.9,4.4,1.9,5.2-0.4c0.8-2.4,0.8-3.7,2-5.7
							C598.6,198.6,597.3,198.3,594.8,198.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M598.2,200.4c-1.1-0.4-2-0.1-2.7,0.6c-0.7,0.7-1.1,1.8-1,3c0.1,1.2,0.8,2.2,1.7,2.7c0.9,0.5,2.2,0.5,3.2-0.4
							c1-0.9,1.7-1.6,2.3-2.3c0.6-0.7,1.3-1.4,2.2-2.1c-1.2,0-2.1-0.2-2.9-0.4C600.2,201.2,599.3,200.8,598.2,200.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M598.6,203c-0.2-0.9-0.7-1.3-1.5-1.4c-0.8-0.1-1.9,0.1-3,0.8c-1,0.6-1.7,1.7-1.6,2.8c0.1,1.1,0.9,2.2,2.2,2.5
							c1.4,0.3,2.4,0.2,3.3,0.1c0.9-0.1,1.8-0.2,2.9-0.2c-0.9-0.7-1.3-1.4-1.6-2.1C599,204.8,598.8,204,598.6,203z"
                      />
                    </g>
                    <g>
                      <path
                        d="M597,206.3c0.4-0.3,0.6-0.7,0.8-1.1c0.1-0.5,0.1-1,0-1.6c-0.1-0.5-0.4-1.1-0.9-1.4c-0.4-0.4-1-0.6-1.6-0.7
							c-0.6-0.1-1.2-0.1-1.7,0c-0.5,0.1-1,0.2-1.4,0.4c-0.4,0.2-0.7,0.6-0.9,1c-0.2,0.5-0.3,1.1-0.2,1.9c0.1,0.8,0.3,1.5,0.6,2.1
							c0.3,0.6,0.6,1.1,0.9,1.5c0.7,0.8,1.5,1.6,2.7,2c-0.4-0.5-0.5-1-0.4-1.4c0-0.4,0.2-0.8,0.4-1.1c0.2-0.3,0.5-0.6,0.7-0.9
							C596.3,206.7,596.6,206.6,597,206.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M594.9,207.2c1.1-0.1,1.7-0.6,2.1-1.5c0.3-0.8,0.5-2,0-3.2c-0.4-1.1-1.4-1.9-2.6-2c-1.2-0.1-2.5,0.5-3.2,1.9
							c-0.7,1.4-0.9,2.4-1.1,3.4c-0.2,1-0.4,1.9-0.7,3.2c0.9-0.8,1.8-1.2,2.7-1.4C592.9,207.4,593.8,207.3,594.9,207.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M591.5,207.3c1.4,0.5,2.8,0.1,3.7-0.7c0.9-0.8,1.2-2,0.9-3.2c-0.3-1.2-1.2-2.1-2.1-2.5c-0.9-0.4-2-0.4-3,0.3
							c-1.1,0.7-1.9,1.3-2.7,1.8c-0.8,0.5-1.7,0.9-3.1,1.1c1.2,0.5,2.1,1,3,1.6C589.1,206.1,590.1,206.7,591.5,207.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M589.7,203.4c0.6,2.6,3.6,3.1,5.4,1.5c1.8-1.6,1.7-4.6-0.8-5.5c-2.6-1-3.9-1-6.1-2.3
							C589.3,199.5,589.1,200.8,589.7,203.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M590.5,199.9c-1.1,2.2,0.8,4.3,3.2,4.2c2.4-0.1,4.2-2.4,2.8-4.5c-1.4-2.2-2.4-3-3.3-5.2
							C592.6,196.7,591.6,197.6,590.5,199.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M593.1,198.5c-0.9,0.7-1.1,1.7-0.8,2.5c0.4,0.9,1.3,1.6,2.4,2c1.2,0.3,2.3,0.2,3.1-0.2c0.8-0.4,1.2-1.1,0.9-2.1
							c-0.5-2-1.2-3.1-0.9-5.1C596.3,197,595.1,197.2,593.1,198.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M629.5,211.2c3.3,2.5,6.7,4,9.9,4.5c3.2,0.5,6.1,0.1,7.6-1.9c1.5-1.9,0.9-5.1-1.5-8c-2.4-2.9-6.8-5.5-11.7-6
							c-5.1-0.5-8.5-0.1-11.9,0.2c-3.4,0.4-6.7,0.8-11.3,1.5c4.6,0.9,7.6,2.2,10.3,3.7C623.6,206.8,626.1,208.6,629.5,211.2z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M241.1,523.3c3.8,4.2,8.2,7.3,12.1,9.2c3.9,1.8,7.4,2.3,9.1,0.6c1.7-1.7,1.1-5.3-1.4-9.3c-2.6-3.9-7.1-8.1-12.8-10.5
							c-5.8-2.5-10-3.6-14.1-4.8c-4.1-1.1-8.3-2.3-14.2-4.1c5.3,3.1,8.6,5.8,11.6,8.7C234.3,516,237.1,519,241.1,523.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M230.3,532.5c1.7,4.7,4.2,8.8,6.9,11.6c2.7,2.8,5.6,4.4,7.9,3.4c2.2-0.9,3.3-4.2,2.6-8.5c-0.7-4.2-3.2-9.4-7.3-13.3
							c-4.2-4-7.5-6.2-10.7-8.5c-3.3-2.2-6.5-4.4-11.1-7.6c3.6,4.3,5.5,7.6,7.1,11C227.3,524.1,228.6,527.6,230.3,532.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M215.4,533.7c-0.3,3.7,0.5,7,2,9.6c1.5,2.5,3.7,4.3,6.1,4.2c2.4-0.1,4.5-2.2,5.4-5.4c0.9-3.2,0.5-7.6-1.7-11.3
							c-2.3-3.8-4.3-6.2-6.3-8.5c-2-2.3-3.9-4.5-6.5-7.8c1.3,4,1.6,6.8,1.6,9.7C216,527.1,215.7,529.9,215.4,533.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M197.2,528.3c-2.1,2.7-2.8,5.7-2.5,8.4c0.3,2.6,1.7,4.9,4,5.8c2.3,0.9,4.9,0.1,6.9-1.9c2-2,3.4-5.2,3.2-8.8
							c-0.2-3.7-0.7-6.3-1.1-8.9c-0.4-2.5-0.7-5.1-0.8-8.6c-1.4,3.3-2.8,5.4-4.3,7.4C201.1,523.7,199.4,525.5,197.2,528.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M184.6,519.6c-3.4,1.1-6,3-7.7,5.3c-1.7,2.3-2.5,4.9-1.5,7.2c0.9,2.2,3.6,3.5,7,3.1c3.3-0.4,7.2-2.4,9.7-5.9
							c2.6-3.6,3.8-6.4,5-9.1c1.2-2.7,2.3-5.4,4-9.1c-2.9,2.8-5.4,4.3-7.8,5.4C190.7,517.6,188.1,518.4,184.6,519.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M179.7,503.2c-3.5-1.1-6.6-1.1-9.4-0.5c-0.7,0.2-1.4,0.3-2,0.6c-0.6,0.3-1.2,0.6-1.7,0.9c-1.1,0.7-1.9,1.6-2.3,2.8
							c-0.4,1.1-0.3,2.4,0.3,3.6c0.3,0.6,0.7,1.2,1.3,1.8c0.6,0.6,1.2,1.1,2,1.6c3.1,1.9,8,2.8,12.5,1.3c4.6-1.6,7.4-3.3,10.1-5.1
							c2.6-1.8,5.1-3.7,8.4-6.4c-4.1,1.4-7.1,1.6-9.9,1.4C186,504.9,183.3,504.3,179.7,503.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M185.6,484.9c-2.6-3.1-5.7-5.2-8.7-6.3c-3-1.1-5.9-1.1-7.7,0.5c-1.8,1.6-1.9,4.8-0.1,8.1c1.8,3.3,5.4,6.6,10.1,8
							c4.8,1.4,8.2,1.8,11.5,2.1c3.3,0.3,6.5,0.5,11.2,0.9c-4.3-1.8-6.8-3.7-9.1-5.8C190.4,490.4,188.4,488.1,185.6,484.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M195.9,472.3c-1.1-4.1-3-7.4-5.1-9.9c-0.6-0.6-1.1-1.2-1.7-1.7c-0.6-0.5-1.2-0.9-1.8-1.2c-1.2-0.6-2.4-0.9-3.6-0.7
							c-1.2,0.3-2.2,1.1-2.8,2.4c-0.3,0.6-0.6,1.4-0.8,2.2c-0.2,0.8-0.3,1.8-0.2,2.8c0.1,4,2,9,5.8,12.6c3.9,3.7,7,5.5,10.1,7.2
							c3.1,1.7,6.2,3.3,10.6,5.4c-3.6-3.3-5.4-6.2-6.8-9.1C198.2,479.5,197.1,476.5,195.9,472.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M209.1,469.2c0.9-3.8,1.3-6.8,1.4-9.5c0.1-1.3,0-2.7-0.1-4c-0.1-1.3-0.4-2.5-1.2-3.5c-0.8-0.9-2-1.4-3.6-1.2
							c-1.6,0.2-3.6,1.2-5.3,3c-1.7,1.8-3.1,4.4-3.7,7.3c-0.6,2.9-0.6,6,0.3,8.9c0.9,3,2.1,5.2,3.3,7c1.2,1.8,2.4,3.3,3.6,4.8
							c2.4,2.9,4.9,5.5,8.1,9.5c-2-4.8-2.9-8.3-3.3-11.7c-0.2-1.7-0.3-3.4-0.3-5.2C208.4,473,208.6,471.1,209.1,469.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M226.2,472.6c3.1-2.2,5.3-4.3,6.9-6.6c0.8-1.1,1.5-2.3,2.1-3.5c0.5-1.2,0.8-2.4,0.6-3.6c-0.2-1.2-1.1-2.2-2.5-2.8
							c-1.4-0.6-3.3-0.8-5.5-0.4c-2.2,0.4-4.5,1.5-6.6,3.3c-2,1.7-3.8,4-4.8,6.5c-1.1,2.7-1.6,4.9-1.9,6.9c-0.3,2-0.4,3.8-0.5,5.6
							c-0.1,3.5,0,6.9-0.1,11.8c1.5-4.6,3.1-7.6,5.1-10.1c1-1.3,2-2.5,3.2-3.6C223.3,474.9,224.6,473.7,226.2,472.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M236.8,481.9c4-0.8,7.3-2.3,9.9-4.3c0.6-0.5,1.2-1,1.7-1.5c0.5-0.5,1-1.1,1.3-1.6c0.7-1.1,1.1-2.3,1-3.6
							c-0.2-1.2-0.9-2.3-2.1-3.1c-0.6-0.4-1.3-0.8-2.1-1c-0.8-0.2-1.7-0.4-2.7-0.5c-3.9-0.3-9.1,1.3-12.8,4.9
							c-3.8,3.7-5.8,6.7-7.7,9.6c-1.8,3-3.5,6-5.6,10.2c3.4-3.4,6.2-5.1,9.1-6.3C229.7,483.6,232.7,482.8,236.8,481.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M245.2,494.6c4,0.9,7.4,1,10.3,0.8c1.5-0.1,2.9-0.4,4.1-0.8c1.2-0.4,2.4-1,3.1-2c0.7-1,0.8-2.3,0.3-3.8
							c-0.5-1.5-1.8-3-3.7-4.4c-1.9-1.3-4.3-2.3-7-2.7c-2.7-0.4-5.6-0.2-8.3,0.7c-2.8,0.9-4.9,2-6.7,3c-1.8,1-3.3,2.1-4.8,3.1
							c-3,2.1-5.8,4.2-10.1,6.8c4.9-1.3,8.4-1.8,11.8-1.8c1.7,0,3.4,0,5.2,0.2C241.2,493.9,243.1,494.1,245.2,494.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M211.7,496.8c-2.4,0.4-2.8,3.2-1.3,5c1.6,1.9,4.4,1.9,5.2-0.4c0.8-2.4,0.8-3.7,2-5.7
							C215.4,496.7,214.2,496.4,211.7,496.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M215,498.4c-1.1-0.4-2-0.1-2.7,0.6c-0.7,0.7-1.1,1.8-1,3c0.1,1.2,0.8,2.2,1.7,2.7c0.9,0.5,2.2,0.5,3.2-0.4
							c1-0.9,1.7-1.6,2.3-2.3c0.6-0.7,1.3-1.4,2.2-2.1c-1.2,0-2.1-0.2-2.9-0.4C217,499.2,216.2,498.9,215,498.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M215.5,501.1c-0.2-0.9-0.7-1.3-1.5-1.4c-0.8-0.1-1.9,0.1-3,0.8c-1,0.6-1.7,1.7-1.6,2.8c0.1,1.1,0.9,2.2,2.2,2.5
							c1.4,0.3,2.4,0.2,3.3,0.1c0.9-0.1,1.8-0.2,2.9-0.2c-0.9-0.7-1.3-1.4-1.6-2.1C215.9,502.9,215.7,502.1,215.5,501.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M213.9,504.3c0.4-0.3,0.6-0.7,0.8-1.1c0.1-0.5,0.1-1,0-1.6c-0.1-0.5-0.4-1.1-0.9-1.4c-0.4-0.4-1-0.6-1.6-0.7
							c-0.6-0.1-1.2-0.1-1.7,0c-0.5,0.1-1,0.2-1.4,0.4c-0.4,0.2-0.7,0.6-0.9,1c-0.2,0.5-0.3,1.1-0.2,1.9c0.1,0.8,0.3,1.5,0.6,2.1
							c0.3,0.6,0.6,1.1,0.9,1.5c0.7,0.8,1.5,1.6,2.7,2c-0.4-0.5-0.5-1-0.4-1.4c0-0.4,0.2-0.8,0.4-1.1c0.2-0.3,0.5-0.6,0.7-0.9
							C213.1,504.8,213.5,504.6,213.9,504.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M211.8,505.3c1.1-0.1,1.7-0.6,2.1-1.5c0.3-0.8,0.5-2,0-3.2c-0.4-1.1-1.4-1.9-2.6-2c-1.2-0.1-2.5,0.5-3.2,1.9
							c-0.7,1.4-0.9,2.4-1.1,3.4c-0.2,1-0.4,1.9-0.7,3.2c0.9-0.8,1.8-1.2,2.7-1.4C209.7,505.4,210.6,505.4,211.8,505.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M208.4,505.3c1.4,0.5,2.8,0.1,3.7-0.7c0.9-0.8,1.2-2,0.9-3.2c-0.3-1.2-1.2-2.1-2.1-2.5c-0.9-0.4-2-0.4-3,0.3
							c-1.1,0.7-1.9,1.3-2.7,1.8c-0.8,0.5-1.7,0.9-3.1,1.1c1.2,0.5,2.1,1,3,1.6C206,504.2,206.9,504.8,208.4,505.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M206.6,501.5c0.6,2.6,3.6,3.1,5.4,1.5c1.8-1.6,1.7-4.6-0.8-5.5c-2.6-1-3.9-1-6.1-2.3
							C206.1,497.5,205.9,498.8,206.6,501.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M207.3,497.9c-1.1,2.2,0.8,4.3,3.2,4.2c2.4-0.1,4.2-2.4,2.8-4.5c-1.4-2.2-2.4-3-3.3-5.2
							C209.5,494.8,208.5,495.6,207.3,497.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M210,496.6c-0.9,0.7-1.1,1.7-0.8,2.5c0.4,0.9,1.3,1.6,2.4,2c1.2,0.3,2.3,0.2,3.1-0.2c0.8-0.4,1.2-1.1,0.9-2.1
							c-0.5-2-1.2-3.1-0.9-5.1C213.2,495,211.9,495.2,210,496.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M246.4,509.3c3.3,2.5,6.7,4,9.9,4.5c3.2,0.5,6.1,0.1,7.6-1.9c1.5-1.9,0.9-5.1-1.5-8c-2.4-2.9-6.8-5.5-11.7-6
							c-5.1-0.5-8.5-0.1-11.9,0.2c-3.4,0.4-6.7,0.8-11.3,1.5c4.6,0.9,7.6,2.2,10.3,3.7C240.4,504.9,243,506.7,246.4,509.3z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M155.7,208.9c3.8,4.2,8.2,7.3,12.1,9.2c3.9,1.8,7.4,2.3,9.1,0.6c1.7-1.7,1.1-5.3-1.4-9.3c-2.6-3.9-7.1-8.1-12.8-10.5
							c-5.8-2.5-10-3.6-14.1-4.8c-4.1-1.1-8.3-2.3-14.2-4.1c5.3,3.1,8.6,5.8,11.6,8.7C148.9,201.6,151.7,204.6,155.7,208.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M144.9,218.2c1.7,4.7,4.2,8.8,6.9,11.6c2.7,2.8,5.6,4.4,7.9,3.4c2.2-0.9,3.3-4.2,2.6-8.5c-0.7-4.2-3.2-9.4-7.3-13.3
							c-4.2-4-7.5-6.2-10.7-8.5c-3.3-2.2-6.5-4.4-11.1-7.6c3.6,4.3,5.5,7.6,7.1,11C141.9,209.8,143.2,213.3,144.9,218.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M130,219.4c-0.3,3.7,0.5,7,2,9.6c1.5,2.5,3.7,4.3,6.1,4.2c2.4-0.1,4.5-2.2,5.4-5.4c0.9-3.2,0.5-7.6-1.7-11.3
							c-2.3-3.8-4.3-6.2-6.3-8.5c-2-2.3-3.9-4.5-6.5-7.8c1.3,4,1.6,6.8,1.6,9.7C130.6,212.7,130.3,215.5,130,219.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M111.8,214c-2.1,2.7-2.8,5.7-2.5,8.4c0.3,2.6,1.7,4.9,4,5.8c2.3,0.9,4.9,0.1,6.9-1.9c2-2,3.4-5.2,3.2-8.8
							c-0.2-3.7-0.7-6.3-1.1-8.9c-0.4-2.5-0.7-5.1-0.8-8.6c-1.4,3.3-2.8,5.4-4.3,7.4C115.7,209.3,114,211.2,111.8,214z"
                      />
                    </g>
                    <g>
                      <path
                        d="M99.2,205.2c-3.4,1.1-6,3-7.7,5.3c-1.7,2.3-2.5,4.9-1.5,7.2c0.9,2.2,3.6,3.5,7,3.1c3.3-0.4,7.2-2.4,9.7-5.9
							c2.6-3.6,3.8-6.4,5-9.1c1.2-2.7,2.3-5.4,4-9.1c-2.9,2.8-5.4,4.3-7.8,5.4C105.3,203.2,102.7,204.1,99.2,205.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M94.3,188.9c-3.5-1.1-6.6-1.1-9.4-0.5c-0.7,0.2-1.4,0.3-2,0.6c-0.6,0.3-1.2,0.6-1.7,0.9c-1.1,0.7-1.9,1.6-2.3,2.8
							c-0.4,1.1-0.3,2.4,0.3,3.6c0.3,0.6,0.7,1.2,1.3,1.8c0.6,0.6,1.2,1.1,2,1.6c3.1,1.9,8,2.8,12.5,1.3c4.6-1.6,7.4-3.3,10.1-5.1
							c2.6-1.8,5.1-3.7,8.4-6.4c-4.1,1.4-7.1,1.6-9.9,1.4C100.6,190.6,97.9,190,94.3,188.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M100.2,170.6c-2.6-3.1-5.7-5.2-8.7-6.3c-3-1.1-5.9-1.1-7.7,0.5c-1.8,1.6-1.9,4.8-0.1,8.1c1.8,3.3,5.4,6.6,10.1,8
							c4.8,1.4,8.2,1.8,11.5,2.1c3.3,0.3,6.5,0.5,11.2,0.9c-4.3-1.8-6.8-3.7-9.1-5.8C105,176.1,103,173.8,100.2,170.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M110.5,158c-1.1-4.1-3-7.4-5.1-9.9c-0.6-0.6-1.1-1.2-1.7-1.7c-0.6-0.5-1.2-0.9-1.8-1.2c-1.2-0.6-2.4-0.9-3.6-0.7
							c-1.2,0.3-2.2,1.1-2.8,2.4c-0.3,0.6-0.6,1.4-0.8,2.2c-0.2,0.8-0.3,1.8-0.2,2.8c0.1,4,2,9,5.8,12.6c3.9,3.7,7,5.5,10.1,7.2
							c3.1,1.7,6.2,3.3,10.6,5.4c-3.6-3.3-5.4-6.2-6.8-9.1C112.8,165.2,111.7,162.2,110.5,158z"
                      />
                    </g>
                    <g>
                      <path
                        d="M123.7,154.9c0.9-3.8,1.3-6.8,1.4-9.5c0.1-1.3,0-2.7-0.1-4c-0.1-1.3-0.4-2.5-1.2-3.5c-0.8-0.9-2-1.4-3.6-1.2
							c-1.6,0.2-3.6,1.2-5.3,3c-1.7,1.8-3.1,4.4-3.7,7.3c-0.6,2.9-0.6,6,0.3,8.9c0.9,3,2.1,5.2,3.3,7c1.2,1.8,2.4,3.3,3.6,4.8
							c2.4,2.9,4.9,5.5,8.1,9.5c-2-4.8-2.9-8.3-3.3-11.7c-0.2-1.7-0.3-3.4-0.3-5.2C123,158.7,123.2,156.8,123.7,154.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M140.8,158.2c3.1-2.2,5.3-4.3,6.9-6.6c0.8-1.1,1.5-2.3,2.1-3.5c0.5-1.2,0.8-2.4,0.6-3.6c-0.2-1.2-1.1-2.2-2.5-2.8
							c-1.4-0.6-3.3-0.8-5.5-0.4c-2.2,0.4-4.5,1.5-6.6,3.3c-2,1.7-3.8,4-4.8,6.5c-1.1,2.7-1.6,4.9-1.9,6.9c-0.3,2-0.4,3.8-0.5,5.6
							c-0.1,3.5,0,6.9-0.1,11.8c1.5-4.6,3.1-7.6,5.1-10.1c1-1.3,2-2.5,3.2-3.6C137.9,160.6,139.2,159.4,140.8,158.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M151.4,167.6c4-0.8,7.3-2.3,9.9-4.3c0.6-0.5,1.2-1,1.7-1.5c0.5-0.5,1-1.1,1.3-1.6c0.7-1.1,1.1-2.3,1-3.6
							c-0.2-1.2-0.9-2.3-2.1-3.1c-0.6-0.4-1.3-0.8-2.1-1c-0.8-0.2-1.7-0.4-2.7-0.5c-3.9-0.3-9.1,1.3-12.8,4.9
							c-3.8,3.7-5.8,6.7-7.7,9.6c-1.8,3-3.5,6-5.6,10.2c3.4-3.4,6.2-5.1,9.1-6.3C144.2,169.3,147.3,168.5,151.4,167.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M159.8,180.2c4,0.9,7.4,1,10.3,0.8c1.5-0.1,2.9-0.4,4.1-0.8c1.2-0.4,2.4-1,3.1-2c0.7-1,0.8-2.3,0.3-3.8
							c-0.5-1.5-1.8-3-3.7-4.4c-1.9-1.3-4.3-2.3-7-2.7c-2.7-0.4-5.6-0.2-8.3,0.7c-2.8,0.9-4.9,2-6.7,3c-1.8,1-3.3,2.1-4.8,3.1
							c-3,2.1-5.8,4.2-10.1,6.8c4.9-1.3,8.4-1.8,11.8-1.8c1.7,0,3.4,0,5.2,0.2C155.8,179.5,157.7,179.8,159.8,180.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M126.3,182.5c-2.4,0.4-2.8,3.2-1.3,5c1.6,1.9,4.4,1.9,5.2-0.4c0.8-2.4,0.8-3.7,2-5.7
							C130,182.3,128.8,182.1,126.3,182.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M129.6,184.1c-1.1-0.4-2-0.1-2.7,0.6c-0.7,0.7-1.1,1.8-1,3c0.1,1.2,0.8,2.2,1.7,2.7c0.9,0.5,2.2,0.5,3.2-0.4
							c1-0.9,1.7-1.6,2.3-2.3c0.6-0.7,1.3-1.4,2.2-2.1c-1.2,0-2.1-0.2-2.9-0.4C131.6,184.9,130.8,184.5,129.6,184.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M130.1,186.7c-0.2-0.9-0.7-1.3-1.5-1.4c-0.8-0.1-1.9,0.1-3,0.8c-1,0.6-1.7,1.7-1.6,2.8c0.1,1.1,0.9,2.2,2.2,2.5
							c1.4,0.3,2.4,0.2,3.3,0.1c0.9-0.1,1.8-0.2,2.9-0.2c-0.9-0.7-1.3-1.4-1.6-2.1C130.5,188.5,130.3,187.7,130.1,186.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M128.5,190c0.4-0.3,0.6-0.7,0.8-1.1c0.1-0.5,0.1-1,0-1.6c-0.1-0.5-0.4-1.1-0.9-1.4c-0.4-0.4-1-0.6-1.6-0.7
							c-0.6-0.1-1.2-0.1-1.7,0c-0.5,0.1-1,0.2-1.4,0.4c-0.4,0.2-0.7,0.6-0.9,1c-0.2,0.5-0.3,1.1-0.2,1.9c0.1,0.8,0.3,1.5,0.6,2.1
							c0.3,0.6,0.6,1.1,0.9,1.5c0.7,0.8,1.5,1.6,2.7,2c-0.4-0.5-0.5-1-0.4-1.4c0-0.4,0.2-0.8,0.4-1.1c0.2-0.3,0.5-0.6,0.7-0.9
							C127.7,190.4,128.1,190.3,128.5,190z"
                      />
                    </g>
                    <g>
                      <path
                        d="M126.4,190.9c1.1-0.1,1.7-0.6,2.1-1.5c0.3-0.8,0.5-2,0-3.2c-0.4-1.1-1.4-1.9-2.6-2c-1.2-0.1-2.5,0.5-3.2,1.9
							c-0.7,1.4-0.9,2.4-1.1,3.4c-0.2,1-0.4,1.9-0.7,3.2c0.9-0.8,1.8-1.2,2.7-1.4C124.3,191.1,125.2,191,126.4,190.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M123,191c1.4,0.5,2.8,0.1,3.7-0.7c0.9-0.8,1.2-2,0.9-3.2c-0.3-1.2-1.2-2.1-2.1-2.5c-0.9-0.4-2-0.4-3,0.3
							c-1.1,0.7-1.9,1.3-2.7,1.8c-0.8,0.5-1.7,0.9-3.1,1.1c1.2,0.5,2.1,1,3,1.6C120.6,189.9,121.5,190.4,123,191z"
                      />
                    </g>
                    <g>
                      <path
                        d="M121.2,187.2c0.6,2.6,3.6,3.1,5.4,1.5c1.8-1.6,1.7-4.6-0.8-5.5c-2.6-1-3.9-1-6.1-2.3
							C120.7,183.2,120.5,184.5,121.2,187.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M121.9,183.6c-1.1,2.2,0.8,4.3,3.2,4.2c2.4-0.1,4.2-2.4,2.8-4.5c-1.4-2.2-2.4-3-3.3-5.2
							C124.1,180.4,123.1,181.3,121.9,183.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M124.6,182.2c-0.9,0.7-1.1,1.7-0.8,2.5c0.4,0.9,1.3,1.6,2.4,2c1.2,0.3,2.3,0.2,3.1-0.2c0.8-0.4,1.2-1.1,0.9-2.1
							c-0.5-2-1.2-3.1-0.9-5.1C127.8,180.7,126.5,180.9,124.6,182.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M161,194.9c3.3,2.5,6.7,4,9.9,4.5c3.2,0.5,6.1,0.1,7.6-1.9c1.5-1.9,0.9-5.1-1.5-8c-2.4-2.9-6.8-5.5-11.7-6
							c-5.1-0.5-8.5-0.1-11.9,0.2c-3.4,0.4-6.7,0.8-11.3,1.5c4.6,0.9,7.6,2.2,10.3,3.7C155,190.5,157.6,192.3,161,194.9z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M353.7,131.6c-6.1,1.4-12.7,4.3-17.8,8c-2.6,1.8-4.9,3.9-7,6c-2,2.1-3.7,4.3-5.1,6.4c-2.8,4.3-4.2,8.4-4.6,11.5
							c-0.2,1.6-0.1,2.9,0.2,3.9c0.3,1,0.9,1.8,1.6,2.2c0.8,0.4,1.7,0.6,2.7,0.4c1-0.2,2.2-0.6,3.5-1.2c2.5-1.2,5.5-3.2,8.8-5.5
							c1.6-1.1,3.4-2.3,5.2-3.6c1.8-1.2,3.8-2.5,5.8-3.8c4-2.7,8.2-5.1,13.1-8c5-3,9.8-5.5,14-8.2c4.2-2.6,8-5.4,11.3-8.5
							c1.7-1.5,3.3-3.1,4.8-4.8c1.6-1.7,3.1-3.5,4.6-5.5c3-3.9,6-8.5,8.6-14.3c-3.6,5.3-7.4,9-11.1,11.8c-1.9,1.4-3.7,2.6-5.5,3.7
							c-1.8,1.1-3.7,2-5.5,2.7c-3.7,1.6-7.5,2.6-11.9,3.5C365,129.4,360,130.1,353.7,131.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M362.6,150.6c-5,2.2-10,4.1-14.7,5.9c-4.7,1.7-9.2,3.3-13.1,4.7c-4,1.4-7.4,2.8-9.9,4.2c-2.5,1.4-4.1,2.9-4.3,4.7
							c-0.1,1.7,1.3,3.5,4.2,5c2.8,1.5,7.1,2.5,12.3,2.7c5.2,0.2,11.2-0.6,17.4-2.6c6.1-2.1,12.3-5.3,17.6-9.8
							c5.4-4.6,9.3-9,12.4-13.3c3.1-4.3,5.4-8.3,7.5-12.4c2-4.1,3.8-8.4,5.4-13.2c1.6-4.8,3-10.2,4-16.7c-2,6.2-4.5,11.1-7.2,15.2
							c-2.6,4.1-5.4,7.4-8.4,10.3c-3,3-6.2,5.6-10,8.1C372,145.9,367.8,148.3,362.6,150.6z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M401.3,173.5c-6-1.9-13.1-2.8-19.4-2.2c-3.2,0.3-6.2,0.8-9,1.6c-2.8,0.8-5.4,1.8-7.7,2.9c-4.6,2.3-7.9,5-9.8,7.5
							c-1,1.2-1.6,2.4-1.8,3.5c-0.3,1.1-0.2,2,0.3,2.7c0.4,0.8,1.2,1.3,2.2,1.7c1,0.4,2.2,0.6,3.6,0.8c2.8,0.2,6.3,0.1,10.3-0.2
							c2-0.1,4.1-0.3,6.4-0.4c2.2-0.1,4.5-0.3,6.9-0.4c4.8-0.2,9.7-0.2,15.4-0.2c5.9,0,11.2,0.3,16.2,0.1c5-0.1,9.7-0.6,14.1-1.5
							c2.2-0.4,4.4-1,6.6-1.7c2.2-0.7,4.4-1.4,6.7-2.4c4.6-1.9,9.5-4.3,14.7-7.9c-5.8,2.7-10.9,3.9-15.6,4.5
							c-2.3,0.3-4.5,0.4-6.7,0.3c-2.1,0-4.2-0.2-6.2-0.5c-4-0.5-7.8-1.6-12-3C412.2,177.4,407.6,175.5,401.3,173.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M399.3,194.3c-5.4-0.7-10.7-1.6-15.6-2.5c-4.9-0.9-9.6-1.9-13.7-2.7c-4.1-0.8-7.8-1.4-10.7-1.5
							c-2.9-0.1-5.1,0.4-6.1,1.9c-1,1.4-0.6,3.7,1.1,6.4c1.7,2.7,4.8,5.8,9.2,8.6c4.3,2.8,9.9,5.3,16.3,6.7
							c6.3,1.4,13.3,1.8,20.1,0.6c7-1.1,12.6-3,17.5-5c4.8-2.1,8.9-4.4,12.8-6.9c3.9-2.5,7.6-5.3,11.4-8.6c3.8-3.3,7.8-7.3,12-12.3
							c-4.9,4.3-9.6,7.2-13.9,9.4c-4.4,2.2-8.4,3.5-12.5,4.6c-4.1,1-8.2,1.6-12.7,1.9C409.8,195.2,404.9,195.1,399.3,194.3z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M143.2,295.9c4-4.8,7.5-11.1,9.3-17.2c0.9-3.1,1.6-6.1,1.9-9c0.3-2.9,0.3-5.7,0.2-8.2c-0.4-5.1-1.7-9.2-3.3-11.9
							c-0.8-1.4-1.7-2.4-2.6-3c-0.9-0.6-1.8-0.9-2.6-0.8c-0.9,0.1-1.7,0.6-2.4,1.3c-0.7,0.8-1.4,1.8-2,3.1c-1.3,2.5-2.4,5.9-3.7,9.7
							c-0.6,1.9-1.3,3.9-2,6.1c-0.7,2.1-1.4,4.3-2.2,6.6c-1.6,4.6-3.4,9-5.5,14.3c-2.2,5.4-4.4,10.3-6.2,15
							c-1.7,4.7-3.1,9.2-3.8,13.6c-0.4,2.2-0.7,4.5-0.9,6.8c-0.2,2.3-0.3,4.7-0.3,7.1c0,4.9,0.4,10.4,1.8,16.6
							c-0.4-6.3,0.4-11.6,1.6-16.1c0.6-2.3,1.3-4.4,2.2-6.3c0.8-2,1.7-3.8,2.7-5.5c2-3.5,4.4-6.6,7.3-10
							C135.6,304.6,139.1,300.9,143.2,295.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M124.7,286.2c2.7-4.8,5.5-9.3,8.1-13.6c2.7-4.3,5.3-8.2,7.6-11.7c2.3-3.5,4.2-6.7,5.4-9.3c1.2-2.6,1.5-4.9,0.5-6.3
							c-1-1.5-3.2-2-6.4-1.4c-3.2,0.6-7.2,2.3-11.4,5.3c-4.2,3-8.6,7.3-12.2,12.6c-3.6,5.4-6.6,11.7-8.1,18.4
							c-1.5,6.9-1.9,12.8-1.8,18.1c0.1,5.3,0.7,9.9,1.6,14.4c0.9,4.5,2.1,9,3.7,13.8c1.7,4.8,3.8,9.9,7,15.7
							c-2.2-6.2-3.2-11.6-3.5-16.4c-0.4-4.8-0.2-9.2,0.4-13.3c0.6-4.2,1.6-8.2,3-12.5C120,295.7,121.9,291.1,124.7,286.2z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M453.4,473.1c-7.8,2.4-14.6,6.2-19.4,10.2c-4.8,4-7.6,8.2-6.4,11.6c1.2,3.3,6.3,4.8,13,3.8c6.7-1,14.9-4.4,21.5-10.4
							c6.8-6.1,10.9-10.9,14.9-15.6c4-4.7,8-9.4,14-16.1c-7.3,5.1-12.9,7.7-18.4,9.9C467.1,468.8,461.4,470.6,453.4,473.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M447.9,453.4c-7.2-0.7-14.1,0.2-19.4,2c-5.3,1.8-9.1,4.7-9.3,8.2c-0.2,3.5,3.5,6.8,9.5,8.5c6,1.7,14.2,1.6,21.8-1.4
							c7.8-3.1,12.7-6,17.6-8.9c4.9-2.9,9.7-5.8,16.7-9.9c-7.8,2.1-13.3,2.5-18.7,2.5C460.7,454.5,455.3,454.1,447.9,453.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M455.3,433.2c-4.7-2.7-9.5-3.7-13.7-3.3c-4.2,0.4-7.9,2.2-9.3,5.4c-1.4,3.2,0.1,7.2,3.8,10.4
							c3.7,3.1,9.6,5.3,15.9,4.7c6.4-0.7,10.7-1.9,14.9-3.1c4.2-1.2,8.3-2.4,14.1-3.8c-6-0.7-9.9-2.1-13.6-3.8
							C463.7,438,460.2,435.9,455.3,433.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M473.5,412.7c-2.2-4.4-5.7-7.2-9.4-8.4c-3.7-1.2-7.5-0.8-10.1,1.7c-2.6,2.4-3.1,6.3-1.8,10.2c1.4,3.9,4.7,7.6,9.6,9.6
							c5,2,8.7,2.9,12.3,3.9c3.6,1,7.1,2.1,11.8,4.3c-3.4-3.8-5.3-7-7-10.2C477.3,420.6,475.8,417.2,473.5,412.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M492.7,401.5c0.7-5.1-0.3-9.7-2.2-13.3c-1.9-3.6-4.9-6.3-8.4-6.4c-3.5-0.1-6.7,2.6-8.3,7.2
							c-1.5,4.5-1.3,10.8,1.8,16.3c3.2,5.6,6.1,8.9,8.9,12.1c2.8,3.2,5.6,6.3,9.4,10.8c-1.9-5.5-2.3-9.6-2.3-13.6
							C491.6,410.7,492,406.8,492.7,401.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M517.1,405c3.5-3.9,5.5-8,6.4-12c0.2-1,0.4-2,0.4-3c0-1,0-1.9-0.1-2.8c-0.3-1.8-1-3.5-2.2-4.7
							c-1.3-1.2-2.9-1.9-4.9-1.8c-1,0-2.1,0.2-3.1,0.6c-1.1,0.4-2.2,0.9-3.3,1.6c-4.4,2.8-8.5,8.7-9.3,15.5
							c-0.8,7-0.1,11.7,0.6,16.3c0.8,4.5,1.7,8.9,3.2,15c0.6-6.2,2.3-10.2,4.3-13.8C511,412.3,513.5,409,517.1,405z"
                      />
                    </g>
                    <g>
                      <path
                        d="M537.4,423.9c5.7-1.6,10.3-4.3,13.5-7.6c3.2-3.2,5.1-7.1,4.1-10.4c-1-3.3-5.1-5.3-10.5-5c-5.4,0.3-11.9,3.1-16.6,8.4
							c-4.8,5.4-7.3,9.6-9.8,13.8c-2.3,4.1-4.7,8.2-8.1,14.1c5-4.5,9-6.7,13.1-8.4C527.3,426.9,531.5,425.6,537.4,423.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M547.6,445.1c6,1,11.5,0.6,16-0.7c1.1-0.4,2.2-0.7,3.2-1.2c1-0.5,1.9-1,2.7-1.6c1.6-1.2,2.7-2.6,3.1-4.4
							c0.4-1.7-0.1-3.5-1.4-5.2c-0.6-0.8-1.4-1.7-2.5-2.4c-1-0.7-2.2-1.5-3.5-2c-5.2-2.3-13-2.8-20,0c-7.2,2.9-11.5,5.9-15.7,8.8
							c-4.1,3-8.1,6.1-13.5,10.5c6.6-2.7,11.4-3.3,16-3.4C536.8,443.6,541.4,444.1,547.6,445.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M543.7,464.2c4.4,3.5,8.1,5.9,11.5,7.7c1.7,0.9,3.5,1.7,5.2,2.3c1.7,0.6,3.5,1,5.3,0.6c1.7-0.4,3.1-1.7,3.8-4
							c0.7-2.2,0.6-5.4-0.8-8.8c-1.3-3.3-3.9-6.8-7.2-9.3c-3.4-2.6-7.5-4.4-11.9-5c-4.5-0.6-8.1-0.4-11.2,0
							c-3.1,0.4-5.8,1.1-8.4,1.8c-5.2,1.4-10.1,3.1-17.4,4.9c7.5,0.3,12.6,1.2,17.3,2.7c2.3,0.8,4.6,1.7,6.9,2.8
							C539.1,461,541.5,462.4,543.7,464.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M528.8,484.6c1,5.4,2.5,9.5,4.4,13.1c1,1.8,2.1,3.4,3.3,4.8c1.2,1.4,2.7,2.5,4.4,2.9c1.7,0.4,3.5,0,5.2-1.5
							c1.7-1.4,3.1-3.9,3.9-7c0.7-3.1,0.7-6.9-0.2-10.6c-1-3.7-2.9-7.4-5.6-10.3c-2.8-3.1-5.4-5.1-7.9-6.8c-2.5-1.7-4.7-2.9-7-4.1
							c-4.6-2.3-9.1-4.3-15.4-7.3c5.1,4.8,8,8.7,10.2,12.8c1.1,2,2,4.1,2.8,6.4C527.6,479.3,528.3,481.8,528.8,484.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M510.1,492.7c-1.4,5.7-1.4,11-0.4,15.5c0.3,1.1,0.5,2.2,0.9,3.2c0.4,1,0.8,1.9,1.3,2.7c1,1.6,2.4,2.9,4.1,3.4
							c1.7,0.5,3.5,0.2,5.3-0.9c0.9-0.5,1.8-1.3,2.6-2.2c0.8-0.9,1.6-2,2.3-3.3c2.7-5,3.8-12.7,1.5-19.8c-2.4-7.3-5.2-11.7-7.9-15.9
							c-2.8-4.2-5.7-8.2-10-13.6c2.4,6.5,2.8,11.3,2.7,15.8C512.2,482.3,511.5,486.8,510.1,492.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M488.5,496c-3.6,4.8-5.9,9-7.3,13c-0.7,2-1.3,4-1.5,5.9c-0.2,1.9-0.1,3.7,0.8,5.3c0.9,1.5,2.5,2.5,4.7,2.7
							c2.2,0.2,5.1-0.5,7.9-2.1c2.8-1.6,5.7-4.3,7.8-7.6c2.1-3.3,3.6-7.2,4.1-11.2c0.5-4.2,0.4-7.6,0.2-10.6c-0.3-3-0.7-5.6-1.1-8.2
							c-0.9-5.1-1.9-10.1-2.8-17.4c-1.3,7.2-2.8,12.1-4.8,16.5c-1,2.2-2.1,4.4-3.4,6.7C491.8,491.2,490.3,493.6,488.5,496z"
                      />
                    </g>
                    <g>
                      <path
                        d="M505.9,450.8c0.9-3.4-2.4-5.6-5.8-4.7c-3.4,0.9-5.2,4.5-2.7,7c2.6,2.5,4.3,3.3,6.2,6.1
							C503.8,455.8,505,454.3,505.9,450.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M501.8,454.2c1.2-1.2,1.4-2.6,0.9-3.9c-0.5-1.3-1.7-2.5-3.4-3.1c-1.7-0.6-3.4-0.4-4.6,0.6c-1.2,0.9-1.9,2.6-1.4,4.4
							c0.5,1.9,1.1,3.2,1.6,4.5c0.5,1.3,1,2.5,1.4,4.2c0.7-1.6,1.5-2.6,2.4-3.6C499.5,456.3,500.5,455.4,501.8,454.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M498,453.2c1.3,0.3,2.1-0.1,2.7-1.2c0.6-1,1-2.6,0.8-4.4c-0.2-1.7-1.2-3.2-2.7-3.8c-1.5-0.6-3.4-0.2-4.6,1.4
							c-1.2,1.6-1.7,3-2.2,4.2c-0.4,1.3-0.8,2.4-1.5,3.9c1.4-0.8,2.6-0.9,3.7-0.9C495.5,452.6,496.6,452.9,498,453.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M494.8,449.1c0.1,0.7,0.5,1.2,1,1.7c0.5,0.4,1.3,0.8,2.1,0.9c0.8,0.1,1.7,0.1,2.4-0.3c0.8-0.3,1.4-0.9,1.9-1.6
							c0.5-0.7,0.8-1.5,1.1-2.2c0.2-0.7,0.3-1.4,0.3-2.1c-0.1-0.7-0.3-1.3-0.8-1.8c-0.5-0.6-1.3-1.1-2.4-1.4
							c-1.1-0.3-2.2-0.5-3.1-0.5c-0.9,0-1.7,0.1-2.5,0.3c-1.5,0.4-3,0.9-4.2,2.3c0.8-0.2,1.5,0,2.1,0.3c0.5,0.3,0.9,0.7,1.2,1.2
							c0.3,0.5,0.5,1,0.7,1.5C494.6,447.8,494.6,448.4,494.8,449.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M494.8,445.7c-0.6,1.5-0.3,2.6,0.6,3.6c0.9,1,2.4,1.8,4.1,2c1.7,0.2,3.4-0.6,4.2-2.1c0.9-1.5,0.9-3.7-0.5-5.4
							c-1.4-1.8-2.6-2.7-3.8-3.6c-1.2-0.9-2.3-1.7-3.7-2.8c0.5,1.8,0.5,3.1,0.2,4.4C495.9,443,495.4,444.2,494.8,445.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M496.9,441.3c-1.5,1.5-1.9,3.6-1.4,5.2c0.5,1.6,1.9,2.8,3.6,3.1c1.7,0.3,3.4-0.2,4.5-1.2c1.1-1,1.7-2.3,1.5-4.1
							c-0.3-1.8-0.6-3.2-0.7-4.6c-0.1-1.4-0.1-2.8,0.5-4.7c-1.5,1.3-2.6,2.1-3.9,3C499.7,438.9,498.4,439.7,496.9,441.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M502.9,441.2c-3.7-0.8-6.3,2.7-5.3,6.1c1,3.4,5,5,7.7,2.3c2.8-2.8,3.6-4.5,6.8-6.6C508.4,443.1,506.8,442,502.9,441.2
							z"
                      />
                    </g>
                    <g>
                      <path
                        d="M507.1,444.4c-2.2-2.9-6.2-1.6-7.5,1.7c-1.3,3.3,0.6,6.9,4.2,6.5c3.7-0.5,5.4-1.4,8.9-1.1
							C510,449.2,509.4,447.3,507.1,444.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M507.3,448.7c-0.3-1.6-1.5-2.5-2.9-2.6c-1.4-0.1-2.9,0.6-4.1,2c-1.2,1.3-1.7,2.9-1.7,4.1c0.1,1.3,0.7,2.2,2.2,2.4
							c3,0.6,4.8,0.4,7.3,1.9C507.4,453.8,507.9,452.1,507.3,448.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M468.5,488.6c-5.3,2.7-9.3,6.4-11.9,10.2c-2.6,3.8-3.8,7.9-2.1,11.1c1.6,3.1,6.1,4.3,11.4,2.9
							c5.3-1.4,11.3-5.5,15-11.7c3.7-6.3,5.4-11.1,7-15.7c1.5-4.7,3-9.2,5-15.7c-3.9,5.5-7.5,8.6-11.2,11.3
							C477.9,483.5,474,485.7,468.5,488.6z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M426.3,566.6c5.8-2.4,11.9-6.2,16.3-10.7c2.3-2.2,4.3-4.6,5.9-7c1.7-2.4,3-4.8,4-7.2c2.1-4.7,2.9-8.9,2.7-12.1
							c-0.1-1.6-0.4-2.9-0.8-3.9c-0.5-1-1.1-1.6-2-1.9c-0.8-0.3-1.8-0.3-2.8,0c-1,0.3-2.1,0.9-3.2,1.7c-2.3,1.6-4.9,4-7.8,6.8
							c-1.4,1.4-3,2.8-4.6,4.4c-1.6,1.5-3.3,3.1-5.1,4.7c-3.5,3.3-7.3,6.4-11.7,10c-4.5,3.7-8.8,7-12.5,10.3
							c-3.8,3.3-7,6.6-9.9,10.2c-1.4,1.8-2.7,3.6-4,5.5c-1.3,1.9-2.5,4-3.7,6.1c-2.3,4.4-4.5,9.3-6.2,15.5c2.7-5.8,5.9-10,9.1-13.4
							c1.6-1.7,3.2-3.2,4.9-4.5c1.6-1.3,3.3-2.5,5-3.6c3.4-2.1,7-3.8,11.2-5.4C415.5,570.6,420.3,569,426.3,566.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M414.5,549.3c4.6-2.9,9.2-5.6,13.6-8.1c4.4-2.5,8.6-4.7,12.2-6.7c3.7-2,6.9-3.9,9.1-5.7c2.3-1.8,3.6-3.6,3.5-5.3
							c-0.2-1.7-1.9-3.3-4.9-4.3c-3-1-7.4-1.4-12.6-0.7c-5.1,0.6-11,2.3-16.8,5.3c-5.7,3-11.4,7.2-15.8,12.4
							c-4.6,5.4-7.8,10.4-10.2,15.1c-2.4,4.7-4.1,9.1-5.4,13.5c-1.4,4.4-2.4,8.9-3.2,13.9c-0.8,5-1.3,10.6-1.3,17.1
							c1-6.5,2.7-11.7,4.7-16.1c2-4.4,4.2-8.1,6.7-11.5c2.5-3.4,5.3-6.5,8.5-9.6C405.9,555.4,409.8,552.3,414.5,549.3z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M372.6,532.7c6.2,0.9,13.4,0.7,19.5-0.9c3.1-0.8,6-1.8,8.7-3c2.7-1.2,5-2.6,7.1-4.1c4.2-3,7-6.2,8.5-9
							c0.7-1.4,1.2-2.7,1.2-3.7c0.1-1.1-0.1-2-0.7-2.7c-0.5-0.7-1.4-1.1-2.4-1.4c-1-0.2-2.3-0.3-3.7-0.2c-2.8,0.2-6.3,0.9-10.2,1.8
							c-1.9,0.4-4,0.9-6.2,1.4c-2.2,0.5-4.4,1-6.8,1.5c-4.7,1-9.5,1.8-15.1,2.6c-5.8,0.9-11.1,1.5-16,2.4c-4.9,0.9-9.4,2.1-13.7,3.7
							c-2.1,0.8-4.2,1.7-6.3,2.7c-2.1,1-4.2,2.1-6.3,3.4c-4.2,2.6-8.7,5.7-13.3,10.1c5.3-3.6,10.2-5.6,14.7-6.9
							c2.3-0.6,4.4-1.1,6.5-1.4c2.1-0.3,4.1-0.5,6.2-0.5c4-0.1,7.9,0.3,12.4,1.1C361.2,530.7,366.2,531.8,372.6,532.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M371.3,511.9c5.5-0.2,10.8-0.1,15.8,0c5,0.1,9.8,0.3,14,0.5c4.2,0.1,7.9,0.1,10.8-0.2c2.9-0.4,4.9-1.2,5.7-2.8
							c0.7-1.6,0.1-3.8-2.1-6.2c-2.1-2.4-5.7-5-10.4-7.1c-4.7-2.1-10.7-3.6-17.1-4c-6.5-0.3-13.4,0.4-20,2.6
							c-6.7,2.2-12,4.9-16.5,7.7c-4.4,2.8-8.1,5.7-11.6,8.8c-3.4,3.1-6.6,6.4-9.9,10.3c-3.2,3.9-6.6,8.4-9.8,14
							c4.2-5,8.3-8.7,12.2-11.5c4-2.8,7.8-4.8,11.6-6.5c3.9-1.6,7.8-2.9,12.2-3.9C360.8,512.7,365.7,512,371.3,511.9z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M514.4,139c10.6-1.1,21.3-3.7,30.7-7.7c9.4-3.9,17.6-9.3,23.7-14.8c6.1-5.5,10.2-11.1,12.5-15.3
							c2.3-4.2,2.9-6.9,2.1-7.4c-1.6-1-7.7,7.5-19.9,16.2c-6.1,4.3-13.6,8.7-22.2,12.2c-8.6,3.5-18.2,6.3-28.2,8.1
							c-10.2,1.9-19.1,2.6-27.2,3.2c-8.1,0.6-15.5,1.1-23,1.9c-7.4,0.8-14.9,1.9-23.1,4.1c-4.1,1.1-8.3,2.5-12.7,4.4
							c-4.4,1.9-8.9,4.3-13.4,7.6c9.3-6,18.4-8.6,26.5-10.2c8.2-1.5,15.6-1.9,22.9-2c7.4-0.1,14.7,0.2,22.9,0.5
							C494.2,139.9,503.4,140.1,514.4,139z"
                      />
                    </g>
                    <g>
                      <path
                        d="M458.8,128.6c2.7-1.5,4.5-4,5.1-6.3c0.6-2.3,0-4.4-1.5-5.3c-1.5-0.9-3.5-0.6-5.3,0.4c-1.8,1-3.5,2.7-4.6,5.2
							c-1.1,2.5-2,4.3-3,6c-1,1.7-2.2,3.4-4.4,5.4c2.8-1.1,4.7-1.7,6.8-2.4C454,130.8,456,130.1,458.8,128.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M475.9,123.1c2.3-1.3,3.6-3.6,3.8-5.6c0.2-2.1-0.6-3.8-2.1-4.7c-1.6-0.8-3.4-0.6-5,0.2c-1.6,0.8-2.9,2.3-3.5,4.4
							c-0.7,2.2-1.1,3.8-1.7,5.4c-0.6,1.6-1.4,3.1-3,5c2.3-1,3.9-1.6,5.6-2.2C471.8,125,473.6,124.4,475.9,123.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M493,119.2c2.2-1.6,3.5-4,3.7-6.1c0.2-2.1-0.5-4-2.1-4.8c-1.5-0.8-3.5-0.5-5.1,0.5c-1.7,1.1-3.1,2.9-3.7,5.3
							c-0.6,2.5-1,4.3-1.5,6.1c-0.5,1.8-1.2,3.6-2.5,5.8c2.2-1.6,3.8-2.5,5.5-3.5C488.9,121.7,490.7,120.8,493,119.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M512.9,117.7c2.4-2.2,3.5-5.3,3.4-7.8c-0.1-2.5-1.3-4.3-3-4.6c-1.7-0.4-3.5,0.6-4.9,2c-1.4,1.4-2.5,3.4-2.9,5.9
							c-0.5,2.6-1,4.5-1.6,6.3c-0.7,1.8-1.6,3.7-3.7,5.8c2.7-1.3,4.5-2.3,6.5-3.3C508.4,121,510.4,119.9,512.9,117.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M529.5,112.5c2.1-2.5,3.1-5.6,2.9-8.1c-0.1-2.5-1.2-4.4-2.9-4.8c-1.7-0.5-3.6,0.5-5,2.2c-1.5,1.7-2.6,4.1-3,7
							c-0.4,3-0.6,5.1-1,7.2c-0.4,2.1-1,4.3-2.4,7.1c2.3-2.2,4-3.7,5.7-5.2C525.6,116.6,527.3,115.1,529.5,112.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M541.5,108.7c2-2,2.9-4.7,2.9-7c-0.1-2.2-1.2-4-2.8-4.5c-1.7-0.5-3.5,0.2-5,1.5c-1.5,1.4-2.6,3.4-2.9,6
							c-0.3,2.6-0.5,4.5-0.8,6.4c-0.3,1.9-0.8,3.8-2,6.3c2.1-1.9,3.6-3.1,5.2-4.3C537.8,112,539.4,110.8,541.5,108.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M558.6,100c2.1-2.3,3.1-5.3,3-7.7c0-2.4-1.1-4.3-2.7-4.9c-1.7-0.5-3.6,0.3-5.1,1.9c-1.5,1.6-2.7,4-3.1,6.8
							c-0.4,2.9-0.6,5-0.9,7.1c-0.4,2.1-0.9,4.2-2.2,7c2.2-2.2,3.8-3.7,5.5-5.1C554.7,103.8,556.5,102.4,558.6,100z"
                      />
                    </g>
                    <g>
                      <path
                        d="M573,93.6c2.2-1.8,3.6-4.1,4.1-6.2c0.5-2.2,0.2-4.2-1.2-5.3c-1.4-1.1-3.5-0.9-5.5,0.3c-2,1.2-3.9,3.5-4.7,6.5
							c-0.8,3-1.2,5.2-1.5,7.3c-0.4,2.1-0.8,4.2-1.6,7.1c1.8-2.4,3.3-3.9,4.9-5.2C569,96.6,570.7,95.4,573,93.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M587.3,86.9c2.3-1.1,3.9-2.9,4.8-4.7c0.8-1.8,0.9-3.8-0.1-5.2c-1-1.4-3.1-1.9-5.2-1.2c-2.2,0.6-4.5,2.4-5.7,5.1
							c-1.3,2.7-1.9,4.7-2.5,6.7c-0.6,2-1.2,3.9-2.3,6.5c1.9-2,3.6-3.2,5.3-4.1C583.1,88.9,584.9,88.1,587.3,86.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M602.4,93.3c2.3,0.6,4.4,0.3,6.1-0.6c1.7-0.9,3-2.3,3.1-4.1c0.1-1.7-1-3.4-3.1-4.3c-2-0.9-4.8-1-7.4,0.3
							c-2.6,1.3-4.2,2.6-5.7,3.7c-1.5,1.2-3.1,2.4-5.3,3.9c2.6-0.5,4.5-0.5,6.3-0.2C598.3,92.3,600,92.7,602.4,93.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M602.3,107.3c2.1,1.2,4.2,1.5,6.1,1.3c1.9-0.3,3.6-1.1,4.4-2.7c0.8-1.6,0.2-3.6-1.6-5.2c-1.8-1.6-4.7-2.6-7.7-2.1
							c-3.1,0.5-5.1,1.4-7,2.2c-2,0.9-3.8,1.7-6.3,2.8c2.8-0.1,4.7,0.4,6.5,1C598.4,105.2,600.1,106.1,602.3,107.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M598.1,120.2c2.4,1.8,5,2.6,7.4,2.6c2.3,0,4.2-0.9,4.9-2.5c0.7-1.6-0.1-3.6-1.8-5.3c-1.7-1.6-4.4-2.9-7.5-3.1
							c-6.3-0.3-8.9,0.5-15.1-0.2C591.6,114.5,593.3,116.5,598.1,120.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M455.6,157.1c1.3,2.2,2.9,3.7,4.7,4.7c1.8,0.9,3.8,1.2,5.3,0.3c1.5-0.9,2.1-3,1.3-5.4c-0.7-2.4-2.8-5-5.7-6.3
							c-3-1.3-5.3-1.7-7.4-2.1c-2.2-0.4-4.3-0.7-7.2-1.1c2.6,1.4,4.1,2.9,5.4,4.4C453.2,153.1,454.3,154.8,455.6,157.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M472.5,157.1c1.1,2.4,2.4,4.3,3.9,5.8c1.5,1.5,3.1,2.7,4.9,2.5c0.9-0.1,1.6-0.6,2.2-1.6c0.6-0.9,0.9-2.2,0.8-3.8
							c-0.1-1.5-0.5-3.3-1.5-4.9c-0.9-1.6-2.3-3.2-4-4.3c-1.8-1.1-3.4-1.8-4.8-2.2c-1.5-0.4-2.8-0.7-4.1-0.8
							c-2.6-0.3-5.1-0.3-8.6,0.2c3.4,0.6,5.5,1.9,7.1,3.3C470,152.9,471.3,154.7,472.5,157.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M487.8,156.6c1.5,2.4,3.4,4.2,5.4,5.3c1.9,1.1,4,1.5,5.5,0.6c1.5-0.9,2-3.1,1.1-5.7c-0.9-2.6-3.1-5.4-6.4-6.9
							c-3.3-1.5-5.7-2.1-8.1-2.5c-2.4-0.5-4.7-0.9-8-1.4c3,1.5,4.7,3.1,6.1,4.7C484.9,152.4,486.2,154.1,487.8,156.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M506.5,159.3c2.5,2.3,5.7,3.4,8.3,3.4c2.6,0,4.6-1.1,5.1-2.7c0.5-1.7-0.5-3.6-2.2-5.1c-1.7-1.6-4.2-2.8-7.2-3.3
							c-3-0.5-5.2-0.9-7.4-1.4c-2.2-0.5-4.3-1.3-7.2-2.9c2.2,2.5,3.6,4.3,5.1,6.1C502.5,155.1,504,157,506.5,159.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M525.3,155.5c3.6,1.7,7.5,2.2,10.5,1.7c3-0.5,5.1-1.9,5.2-3.6c0.2-1.8-1.5-3.5-4.1-4.7c-2.6-1.2-6-2-9.7-2
							c-3.8,0.1-6.5,0.2-9.2,0.1c-2.7-0.1-5.5-0.3-9.4-1.3c3.5,1.9,5.9,3.4,8.3,4.9C519.2,152.2,521.6,153.7,525.3,155.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M549,150.1c6.4,2.5,13.2,0.2,13.4-3.3c0.2-3.5-6-6.6-12.5-5.4c-6.7,1.1-9.4,2.3-16.4,2.3
							C540.3,145.7,542.5,147.5,549,150.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M568.8,144.1c3.4,1.2,7.1,1.2,9.7,0.4c2.7-0.8,4.5-2.3,4.5-4.1c0-1.8-1.7-3.3-4.2-4.3c-2.5-1-5.8-1.4-9.2-0.8
							c-3.5,0.6-6,1.1-8.5,1.5c-2.5,0.4-5.1,0.6-8.8,0.4c3.5,1.2,5.9,2.3,8.2,3.4C562.9,141.7,565.2,142.8,568.8,144.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M580.9,135.9c1.6,1.6,3.5,2.2,5.3,2.2c1.8,0,3.5-0.8,4.4-2.3c0.9-1.5,0.7-3.5-0.5-5.1c-1.2-1.6-3.4-2.9-6.1-3
							c-2.7,0-4.6,0.3-6.4,0.5c-1.8,0.3-3.6,0.5-6,0.6c2.3,0.9,3.7,1.9,5,3C578.1,133,579.3,134.2,580.9,135.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M591.4,129.9c3.6,4.4,9.6,5,11.5,2c1.9-3-1.3-8.1-6.8-9.4c-5.7-1.4-8.2-1.2-13.6-3.3
							C586.8,123.1,587.7,125.4,591.4,129.9z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M191.4,346.1c2,10.5,5.5,20.9,10.2,29.9c4.7,9.1,10.8,16.7,16.8,22.3c6,5.6,11.9,9.2,16.3,11.1
							c4.4,1.9,7.2,2.3,7.5,1.5c0.9-1.7-8.1-7.1-17.8-18.4c-4.8-5.7-9.8-12.8-14-21.1c-4.2-8.3-7.8-17.6-10.5-27.4
							c-2.8-10-4.2-18.8-5.5-26.9c-1.3-8.1-2.4-15.4-3.8-22.7c-1.4-7.3-3.2-14.7-6-22.6c-1.4-4-3.2-8.1-5.4-12.3
							c-2.2-4.2-5.1-8.5-8.7-12.7c6.8,8.8,10.2,17.6,12.4,25.5c2.2,8,3.2,15.3,4,22.6c0.7,7.3,1,14.6,1.5,22.8
							C188.8,326.1,189.3,335.3,191.4,346.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M197,289.9c1.7,2.6,4.4,4.1,6.8,4.5c2.4,0.4,4.4-0.3,5.2-1.9c0.8-1.6,0.3-3.5-0.8-5.3c-1.2-1.7-3-3.3-5.5-4.1
							c-2.6-0.9-4.5-1.6-6.3-2.5c-1.8-0.9-3.6-1.9-5.7-3.9c1.3,2.7,2.1,4.6,3,6.6C194.4,285.3,195.3,287.3,197,289.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M204,306.5c1.5,2.2,3.9,3.3,5.9,3.4c2.1,0.1,3.8-0.9,4.5-2.5c0.7-1.6,0.3-3.5-0.6-4.9c-1-1.5-2.5-2.6-4.7-3.1
							c-2.2-0.5-3.9-0.8-5.6-1.3c-1.6-0.5-3.2-1.2-5.2-2.6c1.2,2.2,1.9,3.8,2.7,5.4C201.7,302.5,202.5,304.3,204,306.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M209.3,323.1c1.8,2.1,4.2,3.1,6.4,3.2c2.1,0.1,3.9-0.8,4.6-2.5c0.7-1.6,0.2-3.5-1-5.1c-1.2-1.6-3.1-2.8-5.6-3.2
							c-2.5-0.4-4.4-0.6-6.2-1c-1.8-0.4-3.6-0.9-6-2c1.7,2,2.8,3.6,3.9,5.2C206.4,319.3,207.5,321,209.3,323.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M212.5,342.8c2.4,2.2,5.6,3,8,2.7c2.5-0.3,4.1-1.7,4.3-3.4c0.2-1.8-0.9-3.4-2.4-4.7c-1.5-1.3-3.6-2.2-6.1-2.4
							c-2.6-0.3-4.5-0.6-6.4-1.1c-1.9-0.5-3.8-1.3-6.1-3.2c1.5,2.5,2.6,4.3,3.8,6.2C208.8,338.7,210.1,340.6,212.5,342.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M219.1,359c2.6,1.9,5.8,2.6,8.3,2.2c2.5-0.3,4.3-1.6,4.6-3.3c0.3-1.7-0.8-3.5-2.7-4.8c-1.8-1.3-4.4-2.3-7.3-2.4
							c-3-0.1-5.1-0.1-7.3-0.4c-2.2-0.2-4.3-0.6-7.3-1.8c2.4,2.1,4,3.6,5.6,5.2C214.7,355.4,216.3,357,219.1,359z"
                      />
                    </g>
                    <g>
                      <path
                        d="M223.9,370.7c2.2,1.9,5,2.5,7.2,2.2c2.2-0.3,3.9-1.5,4.3-3.2c0.4-1.7-0.5-3.5-2-4.8c-1.5-1.4-3.7-2.3-6.2-2.3
							c-2.6-0.1-4.6-0.1-6.5-0.2c-1.9-0.2-3.8-0.5-6.4-1.5c2,1.9,3.4,3.4,4.7,4.9C220.3,367.2,221.6,368.7,223.9,370.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M234,386.9c2.5,1.9,5.5,2.6,7.9,2.4c2.4-0.2,4.2-1.4,4.6-3.1c0.4-1.7-0.6-3.5-2.4-4.9c-1.8-1.4-4.2-2.4-7.1-2.5
							c-3-0.1-5.1-0.2-7.2-0.3c-2.1-0.2-4.2-0.5-7.2-1.6c2.4,2,4,3.5,5.6,5C229.9,383.4,231.4,385,234,386.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M241.6,400.7c2,2.1,4.3,3.2,6.5,3.5c2.2,0.3,4.2-0.2,5.2-1.7c1-1.5,0.6-3.6-0.8-5.5c-1.4-1.9-3.8-3.6-6.8-4.2
							c-3.1-0.6-5.3-0.7-7.4-0.9c-2.1-0.2-4.3-0.4-7.2-1c2.6,1.6,4.2,3,5.6,4.5C238.2,397,239.6,398.6,241.6,400.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M249.5,414.4c1.3,2.2,3.2,3.7,5.1,4.3c1.9,0.7,3.9,0.6,5.2-0.6c1.3-1.2,1.6-3.2,0.7-5.3c-0.8-2.1-2.8-4.2-5.5-5.3
							c-2.8-1.1-4.9-1.5-6.9-1.9c-2-0.4-3.9-0.9-6.6-1.7c2.2,1.8,3.5,3.3,4.6,4.9C247.1,410.4,248.1,412.1,249.5,414.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M244.4,430c-0.4,2.4,0.1,4.4,1.1,6c1,1.6,2.6,2.7,4.3,2.7c1.8,0,3.3-1.3,4-3.4c0.7-2.1,0.6-4.9-0.9-7.3
							c-1.5-2.5-2.9-4-4.2-5.4c-1.3-1.4-2.6-2.9-4.3-4.9c0.7,2.6,0.8,4.4,0.7,6.2C245.1,425.8,244.8,427.6,244.4,430z"
                      />
                    </g>
                    <g>
                      <path
                        d="M230.5,431.1c-1,2.2-1.2,4.3-0.7,6.2c0.4,1.9,1.4,3.5,3.1,4.2c1.6,0.6,3.6-0.1,5-2.1c1.4-1.9,2.2-4.9,1.4-7.9
							c-0.8-3-1.8-4.9-2.8-6.8c-1-1.9-2-3.7-3.3-6.1c0.3,2.8,0,4.7-0.4,6.6C232.2,427,231.5,428.8,230.5,431.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M217.3,428c-1.6,2.5-2.2,5.3-1.9,7.5c0.2,2.3,1.2,4.1,2.9,4.7c1.7,0.5,3.6-0.4,5.1-2.2c1.5-1.9,2.5-4.7,2.4-7.7
							c-0.3-6.3-1.2-8.8-1.1-15C222.4,421,220.5,422.8,217.3,428z"
                      />
                    </g>
                    <g>
                      <path
                        d="M168.4,289.1c-2.1,1.5-3.5,3.2-4.2,5.1c-0.7,1.9-0.9,3.9,0.2,5.3c1,1.4,3.2,1.8,5.5,0.9c2.3-0.9,4.7-3.2,5.8-6.3
							c1.1-3.2,1.3-5.4,1.5-7.6c0.2-2.2,0.3-4.3,0.4-7.3c-1.2,2.7-2.5,4.3-3.9,5.7C172.1,286.4,170.5,287.6,168.4,289.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M169.7,305.9c-2.3,1.3-4,2.7-5.5,4.3c-1.4,1.6-2.5,3.4-2.1,5.1c0.2,0.9,0.8,1.6,1.7,2c1,0.5,2.3,0.7,3.8,0.5
							c1.5-0.2,3.2-0.8,4.8-1.9c1.5-1.1,3-2.6,3.9-4.4c1-1.9,1.5-3.5,1.8-5c0.3-1.5,0.4-2.8,0.5-4.1c0-2.6-0.1-5.2-0.9-8.6
							c-0.3,3.5-1.4,5.6-2.7,7.4C173.7,303.1,172.1,304.6,169.7,305.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M171.5,321.1c-2.3,1.7-3.9,3.7-4.8,5.8c-0.9,2-1.2,4.1-0.1,5.5c1,1.4,3.3,1.7,5.8,0.6c2.5-1.1,5.1-3.6,6.3-6.9
							c1.2-3.4,1.6-5.9,1.8-8.3c0.3-2.4,0.5-4.7,0.7-8.1c-1.3,3.1-2.6,4.9-4.1,6.5C175.6,317.9,173.9,319.3,171.5,321.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M170.5,340.1c-2.1,2.7-2.9,6-2.6,8.5c0.2,2.6,1.4,4.5,3.2,4.8c1.7,0.3,3.5-0.8,4.9-2.6c1.4-1.9,2.4-4.4,2.7-7.4
							c0.3-3.1,0.4-5.3,0.8-7.5c0.4-2.2,0.9-4.4,2.3-7.4c-2.3,2.4-3.9,4-5.6,5.6C174.4,335.7,172.6,337.3,170.5,340.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M175.9,358.4c-1.4,3.7-1.5,7.7-0.8,10.6c0.7,3,2.3,4.9,4,4.9c1.8,0,3.3-1.8,4.3-4.5c1-2.7,1.5-6.2,1.1-9.9
							c-0.4-3.8-0.7-6.5-0.9-9.2c-0.2-2.7-0.2-5.5,0.4-9.4c-1.6,3.7-2.9,6.2-4.2,8.7C178.7,352.1,177.4,354.6,175.9,358.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M183.3,381.6c-1.9,6.6,0.9,13.2,4.4,13.1c3.5-0.1,6-6.5,4.4-12.9c-1.7-6.6-3.1-9.1-3.7-16.1
							C186.9,372.5,185.3,374.9,183.3,381.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M190.9,400.8c-0.9,3.5-0.6,7.1,0.4,9.7c1,2.6,2.7,4.3,4.5,4.1c1.8-0.1,3.2-2,3.9-4.5c0.8-2.6,0.9-5.9,0-9.3
							c-0.9-3.5-1.6-5.9-2.2-8.4c-0.6-2.5-1-5-1.2-8.7c-0.9,3.6-1.8,6-2.6,8.5C192.8,394.7,191.9,397.2,190.9,400.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M200.2,412.2c-1.4,1.7-1.9,3.7-1.8,5.5c0.2,1.8,1.1,3.4,2.7,4.2c1.6,0.8,3.5,0.4,5-1c1.5-1.3,2.6-3.7,2.5-6.3
							c-0.2-2.7-0.6-4.5-1.1-6.3c-0.4-1.8-0.8-3.5-1.1-6c-0.7,2.4-1.6,3.9-2.6,5.3C202.8,409.1,201.7,410.4,200.2,412.2z"
                      />
                    </g>
                    <g>
                      <path d="M207,422.2c-4,4-4.1,10-1,11.6c3.1,1.6,8-2,8.8-7.6c0.9-5.8,0.5-8.2,2.1-13.8C213.4,417,211.1,418.1,207,422.2z" />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M378.5,8.1c9.5-5,18.3-11.4,25.5-18.6c7.3-7.2,12.8-15.2,16.3-22.6c3.6-7.4,5.3-14.1,5.8-18.9c0.5-4.8,0-7.5-0.8-7.6
							c-1.9-0.3-4.3,9.8-12.3,22.5c-4,6.3-9.3,13.2-16,19.6c-6.6,6.5-14.6,12.7-23,18.1c-8.7,5.6-16.7,9.6-24,13.2
							c-7.3,3.6-14,6.8-20.6,10.4c-6.6,3.6-13.1,7.4-19.8,12.5c-3.4,2.5-6.8,5.4-10.1,8.8c-3.4,3.4-6.6,7.4-9.6,12
							c6.4-9.1,13.8-14.9,20.7-19.4c7-4.5,13.7-7.6,20.5-10.5c6.8-2.8,13.7-5.3,21.4-8.2C360.1,16.5,368.7,13.3,378.5,8.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M323.1,19.3c2-2.4,2.7-5.4,2.3-7.8c-0.3-2.4-1.6-4.1-3.3-4.4c-1.7-0.3-3.5,0.7-4.8,2.3c-1.3,1.6-2.2,3.9-2.3,6.5
							c-0.1,2.7-0.2,4.7-0.5,6.7c-0.3,2-0.8,4-2.1,6.6c2.2-2,3.7-3.4,5.4-4.8C319.4,23.2,321.1,21.8,323.1,19.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M336.9,7.8c1.6-2.1,2-4.7,1.5-6.6c-0.5-2-2-3.4-3.7-3.5c-1.8-0.2-3.4,0.7-4.5,2.1c-1.1,1.4-1.8,3.2-1.6,5.4
							c0.2,2.3,0.4,4,0.4,5.7c0,1.7-0.1,3.4-1,5.8c1.7-1.8,3-3,4.4-4.2C333.8,11.1,335.2,9.9,336.9,7.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M351.2-2.2c1.5-2.3,1.7-5,1.1-7c-0.6-2.1-2-3.5-3.7-3.7c-1.8-0.2-3.4,0.8-4.6,2.4c-1.1,1.6-1.8,3.8-1.4,6.3
							c0.4,2.6,0.7,4.4,0.9,6.3c0.2,1.9,0.3,3.7-0.1,6.4c1.4-2.3,2.6-3.8,3.8-5.3C348.4,1.6,349.7,0.1,351.2-2.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M369.1-11.1c1.4-2.9,1.2-6.2,0.2-8.4c-1-2.3-2.9-3.4-4.6-3.1c-1.7,0.3-3,1.8-3.8,3.7c-0.8,1.9-1,4.1-0.5,6.5
							c0.5,2.6,0.8,4.5,0.9,6.5c0,2-0.1,4-1.2,6.8c2-2.2,3.4-3.8,4.8-5.5C366.2-6.4,367.6-8.1,369.1-11.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M382.6-22.2c1-3.1,0.7-6.3-0.3-8.6c-1-2.3-2.8-3.6-4.5-3.4c-1.8,0.2-3.1,1.8-3.8,4c-0.7,2.1-0.9,4.8-0.1,7.6
							c0.8,2.9,1.4,5,1.8,7.1c0.4,2.1,0.7,4.3,0.4,7.5c1.3-2.9,2.3-4.9,3.3-6.9C380.4-16.9,381.5-19,382.6-22.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M392.3-30.2c1.1-2.7,1-5.5,0-7.5c-0.9-2-2.6-3.3-4.3-3.1c-1.8,0.1-3.2,1.5-4,3.3c-0.8,1.8-1.1,4.2-0.4,6.6
							c0.7,2.5,1.3,4.4,1.7,6.3c0.4,1.9,0.7,3.8,0.5,6.6c1.2-2.5,2.2-4.2,3.2-5.9C390-25.7,391.1-27.5,392.3-30.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M404.8-44.7c1.1-3,0.9-6-0.1-8.3c-1-2.2-2.6-3.6-4.4-3.5c-1.8,0.1-3.2,1.6-4,3.7c-0.8,2.1-1,4.7-0.3,7.5
							c0.8,2.9,1.4,4.9,1.8,7c0.4,2.1,0.8,4.2,0.6,7.3c1.2-2.9,2.1-4.8,3.1-6.8C402.6-39.7,403.7-41.7,404.8-44.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M415.7-56.1c1.4-2.5,1.8-5.1,1.4-7.3c-0.3-2.2-1.4-3.9-3.1-4.4c-1.7-0.5-3.6,0.4-5,2.3c-1.4,1.9-2.3,4.7-1.9,7.8
							c0.4,3.1,0.9,5.2,1.3,7.3c0.5,2.1,0.9,4.2,1.2,7.2c0.8-2.9,1.6-4.9,2.6-6.7C413.2-51.8,414.3-53.5,415.7-56.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M426.4-67.6c1.7-1.9,2.5-4.1,2.6-6.1c0.1-2-0.6-3.9-2.1-4.8c-1.5-0.9-3.5-0.6-5.3,0.9c-1.8,1.4-3.2,3.9-3.4,6.8
							c-0.2,3,0,5.1,0.2,7.1c0.2,2,0.3,4,0.3,6.9c1-2.6,2.1-4.3,3.3-5.8C423.3-64.2,424.7-65.6,426.4-67.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M442.9-67.4c2.4-0.3,4.2-1.4,5.4-2.9c1.2-1.4,1.9-3.2,1.3-4.9c-0.5-1.7-2.2-2.8-4.4-2.8c-2.2-0.1-4.8,0.9-6.7,3.1
							c-1.9,2.2-2.9,3.9-3.9,5.6c-1,1.7-2,3.3-3.4,5.6c2.2-1.5,4-2.1,5.7-2.6C438.6-66.8,440.4-67.1,442.9-67.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M448-54.4c2.4,0.3,4.5-0.2,6.2-1.1c1.7-1,3-2.4,3.1-4.2c0.1-1.7-1.2-3.4-3.4-4.2c-2.2-0.8-5.3-0.6-7.9,1
							c-2.7,1.7-4.2,3.2-5.7,4.6c-1.5,1.6-2.9,3-4.8,5c2.6-1.1,4.5-1.4,6.4-1.5C443.6-54.9,445.5-54.7,448-54.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M449-40.9c2.9,0.8,5.7,0.5,7.8-0.4c2.1-0.9,3.6-2.4,3.6-4.2c0-1.8-1.4-3.3-3.6-4.2c-2.2-0.9-5.2-1-8.1,0
							c-6,2.1-8,3.8-14,5.5C440.8-43.7,443.1-42.4,449-40.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M330.8,46.9c2,1.6,4.1,2.4,6.1,2.5c2,0.2,3.9-0.3,5-1.7c1-1.4,0.8-3.5-0.8-5.5c-1.5-1.9-4.4-3.6-7.7-3.6
							c-3.3-0.1-5.5,0.4-7.7,0.8c-2.1,0.5-4.2,1-7.1,1.7c3,0.3,4.9,1.1,6.6,2C327.1,44.2,328.7,45.3,330.8,46.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M346.5,40.6c1.9,1.8,3.8,3.1,5.8,3.9c1.9,0.8,3.9,1.4,5.5,0.5c0.8-0.4,1.3-1.2,1.4-2.3c0.2-1.1,0-2.4-0.7-3.8
							c-0.6-1.4-1.7-2.8-3.2-4c-1.5-1.2-3.3-2.1-5.3-2.4c-2.1-0.4-3.8-0.4-5.3-0.3c-1.5,0.1-2.8,0.4-4.1,0.8
							c-2.5,0.7-4.9,1.6-7.9,3.4c3.4-0.8,5.8-0.3,7.9,0.4C342.6,37.7,344.5,38.8,346.5,40.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M360.5,34.4c2.3,1.7,4.7,2.6,6.9,2.9c2.2,0.3,4.3-0.1,5.3-1.5c1-1.4,0.7-3.6-1.1-5.7c-1.8-2.1-4.9-3.8-8.5-4
							c-3.6-0.2-6.1,0.3-8.5,0.7c-2.4,0.5-4.7,1-7.9,1.7c3.3,0.3,5.5,1.1,7.4,2C356.2,31.5,358.1,32.7,360.5,34.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M378.8,29.8c3.2,1.2,6.5,1,8.9,0c2.4-1,3.8-2.7,3.7-4.4c-0.2-1.8-1.8-3.1-4-3.9c-2.2-0.8-5-1-7.9-0.4
							c-3,0.7-5.2,1.2-7.4,1.5c-2.2,0.3-4.5,0.4-7.8,0c3,1.5,5,2.6,7,3.7C373.5,27.4,375.6,28.6,378.8,29.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M394.8,19.2c4,0.3,7.8-0.8,10.4-2.4c2.6-1.6,4-3.6,3.5-5.3c-0.5-1.7-2.7-2.6-5.6-2.8c-2.9-0.2-6.4,0.4-9.8,1.9
							c-3.5,1.5-6,2.6-8.5,3.6c-2.5,1-5.2,1.8-9.2,2.4c4,0.5,6.7,0.9,9.5,1.4C387.9,18.4,390.7,19,394.8,19.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M414.8,5.3c6.9-0.1,12.4-4.8,11.2-8.1c-1.2-3.3-8-3.8-13.6-0.3c-5.8,3.6-7.8,5.6-14.3,8.3
							C405,4.5,407.7,5.3,414.8,5.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M430.8-7.7c3.7-0.2,7-1.5,9.2-3.3c2.2-1.7,3.3-3.8,2.6-5.5c-0.7-1.6-2.8-2.4-5.5-2.4c-2.7,0-5.9,0.9-8.8,2.7
							c-3,1.9-5.1,3.3-7.3,4.6c-2.2,1.3-4.5,2.5-8,3.7c3.7-0.2,6.3-0.1,8.9,0C424.5-7.7,427.1-7.5,430.8-7.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M439-19.9c2.1,0.9,4.1,0.8,5.8,0c1.6-0.7,3-2.1,3.3-3.8c0.3-1.7-0.7-3.5-2.4-4.5c-1.7-1.1-4.3-1.4-6.7-0.5
							c-2.5,1-4.1,2-5.7,2.9c-1.6,0.9-3.1,1.8-5.4,2.9c2.5,0,4.2,0.4,5.8,0.9C435.2-21.5,436.8-20.8,439-19.9z"
                      />
                    </g>
                    <g>
                      <path d="M446.5-29.3c5,2.7,10.8,1,11.4-2.5c0.6-3.5-4.3-7-9.9-6.2c-5.8,0.9-8,2-13.8,2C439.6-33.9,441.3-32.1,446.5-29.3z" />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M533.7,299.2c0.6-0.6,1.1-1.2,1.6-1.8c0.5-0.7,1-1.3,1.5-2c0.9-1.4,1.8-2.8,2.4-4.3c1.4-3,2.2-6.2,2.6-9.4
						c0.8-6.4-0.3-12.8-2.8-18.6c-1.2-2.9-3-5.7-5-8.1c-2-2.4-4.2-4.5-6.6-6.4c-4.8-3.6-10.2-6.1-15.6-7.6c-2.7-0.7-5.5-1.3-8.2-1.5
						c-2.7-0.3-5.5-0.3-8.1-0.2c-5.4,0.3-10.8,1.5-15.7,3.8c-5,2.3-9.4,5.8-12.6,10c-1.6,2.1-2.9,4.5-3.9,6.9
						c-0.9,2.4-1.5,5.1-1.6,7.7c-0.3,5.1,1,10,3.5,14.1c1.3,2,2.9,3.8,4.7,5.2c1.8,1.4,3.8,2.4,5.8,3.1c4,1.3,8,1.3,11.5,0.3
						c3.5-1,6.4-3.3,8-5.7c1.7-2.4,2.4-4.9,2.6-7c0.2-2.1-0.1-4-0.6-5.3c-0.5-1.4-1.2-2.3-1.7-2.8c-0.6-0.5-1-0.6-1.3-0.5
						c-0.6,0.3-0.4,1.7-0.7,3.8c-0.1,1-0.4,2.2-1.1,3.5c-0.6,1.2-1.5,2.6-2.7,3.6c-0.6,0.5-1.3,0.9-2,1.3c-0.7,0.3-1.6,0.5-2.5,0.6
						c-1.8,0.2-3.8-0.2-5.7-1.2c-0.9-0.5-1.8-1.1-2.5-1.9c-0.7-0.7-1.3-1.6-1.8-2.7c-1-2.1-1.4-4.7-1-7.3c0.2-1.3,0.6-2.5,1.2-3.8
						c0.6-1.2,1.4-2.4,2.4-3.6c1-1.1,2.1-2.2,3.4-3.1c1.3-0.9,2.7-1.7,4.3-2.3c6.2-2.5,14.7-2.8,22.4-0.6c3.8,1.1,7.5,2.8,10.6,5.1
						c1.5,1.2,3,2.5,4.2,3.9c0.6,0.7,1.1,1.5,1.7,2.2l0.7,1.2l0.6,1.3c1.6,3.4,2.4,7.4,2.2,11.1c-0.1,1.9-0.5,3.7-1.2,5.4
						c-0.3,0.9-0.7,1.6-1.2,2.4l-0.7,1.1c-0.3,0.4-0.5,0.7-0.9,1.1c-2.5,3-6.1,5.2-10.1,6.9c-2,0.9-4.1,1.6-6.1,2.2
						c-1.1,0.3-2.1,0.6-3.2,0.9c-1.1,0.3-2.1,0.6-3.1,0.8c-8.4,1.9-16.5,2.6-24.2,2.5c-7.7-0.1-15.1-1.1-22.2-2.7
						c-7.1-1.6-13.8-3.8-20.3-6.6c-13-5.7-25.3-13.9-36.8-25.5c-5.7-5.8-11.3-12.5-16.4-20.3c-5.2-7.7-9.9-16.7-13.9-26.8
						c3.7,10.3,8,19.4,12.8,27.5c4.8,8.1,10,15.2,15.6,21.4c11.1,12.5,23,21.8,36.3,28.8c6.6,3.5,13.5,6.4,20.9,8.7
						c7.4,2.3,15.2,3.9,23.5,4.7c8.4,0.8,17.3,0.7,26.8-0.7c1.2-0.1,2.4-0.4,3.6-0.6c1.2-0.2,2.4-0.5,3.6-0.7c2.5-0.6,5-1.2,7.6-2.1
						c2.6-0.9,5.2-2,7.8-3.5C528.7,303.5,531.3,301.6,533.7,299.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M454.1,257.7c3.8-5.9,5.1-13.2,3.9-20.1c-0.6-3.4-1.9-6.8-3.7-9.7c-1.8-2.9-4.1-5.5-6.7-7.6c-2.6-2.1-5.5-3.7-8.6-4.7
						c-3.1-1-6.3-1.5-9.5-1.3c-3.2,0.2-6.3,0.9-9.1,2.4c-0.7,0.3-1.4,0.8-2,1.2c-0.7,0.5-1.3,0.9-1.9,1.4c-1.2,1-2.2,2.1-3.1,3.3
						c-1.7,2.4-2.8,5-3.3,7.6c-0.5,2.6-0.3,5.3,0.4,7.7c0.8,2.5,2.1,4.4,3.6,5.7c1.5,1.4,3,2.3,4.5,2.9c1.5,0.6,2.9,0.8,4.1,0.8
						c0.6,0,1.2-0.1,1.7-0.2c0.5-0.1,0.9-0.3,1.3-0.4c0.8-0.4,1.3-0.8,1.5-1.2c0.3-0.4,0.4-0.7,0.3-1.1c-0.2-0.7-1-1-2-1.6
						c-1-0.6-2.2-1.3-3.3-2.6c-1.1-1.2-1.9-3.1-1.8-4.5c0.1-0.9,0.2-1.7,0.7-2.6c0.4-0.9,1.1-1.8,1.9-2.6c0.4-0.4,0.9-0.7,1.3-1
						c0.2-0.1,0.5-0.2,0.7-0.4c0.3-0.1,0.5-0.2,0.8-0.3c1.1-0.4,2.4-0.5,3.9-0.5c2.9,0.1,5.9,1.3,8.6,3.4c1.3,1.1,2.5,2.4,3.4,3.8
						c0.9,1.5,1.6,3,2,4.7c0.8,3.4,0.5,7.4-1.1,10.7c-1.6,3.5-4.6,6.3-7.9,8.3c-3.4,1.9-7,3-10.6,3.3c-3.7,0.4-7.3,0.1-10.9-0.6
						c-3.5-0.7-7-1.8-10.4-3.2c-3.4-1.4-6.6-3.1-9.9-5.2c-3.2-2.1-6.3-4.6-9.4-7.5c-3-2.9-6-6.3-8.7-10.3c-2.7-4-5.2-8.6-7.1-14
						c3.2,11,8.3,19.4,13.9,26.1c2.8,3.4,5.7,6.3,8.8,9c3.1,2.6,6.3,4.9,9.7,7c3.4,2.1,7,3.8,11,5.2c3.9,1.4,8.2,2.4,13,2.7
						c4.7,0.3,10-0.4,15.2-2.5c2.6-1,5.2-2.5,7.8-4.4C449.8,263.2,452.2,260.8,454.1,257.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M336.8,288.9c-1.6,6.2-2,12.8-1.5,18.8c0.5,6,2,11.6,3.9,16c1.9,4.5,4.2,7.8,6.2,9.9c2.1,2.1,3.9,2.9,5,2.3
						c1.2-0.6,1.7-2.5,1.6-5.1c0.1-2.7-0.4-6.2-0.8-10.4c-0.4-4.1-0.9-8.8-1.2-13.8c-0.3-5-0.4-10.4-0.3-15.8
						c0.1-5.6,0.8-10.4,1.7-14.7c0.9-4.3,2.2-8.3,3.4-12.5c1.2-4.1,2.4-8.3,3.2-13.2c0.4-2.4,0.6-5,0.6-7.8c0-2.8-0.4-5.7-1.3-8.7
						c0.5,3.1,0.5,6,0.1,8.6c-0.4,2.6-1.1,5-1.9,7.2c-1.6,4.4-3.7,8-5.9,11.6c-2.2,3.6-4.5,7.2-6.8,11.5
						C340.7,277.4,338.5,282.5,336.8,288.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M350.5,287.2c-2,4.9-4.1,10-5.6,15.3c-1.5,5.3-2.2,10.7-1.9,15.5c0.3,4.8,1.5,8.7,3,11.2c1.5,2.5,3.2,3.5,4.5,3.1
						c1.3-0.4,2-2,2.7-4.3c0.7-2.3,1.4-5.3,2.3-8.9c0.9-3.6,2.2-7.7,3.7-12.4c1.4-4.7,3-10.1,4-15.9c0.9-6,1.2-11.1,1.2-15.7
						c0-4.6-0.4-8.7-0.9-12.8c-0.5-4.1-1.2-8.1-2.1-12.5c-1-4.4-2.2-9.3-4.1-14.9c1.1,5.8,1.4,10.8,1.4,15.2c0,4.4-0.4,8.4-0.9,12.2
						c-0.6,3.8-1.4,7.6-2.5,11.6C354.1,277.9,352.6,282.2,350.5,287.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M393.3,330.8c-0.5,0.7-1,1.3-1.5,1.9c-0.5,0.6-1.1,1.1-1.6,1.6c-1.2,0.9-2.4,1.7-3.7,2.3c-1.3,0.6-2.5,0.8-3.7,0.9
						c-1.2,0.1-2.1,0-3.2-0.4c-2.1-0.6-3.9-2.2-4.8-3.6c-0.4-0.7-0.7-1.4-0.8-2.3c-0.1-0.9,0-1.8,0.2-2.8c0.5-1.9,1.6-3.7,2.7-4.9
						c1.2-1.2,2-1.6,2.4-2c0.3-0.1,0.8-0.1,1.2,0c0.4,0.1,0.8,0.3,1.1,0.5c0.7,0.4,1.1,1.1,1.7,1.4c0.3,0.1,0.7,0.1,1.1-0.1
						c0.4-0.3,0.8-0.8,1.1-1.6c0.2-0.8,0.2-2-0.2-3.1c-0.4-1.2-1.2-2.4-2.6-3.5c-1.6-1.1-3.4-1.5-5.4-1.5c-1.9,0-3.8,0.5-5.6,1.3
						c-3.5,1.6-6.7,4.5-9,8.7c-1.1,2.1-1.9,4.6-2.1,7.3c-0.2,2.7,0.2,5.8,1.4,8.5c1.1,2.8,2.9,5.2,5.1,7.2c2.2,2,4.8,3.6,7.7,4.6
						c2.9,1,6.3,1.4,9.3,1.1c3.1-0.3,6.1-1.2,8.7-2.6c2.7-1.4,5-3.2,7.1-5.3c1-1.1,1.9-2.2,2.8-3.4c0.8-1.2,1.5-2.4,2.1-3.7
						c2.5-5.3,3.2-10.4,3.2-14.9c0-4.6-0.8-8.7-1.8-12.3c-1-3.7-2.3-6.9-3.6-10c-1.3-3.1-2.7-6-4.2-8.9c-1.5-2.9-3-5.7-4.8-8.6
						c-1.7-2.9-3.5-5.8-5.6-8.7c-4.1-6-8.9-12.3-15.4-19.3c5.9,7.4,9.9,14.3,13.1,20.6c1.6,3.2,3,6.2,4.2,9.2c1.2,3,2.2,6,3.2,8.9
						c0.9,3,1.7,6,2.4,9c0.7,3.1,1.3,6.2,1.6,9.4c0.3,3.2,0.3,6.5-0.3,9.8C396.4,324.7,395.3,328,393.3,330.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M211.1,46.3c3.4-0.8,6.9-1,10.2-0.6c3.3,0.4,6.4,1.5,8.9,3.1c2.6,1.6,4.7,3.9,6.2,6.4c0.7,1.2,1.3,2.5,1.7,3.8
						c0.4,1.3,0.5,2.6,0.6,3.9c0.1,5.3-3,11-7.3,15.2c-1.1,1.1-2.2,2.1-3.4,2.9c-1.2,0.9-2.4,1.7-3.5,2.4c-2.4,1.4-4.7,2.1-6.6,2.3
						c-1,0.1-1.9,0-2.6-0.2c-0.8-0.2-1.6-0.5-2.3-1c-1.5-1-2.7-2.4-3.5-3.9c-0.4-0.8-0.6-1.5-0.8-2.3c-0.1-0.4-0.1-0.8-0.2-1.1
						c0-0.4,0-0.7,0-1.1c0.1-1.5,0.6-3,1.3-4.2c1.4-2.5,3.4-4.2,4.9-5.2c1.5-1,2.5-1.6,2.5-2.3c0-0.6-1.2-1.4-3.6-1.3
						c-1.2,0-2.7,0.3-4.3,0.9c-1.6,0.7-3.4,1.7-5.1,3.3c-1.7,1.6-3.3,3.8-4.3,6.8c-0.3,0.7-0.4,1.5-0.6,2.3
						c-0.1,0.8-0.2,1.6-0.2,2.5c-0.1,1.7,0.2,3.4,0.6,5.2c0.9,3.5,2.9,6.8,5.9,9.6c1.5,1.4,3.4,2.6,5.5,3.4c2.2,0.9,4.4,1.3,6.6,1.4
						c4.5,0.2,8.9-1,12.7-2.8c1.9-0.9,3.7-1.9,5.5-3.1c1.8-1.2,3.5-2.5,5.1-4c1.6-1.4,3.2-3.1,4.6-4.9c1.4-1.8,2.7-3.7,3.9-5.8
						c1.1-2.1,2.1-4.4,2.8-6.9c0.7-2.5,1.1-5.1,1.1-7.8c0-2.7-0.3-5.5-1.1-8.1c-0.8-2.6-1.9-5.1-3.3-7.4c-2.8-4.6-6.7-8.4-11.4-11.2
						c-4.7-2.8-9.9-4.2-15.1-4.6c-5.2-0.4-10.3,0.3-15.2,1.9c-5,1.6-9.3,4-13,6.7c-3.7,2.7-6.9,5.8-9.6,9
						c-5.3,6.4-9.1,13.2-11.6,19.9c-2.5,6.7-3.9,13.4-4.6,19.9c-0.7,6.5-0.7,12.8-0.1,19.1c0.6,6.3,1.8,12.4,3.6,18.6
						c1.8,6.2,4.1,12.4,6.9,18.7c5.7,12.7,13.8,25.8,24.4,40.6c-10-15.2-17.2-28.8-22-41.6c-2.4-6.4-4.2-12.6-5.4-18.7
						c-1.2-6.1-1.8-12-1.9-18c0-5.9,0.5-11.8,1.7-17.7c1.2-5.8,3-11.7,5.8-17.4c2.8-5.6,6.5-11.1,11.4-16c2.4-2.4,5.2-4.6,8.2-6.5
						C204.3,48.6,207.6,47.1,211.1,46.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M217.7,111.6c1.7-0.5,3.2-0.7,4.8-0.7c1.5,0,2.9,0.2,4,0.6c0.5,0.2,1,0.4,1.3,0.7c0.4,0.2,0.7,0.5,0.9,0.8
						c0.5,0.6,1,1.5,1.3,2.5c0.2,0.5,0.3,1.1,0.3,1.7c0.1,0.6,0.1,1.1,0,1.7c-0.1,1.1-0.4,2.2-0.7,3c-0.2,0.4-0.4,0.8-0.6,1.1
						c-0.2,0.3-0.4,0.6-0.7,0.8c-0.3,0.2-0.4,0.4-0.7,0.6c-0.3,0.2-0.6,0.3-1,0.4c-0.7,0.2-1.5,0.2-2.3,0.1c-0.7-0.1-1.4-0.3-2-0.7
						c-1.2-0.6-2.2-2-2.8-3.3c-0.6-1.3-0.9-2.6-1.1-3.6c-0.2-1-0.5-1.8-1-2.2c-0.5-0.3-1.4-0.2-2.5,0.7c-1,0.9-2.2,2.7-2.6,5.4
						c-0.2,1.3-0.2,2.9,0.1,4.5c0.3,1.7,1,3.5,2.3,5.2c2.5,3.5,7,6.2,12.4,6.3c1.3,0,2.7-0.1,4.2-0.4c1.4-0.3,2.9-0.9,4.2-1.7
						c1.3-0.8,2.5-1.7,3.6-2.8c1.1-1.1,2-2.3,2.8-3.5c1.5-2.5,2.5-5.3,3-8.3c0.2-1.5,0.3-3,0.2-4.5c-0.1-1.5-0.3-3-0.7-4.5
						c-0.8-3-2.3-6.1-4.8-8.7c-2.5-2.6-5.8-4.5-9-5.3c-3.2-0.9-6.4-1-9.4-0.6c-1.5,0.2-2.9,0.5-4.3,0.9c-1.4,0.4-2.7,1-4,1.5
						c-5.3,2.4-9.2,5.9-12.2,9.4c-1.5,1.8-2.8,3.6-3.9,5.4c-1.1,1.9-2,3.7-2.8,5.5c-3,7.5-3.6,14.5-3.3,21.3
						c0.3,6.8,1.7,13.4,4,20.4c2.2,7,5.4,14.5,9.3,23.3c-3.1-9.1-5.5-16.9-6.7-24c-1.3-7.1-1.6-13.5-0.8-19.6
						c0.7-6.1,2.5-12,5.9-17.3c0.8-1.3,1.8-2.6,2.9-3.9c1.1-1.2,2.3-2.4,3.5-3.5C211.2,114.4,214.2,112.5,217.7,111.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M252.1,32.8c3.2,0.6,6.4-0.3,8.5-1.8c2.1-1.5,3-3.4,2.5-5.1c-0.6-1.7-2.4-2.7-4.6-3c-2.2-0.3-4.8,0-7.4,1.2
						c-2.7,1.2-4.6,2.1-6.6,2.8c-2,0.7-4.2,1.2-7.3,1.3c3,1,5.1,1.7,7.3,2.5C246.6,31.4,248.8,32.2,252.1,32.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M267.3,45.6c1.8,2,3.7,3.3,5.6,4.1c1.9,0.8,4,1.1,5.5,0.2c1.5-0.9,1.9-3.2,0.7-5.8c-1.2-2.6-4-5.2-7.6-6.1
						c-3.7-0.9-6.2-0.8-8.7-0.6c-2.4,0.2-4.8,0.6-8,1.4c3.3,0.3,5.4,1.2,7.2,2.3C263.8,42.2,265.4,43.6,267.3,45.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M270,60.5c0.6,2.6,1.5,4.6,2.7,6.3c1.2,1.6,2.7,3,4.5,3.1c0.9,0,1.7-0.4,2.4-1.2c0.3-0.4,0.7-0.9,0.9-1.5
						c0.2-0.6,0.4-1.3,0.5-2c0.4-2.9-0.8-6.8-3.6-9.5c-2.9-2.7-5.2-3.8-7.5-4.7c-2.3-0.9-4.7-1.6-7.9-2.2c3,1.6,4.4,3.3,5.6,5.1
						C268.6,55.8,269.4,57.8,270,60.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M263.1,73.6c-0.7,2.7-0.6,5.1,0.1,7.2c0.7,2,1.9,3.7,3.7,4c1.7,0.3,3.5-0.9,4.7-3.2c1.1-2.3,1.5-5.6,0.3-8.6
						c-1.2-3.2-2.4-5.1-3.5-7.1c-1.2-1.9-2.3-3.8-3.9-6.5c0.6,3.1,0.5,5.2,0.2,7.3C264.3,68.8,263.9,70.8,263.1,73.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M251.6,86.6c-1.8,2-2.8,4.1-3.2,6.2c-0.4,2.1-0.1,4.1,1.2,5.3c1.3,1.2,3.5,1,5.7-0.5c2.1-1.5,4-4.4,4.4-7.7
						c0.4-3.4,0.1-5.8-0.2-8c-0.3-2.3-0.7-4.5-1.2-7.5c-0.5,3.1-1.4,5-2.5,6.8C254.7,82.9,253.5,84.5,251.6,86.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M241.2,20.8c6-2.2,9.5-7.9,7.4-10.8c-2.1-2.9-8.6-1.4-12.5,3.7c-2,2.6-3.3,4.5-4.6,6.4c-0.7,0.9-1.3,1.9-2.1,2.7
						c-0.4,0.4-0.9,0.8-1.5,1.1c-0.6,0.3-1.4,0.4-2.1,0.2c0.7,0.5,1.5,0.7,2.3,0.6c0.8,0,1.5-0.2,2.2-0.4c0.7-0.2,1.3-0.4,1.9-0.6
						c0.6-0.2,1.1-0.4,1.7-0.6C235.8,22.5,238.1,21.9,241.2,20.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M227.4,15.6c2.1-2.5,2.5-5.9,1.9-8.2c-0.7-2.4-2.4-3.8-4.1-3.6c-1.8,0.1-3.2,1.4-4.2,2.9c-1,1.5-1.6,3.3-1.6,5.5
						c0,2.4-0.2,4.2-0.7,5.9c-0.5,1.8-1.3,3.6-3.2,5.6c2.6-1.2,4.4-2.1,6.2-3.2C223.5,19.4,225.3,18.1,227.4,15.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M216.5,14.7c1.1-3.1,0.5-6.4-0.9-8.5c-1.4-2.1-3.4-2.9-5-2.3c-1.7,0.6-2.6,2.3-3.1,4.1c-0.5,1.8-0.5,3.9,0.3,6.1
						c0.8,2.4,1.2,4.2,1.4,6.1c0.2,1.9,0.1,3.9-1,6.6c1.9-2.1,3.3-3.8,4.6-5.5C214.1,19.6,215.4,17.8,216.5,14.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M204,15.5c-0.4-2.8-2.4-5-4.4-5.8c-2.1-0.8-4-0.4-5.1,1c-1.1,1.4-1.2,3.2-0.9,4.8c0.3,1.6,1.1,2.9,2.7,4.1
						c1.7,1.2,2.9,2.3,3.9,3.5c1,1.2,1.9,2.7,2.4,5.1c0.6-2.4,0.9-4.1,1.3-5.9C204.2,20.4,204.4,18.4,204,15.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M189.6,18.1c-1.2-0.9-2.6-1.3-4-1.3c-1.4,0-2.6,0.3-3.6,1c-1,0.6-1.6,1.4-1.9,2.3c-0.3,0.9-0.2,1.7,0.1,2.5
						c0.7,1.6,2.1,2.7,3.1,3.4c1.1,0.7,2,0.9,3.4,0.4c1.5-0.5,2.9-0.5,4.3-0.3c1.4,0.3,2.8,1,4.1,2.7c-0.3-2.2-0.8-3.8-1.5-5.5
						C193,21.7,192,19.9,189.6,18.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M174.9,28.6c-3.1,0-5.9,1.8-7.2,3.8c-1.3,2-1.2,4.2,0,5.4c1.2,1.3,3.1,1.6,4.8,1.4c1.8-0.2,3.4-0.8,5.1-2.3
						c1.7-1.5,3.2-2.6,4.8-3.4c1.6-0.8,3.4-1.4,6.1-1.4c-2.5-1.1-4.3-1.8-6.3-2.4C180.2,29.1,178.1,28.5,174.9,28.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M168.5,41.5c-2.5,0.6-4.3,2.4-4.8,4.3c-0.6,1.9-0.1,3.7,1.3,4.8c1.4,1.1,3.2,1.3,4.7,1c1.5-0.3,2.8-1.1,3.7-2.8
						c1-1.7,1.7-3,2.7-4.3c0.9-1.2,2.1-2.3,4.1-3.3c-2.2,0-3.8-0.1-5.6-0.2C172.8,41,171,40.9,168.5,41.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M158.7,62.1c-2.2,1.8-3.3,4.4-3.4,6.6c-0.1,2.2,0.9,4,2.5,4.7c1.6,0.7,3.5,0.1,5.1-1.1c1.6-1.2,2.8-3.1,3.3-5.6
						c0.5-2.6,0.8-4.4,1.3-6.3c0.5-1.8,1.1-3.6,2.6-6c-2.2,1.6-3.9,2.7-5.6,3.7C162.7,59.2,161,60.2,158.7,62.1z"
                    />
                  </g>
                  <g>
                    <path d="M155.7,90.1c-2.8,3.4-1.4,8,2,9c3.4,1,7.1-2.2,6.5-6.5c-0.6-4.5-1.6-6.4-1.4-10.8C160.4,85.5,158.6,86.6,155.7,90.1z" />
                  </g>
                  <g>
                    <path
                      d="M157,113.8c-1.1,1.8-0.9,3.7-0.1,5.2c0.8,1.4,2.4,2.4,4.1,2.4c1.8,0,3.3-0.9,4.2-2.1c0.9-1.3,1.2-2.9,0.5-4.7
						c-1.5-3.7-2.8-5.3-3.2-9.2C160.8,108.9,159.2,110.1,157,113.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M191.5,102.9c2.1-4.3,3.5-8.8,3.9-12.4c0.5-3.6,0.1-6.5-1.5-7.2c-1.6-0.7-4.1,0.9-6.3,4.1c-2.2,3.2-4.1,8-4.6,13.1
						c-0.5,5.3-0.4,8.9-0.3,12.5c0.1,3.6,0.2,7.2,0.2,12.4c1-5.1,2.2-8.5,3.5-11.8C187.9,110.5,189.4,107.4,191.5,102.9z"
                    />
                  </g>
                  <g>
                    <g>
                      <path
                        d="M6.8,333.4c-3-1.7-5.7-4-7.9-6.6c-2.1-2.6-3.6-5.5-4.4-8.4c-0.8-2.9-0.8-6.1-0.2-8.9c0.3-1.4,0.8-2.7,1.4-3.9
							c0.6-1.2,1.4-2.2,2.3-3.2c3.6-3.9,9.7-6,15.7-6.1c1.5-0.1,3,0,4.5,0.1c1.5,0.1,2.9,0.4,4.2,0.7c2.7,0.6,4.8,1.6,6.3,2.8
							c0.8,0.6,1.4,1.3,1.8,1.9c0.5,0.7,0.8,1.5,1,2.3c0.4,1.7,0.4,3.6-0.2,5.2c-0.2,0.8-0.6,1.6-1,2.2c-0.2,0.4-0.4,0.6-0.7,0.9
							c-0.3,0.3-0.5,0.6-0.8,0.8c-1.1,1-2.5,1.7-3.9,2.2c-2.8,0.9-5.4,0.7-7.1,0.5c-1.8-0.3-3-0.5-3.4,0c-0.4,0.5-0.1,1.8,1.7,3.4
							c0.9,0.8,2.1,1.6,3.8,2.3c1.6,0.6,3.7,1.1,6,1.1c2.3,0,5.1-0.5,7.7-2c0.7-0.3,1.3-0.8,2-1.3c0.6-0.5,1.3-1,1.9-1.6
							c1.2-1.2,2.2-2.6,3.1-4.2c1.7-3.1,2.6-6.9,2.2-11c-0.2-2-0.7-4.2-1.7-6.3c-1-2.1-2.3-4-3.9-5.6c-3.2-3.2-7.2-5.3-11.2-6.6
							c-2-0.7-4.1-1.2-6.1-1.5c-2.1-0.4-4.2-0.5-6.4-0.6c-2.2-0.1-4.4,0.1-6.7,0.4c-2.3,0.3-4.6,0.9-6.8,1.6
							c-2.3,0.8-4.6,1.8-6.8,3.1c-2.2,1.4-4.3,3-6.1,4.9c-1.8,2-3.5,4.2-4.7,6.7c-1.2,2.4-2.1,5-2.7,7.6c-1.1,5.3-0.8,10.7,0.7,15.9
							c1.5,5.2,4.4,9.9,7.9,13.7c3.5,3.8,7.8,6.8,12.3,9c4.7,2.2,9.5,3.4,14.1,4c4.6,0.6,9,0.5,13.1,0c8.3-1,15.6-3.4,22.1-6.6
							c6.4-3.2,12-7.1,17-11.4c4.9-4.3,9.2-8.9,13.1-13.9c3.8-5,7.2-10.3,10.1-16c2.9-5.7,5.5-11.8,7.7-18.4
							c4.5-13.2,7.6-28.3,9.9-46.4c-3.1,18-7.1,32.8-12.3,45.4c-2.6,6.3-5.6,12.1-8.8,17.3c-3.3,5.3-6.9,10-10.9,14.4
							c-4,4.3-8.4,8.3-13.3,11.8c-4.9,3.5-10.2,6.5-16.1,8.7c-5.9,2.2-12.4,3.7-19.2,3.9c-3.4,0.1-6.9-0.1-10.4-0.9
							C13.3,336.4,9.9,335.2,6.8,333.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M46.6,281.2c-1.6-0.8-2.8-1.7-4-2.7c-1.1-1-2-2.1-2.5-3.1c-0.3-0.5-0.4-1-0.5-1.4c-0.1-0.4-0.1-0.8-0.1-1.2
							c0-0.8,0.3-1.7,0.8-2.7c0.3-0.5,0.6-1,0.9-1.5c0.4-0.5,0.7-0.9,1.1-1.2c0.8-0.8,1.8-1.4,2.6-1.7c0.4-0.2,0.8-0.3,1.2-0.4
							c0.4-0.1,0.7-0.1,1-0.1c0.3,0,0.6,0,0.9,0.1c0.3,0.1,0.6,0.2,1,0.4c0.7,0.3,1.3,0.9,1.7,1.5c0.5,0.6,0.8,1.2,1,1.8
							c0.4,1.3,0.2,2.9-0.2,4.3c-0.5,1.4-1.1,2.5-1.7,3.4c-0.5,0.9-0.9,1.6-0.7,2.3c0.2,0.6,0.9,1.1,2.3,1.2c1.4,0,3.5-0.5,5.6-2.2
							c1.1-0.8,2.1-1.9,3-3.4c0.9-1.4,1.6-3.3,1.9-5.4c0.6-4.3-0.9-9.3-4.7-13c-1-0.9-2.1-1.8-3.3-2.5c-1.3-0.7-2.7-1.3-4.2-1.6
							c-1.5-0.3-3-0.5-4.5-0.5c-1.5,0.1-3,0.3-4.4,0.7c-2.9,0.8-5.5,2.2-7.8,4c-1.2,0.9-2.3,2-3.2,3.2c-1,1.2-1.8,2.4-2.6,3.8
							c-1.5,2.7-2.5,6-2.5,9.6c0,3.6,1.2,7.2,2.9,10c1.7,2.9,4,5.1,6.4,6.8c1.2,0.9,2.5,1.6,3.8,2.3c1.3,0.7,2.7,1.2,4,1.6
							c5.5,1.8,10.7,2,15.4,1.5c2.3-0.3,4.5-0.7,6.6-1.3c2.1-0.6,4-1.3,5.8-2.2c7.3-3.4,12.6-8.1,17-13.2c4.4-5.2,7.9-10.9,11-17.6
							c3.2-6.7,5.9-14.3,9.1-23.4c-4,8.8-7.5,16.1-11.4,22.1c-3.9,6.1-8.1,10.9-12.8,14.9c-4.7,3.9-10,7-16.1,8.6
							c-1.5,0.4-3.1,0.7-4.7,0.9c-1.6,0.2-3.3,0.2-5,0.1C53.2,283.5,49.7,282.8,46.6,281.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M-32.4,315.2c-2-2.6-4.9-4.2-7.4-4.5c-2.5-0.4-4.6,0.4-5.3,2c-0.7,1.6-0.1,3.6,1.3,5.3c1.4,1.7,3.5,3.3,6.2,4.2
							c2.8,0.9,4.8,1.6,6.8,2.5c2,0.9,3.9,1.9,6.2,4c-1.5-2.8-2.6-4.7-3.6-6.8C-29.3,320-30.4,317.9-32.4,315.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M-34.7,295.5c0-2.7-0.4-4.9-1.3-6.9c-0.9-1.9-2.1-3.5-3.9-3.8c-1.7-0.3-3.6,1.1-4.5,3.8c-0.9,2.7-0.6,6.6,1.4,9.7
							c2.1,3.2,4,4.8,5.9,6.3c1.9,1.5,3.9,2.8,6.8,4.4c-2.2-2.4-3.1-4.5-3.7-6.6C-34.5,300.4-34.8,298.3-34.7,295.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M-26.6,282.8c1.3-2.3,2-4.4,2.3-6.5c0.2-2,0.1-4.1-1.2-5.3c-0.6-0.6-1.5-0.9-2.5-0.8c-0.5,0.1-1.1,0.2-1.6,0.5
							c-0.5,0.3-1.2,0.6-1.7,1.1c-2.3,1.9-4.1,5.5-3.9,9.3c0.3,3.9,1.2,6.3,2.3,8.6c1.1,2.2,2.3,4.4,4.3,7c-1.1-3.2-1-5.5-0.6-7.6
							C-28.7,287.1-27.9,285.2-26.6,282.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M-12.6,277.9c2.3-1.5,3.9-3.4,4.9-5.3c0.9-1.9,1.1-4,0.1-5.4c-1-1.4-3.2-1.8-5.6-0.9c-2.4,0.9-4.9,3.1-6.2,6.1
							c-1.3,3.1-1.8,5.4-2.3,7.6c-0.4,2.2-0.9,4.4-1.5,7.4c1.7-2.6,3.2-4.2,4.8-5.5C-16.7,280.6-15,279.4-12.6,277.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M4.7,276.2c2.7-0.3,4.9-1.1,6.6-2.4c1.7-1.3,2.9-2.9,2.7-4.7c-0.2-1.7-1.9-3.2-4.4-3.5c-2.5-0.4-5.9,0.4-8.5,2.6
							c-2.6,2.3-4,4.2-5.4,6c-1.3,1.9-2.6,3.7-4.3,6.3c2.5-1.9,4.5-2.7,6.5-3.3C-0.1,276.8,1.9,276.5,4.7,276.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M-32.6,331.5c-5.9-2.5-12.3-0.7-12.8,2.8c-0.4,3.5,5.4,6.9,11.7,5.9c3.2-0.5,5.5-1.1,7.8-1.5c1.2-0.2,2.2-0.5,3.4-0.5
							c0.6,0,1.2,0,1.8,0.2c0.6,0.2,1.3,0.6,1.7,1.3c-0.2-0.8-0.7-1.5-1.2-2c-0.6-0.5-1.2-0.9-1.8-1.2c-0.6-0.3-1.2-0.6-1.8-0.8
							c-0.6-0.2-1.1-0.5-1.6-0.7C-27.5,333.9-29.6,332.8-32.6,331.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M-26.1,344.6c-3.2,0.4-5.9,2.5-7,4.8c-1.2,2.2-0.8,4.4,0.5,5.5c1.4,1.1,3.3,1.1,5.1,0.8c1.8-0.4,3.4-1.3,4.9-3
							c1.6-1.7,3-2.9,4.6-3.9c1.6-1,3.4-1.7,6.2-1.9c-2.7-0.9-4.6-1.4-6.7-1.9C-20.6,344.6-22.8,344.2-26.1,344.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M-18.7,352.8c-2.9,1.5-4.7,4.4-5.1,6.8c-0.4,2.5,0.5,4.5,2.1,5.1c1.7,0.7,3.5,0.1,5.1-0.9c1.6-1,3-2.5,4-4.7
							c1-2.2,2-3.9,3.1-5.4c1.2-1.5,2.6-2.9,5.2-4.2c-2.9,0.2-5,0.5-7.1,0.9C-13.6,350.8-15.8,351.3-18.7,352.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M-9,360.7c-1.6,2.3-1.7,5.3-0.7,7.3c0.9,2,2.7,3,4.4,2.7c1.7-0.3,3.1-1.5,4-2.9c0.9-1.4,1.2-2.9,0.8-4.8
							c-0.4-2-0.6-3.6-0.5-5.2c0.1-1.6,0.4-3.3,1.7-5.4c-2,1.3-3.5,2.4-5,3.5C-5.8,357-7.4,358.3-9,360.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M3.3,368.7c0.3,1.5,1,2.7,2,3.7c1,0.9,2.1,1.5,3.2,1.7c1.1,0.2,2.1,0,2.9-0.4c0.8-0.4,1.3-1.1,1.6-1.9
							c0.6-1.7,0.4-3.4,0.1-4.6c-0.3-1.3-0.8-2.1-2.2-2.7c-1.4-0.7-2.5-1.6-3.3-2.7c-0.8-1.2-1.4-2.6-1.1-4.8
							c-1.3,1.8-2,3.4-2.6,5.1C3.3,363.7,2.7,365.7,3.3,368.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M21.2,371c2.3,2.1,5.5,2.7,7.9,2.1c2.4-0.6,3.8-2.2,3.7-3.9c0-1.8-1.2-3.3-2.6-4.4c-1.4-1.1-3.1-1.7-5.3-1.8
							c-2.3-0.1-4.1-0.3-5.8-0.8c-1.7-0.5-3.4-1.3-5.4-3.2c1.1,2.5,1.9,4.3,2.9,6.1C17.7,367,18.8,368.9,21.2,371z"
                      />
                    </g>
                    <g>
                      <path
                        d="M34.6,366c2.2,1.3,4.7,1.2,6.4,0.2c1.7-1,2.6-2.7,2.3-4.4c-0.3-1.7-1.4-3.1-2.7-4c-1.3-0.8-2.8-1.1-4.7-0.5
							c-1.9,0.6-3.3,1-4.8,1.3c-1.5,0.2-3.1,0.3-5.2-0.4c1.6,1.5,2.7,2.7,3.9,3.9C31.1,363.4,32.4,364.7,34.6,366z"
                      />
                    </g>
                    <g>
                      <path
                        d="M55.9,357.6c2.9,0.2,5.5-1,7-2.5c1.6-1.6,2.1-3.5,1.4-5.1c-0.7-1.6-2.5-2.5-4.5-2.7c-2-0.2-4.2,0.4-6.2,1.9
							c-2.1,1.5-3.6,2.7-5.2,3.7c-1.6,1-3.3,1.9-6,2.6c2.7,0.3,4.7,0.7,6.6,1.1C51,357,52.9,357.4,55.9,357.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M77.2,339.2c4.4-0.5,6.5-4.9,4.7-7.9c-1.8-3-6.6-3.2-9.2,0.4c-2.6,3.7-3.2,5.7-6.3,8.9
							C70.6,339.3,72.7,339.8,77.2,339.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M92.4,321c2-0.6,3.2-2.1,3.6-3.7c0.4-1.6-0.1-3.4-1.4-4.6c-1.3-1.2-3-1.6-4.5-1.3c-1.5,0.3-2.9,1.3-3.6,3.1
							c-1.4,3.7-1.5,5.7-3.9,8.9C86.3,321.9,88.3,322.2,92.4,321z"
                      />
                    </g>
                    <g>
                      <path
                        d="M59.8,305.3c-4.5,1.7-8.5,4.1-11.4,6.4c-2.8,2.3-4.5,4.7-3.8,6.3c0.7,1.6,3.6,2.1,7.4,1.3c3.8-0.8,8.5-3,12.3-6.5
							c3.9-3.5,6.3-6.2,8.8-8.9c2.4-2.7,4.8-5.4,8.3-9.3c-4.2,3.1-7.4,4.7-10.6,6.2C67.6,302.3,64.4,303.6,59.8,305.3z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M363.6,398.2c3.2,1.3,6.2,3.2,8.6,5.5c2.4,2.3,4.3,5,5.4,7.7c1.1,2.8,1.6,5.9,1.3,8.8c-0.1,1.4-0.4,2.8-0.9,4.1
							c-0.4,1.3-1.1,2.4-1.8,3.5c-3,4.3-8.8,7.2-14.8,8.1c-1.5,0.3-3,0.4-4.4,0.4c-1.5,0.1-2.9,0-4.3-0.1c-2.7-0.3-5-1-6.7-2
							c-0.8-0.5-1.5-1.1-2-1.7c-0.5-0.6-1-1.4-1.3-2.2c-0.6-1.7-0.8-3.6-0.5-5.2c0.1-0.8,0.4-1.6,0.7-2.3c0.1-0.4,0.4-0.7,0.5-1
							c0.2-0.3,0.4-0.6,0.7-0.9c1-1.2,2.2-2.1,3.6-2.7c2.6-1.2,5.2-1.4,7-1.4c1.8,0,3,0.1,3.4-0.4c0.4-0.5-0.2-1.8-2.1-3.2
							c-1-0.7-2.3-1.3-4-1.8c-1.7-0.4-3.8-0.6-6.1-0.3c-2.3,0.3-4.9,1.2-7.4,3c-0.6,0.4-1.2,1-1.8,1.5c-0.6,0.6-1.2,1.2-1.6,1.9
							c-1,1.3-1.9,2.9-2.5,4.5c-1.3,3.3-1.6,7.2-0.8,11.2c0.4,2,1.2,4.1,2.4,6c1.3,2,2.8,3.6,4.6,5c3.6,2.8,7.8,4.3,11.9,5.1
							c2.1,0.4,4.2,0.6,6.3,0.7c2.1,0.1,4.3,0,6.4-0.3c2.2-0.2,4.4-0.7,6.6-1.3c2.2-0.6,4.4-1.4,6.6-2.5c2.2-1.1,4.3-2.4,6.3-4
							c2-1.6,3.8-3.5,5.5-5.7c1.6-2.2,2.9-4.6,3.8-7.2c0.9-2.6,1.5-5.2,1.7-7.9c0.4-5.4-0.6-10.7-2.7-15.7c-2.2-5-5.6-9.2-9.6-12.6
							c-4-3.3-8.6-5.8-13.4-7.3c-5-1.6-9.9-2.2-14.5-2.1c-4.6,0-9,0.7-13,1.7c-8.1,2.1-15,5.4-21,9.4c-6,4-11,8.6-15.3,13.5
							c-4.3,4.9-8,10-11.1,15.5c-3.1,5.4-5.8,11.2-8,17.2c-2.2,6.1-3.9,12.4-5.3,19.3c-2.7,13.7-3.8,29-3.8,47.2
							c0.7-18.2,2.8-33.4,6.3-46.6c1.8-6.6,3.9-12.7,6.5-18.3c2.6-5.7,5.5-10.9,8.9-15.7c3.4-4.8,7.3-9.3,11.6-13.4
							c4.4-4.1,9.3-7.7,14.8-10.7c5.5-3,11.8-5.2,18.5-6.4c3.4-0.5,6.9-0.8,10.4-0.5C356.7,396.1,360.3,396.8,363.6,398.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M331,455.1c1.7,0.6,3,1.3,4.3,2.2c1.2,0.9,2.2,1.9,2.9,2.8c0.3,0.5,0.5,0.9,0.7,1.3c0.2,0.4,0.2,0.8,0.3,1.2
							c0.1,0.8,0,1.8-0.4,2.8c-0.2,0.5-0.4,1.1-0.7,1.6c-0.3,0.5-0.6,0.9-1,1.4c-0.7,0.9-1.6,1.6-2.4,2.1c-0.4,0.2-0.8,0.4-1.1,0.6
							c-0.4,0.1-0.7,0.2-1,0.3c-0.3,0-0.6,0.1-0.9,0c-0.3,0-0.7-0.1-1-0.2c-0.7-0.3-1.4-0.7-1.9-1.2c-0.5-0.5-1-1.1-1.2-1.7
							c-0.6-1.2-0.6-2.9-0.3-4.3c0.3-1.4,0.8-2.6,1.2-3.6c0.4-1,0.7-1.7,0.4-2.4c-0.2-0.6-1-1-2.5-0.9c-1.4,0.2-3.4,0.9-5.3,2.9
							c-0.9,1-1.9,2.2-2.6,3.7c-0.7,1.6-1.2,3.4-1.2,5.6c0,4.3,2.1,9.1,6.4,12.3c1.1,0.8,2.3,1.5,3.6,2.1c1.3,0.5,2.9,0.9,4.4,1.1
							c1.5,0.1,3,0.1,4.5-0.1c1.5-0.3,2.9-0.7,4.3-1.2c2.7-1.2,5.2-2.9,7.2-5c1-1.1,2-2.3,2.8-3.6c0.8-1.3,1.5-2.6,2-4.1
							c1.1-2.9,1.7-6.3,1.2-9.9c-0.5-3.6-2.1-7-4.2-9.5c-2.1-2.6-4.6-4.5-7.3-5.9c-1.3-0.7-2.7-1.3-4.1-1.8
							c-1.4-0.5-2.8-0.8-4.1-1.1c-5.7-1.1-10.9-0.6-15.4,0.5c-2.3,0.6-4.4,1.3-6.3,2.2c-2,0.9-3.8,1.8-5.5,2.9
							c-6.8,4.3-11.4,9.7-15.1,15.3c-3.7,5.7-6.4,11.9-8.7,18.9c-2.3,7-4,15-6,24.4c2.8-9.2,5.4-16.9,8.5-23.4
							c3.1-6.5,6.6-11.9,10.7-16.4c4.1-4.5,9-8.3,14.8-10.6c1.5-0.6,3-1.1,4.6-1.5c1.6-0.4,3.2-0.6,4.9-0.8
							C324,453.6,327.6,453.9,331,455.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M404.9,411.1c2.3,2.3,5.4,3.5,7.9,3.5c2.5,0,4.5-1,5-2.7c0.5-1.7-0.4-3.6-2-5.1c-1.6-1.6-3.9-2.8-6.7-3.3
							c-2.9-0.6-5-1-7-1.6c-2.1-0.6-4.1-1.4-6.7-3.2c1.9,2.5,3.2,4.4,4.4,6.3C401.2,406.8,402.5,408.7,404.9,411.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M409.7,430.4c0.3,2.7,1.1,4.8,2.2,6.6c1.1,1.8,2.6,3.2,4.3,3.3c1.7,0.1,3.4-1.5,4-4.4c0.6-2.8-0.2-6.6-2.6-9.4
							c-2.5-2.9-4.6-4.3-6.7-5.5c-2.1-1.2-4.2-2.3-7.3-3.5c2.5,2.1,3.7,4.1,4.5,6.1C408.9,425.6,409.4,427.6,409.7,430.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M403.3,444.1c-1,2.4-1.5,4.6-1.4,6.7c0,2,0.4,4,1.8,5.1c0.7,0.5,1.6,0.7,2.6,0.4c0.5-0.1,1.1-0.3,1.6-0.7
							c0.5-0.3,1.1-0.8,1.6-1.3c2-2.1,3.4-6,2.6-9.8c-0.8-3.8-2.1-6.1-3.4-8.2c-1.4-2.1-2.8-4-5.2-6.4c1.5,3,1.7,5.3,1.5,7.4
							C404.8,439.5,404.3,441.5,403.3,444.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M390,450.7c-2.1,1.7-3.5,3.8-4.1,5.9c-0.6,2-0.6,4.1,0.6,5.4c1.2,1.3,3.4,1.3,5.6,0.1c2.2-1.2,4.4-3.7,5.3-6.8
							c0.9-3.3,1.1-5.6,1.3-7.8c0.2-2.3,0.3-4.5,0.6-7.6c-1.3,2.8-2.6,4.5-4.1,6.1C393.8,447.5,392.2,448.9,390,450.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M373.1,454.6c-2.6,0.6-4.7,1.8-6.2,3.2c-1.5,1.5-2.5,3.3-2.1,5c0.4,1.7,2.3,2.9,4.9,2.9c2.6,0,5.8-1.2,8.1-3.7
							c2.3-2.6,3.4-4.6,4.5-6.7c1.1-2,2.1-4,3.4-6.8c-2.2,2.2-4.1,3.3-6,4.1C377.8,453.4,375.8,453.9,373.1,454.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M402.9,395c6.1,1.8,12.3-0.9,12.3-4.5c0-3.5-6.2-6.1-12.3-4.3c-3.2,0.9-5.3,1.8-7.5,2.5c-1.1,0.4-2.2,0.8-3.3,0.9
							c-0.6,0.1-1.2,0.1-1.8,0c-0.6-0.1-1.3-0.5-1.8-1.1c0.3,0.8,0.9,1.4,1.5,1.9c0.6,0.4,1.3,0.7,2,1c0.6,0.2,1.3,0.4,1.9,0.6
							c0.6,0.2,1.1,0.3,1.7,0.5C397.6,393.2,399.8,394.1,402.9,395z"
                      />
                    </g>
                    <g>
                      <path
                        d="M394.7,382.8c3.1-0.8,5.5-3.3,6.3-5.6c0.9-2.4,0.2-4.4-1.3-5.3c-1.5-0.9-3.4-0.7-5.1-0.1c-1.7,0.6-3.2,1.7-4.5,3.6
							c-1.4,1.9-2.6,3.3-4,4.4c-1.4,1.1-3.1,2.2-5.9,2.7c2.8,0.5,4.8,0.8,6.9,1C389.3,383.5,391.5,383.6,394.7,382.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M386.4,375.6c2.7-1.9,4.1-4.9,4.2-7.4c0.1-2.5-1.1-4.4-2.8-4.8c-1.7-0.4-3.5,0.4-5,1.5c-1.5,1.2-2.6,2.9-3.3,5.2
							c-0.7,2.4-1.5,4.1-2.4,5.8c-1,1.6-2.2,3.2-4.6,4.8c2.8-0.6,4.8-1.2,6.9-1.8C381.5,378.3,383.7,377.5,386.4,375.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M375.7,369.1c1.3-2.5,1-5.5-0.2-7.3c-1.2-1.9-3.1-2.6-4.7-2.1c-1.7,0.5-2.9,1.9-3.6,3.3c-0.7,1.5-0.8,3-0.2,4.9
							c0.6,1.9,1,3.5,1.2,5.1c0.1,1.6,0,3.3-1,5.5c1.8-1.6,3.2-2.8,4.5-4.1C373.1,373.1,374.4,371.7,375.7,369.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M362.5,362.8c-0.5-1.4-1.4-2.6-2.5-3.4c-1.1-0.8-2.3-1.2-3.4-1.3c-1.1-0.1-2.1,0.2-2.8,0.8c-0.7,0.5-1.2,1.3-1.4,2.1
							c-0.4,1.7,0.1,3.4,0.5,4.6c0.5,1.2,1.1,1.9,2.5,2.4c1.5,0.5,2.7,1.2,3.6,2.3c0.9,1,1.7,2.4,1.8,4.6c1-2,1.6-3.6,2-5.4
							C363.2,367.7,363.5,365.7,362.5,362.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M344.5,362.7c-2.6-1.8-5.9-2-8.1-1.1c-2.3,0.9-3.4,2.7-3.2,4.4c0.3,1.8,1.6,3.1,3.1,4c1.5,0.9,3.3,1.3,5.5,1.1
							c2.3-0.2,4.1-0.2,5.8,0c1.8,0.3,3.6,0.8,5.8,2.5c-1.4-2.3-2.5-4-3.7-5.7C348.5,366.3,347.1,364.6,344.5,362.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M331.8,369.5c-2.3-1-4.8-0.5-6.4,0.6c-1.6,1.2-2.2,3-1.8,4.7c0.5,1.7,1.8,2.9,3.2,3.6c1.4,0.6,3,0.7,4.7-0.1
							c1.8-0.8,3.2-1.5,4.6-1.9c1.5-0.4,3-0.7,5.2-0.3c-1.8-1.3-3.1-2.3-4.4-3.4C335.6,371.6,334.2,370.5,331.8,369.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M311.8,380.6c-2.9,0.2-5.3,1.7-6.6,3.4c-1.4,1.8-1.7,3.8-0.7,5.3c0.9,1.5,2.8,2.1,4.8,2c2-0.1,4.1-0.9,5.9-2.7
							c1.9-1.8,3.2-3.1,4.7-4.3c1.5-1.2,3-2.3,5.6-3.4c-2.8,0-4.7-0.1-6.7-0.3C316.8,380.6,314.7,380.4,311.8,380.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M293.1,401.5c-4.3,1.1-5.8,5.7-3.6,8.4c2.2,2.8,7,2.4,9-1.5c2.1-4,2.4-6.1,5.1-9.6
							C299.6,400.6,297.5,400.4,293.1,401.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M280.3,421.6c-1.9,0.9-2.9,2.5-3.1,4.2c-0.2,1.7,0.5,3.3,2,4.4c1.4,1,3.2,1.2,4.7,0.7c1.5-0.5,2.7-1.7,3.1-3.6
							c0.9-3.9,0.8-5.9,2.7-9.4C286.3,419.9,284.3,419.9,280.3,421.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M314.7,432.9c4.2-2.3,7.9-5.1,10.4-7.8c2.5-2.7,3.8-5.2,2.9-6.7c-0.9-1.5-3.8-1.6-7.5-0.3c-3.7,1.3-8,4.1-11.4,8
							c-3.5,4-5.5,7-7.5,10c-2,3-4,6-7,10.3c3.8-3.6,6.7-5.7,9.7-7.5C307.3,436.9,310.4,435.2,314.7,432.9z"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M344.5,109.3c1.3-4.4,1.9-9.1,1.4-13.7c-0.4-4.6-1.8-9.3-4.3-13.4c-0.3-0.5-0.6-1-1-1.5c-0.4-0.5-0.7-1-1.1-1.5
						c-0.8-0.9-1.5-1.8-2.4-2.7c-1.7-1.7-3.6-3.1-5.6-4.3c-4-2.4-8.3-3.8-12.6-4.4c-4.3-0.6-8.7-0.6-12.9,0.4
						c-4.2,0.9-8.3,2.7-11.6,5.5c-3.3,2.7-5.9,6.2-7.2,10.2c-0.7,1.9-1.1,4.1-1.1,6.1c0,2,0.2,4,0.8,5.9c1.1,3.7,3.3,7,6.3,9.2
						c3,2.2,6.5,3.1,9.6,3c3.1-0.1,6.1-1.1,8.3-2.8c1.1-0.9,2-1.9,2.7-2.9c0.7-1.1,1.2-2.2,1.4-3.2c0.6-2.1,0.5-4.1,0.2-5.7
						c-0.3-1.6-1-2.9-1.6-3.8c-1.4-1.8-2.6-2.1-2.9-1.8c-0.3,0.3,0.2,1.3,0.6,2.9c0.2,0.8,0.3,1.8,0.2,3c-0.1,1.1-0.4,2.4-1,3.7
						c-0.3,0.6-0.7,1.2-1.2,1.7c-0.5,0.5-1.1,0.9-1.7,1.3c-1.3,0.7-3,1.1-4.8,0.9c-1.8-0.2-3.7-0.9-5-2.1c-1.4-1.3-2.5-3.2-2.9-5.3
						c-0.2-1.1-0.3-2.2-0.2-3.4c0.1-1.1,0.4-2.2,0.8-3.3c0.9-2.2,2.6-4.3,4.7-6c2.1-1.6,4.8-2.7,7.7-3.3c2.9-0.6,6.2-0.6,9.4-0.1
						c6.4,1,12.6,4.2,15.9,9.6c3.5,5.4,4.4,12.8,2.7,19.6c-0.4,1.7-1,3.4-1.8,5c-0.7,1.6-1.6,3.1-2.6,4.6c-2,2.9-4.2,5.4-6.7,7.6
						c-5,4.4-10.6,7.5-16.3,9.7c-5.7,2.3-11.4,3.6-17.1,4.6c-5.7,1-11.4,1.5-17.1,1.9c-5.7,0.4-11.5,0.5-17.5,0.9
						c-6,0.4-12.3,1.1-18.6,3c-3.2,0.9-6.4,2.1-9.5,3.8c-3.1,1.6-6.1,3.7-8.8,6.3c-2.7,2.6-5,5.8-6.7,9.4c-1.6,3.6-2.6,7.8-2.2,12.1
						c-0.2-4.3,0.9-8.4,2.6-12c1.8-3.5,4.1-6.6,6.9-9c2.7-2.4,5.7-4.3,8.8-5.8c3.1-1.4,6.2-2.5,9.4-3.2c6.3-1.5,12.4-1.9,18.3-1.9
						c5.9-0.1,11.7,0.2,17.5,0.2c5.8,0,11.6-0.2,17.6-0.8c6-0.7,12.2-1.7,18.5-3.8c6.3-2,12.9-5,19-9.8c3.1-2.4,6-5.2,8.6-8.6
						C340.9,117.8,343.1,113.9,344.5,109.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M278.5,96c-3.9-1.2-8.4-1.3-12.5,0.5c-2,0.9-3.8,2.1-5.3,3.7c-1.5,1.5-2.7,3.3-3.6,5.3c-0.8,2-1.2,4.2-1.1,6.4
						c0.1,2.2,0.7,4.2,1.6,6c1.8,3.5,4.9,6.1,8.4,7c3.5,1,6.9,0.3,9.4-1.4c1.2-0.8,2.2-1.9,2.8-3c0.6-1.1,0.9-2.2,1-3.1
						c0.2-1.9-0.3-3.4-0.9-4.2c-0.6-0.8-1.2-1-1.6-0.9c-0.8,0.3-0.9,2.1-2.2,3.6c-0.3,0.4-0.7,0.8-1.1,1c-0.4,0.3-0.8,0.4-1.3,0.5
						c-1,0.2-2.4,0.1-3.4-0.5c-1.1-0.6-2.2-1.8-2.7-3.2c-0.6-1.5-0.5-2.8,0.1-4.4c0.7-1.6,2.1-3.1,3.8-4c1.7-0.9,3.9-1.2,6.3-0.9
						c4.9,0.7,8.5,4.3,10.6,8.1c2.2,3.9,3,8.1,2.7,12.3c-0.2,2.1-0.6,4.2-1.5,6.2c-0.4,1-1,2-1.6,3c-0.7,0.9-1.4,1.8-2.4,2.7
						c-1.8,1.6-4.1,3-6.9,3.8c-2.7,0.7-6,0.9-9.2-0.3c3.1,1.4,6.5,1.6,9.4,1.1c3-0.5,5.6-1.7,7.8-3.3c1.1-0.8,2.1-1.7,3-2.6
						c0.9-1,1.7-2,2.3-3.1c1.4-2.1,2.2-4.5,2.9-6.9c1.1-4.8,1-10-0.9-15.4c-1-2.7-2.4-5.4-4.7-8C285.7,99.6,282.5,97.2,278.5,96z"
                    />
                  </g>
                  <g>
                    <path
                      d="M315.1,122.6c2.7-2.4,4.1-5.8,4.2-8.5c0.1-2.8-0.9-4.8-2.6-5.3c-1.7-0.5-3.6,0.5-5.2,2.2c-1.6,1.7-2.9,3.9-3.7,6.8
						c-0.8,2.9-1.5,5-2.5,7.1c-1,2-2.1,4-4.5,6.4c3-1.5,5.1-2.6,7.3-3.8C310.2,126.3,312.4,125.1,315.1,122.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M366,89.8c2.5-1.7,4.3-3.8,5.4-5.9c1.1-2.1,1.5-4.2,0.4-5.7c-1-1.4-3.3-1.7-5.9-0.7c-2.6,1.1-5.4,3.6-6.8,6.9
						c-1.5,3.5-2,6-2.5,8.4c-0.4,2.5-0.8,4.9-1.4,8.3c1.5-3.1,3.1-4.9,4.7-6.6C361.7,93,363.5,91.6,366,89.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M379.9,95.6c3-0.8,5.4-2.4,6.9-4.3c1.5-1.8,2.2-3.9,1.4-5.5c-0.8-1.6-2.9-2.2-5.4-1.9c-2.5,0.3-5.4,1.7-7.6,4.1
						c-2.3,2.5-3.6,4.4-5,6.2c-1.4,1.8-2.8,3.6-5.1,6c2.9-1.5,5.1-2.3,7.2-2.9C374.6,96.9,376.8,96.4,379.9,95.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M390.9,105.2c3.1,0.2,5.9-0.4,8-1.5c2.1-1.1,3.7-2.6,3.6-4.4c0-1.7-1.7-3.3-4.4-4c-2.6-0.7-6.2-0.4-9.3,1.3
						c-3.2,1.7-5.2,3.3-7.1,4.7c-1.9,1.5-3.8,3-6.6,5c3.3-1.1,5.6-1.4,7.9-1.5C385.4,104.9,387.7,105,390.9,105.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M360.3,116.8c2.7-0.8,4.8-2.3,6.1-4c1.3-1.7,2-3.7,1.2-5.3c-0.7-1.6-2.7-2.4-5.1-2.1c-2.4,0.3-5.2,1.7-7.2,4.2
						c-2,2.5-3.1,4.5-4.2,6.4c-1.1,1.9-2.2,3.8-3.9,6.3c2.5-1.8,4.4-2.7,6.3-3.5C355.5,118.1,357.5,117.6,360.3,116.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M373.4,121.3c2.6,0.1,4.9-0.6,6.6-1.8c1.7-1.2,2.8-2.9,2.6-4.6c-0.2-1.7-1.8-3.1-4.1-3.6c-2.3-0.5-5.2,0-7.7,1.7
						c-2.5,1.8-4.1,3.3-5.6,4.7c-1.5,1.5-3,2.9-5.3,4.7c2.8-0.9,4.8-1.1,6.7-1.2C368.7,121,370.7,121.2,373.4,121.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M293.2,147.9c-6.4,0.5-11.3,5.2-10.1,8.5c1.2,3.3,8,3.6,13.2-0.3c5.3-4,7-6.2,12.8-9.5
						C302.5,148,299.8,147.4,293.2,147.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M300.9,162.8c-1.7,2.4-2,5.5-1.2,7.6c0.7,2.2,2.4,3.5,4.1,3.4c1.8-0.1,3.2-1.3,4.2-2.8c1-1.5,1.5-3.4,1.3-5.6
						c-0.2-2.3-0.3-4.1-0.2-5.9c0.2-1.8,0.5-3.6,1.8-5.9c-2.1,1.6-3.6,2.8-5.1,4.1C304.2,159,302.7,160.4,300.9,162.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M250.7,158.6c5.2-0.2,10.2-0.8,14.1-1.6c3.9-0.8,6.7-2,7-3.7c0.2-1.7-2.4-3.7-6.9-4.6c-4.4-1-10.5-0.7-16.1,1.4
						c-5.7,2.2-9.2,4.4-12.7,6.7c-3.4,2.3-6.7,4.7-11.2,8.5c5.1-2.9,9-4.2,12.8-5.1C241.5,159.3,245.3,158.8,250.7,158.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M452.7,61.6c-0.5-3.2-1.3-6.4-2.5-9.5c-1.2-3.1-2.9-6.2-5.1-8.9c-2.2-2.7-5-5-8.1-6.7c-3.1-1.7-6.7-2.7-10.3-2.8
						l-1.4,0l-1.4,0.1c-0.9,0.1-1.8,0.3-2.7,0.5c-1.8,0.5-3.4,1.1-4.9,2c-3,1.7-5.6,4.2-7.3,7.3c-1.7,3-2.4,6.8-1.9,10
						c0.2,1.6,0.7,3.2,1.3,4.6c0.6,1.4,1.4,2.7,2.2,3.8c3.4,4.5,8.5,7,13.1,7.2c2.3,0.1,4.6-0.3,6.5-1.3c1-0.5,1.8-1.1,2.5-1.8
						c0.7-0.7,1.2-1.5,1.7-2.3c1.5-3.2,1-6,0-7.5c-0.5-0.8-1-1.2-1.4-1.4c-0.4-0.2-0.7-0.2-0.9,0c-0.3,0.3-0.1,1.1-0.1,2.2
						c0,0.6-0.1,1.2-0.4,1.9c-0.3,0.7-0.7,1.5-1.3,2.1c-0.3,0.3-0.7,0.6-1,0.8c-0.3,0.2-0.8,0.4-1.3,0.5c-1,0.2-2.2,0.2-3.5-0.1
						c-2.6-0.6-5.4-2.4-7.1-5.2c-0.4-0.7-0.8-1.4-1-2.2c-0.2-0.8-0.4-1.5-0.4-2.3c-0.1-0.8,0-1.5,0.2-2.2c0.2-0.7,0.5-1.4,0.9-2.1
						c0.8-1.4,2.2-2.6,3.9-3.5c0.8-0.4,1.7-0.8,2.6-1c0.4-0.1,0.9-0.2,1.4-0.2l0.7,0l0.7,0c1.9,0.1,4,0.6,5.9,1.7
						c1.9,1,3.8,2.5,5.3,4.2c3.1,3.5,5.2,8.4,6.4,13.7c1.2,5.5,1.6,10.9,1.4,15.9c-0.2,5-0.9,9.7-1.9,14.1
						c-2.1,8.8-5.4,16.4-9.6,23.5c-4.2,7.1-9.5,13.8-16.3,20.4c-6.8,6.6-15.2,13.2-26.1,19.6c11.2-6,20-12.1,27.3-18.3
						c7.3-6.2,13.3-12.6,18.3-19.6c5-7,9.2-14.7,12.3-23.9c1.5-4.6,2.7-9.6,3.4-15.1C453.5,74.1,453.6,68.1,452.7,61.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M420.9,70.1c-1.7-0.9-3.7-1.5-5.7-1.6c-2.1-0.2-4.2,0.2-6.2,0.9c-1.9,0.7-3.7,1.8-5.2,3.1c-1.5,1.4-2.7,3-3.5,5
						c-0.8,1.9-1.2,4.1-1.1,6c0.1,2,0.6,3.8,1.4,5.5c1.6,3.3,4.3,5.8,7.4,6.9c1.6,0.6,3.2,0.8,4.6,0.6c1.4-0.1,2.7-0.5,3.8-0.9
						c2.2-1,3.7-2.4,4.6-3.8c0.9-1.4,1.2-2.7,1.1-3.6c-0.1-0.9-0.4-1.4-0.8-1.5c-0.9-0.2-1.9,0.9-3.8,1.6c-0.9,0.4-2,0.7-3.2,0.8
						c-1.2,0-2.4-0.2-3.2-0.9c-0.9-0.6-1.7-1.7-2.1-3c-0.2-0.6-0.3-1.3-0.3-2c0-0.6,0.2-1.2,0.4-1.8c0.5-1.2,1.7-2.4,3.2-3
						c0.7-0.3,1.5-0.5,2.2-0.6c0.8,0,1.7,0.1,2.6,0.3c1.9,0.6,3.8,1.9,5.4,3.4c0.8,0.8,1.5,1.6,2.2,2.4c0.7,0.8,1.3,1.7,1.9,2.6
						c2.3,3.5,3.9,7.1,5,10.9c1.1,3.8,1.7,7.8,1.5,12.4c0,0.6-0.1,1.1-0.1,1.7c0,0.6-0.1,1.2-0.2,1.8c-0.1,1.2-0.4,2.4-0.6,3.7
						c-0.6,2.5-1.4,5.2-2.7,8c1.5-2.7,2.7-5.3,3.5-7.8c0.4-1.3,0.8-2.5,1.1-3.7c0.1-0.6,0.3-1.2,0.4-1.8c0.1-0.6,0.2-1.2,0.3-1.8
						c0.8-4.7,0.9-9,0.5-13.3c-0.5-4.3-1.4-8.6-3.3-13.1c-0.5-1.1-1-2.3-1.7-3.4c-0.6-1.2-1.4-2.4-2.2-3.5
						C426.7,74.4,424.4,72,420.9,70.1z"
                    />
                  </g>
                  <g>
                    <g>
                      <path
                        d="M503.8,68.5c5.7-0.7,11.4-1.1,16.9-1.7c5.5-0.5,10.7-1.1,15.1-2c4.5-0.8,8.2-1.9,10.7-2.9c2.5-1.1,3.9-2.2,3.7-3
							c-0.2-0.9-1.8-1.4-4.4-1.8c-2.6-0.4-6.3-0.5-10.8-0.6c-4.4-0.1-9.6,0-15.3,0.4c-5.6,0.4-11.8,1.3-17.7,2.9
							c-6.2,1.7-11.2,3.7-15.6,6c-4.3,2.3-8,4.8-11.3,7.8c-0.8,0.7-1.6,1.5-2.4,2.3c-0.8,0.8-1.5,1.6-2.3,2.5
							c-1.5,1.7-2.9,3.6-4.2,5.6c-2.6,4.1-4.9,9.2-5.6,15.5c1.2-6.2,4.1-10.9,7-14.5c1.5-1.8,3.1-3.4,4.7-4.7c0.8-0.7,1.7-1.3,2.5-2
							c0.9-0.6,1.7-1.2,2.6-1.7c3.5-2.2,7.2-3.8,11.4-5.1C493.2,70.2,498,69.2,503.8,68.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M512,85.7c5.6-1.4,11.1-3.6,15.9-6.1c4.8-2.5,9.1-5.5,12.4-8.3c6.7-5.7,9.8-10.9,8.6-12.1c-1.2-1.3-6.2,1.4-13.3,5.2
							c-7.1,3.9-16.5,8.8-26.3,13c-5.1,2.2-9.5,3.7-13.6,4.9c-4.1,1.2-8,2.1-11.9,3.3c-3.9,1.1-7.8,2.4-11.9,4.4
							c-2,1-4.2,2.2-6.2,3.8c-2.1,1.5-4.1,3.5-5.8,5.9c1.9-2.2,4.1-3.9,6.3-5.2c2.2-1.3,4.3-2.2,6.4-2.9c4.2-1.4,8.1-1.9,12-2.3
							c3.9-0.4,7.8-0.5,12.3-1C501.2,87.9,506.2,87.1,512,85.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M519.6,76.1c6.3-1,12.2-3.4,16.2-6c4.1-2.6,6.3-5.3,5.6-6.9c-0.7-1.6-4.1-1.8-8.7-0.9c-4.5,0.9-10.2,2.7-15.7,5.4
							c-5.7,2.7-9.6,4.6-13.6,6.3c-4.1,1.7-8.3,3.3-14.6,4.9c6.6-0.6,11-0.9,15.5-1.2C508.7,77.4,513.2,77.1,519.6,76.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M517.7,72.2c4.9-0.5,9.4-2.3,12.5-4.4c3.1-2.1,4.8-4.4,4.1-6c-0.7-1.6-3.4-2.2-7-1.8c-3.6,0.4-8,1.7-12.2,3.8
							c-4.3,2.3-7.3,3.9-10.4,5.4c-3.1,1.5-6.2,2.9-11.1,4.4c5-0.4,8.5-0.6,12-0.7C509.2,72.8,512.7,72.7,517.7,72.2z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M490.4,213.7c-5.6-1.1-11.2-2.4-16.6-3.5c-5.4-1.2-10.5-2.2-15-2.7c-4.5-0.6-8.4-0.7-11.1-0.5
							c-2.7,0.3-4.3,0.9-4.5,1.8c-0.1,0.9,1.2,1.9,3.6,3c2.4,1.1,5.9,2.4,10.1,3.8c4.2,1.4,9.2,2.9,14.7,4.2
							c5.5,1.3,11.6,2.3,17.8,2.6c6.4,0.2,11.8-0.1,16.7-1c4.8-0.9,9.1-2.1,13.1-4c1-0.4,2-1,3-1.4c1-0.5,2-1.1,2.9-1.7
							c1.9-1.2,3.8-2.5,5.7-4.1c3.7-3.1,7.4-7.3,10-13.1c-3.1,5.6-7.2,9.1-11.1,11.7c-2,1.3-3.9,2.3-5.9,3.1c-1,0.4-2,0.8-2.9,1.1
							c-1,0.3-2,0.6-3,0.8c-3.9,1-8,1.4-12.4,1.4C501.1,215.3,496.2,214.8,490.4,213.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M487.9,194.8c-5.8-0.4-11.6,0.1-17,1c-5.4,1-10.3,2.5-14.3,4.2c-8.1,3.4-12.6,7.4-11.8,8.9c0.8,1.6,6.3,0.6,14.3-0.9
							c8-1.5,18.4-3.4,29-4.4c5.5-0.6,10.2-0.7,14.5-0.6c4.3,0.1,8.3,0.4,12.3,0.5c4,0.1,8.2,0.1,12.7-0.6c2.3-0.4,4.6-0.9,7.1-1.7
							c2.4-0.9,4.9-2.1,7.3-3.9c-2.5,1.6-5.1,2.5-7.5,3.1c-2.4,0.6-4.8,0.8-7,0.8c-4.4,0-8.3-0.6-12.1-1.4c-3.8-0.8-7.6-1.9-12-2.8
							C498.8,196,493.9,195.2,487.9,194.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M477.7,201.6c-6.3-0.9-12.6-0.4-17.3,0.8c-4.7,1.2-7.6,3.1-7.4,4.9c0.2,1.8,3.4,2.9,8,3.5c4.6,0.5,10.6,0.5,16.6-0.4
							c6.2-0.9,10.5-1.5,14.9-1.9c4.4-0.4,8.9-0.6,15.4-0.3c-6.4-1.4-10.8-2.5-15.2-3.5C488.5,203.7,484.2,202.6,477.7,201.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M478.3,205.9c-4.8-1-9.7-0.7-13.3,0.4c-3.6,1-5.9,2.8-5.8,4.5c0.1,1.8,2.6,3.1,6.1,3.9c3.5,0.7,8.1,0.8,12.8,0
							c4.8-0.8,8.1-1.5,11.5-2c3.4-0.5,6.8-0.9,11.9-0.8c-4.9-1.1-8.3-2-11.6-3C486.6,207.9,483.3,206.9,478.3,205.9z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M295.7,319.1c4.1-3.9,8.4-7.8,12.5-11.5c4.1-3.7,7.9-7.3,10.9-10.7c3.1-3.3,5.4-6.4,6.8-8.8c1.4-2.4,1.8-4.1,1.2-4.7
							c-0.6-0.6-2.3-0.1-4.6,1.2c-2.3,1.3-5.4,3.4-9,6c-3.6,2.6-7.7,5.8-12,9.5c-4.2,3.7-8.6,8.1-12.4,13c-3.9,5.1-6.7,9.7-8.9,14.2
							c-2.1,4.4-3.5,8.7-4.4,13c-0.2,1.1-0.4,2.2-0.6,3.3c-0.1,1.1-0.3,2.2-0.3,3.3c-0.2,2.3-0.2,4.6,0,7c0.4,4.8,1.6,10.3,4.8,15.8
							c-2.8-5.7-3.3-11.1-3.1-15.8c0.1-2.3,0.4-4.5,0.9-6.6c0.2-1,0.5-2,0.8-3c0.3-1,0.6-2,1-2.9c1.5-3.8,3.5-7.3,6.1-11
							C288.2,326.9,291.4,323.2,295.7,319.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M312.5,328c3.7-4.5,6.7-9.5,9-14.5c2.3-4.9,4-9.8,4.9-14.1c1.9-8.6,1.3-14.5-0.4-14.8c-1.8-0.3-4.2,4.8-7.5,12.2
							c-3.4,7.4-7.9,16.9-13.3,26.2c-2.7,4.8-5.4,8.7-7.9,12.1c-2.6,3.4-5.1,6.5-7.5,9.7c-2.4,3.2-4.8,6.6-6.9,10.7
							c-1,2.1-2,4.3-2.7,6.8c-0.7,2.5-1.2,5.2-1.1,8.2c0.2-3,0.9-5.6,1.9-7.9c1-2.3,2.2-4.3,3.4-6.1c2.5-3.6,5.3-6.4,8.2-9
							c2.9-2.7,5.9-5.1,9.3-8.2C305.2,336.2,308.7,332.7,312.5,328z"
                      />
                    </g>
                    <g>
                      <path
                        d="M312.9,315.8c4.4-4.6,7.7-10,9.4-14.5c1.7-4.5,1.8-8,0.3-8.8c-1.6-0.9-4.4,1.1-7.5,4.5c-3.1,3.4-6.5,8.3-9.3,13.8
							c-2.9,5.6-4.9,9.4-7.1,13.3c-2.2,3.8-4.6,7.6-8.7,12.7c4.9-4.4,8.3-7.4,11.7-10.3C305,323.4,308.3,320.5,312.9,315.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M309,313.8c3.6-3.4,6.2-7.5,7.4-11c1.2-3.5,1.2-6.4-0.3-7.3c-1.5-0.9-4,0.3-6.7,2.7c-2.6,2.5-5.4,6.1-7.4,10.4
							c-2.1,4.4-3.5,7.5-5,10.5c-1.6,3-3.3,6.1-6.2,10.2c3.8-3.4,6.5-5.6,9.2-7.8C302.6,319.3,305.3,317.2,309,313.8z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M112.7,104.3c4.4,3.7,8.6,7.6,12.7,11.2c4.1,3.6,8.1,7,11.7,9.8c3.6,2.7,6.9,4.7,9.5,5.9c2.5,1.1,4.2,1.4,4.7,0.7
							c0.5-0.7-0.2-2.2-1.7-4.4c-1.5-2.2-3.9-5-6.9-8.3c-3-3.3-6.6-7.1-10.7-10.9c-4.2-3.8-8.9-7.7-14.2-11
							c-5.4-3.3-10.4-5.7-15-7.3c-4.6-1.6-9-2.6-13.4-3c-1.1-0.1-2.2-0.1-3.3-0.2c-1.1,0-2.2,0-3.4,0c-2.3,0.1-4.6,0.3-7,0.8
							c-4.8,0.9-10.1,2.7-15.2,6.4c5.4-3.3,10.7-4.4,15.4-4.7c2.3-0.1,4.6,0,6.7,0.2c1.1,0.1,2.1,0.3,3.1,0.5c1,0.2,2,0.4,3,0.7
							c3.9,1.1,7.6,2.7,11.5,4.9C104.2,97.6,108.2,100.5,112.7,104.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M105.7,121.9c4.9,3.2,10.2,5.7,15.3,7.5c5.2,1.8,10.2,2.9,14.5,3.4c8.7,1,14.6-0.3,14.7-2c0.1-1.8-5.2-3.6-12.9-6.2
							c-7.7-2.6-17.7-6.1-27.5-10.4c-5.1-2.2-9.2-4.4-12.9-6.6c-3.7-2.2-7-4.4-10.5-6.5C83,99,79.4,97,75.1,95.4
							c-2.1-0.8-4.5-1.5-7-2c-2.5-0.5-5.3-0.6-8.3-0.2c3-0.1,5.7,0.3,8.1,1c2.4,0.7,4.5,1.7,6.5,2.7c3.9,2.1,6.9,4.6,9.8,7.2
							c2.9,2.6,5.7,5.4,9.1,8.4C96.7,115.5,100.6,118.7,105.7,121.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M117.9,121c5,3.9,10.8,6.6,15.4,7.8c4.7,1.2,8.1,1,8.8-0.6c0.7-1.6-1.5-4.2-5.3-7c-3.7-2.7-9-5.6-14.7-7.8
							c-5.8-2.3-9.9-3.9-13.9-5.7c-4-1.8-8-3.8-13.6-7.4c4.9,4.4,8.2,7.5,11.5,10.5C109.5,113.9,112.7,117,117.9,121z"
                      />
                    </g>
                    <g>
                      <path
                        d="M119.5,117c3.7,3.2,8.1,5.3,11.7,6.2c3.6,0.9,6.5,0.5,7.2-1.1c0.8-1.6-0.7-4-3.4-6.4c-2.7-2.4-6.7-4.7-11.1-6.3
							c-4.6-1.6-7.8-2.7-11-3.9c-3.2-1.2-6.4-2.6-10.8-5.1c3.8,3.4,6.2,5.8,8.7,8.3C113.2,111.2,115.6,113.7,119.5,117z"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M46.9,120.2c-4.8-3.3-10.4-5.5-16.3-6.1c-2.9-0.3-5.9-0.2-8.8,0.3c-2.9,0.5-5.8,1.4-8.4,2.9c-2.6,1.5-5.1,3.5-6.9,6.1
						c-0.5,0.7-0.9,1.3-1.2,2c-0.3,0.7-0.7,1.5-0.9,2.2L4,128.7l-0.2,1.1c-0.2,0.8-0.2,1.5-0.3,2.3c-0.1,3,0.5,5.8,1.6,8.3
						c1.1,2.5,2.6,4.6,4.5,6.3c1.8,1.7,4,3,6.2,3.6c2.1,0.7,4.2,1,6.1,0.9c1.9-0.1,3.6-0.4,5.2-0.9c1.5-0.5,2.8-1.1,3.9-1.8
						c2.2-1.3,3.6-2.9,4.3-4c0.7-1.2,0.8-2,0.5-2.2c-0.3-0.3-1.1-0.1-2.2,0.3c-1.1,0.4-2.6,1.1-4.6,1.5c-1,0.2-2,0.4-3.2,0.5
						c-1.1,0.1-2.4,0-3.6-0.2c-1.2-0.2-2.5-0.6-3.6-1.2c-1.2-0.6-2.1-1.4-3-2.4c-1.8-2.1-2.9-5.2-2.6-8.1c0.1-0.3,0.1-0.7,0.2-1
						l0.1-0.5l0.2-0.5c0.1-0.3,0.3-0.6,0.4-0.9c0.2-0.3,0.4-0.6,0.6-0.9c0.9-1.2,2.2-2.2,3.7-3.1c1.6-0.9,3.4-1.5,5.4-1.8
						c2-0.4,4.1-0.5,6.2-0.3c4.2,0.3,8.6,1.6,12.4,3.9c4,2.3,7.2,5.4,9.7,8.7c2.5,3.3,4.3,6.9,5.7,10.4c2.6,7.1,3.3,14.2,2.9,21.3
						c-0.4,7.1-1.8,14.3-4.7,22c-3,7.6-7.4,15.8-14.9,23.8c7.8-7.7,12.8-15.6,16.5-23.2c3.6-7.6,5.7-14.8,6.9-22.3
						c0.6-3.7,0.9-7.5,0.8-11.4c-0.1-3.9-0.5-8-1.6-12.1c-1.1-4.2-2.8-8.5-5.4-12.7C55.4,127.8,51.9,123.6,46.9,120.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M34,153.7c-3,0.6-5.5,2.1-7.5,4c-2,1.9-3.5,4.3-4.3,7c-0.4,1.3-0.6,2.7-0.6,4.1c0,1.4,0.2,2.8,0.7,4.1
						c0.8,2.5,2.4,4.7,4.4,6.1c1,0.7,2,1.2,3.1,1.5c1.1,0.3,2.1,0.4,3,0.4c1.9-0.1,3.4-0.7,4.4-1.5c1-0.8,1.6-1.6,1.8-2.3
						c0.2-0.7,0-1.2-0.3-1.5c-0.7-0.6-1.9-0.4-3.2-0.7c-0.7-0.1-1.4-0.4-2-0.7c-0.3-0.2-0.6-0.4-0.8-0.6c-0.2-0.2-0.4-0.5-0.6-0.8
						c-0.4-0.6-0.7-1.4-0.8-2.3c-0.1-0.4-0.1-0.9,0-1.3c0-0.5,0.2-1,0.3-1.5c0.6-2.1,2.6-4.5,4.7-5.5c1.1-0.5,2.1-0.7,3.3-0.5
						c1.2,0.2,2.3,0.8,3.3,1.6c1,0.8,1.8,1.7,2.5,2.8c0.7,1.1,1.2,2.3,1.6,3.6c0.8,2.6,1,5.5,0.9,8.8c-0.1,3.3-0.7,7-0.7,11.4
						c0.6-4.4,1.7-7.9,2.4-11.2c0.8-3.3,1.3-6.4,1.2-9.6c-0.1-1.6-0.3-3.3-0.8-4.9c-0.5-1.7-1.3-3.4-2.5-5c-1.2-1.6-2.8-3.2-5.1-4.3
						c-1.1-0.6-2.4-1-3.8-1.2C37.1,153.3,35.5,153.4,34,153.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M80.9,129c2-0.8,3.3-2.1,4-3.7c0.7-1.6,0.9-3.4,0.1-5c-0.8-1.5-2.7-2.3-4.8-1.9c-2.1,0.4-4.4,2-5.6,4.5
						c-1.2,2.6-1.6,4.5-1.9,6.4c-0.3,1.9-0.7,3.6-1.1,6.1c1.5-2,2.9-3.1,4.3-4C77.3,130.6,78.8,129.9,80.9,129z"
                    />
                  </g>
                  <g>
                    <path
                      d="M87.6,140.1c1.9,0,3.2-0.8,4.1-2.1c0.9-1.2,1.4-2.9,1-4.7c-0.4-1.7-1.8-3-3.7-3.3c-1.9-0.3-4.2,0.4-5.7,2.3
						c-1.5,2-2.3,3.5-3,5c-0.7,1.5-1.3,2.9-2.3,4.8c1.8-1.2,3.2-1.6,4.7-1.9C84.1,140.2,85.6,140.1,87.6,140.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M69.7,113.9c0.7-3.1,0.1-6.2-1.2-8.3c-1.3-2.1-3.2-3.1-4.9-2.7c-1.7,0.5-2.8,2.2-3.3,4.3c-0.5,2.1-0.3,4.6,0.7,7.2
						c1.1,2.6,1.8,4.6,2.5,6.6c0.6,2,1,4.1,1,7.1c1.1-2.9,1.9-4.9,2.8-6.9C68.1,119.2,69,117.1,69.7,113.9z"
                    />
                  </g>
                  <g>
                    <path d="M51.6,99.8c-0.9-5.8-6-9.3-9.1-7.5c-3,1.8-2.7,7.7,1.3,11.5c4.1,4,6.3,5.3,9.7,10.1C52.1,108.1,52.6,105.7,51.6,99.8z" />
                  </g>
                  <g>
                    <path
                      d="M25.9,96.2c-2.9-2.2-6.5-3.1-9.2-2.9c-2.8,0.2-4.8,1.4-5.1,3.1c-0.3,1.7,0.9,3.6,3,5c2,1.5,4.9,2.6,8.1,2.9
						c3.3,0.4,5.7,0.6,8.1,1.1c2.4,0.5,4.7,1.1,7.9,2.7c-2.6-2.5-4.3-4.2-6.1-6C30.7,100.2,28.9,98.4,25.9,96.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-1.6,103.7c-2.7-0.3-5.2,0.9-6.5,2.5c-1.4,1.7-1.5,3.6-0.6,5.1c1,1.5,2.6,2.2,4.2,2.4c1.6,0.2,3.2-0.2,4.8-1.5
						c1.6-1.3,3-2.3,4.4-3c1.4-0.8,3-1.3,5.4-1.4c-2.1-0.9-3.7-1.6-5.5-2.4C3,104.7,1.2,104-1.6,103.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-13.1,124.3c-2.5,1.7-3.7,4.6-3.5,6.9c0.2,2.4,1.6,3.9,3.3,4.1c1.8,0.2,3.4-0.6,4.6-1.5c1.3-1,2.1-2.2,2.5-4.1
						c0.4-2,0.9-3.5,1.7-5c0.8-1.4,1.9-2.8,4.1-4c-2.5,0.2-4.3,0.5-6.2,0.9C-8.6,122.1-10.6,122.6-13.1,124.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-9.6,149.9c-0.9,2.6-0.3,5.4,1.1,7.1c1.4,1.7,3.3,2.2,4.9,1.5c1.6-0.7,2.7-2.2,3.2-3.7c0.5-1.5,0.5-3.1-0.4-4.8
						c-0.9-1.8-1.5-3.3-1.9-4.9c-0.3-1.6-0.5-3.2,0.1-5.5c-1.6,1.8-2.7,3.2-3.8,4.6C-7.5,145.6-8.6,147.2-9.6,149.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M3.1,168.8c0.4,2.9,2.4,5.2,4.4,6.2c2.1,1,4.1,0.7,5.2-0.6c1.2-1.3,1.4-3.2,1.1-4.9c-0.3-1.7-1.2-3.3-2.9-4.7
						c-1.7-1.4-3-2.6-4.1-3.9c-1.1-1.3-2-2.9-2.7-5.4c-0.4,2.6-0.7,4.4-1,6.4C2.9,163.8,2.7,165.8,3.1,168.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M75.4,229.7c2.3,1.4,4,3.2,4.8,5.2c0.9,2,0.9,4.3,0.3,5.8c-0.6,1.5-2,2.8-3.6,3.4c-0.8,0.3-1.5,0.4-2.3,0.4
						c-0.8,0-1.6-0.2-2.3-0.6c-1.5-0.6-2.7-1.8-3.4-2.9c-0.7-1.1-0.9-2.1-0.8-2.9c0.1-0.8,0.6-1.6,1.1-2c0.4-0.5,0.9-0.8,1-1.2
						c0.1-0.4-0.3-1-1.5-1.3c-1.1-0.2-3,0.1-4.7,1.8c-0.4,0.4-0.8,1-1.1,1.6c-0.3,0.6-0.5,1.2-0.7,1.9c-0.3,1.4-0.3,2.8,0.1,4.3
						c0.7,2.9,2.5,5.8,5.5,7.9c1.5,1,3.3,1.8,5.3,2.3c2,0.4,4.2,0.4,6.3-0.2c2.1-0.5,4.1-1.5,5.9-3c1.8-1.4,3.3-3.4,4.2-5.7
						c0.9-2.3,1.3-4.7,1.1-7c-0.1-2.3-0.8-4.6-1.8-6.7c-1-2.1-2.5-4-4.2-5.5c-1.7-1.5-3.6-2.6-5.6-3.4c-4.1-1.7-8.1-2-11.5-1.8
						c-3.5,0.2-6.5,0.9-9.3,1.7c-5.5,1.7-10.2,3.7-14.6,6.1c-4.5,2.5-8.8,5.2-13,9c-2.1,1.9-4.3,4-6.3,6.4c-2.1,2.5-4.1,5.2-5.8,8.5
						c2-3.1,4.2-5.7,6.4-7.9c2.2-2.2,4.5-4.1,6.8-5.6c4.5-3.2,9-5.2,13.5-6.9c4.5-1.7,9.2-2.8,14.4-3.6c2.6-0.3,5.2-0.5,7.9-0.3
						c1.3,0.1,2.7,0.3,4,0.7C72.9,228.5,74.2,229,75.4,229.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M55.5,215c4.5-2.5,8.1-6.3,10.1-9.7c2-3.4,2.4-6.3,1-7.5c-1.4-1.1-4.1-0.2-7.1,1.7c-3.1,2-6.5,5-9.6,8.6
						c-3.2,3.8-5.4,6.3-7.9,8.8c-2.5,2.5-5.2,4.8-9.6,7.6c4.8-1.8,8.2-3.1,11.7-4.4C47.5,218.9,50.9,217.5,55.5,215z"
                    />
                  </g>
                  <g>
                    <path
                      d="M75.4,216.2c2.3,0.5,4.3,0.1,5.9-0.8c1.6-1,2.7-2.5,2.7-4.3c0-1.8-1.2-3.3-3.1-4.1c-1.9-0.8-4.5-0.8-6.8,0.5
						c-2.4,1.3-3.8,2.5-5.3,3.6c-1.5,1.1-2.9,2.2-5.1,3.5c2.5-0.3,4.3-0.1,6,0.2C71.3,215.2,73,215.7,75.4,216.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M35.3,208.6c0.9-2.4,1.3-5,1.3-7.4c0-0.6,0-1.2-0.1-1.8c-0.1-0.6-0.1-1.1-0.2-1.7c-0.2-1.2-0.6-2.3-1.1-3.4
						c-0.5-1.1-1.1-2-1.9-2.8c-0.7-0.8-1.6-1.3-2.3-1.6c-1.6-0.5-2.6,0-3,0.7c-0.4,0.8-0.3,1.8-0.5,2.6c-0.1,0.9-0.3,1.8-0.4,3
						c-0.1,0.7-0.1,1.4-0.2,2.1c0,0.4-0.1,0.9-0.1,1.3c0,0.5,0,0.9-0.1,1.4c-0.1,1.9-0.1,3.9,0,6c0.1,4.3-0.2,7.5-0.8,10.6
						c-0.7,3.1-1.8,6.3-4.7,10.3c3.7-3.3,5.9-5.9,8.1-8.7c1.1-1.4,2.1-2.8,3.1-4.5C33.4,213,34.4,211.1,35.3,208.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M124.7,53.8c3.3-0.9,6.7-1.7,9-1.4c1.2,0.1,2,0.4,2.7,0.9c0.6,0.4,1.2,1.1,1.6,2c0.9,1.8,1,4.3,0.5,6.2
						c-0.1,0.5-0.3,1-0.5,1.5c-0.2,0.5-0.5,0.9-0.7,1.4c-0.5,0.8-1.2,1.5-1.8,2.1c-1.2,1-2.9,1.5-4.2,1.4c-0.7,0-1.3-0.2-1.8-0.4
						c-0.5-0.3-1-0.6-1.4-1c-1.7-1.6-1.8-3.6-2.6-3.9c-0.4-0.1-1,0.2-1.5,1.2c-0.5,1-0.8,2.8-0.1,4.9c0.3,1,0.9,2.2,1.9,3.2
						c1,1.1,2.2,2,3.8,2.6c3,1.2,6.9,1.2,10.4-0.7c1.7-0.9,3.3-2.2,4.6-3.7c0.7-0.8,1.2-1.6,1.8-2.5c0.5-0.9,1-1.8,1.4-2.9
						c0.8-2.1,1.1-4.3,1.1-6.5c-0.1-2.3-0.5-4.6-1.5-6.9c-1.1-2.3-2.8-4.5-5.2-6c-2.4-1.5-5.1-2.2-7.6-2.3c-2.5-0.1-4.7,0.3-6.8,0.9
						c-2.1,0.6-3.9,1.2-5.7,2c-3.8,1.6-7.6,3.8-10.3,7c-1.3,1.6-2.3,3.5-2.9,5.3c-0.6,1.8-0.9,3.6-0.9,5.3c-0.1,3.4,0.5,6.4,1.3,9.2
						c0.9,2.8,2,5.4,3.4,7.8c2.8,4.8,6.6,9.1,11.7,12.4c2.6,1.7,5.5,3.1,8.7,4.2c3.3,1.1,6.9,1.7,10.9,1.8
						c-3.9-0.3-7.5-1.3-10.6-2.6c-3.1-1.3-5.7-3-8-4.8c-4.5-3.7-7.5-8.1-9.4-12.7c-1-2.3-1.6-4.7-2-7.2c-0.4-2.5-0.5-5,0-7.5
						c0.2-1.2,0.6-2.4,1.1-3.4c0.5-1,1.2-2,2.1-2.8C118.7,56,121.3,54.8,124.7,53.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M86,72c-3-1.1-6.3-0.6-8.4,0.7c-2.1,1.3-3.1,3.2-2.6,4.9c0.5,1.7,2.2,2.8,4,3.3c1.9,0.6,4,0.6,6.4-0.2
						c2.5-0.8,4.3-1.2,6.3-1.4c1.9-0.2,4-0.2,6.8,0.7c-2.3-1.8-4-3.1-5.7-4.3C90.9,74.4,89.1,73.1,86,72z"
                    />
                  </g>
                  <g>
                    <path
                      d="M99.5,62.1c0.8-1.3,0.8-2.3,0.3-3.6c-0.5-1.2-1.3-2.8-2.7-3.8c-0.7-0.5-1.6-0.7-2.5-0.6c-0.9,0.1-1.8,0.5-2.6,1.3
						c-0.8,0.8-1.4,1.9-1.7,3.2c-0.3,1.3-0.2,2.8,0.4,4.1c1.2,2.8,2.7,4.1,4.1,5.3c1.4,1.1,2.9,2,4.9,2.9c-1.3-1.8-1.6-3.3-1.5-4.7
						C98.2,64.8,98.7,63.5,99.5,62.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M152.6,18.3c3.1-2.4,6-4.9,8.2-6.9c2.2-2,3.6-3.6,3.1-4.4c-0.4-0.8-2.6-0.5-5.5,1c-2.8,1.4-6.3,4-8.9,7.3
						c-2.7,3.4-4.2,5.9-5.6,8.5c-1.4,2.6-2.7,5.2-4.4,9.2c2.2-3.7,4-6,6-8.1C147.4,22.8,149.4,20.8,152.6,18.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M161.5,23.3c3.2-1.8,6.3-3.8,8.5-5.5c2.2-1.7,3.6-3.2,3.2-4c-0.4-0.8-2.5-0.7-5.2,0.3c-2.8,1-6.2,3-9.1,5.6
						c-2.9,2.7-4.7,4.8-6.5,6.9c-1.7,2.1-3.5,4.3-5.9,7.5c2.8-2.9,5-4.6,7.2-6.1C155.9,26.6,158.1,25.2,161.5,23.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M160,33.4c2.1-0.6,3.9-1.6,5.2-2.7c1.3-1,2-2.1,1.7-3c-0.3-0.8-1.6-1.2-3.4-0.9c-1.7,0.3-3.9,1.2-5.7,2.7
						c-1.8,1.6-2.9,2.8-4,4c-1.1,1.2-2.2,2.4-3.9,4.1c2-1.3,3.5-1.9,4.9-2.5C156.3,34.6,157.8,34.1,160,33.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M207.1,151.4c1.6-3.8,3.4-7.5,4.8-10.2c1.4-2.8,2.4-4.8,1.8-5.4c-0.6-0.6-2.8,0.4-5.2,3c-2.4,2.6-4.7,6.8-5.6,11.4
						c-0.8,4.7-0.7,8-0.3,11.2c0.4,3.2,1,6.4,2.6,10.8c-1.1-4.5-1.1-7.7-0.7-10.8C204.8,158.3,205.5,155.3,207.1,151.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M186.8,193.3c-0.7,3.1-1.2,5.4-1.8,8c-0.5,2.2-0.7,3.9,0,4.4c0.7,0.5,2.3-0.5,3.8-2.8c1.4-2.2,2.6-5.8,2.3-9.3
						c-0.2-3.6-1-6-1.8-8.2c-0.9-2.2-1.9-4.4-3.8-7.3c1.5,3.1,1.8,5.5,2,7.7C187.6,188,187.5,190.3,186.8,193.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M166,263.2c-0.7,3.5-2,6.9-3.1,9.4c-1.1,2.5-2,4.4-1.3,5c0.6,0.6,2.7-0.2,4.9-2.8c1.1-1.3,2.1-2.9,2.8-4.9
						c0.7-1.9,1.2-4.2,1.1-6.4c0-2.3-0.4-4.1-0.9-5.8c-0.5-1.6-1-3-1.7-4.3c-1.4-2.6-3-5.2-6.2-8.1c2.8,3.3,3.8,6,4.4,8.7
						c0.3,1.3,0.4,2.7,0.5,4.2C166.5,259.8,166.4,261.4,166,263.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M249.5,317.1c1.2-0.7,2.4-1.2,3.5-1.4c0.6-0.1,1.1-0.1,1.6-0.1c0.5,0,1,0.2,1.5,0.3c2,0.7,4,2.6,5.2,4.8
						c0.3,0.5,0.5,1.1,0.8,1.7c0.2,0.6,0.4,1.2,0.5,1.8c0.2,1.2,0.3,2.3,0.2,3.3c-0.2,1.9-1.3,3.4-2.5,4.1c-0.1,0.1-0.3,0.2-0.4,0.2
						c-0.1,0.1-0.3,0.1-0.4,0.2c-0.3,0.1-0.6,0.1-0.9,0.2c-0.7,0-1.3-0.1-1.8-0.4c-0.5-0.2-0.9-0.6-1.2-0.9
						c-0.3-0.4-0.5-0.9-0.5-1.4c-0.1-1.1,0.1-2,0.4-2.8c0.3-0.7,0.6-1.3,0.4-1.7c-0.1-0.4-0.8-0.7-2-0.3c-1.1,0.4-2.7,1.6-3.5,3.8
						c-0.4,1.1-0.6,2.5-0.3,4.1c0.3,1.6,1.1,3.2,2.4,4.5c1.3,1.3,3,2.2,4.9,2.7c1,0.2,2,0.3,3.1,0.3c0.5,0,1.1-0.1,1.7-0.2
						c0.5-0.1,1.1-0.3,1.6-0.4c4.2-1.5,7.4-5.3,8.5-10c0.5-2.3,0.6-4.6,0.2-6.8c-0.2-1.1-0.4-2.2-0.8-3.3c-0.4-1.1-0.8-2.2-1.4-3.2
						c-1.1-2.1-2.5-4-4.4-5.6c-1.8-1.6-4.1-3-6.7-3.8c-2.6-0.8-5.5-0.8-8.1-0.1c-2.6,0.7-4.8,2-6.7,3.5c-3.8,3.3-5.9,7.3-7.1,11
						c-1.1,3.8-1.4,7.4-1.1,10.7c0.2,3.3,1,6.4,1.9,9.1c1,2.8,2.1,5.4,3.5,7.8c1.3,2.5,2.7,4.9,4.3,7.3c1.5,2.4,3.1,4.8,4.9,7.4
						c3.6,5,7.8,10.5,13.5,16.3c-5.3-6.2-9-11.9-12.1-17.2c-1.5-2.7-2.8-5.2-4-7.8c-1.2-2.5-2.3-5-3.2-7.5c-0.9-2.5-1.7-5-2.2-7.6
						c-0.5-2.6-0.7-5.2-0.6-7.9c0.2-2.7,0.8-5.5,2-8.1c0.6-1.3,1.3-2.5,2.2-3.7C247.3,318.8,248.3,317.8,249.5,317.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M264.6,361c0.7-2.6,1-4.8,0.9-6.9c-0.1-2.1-0.6-4.1-2-5.2c-0.7-0.5-1.7-0.6-2.7-0.3c-1,0.3-2.1,1.1-3.1,2.3
						c-1,1.2-1.7,2.8-2.1,4.7c-0.4,1.9-0.3,3.9,0.3,5.9c0.6,2,1.4,3.6,2.2,4.9c0.8,1.3,1.7,2.3,2.6,3.3c1.8,1.9,3.7,3.6,6.7,5.5
						c-2.3-2.7-3-5-3.3-7.2C263.8,365.9,263.9,363.7,264.6,361z"
                    />
                  </g>
                  <g>
                    <path
                      d="M284.7,370c-3.2,6-5.2,12.1-7.6,17c-1.2,2.5-2.4,4.7-3.7,6.6c-0.7,0.9-1.4,1.8-2.1,2.6c-0.7,0.8-1.4,1.4-2.1,2
						c-1.4,1.1-2.5,1.5-3.5,1.7c-0.3,0.1-0.8,0-1.2-0.1c-0.5-0.2-0.9-0.4-1.2-0.7c-0.7-0.5-1.1-1.1-1.5-1.7
						c-0.3-0.6-0.5-1.1-0.9-1.3c-0.2-0.1-0.4-0.1-0.7,0c-0.3,0.1-0.6,0.4-0.9,0.8c-0.6,0.9-1,2.7-0.3,4.6c0.3,1,0.9,1.9,1.8,2.8
						c0.9,0.9,2.1,1.8,3.7,2.2c1.4,0.4,3,0.5,4.6,0.3c1.6-0.2,3.1-0.7,4.5-1.4c1.4-0.7,2.7-1.6,3.9-2.6c1.2-1,2.3-2.1,3.4-3.3
						c2.1-2.4,3.9-5.2,5.3-8.2c2.9-5.9,4.6-12.1,6.6-17.7c1.1-2.9,2.2-5.3,3.6-7.5c1.4-2.2,2.8-4.2,4.4-5.9c0.7-0.9,1.6-1.7,2.4-2.5
						c0.9-0.7,1.7-1.5,2.5-2.2c0.4-0.3,0.9-0.7,1.3-1c0.4-0.3,0.9-0.6,1.3-0.9c0.9-0.6,1.8-1.2,2.8-1.7c3.8-2.2,8-3.8,13-4.7
						c1.2-0.2,2.5-0.4,3.9-0.4c1.3-0.1,2.7-0.1,4.1,0.1c2.8,0.3,5.9,1.2,8.4,3.6c-2.3-2.6-5.4-3.8-8.3-4.4c-1.5-0.3-2.9-0.5-4.3-0.6
						c-1.4-0.1-2.7,0-4.1,0c-5.2,0.3-9.9,1.3-14.4,3c-1.1,0.4-2.2,0.9-3.3,1.4c-0.5,0.3-1.1,0.5-1.6,0.8c-0.5,0.3-1.1,0.6-1.6,0.9
						c-1.1,0.6-2.1,1.3-3.2,2c-1,0.8-2.1,1.5-3.2,2.3c-2.1,1.7-4.2,3.7-6.2,6C288.2,364.1,286.3,366.9,284.7,370z"
                    />
                  </g>
                  <g>
                    <path
                      d="M311.3,353.5c-2.3,1-4.3,2.4-5.9,4.2c-1.6,1.7-2.9,3.8-3.4,5.9c-0.6,2.1-0.3,4.2,0.4,5.5c0.7,1.3,1.7,1.7,2.5,1.5
						c0.9-0.2,1.6-0.8,2.3-1.4c0.7-0.6,1.5-1.2,2.3-1.9c0.9-0.7,1.8-1.6,3-2.7c1.2-1.1,2.4-2.4,3.6-3.7c2.6-2.8,4.9-4.5,7.5-5.8
						c1.3-0.6,2.7-1.3,4.3-1.7c1.6-0.4,3.5-0.7,5.7-0.7c-2.2-0.6-4.1-0.9-5.9-1.1c-1.8-0.2-3.4-0.3-5-0.3c-1.6,0-3.3,0.1-5.1,0.4
						C315.7,352,313.7,352.5,311.3,353.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M333.5,332.9c0.1-2-0.4-3.5-1.3-5c-0.9-1.4-2.2-2.8-3.9-3.1c-0.9-0.2-1.7,0-2.5,0.5c-0.8,0.5-1.5,1.4-1.9,2.6
						c-0.4,1.2-0.6,2.6-0.4,4.2c0.3,1.5,0.9,3.1,2,4.3c2.2,2.6,4.3,3.7,6.2,4.6c1.9,0.9,3.9,1.5,6.5,2.1c-2.2-1.6-3.2-3.2-3.8-4.8
						C333.7,336.7,333.4,335,333.5,332.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M523.9,354.8c0.8-4,0.7-8.3-0.4-12.2c-1-3.9-2.9-7.6-5.4-10.8c-2.5-3.2-5.7-5.8-9.2-7.6c-3.5-1.8-7.3-3-11-3.4
						c-3.8-0.4-7.6-0.2-11.2,0.8c-3.6,0.9-7.2,2.6-10.1,5.5c-1.4,1.4-2.6,3.2-3.4,5c-0.8,1.8-1.3,3.7-1.4,5.5
						c-0.3,3.7,0.5,7.3,2.3,10.3c1.8,3,4.4,5.2,7.1,6.5c2.7,1.3,5.5,1.8,8.1,1.8c2.6-0.1,5-0.8,7-1.8c2-1,3.5-2.4,4.6-3.7
						c2.3-2.7,3.1-5.3,3.4-7.1c0.2-1.8-0.1-2.8-0.5-2.8c-0.5-0.1-1,0.7-1.9,1.9c-0.9,1.2-2.2,3-4.3,4.5c-1,0.7-2.3,1.4-3.7,1.8
						c-1.4,0.4-2.9,0.6-4.5,0.4c-1.6-0.2-3.2-0.7-4.7-1.7c-1.5-0.9-2.7-2.2-3.4-3.7c-0.7-1.5-1-3.5-0.7-5.3c0.1-0.9,0.4-1.8,0.8-2.6
						c0.4-0.8,0.9-1.4,1.6-2.1c2.8-2.6,8.4-3.9,13.5-3.2c5.3,0.6,10.4,3.1,13.9,7.3c3.5,4.2,5.4,9.9,4.7,15.4
						c-0.8,5.7-4.1,10.7-8.1,14.1c-4.1,3.4-9,5.5-13.8,6.4c-4.9,1-9.8,1-14.5,0.4c-4.7-0.6-9.3-1.9-13.8-3.5
						c-4.5-1.6-8.9-3.6-13.5-5.8c-4.5-2.2-9.2-4.6-14.3-6.8c-5.1-2.2-10.8-4.1-17.1-4.8c-3.1-0.3-6.4-0.3-9.8,0.1
						c-1.7,0.3-3.4,0.7-5,1.2c-1.7,0.6-3.3,1.3-4.9,2.2c1.6-0.8,3.3-1.5,5-2c1.7-0.5,3.4-0.8,5-1c3.3-0.3,6.6-0.2,9.6,0.3
						c6.2,0.9,11.5,3.2,16.4,5.6c4.9,2.4,9.4,5.1,13.8,7.7c4.4,2.5,8.8,4.9,13.3,7c4.6,2,9.3,3.8,14.4,4.8
						c5.1,1.1,10.6,1.5,16.4,0.8c5.7-0.7,11.9-2.6,17.5-6.7c2.7-2,5.3-4.5,7.5-7.6C521.4,362.6,523.1,358.9,523.9,354.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M447.8,308.1c-3.2-3.2-7.6-5.5-12.3-6.1c-4.7-0.7-9.8,0.3-13.9,3.4c-2,1.5-3.5,3.4-4.6,5.6c-1.1,2.1-1.7,4.5-1.7,7
						c0,0.6,0.1,1.2,0.1,1.8c0.1,0.6,0.2,1.2,0.4,1.8c0.3,1.2,0.9,2.2,1.4,3.2c1.2,1.9,2.7,3.4,4.5,4.5c1.8,1.1,3.7,1.7,5.7,1.8
						c1.9,0.1,3.9-0.4,5.4-1.3c1.5-0.9,2.6-2,3.3-3.1c0.8-1.1,1.2-2.3,1.5-3.3c0.5-2.1,0-3.9-0.6-4.9c-0.3-0.5-0.6-0.8-0.9-1
						c-0.3-0.2-0.5-0.2-0.7-0.1c-0.2,0.1-0.4,0.3-0.5,0.6c-0.1,0.3-0.2,0.6-0.4,1c-0.3,0.8-0.6,1.7-1.4,2.6c-0.7,0.9-1.9,1.8-3.1,2
						c-1.1,0.2-2.3-0.1-3.5-1.2c-0.6-0.5-1.1-1.2-1.4-2c-0.1-0.4-0.3-0.7-0.3-1.1c0-0.2,0-0.3,0-0.5c0-0.2,0-0.4,0-0.6
						c0.1-1.6,1.2-3.6,2.9-4.8c1.6-1.2,4-1.9,6.7-1.6c2.7,0.2,5.3,1.3,7.7,3.1c2.4,1.8,4.2,4.3,5.3,6.8c1.1,2.5,1.5,5.1,1.3,7.6
						c-0.3,5-2.7,9.6-6.2,12.9c-3.5,3.3-8.6,5.3-14.4,5.7c-5.9,0.5-12.6-0.9-20.1-3.5c7.3,3.1,13.9,5,20.1,5.2
						c6.2,0.2,12-1.1,16.8-4.5c2.4-1.7,4.5-3.8,6.2-6.3c1.7-2.5,3.1-5.4,3.7-8.8C456.1,323.3,454.5,314.8,447.8,308.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M458.9,328.3c-2.4,2.5-3.5,5.9-3.3,8.5c0.2,2.7,1.6,4.5,3.3,4.7c1.8,0.2,3.4-0.9,4.8-2.5c1.3-1.6,2.3-3.7,2.8-6.3
						c0.5-2.7,1-4.7,1.8-6.7c0.8-1.9,1.8-3.8,4.1-6.1c-2.9,1.3-4.9,2.4-6.9,3.5C463.4,324.6,461.4,325.8,458.9,328.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M463.1,362.7c0.5,3.2,2.7,5.9,5,6.9c2.3,1.1,4.4,0.7,5.5-0.7c1-1.4,1-3.4,0.6-5.1c-0.5-1.8-1.4-3.3-3.1-4.8
						c-1.7-1.6-3-3-3.9-4.5c-1-1.6-1.8-3.4-1.9-6.2c-0.9,2.7-1.4,4.7-1.9,6.8C462.9,357.2,462.6,359.4,463.1,362.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M490.6,369c1.7,0.6,3.5,0.7,5.2,0.5c1.7-0.3,3.1-0.9,4.2-1.7c2.3-1.7,2.9-3.9,2-5.4c-0.9-1.5-2.7-2.2-4.6-2.6
						c-1.9-0.3-3.8-0.2-6.1,0.5c-2.3,0.7-4.3,0.9-6.3,0.8c-2-0.2-4-0.7-6.5-2.5c1.7,2.6,3.2,4.3,4.9,5.9
						C485.2,366,487.1,367.7,490.6,369z"
                    />
                  </g>
                  <g>
                    <path
                      d="M448.5,349.2c-1.6-0.6-2.7-0.5-4,0.1c-1.3,0.6-2.7,1.6-3.5,3.2c-0.4,0.8-0.5,1.7-0.2,2.5c0.2,0.9,0.8,1.7,1.7,2.4
						c0.9,0.7,2.1,1.2,3.5,1.3c1.3,0.1,2.8-0.1,4.1-0.8c2.7-1.5,3.9-3.1,5-4.5c1.1-1.5,1.9-3.1,2.9-5.2c-1.8,1.5-3.4,1.9-4.9,1.9
						C451.6,350.1,450.2,349.9,448.5,349.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M441,389.9c-1.2,1.3-3,2.2-4.8,2.4c-1.7,0.3-3.6,0-5.4-0.8c-1.8-0.7-3.5-2-4.7-3.3c-1.2-1.3-1.9-2.6-2-3.5
						c-0.2-1,0.1-2.1,0.4-2.7c0.3-0.6,0.9-1,1.7-1.1c0.8-0.1,1.5,0,2.1,0.2c0.6,0.2,1.1,0.4,1.5,0.3c0.4-0.1,0.8-0.6,0.6-1.7
						c-0.2-1-1-2.4-2.7-3.4c-0.8-0.5-1.9-0.8-3.2-0.9c-1.3-0.1-2.7,0.3-4,1.1c-2.7,1.6-4.5,4.5-4.8,8c-0.3,3.6,1.1,7,3.1,9.7
						c2,2.7,4.7,4.9,8,6.4c3.3,1.5,7.3,2.2,11.4,1.2c3.9-0.9,7.6-3.3,9.9-6.8c0.6-0.9,1.1-1.9,1.4-2.9c0.4-1,0.6-2,0.7-2.9
						c0.3-1.9,0.2-3.7-0.1-5.3c-0.6-3.2-1.9-5.9-3.3-8.1c-1.5-2.2-3.2-4.1-5-5.6c-1.8-1.5-3.7-2.8-5.7-3.8c-4-2.1-8.4-3.3-13.2-3.8
						c-4.9-0.5-10.3-0.3-16.5,0.5c12.6-0.7,21.4,1.7,27.8,6.5c3.2,2.4,5.8,5.4,7.4,9.2c0.8,1.9,1.3,3.9,1.3,5.9
						C442.8,386.8,442.3,388.7,441,389.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M457.3,391.4c0,2.2,1.1,3.9,2.5,4.7c1.5,0.9,3.3,0.9,4.8,0c1.5-0.9,2.4-2.4,2.6-4c0.2-1.5-0.4-3.1-1.9-4.3
						c-1.5-1.2-2.7-2.1-3.9-3c-1.1-0.9-2.1-2-3.1-3.7c0,2-0.2,3.5-0.4,5C457.7,387.6,457.4,389.2,457.3,391.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M431.7,410.6c-2.8,1.3-4.6,3.9-5.2,6.2c-0.6,2.3,0.1,4.3,1.6,5.2c1.5,0.9,3.5,0.6,5.2-0.3c1.7-0.9,3.3-2.4,4.4-4.7
						c1.1-2.3,2-4,3.1-5.6c1.1-1.6,2.4-3.1,4.8-4.7c-2.7,0.6-4.7,1-6.8,1.5C436.6,408.7,434.5,409.2,431.7,410.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M389.6,226.7c1.7,0.3,3.4,0.1,5-0.1c1.5-0.3,2.9-0.8,4-1.3c2.2-1.1,3.4-2.3,3.2-3.1c-0.3-0.9-2-1.1-4.3-0.9
						c-2.3,0.2-5.1,0.7-7.8,1.1c-2.8,0.4-4.9,0.2-6.8-0.5c-1-0.3-1.9-0.8-2.9-1.6c-0.9-0.7-1.9-1.8-2.6-3.3c0.4,1.6,1.1,2.9,1.9,3.9
						c0.8,1,1.7,1.9,2.6,2.6c0.9,0.7,2,1.4,3.2,1.9C386.4,226,387.9,226.5,389.6,226.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M392.5,238.2c1.9,2.5,3.7,4.8,5.3,6.6c1.6,1.7,2.9,2.9,3.7,2.5c0.8-0.3,0.8-2.2-0.1-4.7c-1-2.4-3-5.4-5.8-7.5
						c-2.9-2.2-5-3.2-7.2-4.2c-2.2-1-4.4-1.9-7.8-2.8c3.1,1.5,5,2.9,6.7,4.4C389.1,234,390.6,235.6,392.5,238.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M351.7,217.7c-0.7,2.4-1.3,4.7-1.7,6.6c-0.4,1.9-0.5,3.4,0.2,3.9c0.7,0.5,2.2-0.2,3.6-2.1c1.4-1.8,2.4-4.8,2.3-7.9
						c-0.2-3.1-0.8-5.1-1.4-7.1c-0.7-1.9-1.5-3.8-3.1-6.3c1,2.7,1.2,4.7,1.2,6.6C352.7,213.3,352.5,215.2,351.7,217.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M446.3,284.3c3.7-0.6,7.2-2.4,9.4-4.3c2.2-1.9,3-3.7,2.5-4.4c-0.6-0.7-2.4-0.1-4.7,0.8c-2.3,1-5.3,2.3-8.3,3.6
						c-3.2,1.4-5.5,2-7.9,2.5c-2.4,0.4-5,0.7-8.7,0.1c3.6,1.1,6.2,1.5,8.8,1.8C439.8,284.8,442.4,284.9,446.3,284.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M455.1,296.1c2.5,0.2,5-0.7,6.5-1.9c1.5-1.2,2-2.6,1.4-3.3c-0.6-0.7-1.8-0.7-3.3-0.4c-1.5,0.2-3.2,0.7-5.1,1.3
						c-1.9,0.6-3.4,0.9-4.9,0.9c-1.5,0-3.1-0.1-5.3-1.1c1.9,1.4,3.3,2.3,4.9,3C450.8,295.2,452.5,295.8,455.1,296.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M512.7,278.9c-0.8,1.7-2.2,3.2-3.7,4.4c-1.5,1.2-3.3,2.3-4.8,3.4c-1.5,1.1-2.8,2.1-3.5,3c-0.8,0.9-1,1.6-0.8,2
						c0.3,0.4,1,0.3,2,0.1c1.1-0.2,2.5-0.7,4.2-1.4c1.7-0.7,3.8-1.6,5.9-3.2c2-1.6,4-3.9,4.8-6.9c0.4-1.5,0.4-3,0.2-4.4
						c-0.3-1.3-0.8-2.5-1.4-3.4c-1.2-1.9-2.8-3.1-4.4-4c-3.3-1.8-7-2.2-12.2-2.6c2.6,0.5,4.7,0.9,6.5,1.6c1.8,0.7,3.3,1.5,4.5,2.6
						c1.2,1.1,2.2,2.4,2.8,3.9C513.5,275.5,513.5,277.2,512.7,278.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M421,118.9c2.1-3.8,2.7-8.3,2.3-11.5c-0.4-3.3-1.6-5.2-2.4-5c-0.9,0.2-1.3,2.2-1.8,4.9c-0.5,2.8-1.2,6.3-2.2,9.9
						c-1.1,3.7-2.3,6.2-3.8,8.5c-1.5,2.3-3.4,4.6-6.9,6.9c3.8-1.9,6.2-3.7,8.4-5.7C416.7,125,418.8,122.8,421,118.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M423,162.8c5.4,0.3,10.9,0.4,15,0.3c4.1-0.1,6.9-0.6,7-1.5c0.1-0.9-2.6-2-6.7-2.7c-4.2-0.7-9.8-1.1-15.4-0.5
						c-5.7,0.6-9.6,1.3-13.5,2.1c-3.9,0.8-7.8,1.6-13.5,3c5.8-0.8,9.8-1,13.7-1.1C413.6,162.4,417.4,162.5,423,162.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M428.4,172.9c6.4,1.8,13.2,1.4,13.5-0.3c0.2-1.7-6.2-4-12.9-4c-6.9,0-9.5,0.3-16.5-0.1
						C419.4,169.9,421.8,171,428.4,172.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M465.2,185.4c3.3-1.4,6.4-3,8.7-4.4c2.3-1.4,3.8-2.7,3.5-3.5c-0.3-0.8-2.3-1-5.1-0.3c-2.8,0.7-6.3,2.2-9.3,4.5
						c-3.1,2.4-5,4.3-6.8,6.1c-1.8,1.9-3.7,3.9-6.2,6.8c2.9-2.6,5.1-4,7.4-5.3C459.5,188,461.8,186.9,465.2,185.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M479.4,189.5c3.5-0.2,7-0.5,9.6-1c2.6-0.4,4.5-1,4.6-1.9c0.1-0.9-1.7-1.8-4.6-2.3c-2.8-0.5-6.7-0.3-10.4,0.8
						c-3.7,1.2-6.2,2.3-8.5,3.5c-2.4,1.2-4.7,2.5-8,4.6c3.5-1.6,6.1-2.3,8.6-2.8C473.3,190.1,475.8,189.7,479.4,189.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M496,177.4c5.3-1.4,9.4-5.2,8.5-6.8c-0.9-1.5-6-0.2-10.3,2.8c-2.2,1.5-3.8,2.6-5.3,3.7c-1.6,1-3.2,2-5.8,3.2
						c2.7-0.7,4.6-1.1,6.5-1.5C491.4,178.5,493.3,178.1,496,177.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M508.6,181.3c2.3,0.5,4.6,0.6,6.3,0.4c1.8-0.2,3.1-0.7,3.3-1.5c0.2-0.9-0.9-1.9-2.7-2.6c-1.8-0.7-4.4-1.1-6.9-0.6
						c-2.6,0.4-4.3,1-6,1.5c-1.8,0.6-3.6,1.2-5.8,2c2.6-0.3,4.3-0.3,6-0.1C504.5,180.5,506.2,180.8,508.6,181.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M515.5,191.4c5.5,1.8,11.5,1.5,11.8-0.3c0.3-1.7-5.2-4-11-4.1c-6-0.1-8.3,0.3-14.4-0.2
						C507.8,188.4,509.8,189.5,515.5,191.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M537.8,184.7c2.8,0.1,5.6-0.6,7.4-1.6c1.9-1,2.9-2.2,2.6-3c-0.3-0.8-1.8-1.1-3.7-1c-2,0.1-4.4,0.5-6.9,1.3
						c-2.5,0.8-4.3,1.3-6.2,1.7c-1.9,0.4-3.8,0.6-6.6,0.6c2.8,0.6,4.7,1,6.6,1.3C533,184.3,535,184.6,537.8,184.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M537.5,176.3c2.8-0.1,5.5-1.1,7.4-2.2c1.8-1.1,2.8-2.4,2.4-3.2c-0.4-0.8-1.9-1-3.8-0.7c-2,0.2-4.4,0.9-6.9,1.9
						c-2.5,1-4.3,1.6-6.1,2.2c-1.8,0.5-3.7,1-6.6,1.2c2.9,0.3,4.8,0.5,6.8,0.7C532.6,176.2,534.6,176.4,537.5,176.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M535.8,166.5c2.7-0.6,5.2-1.8,6.8-3c1.7-1.3,2.5-2.6,2.1-3.4c-0.8-1.6-6.1-0.4-10.6,2.3c-2.3,1.4-4,2.4-5.6,3.4
						c-1.7,0.9-3.4,1.8-6.1,2.9c2.8-0.5,4.7-0.8,6.7-1C531.1,167.3,533,167.1,535.8,166.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M557.3,160.8c1.9-0.2,3.7-0.8,5-1.5c1.3-0.7,2.2-1.5,2.2-2.4c-0.1-0.9-1.2-1.6-2.9-1.7c-1.7-0.2-3.9,0.3-5.8,1.5
						c-1.9,1.2-3.1,2.3-4.2,3.4c-1.1,1.1-2.2,2.1-3.7,3.7c1.8-1.2,3.2-1.7,4.6-2.1C553.9,161.3,555.3,161,557.3,160.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M366.2,478.6c3.4-1,6.8-2.1,9.3-3.2c2.5-1.1,4.2-2.1,4-3c-0.1-0.9-2.1-1.3-5-1.1c-2.9,0.3-6.7,1.3-10,3.2
						c-3.4,2-5.6,3.6-7.7,5.3c-2.1,1.7-4.2,3.4-7,6.1c3.2-2.3,5.6-3.5,8-4.5C360.2,480.5,362.6,479.6,366.2,478.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M377.5,483.6c2.3,0.1,4.5-0.2,6.2-0.7c1.7-0.5,2.9-1.2,2.9-2.1c0-0.9-1.2-1.7-3.1-2.1c-1.9-0.4-4.4-0.3-6.8,0.6
						c-2.4,0.9-4,1.7-5.6,2.5c-1.7,0.9-3.3,1.8-5.3,2.9c2.4-0.7,4.2-1,5.9-1.1C373.5,483.5,375.1,483.5,377.5,483.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M400.9,489.4c2.5,0.8,5,1.3,7,1.3c2,0.1,3.5-0.3,3.7-1.1c0.3-0.8-0.8-2-2.7-2.9c-1.9-0.9-4.6-1.6-7.4-1.6
						c-2.9,0-4.8,0.3-6.8,0.5c-2.1,0.2-4.1,0.5-6.7,0.8c2.8,0.2,4.7,0.6,6.6,1.1C396.6,487.9,398.4,488.5,400.9,489.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M404,480.2c5.8,1.9,12.1,1.7,12.4,0c0.3-1.7-5.5-4.1-11.6-4.3c-6.3-0.2-8.7,0.1-15.2-0.5
						C395.8,477.1,398,478.2,404,480.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M405.2,471.3c1.9,0,3.8-0.8,4.9-1.8c1.1-1,1.6-2.1,1.2-2.9c-0.4-0.8-1.5-1.1-2.8-1c-1.3,0.1-2.9,0.5-4.3,1.4
						c-1.5,0.9-2.6,1.5-3.8,2c-1.2,0.5-2.4,0.9-4.3,1.2c1.7,0.3,3,0.5,4.5,0.7C401.8,471.2,403.2,471.4,405.2,471.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M326.4,598.7c3.6-0.2,7.2-0.8,10.9-1.8c3.6-1,7.3-2.7,10.5-5.2c1.6-1.3,3.1-2.8,4.3-4.4c1.2-1.7,2.2-3.5,3-5.4
						c1.5-3.8,2.1-7.8,1.7-11.8c-0.2-2-0.7-3.9-1.4-5.8c-0.7-1.9-1.7-3.6-2.8-5.1c-2.2-3.1-5.1-5.5-8.2-7.3c-1.6-0.8-3.2-1.5-4.9-2
						c-1.7-0.5-3.4-0.7-5.1-0.8c-3.4-0.1-6.7,0.5-9.7,1.8c-2.9,1.4-5.5,3.4-7.2,6.1c-1.7,2.6-2.4,5.5-2.4,8.1c0,2.7,0.8,5.3,2.2,7.2
						c0.7,1,1.5,1.8,2.4,2.5c0.9,0.6,1.8,1.1,2.7,1.4c3.6,1.3,6.6,0.5,8.1-0.6c1.6-1.1,1.9-2.1,1.6-2.4c-0.3-0.4-1.2-0.1-2.5,0
						c-0.7,0-1.5,0-2.4-0.2c-0.9-0.2-1.9-0.6-2.8-1.2c-0.9-0.6-1.7-1.5-2.1-2.5c-0.5-1.1-0.6-2.4-0.4-3.8c0.2-1.4,0.8-2.9,1.8-4.1
						c0.9-1.1,2.4-2.1,4.1-2.7c1.7-0.6,3.8-0.8,5.8-0.6c1,0.1,2,0.3,3,0.7c1,0.4,2,0.8,2.9,1.4c1.9,1.2,3.6,2.7,4.9,4.6
						c1.3,1.9,2,4,2.3,6.3c0.2,2.3-0.1,4.8-1,7.1c-0.4,1.1-1,2.2-1.7,3.2c-0.7,1-1.5,1.8-2.5,2.7c-3.9,3.3-9.9,5-16.1,5.8
						c-6.3,0.8-12.3,0.4-17.8-0.8c-5.5-1.2-10.3-3.2-14.8-5.6c-9-4.9-16.6-10.8-25.2-15.5c-8.6-4.7-18.1-7.9-29-9.2
						c-5.4-0.6-11.2-0.8-17.4-0.2c-6.1,0.6-12.7,2-19.4,4.5c6.8-2.2,13.3-3.3,19.4-3.6c6.1-0.3,11.8,0.2,17.1,1.1
						c10.5,1.9,19.4,5.7,27.3,10.8c3.9,2.6,7.7,5.5,11.5,8.5c3.9,3,7.9,6.1,12.5,9.1c4.6,3,9.9,5.7,16,7.5
						C312.1,598.2,319,599.2,326.4,598.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M279.9,561.6c4.2-1.3,8.3-3.6,11.6-6.8c1.7-1.6,3.1-3.5,4.3-5.6c1.2-2.1,2-4.4,2.3-7c0.7-5.2-1.5-10.8-6.2-13.8
						c-2.4-1.4-4.9-1.9-7.1-1.8c-2.2,0.1-4.1,0.5-6,1.3c-1.8,0.8-3.5,2-4.9,3.6c-1.3,1.6-2.1,3.7-2.1,5.7c0,3.9,2.4,6.6,4.7,7.6
						c2.3,1,4.2,0.5,5.3-0.1c1.1-0.6,1.3-1.3,1.2-1.6c-0.2-0.4-0.8-0.6-1.5-0.9c-0.7-0.3-1.7-0.8-2.4-1.6c-0.3-0.4-0.5-0.8-0.6-1.4
						c-0.1-0.5-0.1-1.1,0.1-1.5c0.2-0.4,0.5-0.7,1-1.1c0.5-0.3,1.2-0.7,2.1-0.9c0.9-0.2,2-0.3,2.9-0.2c1,0.1,1.7,0.4,2.2,0.8
						c0.5,0.4,0.9,1,1.2,1.8c0.2,0.4,0.3,0.9,0.3,1.3c0,0.4,0,0.9,0,1.5c-0.3,2.1-1.7,4.8-3.7,7c-2,2.2-4.7,4.1-7.8,5.4
						c-3.1,1.4-6.4,2.2-9.5,2.6c-3.2,0.4-6.2,0.5-9.2,0.4c-5.9-0.1-11.4-0.8-17-1.1c-5.6-0.3-11.4-0.3-17.6,0.8
						c-6.2,1.1-13,3.1-20.3,7c7.5-3.4,14.4-4.8,20.5-5.3c6.2-0.4,11.6,0.2,17,1.3c5.4,1,10.7,2.4,16.9,3.4c3.1,0.5,6.5,0.9,10.2,0.9
						C271.4,563.3,275.5,563,279.9,561.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M303.9,501.7c1.1-5.3,2.3-10.4,3.1-14.4c0.8-4.1,1.2-7.3-0.3-8.3c-1.4-1-4.6,0.8-7.4,4.9c-1.4,2.1-2.6,4.7-3.4,7.7
						c-0.8,3-1.1,6.3-0.8,9.6c0.3,3.4,0.9,6.2,1.7,8.6c0.7,2.4,1.6,4.5,2.6,6.5c1,2,2,4,3.3,6.1c1.3,2.1,2.8,4.3,4.9,6.8
						c-1.6-2.8-2.6-5.2-3.3-7.5c-0.7-2.3-1.1-4.3-1.3-6.4c-0.2-2.1-0.2-4.1-0.1-6.3C303,506.9,303.3,504.5,303.9,501.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M320.9,499.7c0.3-3.4-0.1-6.9-1.1-10c-0.9-3.1-2.4-5.8-4.1-7.9c-1.6-2.1-3.4-3.4-4.9-4.1c-1.5-0.7-2.6-0.7-3.3-0.1
						c-1.3,1.3-0.3,4.3,0.8,8.2c0.6,2,1.2,4.2,1.9,6.6c0.6,2.4,1.3,5,1.8,7.7c0.6,2.8,0.8,5.2,0.9,7.5c0.1,2.3,0,4.4-0.3,6.5
						c-0.5,4.2-1.6,8.5-4.5,14.4c3.8-5.3,6.1-9.2,8.1-13.3c1-2.1,1.9-4.2,2.7-6.7C319.9,506,320.6,503.2,320.9,499.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M312.1,497.4c0.7-3.6,0.6-7.3,0-10.1c-0.6-2.7-1.6-4.4-2.5-4.3c-0.9,0.1-1.4,1.9-1.7,4.6c-0.3,2.6-0.4,6-0.2,9.5
						c0.2,3.6,0.2,6.1,0.2,8.6c-0.1,2.5-0.3,5.1-1.2,8.8c1.3-3.6,2.2-6,3-8.5C310.5,503.6,311.3,501.1,312.1,497.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M310,493.7c0.9-3.1,1-6.3,0.6-8.7c-0.4-2.4-1.2-4-2.1-3.9c-0.9,0-1.6,1.6-2.1,3.8c-0.5,2.3-0.8,5.2-0.8,8.3
						c0,3.1,0,5.3-0.2,7.5c-0.2,2.2-0.4,4.4-1.2,7.6c1.2-2.9,2.1-5,3.1-7.2C308.2,499,309,496.9,310,493.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M302.6,488.7c1.5-2.6,2.9-5.1,3.9-7.2c1-2,1.6-3.6,1-4.3c-0.6-0.6-2.4,0-4.3,1.7c-1.9,1.8-3.9,4.8-4.7,8.1
						c-0.8,3.5-0.9,5.9-0.8,8.3c0.1,2.4,0.3,4.7,1,8.1c-0.2-3.4,0.2-5.7,0.8-7.9C300.3,493.4,301.1,491.4,302.6,488.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M305.8,559.5c1.5-1.7,2.7-3.7,3.5-5.7c0.8-2,1.3-4,1.4-5.7c0.3-3.4-0.5-5.7-1.4-5.7c-0.9,0-1.6,2-2.7,4.8
						c-1.1,2.8-2.5,6.3-4.4,9.7c-2,3.5-3.9,5.6-6.1,7.6c-2.2,1.9-4.8,3.6-9.1,4.9c4.4-0.8,7.4-1.9,10.1-3.3c1.4-0.7,2.7-1.5,4.2-2.5
						C302.7,562.6,304.2,561.3,305.8,559.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M341,541.1c4.9-0.6,9.7-1.1,13.4-1.5c3.7-0.4,6.3-0.9,6.4-1.8c0.1-0.8-2.4-2-6.4-2.4c-3.9-0.5-9.3-0.1-14.4,1.4
						c-5.2,1.6-8.5,3.3-11.7,5c-3.2,1.8-6.4,3.7-10.8,6.9c4.6-2.8,8.1-4.1,11.5-5.2C332.6,542.5,336,541.7,341,541.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M361.1,546c3.3,0.2,6.6,0,9.1-0.3c2.5-0.4,4.3-1,4.3-1.9c0.1-0.9-1.6-1.8-4.2-2.3c-2.6-0.5-6.1-0.6-9.6,0.1
						c-3.5,0.7-5.9,1.5-8.2,2.3c-2.5,0.8-4.8,1.6-8.1,2.8c3.6-0.7,6-0.8,8.5-0.9C355.2,545.7,357.6,545.8,361.1,546z"
                    />
                  </g>
                  <g>
                    <path
                      d="M368.5,552.6c2.6,0.8,5.2,1.2,7.2,1.2c2,0,3.6-0.3,3.8-1.2c0.2-0.8-0.9-2-2.8-2.9c-2-0.9-4.8-1.6-7.6-1.5
						c-2.9,0.1-4.9,0.4-6.9,0.7c-2.1,0.3-4.2,0.7-6.9,1.1c2.9,0.1,4.9,0.4,6.8,0.8C364,551.3,365.9,551.8,368.5,552.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M371.3,563.5c1.5,1.5,2.9,2.8,4,3.8c1.2,1,2.2,1.7,3.1,1.5c0.8-0.3,1.2-1.8,0.4-3.8c-0.7-2-2.7-4.1-5.1-5.1
						c-2.5-1.1-4.2-1.3-6-1.6c-1.7-0.3-3.4-0.5-5.9-1c2.3,1,3.8,1.8,5.2,2.7C368.4,561,369.8,562,371.3,563.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M370.1,580.9c0.2,2.4,0.6,4.7,1.1,6.5c0.5,1.8,1.1,3.1,2,3.2c0.9,0.1,1.8-1.2,2.2-3.3c0.4-2.1,0.2-5-1-7.6
						c-1.2-2.6-2.4-4.2-3.6-5.8c-1.2-1.5-2.5-3-4.4-5c1.5,2.3,2.2,4.1,2.7,5.8C369.5,576.6,369.8,578.4,370.1,580.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M364.6,598.8c-0.5,1.7-0.6,3.5-0.3,4.8c0.2,1.4,0.8,2.4,1.7,2.6c0.9,0.2,1.8-0.6,2.5-2c0.6-1.4,1-3.4,0.6-5.3
						c-0.4-2-0.9-3.3-1.3-4.7c-0.5-1.3-0.9-2.6-1.5-4.5c0.1,2-0.1,3.3-0.4,4.7C365.5,595.7,365.1,597,364.6,598.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M353.3,601c-3.3,3.3-5.1,7.7-3.7,8.8c1.4,1.1,5.4-1.9,7.3-6.4c1.9-4.6,2.2-6.5,4.2-11.1
						C358.2,596.4,356.7,597.5,353.3,601z"
                    />
                  </g>
                  <g>
                    <path
                      d="M340.2,602.7c-2.6,1.1-5,2.5-6.7,3.8c-1.7,1.3-2.7,2.6-2.3,3.4c0.4,0.8,2,0.9,4.2,0.3c2.2-0.6,4.8-1.9,7.1-3.8
						c2.3-2,3.8-3.5,5.2-5c1.5-1.5,2.9-3,5.1-5.1c-2.5,1.7-4.4,2.7-6.2,3.6C344.8,600.8,342.9,601.6,340.2,602.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M316.4,606.9c-2.8,0-5.6,0.4-7.6,1c-2.1,0.6-3.5,1.4-3.5,2.2c0,0.9,1.5,1.6,3.7,1.9c2.2,0.3,5.2,0.1,8.1-0.9
						c2.9-1,4.8-1.9,6.7-2.8c2-1,4-1.9,6.6-3.1c-3,0.8-5,1.1-7.1,1.3C321.3,606.8,319.3,606.9,316.4,606.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M233,569.1c5.4,0.9,10.6,2.2,14.6,3.2c4,1,6.8,1.7,7.2,0.8c0.4-0.7-1.8-2.8-5.8-4.8c-4-1.9-9.8-3.5-15.7-3.6
						c-6,0-10.1,0.7-14.1,1.6c-4,0.9-8,2.1-13.5,4.5c5.7-1.9,9.8-2.4,13.7-2.6C223.5,568,227.4,568.2,233,569.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M251.9,584.4c1.8,1.9,3.6,3.7,5.1,5.1c1.5,1.3,2.8,2.2,3.6,1.8c0.8-0.4,0.8-2.1-0.2-4.2c-1-2.1-3.1-4.6-5.8-6.1
						c-2.8-1.5-4.8-2.2-6.9-2.7c-2.1-0.5-4.1-0.9-7.1-1.1c2.9,0.8,4.7,1.8,6.4,2.9C248.6,581.1,250.1,582.4,251.9,584.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M68,396c1.6-0.2,3.2-0.3,4.9-0.5c1.6-0.1,3.3-0.2,4.8-0.2c3.1,0,6.1,0.2,8.8,0.7c2.7,0.5,5.1,1.4,6.9,2.6
						c0.9,0.6,1.7,1.2,2.3,1.9c0.3,0.3,0.6,0.7,0.9,1.1c0.2,0.4,0.4,0.7,0.6,1.1c0.7,1.6,1.1,3.6,0.9,5.5c-0.1,1-0.3,1.9-0.6,2.8
						c-0.3,0.9-0.8,1.8-1.3,2.6c-1.1,1.7-2.5,3.2-4.2,4.4c-1.7,1.2-3.4,2.1-5.2,2.7c-1.8,0.6-3.7,0.8-5.3,0.7
						c-1.7-0.1-3.2-0.4-4.5-1c-2.5-1-4.3-3.3-5.3-5.4c-1-2.1-1.2-4.1-1.3-5.6c0-0.7-0.1-1.4-0.1-1.9c-0.1-0.5-0.2-0.9-0.5-1.1
						c-0.3-0.2-0.7-0.2-1.2,0c-0.5,0.2-1.1,0.7-1.8,1.5c-0.6,0.8-1.3,1.9-1.7,3.4c-0.4,1.4-0.7,3.2-0.5,5.1c0.1,1.9,0.7,4.1,1.7,6.3
						c0.3,0.5,0.6,1.1,0.9,1.6c0.4,0.5,0.7,1.1,1.2,1.6c0.5,0.5,0.9,1.1,1.4,1.5c0.3,0.2,0.5,0.5,0.8,0.7c0.3,0.2,0.6,0.4,0.8,0.7
						c2.3,1.8,5.2,3,8.2,3.7c3.1,0.7,6.4,0.7,9.7,0.2c3.3-0.6,6.8-1.8,9.9-3.6c3.1-1.9,6.1-4.4,8.5-7.6c1.2-1.6,2.3-3.4,3.1-5.4
						c0.8-2,1.4-4.1,1.7-6.3c0.6-4.4,0.1-9-1.8-13.5c-0.5-1.1-1.1-2.2-1.7-3.2c-0.7-1-1.4-2-2.2-2.9c-0.8-0.9-1.7-1.7-2.6-2.5
						c-0.5-0.4-0.9-0.7-1.4-1.1c-0.5-0.4-1-0.7-1.5-1c-4-2.5-8.2-3.7-12.3-4.3c-4.1-0.6-8.1-0.7-12-0.3c-1.9,0.2-3.8,0.4-5.6,0.7
						c-1.9,0.3-3.7,0.6-5.5,1c-7.6,1.7-14.2,4-20.2,6.7c-6,2.7-11.3,5.7-16.1,8.9c-4.8,3.2-9.1,6.5-13,10.1
						c-3.9,3.5-7.6,7.2-10.9,11c-6.7,7.7-12.6,16.1-18,26.2c-5.4,10-10.4,21.7-14.4,36.1c4.8-14.2,10.6-25.4,16.7-34.7
						c6.1-9.4,12.7-16.9,19.9-23.6c3.6-3.3,7.4-6.3,11.4-9.2c4-2.9,8.4-5.5,13.1-7.9c4.7-2.4,9.8-4.6,15.4-6.4
						C55.2,398.3,61.3,396.8,68,396z"
                    />
                  </g>
                  <g>
                    <path
                      d="M53,455.6c0.5,1.9,0.5,3.8,0.3,5.6c-0.3,1.9-0.8,3.7-1.7,5.4c-0.9,1.7-2,3.1-3.3,4.3c-0.6,0.6-1.3,1.1-2,1.6
						c-0.7,0.5-1.5,0.9-2.3,1.2c-3.2,1.4-6.9,1.6-10,0.9c-1.6-0.3-2.9-0.9-3.9-1.6c-1-0.7-1.6-1.3-2.1-2.1c-0.2-0.4-0.4-0.8-0.5-1.1
						c-0.1-0.3-0.2-0.6-0.1-0.9c0-0.6,0.3-1.5,1-2.3c0.7-0.9,1.6-1.7,2.6-2.4c0.5-0.3,1-0.7,1.5-1c0.5-0.3,0.9-0.5,1.3-0.8
						c0.5-0.3,1-0.6,1.4-0.9c0.2-0.2,0.7-0.2,1.1-0.2c0.4,0.1,0.7,0.2,1,0.4c0.3,0.2,0.5,0.5,0.8,0.6c0.3,0.1,0.7,0.1,1.1-0.4
						c0.4-0.4,0.7-1.4,0.4-2.5c-0.2-1.1-0.9-2.3-2.5-3.3c-1.5-0.9-3.3-1.1-5-1c-0.8,0.1-1.6,0.2-2.4,0.3c-0.8,0.1-1.6,0.3-2.5,0.6
						c-1.7,0.5-3.6,1.3-5.5,2.6c-1.9,1.3-4,3.4-5.2,6.4c-0.6,1.5-1,3.2-1.1,4.8c-0.1,1.7,0.1,3.3,0.6,5c0.5,1.6,1.2,3.2,2.1,4.6
						c0.9,1.5,2.1,2.7,3.3,3.8c2.5,2.2,5.4,3.8,8.4,4.8c6.1,2,13.1,1.9,19.6-0.7c3.2-1.3,6.4-3.2,9-5.8c2.6-2.5,4.7-5.6,6.2-8.9
						c1.5-3.3,2.4-6.9,2.5-10.6c0.2-3.7-0.5-7.5-1.8-10.8c-2.7-7-7.7-12-13.2-14.9c-5.6-2.9-11.3-3.4-16.2-3.1
						c-4.9,0.3-9.3,1.4-13.4,2.8c-4.1,1.3-7.9,3-11.5,4.9c-3.7,1.9-7.1,4.1-10.6,6.6c-3.4,2.5-6.7,5.3-9.9,8.6
						c-3.2,3.3-6.3,6.9-9.3,11.1c-2.9,4.2-5.7,9-8,14.5c2.6-5.3,5.7-9.8,9-13.7c3.2-3.9,6.6-7.1,10-9.9c3.4-2.8,6.9-5.1,10.4-7
						c3.5-1.9,7-3.5,10.6-4.8c3.6-1.2,7.3-2.3,11.1-2.9c3.8-0.7,7.7-1.1,11.5-0.8c3.8,0.3,7.6,1.4,10.5,3.5
						C49.6,448.2,52,451.7,53,455.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M121.8,361.1c10.9,1.6,21.5,3.9,29.6,5.9c0.5,0.1,1,0.2,1.5,0.3c0.5,0.1,1,0.2,1.4,0.4c0.9,0.2,1.8,0.4,2.6,0.6
						c0.8,0.2,1.6,0.3,2.4,0.5c0.7,0.2,1.4,0.3,2,0.4c2.5,0.4,4.2,0.3,4.6-0.5c0.4-0.8-0.5-2.1-2.5-3.9c-0.5-0.4-1.1-0.9-1.7-1.4
						c-0.6-0.5-1.4-0.9-2.1-1.4c-0.8-0.5-1.6-1-2.5-1.5c-0.5-0.2-0.9-0.5-1.4-0.8c-0.5-0.2-1-0.5-1.5-0.7
						c-8.1-3.8-19.9-6.9-31.8-6.7c-3.1,0.1-5.9,0.1-8.5,0.4c-1.3,0.1-2.6,0.2-3.8,0.4c-1.2,0.2-2.4,0.4-3.5,0.5
						c-1.1,0.2-2.3,0.3-3.3,0.5c-1.1,0.2-2.1,0.4-3.2,0.7c-1,0.2-2.1,0.4-3.1,0.7c-1,0.3-2,0.5-3,0.8c-1,0.3-2,0.5-3,0.8
						c-1,0.3-2,0.6-3,1c-1,0.3-2,0.6-3.1,1c-1,0.4-2.1,0.8-3.2,1.2c-1.1,0.4-2.2,0.8-3.3,1.3c-1.1,0.5-2.3,1-3.5,1.5
						c-0.6,0.3-1.2,0.5-1.8,0.8c-0.6,0.3-1.2,0.6-1.9,0.9c-1.3,0.6-2.6,1.2-4,1.9c1.4-0.5,2.8-1,4.1-1.5c0.7-0.2,1.3-0.5,1.9-0.7
						c0.6-0.2,1.3-0.4,1.9-0.6c1.2-0.4,2.4-0.7,3.6-1c1.2-0.3,2.3-0.5,3.4-0.8c1.1-0.2,2.2-0.5,3.3-0.7c1.1-0.2,2.1-0.3,3.2-0.5
						c1-0.1,2-0.3,3-0.4c1-0.1,2-0.2,3-0.3c1-0.1,2-0.2,3-0.2c1-0.1,2-0.1,3-0.1c1,0,2.1,0,3.1-0.1c1.1,0,2.1,0.1,3.2,0.1
						c1.1,0,2.2,0.1,3.4,0.1c1.2,0.1,2.3,0.2,3.6,0.3C116.4,360.4,119,360.8,121.8,361.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M120.9,378.3c10.8,0.9,21.8-0.5,29.7-2.7c7.9-2.2,12.8-5.1,12.3-6.8c-0.4-1.8-5.9-1.9-13.7-1.5
						c-7.8,0.4-18.1,1.3-28.4,2.2c-10.6,0.9-18,1.1-25.5,0.9c-7.5-0.3-15.1-1-26.1-3.4c10.7,3.5,18.2,5.4,25.6,7.2
						C102.3,375.9,109.8,377.4,120.9,378.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M132.4,371.1c6.3,1.3,12.7,1.4,17.5,0.7c4.8-0.7,8-2.3,8-4c0-1.8-3.1-3.3-7.8-4.4c-4.7-1-10.9-1.5-17.2-1
						c-6.5,0.5-10.9,0.9-15.5,1.2c-4.5,0.3-9.1,0.4-15.8,0c6.6,1.4,11,2.6,15.4,3.8C121.5,368.5,125.9,369.7,132.4,371.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M166.3,420.2c1,0,2.1,0,3.1-0.2c1-0.2,2.1-0.4,3.1-0.7c2-0.7,4-1.7,5.8-3.3c1.8-1.5,3.3-3.7,4-6.1
						c0.7-2.4,0.7-4.8,0.3-6.9c-0.4-2.1-1.2-4-2.1-5.7c-0.9-1.7-2-3.1-3.3-4.5c-1.3-1.3-2.8-2.4-4.5-3.3c-1.6-0.8-3.6-1.3-5.3-1.4
						c-3.5-0.2-6.9,1.1-9,3.3c-1.1,1.1-1.8,2.4-2.2,3.6c-0.4,1.2-0.5,2.3-0.4,3.3c0.2,2,1.1,3.4,1.9,4c0.8,0.7,1.5,0.7,1.8,0.4
						c0.3-0.3,0.4-0.9,0.5-1.6c0.1-0.7,0.2-1.6,0.7-2.4c0.4-0.9,1.2-1.8,2.1-2.1c0.9-0.4,2.3-0.6,3.6-0.2c1.3,0.4,2.6,1.3,3.9,2.8
						c1.2,1.5,2.3,3.6,2.6,5.5c0.2,0.9,0.1,1.7-0.1,2.3c-0.2,0.6-0.5,1.2-1.1,1.8c-0.6,0.6-1.5,1.2-2.5,1.7
						c-0.5,0.2-1.1,0.4-1.6,0.6c-0.6,0.2-1.1,0.3-1.8,0.4c-2.6,0.3-5.2,0-7.6-0.7c-2.4-0.7-4.7-1.7-6.8-2.9c-4.1-2.4-7.6-5.4-11-8.5
						c-3.5-3.1-6.9-6.5-11.2-9.7c-2.2-1.6-4.5-3.1-7.3-4.5c-2.7-1.3-5.9-2.4-9.3-2.6c3.5,0.5,6.4,1.9,8.9,3.4
						c2.5,1.5,4.7,3.3,6.5,5.1c3.8,3.6,6.6,7.3,9.5,11c3,3.7,6,7.4,10.3,11c2.2,1.8,4.6,3.4,7.6,4.8
						C158.6,419,162.2,420.1,166.3,420.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M130.6,425.7c-0.7,1.3-1.4,2.2-2.3,3c-0.9,0.7-1.7,1.2-2.5,1.4c-0.6,0.2-1.4,0.2-2.1,0c-0.7-0.2-1.2-0.4-1.5-0.7
						c-0.3-0.1-0.6-0.7-0.7-1.3c-0.1-0.6-0.1-1.3,0-1.9c0.1-0.6,0.3-1.1,0.4-1.6c0.2-0.5,0.3-1,0.2-1.4c-0.1-0.4-0.4-0.8-1.1-1
						c-0.7-0.2-1.8-0.1-2.9,0.5c-1.2,0.6-2.4,1.7-3.2,3.4c-0.8,1.6-1.3,4-0.4,6.6c0.8,2.4,2.7,4.6,5.1,5.9c2.4,1.3,5.3,1.8,8.3,1.2
						c2.9-0.6,5.4-2.2,7.2-4.2c1.8-2,3.1-4.6,3.7-7c0.6-2.5,0.6-4.8,0.4-6.8c-0.2-2-0.6-3.8-1-5.4c-0.9-3.3-2.2-6.1-3.7-8.6
						c-1.5-2.6-3.3-4.9-5.6-7.3c-1.1-1.2-2.4-2.3-3.8-3.5c-1.4-1.1-3.1-2.2-4.9-3.2c1.7,1.2,3.1,2.6,4.3,3.8c1.2,1.3,2.2,2.6,3,3.9
						c1.7,2.6,2.7,5.1,3.5,7.7c0.7,2.6,1.1,5.2,1.1,8C132.2,419.9,131.9,423.1,130.6,425.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M612.6,399.5c-0.7-3.6-1.9-7.1-3.5-10.4c-1.6-3.3-3.6-6.4-5.8-9.1c-2.2-2.7-4.6-4.8-6.8-6.8c-2.2-1.9-4.2-3.6-5.9-5.2
						c-3.5-3.1-6-6-7.6-8.2c-1.7-2.2-2.6-3.7-3.5-3.5c-0.8,0.1-1.5,2-0.9,5.4c0.3,1.6,0.9,3.6,1.9,5.6c1,2,2.4,4.2,4,6.3
						c3.3,4.3,7.7,8.3,11.3,12.7c3.5,4.3,6.4,9.6,8.4,15.4c1,3,1.7,5.9,2.1,8.6c0.4,2.7,0.7,5.4,0.7,7.9c0,5-0.5,9.6-1.6,14.1
						c-1.1,4.5-2.6,9-4.9,13.8c-2.3,4.8-5.4,9.9-9.8,15.6c4.8-5.3,8.4-10.1,11.3-14.7c2.9-4.6,5.2-9,7-13.5
						c1.8-4.6,3.3-9.3,4.1-14.8c0.4-2.8,0.6-5.7,0.6-8.9C613.7,406.6,613.4,403.2,612.6,399.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M580.2,404.8c-0.8-5.7-1-11.4-0.9-16.8c0.1-5.4,0.4-10.6,0.8-15c0.7-8.9,0.9-15.2-0.8-15.7c-1.7-0.4-4.9,5.1-7.5,14.3
						c-1.2,4.6-2.2,10.1-2.4,16.2c-0.2,6,0.4,12.6,2.2,18.9c1.9,6.5,4.3,11.6,7.1,15.9c1.4,2.2,2.8,4.1,4.3,5.9
						c1.4,1.8,2.9,3.4,4.2,5.1c2.6,3.3,5,6.9,5.9,11.6c0.2,1.2,0.4,2.4,0.4,3.7c0,1.3,0,2.6-0.2,4c-0.4,2.7-1.3,5.6-3,8.4
						c1.9-2.7,3.1-5.5,3.8-8.2c0.4-1.4,0.6-2.7,0.7-4.1c0.1-1.3,0.1-2.6,0.1-3.9c-0.1-2.5-0.7-5-1.5-7.2c-0.8-2.2-1.8-4.3-2.9-6.2
						c-2.2-3.9-4.5-7.5-6.2-11.7C582.4,415.5,581,410.7,580.2,404.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M593.6,391.6c-1-7.1-3.4-13.9-5.9-18.7c-2.5-4.8-4.9-7.5-5.7-7.1c-0.8,0.4,0.2,3.8,1.8,8.7c1.6,4.9,3.7,11.4,5.5,17.9
						c1.8,6.8,2.7,11.6,3.3,16.5c0.5,4.9,0.8,9.9,0.1,17.3c1.2-7.3,1.6-12.4,1.8-17.4C594.6,403.9,594.6,398.9,593.6,391.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M588.3,385.7c-0.4-5.3-1.8-10.5-3.4-14.1c-1.6-3.7-3.4-5.8-4.2-5.5c-0.8,0.3-0.6,3,0.2,6.7c0.7,3.8,1.9,8.6,3.1,13.5
						c1.2,5.1,1.9,8.6,2.3,12.2c0.4,3.6,0.7,7.4,0.3,12.8c0.9-5.4,1.3-9.2,1.6-12.9C588.5,394.8,588.7,391.1,588.3,385.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M583.8,385.6c0.2-8.5-1.7-16.9-3.5-16.8c-1.8,0.1-2.4,8.7-0.9,17.1c1.6,8.6,2.6,11.8,3.8,20.8
						C583,397.7,583.5,394.4,583.8,385.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M38.3,418.2c3.6-0.5,7.1-1.2,9.7-2c2.6-0.8,4.4-1.7,4.3-2.5c-0.1-0.9-2-1.5-4.9-1.6c-2.9-0.1-6.7,0.5-10.3,1.9
						c-3.7,1.5-6,2.8-8.4,4.1c-2.3,1.3-4.7,2.7-8,4.8c3.6-1.6,6.2-2.4,8.7-3C32.1,419.3,34.6,418.8,38.3,418.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M37.7,425.6c3.2-0.1,6.2-0.5,8.6-1.1c2.3-0.5,4-1.2,4-2.1c0-0.9-1.6-1.7-4.2-2.1c-2.6-0.4-6-0.1-9.3,0.9
						c-3.3,1.1-5.5,2.1-7.6,3.2c-2.1,1.1-4.2,2.2-7.3,3.9c3.3-1.2,5.6-1.8,7.9-2.1C32.2,425.9,34.5,425.7,37.7,425.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M59.5,432.6c2.4,0.8,4.7,1.4,6.6,1.6c1.9,0.2,3.4,0.1,3.8-0.7c0.4-0.8-0.5-2.1-2.3-3.2c-1.8-1.1-4.5-2-7.3-2
						c-2.9,0-4.8,0.3-6.8,0.7c-1.9,0.4-3.8,0.8-6.5,1.5c2.8-0.2,4.7,0,6.5,0.4C55.3,431.3,57.1,431.8,59.5,432.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M80.6,446.8c4,0.5,8.1-0.5,10.7-1.9c2.7-1.4,4-3.1,3.6-3.8c-0.4-0.8-2.4-0.5-5-0.1c-2.6,0.4-5.9,1-9.4,1.5
						c-3.6,0.4-6.1,0.3-8.7,0c-2.5-0.4-5.2-1.1-8.6-3.1c3.1,2.4,5.6,3.7,8.1,4.9C73.9,445.3,76.5,446.2,80.6,446.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M84.1,455.5c2.2,0,4.3-0.8,5.6-1.8c1.3-1,1.9-2.2,1.5-3c-0.4-0.8-1.6-1-3.1-0.9c-1.5,0.1-3.2,0.6-4.9,1.4
						c-1.8,0.8-3.1,1.4-4.4,1.8c-1.4,0.4-2.8,0.8-4.9,0.8c2.1,0.5,3.5,0.8,5,1.1C80.4,455.3,81.9,455.5,84.1,455.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-6.8,468.2c-3.8,3.9-6.3,9-7.3,13c-1,4-0.7,6.8,0.2,7c0.9,0.2,2.1-2.2,3.8-5.5c1.7-3.3,4-7.6,6.8-11.7
						c2.9-4.2,5.4-6.9,8.1-9.3c2.8-2.3,5.8-4.6,11-6.6c-5.3,1.5-8.8,3.2-12.1,5C0.3,462-2.9,464.1-6.8,468.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-0.6,480.6c-1,1.9-1.8,3.9-2.4,5.8c-0.3,1-0.5,1.9-0.7,2.8c-0.2,0.9-0.3,1.9-0.3,2.8c0,0.9,0.1,1.8,0.4,2.6
						c0.3,0.8,0.7,1.5,1.2,1.9c0.5,0.4,1,0.6,1.4,0.6c0.4,0,0.6-0.1,0.7-0.3c0.2-0.4-0.1-1,0-1.6c0-0.6,0.1-1.5,0.5-2.7
						c0.2-0.6,0.4-1.2,0.7-2.1c0.3-0.8,0.6-1.7,0.8-2.5c0.6-1.8,1.1-3.7,1.7-5.6c1.1-4,2.1-6.7,3.2-9.3c1.2-2.7,2.5-5.3,5-8.9
						c-2.9,3.2-4.8,5.6-6.7,7.9C3.3,474.3,1.5,476.7-0.6,480.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M7.5,492.9c-0.1,2.4,0.6,4.8,1.6,6.4c1,1.6,2.2,2.3,3,2c0.8-0.4,1.1-1.7,1-3.3c-0.1-1.6-0.5-3.6-1.2-5.6
						c-0.7-2.1-1.2-3.6-1.5-5.1c-0.3-1.6-0.5-3.2-0.3-5.6c-0.7,2.3-1.2,3.9-1.6,5.5C8.1,488.7,7.7,490.4,7.5,492.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M19.5,498.4c0.8,2,2.3,3.6,3.7,4.4c1.4,0.8,2.7,0.9,3.3,0.2c0.6-0.7,0.3-1.9-0.3-3.1c-0.6-1.3-1.7-2.7-3-3.9
						c-1.4-1.3-2.4-2.3-3.2-3.3c-0.9-1.1-1.7-2.2-2.5-4.2c0.3,2.1,0.5,3.5,0.7,4.9C18.5,494.9,18.7,496.4,19.5,498.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M36.3,500.3c1.6,0.8,3.4,0.8,4.7,0.4c1.3-0.4,2-1.3,2-2.2c-0.1-0.9-0.9-1.6-1.9-2c-1.1-0.5-2.4-0.7-3.8-0.5
						c-1.5,0.2-2.6,0.2-3.7,0.2c-1.1,0-2.3-0.2-3.8-0.8c1.1,0.9,2.1,1.7,3.2,2.6C33.7,498.7,34.7,499.5,36.3,500.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M173.3,453.4c1.6-3.6,2.3-7.6,2.2-11.5c-0.1-3.9-1-7.8-2.9-11.2c-1-1.7-2.2-3.2-3.6-4.5c-1.5-1.3-3.2-2.3-5.1-2.8
						c-3.8-1.1-7.5-0.3-10.2,1.3c-2.8,1.6-4.7,4.3-5.4,6.7c-0.8,2.5-0.6,4.7-0.2,6.4c0.5,1.7,1.2,2.9,1.8,3.6
						c0.6,0.7,1.1,0.9,1.2,0.8c0.4-0.3-0.5-1.9-0.7-4.8c0-1.4,0.2-3.2,1-4.9c0.8-1.7,2.2-3.3,4.2-4.2c2-1,4.6-1.3,6.8-0.5
						c2.3,0.8,4.4,2.7,5.8,5.3c1.4,2.6,2.2,5.7,2.4,8.9c0.2,3.2-0.3,6.6-1.4,9.7c-1.2,3.2-3,6-4.8,8.5c-1.9,2.5-3.8,4.8-5.6,7.1
						c-0.9,1.2-1.7,2.3-2.5,3.5c-0.4,0.6-0.7,1.2-1.1,1.8l-0.5,0.9l-0.5,0.9c-0.3,0.6-0.6,1.2-0.9,1.8c-0.3,0.6-0.5,1.2-0.8,1.8
						c-0.6,1.2-0.9,2.5-1.4,3.7c-0.8,2.5-1.4,5.1-1.8,7.8c-0.2,1.3-0.3,2.7-0.4,4.1c0,0.7,0,1.4,0,2.1c0,0.7,0,1.4,0,2.2
						c0.1,2.9,0.5,6,1.3,9.3c0.8,3.2,2,6.6,3.8,10c-1.7-3.5-2.7-6.9-3.4-10.1c-0.6-3.2-0.9-6.3-0.8-9.2c0-0.7,0-1.4,0.1-2.1
						c0.1-0.7,0.1-1.4,0.2-2.1c0.2-1.4,0.3-2.7,0.6-4c0.5-2.6,1.3-5,2.2-7.4c1.9-4.7,4.5-9,8.3-13.1c1.9-2.1,4-4.2,6.1-6.7
						C169.4,460,171.7,457.1,173.3,453.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M154.7,445.2c-2.1,1.9-3.9,4-5.1,5.8c-1.2,1.8-1.8,3.3-1.2,4c0.6,0.7,2.2,0.3,4.2-0.9c1.9-1.2,4.1-3.4,5.6-6.1
						c0.8-1.4,1.3-2.5,1.8-3.6c0.5-1.1,0.9-2.1,1.2-3.2c0.3-1.1,0.5-2.2,0.4-3.5c-0.1-1.2-0.5-2.6-1.7-3.7c0.9,1.3,1,2.6,0.8,3.7
						c-0.2,1.1-0.7,2-1.2,2.7c-0.6,0.8-1.2,1.5-2,2.3C156.6,443.3,155.7,444.2,154.7,445.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M157.3,453.9c-1.7,1.7-3.1,3.6-4,5.2c-0.9,1.6-1.2,3-0.6,3.7c0.6,0.6,2.1,0.3,3.7-0.9c1.6-1.1,3.4-3.1,4.4-5.5
						c1.1-2.4,1.7-4.2,2.2-6c0.6-1.9,1.1-3.7,1.8-6c-1.2,2.3-2.3,3.8-3.5,5.1C160.3,450.9,159.1,452.2,157.3,453.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M187.3,434.6c0.8-2.6,0.7-5.4,0.1-7.4c-0.6-2-1.7-3.1-2.5-2.9c-0.9,0.2-1.4,1.5-1.6,3.3c-0.3,1.8-0.4,4-0.3,6.3
						c0.1,2.4,0,4.2-0.2,5.9c-0.3,1.7-0.7,3.5-1.9,5.9c1.6-2.1,2.7-3.7,3.6-5.2C185.5,438.9,186.5,437.3,187.3,434.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M196,440.1c1.4-0.9,2.5-2.1,3.2-3.2c0.7-1.1,1-2.2,0.6-3c-0.5-0.7-1.7-0.9-3.1-0.4c-1.4,0.5-3,1.8-4,3.6
						c-1,1.8-1.4,3.2-1.8,4.5c-0.4,1.3-0.7,2.6-1.2,4.5c1-1.7,1.9-2.6,2.8-3.5C193.5,441.8,194.5,441.1,196,440.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M204.1,446c1.7-0.2,3.1-0.8,4.2-1.5c1.1-0.7,1.8-1.6,1.7-2.5c-0.1-0.9-1.2-1.5-2.7-1.6c-1.5-0.1-3.4,0.4-5,1.6
						c-1.6,1.2-2.5,2.2-3.5,3.2c-1,1.1-1.9,2.2-3,3.4c1.6-1,2.8-1.5,4-1.8C201.1,446.4,202.3,446.2,204.1,446z"
                    />
                  </g>
                  <g>
                    <path
                      d="M255.9,442.9c-2.8,2-5.5,3.9-7.6,5.6c-2,1.6-3.4,3-3,3.8c0.3,0.8,2.4,0.8,5.1-0.2c2.7-1,6-3.1,8.4-6
						c2.5-3,3.9-5.3,5.2-7.6c1.2-2.3,2.4-4.7,3.8-8.3c-1.9,3.4-3.5,5.4-5.3,7.2C260.7,439.2,258.8,440.8,255.9,442.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M258,452.6c-2.4,3.6-3.9,7.7-4.5,10.9c-0.5,3.2-0.2,5.5,0.6,5.6c0.9,0.2,2-1.7,3.4-4.4c1.3-2.7,2.9-6.4,4.3-10.1
						c1.5-3.8,2.8-6.4,4.3-9c1.5-2.5,3.1-5,6.1-8.3c-3.4,2.9-5.5,5-7.6,7.1C262.6,446.7,260.5,448.9,258,452.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M264.1,459.8c-1.9,3.8-3,8-3.2,11.2c-0.3,3.2,0.2,5.4,1,5.5c0.9,0.1,1.9-1.8,3-4.6c1.1-2.9,2.3-6.7,3.3-10.6
						c1-4,1.9-6.8,2.9-9.6c1-2.8,2.2-5.5,4.4-9.3c-2.7,3.5-4.4,6-6.1,8.5C267.7,453.4,266.1,455.9,264.1,459.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M266.4,475.3c-1.1,3.9-1.3,8-0.8,11c0.4,3,1.4,5,2.2,4.9c0.9-0.1,1.5-2,2-4.9c0.5-2.8,0.8-6.6,1-10.3
						c0.2-3.9,0.5-6.6,0.9-9.3c0.5-2.7,1.1-5.5,2.6-9.4c-2,3.6-3.2,6.2-4.4,8.8C268.6,468.7,267.5,471.3,266.4,475.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M332.1,491.8c-2.3,0.4-4.4,1.4-5.7,2.6c-1.3,1.2-1.9,2.4-1.4,3.2c0.5,0.8,1.8,0.9,3.4,0.5c1.6-0.3,3.5-1.1,5.3-2.2
						c1.9-1.2,3.2-2,4.6-2.7c1.4-0.7,2.9-1.4,5.1-2c-2.1,0-3.8,0.1-5.6,0.1C336.1,491.3,334.5,491.4,332.1,491.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M338.2,497.8c-2,0.5-2.5,2.8-1.3,4.1c1.2,1.3,3.4,1.1,4.2-0.8c0.8-1.9,0.9-3,2-4.7
						C341.2,497.3,340.2,497.3,338.2,497.8z"
                    />
                  </g>
                  <g>
                    <path d="M432.9,506.6c-3.6,0.1-6.5,2.6-5.9,4.2c0.7,1.6,4.1,1.7,7,0c3-1.8,4.1-2.8,7.6-4C438.1,507,436.6,506.6,432.9,506.6z" />
                  </g>
                  <g>
                    <path
                      d="M152,526.9c-2.4-3.5-5.6-6.8-9.9-9.1c-2.1-1.1-4.4-2-6.8-2.5c-2.4-0.5-5-0.6-7.6-0.1c-5.2,0.9-9.7,3.7-12.6,7.9
						c-0.7,1.1-1.3,2.2-1.8,3.4c-0.5,1.2-0.8,2.5-0.9,3.7c-0.3,2.4,0,4.7,0.7,6.8c0.7,2.1,1.8,4,3.3,5.5c1.5,1.5,3.3,2.6,5,3.2
						c3.6,1.1,6.6,0.4,8.6-0.7c2.1-1.1,3.2-2.7,3.5-3.9c0.2-0.6,0.2-1.1,0.1-1.5c-0.1-0.4-0.3-0.7-0.6-0.8c-0.3-0.2-0.6-0.3-1-0.3
						c-0.4,0-0.8,0-1.2-0.1c-0.8,0-1.8-0.1-2.7-0.4c-0.9-0.3-2-0.9-2.4-1.5c-0.2-0.3-0.3-0.7-0.4-1.1c-0.1-0.4-0.1-0.8-0.1-1.4
						c0-0.5,0.2-1,0.4-1.4c0.1-0.2,0.2-0.4,0.3-0.5c0.1-0.2,0.3-0.4,0.5-0.5c0.4-0.4,0.9-0.7,1.5-1c0.6-0.3,1.3-0.5,2-0.6
						c1.4-0.2,3.4,0.1,5.5,0.9c2,0.8,4.2,2.4,6.2,4.4c1.1,1,2.1,2.2,3,3.4c0.9,1.2,1.8,2.3,2.6,3.5c1.5,2.4,2.8,4.7,3.8,7.1
						c2,4.7,3.3,9.3,3.7,14.1c0.5,4.8,0.2,9.7-1.1,15.2c-1.3,5.4-3.8,11.3-8.4,17.4c5.2-5.5,8.6-11.1,10.9-16.5
						c2.3-5.4,3.7-10.6,4.3-15.9c0.6-5.3,0.5-10.8-0.6-16.8c-0.6-3-1.4-6.1-2.7-9.4c-0.7-1.6-1.4-3.3-2.3-5
						C154.2,530.4,153.2,528.7,152,526.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M180.5,567.9c3.9-1.9,7.3-4.1,9.8-6.3c2.5-2.1,4.1-4.2,3.6-5.9c-0.5-1.6-3.3-2.4-7.1-1.7c-3.7,0.7-8.4,3.2-11.7,7
						c-3.4,3.9-5.2,7-6.8,10c-1.6,3.1-3.1,6.2-4.9,10.7c2.8-4.1,5.3-6.3,7.8-8.3C173.8,571.6,176.5,569.9,180.5,567.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M174.5,542.4c0.4-3.8-1-7.4-2.8-9.6c-1.9-2.2-4.1-3-5.6-2.2c-1.6,0.9-2.2,2.9-2.2,5.3c0,2.4,0.6,5.1,1.9,7.8
						c1.3,2.9,2.2,5,2.8,7.3c0.6,2.3,1,4.7,0.6,8.2c1.4-3.3,2.3-5.6,3.2-8C173.2,548.8,174.1,546.3,174.5,542.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M59.8,573.1c-3.7,3.4-7.3,6.6-11.1,9.2c-3.7,2.5-7.5,4.5-10.6,4.9c-3.2,0.5-5.8-0.6-7.3-1.7c-1.6-1.1-2.1-2.3-2.6-2.2
						c-0.2,0.1-0.3,0.4-0.3,1.1c0.1,0.7,0.4,1.7,1.1,2.7c0.7,1.1,1.9,2.2,3.5,3c1.6,0.8,3.7,1.3,5.9,1.3c2.2-0.1,4.5-0.6,6.7-1.5
						c2.2-0.9,4.3-2.1,6.4-3.4c4.2-2.8,8-6.4,11.4-10.2c3.5-3.9,6.3-7.3,8.9-10.2c2.6-2.9,5.1-5.4,7.9-7.7c2.7-2.2,5.7-4.2,9.4-5.8
						c3.7-1.5,8.2-2.7,13.6-2c-5.3-1-10-0.1-13.9,1.2c-3.9,1.3-7.2,3.1-10.3,5.1c-3.1,2-5.9,4.3-8.9,7
						C66.7,566.4,63.5,569.6,59.8,573.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M73.8,568.7c-1.9,3-3.2,6.4-4,9.7c-0.8,3.3-0.9,6.4-0.8,9.1c0.4,5.4,2.1,8.6,2.9,8.4c0.9-0.2,0.8-3.6,1.3-8.4
						c0.3-2.4,0.7-5.1,1.4-8c0.7-2.9,1.7-5.9,3-8.8c1.4-3,2.8-5.4,4.4-7.4c1.6-2,3.2-3.7,4.9-5.3c1.8-1.5,3.7-3,6-4.3
						c2.3-1.3,5.1-2.5,8.6-3.4c-3.5,0.6-6.4,1.6-9,2.6c-2.5,1.1-4.7,2.2-6.8,3.5c-2.1,1.3-4,2.8-6.1,4.7
						C77.8,563.3,75.8,565.6,73.8,568.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M82.3,565.7c-1.2,2.3-2,4.8-2.4,7.2c-0.4,2.4-0.3,4.7,0,6.6c0.6,3.9,2.2,6.1,3,5.9c0.9-0.2,0.9-2.7,1.2-6
						c0.1-1.7,0.4-3.6,0.7-5.6c0.4-2,0.9-4.2,1.5-6.3c0.7-2.2,1.5-4,2.4-5.5c0.9-1.6,1.8-2.9,2.9-4.2c1.1-1.3,2.3-2.5,3.8-3.7
						c1.5-1.2,3.3-2.3,5.7-3.3c-2.4,0.8-4.4,1.7-6.1,2.6c-1.7,0.9-3.2,1.9-4.6,3c-1.4,1.1-2.7,2.3-4.1,3.8
						C84.9,561.6,83.5,563.4,82.3,565.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M126.8,555.4c3.6,0.7,7.5,0,9.9-1.3c2.5-1.3,3.7-2.8,3.3-3.6c-0.4-0.8-2.2-0.7-4.6-0.4c-2.4,0.3-5.3,0.6-8.5,0.9
						c-3.2,0.2-5.5,0-7.8-0.4c-2.3-0.5-4.6-1.3-7.6-3.4c2.6,2.5,4.7,3.9,6.9,5.1C120.8,553.5,123.1,554.6,126.8,555.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M138.6,563.1c1.7,1.4,3.4,2.4,4.9,3.1c1.5,0.7,2.8,0.9,3.4,0.3c0.6-0.6,0.4-2-0.8-3.5c-1.1-1.5-3.1-3.1-5.5-3.8
						c-2.4-0.7-4.2-0.9-5.9-1c-1.7-0.1-3.4-0.2-5.8-0.2c2.4,0.5,3.9,1.2,5.3,2C135.6,560.8,136.9,561.7,138.6,563.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M141,578.8c0.3,2.1,1,4.1,1.8,5.5c0.8,1.5,1.7,2.4,2.6,2.3c0.9-0.1,1.5-1.4,1.5-3.2c0.1-1.8-0.5-4.2-1.8-6.2
						c-1.3-2.1-2.4-3.4-3.5-4.6c-1.1-1.2-2.2-2.5-3.8-4.2c1.2,2.1,1.7,3.6,2.2,5.2C140.4,575.1,140.7,576.6,141,578.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M143.4,505.7c0.1-2,0.1-3.7-0.1-5.2c-0.2-1.5-0.5-2.7-1.3-3c-0.8-0.3-2,0.5-2.8,2.3c-0.8,1.7-1,4.4-0.2,6.7
						c0.9,2.4,1.9,3.8,3,5.1c1.1,1.3,2.2,2.5,4,4c-1.5-1.8-2-3.4-2.3-4.8C143.4,509.2,143.3,507.7,143.4,505.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M158.8,498.6c0.9-1.8,1.7-3.4,2.3-4.9c0.6-1.4,0.9-2.7,0.4-3.4c-0.5-0.7-2.1-0.5-3.6,0.8c-1.6,1.3-3.1,3.7-3.3,6.4
						c-0.2,2.8,0.3,4.6,0.9,6.3c0.6,1.7,1.4,3.3,2.8,5.3c-1-2.3-1.1-4-0.9-5.5C157.5,501.9,157.9,500.4,158.8,498.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M187.9,576c2.7-0.4,5.2-1.1,7.1-1.9c1.9-0.8,3.2-1.7,3.1-2.6c-0.1-0.9-1.6-1.5-3.8-1.5c-2.2,0-5.1,0.5-7.7,1.8
						c-2.7,1.3-4.4,2.5-6.1,3.6c-1.8,1.2-3.5,2.4-5.8,4c2.7-1.2,4.6-1.8,6.5-2.3C183.2,576.8,185.2,576.4,187.9,576z"
                    />
                  </g>
                  <g>
                    <path
                      d="M149,392.3c3,0.1,5.8-1,7.6-2.1c1.8-1.1,2.7-2.4,2.4-3.2c-0.3-0.8-1.9-1-3.9-0.7c-2,0.3-4.4,1-6.7,1.7
						c-2.4,0.8-4.2,1-6,0.9c-1.8-0.1-3.8-0.5-5.9-2.5c1.7,2.3,3.6,3.4,5.4,4.3C143.8,391.6,145.9,392.2,149,392.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M134,321c-1.8,2.8-3.2,6-3.8,9.2c-0.6,3.2-0.5,6.3,0.2,8.9c0.7,2.6,1.8,4.5,2.9,5.6c0.5,0.6,1,0.9,1.4,1.1
						c0.4,0.2,0.7,0.2,0.8,0.1c0.7-0.6-0.7-3.3-1-7.6c-0.1-2.1,0-4.6,0.5-7.2c0.5-2.6,1.5-5.3,2.8-8c1.3-2.8,2.7-5.1,4-7.1
						c1.3-2.1,2.6-3.9,3.9-5.7c1.3-1.9,2.6-3.7,4-5.8c1.4-2.1,2.9-4.5,4.3-7.6c-1.6,2.9-3.4,5.1-5,7.1c-1.6,1.9-3.2,3.5-4.8,5.2
						c-1.6,1.6-3.1,3.2-4.8,5.1C137.7,315.9,135.8,318.1,134,321z"
                    />
                  </g>
                  <g>
                    <path
                      d="M143.2,322.4c-0.7,5.5,0.4,11.2,2,15.1c1.6,3.9,3.6,6,4.4,5.6c0.8-0.4,0.3-3.1-0.4-6.8c-0.7-3.8-1.4-8.7-1.7-13.7
						c-0.2-5.2,0.2-8.9,1-12.5c0.8-3.6,2-7.3,5-12.1c-3.4,4.5-5.2,8-6.8,11.5C145.2,313,143.9,316.7,143.2,322.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M149.7,316.6c0,4,1.3,7.8,2.8,10.4c1.5,2.6,3.2,3.9,3.9,3.4c0.8-0.4,0.5-2.4-0.1-5c-0.6-2.6-1.5-6-2.3-9.4
						c-0.9-3.5-1.2-6.1-1.4-8.7c-0.1-2.6,0-5.3,1-9.2c-1.5,3.7-2.3,6.3-2.9,9C150.2,309.8,149.7,312.5,149.7,316.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M93.8,341.5c3.2-2.6,5.7-6,7.1-8.8c1.4-2.8,1.7-5,0.9-5.4c-0.8-0.4-2.4,0.9-4.4,3c-2,2.1-4.4,5-6.8,8.1
						c-2.4,3.1-4.3,5.2-6.3,7.2c-2,2-4.2,3.8-7.8,6.1c3.8-1.8,6.4-3.2,8.9-4.6C88,345.7,90.5,344.2,93.8,341.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M102.3,347.6c2.7-0.3,5.3-1,7.2-1.7c1.9-0.8,3.2-1.7,3.1-2.5c-0.1-0.9-1.6-1.5-3.8-1.6c-2.2-0.1-5.1,0.4-7.7,1.7
						c-2.7,1.3-4.4,2.4-6.2,3.5c-1.8,1.2-3.6,2.3-5.9,3.8c2.7-1.2,4.7-1.7,6.6-2.1C97.6,348.2,99.5,347.9,102.3,347.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M119.4,246.7c1.9-1.6,3.5-3.5,4.5-5.2c1-1.6,1.4-3,0.8-3.7c-0.6-0.6-2.1-0.3-3.8,0.8c-1.7,1.1-3.6,3-4.9,5.4
						c-1.3,2.4-2,4.2-2.8,5.9c-0.8,1.9-1.5,3.7-2.5,6c1.5-2.3,2.8-3.7,4.1-5C116.1,249.6,117.4,248.4,119.4,246.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M20.7,384.4c-7.8,3.2-14.9,7.9-19.7,12.1c-4.8,4.2-7.2,7.7-6.7,8.3c0.6,0.7,4.1-1.6,9.2-5c5.1-3.3,11.9-7.6,19-11.4
						c7.3-3.9,12.7-6.1,18.2-8c5.5-1.8,11.3-3.3,20-4.3c-8.8,0.4-14.7,1.3-20.5,2.4C34.5,379.7,28.7,381.1,20.7,384.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M27.1,386.9c-6.2,3.1-11.7,7.5-15.3,11.3c-3.6,3.8-5.4,6.9-4.8,7.4c0.7,0.6,3.5-1.3,7.5-4.3c4-3,9.3-6.9,14.8-10.6
						c5.7-3.8,9.8-6.1,14.1-8.2c4.3-2,8.9-3.9,15.8-5.7c-7.1,1.2-11.9,2.5-16.5,3.9C38.1,382.1,33.5,383.7,27.1,386.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-19.8,372.5c-3.2-2.4-6.3-4.7-8.9-6.3c-2.5-1.6-4.5-2.5-5.1-1.9c-0.6,0.6,0.3,2.7,2.4,5.1c2.1,2.4,5.5,5.1,9.3,6.9
						c4,1.8,6.8,2.7,9.6,3.6c2.9,0.8,5.7,1.6,10,2.5c-4.1-1.4-6.8-2.8-9.3-4.2C-14.2,376.7-16.6,375-19.8,372.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-14.5,384.4c-0.9,0-1.8,0.2-2.7,0.6c-0.9,0.3-1.9,1-2.5,2c-0.6,1-0.6,2.1-0.3,2.7c0.3,0.7,0.9,0.8,1.3,0.7
						c0.4-0.1,0.8-0.4,1.2-0.5c0.4-0.1,0.7-0.1,1-0.1c0.2,0.1,0.6,0,1.2-0.2c0.7-0.2,1.4-0.5,2.1-1c1.4-0.9,2.5-1.5,3.6-2
						c1.1-0.5,2.3-0.9,4.1-1.2c-1.6-0.2-2.9-0.5-4.4-0.7C-11.3,384.6-12.6,384.4-14.5,384.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-13.6,395.2c-2.1,1.6-3.6,3.7-4.4,5.6c-0.7,1.8-0.7,3.3,0,3.8c0.8,0.5,2-0.2,3.4-1.4c1.4-1.2,2.9-3,4.1-5
						c1.3-2.1,2.3-3.5,3.4-4.9c1.1-1.4,2.3-2.7,4.3-4.4c-2.3,1.2-4,2-5.6,2.9C-9.8,392.7-11.4,393.6-13.6,395.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-9.5,421.5c-1.3,2.9-0.3,6,1.4,6.3c1.7,0.2,3.5-2.6,2.9-5.7c-0.5-3.2-1.1-4.4-1.2-7.7C-7.4,417.5-8.2,418.6-9.5,421.5
						z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-2.1,415.6c-1.2,2.5-0.2,5.3,1.5,5.5c1.7,0.2,3.4-2.2,2.8-4.9c-0.6-2.8-1.2-3.9-1.4-6.7C0,412.2-0.9,413.1-2.1,415.6z
						"
                    />
                  </g>
                  <g>
                    <path
                      d="M578.1,305.9c3.3-4.2,6.4-8.7,8.9-13.6c2.5-4.9,4.2-10.3,4.9-15.7c0.7-5.4,0.4-10.9-1.2-15.8c-0.4-1.2-0.8-2.4-1.3-3.6
						c-0.5-1.2-1.1-2.3-1.8-3.4c-1.4-2.2-3.1-4.2-5.3-5.7c-1.1-0.8-2.3-1.3-3.6-1.8c-1.3-0.4-2.7-0.6-4-0.5
						c-2.5,0.1-4.9,0.9-6.8,2.5c-0.9,0.8-1.7,1.7-2.3,2.7c-0.6,1-0.8,2.1-1,3.1c-0.2,2,0.2,3.7,0.7,5c1.1,2.5,2.5,3.9,3.5,4.8
						c1,0.9,1.7,1.1,2.1,0.9c0.4-0.2,0.3-1,0.1-2.2c-0.2-1.2-0.8-2.9-1-4.7c-0.1-0.9,0-1.8,0.3-2.5c0.2-0.3,0.4-0.7,0.6-0.9
						c0.2-0.2,0.5-0.4,0.9-0.6c0.7-0.3,1.7-0.5,2.7-0.3c0.5,0.1,1,0.2,1.4,0.4c0.4,0.2,0.9,0.6,1.4,1c0.9,0.8,1.8,2,2.5,3.4
						c0.7,1.4,1.3,3.2,1.8,5c0.9,3.6,1,7.7,0.4,12c-0.6,4.2-1.9,8.6-3.8,12.8c-1.9,4.3-4.4,8.5-7.2,12.6c-2.9,4.3-5.8,8.2-8.5,11.9
						c-2.7,3.7-5.2,7.3-7.5,10.8c-2.2,3.5-4.2,7-5.8,10.6c-1.6,3.6-2.8,7.2-3.7,10.9c-1.7,7.4-2.1,15.1-0.8,23.5
						c0.6,4.2,1.7,8.5,3.4,13c1.7,4.4,4,9,7.3,13.5c-3-4.6-5.1-9.3-6.5-13.7c-1.4-4.5-2.1-8.8-2.4-12.9c-0.6-8.2,0.5-15.5,2.8-22.3
						c1.2-3.4,2.6-6.6,4.4-9.7c1.8-3.1,4-6.2,6.4-9.3c2.4-3.1,5.2-6.3,8.2-9.7C571.4,313.8,574.7,310.2,578.1,305.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M561.9,353.7c1-1.7,2-3.3,3.1-4.8c1.1-1.5,2.2-2.8,3.4-4.1c1.1-1.3,2.4-2.4,3.5-3.4c0.3-0.2,0.6-0.5,0.9-0.7
						c0.3-0.2,0.5-0.4,0.8-0.6c0.5-0.4,1.1-0.7,1.6-1c2.2-1.2,4-1.8,5.4-2c1.5-0.3,2.7-0.1,3.3-0.7c0.3-0.3,0.4-0.8,0.2-1.5
						c-0.2-0.7-0.8-1.5-1.9-2.3c-1-0.8-2.5-1.4-4.3-1.6c-1.7-0.2-3.7-0.1-5.6,0.3c-1,0.2-2,0.6-3,1c-0.5,0.2-1,0.5-1.5,0.7
						c-0.5,0.3-0.9,0.5-1.4,0.8c-1.8,1.1-3.7,2.5-5.3,4.2c-1.6,1.7-3.1,3.6-4.4,5.6c-1.2,2.1-2.2,4.3-3,6.5
						c-1.6,4.7-2.1,8.7-2.3,12.3c-0.2,3.6,0,6.8,0.3,9.9c0.7,6.3,1.9,12.4,3.8,21.4c-0.9-9.1-0.8-15.4,0-21.4c0.4-3,1-5.9,1.9-9
						C558.6,360.4,559.9,357.1,561.9,353.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M574.5,279.9c-0.6-1.9-1.5-3.7-2.6-5.3c-0.6-0.8-1.2-1.6-1.9-2.3c-0.7-0.7-1.5-1.3-2.3-1.9c-1.7-1.1-3.9-1.8-6.2-1.5
						c-1.1,0.2-2.1,0.5-3,1.1c-0.9,0.6-1.6,1.4-2.1,2.4c-0.4,0.9-0.6,1.9-0.6,2.8c0,0.9,0.2,1.6,0.5,2.3c0.5,1.3,1.2,2.1,1.8,2.7
						c1.2,1.2,2.1,1.6,2.4,1.4c0.4-0.3,0.1-1.2-0.4-2.6c-0.2-0.7-0.5-1.5-0.6-2.4c-0.1-0.9,0.1-1.5,0.5-2c0.4-0.4,1.1-0.8,2-0.9
						c0.8-0.1,1.9,0.3,2.9,1c1.1,0.7,2.1,1.8,3.1,2.9c0.9,1.2,1.7,2.5,2.4,3.9c1.3,3,1.8,5.9,1.9,8.5c0.1,2.6-0.2,5.1-0.8,7.5
						c-1.1,4.8-3.2,9.6-6.8,16.2c4.1-6.4,6.7-10.8,8.6-15.6c0.9-2.4,1.7-4.9,2-7.9C575.6,287.2,575.6,283.8,574.5,279.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M568.9,290c-0.3-0.5-0.5-1.1-0.8-1.6c-0.3-0.5-0.7-1.1-1.2-1.5c-0.9-1-1.9-1.8-3.4-2.3c-0.7-0.3-1.5-0.4-2.3-0.4
						c-0.8,0-1.6,0.3-2.3,0.6c-1.4,0.7-2.2,1.8-2.6,2.8c-0.5,1-0.6,2-0.6,2.7c0,0.8,0.1,1.4,0.3,1.9c0.3,1,0.7,1.5,1.2,1.5
						c0.4,0,0.9-0.5,1.3-1.4c0.4-0.8,0.9-2,1.5-2.7c0.3-0.4,0.7-0.6,1-0.7c0.3,0,0.5,0,0.9,0.1c0.4,0.2,1,0.5,1.6,1.1
						c0.3,0.3,0.6,0.5,0.8,0.9c0.3,0.3,0.5,0.7,0.8,1.1c1.1,1.5,1.8,3,2.3,4.5c0.5,1.4,0.8,2.8,0.9,4.2c0.1,1.4,0.1,2.9-0.3,4.5
						c-0.3,1.6-0.9,3.4-2.1,5.3c1.4-1.7,2.3-3.4,3-5c0.7-1.6,1.1-3.1,1.3-4.7c0.2-1.6,0.4-3.1,0.2-4.9
						C570.3,294.2,569.9,292.2,568.9,290z"
                    />
                  </g>
                  <g>
                    <path
                      d="M548.8,301.3c-1.9-0.3-3.8-0.3-5.2,0c-1.5,0.3-2.6,0.9-2.7,1.8c-0.2,0.9,0.7,1.8,2.3,2.4c1.6,0.6,3.8,0.8,5.9,0.2
						c2.2-0.6,3.6-1.2,5-1.9c1.5-0.7,2.9-1.4,4.7-2.2c-2.1,0.4-3.6,0.5-5.1,0.4C552.1,301.9,550.7,301.7,548.8,301.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M536.3,308.9c-5.8,0.2-11.1,2.8-10.6,4.5c0.4,1.7,6.2,1.7,11.5-0.2c5.5-1.9,7.5-3,13.4-4.3
						C544.5,309.2,542.3,308.8,536.3,308.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M525.6,316.8c-3.9-0.7-7.6,0.8-7.6,2.5c0.1,1.8,4,2.9,7.8,1.8c3.9-1.1,5.3-1.9,9.3-2.6
						C531,318.2,529.6,317.5,525.6,316.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M537.6,319c-2.2,1.1-3.9,2.9-4.8,4.6c-0.9,1.6-1.1,3.1-0.4,3.6c0.7,0.6,2,0.2,3.4-0.7c1.5-0.9,3.1-2.3,4.5-4
						c1.5-1.7,2.6-2.9,3.8-4.1c1.2-1.1,2.5-2.2,4.6-3.4c-2.4,0.7-4,1.3-5.6,1.8C541.5,317.3,539.9,317.8,537.6,319z"
                    />
                  </g>
                  <g>
                    <path
                      d="M545.3,322.1c-1.5,1.4-2.5,3.1-2.9,4.6c-0.4,1.5-0.2,2.7,0.6,3.2c0.8,0.4,1.9,0,3-1c1.1-1,2.2-2.5,2.9-4.3
						c1.5-3.7,1.8-5.3,4.1-8.8C549.9,318.6,548.4,319.3,545.3,322.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M554.7,308c-1.3,0.9-2.1,2.2-2.4,3.4c-0.3,1.2,0,2.2,0.7,2.7c0.7,0.5,1.8,0.3,2.7-0.3c1-0.6,1.9-1.7,2.4-3
						c1.1-2.8,1.3-4.1,3.2-6.7C558.7,305.8,557.4,306.2,554.7,308z"
                    />
                  </g>
                  <g>
                    <path
                      d="M532.3,341c-1.2,1.5-1.8,3.3-1.8,4.8c0,1.5,0.5,2.5,1.4,2.7c0.9,0.2,1.8-0.4,2.6-1.5c0.8-1,1.5-2.5,1.8-4.2
						c0.3-1.7,0.5-3,0.9-4.2c0.3-1.2,0.8-2.4,1.7-4.1c-1.4,1.3-2.3,2.2-3.3,3.1C534.5,338.6,533.5,339.5,532.3,341z"
                    />
                  </g>
                  <g>
                    <path
                      d="M533.2,356.5c-2.1,3.9-1.8,8.5-0.1,8.9c1.7,0.4,3.9-3.4,4.3-7.6c0.4-4.4,0.2-6.1,1.4-10.5
						C536.7,351.3,535.4,352.5,533.2,356.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M533.9,379.3c-0.4,2.4,0.2,4.9,1,6.6c0.8,1.7,2,2.6,2.8,2.3c0.8-0.3,1.2-1.6,1.3-3.2c0.1-1.7-0.2-3.7-0.7-5.8
						c-0.5-2.2-0.8-3.7-1-5.4c-0.2-1.6-0.2-3.3,0.2-5.7c-0.9,2.3-1.5,3.8-2.1,5.5C534.8,375.2,534.2,376.8,533.9,379.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M570.2,359.3c1.9-2.2,3.7-4.2,5-6c1.3-1.7,2.2-3.2,1.7-3.9c-0.5-0.7-2.3-0.5-4.4,0.8c-2.2,1.3-4.6,3.7-6,6.8
						c-1.4,3.1-1.9,5.4-2.3,7.6c-0.4,2.2-0.6,4.5-0.6,7.8c0.5-3.2,1.4-5.3,2.4-7.2C567.1,363.2,568.3,361.5,570.2,359.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M580.7,319.9c1.7-0.7,3-1.7,4-2.7c0.9-1,1.4-2.1,1.1-2.9c-0.4-0.8-1.6-1.1-3.1-0.8c-1.5,0.3-3.3,1.3-4.6,2.8
						c-1.3,1.6-2.1,2.8-2.8,4c-0.8,1.3-1.5,2.6-2.4,4.1c1.4-1.4,2.6-2.1,3.8-2.7C577.8,321.1,579,320.6,580.7,319.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-6.5,258.1c1.9-0.4,3.5-1.1,4.7-2c1.2-0.9,1.9-1.9,1.6-2.7c-0.2-0.8-1.4-1.3-3-1.2c-1.6,0.1-3.5,0.7-5.2,2
						c-1.7,1.3-2.7,2.3-3.8,3.3c-1,1-2.1,2-3.7,3.4c1.9-0.9,3.2-1.4,4.6-1.7C-9.8,258.7-8.5,258.5-6.5,258.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M8.4,246.2c-1.5,2.6-2.1,5.6-2,7.8c0.1,2.3,0.8,3.7,1.7,3.8c0.9,0.1,1.7-1.2,2.5-3.1c0.8-1.9,1.5-4.3,2-6.9
						c0.5-2.7,1.1-4.5,1.7-6.4c0.7-1.8,1.5-3.7,3.3-6.1c-2.1,2-3.5,3.5-4.9,5.1C11.2,242,9.9,243.6,8.4,246.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M544.8,223.7c-1.8-1.5-3.8-2.7-6-3.5c-2.1-0.9-4.5-1.4-6.9-1.4c-1.2,0-2.4,0.2-3.5,0.6c-1.2,0.4-2.3,0.9-3.2,1.6
						c-1.9,1.4-3.2,3.3-3.7,5.3c-0.3,1-0.3,2-0.2,3.1c0.2,1,0.5,1.8,0.9,2.5c0.9,1.4,2.1,2.3,3.1,2.7c1.1,0.4,2,0.4,2.6,0.2
						c0.6-0.2,0.8-0.4,0.8-0.6c-0.1-0.5-1.1-0.6-2.2-1.7c-1.1-1-2.1-3.1-1.2-5c0.4-1,1.3-2,2.4-2.7c0.6-0.4,1.2-0.6,1.9-0.8
						c0.7-0.2,1.5-0.3,2.2-0.3c3.2,0,7,1.4,10.2,3.5c3.3,2.2,5.7,4.8,7.4,7.5c0.9,1.3,1.6,2.7,2.2,4c0.6,1.4,1.1,2.7,1.5,4.1
						c0.8,2.8,1.2,5.8,1.2,9.2c0,3.4-0.4,7.1-1.6,11.5c1.4-4.3,2.2-8,2.5-11.5c0.3-3.4,0.2-6.5-0.3-9.6c-0.3-1.5-0.6-3.1-1-4.6
						c-0.5-1.5-1.1-3.1-1.9-4.7C550.7,230.1,548.4,226.7,544.8,223.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M547.9,236.8c-0.9-1.3-2.2-2.4-3.6-3.2c-1.5-0.8-3.3-1.2-5-1c-1.8,0.2-3.4,1.2-4.5,2.4c-1.1,1.2-1.7,2.9-1.6,4.4
						c0.1,1.7,1,2.9,1.8,3.6c0.9,0.7,1.9,1.1,2.7,1.1c0.8,0,1.4-0.2,1.8-0.5c0.3-0.3,0.4-0.6,0.3-0.8c-0.2-0.4-0.9-0.6-1.4-1.1
						c-0.3-0.3-0.6-0.6-0.8-1.1c-0.2-0.4-0.3-0.9-0.1-1.2c0.1-0.4,0.4-0.8,0.8-1.2c0.5-0.4,1.1-0.7,1.7-0.7c1.3-0.2,3.2,0.6,4.7,2
						c1.6,1.5,2.6,3.5,3.6,5.3c0.9,1.8,1.7,3.4,2.3,5.1c0.6,1.7,1.2,3.5,1.6,5.5c0.4,2,0.7,4.4,0.8,7.1c0.2-2.8,0.2-5.1,0.1-7.2
						c-0.1-2.1-0.3-4-0.6-5.9c-0.3-1.9-0.7-3.7-1.3-5.8c-0.3-1-0.6-2.1-1.1-3.3C549.5,239.4,548.9,238.1,547.9,236.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M613.1,514.1c0.5,1.2,0.7,2.4,0.8,3.5c0,1.1-0.1,2-0.4,2.7c-0.3,0.7-0.7,1.4-1.3,2.1c-0.6,0.6-1.4,1.3-2.4,1.7
						c-1.9,1-4,1.4-6.1,1.3c-2-0.1-3.6-0.8-4.7-1.5c-1.2-0.9-2-2.1-2.3-3.2c-0.4-1-0.3-2.3,0.1-3.4c0.4-1.1,0.9-2,1.4-2.8
						c0.5-0.8,0.9-1.4,0.9-2.1c0-0.6-0.6-1.3-1.9-1.6c-1.3-0.3-3.3,0-5.5,1.3c-1.1,0.7-2.1,1.6-3.1,2.9c-0.5,0.7-0.9,1.4-1.2,2.2
						c-0.4,0.8-0.6,1.8-0.8,2.7c-0.7,3.8,0.2,8.2,2.9,11.9c2.8,3.9,7.2,6.5,12,7.4c4.7,1,10.1,0.5,14.9-1.9c2.4-1.2,4.7-2.8,6.7-5
						c2-2.2,3.6-4.9,4.4-8c0.8-3,0.8-6.2,0.3-9c-0.6-2.9-1.7-5.4-3.1-7.7c-1.5-2.3-3.3-4.3-5.2-5.9c-1.9-1.6-4.1-2.9-6.1-3.7
						c-4.1-1.7-8-2.3-11.5-2.4c-7.1-0.1-12.9,1.4-18.6,3.1c-5.6,1.8-11.1,4.1-16.8,7.4c-5.7,3.2-11.6,7.4-17.6,13.4
						c6.6-5.4,12.9-8.7,18.8-11c5.9-2.3,11.4-3.5,16.8-4.2c5.4-0.6,11-0.7,16.3,0.5c2.7,0.6,5.3,1.6,7.5,3.1c1.2,0.8,2,1.6,2.8,2.6
						C611.9,511.7,612.6,512.9,613.1,514.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M586.9,487.9c2.8-1.8,5.2-4.2,7.1-6.8c1.9-2.6,3.2-5.3,3.9-7.8c0.7-2.5,0.8-4.7,0.6-6.3c-0.3-1.6-0.9-2.5-1.8-2.7
						c-1.8-0.3-3.7,2.1-6.3,5.3c-1.3,1.7-2.7,3.5-4.3,5.4c-1.6,2-3.3,4.1-5.1,6.3c-1.8,2.3-3.6,4.1-5.2,5.7
						c-1.7,1.6-3.2,3.1-4.9,4.4c-3.3,2.7-6.9,5.3-12.6,8.5c6.1-2.3,10.3-3.7,14.5-5.2c2.1-0.8,4.2-1.6,6.5-2.7
						C581.6,491.1,584.1,489.8,586.9,487.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M574,520.4c-1.2,2.3-1.3,5.1-0.8,7c0.5,1.9,1.5,3,2.4,2.8c0.9-0.2,1.4-1.4,1.8-3c0.4-1.6,0.7-3.5,0.8-5.6
						c0.2-2.2,0.5-3.7,1-5.3c0.5-1.5,1.3-3,2.9-4.9c-2,1.4-3.3,2.6-4.5,3.9C576.4,516.6,575.3,518,574,520.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M580.2,543.9c0.4,2.6,1.7,5,3.2,6.4c1.5,1.4,2.9,1.9,3.6,1.3c0.7-0.6,0.5-2,0-3.6c-0.5-1.6-1.4-3.6-2.5-5.5
						c-1.1-2-1.8-3.6-2.3-5.2c-0.5-1.6-0.9-3.3-0.8-5.9c-0.6,2.5-0.9,4.3-1.1,6.1C580,539.4,579.8,541.2,580.2,543.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M599.3,558c1.3,1.4,3,2.4,4.7,2.8c1.7,0.5,3.4,0.5,4.8,0.2c1.4-0.3,2.4-0.9,3-1.4c0.6-0.6,0.8-1.1,0.7-1.5
						c-0.4-0.8-2-0.9-3.9-1.2c-2-0.3-4.3-0.9-6.5-2.2c-1.2-0.7-2.1-1.5-2.9-2.3c-0.8-0.8-1.4-1.7-2-2.6c-1.2-1.8-2.1-3.9-3.1-7.1
						c0.5,3.3,0.8,5.6,1.4,7.8c0.3,1.1,0.6,2.3,1.2,3.5C597.2,555.2,598,556.6,599.3,558z"
                    />
                  </g>
                  <g>
                    <path
                      d="M613.7,553.2c2.2,1.1,4.7,1.3,6.5,1c1.8-0.3,3-1.2,2.9-2.1c-0.1-0.9-1.2-1.6-2.7-2.1c-1.5-0.5-3.5-0.9-5.5-1
						c-2.2-0.2-3.7-0.4-5.2-0.7c-1.5-0.4-3.1-0.9-5.1-2.2c1.7,1.7,2.9,2.8,4.2,3.9C610.1,551,611.5,552.1,613.7,553.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M623.6,545.9c1.5,0.1,2.9-0.6,3.8-1.4c0.8-0.8,1.1-1.9,0.7-2.6c-0.4-0.8-1.3-1.2-2.4-1.2c-1,0-2.2,0.3-3.3,1
						c-1.1,0.8-1.9,1.3-2.8,1.8c-0.9,0.5-1.8,0.9-3.2,1.2c1.4,0.3,2.5,0.5,3.5,0.7C620.9,545.6,622,545.8,623.6,545.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M584.7,454.4c0.8-4,0.4-8.1-0.6-11.1c-0.9-2.9-2.3-4.6-3.1-4.4c-0.9,0.3-1.1,2.3-1,5c0,2.8,0.2,6.4,0.3,10.1
						c0.1,3.8,0,6.5-0.4,9.1c-0.4,2.7-1,5.4-2.7,9.1c2.2-3.5,3.4-6,4.5-8.6C582.9,461.2,583.9,458.5,584.7,454.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M175.8,236.9c-0.3,3.3,0.7,6.7,2.2,8.7c1.4,2.1,3.1,2.9,3.7,2.4c0.7-0.5,0.5-2.1,0.1-4.1c-0.5-2.3-1-4.4-1.6-7.2
						c-0.5-2.8-0.6-4.8-0.4-6.9c0.2-2,0.7-4.2,2.3-6.9c-2.1,2.4-3.2,4.4-4.2,6.4C177,231.3,176.2,233.5,175.8,236.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M316.4,56.6c3.3,0.8,6.5,1.5,9,2c2.5,0.5,4.4,0.7,4.8-0.1c0.4-0.7-0.8-2.4-3.3-3.8c-2.5-1.5-6.3-2.6-10.1-2.5
						c-3.9,0.1-6.5,0.7-9.1,1.5c-2.5,0.8-5,1.6-8.5,3.4c3.6-1.2,6.2-1.4,8.7-1.5C310.5,55.6,313,55.9,316.4,56.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M166.4,149.7c1.4,3.4,3.9,6.3,6.1,7.9c2.2,1.7,4.1,2.1,4.7,1.5c0.6-0.7-0.3-2.3-1.7-4.4c-1.4-2.1-3.3-4.6-5.3-7.2
						c-2.1-2.7-3.3-4.7-4.4-6.9c-1.1-2.2-2-4.5-2.6-8.1c0.1,3.7,0.4,6.2,0.8,8.7C164.4,143.7,165,146.2,166.4,149.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M79.1,161.1c1.1-2.5,1.1-5.4,0.4-7.4c-0.7-2-1.8-3-2.6-2.8c-0.9,0.3-1.3,1.6-1.5,3.2c-0.3,1.7-0.4,3.7-0.5,5.9
						c-0.1,2.3-0.4,3.9-0.9,5.5c-0.5,1.6-1.3,3.2-3.1,5.1c2.1-1.5,3.4-2.8,4.7-4.2C76.7,165.2,77.9,163.7,79.1,161.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M340.8,260.3c3.5-6.8,4.5-14.6,2.8-15.1c-1.7-0.5-5.1,6.3-6.9,13.5c-0.9,3.8-1.5,6.4-2.2,9c-0.7,2.6-1.5,5.2-2.9,9
						c1.9-3.5,3.3-5.9,4.7-8.3C337.6,266.1,339,263.8,340.8,260.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M485,235.3c3.3,0,6.5-0.3,9-0.8c2.4-0.5,4.1-1.2,4.2-2.1c0-0.9-1.7-1.7-4.2-2.1c-2.6-0.4-6.1-0.3-9.4,0.6
						c-3.4,0.9-5.7,1.8-8,2.7c-2.4,1-4.7,1.9-7.8,3.1c3.5-0.8,5.9-1.1,8.3-1.3C479.3,235.3,481.6,235.3,485,235.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M596.5,141.5c-2.7,0.3-5.3,1.3-7,2.5c-1.7,1.2-2.6,2.4-2.2,3.2c0.4,0.8,1.9,0.9,3.8,0.6c1.9-0.3,4.3-1,6.5-2.1
						c2.4-1.1,4-1.9,5.8-2.5c1.7-0.7,3.5-1.2,6.3-1.7c-2.6-0.1-4.5-0.1-6.6-0.2C601.2,141.3,599.3,141.2,596.5,141.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M548.5,79c-2.4,1.8-4.6,3.6-6.2,5.2c-1.6,1.6-2.6,2.9-2.2,3.7c0.4,0.8,2.2,0.8,4.5-0.2c2.3-1,5.1-3,7.1-5.6
						c2-2.7,3.1-4.8,4-6.9c0.9-2.1,1.8-4.2,2.9-7.4c-1.5,3-3,4.7-4.5,6.3C552.6,75.7,550.9,77.1,548.5,79z"
                    />
                  </g>
                  <g>
                    <path
                      d="M482.8,101.2c3.5-0.6,7-1.5,9.5-2.4c2.6-0.9,4.3-1.9,4.2-2.8c-0.1-0.9-2.1-1.4-4.9-1.3c-2.8,0.1-6.6,0.8-10,2.3
						c-3.6,1.5-5.9,2.8-8.2,4.1c-2.3,1.3-4.6,2.7-7.9,4.7c3.6-1.5,6.1-2.3,8.6-2.9C476.6,102.3,479.2,101.9,482.8,101.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M503.3,526.1c2.5-1.7,3.3-4.9,2-6c-1.4-1.1-4.3,0.4-5.4,3.3c-1.2,2.9-1.3,4.3-2.8,7.1
						C499.4,528.3,500.7,527.9,503.3,526.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M281.3,518.4c2,0.3,3.8,0.4,5.3,0.2c1.5-0.1,2.7-0.5,3-1.3c0.3-0.8-0.5-2-2.2-2.8c-1.6-0.8-4.1-1.2-6.5-0.5
						c-2.4,0.7-3.9,1.5-5.3,2.3c-1.4,0.9-2.8,1.8-4.6,3.2c2.1-1,3.6-1.3,5.1-1.4C277.8,518,279.3,518.1,281.3,518.4z"
                    />
                  </g>
                </g>
              </g>
              <g>
                <defs>
                  <path
                    id="SVGID_00000018227207791758164020000004106150349657503406_"
                    d="M120.2,262.5c41.6-1.8,220.4,16.7,221.8,48.9
					c1.4,32.2-175.2,66-216.8,67.7C83.7,381,19.2,353.2,18,325.3C16.8,297.4,78.7,264.3,120.2,262.5z"
                  />
                </defs>
                <clipPath id="SVGID_00000140723151496104734020000016582944924931742103_">
                  <use
                    xlinkHref="#SVGID_00000018227207791758164020000004106150349657503406_"
                    overflow="visible"
                  />
                </clipPath>
              </g>
            </g>
          </g>
          <g id="Peddle6">
            <image
              overflow="visible"
              opacity="0.7"
              width="1390"
              height="1461"
              xlinkHref={Shadow3}
              transform="matrix(0.24 0 0 0.24 48.1493 224.9764)"
            ></image>
            <g>
              <g>
                <defs>
                  <path
                    id="SVGID_00000027567107102270228060000010077016068969047733_"
                    d="M135.4,401.1c28.1-30.7,167.6-144,191.4-122.2
					c23.8,21.8-77.2,170.5-105.4,201.2c-28.1,30.6-93.3,56.6-113.9,37.7C87,498.9,107.3,431.7,135.4,401.1z"
                  />
                </defs>
                <clipPath id="SVGID_00000133494831426011677000000018313419454495001247_">
                  <use
                    xlinkHref="#SVGID_00000027567107102270228060000010077016068969047733_"
                    overflow="visible"
                  />
                </clipPath>
              </g>
              <g>
                <defs>
                  <path
                    id="SVGID_00000077282089850020705710000018290595878470719423_"
                    d="M135.4,401.1c28.1-30.7,167.6-144,191.4-122.2
					c23.8,21.8-77.2,170.5-105.4,201.2c-28.1,30.6-93.3,56.6-113.9,37.7C87,498.9,107.3,431.7,135.4,401.1z"
                  />
                </defs>
                <use
                  xlinkHref="#SVGID_00000077282089850020705710000018290595878470719423_"
                  overflow="visible"
                  fill="#36B657"
                />
                <clipPath id="SVGID_00000107552194520747973270000000483660439938946464_">
                  <use
                    xlinkHref="#SVGID_00000077282089850020705710000018290595878470719423_"
                    overflow="visible"
                  />
                </clipPath>
                <g
                  opacity="0.2"
                  clipPath="url(#SVGID_00000107552194520747973270000000483660439938946464_)"
                >
                  <g>
                    <g>
                      <path
                        d="M312.1,219.6c8.1-1.2,15.4-3.8,20.8-7c5.4-3.2,8.8-6.9,8.1-10.4c-0.7-3.4-5.5-5.7-12.2-5.8c-6.7-0.1-15.4,2-22.9,6.8
							c-7.7,4.9-12.4,9-17.2,13c-4.7,4-9.4,8-16.3,13.7c8.1-3.8,13.9-5.6,19.8-6.9C297.9,221.7,303.8,220.8,312.1,219.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M314.5,239.9c7,1.8,13.9,2.1,19.5,1.1c5.5-1,9.7-3.2,10.5-6.6c0.8-3.4-2.4-7.3-8.1-9.9c-5.6-2.6-13.8-3.8-21.8-2.1
							c-8.2,1.8-13.5,3.9-18.8,6c-5.3,2.1-10.5,4.2-18,7.1c8-0.8,13.5-0.4,18.9,0.5C302,236.8,307.2,238.1,314.5,239.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M303.9,258.7c4.2,3.4,8.8,5.1,13,5.4c4.2,0.3,8.1-0.9,10-3.9c1.9-3,1.1-7.2-2.1-10.9c-3.1-3.7-8.6-6.8-14.9-7.1
							c-6.5-0.4-10.9,0.2-15.2,0.7c-4.3,0.5-8.6,1-14.6,1.5c5.8,1.7,9.5,3.7,12.9,5.9C296.4,252.6,299.5,255.2,303.9,258.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M282.6,276.1c1.5,4.7,4.5,8,8,9.7c3.4,1.7,7.3,1.9,10.2-0.1c2.9-2,4.1-5.7,3.3-9.8c-0.7-4-3.4-8.3-7.9-11
							c-4.6-2.8-8.1-4.2-11.5-5.8c-3.4-1.6-6.6-3.2-10.9-6.1c2.8,4.3,4.2,7.7,5.3,11.2C280.2,267.7,281.1,271.2,282.6,276.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M262,284.1c-1.5,5-1.3,9.6,0.1,13.5c1.3,3.8,3.8,7,7.3,7.6c3.4,0.7,7.1-1.5,9.3-5.8c2.2-4.2,3-10.5,0.8-16.4
							c-2.3-6-4.6-9.7-6.8-13.4c-2.3-3.6-4.5-7.1-7.6-12.2c1,5.8,0.8,9.9,0.1,13.8C264.5,275.2,263.5,279,262,284.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M238.4,276.8c-4.1,3.3-6.7,7-8.2,10.8c-0.3,1-0.7,1.9-0.9,2.9c-0.2,1-0.3,1.9-0.3,2.8c0,1.8,0.4,3.6,1.4,5
							c1,1.4,2.6,2.3,4.6,2.6c1,0.1,2.1,0.1,3.2-0.1c1.1-0.2,2.4-0.5,3.5-1.1c4.8-2.1,9.8-7.2,11.7-13.9c1.9-6.8,2-11.6,2-16.2
							c-0.1-4.6-0.3-9.1-0.8-15.3c-1.6,6-3.8,9.7-6.4,12.9C245.6,270.5,242.6,273.3,238.4,276.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M221.4,254.8c-5.9,0.7-10.8,2.7-14.6,5.4c-3.7,2.7-6.2,6.2-5.7,9.7c0.5,3.5,4.2,6.1,9.6,6.6
							c5.3,0.5,12.3-1.2,17.7-5.6c5.6-4.6,8.8-8.3,11.8-12c3-3.7,5.9-7.3,10.2-12.6c-5.6,3.6-10,5.2-14.3,6.3
							C231.8,253.5,227.4,254.1,221.4,254.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M214.7,232.3c-5.8-1.9-11.2-2.4-15.9-1.9c-1.2,0.2-2.3,0.3-3.3,0.7c-1.1,0.3-2,0.7-2.9,1.1c-1.7,0.9-3.1,2.2-3.7,3.8
							c-0.6,1.6-0.4,3.5,0.5,5.3c0.5,0.9,1.2,1.9,2,2.7c0.9,0.9,1.9,1.8,3.1,2.5c4.8,3.1,12.4,4.8,19.7,3.2c7.5-1.7,12.3-4,16.9-6.2
							c4.5-2.3,9-4.7,15-8.3c-6.9,1.6-11.7,1.4-16.4,0.8C225.1,235.5,220.6,234.3,214.7,232.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M221.6,214.1c-3.8-4.1-7-7.1-10.2-9.4c-1.6-1.2-3.2-2.2-4.8-3.1c-1.6-0.9-3.3-1.6-5.1-1.4c-1.7,0.1-3.3,1.2-4.4,3.3
							c-1.1,2.1-1.4,5.3-0.6,8.8c0.8,3.5,2.7,7.3,5.7,10.4c2.9,3.1,6.8,5.6,11,6.8c4.4,1.3,7.9,1.6,11.1,1.7
							c3.2,0.1,5.9-0.1,8.6-0.4c5.4-0.6,10.5-1.4,17.9-2.1c-7.3-1.5-12.2-3.2-16.6-5.4c-2.2-1.1-4.3-2.4-6.4-3.9
							C225.6,217.9,223.5,216.2,221.6,214.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M239.5,196.3c-0.1-5.5-0.9-9.8-2.3-13.6c-0.7-1.9-1.5-3.7-2.5-5.3c-1-1.6-2.2-2.9-3.9-3.6c-1.6-0.7-3.5-0.5-5.4,0.6
							c-1.9,1.1-3.7,3.3-4.9,6.3c-1.2,3-1.8,6.7-1.4,10.5c0.4,3.8,1.7,7.8,3.9,11.1c2.3,3.5,4.6,5.9,6.7,7.9c2.2,2,4.2,3.6,6.3,5.1
							c4.1,3,8.3,5.7,14,9.6c-4.3-5.5-6.5-9.9-8-14.2c-0.7-2.2-1.3-4.4-1.8-6.8C239.9,201.7,239.6,199.1,239.5,196.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M259.2,191.2c2.3-5.4,3.1-10.6,2.9-15.2c-0.1-1.2-0.2-2.2-0.4-3.3c-0.2-1.1-0.5-2-0.9-2.9c-0.8-1.8-1.9-3.2-3.5-4
							c-1.6-0.8-3.4-0.8-5.3,0c-1,0.4-2,1-2.9,1.8c-0.9,0.8-1.9,1.7-2.8,2.9c-3.5,4.5-5.8,11.9-4.6,19.3c1.3,7.6,3.3,12.4,5.3,17
							c2.1,4.6,4.3,9,7.7,15c-1.4-6.8-1-11.6-0.1-16C255.5,201.1,256.9,196.9,259.2,191.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M281.1,191.4c4.3-4.1,7.2-8,9.3-11.7c1-1.9,1.9-3.7,2.4-5.6c0.5-1.8,0.7-3.7,0-5.3c-0.6-1.6-2.1-2.9-4.3-3.4
							c-2.2-0.6-5.1-0.3-8.2,0.8c-3.1,1.2-6.3,3.3-8.9,6.2c-2.6,2.9-4.7,6.5-5.8,10.5c-1.2,4-1.6,7.4-1.8,10.4
							c-0.2,3-0.2,5.7-0.2,8.3c0.1,5.2,0.3,10.3,0,17.6c2.4-6.9,4.7-11.5,7.3-15.6c1.3-2.1,2.8-4,4.4-6
							C277.1,195.6,278.9,193.5,281.1,191.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M256.7,233.3c-1.5,3.2,1.5,6,5,5.6c3.5-0.4,5.8-3.7,3.7-6.5c-2.2-2.9-3.7-3.9-5.1-7
							C259.6,228.7,258.2,230,256.7,233.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M261.4,230.6c-1.4,1-1.8,2.3-1.5,3.7c0.3,1.4,1.3,2.8,2.8,3.6c1.5,0.9,3.3,0.9,4.7,0.2c1.4-0.7,2.3-2.2,2.1-4.1
							c-0.2-2-0.6-3.3-0.9-4.7c-0.3-1.3-0.6-2.6-0.7-4.4c-0.9,1.5-1.9,2.4-2.9,3.1C263.9,228.9,262.8,229.6,261.4,230.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M264.9,232.2c-1.3-0.6-2.1-0.2-2.9,0.7c-0.7,0.9-1.4,2.4-1.5,4.2c-0.1,1.7,0.7,3.3,2.1,4.1c1.4,0.8,3.3,0.7,4.8-0.7
							c1.5-1.4,2.2-2.7,2.8-3.8c0.6-1.2,1.2-2.3,2.1-3.6c-1.5,0.5-2.7,0.5-3.8,0.3C267.3,233.2,266.2,232.7,264.9,232.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M267.4,236.8c0-0.7-0.3-1.3-0.7-1.8c-0.5-0.5-1.1-1-1.9-1.2c-0.8-0.3-1.6-0.3-2.4-0.1c-0.8,0.2-1.5,0.6-2.1,1.3
							c-0.6,0.6-1.1,1.3-1.4,2c-0.3,0.7-0.6,1.4-0.6,2c0,0.7,0.1,1.3,0.5,2c0.4,0.6,1.1,1.3,2.1,1.7c1,0.5,2.1,0.8,3,1
							c0.9,0.2,1.7,0.2,2.5,0.1c1.6-0.1,3.1-0.5,4.5-1.6c-0.8,0.1-1.5-0.2-2-0.6c-0.5-0.4-0.8-0.8-1-1.3c-0.2-0.5-0.3-1-0.4-1.6
							C267.4,238,267.5,237.5,267.4,236.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M266.9,240c0.8-1.4,0.7-2.5-0.1-3.6c-0.7-1.1-2-2.2-3.8-2.6c-1.7-0.4-3.4,0.1-4.5,1.4c-1.1,1.3-1.4,3.5-0.4,5.4
							c1.1,2,2.1,3.1,3.2,4.1c1,1,2,2,3.2,3.4c-0.2-1.8,0-3.1,0.5-4.3C265.4,242.6,266.1,241.5,266.9,240z"
                      />
                    </g>
                    <g>
                      <path
                        d="M264.2,244.1c1.7-1.3,2.4-3.2,2.2-4.9c-0.2-1.7-1.4-3.1-3.1-3.7c-1.7-0.6-3.4-0.3-4.7,0.5c-1.3,0.8-2.1,2-2.1,3.8
							c0,1.9,0,3.3,0,4.7c-0.1,1.4-0.4,2.8-1.2,4.5c1.6-1,2.9-1.7,4.3-2.3C260.9,246.1,262.4,245.4,264.2,244.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M258.2,243.2c3.6,1.4,6.6-1.7,6.2-5.2c-0.4-3.5-4.2-5.7-7.3-3.5c-3.2,2.3-4.3,3.9-7.7,5.4
							C253,240.5,254.5,241.8,258.2,243.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M254.5,239.4c1.8,3.2,5.8,2.6,7.7-0.4c1.8-3,0.5-6.9-3.1-7c-3.7-0.1-5.5,0.5-8.9-0.3
							C252.5,234.3,252.7,236.1,254.5,239.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M255,235.1c0,1.7,1.1,2.7,2.4,3c1.3,0.3,3-0.2,4.3-1.3c1.4-1.1,2.1-2.6,2.3-3.8c0.1-1.2-0.4-2.3-1.8-2.8
							c-2.9-1-4.7-1.1-6.9-3C255.8,230.1,255,231.7,255,235.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M299.7,201.9c5.7-1.8,10.2-4.8,13.4-8.2c3.2-3.4,5-7.2,3.9-10.6c-1.1-3.3-5.3-5.2-10.8-4.6c-5.4,0.5-12,3.6-16.6,9.1
							c-4.7,5.7-7.1,10.1-9.4,14.4c-2.2,4.4-4.4,8.6-7.4,14.7c4.8-4.8,8.8-7.3,12.8-9.3C289.6,205.4,293.8,203.9,299.7,201.9z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M104.9,470.4c8.1-1.2,15.4-3.8,20.8-7c5.4-3.2,8.8-6.9,8.1-10.4c-0.7-3.4-5.5-5.7-12.2-5.8c-6.7-0.1-15.4,2-22.9,6.8
							c-7.7,4.9-12.4,9-17.2,13c-4.7,4-9.4,8-16.3,13.7c8.1-3.8,13.9-5.6,19.8-6.9C90.8,472.5,96.6,471.6,104.9,470.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M107.3,490.7c7,1.8,13.9,2.1,19.5,1.1c5.5-1,9.7-3.2,10.5-6.6c0.8-3.4-2.4-7.3-8.1-9.9c-5.6-2.6-13.8-3.8-21.8-2.1
							c-8.2,1.8-13.5,3.9-18.8,6c-5.3,2.1-10.5,4.2-18,7.1c8-0.8,13.5-0.4,18.9,0.5C94.8,487.6,100,488.8,107.3,490.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M96.7,509.5c4.2,3.4,8.8,5.1,13,5.4c4.2,0.3,8.1-0.9,10-3.9c1.9-3,1.1-7.2-2.1-10.9c-3.1-3.7-8.6-6.8-14.9-7.1
							c-6.5-0.4-10.9,0.2-15.2,0.7c-4.3,0.5-8.6,1-14.6,1.5c5.8,1.7,9.5,3.7,12.9,5.9C89.2,503.4,92.4,506,96.7,509.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M75.5,526.8c1.5,4.7,4.5,8,8,9.7c3.4,1.7,7.3,1.9,10.2-0.1c2.9-2,4.1-5.7,3.3-9.8c-0.7-4-3.4-8.3-7.9-11
							c-4.6-2.8-8.1-4.2-11.5-5.8c-3.4-1.6-6.6-3.2-10.9-6.1c2.8,4.3,4.2,7.7,5.3,11.2C73.1,518.5,73.9,522,75.5,526.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M54.8,534.9c-1.5,5-1.3,9.6,0.1,13.5c1.3,3.8,3.8,7,7.3,7.6c3.4,0.7,7.1-1.5,9.3-5.8c2.2-4.2,3-10.5,0.8-16.4
							c-2.3-6-4.6-9.7-6.8-13.4c-2.3-3.6-4.5-7.1-7.6-12.2c1,5.8,0.8,9.9,0.1,13.8C57.4,525.9,56.3,529.7,54.8,534.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M31.3,527.5c-4.1,3.3-6.7,7-8.2,10.8c-0.3,1-0.7,1.9-0.9,2.9c-0.2,1-0.3,1.9-0.3,2.8c0,1.8,0.4,3.6,1.4,5
							c1,1.4,2.6,2.3,4.6,2.6c1,0.1,2.1,0.1,3.2-0.1c1.1-0.2,2.4-0.5,3.5-1.1c4.8-2.1,9.8-7.2,11.7-13.9c1.9-6.8,2-11.6,2-16.2
							c-0.1-4.6-0.3-9.1-0.8-15.3c-1.6,6-3.8,9.7-6.4,12.9C38.4,521.3,35.5,524.1,31.3,527.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M14.2,505.6c-5.9,0.7-10.8,2.7-14.6,5.4c-3.7,2.7-6.2,6.2-5.7,9.7c0.5,3.5,4.2,6.1,9.6,6.6c5.3,0.5,12.3-1.2,17.7-5.6
							c5.6-4.6,8.8-8.3,11.8-12c3-3.7,5.9-7.3,10.2-12.6c-5.6,3.6-10,5.2-14.3,6.3C24.7,504.3,20.3,504.9,14.2,505.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M7.5,483.1c-5.8-1.9-11.2-2.4-15.9-1.9c-1.2,0.2-2.3,0.3-3.3,0.7c-1.1,0.3-2,0.7-2.9,1.1c-1.7,0.9-3.1,2.2-3.7,3.8
							c-0.6,1.6-0.4,3.5,0.5,5.3c0.5,0.9,1.2,1.9,2,2.7c0.9,0.9,1.9,1.8,3.1,2.5c4.8,3.1,12.4,4.8,19.7,3.2c7.5-1.7,12.3-4,16.9-6.2
							c4.5-2.3,9-4.7,15-8.3c-6.9,1.6-11.7,1.4-16.4,0.8C17.9,486.2,13.5,485,7.5,483.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M14.4,464.8c-3.8-4.1-7-7.1-10.2-9.4c-1.6-1.2-3.2-2.2-4.8-3.1c-1.6-0.9-3.3-1.6-5.1-1.4c-1.7,0.1-3.3,1.2-4.4,3.3
							c-1.1,2.1-1.4,5.3-0.6,8.8c0.8,3.5,2.7,7.3,5.7,10.4c2.9,3.1,6.8,5.6,11,6.8c4.4,1.3,7.9,1.6,11.1,1.7
							c3.2,0.1,5.9-0.1,8.6-0.4c5.4-0.6,10.5-1.4,17.9-2.1c-7.3-1.5-12.2-3.2-16.6-5.4c-2.2-1.1-4.3-2.4-6.4-3.9
							C18.5,468.7,16.3,466.9,14.4,464.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M32.3,447.1c-0.1-5.5-0.9-9.8-2.3-13.6c-0.7-1.9-1.5-3.7-2.5-5.3c-1-1.6-2.2-2.9-3.9-3.6c-1.6-0.7-3.5-0.5-5.4,0.6
							c-1.9,1.1-3.7,3.3-4.9,6.3c-1.2,3-1.8,6.7-1.4,10.5c0.4,3.8,1.7,7.8,3.9,11.1c2.3,3.5,4.6,5.9,6.7,7.9c2.2,2,4.2,3.6,6.3,5.1
							c4.1,3,8.3,5.7,14,9.6c-4.3-5.5-6.5-9.9-8-14.2c-0.7-2.2-1.3-4.4-1.8-6.8C32.7,452.5,32.4,449.9,32.3,447.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M52.1,442c2.3-5.4,3.1-10.6,2.9-15.2c-0.1-1.2-0.2-2.2-0.4-3.3c-0.2-1.1-0.5-2-0.9-2.9c-0.8-1.8-1.9-3.2-3.5-4
							c-1.6-0.8-3.4-0.8-5.3,0c-1,0.4-2,1-2.9,1.8c-0.9,0.8-1.9,1.7-2.8,2.9c-3.5,4.5-5.8,11.9-4.6,19.3c1.3,7.6,3.3,12.4,5.3,17
							c2.1,4.6,4.3,9,7.7,15c-1.4-6.8-1-11.6-0.1-16C48.3,451.9,49.8,447.6,52.1,442z"
                      />
                    </g>
                    <g>
                      <path
                        d="M74,442.2c4.3-4.1,7.2-8,9.3-11.7c1-1.9,1.9-3.7,2.4-5.6c0.5-1.8,0.7-3.7,0-5.3c-0.6-1.6-2.1-2.9-4.3-3.4
							c-2.2-0.6-5.1-0.3-8.2,0.8c-3.1,1.2-6.3,3.3-8.9,6.2c-2.6,2.9-4.7,6.5-5.8,10.5c-1.2,4-1.6,7.4-1.8,10.4
							c-0.2,3-0.2,5.7-0.2,8.3c0.1,5.2,0.3,10.3,0,17.6c2.4-6.9,4.7-11.5,7.3-15.6c1.3-2.1,2.8-4,4.4-6
							C69.9,446.4,71.8,444.3,74,442.2z"
                      />
                    </g>
                    <g>
                      <path d="M49.5,484c-1.5,3.2,1.5,6,5,5.6c3.5-0.4,5.8-3.7,3.7-6.5c-2.2-2.9-3.7-3.9-5.1-7C52.4,479.5,51.1,480.7,49.5,484z" />
                    </g>
                    <g>
                      <path
                        d="M54.2,481.4c-1.4,1-1.8,2.3-1.5,3.7c0.3,1.4,1.3,2.8,2.8,3.6c1.5,0.9,3.3,0.9,4.7,0.2c1.4-0.7,2.3-2.2,2.1-4.1
							c-0.2-2-0.6-3.3-0.9-4.7c-0.3-1.3-0.6-2.6-0.7-4.4c-0.9,1.5-1.9,2.4-2.9,3.1C56.8,479.6,55.6,480.4,54.2,481.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M57.7,483c-1.3-0.6-2.1-0.2-2.9,0.7c-0.7,0.9-1.4,2.4-1.5,4.2c-0.1,1.7,0.7,3.3,2.1,4.1c1.4,0.8,3.3,0.7,4.8-0.7
							c1.5-1.4,2.2-2.7,2.8-3.8c0.6-1.2,1.2-2.3,2.1-3.6c-1.5,0.5-2.7,0.5-3.8,0.3C60.2,483.9,59.1,483.5,57.7,483z"
                      />
                    </g>
                    <g>
                      <path
                        d="M60.3,487.5c0-0.7-0.3-1.3-0.7-1.8c-0.5-0.5-1.1-1-1.9-1.2c-0.8-0.3-1.6-0.3-2.4-0.1c-0.8,0.2-1.5,0.6-2.1,1.3
							c-0.6,0.6-1.1,1.3-1.4,2c-0.3,0.7-0.6,1.4-0.6,2c0,0.7,0.1,1.3,0.5,2c0.4,0.6,1.1,1.3,2.1,1.7c1,0.5,2.1,0.8,3,1
							c0.9,0.2,1.7,0.2,2.5,0.1c1.6-0.1,3.1-0.5,4.5-1.6c-0.8,0.1-1.5-0.2-2-0.6c-0.5-0.4-0.8-0.8-1-1.3c-0.2-0.5-0.3-1-0.4-1.6
							C60.2,488.8,60.3,488.3,60.3,487.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M59.7,490.8c0.8-1.4,0.7-2.5-0.1-3.6c-0.7-1.1-2-2.2-3.8-2.6c-1.7-0.4-3.4,0.1-4.5,1.4c-1.1,1.3-1.4,3.5-0.4,5.4
							c1.1,2,2.1,3.1,3.2,4.1c1,1,2,2,3.2,3.4c-0.2-1.8,0-3.1,0.5-4.3C58.3,493.4,58.9,492.2,59.7,490.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M57,494.9c1.7-1.3,2.4-3.2,2.2-4.9c-0.2-1.7-1.4-3.1-3.1-3.7c-1.7-0.6-3.4-0.3-4.7,0.5c-1.3,0.8-2.1,2-2.1,3.8
							c0,1.9,0,3.3,0,4.7c-0.1,1.4-0.4,2.8-1.2,4.5c1.6-1,2.9-1.7,4.3-2.3C53.8,496.8,55.2,496.2,57,494.9z"
                      />
                    </g>
                    <g>
                      <path d="M51,494c3.6,1.4,6.6-1.7,6.2-5.2c-0.4-3.5-4.2-5.7-7.3-3.5c-3.2,2.3-4.3,3.9-7.7,5.4C45.9,491.3,47.3,492.6,51,494z" />
                    </g>
                    <g>
                      <path
                        d="M47.3,490.2c1.8,3.2,5.8,2.6,7.7-0.4c1.8-3,0.5-6.9-3.1-7c-3.7-0.1-5.5,0.5-8.9-0.3C45.3,485.1,45.5,486.9,47.3,490.2
							z"
                      />
                    </g>
                    <g>
                      <path
                        d="M47.9,485.9c0,1.7,1.1,2.7,2.4,3c1.3,0.3,3-0.2,4.3-1.3c1.4-1.1,2.1-2.6,2.3-3.8c0.1-1.2-0.4-2.3-1.8-2.8
							c-2.9-1-4.7-1.1-6.9-3C48.6,480.9,47.8,482.5,47.9,485.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M92.5,452.7c5.7-1.8,10.2-4.8,13.4-8.2c3.2-3.4,5-7.2,3.9-10.6c-1.1-3.3-5.3-5.2-10.8-4.6c-5.4,0.5-12,3.6-16.6,9.1
							c-4.7,5.7-7.1,10.1-9.4,14.4c-2.2,4.4-4.4,8.6-7.4,14.7c4.8-4.8,8.8-7.3,12.8-9.3C82.4,456.2,86.7,454.6,92.5,452.7z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M624.2,225.2c3.8,4.2,8.2,7.3,12.1,9.2c3.9,1.8,7.4,2.3,9.1,0.6c1.7-1.7,1.1-5.3-1.4-9.3c-2.6-3.9-7.1-8.1-12.8-10.5
							c-5.8-2.5-10-3.6-14.1-4.8c-4.1-1.1-8.3-2.3-14.2-4.1c5.3,3.1,8.6,5.8,11.6,8.7C617.5,217.9,620.3,220.9,624.2,225.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M613.5,234.5c1.7,4.7,4.2,8.8,6.9,11.6c2.7,2.8,5.6,4.4,7.9,3.4c2.2-0.9,3.3-4.2,2.6-8.5c-0.7-4.2-3.2-9.4-7.3-13.3
							c-4.2-4-7.5-6.2-10.7-8.5c-3.3-2.2-6.5-4.4-11.1-7.6c3.6,4.3,5.5,7.6,7.1,11C610.4,226.1,611.7,229.6,613.5,234.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M598.5,235.7c-0.3,3.7,0.5,7,2,9.6c1.5,2.5,3.7,4.3,6.1,4.2c2.4-0.1,4.5-2.2,5.4-5.4c0.9-3.2,0.5-7.6-1.7-11.3
							c-2.3-3.8-4.3-6.2-6.3-8.5c-2-2.3-3.9-4.5-6.5-7.8c1.3,4,1.6,6.8,1.6,9.7C599.1,229,598.9,231.8,598.5,235.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M580.4,230.2c-2.1,2.7-2.8,5.7-2.5,8.4c0.3,2.6,1.7,4.9,4,5.8c2.3,0.9,4.9,0.1,6.9-1.9c2-2,3.4-5.2,3.2-8.8
							c-0.2-3.7-0.7-6.3-1.1-8.9c-0.4-2.5-0.7-5.1-0.8-8.6c-1.4,3.3-2.8,5.4-4.3,7.4C584.2,225.6,582.5,227.5,580.4,230.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M567.7,221.5c-3.4,1.1-6,3-7.7,5.3c-1.7,2.3-2.5,4.9-1.5,7.2c0.9,2.2,3.6,3.5,7,3.1c3.3-0.4,7.2-2.4,9.7-5.9
							c2.6-3.6,3.8-6.4,5-9.1c1.2-2.7,2.3-5.4,4-9.1c-2.9,2.8-5.4,4.3-7.8,5.4C573.9,219.5,571.3,220.4,567.7,221.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M562.8,205.2c-3.5-1.1-6.6-1.1-9.4-0.5c-0.7,0.2-1.4,0.3-2,0.6c-0.6,0.3-1.2,0.6-1.7,0.9c-1.1,0.7-1.9,1.6-2.3,2.8
							c-0.4,1.1-0.3,2.4,0.3,3.6c0.3,0.6,0.7,1.2,1.3,1.8c0.6,0.6,1.2,1.1,2,1.6c3.1,1.9,8,2.8,12.5,1.3c4.6-1.6,7.4-3.3,10.1-5.1
							c2.6-1.8,5.1-3.7,8.4-6.4c-4.1,1.4-7.1,1.6-9.9,1.4C569.2,206.9,566.4,206.2,562.8,205.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M568.7,186.9c-2.6-3.1-5.7-5.2-8.7-6.3c-3-1.1-5.9-1.1-7.7,0.5c-1.8,1.6-1.9,4.8-0.1,8.1c1.8,3.3,5.4,6.6,10.1,8
							c4.8,1.4,8.2,1.8,11.5,2.1c3.3,0.3,6.5,0.5,11.2,0.9c-4.3-1.8-6.8-3.7-9.1-5.8C573.6,192.4,571.5,190.1,568.7,186.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M579.1,174.3c-1.1-4.1-3-7.4-5.1-9.9c-0.6-0.6-1.1-1.2-1.7-1.7c-0.6-0.5-1.2-0.9-1.8-1.2c-1.2-0.6-2.4-0.9-3.6-0.7
							c-1.2,0.3-2.2,1.1-2.8,2.4c-0.3,0.6-0.6,1.4-0.8,2.2c-0.2,0.8-0.3,1.8-0.2,2.8c0.1,4,2,9,5.8,12.6c3.9,3.7,7,5.5,10.1,7.2
							c3.1,1.7,6.2,3.3,10.6,5.4c-3.6-3.3-5.4-6.2-6.8-9.1C581.3,181.5,580.3,178.5,579.1,174.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M592.2,171.1c0.9-3.8,1.3-6.8,1.4-9.5c0.1-1.3,0-2.7-0.1-4c-0.1-1.3-0.4-2.5-1.2-3.5c-0.8-0.9-2-1.4-3.6-1.2
							c-1.6,0.2-3.6,1.2-5.3,3c-1.7,1.8-3.1,4.4-3.7,7.3c-0.6,2.9-0.6,6,0.3,8.9c0.9,3,2.1,5.2,3.3,7c1.2,1.8,2.4,3.3,3.6,4.8
							c2.4,2.9,4.9,5.5,8.1,9.5c-2-4.8-2.9-8.3-3.3-11.7c-0.2-1.7-0.3-3.4-0.3-5.2C591.6,175,591.7,173.1,592.2,171.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M609.4,174.5c3.1-2.2,5.3-4.3,6.9-6.6c0.8-1.1,1.5-2.3,2.1-3.5c0.5-1.2,0.8-2.4,0.6-3.6c-0.2-1.2-1.1-2.2-2.5-2.8
							c-1.4-0.6-3.3-0.8-5.5-0.4c-2.2,0.4-4.5,1.5-6.6,3.3c-2,1.7-3.8,4-4.8,6.5c-1.1,2.7-1.6,4.9-1.9,6.9c-0.3,2-0.4,3.8-0.5,5.6
							c-0.1,3.5,0,6.9-0.1,11.8c1.5-4.6,3.1-7.6,5.1-10.1c1-1.3,2-2.5,3.2-3.6C606.4,176.8,607.8,175.6,609.4,174.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M619.9,183.9c4-0.8,7.3-2.3,9.9-4.3c0.6-0.5,1.2-1,1.7-1.5c0.5-0.5,1-1.1,1.3-1.6c0.7-1.1,1.1-2.3,1-3.6
							c-0.2-1.2-0.9-2.3-2.1-3.1c-0.6-0.4-1.3-0.8-2.1-1c-0.8-0.2-1.7-0.4-2.7-0.5c-3.9-0.3-9.1,1.3-12.8,4.9
							c-3.8,3.7-5.8,6.7-7.7,9.6c-1.8,3-3.5,6-5.6,10.2c3.4-3.4,6.2-5.1,9.1-6.3C612.8,185.6,615.8,184.7,619.9,183.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M628.3,196.5c4,0.9,7.4,1,10.3,0.8c1.5-0.1,2.9-0.4,4.1-0.8c1.2-0.4,2.4-1,3.1-2c0.7-1,0.8-2.3,0.3-3.8
							c-0.5-1.5-1.8-3-3.7-4.4c-1.9-1.3-4.3-2.3-7-2.7c-2.7-0.4-5.6-0.2-8.3,0.7c-2.8,0.9-4.9,2-6.7,3c-1.8,1-3.3,2.1-4.8,3.1
							c-3,2.1-5.8,4.2-10.1,6.8c4.9-1.3,8.4-1.8,11.8-1.8c1.7,0,3.4,0,5.2,0.2C624.3,195.8,626.3,196,628.3,196.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M594.8,198.8c-2.4,0.4-2.8,3.2-1.3,5c1.6,1.9,4.4,1.9,5.2-0.4c0.8-2.4,0.8-3.7,2-5.7
							C598.6,198.6,597.3,198.3,594.8,198.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M598.2,200.4c-1.1-0.4-2-0.1-2.7,0.6c-0.7,0.7-1.1,1.8-1,3c0.1,1.2,0.8,2.2,1.7,2.7c0.9,0.5,2.2,0.5,3.2-0.4
							c1-0.9,1.7-1.6,2.3-2.3c0.6-0.7,1.3-1.4,2.2-2.1c-1.2,0-2.1-0.2-2.9-0.4C600.2,201.2,599.3,200.8,598.2,200.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M598.6,203c-0.2-0.9-0.7-1.3-1.5-1.4c-0.8-0.1-1.9,0.1-3,0.8c-1,0.6-1.7,1.7-1.6,2.8c0.1,1.1,0.9,2.2,2.2,2.5
							c1.4,0.3,2.4,0.2,3.3,0.1c0.9-0.1,1.8-0.2,2.9-0.2c-0.9-0.7-1.3-1.4-1.6-2.1C599,204.8,598.8,204,598.6,203z"
                      />
                    </g>
                    <g>
                      <path
                        d="M597,206.3c0.4-0.3,0.6-0.7,0.8-1.1c0.1-0.5,0.1-1,0-1.6c-0.1-0.5-0.4-1.1-0.9-1.4c-0.4-0.4-1-0.6-1.6-0.7
							c-0.6-0.1-1.2-0.1-1.7,0c-0.5,0.1-1,0.2-1.4,0.4c-0.4,0.2-0.7,0.6-0.9,1c-0.2,0.5-0.3,1.1-0.2,1.9c0.1,0.8,0.3,1.5,0.6,2.1
							c0.3,0.6,0.6,1.1,0.9,1.5c0.7,0.8,1.5,1.6,2.7,2c-0.4-0.5-0.5-1-0.4-1.4c0-0.4,0.2-0.8,0.4-1.1c0.2-0.3,0.5-0.6,0.7-0.9
							C596.3,206.7,596.6,206.6,597,206.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M594.9,207.2c1.1-0.1,1.7-0.6,2.1-1.5c0.3-0.8,0.5-2,0-3.2c-0.4-1.1-1.4-1.9-2.6-2c-1.2-0.1-2.5,0.5-3.2,1.9
							c-0.7,1.4-0.9,2.4-1.1,3.4c-0.2,1-0.4,1.9-0.7,3.2c0.9-0.8,1.8-1.2,2.7-1.4C592.9,207.4,593.8,207.3,594.9,207.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M591.5,207.3c1.4,0.5,2.8,0.1,3.7-0.7c0.9-0.8,1.2-2,0.9-3.2c-0.3-1.2-1.2-2.1-2.1-2.5c-0.9-0.4-2-0.4-3,0.3
							c-1.1,0.7-1.9,1.3-2.7,1.8c-0.8,0.5-1.7,0.9-3.1,1.1c1.2,0.5,2.1,1,3,1.6C589.1,206.1,590.1,206.7,591.5,207.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M589.7,203.4c0.6,2.6,3.6,3.1,5.4,1.5c1.8-1.6,1.7-4.6-0.8-5.5c-2.6-1-3.9-1-6.1-2.3
							C589.3,199.5,589.1,200.8,589.7,203.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M590.5,199.9c-1.1,2.2,0.8,4.3,3.2,4.2c2.4-0.1,4.2-2.4,2.8-4.5c-1.4-2.2-2.4-3-3.3-5.2
							C592.6,196.7,591.6,197.6,590.5,199.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M593.1,198.5c-0.9,0.7-1.1,1.7-0.8,2.5c0.4,0.9,1.3,1.6,2.4,2c1.2,0.3,2.3,0.2,3.1-0.2c0.8-0.4,1.2-1.1,0.9-2.1
							c-0.5-2-1.2-3.1-0.9-5.1C596.3,197,595.1,197.2,593.1,198.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M629.5,211.2c3.3,2.5,6.7,4,9.9,4.5c3.2,0.5,6.1,0.1,7.6-1.9c1.5-1.9,0.9-5.1-1.5-8c-2.4-2.9-6.8-5.5-11.7-6
							c-5.1-0.5-8.5-0.1-11.9,0.2c-3.4,0.4-6.7,0.8-11.3,1.5c4.6,0.9,7.6,2.2,10.3,3.7C623.6,206.8,626.1,208.6,629.5,211.2z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M241.1,523.3c3.8,4.2,8.2,7.3,12.1,9.2c3.9,1.8,7.4,2.3,9.1,0.6c1.7-1.7,1.1-5.3-1.4-9.3c-2.6-3.9-7.1-8.1-12.8-10.5
							c-5.8-2.5-10-3.6-14.1-4.8c-4.1-1.1-8.3-2.3-14.2-4.1c5.3,3.1,8.6,5.8,11.6,8.7C234.3,516,237.1,519,241.1,523.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M230.3,532.5c1.7,4.7,4.2,8.8,6.9,11.6c2.7,2.8,5.6,4.4,7.9,3.4c2.2-0.9,3.3-4.2,2.6-8.5c-0.7-4.2-3.2-9.4-7.3-13.3
							c-4.2-4-7.5-6.2-10.7-8.5c-3.3-2.2-6.5-4.4-11.1-7.6c3.6,4.3,5.5,7.6,7.1,11C227.3,524.1,228.6,527.6,230.3,532.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M215.4,533.7c-0.3,3.7,0.5,7,2,9.6c1.5,2.5,3.7,4.3,6.1,4.2c2.4-0.1,4.5-2.2,5.4-5.4c0.9-3.2,0.5-7.6-1.7-11.3
							c-2.3-3.8-4.3-6.2-6.3-8.5c-2-2.3-3.9-4.5-6.5-7.8c1.3,4,1.6,6.8,1.6,9.7C216,527.1,215.7,529.9,215.4,533.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M197.2,528.3c-2.1,2.7-2.8,5.7-2.5,8.4c0.3,2.6,1.7,4.9,4,5.8c2.3,0.9,4.9,0.1,6.9-1.9c2-2,3.4-5.2,3.2-8.8
							c-0.2-3.7-0.7-6.3-1.1-8.9c-0.4-2.5-0.7-5.1-0.8-8.6c-1.4,3.3-2.8,5.4-4.3,7.4C201.1,523.7,199.4,525.5,197.2,528.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M184.6,519.6c-3.4,1.1-6,3-7.7,5.3c-1.7,2.3-2.5,4.9-1.5,7.2c0.9,2.2,3.6,3.5,7,3.1c3.3-0.4,7.2-2.4,9.7-5.9
							c2.6-3.6,3.8-6.4,5-9.1c1.2-2.7,2.3-5.4,4-9.1c-2.9,2.8-5.4,4.3-7.8,5.4C190.7,517.6,188.1,518.4,184.6,519.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M179.7,503.2c-3.5-1.1-6.6-1.1-9.4-0.5c-0.7,0.2-1.4,0.3-2,0.6c-0.6,0.3-1.2,0.6-1.7,0.9c-1.1,0.7-1.9,1.6-2.3,2.8
							c-0.4,1.1-0.3,2.4,0.3,3.6c0.3,0.6,0.7,1.2,1.3,1.8c0.6,0.6,1.2,1.1,2,1.6c3.1,1.9,8,2.8,12.5,1.3c4.6-1.6,7.4-3.3,10.1-5.1
							c2.6-1.8,5.1-3.7,8.4-6.4c-4.1,1.4-7.1,1.6-9.9,1.4C186,504.9,183.3,504.3,179.7,503.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M185.6,484.9c-2.6-3.1-5.7-5.2-8.7-6.3c-3-1.1-5.9-1.1-7.7,0.5c-1.8,1.6-1.9,4.8-0.1,8.1c1.8,3.3,5.4,6.6,10.1,8
							c4.8,1.4,8.2,1.8,11.5,2.1c3.3,0.3,6.5,0.5,11.2,0.9c-4.3-1.8-6.8-3.7-9.1-5.8C190.4,490.4,188.4,488.1,185.6,484.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M195.9,472.3c-1.1-4.1-3-7.4-5.1-9.9c-0.6-0.6-1.1-1.2-1.7-1.7c-0.6-0.5-1.2-0.9-1.8-1.2c-1.2-0.6-2.4-0.9-3.6-0.7
							c-1.2,0.3-2.2,1.1-2.8,2.4c-0.3,0.6-0.6,1.4-0.8,2.2c-0.2,0.8-0.3,1.8-0.2,2.8c0.1,4,2,9,5.8,12.6c3.9,3.7,7,5.5,10.1,7.2
							c3.1,1.7,6.2,3.3,10.6,5.4c-3.6-3.3-5.4-6.2-6.8-9.1C198.2,479.5,197.1,476.5,195.9,472.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M209.1,469.2c0.9-3.8,1.3-6.8,1.4-9.5c0.1-1.3,0-2.7-0.1-4c-0.1-1.3-0.4-2.5-1.2-3.5c-0.8-0.9-2-1.4-3.6-1.2
							c-1.6,0.2-3.6,1.2-5.3,3c-1.7,1.8-3.1,4.4-3.7,7.3c-0.6,2.9-0.6,6,0.3,8.9c0.9,3,2.1,5.2,3.3,7c1.2,1.8,2.4,3.3,3.6,4.8
							c2.4,2.9,4.9,5.5,8.1,9.5c-2-4.8-2.9-8.3-3.3-11.7c-0.2-1.7-0.3-3.4-0.3-5.2C208.4,473,208.6,471.1,209.1,469.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M226.2,472.6c3.1-2.2,5.3-4.3,6.9-6.6c0.8-1.1,1.5-2.3,2.1-3.5c0.5-1.2,0.8-2.4,0.6-3.6c-0.2-1.2-1.1-2.2-2.5-2.8
							c-1.4-0.6-3.3-0.8-5.5-0.4c-2.2,0.4-4.5,1.5-6.6,3.3c-2,1.7-3.8,4-4.8,6.5c-1.1,2.7-1.6,4.9-1.9,6.9c-0.3,2-0.4,3.8-0.5,5.6
							c-0.1,3.5,0,6.9-0.1,11.8c1.5-4.6,3.1-7.6,5.1-10.1c1-1.3,2-2.5,3.2-3.6C223.3,474.9,224.6,473.7,226.2,472.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M236.8,481.9c4-0.8,7.3-2.3,9.9-4.3c0.6-0.5,1.2-1,1.7-1.5c0.5-0.5,1-1.1,1.3-1.6c0.7-1.1,1.1-2.3,1-3.6
							c-0.2-1.2-0.9-2.3-2.1-3.1c-0.6-0.4-1.3-0.8-2.1-1c-0.8-0.2-1.7-0.4-2.7-0.5c-3.9-0.3-9.1,1.3-12.8,4.9
							c-3.8,3.7-5.8,6.7-7.7,9.6c-1.8,3-3.5,6-5.6,10.2c3.4-3.4,6.2-5.1,9.1-6.3C229.7,483.6,232.7,482.8,236.8,481.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M245.2,494.6c4,0.9,7.4,1,10.3,0.8c1.5-0.1,2.9-0.4,4.1-0.8c1.2-0.4,2.4-1,3.1-2c0.7-1,0.8-2.3,0.3-3.8
							c-0.5-1.5-1.8-3-3.7-4.4c-1.9-1.3-4.3-2.3-7-2.7c-2.7-0.4-5.6-0.2-8.3,0.7c-2.8,0.9-4.9,2-6.7,3c-1.8,1-3.3,2.1-4.8,3.1
							c-3,2.1-5.8,4.2-10.1,6.8c4.9-1.3,8.4-1.8,11.8-1.8c1.7,0,3.4,0,5.2,0.2C241.2,493.9,243.1,494.1,245.2,494.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M211.7,496.8c-2.4,0.4-2.8,3.2-1.3,5c1.6,1.9,4.4,1.9,5.2-0.4c0.8-2.4,0.8-3.7,2-5.7
							C215.4,496.7,214.2,496.4,211.7,496.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M215,498.4c-1.1-0.4-2-0.1-2.7,0.6c-0.7,0.7-1.1,1.8-1,3c0.1,1.2,0.8,2.2,1.7,2.7c0.9,0.5,2.2,0.5,3.2-0.4
							c1-0.9,1.7-1.6,2.3-2.3c0.6-0.7,1.3-1.4,2.2-2.1c-1.2,0-2.1-0.2-2.9-0.4C217,499.2,216.2,498.9,215,498.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M215.5,501.1c-0.2-0.9-0.7-1.3-1.5-1.4c-0.8-0.1-1.9,0.1-3,0.8c-1,0.6-1.7,1.7-1.6,2.8c0.1,1.1,0.9,2.2,2.2,2.5
							c1.4,0.3,2.4,0.2,3.3,0.1c0.9-0.1,1.8-0.2,2.9-0.2c-0.9-0.7-1.3-1.4-1.6-2.1C215.9,502.9,215.7,502.1,215.5,501.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M213.9,504.3c0.4-0.3,0.6-0.7,0.8-1.1c0.1-0.5,0.1-1,0-1.6c-0.1-0.5-0.4-1.1-0.9-1.4c-0.4-0.4-1-0.6-1.6-0.7
							c-0.6-0.1-1.2-0.1-1.7,0c-0.5,0.1-1,0.2-1.4,0.4c-0.4,0.2-0.7,0.6-0.9,1c-0.2,0.5-0.3,1.1-0.2,1.9c0.1,0.8,0.3,1.5,0.6,2.1
							c0.3,0.6,0.6,1.1,0.9,1.5c0.7,0.8,1.5,1.6,2.7,2c-0.4-0.5-0.5-1-0.4-1.4c0-0.4,0.2-0.8,0.4-1.1c0.2-0.3,0.5-0.6,0.7-0.9
							C213.1,504.8,213.5,504.6,213.9,504.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M211.8,505.3c1.1-0.1,1.7-0.6,2.1-1.5c0.3-0.8,0.5-2,0-3.2c-0.4-1.1-1.4-1.9-2.6-2c-1.2-0.1-2.5,0.5-3.2,1.9
							c-0.7,1.4-0.9,2.4-1.1,3.4c-0.2,1-0.4,1.9-0.7,3.2c0.9-0.8,1.8-1.2,2.7-1.4C209.7,505.4,210.6,505.4,211.8,505.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M208.4,505.3c1.4,0.5,2.8,0.1,3.7-0.7c0.9-0.8,1.2-2,0.9-3.2c-0.3-1.2-1.2-2.1-2.1-2.5c-0.9-0.4-2-0.4-3,0.3
							c-1.1,0.7-1.9,1.3-2.7,1.8c-0.8,0.5-1.7,0.9-3.1,1.1c1.2,0.5,2.1,1,3,1.6C206,504.2,206.9,504.8,208.4,505.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M206.6,501.5c0.6,2.6,3.6,3.1,5.4,1.5c1.8-1.6,1.7-4.6-0.8-5.5c-2.6-1-3.9-1-6.1-2.3
							C206.1,497.5,205.9,498.8,206.6,501.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M207.3,497.9c-1.1,2.2,0.8,4.3,3.2,4.2c2.4-0.1,4.2-2.4,2.8-4.5c-1.4-2.2-2.4-3-3.3-5.2
							C209.5,494.8,208.5,495.6,207.3,497.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M210,496.6c-0.9,0.7-1.1,1.7-0.8,2.5c0.4,0.9,1.3,1.6,2.4,2c1.2,0.3,2.3,0.2,3.1-0.2c0.8-0.4,1.2-1.1,0.9-2.1
							c-0.5-2-1.2-3.1-0.9-5.1C213.2,495,211.9,495.2,210,496.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M246.4,509.3c3.3,2.5,6.7,4,9.9,4.5c3.2,0.5,6.1,0.1,7.6-1.9c1.5-1.9,0.9-5.1-1.5-8c-2.4-2.9-6.8-5.5-11.7-6
							c-5.1-0.5-8.5-0.1-11.9,0.2c-3.4,0.4-6.7,0.8-11.3,1.5c4.6,0.9,7.6,2.2,10.3,3.7C240.4,504.9,243,506.7,246.4,509.3z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M155.7,208.9c3.8,4.2,8.2,7.3,12.1,9.2c3.9,1.8,7.4,2.3,9.1,0.6c1.7-1.7,1.1-5.3-1.4-9.3c-2.6-3.9-7.1-8.1-12.8-10.5
							c-5.8-2.5-10-3.6-14.1-4.8c-4.1-1.1-8.3-2.3-14.2-4.1c5.3,3.1,8.6,5.8,11.6,8.7C148.9,201.6,151.7,204.6,155.7,208.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M144.9,218.2c1.7,4.7,4.2,8.8,6.9,11.6c2.7,2.8,5.6,4.4,7.9,3.4c2.2-0.9,3.3-4.2,2.6-8.5c-0.7-4.2-3.2-9.4-7.3-13.3
							c-4.2-4-7.5-6.2-10.7-8.5c-3.3-2.2-6.5-4.4-11.1-7.6c3.6,4.3,5.5,7.6,7.1,11C141.9,209.8,143.2,213.3,144.9,218.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M130,219.4c-0.3,3.7,0.5,7,2,9.6c1.5,2.5,3.7,4.3,6.1,4.2c2.4-0.1,4.5-2.2,5.4-5.4c0.9-3.2,0.5-7.6-1.7-11.3
							c-2.3-3.8-4.3-6.2-6.3-8.5c-2-2.3-3.9-4.5-6.5-7.8c1.3,4,1.6,6.8,1.6,9.7C130.6,212.7,130.3,215.5,130,219.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M111.8,214c-2.1,2.7-2.8,5.7-2.5,8.4c0.3,2.6,1.7,4.9,4,5.8c2.3,0.9,4.9,0.1,6.9-1.9c2-2,3.4-5.2,3.2-8.8
							c-0.2-3.7-0.7-6.3-1.1-8.9c-0.4-2.5-0.7-5.1-0.8-8.6c-1.4,3.3-2.8,5.4-4.3,7.4C115.7,209.3,114,211.2,111.8,214z"
                      />
                    </g>
                    <g>
                      <path
                        d="M99.2,205.2c-3.4,1.1-6,3-7.7,5.3c-1.7,2.3-2.5,4.9-1.5,7.2c0.9,2.2,3.6,3.5,7,3.1c3.3-0.4,7.2-2.4,9.7-5.9
							c2.6-3.6,3.8-6.4,5-9.1c1.2-2.7,2.3-5.4,4-9.1c-2.9,2.8-5.4,4.3-7.8,5.4C105.3,203.2,102.7,204.1,99.2,205.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M94.3,188.9c-3.5-1.1-6.6-1.1-9.4-0.5c-0.7,0.2-1.4,0.3-2,0.6c-0.6,0.3-1.2,0.6-1.7,0.9c-1.1,0.7-1.9,1.6-2.3,2.8
							c-0.4,1.1-0.3,2.4,0.3,3.6c0.3,0.6,0.7,1.2,1.3,1.8c0.6,0.6,1.2,1.1,2,1.6c3.1,1.9,8,2.8,12.5,1.3c4.6-1.6,7.4-3.3,10.1-5.1
							c2.6-1.8,5.1-3.7,8.4-6.4c-4.1,1.4-7.1,1.6-9.9,1.4C100.6,190.6,97.9,190,94.3,188.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M100.2,170.6c-2.6-3.1-5.7-5.2-8.7-6.3c-3-1.1-5.9-1.1-7.7,0.5c-1.8,1.6-1.9,4.8-0.1,8.1c1.8,3.3,5.4,6.6,10.1,8
							c4.8,1.4,8.2,1.8,11.5,2.1c3.3,0.3,6.5,0.5,11.2,0.9c-4.3-1.8-6.8-3.7-9.1-5.8C105,176.1,103,173.8,100.2,170.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M110.5,158c-1.1-4.1-3-7.4-5.1-9.9c-0.6-0.6-1.1-1.2-1.7-1.7c-0.6-0.5-1.2-0.9-1.8-1.2c-1.2-0.6-2.4-0.9-3.6-0.7
							c-1.2,0.3-2.2,1.1-2.8,2.4c-0.3,0.6-0.6,1.4-0.8,2.2c-0.2,0.8-0.3,1.8-0.2,2.8c0.1,4,2,9,5.8,12.6c3.9,3.7,7,5.5,10.1,7.2
							c3.1,1.7,6.2,3.3,10.6,5.4c-3.6-3.3-5.4-6.2-6.8-9.1C112.8,165.2,111.7,162.2,110.5,158z"
                      />
                    </g>
                    <g>
                      <path
                        d="M123.7,154.9c0.9-3.8,1.3-6.8,1.4-9.5c0.1-1.3,0-2.7-0.1-4c-0.1-1.3-0.4-2.5-1.2-3.5c-0.8-0.9-2-1.4-3.6-1.2
							c-1.6,0.2-3.6,1.2-5.3,3c-1.7,1.8-3.1,4.4-3.7,7.3c-0.6,2.9-0.6,6,0.3,8.9c0.9,3,2.1,5.2,3.3,7c1.2,1.8,2.4,3.3,3.6,4.8
							c2.4,2.9,4.9,5.5,8.1,9.5c-2-4.8-2.9-8.3-3.3-11.7c-0.2-1.7-0.3-3.4-0.3-5.2C123,158.7,123.2,156.8,123.7,154.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M140.8,158.2c3.1-2.2,5.3-4.3,6.9-6.6c0.8-1.1,1.5-2.3,2.1-3.5c0.5-1.2,0.8-2.4,0.6-3.6c-0.2-1.2-1.1-2.2-2.5-2.8
							c-1.4-0.6-3.3-0.8-5.5-0.4c-2.2,0.4-4.5,1.5-6.6,3.3c-2,1.7-3.8,4-4.8,6.5c-1.1,2.7-1.6,4.9-1.9,6.9c-0.3,2-0.4,3.8-0.5,5.6
							c-0.1,3.5,0,6.9-0.1,11.8c1.5-4.6,3.1-7.6,5.1-10.1c1-1.3,2-2.5,3.2-3.6C137.9,160.6,139.2,159.4,140.8,158.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M151.4,167.6c4-0.8,7.3-2.3,9.9-4.3c0.6-0.5,1.2-1,1.7-1.5c0.5-0.5,1-1.1,1.3-1.6c0.7-1.1,1.1-2.3,1-3.6
							c-0.2-1.2-0.9-2.3-2.1-3.1c-0.6-0.4-1.3-0.8-2.1-1c-0.8-0.2-1.7-0.4-2.7-0.5c-3.9-0.3-9.1,1.3-12.8,4.9
							c-3.8,3.7-5.8,6.7-7.7,9.6c-1.8,3-3.5,6-5.6,10.2c3.4-3.4,6.2-5.1,9.1-6.3C144.2,169.3,147.3,168.5,151.4,167.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M159.8,180.2c4,0.9,7.4,1,10.3,0.8c1.5-0.1,2.9-0.4,4.1-0.8c1.2-0.4,2.4-1,3.1-2c0.7-1,0.8-2.3,0.3-3.8
							c-0.5-1.5-1.8-3-3.7-4.4c-1.9-1.3-4.3-2.3-7-2.7c-2.7-0.4-5.6-0.2-8.3,0.7c-2.8,0.9-4.9,2-6.7,3c-1.8,1-3.3,2.1-4.8,3.1
							c-3,2.1-5.8,4.2-10.1,6.8c4.9-1.3,8.4-1.8,11.8-1.8c1.7,0,3.4,0,5.2,0.2C155.8,179.5,157.7,179.8,159.8,180.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M126.3,182.5c-2.4,0.4-2.8,3.2-1.3,5c1.6,1.9,4.4,1.9,5.2-0.4c0.8-2.4,0.8-3.7,2-5.7
							C130,182.3,128.8,182.1,126.3,182.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M129.6,184.1c-1.1-0.4-2-0.1-2.7,0.6c-0.7,0.7-1.1,1.8-1,3c0.1,1.2,0.8,2.2,1.7,2.7c0.9,0.5,2.2,0.5,3.2-0.4
							c1-0.9,1.7-1.6,2.3-2.3c0.6-0.7,1.3-1.4,2.2-2.1c-1.2,0-2.1-0.2-2.9-0.4C131.6,184.9,130.8,184.5,129.6,184.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M130.1,186.7c-0.2-0.9-0.7-1.3-1.5-1.4c-0.8-0.1-1.9,0.1-3,0.8c-1,0.6-1.7,1.7-1.6,2.8c0.1,1.1,0.9,2.2,2.2,2.5
							c1.4,0.3,2.4,0.2,3.3,0.1c0.9-0.1,1.8-0.2,2.9-0.2c-0.9-0.7-1.3-1.4-1.6-2.1C130.5,188.5,130.3,187.7,130.1,186.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M128.5,190c0.4-0.3,0.6-0.7,0.8-1.1c0.1-0.5,0.1-1,0-1.6c-0.1-0.5-0.4-1.1-0.9-1.4c-0.4-0.4-1-0.6-1.6-0.7
							c-0.6-0.1-1.2-0.1-1.7,0c-0.5,0.1-1,0.2-1.4,0.4c-0.4,0.2-0.7,0.6-0.9,1c-0.2,0.5-0.3,1.1-0.2,1.9c0.1,0.8,0.3,1.5,0.6,2.1
							c0.3,0.6,0.6,1.1,0.9,1.5c0.7,0.8,1.5,1.6,2.7,2c-0.4-0.5-0.5-1-0.4-1.4c0-0.4,0.2-0.8,0.4-1.1c0.2-0.3,0.5-0.6,0.7-0.9
							C127.7,190.4,128.1,190.3,128.5,190z"
                      />
                    </g>
                    <g>
                      <path
                        d="M126.4,190.9c1.1-0.1,1.7-0.6,2.1-1.5c0.3-0.8,0.5-2,0-3.2c-0.4-1.1-1.4-1.9-2.6-2c-1.2-0.1-2.5,0.5-3.2,1.9
							c-0.7,1.4-0.9,2.4-1.1,3.4c-0.2,1-0.4,1.9-0.7,3.2c0.9-0.8,1.8-1.2,2.7-1.4C124.3,191.1,125.2,191,126.4,190.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M123,191c1.4,0.5,2.8,0.1,3.7-0.7c0.9-0.8,1.2-2,0.9-3.2c-0.3-1.2-1.2-2.1-2.1-2.5c-0.9-0.4-2-0.4-3,0.3
							c-1.1,0.7-1.9,1.3-2.7,1.8c-0.8,0.5-1.7,0.9-3.1,1.1c1.2,0.5,2.1,1,3,1.6C120.6,189.9,121.5,190.4,123,191z"
                      />
                    </g>
                    <g>
                      <path
                        d="M121.2,187.2c0.6,2.6,3.6,3.1,5.4,1.5c1.8-1.6,1.7-4.6-0.8-5.5c-2.6-1-3.9-1-6.1-2.3
							C120.7,183.2,120.5,184.5,121.2,187.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M121.9,183.6c-1.1,2.2,0.8,4.3,3.2,4.2c2.4-0.1,4.2-2.4,2.8-4.5c-1.4-2.2-2.4-3-3.3-5.2
							C124.1,180.4,123.1,181.3,121.9,183.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M124.6,182.2c-0.9,0.7-1.1,1.7-0.8,2.5c0.4,0.9,1.3,1.6,2.4,2c1.2,0.3,2.3,0.2,3.1-0.2c0.8-0.4,1.2-1.1,0.9-2.1
							c-0.5-2-1.2-3.1-0.9-5.1C127.8,180.7,126.5,180.9,124.6,182.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M161,194.9c3.3,2.5,6.7,4,9.9,4.5c3.2,0.5,6.1,0.1,7.6-1.9c1.5-1.9,0.9-5.1-1.5-8c-2.4-2.9-6.8-5.5-11.7-6
							c-5.1-0.5-8.5-0.1-11.9,0.2c-3.4,0.4-6.7,0.8-11.3,1.5c4.6,0.9,7.6,2.2,10.3,3.7C155,190.5,157.6,192.3,161,194.9z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M353.7,131.6c-6.1,1.4-12.7,4.3-17.8,8c-2.6,1.8-4.9,3.9-7,6c-2,2.1-3.7,4.3-5.1,6.4c-2.8,4.3-4.2,8.4-4.6,11.5
							c-0.2,1.6-0.1,2.9,0.2,3.9c0.3,1,0.9,1.8,1.6,2.2c0.8,0.4,1.7,0.6,2.7,0.4c1-0.2,2.2-0.6,3.5-1.2c2.5-1.2,5.5-3.2,8.8-5.5
							c1.6-1.1,3.4-2.3,5.2-3.6c1.8-1.2,3.8-2.5,5.8-3.8c4-2.7,8.2-5.1,13.1-8c5-3,9.8-5.5,14-8.2c4.2-2.6,8-5.4,11.3-8.5
							c1.7-1.5,3.3-3.1,4.8-4.8c1.6-1.7,3.1-3.5,4.6-5.5c3-3.9,6-8.5,8.6-14.3c-3.6,5.3-7.4,9-11.1,11.8c-1.9,1.4-3.7,2.6-5.5,3.7
							c-1.8,1.1-3.7,2-5.5,2.7c-3.7,1.6-7.5,2.6-11.9,3.5C365,129.4,360,130.1,353.7,131.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M362.6,150.6c-5,2.2-10,4.1-14.7,5.9c-4.7,1.7-9.2,3.3-13.1,4.7c-4,1.4-7.4,2.8-9.9,4.2c-2.5,1.4-4.1,2.9-4.3,4.7
							c-0.1,1.7,1.3,3.5,4.2,5c2.8,1.5,7.1,2.5,12.3,2.7c5.2,0.2,11.2-0.6,17.4-2.6c6.1-2.1,12.3-5.3,17.6-9.8
							c5.4-4.6,9.3-9,12.4-13.3c3.1-4.3,5.4-8.3,7.5-12.4c2-4.1,3.8-8.4,5.4-13.2c1.6-4.8,3-10.2,4-16.7c-2,6.2-4.5,11.1-7.2,15.2
							c-2.6,4.1-5.4,7.4-8.4,10.3c-3,3-6.2,5.6-10,8.1C372,145.9,367.8,148.3,362.6,150.6z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M401.3,173.5c-6-1.9-13.1-2.8-19.4-2.2c-3.2,0.3-6.2,0.8-9,1.6c-2.8,0.8-5.4,1.8-7.7,2.9c-4.6,2.3-7.9,5-9.8,7.5
							c-1,1.2-1.6,2.4-1.8,3.5c-0.3,1.1-0.2,2,0.3,2.7c0.4,0.8,1.2,1.3,2.2,1.7c1,0.4,2.2,0.6,3.6,0.8c2.8,0.2,6.3,0.1,10.3-0.2
							c2-0.1,4.1-0.3,6.4-0.4c2.2-0.1,4.5-0.3,6.9-0.4c4.8-0.2,9.7-0.2,15.4-0.2c5.9,0,11.2,0.3,16.2,0.1c5-0.1,9.7-0.6,14.1-1.5
							c2.2-0.4,4.4-1,6.6-1.7c2.2-0.7,4.4-1.4,6.7-2.4c4.6-1.9,9.5-4.3,14.7-7.9c-5.8,2.7-10.9,3.9-15.6,4.5
							c-2.3,0.3-4.5,0.4-6.7,0.3c-2.1,0-4.2-0.2-6.2-0.5c-4-0.5-7.8-1.6-12-3C412.2,177.4,407.6,175.5,401.3,173.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M399.3,194.3c-5.4-0.7-10.7-1.6-15.6-2.5c-4.9-0.9-9.6-1.9-13.7-2.7c-4.1-0.8-7.8-1.4-10.7-1.5
							c-2.9-0.1-5.1,0.4-6.1,1.9c-1,1.4-0.6,3.7,1.1,6.4c1.7,2.7,4.8,5.8,9.2,8.6c4.3,2.8,9.9,5.3,16.3,6.7
							c6.3,1.4,13.3,1.8,20.1,0.6c7-1.1,12.6-3,17.5-5c4.8-2.1,8.9-4.4,12.8-6.9c3.9-2.5,7.6-5.3,11.4-8.6c3.8-3.3,7.8-7.3,12-12.3
							c-4.9,4.3-9.6,7.2-13.9,9.4c-4.4,2.2-8.4,3.5-12.5,4.6c-4.1,1-8.2,1.6-12.7,1.9C409.8,195.2,404.9,195.1,399.3,194.3z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M143.2,295.9c4-4.8,7.5-11.1,9.3-17.2c0.9-3.1,1.6-6.1,1.9-9c0.3-2.9,0.3-5.7,0.2-8.2c-0.4-5.1-1.7-9.2-3.3-11.9
							c-0.8-1.4-1.7-2.4-2.6-3c-0.9-0.6-1.8-0.9-2.6-0.8c-0.9,0.1-1.7,0.6-2.4,1.3c-0.7,0.8-1.4,1.8-2,3.1c-1.3,2.5-2.4,5.9-3.7,9.7
							c-0.6,1.9-1.3,3.9-2,6.1c-0.7,2.1-1.4,4.3-2.2,6.6c-1.6,4.6-3.4,9-5.5,14.3c-2.2,5.4-4.4,10.3-6.2,15
							c-1.7,4.7-3.1,9.2-3.8,13.6c-0.4,2.2-0.7,4.5-0.9,6.8c-0.2,2.3-0.3,4.7-0.3,7.1c0,4.9,0.4,10.4,1.8,16.6
							c-0.4-6.3,0.4-11.6,1.6-16.1c0.6-2.3,1.3-4.4,2.2-6.3c0.8-2,1.7-3.8,2.7-5.5c2-3.5,4.4-6.6,7.3-10
							C135.6,304.6,139.1,300.9,143.2,295.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M124.7,286.2c2.7-4.8,5.5-9.3,8.1-13.6c2.7-4.3,5.3-8.2,7.6-11.7c2.3-3.5,4.2-6.7,5.4-9.3c1.2-2.6,1.5-4.9,0.5-6.3
							c-1-1.5-3.2-2-6.4-1.4c-3.2,0.6-7.2,2.3-11.4,5.3c-4.2,3-8.6,7.3-12.2,12.6c-3.6,5.4-6.6,11.7-8.1,18.4
							c-1.5,6.9-1.9,12.8-1.8,18.1c0.1,5.3,0.7,9.9,1.6,14.4c0.9,4.5,2.1,9,3.7,13.8c1.7,4.8,3.8,9.9,7,15.7
							c-2.2-6.2-3.2-11.6-3.5-16.4c-0.4-4.8-0.2-9.2,0.4-13.3c0.6-4.2,1.6-8.2,3-12.5C120,295.7,121.9,291.1,124.7,286.2z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M453.4,473.1c-7.8,2.4-14.6,6.2-19.4,10.2c-4.8,4-7.6,8.2-6.4,11.6c1.2,3.3,6.3,4.8,13,3.8c6.7-1,14.9-4.4,21.5-10.4
							c6.8-6.1,10.9-10.9,14.9-15.6c4-4.7,8-9.4,14-16.1c-7.3,5.1-12.9,7.7-18.4,9.9C467.1,468.8,461.4,470.6,453.4,473.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M447.9,453.4c-7.2-0.7-14.1,0.2-19.4,2c-5.3,1.8-9.1,4.7-9.3,8.2c-0.2,3.5,3.5,6.8,9.5,8.5c6,1.7,14.2,1.6,21.8-1.4
							c7.8-3.1,12.7-6,17.6-8.9c4.9-2.9,9.7-5.8,16.7-9.9c-7.8,2.1-13.3,2.5-18.7,2.5C460.7,454.5,455.3,454.1,447.9,453.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M455.3,433.2c-4.7-2.7-9.5-3.7-13.7-3.3c-4.2,0.4-7.9,2.2-9.3,5.4c-1.4,3.2,0.1,7.2,3.8,10.4
							c3.7,3.1,9.6,5.3,15.9,4.7c6.4-0.7,10.7-1.9,14.9-3.1c4.2-1.2,8.3-2.4,14.1-3.8c-6-0.7-9.9-2.1-13.6-3.8
							C463.7,438,460.2,435.9,455.3,433.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M473.5,412.7c-2.2-4.4-5.7-7.2-9.4-8.4c-3.7-1.2-7.5-0.8-10.1,1.7c-2.6,2.4-3.1,6.3-1.8,10.2c1.4,3.9,4.7,7.6,9.6,9.6
							c5,2,8.7,2.9,12.3,3.9c3.6,1,7.1,2.1,11.8,4.3c-3.4-3.8-5.3-7-7-10.2C477.3,420.6,475.8,417.2,473.5,412.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M492.7,401.5c0.7-5.1-0.3-9.7-2.2-13.3c-1.9-3.6-4.9-6.3-8.4-6.4c-3.5-0.1-6.7,2.6-8.3,7.2
							c-1.5,4.5-1.3,10.8,1.8,16.3c3.2,5.6,6.1,8.9,8.9,12.1c2.8,3.2,5.6,6.3,9.4,10.8c-1.9-5.5-2.3-9.6-2.3-13.6
							C491.6,410.7,492,406.8,492.7,401.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M517.1,405c3.5-3.9,5.5-8,6.4-12c0.2-1,0.4-2,0.4-3c0-1,0-1.9-0.1-2.8c-0.3-1.8-1-3.5-2.2-4.7
							c-1.3-1.2-2.9-1.9-4.9-1.8c-1,0-2.1,0.2-3.1,0.6c-1.1,0.4-2.2,0.9-3.3,1.6c-4.4,2.8-8.5,8.7-9.3,15.5
							c-0.8,7-0.1,11.7,0.6,16.3c0.8,4.5,1.7,8.9,3.2,15c0.6-6.2,2.3-10.2,4.3-13.8C511,412.3,513.5,409,517.1,405z"
                      />
                    </g>
                    <g>
                      <path
                        d="M537.4,423.9c5.7-1.6,10.3-4.3,13.5-7.6c3.2-3.2,5.1-7.1,4.1-10.4c-1-3.3-5.1-5.3-10.5-5c-5.4,0.3-11.9,3.1-16.6,8.4
							c-4.8,5.4-7.3,9.6-9.8,13.8c-2.3,4.1-4.7,8.2-8.1,14.1c5-4.5,9-6.7,13.1-8.4C527.3,426.9,531.5,425.6,537.4,423.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M547.6,445.1c6,1,11.5,0.6,16-0.7c1.1-0.4,2.2-0.7,3.2-1.2c1-0.5,1.9-1,2.7-1.6c1.6-1.2,2.7-2.6,3.1-4.4
							c0.4-1.7-0.1-3.5-1.4-5.2c-0.6-0.8-1.4-1.7-2.5-2.4c-1-0.7-2.2-1.5-3.5-2c-5.2-2.3-13-2.8-20,0c-7.2,2.9-11.5,5.9-15.7,8.8
							c-4.1,3-8.1,6.1-13.5,10.5c6.6-2.7,11.4-3.3,16-3.4C536.8,443.6,541.4,444.1,547.6,445.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M543.7,464.2c4.4,3.5,8.1,5.9,11.5,7.7c1.7,0.9,3.5,1.7,5.2,2.3c1.7,0.6,3.5,1,5.3,0.6c1.7-0.4,3.1-1.7,3.8-4
							c0.7-2.2,0.6-5.4-0.8-8.8c-1.3-3.3-3.9-6.8-7.2-9.3c-3.4-2.6-7.5-4.4-11.9-5c-4.5-0.6-8.1-0.4-11.2,0
							c-3.1,0.4-5.8,1.1-8.4,1.8c-5.2,1.4-10.1,3.1-17.4,4.9c7.5,0.3,12.6,1.2,17.3,2.7c2.3,0.8,4.6,1.7,6.9,2.8
							C539.1,461,541.5,462.4,543.7,464.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M528.8,484.6c1,5.4,2.5,9.5,4.4,13.1c1,1.8,2.1,3.4,3.3,4.8c1.2,1.4,2.7,2.5,4.4,2.9c1.7,0.4,3.5,0,5.2-1.5
							c1.7-1.4,3.1-3.9,3.9-7c0.7-3.1,0.7-6.9-0.2-10.6c-1-3.7-2.9-7.4-5.6-10.3c-2.8-3.1-5.4-5.1-7.9-6.8c-2.5-1.7-4.7-2.9-7-4.1
							c-4.6-2.3-9.1-4.3-15.4-7.3c5.1,4.8,8,8.7,10.2,12.8c1.1,2,2,4.1,2.8,6.4C527.6,479.3,528.3,481.8,528.8,484.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M510.1,492.7c-1.4,5.7-1.4,11-0.4,15.5c0.3,1.1,0.5,2.2,0.9,3.2c0.4,1,0.8,1.9,1.3,2.7c1,1.6,2.4,2.9,4.1,3.4
							c1.7,0.5,3.5,0.2,5.3-0.9c0.9-0.5,1.8-1.3,2.6-2.2c0.8-0.9,1.6-2,2.3-3.3c2.7-5,3.8-12.7,1.5-19.8c-2.4-7.3-5.2-11.7-7.9-15.9
							c-2.8-4.2-5.7-8.2-10-13.6c2.4,6.5,2.8,11.3,2.7,15.8C512.2,482.3,511.5,486.8,510.1,492.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M488.5,496c-3.6,4.8-5.9,9-7.3,13c-0.7,2-1.3,4-1.5,5.9c-0.2,1.9-0.1,3.7,0.8,5.3c0.9,1.5,2.5,2.5,4.7,2.7
							c2.2,0.2,5.1-0.5,7.9-2.1c2.8-1.6,5.7-4.3,7.8-7.6c2.1-3.3,3.6-7.2,4.1-11.2c0.5-4.2,0.4-7.6,0.2-10.6c-0.3-3-0.7-5.6-1.1-8.2
							c-0.9-5.1-1.9-10.1-2.8-17.4c-1.3,7.2-2.8,12.1-4.8,16.5c-1,2.2-2.1,4.4-3.4,6.7C491.8,491.2,490.3,493.6,488.5,496z"
                      />
                    </g>
                    <g>
                      <path
                        d="M505.9,450.8c0.9-3.4-2.4-5.6-5.8-4.7c-3.4,0.9-5.2,4.5-2.7,7c2.6,2.5,4.3,3.3,6.2,6.1
							C503.8,455.8,505,454.3,505.9,450.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M501.8,454.2c1.2-1.2,1.4-2.6,0.9-3.9c-0.5-1.3-1.7-2.5-3.4-3.1c-1.7-0.6-3.4-0.4-4.6,0.6c-1.2,0.9-1.9,2.6-1.4,4.4
							c0.5,1.9,1.1,3.2,1.6,4.5c0.5,1.3,1,2.5,1.4,4.2c0.7-1.6,1.5-2.6,2.4-3.6C499.5,456.3,500.5,455.4,501.8,454.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M498,453.2c1.3,0.3,2.1-0.1,2.7-1.2c0.6-1,1-2.6,0.8-4.4c-0.2-1.7-1.2-3.2-2.7-3.8c-1.5-0.6-3.4-0.2-4.6,1.4
							c-1.2,1.6-1.7,3-2.2,4.2c-0.4,1.3-0.8,2.4-1.5,3.9c1.4-0.8,2.6-0.9,3.7-0.9C495.5,452.6,496.6,452.9,498,453.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M494.8,449.1c0.1,0.7,0.5,1.2,1,1.7c0.5,0.4,1.3,0.8,2.1,0.9c0.8,0.1,1.7,0.1,2.4-0.3c0.8-0.3,1.4-0.9,1.9-1.6
							c0.5-0.7,0.8-1.5,1.1-2.2c0.2-0.7,0.3-1.4,0.3-2.1c-0.1-0.7-0.3-1.3-0.8-1.8c-0.5-0.6-1.3-1.1-2.4-1.4
							c-1.1-0.3-2.2-0.5-3.1-0.5c-0.9,0-1.7,0.1-2.5,0.3c-1.5,0.4-3,0.9-4.2,2.3c0.8-0.2,1.5,0,2.1,0.3c0.5,0.3,0.9,0.7,1.2,1.2
							c0.3,0.5,0.5,1,0.7,1.5C494.6,447.8,494.6,448.4,494.8,449.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M494.8,445.7c-0.6,1.5-0.3,2.6,0.6,3.6c0.9,1,2.4,1.8,4.1,2c1.7,0.2,3.4-0.6,4.2-2.1c0.9-1.5,0.9-3.7-0.5-5.4
							c-1.4-1.8-2.6-2.7-3.8-3.6c-1.2-0.9-2.3-1.7-3.7-2.8c0.5,1.8,0.5,3.1,0.2,4.4C495.9,443,495.4,444.2,494.8,445.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M496.9,441.3c-1.5,1.5-1.9,3.6-1.4,5.2c0.5,1.6,1.9,2.8,3.6,3.1c1.7,0.3,3.4-0.2,4.5-1.2c1.1-1,1.7-2.3,1.5-4.1
							c-0.3-1.8-0.6-3.2-0.7-4.6c-0.1-1.4-0.1-2.8,0.5-4.7c-1.5,1.3-2.6,2.1-3.9,3C499.7,438.9,498.4,439.7,496.9,441.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M502.9,441.2c-3.7-0.8-6.3,2.7-5.3,6.1c1,3.4,5,5,7.7,2.3c2.8-2.8,3.6-4.5,6.8-6.6C508.4,443.1,506.8,442,502.9,441.2
							z"
                      />
                    </g>
                    <g>
                      <path
                        d="M507.1,444.4c-2.2-2.9-6.2-1.6-7.5,1.7c-1.3,3.3,0.6,6.9,4.2,6.5c3.7-0.5,5.4-1.4,8.9-1.1
							C510,449.2,509.4,447.3,507.1,444.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M507.3,448.7c-0.3-1.6-1.5-2.5-2.9-2.6c-1.4-0.1-2.9,0.6-4.1,2c-1.2,1.3-1.7,2.9-1.7,4.1c0.1,1.3,0.7,2.2,2.2,2.4
							c3,0.6,4.8,0.4,7.3,1.9C507.4,453.8,507.9,452.1,507.3,448.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M468.5,488.6c-5.3,2.7-9.3,6.4-11.9,10.2c-2.6,3.8-3.8,7.9-2.1,11.1c1.6,3.1,6.1,4.3,11.4,2.9
							c5.3-1.4,11.3-5.5,15-11.7c3.7-6.3,5.4-11.1,7-15.7c1.5-4.7,3-9.2,5-15.7c-3.9,5.5-7.5,8.6-11.2,11.3
							C477.9,483.5,474,485.7,468.5,488.6z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M426.3,566.6c5.8-2.4,11.9-6.2,16.3-10.7c2.3-2.2,4.3-4.6,5.9-7c1.7-2.4,3-4.8,4-7.2c2.1-4.7,2.9-8.9,2.7-12.1
							c-0.1-1.6-0.4-2.9-0.8-3.9c-0.5-1-1.1-1.6-2-1.9c-0.8-0.3-1.8-0.3-2.8,0c-1,0.3-2.1,0.9-3.2,1.7c-2.3,1.6-4.9,4-7.8,6.8
							c-1.4,1.4-3,2.8-4.6,4.4c-1.6,1.5-3.3,3.1-5.1,4.7c-3.5,3.3-7.3,6.4-11.7,10c-4.5,3.7-8.8,7-12.5,10.3
							c-3.8,3.3-7,6.6-9.9,10.2c-1.4,1.8-2.7,3.6-4,5.5c-1.3,1.9-2.5,4-3.7,6.1c-2.3,4.4-4.5,9.3-6.2,15.5c2.7-5.8,5.9-10,9.1-13.4
							c1.6-1.7,3.2-3.2,4.9-4.5c1.6-1.3,3.3-2.5,5-3.6c3.4-2.1,7-3.8,11.2-5.4C415.5,570.6,420.3,569,426.3,566.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M414.5,549.3c4.6-2.9,9.2-5.6,13.6-8.1c4.4-2.5,8.6-4.7,12.2-6.7c3.7-2,6.9-3.9,9.1-5.7c2.3-1.8,3.6-3.6,3.5-5.3
							c-0.2-1.7-1.9-3.3-4.9-4.3c-3-1-7.4-1.4-12.6-0.7c-5.1,0.6-11,2.3-16.8,5.3c-5.7,3-11.4,7.2-15.8,12.4
							c-4.6,5.4-7.8,10.4-10.2,15.1c-2.4,4.7-4.1,9.1-5.4,13.5c-1.4,4.4-2.4,8.9-3.2,13.9c-0.8,5-1.3,10.6-1.3,17.1
							c1-6.5,2.7-11.7,4.7-16.1c2-4.4,4.2-8.1,6.7-11.5c2.5-3.4,5.3-6.5,8.5-9.6C405.9,555.4,409.8,552.3,414.5,549.3z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M372.6,532.7c6.2,0.9,13.4,0.7,19.5-0.9c3.1-0.8,6-1.8,8.7-3c2.7-1.2,5-2.6,7.1-4.1c4.2-3,7-6.2,8.5-9
							c0.7-1.4,1.2-2.7,1.2-3.7c0.1-1.1-0.1-2-0.7-2.7c-0.5-0.7-1.4-1.1-2.4-1.4c-1-0.2-2.3-0.3-3.7-0.2c-2.8,0.2-6.3,0.9-10.2,1.8
							c-1.9,0.4-4,0.9-6.2,1.4c-2.2,0.5-4.4,1-6.8,1.5c-4.7,1-9.5,1.8-15.1,2.6c-5.8,0.9-11.1,1.5-16,2.4c-4.9,0.9-9.4,2.1-13.7,3.7
							c-2.1,0.8-4.2,1.7-6.3,2.7c-2.1,1-4.2,2.1-6.3,3.4c-4.2,2.6-8.7,5.7-13.3,10.1c5.3-3.6,10.2-5.6,14.7-6.9
							c2.3-0.6,4.4-1.1,6.5-1.4c2.1-0.3,4.1-0.5,6.2-0.5c4-0.1,7.9,0.3,12.4,1.1C361.2,530.7,366.2,531.8,372.6,532.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M371.3,511.9c5.5-0.2,10.8-0.1,15.8,0c5,0.1,9.8,0.3,14,0.5c4.2,0.1,7.9,0.1,10.8-0.2c2.9-0.4,4.9-1.2,5.7-2.8
							c0.7-1.6,0.1-3.8-2.1-6.2c-2.1-2.4-5.7-5-10.4-7.1c-4.7-2.1-10.7-3.6-17.1-4c-6.5-0.3-13.4,0.4-20,2.6
							c-6.7,2.2-12,4.9-16.5,7.7c-4.4,2.8-8.1,5.7-11.6,8.8c-3.4,3.1-6.6,6.4-9.9,10.3c-3.2,3.9-6.6,8.4-9.8,14
							c4.2-5,8.3-8.7,12.2-11.5c4-2.8,7.8-4.8,11.6-6.5c3.9-1.6,7.8-2.9,12.2-3.9C360.8,512.7,365.7,512,371.3,511.9z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M514.4,139c10.6-1.1,21.3-3.7,30.7-7.7c9.4-3.9,17.6-9.3,23.7-14.8c6.1-5.5,10.2-11.1,12.5-15.3
							c2.3-4.2,2.9-6.9,2.1-7.4c-1.6-1-7.7,7.5-19.9,16.2c-6.1,4.3-13.6,8.7-22.2,12.2c-8.6,3.5-18.2,6.3-28.2,8.1
							c-10.2,1.9-19.1,2.6-27.2,3.2c-8.1,0.6-15.5,1.1-23,1.9c-7.4,0.8-14.9,1.9-23.1,4.1c-4.1,1.1-8.3,2.5-12.7,4.4
							c-4.4,1.9-8.9,4.3-13.4,7.6c9.3-6,18.4-8.6,26.5-10.2c8.2-1.5,15.6-1.9,22.9-2c7.4-0.1,14.7,0.2,22.9,0.5
							C494.2,139.9,503.4,140.1,514.4,139z"
                      />
                    </g>
                    <g>
                      <path
                        d="M458.8,128.6c2.7-1.5,4.5-4,5.1-6.3c0.6-2.3,0-4.4-1.5-5.3c-1.5-0.9-3.5-0.6-5.3,0.4c-1.8,1-3.5,2.7-4.6,5.2
							c-1.1,2.5-2,4.3-3,6c-1,1.7-2.2,3.4-4.4,5.4c2.8-1.1,4.7-1.7,6.8-2.4C454,130.8,456,130.1,458.8,128.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M475.9,123.1c2.3-1.3,3.6-3.6,3.8-5.6c0.2-2.1-0.6-3.8-2.1-4.7c-1.6-0.8-3.4-0.6-5,0.2c-1.6,0.8-2.9,2.3-3.5,4.4
							c-0.7,2.2-1.1,3.8-1.7,5.4c-0.6,1.6-1.4,3.1-3,5c2.3-1,3.9-1.6,5.6-2.2C471.8,125,473.6,124.4,475.9,123.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M493,119.2c2.2-1.6,3.5-4,3.7-6.1c0.2-2.1-0.5-4-2.1-4.8c-1.5-0.8-3.5-0.5-5.1,0.5c-1.7,1.1-3.1,2.9-3.7,5.3
							c-0.6,2.5-1,4.3-1.5,6.1c-0.5,1.8-1.2,3.6-2.5,5.8c2.2-1.6,3.8-2.5,5.5-3.5C488.9,121.7,490.7,120.8,493,119.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M512.9,117.7c2.4-2.2,3.5-5.3,3.4-7.8c-0.1-2.5-1.3-4.3-3-4.6c-1.7-0.4-3.5,0.6-4.9,2c-1.4,1.4-2.5,3.4-2.9,5.9
							c-0.5,2.6-1,4.5-1.6,6.3c-0.7,1.8-1.6,3.7-3.7,5.8c2.7-1.3,4.5-2.3,6.5-3.3C508.4,121,510.4,119.9,512.9,117.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M529.5,112.5c2.1-2.5,3.1-5.6,2.9-8.1c-0.1-2.5-1.2-4.4-2.9-4.8c-1.7-0.5-3.6,0.5-5,2.2c-1.5,1.7-2.6,4.1-3,7
							c-0.4,3-0.6,5.1-1,7.2c-0.4,2.1-1,4.3-2.4,7.1c2.3-2.2,4-3.7,5.7-5.2C525.6,116.6,527.3,115.1,529.5,112.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M541.5,108.7c2-2,2.9-4.7,2.9-7c-0.1-2.2-1.2-4-2.8-4.5c-1.7-0.5-3.5,0.2-5,1.5c-1.5,1.4-2.6,3.4-2.9,6
							c-0.3,2.6-0.5,4.5-0.8,6.4c-0.3,1.9-0.8,3.8-2,6.3c2.1-1.9,3.6-3.1,5.2-4.3C537.8,112,539.4,110.8,541.5,108.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M558.6,100c2.1-2.3,3.1-5.3,3-7.7c0-2.4-1.1-4.3-2.7-4.9c-1.7-0.5-3.6,0.3-5.1,1.9c-1.5,1.6-2.7,4-3.1,6.8
							c-0.4,2.9-0.6,5-0.9,7.1c-0.4,2.1-0.9,4.2-2.2,7c2.2-2.2,3.8-3.7,5.5-5.1C554.7,103.8,556.5,102.4,558.6,100z"
                      />
                    </g>
                    <g>
                      <path
                        d="M573,93.6c2.2-1.8,3.6-4.1,4.1-6.2c0.5-2.2,0.2-4.2-1.2-5.3c-1.4-1.1-3.5-0.9-5.5,0.3c-2,1.2-3.9,3.5-4.7,6.5
							c-0.8,3-1.2,5.2-1.5,7.3c-0.4,2.1-0.8,4.2-1.6,7.1c1.8-2.4,3.3-3.9,4.9-5.2C569,96.6,570.7,95.4,573,93.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M587.3,86.9c2.3-1.1,3.9-2.9,4.8-4.7c0.8-1.8,0.9-3.8-0.1-5.2c-1-1.4-3.1-1.9-5.2-1.2c-2.2,0.6-4.5,2.4-5.7,5.1
							c-1.3,2.7-1.9,4.7-2.5,6.7c-0.6,2-1.2,3.9-2.3,6.5c1.9-2,3.6-3.2,5.3-4.1C583.1,88.9,584.9,88.1,587.3,86.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M602.4,93.3c2.3,0.6,4.4,0.3,6.1-0.6c1.7-0.9,3-2.3,3.1-4.1c0.1-1.7-1-3.4-3.1-4.3c-2-0.9-4.8-1-7.4,0.3
							c-2.6,1.3-4.2,2.6-5.7,3.7c-1.5,1.2-3.1,2.4-5.3,3.9c2.6-0.5,4.5-0.5,6.3-0.2C598.3,92.3,600,92.7,602.4,93.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M602.3,107.3c2.1,1.2,4.2,1.5,6.1,1.3c1.9-0.3,3.6-1.1,4.4-2.7c0.8-1.6,0.2-3.6-1.6-5.2c-1.8-1.6-4.7-2.6-7.7-2.1
							c-3.1,0.5-5.1,1.4-7,2.2c-2,0.9-3.8,1.7-6.3,2.8c2.8-0.1,4.7,0.4,6.5,1C598.4,105.2,600.1,106.1,602.3,107.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M598.1,120.2c2.4,1.8,5,2.6,7.4,2.6c2.3,0,4.2-0.9,4.9-2.5c0.7-1.6-0.1-3.6-1.8-5.3c-1.7-1.6-4.4-2.9-7.5-3.1
							c-6.3-0.3-8.9,0.5-15.1-0.2C591.6,114.5,593.3,116.5,598.1,120.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M455.6,157.1c1.3,2.2,2.9,3.7,4.7,4.7c1.8,0.9,3.8,1.2,5.3,0.3c1.5-0.9,2.1-3,1.3-5.4c-0.7-2.4-2.8-5-5.7-6.3
							c-3-1.3-5.3-1.7-7.4-2.1c-2.2-0.4-4.3-0.7-7.2-1.1c2.6,1.4,4.1,2.9,5.4,4.4C453.2,153.1,454.3,154.8,455.6,157.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M472.5,157.1c1.1,2.4,2.4,4.3,3.9,5.8c1.5,1.5,3.1,2.7,4.9,2.5c0.9-0.1,1.6-0.6,2.2-1.6c0.6-0.9,0.9-2.2,0.8-3.8
							c-0.1-1.5-0.5-3.3-1.5-4.9c-0.9-1.6-2.3-3.2-4-4.3c-1.8-1.1-3.4-1.8-4.8-2.2c-1.5-0.4-2.8-0.7-4.1-0.8
							c-2.6-0.3-5.1-0.3-8.6,0.2c3.4,0.6,5.5,1.9,7.1,3.3C470,152.9,471.3,154.7,472.5,157.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M487.8,156.6c1.5,2.4,3.4,4.2,5.4,5.3c1.9,1.1,4,1.5,5.5,0.6c1.5-0.9,2-3.1,1.1-5.7c-0.9-2.6-3.1-5.4-6.4-6.9
							c-3.3-1.5-5.7-2.1-8.1-2.5c-2.4-0.5-4.7-0.9-8-1.4c3,1.5,4.7,3.1,6.1,4.7C484.9,152.4,486.2,154.1,487.8,156.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M506.5,159.3c2.5,2.3,5.7,3.4,8.3,3.4c2.6,0,4.6-1.1,5.1-2.7c0.5-1.7-0.5-3.6-2.2-5.1c-1.7-1.6-4.2-2.8-7.2-3.3
							c-3-0.5-5.2-0.9-7.4-1.4c-2.2-0.5-4.3-1.3-7.2-2.9c2.2,2.5,3.6,4.3,5.1,6.1C502.5,155.1,504,157,506.5,159.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M525.3,155.5c3.6,1.7,7.5,2.2,10.5,1.7c3-0.5,5.1-1.9,5.2-3.6c0.2-1.8-1.5-3.5-4.1-4.7c-2.6-1.2-6-2-9.7-2
							c-3.8,0.1-6.5,0.2-9.2,0.1c-2.7-0.1-5.5-0.3-9.4-1.3c3.5,1.9,5.9,3.4,8.3,4.9C519.2,152.2,521.6,153.7,525.3,155.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M549,150.1c6.4,2.5,13.2,0.2,13.4-3.3c0.2-3.5-6-6.6-12.5-5.4c-6.7,1.1-9.4,2.3-16.4,2.3
							C540.3,145.7,542.5,147.5,549,150.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M568.8,144.1c3.4,1.2,7.1,1.2,9.7,0.4c2.7-0.8,4.5-2.3,4.5-4.1c0-1.8-1.7-3.3-4.2-4.3c-2.5-1-5.8-1.4-9.2-0.8
							c-3.5,0.6-6,1.1-8.5,1.5c-2.5,0.4-5.1,0.6-8.8,0.4c3.5,1.2,5.9,2.3,8.2,3.4C562.9,141.7,565.2,142.8,568.8,144.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M580.9,135.9c1.6,1.6,3.5,2.2,5.3,2.2c1.8,0,3.5-0.8,4.4-2.3c0.9-1.5,0.7-3.5-0.5-5.1c-1.2-1.6-3.4-2.9-6.1-3
							c-2.7,0-4.6,0.3-6.4,0.5c-1.8,0.3-3.6,0.5-6,0.6c2.3,0.9,3.7,1.9,5,3C578.1,133,579.3,134.2,580.9,135.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M591.4,129.9c3.6,4.4,9.6,5,11.5,2c1.9-3-1.3-8.1-6.8-9.4c-5.7-1.4-8.2-1.2-13.6-3.3
							C586.8,123.1,587.7,125.4,591.4,129.9z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M191.4,346.1c2,10.5,5.5,20.9,10.2,29.9c4.7,9.1,10.8,16.7,16.8,22.3c6,5.6,11.9,9.2,16.3,11.1
							c4.4,1.9,7.2,2.3,7.5,1.5c0.9-1.7-8.1-7.1-17.8-18.4c-4.8-5.7-9.8-12.8-14-21.1c-4.2-8.3-7.8-17.6-10.5-27.4
							c-2.8-10-4.2-18.8-5.5-26.9c-1.3-8.1-2.4-15.4-3.8-22.7c-1.4-7.3-3.2-14.7-6-22.6c-1.4-4-3.2-8.1-5.4-12.3
							c-2.2-4.2-5.1-8.5-8.7-12.7c6.8,8.8,10.2,17.6,12.4,25.5c2.2,8,3.2,15.3,4,22.6c0.7,7.3,1,14.6,1.5,22.8
							C188.8,326.1,189.3,335.3,191.4,346.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M197,289.9c1.7,2.6,4.4,4.1,6.8,4.5c2.4,0.4,4.4-0.3,5.2-1.9c0.8-1.6,0.3-3.5-0.8-5.3c-1.2-1.7-3-3.3-5.5-4.1
							c-2.6-0.9-4.5-1.6-6.3-2.5c-1.8-0.9-3.6-1.9-5.7-3.9c1.3,2.7,2.1,4.6,3,6.6C194.4,285.3,195.3,287.3,197,289.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M204,306.5c1.5,2.2,3.9,3.3,5.9,3.4c2.1,0.1,3.8-0.9,4.5-2.5c0.7-1.6,0.3-3.5-0.6-4.9c-1-1.5-2.5-2.6-4.7-3.1
							c-2.2-0.5-3.9-0.8-5.6-1.3c-1.6-0.5-3.2-1.2-5.2-2.6c1.2,2.2,1.9,3.8,2.7,5.4C201.7,302.5,202.5,304.3,204,306.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M209.3,323.1c1.8,2.1,4.2,3.1,6.4,3.2c2.1,0.1,3.9-0.8,4.6-2.5c0.7-1.6,0.2-3.5-1-5.1c-1.2-1.6-3.1-2.8-5.6-3.2
							c-2.5-0.4-4.4-0.6-6.2-1c-1.8-0.4-3.6-0.9-6-2c1.7,2,2.8,3.6,3.9,5.2C206.4,319.3,207.5,321,209.3,323.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M212.5,342.8c2.4,2.2,5.6,3,8,2.7c2.5-0.3,4.1-1.7,4.3-3.4c0.2-1.8-0.9-3.4-2.4-4.7c-1.5-1.3-3.6-2.2-6.1-2.4
							c-2.6-0.3-4.5-0.6-6.4-1.1c-1.9-0.5-3.8-1.3-6.1-3.2c1.5,2.5,2.6,4.3,3.8,6.2C208.8,338.7,210.1,340.6,212.5,342.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M219.1,359c2.6,1.9,5.8,2.6,8.3,2.2c2.5-0.3,4.3-1.6,4.6-3.3c0.3-1.7-0.8-3.5-2.7-4.8c-1.8-1.3-4.4-2.3-7.3-2.4
							c-3-0.1-5.1-0.1-7.3-0.4c-2.2-0.2-4.3-0.6-7.3-1.8c2.4,2.1,4,3.6,5.6,5.2C214.7,355.4,216.3,357,219.1,359z"
                      />
                    </g>
                    <g>
                      <path
                        d="M223.9,370.7c2.2,1.9,5,2.5,7.2,2.2c2.2-0.3,3.9-1.5,4.3-3.2c0.4-1.7-0.5-3.5-2-4.8c-1.5-1.4-3.7-2.3-6.2-2.3
							c-2.6-0.1-4.6-0.1-6.5-0.2c-1.9-0.2-3.8-0.5-6.4-1.5c2,1.9,3.4,3.4,4.7,4.9C220.3,367.2,221.6,368.7,223.9,370.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M234,386.9c2.5,1.9,5.5,2.6,7.9,2.4c2.4-0.2,4.2-1.4,4.6-3.1c0.4-1.7-0.6-3.5-2.4-4.9c-1.8-1.4-4.2-2.4-7.1-2.5
							c-3-0.1-5.1-0.2-7.2-0.3c-2.1-0.2-4.2-0.5-7.2-1.6c2.4,2,4,3.5,5.6,5C229.9,383.4,231.4,385,234,386.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M241.6,400.7c2,2.1,4.3,3.2,6.5,3.5c2.2,0.3,4.2-0.2,5.2-1.7c1-1.5,0.6-3.6-0.8-5.5c-1.4-1.9-3.8-3.6-6.8-4.2
							c-3.1-0.6-5.3-0.7-7.4-0.9c-2.1-0.2-4.3-0.4-7.2-1c2.6,1.6,4.2,3,5.6,4.5C238.2,397,239.6,398.6,241.6,400.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M249.5,414.4c1.3,2.2,3.2,3.7,5.1,4.3c1.9,0.7,3.9,0.6,5.2-0.6c1.3-1.2,1.6-3.2,0.7-5.3c-0.8-2.1-2.8-4.2-5.5-5.3
							c-2.8-1.1-4.9-1.5-6.9-1.9c-2-0.4-3.9-0.9-6.6-1.7c2.2,1.8,3.5,3.3,4.6,4.9C247.1,410.4,248.1,412.1,249.5,414.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M244.4,430c-0.4,2.4,0.1,4.4,1.1,6c1,1.6,2.6,2.7,4.3,2.7c1.8,0,3.3-1.3,4-3.4c0.7-2.1,0.6-4.9-0.9-7.3
							c-1.5-2.5-2.9-4-4.2-5.4c-1.3-1.4-2.6-2.9-4.3-4.9c0.7,2.6,0.8,4.4,0.7,6.2C245.1,425.8,244.8,427.6,244.4,430z"
                      />
                    </g>
                    <g>
                      <path
                        d="M230.5,431.1c-1,2.2-1.2,4.3-0.7,6.2c0.4,1.9,1.4,3.5,3.1,4.2c1.6,0.6,3.6-0.1,5-2.1c1.4-1.9,2.2-4.9,1.4-7.9
							c-0.8-3-1.8-4.9-2.8-6.8c-1-1.9-2-3.7-3.3-6.1c0.3,2.8,0,4.7-0.4,6.6C232.2,427,231.5,428.8,230.5,431.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M217.3,428c-1.6,2.5-2.2,5.3-1.9,7.5c0.2,2.3,1.2,4.1,2.9,4.7c1.7,0.5,3.6-0.4,5.1-2.2c1.5-1.9,2.5-4.7,2.4-7.7
							c-0.3-6.3-1.2-8.8-1.1-15C222.4,421,220.5,422.8,217.3,428z"
                      />
                    </g>
                    <g>
                      <path
                        d="M168.4,289.1c-2.1,1.5-3.5,3.2-4.2,5.1c-0.7,1.9-0.9,3.9,0.2,5.3c1,1.4,3.2,1.8,5.5,0.9c2.3-0.9,4.7-3.2,5.8-6.3
							c1.1-3.2,1.3-5.4,1.5-7.6c0.2-2.2,0.3-4.3,0.4-7.3c-1.2,2.7-2.5,4.3-3.9,5.7C172.1,286.4,170.5,287.6,168.4,289.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M169.7,305.9c-2.3,1.3-4,2.7-5.5,4.3c-1.4,1.6-2.5,3.4-2.1,5.1c0.2,0.9,0.8,1.6,1.7,2c1,0.5,2.3,0.7,3.8,0.5
							c1.5-0.2,3.2-0.8,4.8-1.9c1.5-1.1,3-2.6,3.9-4.4c1-1.9,1.5-3.5,1.8-5c0.3-1.5,0.4-2.8,0.5-4.1c0-2.6-0.1-5.2-0.9-8.6
							c-0.3,3.5-1.4,5.6-2.7,7.4C173.7,303.1,172.1,304.6,169.7,305.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M171.5,321.1c-2.3,1.7-3.9,3.7-4.8,5.8c-0.9,2-1.2,4.1-0.1,5.5c1,1.4,3.3,1.7,5.8,0.6c2.5-1.1,5.1-3.6,6.3-6.9
							c1.2-3.4,1.6-5.9,1.8-8.3c0.3-2.4,0.5-4.7,0.7-8.1c-1.3,3.1-2.6,4.9-4.1,6.5C175.6,317.9,173.9,319.3,171.5,321.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M170.5,340.1c-2.1,2.7-2.9,6-2.6,8.5c0.2,2.6,1.4,4.5,3.2,4.8c1.7,0.3,3.5-0.8,4.9-2.6c1.4-1.9,2.4-4.4,2.7-7.4
							c0.3-3.1,0.4-5.3,0.8-7.5c0.4-2.2,0.9-4.4,2.3-7.4c-2.3,2.4-3.9,4-5.6,5.6C174.4,335.7,172.6,337.3,170.5,340.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M175.9,358.4c-1.4,3.7-1.5,7.7-0.8,10.6c0.7,3,2.3,4.9,4,4.9c1.8,0,3.3-1.8,4.3-4.5c1-2.7,1.5-6.2,1.1-9.9
							c-0.4-3.8-0.7-6.5-0.9-9.2c-0.2-2.7-0.2-5.5,0.4-9.4c-1.6,3.7-2.9,6.2-4.2,8.7C178.7,352.1,177.4,354.6,175.9,358.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M183.3,381.6c-1.9,6.6,0.9,13.2,4.4,13.1c3.5-0.1,6-6.5,4.4-12.9c-1.7-6.6-3.1-9.1-3.7-16.1
							C186.9,372.5,185.3,374.9,183.3,381.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M190.9,400.8c-0.9,3.5-0.6,7.1,0.4,9.7c1,2.6,2.7,4.3,4.5,4.1c1.8-0.1,3.2-2,3.9-4.5c0.8-2.6,0.9-5.9,0-9.3
							c-0.9-3.5-1.6-5.9-2.2-8.4c-0.6-2.5-1-5-1.2-8.7c-0.9,3.6-1.8,6-2.6,8.5C192.8,394.7,191.9,397.2,190.9,400.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M200.2,412.2c-1.4,1.7-1.9,3.7-1.8,5.5c0.2,1.8,1.1,3.4,2.7,4.2c1.6,0.8,3.5,0.4,5-1c1.5-1.3,2.6-3.7,2.5-6.3
							c-0.2-2.7-0.6-4.5-1.1-6.3c-0.4-1.8-0.8-3.5-1.1-6c-0.7,2.4-1.6,3.9-2.6,5.3C202.8,409.1,201.7,410.4,200.2,412.2z"
                      />
                    </g>
                    <g>
                      <path d="M207,422.2c-4,4-4.1,10-1,11.6c3.1,1.6,8-2,8.8-7.6c0.9-5.8,0.5-8.2,2.1-13.8C213.4,417,211.1,418.1,207,422.2z" />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M378.5,8.1c9.5-5,18.3-11.4,25.5-18.6c7.3-7.2,12.8-15.2,16.3-22.6c3.6-7.4,5.3-14.1,5.8-18.9c0.5-4.8,0-7.5-0.8-7.6
							c-1.9-0.3-4.3,9.8-12.3,22.5c-4,6.3-9.3,13.2-16,19.6c-6.6,6.5-14.6,12.7-23,18.1c-8.7,5.6-16.7,9.6-24,13.2
							c-7.3,3.6-14,6.8-20.6,10.4c-6.6,3.6-13.1,7.4-19.8,12.5c-3.4,2.5-6.8,5.4-10.1,8.8c-3.4,3.4-6.6,7.4-9.6,12
							c6.4-9.1,13.8-14.9,20.7-19.4c7-4.5,13.7-7.6,20.5-10.5c6.8-2.8,13.7-5.3,21.4-8.2C360.1,16.5,368.7,13.3,378.5,8.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M323.1,19.3c2-2.4,2.7-5.4,2.3-7.8c-0.3-2.4-1.6-4.1-3.3-4.4c-1.7-0.3-3.5,0.7-4.8,2.3c-1.3,1.6-2.2,3.9-2.3,6.5
							c-0.1,2.7-0.2,4.7-0.5,6.7c-0.3,2-0.8,4-2.1,6.6c2.2-2,3.7-3.4,5.4-4.8C319.4,23.2,321.1,21.8,323.1,19.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M336.9,7.8c1.6-2.1,2-4.7,1.5-6.6c-0.5-2-2-3.4-3.7-3.5c-1.8-0.2-3.4,0.7-4.5,2.1c-1.1,1.4-1.8,3.2-1.6,5.4
							c0.2,2.3,0.4,4,0.4,5.7c0,1.7-0.1,3.4-1,5.8c1.7-1.8,3-3,4.4-4.2C333.8,11.1,335.2,9.9,336.9,7.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M351.2-2.2c1.5-2.3,1.7-5,1.1-7c-0.6-2.1-2-3.5-3.7-3.7c-1.8-0.2-3.4,0.8-4.6,2.4c-1.1,1.6-1.8,3.8-1.4,6.3
							c0.4,2.6,0.7,4.4,0.9,6.3c0.2,1.9,0.3,3.7-0.1,6.4c1.4-2.3,2.6-3.8,3.8-5.3C348.4,1.6,349.7,0.1,351.2-2.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M369.1-11.1c1.4-2.9,1.2-6.2,0.2-8.4c-1-2.3-2.9-3.4-4.6-3.1c-1.7,0.3-3,1.8-3.8,3.7c-0.8,1.9-1,4.1-0.5,6.5
							c0.5,2.6,0.8,4.5,0.9,6.5c0,2-0.1,4-1.2,6.8c2-2.2,3.4-3.8,4.8-5.5C366.2-6.4,367.6-8.1,369.1-11.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M382.6-22.2c1-3.1,0.7-6.3-0.3-8.6c-1-2.3-2.8-3.6-4.5-3.4c-1.8,0.2-3.1,1.8-3.8,4c-0.7,2.1-0.9,4.8-0.1,7.6
							c0.8,2.9,1.4,5,1.8,7.1c0.4,2.1,0.7,4.3,0.4,7.5c1.3-2.9,2.3-4.9,3.3-6.9C380.4-16.9,381.5-19,382.6-22.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M392.3-30.2c1.1-2.7,1-5.5,0-7.5c-0.9-2-2.6-3.3-4.3-3.1c-1.8,0.1-3.2,1.5-4,3.3c-0.8,1.8-1.1,4.2-0.4,6.6
							c0.7,2.5,1.3,4.4,1.7,6.3c0.4,1.9,0.7,3.8,0.5,6.6c1.2-2.5,2.2-4.2,3.2-5.9C390-25.7,391.1-27.5,392.3-30.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M404.8-44.7c1.1-3,0.9-6-0.1-8.3c-1-2.2-2.6-3.6-4.4-3.5c-1.8,0.1-3.2,1.6-4,3.7c-0.8,2.1-1,4.7-0.3,7.5
							c0.8,2.9,1.4,4.9,1.8,7c0.4,2.1,0.8,4.2,0.6,7.3c1.2-2.9,2.1-4.8,3.1-6.8C402.6-39.7,403.7-41.7,404.8-44.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M415.7-56.1c1.4-2.5,1.8-5.1,1.4-7.3c-0.3-2.2-1.4-3.9-3.1-4.4c-1.7-0.5-3.6,0.4-5,2.3c-1.4,1.9-2.3,4.7-1.9,7.8
							c0.4,3.1,0.9,5.2,1.3,7.3c0.5,2.1,0.9,4.2,1.2,7.2c0.8-2.9,1.6-4.9,2.6-6.7C413.2-51.8,414.3-53.5,415.7-56.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M426.4-67.6c1.7-1.9,2.5-4.1,2.6-6.1c0.1-2-0.6-3.9-2.1-4.8c-1.5-0.9-3.5-0.6-5.3,0.9c-1.8,1.4-3.2,3.9-3.4,6.8
							c-0.2,3,0,5.1,0.2,7.1c0.2,2,0.3,4,0.3,6.9c1-2.6,2.1-4.3,3.3-5.8C423.3-64.2,424.7-65.6,426.4-67.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M442.9-67.4c2.4-0.3,4.2-1.4,5.4-2.9c1.2-1.4,1.9-3.2,1.3-4.9c-0.5-1.7-2.2-2.8-4.4-2.8c-2.2-0.1-4.8,0.9-6.7,3.1
							c-1.9,2.2-2.9,3.9-3.9,5.6c-1,1.7-2,3.3-3.4,5.6c2.2-1.5,4-2.1,5.7-2.6C438.6-66.8,440.4-67.1,442.9-67.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M448-54.4c2.4,0.3,4.5-0.2,6.2-1.1c1.7-1,3-2.4,3.1-4.2c0.1-1.7-1.2-3.4-3.4-4.2c-2.2-0.8-5.3-0.6-7.9,1
							c-2.7,1.7-4.2,3.2-5.7,4.6c-1.5,1.6-2.9,3-4.8,5c2.6-1.1,4.5-1.4,6.4-1.5C443.6-54.9,445.5-54.7,448-54.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M449-40.9c2.9,0.8,5.7,0.5,7.8-0.4c2.1-0.9,3.6-2.4,3.6-4.2c0-1.8-1.4-3.3-3.6-4.2c-2.2-0.9-5.2-1-8.1,0
							c-6,2.1-8,3.8-14,5.5C440.8-43.7,443.1-42.4,449-40.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M330.8,46.9c2,1.6,4.1,2.4,6.1,2.5c2,0.2,3.9-0.3,5-1.7c1-1.4,0.8-3.5-0.8-5.5c-1.5-1.9-4.4-3.6-7.7-3.6
							c-3.3-0.1-5.5,0.4-7.7,0.8c-2.1,0.5-4.2,1-7.1,1.7c3,0.3,4.9,1.1,6.6,2C327.1,44.2,328.7,45.3,330.8,46.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M346.5,40.6c1.9,1.8,3.8,3.1,5.8,3.9c1.9,0.8,3.9,1.4,5.5,0.5c0.8-0.4,1.3-1.2,1.4-2.3c0.2-1.1,0-2.4-0.7-3.8
							c-0.6-1.4-1.7-2.8-3.2-4c-1.5-1.2-3.3-2.1-5.3-2.4c-2.1-0.4-3.8-0.4-5.3-0.3c-1.5,0.1-2.8,0.4-4.1,0.8
							c-2.5,0.7-4.9,1.6-7.9,3.4c3.4-0.8,5.8-0.3,7.9,0.4C342.6,37.7,344.5,38.8,346.5,40.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M360.5,34.4c2.3,1.7,4.7,2.6,6.9,2.9c2.2,0.3,4.3-0.1,5.3-1.5c1-1.4,0.7-3.6-1.1-5.7c-1.8-2.1-4.9-3.8-8.5-4
							c-3.6-0.2-6.1,0.3-8.5,0.7c-2.4,0.5-4.7,1-7.9,1.7c3.3,0.3,5.5,1.1,7.4,2C356.2,31.5,358.1,32.7,360.5,34.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M378.8,29.8c3.2,1.2,6.5,1,8.9,0c2.4-1,3.8-2.7,3.7-4.4c-0.2-1.8-1.8-3.1-4-3.9c-2.2-0.8-5-1-7.9-0.4
							c-3,0.7-5.2,1.2-7.4,1.5c-2.2,0.3-4.5,0.4-7.8,0c3,1.5,5,2.6,7,3.7C373.5,27.4,375.6,28.6,378.8,29.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M394.8,19.2c4,0.3,7.8-0.8,10.4-2.4c2.6-1.6,4-3.6,3.5-5.3c-0.5-1.7-2.7-2.6-5.6-2.8c-2.9-0.2-6.4,0.4-9.8,1.9
							c-3.5,1.5-6,2.6-8.5,3.6c-2.5,1-5.2,1.8-9.2,2.4c4,0.5,6.7,0.9,9.5,1.4C387.9,18.4,390.7,19,394.8,19.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M414.8,5.3c6.9-0.1,12.4-4.8,11.2-8.1c-1.2-3.3-8-3.8-13.6-0.3c-5.8,3.6-7.8,5.6-14.3,8.3
							C405,4.5,407.7,5.3,414.8,5.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M430.8-7.7c3.7-0.2,7-1.5,9.2-3.3c2.2-1.7,3.3-3.8,2.6-5.5c-0.7-1.6-2.8-2.4-5.5-2.4c-2.7,0-5.9,0.9-8.8,2.7
							c-3,1.9-5.1,3.3-7.3,4.6c-2.2,1.3-4.5,2.5-8,3.7c3.7-0.2,6.3-0.1,8.9,0C424.5-7.7,427.1-7.5,430.8-7.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M439-19.9c2.1,0.9,4.1,0.8,5.8,0c1.6-0.7,3-2.1,3.3-3.8c0.3-1.7-0.7-3.5-2.4-4.5c-1.7-1.1-4.3-1.4-6.7-0.5
							c-2.5,1-4.1,2-5.7,2.9c-1.6,0.9-3.1,1.8-5.4,2.9c2.5,0,4.2,0.4,5.8,0.9C435.2-21.5,436.8-20.8,439-19.9z"
                      />
                    </g>
                    <g>
                      <path d="M446.5-29.3c5,2.7,10.8,1,11.4-2.5c0.6-3.5-4.3-7-9.9-6.2c-5.8,0.9-8,2-13.8,2C439.6-33.9,441.3-32.1,446.5-29.3z" />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M533.7,299.2c0.6-0.6,1.1-1.2,1.6-1.8c0.5-0.7,1-1.3,1.5-2c0.9-1.4,1.8-2.8,2.4-4.3c1.4-3,2.2-6.2,2.6-9.4
						c0.8-6.4-0.3-12.8-2.8-18.6c-1.2-2.9-3-5.7-5-8.1c-2-2.4-4.2-4.5-6.6-6.4c-4.8-3.6-10.2-6.1-15.6-7.6c-2.7-0.7-5.5-1.3-8.2-1.5
						c-2.7-0.3-5.5-0.3-8.1-0.2c-5.4,0.3-10.8,1.5-15.7,3.8c-5,2.3-9.4,5.8-12.6,10c-1.6,2.1-2.9,4.5-3.9,6.9
						c-0.9,2.4-1.5,5.1-1.6,7.7c-0.3,5.1,1,10,3.5,14.1c1.3,2,2.9,3.8,4.7,5.2c1.8,1.4,3.8,2.4,5.8,3.1c4,1.3,8,1.3,11.5,0.3
						c3.5-1,6.4-3.3,8-5.7c1.7-2.4,2.4-4.9,2.6-7c0.2-2.1-0.1-4-0.6-5.3c-0.5-1.4-1.2-2.3-1.7-2.8c-0.6-0.5-1-0.6-1.3-0.5
						c-0.6,0.3-0.4,1.7-0.7,3.8c-0.1,1-0.4,2.2-1.1,3.5c-0.6,1.2-1.5,2.6-2.7,3.6c-0.6,0.5-1.3,0.9-2,1.3c-0.7,0.3-1.6,0.5-2.5,0.6
						c-1.8,0.2-3.8-0.2-5.7-1.2c-0.9-0.5-1.8-1.1-2.5-1.9c-0.7-0.7-1.3-1.6-1.8-2.7c-1-2.1-1.4-4.7-1-7.3c0.2-1.3,0.6-2.5,1.2-3.8
						c0.6-1.2,1.4-2.4,2.4-3.6c1-1.1,2.1-2.2,3.4-3.1c1.3-0.9,2.7-1.7,4.3-2.3c6.2-2.5,14.7-2.8,22.4-0.6c3.8,1.1,7.5,2.8,10.6,5.1
						c1.5,1.2,3,2.5,4.2,3.9c0.6,0.7,1.1,1.5,1.7,2.2l0.7,1.2l0.6,1.3c1.6,3.4,2.4,7.4,2.2,11.1c-0.1,1.9-0.5,3.7-1.2,5.4
						c-0.3,0.9-0.7,1.6-1.2,2.4l-0.7,1.1c-0.3,0.4-0.5,0.7-0.9,1.1c-2.5,3-6.1,5.2-10.1,6.9c-2,0.9-4.1,1.6-6.1,2.2
						c-1.1,0.3-2.1,0.6-3.2,0.9c-1.1,0.3-2.1,0.6-3.1,0.8c-8.4,1.9-16.5,2.6-24.2,2.5c-7.7-0.1-15.1-1.1-22.2-2.7
						c-7.1-1.6-13.8-3.8-20.3-6.6c-13-5.7-25.3-13.9-36.8-25.5c-5.7-5.8-11.3-12.5-16.4-20.3c-5.2-7.7-9.9-16.7-13.9-26.8
						c3.7,10.3,8,19.4,12.8,27.5c4.8,8.1,10,15.2,15.6,21.4c11.1,12.5,23,21.8,36.3,28.8c6.6,3.5,13.5,6.4,20.9,8.7
						c7.4,2.3,15.2,3.9,23.5,4.7c8.4,0.8,17.3,0.7,26.8-0.7c1.2-0.1,2.4-0.4,3.6-0.6c1.2-0.2,2.4-0.5,3.6-0.7c2.5-0.6,5-1.2,7.6-2.1
						c2.6-0.9,5.2-2,7.8-3.5C528.7,303.5,531.3,301.6,533.7,299.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M454.1,257.7c3.8-5.9,5.1-13.2,3.9-20.1c-0.6-3.4-1.9-6.8-3.7-9.7c-1.8-2.9-4.1-5.5-6.7-7.6c-2.6-2.1-5.5-3.7-8.6-4.7
						c-3.1-1-6.3-1.5-9.5-1.3c-3.2,0.2-6.3,0.9-9.1,2.4c-0.7,0.3-1.4,0.8-2,1.2c-0.7,0.5-1.3,0.9-1.9,1.4c-1.2,1-2.2,2.1-3.1,3.3
						c-1.7,2.4-2.8,5-3.3,7.6c-0.5,2.6-0.3,5.3,0.4,7.7c0.8,2.5,2.1,4.4,3.6,5.7c1.5,1.4,3,2.3,4.5,2.9c1.5,0.6,2.9,0.8,4.1,0.8
						c0.6,0,1.2-0.1,1.7-0.2c0.5-0.1,0.9-0.3,1.3-0.4c0.8-0.4,1.3-0.8,1.5-1.2c0.3-0.4,0.4-0.7,0.3-1.1c-0.2-0.7-1-1-2-1.6
						c-1-0.6-2.2-1.3-3.3-2.6c-1.1-1.2-1.9-3.1-1.8-4.5c0.1-0.9,0.2-1.7,0.7-2.6c0.4-0.9,1.1-1.8,1.9-2.6c0.4-0.4,0.9-0.7,1.3-1
						c0.2-0.1,0.5-0.2,0.7-0.4c0.3-0.1,0.5-0.2,0.8-0.3c1.1-0.4,2.4-0.5,3.9-0.5c2.9,0.1,5.9,1.3,8.6,3.4c1.3,1.1,2.5,2.4,3.4,3.8
						c0.9,1.5,1.6,3,2,4.7c0.8,3.4,0.5,7.4-1.1,10.7c-1.6,3.5-4.6,6.3-7.9,8.3c-3.4,1.9-7,3-10.6,3.3c-3.7,0.4-7.3,0.1-10.9-0.6
						c-3.5-0.7-7-1.8-10.4-3.2c-3.4-1.4-6.6-3.1-9.9-5.2c-3.2-2.1-6.3-4.6-9.4-7.5c-3-2.9-6-6.3-8.7-10.3c-2.7-4-5.2-8.6-7.1-14
						c3.2,11,8.3,19.4,13.9,26.1c2.8,3.4,5.7,6.3,8.8,9c3.1,2.6,6.3,4.9,9.7,7c3.4,2.1,7,3.8,11,5.2c3.9,1.4,8.2,2.4,13,2.7
						c4.7,0.3,10-0.4,15.2-2.5c2.6-1,5.2-2.5,7.8-4.4C449.8,263.2,452.2,260.8,454.1,257.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M336.8,288.9c-1.6,6.2-2,12.8-1.5,18.8c0.5,6,2,11.6,3.9,16c1.9,4.5,4.2,7.8,6.2,9.9c2.1,2.1,3.9,2.9,5,2.3
						c1.2-0.6,1.7-2.5,1.6-5.1c0.1-2.7-0.4-6.2-0.8-10.4c-0.4-4.1-0.9-8.8-1.2-13.8c-0.3-5-0.4-10.4-0.3-15.8
						c0.1-5.6,0.8-10.4,1.7-14.7c0.9-4.3,2.2-8.3,3.4-12.5c1.2-4.1,2.4-8.3,3.2-13.2c0.4-2.4,0.6-5,0.6-7.8c0-2.8-0.4-5.7-1.3-8.7
						c0.5,3.1,0.5,6,0.1,8.6c-0.4,2.6-1.1,5-1.9,7.2c-1.6,4.4-3.7,8-5.9,11.6c-2.2,3.6-4.5,7.2-6.8,11.5
						C340.7,277.4,338.5,282.5,336.8,288.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M350.5,287.2c-2,4.9-4.1,10-5.6,15.3c-1.5,5.3-2.2,10.7-1.9,15.5c0.3,4.8,1.5,8.7,3,11.2c1.5,2.5,3.2,3.5,4.5,3.1
						c1.3-0.4,2-2,2.7-4.3c0.7-2.3,1.4-5.3,2.3-8.9c0.9-3.6,2.2-7.7,3.7-12.4c1.4-4.7,3-10.1,4-15.9c0.9-6,1.2-11.1,1.2-15.7
						c0-4.6-0.4-8.7-0.9-12.8c-0.5-4.1-1.2-8.1-2.1-12.5c-1-4.4-2.2-9.3-4.1-14.9c1.1,5.8,1.4,10.8,1.4,15.2c0,4.4-0.4,8.4-0.9,12.2
						c-0.6,3.8-1.4,7.6-2.5,11.6C354.1,277.9,352.6,282.2,350.5,287.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M393.3,330.8c-0.5,0.7-1,1.3-1.5,1.9c-0.5,0.6-1.1,1.1-1.6,1.6c-1.2,0.9-2.4,1.7-3.7,2.3c-1.3,0.6-2.5,0.8-3.7,0.9
						c-1.2,0.1-2.1,0-3.2-0.4c-2.1-0.6-3.9-2.2-4.8-3.6c-0.4-0.7-0.7-1.4-0.8-2.3c-0.1-0.9,0-1.8,0.2-2.8c0.5-1.9,1.6-3.7,2.7-4.9
						c1.2-1.2,2-1.6,2.4-2c0.3-0.1,0.8-0.1,1.2,0c0.4,0.1,0.8,0.3,1.1,0.5c0.7,0.4,1.1,1.1,1.7,1.4c0.3,0.1,0.7,0.1,1.1-0.1
						c0.4-0.3,0.8-0.8,1.1-1.6c0.2-0.8,0.2-2-0.2-3.1c-0.4-1.2-1.2-2.4-2.6-3.5c-1.6-1.1-3.4-1.5-5.4-1.5c-1.9,0-3.8,0.5-5.6,1.3
						c-3.5,1.6-6.7,4.5-9,8.7c-1.1,2.1-1.9,4.6-2.1,7.3c-0.2,2.7,0.2,5.8,1.4,8.5c1.1,2.8,2.9,5.2,5.1,7.2c2.2,2,4.8,3.6,7.7,4.6
						c2.9,1,6.3,1.4,9.3,1.1c3.1-0.3,6.1-1.2,8.7-2.6c2.7-1.4,5-3.2,7.1-5.3c1-1.1,1.9-2.2,2.8-3.4c0.8-1.2,1.5-2.4,2.1-3.7
						c2.5-5.3,3.2-10.4,3.2-14.9c0-4.6-0.8-8.7-1.8-12.3c-1-3.7-2.3-6.9-3.6-10c-1.3-3.1-2.7-6-4.2-8.9c-1.5-2.9-3-5.7-4.8-8.6
						c-1.7-2.9-3.5-5.8-5.6-8.7c-4.1-6-8.9-12.3-15.4-19.3c5.9,7.4,9.9,14.3,13.1,20.6c1.6,3.2,3,6.2,4.2,9.2c1.2,3,2.2,6,3.2,8.9
						c0.9,3,1.7,6,2.4,9c0.7,3.1,1.3,6.2,1.6,9.4c0.3,3.2,0.3,6.5-0.3,9.8C396.4,324.7,395.3,328,393.3,330.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M211.1,46.3c3.4-0.8,6.9-1,10.2-0.6c3.3,0.4,6.4,1.5,8.9,3.1c2.6,1.6,4.7,3.9,6.2,6.4c0.7,1.2,1.3,2.5,1.7,3.8
						c0.4,1.3,0.5,2.6,0.6,3.9c0.1,5.3-3,11-7.3,15.2c-1.1,1.1-2.2,2.1-3.4,2.9c-1.2,0.9-2.4,1.7-3.5,2.4c-2.4,1.4-4.7,2.1-6.6,2.3
						c-1,0.1-1.9,0-2.6-0.2c-0.8-0.2-1.6-0.5-2.3-1c-1.5-1-2.7-2.4-3.5-3.9c-0.4-0.8-0.6-1.5-0.8-2.3c-0.1-0.4-0.1-0.8-0.2-1.1
						c0-0.4,0-0.7,0-1.1c0.1-1.5,0.6-3,1.3-4.2c1.4-2.5,3.4-4.2,4.9-5.2c1.5-1,2.5-1.6,2.5-2.3c0-0.6-1.2-1.4-3.6-1.3
						c-1.2,0-2.7,0.3-4.3,0.9c-1.6,0.7-3.4,1.7-5.1,3.3c-1.7,1.6-3.3,3.8-4.3,6.8c-0.3,0.7-0.4,1.5-0.6,2.3
						c-0.1,0.8-0.2,1.6-0.2,2.5c-0.1,1.7,0.2,3.4,0.6,5.2c0.9,3.5,2.9,6.8,5.9,9.6c1.5,1.4,3.4,2.6,5.5,3.4c2.2,0.9,4.4,1.3,6.6,1.4
						c4.5,0.2,8.9-1,12.7-2.8c1.9-0.9,3.7-1.9,5.5-3.1c1.8-1.2,3.5-2.5,5.1-4c1.6-1.4,3.2-3.1,4.6-4.9c1.4-1.8,2.7-3.7,3.9-5.8
						c1.1-2.1,2.1-4.4,2.8-6.9c0.7-2.5,1.1-5.1,1.1-7.8c0-2.7-0.3-5.5-1.1-8.1c-0.8-2.6-1.9-5.1-3.3-7.4c-2.8-4.6-6.7-8.4-11.4-11.2
						c-4.7-2.8-9.9-4.2-15.1-4.6c-5.2-0.4-10.3,0.3-15.2,1.9c-5,1.6-9.3,4-13,6.7c-3.7,2.7-6.9,5.8-9.6,9
						c-5.3,6.4-9.1,13.2-11.6,19.9c-2.5,6.7-3.9,13.4-4.6,19.9c-0.7,6.5-0.7,12.8-0.1,19.1c0.6,6.3,1.8,12.4,3.6,18.6
						c1.8,6.2,4.1,12.4,6.9,18.7c5.7,12.7,13.8,25.8,24.4,40.6c-10-15.2-17.2-28.8-22-41.6c-2.4-6.4-4.2-12.6-5.4-18.7
						c-1.2-6.1-1.8-12-1.9-18c0-5.9,0.5-11.8,1.7-17.7c1.2-5.8,3-11.7,5.8-17.4c2.8-5.6,6.5-11.1,11.4-16c2.4-2.4,5.2-4.6,8.2-6.5
						C204.3,48.6,207.6,47.1,211.1,46.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M217.7,111.6c1.7-0.5,3.2-0.7,4.8-0.7c1.5,0,2.9,0.2,4,0.6c0.5,0.2,1,0.4,1.3,0.7c0.4,0.2,0.7,0.5,0.9,0.8
						c0.5,0.6,1,1.5,1.3,2.5c0.2,0.5,0.3,1.1,0.3,1.7c0.1,0.6,0.1,1.1,0,1.7c-0.1,1.1-0.4,2.2-0.7,3c-0.2,0.4-0.4,0.8-0.6,1.1
						c-0.2,0.3-0.4,0.6-0.7,0.8c-0.3,0.2-0.4,0.4-0.7,0.6c-0.3,0.2-0.6,0.3-1,0.4c-0.7,0.2-1.5,0.2-2.3,0.1c-0.7-0.1-1.4-0.3-2-0.7
						c-1.2-0.6-2.2-2-2.8-3.3c-0.6-1.3-0.9-2.6-1.1-3.6c-0.2-1-0.5-1.8-1-2.2c-0.5-0.3-1.4-0.2-2.5,0.7c-1,0.9-2.2,2.7-2.6,5.4
						c-0.2,1.3-0.2,2.9,0.1,4.5c0.3,1.7,1,3.5,2.3,5.2c2.5,3.5,7,6.2,12.4,6.3c1.3,0,2.7-0.1,4.2-0.4c1.4-0.3,2.9-0.9,4.2-1.7
						c1.3-0.8,2.5-1.7,3.6-2.8c1.1-1.1,2-2.3,2.8-3.5c1.5-2.5,2.5-5.3,3-8.3c0.2-1.5,0.3-3,0.2-4.5c-0.1-1.5-0.3-3-0.7-4.5
						c-0.8-3-2.3-6.1-4.8-8.7c-2.5-2.6-5.8-4.5-9-5.3c-3.2-0.9-6.4-1-9.4-0.6c-1.5,0.2-2.9,0.5-4.3,0.9c-1.4,0.4-2.7,1-4,1.5
						c-5.3,2.4-9.2,5.9-12.2,9.4c-1.5,1.8-2.8,3.6-3.9,5.4c-1.1,1.9-2,3.7-2.8,5.5c-3,7.5-3.6,14.5-3.3,21.3
						c0.3,6.8,1.7,13.4,4,20.4c2.2,7,5.4,14.5,9.3,23.3c-3.1-9.1-5.5-16.9-6.7-24c-1.3-7.1-1.6-13.5-0.8-19.6
						c0.7-6.1,2.5-12,5.9-17.3c0.8-1.3,1.8-2.6,2.9-3.9c1.1-1.2,2.3-2.4,3.5-3.5C211.2,114.4,214.2,112.5,217.7,111.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M252.1,32.8c3.2,0.6,6.4-0.3,8.5-1.8c2.1-1.5,3-3.4,2.5-5.1c-0.6-1.7-2.4-2.7-4.6-3c-2.2-0.3-4.8,0-7.4,1.2
						c-2.7,1.2-4.6,2.1-6.6,2.8c-2,0.7-4.2,1.2-7.3,1.3c3,1,5.1,1.7,7.3,2.5C246.6,31.4,248.8,32.2,252.1,32.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M267.3,45.6c1.8,2,3.7,3.3,5.6,4.1c1.9,0.8,4,1.1,5.5,0.2c1.5-0.9,1.9-3.2,0.7-5.8c-1.2-2.6-4-5.2-7.6-6.1
						c-3.7-0.9-6.2-0.8-8.7-0.6c-2.4,0.2-4.8,0.6-8,1.4c3.3,0.3,5.4,1.2,7.2,2.3C263.8,42.2,265.4,43.6,267.3,45.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M270,60.5c0.6,2.6,1.5,4.6,2.7,6.3c1.2,1.6,2.7,3,4.5,3.1c0.9,0,1.7-0.4,2.4-1.2c0.3-0.4,0.7-0.9,0.9-1.5
						c0.2-0.6,0.4-1.3,0.5-2c0.4-2.9-0.8-6.8-3.6-9.5c-2.9-2.7-5.2-3.8-7.5-4.7c-2.3-0.9-4.7-1.6-7.9-2.2c3,1.6,4.4,3.3,5.6,5.1
						C268.6,55.8,269.4,57.8,270,60.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M263.1,73.6c-0.7,2.7-0.6,5.1,0.1,7.2c0.7,2,1.9,3.7,3.7,4c1.7,0.3,3.5-0.9,4.7-3.2c1.1-2.3,1.5-5.6,0.3-8.6
						c-1.2-3.2-2.4-5.1-3.5-7.1c-1.2-1.9-2.3-3.8-3.9-6.5c0.6,3.1,0.5,5.2,0.2,7.3C264.3,68.8,263.9,70.8,263.1,73.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M251.6,86.6c-1.8,2-2.8,4.1-3.2,6.2c-0.4,2.1-0.1,4.1,1.2,5.3c1.3,1.2,3.5,1,5.7-0.5c2.1-1.5,4-4.4,4.4-7.7
						c0.4-3.4,0.1-5.8-0.2-8c-0.3-2.3-0.7-4.5-1.2-7.5c-0.5,3.1-1.4,5-2.5,6.8C254.7,82.9,253.5,84.5,251.6,86.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M241.2,20.8c6-2.2,9.5-7.9,7.4-10.8c-2.1-2.9-8.6-1.4-12.5,3.7c-2,2.6-3.3,4.5-4.6,6.4c-0.7,0.9-1.3,1.9-2.1,2.7
						c-0.4,0.4-0.9,0.8-1.5,1.1c-0.6,0.3-1.4,0.4-2.1,0.2c0.7,0.5,1.5,0.7,2.3,0.6c0.8,0,1.5-0.2,2.2-0.4c0.7-0.2,1.3-0.4,1.9-0.6
						c0.6-0.2,1.1-0.4,1.7-0.6C235.8,22.5,238.1,21.9,241.2,20.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M227.4,15.6c2.1-2.5,2.5-5.9,1.9-8.2c-0.7-2.4-2.4-3.8-4.1-3.6c-1.8,0.1-3.2,1.4-4.2,2.9c-1,1.5-1.6,3.3-1.6,5.5
						c0,2.4-0.2,4.2-0.7,5.9c-0.5,1.8-1.3,3.6-3.2,5.6c2.6-1.2,4.4-2.1,6.2-3.2C223.5,19.4,225.3,18.1,227.4,15.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M216.5,14.7c1.1-3.1,0.5-6.4-0.9-8.5c-1.4-2.1-3.4-2.9-5-2.3c-1.7,0.6-2.6,2.3-3.1,4.1c-0.5,1.8-0.5,3.9,0.3,6.1
						c0.8,2.4,1.2,4.2,1.4,6.1c0.2,1.9,0.1,3.9-1,6.6c1.9-2.1,3.3-3.8,4.6-5.5C214.1,19.6,215.4,17.8,216.5,14.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M204,15.5c-0.4-2.8-2.4-5-4.4-5.8c-2.1-0.8-4-0.4-5.1,1c-1.1,1.4-1.2,3.2-0.9,4.8c0.3,1.6,1.1,2.9,2.7,4.1
						c1.7,1.2,2.9,2.3,3.9,3.5c1,1.2,1.9,2.7,2.4,5.1c0.6-2.4,0.9-4.1,1.3-5.9C204.2,20.4,204.4,18.4,204,15.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M189.6,18.1c-1.2-0.9-2.6-1.3-4-1.3c-1.4,0-2.6,0.3-3.6,1c-1,0.6-1.6,1.4-1.9,2.3c-0.3,0.9-0.2,1.7,0.1,2.5
						c0.7,1.6,2.1,2.7,3.1,3.4c1.1,0.7,2,0.9,3.4,0.4c1.5-0.5,2.9-0.5,4.3-0.3c1.4,0.3,2.8,1,4.1,2.7c-0.3-2.2-0.8-3.8-1.5-5.5
						C193,21.7,192,19.9,189.6,18.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M174.9,28.6c-3.1,0-5.9,1.8-7.2,3.8c-1.3,2-1.2,4.2,0,5.4c1.2,1.3,3.1,1.6,4.8,1.4c1.8-0.2,3.4-0.8,5.1-2.3
						c1.7-1.5,3.2-2.6,4.8-3.4c1.6-0.8,3.4-1.4,6.1-1.4c-2.5-1.1-4.3-1.8-6.3-2.4C180.2,29.1,178.1,28.5,174.9,28.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M168.5,41.5c-2.5,0.6-4.3,2.4-4.8,4.3c-0.6,1.9-0.1,3.7,1.3,4.8c1.4,1.1,3.2,1.3,4.7,1c1.5-0.3,2.8-1.1,3.7-2.8
						c1-1.7,1.7-3,2.7-4.3c0.9-1.2,2.1-2.3,4.1-3.3c-2.2,0-3.8-0.1-5.6-0.2C172.8,41,171,40.9,168.5,41.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M158.7,62.1c-2.2,1.8-3.3,4.4-3.4,6.6c-0.1,2.2,0.9,4,2.5,4.7c1.6,0.7,3.5,0.1,5.1-1.1c1.6-1.2,2.8-3.1,3.3-5.6
						c0.5-2.6,0.8-4.4,1.3-6.3c0.5-1.8,1.1-3.6,2.6-6c-2.2,1.6-3.9,2.7-5.6,3.7C162.7,59.2,161,60.2,158.7,62.1z"
                    />
                  </g>
                  <g>
                    <path d="M155.7,90.1c-2.8,3.4-1.4,8,2,9c3.4,1,7.1-2.2,6.5-6.5c-0.6-4.5-1.6-6.4-1.4-10.8C160.4,85.5,158.6,86.6,155.7,90.1z" />
                  </g>
                  <g>
                    <path
                      d="M157,113.8c-1.1,1.8-0.9,3.7-0.1,5.2c0.8,1.4,2.4,2.4,4.1,2.4c1.8,0,3.3-0.9,4.2-2.1c0.9-1.3,1.2-2.9,0.5-4.7
						c-1.5-3.7-2.8-5.3-3.2-9.2C160.8,108.9,159.2,110.1,157,113.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M191.5,102.9c2.1-4.3,3.5-8.8,3.9-12.4c0.5-3.6,0.1-6.5-1.5-7.2c-1.6-0.7-4.1,0.9-6.3,4.1c-2.2,3.2-4.1,8-4.6,13.1
						c-0.5,5.3-0.4,8.9-0.3,12.5c0.1,3.6,0.2,7.2,0.2,12.4c1-5.1,2.2-8.5,3.5-11.8C187.9,110.5,189.4,107.4,191.5,102.9z"
                    />
                  </g>
                  <g>
                    <g>
                      <path
                        d="M6.8,333.4c-3-1.7-5.7-4-7.9-6.6c-2.1-2.6-3.6-5.5-4.4-8.4c-0.8-2.9-0.8-6.1-0.2-8.9c0.3-1.4,0.8-2.7,1.4-3.9
							c0.6-1.2,1.4-2.2,2.3-3.2c3.6-3.9,9.7-6,15.7-6.1c1.5-0.1,3,0,4.5,0.1c1.5,0.1,2.9,0.4,4.2,0.7c2.7,0.6,4.8,1.6,6.3,2.8
							c0.8,0.6,1.4,1.3,1.8,1.9c0.5,0.7,0.8,1.5,1,2.3c0.4,1.7,0.4,3.6-0.2,5.2c-0.2,0.8-0.6,1.6-1,2.2c-0.2,0.4-0.4,0.6-0.7,0.9
							c-0.3,0.3-0.5,0.6-0.8,0.8c-1.1,1-2.5,1.7-3.9,2.2c-2.8,0.9-5.4,0.7-7.1,0.5c-1.8-0.3-3-0.5-3.4,0c-0.4,0.5-0.1,1.8,1.7,3.4
							c0.9,0.8,2.1,1.6,3.8,2.3c1.6,0.6,3.7,1.1,6,1.1c2.3,0,5.1-0.5,7.7-2c0.7-0.3,1.3-0.8,2-1.3c0.6-0.5,1.3-1,1.9-1.6
							c1.2-1.2,2.2-2.6,3.1-4.2c1.7-3.1,2.6-6.9,2.2-11c-0.2-2-0.7-4.2-1.7-6.3c-1-2.1-2.3-4-3.9-5.6c-3.2-3.2-7.2-5.3-11.2-6.6
							c-2-0.7-4.1-1.2-6.1-1.5c-2.1-0.4-4.2-0.5-6.4-0.6c-2.2-0.1-4.4,0.1-6.7,0.4c-2.3,0.3-4.6,0.9-6.8,1.6
							c-2.3,0.8-4.6,1.8-6.8,3.1c-2.2,1.4-4.3,3-6.1,4.9c-1.8,2-3.5,4.2-4.7,6.7c-1.2,2.4-2.1,5-2.7,7.6c-1.1,5.3-0.8,10.7,0.7,15.9
							c1.5,5.2,4.4,9.9,7.9,13.7c3.5,3.8,7.8,6.8,12.3,9c4.7,2.2,9.5,3.4,14.1,4c4.6,0.6,9,0.5,13.1,0c8.3-1,15.6-3.4,22.1-6.6
							c6.4-3.2,12-7.1,17-11.4c4.9-4.3,9.2-8.9,13.1-13.9c3.8-5,7.2-10.3,10.1-16c2.9-5.7,5.5-11.8,7.7-18.4
							c4.5-13.2,7.6-28.3,9.9-46.4c-3.1,18-7.1,32.8-12.3,45.4c-2.6,6.3-5.6,12.1-8.8,17.3c-3.3,5.3-6.9,10-10.9,14.4
							c-4,4.3-8.4,8.3-13.3,11.8c-4.9,3.5-10.2,6.5-16.1,8.7c-5.9,2.2-12.4,3.7-19.2,3.9c-3.4,0.1-6.9-0.1-10.4-0.9
							C13.3,336.4,9.9,335.2,6.8,333.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M46.6,281.2c-1.6-0.8-2.8-1.7-4-2.7c-1.1-1-2-2.1-2.5-3.1c-0.3-0.5-0.4-1-0.5-1.4c-0.1-0.4-0.1-0.8-0.1-1.2
							c0-0.8,0.3-1.7,0.8-2.7c0.3-0.5,0.6-1,0.9-1.5c0.4-0.5,0.7-0.9,1.1-1.2c0.8-0.8,1.8-1.4,2.6-1.7c0.4-0.2,0.8-0.3,1.2-0.4
							c0.4-0.1,0.7-0.1,1-0.1c0.3,0,0.6,0,0.9,0.1c0.3,0.1,0.6,0.2,1,0.4c0.7,0.3,1.3,0.9,1.7,1.5c0.5,0.6,0.8,1.2,1,1.8
							c0.4,1.3,0.2,2.9-0.2,4.3c-0.5,1.4-1.1,2.5-1.7,3.4c-0.5,0.9-0.9,1.6-0.7,2.3c0.2,0.6,0.9,1.1,2.3,1.2c1.4,0,3.5-0.5,5.6-2.2
							c1.1-0.8,2.1-1.9,3-3.4c0.9-1.4,1.6-3.3,1.9-5.4c0.6-4.3-0.9-9.3-4.7-13c-1-0.9-2.1-1.8-3.3-2.5c-1.3-0.7-2.7-1.3-4.2-1.6
							c-1.5-0.3-3-0.5-4.5-0.5c-1.5,0.1-3,0.3-4.4,0.7c-2.9,0.8-5.5,2.2-7.8,4c-1.2,0.9-2.3,2-3.2,3.2c-1,1.2-1.8,2.4-2.6,3.8
							c-1.5,2.7-2.5,6-2.5,9.6c0,3.6,1.2,7.2,2.9,10c1.7,2.9,4,5.1,6.4,6.8c1.2,0.9,2.5,1.6,3.8,2.3c1.3,0.7,2.7,1.2,4,1.6
							c5.5,1.8,10.7,2,15.4,1.5c2.3-0.3,4.5-0.7,6.6-1.3c2.1-0.6,4-1.3,5.8-2.2c7.3-3.4,12.6-8.1,17-13.2c4.4-5.2,7.9-10.9,11-17.6
							c3.2-6.7,5.9-14.3,9.1-23.4c-4,8.8-7.5,16.1-11.4,22.1c-3.9,6.1-8.1,10.9-12.8,14.9c-4.7,3.9-10,7-16.1,8.6
							c-1.5,0.4-3.1,0.7-4.7,0.9c-1.6,0.2-3.3,0.2-5,0.1C53.2,283.5,49.7,282.8,46.6,281.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M-32.4,315.2c-2-2.6-4.9-4.2-7.4-4.5c-2.5-0.4-4.6,0.4-5.3,2c-0.7,1.6-0.1,3.6,1.3,5.3c1.4,1.7,3.5,3.3,6.2,4.2
							c2.8,0.9,4.8,1.6,6.8,2.5c2,0.9,3.9,1.9,6.2,4c-1.5-2.8-2.6-4.7-3.6-6.8C-29.3,320-30.4,317.9-32.4,315.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M-34.7,295.5c0-2.7-0.4-4.9-1.3-6.9c-0.9-1.9-2.1-3.5-3.9-3.8c-1.7-0.3-3.6,1.1-4.5,3.8c-0.9,2.7-0.6,6.6,1.4,9.7
							c2.1,3.2,4,4.8,5.9,6.3c1.9,1.5,3.9,2.8,6.8,4.4c-2.2-2.4-3.1-4.5-3.7-6.6C-34.5,300.4-34.8,298.3-34.7,295.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M-26.6,282.8c1.3-2.3,2-4.4,2.3-6.5c0.2-2,0.1-4.1-1.2-5.3c-0.6-0.6-1.5-0.9-2.5-0.8c-0.5,0.1-1.1,0.2-1.6,0.5
							c-0.5,0.3-1.2,0.6-1.7,1.1c-2.3,1.9-4.1,5.5-3.9,9.3c0.3,3.9,1.2,6.3,2.3,8.6c1.1,2.2,2.3,4.4,4.3,7c-1.1-3.2-1-5.5-0.6-7.6
							C-28.7,287.1-27.9,285.2-26.6,282.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M-12.6,277.9c2.3-1.5,3.9-3.4,4.9-5.3c0.9-1.9,1.1-4,0.1-5.4c-1-1.4-3.2-1.8-5.6-0.9c-2.4,0.9-4.9,3.1-6.2,6.1
							c-1.3,3.1-1.8,5.4-2.3,7.6c-0.4,2.2-0.9,4.4-1.5,7.4c1.7-2.6,3.2-4.2,4.8-5.5C-16.7,280.6-15,279.4-12.6,277.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M4.7,276.2c2.7-0.3,4.9-1.1,6.6-2.4c1.7-1.3,2.9-2.9,2.7-4.7c-0.2-1.7-1.9-3.2-4.4-3.5c-2.5-0.4-5.9,0.4-8.5,2.6
							c-2.6,2.3-4,4.2-5.4,6c-1.3,1.9-2.6,3.7-4.3,6.3c2.5-1.9,4.5-2.7,6.5-3.3C-0.1,276.8,1.9,276.5,4.7,276.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M-32.6,331.5c-5.9-2.5-12.3-0.7-12.8,2.8c-0.4,3.5,5.4,6.9,11.7,5.9c3.2-0.5,5.5-1.1,7.8-1.5c1.2-0.2,2.2-0.5,3.4-0.5
							c0.6,0,1.2,0,1.8,0.2c0.6,0.2,1.3,0.6,1.7,1.3c-0.2-0.8-0.7-1.5-1.2-2c-0.6-0.5-1.2-0.9-1.8-1.2c-0.6-0.3-1.2-0.6-1.8-0.8
							c-0.6-0.2-1.1-0.5-1.6-0.7C-27.5,333.9-29.6,332.8-32.6,331.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M-26.1,344.6c-3.2,0.4-5.9,2.5-7,4.8c-1.2,2.2-0.8,4.4,0.5,5.5c1.4,1.1,3.3,1.1,5.1,0.8c1.8-0.4,3.4-1.3,4.9-3
							c1.6-1.7,3-2.9,4.6-3.9c1.6-1,3.4-1.7,6.2-1.9c-2.7-0.9-4.6-1.4-6.7-1.9C-20.6,344.6-22.8,344.2-26.1,344.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M-18.7,352.8c-2.9,1.5-4.7,4.4-5.1,6.8c-0.4,2.5,0.5,4.5,2.1,5.1c1.7,0.7,3.5,0.1,5.1-0.9c1.6-1,3-2.5,4-4.7
							c1-2.2,2-3.9,3.1-5.4c1.2-1.5,2.6-2.9,5.2-4.2c-2.9,0.2-5,0.5-7.1,0.9C-13.6,350.8-15.8,351.3-18.7,352.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M-9,360.7c-1.6,2.3-1.7,5.3-0.7,7.3c0.9,2,2.7,3,4.4,2.7c1.7-0.3,3.1-1.5,4-2.9c0.9-1.4,1.2-2.9,0.8-4.8
							c-0.4-2-0.6-3.6-0.5-5.2c0.1-1.6,0.4-3.3,1.7-5.4c-2,1.3-3.5,2.4-5,3.5C-5.8,357-7.4,358.3-9,360.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M3.3,368.7c0.3,1.5,1,2.7,2,3.7c1,0.9,2.1,1.5,3.2,1.7c1.1,0.2,2.1,0,2.9-0.4c0.8-0.4,1.3-1.1,1.6-1.9
							c0.6-1.7,0.4-3.4,0.1-4.6c-0.3-1.3-0.8-2.1-2.2-2.7c-1.4-0.7-2.5-1.6-3.3-2.7c-0.8-1.2-1.4-2.6-1.1-4.8
							c-1.3,1.8-2,3.4-2.6,5.1C3.3,363.7,2.7,365.7,3.3,368.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M21.2,371c2.3,2.1,5.5,2.7,7.9,2.1c2.4-0.6,3.8-2.2,3.7-3.9c0-1.8-1.2-3.3-2.6-4.4c-1.4-1.1-3.1-1.7-5.3-1.8
							c-2.3-0.1-4.1-0.3-5.8-0.8c-1.7-0.5-3.4-1.3-5.4-3.2c1.1,2.5,1.9,4.3,2.9,6.1C17.7,367,18.8,368.9,21.2,371z"
                      />
                    </g>
                    <g>
                      <path
                        d="M34.6,366c2.2,1.3,4.7,1.2,6.4,0.2c1.7-1,2.6-2.7,2.3-4.4c-0.3-1.7-1.4-3.1-2.7-4c-1.3-0.8-2.8-1.1-4.7-0.5
							c-1.9,0.6-3.3,1-4.8,1.3c-1.5,0.2-3.1,0.3-5.2-0.4c1.6,1.5,2.7,2.7,3.9,3.9C31.1,363.4,32.4,364.7,34.6,366z"
                      />
                    </g>
                    <g>
                      <path
                        d="M55.9,357.6c2.9,0.2,5.5-1,7-2.5c1.6-1.6,2.1-3.5,1.4-5.1c-0.7-1.6-2.5-2.5-4.5-2.7c-2-0.2-4.2,0.4-6.2,1.9
							c-2.1,1.5-3.6,2.7-5.2,3.7c-1.6,1-3.3,1.9-6,2.6c2.7,0.3,4.7,0.7,6.6,1.1C51,357,52.9,357.4,55.9,357.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M77.2,339.2c4.4-0.5,6.5-4.9,4.7-7.9c-1.8-3-6.6-3.2-9.2,0.4c-2.6,3.7-3.2,5.7-6.3,8.9
							C70.6,339.3,72.7,339.8,77.2,339.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M92.4,321c2-0.6,3.2-2.1,3.6-3.7c0.4-1.6-0.1-3.4-1.4-4.6c-1.3-1.2-3-1.6-4.5-1.3c-1.5,0.3-2.9,1.3-3.6,3.1
							c-1.4,3.7-1.5,5.7-3.9,8.9C86.3,321.9,88.3,322.2,92.4,321z"
                      />
                    </g>
                    <g>
                      <path
                        d="M59.8,305.3c-4.5,1.7-8.5,4.1-11.4,6.4c-2.8,2.3-4.5,4.7-3.8,6.3c0.7,1.6,3.6,2.1,7.4,1.3c3.8-0.8,8.5-3,12.3-6.5
							c3.9-3.5,6.3-6.2,8.8-8.9c2.4-2.7,4.8-5.4,8.3-9.3c-4.2,3.1-7.4,4.7-10.6,6.2C67.6,302.3,64.4,303.6,59.8,305.3z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M363.6,398.2c3.2,1.3,6.2,3.2,8.6,5.5c2.4,2.3,4.3,5,5.4,7.7c1.1,2.8,1.6,5.9,1.3,8.8c-0.1,1.4-0.4,2.8-0.9,4.1
							c-0.4,1.3-1.1,2.4-1.8,3.5c-3,4.3-8.8,7.2-14.8,8.1c-1.5,0.3-3,0.4-4.4,0.4c-1.5,0.1-2.9,0-4.3-0.1c-2.7-0.3-5-1-6.7-2
							c-0.8-0.5-1.5-1.1-2-1.7c-0.5-0.6-1-1.4-1.3-2.2c-0.6-1.7-0.8-3.6-0.5-5.2c0.1-0.8,0.4-1.6,0.7-2.3c0.1-0.4,0.4-0.7,0.5-1
							c0.2-0.3,0.4-0.6,0.7-0.9c1-1.2,2.2-2.1,3.6-2.7c2.6-1.2,5.2-1.4,7-1.4c1.8,0,3,0.1,3.4-0.4c0.4-0.5-0.2-1.8-2.1-3.2
							c-1-0.7-2.3-1.3-4-1.8c-1.7-0.4-3.8-0.6-6.1-0.3c-2.3,0.3-4.9,1.2-7.4,3c-0.6,0.4-1.2,1-1.8,1.5c-0.6,0.6-1.2,1.2-1.6,1.9
							c-1,1.3-1.9,2.9-2.5,4.5c-1.3,3.3-1.6,7.2-0.8,11.2c0.4,2,1.2,4.1,2.4,6c1.3,2,2.8,3.6,4.6,5c3.6,2.8,7.8,4.3,11.9,5.1
							c2.1,0.4,4.2,0.6,6.3,0.7c2.1,0.1,4.3,0,6.4-0.3c2.2-0.2,4.4-0.7,6.6-1.3c2.2-0.6,4.4-1.4,6.6-2.5c2.2-1.1,4.3-2.4,6.3-4
							c2-1.6,3.8-3.5,5.5-5.7c1.6-2.2,2.9-4.6,3.8-7.2c0.9-2.6,1.5-5.2,1.7-7.9c0.4-5.4-0.6-10.7-2.7-15.7c-2.2-5-5.6-9.2-9.6-12.6
							c-4-3.3-8.6-5.8-13.4-7.3c-5-1.6-9.9-2.2-14.5-2.1c-4.6,0-9,0.7-13,1.7c-8.1,2.1-15,5.4-21,9.4c-6,4-11,8.6-15.3,13.5
							c-4.3,4.9-8,10-11.1,15.5c-3.1,5.4-5.8,11.2-8,17.2c-2.2,6.1-3.9,12.4-5.3,19.3c-2.7,13.7-3.8,29-3.8,47.2
							c0.7-18.2,2.8-33.4,6.3-46.6c1.8-6.6,3.9-12.7,6.5-18.3c2.6-5.7,5.5-10.9,8.9-15.7c3.4-4.8,7.3-9.3,11.6-13.4
							c4.4-4.1,9.3-7.7,14.8-10.7c5.5-3,11.8-5.2,18.5-6.4c3.4-0.5,6.9-0.8,10.4-0.5C356.7,396.1,360.3,396.8,363.6,398.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M331,455.1c1.7,0.6,3,1.3,4.3,2.2c1.2,0.9,2.2,1.9,2.9,2.8c0.3,0.5,0.5,0.9,0.7,1.3c0.2,0.4,0.2,0.8,0.3,1.2
							c0.1,0.8,0,1.8-0.4,2.8c-0.2,0.5-0.4,1.1-0.7,1.6c-0.3,0.5-0.6,0.9-1,1.4c-0.7,0.9-1.6,1.6-2.4,2.1c-0.4,0.2-0.8,0.4-1.1,0.6
							c-0.4,0.1-0.7,0.2-1,0.3c-0.3,0-0.6,0.1-0.9,0c-0.3,0-0.7-0.1-1-0.2c-0.7-0.3-1.4-0.7-1.9-1.2c-0.5-0.5-1-1.1-1.2-1.7
							c-0.6-1.2-0.6-2.9-0.3-4.3c0.3-1.4,0.8-2.6,1.2-3.6c0.4-1,0.7-1.7,0.4-2.4c-0.2-0.6-1-1-2.5-0.9c-1.4,0.2-3.4,0.9-5.3,2.9
							c-0.9,1-1.9,2.2-2.6,3.7c-0.7,1.6-1.2,3.4-1.2,5.6c0,4.3,2.1,9.1,6.4,12.3c1.1,0.8,2.3,1.5,3.6,2.1c1.3,0.5,2.9,0.9,4.4,1.1
							c1.5,0.1,3,0.1,4.5-0.1c1.5-0.3,2.9-0.7,4.3-1.2c2.7-1.2,5.2-2.9,7.2-5c1-1.1,2-2.3,2.8-3.6c0.8-1.3,1.5-2.6,2-4.1
							c1.1-2.9,1.7-6.3,1.2-9.9c-0.5-3.6-2.1-7-4.2-9.5c-2.1-2.6-4.6-4.5-7.3-5.9c-1.3-0.7-2.7-1.3-4.1-1.8
							c-1.4-0.5-2.8-0.8-4.1-1.1c-5.7-1.1-10.9-0.6-15.4,0.5c-2.3,0.6-4.4,1.3-6.3,2.2c-2,0.9-3.8,1.8-5.5,2.9
							c-6.8,4.3-11.4,9.7-15.1,15.3c-3.7,5.7-6.4,11.9-8.7,18.9c-2.3,7-4,15-6,24.4c2.8-9.2,5.4-16.9,8.5-23.4
							c3.1-6.5,6.6-11.9,10.7-16.4c4.1-4.5,9-8.3,14.8-10.6c1.5-0.6,3-1.1,4.6-1.5c1.6-0.4,3.2-0.6,4.9-0.8
							C324,453.6,327.6,453.9,331,455.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M404.9,411.1c2.3,2.3,5.4,3.5,7.9,3.5c2.5,0,4.5-1,5-2.7c0.5-1.7-0.4-3.6-2-5.1c-1.6-1.6-3.9-2.8-6.7-3.3
							c-2.9-0.6-5-1-7-1.6c-2.1-0.6-4.1-1.4-6.7-3.2c1.9,2.5,3.2,4.4,4.4,6.3C401.2,406.8,402.5,408.7,404.9,411.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M409.7,430.4c0.3,2.7,1.1,4.8,2.2,6.6c1.1,1.8,2.6,3.2,4.3,3.3c1.7,0.1,3.4-1.5,4-4.4c0.6-2.8-0.2-6.6-2.6-9.4
							c-2.5-2.9-4.6-4.3-6.7-5.5c-2.1-1.2-4.2-2.3-7.3-3.5c2.5,2.1,3.7,4.1,4.5,6.1C408.9,425.6,409.4,427.6,409.7,430.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M403.3,444.1c-1,2.4-1.5,4.6-1.4,6.7c0,2,0.4,4,1.8,5.1c0.7,0.5,1.6,0.7,2.6,0.4c0.5-0.1,1.1-0.3,1.6-0.7
							c0.5-0.3,1.1-0.8,1.6-1.3c2-2.1,3.4-6,2.6-9.8c-0.8-3.8-2.1-6.1-3.4-8.2c-1.4-2.1-2.8-4-5.2-6.4c1.5,3,1.7,5.3,1.5,7.4
							C404.8,439.5,404.3,441.5,403.3,444.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M390,450.7c-2.1,1.7-3.5,3.8-4.1,5.9c-0.6,2-0.6,4.1,0.6,5.4c1.2,1.3,3.4,1.3,5.6,0.1c2.2-1.2,4.4-3.7,5.3-6.8
							c0.9-3.3,1.1-5.6,1.3-7.8c0.2-2.3,0.3-4.5,0.6-7.6c-1.3,2.8-2.6,4.5-4.1,6.1C393.8,447.5,392.2,448.9,390,450.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M373.1,454.6c-2.6,0.6-4.7,1.8-6.2,3.2c-1.5,1.5-2.5,3.3-2.1,5c0.4,1.7,2.3,2.9,4.9,2.9c2.6,0,5.8-1.2,8.1-3.7
							c2.3-2.6,3.4-4.6,4.5-6.7c1.1-2,2.1-4,3.4-6.8c-2.2,2.2-4.1,3.3-6,4.1C377.8,453.4,375.8,453.9,373.1,454.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M402.9,395c6.1,1.8,12.3-0.9,12.3-4.5c0-3.5-6.2-6.1-12.3-4.3c-3.2,0.9-5.3,1.8-7.5,2.5c-1.1,0.4-2.2,0.8-3.3,0.9
							c-0.6,0.1-1.2,0.1-1.8,0c-0.6-0.1-1.3-0.5-1.8-1.1c0.3,0.8,0.9,1.4,1.5,1.9c0.6,0.4,1.3,0.7,2,1c0.6,0.2,1.3,0.4,1.9,0.6
							c0.6,0.2,1.1,0.3,1.7,0.5C397.6,393.2,399.8,394.1,402.9,395z"
                      />
                    </g>
                    <g>
                      <path
                        d="M394.7,382.8c3.1-0.8,5.5-3.3,6.3-5.6c0.9-2.4,0.2-4.4-1.3-5.3c-1.5-0.9-3.4-0.7-5.1-0.1c-1.7,0.6-3.2,1.7-4.5,3.6
							c-1.4,1.9-2.6,3.3-4,4.4c-1.4,1.1-3.1,2.2-5.9,2.7c2.8,0.5,4.8,0.8,6.9,1C389.3,383.5,391.5,383.6,394.7,382.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M386.4,375.6c2.7-1.9,4.1-4.9,4.2-7.4c0.1-2.5-1.1-4.4-2.8-4.8c-1.7-0.4-3.5,0.4-5,1.5c-1.5,1.2-2.6,2.9-3.3,5.2
							c-0.7,2.4-1.5,4.1-2.4,5.8c-1,1.6-2.2,3.2-4.6,4.8c2.8-0.6,4.8-1.2,6.9-1.8C381.5,378.3,383.7,377.5,386.4,375.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M375.7,369.1c1.3-2.5,1-5.5-0.2-7.3c-1.2-1.9-3.1-2.6-4.7-2.1c-1.7,0.5-2.9,1.9-3.6,3.3c-0.7,1.5-0.8,3-0.2,4.9
							c0.6,1.9,1,3.5,1.2,5.1c0.1,1.6,0,3.3-1,5.5c1.8-1.6,3.2-2.8,4.5-4.1C373.1,373.1,374.4,371.7,375.7,369.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M362.5,362.8c-0.5-1.4-1.4-2.6-2.5-3.4c-1.1-0.8-2.3-1.2-3.4-1.3c-1.1-0.1-2.1,0.2-2.8,0.8c-0.7,0.5-1.2,1.3-1.4,2.1
							c-0.4,1.7,0.1,3.4,0.5,4.6c0.5,1.2,1.1,1.9,2.5,2.4c1.5,0.5,2.7,1.2,3.6,2.3c0.9,1,1.7,2.4,1.8,4.6c1-2,1.6-3.6,2-5.4
							C363.2,367.7,363.5,365.7,362.5,362.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M344.5,362.7c-2.6-1.8-5.9-2-8.1-1.1c-2.3,0.9-3.4,2.7-3.2,4.4c0.3,1.8,1.6,3.1,3.1,4c1.5,0.9,3.3,1.3,5.5,1.1
							c2.3-0.2,4.1-0.2,5.8,0c1.8,0.3,3.6,0.8,5.8,2.5c-1.4-2.3-2.5-4-3.7-5.7C348.5,366.3,347.1,364.6,344.5,362.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M331.8,369.5c-2.3-1-4.8-0.5-6.4,0.6c-1.6,1.2-2.2,3-1.8,4.7c0.5,1.7,1.8,2.9,3.2,3.6c1.4,0.6,3,0.7,4.7-0.1
							c1.8-0.8,3.2-1.5,4.6-1.9c1.5-0.4,3-0.7,5.2-0.3c-1.8-1.3-3.1-2.3-4.4-3.4C335.6,371.6,334.2,370.5,331.8,369.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M311.8,380.6c-2.9,0.2-5.3,1.7-6.6,3.4c-1.4,1.8-1.7,3.8-0.7,5.3c0.9,1.5,2.8,2.1,4.8,2c2-0.1,4.1-0.9,5.9-2.7
							c1.9-1.8,3.2-3.1,4.7-4.3c1.5-1.2,3-2.3,5.6-3.4c-2.8,0-4.7-0.1-6.7-0.3C316.8,380.6,314.7,380.4,311.8,380.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M293.1,401.5c-4.3,1.1-5.8,5.7-3.6,8.4c2.2,2.8,7,2.4,9-1.5c2.1-4,2.4-6.1,5.1-9.6
							C299.6,400.6,297.5,400.4,293.1,401.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M280.3,421.6c-1.9,0.9-2.9,2.5-3.1,4.2c-0.2,1.7,0.5,3.3,2,4.4c1.4,1,3.2,1.2,4.7,0.7c1.5-0.5,2.7-1.7,3.1-3.6
							c0.9-3.9,0.8-5.9,2.7-9.4C286.3,419.9,284.3,419.9,280.3,421.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M314.7,432.9c4.2-2.3,7.9-5.1,10.4-7.8c2.5-2.7,3.8-5.2,2.9-6.7c-0.9-1.5-3.8-1.6-7.5-0.3c-3.7,1.3-8,4.1-11.4,8
							c-3.5,4-5.5,7-7.5,10c-2,3-4,6-7,10.3c3.8-3.6,6.7-5.7,9.7-7.5C307.3,436.9,310.4,435.2,314.7,432.9z"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M344.5,109.3c1.3-4.4,1.9-9.1,1.4-13.7c-0.4-4.6-1.8-9.3-4.3-13.4c-0.3-0.5-0.6-1-1-1.5c-0.4-0.5-0.7-1-1.1-1.5
						c-0.8-0.9-1.5-1.8-2.4-2.7c-1.7-1.7-3.6-3.1-5.6-4.3c-4-2.4-8.3-3.8-12.6-4.4c-4.3-0.6-8.7-0.6-12.9,0.4
						c-4.2,0.9-8.3,2.7-11.6,5.5c-3.3,2.7-5.9,6.2-7.2,10.2c-0.7,1.9-1.1,4.1-1.1,6.1c0,2,0.2,4,0.8,5.9c1.1,3.7,3.3,7,6.3,9.2
						c3,2.2,6.5,3.1,9.6,3c3.1-0.1,6.1-1.1,8.3-2.8c1.1-0.9,2-1.9,2.7-2.9c0.7-1.1,1.2-2.2,1.4-3.2c0.6-2.1,0.5-4.1,0.2-5.7
						c-0.3-1.6-1-2.9-1.6-3.8c-1.4-1.8-2.6-2.1-2.9-1.8c-0.3,0.3,0.2,1.3,0.6,2.9c0.2,0.8,0.3,1.8,0.2,3c-0.1,1.1-0.4,2.4-1,3.7
						c-0.3,0.6-0.7,1.2-1.2,1.7c-0.5,0.5-1.1,0.9-1.7,1.3c-1.3,0.7-3,1.1-4.8,0.9c-1.8-0.2-3.7-0.9-5-2.1c-1.4-1.3-2.5-3.2-2.9-5.3
						c-0.2-1.1-0.3-2.2-0.2-3.4c0.1-1.1,0.4-2.2,0.8-3.3c0.9-2.2,2.6-4.3,4.7-6c2.1-1.6,4.8-2.7,7.7-3.3c2.9-0.6,6.2-0.6,9.4-0.1
						c6.4,1,12.6,4.2,15.9,9.6c3.5,5.4,4.4,12.8,2.7,19.6c-0.4,1.7-1,3.4-1.8,5c-0.7,1.6-1.6,3.1-2.6,4.6c-2,2.9-4.2,5.4-6.7,7.6
						c-5,4.4-10.6,7.5-16.3,9.7c-5.7,2.3-11.4,3.6-17.1,4.6c-5.7,1-11.4,1.5-17.1,1.9c-5.7,0.4-11.5,0.5-17.5,0.9
						c-6,0.4-12.3,1.1-18.6,3c-3.2,0.9-6.4,2.1-9.5,3.8c-3.1,1.6-6.1,3.7-8.8,6.3c-2.7,2.6-5,5.8-6.7,9.4c-1.6,3.6-2.6,7.8-2.2,12.1
						c-0.2-4.3,0.9-8.4,2.6-12c1.8-3.5,4.1-6.6,6.9-9c2.7-2.4,5.7-4.3,8.8-5.8c3.1-1.4,6.2-2.5,9.4-3.2c6.3-1.5,12.4-1.9,18.3-1.9
						c5.9-0.1,11.7,0.2,17.5,0.2c5.8,0,11.6-0.2,17.6-0.8c6-0.7,12.2-1.7,18.5-3.8c6.3-2,12.9-5,19-9.8c3.1-2.4,6-5.2,8.6-8.6
						C340.9,117.8,343.1,113.9,344.5,109.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M278.5,96c-3.9-1.2-8.4-1.3-12.5,0.5c-2,0.9-3.8,2.1-5.3,3.7c-1.5,1.5-2.7,3.3-3.6,5.3c-0.8,2-1.2,4.2-1.1,6.4
						c0.1,2.2,0.7,4.2,1.6,6c1.8,3.5,4.9,6.1,8.4,7c3.5,1,6.9,0.3,9.4-1.4c1.2-0.8,2.2-1.9,2.8-3c0.6-1.1,0.9-2.2,1-3.1
						c0.2-1.9-0.3-3.4-0.9-4.2c-0.6-0.8-1.2-1-1.6-0.9c-0.8,0.3-0.9,2.1-2.2,3.6c-0.3,0.4-0.7,0.8-1.1,1c-0.4,0.3-0.8,0.4-1.3,0.5
						c-1,0.2-2.4,0.1-3.4-0.5c-1.1-0.6-2.2-1.8-2.7-3.2c-0.6-1.5-0.5-2.8,0.1-4.4c0.7-1.6,2.1-3.1,3.8-4c1.7-0.9,3.9-1.2,6.3-0.9
						c4.9,0.7,8.5,4.3,10.6,8.1c2.2,3.9,3,8.1,2.7,12.3c-0.2,2.1-0.6,4.2-1.5,6.2c-0.4,1-1,2-1.6,3c-0.7,0.9-1.4,1.8-2.4,2.7
						c-1.8,1.6-4.1,3-6.9,3.8c-2.7,0.7-6,0.9-9.2-0.3c3.1,1.4,6.5,1.6,9.4,1.1c3-0.5,5.6-1.7,7.8-3.3c1.1-0.8,2.1-1.7,3-2.6
						c0.9-1,1.7-2,2.3-3.1c1.4-2.1,2.2-4.5,2.9-6.9c1.1-4.8,1-10-0.9-15.4c-1-2.7-2.4-5.4-4.7-8C285.7,99.6,282.5,97.2,278.5,96z"
                    />
                  </g>
                  <g>
                    <path
                      d="M315.1,122.6c2.7-2.4,4.1-5.8,4.2-8.5c0.1-2.8-0.9-4.8-2.6-5.3c-1.7-0.5-3.6,0.5-5.2,2.2c-1.6,1.7-2.9,3.9-3.7,6.8
						c-0.8,2.9-1.5,5-2.5,7.1c-1,2-2.1,4-4.5,6.4c3-1.5,5.1-2.6,7.3-3.8C310.2,126.3,312.4,125.1,315.1,122.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M366,89.8c2.5-1.7,4.3-3.8,5.4-5.9c1.1-2.1,1.5-4.2,0.4-5.7c-1-1.4-3.3-1.7-5.9-0.7c-2.6,1.1-5.4,3.6-6.8,6.9
						c-1.5,3.5-2,6-2.5,8.4c-0.4,2.5-0.8,4.9-1.4,8.3c1.5-3.1,3.1-4.9,4.7-6.6C361.7,93,363.5,91.6,366,89.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M379.9,95.6c3-0.8,5.4-2.4,6.9-4.3c1.5-1.8,2.2-3.9,1.4-5.5c-0.8-1.6-2.9-2.2-5.4-1.9c-2.5,0.3-5.4,1.7-7.6,4.1
						c-2.3,2.5-3.6,4.4-5,6.2c-1.4,1.8-2.8,3.6-5.1,6c2.9-1.5,5.1-2.3,7.2-2.9C374.6,96.9,376.8,96.4,379.9,95.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M390.9,105.2c3.1,0.2,5.9-0.4,8-1.5c2.1-1.1,3.7-2.6,3.6-4.4c0-1.7-1.7-3.3-4.4-4c-2.6-0.7-6.2-0.4-9.3,1.3
						c-3.2,1.7-5.2,3.3-7.1,4.7c-1.9,1.5-3.8,3-6.6,5c3.3-1.1,5.6-1.4,7.9-1.5C385.4,104.9,387.7,105,390.9,105.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M360.3,116.8c2.7-0.8,4.8-2.3,6.1-4c1.3-1.7,2-3.7,1.2-5.3c-0.7-1.6-2.7-2.4-5.1-2.1c-2.4,0.3-5.2,1.7-7.2,4.2
						c-2,2.5-3.1,4.5-4.2,6.4c-1.1,1.9-2.2,3.8-3.9,6.3c2.5-1.8,4.4-2.7,6.3-3.5C355.5,118.1,357.5,117.6,360.3,116.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M373.4,121.3c2.6,0.1,4.9-0.6,6.6-1.8c1.7-1.2,2.8-2.9,2.6-4.6c-0.2-1.7-1.8-3.1-4.1-3.6c-2.3-0.5-5.2,0-7.7,1.7
						c-2.5,1.8-4.1,3.3-5.6,4.7c-1.5,1.5-3,2.9-5.3,4.7c2.8-0.9,4.8-1.1,6.7-1.2C368.7,121,370.7,121.2,373.4,121.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M293.2,147.9c-6.4,0.5-11.3,5.2-10.1,8.5c1.2,3.3,8,3.6,13.2-0.3c5.3-4,7-6.2,12.8-9.5
						C302.5,148,299.8,147.4,293.2,147.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M300.9,162.8c-1.7,2.4-2,5.5-1.2,7.6c0.7,2.2,2.4,3.5,4.1,3.4c1.8-0.1,3.2-1.3,4.2-2.8c1-1.5,1.5-3.4,1.3-5.6
						c-0.2-2.3-0.3-4.1-0.2-5.9c0.2-1.8,0.5-3.6,1.8-5.9c-2.1,1.6-3.6,2.8-5.1,4.1C304.2,159,302.7,160.4,300.9,162.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M250.7,158.6c5.2-0.2,10.2-0.8,14.1-1.6c3.9-0.8,6.7-2,7-3.7c0.2-1.7-2.4-3.7-6.9-4.6c-4.4-1-10.5-0.7-16.1,1.4
						c-5.7,2.2-9.2,4.4-12.7,6.7c-3.4,2.3-6.7,4.7-11.2,8.5c5.1-2.9,9-4.2,12.8-5.1C241.5,159.3,245.3,158.8,250.7,158.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M452.7,61.6c-0.5-3.2-1.3-6.4-2.5-9.5c-1.2-3.1-2.9-6.2-5.1-8.9c-2.2-2.7-5-5-8.1-6.7c-3.1-1.7-6.7-2.7-10.3-2.8
						l-1.4,0l-1.4,0.1c-0.9,0.1-1.8,0.3-2.7,0.5c-1.8,0.5-3.4,1.1-4.9,2c-3,1.7-5.6,4.2-7.3,7.3c-1.7,3-2.4,6.8-1.9,10
						c0.2,1.6,0.7,3.2,1.3,4.6c0.6,1.4,1.4,2.7,2.2,3.8c3.4,4.5,8.5,7,13.1,7.2c2.3,0.1,4.6-0.3,6.5-1.3c1-0.5,1.8-1.1,2.5-1.8
						c0.7-0.7,1.2-1.5,1.7-2.3c1.5-3.2,1-6,0-7.5c-0.5-0.8-1-1.2-1.4-1.4c-0.4-0.2-0.7-0.2-0.9,0c-0.3,0.3-0.1,1.1-0.1,2.2
						c0,0.6-0.1,1.2-0.4,1.9c-0.3,0.7-0.7,1.5-1.3,2.1c-0.3,0.3-0.7,0.6-1,0.8c-0.3,0.2-0.8,0.4-1.3,0.5c-1,0.2-2.2,0.2-3.5-0.1
						c-2.6-0.6-5.4-2.4-7.1-5.2c-0.4-0.7-0.8-1.4-1-2.2c-0.2-0.8-0.4-1.5-0.4-2.3c-0.1-0.8,0-1.5,0.2-2.2c0.2-0.7,0.5-1.4,0.9-2.1
						c0.8-1.4,2.2-2.6,3.9-3.5c0.8-0.4,1.7-0.8,2.6-1c0.4-0.1,0.9-0.2,1.4-0.2l0.7,0l0.7,0c1.9,0.1,4,0.6,5.9,1.7
						c1.9,1,3.8,2.5,5.3,4.2c3.1,3.5,5.2,8.4,6.4,13.7c1.2,5.5,1.6,10.9,1.4,15.9c-0.2,5-0.9,9.7-1.9,14.1
						c-2.1,8.8-5.4,16.4-9.6,23.5c-4.2,7.1-9.5,13.8-16.3,20.4c-6.8,6.6-15.2,13.2-26.1,19.6c11.2-6,20-12.1,27.3-18.3
						c7.3-6.2,13.3-12.6,18.3-19.6c5-7,9.2-14.7,12.3-23.9c1.5-4.6,2.7-9.6,3.4-15.1C453.5,74.1,453.6,68.1,452.7,61.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M420.9,70.1c-1.7-0.9-3.7-1.5-5.7-1.6c-2.1-0.2-4.2,0.2-6.2,0.9c-1.9,0.7-3.7,1.8-5.2,3.1c-1.5,1.4-2.7,3-3.5,5
						c-0.8,1.9-1.2,4.1-1.1,6c0.1,2,0.6,3.8,1.4,5.5c1.6,3.3,4.3,5.8,7.4,6.9c1.6,0.6,3.2,0.8,4.6,0.6c1.4-0.1,2.7-0.5,3.8-0.9
						c2.2-1,3.7-2.4,4.6-3.8c0.9-1.4,1.2-2.7,1.1-3.6c-0.1-0.9-0.4-1.4-0.8-1.5c-0.9-0.2-1.9,0.9-3.8,1.6c-0.9,0.4-2,0.7-3.2,0.8
						c-1.2,0-2.4-0.2-3.2-0.9c-0.9-0.6-1.7-1.7-2.1-3c-0.2-0.6-0.3-1.3-0.3-2c0-0.6,0.2-1.2,0.4-1.8c0.5-1.2,1.7-2.4,3.2-3
						c0.7-0.3,1.5-0.5,2.2-0.6c0.8,0,1.7,0.1,2.6,0.3c1.9,0.6,3.8,1.9,5.4,3.4c0.8,0.8,1.5,1.6,2.2,2.4c0.7,0.8,1.3,1.7,1.9,2.6
						c2.3,3.5,3.9,7.1,5,10.9c1.1,3.8,1.7,7.8,1.5,12.4c0,0.6-0.1,1.1-0.1,1.7c0,0.6-0.1,1.2-0.2,1.8c-0.1,1.2-0.4,2.4-0.6,3.7
						c-0.6,2.5-1.4,5.2-2.7,8c1.5-2.7,2.7-5.3,3.5-7.8c0.4-1.3,0.8-2.5,1.1-3.7c0.1-0.6,0.3-1.2,0.4-1.8c0.1-0.6,0.2-1.2,0.3-1.8
						c0.8-4.7,0.9-9,0.5-13.3c-0.5-4.3-1.4-8.6-3.3-13.1c-0.5-1.1-1-2.3-1.7-3.4c-0.6-1.2-1.4-2.4-2.2-3.5
						C426.7,74.4,424.4,72,420.9,70.1z"
                    />
                  </g>
                  <g>
                    <g>
                      <path
                        d="M503.8,68.5c5.7-0.7,11.4-1.1,16.9-1.7c5.5-0.5,10.7-1.1,15.1-2c4.5-0.8,8.2-1.9,10.7-2.9c2.5-1.1,3.9-2.2,3.7-3
							c-0.2-0.9-1.8-1.4-4.4-1.8c-2.6-0.4-6.3-0.5-10.8-0.6c-4.4-0.1-9.6,0-15.3,0.4c-5.6,0.4-11.8,1.3-17.7,2.9
							c-6.2,1.7-11.2,3.7-15.6,6c-4.3,2.3-8,4.8-11.3,7.8c-0.8,0.7-1.6,1.5-2.4,2.3c-0.8,0.8-1.5,1.6-2.3,2.5
							c-1.5,1.7-2.9,3.6-4.2,5.6c-2.6,4.1-4.9,9.2-5.6,15.5c1.2-6.2,4.1-10.9,7-14.5c1.5-1.8,3.1-3.4,4.7-4.7c0.8-0.7,1.7-1.3,2.5-2
							c0.9-0.6,1.7-1.2,2.6-1.7c3.5-2.2,7.2-3.8,11.4-5.1C493.2,70.2,498,69.2,503.8,68.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M512,85.7c5.6-1.4,11.1-3.6,15.9-6.1c4.8-2.5,9.1-5.5,12.4-8.3c6.7-5.7,9.8-10.9,8.6-12.1c-1.2-1.3-6.2,1.4-13.3,5.2
							c-7.1,3.9-16.5,8.8-26.3,13c-5.1,2.2-9.5,3.7-13.6,4.9c-4.1,1.2-8,2.1-11.9,3.3c-3.9,1.1-7.8,2.4-11.9,4.4
							c-2,1-4.2,2.2-6.2,3.8c-2.1,1.5-4.1,3.5-5.8,5.9c1.9-2.2,4.1-3.9,6.3-5.2c2.2-1.3,4.3-2.2,6.4-2.9c4.2-1.4,8.1-1.9,12-2.3
							c3.9-0.4,7.8-0.5,12.3-1C501.2,87.9,506.2,87.1,512,85.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M519.6,76.1c6.3-1,12.2-3.4,16.2-6c4.1-2.6,6.3-5.3,5.6-6.9c-0.7-1.6-4.1-1.8-8.7-0.9c-4.5,0.9-10.2,2.7-15.7,5.4
							c-5.7,2.7-9.6,4.6-13.6,6.3c-4.1,1.7-8.3,3.3-14.6,4.9c6.6-0.6,11-0.9,15.5-1.2C508.7,77.4,513.2,77.1,519.6,76.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M517.7,72.2c4.9-0.5,9.4-2.3,12.5-4.4c3.1-2.1,4.8-4.4,4.1-6c-0.7-1.6-3.4-2.2-7-1.8c-3.6,0.4-8,1.7-12.2,3.8
							c-4.3,2.3-7.3,3.9-10.4,5.4c-3.1,1.5-6.2,2.9-11.1,4.4c5-0.4,8.5-0.6,12-0.7C509.2,72.8,512.7,72.7,517.7,72.2z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M490.4,213.7c-5.6-1.1-11.2-2.4-16.6-3.5c-5.4-1.2-10.5-2.2-15-2.7c-4.5-0.6-8.4-0.7-11.1-0.5
							c-2.7,0.3-4.3,0.9-4.5,1.8c-0.1,0.9,1.2,1.9,3.6,3c2.4,1.1,5.9,2.4,10.1,3.8c4.2,1.4,9.2,2.9,14.7,4.2
							c5.5,1.3,11.6,2.3,17.8,2.6c6.4,0.2,11.8-0.1,16.7-1c4.8-0.9,9.1-2.1,13.1-4c1-0.4,2-1,3-1.4c1-0.5,2-1.1,2.9-1.7
							c1.9-1.2,3.8-2.5,5.7-4.1c3.7-3.1,7.4-7.3,10-13.1c-3.1,5.6-7.2,9.1-11.1,11.7c-2,1.3-3.9,2.3-5.9,3.1c-1,0.4-2,0.8-2.9,1.1
							c-1,0.3-2,0.6-3,0.8c-3.9,1-8,1.4-12.4,1.4C501.1,215.3,496.2,214.8,490.4,213.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M487.9,194.8c-5.8-0.4-11.6,0.1-17,1c-5.4,1-10.3,2.5-14.3,4.2c-8.1,3.4-12.6,7.4-11.8,8.9c0.8,1.6,6.3,0.6,14.3-0.9
							c8-1.5,18.4-3.4,29-4.4c5.5-0.6,10.2-0.7,14.5-0.6c4.3,0.1,8.3,0.4,12.3,0.5c4,0.1,8.2,0.1,12.7-0.6c2.3-0.4,4.6-0.9,7.1-1.7
							c2.4-0.9,4.9-2.1,7.3-3.9c-2.5,1.6-5.1,2.5-7.5,3.1c-2.4,0.6-4.8,0.8-7,0.8c-4.4,0-8.3-0.6-12.1-1.4c-3.8-0.8-7.6-1.9-12-2.8
							C498.8,196,493.9,195.2,487.9,194.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M477.7,201.6c-6.3-0.9-12.6-0.4-17.3,0.8c-4.7,1.2-7.6,3.1-7.4,4.9c0.2,1.8,3.4,2.9,8,3.5c4.6,0.5,10.6,0.5,16.6-0.4
							c6.2-0.9,10.5-1.5,14.9-1.9c4.4-0.4,8.9-0.6,15.4-0.3c-6.4-1.4-10.8-2.5-15.2-3.5C488.5,203.7,484.2,202.6,477.7,201.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M478.3,205.9c-4.8-1-9.7-0.7-13.3,0.4c-3.6,1-5.9,2.8-5.8,4.5c0.1,1.8,2.6,3.1,6.1,3.9c3.5,0.7,8.1,0.8,12.8,0
							c4.8-0.8,8.1-1.5,11.5-2c3.4-0.5,6.8-0.9,11.9-0.8c-4.9-1.1-8.3-2-11.6-3C486.6,207.9,483.3,206.9,478.3,205.9z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M295.7,319.1c4.1-3.9,8.4-7.8,12.5-11.5c4.1-3.7,7.9-7.3,10.9-10.7c3.1-3.3,5.4-6.4,6.8-8.8c1.4-2.4,1.8-4.1,1.2-4.7
							c-0.6-0.6-2.3-0.1-4.6,1.2c-2.3,1.3-5.4,3.4-9,6c-3.6,2.6-7.7,5.8-12,9.5c-4.2,3.7-8.6,8.1-12.4,13c-3.9,5.1-6.7,9.7-8.9,14.2
							c-2.1,4.4-3.5,8.7-4.4,13c-0.2,1.1-0.4,2.2-0.6,3.3c-0.1,1.1-0.3,2.2-0.3,3.3c-0.2,2.3-0.2,4.6,0,7c0.4,4.8,1.6,10.3,4.8,15.8
							c-2.8-5.7-3.3-11.1-3.1-15.8c0.1-2.3,0.4-4.5,0.9-6.6c0.2-1,0.5-2,0.8-3c0.3-1,0.6-2,1-2.9c1.5-3.8,3.5-7.3,6.1-11
							C288.2,326.9,291.4,323.2,295.7,319.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M312.5,328c3.7-4.5,6.7-9.5,9-14.5c2.3-4.9,4-9.8,4.9-14.1c1.9-8.6,1.3-14.5-0.4-14.8c-1.8-0.3-4.2,4.8-7.5,12.2
							c-3.4,7.4-7.9,16.9-13.3,26.2c-2.7,4.8-5.4,8.7-7.9,12.1c-2.6,3.4-5.1,6.5-7.5,9.7c-2.4,3.2-4.8,6.6-6.9,10.7
							c-1,2.1-2,4.3-2.7,6.8c-0.7,2.5-1.2,5.2-1.1,8.2c0.2-3,0.9-5.6,1.9-7.9c1-2.3,2.2-4.3,3.4-6.1c2.5-3.6,5.3-6.4,8.2-9
							c2.9-2.7,5.9-5.1,9.3-8.2C305.2,336.2,308.7,332.7,312.5,328z"
                      />
                    </g>
                    <g>
                      <path
                        d="M312.9,315.8c4.4-4.6,7.7-10,9.4-14.5c1.7-4.5,1.8-8,0.3-8.8c-1.6-0.9-4.4,1.1-7.5,4.5c-3.1,3.4-6.5,8.3-9.3,13.8
							c-2.9,5.6-4.9,9.4-7.1,13.3c-2.2,3.8-4.6,7.6-8.7,12.7c4.9-4.4,8.3-7.4,11.7-10.3C305,323.4,308.3,320.5,312.9,315.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M309,313.8c3.6-3.4,6.2-7.5,7.4-11c1.2-3.5,1.2-6.4-0.3-7.3c-1.5-0.9-4,0.3-6.7,2.7c-2.6,2.5-5.4,6.1-7.4,10.4
							c-2.1,4.4-3.5,7.5-5,10.5c-1.6,3-3.3,6.1-6.2,10.2c3.8-3.4,6.5-5.6,9.2-7.8C302.6,319.3,305.3,317.2,309,313.8z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M112.7,104.3c4.4,3.7,8.6,7.6,12.7,11.2c4.1,3.6,8.1,7,11.7,9.8c3.6,2.7,6.9,4.7,9.5,5.9c2.5,1.1,4.2,1.4,4.7,0.7
							c0.5-0.7-0.2-2.2-1.7-4.4c-1.5-2.2-3.9-5-6.9-8.3c-3-3.3-6.6-7.1-10.7-10.9c-4.2-3.8-8.9-7.7-14.2-11
							c-5.4-3.3-10.4-5.7-15-7.3c-4.6-1.6-9-2.6-13.4-3c-1.1-0.1-2.2-0.1-3.3-0.2c-1.1,0-2.2,0-3.4,0c-2.3,0.1-4.6,0.3-7,0.8
							c-4.8,0.9-10.1,2.7-15.2,6.4c5.4-3.3,10.7-4.4,15.4-4.7c2.3-0.1,4.6,0,6.7,0.2c1.1,0.1,2.1,0.3,3.1,0.5c1,0.2,2,0.4,3,0.7
							c3.9,1.1,7.6,2.7,11.5,4.9C104.2,97.6,108.2,100.5,112.7,104.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M105.7,121.9c4.9,3.2,10.2,5.7,15.3,7.5c5.2,1.8,10.2,2.9,14.5,3.4c8.7,1,14.6-0.3,14.7-2c0.1-1.8-5.2-3.6-12.9-6.2
							c-7.7-2.6-17.7-6.1-27.5-10.4c-5.1-2.2-9.2-4.4-12.9-6.6c-3.7-2.2-7-4.4-10.5-6.5C83,99,79.4,97,75.1,95.4
							c-2.1-0.8-4.5-1.5-7-2c-2.5-0.5-5.3-0.6-8.3-0.2c3-0.1,5.7,0.3,8.1,1c2.4,0.7,4.5,1.7,6.5,2.7c3.9,2.1,6.9,4.6,9.8,7.2
							c2.9,2.6,5.7,5.4,9.1,8.4C96.7,115.5,100.6,118.7,105.7,121.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M117.9,121c5,3.9,10.8,6.6,15.4,7.8c4.7,1.2,8.1,1,8.8-0.6c0.7-1.6-1.5-4.2-5.3-7c-3.7-2.7-9-5.6-14.7-7.8
							c-5.8-2.3-9.9-3.9-13.9-5.7c-4-1.8-8-3.8-13.6-7.4c4.9,4.4,8.2,7.5,11.5,10.5C109.5,113.9,112.7,117,117.9,121z"
                      />
                    </g>
                    <g>
                      <path
                        d="M119.5,117c3.7,3.2,8.1,5.3,11.7,6.2c3.6,0.9,6.5,0.5,7.2-1.1c0.8-1.6-0.7-4-3.4-6.4c-2.7-2.4-6.7-4.7-11.1-6.3
							c-4.6-1.6-7.8-2.7-11-3.9c-3.2-1.2-6.4-2.6-10.8-5.1c3.8,3.4,6.2,5.8,8.7,8.3C113.2,111.2,115.6,113.7,119.5,117z"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M46.9,120.2c-4.8-3.3-10.4-5.5-16.3-6.1c-2.9-0.3-5.9-0.2-8.8,0.3c-2.9,0.5-5.8,1.4-8.4,2.9c-2.6,1.5-5.1,3.5-6.9,6.1
						c-0.5,0.7-0.9,1.3-1.2,2c-0.3,0.7-0.7,1.5-0.9,2.2L4,128.7l-0.2,1.1c-0.2,0.8-0.2,1.5-0.3,2.3c-0.1,3,0.5,5.8,1.6,8.3
						c1.1,2.5,2.6,4.6,4.5,6.3c1.8,1.7,4,3,6.2,3.6c2.1,0.7,4.2,1,6.1,0.9c1.9-0.1,3.6-0.4,5.2-0.9c1.5-0.5,2.8-1.1,3.9-1.8
						c2.2-1.3,3.6-2.9,4.3-4c0.7-1.2,0.8-2,0.5-2.2c-0.3-0.3-1.1-0.1-2.2,0.3c-1.1,0.4-2.6,1.1-4.6,1.5c-1,0.2-2,0.4-3.2,0.5
						c-1.1,0.1-2.4,0-3.6-0.2c-1.2-0.2-2.5-0.6-3.6-1.2c-1.2-0.6-2.1-1.4-3-2.4c-1.8-2.1-2.9-5.2-2.6-8.1c0.1-0.3,0.1-0.7,0.2-1
						l0.1-0.5l0.2-0.5c0.1-0.3,0.3-0.6,0.4-0.9c0.2-0.3,0.4-0.6,0.6-0.9c0.9-1.2,2.2-2.2,3.7-3.1c1.6-0.9,3.4-1.5,5.4-1.8
						c2-0.4,4.1-0.5,6.2-0.3c4.2,0.3,8.6,1.6,12.4,3.9c4,2.3,7.2,5.4,9.7,8.7c2.5,3.3,4.3,6.9,5.7,10.4c2.6,7.1,3.3,14.2,2.9,21.3
						c-0.4,7.1-1.8,14.3-4.7,22c-3,7.6-7.4,15.8-14.9,23.8c7.8-7.7,12.8-15.6,16.5-23.2c3.6-7.6,5.7-14.8,6.9-22.3
						c0.6-3.7,0.9-7.5,0.8-11.4c-0.1-3.9-0.5-8-1.6-12.1c-1.1-4.2-2.8-8.5-5.4-12.7C55.4,127.8,51.9,123.6,46.9,120.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M34,153.7c-3,0.6-5.5,2.1-7.5,4c-2,1.9-3.5,4.3-4.3,7c-0.4,1.3-0.6,2.7-0.6,4.1c0,1.4,0.2,2.8,0.7,4.1
						c0.8,2.5,2.4,4.7,4.4,6.1c1,0.7,2,1.2,3.1,1.5c1.1,0.3,2.1,0.4,3,0.4c1.9-0.1,3.4-0.7,4.4-1.5c1-0.8,1.6-1.6,1.8-2.3
						c0.2-0.7,0-1.2-0.3-1.5c-0.7-0.6-1.9-0.4-3.2-0.7c-0.7-0.1-1.4-0.4-2-0.7c-0.3-0.2-0.6-0.4-0.8-0.6c-0.2-0.2-0.4-0.5-0.6-0.8
						c-0.4-0.6-0.7-1.4-0.8-2.3c-0.1-0.4-0.1-0.9,0-1.3c0-0.5,0.2-1,0.3-1.5c0.6-2.1,2.6-4.5,4.7-5.5c1.1-0.5,2.1-0.7,3.3-0.5
						c1.2,0.2,2.3,0.8,3.3,1.6c1,0.8,1.8,1.7,2.5,2.8c0.7,1.1,1.2,2.3,1.6,3.6c0.8,2.6,1,5.5,0.9,8.8c-0.1,3.3-0.7,7-0.7,11.4
						c0.6-4.4,1.7-7.9,2.4-11.2c0.8-3.3,1.3-6.4,1.2-9.6c-0.1-1.6-0.3-3.3-0.8-4.9c-0.5-1.7-1.3-3.4-2.5-5c-1.2-1.6-2.8-3.2-5.1-4.3
						c-1.1-0.6-2.4-1-3.8-1.2C37.1,153.3,35.5,153.4,34,153.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M80.9,129c2-0.8,3.3-2.1,4-3.7c0.7-1.6,0.9-3.4,0.1-5c-0.8-1.5-2.7-2.3-4.8-1.9c-2.1,0.4-4.4,2-5.6,4.5
						c-1.2,2.6-1.6,4.5-1.9,6.4c-0.3,1.9-0.7,3.6-1.1,6.1c1.5-2,2.9-3.1,4.3-4C77.3,130.6,78.8,129.9,80.9,129z"
                    />
                  </g>
                  <g>
                    <path
                      d="M87.6,140.1c1.9,0,3.2-0.8,4.1-2.1c0.9-1.2,1.4-2.9,1-4.7c-0.4-1.7-1.8-3-3.7-3.3c-1.9-0.3-4.2,0.4-5.7,2.3
						c-1.5,2-2.3,3.5-3,5c-0.7,1.5-1.3,2.9-2.3,4.8c1.8-1.2,3.2-1.6,4.7-1.9C84.1,140.2,85.6,140.1,87.6,140.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M69.7,113.9c0.7-3.1,0.1-6.2-1.2-8.3c-1.3-2.1-3.2-3.1-4.9-2.7c-1.7,0.5-2.8,2.2-3.3,4.3c-0.5,2.1-0.3,4.6,0.7,7.2
						c1.1,2.6,1.8,4.6,2.5,6.6c0.6,2,1,4.1,1,7.1c1.1-2.9,1.9-4.9,2.8-6.9C68.1,119.2,69,117.1,69.7,113.9z"
                    />
                  </g>
                  <g>
                    <path d="M51.6,99.8c-0.9-5.8-6-9.3-9.1-7.5c-3,1.8-2.7,7.7,1.3,11.5c4.1,4,6.3,5.3,9.7,10.1C52.1,108.1,52.6,105.7,51.6,99.8z" />
                  </g>
                  <g>
                    <path
                      d="M25.9,96.2c-2.9-2.2-6.5-3.1-9.2-2.9c-2.8,0.2-4.8,1.4-5.1,3.1c-0.3,1.7,0.9,3.6,3,5c2,1.5,4.9,2.6,8.1,2.9
						c3.3,0.4,5.7,0.6,8.1,1.1c2.4,0.5,4.7,1.1,7.9,2.7c-2.6-2.5-4.3-4.2-6.1-6C30.7,100.2,28.9,98.4,25.9,96.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-1.6,103.7c-2.7-0.3-5.2,0.9-6.5,2.5c-1.4,1.7-1.5,3.6-0.6,5.1c1,1.5,2.6,2.2,4.2,2.4c1.6,0.2,3.2-0.2,4.8-1.5
						c1.6-1.3,3-2.3,4.4-3c1.4-0.8,3-1.3,5.4-1.4c-2.1-0.9-3.7-1.6-5.5-2.4C3,104.7,1.2,104-1.6,103.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-13.1,124.3c-2.5,1.7-3.7,4.6-3.5,6.9c0.2,2.4,1.6,3.9,3.3,4.1c1.8,0.2,3.4-0.6,4.6-1.5c1.3-1,2.1-2.2,2.5-4.1
						c0.4-2,0.9-3.5,1.7-5c0.8-1.4,1.9-2.8,4.1-4c-2.5,0.2-4.3,0.5-6.2,0.9C-8.6,122.1-10.6,122.6-13.1,124.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-9.6,149.9c-0.9,2.6-0.3,5.4,1.1,7.1c1.4,1.7,3.3,2.2,4.9,1.5c1.6-0.7,2.7-2.2,3.2-3.7c0.5-1.5,0.5-3.1-0.4-4.8
						c-0.9-1.8-1.5-3.3-1.9-4.9c-0.3-1.6-0.5-3.2,0.1-5.5c-1.6,1.8-2.7,3.2-3.8,4.6C-7.5,145.6-8.6,147.2-9.6,149.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M3.1,168.8c0.4,2.9,2.4,5.2,4.4,6.2c2.1,1,4.1,0.7,5.2-0.6c1.2-1.3,1.4-3.2,1.1-4.9c-0.3-1.7-1.2-3.3-2.9-4.7
						c-1.7-1.4-3-2.6-4.1-3.9c-1.1-1.3-2-2.9-2.7-5.4c-0.4,2.6-0.7,4.4-1,6.4C2.9,163.8,2.7,165.8,3.1,168.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M75.4,229.7c2.3,1.4,4,3.2,4.8,5.2c0.9,2,0.9,4.3,0.3,5.8c-0.6,1.5-2,2.8-3.6,3.4c-0.8,0.3-1.5,0.4-2.3,0.4
						c-0.8,0-1.6-0.2-2.3-0.6c-1.5-0.6-2.7-1.8-3.4-2.9c-0.7-1.1-0.9-2.1-0.8-2.9c0.1-0.8,0.6-1.6,1.1-2c0.4-0.5,0.9-0.8,1-1.2
						c0.1-0.4-0.3-1-1.5-1.3c-1.1-0.2-3,0.1-4.7,1.8c-0.4,0.4-0.8,1-1.1,1.6c-0.3,0.6-0.5,1.2-0.7,1.9c-0.3,1.4-0.3,2.8,0.1,4.3
						c0.7,2.9,2.5,5.8,5.5,7.9c1.5,1,3.3,1.8,5.3,2.3c2,0.4,4.2,0.4,6.3-0.2c2.1-0.5,4.1-1.5,5.9-3c1.8-1.4,3.3-3.4,4.2-5.7
						c0.9-2.3,1.3-4.7,1.1-7c-0.1-2.3-0.8-4.6-1.8-6.7c-1-2.1-2.5-4-4.2-5.5c-1.7-1.5-3.6-2.6-5.6-3.4c-4.1-1.7-8.1-2-11.5-1.8
						c-3.5,0.2-6.5,0.9-9.3,1.7c-5.5,1.7-10.2,3.7-14.6,6.1c-4.5,2.5-8.8,5.2-13,9c-2.1,1.9-4.3,4-6.3,6.4c-2.1,2.5-4.1,5.2-5.8,8.5
						c2-3.1,4.2-5.7,6.4-7.9c2.2-2.2,4.5-4.1,6.8-5.6c4.5-3.2,9-5.2,13.5-6.9c4.5-1.7,9.2-2.8,14.4-3.6c2.6-0.3,5.2-0.5,7.9-0.3
						c1.3,0.1,2.7,0.3,4,0.7C72.9,228.5,74.2,229,75.4,229.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M55.5,215c4.5-2.5,8.1-6.3,10.1-9.7c2-3.4,2.4-6.3,1-7.5c-1.4-1.1-4.1-0.2-7.1,1.7c-3.1,2-6.5,5-9.6,8.6
						c-3.2,3.8-5.4,6.3-7.9,8.8c-2.5,2.5-5.2,4.8-9.6,7.6c4.8-1.8,8.2-3.1,11.7-4.4C47.5,218.9,50.9,217.5,55.5,215z"
                    />
                  </g>
                  <g>
                    <path
                      d="M75.4,216.2c2.3,0.5,4.3,0.1,5.9-0.8c1.6-1,2.7-2.5,2.7-4.3c0-1.8-1.2-3.3-3.1-4.1c-1.9-0.8-4.5-0.8-6.8,0.5
						c-2.4,1.3-3.8,2.5-5.3,3.6c-1.5,1.1-2.9,2.2-5.1,3.5c2.5-0.3,4.3-0.1,6,0.2C71.3,215.2,73,215.7,75.4,216.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M35.3,208.6c0.9-2.4,1.3-5,1.3-7.4c0-0.6,0-1.2-0.1-1.8c-0.1-0.6-0.1-1.1-0.2-1.7c-0.2-1.2-0.6-2.3-1.1-3.4
						c-0.5-1.1-1.1-2-1.9-2.8c-0.7-0.8-1.6-1.3-2.3-1.6c-1.6-0.5-2.6,0-3,0.7c-0.4,0.8-0.3,1.8-0.5,2.6c-0.1,0.9-0.3,1.8-0.4,3
						c-0.1,0.7-0.1,1.4-0.2,2.1c0,0.4-0.1,0.9-0.1,1.3c0,0.5,0,0.9-0.1,1.4c-0.1,1.9-0.1,3.9,0,6c0.1,4.3-0.2,7.5-0.8,10.6
						c-0.7,3.1-1.8,6.3-4.7,10.3c3.7-3.3,5.9-5.9,8.1-8.7c1.1-1.4,2.1-2.8,3.1-4.5C33.4,213,34.4,211.1,35.3,208.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M124.7,53.8c3.3-0.9,6.7-1.7,9-1.4c1.2,0.1,2,0.4,2.7,0.9c0.6,0.4,1.2,1.1,1.6,2c0.9,1.8,1,4.3,0.5,6.2
						c-0.1,0.5-0.3,1-0.5,1.5c-0.2,0.5-0.5,0.9-0.7,1.4c-0.5,0.8-1.2,1.5-1.8,2.1c-1.2,1-2.9,1.5-4.2,1.4c-0.7,0-1.3-0.2-1.8-0.4
						c-0.5-0.3-1-0.6-1.4-1c-1.7-1.6-1.8-3.6-2.6-3.9c-0.4-0.1-1,0.2-1.5,1.2c-0.5,1-0.8,2.8-0.1,4.9c0.3,1,0.9,2.2,1.9,3.2
						c1,1.1,2.2,2,3.8,2.6c3,1.2,6.9,1.2,10.4-0.7c1.7-0.9,3.3-2.2,4.6-3.7c0.7-0.8,1.2-1.6,1.8-2.5c0.5-0.9,1-1.8,1.4-2.9
						c0.8-2.1,1.1-4.3,1.1-6.5c-0.1-2.3-0.5-4.6-1.5-6.9c-1.1-2.3-2.8-4.5-5.2-6c-2.4-1.5-5.1-2.2-7.6-2.3c-2.5-0.1-4.7,0.3-6.8,0.9
						c-2.1,0.6-3.9,1.2-5.7,2c-3.8,1.6-7.6,3.8-10.3,7c-1.3,1.6-2.3,3.5-2.9,5.3c-0.6,1.8-0.9,3.6-0.9,5.3c-0.1,3.4,0.5,6.4,1.3,9.2
						c0.9,2.8,2,5.4,3.4,7.8c2.8,4.8,6.6,9.1,11.7,12.4c2.6,1.7,5.5,3.1,8.7,4.2c3.3,1.1,6.9,1.7,10.9,1.8
						c-3.9-0.3-7.5-1.3-10.6-2.6c-3.1-1.3-5.7-3-8-4.8c-4.5-3.7-7.5-8.1-9.4-12.7c-1-2.3-1.6-4.7-2-7.2c-0.4-2.5-0.5-5,0-7.5
						c0.2-1.2,0.6-2.4,1.1-3.4c0.5-1,1.2-2,2.1-2.8C118.7,56,121.3,54.8,124.7,53.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M86,72c-3-1.1-6.3-0.6-8.4,0.7c-2.1,1.3-3.1,3.2-2.6,4.9c0.5,1.7,2.2,2.8,4,3.3c1.9,0.6,4,0.6,6.4-0.2
						c2.5-0.8,4.3-1.2,6.3-1.4c1.9-0.2,4-0.2,6.8,0.7c-2.3-1.8-4-3.1-5.7-4.3C90.9,74.4,89.1,73.1,86,72z"
                    />
                  </g>
                  <g>
                    <path
                      d="M99.5,62.1c0.8-1.3,0.8-2.3,0.3-3.6c-0.5-1.2-1.3-2.8-2.7-3.8c-0.7-0.5-1.6-0.7-2.5-0.6c-0.9,0.1-1.8,0.5-2.6,1.3
						c-0.8,0.8-1.4,1.9-1.7,3.2c-0.3,1.3-0.2,2.8,0.4,4.1c1.2,2.8,2.7,4.1,4.1,5.3c1.4,1.1,2.9,2,4.9,2.9c-1.3-1.8-1.6-3.3-1.5-4.7
						C98.2,64.8,98.7,63.5,99.5,62.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M152.6,18.3c3.1-2.4,6-4.9,8.2-6.9c2.2-2,3.6-3.6,3.1-4.4c-0.4-0.8-2.6-0.5-5.5,1c-2.8,1.4-6.3,4-8.9,7.3
						c-2.7,3.4-4.2,5.9-5.6,8.5c-1.4,2.6-2.7,5.2-4.4,9.2c2.2-3.7,4-6,6-8.1C147.4,22.8,149.4,20.8,152.6,18.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M161.5,23.3c3.2-1.8,6.3-3.8,8.5-5.5c2.2-1.7,3.6-3.2,3.2-4c-0.4-0.8-2.5-0.7-5.2,0.3c-2.8,1-6.2,3-9.1,5.6
						c-2.9,2.7-4.7,4.8-6.5,6.9c-1.7,2.1-3.5,4.3-5.9,7.5c2.8-2.9,5-4.6,7.2-6.1C155.9,26.6,158.1,25.2,161.5,23.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M160,33.4c2.1-0.6,3.9-1.6,5.2-2.7c1.3-1,2-2.1,1.7-3c-0.3-0.8-1.6-1.2-3.4-0.9c-1.7,0.3-3.9,1.2-5.7,2.7
						c-1.8,1.6-2.9,2.8-4,4c-1.1,1.2-2.2,2.4-3.9,4.1c2-1.3,3.5-1.9,4.9-2.5C156.3,34.6,157.8,34.1,160,33.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M207.1,151.4c1.6-3.8,3.4-7.5,4.8-10.2c1.4-2.8,2.4-4.8,1.8-5.4c-0.6-0.6-2.8,0.4-5.2,3c-2.4,2.6-4.7,6.8-5.6,11.4
						c-0.8,4.7-0.7,8-0.3,11.2c0.4,3.2,1,6.4,2.6,10.8c-1.1-4.5-1.1-7.7-0.7-10.8C204.8,158.3,205.5,155.3,207.1,151.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M186.8,193.3c-0.7,3.1-1.2,5.4-1.8,8c-0.5,2.2-0.7,3.9,0,4.4c0.7,0.5,2.3-0.5,3.8-2.8c1.4-2.2,2.6-5.8,2.3-9.3
						c-0.2-3.6-1-6-1.8-8.2c-0.9-2.2-1.9-4.4-3.8-7.3c1.5,3.1,1.8,5.5,2,7.7C187.6,188,187.5,190.3,186.8,193.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M166,263.2c-0.7,3.5-2,6.9-3.1,9.4c-1.1,2.5-2,4.4-1.3,5c0.6,0.6,2.7-0.2,4.9-2.8c1.1-1.3,2.1-2.9,2.8-4.9
						c0.7-1.9,1.2-4.2,1.1-6.4c0-2.3-0.4-4.1-0.9-5.8c-0.5-1.6-1-3-1.7-4.3c-1.4-2.6-3-5.2-6.2-8.1c2.8,3.3,3.8,6,4.4,8.7
						c0.3,1.3,0.4,2.7,0.5,4.2C166.5,259.8,166.4,261.4,166,263.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M249.5,317.1c1.2-0.7,2.4-1.2,3.5-1.4c0.6-0.1,1.1-0.1,1.6-0.1c0.5,0,1,0.2,1.5,0.3c2,0.7,4,2.6,5.2,4.8
						c0.3,0.5,0.5,1.1,0.8,1.7c0.2,0.6,0.4,1.2,0.5,1.8c0.2,1.2,0.3,2.3,0.2,3.3c-0.2,1.9-1.3,3.4-2.5,4.1c-0.1,0.1-0.3,0.2-0.4,0.2
						c-0.1,0.1-0.3,0.1-0.4,0.2c-0.3,0.1-0.6,0.1-0.9,0.2c-0.7,0-1.3-0.1-1.8-0.4c-0.5-0.2-0.9-0.6-1.2-0.9
						c-0.3-0.4-0.5-0.9-0.5-1.4c-0.1-1.1,0.1-2,0.4-2.8c0.3-0.7,0.6-1.3,0.4-1.7c-0.1-0.4-0.8-0.7-2-0.3c-1.1,0.4-2.7,1.6-3.5,3.8
						c-0.4,1.1-0.6,2.5-0.3,4.1c0.3,1.6,1.1,3.2,2.4,4.5c1.3,1.3,3,2.2,4.9,2.7c1,0.2,2,0.3,3.1,0.3c0.5,0,1.1-0.1,1.7-0.2
						c0.5-0.1,1.1-0.3,1.6-0.4c4.2-1.5,7.4-5.3,8.5-10c0.5-2.3,0.6-4.6,0.2-6.8c-0.2-1.1-0.4-2.2-0.8-3.3c-0.4-1.1-0.8-2.2-1.4-3.2
						c-1.1-2.1-2.5-4-4.4-5.6c-1.8-1.6-4.1-3-6.7-3.8c-2.6-0.8-5.5-0.8-8.1-0.1c-2.6,0.7-4.8,2-6.7,3.5c-3.8,3.3-5.9,7.3-7.1,11
						c-1.1,3.8-1.4,7.4-1.1,10.7c0.2,3.3,1,6.4,1.9,9.1c1,2.8,2.1,5.4,3.5,7.8c1.3,2.5,2.7,4.9,4.3,7.3c1.5,2.4,3.1,4.8,4.9,7.4
						c3.6,5,7.8,10.5,13.5,16.3c-5.3-6.2-9-11.9-12.1-17.2c-1.5-2.7-2.8-5.2-4-7.8c-1.2-2.5-2.3-5-3.2-7.5c-0.9-2.5-1.7-5-2.2-7.6
						c-0.5-2.6-0.7-5.2-0.6-7.9c0.2-2.7,0.8-5.5,2-8.1c0.6-1.3,1.3-2.5,2.2-3.7C247.3,318.8,248.3,317.8,249.5,317.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M264.6,361c0.7-2.6,1-4.8,0.9-6.9c-0.1-2.1-0.6-4.1-2-5.2c-0.7-0.5-1.7-0.6-2.7-0.3c-1,0.3-2.1,1.1-3.1,2.3
						c-1,1.2-1.7,2.8-2.1,4.7c-0.4,1.9-0.3,3.9,0.3,5.9c0.6,2,1.4,3.6,2.2,4.9c0.8,1.3,1.7,2.3,2.6,3.3c1.8,1.9,3.7,3.6,6.7,5.5
						c-2.3-2.7-3-5-3.3-7.2C263.8,365.9,263.9,363.7,264.6,361z"
                    />
                  </g>
                  <g>
                    <path
                      d="M284.7,370c-3.2,6-5.2,12.1-7.6,17c-1.2,2.5-2.4,4.7-3.7,6.6c-0.7,0.9-1.4,1.8-2.1,2.6c-0.7,0.8-1.4,1.4-2.1,2
						c-1.4,1.1-2.5,1.5-3.5,1.7c-0.3,0.1-0.8,0-1.2-0.1c-0.5-0.2-0.9-0.4-1.2-0.7c-0.7-0.5-1.1-1.1-1.5-1.7
						c-0.3-0.6-0.5-1.1-0.9-1.3c-0.2-0.1-0.4-0.1-0.7,0c-0.3,0.1-0.6,0.4-0.9,0.8c-0.6,0.9-1,2.7-0.3,4.6c0.3,1,0.9,1.9,1.8,2.8
						c0.9,0.9,2.1,1.8,3.7,2.2c1.4,0.4,3,0.5,4.6,0.3c1.6-0.2,3.1-0.7,4.5-1.4c1.4-0.7,2.7-1.6,3.9-2.6c1.2-1,2.3-2.1,3.4-3.3
						c2.1-2.4,3.9-5.2,5.3-8.2c2.9-5.9,4.6-12.1,6.6-17.7c1.1-2.9,2.2-5.3,3.6-7.5c1.4-2.2,2.8-4.2,4.4-5.9c0.7-0.9,1.6-1.7,2.4-2.5
						c0.9-0.7,1.7-1.5,2.5-2.2c0.4-0.3,0.9-0.7,1.3-1c0.4-0.3,0.9-0.6,1.3-0.9c0.9-0.6,1.8-1.2,2.8-1.7c3.8-2.2,8-3.8,13-4.7
						c1.2-0.2,2.5-0.4,3.9-0.4c1.3-0.1,2.7-0.1,4.1,0.1c2.8,0.3,5.9,1.2,8.4,3.6c-2.3-2.6-5.4-3.8-8.3-4.4c-1.5-0.3-2.9-0.5-4.3-0.6
						c-1.4-0.1-2.7,0-4.1,0c-5.2,0.3-9.9,1.3-14.4,3c-1.1,0.4-2.2,0.9-3.3,1.4c-0.5,0.3-1.1,0.5-1.6,0.8c-0.5,0.3-1.1,0.6-1.6,0.9
						c-1.1,0.6-2.1,1.3-3.2,2c-1,0.8-2.1,1.5-3.2,2.3c-2.1,1.7-4.2,3.7-6.2,6C288.2,364.1,286.3,366.9,284.7,370z"
                    />
                  </g>
                  <g>
                    <path
                      d="M311.3,353.5c-2.3,1-4.3,2.4-5.9,4.2c-1.6,1.7-2.9,3.8-3.4,5.9c-0.6,2.1-0.3,4.2,0.4,5.5c0.7,1.3,1.7,1.7,2.5,1.5
						c0.9-0.2,1.6-0.8,2.3-1.4c0.7-0.6,1.5-1.2,2.3-1.9c0.9-0.7,1.8-1.6,3-2.7c1.2-1.1,2.4-2.4,3.6-3.7c2.6-2.8,4.9-4.5,7.5-5.8
						c1.3-0.6,2.7-1.3,4.3-1.7c1.6-0.4,3.5-0.7,5.7-0.7c-2.2-0.6-4.1-0.9-5.9-1.1c-1.8-0.2-3.4-0.3-5-0.3c-1.6,0-3.3,0.1-5.1,0.4
						C315.7,352,313.7,352.5,311.3,353.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M333.5,332.9c0.1-2-0.4-3.5-1.3-5c-0.9-1.4-2.2-2.8-3.9-3.1c-0.9-0.2-1.7,0-2.5,0.5c-0.8,0.5-1.5,1.4-1.9,2.6
						c-0.4,1.2-0.6,2.6-0.4,4.2c0.3,1.5,0.9,3.1,2,4.3c2.2,2.6,4.3,3.7,6.2,4.6c1.9,0.9,3.9,1.5,6.5,2.1c-2.2-1.6-3.2-3.2-3.8-4.8
						C333.7,336.7,333.4,335,333.5,332.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M523.9,354.8c0.8-4,0.7-8.3-0.4-12.2c-1-3.9-2.9-7.6-5.4-10.8c-2.5-3.2-5.7-5.8-9.2-7.6c-3.5-1.8-7.3-3-11-3.4
						c-3.8-0.4-7.6-0.2-11.2,0.8c-3.6,0.9-7.2,2.6-10.1,5.5c-1.4,1.4-2.6,3.2-3.4,5c-0.8,1.8-1.3,3.7-1.4,5.5
						c-0.3,3.7,0.5,7.3,2.3,10.3c1.8,3,4.4,5.2,7.1,6.5c2.7,1.3,5.5,1.8,8.1,1.8c2.6-0.1,5-0.8,7-1.8c2-1,3.5-2.4,4.6-3.7
						c2.3-2.7,3.1-5.3,3.4-7.1c0.2-1.8-0.1-2.8-0.5-2.8c-0.5-0.1-1,0.7-1.9,1.9c-0.9,1.2-2.2,3-4.3,4.5c-1,0.7-2.3,1.4-3.7,1.8
						c-1.4,0.4-2.9,0.6-4.5,0.4c-1.6-0.2-3.2-0.7-4.7-1.7c-1.5-0.9-2.7-2.2-3.4-3.7c-0.7-1.5-1-3.5-0.7-5.3c0.1-0.9,0.4-1.8,0.8-2.6
						c0.4-0.8,0.9-1.4,1.6-2.1c2.8-2.6,8.4-3.9,13.5-3.2c5.3,0.6,10.4,3.1,13.9,7.3c3.5,4.2,5.4,9.9,4.7,15.4
						c-0.8,5.7-4.1,10.7-8.1,14.1c-4.1,3.4-9,5.5-13.8,6.4c-4.9,1-9.8,1-14.5,0.4c-4.7-0.6-9.3-1.9-13.8-3.5
						c-4.5-1.6-8.9-3.6-13.5-5.8c-4.5-2.2-9.2-4.6-14.3-6.8c-5.1-2.2-10.8-4.1-17.1-4.8c-3.1-0.3-6.4-0.3-9.8,0.1
						c-1.7,0.3-3.4,0.7-5,1.2c-1.7,0.6-3.3,1.3-4.9,2.2c1.6-0.8,3.3-1.5,5-2c1.7-0.5,3.4-0.8,5-1c3.3-0.3,6.6-0.2,9.6,0.3
						c6.2,0.9,11.5,3.2,16.4,5.6c4.9,2.4,9.4,5.1,13.8,7.7c4.4,2.5,8.8,4.9,13.3,7c4.6,2,9.3,3.8,14.4,4.8
						c5.1,1.1,10.6,1.5,16.4,0.8c5.7-0.7,11.9-2.6,17.5-6.7c2.7-2,5.3-4.5,7.5-7.6C521.4,362.6,523.1,358.9,523.9,354.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M447.8,308.1c-3.2-3.2-7.6-5.5-12.3-6.1c-4.7-0.7-9.8,0.3-13.9,3.4c-2,1.5-3.5,3.4-4.6,5.6c-1.1,2.1-1.7,4.5-1.7,7
						c0,0.6,0.1,1.2,0.1,1.8c0.1,0.6,0.2,1.2,0.4,1.8c0.3,1.2,0.9,2.2,1.4,3.2c1.2,1.9,2.7,3.4,4.5,4.5c1.8,1.1,3.7,1.7,5.7,1.8
						c1.9,0.1,3.9-0.4,5.4-1.3c1.5-0.9,2.6-2,3.3-3.1c0.8-1.1,1.2-2.3,1.5-3.3c0.5-2.1,0-3.9-0.6-4.9c-0.3-0.5-0.6-0.8-0.9-1
						c-0.3-0.2-0.5-0.2-0.7-0.1c-0.2,0.1-0.4,0.3-0.5,0.6c-0.1,0.3-0.2,0.6-0.4,1c-0.3,0.8-0.6,1.7-1.4,2.6c-0.7,0.9-1.9,1.8-3.1,2
						c-1.1,0.2-2.3-0.1-3.5-1.2c-0.6-0.5-1.1-1.2-1.4-2c-0.1-0.4-0.3-0.7-0.3-1.1c0-0.2,0-0.3,0-0.5c0-0.2,0-0.4,0-0.6
						c0.1-1.6,1.2-3.6,2.9-4.8c1.6-1.2,4-1.9,6.7-1.6c2.7,0.2,5.3,1.3,7.7,3.1c2.4,1.8,4.2,4.3,5.3,6.8c1.1,2.5,1.5,5.1,1.3,7.6
						c-0.3,5-2.7,9.6-6.2,12.9c-3.5,3.3-8.6,5.3-14.4,5.7c-5.9,0.5-12.6-0.9-20.1-3.5c7.3,3.1,13.9,5,20.1,5.2
						c6.2,0.2,12-1.1,16.8-4.5c2.4-1.7,4.5-3.8,6.2-6.3c1.7-2.5,3.1-5.4,3.7-8.8C456.1,323.3,454.5,314.8,447.8,308.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M458.9,328.3c-2.4,2.5-3.5,5.9-3.3,8.5c0.2,2.7,1.6,4.5,3.3,4.7c1.8,0.2,3.4-0.9,4.8-2.5c1.3-1.6,2.3-3.7,2.8-6.3
						c0.5-2.7,1-4.7,1.8-6.7c0.8-1.9,1.8-3.8,4.1-6.1c-2.9,1.3-4.9,2.4-6.9,3.5C463.4,324.6,461.4,325.8,458.9,328.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M463.1,362.7c0.5,3.2,2.7,5.9,5,6.9c2.3,1.1,4.4,0.7,5.5-0.7c1-1.4,1-3.4,0.6-5.1c-0.5-1.8-1.4-3.3-3.1-4.8
						c-1.7-1.6-3-3-3.9-4.5c-1-1.6-1.8-3.4-1.9-6.2c-0.9,2.7-1.4,4.7-1.9,6.8C462.9,357.2,462.6,359.4,463.1,362.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M490.6,369c1.7,0.6,3.5,0.7,5.2,0.5c1.7-0.3,3.1-0.9,4.2-1.7c2.3-1.7,2.9-3.9,2-5.4c-0.9-1.5-2.7-2.2-4.6-2.6
						c-1.9-0.3-3.8-0.2-6.1,0.5c-2.3,0.7-4.3,0.9-6.3,0.8c-2-0.2-4-0.7-6.5-2.5c1.7,2.6,3.2,4.3,4.9,5.9
						C485.2,366,487.1,367.7,490.6,369z"
                    />
                  </g>
                  <g>
                    <path
                      d="M448.5,349.2c-1.6-0.6-2.7-0.5-4,0.1c-1.3,0.6-2.7,1.6-3.5,3.2c-0.4,0.8-0.5,1.7-0.2,2.5c0.2,0.9,0.8,1.7,1.7,2.4
						c0.9,0.7,2.1,1.2,3.5,1.3c1.3,0.1,2.8-0.1,4.1-0.8c2.7-1.5,3.9-3.1,5-4.5c1.1-1.5,1.9-3.1,2.9-5.2c-1.8,1.5-3.4,1.9-4.9,1.9
						C451.6,350.1,450.2,349.9,448.5,349.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M441,389.9c-1.2,1.3-3,2.2-4.8,2.4c-1.7,0.3-3.6,0-5.4-0.8c-1.8-0.7-3.5-2-4.7-3.3c-1.2-1.3-1.9-2.6-2-3.5
						c-0.2-1,0.1-2.1,0.4-2.7c0.3-0.6,0.9-1,1.7-1.1c0.8-0.1,1.5,0,2.1,0.2c0.6,0.2,1.1,0.4,1.5,0.3c0.4-0.1,0.8-0.6,0.6-1.7
						c-0.2-1-1-2.4-2.7-3.4c-0.8-0.5-1.9-0.8-3.2-0.9c-1.3-0.1-2.7,0.3-4,1.1c-2.7,1.6-4.5,4.5-4.8,8c-0.3,3.6,1.1,7,3.1,9.7
						c2,2.7,4.7,4.9,8,6.4c3.3,1.5,7.3,2.2,11.4,1.2c3.9-0.9,7.6-3.3,9.9-6.8c0.6-0.9,1.1-1.9,1.4-2.9c0.4-1,0.6-2,0.7-2.9
						c0.3-1.9,0.2-3.7-0.1-5.3c-0.6-3.2-1.9-5.9-3.3-8.1c-1.5-2.2-3.2-4.1-5-5.6c-1.8-1.5-3.7-2.8-5.7-3.8c-4-2.1-8.4-3.3-13.2-3.8
						c-4.9-0.5-10.3-0.3-16.5,0.5c12.6-0.7,21.4,1.7,27.8,6.5c3.2,2.4,5.8,5.4,7.4,9.2c0.8,1.9,1.3,3.9,1.3,5.9
						C442.8,386.8,442.3,388.7,441,389.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M457.3,391.4c0,2.2,1.1,3.9,2.5,4.7c1.5,0.9,3.3,0.9,4.8,0c1.5-0.9,2.4-2.4,2.6-4c0.2-1.5-0.4-3.1-1.9-4.3
						c-1.5-1.2-2.7-2.1-3.9-3c-1.1-0.9-2.1-2-3.1-3.7c0,2-0.2,3.5-0.4,5C457.7,387.6,457.4,389.2,457.3,391.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M431.7,410.6c-2.8,1.3-4.6,3.9-5.2,6.2c-0.6,2.3,0.1,4.3,1.6,5.2c1.5,0.9,3.5,0.6,5.2-0.3c1.7-0.9,3.3-2.4,4.4-4.7
						c1.1-2.3,2-4,3.1-5.6c1.1-1.6,2.4-3.1,4.8-4.7c-2.7,0.6-4.7,1-6.8,1.5C436.6,408.7,434.5,409.2,431.7,410.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M389.6,226.7c1.7,0.3,3.4,0.1,5-0.1c1.5-0.3,2.9-0.8,4-1.3c2.2-1.1,3.4-2.3,3.2-3.1c-0.3-0.9-2-1.1-4.3-0.9
						c-2.3,0.2-5.1,0.7-7.8,1.1c-2.8,0.4-4.9,0.2-6.8-0.5c-1-0.3-1.9-0.8-2.9-1.6c-0.9-0.7-1.9-1.8-2.6-3.3c0.4,1.6,1.1,2.9,1.9,3.9
						c0.8,1,1.7,1.9,2.6,2.6c0.9,0.7,2,1.4,3.2,1.9C386.4,226,387.9,226.5,389.6,226.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M392.5,238.2c1.9,2.5,3.7,4.8,5.3,6.6c1.6,1.7,2.9,2.9,3.7,2.5c0.8-0.3,0.8-2.2-0.1-4.7c-1-2.4-3-5.4-5.8-7.5
						c-2.9-2.2-5-3.2-7.2-4.2c-2.2-1-4.4-1.9-7.8-2.8c3.1,1.5,5,2.9,6.7,4.4C389.1,234,390.6,235.6,392.5,238.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M351.7,217.7c-0.7,2.4-1.3,4.7-1.7,6.6c-0.4,1.9-0.5,3.4,0.2,3.9c0.7,0.5,2.2-0.2,3.6-2.1c1.4-1.8,2.4-4.8,2.3-7.9
						c-0.2-3.1-0.8-5.1-1.4-7.1c-0.7-1.9-1.5-3.8-3.1-6.3c1,2.7,1.2,4.7,1.2,6.6C352.7,213.3,352.5,215.2,351.7,217.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M446.3,284.3c3.7-0.6,7.2-2.4,9.4-4.3c2.2-1.9,3-3.7,2.5-4.4c-0.6-0.7-2.4-0.1-4.7,0.8c-2.3,1-5.3,2.3-8.3,3.6
						c-3.2,1.4-5.5,2-7.9,2.5c-2.4,0.4-5,0.7-8.7,0.1c3.6,1.1,6.2,1.5,8.8,1.8C439.8,284.8,442.4,284.9,446.3,284.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M455.1,296.1c2.5,0.2,5-0.7,6.5-1.9c1.5-1.2,2-2.6,1.4-3.3c-0.6-0.7-1.8-0.7-3.3-0.4c-1.5,0.2-3.2,0.7-5.1,1.3
						c-1.9,0.6-3.4,0.9-4.9,0.9c-1.5,0-3.1-0.1-5.3-1.1c1.9,1.4,3.3,2.3,4.9,3C450.8,295.2,452.5,295.8,455.1,296.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M512.7,278.9c-0.8,1.7-2.2,3.2-3.7,4.4c-1.5,1.2-3.3,2.3-4.8,3.4c-1.5,1.1-2.8,2.1-3.5,3c-0.8,0.9-1,1.6-0.8,2
						c0.3,0.4,1,0.3,2,0.1c1.1-0.2,2.5-0.7,4.2-1.4c1.7-0.7,3.8-1.6,5.9-3.2c2-1.6,4-3.9,4.8-6.9c0.4-1.5,0.4-3,0.2-4.4
						c-0.3-1.3-0.8-2.5-1.4-3.4c-1.2-1.9-2.8-3.1-4.4-4c-3.3-1.8-7-2.2-12.2-2.6c2.6,0.5,4.7,0.9,6.5,1.6c1.8,0.7,3.3,1.5,4.5,2.6
						c1.2,1.1,2.2,2.4,2.8,3.9C513.5,275.5,513.5,277.2,512.7,278.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M421,118.9c2.1-3.8,2.7-8.3,2.3-11.5c-0.4-3.3-1.6-5.2-2.4-5c-0.9,0.2-1.3,2.2-1.8,4.9c-0.5,2.8-1.2,6.3-2.2,9.9
						c-1.1,3.7-2.3,6.2-3.8,8.5c-1.5,2.3-3.4,4.6-6.9,6.9c3.8-1.9,6.2-3.7,8.4-5.7C416.7,125,418.8,122.8,421,118.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M423,162.8c5.4,0.3,10.9,0.4,15,0.3c4.1-0.1,6.9-0.6,7-1.5c0.1-0.9-2.6-2-6.7-2.7c-4.2-0.7-9.8-1.1-15.4-0.5
						c-5.7,0.6-9.6,1.3-13.5,2.1c-3.9,0.8-7.8,1.6-13.5,3c5.8-0.8,9.8-1,13.7-1.1C413.6,162.4,417.4,162.5,423,162.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M428.4,172.9c6.4,1.8,13.2,1.4,13.5-0.3c0.2-1.7-6.2-4-12.9-4c-6.9,0-9.5,0.3-16.5-0.1
						C419.4,169.9,421.8,171,428.4,172.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M465.2,185.4c3.3-1.4,6.4-3,8.7-4.4c2.3-1.4,3.8-2.7,3.5-3.5c-0.3-0.8-2.3-1-5.1-0.3c-2.8,0.7-6.3,2.2-9.3,4.5
						c-3.1,2.4-5,4.3-6.8,6.1c-1.8,1.9-3.7,3.9-6.2,6.8c2.9-2.6,5.1-4,7.4-5.3C459.5,188,461.8,186.9,465.2,185.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M479.4,189.5c3.5-0.2,7-0.5,9.6-1c2.6-0.4,4.5-1,4.6-1.9c0.1-0.9-1.7-1.8-4.6-2.3c-2.8-0.5-6.7-0.3-10.4,0.8
						c-3.7,1.2-6.2,2.3-8.5,3.5c-2.4,1.2-4.7,2.5-8,4.6c3.5-1.6,6.1-2.3,8.6-2.8C473.3,190.1,475.8,189.7,479.4,189.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M496,177.4c5.3-1.4,9.4-5.2,8.5-6.8c-0.9-1.5-6-0.2-10.3,2.8c-2.2,1.5-3.8,2.6-5.3,3.7c-1.6,1-3.2,2-5.8,3.2
						c2.7-0.7,4.6-1.1,6.5-1.5C491.4,178.5,493.3,178.1,496,177.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M508.6,181.3c2.3,0.5,4.6,0.6,6.3,0.4c1.8-0.2,3.1-0.7,3.3-1.5c0.2-0.9-0.9-1.9-2.7-2.6c-1.8-0.7-4.4-1.1-6.9-0.6
						c-2.6,0.4-4.3,1-6,1.5c-1.8,0.6-3.6,1.2-5.8,2c2.6-0.3,4.3-0.3,6-0.1C504.5,180.5,506.2,180.8,508.6,181.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M515.5,191.4c5.5,1.8,11.5,1.5,11.8-0.3c0.3-1.7-5.2-4-11-4.1c-6-0.1-8.3,0.3-14.4-0.2
						C507.8,188.4,509.8,189.5,515.5,191.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M537.8,184.7c2.8,0.1,5.6-0.6,7.4-1.6c1.9-1,2.9-2.2,2.6-3c-0.3-0.8-1.8-1.1-3.7-1c-2,0.1-4.4,0.5-6.9,1.3
						c-2.5,0.8-4.3,1.3-6.2,1.7c-1.9,0.4-3.8,0.6-6.6,0.6c2.8,0.6,4.7,1,6.6,1.3C533,184.3,535,184.6,537.8,184.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M537.5,176.3c2.8-0.1,5.5-1.1,7.4-2.2c1.8-1.1,2.8-2.4,2.4-3.2c-0.4-0.8-1.9-1-3.8-0.7c-2,0.2-4.4,0.9-6.9,1.9
						c-2.5,1-4.3,1.6-6.1,2.2c-1.8,0.5-3.7,1-6.6,1.2c2.9,0.3,4.8,0.5,6.8,0.7C532.6,176.2,534.6,176.4,537.5,176.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M535.8,166.5c2.7-0.6,5.2-1.8,6.8-3c1.7-1.3,2.5-2.6,2.1-3.4c-0.8-1.6-6.1-0.4-10.6,2.3c-2.3,1.4-4,2.4-5.6,3.4
						c-1.7,0.9-3.4,1.8-6.1,2.9c2.8-0.5,4.7-0.8,6.7-1C531.1,167.3,533,167.1,535.8,166.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M557.3,160.8c1.9-0.2,3.7-0.8,5-1.5c1.3-0.7,2.2-1.5,2.2-2.4c-0.1-0.9-1.2-1.6-2.9-1.7c-1.7-0.2-3.9,0.3-5.8,1.5
						c-1.9,1.2-3.1,2.3-4.2,3.4c-1.1,1.1-2.2,2.1-3.7,3.7c1.8-1.2,3.2-1.7,4.6-2.1C553.9,161.3,555.3,161,557.3,160.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M366.2,478.6c3.4-1,6.8-2.1,9.3-3.2c2.5-1.1,4.2-2.1,4-3c-0.1-0.9-2.1-1.3-5-1.1c-2.9,0.3-6.7,1.3-10,3.2
						c-3.4,2-5.6,3.6-7.7,5.3c-2.1,1.7-4.2,3.4-7,6.1c3.2-2.3,5.6-3.5,8-4.5C360.2,480.5,362.6,479.6,366.2,478.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M377.5,483.6c2.3,0.1,4.5-0.2,6.2-0.7c1.7-0.5,2.9-1.2,2.9-2.1c0-0.9-1.2-1.7-3.1-2.1c-1.9-0.4-4.4-0.3-6.8,0.6
						c-2.4,0.9-4,1.7-5.6,2.5c-1.7,0.9-3.3,1.8-5.3,2.9c2.4-0.7,4.2-1,5.9-1.1C373.5,483.5,375.1,483.5,377.5,483.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M400.9,489.4c2.5,0.8,5,1.3,7,1.3c2,0.1,3.5-0.3,3.7-1.1c0.3-0.8-0.8-2-2.7-2.9c-1.9-0.9-4.6-1.6-7.4-1.6
						c-2.9,0-4.8,0.3-6.8,0.5c-2.1,0.2-4.1,0.5-6.7,0.8c2.8,0.2,4.7,0.6,6.6,1.1C396.6,487.9,398.4,488.5,400.9,489.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M404,480.2c5.8,1.9,12.1,1.7,12.4,0c0.3-1.7-5.5-4.1-11.6-4.3c-6.3-0.2-8.7,0.1-15.2-0.5
						C395.8,477.1,398,478.2,404,480.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M405.2,471.3c1.9,0,3.8-0.8,4.9-1.8c1.1-1,1.6-2.1,1.2-2.9c-0.4-0.8-1.5-1.1-2.8-1c-1.3,0.1-2.9,0.5-4.3,1.4
						c-1.5,0.9-2.6,1.5-3.8,2c-1.2,0.5-2.4,0.9-4.3,1.2c1.7,0.3,3,0.5,4.5,0.7C401.8,471.2,403.2,471.4,405.2,471.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M326.4,598.7c3.6-0.2,7.2-0.8,10.9-1.8c3.6-1,7.3-2.7,10.5-5.2c1.6-1.3,3.1-2.8,4.3-4.4c1.2-1.7,2.2-3.5,3-5.4
						c1.5-3.8,2.1-7.8,1.7-11.8c-0.2-2-0.7-3.9-1.4-5.8c-0.7-1.9-1.7-3.6-2.8-5.1c-2.2-3.1-5.1-5.5-8.2-7.3c-1.6-0.8-3.2-1.5-4.9-2
						c-1.7-0.5-3.4-0.7-5.1-0.8c-3.4-0.1-6.7,0.5-9.7,1.8c-2.9,1.4-5.5,3.4-7.2,6.1c-1.7,2.6-2.4,5.5-2.4,8.1c0,2.7,0.8,5.3,2.2,7.2
						c0.7,1,1.5,1.8,2.4,2.5c0.9,0.6,1.8,1.1,2.7,1.4c3.6,1.3,6.6,0.5,8.1-0.6c1.6-1.1,1.9-2.1,1.6-2.4c-0.3-0.4-1.2-0.1-2.5,0
						c-0.7,0-1.5,0-2.4-0.2c-0.9-0.2-1.9-0.6-2.8-1.2c-0.9-0.6-1.7-1.5-2.1-2.5c-0.5-1.1-0.6-2.4-0.4-3.8c0.2-1.4,0.8-2.9,1.8-4.1
						c0.9-1.1,2.4-2.1,4.1-2.7c1.7-0.6,3.8-0.8,5.8-0.6c1,0.1,2,0.3,3,0.7c1,0.4,2,0.8,2.9,1.4c1.9,1.2,3.6,2.7,4.9,4.6
						c1.3,1.9,2,4,2.3,6.3c0.2,2.3-0.1,4.8-1,7.1c-0.4,1.1-1,2.2-1.7,3.2c-0.7,1-1.5,1.8-2.5,2.7c-3.9,3.3-9.9,5-16.1,5.8
						c-6.3,0.8-12.3,0.4-17.8-0.8c-5.5-1.2-10.3-3.2-14.8-5.6c-9-4.9-16.6-10.8-25.2-15.5c-8.6-4.7-18.1-7.9-29-9.2
						c-5.4-0.6-11.2-0.8-17.4-0.2c-6.1,0.6-12.7,2-19.4,4.5c6.8-2.2,13.3-3.3,19.4-3.6c6.1-0.3,11.8,0.2,17.1,1.1
						c10.5,1.9,19.4,5.7,27.3,10.8c3.9,2.6,7.7,5.5,11.5,8.5c3.9,3,7.9,6.1,12.5,9.1c4.6,3,9.9,5.7,16,7.5
						C312.1,598.2,319,599.2,326.4,598.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M279.9,561.6c4.2-1.3,8.3-3.6,11.6-6.8c1.7-1.6,3.1-3.5,4.3-5.6c1.2-2.1,2-4.4,2.3-7c0.7-5.2-1.5-10.8-6.2-13.8
						c-2.4-1.4-4.9-1.9-7.1-1.8c-2.2,0.1-4.1,0.5-6,1.3c-1.8,0.8-3.5,2-4.9,3.6c-1.3,1.6-2.1,3.7-2.1,5.7c0,3.9,2.4,6.6,4.7,7.6
						c2.3,1,4.2,0.5,5.3-0.1c1.1-0.6,1.3-1.3,1.2-1.6c-0.2-0.4-0.8-0.6-1.5-0.9c-0.7-0.3-1.7-0.8-2.4-1.6c-0.3-0.4-0.5-0.8-0.6-1.4
						c-0.1-0.5-0.1-1.1,0.1-1.5c0.2-0.4,0.5-0.7,1-1.1c0.5-0.3,1.2-0.7,2.1-0.9c0.9-0.2,2-0.3,2.9-0.2c1,0.1,1.7,0.4,2.2,0.8
						c0.5,0.4,0.9,1,1.2,1.8c0.2,0.4,0.3,0.9,0.3,1.3c0,0.4,0,0.9,0,1.5c-0.3,2.1-1.7,4.8-3.7,7c-2,2.2-4.7,4.1-7.8,5.4
						c-3.1,1.4-6.4,2.2-9.5,2.6c-3.2,0.4-6.2,0.5-9.2,0.4c-5.9-0.1-11.4-0.8-17-1.1c-5.6-0.3-11.4-0.3-17.6,0.8
						c-6.2,1.1-13,3.1-20.3,7c7.5-3.4,14.4-4.8,20.5-5.3c6.2-0.4,11.6,0.2,17,1.3c5.4,1,10.7,2.4,16.9,3.4c3.1,0.5,6.5,0.9,10.2,0.9
						C271.4,563.3,275.5,563,279.9,561.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M303.9,501.7c1.1-5.3,2.3-10.4,3.1-14.4c0.8-4.1,1.2-7.3-0.3-8.3c-1.4-1-4.6,0.8-7.4,4.9c-1.4,2.1-2.6,4.7-3.4,7.7
						c-0.8,3-1.1,6.3-0.8,9.6c0.3,3.4,0.9,6.2,1.7,8.6c0.7,2.4,1.6,4.5,2.6,6.5c1,2,2,4,3.3,6.1c1.3,2.1,2.8,4.3,4.9,6.8
						c-1.6-2.8-2.6-5.2-3.3-7.5c-0.7-2.3-1.1-4.3-1.3-6.4c-0.2-2.1-0.2-4.1-0.1-6.3C303,506.9,303.3,504.5,303.9,501.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M320.9,499.7c0.3-3.4-0.1-6.9-1.1-10c-0.9-3.1-2.4-5.8-4.1-7.9c-1.6-2.1-3.4-3.4-4.9-4.1c-1.5-0.7-2.6-0.7-3.3-0.1
						c-1.3,1.3-0.3,4.3,0.8,8.2c0.6,2,1.2,4.2,1.9,6.6c0.6,2.4,1.3,5,1.8,7.7c0.6,2.8,0.8,5.2,0.9,7.5c0.1,2.3,0,4.4-0.3,6.5
						c-0.5,4.2-1.6,8.5-4.5,14.4c3.8-5.3,6.1-9.2,8.1-13.3c1-2.1,1.9-4.2,2.7-6.7C319.9,506,320.6,503.2,320.9,499.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M312.1,497.4c0.7-3.6,0.6-7.3,0-10.1c-0.6-2.7-1.6-4.4-2.5-4.3c-0.9,0.1-1.4,1.9-1.7,4.6c-0.3,2.6-0.4,6-0.2,9.5
						c0.2,3.6,0.2,6.1,0.2,8.6c-0.1,2.5-0.3,5.1-1.2,8.8c1.3-3.6,2.2-6,3-8.5C310.5,503.6,311.3,501.1,312.1,497.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M310,493.7c0.9-3.1,1-6.3,0.6-8.7c-0.4-2.4-1.2-4-2.1-3.9c-0.9,0-1.6,1.6-2.1,3.8c-0.5,2.3-0.8,5.2-0.8,8.3
						c0,3.1,0,5.3-0.2,7.5c-0.2,2.2-0.4,4.4-1.2,7.6c1.2-2.9,2.1-5,3.1-7.2C308.2,499,309,496.9,310,493.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M302.6,488.7c1.5-2.6,2.9-5.1,3.9-7.2c1-2,1.6-3.6,1-4.3c-0.6-0.6-2.4,0-4.3,1.7c-1.9,1.8-3.9,4.8-4.7,8.1
						c-0.8,3.5-0.9,5.9-0.8,8.3c0.1,2.4,0.3,4.7,1,8.1c-0.2-3.4,0.2-5.7,0.8-7.9C300.3,493.4,301.1,491.4,302.6,488.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M305.8,559.5c1.5-1.7,2.7-3.7,3.5-5.7c0.8-2,1.3-4,1.4-5.7c0.3-3.4-0.5-5.7-1.4-5.7c-0.9,0-1.6,2-2.7,4.8
						c-1.1,2.8-2.5,6.3-4.4,9.7c-2,3.5-3.9,5.6-6.1,7.6c-2.2,1.9-4.8,3.6-9.1,4.9c4.4-0.8,7.4-1.9,10.1-3.3c1.4-0.7,2.7-1.5,4.2-2.5
						C302.7,562.6,304.2,561.3,305.8,559.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M341,541.1c4.9-0.6,9.7-1.1,13.4-1.5c3.7-0.4,6.3-0.9,6.4-1.8c0.1-0.8-2.4-2-6.4-2.4c-3.9-0.5-9.3-0.1-14.4,1.4
						c-5.2,1.6-8.5,3.3-11.7,5c-3.2,1.8-6.4,3.7-10.8,6.9c4.6-2.8,8.1-4.1,11.5-5.2C332.6,542.5,336,541.7,341,541.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M361.1,546c3.3,0.2,6.6,0,9.1-0.3c2.5-0.4,4.3-1,4.3-1.9c0.1-0.9-1.6-1.8-4.2-2.3c-2.6-0.5-6.1-0.6-9.6,0.1
						c-3.5,0.7-5.9,1.5-8.2,2.3c-2.5,0.8-4.8,1.6-8.1,2.8c3.6-0.7,6-0.8,8.5-0.9C355.2,545.7,357.6,545.8,361.1,546z"
                    />
                  </g>
                  <g>
                    <path
                      d="M368.5,552.6c2.6,0.8,5.2,1.2,7.2,1.2c2,0,3.6-0.3,3.8-1.2c0.2-0.8-0.9-2-2.8-2.9c-2-0.9-4.8-1.6-7.6-1.5
						c-2.9,0.1-4.9,0.4-6.9,0.7c-2.1,0.3-4.2,0.7-6.9,1.1c2.9,0.1,4.9,0.4,6.8,0.8C364,551.3,365.9,551.8,368.5,552.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M371.3,563.5c1.5,1.5,2.9,2.8,4,3.8c1.2,1,2.2,1.7,3.1,1.5c0.8-0.3,1.2-1.8,0.4-3.8c-0.7-2-2.7-4.1-5.1-5.1
						c-2.5-1.1-4.2-1.3-6-1.6c-1.7-0.3-3.4-0.5-5.9-1c2.3,1,3.8,1.8,5.2,2.7C368.4,561,369.8,562,371.3,563.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M370.1,580.9c0.2,2.4,0.6,4.7,1.1,6.5c0.5,1.8,1.1,3.1,2,3.2c0.9,0.1,1.8-1.2,2.2-3.3c0.4-2.1,0.2-5-1-7.6
						c-1.2-2.6-2.4-4.2-3.6-5.8c-1.2-1.5-2.5-3-4.4-5c1.5,2.3,2.2,4.1,2.7,5.8C369.5,576.6,369.8,578.4,370.1,580.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M364.6,598.8c-0.5,1.7-0.6,3.5-0.3,4.8c0.2,1.4,0.8,2.4,1.7,2.6c0.9,0.2,1.8-0.6,2.5-2c0.6-1.4,1-3.4,0.6-5.3
						c-0.4-2-0.9-3.3-1.3-4.7c-0.5-1.3-0.9-2.6-1.5-4.5c0.1,2-0.1,3.3-0.4,4.7C365.5,595.7,365.1,597,364.6,598.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M353.3,601c-3.3,3.3-5.1,7.7-3.7,8.8c1.4,1.1,5.4-1.9,7.3-6.4c1.9-4.6,2.2-6.5,4.2-11.1
						C358.2,596.4,356.7,597.5,353.3,601z"
                    />
                  </g>
                  <g>
                    <path
                      d="M340.2,602.7c-2.6,1.1-5,2.5-6.7,3.8c-1.7,1.3-2.7,2.6-2.3,3.4c0.4,0.8,2,0.9,4.2,0.3c2.2-0.6,4.8-1.9,7.1-3.8
						c2.3-2,3.8-3.5,5.2-5c1.5-1.5,2.9-3,5.1-5.1c-2.5,1.7-4.4,2.7-6.2,3.6C344.8,600.8,342.9,601.6,340.2,602.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M316.4,606.9c-2.8,0-5.6,0.4-7.6,1c-2.1,0.6-3.5,1.4-3.5,2.2c0,0.9,1.5,1.6,3.7,1.9c2.2,0.3,5.2,0.1,8.1-0.9
						c2.9-1,4.8-1.9,6.7-2.8c2-1,4-1.9,6.6-3.1c-3,0.8-5,1.1-7.1,1.3C321.3,606.8,319.3,606.9,316.4,606.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M233,569.1c5.4,0.9,10.6,2.2,14.6,3.2c4,1,6.8,1.7,7.2,0.8c0.4-0.7-1.8-2.8-5.8-4.8c-4-1.9-9.8-3.5-15.7-3.6
						c-6,0-10.1,0.7-14.1,1.6c-4,0.9-8,2.1-13.5,4.5c5.7-1.9,9.8-2.4,13.7-2.6C223.5,568,227.4,568.2,233,569.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M251.9,584.4c1.8,1.9,3.6,3.7,5.1,5.1c1.5,1.3,2.8,2.2,3.6,1.8c0.8-0.4,0.8-2.1-0.2-4.2c-1-2.1-3.1-4.6-5.8-6.1
						c-2.8-1.5-4.8-2.2-6.9-2.7c-2.1-0.5-4.1-0.9-7.1-1.1c2.9,0.8,4.7,1.8,6.4,2.9C248.6,581.1,250.1,582.4,251.9,584.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M68,396c1.6-0.2,3.2-0.3,4.9-0.5c1.6-0.1,3.3-0.2,4.8-0.2c3.1,0,6.1,0.2,8.8,0.7c2.7,0.5,5.1,1.4,6.9,2.6
						c0.9,0.6,1.7,1.2,2.3,1.9c0.3,0.3,0.6,0.7,0.9,1.1c0.2,0.4,0.4,0.7,0.6,1.1c0.7,1.6,1.1,3.6,0.9,5.5c-0.1,1-0.3,1.9-0.6,2.8
						c-0.3,0.9-0.8,1.8-1.3,2.6c-1.1,1.7-2.5,3.2-4.2,4.4c-1.7,1.2-3.4,2.1-5.2,2.7c-1.8,0.6-3.7,0.8-5.3,0.7
						c-1.7-0.1-3.2-0.4-4.5-1c-2.5-1-4.3-3.3-5.3-5.4c-1-2.1-1.2-4.1-1.3-5.6c0-0.7-0.1-1.4-0.1-1.9c-0.1-0.5-0.2-0.9-0.5-1.1
						c-0.3-0.2-0.7-0.2-1.2,0c-0.5,0.2-1.1,0.7-1.8,1.5c-0.6,0.8-1.3,1.9-1.7,3.4c-0.4,1.4-0.7,3.2-0.5,5.1c0.1,1.9,0.7,4.1,1.7,6.3
						c0.3,0.5,0.6,1.1,0.9,1.6c0.4,0.5,0.7,1.1,1.2,1.6c0.5,0.5,0.9,1.1,1.4,1.5c0.3,0.2,0.5,0.5,0.8,0.7c0.3,0.2,0.6,0.4,0.8,0.7
						c2.3,1.8,5.2,3,8.2,3.7c3.1,0.7,6.4,0.7,9.7,0.2c3.3-0.6,6.8-1.8,9.9-3.6c3.1-1.9,6.1-4.4,8.5-7.6c1.2-1.6,2.3-3.4,3.1-5.4
						c0.8-2,1.4-4.1,1.7-6.3c0.6-4.4,0.1-9-1.8-13.5c-0.5-1.1-1.1-2.2-1.7-3.2c-0.7-1-1.4-2-2.2-2.9c-0.8-0.9-1.7-1.7-2.6-2.5
						c-0.5-0.4-0.9-0.7-1.4-1.1c-0.5-0.4-1-0.7-1.5-1c-4-2.5-8.2-3.7-12.3-4.3c-4.1-0.6-8.1-0.7-12-0.3c-1.9,0.2-3.8,0.4-5.6,0.7
						c-1.9,0.3-3.7,0.6-5.5,1c-7.6,1.7-14.2,4-20.2,6.7c-6,2.7-11.3,5.7-16.1,8.9c-4.8,3.2-9.1,6.5-13,10.1
						c-3.9,3.5-7.6,7.2-10.9,11c-6.7,7.7-12.6,16.1-18,26.2c-5.4,10-10.4,21.7-14.4,36.1c4.8-14.2,10.6-25.4,16.7-34.7
						c6.1-9.4,12.7-16.9,19.9-23.6c3.6-3.3,7.4-6.3,11.4-9.2c4-2.9,8.4-5.5,13.1-7.9c4.7-2.4,9.8-4.6,15.4-6.4
						C55.2,398.3,61.3,396.8,68,396z"
                    />
                  </g>
                  <g>
                    <path
                      d="M53,455.6c0.5,1.9,0.5,3.8,0.3,5.6c-0.3,1.9-0.8,3.7-1.7,5.4c-0.9,1.7-2,3.1-3.3,4.3c-0.6,0.6-1.3,1.1-2,1.6
						c-0.7,0.5-1.5,0.9-2.3,1.2c-3.2,1.4-6.9,1.6-10,0.9c-1.6-0.3-2.9-0.9-3.9-1.6c-1-0.7-1.6-1.3-2.1-2.1c-0.2-0.4-0.4-0.8-0.5-1.1
						c-0.1-0.3-0.2-0.6-0.1-0.9c0-0.6,0.3-1.5,1-2.3c0.7-0.9,1.6-1.7,2.6-2.4c0.5-0.3,1-0.7,1.5-1c0.5-0.3,0.9-0.5,1.3-0.8
						c0.5-0.3,1-0.6,1.4-0.9c0.2-0.2,0.7-0.2,1.1-0.2c0.4,0.1,0.7,0.2,1,0.4c0.3,0.2,0.5,0.5,0.8,0.6c0.3,0.1,0.7,0.1,1.1-0.4
						c0.4-0.4,0.7-1.4,0.4-2.5c-0.2-1.1-0.9-2.3-2.5-3.3c-1.5-0.9-3.3-1.1-5-1c-0.8,0.1-1.6,0.2-2.4,0.3c-0.8,0.1-1.6,0.3-2.5,0.6
						c-1.7,0.5-3.6,1.3-5.5,2.6c-1.9,1.3-4,3.4-5.2,6.4c-0.6,1.5-1,3.2-1.1,4.8c-0.1,1.7,0.1,3.3,0.6,5c0.5,1.6,1.2,3.2,2.1,4.6
						c0.9,1.5,2.1,2.7,3.3,3.8c2.5,2.2,5.4,3.8,8.4,4.8c6.1,2,13.1,1.9,19.6-0.7c3.2-1.3,6.4-3.2,9-5.8c2.6-2.5,4.7-5.6,6.2-8.9
						c1.5-3.3,2.4-6.9,2.5-10.6c0.2-3.7-0.5-7.5-1.8-10.8c-2.7-7-7.7-12-13.2-14.9c-5.6-2.9-11.3-3.4-16.2-3.1
						c-4.9,0.3-9.3,1.4-13.4,2.8c-4.1,1.3-7.9,3-11.5,4.9c-3.7,1.9-7.1,4.1-10.6,6.6c-3.4,2.5-6.7,5.3-9.9,8.6
						c-3.2,3.3-6.3,6.9-9.3,11.1c-2.9,4.2-5.7,9-8,14.5c2.6-5.3,5.7-9.8,9-13.7c3.2-3.9,6.6-7.1,10-9.9c3.4-2.8,6.9-5.1,10.4-7
						c3.5-1.9,7-3.5,10.6-4.8c3.6-1.2,7.3-2.3,11.1-2.9c3.8-0.7,7.7-1.1,11.5-0.8c3.8,0.3,7.6,1.4,10.5,3.5
						C49.6,448.2,52,451.7,53,455.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M121.8,361.1c10.9,1.6,21.5,3.9,29.6,5.9c0.5,0.1,1,0.2,1.5,0.3c0.5,0.1,1,0.2,1.4,0.4c0.9,0.2,1.8,0.4,2.6,0.6
						c0.8,0.2,1.6,0.3,2.4,0.5c0.7,0.2,1.4,0.3,2,0.4c2.5,0.4,4.2,0.3,4.6-0.5c0.4-0.8-0.5-2.1-2.5-3.9c-0.5-0.4-1.1-0.9-1.7-1.4
						c-0.6-0.5-1.4-0.9-2.1-1.4c-0.8-0.5-1.6-1-2.5-1.5c-0.5-0.2-0.9-0.5-1.4-0.8c-0.5-0.2-1-0.5-1.5-0.7
						c-8.1-3.8-19.9-6.9-31.8-6.7c-3.1,0.1-5.9,0.1-8.5,0.4c-1.3,0.1-2.6,0.2-3.8,0.4c-1.2,0.2-2.4,0.4-3.5,0.5
						c-1.1,0.2-2.3,0.3-3.3,0.5c-1.1,0.2-2.1,0.4-3.2,0.7c-1,0.2-2.1,0.4-3.1,0.7c-1,0.3-2,0.5-3,0.8c-1,0.3-2,0.5-3,0.8
						c-1,0.3-2,0.6-3,1c-1,0.3-2,0.6-3.1,1c-1,0.4-2.1,0.8-3.2,1.2c-1.1,0.4-2.2,0.8-3.3,1.3c-1.1,0.5-2.3,1-3.5,1.5
						c-0.6,0.3-1.2,0.5-1.8,0.8c-0.6,0.3-1.2,0.6-1.9,0.9c-1.3,0.6-2.6,1.2-4,1.9c1.4-0.5,2.8-1,4.1-1.5c0.7-0.2,1.3-0.5,1.9-0.7
						c0.6-0.2,1.3-0.4,1.9-0.6c1.2-0.4,2.4-0.7,3.6-1c1.2-0.3,2.3-0.5,3.4-0.8c1.1-0.2,2.2-0.5,3.3-0.7c1.1-0.2,2.1-0.3,3.2-0.5
						c1-0.1,2-0.3,3-0.4c1-0.1,2-0.2,3-0.3c1-0.1,2-0.2,3-0.2c1-0.1,2-0.1,3-0.1c1,0,2.1,0,3.1-0.1c1.1,0,2.1,0.1,3.2,0.1
						c1.1,0,2.2,0.1,3.4,0.1c1.2,0.1,2.3,0.2,3.6,0.3C116.4,360.4,119,360.8,121.8,361.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M120.9,378.3c10.8,0.9,21.8-0.5,29.7-2.7c7.9-2.2,12.8-5.1,12.3-6.8c-0.4-1.8-5.9-1.9-13.7-1.5
						c-7.8,0.4-18.1,1.3-28.4,2.2c-10.6,0.9-18,1.1-25.5,0.9c-7.5-0.3-15.1-1-26.1-3.4c10.7,3.5,18.2,5.4,25.6,7.2
						C102.3,375.9,109.8,377.4,120.9,378.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M132.4,371.1c6.3,1.3,12.7,1.4,17.5,0.7c4.8-0.7,8-2.3,8-4c0-1.8-3.1-3.3-7.8-4.4c-4.7-1-10.9-1.5-17.2-1
						c-6.5,0.5-10.9,0.9-15.5,1.2c-4.5,0.3-9.1,0.4-15.8,0c6.6,1.4,11,2.6,15.4,3.8C121.5,368.5,125.9,369.7,132.4,371.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M166.3,420.2c1,0,2.1,0,3.1-0.2c1-0.2,2.1-0.4,3.1-0.7c2-0.7,4-1.7,5.8-3.3c1.8-1.5,3.3-3.7,4-6.1
						c0.7-2.4,0.7-4.8,0.3-6.9c-0.4-2.1-1.2-4-2.1-5.7c-0.9-1.7-2-3.1-3.3-4.5c-1.3-1.3-2.8-2.4-4.5-3.3c-1.6-0.8-3.6-1.3-5.3-1.4
						c-3.5-0.2-6.9,1.1-9,3.3c-1.1,1.1-1.8,2.4-2.2,3.6c-0.4,1.2-0.5,2.3-0.4,3.3c0.2,2,1.1,3.4,1.9,4c0.8,0.7,1.5,0.7,1.8,0.4
						c0.3-0.3,0.4-0.9,0.5-1.6c0.1-0.7,0.2-1.6,0.7-2.4c0.4-0.9,1.2-1.8,2.1-2.1c0.9-0.4,2.3-0.6,3.6-0.2c1.3,0.4,2.6,1.3,3.9,2.8
						c1.2,1.5,2.3,3.6,2.6,5.5c0.2,0.9,0.1,1.7-0.1,2.3c-0.2,0.6-0.5,1.2-1.1,1.8c-0.6,0.6-1.5,1.2-2.5,1.7
						c-0.5,0.2-1.1,0.4-1.6,0.6c-0.6,0.2-1.1,0.3-1.8,0.4c-2.6,0.3-5.2,0-7.6-0.7c-2.4-0.7-4.7-1.7-6.8-2.9c-4.1-2.4-7.6-5.4-11-8.5
						c-3.5-3.1-6.9-6.5-11.2-9.7c-2.2-1.6-4.5-3.1-7.3-4.5c-2.7-1.3-5.9-2.4-9.3-2.6c3.5,0.5,6.4,1.9,8.9,3.4
						c2.5,1.5,4.7,3.3,6.5,5.1c3.8,3.6,6.6,7.3,9.5,11c3,3.7,6,7.4,10.3,11c2.2,1.8,4.6,3.4,7.6,4.8
						C158.6,419,162.2,420.1,166.3,420.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M130.6,425.7c-0.7,1.3-1.4,2.2-2.3,3c-0.9,0.7-1.7,1.2-2.5,1.4c-0.6,0.2-1.4,0.2-2.1,0c-0.7-0.2-1.2-0.4-1.5-0.7
						c-0.3-0.1-0.6-0.7-0.7-1.3c-0.1-0.6-0.1-1.3,0-1.9c0.1-0.6,0.3-1.1,0.4-1.6c0.2-0.5,0.3-1,0.2-1.4c-0.1-0.4-0.4-0.8-1.1-1
						c-0.7-0.2-1.8-0.1-2.9,0.5c-1.2,0.6-2.4,1.7-3.2,3.4c-0.8,1.6-1.3,4-0.4,6.6c0.8,2.4,2.7,4.6,5.1,5.9c2.4,1.3,5.3,1.8,8.3,1.2
						c2.9-0.6,5.4-2.2,7.2-4.2c1.8-2,3.1-4.6,3.7-7c0.6-2.5,0.6-4.8,0.4-6.8c-0.2-2-0.6-3.8-1-5.4c-0.9-3.3-2.2-6.1-3.7-8.6
						c-1.5-2.6-3.3-4.9-5.6-7.3c-1.1-1.2-2.4-2.3-3.8-3.5c-1.4-1.1-3.1-2.2-4.9-3.2c1.7,1.2,3.1,2.6,4.3,3.8c1.2,1.3,2.2,2.6,3,3.9
						c1.7,2.6,2.7,5.1,3.5,7.7c0.7,2.6,1.1,5.2,1.1,8C132.2,419.9,131.9,423.1,130.6,425.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M612.6,399.5c-0.7-3.6-1.9-7.1-3.5-10.4c-1.6-3.3-3.6-6.4-5.8-9.1c-2.2-2.7-4.6-4.8-6.8-6.8c-2.2-1.9-4.2-3.6-5.9-5.2
						c-3.5-3.1-6-6-7.6-8.2c-1.7-2.2-2.6-3.7-3.5-3.5c-0.8,0.1-1.5,2-0.9,5.4c0.3,1.6,0.9,3.6,1.9,5.6c1,2,2.4,4.2,4,6.3
						c3.3,4.3,7.7,8.3,11.3,12.7c3.5,4.3,6.4,9.6,8.4,15.4c1,3,1.7,5.9,2.1,8.6c0.4,2.7,0.7,5.4,0.7,7.9c0,5-0.5,9.6-1.6,14.1
						c-1.1,4.5-2.6,9-4.9,13.8c-2.3,4.8-5.4,9.9-9.8,15.6c4.8-5.3,8.4-10.1,11.3-14.7c2.9-4.6,5.2-9,7-13.5
						c1.8-4.6,3.3-9.3,4.1-14.8c0.4-2.8,0.6-5.7,0.6-8.9C613.7,406.6,613.4,403.2,612.6,399.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M580.2,404.8c-0.8-5.7-1-11.4-0.9-16.8c0.1-5.4,0.4-10.6,0.8-15c0.7-8.9,0.9-15.2-0.8-15.7c-1.7-0.4-4.9,5.1-7.5,14.3
						c-1.2,4.6-2.2,10.1-2.4,16.2c-0.2,6,0.4,12.6,2.2,18.9c1.9,6.5,4.3,11.6,7.1,15.9c1.4,2.2,2.8,4.1,4.3,5.9
						c1.4,1.8,2.9,3.4,4.2,5.1c2.6,3.3,5,6.9,5.9,11.6c0.2,1.2,0.4,2.4,0.4,3.7c0,1.3,0,2.6-0.2,4c-0.4,2.7-1.3,5.6-3,8.4
						c1.9-2.7,3.1-5.5,3.8-8.2c0.4-1.4,0.6-2.7,0.7-4.1c0.1-1.3,0.1-2.6,0.1-3.9c-0.1-2.5-0.7-5-1.5-7.2c-0.8-2.2-1.8-4.3-2.9-6.2
						c-2.2-3.9-4.5-7.5-6.2-11.7C582.4,415.5,581,410.7,580.2,404.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M593.6,391.6c-1-7.1-3.4-13.9-5.9-18.7c-2.5-4.8-4.9-7.5-5.7-7.1c-0.8,0.4,0.2,3.8,1.8,8.7c1.6,4.9,3.7,11.4,5.5,17.9
						c1.8,6.8,2.7,11.6,3.3,16.5c0.5,4.9,0.8,9.9,0.1,17.3c1.2-7.3,1.6-12.4,1.8-17.4C594.6,403.9,594.6,398.9,593.6,391.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M588.3,385.7c-0.4-5.3-1.8-10.5-3.4-14.1c-1.6-3.7-3.4-5.8-4.2-5.5c-0.8,0.3-0.6,3,0.2,6.7c0.7,3.8,1.9,8.6,3.1,13.5
						c1.2,5.1,1.9,8.6,2.3,12.2c0.4,3.6,0.7,7.4,0.3,12.8c0.9-5.4,1.3-9.2,1.6-12.9C588.5,394.8,588.7,391.1,588.3,385.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M583.8,385.6c0.2-8.5-1.7-16.9-3.5-16.8c-1.8,0.1-2.4,8.7-0.9,17.1c1.6,8.6,2.6,11.8,3.8,20.8
						C583,397.7,583.5,394.4,583.8,385.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M38.3,418.2c3.6-0.5,7.1-1.2,9.7-2c2.6-0.8,4.4-1.7,4.3-2.5c-0.1-0.9-2-1.5-4.9-1.6c-2.9-0.1-6.7,0.5-10.3,1.9
						c-3.7,1.5-6,2.8-8.4,4.1c-2.3,1.3-4.7,2.7-8,4.8c3.6-1.6,6.2-2.4,8.7-3C32.1,419.3,34.6,418.8,38.3,418.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M37.7,425.6c3.2-0.1,6.2-0.5,8.6-1.1c2.3-0.5,4-1.2,4-2.1c0-0.9-1.6-1.7-4.2-2.1c-2.6-0.4-6-0.1-9.3,0.9
						c-3.3,1.1-5.5,2.1-7.6,3.2c-2.1,1.1-4.2,2.2-7.3,3.9c3.3-1.2,5.6-1.8,7.9-2.1C32.2,425.9,34.5,425.7,37.7,425.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M59.5,432.6c2.4,0.8,4.7,1.4,6.6,1.6c1.9,0.2,3.4,0.1,3.8-0.7c0.4-0.8-0.5-2.1-2.3-3.2c-1.8-1.1-4.5-2-7.3-2
						c-2.9,0-4.8,0.3-6.8,0.7c-1.9,0.4-3.8,0.8-6.5,1.5c2.8-0.2,4.7,0,6.5,0.4C55.3,431.3,57.1,431.8,59.5,432.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M80.6,446.8c4,0.5,8.1-0.5,10.7-1.9c2.7-1.4,4-3.1,3.6-3.8c-0.4-0.8-2.4-0.5-5-0.1c-2.6,0.4-5.9,1-9.4,1.5
						c-3.6,0.4-6.1,0.3-8.7,0c-2.5-0.4-5.2-1.1-8.6-3.1c3.1,2.4,5.6,3.7,8.1,4.9C73.9,445.3,76.5,446.2,80.6,446.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M84.1,455.5c2.2,0,4.3-0.8,5.6-1.8c1.3-1,1.9-2.2,1.5-3c-0.4-0.8-1.6-1-3.1-0.9c-1.5,0.1-3.2,0.6-4.9,1.4
						c-1.8,0.8-3.1,1.4-4.4,1.8c-1.4,0.4-2.8,0.8-4.9,0.8c2.1,0.5,3.5,0.8,5,1.1C80.4,455.3,81.9,455.5,84.1,455.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-6.8,468.2c-3.8,3.9-6.3,9-7.3,13c-1,4-0.7,6.8,0.2,7c0.9,0.2,2.1-2.2,3.8-5.5c1.7-3.3,4-7.6,6.8-11.7
						c2.9-4.2,5.4-6.9,8.1-9.3c2.8-2.3,5.8-4.6,11-6.6c-5.3,1.5-8.8,3.2-12.1,5C0.3,462-2.9,464.1-6.8,468.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-0.6,480.6c-1,1.9-1.8,3.9-2.4,5.8c-0.3,1-0.5,1.9-0.7,2.8c-0.2,0.9-0.3,1.9-0.3,2.8c0,0.9,0.1,1.8,0.4,2.6
						c0.3,0.8,0.7,1.5,1.2,1.9c0.5,0.4,1,0.6,1.4,0.6c0.4,0,0.6-0.1,0.7-0.3c0.2-0.4-0.1-1,0-1.6c0-0.6,0.1-1.5,0.5-2.7
						c0.2-0.6,0.4-1.2,0.7-2.1c0.3-0.8,0.6-1.7,0.8-2.5c0.6-1.8,1.1-3.7,1.7-5.6c1.1-4,2.1-6.7,3.2-9.3c1.2-2.7,2.5-5.3,5-8.9
						c-2.9,3.2-4.8,5.6-6.7,7.9C3.3,474.3,1.5,476.7-0.6,480.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M7.5,492.9c-0.1,2.4,0.6,4.8,1.6,6.4c1,1.6,2.2,2.3,3,2c0.8-0.4,1.1-1.7,1-3.3c-0.1-1.6-0.5-3.6-1.2-5.6
						c-0.7-2.1-1.2-3.6-1.5-5.1c-0.3-1.6-0.5-3.2-0.3-5.6c-0.7,2.3-1.2,3.9-1.6,5.5C8.1,488.7,7.7,490.4,7.5,492.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M19.5,498.4c0.8,2,2.3,3.6,3.7,4.4c1.4,0.8,2.7,0.9,3.3,0.2c0.6-0.7,0.3-1.9-0.3-3.1c-0.6-1.3-1.7-2.7-3-3.9
						c-1.4-1.3-2.4-2.3-3.2-3.3c-0.9-1.1-1.7-2.2-2.5-4.2c0.3,2.1,0.5,3.5,0.7,4.9C18.5,494.9,18.7,496.4,19.5,498.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M36.3,500.3c1.6,0.8,3.4,0.8,4.7,0.4c1.3-0.4,2-1.3,2-2.2c-0.1-0.9-0.9-1.6-1.9-2c-1.1-0.5-2.4-0.7-3.8-0.5
						c-1.5,0.2-2.6,0.2-3.7,0.2c-1.1,0-2.3-0.2-3.8-0.8c1.1,0.9,2.1,1.7,3.2,2.6C33.7,498.7,34.7,499.5,36.3,500.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M173.3,453.4c1.6-3.6,2.3-7.6,2.2-11.5c-0.1-3.9-1-7.8-2.9-11.2c-1-1.7-2.2-3.2-3.6-4.5c-1.5-1.3-3.2-2.3-5.1-2.8
						c-3.8-1.1-7.5-0.3-10.2,1.3c-2.8,1.6-4.7,4.3-5.4,6.7c-0.8,2.5-0.6,4.7-0.2,6.4c0.5,1.7,1.2,2.9,1.8,3.6
						c0.6,0.7,1.1,0.9,1.2,0.8c0.4-0.3-0.5-1.9-0.7-4.8c0-1.4,0.2-3.2,1-4.9c0.8-1.7,2.2-3.3,4.2-4.2c2-1,4.6-1.3,6.8-0.5
						c2.3,0.8,4.4,2.7,5.8,5.3c1.4,2.6,2.2,5.7,2.4,8.9c0.2,3.2-0.3,6.6-1.4,9.7c-1.2,3.2-3,6-4.8,8.5c-1.9,2.5-3.8,4.8-5.6,7.1
						c-0.9,1.2-1.7,2.3-2.5,3.5c-0.4,0.6-0.7,1.2-1.1,1.8l-0.5,0.9l-0.5,0.9c-0.3,0.6-0.6,1.2-0.9,1.8c-0.3,0.6-0.5,1.2-0.8,1.8
						c-0.6,1.2-0.9,2.5-1.4,3.7c-0.8,2.5-1.4,5.1-1.8,7.8c-0.2,1.3-0.3,2.7-0.4,4.1c0,0.7,0,1.4,0,2.1c0,0.7,0,1.4,0,2.2
						c0.1,2.9,0.5,6,1.3,9.3c0.8,3.2,2,6.6,3.8,10c-1.7-3.5-2.7-6.9-3.4-10.1c-0.6-3.2-0.9-6.3-0.8-9.2c0-0.7,0-1.4,0.1-2.1
						c0.1-0.7,0.1-1.4,0.2-2.1c0.2-1.4,0.3-2.7,0.6-4c0.5-2.6,1.3-5,2.2-7.4c1.9-4.7,4.5-9,8.3-13.1c1.9-2.1,4-4.2,6.1-6.7
						C169.4,460,171.7,457.1,173.3,453.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M154.7,445.2c-2.1,1.9-3.9,4-5.1,5.8c-1.2,1.8-1.8,3.3-1.2,4c0.6,0.7,2.2,0.3,4.2-0.9c1.9-1.2,4.1-3.4,5.6-6.1
						c0.8-1.4,1.3-2.5,1.8-3.6c0.5-1.1,0.9-2.1,1.2-3.2c0.3-1.1,0.5-2.2,0.4-3.5c-0.1-1.2-0.5-2.6-1.7-3.7c0.9,1.3,1,2.6,0.8,3.7
						c-0.2,1.1-0.7,2-1.2,2.7c-0.6,0.8-1.2,1.5-2,2.3C156.6,443.3,155.7,444.2,154.7,445.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M157.3,453.9c-1.7,1.7-3.1,3.6-4,5.2c-0.9,1.6-1.2,3-0.6,3.7c0.6,0.6,2.1,0.3,3.7-0.9c1.6-1.1,3.4-3.1,4.4-5.5
						c1.1-2.4,1.7-4.2,2.2-6c0.6-1.9,1.1-3.7,1.8-6c-1.2,2.3-2.3,3.8-3.5,5.1C160.3,450.9,159.1,452.2,157.3,453.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M187.3,434.6c0.8-2.6,0.7-5.4,0.1-7.4c-0.6-2-1.7-3.1-2.5-2.9c-0.9,0.2-1.4,1.5-1.6,3.3c-0.3,1.8-0.4,4-0.3,6.3
						c0.1,2.4,0,4.2-0.2,5.9c-0.3,1.7-0.7,3.5-1.9,5.9c1.6-2.1,2.7-3.7,3.6-5.2C185.5,438.9,186.5,437.3,187.3,434.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M196,440.1c1.4-0.9,2.5-2.1,3.2-3.2c0.7-1.1,1-2.2,0.6-3c-0.5-0.7-1.7-0.9-3.1-0.4c-1.4,0.5-3,1.8-4,3.6
						c-1,1.8-1.4,3.2-1.8,4.5c-0.4,1.3-0.7,2.6-1.2,4.5c1-1.7,1.9-2.6,2.8-3.5C193.5,441.8,194.5,441.1,196,440.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M204.1,446c1.7-0.2,3.1-0.8,4.2-1.5c1.1-0.7,1.8-1.6,1.7-2.5c-0.1-0.9-1.2-1.5-2.7-1.6c-1.5-0.1-3.4,0.4-5,1.6
						c-1.6,1.2-2.5,2.2-3.5,3.2c-1,1.1-1.9,2.2-3,3.4c1.6-1,2.8-1.5,4-1.8C201.1,446.4,202.3,446.2,204.1,446z"
                    />
                  </g>
                  <g>
                    <path
                      d="M255.9,442.9c-2.8,2-5.5,3.9-7.6,5.6c-2,1.6-3.4,3-3,3.8c0.3,0.8,2.4,0.8,5.1-0.2c2.7-1,6-3.1,8.4-6
						c2.5-3,3.9-5.3,5.2-7.6c1.2-2.3,2.4-4.7,3.8-8.3c-1.9,3.4-3.5,5.4-5.3,7.2C260.7,439.2,258.8,440.8,255.9,442.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M258,452.6c-2.4,3.6-3.9,7.7-4.5,10.9c-0.5,3.2-0.2,5.5,0.6,5.6c0.9,0.2,2-1.7,3.4-4.4c1.3-2.7,2.9-6.4,4.3-10.1
						c1.5-3.8,2.8-6.4,4.3-9c1.5-2.5,3.1-5,6.1-8.3c-3.4,2.9-5.5,5-7.6,7.1C262.6,446.7,260.5,448.9,258,452.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M264.1,459.8c-1.9,3.8-3,8-3.2,11.2c-0.3,3.2,0.2,5.4,1,5.5c0.9,0.1,1.9-1.8,3-4.6c1.1-2.9,2.3-6.7,3.3-10.6
						c1-4,1.9-6.8,2.9-9.6c1-2.8,2.2-5.5,4.4-9.3c-2.7,3.5-4.4,6-6.1,8.5C267.7,453.4,266.1,455.9,264.1,459.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M266.4,475.3c-1.1,3.9-1.3,8-0.8,11c0.4,3,1.4,5,2.2,4.9c0.9-0.1,1.5-2,2-4.9c0.5-2.8,0.8-6.6,1-10.3
						c0.2-3.9,0.5-6.6,0.9-9.3c0.5-2.7,1.1-5.5,2.6-9.4c-2,3.6-3.2,6.2-4.4,8.8C268.6,468.7,267.5,471.3,266.4,475.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M332.1,491.8c-2.3,0.4-4.4,1.4-5.7,2.6c-1.3,1.2-1.9,2.4-1.4,3.2c0.5,0.8,1.8,0.9,3.4,0.5c1.6-0.3,3.5-1.1,5.3-2.2
						c1.9-1.2,3.2-2,4.6-2.7c1.4-0.7,2.9-1.4,5.1-2c-2.1,0-3.8,0.1-5.6,0.1C336.1,491.3,334.5,491.4,332.1,491.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M338.2,497.8c-2,0.5-2.5,2.8-1.3,4.1c1.2,1.3,3.4,1.1,4.2-0.8c0.8-1.9,0.9-3,2-4.7
						C341.2,497.3,340.2,497.3,338.2,497.8z"
                    />
                  </g>
                  <g>
                    <path d="M432.9,506.6c-3.6,0.1-6.5,2.6-5.9,4.2c0.7,1.6,4.1,1.7,7,0c3-1.8,4.1-2.8,7.6-4C438.1,507,436.6,506.6,432.9,506.6z" />
                  </g>
                  <g>
                    <path
                      d="M152,526.9c-2.4-3.5-5.6-6.8-9.9-9.1c-2.1-1.1-4.4-2-6.8-2.5c-2.4-0.5-5-0.6-7.6-0.1c-5.2,0.9-9.7,3.7-12.6,7.9
						c-0.7,1.1-1.3,2.2-1.8,3.4c-0.5,1.2-0.8,2.5-0.9,3.7c-0.3,2.4,0,4.7,0.7,6.8c0.7,2.1,1.8,4,3.3,5.5c1.5,1.5,3.3,2.6,5,3.2
						c3.6,1.1,6.6,0.4,8.6-0.7c2.1-1.1,3.2-2.7,3.5-3.9c0.2-0.6,0.2-1.1,0.1-1.5c-0.1-0.4-0.3-0.7-0.6-0.8c-0.3-0.2-0.6-0.3-1-0.3
						c-0.4,0-0.8,0-1.2-0.1c-0.8,0-1.8-0.1-2.7-0.4c-0.9-0.3-2-0.9-2.4-1.5c-0.2-0.3-0.3-0.7-0.4-1.1c-0.1-0.4-0.1-0.8-0.1-1.4
						c0-0.5,0.2-1,0.4-1.4c0.1-0.2,0.2-0.4,0.3-0.5c0.1-0.2,0.3-0.4,0.5-0.5c0.4-0.4,0.9-0.7,1.5-1c0.6-0.3,1.3-0.5,2-0.6
						c1.4-0.2,3.4,0.1,5.5,0.9c2,0.8,4.2,2.4,6.2,4.4c1.1,1,2.1,2.2,3,3.4c0.9,1.2,1.8,2.3,2.6,3.5c1.5,2.4,2.8,4.7,3.8,7.1
						c2,4.7,3.3,9.3,3.7,14.1c0.5,4.8,0.2,9.7-1.1,15.2c-1.3,5.4-3.8,11.3-8.4,17.4c5.2-5.5,8.6-11.1,10.9-16.5
						c2.3-5.4,3.7-10.6,4.3-15.9c0.6-5.3,0.5-10.8-0.6-16.8c-0.6-3-1.4-6.1-2.7-9.4c-0.7-1.6-1.4-3.3-2.3-5
						C154.2,530.4,153.2,528.7,152,526.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M180.5,567.9c3.9-1.9,7.3-4.1,9.8-6.3c2.5-2.1,4.1-4.2,3.6-5.9c-0.5-1.6-3.3-2.4-7.1-1.7c-3.7,0.7-8.4,3.2-11.7,7
						c-3.4,3.9-5.2,7-6.8,10c-1.6,3.1-3.1,6.2-4.9,10.7c2.8-4.1,5.3-6.3,7.8-8.3C173.8,571.6,176.5,569.9,180.5,567.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M174.5,542.4c0.4-3.8-1-7.4-2.8-9.6c-1.9-2.2-4.1-3-5.6-2.2c-1.6,0.9-2.2,2.9-2.2,5.3c0,2.4,0.6,5.1,1.9,7.8
						c1.3,2.9,2.2,5,2.8,7.3c0.6,2.3,1,4.7,0.6,8.2c1.4-3.3,2.3-5.6,3.2-8C173.2,548.8,174.1,546.3,174.5,542.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M59.8,573.1c-3.7,3.4-7.3,6.6-11.1,9.2c-3.7,2.5-7.5,4.5-10.6,4.9c-3.2,0.5-5.8-0.6-7.3-1.7c-1.6-1.1-2.1-2.3-2.6-2.2
						c-0.2,0.1-0.3,0.4-0.3,1.1c0.1,0.7,0.4,1.7,1.1,2.7c0.7,1.1,1.9,2.2,3.5,3c1.6,0.8,3.7,1.3,5.9,1.3c2.2-0.1,4.5-0.6,6.7-1.5
						c2.2-0.9,4.3-2.1,6.4-3.4c4.2-2.8,8-6.4,11.4-10.2c3.5-3.9,6.3-7.3,8.9-10.2c2.6-2.9,5.1-5.4,7.9-7.7c2.7-2.2,5.7-4.2,9.4-5.8
						c3.7-1.5,8.2-2.7,13.6-2c-5.3-1-10-0.1-13.9,1.2c-3.9,1.3-7.2,3.1-10.3,5.1c-3.1,2-5.9,4.3-8.9,7
						C66.7,566.4,63.5,569.6,59.8,573.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M73.8,568.7c-1.9,3-3.2,6.4-4,9.7c-0.8,3.3-0.9,6.4-0.8,9.1c0.4,5.4,2.1,8.6,2.9,8.4c0.9-0.2,0.8-3.6,1.3-8.4
						c0.3-2.4,0.7-5.1,1.4-8c0.7-2.9,1.7-5.9,3-8.8c1.4-3,2.8-5.4,4.4-7.4c1.6-2,3.2-3.7,4.9-5.3c1.8-1.5,3.7-3,6-4.3
						c2.3-1.3,5.1-2.5,8.6-3.4c-3.5,0.6-6.4,1.6-9,2.6c-2.5,1.1-4.7,2.2-6.8,3.5c-2.1,1.3-4,2.8-6.1,4.7
						C77.8,563.3,75.8,565.6,73.8,568.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M82.3,565.7c-1.2,2.3-2,4.8-2.4,7.2c-0.4,2.4-0.3,4.7,0,6.6c0.6,3.9,2.2,6.1,3,5.9c0.9-0.2,0.9-2.7,1.2-6
						c0.1-1.7,0.4-3.6,0.7-5.6c0.4-2,0.9-4.2,1.5-6.3c0.7-2.2,1.5-4,2.4-5.5c0.9-1.6,1.8-2.9,2.9-4.2c1.1-1.3,2.3-2.5,3.8-3.7
						c1.5-1.2,3.3-2.3,5.7-3.3c-2.4,0.8-4.4,1.7-6.1,2.6c-1.7,0.9-3.2,1.9-4.6,3c-1.4,1.1-2.7,2.3-4.1,3.8
						C84.9,561.6,83.5,563.4,82.3,565.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M126.8,555.4c3.6,0.7,7.5,0,9.9-1.3c2.5-1.3,3.7-2.8,3.3-3.6c-0.4-0.8-2.2-0.7-4.6-0.4c-2.4,0.3-5.3,0.6-8.5,0.9
						c-3.2,0.2-5.5,0-7.8-0.4c-2.3-0.5-4.6-1.3-7.6-3.4c2.6,2.5,4.7,3.9,6.9,5.1C120.8,553.5,123.1,554.6,126.8,555.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M138.6,563.1c1.7,1.4,3.4,2.4,4.9,3.1c1.5,0.7,2.8,0.9,3.4,0.3c0.6-0.6,0.4-2-0.8-3.5c-1.1-1.5-3.1-3.1-5.5-3.8
						c-2.4-0.7-4.2-0.9-5.9-1c-1.7-0.1-3.4-0.2-5.8-0.2c2.4,0.5,3.9,1.2,5.3,2C135.6,560.8,136.9,561.7,138.6,563.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M141,578.8c0.3,2.1,1,4.1,1.8,5.5c0.8,1.5,1.7,2.4,2.6,2.3c0.9-0.1,1.5-1.4,1.5-3.2c0.1-1.8-0.5-4.2-1.8-6.2
						c-1.3-2.1-2.4-3.4-3.5-4.6c-1.1-1.2-2.2-2.5-3.8-4.2c1.2,2.1,1.7,3.6,2.2,5.2C140.4,575.1,140.7,576.6,141,578.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M143.4,505.7c0.1-2,0.1-3.7-0.1-5.2c-0.2-1.5-0.5-2.7-1.3-3c-0.8-0.3-2,0.5-2.8,2.3c-0.8,1.7-1,4.4-0.2,6.7
						c0.9,2.4,1.9,3.8,3,5.1c1.1,1.3,2.2,2.5,4,4c-1.5-1.8-2-3.4-2.3-4.8C143.4,509.2,143.3,507.7,143.4,505.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M158.8,498.6c0.9-1.8,1.7-3.4,2.3-4.9c0.6-1.4,0.9-2.7,0.4-3.4c-0.5-0.7-2.1-0.5-3.6,0.8c-1.6,1.3-3.1,3.7-3.3,6.4
						c-0.2,2.8,0.3,4.6,0.9,6.3c0.6,1.7,1.4,3.3,2.8,5.3c-1-2.3-1.1-4-0.9-5.5C157.5,501.9,157.9,500.4,158.8,498.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M187.9,576c2.7-0.4,5.2-1.1,7.1-1.9c1.9-0.8,3.2-1.7,3.1-2.6c-0.1-0.9-1.6-1.5-3.8-1.5c-2.2,0-5.1,0.5-7.7,1.8
						c-2.7,1.3-4.4,2.5-6.1,3.6c-1.8,1.2-3.5,2.4-5.8,4c2.7-1.2,4.6-1.8,6.5-2.3C183.2,576.8,185.2,576.4,187.9,576z"
                    />
                  </g>
                  <g>
                    <path
                      d="M149,392.3c3,0.1,5.8-1,7.6-2.1c1.8-1.1,2.7-2.4,2.4-3.2c-0.3-0.8-1.9-1-3.9-0.7c-2,0.3-4.4,1-6.7,1.7
						c-2.4,0.8-4.2,1-6,0.9c-1.8-0.1-3.8-0.5-5.9-2.5c1.7,2.3,3.6,3.4,5.4,4.3C143.8,391.6,145.9,392.2,149,392.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M134,321c-1.8,2.8-3.2,6-3.8,9.2c-0.6,3.2-0.5,6.3,0.2,8.9c0.7,2.6,1.8,4.5,2.9,5.6c0.5,0.6,1,0.9,1.4,1.1
						c0.4,0.2,0.7,0.2,0.8,0.1c0.7-0.6-0.7-3.3-1-7.6c-0.1-2.1,0-4.6,0.5-7.2c0.5-2.6,1.5-5.3,2.8-8c1.3-2.8,2.7-5.1,4-7.1
						c1.3-2.1,2.6-3.9,3.9-5.7c1.3-1.9,2.6-3.7,4-5.8c1.4-2.1,2.9-4.5,4.3-7.6c-1.6,2.9-3.4,5.1-5,7.1c-1.6,1.9-3.2,3.5-4.8,5.2
						c-1.6,1.6-3.1,3.2-4.8,5.1C137.7,315.9,135.8,318.1,134,321z"
                    />
                  </g>
                  <g>
                    <path
                      d="M143.2,322.4c-0.7,5.5,0.4,11.2,2,15.1c1.6,3.9,3.6,6,4.4,5.6c0.8-0.4,0.3-3.1-0.4-6.8c-0.7-3.8-1.4-8.7-1.7-13.7
						c-0.2-5.2,0.2-8.9,1-12.5c0.8-3.6,2-7.3,5-12.1c-3.4,4.5-5.2,8-6.8,11.5C145.2,313,143.9,316.7,143.2,322.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M149.7,316.6c0,4,1.3,7.8,2.8,10.4c1.5,2.6,3.2,3.9,3.9,3.4c0.8-0.4,0.5-2.4-0.1-5c-0.6-2.6-1.5-6-2.3-9.4
						c-0.9-3.5-1.2-6.1-1.4-8.7c-0.1-2.6,0-5.3,1-9.2c-1.5,3.7-2.3,6.3-2.9,9C150.2,309.8,149.7,312.5,149.7,316.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M93.8,341.5c3.2-2.6,5.7-6,7.1-8.8c1.4-2.8,1.7-5,0.9-5.4c-0.8-0.4-2.4,0.9-4.4,3c-2,2.1-4.4,5-6.8,8.1
						c-2.4,3.1-4.3,5.2-6.3,7.2c-2,2-4.2,3.8-7.8,6.1c3.8-1.8,6.4-3.2,8.9-4.6C88,345.7,90.5,344.2,93.8,341.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M102.3,347.6c2.7-0.3,5.3-1,7.2-1.7c1.9-0.8,3.2-1.7,3.1-2.5c-0.1-0.9-1.6-1.5-3.8-1.6c-2.2-0.1-5.1,0.4-7.7,1.7
						c-2.7,1.3-4.4,2.4-6.2,3.5c-1.8,1.2-3.6,2.3-5.9,3.8c2.7-1.2,4.7-1.7,6.6-2.1C97.6,348.2,99.5,347.9,102.3,347.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M119.4,246.7c1.9-1.6,3.5-3.5,4.5-5.2c1-1.6,1.4-3,0.8-3.7c-0.6-0.6-2.1-0.3-3.8,0.8c-1.7,1.1-3.6,3-4.9,5.4
						c-1.3,2.4-2,4.2-2.8,5.9c-0.8,1.9-1.5,3.7-2.5,6c1.5-2.3,2.8-3.7,4.1-5C116.1,249.6,117.4,248.4,119.4,246.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M20.7,384.4c-7.8,3.2-14.9,7.9-19.7,12.1c-4.8,4.2-7.2,7.7-6.7,8.3c0.6,0.7,4.1-1.6,9.2-5c5.1-3.3,11.9-7.6,19-11.4
						c7.3-3.9,12.7-6.1,18.2-8c5.5-1.8,11.3-3.3,20-4.3c-8.8,0.4-14.7,1.3-20.5,2.4C34.5,379.7,28.7,381.1,20.7,384.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M27.1,386.9c-6.2,3.1-11.7,7.5-15.3,11.3c-3.6,3.8-5.4,6.9-4.8,7.4c0.7,0.6,3.5-1.3,7.5-4.3c4-3,9.3-6.9,14.8-10.6
						c5.7-3.8,9.8-6.1,14.1-8.2c4.3-2,8.9-3.9,15.8-5.7c-7.1,1.2-11.9,2.5-16.5,3.9C38.1,382.1,33.5,383.7,27.1,386.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-19.8,372.5c-3.2-2.4-6.3-4.7-8.9-6.3c-2.5-1.6-4.5-2.5-5.1-1.9c-0.6,0.6,0.3,2.7,2.4,5.1c2.1,2.4,5.5,5.1,9.3,6.9
						c4,1.8,6.8,2.7,9.6,3.6c2.9,0.8,5.7,1.6,10,2.5c-4.1-1.4-6.8-2.8-9.3-4.2C-14.2,376.7-16.6,375-19.8,372.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-14.5,384.4c-0.9,0-1.8,0.2-2.7,0.6c-0.9,0.3-1.9,1-2.5,2c-0.6,1-0.6,2.1-0.3,2.7c0.3,0.7,0.9,0.8,1.3,0.7
						c0.4-0.1,0.8-0.4,1.2-0.5c0.4-0.1,0.7-0.1,1-0.1c0.2,0.1,0.6,0,1.2-0.2c0.7-0.2,1.4-0.5,2.1-1c1.4-0.9,2.5-1.5,3.6-2
						c1.1-0.5,2.3-0.9,4.1-1.2c-1.6-0.2-2.9-0.5-4.4-0.7C-11.3,384.6-12.6,384.4-14.5,384.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-13.6,395.2c-2.1,1.6-3.6,3.7-4.4,5.6c-0.7,1.8-0.7,3.3,0,3.8c0.8,0.5,2-0.2,3.4-1.4c1.4-1.2,2.9-3,4.1-5
						c1.3-2.1,2.3-3.5,3.4-4.9c1.1-1.4,2.3-2.7,4.3-4.4c-2.3,1.2-4,2-5.6,2.9C-9.8,392.7-11.4,393.6-13.6,395.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-9.5,421.5c-1.3,2.9-0.3,6,1.4,6.3c1.7,0.2,3.5-2.6,2.9-5.7c-0.5-3.2-1.1-4.4-1.2-7.7C-7.4,417.5-8.2,418.6-9.5,421.5
						z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-2.1,415.6c-1.2,2.5-0.2,5.3,1.5,5.5c1.7,0.2,3.4-2.2,2.8-4.9c-0.6-2.8-1.2-3.9-1.4-6.7C0,412.2-0.9,413.1-2.1,415.6z
						"
                    />
                  </g>
                  <g>
                    <path
                      d="M578.1,305.9c3.3-4.2,6.4-8.7,8.9-13.6c2.5-4.9,4.2-10.3,4.9-15.7c0.7-5.4,0.4-10.9-1.2-15.8c-0.4-1.2-0.8-2.4-1.3-3.6
						c-0.5-1.2-1.1-2.3-1.8-3.4c-1.4-2.2-3.1-4.2-5.3-5.7c-1.1-0.8-2.3-1.3-3.6-1.8c-1.3-0.4-2.7-0.6-4-0.5
						c-2.5,0.1-4.9,0.9-6.8,2.5c-0.9,0.8-1.7,1.7-2.3,2.7c-0.6,1-0.8,2.1-1,3.1c-0.2,2,0.2,3.7,0.7,5c1.1,2.5,2.5,3.9,3.5,4.8
						c1,0.9,1.7,1.1,2.1,0.9c0.4-0.2,0.3-1,0.1-2.2c-0.2-1.2-0.8-2.9-1-4.7c-0.1-0.9,0-1.8,0.3-2.5c0.2-0.3,0.4-0.7,0.6-0.9
						c0.2-0.2,0.5-0.4,0.9-0.6c0.7-0.3,1.7-0.5,2.7-0.3c0.5,0.1,1,0.2,1.4,0.4c0.4,0.2,0.9,0.6,1.4,1c0.9,0.8,1.8,2,2.5,3.4
						c0.7,1.4,1.3,3.2,1.8,5c0.9,3.6,1,7.7,0.4,12c-0.6,4.2-1.9,8.6-3.8,12.8c-1.9,4.3-4.4,8.5-7.2,12.6c-2.9,4.3-5.8,8.2-8.5,11.9
						c-2.7,3.7-5.2,7.3-7.5,10.8c-2.2,3.5-4.2,7-5.8,10.6c-1.6,3.6-2.8,7.2-3.7,10.9c-1.7,7.4-2.1,15.1-0.8,23.5
						c0.6,4.2,1.7,8.5,3.4,13c1.7,4.4,4,9,7.3,13.5c-3-4.6-5.1-9.3-6.5-13.7c-1.4-4.5-2.1-8.8-2.4-12.9c-0.6-8.2,0.5-15.5,2.8-22.3
						c1.2-3.4,2.6-6.6,4.4-9.7c1.8-3.1,4-6.2,6.4-9.3c2.4-3.1,5.2-6.3,8.2-9.7C571.4,313.8,574.7,310.2,578.1,305.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M561.9,353.7c1-1.7,2-3.3,3.1-4.8c1.1-1.5,2.2-2.8,3.4-4.1c1.1-1.3,2.4-2.4,3.5-3.4c0.3-0.2,0.6-0.5,0.9-0.7
						c0.3-0.2,0.5-0.4,0.8-0.6c0.5-0.4,1.1-0.7,1.6-1c2.2-1.2,4-1.8,5.4-2c1.5-0.3,2.7-0.1,3.3-0.7c0.3-0.3,0.4-0.8,0.2-1.5
						c-0.2-0.7-0.8-1.5-1.9-2.3c-1-0.8-2.5-1.4-4.3-1.6c-1.7-0.2-3.7-0.1-5.6,0.3c-1,0.2-2,0.6-3,1c-0.5,0.2-1,0.5-1.5,0.7
						c-0.5,0.3-0.9,0.5-1.4,0.8c-1.8,1.1-3.7,2.5-5.3,4.2c-1.6,1.7-3.1,3.6-4.4,5.6c-1.2,2.1-2.2,4.3-3,6.5
						c-1.6,4.7-2.1,8.7-2.3,12.3c-0.2,3.6,0,6.8,0.3,9.9c0.7,6.3,1.9,12.4,3.8,21.4c-0.9-9.1-0.8-15.4,0-21.4c0.4-3,1-5.9,1.9-9
						C558.6,360.4,559.9,357.1,561.9,353.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M574.5,279.9c-0.6-1.9-1.5-3.7-2.6-5.3c-0.6-0.8-1.2-1.6-1.9-2.3c-0.7-0.7-1.5-1.3-2.3-1.9c-1.7-1.1-3.9-1.8-6.2-1.5
						c-1.1,0.2-2.1,0.5-3,1.1c-0.9,0.6-1.6,1.4-2.1,2.4c-0.4,0.9-0.6,1.9-0.6,2.8c0,0.9,0.2,1.6,0.5,2.3c0.5,1.3,1.2,2.1,1.8,2.7
						c1.2,1.2,2.1,1.6,2.4,1.4c0.4-0.3,0.1-1.2-0.4-2.6c-0.2-0.7-0.5-1.5-0.6-2.4c-0.1-0.9,0.1-1.5,0.5-2c0.4-0.4,1.1-0.8,2-0.9
						c0.8-0.1,1.9,0.3,2.9,1c1.1,0.7,2.1,1.8,3.1,2.9c0.9,1.2,1.7,2.5,2.4,3.9c1.3,3,1.8,5.9,1.9,8.5c0.1,2.6-0.2,5.1-0.8,7.5
						c-1.1,4.8-3.2,9.6-6.8,16.2c4.1-6.4,6.7-10.8,8.6-15.6c0.9-2.4,1.7-4.9,2-7.9C575.6,287.2,575.6,283.8,574.5,279.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M568.9,290c-0.3-0.5-0.5-1.1-0.8-1.6c-0.3-0.5-0.7-1.1-1.2-1.5c-0.9-1-1.9-1.8-3.4-2.3c-0.7-0.3-1.5-0.4-2.3-0.4
						c-0.8,0-1.6,0.3-2.3,0.6c-1.4,0.7-2.2,1.8-2.6,2.8c-0.5,1-0.6,2-0.6,2.7c0,0.8,0.1,1.4,0.3,1.9c0.3,1,0.7,1.5,1.2,1.5
						c0.4,0,0.9-0.5,1.3-1.4c0.4-0.8,0.9-2,1.5-2.7c0.3-0.4,0.7-0.6,1-0.7c0.3,0,0.5,0,0.9,0.1c0.4,0.2,1,0.5,1.6,1.1
						c0.3,0.3,0.6,0.5,0.8,0.9c0.3,0.3,0.5,0.7,0.8,1.1c1.1,1.5,1.8,3,2.3,4.5c0.5,1.4,0.8,2.8,0.9,4.2c0.1,1.4,0.1,2.9-0.3,4.5
						c-0.3,1.6-0.9,3.4-2.1,5.3c1.4-1.7,2.3-3.4,3-5c0.7-1.6,1.1-3.1,1.3-4.7c0.2-1.6,0.4-3.1,0.2-4.9
						C570.3,294.2,569.9,292.2,568.9,290z"
                    />
                  </g>
                  <g>
                    <path
                      d="M548.8,301.3c-1.9-0.3-3.8-0.3-5.2,0c-1.5,0.3-2.6,0.9-2.7,1.8c-0.2,0.9,0.7,1.8,2.3,2.4c1.6,0.6,3.8,0.8,5.9,0.2
						c2.2-0.6,3.6-1.2,5-1.9c1.5-0.7,2.9-1.4,4.7-2.2c-2.1,0.4-3.6,0.5-5.1,0.4C552.1,301.9,550.7,301.7,548.8,301.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M536.3,308.9c-5.8,0.2-11.1,2.8-10.6,4.5c0.4,1.7,6.2,1.7,11.5-0.2c5.5-1.9,7.5-3,13.4-4.3
						C544.5,309.2,542.3,308.8,536.3,308.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M525.6,316.8c-3.9-0.7-7.6,0.8-7.6,2.5c0.1,1.8,4,2.9,7.8,1.8c3.9-1.1,5.3-1.9,9.3-2.6
						C531,318.2,529.6,317.5,525.6,316.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M537.6,319c-2.2,1.1-3.9,2.9-4.8,4.6c-0.9,1.6-1.1,3.1-0.4,3.6c0.7,0.6,2,0.2,3.4-0.7c1.5-0.9,3.1-2.3,4.5-4
						c1.5-1.7,2.6-2.9,3.8-4.1c1.2-1.1,2.5-2.2,4.6-3.4c-2.4,0.7-4,1.3-5.6,1.8C541.5,317.3,539.9,317.8,537.6,319z"
                    />
                  </g>
                  <g>
                    <path
                      d="M545.3,322.1c-1.5,1.4-2.5,3.1-2.9,4.6c-0.4,1.5-0.2,2.7,0.6,3.2c0.8,0.4,1.9,0,3-1c1.1-1,2.2-2.5,2.9-4.3
						c1.5-3.7,1.8-5.3,4.1-8.8C549.9,318.6,548.4,319.3,545.3,322.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M554.7,308c-1.3,0.9-2.1,2.2-2.4,3.4c-0.3,1.2,0,2.2,0.7,2.7c0.7,0.5,1.8,0.3,2.7-0.3c1-0.6,1.9-1.7,2.4-3
						c1.1-2.8,1.3-4.1,3.2-6.7C558.7,305.8,557.4,306.2,554.7,308z"
                    />
                  </g>
                  <g>
                    <path
                      d="M532.3,341c-1.2,1.5-1.8,3.3-1.8,4.8c0,1.5,0.5,2.5,1.4,2.7c0.9,0.2,1.8-0.4,2.6-1.5c0.8-1,1.5-2.5,1.8-4.2
						c0.3-1.7,0.5-3,0.9-4.2c0.3-1.2,0.8-2.4,1.7-4.1c-1.4,1.3-2.3,2.2-3.3,3.1C534.5,338.6,533.5,339.5,532.3,341z"
                    />
                  </g>
                  <g>
                    <path
                      d="M533.2,356.5c-2.1,3.9-1.8,8.5-0.1,8.9c1.7,0.4,3.9-3.4,4.3-7.6c0.4-4.4,0.2-6.1,1.4-10.5
						C536.7,351.3,535.4,352.5,533.2,356.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M533.9,379.3c-0.4,2.4,0.2,4.9,1,6.6c0.8,1.7,2,2.6,2.8,2.3c0.8-0.3,1.2-1.6,1.3-3.2c0.1-1.7-0.2-3.7-0.7-5.8
						c-0.5-2.2-0.8-3.7-1-5.4c-0.2-1.6-0.2-3.3,0.2-5.7c-0.9,2.3-1.5,3.8-2.1,5.5C534.8,375.2,534.2,376.8,533.9,379.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M570.2,359.3c1.9-2.2,3.7-4.2,5-6c1.3-1.7,2.2-3.2,1.7-3.9c-0.5-0.7-2.3-0.5-4.4,0.8c-2.2,1.3-4.6,3.7-6,6.8
						c-1.4,3.1-1.9,5.4-2.3,7.6c-0.4,2.2-0.6,4.5-0.6,7.8c0.5-3.2,1.4-5.3,2.4-7.2C567.1,363.2,568.3,361.5,570.2,359.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M580.7,319.9c1.7-0.7,3-1.7,4-2.7c0.9-1,1.4-2.1,1.1-2.9c-0.4-0.8-1.6-1.1-3.1-0.8c-1.5,0.3-3.3,1.3-4.6,2.8
						c-1.3,1.6-2.1,2.8-2.8,4c-0.8,1.3-1.5,2.6-2.4,4.1c1.4-1.4,2.6-2.1,3.8-2.7C577.8,321.1,579,320.6,580.7,319.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-6.5,258.1c1.9-0.4,3.5-1.1,4.7-2c1.2-0.9,1.9-1.9,1.6-2.7c-0.2-0.8-1.4-1.3-3-1.2c-1.6,0.1-3.5,0.7-5.2,2
						c-1.7,1.3-2.7,2.3-3.8,3.3c-1,1-2.1,2-3.7,3.4c1.9-0.9,3.2-1.4,4.6-1.7C-9.8,258.7-8.5,258.5-6.5,258.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M8.4,246.2c-1.5,2.6-2.1,5.6-2,7.8c0.1,2.3,0.8,3.7,1.7,3.8c0.9,0.1,1.7-1.2,2.5-3.1c0.8-1.9,1.5-4.3,2-6.9
						c0.5-2.7,1.1-4.5,1.7-6.4c0.7-1.8,1.5-3.7,3.3-6.1c-2.1,2-3.5,3.5-4.9,5.1C11.2,242,9.9,243.6,8.4,246.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M544.8,223.7c-1.8-1.5-3.8-2.7-6-3.5c-2.1-0.9-4.5-1.4-6.9-1.4c-1.2,0-2.4,0.2-3.5,0.6c-1.2,0.4-2.3,0.9-3.2,1.6
						c-1.9,1.4-3.2,3.3-3.7,5.3c-0.3,1-0.3,2-0.2,3.1c0.2,1,0.5,1.8,0.9,2.5c0.9,1.4,2.1,2.3,3.1,2.7c1.1,0.4,2,0.4,2.6,0.2
						c0.6-0.2,0.8-0.4,0.8-0.6c-0.1-0.5-1.1-0.6-2.2-1.7c-1.1-1-2.1-3.1-1.2-5c0.4-1,1.3-2,2.4-2.7c0.6-0.4,1.2-0.6,1.9-0.8
						c0.7-0.2,1.5-0.3,2.2-0.3c3.2,0,7,1.4,10.2,3.5c3.3,2.2,5.7,4.8,7.4,7.5c0.9,1.3,1.6,2.7,2.2,4c0.6,1.4,1.1,2.7,1.5,4.1
						c0.8,2.8,1.2,5.8,1.2,9.2c0,3.4-0.4,7.1-1.6,11.5c1.4-4.3,2.2-8,2.5-11.5c0.3-3.4,0.2-6.5-0.3-9.6c-0.3-1.5-0.6-3.1-1-4.6
						c-0.5-1.5-1.1-3.1-1.9-4.7C550.7,230.1,548.4,226.7,544.8,223.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M547.9,236.8c-0.9-1.3-2.2-2.4-3.6-3.2c-1.5-0.8-3.3-1.2-5-1c-1.8,0.2-3.4,1.2-4.5,2.4c-1.1,1.2-1.7,2.9-1.6,4.4
						c0.1,1.7,1,2.9,1.8,3.6c0.9,0.7,1.9,1.1,2.7,1.1c0.8,0,1.4-0.2,1.8-0.5c0.3-0.3,0.4-0.6,0.3-0.8c-0.2-0.4-0.9-0.6-1.4-1.1
						c-0.3-0.3-0.6-0.6-0.8-1.1c-0.2-0.4-0.3-0.9-0.1-1.2c0.1-0.4,0.4-0.8,0.8-1.2c0.5-0.4,1.1-0.7,1.7-0.7c1.3-0.2,3.2,0.6,4.7,2
						c1.6,1.5,2.6,3.5,3.6,5.3c0.9,1.8,1.7,3.4,2.3,5.1c0.6,1.7,1.2,3.5,1.6,5.5c0.4,2,0.7,4.4,0.8,7.1c0.2-2.8,0.2-5.1,0.1-7.2
						c-0.1-2.1-0.3-4-0.6-5.9c-0.3-1.9-0.7-3.7-1.3-5.8c-0.3-1-0.6-2.1-1.1-3.3C549.5,239.4,548.9,238.1,547.9,236.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M613.1,514.1c0.5,1.2,0.7,2.4,0.8,3.5c0,1.1-0.1,2-0.4,2.7c-0.3,0.7-0.7,1.4-1.3,2.1c-0.6,0.6-1.4,1.3-2.4,1.7
						c-1.9,1-4,1.4-6.1,1.3c-2-0.1-3.6-0.8-4.7-1.5c-1.2-0.9-2-2.1-2.3-3.2c-0.4-1-0.3-2.3,0.1-3.4c0.4-1.1,0.9-2,1.4-2.8
						c0.5-0.8,0.9-1.4,0.9-2.1c0-0.6-0.6-1.3-1.9-1.6c-1.3-0.3-3.3,0-5.5,1.3c-1.1,0.7-2.1,1.6-3.1,2.9c-0.5,0.7-0.9,1.4-1.2,2.2
						c-0.4,0.8-0.6,1.8-0.8,2.7c-0.7,3.8,0.2,8.2,2.9,11.9c2.8,3.9,7.2,6.5,12,7.4c4.7,1,10.1,0.5,14.9-1.9c2.4-1.2,4.7-2.8,6.7-5
						c2-2.2,3.6-4.9,4.4-8c0.8-3,0.8-6.2,0.3-9c-0.6-2.9-1.7-5.4-3.1-7.7c-1.5-2.3-3.3-4.3-5.2-5.9c-1.9-1.6-4.1-2.9-6.1-3.7
						c-4.1-1.7-8-2.3-11.5-2.4c-7.1-0.1-12.9,1.4-18.6,3.1c-5.6,1.8-11.1,4.1-16.8,7.4c-5.7,3.2-11.6,7.4-17.6,13.4
						c6.6-5.4,12.9-8.7,18.8-11c5.9-2.3,11.4-3.5,16.8-4.2c5.4-0.6,11-0.7,16.3,0.5c2.7,0.6,5.3,1.6,7.5,3.1c1.2,0.8,2,1.6,2.8,2.6
						C611.9,511.7,612.6,512.9,613.1,514.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M586.9,487.9c2.8-1.8,5.2-4.2,7.1-6.8c1.9-2.6,3.2-5.3,3.9-7.8c0.7-2.5,0.8-4.7,0.6-6.3c-0.3-1.6-0.9-2.5-1.8-2.7
						c-1.8-0.3-3.7,2.1-6.3,5.3c-1.3,1.7-2.7,3.5-4.3,5.4c-1.6,2-3.3,4.1-5.1,6.3c-1.8,2.3-3.6,4.1-5.2,5.7
						c-1.7,1.6-3.2,3.1-4.9,4.4c-3.3,2.7-6.9,5.3-12.6,8.5c6.1-2.3,10.3-3.7,14.5-5.2c2.1-0.8,4.2-1.6,6.5-2.7
						C581.6,491.1,584.1,489.8,586.9,487.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M574,520.4c-1.2,2.3-1.3,5.1-0.8,7c0.5,1.9,1.5,3,2.4,2.8c0.9-0.2,1.4-1.4,1.8-3c0.4-1.6,0.7-3.5,0.8-5.6
						c0.2-2.2,0.5-3.7,1-5.3c0.5-1.5,1.3-3,2.9-4.9c-2,1.4-3.3,2.6-4.5,3.9C576.4,516.6,575.3,518,574,520.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M580.2,543.9c0.4,2.6,1.7,5,3.2,6.4c1.5,1.4,2.9,1.9,3.6,1.3c0.7-0.6,0.5-2,0-3.6c-0.5-1.6-1.4-3.6-2.5-5.5
						c-1.1-2-1.8-3.6-2.3-5.2c-0.5-1.6-0.9-3.3-0.8-5.9c-0.6,2.5-0.9,4.3-1.1,6.1C580,539.4,579.8,541.2,580.2,543.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M599.3,558c1.3,1.4,3,2.4,4.7,2.8c1.7,0.5,3.4,0.5,4.8,0.2c1.4-0.3,2.4-0.9,3-1.4c0.6-0.6,0.8-1.1,0.7-1.5
						c-0.4-0.8-2-0.9-3.9-1.2c-2-0.3-4.3-0.9-6.5-2.2c-1.2-0.7-2.1-1.5-2.9-2.3c-0.8-0.8-1.4-1.7-2-2.6c-1.2-1.8-2.1-3.9-3.1-7.1
						c0.5,3.3,0.8,5.6,1.4,7.8c0.3,1.1,0.6,2.3,1.2,3.5C597.2,555.2,598,556.6,599.3,558z"
                    />
                  </g>
                  <g>
                    <path
                      d="M613.7,553.2c2.2,1.1,4.7,1.3,6.5,1c1.8-0.3,3-1.2,2.9-2.1c-0.1-0.9-1.2-1.6-2.7-2.1c-1.5-0.5-3.5-0.9-5.5-1
						c-2.2-0.2-3.7-0.4-5.2-0.7c-1.5-0.4-3.1-0.9-5.1-2.2c1.7,1.7,2.9,2.8,4.2,3.9C610.1,551,611.5,552.1,613.7,553.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M623.6,545.9c1.5,0.1,2.9-0.6,3.8-1.4c0.8-0.8,1.1-1.9,0.7-2.6c-0.4-0.8-1.3-1.2-2.4-1.2c-1,0-2.2,0.3-3.3,1
						c-1.1,0.8-1.9,1.3-2.8,1.8c-0.9,0.5-1.8,0.9-3.2,1.2c1.4,0.3,2.5,0.5,3.5,0.7C620.9,545.6,622,545.8,623.6,545.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M584.7,454.4c0.8-4,0.4-8.1-0.6-11.1c-0.9-2.9-2.3-4.6-3.1-4.4c-0.9,0.3-1.1,2.3-1,5c0,2.8,0.2,6.4,0.3,10.1
						c0.1,3.8,0,6.5-0.4,9.1c-0.4,2.7-1,5.4-2.7,9.1c2.2-3.5,3.4-6,4.5-8.6C582.9,461.2,583.9,458.5,584.7,454.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M175.8,236.9c-0.3,3.3,0.7,6.7,2.2,8.7c1.4,2.1,3.1,2.9,3.7,2.4c0.7-0.5,0.5-2.1,0.1-4.1c-0.5-2.3-1-4.4-1.6-7.2
						c-0.5-2.8-0.6-4.8-0.4-6.9c0.2-2,0.7-4.2,2.3-6.9c-2.1,2.4-3.2,4.4-4.2,6.4C177,231.3,176.2,233.5,175.8,236.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M316.4,56.6c3.3,0.8,6.5,1.5,9,2c2.5,0.5,4.4,0.7,4.8-0.1c0.4-0.7-0.8-2.4-3.3-3.8c-2.5-1.5-6.3-2.6-10.1-2.5
						c-3.9,0.1-6.5,0.7-9.1,1.5c-2.5,0.8-5,1.6-8.5,3.4c3.6-1.2,6.2-1.4,8.7-1.5C310.5,55.6,313,55.9,316.4,56.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M166.4,149.7c1.4,3.4,3.9,6.3,6.1,7.9c2.2,1.7,4.1,2.1,4.7,1.5c0.6-0.7-0.3-2.3-1.7-4.4c-1.4-2.1-3.3-4.6-5.3-7.2
						c-2.1-2.7-3.3-4.7-4.4-6.9c-1.1-2.2-2-4.5-2.6-8.1c0.1,3.7,0.4,6.2,0.8,8.7C164.4,143.7,165,146.2,166.4,149.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M79.1,161.1c1.1-2.5,1.1-5.4,0.4-7.4c-0.7-2-1.8-3-2.6-2.8c-0.9,0.3-1.3,1.6-1.5,3.2c-0.3,1.7-0.4,3.7-0.5,5.9
						c-0.1,2.3-0.4,3.9-0.9,5.5c-0.5,1.6-1.3,3.2-3.1,5.1c2.1-1.5,3.4-2.8,4.7-4.2C76.7,165.2,77.9,163.7,79.1,161.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M340.8,260.3c3.5-6.8,4.5-14.6,2.8-15.1c-1.7-0.5-5.1,6.3-6.9,13.5c-0.9,3.8-1.5,6.4-2.2,9c-0.7,2.6-1.5,5.2-2.9,9
						c1.9-3.5,3.3-5.9,4.7-8.3C337.6,266.1,339,263.8,340.8,260.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M485,235.3c3.3,0,6.5-0.3,9-0.8c2.4-0.5,4.1-1.2,4.2-2.1c0-0.9-1.7-1.7-4.2-2.1c-2.6-0.4-6.1-0.3-9.4,0.6
						c-3.4,0.9-5.7,1.8-8,2.7c-2.4,1-4.7,1.9-7.8,3.1c3.5-0.8,5.9-1.1,8.3-1.3C479.3,235.3,481.6,235.3,485,235.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M596.5,141.5c-2.7,0.3-5.3,1.3-7,2.5c-1.7,1.2-2.6,2.4-2.2,3.2c0.4,0.8,1.9,0.9,3.8,0.6c1.9-0.3,4.3-1,6.5-2.1
						c2.4-1.1,4-1.9,5.8-2.5c1.7-0.7,3.5-1.2,6.3-1.7c-2.6-0.1-4.5-0.1-6.6-0.2C601.2,141.3,599.3,141.2,596.5,141.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M548.5,79c-2.4,1.8-4.6,3.6-6.2,5.2c-1.6,1.6-2.6,2.9-2.2,3.7c0.4,0.8,2.2,0.8,4.5-0.2c2.3-1,5.1-3,7.1-5.6
						c2-2.7,3.1-4.8,4-6.9c0.9-2.1,1.8-4.2,2.9-7.4c-1.5,3-3,4.7-4.5,6.3C552.6,75.7,550.9,77.1,548.5,79z"
                    />
                  </g>
                  <g>
                    <path
                      d="M482.8,101.2c3.5-0.6,7-1.5,9.5-2.4c2.6-0.9,4.3-1.9,4.2-2.8c-0.1-0.9-2.1-1.4-4.9-1.3c-2.8,0.1-6.6,0.8-10,2.3
						c-3.6,1.5-5.9,2.8-8.2,4.1c-2.3,1.3-4.6,2.7-7.9,4.7c3.6-1.5,6.1-2.3,8.6-2.9C476.6,102.3,479.2,101.9,482.8,101.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M503.3,526.1c2.5-1.7,3.3-4.9,2-6c-1.4-1.1-4.3,0.4-5.4,3.3c-1.2,2.9-1.3,4.3-2.8,7.1
						C499.4,528.3,500.7,527.9,503.3,526.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M281.3,518.4c2,0.3,3.8,0.4,5.3,0.2c1.5-0.1,2.7-0.5,3-1.3c0.3-0.8-0.5-2-2.2-2.8c-1.6-0.8-4.1-1.2-6.5-0.5
						c-2.4,0.7-3.9,1.5-5.3,2.3c-1.4,0.9-2.8,1.8-4.6,3.2c2.1-1,3.6-1.3,5.1-1.4C277.8,518,279.3,518.1,281.3,518.4z"
                    />
                  </g>
                </g>
              </g>
              <g>
                <defs>
                  <path
                    id="SVGID_00000124864535544715738240000010146609127409941429_"
                    d="M135.4,401.1c28.1-30.7,167.6-144,191.4-122.2
					c23.8,21.8-77.2,170.5-105.4,201.2c-28.1,30.6-93.3,56.6-113.9,37.7C87,498.9,107.3,431.7,135.4,401.1z"
                  />
                </defs>
                <clipPath id="SVGID_00000013189815083744757780000010917144514411904436_">
                  <use
                    xlinkHref="#SVGID_00000124864535544715738240000010146609127409941429_"
                    overflow="visible"
                  />
                </clipPath>
              </g>
            </g>
          </g>
          <g id="Peddle5">
            <image
              overflow="visible"
              opacity="0.7"
              width="914"
              height="1777"
              xlinkHref={Shadow4}
              transform="matrix(0.24 0 0 0.24 192.8693 215.3764)"
            ></image>
            <g>
              <g>
                <defs>
                  <path
                    id="SVGID_00000126318939456402678290000017767105383641175199_"
                    d="M244.2,488.3c-1.8-41.6,16.7-220.4,48.9-221.8
					c32.2-1.4,66,175.2,67.8,216.7c1.8,41.6-25.9,106-53.8,107.2C279.1,591.8,246,529.9,244.2,488.3z"
                  />
                </defs>
                <clipPath id="SVGID_00000146483343504724242090000013693774821312679053_">
                  <use
                    xlinkHref="#SVGID_00000126318939456402678290000017767105383641175199_"
                    overflow="visible"
                  />
                </clipPath>
              </g>
              <g>
                <defs>
                  <path
                    id="SVGID_00000104677717090975692990000005847580124976248248_"
                    d="M244.2,488.3c-1.8-41.6,16.7-220.4,48.9-221.8
					c32.2-1.4,66,175.2,67.8,216.7c1.8,41.6-25.9,106-53.8,107.2C279.1,591.8,246,529.9,244.2,488.3z"
                  />
                </defs>
                <use
                  xlinkHref="#SVGID_00000104677717090975692990000005847580124976248248_"
                  overflow="visible"
                  fill="#36B657"
                />
                <clipPath id="SVGID_00000028289758167624516260000017202991382767845022_">
                  <use
                    xlinkHref="#SVGID_00000104677717090975692990000005847580124976248248_"
                    overflow="visible"
                  />
                </clipPath>
                <g
                  opacity="0.2"
                  clipPath="url(#SVGID_00000028289758167624516260000017202991382767845022_)"
                >
                  <g>
                    <g>
                      <path
                        d="M312.1,219.6c8.1-1.2,15.4-3.8,20.8-7c5.4-3.2,8.8-6.9,8.1-10.4c-0.7-3.4-5.5-5.7-12.2-5.8c-6.7-0.1-15.4,2-22.9,6.8
							c-7.7,4.9-12.4,9-17.2,13c-4.7,4-9.4,8-16.3,13.7c8.1-3.8,13.9-5.6,19.8-6.9C297.9,221.7,303.8,220.8,312.1,219.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M314.5,239.9c7,1.8,13.9,2.1,19.5,1.1c5.5-1,9.7-3.2,10.5-6.6c0.8-3.4-2.4-7.3-8.1-9.9c-5.6-2.6-13.8-3.8-21.8-2.1
							c-8.2,1.8-13.5,3.9-18.8,6c-5.3,2.1-10.5,4.2-18,7.1c8-0.8,13.5-0.4,18.9,0.5C302,236.8,307.2,238.1,314.5,239.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M303.9,258.7c4.2,3.4,8.8,5.1,13,5.4c4.2,0.3,8.1-0.9,10-3.9c1.9-3,1.1-7.2-2.1-10.9c-3.1-3.7-8.6-6.8-14.9-7.1
							c-6.5-0.4-10.9,0.2-15.2,0.7c-4.3,0.5-8.6,1-14.6,1.5c5.8,1.7,9.5,3.7,12.9,5.9C296.4,252.6,299.5,255.2,303.9,258.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M282.6,276.1c1.5,4.7,4.5,8,8,9.7c3.4,1.7,7.3,1.9,10.2-0.1c2.9-2,4.1-5.7,3.3-9.8c-0.7-4-3.4-8.3-7.9-11
							c-4.6-2.8-8.1-4.2-11.5-5.8c-3.4-1.6-6.6-3.2-10.9-6.1c2.8,4.3,4.2,7.7,5.3,11.2C280.2,267.7,281.1,271.2,282.6,276.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M262,284.1c-1.5,5-1.3,9.6,0.1,13.5c1.3,3.8,3.8,7,7.3,7.6c3.4,0.7,7.1-1.5,9.3-5.8c2.2-4.2,3-10.5,0.8-16.4
							c-2.3-6-4.6-9.7-6.8-13.4c-2.3-3.6-4.5-7.1-7.6-12.2c1,5.8,0.8,9.9,0.1,13.8C264.5,275.2,263.5,279,262,284.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M238.4,276.8c-4.1,3.3-6.7,7-8.2,10.8c-0.3,1-0.7,1.9-0.9,2.9c-0.2,1-0.3,1.9-0.3,2.8c0,1.8,0.4,3.6,1.4,5
							c1,1.4,2.6,2.3,4.6,2.6c1,0.1,2.1,0.1,3.2-0.1c1.1-0.2,2.4-0.5,3.5-1.1c4.8-2.1,9.8-7.2,11.7-13.9c1.9-6.8,2-11.6,2-16.2
							c-0.1-4.6-0.3-9.1-0.8-15.3c-1.6,6-3.8,9.7-6.4,12.9C245.6,270.5,242.6,273.3,238.4,276.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M221.4,254.8c-5.9,0.7-10.8,2.7-14.6,5.4c-3.7,2.7-6.2,6.2-5.7,9.7c0.5,3.5,4.2,6.1,9.6,6.6
							c5.3,0.5,12.3-1.2,17.7-5.6c5.6-4.6,8.8-8.3,11.8-12c3-3.7,5.9-7.3,10.2-12.6c-5.6,3.6-10,5.2-14.3,6.3
							C231.8,253.5,227.4,254.1,221.4,254.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M214.7,232.3c-5.8-1.9-11.2-2.4-15.9-1.9c-1.2,0.2-2.3,0.3-3.3,0.7c-1.1,0.3-2,0.7-2.9,1.1c-1.7,0.9-3.1,2.2-3.7,3.8
							c-0.6,1.6-0.4,3.5,0.5,5.3c0.5,0.9,1.2,1.9,2,2.7c0.9,0.9,1.9,1.8,3.1,2.5c4.8,3.1,12.4,4.8,19.7,3.2c7.5-1.7,12.3-4,16.9-6.2
							c4.5-2.3,9-4.7,15-8.3c-6.9,1.6-11.7,1.4-16.4,0.8C225.1,235.5,220.6,234.3,214.7,232.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M221.6,214.1c-3.8-4.1-7-7.1-10.2-9.4c-1.6-1.2-3.2-2.2-4.8-3.1c-1.6-0.9-3.3-1.6-5.1-1.4c-1.7,0.1-3.3,1.2-4.4,3.3
							c-1.1,2.1-1.4,5.3-0.6,8.8c0.8,3.5,2.7,7.3,5.7,10.4c2.9,3.1,6.8,5.6,11,6.8c4.4,1.3,7.9,1.6,11.1,1.7
							c3.2,0.1,5.9-0.1,8.6-0.4c5.4-0.6,10.5-1.4,17.9-2.1c-7.3-1.5-12.2-3.2-16.6-5.4c-2.2-1.1-4.3-2.4-6.4-3.9
							C225.6,217.9,223.5,216.2,221.6,214.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M239.5,196.3c-0.1-5.5-0.9-9.8-2.3-13.6c-0.7-1.9-1.5-3.7-2.5-5.3c-1-1.6-2.2-2.9-3.9-3.6c-1.6-0.7-3.5-0.5-5.4,0.6
							c-1.9,1.1-3.7,3.3-4.9,6.3c-1.2,3-1.8,6.7-1.4,10.5c0.4,3.8,1.7,7.8,3.9,11.1c2.3,3.5,4.6,5.9,6.7,7.9c2.2,2,4.2,3.6,6.3,5.1
							c4.1,3,8.3,5.7,14,9.6c-4.3-5.5-6.5-9.9-8-14.2c-0.7-2.2-1.3-4.4-1.8-6.8C239.9,201.7,239.6,199.1,239.5,196.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M259.2,191.2c2.3-5.4,3.1-10.6,2.9-15.2c-0.1-1.2-0.2-2.2-0.4-3.3c-0.2-1.1-0.5-2-0.9-2.9c-0.8-1.8-1.9-3.2-3.5-4
							c-1.6-0.8-3.4-0.8-5.3,0c-1,0.4-2,1-2.9,1.8c-0.9,0.8-1.9,1.7-2.8,2.9c-3.5,4.5-5.8,11.9-4.6,19.3c1.3,7.6,3.3,12.4,5.3,17
							c2.1,4.6,4.3,9,7.7,15c-1.4-6.8-1-11.6-0.1-16C255.5,201.1,256.9,196.9,259.2,191.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M281.1,191.4c4.3-4.1,7.2-8,9.3-11.7c1-1.9,1.9-3.7,2.4-5.6c0.5-1.8,0.7-3.7,0-5.3c-0.6-1.6-2.1-2.9-4.3-3.4
							c-2.2-0.6-5.1-0.3-8.2,0.8c-3.1,1.2-6.3,3.3-8.9,6.2c-2.6,2.9-4.7,6.5-5.8,10.5c-1.2,4-1.6,7.4-1.8,10.4
							c-0.2,3-0.2,5.7-0.2,8.3c0.1,5.2,0.3,10.3,0,17.6c2.4-6.9,4.7-11.5,7.3-15.6c1.3-2.1,2.8-4,4.4-6
							C277.1,195.6,278.9,193.5,281.1,191.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M256.7,233.3c-1.5,3.2,1.5,6,5,5.6c3.5-0.4,5.8-3.7,3.7-6.5c-2.2-2.9-3.7-3.9-5.1-7
							C259.6,228.7,258.2,230,256.7,233.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M261.4,230.6c-1.4,1-1.8,2.3-1.5,3.7c0.3,1.4,1.3,2.8,2.8,3.6c1.5,0.9,3.3,0.9,4.7,0.2c1.4-0.7,2.3-2.2,2.1-4.1
							c-0.2-2-0.6-3.3-0.9-4.7c-0.3-1.3-0.6-2.6-0.7-4.4c-0.9,1.5-1.9,2.4-2.9,3.1C263.9,228.9,262.8,229.6,261.4,230.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M264.9,232.2c-1.3-0.6-2.1-0.2-2.9,0.7c-0.7,0.9-1.4,2.4-1.5,4.2c-0.1,1.7,0.7,3.3,2.1,4.1c1.4,0.8,3.3,0.7,4.8-0.7
							c1.5-1.4,2.2-2.7,2.8-3.8c0.6-1.2,1.2-2.3,2.1-3.6c-1.5,0.5-2.7,0.5-3.8,0.3C267.3,233.2,266.2,232.7,264.9,232.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M267.4,236.8c0-0.7-0.3-1.3-0.7-1.8c-0.5-0.5-1.1-1-1.9-1.2c-0.8-0.3-1.6-0.3-2.4-0.1c-0.8,0.2-1.5,0.6-2.1,1.3
							c-0.6,0.6-1.1,1.3-1.4,2c-0.3,0.7-0.6,1.4-0.6,2c0,0.7,0.1,1.3,0.5,2c0.4,0.6,1.1,1.3,2.1,1.7c1,0.5,2.1,0.8,3,1
							c0.9,0.2,1.7,0.2,2.5,0.1c1.6-0.1,3.1-0.5,4.5-1.6c-0.8,0.1-1.5-0.2-2-0.6c-0.5-0.4-0.8-0.8-1-1.3c-0.2-0.5-0.3-1-0.4-1.6
							C267.4,238,267.5,237.5,267.4,236.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M266.9,240c0.8-1.4,0.7-2.5-0.1-3.6c-0.7-1.1-2-2.2-3.8-2.6c-1.7-0.4-3.4,0.1-4.5,1.4c-1.1,1.3-1.4,3.5-0.4,5.4
							c1.1,2,2.1,3.1,3.2,4.1c1,1,2,2,3.2,3.4c-0.2-1.8,0-3.1,0.5-4.3C265.4,242.6,266.1,241.5,266.9,240z"
                      />
                    </g>
                    <g>
                      <path
                        d="M264.2,244.1c1.7-1.3,2.4-3.2,2.2-4.9c-0.2-1.7-1.4-3.1-3.1-3.7c-1.7-0.6-3.4-0.3-4.7,0.5c-1.3,0.8-2.1,2-2.1,3.8
							c0,1.9,0,3.3,0,4.7c-0.1,1.4-0.4,2.8-1.2,4.5c1.6-1,2.9-1.7,4.3-2.3C260.9,246.1,262.4,245.4,264.2,244.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M258.2,243.2c3.6,1.4,6.6-1.7,6.2-5.2c-0.4-3.5-4.2-5.7-7.3-3.5c-3.2,2.3-4.3,3.9-7.7,5.4
							C253,240.5,254.5,241.8,258.2,243.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M254.5,239.4c1.8,3.2,5.8,2.6,7.7-0.4c1.8-3,0.5-6.9-3.1-7c-3.7-0.1-5.5,0.5-8.9-0.3
							C252.5,234.3,252.7,236.1,254.5,239.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M255,235.1c0,1.7,1.1,2.7,2.4,3c1.3,0.3,3-0.2,4.3-1.3c1.4-1.1,2.1-2.6,2.3-3.8c0.1-1.2-0.4-2.3-1.8-2.8
							c-2.9-1-4.7-1.1-6.9-3C255.8,230.1,255,231.7,255,235.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M299.7,201.9c5.7-1.8,10.2-4.8,13.4-8.2c3.2-3.4,5-7.2,3.9-10.6c-1.1-3.3-5.3-5.2-10.8-4.6c-5.4,0.5-12,3.6-16.6,9.1
							c-4.7,5.7-7.1,10.1-9.4,14.4c-2.2,4.4-4.4,8.6-7.4,14.7c4.8-4.8,8.8-7.3,12.8-9.3C289.6,205.4,293.8,203.9,299.7,201.9z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M624.2,225.2c3.8,4.2,8.2,7.3,12.1,9.2c3.9,1.8,7.4,2.3,9.1,0.6c1.7-1.7,1.1-5.3-1.4-9.3c-2.6-3.9-7.1-8.1-12.8-10.5
							c-5.8-2.5-10-3.6-14.1-4.8c-4.1-1.1-8.3-2.3-14.2-4.1c5.3,3.1,8.6,5.8,11.6,8.7C617.5,217.9,620.3,220.9,624.2,225.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M613.5,234.5c1.7,4.7,4.2,8.8,6.9,11.6c2.7,2.8,5.6,4.4,7.9,3.4c2.2-0.9,3.3-4.2,2.6-8.5c-0.7-4.2-3.2-9.4-7.3-13.3
							c-4.2-4-7.5-6.2-10.7-8.5c-3.3-2.2-6.5-4.4-11.1-7.6c3.6,4.3,5.5,7.6,7.1,11C610.4,226.1,611.7,229.6,613.5,234.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M598.5,235.7c-0.3,3.7,0.5,7,2,9.6c1.5,2.5,3.7,4.3,6.1,4.2c2.4-0.1,4.5-2.2,5.4-5.4c0.9-3.2,0.5-7.6-1.7-11.3
							c-2.3-3.8-4.3-6.2-6.3-8.5c-2-2.3-3.9-4.5-6.5-7.8c1.3,4,1.6,6.8,1.6,9.7C599.1,229,598.9,231.8,598.5,235.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M580.4,230.2c-2.1,2.7-2.8,5.7-2.5,8.4c0.3,2.6,1.7,4.9,4,5.8c2.3,0.9,4.9,0.1,6.9-1.9c2-2,3.4-5.2,3.2-8.8
							c-0.2-3.7-0.7-6.3-1.1-8.9c-0.4-2.5-0.7-5.1-0.8-8.6c-1.4,3.3-2.8,5.4-4.3,7.4C584.2,225.6,582.5,227.5,580.4,230.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M567.7,221.5c-3.4,1.1-6,3-7.7,5.3c-1.7,2.3-2.5,4.9-1.5,7.2c0.9,2.2,3.6,3.5,7,3.1c3.3-0.4,7.2-2.4,9.7-5.9
							c2.6-3.6,3.8-6.4,5-9.1c1.2-2.7,2.3-5.4,4-9.1c-2.9,2.8-5.4,4.3-7.8,5.4C573.9,219.5,571.3,220.4,567.7,221.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M562.8,205.2c-3.5-1.1-6.6-1.1-9.4-0.5c-0.7,0.2-1.4,0.3-2,0.6c-0.6,0.3-1.2,0.6-1.7,0.9c-1.1,0.7-1.9,1.6-2.3,2.8
							c-0.4,1.1-0.3,2.4,0.3,3.6c0.3,0.6,0.7,1.2,1.3,1.8c0.6,0.6,1.2,1.1,2,1.6c3.1,1.9,8,2.8,12.5,1.3c4.6-1.6,7.4-3.3,10.1-5.1
							c2.6-1.8,5.1-3.7,8.4-6.4c-4.1,1.4-7.1,1.6-9.9,1.4C569.2,206.9,566.4,206.2,562.8,205.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M568.7,186.9c-2.6-3.1-5.7-5.2-8.7-6.3c-3-1.1-5.9-1.1-7.7,0.5c-1.8,1.6-1.9,4.8-0.1,8.1c1.8,3.3,5.4,6.6,10.1,8
							c4.8,1.4,8.2,1.8,11.5,2.1c3.3,0.3,6.5,0.5,11.2,0.9c-4.3-1.8-6.8-3.7-9.1-5.8C573.6,192.4,571.5,190.1,568.7,186.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M579.1,174.3c-1.1-4.1-3-7.4-5.1-9.9c-0.6-0.6-1.1-1.2-1.7-1.7c-0.6-0.5-1.2-0.9-1.8-1.2c-1.2-0.6-2.4-0.9-3.6-0.7
							c-1.2,0.3-2.2,1.1-2.8,2.4c-0.3,0.6-0.6,1.4-0.8,2.2c-0.2,0.8-0.3,1.8-0.2,2.8c0.1,4,2,9,5.8,12.6c3.9,3.7,7,5.5,10.1,7.2
							c3.1,1.7,6.2,3.3,10.6,5.4c-3.6-3.3-5.4-6.2-6.8-9.1C581.3,181.5,580.3,178.5,579.1,174.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M592.2,171.1c0.9-3.8,1.3-6.8,1.4-9.5c0.1-1.3,0-2.7-0.1-4c-0.1-1.3-0.4-2.5-1.2-3.5c-0.8-0.9-2-1.4-3.6-1.2
							c-1.6,0.2-3.6,1.2-5.3,3c-1.7,1.8-3.1,4.4-3.7,7.3c-0.6,2.9-0.6,6,0.3,8.9c0.9,3,2.1,5.2,3.3,7c1.2,1.8,2.4,3.3,3.6,4.8
							c2.4,2.9,4.9,5.5,8.1,9.5c-2-4.8-2.9-8.3-3.3-11.7c-0.2-1.7-0.3-3.4-0.3-5.2C591.6,175,591.7,173.1,592.2,171.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M609.4,174.5c3.1-2.2,5.3-4.3,6.9-6.6c0.8-1.1,1.5-2.3,2.1-3.5c0.5-1.2,0.8-2.4,0.6-3.6c-0.2-1.2-1.1-2.2-2.5-2.8
							c-1.4-0.6-3.3-0.8-5.5-0.4c-2.2,0.4-4.5,1.5-6.6,3.3c-2,1.7-3.8,4-4.8,6.5c-1.1,2.7-1.6,4.9-1.9,6.9c-0.3,2-0.4,3.8-0.5,5.6
							c-0.1,3.5,0,6.9-0.1,11.8c1.5-4.6,3.1-7.6,5.1-10.1c1-1.3,2-2.5,3.2-3.6C606.4,176.8,607.8,175.6,609.4,174.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M619.9,183.9c4-0.8,7.3-2.3,9.9-4.3c0.6-0.5,1.2-1,1.7-1.5c0.5-0.5,1-1.1,1.3-1.6c0.7-1.1,1.1-2.3,1-3.6
							c-0.2-1.2-0.9-2.3-2.1-3.1c-0.6-0.4-1.3-0.8-2.1-1c-0.8-0.2-1.7-0.4-2.7-0.5c-3.9-0.3-9.1,1.3-12.8,4.9
							c-3.8,3.7-5.8,6.7-7.7,9.6c-1.8,3-3.5,6-5.6,10.2c3.4-3.4,6.2-5.1,9.1-6.3C612.8,185.6,615.8,184.7,619.9,183.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M628.3,196.5c4,0.9,7.4,1,10.3,0.8c1.5-0.1,2.9-0.4,4.1-0.8c1.2-0.4,2.4-1,3.1-2c0.7-1,0.8-2.3,0.3-3.8
							c-0.5-1.5-1.8-3-3.7-4.4c-1.9-1.3-4.3-2.3-7-2.7c-2.7-0.4-5.6-0.2-8.3,0.7c-2.8,0.9-4.9,2-6.7,3c-1.8,1-3.3,2.1-4.8,3.1
							c-3,2.1-5.8,4.2-10.1,6.8c4.9-1.3,8.4-1.8,11.8-1.8c1.7,0,3.4,0,5.2,0.2C624.3,195.8,626.3,196,628.3,196.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M594.8,198.8c-2.4,0.4-2.8,3.2-1.3,5c1.6,1.9,4.4,1.9,5.2-0.4c0.8-2.4,0.8-3.7,2-5.7
							C598.6,198.6,597.3,198.3,594.8,198.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M598.2,200.4c-1.1-0.4-2-0.1-2.7,0.6c-0.7,0.7-1.1,1.8-1,3c0.1,1.2,0.8,2.2,1.7,2.7c0.9,0.5,2.2,0.5,3.2-0.4
							c1-0.9,1.7-1.6,2.3-2.3c0.6-0.7,1.3-1.4,2.2-2.1c-1.2,0-2.1-0.2-2.9-0.4C600.2,201.2,599.3,200.8,598.2,200.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M598.6,203c-0.2-0.9-0.7-1.3-1.5-1.4c-0.8-0.1-1.9,0.1-3,0.8c-1,0.6-1.7,1.7-1.6,2.8c0.1,1.1,0.9,2.2,2.2,2.5
							c1.4,0.3,2.4,0.2,3.3,0.1c0.9-0.1,1.8-0.2,2.9-0.2c-0.9-0.7-1.3-1.4-1.6-2.1C599,204.8,598.8,204,598.6,203z"
                      />
                    </g>
                    <g>
                      <path
                        d="M597,206.3c0.4-0.3,0.6-0.7,0.8-1.1c0.1-0.5,0.1-1,0-1.6c-0.1-0.5-0.4-1.1-0.9-1.4c-0.4-0.4-1-0.6-1.6-0.7
							c-0.6-0.1-1.2-0.1-1.7,0c-0.5,0.1-1,0.2-1.4,0.4c-0.4,0.2-0.7,0.6-0.9,1c-0.2,0.5-0.3,1.1-0.2,1.9c0.1,0.8,0.3,1.5,0.6,2.1
							c0.3,0.6,0.6,1.1,0.9,1.5c0.7,0.8,1.5,1.6,2.7,2c-0.4-0.5-0.5-1-0.4-1.4c0-0.4,0.2-0.8,0.4-1.1c0.2-0.3,0.5-0.6,0.7-0.9
							C596.3,206.7,596.6,206.6,597,206.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M594.9,207.2c1.1-0.1,1.7-0.6,2.1-1.5c0.3-0.8,0.5-2,0-3.2c-0.4-1.1-1.4-1.9-2.6-2c-1.2-0.1-2.5,0.5-3.2,1.9
							c-0.7,1.4-0.9,2.4-1.1,3.4c-0.2,1-0.4,1.9-0.7,3.2c0.9-0.8,1.8-1.2,2.7-1.4C592.9,207.4,593.8,207.3,594.9,207.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M591.5,207.3c1.4,0.5,2.8,0.1,3.7-0.7c0.9-0.8,1.2-2,0.9-3.2c-0.3-1.2-1.2-2.1-2.1-2.5c-0.9-0.4-2-0.4-3,0.3
							c-1.1,0.7-1.9,1.3-2.7,1.8c-0.8,0.5-1.7,0.9-3.1,1.1c1.2,0.5,2.1,1,3,1.6C589.1,206.1,590.1,206.7,591.5,207.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M589.7,203.4c0.6,2.6,3.6,3.1,5.4,1.5c1.8-1.6,1.7-4.6-0.8-5.5c-2.6-1-3.9-1-6.1-2.3
							C589.3,199.5,589.1,200.8,589.7,203.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M590.5,199.9c-1.1,2.2,0.8,4.3,3.2,4.2c2.4-0.1,4.2-2.4,2.8-4.5c-1.4-2.2-2.4-3-3.3-5.2
							C592.6,196.7,591.6,197.6,590.5,199.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M593.1,198.5c-0.9,0.7-1.1,1.7-0.8,2.5c0.4,0.9,1.3,1.6,2.4,2c1.2,0.3,2.3,0.2,3.1-0.2c0.8-0.4,1.2-1.1,0.9-2.1
							c-0.5-2-1.2-3.1-0.9-5.1C596.3,197,595.1,197.2,593.1,198.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M629.5,211.2c3.3,2.5,6.7,4,9.9,4.5c3.2,0.5,6.1,0.1,7.6-1.9c1.5-1.9,0.9-5.1-1.5-8c-2.4-2.9-6.8-5.5-11.7-6
							c-5.1-0.5-8.5-0.1-11.9,0.2c-3.4,0.4-6.7,0.8-11.3,1.5c4.6,0.9,7.6,2.2,10.3,3.7C623.6,206.8,626.1,208.6,629.5,211.2z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M241.1,523.3c3.8,4.2,8.2,7.3,12.1,9.2c3.9,1.8,7.4,2.3,9.1,0.6c1.7-1.7,1.1-5.3-1.4-9.3c-2.6-3.9-7.1-8.1-12.8-10.5
							c-5.8-2.5-10-3.6-14.1-4.8c-4.1-1.1-8.3-2.3-14.2-4.1c5.3,3.1,8.6,5.8,11.6,8.7C234.3,516,237.1,519,241.1,523.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M230.3,532.5c1.7,4.7,4.2,8.8,6.9,11.6c2.7,2.8,5.6,4.4,7.9,3.4c2.2-0.9,3.3-4.2,2.6-8.5c-0.7-4.2-3.2-9.4-7.3-13.3
							c-4.2-4-7.5-6.2-10.7-8.5c-3.3-2.2-6.5-4.4-11.1-7.6c3.6,4.3,5.5,7.6,7.1,11C227.3,524.1,228.6,527.6,230.3,532.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M215.4,533.7c-0.3,3.7,0.5,7,2,9.6c1.5,2.5,3.7,4.3,6.1,4.2c2.4-0.1,4.5-2.2,5.4-5.4c0.9-3.2,0.5-7.6-1.7-11.3
							c-2.3-3.8-4.3-6.2-6.3-8.5c-2-2.3-3.9-4.5-6.5-7.8c1.3,4,1.6,6.8,1.6,9.7C216,527.1,215.7,529.9,215.4,533.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M197.2,528.3c-2.1,2.7-2.8,5.7-2.5,8.4c0.3,2.6,1.7,4.9,4,5.8c2.3,0.9,4.9,0.1,6.9-1.9c2-2,3.4-5.2,3.2-8.8
							c-0.2-3.7-0.7-6.3-1.1-8.9c-0.4-2.5-0.7-5.1-0.8-8.6c-1.4,3.3-2.8,5.4-4.3,7.4C201.1,523.7,199.4,525.5,197.2,528.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M184.6,519.6c-3.4,1.1-6,3-7.7,5.3c-1.7,2.3-2.5,4.9-1.5,7.2c0.9,2.2,3.6,3.5,7,3.1c3.3-0.4,7.2-2.4,9.7-5.9
							c2.6-3.6,3.8-6.4,5-9.1c1.2-2.7,2.3-5.4,4-9.1c-2.9,2.8-5.4,4.3-7.8,5.4C190.7,517.6,188.1,518.4,184.6,519.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M179.7,503.2c-3.5-1.1-6.6-1.1-9.4-0.5c-0.7,0.2-1.4,0.3-2,0.6c-0.6,0.3-1.2,0.6-1.7,0.9c-1.1,0.7-1.9,1.6-2.3,2.8
							c-0.4,1.1-0.3,2.4,0.3,3.6c0.3,0.6,0.7,1.2,1.3,1.8c0.6,0.6,1.2,1.1,2,1.6c3.1,1.9,8,2.8,12.5,1.3c4.6-1.6,7.4-3.3,10.1-5.1
							c2.6-1.8,5.1-3.7,8.4-6.4c-4.1,1.4-7.1,1.6-9.9,1.4C186,504.9,183.3,504.3,179.7,503.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M185.6,484.9c-2.6-3.1-5.7-5.2-8.7-6.3c-3-1.1-5.9-1.1-7.7,0.5c-1.8,1.6-1.9,4.8-0.1,8.1c1.8,3.3,5.4,6.6,10.1,8
							c4.8,1.4,8.2,1.8,11.5,2.1c3.3,0.3,6.5,0.5,11.2,0.9c-4.3-1.8-6.8-3.7-9.1-5.8C190.4,490.4,188.4,488.1,185.6,484.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M195.9,472.3c-1.1-4.1-3-7.4-5.1-9.9c-0.6-0.6-1.1-1.2-1.7-1.7c-0.6-0.5-1.2-0.9-1.8-1.2c-1.2-0.6-2.4-0.9-3.6-0.7
							c-1.2,0.3-2.2,1.1-2.8,2.4c-0.3,0.6-0.6,1.4-0.8,2.2c-0.2,0.8-0.3,1.8-0.2,2.8c0.1,4,2,9,5.8,12.6c3.9,3.7,7,5.5,10.1,7.2
							c3.1,1.7,6.2,3.3,10.6,5.4c-3.6-3.3-5.4-6.2-6.8-9.1C198.2,479.5,197.1,476.5,195.9,472.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M209.1,469.2c0.9-3.8,1.3-6.8,1.4-9.5c0.1-1.3,0-2.7-0.1-4c-0.1-1.3-0.4-2.5-1.2-3.5c-0.8-0.9-2-1.4-3.6-1.2
							c-1.6,0.2-3.6,1.2-5.3,3c-1.7,1.8-3.1,4.4-3.7,7.3c-0.6,2.9-0.6,6,0.3,8.9c0.9,3,2.1,5.2,3.3,7c1.2,1.8,2.4,3.3,3.6,4.8
							c2.4,2.9,4.9,5.5,8.1,9.5c-2-4.8-2.9-8.3-3.3-11.7c-0.2-1.7-0.3-3.4-0.3-5.2C208.4,473,208.6,471.1,209.1,469.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M226.2,472.6c3.1-2.2,5.3-4.3,6.9-6.6c0.8-1.1,1.5-2.3,2.1-3.5c0.5-1.2,0.8-2.4,0.6-3.6c-0.2-1.2-1.1-2.2-2.5-2.8
							c-1.4-0.6-3.3-0.8-5.5-0.4c-2.2,0.4-4.5,1.5-6.6,3.3c-2,1.7-3.8,4-4.8,6.5c-1.1,2.7-1.6,4.9-1.9,6.9c-0.3,2-0.4,3.8-0.5,5.6
							c-0.1,3.5,0,6.9-0.1,11.8c1.5-4.6,3.1-7.6,5.1-10.1c1-1.3,2-2.5,3.2-3.6C223.3,474.9,224.6,473.7,226.2,472.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M236.8,481.9c4-0.8,7.3-2.3,9.9-4.3c0.6-0.5,1.2-1,1.7-1.5c0.5-0.5,1-1.1,1.3-1.6c0.7-1.1,1.1-2.3,1-3.6
							c-0.2-1.2-0.9-2.3-2.1-3.1c-0.6-0.4-1.3-0.8-2.1-1c-0.8-0.2-1.7-0.4-2.7-0.5c-3.9-0.3-9.1,1.3-12.8,4.9
							c-3.8,3.7-5.8,6.7-7.7,9.6c-1.8,3-3.5,6-5.6,10.2c3.4-3.4,6.2-5.1,9.1-6.3C229.7,483.6,232.7,482.8,236.8,481.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M245.2,494.6c4,0.9,7.4,1,10.3,0.8c1.5-0.1,2.9-0.4,4.1-0.8c1.2-0.4,2.4-1,3.1-2c0.7-1,0.8-2.3,0.3-3.8
							c-0.5-1.5-1.8-3-3.7-4.4c-1.9-1.3-4.3-2.3-7-2.7c-2.7-0.4-5.6-0.2-8.3,0.7c-2.8,0.9-4.9,2-6.7,3c-1.8,1-3.3,2.1-4.8,3.1
							c-3,2.1-5.8,4.2-10.1,6.8c4.9-1.3,8.4-1.8,11.8-1.8c1.7,0,3.4,0,5.2,0.2C241.2,493.9,243.1,494.1,245.2,494.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M211.7,496.8c-2.4,0.4-2.8,3.2-1.3,5c1.6,1.9,4.4,1.9,5.2-0.4c0.8-2.4,0.8-3.7,2-5.7
							C215.4,496.7,214.2,496.4,211.7,496.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M215,498.4c-1.1-0.4-2-0.1-2.7,0.6c-0.7,0.7-1.1,1.8-1,3c0.1,1.2,0.8,2.2,1.7,2.7c0.9,0.5,2.2,0.5,3.2-0.4
							c1-0.9,1.7-1.6,2.3-2.3c0.6-0.7,1.3-1.4,2.2-2.1c-1.2,0-2.1-0.2-2.9-0.4C217,499.2,216.2,498.9,215,498.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M215.5,501.1c-0.2-0.9-0.7-1.3-1.5-1.4c-0.8-0.1-1.9,0.1-3,0.8c-1,0.6-1.7,1.7-1.6,2.8c0.1,1.1,0.9,2.2,2.2,2.5
							c1.4,0.3,2.4,0.2,3.3,0.1c0.9-0.1,1.8-0.2,2.9-0.2c-0.9-0.7-1.3-1.4-1.6-2.1C215.9,502.9,215.7,502.1,215.5,501.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M213.9,504.3c0.4-0.3,0.6-0.7,0.8-1.1c0.1-0.5,0.1-1,0-1.6c-0.1-0.5-0.4-1.1-0.9-1.4c-0.4-0.4-1-0.6-1.6-0.7
							c-0.6-0.1-1.2-0.1-1.7,0c-0.5,0.1-1,0.2-1.4,0.4c-0.4,0.2-0.7,0.6-0.9,1c-0.2,0.5-0.3,1.1-0.2,1.9c0.1,0.8,0.3,1.5,0.6,2.1
							c0.3,0.6,0.6,1.1,0.9,1.5c0.7,0.8,1.5,1.6,2.7,2c-0.4-0.5-0.5-1-0.4-1.4c0-0.4,0.2-0.8,0.4-1.1c0.2-0.3,0.5-0.6,0.7-0.9
							C213.1,504.8,213.5,504.6,213.9,504.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M211.8,505.3c1.1-0.1,1.7-0.6,2.1-1.5c0.3-0.8,0.5-2,0-3.2c-0.4-1.1-1.4-1.9-2.6-2c-1.2-0.1-2.5,0.5-3.2,1.9
							c-0.7,1.4-0.9,2.4-1.1,3.4c-0.2,1-0.4,1.9-0.7,3.2c0.9-0.8,1.8-1.2,2.7-1.4C209.7,505.4,210.6,505.4,211.8,505.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M208.4,505.3c1.4,0.5,2.8,0.1,3.7-0.7c0.9-0.8,1.2-2,0.9-3.2c-0.3-1.2-1.2-2.1-2.1-2.5c-0.9-0.4-2-0.4-3,0.3
							c-1.1,0.7-1.9,1.3-2.7,1.8c-0.8,0.5-1.7,0.9-3.1,1.1c1.2,0.5,2.1,1,3,1.6C206,504.2,206.9,504.8,208.4,505.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M206.6,501.5c0.6,2.6,3.6,3.1,5.4,1.5c1.8-1.6,1.7-4.6-0.8-5.5c-2.6-1-3.9-1-6.1-2.3
							C206.1,497.5,205.9,498.8,206.6,501.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M207.3,497.9c-1.1,2.2,0.8,4.3,3.2,4.2c2.4-0.1,4.2-2.4,2.8-4.5c-1.4-2.2-2.4-3-3.3-5.2
							C209.5,494.8,208.5,495.6,207.3,497.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M210,496.6c-0.9,0.7-1.1,1.7-0.8,2.5c0.4,0.9,1.3,1.6,2.4,2c1.2,0.3,2.3,0.2,3.1-0.2c0.8-0.4,1.2-1.1,0.9-2.1
							c-0.5-2-1.2-3.1-0.9-5.1C213.2,495,211.9,495.2,210,496.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M246.4,509.3c3.3,2.5,6.7,4,9.9,4.5c3.2,0.5,6.1,0.1,7.6-1.9c1.5-1.9,0.9-5.1-1.5-8c-2.4-2.9-6.8-5.5-11.7-6
							c-5.1-0.5-8.5-0.1-11.9,0.2c-3.4,0.4-6.7,0.8-11.3,1.5c4.6,0.9,7.6,2.2,10.3,3.7C240.4,504.9,243,506.7,246.4,509.3z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M155.7,208.9c3.8,4.2,8.2,7.3,12.1,9.2c3.9,1.8,7.4,2.3,9.1,0.6c1.7-1.7,1.1-5.3-1.4-9.3c-2.6-3.9-7.1-8.1-12.8-10.5
							c-5.8-2.5-10-3.6-14.1-4.8c-4.1-1.1-8.3-2.3-14.2-4.1c5.3,3.1,8.6,5.8,11.6,8.7C148.9,201.6,151.7,204.6,155.7,208.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M144.9,218.2c1.7,4.7,4.2,8.8,6.9,11.6c2.7,2.8,5.6,4.4,7.9,3.4c2.2-0.9,3.3-4.2,2.6-8.5c-0.7-4.2-3.2-9.4-7.3-13.3
							c-4.2-4-7.5-6.2-10.7-8.5c-3.3-2.2-6.5-4.4-11.1-7.6c3.6,4.3,5.5,7.6,7.1,11C141.9,209.8,143.2,213.3,144.9,218.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M130,219.4c-0.3,3.7,0.5,7,2,9.6c1.5,2.5,3.7,4.3,6.1,4.2c2.4-0.1,4.5-2.2,5.4-5.4c0.9-3.2,0.5-7.6-1.7-11.3
							c-2.3-3.8-4.3-6.2-6.3-8.5c-2-2.3-3.9-4.5-6.5-7.8c1.3,4,1.6,6.8,1.6,9.7C130.6,212.7,130.3,215.5,130,219.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M111.8,214c-2.1,2.7-2.8,5.7-2.5,8.4c0.3,2.6,1.7,4.9,4,5.8c2.3,0.9,4.9,0.1,6.9-1.9c2-2,3.4-5.2,3.2-8.8
							c-0.2-3.7-0.7-6.3-1.1-8.9c-0.4-2.5-0.7-5.1-0.8-8.6c-1.4,3.3-2.8,5.4-4.3,7.4C115.7,209.3,114,211.2,111.8,214z"
                      />
                    </g>
                    <g>
                      <path
                        d="M99.2,205.2c-3.4,1.1-6,3-7.7,5.3c-1.7,2.3-2.5,4.9-1.5,7.2c0.9,2.2,3.6,3.5,7,3.1c3.3-0.4,7.2-2.4,9.7-5.9
							c2.6-3.6,3.8-6.4,5-9.1c1.2-2.7,2.3-5.4,4-9.1c-2.9,2.8-5.4,4.3-7.8,5.4C105.3,203.2,102.7,204.1,99.2,205.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M94.3,188.9c-3.5-1.1-6.6-1.1-9.4-0.5c-0.7,0.2-1.4,0.3-2,0.6c-0.6,0.3-1.2,0.6-1.7,0.9c-1.1,0.7-1.9,1.6-2.3,2.8
							c-0.4,1.1-0.3,2.4,0.3,3.6c0.3,0.6,0.7,1.2,1.3,1.8c0.6,0.6,1.2,1.1,2,1.6c3.1,1.9,8,2.8,12.5,1.3c4.6-1.6,7.4-3.3,10.1-5.1
							c2.6-1.8,5.1-3.7,8.4-6.4c-4.1,1.4-7.1,1.6-9.9,1.4C100.6,190.6,97.9,190,94.3,188.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M100.2,170.6c-2.6-3.1-5.7-5.2-8.7-6.3c-3-1.1-5.9-1.1-7.7,0.5c-1.8,1.6-1.9,4.8-0.1,8.1c1.8,3.3,5.4,6.6,10.1,8
							c4.8,1.4,8.2,1.8,11.5,2.1c3.3,0.3,6.5,0.5,11.2,0.9c-4.3-1.8-6.8-3.7-9.1-5.8C105,176.1,103,173.8,100.2,170.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M110.5,158c-1.1-4.1-3-7.4-5.1-9.9c-0.6-0.6-1.1-1.2-1.7-1.7c-0.6-0.5-1.2-0.9-1.8-1.2c-1.2-0.6-2.4-0.9-3.6-0.7
							c-1.2,0.3-2.2,1.1-2.8,2.4c-0.3,0.6-0.6,1.4-0.8,2.2c-0.2,0.8-0.3,1.8-0.2,2.8c0.1,4,2,9,5.8,12.6c3.9,3.7,7,5.5,10.1,7.2
							c3.1,1.7,6.2,3.3,10.6,5.4c-3.6-3.3-5.4-6.2-6.8-9.1C112.8,165.2,111.7,162.2,110.5,158z"
                      />
                    </g>
                    <g>
                      <path
                        d="M123.7,154.9c0.9-3.8,1.3-6.8,1.4-9.5c0.1-1.3,0-2.7-0.1-4c-0.1-1.3-0.4-2.5-1.2-3.5c-0.8-0.9-2-1.4-3.6-1.2
							c-1.6,0.2-3.6,1.2-5.3,3c-1.7,1.8-3.1,4.4-3.7,7.3c-0.6,2.9-0.6,6,0.3,8.9c0.9,3,2.1,5.2,3.3,7c1.2,1.8,2.4,3.3,3.6,4.8
							c2.4,2.9,4.9,5.5,8.1,9.5c-2-4.8-2.9-8.3-3.3-11.7c-0.2-1.7-0.3-3.4-0.3-5.2C123,158.7,123.2,156.8,123.7,154.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M140.8,158.2c3.1-2.2,5.3-4.3,6.9-6.6c0.8-1.1,1.5-2.3,2.1-3.5c0.5-1.2,0.8-2.4,0.6-3.6c-0.2-1.2-1.1-2.2-2.5-2.8
							c-1.4-0.6-3.3-0.8-5.5-0.4c-2.2,0.4-4.5,1.5-6.6,3.3c-2,1.7-3.8,4-4.8,6.5c-1.1,2.7-1.6,4.9-1.9,6.9c-0.3,2-0.4,3.8-0.5,5.6
							c-0.1,3.5,0,6.9-0.1,11.8c1.5-4.6,3.1-7.6,5.1-10.1c1-1.3,2-2.5,3.2-3.6C137.9,160.6,139.2,159.4,140.8,158.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M151.4,167.6c4-0.8,7.3-2.3,9.9-4.3c0.6-0.5,1.2-1,1.7-1.5c0.5-0.5,1-1.1,1.3-1.6c0.7-1.1,1.1-2.3,1-3.6
							c-0.2-1.2-0.9-2.3-2.1-3.1c-0.6-0.4-1.3-0.8-2.1-1c-0.8-0.2-1.7-0.4-2.7-0.5c-3.9-0.3-9.1,1.3-12.8,4.9
							c-3.8,3.7-5.8,6.7-7.7,9.6c-1.8,3-3.5,6-5.6,10.2c3.4-3.4,6.2-5.1,9.1-6.3C144.2,169.3,147.3,168.5,151.4,167.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M159.8,180.2c4,0.9,7.4,1,10.3,0.8c1.5-0.1,2.9-0.4,4.1-0.8c1.2-0.4,2.4-1,3.1-2c0.7-1,0.8-2.3,0.3-3.8
							c-0.5-1.5-1.8-3-3.7-4.4c-1.9-1.3-4.3-2.3-7-2.7c-2.7-0.4-5.6-0.2-8.3,0.7c-2.8,0.9-4.9,2-6.7,3c-1.8,1-3.3,2.1-4.8,3.1
							c-3,2.1-5.8,4.2-10.1,6.8c4.9-1.3,8.4-1.8,11.8-1.8c1.7,0,3.4,0,5.2,0.2C155.8,179.5,157.7,179.8,159.8,180.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M126.3,182.5c-2.4,0.4-2.8,3.2-1.3,5c1.6,1.9,4.4,1.9,5.2-0.4c0.8-2.4,0.8-3.7,2-5.7
							C130,182.3,128.8,182.1,126.3,182.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M129.6,184.1c-1.1-0.4-2-0.1-2.7,0.6c-0.7,0.7-1.1,1.8-1,3c0.1,1.2,0.8,2.2,1.7,2.7c0.9,0.5,2.2,0.5,3.2-0.4
							c1-0.9,1.7-1.6,2.3-2.3c0.6-0.7,1.3-1.4,2.2-2.1c-1.2,0-2.1-0.2-2.9-0.4C131.6,184.9,130.8,184.5,129.6,184.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M130.1,186.7c-0.2-0.9-0.7-1.3-1.5-1.4c-0.8-0.1-1.9,0.1-3,0.8c-1,0.6-1.7,1.7-1.6,2.8c0.1,1.1,0.9,2.2,2.2,2.5
							c1.4,0.3,2.4,0.2,3.3,0.1c0.9-0.1,1.8-0.2,2.9-0.2c-0.9-0.7-1.3-1.4-1.6-2.1C130.5,188.5,130.3,187.7,130.1,186.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M128.5,190c0.4-0.3,0.6-0.7,0.8-1.1c0.1-0.5,0.1-1,0-1.6c-0.1-0.5-0.4-1.1-0.9-1.4c-0.4-0.4-1-0.6-1.6-0.7
							c-0.6-0.1-1.2-0.1-1.7,0c-0.5,0.1-1,0.2-1.4,0.4c-0.4,0.2-0.7,0.6-0.9,1c-0.2,0.5-0.3,1.1-0.2,1.9c0.1,0.8,0.3,1.5,0.6,2.1
							c0.3,0.6,0.6,1.1,0.9,1.5c0.7,0.8,1.5,1.6,2.7,2c-0.4-0.5-0.5-1-0.4-1.4c0-0.4,0.2-0.8,0.4-1.1c0.2-0.3,0.5-0.6,0.7-0.9
							C127.7,190.4,128.1,190.3,128.5,190z"
                      />
                    </g>
                    <g>
                      <path
                        d="M126.4,190.9c1.1-0.1,1.7-0.6,2.1-1.5c0.3-0.8,0.5-2,0-3.2c-0.4-1.1-1.4-1.9-2.6-2c-1.2-0.1-2.5,0.5-3.2,1.9
							c-0.7,1.4-0.9,2.4-1.1,3.4c-0.2,1-0.4,1.9-0.7,3.2c0.9-0.8,1.8-1.2,2.7-1.4C124.3,191.1,125.2,191,126.4,190.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M123,191c1.4,0.5,2.8,0.1,3.7-0.7c0.9-0.8,1.2-2,0.9-3.2c-0.3-1.2-1.2-2.1-2.1-2.5c-0.9-0.4-2-0.4-3,0.3
							c-1.1,0.7-1.9,1.3-2.7,1.8c-0.8,0.5-1.7,0.9-3.1,1.1c1.2,0.5,2.1,1,3,1.6C120.6,189.9,121.5,190.4,123,191z"
                      />
                    </g>
                    <g>
                      <path
                        d="M121.2,187.2c0.6,2.6,3.6,3.1,5.4,1.5c1.8-1.6,1.7-4.6-0.8-5.5c-2.6-1-3.9-1-6.1-2.3
							C120.7,183.2,120.5,184.5,121.2,187.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M121.9,183.6c-1.1,2.2,0.8,4.3,3.2,4.2c2.4-0.1,4.2-2.4,2.8-4.5c-1.4-2.2-2.4-3-3.3-5.2
							C124.1,180.4,123.1,181.3,121.9,183.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M124.6,182.2c-0.9,0.7-1.1,1.7-0.8,2.5c0.4,0.9,1.3,1.6,2.4,2c1.2,0.3,2.3,0.2,3.1-0.2c0.8-0.4,1.2-1.1,0.9-2.1
							c-0.5-2-1.2-3.1-0.9-5.1C127.8,180.7,126.5,180.9,124.6,182.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M161,194.9c3.3,2.5,6.7,4,9.9,4.5c3.2,0.5,6.1,0.1,7.6-1.9c1.5-1.9,0.9-5.1-1.5-8c-2.4-2.9-6.8-5.5-11.7-6
							c-5.1-0.5-8.5-0.1-11.9,0.2c-3.4,0.4-6.7,0.8-11.3,1.5c4.6,0.9,7.6,2.2,10.3,3.7C155,190.5,157.6,192.3,161,194.9z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M353.7,131.6c-6.1,1.4-12.7,4.3-17.8,8c-2.6,1.8-4.9,3.9-7,6c-2,2.1-3.7,4.3-5.1,6.4c-2.8,4.3-4.2,8.4-4.6,11.5
							c-0.2,1.6-0.1,2.9,0.2,3.9c0.3,1,0.9,1.8,1.6,2.2c0.8,0.4,1.7,0.6,2.7,0.4c1-0.2,2.2-0.6,3.5-1.2c2.5-1.2,5.5-3.2,8.8-5.5
							c1.6-1.1,3.4-2.3,5.2-3.6c1.8-1.2,3.8-2.5,5.8-3.8c4-2.7,8.2-5.1,13.1-8c5-3,9.8-5.5,14-8.2c4.2-2.6,8-5.4,11.3-8.5
							c1.7-1.5,3.3-3.1,4.8-4.8c1.6-1.7,3.1-3.5,4.6-5.5c3-3.9,6-8.5,8.6-14.3c-3.6,5.3-7.4,9-11.1,11.8c-1.9,1.4-3.7,2.6-5.5,3.7
							c-1.8,1.1-3.7,2-5.5,2.7c-3.7,1.6-7.5,2.6-11.9,3.5C365,129.4,360,130.1,353.7,131.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M362.6,150.6c-5,2.2-10,4.1-14.7,5.9c-4.7,1.7-9.2,3.3-13.1,4.7c-4,1.4-7.4,2.8-9.9,4.2c-2.5,1.4-4.1,2.9-4.3,4.7
							c-0.1,1.7,1.3,3.5,4.2,5c2.8,1.5,7.1,2.5,12.3,2.7c5.2,0.2,11.2-0.6,17.4-2.6c6.1-2.1,12.3-5.3,17.6-9.8
							c5.4-4.6,9.3-9,12.4-13.3c3.1-4.3,5.4-8.3,7.5-12.4c2-4.1,3.8-8.4,5.4-13.2c1.6-4.8,3-10.2,4-16.7c-2,6.2-4.5,11.1-7.2,15.2
							c-2.6,4.1-5.4,7.4-8.4,10.3c-3,3-6.2,5.6-10,8.1C372,145.9,367.8,148.3,362.6,150.6z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M401.3,173.5c-6-1.9-13.1-2.8-19.4-2.2c-3.2,0.3-6.2,0.8-9,1.6c-2.8,0.8-5.4,1.8-7.7,2.9c-4.6,2.3-7.9,5-9.8,7.5
							c-1,1.2-1.6,2.4-1.8,3.5c-0.3,1.1-0.2,2,0.3,2.7c0.4,0.8,1.2,1.3,2.2,1.7c1,0.4,2.2,0.6,3.6,0.8c2.8,0.2,6.3,0.1,10.3-0.2
							c2-0.1,4.1-0.3,6.4-0.4c2.2-0.1,4.5-0.3,6.9-0.4c4.8-0.2,9.7-0.2,15.4-0.2c5.9,0,11.2,0.3,16.2,0.1c5-0.1,9.7-0.6,14.1-1.5
							c2.2-0.4,4.4-1,6.6-1.7c2.2-0.7,4.4-1.4,6.7-2.4c4.6-1.9,9.5-4.3,14.7-7.9c-5.8,2.7-10.9,3.9-15.6,4.5
							c-2.3,0.3-4.5,0.4-6.7,0.3c-2.1,0-4.2-0.2-6.2-0.5c-4-0.5-7.8-1.6-12-3C412.2,177.4,407.6,175.5,401.3,173.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M399.3,194.3c-5.4-0.7-10.7-1.6-15.6-2.5c-4.9-0.9-9.6-1.9-13.7-2.7c-4.1-0.8-7.8-1.4-10.7-1.5
							c-2.9-0.1-5.1,0.4-6.1,1.9c-1,1.4-0.6,3.7,1.1,6.4c1.7,2.7,4.8,5.8,9.2,8.6c4.3,2.8,9.9,5.3,16.3,6.7
							c6.3,1.4,13.3,1.8,20.1,0.6c7-1.1,12.6-3,17.5-5c4.8-2.1,8.9-4.4,12.8-6.9c3.9-2.5,7.6-5.3,11.4-8.6c3.8-3.3,7.8-7.3,12-12.3
							c-4.9,4.3-9.6,7.2-13.9,9.4c-4.4,2.2-8.4,3.5-12.5,4.6c-4.1,1-8.2,1.6-12.7,1.9C409.8,195.2,404.9,195.1,399.3,194.3z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M143.2,295.9c4-4.8,7.5-11.1,9.3-17.2c0.9-3.1,1.6-6.1,1.9-9c0.3-2.9,0.3-5.7,0.2-8.2c-0.4-5.1-1.7-9.2-3.3-11.9
							c-0.8-1.4-1.7-2.4-2.6-3c-0.9-0.6-1.8-0.9-2.6-0.8c-0.9,0.1-1.7,0.6-2.4,1.3c-0.7,0.8-1.4,1.8-2,3.1c-1.3,2.5-2.4,5.9-3.7,9.7
							c-0.6,1.9-1.3,3.9-2,6.1c-0.7,2.1-1.4,4.3-2.2,6.6c-1.6,4.6-3.4,9-5.5,14.3c-2.2,5.4-4.4,10.3-6.2,15
							c-1.7,4.7-3.1,9.2-3.8,13.6c-0.4,2.2-0.7,4.5-0.9,6.8c-0.2,2.3-0.3,4.7-0.3,7.1c0,4.9,0.4,10.4,1.8,16.6
							c-0.4-6.3,0.4-11.6,1.6-16.1c0.6-2.3,1.3-4.4,2.2-6.3c0.8-2,1.7-3.8,2.7-5.5c2-3.5,4.4-6.6,7.3-10
							C135.6,304.6,139.1,300.9,143.2,295.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M124.7,286.2c2.7-4.8,5.5-9.3,8.1-13.6c2.7-4.3,5.3-8.2,7.6-11.7c2.3-3.5,4.2-6.7,5.4-9.3c1.2-2.6,1.5-4.9,0.5-6.3
							c-1-1.5-3.2-2-6.4-1.4c-3.2,0.6-7.2,2.3-11.4,5.3c-4.2,3-8.6,7.3-12.2,12.6c-3.6,5.4-6.6,11.7-8.1,18.4
							c-1.5,6.9-1.9,12.8-1.8,18.1c0.1,5.3,0.7,9.9,1.6,14.4c0.9,4.5,2.1,9,3.7,13.8c1.7,4.8,3.8,9.9,7,15.7
							c-2.2-6.2-3.2-11.6-3.5-16.4c-0.4-4.8-0.2-9.2,0.4-13.3c0.6-4.2,1.6-8.2,3-12.5C120,295.7,121.9,291.1,124.7,286.2z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M453.4,473.1c-7.8,2.4-14.6,6.2-19.4,10.2c-4.8,4-7.6,8.2-6.4,11.6c1.2,3.3,6.3,4.8,13,3.8c6.7-1,14.9-4.4,21.5-10.4
							c6.8-6.1,10.9-10.9,14.9-15.6c4-4.7,8-9.4,14-16.1c-7.3,5.1-12.9,7.7-18.4,9.9C467.1,468.8,461.4,470.6,453.4,473.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M447.9,453.4c-7.2-0.7-14.1,0.2-19.4,2c-5.3,1.8-9.1,4.7-9.3,8.2c-0.2,3.5,3.5,6.8,9.5,8.5c6,1.7,14.2,1.6,21.8-1.4
							c7.8-3.1,12.7-6,17.6-8.9c4.9-2.9,9.7-5.8,16.7-9.9c-7.8,2.1-13.3,2.5-18.7,2.5C460.7,454.5,455.3,454.1,447.9,453.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M455.3,433.2c-4.7-2.7-9.5-3.7-13.7-3.3c-4.2,0.4-7.9,2.2-9.3,5.4c-1.4,3.2,0.1,7.2,3.8,10.4
							c3.7,3.1,9.6,5.3,15.9,4.7c6.4-0.7,10.7-1.9,14.9-3.1c4.2-1.2,8.3-2.4,14.1-3.8c-6-0.7-9.9-2.1-13.6-3.8
							C463.7,438,460.2,435.9,455.3,433.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M473.5,412.7c-2.2-4.4-5.7-7.2-9.4-8.4c-3.7-1.2-7.5-0.8-10.1,1.7c-2.6,2.4-3.1,6.3-1.8,10.2c1.4,3.9,4.7,7.6,9.6,9.6
							c5,2,8.7,2.9,12.3,3.9c3.6,1,7.1,2.1,11.8,4.3c-3.4-3.8-5.3-7-7-10.2C477.3,420.6,475.8,417.2,473.5,412.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M492.7,401.5c0.7-5.1-0.3-9.7-2.2-13.3c-1.9-3.6-4.9-6.3-8.4-6.4c-3.5-0.1-6.7,2.6-8.3,7.2
							c-1.5,4.5-1.3,10.8,1.8,16.3c3.2,5.6,6.1,8.9,8.9,12.1c2.8,3.2,5.6,6.3,9.4,10.8c-1.9-5.5-2.3-9.6-2.3-13.6
							C491.6,410.7,492,406.8,492.7,401.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M517.1,405c3.5-3.9,5.5-8,6.4-12c0.2-1,0.4-2,0.4-3c0-1,0-1.9-0.1-2.8c-0.3-1.8-1-3.5-2.2-4.7
							c-1.3-1.2-2.9-1.9-4.9-1.8c-1,0-2.1,0.2-3.1,0.6c-1.1,0.4-2.2,0.9-3.3,1.6c-4.4,2.8-8.5,8.7-9.3,15.5
							c-0.8,7-0.1,11.7,0.6,16.3c0.8,4.5,1.7,8.9,3.2,15c0.6-6.2,2.3-10.2,4.3-13.8C511,412.3,513.5,409,517.1,405z"
                      />
                    </g>
                    <g>
                      <path
                        d="M537.4,423.9c5.7-1.6,10.3-4.3,13.5-7.6c3.2-3.2,5.1-7.1,4.1-10.4c-1-3.3-5.1-5.3-10.5-5c-5.4,0.3-11.9,3.1-16.6,8.4
							c-4.8,5.4-7.3,9.6-9.8,13.8c-2.3,4.1-4.7,8.2-8.1,14.1c5-4.5,9-6.7,13.1-8.4C527.3,426.9,531.5,425.6,537.4,423.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M547.6,445.1c6,1,11.5,0.6,16-0.7c1.1-0.4,2.2-0.7,3.2-1.2c1-0.5,1.9-1,2.7-1.6c1.6-1.2,2.7-2.6,3.1-4.4
							c0.4-1.7-0.1-3.5-1.4-5.2c-0.6-0.8-1.4-1.7-2.5-2.4c-1-0.7-2.2-1.5-3.5-2c-5.2-2.3-13-2.8-20,0c-7.2,2.9-11.5,5.9-15.7,8.8
							c-4.1,3-8.1,6.1-13.5,10.5c6.6-2.7,11.4-3.3,16-3.4C536.8,443.6,541.4,444.1,547.6,445.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M543.7,464.2c4.4,3.5,8.1,5.9,11.5,7.7c1.7,0.9,3.5,1.7,5.2,2.3c1.7,0.6,3.5,1,5.3,0.6c1.7-0.4,3.1-1.7,3.8-4
							c0.7-2.2,0.6-5.4-0.8-8.8c-1.3-3.3-3.9-6.8-7.2-9.3c-3.4-2.6-7.5-4.4-11.9-5c-4.5-0.6-8.1-0.4-11.2,0
							c-3.1,0.4-5.8,1.1-8.4,1.8c-5.2,1.4-10.1,3.1-17.4,4.9c7.5,0.3,12.6,1.2,17.3,2.7c2.3,0.8,4.6,1.7,6.9,2.8
							C539.1,461,541.5,462.4,543.7,464.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M528.8,484.6c1,5.4,2.5,9.5,4.4,13.1c1,1.8,2.1,3.4,3.3,4.8c1.2,1.4,2.7,2.5,4.4,2.9c1.7,0.4,3.5,0,5.2-1.5
							c1.7-1.4,3.1-3.9,3.9-7c0.7-3.1,0.7-6.9-0.2-10.6c-1-3.7-2.9-7.4-5.6-10.3c-2.8-3.1-5.4-5.1-7.9-6.8c-2.5-1.7-4.7-2.9-7-4.1
							c-4.6-2.3-9.1-4.3-15.4-7.3c5.1,4.8,8,8.7,10.2,12.8c1.1,2,2,4.1,2.8,6.4C527.6,479.3,528.3,481.8,528.8,484.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M510.1,492.7c-1.4,5.7-1.4,11-0.4,15.5c0.3,1.1,0.5,2.2,0.9,3.2c0.4,1,0.8,1.9,1.3,2.7c1,1.6,2.4,2.9,4.1,3.4
							c1.7,0.5,3.5,0.2,5.3-0.9c0.9-0.5,1.8-1.3,2.6-2.2c0.8-0.9,1.6-2,2.3-3.3c2.7-5,3.8-12.7,1.5-19.8c-2.4-7.3-5.2-11.7-7.9-15.9
							c-2.8-4.2-5.7-8.2-10-13.6c2.4,6.5,2.8,11.3,2.7,15.8C512.2,482.3,511.5,486.8,510.1,492.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M488.5,496c-3.6,4.8-5.9,9-7.3,13c-0.7,2-1.3,4-1.5,5.9c-0.2,1.9-0.1,3.7,0.8,5.3c0.9,1.5,2.5,2.5,4.7,2.7
							c2.2,0.2,5.1-0.5,7.9-2.1c2.8-1.6,5.7-4.3,7.8-7.6c2.1-3.3,3.6-7.2,4.1-11.2c0.5-4.2,0.4-7.6,0.2-10.6c-0.3-3-0.7-5.6-1.1-8.2
							c-0.9-5.1-1.9-10.1-2.8-17.4c-1.3,7.2-2.8,12.1-4.8,16.5c-1,2.2-2.1,4.4-3.4,6.7C491.8,491.2,490.3,493.6,488.5,496z"
                      />
                    </g>
                    <g>
                      <path
                        d="M505.9,450.8c0.9-3.4-2.4-5.6-5.8-4.7c-3.4,0.9-5.2,4.5-2.7,7c2.6,2.5,4.3,3.3,6.2,6.1
							C503.8,455.8,505,454.3,505.9,450.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M501.8,454.2c1.2-1.2,1.4-2.6,0.9-3.9c-0.5-1.3-1.7-2.5-3.4-3.1c-1.7-0.6-3.4-0.4-4.6,0.6c-1.2,0.9-1.9,2.6-1.4,4.4
							c0.5,1.9,1.1,3.2,1.6,4.5c0.5,1.3,1,2.5,1.4,4.2c0.7-1.6,1.5-2.6,2.4-3.6C499.5,456.3,500.5,455.4,501.8,454.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M498,453.2c1.3,0.3,2.1-0.1,2.7-1.2c0.6-1,1-2.6,0.8-4.4c-0.2-1.7-1.2-3.2-2.7-3.8c-1.5-0.6-3.4-0.2-4.6,1.4
							c-1.2,1.6-1.7,3-2.2,4.2c-0.4,1.3-0.8,2.4-1.5,3.9c1.4-0.8,2.6-0.9,3.7-0.9C495.5,452.6,496.6,452.9,498,453.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M494.8,449.1c0.1,0.7,0.5,1.2,1,1.7c0.5,0.4,1.3,0.8,2.1,0.9c0.8,0.1,1.7,0.1,2.4-0.3c0.8-0.3,1.4-0.9,1.9-1.6
							c0.5-0.7,0.8-1.5,1.1-2.2c0.2-0.7,0.3-1.4,0.3-2.1c-0.1-0.7-0.3-1.3-0.8-1.8c-0.5-0.6-1.3-1.1-2.4-1.4
							c-1.1-0.3-2.2-0.5-3.1-0.5c-0.9,0-1.7,0.1-2.5,0.3c-1.5,0.4-3,0.9-4.2,2.3c0.8-0.2,1.5,0,2.1,0.3c0.5,0.3,0.9,0.7,1.2,1.2
							c0.3,0.5,0.5,1,0.7,1.5C494.6,447.8,494.6,448.4,494.8,449.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M494.8,445.7c-0.6,1.5-0.3,2.6,0.6,3.6c0.9,1,2.4,1.8,4.1,2c1.7,0.2,3.4-0.6,4.2-2.1c0.9-1.5,0.9-3.7-0.5-5.4
							c-1.4-1.8-2.6-2.7-3.8-3.6c-1.2-0.9-2.3-1.7-3.7-2.8c0.5,1.8,0.5,3.1,0.2,4.4C495.9,443,495.4,444.2,494.8,445.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M496.9,441.3c-1.5,1.5-1.9,3.6-1.4,5.2c0.5,1.6,1.9,2.8,3.6,3.1c1.7,0.3,3.4-0.2,4.5-1.2c1.1-1,1.7-2.3,1.5-4.1
							c-0.3-1.8-0.6-3.2-0.7-4.6c-0.1-1.4-0.1-2.8,0.5-4.7c-1.5,1.3-2.6,2.1-3.9,3C499.7,438.9,498.4,439.7,496.9,441.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M502.9,441.2c-3.7-0.8-6.3,2.7-5.3,6.1c1,3.4,5,5,7.7,2.3c2.8-2.8,3.6-4.5,6.8-6.6C508.4,443.1,506.8,442,502.9,441.2
							z"
                      />
                    </g>
                    <g>
                      <path
                        d="M507.1,444.4c-2.2-2.9-6.2-1.6-7.5,1.7c-1.3,3.3,0.6,6.9,4.2,6.5c3.7-0.5,5.4-1.4,8.9-1.1
							C510,449.2,509.4,447.3,507.1,444.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M507.3,448.7c-0.3-1.6-1.5-2.5-2.9-2.6c-1.4-0.1-2.9,0.6-4.1,2c-1.2,1.3-1.7,2.9-1.7,4.1c0.1,1.3,0.7,2.2,2.2,2.4
							c3,0.6,4.8,0.4,7.3,1.9C507.4,453.8,507.9,452.1,507.3,448.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M468.5,488.6c-5.3,2.7-9.3,6.4-11.9,10.2c-2.6,3.8-3.8,7.9-2.1,11.1c1.6,3.1,6.1,4.3,11.4,2.9
							c5.3-1.4,11.3-5.5,15-11.7c3.7-6.3,5.4-11.1,7-15.7c1.5-4.7,3-9.2,5-15.7c-3.9,5.5-7.5,8.6-11.2,11.3
							C477.9,483.5,474,485.7,468.5,488.6z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M426.3,566.6c5.8-2.4,11.9-6.2,16.3-10.7c2.3-2.2,4.3-4.6,5.9-7c1.7-2.4,3-4.8,4-7.2c2.1-4.7,2.9-8.9,2.7-12.1
							c-0.1-1.6-0.4-2.9-0.8-3.9c-0.5-1-1.1-1.6-2-1.9c-0.8-0.3-1.8-0.3-2.8,0c-1,0.3-2.1,0.9-3.2,1.7c-2.3,1.6-4.9,4-7.8,6.8
							c-1.4,1.4-3,2.8-4.6,4.4c-1.6,1.5-3.3,3.1-5.1,4.7c-3.5,3.3-7.3,6.4-11.7,10c-4.5,3.7-8.8,7-12.5,10.3
							c-3.8,3.3-7,6.6-9.9,10.2c-1.4,1.8-2.7,3.6-4,5.5c-1.3,1.9-2.5,4-3.7,6.1c-2.3,4.4-4.5,9.3-6.2,15.5c2.7-5.8,5.9-10,9.1-13.4
							c1.6-1.7,3.2-3.2,4.9-4.5c1.6-1.3,3.3-2.5,5-3.6c3.4-2.1,7-3.8,11.2-5.4C415.5,570.6,420.3,569,426.3,566.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M414.5,549.3c4.6-2.9,9.2-5.6,13.6-8.1c4.4-2.5,8.6-4.7,12.2-6.7c3.7-2,6.9-3.9,9.1-5.7c2.3-1.8,3.6-3.6,3.5-5.3
							c-0.2-1.7-1.9-3.3-4.9-4.3c-3-1-7.4-1.4-12.6-0.7c-5.1,0.6-11,2.3-16.8,5.3c-5.7,3-11.4,7.2-15.8,12.4
							c-4.6,5.4-7.8,10.4-10.2,15.1c-2.4,4.7-4.1,9.1-5.4,13.5c-1.4,4.4-2.4,8.9-3.2,13.9c-0.8,5-1.3,10.6-1.3,17.1
							c1-6.5,2.7-11.7,4.7-16.1c2-4.4,4.2-8.1,6.7-11.5c2.5-3.4,5.3-6.5,8.5-9.6C405.9,555.4,409.8,552.3,414.5,549.3z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M372.6,532.7c6.2,0.9,13.4,0.7,19.5-0.9c3.1-0.8,6-1.8,8.7-3c2.7-1.2,5-2.6,7.1-4.1c4.2-3,7-6.2,8.5-9
							c0.7-1.4,1.2-2.7,1.2-3.7c0.1-1.1-0.1-2-0.7-2.7c-0.5-0.7-1.4-1.1-2.4-1.4c-1-0.2-2.3-0.3-3.7-0.2c-2.8,0.2-6.3,0.9-10.2,1.8
							c-1.9,0.4-4,0.9-6.2,1.4c-2.2,0.5-4.4,1-6.8,1.5c-4.7,1-9.5,1.8-15.1,2.6c-5.8,0.9-11.1,1.5-16,2.4c-4.9,0.9-9.4,2.1-13.7,3.7
							c-2.1,0.8-4.2,1.7-6.3,2.7c-2.1,1-4.2,2.1-6.3,3.4c-4.2,2.6-8.7,5.7-13.3,10.1c5.3-3.6,10.2-5.6,14.7-6.9
							c2.3-0.6,4.4-1.1,6.5-1.4c2.1-0.3,4.1-0.5,6.2-0.5c4-0.1,7.9,0.3,12.4,1.1C361.2,530.7,366.2,531.8,372.6,532.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M371.3,511.9c5.5-0.2,10.8-0.1,15.8,0c5,0.1,9.8,0.3,14,0.5c4.2,0.1,7.9,0.1,10.8-0.2c2.9-0.4,4.9-1.2,5.7-2.8
							c0.7-1.6,0.1-3.8-2.1-6.2c-2.1-2.4-5.7-5-10.4-7.1c-4.7-2.1-10.7-3.6-17.1-4c-6.5-0.3-13.4,0.4-20,2.6
							c-6.7,2.2-12,4.9-16.5,7.7c-4.4,2.8-8.1,5.7-11.6,8.8c-3.4,3.1-6.6,6.4-9.9,10.3c-3.2,3.9-6.6,8.4-9.8,14
							c4.2-5,8.3-8.7,12.2-11.5c4-2.8,7.8-4.8,11.6-6.5c3.9-1.6,7.8-2.9,12.2-3.9C360.8,512.7,365.7,512,371.3,511.9z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M514.4,139c10.6-1.1,21.3-3.7,30.7-7.7c9.4-3.9,17.6-9.3,23.7-14.8c6.1-5.5,10.2-11.1,12.5-15.3
							c2.3-4.2,2.9-6.9,2.1-7.4c-1.6-1-7.7,7.5-19.9,16.2c-6.1,4.3-13.6,8.7-22.2,12.2c-8.6,3.5-18.2,6.3-28.2,8.1
							c-10.2,1.9-19.1,2.6-27.2,3.2c-8.1,0.6-15.5,1.1-23,1.9c-7.4,0.8-14.9,1.9-23.1,4.1c-4.1,1.1-8.3,2.5-12.7,4.4
							c-4.4,1.9-8.9,4.3-13.4,7.6c9.3-6,18.4-8.6,26.5-10.2c8.2-1.5,15.6-1.9,22.9-2c7.4-0.1,14.7,0.2,22.9,0.5
							C494.2,139.9,503.4,140.1,514.4,139z"
                      />
                    </g>
                    <g>
                      <path
                        d="M458.8,128.6c2.7-1.5,4.5-4,5.1-6.3c0.6-2.3,0-4.4-1.5-5.3c-1.5-0.9-3.5-0.6-5.3,0.4c-1.8,1-3.5,2.7-4.6,5.2
							c-1.1,2.5-2,4.3-3,6c-1,1.7-2.2,3.4-4.4,5.4c2.8-1.1,4.7-1.7,6.8-2.4C454,130.8,456,130.1,458.8,128.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M475.9,123.1c2.3-1.3,3.6-3.6,3.8-5.6c0.2-2.1-0.6-3.8-2.1-4.7c-1.6-0.8-3.4-0.6-5,0.2c-1.6,0.8-2.9,2.3-3.5,4.4
							c-0.7,2.2-1.1,3.8-1.7,5.4c-0.6,1.6-1.4,3.1-3,5c2.3-1,3.9-1.6,5.6-2.2C471.8,125,473.6,124.4,475.9,123.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M493,119.2c2.2-1.6,3.5-4,3.7-6.1c0.2-2.1-0.5-4-2.1-4.8c-1.5-0.8-3.5-0.5-5.1,0.5c-1.7,1.1-3.1,2.9-3.7,5.3
							c-0.6,2.5-1,4.3-1.5,6.1c-0.5,1.8-1.2,3.6-2.5,5.8c2.2-1.6,3.8-2.5,5.5-3.5C488.9,121.7,490.7,120.8,493,119.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M512.9,117.7c2.4-2.2,3.5-5.3,3.4-7.8c-0.1-2.5-1.3-4.3-3-4.6c-1.7-0.4-3.5,0.6-4.9,2c-1.4,1.4-2.5,3.4-2.9,5.9
							c-0.5,2.6-1,4.5-1.6,6.3c-0.7,1.8-1.6,3.7-3.7,5.8c2.7-1.3,4.5-2.3,6.5-3.3C508.4,121,510.4,119.9,512.9,117.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M529.5,112.5c2.1-2.5,3.1-5.6,2.9-8.1c-0.1-2.5-1.2-4.4-2.9-4.8c-1.7-0.5-3.6,0.5-5,2.2c-1.5,1.7-2.6,4.1-3,7
							c-0.4,3-0.6,5.1-1,7.2c-0.4,2.1-1,4.3-2.4,7.1c2.3-2.2,4-3.7,5.7-5.2C525.6,116.6,527.3,115.1,529.5,112.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M541.5,108.7c2-2,2.9-4.7,2.9-7c-0.1-2.2-1.2-4-2.8-4.5c-1.7-0.5-3.5,0.2-5,1.5c-1.5,1.4-2.6,3.4-2.9,6
							c-0.3,2.6-0.5,4.5-0.8,6.4c-0.3,1.9-0.8,3.8-2,6.3c2.1-1.9,3.6-3.1,5.2-4.3C537.8,112,539.4,110.8,541.5,108.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M558.6,100c2.1-2.3,3.1-5.3,3-7.7c0-2.4-1.1-4.3-2.7-4.9c-1.7-0.5-3.6,0.3-5.1,1.9c-1.5,1.6-2.7,4-3.1,6.8
							c-0.4,2.9-0.6,5-0.9,7.1c-0.4,2.1-0.9,4.2-2.2,7c2.2-2.2,3.8-3.7,5.5-5.1C554.7,103.8,556.5,102.4,558.6,100z"
                      />
                    </g>
                    <g>
                      <path
                        d="M573,93.6c2.2-1.8,3.6-4.1,4.1-6.2c0.5-2.2,0.2-4.2-1.2-5.3c-1.4-1.1-3.5-0.9-5.5,0.3c-2,1.2-3.9,3.5-4.7,6.5
							c-0.8,3-1.2,5.2-1.5,7.3c-0.4,2.1-0.8,4.2-1.6,7.1c1.8-2.4,3.3-3.9,4.9-5.2C569,96.6,570.7,95.4,573,93.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M587.3,86.9c2.3-1.1,3.9-2.9,4.8-4.7c0.8-1.8,0.9-3.8-0.1-5.2c-1-1.4-3.1-1.9-5.2-1.2c-2.2,0.6-4.5,2.4-5.7,5.1
							c-1.3,2.7-1.9,4.7-2.5,6.7c-0.6,2-1.2,3.9-2.3,6.5c1.9-2,3.6-3.2,5.3-4.1C583.1,88.9,584.9,88.1,587.3,86.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M602.4,93.3c2.3,0.6,4.4,0.3,6.1-0.6c1.7-0.9,3-2.3,3.1-4.1c0.1-1.7-1-3.4-3.1-4.3c-2-0.9-4.8-1-7.4,0.3
							c-2.6,1.3-4.2,2.6-5.7,3.7c-1.5,1.2-3.1,2.4-5.3,3.9c2.6-0.5,4.5-0.5,6.3-0.2C598.3,92.3,600,92.7,602.4,93.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M602.3,107.3c2.1,1.2,4.2,1.5,6.1,1.3c1.9-0.3,3.6-1.1,4.4-2.7c0.8-1.6,0.2-3.6-1.6-5.2c-1.8-1.6-4.7-2.6-7.7-2.1
							c-3.1,0.5-5.1,1.4-7,2.2c-2,0.9-3.8,1.7-6.3,2.8c2.8-0.1,4.7,0.4,6.5,1C598.4,105.2,600.1,106.1,602.3,107.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M598.1,120.2c2.4,1.8,5,2.6,7.4,2.6c2.3,0,4.2-0.9,4.9-2.5c0.7-1.6-0.1-3.6-1.8-5.3c-1.7-1.6-4.4-2.9-7.5-3.1
							c-6.3-0.3-8.9,0.5-15.1-0.2C591.6,114.5,593.3,116.5,598.1,120.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M455.6,157.1c1.3,2.2,2.9,3.7,4.7,4.7c1.8,0.9,3.8,1.2,5.3,0.3c1.5-0.9,2.1-3,1.3-5.4c-0.7-2.4-2.8-5-5.7-6.3
							c-3-1.3-5.3-1.7-7.4-2.1c-2.2-0.4-4.3-0.7-7.2-1.1c2.6,1.4,4.1,2.9,5.4,4.4C453.2,153.1,454.3,154.8,455.6,157.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M472.5,157.1c1.1,2.4,2.4,4.3,3.9,5.8c1.5,1.5,3.1,2.7,4.9,2.5c0.9-0.1,1.6-0.6,2.2-1.6c0.6-0.9,0.9-2.2,0.8-3.8
							c-0.1-1.5-0.5-3.3-1.5-4.9c-0.9-1.6-2.3-3.2-4-4.3c-1.8-1.1-3.4-1.8-4.8-2.2c-1.5-0.4-2.8-0.7-4.1-0.8
							c-2.6-0.3-5.1-0.3-8.6,0.2c3.4,0.6,5.5,1.9,7.1,3.3C470,152.9,471.3,154.7,472.5,157.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M487.8,156.6c1.5,2.4,3.4,4.2,5.4,5.3c1.9,1.1,4,1.5,5.5,0.6c1.5-0.9,2-3.1,1.1-5.7c-0.9-2.6-3.1-5.4-6.4-6.9
							c-3.3-1.5-5.7-2.1-8.1-2.5c-2.4-0.5-4.7-0.9-8-1.4c3,1.5,4.7,3.1,6.1,4.7C484.9,152.4,486.2,154.1,487.8,156.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M506.5,159.3c2.5,2.3,5.7,3.4,8.3,3.4c2.6,0,4.6-1.1,5.1-2.7c0.5-1.7-0.5-3.6-2.2-5.1c-1.7-1.6-4.2-2.8-7.2-3.3
							c-3-0.5-5.2-0.9-7.4-1.4c-2.2-0.5-4.3-1.3-7.2-2.9c2.2,2.5,3.6,4.3,5.1,6.1C502.5,155.1,504,157,506.5,159.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M525.3,155.5c3.6,1.7,7.5,2.2,10.5,1.7c3-0.5,5.1-1.9,5.2-3.6c0.2-1.8-1.5-3.5-4.1-4.7c-2.6-1.2-6-2-9.7-2
							c-3.8,0.1-6.5,0.2-9.2,0.1c-2.7-0.1-5.5-0.3-9.4-1.3c3.5,1.9,5.9,3.4,8.3,4.9C519.2,152.2,521.6,153.7,525.3,155.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M549,150.1c6.4,2.5,13.2,0.2,13.4-3.3c0.2-3.5-6-6.6-12.5-5.4c-6.7,1.1-9.4,2.3-16.4,2.3
							C540.3,145.7,542.5,147.5,549,150.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M568.8,144.1c3.4,1.2,7.1,1.2,9.7,0.4c2.7-0.8,4.5-2.3,4.5-4.1c0-1.8-1.7-3.3-4.2-4.3c-2.5-1-5.8-1.4-9.2-0.8
							c-3.5,0.6-6,1.1-8.5,1.5c-2.5,0.4-5.1,0.6-8.8,0.4c3.5,1.2,5.9,2.3,8.2,3.4C562.9,141.7,565.2,142.8,568.8,144.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M580.9,135.9c1.6,1.6,3.5,2.2,5.3,2.2c1.8,0,3.5-0.8,4.4-2.3c0.9-1.5,0.7-3.5-0.5-5.1c-1.2-1.6-3.4-2.9-6.1-3
							c-2.7,0-4.6,0.3-6.4,0.5c-1.8,0.3-3.6,0.5-6,0.6c2.3,0.9,3.7,1.9,5,3C578.1,133,579.3,134.2,580.9,135.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M591.4,129.9c3.6,4.4,9.6,5,11.5,2c1.9-3-1.3-8.1-6.8-9.4c-5.7-1.4-8.2-1.2-13.6-3.3
							C586.8,123.1,587.7,125.4,591.4,129.9z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M191.4,346.1c2,10.5,5.5,20.9,10.2,29.9c4.7,9.1,10.8,16.7,16.8,22.3c6,5.6,11.9,9.2,16.3,11.1
							c4.4,1.9,7.2,2.3,7.5,1.5c0.9-1.7-8.1-7.1-17.8-18.4c-4.8-5.7-9.8-12.8-14-21.1c-4.2-8.3-7.8-17.6-10.5-27.4
							c-2.8-10-4.2-18.8-5.5-26.9c-1.3-8.1-2.4-15.4-3.8-22.7c-1.4-7.3-3.2-14.7-6-22.6c-1.4-4-3.2-8.1-5.4-12.3
							c-2.2-4.2-5.1-8.5-8.7-12.7c6.8,8.8,10.2,17.6,12.4,25.5c2.2,8,3.2,15.3,4,22.6c0.7,7.3,1,14.6,1.5,22.8
							C188.8,326.1,189.3,335.3,191.4,346.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M197,289.9c1.7,2.6,4.4,4.1,6.8,4.5c2.4,0.4,4.4-0.3,5.2-1.9c0.8-1.6,0.3-3.5-0.8-5.3c-1.2-1.7-3-3.3-5.5-4.1
							c-2.6-0.9-4.5-1.6-6.3-2.5c-1.8-0.9-3.6-1.9-5.7-3.9c1.3,2.7,2.1,4.6,3,6.6C194.4,285.3,195.3,287.3,197,289.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M204,306.5c1.5,2.2,3.9,3.3,5.9,3.4c2.1,0.1,3.8-0.9,4.5-2.5c0.7-1.6,0.3-3.5-0.6-4.9c-1-1.5-2.5-2.6-4.7-3.1
							c-2.2-0.5-3.9-0.8-5.6-1.3c-1.6-0.5-3.2-1.2-5.2-2.6c1.2,2.2,1.9,3.8,2.7,5.4C201.7,302.5,202.5,304.3,204,306.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M209.3,323.1c1.8,2.1,4.2,3.1,6.4,3.2c2.1,0.1,3.9-0.8,4.6-2.5c0.7-1.6,0.2-3.5-1-5.1c-1.2-1.6-3.1-2.8-5.6-3.2
							c-2.5-0.4-4.4-0.6-6.2-1c-1.8-0.4-3.6-0.9-6-2c1.7,2,2.8,3.6,3.9,5.2C206.4,319.3,207.5,321,209.3,323.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M212.5,342.8c2.4,2.2,5.6,3,8,2.7c2.5-0.3,4.1-1.7,4.3-3.4c0.2-1.8-0.9-3.4-2.4-4.7c-1.5-1.3-3.6-2.2-6.1-2.4
							c-2.6-0.3-4.5-0.6-6.4-1.1c-1.9-0.5-3.8-1.3-6.1-3.2c1.5,2.5,2.6,4.3,3.8,6.2C208.8,338.7,210.1,340.6,212.5,342.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M219.1,359c2.6,1.9,5.8,2.6,8.3,2.2c2.5-0.3,4.3-1.6,4.6-3.3c0.3-1.7-0.8-3.5-2.7-4.8c-1.8-1.3-4.4-2.3-7.3-2.4
							c-3-0.1-5.1-0.1-7.3-0.4c-2.2-0.2-4.3-0.6-7.3-1.8c2.4,2.1,4,3.6,5.6,5.2C214.7,355.4,216.3,357,219.1,359z"
                      />
                    </g>
                    <g>
                      <path
                        d="M223.9,370.7c2.2,1.9,5,2.5,7.2,2.2c2.2-0.3,3.9-1.5,4.3-3.2c0.4-1.7-0.5-3.5-2-4.8c-1.5-1.4-3.7-2.3-6.2-2.3
							c-2.6-0.1-4.6-0.1-6.5-0.2c-1.9-0.2-3.8-0.5-6.4-1.5c2,1.9,3.4,3.4,4.7,4.9C220.3,367.2,221.6,368.7,223.9,370.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M234,386.9c2.5,1.9,5.5,2.6,7.9,2.4c2.4-0.2,4.2-1.4,4.6-3.1c0.4-1.7-0.6-3.5-2.4-4.9c-1.8-1.4-4.2-2.4-7.1-2.5
							c-3-0.1-5.1-0.2-7.2-0.3c-2.1-0.2-4.2-0.5-7.2-1.6c2.4,2,4,3.5,5.6,5C229.9,383.4,231.4,385,234,386.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M241.6,400.7c2,2.1,4.3,3.2,6.5,3.5c2.2,0.3,4.2-0.2,5.2-1.7c1-1.5,0.6-3.6-0.8-5.5c-1.4-1.9-3.8-3.6-6.8-4.2
							c-3.1-0.6-5.3-0.7-7.4-0.9c-2.1-0.2-4.3-0.4-7.2-1c2.6,1.6,4.2,3,5.6,4.5C238.2,397,239.6,398.6,241.6,400.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M249.5,414.4c1.3,2.2,3.2,3.7,5.1,4.3c1.9,0.7,3.9,0.6,5.2-0.6c1.3-1.2,1.6-3.2,0.7-5.3c-0.8-2.1-2.8-4.2-5.5-5.3
							c-2.8-1.1-4.9-1.5-6.9-1.9c-2-0.4-3.9-0.9-6.6-1.7c2.2,1.8,3.5,3.3,4.6,4.9C247.1,410.4,248.1,412.1,249.5,414.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M244.4,430c-0.4,2.4,0.1,4.4,1.1,6c1,1.6,2.6,2.7,4.3,2.7c1.8,0,3.3-1.3,4-3.4c0.7-2.1,0.6-4.9-0.9-7.3
							c-1.5-2.5-2.9-4-4.2-5.4c-1.3-1.4-2.6-2.9-4.3-4.9c0.7,2.6,0.8,4.4,0.7,6.2C245.1,425.8,244.8,427.6,244.4,430z"
                      />
                    </g>
                    <g>
                      <path
                        d="M230.5,431.1c-1,2.2-1.2,4.3-0.7,6.2c0.4,1.9,1.4,3.5,3.1,4.2c1.6,0.6,3.6-0.1,5-2.1c1.4-1.9,2.2-4.9,1.4-7.9
							c-0.8-3-1.8-4.9-2.8-6.8c-1-1.9-2-3.7-3.3-6.1c0.3,2.8,0,4.7-0.4,6.6C232.2,427,231.5,428.8,230.5,431.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M217.3,428c-1.6,2.5-2.2,5.3-1.9,7.5c0.2,2.3,1.2,4.1,2.9,4.7c1.7,0.5,3.6-0.4,5.1-2.2c1.5-1.9,2.5-4.7,2.4-7.7
							c-0.3-6.3-1.2-8.8-1.1-15C222.4,421,220.5,422.8,217.3,428z"
                      />
                    </g>
                    <g>
                      <path
                        d="M168.4,289.1c-2.1,1.5-3.5,3.2-4.2,5.1c-0.7,1.9-0.9,3.9,0.2,5.3c1,1.4,3.2,1.8,5.5,0.9c2.3-0.9,4.7-3.2,5.8-6.3
							c1.1-3.2,1.3-5.4,1.5-7.6c0.2-2.2,0.3-4.3,0.4-7.3c-1.2,2.7-2.5,4.3-3.9,5.7C172.1,286.4,170.5,287.6,168.4,289.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M169.7,305.9c-2.3,1.3-4,2.7-5.5,4.3c-1.4,1.6-2.5,3.4-2.1,5.1c0.2,0.9,0.8,1.6,1.7,2c1,0.5,2.3,0.7,3.8,0.5
							c1.5-0.2,3.2-0.8,4.8-1.9c1.5-1.1,3-2.6,3.9-4.4c1-1.9,1.5-3.5,1.8-5c0.3-1.5,0.4-2.8,0.5-4.1c0-2.6-0.1-5.2-0.9-8.6
							c-0.3,3.5-1.4,5.6-2.7,7.4C173.7,303.1,172.1,304.6,169.7,305.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M171.5,321.1c-2.3,1.7-3.9,3.7-4.8,5.8c-0.9,2-1.2,4.1-0.1,5.5c1,1.4,3.3,1.7,5.8,0.6c2.5-1.1,5.1-3.6,6.3-6.9
							c1.2-3.4,1.6-5.9,1.8-8.3c0.3-2.4,0.5-4.7,0.7-8.1c-1.3,3.1-2.6,4.9-4.1,6.5C175.6,317.9,173.9,319.3,171.5,321.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M170.5,340.1c-2.1,2.7-2.9,6-2.6,8.5c0.2,2.6,1.4,4.5,3.2,4.8c1.7,0.3,3.5-0.8,4.9-2.6c1.4-1.9,2.4-4.4,2.7-7.4
							c0.3-3.1,0.4-5.3,0.8-7.5c0.4-2.2,0.9-4.4,2.3-7.4c-2.3,2.4-3.9,4-5.6,5.6C174.4,335.7,172.6,337.3,170.5,340.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M175.9,358.4c-1.4,3.7-1.5,7.7-0.8,10.6c0.7,3,2.3,4.9,4,4.9c1.8,0,3.3-1.8,4.3-4.5c1-2.7,1.5-6.2,1.1-9.9
							c-0.4-3.8-0.7-6.5-0.9-9.2c-0.2-2.7-0.2-5.5,0.4-9.4c-1.6,3.7-2.9,6.2-4.2,8.7C178.7,352.1,177.4,354.6,175.9,358.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M183.3,381.6c-1.9,6.6,0.9,13.2,4.4,13.1c3.5-0.1,6-6.5,4.4-12.9c-1.7-6.6-3.1-9.1-3.7-16.1
							C186.9,372.5,185.3,374.9,183.3,381.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M190.9,400.8c-0.9,3.5-0.6,7.1,0.4,9.7c1,2.6,2.7,4.3,4.5,4.1c1.8-0.1,3.2-2,3.9-4.5c0.8-2.6,0.9-5.9,0-9.3
							c-0.9-3.5-1.6-5.9-2.2-8.4c-0.6-2.5-1-5-1.2-8.7c-0.9,3.6-1.8,6-2.6,8.5C192.8,394.7,191.9,397.2,190.9,400.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M200.2,412.2c-1.4,1.7-1.9,3.7-1.8,5.5c0.2,1.8,1.1,3.4,2.7,4.2c1.6,0.8,3.5,0.4,5-1c1.5-1.3,2.6-3.7,2.5-6.3
							c-0.2-2.7-0.6-4.5-1.1-6.3c-0.4-1.8-0.8-3.5-1.1-6c-0.7,2.4-1.6,3.9-2.6,5.3C202.8,409.1,201.7,410.4,200.2,412.2z"
                      />
                    </g>
                    <g>
                      <path d="M207,422.2c-4,4-4.1,10-1,11.6c3.1,1.6,8-2,8.8-7.6c0.9-5.8,0.5-8.2,2.1-13.8C213.4,417,211.1,418.1,207,422.2z" />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M378.5,8.1c9.5-5,18.3-11.4,25.5-18.6c7.3-7.2,12.8-15.2,16.3-22.6c3.6-7.4,5.3-14.1,5.8-18.9c0.5-4.8,0-7.5-0.8-7.6
							c-1.9-0.3-4.3,9.8-12.3,22.5c-4,6.3-9.3,13.2-16,19.6c-6.6,6.5-14.6,12.7-23,18.1c-8.7,5.6-16.7,9.6-24,13.2
							c-7.3,3.6-14,6.8-20.6,10.4c-6.6,3.6-13.1,7.4-19.8,12.5c-3.4,2.5-6.8,5.4-10.1,8.8c-3.4,3.4-6.6,7.4-9.6,12
							c6.4-9.1,13.8-14.9,20.7-19.4c7-4.5,13.7-7.6,20.5-10.5c6.8-2.8,13.7-5.3,21.4-8.2C360.1,16.5,368.7,13.3,378.5,8.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M323.1,19.3c2-2.4,2.7-5.4,2.3-7.8c-0.3-2.4-1.6-4.1-3.3-4.4c-1.7-0.3-3.5,0.7-4.8,2.3c-1.3,1.6-2.2,3.9-2.3,6.5
							c-0.1,2.7-0.2,4.7-0.5,6.7c-0.3,2-0.8,4-2.1,6.6c2.2-2,3.7-3.4,5.4-4.8C319.4,23.2,321.1,21.8,323.1,19.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M336.9,7.8c1.6-2.1,2-4.7,1.5-6.6c-0.5-2-2-3.4-3.7-3.5c-1.8-0.2-3.4,0.7-4.5,2.1c-1.1,1.4-1.8,3.2-1.6,5.4
							c0.2,2.3,0.4,4,0.4,5.7c0,1.7-0.1,3.4-1,5.8c1.7-1.8,3-3,4.4-4.2C333.8,11.1,335.2,9.9,336.9,7.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M351.2-2.2c1.5-2.3,1.7-5,1.1-7c-0.6-2.1-2-3.5-3.7-3.7c-1.8-0.2-3.4,0.8-4.6,2.4c-1.1,1.6-1.8,3.8-1.4,6.3
							c0.4,2.6,0.7,4.4,0.9,6.3c0.2,1.9,0.3,3.7-0.1,6.4c1.4-2.3,2.6-3.8,3.8-5.3C348.4,1.6,349.7,0.1,351.2-2.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M369.1-11.1c1.4-2.9,1.2-6.2,0.2-8.4c-1-2.3-2.9-3.4-4.6-3.1c-1.7,0.3-3,1.8-3.8,3.7c-0.8,1.9-1,4.1-0.5,6.5
							c0.5,2.6,0.8,4.5,0.9,6.5c0,2-0.1,4-1.2,6.8c2-2.2,3.4-3.8,4.8-5.5C366.2-6.4,367.6-8.1,369.1-11.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M382.6-22.2c1-3.1,0.7-6.3-0.3-8.6c-1-2.3-2.8-3.6-4.5-3.4c-1.8,0.2-3.1,1.8-3.8,4c-0.7,2.1-0.9,4.8-0.1,7.6
							c0.8,2.9,1.4,5,1.8,7.1c0.4,2.1,0.7,4.3,0.4,7.5c1.3-2.9,2.3-4.9,3.3-6.9C380.4-16.9,381.5-19,382.6-22.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M392.3-30.2c1.1-2.7,1-5.5,0-7.5c-0.9-2-2.6-3.3-4.3-3.1c-1.8,0.1-3.2,1.5-4,3.3c-0.8,1.8-1.1,4.2-0.4,6.6
							c0.7,2.5,1.3,4.4,1.7,6.3c0.4,1.9,0.7,3.8,0.5,6.6c1.2-2.5,2.2-4.2,3.2-5.9C390-25.7,391.1-27.5,392.3-30.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M404.8-44.7c1.1-3,0.9-6-0.1-8.3c-1-2.2-2.6-3.6-4.4-3.5c-1.8,0.1-3.2,1.6-4,3.7c-0.8,2.1-1,4.7-0.3,7.5
							c0.8,2.9,1.4,4.9,1.8,7c0.4,2.1,0.8,4.2,0.6,7.3c1.2-2.9,2.1-4.8,3.1-6.8C402.6-39.7,403.7-41.7,404.8-44.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M415.7-56.1c1.4-2.5,1.8-5.1,1.4-7.3c-0.3-2.2-1.4-3.9-3.1-4.4c-1.7-0.5-3.6,0.4-5,2.3c-1.4,1.9-2.3,4.7-1.9,7.8
							c0.4,3.1,0.9,5.2,1.3,7.3c0.5,2.1,0.9,4.2,1.2,7.2c0.8-2.9,1.6-4.9,2.6-6.7C413.2-51.8,414.3-53.5,415.7-56.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M426.4-67.6c1.7-1.9,2.5-4.1,2.6-6.1c0.1-2-0.6-3.9-2.1-4.8c-1.5-0.9-3.5-0.6-5.3,0.9c-1.8,1.4-3.2,3.9-3.4,6.8
							c-0.2,3,0,5.1,0.2,7.1c0.2,2,0.3,4,0.3,6.9c1-2.6,2.1-4.3,3.3-5.8C423.3-64.2,424.7-65.6,426.4-67.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M442.9-67.4c2.4-0.3,4.2-1.4,5.4-2.9c1.2-1.4,1.9-3.2,1.3-4.9c-0.5-1.7-2.2-2.8-4.4-2.8c-2.2-0.1-4.8,0.9-6.7,3.1
							c-1.9,2.2-2.9,3.9-3.9,5.6c-1,1.7-2,3.3-3.4,5.6c2.2-1.5,4-2.1,5.7-2.6C438.6-66.8,440.4-67.1,442.9-67.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M448-54.4c2.4,0.3,4.5-0.2,6.2-1.1c1.7-1,3-2.4,3.1-4.2c0.1-1.7-1.2-3.4-3.4-4.2c-2.2-0.8-5.3-0.6-7.9,1
							c-2.7,1.7-4.2,3.2-5.7,4.6c-1.5,1.6-2.9,3-4.8,5c2.6-1.1,4.5-1.4,6.4-1.5C443.6-54.9,445.5-54.7,448-54.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M449-40.9c2.9,0.8,5.7,0.5,7.8-0.4c2.1-0.9,3.6-2.4,3.6-4.2c0-1.8-1.4-3.3-3.6-4.2c-2.2-0.9-5.2-1-8.1,0
							c-6,2.1-8,3.8-14,5.5C440.8-43.7,443.1-42.4,449-40.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M330.8,46.9c2,1.6,4.1,2.4,6.1,2.5c2,0.2,3.9-0.3,5-1.7c1-1.4,0.8-3.5-0.8-5.5c-1.5-1.9-4.4-3.6-7.7-3.6
							c-3.3-0.1-5.5,0.4-7.7,0.8c-2.1,0.5-4.2,1-7.1,1.7c3,0.3,4.9,1.1,6.6,2C327.1,44.2,328.7,45.3,330.8,46.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M346.5,40.6c1.9,1.8,3.8,3.1,5.8,3.9c1.9,0.8,3.9,1.4,5.5,0.5c0.8-0.4,1.3-1.2,1.4-2.3c0.2-1.1,0-2.4-0.7-3.8
							c-0.6-1.4-1.7-2.8-3.2-4c-1.5-1.2-3.3-2.1-5.3-2.4c-2.1-0.4-3.8-0.4-5.3-0.3c-1.5,0.1-2.8,0.4-4.1,0.8
							c-2.5,0.7-4.9,1.6-7.9,3.4c3.4-0.8,5.8-0.3,7.9,0.4C342.6,37.7,344.5,38.8,346.5,40.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M360.5,34.4c2.3,1.7,4.7,2.6,6.9,2.9c2.2,0.3,4.3-0.1,5.3-1.5c1-1.4,0.7-3.6-1.1-5.7c-1.8-2.1-4.9-3.8-8.5-4
							c-3.6-0.2-6.1,0.3-8.5,0.7c-2.4,0.5-4.7,1-7.9,1.7c3.3,0.3,5.5,1.1,7.4,2C356.2,31.5,358.1,32.7,360.5,34.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M378.8,29.8c3.2,1.2,6.5,1,8.9,0c2.4-1,3.8-2.7,3.7-4.4c-0.2-1.8-1.8-3.1-4-3.9c-2.2-0.8-5-1-7.9-0.4
							c-3,0.7-5.2,1.2-7.4,1.5c-2.2,0.3-4.5,0.4-7.8,0c3,1.5,5,2.6,7,3.7C373.5,27.4,375.6,28.6,378.8,29.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M394.8,19.2c4,0.3,7.8-0.8,10.4-2.4c2.6-1.6,4-3.6,3.5-5.3c-0.5-1.7-2.7-2.6-5.6-2.8c-2.9-0.2-6.4,0.4-9.8,1.9
							c-3.5,1.5-6,2.6-8.5,3.6c-2.5,1-5.2,1.8-9.2,2.4c4,0.5,6.7,0.9,9.5,1.4C387.9,18.4,390.7,19,394.8,19.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M414.8,5.3c6.9-0.1,12.4-4.8,11.2-8.1c-1.2-3.3-8-3.8-13.6-0.3c-5.8,3.6-7.8,5.6-14.3,8.3
							C405,4.5,407.7,5.3,414.8,5.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M430.8-7.7c3.7-0.2,7-1.5,9.2-3.3c2.2-1.7,3.3-3.8,2.6-5.5c-0.7-1.6-2.8-2.4-5.5-2.4c-2.7,0-5.9,0.9-8.8,2.7
							c-3,1.9-5.1,3.3-7.3,4.6c-2.2,1.3-4.5,2.5-8,3.7c3.7-0.2,6.3-0.1,8.9,0C424.5-7.7,427.1-7.5,430.8-7.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M439-19.9c2.1,0.9,4.1,0.8,5.8,0c1.6-0.7,3-2.1,3.3-3.8c0.3-1.7-0.7-3.5-2.4-4.5c-1.7-1.1-4.3-1.4-6.7-0.5
							c-2.5,1-4.1,2-5.7,2.9c-1.6,0.9-3.1,1.8-5.4,2.9c2.5,0,4.2,0.4,5.8,0.9C435.2-21.5,436.8-20.8,439-19.9z"
                      />
                    </g>
                    <g>
                      <path d="M446.5-29.3c5,2.7,10.8,1,11.4-2.5c0.6-3.5-4.3-7-9.9-6.2c-5.8,0.9-8,2-13.8,2C439.6-33.9,441.3-32.1,446.5-29.3z" />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M533.7,299.2c0.6-0.6,1.1-1.2,1.6-1.8c0.5-0.7,1-1.3,1.5-2c0.9-1.4,1.8-2.8,2.4-4.3c1.4-3,2.2-6.2,2.6-9.4
						c0.8-6.4-0.3-12.8-2.8-18.6c-1.2-2.9-3-5.7-5-8.1c-2-2.4-4.2-4.5-6.6-6.4c-4.8-3.6-10.2-6.1-15.6-7.6c-2.7-0.7-5.5-1.3-8.2-1.5
						c-2.7-0.3-5.5-0.3-8.1-0.2c-5.4,0.3-10.8,1.5-15.7,3.8c-5,2.3-9.4,5.8-12.6,10c-1.6,2.1-2.9,4.5-3.9,6.9
						c-0.9,2.4-1.5,5.1-1.6,7.7c-0.3,5.1,1,10,3.5,14.1c1.3,2,2.9,3.8,4.7,5.2c1.8,1.4,3.8,2.4,5.8,3.1c4,1.3,8,1.3,11.5,0.3
						c3.5-1,6.4-3.3,8-5.7c1.7-2.4,2.4-4.9,2.6-7c0.2-2.1-0.1-4-0.6-5.3c-0.5-1.4-1.2-2.3-1.7-2.8c-0.6-0.5-1-0.6-1.3-0.5
						c-0.6,0.3-0.4,1.7-0.7,3.8c-0.1,1-0.4,2.2-1.1,3.5c-0.6,1.2-1.5,2.6-2.7,3.6c-0.6,0.5-1.3,0.9-2,1.3c-0.7,0.3-1.6,0.5-2.5,0.6
						c-1.8,0.2-3.8-0.2-5.7-1.2c-0.9-0.5-1.8-1.1-2.5-1.9c-0.7-0.7-1.3-1.6-1.8-2.7c-1-2.1-1.4-4.7-1-7.3c0.2-1.3,0.6-2.5,1.2-3.8
						c0.6-1.2,1.4-2.4,2.4-3.6c1-1.1,2.1-2.2,3.4-3.1c1.3-0.9,2.7-1.7,4.3-2.3c6.2-2.5,14.7-2.8,22.4-0.6c3.8,1.1,7.5,2.8,10.6,5.1
						c1.5,1.2,3,2.5,4.2,3.9c0.6,0.7,1.1,1.5,1.7,2.2l0.7,1.2l0.6,1.3c1.6,3.4,2.4,7.4,2.2,11.1c-0.1,1.9-0.5,3.7-1.2,5.4
						c-0.3,0.9-0.7,1.6-1.2,2.4l-0.7,1.1c-0.3,0.4-0.5,0.7-0.9,1.1c-2.5,3-6.1,5.2-10.1,6.9c-2,0.9-4.1,1.6-6.1,2.2
						c-1.1,0.3-2.1,0.6-3.2,0.9c-1.1,0.3-2.1,0.6-3.1,0.8c-8.4,1.9-16.5,2.6-24.2,2.5c-7.7-0.1-15.1-1.1-22.2-2.7
						c-7.1-1.6-13.8-3.8-20.3-6.6c-13-5.7-25.3-13.9-36.8-25.5c-5.7-5.8-11.3-12.5-16.4-20.3c-5.2-7.7-9.9-16.7-13.9-26.8
						c3.7,10.3,8,19.4,12.8,27.5c4.8,8.1,10,15.2,15.6,21.4c11.1,12.5,23,21.8,36.3,28.8c6.6,3.5,13.5,6.4,20.9,8.7
						c7.4,2.3,15.2,3.9,23.5,4.7c8.4,0.8,17.3,0.7,26.8-0.7c1.2-0.1,2.4-0.4,3.6-0.6c1.2-0.2,2.4-0.5,3.6-0.7c2.5-0.6,5-1.2,7.6-2.1
						c2.6-0.9,5.2-2,7.8-3.5C528.7,303.5,531.3,301.6,533.7,299.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M454.1,257.7c3.8-5.9,5.1-13.2,3.9-20.1c-0.6-3.4-1.9-6.8-3.7-9.7c-1.8-2.9-4.1-5.5-6.7-7.6c-2.6-2.1-5.5-3.7-8.6-4.7
						c-3.1-1-6.3-1.5-9.5-1.3c-3.2,0.2-6.3,0.9-9.1,2.4c-0.7,0.3-1.4,0.8-2,1.2c-0.7,0.5-1.3,0.9-1.9,1.4c-1.2,1-2.2,2.1-3.1,3.3
						c-1.7,2.4-2.8,5-3.3,7.6c-0.5,2.6-0.3,5.3,0.4,7.7c0.8,2.5,2.1,4.4,3.6,5.7c1.5,1.4,3,2.3,4.5,2.9c1.5,0.6,2.9,0.8,4.1,0.8
						c0.6,0,1.2-0.1,1.7-0.2c0.5-0.1,0.9-0.3,1.3-0.4c0.8-0.4,1.3-0.8,1.5-1.2c0.3-0.4,0.4-0.7,0.3-1.1c-0.2-0.7-1-1-2-1.6
						c-1-0.6-2.2-1.3-3.3-2.6c-1.1-1.2-1.9-3.1-1.8-4.5c0.1-0.9,0.2-1.7,0.7-2.6c0.4-0.9,1.1-1.8,1.9-2.6c0.4-0.4,0.9-0.7,1.3-1
						c0.2-0.1,0.5-0.2,0.7-0.4c0.3-0.1,0.5-0.2,0.8-0.3c1.1-0.4,2.4-0.5,3.9-0.5c2.9,0.1,5.9,1.3,8.6,3.4c1.3,1.1,2.5,2.4,3.4,3.8
						c0.9,1.5,1.6,3,2,4.7c0.8,3.4,0.5,7.4-1.1,10.7c-1.6,3.5-4.6,6.3-7.9,8.3c-3.4,1.9-7,3-10.6,3.3c-3.7,0.4-7.3,0.1-10.9-0.6
						c-3.5-0.7-7-1.8-10.4-3.2c-3.4-1.4-6.6-3.1-9.9-5.2c-3.2-2.1-6.3-4.6-9.4-7.5c-3-2.9-6-6.3-8.7-10.3c-2.7-4-5.2-8.6-7.1-14
						c3.2,11,8.3,19.4,13.9,26.1c2.8,3.4,5.7,6.3,8.8,9c3.1,2.6,6.3,4.9,9.7,7c3.4,2.1,7,3.8,11,5.2c3.9,1.4,8.2,2.4,13,2.7
						c4.7,0.3,10-0.4,15.2-2.5c2.6-1,5.2-2.5,7.8-4.4C449.8,263.2,452.2,260.8,454.1,257.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M336.8,288.9c-1.6,6.2-2,12.8-1.5,18.8c0.5,6,2,11.6,3.9,16c1.9,4.5,4.2,7.8,6.2,9.9c2.1,2.1,3.9,2.9,5,2.3
						c1.2-0.6,1.7-2.5,1.6-5.1c0.1-2.7-0.4-6.2-0.8-10.4c-0.4-4.1-0.9-8.8-1.2-13.8c-0.3-5-0.4-10.4-0.3-15.8
						c0.1-5.6,0.8-10.4,1.7-14.7c0.9-4.3,2.2-8.3,3.4-12.5c1.2-4.1,2.4-8.3,3.2-13.2c0.4-2.4,0.6-5,0.6-7.8c0-2.8-0.4-5.7-1.3-8.7
						c0.5,3.1,0.5,6,0.1,8.6c-0.4,2.6-1.1,5-1.9,7.2c-1.6,4.4-3.7,8-5.9,11.6c-2.2,3.6-4.5,7.2-6.8,11.5
						C340.7,277.4,338.5,282.5,336.8,288.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M350.5,287.2c-2,4.9-4.1,10-5.6,15.3c-1.5,5.3-2.2,10.7-1.9,15.5c0.3,4.8,1.5,8.7,3,11.2c1.5,2.5,3.2,3.5,4.5,3.1
						c1.3-0.4,2-2,2.7-4.3c0.7-2.3,1.4-5.3,2.3-8.9c0.9-3.6,2.2-7.7,3.7-12.4c1.4-4.7,3-10.1,4-15.9c0.9-6,1.2-11.1,1.2-15.7
						c0-4.6-0.4-8.7-0.9-12.8c-0.5-4.1-1.2-8.1-2.1-12.5c-1-4.4-2.2-9.3-4.1-14.9c1.1,5.8,1.4,10.8,1.4,15.2c0,4.4-0.4,8.4-0.9,12.2
						c-0.6,3.8-1.4,7.6-2.5,11.6C354.1,277.9,352.6,282.2,350.5,287.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M393.3,330.8c-0.5,0.7-1,1.3-1.5,1.9c-0.5,0.6-1.1,1.1-1.6,1.6c-1.2,0.9-2.4,1.7-3.7,2.3c-1.3,0.6-2.5,0.8-3.7,0.9
						c-1.2,0.1-2.1,0-3.2-0.4c-2.1-0.6-3.9-2.2-4.8-3.6c-0.4-0.7-0.7-1.4-0.8-2.3c-0.1-0.9,0-1.8,0.2-2.8c0.5-1.9,1.6-3.7,2.7-4.9
						c1.2-1.2,2-1.6,2.4-2c0.3-0.1,0.8-0.1,1.2,0c0.4,0.1,0.8,0.3,1.1,0.5c0.7,0.4,1.1,1.1,1.7,1.4c0.3,0.1,0.7,0.1,1.1-0.1
						c0.4-0.3,0.8-0.8,1.1-1.6c0.2-0.8,0.2-2-0.2-3.1c-0.4-1.2-1.2-2.4-2.6-3.5c-1.6-1.1-3.4-1.5-5.4-1.5c-1.9,0-3.8,0.5-5.6,1.3
						c-3.5,1.6-6.7,4.5-9,8.7c-1.1,2.1-1.9,4.6-2.1,7.3c-0.2,2.7,0.2,5.8,1.4,8.5c1.1,2.8,2.9,5.2,5.1,7.2c2.2,2,4.8,3.6,7.7,4.6
						c2.9,1,6.3,1.4,9.3,1.1c3.1-0.3,6.1-1.2,8.7-2.6c2.7-1.4,5-3.2,7.1-5.3c1-1.1,1.9-2.2,2.8-3.4c0.8-1.2,1.5-2.4,2.1-3.7
						c2.5-5.3,3.2-10.4,3.2-14.9c0-4.6-0.8-8.7-1.8-12.3c-1-3.7-2.3-6.9-3.6-10c-1.3-3.1-2.7-6-4.2-8.9c-1.5-2.9-3-5.7-4.8-8.6
						c-1.7-2.9-3.5-5.8-5.6-8.7c-4.1-6-8.9-12.3-15.4-19.3c5.9,7.4,9.9,14.3,13.1,20.6c1.6,3.2,3,6.2,4.2,9.2c1.2,3,2.2,6,3.2,8.9
						c0.9,3,1.7,6,2.4,9c0.7,3.1,1.3,6.2,1.6,9.4c0.3,3.2,0.3,6.5-0.3,9.8C396.4,324.7,395.3,328,393.3,330.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M211.1,46.3c3.4-0.8,6.9-1,10.2-0.6c3.3,0.4,6.4,1.5,8.9,3.1c2.6,1.6,4.7,3.9,6.2,6.4c0.7,1.2,1.3,2.5,1.7,3.8
						c0.4,1.3,0.5,2.6,0.6,3.9c0.1,5.3-3,11-7.3,15.2c-1.1,1.1-2.2,2.1-3.4,2.9c-1.2,0.9-2.4,1.7-3.5,2.4c-2.4,1.4-4.7,2.1-6.6,2.3
						c-1,0.1-1.9,0-2.6-0.2c-0.8-0.2-1.6-0.5-2.3-1c-1.5-1-2.7-2.4-3.5-3.9c-0.4-0.8-0.6-1.5-0.8-2.3c-0.1-0.4-0.1-0.8-0.2-1.1
						c0-0.4,0-0.7,0-1.1c0.1-1.5,0.6-3,1.3-4.2c1.4-2.5,3.4-4.2,4.9-5.2c1.5-1,2.5-1.6,2.5-2.3c0-0.6-1.2-1.4-3.6-1.3
						c-1.2,0-2.7,0.3-4.3,0.9c-1.6,0.7-3.4,1.7-5.1,3.3c-1.7,1.6-3.3,3.8-4.3,6.8c-0.3,0.7-0.4,1.5-0.6,2.3
						c-0.1,0.8-0.2,1.6-0.2,2.5c-0.1,1.7,0.2,3.4,0.6,5.2c0.9,3.5,2.9,6.8,5.9,9.6c1.5,1.4,3.4,2.6,5.5,3.4c2.2,0.9,4.4,1.3,6.6,1.4
						c4.5,0.2,8.9-1,12.7-2.8c1.9-0.9,3.7-1.9,5.5-3.1c1.8-1.2,3.5-2.5,5.1-4c1.6-1.4,3.2-3.1,4.6-4.9c1.4-1.8,2.7-3.7,3.9-5.8
						c1.1-2.1,2.1-4.4,2.8-6.9c0.7-2.5,1.1-5.1,1.1-7.8c0-2.7-0.3-5.5-1.1-8.1c-0.8-2.6-1.9-5.1-3.3-7.4c-2.8-4.6-6.7-8.4-11.4-11.2
						c-4.7-2.8-9.9-4.2-15.1-4.6c-5.2-0.4-10.3,0.3-15.2,1.9c-5,1.6-9.3,4-13,6.7c-3.7,2.7-6.9,5.8-9.6,9
						c-5.3,6.4-9.1,13.2-11.6,19.9c-2.5,6.7-3.9,13.4-4.6,19.9c-0.7,6.5-0.7,12.8-0.1,19.1c0.6,6.3,1.8,12.4,3.6,18.6
						c1.8,6.2,4.1,12.4,6.9,18.7c5.7,12.7,13.8,25.8,24.4,40.6c-10-15.2-17.2-28.8-22-41.6c-2.4-6.4-4.2-12.6-5.4-18.7
						c-1.2-6.1-1.8-12-1.9-18c0-5.9,0.5-11.8,1.7-17.7c1.2-5.8,3-11.7,5.8-17.4c2.8-5.6,6.5-11.1,11.4-16c2.4-2.4,5.2-4.6,8.2-6.5
						C204.3,48.6,207.6,47.1,211.1,46.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M217.7,111.6c1.7-0.5,3.2-0.7,4.8-0.7c1.5,0,2.9,0.2,4,0.6c0.5,0.2,1,0.4,1.3,0.7c0.4,0.2,0.7,0.5,0.9,0.8
						c0.5,0.6,1,1.5,1.3,2.5c0.2,0.5,0.3,1.1,0.3,1.7c0.1,0.6,0.1,1.1,0,1.7c-0.1,1.1-0.4,2.2-0.7,3c-0.2,0.4-0.4,0.8-0.6,1.1
						c-0.2,0.3-0.4,0.6-0.7,0.8c-0.3,0.2-0.4,0.4-0.7,0.6c-0.3,0.2-0.6,0.3-1,0.4c-0.7,0.2-1.5,0.2-2.3,0.1c-0.7-0.1-1.4-0.3-2-0.7
						c-1.2-0.6-2.2-2-2.8-3.3c-0.6-1.3-0.9-2.6-1.1-3.6c-0.2-1-0.5-1.8-1-2.2c-0.5-0.3-1.4-0.2-2.5,0.7c-1,0.9-2.2,2.7-2.6,5.4
						c-0.2,1.3-0.2,2.9,0.1,4.5c0.3,1.7,1,3.5,2.3,5.2c2.5,3.5,7,6.2,12.4,6.3c1.3,0,2.7-0.1,4.2-0.4c1.4-0.3,2.9-0.9,4.2-1.7
						c1.3-0.8,2.5-1.7,3.6-2.8c1.1-1.1,2-2.3,2.8-3.5c1.5-2.5,2.5-5.3,3-8.3c0.2-1.5,0.3-3,0.2-4.5c-0.1-1.5-0.3-3-0.7-4.5
						c-0.8-3-2.3-6.1-4.8-8.7c-2.5-2.6-5.8-4.5-9-5.3c-3.2-0.9-6.4-1-9.4-0.6c-1.5,0.2-2.9,0.5-4.3,0.9c-1.4,0.4-2.7,1-4,1.5
						c-5.3,2.4-9.2,5.9-12.2,9.4c-1.5,1.8-2.8,3.6-3.9,5.4c-1.1,1.9-2,3.7-2.8,5.5c-3,7.5-3.6,14.5-3.3,21.3
						c0.3,6.8,1.7,13.4,4,20.4c2.2,7,5.4,14.5,9.3,23.3c-3.1-9.1-5.5-16.9-6.7-24c-1.3-7.1-1.6-13.5-0.8-19.6
						c0.7-6.1,2.5-12,5.9-17.3c0.8-1.3,1.8-2.6,2.9-3.9c1.1-1.2,2.3-2.4,3.5-3.5C211.2,114.4,214.2,112.5,217.7,111.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M252.1,32.8c3.2,0.6,6.4-0.3,8.5-1.8c2.1-1.5,3-3.4,2.5-5.1c-0.6-1.7-2.4-2.7-4.6-3c-2.2-0.3-4.8,0-7.4,1.2
						c-2.7,1.2-4.6,2.1-6.6,2.8c-2,0.7-4.2,1.2-7.3,1.3c3,1,5.1,1.7,7.3,2.5C246.6,31.4,248.8,32.2,252.1,32.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M267.3,45.6c1.8,2,3.7,3.3,5.6,4.1c1.9,0.8,4,1.1,5.5,0.2c1.5-0.9,1.9-3.2,0.7-5.8c-1.2-2.6-4-5.2-7.6-6.1
						c-3.7-0.9-6.2-0.8-8.7-0.6c-2.4,0.2-4.8,0.6-8,1.4c3.3,0.3,5.4,1.2,7.2,2.3C263.8,42.2,265.4,43.6,267.3,45.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M270,60.5c0.6,2.6,1.5,4.6,2.7,6.3c1.2,1.6,2.7,3,4.5,3.1c0.9,0,1.7-0.4,2.4-1.2c0.3-0.4,0.7-0.9,0.9-1.5
						c0.2-0.6,0.4-1.3,0.5-2c0.4-2.9-0.8-6.8-3.6-9.5c-2.9-2.7-5.2-3.8-7.5-4.7c-2.3-0.9-4.7-1.6-7.9-2.2c3,1.6,4.4,3.3,5.6,5.1
						C268.6,55.8,269.4,57.8,270,60.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M263.1,73.6c-0.7,2.7-0.6,5.1,0.1,7.2c0.7,2,1.9,3.7,3.7,4c1.7,0.3,3.5-0.9,4.7-3.2c1.1-2.3,1.5-5.6,0.3-8.6
						c-1.2-3.2-2.4-5.1-3.5-7.1c-1.2-1.9-2.3-3.8-3.9-6.5c0.6,3.1,0.5,5.2,0.2,7.3C264.3,68.8,263.9,70.8,263.1,73.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M251.6,86.6c-1.8,2-2.8,4.1-3.2,6.2c-0.4,2.1-0.1,4.1,1.2,5.3c1.3,1.2,3.5,1,5.7-0.5c2.1-1.5,4-4.4,4.4-7.7
						c0.4-3.4,0.1-5.8-0.2-8c-0.3-2.3-0.7-4.5-1.2-7.5c-0.5,3.1-1.4,5-2.5,6.8C254.7,82.9,253.5,84.5,251.6,86.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M241.2,20.8c6-2.2,9.5-7.9,7.4-10.8c-2.1-2.9-8.6-1.4-12.5,3.7c-2,2.6-3.3,4.5-4.6,6.4c-0.7,0.9-1.3,1.9-2.1,2.7
						c-0.4,0.4-0.9,0.8-1.5,1.1c-0.6,0.3-1.4,0.4-2.1,0.2c0.7,0.5,1.5,0.7,2.3,0.6c0.8,0,1.5-0.2,2.2-0.4c0.7-0.2,1.3-0.4,1.9-0.6
						c0.6-0.2,1.1-0.4,1.7-0.6C235.8,22.5,238.1,21.9,241.2,20.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M227.4,15.6c2.1-2.5,2.5-5.9,1.9-8.2c-0.7-2.4-2.4-3.8-4.1-3.6c-1.8,0.1-3.2,1.4-4.2,2.9c-1,1.5-1.6,3.3-1.6,5.5
						c0,2.4-0.2,4.2-0.7,5.9c-0.5,1.8-1.3,3.6-3.2,5.6c2.6-1.2,4.4-2.1,6.2-3.2C223.5,19.4,225.3,18.1,227.4,15.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M216.5,14.7c1.1-3.1,0.5-6.4-0.9-8.5c-1.4-2.1-3.4-2.9-5-2.3c-1.7,0.6-2.6,2.3-3.1,4.1c-0.5,1.8-0.5,3.9,0.3,6.1
						c0.8,2.4,1.2,4.2,1.4,6.1c0.2,1.9,0.1,3.9-1,6.6c1.9-2.1,3.3-3.8,4.6-5.5C214.1,19.6,215.4,17.8,216.5,14.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M204,15.5c-0.4-2.8-2.4-5-4.4-5.8c-2.1-0.8-4-0.4-5.1,1c-1.1,1.4-1.2,3.2-0.9,4.8c0.3,1.6,1.1,2.9,2.7,4.1
						c1.7,1.2,2.9,2.3,3.9,3.5c1,1.2,1.9,2.7,2.4,5.1c0.6-2.4,0.9-4.1,1.3-5.9C204.2,20.4,204.4,18.4,204,15.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M189.6,18.1c-1.2-0.9-2.6-1.3-4-1.3c-1.4,0-2.6,0.3-3.6,1c-1,0.6-1.6,1.4-1.9,2.3c-0.3,0.9-0.2,1.7,0.1,2.5
						c0.7,1.6,2.1,2.7,3.1,3.4c1.1,0.7,2,0.9,3.4,0.4c1.5-0.5,2.9-0.5,4.3-0.3c1.4,0.3,2.8,1,4.1,2.7c-0.3-2.2-0.8-3.8-1.5-5.5
						C193,21.7,192,19.9,189.6,18.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M174.9,28.6c-3.1,0-5.9,1.8-7.2,3.8c-1.3,2-1.2,4.2,0,5.4c1.2,1.3,3.1,1.6,4.8,1.4c1.8-0.2,3.4-0.8,5.1-2.3
						c1.7-1.5,3.2-2.6,4.8-3.4c1.6-0.8,3.4-1.4,6.1-1.4c-2.5-1.1-4.3-1.8-6.3-2.4C180.2,29.1,178.1,28.5,174.9,28.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M168.5,41.5c-2.5,0.6-4.3,2.4-4.8,4.3c-0.6,1.9-0.1,3.7,1.3,4.8c1.4,1.1,3.2,1.3,4.7,1c1.5-0.3,2.8-1.1,3.7-2.8
						c1-1.7,1.7-3,2.7-4.3c0.9-1.2,2.1-2.3,4.1-3.3c-2.2,0-3.8-0.1-5.6-0.2C172.8,41,171,40.9,168.5,41.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M158.7,62.1c-2.2,1.8-3.3,4.4-3.4,6.6c-0.1,2.2,0.9,4,2.5,4.7c1.6,0.7,3.5,0.1,5.1-1.1c1.6-1.2,2.8-3.1,3.3-5.6
						c0.5-2.6,0.8-4.4,1.3-6.3c0.5-1.8,1.1-3.6,2.6-6c-2.2,1.6-3.9,2.7-5.6,3.7C162.7,59.2,161,60.2,158.7,62.1z"
                    />
                  </g>
                  <g>
                    <path d="M155.7,90.1c-2.8,3.4-1.4,8,2,9c3.4,1,7.1-2.2,6.5-6.5c-0.6-4.5-1.6-6.4-1.4-10.8C160.4,85.5,158.6,86.6,155.7,90.1z" />
                  </g>
                  <g>
                    <path
                      d="M157,113.8c-1.1,1.8-0.9,3.7-0.1,5.2c0.8,1.4,2.4,2.4,4.1,2.4c1.8,0,3.3-0.9,4.2-2.1c0.9-1.3,1.2-2.9,0.5-4.7
						c-1.5-3.7-2.8-5.3-3.2-9.2C160.8,108.9,159.2,110.1,157,113.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M191.5,102.9c2.1-4.3,3.5-8.8,3.9-12.4c0.5-3.6,0.1-6.5-1.5-7.2c-1.6-0.7-4.1,0.9-6.3,4.1c-2.2,3.2-4.1,8-4.6,13.1
						c-0.5,5.3-0.4,8.9-0.3,12.5c0.1,3.6,0.2,7.2,0.2,12.4c1-5.1,2.2-8.5,3.5-11.8C187.9,110.5,189.4,107.4,191.5,102.9z"
                    />
                  </g>
                  <g>
                    <g>
                      <path
                        d="M6.8,333.4c-3-1.7-5.7-4-7.9-6.6c-2.1-2.6-3.6-5.5-4.4-8.4c-0.8-2.9-0.8-6.1-0.2-8.9c0.3-1.4,0.8-2.7,1.4-3.9
							c0.6-1.2,1.4-2.2,2.3-3.2c3.6-3.9,9.7-6,15.7-6.1c1.5-0.1,3,0,4.5,0.1c1.5,0.1,2.9,0.4,4.2,0.7c2.7,0.6,4.8,1.6,6.3,2.8
							c0.8,0.6,1.4,1.3,1.8,1.9c0.5,0.7,0.8,1.5,1,2.3c0.4,1.7,0.4,3.6-0.2,5.2c-0.2,0.8-0.6,1.6-1,2.2c-0.2,0.4-0.4,0.6-0.7,0.9
							c-0.3,0.3-0.5,0.6-0.8,0.8c-1.1,1-2.5,1.7-3.9,2.2c-2.8,0.9-5.4,0.7-7.1,0.5c-1.8-0.3-3-0.5-3.4,0c-0.4,0.5-0.1,1.8,1.7,3.4
							c0.9,0.8,2.1,1.6,3.8,2.3c1.6,0.6,3.7,1.1,6,1.1c2.3,0,5.1-0.5,7.7-2c0.7-0.3,1.3-0.8,2-1.3c0.6-0.5,1.3-1,1.9-1.6
							c1.2-1.2,2.2-2.6,3.1-4.2c1.7-3.1,2.6-6.9,2.2-11c-0.2-2-0.7-4.2-1.7-6.3c-1-2.1-2.3-4-3.9-5.6c-3.2-3.2-7.2-5.3-11.2-6.6
							c-2-0.7-4.1-1.2-6.1-1.5c-2.1-0.4-4.2-0.5-6.4-0.6c-2.2-0.1-4.4,0.1-6.7,0.4c-2.3,0.3-4.6,0.9-6.8,1.6
							c-2.3,0.8-4.6,1.8-6.8,3.1c-2.2,1.4-4.3,3-6.1,4.9c-1.8,2-3.5,4.2-4.7,6.7c-1.2,2.4-2.1,5-2.7,7.6c-1.1,5.3-0.8,10.7,0.7,15.9
							c1.5,5.2,4.4,9.9,7.9,13.7c3.5,3.8,7.8,6.8,12.3,9c4.7,2.2,9.5,3.4,14.1,4c4.6,0.6,9,0.5,13.1,0c8.3-1,15.6-3.4,22.1-6.6
							c6.4-3.2,12-7.1,17-11.4c4.9-4.3,9.2-8.9,13.1-13.9c3.8-5,7.2-10.3,10.1-16c2.9-5.7,5.5-11.8,7.7-18.4
							c4.5-13.2,7.6-28.3,9.9-46.4c-3.1,18-7.1,32.8-12.3,45.4c-2.6,6.3-5.6,12.1-8.8,17.3c-3.3,5.3-6.9,10-10.9,14.4
							c-4,4.3-8.4,8.3-13.3,11.8c-4.9,3.5-10.2,6.5-16.1,8.7c-5.9,2.2-12.4,3.7-19.2,3.9c-3.4,0.1-6.9-0.1-10.4-0.9
							C13.3,336.4,9.9,335.2,6.8,333.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M46.6,281.2c-1.6-0.8-2.8-1.7-4-2.7c-1.1-1-2-2.1-2.5-3.1c-0.3-0.5-0.4-1-0.5-1.4c-0.1-0.4-0.1-0.8-0.1-1.2
							c0-0.8,0.3-1.7,0.8-2.7c0.3-0.5,0.6-1,0.9-1.5c0.4-0.5,0.7-0.9,1.1-1.2c0.8-0.8,1.8-1.4,2.6-1.7c0.4-0.2,0.8-0.3,1.2-0.4
							c0.4-0.1,0.7-0.1,1-0.1c0.3,0,0.6,0,0.9,0.1c0.3,0.1,0.6,0.2,1,0.4c0.7,0.3,1.3,0.9,1.7,1.5c0.5,0.6,0.8,1.2,1,1.8
							c0.4,1.3,0.2,2.9-0.2,4.3c-0.5,1.4-1.1,2.5-1.7,3.4c-0.5,0.9-0.9,1.6-0.7,2.3c0.2,0.6,0.9,1.1,2.3,1.2c1.4,0,3.5-0.5,5.6-2.2
							c1.1-0.8,2.1-1.9,3-3.4c0.9-1.4,1.6-3.3,1.9-5.4c0.6-4.3-0.9-9.3-4.7-13c-1-0.9-2.1-1.8-3.3-2.5c-1.3-0.7-2.7-1.3-4.2-1.6
							c-1.5-0.3-3-0.5-4.5-0.5c-1.5,0.1-3,0.3-4.4,0.7c-2.9,0.8-5.5,2.2-7.8,4c-1.2,0.9-2.3,2-3.2,3.2c-1,1.2-1.8,2.4-2.6,3.8
							c-1.5,2.7-2.5,6-2.5,9.6c0,3.6,1.2,7.2,2.9,10c1.7,2.9,4,5.1,6.4,6.8c1.2,0.9,2.5,1.6,3.8,2.3c1.3,0.7,2.7,1.2,4,1.6
							c5.5,1.8,10.7,2,15.4,1.5c2.3-0.3,4.5-0.7,6.6-1.3c2.1-0.6,4-1.3,5.8-2.2c7.3-3.4,12.6-8.1,17-13.2c4.4-5.2,7.9-10.9,11-17.6
							c3.2-6.7,5.9-14.3,9.1-23.4c-4,8.8-7.5,16.1-11.4,22.1c-3.9,6.1-8.1,10.9-12.8,14.9c-4.7,3.9-10,7-16.1,8.6
							c-1.5,0.4-3.1,0.7-4.7,0.9c-1.6,0.2-3.3,0.2-5,0.1C53.2,283.5,49.7,282.8,46.6,281.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M-32.4,315.2c-2-2.6-4.9-4.2-7.4-4.5c-2.5-0.4-4.6,0.4-5.3,2c-0.7,1.6-0.1,3.6,1.3,5.3c1.4,1.7,3.5,3.3,6.2,4.2
							c2.8,0.9,4.8,1.6,6.8,2.5c2,0.9,3.9,1.9,6.2,4c-1.5-2.8-2.6-4.7-3.6-6.8C-29.3,320-30.4,317.9-32.4,315.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M-34.7,295.5c0-2.7-0.4-4.9-1.3-6.9c-0.9-1.9-2.1-3.5-3.9-3.8c-1.7-0.3-3.6,1.1-4.5,3.8c-0.9,2.7-0.6,6.6,1.4,9.7
							c2.1,3.2,4,4.8,5.9,6.3c1.9,1.5,3.9,2.8,6.8,4.4c-2.2-2.4-3.1-4.5-3.7-6.6C-34.5,300.4-34.8,298.3-34.7,295.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M-26.6,282.8c1.3-2.3,2-4.4,2.3-6.5c0.2-2,0.1-4.1-1.2-5.3c-0.6-0.6-1.5-0.9-2.5-0.8c-0.5,0.1-1.1,0.2-1.6,0.5
							c-0.5,0.3-1.2,0.6-1.7,1.1c-2.3,1.9-4.1,5.5-3.9,9.3c0.3,3.9,1.2,6.3,2.3,8.6c1.1,2.2,2.3,4.4,4.3,7c-1.1-3.2-1-5.5-0.6-7.6
							C-28.7,287.1-27.9,285.2-26.6,282.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M-12.6,277.9c2.3-1.5,3.9-3.4,4.9-5.3c0.9-1.9,1.1-4,0.1-5.4c-1-1.4-3.2-1.8-5.6-0.9c-2.4,0.9-4.9,3.1-6.2,6.1
							c-1.3,3.1-1.8,5.4-2.3,7.6c-0.4,2.2-0.9,4.4-1.5,7.4c1.7-2.6,3.2-4.2,4.8-5.5C-16.7,280.6-15,279.4-12.6,277.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M4.7,276.2c2.7-0.3,4.9-1.1,6.6-2.4c1.7-1.3,2.9-2.9,2.7-4.7c-0.2-1.7-1.9-3.2-4.4-3.5c-2.5-0.4-5.9,0.4-8.5,2.6
							c-2.6,2.3-4,4.2-5.4,6c-1.3,1.9-2.6,3.7-4.3,6.3c2.5-1.9,4.5-2.7,6.5-3.3C-0.1,276.8,1.9,276.5,4.7,276.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M-32.6,331.5c-5.9-2.5-12.3-0.7-12.8,2.8c-0.4,3.5,5.4,6.9,11.7,5.9c3.2-0.5,5.5-1.1,7.8-1.5c1.2-0.2,2.2-0.5,3.4-0.5
							c0.6,0,1.2,0,1.8,0.2c0.6,0.2,1.3,0.6,1.7,1.3c-0.2-0.8-0.7-1.5-1.2-2c-0.6-0.5-1.2-0.9-1.8-1.2c-0.6-0.3-1.2-0.6-1.8-0.8
							c-0.6-0.2-1.1-0.5-1.6-0.7C-27.5,333.9-29.6,332.8-32.6,331.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M-26.1,344.6c-3.2,0.4-5.9,2.5-7,4.8c-1.2,2.2-0.8,4.4,0.5,5.5c1.4,1.1,3.3,1.1,5.1,0.8c1.8-0.4,3.4-1.3,4.9-3
							c1.6-1.7,3-2.9,4.6-3.9c1.6-1,3.4-1.7,6.2-1.9c-2.7-0.9-4.6-1.4-6.7-1.9C-20.6,344.6-22.8,344.2-26.1,344.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M-18.7,352.8c-2.9,1.5-4.7,4.4-5.1,6.8c-0.4,2.5,0.5,4.5,2.1,5.1c1.7,0.7,3.5,0.1,5.1-0.9c1.6-1,3-2.5,4-4.7
							c1-2.2,2-3.9,3.1-5.4c1.2-1.5,2.6-2.9,5.2-4.2c-2.9,0.2-5,0.5-7.1,0.9C-13.6,350.8-15.8,351.3-18.7,352.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M-9,360.7c-1.6,2.3-1.7,5.3-0.7,7.3c0.9,2,2.7,3,4.4,2.7c1.7-0.3,3.1-1.5,4-2.9c0.9-1.4,1.2-2.9,0.8-4.8
							c-0.4-2-0.6-3.6-0.5-5.2c0.1-1.6,0.4-3.3,1.7-5.4c-2,1.3-3.5,2.4-5,3.5C-5.8,357-7.4,358.3-9,360.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M3.3,368.7c0.3,1.5,1,2.7,2,3.7c1,0.9,2.1,1.5,3.2,1.7c1.1,0.2,2.1,0,2.9-0.4c0.8-0.4,1.3-1.1,1.6-1.9
							c0.6-1.7,0.4-3.4,0.1-4.6c-0.3-1.3-0.8-2.1-2.2-2.7c-1.4-0.7-2.5-1.6-3.3-2.7c-0.8-1.2-1.4-2.6-1.1-4.8
							c-1.3,1.8-2,3.4-2.6,5.1C3.3,363.7,2.7,365.7,3.3,368.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M21.2,371c2.3,2.1,5.5,2.7,7.9,2.1c2.4-0.6,3.8-2.2,3.7-3.9c0-1.8-1.2-3.3-2.6-4.4c-1.4-1.1-3.1-1.7-5.3-1.8
							c-2.3-0.1-4.1-0.3-5.8-0.8c-1.7-0.5-3.4-1.3-5.4-3.2c1.1,2.5,1.9,4.3,2.9,6.1C17.7,367,18.8,368.9,21.2,371z"
                      />
                    </g>
                    <g>
                      <path
                        d="M34.6,366c2.2,1.3,4.7,1.2,6.4,0.2c1.7-1,2.6-2.7,2.3-4.4c-0.3-1.7-1.4-3.1-2.7-4c-1.3-0.8-2.8-1.1-4.7-0.5
							c-1.9,0.6-3.3,1-4.8,1.3c-1.5,0.2-3.1,0.3-5.2-0.4c1.6,1.5,2.7,2.7,3.9,3.9C31.1,363.4,32.4,364.7,34.6,366z"
                      />
                    </g>
                    <g>
                      <path
                        d="M55.9,357.6c2.9,0.2,5.5-1,7-2.5c1.6-1.6,2.1-3.5,1.4-5.1c-0.7-1.6-2.5-2.5-4.5-2.7c-2-0.2-4.2,0.4-6.2,1.9
							c-2.1,1.5-3.6,2.7-5.2,3.7c-1.6,1-3.3,1.9-6,2.6c2.7,0.3,4.7,0.7,6.6,1.1C51,357,52.9,357.4,55.9,357.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M77.2,339.2c4.4-0.5,6.5-4.9,4.7-7.9c-1.8-3-6.6-3.2-9.2,0.4c-2.6,3.7-3.2,5.7-6.3,8.9
							C70.6,339.3,72.7,339.8,77.2,339.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M92.4,321c2-0.6,3.2-2.1,3.6-3.7c0.4-1.6-0.1-3.4-1.4-4.6c-1.3-1.2-3-1.6-4.5-1.3c-1.5,0.3-2.9,1.3-3.6,3.1
							c-1.4,3.7-1.5,5.7-3.9,8.9C86.3,321.9,88.3,322.2,92.4,321z"
                      />
                    </g>
                    <g>
                      <path
                        d="M59.8,305.3c-4.5,1.7-8.5,4.1-11.4,6.4c-2.8,2.3-4.5,4.7-3.8,6.3c0.7,1.6,3.6,2.1,7.4,1.3c3.8-0.8,8.5-3,12.3-6.5
							c3.9-3.5,6.3-6.2,8.8-8.9c2.4-2.7,4.8-5.4,8.3-9.3c-4.2,3.1-7.4,4.7-10.6,6.2C67.6,302.3,64.4,303.6,59.8,305.3z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M363.6,398.2c3.2,1.3,6.2,3.2,8.6,5.5c2.4,2.3,4.3,5,5.4,7.7c1.1,2.8,1.6,5.9,1.3,8.8c-0.1,1.4-0.4,2.8-0.9,4.1
							c-0.4,1.3-1.1,2.4-1.8,3.5c-3,4.3-8.8,7.2-14.8,8.1c-1.5,0.3-3,0.4-4.4,0.4c-1.5,0.1-2.9,0-4.3-0.1c-2.7-0.3-5-1-6.7-2
							c-0.8-0.5-1.5-1.1-2-1.7c-0.5-0.6-1-1.4-1.3-2.2c-0.6-1.7-0.8-3.6-0.5-5.2c0.1-0.8,0.4-1.6,0.7-2.3c0.1-0.4,0.4-0.7,0.5-1
							c0.2-0.3,0.4-0.6,0.7-0.9c1-1.2,2.2-2.1,3.6-2.7c2.6-1.2,5.2-1.4,7-1.4c1.8,0,3,0.1,3.4-0.4c0.4-0.5-0.2-1.8-2.1-3.2
							c-1-0.7-2.3-1.3-4-1.8c-1.7-0.4-3.8-0.6-6.1-0.3c-2.3,0.3-4.9,1.2-7.4,3c-0.6,0.4-1.2,1-1.8,1.5c-0.6,0.6-1.2,1.2-1.6,1.9
							c-1,1.3-1.9,2.9-2.5,4.5c-1.3,3.3-1.6,7.2-0.8,11.2c0.4,2,1.2,4.1,2.4,6c1.3,2,2.8,3.6,4.6,5c3.6,2.8,7.8,4.3,11.9,5.1
							c2.1,0.4,4.2,0.6,6.3,0.7c2.1,0.1,4.3,0,6.4-0.3c2.2-0.2,4.4-0.7,6.6-1.3c2.2-0.6,4.4-1.4,6.6-2.5c2.2-1.1,4.3-2.4,6.3-4
							c2-1.6,3.8-3.5,5.5-5.7c1.6-2.2,2.9-4.6,3.8-7.2c0.9-2.6,1.5-5.2,1.7-7.9c0.4-5.4-0.6-10.7-2.7-15.7c-2.2-5-5.6-9.2-9.6-12.6
							c-4-3.3-8.6-5.8-13.4-7.3c-5-1.6-9.9-2.2-14.5-2.1c-4.6,0-9,0.7-13,1.7c-8.1,2.1-15,5.4-21,9.4c-6,4-11,8.6-15.3,13.5
							c-4.3,4.9-8,10-11.1,15.5c-3.1,5.4-5.8,11.2-8,17.2c-2.2,6.1-3.9,12.4-5.3,19.3c-2.7,13.7-3.8,29-3.8,47.2
							c0.7-18.2,2.8-33.4,6.3-46.6c1.8-6.6,3.9-12.7,6.5-18.3c2.6-5.7,5.5-10.9,8.9-15.7c3.4-4.8,7.3-9.3,11.6-13.4
							c4.4-4.1,9.3-7.7,14.8-10.7c5.5-3,11.8-5.2,18.5-6.4c3.4-0.5,6.9-0.8,10.4-0.5C356.7,396.1,360.3,396.8,363.6,398.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M331,455.1c1.7,0.6,3,1.3,4.3,2.2c1.2,0.9,2.2,1.9,2.9,2.8c0.3,0.5,0.5,0.9,0.7,1.3c0.2,0.4,0.2,0.8,0.3,1.2
							c0.1,0.8,0,1.8-0.4,2.8c-0.2,0.5-0.4,1.1-0.7,1.6c-0.3,0.5-0.6,0.9-1,1.4c-0.7,0.9-1.6,1.6-2.4,2.1c-0.4,0.2-0.8,0.4-1.1,0.6
							c-0.4,0.1-0.7,0.2-1,0.3c-0.3,0-0.6,0.1-0.9,0c-0.3,0-0.7-0.1-1-0.2c-0.7-0.3-1.4-0.7-1.9-1.2c-0.5-0.5-1-1.1-1.2-1.7
							c-0.6-1.2-0.6-2.9-0.3-4.3c0.3-1.4,0.8-2.6,1.2-3.6c0.4-1,0.7-1.7,0.4-2.4c-0.2-0.6-1-1-2.5-0.9c-1.4,0.2-3.4,0.9-5.3,2.9
							c-0.9,1-1.9,2.2-2.6,3.7c-0.7,1.6-1.2,3.4-1.2,5.6c0,4.3,2.1,9.1,6.4,12.3c1.1,0.8,2.3,1.5,3.6,2.1c1.3,0.5,2.9,0.9,4.4,1.1
							c1.5,0.1,3,0.1,4.5-0.1c1.5-0.3,2.9-0.7,4.3-1.2c2.7-1.2,5.2-2.9,7.2-5c1-1.1,2-2.3,2.8-3.6c0.8-1.3,1.5-2.6,2-4.1
							c1.1-2.9,1.7-6.3,1.2-9.9c-0.5-3.6-2.1-7-4.2-9.5c-2.1-2.6-4.6-4.5-7.3-5.9c-1.3-0.7-2.7-1.3-4.1-1.8
							c-1.4-0.5-2.8-0.8-4.1-1.1c-5.7-1.1-10.9-0.6-15.4,0.5c-2.3,0.6-4.4,1.3-6.3,2.2c-2,0.9-3.8,1.8-5.5,2.9
							c-6.8,4.3-11.4,9.7-15.1,15.3c-3.7,5.7-6.4,11.9-8.7,18.9c-2.3,7-4,15-6,24.4c2.8-9.2,5.4-16.9,8.5-23.4
							c3.1-6.5,6.6-11.9,10.7-16.4c4.1-4.5,9-8.3,14.8-10.6c1.5-0.6,3-1.1,4.6-1.5c1.6-0.4,3.2-0.6,4.9-0.8
							C324,453.6,327.6,453.9,331,455.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M404.9,411.1c2.3,2.3,5.4,3.5,7.9,3.5c2.5,0,4.5-1,5-2.7c0.5-1.7-0.4-3.6-2-5.1c-1.6-1.6-3.9-2.8-6.7-3.3
							c-2.9-0.6-5-1-7-1.6c-2.1-0.6-4.1-1.4-6.7-3.2c1.9,2.5,3.2,4.4,4.4,6.3C401.2,406.8,402.5,408.7,404.9,411.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M409.7,430.4c0.3,2.7,1.1,4.8,2.2,6.6c1.1,1.8,2.6,3.2,4.3,3.3c1.7,0.1,3.4-1.5,4-4.4c0.6-2.8-0.2-6.6-2.6-9.4
							c-2.5-2.9-4.6-4.3-6.7-5.5c-2.1-1.2-4.2-2.3-7.3-3.5c2.5,2.1,3.7,4.1,4.5,6.1C408.9,425.6,409.4,427.6,409.7,430.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M403.3,444.1c-1,2.4-1.5,4.6-1.4,6.7c0,2,0.4,4,1.8,5.1c0.7,0.5,1.6,0.7,2.6,0.4c0.5-0.1,1.1-0.3,1.6-0.7
							c0.5-0.3,1.1-0.8,1.6-1.3c2-2.1,3.4-6,2.6-9.8c-0.8-3.8-2.1-6.1-3.4-8.2c-1.4-2.1-2.8-4-5.2-6.4c1.5,3,1.7,5.3,1.5,7.4
							C404.8,439.5,404.3,441.5,403.3,444.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M390,450.7c-2.1,1.7-3.5,3.8-4.1,5.9c-0.6,2-0.6,4.1,0.6,5.4c1.2,1.3,3.4,1.3,5.6,0.1c2.2-1.2,4.4-3.7,5.3-6.8
							c0.9-3.3,1.1-5.6,1.3-7.8c0.2-2.3,0.3-4.5,0.6-7.6c-1.3,2.8-2.6,4.5-4.1,6.1C393.8,447.5,392.2,448.9,390,450.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M373.1,454.6c-2.6,0.6-4.7,1.8-6.2,3.2c-1.5,1.5-2.5,3.3-2.1,5c0.4,1.7,2.3,2.9,4.9,2.9c2.6,0,5.8-1.2,8.1-3.7
							c2.3-2.6,3.4-4.6,4.5-6.7c1.1-2,2.1-4,3.4-6.8c-2.2,2.2-4.1,3.3-6,4.1C377.8,453.4,375.8,453.9,373.1,454.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M402.9,395c6.1,1.8,12.3-0.9,12.3-4.5c0-3.5-6.2-6.1-12.3-4.3c-3.2,0.9-5.3,1.8-7.5,2.5c-1.1,0.4-2.2,0.8-3.3,0.9
							c-0.6,0.1-1.2,0.1-1.8,0c-0.6-0.1-1.3-0.5-1.8-1.1c0.3,0.8,0.9,1.4,1.5,1.9c0.6,0.4,1.3,0.7,2,1c0.6,0.2,1.3,0.4,1.9,0.6
							c0.6,0.2,1.1,0.3,1.7,0.5C397.6,393.2,399.8,394.1,402.9,395z"
                      />
                    </g>
                    <g>
                      <path
                        d="M394.7,382.8c3.1-0.8,5.5-3.3,6.3-5.6c0.9-2.4,0.2-4.4-1.3-5.3c-1.5-0.9-3.4-0.7-5.1-0.1c-1.7,0.6-3.2,1.7-4.5,3.6
							c-1.4,1.9-2.6,3.3-4,4.4c-1.4,1.1-3.1,2.2-5.9,2.7c2.8,0.5,4.8,0.8,6.9,1C389.3,383.5,391.5,383.6,394.7,382.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M386.4,375.6c2.7-1.9,4.1-4.9,4.2-7.4c0.1-2.5-1.1-4.4-2.8-4.8c-1.7-0.4-3.5,0.4-5,1.5c-1.5,1.2-2.6,2.9-3.3,5.2
							c-0.7,2.4-1.5,4.1-2.4,5.8c-1,1.6-2.2,3.2-4.6,4.8c2.8-0.6,4.8-1.2,6.9-1.8C381.5,378.3,383.7,377.5,386.4,375.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M375.7,369.1c1.3-2.5,1-5.5-0.2-7.3c-1.2-1.9-3.1-2.6-4.7-2.1c-1.7,0.5-2.9,1.9-3.6,3.3c-0.7,1.5-0.8,3-0.2,4.9
							c0.6,1.9,1,3.5,1.2,5.1c0.1,1.6,0,3.3-1,5.5c1.8-1.6,3.2-2.8,4.5-4.1C373.1,373.1,374.4,371.7,375.7,369.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M362.5,362.8c-0.5-1.4-1.4-2.6-2.5-3.4c-1.1-0.8-2.3-1.2-3.4-1.3c-1.1-0.1-2.1,0.2-2.8,0.8c-0.7,0.5-1.2,1.3-1.4,2.1
							c-0.4,1.7,0.1,3.4,0.5,4.6c0.5,1.2,1.1,1.9,2.5,2.4c1.5,0.5,2.7,1.2,3.6,2.3c0.9,1,1.7,2.4,1.8,4.6c1-2,1.6-3.6,2-5.4
							C363.2,367.7,363.5,365.7,362.5,362.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M344.5,362.7c-2.6-1.8-5.9-2-8.1-1.1c-2.3,0.9-3.4,2.7-3.2,4.4c0.3,1.8,1.6,3.1,3.1,4c1.5,0.9,3.3,1.3,5.5,1.1
							c2.3-0.2,4.1-0.2,5.8,0c1.8,0.3,3.6,0.8,5.8,2.5c-1.4-2.3-2.5-4-3.7-5.7C348.5,366.3,347.1,364.6,344.5,362.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M331.8,369.5c-2.3-1-4.8-0.5-6.4,0.6c-1.6,1.2-2.2,3-1.8,4.7c0.5,1.7,1.8,2.9,3.2,3.6c1.4,0.6,3,0.7,4.7-0.1
							c1.8-0.8,3.2-1.5,4.6-1.9c1.5-0.4,3-0.7,5.2-0.3c-1.8-1.3-3.1-2.3-4.4-3.4C335.6,371.6,334.2,370.5,331.8,369.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M311.8,380.6c-2.9,0.2-5.3,1.7-6.6,3.4c-1.4,1.8-1.7,3.8-0.7,5.3c0.9,1.5,2.8,2.1,4.8,2c2-0.1,4.1-0.9,5.9-2.7
							c1.9-1.8,3.2-3.1,4.7-4.3c1.5-1.2,3-2.3,5.6-3.4c-2.8,0-4.7-0.1-6.7-0.3C316.8,380.6,314.7,380.4,311.8,380.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M293.1,401.5c-4.3,1.1-5.8,5.7-3.6,8.4c2.2,2.8,7,2.4,9-1.5c2.1-4,2.4-6.1,5.1-9.6
							C299.6,400.6,297.5,400.4,293.1,401.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M280.3,421.6c-1.9,0.9-2.9,2.5-3.1,4.2c-0.2,1.7,0.5,3.3,2,4.4c1.4,1,3.2,1.2,4.7,0.7c1.5-0.5,2.7-1.7,3.1-3.6
							c0.9-3.9,0.8-5.9,2.7-9.4C286.3,419.9,284.3,419.9,280.3,421.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M314.7,432.9c4.2-2.3,7.9-5.1,10.4-7.8c2.5-2.7,3.8-5.2,2.9-6.7c-0.9-1.5-3.8-1.6-7.5-0.3c-3.7,1.3-8,4.1-11.4,8
							c-3.5,4-5.5,7-7.5,10c-2,3-4,6-7,10.3c3.8-3.6,6.7-5.7,9.7-7.5C307.3,436.9,310.4,435.2,314.7,432.9z"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M344.5,109.3c1.3-4.4,1.9-9.1,1.4-13.7c-0.4-4.6-1.8-9.3-4.3-13.4c-0.3-0.5-0.6-1-1-1.5c-0.4-0.5-0.7-1-1.1-1.5
						c-0.8-0.9-1.5-1.8-2.4-2.7c-1.7-1.7-3.6-3.1-5.6-4.3c-4-2.4-8.3-3.8-12.6-4.4c-4.3-0.6-8.7-0.6-12.9,0.4
						c-4.2,0.9-8.3,2.7-11.6,5.5c-3.3,2.7-5.9,6.2-7.2,10.2c-0.7,1.9-1.1,4.1-1.1,6.1c0,2,0.2,4,0.8,5.9c1.1,3.7,3.3,7,6.3,9.2
						c3,2.2,6.5,3.1,9.6,3c3.1-0.1,6.1-1.1,8.3-2.8c1.1-0.9,2-1.9,2.7-2.9c0.7-1.1,1.2-2.2,1.4-3.2c0.6-2.1,0.5-4.1,0.2-5.7
						c-0.3-1.6-1-2.9-1.6-3.8c-1.4-1.8-2.6-2.1-2.9-1.8c-0.3,0.3,0.2,1.3,0.6,2.9c0.2,0.8,0.3,1.8,0.2,3c-0.1,1.1-0.4,2.4-1,3.7
						c-0.3,0.6-0.7,1.2-1.2,1.7c-0.5,0.5-1.1,0.9-1.7,1.3c-1.3,0.7-3,1.1-4.8,0.9c-1.8-0.2-3.7-0.9-5-2.1c-1.4-1.3-2.5-3.2-2.9-5.3
						c-0.2-1.1-0.3-2.2-0.2-3.4c0.1-1.1,0.4-2.2,0.8-3.3c0.9-2.2,2.6-4.3,4.7-6c2.1-1.6,4.8-2.7,7.7-3.3c2.9-0.6,6.2-0.6,9.4-0.1
						c6.4,1,12.6,4.2,15.9,9.6c3.5,5.4,4.4,12.8,2.7,19.6c-0.4,1.7-1,3.4-1.8,5c-0.7,1.6-1.6,3.1-2.6,4.6c-2,2.9-4.2,5.4-6.7,7.6
						c-5,4.4-10.6,7.5-16.3,9.7c-5.7,2.3-11.4,3.6-17.1,4.6c-5.7,1-11.4,1.5-17.1,1.9c-5.7,0.4-11.5,0.5-17.5,0.9
						c-6,0.4-12.3,1.1-18.6,3c-3.2,0.9-6.4,2.1-9.5,3.8c-3.1,1.6-6.1,3.7-8.8,6.3c-2.7,2.6-5,5.8-6.7,9.4c-1.6,3.6-2.6,7.8-2.2,12.1
						c-0.2-4.3,0.9-8.4,2.6-12c1.8-3.5,4.1-6.6,6.9-9c2.7-2.4,5.7-4.3,8.8-5.8c3.1-1.4,6.2-2.5,9.4-3.2c6.3-1.5,12.4-1.9,18.3-1.9
						c5.9-0.1,11.7,0.2,17.5,0.2c5.8,0,11.6-0.2,17.6-0.8c6-0.7,12.2-1.7,18.5-3.8c6.3-2,12.9-5,19-9.8c3.1-2.4,6-5.2,8.6-8.6
						C340.9,117.8,343.1,113.9,344.5,109.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M278.5,96c-3.9-1.2-8.4-1.3-12.5,0.5c-2,0.9-3.8,2.1-5.3,3.7c-1.5,1.5-2.7,3.3-3.6,5.3c-0.8,2-1.2,4.2-1.1,6.4
						c0.1,2.2,0.7,4.2,1.6,6c1.8,3.5,4.9,6.1,8.4,7c3.5,1,6.9,0.3,9.4-1.4c1.2-0.8,2.2-1.9,2.8-3c0.6-1.1,0.9-2.2,1-3.1
						c0.2-1.9-0.3-3.4-0.9-4.2c-0.6-0.8-1.2-1-1.6-0.9c-0.8,0.3-0.9,2.1-2.2,3.6c-0.3,0.4-0.7,0.8-1.1,1c-0.4,0.3-0.8,0.4-1.3,0.5
						c-1,0.2-2.4,0.1-3.4-0.5c-1.1-0.6-2.2-1.8-2.7-3.2c-0.6-1.5-0.5-2.8,0.1-4.4c0.7-1.6,2.1-3.1,3.8-4c1.7-0.9,3.9-1.2,6.3-0.9
						c4.9,0.7,8.5,4.3,10.6,8.1c2.2,3.9,3,8.1,2.7,12.3c-0.2,2.1-0.6,4.2-1.5,6.2c-0.4,1-1,2-1.6,3c-0.7,0.9-1.4,1.8-2.4,2.7
						c-1.8,1.6-4.1,3-6.9,3.8c-2.7,0.7-6,0.9-9.2-0.3c3.1,1.4,6.5,1.6,9.4,1.1c3-0.5,5.6-1.7,7.8-3.3c1.1-0.8,2.1-1.7,3-2.6
						c0.9-1,1.7-2,2.3-3.1c1.4-2.1,2.2-4.5,2.9-6.9c1.1-4.8,1-10-0.9-15.4c-1-2.7-2.4-5.4-4.7-8C285.7,99.6,282.5,97.2,278.5,96z"
                    />
                  </g>
                  <g>
                    <path
                      d="M315.1,122.6c2.7-2.4,4.1-5.8,4.2-8.5c0.1-2.8-0.9-4.8-2.6-5.3c-1.7-0.5-3.6,0.5-5.2,2.2c-1.6,1.7-2.9,3.9-3.7,6.8
						c-0.8,2.9-1.5,5-2.5,7.1c-1,2-2.1,4-4.5,6.4c3-1.5,5.1-2.6,7.3-3.8C310.2,126.3,312.4,125.1,315.1,122.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M366,89.8c2.5-1.7,4.3-3.8,5.4-5.9c1.1-2.1,1.5-4.2,0.4-5.7c-1-1.4-3.3-1.7-5.9-0.7c-2.6,1.1-5.4,3.6-6.8,6.9
						c-1.5,3.5-2,6-2.5,8.4c-0.4,2.5-0.8,4.9-1.4,8.3c1.5-3.1,3.1-4.9,4.7-6.6C361.7,93,363.5,91.6,366,89.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M379.9,95.6c3-0.8,5.4-2.4,6.9-4.3c1.5-1.8,2.2-3.9,1.4-5.5c-0.8-1.6-2.9-2.2-5.4-1.9c-2.5,0.3-5.4,1.7-7.6,4.1
						c-2.3,2.5-3.6,4.4-5,6.2c-1.4,1.8-2.8,3.6-5.1,6c2.9-1.5,5.1-2.3,7.2-2.9C374.6,96.9,376.8,96.4,379.9,95.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M390.9,105.2c3.1,0.2,5.9-0.4,8-1.5c2.1-1.1,3.7-2.6,3.6-4.4c0-1.7-1.7-3.3-4.4-4c-2.6-0.7-6.2-0.4-9.3,1.3
						c-3.2,1.7-5.2,3.3-7.1,4.7c-1.9,1.5-3.8,3-6.6,5c3.3-1.1,5.6-1.4,7.9-1.5C385.4,104.9,387.7,105,390.9,105.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M360.3,116.8c2.7-0.8,4.8-2.3,6.1-4c1.3-1.7,2-3.7,1.2-5.3c-0.7-1.6-2.7-2.4-5.1-2.1c-2.4,0.3-5.2,1.7-7.2,4.2
						c-2,2.5-3.1,4.5-4.2,6.4c-1.1,1.9-2.2,3.8-3.9,6.3c2.5-1.8,4.4-2.7,6.3-3.5C355.5,118.1,357.5,117.6,360.3,116.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M373.4,121.3c2.6,0.1,4.9-0.6,6.6-1.8c1.7-1.2,2.8-2.9,2.6-4.6c-0.2-1.7-1.8-3.1-4.1-3.6c-2.3-0.5-5.2,0-7.7,1.7
						c-2.5,1.8-4.1,3.3-5.6,4.7c-1.5,1.5-3,2.9-5.3,4.7c2.8-0.9,4.8-1.1,6.7-1.2C368.7,121,370.7,121.2,373.4,121.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M293.2,147.9c-6.4,0.5-11.3,5.2-10.1,8.5c1.2,3.3,8,3.6,13.2-0.3c5.3-4,7-6.2,12.8-9.5
						C302.5,148,299.8,147.4,293.2,147.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M300.9,162.8c-1.7,2.4-2,5.5-1.2,7.6c0.7,2.2,2.4,3.5,4.1,3.4c1.8-0.1,3.2-1.3,4.2-2.8c1-1.5,1.5-3.4,1.3-5.6
						c-0.2-2.3-0.3-4.1-0.2-5.9c0.2-1.8,0.5-3.6,1.8-5.9c-2.1,1.6-3.6,2.8-5.1,4.1C304.2,159,302.7,160.4,300.9,162.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M250.7,158.6c5.2-0.2,10.2-0.8,14.1-1.6c3.9-0.8,6.7-2,7-3.7c0.2-1.7-2.4-3.7-6.9-4.6c-4.4-1-10.5-0.7-16.1,1.4
						c-5.7,2.2-9.2,4.4-12.7,6.7c-3.4,2.3-6.7,4.7-11.2,8.5c5.1-2.9,9-4.2,12.8-5.1C241.5,159.3,245.3,158.8,250.7,158.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M452.7,61.6c-0.5-3.2-1.3-6.4-2.5-9.5c-1.2-3.1-2.9-6.2-5.1-8.9c-2.2-2.7-5-5-8.1-6.7c-3.1-1.7-6.7-2.7-10.3-2.8
						l-1.4,0l-1.4,0.1c-0.9,0.1-1.8,0.3-2.7,0.5c-1.8,0.5-3.4,1.1-4.9,2c-3,1.7-5.6,4.2-7.3,7.3c-1.7,3-2.4,6.8-1.9,10
						c0.2,1.6,0.7,3.2,1.3,4.6c0.6,1.4,1.4,2.7,2.2,3.8c3.4,4.5,8.5,7,13.1,7.2c2.3,0.1,4.6-0.3,6.5-1.3c1-0.5,1.8-1.1,2.5-1.8
						c0.7-0.7,1.2-1.5,1.7-2.3c1.5-3.2,1-6,0-7.5c-0.5-0.8-1-1.2-1.4-1.4c-0.4-0.2-0.7-0.2-0.9,0c-0.3,0.3-0.1,1.1-0.1,2.2
						c0,0.6-0.1,1.2-0.4,1.9c-0.3,0.7-0.7,1.5-1.3,2.1c-0.3,0.3-0.7,0.6-1,0.8c-0.3,0.2-0.8,0.4-1.3,0.5c-1,0.2-2.2,0.2-3.5-0.1
						c-2.6-0.6-5.4-2.4-7.1-5.2c-0.4-0.7-0.8-1.4-1-2.2c-0.2-0.8-0.4-1.5-0.4-2.3c-0.1-0.8,0-1.5,0.2-2.2c0.2-0.7,0.5-1.4,0.9-2.1
						c0.8-1.4,2.2-2.6,3.9-3.5c0.8-0.4,1.7-0.8,2.6-1c0.4-0.1,0.9-0.2,1.4-0.2l0.7,0l0.7,0c1.9,0.1,4,0.6,5.9,1.7
						c1.9,1,3.8,2.5,5.3,4.2c3.1,3.5,5.2,8.4,6.4,13.7c1.2,5.5,1.6,10.9,1.4,15.9c-0.2,5-0.9,9.7-1.9,14.1
						c-2.1,8.8-5.4,16.4-9.6,23.5c-4.2,7.1-9.5,13.8-16.3,20.4c-6.8,6.6-15.2,13.2-26.1,19.6c11.2-6,20-12.1,27.3-18.3
						c7.3-6.2,13.3-12.6,18.3-19.6c5-7,9.2-14.7,12.3-23.9c1.5-4.6,2.7-9.6,3.4-15.1C453.5,74.1,453.6,68.1,452.7,61.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M420.9,70.1c-1.7-0.9-3.7-1.5-5.7-1.6c-2.1-0.2-4.2,0.2-6.2,0.9c-1.9,0.7-3.7,1.8-5.2,3.1c-1.5,1.4-2.7,3-3.5,5
						c-0.8,1.9-1.2,4.1-1.1,6c0.1,2,0.6,3.8,1.4,5.5c1.6,3.3,4.3,5.8,7.4,6.9c1.6,0.6,3.2,0.8,4.6,0.6c1.4-0.1,2.7-0.5,3.8-0.9
						c2.2-1,3.7-2.4,4.6-3.8c0.9-1.4,1.2-2.7,1.1-3.6c-0.1-0.9-0.4-1.4-0.8-1.5c-0.9-0.2-1.9,0.9-3.8,1.6c-0.9,0.4-2,0.7-3.2,0.8
						c-1.2,0-2.4-0.2-3.2-0.9c-0.9-0.6-1.7-1.7-2.1-3c-0.2-0.6-0.3-1.3-0.3-2c0-0.6,0.2-1.2,0.4-1.8c0.5-1.2,1.7-2.4,3.2-3
						c0.7-0.3,1.5-0.5,2.2-0.6c0.8,0,1.7,0.1,2.6,0.3c1.9,0.6,3.8,1.9,5.4,3.4c0.8,0.8,1.5,1.6,2.2,2.4c0.7,0.8,1.3,1.7,1.9,2.6
						c2.3,3.5,3.9,7.1,5,10.9c1.1,3.8,1.7,7.8,1.5,12.4c0,0.6-0.1,1.1-0.1,1.7c0,0.6-0.1,1.2-0.2,1.8c-0.1,1.2-0.4,2.4-0.6,3.7
						c-0.6,2.5-1.4,5.2-2.7,8c1.5-2.7,2.7-5.3,3.5-7.8c0.4-1.3,0.8-2.5,1.1-3.7c0.1-0.6,0.3-1.2,0.4-1.8c0.1-0.6,0.2-1.2,0.3-1.8
						c0.8-4.7,0.9-9,0.5-13.3c-0.5-4.3-1.4-8.6-3.3-13.1c-0.5-1.1-1-2.3-1.7-3.4c-0.6-1.2-1.4-2.4-2.2-3.5
						C426.7,74.4,424.4,72,420.9,70.1z"
                    />
                  </g>
                  <g>
                    <g>
                      <path
                        d="M503.8,68.5c5.7-0.7,11.4-1.1,16.9-1.7c5.5-0.5,10.7-1.1,15.1-2c4.5-0.8,8.2-1.9,10.7-2.9c2.5-1.1,3.9-2.2,3.7-3
							c-0.2-0.9-1.8-1.4-4.4-1.8c-2.6-0.4-6.3-0.5-10.8-0.6c-4.4-0.1-9.6,0-15.3,0.4c-5.6,0.4-11.8,1.3-17.7,2.9
							c-6.2,1.7-11.2,3.7-15.6,6c-4.3,2.3-8,4.8-11.3,7.8c-0.8,0.7-1.6,1.5-2.4,2.3c-0.8,0.8-1.5,1.6-2.3,2.5
							c-1.5,1.7-2.9,3.6-4.2,5.6c-2.6,4.1-4.9,9.2-5.6,15.5c1.2-6.2,4.1-10.9,7-14.5c1.5-1.8,3.1-3.4,4.7-4.7c0.8-0.7,1.7-1.3,2.5-2
							c0.9-0.6,1.7-1.2,2.6-1.7c3.5-2.2,7.2-3.8,11.4-5.1C493.2,70.2,498,69.2,503.8,68.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M512,85.7c5.6-1.4,11.1-3.6,15.9-6.1c4.8-2.5,9.1-5.5,12.4-8.3c6.7-5.7,9.8-10.9,8.6-12.1c-1.2-1.3-6.2,1.4-13.3,5.2
							c-7.1,3.9-16.5,8.8-26.3,13c-5.1,2.2-9.5,3.7-13.6,4.9c-4.1,1.2-8,2.1-11.9,3.3c-3.9,1.1-7.8,2.4-11.9,4.4
							c-2,1-4.2,2.2-6.2,3.8c-2.1,1.5-4.1,3.5-5.8,5.9c1.9-2.2,4.1-3.9,6.3-5.2c2.2-1.3,4.3-2.2,6.4-2.9c4.2-1.4,8.1-1.9,12-2.3
							c3.9-0.4,7.8-0.5,12.3-1C501.2,87.9,506.2,87.1,512,85.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M519.6,76.1c6.3-1,12.2-3.4,16.2-6c4.1-2.6,6.3-5.3,5.6-6.9c-0.7-1.6-4.1-1.8-8.7-0.9c-4.5,0.9-10.2,2.7-15.7,5.4
							c-5.7,2.7-9.6,4.6-13.6,6.3c-4.1,1.7-8.3,3.3-14.6,4.9c6.6-0.6,11-0.9,15.5-1.2C508.7,77.4,513.2,77.1,519.6,76.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M517.7,72.2c4.9-0.5,9.4-2.3,12.5-4.4c3.1-2.1,4.8-4.4,4.1-6c-0.7-1.6-3.4-2.2-7-1.8c-3.6,0.4-8,1.7-12.2,3.8
							c-4.3,2.3-7.3,3.9-10.4,5.4c-3.1,1.5-6.2,2.9-11.1,4.4c5-0.4,8.5-0.6,12-0.7C509.2,72.8,512.7,72.7,517.7,72.2z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M490.4,213.7c-5.6-1.1-11.2-2.4-16.6-3.5c-5.4-1.2-10.5-2.2-15-2.7c-4.5-0.6-8.4-0.7-11.1-0.5
							c-2.7,0.3-4.3,0.9-4.5,1.8c-0.1,0.9,1.2,1.9,3.6,3c2.4,1.1,5.9,2.4,10.1,3.8c4.2,1.4,9.2,2.9,14.7,4.2
							c5.5,1.3,11.6,2.3,17.8,2.6c6.4,0.2,11.8-0.1,16.7-1c4.8-0.9,9.1-2.1,13.1-4c1-0.4,2-1,3-1.4c1-0.5,2-1.1,2.9-1.7
							c1.9-1.2,3.8-2.5,5.7-4.1c3.7-3.1,7.4-7.3,10-13.1c-3.1,5.6-7.2,9.1-11.1,11.7c-2,1.3-3.9,2.3-5.9,3.1c-1,0.4-2,0.8-2.9,1.1
							c-1,0.3-2,0.6-3,0.8c-3.9,1-8,1.4-12.4,1.4C501.1,215.3,496.2,214.8,490.4,213.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M487.9,194.8c-5.8-0.4-11.6,0.1-17,1c-5.4,1-10.3,2.5-14.3,4.2c-8.1,3.4-12.6,7.4-11.8,8.9c0.8,1.6,6.3,0.6,14.3-0.9
							c8-1.5,18.4-3.4,29-4.4c5.5-0.6,10.2-0.7,14.5-0.6c4.3,0.1,8.3,0.4,12.3,0.5c4,0.1,8.2,0.1,12.7-0.6c2.3-0.4,4.6-0.9,7.1-1.7
							c2.4-0.9,4.9-2.1,7.3-3.9c-2.5,1.6-5.1,2.5-7.5,3.1c-2.4,0.6-4.8,0.8-7,0.8c-4.4,0-8.3-0.6-12.1-1.4c-3.8-0.8-7.6-1.9-12-2.8
							C498.8,196,493.9,195.2,487.9,194.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M477.7,201.6c-6.3-0.9-12.6-0.4-17.3,0.8c-4.7,1.2-7.6,3.1-7.4,4.9c0.2,1.8,3.4,2.9,8,3.5c4.6,0.5,10.6,0.5,16.6-0.4
							c6.2-0.9,10.5-1.5,14.9-1.9c4.4-0.4,8.9-0.6,15.4-0.3c-6.4-1.4-10.8-2.5-15.2-3.5C488.5,203.7,484.2,202.6,477.7,201.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M478.3,205.9c-4.8-1-9.7-0.7-13.3,0.4c-3.6,1-5.9,2.8-5.8,4.5c0.1,1.8,2.6,3.1,6.1,3.9c3.5,0.7,8.1,0.8,12.8,0
							c4.8-0.8,8.1-1.5,11.5-2c3.4-0.5,6.8-0.9,11.9-0.8c-4.9-1.1-8.3-2-11.6-3C486.6,207.9,483.3,206.9,478.3,205.9z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M295.7,319.1c4.1-3.9,8.4-7.8,12.5-11.5c4.1-3.7,7.9-7.3,10.9-10.7c3.1-3.3,5.4-6.4,6.8-8.8c1.4-2.4,1.8-4.1,1.2-4.7
							c-0.6-0.6-2.3-0.1-4.6,1.2c-2.3,1.3-5.4,3.4-9,6c-3.6,2.6-7.7,5.8-12,9.5c-4.2,3.7-8.6,8.1-12.4,13c-3.9,5.1-6.7,9.7-8.9,14.2
							c-2.1,4.4-3.5,8.7-4.4,13c-0.2,1.1-0.4,2.2-0.6,3.3c-0.1,1.1-0.3,2.2-0.3,3.3c-0.2,2.3-0.2,4.6,0,7c0.4,4.8,1.6,10.3,4.8,15.8
							c-2.8-5.7-3.3-11.1-3.1-15.8c0.1-2.3,0.4-4.5,0.9-6.6c0.2-1,0.5-2,0.8-3c0.3-1,0.6-2,1-2.9c1.5-3.8,3.5-7.3,6.1-11
							C288.2,326.9,291.4,323.2,295.7,319.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M312.5,328c3.7-4.5,6.7-9.5,9-14.5c2.3-4.9,4-9.8,4.9-14.1c1.9-8.6,1.3-14.5-0.4-14.8c-1.8-0.3-4.2,4.8-7.5,12.2
							c-3.4,7.4-7.9,16.9-13.3,26.2c-2.7,4.8-5.4,8.7-7.9,12.1c-2.6,3.4-5.1,6.5-7.5,9.7c-2.4,3.2-4.8,6.6-6.9,10.7
							c-1,2.1-2,4.3-2.7,6.8c-0.7,2.5-1.2,5.2-1.1,8.2c0.2-3,0.9-5.6,1.9-7.9c1-2.3,2.2-4.3,3.4-6.1c2.5-3.6,5.3-6.4,8.2-9
							c2.9-2.7,5.9-5.1,9.3-8.2C305.2,336.2,308.7,332.7,312.5,328z"
                      />
                    </g>
                    <g>
                      <path
                        d="M312.9,315.8c4.4-4.6,7.7-10,9.4-14.5c1.7-4.5,1.8-8,0.3-8.8c-1.6-0.9-4.4,1.1-7.5,4.5c-3.1,3.4-6.5,8.3-9.3,13.8
							c-2.9,5.6-4.9,9.4-7.1,13.3c-2.2,3.8-4.6,7.6-8.7,12.7c4.9-4.4,8.3-7.4,11.7-10.3C305,323.4,308.3,320.5,312.9,315.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M309,313.8c3.6-3.4,6.2-7.5,7.4-11c1.2-3.5,1.2-6.4-0.3-7.3c-1.5-0.9-4,0.3-6.7,2.7c-2.6,2.5-5.4,6.1-7.4,10.4
							c-2.1,4.4-3.5,7.5-5,10.5c-1.6,3-3.3,6.1-6.2,10.2c3.8-3.4,6.5-5.6,9.2-7.8C302.6,319.3,305.3,317.2,309,313.8z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M112.7,104.3c4.4,3.7,8.6,7.6,12.7,11.2c4.1,3.6,8.1,7,11.7,9.8c3.6,2.7,6.9,4.7,9.5,5.9c2.5,1.1,4.2,1.4,4.7,0.7
							c0.5-0.7-0.2-2.2-1.7-4.4c-1.5-2.2-3.9-5-6.9-8.3c-3-3.3-6.6-7.1-10.7-10.9c-4.2-3.8-8.9-7.7-14.2-11
							c-5.4-3.3-10.4-5.7-15-7.3c-4.6-1.6-9-2.6-13.4-3c-1.1-0.1-2.2-0.1-3.3-0.2c-1.1,0-2.2,0-3.4,0c-2.3,0.1-4.6,0.3-7,0.8
							c-4.8,0.9-10.1,2.7-15.2,6.4c5.4-3.3,10.7-4.4,15.4-4.7c2.3-0.1,4.6,0,6.7,0.2c1.1,0.1,2.1,0.3,3.1,0.5c1,0.2,2,0.4,3,0.7
							c3.9,1.1,7.6,2.7,11.5,4.9C104.2,97.6,108.2,100.5,112.7,104.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M105.7,121.9c4.9,3.2,10.2,5.7,15.3,7.5c5.2,1.8,10.2,2.9,14.5,3.4c8.7,1,14.6-0.3,14.7-2c0.1-1.8-5.2-3.6-12.9-6.2
							c-7.7-2.6-17.7-6.1-27.5-10.4c-5.1-2.2-9.2-4.4-12.9-6.6c-3.7-2.2-7-4.4-10.5-6.5C83,99,79.4,97,75.1,95.4
							c-2.1-0.8-4.5-1.5-7-2c-2.5-0.5-5.3-0.6-8.3-0.2c3-0.1,5.7,0.3,8.1,1c2.4,0.7,4.5,1.7,6.5,2.7c3.9,2.1,6.9,4.6,9.8,7.2
							c2.9,2.6,5.7,5.4,9.1,8.4C96.7,115.5,100.6,118.7,105.7,121.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M117.9,121c5,3.9,10.8,6.6,15.4,7.8c4.7,1.2,8.1,1,8.8-0.6c0.7-1.6-1.5-4.2-5.3-7c-3.7-2.7-9-5.6-14.7-7.8
							c-5.8-2.3-9.9-3.9-13.9-5.7c-4-1.8-8-3.8-13.6-7.4c4.9,4.4,8.2,7.5,11.5,10.5C109.5,113.9,112.7,117,117.9,121z"
                      />
                    </g>
                    <g>
                      <path
                        d="M119.5,117c3.7,3.2,8.1,5.3,11.7,6.2c3.6,0.9,6.5,0.5,7.2-1.1c0.8-1.6-0.7-4-3.4-6.4c-2.7-2.4-6.7-4.7-11.1-6.3
							c-4.6-1.6-7.8-2.7-11-3.9c-3.2-1.2-6.4-2.6-10.8-5.1c3.8,3.4,6.2,5.8,8.7,8.3C113.2,111.2,115.6,113.7,119.5,117z"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M46.9,120.2c-4.8-3.3-10.4-5.5-16.3-6.1c-2.9-0.3-5.9-0.2-8.8,0.3c-2.9,0.5-5.8,1.4-8.4,2.9c-2.6,1.5-5.1,3.5-6.9,6.1
						c-0.5,0.7-0.9,1.3-1.2,2c-0.3,0.7-0.7,1.5-0.9,2.2L4,128.7l-0.2,1.1c-0.2,0.8-0.2,1.5-0.3,2.3c-0.1,3,0.5,5.8,1.6,8.3
						c1.1,2.5,2.6,4.6,4.5,6.3c1.8,1.7,4,3,6.2,3.6c2.1,0.7,4.2,1,6.1,0.9c1.9-0.1,3.6-0.4,5.2-0.9c1.5-0.5,2.8-1.1,3.9-1.8
						c2.2-1.3,3.6-2.9,4.3-4c0.7-1.2,0.8-2,0.5-2.2c-0.3-0.3-1.1-0.1-2.2,0.3c-1.1,0.4-2.6,1.1-4.6,1.5c-1,0.2-2,0.4-3.2,0.5
						c-1.1,0.1-2.4,0-3.6-0.2c-1.2-0.2-2.5-0.6-3.6-1.2c-1.2-0.6-2.1-1.4-3-2.4c-1.8-2.1-2.9-5.2-2.6-8.1c0.1-0.3,0.1-0.7,0.2-1
						l0.1-0.5l0.2-0.5c0.1-0.3,0.3-0.6,0.4-0.9c0.2-0.3,0.4-0.6,0.6-0.9c0.9-1.2,2.2-2.2,3.7-3.1c1.6-0.9,3.4-1.5,5.4-1.8
						c2-0.4,4.1-0.5,6.2-0.3c4.2,0.3,8.6,1.6,12.4,3.9c4,2.3,7.2,5.4,9.7,8.7c2.5,3.3,4.3,6.9,5.7,10.4c2.6,7.1,3.3,14.2,2.9,21.3
						c-0.4,7.1-1.8,14.3-4.7,22c-3,7.6-7.4,15.8-14.9,23.8c7.8-7.7,12.8-15.6,16.5-23.2c3.6-7.6,5.7-14.8,6.9-22.3
						c0.6-3.7,0.9-7.5,0.8-11.4c-0.1-3.9-0.5-8-1.6-12.1c-1.1-4.2-2.8-8.5-5.4-12.7C55.4,127.8,51.9,123.6,46.9,120.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M34,153.7c-3,0.6-5.5,2.1-7.5,4c-2,1.9-3.5,4.3-4.3,7c-0.4,1.3-0.6,2.7-0.6,4.1c0,1.4,0.2,2.8,0.7,4.1
						c0.8,2.5,2.4,4.7,4.4,6.1c1,0.7,2,1.2,3.1,1.5c1.1,0.3,2.1,0.4,3,0.4c1.9-0.1,3.4-0.7,4.4-1.5c1-0.8,1.6-1.6,1.8-2.3
						c0.2-0.7,0-1.2-0.3-1.5c-0.7-0.6-1.9-0.4-3.2-0.7c-0.7-0.1-1.4-0.4-2-0.7c-0.3-0.2-0.6-0.4-0.8-0.6c-0.2-0.2-0.4-0.5-0.6-0.8
						c-0.4-0.6-0.7-1.4-0.8-2.3c-0.1-0.4-0.1-0.9,0-1.3c0-0.5,0.2-1,0.3-1.5c0.6-2.1,2.6-4.5,4.7-5.5c1.1-0.5,2.1-0.7,3.3-0.5
						c1.2,0.2,2.3,0.8,3.3,1.6c1,0.8,1.8,1.7,2.5,2.8c0.7,1.1,1.2,2.3,1.6,3.6c0.8,2.6,1,5.5,0.9,8.8c-0.1,3.3-0.7,7-0.7,11.4
						c0.6-4.4,1.7-7.9,2.4-11.2c0.8-3.3,1.3-6.4,1.2-9.6c-0.1-1.6-0.3-3.3-0.8-4.9c-0.5-1.7-1.3-3.4-2.5-5c-1.2-1.6-2.8-3.2-5.1-4.3
						c-1.1-0.6-2.4-1-3.8-1.2C37.1,153.3,35.5,153.4,34,153.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M80.9,129c2-0.8,3.3-2.1,4-3.7c0.7-1.6,0.9-3.4,0.1-5c-0.8-1.5-2.7-2.3-4.8-1.9c-2.1,0.4-4.4,2-5.6,4.5
						c-1.2,2.6-1.6,4.5-1.9,6.4c-0.3,1.9-0.7,3.6-1.1,6.1c1.5-2,2.9-3.1,4.3-4C77.3,130.6,78.8,129.9,80.9,129z"
                    />
                  </g>
                  <g>
                    <path
                      d="M87.6,140.1c1.9,0,3.2-0.8,4.1-2.1c0.9-1.2,1.4-2.9,1-4.7c-0.4-1.7-1.8-3-3.7-3.3c-1.9-0.3-4.2,0.4-5.7,2.3
						c-1.5,2-2.3,3.5-3,5c-0.7,1.5-1.3,2.9-2.3,4.8c1.8-1.2,3.2-1.6,4.7-1.9C84.1,140.2,85.6,140.1,87.6,140.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M69.7,113.9c0.7-3.1,0.1-6.2-1.2-8.3c-1.3-2.1-3.2-3.1-4.9-2.7c-1.7,0.5-2.8,2.2-3.3,4.3c-0.5,2.1-0.3,4.6,0.7,7.2
						c1.1,2.6,1.8,4.6,2.5,6.6c0.6,2,1,4.1,1,7.1c1.1-2.9,1.9-4.9,2.8-6.9C68.1,119.2,69,117.1,69.7,113.9z"
                    />
                  </g>
                  <g>
                    <path d="M51.6,99.8c-0.9-5.8-6-9.3-9.1-7.5c-3,1.8-2.7,7.7,1.3,11.5c4.1,4,6.3,5.3,9.7,10.1C52.1,108.1,52.6,105.7,51.6,99.8z" />
                  </g>
                  <g>
                    <path
                      d="M25.9,96.2c-2.9-2.2-6.5-3.1-9.2-2.9c-2.8,0.2-4.8,1.4-5.1,3.1c-0.3,1.7,0.9,3.6,3,5c2,1.5,4.9,2.6,8.1,2.9
						c3.3,0.4,5.7,0.6,8.1,1.1c2.4,0.5,4.7,1.1,7.9,2.7c-2.6-2.5-4.3-4.2-6.1-6C30.7,100.2,28.9,98.4,25.9,96.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-1.6,103.7c-2.7-0.3-5.2,0.9-6.5,2.5c-1.4,1.7-1.5,3.6-0.6,5.1c1,1.5,2.6,2.2,4.2,2.4c1.6,0.2,3.2-0.2,4.8-1.5
						c1.6-1.3,3-2.3,4.4-3c1.4-0.8,3-1.3,5.4-1.4c-2.1-0.9-3.7-1.6-5.5-2.4C3,104.7,1.2,104-1.6,103.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-13.1,124.3c-2.5,1.7-3.7,4.6-3.5,6.9c0.2,2.4,1.6,3.9,3.3,4.1c1.8,0.2,3.4-0.6,4.6-1.5c1.3-1,2.1-2.2,2.5-4.1
						c0.4-2,0.9-3.5,1.7-5c0.8-1.4,1.9-2.8,4.1-4c-2.5,0.2-4.3,0.5-6.2,0.9C-8.6,122.1-10.6,122.6-13.1,124.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-9.6,149.9c-0.9,2.6-0.3,5.4,1.1,7.1c1.4,1.7,3.3,2.2,4.9,1.5c1.6-0.7,2.7-2.2,3.2-3.7c0.5-1.5,0.5-3.1-0.4-4.8
						c-0.9-1.8-1.5-3.3-1.9-4.9c-0.3-1.6-0.5-3.2,0.1-5.5c-1.6,1.8-2.7,3.2-3.8,4.6C-7.5,145.6-8.6,147.2-9.6,149.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M3.1,168.8c0.4,2.9,2.4,5.2,4.4,6.2c2.1,1,4.1,0.7,5.2-0.6c1.2-1.3,1.4-3.2,1.1-4.9c-0.3-1.7-1.2-3.3-2.9-4.7
						c-1.7-1.4-3-2.6-4.1-3.9c-1.1-1.3-2-2.9-2.7-5.4c-0.4,2.6-0.7,4.4-1,6.4C2.9,163.8,2.7,165.8,3.1,168.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M75.4,229.7c2.3,1.4,4,3.2,4.8,5.2c0.9,2,0.9,4.3,0.3,5.8c-0.6,1.5-2,2.8-3.6,3.4c-0.8,0.3-1.5,0.4-2.3,0.4
						c-0.8,0-1.6-0.2-2.3-0.6c-1.5-0.6-2.7-1.8-3.4-2.9c-0.7-1.1-0.9-2.1-0.8-2.9c0.1-0.8,0.6-1.6,1.1-2c0.4-0.5,0.9-0.8,1-1.2
						c0.1-0.4-0.3-1-1.5-1.3c-1.1-0.2-3,0.1-4.7,1.8c-0.4,0.4-0.8,1-1.1,1.6c-0.3,0.6-0.5,1.2-0.7,1.9c-0.3,1.4-0.3,2.8,0.1,4.3
						c0.7,2.9,2.5,5.8,5.5,7.9c1.5,1,3.3,1.8,5.3,2.3c2,0.4,4.2,0.4,6.3-0.2c2.1-0.5,4.1-1.5,5.9-3c1.8-1.4,3.3-3.4,4.2-5.7
						c0.9-2.3,1.3-4.7,1.1-7c-0.1-2.3-0.8-4.6-1.8-6.7c-1-2.1-2.5-4-4.2-5.5c-1.7-1.5-3.6-2.6-5.6-3.4c-4.1-1.7-8.1-2-11.5-1.8
						c-3.5,0.2-6.5,0.9-9.3,1.7c-5.5,1.7-10.2,3.7-14.6,6.1c-4.5,2.5-8.8,5.2-13,9c-2.1,1.9-4.3,4-6.3,6.4c-2.1,2.5-4.1,5.2-5.8,8.5
						c2-3.1,4.2-5.7,6.4-7.9c2.2-2.2,4.5-4.1,6.8-5.6c4.5-3.2,9-5.2,13.5-6.9c4.5-1.7,9.2-2.8,14.4-3.6c2.6-0.3,5.2-0.5,7.9-0.3
						c1.3,0.1,2.7,0.3,4,0.7C72.9,228.5,74.2,229,75.4,229.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M55.5,215c4.5-2.5,8.1-6.3,10.1-9.7c2-3.4,2.4-6.3,1-7.5c-1.4-1.1-4.1-0.2-7.1,1.7c-3.1,2-6.5,5-9.6,8.6
						c-3.2,3.8-5.4,6.3-7.9,8.8c-2.5,2.5-5.2,4.8-9.6,7.6c4.8-1.8,8.2-3.1,11.7-4.4C47.5,218.9,50.9,217.5,55.5,215z"
                    />
                  </g>
                  <g>
                    <path
                      d="M75.4,216.2c2.3,0.5,4.3,0.1,5.9-0.8c1.6-1,2.7-2.5,2.7-4.3c0-1.8-1.2-3.3-3.1-4.1c-1.9-0.8-4.5-0.8-6.8,0.5
						c-2.4,1.3-3.8,2.5-5.3,3.6c-1.5,1.1-2.9,2.2-5.1,3.5c2.5-0.3,4.3-0.1,6,0.2C71.3,215.2,73,215.7,75.4,216.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M35.3,208.6c0.9-2.4,1.3-5,1.3-7.4c0-0.6,0-1.2-0.1-1.8c-0.1-0.6-0.1-1.1-0.2-1.7c-0.2-1.2-0.6-2.3-1.1-3.4
						c-0.5-1.1-1.1-2-1.9-2.8c-0.7-0.8-1.6-1.3-2.3-1.6c-1.6-0.5-2.6,0-3,0.7c-0.4,0.8-0.3,1.8-0.5,2.6c-0.1,0.9-0.3,1.8-0.4,3
						c-0.1,0.7-0.1,1.4-0.2,2.1c0,0.4-0.1,0.9-0.1,1.3c0,0.5,0,0.9-0.1,1.4c-0.1,1.9-0.1,3.9,0,6c0.1,4.3-0.2,7.5-0.8,10.6
						c-0.7,3.1-1.8,6.3-4.7,10.3c3.7-3.3,5.9-5.9,8.1-8.7c1.1-1.4,2.1-2.8,3.1-4.5C33.4,213,34.4,211.1,35.3,208.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M124.7,53.8c3.3-0.9,6.7-1.7,9-1.4c1.2,0.1,2,0.4,2.7,0.9c0.6,0.4,1.2,1.1,1.6,2c0.9,1.8,1,4.3,0.5,6.2
						c-0.1,0.5-0.3,1-0.5,1.5c-0.2,0.5-0.5,0.9-0.7,1.4c-0.5,0.8-1.2,1.5-1.8,2.1c-1.2,1-2.9,1.5-4.2,1.4c-0.7,0-1.3-0.2-1.8-0.4
						c-0.5-0.3-1-0.6-1.4-1c-1.7-1.6-1.8-3.6-2.6-3.9c-0.4-0.1-1,0.2-1.5,1.2c-0.5,1-0.8,2.8-0.1,4.9c0.3,1,0.9,2.2,1.9,3.2
						c1,1.1,2.2,2,3.8,2.6c3,1.2,6.9,1.2,10.4-0.7c1.7-0.9,3.3-2.2,4.6-3.7c0.7-0.8,1.2-1.6,1.8-2.5c0.5-0.9,1-1.8,1.4-2.9
						c0.8-2.1,1.1-4.3,1.1-6.5c-0.1-2.3-0.5-4.6-1.5-6.9c-1.1-2.3-2.8-4.5-5.2-6c-2.4-1.5-5.1-2.2-7.6-2.3c-2.5-0.1-4.7,0.3-6.8,0.9
						c-2.1,0.6-3.9,1.2-5.7,2c-3.8,1.6-7.6,3.8-10.3,7c-1.3,1.6-2.3,3.5-2.9,5.3c-0.6,1.8-0.9,3.6-0.9,5.3c-0.1,3.4,0.5,6.4,1.3,9.2
						c0.9,2.8,2,5.4,3.4,7.8c2.8,4.8,6.6,9.1,11.7,12.4c2.6,1.7,5.5,3.1,8.7,4.2c3.3,1.1,6.9,1.7,10.9,1.8
						c-3.9-0.3-7.5-1.3-10.6-2.6c-3.1-1.3-5.7-3-8-4.8c-4.5-3.7-7.5-8.1-9.4-12.7c-1-2.3-1.6-4.7-2-7.2c-0.4-2.5-0.5-5,0-7.5
						c0.2-1.2,0.6-2.4,1.1-3.4c0.5-1,1.2-2,2.1-2.8C118.7,56,121.3,54.8,124.7,53.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M86,72c-3-1.1-6.3-0.6-8.4,0.7c-2.1,1.3-3.1,3.2-2.6,4.9c0.5,1.7,2.2,2.8,4,3.3c1.9,0.6,4,0.6,6.4-0.2
						c2.5-0.8,4.3-1.2,6.3-1.4c1.9-0.2,4-0.2,6.8,0.7c-2.3-1.8-4-3.1-5.7-4.3C90.9,74.4,89.1,73.1,86,72z"
                    />
                  </g>
                  <g>
                    <path
                      d="M99.5,62.1c0.8-1.3,0.8-2.3,0.3-3.6c-0.5-1.2-1.3-2.8-2.7-3.8c-0.7-0.5-1.6-0.7-2.5-0.6c-0.9,0.1-1.8,0.5-2.6,1.3
						c-0.8,0.8-1.4,1.9-1.7,3.2c-0.3,1.3-0.2,2.8,0.4,4.1c1.2,2.8,2.7,4.1,4.1,5.3c1.4,1.1,2.9,2,4.9,2.9c-1.3-1.8-1.6-3.3-1.5-4.7
						C98.2,64.8,98.7,63.5,99.5,62.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M152.6,18.3c3.1-2.4,6-4.9,8.2-6.9c2.2-2,3.6-3.6,3.1-4.4c-0.4-0.8-2.6-0.5-5.5,1c-2.8,1.4-6.3,4-8.9,7.3
						c-2.7,3.4-4.2,5.9-5.6,8.5c-1.4,2.6-2.7,5.2-4.4,9.2c2.2-3.7,4-6,6-8.1C147.4,22.8,149.4,20.8,152.6,18.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M161.5,23.3c3.2-1.8,6.3-3.8,8.5-5.5c2.2-1.7,3.6-3.2,3.2-4c-0.4-0.8-2.5-0.7-5.2,0.3c-2.8,1-6.2,3-9.1,5.6
						c-2.9,2.7-4.7,4.8-6.5,6.9c-1.7,2.1-3.5,4.3-5.9,7.5c2.8-2.9,5-4.6,7.2-6.1C155.9,26.6,158.1,25.2,161.5,23.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M160,33.4c2.1-0.6,3.9-1.6,5.2-2.7c1.3-1,2-2.1,1.7-3c-0.3-0.8-1.6-1.2-3.4-0.9c-1.7,0.3-3.9,1.2-5.7,2.7
						c-1.8,1.6-2.9,2.8-4,4c-1.1,1.2-2.2,2.4-3.9,4.1c2-1.3,3.5-1.9,4.9-2.5C156.3,34.6,157.8,34.1,160,33.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M207.1,151.4c1.6-3.8,3.4-7.5,4.8-10.2c1.4-2.8,2.4-4.8,1.8-5.4c-0.6-0.6-2.8,0.4-5.2,3c-2.4,2.6-4.7,6.8-5.6,11.4
						c-0.8,4.7-0.7,8-0.3,11.2c0.4,3.2,1,6.4,2.6,10.8c-1.1-4.5-1.1-7.7-0.7-10.8C204.8,158.3,205.5,155.3,207.1,151.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M186.8,193.3c-0.7,3.1-1.2,5.4-1.8,8c-0.5,2.2-0.7,3.9,0,4.4c0.7,0.5,2.3-0.5,3.8-2.8c1.4-2.2,2.6-5.8,2.3-9.3
						c-0.2-3.6-1-6-1.8-8.2c-0.9-2.2-1.9-4.4-3.8-7.3c1.5,3.1,1.8,5.5,2,7.7C187.6,188,187.5,190.3,186.8,193.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M166,263.2c-0.7,3.5-2,6.9-3.1,9.4c-1.1,2.5-2,4.4-1.3,5c0.6,0.6,2.7-0.2,4.9-2.8c1.1-1.3,2.1-2.9,2.8-4.9
						c0.7-1.9,1.2-4.2,1.1-6.4c0-2.3-0.4-4.1-0.9-5.8c-0.5-1.6-1-3-1.7-4.3c-1.4-2.6-3-5.2-6.2-8.1c2.8,3.3,3.8,6,4.4,8.7
						c0.3,1.3,0.4,2.7,0.5,4.2C166.5,259.8,166.4,261.4,166,263.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M249.5,317.1c1.2-0.7,2.4-1.2,3.5-1.4c0.6-0.1,1.1-0.1,1.6-0.1c0.5,0,1,0.2,1.5,0.3c2,0.7,4,2.6,5.2,4.8
						c0.3,0.5,0.5,1.1,0.8,1.7c0.2,0.6,0.4,1.2,0.5,1.8c0.2,1.2,0.3,2.3,0.2,3.3c-0.2,1.9-1.3,3.4-2.5,4.1c-0.1,0.1-0.3,0.2-0.4,0.2
						c-0.1,0.1-0.3,0.1-0.4,0.2c-0.3,0.1-0.6,0.1-0.9,0.2c-0.7,0-1.3-0.1-1.8-0.4c-0.5-0.2-0.9-0.6-1.2-0.9
						c-0.3-0.4-0.5-0.9-0.5-1.4c-0.1-1.1,0.1-2,0.4-2.8c0.3-0.7,0.6-1.3,0.4-1.7c-0.1-0.4-0.8-0.7-2-0.3c-1.1,0.4-2.7,1.6-3.5,3.8
						c-0.4,1.1-0.6,2.5-0.3,4.1c0.3,1.6,1.1,3.2,2.4,4.5c1.3,1.3,3,2.2,4.9,2.7c1,0.2,2,0.3,3.1,0.3c0.5,0,1.1-0.1,1.7-0.2
						c0.5-0.1,1.1-0.3,1.6-0.4c4.2-1.5,7.4-5.3,8.5-10c0.5-2.3,0.6-4.6,0.2-6.8c-0.2-1.1-0.4-2.2-0.8-3.3c-0.4-1.1-0.8-2.2-1.4-3.2
						c-1.1-2.1-2.5-4-4.4-5.6c-1.8-1.6-4.1-3-6.7-3.8c-2.6-0.8-5.5-0.8-8.1-0.1c-2.6,0.7-4.8,2-6.7,3.5c-3.8,3.3-5.9,7.3-7.1,11
						c-1.1,3.8-1.4,7.4-1.1,10.7c0.2,3.3,1,6.4,1.9,9.1c1,2.8,2.1,5.4,3.5,7.8c1.3,2.5,2.7,4.9,4.3,7.3c1.5,2.4,3.1,4.8,4.9,7.4
						c3.6,5,7.8,10.5,13.5,16.3c-5.3-6.2-9-11.9-12.1-17.2c-1.5-2.7-2.8-5.2-4-7.8c-1.2-2.5-2.3-5-3.2-7.5c-0.9-2.5-1.7-5-2.2-7.6
						c-0.5-2.6-0.7-5.2-0.6-7.9c0.2-2.7,0.8-5.5,2-8.1c0.6-1.3,1.3-2.5,2.2-3.7C247.3,318.8,248.3,317.8,249.5,317.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M264.6,361c0.7-2.6,1-4.8,0.9-6.9c-0.1-2.1-0.6-4.1-2-5.2c-0.7-0.5-1.7-0.6-2.7-0.3c-1,0.3-2.1,1.1-3.1,2.3
						c-1,1.2-1.7,2.8-2.1,4.7c-0.4,1.9-0.3,3.9,0.3,5.9c0.6,2,1.4,3.6,2.2,4.9c0.8,1.3,1.7,2.3,2.6,3.3c1.8,1.9,3.7,3.6,6.7,5.5
						c-2.3-2.7-3-5-3.3-7.2C263.8,365.9,263.9,363.7,264.6,361z"
                    />
                  </g>
                  <g>
                    <path
                      d="M284.7,370c-3.2,6-5.2,12.1-7.6,17c-1.2,2.5-2.4,4.7-3.7,6.6c-0.7,0.9-1.4,1.8-2.1,2.6c-0.7,0.8-1.4,1.4-2.1,2
						c-1.4,1.1-2.5,1.5-3.5,1.7c-0.3,0.1-0.8,0-1.2-0.1c-0.5-0.2-0.9-0.4-1.2-0.7c-0.7-0.5-1.1-1.1-1.5-1.7
						c-0.3-0.6-0.5-1.1-0.9-1.3c-0.2-0.1-0.4-0.1-0.7,0c-0.3,0.1-0.6,0.4-0.9,0.8c-0.6,0.9-1,2.7-0.3,4.6c0.3,1,0.9,1.9,1.8,2.8
						c0.9,0.9,2.1,1.8,3.7,2.2c1.4,0.4,3,0.5,4.6,0.3c1.6-0.2,3.1-0.7,4.5-1.4c1.4-0.7,2.7-1.6,3.9-2.6c1.2-1,2.3-2.1,3.4-3.3
						c2.1-2.4,3.9-5.2,5.3-8.2c2.9-5.9,4.6-12.1,6.6-17.7c1.1-2.9,2.2-5.3,3.6-7.5c1.4-2.2,2.8-4.2,4.4-5.9c0.7-0.9,1.6-1.7,2.4-2.5
						c0.9-0.7,1.7-1.5,2.5-2.2c0.4-0.3,0.9-0.7,1.3-1c0.4-0.3,0.9-0.6,1.3-0.9c0.9-0.6,1.8-1.2,2.8-1.7c3.8-2.2,8-3.8,13-4.7
						c1.2-0.2,2.5-0.4,3.9-0.4c1.3-0.1,2.7-0.1,4.1,0.1c2.8,0.3,5.9,1.2,8.4,3.6c-2.3-2.6-5.4-3.8-8.3-4.4c-1.5-0.3-2.9-0.5-4.3-0.6
						c-1.4-0.1-2.7,0-4.1,0c-5.2,0.3-9.9,1.3-14.4,3c-1.1,0.4-2.2,0.9-3.3,1.4c-0.5,0.3-1.1,0.5-1.6,0.8c-0.5,0.3-1.1,0.6-1.6,0.9
						c-1.1,0.6-2.1,1.3-3.2,2c-1,0.8-2.1,1.5-3.2,2.3c-2.1,1.7-4.2,3.7-6.2,6C288.2,364.1,286.3,366.9,284.7,370z"
                    />
                  </g>
                  <g>
                    <path
                      d="M311.3,353.5c-2.3,1-4.3,2.4-5.9,4.2c-1.6,1.7-2.9,3.8-3.4,5.9c-0.6,2.1-0.3,4.2,0.4,5.5c0.7,1.3,1.7,1.7,2.5,1.5
						c0.9-0.2,1.6-0.8,2.3-1.4c0.7-0.6,1.5-1.2,2.3-1.9c0.9-0.7,1.8-1.6,3-2.7c1.2-1.1,2.4-2.4,3.6-3.7c2.6-2.8,4.9-4.5,7.5-5.8
						c1.3-0.6,2.7-1.3,4.3-1.7c1.6-0.4,3.5-0.7,5.7-0.7c-2.2-0.6-4.1-0.9-5.9-1.1c-1.8-0.2-3.4-0.3-5-0.3c-1.6,0-3.3,0.1-5.1,0.4
						C315.7,352,313.7,352.5,311.3,353.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M333.5,332.9c0.1-2-0.4-3.5-1.3-5c-0.9-1.4-2.2-2.8-3.9-3.1c-0.9-0.2-1.7,0-2.5,0.5c-0.8,0.5-1.5,1.4-1.9,2.6
						c-0.4,1.2-0.6,2.6-0.4,4.2c0.3,1.5,0.9,3.1,2,4.3c2.2,2.6,4.3,3.7,6.2,4.6c1.9,0.9,3.9,1.5,6.5,2.1c-2.2-1.6-3.2-3.2-3.8-4.8
						C333.7,336.7,333.4,335,333.5,332.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M523.9,354.8c0.8-4,0.7-8.3-0.4-12.2c-1-3.9-2.9-7.6-5.4-10.8c-2.5-3.2-5.7-5.8-9.2-7.6c-3.5-1.8-7.3-3-11-3.4
						c-3.8-0.4-7.6-0.2-11.2,0.8c-3.6,0.9-7.2,2.6-10.1,5.5c-1.4,1.4-2.6,3.2-3.4,5c-0.8,1.8-1.3,3.7-1.4,5.5
						c-0.3,3.7,0.5,7.3,2.3,10.3c1.8,3,4.4,5.2,7.1,6.5c2.7,1.3,5.5,1.8,8.1,1.8c2.6-0.1,5-0.8,7-1.8c2-1,3.5-2.4,4.6-3.7
						c2.3-2.7,3.1-5.3,3.4-7.1c0.2-1.8-0.1-2.8-0.5-2.8c-0.5-0.1-1,0.7-1.9,1.9c-0.9,1.2-2.2,3-4.3,4.5c-1,0.7-2.3,1.4-3.7,1.8
						c-1.4,0.4-2.9,0.6-4.5,0.4c-1.6-0.2-3.2-0.7-4.7-1.7c-1.5-0.9-2.7-2.2-3.4-3.7c-0.7-1.5-1-3.5-0.7-5.3c0.1-0.9,0.4-1.8,0.8-2.6
						c0.4-0.8,0.9-1.4,1.6-2.1c2.8-2.6,8.4-3.9,13.5-3.2c5.3,0.6,10.4,3.1,13.9,7.3c3.5,4.2,5.4,9.9,4.7,15.4
						c-0.8,5.7-4.1,10.7-8.1,14.1c-4.1,3.4-9,5.5-13.8,6.4c-4.9,1-9.8,1-14.5,0.4c-4.7-0.6-9.3-1.9-13.8-3.5
						c-4.5-1.6-8.9-3.6-13.5-5.8c-4.5-2.2-9.2-4.6-14.3-6.8c-5.1-2.2-10.8-4.1-17.1-4.8c-3.1-0.3-6.4-0.3-9.8,0.1
						c-1.7,0.3-3.4,0.7-5,1.2c-1.7,0.6-3.3,1.3-4.9,2.2c1.6-0.8,3.3-1.5,5-2c1.7-0.5,3.4-0.8,5-1c3.3-0.3,6.6-0.2,9.6,0.3
						c6.2,0.9,11.5,3.2,16.4,5.6c4.9,2.4,9.4,5.1,13.8,7.7c4.4,2.5,8.8,4.9,13.3,7c4.6,2,9.3,3.8,14.4,4.8
						c5.1,1.1,10.6,1.5,16.4,0.8c5.7-0.7,11.9-2.6,17.5-6.7c2.7-2,5.3-4.5,7.5-7.6C521.4,362.6,523.1,358.9,523.9,354.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M447.8,308.1c-3.2-3.2-7.6-5.5-12.3-6.1c-4.7-0.7-9.8,0.3-13.9,3.4c-2,1.5-3.5,3.4-4.6,5.6c-1.1,2.1-1.7,4.5-1.7,7
						c0,0.6,0.1,1.2,0.1,1.8c0.1,0.6,0.2,1.2,0.4,1.8c0.3,1.2,0.9,2.2,1.4,3.2c1.2,1.9,2.7,3.4,4.5,4.5c1.8,1.1,3.7,1.7,5.7,1.8
						c1.9,0.1,3.9-0.4,5.4-1.3c1.5-0.9,2.6-2,3.3-3.1c0.8-1.1,1.2-2.3,1.5-3.3c0.5-2.1,0-3.9-0.6-4.9c-0.3-0.5-0.6-0.8-0.9-1
						c-0.3-0.2-0.5-0.2-0.7-0.1c-0.2,0.1-0.4,0.3-0.5,0.6c-0.1,0.3-0.2,0.6-0.4,1c-0.3,0.8-0.6,1.7-1.4,2.6c-0.7,0.9-1.9,1.8-3.1,2
						c-1.1,0.2-2.3-0.1-3.5-1.2c-0.6-0.5-1.1-1.2-1.4-2c-0.1-0.4-0.3-0.7-0.3-1.1c0-0.2,0-0.3,0-0.5c0-0.2,0-0.4,0-0.6
						c0.1-1.6,1.2-3.6,2.9-4.8c1.6-1.2,4-1.9,6.7-1.6c2.7,0.2,5.3,1.3,7.7,3.1c2.4,1.8,4.2,4.3,5.3,6.8c1.1,2.5,1.5,5.1,1.3,7.6
						c-0.3,5-2.7,9.6-6.2,12.9c-3.5,3.3-8.6,5.3-14.4,5.7c-5.9,0.5-12.6-0.9-20.1-3.5c7.3,3.1,13.9,5,20.1,5.2
						c6.2,0.2,12-1.1,16.8-4.5c2.4-1.7,4.5-3.8,6.2-6.3c1.7-2.5,3.1-5.4,3.7-8.8C456.1,323.3,454.5,314.8,447.8,308.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M458.9,328.3c-2.4,2.5-3.5,5.9-3.3,8.5c0.2,2.7,1.6,4.5,3.3,4.7c1.8,0.2,3.4-0.9,4.8-2.5c1.3-1.6,2.3-3.7,2.8-6.3
						c0.5-2.7,1-4.7,1.8-6.7c0.8-1.9,1.8-3.8,4.1-6.1c-2.9,1.3-4.9,2.4-6.9,3.5C463.4,324.6,461.4,325.8,458.9,328.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M463.1,362.7c0.5,3.2,2.7,5.9,5,6.9c2.3,1.1,4.4,0.7,5.5-0.7c1-1.4,1-3.4,0.6-5.1c-0.5-1.8-1.4-3.3-3.1-4.8
						c-1.7-1.6-3-3-3.9-4.5c-1-1.6-1.8-3.4-1.9-6.2c-0.9,2.7-1.4,4.7-1.9,6.8C462.9,357.2,462.6,359.4,463.1,362.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M490.6,369c1.7,0.6,3.5,0.7,5.2,0.5c1.7-0.3,3.1-0.9,4.2-1.7c2.3-1.7,2.9-3.9,2-5.4c-0.9-1.5-2.7-2.2-4.6-2.6
						c-1.9-0.3-3.8-0.2-6.1,0.5c-2.3,0.7-4.3,0.9-6.3,0.8c-2-0.2-4-0.7-6.5-2.5c1.7,2.6,3.2,4.3,4.9,5.9
						C485.2,366,487.1,367.7,490.6,369z"
                    />
                  </g>
                  <g>
                    <path
                      d="M448.5,349.2c-1.6-0.6-2.7-0.5-4,0.1c-1.3,0.6-2.7,1.6-3.5,3.2c-0.4,0.8-0.5,1.7-0.2,2.5c0.2,0.9,0.8,1.7,1.7,2.4
						c0.9,0.7,2.1,1.2,3.5,1.3c1.3,0.1,2.8-0.1,4.1-0.8c2.7-1.5,3.9-3.1,5-4.5c1.1-1.5,1.9-3.1,2.9-5.2c-1.8,1.5-3.4,1.9-4.9,1.9
						C451.6,350.1,450.2,349.9,448.5,349.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M441,389.9c-1.2,1.3-3,2.2-4.8,2.4c-1.7,0.3-3.6,0-5.4-0.8c-1.8-0.7-3.5-2-4.7-3.3c-1.2-1.3-1.9-2.6-2-3.5
						c-0.2-1,0.1-2.1,0.4-2.7c0.3-0.6,0.9-1,1.7-1.1c0.8-0.1,1.5,0,2.1,0.2c0.6,0.2,1.1,0.4,1.5,0.3c0.4-0.1,0.8-0.6,0.6-1.7
						c-0.2-1-1-2.4-2.7-3.4c-0.8-0.5-1.9-0.8-3.2-0.9c-1.3-0.1-2.7,0.3-4,1.1c-2.7,1.6-4.5,4.5-4.8,8c-0.3,3.6,1.1,7,3.1,9.7
						c2,2.7,4.7,4.9,8,6.4c3.3,1.5,7.3,2.2,11.4,1.2c3.9-0.9,7.6-3.3,9.9-6.8c0.6-0.9,1.1-1.9,1.4-2.9c0.4-1,0.6-2,0.7-2.9
						c0.3-1.9,0.2-3.7-0.1-5.3c-0.6-3.2-1.9-5.9-3.3-8.1c-1.5-2.2-3.2-4.1-5-5.6c-1.8-1.5-3.7-2.8-5.7-3.8c-4-2.1-8.4-3.3-13.2-3.8
						c-4.9-0.5-10.3-0.3-16.5,0.5c12.6-0.7,21.4,1.7,27.8,6.5c3.2,2.4,5.8,5.4,7.4,9.2c0.8,1.9,1.3,3.9,1.3,5.9
						C442.8,386.8,442.3,388.7,441,389.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M457.3,391.4c0,2.2,1.1,3.9,2.5,4.7c1.5,0.9,3.3,0.9,4.8,0c1.5-0.9,2.4-2.4,2.6-4c0.2-1.5-0.4-3.1-1.9-4.3
						c-1.5-1.2-2.7-2.1-3.9-3c-1.1-0.9-2.1-2-3.1-3.7c0,2-0.2,3.5-0.4,5C457.7,387.6,457.4,389.2,457.3,391.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M431.7,410.6c-2.8,1.3-4.6,3.9-5.2,6.2c-0.6,2.3,0.1,4.3,1.6,5.2c1.5,0.9,3.5,0.6,5.2-0.3c1.7-0.9,3.3-2.4,4.4-4.7
						c1.1-2.3,2-4,3.1-5.6c1.1-1.6,2.4-3.1,4.8-4.7c-2.7,0.6-4.7,1-6.8,1.5C436.6,408.7,434.5,409.2,431.7,410.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M389.6,226.7c1.7,0.3,3.4,0.1,5-0.1c1.5-0.3,2.9-0.8,4-1.3c2.2-1.1,3.4-2.3,3.2-3.1c-0.3-0.9-2-1.1-4.3-0.9
						c-2.3,0.2-5.1,0.7-7.8,1.1c-2.8,0.4-4.9,0.2-6.8-0.5c-1-0.3-1.9-0.8-2.9-1.6c-0.9-0.7-1.9-1.8-2.6-3.3c0.4,1.6,1.1,2.9,1.9,3.9
						c0.8,1,1.7,1.9,2.6,2.6c0.9,0.7,2,1.4,3.2,1.9C386.4,226,387.9,226.5,389.6,226.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M392.5,238.2c1.9,2.5,3.7,4.8,5.3,6.6c1.6,1.7,2.9,2.9,3.7,2.5c0.8-0.3,0.8-2.2-0.1-4.7c-1-2.4-3-5.4-5.8-7.5
						c-2.9-2.2-5-3.2-7.2-4.2c-2.2-1-4.4-1.9-7.8-2.8c3.1,1.5,5,2.9,6.7,4.4C389.1,234,390.6,235.6,392.5,238.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M351.7,217.7c-0.7,2.4-1.3,4.7-1.7,6.6c-0.4,1.9-0.5,3.4,0.2,3.9c0.7,0.5,2.2-0.2,3.6-2.1c1.4-1.8,2.4-4.8,2.3-7.9
						c-0.2-3.1-0.8-5.1-1.4-7.1c-0.7-1.9-1.5-3.8-3.1-6.3c1,2.7,1.2,4.7,1.2,6.6C352.7,213.3,352.5,215.2,351.7,217.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M446.3,284.3c3.7-0.6,7.2-2.4,9.4-4.3c2.2-1.9,3-3.7,2.5-4.4c-0.6-0.7-2.4-0.1-4.7,0.8c-2.3,1-5.3,2.3-8.3,3.6
						c-3.2,1.4-5.5,2-7.9,2.5c-2.4,0.4-5,0.7-8.7,0.1c3.6,1.1,6.2,1.5,8.8,1.8C439.8,284.8,442.4,284.9,446.3,284.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M455.1,296.1c2.5,0.2,5-0.7,6.5-1.9c1.5-1.2,2-2.6,1.4-3.3c-0.6-0.7-1.8-0.7-3.3-0.4c-1.5,0.2-3.2,0.7-5.1,1.3
						c-1.9,0.6-3.4,0.9-4.9,0.9c-1.5,0-3.1-0.1-5.3-1.1c1.9,1.4,3.3,2.3,4.9,3C450.8,295.2,452.5,295.8,455.1,296.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M512.7,278.9c-0.8,1.7-2.2,3.2-3.7,4.4c-1.5,1.2-3.3,2.3-4.8,3.4c-1.5,1.1-2.8,2.1-3.5,3c-0.8,0.9-1,1.6-0.8,2
						c0.3,0.4,1,0.3,2,0.1c1.1-0.2,2.5-0.7,4.2-1.4c1.7-0.7,3.8-1.6,5.9-3.2c2-1.6,4-3.9,4.8-6.9c0.4-1.5,0.4-3,0.2-4.4
						c-0.3-1.3-0.8-2.5-1.4-3.4c-1.2-1.9-2.8-3.1-4.4-4c-3.3-1.8-7-2.2-12.2-2.6c2.6,0.5,4.7,0.9,6.5,1.6c1.8,0.7,3.3,1.5,4.5,2.6
						c1.2,1.1,2.2,2.4,2.8,3.9C513.5,275.5,513.5,277.2,512.7,278.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M421,118.9c2.1-3.8,2.7-8.3,2.3-11.5c-0.4-3.3-1.6-5.2-2.4-5c-0.9,0.2-1.3,2.2-1.8,4.9c-0.5,2.8-1.2,6.3-2.2,9.9
						c-1.1,3.7-2.3,6.2-3.8,8.5c-1.5,2.3-3.4,4.6-6.9,6.9c3.8-1.9,6.2-3.7,8.4-5.7C416.7,125,418.8,122.8,421,118.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M423,162.8c5.4,0.3,10.9,0.4,15,0.3c4.1-0.1,6.9-0.6,7-1.5c0.1-0.9-2.6-2-6.7-2.7c-4.2-0.7-9.8-1.1-15.4-0.5
						c-5.7,0.6-9.6,1.3-13.5,2.1c-3.9,0.8-7.8,1.6-13.5,3c5.8-0.8,9.8-1,13.7-1.1C413.6,162.4,417.4,162.5,423,162.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M428.4,172.9c6.4,1.8,13.2,1.4,13.5-0.3c0.2-1.7-6.2-4-12.9-4c-6.9,0-9.5,0.3-16.5-0.1
						C419.4,169.9,421.8,171,428.4,172.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M465.2,185.4c3.3-1.4,6.4-3,8.7-4.4c2.3-1.4,3.8-2.7,3.5-3.5c-0.3-0.8-2.3-1-5.1-0.3c-2.8,0.7-6.3,2.2-9.3,4.5
						c-3.1,2.4-5,4.3-6.8,6.1c-1.8,1.9-3.7,3.9-6.2,6.8c2.9-2.6,5.1-4,7.4-5.3C459.5,188,461.8,186.9,465.2,185.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M479.4,189.5c3.5-0.2,7-0.5,9.6-1c2.6-0.4,4.5-1,4.6-1.9c0.1-0.9-1.7-1.8-4.6-2.3c-2.8-0.5-6.7-0.3-10.4,0.8
						c-3.7,1.2-6.2,2.3-8.5,3.5c-2.4,1.2-4.7,2.5-8,4.6c3.5-1.6,6.1-2.3,8.6-2.8C473.3,190.1,475.8,189.7,479.4,189.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M496,177.4c5.3-1.4,9.4-5.2,8.5-6.8c-0.9-1.5-6-0.2-10.3,2.8c-2.2,1.5-3.8,2.6-5.3,3.7c-1.6,1-3.2,2-5.8,3.2
						c2.7-0.7,4.6-1.1,6.5-1.5C491.4,178.5,493.3,178.1,496,177.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M508.6,181.3c2.3,0.5,4.6,0.6,6.3,0.4c1.8-0.2,3.1-0.7,3.3-1.5c0.2-0.9-0.9-1.9-2.7-2.6c-1.8-0.7-4.4-1.1-6.9-0.6
						c-2.6,0.4-4.3,1-6,1.5c-1.8,0.6-3.6,1.2-5.8,2c2.6-0.3,4.3-0.3,6-0.1C504.5,180.5,506.2,180.8,508.6,181.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M515.5,191.4c5.5,1.8,11.5,1.5,11.8-0.3c0.3-1.7-5.2-4-11-4.1c-6-0.1-8.3,0.3-14.4-0.2
						C507.8,188.4,509.8,189.5,515.5,191.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M537.8,184.7c2.8,0.1,5.6-0.6,7.4-1.6c1.9-1,2.9-2.2,2.6-3c-0.3-0.8-1.8-1.1-3.7-1c-2,0.1-4.4,0.5-6.9,1.3
						c-2.5,0.8-4.3,1.3-6.2,1.7c-1.9,0.4-3.8,0.6-6.6,0.6c2.8,0.6,4.7,1,6.6,1.3C533,184.3,535,184.6,537.8,184.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M537.5,176.3c2.8-0.1,5.5-1.1,7.4-2.2c1.8-1.1,2.8-2.4,2.4-3.2c-0.4-0.8-1.9-1-3.8-0.7c-2,0.2-4.4,0.9-6.9,1.9
						c-2.5,1-4.3,1.6-6.1,2.2c-1.8,0.5-3.7,1-6.6,1.2c2.9,0.3,4.8,0.5,6.8,0.7C532.6,176.2,534.6,176.4,537.5,176.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M535.8,166.5c2.7-0.6,5.2-1.8,6.8-3c1.7-1.3,2.5-2.6,2.1-3.4c-0.8-1.6-6.1-0.4-10.6,2.3c-2.3,1.4-4,2.4-5.6,3.4
						c-1.7,0.9-3.4,1.8-6.1,2.9c2.8-0.5,4.7-0.8,6.7-1C531.1,167.3,533,167.1,535.8,166.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M557.3,160.8c1.9-0.2,3.7-0.8,5-1.5c1.3-0.7,2.2-1.5,2.2-2.4c-0.1-0.9-1.2-1.6-2.9-1.7c-1.7-0.2-3.9,0.3-5.8,1.5
						c-1.9,1.2-3.1,2.3-4.2,3.4c-1.1,1.1-2.2,2.1-3.7,3.7c1.8-1.2,3.2-1.7,4.6-2.1C553.9,161.3,555.3,161,557.3,160.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M366.2,478.6c3.4-1,6.8-2.1,9.3-3.2c2.5-1.1,4.2-2.1,4-3c-0.1-0.9-2.1-1.3-5-1.1c-2.9,0.3-6.7,1.3-10,3.2
						c-3.4,2-5.6,3.6-7.7,5.3c-2.1,1.7-4.2,3.4-7,6.1c3.2-2.3,5.6-3.5,8-4.5C360.2,480.5,362.6,479.6,366.2,478.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M377.5,483.6c2.3,0.1,4.5-0.2,6.2-0.7c1.7-0.5,2.9-1.2,2.9-2.1c0-0.9-1.2-1.7-3.1-2.1c-1.9-0.4-4.4-0.3-6.8,0.6
						c-2.4,0.9-4,1.7-5.6,2.5c-1.7,0.9-3.3,1.8-5.3,2.9c2.4-0.7,4.2-1,5.9-1.1C373.5,483.5,375.1,483.5,377.5,483.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M400.9,489.4c2.5,0.8,5,1.3,7,1.3c2,0.1,3.5-0.3,3.7-1.1c0.3-0.8-0.8-2-2.7-2.9c-1.9-0.9-4.6-1.6-7.4-1.6
						c-2.9,0-4.8,0.3-6.8,0.5c-2.1,0.2-4.1,0.5-6.7,0.8c2.8,0.2,4.7,0.6,6.6,1.1C396.6,487.9,398.4,488.5,400.9,489.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M404,480.2c5.8,1.9,12.1,1.7,12.4,0c0.3-1.7-5.5-4.1-11.6-4.3c-6.3-0.2-8.7,0.1-15.2-0.5
						C395.8,477.1,398,478.2,404,480.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M405.2,471.3c1.9,0,3.8-0.8,4.9-1.8c1.1-1,1.6-2.1,1.2-2.9c-0.4-0.8-1.5-1.1-2.8-1c-1.3,0.1-2.9,0.5-4.3,1.4
						c-1.5,0.9-2.6,1.5-3.8,2c-1.2,0.5-2.4,0.9-4.3,1.2c1.7,0.3,3,0.5,4.5,0.7C401.8,471.2,403.2,471.4,405.2,471.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M326.4,598.7c3.6-0.2,7.2-0.8,10.9-1.8c3.6-1,7.3-2.7,10.5-5.2c1.6-1.3,3.1-2.8,4.3-4.4c1.2-1.7,2.2-3.5,3-5.4
						c1.5-3.8,2.1-7.8,1.7-11.8c-0.2-2-0.7-3.9-1.4-5.8c-0.7-1.9-1.7-3.6-2.8-5.1c-2.2-3.1-5.1-5.5-8.2-7.3c-1.6-0.8-3.2-1.5-4.9-2
						c-1.7-0.5-3.4-0.7-5.1-0.8c-3.4-0.1-6.7,0.5-9.7,1.8c-2.9,1.4-5.5,3.4-7.2,6.1c-1.7,2.6-2.4,5.5-2.4,8.1c0,2.7,0.8,5.3,2.2,7.2
						c0.7,1,1.5,1.8,2.4,2.5c0.9,0.6,1.8,1.1,2.7,1.4c3.6,1.3,6.6,0.5,8.1-0.6c1.6-1.1,1.9-2.1,1.6-2.4c-0.3-0.4-1.2-0.1-2.5,0
						c-0.7,0-1.5,0-2.4-0.2c-0.9-0.2-1.9-0.6-2.8-1.2c-0.9-0.6-1.7-1.5-2.1-2.5c-0.5-1.1-0.6-2.4-0.4-3.8c0.2-1.4,0.8-2.9,1.8-4.1
						c0.9-1.1,2.4-2.1,4.1-2.7c1.7-0.6,3.8-0.8,5.8-0.6c1,0.1,2,0.3,3,0.7c1,0.4,2,0.8,2.9,1.4c1.9,1.2,3.6,2.7,4.9,4.6
						c1.3,1.9,2,4,2.3,6.3c0.2,2.3-0.1,4.8-1,7.1c-0.4,1.1-1,2.2-1.7,3.2c-0.7,1-1.5,1.8-2.5,2.7c-3.9,3.3-9.9,5-16.1,5.8
						c-6.3,0.8-12.3,0.4-17.8-0.8c-5.5-1.2-10.3-3.2-14.8-5.6c-9-4.9-16.6-10.8-25.2-15.5c-8.6-4.7-18.1-7.9-29-9.2
						c-5.4-0.6-11.2-0.8-17.4-0.2c-6.1,0.6-12.7,2-19.4,4.5c6.8-2.2,13.3-3.3,19.4-3.6c6.1-0.3,11.8,0.2,17.1,1.1
						c10.5,1.9,19.4,5.7,27.3,10.8c3.9,2.6,7.7,5.5,11.5,8.5c3.9,3,7.9,6.1,12.5,9.1c4.6,3,9.9,5.7,16,7.5
						C312.1,598.2,319,599.2,326.4,598.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M279.9,561.6c4.2-1.3,8.3-3.6,11.6-6.8c1.7-1.6,3.1-3.5,4.3-5.6c1.2-2.1,2-4.4,2.3-7c0.7-5.2-1.5-10.8-6.2-13.8
						c-2.4-1.4-4.9-1.9-7.1-1.8c-2.2,0.1-4.1,0.5-6,1.3c-1.8,0.8-3.5,2-4.9,3.6c-1.3,1.6-2.1,3.7-2.1,5.7c0,3.9,2.4,6.6,4.7,7.6
						c2.3,1,4.2,0.5,5.3-0.1c1.1-0.6,1.3-1.3,1.2-1.6c-0.2-0.4-0.8-0.6-1.5-0.9c-0.7-0.3-1.7-0.8-2.4-1.6c-0.3-0.4-0.5-0.8-0.6-1.4
						c-0.1-0.5-0.1-1.1,0.1-1.5c0.2-0.4,0.5-0.7,1-1.1c0.5-0.3,1.2-0.7,2.1-0.9c0.9-0.2,2-0.3,2.9-0.2c1,0.1,1.7,0.4,2.2,0.8
						c0.5,0.4,0.9,1,1.2,1.8c0.2,0.4,0.3,0.9,0.3,1.3c0,0.4,0,0.9,0,1.5c-0.3,2.1-1.7,4.8-3.7,7c-2,2.2-4.7,4.1-7.8,5.4
						c-3.1,1.4-6.4,2.2-9.5,2.6c-3.2,0.4-6.2,0.5-9.2,0.4c-5.9-0.1-11.4-0.8-17-1.1c-5.6-0.3-11.4-0.3-17.6,0.8
						c-6.2,1.1-13,3.1-20.3,7c7.5-3.4,14.4-4.8,20.5-5.3c6.2-0.4,11.6,0.2,17,1.3c5.4,1,10.7,2.4,16.9,3.4c3.1,0.5,6.5,0.9,10.2,0.9
						C271.4,563.3,275.5,563,279.9,561.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M303.9,501.7c1.1-5.3,2.3-10.4,3.1-14.4c0.8-4.1,1.2-7.3-0.3-8.3c-1.4-1-4.6,0.8-7.4,4.9c-1.4,2.1-2.6,4.7-3.4,7.7
						c-0.8,3-1.1,6.3-0.8,9.6c0.3,3.4,0.9,6.2,1.7,8.6c0.7,2.4,1.6,4.5,2.6,6.5c1,2,2,4,3.3,6.1c1.3,2.1,2.8,4.3,4.9,6.8
						c-1.6-2.8-2.6-5.2-3.3-7.5c-0.7-2.3-1.1-4.3-1.3-6.4c-0.2-2.1-0.2-4.1-0.1-6.3C303,506.9,303.3,504.5,303.9,501.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M320.9,499.7c0.3-3.4-0.1-6.9-1.1-10c-0.9-3.1-2.4-5.8-4.1-7.9c-1.6-2.1-3.4-3.4-4.9-4.1c-1.5-0.7-2.6-0.7-3.3-0.1
						c-1.3,1.3-0.3,4.3,0.8,8.2c0.6,2,1.2,4.2,1.9,6.6c0.6,2.4,1.3,5,1.8,7.7c0.6,2.8,0.8,5.2,0.9,7.5c0.1,2.3,0,4.4-0.3,6.5
						c-0.5,4.2-1.6,8.5-4.5,14.4c3.8-5.3,6.1-9.2,8.1-13.3c1-2.1,1.9-4.2,2.7-6.7C319.9,506,320.6,503.2,320.9,499.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M312.1,497.4c0.7-3.6,0.6-7.3,0-10.1c-0.6-2.7-1.6-4.4-2.5-4.3c-0.9,0.1-1.4,1.9-1.7,4.6c-0.3,2.6-0.4,6-0.2,9.5
						c0.2,3.6,0.2,6.1,0.2,8.6c-0.1,2.5-0.3,5.1-1.2,8.8c1.3-3.6,2.2-6,3-8.5C310.5,503.6,311.3,501.1,312.1,497.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M310,493.7c0.9-3.1,1-6.3,0.6-8.7c-0.4-2.4-1.2-4-2.1-3.9c-0.9,0-1.6,1.6-2.1,3.8c-0.5,2.3-0.8,5.2-0.8,8.3
						c0,3.1,0,5.3-0.2,7.5c-0.2,2.2-0.4,4.4-1.2,7.6c1.2-2.9,2.1-5,3.1-7.2C308.2,499,309,496.9,310,493.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M302.6,488.7c1.5-2.6,2.9-5.1,3.9-7.2c1-2,1.6-3.6,1-4.3c-0.6-0.6-2.4,0-4.3,1.7c-1.9,1.8-3.9,4.8-4.7,8.1
						c-0.8,3.5-0.9,5.9-0.8,8.3c0.1,2.4,0.3,4.7,1,8.1c-0.2-3.4,0.2-5.7,0.8-7.9C300.3,493.4,301.1,491.4,302.6,488.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M305.8,559.5c1.5-1.7,2.7-3.7,3.5-5.7c0.8-2,1.3-4,1.4-5.7c0.3-3.4-0.5-5.7-1.4-5.7c-0.9,0-1.6,2-2.7,4.8
						c-1.1,2.8-2.5,6.3-4.4,9.7c-2,3.5-3.9,5.6-6.1,7.6c-2.2,1.9-4.8,3.6-9.1,4.9c4.4-0.8,7.4-1.9,10.1-3.3c1.4-0.7,2.7-1.5,4.2-2.5
						C302.7,562.6,304.2,561.3,305.8,559.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M341,541.1c4.9-0.6,9.7-1.1,13.4-1.5c3.7-0.4,6.3-0.9,6.4-1.8c0.1-0.8-2.4-2-6.4-2.4c-3.9-0.5-9.3-0.1-14.4,1.4
						c-5.2,1.6-8.5,3.3-11.7,5c-3.2,1.8-6.4,3.7-10.8,6.9c4.6-2.8,8.1-4.1,11.5-5.2C332.6,542.5,336,541.7,341,541.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M361.1,546c3.3,0.2,6.6,0,9.1-0.3c2.5-0.4,4.3-1,4.3-1.9c0.1-0.9-1.6-1.8-4.2-2.3c-2.6-0.5-6.1-0.6-9.6,0.1
						c-3.5,0.7-5.9,1.5-8.2,2.3c-2.5,0.8-4.8,1.6-8.1,2.8c3.6-0.7,6-0.8,8.5-0.9C355.2,545.7,357.6,545.8,361.1,546z"
                    />
                  </g>
                  <g>
                    <path
                      d="M368.5,552.6c2.6,0.8,5.2,1.2,7.2,1.2c2,0,3.6-0.3,3.8-1.2c0.2-0.8-0.9-2-2.8-2.9c-2-0.9-4.8-1.6-7.6-1.5
						c-2.9,0.1-4.9,0.4-6.9,0.7c-2.1,0.3-4.2,0.7-6.9,1.1c2.9,0.1,4.9,0.4,6.8,0.8C364,551.3,365.9,551.8,368.5,552.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M371.3,563.5c1.5,1.5,2.9,2.8,4,3.8c1.2,1,2.2,1.7,3.1,1.5c0.8-0.3,1.2-1.8,0.4-3.8c-0.7-2-2.7-4.1-5.1-5.1
						c-2.5-1.1-4.2-1.3-6-1.6c-1.7-0.3-3.4-0.5-5.9-1c2.3,1,3.8,1.8,5.2,2.7C368.4,561,369.8,562,371.3,563.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M370.1,580.9c0.2,2.4,0.6,4.7,1.1,6.5c0.5,1.8,1.1,3.1,2,3.2c0.9,0.1,1.8-1.2,2.2-3.3c0.4-2.1,0.2-5-1-7.6
						c-1.2-2.6-2.4-4.2-3.6-5.8c-1.2-1.5-2.5-3-4.4-5c1.5,2.3,2.2,4.1,2.7,5.8C369.5,576.6,369.8,578.4,370.1,580.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M364.6,598.8c-0.5,1.7-0.6,3.5-0.3,4.8c0.2,1.4,0.8,2.4,1.7,2.6c0.9,0.2,1.8-0.6,2.5-2c0.6-1.4,1-3.4,0.6-5.3
						c-0.4-2-0.9-3.3-1.3-4.7c-0.5-1.3-0.9-2.6-1.5-4.5c0.1,2-0.1,3.3-0.4,4.7C365.5,595.7,365.1,597,364.6,598.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M353.3,601c-3.3,3.3-5.1,7.7-3.7,8.8c1.4,1.1,5.4-1.9,7.3-6.4c1.9-4.6,2.2-6.5,4.2-11.1
						C358.2,596.4,356.7,597.5,353.3,601z"
                    />
                  </g>
                  <g>
                    <path
                      d="M340.2,602.7c-2.6,1.1-5,2.5-6.7,3.8c-1.7,1.3-2.7,2.6-2.3,3.4c0.4,0.8,2,0.9,4.2,0.3c2.2-0.6,4.8-1.9,7.1-3.8
						c2.3-2,3.8-3.5,5.2-5c1.5-1.5,2.9-3,5.1-5.1c-2.5,1.7-4.4,2.7-6.2,3.6C344.8,600.8,342.9,601.6,340.2,602.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M316.4,606.9c-2.8,0-5.6,0.4-7.6,1c-2.1,0.6-3.5,1.4-3.5,2.2c0,0.9,1.5,1.6,3.7,1.9c2.2,0.3,5.2,0.1,8.1-0.9
						c2.9-1,4.8-1.9,6.7-2.8c2-1,4-1.9,6.6-3.1c-3,0.8-5,1.1-7.1,1.3C321.3,606.8,319.3,606.9,316.4,606.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M233,569.1c5.4,0.9,10.6,2.2,14.6,3.2c4,1,6.8,1.7,7.2,0.8c0.4-0.7-1.8-2.8-5.8-4.8c-4-1.9-9.8-3.5-15.7-3.6
						c-6,0-10.1,0.7-14.1,1.6c-4,0.9-8,2.1-13.5,4.5c5.7-1.9,9.8-2.4,13.7-2.6C223.5,568,227.4,568.2,233,569.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M251.9,584.4c1.8,1.9,3.6,3.7,5.1,5.1c1.5,1.3,2.8,2.2,3.6,1.8c0.8-0.4,0.8-2.1-0.2-4.2c-1-2.1-3.1-4.6-5.8-6.1
						c-2.8-1.5-4.8-2.2-6.9-2.7c-2.1-0.5-4.1-0.9-7.1-1.1c2.9,0.8,4.7,1.8,6.4,2.9C248.6,581.1,250.1,582.4,251.9,584.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M68,396c1.6-0.2,3.2-0.3,4.9-0.5c1.6-0.1,3.3-0.2,4.8-0.2c3.1,0,6.1,0.2,8.8,0.7c2.7,0.5,5.1,1.4,6.9,2.6
						c0.9,0.6,1.7,1.2,2.3,1.9c0.3,0.3,0.6,0.7,0.9,1.1c0.2,0.4,0.4,0.7,0.6,1.1c0.7,1.6,1.1,3.6,0.9,5.5c-0.1,1-0.3,1.9-0.6,2.8
						c-0.3,0.9-0.8,1.8-1.3,2.6c-1.1,1.7-2.5,3.2-4.2,4.4c-1.7,1.2-3.4,2.1-5.2,2.7c-1.8,0.6-3.7,0.8-5.3,0.7
						c-1.7-0.1-3.2-0.4-4.5-1c-2.5-1-4.3-3.3-5.3-5.4c-1-2.1-1.2-4.1-1.3-5.6c0-0.7-0.1-1.4-0.1-1.9c-0.1-0.5-0.2-0.9-0.5-1.1
						c-0.3-0.2-0.7-0.2-1.2,0c-0.5,0.2-1.1,0.7-1.8,1.5c-0.6,0.8-1.3,1.9-1.7,3.4c-0.4,1.4-0.7,3.2-0.5,5.1c0.1,1.9,0.7,4.1,1.7,6.3
						c0.3,0.5,0.6,1.1,0.9,1.6c0.4,0.5,0.7,1.1,1.2,1.6c0.5,0.5,0.9,1.1,1.4,1.5c0.3,0.2,0.5,0.5,0.8,0.7c0.3,0.2,0.6,0.4,0.8,0.7
						c2.3,1.8,5.2,3,8.2,3.7c3.1,0.7,6.4,0.7,9.7,0.2c3.3-0.6,6.8-1.8,9.9-3.6c3.1-1.9,6.1-4.4,8.5-7.6c1.2-1.6,2.3-3.4,3.1-5.4
						c0.8-2,1.4-4.1,1.7-6.3c0.6-4.4,0.1-9-1.8-13.5c-0.5-1.1-1.1-2.2-1.7-3.2c-0.7-1-1.4-2-2.2-2.9c-0.8-0.9-1.7-1.7-2.6-2.5
						c-0.5-0.4-0.9-0.7-1.4-1.1c-0.5-0.4-1-0.7-1.5-1c-4-2.5-8.2-3.7-12.3-4.3c-4.1-0.6-8.1-0.7-12-0.3c-1.9,0.2-3.8,0.4-5.6,0.7
						c-1.9,0.3-3.7,0.6-5.5,1c-7.6,1.7-14.2,4-20.2,6.7c-6,2.7-11.3,5.7-16.1,8.9c-4.8,3.2-9.1,6.5-13,10.1
						c-3.9,3.5-7.6,7.2-10.9,11c-6.7,7.7-12.6,16.1-18,26.2c-5.4,10-10.4,21.7-14.4,36.1c4.8-14.2,10.6-25.4,16.7-34.7
						c6.1-9.4,12.7-16.9,19.9-23.6c3.6-3.3,7.4-6.3,11.4-9.2c4-2.9,8.4-5.5,13.1-7.9c4.7-2.4,9.8-4.6,15.4-6.4
						C55.2,398.3,61.3,396.8,68,396z"
                    />
                  </g>
                  <g>
                    <path
                      d="M53,455.6c0.5,1.9,0.5,3.8,0.3,5.6c-0.3,1.9-0.8,3.7-1.7,5.4c-0.9,1.7-2,3.1-3.3,4.3c-0.6,0.6-1.3,1.1-2,1.6
						c-0.7,0.5-1.5,0.9-2.3,1.2c-3.2,1.4-6.9,1.6-10,0.9c-1.6-0.3-2.9-0.9-3.9-1.6c-1-0.7-1.6-1.3-2.1-2.1c-0.2-0.4-0.4-0.8-0.5-1.1
						c-0.1-0.3-0.2-0.6-0.1-0.9c0-0.6,0.3-1.5,1-2.3c0.7-0.9,1.6-1.7,2.6-2.4c0.5-0.3,1-0.7,1.5-1c0.5-0.3,0.9-0.5,1.3-0.8
						c0.5-0.3,1-0.6,1.4-0.9c0.2-0.2,0.7-0.2,1.1-0.2c0.4,0.1,0.7,0.2,1,0.4c0.3,0.2,0.5,0.5,0.8,0.6c0.3,0.1,0.7,0.1,1.1-0.4
						c0.4-0.4,0.7-1.4,0.4-2.5c-0.2-1.1-0.9-2.3-2.5-3.3c-1.5-0.9-3.3-1.1-5-1c-0.8,0.1-1.6,0.2-2.4,0.3c-0.8,0.1-1.6,0.3-2.5,0.6
						c-1.7,0.5-3.6,1.3-5.5,2.6c-1.9,1.3-4,3.4-5.2,6.4c-0.6,1.5-1,3.2-1.1,4.8c-0.1,1.7,0.1,3.3,0.6,5c0.5,1.6,1.2,3.2,2.1,4.6
						c0.9,1.5,2.1,2.7,3.3,3.8c2.5,2.2,5.4,3.8,8.4,4.8c6.1,2,13.1,1.9,19.6-0.7c3.2-1.3,6.4-3.2,9-5.8c2.6-2.5,4.7-5.6,6.2-8.9
						c1.5-3.3,2.4-6.9,2.5-10.6c0.2-3.7-0.5-7.5-1.8-10.8c-2.7-7-7.7-12-13.2-14.9c-5.6-2.9-11.3-3.4-16.2-3.1
						c-4.9,0.3-9.3,1.4-13.4,2.8c-4.1,1.3-7.9,3-11.5,4.9c-3.7,1.9-7.1,4.1-10.6,6.6c-3.4,2.5-6.7,5.3-9.9,8.6
						c-3.2,3.3-6.3,6.9-9.3,11.1c-2.9,4.2-5.7,9-8,14.5c2.6-5.3,5.7-9.8,9-13.7c3.2-3.9,6.6-7.1,10-9.9c3.4-2.8,6.9-5.1,10.4-7
						c3.5-1.9,7-3.5,10.6-4.8c3.6-1.2,7.3-2.3,11.1-2.9c3.8-0.7,7.7-1.1,11.5-0.8c3.8,0.3,7.6,1.4,10.5,3.5
						C49.6,448.2,52,451.7,53,455.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M121.8,361.1c10.9,1.6,21.5,3.9,29.6,5.9c0.5,0.1,1,0.2,1.5,0.3c0.5,0.1,1,0.2,1.4,0.4c0.9,0.2,1.8,0.4,2.6,0.6
						c0.8,0.2,1.6,0.3,2.4,0.5c0.7,0.2,1.4,0.3,2,0.4c2.5,0.4,4.2,0.3,4.6-0.5c0.4-0.8-0.5-2.1-2.5-3.9c-0.5-0.4-1.1-0.9-1.7-1.4
						c-0.6-0.5-1.4-0.9-2.1-1.4c-0.8-0.5-1.6-1-2.5-1.5c-0.5-0.2-0.9-0.5-1.4-0.8c-0.5-0.2-1-0.5-1.5-0.7
						c-8.1-3.8-19.9-6.9-31.8-6.7c-3.1,0.1-5.9,0.1-8.5,0.4c-1.3,0.1-2.6,0.2-3.8,0.4c-1.2,0.2-2.4,0.4-3.5,0.5
						c-1.1,0.2-2.3,0.3-3.3,0.5c-1.1,0.2-2.1,0.4-3.2,0.7c-1,0.2-2.1,0.4-3.1,0.7c-1,0.3-2,0.5-3,0.8c-1,0.3-2,0.5-3,0.8
						c-1,0.3-2,0.6-3,1c-1,0.3-2,0.6-3.1,1c-1,0.4-2.1,0.8-3.2,1.2c-1.1,0.4-2.2,0.8-3.3,1.3c-1.1,0.5-2.3,1-3.5,1.5
						c-0.6,0.3-1.2,0.5-1.8,0.8c-0.6,0.3-1.2,0.6-1.9,0.9c-1.3,0.6-2.6,1.2-4,1.9c1.4-0.5,2.8-1,4.1-1.5c0.7-0.2,1.3-0.5,1.9-0.7
						c0.6-0.2,1.3-0.4,1.9-0.6c1.2-0.4,2.4-0.7,3.6-1c1.2-0.3,2.3-0.5,3.4-0.8c1.1-0.2,2.2-0.5,3.3-0.7c1.1-0.2,2.1-0.3,3.2-0.5
						c1-0.1,2-0.3,3-0.4c1-0.1,2-0.2,3-0.3c1-0.1,2-0.2,3-0.2c1-0.1,2-0.1,3-0.1c1,0,2.1,0,3.1-0.1c1.1,0,2.1,0.1,3.2,0.1
						c1.1,0,2.2,0.1,3.4,0.1c1.2,0.1,2.3,0.2,3.6,0.3C116.4,360.4,119,360.8,121.8,361.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M120.9,378.3c10.8,0.9,21.8-0.5,29.7-2.7c7.9-2.2,12.8-5.1,12.3-6.8c-0.4-1.8-5.9-1.9-13.7-1.5
						c-7.8,0.4-18.1,1.3-28.4,2.2c-10.6,0.9-18,1.1-25.5,0.9c-7.5-0.3-15.1-1-26.1-3.4c10.7,3.5,18.2,5.4,25.6,7.2
						C102.3,375.9,109.8,377.4,120.9,378.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M132.4,371.1c6.3,1.3,12.7,1.4,17.5,0.7c4.8-0.7,8-2.3,8-4c0-1.8-3.1-3.3-7.8-4.4c-4.7-1-10.9-1.5-17.2-1
						c-6.5,0.5-10.9,0.9-15.5,1.2c-4.5,0.3-9.1,0.4-15.8,0c6.6,1.4,11,2.6,15.4,3.8C121.5,368.5,125.9,369.7,132.4,371.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M166.3,420.2c1,0,2.1,0,3.1-0.2c1-0.2,2.1-0.4,3.1-0.7c2-0.7,4-1.7,5.8-3.3c1.8-1.5,3.3-3.7,4-6.1
						c0.7-2.4,0.7-4.8,0.3-6.9c-0.4-2.1-1.2-4-2.1-5.7c-0.9-1.7-2-3.1-3.3-4.5c-1.3-1.3-2.8-2.4-4.5-3.3c-1.6-0.8-3.6-1.3-5.3-1.4
						c-3.5-0.2-6.9,1.1-9,3.3c-1.1,1.1-1.8,2.4-2.2,3.6c-0.4,1.2-0.5,2.3-0.4,3.3c0.2,2,1.1,3.4,1.9,4c0.8,0.7,1.5,0.7,1.8,0.4
						c0.3-0.3,0.4-0.9,0.5-1.6c0.1-0.7,0.2-1.6,0.7-2.4c0.4-0.9,1.2-1.8,2.1-2.1c0.9-0.4,2.3-0.6,3.6-0.2c1.3,0.4,2.6,1.3,3.9,2.8
						c1.2,1.5,2.3,3.6,2.6,5.5c0.2,0.9,0.1,1.7-0.1,2.3c-0.2,0.6-0.5,1.2-1.1,1.8c-0.6,0.6-1.5,1.2-2.5,1.7
						c-0.5,0.2-1.1,0.4-1.6,0.6c-0.6,0.2-1.1,0.3-1.8,0.4c-2.6,0.3-5.2,0-7.6-0.7c-2.4-0.7-4.7-1.7-6.8-2.9c-4.1-2.4-7.6-5.4-11-8.5
						c-3.5-3.1-6.9-6.5-11.2-9.7c-2.2-1.6-4.5-3.1-7.3-4.5c-2.7-1.3-5.9-2.4-9.3-2.6c3.5,0.5,6.4,1.9,8.9,3.4
						c2.5,1.5,4.7,3.3,6.5,5.1c3.8,3.6,6.6,7.3,9.5,11c3,3.7,6,7.4,10.3,11c2.2,1.8,4.6,3.4,7.6,4.8
						C158.6,419,162.2,420.1,166.3,420.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M130.6,425.7c-0.7,1.3-1.4,2.2-2.3,3c-0.9,0.7-1.7,1.2-2.5,1.4c-0.6,0.2-1.4,0.2-2.1,0c-0.7-0.2-1.2-0.4-1.5-0.7
						c-0.3-0.1-0.6-0.7-0.7-1.3c-0.1-0.6-0.1-1.3,0-1.9c0.1-0.6,0.3-1.1,0.4-1.6c0.2-0.5,0.3-1,0.2-1.4c-0.1-0.4-0.4-0.8-1.1-1
						c-0.7-0.2-1.8-0.1-2.9,0.5c-1.2,0.6-2.4,1.7-3.2,3.4c-0.8,1.6-1.3,4-0.4,6.6c0.8,2.4,2.7,4.6,5.1,5.9c2.4,1.3,5.3,1.8,8.3,1.2
						c2.9-0.6,5.4-2.2,7.2-4.2c1.8-2,3.1-4.6,3.7-7c0.6-2.5,0.6-4.8,0.4-6.8c-0.2-2-0.6-3.8-1-5.4c-0.9-3.3-2.2-6.1-3.7-8.6
						c-1.5-2.6-3.3-4.9-5.6-7.3c-1.1-1.2-2.4-2.3-3.8-3.5c-1.4-1.1-3.1-2.2-4.9-3.2c1.7,1.2,3.1,2.6,4.3,3.8c1.2,1.3,2.2,2.6,3,3.9
						c1.7,2.6,2.7,5.1,3.5,7.7c0.7,2.6,1.1,5.2,1.1,8C132.2,419.9,131.9,423.1,130.6,425.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M612.6,399.5c-0.7-3.6-1.9-7.1-3.5-10.4c-1.6-3.3-3.6-6.4-5.8-9.1c-2.2-2.7-4.6-4.8-6.8-6.8c-2.2-1.9-4.2-3.6-5.9-5.2
						c-3.5-3.1-6-6-7.6-8.2c-1.7-2.2-2.6-3.7-3.5-3.5c-0.8,0.1-1.5,2-0.9,5.4c0.3,1.6,0.9,3.6,1.9,5.6c1,2,2.4,4.2,4,6.3
						c3.3,4.3,7.7,8.3,11.3,12.7c3.5,4.3,6.4,9.6,8.4,15.4c1,3,1.7,5.9,2.1,8.6c0.4,2.7,0.7,5.4,0.7,7.9c0,5-0.5,9.6-1.6,14.1
						c-1.1,4.5-2.6,9-4.9,13.8c-2.3,4.8-5.4,9.9-9.8,15.6c4.8-5.3,8.4-10.1,11.3-14.7c2.9-4.6,5.2-9,7-13.5
						c1.8-4.6,3.3-9.3,4.1-14.8c0.4-2.8,0.6-5.7,0.6-8.9C613.7,406.6,613.4,403.2,612.6,399.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M580.2,404.8c-0.8-5.7-1-11.4-0.9-16.8c0.1-5.4,0.4-10.6,0.8-15c0.7-8.9,0.9-15.2-0.8-15.7c-1.7-0.4-4.9,5.1-7.5,14.3
						c-1.2,4.6-2.2,10.1-2.4,16.2c-0.2,6,0.4,12.6,2.2,18.9c1.9,6.5,4.3,11.6,7.1,15.9c1.4,2.2,2.8,4.1,4.3,5.9
						c1.4,1.8,2.9,3.4,4.2,5.1c2.6,3.3,5,6.9,5.9,11.6c0.2,1.2,0.4,2.4,0.4,3.7c0,1.3,0,2.6-0.2,4c-0.4,2.7-1.3,5.6-3,8.4
						c1.9-2.7,3.1-5.5,3.8-8.2c0.4-1.4,0.6-2.7,0.7-4.1c0.1-1.3,0.1-2.6,0.1-3.9c-0.1-2.5-0.7-5-1.5-7.2c-0.8-2.2-1.8-4.3-2.9-6.2
						c-2.2-3.9-4.5-7.5-6.2-11.7C582.4,415.5,581,410.7,580.2,404.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M593.6,391.6c-1-7.1-3.4-13.9-5.9-18.7c-2.5-4.8-4.9-7.5-5.7-7.1c-0.8,0.4,0.2,3.8,1.8,8.7c1.6,4.9,3.7,11.4,5.5,17.9
						c1.8,6.8,2.7,11.6,3.3,16.5c0.5,4.9,0.8,9.9,0.1,17.3c1.2-7.3,1.6-12.4,1.8-17.4C594.6,403.9,594.6,398.9,593.6,391.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M588.3,385.7c-0.4-5.3-1.8-10.5-3.4-14.1c-1.6-3.7-3.4-5.8-4.2-5.5c-0.8,0.3-0.6,3,0.2,6.7c0.7,3.8,1.9,8.6,3.1,13.5
						c1.2,5.1,1.9,8.6,2.3,12.2c0.4,3.6,0.7,7.4,0.3,12.8c0.9-5.4,1.3-9.2,1.6-12.9C588.5,394.8,588.7,391.1,588.3,385.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M583.8,385.6c0.2-8.5-1.7-16.9-3.5-16.8c-1.8,0.1-2.4,8.7-0.9,17.1c1.6,8.6,2.6,11.8,3.8,20.8
						C583,397.7,583.5,394.4,583.8,385.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M38.3,418.2c3.6-0.5,7.1-1.2,9.7-2c2.6-0.8,4.4-1.7,4.3-2.5c-0.1-0.9-2-1.5-4.9-1.6c-2.9-0.1-6.7,0.5-10.3,1.9
						c-3.7,1.5-6,2.8-8.4,4.1c-2.3,1.3-4.7,2.7-8,4.8c3.6-1.6,6.2-2.4,8.7-3C32.1,419.3,34.6,418.8,38.3,418.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M37.7,425.6c3.2-0.1,6.2-0.5,8.6-1.1c2.3-0.5,4-1.2,4-2.1c0-0.9-1.6-1.7-4.2-2.1c-2.6-0.4-6-0.1-9.3,0.9
						c-3.3,1.1-5.5,2.1-7.6,3.2c-2.1,1.1-4.2,2.2-7.3,3.9c3.3-1.2,5.6-1.8,7.9-2.1C32.2,425.9,34.5,425.7,37.7,425.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M59.5,432.6c2.4,0.8,4.7,1.4,6.6,1.6c1.9,0.2,3.4,0.1,3.8-0.7c0.4-0.8-0.5-2.1-2.3-3.2c-1.8-1.1-4.5-2-7.3-2
						c-2.9,0-4.8,0.3-6.8,0.7c-1.9,0.4-3.8,0.8-6.5,1.5c2.8-0.2,4.7,0,6.5,0.4C55.3,431.3,57.1,431.8,59.5,432.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M80.6,446.8c4,0.5,8.1-0.5,10.7-1.9c2.7-1.4,4-3.1,3.6-3.8c-0.4-0.8-2.4-0.5-5-0.1c-2.6,0.4-5.9,1-9.4,1.5
						c-3.6,0.4-6.1,0.3-8.7,0c-2.5-0.4-5.2-1.1-8.6-3.1c3.1,2.4,5.6,3.7,8.1,4.9C73.9,445.3,76.5,446.2,80.6,446.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M84.1,455.5c2.2,0,4.3-0.8,5.6-1.8c1.3-1,1.9-2.2,1.5-3c-0.4-0.8-1.6-1-3.1-0.9c-1.5,0.1-3.2,0.6-4.9,1.4
						c-1.8,0.8-3.1,1.4-4.4,1.8c-1.4,0.4-2.8,0.8-4.9,0.8c2.1,0.5,3.5,0.8,5,1.1C80.4,455.3,81.9,455.5,84.1,455.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-6.8,468.2c-3.8,3.9-6.3,9-7.3,13c-1,4-0.7,6.8,0.2,7c0.9,0.2,2.1-2.2,3.8-5.5c1.7-3.3,4-7.6,6.8-11.7
						c2.9-4.2,5.4-6.9,8.1-9.3c2.8-2.3,5.8-4.6,11-6.6c-5.3,1.5-8.8,3.2-12.1,5C0.3,462-2.9,464.1-6.8,468.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-0.6,480.6c-1,1.9-1.8,3.9-2.4,5.8c-0.3,1-0.5,1.9-0.7,2.8c-0.2,0.9-0.3,1.9-0.3,2.8c0,0.9,0.1,1.8,0.4,2.6
						c0.3,0.8,0.7,1.5,1.2,1.9c0.5,0.4,1,0.6,1.4,0.6c0.4,0,0.6-0.1,0.7-0.3c0.2-0.4-0.1-1,0-1.6c0-0.6,0.1-1.5,0.5-2.7
						c0.2-0.6,0.4-1.2,0.7-2.1c0.3-0.8,0.6-1.7,0.8-2.5c0.6-1.8,1.1-3.7,1.7-5.6c1.1-4,2.1-6.7,3.2-9.3c1.2-2.7,2.5-5.3,5-8.9
						c-2.9,3.2-4.8,5.6-6.7,7.9C3.3,474.3,1.5,476.7-0.6,480.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M7.5,492.9c-0.1,2.4,0.6,4.8,1.6,6.4c1,1.6,2.2,2.3,3,2c0.8-0.4,1.1-1.7,1-3.3c-0.1-1.6-0.5-3.6-1.2-5.6
						c-0.7-2.1-1.2-3.6-1.5-5.1c-0.3-1.6-0.5-3.2-0.3-5.6c-0.7,2.3-1.2,3.9-1.6,5.5C8.1,488.7,7.7,490.4,7.5,492.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M19.5,498.4c0.8,2,2.3,3.6,3.7,4.4c1.4,0.8,2.7,0.9,3.3,0.2c0.6-0.7,0.3-1.9-0.3-3.1c-0.6-1.3-1.7-2.7-3-3.9
						c-1.4-1.3-2.4-2.3-3.2-3.3c-0.9-1.1-1.7-2.2-2.5-4.2c0.3,2.1,0.5,3.5,0.7,4.9C18.5,494.9,18.7,496.4,19.5,498.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M36.3,500.3c1.6,0.8,3.4,0.8,4.7,0.4c1.3-0.4,2-1.3,2-2.2c-0.1-0.9-0.9-1.6-1.9-2c-1.1-0.5-2.4-0.7-3.8-0.5
						c-1.5,0.2-2.6,0.2-3.7,0.2c-1.1,0-2.3-0.2-3.8-0.8c1.1,0.9,2.1,1.7,3.2,2.6C33.7,498.7,34.7,499.5,36.3,500.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M173.3,453.4c1.6-3.6,2.3-7.6,2.2-11.5c-0.1-3.9-1-7.8-2.9-11.2c-1-1.7-2.2-3.2-3.6-4.5c-1.5-1.3-3.2-2.3-5.1-2.8
						c-3.8-1.1-7.5-0.3-10.2,1.3c-2.8,1.6-4.7,4.3-5.4,6.7c-0.8,2.5-0.6,4.7-0.2,6.4c0.5,1.7,1.2,2.9,1.8,3.6
						c0.6,0.7,1.1,0.9,1.2,0.8c0.4-0.3-0.5-1.9-0.7-4.8c0-1.4,0.2-3.2,1-4.9c0.8-1.7,2.2-3.3,4.2-4.2c2-1,4.6-1.3,6.8-0.5
						c2.3,0.8,4.4,2.7,5.8,5.3c1.4,2.6,2.2,5.7,2.4,8.9c0.2,3.2-0.3,6.6-1.4,9.7c-1.2,3.2-3,6-4.8,8.5c-1.9,2.5-3.8,4.8-5.6,7.1
						c-0.9,1.2-1.7,2.3-2.5,3.5c-0.4,0.6-0.7,1.2-1.1,1.8l-0.5,0.9l-0.5,0.9c-0.3,0.6-0.6,1.2-0.9,1.8c-0.3,0.6-0.5,1.2-0.8,1.8
						c-0.6,1.2-0.9,2.5-1.4,3.7c-0.8,2.5-1.4,5.1-1.8,7.8c-0.2,1.3-0.3,2.7-0.4,4.1c0,0.7,0,1.4,0,2.1c0,0.7,0,1.4,0,2.2
						c0.1,2.9,0.5,6,1.3,9.3c0.8,3.2,2,6.6,3.8,10c-1.7-3.5-2.7-6.9-3.4-10.1c-0.6-3.2-0.9-6.3-0.8-9.2c0-0.7,0-1.4,0.1-2.1
						c0.1-0.7,0.1-1.4,0.2-2.1c0.2-1.4,0.3-2.7,0.6-4c0.5-2.6,1.3-5,2.2-7.4c1.9-4.7,4.5-9,8.3-13.1c1.9-2.1,4-4.2,6.1-6.7
						C169.4,460,171.7,457.1,173.3,453.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M154.7,445.2c-2.1,1.9-3.9,4-5.1,5.8c-1.2,1.8-1.8,3.3-1.2,4c0.6,0.7,2.2,0.3,4.2-0.9c1.9-1.2,4.1-3.4,5.6-6.1
						c0.8-1.4,1.3-2.5,1.8-3.6c0.5-1.1,0.9-2.1,1.2-3.2c0.3-1.1,0.5-2.2,0.4-3.5c-0.1-1.2-0.5-2.6-1.7-3.7c0.9,1.3,1,2.6,0.8,3.7
						c-0.2,1.1-0.7,2-1.2,2.7c-0.6,0.8-1.2,1.5-2,2.3C156.6,443.3,155.7,444.2,154.7,445.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M157.3,453.9c-1.7,1.7-3.1,3.6-4,5.2c-0.9,1.6-1.2,3-0.6,3.7c0.6,0.6,2.1,0.3,3.7-0.9c1.6-1.1,3.4-3.1,4.4-5.5
						c1.1-2.4,1.7-4.2,2.2-6c0.6-1.9,1.1-3.7,1.8-6c-1.2,2.3-2.3,3.8-3.5,5.1C160.3,450.9,159.1,452.2,157.3,453.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M187.3,434.6c0.8-2.6,0.7-5.4,0.1-7.4c-0.6-2-1.7-3.1-2.5-2.9c-0.9,0.2-1.4,1.5-1.6,3.3c-0.3,1.8-0.4,4-0.3,6.3
						c0.1,2.4,0,4.2-0.2,5.9c-0.3,1.7-0.7,3.5-1.9,5.9c1.6-2.1,2.7-3.7,3.6-5.2C185.5,438.9,186.5,437.3,187.3,434.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M196,440.1c1.4-0.9,2.5-2.1,3.2-3.2c0.7-1.1,1-2.2,0.6-3c-0.5-0.7-1.7-0.9-3.1-0.4c-1.4,0.5-3,1.8-4,3.6
						c-1,1.8-1.4,3.2-1.8,4.5c-0.4,1.3-0.7,2.6-1.2,4.5c1-1.7,1.9-2.6,2.8-3.5C193.5,441.8,194.5,441.1,196,440.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M204.1,446c1.7-0.2,3.1-0.8,4.2-1.5c1.1-0.7,1.8-1.6,1.7-2.5c-0.1-0.9-1.2-1.5-2.7-1.6c-1.5-0.1-3.4,0.4-5,1.6
						c-1.6,1.2-2.5,2.2-3.5,3.2c-1,1.1-1.9,2.2-3,3.4c1.6-1,2.8-1.5,4-1.8C201.1,446.4,202.3,446.2,204.1,446z"
                    />
                  </g>
                  <g>
                    <path
                      d="M255.9,442.9c-2.8,2-5.5,3.9-7.6,5.6c-2,1.6-3.4,3-3,3.8c0.3,0.8,2.4,0.8,5.1-0.2c2.7-1,6-3.1,8.4-6
						c2.5-3,3.9-5.3,5.2-7.6c1.2-2.3,2.4-4.7,3.8-8.3c-1.9,3.4-3.5,5.4-5.3,7.2C260.7,439.2,258.8,440.8,255.9,442.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M258,452.6c-2.4,3.6-3.9,7.7-4.5,10.9c-0.5,3.2-0.2,5.5,0.6,5.6c0.9,0.2,2-1.7,3.4-4.4c1.3-2.7,2.9-6.4,4.3-10.1
						c1.5-3.8,2.8-6.4,4.3-9c1.5-2.5,3.1-5,6.1-8.3c-3.4,2.9-5.5,5-7.6,7.1C262.6,446.7,260.5,448.9,258,452.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M264.1,459.8c-1.9,3.8-3,8-3.2,11.2c-0.3,3.2,0.2,5.4,1,5.5c0.9,0.1,1.9-1.8,3-4.6c1.1-2.9,2.3-6.7,3.3-10.6
						c1-4,1.9-6.8,2.9-9.6c1-2.8,2.2-5.5,4.4-9.3c-2.7,3.5-4.4,6-6.1,8.5C267.7,453.4,266.1,455.9,264.1,459.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M266.4,475.3c-1.1,3.9-1.3,8-0.8,11c0.4,3,1.4,5,2.2,4.9c0.9-0.1,1.5-2,2-4.9c0.5-2.8,0.8-6.6,1-10.3
						c0.2-3.9,0.5-6.6,0.9-9.3c0.5-2.7,1.1-5.5,2.6-9.4c-2,3.6-3.2,6.2-4.4,8.8C268.6,468.7,267.5,471.3,266.4,475.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M332.1,491.8c-2.3,0.4-4.4,1.4-5.7,2.6c-1.3,1.2-1.9,2.4-1.4,3.2c0.5,0.8,1.8,0.9,3.4,0.5c1.6-0.3,3.5-1.1,5.3-2.2
						c1.9-1.2,3.2-2,4.6-2.7c1.4-0.7,2.9-1.4,5.1-2c-2.1,0-3.8,0.1-5.6,0.1C336.1,491.3,334.5,491.4,332.1,491.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M338.2,497.8c-2,0.5-2.5,2.8-1.3,4.1c1.2,1.3,3.4,1.1,4.2-0.8c0.8-1.9,0.9-3,2-4.7
						C341.2,497.3,340.2,497.3,338.2,497.8z"
                    />
                  </g>
                  <g>
                    <path d="M432.9,506.6c-3.6,0.1-6.5,2.6-5.9,4.2c0.7,1.6,4.1,1.7,7,0c3-1.8,4.1-2.8,7.6-4C438.1,507,436.6,506.6,432.9,506.6z" />
                  </g>
                  <g>
                    <path
                      d="M152,526.9c-2.4-3.5-5.6-6.8-9.9-9.1c-2.1-1.1-4.4-2-6.8-2.5c-2.4-0.5-5-0.6-7.6-0.1c-5.2,0.9-9.7,3.7-12.6,7.9
						c-0.7,1.1-1.3,2.2-1.8,3.4c-0.5,1.2-0.8,2.5-0.9,3.7c-0.3,2.4,0,4.7,0.7,6.8c0.7,2.1,1.8,4,3.3,5.5c1.5,1.5,3.3,2.6,5,3.2
						c3.6,1.1,6.6,0.4,8.6-0.7c2.1-1.1,3.2-2.7,3.5-3.9c0.2-0.6,0.2-1.1,0.1-1.5c-0.1-0.4-0.3-0.7-0.6-0.8c-0.3-0.2-0.6-0.3-1-0.3
						c-0.4,0-0.8,0-1.2-0.1c-0.8,0-1.8-0.1-2.7-0.4c-0.9-0.3-2-0.9-2.4-1.5c-0.2-0.3-0.3-0.7-0.4-1.1c-0.1-0.4-0.1-0.8-0.1-1.4
						c0-0.5,0.2-1,0.4-1.4c0.1-0.2,0.2-0.4,0.3-0.5c0.1-0.2,0.3-0.4,0.5-0.5c0.4-0.4,0.9-0.7,1.5-1c0.6-0.3,1.3-0.5,2-0.6
						c1.4-0.2,3.4,0.1,5.5,0.9c2,0.8,4.2,2.4,6.2,4.4c1.1,1,2.1,2.2,3,3.4c0.9,1.2,1.8,2.3,2.6,3.5c1.5,2.4,2.8,4.7,3.8,7.1
						c2,4.7,3.3,9.3,3.7,14.1c0.5,4.8,0.2,9.7-1.1,15.2c-1.3,5.4-3.8,11.3-8.4,17.4c5.2-5.5,8.6-11.1,10.9-16.5
						c2.3-5.4,3.7-10.6,4.3-15.9c0.6-5.3,0.5-10.8-0.6-16.8c-0.6-3-1.4-6.1-2.7-9.4c-0.7-1.6-1.4-3.3-2.3-5
						C154.2,530.4,153.2,528.7,152,526.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M180.5,567.9c3.9-1.9,7.3-4.1,9.8-6.3c2.5-2.1,4.1-4.2,3.6-5.9c-0.5-1.6-3.3-2.4-7.1-1.7c-3.7,0.7-8.4,3.2-11.7,7
						c-3.4,3.9-5.2,7-6.8,10c-1.6,3.1-3.1,6.2-4.9,10.7c2.8-4.1,5.3-6.3,7.8-8.3C173.8,571.6,176.5,569.9,180.5,567.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M174.5,542.4c0.4-3.8-1-7.4-2.8-9.6c-1.9-2.2-4.1-3-5.6-2.2c-1.6,0.9-2.2,2.9-2.2,5.3c0,2.4,0.6,5.1,1.9,7.8
						c1.3,2.9,2.2,5,2.8,7.3c0.6,2.3,1,4.7,0.6,8.2c1.4-3.3,2.3-5.6,3.2-8C173.2,548.8,174.1,546.3,174.5,542.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M59.8,573.1c-3.7,3.4-7.3,6.6-11.1,9.2c-3.7,2.5-7.5,4.5-10.6,4.9c-3.2,0.5-5.8-0.6-7.3-1.7c-1.6-1.1-2.1-2.3-2.6-2.2
						c-0.2,0.1-0.3,0.4-0.3,1.1c0.1,0.7,0.4,1.7,1.1,2.7c0.7,1.1,1.9,2.2,3.5,3c1.6,0.8,3.7,1.3,5.9,1.3c2.2-0.1,4.5-0.6,6.7-1.5
						c2.2-0.9,4.3-2.1,6.4-3.4c4.2-2.8,8-6.4,11.4-10.2c3.5-3.9,6.3-7.3,8.9-10.2c2.6-2.9,5.1-5.4,7.9-7.7c2.7-2.2,5.7-4.2,9.4-5.8
						c3.7-1.5,8.2-2.7,13.6-2c-5.3-1-10-0.1-13.9,1.2c-3.9,1.3-7.2,3.1-10.3,5.1c-3.1,2-5.9,4.3-8.9,7
						C66.7,566.4,63.5,569.6,59.8,573.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M73.8,568.7c-1.9,3-3.2,6.4-4,9.7c-0.8,3.3-0.9,6.4-0.8,9.1c0.4,5.4,2.1,8.6,2.9,8.4c0.9-0.2,0.8-3.6,1.3-8.4
						c0.3-2.4,0.7-5.1,1.4-8c0.7-2.9,1.7-5.9,3-8.8c1.4-3,2.8-5.4,4.4-7.4c1.6-2,3.2-3.7,4.9-5.3c1.8-1.5,3.7-3,6-4.3
						c2.3-1.3,5.1-2.5,8.6-3.4c-3.5,0.6-6.4,1.6-9,2.6c-2.5,1.1-4.7,2.2-6.8,3.5c-2.1,1.3-4,2.8-6.1,4.7
						C77.8,563.3,75.8,565.6,73.8,568.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M82.3,565.7c-1.2,2.3-2,4.8-2.4,7.2c-0.4,2.4-0.3,4.7,0,6.6c0.6,3.9,2.2,6.1,3,5.9c0.9-0.2,0.9-2.7,1.2-6
						c0.1-1.7,0.4-3.6,0.7-5.6c0.4-2,0.9-4.2,1.5-6.3c0.7-2.2,1.5-4,2.4-5.5c0.9-1.6,1.8-2.9,2.9-4.2c1.1-1.3,2.3-2.5,3.8-3.7
						c1.5-1.2,3.3-2.3,5.7-3.3c-2.4,0.8-4.4,1.7-6.1,2.6c-1.7,0.9-3.2,1.9-4.6,3c-1.4,1.1-2.7,2.3-4.1,3.8
						C84.9,561.6,83.5,563.4,82.3,565.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M126.8,555.4c3.6,0.7,7.5,0,9.9-1.3c2.5-1.3,3.7-2.8,3.3-3.6c-0.4-0.8-2.2-0.7-4.6-0.4c-2.4,0.3-5.3,0.6-8.5,0.9
						c-3.2,0.2-5.5,0-7.8-0.4c-2.3-0.5-4.6-1.3-7.6-3.4c2.6,2.5,4.7,3.9,6.9,5.1C120.8,553.5,123.1,554.6,126.8,555.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M138.6,563.1c1.7,1.4,3.4,2.4,4.9,3.1c1.5,0.7,2.8,0.9,3.4,0.3c0.6-0.6,0.4-2-0.8-3.5c-1.1-1.5-3.1-3.1-5.5-3.8
						c-2.4-0.7-4.2-0.9-5.9-1c-1.7-0.1-3.4-0.2-5.8-0.2c2.4,0.5,3.9,1.2,5.3,2C135.6,560.8,136.9,561.7,138.6,563.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M141,578.8c0.3,2.1,1,4.1,1.8,5.5c0.8,1.5,1.7,2.4,2.6,2.3c0.9-0.1,1.5-1.4,1.5-3.2c0.1-1.8-0.5-4.2-1.8-6.2
						c-1.3-2.1-2.4-3.4-3.5-4.6c-1.1-1.2-2.2-2.5-3.8-4.2c1.2,2.1,1.7,3.6,2.2,5.2C140.4,575.1,140.7,576.6,141,578.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M143.4,505.7c0.1-2,0.1-3.7-0.1-5.2c-0.2-1.5-0.5-2.7-1.3-3c-0.8-0.3-2,0.5-2.8,2.3c-0.8,1.7-1,4.4-0.2,6.7
						c0.9,2.4,1.9,3.8,3,5.1c1.1,1.3,2.2,2.5,4,4c-1.5-1.8-2-3.4-2.3-4.8C143.4,509.2,143.3,507.7,143.4,505.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M158.8,498.6c0.9-1.8,1.7-3.4,2.3-4.9c0.6-1.4,0.9-2.7,0.4-3.4c-0.5-0.7-2.1-0.5-3.6,0.8c-1.6,1.3-3.1,3.7-3.3,6.4
						c-0.2,2.8,0.3,4.6,0.9,6.3c0.6,1.7,1.4,3.3,2.8,5.3c-1-2.3-1.1-4-0.9-5.5C157.5,501.9,157.9,500.4,158.8,498.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M187.9,576c2.7-0.4,5.2-1.1,7.1-1.9c1.9-0.8,3.2-1.7,3.1-2.6c-0.1-0.9-1.6-1.5-3.8-1.5c-2.2,0-5.1,0.5-7.7,1.8
						c-2.7,1.3-4.4,2.5-6.1,3.6c-1.8,1.2-3.5,2.4-5.8,4c2.7-1.2,4.6-1.8,6.5-2.3C183.2,576.8,185.2,576.4,187.9,576z"
                    />
                  </g>
                  <g>
                    <path
                      d="M149,392.3c3,0.1,5.8-1,7.6-2.1c1.8-1.1,2.7-2.4,2.4-3.2c-0.3-0.8-1.9-1-3.9-0.7c-2,0.3-4.4,1-6.7,1.7
						c-2.4,0.8-4.2,1-6,0.9c-1.8-0.1-3.8-0.5-5.9-2.5c1.7,2.3,3.6,3.4,5.4,4.3C143.8,391.6,145.9,392.2,149,392.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M134,321c-1.8,2.8-3.2,6-3.8,9.2c-0.6,3.2-0.5,6.3,0.2,8.9c0.7,2.6,1.8,4.5,2.9,5.6c0.5,0.6,1,0.9,1.4,1.1
						c0.4,0.2,0.7,0.2,0.8,0.1c0.7-0.6-0.7-3.3-1-7.6c-0.1-2.1,0-4.6,0.5-7.2c0.5-2.6,1.5-5.3,2.8-8c1.3-2.8,2.7-5.1,4-7.1
						c1.3-2.1,2.6-3.9,3.9-5.7c1.3-1.9,2.6-3.7,4-5.8c1.4-2.1,2.9-4.5,4.3-7.6c-1.6,2.9-3.4,5.1-5,7.1c-1.6,1.9-3.2,3.5-4.8,5.2
						c-1.6,1.6-3.1,3.2-4.8,5.1C137.7,315.9,135.8,318.1,134,321z"
                    />
                  </g>
                  <g>
                    <path
                      d="M143.2,322.4c-0.7,5.5,0.4,11.2,2,15.1c1.6,3.9,3.6,6,4.4,5.6c0.8-0.4,0.3-3.1-0.4-6.8c-0.7-3.8-1.4-8.7-1.7-13.7
						c-0.2-5.2,0.2-8.9,1-12.5c0.8-3.6,2-7.3,5-12.1c-3.4,4.5-5.2,8-6.8,11.5C145.2,313,143.9,316.7,143.2,322.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M149.7,316.6c0,4,1.3,7.8,2.8,10.4c1.5,2.6,3.2,3.9,3.9,3.4c0.8-0.4,0.5-2.4-0.1-5c-0.6-2.6-1.5-6-2.3-9.4
						c-0.9-3.5-1.2-6.1-1.4-8.7c-0.1-2.6,0-5.3,1-9.2c-1.5,3.7-2.3,6.3-2.9,9C150.2,309.8,149.7,312.5,149.7,316.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M93.8,341.5c3.2-2.6,5.7-6,7.1-8.8c1.4-2.8,1.7-5,0.9-5.4c-0.8-0.4-2.4,0.9-4.4,3c-2,2.1-4.4,5-6.8,8.1
						c-2.4,3.1-4.3,5.2-6.3,7.2c-2,2-4.2,3.8-7.8,6.1c3.8-1.8,6.4-3.2,8.9-4.6C88,345.7,90.5,344.2,93.8,341.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M102.3,347.6c2.7-0.3,5.3-1,7.2-1.7c1.9-0.8,3.2-1.7,3.1-2.5c-0.1-0.9-1.6-1.5-3.8-1.6c-2.2-0.1-5.1,0.4-7.7,1.7
						c-2.7,1.3-4.4,2.4-6.2,3.5c-1.8,1.2-3.6,2.3-5.9,3.8c2.7-1.2,4.7-1.7,6.6-2.1C97.6,348.2,99.5,347.9,102.3,347.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M119.4,246.7c1.9-1.6,3.5-3.5,4.5-5.2c1-1.6,1.4-3,0.8-3.7c-0.6-0.6-2.1-0.3-3.8,0.8c-1.7,1.1-3.6,3-4.9,5.4
						c-1.3,2.4-2,4.2-2.8,5.9c-0.8,1.9-1.5,3.7-2.5,6c1.5-2.3,2.8-3.7,4.1-5C116.1,249.6,117.4,248.4,119.4,246.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M20.7,384.4c-7.8,3.2-14.9,7.9-19.7,12.1c-4.8,4.2-7.2,7.7-6.7,8.3c0.6,0.7,4.1-1.6,9.2-5c5.1-3.3,11.9-7.6,19-11.4
						c7.3-3.9,12.7-6.1,18.2-8c5.5-1.8,11.3-3.3,20-4.3c-8.8,0.4-14.7,1.3-20.5,2.4C34.5,379.7,28.7,381.1,20.7,384.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M27.1,386.9c-6.2,3.1-11.7,7.5-15.3,11.3c-3.6,3.8-5.4,6.9-4.8,7.4c0.7,0.6,3.5-1.3,7.5-4.3c4-3,9.3-6.9,14.8-10.6
						c5.7-3.8,9.8-6.1,14.1-8.2c4.3-2,8.9-3.9,15.8-5.7c-7.1,1.2-11.9,2.5-16.5,3.9C38.1,382.1,33.5,383.7,27.1,386.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-19.8,372.5c-3.2-2.4-6.3-4.7-8.9-6.3c-2.5-1.6-4.5-2.5-5.1-1.9c-0.6,0.6,0.3,2.7,2.4,5.1c2.1,2.4,5.5,5.1,9.3,6.9
						c4,1.8,6.8,2.7,9.6,3.6c2.9,0.8,5.7,1.6,10,2.5c-4.1-1.4-6.8-2.8-9.3-4.2C-14.2,376.7-16.6,375-19.8,372.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-14.5,384.4c-0.9,0-1.8,0.2-2.7,0.6c-0.9,0.3-1.9,1-2.5,2c-0.6,1-0.6,2.1-0.3,2.7c0.3,0.7,0.9,0.8,1.3,0.7
						c0.4-0.1,0.8-0.4,1.2-0.5c0.4-0.1,0.7-0.1,1-0.1c0.2,0.1,0.6,0,1.2-0.2c0.7-0.2,1.4-0.5,2.1-1c1.4-0.9,2.5-1.5,3.6-2
						c1.1-0.5,2.3-0.9,4.1-1.2c-1.6-0.2-2.9-0.5-4.4-0.7C-11.3,384.6-12.6,384.4-14.5,384.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-13.6,395.2c-2.1,1.6-3.6,3.7-4.4,5.6c-0.7,1.8-0.7,3.3,0,3.8c0.8,0.5,2-0.2,3.4-1.4c1.4-1.2,2.9-3,4.1-5
						c1.3-2.1,2.3-3.5,3.4-4.9c1.1-1.4,2.3-2.7,4.3-4.4c-2.3,1.2-4,2-5.6,2.9C-9.8,392.7-11.4,393.6-13.6,395.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-9.5,421.5c-1.3,2.9-0.3,6,1.4,6.3c1.7,0.2,3.5-2.6,2.9-5.7c-0.5-3.2-1.1-4.4-1.2-7.7C-7.4,417.5-8.2,418.6-9.5,421.5
						z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-2.1,415.6c-1.2,2.5-0.2,5.3,1.5,5.5c1.7,0.2,3.4-2.2,2.8-4.9c-0.6-2.8-1.2-3.9-1.4-6.7C0,412.2-0.9,413.1-2.1,415.6z
						"
                    />
                  </g>
                  <g>
                    <path
                      d="M578.1,305.9c3.3-4.2,6.4-8.7,8.9-13.6c2.5-4.9,4.2-10.3,4.9-15.7c0.7-5.4,0.4-10.9-1.2-15.8c-0.4-1.2-0.8-2.4-1.3-3.6
						c-0.5-1.2-1.1-2.3-1.8-3.4c-1.4-2.2-3.1-4.2-5.3-5.7c-1.1-0.8-2.3-1.3-3.6-1.8c-1.3-0.4-2.7-0.6-4-0.5
						c-2.5,0.1-4.9,0.9-6.8,2.5c-0.9,0.8-1.7,1.7-2.3,2.7c-0.6,1-0.8,2.1-1,3.1c-0.2,2,0.2,3.7,0.7,5c1.1,2.5,2.5,3.9,3.5,4.8
						c1,0.9,1.7,1.1,2.1,0.9c0.4-0.2,0.3-1,0.1-2.2c-0.2-1.2-0.8-2.9-1-4.7c-0.1-0.9,0-1.8,0.3-2.5c0.2-0.3,0.4-0.7,0.6-0.9
						c0.2-0.2,0.5-0.4,0.9-0.6c0.7-0.3,1.7-0.5,2.7-0.3c0.5,0.1,1,0.2,1.4,0.4c0.4,0.2,0.9,0.6,1.4,1c0.9,0.8,1.8,2,2.5,3.4
						c0.7,1.4,1.3,3.2,1.8,5c0.9,3.6,1,7.7,0.4,12c-0.6,4.2-1.9,8.6-3.8,12.8c-1.9,4.3-4.4,8.5-7.2,12.6c-2.9,4.3-5.8,8.2-8.5,11.9
						c-2.7,3.7-5.2,7.3-7.5,10.8c-2.2,3.5-4.2,7-5.8,10.6c-1.6,3.6-2.8,7.2-3.7,10.9c-1.7,7.4-2.1,15.1-0.8,23.5
						c0.6,4.2,1.7,8.5,3.4,13c1.7,4.4,4,9,7.3,13.5c-3-4.6-5.1-9.3-6.5-13.7c-1.4-4.5-2.1-8.8-2.4-12.9c-0.6-8.2,0.5-15.5,2.8-22.3
						c1.2-3.4,2.6-6.6,4.4-9.7c1.8-3.1,4-6.2,6.4-9.3c2.4-3.1,5.2-6.3,8.2-9.7C571.4,313.8,574.7,310.2,578.1,305.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M561.9,353.7c1-1.7,2-3.3,3.1-4.8c1.1-1.5,2.2-2.8,3.4-4.1c1.1-1.3,2.4-2.4,3.5-3.4c0.3-0.2,0.6-0.5,0.9-0.7
						c0.3-0.2,0.5-0.4,0.8-0.6c0.5-0.4,1.1-0.7,1.6-1c2.2-1.2,4-1.8,5.4-2c1.5-0.3,2.7-0.1,3.3-0.7c0.3-0.3,0.4-0.8,0.2-1.5
						c-0.2-0.7-0.8-1.5-1.9-2.3c-1-0.8-2.5-1.4-4.3-1.6c-1.7-0.2-3.7-0.1-5.6,0.3c-1,0.2-2,0.6-3,1c-0.5,0.2-1,0.5-1.5,0.7
						c-0.5,0.3-0.9,0.5-1.4,0.8c-1.8,1.1-3.7,2.5-5.3,4.2c-1.6,1.7-3.1,3.6-4.4,5.6c-1.2,2.1-2.2,4.3-3,6.5
						c-1.6,4.7-2.1,8.7-2.3,12.3c-0.2,3.6,0,6.8,0.3,9.9c0.7,6.3,1.9,12.4,3.8,21.4c-0.9-9.1-0.8-15.4,0-21.4c0.4-3,1-5.9,1.9-9
						C558.6,360.4,559.9,357.1,561.9,353.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M574.5,279.9c-0.6-1.9-1.5-3.7-2.6-5.3c-0.6-0.8-1.2-1.6-1.9-2.3c-0.7-0.7-1.5-1.3-2.3-1.9c-1.7-1.1-3.9-1.8-6.2-1.5
						c-1.1,0.2-2.1,0.5-3,1.1c-0.9,0.6-1.6,1.4-2.1,2.4c-0.4,0.9-0.6,1.9-0.6,2.8c0,0.9,0.2,1.6,0.5,2.3c0.5,1.3,1.2,2.1,1.8,2.7
						c1.2,1.2,2.1,1.6,2.4,1.4c0.4-0.3,0.1-1.2-0.4-2.6c-0.2-0.7-0.5-1.5-0.6-2.4c-0.1-0.9,0.1-1.5,0.5-2c0.4-0.4,1.1-0.8,2-0.9
						c0.8-0.1,1.9,0.3,2.9,1c1.1,0.7,2.1,1.8,3.1,2.9c0.9,1.2,1.7,2.5,2.4,3.9c1.3,3,1.8,5.9,1.9,8.5c0.1,2.6-0.2,5.1-0.8,7.5
						c-1.1,4.8-3.2,9.6-6.8,16.2c4.1-6.4,6.7-10.8,8.6-15.6c0.9-2.4,1.7-4.9,2-7.9C575.6,287.2,575.6,283.8,574.5,279.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M568.9,290c-0.3-0.5-0.5-1.1-0.8-1.6c-0.3-0.5-0.7-1.1-1.2-1.5c-0.9-1-1.9-1.8-3.4-2.3c-0.7-0.3-1.5-0.4-2.3-0.4
						c-0.8,0-1.6,0.3-2.3,0.6c-1.4,0.7-2.2,1.8-2.6,2.8c-0.5,1-0.6,2-0.6,2.7c0,0.8,0.1,1.4,0.3,1.9c0.3,1,0.7,1.5,1.2,1.5
						c0.4,0,0.9-0.5,1.3-1.4c0.4-0.8,0.9-2,1.5-2.7c0.3-0.4,0.7-0.6,1-0.7c0.3,0,0.5,0,0.9,0.1c0.4,0.2,1,0.5,1.6,1.1
						c0.3,0.3,0.6,0.5,0.8,0.9c0.3,0.3,0.5,0.7,0.8,1.1c1.1,1.5,1.8,3,2.3,4.5c0.5,1.4,0.8,2.8,0.9,4.2c0.1,1.4,0.1,2.9-0.3,4.5
						c-0.3,1.6-0.9,3.4-2.1,5.3c1.4-1.7,2.3-3.4,3-5c0.7-1.6,1.1-3.1,1.3-4.7c0.2-1.6,0.4-3.1,0.2-4.9
						C570.3,294.2,569.9,292.2,568.9,290z"
                    />
                  </g>
                  <g>
                    <path
                      d="M548.8,301.3c-1.9-0.3-3.8-0.3-5.2,0c-1.5,0.3-2.6,0.9-2.7,1.8c-0.2,0.9,0.7,1.8,2.3,2.4c1.6,0.6,3.8,0.8,5.9,0.2
						c2.2-0.6,3.6-1.2,5-1.9c1.5-0.7,2.9-1.4,4.7-2.2c-2.1,0.4-3.6,0.5-5.1,0.4C552.1,301.9,550.7,301.7,548.8,301.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M536.3,308.9c-5.8,0.2-11.1,2.8-10.6,4.5c0.4,1.7,6.2,1.7,11.5-0.2c5.5-1.9,7.5-3,13.4-4.3
						C544.5,309.2,542.3,308.8,536.3,308.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M525.6,316.8c-3.9-0.7-7.6,0.8-7.6,2.5c0.1,1.8,4,2.9,7.8,1.8c3.9-1.1,5.3-1.9,9.3-2.6
						C531,318.2,529.6,317.5,525.6,316.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M537.6,319c-2.2,1.1-3.9,2.9-4.8,4.6c-0.9,1.6-1.1,3.1-0.4,3.6c0.7,0.6,2,0.2,3.4-0.7c1.5-0.9,3.1-2.3,4.5-4
						c1.5-1.7,2.6-2.9,3.8-4.1c1.2-1.1,2.5-2.2,4.6-3.4c-2.4,0.7-4,1.3-5.6,1.8C541.5,317.3,539.9,317.8,537.6,319z"
                    />
                  </g>
                  <g>
                    <path
                      d="M545.3,322.1c-1.5,1.4-2.5,3.1-2.9,4.6c-0.4,1.5-0.2,2.7,0.6,3.2c0.8,0.4,1.9,0,3-1c1.1-1,2.2-2.5,2.9-4.3
						c1.5-3.7,1.8-5.3,4.1-8.8C549.9,318.6,548.4,319.3,545.3,322.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M554.7,308c-1.3,0.9-2.1,2.2-2.4,3.4c-0.3,1.2,0,2.2,0.7,2.7c0.7,0.5,1.8,0.3,2.7-0.3c1-0.6,1.9-1.7,2.4-3
						c1.1-2.8,1.3-4.1,3.2-6.7C558.7,305.8,557.4,306.2,554.7,308z"
                    />
                  </g>
                  <g>
                    <path
                      d="M532.3,341c-1.2,1.5-1.8,3.3-1.8,4.8c0,1.5,0.5,2.5,1.4,2.7c0.9,0.2,1.8-0.4,2.6-1.5c0.8-1,1.5-2.5,1.8-4.2
						c0.3-1.7,0.5-3,0.9-4.2c0.3-1.2,0.8-2.4,1.7-4.1c-1.4,1.3-2.3,2.2-3.3,3.1C534.5,338.6,533.5,339.5,532.3,341z"
                    />
                  </g>
                  <g>
                    <path
                      d="M533.2,356.5c-2.1,3.9-1.8,8.5-0.1,8.9c1.7,0.4,3.9-3.4,4.3-7.6c0.4-4.4,0.2-6.1,1.4-10.5
						C536.7,351.3,535.4,352.5,533.2,356.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M533.9,379.3c-0.4,2.4,0.2,4.9,1,6.6c0.8,1.7,2,2.6,2.8,2.3c0.8-0.3,1.2-1.6,1.3-3.2c0.1-1.7-0.2-3.7-0.7-5.8
						c-0.5-2.2-0.8-3.7-1-5.4c-0.2-1.6-0.2-3.3,0.2-5.7c-0.9,2.3-1.5,3.8-2.1,5.5C534.8,375.2,534.2,376.8,533.9,379.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M570.2,359.3c1.9-2.2,3.7-4.2,5-6c1.3-1.7,2.2-3.2,1.7-3.9c-0.5-0.7-2.3-0.5-4.4,0.8c-2.2,1.3-4.6,3.7-6,6.8
						c-1.4,3.1-1.9,5.4-2.3,7.6c-0.4,2.2-0.6,4.5-0.6,7.8c0.5-3.2,1.4-5.3,2.4-7.2C567.1,363.2,568.3,361.5,570.2,359.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M580.7,319.9c1.7-0.7,3-1.7,4-2.7c0.9-1,1.4-2.1,1.1-2.9c-0.4-0.8-1.6-1.1-3.1-0.8c-1.5,0.3-3.3,1.3-4.6,2.8
						c-1.3,1.6-2.1,2.8-2.8,4c-0.8,1.3-1.5,2.6-2.4,4.1c1.4-1.4,2.6-2.1,3.8-2.7C577.8,321.1,579,320.6,580.7,319.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-6.5,258.1c1.9-0.4,3.5-1.1,4.7-2c1.2-0.9,1.9-1.9,1.6-2.7c-0.2-0.8-1.4-1.3-3-1.2c-1.6,0.1-3.5,0.7-5.2,2
						c-1.7,1.3-2.7,2.3-3.8,3.3c-1,1-2.1,2-3.7,3.4c1.9-0.9,3.2-1.4,4.6-1.7C-9.8,258.7-8.5,258.5-6.5,258.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M8.4,246.2c-1.5,2.6-2.1,5.6-2,7.8c0.1,2.3,0.8,3.7,1.7,3.8c0.9,0.1,1.7-1.2,2.5-3.1c0.8-1.9,1.5-4.3,2-6.9
						c0.5-2.7,1.1-4.5,1.7-6.4c0.7-1.8,1.5-3.7,3.3-6.1c-2.1,2-3.5,3.5-4.9,5.1C11.2,242,9.9,243.6,8.4,246.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M544.8,223.7c-1.8-1.5-3.8-2.7-6-3.5c-2.1-0.9-4.5-1.4-6.9-1.4c-1.2,0-2.4,0.2-3.5,0.6c-1.2,0.4-2.3,0.9-3.2,1.6
						c-1.9,1.4-3.2,3.3-3.7,5.3c-0.3,1-0.3,2-0.2,3.1c0.2,1,0.5,1.8,0.9,2.5c0.9,1.4,2.1,2.3,3.1,2.7c1.1,0.4,2,0.4,2.6,0.2
						c0.6-0.2,0.8-0.4,0.8-0.6c-0.1-0.5-1.1-0.6-2.2-1.7c-1.1-1-2.1-3.1-1.2-5c0.4-1,1.3-2,2.4-2.7c0.6-0.4,1.2-0.6,1.9-0.8
						c0.7-0.2,1.5-0.3,2.2-0.3c3.2,0,7,1.4,10.2,3.5c3.3,2.2,5.7,4.8,7.4,7.5c0.9,1.3,1.6,2.7,2.2,4c0.6,1.4,1.1,2.7,1.5,4.1
						c0.8,2.8,1.2,5.8,1.2,9.2c0,3.4-0.4,7.1-1.6,11.5c1.4-4.3,2.2-8,2.5-11.5c0.3-3.4,0.2-6.5-0.3-9.6c-0.3-1.5-0.6-3.1-1-4.6
						c-0.5-1.5-1.1-3.1-1.9-4.7C550.7,230.1,548.4,226.7,544.8,223.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M547.9,236.8c-0.9-1.3-2.2-2.4-3.6-3.2c-1.5-0.8-3.3-1.2-5-1c-1.8,0.2-3.4,1.2-4.5,2.4c-1.1,1.2-1.7,2.9-1.6,4.4
						c0.1,1.7,1,2.9,1.8,3.6c0.9,0.7,1.9,1.1,2.7,1.1c0.8,0,1.4-0.2,1.8-0.5c0.3-0.3,0.4-0.6,0.3-0.8c-0.2-0.4-0.9-0.6-1.4-1.1
						c-0.3-0.3-0.6-0.6-0.8-1.1c-0.2-0.4-0.3-0.9-0.1-1.2c0.1-0.4,0.4-0.8,0.8-1.2c0.5-0.4,1.1-0.7,1.7-0.7c1.3-0.2,3.2,0.6,4.7,2
						c1.6,1.5,2.6,3.5,3.6,5.3c0.9,1.8,1.7,3.4,2.3,5.1c0.6,1.7,1.2,3.5,1.6,5.5c0.4,2,0.7,4.4,0.8,7.1c0.2-2.8,0.2-5.1,0.1-7.2
						c-0.1-2.1-0.3-4-0.6-5.9c-0.3-1.9-0.7-3.7-1.3-5.8c-0.3-1-0.6-2.1-1.1-3.3C549.5,239.4,548.9,238.1,547.9,236.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M613.1,514.1c0.5,1.2,0.7,2.4,0.8,3.5c0,1.1-0.1,2-0.4,2.7c-0.3,0.7-0.7,1.4-1.3,2.1c-0.6,0.6-1.4,1.3-2.4,1.7
						c-1.9,1-4,1.4-6.1,1.3c-2-0.1-3.6-0.8-4.7-1.5c-1.2-0.9-2-2.1-2.3-3.2c-0.4-1-0.3-2.3,0.1-3.4c0.4-1.1,0.9-2,1.4-2.8
						c0.5-0.8,0.9-1.4,0.9-2.1c0-0.6-0.6-1.3-1.9-1.6c-1.3-0.3-3.3,0-5.5,1.3c-1.1,0.7-2.1,1.6-3.1,2.9c-0.5,0.7-0.9,1.4-1.2,2.2
						c-0.4,0.8-0.6,1.8-0.8,2.7c-0.7,3.8,0.2,8.2,2.9,11.9c2.8,3.9,7.2,6.5,12,7.4c4.7,1,10.1,0.5,14.9-1.9c2.4-1.2,4.7-2.8,6.7-5
						c2-2.2,3.6-4.9,4.4-8c0.8-3,0.8-6.2,0.3-9c-0.6-2.9-1.7-5.4-3.1-7.7c-1.5-2.3-3.3-4.3-5.2-5.9c-1.9-1.6-4.1-2.9-6.1-3.7
						c-4.1-1.7-8-2.3-11.5-2.4c-7.1-0.1-12.9,1.4-18.6,3.1c-5.6,1.8-11.1,4.1-16.8,7.4c-5.7,3.2-11.6,7.4-17.6,13.4
						c6.6-5.4,12.9-8.7,18.8-11c5.9-2.3,11.4-3.5,16.8-4.2c5.4-0.6,11-0.7,16.3,0.5c2.7,0.6,5.3,1.6,7.5,3.1c1.2,0.8,2,1.6,2.8,2.6
						C611.9,511.7,612.6,512.9,613.1,514.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M586.9,487.9c2.8-1.8,5.2-4.2,7.1-6.8c1.9-2.6,3.2-5.3,3.9-7.8c0.7-2.5,0.8-4.7,0.6-6.3c-0.3-1.6-0.9-2.5-1.8-2.7
						c-1.8-0.3-3.7,2.1-6.3,5.3c-1.3,1.7-2.7,3.5-4.3,5.4c-1.6,2-3.3,4.1-5.1,6.3c-1.8,2.3-3.6,4.1-5.2,5.7
						c-1.7,1.6-3.2,3.1-4.9,4.4c-3.3,2.7-6.9,5.3-12.6,8.5c6.1-2.3,10.3-3.7,14.5-5.2c2.1-0.8,4.2-1.6,6.5-2.7
						C581.6,491.1,584.1,489.8,586.9,487.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M574,520.4c-1.2,2.3-1.3,5.1-0.8,7c0.5,1.9,1.5,3,2.4,2.8c0.9-0.2,1.4-1.4,1.8-3c0.4-1.6,0.7-3.5,0.8-5.6
						c0.2-2.2,0.5-3.7,1-5.3c0.5-1.5,1.3-3,2.9-4.9c-2,1.4-3.3,2.6-4.5,3.9C576.4,516.6,575.3,518,574,520.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M580.2,543.9c0.4,2.6,1.7,5,3.2,6.4c1.5,1.4,2.9,1.9,3.6,1.3c0.7-0.6,0.5-2,0-3.6c-0.5-1.6-1.4-3.6-2.5-5.5
						c-1.1-2-1.8-3.6-2.3-5.2c-0.5-1.6-0.9-3.3-0.8-5.9c-0.6,2.5-0.9,4.3-1.1,6.1C580,539.4,579.8,541.2,580.2,543.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M599.3,558c1.3,1.4,3,2.4,4.7,2.8c1.7,0.5,3.4,0.5,4.8,0.2c1.4-0.3,2.4-0.9,3-1.4c0.6-0.6,0.8-1.1,0.7-1.5
						c-0.4-0.8-2-0.9-3.9-1.2c-2-0.3-4.3-0.9-6.5-2.2c-1.2-0.7-2.1-1.5-2.9-2.3c-0.8-0.8-1.4-1.7-2-2.6c-1.2-1.8-2.1-3.9-3.1-7.1
						c0.5,3.3,0.8,5.6,1.4,7.8c0.3,1.1,0.6,2.3,1.2,3.5C597.2,555.2,598,556.6,599.3,558z"
                    />
                  </g>
                  <g>
                    <path
                      d="M613.7,553.2c2.2,1.1,4.7,1.3,6.5,1c1.8-0.3,3-1.2,2.9-2.1c-0.1-0.9-1.2-1.6-2.7-2.1c-1.5-0.5-3.5-0.9-5.5-1
						c-2.2-0.2-3.7-0.4-5.2-0.7c-1.5-0.4-3.1-0.9-5.1-2.2c1.7,1.7,2.9,2.8,4.2,3.9C610.1,551,611.5,552.1,613.7,553.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M623.6,545.9c1.5,0.1,2.9-0.6,3.8-1.4c0.8-0.8,1.1-1.9,0.7-2.6c-0.4-0.8-1.3-1.2-2.4-1.2c-1,0-2.2,0.3-3.3,1
						c-1.1,0.8-1.9,1.3-2.8,1.8c-0.9,0.5-1.8,0.9-3.2,1.2c1.4,0.3,2.5,0.5,3.5,0.7C620.9,545.6,622,545.8,623.6,545.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M584.7,454.4c0.8-4,0.4-8.1-0.6-11.1c-0.9-2.9-2.3-4.6-3.1-4.4c-0.9,0.3-1.1,2.3-1,5c0,2.8,0.2,6.4,0.3,10.1
						c0.1,3.8,0,6.5-0.4,9.1c-0.4,2.7-1,5.4-2.7,9.1c2.2-3.5,3.4-6,4.5-8.6C582.9,461.2,583.9,458.5,584.7,454.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M175.8,236.9c-0.3,3.3,0.7,6.7,2.2,8.7c1.4,2.1,3.1,2.9,3.7,2.4c0.7-0.5,0.5-2.1,0.1-4.1c-0.5-2.3-1-4.4-1.6-7.2
						c-0.5-2.8-0.6-4.8-0.4-6.9c0.2-2,0.7-4.2,2.3-6.9c-2.1,2.4-3.2,4.4-4.2,6.4C177,231.3,176.2,233.5,175.8,236.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M316.4,56.6c3.3,0.8,6.5,1.5,9,2c2.5,0.5,4.4,0.7,4.8-0.1c0.4-0.7-0.8-2.4-3.3-3.8c-2.5-1.5-6.3-2.6-10.1-2.5
						c-3.9,0.1-6.5,0.7-9.1,1.5c-2.5,0.8-5,1.6-8.5,3.4c3.6-1.2,6.2-1.4,8.7-1.5C310.5,55.6,313,55.9,316.4,56.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M166.4,149.7c1.4,3.4,3.9,6.3,6.1,7.9c2.2,1.7,4.1,2.1,4.7,1.5c0.6-0.7-0.3-2.3-1.7-4.4c-1.4-2.1-3.3-4.6-5.3-7.2
						c-2.1-2.7-3.3-4.7-4.4-6.9c-1.1-2.2-2-4.5-2.6-8.1c0.1,3.7,0.4,6.2,0.8,8.7C164.4,143.7,165,146.2,166.4,149.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M79.1,161.1c1.1-2.5,1.1-5.4,0.4-7.4c-0.7-2-1.8-3-2.6-2.8c-0.9,0.3-1.3,1.6-1.5,3.2c-0.3,1.7-0.4,3.7-0.5,5.9
						c-0.1,2.3-0.4,3.9-0.9,5.5c-0.5,1.6-1.3,3.2-3.1,5.1c2.1-1.5,3.4-2.8,4.7-4.2C76.7,165.2,77.9,163.7,79.1,161.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M340.8,260.3c3.5-6.8,4.5-14.6,2.8-15.1c-1.7-0.5-5.1,6.3-6.9,13.5c-0.9,3.8-1.5,6.4-2.2,9c-0.7,2.6-1.5,5.2-2.9,9
						c1.9-3.5,3.3-5.9,4.7-8.3C337.6,266.1,339,263.8,340.8,260.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M485,235.3c3.3,0,6.5-0.3,9-0.8c2.4-0.5,4.1-1.2,4.2-2.1c0-0.9-1.7-1.7-4.2-2.1c-2.6-0.4-6.1-0.3-9.4,0.6
						c-3.4,0.9-5.7,1.8-8,2.7c-2.4,1-4.7,1.9-7.8,3.1c3.5-0.8,5.9-1.1,8.3-1.3C479.3,235.3,481.6,235.3,485,235.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M596.5,141.5c-2.7,0.3-5.3,1.3-7,2.5c-1.7,1.2-2.6,2.4-2.2,3.2c0.4,0.8,1.9,0.9,3.8,0.6c1.9-0.3,4.3-1,6.5-2.1
						c2.4-1.1,4-1.9,5.8-2.5c1.7-0.7,3.5-1.2,6.3-1.7c-2.6-0.1-4.5-0.1-6.6-0.2C601.2,141.3,599.3,141.2,596.5,141.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M548.5,79c-2.4,1.8-4.6,3.6-6.2,5.2c-1.6,1.6-2.6,2.9-2.2,3.7c0.4,0.8,2.2,0.8,4.5-0.2c2.3-1,5.1-3,7.1-5.6
						c2-2.7,3.1-4.8,4-6.9c0.9-2.1,1.8-4.2,2.9-7.4c-1.5,3-3,4.7-4.5,6.3C552.6,75.7,550.9,77.1,548.5,79z"
                    />
                  </g>
                  <g>
                    <path
                      d="M482.8,101.2c3.5-0.6,7-1.5,9.5-2.4c2.6-0.9,4.3-1.9,4.2-2.8c-0.1-0.9-2.1-1.4-4.9-1.3c-2.8,0.1-6.6,0.8-10,2.3
						c-3.6,1.5-5.9,2.8-8.2,4.1c-2.3,1.3-4.6,2.7-7.9,4.7c3.6-1.5,6.1-2.3,8.6-2.9C476.6,102.3,479.2,101.9,482.8,101.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M503.3,526.1c2.5-1.7,3.3-4.9,2-6c-1.4-1.1-4.3,0.4-5.4,3.3c-1.2,2.9-1.3,4.3-2.8,7.1
						C499.4,528.3,500.7,527.9,503.3,526.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M281.3,518.4c2,0.3,3.8,0.4,5.3,0.2c1.5-0.1,2.7-0.5,3-1.3c0.3-0.8-0.5-2-2.2-2.8c-1.6-0.8-4.1-1.2-6.5-0.5
						c-2.4,0.7-3.9,1.5-5.3,2.3c-1.4,0.9-2.8,1.8-4.6,3.2c2.1-1,3.6-1.3,5.1-1.4C277.8,518,279.3,518.1,281.3,518.4z"
                    />
                  </g>
                </g>
              </g>
              <g>
                <defs>
                  <path
                    id="SVGID_00000096033204928138950900000006725822018419696573_"
                    d="M244.2,488.3c-1.8-41.6,16.7-220.4,48.9-221.8
					c32.2-1.4,66,175.2,67.8,216.7c1.8,41.6-25.9,106-53.8,107.2C279.1,591.8,246,529.9,244.2,488.3z"
                  />
                </defs>
                <clipPath id="SVGID_00000027575322986346263420000009639315529338161318_">
                  <use
                    xlinkHref="#SVGID_00000096033204928138950900000006725822018419696573_"
                    overflow="visible"
                  />
                </clipPath>
              </g>
            </g>
          </g>
          <g id="Peddle4">
            <image
              overflow="visible"
              opacity="0.7"
              width="1460"
              height="1391"
              xlinkHref={Shadow9}
              transform="matrix(0.24 0 0 0.24 206.7893 226.8964)"
            ></image>
            <g>
              <g>
                <defs>
                  <path
                    id="SVGID_00000021798783300267408400000011797726993715806608_"
                    d="M382.8,473.1c-30.7-28.1-144-167.7-122.2-191.4
					C282.4,258,431.1,359,461.8,387.1c30.7,28.1,56.6,93.3,37.7,113.9C480.6,521.6,413.5,501.3,382.8,473.1z"
                  />
                </defs>
                <clipPath id="SVGID_00000101069252934117823260000007611562701175426472_">
                  <use
                    xlinkHref="#SVGID_00000021798783300267408400000011797726993715806608_"
                    overflow="visible"
                  />
                </clipPath>
              </g>
              <g>
                <defs>
                  <path
                    id="SVGID_00000181082431783923208120000007302772574910011819_"
                    d="M382.8,473.1c-30.7-28.1-144-167.7-122.2-191.4
					C282.4,258,431.1,359,461.8,387.1c30.7,28.1,56.6,93.3,37.7,113.9C480.6,521.6,413.5,501.3,382.8,473.1z"
                  />
                </defs>
                <use
                  xlinkHref="#SVGID_00000181082431783923208120000007302772574910011819_"
                  overflow="visible"
                  fill="#36B657"
                />
                <clipPath id="SVGID_00000108294942219810716810000011958459240064332164_">
                  <use
                    xlinkHref="#SVGID_00000181082431783923208120000007302772574910011819_"
                    overflow="visible"
                  />
                </clipPath>
                <g
                  opacity="0.2"
                  clipPath="url(#SVGID_00000108294942219810716810000011958459240064332164_)"
                >
                  <g>
                    <g>
                      <path
                        d="M312.1,219.6c8.1-1.2,15.4-3.8,20.8-7c5.4-3.2,8.8-6.9,8.1-10.4c-0.7-3.4-5.5-5.7-12.2-5.8c-6.7-0.1-15.4,2-22.9,6.8
							c-7.7,4.9-12.4,9-17.2,13c-4.7,4-9.4,8-16.3,13.7c8.1-3.8,13.9-5.6,19.8-6.9C297.9,221.7,303.8,220.8,312.1,219.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M314.5,239.9c7,1.8,13.9,2.1,19.5,1.1c5.5-1,9.7-3.2,10.5-6.6c0.8-3.4-2.4-7.3-8.1-9.9c-5.6-2.6-13.8-3.8-21.8-2.1
							c-8.2,1.8-13.5,3.9-18.8,6c-5.3,2.1-10.5,4.2-18,7.1c8-0.8,13.5-0.4,18.9,0.5C302,236.8,307.2,238.1,314.5,239.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M303.9,258.7c4.2,3.4,8.8,5.1,13,5.4c4.2,0.3,8.1-0.9,10-3.9c1.9-3,1.1-7.2-2.1-10.9c-3.1-3.7-8.6-6.8-14.9-7.1
							c-6.5-0.4-10.9,0.2-15.2,0.7c-4.3,0.5-8.6,1-14.6,1.5c5.8,1.7,9.5,3.7,12.9,5.9C296.4,252.6,299.5,255.2,303.9,258.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M282.6,276.1c1.5,4.7,4.5,8,8,9.7c3.4,1.7,7.3,1.9,10.2-0.1c2.9-2,4.1-5.7,3.3-9.8c-0.7-4-3.4-8.3-7.9-11
							c-4.6-2.8-8.1-4.2-11.5-5.8c-3.4-1.6-6.6-3.2-10.9-6.1c2.8,4.3,4.2,7.7,5.3,11.2C280.2,267.7,281.1,271.2,282.6,276.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M262,284.1c-1.5,5-1.3,9.6,0.1,13.5c1.3,3.8,3.8,7,7.3,7.6c3.4,0.7,7.1-1.5,9.3-5.8c2.2-4.2,3-10.5,0.8-16.4
							c-2.3-6-4.6-9.7-6.8-13.4c-2.3-3.6-4.5-7.1-7.6-12.2c1,5.8,0.8,9.9,0.1,13.8C264.5,275.2,263.5,279,262,284.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M238.4,276.8c-4.1,3.3-6.7,7-8.2,10.8c-0.3,1-0.7,1.9-0.9,2.9c-0.2,1-0.3,1.9-0.3,2.8c0,1.8,0.4,3.6,1.4,5
							c1,1.4,2.6,2.3,4.6,2.6c1,0.1,2.1,0.1,3.2-0.1c1.1-0.2,2.4-0.5,3.5-1.1c4.8-2.1,9.8-7.2,11.7-13.9c1.9-6.8,2-11.6,2-16.2
							c-0.1-4.6-0.3-9.1-0.8-15.3c-1.6,6-3.8,9.7-6.4,12.9C245.6,270.5,242.6,273.3,238.4,276.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M221.4,254.8c-5.9,0.7-10.8,2.7-14.6,5.4c-3.7,2.7-6.2,6.2-5.7,9.7c0.5,3.5,4.2,6.1,9.6,6.6
							c5.3,0.5,12.3-1.2,17.7-5.6c5.6-4.6,8.8-8.3,11.8-12c3-3.7,5.9-7.3,10.2-12.6c-5.6,3.6-10,5.2-14.3,6.3
							C231.8,253.5,227.4,254.1,221.4,254.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M214.7,232.3c-5.8-1.9-11.2-2.4-15.9-1.9c-1.2,0.2-2.3,0.3-3.3,0.7c-1.1,0.3-2,0.7-2.9,1.1c-1.7,0.9-3.1,2.2-3.7,3.8
							c-0.6,1.6-0.4,3.5,0.5,5.3c0.5,0.9,1.2,1.9,2,2.7c0.9,0.9,1.9,1.8,3.1,2.5c4.8,3.1,12.4,4.8,19.7,3.2c7.5-1.7,12.3-4,16.9-6.2
							c4.5-2.3,9-4.7,15-8.3c-6.9,1.6-11.7,1.4-16.4,0.8C225.1,235.5,220.6,234.3,214.7,232.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M221.6,214.1c-3.8-4.1-7-7.1-10.2-9.4c-1.6-1.2-3.2-2.2-4.8-3.1c-1.6-0.9-3.3-1.6-5.1-1.4c-1.7,0.1-3.3,1.2-4.4,3.3
							c-1.1,2.1-1.4,5.3-0.6,8.8c0.8,3.5,2.7,7.3,5.7,10.4c2.9,3.1,6.8,5.6,11,6.8c4.4,1.3,7.9,1.6,11.1,1.7
							c3.2,0.1,5.9-0.1,8.6-0.4c5.4-0.6,10.5-1.4,17.9-2.1c-7.3-1.5-12.2-3.2-16.6-5.4c-2.2-1.1-4.3-2.4-6.4-3.9
							C225.6,217.9,223.5,216.2,221.6,214.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M239.5,196.3c-0.1-5.5-0.9-9.8-2.3-13.6c-0.7-1.9-1.5-3.7-2.5-5.3c-1-1.6-2.2-2.9-3.9-3.6c-1.6-0.7-3.5-0.5-5.4,0.6
							c-1.9,1.1-3.7,3.3-4.9,6.3c-1.2,3-1.8,6.7-1.4,10.5c0.4,3.8,1.7,7.8,3.9,11.1c2.3,3.5,4.6,5.9,6.7,7.9c2.2,2,4.2,3.6,6.3,5.1
							c4.1,3,8.3,5.7,14,9.6c-4.3-5.5-6.5-9.9-8-14.2c-0.7-2.2-1.3-4.4-1.8-6.8C239.9,201.7,239.6,199.1,239.5,196.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M259.2,191.2c2.3-5.4,3.1-10.6,2.9-15.2c-0.1-1.2-0.2-2.2-0.4-3.3c-0.2-1.1-0.5-2-0.9-2.9c-0.8-1.8-1.9-3.2-3.5-4
							c-1.6-0.8-3.4-0.8-5.3,0c-1,0.4-2,1-2.9,1.8c-0.9,0.8-1.9,1.7-2.8,2.9c-3.5,4.5-5.8,11.9-4.6,19.3c1.3,7.6,3.3,12.4,5.3,17
							c2.1,4.6,4.3,9,7.7,15c-1.4-6.8-1-11.6-0.1-16C255.5,201.1,256.9,196.9,259.2,191.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M281.1,191.4c4.3-4.1,7.2-8,9.3-11.7c1-1.9,1.9-3.7,2.4-5.6c0.5-1.8,0.7-3.7,0-5.3c-0.6-1.6-2.1-2.9-4.3-3.4
							c-2.2-0.6-5.1-0.3-8.2,0.8c-3.1,1.2-6.3,3.3-8.9,6.2c-2.6,2.9-4.7,6.5-5.8,10.5c-1.2,4-1.6,7.4-1.8,10.4
							c-0.2,3-0.2,5.7-0.2,8.3c0.1,5.2,0.3,10.3,0,17.6c2.4-6.9,4.7-11.5,7.3-15.6c1.3-2.1,2.8-4,4.4-6
							C277.1,195.6,278.9,193.5,281.1,191.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M256.7,233.3c-1.5,3.2,1.5,6,5,5.6c3.5-0.4,5.8-3.7,3.7-6.5c-2.2-2.9-3.7-3.9-5.1-7
							C259.6,228.7,258.2,230,256.7,233.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M261.4,230.6c-1.4,1-1.8,2.3-1.5,3.7c0.3,1.4,1.3,2.8,2.8,3.6c1.5,0.9,3.3,0.9,4.7,0.2c1.4-0.7,2.3-2.2,2.1-4.1
							c-0.2-2-0.6-3.3-0.9-4.7c-0.3-1.3-0.6-2.6-0.7-4.4c-0.9,1.5-1.9,2.4-2.9,3.1C263.9,228.9,262.8,229.6,261.4,230.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M264.9,232.2c-1.3-0.6-2.1-0.2-2.9,0.7c-0.7,0.9-1.4,2.4-1.5,4.2c-0.1,1.7,0.7,3.3,2.1,4.1c1.4,0.8,3.3,0.7,4.8-0.7
							c1.5-1.4,2.2-2.7,2.8-3.8c0.6-1.2,1.2-2.3,2.1-3.6c-1.5,0.5-2.7,0.5-3.8,0.3C267.3,233.2,266.2,232.7,264.9,232.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M267.4,236.8c0-0.7-0.3-1.3-0.7-1.8c-0.5-0.5-1.1-1-1.9-1.2c-0.8-0.3-1.6-0.3-2.4-0.1c-0.8,0.2-1.5,0.6-2.1,1.3
							c-0.6,0.6-1.1,1.3-1.4,2c-0.3,0.7-0.6,1.4-0.6,2c0,0.7,0.1,1.3,0.5,2c0.4,0.6,1.1,1.3,2.1,1.7c1,0.5,2.1,0.8,3,1
							c0.9,0.2,1.7,0.2,2.5,0.1c1.6-0.1,3.1-0.5,4.5-1.6c-0.8,0.1-1.5-0.2-2-0.6c-0.5-0.4-0.8-0.8-1-1.3c-0.2-0.5-0.3-1-0.4-1.6
							C267.4,238,267.5,237.5,267.4,236.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M266.9,240c0.8-1.4,0.7-2.5-0.1-3.6c-0.7-1.1-2-2.2-3.8-2.6c-1.7-0.4-3.4,0.1-4.5,1.4c-1.1,1.3-1.4,3.5-0.4,5.4
							c1.1,2,2.1,3.1,3.2,4.1c1,1,2,2,3.2,3.4c-0.2-1.8,0-3.1,0.5-4.3C265.4,242.6,266.1,241.5,266.9,240z"
                      />
                    </g>
                    <g>
                      <path
                        d="M264.2,244.1c1.7-1.3,2.4-3.2,2.2-4.9c-0.2-1.7-1.4-3.1-3.1-3.7c-1.7-0.6-3.4-0.3-4.7,0.5c-1.3,0.8-2.1,2-2.1,3.8
							c0,1.9,0,3.3,0,4.7c-0.1,1.4-0.4,2.8-1.2,4.5c1.6-1,2.9-1.7,4.3-2.3C260.9,246.1,262.4,245.4,264.2,244.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M258.2,243.2c3.6,1.4,6.6-1.7,6.2-5.2c-0.4-3.5-4.2-5.7-7.3-3.5c-3.2,2.3-4.3,3.9-7.7,5.4
							C253,240.5,254.5,241.8,258.2,243.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M254.5,239.4c1.8,3.2,5.8,2.6,7.7-0.4c1.8-3,0.5-6.9-3.1-7c-3.7-0.1-5.5,0.5-8.9-0.3
							C252.5,234.3,252.7,236.1,254.5,239.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M255,235.1c0,1.7,1.1,2.7,2.4,3c1.3,0.3,3-0.2,4.3-1.3c1.4-1.1,2.1-2.6,2.3-3.8c0.1-1.2-0.4-2.3-1.8-2.8
							c-2.9-1-4.7-1.1-6.9-3C255.8,230.1,255,231.7,255,235.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M299.7,201.9c5.7-1.8,10.2-4.8,13.4-8.2c3.2-3.4,5-7.2,3.9-10.6c-1.1-3.3-5.3-5.2-10.8-4.6c-5.4,0.5-12,3.6-16.6,9.1
							c-4.7,5.7-7.1,10.1-9.4,14.4c-2.2,4.4-4.4,8.6-7.4,14.7c4.8-4.8,8.8-7.3,12.8-9.3C289.6,205.4,293.8,203.9,299.7,201.9z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M624.2,225.2c3.8,4.2,8.2,7.3,12.1,9.2c3.9,1.8,7.4,2.3,9.1,0.6c1.7-1.7,1.1-5.3-1.4-9.3c-2.6-3.9-7.1-8.1-12.8-10.5
							c-5.8-2.5-10-3.6-14.1-4.8c-4.1-1.1-8.3-2.3-14.2-4.1c5.3,3.1,8.6,5.8,11.6,8.7C617.5,217.9,620.3,220.9,624.2,225.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M613.5,234.5c1.7,4.7,4.2,8.8,6.9,11.6c2.7,2.8,5.6,4.4,7.9,3.4c2.2-0.9,3.3-4.2,2.6-8.5c-0.7-4.2-3.2-9.4-7.3-13.3
							c-4.2-4-7.5-6.2-10.7-8.5c-3.3-2.2-6.5-4.4-11.1-7.6c3.6,4.3,5.5,7.6,7.1,11C610.4,226.1,611.7,229.6,613.5,234.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M598.5,235.7c-0.3,3.7,0.5,7,2,9.6c1.5,2.5,3.7,4.3,6.1,4.2c2.4-0.1,4.5-2.2,5.4-5.4c0.9-3.2,0.5-7.6-1.7-11.3
							c-2.3-3.8-4.3-6.2-6.3-8.5c-2-2.3-3.9-4.5-6.5-7.8c1.3,4,1.6,6.8,1.6,9.7C599.1,229,598.9,231.8,598.5,235.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M580.4,230.2c-2.1,2.7-2.8,5.7-2.5,8.4c0.3,2.6,1.7,4.9,4,5.8c2.3,0.9,4.9,0.1,6.9-1.9c2-2,3.4-5.2,3.2-8.8
							c-0.2-3.7-0.7-6.3-1.1-8.9c-0.4-2.5-0.7-5.1-0.8-8.6c-1.4,3.3-2.8,5.4-4.3,7.4C584.2,225.6,582.5,227.5,580.4,230.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M567.7,221.5c-3.4,1.1-6,3-7.7,5.3c-1.7,2.3-2.5,4.9-1.5,7.2c0.9,2.2,3.6,3.5,7,3.1c3.3-0.4,7.2-2.4,9.7-5.9
							c2.6-3.6,3.8-6.4,5-9.1c1.2-2.7,2.3-5.4,4-9.1c-2.9,2.8-5.4,4.3-7.8,5.4C573.9,219.5,571.3,220.4,567.7,221.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M562.8,205.2c-3.5-1.1-6.6-1.1-9.4-0.5c-0.7,0.2-1.4,0.3-2,0.6c-0.6,0.3-1.2,0.6-1.7,0.9c-1.1,0.7-1.9,1.6-2.3,2.8
							c-0.4,1.1-0.3,2.4,0.3,3.6c0.3,0.6,0.7,1.2,1.3,1.8c0.6,0.6,1.2,1.1,2,1.6c3.1,1.9,8,2.8,12.5,1.3c4.6-1.6,7.4-3.3,10.1-5.1
							c2.6-1.8,5.1-3.7,8.4-6.4c-4.1,1.4-7.1,1.6-9.9,1.4C569.2,206.9,566.4,206.2,562.8,205.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M568.7,186.9c-2.6-3.1-5.7-5.2-8.7-6.3c-3-1.1-5.9-1.1-7.7,0.5c-1.8,1.6-1.9,4.8-0.1,8.1c1.8,3.3,5.4,6.6,10.1,8
							c4.8,1.4,8.2,1.8,11.5,2.1c3.3,0.3,6.5,0.5,11.2,0.9c-4.3-1.8-6.8-3.7-9.1-5.8C573.6,192.4,571.5,190.1,568.7,186.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M579.1,174.3c-1.1-4.1-3-7.4-5.1-9.9c-0.6-0.6-1.1-1.2-1.7-1.7c-0.6-0.5-1.2-0.9-1.8-1.2c-1.2-0.6-2.4-0.9-3.6-0.7
							c-1.2,0.3-2.2,1.1-2.8,2.4c-0.3,0.6-0.6,1.4-0.8,2.2c-0.2,0.8-0.3,1.8-0.2,2.8c0.1,4,2,9,5.8,12.6c3.9,3.7,7,5.5,10.1,7.2
							c3.1,1.7,6.2,3.3,10.6,5.4c-3.6-3.3-5.4-6.2-6.8-9.1C581.3,181.5,580.3,178.5,579.1,174.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M592.2,171.1c0.9-3.8,1.3-6.8,1.4-9.5c0.1-1.3,0-2.7-0.1-4c-0.1-1.3-0.4-2.5-1.2-3.5c-0.8-0.9-2-1.4-3.6-1.2
							c-1.6,0.2-3.6,1.2-5.3,3c-1.7,1.8-3.1,4.4-3.7,7.3c-0.6,2.9-0.6,6,0.3,8.9c0.9,3,2.1,5.2,3.3,7c1.2,1.8,2.4,3.3,3.6,4.8
							c2.4,2.9,4.9,5.5,8.1,9.5c-2-4.8-2.9-8.3-3.3-11.7c-0.2-1.7-0.3-3.4-0.3-5.2C591.6,175,591.7,173.1,592.2,171.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M609.4,174.5c3.1-2.2,5.3-4.3,6.9-6.6c0.8-1.1,1.5-2.3,2.1-3.5c0.5-1.2,0.8-2.4,0.6-3.6c-0.2-1.2-1.1-2.2-2.5-2.8
							c-1.4-0.6-3.3-0.8-5.5-0.4c-2.2,0.4-4.5,1.5-6.6,3.3c-2,1.7-3.8,4-4.8,6.5c-1.1,2.7-1.6,4.9-1.9,6.9c-0.3,2-0.4,3.8-0.5,5.6
							c-0.1,3.5,0,6.9-0.1,11.8c1.5-4.6,3.1-7.6,5.1-10.1c1-1.3,2-2.5,3.2-3.6C606.4,176.8,607.8,175.6,609.4,174.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M619.9,183.9c4-0.8,7.3-2.3,9.9-4.3c0.6-0.5,1.2-1,1.7-1.5c0.5-0.5,1-1.1,1.3-1.6c0.7-1.1,1.1-2.3,1-3.6
							c-0.2-1.2-0.9-2.3-2.1-3.1c-0.6-0.4-1.3-0.8-2.1-1c-0.8-0.2-1.7-0.4-2.7-0.5c-3.9-0.3-9.1,1.3-12.8,4.9
							c-3.8,3.7-5.8,6.7-7.7,9.6c-1.8,3-3.5,6-5.6,10.2c3.4-3.4,6.2-5.1,9.1-6.3C612.8,185.6,615.8,184.7,619.9,183.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M628.3,196.5c4,0.9,7.4,1,10.3,0.8c1.5-0.1,2.9-0.4,4.1-0.8c1.2-0.4,2.4-1,3.1-2c0.7-1,0.8-2.3,0.3-3.8
							c-0.5-1.5-1.8-3-3.7-4.4c-1.9-1.3-4.3-2.3-7-2.7c-2.7-0.4-5.6-0.2-8.3,0.7c-2.8,0.9-4.9,2-6.7,3c-1.8,1-3.3,2.1-4.8,3.1
							c-3,2.1-5.8,4.2-10.1,6.8c4.9-1.3,8.4-1.8,11.8-1.8c1.7,0,3.4,0,5.2,0.2C624.3,195.8,626.3,196,628.3,196.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M594.8,198.8c-2.4,0.4-2.8,3.2-1.3,5c1.6,1.9,4.4,1.9,5.2-0.4c0.8-2.4,0.8-3.7,2-5.7
							C598.6,198.6,597.3,198.3,594.8,198.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M598.2,200.4c-1.1-0.4-2-0.1-2.7,0.6c-0.7,0.7-1.1,1.8-1,3c0.1,1.2,0.8,2.2,1.7,2.7c0.9,0.5,2.2,0.5,3.2-0.4
							c1-0.9,1.7-1.6,2.3-2.3c0.6-0.7,1.3-1.4,2.2-2.1c-1.2,0-2.1-0.2-2.9-0.4C600.2,201.2,599.3,200.8,598.2,200.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M598.6,203c-0.2-0.9-0.7-1.3-1.5-1.4c-0.8-0.1-1.9,0.1-3,0.8c-1,0.6-1.7,1.7-1.6,2.8c0.1,1.1,0.9,2.2,2.2,2.5
							c1.4,0.3,2.4,0.2,3.3,0.1c0.9-0.1,1.8-0.2,2.9-0.2c-0.9-0.7-1.3-1.4-1.6-2.1C599,204.8,598.8,204,598.6,203z"
                      />
                    </g>
                    <g>
                      <path
                        d="M597,206.3c0.4-0.3,0.6-0.7,0.8-1.1c0.1-0.5,0.1-1,0-1.6c-0.1-0.5-0.4-1.1-0.9-1.4c-0.4-0.4-1-0.6-1.6-0.7
							c-0.6-0.1-1.2-0.1-1.7,0c-0.5,0.1-1,0.2-1.4,0.4c-0.4,0.2-0.7,0.6-0.9,1c-0.2,0.5-0.3,1.1-0.2,1.9c0.1,0.8,0.3,1.5,0.6,2.1
							c0.3,0.6,0.6,1.1,0.9,1.5c0.7,0.8,1.5,1.6,2.7,2c-0.4-0.5-0.5-1-0.4-1.4c0-0.4,0.2-0.8,0.4-1.1c0.2-0.3,0.5-0.6,0.7-0.9
							C596.3,206.7,596.6,206.6,597,206.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M594.9,207.2c1.1-0.1,1.7-0.6,2.1-1.5c0.3-0.8,0.5-2,0-3.2c-0.4-1.1-1.4-1.9-2.6-2c-1.2-0.1-2.5,0.5-3.2,1.9
							c-0.7,1.4-0.9,2.4-1.1,3.4c-0.2,1-0.4,1.9-0.7,3.2c0.9-0.8,1.8-1.2,2.7-1.4C592.9,207.4,593.8,207.3,594.9,207.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M591.5,207.3c1.4,0.5,2.8,0.1,3.7-0.7c0.9-0.8,1.2-2,0.9-3.2c-0.3-1.2-1.2-2.1-2.1-2.5c-0.9-0.4-2-0.4-3,0.3
							c-1.1,0.7-1.9,1.3-2.7,1.8c-0.8,0.5-1.7,0.9-3.1,1.1c1.2,0.5,2.1,1,3,1.6C589.1,206.1,590.1,206.7,591.5,207.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M589.7,203.4c0.6,2.6,3.6,3.1,5.4,1.5c1.8-1.6,1.7-4.6-0.8-5.5c-2.6-1-3.9-1-6.1-2.3
							C589.3,199.5,589.1,200.8,589.7,203.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M590.5,199.9c-1.1,2.2,0.8,4.3,3.2,4.2c2.4-0.1,4.2-2.4,2.8-4.5c-1.4-2.2-2.4-3-3.3-5.2
							C592.6,196.7,591.6,197.6,590.5,199.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M593.1,198.5c-0.9,0.7-1.1,1.7-0.8,2.5c0.4,0.9,1.3,1.6,2.4,2c1.2,0.3,2.3,0.2,3.1-0.2c0.8-0.4,1.2-1.1,0.9-2.1
							c-0.5-2-1.2-3.1-0.9-5.1C596.3,197,595.1,197.2,593.1,198.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M629.5,211.2c3.3,2.5,6.7,4,9.9,4.5c3.2,0.5,6.1,0.1,7.6-1.9c1.5-1.9,0.9-5.1-1.5-8c-2.4-2.9-6.8-5.5-11.7-6
							c-5.1-0.5-8.5-0.1-11.9,0.2c-3.4,0.4-6.7,0.8-11.3,1.5c4.6,0.9,7.6,2.2,10.3,3.7C623.6,206.8,626.1,208.6,629.5,211.2z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M241.1,523.3c3.8,4.2,8.2,7.3,12.1,9.2c3.9,1.8,7.4,2.3,9.1,0.6c1.7-1.7,1.1-5.3-1.4-9.3c-2.6-3.9-7.1-8.1-12.8-10.5
							c-5.8-2.5-10-3.6-14.1-4.8c-4.1-1.1-8.3-2.3-14.2-4.1c5.3,3.1,8.6,5.8,11.6,8.7C234.3,516,237.1,519,241.1,523.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M230.3,532.5c1.7,4.7,4.2,8.8,6.9,11.6c2.7,2.8,5.6,4.4,7.9,3.4c2.2-0.9,3.3-4.2,2.6-8.5c-0.7-4.2-3.2-9.4-7.3-13.3
							c-4.2-4-7.5-6.2-10.7-8.5c-3.3-2.2-6.5-4.4-11.1-7.6c3.6,4.3,5.5,7.6,7.1,11C227.3,524.1,228.6,527.6,230.3,532.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M215.4,533.7c-0.3,3.7,0.5,7,2,9.6c1.5,2.5,3.7,4.3,6.1,4.2c2.4-0.1,4.5-2.2,5.4-5.4c0.9-3.2,0.5-7.6-1.7-11.3
							c-2.3-3.8-4.3-6.2-6.3-8.5c-2-2.3-3.9-4.5-6.5-7.8c1.3,4,1.6,6.8,1.6,9.7C216,527.1,215.7,529.9,215.4,533.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M197.2,528.3c-2.1,2.7-2.8,5.7-2.5,8.4c0.3,2.6,1.7,4.9,4,5.8c2.3,0.9,4.9,0.1,6.9-1.9c2-2,3.4-5.2,3.2-8.8
							c-0.2-3.7-0.7-6.3-1.1-8.9c-0.4-2.5-0.7-5.1-0.8-8.6c-1.4,3.3-2.8,5.4-4.3,7.4C201.1,523.7,199.4,525.5,197.2,528.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M184.6,519.6c-3.4,1.1-6,3-7.7,5.3c-1.7,2.3-2.5,4.9-1.5,7.2c0.9,2.2,3.6,3.5,7,3.1c3.3-0.4,7.2-2.4,9.7-5.9
							c2.6-3.6,3.8-6.4,5-9.1c1.2-2.7,2.3-5.4,4-9.1c-2.9,2.8-5.4,4.3-7.8,5.4C190.7,517.6,188.1,518.4,184.6,519.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M179.7,503.2c-3.5-1.1-6.6-1.1-9.4-0.5c-0.7,0.2-1.4,0.3-2,0.6c-0.6,0.3-1.2,0.6-1.7,0.9c-1.1,0.7-1.9,1.6-2.3,2.8
							c-0.4,1.1-0.3,2.4,0.3,3.6c0.3,0.6,0.7,1.2,1.3,1.8c0.6,0.6,1.2,1.1,2,1.6c3.1,1.9,8,2.8,12.5,1.3c4.6-1.6,7.4-3.3,10.1-5.1
							c2.6-1.8,5.1-3.7,8.4-6.4c-4.1,1.4-7.1,1.6-9.9,1.4C186,504.9,183.3,504.3,179.7,503.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M185.6,484.9c-2.6-3.1-5.7-5.2-8.7-6.3c-3-1.1-5.9-1.1-7.7,0.5c-1.8,1.6-1.9,4.8-0.1,8.1c1.8,3.3,5.4,6.6,10.1,8
							c4.8,1.4,8.2,1.8,11.5,2.1c3.3,0.3,6.5,0.5,11.2,0.9c-4.3-1.8-6.8-3.7-9.1-5.8C190.4,490.4,188.4,488.1,185.6,484.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M195.9,472.3c-1.1-4.1-3-7.4-5.1-9.9c-0.6-0.6-1.1-1.2-1.7-1.7c-0.6-0.5-1.2-0.9-1.8-1.2c-1.2-0.6-2.4-0.9-3.6-0.7
							c-1.2,0.3-2.2,1.1-2.8,2.4c-0.3,0.6-0.6,1.4-0.8,2.2c-0.2,0.8-0.3,1.8-0.2,2.8c0.1,4,2,9,5.8,12.6c3.9,3.7,7,5.5,10.1,7.2
							c3.1,1.7,6.2,3.3,10.6,5.4c-3.6-3.3-5.4-6.2-6.8-9.1C198.2,479.5,197.1,476.5,195.9,472.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M209.1,469.2c0.9-3.8,1.3-6.8,1.4-9.5c0.1-1.3,0-2.7-0.1-4c-0.1-1.3-0.4-2.5-1.2-3.5c-0.8-0.9-2-1.4-3.6-1.2
							c-1.6,0.2-3.6,1.2-5.3,3c-1.7,1.8-3.1,4.4-3.7,7.3c-0.6,2.9-0.6,6,0.3,8.9c0.9,3,2.1,5.2,3.3,7c1.2,1.8,2.4,3.3,3.6,4.8
							c2.4,2.9,4.9,5.5,8.1,9.5c-2-4.8-2.9-8.3-3.3-11.7c-0.2-1.7-0.3-3.4-0.3-5.2C208.4,473,208.6,471.1,209.1,469.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M226.2,472.6c3.1-2.2,5.3-4.3,6.9-6.6c0.8-1.1,1.5-2.3,2.1-3.5c0.5-1.2,0.8-2.4,0.6-3.6c-0.2-1.2-1.1-2.2-2.5-2.8
							c-1.4-0.6-3.3-0.8-5.5-0.4c-2.2,0.4-4.5,1.5-6.6,3.3c-2,1.7-3.8,4-4.8,6.5c-1.1,2.7-1.6,4.9-1.9,6.9c-0.3,2-0.4,3.8-0.5,5.6
							c-0.1,3.5,0,6.9-0.1,11.8c1.5-4.6,3.1-7.6,5.1-10.1c1-1.3,2-2.5,3.2-3.6C223.3,474.9,224.6,473.7,226.2,472.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M236.8,481.9c4-0.8,7.3-2.3,9.9-4.3c0.6-0.5,1.2-1,1.7-1.5c0.5-0.5,1-1.1,1.3-1.6c0.7-1.1,1.1-2.3,1-3.6
							c-0.2-1.2-0.9-2.3-2.1-3.1c-0.6-0.4-1.3-0.8-2.1-1c-0.8-0.2-1.7-0.4-2.7-0.5c-3.9-0.3-9.1,1.3-12.8,4.9
							c-3.8,3.7-5.8,6.7-7.7,9.6c-1.8,3-3.5,6-5.6,10.2c3.4-3.4,6.2-5.1,9.1-6.3C229.7,483.6,232.7,482.8,236.8,481.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M245.2,494.6c4,0.9,7.4,1,10.3,0.8c1.5-0.1,2.9-0.4,4.1-0.8c1.2-0.4,2.4-1,3.1-2c0.7-1,0.8-2.3,0.3-3.8
							c-0.5-1.5-1.8-3-3.7-4.4c-1.9-1.3-4.3-2.3-7-2.7c-2.7-0.4-5.6-0.2-8.3,0.7c-2.8,0.9-4.9,2-6.7,3c-1.8,1-3.3,2.1-4.8,3.1
							c-3,2.1-5.8,4.2-10.1,6.8c4.9-1.3,8.4-1.8,11.8-1.8c1.7,0,3.4,0,5.2,0.2C241.2,493.9,243.1,494.1,245.2,494.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M211.7,496.8c-2.4,0.4-2.8,3.2-1.3,5c1.6,1.9,4.4,1.9,5.2-0.4c0.8-2.4,0.8-3.7,2-5.7
							C215.4,496.7,214.2,496.4,211.7,496.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M215,498.4c-1.1-0.4-2-0.1-2.7,0.6c-0.7,0.7-1.1,1.8-1,3c0.1,1.2,0.8,2.2,1.7,2.7c0.9,0.5,2.2,0.5,3.2-0.4
							c1-0.9,1.7-1.6,2.3-2.3c0.6-0.7,1.3-1.4,2.2-2.1c-1.2,0-2.1-0.2-2.9-0.4C217,499.2,216.2,498.9,215,498.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M215.5,501.1c-0.2-0.9-0.7-1.3-1.5-1.4c-0.8-0.1-1.9,0.1-3,0.8c-1,0.6-1.7,1.7-1.6,2.8c0.1,1.1,0.9,2.2,2.2,2.5
							c1.4,0.3,2.4,0.2,3.3,0.1c0.9-0.1,1.8-0.2,2.9-0.2c-0.9-0.7-1.3-1.4-1.6-2.1C215.9,502.9,215.7,502.1,215.5,501.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M213.9,504.3c0.4-0.3,0.6-0.7,0.8-1.1c0.1-0.5,0.1-1,0-1.6c-0.1-0.5-0.4-1.1-0.9-1.4c-0.4-0.4-1-0.6-1.6-0.7
							c-0.6-0.1-1.2-0.1-1.7,0c-0.5,0.1-1,0.2-1.4,0.4c-0.4,0.2-0.7,0.6-0.9,1c-0.2,0.5-0.3,1.1-0.2,1.9c0.1,0.8,0.3,1.5,0.6,2.1
							c0.3,0.6,0.6,1.1,0.9,1.5c0.7,0.8,1.5,1.6,2.7,2c-0.4-0.5-0.5-1-0.4-1.4c0-0.4,0.2-0.8,0.4-1.1c0.2-0.3,0.5-0.6,0.7-0.9
							C213.1,504.8,213.5,504.6,213.9,504.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M211.8,505.3c1.1-0.1,1.7-0.6,2.1-1.5c0.3-0.8,0.5-2,0-3.2c-0.4-1.1-1.4-1.9-2.6-2c-1.2-0.1-2.5,0.5-3.2,1.9
							c-0.7,1.4-0.9,2.4-1.1,3.4c-0.2,1-0.4,1.9-0.7,3.2c0.9-0.8,1.8-1.2,2.7-1.4C209.7,505.4,210.6,505.4,211.8,505.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M208.4,505.3c1.4,0.5,2.8,0.1,3.7-0.7c0.9-0.8,1.2-2,0.9-3.2c-0.3-1.2-1.2-2.1-2.1-2.5c-0.9-0.4-2-0.4-3,0.3
							c-1.1,0.7-1.9,1.3-2.7,1.8c-0.8,0.5-1.7,0.9-3.1,1.1c1.2,0.5,2.1,1,3,1.6C206,504.2,206.9,504.8,208.4,505.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M206.6,501.5c0.6,2.6,3.6,3.1,5.4,1.5c1.8-1.6,1.7-4.6-0.8-5.5c-2.6-1-3.9-1-6.1-2.3
							C206.1,497.5,205.9,498.8,206.6,501.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M207.3,497.9c-1.1,2.2,0.8,4.3,3.2,4.2c2.4-0.1,4.2-2.4,2.8-4.5c-1.4-2.2-2.4-3-3.3-5.2
							C209.5,494.8,208.5,495.6,207.3,497.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M210,496.6c-0.9,0.7-1.1,1.7-0.8,2.5c0.4,0.9,1.3,1.6,2.4,2c1.2,0.3,2.3,0.2,3.1-0.2c0.8-0.4,1.2-1.1,0.9-2.1
							c-0.5-2-1.2-3.1-0.9-5.1C213.2,495,211.9,495.2,210,496.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M246.4,509.3c3.3,2.5,6.7,4,9.9,4.5c3.2,0.5,6.1,0.1,7.6-1.9c1.5-1.9,0.9-5.1-1.5-8c-2.4-2.9-6.8-5.5-11.7-6
							c-5.1-0.5-8.5-0.1-11.9,0.2c-3.4,0.4-6.7,0.8-11.3,1.5c4.6,0.9,7.6,2.2,10.3,3.7C240.4,504.9,243,506.7,246.4,509.3z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M155.7,208.9c3.8,4.2,8.2,7.3,12.1,9.2c3.9,1.8,7.4,2.3,9.1,0.6c1.7-1.7,1.1-5.3-1.4-9.3c-2.6-3.9-7.1-8.1-12.8-10.5
							c-5.8-2.5-10-3.6-14.1-4.8c-4.1-1.1-8.3-2.3-14.2-4.1c5.3,3.1,8.6,5.8,11.6,8.7C148.9,201.6,151.7,204.6,155.7,208.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M144.9,218.2c1.7,4.7,4.2,8.8,6.9,11.6c2.7,2.8,5.6,4.4,7.9,3.4c2.2-0.9,3.3-4.2,2.6-8.5c-0.7-4.2-3.2-9.4-7.3-13.3
							c-4.2-4-7.5-6.2-10.7-8.5c-3.3-2.2-6.5-4.4-11.1-7.6c3.6,4.3,5.5,7.6,7.1,11C141.9,209.8,143.2,213.3,144.9,218.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M130,219.4c-0.3,3.7,0.5,7,2,9.6c1.5,2.5,3.7,4.3,6.1,4.2c2.4-0.1,4.5-2.2,5.4-5.4c0.9-3.2,0.5-7.6-1.7-11.3
							c-2.3-3.8-4.3-6.2-6.3-8.5c-2-2.3-3.9-4.5-6.5-7.8c1.3,4,1.6,6.8,1.6,9.7C130.6,212.7,130.3,215.5,130,219.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M111.8,214c-2.1,2.7-2.8,5.7-2.5,8.4c0.3,2.6,1.7,4.9,4,5.8c2.3,0.9,4.9,0.1,6.9-1.9c2-2,3.4-5.2,3.2-8.8
							c-0.2-3.7-0.7-6.3-1.1-8.9c-0.4-2.5-0.7-5.1-0.8-8.6c-1.4,3.3-2.8,5.4-4.3,7.4C115.7,209.3,114,211.2,111.8,214z"
                      />
                    </g>
                    <g>
                      <path
                        d="M99.2,205.2c-3.4,1.1-6,3-7.7,5.3c-1.7,2.3-2.5,4.9-1.5,7.2c0.9,2.2,3.6,3.5,7,3.1c3.3-0.4,7.2-2.4,9.7-5.9
							c2.6-3.6,3.8-6.4,5-9.1c1.2-2.7,2.3-5.4,4-9.1c-2.9,2.8-5.4,4.3-7.8,5.4C105.3,203.2,102.7,204.1,99.2,205.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M94.3,188.9c-3.5-1.1-6.6-1.1-9.4-0.5c-0.7,0.2-1.4,0.3-2,0.6c-0.6,0.3-1.2,0.6-1.7,0.9c-1.1,0.7-1.9,1.6-2.3,2.8
							c-0.4,1.1-0.3,2.4,0.3,3.6c0.3,0.6,0.7,1.2,1.3,1.8c0.6,0.6,1.2,1.1,2,1.6c3.1,1.9,8,2.8,12.5,1.3c4.6-1.6,7.4-3.3,10.1-5.1
							c2.6-1.8,5.1-3.7,8.4-6.4c-4.1,1.4-7.1,1.6-9.9,1.4C100.6,190.6,97.9,190,94.3,188.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M100.2,170.6c-2.6-3.1-5.7-5.2-8.7-6.3c-3-1.1-5.9-1.1-7.7,0.5c-1.8,1.6-1.9,4.8-0.1,8.1c1.8,3.3,5.4,6.6,10.1,8
							c4.8,1.4,8.2,1.8,11.5,2.1c3.3,0.3,6.5,0.5,11.2,0.9c-4.3-1.8-6.8-3.7-9.1-5.8C105,176.1,103,173.8,100.2,170.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M110.5,158c-1.1-4.1-3-7.4-5.1-9.9c-0.6-0.6-1.1-1.2-1.7-1.7c-0.6-0.5-1.2-0.9-1.8-1.2c-1.2-0.6-2.4-0.9-3.6-0.7
							c-1.2,0.3-2.2,1.1-2.8,2.4c-0.3,0.6-0.6,1.4-0.8,2.2c-0.2,0.8-0.3,1.8-0.2,2.8c0.1,4,2,9,5.8,12.6c3.9,3.7,7,5.5,10.1,7.2
							c3.1,1.7,6.2,3.3,10.6,5.4c-3.6-3.3-5.4-6.2-6.8-9.1C112.8,165.2,111.7,162.2,110.5,158z"
                      />
                    </g>
                    <g>
                      <path
                        d="M123.7,154.9c0.9-3.8,1.3-6.8,1.4-9.5c0.1-1.3,0-2.7-0.1-4c-0.1-1.3-0.4-2.5-1.2-3.5c-0.8-0.9-2-1.4-3.6-1.2
							c-1.6,0.2-3.6,1.2-5.3,3c-1.7,1.8-3.1,4.4-3.7,7.3c-0.6,2.9-0.6,6,0.3,8.9c0.9,3,2.1,5.2,3.3,7c1.2,1.8,2.4,3.3,3.6,4.8
							c2.4,2.9,4.9,5.5,8.1,9.5c-2-4.8-2.9-8.3-3.3-11.7c-0.2-1.7-0.3-3.4-0.3-5.2C123,158.7,123.2,156.8,123.7,154.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M140.8,158.2c3.1-2.2,5.3-4.3,6.9-6.6c0.8-1.1,1.5-2.3,2.1-3.5c0.5-1.2,0.8-2.4,0.6-3.6c-0.2-1.2-1.1-2.2-2.5-2.8
							c-1.4-0.6-3.3-0.8-5.5-0.4c-2.2,0.4-4.5,1.5-6.6,3.3c-2,1.7-3.8,4-4.8,6.5c-1.1,2.7-1.6,4.9-1.9,6.9c-0.3,2-0.4,3.8-0.5,5.6
							c-0.1,3.5,0,6.9-0.1,11.8c1.5-4.6,3.1-7.6,5.1-10.1c1-1.3,2-2.5,3.2-3.6C137.9,160.6,139.2,159.4,140.8,158.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M151.4,167.6c4-0.8,7.3-2.3,9.9-4.3c0.6-0.5,1.2-1,1.7-1.5c0.5-0.5,1-1.1,1.3-1.6c0.7-1.1,1.1-2.3,1-3.6
							c-0.2-1.2-0.9-2.3-2.1-3.1c-0.6-0.4-1.3-0.8-2.1-1c-0.8-0.2-1.7-0.4-2.7-0.5c-3.9-0.3-9.1,1.3-12.8,4.9
							c-3.8,3.7-5.8,6.7-7.7,9.6c-1.8,3-3.5,6-5.6,10.2c3.4-3.4,6.2-5.1,9.1-6.3C144.2,169.3,147.3,168.5,151.4,167.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M159.8,180.2c4,0.9,7.4,1,10.3,0.8c1.5-0.1,2.9-0.4,4.1-0.8c1.2-0.4,2.4-1,3.1-2c0.7-1,0.8-2.3,0.3-3.8
							c-0.5-1.5-1.8-3-3.7-4.4c-1.9-1.3-4.3-2.3-7-2.7c-2.7-0.4-5.6-0.2-8.3,0.7c-2.8,0.9-4.9,2-6.7,3c-1.8,1-3.3,2.1-4.8,3.1
							c-3,2.1-5.8,4.2-10.1,6.8c4.9-1.3,8.4-1.8,11.8-1.8c1.7,0,3.4,0,5.2,0.2C155.8,179.5,157.7,179.8,159.8,180.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M126.3,182.5c-2.4,0.4-2.8,3.2-1.3,5c1.6,1.9,4.4,1.9,5.2-0.4c0.8-2.4,0.8-3.7,2-5.7
							C130,182.3,128.8,182.1,126.3,182.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M129.6,184.1c-1.1-0.4-2-0.1-2.7,0.6c-0.7,0.7-1.1,1.8-1,3c0.1,1.2,0.8,2.2,1.7,2.7c0.9,0.5,2.2,0.5,3.2-0.4
							c1-0.9,1.7-1.6,2.3-2.3c0.6-0.7,1.3-1.4,2.2-2.1c-1.2,0-2.1-0.2-2.9-0.4C131.6,184.9,130.8,184.5,129.6,184.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M130.1,186.7c-0.2-0.9-0.7-1.3-1.5-1.4c-0.8-0.1-1.9,0.1-3,0.8c-1,0.6-1.7,1.7-1.6,2.8c0.1,1.1,0.9,2.2,2.2,2.5
							c1.4,0.3,2.4,0.2,3.3,0.1c0.9-0.1,1.8-0.2,2.9-0.2c-0.9-0.7-1.3-1.4-1.6-2.1C130.5,188.5,130.3,187.7,130.1,186.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M128.5,190c0.4-0.3,0.6-0.7,0.8-1.1c0.1-0.5,0.1-1,0-1.6c-0.1-0.5-0.4-1.1-0.9-1.4c-0.4-0.4-1-0.6-1.6-0.7
							c-0.6-0.1-1.2-0.1-1.7,0c-0.5,0.1-1,0.2-1.4,0.4c-0.4,0.2-0.7,0.6-0.9,1c-0.2,0.5-0.3,1.1-0.2,1.9c0.1,0.8,0.3,1.5,0.6,2.1
							c0.3,0.6,0.6,1.1,0.9,1.5c0.7,0.8,1.5,1.6,2.7,2c-0.4-0.5-0.5-1-0.4-1.4c0-0.4,0.2-0.8,0.4-1.1c0.2-0.3,0.5-0.6,0.7-0.9
							C127.7,190.4,128.1,190.3,128.5,190z"
                      />
                    </g>
                    <g>
                      <path
                        d="M126.4,190.9c1.1-0.1,1.7-0.6,2.1-1.5c0.3-0.8,0.5-2,0-3.2c-0.4-1.1-1.4-1.9-2.6-2c-1.2-0.1-2.5,0.5-3.2,1.9
							c-0.7,1.4-0.9,2.4-1.1,3.4c-0.2,1-0.4,1.9-0.7,3.2c0.9-0.8,1.8-1.2,2.7-1.4C124.3,191.1,125.2,191,126.4,190.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M123,191c1.4,0.5,2.8,0.1,3.7-0.7c0.9-0.8,1.2-2,0.9-3.2c-0.3-1.2-1.2-2.1-2.1-2.5c-0.9-0.4-2-0.4-3,0.3
							c-1.1,0.7-1.9,1.3-2.7,1.8c-0.8,0.5-1.7,0.9-3.1,1.1c1.2,0.5,2.1,1,3,1.6C120.6,189.9,121.5,190.4,123,191z"
                      />
                    </g>
                    <g>
                      <path
                        d="M121.2,187.2c0.6,2.6,3.6,3.1,5.4,1.5c1.8-1.6,1.7-4.6-0.8-5.5c-2.6-1-3.9-1-6.1-2.3
							C120.7,183.2,120.5,184.5,121.2,187.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M121.9,183.6c-1.1,2.2,0.8,4.3,3.2,4.2c2.4-0.1,4.2-2.4,2.8-4.5c-1.4-2.2-2.4-3-3.3-5.2
							C124.1,180.4,123.1,181.3,121.9,183.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M124.6,182.2c-0.9,0.7-1.1,1.7-0.8,2.5c0.4,0.9,1.3,1.6,2.4,2c1.2,0.3,2.3,0.2,3.1-0.2c0.8-0.4,1.2-1.1,0.9-2.1
							c-0.5-2-1.2-3.1-0.9-5.1C127.8,180.7,126.5,180.9,124.6,182.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M161,194.9c3.3,2.5,6.7,4,9.9,4.5c3.2,0.5,6.1,0.1,7.6-1.9c1.5-1.9,0.9-5.1-1.5-8c-2.4-2.9-6.8-5.5-11.7-6
							c-5.1-0.5-8.5-0.1-11.9,0.2c-3.4,0.4-6.7,0.8-11.3,1.5c4.6,0.9,7.6,2.2,10.3,3.7C155,190.5,157.6,192.3,161,194.9z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M353.7,131.6c-6.1,1.4-12.7,4.3-17.8,8c-2.6,1.8-4.9,3.9-7,6c-2,2.1-3.7,4.3-5.1,6.4c-2.8,4.3-4.2,8.4-4.6,11.5
							c-0.2,1.6-0.1,2.9,0.2,3.9c0.3,1,0.9,1.8,1.6,2.2c0.8,0.4,1.7,0.6,2.7,0.4c1-0.2,2.2-0.6,3.5-1.2c2.5-1.2,5.5-3.2,8.8-5.5
							c1.6-1.1,3.4-2.3,5.2-3.6c1.8-1.2,3.8-2.5,5.8-3.8c4-2.7,8.2-5.1,13.1-8c5-3,9.8-5.5,14-8.2c4.2-2.6,8-5.4,11.3-8.5
							c1.7-1.5,3.3-3.1,4.8-4.8c1.6-1.7,3.1-3.5,4.6-5.5c3-3.9,6-8.5,8.6-14.3c-3.6,5.3-7.4,9-11.1,11.8c-1.9,1.4-3.7,2.6-5.5,3.7
							c-1.8,1.1-3.7,2-5.5,2.7c-3.7,1.6-7.5,2.6-11.9,3.5C365,129.4,360,130.1,353.7,131.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M362.6,150.6c-5,2.2-10,4.1-14.7,5.9c-4.7,1.7-9.2,3.3-13.1,4.7c-4,1.4-7.4,2.8-9.9,4.2c-2.5,1.4-4.1,2.9-4.3,4.7
							c-0.1,1.7,1.3,3.5,4.2,5c2.8,1.5,7.1,2.5,12.3,2.7c5.2,0.2,11.2-0.6,17.4-2.6c6.1-2.1,12.3-5.3,17.6-9.8
							c5.4-4.6,9.3-9,12.4-13.3c3.1-4.3,5.4-8.3,7.5-12.4c2-4.1,3.8-8.4,5.4-13.2c1.6-4.8,3-10.2,4-16.7c-2,6.2-4.5,11.1-7.2,15.2
							c-2.6,4.1-5.4,7.4-8.4,10.3c-3,3-6.2,5.6-10,8.1C372,145.9,367.8,148.3,362.6,150.6z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M401.3,173.5c-6-1.9-13.1-2.8-19.4-2.2c-3.2,0.3-6.2,0.8-9,1.6c-2.8,0.8-5.4,1.8-7.7,2.9c-4.6,2.3-7.9,5-9.8,7.5
							c-1,1.2-1.6,2.4-1.8,3.5c-0.3,1.1-0.2,2,0.3,2.7c0.4,0.8,1.2,1.3,2.2,1.7c1,0.4,2.2,0.6,3.6,0.8c2.8,0.2,6.3,0.1,10.3-0.2
							c2-0.1,4.1-0.3,6.4-0.4c2.2-0.1,4.5-0.3,6.9-0.4c4.8-0.2,9.7-0.2,15.4-0.2c5.9,0,11.2,0.3,16.2,0.1c5-0.1,9.7-0.6,14.1-1.5
							c2.2-0.4,4.4-1,6.6-1.7c2.2-0.7,4.4-1.4,6.7-2.4c4.6-1.9,9.5-4.3,14.7-7.9c-5.8,2.7-10.9,3.9-15.6,4.5
							c-2.3,0.3-4.5,0.4-6.7,0.3c-2.1,0-4.2-0.2-6.2-0.5c-4-0.5-7.8-1.6-12-3C412.2,177.4,407.6,175.5,401.3,173.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M399.3,194.3c-5.4-0.7-10.7-1.6-15.6-2.5c-4.9-0.9-9.6-1.9-13.7-2.7c-4.1-0.8-7.8-1.4-10.7-1.5
							c-2.9-0.1-5.1,0.4-6.1,1.9c-1,1.4-0.6,3.7,1.1,6.4c1.7,2.7,4.8,5.8,9.2,8.6c4.3,2.8,9.9,5.3,16.3,6.7
							c6.3,1.4,13.3,1.8,20.1,0.6c7-1.1,12.6-3,17.5-5c4.8-2.1,8.9-4.4,12.8-6.9c3.9-2.5,7.6-5.3,11.4-8.6c3.8-3.3,7.8-7.3,12-12.3
							c-4.9,4.3-9.6,7.2-13.9,9.4c-4.4,2.2-8.4,3.5-12.5,4.6c-4.1,1-8.2,1.6-12.7,1.9C409.8,195.2,404.9,195.1,399.3,194.3z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M143.2,295.9c4-4.8,7.5-11.1,9.3-17.2c0.9-3.1,1.6-6.1,1.9-9c0.3-2.9,0.3-5.7,0.2-8.2c-0.4-5.1-1.7-9.2-3.3-11.9
							c-0.8-1.4-1.7-2.4-2.6-3c-0.9-0.6-1.8-0.9-2.6-0.8c-0.9,0.1-1.7,0.6-2.4,1.3c-0.7,0.8-1.4,1.8-2,3.1c-1.3,2.5-2.4,5.9-3.7,9.7
							c-0.6,1.9-1.3,3.9-2,6.1c-0.7,2.1-1.4,4.3-2.2,6.6c-1.6,4.6-3.4,9-5.5,14.3c-2.2,5.4-4.4,10.3-6.2,15
							c-1.7,4.7-3.1,9.2-3.8,13.6c-0.4,2.2-0.7,4.5-0.9,6.8c-0.2,2.3-0.3,4.7-0.3,7.1c0,4.9,0.4,10.4,1.8,16.6
							c-0.4-6.3,0.4-11.6,1.6-16.1c0.6-2.3,1.3-4.4,2.2-6.3c0.8-2,1.7-3.8,2.7-5.5c2-3.5,4.4-6.6,7.3-10
							C135.6,304.6,139.1,300.9,143.2,295.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M124.7,286.2c2.7-4.8,5.5-9.3,8.1-13.6c2.7-4.3,5.3-8.2,7.6-11.7c2.3-3.5,4.2-6.7,5.4-9.3c1.2-2.6,1.5-4.9,0.5-6.3
							c-1-1.5-3.2-2-6.4-1.4c-3.2,0.6-7.2,2.3-11.4,5.3c-4.2,3-8.6,7.3-12.2,12.6c-3.6,5.4-6.6,11.7-8.1,18.4
							c-1.5,6.9-1.9,12.8-1.8,18.1c0.1,5.3,0.7,9.9,1.6,14.4c0.9,4.5,2.1,9,3.7,13.8c1.7,4.8,3.8,9.9,7,15.7
							c-2.2-6.2-3.2-11.6-3.5-16.4c-0.4-4.8-0.2-9.2,0.4-13.3c0.6-4.2,1.6-8.2,3-12.5C120,295.7,121.9,291.1,124.7,286.2z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M453.4,473.1c-7.8,2.4-14.6,6.2-19.4,10.2c-4.8,4-7.6,8.2-6.4,11.6c1.2,3.3,6.3,4.8,13,3.8c6.7-1,14.9-4.4,21.5-10.4
							c6.8-6.1,10.9-10.9,14.9-15.6c4-4.7,8-9.4,14-16.1c-7.3,5.1-12.9,7.7-18.4,9.9C467.1,468.8,461.4,470.6,453.4,473.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M447.9,453.4c-7.2-0.7-14.1,0.2-19.4,2c-5.3,1.8-9.1,4.7-9.3,8.2c-0.2,3.5,3.5,6.8,9.5,8.5c6,1.7,14.2,1.6,21.8-1.4
							c7.8-3.1,12.7-6,17.6-8.9c4.9-2.9,9.7-5.8,16.7-9.9c-7.8,2.1-13.3,2.5-18.7,2.5C460.7,454.5,455.3,454.1,447.9,453.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M455.3,433.2c-4.7-2.7-9.5-3.7-13.7-3.3c-4.2,0.4-7.9,2.2-9.3,5.4c-1.4,3.2,0.1,7.2,3.8,10.4
							c3.7,3.1,9.6,5.3,15.9,4.7c6.4-0.7,10.7-1.9,14.9-3.1c4.2-1.2,8.3-2.4,14.1-3.8c-6-0.7-9.9-2.1-13.6-3.8
							C463.7,438,460.2,435.9,455.3,433.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M473.5,412.7c-2.2-4.4-5.7-7.2-9.4-8.4c-3.7-1.2-7.5-0.8-10.1,1.7c-2.6,2.4-3.1,6.3-1.8,10.2c1.4,3.9,4.7,7.6,9.6,9.6
							c5,2,8.7,2.9,12.3,3.9c3.6,1,7.1,2.1,11.8,4.3c-3.4-3.8-5.3-7-7-10.2C477.3,420.6,475.8,417.2,473.5,412.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M492.7,401.5c0.7-5.1-0.3-9.7-2.2-13.3c-1.9-3.6-4.9-6.3-8.4-6.4c-3.5-0.1-6.7,2.6-8.3,7.2
							c-1.5,4.5-1.3,10.8,1.8,16.3c3.2,5.6,6.1,8.9,8.9,12.1c2.8,3.2,5.6,6.3,9.4,10.8c-1.9-5.5-2.3-9.6-2.3-13.6
							C491.6,410.7,492,406.8,492.7,401.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M517.1,405c3.5-3.9,5.5-8,6.4-12c0.2-1,0.4-2,0.4-3c0-1,0-1.9-0.1-2.8c-0.3-1.8-1-3.5-2.2-4.7
							c-1.3-1.2-2.9-1.9-4.9-1.8c-1,0-2.1,0.2-3.1,0.6c-1.1,0.4-2.2,0.9-3.3,1.6c-4.4,2.8-8.5,8.7-9.3,15.5
							c-0.8,7-0.1,11.7,0.6,16.3c0.8,4.5,1.7,8.9,3.2,15c0.6-6.2,2.3-10.2,4.3-13.8C511,412.3,513.5,409,517.1,405z"
                      />
                    </g>
                    <g>
                      <path
                        d="M537.4,423.9c5.7-1.6,10.3-4.3,13.5-7.6c3.2-3.2,5.1-7.1,4.1-10.4c-1-3.3-5.1-5.3-10.5-5c-5.4,0.3-11.9,3.1-16.6,8.4
							c-4.8,5.4-7.3,9.6-9.8,13.8c-2.3,4.1-4.7,8.2-8.1,14.1c5-4.5,9-6.7,13.1-8.4C527.3,426.9,531.5,425.6,537.4,423.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M547.6,445.1c6,1,11.5,0.6,16-0.7c1.1-0.4,2.2-0.7,3.2-1.2c1-0.5,1.9-1,2.7-1.6c1.6-1.2,2.7-2.6,3.1-4.4
							c0.4-1.7-0.1-3.5-1.4-5.2c-0.6-0.8-1.4-1.7-2.5-2.4c-1-0.7-2.2-1.5-3.5-2c-5.2-2.3-13-2.8-20,0c-7.2,2.9-11.5,5.9-15.7,8.8
							c-4.1,3-8.1,6.1-13.5,10.5c6.6-2.7,11.4-3.3,16-3.4C536.8,443.6,541.4,444.1,547.6,445.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M543.7,464.2c4.4,3.5,8.1,5.9,11.5,7.7c1.7,0.9,3.5,1.7,5.2,2.3c1.7,0.6,3.5,1,5.3,0.6c1.7-0.4,3.1-1.7,3.8-4
							c0.7-2.2,0.6-5.4-0.8-8.8c-1.3-3.3-3.9-6.8-7.2-9.3c-3.4-2.6-7.5-4.4-11.9-5c-4.5-0.6-8.1-0.4-11.2,0
							c-3.1,0.4-5.8,1.1-8.4,1.8c-5.2,1.4-10.1,3.1-17.4,4.9c7.5,0.3,12.6,1.2,17.3,2.7c2.3,0.8,4.6,1.7,6.9,2.8
							C539.1,461,541.5,462.4,543.7,464.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M528.8,484.6c1,5.4,2.5,9.5,4.4,13.1c1,1.8,2.1,3.4,3.3,4.8c1.2,1.4,2.7,2.5,4.4,2.9c1.7,0.4,3.5,0,5.2-1.5
							c1.7-1.4,3.1-3.9,3.9-7c0.7-3.1,0.7-6.9-0.2-10.6c-1-3.7-2.9-7.4-5.6-10.3c-2.8-3.1-5.4-5.1-7.9-6.8c-2.5-1.7-4.7-2.9-7-4.1
							c-4.6-2.3-9.1-4.3-15.4-7.3c5.1,4.8,8,8.7,10.2,12.8c1.1,2,2,4.1,2.8,6.4C527.6,479.3,528.3,481.8,528.8,484.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M510.1,492.7c-1.4,5.7-1.4,11-0.4,15.5c0.3,1.1,0.5,2.2,0.9,3.2c0.4,1,0.8,1.9,1.3,2.7c1,1.6,2.4,2.9,4.1,3.4
							c1.7,0.5,3.5,0.2,5.3-0.9c0.9-0.5,1.8-1.3,2.6-2.2c0.8-0.9,1.6-2,2.3-3.3c2.7-5,3.8-12.7,1.5-19.8c-2.4-7.3-5.2-11.7-7.9-15.9
							c-2.8-4.2-5.7-8.2-10-13.6c2.4,6.5,2.8,11.3,2.7,15.8C512.2,482.3,511.5,486.8,510.1,492.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M488.5,496c-3.6,4.8-5.9,9-7.3,13c-0.7,2-1.3,4-1.5,5.9c-0.2,1.9-0.1,3.7,0.8,5.3c0.9,1.5,2.5,2.5,4.7,2.7
							c2.2,0.2,5.1-0.5,7.9-2.1c2.8-1.6,5.7-4.3,7.8-7.6c2.1-3.3,3.6-7.2,4.1-11.2c0.5-4.2,0.4-7.6,0.2-10.6c-0.3-3-0.7-5.6-1.1-8.2
							c-0.9-5.1-1.9-10.1-2.8-17.4c-1.3,7.2-2.8,12.1-4.8,16.5c-1,2.2-2.1,4.4-3.4,6.7C491.8,491.2,490.3,493.6,488.5,496z"
                      />
                    </g>
                    <g>
                      <path
                        d="M505.9,450.8c0.9-3.4-2.4-5.6-5.8-4.7c-3.4,0.9-5.2,4.5-2.7,7c2.6,2.5,4.3,3.3,6.2,6.1
							C503.8,455.8,505,454.3,505.9,450.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M501.8,454.2c1.2-1.2,1.4-2.6,0.9-3.9c-0.5-1.3-1.7-2.5-3.4-3.1c-1.7-0.6-3.4-0.4-4.6,0.6c-1.2,0.9-1.9,2.6-1.4,4.4
							c0.5,1.9,1.1,3.2,1.6,4.5c0.5,1.3,1,2.5,1.4,4.2c0.7-1.6,1.5-2.6,2.4-3.6C499.5,456.3,500.5,455.4,501.8,454.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M498,453.2c1.3,0.3,2.1-0.1,2.7-1.2c0.6-1,1-2.6,0.8-4.4c-0.2-1.7-1.2-3.2-2.7-3.8c-1.5-0.6-3.4-0.2-4.6,1.4
							c-1.2,1.6-1.7,3-2.2,4.2c-0.4,1.3-0.8,2.4-1.5,3.9c1.4-0.8,2.6-0.9,3.7-0.9C495.5,452.6,496.6,452.9,498,453.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M494.8,449.1c0.1,0.7,0.5,1.2,1,1.7c0.5,0.4,1.3,0.8,2.1,0.9c0.8,0.1,1.7,0.1,2.4-0.3c0.8-0.3,1.4-0.9,1.9-1.6
							c0.5-0.7,0.8-1.5,1.1-2.2c0.2-0.7,0.3-1.4,0.3-2.1c-0.1-0.7-0.3-1.3-0.8-1.8c-0.5-0.6-1.3-1.1-2.4-1.4
							c-1.1-0.3-2.2-0.5-3.1-0.5c-0.9,0-1.7,0.1-2.5,0.3c-1.5,0.4-3,0.9-4.2,2.3c0.8-0.2,1.5,0,2.1,0.3c0.5,0.3,0.9,0.7,1.2,1.2
							c0.3,0.5,0.5,1,0.7,1.5C494.6,447.8,494.6,448.4,494.8,449.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M494.8,445.7c-0.6,1.5-0.3,2.6,0.6,3.6c0.9,1,2.4,1.8,4.1,2c1.7,0.2,3.4-0.6,4.2-2.1c0.9-1.5,0.9-3.7-0.5-5.4
							c-1.4-1.8-2.6-2.7-3.8-3.6c-1.2-0.9-2.3-1.7-3.7-2.8c0.5,1.8,0.5,3.1,0.2,4.4C495.9,443,495.4,444.2,494.8,445.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M496.9,441.3c-1.5,1.5-1.9,3.6-1.4,5.2c0.5,1.6,1.9,2.8,3.6,3.1c1.7,0.3,3.4-0.2,4.5-1.2c1.1-1,1.7-2.3,1.5-4.1
							c-0.3-1.8-0.6-3.2-0.7-4.6c-0.1-1.4-0.1-2.8,0.5-4.7c-1.5,1.3-2.6,2.1-3.9,3C499.7,438.9,498.4,439.7,496.9,441.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M502.9,441.2c-3.7-0.8-6.3,2.7-5.3,6.1c1,3.4,5,5,7.7,2.3c2.8-2.8,3.6-4.5,6.8-6.6C508.4,443.1,506.8,442,502.9,441.2
							z"
                      />
                    </g>
                    <g>
                      <path
                        d="M507.1,444.4c-2.2-2.9-6.2-1.6-7.5,1.7c-1.3,3.3,0.6,6.9,4.2,6.5c3.7-0.5,5.4-1.4,8.9-1.1
							C510,449.2,509.4,447.3,507.1,444.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M507.3,448.7c-0.3-1.6-1.5-2.5-2.9-2.6c-1.4-0.1-2.9,0.6-4.1,2c-1.2,1.3-1.7,2.9-1.7,4.1c0.1,1.3,0.7,2.2,2.2,2.4
							c3,0.6,4.8,0.4,7.3,1.9C507.4,453.8,507.9,452.1,507.3,448.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M468.5,488.6c-5.3,2.7-9.3,6.4-11.9,10.2c-2.6,3.8-3.8,7.9-2.1,11.1c1.6,3.1,6.1,4.3,11.4,2.9
							c5.3-1.4,11.3-5.5,15-11.7c3.7-6.3,5.4-11.1,7-15.7c1.5-4.7,3-9.2,5-15.7c-3.9,5.5-7.5,8.6-11.2,11.3
							C477.9,483.5,474,485.7,468.5,488.6z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M426.3,566.6c5.8-2.4,11.9-6.2,16.3-10.7c2.3-2.2,4.3-4.6,5.9-7c1.7-2.4,3-4.8,4-7.2c2.1-4.7,2.9-8.9,2.7-12.1
							c-0.1-1.6-0.4-2.9-0.8-3.9c-0.5-1-1.1-1.6-2-1.9c-0.8-0.3-1.8-0.3-2.8,0c-1,0.3-2.1,0.9-3.2,1.7c-2.3,1.6-4.9,4-7.8,6.8
							c-1.4,1.4-3,2.8-4.6,4.4c-1.6,1.5-3.3,3.1-5.1,4.7c-3.5,3.3-7.3,6.4-11.7,10c-4.5,3.7-8.8,7-12.5,10.3
							c-3.8,3.3-7,6.6-9.9,10.2c-1.4,1.8-2.7,3.6-4,5.5c-1.3,1.9-2.5,4-3.7,6.1c-2.3,4.4-4.5,9.3-6.2,15.5c2.7-5.8,5.9-10,9.1-13.4
							c1.6-1.7,3.2-3.2,4.9-4.5c1.6-1.3,3.3-2.5,5-3.6c3.4-2.1,7-3.8,11.2-5.4C415.5,570.6,420.3,569,426.3,566.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M414.5,549.3c4.6-2.9,9.2-5.6,13.6-8.1c4.4-2.5,8.6-4.7,12.2-6.7c3.7-2,6.9-3.9,9.1-5.7c2.3-1.8,3.6-3.6,3.5-5.3
							c-0.2-1.7-1.9-3.3-4.9-4.3c-3-1-7.4-1.4-12.6-0.7c-5.1,0.6-11,2.3-16.8,5.3c-5.7,3-11.4,7.2-15.8,12.4
							c-4.6,5.4-7.8,10.4-10.2,15.1c-2.4,4.7-4.1,9.1-5.4,13.5c-1.4,4.4-2.4,8.9-3.2,13.9c-0.8,5-1.3,10.6-1.3,17.1
							c1-6.5,2.7-11.7,4.7-16.1c2-4.4,4.2-8.1,6.7-11.5c2.5-3.4,5.3-6.5,8.5-9.6C405.9,555.4,409.8,552.3,414.5,549.3z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M372.6,532.7c6.2,0.9,13.4,0.7,19.5-0.9c3.1-0.8,6-1.8,8.7-3c2.7-1.2,5-2.6,7.1-4.1c4.2-3,7-6.2,8.5-9
							c0.7-1.4,1.2-2.7,1.2-3.7c0.1-1.1-0.1-2-0.7-2.7c-0.5-0.7-1.4-1.1-2.4-1.4c-1-0.2-2.3-0.3-3.7-0.2c-2.8,0.2-6.3,0.9-10.2,1.8
							c-1.9,0.4-4,0.9-6.2,1.4c-2.2,0.5-4.4,1-6.8,1.5c-4.7,1-9.5,1.8-15.1,2.6c-5.8,0.9-11.1,1.5-16,2.4c-4.9,0.9-9.4,2.1-13.7,3.7
							c-2.1,0.8-4.2,1.7-6.3,2.7c-2.1,1-4.2,2.1-6.3,3.4c-4.2,2.6-8.7,5.7-13.3,10.1c5.3-3.6,10.2-5.6,14.7-6.9
							c2.3-0.6,4.4-1.1,6.5-1.4c2.1-0.3,4.1-0.5,6.2-0.5c4-0.1,7.9,0.3,12.4,1.1C361.2,530.7,366.2,531.8,372.6,532.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M371.3,511.9c5.5-0.2,10.8-0.1,15.8,0c5,0.1,9.8,0.3,14,0.5c4.2,0.1,7.9,0.1,10.8-0.2c2.9-0.4,4.9-1.2,5.7-2.8
							c0.7-1.6,0.1-3.8-2.1-6.2c-2.1-2.4-5.7-5-10.4-7.1c-4.7-2.1-10.7-3.6-17.1-4c-6.5-0.3-13.4,0.4-20,2.6
							c-6.7,2.2-12,4.9-16.5,7.7c-4.4,2.8-8.1,5.7-11.6,8.8c-3.4,3.1-6.6,6.4-9.9,10.3c-3.2,3.9-6.6,8.4-9.8,14
							c4.2-5,8.3-8.7,12.2-11.5c4-2.8,7.8-4.8,11.6-6.5c3.9-1.6,7.8-2.9,12.2-3.9C360.8,512.7,365.7,512,371.3,511.9z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M514.4,139c10.6-1.1,21.3-3.7,30.7-7.7c9.4-3.9,17.6-9.3,23.7-14.8c6.1-5.5,10.2-11.1,12.5-15.3
							c2.3-4.2,2.9-6.9,2.1-7.4c-1.6-1-7.7,7.5-19.9,16.2c-6.1,4.3-13.6,8.7-22.2,12.2c-8.6,3.5-18.2,6.3-28.2,8.1
							c-10.2,1.9-19.1,2.6-27.2,3.2c-8.1,0.6-15.5,1.1-23,1.9c-7.4,0.8-14.9,1.9-23.1,4.1c-4.1,1.1-8.3,2.5-12.7,4.4
							c-4.4,1.9-8.9,4.3-13.4,7.6c9.3-6,18.4-8.6,26.5-10.2c8.2-1.5,15.6-1.9,22.9-2c7.4-0.1,14.7,0.2,22.9,0.5
							C494.2,139.9,503.4,140.1,514.4,139z"
                      />
                    </g>
                    <g>
                      <path
                        d="M458.8,128.6c2.7-1.5,4.5-4,5.1-6.3c0.6-2.3,0-4.4-1.5-5.3c-1.5-0.9-3.5-0.6-5.3,0.4c-1.8,1-3.5,2.7-4.6,5.2
							c-1.1,2.5-2,4.3-3,6c-1,1.7-2.2,3.4-4.4,5.4c2.8-1.1,4.7-1.7,6.8-2.4C454,130.8,456,130.1,458.8,128.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M475.9,123.1c2.3-1.3,3.6-3.6,3.8-5.6c0.2-2.1-0.6-3.8-2.1-4.7c-1.6-0.8-3.4-0.6-5,0.2c-1.6,0.8-2.9,2.3-3.5,4.4
							c-0.7,2.2-1.1,3.8-1.7,5.4c-0.6,1.6-1.4,3.1-3,5c2.3-1,3.9-1.6,5.6-2.2C471.8,125,473.6,124.4,475.9,123.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M493,119.2c2.2-1.6,3.5-4,3.7-6.1c0.2-2.1-0.5-4-2.1-4.8c-1.5-0.8-3.5-0.5-5.1,0.5c-1.7,1.1-3.1,2.9-3.7,5.3
							c-0.6,2.5-1,4.3-1.5,6.1c-0.5,1.8-1.2,3.6-2.5,5.8c2.2-1.6,3.8-2.5,5.5-3.5C488.9,121.7,490.7,120.8,493,119.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M512.9,117.7c2.4-2.2,3.5-5.3,3.4-7.8c-0.1-2.5-1.3-4.3-3-4.6c-1.7-0.4-3.5,0.6-4.9,2c-1.4,1.4-2.5,3.4-2.9,5.9
							c-0.5,2.6-1,4.5-1.6,6.3c-0.7,1.8-1.6,3.7-3.7,5.8c2.7-1.3,4.5-2.3,6.5-3.3C508.4,121,510.4,119.9,512.9,117.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M529.5,112.5c2.1-2.5,3.1-5.6,2.9-8.1c-0.1-2.5-1.2-4.4-2.9-4.8c-1.7-0.5-3.6,0.5-5,2.2c-1.5,1.7-2.6,4.1-3,7
							c-0.4,3-0.6,5.1-1,7.2c-0.4,2.1-1,4.3-2.4,7.1c2.3-2.2,4-3.7,5.7-5.2C525.6,116.6,527.3,115.1,529.5,112.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M541.5,108.7c2-2,2.9-4.7,2.9-7c-0.1-2.2-1.2-4-2.8-4.5c-1.7-0.5-3.5,0.2-5,1.5c-1.5,1.4-2.6,3.4-2.9,6
							c-0.3,2.6-0.5,4.5-0.8,6.4c-0.3,1.9-0.8,3.8-2,6.3c2.1-1.9,3.6-3.1,5.2-4.3C537.8,112,539.4,110.8,541.5,108.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M558.6,100c2.1-2.3,3.1-5.3,3-7.7c0-2.4-1.1-4.3-2.7-4.9c-1.7-0.5-3.6,0.3-5.1,1.9c-1.5,1.6-2.7,4-3.1,6.8
							c-0.4,2.9-0.6,5-0.9,7.1c-0.4,2.1-0.9,4.2-2.2,7c2.2-2.2,3.8-3.7,5.5-5.1C554.7,103.8,556.5,102.4,558.6,100z"
                      />
                    </g>
                    <g>
                      <path
                        d="M573,93.6c2.2-1.8,3.6-4.1,4.1-6.2c0.5-2.2,0.2-4.2-1.2-5.3c-1.4-1.1-3.5-0.9-5.5,0.3c-2,1.2-3.9,3.5-4.7,6.5
							c-0.8,3-1.2,5.2-1.5,7.3c-0.4,2.1-0.8,4.2-1.6,7.1c1.8-2.4,3.3-3.9,4.9-5.2C569,96.6,570.7,95.4,573,93.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M587.3,86.9c2.3-1.1,3.9-2.9,4.8-4.7c0.8-1.8,0.9-3.8-0.1-5.2c-1-1.4-3.1-1.9-5.2-1.2c-2.2,0.6-4.5,2.4-5.7,5.1
							c-1.3,2.7-1.9,4.7-2.5,6.7c-0.6,2-1.2,3.9-2.3,6.5c1.9-2,3.6-3.2,5.3-4.1C583.1,88.9,584.9,88.1,587.3,86.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M602.4,93.3c2.3,0.6,4.4,0.3,6.1-0.6c1.7-0.9,3-2.3,3.1-4.1c0.1-1.7-1-3.4-3.1-4.3c-2-0.9-4.8-1-7.4,0.3
							c-2.6,1.3-4.2,2.6-5.7,3.7c-1.5,1.2-3.1,2.4-5.3,3.9c2.6-0.5,4.5-0.5,6.3-0.2C598.3,92.3,600,92.7,602.4,93.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M602.3,107.3c2.1,1.2,4.2,1.5,6.1,1.3c1.9-0.3,3.6-1.1,4.4-2.7c0.8-1.6,0.2-3.6-1.6-5.2c-1.8-1.6-4.7-2.6-7.7-2.1
							c-3.1,0.5-5.1,1.4-7,2.2c-2,0.9-3.8,1.7-6.3,2.8c2.8-0.1,4.7,0.4,6.5,1C598.4,105.2,600.1,106.1,602.3,107.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M598.1,120.2c2.4,1.8,5,2.6,7.4,2.6c2.3,0,4.2-0.9,4.9-2.5c0.7-1.6-0.1-3.6-1.8-5.3c-1.7-1.6-4.4-2.9-7.5-3.1
							c-6.3-0.3-8.9,0.5-15.1-0.2C591.6,114.5,593.3,116.5,598.1,120.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M455.6,157.1c1.3,2.2,2.9,3.7,4.7,4.7c1.8,0.9,3.8,1.2,5.3,0.3c1.5-0.9,2.1-3,1.3-5.4c-0.7-2.4-2.8-5-5.7-6.3
							c-3-1.3-5.3-1.7-7.4-2.1c-2.2-0.4-4.3-0.7-7.2-1.1c2.6,1.4,4.1,2.9,5.4,4.4C453.2,153.1,454.3,154.8,455.6,157.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M472.5,157.1c1.1,2.4,2.4,4.3,3.9,5.8c1.5,1.5,3.1,2.7,4.9,2.5c0.9-0.1,1.6-0.6,2.2-1.6c0.6-0.9,0.9-2.2,0.8-3.8
							c-0.1-1.5-0.5-3.3-1.5-4.9c-0.9-1.6-2.3-3.2-4-4.3c-1.8-1.1-3.4-1.8-4.8-2.2c-1.5-0.4-2.8-0.7-4.1-0.8
							c-2.6-0.3-5.1-0.3-8.6,0.2c3.4,0.6,5.5,1.9,7.1,3.3C470,152.9,471.3,154.7,472.5,157.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M487.8,156.6c1.5,2.4,3.4,4.2,5.4,5.3c1.9,1.1,4,1.5,5.5,0.6c1.5-0.9,2-3.1,1.1-5.7c-0.9-2.6-3.1-5.4-6.4-6.9
							c-3.3-1.5-5.7-2.1-8.1-2.5c-2.4-0.5-4.7-0.9-8-1.4c3,1.5,4.7,3.1,6.1,4.7C484.9,152.4,486.2,154.1,487.8,156.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M506.5,159.3c2.5,2.3,5.7,3.4,8.3,3.4c2.6,0,4.6-1.1,5.1-2.7c0.5-1.7-0.5-3.6-2.2-5.1c-1.7-1.6-4.2-2.8-7.2-3.3
							c-3-0.5-5.2-0.9-7.4-1.4c-2.2-0.5-4.3-1.3-7.2-2.9c2.2,2.5,3.6,4.3,5.1,6.1C502.5,155.1,504,157,506.5,159.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M525.3,155.5c3.6,1.7,7.5,2.2,10.5,1.7c3-0.5,5.1-1.9,5.2-3.6c0.2-1.8-1.5-3.5-4.1-4.7c-2.6-1.2-6-2-9.7-2
							c-3.8,0.1-6.5,0.2-9.2,0.1c-2.7-0.1-5.5-0.3-9.4-1.3c3.5,1.9,5.9,3.4,8.3,4.9C519.2,152.2,521.6,153.7,525.3,155.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M549,150.1c6.4,2.5,13.2,0.2,13.4-3.3c0.2-3.5-6-6.6-12.5-5.4c-6.7,1.1-9.4,2.3-16.4,2.3
							C540.3,145.7,542.5,147.5,549,150.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M568.8,144.1c3.4,1.2,7.1,1.2,9.7,0.4c2.7-0.8,4.5-2.3,4.5-4.1c0-1.8-1.7-3.3-4.2-4.3c-2.5-1-5.8-1.4-9.2-0.8
							c-3.5,0.6-6,1.1-8.5,1.5c-2.5,0.4-5.1,0.6-8.8,0.4c3.5,1.2,5.9,2.3,8.2,3.4C562.9,141.7,565.2,142.8,568.8,144.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M580.9,135.9c1.6,1.6,3.5,2.2,5.3,2.2c1.8,0,3.5-0.8,4.4-2.3c0.9-1.5,0.7-3.5-0.5-5.1c-1.2-1.6-3.4-2.9-6.1-3
							c-2.7,0-4.6,0.3-6.4,0.5c-1.8,0.3-3.6,0.5-6,0.6c2.3,0.9,3.7,1.9,5,3C578.1,133,579.3,134.2,580.9,135.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M591.4,129.9c3.6,4.4,9.6,5,11.5,2c1.9-3-1.3-8.1-6.8-9.4c-5.7-1.4-8.2-1.2-13.6-3.3
							C586.8,123.1,587.7,125.4,591.4,129.9z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M191.4,346.1c2,10.5,5.5,20.9,10.2,29.9c4.7,9.1,10.8,16.7,16.8,22.3c6,5.6,11.9,9.2,16.3,11.1
							c4.4,1.9,7.2,2.3,7.5,1.5c0.9-1.7-8.1-7.1-17.8-18.4c-4.8-5.7-9.8-12.8-14-21.1c-4.2-8.3-7.8-17.6-10.5-27.4
							c-2.8-10-4.2-18.8-5.5-26.9c-1.3-8.1-2.4-15.4-3.8-22.7c-1.4-7.3-3.2-14.7-6-22.6c-1.4-4-3.2-8.1-5.4-12.3
							c-2.2-4.2-5.1-8.5-8.7-12.7c6.8,8.8,10.2,17.6,12.4,25.5c2.2,8,3.2,15.3,4,22.6c0.7,7.3,1,14.6,1.5,22.8
							C188.8,326.1,189.3,335.3,191.4,346.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M197,289.9c1.7,2.6,4.4,4.1,6.8,4.5c2.4,0.4,4.4-0.3,5.2-1.9c0.8-1.6,0.3-3.5-0.8-5.3c-1.2-1.7-3-3.3-5.5-4.1
							c-2.6-0.9-4.5-1.6-6.3-2.5c-1.8-0.9-3.6-1.9-5.7-3.9c1.3,2.7,2.1,4.6,3,6.6C194.4,285.3,195.3,287.3,197,289.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M204,306.5c1.5,2.2,3.9,3.3,5.9,3.4c2.1,0.1,3.8-0.9,4.5-2.5c0.7-1.6,0.3-3.5-0.6-4.9c-1-1.5-2.5-2.6-4.7-3.1
							c-2.2-0.5-3.9-0.8-5.6-1.3c-1.6-0.5-3.2-1.2-5.2-2.6c1.2,2.2,1.9,3.8,2.7,5.4C201.7,302.5,202.5,304.3,204,306.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M209.3,323.1c1.8,2.1,4.2,3.1,6.4,3.2c2.1,0.1,3.9-0.8,4.6-2.5c0.7-1.6,0.2-3.5-1-5.1c-1.2-1.6-3.1-2.8-5.6-3.2
							c-2.5-0.4-4.4-0.6-6.2-1c-1.8-0.4-3.6-0.9-6-2c1.7,2,2.8,3.6,3.9,5.2C206.4,319.3,207.5,321,209.3,323.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M212.5,342.8c2.4,2.2,5.6,3,8,2.7c2.5-0.3,4.1-1.7,4.3-3.4c0.2-1.8-0.9-3.4-2.4-4.7c-1.5-1.3-3.6-2.2-6.1-2.4
							c-2.6-0.3-4.5-0.6-6.4-1.1c-1.9-0.5-3.8-1.3-6.1-3.2c1.5,2.5,2.6,4.3,3.8,6.2C208.8,338.7,210.1,340.6,212.5,342.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M219.1,359c2.6,1.9,5.8,2.6,8.3,2.2c2.5-0.3,4.3-1.6,4.6-3.3c0.3-1.7-0.8-3.5-2.7-4.8c-1.8-1.3-4.4-2.3-7.3-2.4
							c-3-0.1-5.1-0.1-7.3-0.4c-2.2-0.2-4.3-0.6-7.3-1.8c2.4,2.1,4,3.6,5.6,5.2C214.7,355.4,216.3,357,219.1,359z"
                      />
                    </g>
                    <g>
                      <path
                        d="M223.9,370.7c2.2,1.9,5,2.5,7.2,2.2c2.2-0.3,3.9-1.5,4.3-3.2c0.4-1.7-0.5-3.5-2-4.8c-1.5-1.4-3.7-2.3-6.2-2.3
							c-2.6-0.1-4.6-0.1-6.5-0.2c-1.9-0.2-3.8-0.5-6.4-1.5c2,1.9,3.4,3.4,4.7,4.9C220.3,367.2,221.6,368.7,223.9,370.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M234,386.9c2.5,1.9,5.5,2.6,7.9,2.4c2.4-0.2,4.2-1.4,4.6-3.1c0.4-1.7-0.6-3.5-2.4-4.9c-1.8-1.4-4.2-2.4-7.1-2.5
							c-3-0.1-5.1-0.2-7.2-0.3c-2.1-0.2-4.2-0.5-7.2-1.6c2.4,2,4,3.5,5.6,5C229.9,383.4,231.4,385,234,386.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M241.6,400.7c2,2.1,4.3,3.2,6.5,3.5c2.2,0.3,4.2-0.2,5.2-1.7c1-1.5,0.6-3.6-0.8-5.5c-1.4-1.9-3.8-3.6-6.8-4.2
							c-3.1-0.6-5.3-0.7-7.4-0.9c-2.1-0.2-4.3-0.4-7.2-1c2.6,1.6,4.2,3,5.6,4.5C238.2,397,239.6,398.6,241.6,400.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M249.5,414.4c1.3,2.2,3.2,3.7,5.1,4.3c1.9,0.7,3.9,0.6,5.2-0.6c1.3-1.2,1.6-3.2,0.7-5.3c-0.8-2.1-2.8-4.2-5.5-5.3
							c-2.8-1.1-4.9-1.5-6.9-1.9c-2-0.4-3.9-0.9-6.6-1.7c2.2,1.8,3.5,3.3,4.6,4.9C247.1,410.4,248.1,412.1,249.5,414.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M244.4,430c-0.4,2.4,0.1,4.4,1.1,6c1,1.6,2.6,2.7,4.3,2.7c1.8,0,3.3-1.3,4-3.4c0.7-2.1,0.6-4.9-0.9-7.3
							c-1.5-2.5-2.9-4-4.2-5.4c-1.3-1.4-2.6-2.9-4.3-4.9c0.7,2.6,0.8,4.4,0.7,6.2C245.1,425.8,244.8,427.6,244.4,430z"
                      />
                    </g>
                    <g>
                      <path
                        d="M230.5,431.1c-1,2.2-1.2,4.3-0.7,6.2c0.4,1.9,1.4,3.5,3.1,4.2c1.6,0.6,3.6-0.1,5-2.1c1.4-1.9,2.2-4.9,1.4-7.9
							c-0.8-3-1.8-4.9-2.8-6.8c-1-1.9-2-3.7-3.3-6.1c0.3,2.8,0,4.7-0.4,6.6C232.2,427,231.5,428.8,230.5,431.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M217.3,428c-1.6,2.5-2.2,5.3-1.9,7.5c0.2,2.3,1.2,4.1,2.9,4.7c1.7,0.5,3.6-0.4,5.1-2.2c1.5-1.9,2.5-4.7,2.4-7.7
							c-0.3-6.3-1.2-8.8-1.1-15C222.4,421,220.5,422.8,217.3,428z"
                      />
                    </g>
                    <g>
                      <path
                        d="M168.4,289.1c-2.1,1.5-3.5,3.2-4.2,5.1c-0.7,1.9-0.9,3.9,0.2,5.3c1,1.4,3.2,1.8,5.5,0.9c2.3-0.9,4.7-3.2,5.8-6.3
							c1.1-3.2,1.3-5.4,1.5-7.6c0.2-2.2,0.3-4.3,0.4-7.3c-1.2,2.7-2.5,4.3-3.9,5.7C172.1,286.4,170.5,287.6,168.4,289.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M169.7,305.9c-2.3,1.3-4,2.7-5.5,4.3c-1.4,1.6-2.5,3.4-2.1,5.1c0.2,0.9,0.8,1.6,1.7,2c1,0.5,2.3,0.7,3.8,0.5
							c1.5-0.2,3.2-0.8,4.8-1.9c1.5-1.1,3-2.6,3.9-4.4c1-1.9,1.5-3.5,1.8-5c0.3-1.5,0.4-2.8,0.5-4.1c0-2.6-0.1-5.2-0.9-8.6
							c-0.3,3.5-1.4,5.6-2.7,7.4C173.7,303.1,172.1,304.6,169.7,305.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M171.5,321.1c-2.3,1.7-3.9,3.7-4.8,5.8c-0.9,2-1.2,4.1-0.1,5.5c1,1.4,3.3,1.7,5.8,0.6c2.5-1.1,5.1-3.6,6.3-6.9
							c1.2-3.4,1.6-5.9,1.8-8.3c0.3-2.4,0.5-4.7,0.7-8.1c-1.3,3.1-2.6,4.9-4.1,6.5C175.6,317.9,173.9,319.3,171.5,321.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M170.5,340.1c-2.1,2.7-2.9,6-2.6,8.5c0.2,2.6,1.4,4.5,3.2,4.8c1.7,0.3,3.5-0.8,4.9-2.6c1.4-1.9,2.4-4.4,2.7-7.4
							c0.3-3.1,0.4-5.3,0.8-7.5c0.4-2.2,0.9-4.4,2.3-7.4c-2.3,2.4-3.9,4-5.6,5.6C174.4,335.7,172.6,337.3,170.5,340.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M175.9,358.4c-1.4,3.7-1.5,7.7-0.8,10.6c0.7,3,2.3,4.9,4,4.9c1.8,0,3.3-1.8,4.3-4.5c1-2.7,1.5-6.2,1.1-9.9
							c-0.4-3.8-0.7-6.5-0.9-9.2c-0.2-2.7-0.2-5.5,0.4-9.4c-1.6,3.7-2.9,6.2-4.2,8.7C178.7,352.1,177.4,354.6,175.9,358.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M183.3,381.6c-1.9,6.6,0.9,13.2,4.4,13.1c3.5-0.1,6-6.5,4.4-12.9c-1.7-6.6-3.1-9.1-3.7-16.1
							C186.9,372.5,185.3,374.9,183.3,381.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M190.9,400.8c-0.9,3.5-0.6,7.1,0.4,9.7c1,2.6,2.7,4.3,4.5,4.1c1.8-0.1,3.2-2,3.9-4.5c0.8-2.6,0.9-5.9,0-9.3
							c-0.9-3.5-1.6-5.9-2.2-8.4c-0.6-2.5-1-5-1.2-8.7c-0.9,3.6-1.8,6-2.6,8.5C192.8,394.7,191.9,397.2,190.9,400.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M200.2,412.2c-1.4,1.7-1.9,3.7-1.8,5.5c0.2,1.8,1.1,3.4,2.7,4.2c1.6,0.8,3.5,0.4,5-1c1.5-1.3,2.6-3.7,2.5-6.3
							c-0.2-2.7-0.6-4.5-1.1-6.3c-0.4-1.8-0.8-3.5-1.1-6c-0.7,2.4-1.6,3.9-2.6,5.3C202.8,409.1,201.7,410.4,200.2,412.2z"
                      />
                    </g>
                    <g>
                      <path d="M207,422.2c-4,4-4.1,10-1,11.6c3.1,1.6,8-2,8.8-7.6c0.9-5.8,0.5-8.2,2.1-13.8C213.4,417,211.1,418.1,207,422.2z" />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M378.5,8.1c9.5-5,18.3-11.4,25.5-18.6c7.3-7.2,12.8-15.2,16.3-22.6c3.6-7.4,5.3-14.1,5.8-18.9c0.5-4.8,0-7.5-0.8-7.6
							c-1.9-0.3-4.3,9.8-12.3,22.5c-4,6.3-9.3,13.2-16,19.6c-6.6,6.5-14.6,12.7-23,18.1c-8.7,5.6-16.7,9.6-24,13.2
							c-7.3,3.6-14,6.8-20.6,10.4c-6.6,3.6-13.1,7.4-19.8,12.5c-3.4,2.5-6.8,5.4-10.1,8.8c-3.4,3.4-6.6,7.4-9.6,12
							c6.4-9.1,13.8-14.9,20.7-19.4c7-4.5,13.7-7.6,20.5-10.5c6.8-2.8,13.7-5.3,21.4-8.2C360.1,16.5,368.7,13.3,378.5,8.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M323.1,19.3c2-2.4,2.7-5.4,2.3-7.8c-0.3-2.4-1.6-4.1-3.3-4.4c-1.7-0.3-3.5,0.7-4.8,2.3c-1.3,1.6-2.2,3.9-2.3,6.5
							c-0.1,2.7-0.2,4.7-0.5,6.7c-0.3,2-0.8,4-2.1,6.6c2.2-2,3.7-3.4,5.4-4.8C319.4,23.2,321.1,21.8,323.1,19.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M336.9,7.8c1.6-2.1,2-4.7,1.5-6.6c-0.5-2-2-3.4-3.7-3.5c-1.8-0.2-3.4,0.7-4.5,2.1c-1.1,1.4-1.8,3.2-1.6,5.4
							c0.2,2.3,0.4,4,0.4,5.7c0,1.7-0.1,3.4-1,5.8c1.7-1.8,3-3,4.4-4.2C333.8,11.1,335.2,9.9,336.9,7.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M351.2-2.2c1.5-2.3,1.7-5,1.1-7c-0.6-2.1-2-3.5-3.7-3.7c-1.8-0.2-3.4,0.8-4.6,2.4c-1.1,1.6-1.8,3.8-1.4,6.3
							c0.4,2.6,0.7,4.4,0.9,6.3c0.2,1.9,0.3,3.7-0.1,6.4c1.4-2.3,2.6-3.8,3.8-5.3C348.4,1.6,349.7,0.1,351.2-2.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M369.1-11.1c1.4-2.9,1.2-6.2,0.2-8.4c-1-2.3-2.9-3.4-4.6-3.1c-1.7,0.3-3,1.8-3.8,3.7c-0.8,1.9-1,4.1-0.5,6.5
							c0.5,2.6,0.8,4.5,0.9,6.5c0,2-0.1,4-1.2,6.8c2-2.2,3.4-3.8,4.8-5.5C366.2-6.4,367.6-8.1,369.1-11.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M382.6-22.2c1-3.1,0.7-6.3-0.3-8.6c-1-2.3-2.8-3.6-4.5-3.4c-1.8,0.2-3.1,1.8-3.8,4c-0.7,2.1-0.9,4.8-0.1,7.6
							c0.8,2.9,1.4,5,1.8,7.1c0.4,2.1,0.7,4.3,0.4,7.5c1.3-2.9,2.3-4.9,3.3-6.9C380.4-16.9,381.5-19,382.6-22.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M392.3-30.2c1.1-2.7,1-5.5,0-7.5c-0.9-2-2.6-3.3-4.3-3.1c-1.8,0.1-3.2,1.5-4,3.3c-0.8,1.8-1.1,4.2-0.4,6.6
							c0.7,2.5,1.3,4.4,1.7,6.3c0.4,1.9,0.7,3.8,0.5,6.6c1.2-2.5,2.2-4.2,3.2-5.9C390-25.7,391.1-27.5,392.3-30.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M404.8-44.7c1.1-3,0.9-6-0.1-8.3c-1-2.2-2.6-3.6-4.4-3.5c-1.8,0.1-3.2,1.6-4,3.7c-0.8,2.1-1,4.7-0.3,7.5
							c0.8,2.9,1.4,4.9,1.8,7c0.4,2.1,0.8,4.2,0.6,7.3c1.2-2.9,2.1-4.8,3.1-6.8C402.6-39.7,403.7-41.7,404.8-44.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M415.7-56.1c1.4-2.5,1.8-5.1,1.4-7.3c-0.3-2.2-1.4-3.9-3.1-4.4c-1.7-0.5-3.6,0.4-5,2.3c-1.4,1.9-2.3,4.7-1.9,7.8
							c0.4,3.1,0.9,5.2,1.3,7.3c0.5,2.1,0.9,4.2,1.2,7.2c0.8-2.9,1.6-4.9,2.6-6.7C413.2-51.8,414.3-53.5,415.7-56.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M426.4-67.6c1.7-1.9,2.5-4.1,2.6-6.1c0.1-2-0.6-3.9-2.1-4.8c-1.5-0.9-3.5-0.6-5.3,0.9c-1.8,1.4-3.2,3.9-3.4,6.8
							c-0.2,3,0,5.1,0.2,7.1c0.2,2,0.3,4,0.3,6.9c1-2.6,2.1-4.3,3.3-5.8C423.3-64.2,424.7-65.6,426.4-67.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M442.9-67.4c2.4-0.3,4.2-1.4,5.4-2.9c1.2-1.4,1.9-3.2,1.3-4.9c-0.5-1.7-2.2-2.8-4.4-2.8c-2.2-0.1-4.8,0.9-6.7,3.1
							c-1.9,2.2-2.9,3.9-3.9,5.6c-1,1.7-2,3.3-3.4,5.6c2.2-1.5,4-2.1,5.7-2.6C438.6-66.8,440.4-67.1,442.9-67.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M448-54.4c2.4,0.3,4.5-0.2,6.2-1.1c1.7-1,3-2.4,3.1-4.2c0.1-1.7-1.2-3.4-3.4-4.2c-2.2-0.8-5.3-0.6-7.9,1
							c-2.7,1.7-4.2,3.2-5.7,4.6c-1.5,1.6-2.9,3-4.8,5c2.6-1.1,4.5-1.4,6.4-1.5C443.6-54.9,445.5-54.7,448-54.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M449-40.9c2.9,0.8,5.7,0.5,7.8-0.4c2.1-0.9,3.6-2.4,3.6-4.2c0-1.8-1.4-3.3-3.6-4.2c-2.2-0.9-5.2-1-8.1,0
							c-6,2.1-8,3.8-14,5.5C440.8-43.7,443.1-42.4,449-40.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M330.8,46.9c2,1.6,4.1,2.4,6.1,2.5c2,0.2,3.9-0.3,5-1.7c1-1.4,0.8-3.5-0.8-5.5c-1.5-1.9-4.4-3.6-7.7-3.6
							c-3.3-0.1-5.5,0.4-7.7,0.8c-2.1,0.5-4.2,1-7.1,1.7c3,0.3,4.9,1.1,6.6,2C327.1,44.2,328.7,45.3,330.8,46.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M346.5,40.6c1.9,1.8,3.8,3.1,5.8,3.9c1.9,0.8,3.9,1.4,5.5,0.5c0.8-0.4,1.3-1.2,1.4-2.3c0.2-1.1,0-2.4-0.7-3.8
							c-0.6-1.4-1.7-2.8-3.2-4c-1.5-1.2-3.3-2.1-5.3-2.4c-2.1-0.4-3.8-0.4-5.3-0.3c-1.5,0.1-2.8,0.4-4.1,0.8
							c-2.5,0.7-4.9,1.6-7.9,3.4c3.4-0.8,5.8-0.3,7.9,0.4C342.6,37.7,344.5,38.8,346.5,40.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M360.5,34.4c2.3,1.7,4.7,2.6,6.9,2.9c2.2,0.3,4.3-0.1,5.3-1.5c1-1.4,0.7-3.6-1.1-5.7c-1.8-2.1-4.9-3.8-8.5-4
							c-3.6-0.2-6.1,0.3-8.5,0.7c-2.4,0.5-4.7,1-7.9,1.7c3.3,0.3,5.5,1.1,7.4,2C356.2,31.5,358.1,32.7,360.5,34.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M378.8,29.8c3.2,1.2,6.5,1,8.9,0c2.4-1,3.8-2.7,3.7-4.4c-0.2-1.8-1.8-3.1-4-3.9c-2.2-0.8-5-1-7.9-0.4
							c-3,0.7-5.2,1.2-7.4,1.5c-2.2,0.3-4.5,0.4-7.8,0c3,1.5,5,2.6,7,3.7C373.5,27.4,375.6,28.6,378.8,29.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M394.8,19.2c4,0.3,7.8-0.8,10.4-2.4c2.6-1.6,4-3.6,3.5-5.3c-0.5-1.7-2.7-2.6-5.6-2.8c-2.9-0.2-6.4,0.4-9.8,1.9
							c-3.5,1.5-6,2.6-8.5,3.6c-2.5,1-5.2,1.8-9.2,2.4c4,0.5,6.7,0.9,9.5,1.4C387.9,18.4,390.7,19,394.8,19.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M414.8,5.3c6.9-0.1,12.4-4.8,11.2-8.1c-1.2-3.3-8-3.8-13.6-0.3c-5.8,3.6-7.8,5.6-14.3,8.3
							C405,4.5,407.7,5.3,414.8,5.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M430.8-7.7c3.7-0.2,7-1.5,9.2-3.3c2.2-1.7,3.3-3.8,2.6-5.5c-0.7-1.6-2.8-2.4-5.5-2.4c-2.7,0-5.9,0.9-8.8,2.7
							c-3,1.9-5.1,3.3-7.3,4.6c-2.2,1.3-4.5,2.5-8,3.7c3.7-0.2,6.3-0.1,8.9,0C424.5-7.7,427.1-7.5,430.8-7.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M439-19.9c2.1,0.9,4.1,0.8,5.8,0c1.6-0.7,3-2.1,3.3-3.8c0.3-1.7-0.7-3.5-2.4-4.5c-1.7-1.1-4.3-1.4-6.7-0.5
							c-2.5,1-4.1,2-5.7,2.9c-1.6,0.9-3.1,1.8-5.4,2.9c2.5,0,4.2,0.4,5.8,0.9C435.2-21.5,436.8-20.8,439-19.9z"
                      />
                    </g>
                    <g>
                      <path d="M446.5-29.3c5,2.7,10.8,1,11.4-2.5c0.6-3.5-4.3-7-9.9-6.2c-5.8,0.9-8,2-13.8,2C439.6-33.9,441.3-32.1,446.5-29.3z" />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M533.7,299.2c0.6-0.6,1.1-1.2,1.6-1.8c0.5-0.7,1-1.3,1.5-2c0.9-1.4,1.8-2.8,2.4-4.3c1.4-3,2.2-6.2,2.6-9.4
						c0.8-6.4-0.3-12.8-2.8-18.6c-1.2-2.9-3-5.7-5-8.1c-2-2.4-4.2-4.5-6.6-6.4c-4.8-3.6-10.2-6.1-15.6-7.6c-2.7-0.7-5.5-1.3-8.2-1.5
						c-2.7-0.3-5.5-0.3-8.1-0.2c-5.4,0.3-10.8,1.5-15.7,3.8c-5,2.3-9.4,5.8-12.6,10c-1.6,2.1-2.9,4.5-3.9,6.9
						c-0.9,2.4-1.5,5.1-1.6,7.7c-0.3,5.1,1,10,3.5,14.1c1.3,2,2.9,3.8,4.7,5.2c1.8,1.4,3.8,2.4,5.8,3.1c4,1.3,8,1.3,11.5,0.3
						c3.5-1,6.4-3.3,8-5.7c1.7-2.4,2.4-4.9,2.6-7c0.2-2.1-0.1-4-0.6-5.3c-0.5-1.4-1.2-2.3-1.7-2.8c-0.6-0.5-1-0.6-1.3-0.5
						c-0.6,0.3-0.4,1.7-0.7,3.8c-0.1,1-0.4,2.2-1.1,3.5c-0.6,1.2-1.5,2.6-2.7,3.6c-0.6,0.5-1.3,0.9-2,1.3c-0.7,0.3-1.6,0.5-2.5,0.6
						c-1.8,0.2-3.8-0.2-5.7-1.2c-0.9-0.5-1.8-1.1-2.5-1.9c-0.7-0.7-1.3-1.6-1.8-2.7c-1-2.1-1.4-4.7-1-7.3c0.2-1.3,0.6-2.5,1.2-3.8
						c0.6-1.2,1.4-2.4,2.4-3.6c1-1.1,2.1-2.2,3.4-3.1c1.3-0.9,2.7-1.7,4.3-2.3c6.2-2.5,14.7-2.8,22.4-0.6c3.8,1.1,7.5,2.8,10.6,5.1
						c1.5,1.2,3,2.5,4.2,3.9c0.6,0.7,1.1,1.5,1.7,2.2l0.7,1.2l0.6,1.3c1.6,3.4,2.4,7.4,2.2,11.1c-0.1,1.9-0.5,3.7-1.2,5.4
						c-0.3,0.9-0.7,1.6-1.2,2.4l-0.7,1.1c-0.3,0.4-0.5,0.7-0.9,1.1c-2.5,3-6.1,5.2-10.1,6.9c-2,0.9-4.1,1.6-6.1,2.2
						c-1.1,0.3-2.1,0.6-3.2,0.9c-1.1,0.3-2.1,0.6-3.1,0.8c-8.4,1.9-16.5,2.6-24.2,2.5c-7.7-0.1-15.1-1.1-22.2-2.7
						c-7.1-1.6-13.8-3.8-20.3-6.6c-13-5.7-25.3-13.9-36.8-25.5c-5.7-5.8-11.3-12.5-16.4-20.3c-5.2-7.7-9.9-16.7-13.9-26.8
						c3.7,10.3,8,19.4,12.8,27.5c4.8,8.1,10,15.2,15.6,21.4c11.1,12.5,23,21.8,36.3,28.8c6.6,3.5,13.5,6.4,20.9,8.7
						c7.4,2.3,15.2,3.9,23.5,4.7c8.4,0.8,17.3,0.7,26.8-0.7c1.2-0.1,2.4-0.4,3.6-0.6c1.2-0.2,2.4-0.5,3.6-0.7c2.5-0.6,5-1.2,7.6-2.1
						c2.6-0.9,5.2-2,7.8-3.5C528.7,303.5,531.3,301.6,533.7,299.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M454.1,257.7c3.8-5.9,5.1-13.2,3.9-20.1c-0.6-3.4-1.9-6.8-3.7-9.7c-1.8-2.9-4.1-5.5-6.7-7.6c-2.6-2.1-5.5-3.7-8.6-4.7
						c-3.1-1-6.3-1.5-9.5-1.3c-3.2,0.2-6.3,0.9-9.1,2.4c-0.7,0.3-1.4,0.8-2,1.2c-0.7,0.5-1.3,0.9-1.9,1.4c-1.2,1-2.2,2.1-3.1,3.3
						c-1.7,2.4-2.8,5-3.3,7.6c-0.5,2.6-0.3,5.3,0.4,7.7c0.8,2.5,2.1,4.4,3.6,5.7c1.5,1.4,3,2.3,4.5,2.9c1.5,0.6,2.9,0.8,4.1,0.8
						c0.6,0,1.2-0.1,1.7-0.2c0.5-0.1,0.9-0.3,1.3-0.4c0.8-0.4,1.3-0.8,1.5-1.2c0.3-0.4,0.4-0.7,0.3-1.1c-0.2-0.7-1-1-2-1.6
						c-1-0.6-2.2-1.3-3.3-2.6c-1.1-1.2-1.9-3.1-1.8-4.5c0.1-0.9,0.2-1.7,0.7-2.6c0.4-0.9,1.1-1.8,1.9-2.6c0.4-0.4,0.9-0.7,1.3-1
						c0.2-0.1,0.5-0.2,0.7-0.4c0.3-0.1,0.5-0.2,0.8-0.3c1.1-0.4,2.4-0.5,3.9-0.5c2.9,0.1,5.9,1.3,8.6,3.4c1.3,1.1,2.5,2.4,3.4,3.8
						c0.9,1.5,1.6,3,2,4.7c0.8,3.4,0.5,7.4-1.1,10.7c-1.6,3.5-4.6,6.3-7.9,8.3c-3.4,1.9-7,3-10.6,3.3c-3.7,0.4-7.3,0.1-10.9-0.6
						c-3.5-0.7-7-1.8-10.4-3.2c-3.4-1.4-6.6-3.1-9.9-5.2c-3.2-2.1-6.3-4.6-9.4-7.5c-3-2.9-6-6.3-8.7-10.3c-2.7-4-5.2-8.6-7.1-14
						c3.2,11,8.3,19.4,13.9,26.1c2.8,3.4,5.7,6.3,8.8,9c3.1,2.6,6.3,4.9,9.7,7c3.4,2.1,7,3.8,11,5.2c3.9,1.4,8.2,2.4,13,2.7
						c4.7,0.3,10-0.4,15.2-2.5c2.6-1,5.2-2.5,7.8-4.4C449.8,263.2,452.2,260.8,454.1,257.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M336.8,288.9c-1.6,6.2-2,12.8-1.5,18.8c0.5,6,2,11.6,3.9,16c1.9,4.5,4.2,7.8,6.2,9.9c2.1,2.1,3.9,2.9,5,2.3
						c1.2-0.6,1.7-2.5,1.6-5.1c0.1-2.7-0.4-6.2-0.8-10.4c-0.4-4.1-0.9-8.8-1.2-13.8c-0.3-5-0.4-10.4-0.3-15.8
						c0.1-5.6,0.8-10.4,1.7-14.7c0.9-4.3,2.2-8.3,3.4-12.5c1.2-4.1,2.4-8.3,3.2-13.2c0.4-2.4,0.6-5,0.6-7.8c0-2.8-0.4-5.7-1.3-8.7
						c0.5,3.1,0.5,6,0.1,8.6c-0.4,2.6-1.1,5-1.9,7.2c-1.6,4.4-3.7,8-5.9,11.6c-2.2,3.6-4.5,7.2-6.8,11.5
						C340.7,277.4,338.5,282.5,336.8,288.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M350.5,287.2c-2,4.9-4.1,10-5.6,15.3c-1.5,5.3-2.2,10.7-1.9,15.5c0.3,4.8,1.5,8.7,3,11.2c1.5,2.5,3.2,3.5,4.5,3.1
						c1.3-0.4,2-2,2.7-4.3c0.7-2.3,1.4-5.3,2.3-8.9c0.9-3.6,2.2-7.7,3.7-12.4c1.4-4.7,3-10.1,4-15.9c0.9-6,1.2-11.1,1.2-15.7
						c0-4.6-0.4-8.7-0.9-12.8c-0.5-4.1-1.2-8.1-2.1-12.5c-1-4.4-2.2-9.3-4.1-14.9c1.1,5.8,1.4,10.8,1.4,15.2c0,4.4-0.4,8.4-0.9,12.2
						c-0.6,3.8-1.4,7.6-2.5,11.6C354.1,277.9,352.6,282.2,350.5,287.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M393.3,330.8c-0.5,0.7-1,1.3-1.5,1.9c-0.5,0.6-1.1,1.1-1.6,1.6c-1.2,0.9-2.4,1.7-3.7,2.3c-1.3,0.6-2.5,0.8-3.7,0.9
						c-1.2,0.1-2.1,0-3.2-0.4c-2.1-0.6-3.9-2.2-4.8-3.6c-0.4-0.7-0.7-1.4-0.8-2.3c-0.1-0.9,0-1.8,0.2-2.8c0.5-1.9,1.6-3.7,2.7-4.9
						c1.2-1.2,2-1.6,2.4-2c0.3-0.1,0.8-0.1,1.2,0c0.4,0.1,0.8,0.3,1.1,0.5c0.7,0.4,1.1,1.1,1.7,1.4c0.3,0.1,0.7,0.1,1.1-0.1
						c0.4-0.3,0.8-0.8,1.1-1.6c0.2-0.8,0.2-2-0.2-3.1c-0.4-1.2-1.2-2.4-2.6-3.5c-1.6-1.1-3.4-1.5-5.4-1.5c-1.9,0-3.8,0.5-5.6,1.3
						c-3.5,1.6-6.7,4.5-9,8.7c-1.1,2.1-1.9,4.6-2.1,7.3c-0.2,2.7,0.2,5.8,1.4,8.5c1.1,2.8,2.9,5.2,5.1,7.2c2.2,2,4.8,3.6,7.7,4.6
						c2.9,1,6.3,1.4,9.3,1.1c3.1-0.3,6.1-1.2,8.7-2.6c2.7-1.4,5-3.2,7.1-5.3c1-1.1,1.9-2.2,2.8-3.4c0.8-1.2,1.5-2.4,2.1-3.7
						c2.5-5.3,3.2-10.4,3.2-14.9c0-4.6-0.8-8.7-1.8-12.3c-1-3.7-2.3-6.9-3.6-10c-1.3-3.1-2.7-6-4.2-8.9c-1.5-2.9-3-5.7-4.8-8.6
						c-1.7-2.9-3.5-5.8-5.6-8.7c-4.1-6-8.9-12.3-15.4-19.3c5.9,7.4,9.9,14.3,13.1,20.6c1.6,3.2,3,6.2,4.2,9.2c1.2,3,2.2,6,3.2,8.9
						c0.9,3,1.7,6,2.4,9c0.7,3.1,1.3,6.2,1.6,9.4c0.3,3.2,0.3,6.5-0.3,9.8C396.4,324.7,395.3,328,393.3,330.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M211.1,46.3c3.4-0.8,6.9-1,10.2-0.6c3.3,0.4,6.4,1.5,8.9,3.1c2.6,1.6,4.7,3.9,6.2,6.4c0.7,1.2,1.3,2.5,1.7,3.8
						c0.4,1.3,0.5,2.6,0.6,3.9c0.1,5.3-3,11-7.3,15.2c-1.1,1.1-2.2,2.1-3.4,2.9c-1.2,0.9-2.4,1.7-3.5,2.4c-2.4,1.4-4.7,2.1-6.6,2.3
						c-1,0.1-1.9,0-2.6-0.2c-0.8-0.2-1.6-0.5-2.3-1c-1.5-1-2.7-2.4-3.5-3.9c-0.4-0.8-0.6-1.5-0.8-2.3c-0.1-0.4-0.1-0.8-0.2-1.1
						c0-0.4,0-0.7,0-1.1c0.1-1.5,0.6-3,1.3-4.2c1.4-2.5,3.4-4.2,4.9-5.2c1.5-1,2.5-1.6,2.5-2.3c0-0.6-1.2-1.4-3.6-1.3
						c-1.2,0-2.7,0.3-4.3,0.9c-1.6,0.7-3.4,1.7-5.1,3.3c-1.7,1.6-3.3,3.8-4.3,6.8c-0.3,0.7-0.4,1.5-0.6,2.3
						c-0.1,0.8-0.2,1.6-0.2,2.5c-0.1,1.7,0.2,3.4,0.6,5.2c0.9,3.5,2.9,6.8,5.9,9.6c1.5,1.4,3.4,2.6,5.5,3.4c2.2,0.9,4.4,1.3,6.6,1.4
						c4.5,0.2,8.9-1,12.7-2.8c1.9-0.9,3.7-1.9,5.5-3.1c1.8-1.2,3.5-2.5,5.1-4c1.6-1.4,3.2-3.1,4.6-4.9c1.4-1.8,2.7-3.7,3.9-5.8
						c1.1-2.1,2.1-4.4,2.8-6.9c0.7-2.5,1.1-5.1,1.1-7.8c0-2.7-0.3-5.5-1.1-8.1c-0.8-2.6-1.9-5.1-3.3-7.4c-2.8-4.6-6.7-8.4-11.4-11.2
						c-4.7-2.8-9.9-4.2-15.1-4.6c-5.2-0.4-10.3,0.3-15.2,1.9c-5,1.6-9.3,4-13,6.7c-3.7,2.7-6.9,5.8-9.6,9
						c-5.3,6.4-9.1,13.2-11.6,19.9c-2.5,6.7-3.9,13.4-4.6,19.9c-0.7,6.5-0.7,12.8-0.1,19.1c0.6,6.3,1.8,12.4,3.6,18.6
						c1.8,6.2,4.1,12.4,6.9,18.7c5.7,12.7,13.8,25.8,24.4,40.6c-10-15.2-17.2-28.8-22-41.6c-2.4-6.4-4.2-12.6-5.4-18.7
						c-1.2-6.1-1.8-12-1.9-18c0-5.9,0.5-11.8,1.7-17.7c1.2-5.8,3-11.7,5.8-17.4c2.8-5.6,6.5-11.1,11.4-16c2.4-2.4,5.2-4.6,8.2-6.5
						C204.3,48.6,207.6,47.1,211.1,46.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M217.7,111.6c1.7-0.5,3.2-0.7,4.8-0.7c1.5,0,2.9,0.2,4,0.6c0.5,0.2,1,0.4,1.3,0.7c0.4,0.2,0.7,0.5,0.9,0.8
						c0.5,0.6,1,1.5,1.3,2.5c0.2,0.5,0.3,1.1,0.3,1.7c0.1,0.6,0.1,1.1,0,1.7c-0.1,1.1-0.4,2.2-0.7,3c-0.2,0.4-0.4,0.8-0.6,1.1
						c-0.2,0.3-0.4,0.6-0.7,0.8c-0.3,0.2-0.4,0.4-0.7,0.6c-0.3,0.2-0.6,0.3-1,0.4c-0.7,0.2-1.5,0.2-2.3,0.1c-0.7-0.1-1.4-0.3-2-0.7
						c-1.2-0.6-2.2-2-2.8-3.3c-0.6-1.3-0.9-2.6-1.1-3.6c-0.2-1-0.5-1.8-1-2.2c-0.5-0.3-1.4-0.2-2.5,0.7c-1,0.9-2.2,2.7-2.6,5.4
						c-0.2,1.3-0.2,2.9,0.1,4.5c0.3,1.7,1,3.5,2.3,5.2c2.5,3.5,7,6.2,12.4,6.3c1.3,0,2.7-0.1,4.2-0.4c1.4-0.3,2.9-0.9,4.2-1.7
						c1.3-0.8,2.5-1.7,3.6-2.8c1.1-1.1,2-2.3,2.8-3.5c1.5-2.5,2.5-5.3,3-8.3c0.2-1.5,0.3-3,0.2-4.5c-0.1-1.5-0.3-3-0.7-4.5
						c-0.8-3-2.3-6.1-4.8-8.7c-2.5-2.6-5.8-4.5-9-5.3c-3.2-0.9-6.4-1-9.4-0.6c-1.5,0.2-2.9,0.5-4.3,0.9c-1.4,0.4-2.7,1-4,1.5
						c-5.3,2.4-9.2,5.9-12.2,9.4c-1.5,1.8-2.8,3.6-3.9,5.4c-1.1,1.9-2,3.7-2.8,5.5c-3,7.5-3.6,14.5-3.3,21.3
						c0.3,6.8,1.7,13.4,4,20.4c2.2,7,5.4,14.5,9.3,23.3c-3.1-9.1-5.5-16.9-6.7-24c-1.3-7.1-1.6-13.5-0.8-19.6
						c0.7-6.1,2.5-12,5.9-17.3c0.8-1.3,1.8-2.6,2.9-3.9c1.1-1.2,2.3-2.4,3.5-3.5C211.2,114.4,214.2,112.5,217.7,111.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M252.1,32.8c3.2,0.6,6.4-0.3,8.5-1.8c2.1-1.5,3-3.4,2.5-5.1c-0.6-1.7-2.4-2.7-4.6-3c-2.2-0.3-4.8,0-7.4,1.2
						c-2.7,1.2-4.6,2.1-6.6,2.8c-2,0.7-4.2,1.2-7.3,1.3c3,1,5.1,1.7,7.3,2.5C246.6,31.4,248.8,32.2,252.1,32.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M267.3,45.6c1.8,2,3.7,3.3,5.6,4.1c1.9,0.8,4,1.1,5.5,0.2c1.5-0.9,1.9-3.2,0.7-5.8c-1.2-2.6-4-5.2-7.6-6.1
						c-3.7-0.9-6.2-0.8-8.7-0.6c-2.4,0.2-4.8,0.6-8,1.4c3.3,0.3,5.4,1.2,7.2,2.3C263.8,42.2,265.4,43.6,267.3,45.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M270,60.5c0.6,2.6,1.5,4.6,2.7,6.3c1.2,1.6,2.7,3,4.5,3.1c0.9,0,1.7-0.4,2.4-1.2c0.3-0.4,0.7-0.9,0.9-1.5
						c0.2-0.6,0.4-1.3,0.5-2c0.4-2.9-0.8-6.8-3.6-9.5c-2.9-2.7-5.2-3.8-7.5-4.7c-2.3-0.9-4.7-1.6-7.9-2.2c3,1.6,4.4,3.3,5.6,5.1
						C268.6,55.8,269.4,57.8,270,60.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M263.1,73.6c-0.7,2.7-0.6,5.1,0.1,7.2c0.7,2,1.9,3.7,3.7,4c1.7,0.3,3.5-0.9,4.7-3.2c1.1-2.3,1.5-5.6,0.3-8.6
						c-1.2-3.2-2.4-5.1-3.5-7.1c-1.2-1.9-2.3-3.8-3.9-6.5c0.6,3.1,0.5,5.2,0.2,7.3C264.3,68.8,263.9,70.8,263.1,73.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M251.6,86.6c-1.8,2-2.8,4.1-3.2,6.2c-0.4,2.1-0.1,4.1,1.2,5.3c1.3,1.2,3.5,1,5.7-0.5c2.1-1.5,4-4.4,4.4-7.7
						c0.4-3.4,0.1-5.8-0.2-8c-0.3-2.3-0.7-4.5-1.2-7.5c-0.5,3.1-1.4,5-2.5,6.8C254.7,82.9,253.5,84.5,251.6,86.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M241.2,20.8c6-2.2,9.5-7.9,7.4-10.8c-2.1-2.9-8.6-1.4-12.5,3.7c-2,2.6-3.3,4.5-4.6,6.4c-0.7,0.9-1.3,1.9-2.1,2.7
						c-0.4,0.4-0.9,0.8-1.5,1.1c-0.6,0.3-1.4,0.4-2.1,0.2c0.7,0.5,1.5,0.7,2.3,0.6c0.8,0,1.5-0.2,2.2-0.4c0.7-0.2,1.3-0.4,1.9-0.6
						c0.6-0.2,1.1-0.4,1.7-0.6C235.8,22.5,238.1,21.9,241.2,20.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M227.4,15.6c2.1-2.5,2.5-5.9,1.9-8.2c-0.7-2.4-2.4-3.8-4.1-3.6c-1.8,0.1-3.2,1.4-4.2,2.9c-1,1.5-1.6,3.3-1.6,5.5
						c0,2.4-0.2,4.2-0.7,5.9c-0.5,1.8-1.3,3.6-3.2,5.6c2.6-1.2,4.4-2.1,6.2-3.2C223.5,19.4,225.3,18.1,227.4,15.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M216.5,14.7c1.1-3.1,0.5-6.4-0.9-8.5c-1.4-2.1-3.4-2.9-5-2.3c-1.7,0.6-2.6,2.3-3.1,4.1c-0.5,1.8-0.5,3.9,0.3,6.1
						c0.8,2.4,1.2,4.2,1.4,6.1c0.2,1.9,0.1,3.9-1,6.6c1.9-2.1,3.3-3.8,4.6-5.5C214.1,19.6,215.4,17.8,216.5,14.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M204,15.5c-0.4-2.8-2.4-5-4.4-5.8c-2.1-0.8-4-0.4-5.1,1c-1.1,1.4-1.2,3.2-0.9,4.8c0.3,1.6,1.1,2.9,2.7,4.1
						c1.7,1.2,2.9,2.3,3.9,3.5c1,1.2,1.9,2.7,2.4,5.1c0.6-2.4,0.9-4.1,1.3-5.9C204.2,20.4,204.4,18.4,204,15.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M189.6,18.1c-1.2-0.9-2.6-1.3-4-1.3c-1.4,0-2.6,0.3-3.6,1c-1,0.6-1.6,1.4-1.9,2.3c-0.3,0.9-0.2,1.7,0.1,2.5
						c0.7,1.6,2.1,2.7,3.1,3.4c1.1,0.7,2,0.9,3.4,0.4c1.5-0.5,2.9-0.5,4.3-0.3c1.4,0.3,2.8,1,4.1,2.7c-0.3-2.2-0.8-3.8-1.5-5.5
						C193,21.7,192,19.9,189.6,18.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M174.9,28.6c-3.1,0-5.9,1.8-7.2,3.8c-1.3,2-1.2,4.2,0,5.4c1.2,1.3,3.1,1.6,4.8,1.4c1.8-0.2,3.4-0.8,5.1-2.3
						c1.7-1.5,3.2-2.6,4.8-3.4c1.6-0.8,3.4-1.4,6.1-1.4c-2.5-1.1-4.3-1.8-6.3-2.4C180.2,29.1,178.1,28.5,174.9,28.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M168.5,41.5c-2.5,0.6-4.3,2.4-4.8,4.3c-0.6,1.9-0.1,3.7,1.3,4.8c1.4,1.1,3.2,1.3,4.7,1c1.5-0.3,2.8-1.1,3.7-2.8
						c1-1.7,1.7-3,2.7-4.3c0.9-1.2,2.1-2.3,4.1-3.3c-2.2,0-3.8-0.1-5.6-0.2C172.8,41,171,40.9,168.5,41.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M158.7,62.1c-2.2,1.8-3.3,4.4-3.4,6.6c-0.1,2.2,0.9,4,2.5,4.7c1.6,0.7,3.5,0.1,5.1-1.1c1.6-1.2,2.8-3.1,3.3-5.6
						c0.5-2.6,0.8-4.4,1.3-6.3c0.5-1.8,1.1-3.6,2.6-6c-2.2,1.6-3.9,2.7-5.6,3.7C162.7,59.2,161,60.2,158.7,62.1z"
                    />
                  </g>
                  <g>
                    <path d="M155.7,90.1c-2.8,3.4-1.4,8,2,9c3.4,1,7.1-2.2,6.5-6.5c-0.6-4.5-1.6-6.4-1.4-10.8C160.4,85.5,158.6,86.6,155.7,90.1z" />
                  </g>
                  <g>
                    <path
                      d="M157,113.8c-1.1,1.8-0.9,3.7-0.1,5.2c0.8,1.4,2.4,2.4,4.1,2.4c1.8,0,3.3-0.9,4.2-2.1c0.9-1.3,1.2-2.9,0.5-4.7
						c-1.5-3.7-2.8-5.3-3.2-9.2C160.8,108.9,159.2,110.1,157,113.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M191.5,102.9c2.1-4.3,3.5-8.8,3.9-12.4c0.5-3.6,0.1-6.5-1.5-7.2c-1.6-0.7-4.1,0.9-6.3,4.1c-2.2,3.2-4.1,8-4.6,13.1
						c-0.5,5.3-0.4,8.9-0.3,12.5c0.1,3.6,0.2,7.2,0.2,12.4c1-5.1,2.2-8.5,3.5-11.8C187.9,110.5,189.4,107.4,191.5,102.9z"
                    />
                  </g>
                  <g>
                    <g>
                      <path
                        d="M6.8,333.4c-3-1.7-5.7-4-7.9-6.6c-2.1-2.6-3.6-5.5-4.4-8.4c-0.8-2.9-0.8-6.1-0.2-8.9c0.3-1.4,0.8-2.7,1.4-3.9
							c0.6-1.2,1.4-2.2,2.3-3.2c3.6-3.9,9.7-6,15.7-6.1c1.5-0.1,3,0,4.5,0.1c1.5,0.1,2.9,0.4,4.2,0.7c2.7,0.6,4.8,1.6,6.3,2.8
							c0.8,0.6,1.4,1.3,1.8,1.9c0.5,0.7,0.8,1.5,1,2.3c0.4,1.7,0.4,3.6-0.2,5.2c-0.2,0.8-0.6,1.6-1,2.2c-0.2,0.4-0.4,0.6-0.7,0.9
							c-0.3,0.3-0.5,0.6-0.8,0.8c-1.1,1-2.5,1.7-3.9,2.2c-2.8,0.9-5.4,0.7-7.1,0.5c-1.8-0.3-3-0.5-3.4,0c-0.4,0.5-0.1,1.8,1.7,3.4
							c0.9,0.8,2.1,1.6,3.8,2.3c1.6,0.6,3.7,1.1,6,1.1c2.3,0,5.1-0.5,7.7-2c0.7-0.3,1.3-0.8,2-1.3c0.6-0.5,1.3-1,1.9-1.6
							c1.2-1.2,2.2-2.6,3.1-4.2c1.7-3.1,2.6-6.9,2.2-11c-0.2-2-0.7-4.2-1.7-6.3c-1-2.1-2.3-4-3.9-5.6c-3.2-3.2-7.2-5.3-11.2-6.6
							c-2-0.7-4.1-1.2-6.1-1.5c-2.1-0.4-4.2-0.5-6.4-0.6c-2.2-0.1-4.4,0.1-6.7,0.4c-2.3,0.3-4.6,0.9-6.8,1.6
							c-2.3,0.8-4.6,1.8-6.8,3.1c-2.2,1.4-4.3,3-6.1,4.9c-1.8,2-3.5,4.2-4.7,6.7c-1.2,2.4-2.1,5-2.7,7.6c-1.1,5.3-0.8,10.7,0.7,15.9
							c1.5,5.2,4.4,9.9,7.9,13.7c3.5,3.8,7.8,6.8,12.3,9c4.7,2.2,9.5,3.4,14.1,4c4.6,0.6,9,0.5,13.1,0c8.3-1,15.6-3.4,22.1-6.6
							c6.4-3.2,12-7.1,17-11.4c4.9-4.3,9.2-8.9,13.1-13.9c3.8-5,7.2-10.3,10.1-16c2.9-5.7,5.5-11.8,7.7-18.4
							c4.5-13.2,7.6-28.3,9.9-46.4c-3.1,18-7.1,32.8-12.3,45.4c-2.6,6.3-5.6,12.1-8.8,17.3c-3.3,5.3-6.9,10-10.9,14.4
							c-4,4.3-8.4,8.3-13.3,11.8c-4.9,3.5-10.2,6.5-16.1,8.7c-5.9,2.2-12.4,3.7-19.2,3.9c-3.4,0.1-6.9-0.1-10.4-0.9
							C13.3,336.4,9.9,335.2,6.8,333.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M46.6,281.2c-1.6-0.8-2.8-1.7-4-2.7c-1.1-1-2-2.1-2.5-3.1c-0.3-0.5-0.4-1-0.5-1.4c-0.1-0.4-0.1-0.8-0.1-1.2
							c0-0.8,0.3-1.7,0.8-2.7c0.3-0.5,0.6-1,0.9-1.5c0.4-0.5,0.7-0.9,1.1-1.2c0.8-0.8,1.8-1.4,2.6-1.7c0.4-0.2,0.8-0.3,1.2-0.4
							c0.4-0.1,0.7-0.1,1-0.1c0.3,0,0.6,0,0.9,0.1c0.3,0.1,0.6,0.2,1,0.4c0.7,0.3,1.3,0.9,1.7,1.5c0.5,0.6,0.8,1.2,1,1.8
							c0.4,1.3,0.2,2.9-0.2,4.3c-0.5,1.4-1.1,2.5-1.7,3.4c-0.5,0.9-0.9,1.6-0.7,2.3c0.2,0.6,0.9,1.1,2.3,1.2c1.4,0,3.5-0.5,5.6-2.2
							c1.1-0.8,2.1-1.9,3-3.4c0.9-1.4,1.6-3.3,1.9-5.4c0.6-4.3-0.9-9.3-4.7-13c-1-0.9-2.1-1.8-3.3-2.5c-1.3-0.7-2.7-1.3-4.2-1.6
							c-1.5-0.3-3-0.5-4.5-0.5c-1.5,0.1-3,0.3-4.4,0.7c-2.9,0.8-5.5,2.2-7.8,4c-1.2,0.9-2.3,2-3.2,3.2c-1,1.2-1.8,2.4-2.6,3.8
							c-1.5,2.7-2.5,6-2.5,9.6c0,3.6,1.2,7.2,2.9,10c1.7,2.9,4,5.1,6.4,6.8c1.2,0.9,2.5,1.6,3.8,2.3c1.3,0.7,2.7,1.2,4,1.6
							c5.5,1.8,10.7,2,15.4,1.5c2.3-0.3,4.5-0.7,6.6-1.3c2.1-0.6,4-1.3,5.8-2.2c7.3-3.4,12.6-8.1,17-13.2c4.4-5.2,7.9-10.9,11-17.6
							c3.2-6.7,5.9-14.3,9.1-23.4c-4,8.8-7.5,16.1-11.4,22.1c-3.9,6.1-8.1,10.9-12.8,14.9c-4.7,3.9-10,7-16.1,8.6
							c-1.5,0.4-3.1,0.7-4.7,0.9c-1.6,0.2-3.3,0.2-5,0.1C53.2,283.5,49.7,282.8,46.6,281.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M-32.4,315.2c-2-2.6-4.9-4.2-7.4-4.5c-2.5-0.4-4.6,0.4-5.3,2c-0.7,1.6-0.1,3.6,1.3,5.3c1.4,1.7,3.5,3.3,6.2,4.2
							c2.8,0.9,4.8,1.6,6.8,2.5c2,0.9,3.9,1.9,6.2,4c-1.5-2.8-2.6-4.7-3.6-6.8C-29.3,320-30.4,317.9-32.4,315.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M-34.7,295.5c0-2.7-0.4-4.9-1.3-6.9c-0.9-1.9-2.1-3.5-3.9-3.8c-1.7-0.3-3.6,1.1-4.5,3.8c-0.9,2.7-0.6,6.6,1.4,9.7
							c2.1,3.2,4,4.8,5.9,6.3c1.9,1.5,3.9,2.8,6.8,4.4c-2.2-2.4-3.1-4.5-3.7-6.6C-34.5,300.4-34.8,298.3-34.7,295.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M-26.6,282.8c1.3-2.3,2-4.4,2.3-6.5c0.2-2,0.1-4.1-1.2-5.3c-0.6-0.6-1.5-0.9-2.5-0.8c-0.5,0.1-1.1,0.2-1.6,0.5
							c-0.5,0.3-1.2,0.6-1.7,1.1c-2.3,1.9-4.1,5.5-3.9,9.3c0.3,3.9,1.2,6.3,2.3,8.6c1.1,2.2,2.3,4.4,4.3,7c-1.1-3.2-1-5.5-0.6-7.6
							C-28.7,287.1-27.9,285.2-26.6,282.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M-12.6,277.9c2.3-1.5,3.9-3.4,4.9-5.3c0.9-1.9,1.1-4,0.1-5.4c-1-1.4-3.2-1.8-5.6-0.9c-2.4,0.9-4.9,3.1-6.2,6.1
							c-1.3,3.1-1.8,5.4-2.3,7.6c-0.4,2.2-0.9,4.4-1.5,7.4c1.7-2.6,3.2-4.2,4.8-5.5C-16.7,280.6-15,279.4-12.6,277.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M4.7,276.2c2.7-0.3,4.9-1.1,6.6-2.4c1.7-1.3,2.9-2.9,2.7-4.7c-0.2-1.7-1.9-3.2-4.4-3.5c-2.5-0.4-5.9,0.4-8.5,2.6
							c-2.6,2.3-4,4.2-5.4,6c-1.3,1.9-2.6,3.7-4.3,6.3c2.5-1.9,4.5-2.7,6.5-3.3C-0.1,276.8,1.9,276.5,4.7,276.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M-32.6,331.5c-5.9-2.5-12.3-0.7-12.8,2.8c-0.4,3.5,5.4,6.9,11.7,5.9c3.2-0.5,5.5-1.1,7.8-1.5c1.2-0.2,2.2-0.5,3.4-0.5
							c0.6,0,1.2,0,1.8,0.2c0.6,0.2,1.3,0.6,1.7,1.3c-0.2-0.8-0.7-1.5-1.2-2c-0.6-0.5-1.2-0.9-1.8-1.2c-0.6-0.3-1.2-0.6-1.8-0.8
							c-0.6-0.2-1.1-0.5-1.6-0.7C-27.5,333.9-29.6,332.8-32.6,331.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M-26.1,344.6c-3.2,0.4-5.9,2.5-7,4.8c-1.2,2.2-0.8,4.4,0.5,5.5c1.4,1.1,3.3,1.1,5.1,0.8c1.8-0.4,3.4-1.3,4.9-3
							c1.6-1.7,3-2.9,4.6-3.9c1.6-1,3.4-1.7,6.2-1.9c-2.7-0.9-4.6-1.4-6.7-1.9C-20.6,344.6-22.8,344.2-26.1,344.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M-18.7,352.8c-2.9,1.5-4.7,4.4-5.1,6.8c-0.4,2.5,0.5,4.5,2.1,5.1c1.7,0.7,3.5,0.1,5.1-0.9c1.6-1,3-2.5,4-4.7
							c1-2.2,2-3.9,3.1-5.4c1.2-1.5,2.6-2.9,5.2-4.2c-2.9,0.2-5,0.5-7.1,0.9C-13.6,350.8-15.8,351.3-18.7,352.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M-9,360.7c-1.6,2.3-1.7,5.3-0.7,7.3c0.9,2,2.7,3,4.4,2.7c1.7-0.3,3.1-1.5,4-2.9c0.9-1.4,1.2-2.9,0.8-4.8
							c-0.4-2-0.6-3.6-0.5-5.2c0.1-1.6,0.4-3.3,1.7-5.4c-2,1.3-3.5,2.4-5,3.5C-5.8,357-7.4,358.3-9,360.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M3.3,368.7c0.3,1.5,1,2.7,2,3.7c1,0.9,2.1,1.5,3.2,1.7c1.1,0.2,2.1,0,2.9-0.4c0.8-0.4,1.3-1.1,1.6-1.9
							c0.6-1.7,0.4-3.4,0.1-4.6c-0.3-1.3-0.8-2.1-2.2-2.7c-1.4-0.7-2.5-1.6-3.3-2.7c-0.8-1.2-1.4-2.6-1.1-4.8
							c-1.3,1.8-2,3.4-2.6,5.1C3.3,363.7,2.7,365.7,3.3,368.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M21.2,371c2.3,2.1,5.5,2.7,7.9,2.1c2.4-0.6,3.8-2.2,3.7-3.9c0-1.8-1.2-3.3-2.6-4.4c-1.4-1.1-3.1-1.7-5.3-1.8
							c-2.3-0.1-4.1-0.3-5.8-0.8c-1.7-0.5-3.4-1.3-5.4-3.2c1.1,2.5,1.9,4.3,2.9,6.1C17.7,367,18.8,368.9,21.2,371z"
                      />
                    </g>
                    <g>
                      <path
                        d="M34.6,366c2.2,1.3,4.7,1.2,6.4,0.2c1.7-1,2.6-2.7,2.3-4.4c-0.3-1.7-1.4-3.1-2.7-4c-1.3-0.8-2.8-1.1-4.7-0.5
							c-1.9,0.6-3.3,1-4.8,1.3c-1.5,0.2-3.1,0.3-5.2-0.4c1.6,1.5,2.7,2.7,3.9,3.9C31.1,363.4,32.4,364.7,34.6,366z"
                      />
                    </g>
                    <g>
                      <path
                        d="M55.9,357.6c2.9,0.2,5.5-1,7-2.5c1.6-1.6,2.1-3.5,1.4-5.1c-0.7-1.6-2.5-2.5-4.5-2.7c-2-0.2-4.2,0.4-6.2,1.9
							c-2.1,1.5-3.6,2.7-5.2,3.7c-1.6,1-3.3,1.9-6,2.6c2.7,0.3,4.7,0.7,6.6,1.1C51,357,52.9,357.4,55.9,357.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M77.2,339.2c4.4-0.5,6.5-4.9,4.7-7.9c-1.8-3-6.6-3.2-9.2,0.4c-2.6,3.7-3.2,5.7-6.3,8.9
							C70.6,339.3,72.7,339.8,77.2,339.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M92.4,321c2-0.6,3.2-2.1,3.6-3.7c0.4-1.6-0.1-3.4-1.4-4.6c-1.3-1.2-3-1.6-4.5-1.3c-1.5,0.3-2.9,1.3-3.6,3.1
							c-1.4,3.7-1.5,5.7-3.9,8.9C86.3,321.9,88.3,322.2,92.4,321z"
                      />
                    </g>
                    <g>
                      <path
                        d="M59.8,305.3c-4.5,1.7-8.5,4.1-11.4,6.4c-2.8,2.3-4.5,4.7-3.8,6.3c0.7,1.6,3.6,2.1,7.4,1.3c3.8-0.8,8.5-3,12.3-6.5
							c3.9-3.5,6.3-6.2,8.8-8.9c2.4-2.7,4.8-5.4,8.3-9.3c-4.2,3.1-7.4,4.7-10.6,6.2C67.6,302.3,64.4,303.6,59.8,305.3z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M363.6,398.2c3.2,1.3,6.2,3.2,8.6,5.5c2.4,2.3,4.3,5,5.4,7.7c1.1,2.8,1.6,5.9,1.3,8.8c-0.1,1.4-0.4,2.8-0.9,4.1
							c-0.4,1.3-1.1,2.4-1.8,3.5c-3,4.3-8.8,7.2-14.8,8.1c-1.5,0.3-3,0.4-4.4,0.4c-1.5,0.1-2.9,0-4.3-0.1c-2.7-0.3-5-1-6.7-2
							c-0.8-0.5-1.5-1.1-2-1.7c-0.5-0.6-1-1.4-1.3-2.2c-0.6-1.7-0.8-3.6-0.5-5.2c0.1-0.8,0.4-1.6,0.7-2.3c0.1-0.4,0.4-0.7,0.5-1
							c0.2-0.3,0.4-0.6,0.7-0.9c1-1.2,2.2-2.1,3.6-2.7c2.6-1.2,5.2-1.4,7-1.4c1.8,0,3,0.1,3.4-0.4c0.4-0.5-0.2-1.8-2.1-3.2
							c-1-0.7-2.3-1.3-4-1.8c-1.7-0.4-3.8-0.6-6.1-0.3c-2.3,0.3-4.9,1.2-7.4,3c-0.6,0.4-1.2,1-1.8,1.5c-0.6,0.6-1.2,1.2-1.6,1.9
							c-1,1.3-1.9,2.9-2.5,4.5c-1.3,3.3-1.6,7.2-0.8,11.2c0.4,2,1.2,4.1,2.4,6c1.3,2,2.8,3.6,4.6,5c3.6,2.8,7.8,4.3,11.9,5.1
							c2.1,0.4,4.2,0.6,6.3,0.7c2.1,0.1,4.3,0,6.4-0.3c2.2-0.2,4.4-0.7,6.6-1.3c2.2-0.6,4.4-1.4,6.6-2.5c2.2-1.1,4.3-2.4,6.3-4
							c2-1.6,3.8-3.5,5.5-5.7c1.6-2.2,2.9-4.6,3.8-7.2c0.9-2.6,1.5-5.2,1.7-7.9c0.4-5.4-0.6-10.7-2.7-15.7c-2.2-5-5.6-9.2-9.6-12.6
							c-4-3.3-8.6-5.8-13.4-7.3c-5-1.6-9.9-2.2-14.5-2.1c-4.6,0-9,0.7-13,1.7c-8.1,2.1-15,5.4-21,9.4c-6,4-11,8.6-15.3,13.5
							c-4.3,4.9-8,10-11.1,15.5c-3.1,5.4-5.8,11.2-8,17.2c-2.2,6.1-3.9,12.4-5.3,19.3c-2.7,13.7-3.8,29-3.8,47.2
							c0.7-18.2,2.8-33.4,6.3-46.6c1.8-6.6,3.9-12.7,6.5-18.3c2.6-5.7,5.5-10.9,8.9-15.7c3.4-4.8,7.3-9.3,11.6-13.4
							c4.4-4.1,9.3-7.7,14.8-10.7c5.5-3,11.8-5.2,18.5-6.4c3.4-0.5,6.9-0.8,10.4-0.5C356.7,396.1,360.3,396.8,363.6,398.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M331,455.1c1.7,0.6,3,1.3,4.3,2.2c1.2,0.9,2.2,1.9,2.9,2.8c0.3,0.5,0.5,0.9,0.7,1.3c0.2,0.4,0.2,0.8,0.3,1.2
							c0.1,0.8,0,1.8-0.4,2.8c-0.2,0.5-0.4,1.1-0.7,1.6c-0.3,0.5-0.6,0.9-1,1.4c-0.7,0.9-1.6,1.6-2.4,2.1c-0.4,0.2-0.8,0.4-1.1,0.6
							c-0.4,0.1-0.7,0.2-1,0.3c-0.3,0-0.6,0.1-0.9,0c-0.3,0-0.7-0.1-1-0.2c-0.7-0.3-1.4-0.7-1.9-1.2c-0.5-0.5-1-1.1-1.2-1.7
							c-0.6-1.2-0.6-2.9-0.3-4.3c0.3-1.4,0.8-2.6,1.2-3.6c0.4-1,0.7-1.7,0.4-2.4c-0.2-0.6-1-1-2.5-0.9c-1.4,0.2-3.4,0.9-5.3,2.9
							c-0.9,1-1.9,2.2-2.6,3.7c-0.7,1.6-1.2,3.4-1.2,5.6c0,4.3,2.1,9.1,6.4,12.3c1.1,0.8,2.3,1.5,3.6,2.1c1.3,0.5,2.9,0.9,4.4,1.1
							c1.5,0.1,3,0.1,4.5-0.1c1.5-0.3,2.9-0.7,4.3-1.2c2.7-1.2,5.2-2.9,7.2-5c1-1.1,2-2.3,2.8-3.6c0.8-1.3,1.5-2.6,2-4.1
							c1.1-2.9,1.7-6.3,1.2-9.9c-0.5-3.6-2.1-7-4.2-9.5c-2.1-2.6-4.6-4.5-7.3-5.9c-1.3-0.7-2.7-1.3-4.1-1.8
							c-1.4-0.5-2.8-0.8-4.1-1.1c-5.7-1.1-10.9-0.6-15.4,0.5c-2.3,0.6-4.4,1.3-6.3,2.2c-2,0.9-3.8,1.8-5.5,2.9
							c-6.8,4.3-11.4,9.7-15.1,15.3c-3.7,5.7-6.4,11.9-8.7,18.9c-2.3,7-4,15-6,24.4c2.8-9.2,5.4-16.9,8.5-23.4
							c3.1-6.5,6.6-11.9,10.7-16.4c4.1-4.5,9-8.3,14.8-10.6c1.5-0.6,3-1.1,4.6-1.5c1.6-0.4,3.2-0.6,4.9-0.8
							C324,453.6,327.6,453.9,331,455.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M404.9,411.1c2.3,2.3,5.4,3.5,7.9,3.5c2.5,0,4.5-1,5-2.7c0.5-1.7-0.4-3.6-2-5.1c-1.6-1.6-3.9-2.8-6.7-3.3
							c-2.9-0.6-5-1-7-1.6c-2.1-0.6-4.1-1.4-6.7-3.2c1.9,2.5,3.2,4.4,4.4,6.3C401.2,406.8,402.5,408.7,404.9,411.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M409.7,430.4c0.3,2.7,1.1,4.8,2.2,6.6c1.1,1.8,2.6,3.2,4.3,3.3c1.7,0.1,3.4-1.5,4-4.4c0.6-2.8-0.2-6.6-2.6-9.4
							c-2.5-2.9-4.6-4.3-6.7-5.5c-2.1-1.2-4.2-2.3-7.3-3.5c2.5,2.1,3.7,4.1,4.5,6.1C408.9,425.6,409.4,427.6,409.7,430.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M403.3,444.1c-1,2.4-1.5,4.6-1.4,6.7c0,2,0.4,4,1.8,5.1c0.7,0.5,1.6,0.7,2.6,0.4c0.5-0.1,1.1-0.3,1.6-0.7
							c0.5-0.3,1.1-0.8,1.6-1.3c2-2.1,3.4-6,2.6-9.8c-0.8-3.8-2.1-6.1-3.4-8.2c-1.4-2.1-2.8-4-5.2-6.4c1.5,3,1.7,5.3,1.5,7.4
							C404.8,439.5,404.3,441.5,403.3,444.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M390,450.7c-2.1,1.7-3.5,3.8-4.1,5.9c-0.6,2-0.6,4.1,0.6,5.4c1.2,1.3,3.4,1.3,5.6,0.1c2.2-1.2,4.4-3.7,5.3-6.8
							c0.9-3.3,1.1-5.6,1.3-7.8c0.2-2.3,0.3-4.5,0.6-7.6c-1.3,2.8-2.6,4.5-4.1,6.1C393.8,447.5,392.2,448.9,390,450.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M373.1,454.6c-2.6,0.6-4.7,1.8-6.2,3.2c-1.5,1.5-2.5,3.3-2.1,5c0.4,1.7,2.3,2.9,4.9,2.9c2.6,0,5.8-1.2,8.1-3.7
							c2.3-2.6,3.4-4.6,4.5-6.7c1.1-2,2.1-4,3.4-6.8c-2.2,2.2-4.1,3.3-6,4.1C377.8,453.4,375.8,453.9,373.1,454.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M402.9,395c6.1,1.8,12.3-0.9,12.3-4.5c0-3.5-6.2-6.1-12.3-4.3c-3.2,0.9-5.3,1.8-7.5,2.5c-1.1,0.4-2.2,0.8-3.3,0.9
							c-0.6,0.1-1.2,0.1-1.8,0c-0.6-0.1-1.3-0.5-1.8-1.1c0.3,0.8,0.9,1.4,1.5,1.9c0.6,0.4,1.3,0.7,2,1c0.6,0.2,1.3,0.4,1.9,0.6
							c0.6,0.2,1.1,0.3,1.7,0.5C397.6,393.2,399.8,394.1,402.9,395z"
                      />
                    </g>
                    <g>
                      <path
                        d="M394.7,382.8c3.1-0.8,5.5-3.3,6.3-5.6c0.9-2.4,0.2-4.4-1.3-5.3c-1.5-0.9-3.4-0.7-5.1-0.1c-1.7,0.6-3.2,1.7-4.5,3.6
							c-1.4,1.9-2.6,3.3-4,4.4c-1.4,1.1-3.1,2.2-5.9,2.7c2.8,0.5,4.8,0.8,6.9,1C389.3,383.5,391.5,383.6,394.7,382.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M386.4,375.6c2.7-1.9,4.1-4.9,4.2-7.4c0.1-2.5-1.1-4.4-2.8-4.8c-1.7-0.4-3.5,0.4-5,1.5c-1.5,1.2-2.6,2.9-3.3,5.2
							c-0.7,2.4-1.5,4.1-2.4,5.8c-1,1.6-2.2,3.2-4.6,4.8c2.8-0.6,4.8-1.2,6.9-1.8C381.5,378.3,383.7,377.5,386.4,375.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M375.7,369.1c1.3-2.5,1-5.5-0.2-7.3c-1.2-1.9-3.1-2.6-4.7-2.1c-1.7,0.5-2.9,1.9-3.6,3.3c-0.7,1.5-0.8,3-0.2,4.9
							c0.6,1.9,1,3.5,1.2,5.1c0.1,1.6,0,3.3-1,5.5c1.8-1.6,3.2-2.8,4.5-4.1C373.1,373.1,374.4,371.7,375.7,369.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M362.5,362.8c-0.5-1.4-1.4-2.6-2.5-3.4c-1.1-0.8-2.3-1.2-3.4-1.3c-1.1-0.1-2.1,0.2-2.8,0.8c-0.7,0.5-1.2,1.3-1.4,2.1
							c-0.4,1.7,0.1,3.4,0.5,4.6c0.5,1.2,1.1,1.9,2.5,2.4c1.5,0.5,2.7,1.2,3.6,2.3c0.9,1,1.7,2.4,1.8,4.6c1-2,1.6-3.6,2-5.4
							C363.2,367.7,363.5,365.7,362.5,362.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M344.5,362.7c-2.6-1.8-5.9-2-8.1-1.1c-2.3,0.9-3.4,2.7-3.2,4.4c0.3,1.8,1.6,3.1,3.1,4c1.5,0.9,3.3,1.3,5.5,1.1
							c2.3-0.2,4.1-0.2,5.8,0c1.8,0.3,3.6,0.8,5.8,2.5c-1.4-2.3-2.5-4-3.7-5.7C348.5,366.3,347.1,364.6,344.5,362.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M331.8,369.5c-2.3-1-4.8-0.5-6.4,0.6c-1.6,1.2-2.2,3-1.8,4.7c0.5,1.7,1.8,2.9,3.2,3.6c1.4,0.6,3,0.7,4.7-0.1
							c1.8-0.8,3.2-1.5,4.6-1.9c1.5-0.4,3-0.7,5.2-0.3c-1.8-1.3-3.1-2.3-4.4-3.4C335.6,371.6,334.2,370.5,331.8,369.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M311.8,380.6c-2.9,0.2-5.3,1.7-6.6,3.4c-1.4,1.8-1.7,3.8-0.7,5.3c0.9,1.5,2.8,2.1,4.8,2c2-0.1,4.1-0.9,5.9-2.7
							c1.9-1.8,3.2-3.1,4.7-4.3c1.5-1.2,3-2.3,5.6-3.4c-2.8,0-4.7-0.1-6.7-0.3C316.8,380.6,314.7,380.4,311.8,380.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M293.1,401.5c-4.3,1.1-5.8,5.7-3.6,8.4c2.2,2.8,7,2.4,9-1.5c2.1-4,2.4-6.1,5.1-9.6
							C299.6,400.6,297.5,400.4,293.1,401.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M280.3,421.6c-1.9,0.9-2.9,2.5-3.1,4.2c-0.2,1.7,0.5,3.3,2,4.4c1.4,1,3.2,1.2,4.7,0.7c1.5-0.5,2.7-1.7,3.1-3.6
							c0.9-3.9,0.8-5.9,2.7-9.4C286.3,419.9,284.3,419.9,280.3,421.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M314.7,432.9c4.2-2.3,7.9-5.1,10.4-7.8c2.5-2.7,3.8-5.2,2.9-6.7c-0.9-1.5-3.8-1.6-7.5-0.3c-3.7,1.3-8,4.1-11.4,8
							c-3.5,4-5.5,7-7.5,10c-2,3-4,6-7,10.3c3.8-3.6,6.7-5.7,9.7-7.5C307.3,436.9,310.4,435.2,314.7,432.9z"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M344.5,109.3c1.3-4.4,1.9-9.1,1.4-13.7c-0.4-4.6-1.8-9.3-4.3-13.4c-0.3-0.5-0.6-1-1-1.5c-0.4-0.5-0.7-1-1.1-1.5
						c-0.8-0.9-1.5-1.8-2.4-2.7c-1.7-1.7-3.6-3.1-5.6-4.3c-4-2.4-8.3-3.8-12.6-4.4c-4.3-0.6-8.7-0.6-12.9,0.4
						c-4.2,0.9-8.3,2.7-11.6,5.5c-3.3,2.7-5.9,6.2-7.2,10.2c-0.7,1.9-1.1,4.1-1.1,6.1c0,2,0.2,4,0.8,5.9c1.1,3.7,3.3,7,6.3,9.2
						c3,2.2,6.5,3.1,9.6,3c3.1-0.1,6.1-1.1,8.3-2.8c1.1-0.9,2-1.9,2.7-2.9c0.7-1.1,1.2-2.2,1.4-3.2c0.6-2.1,0.5-4.1,0.2-5.7
						c-0.3-1.6-1-2.9-1.6-3.8c-1.4-1.8-2.6-2.1-2.9-1.8c-0.3,0.3,0.2,1.3,0.6,2.9c0.2,0.8,0.3,1.8,0.2,3c-0.1,1.1-0.4,2.4-1,3.7
						c-0.3,0.6-0.7,1.2-1.2,1.7c-0.5,0.5-1.1,0.9-1.7,1.3c-1.3,0.7-3,1.1-4.8,0.9c-1.8-0.2-3.7-0.9-5-2.1c-1.4-1.3-2.5-3.2-2.9-5.3
						c-0.2-1.1-0.3-2.2-0.2-3.4c0.1-1.1,0.4-2.2,0.8-3.3c0.9-2.2,2.6-4.3,4.7-6c2.1-1.6,4.8-2.7,7.7-3.3c2.9-0.6,6.2-0.6,9.4-0.1
						c6.4,1,12.6,4.2,15.9,9.6c3.5,5.4,4.4,12.8,2.7,19.6c-0.4,1.7-1,3.4-1.8,5c-0.7,1.6-1.6,3.1-2.6,4.6c-2,2.9-4.2,5.4-6.7,7.6
						c-5,4.4-10.6,7.5-16.3,9.7c-5.7,2.3-11.4,3.6-17.1,4.6c-5.7,1-11.4,1.5-17.1,1.9c-5.7,0.4-11.5,0.5-17.5,0.9
						c-6,0.4-12.3,1.1-18.6,3c-3.2,0.9-6.4,2.1-9.5,3.8c-3.1,1.6-6.1,3.7-8.8,6.3c-2.7,2.6-5,5.8-6.7,9.4c-1.6,3.6-2.6,7.8-2.2,12.1
						c-0.2-4.3,0.9-8.4,2.6-12c1.8-3.5,4.1-6.6,6.9-9c2.7-2.4,5.7-4.3,8.8-5.8c3.1-1.4,6.2-2.5,9.4-3.2c6.3-1.5,12.4-1.9,18.3-1.9
						c5.9-0.1,11.7,0.2,17.5,0.2c5.8,0,11.6-0.2,17.6-0.8c6-0.7,12.2-1.7,18.5-3.8c6.3-2,12.9-5,19-9.8c3.1-2.4,6-5.2,8.6-8.6
						C340.9,117.8,343.1,113.9,344.5,109.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M278.5,96c-3.9-1.2-8.4-1.3-12.5,0.5c-2,0.9-3.8,2.1-5.3,3.7c-1.5,1.5-2.7,3.3-3.6,5.3c-0.8,2-1.2,4.2-1.1,6.4
						c0.1,2.2,0.7,4.2,1.6,6c1.8,3.5,4.9,6.1,8.4,7c3.5,1,6.9,0.3,9.4-1.4c1.2-0.8,2.2-1.9,2.8-3c0.6-1.1,0.9-2.2,1-3.1
						c0.2-1.9-0.3-3.4-0.9-4.2c-0.6-0.8-1.2-1-1.6-0.9c-0.8,0.3-0.9,2.1-2.2,3.6c-0.3,0.4-0.7,0.8-1.1,1c-0.4,0.3-0.8,0.4-1.3,0.5
						c-1,0.2-2.4,0.1-3.4-0.5c-1.1-0.6-2.2-1.8-2.7-3.2c-0.6-1.5-0.5-2.8,0.1-4.4c0.7-1.6,2.1-3.1,3.8-4c1.7-0.9,3.9-1.2,6.3-0.9
						c4.9,0.7,8.5,4.3,10.6,8.1c2.2,3.9,3,8.1,2.7,12.3c-0.2,2.1-0.6,4.2-1.5,6.2c-0.4,1-1,2-1.6,3c-0.7,0.9-1.4,1.8-2.4,2.7
						c-1.8,1.6-4.1,3-6.9,3.8c-2.7,0.7-6,0.9-9.2-0.3c3.1,1.4,6.5,1.6,9.4,1.1c3-0.5,5.6-1.7,7.8-3.3c1.1-0.8,2.1-1.7,3-2.6
						c0.9-1,1.7-2,2.3-3.1c1.4-2.1,2.2-4.5,2.9-6.9c1.1-4.8,1-10-0.9-15.4c-1-2.7-2.4-5.4-4.7-8C285.7,99.6,282.5,97.2,278.5,96z"
                    />
                  </g>
                  <g>
                    <path
                      d="M315.1,122.6c2.7-2.4,4.1-5.8,4.2-8.5c0.1-2.8-0.9-4.8-2.6-5.3c-1.7-0.5-3.6,0.5-5.2,2.2c-1.6,1.7-2.9,3.9-3.7,6.8
						c-0.8,2.9-1.5,5-2.5,7.1c-1,2-2.1,4-4.5,6.4c3-1.5,5.1-2.6,7.3-3.8C310.2,126.3,312.4,125.1,315.1,122.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M366,89.8c2.5-1.7,4.3-3.8,5.4-5.9c1.1-2.1,1.5-4.2,0.4-5.7c-1-1.4-3.3-1.7-5.9-0.7c-2.6,1.1-5.4,3.6-6.8,6.9
						c-1.5,3.5-2,6-2.5,8.4c-0.4,2.5-0.8,4.9-1.4,8.3c1.5-3.1,3.1-4.9,4.7-6.6C361.7,93,363.5,91.6,366,89.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M379.9,95.6c3-0.8,5.4-2.4,6.9-4.3c1.5-1.8,2.2-3.9,1.4-5.5c-0.8-1.6-2.9-2.2-5.4-1.9c-2.5,0.3-5.4,1.7-7.6,4.1
						c-2.3,2.5-3.6,4.4-5,6.2c-1.4,1.8-2.8,3.6-5.1,6c2.9-1.5,5.1-2.3,7.2-2.9C374.6,96.9,376.8,96.4,379.9,95.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M390.9,105.2c3.1,0.2,5.9-0.4,8-1.5c2.1-1.1,3.7-2.6,3.6-4.4c0-1.7-1.7-3.3-4.4-4c-2.6-0.7-6.2-0.4-9.3,1.3
						c-3.2,1.7-5.2,3.3-7.1,4.7c-1.9,1.5-3.8,3-6.6,5c3.3-1.1,5.6-1.4,7.9-1.5C385.4,104.9,387.7,105,390.9,105.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M360.3,116.8c2.7-0.8,4.8-2.3,6.1-4c1.3-1.7,2-3.7,1.2-5.3c-0.7-1.6-2.7-2.4-5.1-2.1c-2.4,0.3-5.2,1.7-7.2,4.2
						c-2,2.5-3.1,4.5-4.2,6.4c-1.1,1.9-2.2,3.8-3.9,6.3c2.5-1.8,4.4-2.7,6.3-3.5C355.5,118.1,357.5,117.6,360.3,116.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M373.4,121.3c2.6,0.1,4.9-0.6,6.6-1.8c1.7-1.2,2.8-2.9,2.6-4.6c-0.2-1.7-1.8-3.1-4.1-3.6c-2.3-0.5-5.2,0-7.7,1.7
						c-2.5,1.8-4.1,3.3-5.6,4.7c-1.5,1.5-3,2.9-5.3,4.7c2.8-0.9,4.8-1.1,6.7-1.2C368.7,121,370.7,121.2,373.4,121.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M293.2,147.9c-6.4,0.5-11.3,5.2-10.1,8.5c1.2,3.3,8,3.6,13.2-0.3c5.3-4,7-6.2,12.8-9.5
						C302.5,148,299.8,147.4,293.2,147.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M300.9,162.8c-1.7,2.4-2,5.5-1.2,7.6c0.7,2.2,2.4,3.5,4.1,3.4c1.8-0.1,3.2-1.3,4.2-2.8c1-1.5,1.5-3.4,1.3-5.6
						c-0.2-2.3-0.3-4.1-0.2-5.9c0.2-1.8,0.5-3.6,1.8-5.9c-2.1,1.6-3.6,2.8-5.1,4.1C304.2,159,302.7,160.4,300.9,162.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M250.7,158.6c5.2-0.2,10.2-0.8,14.1-1.6c3.9-0.8,6.7-2,7-3.7c0.2-1.7-2.4-3.7-6.9-4.6c-4.4-1-10.5-0.7-16.1,1.4
						c-5.7,2.2-9.2,4.4-12.7,6.7c-3.4,2.3-6.7,4.7-11.2,8.5c5.1-2.9,9-4.2,12.8-5.1C241.5,159.3,245.3,158.8,250.7,158.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M452.7,61.6c-0.5-3.2-1.3-6.4-2.5-9.5c-1.2-3.1-2.9-6.2-5.1-8.9c-2.2-2.7-5-5-8.1-6.7c-3.1-1.7-6.7-2.7-10.3-2.8
						l-1.4,0l-1.4,0.1c-0.9,0.1-1.8,0.3-2.7,0.5c-1.8,0.5-3.4,1.1-4.9,2c-3,1.7-5.6,4.2-7.3,7.3c-1.7,3-2.4,6.8-1.9,10
						c0.2,1.6,0.7,3.2,1.3,4.6c0.6,1.4,1.4,2.7,2.2,3.8c3.4,4.5,8.5,7,13.1,7.2c2.3,0.1,4.6-0.3,6.5-1.3c1-0.5,1.8-1.1,2.5-1.8
						c0.7-0.7,1.2-1.5,1.7-2.3c1.5-3.2,1-6,0-7.5c-0.5-0.8-1-1.2-1.4-1.4c-0.4-0.2-0.7-0.2-0.9,0c-0.3,0.3-0.1,1.1-0.1,2.2
						c0,0.6-0.1,1.2-0.4,1.9c-0.3,0.7-0.7,1.5-1.3,2.1c-0.3,0.3-0.7,0.6-1,0.8c-0.3,0.2-0.8,0.4-1.3,0.5c-1,0.2-2.2,0.2-3.5-0.1
						c-2.6-0.6-5.4-2.4-7.1-5.2c-0.4-0.7-0.8-1.4-1-2.2c-0.2-0.8-0.4-1.5-0.4-2.3c-0.1-0.8,0-1.5,0.2-2.2c0.2-0.7,0.5-1.4,0.9-2.1
						c0.8-1.4,2.2-2.6,3.9-3.5c0.8-0.4,1.7-0.8,2.6-1c0.4-0.1,0.9-0.2,1.4-0.2l0.7,0l0.7,0c1.9,0.1,4,0.6,5.9,1.7
						c1.9,1,3.8,2.5,5.3,4.2c3.1,3.5,5.2,8.4,6.4,13.7c1.2,5.5,1.6,10.9,1.4,15.9c-0.2,5-0.9,9.7-1.9,14.1
						c-2.1,8.8-5.4,16.4-9.6,23.5c-4.2,7.1-9.5,13.8-16.3,20.4c-6.8,6.6-15.2,13.2-26.1,19.6c11.2-6,20-12.1,27.3-18.3
						c7.3-6.2,13.3-12.6,18.3-19.6c5-7,9.2-14.7,12.3-23.9c1.5-4.6,2.7-9.6,3.4-15.1C453.5,74.1,453.6,68.1,452.7,61.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M420.9,70.1c-1.7-0.9-3.7-1.5-5.7-1.6c-2.1-0.2-4.2,0.2-6.2,0.9c-1.9,0.7-3.7,1.8-5.2,3.1c-1.5,1.4-2.7,3-3.5,5
						c-0.8,1.9-1.2,4.1-1.1,6c0.1,2,0.6,3.8,1.4,5.5c1.6,3.3,4.3,5.8,7.4,6.9c1.6,0.6,3.2,0.8,4.6,0.6c1.4-0.1,2.7-0.5,3.8-0.9
						c2.2-1,3.7-2.4,4.6-3.8c0.9-1.4,1.2-2.7,1.1-3.6c-0.1-0.9-0.4-1.4-0.8-1.5c-0.9-0.2-1.9,0.9-3.8,1.6c-0.9,0.4-2,0.7-3.2,0.8
						c-1.2,0-2.4-0.2-3.2-0.9c-0.9-0.6-1.7-1.7-2.1-3c-0.2-0.6-0.3-1.3-0.3-2c0-0.6,0.2-1.2,0.4-1.8c0.5-1.2,1.7-2.4,3.2-3
						c0.7-0.3,1.5-0.5,2.2-0.6c0.8,0,1.7,0.1,2.6,0.3c1.9,0.6,3.8,1.9,5.4,3.4c0.8,0.8,1.5,1.6,2.2,2.4c0.7,0.8,1.3,1.7,1.9,2.6
						c2.3,3.5,3.9,7.1,5,10.9c1.1,3.8,1.7,7.8,1.5,12.4c0,0.6-0.1,1.1-0.1,1.7c0,0.6-0.1,1.2-0.2,1.8c-0.1,1.2-0.4,2.4-0.6,3.7
						c-0.6,2.5-1.4,5.2-2.7,8c1.5-2.7,2.7-5.3,3.5-7.8c0.4-1.3,0.8-2.5,1.1-3.7c0.1-0.6,0.3-1.2,0.4-1.8c0.1-0.6,0.2-1.2,0.3-1.8
						c0.8-4.7,0.9-9,0.5-13.3c-0.5-4.3-1.4-8.6-3.3-13.1c-0.5-1.1-1-2.3-1.7-3.4c-0.6-1.2-1.4-2.4-2.2-3.5
						C426.7,74.4,424.4,72,420.9,70.1z"
                    />
                  </g>
                  <g>
                    <g>
                      <path
                        d="M503.8,68.5c5.7-0.7,11.4-1.1,16.9-1.7c5.5-0.5,10.7-1.1,15.1-2c4.5-0.8,8.2-1.9,10.7-2.9c2.5-1.1,3.9-2.2,3.7-3
							c-0.2-0.9-1.8-1.4-4.4-1.8c-2.6-0.4-6.3-0.5-10.8-0.6c-4.4-0.1-9.6,0-15.3,0.4c-5.6,0.4-11.8,1.3-17.7,2.9
							c-6.2,1.7-11.2,3.7-15.6,6c-4.3,2.3-8,4.8-11.3,7.8c-0.8,0.7-1.6,1.5-2.4,2.3c-0.8,0.8-1.5,1.6-2.3,2.5
							c-1.5,1.7-2.9,3.6-4.2,5.6c-2.6,4.1-4.9,9.2-5.6,15.5c1.2-6.2,4.1-10.9,7-14.5c1.5-1.8,3.1-3.4,4.7-4.7c0.8-0.7,1.7-1.3,2.5-2
							c0.9-0.6,1.7-1.2,2.6-1.7c3.5-2.2,7.2-3.8,11.4-5.1C493.2,70.2,498,69.2,503.8,68.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M512,85.7c5.6-1.4,11.1-3.6,15.9-6.1c4.8-2.5,9.1-5.5,12.4-8.3c6.7-5.7,9.8-10.9,8.6-12.1c-1.2-1.3-6.2,1.4-13.3,5.2
							c-7.1,3.9-16.5,8.8-26.3,13c-5.1,2.2-9.5,3.7-13.6,4.9c-4.1,1.2-8,2.1-11.9,3.3c-3.9,1.1-7.8,2.4-11.9,4.4
							c-2,1-4.2,2.2-6.2,3.8c-2.1,1.5-4.1,3.5-5.8,5.9c1.9-2.2,4.1-3.9,6.3-5.2c2.2-1.3,4.3-2.2,6.4-2.9c4.2-1.4,8.1-1.9,12-2.3
							c3.9-0.4,7.8-0.5,12.3-1C501.2,87.9,506.2,87.1,512,85.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M519.6,76.1c6.3-1,12.2-3.4,16.2-6c4.1-2.6,6.3-5.3,5.6-6.9c-0.7-1.6-4.1-1.8-8.7-0.9c-4.5,0.9-10.2,2.7-15.7,5.4
							c-5.7,2.7-9.6,4.6-13.6,6.3c-4.1,1.7-8.3,3.3-14.6,4.9c6.6-0.6,11-0.9,15.5-1.2C508.7,77.4,513.2,77.1,519.6,76.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M517.7,72.2c4.9-0.5,9.4-2.3,12.5-4.4c3.1-2.1,4.8-4.4,4.1-6c-0.7-1.6-3.4-2.2-7-1.8c-3.6,0.4-8,1.7-12.2,3.8
							c-4.3,2.3-7.3,3.9-10.4,5.4c-3.1,1.5-6.2,2.9-11.1,4.4c5-0.4,8.5-0.6,12-0.7C509.2,72.8,512.7,72.7,517.7,72.2z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M490.4,213.7c-5.6-1.1-11.2-2.4-16.6-3.5c-5.4-1.2-10.5-2.2-15-2.7c-4.5-0.6-8.4-0.7-11.1-0.5
							c-2.7,0.3-4.3,0.9-4.5,1.8c-0.1,0.9,1.2,1.9,3.6,3c2.4,1.1,5.9,2.4,10.1,3.8c4.2,1.4,9.2,2.9,14.7,4.2
							c5.5,1.3,11.6,2.3,17.8,2.6c6.4,0.2,11.8-0.1,16.7-1c4.8-0.9,9.1-2.1,13.1-4c1-0.4,2-1,3-1.4c1-0.5,2-1.1,2.9-1.7
							c1.9-1.2,3.8-2.5,5.7-4.1c3.7-3.1,7.4-7.3,10-13.1c-3.1,5.6-7.2,9.1-11.1,11.7c-2,1.3-3.9,2.3-5.9,3.1c-1,0.4-2,0.8-2.9,1.1
							c-1,0.3-2,0.6-3,0.8c-3.9,1-8,1.4-12.4,1.4C501.1,215.3,496.2,214.8,490.4,213.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M487.9,194.8c-5.8-0.4-11.6,0.1-17,1c-5.4,1-10.3,2.5-14.3,4.2c-8.1,3.4-12.6,7.4-11.8,8.9c0.8,1.6,6.3,0.6,14.3-0.9
							c8-1.5,18.4-3.4,29-4.4c5.5-0.6,10.2-0.7,14.5-0.6c4.3,0.1,8.3,0.4,12.3,0.5c4,0.1,8.2,0.1,12.7-0.6c2.3-0.4,4.6-0.9,7.1-1.7
							c2.4-0.9,4.9-2.1,7.3-3.9c-2.5,1.6-5.1,2.5-7.5,3.1c-2.4,0.6-4.8,0.8-7,0.8c-4.4,0-8.3-0.6-12.1-1.4c-3.8-0.8-7.6-1.9-12-2.8
							C498.8,196,493.9,195.2,487.9,194.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M477.7,201.6c-6.3-0.9-12.6-0.4-17.3,0.8c-4.7,1.2-7.6,3.1-7.4,4.9c0.2,1.8,3.4,2.9,8,3.5c4.6,0.5,10.6,0.5,16.6-0.4
							c6.2-0.9,10.5-1.5,14.9-1.9c4.4-0.4,8.9-0.6,15.4-0.3c-6.4-1.4-10.8-2.5-15.2-3.5C488.5,203.7,484.2,202.6,477.7,201.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M478.3,205.9c-4.8-1-9.7-0.7-13.3,0.4c-3.6,1-5.9,2.8-5.8,4.5c0.1,1.8,2.6,3.1,6.1,3.9c3.5,0.7,8.1,0.8,12.8,0
							c4.8-0.8,8.1-1.5,11.5-2c3.4-0.5,6.8-0.9,11.9-0.8c-4.9-1.1-8.3-2-11.6-3C486.6,207.9,483.3,206.9,478.3,205.9z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M295.7,319.1c4.1-3.9,8.4-7.8,12.5-11.5c4.1-3.7,7.9-7.3,10.9-10.7c3.1-3.3,5.4-6.4,6.8-8.8c1.4-2.4,1.8-4.1,1.2-4.7
							c-0.6-0.6-2.3-0.1-4.6,1.2c-2.3,1.3-5.4,3.4-9,6c-3.6,2.6-7.7,5.8-12,9.5c-4.2,3.7-8.6,8.1-12.4,13c-3.9,5.1-6.7,9.7-8.9,14.2
							c-2.1,4.4-3.5,8.7-4.4,13c-0.2,1.1-0.4,2.2-0.6,3.3c-0.1,1.1-0.3,2.2-0.3,3.3c-0.2,2.3-0.2,4.6,0,7c0.4,4.8,1.6,10.3,4.8,15.8
							c-2.8-5.7-3.3-11.1-3.1-15.8c0.1-2.3,0.4-4.5,0.9-6.6c0.2-1,0.5-2,0.8-3c0.3-1,0.6-2,1-2.9c1.5-3.8,3.5-7.3,6.1-11
							C288.2,326.9,291.4,323.2,295.7,319.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M312.5,328c3.7-4.5,6.7-9.5,9-14.5c2.3-4.9,4-9.8,4.9-14.1c1.9-8.6,1.3-14.5-0.4-14.8c-1.8-0.3-4.2,4.8-7.5,12.2
							c-3.4,7.4-7.9,16.9-13.3,26.2c-2.7,4.8-5.4,8.7-7.9,12.1c-2.6,3.4-5.1,6.5-7.5,9.7c-2.4,3.2-4.8,6.6-6.9,10.7
							c-1,2.1-2,4.3-2.7,6.8c-0.7,2.5-1.2,5.2-1.1,8.2c0.2-3,0.9-5.6,1.9-7.9c1-2.3,2.2-4.3,3.4-6.1c2.5-3.6,5.3-6.4,8.2-9
							c2.9-2.7,5.9-5.1,9.3-8.2C305.2,336.2,308.7,332.7,312.5,328z"
                      />
                    </g>
                    <g>
                      <path
                        d="M312.9,315.8c4.4-4.6,7.7-10,9.4-14.5c1.7-4.5,1.8-8,0.3-8.8c-1.6-0.9-4.4,1.1-7.5,4.5c-3.1,3.4-6.5,8.3-9.3,13.8
							c-2.9,5.6-4.9,9.4-7.1,13.3c-2.2,3.8-4.6,7.6-8.7,12.7c4.9-4.4,8.3-7.4,11.7-10.3C305,323.4,308.3,320.5,312.9,315.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M309,313.8c3.6-3.4,6.2-7.5,7.4-11c1.2-3.5,1.2-6.4-0.3-7.3c-1.5-0.9-4,0.3-6.7,2.7c-2.6,2.5-5.4,6.1-7.4,10.4
							c-2.1,4.4-3.5,7.5-5,10.5c-1.6,3-3.3,6.1-6.2,10.2c3.8-3.4,6.5-5.6,9.2-7.8C302.6,319.3,305.3,317.2,309,313.8z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M112.7,104.3c4.4,3.7,8.6,7.6,12.7,11.2c4.1,3.6,8.1,7,11.7,9.8c3.6,2.7,6.9,4.7,9.5,5.9c2.5,1.1,4.2,1.4,4.7,0.7
							c0.5-0.7-0.2-2.2-1.7-4.4c-1.5-2.2-3.9-5-6.9-8.3c-3-3.3-6.6-7.1-10.7-10.9c-4.2-3.8-8.9-7.7-14.2-11
							c-5.4-3.3-10.4-5.7-15-7.3c-4.6-1.6-9-2.6-13.4-3c-1.1-0.1-2.2-0.1-3.3-0.2c-1.1,0-2.2,0-3.4,0c-2.3,0.1-4.6,0.3-7,0.8
							c-4.8,0.9-10.1,2.7-15.2,6.4c5.4-3.3,10.7-4.4,15.4-4.7c2.3-0.1,4.6,0,6.7,0.2c1.1,0.1,2.1,0.3,3.1,0.5c1,0.2,2,0.4,3,0.7
							c3.9,1.1,7.6,2.7,11.5,4.9C104.2,97.6,108.2,100.5,112.7,104.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M105.7,121.9c4.9,3.2,10.2,5.7,15.3,7.5c5.2,1.8,10.2,2.9,14.5,3.4c8.7,1,14.6-0.3,14.7-2c0.1-1.8-5.2-3.6-12.9-6.2
							c-7.7-2.6-17.7-6.1-27.5-10.4c-5.1-2.2-9.2-4.4-12.9-6.6c-3.7-2.2-7-4.4-10.5-6.5C83,99,79.4,97,75.1,95.4
							c-2.1-0.8-4.5-1.5-7-2c-2.5-0.5-5.3-0.6-8.3-0.2c3-0.1,5.7,0.3,8.1,1c2.4,0.7,4.5,1.7,6.5,2.7c3.9,2.1,6.9,4.6,9.8,7.2
							c2.9,2.6,5.7,5.4,9.1,8.4C96.7,115.5,100.6,118.7,105.7,121.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M117.9,121c5,3.9,10.8,6.6,15.4,7.8c4.7,1.2,8.1,1,8.8-0.6c0.7-1.6-1.5-4.2-5.3-7c-3.7-2.7-9-5.6-14.7-7.8
							c-5.8-2.3-9.9-3.9-13.9-5.7c-4-1.8-8-3.8-13.6-7.4c4.9,4.4,8.2,7.5,11.5,10.5C109.5,113.9,112.7,117,117.9,121z"
                      />
                    </g>
                    <g>
                      <path
                        d="M119.5,117c3.7,3.2,8.1,5.3,11.7,6.2c3.6,0.9,6.5,0.5,7.2-1.1c0.8-1.6-0.7-4-3.4-6.4c-2.7-2.4-6.7-4.7-11.1-6.3
							c-4.6-1.6-7.8-2.7-11-3.9c-3.2-1.2-6.4-2.6-10.8-5.1c3.8,3.4,6.2,5.8,8.7,8.3C113.2,111.2,115.6,113.7,119.5,117z"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M46.9,120.2c-4.8-3.3-10.4-5.5-16.3-6.1c-2.9-0.3-5.9-0.2-8.8,0.3c-2.9,0.5-5.8,1.4-8.4,2.9c-2.6,1.5-5.1,3.5-6.9,6.1
						c-0.5,0.7-0.9,1.3-1.2,2c-0.3,0.7-0.7,1.5-0.9,2.2L4,128.7l-0.2,1.1c-0.2,0.8-0.2,1.5-0.3,2.3c-0.1,3,0.5,5.8,1.6,8.3
						c1.1,2.5,2.6,4.6,4.5,6.3c1.8,1.7,4,3,6.2,3.6c2.1,0.7,4.2,1,6.1,0.9c1.9-0.1,3.6-0.4,5.2-0.9c1.5-0.5,2.8-1.1,3.9-1.8
						c2.2-1.3,3.6-2.9,4.3-4c0.7-1.2,0.8-2,0.5-2.2c-0.3-0.3-1.1-0.1-2.2,0.3c-1.1,0.4-2.6,1.1-4.6,1.5c-1,0.2-2,0.4-3.2,0.5
						c-1.1,0.1-2.4,0-3.6-0.2c-1.2-0.2-2.5-0.6-3.6-1.2c-1.2-0.6-2.1-1.4-3-2.4c-1.8-2.1-2.9-5.2-2.6-8.1c0.1-0.3,0.1-0.7,0.2-1
						l0.1-0.5l0.2-0.5c0.1-0.3,0.3-0.6,0.4-0.9c0.2-0.3,0.4-0.6,0.6-0.9c0.9-1.2,2.2-2.2,3.7-3.1c1.6-0.9,3.4-1.5,5.4-1.8
						c2-0.4,4.1-0.5,6.2-0.3c4.2,0.3,8.6,1.6,12.4,3.9c4,2.3,7.2,5.4,9.7,8.7c2.5,3.3,4.3,6.9,5.7,10.4c2.6,7.1,3.3,14.2,2.9,21.3
						c-0.4,7.1-1.8,14.3-4.7,22c-3,7.6-7.4,15.8-14.9,23.8c7.8-7.7,12.8-15.6,16.5-23.2c3.6-7.6,5.7-14.8,6.9-22.3
						c0.6-3.7,0.9-7.5,0.8-11.4c-0.1-3.9-0.5-8-1.6-12.1c-1.1-4.2-2.8-8.5-5.4-12.7C55.4,127.8,51.9,123.6,46.9,120.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M34,153.7c-3,0.6-5.5,2.1-7.5,4c-2,1.9-3.5,4.3-4.3,7c-0.4,1.3-0.6,2.7-0.6,4.1c0,1.4,0.2,2.8,0.7,4.1
						c0.8,2.5,2.4,4.7,4.4,6.1c1,0.7,2,1.2,3.1,1.5c1.1,0.3,2.1,0.4,3,0.4c1.9-0.1,3.4-0.7,4.4-1.5c1-0.8,1.6-1.6,1.8-2.3
						c0.2-0.7,0-1.2-0.3-1.5c-0.7-0.6-1.9-0.4-3.2-0.7c-0.7-0.1-1.4-0.4-2-0.7c-0.3-0.2-0.6-0.4-0.8-0.6c-0.2-0.2-0.4-0.5-0.6-0.8
						c-0.4-0.6-0.7-1.4-0.8-2.3c-0.1-0.4-0.1-0.9,0-1.3c0-0.5,0.2-1,0.3-1.5c0.6-2.1,2.6-4.5,4.7-5.5c1.1-0.5,2.1-0.7,3.3-0.5
						c1.2,0.2,2.3,0.8,3.3,1.6c1,0.8,1.8,1.7,2.5,2.8c0.7,1.1,1.2,2.3,1.6,3.6c0.8,2.6,1,5.5,0.9,8.8c-0.1,3.3-0.7,7-0.7,11.4
						c0.6-4.4,1.7-7.9,2.4-11.2c0.8-3.3,1.3-6.4,1.2-9.6c-0.1-1.6-0.3-3.3-0.8-4.9c-0.5-1.7-1.3-3.4-2.5-5c-1.2-1.6-2.8-3.2-5.1-4.3
						c-1.1-0.6-2.4-1-3.8-1.2C37.1,153.3,35.5,153.4,34,153.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M80.9,129c2-0.8,3.3-2.1,4-3.7c0.7-1.6,0.9-3.4,0.1-5c-0.8-1.5-2.7-2.3-4.8-1.9c-2.1,0.4-4.4,2-5.6,4.5
						c-1.2,2.6-1.6,4.5-1.9,6.4c-0.3,1.9-0.7,3.6-1.1,6.1c1.5-2,2.9-3.1,4.3-4C77.3,130.6,78.8,129.9,80.9,129z"
                    />
                  </g>
                  <g>
                    <path
                      d="M87.6,140.1c1.9,0,3.2-0.8,4.1-2.1c0.9-1.2,1.4-2.9,1-4.7c-0.4-1.7-1.8-3-3.7-3.3c-1.9-0.3-4.2,0.4-5.7,2.3
						c-1.5,2-2.3,3.5-3,5c-0.7,1.5-1.3,2.9-2.3,4.8c1.8-1.2,3.2-1.6,4.7-1.9C84.1,140.2,85.6,140.1,87.6,140.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M69.7,113.9c0.7-3.1,0.1-6.2-1.2-8.3c-1.3-2.1-3.2-3.1-4.9-2.7c-1.7,0.5-2.8,2.2-3.3,4.3c-0.5,2.1-0.3,4.6,0.7,7.2
						c1.1,2.6,1.8,4.6,2.5,6.6c0.6,2,1,4.1,1,7.1c1.1-2.9,1.9-4.9,2.8-6.9C68.1,119.2,69,117.1,69.7,113.9z"
                    />
                  </g>
                  <g>
                    <path d="M51.6,99.8c-0.9-5.8-6-9.3-9.1-7.5c-3,1.8-2.7,7.7,1.3,11.5c4.1,4,6.3,5.3,9.7,10.1C52.1,108.1,52.6,105.7,51.6,99.8z" />
                  </g>
                  <g>
                    <path
                      d="M25.9,96.2c-2.9-2.2-6.5-3.1-9.2-2.9c-2.8,0.2-4.8,1.4-5.1,3.1c-0.3,1.7,0.9,3.6,3,5c2,1.5,4.9,2.6,8.1,2.9
						c3.3,0.4,5.7,0.6,8.1,1.1c2.4,0.5,4.7,1.1,7.9,2.7c-2.6-2.5-4.3-4.2-6.1-6C30.7,100.2,28.9,98.4,25.9,96.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-1.6,103.7c-2.7-0.3-5.2,0.9-6.5,2.5c-1.4,1.7-1.5,3.6-0.6,5.1c1,1.5,2.6,2.2,4.2,2.4c1.6,0.2,3.2-0.2,4.8-1.5
						c1.6-1.3,3-2.3,4.4-3c1.4-0.8,3-1.3,5.4-1.4c-2.1-0.9-3.7-1.6-5.5-2.4C3,104.7,1.2,104-1.6,103.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-13.1,124.3c-2.5,1.7-3.7,4.6-3.5,6.9c0.2,2.4,1.6,3.9,3.3,4.1c1.8,0.2,3.4-0.6,4.6-1.5c1.3-1,2.1-2.2,2.5-4.1
						c0.4-2,0.9-3.5,1.7-5c0.8-1.4,1.9-2.8,4.1-4c-2.5,0.2-4.3,0.5-6.2,0.9C-8.6,122.1-10.6,122.6-13.1,124.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-9.6,149.9c-0.9,2.6-0.3,5.4,1.1,7.1c1.4,1.7,3.3,2.2,4.9,1.5c1.6-0.7,2.7-2.2,3.2-3.7c0.5-1.5,0.5-3.1-0.4-4.8
						c-0.9-1.8-1.5-3.3-1.9-4.9c-0.3-1.6-0.5-3.2,0.1-5.5c-1.6,1.8-2.7,3.2-3.8,4.6C-7.5,145.6-8.6,147.2-9.6,149.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M3.1,168.8c0.4,2.9,2.4,5.2,4.4,6.2c2.1,1,4.1,0.7,5.2-0.6c1.2-1.3,1.4-3.2,1.1-4.9c-0.3-1.7-1.2-3.3-2.9-4.7
						c-1.7-1.4-3-2.6-4.1-3.9c-1.1-1.3-2-2.9-2.7-5.4c-0.4,2.6-0.7,4.4-1,6.4C2.9,163.8,2.7,165.8,3.1,168.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M75.4,229.7c2.3,1.4,4,3.2,4.8,5.2c0.9,2,0.9,4.3,0.3,5.8c-0.6,1.5-2,2.8-3.6,3.4c-0.8,0.3-1.5,0.4-2.3,0.4
						c-0.8,0-1.6-0.2-2.3-0.6c-1.5-0.6-2.7-1.8-3.4-2.9c-0.7-1.1-0.9-2.1-0.8-2.9c0.1-0.8,0.6-1.6,1.1-2c0.4-0.5,0.9-0.8,1-1.2
						c0.1-0.4-0.3-1-1.5-1.3c-1.1-0.2-3,0.1-4.7,1.8c-0.4,0.4-0.8,1-1.1,1.6c-0.3,0.6-0.5,1.2-0.7,1.9c-0.3,1.4-0.3,2.8,0.1,4.3
						c0.7,2.9,2.5,5.8,5.5,7.9c1.5,1,3.3,1.8,5.3,2.3c2,0.4,4.2,0.4,6.3-0.2c2.1-0.5,4.1-1.5,5.9-3c1.8-1.4,3.3-3.4,4.2-5.7
						c0.9-2.3,1.3-4.7,1.1-7c-0.1-2.3-0.8-4.6-1.8-6.7c-1-2.1-2.5-4-4.2-5.5c-1.7-1.5-3.6-2.6-5.6-3.4c-4.1-1.7-8.1-2-11.5-1.8
						c-3.5,0.2-6.5,0.9-9.3,1.7c-5.5,1.7-10.2,3.7-14.6,6.1c-4.5,2.5-8.8,5.2-13,9c-2.1,1.9-4.3,4-6.3,6.4c-2.1,2.5-4.1,5.2-5.8,8.5
						c2-3.1,4.2-5.7,6.4-7.9c2.2-2.2,4.5-4.1,6.8-5.6c4.5-3.2,9-5.2,13.5-6.9c4.5-1.7,9.2-2.8,14.4-3.6c2.6-0.3,5.2-0.5,7.9-0.3
						c1.3,0.1,2.7,0.3,4,0.7C72.9,228.5,74.2,229,75.4,229.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M55.5,215c4.5-2.5,8.1-6.3,10.1-9.7c2-3.4,2.4-6.3,1-7.5c-1.4-1.1-4.1-0.2-7.1,1.7c-3.1,2-6.5,5-9.6,8.6
						c-3.2,3.8-5.4,6.3-7.9,8.8c-2.5,2.5-5.2,4.8-9.6,7.6c4.8-1.8,8.2-3.1,11.7-4.4C47.5,218.9,50.9,217.5,55.5,215z"
                    />
                  </g>
                  <g>
                    <path
                      d="M75.4,216.2c2.3,0.5,4.3,0.1,5.9-0.8c1.6-1,2.7-2.5,2.7-4.3c0-1.8-1.2-3.3-3.1-4.1c-1.9-0.8-4.5-0.8-6.8,0.5
						c-2.4,1.3-3.8,2.5-5.3,3.6c-1.5,1.1-2.9,2.2-5.1,3.5c2.5-0.3,4.3-0.1,6,0.2C71.3,215.2,73,215.7,75.4,216.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M35.3,208.6c0.9-2.4,1.3-5,1.3-7.4c0-0.6,0-1.2-0.1-1.8c-0.1-0.6-0.1-1.1-0.2-1.7c-0.2-1.2-0.6-2.3-1.1-3.4
						c-0.5-1.1-1.1-2-1.9-2.8c-0.7-0.8-1.6-1.3-2.3-1.6c-1.6-0.5-2.6,0-3,0.7c-0.4,0.8-0.3,1.8-0.5,2.6c-0.1,0.9-0.3,1.8-0.4,3
						c-0.1,0.7-0.1,1.4-0.2,2.1c0,0.4-0.1,0.9-0.1,1.3c0,0.5,0,0.9-0.1,1.4c-0.1,1.9-0.1,3.9,0,6c0.1,4.3-0.2,7.5-0.8,10.6
						c-0.7,3.1-1.8,6.3-4.7,10.3c3.7-3.3,5.9-5.9,8.1-8.7c1.1-1.4,2.1-2.8,3.1-4.5C33.4,213,34.4,211.1,35.3,208.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M124.7,53.8c3.3-0.9,6.7-1.7,9-1.4c1.2,0.1,2,0.4,2.7,0.9c0.6,0.4,1.2,1.1,1.6,2c0.9,1.8,1,4.3,0.5,6.2
						c-0.1,0.5-0.3,1-0.5,1.5c-0.2,0.5-0.5,0.9-0.7,1.4c-0.5,0.8-1.2,1.5-1.8,2.1c-1.2,1-2.9,1.5-4.2,1.4c-0.7,0-1.3-0.2-1.8-0.4
						c-0.5-0.3-1-0.6-1.4-1c-1.7-1.6-1.8-3.6-2.6-3.9c-0.4-0.1-1,0.2-1.5,1.2c-0.5,1-0.8,2.8-0.1,4.9c0.3,1,0.9,2.2,1.9,3.2
						c1,1.1,2.2,2,3.8,2.6c3,1.2,6.9,1.2,10.4-0.7c1.7-0.9,3.3-2.2,4.6-3.7c0.7-0.8,1.2-1.6,1.8-2.5c0.5-0.9,1-1.8,1.4-2.9
						c0.8-2.1,1.1-4.3,1.1-6.5c-0.1-2.3-0.5-4.6-1.5-6.9c-1.1-2.3-2.8-4.5-5.2-6c-2.4-1.5-5.1-2.2-7.6-2.3c-2.5-0.1-4.7,0.3-6.8,0.9
						c-2.1,0.6-3.9,1.2-5.7,2c-3.8,1.6-7.6,3.8-10.3,7c-1.3,1.6-2.3,3.5-2.9,5.3c-0.6,1.8-0.9,3.6-0.9,5.3c-0.1,3.4,0.5,6.4,1.3,9.2
						c0.9,2.8,2,5.4,3.4,7.8c2.8,4.8,6.6,9.1,11.7,12.4c2.6,1.7,5.5,3.1,8.7,4.2c3.3,1.1,6.9,1.7,10.9,1.8
						c-3.9-0.3-7.5-1.3-10.6-2.6c-3.1-1.3-5.7-3-8-4.8c-4.5-3.7-7.5-8.1-9.4-12.7c-1-2.3-1.6-4.7-2-7.2c-0.4-2.5-0.5-5,0-7.5
						c0.2-1.2,0.6-2.4,1.1-3.4c0.5-1,1.2-2,2.1-2.8C118.7,56,121.3,54.8,124.7,53.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M86,72c-3-1.1-6.3-0.6-8.4,0.7c-2.1,1.3-3.1,3.2-2.6,4.9c0.5,1.7,2.2,2.8,4,3.3c1.9,0.6,4,0.6,6.4-0.2
						c2.5-0.8,4.3-1.2,6.3-1.4c1.9-0.2,4-0.2,6.8,0.7c-2.3-1.8-4-3.1-5.7-4.3C90.9,74.4,89.1,73.1,86,72z"
                    />
                  </g>
                  <g>
                    <path
                      d="M99.5,62.1c0.8-1.3,0.8-2.3,0.3-3.6c-0.5-1.2-1.3-2.8-2.7-3.8c-0.7-0.5-1.6-0.7-2.5-0.6c-0.9,0.1-1.8,0.5-2.6,1.3
						c-0.8,0.8-1.4,1.9-1.7,3.2c-0.3,1.3-0.2,2.8,0.4,4.1c1.2,2.8,2.7,4.1,4.1,5.3c1.4,1.1,2.9,2,4.9,2.9c-1.3-1.8-1.6-3.3-1.5-4.7
						C98.2,64.8,98.7,63.5,99.5,62.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M152.6,18.3c3.1-2.4,6-4.9,8.2-6.9c2.2-2,3.6-3.6,3.1-4.4c-0.4-0.8-2.6-0.5-5.5,1c-2.8,1.4-6.3,4-8.9,7.3
						c-2.7,3.4-4.2,5.9-5.6,8.5c-1.4,2.6-2.7,5.2-4.4,9.2c2.2-3.7,4-6,6-8.1C147.4,22.8,149.4,20.8,152.6,18.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M161.5,23.3c3.2-1.8,6.3-3.8,8.5-5.5c2.2-1.7,3.6-3.2,3.2-4c-0.4-0.8-2.5-0.7-5.2,0.3c-2.8,1-6.2,3-9.1,5.6
						c-2.9,2.7-4.7,4.8-6.5,6.9c-1.7,2.1-3.5,4.3-5.9,7.5c2.8-2.9,5-4.6,7.2-6.1C155.9,26.6,158.1,25.2,161.5,23.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M160,33.4c2.1-0.6,3.9-1.6,5.2-2.7c1.3-1,2-2.1,1.7-3c-0.3-0.8-1.6-1.2-3.4-0.9c-1.7,0.3-3.9,1.2-5.7,2.7
						c-1.8,1.6-2.9,2.8-4,4c-1.1,1.2-2.2,2.4-3.9,4.1c2-1.3,3.5-1.9,4.9-2.5C156.3,34.6,157.8,34.1,160,33.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M207.1,151.4c1.6-3.8,3.4-7.5,4.8-10.2c1.4-2.8,2.4-4.8,1.8-5.4c-0.6-0.6-2.8,0.4-5.2,3c-2.4,2.6-4.7,6.8-5.6,11.4
						c-0.8,4.7-0.7,8-0.3,11.2c0.4,3.2,1,6.4,2.6,10.8c-1.1-4.5-1.1-7.7-0.7-10.8C204.8,158.3,205.5,155.3,207.1,151.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M186.8,193.3c-0.7,3.1-1.2,5.4-1.8,8c-0.5,2.2-0.7,3.9,0,4.4c0.7,0.5,2.3-0.5,3.8-2.8c1.4-2.2,2.6-5.8,2.3-9.3
						c-0.2-3.6-1-6-1.8-8.2c-0.9-2.2-1.9-4.4-3.8-7.3c1.5,3.1,1.8,5.5,2,7.7C187.6,188,187.5,190.3,186.8,193.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M166,263.2c-0.7,3.5-2,6.9-3.1,9.4c-1.1,2.5-2,4.4-1.3,5c0.6,0.6,2.7-0.2,4.9-2.8c1.1-1.3,2.1-2.9,2.8-4.9
						c0.7-1.9,1.2-4.2,1.1-6.4c0-2.3-0.4-4.1-0.9-5.8c-0.5-1.6-1-3-1.7-4.3c-1.4-2.6-3-5.2-6.2-8.1c2.8,3.3,3.8,6,4.4,8.7
						c0.3,1.3,0.4,2.7,0.5,4.2C166.5,259.8,166.4,261.4,166,263.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M249.5,317.1c1.2-0.7,2.4-1.2,3.5-1.4c0.6-0.1,1.1-0.1,1.6-0.1c0.5,0,1,0.2,1.5,0.3c2,0.7,4,2.6,5.2,4.8
						c0.3,0.5,0.5,1.1,0.8,1.7c0.2,0.6,0.4,1.2,0.5,1.8c0.2,1.2,0.3,2.3,0.2,3.3c-0.2,1.9-1.3,3.4-2.5,4.1c-0.1,0.1-0.3,0.2-0.4,0.2
						c-0.1,0.1-0.3,0.1-0.4,0.2c-0.3,0.1-0.6,0.1-0.9,0.2c-0.7,0-1.3-0.1-1.8-0.4c-0.5-0.2-0.9-0.6-1.2-0.9
						c-0.3-0.4-0.5-0.9-0.5-1.4c-0.1-1.1,0.1-2,0.4-2.8c0.3-0.7,0.6-1.3,0.4-1.7c-0.1-0.4-0.8-0.7-2-0.3c-1.1,0.4-2.7,1.6-3.5,3.8
						c-0.4,1.1-0.6,2.5-0.3,4.1c0.3,1.6,1.1,3.2,2.4,4.5c1.3,1.3,3,2.2,4.9,2.7c1,0.2,2,0.3,3.1,0.3c0.5,0,1.1-0.1,1.7-0.2
						c0.5-0.1,1.1-0.3,1.6-0.4c4.2-1.5,7.4-5.3,8.5-10c0.5-2.3,0.6-4.6,0.2-6.8c-0.2-1.1-0.4-2.2-0.8-3.3c-0.4-1.1-0.8-2.2-1.4-3.2
						c-1.1-2.1-2.5-4-4.4-5.6c-1.8-1.6-4.1-3-6.7-3.8c-2.6-0.8-5.5-0.8-8.1-0.1c-2.6,0.7-4.8,2-6.7,3.5c-3.8,3.3-5.9,7.3-7.1,11
						c-1.1,3.8-1.4,7.4-1.1,10.7c0.2,3.3,1,6.4,1.9,9.1c1,2.8,2.1,5.4,3.5,7.8c1.3,2.5,2.7,4.9,4.3,7.3c1.5,2.4,3.1,4.8,4.9,7.4
						c3.6,5,7.8,10.5,13.5,16.3c-5.3-6.2-9-11.9-12.1-17.2c-1.5-2.7-2.8-5.2-4-7.8c-1.2-2.5-2.3-5-3.2-7.5c-0.9-2.5-1.7-5-2.2-7.6
						c-0.5-2.6-0.7-5.2-0.6-7.9c0.2-2.7,0.8-5.5,2-8.1c0.6-1.3,1.3-2.5,2.2-3.7C247.3,318.8,248.3,317.8,249.5,317.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M264.6,361c0.7-2.6,1-4.8,0.9-6.9c-0.1-2.1-0.6-4.1-2-5.2c-0.7-0.5-1.7-0.6-2.7-0.3c-1,0.3-2.1,1.1-3.1,2.3
						c-1,1.2-1.7,2.8-2.1,4.7c-0.4,1.9-0.3,3.9,0.3,5.9c0.6,2,1.4,3.6,2.2,4.9c0.8,1.3,1.7,2.3,2.6,3.3c1.8,1.9,3.7,3.6,6.7,5.5
						c-2.3-2.7-3-5-3.3-7.2C263.8,365.9,263.9,363.7,264.6,361z"
                    />
                  </g>
                  <g>
                    <path
                      d="M284.7,370c-3.2,6-5.2,12.1-7.6,17c-1.2,2.5-2.4,4.7-3.7,6.6c-0.7,0.9-1.4,1.8-2.1,2.6c-0.7,0.8-1.4,1.4-2.1,2
						c-1.4,1.1-2.5,1.5-3.5,1.7c-0.3,0.1-0.8,0-1.2-0.1c-0.5-0.2-0.9-0.4-1.2-0.7c-0.7-0.5-1.1-1.1-1.5-1.7
						c-0.3-0.6-0.5-1.1-0.9-1.3c-0.2-0.1-0.4-0.1-0.7,0c-0.3,0.1-0.6,0.4-0.9,0.8c-0.6,0.9-1,2.7-0.3,4.6c0.3,1,0.9,1.9,1.8,2.8
						c0.9,0.9,2.1,1.8,3.7,2.2c1.4,0.4,3,0.5,4.6,0.3c1.6-0.2,3.1-0.7,4.5-1.4c1.4-0.7,2.7-1.6,3.9-2.6c1.2-1,2.3-2.1,3.4-3.3
						c2.1-2.4,3.9-5.2,5.3-8.2c2.9-5.9,4.6-12.1,6.6-17.7c1.1-2.9,2.2-5.3,3.6-7.5c1.4-2.2,2.8-4.2,4.4-5.9c0.7-0.9,1.6-1.7,2.4-2.5
						c0.9-0.7,1.7-1.5,2.5-2.2c0.4-0.3,0.9-0.7,1.3-1c0.4-0.3,0.9-0.6,1.3-0.9c0.9-0.6,1.8-1.2,2.8-1.7c3.8-2.2,8-3.8,13-4.7
						c1.2-0.2,2.5-0.4,3.9-0.4c1.3-0.1,2.7-0.1,4.1,0.1c2.8,0.3,5.9,1.2,8.4,3.6c-2.3-2.6-5.4-3.8-8.3-4.4c-1.5-0.3-2.9-0.5-4.3-0.6
						c-1.4-0.1-2.7,0-4.1,0c-5.2,0.3-9.9,1.3-14.4,3c-1.1,0.4-2.2,0.9-3.3,1.4c-0.5,0.3-1.1,0.5-1.6,0.8c-0.5,0.3-1.1,0.6-1.6,0.9
						c-1.1,0.6-2.1,1.3-3.2,2c-1,0.8-2.1,1.5-3.2,2.3c-2.1,1.7-4.2,3.7-6.2,6C288.2,364.1,286.3,366.9,284.7,370z"
                    />
                  </g>
                  <g>
                    <path
                      d="M311.3,353.5c-2.3,1-4.3,2.4-5.9,4.2c-1.6,1.7-2.9,3.8-3.4,5.9c-0.6,2.1-0.3,4.2,0.4,5.5c0.7,1.3,1.7,1.7,2.5,1.5
						c0.9-0.2,1.6-0.8,2.3-1.4c0.7-0.6,1.5-1.2,2.3-1.9c0.9-0.7,1.8-1.6,3-2.7c1.2-1.1,2.4-2.4,3.6-3.7c2.6-2.8,4.9-4.5,7.5-5.8
						c1.3-0.6,2.7-1.3,4.3-1.7c1.6-0.4,3.5-0.7,5.7-0.7c-2.2-0.6-4.1-0.9-5.9-1.1c-1.8-0.2-3.4-0.3-5-0.3c-1.6,0-3.3,0.1-5.1,0.4
						C315.7,352,313.7,352.5,311.3,353.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M333.5,332.9c0.1-2-0.4-3.5-1.3-5c-0.9-1.4-2.2-2.8-3.9-3.1c-0.9-0.2-1.7,0-2.5,0.5c-0.8,0.5-1.5,1.4-1.9,2.6
						c-0.4,1.2-0.6,2.6-0.4,4.2c0.3,1.5,0.9,3.1,2,4.3c2.2,2.6,4.3,3.7,6.2,4.6c1.9,0.9,3.9,1.5,6.5,2.1c-2.2-1.6-3.2-3.2-3.8-4.8
						C333.7,336.7,333.4,335,333.5,332.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M523.9,354.8c0.8-4,0.7-8.3-0.4-12.2c-1-3.9-2.9-7.6-5.4-10.8c-2.5-3.2-5.7-5.8-9.2-7.6c-3.5-1.8-7.3-3-11-3.4
						c-3.8-0.4-7.6-0.2-11.2,0.8c-3.6,0.9-7.2,2.6-10.1,5.5c-1.4,1.4-2.6,3.2-3.4,5c-0.8,1.8-1.3,3.7-1.4,5.5
						c-0.3,3.7,0.5,7.3,2.3,10.3c1.8,3,4.4,5.2,7.1,6.5c2.7,1.3,5.5,1.8,8.1,1.8c2.6-0.1,5-0.8,7-1.8c2-1,3.5-2.4,4.6-3.7
						c2.3-2.7,3.1-5.3,3.4-7.1c0.2-1.8-0.1-2.8-0.5-2.8c-0.5-0.1-1,0.7-1.9,1.9c-0.9,1.2-2.2,3-4.3,4.5c-1,0.7-2.3,1.4-3.7,1.8
						c-1.4,0.4-2.9,0.6-4.5,0.4c-1.6-0.2-3.2-0.7-4.7-1.7c-1.5-0.9-2.7-2.2-3.4-3.7c-0.7-1.5-1-3.5-0.7-5.3c0.1-0.9,0.4-1.8,0.8-2.6
						c0.4-0.8,0.9-1.4,1.6-2.1c2.8-2.6,8.4-3.9,13.5-3.2c5.3,0.6,10.4,3.1,13.9,7.3c3.5,4.2,5.4,9.9,4.7,15.4
						c-0.8,5.7-4.1,10.7-8.1,14.1c-4.1,3.4-9,5.5-13.8,6.4c-4.9,1-9.8,1-14.5,0.4c-4.7-0.6-9.3-1.9-13.8-3.5
						c-4.5-1.6-8.9-3.6-13.5-5.8c-4.5-2.2-9.2-4.6-14.3-6.8c-5.1-2.2-10.8-4.1-17.1-4.8c-3.1-0.3-6.4-0.3-9.8,0.1
						c-1.7,0.3-3.4,0.7-5,1.2c-1.7,0.6-3.3,1.3-4.9,2.2c1.6-0.8,3.3-1.5,5-2c1.7-0.5,3.4-0.8,5-1c3.3-0.3,6.6-0.2,9.6,0.3
						c6.2,0.9,11.5,3.2,16.4,5.6c4.9,2.4,9.4,5.1,13.8,7.7c4.4,2.5,8.8,4.9,13.3,7c4.6,2,9.3,3.8,14.4,4.8
						c5.1,1.1,10.6,1.5,16.4,0.8c5.7-0.7,11.9-2.6,17.5-6.7c2.7-2,5.3-4.5,7.5-7.6C521.4,362.6,523.1,358.9,523.9,354.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M447.8,308.1c-3.2-3.2-7.6-5.5-12.3-6.1c-4.7-0.7-9.8,0.3-13.9,3.4c-2,1.5-3.5,3.4-4.6,5.6c-1.1,2.1-1.7,4.5-1.7,7
						c0,0.6,0.1,1.2,0.1,1.8c0.1,0.6,0.2,1.2,0.4,1.8c0.3,1.2,0.9,2.2,1.4,3.2c1.2,1.9,2.7,3.4,4.5,4.5c1.8,1.1,3.7,1.7,5.7,1.8
						c1.9,0.1,3.9-0.4,5.4-1.3c1.5-0.9,2.6-2,3.3-3.1c0.8-1.1,1.2-2.3,1.5-3.3c0.5-2.1,0-3.9-0.6-4.9c-0.3-0.5-0.6-0.8-0.9-1
						c-0.3-0.2-0.5-0.2-0.7-0.1c-0.2,0.1-0.4,0.3-0.5,0.6c-0.1,0.3-0.2,0.6-0.4,1c-0.3,0.8-0.6,1.7-1.4,2.6c-0.7,0.9-1.9,1.8-3.1,2
						c-1.1,0.2-2.3-0.1-3.5-1.2c-0.6-0.5-1.1-1.2-1.4-2c-0.1-0.4-0.3-0.7-0.3-1.1c0-0.2,0-0.3,0-0.5c0-0.2,0-0.4,0-0.6
						c0.1-1.6,1.2-3.6,2.9-4.8c1.6-1.2,4-1.9,6.7-1.6c2.7,0.2,5.3,1.3,7.7,3.1c2.4,1.8,4.2,4.3,5.3,6.8c1.1,2.5,1.5,5.1,1.3,7.6
						c-0.3,5-2.7,9.6-6.2,12.9c-3.5,3.3-8.6,5.3-14.4,5.7c-5.9,0.5-12.6-0.9-20.1-3.5c7.3,3.1,13.9,5,20.1,5.2
						c6.2,0.2,12-1.1,16.8-4.5c2.4-1.7,4.5-3.8,6.2-6.3c1.7-2.5,3.1-5.4,3.7-8.8C456.1,323.3,454.5,314.8,447.8,308.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M458.9,328.3c-2.4,2.5-3.5,5.9-3.3,8.5c0.2,2.7,1.6,4.5,3.3,4.7c1.8,0.2,3.4-0.9,4.8-2.5c1.3-1.6,2.3-3.7,2.8-6.3
						c0.5-2.7,1-4.7,1.8-6.7c0.8-1.9,1.8-3.8,4.1-6.1c-2.9,1.3-4.9,2.4-6.9,3.5C463.4,324.6,461.4,325.8,458.9,328.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M463.1,362.7c0.5,3.2,2.7,5.9,5,6.9c2.3,1.1,4.4,0.7,5.5-0.7c1-1.4,1-3.4,0.6-5.1c-0.5-1.8-1.4-3.3-3.1-4.8
						c-1.7-1.6-3-3-3.9-4.5c-1-1.6-1.8-3.4-1.9-6.2c-0.9,2.7-1.4,4.7-1.9,6.8C462.9,357.2,462.6,359.4,463.1,362.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M490.6,369c1.7,0.6,3.5,0.7,5.2,0.5c1.7-0.3,3.1-0.9,4.2-1.7c2.3-1.7,2.9-3.9,2-5.4c-0.9-1.5-2.7-2.2-4.6-2.6
						c-1.9-0.3-3.8-0.2-6.1,0.5c-2.3,0.7-4.3,0.9-6.3,0.8c-2-0.2-4-0.7-6.5-2.5c1.7,2.6,3.2,4.3,4.9,5.9
						C485.2,366,487.1,367.7,490.6,369z"
                    />
                  </g>
                  <g>
                    <path
                      d="M448.5,349.2c-1.6-0.6-2.7-0.5-4,0.1c-1.3,0.6-2.7,1.6-3.5,3.2c-0.4,0.8-0.5,1.7-0.2,2.5c0.2,0.9,0.8,1.7,1.7,2.4
						c0.9,0.7,2.1,1.2,3.5,1.3c1.3,0.1,2.8-0.1,4.1-0.8c2.7-1.5,3.9-3.1,5-4.5c1.1-1.5,1.9-3.1,2.9-5.2c-1.8,1.5-3.4,1.9-4.9,1.9
						C451.6,350.1,450.2,349.9,448.5,349.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M441,389.9c-1.2,1.3-3,2.2-4.8,2.4c-1.7,0.3-3.6,0-5.4-0.8c-1.8-0.7-3.5-2-4.7-3.3c-1.2-1.3-1.9-2.6-2-3.5
						c-0.2-1,0.1-2.1,0.4-2.7c0.3-0.6,0.9-1,1.7-1.1c0.8-0.1,1.5,0,2.1,0.2c0.6,0.2,1.1,0.4,1.5,0.3c0.4-0.1,0.8-0.6,0.6-1.7
						c-0.2-1-1-2.4-2.7-3.4c-0.8-0.5-1.9-0.8-3.2-0.9c-1.3-0.1-2.7,0.3-4,1.1c-2.7,1.6-4.5,4.5-4.8,8c-0.3,3.6,1.1,7,3.1,9.7
						c2,2.7,4.7,4.9,8,6.4c3.3,1.5,7.3,2.2,11.4,1.2c3.9-0.9,7.6-3.3,9.9-6.8c0.6-0.9,1.1-1.9,1.4-2.9c0.4-1,0.6-2,0.7-2.9
						c0.3-1.9,0.2-3.7-0.1-5.3c-0.6-3.2-1.9-5.9-3.3-8.1c-1.5-2.2-3.2-4.1-5-5.6c-1.8-1.5-3.7-2.8-5.7-3.8c-4-2.1-8.4-3.3-13.2-3.8
						c-4.9-0.5-10.3-0.3-16.5,0.5c12.6-0.7,21.4,1.7,27.8,6.5c3.2,2.4,5.8,5.4,7.4,9.2c0.8,1.9,1.3,3.9,1.3,5.9
						C442.8,386.8,442.3,388.7,441,389.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M457.3,391.4c0,2.2,1.1,3.9,2.5,4.7c1.5,0.9,3.3,0.9,4.8,0c1.5-0.9,2.4-2.4,2.6-4c0.2-1.5-0.4-3.1-1.9-4.3
						c-1.5-1.2-2.7-2.1-3.9-3c-1.1-0.9-2.1-2-3.1-3.7c0,2-0.2,3.5-0.4,5C457.7,387.6,457.4,389.2,457.3,391.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M431.7,410.6c-2.8,1.3-4.6,3.9-5.2,6.2c-0.6,2.3,0.1,4.3,1.6,5.2c1.5,0.9,3.5,0.6,5.2-0.3c1.7-0.9,3.3-2.4,4.4-4.7
						c1.1-2.3,2-4,3.1-5.6c1.1-1.6,2.4-3.1,4.8-4.7c-2.7,0.6-4.7,1-6.8,1.5C436.6,408.7,434.5,409.2,431.7,410.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M389.6,226.7c1.7,0.3,3.4,0.1,5-0.1c1.5-0.3,2.9-0.8,4-1.3c2.2-1.1,3.4-2.3,3.2-3.1c-0.3-0.9-2-1.1-4.3-0.9
						c-2.3,0.2-5.1,0.7-7.8,1.1c-2.8,0.4-4.9,0.2-6.8-0.5c-1-0.3-1.9-0.8-2.9-1.6c-0.9-0.7-1.9-1.8-2.6-3.3c0.4,1.6,1.1,2.9,1.9,3.9
						c0.8,1,1.7,1.9,2.6,2.6c0.9,0.7,2,1.4,3.2,1.9C386.4,226,387.9,226.5,389.6,226.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M392.5,238.2c1.9,2.5,3.7,4.8,5.3,6.6c1.6,1.7,2.9,2.9,3.7,2.5c0.8-0.3,0.8-2.2-0.1-4.7c-1-2.4-3-5.4-5.8-7.5
						c-2.9-2.2-5-3.2-7.2-4.2c-2.2-1-4.4-1.9-7.8-2.8c3.1,1.5,5,2.9,6.7,4.4C389.1,234,390.6,235.6,392.5,238.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M351.7,217.7c-0.7,2.4-1.3,4.7-1.7,6.6c-0.4,1.9-0.5,3.4,0.2,3.9c0.7,0.5,2.2-0.2,3.6-2.1c1.4-1.8,2.4-4.8,2.3-7.9
						c-0.2-3.1-0.8-5.1-1.4-7.1c-0.7-1.9-1.5-3.8-3.1-6.3c1,2.7,1.2,4.7,1.2,6.6C352.7,213.3,352.5,215.2,351.7,217.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M446.3,284.3c3.7-0.6,7.2-2.4,9.4-4.3c2.2-1.9,3-3.7,2.5-4.4c-0.6-0.7-2.4-0.1-4.7,0.8c-2.3,1-5.3,2.3-8.3,3.6
						c-3.2,1.4-5.5,2-7.9,2.5c-2.4,0.4-5,0.7-8.7,0.1c3.6,1.1,6.2,1.5,8.8,1.8C439.8,284.8,442.4,284.9,446.3,284.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M455.1,296.1c2.5,0.2,5-0.7,6.5-1.9c1.5-1.2,2-2.6,1.4-3.3c-0.6-0.7-1.8-0.7-3.3-0.4c-1.5,0.2-3.2,0.7-5.1,1.3
						c-1.9,0.6-3.4,0.9-4.9,0.9c-1.5,0-3.1-0.1-5.3-1.1c1.9,1.4,3.3,2.3,4.9,3C450.8,295.2,452.5,295.8,455.1,296.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M512.7,278.9c-0.8,1.7-2.2,3.2-3.7,4.4c-1.5,1.2-3.3,2.3-4.8,3.4c-1.5,1.1-2.8,2.1-3.5,3c-0.8,0.9-1,1.6-0.8,2
						c0.3,0.4,1,0.3,2,0.1c1.1-0.2,2.5-0.7,4.2-1.4c1.7-0.7,3.8-1.6,5.9-3.2c2-1.6,4-3.9,4.8-6.9c0.4-1.5,0.4-3,0.2-4.4
						c-0.3-1.3-0.8-2.5-1.4-3.4c-1.2-1.9-2.8-3.1-4.4-4c-3.3-1.8-7-2.2-12.2-2.6c2.6,0.5,4.7,0.9,6.5,1.6c1.8,0.7,3.3,1.5,4.5,2.6
						c1.2,1.1,2.2,2.4,2.8,3.9C513.5,275.5,513.5,277.2,512.7,278.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M421,118.9c2.1-3.8,2.7-8.3,2.3-11.5c-0.4-3.3-1.6-5.2-2.4-5c-0.9,0.2-1.3,2.2-1.8,4.9c-0.5,2.8-1.2,6.3-2.2,9.9
						c-1.1,3.7-2.3,6.2-3.8,8.5c-1.5,2.3-3.4,4.6-6.9,6.9c3.8-1.9,6.2-3.7,8.4-5.7C416.7,125,418.8,122.8,421,118.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M423,162.8c5.4,0.3,10.9,0.4,15,0.3c4.1-0.1,6.9-0.6,7-1.5c0.1-0.9-2.6-2-6.7-2.7c-4.2-0.7-9.8-1.1-15.4-0.5
						c-5.7,0.6-9.6,1.3-13.5,2.1c-3.9,0.8-7.8,1.6-13.5,3c5.8-0.8,9.8-1,13.7-1.1C413.6,162.4,417.4,162.5,423,162.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M428.4,172.9c6.4,1.8,13.2,1.4,13.5-0.3c0.2-1.7-6.2-4-12.9-4c-6.9,0-9.5,0.3-16.5-0.1
						C419.4,169.9,421.8,171,428.4,172.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M465.2,185.4c3.3-1.4,6.4-3,8.7-4.4c2.3-1.4,3.8-2.7,3.5-3.5c-0.3-0.8-2.3-1-5.1-0.3c-2.8,0.7-6.3,2.2-9.3,4.5
						c-3.1,2.4-5,4.3-6.8,6.1c-1.8,1.9-3.7,3.9-6.2,6.8c2.9-2.6,5.1-4,7.4-5.3C459.5,188,461.8,186.9,465.2,185.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M479.4,189.5c3.5-0.2,7-0.5,9.6-1c2.6-0.4,4.5-1,4.6-1.9c0.1-0.9-1.7-1.8-4.6-2.3c-2.8-0.5-6.7-0.3-10.4,0.8
						c-3.7,1.2-6.2,2.3-8.5,3.5c-2.4,1.2-4.7,2.5-8,4.6c3.5-1.6,6.1-2.3,8.6-2.8C473.3,190.1,475.8,189.7,479.4,189.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M496,177.4c5.3-1.4,9.4-5.2,8.5-6.8c-0.9-1.5-6-0.2-10.3,2.8c-2.2,1.5-3.8,2.6-5.3,3.7c-1.6,1-3.2,2-5.8,3.2
						c2.7-0.7,4.6-1.1,6.5-1.5C491.4,178.5,493.3,178.1,496,177.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M508.6,181.3c2.3,0.5,4.6,0.6,6.3,0.4c1.8-0.2,3.1-0.7,3.3-1.5c0.2-0.9-0.9-1.9-2.7-2.6c-1.8-0.7-4.4-1.1-6.9-0.6
						c-2.6,0.4-4.3,1-6,1.5c-1.8,0.6-3.6,1.2-5.8,2c2.6-0.3,4.3-0.3,6-0.1C504.5,180.5,506.2,180.8,508.6,181.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M515.5,191.4c5.5,1.8,11.5,1.5,11.8-0.3c0.3-1.7-5.2-4-11-4.1c-6-0.1-8.3,0.3-14.4-0.2
						C507.8,188.4,509.8,189.5,515.5,191.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M537.8,184.7c2.8,0.1,5.6-0.6,7.4-1.6c1.9-1,2.9-2.2,2.6-3c-0.3-0.8-1.8-1.1-3.7-1c-2,0.1-4.4,0.5-6.9,1.3
						c-2.5,0.8-4.3,1.3-6.2,1.7c-1.9,0.4-3.8,0.6-6.6,0.6c2.8,0.6,4.7,1,6.6,1.3C533,184.3,535,184.6,537.8,184.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M537.5,176.3c2.8-0.1,5.5-1.1,7.4-2.2c1.8-1.1,2.8-2.4,2.4-3.2c-0.4-0.8-1.9-1-3.8-0.7c-2,0.2-4.4,0.9-6.9,1.9
						c-2.5,1-4.3,1.6-6.1,2.2c-1.8,0.5-3.7,1-6.6,1.2c2.9,0.3,4.8,0.5,6.8,0.7C532.6,176.2,534.6,176.4,537.5,176.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M535.8,166.5c2.7-0.6,5.2-1.8,6.8-3c1.7-1.3,2.5-2.6,2.1-3.4c-0.8-1.6-6.1-0.4-10.6,2.3c-2.3,1.4-4,2.4-5.6,3.4
						c-1.7,0.9-3.4,1.8-6.1,2.9c2.8-0.5,4.7-0.8,6.7-1C531.1,167.3,533,167.1,535.8,166.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M557.3,160.8c1.9-0.2,3.7-0.8,5-1.5c1.3-0.7,2.2-1.5,2.2-2.4c-0.1-0.9-1.2-1.6-2.9-1.7c-1.7-0.2-3.9,0.3-5.8,1.5
						c-1.9,1.2-3.1,2.3-4.2,3.4c-1.1,1.1-2.2,2.1-3.7,3.7c1.8-1.2,3.2-1.7,4.6-2.1C553.9,161.3,555.3,161,557.3,160.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M366.2,478.6c3.4-1,6.8-2.1,9.3-3.2c2.5-1.1,4.2-2.1,4-3c-0.1-0.9-2.1-1.3-5-1.1c-2.9,0.3-6.7,1.3-10,3.2
						c-3.4,2-5.6,3.6-7.7,5.3c-2.1,1.7-4.2,3.4-7,6.1c3.2-2.3,5.6-3.5,8-4.5C360.2,480.5,362.6,479.6,366.2,478.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M377.5,483.6c2.3,0.1,4.5-0.2,6.2-0.7c1.7-0.5,2.9-1.2,2.9-2.1c0-0.9-1.2-1.7-3.1-2.1c-1.9-0.4-4.4-0.3-6.8,0.6
						c-2.4,0.9-4,1.7-5.6,2.5c-1.7,0.9-3.3,1.8-5.3,2.9c2.4-0.7,4.2-1,5.9-1.1C373.5,483.5,375.1,483.5,377.5,483.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M400.9,489.4c2.5,0.8,5,1.3,7,1.3c2,0.1,3.5-0.3,3.7-1.1c0.3-0.8-0.8-2-2.7-2.9c-1.9-0.9-4.6-1.6-7.4-1.6
						c-2.9,0-4.8,0.3-6.8,0.5c-2.1,0.2-4.1,0.5-6.7,0.8c2.8,0.2,4.7,0.6,6.6,1.1C396.6,487.9,398.4,488.5,400.9,489.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M404,480.2c5.8,1.9,12.1,1.7,12.4,0c0.3-1.7-5.5-4.1-11.6-4.3c-6.3-0.2-8.7,0.1-15.2-0.5
						C395.8,477.1,398,478.2,404,480.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M405.2,471.3c1.9,0,3.8-0.8,4.9-1.8c1.1-1,1.6-2.1,1.2-2.9c-0.4-0.8-1.5-1.1-2.8-1c-1.3,0.1-2.9,0.5-4.3,1.4
						c-1.5,0.9-2.6,1.5-3.8,2c-1.2,0.5-2.4,0.9-4.3,1.2c1.7,0.3,3,0.5,4.5,0.7C401.8,471.2,403.2,471.4,405.2,471.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M326.4,598.7c3.6-0.2,7.2-0.8,10.9-1.8c3.6-1,7.3-2.7,10.5-5.2c1.6-1.3,3.1-2.8,4.3-4.4c1.2-1.7,2.2-3.5,3-5.4
						c1.5-3.8,2.1-7.8,1.7-11.8c-0.2-2-0.7-3.9-1.4-5.8c-0.7-1.9-1.7-3.6-2.8-5.1c-2.2-3.1-5.1-5.5-8.2-7.3c-1.6-0.8-3.2-1.5-4.9-2
						c-1.7-0.5-3.4-0.7-5.1-0.8c-3.4-0.1-6.7,0.5-9.7,1.8c-2.9,1.4-5.5,3.4-7.2,6.1c-1.7,2.6-2.4,5.5-2.4,8.1c0,2.7,0.8,5.3,2.2,7.2
						c0.7,1,1.5,1.8,2.4,2.5c0.9,0.6,1.8,1.1,2.7,1.4c3.6,1.3,6.6,0.5,8.1-0.6c1.6-1.1,1.9-2.1,1.6-2.4c-0.3-0.4-1.2-0.1-2.5,0
						c-0.7,0-1.5,0-2.4-0.2c-0.9-0.2-1.9-0.6-2.8-1.2c-0.9-0.6-1.7-1.5-2.1-2.5c-0.5-1.1-0.6-2.4-0.4-3.8c0.2-1.4,0.8-2.9,1.8-4.1
						c0.9-1.1,2.4-2.1,4.1-2.7c1.7-0.6,3.8-0.8,5.8-0.6c1,0.1,2,0.3,3,0.7c1,0.4,2,0.8,2.9,1.4c1.9,1.2,3.6,2.7,4.9,4.6
						c1.3,1.9,2,4,2.3,6.3c0.2,2.3-0.1,4.8-1,7.1c-0.4,1.1-1,2.2-1.7,3.2c-0.7,1-1.5,1.8-2.5,2.7c-3.9,3.3-9.9,5-16.1,5.8
						c-6.3,0.8-12.3,0.4-17.8-0.8c-5.5-1.2-10.3-3.2-14.8-5.6c-9-4.9-16.6-10.8-25.2-15.5c-8.6-4.7-18.1-7.9-29-9.2
						c-5.4-0.6-11.2-0.8-17.4-0.2c-6.1,0.6-12.7,2-19.4,4.5c6.8-2.2,13.3-3.3,19.4-3.6c6.1-0.3,11.8,0.2,17.1,1.1
						c10.5,1.9,19.4,5.7,27.3,10.8c3.9,2.6,7.7,5.5,11.5,8.5c3.9,3,7.9,6.1,12.5,9.1c4.6,3,9.9,5.7,16,7.5
						C312.1,598.2,319,599.2,326.4,598.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M279.9,561.6c4.2-1.3,8.3-3.6,11.6-6.8c1.7-1.6,3.1-3.5,4.3-5.6c1.2-2.1,2-4.4,2.3-7c0.7-5.2-1.5-10.8-6.2-13.8
						c-2.4-1.4-4.9-1.9-7.1-1.8c-2.2,0.1-4.1,0.5-6,1.3c-1.8,0.8-3.5,2-4.9,3.6c-1.3,1.6-2.1,3.7-2.1,5.7c0,3.9,2.4,6.6,4.7,7.6
						c2.3,1,4.2,0.5,5.3-0.1c1.1-0.6,1.3-1.3,1.2-1.6c-0.2-0.4-0.8-0.6-1.5-0.9c-0.7-0.3-1.7-0.8-2.4-1.6c-0.3-0.4-0.5-0.8-0.6-1.4
						c-0.1-0.5-0.1-1.1,0.1-1.5c0.2-0.4,0.5-0.7,1-1.1c0.5-0.3,1.2-0.7,2.1-0.9c0.9-0.2,2-0.3,2.9-0.2c1,0.1,1.7,0.4,2.2,0.8
						c0.5,0.4,0.9,1,1.2,1.8c0.2,0.4,0.3,0.9,0.3,1.3c0,0.4,0,0.9,0,1.5c-0.3,2.1-1.7,4.8-3.7,7c-2,2.2-4.7,4.1-7.8,5.4
						c-3.1,1.4-6.4,2.2-9.5,2.6c-3.2,0.4-6.2,0.5-9.2,0.4c-5.9-0.1-11.4-0.8-17-1.1c-5.6-0.3-11.4-0.3-17.6,0.8
						c-6.2,1.1-13,3.1-20.3,7c7.5-3.4,14.4-4.8,20.5-5.3c6.2-0.4,11.6,0.2,17,1.3c5.4,1,10.7,2.4,16.9,3.4c3.1,0.5,6.5,0.9,10.2,0.9
						C271.4,563.3,275.5,563,279.9,561.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M303.9,501.7c1.1-5.3,2.3-10.4,3.1-14.4c0.8-4.1,1.2-7.3-0.3-8.3c-1.4-1-4.6,0.8-7.4,4.9c-1.4,2.1-2.6,4.7-3.4,7.7
						c-0.8,3-1.1,6.3-0.8,9.6c0.3,3.4,0.9,6.2,1.7,8.6c0.7,2.4,1.6,4.5,2.6,6.5c1,2,2,4,3.3,6.1c1.3,2.1,2.8,4.3,4.9,6.8
						c-1.6-2.8-2.6-5.2-3.3-7.5c-0.7-2.3-1.1-4.3-1.3-6.4c-0.2-2.1-0.2-4.1-0.1-6.3C303,506.9,303.3,504.5,303.9,501.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M320.9,499.7c0.3-3.4-0.1-6.9-1.1-10c-0.9-3.1-2.4-5.8-4.1-7.9c-1.6-2.1-3.4-3.4-4.9-4.1c-1.5-0.7-2.6-0.7-3.3-0.1
						c-1.3,1.3-0.3,4.3,0.8,8.2c0.6,2,1.2,4.2,1.9,6.6c0.6,2.4,1.3,5,1.8,7.7c0.6,2.8,0.8,5.2,0.9,7.5c0.1,2.3,0,4.4-0.3,6.5
						c-0.5,4.2-1.6,8.5-4.5,14.4c3.8-5.3,6.1-9.2,8.1-13.3c1-2.1,1.9-4.2,2.7-6.7C319.9,506,320.6,503.2,320.9,499.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M312.1,497.4c0.7-3.6,0.6-7.3,0-10.1c-0.6-2.7-1.6-4.4-2.5-4.3c-0.9,0.1-1.4,1.9-1.7,4.6c-0.3,2.6-0.4,6-0.2,9.5
						c0.2,3.6,0.2,6.1,0.2,8.6c-0.1,2.5-0.3,5.1-1.2,8.8c1.3-3.6,2.2-6,3-8.5C310.5,503.6,311.3,501.1,312.1,497.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M310,493.7c0.9-3.1,1-6.3,0.6-8.7c-0.4-2.4-1.2-4-2.1-3.9c-0.9,0-1.6,1.6-2.1,3.8c-0.5,2.3-0.8,5.2-0.8,8.3
						c0,3.1,0,5.3-0.2,7.5c-0.2,2.2-0.4,4.4-1.2,7.6c1.2-2.9,2.1-5,3.1-7.2C308.2,499,309,496.9,310,493.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M302.6,488.7c1.5-2.6,2.9-5.1,3.9-7.2c1-2,1.6-3.6,1-4.3c-0.6-0.6-2.4,0-4.3,1.7c-1.9,1.8-3.9,4.8-4.7,8.1
						c-0.8,3.5-0.9,5.9-0.8,8.3c0.1,2.4,0.3,4.7,1,8.1c-0.2-3.4,0.2-5.7,0.8-7.9C300.3,493.4,301.1,491.4,302.6,488.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M305.8,559.5c1.5-1.7,2.7-3.7,3.5-5.7c0.8-2,1.3-4,1.4-5.7c0.3-3.4-0.5-5.7-1.4-5.7c-0.9,0-1.6,2-2.7,4.8
						c-1.1,2.8-2.5,6.3-4.4,9.7c-2,3.5-3.9,5.6-6.1,7.6c-2.2,1.9-4.8,3.6-9.1,4.9c4.4-0.8,7.4-1.9,10.1-3.3c1.4-0.7,2.7-1.5,4.2-2.5
						C302.7,562.6,304.2,561.3,305.8,559.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M341,541.1c4.9-0.6,9.7-1.1,13.4-1.5c3.7-0.4,6.3-0.9,6.4-1.8c0.1-0.8-2.4-2-6.4-2.4c-3.9-0.5-9.3-0.1-14.4,1.4
						c-5.2,1.6-8.5,3.3-11.7,5c-3.2,1.8-6.4,3.7-10.8,6.9c4.6-2.8,8.1-4.1,11.5-5.2C332.6,542.5,336,541.7,341,541.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M361.1,546c3.3,0.2,6.6,0,9.1-0.3c2.5-0.4,4.3-1,4.3-1.9c0.1-0.9-1.6-1.8-4.2-2.3c-2.6-0.5-6.1-0.6-9.6,0.1
						c-3.5,0.7-5.9,1.5-8.2,2.3c-2.5,0.8-4.8,1.6-8.1,2.8c3.6-0.7,6-0.8,8.5-0.9C355.2,545.7,357.6,545.8,361.1,546z"
                    />
                  </g>
                  <g>
                    <path
                      d="M368.5,552.6c2.6,0.8,5.2,1.2,7.2,1.2c2,0,3.6-0.3,3.8-1.2c0.2-0.8-0.9-2-2.8-2.9c-2-0.9-4.8-1.6-7.6-1.5
						c-2.9,0.1-4.9,0.4-6.9,0.7c-2.1,0.3-4.2,0.7-6.9,1.1c2.9,0.1,4.9,0.4,6.8,0.8C364,551.3,365.9,551.8,368.5,552.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M371.3,563.5c1.5,1.5,2.9,2.8,4,3.8c1.2,1,2.2,1.7,3.1,1.5c0.8-0.3,1.2-1.8,0.4-3.8c-0.7-2-2.7-4.1-5.1-5.1
						c-2.5-1.1-4.2-1.3-6-1.6c-1.7-0.3-3.4-0.5-5.9-1c2.3,1,3.8,1.8,5.2,2.7C368.4,561,369.8,562,371.3,563.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M370.1,580.9c0.2,2.4,0.6,4.7,1.1,6.5c0.5,1.8,1.1,3.1,2,3.2c0.9,0.1,1.8-1.2,2.2-3.3c0.4-2.1,0.2-5-1-7.6
						c-1.2-2.6-2.4-4.2-3.6-5.8c-1.2-1.5-2.5-3-4.4-5c1.5,2.3,2.2,4.1,2.7,5.8C369.5,576.6,369.8,578.4,370.1,580.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M364.6,598.8c-0.5,1.7-0.6,3.5-0.3,4.8c0.2,1.4,0.8,2.4,1.7,2.6c0.9,0.2,1.8-0.6,2.5-2c0.6-1.4,1-3.4,0.6-5.3
						c-0.4-2-0.9-3.3-1.3-4.7c-0.5-1.3-0.9-2.6-1.5-4.5c0.1,2-0.1,3.3-0.4,4.7C365.5,595.7,365.1,597,364.6,598.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M353.3,601c-3.3,3.3-5.1,7.7-3.7,8.8c1.4,1.1,5.4-1.9,7.3-6.4c1.9-4.6,2.2-6.5,4.2-11.1
						C358.2,596.4,356.7,597.5,353.3,601z"
                    />
                  </g>
                  <g>
                    <path
                      d="M340.2,602.7c-2.6,1.1-5,2.5-6.7,3.8c-1.7,1.3-2.7,2.6-2.3,3.4c0.4,0.8,2,0.9,4.2,0.3c2.2-0.6,4.8-1.9,7.1-3.8
						c2.3-2,3.8-3.5,5.2-5c1.5-1.5,2.9-3,5.1-5.1c-2.5,1.7-4.4,2.7-6.2,3.6C344.8,600.8,342.9,601.6,340.2,602.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M316.4,606.9c-2.8,0-5.6,0.4-7.6,1c-2.1,0.6-3.5,1.4-3.5,2.2c0,0.9,1.5,1.6,3.7,1.9c2.2,0.3,5.2,0.1,8.1-0.9
						c2.9-1,4.8-1.9,6.7-2.8c2-1,4-1.9,6.6-3.1c-3,0.8-5,1.1-7.1,1.3C321.3,606.8,319.3,606.9,316.4,606.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M233,569.1c5.4,0.9,10.6,2.2,14.6,3.2c4,1,6.8,1.7,7.2,0.8c0.4-0.7-1.8-2.8-5.8-4.8c-4-1.9-9.8-3.5-15.7-3.6
						c-6,0-10.1,0.7-14.1,1.6c-4,0.9-8,2.1-13.5,4.5c5.7-1.9,9.8-2.4,13.7-2.6C223.5,568,227.4,568.2,233,569.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M251.9,584.4c1.8,1.9,3.6,3.7,5.1,5.1c1.5,1.3,2.8,2.2,3.6,1.8c0.8-0.4,0.8-2.1-0.2-4.2c-1-2.1-3.1-4.6-5.8-6.1
						c-2.8-1.5-4.8-2.2-6.9-2.7c-2.1-0.5-4.1-0.9-7.1-1.1c2.9,0.8,4.7,1.8,6.4,2.9C248.6,581.1,250.1,582.4,251.9,584.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M68,396c1.6-0.2,3.2-0.3,4.9-0.5c1.6-0.1,3.3-0.2,4.8-0.2c3.1,0,6.1,0.2,8.8,0.7c2.7,0.5,5.1,1.4,6.9,2.6
						c0.9,0.6,1.7,1.2,2.3,1.9c0.3,0.3,0.6,0.7,0.9,1.1c0.2,0.4,0.4,0.7,0.6,1.1c0.7,1.6,1.1,3.6,0.9,5.5c-0.1,1-0.3,1.9-0.6,2.8
						c-0.3,0.9-0.8,1.8-1.3,2.6c-1.1,1.7-2.5,3.2-4.2,4.4c-1.7,1.2-3.4,2.1-5.2,2.7c-1.8,0.6-3.7,0.8-5.3,0.7
						c-1.7-0.1-3.2-0.4-4.5-1c-2.5-1-4.3-3.3-5.3-5.4c-1-2.1-1.2-4.1-1.3-5.6c0-0.7-0.1-1.4-0.1-1.9c-0.1-0.5-0.2-0.9-0.5-1.1
						c-0.3-0.2-0.7-0.2-1.2,0c-0.5,0.2-1.1,0.7-1.8,1.5c-0.6,0.8-1.3,1.9-1.7,3.4c-0.4,1.4-0.7,3.2-0.5,5.1c0.1,1.9,0.7,4.1,1.7,6.3
						c0.3,0.5,0.6,1.1,0.9,1.6c0.4,0.5,0.7,1.1,1.2,1.6c0.5,0.5,0.9,1.1,1.4,1.5c0.3,0.2,0.5,0.5,0.8,0.7c0.3,0.2,0.6,0.4,0.8,0.7
						c2.3,1.8,5.2,3,8.2,3.7c3.1,0.7,6.4,0.7,9.7,0.2c3.3-0.6,6.8-1.8,9.9-3.6c3.1-1.9,6.1-4.4,8.5-7.6c1.2-1.6,2.3-3.4,3.1-5.4
						c0.8-2,1.4-4.1,1.7-6.3c0.6-4.4,0.1-9-1.8-13.5c-0.5-1.1-1.1-2.2-1.7-3.2c-0.7-1-1.4-2-2.2-2.9c-0.8-0.9-1.7-1.7-2.6-2.5
						c-0.5-0.4-0.9-0.7-1.4-1.1c-0.5-0.4-1-0.7-1.5-1c-4-2.5-8.2-3.7-12.3-4.3c-4.1-0.6-8.1-0.7-12-0.3c-1.9,0.2-3.8,0.4-5.6,0.7
						c-1.9,0.3-3.7,0.6-5.5,1c-7.6,1.7-14.2,4-20.2,6.7c-6,2.7-11.3,5.7-16.1,8.9c-4.8,3.2-9.1,6.5-13,10.1
						c-3.9,3.5-7.6,7.2-10.9,11c-6.7,7.7-12.6,16.1-18,26.2c-5.4,10-10.4,21.7-14.4,36.1c4.8-14.2,10.6-25.4,16.7-34.7
						c6.1-9.4,12.7-16.9,19.9-23.6c3.6-3.3,7.4-6.3,11.4-9.2c4-2.9,8.4-5.5,13.1-7.9c4.7-2.4,9.8-4.6,15.4-6.4
						C55.2,398.3,61.3,396.8,68,396z"
                    />
                  </g>
                  <g>
                    <path
                      d="M53,455.6c0.5,1.9,0.5,3.8,0.3,5.6c-0.3,1.9-0.8,3.7-1.7,5.4c-0.9,1.7-2,3.1-3.3,4.3c-0.6,0.6-1.3,1.1-2,1.6
						c-0.7,0.5-1.5,0.9-2.3,1.2c-3.2,1.4-6.9,1.6-10,0.9c-1.6-0.3-2.9-0.9-3.9-1.6c-1-0.7-1.6-1.3-2.1-2.1c-0.2-0.4-0.4-0.8-0.5-1.1
						c-0.1-0.3-0.2-0.6-0.1-0.9c0-0.6,0.3-1.5,1-2.3c0.7-0.9,1.6-1.7,2.6-2.4c0.5-0.3,1-0.7,1.5-1c0.5-0.3,0.9-0.5,1.3-0.8
						c0.5-0.3,1-0.6,1.4-0.9c0.2-0.2,0.7-0.2,1.1-0.2c0.4,0.1,0.7,0.2,1,0.4c0.3,0.2,0.5,0.5,0.8,0.6c0.3,0.1,0.7,0.1,1.1-0.4
						c0.4-0.4,0.7-1.4,0.4-2.5c-0.2-1.1-0.9-2.3-2.5-3.3c-1.5-0.9-3.3-1.1-5-1c-0.8,0.1-1.6,0.2-2.4,0.3c-0.8,0.1-1.6,0.3-2.5,0.6
						c-1.7,0.5-3.6,1.3-5.5,2.6c-1.9,1.3-4,3.4-5.2,6.4c-0.6,1.5-1,3.2-1.1,4.8c-0.1,1.7,0.1,3.3,0.6,5c0.5,1.6,1.2,3.2,2.1,4.6
						c0.9,1.5,2.1,2.7,3.3,3.8c2.5,2.2,5.4,3.8,8.4,4.8c6.1,2,13.1,1.9,19.6-0.7c3.2-1.3,6.4-3.2,9-5.8c2.6-2.5,4.7-5.6,6.2-8.9
						c1.5-3.3,2.4-6.9,2.5-10.6c0.2-3.7-0.5-7.5-1.8-10.8c-2.7-7-7.7-12-13.2-14.9c-5.6-2.9-11.3-3.4-16.2-3.1
						c-4.9,0.3-9.3,1.4-13.4,2.8c-4.1,1.3-7.9,3-11.5,4.9c-3.7,1.9-7.1,4.1-10.6,6.6c-3.4,2.5-6.7,5.3-9.9,8.6
						c-3.2,3.3-6.3,6.9-9.3,11.1c-2.9,4.2-5.7,9-8,14.5c2.6-5.3,5.7-9.8,9-13.7c3.2-3.9,6.6-7.1,10-9.9c3.4-2.8,6.9-5.1,10.4-7
						c3.5-1.9,7-3.5,10.6-4.8c3.6-1.2,7.3-2.3,11.1-2.9c3.8-0.7,7.7-1.1,11.5-0.8c3.8,0.3,7.6,1.4,10.5,3.5
						C49.6,448.2,52,451.7,53,455.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M121.8,361.1c10.9,1.6,21.5,3.9,29.6,5.9c0.5,0.1,1,0.2,1.5,0.3c0.5,0.1,1,0.2,1.4,0.4c0.9,0.2,1.8,0.4,2.6,0.6
						c0.8,0.2,1.6,0.3,2.4,0.5c0.7,0.2,1.4,0.3,2,0.4c2.5,0.4,4.2,0.3,4.6-0.5c0.4-0.8-0.5-2.1-2.5-3.9c-0.5-0.4-1.1-0.9-1.7-1.4
						c-0.6-0.5-1.4-0.9-2.1-1.4c-0.8-0.5-1.6-1-2.5-1.5c-0.5-0.2-0.9-0.5-1.4-0.8c-0.5-0.2-1-0.5-1.5-0.7
						c-8.1-3.8-19.9-6.9-31.8-6.7c-3.1,0.1-5.9,0.1-8.5,0.4c-1.3,0.1-2.6,0.2-3.8,0.4c-1.2,0.2-2.4,0.4-3.5,0.5
						c-1.1,0.2-2.3,0.3-3.3,0.5c-1.1,0.2-2.1,0.4-3.2,0.7c-1,0.2-2.1,0.4-3.1,0.7c-1,0.3-2,0.5-3,0.8c-1,0.3-2,0.5-3,0.8
						c-1,0.3-2,0.6-3,1c-1,0.3-2,0.6-3.1,1c-1,0.4-2.1,0.8-3.2,1.2c-1.1,0.4-2.2,0.8-3.3,1.3c-1.1,0.5-2.3,1-3.5,1.5
						c-0.6,0.3-1.2,0.5-1.8,0.8c-0.6,0.3-1.2,0.6-1.9,0.9c-1.3,0.6-2.6,1.2-4,1.9c1.4-0.5,2.8-1,4.1-1.5c0.7-0.2,1.3-0.5,1.9-0.7
						c0.6-0.2,1.3-0.4,1.9-0.6c1.2-0.4,2.4-0.7,3.6-1c1.2-0.3,2.3-0.5,3.4-0.8c1.1-0.2,2.2-0.5,3.3-0.7c1.1-0.2,2.1-0.3,3.2-0.5
						c1-0.1,2-0.3,3-0.4c1-0.1,2-0.2,3-0.3c1-0.1,2-0.2,3-0.2c1-0.1,2-0.1,3-0.1c1,0,2.1,0,3.1-0.1c1.1,0,2.1,0.1,3.2,0.1
						c1.1,0,2.2,0.1,3.4,0.1c1.2,0.1,2.3,0.2,3.6,0.3C116.4,360.4,119,360.8,121.8,361.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M120.9,378.3c10.8,0.9,21.8-0.5,29.7-2.7c7.9-2.2,12.8-5.1,12.3-6.8c-0.4-1.8-5.9-1.9-13.7-1.5
						c-7.8,0.4-18.1,1.3-28.4,2.2c-10.6,0.9-18,1.1-25.5,0.9c-7.5-0.3-15.1-1-26.1-3.4c10.7,3.5,18.2,5.4,25.6,7.2
						C102.3,375.9,109.8,377.4,120.9,378.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M132.4,371.1c6.3,1.3,12.7,1.4,17.5,0.7c4.8-0.7,8-2.3,8-4c0-1.8-3.1-3.3-7.8-4.4c-4.7-1-10.9-1.5-17.2-1
						c-6.5,0.5-10.9,0.9-15.5,1.2c-4.5,0.3-9.1,0.4-15.8,0c6.6,1.4,11,2.6,15.4,3.8C121.5,368.5,125.9,369.7,132.4,371.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M166.3,420.2c1,0,2.1,0,3.1-0.2c1-0.2,2.1-0.4,3.1-0.7c2-0.7,4-1.7,5.8-3.3c1.8-1.5,3.3-3.7,4-6.1
						c0.7-2.4,0.7-4.8,0.3-6.9c-0.4-2.1-1.2-4-2.1-5.7c-0.9-1.7-2-3.1-3.3-4.5c-1.3-1.3-2.8-2.4-4.5-3.3c-1.6-0.8-3.6-1.3-5.3-1.4
						c-3.5-0.2-6.9,1.1-9,3.3c-1.1,1.1-1.8,2.4-2.2,3.6c-0.4,1.2-0.5,2.3-0.4,3.3c0.2,2,1.1,3.4,1.9,4c0.8,0.7,1.5,0.7,1.8,0.4
						c0.3-0.3,0.4-0.9,0.5-1.6c0.1-0.7,0.2-1.6,0.7-2.4c0.4-0.9,1.2-1.8,2.1-2.1c0.9-0.4,2.3-0.6,3.6-0.2c1.3,0.4,2.6,1.3,3.9,2.8
						c1.2,1.5,2.3,3.6,2.6,5.5c0.2,0.9,0.1,1.7-0.1,2.3c-0.2,0.6-0.5,1.2-1.1,1.8c-0.6,0.6-1.5,1.2-2.5,1.7
						c-0.5,0.2-1.1,0.4-1.6,0.6c-0.6,0.2-1.1,0.3-1.8,0.4c-2.6,0.3-5.2,0-7.6-0.7c-2.4-0.7-4.7-1.7-6.8-2.9c-4.1-2.4-7.6-5.4-11-8.5
						c-3.5-3.1-6.9-6.5-11.2-9.7c-2.2-1.6-4.5-3.1-7.3-4.5c-2.7-1.3-5.9-2.4-9.3-2.6c3.5,0.5,6.4,1.9,8.9,3.4
						c2.5,1.5,4.7,3.3,6.5,5.1c3.8,3.6,6.6,7.3,9.5,11c3,3.7,6,7.4,10.3,11c2.2,1.8,4.6,3.4,7.6,4.8
						C158.6,419,162.2,420.1,166.3,420.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M130.6,425.7c-0.7,1.3-1.4,2.2-2.3,3c-0.9,0.7-1.7,1.2-2.5,1.4c-0.6,0.2-1.4,0.2-2.1,0c-0.7-0.2-1.2-0.4-1.5-0.7
						c-0.3-0.1-0.6-0.7-0.7-1.3c-0.1-0.6-0.1-1.3,0-1.9c0.1-0.6,0.3-1.1,0.4-1.6c0.2-0.5,0.3-1,0.2-1.4c-0.1-0.4-0.4-0.8-1.1-1
						c-0.7-0.2-1.8-0.1-2.9,0.5c-1.2,0.6-2.4,1.7-3.2,3.4c-0.8,1.6-1.3,4-0.4,6.6c0.8,2.4,2.7,4.6,5.1,5.9c2.4,1.3,5.3,1.8,8.3,1.2
						c2.9-0.6,5.4-2.2,7.2-4.2c1.8-2,3.1-4.6,3.7-7c0.6-2.5,0.6-4.8,0.4-6.8c-0.2-2-0.6-3.8-1-5.4c-0.9-3.3-2.2-6.1-3.7-8.6
						c-1.5-2.6-3.3-4.9-5.6-7.3c-1.1-1.2-2.4-2.3-3.8-3.5c-1.4-1.1-3.1-2.2-4.9-3.2c1.7,1.2,3.1,2.6,4.3,3.8c1.2,1.3,2.2,2.6,3,3.9
						c1.7,2.6,2.7,5.1,3.5,7.7c0.7,2.6,1.1,5.2,1.1,8C132.2,419.9,131.9,423.1,130.6,425.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M612.6,399.5c-0.7-3.6-1.9-7.1-3.5-10.4c-1.6-3.3-3.6-6.4-5.8-9.1c-2.2-2.7-4.6-4.8-6.8-6.8c-2.2-1.9-4.2-3.6-5.9-5.2
						c-3.5-3.1-6-6-7.6-8.2c-1.7-2.2-2.6-3.7-3.5-3.5c-0.8,0.1-1.5,2-0.9,5.4c0.3,1.6,0.9,3.6,1.9,5.6c1,2,2.4,4.2,4,6.3
						c3.3,4.3,7.7,8.3,11.3,12.7c3.5,4.3,6.4,9.6,8.4,15.4c1,3,1.7,5.9,2.1,8.6c0.4,2.7,0.7,5.4,0.7,7.9c0,5-0.5,9.6-1.6,14.1
						c-1.1,4.5-2.6,9-4.9,13.8c-2.3,4.8-5.4,9.9-9.8,15.6c4.8-5.3,8.4-10.1,11.3-14.7c2.9-4.6,5.2-9,7-13.5
						c1.8-4.6,3.3-9.3,4.1-14.8c0.4-2.8,0.6-5.7,0.6-8.9C613.7,406.6,613.4,403.2,612.6,399.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M580.2,404.8c-0.8-5.7-1-11.4-0.9-16.8c0.1-5.4,0.4-10.6,0.8-15c0.7-8.9,0.9-15.2-0.8-15.7c-1.7-0.4-4.9,5.1-7.5,14.3
						c-1.2,4.6-2.2,10.1-2.4,16.2c-0.2,6,0.4,12.6,2.2,18.9c1.9,6.5,4.3,11.6,7.1,15.9c1.4,2.2,2.8,4.1,4.3,5.9
						c1.4,1.8,2.9,3.4,4.2,5.1c2.6,3.3,5,6.9,5.9,11.6c0.2,1.2,0.4,2.4,0.4,3.7c0,1.3,0,2.6-0.2,4c-0.4,2.7-1.3,5.6-3,8.4
						c1.9-2.7,3.1-5.5,3.8-8.2c0.4-1.4,0.6-2.7,0.7-4.1c0.1-1.3,0.1-2.6,0.1-3.9c-0.1-2.5-0.7-5-1.5-7.2c-0.8-2.2-1.8-4.3-2.9-6.2
						c-2.2-3.9-4.5-7.5-6.2-11.7C582.4,415.5,581,410.7,580.2,404.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M593.6,391.6c-1-7.1-3.4-13.9-5.9-18.7c-2.5-4.8-4.9-7.5-5.7-7.1c-0.8,0.4,0.2,3.8,1.8,8.7c1.6,4.9,3.7,11.4,5.5,17.9
						c1.8,6.8,2.7,11.6,3.3,16.5c0.5,4.9,0.8,9.9,0.1,17.3c1.2-7.3,1.6-12.4,1.8-17.4C594.6,403.9,594.6,398.9,593.6,391.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M588.3,385.7c-0.4-5.3-1.8-10.5-3.4-14.1c-1.6-3.7-3.4-5.8-4.2-5.5c-0.8,0.3-0.6,3,0.2,6.7c0.7,3.8,1.9,8.6,3.1,13.5
						c1.2,5.1,1.9,8.6,2.3,12.2c0.4,3.6,0.7,7.4,0.3,12.8c0.9-5.4,1.3-9.2,1.6-12.9C588.5,394.8,588.7,391.1,588.3,385.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M583.8,385.6c0.2-8.5-1.7-16.9-3.5-16.8c-1.8,0.1-2.4,8.7-0.9,17.1c1.6,8.6,2.6,11.8,3.8,20.8
						C583,397.7,583.5,394.4,583.8,385.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M38.3,418.2c3.6-0.5,7.1-1.2,9.7-2c2.6-0.8,4.4-1.7,4.3-2.5c-0.1-0.9-2-1.5-4.9-1.6c-2.9-0.1-6.7,0.5-10.3,1.9
						c-3.7,1.5-6,2.8-8.4,4.1c-2.3,1.3-4.7,2.7-8,4.8c3.6-1.6,6.2-2.4,8.7-3C32.1,419.3,34.6,418.8,38.3,418.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M37.7,425.6c3.2-0.1,6.2-0.5,8.6-1.1c2.3-0.5,4-1.2,4-2.1c0-0.9-1.6-1.7-4.2-2.1c-2.6-0.4-6-0.1-9.3,0.9
						c-3.3,1.1-5.5,2.1-7.6,3.2c-2.1,1.1-4.2,2.2-7.3,3.9c3.3-1.2,5.6-1.8,7.9-2.1C32.2,425.9,34.5,425.7,37.7,425.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M59.5,432.6c2.4,0.8,4.7,1.4,6.6,1.6c1.9,0.2,3.4,0.1,3.8-0.7c0.4-0.8-0.5-2.1-2.3-3.2c-1.8-1.1-4.5-2-7.3-2
						c-2.9,0-4.8,0.3-6.8,0.7c-1.9,0.4-3.8,0.8-6.5,1.5c2.8-0.2,4.7,0,6.5,0.4C55.3,431.3,57.1,431.8,59.5,432.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M80.6,446.8c4,0.5,8.1-0.5,10.7-1.9c2.7-1.4,4-3.1,3.6-3.8c-0.4-0.8-2.4-0.5-5-0.1c-2.6,0.4-5.9,1-9.4,1.5
						c-3.6,0.4-6.1,0.3-8.7,0c-2.5-0.4-5.2-1.1-8.6-3.1c3.1,2.4,5.6,3.7,8.1,4.9C73.9,445.3,76.5,446.2,80.6,446.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M84.1,455.5c2.2,0,4.3-0.8,5.6-1.8c1.3-1,1.9-2.2,1.5-3c-0.4-0.8-1.6-1-3.1-0.9c-1.5,0.1-3.2,0.6-4.9,1.4
						c-1.8,0.8-3.1,1.4-4.4,1.8c-1.4,0.4-2.8,0.8-4.9,0.8c2.1,0.5,3.5,0.8,5,1.1C80.4,455.3,81.9,455.5,84.1,455.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-6.8,468.2c-3.8,3.9-6.3,9-7.3,13c-1,4-0.7,6.8,0.2,7c0.9,0.2,2.1-2.2,3.8-5.5c1.7-3.3,4-7.6,6.8-11.7
						c2.9-4.2,5.4-6.9,8.1-9.3c2.8-2.3,5.8-4.6,11-6.6c-5.3,1.5-8.8,3.2-12.1,5C0.3,462-2.9,464.1-6.8,468.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-0.6,480.6c-1,1.9-1.8,3.9-2.4,5.8c-0.3,1-0.5,1.9-0.7,2.8c-0.2,0.9-0.3,1.9-0.3,2.8c0,0.9,0.1,1.8,0.4,2.6
						c0.3,0.8,0.7,1.5,1.2,1.9c0.5,0.4,1,0.6,1.4,0.6c0.4,0,0.6-0.1,0.7-0.3c0.2-0.4-0.1-1,0-1.6c0-0.6,0.1-1.5,0.5-2.7
						c0.2-0.6,0.4-1.2,0.7-2.1c0.3-0.8,0.6-1.7,0.8-2.5c0.6-1.8,1.1-3.7,1.7-5.6c1.1-4,2.1-6.7,3.2-9.3c1.2-2.7,2.5-5.3,5-8.9
						c-2.9,3.2-4.8,5.6-6.7,7.9C3.3,474.3,1.5,476.7-0.6,480.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M7.5,492.9c-0.1,2.4,0.6,4.8,1.6,6.4c1,1.6,2.2,2.3,3,2c0.8-0.4,1.1-1.7,1-3.3c-0.1-1.6-0.5-3.6-1.2-5.6
						c-0.7-2.1-1.2-3.6-1.5-5.1c-0.3-1.6-0.5-3.2-0.3-5.6c-0.7,2.3-1.2,3.9-1.6,5.5C8.1,488.7,7.7,490.4,7.5,492.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M19.5,498.4c0.8,2,2.3,3.6,3.7,4.4c1.4,0.8,2.7,0.9,3.3,0.2c0.6-0.7,0.3-1.9-0.3-3.1c-0.6-1.3-1.7-2.7-3-3.9
						c-1.4-1.3-2.4-2.3-3.2-3.3c-0.9-1.1-1.7-2.2-2.5-4.2c0.3,2.1,0.5,3.5,0.7,4.9C18.5,494.9,18.7,496.4,19.5,498.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M36.3,500.3c1.6,0.8,3.4,0.8,4.7,0.4c1.3-0.4,2-1.3,2-2.2c-0.1-0.9-0.9-1.6-1.9-2c-1.1-0.5-2.4-0.7-3.8-0.5
						c-1.5,0.2-2.6,0.2-3.7,0.2c-1.1,0-2.3-0.2-3.8-0.8c1.1,0.9,2.1,1.7,3.2,2.6C33.7,498.7,34.7,499.5,36.3,500.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M173.3,453.4c1.6-3.6,2.3-7.6,2.2-11.5c-0.1-3.9-1-7.8-2.9-11.2c-1-1.7-2.2-3.2-3.6-4.5c-1.5-1.3-3.2-2.3-5.1-2.8
						c-3.8-1.1-7.5-0.3-10.2,1.3c-2.8,1.6-4.7,4.3-5.4,6.7c-0.8,2.5-0.6,4.7-0.2,6.4c0.5,1.7,1.2,2.9,1.8,3.6
						c0.6,0.7,1.1,0.9,1.2,0.8c0.4-0.3-0.5-1.9-0.7-4.8c0-1.4,0.2-3.2,1-4.9c0.8-1.7,2.2-3.3,4.2-4.2c2-1,4.6-1.3,6.8-0.5
						c2.3,0.8,4.4,2.7,5.8,5.3c1.4,2.6,2.2,5.7,2.4,8.9c0.2,3.2-0.3,6.6-1.4,9.7c-1.2,3.2-3,6-4.8,8.5c-1.9,2.5-3.8,4.8-5.6,7.1
						c-0.9,1.2-1.7,2.3-2.5,3.5c-0.4,0.6-0.7,1.2-1.1,1.8l-0.5,0.9l-0.5,0.9c-0.3,0.6-0.6,1.2-0.9,1.8c-0.3,0.6-0.5,1.2-0.8,1.8
						c-0.6,1.2-0.9,2.5-1.4,3.7c-0.8,2.5-1.4,5.1-1.8,7.8c-0.2,1.3-0.3,2.7-0.4,4.1c0,0.7,0,1.4,0,2.1c0,0.7,0,1.4,0,2.2
						c0.1,2.9,0.5,6,1.3,9.3c0.8,3.2,2,6.6,3.8,10c-1.7-3.5-2.7-6.9-3.4-10.1c-0.6-3.2-0.9-6.3-0.8-9.2c0-0.7,0-1.4,0.1-2.1
						c0.1-0.7,0.1-1.4,0.2-2.1c0.2-1.4,0.3-2.7,0.6-4c0.5-2.6,1.3-5,2.2-7.4c1.9-4.7,4.5-9,8.3-13.1c1.9-2.1,4-4.2,6.1-6.7
						C169.4,460,171.7,457.1,173.3,453.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M154.7,445.2c-2.1,1.9-3.9,4-5.1,5.8c-1.2,1.8-1.8,3.3-1.2,4c0.6,0.7,2.2,0.3,4.2-0.9c1.9-1.2,4.1-3.4,5.6-6.1
						c0.8-1.4,1.3-2.5,1.8-3.6c0.5-1.1,0.9-2.1,1.2-3.2c0.3-1.1,0.5-2.2,0.4-3.5c-0.1-1.2-0.5-2.6-1.7-3.7c0.9,1.3,1,2.6,0.8,3.7
						c-0.2,1.1-0.7,2-1.2,2.7c-0.6,0.8-1.2,1.5-2,2.3C156.6,443.3,155.7,444.2,154.7,445.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M157.3,453.9c-1.7,1.7-3.1,3.6-4,5.2c-0.9,1.6-1.2,3-0.6,3.7c0.6,0.6,2.1,0.3,3.7-0.9c1.6-1.1,3.4-3.1,4.4-5.5
						c1.1-2.4,1.7-4.2,2.2-6c0.6-1.9,1.1-3.7,1.8-6c-1.2,2.3-2.3,3.8-3.5,5.1C160.3,450.9,159.1,452.2,157.3,453.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M187.3,434.6c0.8-2.6,0.7-5.4,0.1-7.4c-0.6-2-1.7-3.1-2.5-2.9c-0.9,0.2-1.4,1.5-1.6,3.3c-0.3,1.8-0.4,4-0.3,6.3
						c0.1,2.4,0,4.2-0.2,5.9c-0.3,1.7-0.7,3.5-1.9,5.9c1.6-2.1,2.7-3.7,3.6-5.2C185.5,438.9,186.5,437.3,187.3,434.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M196,440.1c1.4-0.9,2.5-2.1,3.2-3.2c0.7-1.1,1-2.2,0.6-3c-0.5-0.7-1.7-0.9-3.1-0.4c-1.4,0.5-3,1.8-4,3.6
						c-1,1.8-1.4,3.2-1.8,4.5c-0.4,1.3-0.7,2.6-1.2,4.5c1-1.7,1.9-2.6,2.8-3.5C193.5,441.8,194.5,441.1,196,440.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M204.1,446c1.7-0.2,3.1-0.8,4.2-1.5c1.1-0.7,1.8-1.6,1.7-2.5c-0.1-0.9-1.2-1.5-2.7-1.6c-1.5-0.1-3.4,0.4-5,1.6
						c-1.6,1.2-2.5,2.2-3.5,3.2c-1,1.1-1.9,2.2-3,3.4c1.6-1,2.8-1.5,4-1.8C201.1,446.4,202.3,446.2,204.1,446z"
                    />
                  </g>
                  <g>
                    <path
                      d="M255.9,442.9c-2.8,2-5.5,3.9-7.6,5.6c-2,1.6-3.4,3-3,3.8c0.3,0.8,2.4,0.8,5.1-0.2c2.7-1,6-3.1,8.4-6
						c2.5-3,3.9-5.3,5.2-7.6c1.2-2.3,2.4-4.7,3.8-8.3c-1.9,3.4-3.5,5.4-5.3,7.2C260.7,439.2,258.8,440.8,255.9,442.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M258,452.6c-2.4,3.6-3.9,7.7-4.5,10.9c-0.5,3.2-0.2,5.5,0.6,5.6c0.9,0.2,2-1.7,3.4-4.4c1.3-2.7,2.9-6.4,4.3-10.1
						c1.5-3.8,2.8-6.4,4.3-9c1.5-2.5,3.1-5,6.1-8.3c-3.4,2.9-5.5,5-7.6,7.1C262.6,446.7,260.5,448.9,258,452.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M264.1,459.8c-1.9,3.8-3,8-3.2,11.2c-0.3,3.2,0.2,5.4,1,5.5c0.9,0.1,1.9-1.8,3-4.6c1.1-2.9,2.3-6.7,3.3-10.6
						c1-4,1.9-6.8,2.9-9.6c1-2.8,2.2-5.5,4.4-9.3c-2.7,3.5-4.4,6-6.1,8.5C267.7,453.4,266.1,455.9,264.1,459.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M266.4,475.3c-1.1,3.9-1.3,8-0.8,11c0.4,3,1.4,5,2.2,4.9c0.9-0.1,1.5-2,2-4.9c0.5-2.8,0.8-6.6,1-10.3
						c0.2-3.9,0.5-6.6,0.9-9.3c0.5-2.7,1.1-5.5,2.6-9.4c-2,3.6-3.2,6.2-4.4,8.8C268.6,468.7,267.5,471.3,266.4,475.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M332.1,491.8c-2.3,0.4-4.4,1.4-5.7,2.6c-1.3,1.2-1.9,2.4-1.4,3.2c0.5,0.8,1.8,0.9,3.4,0.5c1.6-0.3,3.5-1.1,5.3-2.2
						c1.9-1.2,3.2-2,4.6-2.7c1.4-0.7,2.9-1.4,5.1-2c-2.1,0-3.8,0.1-5.6,0.1C336.1,491.3,334.5,491.4,332.1,491.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M338.2,497.8c-2,0.5-2.5,2.8-1.3,4.1c1.2,1.3,3.4,1.1,4.2-0.8c0.8-1.9,0.9-3,2-4.7
						C341.2,497.3,340.2,497.3,338.2,497.8z"
                    />
                  </g>
                  <g>
                    <path d="M432.9,506.6c-3.6,0.1-6.5,2.6-5.9,4.2c0.7,1.6,4.1,1.7,7,0c3-1.8,4.1-2.8,7.6-4C438.1,507,436.6,506.6,432.9,506.6z" />
                  </g>
                  <g>
                    <path
                      d="M152,526.9c-2.4-3.5-5.6-6.8-9.9-9.1c-2.1-1.1-4.4-2-6.8-2.5c-2.4-0.5-5-0.6-7.6-0.1c-5.2,0.9-9.7,3.7-12.6,7.9
						c-0.7,1.1-1.3,2.2-1.8,3.4c-0.5,1.2-0.8,2.5-0.9,3.7c-0.3,2.4,0,4.7,0.7,6.8c0.7,2.1,1.8,4,3.3,5.5c1.5,1.5,3.3,2.6,5,3.2
						c3.6,1.1,6.6,0.4,8.6-0.7c2.1-1.1,3.2-2.7,3.5-3.9c0.2-0.6,0.2-1.1,0.1-1.5c-0.1-0.4-0.3-0.7-0.6-0.8c-0.3-0.2-0.6-0.3-1-0.3
						c-0.4,0-0.8,0-1.2-0.1c-0.8,0-1.8-0.1-2.7-0.4c-0.9-0.3-2-0.9-2.4-1.5c-0.2-0.3-0.3-0.7-0.4-1.1c-0.1-0.4-0.1-0.8-0.1-1.4
						c0-0.5,0.2-1,0.4-1.4c0.1-0.2,0.2-0.4,0.3-0.5c0.1-0.2,0.3-0.4,0.5-0.5c0.4-0.4,0.9-0.7,1.5-1c0.6-0.3,1.3-0.5,2-0.6
						c1.4-0.2,3.4,0.1,5.5,0.9c2,0.8,4.2,2.4,6.2,4.4c1.1,1,2.1,2.2,3,3.4c0.9,1.2,1.8,2.3,2.6,3.5c1.5,2.4,2.8,4.7,3.8,7.1
						c2,4.7,3.3,9.3,3.7,14.1c0.5,4.8,0.2,9.7-1.1,15.2c-1.3,5.4-3.8,11.3-8.4,17.4c5.2-5.5,8.6-11.1,10.9-16.5
						c2.3-5.4,3.7-10.6,4.3-15.9c0.6-5.3,0.5-10.8-0.6-16.8c-0.6-3-1.4-6.1-2.7-9.4c-0.7-1.6-1.4-3.3-2.3-5
						C154.2,530.4,153.2,528.7,152,526.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M180.5,567.9c3.9-1.9,7.3-4.1,9.8-6.3c2.5-2.1,4.1-4.2,3.6-5.9c-0.5-1.6-3.3-2.4-7.1-1.7c-3.7,0.7-8.4,3.2-11.7,7
						c-3.4,3.9-5.2,7-6.8,10c-1.6,3.1-3.1,6.2-4.9,10.7c2.8-4.1,5.3-6.3,7.8-8.3C173.8,571.6,176.5,569.9,180.5,567.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M174.5,542.4c0.4-3.8-1-7.4-2.8-9.6c-1.9-2.2-4.1-3-5.6-2.2c-1.6,0.9-2.2,2.9-2.2,5.3c0,2.4,0.6,5.1,1.9,7.8
						c1.3,2.9,2.2,5,2.8,7.3c0.6,2.3,1,4.7,0.6,8.2c1.4-3.3,2.3-5.6,3.2-8C173.2,548.8,174.1,546.3,174.5,542.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M59.8,573.1c-3.7,3.4-7.3,6.6-11.1,9.2c-3.7,2.5-7.5,4.5-10.6,4.9c-3.2,0.5-5.8-0.6-7.3-1.7c-1.6-1.1-2.1-2.3-2.6-2.2
						c-0.2,0.1-0.3,0.4-0.3,1.1c0.1,0.7,0.4,1.7,1.1,2.7c0.7,1.1,1.9,2.2,3.5,3c1.6,0.8,3.7,1.3,5.9,1.3c2.2-0.1,4.5-0.6,6.7-1.5
						c2.2-0.9,4.3-2.1,6.4-3.4c4.2-2.8,8-6.4,11.4-10.2c3.5-3.9,6.3-7.3,8.9-10.2c2.6-2.9,5.1-5.4,7.9-7.7c2.7-2.2,5.7-4.2,9.4-5.8
						c3.7-1.5,8.2-2.7,13.6-2c-5.3-1-10-0.1-13.9,1.2c-3.9,1.3-7.2,3.1-10.3,5.1c-3.1,2-5.9,4.3-8.9,7
						C66.7,566.4,63.5,569.6,59.8,573.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M73.8,568.7c-1.9,3-3.2,6.4-4,9.7c-0.8,3.3-0.9,6.4-0.8,9.1c0.4,5.4,2.1,8.6,2.9,8.4c0.9-0.2,0.8-3.6,1.3-8.4
						c0.3-2.4,0.7-5.1,1.4-8c0.7-2.9,1.7-5.9,3-8.8c1.4-3,2.8-5.4,4.4-7.4c1.6-2,3.2-3.7,4.9-5.3c1.8-1.5,3.7-3,6-4.3
						c2.3-1.3,5.1-2.5,8.6-3.4c-3.5,0.6-6.4,1.6-9,2.6c-2.5,1.1-4.7,2.2-6.8,3.5c-2.1,1.3-4,2.8-6.1,4.7
						C77.8,563.3,75.8,565.6,73.8,568.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M82.3,565.7c-1.2,2.3-2,4.8-2.4,7.2c-0.4,2.4-0.3,4.7,0,6.6c0.6,3.9,2.2,6.1,3,5.9c0.9-0.2,0.9-2.7,1.2-6
						c0.1-1.7,0.4-3.6,0.7-5.6c0.4-2,0.9-4.2,1.5-6.3c0.7-2.2,1.5-4,2.4-5.5c0.9-1.6,1.8-2.9,2.9-4.2c1.1-1.3,2.3-2.5,3.8-3.7
						c1.5-1.2,3.3-2.3,5.7-3.3c-2.4,0.8-4.4,1.7-6.1,2.6c-1.7,0.9-3.2,1.9-4.6,3c-1.4,1.1-2.7,2.3-4.1,3.8
						C84.9,561.6,83.5,563.4,82.3,565.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M126.8,555.4c3.6,0.7,7.5,0,9.9-1.3c2.5-1.3,3.7-2.8,3.3-3.6c-0.4-0.8-2.2-0.7-4.6-0.4c-2.4,0.3-5.3,0.6-8.5,0.9
						c-3.2,0.2-5.5,0-7.8-0.4c-2.3-0.5-4.6-1.3-7.6-3.4c2.6,2.5,4.7,3.9,6.9,5.1C120.8,553.5,123.1,554.6,126.8,555.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M138.6,563.1c1.7,1.4,3.4,2.4,4.9,3.1c1.5,0.7,2.8,0.9,3.4,0.3c0.6-0.6,0.4-2-0.8-3.5c-1.1-1.5-3.1-3.1-5.5-3.8
						c-2.4-0.7-4.2-0.9-5.9-1c-1.7-0.1-3.4-0.2-5.8-0.2c2.4,0.5,3.9,1.2,5.3,2C135.6,560.8,136.9,561.7,138.6,563.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M141,578.8c0.3,2.1,1,4.1,1.8,5.5c0.8,1.5,1.7,2.4,2.6,2.3c0.9-0.1,1.5-1.4,1.5-3.2c0.1-1.8-0.5-4.2-1.8-6.2
						c-1.3-2.1-2.4-3.4-3.5-4.6c-1.1-1.2-2.2-2.5-3.8-4.2c1.2,2.1,1.7,3.6,2.2,5.2C140.4,575.1,140.7,576.6,141,578.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M143.4,505.7c0.1-2,0.1-3.7-0.1-5.2c-0.2-1.5-0.5-2.7-1.3-3c-0.8-0.3-2,0.5-2.8,2.3c-0.8,1.7-1,4.4-0.2,6.7
						c0.9,2.4,1.9,3.8,3,5.1c1.1,1.3,2.2,2.5,4,4c-1.5-1.8-2-3.4-2.3-4.8C143.4,509.2,143.3,507.7,143.4,505.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M158.8,498.6c0.9-1.8,1.7-3.4,2.3-4.9c0.6-1.4,0.9-2.7,0.4-3.4c-0.5-0.7-2.1-0.5-3.6,0.8c-1.6,1.3-3.1,3.7-3.3,6.4
						c-0.2,2.8,0.3,4.6,0.9,6.3c0.6,1.7,1.4,3.3,2.8,5.3c-1-2.3-1.1-4-0.9-5.5C157.5,501.9,157.9,500.4,158.8,498.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M187.9,576c2.7-0.4,5.2-1.1,7.1-1.9c1.9-0.8,3.2-1.7,3.1-2.6c-0.1-0.9-1.6-1.5-3.8-1.5c-2.2,0-5.1,0.5-7.7,1.8
						c-2.7,1.3-4.4,2.5-6.1,3.6c-1.8,1.2-3.5,2.4-5.8,4c2.7-1.2,4.6-1.8,6.5-2.3C183.2,576.8,185.2,576.4,187.9,576z"
                    />
                  </g>
                  <g>
                    <path
                      d="M149,392.3c3,0.1,5.8-1,7.6-2.1c1.8-1.1,2.7-2.4,2.4-3.2c-0.3-0.8-1.9-1-3.9-0.7c-2,0.3-4.4,1-6.7,1.7
						c-2.4,0.8-4.2,1-6,0.9c-1.8-0.1-3.8-0.5-5.9-2.5c1.7,2.3,3.6,3.4,5.4,4.3C143.8,391.6,145.9,392.2,149,392.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M134,321c-1.8,2.8-3.2,6-3.8,9.2c-0.6,3.2-0.5,6.3,0.2,8.9c0.7,2.6,1.8,4.5,2.9,5.6c0.5,0.6,1,0.9,1.4,1.1
						c0.4,0.2,0.7,0.2,0.8,0.1c0.7-0.6-0.7-3.3-1-7.6c-0.1-2.1,0-4.6,0.5-7.2c0.5-2.6,1.5-5.3,2.8-8c1.3-2.8,2.7-5.1,4-7.1
						c1.3-2.1,2.6-3.9,3.9-5.7c1.3-1.9,2.6-3.7,4-5.8c1.4-2.1,2.9-4.5,4.3-7.6c-1.6,2.9-3.4,5.1-5,7.1c-1.6,1.9-3.2,3.5-4.8,5.2
						c-1.6,1.6-3.1,3.2-4.8,5.1C137.7,315.9,135.8,318.1,134,321z"
                    />
                  </g>
                  <g>
                    <path
                      d="M143.2,322.4c-0.7,5.5,0.4,11.2,2,15.1c1.6,3.9,3.6,6,4.4,5.6c0.8-0.4,0.3-3.1-0.4-6.8c-0.7-3.8-1.4-8.7-1.7-13.7
						c-0.2-5.2,0.2-8.9,1-12.5c0.8-3.6,2-7.3,5-12.1c-3.4,4.5-5.2,8-6.8,11.5C145.2,313,143.9,316.7,143.2,322.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M149.7,316.6c0,4,1.3,7.8,2.8,10.4c1.5,2.6,3.2,3.9,3.9,3.4c0.8-0.4,0.5-2.4-0.1-5c-0.6-2.6-1.5-6-2.3-9.4
						c-0.9-3.5-1.2-6.1-1.4-8.7c-0.1-2.6,0-5.3,1-9.2c-1.5,3.7-2.3,6.3-2.9,9C150.2,309.8,149.7,312.5,149.7,316.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M93.8,341.5c3.2-2.6,5.7-6,7.1-8.8c1.4-2.8,1.7-5,0.9-5.4c-0.8-0.4-2.4,0.9-4.4,3c-2,2.1-4.4,5-6.8,8.1
						c-2.4,3.1-4.3,5.2-6.3,7.2c-2,2-4.2,3.8-7.8,6.1c3.8-1.8,6.4-3.2,8.9-4.6C88,345.7,90.5,344.2,93.8,341.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M102.3,347.6c2.7-0.3,5.3-1,7.2-1.7c1.9-0.8,3.2-1.7,3.1-2.5c-0.1-0.9-1.6-1.5-3.8-1.6c-2.2-0.1-5.1,0.4-7.7,1.7
						c-2.7,1.3-4.4,2.4-6.2,3.5c-1.8,1.2-3.6,2.3-5.9,3.8c2.7-1.2,4.7-1.7,6.6-2.1C97.6,348.2,99.5,347.9,102.3,347.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M119.4,246.7c1.9-1.6,3.5-3.5,4.5-5.2c1-1.6,1.4-3,0.8-3.7c-0.6-0.6-2.1-0.3-3.8,0.8c-1.7,1.1-3.6,3-4.9,5.4
						c-1.3,2.4-2,4.2-2.8,5.9c-0.8,1.9-1.5,3.7-2.5,6c1.5-2.3,2.8-3.7,4.1-5C116.1,249.6,117.4,248.4,119.4,246.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M20.7,384.4c-7.8,3.2-14.9,7.9-19.7,12.1c-4.8,4.2-7.2,7.7-6.7,8.3c0.6,0.7,4.1-1.6,9.2-5c5.1-3.3,11.9-7.6,19-11.4
						c7.3-3.9,12.7-6.1,18.2-8c5.5-1.8,11.3-3.3,20-4.3c-8.8,0.4-14.7,1.3-20.5,2.4C34.5,379.7,28.7,381.1,20.7,384.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M27.1,386.9c-6.2,3.1-11.7,7.5-15.3,11.3c-3.6,3.8-5.4,6.9-4.8,7.4c0.7,0.6,3.5-1.3,7.5-4.3c4-3,9.3-6.9,14.8-10.6
						c5.7-3.8,9.8-6.1,14.1-8.2c4.3-2,8.9-3.9,15.8-5.7c-7.1,1.2-11.9,2.5-16.5,3.9C38.1,382.1,33.5,383.7,27.1,386.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-19.8,372.5c-3.2-2.4-6.3-4.7-8.9-6.3c-2.5-1.6-4.5-2.5-5.1-1.9c-0.6,0.6,0.3,2.7,2.4,5.1c2.1,2.4,5.5,5.1,9.3,6.9
						c4,1.8,6.8,2.7,9.6,3.6c2.9,0.8,5.7,1.6,10,2.5c-4.1-1.4-6.8-2.8-9.3-4.2C-14.2,376.7-16.6,375-19.8,372.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-14.5,384.4c-0.9,0-1.8,0.2-2.7,0.6c-0.9,0.3-1.9,1-2.5,2c-0.6,1-0.6,2.1-0.3,2.7c0.3,0.7,0.9,0.8,1.3,0.7
						c0.4-0.1,0.8-0.4,1.2-0.5c0.4-0.1,0.7-0.1,1-0.1c0.2,0.1,0.6,0,1.2-0.2c0.7-0.2,1.4-0.5,2.1-1c1.4-0.9,2.5-1.5,3.6-2
						c1.1-0.5,2.3-0.9,4.1-1.2c-1.6-0.2-2.9-0.5-4.4-0.7C-11.3,384.6-12.6,384.4-14.5,384.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-13.6,395.2c-2.1,1.6-3.6,3.7-4.4,5.6c-0.7,1.8-0.7,3.3,0,3.8c0.8,0.5,2-0.2,3.4-1.4c1.4-1.2,2.9-3,4.1-5
						c1.3-2.1,2.3-3.5,3.4-4.9c1.1-1.4,2.3-2.7,4.3-4.4c-2.3,1.2-4,2-5.6,2.9C-9.8,392.7-11.4,393.6-13.6,395.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-9.5,421.5c-1.3,2.9-0.3,6,1.4,6.3c1.7,0.2,3.5-2.6,2.9-5.7c-0.5-3.2-1.1-4.4-1.2-7.7C-7.4,417.5-8.2,418.6-9.5,421.5
						z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-2.1,415.6c-1.2,2.5-0.2,5.3,1.5,5.5c1.7,0.2,3.4-2.2,2.8-4.9c-0.6-2.8-1.2-3.9-1.4-6.7C0,412.2-0.9,413.1-2.1,415.6z
						"
                    />
                  </g>
                  <g>
                    <path
                      d="M578.1,305.9c3.3-4.2,6.4-8.7,8.9-13.6c2.5-4.9,4.2-10.3,4.9-15.7c0.7-5.4,0.4-10.9-1.2-15.8c-0.4-1.2-0.8-2.4-1.3-3.6
						c-0.5-1.2-1.1-2.3-1.8-3.4c-1.4-2.2-3.1-4.2-5.3-5.7c-1.1-0.8-2.3-1.3-3.6-1.8c-1.3-0.4-2.7-0.6-4-0.5
						c-2.5,0.1-4.9,0.9-6.8,2.5c-0.9,0.8-1.7,1.7-2.3,2.7c-0.6,1-0.8,2.1-1,3.1c-0.2,2,0.2,3.7,0.7,5c1.1,2.5,2.5,3.9,3.5,4.8
						c1,0.9,1.7,1.1,2.1,0.9c0.4-0.2,0.3-1,0.1-2.2c-0.2-1.2-0.8-2.9-1-4.7c-0.1-0.9,0-1.8,0.3-2.5c0.2-0.3,0.4-0.7,0.6-0.9
						c0.2-0.2,0.5-0.4,0.9-0.6c0.7-0.3,1.7-0.5,2.7-0.3c0.5,0.1,1,0.2,1.4,0.4c0.4,0.2,0.9,0.6,1.4,1c0.9,0.8,1.8,2,2.5,3.4
						c0.7,1.4,1.3,3.2,1.8,5c0.9,3.6,1,7.7,0.4,12c-0.6,4.2-1.9,8.6-3.8,12.8c-1.9,4.3-4.4,8.5-7.2,12.6c-2.9,4.3-5.8,8.2-8.5,11.9
						c-2.7,3.7-5.2,7.3-7.5,10.8c-2.2,3.5-4.2,7-5.8,10.6c-1.6,3.6-2.8,7.2-3.7,10.9c-1.7,7.4-2.1,15.1-0.8,23.5
						c0.6,4.2,1.7,8.5,3.4,13c1.7,4.4,4,9,7.3,13.5c-3-4.6-5.1-9.3-6.5-13.7c-1.4-4.5-2.1-8.8-2.4-12.9c-0.6-8.2,0.5-15.5,2.8-22.3
						c1.2-3.4,2.6-6.6,4.4-9.7c1.8-3.1,4-6.2,6.4-9.3c2.4-3.1,5.2-6.3,8.2-9.7C571.4,313.8,574.7,310.2,578.1,305.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M561.9,353.7c1-1.7,2-3.3,3.1-4.8c1.1-1.5,2.2-2.8,3.4-4.1c1.1-1.3,2.4-2.4,3.5-3.4c0.3-0.2,0.6-0.5,0.9-0.7
						c0.3-0.2,0.5-0.4,0.8-0.6c0.5-0.4,1.1-0.7,1.6-1c2.2-1.2,4-1.8,5.4-2c1.5-0.3,2.7-0.1,3.3-0.7c0.3-0.3,0.4-0.8,0.2-1.5
						c-0.2-0.7-0.8-1.5-1.9-2.3c-1-0.8-2.5-1.4-4.3-1.6c-1.7-0.2-3.7-0.1-5.6,0.3c-1,0.2-2,0.6-3,1c-0.5,0.2-1,0.5-1.5,0.7
						c-0.5,0.3-0.9,0.5-1.4,0.8c-1.8,1.1-3.7,2.5-5.3,4.2c-1.6,1.7-3.1,3.6-4.4,5.6c-1.2,2.1-2.2,4.3-3,6.5
						c-1.6,4.7-2.1,8.7-2.3,12.3c-0.2,3.6,0,6.8,0.3,9.9c0.7,6.3,1.9,12.4,3.8,21.4c-0.9-9.1-0.8-15.4,0-21.4c0.4-3,1-5.9,1.9-9
						C558.6,360.4,559.9,357.1,561.9,353.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M574.5,279.9c-0.6-1.9-1.5-3.7-2.6-5.3c-0.6-0.8-1.2-1.6-1.9-2.3c-0.7-0.7-1.5-1.3-2.3-1.9c-1.7-1.1-3.9-1.8-6.2-1.5
						c-1.1,0.2-2.1,0.5-3,1.1c-0.9,0.6-1.6,1.4-2.1,2.4c-0.4,0.9-0.6,1.9-0.6,2.8c0,0.9,0.2,1.6,0.5,2.3c0.5,1.3,1.2,2.1,1.8,2.7
						c1.2,1.2,2.1,1.6,2.4,1.4c0.4-0.3,0.1-1.2-0.4-2.6c-0.2-0.7-0.5-1.5-0.6-2.4c-0.1-0.9,0.1-1.5,0.5-2c0.4-0.4,1.1-0.8,2-0.9
						c0.8-0.1,1.9,0.3,2.9,1c1.1,0.7,2.1,1.8,3.1,2.9c0.9,1.2,1.7,2.5,2.4,3.9c1.3,3,1.8,5.9,1.9,8.5c0.1,2.6-0.2,5.1-0.8,7.5
						c-1.1,4.8-3.2,9.6-6.8,16.2c4.1-6.4,6.7-10.8,8.6-15.6c0.9-2.4,1.7-4.9,2-7.9C575.6,287.2,575.6,283.8,574.5,279.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M568.9,290c-0.3-0.5-0.5-1.1-0.8-1.6c-0.3-0.5-0.7-1.1-1.2-1.5c-0.9-1-1.9-1.8-3.4-2.3c-0.7-0.3-1.5-0.4-2.3-0.4
						c-0.8,0-1.6,0.3-2.3,0.6c-1.4,0.7-2.2,1.8-2.6,2.8c-0.5,1-0.6,2-0.6,2.7c0,0.8,0.1,1.4,0.3,1.9c0.3,1,0.7,1.5,1.2,1.5
						c0.4,0,0.9-0.5,1.3-1.4c0.4-0.8,0.9-2,1.5-2.7c0.3-0.4,0.7-0.6,1-0.7c0.3,0,0.5,0,0.9,0.1c0.4,0.2,1,0.5,1.6,1.1
						c0.3,0.3,0.6,0.5,0.8,0.9c0.3,0.3,0.5,0.7,0.8,1.1c1.1,1.5,1.8,3,2.3,4.5c0.5,1.4,0.8,2.8,0.9,4.2c0.1,1.4,0.1,2.9-0.3,4.5
						c-0.3,1.6-0.9,3.4-2.1,5.3c1.4-1.7,2.3-3.4,3-5c0.7-1.6,1.1-3.1,1.3-4.7c0.2-1.6,0.4-3.1,0.2-4.9
						C570.3,294.2,569.9,292.2,568.9,290z"
                    />
                  </g>
                  <g>
                    <path
                      d="M548.8,301.3c-1.9-0.3-3.8-0.3-5.2,0c-1.5,0.3-2.6,0.9-2.7,1.8c-0.2,0.9,0.7,1.8,2.3,2.4c1.6,0.6,3.8,0.8,5.9,0.2
						c2.2-0.6,3.6-1.2,5-1.9c1.5-0.7,2.9-1.4,4.7-2.2c-2.1,0.4-3.6,0.5-5.1,0.4C552.1,301.9,550.7,301.7,548.8,301.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M536.3,308.9c-5.8,0.2-11.1,2.8-10.6,4.5c0.4,1.7,6.2,1.7,11.5-0.2c5.5-1.9,7.5-3,13.4-4.3
						C544.5,309.2,542.3,308.8,536.3,308.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M525.6,316.8c-3.9-0.7-7.6,0.8-7.6,2.5c0.1,1.8,4,2.9,7.8,1.8c3.9-1.1,5.3-1.9,9.3-2.6
						C531,318.2,529.6,317.5,525.6,316.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M537.6,319c-2.2,1.1-3.9,2.9-4.8,4.6c-0.9,1.6-1.1,3.1-0.4,3.6c0.7,0.6,2,0.2,3.4-0.7c1.5-0.9,3.1-2.3,4.5-4
						c1.5-1.7,2.6-2.9,3.8-4.1c1.2-1.1,2.5-2.2,4.6-3.4c-2.4,0.7-4,1.3-5.6,1.8C541.5,317.3,539.9,317.8,537.6,319z"
                    />
                  </g>
                  <g>
                    <path
                      d="M545.3,322.1c-1.5,1.4-2.5,3.1-2.9,4.6c-0.4,1.5-0.2,2.7,0.6,3.2c0.8,0.4,1.9,0,3-1c1.1-1,2.2-2.5,2.9-4.3
						c1.5-3.7,1.8-5.3,4.1-8.8C549.9,318.6,548.4,319.3,545.3,322.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M554.7,308c-1.3,0.9-2.1,2.2-2.4,3.4c-0.3,1.2,0,2.2,0.7,2.7c0.7,0.5,1.8,0.3,2.7-0.3c1-0.6,1.9-1.7,2.4-3
						c1.1-2.8,1.3-4.1,3.2-6.7C558.7,305.8,557.4,306.2,554.7,308z"
                    />
                  </g>
                  <g>
                    <path
                      d="M532.3,341c-1.2,1.5-1.8,3.3-1.8,4.8c0,1.5,0.5,2.5,1.4,2.7c0.9,0.2,1.8-0.4,2.6-1.5c0.8-1,1.5-2.5,1.8-4.2
						c0.3-1.7,0.5-3,0.9-4.2c0.3-1.2,0.8-2.4,1.7-4.1c-1.4,1.3-2.3,2.2-3.3,3.1C534.5,338.6,533.5,339.5,532.3,341z"
                    />
                  </g>
                  <g>
                    <path
                      d="M533.2,356.5c-2.1,3.9-1.8,8.5-0.1,8.9c1.7,0.4,3.9-3.4,4.3-7.6c0.4-4.4,0.2-6.1,1.4-10.5
						C536.7,351.3,535.4,352.5,533.2,356.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M533.9,379.3c-0.4,2.4,0.2,4.9,1,6.6c0.8,1.7,2,2.6,2.8,2.3c0.8-0.3,1.2-1.6,1.3-3.2c0.1-1.7-0.2-3.7-0.7-5.8
						c-0.5-2.2-0.8-3.7-1-5.4c-0.2-1.6-0.2-3.3,0.2-5.7c-0.9,2.3-1.5,3.8-2.1,5.5C534.8,375.2,534.2,376.8,533.9,379.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M570.2,359.3c1.9-2.2,3.7-4.2,5-6c1.3-1.7,2.2-3.2,1.7-3.9c-0.5-0.7-2.3-0.5-4.4,0.8c-2.2,1.3-4.6,3.7-6,6.8
						c-1.4,3.1-1.9,5.4-2.3,7.6c-0.4,2.2-0.6,4.5-0.6,7.8c0.5-3.2,1.4-5.3,2.4-7.2C567.1,363.2,568.3,361.5,570.2,359.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M580.7,319.9c1.7-0.7,3-1.7,4-2.7c0.9-1,1.4-2.1,1.1-2.9c-0.4-0.8-1.6-1.1-3.1-0.8c-1.5,0.3-3.3,1.3-4.6,2.8
						c-1.3,1.6-2.1,2.8-2.8,4c-0.8,1.3-1.5,2.6-2.4,4.1c1.4-1.4,2.6-2.1,3.8-2.7C577.8,321.1,579,320.6,580.7,319.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-6.5,258.1c1.9-0.4,3.5-1.1,4.7-2c1.2-0.9,1.9-1.9,1.6-2.7c-0.2-0.8-1.4-1.3-3-1.2c-1.6,0.1-3.5,0.7-5.2,2
						c-1.7,1.3-2.7,2.3-3.8,3.3c-1,1-2.1,2-3.7,3.4c1.9-0.9,3.2-1.4,4.6-1.7C-9.8,258.7-8.5,258.5-6.5,258.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M8.4,246.2c-1.5,2.6-2.1,5.6-2,7.8c0.1,2.3,0.8,3.7,1.7,3.8c0.9,0.1,1.7-1.2,2.5-3.1c0.8-1.9,1.5-4.3,2-6.9
						c0.5-2.7,1.1-4.5,1.7-6.4c0.7-1.8,1.5-3.7,3.3-6.1c-2.1,2-3.5,3.5-4.9,5.1C11.2,242,9.9,243.6,8.4,246.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M544.8,223.7c-1.8-1.5-3.8-2.7-6-3.5c-2.1-0.9-4.5-1.4-6.9-1.4c-1.2,0-2.4,0.2-3.5,0.6c-1.2,0.4-2.3,0.9-3.2,1.6
						c-1.9,1.4-3.2,3.3-3.7,5.3c-0.3,1-0.3,2-0.2,3.1c0.2,1,0.5,1.8,0.9,2.5c0.9,1.4,2.1,2.3,3.1,2.7c1.1,0.4,2,0.4,2.6,0.2
						c0.6-0.2,0.8-0.4,0.8-0.6c-0.1-0.5-1.1-0.6-2.2-1.7c-1.1-1-2.1-3.1-1.2-5c0.4-1,1.3-2,2.4-2.7c0.6-0.4,1.2-0.6,1.9-0.8
						c0.7-0.2,1.5-0.3,2.2-0.3c3.2,0,7,1.4,10.2,3.5c3.3,2.2,5.7,4.8,7.4,7.5c0.9,1.3,1.6,2.7,2.2,4c0.6,1.4,1.1,2.7,1.5,4.1
						c0.8,2.8,1.2,5.8,1.2,9.2c0,3.4-0.4,7.1-1.6,11.5c1.4-4.3,2.2-8,2.5-11.5c0.3-3.4,0.2-6.5-0.3-9.6c-0.3-1.5-0.6-3.1-1-4.6
						c-0.5-1.5-1.1-3.1-1.9-4.7C550.7,230.1,548.4,226.7,544.8,223.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M547.9,236.8c-0.9-1.3-2.2-2.4-3.6-3.2c-1.5-0.8-3.3-1.2-5-1c-1.8,0.2-3.4,1.2-4.5,2.4c-1.1,1.2-1.7,2.9-1.6,4.4
						c0.1,1.7,1,2.9,1.8,3.6c0.9,0.7,1.9,1.1,2.7,1.1c0.8,0,1.4-0.2,1.8-0.5c0.3-0.3,0.4-0.6,0.3-0.8c-0.2-0.4-0.9-0.6-1.4-1.1
						c-0.3-0.3-0.6-0.6-0.8-1.1c-0.2-0.4-0.3-0.9-0.1-1.2c0.1-0.4,0.4-0.8,0.8-1.2c0.5-0.4,1.1-0.7,1.7-0.7c1.3-0.2,3.2,0.6,4.7,2
						c1.6,1.5,2.6,3.5,3.6,5.3c0.9,1.8,1.7,3.4,2.3,5.1c0.6,1.7,1.2,3.5,1.6,5.5c0.4,2,0.7,4.4,0.8,7.1c0.2-2.8,0.2-5.1,0.1-7.2
						c-0.1-2.1-0.3-4-0.6-5.9c-0.3-1.9-0.7-3.7-1.3-5.8c-0.3-1-0.6-2.1-1.1-3.3C549.5,239.4,548.9,238.1,547.9,236.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M613.1,514.1c0.5,1.2,0.7,2.4,0.8,3.5c0,1.1-0.1,2-0.4,2.7c-0.3,0.7-0.7,1.4-1.3,2.1c-0.6,0.6-1.4,1.3-2.4,1.7
						c-1.9,1-4,1.4-6.1,1.3c-2-0.1-3.6-0.8-4.7-1.5c-1.2-0.9-2-2.1-2.3-3.2c-0.4-1-0.3-2.3,0.1-3.4c0.4-1.1,0.9-2,1.4-2.8
						c0.5-0.8,0.9-1.4,0.9-2.1c0-0.6-0.6-1.3-1.9-1.6c-1.3-0.3-3.3,0-5.5,1.3c-1.1,0.7-2.1,1.6-3.1,2.9c-0.5,0.7-0.9,1.4-1.2,2.2
						c-0.4,0.8-0.6,1.8-0.8,2.7c-0.7,3.8,0.2,8.2,2.9,11.9c2.8,3.9,7.2,6.5,12,7.4c4.7,1,10.1,0.5,14.9-1.9c2.4-1.2,4.7-2.8,6.7-5
						c2-2.2,3.6-4.9,4.4-8c0.8-3,0.8-6.2,0.3-9c-0.6-2.9-1.7-5.4-3.1-7.7c-1.5-2.3-3.3-4.3-5.2-5.9c-1.9-1.6-4.1-2.9-6.1-3.7
						c-4.1-1.7-8-2.3-11.5-2.4c-7.1-0.1-12.9,1.4-18.6,3.1c-5.6,1.8-11.1,4.1-16.8,7.4c-5.7,3.2-11.6,7.4-17.6,13.4
						c6.6-5.4,12.9-8.7,18.8-11c5.9-2.3,11.4-3.5,16.8-4.2c5.4-0.6,11-0.7,16.3,0.5c2.7,0.6,5.3,1.6,7.5,3.1c1.2,0.8,2,1.6,2.8,2.6
						C611.9,511.7,612.6,512.9,613.1,514.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M586.9,487.9c2.8-1.8,5.2-4.2,7.1-6.8c1.9-2.6,3.2-5.3,3.9-7.8c0.7-2.5,0.8-4.7,0.6-6.3c-0.3-1.6-0.9-2.5-1.8-2.7
						c-1.8-0.3-3.7,2.1-6.3,5.3c-1.3,1.7-2.7,3.5-4.3,5.4c-1.6,2-3.3,4.1-5.1,6.3c-1.8,2.3-3.6,4.1-5.2,5.7
						c-1.7,1.6-3.2,3.1-4.9,4.4c-3.3,2.7-6.9,5.3-12.6,8.5c6.1-2.3,10.3-3.7,14.5-5.2c2.1-0.8,4.2-1.6,6.5-2.7
						C581.6,491.1,584.1,489.8,586.9,487.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M574,520.4c-1.2,2.3-1.3,5.1-0.8,7c0.5,1.9,1.5,3,2.4,2.8c0.9-0.2,1.4-1.4,1.8-3c0.4-1.6,0.7-3.5,0.8-5.6
						c0.2-2.2,0.5-3.7,1-5.3c0.5-1.5,1.3-3,2.9-4.9c-2,1.4-3.3,2.6-4.5,3.9C576.4,516.6,575.3,518,574,520.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M580.2,543.9c0.4,2.6,1.7,5,3.2,6.4c1.5,1.4,2.9,1.9,3.6,1.3c0.7-0.6,0.5-2,0-3.6c-0.5-1.6-1.4-3.6-2.5-5.5
						c-1.1-2-1.8-3.6-2.3-5.2c-0.5-1.6-0.9-3.3-0.8-5.9c-0.6,2.5-0.9,4.3-1.1,6.1C580,539.4,579.8,541.2,580.2,543.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M599.3,558c1.3,1.4,3,2.4,4.7,2.8c1.7,0.5,3.4,0.5,4.8,0.2c1.4-0.3,2.4-0.9,3-1.4c0.6-0.6,0.8-1.1,0.7-1.5
						c-0.4-0.8-2-0.9-3.9-1.2c-2-0.3-4.3-0.9-6.5-2.2c-1.2-0.7-2.1-1.5-2.9-2.3c-0.8-0.8-1.4-1.7-2-2.6c-1.2-1.8-2.1-3.9-3.1-7.1
						c0.5,3.3,0.8,5.6,1.4,7.8c0.3,1.1,0.6,2.3,1.2,3.5C597.2,555.2,598,556.6,599.3,558z"
                    />
                  </g>
                  <g>
                    <path
                      d="M613.7,553.2c2.2,1.1,4.7,1.3,6.5,1c1.8-0.3,3-1.2,2.9-2.1c-0.1-0.9-1.2-1.6-2.7-2.1c-1.5-0.5-3.5-0.9-5.5-1
						c-2.2-0.2-3.7-0.4-5.2-0.7c-1.5-0.4-3.1-0.9-5.1-2.2c1.7,1.7,2.9,2.8,4.2,3.9C610.1,551,611.5,552.1,613.7,553.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M623.6,545.9c1.5,0.1,2.9-0.6,3.8-1.4c0.8-0.8,1.1-1.9,0.7-2.6c-0.4-0.8-1.3-1.2-2.4-1.2c-1,0-2.2,0.3-3.3,1
						c-1.1,0.8-1.9,1.3-2.8,1.8c-0.9,0.5-1.8,0.9-3.2,1.2c1.4,0.3,2.5,0.5,3.5,0.7C620.9,545.6,622,545.8,623.6,545.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M584.7,454.4c0.8-4,0.4-8.1-0.6-11.1c-0.9-2.9-2.3-4.6-3.1-4.4c-0.9,0.3-1.1,2.3-1,5c0,2.8,0.2,6.4,0.3,10.1
						c0.1,3.8,0,6.5-0.4,9.1c-0.4,2.7-1,5.4-2.7,9.1c2.2-3.5,3.4-6,4.5-8.6C582.9,461.2,583.9,458.5,584.7,454.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M175.8,236.9c-0.3,3.3,0.7,6.7,2.2,8.7c1.4,2.1,3.1,2.9,3.7,2.4c0.7-0.5,0.5-2.1,0.1-4.1c-0.5-2.3-1-4.4-1.6-7.2
						c-0.5-2.8-0.6-4.8-0.4-6.9c0.2-2,0.7-4.2,2.3-6.9c-2.1,2.4-3.2,4.4-4.2,6.4C177,231.3,176.2,233.5,175.8,236.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M316.4,56.6c3.3,0.8,6.5,1.5,9,2c2.5,0.5,4.4,0.7,4.8-0.1c0.4-0.7-0.8-2.4-3.3-3.8c-2.5-1.5-6.3-2.6-10.1-2.5
						c-3.9,0.1-6.5,0.7-9.1,1.5c-2.5,0.8-5,1.6-8.5,3.4c3.6-1.2,6.2-1.4,8.7-1.5C310.5,55.6,313,55.9,316.4,56.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M166.4,149.7c1.4,3.4,3.9,6.3,6.1,7.9c2.2,1.7,4.1,2.1,4.7,1.5c0.6-0.7-0.3-2.3-1.7-4.4c-1.4-2.1-3.3-4.6-5.3-7.2
						c-2.1-2.7-3.3-4.7-4.4-6.9c-1.1-2.2-2-4.5-2.6-8.1c0.1,3.7,0.4,6.2,0.8,8.7C164.4,143.7,165,146.2,166.4,149.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M79.1,161.1c1.1-2.5,1.1-5.4,0.4-7.4c-0.7-2-1.8-3-2.6-2.8c-0.9,0.3-1.3,1.6-1.5,3.2c-0.3,1.7-0.4,3.7-0.5,5.9
						c-0.1,2.3-0.4,3.9-0.9,5.5c-0.5,1.6-1.3,3.2-3.1,5.1c2.1-1.5,3.4-2.8,4.7-4.2C76.7,165.2,77.9,163.7,79.1,161.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M340.8,260.3c3.5-6.8,4.5-14.6,2.8-15.1c-1.7-0.5-5.1,6.3-6.9,13.5c-0.9,3.8-1.5,6.4-2.2,9c-0.7,2.6-1.5,5.2-2.9,9
						c1.9-3.5,3.3-5.9,4.7-8.3C337.6,266.1,339,263.8,340.8,260.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M485,235.3c3.3,0,6.5-0.3,9-0.8c2.4-0.5,4.1-1.2,4.2-2.1c0-0.9-1.7-1.7-4.2-2.1c-2.6-0.4-6.1-0.3-9.4,0.6
						c-3.4,0.9-5.7,1.8-8,2.7c-2.4,1-4.7,1.9-7.8,3.1c3.5-0.8,5.9-1.1,8.3-1.3C479.3,235.3,481.6,235.3,485,235.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M596.5,141.5c-2.7,0.3-5.3,1.3-7,2.5c-1.7,1.2-2.6,2.4-2.2,3.2c0.4,0.8,1.9,0.9,3.8,0.6c1.9-0.3,4.3-1,6.5-2.1
						c2.4-1.1,4-1.9,5.8-2.5c1.7-0.7,3.5-1.2,6.3-1.7c-2.6-0.1-4.5-0.1-6.6-0.2C601.2,141.3,599.3,141.2,596.5,141.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M548.5,79c-2.4,1.8-4.6,3.6-6.2,5.2c-1.6,1.6-2.6,2.9-2.2,3.7c0.4,0.8,2.2,0.8,4.5-0.2c2.3-1,5.1-3,7.1-5.6
						c2-2.7,3.1-4.8,4-6.9c0.9-2.1,1.8-4.2,2.9-7.4c-1.5,3-3,4.7-4.5,6.3C552.6,75.7,550.9,77.1,548.5,79z"
                    />
                  </g>
                  <g>
                    <path
                      d="M482.8,101.2c3.5-0.6,7-1.5,9.5-2.4c2.6-0.9,4.3-1.9,4.2-2.8c-0.1-0.9-2.1-1.4-4.9-1.3c-2.8,0.1-6.6,0.8-10,2.3
						c-3.6,1.5-5.9,2.8-8.2,4.1c-2.3,1.3-4.6,2.7-7.9,4.7c3.6-1.5,6.1-2.3,8.6-2.9C476.6,102.3,479.2,101.9,482.8,101.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M503.3,526.1c2.5-1.7,3.3-4.9,2-6c-1.4-1.1-4.3,0.4-5.4,3.3c-1.2,2.9-1.3,4.3-2.8,7.1
						C499.4,528.3,500.7,527.9,503.3,526.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M281.3,518.4c2,0.3,3.8,0.4,5.3,0.2c1.5-0.1,2.7-0.5,3-1.3c0.3-0.8-0.5-2-2.2-2.8c-1.6-0.8-4.1-1.2-6.5-0.5
						c-2.4,0.7-3.9,1.5-5.3,2.3c-1.4,0.9-2.8,1.8-4.6,3.2c2.1-1,3.6-1.3,5.1-1.4C277.8,518,279.3,518.1,281.3,518.4z"
                    />
                  </g>
                </g>
              </g>
              <g>
                <defs>
                  <path
                    id="SVGID_00000177462521826548859880000011151844479749506981_"
                    d="M382.8,473.1c-30.7-28.1-144-167.7-122.2-191.4
					C282.4,258,431.1,359,461.8,387.1c30.7,28.1,56.6,93.3,37.7,113.9C480.6,521.6,413.5,501.3,382.8,473.1z"
                  />
                </defs>
                <clipPath id="SVGID_00000171000433120290616340000012549147896148799678_">
                  <use
                    xlinkHref="#SVGID_00000177462521826548859880000011151844479749506981_"
                    overflow="visible"
                  />
                </clipPath>
              </g>
            </g>
          </g>
          <g id="Peddle3">
            <image
              overflow="visible"
              opacity="0.7"
              width="1777"
              height="914"
              xlinkHref={Shadow10}
              transform="matrix(0.24 0 0 0.24 197.1893 196.4164)"
            ></image>
            <g>
              <g>
                <defs>
                  <path
                    id="SVGID_00000054974270780159632070000018253408568513411461_"
                    d="M470.1,364.4c-41.6,1.8-220.4-16.7-221.8-48.9
					c-1.4-32.2,175.2-66,216.8-67.8c41.6-1.8,106,25.9,107.2,53.8C573.5,329.4,511.6,362.6,470.1,364.4z"
                  />
                </defs>
                <clipPath id="SVGID_00000072252780386736859560000009836389015203801261_">
                  <use
                    xlinkHref="#SVGID_00000054974270780159632070000018253408568513411461_"
                    overflow="visible"
                  />
                </clipPath>
              </g>
              <g>
                <defs>
                  <path
                    id="SVGID_00000039103187826368970960000015077809421311286206_"
                    d="M470.1,364.4c-41.6,1.8-220.4-16.7-221.8-48.9
					c-1.4-32.2,175.2-66,216.8-67.8c41.6-1.8,106,25.9,107.2,53.8C573.5,329.4,511.6,362.6,470.1,364.4z"
                  />
                </defs>
                <use
                  xlinkHref="#SVGID_00000039103187826368970960000015077809421311286206_"
                  overflow="visible"
                  fill="#36B657"
                />
                <clipPath id="SVGID_00000004512118860522077310000000977675551122382525_">
                  <use
                    xlinkHref="#SVGID_00000039103187826368970960000015077809421311286206_"
                    overflow="visible"
                  />
                </clipPath>
                <g
                  opacity="0.2"
                  clipPath="url(#SVGID_00000004512118860522077310000000977675551122382525_)"
                >
                  <g>
                    <g>
                      <path
                        d="M312.1,219.6c8.1-1.2,15.4-3.8,20.8-7c5.4-3.2,8.8-6.9,8.1-10.4c-0.7-3.4-5.5-5.7-12.2-5.8c-6.7-0.1-15.4,2-22.9,6.8
							c-7.7,4.9-12.4,9-17.2,13c-4.7,4-9.4,8-16.3,13.7c8.1-3.8,13.9-5.6,19.8-6.9C297.9,221.7,303.8,220.8,312.1,219.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M314.5,239.9c7,1.8,13.9,2.1,19.5,1.1c5.5-1,9.7-3.2,10.5-6.6c0.8-3.4-2.4-7.3-8.1-9.9c-5.6-2.6-13.8-3.8-21.8-2.1
							c-8.2,1.8-13.5,3.9-18.8,6c-5.3,2.1-10.5,4.2-18,7.1c8-0.8,13.5-0.4,18.9,0.5C302,236.8,307.2,238.1,314.5,239.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M303.9,258.7c4.2,3.4,8.8,5.1,13,5.4c4.2,0.3,8.1-0.9,10-3.9c1.9-3,1.1-7.2-2.1-10.9c-3.1-3.7-8.6-6.8-14.9-7.1
							c-6.5-0.4-10.9,0.2-15.2,0.7c-4.3,0.5-8.6,1-14.6,1.5c5.8,1.7,9.5,3.7,12.9,5.9C296.4,252.6,299.5,255.2,303.9,258.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M282.6,276.1c1.5,4.7,4.5,8,8,9.7c3.4,1.7,7.3,1.9,10.2-0.1c2.9-2,4.1-5.7,3.3-9.8c-0.7-4-3.4-8.3-7.9-11
							c-4.6-2.8-8.1-4.2-11.5-5.8c-3.4-1.6-6.6-3.2-10.9-6.1c2.8,4.3,4.2,7.7,5.3,11.2C280.2,267.7,281.1,271.2,282.6,276.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M262,284.1c-1.5,5-1.3,9.6,0.1,13.5c1.3,3.8,3.8,7,7.3,7.6c3.4,0.7,7.1-1.5,9.3-5.8c2.2-4.2,3-10.5,0.8-16.4
							c-2.3-6-4.6-9.7-6.8-13.4c-2.3-3.6-4.5-7.1-7.6-12.2c1,5.8,0.8,9.9,0.1,13.8C264.5,275.2,263.5,279,262,284.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M238.4,276.8c-4.1,3.3-6.7,7-8.2,10.8c-0.3,1-0.7,1.9-0.9,2.9c-0.2,1-0.3,1.9-0.3,2.8c0,1.8,0.4,3.6,1.4,5
							c1,1.4,2.6,2.3,4.6,2.6c1,0.1,2.1,0.1,3.2-0.1c1.1-0.2,2.4-0.5,3.5-1.1c4.8-2.1,9.8-7.2,11.7-13.9c1.9-6.8,2-11.6,2-16.2
							c-0.1-4.6-0.3-9.1-0.8-15.3c-1.6,6-3.8,9.7-6.4,12.9C245.6,270.5,242.6,273.3,238.4,276.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M221.4,254.8c-5.9,0.7-10.8,2.7-14.6,5.4c-3.7,2.7-6.2,6.2-5.7,9.7c0.5,3.5,4.2,6.1,9.6,6.6
							c5.3,0.5,12.3-1.2,17.7-5.6c5.6-4.6,8.8-8.3,11.8-12c3-3.7,5.9-7.3,10.2-12.6c-5.6,3.6-10,5.2-14.3,6.3
							C231.8,253.5,227.4,254.1,221.4,254.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M214.7,232.3c-5.8-1.9-11.2-2.4-15.9-1.9c-1.2,0.2-2.3,0.3-3.3,0.7c-1.1,0.3-2,0.7-2.9,1.1c-1.7,0.9-3.1,2.2-3.7,3.8
							c-0.6,1.6-0.4,3.5,0.5,5.3c0.5,0.9,1.2,1.9,2,2.7c0.9,0.9,1.9,1.8,3.1,2.5c4.8,3.1,12.4,4.8,19.7,3.2c7.5-1.7,12.3-4,16.9-6.2
							c4.5-2.3,9-4.7,15-8.3c-6.9,1.6-11.7,1.4-16.4,0.8C225.1,235.5,220.6,234.3,214.7,232.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M221.6,214.1c-3.8-4.1-7-7.1-10.2-9.4c-1.6-1.2-3.2-2.2-4.8-3.1c-1.6-0.9-3.3-1.6-5.1-1.4c-1.7,0.1-3.3,1.2-4.4,3.3
							c-1.1,2.1-1.4,5.3-0.6,8.8c0.8,3.5,2.7,7.3,5.7,10.4c2.9,3.1,6.8,5.6,11,6.8c4.4,1.3,7.9,1.6,11.1,1.7
							c3.2,0.1,5.9-0.1,8.6-0.4c5.4-0.6,10.5-1.4,17.9-2.1c-7.3-1.5-12.2-3.2-16.6-5.4c-2.2-1.1-4.3-2.4-6.4-3.9
							C225.6,217.9,223.5,216.2,221.6,214.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M239.5,196.3c-0.1-5.5-0.9-9.8-2.3-13.6c-0.7-1.9-1.5-3.7-2.5-5.3c-1-1.6-2.2-2.9-3.9-3.6c-1.6-0.7-3.5-0.5-5.4,0.6
							c-1.9,1.1-3.7,3.3-4.9,6.3c-1.2,3-1.8,6.7-1.4,10.5c0.4,3.8,1.7,7.8,3.9,11.1c2.3,3.5,4.6,5.9,6.7,7.9c2.2,2,4.2,3.6,6.3,5.1
							c4.1,3,8.3,5.7,14,9.6c-4.3-5.5-6.5-9.9-8-14.2c-0.7-2.2-1.3-4.4-1.8-6.8C239.9,201.7,239.6,199.1,239.5,196.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M259.2,191.2c2.3-5.4,3.1-10.6,2.9-15.2c-0.1-1.2-0.2-2.2-0.4-3.3c-0.2-1.1-0.5-2-0.9-2.9c-0.8-1.8-1.9-3.2-3.5-4
							c-1.6-0.8-3.4-0.8-5.3,0c-1,0.4-2,1-2.9,1.8c-0.9,0.8-1.9,1.7-2.8,2.9c-3.5,4.5-5.8,11.9-4.6,19.3c1.3,7.6,3.3,12.4,5.3,17
							c2.1,4.6,4.3,9,7.7,15c-1.4-6.8-1-11.6-0.1-16C255.5,201.1,256.9,196.9,259.2,191.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M281.1,191.4c4.3-4.1,7.2-8,9.3-11.7c1-1.9,1.9-3.7,2.4-5.6c0.5-1.8,0.7-3.7,0-5.3c-0.6-1.6-2.1-2.9-4.3-3.4
							c-2.2-0.6-5.1-0.3-8.2,0.8c-3.1,1.2-6.3,3.3-8.9,6.2c-2.6,2.9-4.7,6.5-5.8,10.5c-1.2,4-1.6,7.4-1.8,10.4
							c-0.2,3-0.2,5.7-0.2,8.3c0.1,5.2,0.3,10.3,0,17.6c2.4-6.9,4.7-11.5,7.3-15.6c1.3-2.1,2.8-4,4.4-6
							C277.1,195.6,278.9,193.5,281.1,191.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M256.7,233.3c-1.5,3.2,1.5,6,5,5.6c3.5-0.4,5.8-3.7,3.7-6.5c-2.2-2.9-3.7-3.9-5.1-7
							C259.6,228.7,258.2,230,256.7,233.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M261.4,230.6c-1.4,1-1.8,2.3-1.5,3.7c0.3,1.4,1.3,2.8,2.8,3.6c1.5,0.9,3.3,0.9,4.7,0.2c1.4-0.7,2.3-2.2,2.1-4.1
							c-0.2-2-0.6-3.3-0.9-4.7c-0.3-1.3-0.6-2.6-0.7-4.4c-0.9,1.5-1.9,2.4-2.9,3.1C263.9,228.9,262.8,229.6,261.4,230.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M264.9,232.2c-1.3-0.6-2.1-0.2-2.9,0.7c-0.7,0.9-1.4,2.4-1.5,4.2c-0.1,1.7,0.7,3.3,2.1,4.1c1.4,0.8,3.3,0.7,4.8-0.7
							c1.5-1.4,2.2-2.7,2.8-3.8c0.6-1.2,1.2-2.3,2.1-3.6c-1.5,0.5-2.7,0.5-3.8,0.3C267.3,233.2,266.2,232.7,264.9,232.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M267.4,236.8c0-0.7-0.3-1.3-0.7-1.8c-0.5-0.5-1.1-1-1.9-1.2c-0.8-0.3-1.6-0.3-2.4-0.1c-0.8,0.2-1.5,0.6-2.1,1.3
							c-0.6,0.6-1.1,1.3-1.4,2c-0.3,0.7-0.6,1.4-0.6,2c0,0.7,0.1,1.3,0.5,2c0.4,0.6,1.1,1.3,2.1,1.7c1,0.5,2.1,0.8,3,1
							c0.9,0.2,1.7,0.2,2.5,0.1c1.6-0.1,3.1-0.5,4.5-1.6c-0.8,0.1-1.5-0.2-2-0.6c-0.5-0.4-0.8-0.8-1-1.3c-0.2-0.5-0.3-1-0.4-1.6
							C267.4,238,267.5,237.5,267.4,236.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M266.9,240c0.8-1.4,0.7-2.5-0.1-3.6c-0.7-1.1-2-2.2-3.8-2.6c-1.7-0.4-3.4,0.1-4.5,1.4c-1.1,1.3-1.4,3.5-0.4,5.4
							c1.1,2,2.1,3.1,3.2,4.1c1,1,2,2,3.2,3.4c-0.2-1.8,0-3.1,0.5-4.3C265.4,242.6,266.1,241.5,266.9,240z"
                      />
                    </g>
                    <g>
                      <path
                        d="M264.2,244.1c1.7-1.3,2.4-3.2,2.2-4.9c-0.2-1.7-1.4-3.1-3.1-3.7c-1.7-0.6-3.4-0.3-4.7,0.5c-1.3,0.8-2.1,2-2.1,3.8
							c0,1.9,0,3.3,0,4.7c-0.1,1.4-0.4,2.8-1.2,4.5c1.6-1,2.9-1.7,4.3-2.3C260.9,246.1,262.4,245.4,264.2,244.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M258.2,243.2c3.6,1.4,6.6-1.7,6.2-5.2c-0.4-3.5-4.2-5.7-7.3-3.5c-3.2,2.3-4.3,3.9-7.7,5.4
							C253,240.5,254.5,241.8,258.2,243.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M254.5,239.4c1.8,3.2,5.8,2.6,7.7-0.4c1.8-3,0.5-6.9-3.1-7c-3.7-0.1-5.5,0.5-8.9-0.3
							C252.5,234.3,252.7,236.1,254.5,239.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M255,235.1c0,1.7,1.1,2.7,2.4,3c1.3,0.3,3-0.2,4.3-1.3c1.4-1.1,2.1-2.6,2.3-3.8c0.1-1.2-0.4-2.3-1.8-2.8
							c-2.9-1-4.7-1.1-6.9-3C255.8,230.1,255,231.7,255,235.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M299.7,201.9c5.7-1.8,10.2-4.8,13.4-8.2c3.2-3.4,5-7.2,3.9-10.6c-1.1-3.3-5.3-5.2-10.8-4.6c-5.4,0.5-12,3.6-16.6,9.1
							c-4.7,5.7-7.1,10.1-9.4,14.4c-2.2,4.4-4.4,8.6-7.4,14.7c4.8-4.8,8.8-7.3,12.8-9.3C289.6,205.4,293.8,203.9,299.7,201.9z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M624.2,225.2c3.8,4.2,8.2,7.3,12.1,9.2c3.9,1.8,7.4,2.3,9.1,0.6c1.7-1.7,1.1-5.3-1.4-9.3c-2.6-3.9-7.1-8.1-12.8-10.5
							c-5.8-2.5-10-3.6-14.1-4.8c-4.1-1.1-8.3-2.3-14.2-4.1c5.3,3.1,8.6,5.8,11.6,8.7C617.5,217.9,620.3,220.9,624.2,225.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M613.5,234.5c1.7,4.7,4.2,8.8,6.9,11.6c2.7,2.8,5.6,4.4,7.9,3.4c2.2-0.9,3.3-4.2,2.6-8.5c-0.7-4.2-3.2-9.4-7.3-13.3
							c-4.2-4-7.5-6.2-10.7-8.5c-3.3-2.2-6.5-4.4-11.1-7.6c3.6,4.3,5.5,7.6,7.1,11C610.4,226.1,611.7,229.6,613.5,234.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M598.5,235.7c-0.3,3.7,0.5,7,2,9.6c1.5,2.5,3.7,4.3,6.1,4.2c2.4-0.1,4.5-2.2,5.4-5.4c0.9-3.2,0.5-7.6-1.7-11.3
							c-2.3-3.8-4.3-6.2-6.3-8.5c-2-2.3-3.9-4.5-6.5-7.8c1.3,4,1.6,6.8,1.6,9.7C599.1,229,598.9,231.8,598.5,235.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M580.4,230.2c-2.1,2.7-2.8,5.7-2.5,8.4c0.3,2.6,1.7,4.9,4,5.8c2.3,0.9,4.9,0.1,6.9-1.9c2-2,3.4-5.2,3.2-8.8
							c-0.2-3.7-0.7-6.3-1.1-8.9c-0.4-2.5-0.7-5.1-0.8-8.6c-1.4,3.3-2.8,5.4-4.3,7.4C584.2,225.6,582.5,227.5,580.4,230.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M567.7,221.5c-3.4,1.1-6,3-7.7,5.3c-1.7,2.3-2.5,4.9-1.5,7.2c0.9,2.2,3.6,3.5,7,3.1c3.3-0.4,7.2-2.4,9.7-5.9
							c2.6-3.6,3.8-6.4,5-9.1c1.2-2.7,2.3-5.4,4-9.1c-2.9,2.8-5.4,4.3-7.8,5.4C573.9,219.5,571.3,220.4,567.7,221.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M562.8,205.2c-3.5-1.1-6.6-1.1-9.4-0.5c-0.7,0.2-1.4,0.3-2,0.6c-0.6,0.3-1.2,0.6-1.7,0.9c-1.1,0.7-1.9,1.6-2.3,2.8
							c-0.4,1.1-0.3,2.4,0.3,3.6c0.3,0.6,0.7,1.2,1.3,1.8c0.6,0.6,1.2,1.1,2,1.6c3.1,1.9,8,2.8,12.5,1.3c4.6-1.6,7.4-3.3,10.1-5.1
							c2.6-1.8,5.1-3.7,8.4-6.4c-4.1,1.4-7.1,1.6-9.9,1.4C569.2,206.9,566.4,206.2,562.8,205.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M568.7,186.9c-2.6-3.1-5.7-5.2-8.7-6.3c-3-1.1-5.9-1.1-7.7,0.5c-1.8,1.6-1.9,4.8-0.1,8.1c1.8,3.3,5.4,6.6,10.1,8
							c4.8,1.4,8.2,1.8,11.5,2.1c3.3,0.3,6.5,0.5,11.2,0.9c-4.3-1.8-6.8-3.7-9.1-5.8C573.6,192.4,571.5,190.1,568.7,186.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M579.1,174.3c-1.1-4.1-3-7.4-5.1-9.9c-0.6-0.6-1.1-1.2-1.7-1.7c-0.6-0.5-1.2-0.9-1.8-1.2c-1.2-0.6-2.4-0.9-3.6-0.7
							c-1.2,0.3-2.2,1.1-2.8,2.4c-0.3,0.6-0.6,1.4-0.8,2.2c-0.2,0.8-0.3,1.8-0.2,2.8c0.1,4,2,9,5.8,12.6c3.9,3.7,7,5.5,10.1,7.2
							c3.1,1.7,6.2,3.3,10.6,5.4c-3.6-3.3-5.4-6.2-6.8-9.1C581.3,181.5,580.3,178.5,579.1,174.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M592.2,171.1c0.9-3.8,1.3-6.8,1.4-9.5c0.1-1.3,0-2.7-0.1-4c-0.1-1.3-0.4-2.5-1.2-3.5c-0.8-0.9-2-1.4-3.6-1.2
							c-1.6,0.2-3.6,1.2-5.3,3c-1.7,1.8-3.1,4.4-3.7,7.3c-0.6,2.9-0.6,6,0.3,8.9c0.9,3,2.1,5.2,3.3,7c1.2,1.8,2.4,3.3,3.6,4.8
							c2.4,2.9,4.9,5.5,8.1,9.5c-2-4.8-2.9-8.3-3.3-11.7c-0.2-1.7-0.3-3.4-0.3-5.2C591.6,175,591.7,173.1,592.2,171.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M609.4,174.5c3.1-2.2,5.3-4.3,6.9-6.6c0.8-1.1,1.5-2.3,2.1-3.5c0.5-1.2,0.8-2.4,0.6-3.6c-0.2-1.2-1.1-2.2-2.5-2.8
							c-1.4-0.6-3.3-0.8-5.5-0.4c-2.2,0.4-4.5,1.5-6.6,3.3c-2,1.7-3.8,4-4.8,6.5c-1.1,2.7-1.6,4.9-1.9,6.9c-0.3,2-0.4,3.8-0.5,5.6
							c-0.1,3.5,0,6.9-0.1,11.8c1.5-4.6,3.1-7.6,5.1-10.1c1-1.3,2-2.5,3.2-3.6C606.4,176.8,607.8,175.6,609.4,174.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M619.9,183.9c4-0.8,7.3-2.3,9.9-4.3c0.6-0.5,1.2-1,1.7-1.5c0.5-0.5,1-1.1,1.3-1.6c0.7-1.1,1.1-2.3,1-3.6
							c-0.2-1.2-0.9-2.3-2.1-3.1c-0.6-0.4-1.3-0.8-2.1-1c-0.8-0.2-1.7-0.4-2.7-0.5c-3.9-0.3-9.1,1.3-12.8,4.9
							c-3.8,3.7-5.8,6.7-7.7,9.6c-1.8,3-3.5,6-5.6,10.2c3.4-3.4,6.2-5.1,9.1-6.3C612.8,185.6,615.8,184.7,619.9,183.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M628.3,196.5c4,0.9,7.4,1,10.3,0.8c1.5-0.1,2.9-0.4,4.1-0.8c1.2-0.4,2.4-1,3.1-2c0.7-1,0.8-2.3,0.3-3.8
							c-0.5-1.5-1.8-3-3.7-4.4c-1.9-1.3-4.3-2.3-7-2.7c-2.7-0.4-5.6-0.2-8.3,0.7c-2.8,0.9-4.9,2-6.7,3c-1.8,1-3.3,2.1-4.8,3.1
							c-3,2.1-5.8,4.2-10.1,6.8c4.9-1.3,8.4-1.8,11.8-1.8c1.7,0,3.4,0,5.2,0.2C624.3,195.8,626.3,196,628.3,196.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M594.8,198.8c-2.4,0.4-2.8,3.2-1.3,5c1.6,1.9,4.4,1.9,5.2-0.4c0.8-2.4,0.8-3.7,2-5.7
							C598.6,198.6,597.3,198.3,594.8,198.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M598.2,200.4c-1.1-0.4-2-0.1-2.7,0.6c-0.7,0.7-1.1,1.8-1,3c0.1,1.2,0.8,2.2,1.7,2.7c0.9,0.5,2.2,0.5,3.2-0.4
							c1-0.9,1.7-1.6,2.3-2.3c0.6-0.7,1.3-1.4,2.2-2.1c-1.2,0-2.1-0.2-2.9-0.4C600.2,201.2,599.3,200.8,598.2,200.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M598.6,203c-0.2-0.9-0.7-1.3-1.5-1.4c-0.8-0.1-1.9,0.1-3,0.8c-1,0.6-1.7,1.7-1.6,2.8c0.1,1.1,0.9,2.2,2.2,2.5
							c1.4,0.3,2.4,0.2,3.3,0.1c0.9-0.1,1.8-0.2,2.9-0.2c-0.9-0.7-1.3-1.4-1.6-2.1C599,204.8,598.8,204,598.6,203z"
                      />
                    </g>
                    <g>
                      <path
                        d="M597,206.3c0.4-0.3,0.6-0.7,0.8-1.1c0.1-0.5,0.1-1,0-1.6c-0.1-0.5-0.4-1.1-0.9-1.4c-0.4-0.4-1-0.6-1.6-0.7
							c-0.6-0.1-1.2-0.1-1.7,0c-0.5,0.1-1,0.2-1.4,0.4c-0.4,0.2-0.7,0.6-0.9,1c-0.2,0.5-0.3,1.1-0.2,1.9c0.1,0.8,0.3,1.5,0.6,2.1
							c0.3,0.6,0.6,1.1,0.9,1.5c0.7,0.8,1.5,1.6,2.7,2c-0.4-0.5-0.5-1-0.4-1.4c0-0.4,0.2-0.8,0.4-1.1c0.2-0.3,0.5-0.6,0.7-0.9
							C596.3,206.7,596.6,206.6,597,206.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M594.9,207.2c1.1-0.1,1.7-0.6,2.1-1.5c0.3-0.8,0.5-2,0-3.2c-0.4-1.1-1.4-1.9-2.6-2c-1.2-0.1-2.5,0.5-3.2,1.9
							c-0.7,1.4-0.9,2.4-1.1,3.4c-0.2,1-0.4,1.9-0.7,3.2c0.9-0.8,1.8-1.2,2.7-1.4C592.9,207.4,593.8,207.3,594.9,207.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M591.5,207.3c1.4,0.5,2.8,0.1,3.7-0.7c0.9-0.8,1.2-2,0.9-3.2c-0.3-1.2-1.2-2.1-2.1-2.5c-0.9-0.4-2-0.4-3,0.3
							c-1.1,0.7-1.9,1.3-2.7,1.8c-0.8,0.5-1.7,0.9-3.1,1.1c1.2,0.5,2.1,1,3,1.6C589.1,206.1,590.1,206.7,591.5,207.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M589.7,203.4c0.6,2.6,3.6,3.1,5.4,1.5c1.8-1.6,1.7-4.6-0.8-5.5c-2.6-1-3.9-1-6.1-2.3
							C589.3,199.5,589.1,200.8,589.7,203.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M590.5,199.9c-1.1,2.2,0.8,4.3,3.2,4.2c2.4-0.1,4.2-2.4,2.8-4.5c-1.4-2.2-2.4-3-3.3-5.2
							C592.6,196.7,591.6,197.6,590.5,199.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M593.1,198.5c-0.9,0.7-1.1,1.7-0.8,2.5c0.4,0.9,1.3,1.6,2.4,2c1.2,0.3,2.3,0.2,3.1-0.2c0.8-0.4,1.2-1.1,0.9-2.1
							c-0.5-2-1.2-3.1-0.9-5.1C596.3,197,595.1,197.2,593.1,198.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M629.5,211.2c3.3,2.5,6.7,4,9.9,4.5c3.2,0.5,6.1,0.1,7.6-1.9c1.5-1.9,0.9-5.1-1.5-8c-2.4-2.9-6.8-5.5-11.7-6
							c-5.1-0.5-8.5-0.1-11.9,0.2c-3.4,0.4-6.7,0.8-11.3,1.5c4.6,0.9,7.6,2.2,10.3,3.7C623.6,206.8,626.1,208.6,629.5,211.2z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M241.1,523.3c3.8,4.2,8.2,7.3,12.1,9.2c3.9,1.8,7.4,2.3,9.1,0.6c1.7-1.7,1.1-5.3-1.4-9.3c-2.6-3.9-7.1-8.1-12.8-10.5
							c-5.8-2.5-10-3.6-14.1-4.8c-4.1-1.1-8.3-2.3-14.2-4.1c5.3,3.1,8.6,5.8,11.6,8.7C234.3,516,237.1,519,241.1,523.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M230.3,532.5c1.7,4.7,4.2,8.8,6.9,11.6c2.7,2.8,5.6,4.4,7.9,3.4c2.2-0.9,3.3-4.2,2.6-8.5c-0.7-4.2-3.2-9.4-7.3-13.3
							c-4.2-4-7.5-6.2-10.7-8.5c-3.3-2.2-6.5-4.4-11.1-7.6c3.6,4.3,5.5,7.6,7.1,11C227.3,524.1,228.6,527.6,230.3,532.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M215.4,533.7c-0.3,3.7,0.5,7,2,9.6c1.5,2.5,3.7,4.3,6.1,4.2c2.4-0.1,4.5-2.2,5.4-5.4c0.9-3.2,0.5-7.6-1.7-11.3
							c-2.3-3.8-4.3-6.2-6.3-8.5c-2-2.3-3.9-4.5-6.5-7.8c1.3,4,1.6,6.8,1.6,9.7C216,527.1,215.7,529.9,215.4,533.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M197.2,528.3c-2.1,2.7-2.8,5.7-2.5,8.4c0.3,2.6,1.7,4.9,4,5.8c2.3,0.9,4.9,0.1,6.9-1.9c2-2,3.4-5.2,3.2-8.8
							c-0.2-3.7-0.7-6.3-1.1-8.9c-0.4-2.5-0.7-5.1-0.8-8.6c-1.4,3.3-2.8,5.4-4.3,7.4C201.1,523.7,199.4,525.5,197.2,528.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M184.6,519.6c-3.4,1.1-6,3-7.7,5.3c-1.7,2.3-2.5,4.9-1.5,7.2c0.9,2.2,3.6,3.5,7,3.1c3.3-0.4,7.2-2.4,9.7-5.9
							c2.6-3.6,3.8-6.4,5-9.1c1.2-2.7,2.3-5.4,4-9.1c-2.9,2.8-5.4,4.3-7.8,5.4C190.7,517.6,188.1,518.4,184.6,519.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M179.7,503.2c-3.5-1.1-6.6-1.1-9.4-0.5c-0.7,0.2-1.4,0.3-2,0.6c-0.6,0.3-1.2,0.6-1.7,0.9c-1.1,0.7-1.9,1.6-2.3,2.8
							c-0.4,1.1-0.3,2.4,0.3,3.6c0.3,0.6,0.7,1.2,1.3,1.8c0.6,0.6,1.2,1.1,2,1.6c3.1,1.9,8,2.8,12.5,1.3c4.6-1.6,7.4-3.3,10.1-5.1
							c2.6-1.8,5.1-3.7,8.4-6.4c-4.1,1.4-7.1,1.6-9.9,1.4C186,504.9,183.3,504.3,179.7,503.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M185.6,484.9c-2.6-3.1-5.7-5.2-8.7-6.3c-3-1.1-5.9-1.1-7.7,0.5c-1.8,1.6-1.9,4.8-0.1,8.1c1.8,3.3,5.4,6.6,10.1,8
							c4.8,1.4,8.2,1.8,11.5,2.1c3.3,0.3,6.5,0.5,11.2,0.9c-4.3-1.8-6.8-3.7-9.1-5.8C190.4,490.4,188.4,488.1,185.6,484.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M195.9,472.3c-1.1-4.1-3-7.4-5.1-9.9c-0.6-0.6-1.1-1.2-1.7-1.7c-0.6-0.5-1.2-0.9-1.8-1.2c-1.2-0.6-2.4-0.9-3.6-0.7
							c-1.2,0.3-2.2,1.1-2.8,2.4c-0.3,0.6-0.6,1.4-0.8,2.2c-0.2,0.8-0.3,1.8-0.2,2.8c0.1,4,2,9,5.8,12.6c3.9,3.7,7,5.5,10.1,7.2
							c3.1,1.7,6.2,3.3,10.6,5.4c-3.6-3.3-5.4-6.2-6.8-9.1C198.2,479.5,197.1,476.5,195.9,472.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M209.1,469.2c0.9-3.8,1.3-6.8,1.4-9.5c0.1-1.3,0-2.7-0.1-4c-0.1-1.3-0.4-2.5-1.2-3.5c-0.8-0.9-2-1.4-3.6-1.2
							c-1.6,0.2-3.6,1.2-5.3,3c-1.7,1.8-3.1,4.4-3.7,7.3c-0.6,2.9-0.6,6,0.3,8.9c0.9,3,2.1,5.2,3.3,7c1.2,1.8,2.4,3.3,3.6,4.8
							c2.4,2.9,4.9,5.5,8.1,9.5c-2-4.8-2.9-8.3-3.3-11.7c-0.2-1.7-0.3-3.4-0.3-5.2C208.4,473,208.6,471.1,209.1,469.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M226.2,472.6c3.1-2.2,5.3-4.3,6.9-6.6c0.8-1.1,1.5-2.3,2.1-3.5c0.5-1.2,0.8-2.4,0.6-3.6c-0.2-1.2-1.1-2.2-2.5-2.8
							c-1.4-0.6-3.3-0.8-5.5-0.4c-2.2,0.4-4.5,1.5-6.6,3.3c-2,1.7-3.8,4-4.8,6.5c-1.1,2.7-1.6,4.9-1.9,6.9c-0.3,2-0.4,3.8-0.5,5.6
							c-0.1,3.5,0,6.9-0.1,11.8c1.5-4.6,3.1-7.6,5.1-10.1c1-1.3,2-2.5,3.2-3.6C223.3,474.9,224.6,473.7,226.2,472.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M236.8,481.9c4-0.8,7.3-2.3,9.9-4.3c0.6-0.5,1.2-1,1.7-1.5c0.5-0.5,1-1.1,1.3-1.6c0.7-1.1,1.1-2.3,1-3.6
							c-0.2-1.2-0.9-2.3-2.1-3.1c-0.6-0.4-1.3-0.8-2.1-1c-0.8-0.2-1.7-0.4-2.7-0.5c-3.9-0.3-9.1,1.3-12.8,4.9
							c-3.8,3.7-5.8,6.7-7.7,9.6c-1.8,3-3.5,6-5.6,10.2c3.4-3.4,6.2-5.1,9.1-6.3C229.7,483.6,232.7,482.8,236.8,481.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M245.2,494.6c4,0.9,7.4,1,10.3,0.8c1.5-0.1,2.9-0.4,4.1-0.8c1.2-0.4,2.4-1,3.1-2c0.7-1,0.8-2.3,0.3-3.8
							c-0.5-1.5-1.8-3-3.7-4.4c-1.9-1.3-4.3-2.3-7-2.7c-2.7-0.4-5.6-0.2-8.3,0.7c-2.8,0.9-4.9,2-6.7,3c-1.8,1-3.3,2.1-4.8,3.1
							c-3,2.1-5.8,4.2-10.1,6.8c4.9-1.3,8.4-1.8,11.8-1.8c1.7,0,3.4,0,5.2,0.2C241.2,493.9,243.1,494.1,245.2,494.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M211.7,496.8c-2.4,0.4-2.8,3.2-1.3,5c1.6,1.9,4.4,1.9,5.2-0.4c0.8-2.4,0.8-3.7,2-5.7
							C215.4,496.7,214.2,496.4,211.7,496.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M215,498.4c-1.1-0.4-2-0.1-2.7,0.6c-0.7,0.7-1.1,1.8-1,3c0.1,1.2,0.8,2.2,1.7,2.7c0.9,0.5,2.2,0.5,3.2-0.4
							c1-0.9,1.7-1.6,2.3-2.3c0.6-0.7,1.3-1.4,2.2-2.1c-1.2,0-2.1-0.2-2.9-0.4C217,499.2,216.2,498.9,215,498.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M215.5,501.1c-0.2-0.9-0.7-1.3-1.5-1.4c-0.8-0.1-1.9,0.1-3,0.8c-1,0.6-1.7,1.7-1.6,2.8c0.1,1.1,0.9,2.2,2.2,2.5
							c1.4,0.3,2.4,0.2,3.3,0.1c0.9-0.1,1.8-0.2,2.9-0.2c-0.9-0.7-1.3-1.4-1.6-2.1C215.9,502.9,215.7,502.1,215.5,501.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M213.9,504.3c0.4-0.3,0.6-0.7,0.8-1.1c0.1-0.5,0.1-1,0-1.6c-0.1-0.5-0.4-1.1-0.9-1.4c-0.4-0.4-1-0.6-1.6-0.7
							c-0.6-0.1-1.2-0.1-1.7,0c-0.5,0.1-1,0.2-1.4,0.4c-0.4,0.2-0.7,0.6-0.9,1c-0.2,0.5-0.3,1.1-0.2,1.9c0.1,0.8,0.3,1.5,0.6,2.1
							c0.3,0.6,0.6,1.1,0.9,1.5c0.7,0.8,1.5,1.6,2.7,2c-0.4-0.5-0.5-1-0.4-1.4c0-0.4,0.2-0.8,0.4-1.1c0.2-0.3,0.5-0.6,0.7-0.9
							C213.1,504.8,213.5,504.6,213.9,504.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M211.8,505.3c1.1-0.1,1.7-0.6,2.1-1.5c0.3-0.8,0.5-2,0-3.2c-0.4-1.1-1.4-1.9-2.6-2c-1.2-0.1-2.5,0.5-3.2,1.9
							c-0.7,1.4-0.9,2.4-1.1,3.4c-0.2,1-0.4,1.9-0.7,3.2c0.9-0.8,1.8-1.2,2.7-1.4C209.7,505.4,210.6,505.4,211.8,505.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M208.4,505.3c1.4,0.5,2.8,0.1,3.7-0.7c0.9-0.8,1.2-2,0.9-3.2c-0.3-1.2-1.2-2.1-2.1-2.5c-0.9-0.4-2-0.4-3,0.3
							c-1.1,0.7-1.9,1.3-2.7,1.8c-0.8,0.5-1.7,0.9-3.1,1.1c1.2,0.5,2.1,1,3,1.6C206,504.2,206.9,504.8,208.4,505.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M206.6,501.5c0.6,2.6,3.6,3.1,5.4,1.5c1.8-1.6,1.7-4.6-0.8-5.5c-2.6-1-3.9-1-6.1-2.3
							C206.1,497.5,205.9,498.8,206.6,501.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M207.3,497.9c-1.1,2.2,0.8,4.3,3.2,4.2c2.4-0.1,4.2-2.4,2.8-4.5c-1.4-2.2-2.4-3-3.3-5.2
							C209.5,494.8,208.5,495.6,207.3,497.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M210,496.6c-0.9,0.7-1.1,1.7-0.8,2.5c0.4,0.9,1.3,1.6,2.4,2c1.2,0.3,2.3,0.2,3.1-0.2c0.8-0.4,1.2-1.1,0.9-2.1
							c-0.5-2-1.2-3.1-0.9-5.1C213.2,495,211.9,495.2,210,496.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M246.4,509.3c3.3,2.5,6.7,4,9.9,4.5c3.2,0.5,6.1,0.1,7.6-1.9c1.5-1.9,0.9-5.1-1.5-8c-2.4-2.9-6.8-5.5-11.7-6
							c-5.1-0.5-8.5-0.1-11.9,0.2c-3.4,0.4-6.7,0.8-11.3,1.5c4.6,0.9,7.6,2.2,10.3,3.7C240.4,504.9,243,506.7,246.4,509.3z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M155.7,208.9c3.8,4.2,8.2,7.3,12.1,9.2c3.9,1.8,7.4,2.3,9.1,0.6c1.7-1.7,1.1-5.3-1.4-9.3c-2.6-3.9-7.1-8.1-12.8-10.5
							c-5.8-2.5-10-3.6-14.1-4.8c-4.1-1.1-8.3-2.3-14.2-4.1c5.3,3.1,8.6,5.8,11.6,8.7C148.9,201.6,151.7,204.6,155.7,208.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M144.9,218.2c1.7,4.7,4.2,8.8,6.9,11.6c2.7,2.8,5.6,4.4,7.9,3.4c2.2-0.9,3.3-4.2,2.6-8.5c-0.7-4.2-3.2-9.4-7.3-13.3
							c-4.2-4-7.5-6.2-10.7-8.5c-3.3-2.2-6.5-4.4-11.1-7.6c3.6,4.3,5.5,7.6,7.1,11C141.9,209.8,143.2,213.3,144.9,218.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M130,219.4c-0.3,3.7,0.5,7,2,9.6c1.5,2.5,3.7,4.3,6.1,4.2c2.4-0.1,4.5-2.2,5.4-5.4c0.9-3.2,0.5-7.6-1.7-11.3
							c-2.3-3.8-4.3-6.2-6.3-8.5c-2-2.3-3.9-4.5-6.5-7.8c1.3,4,1.6,6.8,1.6,9.7C130.6,212.7,130.3,215.5,130,219.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M111.8,214c-2.1,2.7-2.8,5.7-2.5,8.4c0.3,2.6,1.7,4.9,4,5.8c2.3,0.9,4.9,0.1,6.9-1.9c2-2,3.4-5.2,3.2-8.8
							c-0.2-3.7-0.7-6.3-1.1-8.9c-0.4-2.5-0.7-5.1-0.8-8.6c-1.4,3.3-2.8,5.4-4.3,7.4C115.7,209.3,114,211.2,111.8,214z"
                      />
                    </g>
                    <g>
                      <path
                        d="M99.2,205.2c-3.4,1.1-6,3-7.7,5.3c-1.7,2.3-2.5,4.9-1.5,7.2c0.9,2.2,3.6,3.5,7,3.1c3.3-0.4,7.2-2.4,9.7-5.9
							c2.6-3.6,3.8-6.4,5-9.1c1.2-2.7,2.3-5.4,4-9.1c-2.9,2.8-5.4,4.3-7.8,5.4C105.3,203.2,102.7,204.1,99.2,205.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M94.3,188.9c-3.5-1.1-6.6-1.1-9.4-0.5c-0.7,0.2-1.4,0.3-2,0.6c-0.6,0.3-1.2,0.6-1.7,0.9c-1.1,0.7-1.9,1.6-2.3,2.8
							c-0.4,1.1-0.3,2.4,0.3,3.6c0.3,0.6,0.7,1.2,1.3,1.8c0.6,0.6,1.2,1.1,2,1.6c3.1,1.9,8,2.8,12.5,1.3c4.6-1.6,7.4-3.3,10.1-5.1
							c2.6-1.8,5.1-3.7,8.4-6.4c-4.1,1.4-7.1,1.6-9.9,1.4C100.6,190.6,97.9,190,94.3,188.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M100.2,170.6c-2.6-3.1-5.7-5.2-8.7-6.3c-3-1.1-5.9-1.1-7.7,0.5c-1.8,1.6-1.9,4.8-0.1,8.1c1.8,3.3,5.4,6.6,10.1,8
							c4.8,1.4,8.2,1.8,11.5,2.1c3.3,0.3,6.5,0.5,11.2,0.9c-4.3-1.8-6.8-3.7-9.1-5.8C105,176.1,103,173.8,100.2,170.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M110.5,158c-1.1-4.1-3-7.4-5.1-9.9c-0.6-0.6-1.1-1.2-1.7-1.7c-0.6-0.5-1.2-0.9-1.8-1.2c-1.2-0.6-2.4-0.9-3.6-0.7
							c-1.2,0.3-2.2,1.1-2.8,2.4c-0.3,0.6-0.6,1.4-0.8,2.2c-0.2,0.8-0.3,1.8-0.2,2.8c0.1,4,2,9,5.8,12.6c3.9,3.7,7,5.5,10.1,7.2
							c3.1,1.7,6.2,3.3,10.6,5.4c-3.6-3.3-5.4-6.2-6.8-9.1C112.8,165.2,111.7,162.2,110.5,158z"
                      />
                    </g>
                    <g>
                      <path
                        d="M123.7,154.9c0.9-3.8,1.3-6.8,1.4-9.5c0.1-1.3,0-2.7-0.1-4c-0.1-1.3-0.4-2.5-1.2-3.5c-0.8-0.9-2-1.4-3.6-1.2
							c-1.6,0.2-3.6,1.2-5.3,3c-1.7,1.8-3.1,4.4-3.7,7.3c-0.6,2.9-0.6,6,0.3,8.9c0.9,3,2.1,5.2,3.3,7c1.2,1.8,2.4,3.3,3.6,4.8
							c2.4,2.9,4.9,5.5,8.1,9.5c-2-4.8-2.9-8.3-3.3-11.7c-0.2-1.7-0.3-3.4-0.3-5.2C123,158.7,123.2,156.8,123.7,154.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M140.8,158.2c3.1-2.2,5.3-4.3,6.9-6.6c0.8-1.1,1.5-2.3,2.1-3.5c0.5-1.2,0.8-2.4,0.6-3.6c-0.2-1.2-1.1-2.2-2.5-2.8
							c-1.4-0.6-3.3-0.8-5.5-0.4c-2.2,0.4-4.5,1.5-6.6,3.3c-2,1.7-3.8,4-4.8,6.5c-1.1,2.7-1.6,4.9-1.9,6.9c-0.3,2-0.4,3.8-0.5,5.6
							c-0.1,3.5,0,6.9-0.1,11.8c1.5-4.6,3.1-7.6,5.1-10.1c1-1.3,2-2.5,3.2-3.6C137.9,160.6,139.2,159.4,140.8,158.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M151.4,167.6c4-0.8,7.3-2.3,9.9-4.3c0.6-0.5,1.2-1,1.7-1.5c0.5-0.5,1-1.1,1.3-1.6c0.7-1.1,1.1-2.3,1-3.6
							c-0.2-1.2-0.9-2.3-2.1-3.1c-0.6-0.4-1.3-0.8-2.1-1c-0.8-0.2-1.7-0.4-2.7-0.5c-3.9-0.3-9.1,1.3-12.8,4.9
							c-3.8,3.7-5.8,6.7-7.7,9.6c-1.8,3-3.5,6-5.6,10.2c3.4-3.4,6.2-5.1,9.1-6.3C144.2,169.3,147.3,168.5,151.4,167.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M159.8,180.2c4,0.9,7.4,1,10.3,0.8c1.5-0.1,2.9-0.4,4.1-0.8c1.2-0.4,2.4-1,3.1-2c0.7-1,0.8-2.3,0.3-3.8
							c-0.5-1.5-1.8-3-3.7-4.4c-1.9-1.3-4.3-2.3-7-2.7c-2.7-0.4-5.6-0.2-8.3,0.7c-2.8,0.9-4.9,2-6.7,3c-1.8,1-3.3,2.1-4.8,3.1
							c-3,2.1-5.8,4.2-10.1,6.8c4.9-1.3,8.4-1.8,11.8-1.8c1.7,0,3.4,0,5.2,0.2C155.8,179.5,157.7,179.8,159.8,180.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M126.3,182.5c-2.4,0.4-2.8,3.2-1.3,5c1.6,1.9,4.4,1.9,5.2-0.4c0.8-2.4,0.8-3.7,2-5.7
							C130,182.3,128.8,182.1,126.3,182.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M129.6,184.1c-1.1-0.4-2-0.1-2.7,0.6c-0.7,0.7-1.1,1.8-1,3c0.1,1.2,0.8,2.2,1.7,2.7c0.9,0.5,2.2,0.5,3.2-0.4
							c1-0.9,1.7-1.6,2.3-2.3c0.6-0.7,1.3-1.4,2.2-2.1c-1.2,0-2.1-0.2-2.9-0.4C131.6,184.9,130.8,184.5,129.6,184.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M130.1,186.7c-0.2-0.9-0.7-1.3-1.5-1.4c-0.8-0.1-1.9,0.1-3,0.8c-1,0.6-1.7,1.7-1.6,2.8c0.1,1.1,0.9,2.2,2.2,2.5
							c1.4,0.3,2.4,0.2,3.3,0.1c0.9-0.1,1.8-0.2,2.9-0.2c-0.9-0.7-1.3-1.4-1.6-2.1C130.5,188.5,130.3,187.7,130.1,186.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M128.5,190c0.4-0.3,0.6-0.7,0.8-1.1c0.1-0.5,0.1-1,0-1.6c-0.1-0.5-0.4-1.1-0.9-1.4c-0.4-0.4-1-0.6-1.6-0.7
							c-0.6-0.1-1.2-0.1-1.7,0c-0.5,0.1-1,0.2-1.4,0.4c-0.4,0.2-0.7,0.6-0.9,1c-0.2,0.5-0.3,1.1-0.2,1.9c0.1,0.8,0.3,1.5,0.6,2.1
							c0.3,0.6,0.6,1.1,0.9,1.5c0.7,0.8,1.5,1.6,2.7,2c-0.4-0.5-0.5-1-0.4-1.4c0-0.4,0.2-0.8,0.4-1.1c0.2-0.3,0.5-0.6,0.7-0.9
							C127.7,190.4,128.1,190.3,128.5,190z"
                      />
                    </g>
                    <g>
                      <path
                        d="M126.4,190.9c1.1-0.1,1.7-0.6,2.1-1.5c0.3-0.8,0.5-2,0-3.2c-0.4-1.1-1.4-1.9-2.6-2c-1.2-0.1-2.5,0.5-3.2,1.9
							c-0.7,1.4-0.9,2.4-1.1,3.4c-0.2,1-0.4,1.9-0.7,3.2c0.9-0.8,1.8-1.2,2.7-1.4C124.3,191.1,125.2,191,126.4,190.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M123,191c1.4,0.5,2.8,0.1,3.7-0.7c0.9-0.8,1.2-2,0.9-3.2c-0.3-1.2-1.2-2.1-2.1-2.5c-0.9-0.4-2-0.4-3,0.3
							c-1.1,0.7-1.9,1.3-2.7,1.8c-0.8,0.5-1.7,0.9-3.1,1.1c1.2,0.5,2.1,1,3,1.6C120.6,189.9,121.5,190.4,123,191z"
                      />
                    </g>
                    <g>
                      <path
                        d="M121.2,187.2c0.6,2.6,3.6,3.1,5.4,1.5c1.8-1.6,1.7-4.6-0.8-5.5c-2.6-1-3.9-1-6.1-2.3
							C120.7,183.2,120.5,184.5,121.2,187.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M121.9,183.6c-1.1,2.2,0.8,4.3,3.2,4.2c2.4-0.1,4.2-2.4,2.8-4.5c-1.4-2.2-2.4-3-3.3-5.2
							C124.1,180.4,123.1,181.3,121.9,183.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M124.6,182.2c-0.9,0.7-1.1,1.7-0.8,2.5c0.4,0.9,1.3,1.6,2.4,2c1.2,0.3,2.3,0.2,3.1-0.2c0.8-0.4,1.2-1.1,0.9-2.1
							c-0.5-2-1.2-3.1-0.9-5.1C127.8,180.7,126.5,180.9,124.6,182.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M161,194.9c3.3,2.5,6.7,4,9.9,4.5c3.2,0.5,6.1,0.1,7.6-1.9c1.5-1.9,0.9-5.1-1.5-8c-2.4-2.9-6.8-5.5-11.7-6
							c-5.1-0.5-8.5-0.1-11.9,0.2c-3.4,0.4-6.7,0.8-11.3,1.5c4.6,0.9,7.6,2.2,10.3,3.7C155,190.5,157.6,192.3,161,194.9z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M353.7,131.6c-6.1,1.4-12.7,4.3-17.8,8c-2.6,1.8-4.9,3.9-7,6c-2,2.1-3.7,4.3-5.1,6.4c-2.8,4.3-4.2,8.4-4.6,11.5
							c-0.2,1.6-0.1,2.9,0.2,3.9c0.3,1,0.9,1.8,1.6,2.2c0.8,0.4,1.7,0.6,2.7,0.4c1-0.2,2.2-0.6,3.5-1.2c2.5-1.2,5.5-3.2,8.8-5.5
							c1.6-1.1,3.4-2.3,5.2-3.6c1.8-1.2,3.8-2.5,5.8-3.8c4-2.7,8.2-5.1,13.1-8c5-3,9.8-5.5,14-8.2c4.2-2.6,8-5.4,11.3-8.5
							c1.7-1.5,3.3-3.1,4.8-4.8c1.6-1.7,3.1-3.5,4.6-5.5c3-3.9,6-8.5,8.6-14.3c-3.6,5.3-7.4,9-11.1,11.8c-1.9,1.4-3.7,2.6-5.5,3.7
							c-1.8,1.1-3.7,2-5.5,2.7c-3.7,1.6-7.5,2.6-11.9,3.5C365,129.4,360,130.1,353.7,131.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M362.6,150.6c-5,2.2-10,4.1-14.7,5.9c-4.7,1.7-9.2,3.3-13.1,4.7c-4,1.4-7.4,2.8-9.9,4.2c-2.5,1.4-4.1,2.9-4.3,4.7
							c-0.1,1.7,1.3,3.5,4.2,5c2.8,1.5,7.1,2.5,12.3,2.7c5.2,0.2,11.2-0.6,17.4-2.6c6.1-2.1,12.3-5.3,17.6-9.8
							c5.4-4.6,9.3-9,12.4-13.3c3.1-4.3,5.4-8.3,7.5-12.4c2-4.1,3.8-8.4,5.4-13.2c1.6-4.8,3-10.2,4-16.7c-2,6.2-4.5,11.1-7.2,15.2
							c-2.6,4.1-5.4,7.4-8.4,10.3c-3,3-6.2,5.6-10,8.1C372,145.9,367.8,148.3,362.6,150.6z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M401.3,173.5c-6-1.9-13.1-2.8-19.4-2.2c-3.2,0.3-6.2,0.8-9,1.6c-2.8,0.8-5.4,1.8-7.7,2.9c-4.6,2.3-7.9,5-9.8,7.5
							c-1,1.2-1.6,2.4-1.8,3.5c-0.3,1.1-0.2,2,0.3,2.7c0.4,0.8,1.2,1.3,2.2,1.7c1,0.4,2.2,0.6,3.6,0.8c2.8,0.2,6.3,0.1,10.3-0.2
							c2-0.1,4.1-0.3,6.4-0.4c2.2-0.1,4.5-0.3,6.9-0.4c4.8-0.2,9.7-0.2,15.4-0.2c5.9,0,11.2,0.3,16.2,0.1c5-0.1,9.7-0.6,14.1-1.5
							c2.2-0.4,4.4-1,6.6-1.7c2.2-0.7,4.4-1.4,6.7-2.4c4.6-1.9,9.5-4.3,14.7-7.9c-5.8,2.7-10.9,3.9-15.6,4.5
							c-2.3,0.3-4.5,0.4-6.7,0.3c-2.1,0-4.2-0.2-6.2-0.5c-4-0.5-7.8-1.6-12-3C412.2,177.4,407.6,175.5,401.3,173.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M399.3,194.3c-5.4-0.7-10.7-1.6-15.6-2.5c-4.9-0.9-9.6-1.9-13.7-2.7c-4.1-0.8-7.8-1.4-10.7-1.5
							c-2.9-0.1-5.1,0.4-6.1,1.9c-1,1.4-0.6,3.7,1.1,6.4c1.7,2.7,4.8,5.8,9.2,8.6c4.3,2.8,9.9,5.3,16.3,6.7
							c6.3,1.4,13.3,1.8,20.1,0.6c7-1.1,12.6-3,17.5-5c4.8-2.1,8.9-4.4,12.8-6.9c3.9-2.5,7.6-5.3,11.4-8.6c3.8-3.3,7.8-7.3,12-12.3
							c-4.9,4.3-9.6,7.2-13.9,9.4c-4.4,2.2-8.4,3.5-12.5,4.6c-4.1,1-8.2,1.6-12.7,1.9C409.8,195.2,404.9,195.1,399.3,194.3z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M143.2,295.9c4-4.8,7.5-11.1,9.3-17.2c0.9-3.1,1.6-6.1,1.9-9c0.3-2.9,0.3-5.7,0.2-8.2c-0.4-5.1-1.7-9.2-3.3-11.9
							c-0.8-1.4-1.7-2.4-2.6-3c-0.9-0.6-1.8-0.9-2.6-0.8c-0.9,0.1-1.7,0.6-2.4,1.3c-0.7,0.8-1.4,1.8-2,3.1c-1.3,2.5-2.4,5.9-3.7,9.7
							c-0.6,1.9-1.3,3.9-2,6.1c-0.7,2.1-1.4,4.3-2.2,6.6c-1.6,4.6-3.4,9-5.5,14.3c-2.2,5.4-4.4,10.3-6.2,15
							c-1.7,4.7-3.1,9.2-3.8,13.6c-0.4,2.2-0.7,4.5-0.9,6.8c-0.2,2.3-0.3,4.7-0.3,7.1c0,4.9,0.4,10.4,1.8,16.6
							c-0.4-6.3,0.4-11.6,1.6-16.1c0.6-2.3,1.3-4.4,2.2-6.3c0.8-2,1.7-3.8,2.7-5.5c2-3.5,4.4-6.6,7.3-10
							C135.6,304.6,139.1,300.9,143.2,295.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M124.7,286.2c2.7-4.8,5.5-9.3,8.1-13.6c2.7-4.3,5.3-8.2,7.6-11.7c2.3-3.5,4.2-6.7,5.4-9.3c1.2-2.6,1.5-4.9,0.5-6.3
							c-1-1.5-3.2-2-6.4-1.4c-3.2,0.6-7.2,2.3-11.4,5.3c-4.2,3-8.6,7.3-12.2,12.6c-3.6,5.4-6.6,11.7-8.1,18.4
							c-1.5,6.9-1.9,12.8-1.8,18.1c0.1,5.3,0.7,9.9,1.6,14.4c0.9,4.5,2.1,9,3.7,13.8c1.7,4.8,3.8,9.9,7,15.7
							c-2.2-6.2-3.2-11.6-3.5-16.4c-0.4-4.8-0.2-9.2,0.4-13.3c0.6-4.2,1.6-8.2,3-12.5C120,295.7,121.9,291.1,124.7,286.2z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M453.4,473.1c-7.8,2.4-14.6,6.2-19.4,10.2c-4.8,4-7.6,8.2-6.4,11.6c1.2,3.3,6.3,4.8,13,3.8c6.7-1,14.9-4.4,21.5-10.4
							c6.8-6.1,10.9-10.9,14.9-15.6c4-4.7,8-9.4,14-16.1c-7.3,5.1-12.9,7.7-18.4,9.9C467.1,468.8,461.4,470.6,453.4,473.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M447.9,453.4c-7.2-0.7-14.1,0.2-19.4,2c-5.3,1.8-9.1,4.7-9.3,8.2c-0.2,3.5,3.5,6.8,9.5,8.5c6,1.7,14.2,1.6,21.8-1.4
							c7.8-3.1,12.7-6,17.6-8.9c4.9-2.9,9.7-5.8,16.7-9.9c-7.8,2.1-13.3,2.5-18.7,2.5C460.7,454.5,455.3,454.1,447.9,453.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M455.3,433.2c-4.7-2.7-9.5-3.7-13.7-3.3c-4.2,0.4-7.9,2.2-9.3,5.4c-1.4,3.2,0.1,7.2,3.8,10.4
							c3.7,3.1,9.6,5.3,15.9,4.7c6.4-0.7,10.7-1.9,14.9-3.1c4.2-1.2,8.3-2.4,14.1-3.8c-6-0.7-9.9-2.1-13.6-3.8
							C463.7,438,460.2,435.9,455.3,433.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M473.5,412.7c-2.2-4.4-5.7-7.2-9.4-8.4c-3.7-1.2-7.5-0.8-10.1,1.7c-2.6,2.4-3.1,6.3-1.8,10.2c1.4,3.9,4.7,7.6,9.6,9.6
							c5,2,8.7,2.9,12.3,3.9c3.6,1,7.1,2.1,11.8,4.3c-3.4-3.8-5.3-7-7-10.2C477.3,420.6,475.8,417.2,473.5,412.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M492.7,401.5c0.7-5.1-0.3-9.7-2.2-13.3c-1.9-3.6-4.9-6.3-8.4-6.4c-3.5-0.1-6.7,2.6-8.3,7.2
							c-1.5,4.5-1.3,10.8,1.8,16.3c3.2,5.6,6.1,8.9,8.9,12.1c2.8,3.2,5.6,6.3,9.4,10.8c-1.9-5.5-2.3-9.6-2.3-13.6
							C491.6,410.7,492,406.8,492.7,401.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M517.1,405c3.5-3.9,5.5-8,6.4-12c0.2-1,0.4-2,0.4-3c0-1,0-1.9-0.1-2.8c-0.3-1.8-1-3.5-2.2-4.7
							c-1.3-1.2-2.9-1.9-4.9-1.8c-1,0-2.1,0.2-3.1,0.6c-1.1,0.4-2.2,0.9-3.3,1.6c-4.4,2.8-8.5,8.7-9.3,15.5
							c-0.8,7-0.1,11.7,0.6,16.3c0.8,4.5,1.7,8.9,3.2,15c0.6-6.2,2.3-10.2,4.3-13.8C511,412.3,513.5,409,517.1,405z"
                      />
                    </g>
                    <g>
                      <path
                        d="M537.4,423.9c5.7-1.6,10.3-4.3,13.5-7.6c3.2-3.2,5.1-7.1,4.1-10.4c-1-3.3-5.1-5.3-10.5-5c-5.4,0.3-11.9,3.1-16.6,8.4
							c-4.8,5.4-7.3,9.6-9.8,13.8c-2.3,4.1-4.7,8.2-8.1,14.1c5-4.5,9-6.7,13.1-8.4C527.3,426.9,531.5,425.6,537.4,423.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M547.6,445.1c6,1,11.5,0.6,16-0.7c1.1-0.4,2.2-0.7,3.2-1.2c1-0.5,1.9-1,2.7-1.6c1.6-1.2,2.7-2.6,3.1-4.4
							c0.4-1.7-0.1-3.5-1.4-5.2c-0.6-0.8-1.4-1.7-2.5-2.4c-1-0.7-2.2-1.5-3.5-2c-5.2-2.3-13-2.8-20,0c-7.2,2.9-11.5,5.9-15.7,8.8
							c-4.1,3-8.1,6.1-13.5,10.5c6.6-2.7,11.4-3.3,16-3.4C536.8,443.6,541.4,444.1,547.6,445.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M543.7,464.2c4.4,3.5,8.1,5.9,11.5,7.7c1.7,0.9,3.5,1.7,5.2,2.3c1.7,0.6,3.5,1,5.3,0.6c1.7-0.4,3.1-1.7,3.8-4
							c0.7-2.2,0.6-5.4-0.8-8.8c-1.3-3.3-3.9-6.8-7.2-9.3c-3.4-2.6-7.5-4.4-11.9-5c-4.5-0.6-8.1-0.4-11.2,0
							c-3.1,0.4-5.8,1.1-8.4,1.8c-5.2,1.4-10.1,3.1-17.4,4.9c7.5,0.3,12.6,1.2,17.3,2.7c2.3,0.8,4.6,1.7,6.9,2.8
							C539.1,461,541.5,462.4,543.7,464.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M528.8,484.6c1,5.4,2.5,9.5,4.4,13.1c1,1.8,2.1,3.4,3.3,4.8c1.2,1.4,2.7,2.5,4.4,2.9c1.7,0.4,3.5,0,5.2-1.5
							c1.7-1.4,3.1-3.9,3.9-7c0.7-3.1,0.7-6.9-0.2-10.6c-1-3.7-2.9-7.4-5.6-10.3c-2.8-3.1-5.4-5.1-7.9-6.8c-2.5-1.7-4.7-2.9-7-4.1
							c-4.6-2.3-9.1-4.3-15.4-7.3c5.1,4.8,8,8.7,10.2,12.8c1.1,2,2,4.1,2.8,6.4C527.6,479.3,528.3,481.8,528.8,484.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M510.1,492.7c-1.4,5.7-1.4,11-0.4,15.5c0.3,1.1,0.5,2.2,0.9,3.2c0.4,1,0.8,1.9,1.3,2.7c1,1.6,2.4,2.9,4.1,3.4
							c1.7,0.5,3.5,0.2,5.3-0.9c0.9-0.5,1.8-1.3,2.6-2.2c0.8-0.9,1.6-2,2.3-3.3c2.7-5,3.8-12.7,1.5-19.8c-2.4-7.3-5.2-11.7-7.9-15.9
							c-2.8-4.2-5.7-8.2-10-13.6c2.4,6.5,2.8,11.3,2.7,15.8C512.2,482.3,511.5,486.8,510.1,492.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M488.5,496c-3.6,4.8-5.9,9-7.3,13c-0.7,2-1.3,4-1.5,5.9c-0.2,1.9-0.1,3.7,0.8,5.3c0.9,1.5,2.5,2.5,4.7,2.7
							c2.2,0.2,5.1-0.5,7.9-2.1c2.8-1.6,5.7-4.3,7.8-7.6c2.1-3.3,3.6-7.2,4.1-11.2c0.5-4.2,0.4-7.6,0.2-10.6c-0.3-3-0.7-5.6-1.1-8.2
							c-0.9-5.1-1.9-10.1-2.8-17.4c-1.3,7.2-2.8,12.1-4.8,16.5c-1,2.2-2.1,4.4-3.4,6.7C491.8,491.2,490.3,493.6,488.5,496z"
                      />
                    </g>
                    <g>
                      <path
                        d="M505.9,450.8c0.9-3.4-2.4-5.6-5.8-4.7c-3.4,0.9-5.2,4.5-2.7,7c2.6,2.5,4.3,3.3,6.2,6.1
							C503.8,455.8,505,454.3,505.9,450.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M501.8,454.2c1.2-1.2,1.4-2.6,0.9-3.9c-0.5-1.3-1.7-2.5-3.4-3.1c-1.7-0.6-3.4-0.4-4.6,0.6c-1.2,0.9-1.9,2.6-1.4,4.4
							c0.5,1.9,1.1,3.2,1.6,4.5c0.5,1.3,1,2.5,1.4,4.2c0.7-1.6,1.5-2.6,2.4-3.6C499.5,456.3,500.5,455.4,501.8,454.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M498,453.2c1.3,0.3,2.1-0.1,2.7-1.2c0.6-1,1-2.6,0.8-4.4c-0.2-1.7-1.2-3.2-2.7-3.8c-1.5-0.6-3.4-0.2-4.6,1.4
							c-1.2,1.6-1.7,3-2.2,4.2c-0.4,1.3-0.8,2.4-1.5,3.9c1.4-0.8,2.6-0.9,3.7-0.9C495.5,452.6,496.6,452.9,498,453.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M494.8,449.1c0.1,0.7,0.5,1.2,1,1.7c0.5,0.4,1.3,0.8,2.1,0.9c0.8,0.1,1.7,0.1,2.4-0.3c0.8-0.3,1.4-0.9,1.9-1.6
							c0.5-0.7,0.8-1.5,1.1-2.2c0.2-0.7,0.3-1.4,0.3-2.1c-0.1-0.7-0.3-1.3-0.8-1.8c-0.5-0.6-1.3-1.1-2.4-1.4
							c-1.1-0.3-2.2-0.5-3.1-0.5c-0.9,0-1.7,0.1-2.5,0.3c-1.5,0.4-3,0.9-4.2,2.3c0.8-0.2,1.5,0,2.1,0.3c0.5,0.3,0.9,0.7,1.2,1.2
							c0.3,0.5,0.5,1,0.7,1.5C494.6,447.8,494.6,448.4,494.8,449.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M494.8,445.7c-0.6,1.5-0.3,2.6,0.6,3.6c0.9,1,2.4,1.8,4.1,2c1.7,0.2,3.4-0.6,4.2-2.1c0.9-1.5,0.9-3.7-0.5-5.4
							c-1.4-1.8-2.6-2.7-3.8-3.6c-1.2-0.9-2.3-1.7-3.7-2.8c0.5,1.8,0.5,3.1,0.2,4.4C495.9,443,495.4,444.2,494.8,445.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M496.9,441.3c-1.5,1.5-1.9,3.6-1.4,5.2c0.5,1.6,1.9,2.8,3.6,3.1c1.7,0.3,3.4-0.2,4.5-1.2c1.1-1,1.7-2.3,1.5-4.1
							c-0.3-1.8-0.6-3.2-0.7-4.6c-0.1-1.4-0.1-2.8,0.5-4.7c-1.5,1.3-2.6,2.1-3.9,3C499.7,438.9,498.4,439.7,496.9,441.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M502.9,441.2c-3.7-0.8-6.3,2.7-5.3,6.1c1,3.4,5,5,7.7,2.3c2.8-2.8,3.6-4.5,6.8-6.6C508.4,443.1,506.8,442,502.9,441.2
							z"
                      />
                    </g>
                    <g>
                      <path
                        d="M507.1,444.4c-2.2-2.9-6.2-1.6-7.5,1.7c-1.3,3.3,0.6,6.9,4.2,6.5c3.7-0.5,5.4-1.4,8.9-1.1
							C510,449.2,509.4,447.3,507.1,444.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M507.3,448.7c-0.3-1.6-1.5-2.5-2.9-2.6c-1.4-0.1-2.9,0.6-4.1,2c-1.2,1.3-1.7,2.9-1.7,4.1c0.1,1.3,0.7,2.2,2.2,2.4
							c3,0.6,4.8,0.4,7.3,1.9C507.4,453.8,507.9,452.1,507.3,448.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M468.5,488.6c-5.3,2.7-9.3,6.4-11.9,10.2c-2.6,3.8-3.8,7.9-2.1,11.1c1.6,3.1,6.1,4.3,11.4,2.9
							c5.3-1.4,11.3-5.5,15-11.7c3.7-6.3,5.4-11.1,7-15.7c1.5-4.7,3-9.2,5-15.7c-3.9,5.5-7.5,8.6-11.2,11.3
							C477.9,483.5,474,485.7,468.5,488.6z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M426.3,566.6c5.8-2.4,11.9-6.2,16.3-10.7c2.3-2.2,4.3-4.6,5.9-7c1.7-2.4,3-4.8,4-7.2c2.1-4.7,2.9-8.9,2.7-12.1
							c-0.1-1.6-0.4-2.9-0.8-3.9c-0.5-1-1.1-1.6-2-1.9c-0.8-0.3-1.8-0.3-2.8,0c-1,0.3-2.1,0.9-3.2,1.7c-2.3,1.6-4.9,4-7.8,6.8
							c-1.4,1.4-3,2.8-4.6,4.4c-1.6,1.5-3.3,3.1-5.1,4.7c-3.5,3.3-7.3,6.4-11.7,10c-4.5,3.7-8.8,7-12.5,10.3
							c-3.8,3.3-7,6.6-9.9,10.2c-1.4,1.8-2.7,3.6-4,5.5c-1.3,1.9-2.5,4-3.7,6.1c-2.3,4.4-4.5,9.3-6.2,15.5c2.7-5.8,5.9-10,9.1-13.4
							c1.6-1.7,3.2-3.2,4.9-4.5c1.6-1.3,3.3-2.5,5-3.6c3.4-2.1,7-3.8,11.2-5.4C415.5,570.6,420.3,569,426.3,566.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M414.5,549.3c4.6-2.9,9.2-5.6,13.6-8.1c4.4-2.5,8.6-4.7,12.2-6.7c3.7-2,6.9-3.9,9.1-5.7c2.3-1.8,3.6-3.6,3.5-5.3
							c-0.2-1.7-1.9-3.3-4.9-4.3c-3-1-7.4-1.4-12.6-0.7c-5.1,0.6-11,2.3-16.8,5.3c-5.7,3-11.4,7.2-15.8,12.4
							c-4.6,5.4-7.8,10.4-10.2,15.1c-2.4,4.7-4.1,9.1-5.4,13.5c-1.4,4.4-2.4,8.9-3.2,13.9c-0.8,5-1.3,10.6-1.3,17.1
							c1-6.5,2.7-11.7,4.7-16.1c2-4.4,4.2-8.1,6.7-11.5c2.5-3.4,5.3-6.5,8.5-9.6C405.9,555.4,409.8,552.3,414.5,549.3z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M372.6,532.7c6.2,0.9,13.4,0.7,19.5-0.9c3.1-0.8,6-1.8,8.7-3c2.7-1.2,5-2.6,7.1-4.1c4.2-3,7-6.2,8.5-9
							c0.7-1.4,1.2-2.7,1.2-3.7c0.1-1.1-0.1-2-0.7-2.7c-0.5-0.7-1.4-1.1-2.4-1.4c-1-0.2-2.3-0.3-3.7-0.2c-2.8,0.2-6.3,0.9-10.2,1.8
							c-1.9,0.4-4,0.9-6.2,1.4c-2.2,0.5-4.4,1-6.8,1.5c-4.7,1-9.5,1.8-15.1,2.6c-5.8,0.9-11.1,1.5-16,2.4c-4.9,0.9-9.4,2.1-13.7,3.7
							c-2.1,0.8-4.2,1.7-6.3,2.7c-2.1,1-4.2,2.1-6.3,3.4c-4.2,2.6-8.7,5.7-13.3,10.1c5.3-3.6,10.2-5.6,14.7-6.9
							c2.3-0.6,4.4-1.1,6.5-1.4c2.1-0.3,4.1-0.5,6.2-0.5c4-0.1,7.9,0.3,12.4,1.1C361.2,530.7,366.2,531.8,372.6,532.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M371.3,511.9c5.5-0.2,10.8-0.1,15.8,0c5,0.1,9.8,0.3,14,0.5c4.2,0.1,7.9,0.1,10.8-0.2c2.9-0.4,4.9-1.2,5.7-2.8
							c0.7-1.6,0.1-3.8-2.1-6.2c-2.1-2.4-5.7-5-10.4-7.1c-4.7-2.1-10.7-3.6-17.1-4c-6.5-0.3-13.4,0.4-20,2.6
							c-6.7,2.2-12,4.9-16.5,7.7c-4.4,2.8-8.1,5.7-11.6,8.8c-3.4,3.1-6.6,6.4-9.9,10.3c-3.2,3.9-6.6,8.4-9.8,14
							c4.2-5,8.3-8.7,12.2-11.5c4-2.8,7.8-4.8,11.6-6.5c3.9-1.6,7.8-2.9,12.2-3.9C360.8,512.7,365.7,512,371.3,511.9z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M514.4,139c10.6-1.1,21.3-3.7,30.7-7.7c9.4-3.9,17.6-9.3,23.7-14.8c6.1-5.5,10.2-11.1,12.5-15.3
							c2.3-4.2,2.9-6.9,2.1-7.4c-1.6-1-7.7,7.5-19.9,16.2c-6.1,4.3-13.6,8.7-22.2,12.2c-8.6,3.5-18.2,6.3-28.2,8.1
							c-10.2,1.9-19.1,2.6-27.2,3.2c-8.1,0.6-15.5,1.1-23,1.9c-7.4,0.8-14.9,1.9-23.1,4.1c-4.1,1.1-8.3,2.5-12.7,4.4
							c-4.4,1.9-8.9,4.3-13.4,7.6c9.3-6,18.4-8.6,26.5-10.2c8.2-1.5,15.6-1.9,22.9-2c7.4-0.1,14.7,0.2,22.9,0.5
							C494.2,139.9,503.4,140.1,514.4,139z"
                      />
                    </g>
                    <g>
                      <path
                        d="M458.8,128.6c2.7-1.5,4.5-4,5.1-6.3c0.6-2.3,0-4.4-1.5-5.3c-1.5-0.9-3.5-0.6-5.3,0.4c-1.8,1-3.5,2.7-4.6,5.2
							c-1.1,2.5-2,4.3-3,6c-1,1.7-2.2,3.4-4.4,5.4c2.8-1.1,4.7-1.7,6.8-2.4C454,130.8,456,130.1,458.8,128.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M475.9,123.1c2.3-1.3,3.6-3.6,3.8-5.6c0.2-2.1-0.6-3.8-2.1-4.7c-1.6-0.8-3.4-0.6-5,0.2c-1.6,0.8-2.9,2.3-3.5,4.4
							c-0.7,2.2-1.1,3.8-1.7,5.4c-0.6,1.6-1.4,3.1-3,5c2.3-1,3.9-1.6,5.6-2.2C471.8,125,473.6,124.4,475.9,123.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M493,119.2c2.2-1.6,3.5-4,3.7-6.1c0.2-2.1-0.5-4-2.1-4.8c-1.5-0.8-3.5-0.5-5.1,0.5c-1.7,1.1-3.1,2.9-3.7,5.3
							c-0.6,2.5-1,4.3-1.5,6.1c-0.5,1.8-1.2,3.6-2.5,5.8c2.2-1.6,3.8-2.5,5.5-3.5C488.9,121.7,490.7,120.8,493,119.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M512.9,117.7c2.4-2.2,3.5-5.3,3.4-7.8c-0.1-2.5-1.3-4.3-3-4.6c-1.7-0.4-3.5,0.6-4.9,2c-1.4,1.4-2.5,3.4-2.9,5.9
							c-0.5,2.6-1,4.5-1.6,6.3c-0.7,1.8-1.6,3.7-3.7,5.8c2.7-1.3,4.5-2.3,6.5-3.3C508.4,121,510.4,119.9,512.9,117.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M529.5,112.5c2.1-2.5,3.1-5.6,2.9-8.1c-0.1-2.5-1.2-4.4-2.9-4.8c-1.7-0.5-3.6,0.5-5,2.2c-1.5,1.7-2.6,4.1-3,7
							c-0.4,3-0.6,5.1-1,7.2c-0.4,2.1-1,4.3-2.4,7.1c2.3-2.2,4-3.7,5.7-5.2C525.6,116.6,527.3,115.1,529.5,112.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M541.5,108.7c2-2,2.9-4.7,2.9-7c-0.1-2.2-1.2-4-2.8-4.5c-1.7-0.5-3.5,0.2-5,1.5c-1.5,1.4-2.6,3.4-2.9,6
							c-0.3,2.6-0.5,4.5-0.8,6.4c-0.3,1.9-0.8,3.8-2,6.3c2.1-1.9,3.6-3.1,5.2-4.3C537.8,112,539.4,110.8,541.5,108.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M558.6,100c2.1-2.3,3.1-5.3,3-7.7c0-2.4-1.1-4.3-2.7-4.9c-1.7-0.5-3.6,0.3-5.1,1.9c-1.5,1.6-2.7,4-3.1,6.8
							c-0.4,2.9-0.6,5-0.9,7.1c-0.4,2.1-0.9,4.2-2.2,7c2.2-2.2,3.8-3.7,5.5-5.1C554.7,103.8,556.5,102.4,558.6,100z"
                      />
                    </g>
                    <g>
                      <path
                        d="M573,93.6c2.2-1.8,3.6-4.1,4.1-6.2c0.5-2.2,0.2-4.2-1.2-5.3c-1.4-1.1-3.5-0.9-5.5,0.3c-2,1.2-3.9,3.5-4.7,6.5
							c-0.8,3-1.2,5.2-1.5,7.3c-0.4,2.1-0.8,4.2-1.6,7.1c1.8-2.4,3.3-3.9,4.9-5.2C569,96.6,570.7,95.4,573,93.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M587.3,86.9c2.3-1.1,3.9-2.9,4.8-4.7c0.8-1.8,0.9-3.8-0.1-5.2c-1-1.4-3.1-1.9-5.2-1.2c-2.2,0.6-4.5,2.4-5.7,5.1
							c-1.3,2.7-1.9,4.7-2.5,6.7c-0.6,2-1.2,3.9-2.3,6.5c1.9-2,3.6-3.2,5.3-4.1C583.1,88.9,584.9,88.1,587.3,86.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M602.4,93.3c2.3,0.6,4.4,0.3,6.1-0.6c1.7-0.9,3-2.3,3.1-4.1c0.1-1.7-1-3.4-3.1-4.3c-2-0.9-4.8-1-7.4,0.3
							c-2.6,1.3-4.2,2.6-5.7,3.7c-1.5,1.2-3.1,2.4-5.3,3.9c2.6-0.5,4.5-0.5,6.3-0.2C598.3,92.3,600,92.7,602.4,93.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M602.3,107.3c2.1,1.2,4.2,1.5,6.1,1.3c1.9-0.3,3.6-1.1,4.4-2.7c0.8-1.6,0.2-3.6-1.6-5.2c-1.8-1.6-4.7-2.6-7.7-2.1
							c-3.1,0.5-5.1,1.4-7,2.2c-2,0.9-3.8,1.7-6.3,2.8c2.8-0.1,4.7,0.4,6.5,1C598.4,105.2,600.1,106.1,602.3,107.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M598.1,120.2c2.4,1.8,5,2.6,7.4,2.6c2.3,0,4.2-0.9,4.9-2.5c0.7-1.6-0.1-3.6-1.8-5.3c-1.7-1.6-4.4-2.9-7.5-3.1
							c-6.3-0.3-8.9,0.5-15.1-0.2C591.6,114.5,593.3,116.5,598.1,120.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M455.6,157.1c1.3,2.2,2.9,3.7,4.7,4.7c1.8,0.9,3.8,1.2,5.3,0.3c1.5-0.9,2.1-3,1.3-5.4c-0.7-2.4-2.8-5-5.7-6.3
							c-3-1.3-5.3-1.7-7.4-2.1c-2.2-0.4-4.3-0.7-7.2-1.1c2.6,1.4,4.1,2.9,5.4,4.4C453.2,153.1,454.3,154.8,455.6,157.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M472.5,157.1c1.1,2.4,2.4,4.3,3.9,5.8c1.5,1.5,3.1,2.7,4.9,2.5c0.9-0.1,1.6-0.6,2.2-1.6c0.6-0.9,0.9-2.2,0.8-3.8
							c-0.1-1.5-0.5-3.3-1.5-4.9c-0.9-1.6-2.3-3.2-4-4.3c-1.8-1.1-3.4-1.8-4.8-2.2c-1.5-0.4-2.8-0.7-4.1-0.8
							c-2.6-0.3-5.1-0.3-8.6,0.2c3.4,0.6,5.5,1.9,7.1,3.3C470,152.9,471.3,154.7,472.5,157.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M487.8,156.6c1.5,2.4,3.4,4.2,5.4,5.3c1.9,1.1,4,1.5,5.5,0.6c1.5-0.9,2-3.1,1.1-5.7c-0.9-2.6-3.1-5.4-6.4-6.9
							c-3.3-1.5-5.7-2.1-8.1-2.5c-2.4-0.5-4.7-0.9-8-1.4c3,1.5,4.7,3.1,6.1,4.7C484.9,152.4,486.2,154.1,487.8,156.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M506.5,159.3c2.5,2.3,5.7,3.4,8.3,3.4c2.6,0,4.6-1.1,5.1-2.7c0.5-1.7-0.5-3.6-2.2-5.1c-1.7-1.6-4.2-2.8-7.2-3.3
							c-3-0.5-5.2-0.9-7.4-1.4c-2.2-0.5-4.3-1.3-7.2-2.9c2.2,2.5,3.6,4.3,5.1,6.1C502.5,155.1,504,157,506.5,159.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M525.3,155.5c3.6,1.7,7.5,2.2,10.5,1.7c3-0.5,5.1-1.9,5.2-3.6c0.2-1.8-1.5-3.5-4.1-4.7c-2.6-1.2-6-2-9.7-2
							c-3.8,0.1-6.5,0.2-9.2,0.1c-2.7-0.1-5.5-0.3-9.4-1.3c3.5,1.9,5.9,3.4,8.3,4.9C519.2,152.2,521.6,153.7,525.3,155.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M549,150.1c6.4,2.5,13.2,0.2,13.4-3.3c0.2-3.5-6-6.6-12.5-5.4c-6.7,1.1-9.4,2.3-16.4,2.3
							C540.3,145.7,542.5,147.5,549,150.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M568.8,144.1c3.4,1.2,7.1,1.2,9.7,0.4c2.7-0.8,4.5-2.3,4.5-4.1c0-1.8-1.7-3.3-4.2-4.3c-2.5-1-5.8-1.4-9.2-0.8
							c-3.5,0.6-6,1.1-8.5,1.5c-2.5,0.4-5.1,0.6-8.8,0.4c3.5,1.2,5.9,2.3,8.2,3.4C562.9,141.7,565.2,142.8,568.8,144.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M580.9,135.9c1.6,1.6,3.5,2.2,5.3,2.2c1.8,0,3.5-0.8,4.4-2.3c0.9-1.5,0.7-3.5-0.5-5.1c-1.2-1.6-3.4-2.9-6.1-3
							c-2.7,0-4.6,0.3-6.4,0.5c-1.8,0.3-3.6,0.5-6,0.6c2.3,0.9,3.7,1.9,5,3C578.1,133,579.3,134.2,580.9,135.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M591.4,129.9c3.6,4.4,9.6,5,11.5,2c1.9-3-1.3-8.1-6.8-9.4c-5.7-1.4-8.2-1.2-13.6-3.3
							C586.8,123.1,587.7,125.4,591.4,129.9z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M191.4,346.1c2,10.5,5.5,20.9,10.2,29.9c4.7,9.1,10.8,16.7,16.8,22.3c6,5.6,11.9,9.2,16.3,11.1
							c4.4,1.9,7.2,2.3,7.5,1.5c0.9-1.7-8.1-7.1-17.8-18.4c-4.8-5.7-9.8-12.8-14-21.1c-4.2-8.3-7.8-17.6-10.5-27.4
							c-2.8-10-4.2-18.8-5.5-26.9c-1.3-8.1-2.4-15.4-3.8-22.7c-1.4-7.3-3.2-14.7-6-22.6c-1.4-4-3.2-8.1-5.4-12.3
							c-2.2-4.2-5.1-8.5-8.7-12.7c6.8,8.8,10.2,17.6,12.4,25.5c2.2,8,3.2,15.3,4,22.6c0.7,7.3,1,14.6,1.5,22.8
							C188.8,326.1,189.3,335.3,191.4,346.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M197,289.9c1.7,2.6,4.4,4.1,6.8,4.5c2.4,0.4,4.4-0.3,5.2-1.9c0.8-1.6,0.3-3.5-0.8-5.3c-1.2-1.7-3-3.3-5.5-4.1
							c-2.6-0.9-4.5-1.6-6.3-2.5c-1.8-0.9-3.6-1.9-5.7-3.9c1.3,2.7,2.1,4.6,3,6.6C194.4,285.3,195.3,287.3,197,289.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M204,306.5c1.5,2.2,3.9,3.3,5.9,3.4c2.1,0.1,3.8-0.9,4.5-2.5c0.7-1.6,0.3-3.5-0.6-4.9c-1-1.5-2.5-2.6-4.7-3.1
							c-2.2-0.5-3.9-0.8-5.6-1.3c-1.6-0.5-3.2-1.2-5.2-2.6c1.2,2.2,1.9,3.8,2.7,5.4C201.7,302.5,202.5,304.3,204,306.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M209.3,323.1c1.8,2.1,4.2,3.1,6.4,3.2c2.1,0.1,3.9-0.8,4.6-2.5c0.7-1.6,0.2-3.5-1-5.1c-1.2-1.6-3.1-2.8-5.6-3.2
							c-2.5-0.4-4.4-0.6-6.2-1c-1.8-0.4-3.6-0.9-6-2c1.7,2,2.8,3.6,3.9,5.2C206.4,319.3,207.5,321,209.3,323.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M212.5,342.8c2.4,2.2,5.6,3,8,2.7c2.5-0.3,4.1-1.7,4.3-3.4c0.2-1.8-0.9-3.4-2.4-4.7c-1.5-1.3-3.6-2.2-6.1-2.4
							c-2.6-0.3-4.5-0.6-6.4-1.1c-1.9-0.5-3.8-1.3-6.1-3.2c1.5,2.5,2.6,4.3,3.8,6.2C208.8,338.7,210.1,340.6,212.5,342.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M219.1,359c2.6,1.9,5.8,2.6,8.3,2.2c2.5-0.3,4.3-1.6,4.6-3.3c0.3-1.7-0.8-3.5-2.7-4.8c-1.8-1.3-4.4-2.3-7.3-2.4
							c-3-0.1-5.1-0.1-7.3-0.4c-2.2-0.2-4.3-0.6-7.3-1.8c2.4,2.1,4,3.6,5.6,5.2C214.7,355.4,216.3,357,219.1,359z"
                      />
                    </g>
                    <g>
                      <path
                        d="M223.9,370.7c2.2,1.9,5,2.5,7.2,2.2c2.2-0.3,3.9-1.5,4.3-3.2c0.4-1.7-0.5-3.5-2-4.8c-1.5-1.4-3.7-2.3-6.2-2.3
							c-2.6-0.1-4.6-0.1-6.5-0.2c-1.9-0.2-3.8-0.5-6.4-1.5c2,1.9,3.4,3.4,4.7,4.9C220.3,367.2,221.6,368.7,223.9,370.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M234,386.9c2.5,1.9,5.5,2.6,7.9,2.4c2.4-0.2,4.2-1.4,4.6-3.1c0.4-1.7-0.6-3.5-2.4-4.9c-1.8-1.4-4.2-2.4-7.1-2.5
							c-3-0.1-5.1-0.2-7.2-0.3c-2.1-0.2-4.2-0.5-7.2-1.6c2.4,2,4,3.5,5.6,5C229.9,383.4,231.4,385,234,386.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M241.6,400.7c2,2.1,4.3,3.2,6.5,3.5c2.2,0.3,4.2-0.2,5.2-1.7c1-1.5,0.6-3.6-0.8-5.5c-1.4-1.9-3.8-3.6-6.8-4.2
							c-3.1-0.6-5.3-0.7-7.4-0.9c-2.1-0.2-4.3-0.4-7.2-1c2.6,1.6,4.2,3,5.6,4.5C238.2,397,239.6,398.6,241.6,400.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M249.5,414.4c1.3,2.2,3.2,3.7,5.1,4.3c1.9,0.7,3.9,0.6,5.2-0.6c1.3-1.2,1.6-3.2,0.7-5.3c-0.8-2.1-2.8-4.2-5.5-5.3
							c-2.8-1.1-4.9-1.5-6.9-1.9c-2-0.4-3.9-0.9-6.6-1.7c2.2,1.8,3.5,3.3,4.6,4.9C247.1,410.4,248.1,412.1,249.5,414.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M244.4,430c-0.4,2.4,0.1,4.4,1.1,6c1,1.6,2.6,2.7,4.3,2.7c1.8,0,3.3-1.3,4-3.4c0.7-2.1,0.6-4.9-0.9-7.3
							c-1.5-2.5-2.9-4-4.2-5.4c-1.3-1.4-2.6-2.9-4.3-4.9c0.7,2.6,0.8,4.4,0.7,6.2C245.1,425.8,244.8,427.6,244.4,430z"
                      />
                    </g>
                    <g>
                      <path
                        d="M230.5,431.1c-1,2.2-1.2,4.3-0.7,6.2c0.4,1.9,1.4,3.5,3.1,4.2c1.6,0.6,3.6-0.1,5-2.1c1.4-1.9,2.2-4.9,1.4-7.9
							c-0.8-3-1.8-4.9-2.8-6.8c-1-1.9-2-3.7-3.3-6.1c0.3,2.8,0,4.7-0.4,6.6C232.2,427,231.5,428.8,230.5,431.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M217.3,428c-1.6,2.5-2.2,5.3-1.9,7.5c0.2,2.3,1.2,4.1,2.9,4.7c1.7,0.5,3.6-0.4,5.1-2.2c1.5-1.9,2.5-4.7,2.4-7.7
							c-0.3-6.3-1.2-8.8-1.1-15C222.4,421,220.5,422.8,217.3,428z"
                      />
                    </g>
                    <g>
                      <path
                        d="M168.4,289.1c-2.1,1.5-3.5,3.2-4.2,5.1c-0.7,1.9-0.9,3.9,0.2,5.3c1,1.4,3.2,1.8,5.5,0.9c2.3-0.9,4.7-3.2,5.8-6.3
							c1.1-3.2,1.3-5.4,1.5-7.6c0.2-2.2,0.3-4.3,0.4-7.3c-1.2,2.7-2.5,4.3-3.9,5.7C172.1,286.4,170.5,287.6,168.4,289.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M169.7,305.9c-2.3,1.3-4,2.7-5.5,4.3c-1.4,1.6-2.5,3.4-2.1,5.1c0.2,0.9,0.8,1.6,1.7,2c1,0.5,2.3,0.7,3.8,0.5
							c1.5-0.2,3.2-0.8,4.8-1.9c1.5-1.1,3-2.6,3.9-4.4c1-1.9,1.5-3.5,1.8-5c0.3-1.5,0.4-2.8,0.5-4.1c0-2.6-0.1-5.2-0.9-8.6
							c-0.3,3.5-1.4,5.6-2.7,7.4C173.7,303.1,172.1,304.6,169.7,305.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M171.5,321.1c-2.3,1.7-3.9,3.7-4.8,5.8c-0.9,2-1.2,4.1-0.1,5.5c1,1.4,3.3,1.7,5.8,0.6c2.5-1.1,5.1-3.6,6.3-6.9
							c1.2-3.4,1.6-5.9,1.8-8.3c0.3-2.4,0.5-4.7,0.7-8.1c-1.3,3.1-2.6,4.9-4.1,6.5C175.6,317.9,173.9,319.3,171.5,321.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M170.5,340.1c-2.1,2.7-2.9,6-2.6,8.5c0.2,2.6,1.4,4.5,3.2,4.8c1.7,0.3,3.5-0.8,4.9-2.6c1.4-1.9,2.4-4.4,2.7-7.4
							c0.3-3.1,0.4-5.3,0.8-7.5c0.4-2.2,0.9-4.4,2.3-7.4c-2.3,2.4-3.9,4-5.6,5.6C174.4,335.7,172.6,337.3,170.5,340.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M175.9,358.4c-1.4,3.7-1.5,7.7-0.8,10.6c0.7,3,2.3,4.9,4,4.9c1.8,0,3.3-1.8,4.3-4.5c1-2.7,1.5-6.2,1.1-9.9
							c-0.4-3.8-0.7-6.5-0.9-9.2c-0.2-2.7-0.2-5.5,0.4-9.4c-1.6,3.7-2.9,6.2-4.2,8.7C178.7,352.1,177.4,354.6,175.9,358.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M183.3,381.6c-1.9,6.6,0.9,13.2,4.4,13.1c3.5-0.1,6-6.5,4.4-12.9c-1.7-6.6-3.1-9.1-3.7-16.1
							C186.9,372.5,185.3,374.9,183.3,381.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M190.9,400.8c-0.9,3.5-0.6,7.1,0.4,9.7c1,2.6,2.7,4.3,4.5,4.1c1.8-0.1,3.2-2,3.9-4.5c0.8-2.6,0.9-5.9,0-9.3
							c-0.9-3.5-1.6-5.9-2.2-8.4c-0.6-2.5-1-5-1.2-8.7c-0.9,3.6-1.8,6-2.6,8.5C192.8,394.7,191.9,397.2,190.9,400.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M200.2,412.2c-1.4,1.7-1.9,3.7-1.8,5.5c0.2,1.8,1.1,3.4,2.7,4.2c1.6,0.8,3.5,0.4,5-1c1.5-1.3,2.6-3.7,2.5-6.3
							c-0.2-2.7-0.6-4.5-1.1-6.3c-0.4-1.8-0.8-3.5-1.1-6c-0.7,2.4-1.6,3.9-2.6,5.3C202.8,409.1,201.7,410.4,200.2,412.2z"
                      />
                    </g>
                    <g>
                      <path d="M207,422.2c-4,4-4.1,10-1,11.6c3.1,1.6,8-2,8.8-7.6c0.9-5.8,0.5-8.2,2.1-13.8C213.4,417,211.1,418.1,207,422.2z" />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M378.5,8.1c9.5-5,18.3-11.4,25.5-18.6c7.3-7.2,12.8-15.2,16.3-22.6c3.6-7.4,5.3-14.1,5.8-18.9c0.5-4.8,0-7.5-0.8-7.6
							c-1.9-0.3-4.3,9.8-12.3,22.5c-4,6.3-9.3,13.2-16,19.6c-6.6,6.5-14.6,12.7-23,18.1c-8.7,5.6-16.7,9.6-24,13.2
							c-7.3,3.6-14,6.8-20.6,10.4c-6.6,3.6-13.1,7.4-19.8,12.5c-3.4,2.5-6.8,5.4-10.1,8.8c-3.4,3.4-6.6,7.4-9.6,12
							c6.4-9.1,13.8-14.9,20.7-19.4c7-4.5,13.7-7.6,20.5-10.5c6.8-2.8,13.7-5.3,21.4-8.2C360.1,16.5,368.7,13.3,378.5,8.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M323.1,19.3c2-2.4,2.7-5.4,2.3-7.8c-0.3-2.4-1.6-4.1-3.3-4.4c-1.7-0.3-3.5,0.7-4.8,2.3c-1.3,1.6-2.2,3.9-2.3,6.5
							c-0.1,2.7-0.2,4.7-0.5,6.7c-0.3,2-0.8,4-2.1,6.6c2.2-2,3.7-3.4,5.4-4.8C319.4,23.2,321.1,21.8,323.1,19.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M336.9,7.8c1.6-2.1,2-4.7,1.5-6.6c-0.5-2-2-3.4-3.7-3.5c-1.8-0.2-3.4,0.7-4.5,2.1c-1.1,1.4-1.8,3.2-1.6,5.4
							c0.2,2.3,0.4,4,0.4,5.7c0,1.7-0.1,3.4-1,5.8c1.7-1.8,3-3,4.4-4.2C333.8,11.1,335.2,9.9,336.9,7.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M351.2-2.2c1.5-2.3,1.7-5,1.1-7c-0.6-2.1-2-3.5-3.7-3.7c-1.8-0.2-3.4,0.8-4.6,2.4c-1.1,1.6-1.8,3.8-1.4,6.3
							c0.4,2.6,0.7,4.4,0.9,6.3c0.2,1.9,0.3,3.7-0.1,6.4c1.4-2.3,2.6-3.8,3.8-5.3C348.4,1.6,349.7,0.1,351.2-2.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M369.1-11.1c1.4-2.9,1.2-6.2,0.2-8.4c-1-2.3-2.9-3.4-4.6-3.1c-1.7,0.3-3,1.8-3.8,3.7c-0.8,1.9-1,4.1-0.5,6.5
							c0.5,2.6,0.8,4.5,0.9,6.5c0,2-0.1,4-1.2,6.8c2-2.2,3.4-3.8,4.8-5.5C366.2-6.4,367.6-8.1,369.1-11.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M382.6-22.2c1-3.1,0.7-6.3-0.3-8.6c-1-2.3-2.8-3.6-4.5-3.4c-1.8,0.2-3.1,1.8-3.8,4c-0.7,2.1-0.9,4.8-0.1,7.6
							c0.8,2.9,1.4,5,1.8,7.1c0.4,2.1,0.7,4.3,0.4,7.5c1.3-2.9,2.3-4.9,3.3-6.9C380.4-16.9,381.5-19,382.6-22.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M392.3-30.2c1.1-2.7,1-5.5,0-7.5c-0.9-2-2.6-3.3-4.3-3.1c-1.8,0.1-3.2,1.5-4,3.3c-0.8,1.8-1.1,4.2-0.4,6.6
							c0.7,2.5,1.3,4.4,1.7,6.3c0.4,1.9,0.7,3.8,0.5,6.6c1.2-2.5,2.2-4.2,3.2-5.9C390-25.7,391.1-27.5,392.3-30.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M404.8-44.7c1.1-3,0.9-6-0.1-8.3c-1-2.2-2.6-3.6-4.4-3.5c-1.8,0.1-3.2,1.6-4,3.7c-0.8,2.1-1,4.7-0.3,7.5
							c0.8,2.9,1.4,4.9,1.8,7c0.4,2.1,0.8,4.2,0.6,7.3c1.2-2.9,2.1-4.8,3.1-6.8C402.6-39.7,403.7-41.7,404.8-44.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M415.7-56.1c1.4-2.5,1.8-5.1,1.4-7.3c-0.3-2.2-1.4-3.9-3.1-4.4c-1.7-0.5-3.6,0.4-5,2.3c-1.4,1.9-2.3,4.7-1.9,7.8
							c0.4,3.1,0.9,5.2,1.3,7.3c0.5,2.1,0.9,4.2,1.2,7.2c0.8-2.9,1.6-4.9,2.6-6.7C413.2-51.8,414.3-53.5,415.7-56.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M426.4-67.6c1.7-1.9,2.5-4.1,2.6-6.1c0.1-2-0.6-3.9-2.1-4.8c-1.5-0.9-3.5-0.6-5.3,0.9c-1.8,1.4-3.2,3.9-3.4,6.8
							c-0.2,3,0,5.1,0.2,7.1c0.2,2,0.3,4,0.3,6.9c1-2.6,2.1-4.3,3.3-5.8C423.3-64.2,424.7-65.6,426.4-67.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M442.9-67.4c2.4-0.3,4.2-1.4,5.4-2.9c1.2-1.4,1.9-3.2,1.3-4.9c-0.5-1.7-2.2-2.8-4.4-2.8c-2.2-0.1-4.8,0.9-6.7,3.1
							c-1.9,2.2-2.9,3.9-3.9,5.6c-1,1.7-2,3.3-3.4,5.6c2.2-1.5,4-2.1,5.7-2.6C438.6-66.8,440.4-67.1,442.9-67.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M448-54.4c2.4,0.3,4.5-0.2,6.2-1.1c1.7-1,3-2.4,3.1-4.2c0.1-1.7-1.2-3.4-3.4-4.2c-2.2-0.8-5.3-0.6-7.9,1
							c-2.7,1.7-4.2,3.2-5.7,4.6c-1.5,1.6-2.9,3-4.8,5c2.6-1.1,4.5-1.4,6.4-1.5C443.6-54.9,445.5-54.7,448-54.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M449-40.9c2.9,0.8,5.7,0.5,7.8-0.4c2.1-0.9,3.6-2.4,3.6-4.2c0-1.8-1.4-3.3-3.6-4.2c-2.2-0.9-5.2-1-8.1,0
							c-6,2.1-8,3.8-14,5.5C440.8-43.7,443.1-42.4,449-40.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M330.8,46.9c2,1.6,4.1,2.4,6.1,2.5c2,0.2,3.9-0.3,5-1.7c1-1.4,0.8-3.5-0.8-5.5c-1.5-1.9-4.4-3.6-7.7-3.6
							c-3.3-0.1-5.5,0.4-7.7,0.8c-2.1,0.5-4.2,1-7.1,1.7c3,0.3,4.9,1.1,6.6,2C327.1,44.2,328.7,45.3,330.8,46.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M346.5,40.6c1.9,1.8,3.8,3.1,5.8,3.9c1.9,0.8,3.9,1.4,5.5,0.5c0.8-0.4,1.3-1.2,1.4-2.3c0.2-1.1,0-2.4-0.7-3.8
							c-0.6-1.4-1.7-2.8-3.2-4c-1.5-1.2-3.3-2.1-5.3-2.4c-2.1-0.4-3.8-0.4-5.3-0.3c-1.5,0.1-2.8,0.4-4.1,0.8
							c-2.5,0.7-4.9,1.6-7.9,3.4c3.4-0.8,5.8-0.3,7.9,0.4C342.6,37.7,344.5,38.8,346.5,40.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M360.5,34.4c2.3,1.7,4.7,2.6,6.9,2.9c2.2,0.3,4.3-0.1,5.3-1.5c1-1.4,0.7-3.6-1.1-5.7c-1.8-2.1-4.9-3.8-8.5-4
							c-3.6-0.2-6.1,0.3-8.5,0.7c-2.4,0.5-4.7,1-7.9,1.7c3.3,0.3,5.5,1.1,7.4,2C356.2,31.5,358.1,32.7,360.5,34.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M378.8,29.8c3.2,1.2,6.5,1,8.9,0c2.4-1,3.8-2.7,3.7-4.4c-0.2-1.8-1.8-3.1-4-3.9c-2.2-0.8-5-1-7.9-0.4
							c-3,0.7-5.2,1.2-7.4,1.5c-2.2,0.3-4.5,0.4-7.8,0c3,1.5,5,2.6,7,3.7C373.5,27.4,375.6,28.6,378.8,29.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M394.8,19.2c4,0.3,7.8-0.8,10.4-2.4c2.6-1.6,4-3.6,3.5-5.3c-0.5-1.7-2.7-2.6-5.6-2.8c-2.9-0.2-6.4,0.4-9.8,1.9
							c-3.5,1.5-6,2.6-8.5,3.6c-2.5,1-5.2,1.8-9.2,2.4c4,0.5,6.7,0.9,9.5,1.4C387.9,18.4,390.7,19,394.8,19.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M414.8,5.3c6.9-0.1,12.4-4.8,11.2-8.1c-1.2-3.3-8-3.8-13.6-0.3c-5.8,3.6-7.8,5.6-14.3,8.3
							C405,4.5,407.7,5.3,414.8,5.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M430.8-7.7c3.7-0.2,7-1.5,9.2-3.3c2.2-1.7,3.3-3.8,2.6-5.5c-0.7-1.6-2.8-2.4-5.5-2.4c-2.7,0-5.9,0.9-8.8,2.7
							c-3,1.9-5.1,3.3-7.3,4.6c-2.2,1.3-4.5,2.5-8,3.7c3.7-0.2,6.3-0.1,8.9,0C424.5-7.7,427.1-7.5,430.8-7.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M439-19.9c2.1,0.9,4.1,0.8,5.8,0c1.6-0.7,3-2.1,3.3-3.8c0.3-1.7-0.7-3.5-2.4-4.5c-1.7-1.1-4.3-1.4-6.7-0.5
							c-2.5,1-4.1,2-5.7,2.9c-1.6,0.9-3.1,1.8-5.4,2.9c2.5,0,4.2,0.4,5.8,0.9C435.2-21.5,436.8-20.8,439-19.9z"
                      />
                    </g>
                    <g>
                      <path d="M446.5-29.3c5,2.7,10.8,1,11.4-2.5c0.6-3.5-4.3-7-9.9-6.2c-5.8,0.9-8,2-13.8,2C439.6-33.9,441.3-32.1,446.5-29.3z" />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M533.7,299.2c0.6-0.6,1.1-1.2,1.6-1.8c0.5-0.7,1-1.3,1.5-2c0.9-1.4,1.8-2.8,2.4-4.3c1.4-3,2.2-6.2,2.6-9.4
						c0.8-6.4-0.3-12.8-2.8-18.6c-1.2-2.9-3-5.7-5-8.1c-2-2.4-4.2-4.5-6.6-6.4c-4.8-3.6-10.2-6.1-15.6-7.6c-2.7-0.7-5.5-1.3-8.2-1.5
						c-2.7-0.3-5.5-0.3-8.1-0.2c-5.4,0.3-10.8,1.5-15.7,3.8c-5,2.3-9.4,5.8-12.6,10c-1.6,2.1-2.9,4.5-3.9,6.9
						c-0.9,2.4-1.5,5.1-1.6,7.7c-0.3,5.1,1,10,3.5,14.1c1.3,2,2.9,3.8,4.7,5.2c1.8,1.4,3.8,2.4,5.8,3.1c4,1.3,8,1.3,11.5,0.3
						c3.5-1,6.4-3.3,8-5.7c1.7-2.4,2.4-4.9,2.6-7c0.2-2.1-0.1-4-0.6-5.3c-0.5-1.4-1.2-2.3-1.7-2.8c-0.6-0.5-1-0.6-1.3-0.5
						c-0.6,0.3-0.4,1.7-0.7,3.8c-0.1,1-0.4,2.2-1.1,3.5c-0.6,1.2-1.5,2.6-2.7,3.6c-0.6,0.5-1.3,0.9-2,1.3c-0.7,0.3-1.6,0.5-2.5,0.6
						c-1.8,0.2-3.8-0.2-5.7-1.2c-0.9-0.5-1.8-1.1-2.5-1.9c-0.7-0.7-1.3-1.6-1.8-2.7c-1-2.1-1.4-4.7-1-7.3c0.2-1.3,0.6-2.5,1.2-3.8
						c0.6-1.2,1.4-2.4,2.4-3.6c1-1.1,2.1-2.2,3.4-3.1c1.3-0.9,2.7-1.7,4.3-2.3c6.2-2.5,14.7-2.8,22.4-0.6c3.8,1.1,7.5,2.8,10.6,5.1
						c1.5,1.2,3,2.5,4.2,3.9c0.6,0.7,1.1,1.5,1.7,2.2l0.7,1.2l0.6,1.3c1.6,3.4,2.4,7.4,2.2,11.1c-0.1,1.9-0.5,3.7-1.2,5.4
						c-0.3,0.9-0.7,1.6-1.2,2.4l-0.7,1.1c-0.3,0.4-0.5,0.7-0.9,1.1c-2.5,3-6.1,5.2-10.1,6.9c-2,0.9-4.1,1.6-6.1,2.2
						c-1.1,0.3-2.1,0.6-3.2,0.9c-1.1,0.3-2.1,0.6-3.1,0.8c-8.4,1.9-16.5,2.6-24.2,2.5c-7.7-0.1-15.1-1.1-22.2-2.7
						c-7.1-1.6-13.8-3.8-20.3-6.6c-13-5.7-25.3-13.9-36.8-25.5c-5.7-5.8-11.3-12.5-16.4-20.3c-5.2-7.7-9.9-16.7-13.9-26.8
						c3.7,10.3,8,19.4,12.8,27.5c4.8,8.1,10,15.2,15.6,21.4c11.1,12.5,23,21.8,36.3,28.8c6.6,3.5,13.5,6.4,20.9,8.7
						c7.4,2.3,15.2,3.9,23.5,4.7c8.4,0.8,17.3,0.7,26.8-0.7c1.2-0.1,2.4-0.4,3.6-0.6c1.2-0.2,2.4-0.5,3.6-0.7c2.5-0.6,5-1.2,7.6-2.1
						c2.6-0.9,5.2-2,7.8-3.5C528.7,303.5,531.3,301.6,533.7,299.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M454.1,257.7c3.8-5.9,5.1-13.2,3.9-20.1c-0.6-3.4-1.9-6.8-3.7-9.7c-1.8-2.9-4.1-5.5-6.7-7.6c-2.6-2.1-5.5-3.7-8.6-4.7
						c-3.1-1-6.3-1.5-9.5-1.3c-3.2,0.2-6.3,0.9-9.1,2.4c-0.7,0.3-1.4,0.8-2,1.2c-0.7,0.5-1.3,0.9-1.9,1.4c-1.2,1-2.2,2.1-3.1,3.3
						c-1.7,2.4-2.8,5-3.3,7.6c-0.5,2.6-0.3,5.3,0.4,7.7c0.8,2.5,2.1,4.4,3.6,5.7c1.5,1.4,3,2.3,4.5,2.9c1.5,0.6,2.9,0.8,4.1,0.8
						c0.6,0,1.2-0.1,1.7-0.2c0.5-0.1,0.9-0.3,1.3-0.4c0.8-0.4,1.3-0.8,1.5-1.2c0.3-0.4,0.4-0.7,0.3-1.1c-0.2-0.7-1-1-2-1.6
						c-1-0.6-2.2-1.3-3.3-2.6c-1.1-1.2-1.9-3.1-1.8-4.5c0.1-0.9,0.2-1.7,0.7-2.6c0.4-0.9,1.1-1.8,1.9-2.6c0.4-0.4,0.9-0.7,1.3-1
						c0.2-0.1,0.5-0.2,0.7-0.4c0.3-0.1,0.5-0.2,0.8-0.3c1.1-0.4,2.4-0.5,3.9-0.5c2.9,0.1,5.9,1.3,8.6,3.4c1.3,1.1,2.5,2.4,3.4,3.8
						c0.9,1.5,1.6,3,2,4.7c0.8,3.4,0.5,7.4-1.1,10.7c-1.6,3.5-4.6,6.3-7.9,8.3c-3.4,1.9-7,3-10.6,3.3c-3.7,0.4-7.3,0.1-10.9-0.6
						c-3.5-0.7-7-1.8-10.4-3.2c-3.4-1.4-6.6-3.1-9.9-5.2c-3.2-2.1-6.3-4.6-9.4-7.5c-3-2.9-6-6.3-8.7-10.3c-2.7-4-5.2-8.6-7.1-14
						c3.2,11,8.3,19.4,13.9,26.1c2.8,3.4,5.7,6.3,8.8,9c3.1,2.6,6.3,4.9,9.7,7c3.4,2.1,7,3.8,11,5.2c3.9,1.4,8.2,2.4,13,2.7
						c4.7,0.3,10-0.4,15.2-2.5c2.6-1,5.2-2.5,7.8-4.4C449.8,263.2,452.2,260.8,454.1,257.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M336.8,288.9c-1.6,6.2-2,12.8-1.5,18.8c0.5,6,2,11.6,3.9,16c1.9,4.5,4.2,7.8,6.2,9.9c2.1,2.1,3.9,2.9,5,2.3
						c1.2-0.6,1.7-2.5,1.6-5.1c0.1-2.7-0.4-6.2-0.8-10.4c-0.4-4.1-0.9-8.8-1.2-13.8c-0.3-5-0.4-10.4-0.3-15.8
						c0.1-5.6,0.8-10.4,1.7-14.7c0.9-4.3,2.2-8.3,3.4-12.5c1.2-4.1,2.4-8.3,3.2-13.2c0.4-2.4,0.6-5,0.6-7.8c0-2.8-0.4-5.7-1.3-8.7
						c0.5,3.1,0.5,6,0.1,8.6c-0.4,2.6-1.1,5-1.9,7.2c-1.6,4.4-3.7,8-5.9,11.6c-2.2,3.6-4.5,7.2-6.8,11.5
						C340.7,277.4,338.5,282.5,336.8,288.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M350.5,287.2c-2,4.9-4.1,10-5.6,15.3c-1.5,5.3-2.2,10.7-1.9,15.5c0.3,4.8,1.5,8.7,3,11.2c1.5,2.5,3.2,3.5,4.5,3.1
						c1.3-0.4,2-2,2.7-4.3c0.7-2.3,1.4-5.3,2.3-8.9c0.9-3.6,2.2-7.7,3.7-12.4c1.4-4.7,3-10.1,4-15.9c0.9-6,1.2-11.1,1.2-15.7
						c0-4.6-0.4-8.7-0.9-12.8c-0.5-4.1-1.2-8.1-2.1-12.5c-1-4.4-2.2-9.3-4.1-14.9c1.1,5.8,1.4,10.8,1.4,15.2c0,4.4-0.4,8.4-0.9,12.2
						c-0.6,3.8-1.4,7.6-2.5,11.6C354.1,277.9,352.6,282.2,350.5,287.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M393.3,330.8c-0.5,0.7-1,1.3-1.5,1.9c-0.5,0.6-1.1,1.1-1.6,1.6c-1.2,0.9-2.4,1.7-3.7,2.3c-1.3,0.6-2.5,0.8-3.7,0.9
						c-1.2,0.1-2.1,0-3.2-0.4c-2.1-0.6-3.9-2.2-4.8-3.6c-0.4-0.7-0.7-1.4-0.8-2.3c-0.1-0.9,0-1.8,0.2-2.8c0.5-1.9,1.6-3.7,2.7-4.9
						c1.2-1.2,2-1.6,2.4-2c0.3-0.1,0.8-0.1,1.2,0c0.4,0.1,0.8,0.3,1.1,0.5c0.7,0.4,1.1,1.1,1.7,1.4c0.3,0.1,0.7,0.1,1.1-0.1
						c0.4-0.3,0.8-0.8,1.1-1.6c0.2-0.8,0.2-2-0.2-3.1c-0.4-1.2-1.2-2.4-2.6-3.5c-1.6-1.1-3.4-1.5-5.4-1.5c-1.9,0-3.8,0.5-5.6,1.3
						c-3.5,1.6-6.7,4.5-9,8.7c-1.1,2.1-1.9,4.6-2.1,7.3c-0.2,2.7,0.2,5.8,1.4,8.5c1.1,2.8,2.9,5.2,5.1,7.2c2.2,2,4.8,3.6,7.7,4.6
						c2.9,1,6.3,1.4,9.3,1.1c3.1-0.3,6.1-1.2,8.7-2.6c2.7-1.4,5-3.2,7.1-5.3c1-1.1,1.9-2.2,2.8-3.4c0.8-1.2,1.5-2.4,2.1-3.7
						c2.5-5.3,3.2-10.4,3.2-14.9c0-4.6-0.8-8.7-1.8-12.3c-1-3.7-2.3-6.9-3.6-10c-1.3-3.1-2.7-6-4.2-8.9c-1.5-2.9-3-5.7-4.8-8.6
						c-1.7-2.9-3.5-5.8-5.6-8.7c-4.1-6-8.9-12.3-15.4-19.3c5.9,7.4,9.9,14.3,13.1,20.6c1.6,3.2,3,6.2,4.2,9.2c1.2,3,2.2,6,3.2,8.9
						c0.9,3,1.7,6,2.4,9c0.7,3.1,1.3,6.2,1.6,9.4c0.3,3.2,0.3,6.5-0.3,9.8C396.4,324.7,395.3,328,393.3,330.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M211.1,46.3c3.4-0.8,6.9-1,10.2-0.6c3.3,0.4,6.4,1.5,8.9,3.1c2.6,1.6,4.7,3.9,6.2,6.4c0.7,1.2,1.3,2.5,1.7,3.8
						c0.4,1.3,0.5,2.6,0.6,3.9c0.1,5.3-3,11-7.3,15.2c-1.1,1.1-2.2,2.1-3.4,2.9c-1.2,0.9-2.4,1.7-3.5,2.4c-2.4,1.4-4.7,2.1-6.6,2.3
						c-1,0.1-1.9,0-2.6-0.2c-0.8-0.2-1.6-0.5-2.3-1c-1.5-1-2.7-2.4-3.5-3.9c-0.4-0.8-0.6-1.5-0.8-2.3c-0.1-0.4-0.1-0.8-0.2-1.1
						c0-0.4,0-0.7,0-1.1c0.1-1.5,0.6-3,1.3-4.2c1.4-2.5,3.4-4.2,4.9-5.2c1.5-1,2.5-1.6,2.5-2.3c0-0.6-1.2-1.4-3.6-1.3
						c-1.2,0-2.7,0.3-4.3,0.9c-1.6,0.7-3.4,1.7-5.1,3.3c-1.7,1.6-3.3,3.8-4.3,6.8c-0.3,0.7-0.4,1.5-0.6,2.3
						c-0.1,0.8-0.2,1.6-0.2,2.5c-0.1,1.7,0.2,3.4,0.6,5.2c0.9,3.5,2.9,6.8,5.9,9.6c1.5,1.4,3.4,2.6,5.5,3.4c2.2,0.9,4.4,1.3,6.6,1.4
						c4.5,0.2,8.9-1,12.7-2.8c1.9-0.9,3.7-1.9,5.5-3.1c1.8-1.2,3.5-2.5,5.1-4c1.6-1.4,3.2-3.1,4.6-4.9c1.4-1.8,2.7-3.7,3.9-5.8
						c1.1-2.1,2.1-4.4,2.8-6.9c0.7-2.5,1.1-5.1,1.1-7.8c0-2.7-0.3-5.5-1.1-8.1c-0.8-2.6-1.9-5.1-3.3-7.4c-2.8-4.6-6.7-8.4-11.4-11.2
						c-4.7-2.8-9.9-4.2-15.1-4.6c-5.2-0.4-10.3,0.3-15.2,1.9c-5,1.6-9.3,4-13,6.7c-3.7,2.7-6.9,5.8-9.6,9
						c-5.3,6.4-9.1,13.2-11.6,19.9c-2.5,6.7-3.9,13.4-4.6,19.9c-0.7,6.5-0.7,12.8-0.1,19.1c0.6,6.3,1.8,12.4,3.6,18.6
						c1.8,6.2,4.1,12.4,6.9,18.7c5.7,12.7,13.8,25.8,24.4,40.6c-10-15.2-17.2-28.8-22-41.6c-2.4-6.4-4.2-12.6-5.4-18.7
						c-1.2-6.1-1.8-12-1.9-18c0-5.9,0.5-11.8,1.7-17.7c1.2-5.8,3-11.7,5.8-17.4c2.8-5.6,6.5-11.1,11.4-16c2.4-2.4,5.2-4.6,8.2-6.5
						C204.3,48.6,207.6,47.1,211.1,46.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M217.7,111.6c1.7-0.5,3.2-0.7,4.8-0.7c1.5,0,2.9,0.2,4,0.6c0.5,0.2,1,0.4,1.3,0.7c0.4,0.2,0.7,0.5,0.9,0.8
						c0.5,0.6,1,1.5,1.3,2.5c0.2,0.5,0.3,1.1,0.3,1.7c0.1,0.6,0.1,1.1,0,1.7c-0.1,1.1-0.4,2.2-0.7,3c-0.2,0.4-0.4,0.8-0.6,1.1
						c-0.2,0.3-0.4,0.6-0.7,0.8c-0.3,0.2-0.4,0.4-0.7,0.6c-0.3,0.2-0.6,0.3-1,0.4c-0.7,0.2-1.5,0.2-2.3,0.1c-0.7-0.1-1.4-0.3-2-0.7
						c-1.2-0.6-2.2-2-2.8-3.3c-0.6-1.3-0.9-2.6-1.1-3.6c-0.2-1-0.5-1.8-1-2.2c-0.5-0.3-1.4-0.2-2.5,0.7c-1,0.9-2.2,2.7-2.6,5.4
						c-0.2,1.3-0.2,2.9,0.1,4.5c0.3,1.7,1,3.5,2.3,5.2c2.5,3.5,7,6.2,12.4,6.3c1.3,0,2.7-0.1,4.2-0.4c1.4-0.3,2.9-0.9,4.2-1.7
						c1.3-0.8,2.5-1.7,3.6-2.8c1.1-1.1,2-2.3,2.8-3.5c1.5-2.5,2.5-5.3,3-8.3c0.2-1.5,0.3-3,0.2-4.5c-0.1-1.5-0.3-3-0.7-4.5
						c-0.8-3-2.3-6.1-4.8-8.7c-2.5-2.6-5.8-4.5-9-5.3c-3.2-0.9-6.4-1-9.4-0.6c-1.5,0.2-2.9,0.5-4.3,0.9c-1.4,0.4-2.7,1-4,1.5
						c-5.3,2.4-9.2,5.9-12.2,9.4c-1.5,1.8-2.8,3.6-3.9,5.4c-1.1,1.9-2,3.7-2.8,5.5c-3,7.5-3.6,14.5-3.3,21.3
						c0.3,6.8,1.7,13.4,4,20.4c2.2,7,5.4,14.5,9.3,23.3c-3.1-9.1-5.5-16.9-6.7-24c-1.3-7.1-1.6-13.5-0.8-19.6
						c0.7-6.1,2.5-12,5.9-17.3c0.8-1.3,1.8-2.6,2.9-3.9c1.1-1.2,2.3-2.4,3.5-3.5C211.2,114.4,214.2,112.5,217.7,111.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M252.1,32.8c3.2,0.6,6.4-0.3,8.5-1.8c2.1-1.5,3-3.4,2.5-5.1c-0.6-1.7-2.4-2.7-4.6-3c-2.2-0.3-4.8,0-7.4,1.2
						c-2.7,1.2-4.6,2.1-6.6,2.8c-2,0.7-4.2,1.2-7.3,1.3c3,1,5.1,1.7,7.3,2.5C246.6,31.4,248.8,32.2,252.1,32.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M267.3,45.6c1.8,2,3.7,3.3,5.6,4.1c1.9,0.8,4,1.1,5.5,0.2c1.5-0.9,1.9-3.2,0.7-5.8c-1.2-2.6-4-5.2-7.6-6.1
						c-3.7-0.9-6.2-0.8-8.7-0.6c-2.4,0.2-4.8,0.6-8,1.4c3.3,0.3,5.4,1.2,7.2,2.3C263.8,42.2,265.4,43.6,267.3,45.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M270,60.5c0.6,2.6,1.5,4.6,2.7,6.3c1.2,1.6,2.7,3,4.5,3.1c0.9,0,1.7-0.4,2.4-1.2c0.3-0.4,0.7-0.9,0.9-1.5
						c0.2-0.6,0.4-1.3,0.5-2c0.4-2.9-0.8-6.8-3.6-9.5c-2.9-2.7-5.2-3.8-7.5-4.7c-2.3-0.9-4.7-1.6-7.9-2.2c3,1.6,4.4,3.3,5.6,5.1
						C268.6,55.8,269.4,57.8,270,60.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M263.1,73.6c-0.7,2.7-0.6,5.1,0.1,7.2c0.7,2,1.9,3.7,3.7,4c1.7,0.3,3.5-0.9,4.7-3.2c1.1-2.3,1.5-5.6,0.3-8.6
						c-1.2-3.2-2.4-5.1-3.5-7.1c-1.2-1.9-2.3-3.8-3.9-6.5c0.6,3.1,0.5,5.2,0.2,7.3C264.3,68.8,263.9,70.8,263.1,73.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M251.6,86.6c-1.8,2-2.8,4.1-3.2,6.2c-0.4,2.1-0.1,4.1,1.2,5.3c1.3,1.2,3.5,1,5.7-0.5c2.1-1.5,4-4.4,4.4-7.7
						c0.4-3.4,0.1-5.8-0.2-8c-0.3-2.3-0.7-4.5-1.2-7.5c-0.5,3.1-1.4,5-2.5,6.8C254.7,82.9,253.5,84.5,251.6,86.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M241.2,20.8c6-2.2,9.5-7.9,7.4-10.8c-2.1-2.9-8.6-1.4-12.5,3.7c-2,2.6-3.3,4.5-4.6,6.4c-0.7,0.9-1.3,1.9-2.1,2.7
						c-0.4,0.4-0.9,0.8-1.5,1.1c-0.6,0.3-1.4,0.4-2.1,0.2c0.7,0.5,1.5,0.7,2.3,0.6c0.8,0,1.5-0.2,2.2-0.4c0.7-0.2,1.3-0.4,1.9-0.6
						c0.6-0.2,1.1-0.4,1.7-0.6C235.8,22.5,238.1,21.9,241.2,20.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M227.4,15.6c2.1-2.5,2.5-5.9,1.9-8.2c-0.7-2.4-2.4-3.8-4.1-3.6c-1.8,0.1-3.2,1.4-4.2,2.9c-1,1.5-1.6,3.3-1.6,5.5
						c0,2.4-0.2,4.2-0.7,5.9c-0.5,1.8-1.3,3.6-3.2,5.6c2.6-1.2,4.4-2.1,6.2-3.2C223.5,19.4,225.3,18.1,227.4,15.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M216.5,14.7c1.1-3.1,0.5-6.4-0.9-8.5c-1.4-2.1-3.4-2.9-5-2.3c-1.7,0.6-2.6,2.3-3.1,4.1c-0.5,1.8-0.5,3.9,0.3,6.1
						c0.8,2.4,1.2,4.2,1.4,6.1c0.2,1.9,0.1,3.9-1,6.6c1.9-2.1,3.3-3.8,4.6-5.5C214.1,19.6,215.4,17.8,216.5,14.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M204,15.5c-0.4-2.8-2.4-5-4.4-5.8c-2.1-0.8-4-0.4-5.1,1c-1.1,1.4-1.2,3.2-0.9,4.8c0.3,1.6,1.1,2.9,2.7,4.1
						c1.7,1.2,2.9,2.3,3.9,3.5c1,1.2,1.9,2.7,2.4,5.1c0.6-2.4,0.9-4.1,1.3-5.9C204.2,20.4,204.4,18.4,204,15.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M189.6,18.1c-1.2-0.9-2.6-1.3-4-1.3c-1.4,0-2.6,0.3-3.6,1c-1,0.6-1.6,1.4-1.9,2.3c-0.3,0.9-0.2,1.7,0.1,2.5
						c0.7,1.6,2.1,2.7,3.1,3.4c1.1,0.7,2,0.9,3.4,0.4c1.5-0.5,2.9-0.5,4.3-0.3c1.4,0.3,2.8,1,4.1,2.7c-0.3-2.2-0.8-3.8-1.5-5.5
						C193,21.7,192,19.9,189.6,18.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M174.9,28.6c-3.1,0-5.9,1.8-7.2,3.8c-1.3,2-1.2,4.2,0,5.4c1.2,1.3,3.1,1.6,4.8,1.4c1.8-0.2,3.4-0.8,5.1-2.3
						c1.7-1.5,3.2-2.6,4.8-3.4c1.6-0.8,3.4-1.4,6.1-1.4c-2.5-1.1-4.3-1.8-6.3-2.4C180.2,29.1,178.1,28.5,174.9,28.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M168.5,41.5c-2.5,0.6-4.3,2.4-4.8,4.3c-0.6,1.9-0.1,3.7,1.3,4.8c1.4,1.1,3.2,1.3,4.7,1c1.5-0.3,2.8-1.1,3.7-2.8
						c1-1.7,1.7-3,2.7-4.3c0.9-1.2,2.1-2.3,4.1-3.3c-2.2,0-3.8-0.1-5.6-0.2C172.8,41,171,40.9,168.5,41.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M158.7,62.1c-2.2,1.8-3.3,4.4-3.4,6.6c-0.1,2.2,0.9,4,2.5,4.7c1.6,0.7,3.5,0.1,5.1-1.1c1.6-1.2,2.8-3.1,3.3-5.6
						c0.5-2.6,0.8-4.4,1.3-6.3c0.5-1.8,1.1-3.6,2.6-6c-2.2,1.6-3.9,2.7-5.6,3.7C162.7,59.2,161,60.2,158.7,62.1z"
                    />
                  </g>
                  <g>
                    <path d="M155.7,90.1c-2.8,3.4-1.4,8,2,9c3.4,1,7.1-2.2,6.5-6.5c-0.6-4.5-1.6-6.4-1.4-10.8C160.4,85.5,158.6,86.6,155.7,90.1z" />
                  </g>
                  <g>
                    <path
                      d="M157,113.8c-1.1,1.8-0.9,3.7-0.1,5.2c0.8,1.4,2.4,2.4,4.1,2.4c1.8,0,3.3-0.9,4.2-2.1c0.9-1.3,1.2-2.9,0.5-4.7
						c-1.5-3.7-2.8-5.3-3.2-9.2C160.8,108.9,159.2,110.1,157,113.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M191.5,102.9c2.1-4.3,3.5-8.8,3.9-12.4c0.5-3.6,0.1-6.5-1.5-7.2c-1.6-0.7-4.1,0.9-6.3,4.1c-2.2,3.2-4.1,8-4.6,13.1
						c-0.5,5.3-0.4,8.9-0.3,12.5c0.1,3.6,0.2,7.2,0.2,12.4c1-5.1,2.2-8.5,3.5-11.8C187.9,110.5,189.4,107.4,191.5,102.9z"
                    />
                  </g>
                  <g>
                    <g>
                      <path
                        d="M6.8,333.4c-3-1.7-5.7-4-7.9-6.6c-2.1-2.6-3.6-5.5-4.4-8.4c-0.8-2.9-0.8-6.1-0.2-8.9c0.3-1.4,0.8-2.7,1.4-3.9
							c0.6-1.2,1.4-2.2,2.3-3.2c3.6-3.9,9.7-6,15.7-6.1c1.5-0.1,3,0,4.5,0.1c1.5,0.1,2.9,0.4,4.2,0.7c2.7,0.6,4.8,1.6,6.3,2.8
							c0.8,0.6,1.4,1.3,1.8,1.9c0.5,0.7,0.8,1.5,1,2.3c0.4,1.7,0.4,3.6-0.2,5.2c-0.2,0.8-0.6,1.6-1,2.2c-0.2,0.4-0.4,0.6-0.7,0.9
							c-0.3,0.3-0.5,0.6-0.8,0.8c-1.1,1-2.5,1.7-3.9,2.2c-2.8,0.9-5.4,0.7-7.1,0.5c-1.8-0.3-3-0.5-3.4,0c-0.4,0.5-0.1,1.8,1.7,3.4
							c0.9,0.8,2.1,1.6,3.8,2.3c1.6,0.6,3.7,1.1,6,1.1c2.3,0,5.1-0.5,7.7-2c0.7-0.3,1.3-0.8,2-1.3c0.6-0.5,1.3-1,1.9-1.6
							c1.2-1.2,2.2-2.6,3.1-4.2c1.7-3.1,2.6-6.9,2.2-11c-0.2-2-0.7-4.2-1.7-6.3c-1-2.1-2.3-4-3.9-5.6c-3.2-3.2-7.2-5.3-11.2-6.6
							c-2-0.7-4.1-1.2-6.1-1.5c-2.1-0.4-4.2-0.5-6.4-0.6c-2.2-0.1-4.4,0.1-6.7,0.4c-2.3,0.3-4.6,0.9-6.8,1.6
							c-2.3,0.8-4.6,1.8-6.8,3.1c-2.2,1.4-4.3,3-6.1,4.9c-1.8,2-3.5,4.2-4.7,6.7c-1.2,2.4-2.1,5-2.7,7.6c-1.1,5.3-0.8,10.7,0.7,15.9
							c1.5,5.2,4.4,9.9,7.9,13.7c3.5,3.8,7.8,6.8,12.3,9c4.7,2.2,9.5,3.4,14.1,4c4.6,0.6,9,0.5,13.1,0c8.3-1,15.6-3.4,22.1-6.6
							c6.4-3.2,12-7.1,17-11.4c4.9-4.3,9.2-8.9,13.1-13.9c3.8-5,7.2-10.3,10.1-16c2.9-5.7,5.5-11.8,7.7-18.4
							c4.5-13.2,7.6-28.3,9.9-46.4c-3.1,18-7.1,32.8-12.3,45.4c-2.6,6.3-5.6,12.1-8.8,17.3c-3.3,5.3-6.9,10-10.9,14.4
							c-4,4.3-8.4,8.3-13.3,11.8c-4.9,3.5-10.2,6.5-16.1,8.7c-5.9,2.2-12.4,3.7-19.2,3.9c-3.4,0.1-6.9-0.1-10.4-0.9
							C13.3,336.4,9.9,335.2,6.8,333.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M46.6,281.2c-1.6-0.8-2.8-1.7-4-2.7c-1.1-1-2-2.1-2.5-3.1c-0.3-0.5-0.4-1-0.5-1.4c-0.1-0.4-0.1-0.8-0.1-1.2
							c0-0.8,0.3-1.7,0.8-2.7c0.3-0.5,0.6-1,0.9-1.5c0.4-0.5,0.7-0.9,1.1-1.2c0.8-0.8,1.8-1.4,2.6-1.7c0.4-0.2,0.8-0.3,1.2-0.4
							c0.4-0.1,0.7-0.1,1-0.1c0.3,0,0.6,0,0.9,0.1c0.3,0.1,0.6,0.2,1,0.4c0.7,0.3,1.3,0.9,1.7,1.5c0.5,0.6,0.8,1.2,1,1.8
							c0.4,1.3,0.2,2.9-0.2,4.3c-0.5,1.4-1.1,2.5-1.7,3.4c-0.5,0.9-0.9,1.6-0.7,2.3c0.2,0.6,0.9,1.1,2.3,1.2c1.4,0,3.5-0.5,5.6-2.2
							c1.1-0.8,2.1-1.9,3-3.4c0.9-1.4,1.6-3.3,1.9-5.4c0.6-4.3-0.9-9.3-4.7-13c-1-0.9-2.1-1.8-3.3-2.5c-1.3-0.7-2.7-1.3-4.2-1.6
							c-1.5-0.3-3-0.5-4.5-0.5c-1.5,0.1-3,0.3-4.4,0.7c-2.9,0.8-5.5,2.2-7.8,4c-1.2,0.9-2.3,2-3.2,3.2c-1,1.2-1.8,2.4-2.6,3.8
							c-1.5,2.7-2.5,6-2.5,9.6c0,3.6,1.2,7.2,2.9,10c1.7,2.9,4,5.1,6.4,6.8c1.2,0.9,2.5,1.6,3.8,2.3c1.3,0.7,2.7,1.2,4,1.6
							c5.5,1.8,10.7,2,15.4,1.5c2.3-0.3,4.5-0.7,6.6-1.3c2.1-0.6,4-1.3,5.8-2.2c7.3-3.4,12.6-8.1,17-13.2c4.4-5.2,7.9-10.9,11-17.6
							c3.2-6.7,5.9-14.3,9.1-23.4c-4,8.8-7.5,16.1-11.4,22.1c-3.9,6.1-8.1,10.9-12.8,14.9c-4.7,3.9-10,7-16.1,8.6
							c-1.5,0.4-3.1,0.7-4.7,0.9c-1.6,0.2-3.3,0.2-5,0.1C53.2,283.5,49.7,282.8,46.6,281.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M-32.4,315.2c-2-2.6-4.9-4.2-7.4-4.5c-2.5-0.4-4.6,0.4-5.3,2c-0.7,1.6-0.1,3.6,1.3,5.3c1.4,1.7,3.5,3.3,6.2,4.2
							c2.8,0.9,4.8,1.6,6.8,2.5c2,0.9,3.9,1.9,6.2,4c-1.5-2.8-2.6-4.7-3.6-6.8C-29.3,320-30.4,317.9-32.4,315.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M-34.7,295.5c0-2.7-0.4-4.9-1.3-6.9c-0.9-1.9-2.1-3.5-3.9-3.8c-1.7-0.3-3.6,1.1-4.5,3.8c-0.9,2.7-0.6,6.6,1.4,9.7
							c2.1,3.2,4,4.8,5.9,6.3c1.9,1.5,3.9,2.8,6.8,4.4c-2.2-2.4-3.1-4.5-3.7-6.6C-34.5,300.4-34.8,298.3-34.7,295.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M-26.6,282.8c1.3-2.3,2-4.4,2.3-6.5c0.2-2,0.1-4.1-1.2-5.3c-0.6-0.6-1.5-0.9-2.5-0.8c-0.5,0.1-1.1,0.2-1.6,0.5
							c-0.5,0.3-1.2,0.6-1.7,1.1c-2.3,1.9-4.1,5.5-3.9,9.3c0.3,3.9,1.2,6.3,2.3,8.6c1.1,2.2,2.3,4.4,4.3,7c-1.1-3.2-1-5.5-0.6-7.6
							C-28.7,287.1-27.9,285.2-26.6,282.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M-12.6,277.9c2.3-1.5,3.9-3.4,4.9-5.3c0.9-1.9,1.1-4,0.1-5.4c-1-1.4-3.2-1.8-5.6-0.9c-2.4,0.9-4.9,3.1-6.2,6.1
							c-1.3,3.1-1.8,5.4-2.3,7.6c-0.4,2.2-0.9,4.4-1.5,7.4c1.7-2.6,3.2-4.2,4.8-5.5C-16.7,280.6-15,279.4-12.6,277.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M4.7,276.2c2.7-0.3,4.9-1.1,6.6-2.4c1.7-1.3,2.9-2.9,2.7-4.7c-0.2-1.7-1.9-3.2-4.4-3.5c-2.5-0.4-5.9,0.4-8.5,2.6
							c-2.6,2.3-4,4.2-5.4,6c-1.3,1.9-2.6,3.7-4.3,6.3c2.5-1.9,4.5-2.7,6.5-3.3C-0.1,276.8,1.9,276.5,4.7,276.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M-32.6,331.5c-5.9-2.5-12.3-0.7-12.8,2.8c-0.4,3.5,5.4,6.9,11.7,5.9c3.2-0.5,5.5-1.1,7.8-1.5c1.2-0.2,2.2-0.5,3.4-0.5
							c0.6,0,1.2,0,1.8,0.2c0.6,0.2,1.3,0.6,1.7,1.3c-0.2-0.8-0.7-1.5-1.2-2c-0.6-0.5-1.2-0.9-1.8-1.2c-0.6-0.3-1.2-0.6-1.8-0.8
							c-0.6-0.2-1.1-0.5-1.6-0.7C-27.5,333.9-29.6,332.8-32.6,331.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M-26.1,344.6c-3.2,0.4-5.9,2.5-7,4.8c-1.2,2.2-0.8,4.4,0.5,5.5c1.4,1.1,3.3,1.1,5.1,0.8c1.8-0.4,3.4-1.3,4.9-3
							c1.6-1.7,3-2.9,4.6-3.9c1.6-1,3.4-1.7,6.2-1.9c-2.7-0.9-4.6-1.4-6.7-1.9C-20.6,344.6-22.8,344.2-26.1,344.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M-18.7,352.8c-2.9,1.5-4.7,4.4-5.1,6.8c-0.4,2.5,0.5,4.5,2.1,5.1c1.7,0.7,3.5,0.1,5.1-0.9c1.6-1,3-2.5,4-4.7
							c1-2.2,2-3.9,3.1-5.4c1.2-1.5,2.6-2.9,5.2-4.2c-2.9,0.2-5,0.5-7.1,0.9C-13.6,350.8-15.8,351.3-18.7,352.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M-9,360.7c-1.6,2.3-1.7,5.3-0.7,7.3c0.9,2,2.7,3,4.4,2.7c1.7-0.3,3.1-1.5,4-2.9c0.9-1.4,1.2-2.9,0.8-4.8
							c-0.4-2-0.6-3.6-0.5-5.2c0.1-1.6,0.4-3.3,1.7-5.4c-2,1.3-3.5,2.4-5,3.5C-5.8,357-7.4,358.3-9,360.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M3.3,368.7c0.3,1.5,1,2.7,2,3.7c1,0.9,2.1,1.5,3.2,1.7c1.1,0.2,2.1,0,2.9-0.4c0.8-0.4,1.3-1.1,1.6-1.9
							c0.6-1.7,0.4-3.4,0.1-4.6c-0.3-1.3-0.8-2.1-2.2-2.7c-1.4-0.7-2.5-1.6-3.3-2.7c-0.8-1.2-1.4-2.6-1.1-4.8
							c-1.3,1.8-2,3.4-2.6,5.1C3.3,363.7,2.7,365.7,3.3,368.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M21.2,371c2.3,2.1,5.5,2.7,7.9,2.1c2.4-0.6,3.8-2.2,3.7-3.9c0-1.8-1.2-3.3-2.6-4.4c-1.4-1.1-3.1-1.7-5.3-1.8
							c-2.3-0.1-4.1-0.3-5.8-0.8c-1.7-0.5-3.4-1.3-5.4-3.2c1.1,2.5,1.9,4.3,2.9,6.1C17.7,367,18.8,368.9,21.2,371z"
                      />
                    </g>
                    <g>
                      <path
                        d="M34.6,366c2.2,1.3,4.7,1.2,6.4,0.2c1.7-1,2.6-2.7,2.3-4.4c-0.3-1.7-1.4-3.1-2.7-4c-1.3-0.8-2.8-1.1-4.7-0.5
							c-1.9,0.6-3.3,1-4.8,1.3c-1.5,0.2-3.1,0.3-5.2-0.4c1.6,1.5,2.7,2.7,3.9,3.9C31.1,363.4,32.4,364.7,34.6,366z"
                      />
                    </g>
                    <g>
                      <path
                        d="M55.9,357.6c2.9,0.2,5.5-1,7-2.5c1.6-1.6,2.1-3.5,1.4-5.1c-0.7-1.6-2.5-2.5-4.5-2.7c-2-0.2-4.2,0.4-6.2,1.9
							c-2.1,1.5-3.6,2.7-5.2,3.7c-1.6,1-3.3,1.9-6,2.6c2.7,0.3,4.7,0.7,6.6,1.1C51,357,52.9,357.4,55.9,357.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M77.2,339.2c4.4-0.5,6.5-4.9,4.7-7.9c-1.8-3-6.6-3.2-9.2,0.4c-2.6,3.7-3.2,5.7-6.3,8.9
							C70.6,339.3,72.7,339.8,77.2,339.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M92.4,321c2-0.6,3.2-2.1,3.6-3.7c0.4-1.6-0.1-3.4-1.4-4.6c-1.3-1.2-3-1.6-4.5-1.3c-1.5,0.3-2.9,1.3-3.6,3.1
							c-1.4,3.7-1.5,5.7-3.9,8.9C86.3,321.9,88.3,322.2,92.4,321z"
                      />
                    </g>
                    <g>
                      <path
                        d="M59.8,305.3c-4.5,1.7-8.5,4.1-11.4,6.4c-2.8,2.3-4.5,4.7-3.8,6.3c0.7,1.6,3.6,2.1,7.4,1.3c3.8-0.8,8.5-3,12.3-6.5
							c3.9-3.5,6.3-6.2,8.8-8.9c2.4-2.7,4.8-5.4,8.3-9.3c-4.2,3.1-7.4,4.7-10.6,6.2C67.6,302.3,64.4,303.6,59.8,305.3z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M363.6,398.2c3.2,1.3,6.2,3.2,8.6,5.5c2.4,2.3,4.3,5,5.4,7.7c1.1,2.8,1.6,5.9,1.3,8.8c-0.1,1.4-0.4,2.8-0.9,4.1
							c-0.4,1.3-1.1,2.4-1.8,3.5c-3,4.3-8.8,7.2-14.8,8.1c-1.5,0.3-3,0.4-4.4,0.4c-1.5,0.1-2.9,0-4.3-0.1c-2.7-0.3-5-1-6.7-2
							c-0.8-0.5-1.5-1.1-2-1.7c-0.5-0.6-1-1.4-1.3-2.2c-0.6-1.7-0.8-3.6-0.5-5.2c0.1-0.8,0.4-1.6,0.7-2.3c0.1-0.4,0.4-0.7,0.5-1
							c0.2-0.3,0.4-0.6,0.7-0.9c1-1.2,2.2-2.1,3.6-2.7c2.6-1.2,5.2-1.4,7-1.4c1.8,0,3,0.1,3.4-0.4c0.4-0.5-0.2-1.8-2.1-3.2
							c-1-0.7-2.3-1.3-4-1.8c-1.7-0.4-3.8-0.6-6.1-0.3c-2.3,0.3-4.9,1.2-7.4,3c-0.6,0.4-1.2,1-1.8,1.5c-0.6,0.6-1.2,1.2-1.6,1.9
							c-1,1.3-1.9,2.9-2.5,4.5c-1.3,3.3-1.6,7.2-0.8,11.2c0.4,2,1.2,4.1,2.4,6c1.3,2,2.8,3.6,4.6,5c3.6,2.8,7.8,4.3,11.9,5.1
							c2.1,0.4,4.2,0.6,6.3,0.7c2.1,0.1,4.3,0,6.4-0.3c2.2-0.2,4.4-0.7,6.6-1.3c2.2-0.6,4.4-1.4,6.6-2.5c2.2-1.1,4.3-2.4,6.3-4
							c2-1.6,3.8-3.5,5.5-5.7c1.6-2.2,2.9-4.6,3.8-7.2c0.9-2.6,1.5-5.2,1.7-7.9c0.4-5.4-0.6-10.7-2.7-15.7c-2.2-5-5.6-9.2-9.6-12.6
							c-4-3.3-8.6-5.8-13.4-7.3c-5-1.6-9.9-2.2-14.5-2.1c-4.6,0-9,0.7-13,1.7c-8.1,2.1-15,5.4-21,9.4c-6,4-11,8.6-15.3,13.5
							c-4.3,4.9-8,10-11.1,15.5c-3.1,5.4-5.8,11.2-8,17.2c-2.2,6.1-3.9,12.4-5.3,19.3c-2.7,13.7-3.8,29-3.8,47.2
							c0.7-18.2,2.8-33.4,6.3-46.6c1.8-6.6,3.9-12.7,6.5-18.3c2.6-5.7,5.5-10.9,8.9-15.7c3.4-4.8,7.3-9.3,11.6-13.4
							c4.4-4.1,9.3-7.7,14.8-10.7c5.5-3,11.8-5.2,18.5-6.4c3.4-0.5,6.9-0.8,10.4-0.5C356.7,396.1,360.3,396.8,363.6,398.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M331,455.1c1.7,0.6,3,1.3,4.3,2.2c1.2,0.9,2.2,1.9,2.9,2.8c0.3,0.5,0.5,0.9,0.7,1.3c0.2,0.4,0.2,0.8,0.3,1.2
							c0.1,0.8,0,1.8-0.4,2.8c-0.2,0.5-0.4,1.1-0.7,1.6c-0.3,0.5-0.6,0.9-1,1.4c-0.7,0.9-1.6,1.6-2.4,2.1c-0.4,0.2-0.8,0.4-1.1,0.6
							c-0.4,0.1-0.7,0.2-1,0.3c-0.3,0-0.6,0.1-0.9,0c-0.3,0-0.7-0.1-1-0.2c-0.7-0.3-1.4-0.7-1.9-1.2c-0.5-0.5-1-1.1-1.2-1.7
							c-0.6-1.2-0.6-2.9-0.3-4.3c0.3-1.4,0.8-2.6,1.2-3.6c0.4-1,0.7-1.7,0.4-2.4c-0.2-0.6-1-1-2.5-0.9c-1.4,0.2-3.4,0.9-5.3,2.9
							c-0.9,1-1.9,2.2-2.6,3.7c-0.7,1.6-1.2,3.4-1.2,5.6c0,4.3,2.1,9.1,6.4,12.3c1.1,0.8,2.3,1.5,3.6,2.1c1.3,0.5,2.9,0.9,4.4,1.1
							c1.5,0.1,3,0.1,4.5-0.1c1.5-0.3,2.9-0.7,4.3-1.2c2.7-1.2,5.2-2.9,7.2-5c1-1.1,2-2.3,2.8-3.6c0.8-1.3,1.5-2.6,2-4.1
							c1.1-2.9,1.7-6.3,1.2-9.9c-0.5-3.6-2.1-7-4.2-9.5c-2.1-2.6-4.6-4.5-7.3-5.9c-1.3-0.7-2.7-1.3-4.1-1.8
							c-1.4-0.5-2.8-0.8-4.1-1.1c-5.7-1.1-10.9-0.6-15.4,0.5c-2.3,0.6-4.4,1.3-6.3,2.2c-2,0.9-3.8,1.8-5.5,2.9
							c-6.8,4.3-11.4,9.7-15.1,15.3c-3.7,5.7-6.4,11.9-8.7,18.9c-2.3,7-4,15-6,24.4c2.8-9.2,5.4-16.9,8.5-23.4
							c3.1-6.5,6.6-11.9,10.7-16.4c4.1-4.5,9-8.3,14.8-10.6c1.5-0.6,3-1.1,4.6-1.5c1.6-0.4,3.2-0.6,4.9-0.8
							C324,453.6,327.6,453.9,331,455.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M404.9,411.1c2.3,2.3,5.4,3.5,7.9,3.5c2.5,0,4.5-1,5-2.7c0.5-1.7-0.4-3.6-2-5.1c-1.6-1.6-3.9-2.8-6.7-3.3
							c-2.9-0.6-5-1-7-1.6c-2.1-0.6-4.1-1.4-6.7-3.2c1.9,2.5,3.2,4.4,4.4,6.3C401.2,406.8,402.5,408.7,404.9,411.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M409.7,430.4c0.3,2.7,1.1,4.8,2.2,6.6c1.1,1.8,2.6,3.2,4.3,3.3c1.7,0.1,3.4-1.5,4-4.4c0.6-2.8-0.2-6.6-2.6-9.4
							c-2.5-2.9-4.6-4.3-6.7-5.5c-2.1-1.2-4.2-2.3-7.3-3.5c2.5,2.1,3.7,4.1,4.5,6.1C408.9,425.6,409.4,427.6,409.7,430.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M403.3,444.1c-1,2.4-1.5,4.6-1.4,6.7c0,2,0.4,4,1.8,5.1c0.7,0.5,1.6,0.7,2.6,0.4c0.5-0.1,1.1-0.3,1.6-0.7
							c0.5-0.3,1.1-0.8,1.6-1.3c2-2.1,3.4-6,2.6-9.8c-0.8-3.8-2.1-6.1-3.4-8.2c-1.4-2.1-2.8-4-5.2-6.4c1.5,3,1.7,5.3,1.5,7.4
							C404.8,439.5,404.3,441.5,403.3,444.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M390,450.7c-2.1,1.7-3.5,3.8-4.1,5.9c-0.6,2-0.6,4.1,0.6,5.4c1.2,1.3,3.4,1.3,5.6,0.1c2.2-1.2,4.4-3.7,5.3-6.8
							c0.9-3.3,1.1-5.6,1.3-7.8c0.2-2.3,0.3-4.5,0.6-7.6c-1.3,2.8-2.6,4.5-4.1,6.1C393.8,447.5,392.2,448.9,390,450.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M373.1,454.6c-2.6,0.6-4.7,1.8-6.2,3.2c-1.5,1.5-2.5,3.3-2.1,5c0.4,1.7,2.3,2.9,4.9,2.9c2.6,0,5.8-1.2,8.1-3.7
							c2.3-2.6,3.4-4.6,4.5-6.7c1.1-2,2.1-4,3.4-6.8c-2.2,2.2-4.1,3.3-6,4.1C377.8,453.4,375.8,453.9,373.1,454.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M402.9,395c6.1,1.8,12.3-0.9,12.3-4.5c0-3.5-6.2-6.1-12.3-4.3c-3.2,0.9-5.3,1.8-7.5,2.5c-1.1,0.4-2.2,0.8-3.3,0.9
							c-0.6,0.1-1.2,0.1-1.8,0c-0.6-0.1-1.3-0.5-1.8-1.1c0.3,0.8,0.9,1.4,1.5,1.9c0.6,0.4,1.3,0.7,2,1c0.6,0.2,1.3,0.4,1.9,0.6
							c0.6,0.2,1.1,0.3,1.7,0.5C397.6,393.2,399.8,394.1,402.9,395z"
                      />
                    </g>
                    <g>
                      <path
                        d="M394.7,382.8c3.1-0.8,5.5-3.3,6.3-5.6c0.9-2.4,0.2-4.4-1.3-5.3c-1.5-0.9-3.4-0.7-5.1-0.1c-1.7,0.6-3.2,1.7-4.5,3.6
							c-1.4,1.9-2.6,3.3-4,4.4c-1.4,1.1-3.1,2.2-5.9,2.7c2.8,0.5,4.8,0.8,6.9,1C389.3,383.5,391.5,383.6,394.7,382.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M386.4,375.6c2.7-1.9,4.1-4.9,4.2-7.4c0.1-2.5-1.1-4.4-2.8-4.8c-1.7-0.4-3.5,0.4-5,1.5c-1.5,1.2-2.6,2.9-3.3,5.2
							c-0.7,2.4-1.5,4.1-2.4,5.8c-1,1.6-2.2,3.2-4.6,4.8c2.8-0.6,4.8-1.2,6.9-1.8C381.5,378.3,383.7,377.5,386.4,375.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M375.7,369.1c1.3-2.5,1-5.5-0.2-7.3c-1.2-1.9-3.1-2.6-4.7-2.1c-1.7,0.5-2.9,1.9-3.6,3.3c-0.7,1.5-0.8,3-0.2,4.9
							c0.6,1.9,1,3.5,1.2,5.1c0.1,1.6,0,3.3-1,5.5c1.8-1.6,3.2-2.8,4.5-4.1C373.1,373.1,374.4,371.7,375.7,369.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M362.5,362.8c-0.5-1.4-1.4-2.6-2.5-3.4c-1.1-0.8-2.3-1.2-3.4-1.3c-1.1-0.1-2.1,0.2-2.8,0.8c-0.7,0.5-1.2,1.3-1.4,2.1
							c-0.4,1.7,0.1,3.4,0.5,4.6c0.5,1.2,1.1,1.9,2.5,2.4c1.5,0.5,2.7,1.2,3.6,2.3c0.9,1,1.7,2.4,1.8,4.6c1-2,1.6-3.6,2-5.4
							C363.2,367.7,363.5,365.7,362.5,362.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M344.5,362.7c-2.6-1.8-5.9-2-8.1-1.1c-2.3,0.9-3.4,2.7-3.2,4.4c0.3,1.8,1.6,3.1,3.1,4c1.5,0.9,3.3,1.3,5.5,1.1
							c2.3-0.2,4.1-0.2,5.8,0c1.8,0.3,3.6,0.8,5.8,2.5c-1.4-2.3-2.5-4-3.7-5.7C348.5,366.3,347.1,364.6,344.5,362.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M331.8,369.5c-2.3-1-4.8-0.5-6.4,0.6c-1.6,1.2-2.2,3-1.8,4.7c0.5,1.7,1.8,2.9,3.2,3.6c1.4,0.6,3,0.7,4.7-0.1
							c1.8-0.8,3.2-1.5,4.6-1.9c1.5-0.4,3-0.7,5.2-0.3c-1.8-1.3-3.1-2.3-4.4-3.4C335.6,371.6,334.2,370.5,331.8,369.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M311.8,380.6c-2.9,0.2-5.3,1.7-6.6,3.4c-1.4,1.8-1.7,3.8-0.7,5.3c0.9,1.5,2.8,2.1,4.8,2c2-0.1,4.1-0.9,5.9-2.7
							c1.9-1.8,3.2-3.1,4.7-4.3c1.5-1.2,3-2.3,5.6-3.4c-2.8,0-4.7-0.1-6.7-0.3C316.8,380.6,314.7,380.4,311.8,380.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M293.1,401.5c-4.3,1.1-5.8,5.7-3.6,8.4c2.2,2.8,7,2.4,9-1.5c2.1-4,2.4-6.1,5.1-9.6
							C299.6,400.6,297.5,400.4,293.1,401.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M280.3,421.6c-1.9,0.9-2.9,2.5-3.1,4.2c-0.2,1.7,0.5,3.3,2,4.4c1.4,1,3.2,1.2,4.7,0.7c1.5-0.5,2.7-1.7,3.1-3.6
							c0.9-3.9,0.8-5.9,2.7-9.4C286.3,419.9,284.3,419.9,280.3,421.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M314.7,432.9c4.2-2.3,7.9-5.1,10.4-7.8c2.5-2.7,3.8-5.2,2.9-6.7c-0.9-1.5-3.8-1.6-7.5-0.3c-3.7,1.3-8,4.1-11.4,8
							c-3.5,4-5.5,7-7.5,10c-2,3-4,6-7,10.3c3.8-3.6,6.7-5.7,9.7-7.5C307.3,436.9,310.4,435.2,314.7,432.9z"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M344.5,109.3c1.3-4.4,1.9-9.1,1.4-13.7c-0.4-4.6-1.8-9.3-4.3-13.4c-0.3-0.5-0.6-1-1-1.5c-0.4-0.5-0.7-1-1.1-1.5
						c-0.8-0.9-1.5-1.8-2.4-2.7c-1.7-1.7-3.6-3.1-5.6-4.3c-4-2.4-8.3-3.8-12.6-4.4c-4.3-0.6-8.7-0.6-12.9,0.4
						c-4.2,0.9-8.3,2.7-11.6,5.5c-3.3,2.7-5.9,6.2-7.2,10.2c-0.7,1.9-1.1,4.1-1.1,6.1c0,2,0.2,4,0.8,5.9c1.1,3.7,3.3,7,6.3,9.2
						c3,2.2,6.5,3.1,9.6,3c3.1-0.1,6.1-1.1,8.3-2.8c1.1-0.9,2-1.9,2.7-2.9c0.7-1.1,1.2-2.2,1.4-3.2c0.6-2.1,0.5-4.1,0.2-5.7
						c-0.3-1.6-1-2.9-1.6-3.8c-1.4-1.8-2.6-2.1-2.9-1.8c-0.3,0.3,0.2,1.3,0.6,2.9c0.2,0.8,0.3,1.8,0.2,3c-0.1,1.1-0.4,2.4-1,3.7
						c-0.3,0.6-0.7,1.2-1.2,1.7c-0.5,0.5-1.1,0.9-1.7,1.3c-1.3,0.7-3,1.1-4.8,0.9c-1.8-0.2-3.7-0.9-5-2.1c-1.4-1.3-2.5-3.2-2.9-5.3
						c-0.2-1.1-0.3-2.2-0.2-3.4c0.1-1.1,0.4-2.2,0.8-3.3c0.9-2.2,2.6-4.3,4.7-6c2.1-1.6,4.8-2.7,7.7-3.3c2.9-0.6,6.2-0.6,9.4-0.1
						c6.4,1,12.6,4.2,15.9,9.6c3.5,5.4,4.4,12.8,2.7,19.6c-0.4,1.7-1,3.4-1.8,5c-0.7,1.6-1.6,3.1-2.6,4.6c-2,2.9-4.2,5.4-6.7,7.6
						c-5,4.4-10.6,7.5-16.3,9.7c-5.7,2.3-11.4,3.6-17.1,4.6c-5.7,1-11.4,1.5-17.1,1.9c-5.7,0.4-11.5,0.5-17.5,0.9
						c-6,0.4-12.3,1.1-18.6,3c-3.2,0.9-6.4,2.1-9.5,3.8c-3.1,1.6-6.1,3.7-8.8,6.3c-2.7,2.6-5,5.8-6.7,9.4c-1.6,3.6-2.6,7.8-2.2,12.1
						c-0.2-4.3,0.9-8.4,2.6-12c1.8-3.5,4.1-6.6,6.9-9c2.7-2.4,5.7-4.3,8.8-5.8c3.1-1.4,6.2-2.5,9.4-3.2c6.3-1.5,12.4-1.9,18.3-1.9
						c5.9-0.1,11.7,0.2,17.5,0.2c5.8,0,11.6-0.2,17.6-0.8c6-0.7,12.2-1.7,18.5-3.8c6.3-2,12.9-5,19-9.8c3.1-2.4,6-5.2,8.6-8.6
						C340.9,117.8,343.1,113.9,344.5,109.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M278.5,96c-3.9-1.2-8.4-1.3-12.5,0.5c-2,0.9-3.8,2.1-5.3,3.7c-1.5,1.5-2.7,3.3-3.6,5.3c-0.8,2-1.2,4.2-1.1,6.4
						c0.1,2.2,0.7,4.2,1.6,6c1.8,3.5,4.9,6.1,8.4,7c3.5,1,6.9,0.3,9.4-1.4c1.2-0.8,2.2-1.9,2.8-3c0.6-1.1,0.9-2.2,1-3.1
						c0.2-1.9-0.3-3.4-0.9-4.2c-0.6-0.8-1.2-1-1.6-0.9c-0.8,0.3-0.9,2.1-2.2,3.6c-0.3,0.4-0.7,0.8-1.1,1c-0.4,0.3-0.8,0.4-1.3,0.5
						c-1,0.2-2.4,0.1-3.4-0.5c-1.1-0.6-2.2-1.8-2.7-3.2c-0.6-1.5-0.5-2.8,0.1-4.4c0.7-1.6,2.1-3.1,3.8-4c1.7-0.9,3.9-1.2,6.3-0.9
						c4.9,0.7,8.5,4.3,10.6,8.1c2.2,3.9,3,8.1,2.7,12.3c-0.2,2.1-0.6,4.2-1.5,6.2c-0.4,1-1,2-1.6,3c-0.7,0.9-1.4,1.8-2.4,2.7
						c-1.8,1.6-4.1,3-6.9,3.8c-2.7,0.7-6,0.9-9.2-0.3c3.1,1.4,6.5,1.6,9.4,1.1c3-0.5,5.6-1.7,7.8-3.3c1.1-0.8,2.1-1.7,3-2.6
						c0.9-1,1.7-2,2.3-3.1c1.4-2.1,2.2-4.5,2.9-6.9c1.1-4.8,1-10-0.9-15.4c-1-2.7-2.4-5.4-4.7-8C285.7,99.6,282.5,97.2,278.5,96z"
                    />
                  </g>
                  <g>
                    <path
                      d="M315.1,122.6c2.7-2.4,4.1-5.8,4.2-8.5c0.1-2.8-0.9-4.8-2.6-5.3c-1.7-0.5-3.6,0.5-5.2,2.2c-1.6,1.7-2.9,3.9-3.7,6.8
						c-0.8,2.9-1.5,5-2.5,7.1c-1,2-2.1,4-4.5,6.4c3-1.5,5.1-2.6,7.3-3.8C310.2,126.3,312.4,125.1,315.1,122.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M366,89.8c2.5-1.7,4.3-3.8,5.4-5.9c1.1-2.1,1.5-4.2,0.4-5.7c-1-1.4-3.3-1.7-5.9-0.7c-2.6,1.1-5.4,3.6-6.8,6.9
						c-1.5,3.5-2,6-2.5,8.4c-0.4,2.5-0.8,4.9-1.4,8.3c1.5-3.1,3.1-4.9,4.7-6.6C361.7,93,363.5,91.6,366,89.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M379.9,95.6c3-0.8,5.4-2.4,6.9-4.3c1.5-1.8,2.2-3.9,1.4-5.5c-0.8-1.6-2.9-2.2-5.4-1.9c-2.5,0.3-5.4,1.7-7.6,4.1
						c-2.3,2.5-3.6,4.4-5,6.2c-1.4,1.8-2.8,3.6-5.1,6c2.9-1.5,5.1-2.3,7.2-2.9C374.6,96.9,376.8,96.4,379.9,95.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M390.9,105.2c3.1,0.2,5.9-0.4,8-1.5c2.1-1.1,3.7-2.6,3.6-4.4c0-1.7-1.7-3.3-4.4-4c-2.6-0.7-6.2-0.4-9.3,1.3
						c-3.2,1.7-5.2,3.3-7.1,4.7c-1.9,1.5-3.8,3-6.6,5c3.3-1.1,5.6-1.4,7.9-1.5C385.4,104.9,387.7,105,390.9,105.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M360.3,116.8c2.7-0.8,4.8-2.3,6.1-4c1.3-1.7,2-3.7,1.2-5.3c-0.7-1.6-2.7-2.4-5.1-2.1c-2.4,0.3-5.2,1.7-7.2,4.2
						c-2,2.5-3.1,4.5-4.2,6.4c-1.1,1.9-2.2,3.8-3.9,6.3c2.5-1.8,4.4-2.7,6.3-3.5C355.5,118.1,357.5,117.6,360.3,116.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M373.4,121.3c2.6,0.1,4.9-0.6,6.6-1.8c1.7-1.2,2.8-2.9,2.6-4.6c-0.2-1.7-1.8-3.1-4.1-3.6c-2.3-0.5-5.2,0-7.7,1.7
						c-2.5,1.8-4.1,3.3-5.6,4.7c-1.5,1.5-3,2.9-5.3,4.7c2.8-0.9,4.8-1.1,6.7-1.2C368.7,121,370.7,121.2,373.4,121.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M293.2,147.9c-6.4,0.5-11.3,5.2-10.1,8.5c1.2,3.3,8,3.6,13.2-0.3c5.3-4,7-6.2,12.8-9.5
						C302.5,148,299.8,147.4,293.2,147.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M300.9,162.8c-1.7,2.4-2,5.5-1.2,7.6c0.7,2.2,2.4,3.5,4.1,3.4c1.8-0.1,3.2-1.3,4.2-2.8c1-1.5,1.5-3.4,1.3-5.6
						c-0.2-2.3-0.3-4.1-0.2-5.9c0.2-1.8,0.5-3.6,1.8-5.9c-2.1,1.6-3.6,2.8-5.1,4.1C304.2,159,302.7,160.4,300.9,162.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M250.7,158.6c5.2-0.2,10.2-0.8,14.1-1.6c3.9-0.8,6.7-2,7-3.7c0.2-1.7-2.4-3.7-6.9-4.6c-4.4-1-10.5-0.7-16.1,1.4
						c-5.7,2.2-9.2,4.4-12.7,6.7c-3.4,2.3-6.7,4.7-11.2,8.5c5.1-2.9,9-4.2,12.8-5.1C241.5,159.3,245.3,158.8,250.7,158.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M452.7,61.6c-0.5-3.2-1.3-6.4-2.5-9.5c-1.2-3.1-2.9-6.2-5.1-8.9c-2.2-2.7-5-5-8.1-6.7c-3.1-1.7-6.7-2.7-10.3-2.8
						l-1.4,0l-1.4,0.1c-0.9,0.1-1.8,0.3-2.7,0.5c-1.8,0.5-3.4,1.1-4.9,2c-3,1.7-5.6,4.2-7.3,7.3c-1.7,3-2.4,6.8-1.9,10
						c0.2,1.6,0.7,3.2,1.3,4.6c0.6,1.4,1.4,2.7,2.2,3.8c3.4,4.5,8.5,7,13.1,7.2c2.3,0.1,4.6-0.3,6.5-1.3c1-0.5,1.8-1.1,2.5-1.8
						c0.7-0.7,1.2-1.5,1.7-2.3c1.5-3.2,1-6,0-7.5c-0.5-0.8-1-1.2-1.4-1.4c-0.4-0.2-0.7-0.2-0.9,0c-0.3,0.3-0.1,1.1-0.1,2.2
						c0,0.6-0.1,1.2-0.4,1.9c-0.3,0.7-0.7,1.5-1.3,2.1c-0.3,0.3-0.7,0.6-1,0.8c-0.3,0.2-0.8,0.4-1.3,0.5c-1,0.2-2.2,0.2-3.5-0.1
						c-2.6-0.6-5.4-2.4-7.1-5.2c-0.4-0.7-0.8-1.4-1-2.2c-0.2-0.8-0.4-1.5-0.4-2.3c-0.1-0.8,0-1.5,0.2-2.2c0.2-0.7,0.5-1.4,0.9-2.1
						c0.8-1.4,2.2-2.6,3.9-3.5c0.8-0.4,1.7-0.8,2.6-1c0.4-0.1,0.9-0.2,1.4-0.2l0.7,0l0.7,0c1.9,0.1,4,0.6,5.9,1.7
						c1.9,1,3.8,2.5,5.3,4.2c3.1,3.5,5.2,8.4,6.4,13.7c1.2,5.5,1.6,10.9,1.4,15.9c-0.2,5-0.9,9.7-1.9,14.1
						c-2.1,8.8-5.4,16.4-9.6,23.5c-4.2,7.1-9.5,13.8-16.3,20.4c-6.8,6.6-15.2,13.2-26.1,19.6c11.2-6,20-12.1,27.3-18.3
						c7.3-6.2,13.3-12.6,18.3-19.6c5-7,9.2-14.7,12.3-23.9c1.5-4.6,2.7-9.6,3.4-15.1C453.5,74.1,453.6,68.1,452.7,61.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M420.9,70.1c-1.7-0.9-3.7-1.5-5.7-1.6c-2.1-0.2-4.2,0.2-6.2,0.9c-1.9,0.7-3.7,1.8-5.2,3.1c-1.5,1.4-2.7,3-3.5,5
						c-0.8,1.9-1.2,4.1-1.1,6c0.1,2,0.6,3.8,1.4,5.5c1.6,3.3,4.3,5.8,7.4,6.9c1.6,0.6,3.2,0.8,4.6,0.6c1.4-0.1,2.7-0.5,3.8-0.9
						c2.2-1,3.7-2.4,4.6-3.8c0.9-1.4,1.2-2.7,1.1-3.6c-0.1-0.9-0.4-1.4-0.8-1.5c-0.9-0.2-1.9,0.9-3.8,1.6c-0.9,0.4-2,0.7-3.2,0.8
						c-1.2,0-2.4-0.2-3.2-0.9c-0.9-0.6-1.7-1.7-2.1-3c-0.2-0.6-0.3-1.3-0.3-2c0-0.6,0.2-1.2,0.4-1.8c0.5-1.2,1.7-2.4,3.2-3
						c0.7-0.3,1.5-0.5,2.2-0.6c0.8,0,1.7,0.1,2.6,0.3c1.9,0.6,3.8,1.9,5.4,3.4c0.8,0.8,1.5,1.6,2.2,2.4c0.7,0.8,1.3,1.7,1.9,2.6
						c2.3,3.5,3.9,7.1,5,10.9c1.1,3.8,1.7,7.8,1.5,12.4c0,0.6-0.1,1.1-0.1,1.7c0,0.6-0.1,1.2-0.2,1.8c-0.1,1.2-0.4,2.4-0.6,3.7
						c-0.6,2.5-1.4,5.2-2.7,8c1.5-2.7,2.7-5.3,3.5-7.8c0.4-1.3,0.8-2.5,1.1-3.7c0.1-0.6,0.3-1.2,0.4-1.8c0.1-0.6,0.2-1.2,0.3-1.8
						c0.8-4.7,0.9-9,0.5-13.3c-0.5-4.3-1.4-8.6-3.3-13.1c-0.5-1.1-1-2.3-1.7-3.4c-0.6-1.2-1.4-2.4-2.2-3.5
						C426.7,74.4,424.4,72,420.9,70.1z"
                    />
                  </g>
                  <g>
                    <g>
                      <path
                        d="M503.8,68.5c5.7-0.7,11.4-1.1,16.9-1.7c5.5-0.5,10.7-1.1,15.1-2c4.5-0.8,8.2-1.9,10.7-2.9c2.5-1.1,3.9-2.2,3.7-3
							c-0.2-0.9-1.8-1.4-4.4-1.8c-2.6-0.4-6.3-0.5-10.8-0.6c-4.4-0.1-9.6,0-15.3,0.4c-5.6,0.4-11.8,1.3-17.7,2.9
							c-6.2,1.7-11.2,3.7-15.6,6c-4.3,2.3-8,4.8-11.3,7.8c-0.8,0.7-1.6,1.5-2.4,2.3c-0.8,0.8-1.5,1.6-2.3,2.5
							c-1.5,1.7-2.9,3.6-4.2,5.6c-2.6,4.1-4.9,9.2-5.6,15.5c1.2-6.2,4.1-10.9,7-14.5c1.5-1.8,3.1-3.4,4.7-4.7c0.8-0.7,1.7-1.3,2.5-2
							c0.9-0.6,1.7-1.2,2.6-1.7c3.5-2.2,7.2-3.8,11.4-5.1C493.2,70.2,498,69.2,503.8,68.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M512,85.7c5.6-1.4,11.1-3.6,15.9-6.1c4.8-2.5,9.1-5.5,12.4-8.3c6.7-5.7,9.8-10.9,8.6-12.1c-1.2-1.3-6.2,1.4-13.3,5.2
							c-7.1,3.9-16.5,8.8-26.3,13c-5.1,2.2-9.5,3.7-13.6,4.9c-4.1,1.2-8,2.1-11.9,3.3c-3.9,1.1-7.8,2.4-11.9,4.4
							c-2,1-4.2,2.2-6.2,3.8c-2.1,1.5-4.1,3.5-5.8,5.9c1.9-2.2,4.1-3.9,6.3-5.2c2.2-1.3,4.3-2.2,6.4-2.9c4.2-1.4,8.1-1.9,12-2.3
							c3.9-0.4,7.8-0.5,12.3-1C501.2,87.9,506.2,87.1,512,85.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M519.6,76.1c6.3-1,12.2-3.4,16.2-6c4.1-2.6,6.3-5.3,5.6-6.9c-0.7-1.6-4.1-1.8-8.7-0.9c-4.5,0.9-10.2,2.7-15.7,5.4
							c-5.7,2.7-9.6,4.6-13.6,6.3c-4.1,1.7-8.3,3.3-14.6,4.9c6.6-0.6,11-0.9,15.5-1.2C508.7,77.4,513.2,77.1,519.6,76.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M517.7,72.2c4.9-0.5,9.4-2.3,12.5-4.4c3.1-2.1,4.8-4.4,4.1-6c-0.7-1.6-3.4-2.2-7-1.8c-3.6,0.4-8,1.7-12.2,3.8
							c-4.3,2.3-7.3,3.9-10.4,5.4c-3.1,1.5-6.2,2.9-11.1,4.4c5-0.4,8.5-0.6,12-0.7C509.2,72.8,512.7,72.7,517.7,72.2z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M490.4,213.7c-5.6-1.1-11.2-2.4-16.6-3.5c-5.4-1.2-10.5-2.2-15-2.7c-4.5-0.6-8.4-0.7-11.1-0.5
							c-2.7,0.3-4.3,0.9-4.5,1.8c-0.1,0.9,1.2,1.9,3.6,3c2.4,1.1,5.9,2.4,10.1,3.8c4.2,1.4,9.2,2.9,14.7,4.2
							c5.5,1.3,11.6,2.3,17.8,2.6c6.4,0.2,11.8-0.1,16.7-1c4.8-0.9,9.1-2.1,13.1-4c1-0.4,2-1,3-1.4c1-0.5,2-1.1,2.9-1.7
							c1.9-1.2,3.8-2.5,5.7-4.1c3.7-3.1,7.4-7.3,10-13.1c-3.1,5.6-7.2,9.1-11.1,11.7c-2,1.3-3.9,2.3-5.9,3.1c-1,0.4-2,0.8-2.9,1.1
							c-1,0.3-2,0.6-3,0.8c-3.9,1-8,1.4-12.4,1.4C501.1,215.3,496.2,214.8,490.4,213.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M487.9,194.8c-5.8-0.4-11.6,0.1-17,1c-5.4,1-10.3,2.5-14.3,4.2c-8.1,3.4-12.6,7.4-11.8,8.9c0.8,1.6,6.3,0.6,14.3-0.9
							c8-1.5,18.4-3.4,29-4.4c5.5-0.6,10.2-0.7,14.5-0.6c4.3,0.1,8.3,0.4,12.3,0.5c4,0.1,8.2,0.1,12.7-0.6c2.3-0.4,4.6-0.9,7.1-1.7
							c2.4-0.9,4.9-2.1,7.3-3.9c-2.5,1.6-5.1,2.5-7.5,3.1c-2.4,0.6-4.8,0.8-7,0.8c-4.4,0-8.3-0.6-12.1-1.4c-3.8-0.8-7.6-1.9-12-2.8
							C498.8,196,493.9,195.2,487.9,194.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M477.7,201.6c-6.3-0.9-12.6-0.4-17.3,0.8c-4.7,1.2-7.6,3.1-7.4,4.9c0.2,1.8,3.4,2.9,8,3.5c4.6,0.5,10.6,0.5,16.6-0.4
							c6.2-0.9,10.5-1.5,14.9-1.9c4.4-0.4,8.9-0.6,15.4-0.3c-6.4-1.4-10.8-2.5-15.2-3.5C488.5,203.7,484.2,202.6,477.7,201.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M478.3,205.9c-4.8-1-9.7-0.7-13.3,0.4c-3.6,1-5.9,2.8-5.8,4.5c0.1,1.8,2.6,3.1,6.1,3.9c3.5,0.7,8.1,0.8,12.8,0
							c4.8-0.8,8.1-1.5,11.5-2c3.4-0.5,6.8-0.9,11.9-0.8c-4.9-1.1-8.3-2-11.6-3C486.6,207.9,483.3,206.9,478.3,205.9z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M295.7,319.1c4.1-3.9,8.4-7.8,12.5-11.5c4.1-3.7,7.9-7.3,10.9-10.7c3.1-3.3,5.4-6.4,6.8-8.8c1.4-2.4,1.8-4.1,1.2-4.7
							c-0.6-0.6-2.3-0.1-4.6,1.2c-2.3,1.3-5.4,3.4-9,6c-3.6,2.6-7.7,5.8-12,9.5c-4.2,3.7-8.6,8.1-12.4,13c-3.9,5.1-6.7,9.7-8.9,14.2
							c-2.1,4.4-3.5,8.7-4.4,13c-0.2,1.1-0.4,2.2-0.6,3.3c-0.1,1.1-0.3,2.2-0.3,3.3c-0.2,2.3-0.2,4.6,0,7c0.4,4.8,1.6,10.3,4.8,15.8
							c-2.8-5.7-3.3-11.1-3.1-15.8c0.1-2.3,0.4-4.5,0.9-6.6c0.2-1,0.5-2,0.8-3c0.3-1,0.6-2,1-2.9c1.5-3.8,3.5-7.3,6.1-11
							C288.2,326.9,291.4,323.2,295.7,319.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M312.5,328c3.7-4.5,6.7-9.5,9-14.5c2.3-4.9,4-9.8,4.9-14.1c1.9-8.6,1.3-14.5-0.4-14.8c-1.8-0.3-4.2,4.8-7.5,12.2
							c-3.4,7.4-7.9,16.9-13.3,26.2c-2.7,4.8-5.4,8.7-7.9,12.1c-2.6,3.4-5.1,6.5-7.5,9.7c-2.4,3.2-4.8,6.6-6.9,10.7
							c-1,2.1-2,4.3-2.7,6.8c-0.7,2.5-1.2,5.2-1.1,8.2c0.2-3,0.9-5.6,1.9-7.9c1-2.3,2.2-4.3,3.4-6.1c2.5-3.6,5.3-6.4,8.2-9
							c2.9-2.7,5.9-5.1,9.3-8.2C305.2,336.2,308.7,332.7,312.5,328z"
                      />
                    </g>
                    <g>
                      <path
                        d="M312.9,315.8c4.4-4.6,7.7-10,9.4-14.5c1.7-4.5,1.8-8,0.3-8.8c-1.6-0.9-4.4,1.1-7.5,4.5c-3.1,3.4-6.5,8.3-9.3,13.8
							c-2.9,5.6-4.9,9.4-7.1,13.3c-2.2,3.8-4.6,7.6-8.7,12.7c4.9-4.4,8.3-7.4,11.7-10.3C305,323.4,308.3,320.5,312.9,315.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M309,313.8c3.6-3.4,6.2-7.5,7.4-11c1.2-3.5,1.2-6.4-0.3-7.3c-1.5-0.9-4,0.3-6.7,2.7c-2.6,2.5-5.4,6.1-7.4,10.4
							c-2.1,4.4-3.5,7.5-5,10.5c-1.6,3-3.3,6.1-6.2,10.2c3.8-3.4,6.5-5.6,9.2-7.8C302.6,319.3,305.3,317.2,309,313.8z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M112.7,104.3c4.4,3.7,8.6,7.6,12.7,11.2c4.1,3.6,8.1,7,11.7,9.8c3.6,2.7,6.9,4.7,9.5,5.9c2.5,1.1,4.2,1.4,4.7,0.7
							c0.5-0.7-0.2-2.2-1.7-4.4c-1.5-2.2-3.9-5-6.9-8.3c-3-3.3-6.6-7.1-10.7-10.9c-4.2-3.8-8.9-7.7-14.2-11
							c-5.4-3.3-10.4-5.7-15-7.3c-4.6-1.6-9-2.6-13.4-3c-1.1-0.1-2.2-0.1-3.3-0.2c-1.1,0-2.2,0-3.4,0c-2.3,0.1-4.6,0.3-7,0.8
							c-4.8,0.9-10.1,2.7-15.2,6.4c5.4-3.3,10.7-4.4,15.4-4.7c2.3-0.1,4.6,0,6.7,0.2c1.1,0.1,2.1,0.3,3.1,0.5c1,0.2,2,0.4,3,0.7
							c3.9,1.1,7.6,2.7,11.5,4.9C104.2,97.6,108.2,100.5,112.7,104.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M105.7,121.9c4.9,3.2,10.2,5.7,15.3,7.5c5.2,1.8,10.2,2.9,14.5,3.4c8.7,1,14.6-0.3,14.7-2c0.1-1.8-5.2-3.6-12.9-6.2
							c-7.7-2.6-17.7-6.1-27.5-10.4c-5.1-2.2-9.2-4.4-12.9-6.6c-3.7-2.2-7-4.4-10.5-6.5C83,99,79.4,97,75.1,95.4
							c-2.1-0.8-4.5-1.5-7-2c-2.5-0.5-5.3-0.6-8.3-0.2c3-0.1,5.7,0.3,8.1,1c2.4,0.7,4.5,1.7,6.5,2.7c3.9,2.1,6.9,4.6,9.8,7.2
							c2.9,2.6,5.7,5.4,9.1,8.4C96.7,115.5,100.6,118.7,105.7,121.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M117.9,121c5,3.9,10.8,6.6,15.4,7.8c4.7,1.2,8.1,1,8.8-0.6c0.7-1.6-1.5-4.2-5.3-7c-3.7-2.7-9-5.6-14.7-7.8
							c-5.8-2.3-9.9-3.9-13.9-5.7c-4-1.8-8-3.8-13.6-7.4c4.9,4.4,8.2,7.5,11.5,10.5C109.5,113.9,112.7,117,117.9,121z"
                      />
                    </g>
                    <g>
                      <path
                        d="M119.5,117c3.7,3.2,8.1,5.3,11.7,6.2c3.6,0.9,6.5,0.5,7.2-1.1c0.8-1.6-0.7-4-3.4-6.4c-2.7-2.4-6.7-4.7-11.1-6.3
							c-4.6-1.6-7.8-2.7-11-3.9c-3.2-1.2-6.4-2.6-10.8-5.1c3.8,3.4,6.2,5.8,8.7,8.3C113.2,111.2,115.6,113.7,119.5,117z"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M46.9,120.2c-4.8-3.3-10.4-5.5-16.3-6.1c-2.9-0.3-5.9-0.2-8.8,0.3c-2.9,0.5-5.8,1.4-8.4,2.9c-2.6,1.5-5.1,3.5-6.9,6.1
						c-0.5,0.7-0.9,1.3-1.2,2c-0.3,0.7-0.7,1.5-0.9,2.2L4,128.7l-0.2,1.1c-0.2,0.8-0.2,1.5-0.3,2.3c-0.1,3,0.5,5.8,1.6,8.3
						c1.1,2.5,2.6,4.6,4.5,6.3c1.8,1.7,4,3,6.2,3.6c2.1,0.7,4.2,1,6.1,0.9c1.9-0.1,3.6-0.4,5.2-0.9c1.5-0.5,2.8-1.1,3.9-1.8
						c2.2-1.3,3.6-2.9,4.3-4c0.7-1.2,0.8-2,0.5-2.2c-0.3-0.3-1.1-0.1-2.2,0.3c-1.1,0.4-2.6,1.1-4.6,1.5c-1,0.2-2,0.4-3.2,0.5
						c-1.1,0.1-2.4,0-3.6-0.2c-1.2-0.2-2.5-0.6-3.6-1.2c-1.2-0.6-2.1-1.4-3-2.4c-1.8-2.1-2.9-5.2-2.6-8.1c0.1-0.3,0.1-0.7,0.2-1
						l0.1-0.5l0.2-0.5c0.1-0.3,0.3-0.6,0.4-0.9c0.2-0.3,0.4-0.6,0.6-0.9c0.9-1.2,2.2-2.2,3.7-3.1c1.6-0.9,3.4-1.5,5.4-1.8
						c2-0.4,4.1-0.5,6.2-0.3c4.2,0.3,8.6,1.6,12.4,3.9c4,2.3,7.2,5.4,9.7,8.7c2.5,3.3,4.3,6.9,5.7,10.4c2.6,7.1,3.3,14.2,2.9,21.3
						c-0.4,7.1-1.8,14.3-4.7,22c-3,7.6-7.4,15.8-14.9,23.8c7.8-7.7,12.8-15.6,16.5-23.2c3.6-7.6,5.7-14.8,6.9-22.3
						c0.6-3.7,0.9-7.5,0.8-11.4c-0.1-3.9-0.5-8-1.6-12.1c-1.1-4.2-2.8-8.5-5.4-12.7C55.4,127.8,51.9,123.6,46.9,120.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M34,153.7c-3,0.6-5.5,2.1-7.5,4c-2,1.9-3.5,4.3-4.3,7c-0.4,1.3-0.6,2.7-0.6,4.1c0,1.4,0.2,2.8,0.7,4.1
						c0.8,2.5,2.4,4.7,4.4,6.1c1,0.7,2,1.2,3.1,1.5c1.1,0.3,2.1,0.4,3,0.4c1.9-0.1,3.4-0.7,4.4-1.5c1-0.8,1.6-1.6,1.8-2.3
						c0.2-0.7,0-1.2-0.3-1.5c-0.7-0.6-1.9-0.4-3.2-0.7c-0.7-0.1-1.4-0.4-2-0.7c-0.3-0.2-0.6-0.4-0.8-0.6c-0.2-0.2-0.4-0.5-0.6-0.8
						c-0.4-0.6-0.7-1.4-0.8-2.3c-0.1-0.4-0.1-0.9,0-1.3c0-0.5,0.2-1,0.3-1.5c0.6-2.1,2.6-4.5,4.7-5.5c1.1-0.5,2.1-0.7,3.3-0.5
						c1.2,0.2,2.3,0.8,3.3,1.6c1,0.8,1.8,1.7,2.5,2.8c0.7,1.1,1.2,2.3,1.6,3.6c0.8,2.6,1,5.5,0.9,8.8c-0.1,3.3-0.7,7-0.7,11.4
						c0.6-4.4,1.7-7.9,2.4-11.2c0.8-3.3,1.3-6.4,1.2-9.6c-0.1-1.6-0.3-3.3-0.8-4.9c-0.5-1.7-1.3-3.4-2.5-5c-1.2-1.6-2.8-3.2-5.1-4.3
						c-1.1-0.6-2.4-1-3.8-1.2C37.1,153.3,35.5,153.4,34,153.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M80.9,129c2-0.8,3.3-2.1,4-3.7c0.7-1.6,0.9-3.4,0.1-5c-0.8-1.5-2.7-2.3-4.8-1.9c-2.1,0.4-4.4,2-5.6,4.5
						c-1.2,2.6-1.6,4.5-1.9,6.4c-0.3,1.9-0.7,3.6-1.1,6.1c1.5-2,2.9-3.1,4.3-4C77.3,130.6,78.8,129.9,80.9,129z"
                    />
                  </g>
                  <g>
                    <path
                      d="M87.6,140.1c1.9,0,3.2-0.8,4.1-2.1c0.9-1.2,1.4-2.9,1-4.7c-0.4-1.7-1.8-3-3.7-3.3c-1.9-0.3-4.2,0.4-5.7,2.3
						c-1.5,2-2.3,3.5-3,5c-0.7,1.5-1.3,2.9-2.3,4.8c1.8-1.2,3.2-1.6,4.7-1.9C84.1,140.2,85.6,140.1,87.6,140.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M69.7,113.9c0.7-3.1,0.1-6.2-1.2-8.3c-1.3-2.1-3.2-3.1-4.9-2.7c-1.7,0.5-2.8,2.2-3.3,4.3c-0.5,2.1-0.3,4.6,0.7,7.2
						c1.1,2.6,1.8,4.6,2.5,6.6c0.6,2,1,4.1,1,7.1c1.1-2.9,1.9-4.9,2.8-6.9C68.1,119.2,69,117.1,69.7,113.9z"
                    />
                  </g>
                  <g>
                    <path d="M51.6,99.8c-0.9-5.8-6-9.3-9.1-7.5c-3,1.8-2.7,7.7,1.3,11.5c4.1,4,6.3,5.3,9.7,10.1C52.1,108.1,52.6,105.7,51.6,99.8z" />
                  </g>
                  <g>
                    <path
                      d="M25.9,96.2c-2.9-2.2-6.5-3.1-9.2-2.9c-2.8,0.2-4.8,1.4-5.1,3.1c-0.3,1.7,0.9,3.6,3,5c2,1.5,4.9,2.6,8.1,2.9
						c3.3,0.4,5.7,0.6,8.1,1.1c2.4,0.5,4.7,1.1,7.9,2.7c-2.6-2.5-4.3-4.2-6.1-6C30.7,100.2,28.9,98.4,25.9,96.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-1.6,103.7c-2.7-0.3-5.2,0.9-6.5,2.5c-1.4,1.7-1.5,3.6-0.6,5.1c1,1.5,2.6,2.2,4.2,2.4c1.6,0.2,3.2-0.2,4.8-1.5
						c1.6-1.3,3-2.3,4.4-3c1.4-0.8,3-1.3,5.4-1.4c-2.1-0.9-3.7-1.6-5.5-2.4C3,104.7,1.2,104-1.6,103.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-13.1,124.3c-2.5,1.7-3.7,4.6-3.5,6.9c0.2,2.4,1.6,3.9,3.3,4.1c1.8,0.2,3.4-0.6,4.6-1.5c1.3-1,2.1-2.2,2.5-4.1
						c0.4-2,0.9-3.5,1.7-5c0.8-1.4,1.9-2.8,4.1-4c-2.5,0.2-4.3,0.5-6.2,0.9C-8.6,122.1-10.6,122.6-13.1,124.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-9.6,149.9c-0.9,2.6-0.3,5.4,1.1,7.1c1.4,1.7,3.3,2.2,4.9,1.5c1.6-0.7,2.7-2.2,3.2-3.7c0.5-1.5,0.5-3.1-0.4-4.8
						c-0.9-1.8-1.5-3.3-1.9-4.9c-0.3-1.6-0.5-3.2,0.1-5.5c-1.6,1.8-2.7,3.2-3.8,4.6C-7.5,145.6-8.6,147.2-9.6,149.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M3.1,168.8c0.4,2.9,2.4,5.2,4.4,6.2c2.1,1,4.1,0.7,5.2-0.6c1.2-1.3,1.4-3.2,1.1-4.9c-0.3-1.7-1.2-3.3-2.9-4.7
						c-1.7-1.4-3-2.6-4.1-3.9c-1.1-1.3-2-2.9-2.7-5.4c-0.4,2.6-0.7,4.4-1,6.4C2.9,163.8,2.7,165.8,3.1,168.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M75.4,229.7c2.3,1.4,4,3.2,4.8,5.2c0.9,2,0.9,4.3,0.3,5.8c-0.6,1.5-2,2.8-3.6,3.4c-0.8,0.3-1.5,0.4-2.3,0.4
						c-0.8,0-1.6-0.2-2.3-0.6c-1.5-0.6-2.7-1.8-3.4-2.9c-0.7-1.1-0.9-2.1-0.8-2.9c0.1-0.8,0.6-1.6,1.1-2c0.4-0.5,0.9-0.8,1-1.2
						c0.1-0.4-0.3-1-1.5-1.3c-1.1-0.2-3,0.1-4.7,1.8c-0.4,0.4-0.8,1-1.1,1.6c-0.3,0.6-0.5,1.2-0.7,1.9c-0.3,1.4-0.3,2.8,0.1,4.3
						c0.7,2.9,2.5,5.8,5.5,7.9c1.5,1,3.3,1.8,5.3,2.3c2,0.4,4.2,0.4,6.3-0.2c2.1-0.5,4.1-1.5,5.9-3c1.8-1.4,3.3-3.4,4.2-5.7
						c0.9-2.3,1.3-4.7,1.1-7c-0.1-2.3-0.8-4.6-1.8-6.7c-1-2.1-2.5-4-4.2-5.5c-1.7-1.5-3.6-2.6-5.6-3.4c-4.1-1.7-8.1-2-11.5-1.8
						c-3.5,0.2-6.5,0.9-9.3,1.7c-5.5,1.7-10.2,3.7-14.6,6.1c-4.5,2.5-8.8,5.2-13,9c-2.1,1.9-4.3,4-6.3,6.4c-2.1,2.5-4.1,5.2-5.8,8.5
						c2-3.1,4.2-5.7,6.4-7.9c2.2-2.2,4.5-4.1,6.8-5.6c4.5-3.2,9-5.2,13.5-6.9c4.5-1.7,9.2-2.8,14.4-3.6c2.6-0.3,5.2-0.5,7.9-0.3
						c1.3,0.1,2.7,0.3,4,0.7C72.9,228.5,74.2,229,75.4,229.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M55.5,215c4.5-2.5,8.1-6.3,10.1-9.7c2-3.4,2.4-6.3,1-7.5c-1.4-1.1-4.1-0.2-7.1,1.7c-3.1,2-6.5,5-9.6,8.6
						c-3.2,3.8-5.4,6.3-7.9,8.8c-2.5,2.5-5.2,4.8-9.6,7.6c4.8-1.8,8.2-3.1,11.7-4.4C47.5,218.9,50.9,217.5,55.5,215z"
                    />
                  </g>
                  <g>
                    <path
                      d="M75.4,216.2c2.3,0.5,4.3,0.1,5.9-0.8c1.6-1,2.7-2.5,2.7-4.3c0-1.8-1.2-3.3-3.1-4.1c-1.9-0.8-4.5-0.8-6.8,0.5
						c-2.4,1.3-3.8,2.5-5.3,3.6c-1.5,1.1-2.9,2.2-5.1,3.5c2.5-0.3,4.3-0.1,6,0.2C71.3,215.2,73,215.7,75.4,216.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M35.3,208.6c0.9-2.4,1.3-5,1.3-7.4c0-0.6,0-1.2-0.1-1.8c-0.1-0.6-0.1-1.1-0.2-1.7c-0.2-1.2-0.6-2.3-1.1-3.4
						c-0.5-1.1-1.1-2-1.9-2.8c-0.7-0.8-1.6-1.3-2.3-1.6c-1.6-0.5-2.6,0-3,0.7c-0.4,0.8-0.3,1.8-0.5,2.6c-0.1,0.9-0.3,1.8-0.4,3
						c-0.1,0.7-0.1,1.4-0.2,2.1c0,0.4-0.1,0.9-0.1,1.3c0,0.5,0,0.9-0.1,1.4c-0.1,1.9-0.1,3.9,0,6c0.1,4.3-0.2,7.5-0.8,10.6
						c-0.7,3.1-1.8,6.3-4.7,10.3c3.7-3.3,5.9-5.9,8.1-8.7c1.1-1.4,2.1-2.8,3.1-4.5C33.4,213,34.4,211.1,35.3,208.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M124.7,53.8c3.3-0.9,6.7-1.7,9-1.4c1.2,0.1,2,0.4,2.7,0.9c0.6,0.4,1.2,1.1,1.6,2c0.9,1.8,1,4.3,0.5,6.2
						c-0.1,0.5-0.3,1-0.5,1.5c-0.2,0.5-0.5,0.9-0.7,1.4c-0.5,0.8-1.2,1.5-1.8,2.1c-1.2,1-2.9,1.5-4.2,1.4c-0.7,0-1.3-0.2-1.8-0.4
						c-0.5-0.3-1-0.6-1.4-1c-1.7-1.6-1.8-3.6-2.6-3.9c-0.4-0.1-1,0.2-1.5,1.2c-0.5,1-0.8,2.8-0.1,4.9c0.3,1,0.9,2.2,1.9,3.2
						c1,1.1,2.2,2,3.8,2.6c3,1.2,6.9,1.2,10.4-0.7c1.7-0.9,3.3-2.2,4.6-3.7c0.7-0.8,1.2-1.6,1.8-2.5c0.5-0.9,1-1.8,1.4-2.9
						c0.8-2.1,1.1-4.3,1.1-6.5c-0.1-2.3-0.5-4.6-1.5-6.9c-1.1-2.3-2.8-4.5-5.2-6c-2.4-1.5-5.1-2.2-7.6-2.3c-2.5-0.1-4.7,0.3-6.8,0.9
						c-2.1,0.6-3.9,1.2-5.7,2c-3.8,1.6-7.6,3.8-10.3,7c-1.3,1.6-2.3,3.5-2.9,5.3c-0.6,1.8-0.9,3.6-0.9,5.3c-0.1,3.4,0.5,6.4,1.3,9.2
						c0.9,2.8,2,5.4,3.4,7.8c2.8,4.8,6.6,9.1,11.7,12.4c2.6,1.7,5.5,3.1,8.7,4.2c3.3,1.1,6.9,1.7,10.9,1.8
						c-3.9-0.3-7.5-1.3-10.6-2.6c-3.1-1.3-5.7-3-8-4.8c-4.5-3.7-7.5-8.1-9.4-12.7c-1-2.3-1.6-4.7-2-7.2c-0.4-2.5-0.5-5,0-7.5
						c0.2-1.2,0.6-2.4,1.1-3.4c0.5-1,1.2-2,2.1-2.8C118.7,56,121.3,54.8,124.7,53.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M86,72c-3-1.1-6.3-0.6-8.4,0.7c-2.1,1.3-3.1,3.2-2.6,4.9c0.5,1.7,2.2,2.8,4,3.3c1.9,0.6,4,0.6,6.4-0.2
						c2.5-0.8,4.3-1.2,6.3-1.4c1.9-0.2,4-0.2,6.8,0.7c-2.3-1.8-4-3.1-5.7-4.3C90.9,74.4,89.1,73.1,86,72z"
                    />
                  </g>
                  <g>
                    <path
                      d="M99.5,62.1c0.8-1.3,0.8-2.3,0.3-3.6c-0.5-1.2-1.3-2.8-2.7-3.8c-0.7-0.5-1.6-0.7-2.5-0.6c-0.9,0.1-1.8,0.5-2.6,1.3
						c-0.8,0.8-1.4,1.9-1.7,3.2c-0.3,1.3-0.2,2.8,0.4,4.1c1.2,2.8,2.7,4.1,4.1,5.3c1.4,1.1,2.9,2,4.9,2.9c-1.3-1.8-1.6-3.3-1.5-4.7
						C98.2,64.8,98.7,63.5,99.5,62.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M152.6,18.3c3.1-2.4,6-4.9,8.2-6.9c2.2-2,3.6-3.6,3.1-4.4c-0.4-0.8-2.6-0.5-5.5,1c-2.8,1.4-6.3,4-8.9,7.3
						c-2.7,3.4-4.2,5.9-5.6,8.5c-1.4,2.6-2.7,5.2-4.4,9.2c2.2-3.7,4-6,6-8.1C147.4,22.8,149.4,20.8,152.6,18.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M161.5,23.3c3.2-1.8,6.3-3.8,8.5-5.5c2.2-1.7,3.6-3.2,3.2-4c-0.4-0.8-2.5-0.7-5.2,0.3c-2.8,1-6.2,3-9.1,5.6
						c-2.9,2.7-4.7,4.8-6.5,6.9c-1.7,2.1-3.5,4.3-5.9,7.5c2.8-2.9,5-4.6,7.2-6.1C155.9,26.6,158.1,25.2,161.5,23.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M160,33.4c2.1-0.6,3.9-1.6,5.2-2.7c1.3-1,2-2.1,1.7-3c-0.3-0.8-1.6-1.2-3.4-0.9c-1.7,0.3-3.9,1.2-5.7,2.7
						c-1.8,1.6-2.9,2.8-4,4c-1.1,1.2-2.2,2.4-3.9,4.1c2-1.3,3.5-1.9,4.9-2.5C156.3,34.6,157.8,34.1,160,33.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M207.1,151.4c1.6-3.8,3.4-7.5,4.8-10.2c1.4-2.8,2.4-4.8,1.8-5.4c-0.6-0.6-2.8,0.4-5.2,3c-2.4,2.6-4.7,6.8-5.6,11.4
						c-0.8,4.7-0.7,8-0.3,11.2c0.4,3.2,1,6.4,2.6,10.8c-1.1-4.5-1.1-7.7-0.7-10.8C204.8,158.3,205.5,155.3,207.1,151.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M186.8,193.3c-0.7,3.1-1.2,5.4-1.8,8c-0.5,2.2-0.7,3.9,0,4.4c0.7,0.5,2.3-0.5,3.8-2.8c1.4-2.2,2.6-5.8,2.3-9.3
						c-0.2-3.6-1-6-1.8-8.2c-0.9-2.2-1.9-4.4-3.8-7.3c1.5,3.1,1.8,5.5,2,7.7C187.6,188,187.5,190.3,186.8,193.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M166,263.2c-0.7,3.5-2,6.9-3.1,9.4c-1.1,2.5-2,4.4-1.3,5c0.6,0.6,2.7-0.2,4.9-2.8c1.1-1.3,2.1-2.9,2.8-4.9
						c0.7-1.9,1.2-4.2,1.1-6.4c0-2.3-0.4-4.1-0.9-5.8c-0.5-1.6-1-3-1.7-4.3c-1.4-2.6-3-5.2-6.2-8.1c2.8,3.3,3.8,6,4.4,8.7
						c0.3,1.3,0.4,2.7,0.5,4.2C166.5,259.8,166.4,261.4,166,263.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M249.5,317.1c1.2-0.7,2.4-1.2,3.5-1.4c0.6-0.1,1.1-0.1,1.6-0.1c0.5,0,1,0.2,1.5,0.3c2,0.7,4,2.6,5.2,4.8
						c0.3,0.5,0.5,1.1,0.8,1.7c0.2,0.6,0.4,1.2,0.5,1.8c0.2,1.2,0.3,2.3,0.2,3.3c-0.2,1.9-1.3,3.4-2.5,4.1c-0.1,0.1-0.3,0.2-0.4,0.2
						c-0.1,0.1-0.3,0.1-0.4,0.2c-0.3,0.1-0.6,0.1-0.9,0.2c-0.7,0-1.3-0.1-1.8-0.4c-0.5-0.2-0.9-0.6-1.2-0.9
						c-0.3-0.4-0.5-0.9-0.5-1.4c-0.1-1.1,0.1-2,0.4-2.8c0.3-0.7,0.6-1.3,0.4-1.7c-0.1-0.4-0.8-0.7-2-0.3c-1.1,0.4-2.7,1.6-3.5,3.8
						c-0.4,1.1-0.6,2.5-0.3,4.1c0.3,1.6,1.1,3.2,2.4,4.5c1.3,1.3,3,2.2,4.9,2.7c1,0.2,2,0.3,3.1,0.3c0.5,0,1.1-0.1,1.7-0.2
						c0.5-0.1,1.1-0.3,1.6-0.4c4.2-1.5,7.4-5.3,8.5-10c0.5-2.3,0.6-4.6,0.2-6.8c-0.2-1.1-0.4-2.2-0.8-3.3c-0.4-1.1-0.8-2.2-1.4-3.2
						c-1.1-2.1-2.5-4-4.4-5.6c-1.8-1.6-4.1-3-6.7-3.8c-2.6-0.8-5.5-0.8-8.1-0.1c-2.6,0.7-4.8,2-6.7,3.5c-3.8,3.3-5.9,7.3-7.1,11
						c-1.1,3.8-1.4,7.4-1.1,10.7c0.2,3.3,1,6.4,1.9,9.1c1,2.8,2.1,5.4,3.5,7.8c1.3,2.5,2.7,4.9,4.3,7.3c1.5,2.4,3.1,4.8,4.9,7.4
						c3.6,5,7.8,10.5,13.5,16.3c-5.3-6.2-9-11.9-12.1-17.2c-1.5-2.7-2.8-5.2-4-7.8c-1.2-2.5-2.3-5-3.2-7.5c-0.9-2.5-1.7-5-2.2-7.6
						c-0.5-2.6-0.7-5.2-0.6-7.9c0.2-2.7,0.8-5.5,2-8.1c0.6-1.3,1.3-2.5,2.2-3.7C247.3,318.8,248.3,317.8,249.5,317.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M264.6,361c0.7-2.6,1-4.8,0.9-6.9c-0.1-2.1-0.6-4.1-2-5.2c-0.7-0.5-1.7-0.6-2.7-0.3c-1,0.3-2.1,1.1-3.1,2.3
						c-1,1.2-1.7,2.8-2.1,4.7c-0.4,1.9-0.3,3.9,0.3,5.9c0.6,2,1.4,3.6,2.2,4.9c0.8,1.3,1.7,2.3,2.6,3.3c1.8,1.9,3.7,3.6,6.7,5.5
						c-2.3-2.7-3-5-3.3-7.2C263.8,365.9,263.9,363.7,264.6,361z"
                    />
                  </g>
                  <g>
                    <path
                      d="M284.7,370c-3.2,6-5.2,12.1-7.6,17c-1.2,2.5-2.4,4.7-3.7,6.6c-0.7,0.9-1.4,1.8-2.1,2.6c-0.7,0.8-1.4,1.4-2.1,2
						c-1.4,1.1-2.5,1.5-3.5,1.7c-0.3,0.1-0.8,0-1.2-0.1c-0.5-0.2-0.9-0.4-1.2-0.7c-0.7-0.5-1.1-1.1-1.5-1.7
						c-0.3-0.6-0.5-1.1-0.9-1.3c-0.2-0.1-0.4-0.1-0.7,0c-0.3,0.1-0.6,0.4-0.9,0.8c-0.6,0.9-1,2.7-0.3,4.6c0.3,1,0.9,1.9,1.8,2.8
						c0.9,0.9,2.1,1.8,3.7,2.2c1.4,0.4,3,0.5,4.6,0.3c1.6-0.2,3.1-0.7,4.5-1.4c1.4-0.7,2.7-1.6,3.9-2.6c1.2-1,2.3-2.1,3.4-3.3
						c2.1-2.4,3.9-5.2,5.3-8.2c2.9-5.9,4.6-12.1,6.6-17.7c1.1-2.9,2.2-5.3,3.6-7.5c1.4-2.2,2.8-4.2,4.4-5.9c0.7-0.9,1.6-1.7,2.4-2.5
						c0.9-0.7,1.7-1.5,2.5-2.2c0.4-0.3,0.9-0.7,1.3-1c0.4-0.3,0.9-0.6,1.3-0.9c0.9-0.6,1.8-1.2,2.8-1.7c3.8-2.2,8-3.8,13-4.7
						c1.2-0.2,2.5-0.4,3.9-0.4c1.3-0.1,2.7-0.1,4.1,0.1c2.8,0.3,5.9,1.2,8.4,3.6c-2.3-2.6-5.4-3.8-8.3-4.4c-1.5-0.3-2.9-0.5-4.3-0.6
						c-1.4-0.1-2.7,0-4.1,0c-5.2,0.3-9.9,1.3-14.4,3c-1.1,0.4-2.2,0.9-3.3,1.4c-0.5,0.3-1.1,0.5-1.6,0.8c-0.5,0.3-1.1,0.6-1.6,0.9
						c-1.1,0.6-2.1,1.3-3.2,2c-1,0.8-2.1,1.5-3.2,2.3c-2.1,1.7-4.2,3.7-6.2,6C288.2,364.1,286.3,366.9,284.7,370z"
                    />
                  </g>
                  <g>
                    <path
                      d="M311.3,353.5c-2.3,1-4.3,2.4-5.9,4.2c-1.6,1.7-2.9,3.8-3.4,5.9c-0.6,2.1-0.3,4.2,0.4,5.5c0.7,1.3,1.7,1.7,2.5,1.5
						c0.9-0.2,1.6-0.8,2.3-1.4c0.7-0.6,1.5-1.2,2.3-1.9c0.9-0.7,1.8-1.6,3-2.7c1.2-1.1,2.4-2.4,3.6-3.7c2.6-2.8,4.9-4.5,7.5-5.8
						c1.3-0.6,2.7-1.3,4.3-1.7c1.6-0.4,3.5-0.7,5.7-0.7c-2.2-0.6-4.1-0.9-5.9-1.1c-1.8-0.2-3.4-0.3-5-0.3c-1.6,0-3.3,0.1-5.1,0.4
						C315.7,352,313.7,352.5,311.3,353.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M333.5,332.9c0.1-2-0.4-3.5-1.3-5c-0.9-1.4-2.2-2.8-3.9-3.1c-0.9-0.2-1.7,0-2.5,0.5c-0.8,0.5-1.5,1.4-1.9,2.6
						c-0.4,1.2-0.6,2.6-0.4,4.2c0.3,1.5,0.9,3.1,2,4.3c2.2,2.6,4.3,3.7,6.2,4.6c1.9,0.9,3.9,1.5,6.5,2.1c-2.2-1.6-3.2-3.2-3.8-4.8
						C333.7,336.7,333.4,335,333.5,332.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M523.9,354.8c0.8-4,0.7-8.3-0.4-12.2c-1-3.9-2.9-7.6-5.4-10.8c-2.5-3.2-5.7-5.8-9.2-7.6c-3.5-1.8-7.3-3-11-3.4
						c-3.8-0.4-7.6-0.2-11.2,0.8c-3.6,0.9-7.2,2.6-10.1,5.5c-1.4,1.4-2.6,3.2-3.4,5c-0.8,1.8-1.3,3.7-1.4,5.5
						c-0.3,3.7,0.5,7.3,2.3,10.3c1.8,3,4.4,5.2,7.1,6.5c2.7,1.3,5.5,1.8,8.1,1.8c2.6-0.1,5-0.8,7-1.8c2-1,3.5-2.4,4.6-3.7
						c2.3-2.7,3.1-5.3,3.4-7.1c0.2-1.8-0.1-2.8-0.5-2.8c-0.5-0.1-1,0.7-1.9,1.9c-0.9,1.2-2.2,3-4.3,4.5c-1,0.7-2.3,1.4-3.7,1.8
						c-1.4,0.4-2.9,0.6-4.5,0.4c-1.6-0.2-3.2-0.7-4.7-1.7c-1.5-0.9-2.7-2.2-3.4-3.7c-0.7-1.5-1-3.5-0.7-5.3c0.1-0.9,0.4-1.8,0.8-2.6
						c0.4-0.8,0.9-1.4,1.6-2.1c2.8-2.6,8.4-3.9,13.5-3.2c5.3,0.6,10.4,3.1,13.9,7.3c3.5,4.2,5.4,9.9,4.7,15.4
						c-0.8,5.7-4.1,10.7-8.1,14.1c-4.1,3.4-9,5.5-13.8,6.4c-4.9,1-9.8,1-14.5,0.4c-4.7-0.6-9.3-1.9-13.8-3.5
						c-4.5-1.6-8.9-3.6-13.5-5.8c-4.5-2.2-9.2-4.6-14.3-6.8c-5.1-2.2-10.8-4.1-17.1-4.8c-3.1-0.3-6.4-0.3-9.8,0.1
						c-1.7,0.3-3.4,0.7-5,1.2c-1.7,0.6-3.3,1.3-4.9,2.2c1.6-0.8,3.3-1.5,5-2c1.7-0.5,3.4-0.8,5-1c3.3-0.3,6.6-0.2,9.6,0.3
						c6.2,0.9,11.5,3.2,16.4,5.6c4.9,2.4,9.4,5.1,13.8,7.7c4.4,2.5,8.8,4.9,13.3,7c4.6,2,9.3,3.8,14.4,4.8
						c5.1,1.1,10.6,1.5,16.4,0.8c5.7-0.7,11.9-2.6,17.5-6.7c2.7-2,5.3-4.5,7.5-7.6C521.4,362.6,523.1,358.9,523.9,354.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M447.8,308.1c-3.2-3.2-7.6-5.5-12.3-6.1c-4.7-0.7-9.8,0.3-13.9,3.4c-2,1.5-3.5,3.4-4.6,5.6c-1.1,2.1-1.7,4.5-1.7,7
						c0,0.6,0.1,1.2,0.1,1.8c0.1,0.6,0.2,1.2,0.4,1.8c0.3,1.2,0.9,2.2,1.4,3.2c1.2,1.9,2.7,3.4,4.5,4.5c1.8,1.1,3.7,1.7,5.7,1.8
						c1.9,0.1,3.9-0.4,5.4-1.3c1.5-0.9,2.6-2,3.3-3.1c0.8-1.1,1.2-2.3,1.5-3.3c0.5-2.1,0-3.9-0.6-4.9c-0.3-0.5-0.6-0.8-0.9-1
						c-0.3-0.2-0.5-0.2-0.7-0.1c-0.2,0.1-0.4,0.3-0.5,0.6c-0.1,0.3-0.2,0.6-0.4,1c-0.3,0.8-0.6,1.7-1.4,2.6c-0.7,0.9-1.9,1.8-3.1,2
						c-1.1,0.2-2.3-0.1-3.5-1.2c-0.6-0.5-1.1-1.2-1.4-2c-0.1-0.4-0.3-0.7-0.3-1.1c0-0.2,0-0.3,0-0.5c0-0.2,0-0.4,0-0.6
						c0.1-1.6,1.2-3.6,2.9-4.8c1.6-1.2,4-1.9,6.7-1.6c2.7,0.2,5.3,1.3,7.7,3.1c2.4,1.8,4.2,4.3,5.3,6.8c1.1,2.5,1.5,5.1,1.3,7.6
						c-0.3,5-2.7,9.6-6.2,12.9c-3.5,3.3-8.6,5.3-14.4,5.7c-5.9,0.5-12.6-0.9-20.1-3.5c7.3,3.1,13.9,5,20.1,5.2
						c6.2,0.2,12-1.1,16.8-4.5c2.4-1.7,4.5-3.8,6.2-6.3c1.7-2.5,3.1-5.4,3.7-8.8C456.1,323.3,454.5,314.8,447.8,308.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M458.9,328.3c-2.4,2.5-3.5,5.9-3.3,8.5c0.2,2.7,1.6,4.5,3.3,4.7c1.8,0.2,3.4-0.9,4.8-2.5c1.3-1.6,2.3-3.7,2.8-6.3
						c0.5-2.7,1-4.7,1.8-6.7c0.8-1.9,1.8-3.8,4.1-6.1c-2.9,1.3-4.9,2.4-6.9,3.5C463.4,324.6,461.4,325.8,458.9,328.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M463.1,362.7c0.5,3.2,2.7,5.9,5,6.9c2.3,1.1,4.4,0.7,5.5-0.7c1-1.4,1-3.4,0.6-5.1c-0.5-1.8-1.4-3.3-3.1-4.8
						c-1.7-1.6-3-3-3.9-4.5c-1-1.6-1.8-3.4-1.9-6.2c-0.9,2.7-1.4,4.7-1.9,6.8C462.9,357.2,462.6,359.4,463.1,362.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M490.6,369c1.7,0.6,3.5,0.7,5.2,0.5c1.7-0.3,3.1-0.9,4.2-1.7c2.3-1.7,2.9-3.9,2-5.4c-0.9-1.5-2.7-2.2-4.6-2.6
						c-1.9-0.3-3.8-0.2-6.1,0.5c-2.3,0.7-4.3,0.9-6.3,0.8c-2-0.2-4-0.7-6.5-2.5c1.7,2.6,3.2,4.3,4.9,5.9
						C485.2,366,487.1,367.7,490.6,369z"
                    />
                  </g>
                  <g>
                    <path
                      d="M448.5,349.2c-1.6-0.6-2.7-0.5-4,0.1c-1.3,0.6-2.7,1.6-3.5,3.2c-0.4,0.8-0.5,1.7-0.2,2.5c0.2,0.9,0.8,1.7,1.7,2.4
						c0.9,0.7,2.1,1.2,3.5,1.3c1.3,0.1,2.8-0.1,4.1-0.8c2.7-1.5,3.9-3.1,5-4.5c1.1-1.5,1.9-3.1,2.9-5.2c-1.8,1.5-3.4,1.9-4.9,1.9
						C451.6,350.1,450.2,349.9,448.5,349.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M441,389.9c-1.2,1.3-3,2.2-4.8,2.4c-1.7,0.3-3.6,0-5.4-0.8c-1.8-0.7-3.5-2-4.7-3.3c-1.2-1.3-1.9-2.6-2-3.5
						c-0.2-1,0.1-2.1,0.4-2.7c0.3-0.6,0.9-1,1.7-1.1c0.8-0.1,1.5,0,2.1,0.2c0.6,0.2,1.1,0.4,1.5,0.3c0.4-0.1,0.8-0.6,0.6-1.7
						c-0.2-1-1-2.4-2.7-3.4c-0.8-0.5-1.9-0.8-3.2-0.9c-1.3-0.1-2.7,0.3-4,1.1c-2.7,1.6-4.5,4.5-4.8,8c-0.3,3.6,1.1,7,3.1,9.7
						c2,2.7,4.7,4.9,8,6.4c3.3,1.5,7.3,2.2,11.4,1.2c3.9-0.9,7.6-3.3,9.9-6.8c0.6-0.9,1.1-1.9,1.4-2.9c0.4-1,0.6-2,0.7-2.9
						c0.3-1.9,0.2-3.7-0.1-5.3c-0.6-3.2-1.9-5.9-3.3-8.1c-1.5-2.2-3.2-4.1-5-5.6c-1.8-1.5-3.7-2.8-5.7-3.8c-4-2.1-8.4-3.3-13.2-3.8
						c-4.9-0.5-10.3-0.3-16.5,0.5c12.6-0.7,21.4,1.7,27.8,6.5c3.2,2.4,5.8,5.4,7.4,9.2c0.8,1.9,1.3,3.9,1.3,5.9
						C442.8,386.8,442.3,388.7,441,389.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M457.3,391.4c0,2.2,1.1,3.9,2.5,4.7c1.5,0.9,3.3,0.9,4.8,0c1.5-0.9,2.4-2.4,2.6-4c0.2-1.5-0.4-3.1-1.9-4.3
						c-1.5-1.2-2.7-2.1-3.9-3c-1.1-0.9-2.1-2-3.1-3.7c0,2-0.2,3.5-0.4,5C457.7,387.6,457.4,389.2,457.3,391.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M431.7,410.6c-2.8,1.3-4.6,3.9-5.2,6.2c-0.6,2.3,0.1,4.3,1.6,5.2c1.5,0.9,3.5,0.6,5.2-0.3c1.7-0.9,3.3-2.4,4.4-4.7
						c1.1-2.3,2-4,3.1-5.6c1.1-1.6,2.4-3.1,4.8-4.7c-2.7,0.6-4.7,1-6.8,1.5C436.6,408.7,434.5,409.2,431.7,410.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M389.6,226.7c1.7,0.3,3.4,0.1,5-0.1c1.5-0.3,2.9-0.8,4-1.3c2.2-1.1,3.4-2.3,3.2-3.1c-0.3-0.9-2-1.1-4.3-0.9
						c-2.3,0.2-5.1,0.7-7.8,1.1c-2.8,0.4-4.9,0.2-6.8-0.5c-1-0.3-1.9-0.8-2.9-1.6c-0.9-0.7-1.9-1.8-2.6-3.3c0.4,1.6,1.1,2.9,1.9,3.9
						c0.8,1,1.7,1.9,2.6,2.6c0.9,0.7,2,1.4,3.2,1.9C386.4,226,387.9,226.5,389.6,226.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M392.5,238.2c1.9,2.5,3.7,4.8,5.3,6.6c1.6,1.7,2.9,2.9,3.7,2.5c0.8-0.3,0.8-2.2-0.1-4.7c-1-2.4-3-5.4-5.8-7.5
						c-2.9-2.2-5-3.2-7.2-4.2c-2.2-1-4.4-1.9-7.8-2.8c3.1,1.5,5,2.9,6.7,4.4C389.1,234,390.6,235.6,392.5,238.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M351.7,217.7c-0.7,2.4-1.3,4.7-1.7,6.6c-0.4,1.9-0.5,3.4,0.2,3.9c0.7,0.5,2.2-0.2,3.6-2.1c1.4-1.8,2.4-4.8,2.3-7.9
						c-0.2-3.1-0.8-5.1-1.4-7.1c-0.7-1.9-1.5-3.8-3.1-6.3c1,2.7,1.2,4.7,1.2,6.6C352.7,213.3,352.5,215.2,351.7,217.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M446.3,284.3c3.7-0.6,7.2-2.4,9.4-4.3c2.2-1.9,3-3.7,2.5-4.4c-0.6-0.7-2.4-0.1-4.7,0.8c-2.3,1-5.3,2.3-8.3,3.6
						c-3.2,1.4-5.5,2-7.9,2.5c-2.4,0.4-5,0.7-8.7,0.1c3.6,1.1,6.2,1.5,8.8,1.8C439.8,284.8,442.4,284.9,446.3,284.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M455.1,296.1c2.5,0.2,5-0.7,6.5-1.9c1.5-1.2,2-2.6,1.4-3.3c-0.6-0.7-1.8-0.7-3.3-0.4c-1.5,0.2-3.2,0.7-5.1,1.3
						c-1.9,0.6-3.4,0.9-4.9,0.9c-1.5,0-3.1-0.1-5.3-1.1c1.9,1.4,3.3,2.3,4.9,3C450.8,295.2,452.5,295.8,455.1,296.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M512.7,278.9c-0.8,1.7-2.2,3.2-3.7,4.4c-1.5,1.2-3.3,2.3-4.8,3.4c-1.5,1.1-2.8,2.1-3.5,3c-0.8,0.9-1,1.6-0.8,2
						c0.3,0.4,1,0.3,2,0.1c1.1-0.2,2.5-0.7,4.2-1.4c1.7-0.7,3.8-1.6,5.9-3.2c2-1.6,4-3.9,4.8-6.9c0.4-1.5,0.4-3,0.2-4.4
						c-0.3-1.3-0.8-2.5-1.4-3.4c-1.2-1.9-2.8-3.1-4.4-4c-3.3-1.8-7-2.2-12.2-2.6c2.6,0.5,4.7,0.9,6.5,1.6c1.8,0.7,3.3,1.5,4.5,2.6
						c1.2,1.1,2.2,2.4,2.8,3.9C513.5,275.5,513.5,277.2,512.7,278.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M421,118.9c2.1-3.8,2.7-8.3,2.3-11.5c-0.4-3.3-1.6-5.2-2.4-5c-0.9,0.2-1.3,2.2-1.8,4.9c-0.5,2.8-1.2,6.3-2.2,9.9
						c-1.1,3.7-2.3,6.2-3.8,8.5c-1.5,2.3-3.4,4.6-6.9,6.9c3.8-1.9,6.2-3.7,8.4-5.7C416.7,125,418.8,122.8,421,118.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M423,162.8c5.4,0.3,10.9,0.4,15,0.3c4.1-0.1,6.9-0.6,7-1.5c0.1-0.9-2.6-2-6.7-2.7c-4.2-0.7-9.8-1.1-15.4-0.5
						c-5.7,0.6-9.6,1.3-13.5,2.1c-3.9,0.8-7.8,1.6-13.5,3c5.8-0.8,9.8-1,13.7-1.1C413.6,162.4,417.4,162.5,423,162.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M428.4,172.9c6.4,1.8,13.2,1.4,13.5-0.3c0.2-1.7-6.2-4-12.9-4c-6.9,0-9.5,0.3-16.5-0.1
						C419.4,169.9,421.8,171,428.4,172.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M465.2,185.4c3.3-1.4,6.4-3,8.7-4.4c2.3-1.4,3.8-2.7,3.5-3.5c-0.3-0.8-2.3-1-5.1-0.3c-2.8,0.7-6.3,2.2-9.3,4.5
						c-3.1,2.4-5,4.3-6.8,6.1c-1.8,1.9-3.7,3.9-6.2,6.8c2.9-2.6,5.1-4,7.4-5.3C459.5,188,461.8,186.9,465.2,185.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M479.4,189.5c3.5-0.2,7-0.5,9.6-1c2.6-0.4,4.5-1,4.6-1.9c0.1-0.9-1.7-1.8-4.6-2.3c-2.8-0.5-6.7-0.3-10.4,0.8
						c-3.7,1.2-6.2,2.3-8.5,3.5c-2.4,1.2-4.7,2.5-8,4.6c3.5-1.6,6.1-2.3,8.6-2.8C473.3,190.1,475.8,189.7,479.4,189.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M496,177.4c5.3-1.4,9.4-5.2,8.5-6.8c-0.9-1.5-6-0.2-10.3,2.8c-2.2,1.5-3.8,2.6-5.3,3.7c-1.6,1-3.2,2-5.8,3.2
						c2.7-0.7,4.6-1.1,6.5-1.5C491.4,178.5,493.3,178.1,496,177.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M508.6,181.3c2.3,0.5,4.6,0.6,6.3,0.4c1.8-0.2,3.1-0.7,3.3-1.5c0.2-0.9-0.9-1.9-2.7-2.6c-1.8-0.7-4.4-1.1-6.9-0.6
						c-2.6,0.4-4.3,1-6,1.5c-1.8,0.6-3.6,1.2-5.8,2c2.6-0.3,4.3-0.3,6-0.1C504.5,180.5,506.2,180.8,508.6,181.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M515.5,191.4c5.5,1.8,11.5,1.5,11.8-0.3c0.3-1.7-5.2-4-11-4.1c-6-0.1-8.3,0.3-14.4-0.2
						C507.8,188.4,509.8,189.5,515.5,191.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M537.8,184.7c2.8,0.1,5.6-0.6,7.4-1.6c1.9-1,2.9-2.2,2.6-3c-0.3-0.8-1.8-1.1-3.7-1c-2,0.1-4.4,0.5-6.9,1.3
						c-2.5,0.8-4.3,1.3-6.2,1.7c-1.9,0.4-3.8,0.6-6.6,0.6c2.8,0.6,4.7,1,6.6,1.3C533,184.3,535,184.6,537.8,184.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M537.5,176.3c2.8-0.1,5.5-1.1,7.4-2.2c1.8-1.1,2.8-2.4,2.4-3.2c-0.4-0.8-1.9-1-3.8-0.7c-2,0.2-4.4,0.9-6.9,1.9
						c-2.5,1-4.3,1.6-6.1,2.2c-1.8,0.5-3.7,1-6.6,1.2c2.9,0.3,4.8,0.5,6.8,0.7C532.6,176.2,534.6,176.4,537.5,176.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M535.8,166.5c2.7-0.6,5.2-1.8,6.8-3c1.7-1.3,2.5-2.6,2.1-3.4c-0.8-1.6-6.1-0.4-10.6,2.3c-2.3,1.4-4,2.4-5.6,3.4
						c-1.7,0.9-3.4,1.8-6.1,2.9c2.8-0.5,4.7-0.8,6.7-1C531.1,167.3,533,167.1,535.8,166.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M557.3,160.8c1.9-0.2,3.7-0.8,5-1.5c1.3-0.7,2.2-1.5,2.2-2.4c-0.1-0.9-1.2-1.6-2.9-1.7c-1.7-0.2-3.9,0.3-5.8,1.5
						c-1.9,1.2-3.1,2.3-4.2,3.4c-1.1,1.1-2.2,2.1-3.7,3.7c1.8-1.2,3.2-1.7,4.6-2.1C553.9,161.3,555.3,161,557.3,160.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M366.2,478.6c3.4-1,6.8-2.1,9.3-3.2c2.5-1.1,4.2-2.1,4-3c-0.1-0.9-2.1-1.3-5-1.1c-2.9,0.3-6.7,1.3-10,3.2
						c-3.4,2-5.6,3.6-7.7,5.3c-2.1,1.7-4.2,3.4-7,6.1c3.2-2.3,5.6-3.5,8-4.5C360.2,480.5,362.6,479.6,366.2,478.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M377.5,483.6c2.3,0.1,4.5-0.2,6.2-0.7c1.7-0.5,2.9-1.2,2.9-2.1c0-0.9-1.2-1.7-3.1-2.1c-1.9-0.4-4.4-0.3-6.8,0.6
						c-2.4,0.9-4,1.7-5.6,2.5c-1.7,0.9-3.3,1.8-5.3,2.9c2.4-0.7,4.2-1,5.9-1.1C373.5,483.5,375.1,483.5,377.5,483.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M400.9,489.4c2.5,0.8,5,1.3,7,1.3c2,0.1,3.5-0.3,3.7-1.1c0.3-0.8-0.8-2-2.7-2.9c-1.9-0.9-4.6-1.6-7.4-1.6
						c-2.9,0-4.8,0.3-6.8,0.5c-2.1,0.2-4.1,0.5-6.7,0.8c2.8,0.2,4.7,0.6,6.6,1.1C396.6,487.9,398.4,488.5,400.9,489.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M404,480.2c5.8,1.9,12.1,1.7,12.4,0c0.3-1.7-5.5-4.1-11.6-4.3c-6.3-0.2-8.7,0.1-15.2-0.5
						C395.8,477.1,398,478.2,404,480.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M405.2,471.3c1.9,0,3.8-0.8,4.9-1.8c1.1-1,1.6-2.1,1.2-2.9c-0.4-0.8-1.5-1.1-2.8-1c-1.3,0.1-2.9,0.5-4.3,1.4
						c-1.5,0.9-2.6,1.5-3.8,2c-1.2,0.5-2.4,0.9-4.3,1.2c1.7,0.3,3,0.5,4.5,0.7C401.8,471.2,403.2,471.4,405.2,471.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M326.4,598.7c3.6-0.2,7.2-0.8,10.9-1.8c3.6-1,7.3-2.7,10.5-5.2c1.6-1.3,3.1-2.8,4.3-4.4c1.2-1.7,2.2-3.5,3-5.4
						c1.5-3.8,2.1-7.8,1.7-11.8c-0.2-2-0.7-3.9-1.4-5.8c-0.7-1.9-1.7-3.6-2.8-5.1c-2.2-3.1-5.1-5.5-8.2-7.3c-1.6-0.8-3.2-1.5-4.9-2
						c-1.7-0.5-3.4-0.7-5.1-0.8c-3.4-0.1-6.7,0.5-9.7,1.8c-2.9,1.4-5.5,3.4-7.2,6.1c-1.7,2.6-2.4,5.5-2.4,8.1c0,2.7,0.8,5.3,2.2,7.2
						c0.7,1,1.5,1.8,2.4,2.5c0.9,0.6,1.8,1.1,2.7,1.4c3.6,1.3,6.6,0.5,8.1-0.6c1.6-1.1,1.9-2.1,1.6-2.4c-0.3-0.4-1.2-0.1-2.5,0
						c-0.7,0-1.5,0-2.4-0.2c-0.9-0.2-1.9-0.6-2.8-1.2c-0.9-0.6-1.7-1.5-2.1-2.5c-0.5-1.1-0.6-2.4-0.4-3.8c0.2-1.4,0.8-2.9,1.8-4.1
						c0.9-1.1,2.4-2.1,4.1-2.7c1.7-0.6,3.8-0.8,5.8-0.6c1,0.1,2,0.3,3,0.7c1,0.4,2,0.8,2.9,1.4c1.9,1.2,3.6,2.7,4.9,4.6
						c1.3,1.9,2,4,2.3,6.3c0.2,2.3-0.1,4.8-1,7.1c-0.4,1.1-1,2.2-1.7,3.2c-0.7,1-1.5,1.8-2.5,2.7c-3.9,3.3-9.9,5-16.1,5.8
						c-6.3,0.8-12.3,0.4-17.8-0.8c-5.5-1.2-10.3-3.2-14.8-5.6c-9-4.9-16.6-10.8-25.2-15.5c-8.6-4.7-18.1-7.9-29-9.2
						c-5.4-0.6-11.2-0.8-17.4-0.2c-6.1,0.6-12.7,2-19.4,4.5c6.8-2.2,13.3-3.3,19.4-3.6c6.1-0.3,11.8,0.2,17.1,1.1
						c10.5,1.9,19.4,5.7,27.3,10.8c3.9,2.6,7.7,5.5,11.5,8.5c3.9,3,7.9,6.1,12.5,9.1c4.6,3,9.9,5.7,16,7.5
						C312.1,598.2,319,599.2,326.4,598.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M279.9,561.6c4.2-1.3,8.3-3.6,11.6-6.8c1.7-1.6,3.1-3.5,4.3-5.6c1.2-2.1,2-4.4,2.3-7c0.7-5.2-1.5-10.8-6.2-13.8
						c-2.4-1.4-4.9-1.9-7.1-1.8c-2.2,0.1-4.1,0.5-6,1.3c-1.8,0.8-3.5,2-4.9,3.6c-1.3,1.6-2.1,3.7-2.1,5.7c0,3.9,2.4,6.6,4.7,7.6
						c2.3,1,4.2,0.5,5.3-0.1c1.1-0.6,1.3-1.3,1.2-1.6c-0.2-0.4-0.8-0.6-1.5-0.9c-0.7-0.3-1.7-0.8-2.4-1.6c-0.3-0.4-0.5-0.8-0.6-1.4
						c-0.1-0.5-0.1-1.1,0.1-1.5c0.2-0.4,0.5-0.7,1-1.1c0.5-0.3,1.2-0.7,2.1-0.9c0.9-0.2,2-0.3,2.9-0.2c1,0.1,1.7,0.4,2.2,0.8
						c0.5,0.4,0.9,1,1.2,1.8c0.2,0.4,0.3,0.9,0.3,1.3c0,0.4,0,0.9,0,1.5c-0.3,2.1-1.7,4.8-3.7,7c-2,2.2-4.7,4.1-7.8,5.4
						c-3.1,1.4-6.4,2.2-9.5,2.6c-3.2,0.4-6.2,0.5-9.2,0.4c-5.9-0.1-11.4-0.8-17-1.1c-5.6-0.3-11.4-0.3-17.6,0.8
						c-6.2,1.1-13,3.1-20.3,7c7.5-3.4,14.4-4.8,20.5-5.3c6.2-0.4,11.6,0.2,17,1.3c5.4,1,10.7,2.4,16.9,3.4c3.1,0.5,6.5,0.9,10.2,0.9
						C271.4,563.3,275.5,563,279.9,561.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M303.9,501.7c1.1-5.3,2.3-10.4,3.1-14.4c0.8-4.1,1.2-7.3-0.3-8.3c-1.4-1-4.6,0.8-7.4,4.9c-1.4,2.1-2.6,4.7-3.4,7.7
						c-0.8,3-1.1,6.3-0.8,9.6c0.3,3.4,0.9,6.2,1.7,8.6c0.7,2.4,1.6,4.5,2.6,6.5c1,2,2,4,3.3,6.1c1.3,2.1,2.8,4.3,4.9,6.8
						c-1.6-2.8-2.6-5.2-3.3-7.5c-0.7-2.3-1.1-4.3-1.3-6.4c-0.2-2.1-0.2-4.1-0.1-6.3C303,506.9,303.3,504.5,303.9,501.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M320.9,499.7c0.3-3.4-0.1-6.9-1.1-10c-0.9-3.1-2.4-5.8-4.1-7.9c-1.6-2.1-3.4-3.4-4.9-4.1c-1.5-0.7-2.6-0.7-3.3-0.1
						c-1.3,1.3-0.3,4.3,0.8,8.2c0.6,2,1.2,4.2,1.9,6.6c0.6,2.4,1.3,5,1.8,7.7c0.6,2.8,0.8,5.2,0.9,7.5c0.1,2.3,0,4.4-0.3,6.5
						c-0.5,4.2-1.6,8.5-4.5,14.4c3.8-5.3,6.1-9.2,8.1-13.3c1-2.1,1.9-4.2,2.7-6.7C319.9,506,320.6,503.2,320.9,499.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M312.1,497.4c0.7-3.6,0.6-7.3,0-10.1c-0.6-2.7-1.6-4.4-2.5-4.3c-0.9,0.1-1.4,1.9-1.7,4.6c-0.3,2.6-0.4,6-0.2,9.5
						c0.2,3.6,0.2,6.1,0.2,8.6c-0.1,2.5-0.3,5.1-1.2,8.8c1.3-3.6,2.2-6,3-8.5C310.5,503.6,311.3,501.1,312.1,497.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M310,493.7c0.9-3.1,1-6.3,0.6-8.7c-0.4-2.4-1.2-4-2.1-3.9c-0.9,0-1.6,1.6-2.1,3.8c-0.5,2.3-0.8,5.2-0.8,8.3
						c0,3.1,0,5.3-0.2,7.5c-0.2,2.2-0.4,4.4-1.2,7.6c1.2-2.9,2.1-5,3.1-7.2C308.2,499,309,496.9,310,493.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M302.6,488.7c1.5-2.6,2.9-5.1,3.9-7.2c1-2,1.6-3.6,1-4.3c-0.6-0.6-2.4,0-4.3,1.7c-1.9,1.8-3.9,4.8-4.7,8.1
						c-0.8,3.5-0.9,5.9-0.8,8.3c0.1,2.4,0.3,4.7,1,8.1c-0.2-3.4,0.2-5.7,0.8-7.9C300.3,493.4,301.1,491.4,302.6,488.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M305.8,559.5c1.5-1.7,2.7-3.7,3.5-5.7c0.8-2,1.3-4,1.4-5.7c0.3-3.4-0.5-5.7-1.4-5.7c-0.9,0-1.6,2-2.7,4.8
						c-1.1,2.8-2.5,6.3-4.4,9.7c-2,3.5-3.9,5.6-6.1,7.6c-2.2,1.9-4.8,3.6-9.1,4.9c4.4-0.8,7.4-1.9,10.1-3.3c1.4-0.7,2.7-1.5,4.2-2.5
						C302.7,562.6,304.2,561.3,305.8,559.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M341,541.1c4.9-0.6,9.7-1.1,13.4-1.5c3.7-0.4,6.3-0.9,6.4-1.8c0.1-0.8-2.4-2-6.4-2.4c-3.9-0.5-9.3-0.1-14.4,1.4
						c-5.2,1.6-8.5,3.3-11.7,5c-3.2,1.8-6.4,3.7-10.8,6.9c4.6-2.8,8.1-4.1,11.5-5.2C332.6,542.5,336,541.7,341,541.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M361.1,546c3.3,0.2,6.6,0,9.1-0.3c2.5-0.4,4.3-1,4.3-1.9c0.1-0.9-1.6-1.8-4.2-2.3c-2.6-0.5-6.1-0.6-9.6,0.1
						c-3.5,0.7-5.9,1.5-8.2,2.3c-2.5,0.8-4.8,1.6-8.1,2.8c3.6-0.7,6-0.8,8.5-0.9C355.2,545.7,357.6,545.8,361.1,546z"
                    />
                  </g>
                  <g>
                    <path
                      d="M368.5,552.6c2.6,0.8,5.2,1.2,7.2,1.2c2,0,3.6-0.3,3.8-1.2c0.2-0.8-0.9-2-2.8-2.9c-2-0.9-4.8-1.6-7.6-1.5
						c-2.9,0.1-4.9,0.4-6.9,0.7c-2.1,0.3-4.2,0.7-6.9,1.1c2.9,0.1,4.9,0.4,6.8,0.8C364,551.3,365.9,551.8,368.5,552.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M371.3,563.5c1.5,1.5,2.9,2.8,4,3.8c1.2,1,2.2,1.7,3.1,1.5c0.8-0.3,1.2-1.8,0.4-3.8c-0.7-2-2.7-4.1-5.1-5.1
						c-2.5-1.1-4.2-1.3-6-1.6c-1.7-0.3-3.4-0.5-5.9-1c2.3,1,3.8,1.8,5.2,2.7C368.4,561,369.8,562,371.3,563.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M370.1,580.9c0.2,2.4,0.6,4.7,1.1,6.5c0.5,1.8,1.1,3.1,2,3.2c0.9,0.1,1.8-1.2,2.2-3.3c0.4-2.1,0.2-5-1-7.6
						c-1.2-2.6-2.4-4.2-3.6-5.8c-1.2-1.5-2.5-3-4.4-5c1.5,2.3,2.2,4.1,2.7,5.8C369.5,576.6,369.8,578.4,370.1,580.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M364.6,598.8c-0.5,1.7-0.6,3.5-0.3,4.8c0.2,1.4,0.8,2.4,1.7,2.6c0.9,0.2,1.8-0.6,2.5-2c0.6-1.4,1-3.4,0.6-5.3
						c-0.4-2-0.9-3.3-1.3-4.7c-0.5-1.3-0.9-2.6-1.5-4.5c0.1,2-0.1,3.3-0.4,4.7C365.5,595.7,365.1,597,364.6,598.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M353.3,601c-3.3,3.3-5.1,7.7-3.7,8.8c1.4,1.1,5.4-1.9,7.3-6.4c1.9-4.6,2.2-6.5,4.2-11.1
						C358.2,596.4,356.7,597.5,353.3,601z"
                    />
                  </g>
                  <g>
                    <path
                      d="M340.2,602.7c-2.6,1.1-5,2.5-6.7,3.8c-1.7,1.3-2.7,2.6-2.3,3.4c0.4,0.8,2,0.9,4.2,0.3c2.2-0.6,4.8-1.9,7.1-3.8
						c2.3-2,3.8-3.5,5.2-5c1.5-1.5,2.9-3,5.1-5.1c-2.5,1.7-4.4,2.7-6.2,3.6C344.8,600.8,342.9,601.6,340.2,602.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M316.4,606.9c-2.8,0-5.6,0.4-7.6,1c-2.1,0.6-3.5,1.4-3.5,2.2c0,0.9,1.5,1.6,3.7,1.9c2.2,0.3,5.2,0.1,8.1-0.9
						c2.9-1,4.8-1.9,6.7-2.8c2-1,4-1.9,6.6-3.1c-3,0.8-5,1.1-7.1,1.3C321.3,606.8,319.3,606.9,316.4,606.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M233,569.1c5.4,0.9,10.6,2.2,14.6,3.2c4,1,6.8,1.7,7.2,0.8c0.4-0.7-1.8-2.8-5.8-4.8c-4-1.9-9.8-3.5-15.7-3.6
						c-6,0-10.1,0.7-14.1,1.6c-4,0.9-8,2.1-13.5,4.5c5.7-1.9,9.8-2.4,13.7-2.6C223.5,568,227.4,568.2,233,569.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M251.9,584.4c1.8,1.9,3.6,3.7,5.1,5.1c1.5,1.3,2.8,2.2,3.6,1.8c0.8-0.4,0.8-2.1-0.2-4.2c-1-2.1-3.1-4.6-5.8-6.1
						c-2.8-1.5-4.8-2.2-6.9-2.7c-2.1-0.5-4.1-0.9-7.1-1.1c2.9,0.8,4.7,1.8,6.4,2.9C248.6,581.1,250.1,582.4,251.9,584.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M68,396c1.6-0.2,3.2-0.3,4.9-0.5c1.6-0.1,3.3-0.2,4.8-0.2c3.1,0,6.1,0.2,8.8,0.7c2.7,0.5,5.1,1.4,6.9,2.6
						c0.9,0.6,1.7,1.2,2.3,1.9c0.3,0.3,0.6,0.7,0.9,1.1c0.2,0.4,0.4,0.7,0.6,1.1c0.7,1.6,1.1,3.6,0.9,5.5c-0.1,1-0.3,1.9-0.6,2.8
						c-0.3,0.9-0.8,1.8-1.3,2.6c-1.1,1.7-2.5,3.2-4.2,4.4c-1.7,1.2-3.4,2.1-5.2,2.7c-1.8,0.6-3.7,0.8-5.3,0.7
						c-1.7-0.1-3.2-0.4-4.5-1c-2.5-1-4.3-3.3-5.3-5.4c-1-2.1-1.2-4.1-1.3-5.6c0-0.7-0.1-1.4-0.1-1.9c-0.1-0.5-0.2-0.9-0.5-1.1
						c-0.3-0.2-0.7-0.2-1.2,0c-0.5,0.2-1.1,0.7-1.8,1.5c-0.6,0.8-1.3,1.9-1.7,3.4c-0.4,1.4-0.7,3.2-0.5,5.1c0.1,1.9,0.7,4.1,1.7,6.3
						c0.3,0.5,0.6,1.1,0.9,1.6c0.4,0.5,0.7,1.1,1.2,1.6c0.5,0.5,0.9,1.1,1.4,1.5c0.3,0.2,0.5,0.5,0.8,0.7c0.3,0.2,0.6,0.4,0.8,0.7
						c2.3,1.8,5.2,3,8.2,3.7c3.1,0.7,6.4,0.7,9.7,0.2c3.3-0.6,6.8-1.8,9.9-3.6c3.1-1.9,6.1-4.4,8.5-7.6c1.2-1.6,2.3-3.4,3.1-5.4
						c0.8-2,1.4-4.1,1.7-6.3c0.6-4.4,0.1-9-1.8-13.5c-0.5-1.1-1.1-2.2-1.7-3.2c-0.7-1-1.4-2-2.2-2.9c-0.8-0.9-1.7-1.7-2.6-2.5
						c-0.5-0.4-0.9-0.7-1.4-1.1c-0.5-0.4-1-0.7-1.5-1c-4-2.5-8.2-3.7-12.3-4.3c-4.1-0.6-8.1-0.7-12-0.3c-1.9,0.2-3.8,0.4-5.6,0.7
						c-1.9,0.3-3.7,0.6-5.5,1c-7.6,1.7-14.2,4-20.2,6.7c-6,2.7-11.3,5.7-16.1,8.9c-4.8,3.2-9.1,6.5-13,10.1
						c-3.9,3.5-7.6,7.2-10.9,11c-6.7,7.7-12.6,16.1-18,26.2c-5.4,10-10.4,21.7-14.4,36.1c4.8-14.2,10.6-25.4,16.7-34.7
						c6.1-9.4,12.7-16.9,19.9-23.6c3.6-3.3,7.4-6.3,11.4-9.2c4-2.9,8.4-5.5,13.1-7.9c4.7-2.4,9.8-4.6,15.4-6.4
						C55.2,398.3,61.3,396.8,68,396z"
                    />
                  </g>
                  <g>
                    <path
                      d="M53,455.6c0.5,1.9,0.5,3.8,0.3,5.6c-0.3,1.9-0.8,3.7-1.7,5.4c-0.9,1.7-2,3.1-3.3,4.3c-0.6,0.6-1.3,1.1-2,1.6
						c-0.7,0.5-1.5,0.9-2.3,1.2c-3.2,1.4-6.9,1.6-10,0.9c-1.6-0.3-2.9-0.9-3.9-1.6c-1-0.7-1.6-1.3-2.1-2.1c-0.2-0.4-0.4-0.8-0.5-1.1
						c-0.1-0.3-0.2-0.6-0.1-0.9c0-0.6,0.3-1.5,1-2.3c0.7-0.9,1.6-1.7,2.6-2.4c0.5-0.3,1-0.7,1.5-1c0.5-0.3,0.9-0.5,1.3-0.8
						c0.5-0.3,1-0.6,1.4-0.9c0.2-0.2,0.7-0.2,1.1-0.2c0.4,0.1,0.7,0.2,1,0.4c0.3,0.2,0.5,0.5,0.8,0.6c0.3,0.1,0.7,0.1,1.1-0.4
						c0.4-0.4,0.7-1.4,0.4-2.5c-0.2-1.1-0.9-2.3-2.5-3.3c-1.5-0.9-3.3-1.1-5-1c-0.8,0.1-1.6,0.2-2.4,0.3c-0.8,0.1-1.6,0.3-2.5,0.6
						c-1.7,0.5-3.6,1.3-5.5,2.6c-1.9,1.3-4,3.4-5.2,6.4c-0.6,1.5-1,3.2-1.1,4.8c-0.1,1.7,0.1,3.3,0.6,5c0.5,1.6,1.2,3.2,2.1,4.6
						c0.9,1.5,2.1,2.7,3.3,3.8c2.5,2.2,5.4,3.8,8.4,4.8c6.1,2,13.1,1.9,19.6-0.7c3.2-1.3,6.4-3.2,9-5.8c2.6-2.5,4.7-5.6,6.2-8.9
						c1.5-3.3,2.4-6.9,2.5-10.6c0.2-3.7-0.5-7.5-1.8-10.8c-2.7-7-7.7-12-13.2-14.9c-5.6-2.9-11.3-3.4-16.2-3.1
						c-4.9,0.3-9.3,1.4-13.4,2.8c-4.1,1.3-7.9,3-11.5,4.9c-3.7,1.9-7.1,4.1-10.6,6.6c-3.4,2.5-6.7,5.3-9.9,8.6
						c-3.2,3.3-6.3,6.9-9.3,11.1c-2.9,4.2-5.7,9-8,14.5c2.6-5.3,5.7-9.8,9-13.7c3.2-3.9,6.6-7.1,10-9.9c3.4-2.8,6.9-5.1,10.4-7
						c3.5-1.9,7-3.5,10.6-4.8c3.6-1.2,7.3-2.3,11.1-2.9c3.8-0.7,7.7-1.1,11.5-0.8c3.8,0.3,7.6,1.4,10.5,3.5
						C49.6,448.2,52,451.7,53,455.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M121.8,361.1c10.9,1.6,21.5,3.9,29.6,5.9c0.5,0.1,1,0.2,1.5,0.3c0.5,0.1,1,0.2,1.4,0.4c0.9,0.2,1.8,0.4,2.6,0.6
						c0.8,0.2,1.6,0.3,2.4,0.5c0.7,0.2,1.4,0.3,2,0.4c2.5,0.4,4.2,0.3,4.6-0.5c0.4-0.8-0.5-2.1-2.5-3.9c-0.5-0.4-1.1-0.9-1.7-1.4
						c-0.6-0.5-1.4-0.9-2.1-1.4c-0.8-0.5-1.6-1-2.5-1.5c-0.5-0.2-0.9-0.5-1.4-0.8c-0.5-0.2-1-0.5-1.5-0.7
						c-8.1-3.8-19.9-6.9-31.8-6.7c-3.1,0.1-5.9,0.1-8.5,0.4c-1.3,0.1-2.6,0.2-3.8,0.4c-1.2,0.2-2.4,0.4-3.5,0.5
						c-1.1,0.2-2.3,0.3-3.3,0.5c-1.1,0.2-2.1,0.4-3.2,0.7c-1,0.2-2.1,0.4-3.1,0.7c-1,0.3-2,0.5-3,0.8c-1,0.3-2,0.5-3,0.8
						c-1,0.3-2,0.6-3,1c-1,0.3-2,0.6-3.1,1c-1,0.4-2.1,0.8-3.2,1.2c-1.1,0.4-2.2,0.8-3.3,1.3c-1.1,0.5-2.3,1-3.5,1.5
						c-0.6,0.3-1.2,0.5-1.8,0.8c-0.6,0.3-1.2,0.6-1.9,0.9c-1.3,0.6-2.6,1.2-4,1.9c1.4-0.5,2.8-1,4.1-1.5c0.7-0.2,1.3-0.5,1.9-0.7
						c0.6-0.2,1.3-0.4,1.9-0.6c1.2-0.4,2.4-0.7,3.6-1c1.2-0.3,2.3-0.5,3.4-0.8c1.1-0.2,2.2-0.5,3.3-0.7c1.1-0.2,2.1-0.3,3.2-0.5
						c1-0.1,2-0.3,3-0.4c1-0.1,2-0.2,3-0.3c1-0.1,2-0.2,3-0.2c1-0.1,2-0.1,3-0.1c1,0,2.1,0,3.1-0.1c1.1,0,2.1,0.1,3.2,0.1
						c1.1,0,2.2,0.1,3.4,0.1c1.2,0.1,2.3,0.2,3.6,0.3C116.4,360.4,119,360.8,121.8,361.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M120.9,378.3c10.8,0.9,21.8-0.5,29.7-2.7c7.9-2.2,12.8-5.1,12.3-6.8c-0.4-1.8-5.9-1.9-13.7-1.5
						c-7.8,0.4-18.1,1.3-28.4,2.2c-10.6,0.9-18,1.1-25.5,0.9c-7.5-0.3-15.1-1-26.1-3.4c10.7,3.5,18.2,5.4,25.6,7.2
						C102.3,375.9,109.8,377.4,120.9,378.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M132.4,371.1c6.3,1.3,12.7,1.4,17.5,0.7c4.8-0.7,8-2.3,8-4c0-1.8-3.1-3.3-7.8-4.4c-4.7-1-10.9-1.5-17.2-1
						c-6.5,0.5-10.9,0.9-15.5,1.2c-4.5,0.3-9.1,0.4-15.8,0c6.6,1.4,11,2.6,15.4,3.8C121.5,368.5,125.9,369.7,132.4,371.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M166.3,420.2c1,0,2.1,0,3.1-0.2c1-0.2,2.1-0.4,3.1-0.7c2-0.7,4-1.7,5.8-3.3c1.8-1.5,3.3-3.7,4-6.1
						c0.7-2.4,0.7-4.8,0.3-6.9c-0.4-2.1-1.2-4-2.1-5.7c-0.9-1.7-2-3.1-3.3-4.5c-1.3-1.3-2.8-2.4-4.5-3.3c-1.6-0.8-3.6-1.3-5.3-1.4
						c-3.5-0.2-6.9,1.1-9,3.3c-1.1,1.1-1.8,2.4-2.2,3.6c-0.4,1.2-0.5,2.3-0.4,3.3c0.2,2,1.1,3.4,1.9,4c0.8,0.7,1.5,0.7,1.8,0.4
						c0.3-0.3,0.4-0.9,0.5-1.6c0.1-0.7,0.2-1.6,0.7-2.4c0.4-0.9,1.2-1.8,2.1-2.1c0.9-0.4,2.3-0.6,3.6-0.2c1.3,0.4,2.6,1.3,3.9,2.8
						c1.2,1.5,2.3,3.6,2.6,5.5c0.2,0.9,0.1,1.7-0.1,2.3c-0.2,0.6-0.5,1.2-1.1,1.8c-0.6,0.6-1.5,1.2-2.5,1.7
						c-0.5,0.2-1.1,0.4-1.6,0.6c-0.6,0.2-1.1,0.3-1.8,0.4c-2.6,0.3-5.2,0-7.6-0.7c-2.4-0.7-4.7-1.7-6.8-2.9c-4.1-2.4-7.6-5.4-11-8.5
						c-3.5-3.1-6.9-6.5-11.2-9.7c-2.2-1.6-4.5-3.1-7.3-4.5c-2.7-1.3-5.9-2.4-9.3-2.6c3.5,0.5,6.4,1.9,8.9,3.4
						c2.5,1.5,4.7,3.3,6.5,5.1c3.8,3.6,6.6,7.3,9.5,11c3,3.7,6,7.4,10.3,11c2.2,1.8,4.6,3.4,7.6,4.8
						C158.6,419,162.2,420.1,166.3,420.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M130.6,425.7c-0.7,1.3-1.4,2.2-2.3,3c-0.9,0.7-1.7,1.2-2.5,1.4c-0.6,0.2-1.4,0.2-2.1,0c-0.7-0.2-1.2-0.4-1.5-0.7
						c-0.3-0.1-0.6-0.7-0.7-1.3c-0.1-0.6-0.1-1.3,0-1.9c0.1-0.6,0.3-1.1,0.4-1.6c0.2-0.5,0.3-1,0.2-1.4c-0.1-0.4-0.4-0.8-1.1-1
						c-0.7-0.2-1.8-0.1-2.9,0.5c-1.2,0.6-2.4,1.7-3.2,3.4c-0.8,1.6-1.3,4-0.4,6.6c0.8,2.4,2.7,4.6,5.1,5.9c2.4,1.3,5.3,1.8,8.3,1.2
						c2.9-0.6,5.4-2.2,7.2-4.2c1.8-2,3.1-4.6,3.7-7c0.6-2.5,0.6-4.8,0.4-6.8c-0.2-2-0.6-3.8-1-5.4c-0.9-3.3-2.2-6.1-3.7-8.6
						c-1.5-2.6-3.3-4.9-5.6-7.3c-1.1-1.2-2.4-2.3-3.8-3.5c-1.4-1.1-3.1-2.2-4.9-3.2c1.7,1.2,3.1,2.6,4.3,3.8c1.2,1.3,2.2,2.6,3,3.9
						c1.7,2.6,2.7,5.1,3.5,7.7c0.7,2.6,1.1,5.2,1.1,8C132.2,419.9,131.9,423.1,130.6,425.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M612.6,399.5c-0.7-3.6-1.9-7.1-3.5-10.4c-1.6-3.3-3.6-6.4-5.8-9.1c-2.2-2.7-4.6-4.8-6.8-6.8c-2.2-1.9-4.2-3.6-5.9-5.2
						c-3.5-3.1-6-6-7.6-8.2c-1.7-2.2-2.6-3.7-3.5-3.5c-0.8,0.1-1.5,2-0.9,5.4c0.3,1.6,0.9,3.6,1.9,5.6c1,2,2.4,4.2,4,6.3
						c3.3,4.3,7.7,8.3,11.3,12.7c3.5,4.3,6.4,9.6,8.4,15.4c1,3,1.7,5.9,2.1,8.6c0.4,2.7,0.7,5.4,0.7,7.9c0,5-0.5,9.6-1.6,14.1
						c-1.1,4.5-2.6,9-4.9,13.8c-2.3,4.8-5.4,9.9-9.8,15.6c4.8-5.3,8.4-10.1,11.3-14.7c2.9-4.6,5.2-9,7-13.5
						c1.8-4.6,3.3-9.3,4.1-14.8c0.4-2.8,0.6-5.7,0.6-8.9C613.7,406.6,613.4,403.2,612.6,399.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M580.2,404.8c-0.8-5.7-1-11.4-0.9-16.8c0.1-5.4,0.4-10.6,0.8-15c0.7-8.9,0.9-15.2-0.8-15.7c-1.7-0.4-4.9,5.1-7.5,14.3
						c-1.2,4.6-2.2,10.1-2.4,16.2c-0.2,6,0.4,12.6,2.2,18.9c1.9,6.5,4.3,11.6,7.1,15.9c1.4,2.2,2.8,4.1,4.3,5.9
						c1.4,1.8,2.9,3.4,4.2,5.1c2.6,3.3,5,6.9,5.9,11.6c0.2,1.2,0.4,2.4,0.4,3.7c0,1.3,0,2.6-0.2,4c-0.4,2.7-1.3,5.6-3,8.4
						c1.9-2.7,3.1-5.5,3.8-8.2c0.4-1.4,0.6-2.7,0.7-4.1c0.1-1.3,0.1-2.6,0.1-3.9c-0.1-2.5-0.7-5-1.5-7.2c-0.8-2.2-1.8-4.3-2.9-6.2
						c-2.2-3.9-4.5-7.5-6.2-11.7C582.4,415.5,581,410.7,580.2,404.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M593.6,391.6c-1-7.1-3.4-13.9-5.9-18.7c-2.5-4.8-4.9-7.5-5.7-7.1c-0.8,0.4,0.2,3.8,1.8,8.7c1.6,4.9,3.7,11.4,5.5,17.9
						c1.8,6.8,2.7,11.6,3.3,16.5c0.5,4.9,0.8,9.9,0.1,17.3c1.2-7.3,1.6-12.4,1.8-17.4C594.6,403.9,594.6,398.9,593.6,391.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M588.3,385.7c-0.4-5.3-1.8-10.5-3.4-14.1c-1.6-3.7-3.4-5.8-4.2-5.5c-0.8,0.3-0.6,3,0.2,6.7c0.7,3.8,1.9,8.6,3.1,13.5
						c1.2,5.1,1.9,8.6,2.3,12.2c0.4,3.6,0.7,7.4,0.3,12.8c0.9-5.4,1.3-9.2,1.6-12.9C588.5,394.8,588.7,391.1,588.3,385.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M583.8,385.6c0.2-8.5-1.7-16.9-3.5-16.8c-1.8,0.1-2.4,8.7-0.9,17.1c1.6,8.6,2.6,11.8,3.8,20.8
						C583,397.7,583.5,394.4,583.8,385.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M38.3,418.2c3.6-0.5,7.1-1.2,9.7-2c2.6-0.8,4.4-1.7,4.3-2.5c-0.1-0.9-2-1.5-4.9-1.6c-2.9-0.1-6.7,0.5-10.3,1.9
						c-3.7,1.5-6,2.8-8.4,4.1c-2.3,1.3-4.7,2.7-8,4.8c3.6-1.6,6.2-2.4,8.7-3C32.1,419.3,34.6,418.8,38.3,418.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M37.7,425.6c3.2-0.1,6.2-0.5,8.6-1.1c2.3-0.5,4-1.2,4-2.1c0-0.9-1.6-1.7-4.2-2.1c-2.6-0.4-6-0.1-9.3,0.9
						c-3.3,1.1-5.5,2.1-7.6,3.2c-2.1,1.1-4.2,2.2-7.3,3.9c3.3-1.2,5.6-1.8,7.9-2.1C32.2,425.9,34.5,425.7,37.7,425.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M59.5,432.6c2.4,0.8,4.7,1.4,6.6,1.6c1.9,0.2,3.4,0.1,3.8-0.7c0.4-0.8-0.5-2.1-2.3-3.2c-1.8-1.1-4.5-2-7.3-2
						c-2.9,0-4.8,0.3-6.8,0.7c-1.9,0.4-3.8,0.8-6.5,1.5c2.8-0.2,4.7,0,6.5,0.4C55.3,431.3,57.1,431.8,59.5,432.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M80.6,446.8c4,0.5,8.1-0.5,10.7-1.9c2.7-1.4,4-3.1,3.6-3.8c-0.4-0.8-2.4-0.5-5-0.1c-2.6,0.4-5.9,1-9.4,1.5
						c-3.6,0.4-6.1,0.3-8.7,0c-2.5-0.4-5.2-1.1-8.6-3.1c3.1,2.4,5.6,3.7,8.1,4.9C73.9,445.3,76.5,446.2,80.6,446.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M84.1,455.5c2.2,0,4.3-0.8,5.6-1.8c1.3-1,1.9-2.2,1.5-3c-0.4-0.8-1.6-1-3.1-0.9c-1.5,0.1-3.2,0.6-4.9,1.4
						c-1.8,0.8-3.1,1.4-4.4,1.8c-1.4,0.4-2.8,0.8-4.9,0.8c2.1,0.5,3.5,0.8,5,1.1C80.4,455.3,81.9,455.5,84.1,455.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-6.8,468.2c-3.8,3.9-6.3,9-7.3,13c-1,4-0.7,6.8,0.2,7c0.9,0.2,2.1-2.2,3.8-5.5c1.7-3.3,4-7.6,6.8-11.7
						c2.9-4.2,5.4-6.9,8.1-9.3c2.8-2.3,5.8-4.6,11-6.6c-5.3,1.5-8.8,3.2-12.1,5C0.3,462-2.9,464.1-6.8,468.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-0.6,480.6c-1,1.9-1.8,3.9-2.4,5.8c-0.3,1-0.5,1.9-0.7,2.8c-0.2,0.9-0.3,1.9-0.3,2.8c0,0.9,0.1,1.8,0.4,2.6
						c0.3,0.8,0.7,1.5,1.2,1.9c0.5,0.4,1,0.6,1.4,0.6c0.4,0,0.6-0.1,0.7-0.3c0.2-0.4-0.1-1,0-1.6c0-0.6,0.1-1.5,0.5-2.7
						c0.2-0.6,0.4-1.2,0.7-2.1c0.3-0.8,0.6-1.7,0.8-2.5c0.6-1.8,1.1-3.7,1.7-5.6c1.1-4,2.1-6.7,3.2-9.3c1.2-2.7,2.5-5.3,5-8.9
						c-2.9,3.2-4.8,5.6-6.7,7.9C3.3,474.3,1.5,476.7-0.6,480.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M7.5,492.9c-0.1,2.4,0.6,4.8,1.6,6.4c1,1.6,2.2,2.3,3,2c0.8-0.4,1.1-1.7,1-3.3c-0.1-1.6-0.5-3.6-1.2-5.6
						c-0.7-2.1-1.2-3.6-1.5-5.1c-0.3-1.6-0.5-3.2-0.3-5.6c-0.7,2.3-1.2,3.9-1.6,5.5C8.1,488.7,7.7,490.4,7.5,492.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M19.5,498.4c0.8,2,2.3,3.6,3.7,4.4c1.4,0.8,2.7,0.9,3.3,0.2c0.6-0.7,0.3-1.9-0.3-3.1c-0.6-1.3-1.7-2.7-3-3.9
						c-1.4-1.3-2.4-2.3-3.2-3.3c-0.9-1.1-1.7-2.2-2.5-4.2c0.3,2.1,0.5,3.5,0.7,4.9C18.5,494.9,18.7,496.4,19.5,498.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M36.3,500.3c1.6,0.8,3.4,0.8,4.7,0.4c1.3-0.4,2-1.3,2-2.2c-0.1-0.9-0.9-1.6-1.9-2c-1.1-0.5-2.4-0.7-3.8-0.5
						c-1.5,0.2-2.6,0.2-3.7,0.2c-1.1,0-2.3-0.2-3.8-0.8c1.1,0.9,2.1,1.7,3.2,2.6C33.7,498.7,34.7,499.5,36.3,500.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M173.3,453.4c1.6-3.6,2.3-7.6,2.2-11.5c-0.1-3.9-1-7.8-2.9-11.2c-1-1.7-2.2-3.2-3.6-4.5c-1.5-1.3-3.2-2.3-5.1-2.8
						c-3.8-1.1-7.5-0.3-10.2,1.3c-2.8,1.6-4.7,4.3-5.4,6.7c-0.8,2.5-0.6,4.7-0.2,6.4c0.5,1.7,1.2,2.9,1.8,3.6
						c0.6,0.7,1.1,0.9,1.2,0.8c0.4-0.3-0.5-1.9-0.7-4.8c0-1.4,0.2-3.2,1-4.9c0.8-1.7,2.2-3.3,4.2-4.2c2-1,4.6-1.3,6.8-0.5
						c2.3,0.8,4.4,2.7,5.8,5.3c1.4,2.6,2.2,5.7,2.4,8.9c0.2,3.2-0.3,6.6-1.4,9.7c-1.2,3.2-3,6-4.8,8.5c-1.9,2.5-3.8,4.8-5.6,7.1
						c-0.9,1.2-1.7,2.3-2.5,3.5c-0.4,0.6-0.7,1.2-1.1,1.8l-0.5,0.9l-0.5,0.9c-0.3,0.6-0.6,1.2-0.9,1.8c-0.3,0.6-0.5,1.2-0.8,1.8
						c-0.6,1.2-0.9,2.5-1.4,3.7c-0.8,2.5-1.4,5.1-1.8,7.8c-0.2,1.3-0.3,2.7-0.4,4.1c0,0.7,0,1.4,0,2.1c0,0.7,0,1.4,0,2.2
						c0.1,2.9,0.5,6,1.3,9.3c0.8,3.2,2,6.6,3.8,10c-1.7-3.5-2.7-6.9-3.4-10.1c-0.6-3.2-0.9-6.3-0.8-9.2c0-0.7,0-1.4,0.1-2.1
						c0.1-0.7,0.1-1.4,0.2-2.1c0.2-1.4,0.3-2.7,0.6-4c0.5-2.6,1.3-5,2.2-7.4c1.9-4.7,4.5-9,8.3-13.1c1.9-2.1,4-4.2,6.1-6.7
						C169.4,460,171.7,457.1,173.3,453.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M154.7,445.2c-2.1,1.9-3.9,4-5.1,5.8c-1.2,1.8-1.8,3.3-1.2,4c0.6,0.7,2.2,0.3,4.2-0.9c1.9-1.2,4.1-3.4,5.6-6.1
						c0.8-1.4,1.3-2.5,1.8-3.6c0.5-1.1,0.9-2.1,1.2-3.2c0.3-1.1,0.5-2.2,0.4-3.5c-0.1-1.2-0.5-2.6-1.7-3.7c0.9,1.3,1,2.6,0.8,3.7
						c-0.2,1.1-0.7,2-1.2,2.7c-0.6,0.8-1.2,1.5-2,2.3C156.6,443.3,155.7,444.2,154.7,445.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M157.3,453.9c-1.7,1.7-3.1,3.6-4,5.2c-0.9,1.6-1.2,3-0.6,3.7c0.6,0.6,2.1,0.3,3.7-0.9c1.6-1.1,3.4-3.1,4.4-5.5
						c1.1-2.4,1.7-4.2,2.2-6c0.6-1.9,1.1-3.7,1.8-6c-1.2,2.3-2.3,3.8-3.5,5.1C160.3,450.9,159.1,452.2,157.3,453.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M187.3,434.6c0.8-2.6,0.7-5.4,0.1-7.4c-0.6-2-1.7-3.1-2.5-2.9c-0.9,0.2-1.4,1.5-1.6,3.3c-0.3,1.8-0.4,4-0.3,6.3
						c0.1,2.4,0,4.2-0.2,5.9c-0.3,1.7-0.7,3.5-1.9,5.9c1.6-2.1,2.7-3.7,3.6-5.2C185.5,438.9,186.5,437.3,187.3,434.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M196,440.1c1.4-0.9,2.5-2.1,3.2-3.2c0.7-1.1,1-2.2,0.6-3c-0.5-0.7-1.7-0.9-3.1-0.4c-1.4,0.5-3,1.8-4,3.6
						c-1,1.8-1.4,3.2-1.8,4.5c-0.4,1.3-0.7,2.6-1.2,4.5c1-1.7,1.9-2.6,2.8-3.5C193.5,441.8,194.5,441.1,196,440.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M204.1,446c1.7-0.2,3.1-0.8,4.2-1.5c1.1-0.7,1.8-1.6,1.7-2.5c-0.1-0.9-1.2-1.5-2.7-1.6c-1.5-0.1-3.4,0.4-5,1.6
						c-1.6,1.2-2.5,2.2-3.5,3.2c-1,1.1-1.9,2.2-3,3.4c1.6-1,2.8-1.5,4-1.8C201.1,446.4,202.3,446.2,204.1,446z"
                    />
                  </g>
                  <g>
                    <path
                      d="M255.9,442.9c-2.8,2-5.5,3.9-7.6,5.6c-2,1.6-3.4,3-3,3.8c0.3,0.8,2.4,0.8,5.1-0.2c2.7-1,6-3.1,8.4-6
						c2.5-3,3.9-5.3,5.2-7.6c1.2-2.3,2.4-4.7,3.8-8.3c-1.9,3.4-3.5,5.4-5.3,7.2C260.7,439.2,258.8,440.8,255.9,442.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M258,452.6c-2.4,3.6-3.9,7.7-4.5,10.9c-0.5,3.2-0.2,5.5,0.6,5.6c0.9,0.2,2-1.7,3.4-4.4c1.3-2.7,2.9-6.4,4.3-10.1
						c1.5-3.8,2.8-6.4,4.3-9c1.5-2.5,3.1-5,6.1-8.3c-3.4,2.9-5.5,5-7.6,7.1C262.6,446.7,260.5,448.9,258,452.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M264.1,459.8c-1.9,3.8-3,8-3.2,11.2c-0.3,3.2,0.2,5.4,1,5.5c0.9,0.1,1.9-1.8,3-4.6c1.1-2.9,2.3-6.7,3.3-10.6
						c1-4,1.9-6.8,2.9-9.6c1-2.8,2.2-5.5,4.4-9.3c-2.7,3.5-4.4,6-6.1,8.5C267.7,453.4,266.1,455.9,264.1,459.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M266.4,475.3c-1.1,3.9-1.3,8-0.8,11c0.4,3,1.4,5,2.2,4.9c0.9-0.1,1.5-2,2-4.9c0.5-2.8,0.8-6.6,1-10.3
						c0.2-3.9,0.5-6.6,0.9-9.3c0.5-2.7,1.1-5.5,2.6-9.4c-2,3.6-3.2,6.2-4.4,8.8C268.6,468.7,267.5,471.3,266.4,475.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M332.1,491.8c-2.3,0.4-4.4,1.4-5.7,2.6c-1.3,1.2-1.9,2.4-1.4,3.2c0.5,0.8,1.8,0.9,3.4,0.5c1.6-0.3,3.5-1.1,5.3-2.2
						c1.9-1.2,3.2-2,4.6-2.7c1.4-0.7,2.9-1.4,5.1-2c-2.1,0-3.8,0.1-5.6,0.1C336.1,491.3,334.5,491.4,332.1,491.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M338.2,497.8c-2,0.5-2.5,2.8-1.3,4.1c1.2,1.3,3.4,1.1,4.2-0.8c0.8-1.9,0.9-3,2-4.7
						C341.2,497.3,340.2,497.3,338.2,497.8z"
                    />
                  </g>
                  <g>
                    <path d="M432.9,506.6c-3.6,0.1-6.5,2.6-5.9,4.2c0.7,1.6,4.1,1.7,7,0c3-1.8,4.1-2.8,7.6-4C438.1,507,436.6,506.6,432.9,506.6z" />
                  </g>
                  <g>
                    <path
                      d="M152,526.9c-2.4-3.5-5.6-6.8-9.9-9.1c-2.1-1.1-4.4-2-6.8-2.5c-2.4-0.5-5-0.6-7.6-0.1c-5.2,0.9-9.7,3.7-12.6,7.9
						c-0.7,1.1-1.3,2.2-1.8,3.4c-0.5,1.2-0.8,2.5-0.9,3.7c-0.3,2.4,0,4.7,0.7,6.8c0.7,2.1,1.8,4,3.3,5.5c1.5,1.5,3.3,2.6,5,3.2
						c3.6,1.1,6.6,0.4,8.6-0.7c2.1-1.1,3.2-2.7,3.5-3.9c0.2-0.6,0.2-1.1,0.1-1.5c-0.1-0.4-0.3-0.7-0.6-0.8c-0.3-0.2-0.6-0.3-1-0.3
						c-0.4,0-0.8,0-1.2-0.1c-0.8,0-1.8-0.1-2.7-0.4c-0.9-0.3-2-0.9-2.4-1.5c-0.2-0.3-0.3-0.7-0.4-1.1c-0.1-0.4-0.1-0.8-0.1-1.4
						c0-0.5,0.2-1,0.4-1.4c0.1-0.2,0.2-0.4,0.3-0.5c0.1-0.2,0.3-0.4,0.5-0.5c0.4-0.4,0.9-0.7,1.5-1c0.6-0.3,1.3-0.5,2-0.6
						c1.4-0.2,3.4,0.1,5.5,0.9c2,0.8,4.2,2.4,6.2,4.4c1.1,1,2.1,2.2,3,3.4c0.9,1.2,1.8,2.3,2.6,3.5c1.5,2.4,2.8,4.7,3.8,7.1
						c2,4.7,3.3,9.3,3.7,14.1c0.5,4.8,0.2,9.7-1.1,15.2c-1.3,5.4-3.8,11.3-8.4,17.4c5.2-5.5,8.6-11.1,10.9-16.5
						c2.3-5.4,3.7-10.6,4.3-15.9c0.6-5.3,0.5-10.8-0.6-16.8c-0.6-3-1.4-6.1-2.7-9.4c-0.7-1.6-1.4-3.3-2.3-5
						C154.2,530.4,153.2,528.7,152,526.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M180.5,567.9c3.9-1.9,7.3-4.1,9.8-6.3c2.5-2.1,4.1-4.2,3.6-5.9c-0.5-1.6-3.3-2.4-7.1-1.7c-3.7,0.7-8.4,3.2-11.7,7
						c-3.4,3.9-5.2,7-6.8,10c-1.6,3.1-3.1,6.2-4.9,10.7c2.8-4.1,5.3-6.3,7.8-8.3C173.8,571.6,176.5,569.9,180.5,567.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M174.5,542.4c0.4-3.8-1-7.4-2.8-9.6c-1.9-2.2-4.1-3-5.6-2.2c-1.6,0.9-2.2,2.9-2.2,5.3c0,2.4,0.6,5.1,1.9,7.8
						c1.3,2.9,2.2,5,2.8,7.3c0.6,2.3,1,4.7,0.6,8.2c1.4-3.3,2.3-5.6,3.2-8C173.2,548.8,174.1,546.3,174.5,542.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M59.8,573.1c-3.7,3.4-7.3,6.6-11.1,9.2c-3.7,2.5-7.5,4.5-10.6,4.9c-3.2,0.5-5.8-0.6-7.3-1.7c-1.6-1.1-2.1-2.3-2.6-2.2
						c-0.2,0.1-0.3,0.4-0.3,1.1c0.1,0.7,0.4,1.7,1.1,2.7c0.7,1.1,1.9,2.2,3.5,3c1.6,0.8,3.7,1.3,5.9,1.3c2.2-0.1,4.5-0.6,6.7-1.5
						c2.2-0.9,4.3-2.1,6.4-3.4c4.2-2.8,8-6.4,11.4-10.2c3.5-3.9,6.3-7.3,8.9-10.2c2.6-2.9,5.1-5.4,7.9-7.7c2.7-2.2,5.7-4.2,9.4-5.8
						c3.7-1.5,8.2-2.7,13.6-2c-5.3-1-10-0.1-13.9,1.2c-3.9,1.3-7.2,3.1-10.3,5.1c-3.1,2-5.9,4.3-8.9,7
						C66.7,566.4,63.5,569.6,59.8,573.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M73.8,568.7c-1.9,3-3.2,6.4-4,9.7c-0.8,3.3-0.9,6.4-0.8,9.1c0.4,5.4,2.1,8.6,2.9,8.4c0.9-0.2,0.8-3.6,1.3-8.4
						c0.3-2.4,0.7-5.1,1.4-8c0.7-2.9,1.7-5.9,3-8.8c1.4-3,2.8-5.4,4.4-7.4c1.6-2,3.2-3.7,4.9-5.3c1.8-1.5,3.7-3,6-4.3
						c2.3-1.3,5.1-2.5,8.6-3.4c-3.5,0.6-6.4,1.6-9,2.6c-2.5,1.1-4.7,2.2-6.8,3.5c-2.1,1.3-4,2.8-6.1,4.7
						C77.8,563.3,75.8,565.6,73.8,568.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M82.3,565.7c-1.2,2.3-2,4.8-2.4,7.2c-0.4,2.4-0.3,4.7,0,6.6c0.6,3.9,2.2,6.1,3,5.9c0.9-0.2,0.9-2.7,1.2-6
						c0.1-1.7,0.4-3.6,0.7-5.6c0.4-2,0.9-4.2,1.5-6.3c0.7-2.2,1.5-4,2.4-5.5c0.9-1.6,1.8-2.9,2.9-4.2c1.1-1.3,2.3-2.5,3.8-3.7
						c1.5-1.2,3.3-2.3,5.7-3.3c-2.4,0.8-4.4,1.7-6.1,2.6c-1.7,0.9-3.2,1.9-4.6,3c-1.4,1.1-2.7,2.3-4.1,3.8
						C84.9,561.6,83.5,563.4,82.3,565.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M126.8,555.4c3.6,0.7,7.5,0,9.9-1.3c2.5-1.3,3.7-2.8,3.3-3.6c-0.4-0.8-2.2-0.7-4.6-0.4c-2.4,0.3-5.3,0.6-8.5,0.9
						c-3.2,0.2-5.5,0-7.8-0.4c-2.3-0.5-4.6-1.3-7.6-3.4c2.6,2.5,4.7,3.9,6.9,5.1C120.8,553.5,123.1,554.6,126.8,555.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M138.6,563.1c1.7,1.4,3.4,2.4,4.9,3.1c1.5,0.7,2.8,0.9,3.4,0.3c0.6-0.6,0.4-2-0.8-3.5c-1.1-1.5-3.1-3.1-5.5-3.8
						c-2.4-0.7-4.2-0.9-5.9-1c-1.7-0.1-3.4-0.2-5.8-0.2c2.4,0.5,3.9,1.2,5.3,2C135.6,560.8,136.9,561.7,138.6,563.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M141,578.8c0.3,2.1,1,4.1,1.8,5.5c0.8,1.5,1.7,2.4,2.6,2.3c0.9-0.1,1.5-1.4,1.5-3.2c0.1-1.8-0.5-4.2-1.8-6.2
						c-1.3-2.1-2.4-3.4-3.5-4.6c-1.1-1.2-2.2-2.5-3.8-4.2c1.2,2.1,1.7,3.6,2.2,5.2C140.4,575.1,140.7,576.6,141,578.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M143.4,505.7c0.1-2,0.1-3.7-0.1-5.2c-0.2-1.5-0.5-2.7-1.3-3c-0.8-0.3-2,0.5-2.8,2.3c-0.8,1.7-1,4.4-0.2,6.7
						c0.9,2.4,1.9,3.8,3,5.1c1.1,1.3,2.2,2.5,4,4c-1.5-1.8-2-3.4-2.3-4.8C143.4,509.2,143.3,507.7,143.4,505.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M158.8,498.6c0.9-1.8,1.7-3.4,2.3-4.9c0.6-1.4,0.9-2.7,0.4-3.4c-0.5-0.7-2.1-0.5-3.6,0.8c-1.6,1.3-3.1,3.7-3.3,6.4
						c-0.2,2.8,0.3,4.6,0.9,6.3c0.6,1.7,1.4,3.3,2.8,5.3c-1-2.3-1.1-4-0.9-5.5C157.5,501.9,157.9,500.4,158.8,498.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M187.9,576c2.7-0.4,5.2-1.1,7.1-1.9c1.9-0.8,3.2-1.7,3.1-2.6c-0.1-0.9-1.6-1.5-3.8-1.5c-2.2,0-5.1,0.5-7.7,1.8
						c-2.7,1.3-4.4,2.5-6.1,3.6c-1.8,1.2-3.5,2.4-5.8,4c2.7-1.2,4.6-1.8,6.5-2.3C183.2,576.8,185.2,576.4,187.9,576z"
                    />
                  </g>
                  <g>
                    <path
                      d="M149,392.3c3,0.1,5.8-1,7.6-2.1c1.8-1.1,2.7-2.4,2.4-3.2c-0.3-0.8-1.9-1-3.9-0.7c-2,0.3-4.4,1-6.7,1.7
						c-2.4,0.8-4.2,1-6,0.9c-1.8-0.1-3.8-0.5-5.9-2.5c1.7,2.3,3.6,3.4,5.4,4.3C143.8,391.6,145.9,392.2,149,392.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M134,321c-1.8,2.8-3.2,6-3.8,9.2c-0.6,3.2-0.5,6.3,0.2,8.9c0.7,2.6,1.8,4.5,2.9,5.6c0.5,0.6,1,0.9,1.4,1.1
						c0.4,0.2,0.7,0.2,0.8,0.1c0.7-0.6-0.7-3.3-1-7.6c-0.1-2.1,0-4.6,0.5-7.2c0.5-2.6,1.5-5.3,2.8-8c1.3-2.8,2.7-5.1,4-7.1
						c1.3-2.1,2.6-3.9,3.9-5.7c1.3-1.9,2.6-3.7,4-5.8c1.4-2.1,2.9-4.5,4.3-7.6c-1.6,2.9-3.4,5.1-5,7.1c-1.6,1.9-3.2,3.5-4.8,5.2
						c-1.6,1.6-3.1,3.2-4.8,5.1C137.7,315.9,135.8,318.1,134,321z"
                    />
                  </g>
                  <g>
                    <path
                      d="M143.2,322.4c-0.7,5.5,0.4,11.2,2,15.1c1.6,3.9,3.6,6,4.4,5.6c0.8-0.4,0.3-3.1-0.4-6.8c-0.7-3.8-1.4-8.7-1.7-13.7
						c-0.2-5.2,0.2-8.9,1-12.5c0.8-3.6,2-7.3,5-12.1c-3.4,4.5-5.2,8-6.8,11.5C145.2,313,143.9,316.7,143.2,322.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M149.7,316.6c0,4,1.3,7.8,2.8,10.4c1.5,2.6,3.2,3.9,3.9,3.4c0.8-0.4,0.5-2.4-0.1-5c-0.6-2.6-1.5-6-2.3-9.4
						c-0.9-3.5-1.2-6.1-1.4-8.7c-0.1-2.6,0-5.3,1-9.2c-1.5,3.7-2.3,6.3-2.9,9C150.2,309.8,149.7,312.5,149.7,316.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M93.8,341.5c3.2-2.6,5.7-6,7.1-8.8c1.4-2.8,1.7-5,0.9-5.4c-0.8-0.4-2.4,0.9-4.4,3c-2,2.1-4.4,5-6.8,8.1
						c-2.4,3.1-4.3,5.2-6.3,7.2c-2,2-4.2,3.8-7.8,6.1c3.8-1.8,6.4-3.2,8.9-4.6C88,345.7,90.5,344.2,93.8,341.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M102.3,347.6c2.7-0.3,5.3-1,7.2-1.7c1.9-0.8,3.2-1.7,3.1-2.5c-0.1-0.9-1.6-1.5-3.8-1.6c-2.2-0.1-5.1,0.4-7.7,1.7
						c-2.7,1.3-4.4,2.4-6.2,3.5c-1.8,1.2-3.6,2.3-5.9,3.8c2.7-1.2,4.7-1.7,6.6-2.1C97.6,348.2,99.5,347.9,102.3,347.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M119.4,246.7c1.9-1.6,3.5-3.5,4.5-5.2c1-1.6,1.4-3,0.8-3.7c-0.6-0.6-2.1-0.3-3.8,0.8c-1.7,1.1-3.6,3-4.9,5.4
						c-1.3,2.4-2,4.2-2.8,5.9c-0.8,1.9-1.5,3.7-2.5,6c1.5-2.3,2.8-3.7,4.1-5C116.1,249.6,117.4,248.4,119.4,246.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M20.7,384.4c-7.8,3.2-14.9,7.9-19.7,12.1c-4.8,4.2-7.2,7.7-6.7,8.3c0.6,0.7,4.1-1.6,9.2-5c5.1-3.3,11.9-7.6,19-11.4
						c7.3-3.9,12.7-6.1,18.2-8c5.5-1.8,11.3-3.3,20-4.3c-8.8,0.4-14.7,1.3-20.5,2.4C34.5,379.7,28.7,381.1,20.7,384.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M27.1,386.9c-6.2,3.1-11.7,7.5-15.3,11.3c-3.6,3.8-5.4,6.9-4.8,7.4c0.7,0.6,3.5-1.3,7.5-4.3c4-3,9.3-6.9,14.8-10.6
						c5.7-3.8,9.8-6.1,14.1-8.2c4.3-2,8.9-3.9,15.8-5.7c-7.1,1.2-11.9,2.5-16.5,3.9C38.1,382.1,33.5,383.7,27.1,386.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-19.8,372.5c-3.2-2.4-6.3-4.7-8.9-6.3c-2.5-1.6-4.5-2.5-5.1-1.9c-0.6,0.6,0.3,2.7,2.4,5.1c2.1,2.4,5.5,5.1,9.3,6.9
						c4,1.8,6.8,2.7,9.6,3.6c2.9,0.8,5.7,1.6,10,2.5c-4.1-1.4-6.8-2.8-9.3-4.2C-14.2,376.7-16.6,375-19.8,372.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-14.5,384.4c-0.9,0-1.8,0.2-2.7,0.6c-0.9,0.3-1.9,1-2.5,2c-0.6,1-0.6,2.1-0.3,2.7c0.3,0.7,0.9,0.8,1.3,0.7
						c0.4-0.1,0.8-0.4,1.2-0.5c0.4-0.1,0.7-0.1,1-0.1c0.2,0.1,0.6,0,1.2-0.2c0.7-0.2,1.4-0.5,2.1-1c1.4-0.9,2.5-1.5,3.6-2
						c1.1-0.5,2.3-0.9,4.1-1.2c-1.6-0.2-2.9-0.5-4.4-0.7C-11.3,384.6-12.6,384.4-14.5,384.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-13.6,395.2c-2.1,1.6-3.6,3.7-4.4,5.6c-0.7,1.8-0.7,3.3,0,3.8c0.8,0.5,2-0.2,3.4-1.4c1.4-1.2,2.9-3,4.1-5
						c1.3-2.1,2.3-3.5,3.4-4.9c1.1-1.4,2.3-2.7,4.3-4.4c-2.3,1.2-4,2-5.6,2.9C-9.8,392.7-11.4,393.6-13.6,395.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-9.5,421.5c-1.3,2.9-0.3,6,1.4,6.3c1.7,0.2,3.5-2.6,2.9-5.7c-0.5-3.2-1.1-4.4-1.2-7.7C-7.4,417.5-8.2,418.6-9.5,421.5
						z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-2.1,415.6c-1.2,2.5-0.2,5.3,1.5,5.5c1.7,0.2,3.4-2.2,2.8-4.9c-0.6-2.8-1.2-3.9-1.4-6.7C0,412.2-0.9,413.1-2.1,415.6z
						"
                    />
                  </g>
                  <g>
                    <path
                      d="M578.1,305.9c3.3-4.2,6.4-8.7,8.9-13.6c2.5-4.9,4.2-10.3,4.9-15.7c0.7-5.4,0.4-10.9-1.2-15.8c-0.4-1.2-0.8-2.4-1.3-3.6
						c-0.5-1.2-1.1-2.3-1.8-3.4c-1.4-2.2-3.1-4.2-5.3-5.7c-1.1-0.8-2.3-1.3-3.6-1.8c-1.3-0.4-2.7-0.6-4-0.5
						c-2.5,0.1-4.9,0.9-6.8,2.5c-0.9,0.8-1.7,1.7-2.3,2.7c-0.6,1-0.8,2.1-1,3.1c-0.2,2,0.2,3.7,0.7,5c1.1,2.5,2.5,3.9,3.5,4.8
						c1,0.9,1.7,1.1,2.1,0.9c0.4-0.2,0.3-1,0.1-2.2c-0.2-1.2-0.8-2.9-1-4.7c-0.1-0.9,0-1.8,0.3-2.5c0.2-0.3,0.4-0.7,0.6-0.9
						c0.2-0.2,0.5-0.4,0.9-0.6c0.7-0.3,1.7-0.5,2.7-0.3c0.5,0.1,1,0.2,1.4,0.4c0.4,0.2,0.9,0.6,1.4,1c0.9,0.8,1.8,2,2.5,3.4
						c0.7,1.4,1.3,3.2,1.8,5c0.9,3.6,1,7.7,0.4,12c-0.6,4.2-1.9,8.6-3.8,12.8c-1.9,4.3-4.4,8.5-7.2,12.6c-2.9,4.3-5.8,8.2-8.5,11.9
						c-2.7,3.7-5.2,7.3-7.5,10.8c-2.2,3.5-4.2,7-5.8,10.6c-1.6,3.6-2.8,7.2-3.7,10.9c-1.7,7.4-2.1,15.1-0.8,23.5
						c0.6,4.2,1.7,8.5,3.4,13c1.7,4.4,4,9,7.3,13.5c-3-4.6-5.1-9.3-6.5-13.7c-1.4-4.5-2.1-8.8-2.4-12.9c-0.6-8.2,0.5-15.5,2.8-22.3
						c1.2-3.4,2.6-6.6,4.4-9.7c1.8-3.1,4-6.2,6.4-9.3c2.4-3.1,5.2-6.3,8.2-9.7C571.4,313.8,574.7,310.2,578.1,305.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M561.9,353.7c1-1.7,2-3.3,3.1-4.8c1.1-1.5,2.2-2.8,3.4-4.1c1.1-1.3,2.4-2.4,3.5-3.4c0.3-0.2,0.6-0.5,0.9-0.7
						c0.3-0.2,0.5-0.4,0.8-0.6c0.5-0.4,1.1-0.7,1.6-1c2.2-1.2,4-1.8,5.4-2c1.5-0.3,2.7-0.1,3.3-0.7c0.3-0.3,0.4-0.8,0.2-1.5
						c-0.2-0.7-0.8-1.5-1.9-2.3c-1-0.8-2.5-1.4-4.3-1.6c-1.7-0.2-3.7-0.1-5.6,0.3c-1,0.2-2,0.6-3,1c-0.5,0.2-1,0.5-1.5,0.7
						c-0.5,0.3-0.9,0.5-1.4,0.8c-1.8,1.1-3.7,2.5-5.3,4.2c-1.6,1.7-3.1,3.6-4.4,5.6c-1.2,2.1-2.2,4.3-3,6.5
						c-1.6,4.7-2.1,8.7-2.3,12.3c-0.2,3.6,0,6.8,0.3,9.9c0.7,6.3,1.9,12.4,3.8,21.4c-0.9-9.1-0.8-15.4,0-21.4c0.4-3,1-5.9,1.9-9
						C558.6,360.4,559.9,357.1,561.9,353.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M574.5,279.9c-0.6-1.9-1.5-3.7-2.6-5.3c-0.6-0.8-1.2-1.6-1.9-2.3c-0.7-0.7-1.5-1.3-2.3-1.9c-1.7-1.1-3.9-1.8-6.2-1.5
						c-1.1,0.2-2.1,0.5-3,1.1c-0.9,0.6-1.6,1.4-2.1,2.4c-0.4,0.9-0.6,1.9-0.6,2.8c0,0.9,0.2,1.6,0.5,2.3c0.5,1.3,1.2,2.1,1.8,2.7
						c1.2,1.2,2.1,1.6,2.4,1.4c0.4-0.3,0.1-1.2-0.4-2.6c-0.2-0.7-0.5-1.5-0.6-2.4c-0.1-0.9,0.1-1.5,0.5-2c0.4-0.4,1.1-0.8,2-0.9
						c0.8-0.1,1.9,0.3,2.9,1c1.1,0.7,2.1,1.8,3.1,2.9c0.9,1.2,1.7,2.5,2.4,3.9c1.3,3,1.8,5.9,1.9,8.5c0.1,2.6-0.2,5.1-0.8,7.5
						c-1.1,4.8-3.2,9.6-6.8,16.2c4.1-6.4,6.7-10.8,8.6-15.6c0.9-2.4,1.7-4.9,2-7.9C575.6,287.2,575.6,283.8,574.5,279.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M568.9,290c-0.3-0.5-0.5-1.1-0.8-1.6c-0.3-0.5-0.7-1.1-1.2-1.5c-0.9-1-1.9-1.8-3.4-2.3c-0.7-0.3-1.5-0.4-2.3-0.4
						c-0.8,0-1.6,0.3-2.3,0.6c-1.4,0.7-2.2,1.8-2.6,2.8c-0.5,1-0.6,2-0.6,2.7c0,0.8,0.1,1.4,0.3,1.9c0.3,1,0.7,1.5,1.2,1.5
						c0.4,0,0.9-0.5,1.3-1.4c0.4-0.8,0.9-2,1.5-2.7c0.3-0.4,0.7-0.6,1-0.7c0.3,0,0.5,0,0.9,0.1c0.4,0.2,1,0.5,1.6,1.1
						c0.3,0.3,0.6,0.5,0.8,0.9c0.3,0.3,0.5,0.7,0.8,1.1c1.1,1.5,1.8,3,2.3,4.5c0.5,1.4,0.8,2.8,0.9,4.2c0.1,1.4,0.1,2.9-0.3,4.5
						c-0.3,1.6-0.9,3.4-2.1,5.3c1.4-1.7,2.3-3.4,3-5c0.7-1.6,1.1-3.1,1.3-4.7c0.2-1.6,0.4-3.1,0.2-4.9
						C570.3,294.2,569.9,292.2,568.9,290z"
                    />
                  </g>
                  <g>
                    <path
                      d="M548.8,301.3c-1.9-0.3-3.8-0.3-5.2,0c-1.5,0.3-2.6,0.9-2.7,1.8c-0.2,0.9,0.7,1.8,2.3,2.4c1.6,0.6,3.8,0.8,5.9,0.2
						c2.2-0.6,3.6-1.2,5-1.9c1.5-0.7,2.9-1.4,4.7-2.2c-2.1,0.4-3.6,0.5-5.1,0.4C552.1,301.9,550.7,301.7,548.8,301.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M536.3,308.9c-5.8,0.2-11.1,2.8-10.6,4.5c0.4,1.7,6.2,1.7,11.5-0.2c5.5-1.9,7.5-3,13.4-4.3
						C544.5,309.2,542.3,308.8,536.3,308.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M525.6,316.8c-3.9-0.7-7.6,0.8-7.6,2.5c0.1,1.8,4,2.9,7.8,1.8c3.9-1.1,5.3-1.9,9.3-2.6
						C531,318.2,529.6,317.5,525.6,316.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M537.6,319c-2.2,1.1-3.9,2.9-4.8,4.6c-0.9,1.6-1.1,3.1-0.4,3.6c0.7,0.6,2,0.2,3.4-0.7c1.5-0.9,3.1-2.3,4.5-4
						c1.5-1.7,2.6-2.9,3.8-4.1c1.2-1.1,2.5-2.2,4.6-3.4c-2.4,0.7-4,1.3-5.6,1.8C541.5,317.3,539.9,317.8,537.6,319z"
                    />
                  </g>
                  <g>
                    <path
                      d="M545.3,322.1c-1.5,1.4-2.5,3.1-2.9,4.6c-0.4,1.5-0.2,2.7,0.6,3.2c0.8,0.4,1.9,0,3-1c1.1-1,2.2-2.5,2.9-4.3
						c1.5-3.7,1.8-5.3,4.1-8.8C549.9,318.6,548.4,319.3,545.3,322.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M554.7,308c-1.3,0.9-2.1,2.2-2.4,3.4c-0.3,1.2,0,2.2,0.7,2.7c0.7,0.5,1.8,0.3,2.7-0.3c1-0.6,1.9-1.7,2.4-3
						c1.1-2.8,1.3-4.1,3.2-6.7C558.7,305.8,557.4,306.2,554.7,308z"
                    />
                  </g>
                  <g>
                    <path
                      d="M532.3,341c-1.2,1.5-1.8,3.3-1.8,4.8c0,1.5,0.5,2.5,1.4,2.7c0.9,0.2,1.8-0.4,2.6-1.5c0.8-1,1.5-2.5,1.8-4.2
						c0.3-1.7,0.5-3,0.9-4.2c0.3-1.2,0.8-2.4,1.7-4.1c-1.4,1.3-2.3,2.2-3.3,3.1C534.5,338.6,533.5,339.5,532.3,341z"
                    />
                  </g>
                  <g>
                    <path
                      d="M533.2,356.5c-2.1,3.9-1.8,8.5-0.1,8.9c1.7,0.4,3.9-3.4,4.3-7.6c0.4-4.4,0.2-6.1,1.4-10.5
						C536.7,351.3,535.4,352.5,533.2,356.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M533.9,379.3c-0.4,2.4,0.2,4.9,1,6.6c0.8,1.7,2,2.6,2.8,2.3c0.8-0.3,1.2-1.6,1.3-3.2c0.1-1.7-0.2-3.7-0.7-5.8
						c-0.5-2.2-0.8-3.7-1-5.4c-0.2-1.6-0.2-3.3,0.2-5.7c-0.9,2.3-1.5,3.8-2.1,5.5C534.8,375.2,534.2,376.8,533.9,379.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M570.2,359.3c1.9-2.2,3.7-4.2,5-6c1.3-1.7,2.2-3.2,1.7-3.9c-0.5-0.7-2.3-0.5-4.4,0.8c-2.2,1.3-4.6,3.7-6,6.8
						c-1.4,3.1-1.9,5.4-2.3,7.6c-0.4,2.2-0.6,4.5-0.6,7.8c0.5-3.2,1.4-5.3,2.4-7.2C567.1,363.2,568.3,361.5,570.2,359.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M580.7,319.9c1.7-0.7,3-1.7,4-2.7c0.9-1,1.4-2.1,1.1-2.9c-0.4-0.8-1.6-1.1-3.1-0.8c-1.5,0.3-3.3,1.3-4.6,2.8
						c-1.3,1.6-2.1,2.8-2.8,4c-0.8,1.3-1.5,2.6-2.4,4.1c1.4-1.4,2.6-2.1,3.8-2.7C577.8,321.1,579,320.6,580.7,319.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-6.5,258.1c1.9-0.4,3.5-1.1,4.7-2c1.2-0.9,1.9-1.9,1.6-2.7c-0.2-0.8-1.4-1.3-3-1.2c-1.6,0.1-3.5,0.7-5.2,2
						c-1.7,1.3-2.7,2.3-3.8,3.3c-1,1-2.1,2-3.7,3.4c1.9-0.9,3.2-1.4,4.6-1.7C-9.8,258.7-8.5,258.5-6.5,258.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M8.4,246.2c-1.5,2.6-2.1,5.6-2,7.8c0.1,2.3,0.8,3.7,1.7,3.8c0.9,0.1,1.7-1.2,2.5-3.1c0.8-1.9,1.5-4.3,2-6.9
						c0.5-2.7,1.1-4.5,1.7-6.4c0.7-1.8,1.5-3.7,3.3-6.1c-2.1,2-3.5,3.5-4.9,5.1C11.2,242,9.9,243.6,8.4,246.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M544.8,223.7c-1.8-1.5-3.8-2.7-6-3.5c-2.1-0.9-4.5-1.4-6.9-1.4c-1.2,0-2.4,0.2-3.5,0.6c-1.2,0.4-2.3,0.9-3.2,1.6
						c-1.9,1.4-3.2,3.3-3.7,5.3c-0.3,1-0.3,2-0.2,3.1c0.2,1,0.5,1.8,0.9,2.5c0.9,1.4,2.1,2.3,3.1,2.7c1.1,0.4,2,0.4,2.6,0.2
						c0.6-0.2,0.8-0.4,0.8-0.6c-0.1-0.5-1.1-0.6-2.2-1.7c-1.1-1-2.1-3.1-1.2-5c0.4-1,1.3-2,2.4-2.7c0.6-0.4,1.2-0.6,1.9-0.8
						c0.7-0.2,1.5-0.3,2.2-0.3c3.2,0,7,1.4,10.2,3.5c3.3,2.2,5.7,4.8,7.4,7.5c0.9,1.3,1.6,2.7,2.2,4c0.6,1.4,1.1,2.7,1.5,4.1
						c0.8,2.8,1.2,5.8,1.2,9.2c0,3.4-0.4,7.1-1.6,11.5c1.4-4.3,2.2-8,2.5-11.5c0.3-3.4,0.2-6.5-0.3-9.6c-0.3-1.5-0.6-3.1-1-4.6
						c-0.5-1.5-1.1-3.1-1.9-4.7C550.7,230.1,548.4,226.7,544.8,223.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M547.9,236.8c-0.9-1.3-2.2-2.4-3.6-3.2c-1.5-0.8-3.3-1.2-5-1c-1.8,0.2-3.4,1.2-4.5,2.4c-1.1,1.2-1.7,2.9-1.6,4.4
						c0.1,1.7,1,2.9,1.8,3.6c0.9,0.7,1.9,1.1,2.7,1.1c0.8,0,1.4-0.2,1.8-0.5c0.3-0.3,0.4-0.6,0.3-0.8c-0.2-0.4-0.9-0.6-1.4-1.1
						c-0.3-0.3-0.6-0.6-0.8-1.1c-0.2-0.4-0.3-0.9-0.1-1.2c0.1-0.4,0.4-0.8,0.8-1.2c0.5-0.4,1.1-0.7,1.7-0.7c1.3-0.2,3.2,0.6,4.7,2
						c1.6,1.5,2.6,3.5,3.6,5.3c0.9,1.8,1.7,3.4,2.3,5.1c0.6,1.7,1.2,3.5,1.6,5.5c0.4,2,0.7,4.4,0.8,7.1c0.2-2.8,0.2-5.1,0.1-7.2
						c-0.1-2.1-0.3-4-0.6-5.9c-0.3-1.9-0.7-3.7-1.3-5.8c-0.3-1-0.6-2.1-1.1-3.3C549.5,239.4,548.9,238.1,547.9,236.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M613.1,514.1c0.5,1.2,0.7,2.4,0.8,3.5c0,1.1-0.1,2-0.4,2.7c-0.3,0.7-0.7,1.4-1.3,2.1c-0.6,0.6-1.4,1.3-2.4,1.7
						c-1.9,1-4,1.4-6.1,1.3c-2-0.1-3.6-0.8-4.7-1.5c-1.2-0.9-2-2.1-2.3-3.2c-0.4-1-0.3-2.3,0.1-3.4c0.4-1.1,0.9-2,1.4-2.8
						c0.5-0.8,0.9-1.4,0.9-2.1c0-0.6-0.6-1.3-1.9-1.6c-1.3-0.3-3.3,0-5.5,1.3c-1.1,0.7-2.1,1.6-3.1,2.9c-0.5,0.7-0.9,1.4-1.2,2.2
						c-0.4,0.8-0.6,1.8-0.8,2.7c-0.7,3.8,0.2,8.2,2.9,11.9c2.8,3.9,7.2,6.5,12,7.4c4.7,1,10.1,0.5,14.9-1.9c2.4-1.2,4.7-2.8,6.7-5
						c2-2.2,3.6-4.9,4.4-8c0.8-3,0.8-6.2,0.3-9c-0.6-2.9-1.7-5.4-3.1-7.7c-1.5-2.3-3.3-4.3-5.2-5.9c-1.9-1.6-4.1-2.9-6.1-3.7
						c-4.1-1.7-8-2.3-11.5-2.4c-7.1-0.1-12.9,1.4-18.6,3.1c-5.6,1.8-11.1,4.1-16.8,7.4c-5.7,3.2-11.6,7.4-17.6,13.4
						c6.6-5.4,12.9-8.7,18.8-11c5.9-2.3,11.4-3.5,16.8-4.2c5.4-0.6,11-0.7,16.3,0.5c2.7,0.6,5.3,1.6,7.5,3.1c1.2,0.8,2,1.6,2.8,2.6
						C611.9,511.7,612.6,512.9,613.1,514.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M586.9,487.9c2.8-1.8,5.2-4.2,7.1-6.8c1.9-2.6,3.2-5.3,3.9-7.8c0.7-2.5,0.8-4.7,0.6-6.3c-0.3-1.6-0.9-2.5-1.8-2.7
						c-1.8-0.3-3.7,2.1-6.3,5.3c-1.3,1.7-2.7,3.5-4.3,5.4c-1.6,2-3.3,4.1-5.1,6.3c-1.8,2.3-3.6,4.1-5.2,5.7
						c-1.7,1.6-3.2,3.1-4.9,4.4c-3.3,2.7-6.9,5.3-12.6,8.5c6.1-2.3,10.3-3.7,14.5-5.2c2.1-0.8,4.2-1.6,6.5-2.7
						C581.6,491.1,584.1,489.8,586.9,487.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M574,520.4c-1.2,2.3-1.3,5.1-0.8,7c0.5,1.9,1.5,3,2.4,2.8c0.9-0.2,1.4-1.4,1.8-3c0.4-1.6,0.7-3.5,0.8-5.6
						c0.2-2.2,0.5-3.7,1-5.3c0.5-1.5,1.3-3,2.9-4.9c-2,1.4-3.3,2.6-4.5,3.9C576.4,516.6,575.3,518,574,520.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M580.2,543.9c0.4,2.6,1.7,5,3.2,6.4c1.5,1.4,2.9,1.9,3.6,1.3c0.7-0.6,0.5-2,0-3.6c-0.5-1.6-1.4-3.6-2.5-5.5
						c-1.1-2-1.8-3.6-2.3-5.2c-0.5-1.6-0.9-3.3-0.8-5.9c-0.6,2.5-0.9,4.3-1.1,6.1C580,539.4,579.8,541.2,580.2,543.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M599.3,558c1.3,1.4,3,2.4,4.7,2.8c1.7,0.5,3.4,0.5,4.8,0.2c1.4-0.3,2.4-0.9,3-1.4c0.6-0.6,0.8-1.1,0.7-1.5
						c-0.4-0.8-2-0.9-3.9-1.2c-2-0.3-4.3-0.9-6.5-2.2c-1.2-0.7-2.1-1.5-2.9-2.3c-0.8-0.8-1.4-1.7-2-2.6c-1.2-1.8-2.1-3.9-3.1-7.1
						c0.5,3.3,0.8,5.6,1.4,7.8c0.3,1.1,0.6,2.3,1.2,3.5C597.2,555.2,598,556.6,599.3,558z"
                    />
                  </g>
                  <g>
                    <path
                      d="M613.7,553.2c2.2,1.1,4.7,1.3,6.5,1c1.8-0.3,3-1.2,2.9-2.1c-0.1-0.9-1.2-1.6-2.7-2.1c-1.5-0.5-3.5-0.9-5.5-1
						c-2.2-0.2-3.7-0.4-5.2-0.7c-1.5-0.4-3.1-0.9-5.1-2.2c1.7,1.7,2.9,2.8,4.2,3.9C610.1,551,611.5,552.1,613.7,553.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M623.6,545.9c1.5,0.1,2.9-0.6,3.8-1.4c0.8-0.8,1.1-1.9,0.7-2.6c-0.4-0.8-1.3-1.2-2.4-1.2c-1,0-2.2,0.3-3.3,1
						c-1.1,0.8-1.9,1.3-2.8,1.8c-0.9,0.5-1.8,0.9-3.2,1.2c1.4,0.3,2.5,0.5,3.5,0.7C620.9,545.6,622,545.8,623.6,545.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M584.7,454.4c0.8-4,0.4-8.1-0.6-11.1c-0.9-2.9-2.3-4.6-3.1-4.4c-0.9,0.3-1.1,2.3-1,5c0,2.8,0.2,6.4,0.3,10.1
						c0.1,3.8,0,6.5-0.4,9.1c-0.4,2.7-1,5.4-2.7,9.1c2.2-3.5,3.4-6,4.5-8.6C582.9,461.2,583.9,458.5,584.7,454.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M175.8,236.9c-0.3,3.3,0.7,6.7,2.2,8.7c1.4,2.1,3.1,2.9,3.7,2.4c0.7-0.5,0.5-2.1,0.1-4.1c-0.5-2.3-1-4.4-1.6-7.2
						c-0.5-2.8-0.6-4.8-0.4-6.9c0.2-2,0.7-4.2,2.3-6.9c-2.1,2.4-3.2,4.4-4.2,6.4C177,231.3,176.2,233.5,175.8,236.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M316.4,56.6c3.3,0.8,6.5,1.5,9,2c2.5,0.5,4.4,0.7,4.8-0.1c0.4-0.7-0.8-2.4-3.3-3.8c-2.5-1.5-6.3-2.6-10.1-2.5
						c-3.9,0.1-6.5,0.7-9.1,1.5c-2.5,0.8-5,1.6-8.5,3.4c3.6-1.2,6.2-1.4,8.7-1.5C310.5,55.6,313,55.9,316.4,56.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M166.4,149.7c1.4,3.4,3.9,6.3,6.1,7.9c2.2,1.7,4.1,2.1,4.7,1.5c0.6-0.7-0.3-2.3-1.7-4.4c-1.4-2.1-3.3-4.6-5.3-7.2
						c-2.1-2.7-3.3-4.7-4.4-6.9c-1.1-2.2-2-4.5-2.6-8.1c0.1,3.7,0.4,6.2,0.8,8.7C164.4,143.7,165,146.2,166.4,149.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M79.1,161.1c1.1-2.5,1.1-5.4,0.4-7.4c-0.7-2-1.8-3-2.6-2.8c-0.9,0.3-1.3,1.6-1.5,3.2c-0.3,1.7-0.4,3.7-0.5,5.9
						c-0.1,2.3-0.4,3.9-0.9,5.5c-0.5,1.6-1.3,3.2-3.1,5.1c2.1-1.5,3.4-2.8,4.7-4.2C76.7,165.2,77.9,163.7,79.1,161.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M340.8,260.3c3.5-6.8,4.5-14.6,2.8-15.1c-1.7-0.5-5.1,6.3-6.9,13.5c-0.9,3.8-1.5,6.4-2.2,9c-0.7,2.6-1.5,5.2-2.9,9
						c1.9-3.5,3.3-5.9,4.7-8.3C337.6,266.1,339,263.8,340.8,260.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M485,235.3c3.3,0,6.5-0.3,9-0.8c2.4-0.5,4.1-1.2,4.2-2.1c0-0.9-1.7-1.7-4.2-2.1c-2.6-0.4-6.1-0.3-9.4,0.6
						c-3.4,0.9-5.7,1.8-8,2.7c-2.4,1-4.7,1.9-7.8,3.1c3.5-0.8,5.9-1.1,8.3-1.3C479.3,235.3,481.6,235.3,485,235.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M596.5,141.5c-2.7,0.3-5.3,1.3-7,2.5c-1.7,1.2-2.6,2.4-2.2,3.2c0.4,0.8,1.9,0.9,3.8,0.6c1.9-0.3,4.3-1,6.5-2.1
						c2.4-1.1,4-1.9,5.8-2.5c1.7-0.7,3.5-1.2,6.3-1.7c-2.6-0.1-4.5-0.1-6.6-0.2C601.2,141.3,599.3,141.2,596.5,141.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M548.5,79c-2.4,1.8-4.6,3.6-6.2,5.2c-1.6,1.6-2.6,2.9-2.2,3.7c0.4,0.8,2.2,0.8,4.5-0.2c2.3-1,5.1-3,7.1-5.6
						c2-2.7,3.1-4.8,4-6.9c0.9-2.1,1.8-4.2,2.9-7.4c-1.5,3-3,4.7-4.5,6.3C552.6,75.7,550.9,77.1,548.5,79z"
                    />
                  </g>
                  <g>
                    <path
                      d="M482.8,101.2c3.5-0.6,7-1.5,9.5-2.4c2.6-0.9,4.3-1.9,4.2-2.8c-0.1-0.9-2.1-1.4-4.9-1.3c-2.8,0.1-6.6,0.8-10,2.3
						c-3.6,1.5-5.9,2.8-8.2,4.1c-2.3,1.3-4.6,2.7-7.9,4.7c3.6-1.5,6.1-2.3,8.6-2.9C476.6,102.3,479.2,101.9,482.8,101.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M503.3,526.1c2.5-1.7,3.3-4.9,2-6c-1.4-1.1-4.3,0.4-5.4,3.3c-1.2,2.9-1.3,4.3-2.8,7.1
						C499.4,528.3,500.7,527.9,503.3,526.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M281.3,518.4c2,0.3,3.8,0.4,5.3,0.2c1.5-0.1,2.7-0.5,3-1.3c0.3-0.8-0.5-2-2.2-2.8c-1.6-0.8-4.1-1.2-6.5-0.5
						c-2.4,0.7-3.9,1.5-5.3,2.3c-1.4,0.9-2.8,1.8-4.6,3.2c2.1-1,3.6-1.3,5.1-1.4C277.8,518,279.3,518.1,281.3,518.4z"
                    />
                  </g>
                </g>
              </g>
              <g>
                <defs>
                  <path
                    id="SVGID_00000064321561827995843650000016968362756354283172_"
                    d="M470.1,364.4c-41.6,1.8-220.4-16.7-221.8-48.9
					c-1.4-32.2,175.2-66,216.8-67.8c41.6-1.8,106,25.9,107.2,53.8C573.5,329.4,511.6,362.6,470.1,364.4z"
                  />
                </defs>
                <clipPath id="SVGID_00000106119752953705996340000001912766885822872449_">
                  <use
                    xlinkHref="#SVGID_00000064321561827995843650000016968362756354283172_"
                    overflow="visible"
                  />
                </clipPath>
              </g>
            </g>
          </g>
          <g id="Peddle2">
            <image
              overflow="visible"
              opacity="0.7"
              width="1390"
              height="1461"
              xlinkHref={Shadow7}
              transform="matrix(0.24 0 0 0.24 208.7093 51.4564)"
            ></image>
            <g>
              <g>
                <defs>
                  <path
                    id="SVGID_00000010300858419198374080000001506050848626457251_"
                    d="M454.8,225.8c-28.1,30.7-167.7,144-191.4,122.2
					c-23.8-21.8,77.2-170.5,105.4-201.2c28.1-30.7,93.3-56.6,113.9-37.7C503.3,127.9,483,195.1,454.8,225.8z"
                  />
                </defs>
                <clipPath id="SVGID_00000024713148338251722870000017533282859360354209_">
                  <use
                    xlinkHref="#SVGID_00000010300858419198374080000001506050848626457251_"
                    overflow="visible"
                  />
                </clipPath>
              </g>
              <g>
                <defs>
                  <path
                    id="SVGID_00000065762276448653709330000000371134363458002587_"
                    d="M454.8,225.8c-28.1,30.7-167.7,144-191.4,122.2
					c-23.8-21.8,77.2-170.5,105.4-201.2c28.1-30.7,93.3-56.6,113.9-37.7C503.3,127.9,483,195.1,454.8,225.8z"
                  />
                </defs>
                <use
                  xlinkHref="#SVGID_00000065762276448653709330000000371134363458002587_"
                  overflow="visible"
                  fill="#36B657"
                />
                <clipPath id="SVGID_00000152239332270616979290000001864199433279365049_">
                  <use
                    xlinkHref="#SVGID_00000065762276448653709330000000371134363458002587_"
                    overflow="visible"
                  />
                </clipPath>
                <g
                  opacity="0.2"
                  clipPath="url(#SVGID_00000152239332270616979290000001864199433279365049_)"
                >
                  <g>
                    <g>
                      <path
                        d="M312.1,219.6c8.1-1.2,15.4-3.8,20.8-7c5.4-3.2,8.8-6.9,8.1-10.4c-0.7-3.4-5.5-5.7-12.2-5.8c-6.7-0.1-15.4,2-22.9,6.8
							c-7.7,4.9-12.4,9-17.2,13c-4.7,4-9.4,8-16.3,13.7c8.1-3.8,13.9-5.6,19.8-6.9C297.9,221.7,303.8,220.8,312.1,219.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M314.5,239.9c7,1.8,13.9,2.1,19.5,1.1c5.5-1,9.7-3.2,10.5-6.6c0.8-3.4-2.4-7.3-8.1-9.9c-5.6-2.6-13.8-3.8-21.8-2.1
							c-8.2,1.8-13.5,3.9-18.8,6c-5.3,2.1-10.5,4.2-18,7.1c8-0.8,13.5-0.4,18.9,0.5C302,236.8,307.2,238.1,314.5,239.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M303.9,258.7c4.2,3.4,8.8,5.1,13,5.4c4.2,0.3,8.1-0.9,10-3.9c1.9-3,1.1-7.2-2.1-10.9c-3.1-3.7-8.6-6.8-14.9-7.1
							c-6.5-0.4-10.9,0.2-15.2,0.7c-4.3,0.5-8.6,1-14.6,1.5c5.8,1.7,9.5,3.7,12.9,5.9C296.4,252.6,299.5,255.2,303.9,258.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M282.6,276.1c1.5,4.7,4.5,8,8,9.7c3.4,1.7,7.3,1.9,10.2-0.1c2.9-2,4.1-5.7,3.3-9.8c-0.7-4-3.4-8.3-7.9-11
							c-4.6-2.8-8.1-4.2-11.5-5.8c-3.4-1.6-6.6-3.2-10.9-6.1c2.8,4.3,4.2,7.7,5.3,11.2C280.2,267.7,281.1,271.2,282.6,276.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M262,284.1c-1.5,5-1.3,9.6,0.1,13.5c1.3,3.8,3.8,7,7.3,7.6c3.4,0.7,7.1-1.5,9.3-5.8c2.2-4.2,3-10.5,0.8-16.4
							c-2.3-6-4.6-9.7-6.8-13.4c-2.3-3.6-4.5-7.1-7.6-12.2c1,5.8,0.8,9.9,0.1,13.8C264.5,275.2,263.5,279,262,284.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M238.4,276.8c-4.1,3.3-6.7,7-8.2,10.8c-0.3,1-0.7,1.9-0.9,2.9c-0.2,1-0.3,1.9-0.3,2.8c0,1.8,0.4,3.6,1.4,5
							c1,1.4,2.6,2.3,4.6,2.6c1,0.1,2.1,0.1,3.2-0.1c1.1-0.2,2.4-0.5,3.5-1.1c4.8-2.1,9.8-7.2,11.7-13.9c1.9-6.8,2-11.6,2-16.2
							c-0.1-4.6-0.3-9.1-0.8-15.3c-1.6,6-3.8,9.7-6.4,12.9C245.6,270.5,242.6,273.3,238.4,276.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M221.4,254.8c-5.9,0.7-10.8,2.7-14.6,5.4c-3.7,2.7-6.2,6.2-5.7,9.7c0.5,3.5,4.2,6.1,9.6,6.6
							c5.3,0.5,12.3-1.2,17.7-5.6c5.6-4.6,8.8-8.3,11.8-12c3-3.7,5.9-7.3,10.2-12.6c-5.6,3.6-10,5.2-14.3,6.3
							C231.8,253.5,227.4,254.1,221.4,254.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M214.7,232.3c-5.8-1.9-11.2-2.4-15.9-1.9c-1.2,0.2-2.3,0.3-3.3,0.7c-1.1,0.3-2,0.7-2.9,1.1c-1.7,0.9-3.1,2.2-3.7,3.8
							c-0.6,1.6-0.4,3.5,0.5,5.3c0.5,0.9,1.2,1.9,2,2.7c0.9,0.9,1.9,1.8,3.1,2.5c4.8,3.1,12.4,4.8,19.7,3.2c7.5-1.7,12.3-4,16.9-6.2
							c4.5-2.3,9-4.7,15-8.3c-6.9,1.6-11.7,1.4-16.4,0.8C225.1,235.5,220.6,234.3,214.7,232.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M221.6,214.1c-3.8-4.1-7-7.1-10.2-9.4c-1.6-1.2-3.2-2.2-4.8-3.1c-1.6-0.9-3.3-1.6-5.1-1.4c-1.7,0.1-3.3,1.2-4.4,3.3
							c-1.1,2.1-1.4,5.3-0.6,8.8c0.8,3.5,2.7,7.3,5.7,10.4c2.9,3.1,6.8,5.6,11,6.8c4.4,1.3,7.9,1.6,11.1,1.7
							c3.2,0.1,5.9-0.1,8.6-0.4c5.4-0.6,10.5-1.4,17.9-2.1c-7.3-1.5-12.2-3.2-16.6-5.4c-2.2-1.1-4.3-2.4-6.4-3.9
							C225.6,217.9,223.5,216.2,221.6,214.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M239.5,196.3c-0.1-5.5-0.9-9.8-2.3-13.6c-0.7-1.9-1.5-3.7-2.5-5.3c-1-1.6-2.2-2.9-3.9-3.6c-1.6-0.7-3.5-0.5-5.4,0.6
							c-1.9,1.1-3.7,3.3-4.9,6.3c-1.2,3-1.8,6.7-1.4,10.5c0.4,3.8,1.7,7.8,3.9,11.1c2.3,3.5,4.6,5.9,6.7,7.9c2.2,2,4.2,3.6,6.3,5.1
							c4.1,3,8.3,5.7,14,9.6c-4.3-5.5-6.5-9.9-8-14.2c-0.7-2.2-1.3-4.4-1.8-6.8C239.9,201.7,239.6,199.1,239.5,196.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M259.2,191.2c2.3-5.4,3.1-10.6,2.9-15.2c-0.1-1.2-0.2-2.2-0.4-3.3c-0.2-1.1-0.5-2-0.9-2.9c-0.8-1.8-1.9-3.2-3.5-4
							c-1.6-0.8-3.4-0.8-5.3,0c-1,0.4-2,1-2.9,1.8c-0.9,0.8-1.9,1.7-2.8,2.9c-3.5,4.5-5.8,11.9-4.6,19.3c1.3,7.6,3.3,12.4,5.3,17
							c2.1,4.6,4.3,9,7.7,15c-1.4-6.8-1-11.6-0.1-16C255.5,201.1,256.9,196.9,259.2,191.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M281.1,191.4c4.3-4.1,7.2-8,9.3-11.7c1-1.9,1.9-3.7,2.4-5.6c0.5-1.8,0.7-3.7,0-5.3c-0.6-1.6-2.1-2.9-4.3-3.4
							c-2.2-0.6-5.1-0.3-8.2,0.8c-3.1,1.2-6.3,3.3-8.9,6.2c-2.6,2.9-4.7,6.5-5.8,10.5c-1.2,4-1.6,7.4-1.8,10.4
							c-0.2,3-0.2,5.7-0.2,8.3c0.1,5.2,0.3,10.3,0,17.6c2.4-6.9,4.7-11.5,7.3-15.6c1.3-2.1,2.8-4,4.4-6
							C277.1,195.6,278.9,193.5,281.1,191.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M256.7,233.3c-1.5,3.2,1.5,6,5,5.6c3.5-0.4,5.8-3.7,3.7-6.5c-2.2-2.9-3.7-3.9-5.1-7
							C259.6,228.7,258.2,230,256.7,233.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M261.4,230.6c-1.4,1-1.8,2.3-1.5,3.7c0.3,1.4,1.3,2.8,2.8,3.6c1.5,0.9,3.3,0.9,4.7,0.2c1.4-0.7,2.3-2.2,2.1-4.1
							c-0.2-2-0.6-3.3-0.9-4.7c-0.3-1.3-0.6-2.6-0.7-4.4c-0.9,1.5-1.9,2.4-2.9,3.1C263.9,228.9,262.8,229.6,261.4,230.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M264.9,232.2c-1.3-0.6-2.1-0.2-2.9,0.7c-0.7,0.9-1.4,2.4-1.5,4.2c-0.1,1.7,0.7,3.3,2.1,4.1c1.4,0.8,3.3,0.7,4.8-0.7
							c1.5-1.4,2.2-2.7,2.8-3.8c0.6-1.2,1.2-2.3,2.1-3.6c-1.5,0.5-2.7,0.5-3.8,0.3C267.3,233.2,266.2,232.7,264.9,232.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M267.4,236.8c0-0.7-0.3-1.3-0.7-1.8c-0.5-0.5-1.1-1-1.9-1.2c-0.8-0.3-1.6-0.3-2.4-0.1c-0.8,0.2-1.5,0.6-2.1,1.3
							c-0.6,0.6-1.1,1.3-1.4,2c-0.3,0.7-0.6,1.4-0.6,2c0,0.7,0.1,1.3,0.5,2c0.4,0.6,1.1,1.3,2.1,1.7c1,0.5,2.1,0.8,3,1
							c0.9,0.2,1.7,0.2,2.5,0.1c1.6-0.1,3.1-0.5,4.5-1.6c-0.8,0.1-1.5-0.2-2-0.6c-0.5-0.4-0.8-0.8-1-1.3c-0.2-0.5-0.3-1-0.4-1.6
							C267.4,238,267.5,237.5,267.4,236.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M266.9,240c0.8-1.4,0.7-2.5-0.1-3.6c-0.7-1.1-2-2.2-3.8-2.6c-1.7-0.4-3.4,0.1-4.5,1.4c-1.1,1.3-1.4,3.5-0.4,5.4
							c1.1,2,2.1,3.1,3.2,4.1c1,1,2,2,3.2,3.4c-0.2-1.8,0-3.1,0.5-4.3C265.4,242.6,266.1,241.5,266.9,240z"
                      />
                    </g>
                    <g>
                      <path
                        d="M264.2,244.1c1.7-1.3,2.4-3.2,2.2-4.9c-0.2-1.7-1.4-3.1-3.1-3.7c-1.7-0.6-3.4-0.3-4.7,0.5c-1.3,0.8-2.1,2-2.1,3.8
							c0,1.9,0,3.3,0,4.7c-0.1,1.4-0.4,2.8-1.2,4.5c1.6-1,2.9-1.7,4.3-2.3C260.9,246.1,262.4,245.4,264.2,244.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M258.2,243.2c3.6,1.4,6.6-1.7,6.2-5.2c-0.4-3.5-4.2-5.7-7.3-3.5c-3.2,2.3-4.3,3.9-7.7,5.4
							C253,240.5,254.5,241.8,258.2,243.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M254.5,239.4c1.8,3.2,5.8,2.6,7.7-0.4c1.8-3,0.5-6.9-3.1-7c-3.7-0.1-5.5,0.5-8.9-0.3
							C252.5,234.3,252.7,236.1,254.5,239.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M255,235.1c0,1.7,1.1,2.7,2.4,3c1.3,0.3,3-0.2,4.3-1.3c1.4-1.1,2.1-2.6,2.3-3.8c0.1-1.2-0.4-2.3-1.8-2.8
							c-2.9-1-4.7-1.1-6.9-3C255.8,230.1,255,231.7,255,235.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M299.7,201.9c5.7-1.8,10.2-4.8,13.4-8.2c3.2-3.4,5-7.2,3.9-10.6c-1.1-3.3-5.3-5.2-10.8-4.6c-5.4,0.5-12,3.6-16.6,9.1
							c-4.7,5.7-7.1,10.1-9.4,14.4c-2.2,4.4-4.4,8.6-7.4,14.7c4.8-4.8,8.8-7.3,12.8-9.3C289.6,205.4,293.8,203.9,299.7,201.9z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M624.2,225.2c3.8,4.2,8.2,7.3,12.1,9.2c3.9,1.8,7.4,2.3,9.1,0.6c1.7-1.7,1.1-5.3-1.4-9.3c-2.6-3.9-7.1-8.1-12.8-10.5
							c-5.8-2.5-10-3.6-14.1-4.8c-4.1-1.1-8.3-2.3-14.2-4.1c5.3,3.1,8.6,5.8,11.6,8.7C617.5,217.9,620.3,220.9,624.2,225.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M613.5,234.5c1.7,4.7,4.2,8.8,6.9,11.6c2.7,2.8,5.6,4.4,7.9,3.4c2.2-0.9,3.3-4.2,2.6-8.5c-0.7-4.2-3.2-9.4-7.3-13.3
							c-4.2-4-7.5-6.2-10.7-8.5c-3.3-2.2-6.5-4.4-11.1-7.6c3.6,4.3,5.5,7.6,7.1,11C610.4,226.1,611.7,229.6,613.5,234.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M598.5,235.7c-0.3,3.7,0.5,7,2,9.6c1.5,2.5,3.7,4.3,6.1,4.2c2.4-0.1,4.5-2.2,5.4-5.4c0.9-3.2,0.5-7.6-1.7-11.3
							c-2.3-3.8-4.3-6.2-6.3-8.5c-2-2.3-3.9-4.5-6.5-7.8c1.3,4,1.6,6.8,1.6,9.7C599.1,229,598.9,231.8,598.5,235.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M580.4,230.2c-2.1,2.7-2.8,5.7-2.5,8.4c0.3,2.6,1.7,4.9,4,5.8c2.3,0.9,4.9,0.1,6.9-1.9c2-2,3.4-5.2,3.2-8.8
							c-0.2-3.7-0.7-6.3-1.1-8.9c-0.4-2.5-0.7-5.1-0.8-8.6c-1.4,3.3-2.8,5.4-4.3,7.4C584.2,225.6,582.5,227.5,580.4,230.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M567.7,221.5c-3.4,1.1-6,3-7.7,5.3c-1.7,2.3-2.5,4.9-1.5,7.2c0.9,2.2,3.6,3.5,7,3.1c3.3-0.4,7.2-2.4,9.7-5.9
							c2.6-3.6,3.8-6.4,5-9.1c1.2-2.7,2.3-5.4,4-9.1c-2.9,2.8-5.4,4.3-7.8,5.4C573.9,219.5,571.3,220.4,567.7,221.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M562.8,205.2c-3.5-1.1-6.6-1.1-9.4-0.5c-0.7,0.2-1.4,0.3-2,0.6c-0.6,0.3-1.2,0.6-1.7,0.9c-1.1,0.7-1.9,1.6-2.3,2.8
							c-0.4,1.1-0.3,2.4,0.3,3.6c0.3,0.6,0.7,1.2,1.3,1.8c0.6,0.6,1.2,1.1,2,1.6c3.1,1.9,8,2.8,12.5,1.3c4.6-1.6,7.4-3.3,10.1-5.1
							c2.6-1.8,5.1-3.7,8.4-6.4c-4.1,1.4-7.1,1.6-9.9,1.4C569.2,206.9,566.4,206.2,562.8,205.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M568.7,186.9c-2.6-3.1-5.7-5.2-8.7-6.3c-3-1.1-5.9-1.1-7.7,0.5c-1.8,1.6-1.9,4.8-0.1,8.1c1.8,3.3,5.4,6.6,10.1,8
							c4.8,1.4,8.2,1.8,11.5,2.1c3.3,0.3,6.5,0.5,11.2,0.9c-4.3-1.8-6.8-3.7-9.1-5.8C573.6,192.4,571.5,190.1,568.7,186.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M579.1,174.3c-1.1-4.1-3-7.4-5.1-9.9c-0.6-0.6-1.1-1.2-1.7-1.7c-0.6-0.5-1.2-0.9-1.8-1.2c-1.2-0.6-2.4-0.9-3.6-0.7
							c-1.2,0.3-2.2,1.1-2.8,2.4c-0.3,0.6-0.6,1.4-0.8,2.2c-0.2,0.8-0.3,1.8-0.2,2.8c0.1,4,2,9,5.8,12.6c3.9,3.7,7,5.5,10.1,7.2
							c3.1,1.7,6.2,3.3,10.6,5.4c-3.6-3.3-5.4-6.2-6.8-9.1C581.3,181.5,580.3,178.5,579.1,174.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M592.2,171.1c0.9-3.8,1.3-6.8,1.4-9.5c0.1-1.3,0-2.7-0.1-4c-0.1-1.3-0.4-2.5-1.2-3.5c-0.8-0.9-2-1.4-3.6-1.2
							c-1.6,0.2-3.6,1.2-5.3,3c-1.7,1.8-3.1,4.4-3.7,7.3c-0.6,2.9-0.6,6,0.3,8.9c0.9,3,2.1,5.2,3.3,7c1.2,1.8,2.4,3.3,3.6,4.8
							c2.4,2.9,4.9,5.5,8.1,9.5c-2-4.8-2.9-8.3-3.3-11.7c-0.2-1.7-0.3-3.4-0.3-5.2C591.6,175,591.7,173.1,592.2,171.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M609.4,174.5c3.1-2.2,5.3-4.3,6.9-6.6c0.8-1.1,1.5-2.3,2.1-3.5c0.5-1.2,0.8-2.4,0.6-3.6c-0.2-1.2-1.1-2.2-2.5-2.8
							c-1.4-0.6-3.3-0.8-5.5-0.4c-2.2,0.4-4.5,1.5-6.6,3.3c-2,1.7-3.8,4-4.8,6.5c-1.1,2.7-1.6,4.9-1.9,6.9c-0.3,2-0.4,3.8-0.5,5.6
							c-0.1,3.5,0,6.9-0.1,11.8c1.5-4.6,3.1-7.6,5.1-10.1c1-1.3,2-2.5,3.2-3.6C606.4,176.8,607.8,175.6,609.4,174.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M619.9,183.9c4-0.8,7.3-2.3,9.9-4.3c0.6-0.5,1.2-1,1.7-1.5c0.5-0.5,1-1.1,1.3-1.6c0.7-1.1,1.1-2.3,1-3.6
							c-0.2-1.2-0.9-2.3-2.1-3.1c-0.6-0.4-1.3-0.8-2.1-1c-0.8-0.2-1.7-0.4-2.7-0.5c-3.9-0.3-9.1,1.3-12.8,4.9
							c-3.8,3.7-5.8,6.7-7.7,9.6c-1.8,3-3.5,6-5.6,10.2c3.4-3.4,6.2-5.1,9.1-6.3C612.8,185.6,615.8,184.7,619.9,183.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M628.3,196.5c4,0.9,7.4,1,10.3,0.8c1.5-0.1,2.9-0.4,4.1-0.8c1.2-0.4,2.4-1,3.1-2c0.7-1,0.8-2.3,0.3-3.8
							c-0.5-1.5-1.8-3-3.7-4.4c-1.9-1.3-4.3-2.3-7-2.7c-2.7-0.4-5.6-0.2-8.3,0.7c-2.8,0.9-4.9,2-6.7,3c-1.8,1-3.3,2.1-4.8,3.1
							c-3,2.1-5.8,4.2-10.1,6.8c4.9-1.3,8.4-1.8,11.8-1.8c1.7,0,3.4,0,5.2,0.2C624.3,195.8,626.3,196,628.3,196.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M594.8,198.8c-2.4,0.4-2.8,3.2-1.3,5c1.6,1.9,4.4,1.9,5.2-0.4c0.8-2.4,0.8-3.7,2-5.7
							C598.6,198.6,597.3,198.3,594.8,198.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M598.2,200.4c-1.1-0.4-2-0.1-2.7,0.6c-0.7,0.7-1.1,1.8-1,3c0.1,1.2,0.8,2.2,1.7,2.7c0.9,0.5,2.2,0.5,3.2-0.4
							c1-0.9,1.7-1.6,2.3-2.3c0.6-0.7,1.3-1.4,2.2-2.1c-1.2,0-2.1-0.2-2.9-0.4C600.2,201.2,599.3,200.8,598.2,200.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M598.6,203c-0.2-0.9-0.7-1.3-1.5-1.4c-0.8-0.1-1.9,0.1-3,0.8c-1,0.6-1.7,1.7-1.6,2.8c0.1,1.1,0.9,2.2,2.2,2.5
							c1.4,0.3,2.4,0.2,3.3,0.1c0.9-0.1,1.8-0.2,2.9-0.2c-0.9-0.7-1.3-1.4-1.6-2.1C599,204.8,598.8,204,598.6,203z"
                      />
                    </g>
                    <g>
                      <path
                        d="M597,206.3c0.4-0.3,0.6-0.7,0.8-1.1c0.1-0.5,0.1-1,0-1.6c-0.1-0.5-0.4-1.1-0.9-1.4c-0.4-0.4-1-0.6-1.6-0.7
							c-0.6-0.1-1.2-0.1-1.7,0c-0.5,0.1-1,0.2-1.4,0.4c-0.4,0.2-0.7,0.6-0.9,1c-0.2,0.5-0.3,1.1-0.2,1.9c0.1,0.8,0.3,1.5,0.6,2.1
							c0.3,0.6,0.6,1.1,0.9,1.5c0.7,0.8,1.5,1.6,2.7,2c-0.4-0.5-0.5-1-0.4-1.4c0-0.4,0.2-0.8,0.4-1.1c0.2-0.3,0.5-0.6,0.7-0.9
							C596.3,206.7,596.6,206.6,597,206.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M594.9,207.2c1.1-0.1,1.7-0.6,2.1-1.5c0.3-0.8,0.5-2,0-3.2c-0.4-1.1-1.4-1.9-2.6-2c-1.2-0.1-2.5,0.5-3.2,1.9
							c-0.7,1.4-0.9,2.4-1.1,3.4c-0.2,1-0.4,1.9-0.7,3.2c0.9-0.8,1.8-1.2,2.7-1.4C592.9,207.4,593.8,207.3,594.9,207.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M591.5,207.3c1.4,0.5,2.8,0.1,3.7-0.7c0.9-0.8,1.2-2,0.9-3.2c-0.3-1.2-1.2-2.1-2.1-2.5c-0.9-0.4-2-0.4-3,0.3
							c-1.1,0.7-1.9,1.3-2.7,1.8c-0.8,0.5-1.7,0.9-3.1,1.1c1.2,0.5,2.1,1,3,1.6C589.1,206.1,590.1,206.7,591.5,207.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M589.7,203.4c0.6,2.6,3.6,3.1,5.4,1.5c1.8-1.6,1.7-4.6-0.8-5.5c-2.6-1-3.9-1-6.1-2.3
							C589.3,199.5,589.1,200.8,589.7,203.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M590.5,199.9c-1.1,2.2,0.8,4.3,3.2,4.2c2.4-0.1,4.2-2.4,2.8-4.5c-1.4-2.2-2.4-3-3.3-5.2
							C592.6,196.7,591.6,197.6,590.5,199.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M593.1,198.5c-0.9,0.7-1.1,1.7-0.8,2.5c0.4,0.9,1.3,1.6,2.4,2c1.2,0.3,2.3,0.2,3.1-0.2c0.8-0.4,1.2-1.1,0.9-2.1
							c-0.5-2-1.2-3.1-0.9-5.1C596.3,197,595.1,197.2,593.1,198.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M629.5,211.2c3.3,2.5,6.7,4,9.9,4.5c3.2,0.5,6.1,0.1,7.6-1.9c1.5-1.9,0.9-5.1-1.5-8c-2.4-2.9-6.8-5.5-11.7-6
							c-5.1-0.5-8.5-0.1-11.9,0.2c-3.4,0.4-6.7,0.8-11.3,1.5c4.6,0.9,7.6,2.2,10.3,3.7C623.6,206.8,626.1,208.6,629.5,211.2z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M241.1,523.3c3.8,4.2,8.2,7.3,12.1,9.2c3.9,1.8,7.4,2.3,9.1,0.6c1.7-1.7,1.1-5.3-1.4-9.3c-2.6-3.9-7.1-8.1-12.8-10.5
							c-5.8-2.5-10-3.6-14.1-4.8c-4.1-1.1-8.3-2.3-14.2-4.1c5.3,3.1,8.6,5.8,11.6,8.7C234.3,516,237.1,519,241.1,523.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M230.3,532.5c1.7,4.7,4.2,8.8,6.9,11.6c2.7,2.8,5.6,4.4,7.9,3.4c2.2-0.9,3.3-4.2,2.6-8.5c-0.7-4.2-3.2-9.4-7.3-13.3
							c-4.2-4-7.5-6.2-10.7-8.5c-3.3-2.2-6.5-4.4-11.1-7.6c3.6,4.3,5.5,7.6,7.1,11C227.3,524.1,228.6,527.6,230.3,532.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M215.4,533.7c-0.3,3.7,0.5,7,2,9.6c1.5,2.5,3.7,4.3,6.1,4.2c2.4-0.1,4.5-2.2,5.4-5.4c0.9-3.2,0.5-7.6-1.7-11.3
							c-2.3-3.8-4.3-6.2-6.3-8.5c-2-2.3-3.9-4.5-6.5-7.8c1.3,4,1.6,6.8,1.6,9.7C216,527.1,215.7,529.9,215.4,533.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M197.2,528.3c-2.1,2.7-2.8,5.7-2.5,8.4c0.3,2.6,1.7,4.9,4,5.8c2.3,0.9,4.9,0.1,6.9-1.9c2-2,3.4-5.2,3.2-8.8
							c-0.2-3.7-0.7-6.3-1.1-8.9c-0.4-2.5-0.7-5.1-0.8-8.6c-1.4,3.3-2.8,5.4-4.3,7.4C201.1,523.7,199.4,525.5,197.2,528.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M184.6,519.6c-3.4,1.1-6,3-7.7,5.3c-1.7,2.3-2.5,4.9-1.5,7.2c0.9,2.2,3.6,3.5,7,3.1c3.3-0.4,7.2-2.4,9.7-5.9
							c2.6-3.6,3.8-6.4,5-9.1c1.2-2.7,2.3-5.4,4-9.1c-2.9,2.8-5.4,4.3-7.8,5.4C190.7,517.6,188.1,518.4,184.6,519.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M179.7,503.2c-3.5-1.1-6.6-1.1-9.4-0.5c-0.7,0.2-1.4,0.3-2,0.6c-0.6,0.3-1.2,0.6-1.7,0.9c-1.1,0.7-1.9,1.6-2.3,2.8
							c-0.4,1.1-0.3,2.4,0.3,3.6c0.3,0.6,0.7,1.2,1.3,1.8c0.6,0.6,1.2,1.1,2,1.6c3.1,1.9,8,2.8,12.5,1.3c4.6-1.6,7.4-3.3,10.1-5.1
							c2.6-1.8,5.1-3.7,8.4-6.4c-4.1,1.4-7.1,1.6-9.9,1.4C186,504.9,183.3,504.3,179.7,503.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M185.6,484.9c-2.6-3.1-5.7-5.2-8.7-6.3c-3-1.1-5.9-1.1-7.7,0.5c-1.8,1.6-1.9,4.8-0.1,8.1c1.8,3.3,5.4,6.6,10.1,8
							c4.8,1.4,8.2,1.8,11.5,2.1c3.3,0.3,6.5,0.5,11.2,0.9c-4.3-1.8-6.8-3.7-9.1-5.8C190.4,490.4,188.4,488.1,185.6,484.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M195.9,472.3c-1.1-4.1-3-7.4-5.1-9.9c-0.6-0.6-1.1-1.2-1.7-1.7c-0.6-0.5-1.2-0.9-1.8-1.2c-1.2-0.6-2.4-0.9-3.6-0.7
							c-1.2,0.3-2.2,1.1-2.8,2.4c-0.3,0.6-0.6,1.4-0.8,2.2c-0.2,0.8-0.3,1.8-0.2,2.8c0.1,4,2,9,5.8,12.6c3.9,3.7,7,5.5,10.1,7.2
							c3.1,1.7,6.2,3.3,10.6,5.4c-3.6-3.3-5.4-6.2-6.8-9.1C198.2,479.5,197.1,476.5,195.9,472.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M209.1,469.2c0.9-3.8,1.3-6.8,1.4-9.5c0.1-1.3,0-2.7-0.1-4c-0.1-1.3-0.4-2.5-1.2-3.5c-0.8-0.9-2-1.4-3.6-1.2
							c-1.6,0.2-3.6,1.2-5.3,3c-1.7,1.8-3.1,4.4-3.7,7.3c-0.6,2.9-0.6,6,0.3,8.9c0.9,3,2.1,5.2,3.3,7c1.2,1.8,2.4,3.3,3.6,4.8
							c2.4,2.9,4.9,5.5,8.1,9.5c-2-4.8-2.9-8.3-3.3-11.7c-0.2-1.7-0.3-3.4-0.3-5.2C208.4,473,208.6,471.1,209.1,469.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M226.2,472.6c3.1-2.2,5.3-4.3,6.9-6.6c0.8-1.1,1.5-2.3,2.1-3.5c0.5-1.2,0.8-2.4,0.6-3.6c-0.2-1.2-1.1-2.2-2.5-2.8
							c-1.4-0.6-3.3-0.8-5.5-0.4c-2.2,0.4-4.5,1.5-6.6,3.3c-2,1.7-3.8,4-4.8,6.5c-1.1,2.7-1.6,4.9-1.9,6.9c-0.3,2-0.4,3.8-0.5,5.6
							c-0.1,3.5,0,6.9-0.1,11.8c1.5-4.6,3.1-7.6,5.1-10.1c1-1.3,2-2.5,3.2-3.6C223.3,474.9,224.6,473.7,226.2,472.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M236.8,481.9c4-0.8,7.3-2.3,9.9-4.3c0.6-0.5,1.2-1,1.7-1.5c0.5-0.5,1-1.1,1.3-1.6c0.7-1.1,1.1-2.3,1-3.6
							c-0.2-1.2-0.9-2.3-2.1-3.1c-0.6-0.4-1.3-0.8-2.1-1c-0.8-0.2-1.7-0.4-2.7-0.5c-3.9-0.3-9.1,1.3-12.8,4.9
							c-3.8,3.7-5.8,6.7-7.7,9.6c-1.8,3-3.5,6-5.6,10.2c3.4-3.4,6.2-5.1,9.1-6.3C229.7,483.6,232.7,482.8,236.8,481.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M245.2,494.6c4,0.9,7.4,1,10.3,0.8c1.5-0.1,2.9-0.4,4.1-0.8c1.2-0.4,2.4-1,3.1-2c0.7-1,0.8-2.3,0.3-3.8
							c-0.5-1.5-1.8-3-3.7-4.4c-1.9-1.3-4.3-2.3-7-2.7c-2.7-0.4-5.6-0.2-8.3,0.7c-2.8,0.9-4.9,2-6.7,3c-1.8,1-3.3,2.1-4.8,3.1
							c-3,2.1-5.8,4.2-10.1,6.8c4.9-1.3,8.4-1.8,11.8-1.8c1.7,0,3.4,0,5.2,0.2C241.2,493.9,243.1,494.1,245.2,494.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M211.7,496.8c-2.4,0.4-2.8,3.2-1.3,5c1.6,1.9,4.4,1.9,5.2-0.4c0.8-2.4,0.8-3.7,2-5.7
							C215.4,496.7,214.2,496.4,211.7,496.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M215,498.4c-1.1-0.4-2-0.1-2.7,0.6c-0.7,0.7-1.1,1.8-1,3c0.1,1.2,0.8,2.2,1.7,2.7c0.9,0.5,2.2,0.5,3.2-0.4
							c1-0.9,1.7-1.6,2.3-2.3c0.6-0.7,1.3-1.4,2.2-2.1c-1.2,0-2.1-0.2-2.9-0.4C217,499.2,216.2,498.9,215,498.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M215.5,501.1c-0.2-0.9-0.7-1.3-1.5-1.4c-0.8-0.1-1.9,0.1-3,0.8c-1,0.6-1.7,1.7-1.6,2.8c0.1,1.1,0.9,2.2,2.2,2.5
							c1.4,0.3,2.4,0.2,3.3,0.1c0.9-0.1,1.8-0.2,2.9-0.2c-0.9-0.7-1.3-1.4-1.6-2.1C215.9,502.9,215.7,502.1,215.5,501.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M213.9,504.3c0.4-0.3,0.6-0.7,0.8-1.1c0.1-0.5,0.1-1,0-1.6c-0.1-0.5-0.4-1.1-0.9-1.4c-0.4-0.4-1-0.6-1.6-0.7
							c-0.6-0.1-1.2-0.1-1.7,0c-0.5,0.1-1,0.2-1.4,0.4c-0.4,0.2-0.7,0.6-0.9,1c-0.2,0.5-0.3,1.1-0.2,1.9c0.1,0.8,0.3,1.5,0.6,2.1
							c0.3,0.6,0.6,1.1,0.9,1.5c0.7,0.8,1.5,1.6,2.7,2c-0.4-0.5-0.5-1-0.4-1.4c0-0.4,0.2-0.8,0.4-1.1c0.2-0.3,0.5-0.6,0.7-0.9
							C213.1,504.8,213.5,504.6,213.9,504.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M211.8,505.3c1.1-0.1,1.7-0.6,2.1-1.5c0.3-0.8,0.5-2,0-3.2c-0.4-1.1-1.4-1.9-2.6-2c-1.2-0.1-2.5,0.5-3.2,1.9
							c-0.7,1.4-0.9,2.4-1.1,3.4c-0.2,1-0.4,1.9-0.7,3.2c0.9-0.8,1.8-1.2,2.7-1.4C209.7,505.4,210.6,505.4,211.8,505.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M208.4,505.3c1.4,0.5,2.8,0.1,3.7-0.7c0.9-0.8,1.2-2,0.9-3.2c-0.3-1.2-1.2-2.1-2.1-2.5c-0.9-0.4-2-0.4-3,0.3
							c-1.1,0.7-1.9,1.3-2.7,1.8c-0.8,0.5-1.7,0.9-3.1,1.1c1.2,0.5,2.1,1,3,1.6C206,504.2,206.9,504.8,208.4,505.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M206.6,501.5c0.6,2.6,3.6,3.1,5.4,1.5c1.8-1.6,1.7-4.6-0.8-5.5c-2.6-1-3.9-1-6.1-2.3
							C206.1,497.5,205.9,498.8,206.6,501.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M207.3,497.9c-1.1,2.2,0.8,4.3,3.2,4.2c2.4-0.1,4.2-2.4,2.8-4.5c-1.4-2.2-2.4-3-3.3-5.2
							C209.5,494.8,208.5,495.6,207.3,497.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M210,496.6c-0.9,0.7-1.1,1.7-0.8,2.5c0.4,0.9,1.3,1.6,2.4,2c1.2,0.3,2.3,0.2,3.1-0.2c0.8-0.4,1.2-1.1,0.9-2.1
							c-0.5-2-1.2-3.1-0.9-5.1C213.2,495,211.9,495.2,210,496.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M246.4,509.3c3.3,2.5,6.7,4,9.9,4.5c3.2,0.5,6.1,0.1,7.6-1.9c1.5-1.9,0.9-5.1-1.5-8c-2.4-2.9-6.8-5.5-11.7-6
							c-5.1-0.5-8.5-0.1-11.9,0.2c-3.4,0.4-6.7,0.8-11.3,1.5c4.6,0.9,7.6,2.2,10.3,3.7C240.4,504.9,243,506.7,246.4,509.3z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M155.7,208.9c3.8,4.2,8.2,7.3,12.1,9.2c3.9,1.8,7.4,2.3,9.1,0.6c1.7-1.7,1.1-5.3-1.4-9.3c-2.6-3.9-7.1-8.1-12.8-10.5
							c-5.8-2.5-10-3.6-14.1-4.8c-4.1-1.1-8.3-2.3-14.2-4.1c5.3,3.1,8.6,5.8,11.6,8.7C148.9,201.6,151.7,204.6,155.7,208.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M144.9,218.2c1.7,4.7,4.2,8.8,6.9,11.6c2.7,2.8,5.6,4.4,7.9,3.4c2.2-0.9,3.3-4.2,2.6-8.5c-0.7-4.2-3.2-9.4-7.3-13.3
							c-4.2-4-7.5-6.2-10.7-8.5c-3.3-2.2-6.5-4.4-11.1-7.6c3.6,4.3,5.5,7.6,7.1,11C141.9,209.8,143.2,213.3,144.9,218.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M130,219.4c-0.3,3.7,0.5,7,2,9.6c1.5,2.5,3.7,4.3,6.1,4.2c2.4-0.1,4.5-2.2,5.4-5.4c0.9-3.2,0.5-7.6-1.7-11.3
							c-2.3-3.8-4.3-6.2-6.3-8.5c-2-2.3-3.9-4.5-6.5-7.8c1.3,4,1.6,6.8,1.6,9.7C130.6,212.7,130.3,215.5,130,219.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M111.8,214c-2.1,2.7-2.8,5.7-2.5,8.4c0.3,2.6,1.7,4.9,4,5.8c2.3,0.9,4.9,0.1,6.9-1.9c2-2,3.4-5.2,3.2-8.8
							c-0.2-3.7-0.7-6.3-1.1-8.9c-0.4-2.5-0.7-5.1-0.8-8.6c-1.4,3.3-2.8,5.4-4.3,7.4C115.7,209.3,114,211.2,111.8,214z"
                      />
                    </g>
                    <g>
                      <path
                        d="M99.2,205.2c-3.4,1.1-6,3-7.7,5.3c-1.7,2.3-2.5,4.9-1.5,7.2c0.9,2.2,3.6,3.5,7,3.1c3.3-0.4,7.2-2.4,9.7-5.9
							c2.6-3.6,3.8-6.4,5-9.1c1.2-2.7,2.3-5.4,4-9.1c-2.9,2.8-5.4,4.3-7.8,5.4C105.3,203.2,102.7,204.1,99.2,205.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M94.3,188.9c-3.5-1.1-6.6-1.1-9.4-0.5c-0.7,0.2-1.4,0.3-2,0.6c-0.6,0.3-1.2,0.6-1.7,0.9c-1.1,0.7-1.9,1.6-2.3,2.8
							c-0.4,1.1-0.3,2.4,0.3,3.6c0.3,0.6,0.7,1.2,1.3,1.8c0.6,0.6,1.2,1.1,2,1.6c3.1,1.9,8,2.8,12.5,1.3c4.6-1.6,7.4-3.3,10.1-5.1
							c2.6-1.8,5.1-3.7,8.4-6.4c-4.1,1.4-7.1,1.6-9.9,1.4C100.6,190.6,97.9,190,94.3,188.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M100.2,170.6c-2.6-3.1-5.7-5.2-8.7-6.3c-3-1.1-5.9-1.1-7.7,0.5c-1.8,1.6-1.9,4.8-0.1,8.1c1.8,3.3,5.4,6.6,10.1,8
							c4.8,1.4,8.2,1.8,11.5,2.1c3.3,0.3,6.5,0.5,11.2,0.9c-4.3-1.8-6.8-3.7-9.1-5.8C105,176.1,103,173.8,100.2,170.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M110.5,158c-1.1-4.1-3-7.4-5.1-9.9c-0.6-0.6-1.1-1.2-1.7-1.7c-0.6-0.5-1.2-0.9-1.8-1.2c-1.2-0.6-2.4-0.9-3.6-0.7
							c-1.2,0.3-2.2,1.1-2.8,2.4c-0.3,0.6-0.6,1.4-0.8,2.2c-0.2,0.8-0.3,1.8-0.2,2.8c0.1,4,2,9,5.8,12.6c3.9,3.7,7,5.5,10.1,7.2
							c3.1,1.7,6.2,3.3,10.6,5.4c-3.6-3.3-5.4-6.2-6.8-9.1C112.8,165.2,111.7,162.2,110.5,158z"
                      />
                    </g>
                    <g>
                      <path
                        d="M123.7,154.9c0.9-3.8,1.3-6.8,1.4-9.5c0.1-1.3,0-2.7-0.1-4c-0.1-1.3-0.4-2.5-1.2-3.5c-0.8-0.9-2-1.4-3.6-1.2
							c-1.6,0.2-3.6,1.2-5.3,3c-1.7,1.8-3.1,4.4-3.7,7.3c-0.6,2.9-0.6,6,0.3,8.9c0.9,3,2.1,5.2,3.3,7c1.2,1.8,2.4,3.3,3.6,4.8
							c2.4,2.9,4.9,5.5,8.1,9.5c-2-4.8-2.9-8.3-3.3-11.7c-0.2-1.7-0.3-3.4-0.3-5.2C123,158.7,123.2,156.8,123.7,154.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M140.8,158.2c3.1-2.2,5.3-4.3,6.9-6.6c0.8-1.1,1.5-2.3,2.1-3.5c0.5-1.2,0.8-2.4,0.6-3.6c-0.2-1.2-1.1-2.2-2.5-2.8
							c-1.4-0.6-3.3-0.8-5.5-0.4c-2.2,0.4-4.5,1.5-6.6,3.3c-2,1.7-3.8,4-4.8,6.5c-1.1,2.7-1.6,4.9-1.9,6.9c-0.3,2-0.4,3.8-0.5,5.6
							c-0.1,3.5,0,6.9-0.1,11.8c1.5-4.6,3.1-7.6,5.1-10.1c1-1.3,2-2.5,3.2-3.6C137.9,160.6,139.2,159.4,140.8,158.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M151.4,167.6c4-0.8,7.3-2.3,9.9-4.3c0.6-0.5,1.2-1,1.7-1.5c0.5-0.5,1-1.1,1.3-1.6c0.7-1.1,1.1-2.3,1-3.6
							c-0.2-1.2-0.9-2.3-2.1-3.1c-0.6-0.4-1.3-0.8-2.1-1c-0.8-0.2-1.7-0.4-2.7-0.5c-3.9-0.3-9.1,1.3-12.8,4.9
							c-3.8,3.7-5.8,6.7-7.7,9.6c-1.8,3-3.5,6-5.6,10.2c3.4-3.4,6.2-5.1,9.1-6.3C144.2,169.3,147.3,168.5,151.4,167.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M159.8,180.2c4,0.9,7.4,1,10.3,0.8c1.5-0.1,2.9-0.4,4.1-0.8c1.2-0.4,2.4-1,3.1-2c0.7-1,0.8-2.3,0.3-3.8
							c-0.5-1.5-1.8-3-3.7-4.4c-1.9-1.3-4.3-2.3-7-2.7c-2.7-0.4-5.6-0.2-8.3,0.7c-2.8,0.9-4.9,2-6.7,3c-1.8,1-3.3,2.1-4.8,3.1
							c-3,2.1-5.8,4.2-10.1,6.8c4.9-1.3,8.4-1.8,11.8-1.8c1.7,0,3.4,0,5.2,0.2C155.8,179.5,157.7,179.8,159.8,180.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M126.3,182.5c-2.4,0.4-2.8,3.2-1.3,5c1.6,1.9,4.4,1.9,5.2-0.4c0.8-2.4,0.8-3.7,2-5.7
							C130,182.3,128.8,182.1,126.3,182.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M129.6,184.1c-1.1-0.4-2-0.1-2.7,0.6c-0.7,0.7-1.1,1.8-1,3c0.1,1.2,0.8,2.2,1.7,2.7c0.9,0.5,2.2,0.5,3.2-0.4
							c1-0.9,1.7-1.6,2.3-2.3c0.6-0.7,1.3-1.4,2.2-2.1c-1.2,0-2.1-0.2-2.9-0.4C131.6,184.9,130.8,184.5,129.6,184.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M130.1,186.7c-0.2-0.9-0.7-1.3-1.5-1.4c-0.8-0.1-1.9,0.1-3,0.8c-1,0.6-1.7,1.7-1.6,2.8c0.1,1.1,0.9,2.2,2.2,2.5
							c1.4,0.3,2.4,0.2,3.3,0.1c0.9-0.1,1.8-0.2,2.9-0.2c-0.9-0.7-1.3-1.4-1.6-2.1C130.5,188.5,130.3,187.7,130.1,186.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M128.5,190c0.4-0.3,0.6-0.7,0.8-1.1c0.1-0.5,0.1-1,0-1.6c-0.1-0.5-0.4-1.1-0.9-1.4c-0.4-0.4-1-0.6-1.6-0.7
							c-0.6-0.1-1.2-0.1-1.7,0c-0.5,0.1-1,0.2-1.4,0.4c-0.4,0.2-0.7,0.6-0.9,1c-0.2,0.5-0.3,1.1-0.2,1.9c0.1,0.8,0.3,1.5,0.6,2.1
							c0.3,0.6,0.6,1.1,0.9,1.5c0.7,0.8,1.5,1.6,2.7,2c-0.4-0.5-0.5-1-0.4-1.4c0-0.4,0.2-0.8,0.4-1.1c0.2-0.3,0.5-0.6,0.7-0.9
							C127.7,190.4,128.1,190.3,128.5,190z"
                      />
                    </g>
                    <g>
                      <path
                        d="M126.4,190.9c1.1-0.1,1.7-0.6,2.1-1.5c0.3-0.8,0.5-2,0-3.2c-0.4-1.1-1.4-1.9-2.6-2c-1.2-0.1-2.5,0.5-3.2,1.9
							c-0.7,1.4-0.9,2.4-1.1,3.4c-0.2,1-0.4,1.9-0.7,3.2c0.9-0.8,1.8-1.2,2.7-1.4C124.3,191.1,125.2,191,126.4,190.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M123,191c1.4,0.5,2.8,0.1,3.7-0.7c0.9-0.8,1.2-2,0.9-3.2c-0.3-1.2-1.2-2.1-2.1-2.5c-0.9-0.4-2-0.4-3,0.3
							c-1.1,0.7-1.9,1.3-2.7,1.8c-0.8,0.5-1.7,0.9-3.1,1.1c1.2,0.5,2.1,1,3,1.6C120.6,189.9,121.5,190.4,123,191z"
                      />
                    </g>
                    <g>
                      <path
                        d="M121.2,187.2c0.6,2.6,3.6,3.1,5.4,1.5c1.8-1.6,1.7-4.6-0.8-5.5c-2.6-1-3.9-1-6.1-2.3
							C120.7,183.2,120.5,184.5,121.2,187.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M121.9,183.6c-1.1,2.2,0.8,4.3,3.2,4.2c2.4-0.1,4.2-2.4,2.8-4.5c-1.4-2.2-2.4-3-3.3-5.2
							C124.1,180.4,123.1,181.3,121.9,183.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M124.6,182.2c-0.9,0.7-1.1,1.7-0.8,2.5c0.4,0.9,1.3,1.6,2.4,2c1.2,0.3,2.3,0.2,3.1-0.2c0.8-0.4,1.2-1.1,0.9-2.1
							c-0.5-2-1.2-3.1-0.9-5.1C127.8,180.7,126.5,180.9,124.6,182.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M161,194.9c3.3,2.5,6.7,4,9.9,4.5c3.2,0.5,6.1,0.1,7.6-1.9c1.5-1.9,0.9-5.1-1.5-8c-2.4-2.9-6.8-5.5-11.7-6
							c-5.1-0.5-8.5-0.1-11.9,0.2c-3.4,0.4-6.7,0.8-11.3,1.5c4.6,0.9,7.6,2.2,10.3,3.7C155,190.5,157.6,192.3,161,194.9z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M353.7,131.6c-6.1,1.4-12.7,4.3-17.8,8c-2.6,1.8-4.9,3.9-7,6c-2,2.1-3.7,4.3-5.1,6.4c-2.8,4.3-4.2,8.4-4.6,11.5
							c-0.2,1.6-0.1,2.9,0.2,3.9c0.3,1,0.9,1.8,1.6,2.2c0.8,0.4,1.7,0.6,2.7,0.4c1-0.2,2.2-0.6,3.5-1.2c2.5-1.2,5.5-3.2,8.8-5.5
							c1.6-1.1,3.4-2.3,5.2-3.6c1.8-1.2,3.8-2.5,5.8-3.8c4-2.7,8.2-5.1,13.1-8c5-3,9.8-5.5,14-8.2c4.2-2.6,8-5.4,11.3-8.5
							c1.7-1.5,3.3-3.1,4.8-4.8c1.6-1.7,3.1-3.5,4.6-5.5c3-3.9,6-8.5,8.6-14.3c-3.6,5.3-7.4,9-11.1,11.8c-1.9,1.4-3.7,2.6-5.5,3.7
							c-1.8,1.1-3.7,2-5.5,2.7c-3.7,1.6-7.5,2.6-11.9,3.5C365,129.4,360,130.1,353.7,131.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M362.6,150.6c-5,2.2-10,4.1-14.7,5.9c-4.7,1.7-9.2,3.3-13.1,4.7c-4,1.4-7.4,2.8-9.9,4.2c-2.5,1.4-4.1,2.9-4.3,4.7
							c-0.1,1.7,1.3,3.5,4.2,5c2.8,1.5,7.1,2.5,12.3,2.7c5.2,0.2,11.2-0.6,17.4-2.6c6.1-2.1,12.3-5.3,17.6-9.8
							c5.4-4.6,9.3-9,12.4-13.3c3.1-4.3,5.4-8.3,7.5-12.4c2-4.1,3.8-8.4,5.4-13.2c1.6-4.8,3-10.2,4-16.7c-2,6.2-4.5,11.1-7.2,15.2
							c-2.6,4.1-5.4,7.4-8.4,10.3c-3,3-6.2,5.6-10,8.1C372,145.9,367.8,148.3,362.6,150.6z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M401.3,173.5c-6-1.9-13.1-2.8-19.4-2.2c-3.2,0.3-6.2,0.8-9,1.6c-2.8,0.8-5.4,1.8-7.7,2.9c-4.6,2.3-7.9,5-9.8,7.5
							c-1,1.2-1.6,2.4-1.8,3.5c-0.3,1.1-0.2,2,0.3,2.7c0.4,0.8,1.2,1.3,2.2,1.7c1,0.4,2.2,0.6,3.6,0.8c2.8,0.2,6.3,0.1,10.3-0.2
							c2-0.1,4.1-0.3,6.4-0.4c2.2-0.1,4.5-0.3,6.9-0.4c4.8-0.2,9.7-0.2,15.4-0.2c5.9,0,11.2,0.3,16.2,0.1c5-0.1,9.7-0.6,14.1-1.5
							c2.2-0.4,4.4-1,6.6-1.7c2.2-0.7,4.4-1.4,6.7-2.4c4.6-1.9,9.5-4.3,14.7-7.9c-5.8,2.7-10.9,3.9-15.6,4.5
							c-2.3,0.3-4.5,0.4-6.7,0.3c-2.1,0-4.2-0.2-6.2-0.5c-4-0.5-7.8-1.6-12-3C412.2,177.4,407.6,175.5,401.3,173.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M399.3,194.3c-5.4-0.7-10.7-1.6-15.6-2.5c-4.9-0.9-9.6-1.9-13.7-2.7c-4.1-0.8-7.8-1.4-10.7-1.5
							c-2.9-0.1-5.1,0.4-6.1,1.9c-1,1.4-0.6,3.7,1.1,6.4c1.7,2.7,4.8,5.8,9.2,8.6c4.3,2.8,9.9,5.3,16.3,6.7
							c6.3,1.4,13.3,1.8,20.1,0.6c7-1.1,12.6-3,17.5-5c4.8-2.1,8.9-4.4,12.8-6.9c3.9-2.5,7.6-5.3,11.4-8.6c3.8-3.3,7.8-7.3,12-12.3
							c-4.9,4.3-9.6,7.2-13.9,9.4c-4.4,2.2-8.4,3.5-12.5,4.6c-4.1,1-8.2,1.6-12.7,1.9C409.8,195.2,404.9,195.1,399.3,194.3z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M143.2,295.9c4-4.8,7.5-11.1,9.3-17.2c0.9-3.1,1.6-6.1,1.9-9c0.3-2.9,0.3-5.7,0.2-8.2c-0.4-5.1-1.7-9.2-3.3-11.9
							c-0.8-1.4-1.7-2.4-2.6-3c-0.9-0.6-1.8-0.9-2.6-0.8c-0.9,0.1-1.7,0.6-2.4,1.3c-0.7,0.8-1.4,1.8-2,3.1c-1.3,2.5-2.4,5.9-3.7,9.7
							c-0.6,1.9-1.3,3.9-2,6.1c-0.7,2.1-1.4,4.3-2.2,6.6c-1.6,4.6-3.4,9-5.5,14.3c-2.2,5.4-4.4,10.3-6.2,15
							c-1.7,4.7-3.1,9.2-3.8,13.6c-0.4,2.2-0.7,4.5-0.9,6.8c-0.2,2.3-0.3,4.7-0.3,7.1c0,4.9,0.4,10.4,1.8,16.6
							c-0.4-6.3,0.4-11.6,1.6-16.1c0.6-2.3,1.3-4.4,2.2-6.3c0.8-2,1.7-3.8,2.7-5.5c2-3.5,4.4-6.6,7.3-10
							C135.6,304.6,139.1,300.9,143.2,295.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M124.7,286.2c2.7-4.8,5.5-9.3,8.1-13.6c2.7-4.3,5.3-8.2,7.6-11.7c2.3-3.5,4.2-6.7,5.4-9.3c1.2-2.6,1.5-4.9,0.5-6.3
							c-1-1.5-3.2-2-6.4-1.4c-3.2,0.6-7.2,2.3-11.4,5.3c-4.2,3-8.6,7.3-12.2,12.6c-3.6,5.4-6.6,11.7-8.1,18.4
							c-1.5,6.9-1.9,12.8-1.8,18.1c0.1,5.3,0.7,9.9,1.6,14.4c0.9,4.5,2.1,9,3.7,13.8c1.7,4.8,3.8,9.9,7,15.7
							c-2.2-6.2-3.2-11.6-3.5-16.4c-0.4-4.8-0.2-9.2,0.4-13.3c0.6-4.2,1.6-8.2,3-12.5C120,295.7,121.9,291.1,124.7,286.2z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M453.4,473.1c-7.8,2.4-14.6,6.2-19.4,10.2c-4.8,4-7.6,8.2-6.4,11.6c1.2,3.3,6.3,4.8,13,3.8c6.7-1,14.9-4.4,21.5-10.4
							c6.8-6.1,10.9-10.9,14.9-15.6c4-4.7,8-9.4,14-16.1c-7.3,5.1-12.9,7.7-18.4,9.9C467.1,468.8,461.4,470.6,453.4,473.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M447.9,453.4c-7.2-0.7-14.1,0.2-19.4,2c-5.3,1.8-9.1,4.7-9.3,8.2c-0.2,3.5,3.5,6.8,9.5,8.5c6,1.7,14.2,1.6,21.8-1.4
							c7.8-3.1,12.7-6,17.6-8.9c4.9-2.9,9.7-5.8,16.7-9.9c-7.8,2.1-13.3,2.5-18.7,2.5C460.7,454.5,455.3,454.1,447.9,453.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M455.3,433.2c-4.7-2.7-9.5-3.7-13.7-3.3c-4.2,0.4-7.9,2.2-9.3,5.4c-1.4,3.2,0.1,7.2,3.8,10.4
							c3.7,3.1,9.6,5.3,15.9,4.7c6.4-0.7,10.7-1.9,14.9-3.1c4.2-1.2,8.3-2.4,14.1-3.8c-6-0.7-9.9-2.1-13.6-3.8
							C463.7,438,460.2,435.9,455.3,433.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M473.5,412.7c-2.2-4.4-5.7-7.2-9.4-8.4c-3.7-1.2-7.5-0.8-10.1,1.7c-2.6,2.4-3.1,6.3-1.8,10.2c1.4,3.9,4.7,7.6,9.6,9.6
							c5,2,8.7,2.9,12.3,3.9c3.6,1,7.1,2.1,11.8,4.3c-3.4-3.8-5.3-7-7-10.2C477.3,420.6,475.8,417.2,473.5,412.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M492.7,401.5c0.7-5.1-0.3-9.7-2.2-13.3c-1.9-3.6-4.9-6.3-8.4-6.4c-3.5-0.1-6.7,2.6-8.3,7.2
							c-1.5,4.5-1.3,10.8,1.8,16.3c3.2,5.6,6.1,8.9,8.9,12.1c2.8,3.2,5.6,6.3,9.4,10.8c-1.9-5.5-2.3-9.6-2.3-13.6
							C491.6,410.7,492,406.8,492.7,401.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M517.1,405c3.5-3.9,5.5-8,6.4-12c0.2-1,0.4-2,0.4-3c0-1,0-1.9-0.1-2.8c-0.3-1.8-1-3.5-2.2-4.7
							c-1.3-1.2-2.9-1.9-4.9-1.8c-1,0-2.1,0.2-3.1,0.6c-1.1,0.4-2.2,0.9-3.3,1.6c-4.4,2.8-8.5,8.7-9.3,15.5
							c-0.8,7-0.1,11.7,0.6,16.3c0.8,4.5,1.7,8.9,3.2,15c0.6-6.2,2.3-10.2,4.3-13.8C511,412.3,513.5,409,517.1,405z"
                      />
                    </g>
                    <g>
                      <path
                        d="M537.4,423.9c5.7-1.6,10.3-4.3,13.5-7.6c3.2-3.2,5.1-7.1,4.1-10.4c-1-3.3-5.1-5.3-10.5-5c-5.4,0.3-11.9,3.1-16.6,8.4
							c-4.8,5.4-7.3,9.6-9.8,13.8c-2.3,4.1-4.7,8.2-8.1,14.1c5-4.5,9-6.7,13.1-8.4C527.3,426.9,531.5,425.6,537.4,423.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M547.6,445.1c6,1,11.5,0.6,16-0.7c1.1-0.4,2.2-0.7,3.2-1.2c1-0.5,1.9-1,2.7-1.6c1.6-1.2,2.7-2.6,3.1-4.4
							c0.4-1.7-0.1-3.5-1.4-5.2c-0.6-0.8-1.4-1.7-2.5-2.4c-1-0.7-2.2-1.5-3.5-2c-5.2-2.3-13-2.8-20,0c-7.2,2.9-11.5,5.9-15.7,8.8
							c-4.1,3-8.1,6.1-13.5,10.5c6.6-2.7,11.4-3.3,16-3.4C536.8,443.6,541.4,444.1,547.6,445.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M543.7,464.2c4.4,3.5,8.1,5.9,11.5,7.7c1.7,0.9,3.5,1.7,5.2,2.3c1.7,0.6,3.5,1,5.3,0.6c1.7-0.4,3.1-1.7,3.8-4
							c0.7-2.2,0.6-5.4-0.8-8.8c-1.3-3.3-3.9-6.8-7.2-9.3c-3.4-2.6-7.5-4.4-11.9-5c-4.5-0.6-8.1-0.4-11.2,0
							c-3.1,0.4-5.8,1.1-8.4,1.8c-5.2,1.4-10.1,3.1-17.4,4.9c7.5,0.3,12.6,1.2,17.3,2.7c2.3,0.8,4.6,1.7,6.9,2.8
							C539.1,461,541.5,462.4,543.7,464.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M528.8,484.6c1,5.4,2.5,9.5,4.4,13.1c1,1.8,2.1,3.4,3.3,4.8c1.2,1.4,2.7,2.5,4.4,2.9c1.7,0.4,3.5,0,5.2-1.5
							c1.7-1.4,3.1-3.9,3.9-7c0.7-3.1,0.7-6.9-0.2-10.6c-1-3.7-2.9-7.4-5.6-10.3c-2.8-3.1-5.4-5.1-7.9-6.8c-2.5-1.7-4.7-2.9-7-4.1
							c-4.6-2.3-9.1-4.3-15.4-7.3c5.1,4.8,8,8.7,10.2,12.8c1.1,2,2,4.1,2.8,6.4C527.6,479.3,528.3,481.8,528.8,484.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M510.1,492.7c-1.4,5.7-1.4,11-0.4,15.5c0.3,1.1,0.5,2.2,0.9,3.2c0.4,1,0.8,1.9,1.3,2.7c1,1.6,2.4,2.9,4.1,3.4
							c1.7,0.5,3.5,0.2,5.3-0.9c0.9-0.5,1.8-1.3,2.6-2.2c0.8-0.9,1.6-2,2.3-3.3c2.7-5,3.8-12.7,1.5-19.8c-2.4-7.3-5.2-11.7-7.9-15.9
							c-2.8-4.2-5.7-8.2-10-13.6c2.4,6.5,2.8,11.3,2.7,15.8C512.2,482.3,511.5,486.8,510.1,492.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M488.5,496c-3.6,4.8-5.9,9-7.3,13c-0.7,2-1.3,4-1.5,5.9c-0.2,1.9-0.1,3.7,0.8,5.3c0.9,1.5,2.5,2.5,4.7,2.7
							c2.2,0.2,5.1-0.5,7.9-2.1c2.8-1.6,5.7-4.3,7.8-7.6c2.1-3.3,3.6-7.2,4.1-11.2c0.5-4.2,0.4-7.6,0.2-10.6c-0.3-3-0.7-5.6-1.1-8.2
							c-0.9-5.1-1.9-10.1-2.8-17.4c-1.3,7.2-2.8,12.1-4.8,16.5c-1,2.2-2.1,4.4-3.4,6.7C491.8,491.2,490.3,493.6,488.5,496z"
                      />
                    </g>
                    <g>
                      <path
                        d="M505.9,450.8c0.9-3.4-2.4-5.6-5.8-4.7c-3.4,0.9-5.2,4.5-2.7,7c2.6,2.5,4.3,3.3,6.2,6.1
							C503.8,455.8,505,454.3,505.9,450.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M501.8,454.2c1.2-1.2,1.4-2.6,0.9-3.9c-0.5-1.3-1.7-2.5-3.4-3.1c-1.7-0.6-3.4-0.4-4.6,0.6c-1.2,0.9-1.9,2.6-1.4,4.4
							c0.5,1.9,1.1,3.2,1.6,4.5c0.5,1.3,1,2.5,1.4,4.2c0.7-1.6,1.5-2.6,2.4-3.6C499.5,456.3,500.5,455.4,501.8,454.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M498,453.2c1.3,0.3,2.1-0.1,2.7-1.2c0.6-1,1-2.6,0.8-4.4c-0.2-1.7-1.2-3.2-2.7-3.8c-1.5-0.6-3.4-0.2-4.6,1.4
							c-1.2,1.6-1.7,3-2.2,4.2c-0.4,1.3-0.8,2.4-1.5,3.9c1.4-0.8,2.6-0.9,3.7-0.9C495.5,452.6,496.6,452.9,498,453.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M494.8,449.1c0.1,0.7,0.5,1.2,1,1.7c0.5,0.4,1.3,0.8,2.1,0.9c0.8,0.1,1.7,0.1,2.4-0.3c0.8-0.3,1.4-0.9,1.9-1.6
							c0.5-0.7,0.8-1.5,1.1-2.2c0.2-0.7,0.3-1.4,0.3-2.1c-0.1-0.7-0.3-1.3-0.8-1.8c-0.5-0.6-1.3-1.1-2.4-1.4
							c-1.1-0.3-2.2-0.5-3.1-0.5c-0.9,0-1.7,0.1-2.5,0.3c-1.5,0.4-3,0.9-4.2,2.3c0.8-0.2,1.5,0,2.1,0.3c0.5,0.3,0.9,0.7,1.2,1.2
							c0.3,0.5,0.5,1,0.7,1.5C494.6,447.8,494.6,448.4,494.8,449.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M494.8,445.7c-0.6,1.5-0.3,2.6,0.6,3.6c0.9,1,2.4,1.8,4.1,2c1.7,0.2,3.4-0.6,4.2-2.1c0.9-1.5,0.9-3.7-0.5-5.4
							c-1.4-1.8-2.6-2.7-3.8-3.6c-1.2-0.9-2.3-1.7-3.7-2.8c0.5,1.8,0.5,3.1,0.2,4.4C495.9,443,495.4,444.2,494.8,445.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M496.9,441.3c-1.5,1.5-1.9,3.6-1.4,5.2c0.5,1.6,1.9,2.8,3.6,3.1c1.7,0.3,3.4-0.2,4.5-1.2c1.1-1,1.7-2.3,1.5-4.1
							c-0.3-1.8-0.6-3.2-0.7-4.6c-0.1-1.4-0.1-2.8,0.5-4.7c-1.5,1.3-2.6,2.1-3.9,3C499.7,438.9,498.4,439.7,496.9,441.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M502.9,441.2c-3.7-0.8-6.3,2.7-5.3,6.1c1,3.4,5,5,7.7,2.3c2.8-2.8,3.6-4.5,6.8-6.6C508.4,443.1,506.8,442,502.9,441.2
							z"
                      />
                    </g>
                    <g>
                      <path
                        d="M507.1,444.4c-2.2-2.9-6.2-1.6-7.5,1.7c-1.3,3.3,0.6,6.9,4.2,6.5c3.7-0.5,5.4-1.4,8.9-1.1
							C510,449.2,509.4,447.3,507.1,444.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M507.3,448.7c-0.3-1.6-1.5-2.5-2.9-2.6c-1.4-0.1-2.9,0.6-4.1,2c-1.2,1.3-1.7,2.9-1.7,4.1c0.1,1.3,0.7,2.2,2.2,2.4
							c3,0.6,4.8,0.4,7.3,1.9C507.4,453.8,507.9,452.1,507.3,448.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M468.5,488.6c-5.3,2.7-9.3,6.4-11.9,10.2c-2.6,3.8-3.8,7.9-2.1,11.1c1.6,3.1,6.1,4.3,11.4,2.9
							c5.3-1.4,11.3-5.5,15-11.7c3.7-6.3,5.4-11.1,7-15.7c1.5-4.7,3-9.2,5-15.7c-3.9,5.5-7.5,8.6-11.2,11.3
							C477.9,483.5,474,485.7,468.5,488.6z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M426.3,566.6c5.8-2.4,11.9-6.2,16.3-10.7c2.3-2.2,4.3-4.6,5.9-7c1.7-2.4,3-4.8,4-7.2c2.1-4.7,2.9-8.9,2.7-12.1
							c-0.1-1.6-0.4-2.9-0.8-3.9c-0.5-1-1.1-1.6-2-1.9c-0.8-0.3-1.8-0.3-2.8,0c-1,0.3-2.1,0.9-3.2,1.7c-2.3,1.6-4.9,4-7.8,6.8
							c-1.4,1.4-3,2.8-4.6,4.4c-1.6,1.5-3.3,3.1-5.1,4.7c-3.5,3.3-7.3,6.4-11.7,10c-4.5,3.7-8.8,7-12.5,10.3
							c-3.8,3.3-7,6.6-9.9,10.2c-1.4,1.8-2.7,3.6-4,5.5c-1.3,1.9-2.5,4-3.7,6.1c-2.3,4.4-4.5,9.3-6.2,15.5c2.7-5.8,5.9-10,9.1-13.4
							c1.6-1.7,3.2-3.2,4.9-4.5c1.6-1.3,3.3-2.5,5-3.6c3.4-2.1,7-3.8,11.2-5.4C415.5,570.6,420.3,569,426.3,566.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M414.5,549.3c4.6-2.9,9.2-5.6,13.6-8.1c4.4-2.5,8.6-4.7,12.2-6.7c3.7-2,6.9-3.9,9.1-5.7c2.3-1.8,3.6-3.6,3.5-5.3
							c-0.2-1.7-1.9-3.3-4.9-4.3c-3-1-7.4-1.4-12.6-0.7c-5.1,0.6-11,2.3-16.8,5.3c-5.7,3-11.4,7.2-15.8,12.4
							c-4.6,5.4-7.8,10.4-10.2,15.1c-2.4,4.7-4.1,9.1-5.4,13.5c-1.4,4.4-2.4,8.9-3.2,13.9c-0.8,5-1.3,10.6-1.3,17.1
							c1-6.5,2.7-11.7,4.7-16.1c2-4.4,4.2-8.1,6.7-11.5c2.5-3.4,5.3-6.5,8.5-9.6C405.9,555.4,409.8,552.3,414.5,549.3z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M372.6,532.7c6.2,0.9,13.4,0.7,19.5-0.9c3.1-0.8,6-1.8,8.7-3c2.7-1.2,5-2.6,7.1-4.1c4.2-3,7-6.2,8.5-9
							c0.7-1.4,1.2-2.7,1.2-3.7c0.1-1.1-0.1-2-0.7-2.7c-0.5-0.7-1.4-1.1-2.4-1.4c-1-0.2-2.3-0.3-3.7-0.2c-2.8,0.2-6.3,0.9-10.2,1.8
							c-1.9,0.4-4,0.9-6.2,1.4c-2.2,0.5-4.4,1-6.8,1.5c-4.7,1-9.5,1.8-15.1,2.6c-5.8,0.9-11.1,1.5-16,2.4c-4.9,0.9-9.4,2.1-13.7,3.7
							c-2.1,0.8-4.2,1.7-6.3,2.7c-2.1,1-4.2,2.1-6.3,3.4c-4.2,2.6-8.7,5.7-13.3,10.1c5.3-3.6,10.2-5.6,14.7-6.9
							c2.3-0.6,4.4-1.1,6.5-1.4c2.1-0.3,4.1-0.5,6.2-0.5c4-0.1,7.9,0.3,12.4,1.1C361.2,530.7,366.2,531.8,372.6,532.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M371.3,511.9c5.5-0.2,10.8-0.1,15.8,0c5,0.1,9.8,0.3,14,0.5c4.2,0.1,7.9,0.1,10.8-0.2c2.9-0.4,4.9-1.2,5.7-2.8
							c0.7-1.6,0.1-3.8-2.1-6.2c-2.1-2.4-5.7-5-10.4-7.1c-4.7-2.1-10.7-3.6-17.1-4c-6.5-0.3-13.4,0.4-20,2.6
							c-6.7,2.2-12,4.9-16.5,7.7c-4.4,2.8-8.1,5.7-11.6,8.8c-3.4,3.1-6.6,6.4-9.9,10.3c-3.2,3.9-6.6,8.4-9.8,14
							c4.2-5,8.3-8.7,12.2-11.5c4-2.8,7.8-4.8,11.6-6.5c3.9-1.6,7.8-2.9,12.2-3.9C360.8,512.7,365.7,512,371.3,511.9z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M514.4,139c10.6-1.1,21.3-3.7,30.7-7.7c9.4-3.9,17.6-9.3,23.7-14.8c6.1-5.5,10.2-11.1,12.5-15.3
							c2.3-4.2,2.9-6.9,2.1-7.4c-1.6-1-7.7,7.5-19.9,16.2c-6.1,4.3-13.6,8.7-22.2,12.2c-8.6,3.5-18.2,6.3-28.2,8.1
							c-10.2,1.9-19.1,2.6-27.2,3.2c-8.1,0.6-15.5,1.1-23,1.9c-7.4,0.8-14.9,1.9-23.1,4.1c-4.1,1.1-8.3,2.5-12.7,4.4
							c-4.4,1.9-8.9,4.3-13.4,7.6c9.3-6,18.4-8.6,26.5-10.2c8.2-1.5,15.6-1.9,22.9-2c7.4-0.1,14.7,0.2,22.9,0.5
							C494.2,139.9,503.4,140.1,514.4,139z"
                      />
                    </g>
                    <g>
                      <path
                        d="M458.8,128.6c2.7-1.5,4.5-4,5.1-6.3c0.6-2.3,0-4.4-1.5-5.3c-1.5-0.9-3.5-0.6-5.3,0.4c-1.8,1-3.5,2.7-4.6,5.2
							c-1.1,2.5-2,4.3-3,6c-1,1.7-2.2,3.4-4.4,5.4c2.8-1.1,4.7-1.7,6.8-2.4C454,130.8,456,130.1,458.8,128.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M475.9,123.1c2.3-1.3,3.6-3.6,3.8-5.6c0.2-2.1-0.6-3.8-2.1-4.7c-1.6-0.8-3.4-0.6-5,0.2c-1.6,0.8-2.9,2.3-3.5,4.4
							c-0.7,2.2-1.1,3.8-1.7,5.4c-0.6,1.6-1.4,3.1-3,5c2.3-1,3.9-1.6,5.6-2.2C471.8,125,473.6,124.4,475.9,123.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M493,119.2c2.2-1.6,3.5-4,3.7-6.1c0.2-2.1-0.5-4-2.1-4.8c-1.5-0.8-3.5-0.5-5.1,0.5c-1.7,1.1-3.1,2.9-3.7,5.3
							c-0.6,2.5-1,4.3-1.5,6.1c-0.5,1.8-1.2,3.6-2.5,5.8c2.2-1.6,3.8-2.5,5.5-3.5C488.9,121.7,490.7,120.8,493,119.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M512.9,117.7c2.4-2.2,3.5-5.3,3.4-7.8c-0.1-2.5-1.3-4.3-3-4.6c-1.7-0.4-3.5,0.6-4.9,2c-1.4,1.4-2.5,3.4-2.9,5.9
							c-0.5,2.6-1,4.5-1.6,6.3c-0.7,1.8-1.6,3.7-3.7,5.8c2.7-1.3,4.5-2.3,6.5-3.3C508.4,121,510.4,119.9,512.9,117.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M529.5,112.5c2.1-2.5,3.1-5.6,2.9-8.1c-0.1-2.5-1.2-4.4-2.9-4.8c-1.7-0.5-3.6,0.5-5,2.2c-1.5,1.7-2.6,4.1-3,7
							c-0.4,3-0.6,5.1-1,7.2c-0.4,2.1-1,4.3-2.4,7.1c2.3-2.2,4-3.7,5.7-5.2C525.6,116.6,527.3,115.1,529.5,112.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M541.5,108.7c2-2,2.9-4.7,2.9-7c-0.1-2.2-1.2-4-2.8-4.5c-1.7-0.5-3.5,0.2-5,1.5c-1.5,1.4-2.6,3.4-2.9,6
							c-0.3,2.6-0.5,4.5-0.8,6.4c-0.3,1.9-0.8,3.8-2,6.3c2.1-1.9,3.6-3.1,5.2-4.3C537.8,112,539.4,110.8,541.5,108.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M558.6,100c2.1-2.3,3.1-5.3,3-7.7c0-2.4-1.1-4.3-2.7-4.9c-1.7-0.5-3.6,0.3-5.1,1.9c-1.5,1.6-2.7,4-3.1,6.8
							c-0.4,2.9-0.6,5-0.9,7.1c-0.4,2.1-0.9,4.2-2.2,7c2.2-2.2,3.8-3.7,5.5-5.1C554.7,103.8,556.5,102.4,558.6,100z"
                      />
                    </g>
                    <g>
                      <path
                        d="M573,93.6c2.2-1.8,3.6-4.1,4.1-6.2c0.5-2.2,0.2-4.2-1.2-5.3c-1.4-1.1-3.5-0.9-5.5,0.3c-2,1.2-3.9,3.5-4.7,6.5
							c-0.8,3-1.2,5.2-1.5,7.3c-0.4,2.1-0.8,4.2-1.6,7.1c1.8-2.4,3.3-3.9,4.9-5.2C569,96.6,570.7,95.4,573,93.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M587.3,86.9c2.3-1.1,3.9-2.9,4.8-4.7c0.8-1.8,0.9-3.8-0.1-5.2c-1-1.4-3.1-1.9-5.2-1.2c-2.2,0.6-4.5,2.4-5.7,5.1
							c-1.3,2.7-1.9,4.7-2.5,6.7c-0.6,2-1.2,3.9-2.3,6.5c1.9-2,3.6-3.2,5.3-4.1C583.1,88.9,584.9,88.1,587.3,86.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M602.4,93.3c2.3,0.6,4.4,0.3,6.1-0.6c1.7-0.9,3-2.3,3.1-4.1c0.1-1.7-1-3.4-3.1-4.3c-2-0.9-4.8-1-7.4,0.3
							c-2.6,1.3-4.2,2.6-5.7,3.7c-1.5,1.2-3.1,2.4-5.3,3.9c2.6-0.5,4.5-0.5,6.3-0.2C598.3,92.3,600,92.7,602.4,93.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M602.3,107.3c2.1,1.2,4.2,1.5,6.1,1.3c1.9-0.3,3.6-1.1,4.4-2.7c0.8-1.6,0.2-3.6-1.6-5.2c-1.8-1.6-4.7-2.6-7.7-2.1
							c-3.1,0.5-5.1,1.4-7,2.2c-2,0.9-3.8,1.7-6.3,2.8c2.8-0.1,4.7,0.4,6.5,1C598.4,105.2,600.1,106.1,602.3,107.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M598.1,120.2c2.4,1.8,5,2.6,7.4,2.6c2.3,0,4.2-0.9,4.9-2.5c0.7-1.6-0.1-3.6-1.8-5.3c-1.7-1.6-4.4-2.9-7.5-3.1
							c-6.3-0.3-8.9,0.5-15.1-0.2C591.6,114.5,593.3,116.5,598.1,120.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M455.6,157.1c1.3,2.2,2.9,3.7,4.7,4.7c1.8,0.9,3.8,1.2,5.3,0.3c1.5-0.9,2.1-3,1.3-5.4c-0.7-2.4-2.8-5-5.7-6.3
							c-3-1.3-5.3-1.7-7.4-2.1c-2.2-0.4-4.3-0.7-7.2-1.1c2.6,1.4,4.1,2.9,5.4,4.4C453.2,153.1,454.3,154.8,455.6,157.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M472.5,157.1c1.1,2.4,2.4,4.3,3.9,5.8c1.5,1.5,3.1,2.7,4.9,2.5c0.9-0.1,1.6-0.6,2.2-1.6c0.6-0.9,0.9-2.2,0.8-3.8
							c-0.1-1.5-0.5-3.3-1.5-4.9c-0.9-1.6-2.3-3.2-4-4.3c-1.8-1.1-3.4-1.8-4.8-2.2c-1.5-0.4-2.8-0.7-4.1-0.8
							c-2.6-0.3-5.1-0.3-8.6,0.2c3.4,0.6,5.5,1.9,7.1,3.3C470,152.9,471.3,154.7,472.5,157.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M487.8,156.6c1.5,2.4,3.4,4.2,5.4,5.3c1.9,1.1,4,1.5,5.5,0.6c1.5-0.9,2-3.1,1.1-5.7c-0.9-2.6-3.1-5.4-6.4-6.9
							c-3.3-1.5-5.7-2.1-8.1-2.5c-2.4-0.5-4.7-0.9-8-1.4c3,1.5,4.7,3.1,6.1,4.7C484.9,152.4,486.2,154.1,487.8,156.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M506.5,159.3c2.5,2.3,5.7,3.4,8.3,3.4c2.6,0,4.6-1.1,5.1-2.7c0.5-1.7-0.5-3.6-2.2-5.1c-1.7-1.6-4.2-2.8-7.2-3.3
							c-3-0.5-5.2-0.9-7.4-1.4c-2.2-0.5-4.3-1.3-7.2-2.9c2.2,2.5,3.6,4.3,5.1,6.1C502.5,155.1,504,157,506.5,159.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M525.3,155.5c3.6,1.7,7.5,2.2,10.5,1.7c3-0.5,5.1-1.9,5.2-3.6c0.2-1.8-1.5-3.5-4.1-4.7c-2.6-1.2-6-2-9.7-2
							c-3.8,0.1-6.5,0.2-9.2,0.1c-2.7-0.1-5.5-0.3-9.4-1.3c3.5,1.9,5.9,3.4,8.3,4.9C519.2,152.2,521.6,153.7,525.3,155.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M549,150.1c6.4,2.5,13.2,0.2,13.4-3.3c0.2-3.5-6-6.6-12.5-5.4c-6.7,1.1-9.4,2.3-16.4,2.3
							C540.3,145.7,542.5,147.5,549,150.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M568.8,144.1c3.4,1.2,7.1,1.2,9.7,0.4c2.7-0.8,4.5-2.3,4.5-4.1c0-1.8-1.7-3.3-4.2-4.3c-2.5-1-5.8-1.4-9.2-0.8
							c-3.5,0.6-6,1.1-8.5,1.5c-2.5,0.4-5.1,0.6-8.8,0.4c3.5,1.2,5.9,2.3,8.2,3.4C562.9,141.7,565.2,142.8,568.8,144.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M580.9,135.9c1.6,1.6,3.5,2.2,5.3,2.2c1.8,0,3.5-0.8,4.4-2.3c0.9-1.5,0.7-3.5-0.5-5.1c-1.2-1.6-3.4-2.9-6.1-3
							c-2.7,0-4.6,0.3-6.4,0.5c-1.8,0.3-3.6,0.5-6,0.6c2.3,0.9,3.7,1.9,5,3C578.1,133,579.3,134.2,580.9,135.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M591.4,129.9c3.6,4.4,9.6,5,11.5,2c1.9-3-1.3-8.1-6.8-9.4c-5.7-1.4-8.2-1.2-13.6-3.3
							C586.8,123.1,587.7,125.4,591.4,129.9z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M191.4,346.1c2,10.5,5.5,20.9,10.2,29.9c4.7,9.1,10.8,16.7,16.8,22.3c6,5.6,11.9,9.2,16.3,11.1
							c4.4,1.9,7.2,2.3,7.5,1.5c0.9-1.7-8.1-7.1-17.8-18.4c-4.8-5.7-9.8-12.8-14-21.1c-4.2-8.3-7.8-17.6-10.5-27.4
							c-2.8-10-4.2-18.8-5.5-26.9c-1.3-8.1-2.4-15.4-3.8-22.7c-1.4-7.3-3.2-14.7-6-22.6c-1.4-4-3.2-8.1-5.4-12.3
							c-2.2-4.2-5.1-8.5-8.7-12.7c6.8,8.8,10.2,17.6,12.4,25.5c2.2,8,3.2,15.3,4,22.6c0.7,7.3,1,14.6,1.5,22.8
							C188.8,326.1,189.3,335.3,191.4,346.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M197,289.9c1.7,2.6,4.4,4.1,6.8,4.5c2.4,0.4,4.4-0.3,5.2-1.9c0.8-1.6,0.3-3.5-0.8-5.3c-1.2-1.7-3-3.3-5.5-4.1
							c-2.6-0.9-4.5-1.6-6.3-2.5c-1.8-0.9-3.6-1.9-5.7-3.9c1.3,2.7,2.1,4.6,3,6.6C194.4,285.3,195.3,287.3,197,289.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M204,306.5c1.5,2.2,3.9,3.3,5.9,3.4c2.1,0.1,3.8-0.9,4.5-2.5c0.7-1.6,0.3-3.5-0.6-4.9c-1-1.5-2.5-2.6-4.7-3.1
							c-2.2-0.5-3.9-0.8-5.6-1.3c-1.6-0.5-3.2-1.2-5.2-2.6c1.2,2.2,1.9,3.8,2.7,5.4C201.7,302.5,202.5,304.3,204,306.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M209.3,323.1c1.8,2.1,4.2,3.1,6.4,3.2c2.1,0.1,3.9-0.8,4.6-2.5c0.7-1.6,0.2-3.5-1-5.1c-1.2-1.6-3.1-2.8-5.6-3.2
							c-2.5-0.4-4.4-0.6-6.2-1c-1.8-0.4-3.6-0.9-6-2c1.7,2,2.8,3.6,3.9,5.2C206.4,319.3,207.5,321,209.3,323.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M212.5,342.8c2.4,2.2,5.6,3,8,2.7c2.5-0.3,4.1-1.7,4.3-3.4c0.2-1.8-0.9-3.4-2.4-4.7c-1.5-1.3-3.6-2.2-6.1-2.4
							c-2.6-0.3-4.5-0.6-6.4-1.1c-1.9-0.5-3.8-1.3-6.1-3.2c1.5,2.5,2.6,4.3,3.8,6.2C208.8,338.7,210.1,340.6,212.5,342.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M219.1,359c2.6,1.9,5.8,2.6,8.3,2.2c2.5-0.3,4.3-1.6,4.6-3.3c0.3-1.7-0.8-3.5-2.7-4.8c-1.8-1.3-4.4-2.3-7.3-2.4
							c-3-0.1-5.1-0.1-7.3-0.4c-2.2-0.2-4.3-0.6-7.3-1.8c2.4,2.1,4,3.6,5.6,5.2C214.7,355.4,216.3,357,219.1,359z"
                      />
                    </g>
                    <g>
                      <path
                        d="M223.9,370.7c2.2,1.9,5,2.5,7.2,2.2c2.2-0.3,3.9-1.5,4.3-3.2c0.4-1.7-0.5-3.5-2-4.8c-1.5-1.4-3.7-2.3-6.2-2.3
							c-2.6-0.1-4.6-0.1-6.5-0.2c-1.9-0.2-3.8-0.5-6.4-1.5c2,1.9,3.4,3.4,4.7,4.9C220.3,367.2,221.6,368.7,223.9,370.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M234,386.9c2.5,1.9,5.5,2.6,7.9,2.4c2.4-0.2,4.2-1.4,4.6-3.1c0.4-1.7-0.6-3.5-2.4-4.9c-1.8-1.4-4.2-2.4-7.1-2.5
							c-3-0.1-5.1-0.2-7.2-0.3c-2.1-0.2-4.2-0.5-7.2-1.6c2.4,2,4,3.5,5.6,5C229.9,383.4,231.4,385,234,386.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M241.6,400.7c2,2.1,4.3,3.2,6.5,3.5c2.2,0.3,4.2-0.2,5.2-1.7c1-1.5,0.6-3.6-0.8-5.5c-1.4-1.9-3.8-3.6-6.8-4.2
							c-3.1-0.6-5.3-0.7-7.4-0.9c-2.1-0.2-4.3-0.4-7.2-1c2.6,1.6,4.2,3,5.6,4.5C238.2,397,239.6,398.6,241.6,400.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M249.5,414.4c1.3,2.2,3.2,3.7,5.1,4.3c1.9,0.7,3.9,0.6,5.2-0.6c1.3-1.2,1.6-3.2,0.7-5.3c-0.8-2.1-2.8-4.2-5.5-5.3
							c-2.8-1.1-4.9-1.5-6.9-1.9c-2-0.4-3.9-0.9-6.6-1.7c2.2,1.8,3.5,3.3,4.6,4.9C247.1,410.4,248.1,412.1,249.5,414.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M244.4,430c-0.4,2.4,0.1,4.4,1.1,6c1,1.6,2.6,2.7,4.3,2.7c1.8,0,3.3-1.3,4-3.4c0.7-2.1,0.6-4.9-0.9-7.3
							c-1.5-2.5-2.9-4-4.2-5.4c-1.3-1.4-2.6-2.9-4.3-4.9c0.7,2.6,0.8,4.4,0.7,6.2C245.1,425.8,244.8,427.6,244.4,430z"
                      />
                    </g>
                    <g>
                      <path
                        d="M230.5,431.1c-1,2.2-1.2,4.3-0.7,6.2c0.4,1.9,1.4,3.5,3.1,4.2c1.6,0.6,3.6-0.1,5-2.1c1.4-1.9,2.2-4.9,1.4-7.9
							c-0.8-3-1.8-4.9-2.8-6.8c-1-1.9-2-3.7-3.3-6.1c0.3,2.8,0,4.7-0.4,6.6C232.2,427,231.5,428.8,230.5,431.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M217.3,428c-1.6,2.5-2.2,5.3-1.9,7.5c0.2,2.3,1.2,4.1,2.9,4.7c1.7,0.5,3.6-0.4,5.1-2.2c1.5-1.9,2.5-4.7,2.4-7.7
							c-0.3-6.3-1.2-8.8-1.1-15C222.4,421,220.5,422.8,217.3,428z"
                      />
                    </g>
                    <g>
                      <path
                        d="M168.4,289.1c-2.1,1.5-3.5,3.2-4.2,5.1c-0.7,1.9-0.9,3.9,0.2,5.3c1,1.4,3.2,1.8,5.5,0.9c2.3-0.9,4.7-3.2,5.8-6.3
							c1.1-3.2,1.3-5.4,1.5-7.6c0.2-2.2,0.3-4.3,0.4-7.3c-1.2,2.7-2.5,4.3-3.9,5.7C172.1,286.4,170.5,287.6,168.4,289.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M169.7,305.9c-2.3,1.3-4,2.7-5.5,4.3c-1.4,1.6-2.5,3.4-2.1,5.1c0.2,0.9,0.8,1.6,1.7,2c1,0.5,2.3,0.7,3.8,0.5
							c1.5-0.2,3.2-0.8,4.8-1.9c1.5-1.1,3-2.6,3.9-4.4c1-1.9,1.5-3.5,1.8-5c0.3-1.5,0.4-2.8,0.5-4.1c0-2.6-0.1-5.2-0.9-8.6
							c-0.3,3.5-1.4,5.6-2.7,7.4C173.7,303.1,172.1,304.6,169.7,305.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M171.5,321.1c-2.3,1.7-3.9,3.7-4.8,5.8c-0.9,2-1.2,4.1-0.1,5.5c1,1.4,3.3,1.7,5.8,0.6c2.5-1.1,5.1-3.6,6.3-6.9
							c1.2-3.4,1.6-5.9,1.8-8.3c0.3-2.4,0.5-4.7,0.7-8.1c-1.3,3.1-2.6,4.9-4.1,6.5C175.6,317.9,173.9,319.3,171.5,321.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M170.5,340.1c-2.1,2.7-2.9,6-2.6,8.5c0.2,2.6,1.4,4.5,3.2,4.8c1.7,0.3,3.5-0.8,4.9-2.6c1.4-1.9,2.4-4.4,2.7-7.4
							c0.3-3.1,0.4-5.3,0.8-7.5c0.4-2.2,0.9-4.4,2.3-7.4c-2.3,2.4-3.9,4-5.6,5.6C174.4,335.7,172.6,337.3,170.5,340.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M175.9,358.4c-1.4,3.7-1.5,7.7-0.8,10.6c0.7,3,2.3,4.9,4,4.9c1.8,0,3.3-1.8,4.3-4.5c1-2.7,1.5-6.2,1.1-9.9
							c-0.4-3.8-0.7-6.5-0.9-9.2c-0.2-2.7-0.2-5.5,0.4-9.4c-1.6,3.7-2.9,6.2-4.2,8.7C178.7,352.1,177.4,354.6,175.9,358.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M183.3,381.6c-1.9,6.6,0.9,13.2,4.4,13.1c3.5-0.1,6-6.5,4.4-12.9c-1.7-6.6-3.1-9.1-3.7-16.1
							C186.9,372.5,185.3,374.9,183.3,381.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M190.9,400.8c-0.9,3.5-0.6,7.1,0.4,9.7c1,2.6,2.7,4.3,4.5,4.1c1.8-0.1,3.2-2,3.9-4.5c0.8-2.6,0.9-5.9,0-9.3
							c-0.9-3.5-1.6-5.9-2.2-8.4c-0.6-2.5-1-5-1.2-8.7c-0.9,3.6-1.8,6-2.6,8.5C192.8,394.7,191.9,397.2,190.9,400.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M200.2,412.2c-1.4,1.7-1.9,3.7-1.8,5.5c0.2,1.8,1.1,3.4,2.7,4.2c1.6,0.8,3.5,0.4,5-1c1.5-1.3,2.6-3.7,2.5-6.3
							c-0.2-2.7-0.6-4.5-1.1-6.3c-0.4-1.8-0.8-3.5-1.1-6c-0.7,2.4-1.6,3.9-2.6,5.3C202.8,409.1,201.7,410.4,200.2,412.2z"
                      />
                    </g>
                    <g>
                      <path d="M207,422.2c-4,4-4.1,10-1,11.6c3.1,1.6,8-2,8.8-7.6c0.9-5.8,0.5-8.2,2.1-13.8C213.4,417,211.1,418.1,207,422.2z" />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M378.5,8.1c9.5-5,18.3-11.4,25.5-18.6c7.3-7.2,12.8-15.2,16.3-22.6c3.6-7.4,5.3-14.1,5.8-18.9c0.5-4.8,0-7.5-0.8-7.6
							c-1.9-0.3-4.3,9.8-12.3,22.5c-4,6.3-9.3,13.2-16,19.6c-6.6,6.5-14.6,12.7-23,18.1c-8.7,5.6-16.7,9.6-24,13.2
							c-7.3,3.6-14,6.8-20.6,10.4c-6.6,3.6-13.1,7.4-19.8,12.5c-3.4,2.5-6.8,5.4-10.1,8.8c-3.4,3.4-6.6,7.4-9.6,12
							c6.4-9.1,13.8-14.9,20.7-19.4c7-4.5,13.7-7.6,20.5-10.5c6.8-2.8,13.7-5.3,21.4-8.2C360.1,16.5,368.7,13.3,378.5,8.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M323.1,19.3c2-2.4,2.7-5.4,2.3-7.8c-0.3-2.4-1.6-4.1-3.3-4.4c-1.7-0.3-3.5,0.7-4.8,2.3c-1.3,1.6-2.2,3.9-2.3,6.5
							c-0.1,2.7-0.2,4.7-0.5,6.7c-0.3,2-0.8,4-2.1,6.6c2.2-2,3.7-3.4,5.4-4.8C319.4,23.2,321.1,21.8,323.1,19.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M336.9,7.8c1.6-2.1,2-4.7,1.5-6.6c-0.5-2-2-3.4-3.7-3.5c-1.8-0.2-3.4,0.7-4.5,2.1c-1.1,1.4-1.8,3.2-1.6,5.4
							c0.2,2.3,0.4,4,0.4,5.7c0,1.7-0.1,3.4-1,5.8c1.7-1.8,3-3,4.4-4.2C333.8,11.1,335.2,9.9,336.9,7.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M351.2-2.2c1.5-2.3,1.7-5,1.1-7c-0.6-2.1-2-3.5-3.7-3.7c-1.8-0.2-3.4,0.8-4.6,2.4c-1.1,1.6-1.8,3.8-1.4,6.3
							c0.4,2.6,0.7,4.4,0.9,6.3c0.2,1.9,0.3,3.7-0.1,6.4c1.4-2.3,2.6-3.8,3.8-5.3C348.4,1.6,349.7,0.1,351.2-2.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M369.1-11.1c1.4-2.9,1.2-6.2,0.2-8.4c-1-2.3-2.9-3.4-4.6-3.1c-1.7,0.3-3,1.8-3.8,3.7c-0.8,1.9-1,4.1-0.5,6.5
							c0.5,2.6,0.8,4.5,0.9,6.5c0,2-0.1,4-1.2,6.8c2-2.2,3.4-3.8,4.8-5.5C366.2-6.4,367.6-8.1,369.1-11.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M382.6-22.2c1-3.1,0.7-6.3-0.3-8.6c-1-2.3-2.8-3.6-4.5-3.4c-1.8,0.2-3.1,1.8-3.8,4c-0.7,2.1-0.9,4.8-0.1,7.6
							c0.8,2.9,1.4,5,1.8,7.1c0.4,2.1,0.7,4.3,0.4,7.5c1.3-2.9,2.3-4.9,3.3-6.9C380.4-16.9,381.5-19,382.6-22.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M392.3-30.2c1.1-2.7,1-5.5,0-7.5c-0.9-2-2.6-3.3-4.3-3.1c-1.8,0.1-3.2,1.5-4,3.3c-0.8,1.8-1.1,4.2-0.4,6.6
							c0.7,2.5,1.3,4.4,1.7,6.3c0.4,1.9,0.7,3.8,0.5,6.6c1.2-2.5,2.2-4.2,3.2-5.9C390-25.7,391.1-27.5,392.3-30.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M404.8-44.7c1.1-3,0.9-6-0.1-8.3c-1-2.2-2.6-3.6-4.4-3.5c-1.8,0.1-3.2,1.6-4,3.7c-0.8,2.1-1,4.7-0.3,7.5
							c0.8,2.9,1.4,4.9,1.8,7c0.4,2.1,0.8,4.2,0.6,7.3c1.2-2.9,2.1-4.8,3.1-6.8C402.6-39.7,403.7-41.7,404.8-44.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M415.7-56.1c1.4-2.5,1.8-5.1,1.4-7.3c-0.3-2.2-1.4-3.9-3.1-4.4c-1.7-0.5-3.6,0.4-5,2.3c-1.4,1.9-2.3,4.7-1.9,7.8
							c0.4,3.1,0.9,5.2,1.3,7.3c0.5,2.1,0.9,4.2,1.2,7.2c0.8-2.9,1.6-4.9,2.6-6.7C413.2-51.8,414.3-53.5,415.7-56.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M426.4-67.6c1.7-1.9,2.5-4.1,2.6-6.1c0.1-2-0.6-3.9-2.1-4.8c-1.5-0.9-3.5-0.6-5.3,0.9c-1.8,1.4-3.2,3.9-3.4,6.8
							c-0.2,3,0,5.1,0.2,7.1c0.2,2,0.3,4,0.3,6.9c1-2.6,2.1-4.3,3.3-5.8C423.3-64.2,424.7-65.6,426.4-67.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M442.9-67.4c2.4-0.3,4.2-1.4,5.4-2.9c1.2-1.4,1.9-3.2,1.3-4.9c-0.5-1.7-2.2-2.8-4.4-2.8c-2.2-0.1-4.8,0.9-6.7,3.1
							c-1.9,2.2-2.9,3.9-3.9,5.6c-1,1.7-2,3.3-3.4,5.6c2.2-1.5,4-2.1,5.7-2.6C438.6-66.8,440.4-67.1,442.9-67.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M448-54.4c2.4,0.3,4.5-0.2,6.2-1.1c1.7-1,3-2.4,3.1-4.2c0.1-1.7-1.2-3.4-3.4-4.2c-2.2-0.8-5.3-0.6-7.9,1
							c-2.7,1.7-4.2,3.2-5.7,4.6c-1.5,1.6-2.9,3-4.8,5c2.6-1.1,4.5-1.4,6.4-1.5C443.6-54.9,445.5-54.7,448-54.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M449-40.9c2.9,0.8,5.7,0.5,7.8-0.4c2.1-0.9,3.6-2.4,3.6-4.2c0-1.8-1.4-3.3-3.6-4.2c-2.2-0.9-5.2-1-8.1,0
							c-6,2.1-8,3.8-14,5.5C440.8-43.7,443.1-42.4,449-40.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M330.8,46.9c2,1.6,4.1,2.4,6.1,2.5c2,0.2,3.9-0.3,5-1.7c1-1.4,0.8-3.5-0.8-5.5c-1.5-1.9-4.4-3.6-7.7-3.6
							c-3.3-0.1-5.5,0.4-7.7,0.8c-2.1,0.5-4.2,1-7.1,1.7c3,0.3,4.9,1.1,6.6,2C327.1,44.2,328.7,45.3,330.8,46.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M346.5,40.6c1.9,1.8,3.8,3.1,5.8,3.9c1.9,0.8,3.9,1.4,5.5,0.5c0.8-0.4,1.3-1.2,1.4-2.3c0.2-1.1,0-2.4-0.7-3.8
							c-0.6-1.4-1.7-2.8-3.2-4c-1.5-1.2-3.3-2.1-5.3-2.4c-2.1-0.4-3.8-0.4-5.3-0.3c-1.5,0.1-2.8,0.4-4.1,0.8
							c-2.5,0.7-4.9,1.6-7.9,3.4c3.4-0.8,5.8-0.3,7.9,0.4C342.6,37.7,344.5,38.8,346.5,40.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M360.5,34.4c2.3,1.7,4.7,2.6,6.9,2.9c2.2,0.3,4.3-0.1,5.3-1.5c1-1.4,0.7-3.6-1.1-5.7c-1.8-2.1-4.9-3.8-8.5-4
							c-3.6-0.2-6.1,0.3-8.5,0.7c-2.4,0.5-4.7,1-7.9,1.7c3.3,0.3,5.5,1.1,7.4,2C356.2,31.5,358.1,32.7,360.5,34.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M378.8,29.8c3.2,1.2,6.5,1,8.9,0c2.4-1,3.8-2.7,3.7-4.4c-0.2-1.8-1.8-3.1-4-3.9c-2.2-0.8-5-1-7.9-0.4
							c-3,0.7-5.2,1.2-7.4,1.5c-2.2,0.3-4.5,0.4-7.8,0c3,1.5,5,2.6,7,3.7C373.5,27.4,375.6,28.6,378.8,29.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M394.8,19.2c4,0.3,7.8-0.8,10.4-2.4c2.6-1.6,4-3.6,3.5-5.3c-0.5-1.7-2.7-2.6-5.6-2.8c-2.9-0.2-6.4,0.4-9.8,1.9
							c-3.5,1.5-6,2.6-8.5,3.6c-2.5,1-5.2,1.8-9.2,2.4c4,0.5,6.7,0.9,9.5,1.4C387.9,18.4,390.7,19,394.8,19.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M414.8,5.3c6.9-0.1,12.4-4.8,11.2-8.1c-1.2-3.3-8-3.8-13.6-0.3c-5.8,3.6-7.8,5.6-14.3,8.3
							C405,4.5,407.7,5.3,414.8,5.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M430.8-7.7c3.7-0.2,7-1.5,9.2-3.3c2.2-1.7,3.3-3.8,2.6-5.5c-0.7-1.6-2.8-2.4-5.5-2.4c-2.7,0-5.9,0.9-8.8,2.7
							c-3,1.9-5.1,3.3-7.3,4.6c-2.2,1.3-4.5,2.5-8,3.7c3.7-0.2,6.3-0.1,8.9,0C424.5-7.7,427.1-7.5,430.8-7.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M439-19.9c2.1,0.9,4.1,0.8,5.8,0c1.6-0.7,3-2.1,3.3-3.8c0.3-1.7-0.7-3.5-2.4-4.5c-1.7-1.1-4.3-1.4-6.7-0.5
							c-2.5,1-4.1,2-5.7,2.9c-1.6,0.9-3.1,1.8-5.4,2.9c2.5,0,4.2,0.4,5.8,0.9C435.2-21.5,436.8-20.8,439-19.9z"
                      />
                    </g>
                    <g>
                      <path d="M446.5-29.3c5,2.7,10.8,1,11.4-2.5c0.6-3.5-4.3-7-9.9-6.2c-5.8,0.9-8,2-13.8,2C439.6-33.9,441.3-32.1,446.5-29.3z" />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M533.7,299.2c0.6-0.6,1.1-1.2,1.6-1.8c0.5-0.7,1-1.3,1.5-2c0.9-1.4,1.8-2.8,2.4-4.3c1.4-3,2.2-6.2,2.6-9.4
						c0.8-6.4-0.3-12.8-2.8-18.6c-1.2-2.9-3-5.7-5-8.1c-2-2.4-4.2-4.5-6.6-6.4c-4.8-3.6-10.2-6.1-15.6-7.6c-2.7-0.7-5.5-1.3-8.2-1.5
						c-2.7-0.3-5.5-0.3-8.1-0.2c-5.4,0.3-10.8,1.5-15.7,3.8c-5,2.3-9.4,5.8-12.6,10c-1.6,2.1-2.9,4.5-3.9,6.9
						c-0.9,2.4-1.5,5.1-1.6,7.7c-0.3,5.1,1,10,3.5,14.1c1.3,2,2.9,3.8,4.7,5.2c1.8,1.4,3.8,2.4,5.8,3.1c4,1.3,8,1.3,11.5,0.3
						c3.5-1,6.4-3.3,8-5.7c1.7-2.4,2.4-4.9,2.6-7c0.2-2.1-0.1-4-0.6-5.3c-0.5-1.4-1.2-2.3-1.7-2.8c-0.6-0.5-1-0.6-1.3-0.5
						c-0.6,0.3-0.4,1.7-0.7,3.8c-0.1,1-0.4,2.2-1.1,3.5c-0.6,1.2-1.5,2.6-2.7,3.6c-0.6,0.5-1.3,0.9-2,1.3c-0.7,0.3-1.6,0.5-2.5,0.6
						c-1.8,0.2-3.8-0.2-5.7-1.2c-0.9-0.5-1.8-1.1-2.5-1.9c-0.7-0.7-1.3-1.6-1.8-2.7c-1-2.1-1.4-4.7-1-7.3c0.2-1.3,0.6-2.5,1.2-3.8
						c0.6-1.2,1.4-2.4,2.4-3.6c1-1.1,2.1-2.2,3.4-3.1c1.3-0.9,2.7-1.7,4.3-2.3c6.2-2.5,14.7-2.8,22.4-0.6c3.8,1.1,7.5,2.8,10.6,5.1
						c1.5,1.2,3,2.5,4.2,3.9c0.6,0.7,1.1,1.5,1.7,2.2l0.7,1.2l0.6,1.3c1.6,3.4,2.4,7.4,2.2,11.1c-0.1,1.9-0.5,3.7-1.2,5.4
						c-0.3,0.9-0.7,1.6-1.2,2.4l-0.7,1.1c-0.3,0.4-0.5,0.7-0.9,1.1c-2.5,3-6.1,5.2-10.1,6.9c-2,0.9-4.1,1.6-6.1,2.2
						c-1.1,0.3-2.1,0.6-3.2,0.9c-1.1,0.3-2.1,0.6-3.1,0.8c-8.4,1.9-16.5,2.6-24.2,2.5c-7.7-0.1-15.1-1.1-22.2-2.7
						c-7.1-1.6-13.8-3.8-20.3-6.6c-13-5.7-25.3-13.9-36.8-25.5c-5.7-5.8-11.3-12.5-16.4-20.3c-5.2-7.7-9.9-16.7-13.9-26.8
						c3.7,10.3,8,19.4,12.8,27.5c4.8,8.1,10,15.2,15.6,21.4c11.1,12.5,23,21.8,36.3,28.8c6.6,3.5,13.5,6.4,20.9,8.7
						c7.4,2.3,15.2,3.9,23.5,4.7c8.4,0.8,17.3,0.7,26.8-0.7c1.2-0.1,2.4-0.4,3.6-0.6c1.2-0.2,2.4-0.5,3.6-0.7c2.5-0.6,5-1.2,7.6-2.1
						c2.6-0.9,5.2-2,7.8-3.5C528.7,303.5,531.3,301.6,533.7,299.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M454.1,257.7c3.8-5.9,5.1-13.2,3.9-20.1c-0.6-3.4-1.9-6.8-3.7-9.7c-1.8-2.9-4.1-5.5-6.7-7.6c-2.6-2.1-5.5-3.7-8.6-4.7
						c-3.1-1-6.3-1.5-9.5-1.3c-3.2,0.2-6.3,0.9-9.1,2.4c-0.7,0.3-1.4,0.8-2,1.2c-0.7,0.5-1.3,0.9-1.9,1.4c-1.2,1-2.2,2.1-3.1,3.3
						c-1.7,2.4-2.8,5-3.3,7.6c-0.5,2.6-0.3,5.3,0.4,7.7c0.8,2.5,2.1,4.4,3.6,5.7c1.5,1.4,3,2.3,4.5,2.9c1.5,0.6,2.9,0.8,4.1,0.8
						c0.6,0,1.2-0.1,1.7-0.2c0.5-0.1,0.9-0.3,1.3-0.4c0.8-0.4,1.3-0.8,1.5-1.2c0.3-0.4,0.4-0.7,0.3-1.1c-0.2-0.7-1-1-2-1.6
						c-1-0.6-2.2-1.3-3.3-2.6c-1.1-1.2-1.9-3.1-1.8-4.5c0.1-0.9,0.2-1.7,0.7-2.6c0.4-0.9,1.1-1.8,1.9-2.6c0.4-0.4,0.9-0.7,1.3-1
						c0.2-0.1,0.5-0.2,0.7-0.4c0.3-0.1,0.5-0.2,0.8-0.3c1.1-0.4,2.4-0.5,3.9-0.5c2.9,0.1,5.9,1.3,8.6,3.4c1.3,1.1,2.5,2.4,3.4,3.8
						c0.9,1.5,1.6,3,2,4.7c0.8,3.4,0.5,7.4-1.1,10.7c-1.6,3.5-4.6,6.3-7.9,8.3c-3.4,1.9-7,3-10.6,3.3c-3.7,0.4-7.3,0.1-10.9-0.6
						c-3.5-0.7-7-1.8-10.4-3.2c-3.4-1.4-6.6-3.1-9.9-5.2c-3.2-2.1-6.3-4.6-9.4-7.5c-3-2.9-6-6.3-8.7-10.3c-2.7-4-5.2-8.6-7.1-14
						c3.2,11,8.3,19.4,13.9,26.1c2.8,3.4,5.7,6.3,8.8,9c3.1,2.6,6.3,4.9,9.7,7c3.4,2.1,7,3.8,11,5.2c3.9,1.4,8.2,2.4,13,2.7
						c4.7,0.3,10-0.4,15.2-2.5c2.6-1,5.2-2.5,7.8-4.4C449.8,263.2,452.2,260.8,454.1,257.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M336.8,288.9c-1.6,6.2-2,12.8-1.5,18.8c0.5,6,2,11.6,3.9,16c1.9,4.5,4.2,7.8,6.2,9.9c2.1,2.1,3.9,2.9,5,2.3
						c1.2-0.6,1.7-2.5,1.6-5.1c0.1-2.7-0.4-6.2-0.8-10.4c-0.4-4.1-0.9-8.8-1.2-13.8c-0.3-5-0.4-10.4-0.3-15.8
						c0.1-5.6,0.8-10.4,1.7-14.7c0.9-4.3,2.2-8.3,3.4-12.5c1.2-4.1,2.4-8.3,3.2-13.2c0.4-2.4,0.6-5,0.6-7.8c0-2.8-0.4-5.7-1.3-8.7
						c0.5,3.1,0.5,6,0.1,8.6c-0.4,2.6-1.1,5-1.9,7.2c-1.6,4.4-3.7,8-5.9,11.6c-2.2,3.6-4.5,7.2-6.8,11.5
						C340.7,277.4,338.5,282.5,336.8,288.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M350.5,287.2c-2,4.9-4.1,10-5.6,15.3c-1.5,5.3-2.2,10.7-1.9,15.5c0.3,4.8,1.5,8.7,3,11.2c1.5,2.5,3.2,3.5,4.5,3.1
						c1.3-0.4,2-2,2.7-4.3c0.7-2.3,1.4-5.3,2.3-8.9c0.9-3.6,2.2-7.7,3.7-12.4c1.4-4.7,3-10.1,4-15.9c0.9-6,1.2-11.1,1.2-15.7
						c0-4.6-0.4-8.7-0.9-12.8c-0.5-4.1-1.2-8.1-2.1-12.5c-1-4.4-2.2-9.3-4.1-14.9c1.1,5.8,1.4,10.8,1.4,15.2c0,4.4-0.4,8.4-0.9,12.2
						c-0.6,3.8-1.4,7.6-2.5,11.6C354.1,277.9,352.6,282.2,350.5,287.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M393.3,330.8c-0.5,0.7-1,1.3-1.5,1.9c-0.5,0.6-1.1,1.1-1.6,1.6c-1.2,0.9-2.4,1.7-3.7,2.3c-1.3,0.6-2.5,0.8-3.7,0.9
						c-1.2,0.1-2.1,0-3.2-0.4c-2.1-0.6-3.9-2.2-4.8-3.6c-0.4-0.7-0.7-1.4-0.8-2.3c-0.1-0.9,0-1.8,0.2-2.8c0.5-1.9,1.6-3.7,2.7-4.9
						c1.2-1.2,2-1.6,2.4-2c0.3-0.1,0.8-0.1,1.2,0c0.4,0.1,0.8,0.3,1.1,0.5c0.7,0.4,1.1,1.1,1.7,1.4c0.3,0.1,0.7,0.1,1.1-0.1
						c0.4-0.3,0.8-0.8,1.1-1.6c0.2-0.8,0.2-2-0.2-3.1c-0.4-1.2-1.2-2.4-2.6-3.5c-1.6-1.1-3.4-1.5-5.4-1.5c-1.9,0-3.8,0.5-5.6,1.3
						c-3.5,1.6-6.7,4.5-9,8.7c-1.1,2.1-1.9,4.6-2.1,7.3c-0.2,2.7,0.2,5.8,1.4,8.5c1.1,2.8,2.9,5.2,5.1,7.2c2.2,2,4.8,3.6,7.7,4.6
						c2.9,1,6.3,1.4,9.3,1.1c3.1-0.3,6.1-1.2,8.7-2.6c2.7-1.4,5-3.2,7.1-5.3c1-1.1,1.9-2.2,2.8-3.4c0.8-1.2,1.5-2.4,2.1-3.7
						c2.5-5.3,3.2-10.4,3.2-14.9c0-4.6-0.8-8.7-1.8-12.3c-1-3.7-2.3-6.9-3.6-10c-1.3-3.1-2.7-6-4.2-8.9c-1.5-2.9-3-5.7-4.8-8.6
						c-1.7-2.9-3.5-5.8-5.6-8.7c-4.1-6-8.9-12.3-15.4-19.3c5.9,7.4,9.9,14.3,13.1,20.6c1.6,3.2,3,6.2,4.2,9.2c1.2,3,2.2,6,3.2,8.9
						c0.9,3,1.7,6,2.4,9c0.7,3.1,1.3,6.2,1.6,9.4c0.3,3.2,0.3,6.5-0.3,9.8C396.4,324.7,395.3,328,393.3,330.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M211.1,46.3c3.4-0.8,6.9-1,10.2-0.6c3.3,0.4,6.4,1.5,8.9,3.1c2.6,1.6,4.7,3.9,6.2,6.4c0.7,1.2,1.3,2.5,1.7,3.8
						c0.4,1.3,0.5,2.6,0.6,3.9c0.1,5.3-3,11-7.3,15.2c-1.1,1.1-2.2,2.1-3.4,2.9c-1.2,0.9-2.4,1.7-3.5,2.4c-2.4,1.4-4.7,2.1-6.6,2.3
						c-1,0.1-1.9,0-2.6-0.2c-0.8-0.2-1.6-0.5-2.3-1c-1.5-1-2.7-2.4-3.5-3.9c-0.4-0.8-0.6-1.5-0.8-2.3c-0.1-0.4-0.1-0.8-0.2-1.1
						c0-0.4,0-0.7,0-1.1c0.1-1.5,0.6-3,1.3-4.2c1.4-2.5,3.4-4.2,4.9-5.2c1.5-1,2.5-1.6,2.5-2.3c0-0.6-1.2-1.4-3.6-1.3
						c-1.2,0-2.7,0.3-4.3,0.9c-1.6,0.7-3.4,1.7-5.1,3.3c-1.7,1.6-3.3,3.8-4.3,6.8c-0.3,0.7-0.4,1.5-0.6,2.3
						c-0.1,0.8-0.2,1.6-0.2,2.5c-0.1,1.7,0.2,3.4,0.6,5.2c0.9,3.5,2.9,6.8,5.9,9.6c1.5,1.4,3.4,2.6,5.5,3.4c2.2,0.9,4.4,1.3,6.6,1.4
						c4.5,0.2,8.9-1,12.7-2.8c1.9-0.9,3.7-1.9,5.5-3.1c1.8-1.2,3.5-2.5,5.1-4c1.6-1.4,3.2-3.1,4.6-4.9c1.4-1.8,2.7-3.7,3.9-5.8
						c1.1-2.1,2.1-4.4,2.8-6.9c0.7-2.5,1.1-5.1,1.1-7.8c0-2.7-0.3-5.5-1.1-8.1c-0.8-2.6-1.9-5.1-3.3-7.4c-2.8-4.6-6.7-8.4-11.4-11.2
						c-4.7-2.8-9.9-4.2-15.1-4.6c-5.2-0.4-10.3,0.3-15.2,1.9c-5,1.6-9.3,4-13,6.7c-3.7,2.7-6.9,5.8-9.6,9
						c-5.3,6.4-9.1,13.2-11.6,19.9c-2.5,6.7-3.9,13.4-4.6,19.9c-0.7,6.5-0.7,12.8-0.1,19.1c0.6,6.3,1.8,12.4,3.6,18.6
						c1.8,6.2,4.1,12.4,6.9,18.7c5.7,12.7,13.8,25.8,24.4,40.6c-10-15.2-17.2-28.8-22-41.6c-2.4-6.4-4.2-12.6-5.4-18.7
						c-1.2-6.1-1.8-12-1.9-18c0-5.9,0.5-11.8,1.7-17.7c1.2-5.8,3-11.7,5.8-17.4c2.8-5.6,6.5-11.1,11.4-16c2.4-2.4,5.2-4.6,8.2-6.5
						C204.3,48.6,207.6,47.1,211.1,46.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M217.7,111.6c1.7-0.5,3.2-0.7,4.8-0.7c1.5,0,2.9,0.2,4,0.6c0.5,0.2,1,0.4,1.3,0.7c0.4,0.2,0.7,0.5,0.9,0.8
						c0.5,0.6,1,1.5,1.3,2.5c0.2,0.5,0.3,1.1,0.3,1.7c0.1,0.6,0.1,1.1,0,1.7c-0.1,1.1-0.4,2.2-0.7,3c-0.2,0.4-0.4,0.8-0.6,1.1
						c-0.2,0.3-0.4,0.6-0.7,0.8c-0.3,0.2-0.4,0.4-0.7,0.6c-0.3,0.2-0.6,0.3-1,0.4c-0.7,0.2-1.5,0.2-2.3,0.1c-0.7-0.1-1.4-0.3-2-0.7
						c-1.2-0.6-2.2-2-2.8-3.3c-0.6-1.3-0.9-2.6-1.1-3.6c-0.2-1-0.5-1.8-1-2.2c-0.5-0.3-1.4-0.2-2.5,0.7c-1,0.9-2.2,2.7-2.6,5.4
						c-0.2,1.3-0.2,2.9,0.1,4.5c0.3,1.7,1,3.5,2.3,5.2c2.5,3.5,7,6.2,12.4,6.3c1.3,0,2.7-0.1,4.2-0.4c1.4-0.3,2.9-0.9,4.2-1.7
						c1.3-0.8,2.5-1.7,3.6-2.8c1.1-1.1,2-2.3,2.8-3.5c1.5-2.5,2.5-5.3,3-8.3c0.2-1.5,0.3-3,0.2-4.5c-0.1-1.5-0.3-3-0.7-4.5
						c-0.8-3-2.3-6.1-4.8-8.7c-2.5-2.6-5.8-4.5-9-5.3c-3.2-0.9-6.4-1-9.4-0.6c-1.5,0.2-2.9,0.5-4.3,0.9c-1.4,0.4-2.7,1-4,1.5
						c-5.3,2.4-9.2,5.9-12.2,9.4c-1.5,1.8-2.8,3.6-3.9,5.4c-1.1,1.9-2,3.7-2.8,5.5c-3,7.5-3.6,14.5-3.3,21.3
						c0.3,6.8,1.7,13.4,4,20.4c2.2,7,5.4,14.5,9.3,23.3c-3.1-9.1-5.5-16.9-6.7-24c-1.3-7.1-1.6-13.5-0.8-19.6
						c0.7-6.1,2.5-12,5.9-17.3c0.8-1.3,1.8-2.6,2.9-3.9c1.1-1.2,2.3-2.4,3.5-3.5C211.2,114.4,214.2,112.5,217.7,111.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M252.1,32.8c3.2,0.6,6.4-0.3,8.5-1.8c2.1-1.5,3-3.4,2.5-5.1c-0.6-1.7-2.4-2.7-4.6-3c-2.2-0.3-4.8,0-7.4,1.2
						c-2.7,1.2-4.6,2.1-6.6,2.8c-2,0.7-4.2,1.2-7.3,1.3c3,1,5.1,1.7,7.3,2.5C246.6,31.4,248.8,32.2,252.1,32.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M267.3,45.6c1.8,2,3.7,3.3,5.6,4.1c1.9,0.8,4,1.1,5.5,0.2c1.5-0.9,1.9-3.2,0.7-5.8c-1.2-2.6-4-5.2-7.6-6.1
						c-3.7-0.9-6.2-0.8-8.7-0.6c-2.4,0.2-4.8,0.6-8,1.4c3.3,0.3,5.4,1.2,7.2,2.3C263.8,42.2,265.4,43.6,267.3,45.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M270,60.5c0.6,2.6,1.5,4.6,2.7,6.3c1.2,1.6,2.7,3,4.5,3.1c0.9,0,1.7-0.4,2.4-1.2c0.3-0.4,0.7-0.9,0.9-1.5
						c0.2-0.6,0.4-1.3,0.5-2c0.4-2.9-0.8-6.8-3.6-9.5c-2.9-2.7-5.2-3.8-7.5-4.7c-2.3-0.9-4.7-1.6-7.9-2.2c3,1.6,4.4,3.3,5.6,5.1
						C268.6,55.8,269.4,57.8,270,60.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M263.1,73.6c-0.7,2.7-0.6,5.1,0.1,7.2c0.7,2,1.9,3.7,3.7,4c1.7,0.3,3.5-0.9,4.7-3.2c1.1-2.3,1.5-5.6,0.3-8.6
						c-1.2-3.2-2.4-5.1-3.5-7.1c-1.2-1.9-2.3-3.8-3.9-6.5c0.6,3.1,0.5,5.2,0.2,7.3C264.3,68.8,263.9,70.8,263.1,73.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M251.6,86.6c-1.8,2-2.8,4.1-3.2,6.2c-0.4,2.1-0.1,4.1,1.2,5.3c1.3,1.2,3.5,1,5.7-0.5c2.1-1.5,4-4.4,4.4-7.7
						c0.4-3.4,0.1-5.8-0.2-8c-0.3-2.3-0.7-4.5-1.2-7.5c-0.5,3.1-1.4,5-2.5,6.8C254.7,82.9,253.5,84.5,251.6,86.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M241.2,20.8c6-2.2,9.5-7.9,7.4-10.8c-2.1-2.9-8.6-1.4-12.5,3.7c-2,2.6-3.3,4.5-4.6,6.4c-0.7,0.9-1.3,1.9-2.1,2.7
						c-0.4,0.4-0.9,0.8-1.5,1.1c-0.6,0.3-1.4,0.4-2.1,0.2c0.7,0.5,1.5,0.7,2.3,0.6c0.8,0,1.5-0.2,2.2-0.4c0.7-0.2,1.3-0.4,1.9-0.6
						c0.6-0.2,1.1-0.4,1.7-0.6C235.8,22.5,238.1,21.9,241.2,20.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M227.4,15.6c2.1-2.5,2.5-5.9,1.9-8.2c-0.7-2.4-2.4-3.8-4.1-3.6c-1.8,0.1-3.2,1.4-4.2,2.9c-1,1.5-1.6,3.3-1.6,5.5
						c0,2.4-0.2,4.2-0.7,5.9c-0.5,1.8-1.3,3.6-3.2,5.6c2.6-1.2,4.4-2.1,6.2-3.2C223.5,19.4,225.3,18.1,227.4,15.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M216.5,14.7c1.1-3.1,0.5-6.4-0.9-8.5c-1.4-2.1-3.4-2.9-5-2.3c-1.7,0.6-2.6,2.3-3.1,4.1c-0.5,1.8-0.5,3.9,0.3,6.1
						c0.8,2.4,1.2,4.2,1.4,6.1c0.2,1.9,0.1,3.9-1,6.6c1.9-2.1,3.3-3.8,4.6-5.5C214.1,19.6,215.4,17.8,216.5,14.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M204,15.5c-0.4-2.8-2.4-5-4.4-5.8c-2.1-0.8-4-0.4-5.1,1c-1.1,1.4-1.2,3.2-0.9,4.8c0.3,1.6,1.1,2.9,2.7,4.1
						c1.7,1.2,2.9,2.3,3.9,3.5c1,1.2,1.9,2.7,2.4,5.1c0.6-2.4,0.9-4.1,1.3-5.9C204.2,20.4,204.4,18.4,204,15.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M189.6,18.1c-1.2-0.9-2.6-1.3-4-1.3c-1.4,0-2.6,0.3-3.6,1c-1,0.6-1.6,1.4-1.9,2.3c-0.3,0.9-0.2,1.7,0.1,2.5
						c0.7,1.6,2.1,2.7,3.1,3.4c1.1,0.7,2,0.9,3.4,0.4c1.5-0.5,2.9-0.5,4.3-0.3c1.4,0.3,2.8,1,4.1,2.7c-0.3-2.2-0.8-3.8-1.5-5.5
						C193,21.7,192,19.9,189.6,18.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M174.9,28.6c-3.1,0-5.9,1.8-7.2,3.8c-1.3,2-1.2,4.2,0,5.4c1.2,1.3,3.1,1.6,4.8,1.4c1.8-0.2,3.4-0.8,5.1-2.3
						c1.7-1.5,3.2-2.6,4.8-3.4c1.6-0.8,3.4-1.4,6.1-1.4c-2.5-1.1-4.3-1.8-6.3-2.4C180.2,29.1,178.1,28.5,174.9,28.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M168.5,41.5c-2.5,0.6-4.3,2.4-4.8,4.3c-0.6,1.9-0.1,3.7,1.3,4.8c1.4,1.1,3.2,1.3,4.7,1c1.5-0.3,2.8-1.1,3.7-2.8
						c1-1.7,1.7-3,2.7-4.3c0.9-1.2,2.1-2.3,4.1-3.3c-2.2,0-3.8-0.1-5.6-0.2C172.8,41,171,40.9,168.5,41.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M158.7,62.1c-2.2,1.8-3.3,4.4-3.4,6.6c-0.1,2.2,0.9,4,2.5,4.7c1.6,0.7,3.5,0.1,5.1-1.1c1.6-1.2,2.8-3.1,3.3-5.6
						c0.5-2.6,0.8-4.4,1.3-6.3c0.5-1.8,1.1-3.6,2.6-6c-2.2,1.6-3.9,2.7-5.6,3.7C162.7,59.2,161,60.2,158.7,62.1z"
                    />
                  </g>
                  <g>
                    <path d="M155.7,90.1c-2.8,3.4-1.4,8,2,9c3.4,1,7.1-2.2,6.5-6.5c-0.6-4.5-1.6-6.4-1.4-10.8C160.4,85.5,158.6,86.6,155.7,90.1z" />
                  </g>
                  <g>
                    <path
                      d="M157,113.8c-1.1,1.8-0.9,3.7-0.1,5.2c0.8,1.4,2.4,2.4,4.1,2.4c1.8,0,3.3-0.9,4.2-2.1c0.9-1.3,1.2-2.9,0.5-4.7
						c-1.5-3.7-2.8-5.3-3.2-9.2C160.8,108.9,159.2,110.1,157,113.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M191.5,102.9c2.1-4.3,3.5-8.8,3.9-12.4c0.5-3.6,0.1-6.5-1.5-7.2c-1.6-0.7-4.1,0.9-6.3,4.1c-2.2,3.2-4.1,8-4.6,13.1
						c-0.5,5.3-0.4,8.9-0.3,12.5c0.1,3.6,0.2,7.2,0.2,12.4c1-5.1,2.2-8.5,3.5-11.8C187.9,110.5,189.4,107.4,191.5,102.9z"
                    />
                  </g>
                  <g>
                    <g>
                      <path
                        d="M6.8,333.4c-3-1.7-5.7-4-7.9-6.6c-2.1-2.6-3.6-5.5-4.4-8.4c-0.8-2.9-0.8-6.1-0.2-8.9c0.3-1.4,0.8-2.7,1.4-3.9
							c0.6-1.2,1.4-2.2,2.3-3.2c3.6-3.9,9.7-6,15.7-6.1c1.5-0.1,3,0,4.5,0.1c1.5,0.1,2.9,0.4,4.2,0.7c2.7,0.6,4.8,1.6,6.3,2.8
							c0.8,0.6,1.4,1.3,1.8,1.9c0.5,0.7,0.8,1.5,1,2.3c0.4,1.7,0.4,3.6-0.2,5.2c-0.2,0.8-0.6,1.6-1,2.2c-0.2,0.4-0.4,0.6-0.7,0.9
							c-0.3,0.3-0.5,0.6-0.8,0.8c-1.1,1-2.5,1.7-3.9,2.2c-2.8,0.9-5.4,0.7-7.1,0.5c-1.8-0.3-3-0.5-3.4,0c-0.4,0.5-0.1,1.8,1.7,3.4
							c0.9,0.8,2.1,1.6,3.8,2.3c1.6,0.6,3.7,1.1,6,1.1c2.3,0,5.1-0.5,7.7-2c0.7-0.3,1.3-0.8,2-1.3c0.6-0.5,1.3-1,1.9-1.6
							c1.2-1.2,2.2-2.6,3.1-4.2c1.7-3.1,2.6-6.9,2.2-11c-0.2-2-0.7-4.2-1.7-6.3c-1-2.1-2.3-4-3.9-5.6c-3.2-3.2-7.2-5.3-11.2-6.6
							c-2-0.7-4.1-1.2-6.1-1.5c-2.1-0.4-4.2-0.5-6.4-0.6c-2.2-0.1-4.4,0.1-6.7,0.4c-2.3,0.3-4.6,0.9-6.8,1.6
							c-2.3,0.8-4.6,1.8-6.8,3.1c-2.2,1.4-4.3,3-6.1,4.9c-1.8,2-3.5,4.2-4.7,6.7c-1.2,2.4-2.1,5-2.7,7.6c-1.1,5.3-0.8,10.7,0.7,15.9
							c1.5,5.2,4.4,9.9,7.9,13.7c3.5,3.8,7.8,6.8,12.3,9c4.7,2.2,9.5,3.4,14.1,4c4.6,0.6,9,0.5,13.1,0c8.3-1,15.6-3.4,22.1-6.6
							c6.4-3.2,12-7.1,17-11.4c4.9-4.3,9.2-8.9,13.1-13.9c3.8-5,7.2-10.3,10.1-16c2.9-5.7,5.5-11.8,7.7-18.4
							c4.5-13.2,7.6-28.3,9.9-46.4c-3.1,18-7.1,32.8-12.3,45.4c-2.6,6.3-5.6,12.1-8.8,17.3c-3.3,5.3-6.9,10-10.9,14.4
							c-4,4.3-8.4,8.3-13.3,11.8c-4.9,3.5-10.2,6.5-16.1,8.7c-5.9,2.2-12.4,3.7-19.2,3.9c-3.4,0.1-6.9-0.1-10.4-0.9
							C13.3,336.4,9.9,335.2,6.8,333.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M46.6,281.2c-1.6-0.8-2.8-1.7-4-2.7c-1.1-1-2-2.1-2.5-3.1c-0.3-0.5-0.4-1-0.5-1.4c-0.1-0.4-0.1-0.8-0.1-1.2
							c0-0.8,0.3-1.7,0.8-2.7c0.3-0.5,0.6-1,0.9-1.5c0.4-0.5,0.7-0.9,1.1-1.2c0.8-0.8,1.8-1.4,2.6-1.7c0.4-0.2,0.8-0.3,1.2-0.4
							c0.4-0.1,0.7-0.1,1-0.1c0.3,0,0.6,0,0.9,0.1c0.3,0.1,0.6,0.2,1,0.4c0.7,0.3,1.3,0.9,1.7,1.5c0.5,0.6,0.8,1.2,1,1.8
							c0.4,1.3,0.2,2.9-0.2,4.3c-0.5,1.4-1.1,2.5-1.7,3.4c-0.5,0.9-0.9,1.6-0.7,2.3c0.2,0.6,0.9,1.1,2.3,1.2c1.4,0,3.5-0.5,5.6-2.2
							c1.1-0.8,2.1-1.9,3-3.4c0.9-1.4,1.6-3.3,1.9-5.4c0.6-4.3-0.9-9.3-4.7-13c-1-0.9-2.1-1.8-3.3-2.5c-1.3-0.7-2.7-1.3-4.2-1.6
							c-1.5-0.3-3-0.5-4.5-0.5c-1.5,0.1-3,0.3-4.4,0.7c-2.9,0.8-5.5,2.2-7.8,4c-1.2,0.9-2.3,2-3.2,3.2c-1,1.2-1.8,2.4-2.6,3.8
							c-1.5,2.7-2.5,6-2.5,9.6c0,3.6,1.2,7.2,2.9,10c1.7,2.9,4,5.1,6.4,6.8c1.2,0.9,2.5,1.6,3.8,2.3c1.3,0.7,2.7,1.2,4,1.6
							c5.5,1.8,10.7,2,15.4,1.5c2.3-0.3,4.5-0.7,6.6-1.3c2.1-0.6,4-1.3,5.8-2.2c7.3-3.4,12.6-8.1,17-13.2c4.4-5.2,7.9-10.9,11-17.6
							c3.2-6.7,5.9-14.3,9.1-23.4c-4,8.8-7.5,16.1-11.4,22.1c-3.9,6.1-8.1,10.9-12.8,14.9c-4.7,3.9-10,7-16.1,8.6
							c-1.5,0.4-3.1,0.7-4.7,0.9c-1.6,0.2-3.3,0.2-5,0.1C53.2,283.5,49.7,282.8,46.6,281.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M-32.4,315.2c-2-2.6-4.9-4.2-7.4-4.5c-2.5-0.4-4.6,0.4-5.3,2c-0.7,1.6-0.1,3.6,1.3,5.3c1.4,1.7,3.5,3.3,6.2,4.2
							c2.8,0.9,4.8,1.6,6.8,2.5c2,0.9,3.9,1.9,6.2,4c-1.5-2.8-2.6-4.7-3.6-6.8C-29.3,320-30.4,317.9-32.4,315.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M-34.7,295.5c0-2.7-0.4-4.9-1.3-6.9c-0.9-1.9-2.1-3.5-3.9-3.8c-1.7-0.3-3.6,1.1-4.5,3.8c-0.9,2.7-0.6,6.6,1.4,9.7
							c2.1,3.2,4,4.8,5.9,6.3c1.9,1.5,3.9,2.8,6.8,4.4c-2.2-2.4-3.1-4.5-3.7-6.6C-34.5,300.4-34.8,298.3-34.7,295.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M-26.6,282.8c1.3-2.3,2-4.4,2.3-6.5c0.2-2,0.1-4.1-1.2-5.3c-0.6-0.6-1.5-0.9-2.5-0.8c-0.5,0.1-1.1,0.2-1.6,0.5
							c-0.5,0.3-1.2,0.6-1.7,1.1c-2.3,1.9-4.1,5.5-3.9,9.3c0.3,3.9,1.2,6.3,2.3,8.6c1.1,2.2,2.3,4.4,4.3,7c-1.1-3.2-1-5.5-0.6-7.6
							C-28.7,287.1-27.9,285.2-26.6,282.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M-12.6,277.9c2.3-1.5,3.9-3.4,4.9-5.3c0.9-1.9,1.1-4,0.1-5.4c-1-1.4-3.2-1.8-5.6-0.9c-2.4,0.9-4.9,3.1-6.2,6.1
							c-1.3,3.1-1.8,5.4-2.3,7.6c-0.4,2.2-0.9,4.4-1.5,7.4c1.7-2.6,3.2-4.2,4.8-5.5C-16.7,280.6-15,279.4-12.6,277.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M4.7,276.2c2.7-0.3,4.9-1.1,6.6-2.4c1.7-1.3,2.9-2.9,2.7-4.7c-0.2-1.7-1.9-3.2-4.4-3.5c-2.5-0.4-5.9,0.4-8.5,2.6
							c-2.6,2.3-4,4.2-5.4,6c-1.3,1.9-2.6,3.7-4.3,6.3c2.5-1.9,4.5-2.7,6.5-3.3C-0.1,276.8,1.9,276.5,4.7,276.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M-32.6,331.5c-5.9-2.5-12.3-0.7-12.8,2.8c-0.4,3.5,5.4,6.9,11.7,5.9c3.2-0.5,5.5-1.1,7.8-1.5c1.2-0.2,2.2-0.5,3.4-0.5
							c0.6,0,1.2,0,1.8,0.2c0.6,0.2,1.3,0.6,1.7,1.3c-0.2-0.8-0.7-1.5-1.2-2c-0.6-0.5-1.2-0.9-1.8-1.2c-0.6-0.3-1.2-0.6-1.8-0.8
							c-0.6-0.2-1.1-0.5-1.6-0.7C-27.5,333.9-29.6,332.8-32.6,331.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M-26.1,344.6c-3.2,0.4-5.9,2.5-7,4.8c-1.2,2.2-0.8,4.4,0.5,5.5c1.4,1.1,3.3,1.1,5.1,0.8c1.8-0.4,3.4-1.3,4.9-3
							c1.6-1.7,3-2.9,4.6-3.9c1.6-1,3.4-1.7,6.2-1.9c-2.7-0.9-4.6-1.4-6.7-1.9C-20.6,344.6-22.8,344.2-26.1,344.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M-18.7,352.8c-2.9,1.5-4.7,4.4-5.1,6.8c-0.4,2.5,0.5,4.5,2.1,5.1c1.7,0.7,3.5,0.1,5.1-0.9c1.6-1,3-2.5,4-4.7
							c1-2.2,2-3.9,3.1-5.4c1.2-1.5,2.6-2.9,5.2-4.2c-2.9,0.2-5,0.5-7.1,0.9C-13.6,350.8-15.8,351.3-18.7,352.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M-9,360.7c-1.6,2.3-1.7,5.3-0.7,7.3c0.9,2,2.7,3,4.4,2.7c1.7-0.3,3.1-1.5,4-2.9c0.9-1.4,1.2-2.9,0.8-4.8
							c-0.4-2-0.6-3.6-0.5-5.2c0.1-1.6,0.4-3.3,1.7-5.4c-2,1.3-3.5,2.4-5,3.5C-5.8,357-7.4,358.3-9,360.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M3.3,368.7c0.3,1.5,1,2.7,2,3.7c1,0.9,2.1,1.5,3.2,1.7c1.1,0.2,2.1,0,2.9-0.4c0.8-0.4,1.3-1.1,1.6-1.9
							c0.6-1.7,0.4-3.4,0.1-4.6c-0.3-1.3-0.8-2.1-2.2-2.7c-1.4-0.7-2.5-1.6-3.3-2.7c-0.8-1.2-1.4-2.6-1.1-4.8
							c-1.3,1.8-2,3.4-2.6,5.1C3.3,363.7,2.7,365.7,3.3,368.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M21.2,371c2.3,2.1,5.5,2.7,7.9,2.1c2.4-0.6,3.8-2.2,3.7-3.9c0-1.8-1.2-3.3-2.6-4.4c-1.4-1.1-3.1-1.7-5.3-1.8
							c-2.3-0.1-4.1-0.3-5.8-0.8c-1.7-0.5-3.4-1.3-5.4-3.2c1.1,2.5,1.9,4.3,2.9,6.1C17.7,367,18.8,368.9,21.2,371z"
                      />
                    </g>
                    <g>
                      <path
                        d="M34.6,366c2.2,1.3,4.7,1.2,6.4,0.2c1.7-1,2.6-2.7,2.3-4.4c-0.3-1.7-1.4-3.1-2.7-4c-1.3-0.8-2.8-1.1-4.7-0.5
							c-1.9,0.6-3.3,1-4.8,1.3c-1.5,0.2-3.1,0.3-5.2-0.4c1.6,1.5,2.7,2.7,3.9,3.9C31.1,363.4,32.4,364.7,34.6,366z"
                      />
                    </g>
                    <g>
                      <path
                        d="M55.9,357.6c2.9,0.2,5.5-1,7-2.5c1.6-1.6,2.1-3.5,1.4-5.1c-0.7-1.6-2.5-2.5-4.5-2.7c-2-0.2-4.2,0.4-6.2,1.9
							c-2.1,1.5-3.6,2.7-5.2,3.7c-1.6,1-3.3,1.9-6,2.6c2.7,0.3,4.7,0.7,6.6,1.1C51,357,52.9,357.4,55.9,357.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M77.2,339.2c4.4-0.5,6.5-4.9,4.7-7.9c-1.8-3-6.6-3.2-9.2,0.4c-2.6,3.7-3.2,5.7-6.3,8.9
							C70.6,339.3,72.7,339.8,77.2,339.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M92.4,321c2-0.6,3.2-2.1,3.6-3.7c0.4-1.6-0.1-3.4-1.4-4.6c-1.3-1.2-3-1.6-4.5-1.3c-1.5,0.3-2.9,1.3-3.6,3.1
							c-1.4,3.7-1.5,5.7-3.9,8.9C86.3,321.9,88.3,322.2,92.4,321z"
                      />
                    </g>
                    <g>
                      <path
                        d="M59.8,305.3c-4.5,1.7-8.5,4.1-11.4,6.4c-2.8,2.3-4.5,4.7-3.8,6.3c0.7,1.6,3.6,2.1,7.4,1.3c3.8-0.8,8.5-3,12.3-6.5
							c3.9-3.5,6.3-6.2,8.8-8.9c2.4-2.7,4.8-5.4,8.3-9.3c-4.2,3.1-7.4,4.7-10.6,6.2C67.6,302.3,64.4,303.6,59.8,305.3z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M363.6,398.2c3.2,1.3,6.2,3.2,8.6,5.5c2.4,2.3,4.3,5,5.4,7.7c1.1,2.8,1.6,5.9,1.3,8.8c-0.1,1.4-0.4,2.8-0.9,4.1
							c-0.4,1.3-1.1,2.4-1.8,3.5c-3,4.3-8.8,7.2-14.8,8.1c-1.5,0.3-3,0.4-4.4,0.4c-1.5,0.1-2.9,0-4.3-0.1c-2.7-0.3-5-1-6.7-2
							c-0.8-0.5-1.5-1.1-2-1.7c-0.5-0.6-1-1.4-1.3-2.2c-0.6-1.7-0.8-3.6-0.5-5.2c0.1-0.8,0.4-1.6,0.7-2.3c0.1-0.4,0.4-0.7,0.5-1
							c0.2-0.3,0.4-0.6,0.7-0.9c1-1.2,2.2-2.1,3.6-2.7c2.6-1.2,5.2-1.4,7-1.4c1.8,0,3,0.1,3.4-0.4c0.4-0.5-0.2-1.8-2.1-3.2
							c-1-0.7-2.3-1.3-4-1.8c-1.7-0.4-3.8-0.6-6.1-0.3c-2.3,0.3-4.9,1.2-7.4,3c-0.6,0.4-1.2,1-1.8,1.5c-0.6,0.6-1.2,1.2-1.6,1.9
							c-1,1.3-1.9,2.9-2.5,4.5c-1.3,3.3-1.6,7.2-0.8,11.2c0.4,2,1.2,4.1,2.4,6c1.3,2,2.8,3.6,4.6,5c3.6,2.8,7.8,4.3,11.9,5.1
							c2.1,0.4,4.2,0.6,6.3,0.7c2.1,0.1,4.3,0,6.4-0.3c2.2-0.2,4.4-0.7,6.6-1.3c2.2-0.6,4.4-1.4,6.6-2.5c2.2-1.1,4.3-2.4,6.3-4
							c2-1.6,3.8-3.5,5.5-5.7c1.6-2.2,2.9-4.6,3.8-7.2c0.9-2.6,1.5-5.2,1.7-7.9c0.4-5.4-0.6-10.7-2.7-15.7c-2.2-5-5.6-9.2-9.6-12.6
							c-4-3.3-8.6-5.8-13.4-7.3c-5-1.6-9.9-2.2-14.5-2.1c-4.6,0-9,0.7-13,1.7c-8.1,2.1-15,5.4-21,9.4c-6,4-11,8.6-15.3,13.5
							c-4.3,4.9-8,10-11.1,15.5c-3.1,5.4-5.8,11.2-8,17.2c-2.2,6.1-3.9,12.4-5.3,19.3c-2.7,13.7-3.8,29-3.8,47.2
							c0.7-18.2,2.8-33.4,6.3-46.6c1.8-6.6,3.9-12.7,6.5-18.3c2.6-5.7,5.5-10.9,8.9-15.7c3.4-4.8,7.3-9.3,11.6-13.4
							c4.4-4.1,9.3-7.7,14.8-10.7c5.5-3,11.8-5.2,18.5-6.4c3.4-0.5,6.9-0.8,10.4-0.5C356.7,396.1,360.3,396.8,363.6,398.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M331,455.1c1.7,0.6,3,1.3,4.3,2.2c1.2,0.9,2.2,1.9,2.9,2.8c0.3,0.5,0.5,0.9,0.7,1.3c0.2,0.4,0.2,0.8,0.3,1.2
							c0.1,0.8,0,1.8-0.4,2.8c-0.2,0.5-0.4,1.1-0.7,1.6c-0.3,0.5-0.6,0.9-1,1.4c-0.7,0.9-1.6,1.6-2.4,2.1c-0.4,0.2-0.8,0.4-1.1,0.6
							c-0.4,0.1-0.7,0.2-1,0.3c-0.3,0-0.6,0.1-0.9,0c-0.3,0-0.7-0.1-1-0.2c-0.7-0.3-1.4-0.7-1.9-1.2c-0.5-0.5-1-1.1-1.2-1.7
							c-0.6-1.2-0.6-2.9-0.3-4.3c0.3-1.4,0.8-2.6,1.2-3.6c0.4-1,0.7-1.7,0.4-2.4c-0.2-0.6-1-1-2.5-0.9c-1.4,0.2-3.4,0.9-5.3,2.9
							c-0.9,1-1.9,2.2-2.6,3.7c-0.7,1.6-1.2,3.4-1.2,5.6c0,4.3,2.1,9.1,6.4,12.3c1.1,0.8,2.3,1.5,3.6,2.1c1.3,0.5,2.9,0.9,4.4,1.1
							c1.5,0.1,3,0.1,4.5-0.1c1.5-0.3,2.9-0.7,4.3-1.2c2.7-1.2,5.2-2.9,7.2-5c1-1.1,2-2.3,2.8-3.6c0.8-1.3,1.5-2.6,2-4.1
							c1.1-2.9,1.7-6.3,1.2-9.9c-0.5-3.6-2.1-7-4.2-9.5c-2.1-2.6-4.6-4.5-7.3-5.9c-1.3-0.7-2.7-1.3-4.1-1.8
							c-1.4-0.5-2.8-0.8-4.1-1.1c-5.7-1.1-10.9-0.6-15.4,0.5c-2.3,0.6-4.4,1.3-6.3,2.2c-2,0.9-3.8,1.8-5.5,2.9
							c-6.8,4.3-11.4,9.7-15.1,15.3c-3.7,5.7-6.4,11.9-8.7,18.9c-2.3,7-4,15-6,24.4c2.8-9.2,5.4-16.9,8.5-23.4
							c3.1-6.5,6.6-11.9,10.7-16.4c4.1-4.5,9-8.3,14.8-10.6c1.5-0.6,3-1.1,4.6-1.5c1.6-0.4,3.2-0.6,4.9-0.8
							C324,453.6,327.6,453.9,331,455.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M404.9,411.1c2.3,2.3,5.4,3.5,7.9,3.5c2.5,0,4.5-1,5-2.7c0.5-1.7-0.4-3.6-2-5.1c-1.6-1.6-3.9-2.8-6.7-3.3
							c-2.9-0.6-5-1-7-1.6c-2.1-0.6-4.1-1.4-6.7-3.2c1.9,2.5,3.2,4.4,4.4,6.3C401.2,406.8,402.5,408.7,404.9,411.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M409.7,430.4c0.3,2.7,1.1,4.8,2.2,6.6c1.1,1.8,2.6,3.2,4.3,3.3c1.7,0.1,3.4-1.5,4-4.4c0.6-2.8-0.2-6.6-2.6-9.4
							c-2.5-2.9-4.6-4.3-6.7-5.5c-2.1-1.2-4.2-2.3-7.3-3.5c2.5,2.1,3.7,4.1,4.5,6.1C408.9,425.6,409.4,427.6,409.7,430.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M403.3,444.1c-1,2.4-1.5,4.6-1.4,6.7c0,2,0.4,4,1.8,5.1c0.7,0.5,1.6,0.7,2.6,0.4c0.5-0.1,1.1-0.3,1.6-0.7
							c0.5-0.3,1.1-0.8,1.6-1.3c2-2.1,3.4-6,2.6-9.8c-0.8-3.8-2.1-6.1-3.4-8.2c-1.4-2.1-2.8-4-5.2-6.4c1.5,3,1.7,5.3,1.5,7.4
							C404.8,439.5,404.3,441.5,403.3,444.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M390,450.7c-2.1,1.7-3.5,3.8-4.1,5.9c-0.6,2-0.6,4.1,0.6,5.4c1.2,1.3,3.4,1.3,5.6,0.1c2.2-1.2,4.4-3.7,5.3-6.8
							c0.9-3.3,1.1-5.6,1.3-7.8c0.2-2.3,0.3-4.5,0.6-7.6c-1.3,2.8-2.6,4.5-4.1,6.1C393.8,447.5,392.2,448.9,390,450.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M373.1,454.6c-2.6,0.6-4.7,1.8-6.2,3.2c-1.5,1.5-2.5,3.3-2.1,5c0.4,1.7,2.3,2.9,4.9,2.9c2.6,0,5.8-1.2,8.1-3.7
							c2.3-2.6,3.4-4.6,4.5-6.7c1.1-2,2.1-4,3.4-6.8c-2.2,2.2-4.1,3.3-6,4.1C377.8,453.4,375.8,453.9,373.1,454.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M402.9,395c6.1,1.8,12.3-0.9,12.3-4.5c0-3.5-6.2-6.1-12.3-4.3c-3.2,0.9-5.3,1.8-7.5,2.5c-1.1,0.4-2.2,0.8-3.3,0.9
							c-0.6,0.1-1.2,0.1-1.8,0c-0.6-0.1-1.3-0.5-1.8-1.1c0.3,0.8,0.9,1.4,1.5,1.9c0.6,0.4,1.3,0.7,2,1c0.6,0.2,1.3,0.4,1.9,0.6
							c0.6,0.2,1.1,0.3,1.7,0.5C397.6,393.2,399.8,394.1,402.9,395z"
                      />
                    </g>
                    <g>
                      <path
                        d="M394.7,382.8c3.1-0.8,5.5-3.3,6.3-5.6c0.9-2.4,0.2-4.4-1.3-5.3c-1.5-0.9-3.4-0.7-5.1-0.1c-1.7,0.6-3.2,1.7-4.5,3.6
							c-1.4,1.9-2.6,3.3-4,4.4c-1.4,1.1-3.1,2.2-5.9,2.7c2.8,0.5,4.8,0.8,6.9,1C389.3,383.5,391.5,383.6,394.7,382.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M386.4,375.6c2.7-1.9,4.1-4.9,4.2-7.4c0.1-2.5-1.1-4.4-2.8-4.8c-1.7-0.4-3.5,0.4-5,1.5c-1.5,1.2-2.6,2.9-3.3,5.2
							c-0.7,2.4-1.5,4.1-2.4,5.8c-1,1.6-2.2,3.2-4.6,4.8c2.8-0.6,4.8-1.2,6.9-1.8C381.5,378.3,383.7,377.5,386.4,375.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M375.7,369.1c1.3-2.5,1-5.5-0.2-7.3c-1.2-1.9-3.1-2.6-4.7-2.1c-1.7,0.5-2.9,1.9-3.6,3.3c-0.7,1.5-0.8,3-0.2,4.9
							c0.6,1.9,1,3.5,1.2,5.1c0.1,1.6,0,3.3-1,5.5c1.8-1.6,3.2-2.8,4.5-4.1C373.1,373.1,374.4,371.7,375.7,369.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M362.5,362.8c-0.5-1.4-1.4-2.6-2.5-3.4c-1.1-0.8-2.3-1.2-3.4-1.3c-1.1-0.1-2.1,0.2-2.8,0.8c-0.7,0.5-1.2,1.3-1.4,2.1
							c-0.4,1.7,0.1,3.4,0.5,4.6c0.5,1.2,1.1,1.9,2.5,2.4c1.5,0.5,2.7,1.2,3.6,2.3c0.9,1,1.7,2.4,1.8,4.6c1-2,1.6-3.6,2-5.4
							C363.2,367.7,363.5,365.7,362.5,362.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M344.5,362.7c-2.6-1.8-5.9-2-8.1-1.1c-2.3,0.9-3.4,2.7-3.2,4.4c0.3,1.8,1.6,3.1,3.1,4c1.5,0.9,3.3,1.3,5.5,1.1
							c2.3-0.2,4.1-0.2,5.8,0c1.8,0.3,3.6,0.8,5.8,2.5c-1.4-2.3-2.5-4-3.7-5.7C348.5,366.3,347.1,364.6,344.5,362.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M331.8,369.5c-2.3-1-4.8-0.5-6.4,0.6c-1.6,1.2-2.2,3-1.8,4.7c0.5,1.7,1.8,2.9,3.2,3.6c1.4,0.6,3,0.7,4.7-0.1
							c1.8-0.8,3.2-1.5,4.6-1.9c1.5-0.4,3-0.7,5.2-0.3c-1.8-1.3-3.1-2.3-4.4-3.4C335.6,371.6,334.2,370.5,331.8,369.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M311.8,380.6c-2.9,0.2-5.3,1.7-6.6,3.4c-1.4,1.8-1.7,3.8-0.7,5.3c0.9,1.5,2.8,2.1,4.8,2c2-0.1,4.1-0.9,5.9-2.7
							c1.9-1.8,3.2-3.1,4.7-4.3c1.5-1.2,3-2.3,5.6-3.4c-2.8,0-4.7-0.1-6.7-0.3C316.8,380.6,314.7,380.4,311.8,380.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M293.1,401.5c-4.3,1.1-5.8,5.7-3.6,8.4c2.2,2.8,7,2.4,9-1.5c2.1-4,2.4-6.1,5.1-9.6
							C299.6,400.6,297.5,400.4,293.1,401.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M280.3,421.6c-1.9,0.9-2.9,2.5-3.1,4.2c-0.2,1.7,0.5,3.3,2,4.4c1.4,1,3.2,1.2,4.7,0.7c1.5-0.5,2.7-1.7,3.1-3.6
							c0.9-3.9,0.8-5.9,2.7-9.4C286.3,419.9,284.3,419.9,280.3,421.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M314.7,432.9c4.2-2.3,7.9-5.1,10.4-7.8c2.5-2.7,3.8-5.2,2.9-6.7c-0.9-1.5-3.8-1.6-7.5-0.3c-3.7,1.3-8,4.1-11.4,8
							c-3.5,4-5.5,7-7.5,10c-2,3-4,6-7,10.3c3.8-3.6,6.7-5.7,9.7-7.5C307.3,436.9,310.4,435.2,314.7,432.9z"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M344.5,109.3c1.3-4.4,1.9-9.1,1.4-13.7c-0.4-4.6-1.8-9.3-4.3-13.4c-0.3-0.5-0.6-1-1-1.5c-0.4-0.5-0.7-1-1.1-1.5
						c-0.8-0.9-1.5-1.8-2.4-2.7c-1.7-1.7-3.6-3.1-5.6-4.3c-4-2.4-8.3-3.8-12.6-4.4c-4.3-0.6-8.7-0.6-12.9,0.4
						c-4.2,0.9-8.3,2.7-11.6,5.5c-3.3,2.7-5.9,6.2-7.2,10.2c-0.7,1.9-1.1,4.1-1.1,6.1c0,2,0.2,4,0.8,5.9c1.1,3.7,3.3,7,6.3,9.2
						c3,2.2,6.5,3.1,9.6,3c3.1-0.1,6.1-1.1,8.3-2.8c1.1-0.9,2-1.9,2.7-2.9c0.7-1.1,1.2-2.2,1.4-3.2c0.6-2.1,0.5-4.1,0.2-5.7
						c-0.3-1.6-1-2.9-1.6-3.8c-1.4-1.8-2.6-2.1-2.9-1.8c-0.3,0.3,0.2,1.3,0.6,2.9c0.2,0.8,0.3,1.8,0.2,3c-0.1,1.1-0.4,2.4-1,3.7
						c-0.3,0.6-0.7,1.2-1.2,1.7c-0.5,0.5-1.1,0.9-1.7,1.3c-1.3,0.7-3,1.1-4.8,0.9c-1.8-0.2-3.7-0.9-5-2.1c-1.4-1.3-2.5-3.2-2.9-5.3
						c-0.2-1.1-0.3-2.2-0.2-3.4c0.1-1.1,0.4-2.2,0.8-3.3c0.9-2.2,2.6-4.3,4.7-6c2.1-1.6,4.8-2.7,7.7-3.3c2.9-0.6,6.2-0.6,9.4-0.1
						c6.4,1,12.6,4.2,15.9,9.6c3.5,5.4,4.4,12.8,2.7,19.6c-0.4,1.7-1,3.4-1.8,5c-0.7,1.6-1.6,3.1-2.6,4.6c-2,2.9-4.2,5.4-6.7,7.6
						c-5,4.4-10.6,7.5-16.3,9.7c-5.7,2.3-11.4,3.6-17.1,4.6c-5.7,1-11.4,1.5-17.1,1.9c-5.7,0.4-11.5,0.5-17.5,0.9
						c-6,0.4-12.3,1.1-18.6,3c-3.2,0.9-6.4,2.1-9.5,3.8c-3.1,1.6-6.1,3.7-8.8,6.3c-2.7,2.6-5,5.8-6.7,9.4c-1.6,3.6-2.6,7.8-2.2,12.1
						c-0.2-4.3,0.9-8.4,2.6-12c1.8-3.5,4.1-6.6,6.9-9c2.7-2.4,5.7-4.3,8.8-5.8c3.1-1.4,6.2-2.5,9.4-3.2c6.3-1.5,12.4-1.9,18.3-1.9
						c5.9-0.1,11.7,0.2,17.5,0.2c5.8,0,11.6-0.2,17.6-0.8c6-0.7,12.2-1.7,18.5-3.8c6.3-2,12.9-5,19-9.8c3.1-2.4,6-5.2,8.6-8.6
						C340.9,117.8,343.1,113.9,344.5,109.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M278.5,96c-3.9-1.2-8.4-1.3-12.5,0.5c-2,0.9-3.8,2.1-5.3,3.7c-1.5,1.5-2.7,3.3-3.6,5.3c-0.8,2-1.2,4.2-1.1,6.4
						c0.1,2.2,0.7,4.2,1.6,6c1.8,3.5,4.9,6.1,8.4,7c3.5,1,6.9,0.3,9.4-1.4c1.2-0.8,2.2-1.9,2.8-3c0.6-1.1,0.9-2.2,1-3.1
						c0.2-1.9-0.3-3.4-0.9-4.2c-0.6-0.8-1.2-1-1.6-0.9c-0.8,0.3-0.9,2.1-2.2,3.6c-0.3,0.4-0.7,0.8-1.1,1c-0.4,0.3-0.8,0.4-1.3,0.5
						c-1,0.2-2.4,0.1-3.4-0.5c-1.1-0.6-2.2-1.8-2.7-3.2c-0.6-1.5-0.5-2.8,0.1-4.4c0.7-1.6,2.1-3.1,3.8-4c1.7-0.9,3.9-1.2,6.3-0.9
						c4.9,0.7,8.5,4.3,10.6,8.1c2.2,3.9,3,8.1,2.7,12.3c-0.2,2.1-0.6,4.2-1.5,6.2c-0.4,1-1,2-1.6,3c-0.7,0.9-1.4,1.8-2.4,2.7
						c-1.8,1.6-4.1,3-6.9,3.8c-2.7,0.7-6,0.9-9.2-0.3c3.1,1.4,6.5,1.6,9.4,1.1c3-0.5,5.6-1.7,7.8-3.3c1.1-0.8,2.1-1.7,3-2.6
						c0.9-1,1.7-2,2.3-3.1c1.4-2.1,2.2-4.5,2.9-6.9c1.1-4.8,1-10-0.9-15.4c-1-2.7-2.4-5.4-4.7-8C285.7,99.6,282.5,97.2,278.5,96z"
                    />
                  </g>
                  <g>
                    <path
                      d="M315.1,122.6c2.7-2.4,4.1-5.8,4.2-8.5c0.1-2.8-0.9-4.8-2.6-5.3c-1.7-0.5-3.6,0.5-5.2,2.2c-1.6,1.7-2.9,3.9-3.7,6.8
						c-0.8,2.9-1.5,5-2.5,7.1c-1,2-2.1,4-4.5,6.4c3-1.5,5.1-2.6,7.3-3.8C310.2,126.3,312.4,125.1,315.1,122.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M366,89.8c2.5-1.7,4.3-3.8,5.4-5.9c1.1-2.1,1.5-4.2,0.4-5.7c-1-1.4-3.3-1.7-5.9-0.7c-2.6,1.1-5.4,3.6-6.8,6.9
						c-1.5,3.5-2,6-2.5,8.4c-0.4,2.5-0.8,4.9-1.4,8.3c1.5-3.1,3.1-4.9,4.7-6.6C361.7,93,363.5,91.6,366,89.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M379.9,95.6c3-0.8,5.4-2.4,6.9-4.3c1.5-1.8,2.2-3.9,1.4-5.5c-0.8-1.6-2.9-2.2-5.4-1.9c-2.5,0.3-5.4,1.7-7.6,4.1
						c-2.3,2.5-3.6,4.4-5,6.2c-1.4,1.8-2.8,3.6-5.1,6c2.9-1.5,5.1-2.3,7.2-2.9C374.6,96.9,376.8,96.4,379.9,95.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M390.9,105.2c3.1,0.2,5.9-0.4,8-1.5c2.1-1.1,3.7-2.6,3.6-4.4c0-1.7-1.7-3.3-4.4-4c-2.6-0.7-6.2-0.4-9.3,1.3
						c-3.2,1.7-5.2,3.3-7.1,4.7c-1.9,1.5-3.8,3-6.6,5c3.3-1.1,5.6-1.4,7.9-1.5C385.4,104.9,387.7,105,390.9,105.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M360.3,116.8c2.7-0.8,4.8-2.3,6.1-4c1.3-1.7,2-3.7,1.2-5.3c-0.7-1.6-2.7-2.4-5.1-2.1c-2.4,0.3-5.2,1.7-7.2,4.2
						c-2,2.5-3.1,4.5-4.2,6.4c-1.1,1.9-2.2,3.8-3.9,6.3c2.5-1.8,4.4-2.7,6.3-3.5C355.5,118.1,357.5,117.6,360.3,116.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M373.4,121.3c2.6,0.1,4.9-0.6,6.6-1.8c1.7-1.2,2.8-2.9,2.6-4.6c-0.2-1.7-1.8-3.1-4.1-3.6c-2.3-0.5-5.2,0-7.7,1.7
						c-2.5,1.8-4.1,3.3-5.6,4.7c-1.5,1.5-3,2.9-5.3,4.7c2.8-0.9,4.8-1.1,6.7-1.2C368.7,121,370.7,121.2,373.4,121.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M293.2,147.9c-6.4,0.5-11.3,5.2-10.1,8.5c1.2,3.3,8,3.6,13.2-0.3c5.3-4,7-6.2,12.8-9.5
						C302.5,148,299.8,147.4,293.2,147.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M300.9,162.8c-1.7,2.4-2,5.5-1.2,7.6c0.7,2.2,2.4,3.5,4.1,3.4c1.8-0.1,3.2-1.3,4.2-2.8c1-1.5,1.5-3.4,1.3-5.6
						c-0.2-2.3-0.3-4.1-0.2-5.9c0.2-1.8,0.5-3.6,1.8-5.9c-2.1,1.6-3.6,2.8-5.1,4.1C304.2,159,302.7,160.4,300.9,162.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M250.7,158.6c5.2-0.2,10.2-0.8,14.1-1.6c3.9-0.8,6.7-2,7-3.7c0.2-1.7-2.4-3.7-6.9-4.6c-4.4-1-10.5-0.7-16.1,1.4
						c-5.7,2.2-9.2,4.4-12.7,6.7c-3.4,2.3-6.7,4.7-11.2,8.5c5.1-2.9,9-4.2,12.8-5.1C241.5,159.3,245.3,158.8,250.7,158.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M452.7,61.6c-0.5-3.2-1.3-6.4-2.5-9.5c-1.2-3.1-2.9-6.2-5.1-8.9c-2.2-2.7-5-5-8.1-6.7c-3.1-1.7-6.7-2.7-10.3-2.8
						l-1.4,0l-1.4,0.1c-0.9,0.1-1.8,0.3-2.7,0.5c-1.8,0.5-3.4,1.1-4.9,2c-3,1.7-5.6,4.2-7.3,7.3c-1.7,3-2.4,6.8-1.9,10
						c0.2,1.6,0.7,3.2,1.3,4.6c0.6,1.4,1.4,2.7,2.2,3.8c3.4,4.5,8.5,7,13.1,7.2c2.3,0.1,4.6-0.3,6.5-1.3c1-0.5,1.8-1.1,2.5-1.8
						c0.7-0.7,1.2-1.5,1.7-2.3c1.5-3.2,1-6,0-7.5c-0.5-0.8-1-1.2-1.4-1.4c-0.4-0.2-0.7-0.2-0.9,0c-0.3,0.3-0.1,1.1-0.1,2.2
						c0,0.6-0.1,1.2-0.4,1.9c-0.3,0.7-0.7,1.5-1.3,2.1c-0.3,0.3-0.7,0.6-1,0.8c-0.3,0.2-0.8,0.4-1.3,0.5c-1,0.2-2.2,0.2-3.5-0.1
						c-2.6-0.6-5.4-2.4-7.1-5.2c-0.4-0.7-0.8-1.4-1-2.2c-0.2-0.8-0.4-1.5-0.4-2.3c-0.1-0.8,0-1.5,0.2-2.2c0.2-0.7,0.5-1.4,0.9-2.1
						c0.8-1.4,2.2-2.6,3.9-3.5c0.8-0.4,1.7-0.8,2.6-1c0.4-0.1,0.9-0.2,1.4-0.2l0.7,0l0.7,0c1.9,0.1,4,0.6,5.9,1.7
						c1.9,1,3.8,2.5,5.3,4.2c3.1,3.5,5.2,8.4,6.4,13.7c1.2,5.5,1.6,10.9,1.4,15.9c-0.2,5-0.9,9.7-1.9,14.1
						c-2.1,8.8-5.4,16.4-9.6,23.5c-4.2,7.1-9.5,13.8-16.3,20.4c-6.8,6.6-15.2,13.2-26.1,19.6c11.2-6,20-12.1,27.3-18.3
						c7.3-6.2,13.3-12.6,18.3-19.6c5-7,9.2-14.7,12.3-23.9c1.5-4.6,2.7-9.6,3.4-15.1C453.5,74.1,453.6,68.1,452.7,61.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M420.9,70.1c-1.7-0.9-3.7-1.5-5.7-1.6c-2.1-0.2-4.2,0.2-6.2,0.9c-1.9,0.7-3.7,1.8-5.2,3.1c-1.5,1.4-2.7,3-3.5,5
						c-0.8,1.9-1.2,4.1-1.1,6c0.1,2,0.6,3.8,1.4,5.5c1.6,3.3,4.3,5.8,7.4,6.9c1.6,0.6,3.2,0.8,4.6,0.6c1.4-0.1,2.7-0.5,3.8-0.9
						c2.2-1,3.7-2.4,4.6-3.8c0.9-1.4,1.2-2.7,1.1-3.6c-0.1-0.9-0.4-1.4-0.8-1.5c-0.9-0.2-1.9,0.9-3.8,1.6c-0.9,0.4-2,0.7-3.2,0.8
						c-1.2,0-2.4-0.2-3.2-0.9c-0.9-0.6-1.7-1.7-2.1-3c-0.2-0.6-0.3-1.3-0.3-2c0-0.6,0.2-1.2,0.4-1.8c0.5-1.2,1.7-2.4,3.2-3
						c0.7-0.3,1.5-0.5,2.2-0.6c0.8,0,1.7,0.1,2.6,0.3c1.9,0.6,3.8,1.9,5.4,3.4c0.8,0.8,1.5,1.6,2.2,2.4c0.7,0.8,1.3,1.7,1.9,2.6
						c2.3,3.5,3.9,7.1,5,10.9c1.1,3.8,1.7,7.8,1.5,12.4c0,0.6-0.1,1.1-0.1,1.7c0,0.6-0.1,1.2-0.2,1.8c-0.1,1.2-0.4,2.4-0.6,3.7
						c-0.6,2.5-1.4,5.2-2.7,8c1.5-2.7,2.7-5.3,3.5-7.8c0.4-1.3,0.8-2.5,1.1-3.7c0.1-0.6,0.3-1.2,0.4-1.8c0.1-0.6,0.2-1.2,0.3-1.8
						c0.8-4.7,0.9-9,0.5-13.3c-0.5-4.3-1.4-8.6-3.3-13.1c-0.5-1.1-1-2.3-1.7-3.4c-0.6-1.2-1.4-2.4-2.2-3.5
						C426.7,74.4,424.4,72,420.9,70.1z"
                    />
                  </g>
                  <g>
                    <g>
                      <path
                        d="M503.8,68.5c5.7-0.7,11.4-1.1,16.9-1.7c5.5-0.5,10.7-1.1,15.1-2c4.5-0.8,8.2-1.9,10.7-2.9c2.5-1.1,3.9-2.2,3.7-3
							c-0.2-0.9-1.8-1.4-4.4-1.8c-2.6-0.4-6.3-0.5-10.8-0.6c-4.4-0.1-9.6,0-15.3,0.4c-5.6,0.4-11.8,1.3-17.7,2.9
							c-6.2,1.7-11.2,3.7-15.6,6c-4.3,2.3-8,4.8-11.3,7.8c-0.8,0.7-1.6,1.5-2.4,2.3c-0.8,0.8-1.5,1.6-2.3,2.5
							c-1.5,1.7-2.9,3.6-4.2,5.6c-2.6,4.1-4.9,9.2-5.6,15.5c1.2-6.2,4.1-10.9,7-14.5c1.5-1.8,3.1-3.4,4.7-4.7c0.8-0.7,1.7-1.3,2.5-2
							c0.9-0.6,1.7-1.2,2.6-1.7c3.5-2.2,7.2-3.8,11.4-5.1C493.2,70.2,498,69.2,503.8,68.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M512,85.7c5.6-1.4,11.1-3.6,15.9-6.1c4.8-2.5,9.1-5.5,12.4-8.3c6.7-5.7,9.8-10.9,8.6-12.1c-1.2-1.3-6.2,1.4-13.3,5.2
							c-7.1,3.9-16.5,8.8-26.3,13c-5.1,2.2-9.5,3.7-13.6,4.9c-4.1,1.2-8,2.1-11.9,3.3c-3.9,1.1-7.8,2.4-11.9,4.4
							c-2,1-4.2,2.2-6.2,3.8c-2.1,1.5-4.1,3.5-5.8,5.9c1.9-2.2,4.1-3.9,6.3-5.2c2.2-1.3,4.3-2.2,6.4-2.9c4.2-1.4,8.1-1.9,12-2.3
							c3.9-0.4,7.8-0.5,12.3-1C501.2,87.9,506.2,87.1,512,85.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M519.6,76.1c6.3-1,12.2-3.4,16.2-6c4.1-2.6,6.3-5.3,5.6-6.9c-0.7-1.6-4.1-1.8-8.7-0.9c-4.5,0.9-10.2,2.7-15.7,5.4
							c-5.7,2.7-9.6,4.6-13.6,6.3c-4.1,1.7-8.3,3.3-14.6,4.9c6.6-0.6,11-0.9,15.5-1.2C508.7,77.4,513.2,77.1,519.6,76.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M517.7,72.2c4.9-0.5,9.4-2.3,12.5-4.4c3.1-2.1,4.8-4.4,4.1-6c-0.7-1.6-3.4-2.2-7-1.8c-3.6,0.4-8,1.7-12.2,3.8
							c-4.3,2.3-7.3,3.9-10.4,5.4c-3.1,1.5-6.2,2.9-11.1,4.4c5-0.4,8.5-0.6,12-0.7C509.2,72.8,512.7,72.7,517.7,72.2z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M490.4,213.7c-5.6-1.1-11.2-2.4-16.6-3.5c-5.4-1.2-10.5-2.2-15-2.7c-4.5-0.6-8.4-0.7-11.1-0.5
							c-2.7,0.3-4.3,0.9-4.5,1.8c-0.1,0.9,1.2,1.9,3.6,3c2.4,1.1,5.9,2.4,10.1,3.8c4.2,1.4,9.2,2.9,14.7,4.2
							c5.5,1.3,11.6,2.3,17.8,2.6c6.4,0.2,11.8-0.1,16.7-1c4.8-0.9,9.1-2.1,13.1-4c1-0.4,2-1,3-1.4c1-0.5,2-1.1,2.9-1.7
							c1.9-1.2,3.8-2.5,5.7-4.1c3.7-3.1,7.4-7.3,10-13.1c-3.1,5.6-7.2,9.1-11.1,11.7c-2,1.3-3.9,2.3-5.9,3.1c-1,0.4-2,0.8-2.9,1.1
							c-1,0.3-2,0.6-3,0.8c-3.9,1-8,1.4-12.4,1.4C501.1,215.3,496.2,214.8,490.4,213.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M487.9,194.8c-5.8-0.4-11.6,0.1-17,1c-5.4,1-10.3,2.5-14.3,4.2c-8.1,3.4-12.6,7.4-11.8,8.9c0.8,1.6,6.3,0.6,14.3-0.9
							c8-1.5,18.4-3.4,29-4.4c5.5-0.6,10.2-0.7,14.5-0.6c4.3,0.1,8.3,0.4,12.3,0.5c4,0.1,8.2,0.1,12.7-0.6c2.3-0.4,4.6-0.9,7.1-1.7
							c2.4-0.9,4.9-2.1,7.3-3.9c-2.5,1.6-5.1,2.5-7.5,3.1c-2.4,0.6-4.8,0.8-7,0.8c-4.4,0-8.3-0.6-12.1-1.4c-3.8-0.8-7.6-1.9-12-2.8
							C498.8,196,493.9,195.2,487.9,194.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M477.7,201.6c-6.3-0.9-12.6-0.4-17.3,0.8c-4.7,1.2-7.6,3.1-7.4,4.9c0.2,1.8,3.4,2.9,8,3.5c4.6,0.5,10.6,0.5,16.6-0.4
							c6.2-0.9,10.5-1.5,14.9-1.9c4.4-0.4,8.9-0.6,15.4-0.3c-6.4-1.4-10.8-2.5-15.2-3.5C488.5,203.7,484.2,202.6,477.7,201.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M478.3,205.9c-4.8-1-9.7-0.7-13.3,0.4c-3.6,1-5.9,2.8-5.8,4.5c0.1,1.8,2.6,3.1,6.1,3.9c3.5,0.7,8.1,0.8,12.8,0
							c4.8-0.8,8.1-1.5,11.5-2c3.4-0.5,6.8-0.9,11.9-0.8c-4.9-1.1-8.3-2-11.6-3C486.6,207.9,483.3,206.9,478.3,205.9z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M295.7,319.1c4.1-3.9,8.4-7.8,12.5-11.5c4.1-3.7,7.9-7.3,10.9-10.7c3.1-3.3,5.4-6.4,6.8-8.8c1.4-2.4,1.8-4.1,1.2-4.7
							c-0.6-0.6-2.3-0.1-4.6,1.2c-2.3,1.3-5.4,3.4-9,6c-3.6,2.6-7.7,5.8-12,9.5c-4.2,3.7-8.6,8.1-12.4,13c-3.9,5.1-6.7,9.7-8.9,14.2
							c-2.1,4.4-3.5,8.7-4.4,13c-0.2,1.1-0.4,2.2-0.6,3.3c-0.1,1.1-0.3,2.2-0.3,3.3c-0.2,2.3-0.2,4.6,0,7c0.4,4.8,1.6,10.3,4.8,15.8
							c-2.8-5.7-3.3-11.1-3.1-15.8c0.1-2.3,0.4-4.5,0.9-6.6c0.2-1,0.5-2,0.8-3c0.3-1,0.6-2,1-2.9c1.5-3.8,3.5-7.3,6.1-11
							C288.2,326.9,291.4,323.2,295.7,319.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M312.5,328c3.7-4.5,6.7-9.5,9-14.5c2.3-4.9,4-9.8,4.9-14.1c1.9-8.6,1.3-14.5-0.4-14.8c-1.8-0.3-4.2,4.8-7.5,12.2
							c-3.4,7.4-7.9,16.9-13.3,26.2c-2.7,4.8-5.4,8.7-7.9,12.1c-2.6,3.4-5.1,6.5-7.5,9.7c-2.4,3.2-4.8,6.6-6.9,10.7
							c-1,2.1-2,4.3-2.7,6.8c-0.7,2.5-1.2,5.2-1.1,8.2c0.2-3,0.9-5.6,1.9-7.9c1-2.3,2.2-4.3,3.4-6.1c2.5-3.6,5.3-6.4,8.2-9
							c2.9-2.7,5.9-5.1,9.3-8.2C305.2,336.2,308.7,332.7,312.5,328z"
                      />
                    </g>
                    <g>
                      <path
                        d="M312.9,315.8c4.4-4.6,7.7-10,9.4-14.5c1.7-4.5,1.8-8,0.3-8.8c-1.6-0.9-4.4,1.1-7.5,4.5c-3.1,3.4-6.5,8.3-9.3,13.8
							c-2.9,5.6-4.9,9.4-7.1,13.3c-2.2,3.8-4.6,7.6-8.7,12.7c4.9-4.4,8.3-7.4,11.7-10.3C305,323.4,308.3,320.5,312.9,315.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M309,313.8c3.6-3.4,6.2-7.5,7.4-11c1.2-3.5,1.2-6.4-0.3-7.3c-1.5-0.9-4,0.3-6.7,2.7c-2.6,2.5-5.4,6.1-7.4,10.4
							c-2.1,4.4-3.5,7.5-5,10.5c-1.6,3-3.3,6.1-6.2,10.2c3.8-3.4,6.5-5.6,9.2-7.8C302.6,319.3,305.3,317.2,309,313.8z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M112.7,104.3c4.4,3.7,8.6,7.6,12.7,11.2c4.1,3.6,8.1,7,11.7,9.8c3.6,2.7,6.9,4.7,9.5,5.9c2.5,1.1,4.2,1.4,4.7,0.7
							c0.5-0.7-0.2-2.2-1.7-4.4c-1.5-2.2-3.9-5-6.9-8.3c-3-3.3-6.6-7.1-10.7-10.9c-4.2-3.8-8.9-7.7-14.2-11
							c-5.4-3.3-10.4-5.7-15-7.3c-4.6-1.6-9-2.6-13.4-3c-1.1-0.1-2.2-0.1-3.3-0.2c-1.1,0-2.2,0-3.4,0c-2.3,0.1-4.6,0.3-7,0.8
							c-4.8,0.9-10.1,2.7-15.2,6.4c5.4-3.3,10.7-4.4,15.4-4.7c2.3-0.1,4.6,0,6.7,0.2c1.1,0.1,2.1,0.3,3.1,0.5c1,0.2,2,0.4,3,0.7
							c3.9,1.1,7.6,2.7,11.5,4.9C104.2,97.6,108.2,100.5,112.7,104.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M105.7,121.9c4.9,3.2,10.2,5.7,15.3,7.5c5.2,1.8,10.2,2.9,14.5,3.4c8.7,1,14.6-0.3,14.7-2c0.1-1.8-5.2-3.6-12.9-6.2
							c-7.7-2.6-17.7-6.1-27.5-10.4c-5.1-2.2-9.2-4.4-12.9-6.6c-3.7-2.2-7-4.4-10.5-6.5C83,99,79.4,97,75.1,95.4
							c-2.1-0.8-4.5-1.5-7-2c-2.5-0.5-5.3-0.6-8.3-0.2c3-0.1,5.7,0.3,8.1,1c2.4,0.7,4.5,1.7,6.5,2.7c3.9,2.1,6.9,4.6,9.8,7.2
							c2.9,2.6,5.7,5.4,9.1,8.4C96.7,115.5,100.6,118.7,105.7,121.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M117.9,121c5,3.9,10.8,6.6,15.4,7.8c4.7,1.2,8.1,1,8.8-0.6c0.7-1.6-1.5-4.2-5.3-7c-3.7-2.7-9-5.6-14.7-7.8
							c-5.8-2.3-9.9-3.9-13.9-5.7c-4-1.8-8-3.8-13.6-7.4c4.9,4.4,8.2,7.5,11.5,10.5C109.5,113.9,112.7,117,117.9,121z"
                      />
                    </g>
                    <g>
                      <path
                        d="M119.5,117c3.7,3.2,8.1,5.3,11.7,6.2c3.6,0.9,6.5,0.5,7.2-1.1c0.8-1.6-0.7-4-3.4-6.4c-2.7-2.4-6.7-4.7-11.1-6.3
							c-4.6-1.6-7.8-2.7-11-3.9c-3.2-1.2-6.4-2.6-10.8-5.1c3.8,3.4,6.2,5.8,8.7,8.3C113.2,111.2,115.6,113.7,119.5,117z"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M46.9,120.2c-4.8-3.3-10.4-5.5-16.3-6.1c-2.9-0.3-5.9-0.2-8.8,0.3c-2.9,0.5-5.8,1.4-8.4,2.9c-2.6,1.5-5.1,3.5-6.9,6.1
						c-0.5,0.7-0.9,1.3-1.2,2c-0.3,0.7-0.7,1.5-0.9,2.2L4,128.7l-0.2,1.1c-0.2,0.8-0.2,1.5-0.3,2.3c-0.1,3,0.5,5.8,1.6,8.3
						c1.1,2.5,2.6,4.6,4.5,6.3c1.8,1.7,4,3,6.2,3.6c2.1,0.7,4.2,1,6.1,0.9c1.9-0.1,3.6-0.4,5.2-0.9c1.5-0.5,2.8-1.1,3.9-1.8
						c2.2-1.3,3.6-2.9,4.3-4c0.7-1.2,0.8-2,0.5-2.2c-0.3-0.3-1.1-0.1-2.2,0.3c-1.1,0.4-2.6,1.1-4.6,1.5c-1,0.2-2,0.4-3.2,0.5
						c-1.1,0.1-2.4,0-3.6-0.2c-1.2-0.2-2.5-0.6-3.6-1.2c-1.2-0.6-2.1-1.4-3-2.4c-1.8-2.1-2.9-5.2-2.6-8.1c0.1-0.3,0.1-0.7,0.2-1
						l0.1-0.5l0.2-0.5c0.1-0.3,0.3-0.6,0.4-0.9c0.2-0.3,0.4-0.6,0.6-0.9c0.9-1.2,2.2-2.2,3.7-3.1c1.6-0.9,3.4-1.5,5.4-1.8
						c2-0.4,4.1-0.5,6.2-0.3c4.2,0.3,8.6,1.6,12.4,3.9c4,2.3,7.2,5.4,9.7,8.7c2.5,3.3,4.3,6.9,5.7,10.4c2.6,7.1,3.3,14.2,2.9,21.3
						c-0.4,7.1-1.8,14.3-4.7,22c-3,7.6-7.4,15.8-14.9,23.8c7.8-7.7,12.8-15.6,16.5-23.2c3.6-7.6,5.7-14.8,6.9-22.3
						c0.6-3.7,0.9-7.5,0.8-11.4c-0.1-3.9-0.5-8-1.6-12.1c-1.1-4.2-2.8-8.5-5.4-12.7C55.4,127.8,51.9,123.6,46.9,120.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M34,153.7c-3,0.6-5.5,2.1-7.5,4c-2,1.9-3.5,4.3-4.3,7c-0.4,1.3-0.6,2.7-0.6,4.1c0,1.4,0.2,2.8,0.7,4.1
						c0.8,2.5,2.4,4.7,4.4,6.1c1,0.7,2,1.2,3.1,1.5c1.1,0.3,2.1,0.4,3,0.4c1.9-0.1,3.4-0.7,4.4-1.5c1-0.8,1.6-1.6,1.8-2.3
						c0.2-0.7,0-1.2-0.3-1.5c-0.7-0.6-1.9-0.4-3.2-0.7c-0.7-0.1-1.4-0.4-2-0.7c-0.3-0.2-0.6-0.4-0.8-0.6c-0.2-0.2-0.4-0.5-0.6-0.8
						c-0.4-0.6-0.7-1.4-0.8-2.3c-0.1-0.4-0.1-0.9,0-1.3c0-0.5,0.2-1,0.3-1.5c0.6-2.1,2.6-4.5,4.7-5.5c1.1-0.5,2.1-0.7,3.3-0.5
						c1.2,0.2,2.3,0.8,3.3,1.6c1,0.8,1.8,1.7,2.5,2.8c0.7,1.1,1.2,2.3,1.6,3.6c0.8,2.6,1,5.5,0.9,8.8c-0.1,3.3-0.7,7-0.7,11.4
						c0.6-4.4,1.7-7.9,2.4-11.2c0.8-3.3,1.3-6.4,1.2-9.6c-0.1-1.6-0.3-3.3-0.8-4.9c-0.5-1.7-1.3-3.4-2.5-5c-1.2-1.6-2.8-3.2-5.1-4.3
						c-1.1-0.6-2.4-1-3.8-1.2C37.1,153.3,35.5,153.4,34,153.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M80.9,129c2-0.8,3.3-2.1,4-3.7c0.7-1.6,0.9-3.4,0.1-5c-0.8-1.5-2.7-2.3-4.8-1.9c-2.1,0.4-4.4,2-5.6,4.5
						c-1.2,2.6-1.6,4.5-1.9,6.4c-0.3,1.9-0.7,3.6-1.1,6.1c1.5-2,2.9-3.1,4.3-4C77.3,130.6,78.8,129.9,80.9,129z"
                    />
                  </g>
                  <g>
                    <path
                      d="M87.6,140.1c1.9,0,3.2-0.8,4.1-2.1c0.9-1.2,1.4-2.9,1-4.7c-0.4-1.7-1.8-3-3.7-3.3c-1.9-0.3-4.2,0.4-5.7,2.3
						c-1.5,2-2.3,3.5-3,5c-0.7,1.5-1.3,2.9-2.3,4.8c1.8-1.2,3.2-1.6,4.7-1.9C84.1,140.2,85.6,140.1,87.6,140.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M69.7,113.9c0.7-3.1,0.1-6.2-1.2-8.3c-1.3-2.1-3.2-3.1-4.9-2.7c-1.7,0.5-2.8,2.2-3.3,4.3c-0.5,2.1-0.3,4.6,0.7,7.2
						c1.1,2.6,1.8,4.6,2.5,6.6c0.6,2,1,4.1,1,7.1c1.1-2.9,1.9-4.9,2.8-6.9C68.1,119.2,69,117.1,69.7,113.9z"
                    />
                  </g>
                  <g>
                    <path d="M51.6,99.8c-0.9-5.8-6-9.3-9.1-7.5c-3,1.8-2.7,7.7,1.3,11.5c4.1,4,6.3,5.3,9.7,10.1C52.1,108.1,52.6,105.7,51.6,99.8z" />
                  </g>
                  <g>
                    <path
                      d="M25.9,96.2c-2.9-2.2-6.5-3.1-9.2-2.9c-2.8,0.2-4.8,1.4-5.1,3.1c-0.3,1.7,0.9,3.6,3,5c2,1.5,4.9,2.6,8.1,2.9
						c3.3,0.4,5.7,0.6,8.1,1.1c2.4,0.5,4.7,1.1,7.9,2.7c-2.6-2.5-4.3-4.2-6.1-6C30.7,100.2,28.9,98.4,25.9,96.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-1.6,103.7c-2.7-0.3-5.2,0.9-6.5,2.5c-1.4,1.7-1.5,3.6-0.6,5.1c1,1.5,2.6,2.2,4.2,2.4c1.6,0.2,3.2-0.2,4.8-1.5
						c1.6-1.3,3-2.3,4.4-3c1.4-0.8,3-1.3,5.4-1.4c-2.1-0.9-3.7-1.6-5.5-2.4C3,104.7,1.2,104-1.6,103.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-13.1,124.3c-2.5,1.7-3.7,4.6-3.5,6.9c0.2,2.4,1.6,3.9,3.3,4.1c1.8,0.2,3.4-0.6,4.6-1.5c1.3-1,2.1-2.2,2.5-4.1
						c0.4-2,0.9-3.5,1.7-5c0.8-1.4,1.9-2.8,4.1-4c-2.5,0.2-4.3,0.5-6.2,0.9C-8.6,122.1-10.6,122.6-13.1,124.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-9.6,149.9c-0.9,2.6-0.3,5.4,1.1,7.1c1.4,1.7,3.3,2.2,4.9,1.5c1.6-0.7,2.7-2.2,3.2-3.7c0.5-1.5,0.5-3.1-0.4-4.8
						c-0.9-1.8-1.5-3.3-1.9-4.9c-0.3-1.6-0.5-3.2,0.1-5.5c-1.6,1.8-2.7,3.2-3.8,4.6C-7.5,145.6-8.6,147.2-9.6,149.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M3.1,168.8c0.4,2.9,2.4,5.2,4.4,6.2c2.1,1,4.1,0.7,5.2-0.6c1.2-1.3,1.4-3.2,1.1-4.9c-0.3-1.7-1.2-3.3-2.9-4.7
						c-1.7-1.4-3-2.6-4.1-3.9c-1.1-1.3-2-2.9-2.7-5.4c-0.4,2.6-0.7,4.4-1,6.4C2.9,163.8,2.7,165.8,3.1,168.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M75.4,229.7c2.3,1.4,4,3.2,4.8,5.2c0.9,2,0.9,4.3,0.3,5.8c-0.6,1.5-2,2.8-3.6,3.4c-0.8,0.3-1.5,0.4-2.3,0.4
						c-0.8,0-1.6-0.2-2.3-0.6c-1.5-0.6-2.7-1.8-3.4-2.9c-0.7-1.1-0.9-2.1-0.8-2.9c0.1-0.8,0.6-1.6,1.1-2c0.4-0.5,0.9-0.8,1-1.2
						c0.1-0.4-0.3-1-1.5-1.3c-1.1-0.2-3,0.1-4.7,1.8c-0.4,0.4-0.8,1-1.1,1.6c-0.3,0.6-0.5,1.2-0.7,1.9c-0.3,1.4-0.3,2.8,0.1,4.3
						c0.7,2.9,2.5,5.8,5.5,7.9c1.5,1,3.3,1.8,5.3,2.3c2,0.4,4.2,0.4,6.3-0.2c2.1-0.5,4.1-1.5,5.9-3c1.8-1.4,3.3-3.4,4.2-5.7
						c0.9-2.3,1.3-4.7,1.1-7c-0.1-2.3-0.8-4.6-1.8-6.7c-1-2.1-2.5-4-4.2-5.5c-1.7-1.5-3.6-2.6-5.6-3.4c-4.1-1.7-8.1-2-11.5-1.8
						c-3.5,0.2-6.5,0.9-9.3,1.7c-5.5,1.7-10.2,3.7-14.6,6.1c-4.5,2.5-8.8,5.2-13,9c-2.1,1.9-4.3,4-6.3,6.4c-2.1,2.5-4.1,5.2-5.8,8.5
						c2-3.1,4.2-5.7,6.4-7.9c2.2-2.2,4.5-4.1,6.8-5.6c4.5-3.2,9-5.2,13.5-6.9c4.5-1.7,9.2-2.8,14.4-3.6c2.6-0.3,5.2-0.5,7.9-0.3
						c1.3,0.1,2.7,0.3,4,0.7C72.9,228.5,74.2,229,75.4,229.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M55.5,215c4.5-2.5,8.1-6.3,10.1-9.7c2-3.4,2.4-6.3,1-7.5c-1.4-1.1-4.1-0.2-7.1,1.7c-3.1,2-6.5,5-9.6,8.6
						c-3.2,3.8-5.4,6.3-7.9,8.8c-2.5,2.5-5.2,4.8-9.6,7.6c4.8-1.8,8.2-3.1,11.7-4.4C47.5,218.9,50.9,217.5,55.5,215z"
                    />
                  </g>
                  <g>
                    <path
                      d="M75.4,216.2c2.3,0.5,4.3,0.1,5.9-0.8c1.6-1,2.7-2.5,2.7-4.3c0-1.8-1.2-3.3-3.1-4.1c-1.9-0.8-4.5-0.8-6.8,0.5
						c-2.4,1.3-3.8,2.5-5.3,3.6c-1.5,1.1-2.9,2.2-5.1,3.5c2.5-0.3,4.3-0.1,6,0.2C71.3,215.2,73,215.7,75.4,216.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M35.3,208.6c0.9-2.4,1.3-5,1.3-7.4c0-0.6,0-1.2-0.1-1.8c-0.1-0.6-0.1-1.1-0.2-1.7c-0.2-1.2-0.6-2.3-1.1-3.4
						c-0.5-1.1-1.1-2-1.9-2.8c-0.7-0.8-1.6-1.3-2.3-1.6c-1.6-0.5-2.6,0-3,0.7c-0.4,0.8-0.3,1.8-0.5,2.6c-0.1,0.9-0.3,1.8-0.4,3
						c-0.1,0.7-0.1,1.4-0.2,2.1c0,0.4-0.1,0.9-0.1,1.3c0,0.5,0,0.9-0.1,1.4c-0.1,1.9-0.1,3.9,0,6c0.1,4.3-0.2,7.5-0.8,10.6
						c-0.7,3.1-1.8,6.3-4.7,10.3c3.7-3.3,5.9-5.9,8.1-8.7c1.1-1.4,2.1-2.8,3.1-4.5C33.4,213,34.4,211.1,35.3,208.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M124.7,53.8c3.3-0.9,6.7-1.7,9-1.4c1.2,0.1,2,0.4,2.7,0.9c0.6,0.4,1.2,1.1,1.6,2c0.9,1.8,1,4.3,0.5,6.2
						c-0.1,0.5-0.3,1-0.5,1.5c-0.2,0.5-0.5,0.9-0.7,1.4c-0.5,0.8-1.2,1.5-1.8,2.1c-1.2,1-2.9,1.5-4.2,1.4c-0.7,0-1.3-0.2-1.8-0.4
						c-0.5-0.3-1-0.6-1.4-1c-1.7-1.6-1.8-3.6-2.6-3.9c-0.4-0.1-1,0.2-1.5,1.2c-0.5,1-0.8,2.8-0.1,4.9c0.3,1,0.9,2.2,1.9,3.2
						c1,1.1,2.2,2,3.8,2.6c3,1.2,6.9,1.2,10.4-0.7c1.7-0.9,3.3-2.2,4.6-3.7c0.7-0.8,1.2-1.6,1.8-2.5c0.5-0.9,1-1.8,1.4-2.9
						c0.8-2.1,1.1-4.3,1.1-6.5c-0.1-2.3-0.5-4.6-1.5-6.9c-1.1-2.3-2.8-4.5-5.2-6c-2.4-1.5-5.1-2.2-7.6-2.3c-2.5-0.1-4.7,0.3-6.8,0.9
						c-2.1,0.6-3.9,1.2-5.7,2c-3.8,1.6-7.6,3.8-10.3,7c-1.3,1.6-2.3,3.5-2.9,5.3c-0.6,1.8-0.9,3.6-0.9,5.3c-0.1,3.4,0.5,6.4,1.3,9.2
						c0.9,2.8,2,5.4,3.4,7.8c2.8,4.8,6.6,9.1,11.7,12.4c2.6,1.7,5.5,3.1,8.7,4.2c3.3,1.1,6.9,1.7,10.9,1.8
						c-3.9-0.3-7.5-1.3-10.6-2.6c-3.1-1.3-5.7-3-8-4.8c-4.5-3.7-7.5-8.1-9.4-12.7c-1-2.3-1.6-4.7-2-7.2c-0.4-2.5-0.5-5,0-7.5
						c0.2-1.2,0.6-2.4,1.1-3.4c0.5-1,1.2-2,2.1-2.8C118.7,56,121.3,54.8,124.7,53.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M86,72c-3-1.1-6.3-0.6-8.4,0.7c-2.1,1.3-3.1,3.2-2.6,4.9c0.5,1.7,2.2,2.8,4,3.3c1.9,0.6,4,0.6,6.4-0.2
						c2.5-0.8,4.3-1.2,6.3-1.4c1.9-0.2,4-0.2,6.8,0.7c-2.3-1.8-4-3.1-5.7-4.3C90.9,74.4,89.1,73.1,86,72z"
                    />
                  </g>
                  <g>
                    <path
                      d="M99.5,62.1c0.8-1.3,0.8-2.3,0.3-3.6c-0.5-1.2-1.3-2.8-2.7-3.8c-0.7-0.5-1.6-0.7-2.5-0.6c-0.9,0.1-1.8,0.5-2.6,1.3
						c-0.8,0.8-1.4,1.9-1.7,3.2c-0.3,1.3-0.2,2.8,0.4,4.1c1.2,2.8,2.7,4.1,4.1,5.3c1.4,1.1,2.9,2,4.9,2.9c-1.3-1.8-1.6-3.3-1.5-4.7
						C98.2,64.8,98.7,63.5,99.5,62.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M152.6,18.3c3.1-2.4,6-4.9,8.2-6.9c2.2-2,3.6-3.6,3.1-4.4c-0.4-0.8-2.6-0.5-5.5,1c-2.8,1.4-6.3,4-8.9,7.3
						c-2.7,3.4-4.2,5.9-5.6,8.5c-1.4,2.6-2.7,5.2-4.4,9.2c2.2-3.7,4-6,6-8.1C147.4,22.8,149.4,20.8,152.6,18.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M161.5,23.3c3.2-1.8,6.3-3.8,8.5-5.5c2.2-1.7,3.6-3.2,3.2-4c-0.4-0.8-2.5-0.7-5.2,0.3c-2.8,1-6.2,3-9.1,5.6
						c-2.9,2.7-4.7,4.8-6.5,6.9c-1.7,2.1-3.5,4.3-5.9,7.5c2.8-2.9,5-4.6,7.2-6.1C155.9,26.6,158.1,25.2,161.5,23.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M160,33.4c2.1-0.6,3.9-1.6,5.2-2.7c1.3-1,2-2.1,1.7-3c-0.3-0.8-1.6-1.2-3.4-0.9c-1.7,0.3-3.9,1.2-5.7,2.7
						c-1.8,1.6-2.9,2.8-4,4c-1.1,1.2-2.2,2.4-3.9,4.1c2-1.3,3.5-1.9,4.9-2.5C156.3,34.6,157.8,34.1,160,33.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M207.1,151.4c1.6-3.8,3.4-7.5,4.8-10.2c1.4-2.8,2.4-4.8,1.8-5.4c-0.6-0.6-2.8,0.4-5.2,3c-2.4,2.6-4.7,6.8-5.6,11.4
						c-0.8,4.7-0.7,8-0.3,11.2c0.4,3.2,1,6.4,2.6,10.8c-1.1-4.5-1.1-7.7-0.7-10.8C204.8,158.3,205.5,155.3,207.1,151.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M186.8,193.3c-0.7,3.1-1.2,5.4-1.8,8c-0.5,2.2-0.7,3.9,0,4.4c0.7,0.5,2.3-0.5,3.8-2.8c1.4-2.2,2.6-5.8,2.3-9.3
						c-0.2-3.6-1-6-1.8-8.2c-0.9-2.2-1.9-4.4-3.8-7.3c1.5,3.1,1.8,5.5,2,7.7C187.6,188,187.5,190.3,186.8,193.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M166,263.2c-0.7,3.5-2,6.9-3.1,9.4c-1.1,2.5-2,4.4-1.3,5c0.6,0.6,2.7-0.2,4.9-2.8c1.1-1.3,2.1-2.9,2.8-4.9
						c0.7-1.9,1.2-4.2,1.1-6.4c0-2.3-0.4-4.1-0.9-5.8c-0.5-1.6-1-3-1.7-4.3c-1.4-2.6-3-5.2-6.2-8.1c2.8,3.3,3.8,6,4.4,8.7
						c0.3,1.3,0.4,2.7,0.5,4.2C166.5,259.8,166.4,261.4,166,263.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M249.5,317.1c1.2-0.7,2.4-1.2,3.5-1.4c0.6-0.1,1.1-0.1,1.6-0.1c0.5,0,1,0.2,1.5,0.3c2,0.7,4,2.6,5.2,4.8
						c0.3,0.5,0.5,1.1,0.8,1.7c0.2,0.6,0.4,1.2,0.5,1.8c0.2,1.2,0.3,2.3,0.2,3.3c-0.2,1.9-1.3,3.4-2.5,4.1c-0.1,0.1-0.3,0.2-0.4,0.2
						c-0.1,0.1-0.3,0.1-0.4,0.2c-0.3,0.1-0.6,0.1-0.9,0.2c-0.7,0-1.3-0.1-1.8-0.4c-0.5-0.2-0.9-0.6-1.2-0.9
						c-0.3-0.4-0.5-0.9-0.5-1.4c-0.1-1.1,0.1-2,0.4-2.8c0.3-0.7,0.6-1.3,0.4-1.7c-0.1-0.4-0.8-0.7-2-0.3c-1.1,0.4-2.7,1.6-3.5,3.8
						c-0.4,1.1-0.6,2.5-0.3,4.1c0.3,1.6,1.1,3.2,2.4,4.5c1.3,1.3,3,2.2,4.9,2.7c1,0.2,2,0.3,3.1,0.3c0.5,0,1.1-0.1,1.7-0.2
						c0.5-0.1,1.1-0.3,1.6-0.4c4.2-1.5,7.4-5.3,8.5-10c0.5-2.3,0.6-4.6,0.2-6.8c-0.2-1.1-0.4-2.2-0.8-3.3c-0.4-1.1-0.8-2.2-1.4-3.2
						c-1.1-2.1-2.5-4-4.4-5.6c-1.8-1.6-4.1-3-6.7-3.8c-2.6-0.8-5.5-0.8-8.1-0.1c-2.6,0.7-4.8,2-6.7,3.5c-3.8,3.3-5.9,7.3-7.1,11
						c-1.1,3.8-1.4,7.4-1.1,10.7c0.2,3.3,1,6.4,1.9,9.1c1,2.8,2.1,5.4,3.5,7.8c1.3,2.5,2.7,4.9,4.3,7.3c1.5,2.4,3.1,4.8,4.9,7.4
						c3.6,5,7.8,10.5,13.5,16.3c-5.3-6.2-9-11.9-12.1-17.2c-1.5-2.7-2.8-5.2-4-7.8c-1.2-2.5-2.3-5-3.2-7.5c-0.9-2.5-1.7-5-2.2-7.6
						c-0.5-2.6-0.7-5.2-0.6-7.9c0.2-2.7,0.8-5.5,2-8.1c0.6-1.3,1.3-2.5,2.2-3.7C247.3,318.8,248.3,317.8,249.5,317.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M264.6,361c0.7-2.6,1-4.8,0.9-6.9c-0.1-2.1-0.6-4.1-2-5.2c-0.7-0.5-1.7-0.6-2.7-0.3c-1,0.3-2.1,1.1-3.1,2.3
						c-1,1.2-1.7,2.8-2.1,4.7c-0.4,1.9-0.3,3.9,0.3,5.9c0.6,2,1.4,3.6,2.2,4.9c0.8,1.3,1.7,2.3,2.6,3.3c1.8,1.9,3.7,3.6,6.7,5.5
						c-2.3-2.7-3-5-3.3-7.2C263.8,365.9,263.9,363.7,264.6,361z"
                    />
                  </g>
                  <g>
                    <path
                      d="M284.7,370c-3.2,6-5.2,12.1-7.6,17c-1.2,2.5-2.4,4.7-3.7,6.6c-0.7,0.9-1.4,1.8-2.1,2.6c-0.7,0.8-1.4,1.4-2.1,2
						c-1.4,1.1-2.5,1.5-3.5,1.7c-0.3,0.1-0.8,0-1.2-0.1c-0.5-0.2-0.9-0.4-1.2-0.7c-0.7-0.5-1.1-1.1-1.5-1.7
						c-0.3-0.6-0.5-1.1-0.9-1.3c-0.2-0.1-0.4-0.1-0.7,0c-0.3,0.1-0.6,0.4-0.9,0.8c-0.6,0.9-1,2.7-0.3,4.6c0.3,1,0.9,1.9,1.8,2.8
						c0.9,0.9,2.1,1.8,3.7,2.2c1.4,0.4,3,0.5,4.6,0.3c1.6-0.2,3.1-0.7,4.5-1.4c1.4-0.7,2.7-1.6,3.9-2.6c1.2-1,2.3-2.1,3.4-3.3
						c2.1-2.4,3.9-5.2,5.3-8.2c2.9-5.9,4.6-12.1,6.6-17.7c1.1-2.9,2.2-5.3,3.6-7.5c1.4-2.2,2.8-4.2,4.4-5.9c0.7-0.9,1.6-1.7,2.4-2.5
						c0.9-0.7,1.7-1.5,2.5-2.2c0.4-0.3,0.9-0.7,1.3-1c0.4-0.3,0.9-0.6,1.3-0.9c0.9-0.6,1.8-1.2,2.8-1.7c3.8-2.2,8-3.8,13-4.7
						c1.2-0.2,2.5-0.4,3.9-0.4c1.3-0.1,2.7-0.1,4.1,0.1c2.8,0.3,5.9,1.2,8.4,3.6c-2.3-2.6-5.4-3.8-8.3-4.4c-1.5-0.3-2.9-0.5-4.3-0.6
						c-1.4-0.1-2.7,0-4.1,0c-5.2,0.3-9.9,1.3-14.4,3c-1.1,0.4-2.2,0.9-3.3,1.4c-0.5,0.3-1.1,0.5-1.6,0.8c-0.5,0.3-1.1,0.6-1.6,0.9
						c-1.1,0.6-2.1,1.3-3.2,2c-1,0.8-2.1,1.5-3.2,2.3c-2.1,1.7-4.2,3.7-6.2,6C288.2,364.1,286.3,366.9,284.7,370z"
                    />
                  </g>
                  <g>
                    <path
                      d="M311.3,353.5c-2.3,1-4.3,2.4-5.9,4.2c-1.6,1.7-2.9,3.8-3.4,5.9c-0.6,2.1-0.3,4.2,0.4,5.5c0.7,1.3,1.7,1.7,2.5,1.5
						c0.9-0.2,1.6-0.8,2.3-1.4c0.7-0.6,1.5-1.2,2.3-1.9c0.9-0.7,1.8-1.6,3-2.7c1.2-1.1,2.4-2.4,3.6-3.7c2.6-2.8,4.9-4.5,7.5-5.8
						c1.3-0.6,2.7-1.3,4.3-1.7c1.6-0.4,3.5-0.7,5.7-0.7c-2.2-0.6-4.1-0.9-5.9-1.1c-1.8-0.2-3.4-0.3-5-0.3c-1.6,0-3.3,0.1-5.1,0.4
						C315.7,352,313.7,352.5,311.3,353.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M333.5,332.9c0.1-2-0.4-3.5-1.3-5c-0.9-1.4-2.2-2.8-3.9-3.1c-0.9-0.2-1.7,0-2.5,0.5c-0.8,0.5-1.5,1.4-1.9,2.6
						c-0.4,1.2-0.6,2.6-0.4,4.2c0.3,1.5,0.9,3.1,2,4.3c2.2,2.6,4.3,3.7,6.2,4.6c1.9,0.9,3.9,1.5,6.5,2.1c-2.2-1.6-3.2-3.2-3.8-4.8
						C333.7,336.7,333.4,335,333.5,332.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M523.9,354.8c0.8-4,0.7-8.3-0.4-12.2c-1-3.9-2.9-7.6-5.4-10.8c-2.5-3.2-5.7-5.8-9.2-7.6c-3.5-1.8-7.3-3-11-3.4
						c-3.8-0.4-7.6-0.2-11.2,0.8c-3.6,0.9-7.2,2.6-10.1,5.5c-1.4,1.4-2.6,3.2-3.4,5c-0.8,1.8-1.3,3.7-1.4,5.5
						c-0.3,3.7,0.5,7.3,2.3,10.3c1.8,3,4.4,5.2,7.1,6.5c2.7,1.3,5.5,1.8,8.1,1.8c2.6-0.1,5-0.8,7-1.8c2-1,3.5-2.4,4.6-3.7
						c2.3-2.7,3.1-5.3,3.4-7.1c0.2-1.8-0.1-2.8-0.5-2.8c-0.5-0.1-1,0.7-1.9,1.9c-0.9,1.2-2.2,3-4.3,4.5c-1,0.7-2.3,1.4-3.7,1.8
						c-1.4,0.4-2.9,0.6-4.5,0.4c-1.6-0.2-3.2-0.7-4.7-1.7c-1.5-0.9-2.7-2.2-3.4-3.7c-0.7-1.5-1-3.5-0.7-5.3c0.1-0.9,0.4-1.8,0.8-2.6
						c0.4-0.8,0.9-1.4,1.6-2.1c2.8-2.6,8.4-3.9,13.5-3.2c5.3,0.6,10.4,3.1,13.9,7.3c3.5,4.2,5.4,9.9,4.7,15.4
						c-0.8,5.7-4.1,10.7-8.1,14.1c-4.1,3.4-9,5.5-13.8,6.4c-4.9,1-9.8,1-14.5,0.4c-4.7-0.6-9.3-1.9-13.8-3.5
						c-4.5-1.6-8.9-3.6-13.5-5.8c-4.5-2.2-9.2-4.6-14.3-6.8c-5.1-2.2-10.8-4.1-17.1-4.8c-3.1-0.3-6.4-0.3-9.8,0.1
						c-1.7,0.3-3.4,0.7-5,1.2c-1.7,0.6-3.3,1.3-4.9,2.2c1.6-0.8,3.3-1.5,5-2c1.7-0.5,3.4-0.8,5-1c3.3-0.3,6.6-0.2,9.6,0.3
						c6.2,0.9,11.5,3.2,16.4,5.6c4.9,2.4,9.4,5.1,13.8,7.7c4.4,2.5,8.8,4.9,13.3,7c4.6,2,9.3,3.8,14.4,4.8
						c5.1,1.1,10.6,1.5,16.4,0.8c5.7-0.7,11.9-2.6,17.5-6.7c2.7-2,5.3-4.5,7.5-7.6C521.4,362.6,523.1,358.9,523.9,354.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M447.8,308.1c-3.2-3.2-7.6-5.5-12.3-6.1c-4.7-0.7-9.8,0.3-13.9,3.4c-2,1.5-3.5,3.4-4.6,5.6c-1.1,2.1-1.7,4.5-1.7,7
						c0,0.6,0.1,1.2,0.1,1.8c0.1,0.6,0.2,1.2,0.4,1.8c0.3,1.2,0.9,2.2,1.4,3.2c1.2,1.9,2.7,3.4,4.5,4.5c1.8,1.1,3.7,1.7,5.7,1.8
						c1.9,0.1,3.9-0.4,5.4-1.3c1.5-0.9,2.6-2,3.3-3.1c0.8-1.1,1.2-2.3,1.5-3.3c0.5-2.1,0-3.9-0.6-4.9c-0.3-0.5-0.6-0.8-0.9-1
						c-0.3-0.2-0.5-0.2-0.7-0.1c-0.2,0.1-0.4,0.3-0.5,0.6c-0.1,0.3-0.2,0.6-0.4,1c-0.3,0.8-0.6,1.7-1.4,2.6c-0.7,0.9-1.9,1.8-3.1,2
						c-1.1,0.2-2.3-0.1-3.5-1.2c-0.6-0.5-1.1-1.2-1.4-2c-0.1-0.4-0.3-0.7-0.3-1.1c0-0.2,0-0.3,0-0.5c0-0.2,0-0.4,0-0.6
						c0.1-1.6,1.2-3.6,2.9-4.8c1.6-1.2,4-1.9,6.7-1.6c2.7,0.2,5.3,1.3,7.7,3.1c2.4,1.8,4.2,4.3,5.3,6.8c1.1,2.5,1.5,5.1,1.3,7.6
						c-0.3,5-2.7,9.6-6.2,12.9c-3.5,3.3-8.6,5.3-14.4,5.7c-5.9,0.5-12.6-0.9-20.1-3.5c7.3,3.1,13.9,5,20.1,5.2
						c6.2,0.2,12-1.1,16.8-4.5c2.4-1.7,4.5-3.8,6.2-6.3c1.7-2.5,3.1-5.4,3.7-8.8C456.1,323.3,454.5,314.8,447.8,308.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M458.9,328.3c-2.4,2.5-3.5,5.9-3.3,8.5c0.2,2.7,1.6,4.5,3.3,4.7c1.8,0.2,3.4-0.9,4.8-2.5c1.3-1.6,2.3-3.7,2.8-6.3
						c0.5-2.7,1-4.7,1.8-6.7c0.8-1.9,1.8-3.8,4.1-6.1c-2.9,1.3-4.9,2.4-6.9,3.5C463.4,324.6,461.4,325.8,458.9,328.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M463.1,362.7c0.5,3.2,2.7,5.9,5,6.9c2.3,1.1,4.4,0.7,5.5-0.7c1-1.4,1-3.4,0.6-5.1c-0.5-1.8-1.4-3.3-3.1-4.8
						c-1.7-1.6-3-3-3.9-4.5c-1-1.6-1.8-3.4-1.9-6.2c-0.9,2.7-1.4,4.7-1.9,6.8C462.9,357.2,462.6,359.4,463.1,362.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M490.6,369c1.7,0.6,3.5,0.7,5.2,0.5c1.7-0.3,3.1-0.9,4.2-1.7c2.3-1.7,2.9-3.9,2-5.4c-0.9-1.5-2.7-2.2-4.6-2.6
						c-1.9-0.3-3.8-0.2-6.1,0.5c-2.3,0.7-4.3,0.9-6.3,0.8c-2-0.2-4-0.7-6.5-2.5c1.7,2.6,3.2,4.3,4.9,5.9
						C485.2,366,487.1,367.7,490.6,369z"
                    />
                  </g>
                  <g>
                    <path
                      d="M448.5,349.2c-1.6-0.6-2.7-0.5-4,0.1c-1.3,0.6-2.7,1.6-3.5,3.2c-0.4,0.8-0.5,1.7-0.2,2.5c0.2,0.9,0.8,1.7,1.7,2.4
						c0.9,0.7,2.1,1.2,3.5,1.3c1.3,0.1,2.8-0.1,4.1-0.8c2.7-1.5,3.9-3.1,5-4.5c1.1-1.5,1.9-3.1,2.9-5.2c-1.8,1.5-3.4,1.9-4.9,1.9
						C451.6,350.1,450.2,349.9,448.5,349.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M441,389.9c-1.2,1.3-3,2.2-4.8,2.4c-1.7,0.3-3.6,0-5.4-0.8c-1.8-0.7-3.5-2-4.7-3.3c-1.2-1.3-1.9-2.6-2-3.5
						c-0.2-1,0.1-2.1,0.4-2.7c0.3-0.6,0.9-1,1.7-1.1c0.8-0.1,1.5,0,2.1,0.2c0.6,0.2,1.1,0.4,1.5,0.3c0.4-0.1,0.8-0.6,0.6-1.7
						c-0.2-1-1-2.4-2.7-3.4c-0.8-0.5-1.9-0.8-3.2-0.9c-1.3-0.1-2.7,0.3-4,1.1c-2.7,1.6-4.5,4.5-4.8,8c-0.3,3.6,1.1,7,3.1,9.7
						c2,2.7,4.7,4.9,8,6.4c3.3,1.5,7.3,2.2,11.4,1.2c3.9-0.9,7.6-3.3,9.9-6.8c0.6-0.9,1.1-1.9,1.4-2.9c0.4-1,0.6-2,0.7-2.9
						c0.3-1.9,0.2-3.7-0.1-5.3c-0.6-3.2-1.9-5.9-3.3-8.1c-1.5-2.2-3.2-4.1-5-5.6c-1.8-1.5-3.7-2.8-5.7-3.8c-4-2.1-8.4-3.3-13.2-3.8
						c-4.9-0.5-10.3-0.3-16.5,0.5c12.6-0.7,21.4,1.7,27.8,6.5c3.2,2.4,5.8,5.4,7.4,9.2c0.8,1.9,1.3,3.9,1.3,5.9
						C442.8,386.8,442.3,388.7,441,389.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M457.3,391.4c0,2.2,1.1,3.9,2.5,4.7c1.5,0.9,3.3,0.9,4.8,0c1.5-0.9,2.4-2.4,2.6-4c0.2-1.5-0.4-3.1-1.9-4.3
						c-1.5-1.2-2.7-2.1-3.9-3c-1.1-0.9-2.1-2-3.1-3.7c0,2-0.2,3.5-0.4,5C457.7,387.6,457.4,389.2,457.3,391.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M431.7,410.6c-2.8,1.3-4.6,3.9-5.2,6.2c-0.6,2.3,0.1,4.3,1.6,5.2c1.5,0.9,3.5,0.6,5.2-0.3c1.7-0.9,3.3-2.4,4.4-4.7
						c1.1-2.3,2-4,3.1-5.6c1.1-1.6,2.4-3.1,4.8-4.7c-2.7,0.6-4.7,1-6.8,1.5C436.6,408.7,434.5,409.2,431.7,410.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M389.6,226.7c1.7,0.3,3.4,0.1,5-0.1c1.5-0.3,2.9-0.8,4-1.3c2.2-1.1,3.4-2.3,3.2-3.1c-0.3-0.9-2-1.1-4.3-0.9
						c-2.3,0.2-5.1,0.7-7.8,1.1c-2.8,0.4-4.9,0.2-6.8-0.5c-1-0.3-1.9-0.8-2.9-1.6c-0.9-0.7-1.9-1.8-2.6-3.3c0.4,1.6,1.1,2.9,1.9,3.9
						c0.8,1,1.7,1.9,2.6,2.6c0.9,0.7,2,1.4,3.2,1.9C386.4,226,387.9,226.5,389.6,226.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M392.5,238.2c1.9,2.5,3.7,4.8,5.3,6.6c1.6,1.7,2.9,2.9,3.7,2.5c0.8-0.3,0.8-2.2-0.1-4.7c-1-2.4-3-5.4-5.8-7.5
						c-2.9-2.2-5-3.2-7.2-4.2c-2.2-1-4.4-1.9-7.8-2.8c3.1,1.5,5,2.9,6.7,4.4C389.1,234,390.6,235.6,392.5,238.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M351.7,217.7c-0.7,2.4-1.3,4.7-1.7,6.6c-0.4,1.9-0.5,3.4,0.2,3.9c0.7,0.5,2.2-0.2,3.6-2.1c1.4-1.8,2.4-4.8,2.3-7.9
						c-0.2-3.1-0.8-5.1-1.4-7.1c-0.7-1.9-1.5-3.8-3.1-6.3c1,2.7,1.2,4.7,1.2,6.6C352.7,213.3,352.5,215.2,351.7,217.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M446.3,284.3c3.7-0.6,7.2-2.4,9.4-4.3c2.2-1.9,3-3.7,2.5-4.4c-0.6-0.7-2.4-0.1-4.7,0.8c-2.3,1-5.3,2.3-8.3,3.6
						c-3.2,1.4-5.5,2-7.9,2.5c-2.4,0.4-5,0.7-8.7,0.1c3.6,1.1,6.2,1.5,8.8,1.8C439.8,284.8,442.4,284.9,446.3,284.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M455.1,296.1c2.5,0.2,5-0.7,6.5-1.9c1.5-1.2,2-2.6,1.4-3.3c-0.6-0.7-1.8-0.7-3.3-0.4c-1.5,0.2-3.2,0.7-5.1,1.3
						c-1.9,0.6-3.4,0.9-4.9,0.9c-1.5,0-3.1-0.1-5.3-1.1c1.9,1.4,3.3,2.3,4.9,3C450.8,295.2,452.5,295.8,455.1,296.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M512.7,278.9c-0.8,1.7-2.2,3.2-3.7,4.4c-1.5,1.2-3.3,2.3-4.8,3.4c-1.5,1.1-2.8,2.1-3.5,3c-0.8,0.9-1,1.6-0.8,2
						c0.3,0.4,1,0.3,2,0.1c1.1-0.2,2.5-0.7,4.2-1.4c1.7-0.7,3.8-1.6,5.9-3.2c2-1.6,4-3.9,4.8-6.9c0.4-1.5,0.4-3,0.2-4.4
						c-0.3-1.3-0.8-2.5-1.4-3.4c-1.2-1.9-2.8-3.1-4.4-4c-3.3-1.8-7-2.2-12.2-2.6c2.6,0.5,4.7,0.9,6.5,1.6c1.8,0.7,3.3,1.5,4.5,2.6
						c1.2,1.1,2.2,2.4,2.8,3.9C513.5,275.5,513.5,277.2,512.7,278.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M421,118.9c2.1-3.8,2.7-8.3,2.3-11.5c-0.4-3.3-1.6-5.2-2.4-5c-0.9,0.2-1.3,2.2-1.8,4.9c-0.5,2.8-1.2,6.3-2.2,9.9
						c-1.1,3.7-2.3,6.2-3.8,8.5c-1.5,2.3-3.4,4.6-6.9,6.9c3.8-1.9,6.2-3.7,8.4-5.7C416.7,125,418.8,122.8,421,118.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M423,162.8c5.4,0.3,10.9,0.4,15,0.3c4.1-0.1,6.9-0.6,7-1.5c0.1-0.9-2.6-2-6.7-2.7c-4.2-0.7-9.8-1.1-15.4-0.5
						c-5.7,0.6-9.6,1.3-13.5,2.1c-3.9,0.8-7.8,1.6-13.5,3c5.8-0.8,9.8-1,13.7-1.1C413.6,162.4,417.4,162.5,423,162.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M428.4,172.9c6.4,1.8,13.2,1.4,13.5-0.3c0.2-1.7-6.2-4-12.9-4c-6.9,0-9.5,0.3-16.5-0.1
						C419.4,169.9,421.8,171,428.4,172.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M465.2,185.4c3.3-1.4,6.4-3,8.7-4.4c2.3-1.4,3.8-2.7,3.5-3.5c-0.3-0.8-2.3-1-5.1-0.3c-2.8,0.7-6.3,2.2-9.3,4.5
						c-3.1,2.4-5,4.3-6.8,6.1c-1.8,1.9-3.7,3.9-6.2,6.8c2.9-2.6,5.1-4,7.4-5.3C459.5,188,461.8,186.9,465.2,185.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M479.4,189.5c3.5-0.2,7-0.5,9.6-1c2.6-0.4,4.5-1,4.6-1.9c0.1-0.9-1.7-1.8-4.6-2.3c-2.8-0.5-6.7-0.3-10.4,0.8
						c-3.7,1.2-6.2,2.3-8.5,3.5c-2.4,1.2-4.7,2.5-8,4.6c3.5-1.6,6.1-2.3,8.6-2.8C473.3,190.1,475.8,189.7,479.4,189.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M496,177.4c5.3-1.4,9.4-5.2,8.5-6.8c-0.9-1.5-6-0.2-10.3,2.8c-2.2,1.5-3.8,2.6-5.3,3.7c-1.6,1-3.2,2-5.8,3.2
						c2.7-0.7,4.6-1.1,6.5-1.5C491.4,178.5,493.3,178.1,496,177.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M508.6,181.3c2.3,0.5,4.6,0.6,6.3,0.4c1.8-0.2,3.1-0.7,3.3-1.5c0.2-0.9-0.9-1.9-2.7-2.6c-1.8-0.7-4.4-1.1-6.9-0.6
						c-2.6,0.4-4.3,1-6,1.5c-1.8,0.6-3.6,1.2-5.8,2c2.6-0.3,4.3-0.3,6-0.1C504.5,180.5,506.2,180.8,508.6,181.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M515.5,191.4c5.5,1.8,11.5,1.5,11.8-0.3c0.3-1.7-5.2-4-11-4.1c-6-0.1-8.3,0.3-14.4-0.2
						C507.8,188.4,509.8,189.5,515.5,191.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M537.8,184.7c2.8,0.1,5.6-0.6,7.4-1.6c1.9-1,2.9-2.2,2.6-3c-0.3-0.8-1.8-1.1-3.7-1c-2,0.1-4.4,0.5-6.9,1.3
						c-2.5,0.8-4.3,1.3-6.2,1.7c-1.9,0.4-3.8,0.6-6.6,0.6c2.8,0.6,4.7,1,6.6,1.3C533,184.3,535,184.6,537.8,184.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M537.5,176.3c2.8-0.1,5.5-1.1,7.4-2.2c1.8-1.1,2.8-2.4,2.4-3.2c-0.4-0.8-1.9-1-3.8-0.7c-2,0.2-4.4,0.9-6.9,1.9
						c-2.5,1-4.3,1.6-6.1,2.2c-1.8,0.5-3.7,1-6.6,1.2c2.9,0.3,4.8,0.5,6.8,0.7C532.6,176.2,534.6,176.4,537.5,176.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M535.8,166.5c2.7-0.6,5.2-1.8,6.8-3c1.7-1.3,2.5-2.6,2.1-3.4c-0.8-1.6-6.1-0.4-10.6,2.3c-2.3,1.4-4,2.4-5.6,3.4
						c-1.7,0.9-3.4,1.8-6.1,2.9c2.8-0.5,4.7-0.8,6.7-1C531.1,167.3,533,167.1,535.8,166.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M557.3,160.8c1.9-0.2,3.7-0.8,5-1.5c1.3-0.7,2.2-1.5,2.2-2.4c-0.1-0.9-1.2-1.6-2.9-1.7c-1.7-0.2-3.9,0.3-5.8,1.5
						c-1.9,1.2-3.1,2.3-4.2,3.4c-1.1,1.1-2.2,2.1-3.7,3.7c1.8-1.2,3.2-1.7,4.6-2.1C553.9,161.3,555.3,161,557.3,160.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M366.2,478.6c3.4-1,6.8-2.1,9.3-3.2c2.5-1.1,4.2-2.1,4-3c-0.1-0.9-2.1-1.3-5-1.1c-2.9,0.3-6.7,1.3-10,3.2
						c-3.4,2-5.6,3.6-7.7,5.3c-2.1,1.7-4.2,3.4-7,6.1c3.2-2.3,5.6-3.5,8-4.5C360.2,480.5,362.6,479.6,366.2,478.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M377.5,483.6c2.3,0.1,4.5-0.2,6.2-0.7c1.7-0.5,2.9-1.2,2.9-2.1c0-0.9-1.2-1.7-3.1-2.1c-1.9-0.4-4.4-0.3-6.8,0.6
						c-2.4,0.9-4,1.7-5.6,2.5c-1.7,0.9-3.3,1.8-5.3,2.9c2.4-0.7,4.2-1,5.9-1.1C373.5,483.5,375.1,483.5,377.5,483.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M400.9,489.4c2.5,0.8,5,1.3,7,1.3c2,0.1,3.5-0.3,3.7-1.1c0.3-0.8-0.8-2-2.7-2.9c-1.9-0.9-4.6-1.6-7.4-1.6
						c-2.9,0-4.8,0.3-6.8,0.5c-2.1,0.2-4.1,0.5-6.7,0.8c2.8,0.2,4.7,0.6,6.6,1.1C396.6,487.9,398.4,488.5,400.9,489.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M404,480.2c5.8,1.9,12.1,1.7,12.4,0c0.3-1.7-5.5-4.1-11.6-4.3c-6.3-0.2-8.7,0.1-15.2-0.5
						C395.8,477.1,398,478.2,404,480.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M405.2,471.3c1.9,0,3.8-0.8,4.9-1.8c1.1-1,1.6-2.1,1.2-2.9c-0.4-0.8-1.5-1.1-2.8-1c-1.3,0.1-2.9,0.5-4.3,1.4
						c-1.5,0.9-2.6,1.5-3.8,2c-1.2,0.5-2.4,0.9-4.3,1.2c1.7,0.3,3,0.5,4.5,0.7C401.8,471.2,403.2,471.4,405.2,471.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M326.4,598.7c3.6-0.2,7.2-0.8,10.9-1.8c3.6-1,7.3-2.7,10.5-5.2c1.6-1.3,3.1-2.8,4.3-4.4c1.2-1.7,2.2-3.5,3-5.4
						c1.5-3.8,2.1-7.8,1.7-11.8c-0.2-2-0.7-3.9-1.4-5.8c-0.7-1.9-1.7-3.6-2.8-5.1c-2.2-3.1-5.1-5.5-8.2-7.3c-1.6-0.8-3.2-1.5-4.9-2
						c-1.7-0.5-3.4-0.7-5.1-0.8c-3.4-0.1-6.7,0.5-9.7,1.8c-2.9,1.4-5.5,3.4-7.2,6.1c-1.7,2.6-2.4,5.5-2.4,8.1c0,2.7,0.8,5.3,2.2,7.2
						c0.7,1,1.5,1.8,2.4,2.5c0.9,0.6,1.8,1.1,2.7,1.4c3.6,1.3,6.6,0.5,8.1-0.6c1.6-1.1,1.9-2.1,1.6-2.4c-0.3-0.4-1.2-0.1-2.5,0
						c-0.7,0-1.5,0-2.4-0.2c-0.9-0.2-1.9-0.6-2.8-1.2c-0.9-0.6-1.7-1.5-2.1-2.5c-0.5-1.1-0.6-2.4-0.4-3.8c0.2-1.4,0.8-2.9,1.8-4.1
						c0.9-1.1,2.4-2.1,4.1-2.7c1.7-0.6,3.8-0.8,5.8-0.6c1,0.1,2,0.3,3,0.7c1,0.4,2,0.8,2.9,1.4c1.9,1.2,3.6,2.7,4.9,4.6
						c1.3,1.9,2,4,2.3,6.3c0.2,2.3-0.1,4.8-1,7.1c-0.4,1.1-1,2.2-1.7,3.2c-0.7,1-1.5,1.8-2.5,2.7c-3.9,3.3-9.9,5-16.1,5.8
						c-6.3,0.8-12.3,0.4-17.8-0.8c-5.5-1.2-10.3-3.2-14.8-5.6c-9-4.9-16.6-10.8-25.2-15.5c-8.6-4.7-18.1-7.9-29-9.2
						c-5.4-0.6-11.2-0.8-17.4-0.2c-6.1,0.6-12.7,2-19.4,4.5c6.8-2.2,13.3-3.3,19.4-3.6c6.1-0.3,11.8,0.2,17.1,1.1
						c10.5,1.9,19.4,5.7,27.3,10.8c3.9,2.6,7.7,5.5,11.5,8.5c3.9,3,7.9,6.1,12.5,9.1c4.6,3,9.9,5.7,16,7.5
						C312.1,598.2,319,599.2,326.4,598.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M279.9,561.6c4.2-1.3,8.3-3.6,11.6-6.8c1.7-1.6,3.1-3.5,4.3-5.6c1.2-2.1,2-4.4,2.3-7c0.7-5.2-1.5-10.8-6.2-13.8
						c-2.4-1.4-4.9-1.9-7.1-1.8c-2.2,0.1-4.1,0.5-6,1.3c-1.8,0.8-3.5,2-4.9,3.6c-1.3,1.6-2.1,3.7-2.1,5.7c0,3.9,2.4,6.6,4.7,7.6
						c2.3,1,4.2,0.5,5.3-0.1c1.1-0.6,1.3-1.3,1.2-1.6c-0.2-0.4-0.8-0.6-1.5-0.9c-0.7-0.3-1.7-0.8-2.4-1.6c-0.3-0.4-0.5-0.8-0.6-1.4
						c-0.1-0.5-0.1-1.1,0.1-1.5c0.2-0.4,0.5-0.7,1-1.1c0.5-0.3,1.2-0.7,2.1-0.9c0.9-0.2,2-0.3,2.9-0.2c1,0.1,1.7,0.4,2.2,0.8
						c0.5,0.4,0.9,1,1.2,1.8c0.2,0.4,0.3,0.9,0.3,1.3c0,0.4,0,0.9,0,1.5c-0.3,2.1-1.7,4.8-3.7,7c-2,2.2-4.7,4.1-7.8,5.4
						c-3.1,1.4-6.4,2.2-9.5,2.6c-3.2,0.4-6.2,0.5-9.2,0.4c-5.9-0.1-11.4-0.8-17-1.1c-5.6-0.3-11.4-0.3-17.6,0.8
						c-6.2,1.1-13,3.1-20.3,7c7.5-3.4,14.4-4.8,20.5-5.3c6.2-0.4,11.6,0.2,17,1.3c5.4,1,10.7,2.4,16.9,3.4c3.1,0.5,6.5,0.9,10.2,0.9
						C271.4,563.3,275.5,563,279.9,561.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M303.9,501.7c1.1-5.3,2.3-10.4,3.1-14.4c0.8-4.1,1.2-7.3-0.3-8.3c-1.4-1-4.6,0.8-7.4,4.9c-1.4,2.1-2.6,4.7-3.4,7.7
						c-0.8,3-1.1,6.3-0.8,9.6c0.3,3.4,0.9,6.2,1.7,8.6c0.7,2.4,1.6,4.5,2.6,6.5c1,2,2,4,3.3,6.1c1.3,2.1,2.8,4.3,4.9,6.8
						c-1.6-2.8-2.6-5.2-3.3-7.5c-0.7-2.3-1.1-4.3-1.3-6.4c-0.2-2.1-0.2-4.1-0.1-6.3C303,506.9,303.3,504.5,303.9,501.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M320.9,499.7c0.3-3.4-0.1-6.9-1.1-10c-0.9-3.1-2.4-5.8-4.1-7.9c-1.6-2.1-3.4-3.4-4.9-4.1c-1.5-0.7-2.6-0.7-3.3-0.1
						c-1.3,1.3-0.3,4.3,0.8,8.2c0.6,2,1.2,4.2,1.9,6.6c0.6,2.4,1.3,5,1.8,7.7c0.6,2.8,0.8,5.2,0.9,7.5c0.1,2.3,0,4.4-0.3,6.5
						c-0.5,4.2-1.6,8.5-4.5,14.4c3.8-5.3,6.1-9.2,8.1-13.3c1-2.1,1.9-4.2,2.7-6.7C319.9,506,320.6,503.2,320.9,499.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M312.1,497.4c0.7-3.6,0.6-7.3,0-10.1c-0.6-2.7-1.6-4.4-2.5-4.3c-0.9,0.1-1.4,1.9-1.7,4.6c-0.3,2.6-0.4,6-0.2,9.5
						c0.2,3.6,0.2,6.1,0.2,8.6c-0.1,2.5-0.3,5.1-1.2,8.8c1.3-3.6,2.2-6,3-8.5C310.5,503.6,311.3,501.1,312.1,497.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M310,493.7c0.9-3.1,1-6.3,0.6-8.7c-0.4-2.4-1.2-4-2.1-3.9c-0.9,0-1.6,1.6-2.1,3.8c-0.5,2.3-0.8,5.2-0.8,8.3
						c0,3.1,0,5.3-0.2,7.5c-0.2,2.2-0.4,4.4-1.2,7.6c1.2-2.9,2.1-5,3.1-7.2C308.2,499,309,496.9,310,493.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M302.6,488.7c1.5-2.6,2.9-5.1,3.9-7.2c1-2,1.6-3.6,1-4.3c-0.6-0.6-2.4,0-4.3,1.7c-1.9,1.8-3.9,4.8-4.7,8.1
						c-0.8,3.5-0.9,5.9-0.8,8.3c0.1,2.4,0.3,4.7,1,8.1c-0.2-3.4,0.2-5.7,0.8-7.9C300.3,493.4,301.1,491.4,302.6,488.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M305.8,559.5c1.5-1.7,2.7-3.7,3.5-5.7c0.8-2,1.3-4,1.4-5.7c0.3-3.4-0.5-5.7-1.4-5.7c-0.9,0-1.6,2-2.7,4.8
						c-1.1,2.8-2.5,6.3-4.4,9.7c-2,3.5-3.9,5.6-6.1,7.6c-2.2,1.9-4.8,3.6-9.1,4.9c4.4-0.8,7.4-1.9,10.1-3.3c1.4-0.7,2.7-1.5,4.2-2.5
						C302.7,562.6,304.2,561.3,305.8,559.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M341,541.1c4.9-0.6,9.7-1.1,13.4-1.5c3.7-0.4,6.3-0.9,6.4-1.8c0.1-0.8-2.4-2-6.4-2.4c-3.9-0.5-9.3-0.1-14.4,1.4
						c-5.2,1.6-8.5,3.3-11.7,5c-3.2,1.8-6.4,3.7-10.8,6.9c4.6-2.8,8.1-4.1,11.5-5.2C332.6,542.5,336,541.7,341,541.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M361.1,546c3.3,0.2,6.6,0,9.1-0.3c2.5-0.4,4.3-1,4.3-1.9c0.1-0.9-1.6-1.8-4.2-2.3c-2.6-0.5-6.1-0.6-9.6,0.1
						c-3.5,0.7-5.9,1.5-8.2,2.3c-2.5,0.8-4.8,1.6-8.1,2.8c3.6-0.7,6-0.8,8.5-0.9C355.2,545.7,357.6,545.8,361.1,546z"
                    />
                  </g>
                  <g>
                    <path
                      d="M368.5,552.6c2.6,0.8,5.2,1.2,7.2,1.2c2,0,3.6-0.3,3.8-1.2c0.2-0.8-0.9-2-2.8-2.9c-2-0.9-4.8-1.6-7.6-1.5
						c-2.9,0.1-4.9,0.4-6.9,0.7c-2.1,0.3-4.2,0.7-6.9,1.1c2.9,0.1,4.9,0.4,6.8,0.8C364,551.3,365.9,551.8,368.5,552.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M371.3,563.5c1.5,1.5,2.9,2.8,4,3.8c1.2,1,2.2,1.7,3.1,1.5c0.8-0.3,1.2-1.8,0.4-3.8c-0.7-2-2.7-4.1-5.1-5.1
						c-2.5-1.1-4.2-1.3-6-1.6c-1.7-0.3-3.4-0.5-5.9-1c2.3,1,3.8,1.8,5.2,2.7C368.4,561,369.8,562,371.3,563.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M370.1,580.9c0.2,2.4,0.6,4.7,1.1,6.5c0.5,1.8,1.1,3.1,2,3.2c0.9,0.1,1.8-1.2,2.2-3.3c0.4-2.1,0.2-5-1-7.6
						c-1.2-2.6-2.4-4.2-3.6-5.8c-1.2-1.5-2.5-3-4.4-5c1.5,2.3,2.2,4.1,2.7,5.8C369.5,576.6,369.8,578.4,370.1,580.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M364.6,598.8c-0.5,1.7-0.6,3.5-0.3,4.8c0.2,1.4,0.8,2.4,1.7,2.6c0.9,0.2,1.8-0.6,2.5-2c0.6-1.4,1-3.4,0.6-5.3
						c-0.4-2-0.9-3.3-1.3-4.7c-0.5-1.3-0.9-2.6-1.5-4.5c0.1,2-0.1,3.3-0.4,4.7C365.5,595.7,365.1,597,364.6,598.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M353.3,601c-3.3,3.3-5.1,7.7-3.7,8.8c1.4,1.1,5.4-1.9,7.3-6.4c1.9-4.6,2.2-6.5,4.2-11.1
						C358.2,596.4,356.7,597.5,353.3,601z"
                    />
                  </g>
                  <g>
                    <path
                      d="M340.2,602.7c-2.6,1.1-5,2.5-6.7,3.8c-1.7,1.3-2.7,2.6-2.3,3.4c0.4,0.8,2,0.9,4.2,0.3c2.2-0.6,4.8-1.9,7.1-3.8
						c2.3-2,3.8-3.5,5.2-5c1.5-1.5,2.9-3,5.1-5.1c-2.5,1.7-4.4,2.7-6.2,3.6C344.8,600.8,342.9,601.6,340.2,602.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M316.4,606.9c-2.8,0-5.6,0.4-7.6,1c-2.1,0.6-3.5,1.4-3.5,2.2c0,0.9,1.5,1.6,3.7,1.9c2.2,0.3,5.2,0.1,8.1-0.9
						c2.9-1,4.8-1.9,6.7-2.8c2-1,4-1.9,6.6-3.1c-3,0.8-5,1.1-7.1,1.3C321.3,606.8,319.3,606.9,316.4,606.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M233,569.1c5.4,0.9,10.6,2.2,14.6,3.2c4,1,6.8,1.7,7.2,0.8c0.4-0.7-1.8-2.8-5.8-4.8c-4-1.9-9.8-3.5-15.7-3.6
						c-6,0-10.1,0.7-14.1,1.6c-4,0.9-8,2.1-13.5,4.5c5.7-1.9,9.8-2.4,13.7-2.6C223.5,568,227.4,568.2,233,569.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M251.9,584.4c1.8,1.9,3.6,3.7,5.1,5.1c1.5,1.3,2.8,2.2,3.6,1.8c0.8-0.4,0.8-2.1-0.2-4.2c-1-2.1-3.1-4.6-5.8-6.1
						c-2.8-1.5-4.8-2.2-6.9-2.7c-2.1-0.5-4.1-0.9-7.1-1.1c2.9,0.8,4.7,1.8,6.4,2.9C248.6,581.1,250.1,582.4,251.9,584.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M68,396c1.6-0.2,3.2-0.3,4.9-0.5c1.6-0.1,3.3-0.2,4.8-0.2c3.1,0,6.1,0.2,8.8,0.7c2.7,0.5,5.1,1.4,6.9,2.6
						c0.9,0.6,1.7,1.2,2.3,1.9c0.3,0.3,0.6,0.7,0.9,1.1c0.2,0.4,0.4,0.7,0.6,1.1c0.7,1.6,1.1,3.6,0.9,5.5c-0.1,1-0.3,1.9-0.6,2.8
						c-0.3,0.9-0.8,1.8-1.3,2.6c-1.1,1.7-2.5,3.2-4.2,4.4c-1.7,1.2-3.4,2.1-5.2,2.7c-1.8,0.6-3.7,0.8-5.3,0.7
						c-1.7-0.1-3.2-0.4-4.5-1c-2.5-1-4.3-3.3-5.3-5.4c-1-2.1-1.2-4.1-1.3-5.6c0-0.7-0.1-1.4-0.1-1.9c-0.1-0.5-0.2-0.9-0.5-1.1
						c-0.3-0.2-0.7-0.2-1.2,0c-0.5,0.2-1.1,0.7-1.8,1.5c-0.6,0.8-1.3,1.9-1.7,3.4c-0.4,1.4-0.7,3.2-0.5,5.1c0.1,1.9,0.7,4.1,1.7,6.3
						c0.3,0.5,0.6,1.1,0.9,1.6c0.4,0.5,0.7,1.1,1.2,1.6c0.5,0.5,0.9,1.1,1.4,1.5c0.3,0.2,0.5,0.5,0.8,0.7c0.3,0.2,0.6,0.4,0.8,0.7
						c2.3,1.8,5.2,3,8.2,3.7c3.1,0.7,6.4,0.7,9.7,0.2c3.3-0.6,6.8-1.8,9.9-3.6c3.1-1.9,6.1-4.4,8.5-7.6c1.2-1.6,2.3-3.4,3.1-5.4
						c0.8-2,1.4-4.1,1.7-6.3c0.6-4.4,0.1-9-1.8-13.5c-0.5-1.1-1.1-2.2-1.7-3.2c-0.7-1-1.4-2-2.2-2.9c-0.8-0.9-1.7-1.7-2.6-2.5
						c-0.5-0.4-0.9-0.7-1.4-1.1c-0.5-0.4-1-0.7-1.5-1c-4-2.5-8.2-3.7-12.3-4.3c-4.1-0.6-8.1-0.7-12-0.3c-1.9,0.2-3.8,0.4-5.6,0.7
						c-1.9,0.3-3.7,0.6-5.5,1c-7.6,1.7-14.2,4-20.2,6.7c-6,2.7-11.3,5.7-16.1,8.9c-4.8,3.2-9.1,6.5-13,10.1
						c-3.9,3.5-7.6,7.2-10.9,11c-6.7,7.7-12.6,16.1-18,26.2c-5.4,10-10.4,21.7-14.4,36.1c4.8-14.2,10.6-25.4,16.7-34.7
						c6.1-9.4,12.7-16.9,19.9-23.6c3.6-3.3,7.4-6.3,11.4-9.2c4-2.9,8.4-5.5,13.1-7.9c4.7-2.4,9.8-4.6,15.4-6.4
						C55.2,398.3,61.3,396.8,68,396z"
                    />
                  </g>
                  <g>
                    <path
                      d="M53,455.6c0.5,1.9,0.5,3.8,0.3,5.6c-0.3,1.9-0.8,3.7-1.7,5.4c-0.9,1.7-2,3.1-3.3,4.3c-0.6,0.6-1.3,1.1-2,1.6
						c-0.7,0.5-1.5,0.9-2.3,1.2c-3.2,1.4-6.9,1.6-10,0.9c-1.6-0.3-2.9-0.9-3.9-1.6c-1-0.7-1.6-1.3-2.1-2.1c-0.2-0.4-0.4-0.8-0.5-1.1
						c-0.1-0.3-0.2-0.6-0.1-0.9c0-0.6,0.3-1.5,1-2.3c0.7-0.9,1.6-1.7,2.6-2.4c0.5-0.3,1-0.7,1.5-1c0.5-0.3,0.9-0.5,1.3-0.8
						c0.5-0.3,1-0.6,1.4-0.9c0.2-0.2,0.7-0.2,1.1-0.2c0.4,0.1,0.7,0.2,1,0.4c0.3,0.2,0.5,0.5,0.8,0.6c0.3,0.1,0.7,0.1,1.1-0.4
						c0.4-0.4,0.7-1.4,0.4-2.5c-0.2-1.1-0.9-2.3-2.5-3.3c-1.5-0.9-3.3-1.1-5-1c-0.8,0.1-1.6,0.2-2.4,0.3c-0.8,0.1-1.6,0.3-2.5,0.6
						c-1.7,0.5-3.6,1.3-5.5,2.6c-1.9,1.3-4,3.4-5.2,6.4c-0.6,1.5-1,3.2-1.1,4.8c-0.1,1.7,0.1,3.3,0.6,5c0.5,1.6,1.2,3.2,2.1,4.6
						c0.9,1.5,2.1,2.7,3.3,3.8c2.5,2.2,5.4,3.8,8.4,4.8c6.1,2,13.1,1.9,19.6-0.7c3.2-1.3,6.4-3.2,9-5.8c2.6-2.5,4.7-5.6,6.2-8.9
						c1.5-3.3,2.4-6.9,2.5-10.6c0.2-3.7-0.5-7.5-1.8-10.8c-2.7-7-7.7-12-13.2-14.9c-5.6-2.9-11.3-3.4-16.2-3.1
						c-4.9,0.3-9.3,1.4-13.4,2.8c-4.1,1.3-7.9,3-11.5,4.9c-3.7,1.9-7.1,4.1-10.6,6.6c-3.4,2.5-6.7,5.3-9.9,8.6
						c-3.2,3.3-6.3,6.9-9.3,11.1c-2.9,4.2-5.7,9-8,14.5c2.6-5.3,5.7-9.8,9-13.7c3.2-3.9,6.6-7.1,10-9.9c3.4-2.8,6.9-5.1,10.4-7
						c3.5-1.9,7-3.5,10.6-4.8c3.6-1.2,7.3-2.3,11.1-2.9c3.8-0.7,7.7-1.1,11.5-0.8c3.8,0.3,7.6,1.4,10.5,3.5
						C49.6,448.2,52,451.7,53,455.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M121.8,361.1c10.9,1.6,21.5,3.9,29.6,5.9c0.5,0.1,1,0.2,1.5,0.3c0.5,0.1,1,0.2,1.4,0.4c0.9,0.2,1.8,0.4,2.6,0.6
						c0.8,0.2,1.6,0.3,2.4,0.5c0.7,0.2,1.4,0.3,2,0.4c2.5,0.4,4.2,0.3,4.6-0.5c0.4-0.8-0.5-2.1-2.5-3.9c-0.5-0.4-1.1-0.9-1.7-1.4
						c-0.6-0.5-1.4-0.9-2.1-1.4c-0.8-0.5-1.6-1-2.5-1.5c-0.5-0.2-0.9-0.5-1.4-0.8c-0.5-0.2-1-0.5-1.5-0.7
						c-8.1-3.8-19.9-6.9-31.8-6.7c-3.1,0.1-5.9,0.1-8.5,0.4c-1.3,0.1-2.6,0.2-3.8,0.4c-1.2,0.2-2.4,0.4-3.5,0.5
						c-1.1,0.2-2.3,0.3-3.3,0.5c-1.1,0.2-2.1,0.4-3.2,0.7c-1,0.2-2.1,0.4-3.1,0.7c-1,0.3-2,0.5-3,0.8c-1,0.3-2,0.5-3,0.8
						c-1,0.3-2,0.6-3,1c-1,0.3-2,0.6-3.1,1c-1,0.4-2.1,0.8-3.2,1.2c-1.1,0.4-2.2,0.8-3.3,1.3c-1.1,0.5-2.3,1-3.5,1.5
						c-0.6,0.3-1.2,0.5-1.8,0.8c-0.6,0.3-1.2,0.6-1.9,0.9c-1.3,0.6-2.6,1.2-4,1.9c1.4-0.5,2.8-1,4.1-1.5c0.7-0.2,1.3-0.5,1.9-0.7
						c0.6-0.2,1.3-0.4,1.9-0.6c1.2-0.4,2.4-0.7,3.6-1c1.2-0.3,2.3-0.5,3.4-0.8c1.1-0.2,2.2-0.5,3.3-0.7c1.1-0.2,2.1-0.3,3.2-0.5
						c1-0.1,2-0.3,3-0.4c1-0.1,2-0.2,3-0.3c1-0.1,2-0.2,3-0.2c1-0.1,2-0.1,3-0.1c1,0,2.1,0,3.1-0.1c1.1,0,2.1,0.1,3.2,0.1
						c1.1,0,2.2,0.1,3.4,0.1c1.2,0.1,2.3,0.2,3.6,0.3C116.4,360.4,119,360.8,121.8,361.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M120.9,378.3c10.8,0.9,21.8-0.5,29.7-2.7c7.9-2.2,12.8-5.1,12.3-6.8c-0.4-1.8-5.9-1.9-13.7-1.5
						c-7.8,0.4-18.1,1.3-28.4,2.2c-10.6,0.9-18,1.1-25.5,0.9c-7.5-0.3-15.1-1-26.1-3.4c10.7,3.5,18.2,5.4,25.6,7.2
						C102.3,375.9,109.8,377.4,120.9,378.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M132.4,371.1c6.3,1.3,12.7,1.4,17.5,0.7c4.8-0.7,8-2.3,8-4c0-1.8-3.1-3.3-7.8-4.4c-4.7-1-10.9-1.5-17.2-1
						c-6.5,0.5-10.9,0.9-15.5,1.2c-4.5,0.3-9.1,0.4-15.8,0c6.6,1.4,11,2.6,15.4,3.8C121.5,368.5,125.9,369.7,132.4,371.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M166.3,420.2c1,0,2.1,0,3.1-0.2c1-0.2,2.1-0.4,3.1-0.7c2-0.7,4-1.7,5.8-3.3c1.8-1.5,3.3-3.7,4-6.1
						c0.7-2.4,0.7-4.8,0.3-6.9c-0.4-2.1-1.2-4-2.1-5.7c-0.9-1.7-2-3.1-3.3-4.5c-1.3-1.3-2.8-2.4-4.5-3.3c-1.6-0.8-3.6-1.3-5.3-1.4
						c-3.5-0.2-6.9,1.1-9,3.3c-1.1,1.1-1.8,2.4-2.2,3.6c-0.4,1.2-0.5,2.3-0.4,3.3c0.2,2,1.1,3.4,1.9,4c0.8,0.7,1.5,0.7,1.8,0.4
						c0.3-0.3,0.4-0.9,0.5-1.6c0.1-0.7,0.2-1.6,0.7-2.4c0.4-0.9,1.2-1.8,2.1-2.1c0.9-0.4,2.3-0.6,3.6-0.2c1.3,0.4,2.6,1.3,3.9,2.8
						c1.2,1.5,2.3,3.6,2.6,5.5c0.2,0.9,0.1,1.7-0.1,2.3c-0.2,0.6-0.5,1.2-1.1,1.8c-0.6,0.6-1.5,1.2-2.5,1.7
						c-0.5,0.2-1.1,0.4-1.6,0.6c-0.6,0.2-1.1,0.3-1.8,0.4c-2.6,0.3-5.2,0-7.6-0.7c-2.4-0.7-4.7-1.7-6.8-2.9c-4.1-2.4-7.6-5.4-11-8.5
						c-3.5-3.1-6.9-6.5-11.2-9.7c-2.2-1.6-4.5-3.1-7.3-4.5c-2.7-1.3-5.9-2.4-9.3-2.6c3.5,0.5,6.4,1.9,8.9,3.4
						c2.5,1.5,4.7,3.3,6.5,5.1c3.8,3.6,6.6,7.3,9.5,11c3,3.7,6,7.4,10.3,11c2.2,1.8,4.6,3.4,7.6,4.8
						C158.6,419,162.2,420.1,166.3,420.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M130.6,425.7c-0.7,1.3-1.4,2.2-2.3,3c-0.9,0.7-1.7,1.2-2.5,1.4c-0.6,0.2-1.4,0.2-2.1,0c-0.7-0.2-1.2-0.4-1.5-0.7
						c-0.3-0.1-0.6-0.7-0.7-1.3c-0.1-0.6-0.1-1.3,0-1.9c0.1-0.6,0.3-1.1,0.4-1.6c0.2-0.5,0.3-1,0.2-1.4c-0.1-0.4-0.4-0.8-1.1-1
						c-0.7-0.2-1.8-0.1-2.9,0.5c-1.2,0.6-2.4,1.7-3.2,3.4c-0.8,1.6-1.3,4-0.4,6.6c0.8,2.4,2.7,4.6,5.1,5.9c2.4,1.3,5.3,1.8,8.3,1.2
						c2.9-0.6,5.4-2.2,7.2-4.2c1.8-2,3.1-4.6,3.7-7c0.6-2.5,0.6-4.8,0.4-6.8c-0.2-2-0.6-3.8-1-5.4c-0.9-3.3-2.2-6.1-3.7-8.6
						c-1.5-2.6-3.3-4.9-5.6-7.3c-1.1-1.2-2.4-2.3-3.8-3.5c-1.4-1.1-3.1-2.2-4.9-3.2c1.7,1.2,3.1,2.6,4.3,3.8c1.2,1.3,2.2,2.6,3,3.9
						c1.7,2.6,2.7,5.1,3.5,7.7c0.7,2.6,1.1,5.2,1.1,8C132.2,419.9,131.9,423.1,130.6,425.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M612.6,399.5c-0.7-3.6-1.9-7.1-3.5-10.4c-1.6-3.3-3.6-6.4-5.8-9.1c-2.2-2.7-4.6-4.8-6.8-6.8c-2.2-1.9-4.2-3.6-5.9-5.2
						c-3.5-3.1-6-6-7.6-8.2c-1.7-2.2-2.6-3.7-3.5-3.5c-0.8,0.1-1.5,2-0.9,5.4c0.3,1.6,0.9,3.6,1.9,5.6c1,2,2.4,4.2,4,6.3
						c3.3,4.3,7.7,8.3,11.3,12.7c3.5,4.3,6.4,9.6,8.4,15.4c1,3,1.7,5.9,2.1,8.6c0.4,2.7,0.7,5.4,0.7,7.9c0,5-0.5,9.6-1.6,14.1
						c-1.1,4.5-2.6,9-4.9,13.8c-2.3,4.8-5.4,9.9-9.8,15.6c4.8-5.3,8.4-10.1,11.3-14.7c2.9-4.6,5.2-9,7-13.5
						c1.8-4.6,3.3-9.3,4.1-14.8c0.4-2.8,0.6-5.7,0.6-8.9C613.7,406.6,613.4,403.2,612.6,399.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M580.2,404.8c-0.8-5.7-1-11.4-0.9-16.8c0.1-5.4,0.4-10.6,0.8-15c0.7-8.9,0.9-15.2-0.8-15.7c-1.7-0.4-4.9,5.1-7.5,14.3
						c-1.2,4.6-2.2,10.1-2.4,16.2c-0.2,6,0.4,12.6,2.2,18.9c1.9,6.5,4.3,11.6,7.1,15.9c1.4,2.2,2.8,4.1,4.3,5.9
						c1.4,1.8,2.9,3.4,4.2,5.1c2.6,3.3,5,6.9,5.9,11.6c0.2,1.2,0.4,2.4,0.4,3.7c0,1.3,0,2.6-0.2,4c-0.4,2.7-1.3,5.6-3,8.4
						c1.9-2.7,3.1-5.5,3.8-8.2c0.4-1.4,0.6-2.7,0.7-4.1c0.1-1.3,0.1-2.6,0.1-3.9c-0.1-2.5-0.7-5-1.5-7.2c-0.8-2.2-1.8-4.3-2.9-6.2
						c-2.2-3.9-4.5-7.5-6.2-11.7C582.4,415.5,581,410.7,580.2,404.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M593.6,391.6c-1-7.1-3.4-13.9-5.9-18.7c-2.5-4.8-4.9-7.5-5.7-7.1c-0.8,0.4,0.2,3.8,1.8,8.7c1.6,4.9,3.7,11.4,5.5,17.9
						c1.8,6.8,2.7,11.6,3.3,16.5c0.5,4.9,0.8,9.9,0.1,17.3c1.2-7.3,1.6-12.4,1.8-17.4C594.6,403.9,594.6,398.9,593.6,391.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M588.3,385.7c-0.4-5.3-1.8-10.5-3.4-14.1c-1.6-3.7-3.4-5.8-4.2-5.5c-0.8,0.3-0.6,3,0.2,6.7c0.7,3.8,1.9,8.6,3.1,13.5
						c1.2,5.1,1.9,8.6,2.3,12.2c0.4,3.6,0.7,7.4,0.3,12.8c0.9-5.4,1.3-9.2,1.6-12.9C588.5,394.8,588.7,391.1,588.3,385.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M583.8,385.6c0.2-8.5-1.7-16.9-3.5-16.8c-1.8,0.1-2.4,8.7-0.9,17.1c1.6,8.6,2.6,11.8,3.8,20.8
						C583,397.7,583.5,394.4,583.8,385.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M38.3,418.2c3.6-0.5,7.1-1.2,9.7-2c2.6-0.8,4.4-1.7,4.3-2.5c-0.1-0.9-2-1.5-4.9-1.6c-2.9-0.1-6.7,0.5-10.3,1.9
						c-3.7,1.5-6,2.8-8.4,4.1c-2.3,1.3-4.7,2.7-8,4.8c3.6-1.6,6.2-2.4,8.7-3C32.1,419.3,34.6,418.8,38.3,418.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M37.7,425.6c3.2-0.1,6.2-0.5,8.6-1.1c2.3-0.5,4-1.2,4-2.1c0-0.9-1.6-1.7-4.2-2.1c-2.6-0.4-6-0.1-9.3,0.9
						c-3.3,1.1-5.5,2.1-7.6,3.2c-2.1,1.1-4.2,2.2-7.3,3.9c3.3-1.2,5.6-1.8,7.9-2.1C32.2,425.9,34.5,425.7,37.7,425.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M59.5,432.6c2.4,0.8,4.7,1.4,6.6,1.6c1.9,0.2,3.4,0.1,3.8-0.7c0.4-0.8-0.5-2.1-2.3-3.2c-1.8-1.1-4.5-2-7.3-2
						c-2.9,0-4.8,0.3-6.8,0.7c-1.9,0.4-3.8,0.8-6.5,1.5c2.8-0.2,4.7,0,6.5,0.4C55.3,431.3,57.1,431.8,59.5,432.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M80.6,446.8c4,0.5,8.1-0.5,10.7-1.9c2.7-1.4,4-3.1,3.6-3.8c-0.4-0.8-2.4-0.5-5-0.1c-2.6,0.4-5.9,1-9.4,1.5
						c-3.6,0.4-6.1,0.3-8.7,0c-2.5-0.4-5.2-1.1-8.6-3.1c3.1,2.4,5.6,3.7,8.1,4.9C73.9,445.3,76.5,446.2,80.6,446.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M84.1,455.5c2.2,0,4.3-0.8,5.6-1.8c1.3-1,1.9-2.2,1.5-3c-0.4-0.8-1.6-1-3.1-0.9c-1.5,0.1-3.2,0.6-4.9,1.4
						c-1.8,0.8-3.1,1.4-4.4,1.8c-1.4,0.4-2.8,0.8-4.9,0.8c2.1,0.5,3.5,0.8,5,1.1C80.4,455.3,81.9,455.5,84.1,455.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-6.8,468.2c-3.8,3.9-6.3,9-7.3,13c-1,4-0.7,6.8,0.2,7c0.9,0.2,2.1-2.2,3.8-5.5c1.7-3.3,4-7.6,6.8-11.7
						c2.9-4.2,5.4-6.9,8.1-9.3c2.8-2.3,5.8-4.6,11-6.6c-5.3,1.5-8.8,3.2-12.1,5C0.3,462-2.9,464.1-6.8,468.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-0.6,480.6c-1,1.9-1.8,3.9-2.4,5.8c-0.3,1-0.5,1.9-0.7,2.8c-0.2,0.9-0.3,1.9-0.3,2.8c0,0.9,0.1,1.8,0.4,2.6
						c0.3,0.8,0.7,1.5,1.2,1.9c0.5,0.4,1,0.6,1.4,0.6c0.4,0,0.6-0.1,0.7-0.3c0.2-0.4-0.1-1,0-1.6c0-0.6,0.1-1.5,0.5-2.7
						c0.2-0.6,0.4-1.2,0.7-2.1c0.3-0.8,0.6-1.7,0.8-2.5c0.6-1.8,1.1-3.7,1.7-5.6c1.1-4,2.1-6.7,3.2-9.3c1.2-2.7,2.5-5.3,5-8.9
						c-2.9,3.2-4.8,5.6-6.7,7.9C3.3,474.3,1.5,476.7-0.6,480.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M7.5,492.9c-0.1,2.4,0.6,4.8,1.6,6.4c1,1.6,2.2,2.3,3,2c0.8-0.4,1.1-1.7,1-3.3c-0.1-1.6-0.5-3.6-1.2-5.6
						c-0.7-2.1-1.2-3.6-1.5-5.1c-0.3-1.6-0.5-3.2-0.3-5.6c-0.7,2.3-1.2,3.9-1.6,5.5C8.1,488.7,7.7,490.4,7.5,492.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M19.5,498.4c0.8,2,2.3,3.6,3.7,4.4c1.4,0.8,2.7,0.9,3.3,0.2c0.6-0.7,0.3-1.9-0.3-3.1c-0.6-1.3-1.7-2.7-3-3.9
						c-1.4-1.3-2.4-2.3-3.2-3.3c-0.9-1.1-1.7-2.2-2.5-4.2c0.3,2.1,0.5,3.5,0.7,4.9C18.5,494.9,18.7,496.4,19.5,498.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M36.3,500.3c1.6,0.8,3.4,0.8,4.7,0.4c1.3-0.4,2-1.3,2-2.2c-0.1-0.9-0.9-1.6-1.9-2c-1.1-0.5-2.4-0.7-3.8-0.5
						c-1.5,0.2-2.6,0.2-3.7,0.2c-1.1,0-2.3-0.2-3.8-0.8c1.1,0.9,2.1,1.7,3.2,2.6C33.7,498.7,34.7,499.5,36.3,500.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M173.3,453.4c1.6-3.6,2.3-7.6,2.2-11.5c-0.1-3.9-1-7.8-2.9-11.2c-1-1.7-2.2-3.2-3.6-4.5c-1.5-1.3-3.2-2.3-5.1-2.8
						c-3.8-1.1-7.5-0.3-10.2,1.3c-2.8,1.6-4.7,4.3-5.4,6.7c-0.8,2.5-0.6,4.7-0.2,6.4c0.5,1.7,1.2,2.9,1.8,3.6
						c0.6,0.7,1.1,0.9,1.2,0.8c0.4-0.3-0.5-1.9-0.7-4.8c0-1.4,0.2-3.2,1-4.9c0.8-1.7,2.2-3.3,4.2-4.2c2-1,4.6-1.3,6.8-0.5
						c2.3,0.8,4.4,2.7,5.8,5.3c1.4,2.6,2.2,5.7,2.4,8.9c0.2,3.2-0.3,6.6-1.4,9.7c-1.2,3.2-3,6-4.8,8.5c-1.9,2.5-3.8,4.8-5.6,7.1
						c-0.9,1.2-1.7,2.3-2.5,3.5c-0.4,0.6-0.7,1.2-1.1,1.8l-0.5,0.9l-0.5,0.9c-0.3,0.6-0.6,1.2-0.9,1.8c-0.3,0.6-0.5,1.2-0.8,1.8
						c-0.6,1.2-0.9,2.5-1.4,3.7c-0.8,2.5-1.4,5.1-1.8,7.8c-0.2,1.3-0.3,2.7-0.4,4.1c0,0.7,0,1.4,0,2.1c0,0.7,0,1.4,0,2.2
						c0.1,2.9,0.5,6,1.3,9.3c0.8,3.2,2,6.6,3.8,10c-1.7-3.5-2.7-6.9-3.4-10.1c-0.6-3.2-0.9-6.3-0.8-9.2c0-0.7,0-1.4,0.1-2.1
						c0.1-0.7,0.1-1.4,0.2-2.1c0.2-1.4,0.3-2.7,0.6-4c0.5-2.6,1.3-5,2.2-7.4c1.9-4.7,4.5-9,8.3-13.1c1.9-2.1,4-4.2,6.1-6.7
						C169.4,460,171.7,457.1,173.3,453.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M154.7,445.2c-2.1,1.9-3.9,4-5.1,5.8c-1.2,1.8-1.8,3.3-1.2,4c0.6,0.7,2.2,0.3,4.2-0.9c1.9-1.2,4.1-3.4,5.6-6.1
						c0.8-1.4,1.3-2.5,1.8-3.6c0.5-1.1,0.9-2.1,1.2-3.2c0.3-1.1,0.5-2.2,0.4-3.5c-0.1-1.2-0.5-2.6-1.7-3.7c0.9,1.3,1,2.6,0.8,3.7
						c-0.2,1.1-0.7,2-1.2,2.7c-0.6,0.8-1.2,1.5-2,2.3C156.6,443.3,155.7,444.2,154.7,445.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M157.3,453.9c-1.7,1.7-3.1,3.6-4,5.2c-0.9,1.6-1.2,3-0.6,3.7c0.6,0.6,2.1,0.3,3.7-0.9c1.6-1.1,3.4-3.1,4.4-5.5
						c1.1-2.4,1.7-4.2,2.2-6c0.6-1.9,1.1-3.7,1.8-6c-1.2,2.3-2.3,3.8-3.5,5.1C160.3,450.9,159.1,452.2,157.3,453.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M187.3,434.6c0.8-2.6,0.7-5.4,0.1-7.4c-0.6-2-1.7-3.1-2.5-2.9c-0.9,0.2-1.4,1.5-1.6,3.3c-0.3,1.8-0.4,4-0.3,6.3
						c0.1,2.4,0,4.2-0.2,5.9c-0.3,1.7-0.7,3.5-1.9,5.9c1.6-2.1,2.7-3.7,3.6-5.2C185.5,438.9,186.5,437.3,187.3,434.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M196,440.1c1.4-0.9,2.5-2.1,3.2-3.2c0.7-1.1,1-2.2,0.6-3c-0.5-0.7-1.7-0.9-3.1-0.4c-1.4,0.5-3,1.8-4,3.6
						c-1,1.8-1.4,3.2-1.8,4.5c-0.4,1.3-0.7,2.6-1.2,4.5c1-1.7,1.9-2.6,2.8-3.5C193.5,441.8,194.5,441.1,196,440.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M204.1,446c1.7-0.2,3.1-0.8,4.2-1.5c1.1-0.7,1.8-1.6,1.7-2.5c-0.1-0.9-1.2-1.5-2.7-1.6c-1.5-0.1-3.4,0.4-5,1.6
						c-1.6,1.2-2.5,2.2-3.5,3.2c-1,1.1-1.9,2.2-3,3.4c1.6-1,2.8-1.5,4-1.8C201.1,446.4,202.3,446.2,204.1,446z"
                    />
                  </g>
                  <g>
                    <path
                      d="M255.9,442.9c-2.8,2-5.5,3.9-7.6,5.6c-2,1.6-3.4,3-3,3.8c0.3,0.8,2.4,0.8,5.1-0.2c2.7-1,6-3.1,8.4-6
						c2.5-3,3.9-5.3,5.2-7.6c1.2-2.3,2.4-4.7,3.8-8.3c-1.9,3.4-3.5,5.4-5.3,7.2C260.7,439.2,258.8,440.8,255.9,442.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M258,452.6c-2.4,3.6-3.9,7.7-4.5,10.9c-0.5,3.2-0.2,5.5,0.6,5.6c0.9,0.2,2-1.7,3.4-4.4c1.3-2.7,2.9-6.4,4.3-10.1
						c1.5-3.8,2.8-6.4,4.3-9c1.5-2.5,3.1-5,6.1-8.3c-3.4,2.9-5.5,5-7.6,7.1C262.6,446.7,260.5,448.9,258,452.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M264.1,459.8c-1.9,3.8-3,8-3.2,11.2c-0.3,3.2,0.2,5.4,1,5.5c0.9,0.1,1.9-1.8,3-4.6c1.1-2.9,2.3-6.7,3.3-10.6
						c1-4,1.9-6.8,2.9-9.6c1-2.8,2.2-5.5,4.4-9.3c-2.7,3.5-4.4,6-6.1,8.5C267.7,453.4,266.1,455.9,264.1,459.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M266.4,475.3c-1.1,3.9-1.3,8-0.8,11c0.4,3,1.4,5,2.2,4.9c0.9-0.1,1.5-2,2-4.9c0.5-2.8,0.8-6.6,1-10.3
						c0.2-3.9,0.5-6.6,0.9-9.3c0.5-2.7,1.1-5.5,2.6-9.4c-2,3.6-3.2,6.2-4.4,8.8C268.6,468.7,267.5,471.3,266.4,475.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M332.1,491.8c-2.3,0.4-4.4,1.4-5.7,2.6c-1.3,1.2-1.9,2.4-1.4,3.2c0.5,0.8,1.8,0.9,3.4,0.5c1.6-0.3,3.5-1.1,5.3-2.2
						c1.9-1.2,3.2-2,4.6-2.7c1.4-0.7,2.9-1.4,5.1-2c-2.1,0-3.8,0.1-5.6,0.1C336.1,491.3,334.5,491.4,332.1,491.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M338.2,497.8c-2,0.5-2.5,2.8-1.3,4.1c1.2,1.3,3.4,1.1,4.2-0.8c0.8-1.9,0.9-3,2-4.7
						C341.2,497.3,340.2,497.3,338.2,497.8z"
                    />
                  </g>
                  <g>
                    <path d="M432.9,506.6c-3.6,0.1-6.5,2.6-5.9,4.2c0.7,1.6,4.1,1.7,7,0c3-1.8,4.1-2.8,7.6-4C438.1,507,436.6,506.6,432.9,506.6z" />
                  </g>
                  <g>
                    <path
                      d="M152,526.9c-2.4-3.5-5.6-6.8-9.9-9.1c-2.1-1.1-4.4-2-6.8-2.5c-2.4-0.5-5-0.6-7.6-0.1c-5.2,0.9-9.7,3.7-12.6,7.9
						c-0.7,1.1-1.3,2.2-1.8,3.4c-0.5,1.2-0.8,2.5-0.9,3.7c-0.3,2.4,0,4.7,0.7,6.8c0.7,2.1,1.8,4,3.3,5.5c1.5,1.5,3.3,2.6,5,3.2
						c3.6,1.1,6.6,0.4,8.6-0.7c2.1-1.1,3.2-2.7,3.5-3.9c0.2-0.6,0.2-1.1,0.1-1.5c-0.1-0.4-0.3-0.7-0.6-0.8c-0.3-0.2-0.6-0.3-1-0.3
						c-0.4,0-0.8,0-1.2-0.1c-0.8,0-1.8-0.1-2.7-0.4c-0.9-0.3-2-0.9-2.4-1.5c-0.2-0.3-0.3-0.7-0.4-1.1c-0.1-0.4-0.1-0.8-0.1-1.4
						c0-0.5,0.2-1,0.4-1.4c0.1-0.2,0.2-0.4,0.3-0.5c0.1-0.2,0.3-0.4,0.5-0.5c0.4-0.4,0.9-0.7,1.5-1c0.6-0.3,1.3-0.5,2-0.6
						c1.4-0.2,3.4,0.1,5.5,0.9c2,0.8,4.2,2.4,6.2,4.4c1.1,1,2.1,2.2,3,3.4c0.9,1.2,1.8,2.3,2.6,3.5c1.5,2.4,2.8,4.7,3.8,7.1
						c2,4.7,3.3,9.3,3.7,14.1c0.5,4.8,0.2,9.7-1.1,15.2c-1.3,5.4-3.8,11.3-8.4,17.4c5.2-5.5,8.6-11.1,10.9-16.5
						c2.3-5.4,3.7-10.6,4.3-15.9c0.6-5.3,0.5-10.8-0.6-16.8c-0.6-3-1.4-6.1-2.7-9.4c-0.7-1.6-1.4-3.3-2.3-5
						C154.2,530.4,153.2,528.7,152,526.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M180.5,567.9c3.9-1.9,7.3-4.1,9.8-6.3c2.5-2.1,4.1-4.2,3.6-5.9c-0.5-1.6-3.3-2.4-7.1-1.7c-3.7,0.7-8.4,3.2-11.7,7
						c-3.4,3.9-5.2,7-6.8,10c-1.6,3.1-3.1,6.2-4.9,10.7c2.8-4.1,5.3-6.3,7.8-8.3C173.8,571.6,176.5,569.9,180.5,567.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M174.5,542.4c0.4-3.8-1-7.4-2.8-9.6c-1.9-2.2-4.1-3-5.6-2.2c-1.6,0.9-2.2,2.9-2.2,5.3c0,2.4,0.6,5.1,1.9,7.8
						c1.3,2.9,2.2,5,2.8,7.3c0.6,2.3,1,4.7,0.6,8.2c1.4-3.3,2.3-5.6,3.2-8C173.2,548.8,174.1,546.3,174.5,542.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M59.8,573.1c-3.7,3.4-7.3,6.6-11.1,9.2c-3.7,2.5-7.5,4.5-10.6,4.9c-3.2,0.5-5.8-0.6-7.3-1.7c-1.6-1.1-2.1-2.3-2.6-2.2
						c-0.2,0.1-0.3,0.4-0.3,1.1c0.1,0.7,0.4,1.7,1.1,2.7c0.7,1.1,1.9,2.2,3.5,3c1.6,0.8,3.7,1.3,5.9,1.3c2.2-0.1,4.5-0.6,6.7-1.5
						c2.2-0.9,4.3-2.1,6.4-3.4c4.2-2.8,8-6.4,11.4-10.2c3.5-3.9,6.3-7.3,8.9-10.2c2.6-2.9,5.1-5.4,7.9-7.7c2.7-2.2,5.7-4.2,9.4-5.8
						c3.7-1.5,8.2-2.7,13.6-2c-5.3-1-10-0.1-13.9,1.2c-3.9,1.3-7.2,3.1-10.3,5.1c-3.1,2-5.9,4.3-8.9,7
						C66.7,566.4,63.5,569.6,59.8,573.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M73.8,568.7c-1.9,3-3.2,6.4-4,9.7c-0.8,3.3-0.9,6.4-0.8,9.1c0.4,5.4,2.1,8.6,2.9,8.4c0.9-0.2,0.8-3.6,1.3-8.4
						c0.3-2.4,0.7-5.1,1.4-8c0.7-2.9,1.7-5.9,3-8.8c1.4-3,2.8-5.4,4.4-7.4c1.6-2,3.2-3.7,4.9-5.3c1.8-1.5,3.7-3,6-4.3
						c2.3-1.3,5.1-2.5,8.6-3.4c-3.5,0.6-6.4,1.6-9,2.6c-2.5,1.1-4.7,2.2-6.8,3.5c-2.1,1.3-4,2.8-6.1,4.7
						C77.8,563.3,75.8,565.6,73.8,568.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M82.3,565.7c-1.2,2.3-2,4.8-2.4,7.2c-0.4,2.4-0.3,4.7,0,6.6c0.6,3.9,2.2,6.1,3,5.9c0.9-0.2,0.9-2.7,1.2-6
						c0.1-1.7,0.4-3.6,0.7-5.6c0.4-2,0.9-4.2,1.5-6.3c0.7-2.2,1.5-4,2.4-5.5c0.9-1.6,1.8-2.9,2.9-4.2c1.1-1.3,2.3-2.5,3.8-3.7
						c1.5-1.2,3.3-2.3,5.7-3.3c-2.4,0.8-4.4,1.7-6.1,2.6c-1.7,0.9-3.2,1.9-4.6,3c-1.4,1.1-2.7,2.3-4.1,3.8
						C84.9,561.6,83.5,563.4,82.3,565.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M126.8,555.4c3.6,0.7,7.5,0,9.9-1.3c2.5-1.3,3.7-2.8,3.3-3.6c-0.4-0.8-2.2-0.7-4.6-0.4c-2.4,0.3-5.3,0.6-8.5,0.9
						c-3.2,0.2-5.5,0-7.8-0.4c-2.3-0.5-4.6-1.3-7.6-3.4c2.6,2.5,4.7,3.9,6.9,5.1C120.8,553.5,123.1,554.6,126.8,555.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M138.6,563.1c1.7,1.4,3.4,2.4,4.9,3.1c1.5,0.7,2.8,0.9,3.4,0.3c0.6-0.6,0.4-2-0.8-3.5c-1.1-1.5-3.1-3.1-5.5-3.8
						c-2.4-0.7-4.2-0.9-5.9-1c-1.7-0.1-3.4-0.2-5.8-0.2c2.4,0.5,3.9,1.2,5.3,2C135.6,560.8,136.9,561.7,138.6,563.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M141,578.8c0.3,2.1,1,4.1,1.8,5.5c0.8,1.5,1.7,2.4,2.6,2.3c0.9-0.1,1.5-1.4,1.5-3.2c0.1-1.8-0.5-4.2-1.8-6.2
						c-1.3-2.1-2.4-3.4-3.5-4.6c-1.1-1.2-2.2-2.5-3.8-4.2c1.2,2.1,1.7,3.6,2.2,5.2C140.4,575.1,140.7,576.6,141,578.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M143.4,505.7c0.1-2,0.1-3.7-0.1-5.2c-0.2-1.5-0.5-2.7-1.3-3c-0.8-0.3-2,0.5-2.8,2.3c-0.8,1.7-1,4.4-0.2,6.7
						c0.9,2.4,1.9,3.8,3,5.1c1.1,1.3,2.2,2.5,4,4c-1.5-1.8-2-3.4-2.3-4.8C143.4,509.2,143.3,507.7,143.4,505.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M158.8,498.6c0.9-1.8,1.7-3.4,2.3-4.9c0.6-1.4,0.9-2.7,0.4-3.4c-0.5-0.7-2.1-0.5-3.6,0.8c-1.6,1.3-3.1,3.7-3.3,6.4
						c-0.2,2.8,0.3,4.6,0.9,6.3c0.6,1.7,1.4,3.3,2.8,5.3c-1-2.3-1.1-4-0.9-5.5C157.5,501.9,157.9,500.4,158.8,498.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M187.9,576c2.7-0.4,5.2-1.1,7.1-1.9c1.9-0.8,3.2-1.7,3.1-2.6c-0.1-0.9-1.6-1.5-3.8-1.5c-2.2,0-5.1,0.5-7.7,1.8
						c-2.7,1.3-4.4,2.5-6.1,3.6c-1.8,1.2-3.5,2.4-5.8,4c2.7-1.2,4.6-1.8,6.5-2.3C183.2,576.8,185.2,576.4,187.9,576z"
                    />
                  </g>
                  <g>
                    <path
                      d="M149,392.3c3,0.1,5.8-1,7.6-2.1c1.8-1.1,2.7-2.4,2.4-3.2c-0.3-0.8-1.9-1-3.9-0.7c-2,0.3-4.4,1-6.7,1.7
						c-2.4,0.8-4.2,1-6,0.9c-1.8-0.1-3.8-0.5-5.9-2.5c1.7,2.3,3.6,3.4,5.4,4.3C143.8,391.6,145.9,392.2,149,392.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M134,321c-1.8,2.8-3.2,6-3.8,9.2c-0.6,3.2-0.5,6.3,0.2,8.9c0.7,2.6,1.8,4.5,2.9,5.6c0.5,0.6,1,0.9,1.4,1.1
						c0.4,0.2,0.7,0.2,0.8,0.1c0.7-0.6-0.7-3.3-1-7.6c-0.1-2.1,0-4.6,0.5-7.2c0.5-2.6,1.5-5.3,2.8-8c1.3-2.8,2.7-5.1,4-7.1
						c1.3-2.1,2.6-3.9,3.9-5.7c1.3-1.9,2.6-3.7,4-5.8c1.4-2.1,2.9-4.5,4.3-7.6c-1.6,2.9-3.4,5.1-5,7.1c-1.6,1.9-3.2,3.5-4.8,5.2
						c-1.6,1.6-3.1,3.2-4.8,5.1C137.7,315.9,135.8,318.1,134,321z"
                    />
                  </g>
                  <g>
                    <path
                      d="M143.2,322.4c-0.7,5.5,0.4,11.2,2,15.1c1.6,3.9,3.6,6,4.4,5.6c0.8-0.4,0.3-3.1-0.4-6.8c-0.7-3.8-1.4-8.7-1.7-13.7
						c-0.2-5.2,0.2-8.9,1-12.5c0.8-3.6,2-7.3,5-12.1c-3.4,4.5-5.2,8-6.8,11.5C145.2,313,143.9,316.7,143.2,322.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M149.7,316.6c0,4,1.3,7.8,2.8,10.4c1.5,2.6,3.2,3.9,3.9,3.4c0.8-0.4,0.5-2.4-0.1-5c-0.6-2.6-1.5-6-2.3-9.4
						c-0.9-3.5-1.2-6.1-1.4-8.7c-0.1-2.6,0-5.3,1-9.2c-1.5,3.7-2.3,6.3-2.9,9C150.2,309.8,149.7,312.5,149.7,316.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M93.8,341.5c3.2-2.6,5.7-6,7.1-8.8c1.4-2.8,1.7-5,0.9-5.4c-0.8-0.4-2.4,0.9-4.4,3c-2,2.1-4.4,5-6.8,8.1
						c-2.4,3.1-4.3,5.2-6.3,7.2c-2,2-4.2,3.8-7.8,6.1c3.8-1.8,6.4-3.2,8.9-4.6C88,345.7,90.5,344.2,93.8,341.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M102.3,347.6c2.7-0.3,5.3-1,7.2-1.7c1.9-0.8,3.2-1.7,3.1-2.5c-0.1-0.9-1.6-1.5-3.8-1.6c-2.2-0.1-5.1,0.4-7.7,1.7
						c-2.7,1.3-4.4,2.4-6.2,3.5c-1.8,1.2-3.6,2.3-5.9,3.8c2.7-1.2,4.7-1.7,6.6-2.1C97.6,348.2,99.5,347.9,102.3,347.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M119.4,246.7c1.9-1.6,3.5-3.5,4.5-5.2c1-1.6,1.4-3,0.8-3.7c-0.6-0.6-2.1-0.3-3.8,0.8c-1.7,1.1-3.6,3-4.9,5.4
						c-1.3,2.4-2,4.2-2.8,5.9c-0.8,1.9-1.5,3.7-2.5,6c1.5-2.3,2.8-3.7,4.1-5C116.1,249.6,117.4,248.4,119.4,246.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M20.7,384.4c-7.8,3.2-14.9,7.9-19.7,12.1c-4.8,4.2-7.2,7.7-6.7,8.3c0.6,0.7,4.1-1.6,9.2-5c5.1-3.3,11.9-7.6,19-11.4
						c7.3-3.9,12.7-6.1,18.2-8c5.5-1.8,11.3-3.3,20-4.3c-8.8,0.4-14.7,1.3-20.5,2.4C34.5,379.7,28.7,381.1,20.7,384.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M27.1,386.9c-6.2,3.1-11.7,7.5-15.3,11.3c-3.6,3.8-5.4,6.9-4.8,7.4c0.7,0.6,3.5-1.3,7.5-4.3c4-3,9.3-6.9,14.8-10.6
						c5.7-3.8,9.8-6.1,14.1-8.2c4.3-2,8.9-3.9,15.8-5.7c-7.1,1.2-11.9,2.5-16.5,3.9C38.1,382.1,33.5,383.7,27.1,386.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-19.8,372.5c-3.2-2.4-6.3-4.7-8.9-6.3c-2.5-1.6-4.5-2.5-5.1-1.9c-0.6,0.6,0.3,2.7,2.4,5.1c2.1,2.4,5.5,5.1,9.3,6.9
						c4,1.8,6.8,2.7,9.6,3.6c2.9,0.8,5.7,1.6,10,2.5c-4.1-1.4-6.8-2.8-9.3-4.2C-14.2,376.7-16.6,375-19.8,372.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-14.5,384.4c-0.9,0-1.8,0.2-2.7,0.6c-0.9,0.3-1.9,1-2.5,2c-0.6,1-0.6,2.1-0.3,2.7c0.3,0.7,0.9,0.8,1.3,0.7
						c0.4-0.1,0.8-0.4,1.2-0.5c0.4-0.1,0.7-0.1,1-0.1c0.2,0.1,0.6,0,1.2-0.2c0.7-0.2,1.4-0.5,2.1-1c1.4-0.9,2.5-1.5,3.6-2
						c1.1-0.5,2.3-0.9,4.1-1.2c-1.6-0.2-2.9-0.5-4.4-0.7C-11.3,384.6-12.6,384.4-14.5,384.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-13.6,395.2c-2.1,1.6-3.6,3.7-4.4,5.6c-0.7,1.8-0.7,3.3,0,3.8c0.8,0.5,2-0.2,3.4-1.4c1.4-1.2,2.9-3,4.1-5
						c1.3-2.1,2.3-3.5,3.4-4.9c1.1-1.4,2.3-2.7,4.3-4.4c-2.3,1.2-4,2-5.6,2.9C-9.8,392.7-11.4,393.6-13.6,395.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-9.5,421.5c-1.3,2.9-0.3,6,1.4,6.3c1.7,0.2,3.5-2.6,2.9-5.7c-0.5-3.2-1.1-4.4-1.2-7.7C-7.4,417.5-8.2,418.6-9.5,421.5
						z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-2.1,415.6c-1.2,2.5-0.2,5.3,1.5,5.5c1.7,0.2,3.4-2.2,2.8-4.9c-0.6-2.8-1.2-3.9-1.4-6.7C0,412.2-0.9,413.1-2.1,415.6z
						"
                    />
                  </g>
                  <g>
                    <path
                      d="M578.1,305.9c3.3-4.2,6.4-8.7,8.9-13.6c2.5-4.9,4.2-10.3,4.9-15.7c0.7-5.4,0.4-10.9-1.2-15.8c-0.4-1.2-0.8-2.4-1.3-3.6
						c-0.5-1.2-1.1-2.3-1.8-3.4c-1.4-2.2-3.1-4.2-5.3-5.7c-1.1-0.8-2.3-1.3-3.6-1.8c-1.3-0.4-2.7-0.6-4-0.5
						c-2.5,0.1-4.9,0.9-6.8,2.5c-0.9,0.8-1.7,1.7-2.3,2.7c-0.6,1-0.8,2.1-1,3.1c-0.2,2,0.2,3.7,0.7,5c1.1,2.5,2.5,3.9,3.5,4.8
						c1,0.9,1.7,1.1,2.1,0.9c0.4-0.2,0.3-1,0.1-2.2c-0.2-1.2-0.8-2.9-1-4.7c-0.1-0.9,0-1.8,0.3-2.5c0.2-0.3,0.4-0.7,0.6-0.9
						c0.2-0.2,0.5-0.4,0.9-0.6c0.7-0.3,1.7-0.5,2.7-0.3c0.5,0.1,1,0.2,1.4,0.4c0.4,0.2,0.9,0.6,1.4,1c0.9,0.8,1.8,2,2.5,3.4
						c0.7,1.4,1.3,3.2,1.8,5c0.9,3.6,1,7.7,0.4,12c-0.6,4.2-1.9,8.6-3.8,12.8c-1.9,4.3-4.4,8.5-7.2,12.6c-2.9,4.3-5.8,8.2-8.5,11.9
						c-2.7,3.7-5.2,7.3-7.5,10.8c-2.2,3.5-4.2,7-5.8,10.6c-1.6,3.6-2.8,7.2-3.7,10.9c-1.7,7.4-2.1,15.1-0.8,23.5
						c0.6,4.2,1.7,8.5,3.4,13c1.7,4.4,4,9,7.3,13.5c-3-4.6-5.1-9.3-6.5-13.7c-1.4-4.5-2.1-8.8-2.4-12.9c-0.6-8.2,0.5-15.5,2.8-22.3
						c1.2-3.4,2.6-6.6,4.4-9.7c1.8-3.1,4-6.2,6.4-9.3c2.4-3.1,5.2-6.3,8.2-9.7C571.4,313.8,574.7,310.2,578.1,305.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M561.9,353.7c1-1.7,2-3.3,3.1-4.8c1.1-1.5,2.2-2.8,3.4-4.1c1.1-1.3,2.4-2.4,3.5-3.4c0.3-0.2,0.6-0.5,0.9-0.7
						c0.3-0.2,0.5-0.4,0.8-0.6c0.5-0.4,1.1-0.7,1.6-1c2.2-1.2,4-1.8,5.4-2c1.5-0.3,2.7-0.1,3.3-0.7c0.3-0.3,0.4-0.8,0.2-1.5
						c-0.2-0.7-0.8-1.5-1.9-2.3c-1-0.8-2.5-1.4-4.3-1.6c-1.7-0.2-3.7-0.1-5.6,0.3c-1,0.2-2,0.6-3,1c-0.5,0.2-1,0.5-1.5,0.7
						c-0.5,0.3-0.9,0.5-1.4,0.8c-1.8,1.1-3.7,2.5-5.3,4.2c-1.6,1.7-3.1,3.6-4.4,5.6c-1.2,2.1-2.2,4.3-3,6.5
						c-1.6,4.7-2.1,8.7-2.3,12.3c-0.2,3.6,0,6.8,0.3,9.9c0.7,6.3,1.9,12.4,3.8,21.4c-0.9-9.1-0.8-15.4,0-21.4c0.4-3,1-5.9,1.9-9
						C558.6,360.4,559.9,357.1,561.9,353.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M574.5,279.9c-0.6-1.9-1.5-3.7-2.6-5.3c-0.6-0.8-1.2-1.6-1.9-2.3c-0.7-0.7-1.5-1.3-2.3-1.9c-1.7-1.1-3.9-1.8-6.2-1.5
						c-1.1,0.2-2.1,0.5-3,1.1c-0.9,0.6-1.6,1.4-2.1,2.4c-0.4,0.9-0.6,1.9-0.6,2.8c0,0.9,0.2,1.6,0.5,2.3c0.5,1.3,1.2,2.1,1.8,2.7
						c1.2,1.2,2.1,1.6,2.4,1.4c0.4-0.3,0.1-1.2-0.4-2.6c-0.2-0.7-0.5-1.5-0.6-2.4c-0.1-0.9,0.1-1.5,0.5-2c0.4-0.4,1.1-0.8,2-0.9
						c0.8-0.1,1.9,0.3,2.9,1c1.1,0.7,2.1,1.8,3.1,2.9c0.9,1.2,1.7,2.5,2.4,3.9c1.3,3,1.8,5.9,1.9,8.5c0.1,2.6-0.2,5.1-0.8,7.5
						c-1.1,4.8-3.2,9.6-6.8,16.2c4.1-6.4,6.7-10.8,8.6-15.6c0.9-2.4,1.7-4.9,2-7.9C575.6,287.2,575.6,283.8,574.5,279.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M568.9,290c-0.3-0.5-0.5-1.1-0.8-1.6c-0.3-0.5-0.7-1.1-1.2-1.5c-0.9-1-1.9-1.8-3.4-2.3c-0.7-0.3-1.5-0.4-2.3-0.4
						c-0.8,0-1.6,0.3-2.3,0.6c-1.4,0.7-2.2,1.8-2.6,2.8c-0.5,1-0.6,2-0.6,2.7c0,0.8,0.1,1.4,0.3,1.9c0.3,1,0.7,1.5,1.2,1.5
						c0.4,0,0.9-0.5,1.3-1.4c0.4-0.8,0.9-2,1.5-2.7c0.3-0.4,0.7-0.6,1-0.7c0.3,0,0.5,0,0.9,0.1c0.4,0.2,1,0.5,1.6,1.1
						c0.3,0.3,0.6,0.5,0.8,0.9c0.3,0.3,0.5,0.7,0.8,1.1c1.1,1.5,1.8,3,2.3,4.5c0.5,1.4,0.8,2.8,0.9,4.2c0.1,1.4,0.1,2.9-0.3,4.5
						c-0.3,1.6-0.9,3.4-2.1,5.3c1.4-1.7,2.3-3.4,3-5c0.7-1.6,1.1-3.1,1.3-4.7c0.2-1.6,0.4-3.1,0.2-4.9
						C570.3,294.2,569.9,292.2,568.9,290z"
                    />
                  </g>
                  <g>
                    <path
                      d="M548.8,301.3c-1.9-0.3-3.8-0.3-5.2,0c-1.5,0.3-2.6,0.9-2.7,1.8c-0.2,0.9,0.7,1.8,2.3,2.4c1.6,0.6,3.8,0.8,5.9,0.2
						c2.2-0.6,3.6-1.2,5-1.9c1.5-0.7,2.9-1.4,4.7-2.2c-2.1,0.4-3.6,0.5-5.1,0.4C552.1,301.9,550.7,301.7,548.8,301.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M536.3,308.9c-5.8,0.2-11.1,2.8-10.6,4.5c0.4,1.7,6.2,1.7,11.5-0.2c5.5-1.9,7.5-3,13.4-4.3
						C544.5,309.2,542.3,308.8,536.3,308.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M525.6,316.8c-3.9-0.7-7.6,0.8-7.6,2.5c0.1,1.8,4,2.9,7.8,1.8c3.9-1.1,5.3-1.9,9.3-2.6
						C531,318.2,529.6,317.5,525.6,316.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M537.6,319c-2.2,1.1-3.9,2.9-4.8,4.6c-0.9,1.6-1.1,3.1-0.4,3.6c0.7,0.6,2,0.2,3.4-0.7c1.5-0.9,3.1-2.3,4.5-4
						c1.5-1.7,2.6-2.9,3.8-4.1c1.2-1.1,2.5-2.2,4.6-3.4c-2.4,0.7-4,1.3-5.6,1.8C541.5,317.3,539.9,317.8,537.6,319z"
                    />
                  </g>
                  <g>
                    <path
                      d="M545.3,322.1c-1.5,1.4-2.5,3.1-2.9,4.6c-0.4,1.5-0.2,2.7,0.6,3.2c0.8,0.4,1.9,0,3-1c1.1-1,2.2-2.5,2.9-4.3
						c1.5-3.7,1.8-5.3,4.1-8.8C549.9,318.6,548.4,319.3,545.3,322.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M554.7,308c-1.3,0.9-2.1,2.2-2.4,3.4c-0.3,1.2,0,2.2,0.7,2.7c0.7,0.5,1.8,0.3,2.7-0.3c1-0.6,1.9-1.7,2.4-3
						c1.1-2.8,1.3-4.1,3.2-6.7C558.7,305.8,557.4,306.2,554.7,308z"
                    />
                  </g>
                  <g>
                    <path
                      d="M532.3,341c-1.2,1.5-1.8,3.3-1.8,4.8c0,1.5,0.5,2.5,1.4,2.7c0.9,0.2,1.8-0.4,2.6-1.5c0.8-1,1.5-2.5,1.8-4.2
						c0.3-1.7,0.5-3,0.9-4.2c0.3-1.2,0.8-2.4,1.7-4.1c-1.4,1.3-2.3,2.2-3.3,3.1C534.5,338.6,533.5,339.5,532.3,341z"
                    />
                  </g>
                  <g>
                    <path
                      d="M533.2,356.5c-2.1,3.9-1.8,8.5-0.1,8.9c1.7,0.4,3.9-3.4,4.3-7.6c0.4-4.4,0.2-6.1,1.4-10.5
						C536.7,351.3,535.4,352.5,533.2,356.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M533.9,379.3c-0.4,2.4,0.2,4.9,1,6.6c0.8,1.7,2,2.6,2.8,2.3c0.8-0.3,1.2-1.6,1.3-3.2c0.1-1.7-0.2-3.7-0.7-5.8
						c-0.5-2.2-0.8-3.7-1-5.4c-0.2-1.6-0.2-3.3,0.2-5.7c-0.9,2.3-1.5,3.8-2.1,5.5C534.8,375.2,534.2,376.8,533.9,379.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M570.2,359.3c1.9-2.2,3.7-4.2,5-6c1.3-1.7,2.2-3.2,1.7-3.9c-0.5-0.7-2.3-0.5-4.4,0.8c-2.2,1.3-4.6,3.7-6,6.8
						c-1.4,3.1-1.9,5.4-2.3,7.6c-0.4,2.2-0.6,4.5-0.6,7.8c0.5-3.2,1.4-5.3,2.4-7.2C567.1,363.2,568.3,361.5,570.2,359.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M580.7,319.9c1.7-0.7,3-1.7,4-2.7c0.9-1,1.4-2.1,1.1-2.9c-0.4-0.8-1.6-1.1-3.1-0.8c-1.5,0.3-3.3,1.3-4.6,2.8
						c-1.3,1.6-2.1,2.8-2.8,4c-0.8,1.3-1.5,2.6-2.4,4.1c1.4-1.4,2.6-2.1,3.8-2.7C577.8,321.1,579,320.6,580.7,319.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-6.5,258.1c1.9-0.4,3.5-1.1,4.7-2c1.2-0.9,1.9-1.9,1.6-2.7c-0.2-0.8-1.4-1.3-3-1.2c-1.6,0.1-3.5,0.7-5.2,2
						c-1.7,1.3-2.7,2.3-3.8,3.3c-1,1-2.1,2-3.7,3.4c1.9-0.9,3.2-1.4,4.6-1.7C-9.8,258.7-8.5,258.5-6.5,258.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M8.4,246.2c-1.5,2.6-2.1,5.6-2,7.8c0.1,2.3,0.8,3.7,1.7,3.8c0.9,0.1,1.7-1.2,2.5-3.1c0.8-1.9,1.5-4.3,2-6.9
						c0.5-2.7,1.1-4.5,1.7-6.4c0.7-1.8,1.5-3.7,3.3-6.1c-2.1,2-3.5,3.5-4.9,5.1C11.2,242,9.9,243.6,8.4,246.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M544.8,223.7c-1.8-1.5-3.8-2.7-6-3.5c-2.1-0.9-4.5-1.4-6.9-1.4c-1.2,0-2.4,0.2-3.5,0.6c-1.2,0.4-2.3,0.9-3.2,1.6
						c-1.9,1.4-3.2,3.3-3.7,5.3c-0.3,1-0.3,2-0.2,3.1c0.2,1,0.5,1.8,0.9,2.5c0.9,1.4,2.1,2.3,3.1,2.7c1.1,0.4,2,0.4,2.6,0.2
						c0.6-0.2,0.8-0.4,0.8-0.6c-0.1-0.5-1.1-0.6-2.2-1.7c-1.1-1-2.1-3.1-1.2-5c0.4-1,1.3-2,2.4-2.7c0.6-0.4,1.2-0.6,1.9-0.8
						c0.7-0.2,1.5-0.3,2.2-0.3c3.2,0,7,1.4,10.2,3.5c3.3,2.2,5.7,4.8,7.4,7.5c0.9,1.3,1.6,2.7,2.2,4c0.6,1.4,1.1,2.7,1.5,4.1
						c0.8,2.8,1.2,5.8,1.2,9.2c0,3.4-0.4,7.1-1.6,11.5c1.4-4.3,2.2-8,2.5-11.5c0.3-3.4,0.2-6.5-0.3-9.6c-0.3-1.5-0.6-3.1-1-4.6
						c-0.5-1.5-1.1-3.1-1.9-4.7C550.7,230.1,548.4,226.7,544.8,223.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M547.9,236.8c-0.9-1.3-2.2-2.4-3.6-3.2c-1.5-0.8-3.3-1.2-5-1c-1.8,0.2-3.4,1.2-4.5,2.4c-1.1,1.2-1.7,2.9-1.6,4.4
						c0.1,1.7,1,2.9,1.8,3.6c0.9,0.7,1.9,1.1,2.7,1.1c0.8,0,1.4-0.2,1.8-0.5c0.3-0.3,0.4-0.6,0.3-0.8c-0.2-0.4-0.9-0.6-1.4-1.1
						c-0.3-0.3-0.6-0.6-0.8-1.1c-0.2-0.4-0.3-0.9-0.1-1.2c0.1-0.4,0.4-0.8,0.8-1.2c0.5-0.4,1.1-0.7,1.7-0.7c1.3-0.2,3.2,0.6,4.7,2
						c1.6,1.5,2.6,3.5,3.6,5.3c0.9,1.8,1.7,3.4,2.3,5.1c0.6,1.7,1.2,3.5,1.6,5.5c0.4,2,0.7,4.4,0.8,7.1c0.2-2.8,0.2-5.1,0.1-7.2
						c-0.1-2.1-0.3-4-0.6-5.9c-0.3-1.9-0.7-3.7-1.3-5.8c-0.3-1-0.6-2.1-1.1-3.3C549.5,239.4,548.9,238.1,547.9,236.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M613.1,514.1c0.5,1.2,0.7,2.4,0.8,3.5c0,1.1-0.1,2-0.4,2.7c-0.3,0.7-0.7,1.4-1.3,2.1c-0.6,0.6-1.4,1.3-2.4,1.7
						c-1.9,1-4,1.4-6.1,1.3c-2-0.1-3.6-0.8-4.7-1.5c-1.2-0.9-2-2.1-2.3-3.2c-0.4-1-0.3-2.3,0.1-3.4c0.4-1.1,0.9-2,1.4-2.8
						c0.5-0.8,0.9-1.4,0.9-2.1c0-0.6-0.6-1.3-1.9-1.6c-1.3-0.3-3.3,0-5.5,1.3c-1.1,0.7-2.1,1.6-3.1,2.9c-0.5,0.7-0.9,1.4-1.2,2.2
						c-0.4,0.8-0.6,1.8-0.8,2.7c-0.7,3.8,0.2,8.2,2.9,11.9c2.8,3.9,7.2,6.5,12,7.4c4.7,1,10.1,0.5,14.9-1.9c2.4-1.2,4.7-2.8,6.7-5
						c2-2.2,3.6-4.9,4.4-8c0.8-3,0.8-6.2,0.3-9c-0.6-2.9-1.7-5.4-3.1-7.7c-1.5-2.3-3.3-4.3-5.2-5.9c-1.9-1.6-4.1-2.9-6.1-3.7
						c-4.1-1.7-8-2.3-11.5-2.4c-7.1-0.1-12.9,1.4-18.6,3.1c-5.6,1.8-11.1,4.1-16.8,7.4c-5.7,3.2-11.6,7.4-17.6,13.4
						c6.6-5.4,12.9-8.7,18.8-11c5.9-2.3,11.4-3.5,16.8-4.2c5.4-0.6,11-0.7,16.3,0.5c2.7,0.6,5.3,1.6,7.5,3.1c1.2,0.8,2,1.6,2.8,2.6
						C611.9,511.7,612.6,512.9,613.1,514.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M586.9,487.9c2.8-1.8,5.2-4.2,7.1-6.8c1.9-2.6,3.2-5.3,3.9-7.8c0.7-2.5,0.8-4.7,0.6-6.3c-0.3-1.6-0.9-2.5-1.8-2.7
						c-1.8-0.3-3.7,2.1-6.3,5.3c-1.3,1.7-2.7,3.5-4.3,5.4c-1.6,2-3.3,4.1-5.1,6.3c-1.8,2.3-3.6,4.1-5.2,5.7
						c-1.7,1.6-3.2,3.1-4.9,4.4c-3.3,2.7-6.9,5.3-12.6,8.5c6.1-2.3,10.3-3.7,14.5-5.2c2.1-0.8,4.2-1.6,6.5-2.7
						C581.6,491.1,584.1,489.8,586.9,487.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M574,520.4c-1.2,2.3-1.3,5.1-0.8,7c0.5,1.9,1.5,3,2.4,2.8c0.9-0.2,1.4-1.4,1.8-3c0.4-1.6,0.7-3.5,0.8-5.6
						c0.2-2.2,0.5-3.7,1-5.3c0.5-1.5,1.3-3,2.9-4.9c-2,1.4-3.3,2.6-4.5,3.9C576.4,516.6,575.3,518,574,520.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M580.2,543.9c0.4,2.6,1.7,5,3.2,6.4c1.5,1.4,2.9,1.9,3.6,1.3c0.7-0.6,0.5-2,0-3.6c-0.5-1.6-1.4-3.6-2.5-5.5
						c-1.1-2-1.8-3.6-2.3-5.2c-0.5-1.6-0.9-3.3-0.8-5.9c-0.6,2.5-0.9,4.3-1.1,6.1C580,539.4,579.8,541.2,580.2,543.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M599.3,558c1.3,1.4,3,2.4,4.7,2.8c1.7,0.5,3.4,0.5,4.8,0.2c1.4-0.3,2.4-0.9,3-1.4c0.6-0.6,0.8-1.1,0.7-1.5
						c-0.4-0.8-2-0.9-3.9-1.2c-2-0.3-4.3-0.9-6.5-2.2c-1.2-0.7-2.1-1.5-2.9-2.3c-0.8-0.8-1.4-1.7-2-2.6c-1.2-1.8-2.1-3.9-3.1-7.1
						c0.5,3.3,0.8,5.6,1.4,7.8c0.3,1.1,0.6,2.3,1.2,3.5C597.2,555.2,598,556.6,599.3,558z"
                    />
                  </g>
                  <g>
                    <path
                      d="M613.7,553.2c2.2,1.1,4.7,1.3,6.5,1c1.8-0.3,3-1.2,2.9-2.1c-0.1-0.9-1.2-1.6-2.7-2.1c-1.5-0.5-3.5-0.9-5.5-1
						c-2.2-0.2-3.7-0.4-5.2-0.7c-1.5-0.4-3.1-0.9-5.1-2.2c1.7,1.7,2.9,2.8,4.2,3.9C610.1,551,611.5,552.1,613.7,553.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M623.6,545.9c1.5,0.1,2.9-0.6,3.8-1.4c0.8-0.8,1.1-1.9,0.7-2.6c-0.4-0.8-1.3-1.2-2.4-1.2c-1,0-2.2,0.3-3.3,1
						c-1.1,0.8-1.9,1.3-2.8,1.8c-0.9,0.5-1.8,0.9-3.2,1.2c1.4,0.3,2.5,0.5,3.5,0.7C620.9,545.6,622,545.8,623.6,545.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M584.7,454.4c0.8-4,0.4-8.1-0.6-11.1c-0.9-2.9-2.3-4.6-3.1-4.4c-0.9,0.3-1.1,2.3-1,5c0,2.8,0.2,6.4,0.3,10.1
						c0.1,3.8,0,6.5-0.4,9.1c-0.4,2.7-1,5.4-2.7,9.1c2.2-3.5,3.4-6,4.5-8.6C582.9,461.2,583.9,458.5,584.7,454.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M175.8,236.9c-0.3,3.3,0.7,6.7,2.2,8.7c1.4,2.1,3.1,2.9,3.7,2.4c0.7-0.5,0.5-2.1,0.1-4.1c-0.5-2.3-1-4.4-1.6-7.2
						c-0.5-2.8-0.6-4.8-0.4-6.9c0.2-2,0.7-4.2,2.3-6.9c-2.1,2.4-3.2,4.4-4.2,6.4C177,231.3,176.2,233.5,175.8,236.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M316.4,56.6c3.3,0.8,6.5,1.5,9,2c2.5,0.5,4.4,0.7,4.8-0.1c0.4-0.7-0.8-2.4-3.3-3.8c-2.5-1.5-6.3-2.6-10.1-2.5
						c-3.9,0.1-6.5,0.7-9.1,1.5c-2.5,0.8-5,1.6-8.5,3.4c3.6-1.2,6.2-1.4,8.7-1.5C310.5,55.6,313,55.9,316.4,56.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M166.4,149.7c1.4,3.4,3.9,6.3,6.1,7.9c2.2,1.7,4.1,2.1,4.7,1.5c0.6-0.7-0.3-2.3-1.7-4.4c-1.4-2.1-3.3-4.6-5.3-7.2
						c-2.1-2.7-3.3-4.7-4.4-6.9c-1.1-2.2-2-4.5-2.6-8.1c0.1,3.7,0.4,6.2,0.8,8.7C164.4,143.7,165,146.2,166.4,149.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M79.1,161.1c1.1-2.5,1.1-5.4,0.4-7.4c-0.7-2-1.8-3-2.6-2.8c-0.9,0.3-1.3,1.6-1.5,3.2c-0.3,1.7-0.4,3.7-0.5,5.9
						c-0.1,2.3-0.4,3.9-0.9,5.5c-0.5,1.6-1.3,3.2-3.1,5.1c2.1-1.5,3.4-2.8,4.7-4.2C76.7,165.2,77.9,163.7,79.1,161.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M340.8,260.3c3.5-6.8,4.5-14.6,2.8-15.1c-1.7-0.5-5.1,6.3-6.9,13.5c-0.9,3.8-1.5,6.4-2.2,9c-0.7,2.6-1.5,5.2-2.9,9
						c1.9-3.5,3.3-5.9,4.7-8.3C337.6,266.1,339,263.8,340.8,260.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M485,235.3c3.3,0,6.5-0.3,9-0.8c2.4-0.5,4.1-1.2,4.2-2.1c0-0.9-1.7-1.7-4.2-2.1c-2.6-0.4-6.1-0.3-9.4,0.6
						c-3.4,0.9-5.7,1.8-8,2.7c-2.4,1-4.7,1.9-7.8,3.1c3.5-0.8,5.9-1.1,8.3-1.3C479.3,235.3,481.6,235.3,485,235.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M596.5,141.5c-2.7,0.3-5.3,1.3-7,2.5c-1.7,1.2-2.6,2.4-2.2,3.2c0.4,0.8,1.9,0.9,3.8,0.6c1.9-0.3,4.3-1,6.5-2.1
						c2.4-1.1,4-1.9,5.8-2.5c1.7-0.7,3.5-1.2,6.3-1.7c-2.6-0.1-4.5-0.1-6.6-0.2C601.2,141.3,599.3,141.2,596.5,141.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M548.5,79c-2.4,1.8-4.6,3.6-6.2,5.2c-1.6,1.6-2.6,2.9-2.2,3.7c0.4,0.8,2.2,0.8,4.5-0.2c2.3-1,5.1-3,7.1-5.6
						c2-2.7,3.1-4.8,4-6.9c0.9-2.1,1.8-4.2,2.9-7.4c-1.5,3-3,4.7-4.5,6.3C552.6,75.7,550.9,77.1,548.5,79z"
                    />
                  </g>
                  <g>
                    <path
                      d="M482.8,101.2c3.5-0.6,7-1.5,9.5-2.4c2.6-0.9,4.3-1.9,4.2-2.8c-0.1-0.9-2.1-1.4-4.9-1.3c-2.8,0.1-6.6,0.8-10,2.3
						c-3.6,1.5-5.9,2.8-8.2,4.1c-2.3,1.3-4.6,2.7-7.9,4.7c3.6-1.5,6.1-2.3,8.6-2.9C476.6,102.3,479.2,101.9,482.8,101.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M503.3,526.1c2.5-1.7,3.3-4.9,2-6c-1.4-1.1-4.3,0.4-5.4,3.3c-1.2,2.9-1.3,4.3-2.8,7.1
						C499.4,528.3,500.7,527.9,503.3,526.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M281.3,518.4c2,0.3,3.8,0.4,5.3,0.2c1.5-0.1,2.7-0.5,3-1.3c0.3-0.8-0.5-2-2.2-2.8c-1.6-0.8-4.1-1.2-6.5-0.5
						c-2.4,0.7-3.9,1.5-5.3,2.3c-1.4,0.9-2.8,1.8-4.6,3.2c2.1-1,3.6-1.3,5.1-1.4C277.8,518,279.3,518.1,281.3,518.4z"
                    />
                  </g>
                </g>
              </g>
              <g>
                <defs>
                  <path
                    id="SVGID_00000082364927323383076760000007522621231310827196_"
                    d="M454.8,225.8c-28.1,30.7-167.7,144-191.4,122.2
					c-23.8-21.8,77.2-170.5,105.4-201.2c28.1-30.7,93.3-56.6,113.9-37.7C503.3,127.9,483,195.1,454.8,225.8z"
                  />
                </defs>
                <clipPath id="SVGID_00000063614274841399285390000013987804784858087080_">
                  <use
                    xlinkHref="#SVGID_00000082364927323383076760000007522621231310827196_"
                    overflow="visible"
                  />
                </clipPath>
              </g>
            </g>
          </g>
          <g id="Peddle1">
            <image
              overflow="visible"
              opacity="0.7"
              width="914"
              height="1777"
              xlinkHref={Shadow8}
              transform="matrix(0.24 0 0 0.24 178.2293 -14.7836)"
            ></image>
            <g>
              <g>
                <defs>
                  <path
                    id="SVGID_00000076585459576571415870000014107817466092407231_"
                    d="M346.1,138.5c1.8,41.6-16.7,220.4-48.9,221.8
					c-32.2,1.4-66-175.2-67.8-216.8c-1.8-41.6,25.9-106,53.8-107.2C311.2,35.1,344.3,96.9,346.1,138.5z"
                  />
                </defs>
                <clipPath id="SVGID_00000119087789011686223440000010744390885756443042_">
                  <use
                    xlinkHref="#SVGID_00000076585459576571415870000014107817466092407231_"
                    overflow="visible"
                  />
                </clipPath>
              </g>
              <g>
                <defs>
                  <path
                    id="SVGID_00000170252068621672515440000007795732343252364435_"
                    d="M346.1,138.5c1.8,41.6-16.7,220.4-48.9,221.8
					c-32.2,1.4-66-175.2-67.8-216.8c-1.8-41.6,25.9-106,53.8-107.2C311.2,35.1,344.3,96.9,346.1,138.5z"
                  />
                </defs>
                <use
                  xlinkHref="#SVGID_00000170252068621672515440000007795732343252364435_"
                  overflow="visible"
                  fill="#36B657"
                />
                <clipPath id="SVGID_00000131357347271445551140000016984376703213458847_">
                  <use
                    xlinkHref="#SVGID_00000170252068621672515440000007795732343252364435_"
                    overflow="visible"
                  />
                </clipPath>
                <g
                  opacity="0.2"
                  clipPath="url(#SVGID_00000131357347271445551140000016984376703213458847_)"
                >
                  <g>
                    <g>
                      <path
                        d="M312.1,219.6c8.1-1.2,15.4-3.8,20.8-7c5.4-3.2,8.8-6.9,8.1-10.4c-0.7-3.4-5.5-5.7-12.2-5.8c-6.7-0.1-15.4,2-22.9,6.8
							c-7.7,4.9-12.4,9-17.2,13c-4.7,4-9.4,8-16.3,13.7c8.1-3.8,13.9-5.6,19.8-6.9C297.9,221.7,303.8,220.8,312.1,219.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M314.5,239.9c7,1.8,13.9,2.1,19.5,1.1c5.5-1,9.7-3.2,10.5-6.6c0.8-3.4-2.4-7.3-8.1-9.9c-5.6-2.6-13.8-3.8-21.8-2.1
							c-8.2,1.8-13.5,3.9-18.8,6c-5.3,2.1-10.5,4.2-18,7.1c8-0.8,13.5-0.4,18.9,0.5C302,236.8,307.2,238.1,314.5,239.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M303.9,258.7c4.2,3.4,8.8,5.1,13,5.4c4.2,0.3,8.1-0.9,10-3.9c1.9-3,1.1-7.2-2.1-10.9c-3.1-3.7-8.6-6.8-14.9-7.1
							c-6.5-0.4-10.9,0.2-15.2,0.7c-4.3,0.5-8.6,1-14.6,1.5c5.8,1.7,9.5,3.7,12.9,5.9C296.4,252.6,299.5,255.2,303.9,258.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M282.6,276.1c1.5,4.7,4.5,8,8,9.7c3.4,1.7,7.3,1.9,10.2-0.1c2.9-2,4.1-5.7,3.3-9.8c-0.7-4-3.4-8.3-7.9-11
							c-4.6-2.8-8.1-4.2-11.5-5.8c-3.4-1.6-6.6-3.2-10.9-6.1c2.8,4.3,4.2,7.7,5.3,11.2C280.2,267.7,281.1,271.2,282.6,276.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M262,284.1c-1.5,5-1.3,9.6,0.1,13.5c1.3,3.8,3.8,7,7.3,7.6c3.4,0.7,7.1-1.5,9.3-5.8c2.2-4.2,3-10.5,0.8-16.4
							c-2.3-6-4.6-9.7-6.8-13.4c-2.3-3.6-4.5-7.1-7.6-12.2c1,5.8,0.8,9.9,0.1,13.8C264.5,275.2,263.5,279,262,284.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M238.4,276.8c-4.1,3.3-6.7,7-8.2,10.8c-0.3,1-0.7,1.9-0.9,2.9c-0.2,1-0.3,1.9-0.3,2.8c0,1.8,0.4,3.6,1.4,5
							c1,1.4,2.6,2.3,4.6,2.6c1,0.1,2.1,0.1,3.2-0.1c1.1-0.2,2.4-0.5,3.5-1.1c4.8-2.1,9.8-7.2,11.7-13.9c1.9-6.8,2-11.6,2-16.2
							c-0.1-4.6-0.3-9.1-0.8-15.3c-1.6,6-3.8,9.7-6.4,12.9C245.6,270.5,242.6,273.3,238.4,276.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M221.4,254.8c-5.9,0.7-10.8,2.7-14.6,5.4c-3.7,2.7-6.2,6.2-5.7,9.7c0.5,3.5,4.2,6.1,9.6,6.6
							c5.3,0.5,12.3-1.2,17.7-5.6c5.6-4.6,8.8-8.3,11.8-12c3-3.7,5.9-7.3,10.2-12.6c-5.6,3.6-10,5.2-14.3,6.3
							C231.8,253.5,227.4,254.1,221.4,254.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M214.7,232.3c-5.8-1.9-11.2-2.4-15.9-1.9c-1.2,0.2-2.3,0.3-3.3,0.7c-1.1,0.3-2,0.7-2.9,1.1c-1.7,0.9-3.1,2.2-3.7,3.8
							c-0.6,1.6-0.4,3.5,0.5,5.3c0.5,0.9,1.2,1.9,2,2.7c0.9,0.9,1.9,1.8,3.1,2.5c4.8,3.1,12.4,4.8,19.7,3.2c7.5-1.7,12.3-4,16.9-6.2
							c4.5-2.3,9-4.7,15-8.3c-6.9,1.6-11.7,1.4-16.4,0.8C225.1,235.5,220.6,234.3,214.7,232.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M221.6,214.1c-3.8-4.1-7-7.1-10.2-9.4c-1.6-1.2-3.2-2.2-4.8-3.1c-1.6-0.9-3.3-1.6-5.1-1.4c-1.7,0.1-3.3,1.2-4.4,3.3
							c-1.1,2.1-1.4,5.3-0.6,8.8c0.8,3.5,2.7,7.3,5.7,10.4c2.9,3.1,6.8,5.6,11,6.8c4.4,1.3,7.9,1.6,11.1,1.7
							c3.2,0.1,5.9-0.1,8.6-0.4c5.4-0.6,10.5-1.4,17.9-2.1c-7.3-1.5-12.2-3.2-16.6-5.4c-2.2-1.1-4.3-2.4-6.4-3.9
							C225.6,217.9,223.5,216.2,221.6,214.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M239.5,196.3c-0.1-5.5-0.9-9.8-2.3-13.6c-0.7-1.9-1.5-3.7-2.5-5.3c-1-1.6-2.2-2.9-3.9-3.6c-1.6-0.7-3.5-0.5-5.4,0.6
							c-1.9,1.1-3.7,3.3-4.9,6.3c-1.2,3-1.8,6.7-1.4,10.5c0.4,3.8,1.7,7.8,3.9,11.1c2.3,3.5,4.6,5.9,6.7,7.9c2.2,2,4.2,3.6,6.3,5.1
							c4.1,3,8.3,5.7,14,9.6c-4.3-5.5-6.5-9.9-8-14.2c-0.7-2.2-1.3-4.4-1.8-6.8C239.9,201.7,239.6,199.1,239.5,196.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M259.2,191.2c2.3-5.4,3.1-10.6,2.9-15.2c-0.1-1.2-0.2-2.2-0.4-3.3c-0.2-1.1-0.5-2-0.9-2.9c-0.8-1.8-1.9-3.2-3.5-4
							c-1.6-0.8-3.4-0.8-5.3,0c-1,0.4-2,1-2.9,1.8c-0.9,0.8-1.9,1.7-2.8,2.9c-3.5,4.5-5.8,11.9-4.6,19.3c1.3,7.6,3.3,12.4,5.3,17
							c2.1,4.6,4.3,9,7.7,15c-1.4-6.8-1-11.6-0.1-16C255.5,201.1,256.9,196.9,259.2,191.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M281.1,191.4c4.3-4.1,7.2-8,9.3-11.7c1-1.9,1.9-3.7,2.4-5.6c0.5-1.8,0.7-3.7,0-5.3c-0.6-1.6-2.1-2.9-4.3-3.4
							c-2.2-0.6-5.1-0.3-8.2,0.8c-3.1,1.2-6.3,3.3-8.9,6.2c-2.6,2.9-4.7,6.5-5.8,10.5c-1.2,4-1.6,7.4-1.8,10.4
							c-0.2,3-0.2,5.7-0.2,8.3c0.1,5.2,0.3,10.3,0,17.6c2.4-6.9,4.7-11.5,7.3-15.6c1.3-2.1,2.8-4,4.4-6
							C277.1,195.6,278.9,193.5,281.1,191.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M256.7,233.3c-1.5,3.2,1.5,6,5,5.6c3.5-0.4,5.8-3.7,3.7-6.5c-2.2-2.9-3.7-3.9-5.1-7
							C259.6,228.7,258.2,230,256.7,233.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M261.4,230.6c-1.4,1-1.8,2.3-1.5,3.7c0.3,1.4,1.3,2.8,2.8,3.6c1.5,0.9,3.3,0.9,4.7,0.2c1.4-0.7,2.3-2.2,2.1-4.1
							c-0.2-2-0.6-3.3-0.9-4.7c-0.3-1.3-0.6-2.6-0.7-4.4c-0.9,1.5-1.9,2.4-2.9,3.1C263.9,228.9,262.8,229.6,261.4,230.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M264.9,232.2c-1.3-0.6-2.1-0.2-2.9,0.7c-0.7,0.9-1.4,2.4-1.5,4.2c-0.1,1.7,0.7,3.3,2.1,4.1c1.4,0.8,3.3,0.7,4.8-0.7
							c1.5-1.4,2.2-2.7,2.8-3.8c0.6-1.2,1.2-2.3,2.1-3.6c-1.5,0.5-2.7,0.5-3.8,0.3C267.3,233.2,266.2,232.7,264.9,232.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M267.4,236.8c0-0.7-0.3-1.3-0.7-1.8c-0.5-0.5-1.1-1-1.9-1.2c-0.8-0.3-1.6-0.3-2.4-0.1c-0.8,0.2-1.5,0.6-2.1,1.3
							c-0.6,0.6-1.1,1.3-1.4,2c-0.3,0.7-0.6,1.4-0.6,2c0,0.7,0.1,1.3,0.5,2c0.4,0.6,1.1,1.3,2.1,1.7c1,0.5,2.1,0.8,3,1
							c0.9,0.2,1.7,0.2,2.5,0.1c1.6-0.1,3.1-0.5,4.5-1.6c-0.8,0.1-1.5-0.2-2-0.6c-0.5-0.4-0.8-0.8-1-1.3c-0.2-0.5-0.3-1-0.4-1.6
							C267.4,238,267.5,237.5,267.4,236.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M266.9,240c0.8-1.4,0.7-2.5-0.1-3.6c-0.7-1.1-2-2.2-3.8-2.6c-1.7-0.4-3.4,0.1-4.5,1.4c-1.1,1.3-1.4,3.5-0.4,5.4
							c1.1,2,2.1,3.1,3.2,4.1c1,1,2,2,3.2,3.4c-0.2-1.8,0-3.1,0.5-4.3C265.4,242.6,266.1,241.5,266.9,240z"
                      />
                    </g>
                    <g>
                      <path
                        d="M264.2,244.1c1.7-1.3,2.4-3.2,2.2-4.9c-0.2-1.7-1.4-3.1-3.1-3.7c-1.7-0.6-3.4-0.3-4.7,0.5c-1.3,0.8-2.1,2-2.1,3.8
							c0,1.9,0,3.3,0,4.7c-0.1,1.4-0.4,2.8-1.2,4.5c1.6-1,2.9-1.7,4.3-2.3C260.9,246.1,262.4,245.4,264.2,244.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M258.2,243.2c3.6,1.4,6.6-1.7,6.2-5.2c-0.4-3.5-4.2-5.7-7.3-3.5c-3.2,2.3-4.3,3.9-7.7,5.4
							C253,240.5,254.5,241.8,258.2,243.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M254.5,239.4c1.8,3.2,5.8,2.6,7.7-0.4c1.8-3,0.5-6.9-3.1-7c-3.7-0.1-5.5,0.5-8.9-0.3
							C252.5,234.3,252.7,236.1,254.5,239.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M255,235.1c0,1.7,1.1,2.7,2.4,3c1.3,0.3,3-0.2,4.3-1.3c1.4-1.1,2.1-2.6,2.3-3.8c0.1-1.2-0.4-2.3-1.8-2.8
							c-2.9-1-4.7-1.1-6.9-3C255.8,230.1,255,231.7,255,235.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M299.7,201.9c5.7-1.8,10.2-4.8,13.4-8.2c3.2-3.4,5-7.2,3.9-10.6c-1.1-3.3-5.3-5.2-10.8-4.6c-5.4,0.5-12,3.6-16.6,9.1
							c-4.7,5.7-7.1,10.1-9.4,14.4c-2.2,4.4-4.4,8.6-7.4,14.7c4.8-4.8,8.8-7.3,12.8-9.3C289.6,205.4,293.8,203.9,299.7,201.9z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M624.2,225.2c3.8,4.2,8.2,7.3,12.1,9.2c3.9,1.8,7.4,2.3,9.1,0.6c1.7-1.7,1.1-5.3-1.4-9.3c-2.6-3.9-7.1-8.1-12.8-10.5
							c-5.8-2.5-10-3.6-14.1-4.8c-4.1-1.1-8.3-2.3-14.2-4.1c5.3,3.1,8.6,5.8,11.6,8.7C617.5,217.9,620.3,220.9,624.2,225.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M613.5,234.5c1.7,4.7,4.2,8.8,6.9,11.6c2.7,2.8,5.6,4.4,7.9,3.4c2.2-0.9,3.3-4.2,2.6-8.5c-0.7-4.2-3.2-9.4-7.3-13.3
							c-4.2-4-7.5-6.2-10.7-8.5c-3.3-2.2-6.5-4.4-11.1-7.6c3.6,4.3,5.5,7.6,7.1,11C610.4,226.1,611.7,229.6,613.5,234.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M598.5,235.7c-0.3,3.7,0.5,7,2,9.6c1.5,2.5,3.7,4.3,6.1,4.2c2.4-0.1,4.5-2.2,5.4-5.4c0.9-3.2,0.5-7.6-1.7-11.3
							c-2.3-3.8-4.3-6.2-6.3-8.5c-2-2.3-3.9-4.5-6.5-7.8c1.3,4,1.6,6.8,1.6,9.7C599.1,229,598.9,231.8,598.5,235.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M580.4,230.2c-2.1,2.7-2.8,5.7-2.5,8.4c0.3,2.6,1.7,4.9,4,5.8c2.3,0.9,4.9,0.1,6.9-1.9c2-2,3.4-5.2,3.2-8.8
							c-0.2-3.7-0.7-6.3-1.1-8.9c-0.4-2.5-0.7-5.1-0.8-8.6c-1.4,3.3-2.8,5.4-4.3,7.4C584.2,225.6,582.5,227.5,580.4,230.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M567.7,221.5c-3.4,1.1-6,3-7.7,5.3c-1.7,2.3-2.5,4.9-1.5,7.2c0.9,2.2,3.6,3.5,7,3.1c3.3-0.4,7.2-2.4,9.7-5.9
							c2.6-3.6,3.8-6.4,5-9.1c1.2-2.7,2.3-5.4,4-9.1c-2.9,2.8-5.4,4.3-7.8,5.4C573.9,219.5,571.3,220.4,567.7,221.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M562.8,205.2c-3.5-1.1-6.6-1.1-9.4-0.5c-0.7,0.2-1.4,0.3-2,0.6c-0.6,0.3-1.2,0.6-1.7,0.9c-1.1,0.7-1.9,1.6-2.3,2.8
							c-0.4,1.1-0.3,2.4,0.3,3.6c0.3,0.6,0.7,1.2,1.3,1.8c0.6,0.6,1.2,1.1,2,1.6c3.1,1.9,8,2.8,12.5,1.3c4.6-1.6,7.4-3.3,10.1-5.1
							c2.6-1.8,5.1-3.7,8.4-6.4c-4.1,1.4-7.1,1.6-9.9,1.4C569.2,206.9,566.4,206.2,562.8,205.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M568.7,186.9c-2.6-3.1-5.7-5.2-8.7-6.3c-3-1.1-5.9-1.1-7.7,0.5c-1.8,1.6-1.9,4.8-0.1,8.1c1.8,3.3,5.4,6.6,10.1,8
							c4.8,1.4,8.2,1.8,11.5,2.1c3.3,0.3,6.5,0.5,11.2,0.9c-4.3-1.8-6.8-3.7-9.1-5.8C573.6,192.4,571.5,190.1,568.7,186.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M579.1,174.3c-1.1-4.1-3-7.4-5.1-9.9c-0.6-0.6-1.1-1.2-1.7-1.7c-0.6-0.5-1.2-0.9-1.8-1.2c-1.2-0.6-2.4-0.9-3.6-0.7
							c-1.2,0.3-2.2,1.1-2.8,2.4c-0.3,0.6-0.6,1.4-0.8,2.2c-0.2,0.8-0.3,1.8-0.2,2.8c0.1,4,2,9,5.8,12.6c3.9,3.7,7,5.5,10.1,7.2
							c3.1,1.7,6.2,3.3,10.6,5.4c-3.6-3.3-5.4-6.2-6.8-9.1C581.3,181.5,580.3,178.5,579.1,174.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M592.2,171.1c0.9-3.8,1.3-6.8,1.4-9.5c0.1-1.3,0-2.7-0.1-4c-0.1-1.3-0.4-2.5-1.2-3.5c-0.8-0.9-2-1.4-3.6-1.2
							c-1.6,0.2-3.6,1.2-5.3,3c-1.7,1.8-3.1,4.4-3.7,7.3c-0.6,2.9-0.6,6,0.3,8.9c0.9,3,2.1,5.2,3.3,7c1.2,1.8,2.4,3.3,3.6,4.8
							c2.4,2.9,4.9,5.5,8.1,9.5c-2-4.8-2.9-8.3-3.3-11.7c-0.2-1.7-0.3-3.4-0.3-5.2C591.6,175,591.7,173.1,592.2,171.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M609.4,174.5c3.1-2.2,5.3-4.3,6.9-6.6c0.8-1.1,1.5-2.3,2.1-3.5c0.5-1.2,0.8-2.4,0.6-3.6c-0.2-1.2-1.1-2.2-2.5-2.8
							c-1.4-0.6-3.3-0.8-5.5-0.4c-2.2,0.4-4.5,1.5-6.6,3.3c-2,1.7-3.8,4-4.8,6.5c-1.1,2.7-1.6,4.9-1.9,6.9c-0.3,2-0.4,3.8-0.5,5.6
							c-0.1,3.5,0,6.9-0.1,11.8c1.5-4.6,3.1-7.6,5.1-10.1c1-1.3,2-2.5,3.2-3.6C606.4,176.8,607.8,175.6,609.4,174.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M619.9,183.9c4-0.8,7.3-2.3,9.9-4.3c0.6-0.5,1.2-1,1.7-1.5c0.5-0.5,1-1.1,1.3-1.6c0.7-1.1,1.1-2.3,1-3.6
							c-0.2-1.2-0.9-2.3-2.1-3.1c-0.6-0.4-1.3-0.8-2.1-1c-0.8-0.2-1.7-0.4-2.7-0.5c-3.9-0.3-9.1,1.3-12.8,4.9
							c-3.8,3.7-5.8,6.7-7.7,9.6c-1.8,3-3.5,6-5.6,10.2c3.4-3.4,6.2-5.1,9.1-6.3C612.8,185.6,615.8,184.7,619.9,183.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M628.3,196.5c4,0.9,7.4,1,10.3,0.8c1.5-0.1,2.9-0.4,4.1-0.8c1.2-0.4,2.4-1,3.1-2c0.7-1,0.8-2.3,0.3-3.8
							c-0.5-1.5-1.8-3-3.7-4.4c-1.9-1.3-4.3-2.3-7-2.7c-2.7-0.4-5.6-0.2-8.3,0.7c-2.8,0.9-4.9,2-6.7,3c-1.8,1-3.3,2.1-4.8,3.1
							c-3,2.1-5.8,4.2-10.1,6.8c4.9-1.3,8.4-1.8,11.8-1.8c1.7,0,3.4,0,5.2,0.2C624.3,195.8,626.3,196,628.3,196.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M594.8,198.8c-2.4,0.4-2.8,3.2-1.3,5c1.6,1.9,4.4,1.9,5.2-0.4c0.8-2.4,0.8-3.7,2-5.7
							C598.6,198.6,597.3,198.3,594.8,198.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M598.2,200.4c-1.1-0.4-2-0.1-2.7,0.6c-0.7,0.7-1.1,1.8-1,3c0.1,1.2,0.8,2.2,1.7,2.7c0.9,0.5,2.2,0.5,3.2-0.4
							c1-0.9,1.7-1.6,2.3-2.3c0.6-0.7,1.3-1.4,2.2-2.1c-1.2,0-2.1-0.2-2.9-0.4C600.2,201.2,599.3,200.8,598.2,200.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M598.6,203c-0.2-0.9-0.7-1.3-1.5-1.4c-0.8-0.1-1.9,0.1-3,0.8c-1,0.6-1.7,1.7-1.6,2.8c0.1,1.1,0.9,2.2,2.2,2.5
							c1.4,0.3,2.4,0.2,3.3,0.1c0.9-0.1,1.8-0.2,2.9-0.2c-0.9-0.7-1.3-1.4-1.6-2.1C599,204.8,598.8,204,598.6,203z"
                      />
                    </g>
                    <g>
                      <path
                        d="M597,206.3c0.4-0.3,0.6-0.7,0.8-1.1c0.1-0.5,0.1-1,0-1.6c-0.1-0.5-0.4-1.1-0.9-1.4c-0.4-0.4-1-0.6-1.6-0.7
							c-0.6-0.1-1.2-0.1-1.7,0c-0.5,0.1-1,0.2-1.4,0.4c-0.4,0.2-0.7,0.6-0.9,1c-0.2,0.5-0.3,1.1-0.2,1.9c0.1,0.8,0.3,1.5,0.6,2.1
							c0.3,0.6,0.6,1.1,0.9,1.5c0.7,0.8,1.5,1.6,2.7,2c-0.4-0.5-0.5-1-0.4-1.4c0-0.4,0.2-0.8,0.4-1.1c0.2-0.3,0.5-0.6,0.7-0.9
							C596.3,206.7,596.6,206.6,597,206.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M594.9,207.2c1.1-0.1,1.7-0.6,2.1-1.5c0.3-0.8,0.5-2,0-3.2c-0.4-1.1-1.4-1.9-2.6-2c-1.2-0.1-2.5,0.5-3.2,1.9
							c-0.7,1.4-0.9,2.4-1.1,3.4c-0.2,1-0.4,1.9-0.7,3.2c0.9-0.8,1.8-1.2,2.7-1.4C592.9,207.4,593.8,207.3,594.9,207.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M591.5,207.3c1.4,0.5,2.8,0.1,3.7-0.7c0.9-0.8,1.2-2,0.9-3.2c-0.3-1.2-1.2-2.1-2.1-2.5c-0.9-0.4-2-0.4-3,0.3
							c-1.1,0.7-1.9,1.3-2.7,1.8c-0.8,0.5-1.7,0.9-3.1,1.1c1.2,0.5,2.1,1,3,1.6C589.1,206.1,590.1,206.7,591.5,207.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M589.7,203.4c0.6,2.6,3.6,3.1,5.4,1.5c1.8-1.6,1.7-4.6-0.8-5.5c-2.6-1-3.9-1-6.1-2.3
							C589.3,199.5,589.1,200.8,589.7,203.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M590.5,199.9c-1.1,2.2,0.8,4.3,3.2,4.2c2.4-0.1,4.2-2.4,2.8-4.5c-1.4-2.2-2.4-3-3.3-5.2
							C592.6,196.7,591.6,197.6,590.5,199.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M593.1,198.5c-0.9,0.7-1.1,1.7-0.8,2.5c0.4,0.9,1.3,1.6,2.4,2c1.2,0.3,2.3,0.2,3.1-0.2c0.8-0.4,1.2-1.1,0.9-2.1
							c-0.5-2-1.2-3.1-0.9-5.1C596.3,197,595.1,197.2,593.1,198.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M629.5,211.2c3.3,2.5,6.7,4,9.9,4.5c3.2,0.5,6.1,0.1,7.6-1.9c1.5-1.9,0.9-5.1-1.5-8c-2.4-2.9-6.8-5.5-11.7-6
							c-5.1-0.5-8.5-0.1-11.9,0.2c-3.4,0.4-6.7,0.8-11.3,1.5c4.6,0.9,7.6,2.2,10.3,3.7C623.6,206.8,626.1,208.6,629.5,211.2z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M241.1,523.3c3.8,4.2,8.2,7.3,12.1,9.2c3.9,1.8,7.4,2.3,9.1,0.6c1.7-1.7,1.1-5.3-1.4-9.3c-2.6-3.9-7.1-8.1-12.8-10.5
							c-5.8-2.5-10-3.6-14.1-4.8c-4.1-1.1-8.3-2.3-14.2-4.1c5.3,3.1,8.6,5.8,11.6,8.7C234.3,516,237.1,519,241.1,523.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M230.3,532.5c1.7,4.7,4.2,8.8,6.9,11.6c2.7,2.8,5.6,4.4,7.9,3.4c2.2-0.9,3.3-4.2,2.6-8.5c-0.7-4.2-3.2-9.4-7.3-13.3
							c-4.2-4-7.5-6.2-10.7-8.5c-3.3-2.2-6.5-4.4-11.1-7.6c3.6,4.3,5.5,7.6,7.1,11C227.3,524.1,228.6,527.6,230.3,532.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M215.4,533.7c-0.3,3.7,0.5,7,2,9.6c1.5,2.5,3.7,4.3,6.1,4.2c2.4-0.1,4.5-2.2,5.4-5.4c0.9-3.2,0.5-7.6-1.7-11.3
							c-2.3-3.8-4.3-6.2-6.3-8.5c-2-2.3-3.9-4.5-6.5-7.8c1.3,4,1.6,6.8,1.6,9.7C216,527.1,215.7,529.9,215.4,533.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M197.2,528.3c-2.1,2.7-2.8,5.7-2.5,8.4c0.3,2.6,1.7,4.9,4,5.8c2.3,0.9,4.9,0.1,6.9-1.9c2-2,3.4-5.2,3.2-8.8
							c-0.2-3.7-0.7-6.3-1.1-8.9c-0.4-2.5-0.7-5.1-0.8-8.6c-1.4,3.3-2.8,5.4-4.3,7.4C201.1,523.7,199.4,525.5,197.2,528.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M184.6,519.6c-3.4,1.1-6,3-7.7,5.3c-1.7,2.3-2.5,4.9-1.5,7.2c0.9,2.2,3.6,3.5,7,3.1c3.3-0.4,7.2-2.4,9.7-5.9
							c2.6-3.6,3.8-6.4,5-9.1c1.2-2.7,2.3-5.4,4-9.1c-2.9,2.8-5.4,4.3-7.8,5.4C190.7,517.6,188.1,518.4,184.6,519.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M179.7,503.2c-3.5-1.1-6.6-1.1-9.4-0.5c-0.7,0.2-1.4,0.3-2,0.6c-0.6,0.3-1.2,0.6-1.7,0.9c-1.1,0.7-1.9,1.6-2.3,2.8
							c-0.4,1.1-0.3,2.4,0.3,3.6c0.3,0.6,0.7,1.2,1.3,1.8c0.6,0.6,1.2,1.1,2,1.6c3.1,1.9,8,2.8,12.5,1.3c4.6-1.6,7.4-3.3,10.1-5.1
							c2.6-1.8,5.1-3.7,8.4-6.4c-4.1,1.4-7.1,1.6-9.9,1.4C186,504.9,183.3,504.3,179.7,503.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M185.6,484.9c-2.6-3.1-5.7-5.2-8.7-6.3c-3-1.1-5.9-1.1-7.7,0.5c-1.8,1.6-1.9,4.8-0.1,8.1c1.8,3.3,5.4,6.6,10.1,8
							c4.8,1.4,8.2,1.8,11.5,2.1c3.3,0.3,6.5,0.5,11.2,0.9c-4.3-1.8-6.8-3.7-9.1-5.8C190.4,490.4,188.4,488.1,185.6,484.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M195.9,472.3c-1.1-4.1-3-7.4-5.1-9.9c-0.6-0.6-1.1-1.2-1.7-1.7c-0.6-0.5-1.2-0.9-1.8-1.2c-1.2-0.6-2.4-0.9-3.6-0.7
							c-1.2,0.3-2.2,1.1-2.8,2.4c-0.3,0.6-0.6,1.4-0.8,2.2c-0.2,0.8-0.3,1.8-0.2,2.8c0.1,4,2,9,5.8,12.6c3.9,3.7,7,5.5,10.1,7.2
							c3.1,1.7,6.2,3.3,10.6,5.4c-3.6-3.3-5.4-6.2-6.8-9.1C198.2,479.5,197.1,476.5,195.9,472.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M209.1,469.2c0.9-3.8,1.3-6.8,1.4-9.5c0.1-1.3,0-2.7-0.1-4c-0.1-1.3-0.4-2.5-1.2-3.5c-0.8-0.9-2-1.4-3.6-1.2
							c-1.6,0.2-3.6,1.2-5.3,3c-1.7,1.8-3.1,4.4-3.7,7.3c-0.6,2.9-0.6,6,0.3,8.9c0.9,3,2.1,5.2,3.3,7c1.2,1.8,2.4,3.3,3.6,4.8
							c2.4,2.9,4.9,5.5,8.1,9.5c-2-4.8-2.9-8.3-3.3-11.7c-0.2-1.7-0.3-3.4-0.3-5.2C208.4,473,208.6,471.1,209.1,469.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M226.2,472.6c3.1-2.2,5.3-4.3,6.9-6.6c0.8-1.1,1.5-2.3,2.1-3.5c0.5-1.2,0.8-2.4,0.6-3.6c-0.2-1.2-1.1-2.2-2.5-2.8
							c-1.4-0.6-3.3-0.8-5.5-0.4c-2.2,0.4-4.5,1.5-6.6,3.3c-2,1.7-3.8,4-4.8,6.5c-1.1,2.7-1.6,4.9-1.9,6.9c-0.3,2-0.4,3.8-0.5,5.6
							c-0.1,3.5,0,6.9-0.1,11.8c1.5-4.6,3.1-7.6,5.1-10.1c1-1.3,2-2.5,3.2-3.6C223.3,474.9,224.6,473.7,226.2,472.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M236.8,481.9c4-0.8,7.3-2.3,9.9-4.3c0.6-0.5,1.2-1,1.7-1.5c0.5-0.5,1-1.1,1.3-1.6c0.7-1.1,1.1-2.3,1-3.6
							c-0.2-1.2-0.9-2.3-2.1-3.1c-0.6-0.4-1.3-0.8-2.1-1c-0.8-0.2-1.7-0.4-2.7-0.5c-3.9-0.3-9.1,1.3-12.8,4.9
							c-3.8,3.7-5.8,6.7-7.7,9.6c-1.8,3-3.5,6-5.6,10.2c3.4-3.4,6.2-5.1,9.1-6.3C229.7,483.6,232.7,482.8,236.8,481.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M245.2,494.6c4,0.9,7.4,1,10.3,0.8c1.5-0.1,2.9-0.4,4.1-0.8c1.2-0.4,2.4-1,3.1-2c0.7-1,0.8-2.3,0.3-3.8
							c-0.5-1.5-1.8-3-3.7-4.4c-1.9-1.3-4.3-2.3-7-2.7c-2.7-0.4-5.6-0.2-8.3,0.7c-2.8,0.9-4.9,2-6.7,3c-1.8,1-3.3,2.1-4.8,3.1
							c-3,2.1-5.8,4.2-10.1,6.8c4.9-1.3,8.4-1.8,11.8-1.8c1.7,0,3.4,0,5.2,0.2C241.2,493.9,243.1,494.1,245.2,494.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M211.7,496.8c-2.4,0.4-2.8,3.2-1.3,5c1.6,1.9,4.4,1.9,5.2-0.4c0.8-2.4,0.8-3.7,2-5.7
							C215.4,496.7,214.2,496.4,211.7,496.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M215,498.4c-1.1-0.4-2-0.1-2.7,0.6c-0.7,0.7-1.1,1.8-1,3c0.1,1.2,0.8,2.2,1.7,2.7c0.9,0.5,2.2,0.5,3.2-0.4
							c1-0.9,1.7-1.6,2.3-2.3c0.6-0.7,1.3-1.4,2.2-2.1c-1.2,0-2.1-0.2-2.9-0.4C217,499.2,216.2,498.9,215,498.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M215.5,501.1c-0.2-0.9-0.7-1.3-1.5-1.4c-0.8-0.1-1.9,0.1-3,0.8c-1,0.6-1.7,1.7-1.6,2.8c0.1,1.1,0.9,2.2,2.2,2.5
							c1.4,0.3,2.4,0.2,3.3,0.1c0.9-0.1,1.8-0.2,2.9-0.2c-0.9-0.7-1.3-1.4-1.6-2.1C215.9,502.9,215.7,502.1,215.5,501.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M213.9,504.3c0.4-0.3,0.6-0.7,0.8-1.1c0.1-0.5,0.1-1,0-1.6c-0.1-0.5-0.4-1.1-0.9-1.4c-0.4-0.4-1-0.6-1.6-0.7
							c-0.6-0.1-1.2-0.1-1.7,0c-0.5,0.1-1,0.2-1.4,0.4c-0.4,0.2-0.7,0.6-0.9,1c-0.2,0.5-0.3,1.1-0.2,1.9c0.1,0.8,0.3,1.5,0.6,2.1
							c0.3,0.6,0.6,1.1,0.9,1.5c0.7,0.8,1.5,1.6,2.7,2c-0.4-0.5-0.5-1-0.4-1.4c0-0.4,0.2-0.8,0.4-1.1c0.2-0.3,0.5-0.6,0.7-0.9
							C213.1,504.8,213.5,504.6,213.9,504.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M211.8,505.3c1.1-0.1,1.7-0.6,2.1-1.5c0.3-0.8,0.5-2,0-3.2c-0.4-1.1-1.4-1.9-2.6-2c-1.2-0.1-2.5,0.5-3.2,1.9
							c-0.7,1.4-0.9,2.4-1.1,3.4c-0.2,1-0.4,1.9-0.7,3.2c0.9-0.8,1.8-1.2,2.7-1.4C209.7,505.4,210.6,505.4,211.8,505.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M208.4,505.3c1.4,0.5,2.8,0.1,3.7-0.7c0.9-0.8,1.2-2,0.9-3.2c-0.3-1.2-1.2-2.1-2.1-2.5c-0.9-0.4-2-0.4-3,0.3
							c-1.1,0.7-1.9,1.3-2.7,1.8c-0.8,0.5-1.7,0.9-3.1,1.1c1.2,0.5,2.1,1,3,1.6C206,504.2,206.9,504.8,208.4,505.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M206.6,501.5c0.6,2.6,3.6,3.1,5.4,1.5c1.8-1.6,1.7-4.6-0.8-5.5c-2.6-1-3.9-1-6.1-2.3
							C206.1,497.5,205.9,498.8,206.6,501.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M207.3,497.9c-1.1,2.2,0.8,4.3,3.2,4.2c2.4-0.1,4.2-2.4,2.8-4.5c-1.4-2.2-2.4-3-3.3-5.2
							C209.5,494.8,208.5,495.6,207.3,497.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M210,496.6c-0.9,0.7-1.1,1.7-0.8,2.5c0.4,0.9,1.3,1.6,2.4,2c1.2,0.3,2.3,0.2,3.1-0.2c0.8-0.4,1.2-1.1,0.9-2.1
							c-0.5-2-1.2-3.1-0.9-5.1C213.2,495,211.9,495.2,210,496.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M246.4,509.3c3.3,2.5,6.7,4,9.9,4.5c3.2,0.5,6.1,0.1,7.6-1.9c1.5-1.9,0.9-5.1-1.5-8c-2.4-2.9-6.8-5.5-11.7-6
							c-5.1-0.5-8.5-0.1-11.9,0.2c-3.4,0.4-6.7,0.8-11.3,1.5c4.6,0.9,7.6,2.2,10.3,3.7C240.4,504.9,243,506.7,246.4,509.3z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M155.7,208.9c3.8,4.2,8.2,7.3,12.1,9.2c3.9,1.8,7.4,2.3,9.1,0.6c1.7-1.7,1.1-5.3-1.4-9.3c-2.6-3.9-7.1-8.1-12.8-10.5
							c-5.8-2.5-10-3.6-14.1-4.8c-4.1-1.1-8.3-2.3-14.2-4.1c5.3,3.1,8.6,5.8,11.6,8.7C148.9,201.6,151.7,204.6,155.7,208.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M144.9,218.2c1.7,4.7,4.2,8.8,6.9,11.6c2.7,2.8,5.6,4.4,7.9,3.4c2.2-0.9,3.3-4.2,2.6-8.5c-0.7-4.2-3.2-9.4-7.3-13.3
							c-4.2-4-7.5-6.2-10.7-8.5c-3.3-2.2-6.5-4.4-11.1-7.6c3.6,4.3,5.5,7.6,7.1,11C141.9,209.8,143.2,213.3,144.9,218.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M130,219.4c-0.3,3.7,0.5,7,2,9.6c1.5,2.5,3.7,4.3,6.1,4.2c2.4-0.1,4.5-2.2,5.4-5.4c0.9-3.2,0.5-7.6-1.7-11.3
							c-2.3-3.8-4.3-6.2-6.3-8.5c-2-2.3-3.9-4.5-6.5-7.8c1.3,4,1.6,6.8,1.6,9.7C130.6,212.7,130.3,215.5,130,219.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M111.8,214c-2.1,2.7-2.8,5.7-2.5,8.4c0.3,2.6,1.7,4.9,4,5.8c2.3,0.9,4.9,0.1,6.9-1.9c2-2,3.4-5.2,3.2-8.8
							c-0.2-3.7-0.7-6.3-1.1-8.9c-0.4-2.5-0.7-5.1-0.8-8.6c-1.4,3.3-2.8,5.4-4.3,7.4C115.7,209.3,114,211.2,111.8,214z"
                      />
                    </g>
                    <g>
                      <path
                        d="M99.2,205.2c-3.4,1.1-6,3-7.7,5.3c-1.7,2.3-2.5,4.9-1.5,7.2c0.9,2.2,3.6,3.5,7,3.1c3.3-0.4,7.2-2.4,9.7-5.9
							c2.6-3.6,3.8-6.4,5-9.1c1.2-2.7,2.3-5.4,4-9.1c-2.9,2.8-5.4,4.3-7.8,5.4C105.3,203.2,102.7,204.1,99.2,205.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M94.3,188.9c-3.5-1.1-6.6-1.1-9.4-0.5c-0.7,0.2-1.4,0.3-2,0.6c-0.6,0.3-1.2,0.6-1.7,0.9c-1.1,0.7-1.9,1.6-2.3,2.8
							c-0.4,1.1-0.3,2.4,0.3,3.6c0.3,0.6,0.7,1.2,1.3,1.8c0.6,0.6,1.2,1.1,2,1.6c3.1,1.9,8,2.8,12.5,1.3c4.6-1.6,7.4-3.3,10.1-5.1
							c2.6-1.8,5.1-3.7,8.4-6.4c-4.1,1.4-7.1,1.6-9.9,1.4C100.6,190.6,97.9,190,94.3,188.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M100.2,170.6c-2.6-3.1-5.7-5.2-8.7-6.3c-3-1.1-5.9-1.1-7.7,0.5c-1.8,1.6-1.9,4.8-0.1,8.1c1.8,3.3,5.4,6.6,10.1,8
							c4.8,1.4,8.2,1.8,11.5,2.1c3.3,0.3,6.5,0.5,11.2,0.9c-4.3-1.8-6.8-3.7-9.1-5.8C105,176.1,103,173.8,100.2,170.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M110.5,158c-1.1-4.1-3-7.4-5.1-9.9c-0.6-0.6-1.1-1.2-1.7-1.7c-0.6-0.5-1.2-0.9-1.8-1.2c-1.2-0.6-2.4-0.9-3.6-0.7
							c-1.2,0.3-2.2,1.1-2.8,2.4c-0.3,0.6-0.6,1.4-0.8,2.2c-0.2,0.8-0.3,1.8-0.2,2.8c0.1,4,2,9,5.8,12.6c3.9,3.7,7,5.5,10.1,7.2
							c3.1,1.7,6.2,3.3,10.6,5.4c-3.6-3.3-5.4-6.2-6.8-9.1C112.8,165.2,111.7,162.2,110.5,158z"
                      />
                    </g>
                    <g>
                      <path
                        d="M123.7,154.9c0.9-3.8,1.3-6.8,1.4-9.5c0.1-1.3,0-2.7-0.1-4c-0.1-1.3-0.4-2.5-1.2-3.5c-0.8-0.9-2-1.4-3.6-1.2
							c-1.6,0.2-3.6,1.2-5.3,3c-1.7,1.8-3.1,4.4-3.7,7.3c-0.6,2.9-0.6,6,0.3,8.9c0.9,3,2.1,5.2,3.3,7c1.2,1.8,2.4,3.3,3.6,4.8
							c2.4,2.9,4.9,5.5,8.1,9.5c-2-4.8-2.9-8.3-3.3-11.7c-0.2-1.7-0.3-3.4-0.3-5.2C123,158.7,123.2,156.8,123.7,154.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M140.8,158.2c3.1-2.2,5.3-4.3,6.9-6.6c0.8-1.1,1.5-2.3,2.1-3.5c0.5-1.2,0.8-2.4,0.6-3.6c-0.2-1.2-1.1-2.2-2.5-2.8
							c-1.4-0.6-3.3-0.8-5.5-0.4c-2.2,0.4-4.5,1.5-6.6,3.3c-2,1.7-3.8,4-4.8,6.5c-1.1,2.7-1.6,4.9-1.9,6.9c-0.3,2-0.4,3.8-0.5,5.6
							c-0.1,3.5,0,6.9-0.1,11.8c1.5-4.6,3.1-7.6,5.1-10.1c1-1.3,2-2.5,3.2-3.6C137.9,160.6,139.2,159.4,140.8,158.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M151.4,167.6c4-0.8,7.3-2.3,9.9-4.3c0.6-0.5,1.2-1,1.7-1.5c0.5-0.5,1-1.1,1.3-1.6c0.7-1.1,1.1-2.3,1-3.6
							c-0.2-1.2-0.9-2.3-2.1-3.1c-0.6-0.4-1.3-0.8-2.1-1c-0.8-0.2-1.7-0.4-2.7-0.5c-3.9-0.3-9.1,1.3-12.8,4.9
							c-3.8,3.7-5.8,6.7-7.7,9.6c-1.8,3-3.5,6-5.6,10.2c3.4-3.4,6.2-5.1,9.1-6.3C144.2,169.3,147.3,168.5,151.4,167.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M159.8,180.2c4,0.9,7.4,1,10.3,0.8c1.5-0.1,2.9-0.4,4.1-0.8c1.2-0.4,2.4-1,3.1-2c0.7-1,0.8-2.3,0.3-3.8
							c-0.5-1.5-1.8-3-3.7-4.4c-1.9-1.3-4.3-2.3-7-2.7c-2.7-0.4-5.6-0.2-8.3,0.7c-2.8,0.9-4.9,2-6.7,3c-1.8,1-3.3,2.1-4.8,3.1
							c-3,2.1-5.8,4.2-10.1,6.8c4.9-1.3,8.4-1.8,11.8-1.8c1.7,0,3.4,0,5.2,0.2C155.8,179.5,157.7,179.8,159.8,180.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M126.3,182.5c-2.4,0.4-2.8,3.2-1.3,5c1.6,1.9,4.4,1.9,5.2-0.4c0.8-2.4,0.8-3.7,2-5.7
							C130,182.3,128.8,182.1,126.3,182.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M129.6,184.1c-1.1-0.4-2-0.1-2.7,0.6c-0.7,0.7-1.1,1.8-1,3c0.1,1.2,0.8,2.2,1.7,2.7c0.9,0.5,2.2,0.5,3.2-0.4
							c1-0.9,1.7-1.6,2.3-2.3c0.6-0.7,1.3-1.4,2.2-2.1c-1.2,0-2.1-0.2-2.9-0.4C131.6,184.9,130.8,184.5,129.6,184.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M130.1,186.7c-0.2-0.9-0.7-1.3-1.5-1.4c-0.8-0.1-1.9,0.1-3,0.8c-1,0.6-1.7,1.7-1.6,2.8c0.1,1.1,0.9,2.2,2.2,2.5
							c1.4,0.3,2.4,0.2,3.3,0.1c0.9-0.1,1.8-0.2,2.9-0.2c-0.9-0.7-1.3-1.4-1.6-2.1C130.5,188.5,130.3,187.7,130.1,186.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M128.5,190c0.4-0.3,0.6-0.7,0.8-1.1c0.1-0.5,0.1-1,0-1.6c-0.1-0.5-0.4-1.1-0.9-1.4c-0.4-0.4-1-0.6-1.6-0.7
							c-0.6-0.1-1.2-0.1-1.7,0c-0.5,0.1-1,0.2-1.4,0.4c-0.4,0.2-0.7,0.6-0.9,1c-0.2,0.5-0.3,1.1-0.2,1.9c0.1,0.8,0.3,1.5,0.6,2.1
							c0.3,0.6,0.6,1.1,0.9,1.5c0.7,0.8,1.5,1.6,2.7,2c-0.4-0.5-0.5-1-0.4-1.4c0-0.4,0.2-0.8,0.4-1.1c0.2-0.3,0.5-0.6,0.7-0.9
							C127.7,190.4,128.1,190.3,128.5,190z"
                      />
                    </g>
                    <g>
                      <path
                        d="M126.4,190.9c1.1-0.1,1.7-0.6,2.1-1.5c0.3-0.8,0.5-2,0-3.2c-0.4-1.1-1.4-1.9-2.6-2c-1.2-0.1-2.5,0.5-3.2,1.9
							c-0.7,1.4-0.9,2.4-1.1,3.4c-0.2,1-0.4,1.9-0.7,3.2c0.9-0.8,1.8-1.2,2.7-1.4C124.3,191.1,125.2,191,126.4,190.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M123,191c1.4,0.5,2.8,0.1,3.7-0.7c0.9-0.8,1.2-2,0.9-3.2c-0.3-1.2-1.2-2.1-2.1-2.5c-0.9-0.4-2-0.4-3,0.3
							c-1.1,0.7-1.9,1.3-2.7,1.8c-0.8,0.5-1.7,0.9-3.1,1.1c1.2,0.5,2.1,1,3,1.6C120.6,189.9,121.5,190.4,123,191z"
                      />
                    </g>
                    <g>
                      <path
                        d="M121.2,187.2c0.6,2.6,3.6,3.1,5.4,1.5c1.8-1.6,1.7-4.6-0.8-5.5c-2.6-1-3.9-1-6.1-2.3
							C120.7,183.2,120.5,184.5,121.2,187.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M121.9,183.6c-1.1,2.2,0.8,4.3,3.2,4.2c2.4-0.1,4.2-2.4,2.8-4.5c-1.4-2.2-2.4-3-3.3-5.2
							C124.1,180.4,123.1,181.3,121.9,183.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M124.6,182.2c-0.9,0.7-1.1,1.7-0.8,2.5c0.4,0.9,1.3,1.6,2.4,2c1.2,0.3,2.3,0.2,3.1-0.2c0.8-0.4,1.2-1.1,0.9-2.1
							c-0.5-2-1.2-3.1-0.9-5.1C127.8,180.7,126.5,180.9,124.6,182.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M161,194.9c3.3,2.5,6.7,4,9.9,4.5c3.2,0.5,6.1,0.1,7.6-1.9c1.5-1.9,0.9-5.1-1.5-8c-2.4-2.9-6.8-5.5-11.7-6
							c-5.1-0.5-8.5-0.1-11.9,0.2c-3.4,0.4-6.7,0.8-11.3,1.5c4.6,0.9,7.6,2.2,10.3,3.7C155,190.5,157.6,192.3,161,194.9z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M353.7,131.6c-6.1,1.4-12.7,4.3-17.8,8c-2.6,1.8-4.9,3.9-7,6c-2,2.1-3.7,4.3-5.1,6.4c-2.8,4.3-4.2,8.4-4.6,11.5
							c-0.2,1.6-0.1,2.9,0.2,3.9c0.3,1,0.9,1.8,1.6,2.2c0.8,0.4,1.7,0.6,2.7,0.4c1-0.2,2.2-0.6,3.5-1.2c2.5-1.2,5.5-3.2,8.8-5.5
							c1.6-1.1,3.4-2.3,5.2-3.6c1.8-1.2,3.8-2.5,5.8-3.8c4-2.7,8.2-5.1,13.1-8c5-3,9.8-5.5,14-8.2c4.2-2.6,8-5.4,11.3-8.5
							c1.7-1.5,3.3-3.1,4.8-4.8c1.6-1.7,3.1-3.5,4.6-5.5c3-3.9,6-8.5,8.6-14.3c-3.6,5.3-7.4,9-11.1,11.8c-1.9,1.4-3.7,2.6-5.5,3.7
							c-1.8,1.1-3.7,2-5.5,2.7c-3.7,1.6-7.5,2.6-11.9,3.5C365,129.4,360,130.1,353.7,131.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M362.6,150.6c-5,2.2-10,4.1-14.7,5.9c-4.7,1.7-9.2,3.3-13.1,4.7c-4,1.4-7.4,2.8-9.9,4.2c-2.5,1.4-4.1,2.9-4.3,4.7
							c-0.1,1.7,1.3,3.5,4.2,5c2.8,1.5,7.1,2.5,12.3,2.7c5.2,0.2,11.2-0.6,17.4-2.6c6.1-2.1,12.3-5.3,17.6-9.8
							c5.4-4.6,9.3-9,12.4-13.3c3.1-4.3,5.4-8.3,7.5-12.4c2-4.1,3.8-8.4,5.4-13.2c1.6-4.8,3-10.2,4-16.7c-2,6.2-4.5,11.1-7.2,15.2
							c-2.6,4.1-5.4,7.4-8.4,10.3c-3,3-6.2,5.6-10,8.1C372,145.9,367.8,148.3,362.6,150.6z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M401.3,173.5c-6-1.9-13.1-2.8-19.4-2.2c-3.2,0.3-6.2,0.8-9,1.6c-2.8,0.8-5.4,1.8-7.7,2.9c-4.6,2.3-7.9,5-9.8,7.5
							c-1,1.2-1.6,2.4-1.8,3.5c-0.3,1.1-0.2,2,0.3,2.7c0.4,0.8,1.2,1.3,2.2,1.7c1,0.4,2.2,0.6,3.6,0.8c2.8,0.2,6.3,0.1,10.3-0.2
							c2-0.1,4.1-0.3,6.4-0.4c2.2-0.1,4.5-0.3,6.9-0.4c4.8-0.2,9.7-0.2,15.4-0.2c5.9,0,11.2,0.3,16.2,0.1c5-0.1,9.7-0.6,14.1-1.5
							c2.2-0.4,4.4-1,6.6-1.7c2.2-0.7,4.4-1.4,6.7-2.4c4.6-1.9,9.5-4.3,14.7-7.9c-5.8,2.7-10.9,3.9-15.6,4.5
							c-2.3,0.3-4.5,0.4-6.7,0.3c-2.1,0-4.2-0.2-6.2-0.5c-4-0.5-7.8-1.6-12-3C412.2,177.4,407.6,175.5,401.3,173.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M399.3,194.3c-5.4-0.7-10.7-1.6-15.6-2.5c-4.9-0.9-9.6-1.9-13.7-2.7c-4.1-0.8-7.8-1.4-10.7-1.5
							c-2.9-0.1-5.1,0.4-6.1,1.9c-1,1.4-0.6,3.7,1.1,6.4c1.7,2.7,4.8,5.8,9.2,8.6c4.3,2.8,9.9,5.3,16.3,6.7
							c6.3,1.4,13.3,1.8,20.1,0.6c7-1.1,12.6-3,17.5-5c4.8-2.1,8.9-4.4,12.8-6.9c3.9-2.5,7.6-5.3,11.4-8.6c3.8-3.3,7.8-7.3,12-12.3
							c-4.9,4.3-9.6,7.2-13.9,9.4c-4.4,2.2-8.4,3.5-12.5,4.6c-4.1,1-8.2,1.6-12.7,1.9C409.8,195.2,404.9,195.1,399.3,194.3z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M143.2,295.9c4-4.8,7.5-11.1,9.3-17.2c0.9-3.1,1.6-6.1,1.9-9c0.3-2.9,0.3-5.7,0.2-8.2c-0.4-5.1-1.7-9.2-3.3-11.9
							c-0.8-1.4-1.7-2.4-2.6-3c-0.9-0.6-1.8-0.9-2.6-0.8c-0.9,0.1-1.7,0.6-2.4,1.3c-0.7,0.8-1.4,1.8-2,3.1c-1.3,2.5-2.4,5.9-3.7,9.7
							c-0.6,1.9-1.3,3.9-2,6.1c-0.7,2.1-1.4,4.3-2.2,6.6c-1.6,4.6-3.4,9-5.5,14.3c-2.2,5.4-4.4,10.3-6.2,15
							c-1.7,4.7-3.1,9.2-3.8,13.6c-0.4,2.2-0.7,4.5-0.9,6.8c-0.2,2.3-0.3,4.7-0.3,7.1c0,4.9,0.4,10.4,1.8,16.6
							c-0.4-6.3,0.4-11.6,1.6-16.1c0.6-2.3,1.3-4.4,2.2-6.3c0.8-2,1.7-3.8,2.7-5.5c2-3.5,4.4-6.6,7.3-10
							C135.6,304.6,139.1,300.9,143.2,295.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M124.7,286.2c2.7-4.8,5.5-9.3,8.1-13.6c2.7-4.3,5.3-8.2,7.6-11.7c2.3-3.5,4.2-6.7,5.4-9.3c1.2-2.6,1.5-4.9,0.5-6.3
							c-1-1.5-3.2-2-6.4-1.4c-3.2,0.6-7.2,2.3-11.4,5.3c-4.2,3-8.6,7.3-12.2,12.6c-3.6,5.4-6.6,11.7-8.1,18.4
							c-1.5,6.9-1.9,12.8-1.8,18.1c0.1,5.3,0.7,9.9,1.6,14.4c0.9,4.5,2.1,9,3.7,13.8c1.7,4.8,3.8,9.9,7,15.7
							c-2.2-6.2-3.2-11.6-3.5-16.4c-0.4-4.8-0.2-9.2,0.4-13.3c0.6-4.2,1.6-8.2,3-12.5C120,295.7,121.9,291.1,124.7,286.2z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M453.4,473.1c-7.8,2.4-14.6,6.2-19.4,10.2c-4.8,4-7.6,8.2-6.4,11.6c1.2,3.3,6.3,4.8,13,3.8c6.7-1,14.9-4.4,21.5-10.4
							c6.8-6.1,10.9-10.9,14.9-15.6c4-4.7,8-9.4,14-16.1c-7.3,5.1-12.9,7.7-18.4,9.9C467.1,468.8,461.4,470.6,453.4,473.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M447.9,453.4c-7.2-0.7-14.1,0.2-19.4,2c-5.3,1.8-9.1,4.7-9.3,8.2c-0.2,3.5,3.5,6.8,9.5,8.5c6,1.7,14.2,1.6,21.8-1.4
							c7.8-3.1,12.7-6,17.6-8.9c4.9-2.9,9.7-5.8,16.7-9.9c-7.8,2.1-13.3,2.5-18.7,2.5C460.7,454.5,455.3,454.1,447.9,453.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M455.3,433.2c-4.7-2.7-9.5-3.7-13.7-3.3c-4.2,0.4-7.9,2.2-9.3,5.4c-1.4,3.2,0.1,7.2,3.8,10.4
							c3.7,3.1,9.6,5.3,15.9,4.7c6.4-0.7,10.7-1.9,14.9-3.1c4.2-1.2,8.3-2.4,14.1-3.8c-6-0.7-9.9-2.1-13.6-3.8
							C463.7,438,460.2,435.9,455.3,433.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M473.5,412.7c-2.2-4.4-5.7-7.2-9.4-8.4c-3.7-1.2-7.5-0.8-10.1,1.7c-2.6,2.4-3.1,6.3-1.8,10.2c1.4,3.9,4.7,7.6,9.6,9.6
							c5,2,8.7,2.9,12.3,3.9c3.6,1,7.1,2.1,11.8,4.3c-3.4-3.8-5.3-7-7-10.2C477.3,420.6,475.8,417.2,473.5,412.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M492.7,401.5c0.7-5.1-0.3-9.7-2.2-13.3c-1.9-3.6-4.9-6.3-8.4-6.4c-3.5-0.1-6.7,2.6-8.3,7.2
							c-1.5,4.5-1.3,10.8,1.8,16.3c3.2,5.6,6.1,8.9,8.9,12.1c2.8,3.2,5.6,6.3,9.4,10.8c-1.9-5.5-2.3-9.6-2.3-13.6
							C491.6,410.7,492,406.8,492.7,401.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M517.1,405c3.5-3.9,5.5-8,6.4-12c0.2-1,0.4-2,0.4-3c0-1,0-1.9-0.1-2.8c-0.3-1.8-1-3.5-2.2-4.7
							c-1.3-1.2-2.9-1.9-4.9-1.8c-1,0-2.1,0.2-3.1,0.6c-1.1,0.4-2.2,0.9-3.3,1.6c-4.4,2.8-8.5,8.7-9.3,15.5
							c-0.8,7-0.1,11.7,0.6,16.3c0.8,4.5,1.7,8.9,3.2,15c0.6-6.2,2.3-10.2,4.3-13.8C511,412.3,513.5,409,517.1,405z"
                      />
                    </g>
                    <g>
                      <path
                        d="M537.4,423.9c5.7-1.6,10.3-4.3,13.5-7.6c3.2-3.2,5.1-7.1,4.1-10.4c-1-3.3-5.1-5.3-10.5-5c-5.4,0.3-11.9,3.1-16.6,8.4
							c-4.8,5.4-7.3,9.6-9.8,13.8c-2.3,4.1-4.7,8.2-8.1,14.1c5-4.5,9-6.7,13.1-8.4C527.3,426.9,531.5,425.6,537.4,423.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M547.6,445.1c6,1,11.5,0.6,16-0.7c1.1-0.4,2.2-0.7,3.2-1.2c1-0.5,1.9-1,2.7-1.6c1.6-1.2,2.7-2.6,3.1-4.4
							c0.4-1.7-0.1-3.5-1.4-5.2c-0.6-0.8-1.4-1.7-2.5-2.4c-1-0.7-2.2-1.5-3.5-2c-5.2-2.3-13-2.8-20,0c-7.2,2.9-11.5,5.9-15.7,8.8
							c-4.1,3-8.1,6.1-13.5,10.5c6.6-2.7,11.4-3.3,16-3.4C536.8,443.6,541.4,444.1,547.6,445.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M543.7,464.2c4.4,3.5,8.1,5.9,11.5,7.7c1.7,0.9,3.5,1.7,5.2,2.3c1.7,0.6,3.5,1,5.3,0.6c1.7-0.4,3.1-1.7,3.8-4
							c0.7-2.2,0.6-5.4-0.8-8.8c-1.3-3.3-3.9-6.8-7.2-9.3c-3.4-2.6-7.5-4.4-11.9-5c-4.5-0.6-8.1-0.4-11.2,0
							c-3.1,0.4-5.8,1.1-8.4,1.8c-5.2,1.4-10.1,3.1-17.4,4.9c7.5,0.3,12.6,1.2,17.3,2.7c2.3,0.8,4.6,1.7,6.9,2.8
							C539.1,461,541.5,462.4,543.7,464.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M528.8,484.6c1,5.4,2.5,9.5,4.4,13.1c1,1.8,2.1,3.4,3.3,4.8c1.2,1.4,2.7,2.5,4.4,2.9c1.7,0.4,3.5,0,5.2-1.5
							c1.7-1.4,3.1-3.9,3.9-7c0.7-3.1,0.7-6.9-0.2-10.6c-1-3.7-2.9-7.4-5.6-10.3c-2.8-3.1-5.4-5.1-7.9-6.8c-2.5-1.7-4.7-2.9-7-4.1
							c-4.6-2.3-9.1-4.3-15.4-7.3c5.1,4.8,8,8.7,10.2,12.8c1.1,2,2,4.1,2.8,6.4C527.6,479.3,528.3,481.8,528.8,484.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M510.1,492.7c-1.4,5.7-1.4,11-0.4,15.5c0.3,1.1,0.5,2.2,0.9,3.2c0.4,1,0.8,1.9,1.3,2.7c1,1.6,2.4,2.9,4.1,3.4
							c1.7,0.5,3.5,0.2,5.3-0.9c0.9-0.5,1.8-1.3,2.6-2.2c0.8-0.9,1.6-2,2.3-3.3c2.7-5,3.8-12.7,1.5-19.8c-2.4-7.3-5.2-11.7-7.9-15.9
							c-2.8-4.2-5.7-8.2-10-13.6c2.4,6.5,2.8,11.3,2.7,15.8C512.2,482.3,511.5,486.8,510.1,492.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M488.5,496c-3.6,4.8-5.9,9-7.3,13c-0.7,2-1.3,4-1.5,5.9c-0.2,1.9-0.1,3.7,0.8,5.3c0.9,1.5,2.5,2.5,4.7,2.7
							c2.2,0.2,5.1-0.5,7.9-2.1c2.8-1.6,5.7-4.3,7.8-7.6c2.1-3.3,3.6-7.2,4.1-11.2c0.5-4.2,0.4-7.6,0.2-10.6c-0.3-3-0.7-5.6-1.1-8.2
							c-0.9-5.1-1.9-10.1-2.8-17.4c-1.3,7.2-2.8,12.1-4.8,16.5c-1,2.2-2.1,4.4-3.4,6.7C491.8,491.2,490.3,493.6,488.5,496z"
                      />
                    </g>
                    <g>
                      <path
                        d="M505.9,450.8c0.9-3.4-2.4-5.6-5.8-4.7c-3.4,0.9-5.2,4.5-2.7,7c2.6,2.5,4.3,3.3,6.2,6.1
							C503.8,455.8,505,454.3,505.9,450.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M501.8,454.2c1.2-1.2,1.4-2.6,0.9-3.9c-0.5-1.3-1.7-2.5-3.4-3.1c-1.7-0.6-3.4-0.4-4.6,0.6c-1.2,0.9-1.9,2.6-1.4,4.4
							c0.5,1.9,1.1,3.2,1.6,4.5c0.5,1.3,1,2.5,1.4,4.2c0.7-1.6,1.5-2.6,2.4-3.6C499.5,456.3,500.5,455.4,501.8,454.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M498,453.2c1.3,0.3,2.1-0.1,2.7-1.2c0.6-1,1-2.6,0.8-4.4c-0.2-1.7-1.2-3.2-2.7-3.8c-1.5-0.6-3.4-0.2-4.6,1.4
							c-1.2,1.6-1.7,3-2.2,4.2c-0.4,1.3-0.8,2.4-1.5,3.9c1.4-0.8,2.6-0.9,3.7-0.9C495.5,452.6,496.6,452.9,498,453.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M494.8,449.1c0.1,0.7,0.5,1.2,1,1.7c0.5,0.4,1.3,0.8,2.1,0.9c0.8,0.1,1.7,0.1,2.4-0.3c0.8-0.3,1.4-0.9,1.9-1.6
							c0.5-0.7,0.8-1.5,1.1-2.2c0.2-0.7,0.3-1.4,0.3-2.1c-0.1-0.7-0.3-1.3-0.8-1.8c-0.5-0.6-1.3-1.1-2.4-1.4
							c-1.1-0.3-2.2-0.5-3.1-0.5c-0.9,0-1.7,0.1-2.5,0.3c-1.5,0.4-3,0.9-4.2,2.3c0.8-0.2,1.5,0,2.1,0.3c0.5,0.3,0.9,0.7,1.2,1.2
							c0.3,0.5,0.5,1,0.7,1.5C494.6,447.8,494.6,448.4,494.8,449.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M494.8,445.7c-0.6,1.5-0.3,2.6,0.6,3.6c0.9,1,2.4,1.8,4.1,2c1.7,0.2,3.4-0.6,4.2-2.1c0.9-1.5,0.9-3.7-0.5-5.4
							c-1.4-1.8-2.6-2.7-3.8-3.6c-1.2-0.9-2.3-1.7-3.7-2.8c0.5,1.8,0.5,3.1,0.2,4.4C495.9,443,495.4,444.2,494.8,445.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M496.9,441.3c-1.5,1.5-1.9,3.6-1.4,5.2c0.5,1.6,1.9,2.8,3.6,3.1c1.7,0.3,3.4-0.2,4.5-1.2c1.1-1,1.7-2.3,1.5-4.1
							c-0.3-1.8-0.6-3.2-0.7-4.6c-0.1-1.4-0.1-2.8,0.5-4.7c-1.5,1.3-2.6,2.1-3.9,3C499.7,438.9,498.4,439.7,496.9,441.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M502.9,441.2c-3.7-0.8-6.3,2.7-5.3,6.1c1,3.4,5,5,7.7,2.3c2.8-2.8,3.6-4.5,6.8-6.6C508.4,443.1,506.8,442,502.9,441.2
							z"
                      />
                    </g>
                    <g>
                      <path
                        d="M507.1,444.4c-2.2-2.9-6.2-1.6-7.5,1.7c-1.3,3.3,0.6,6.9,4.2,6.5c3.7-0.5,5.4-1.4,8.9-1.1
							C510,449.2,509.4,447.3,507.1,444.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M507.3,448.7c-0.3-1.6-1.5-2.5-2.9-2.6c-1.4-0.1-2.9,0.6-4.1,2c-1.2,1.3-1.7,2.9-1.7,4.1c0.1,1.3,0.7,2.2,2.2,2.4
							c3,0.6,4.8,0.4,7.3,1.9C507.4,453.8,507.9,452.1,507.3,448.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M468.5,488.6c-5.3,2.7-9.3,6.4-11.9,10.2c-2.6,3.8-3.8,7.9-2.1,11.1c1.6,3.1,6.1,4.3,11.4,2.9
							c5.3-1.4,11.3-5.5,15-11.7c3.7-6.3,5.4-11.1,7-15.7c1.5-4.7,3-9.2,5-15.7c-3.9,5.5-7.5,8.6-11.2,11.3
							C477.9,483.5,474,485.7,468.5,488.6z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M426.3,566.6c5.8-2.4,11.9-6.2,16.3-10.7c2.3-2.2,4.3-4.6,5.9-7c1.7-2.4,3-4.8,4-7.2c2.1-4.7,2.9-8.9,2.7-12.1
							c-0.1-1.6-0.4-2.9-0.8-3.9c-0.5-1-1.1-1.6-2-1.9c-0.8-0.3-1.8-0.3-2.8,0c-1,0.3-2.1,0.9-3.2,1.7c-2.3,1.6-4.9,4-7.8,6.8
							c-1.4,1.4-3,2.8-4.6,4.4c-1.6,1.5-3.3,3.1-5.1,4.7c-3.5,3.3-7.3,6.4-11.7,10c-4.5,3.7-8.8,7-12.5,10.3
							c-3.8,3.3-7,6.6-9.9,10.2c-1.4,1.8-2.7,3.6-4,5.5c-1.3,1.9-2.5,4-3.7,6.1c-2.3,4.4-4.5,9.3-6.2,15.5c2.7-5.8,5.9-10,9.1-13.4
							c1.6-1.7,3.2-3.2,4.9-4.5c1.6-1.3,3.3-2.5,5-3.6c3.4-2.1,7-3.8,11.2-5.4C415.5,570.6,420.3,569,426.3,566.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M414.5,549.3c4.6-2.9,9.2-5.6,13.6-8.1c4.4-2.5,8.6-4.7,12.2-6.7c3.7-2,6.9-3.9,9.1-5.7c2.3-1.8,3.6-3.6,3.5-5.3
							c-0.2-1.7-1.9-3.3-4.9-4.3c-3-1-7.4-1.4-12.6-0.7c-5.1,0.6-11,2.3-16.8,5.3c-5.7,3-11.4,7.2-15.8,12.4
							c-4.6,5.4-7.8,10.4-10.2,15.1c-2.4,4.7-4.1,9.1-5.4,13.5c-1.4,4.4-2.4,8.9-3.2,13.9c-0.8,5-1.3,10.6-1.3,17.1
							c1-6.5,2.7-11.7,4.7-16.1c2-4.4,4.2-8.1,6.7-11.5c2.5-3.4,5.3-6.5,8.5-9.6C405.9,555.4,409.8,552.3,414.5,549.3z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M372.6,532.7c6.2,0.9,13.4,0.7,19.5-0.9c3.1-0.8,6-1.8,8.7-3c2.7-1.2,5-2.6,7.1-4.1c4.2-3,7-6.2,8.5-9
							c0.7-1.4,1.2-2.7,1.2-3.7c0.1-1.1-0.1-2-0.7-2.7c-0.5-0.7-1.4-1.1-2.4-1.4c-1-0.2-2.3-0.3-3.7-0.2c-2.8,0.2-6.3,0.9-10.2,1.8
							c-1.9,0.4-4,0.9-6.2,1.4c-2.2,0.5-4.4,1-6.8,1.5c-4.7,1-9.5,1.8-15.1,2.6c-5.8,0.9-11.1,1.5-16,2.4c-4.9,0.9-9.4,2.1-13.7,3.7
							c-2.1,0.8-4.2,1.7-6.3,2.7c-2.1,1-4.2,2.1-6.3,3.4c-4.2,2.6-8.7,5.7-13.3,10.1c5.3-3.6,10.2-5.6,14.7-6.9
							c2.3-0.6,4.4-1.1,6.5-1.4c2.1-0.3,4.1-0.5,6.2-0.5c4-0.1,7.9,0.3,12.4,1.1C361.2,530.7,366.2,531.8,372.6,532.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M371.3,511.9c5.5-0.2,10.8-0.1,15.8,0c5,0.1,9.8,0.3,14,0.5c4.2,0.1,7.9,0.1,10.8-0.2c2.9-0.4,4.9-1.2,5.7-2.8
							c0.7-1.6,0.1-3.8-2.1-6.2c-2.1-2.4-5.7-5-10.4-7.1c-4.7-2.1-10.7-3.6-17.1-4c-6.5-0.3-13.4,0.4-20,2.6
							c-6.7,2.2-12,4.9-16.5,7.7c-4.4,2.8-8.1,5.7-11.6,8.8c-3.4,3.1-6.6,6.4-9.9,10.3c-3.2,3.9-6.6,8.4-9.8,14
							c4.2-5,8.3-8.7,12.2-11.5c4-2.8,7.8-4.8,11.6-6.5c3.9-1.6,7.8-2.9,12.2-3.9C360.8,512.7,365.7,512,371.3,511.9z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M514.4,139c10.6-1.1,21.3-3.7,30.7-7.7c9.4-3.9,17.6-9.3,23.7-14.8c6.1-5.5,10.2-11.1,12.5-15.3
							c2.3-4.2,2.9-6.9,2.1-7.4c-1.6-1-7.7,7.5-19.9,16.2c-6.1,4.3-13.6,8.7-22.2,12.2c-8.6,3.5-18.2,6.3-28.2,8.1
							c-10.2,1.9-19.1,2.6-27.2,3.2c-8.1,0.6-15.5,1.1-23,1.9c-7.4,0.8-14.9,1.9-23.1,4.1c-4.1,1.1-8.3,2.5-12.7,4.4
							c-4.4,1.9-8.9,4.3-13.4,7.6c9.3-6,18.4-8.6,26.5-10.2c8.2-1.5,15.6-1.9,22.9-2c7.4-0.1,14.7,0.2,22.9,0.5
							C494.2,139.9,503.4,140.1,514.4,139z"
                      />
                    </g>
                    <g>
                      <path
                        d="M458.8,128.6c2.7-1.5,4.5-4,5.1-6.3c0.6-2.3,0-4.4-1.5-5.3c-1.5-0.9-3.5-0.6-5.3,0.4c-1.8,1-3.5,2.7-4.6,5.2
							c-1.1,2.5-2,4.3-3,6c-1,1.7-2.2,3.4-4.4,5.4c2.8-1.1,4.7-1.7,6.8-2.4C454,130.8,456,130.1,458.8,128.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M475.9,123.1c2.3-1.3,3.6-3.6,3.8-5.6c0.2-2.1-0.6-3.8-2.1-4.7c-1.6-0.8-3.4-0.6-5,0.2c-1.6,0.8-2.9,2.3-3.5,4.4
							c-0.7,2.2-1.1,3.8-1.7,5.4c-0.6,1.6-1.4,3.1-3,5c2.3-1,3.9-1.6,5.6-2.2C471.8,125,473.6,124.4,475.9,123.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M493,119.2c2.2-1.6,3.5-4,3.7-6.1c0.2-2.1-0.5-4-2.1-4.8c-1.5-0.8-3.5-0.5-5.1,0.5c-1.7,1.1-3.1,2.9-3.7,5.3
							c-0.6,2.5-1,4.3-1.5,6.1c-0.5,1.8-1.2,3.6-2.5,5.8c2.2-1.6,3.8-2.5,5.5-3.5C488.9,121.7,490.7,120.8,493,119.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M512.9,117.7c2.4-2.2,3.5-5.3,3.4-7.8c-0.1-2.5-1.3-4.3-3-4.6c-1.7-0.4-3.5,0.6-4.9,2c-1.4,1.4-2.5,3.4-2.9,5.9
							c-0.5,2.6-1,4.5-1.6,6.3c-0.7,1.8-1.6,3.7-3.7,5.8c2.7-1.3,4.5-2.3,6.5-3.3C508.4,121,510.4,119.9,512.9,117.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M529.5,112.5c2.1-2.5,3.1-5.6,2.9-8.1c-0.1-2.5-1.2-4.4-2.9-4.8c-1.7-0.5-3.6,0.5-5,2.2c-1.5,1.7-2.6,4.1-3,7
							c-0.4,3-0.6,5.1-1,7.2c-0.4,2.1-1,4.3-2.4,7.1c2.3-2.2,4-3.7,5.7-5.2C525.6,116.6,527.3,115.1,529.5,112.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M541.5,108.7c2-2,2.9-4.7,2.9-7c-0.1-2.2-1.2-4-2.8-4.5c-1.7-0.5-3.5,0.2-5,1.5c-1.5,1.4-2.6,3.4-2.9,6
							c-0.3,2.6-0.5,4.5-0.8,6.4c-0.3,1.9-0.8,3.8-2,6.3c2.1-1.9,3.6-3.1,5.2-4.3C537.8,112,539.4,110.8,541.5,108.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M558.6,100c2.1-2.3,3.1-5.3,3-7.7c0-2.4-1.1-4.3-2.7-4.9c-1.7-0.5-3.6,0.3-5.1,1.9c-1.5,1.6-2.7,4-3.1,6.8
							c-0.4,2.9-0.6,5-0.9,7.1c-0.4,2.1-0.9,4.2-2.2,7c2.2-2.2,3.8-3.7,5.5-5.1C554.7,103.8,556.5,102.4,558.6,100z"
                      />
                    </g>
                    <g>
                      <path
                        d="M573,93.6c2.2-1.8,3.6-4.1,4.1-6.2c0.5-2.2,0.2-4.2-1.2-5.3c-1.4-1.1-3.5-0.9-5.5,0.3c-2,1.2-3.9,3.5-4.7,6.5
							c-0.8,3-1.2,5.2-1.5,7.3c-0.4,2.1-0.8,4.2-1.6,7.1c1.8-2.4,3.3-3.9,4.9-5.2C569,96.6,570.7,95.4,573,93.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M587.3,86.9c2.3-1.1,3.9-2.9,4.8-4.7c0.8-1.8,0.9-3.8-0.1-5.2c-1-1.4-3.1-1.9-5.2-1.2c-2.2,0.6-4.5,2.4-5.7,5.1
							c-1.3,2.7-1.9,4.7-2.5,6.7c-0.6,2-1.2,3.9-2.3,6.5c1.9-2,3.6-3.2,5.3-4.1C583.1,88.9,584.9,88.1,587.3,86.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M602.4,93.3c2.3,0.6,4.4,0.3,6.1-0.6c1.7-0.9,3-2.3,3.1-4.1c0.1-1.7-1-3.4-3.1-4.3c-2-0.9-4.8-1-7.4,0.3
							c-2.6,1.3-4.2,2.6-5.7,3.7c-1.5,1.2-3.1,2.4-5.3,3.9c2.6-0.5,4.5-0.5,6.3-0.2C598.3,92.3,600,92.7,602.4,93.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M602.3,107.3c2.1,1.2,4.2,1.5,6.1,1.3c1.9-0.3,3.6-1.1,4.4-2.7c0.8-1.6,0.2-3.6-1.6-5.2c-1.8-1.6-4.7-2.6-7.7-2.1
							c-3.1,0.5-5.1,1.4-7,2.2c-2,0.9-3.8,1.7-6.3,2.8c2.8-0.1,4.7,0.4,6.5,1C598.4,105.2,600.1,106.1,602.3,107.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M598.1,120.2c2.4,1.8,5,2.6,7.4,2.6c2.3,0,4.2-0.9,4.9-2.5c0.7-1.6-0.1-3.6-1.8-5.3c-1.7-1.6-4.4-2.9-7.5-3.1
							c-6.3-0.3-8.9,0.5-15.1-0.2C591.6,114.5,593.3,116.5,598.1,120.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M455.6,157.1c1.3,2.2,2.9,3.7,4.7,4.7c1.8,0.9,3.8,1.2,5.3,0.3c1.5-0.9,2.1-3,1.3-5.4c-0.7-2.4-2.8-5-5.7-6.3
							c-3-1.3-5.3-1.7-7.4-2.1c-2.2-0.4-4.3-0.7-7.2-1.1c2.6,1.4,4.1,2.9,5.4,4.4C453.2,153.1,454.3,154.8,455.6,157.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M472.5,157.1c1.1,2.4,2.4,4.3,3.9,5.8c1.5,1.5,3.1,2.7,4.9,2.5c0.9-0.1,1.6-0.6,2.2-1.6c0.6-0.9,0.9-2.2,0.8-3.8
							c-0.1-1.5-0.5-3.3-1.5-4.9c-0.9-1.6-2.3-3.2-4-4.3c-1.8-1.1-3.4-1.8-4.8-2.2c-1.5-0.4-2.8-0.7-4.1-0.8
							c-2.6-0.3-5.1-0.3-8.6,0.2c3.4,0.6,5.5,1.9,7.1,3.3C470,152.9,471.3,154.7,472.5,157.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M487.8,156.6c1.5,2.4,3.4,4.2,5.4,5.3c1.9,1.1,4,1.5,5.5,0.6c1.5-0.9,2-3.1,1.1-5.7c-0.9-2.6-3.1-5.4-6.4-6.9
							c-3.3-1.5-5.7-2.1-8.1-2.5c-2.4-0.5-4.7-0.9-8-1.4c3,1.5,4.7,3.1,6.1,4.7C484.9,152.4,486.2,154.1,487.8,156.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M506.5,159.3c2.5,2.3,5.7,3.4,8.3,3.4c2.6,0,4.6-1.1,5.1-2.7c0.5-1.7-0.5-3.6-2.2-5.1c-1.7-1.6-4.2-2.8-7.2-3.3
							c-3-0.5-5.2-0.9-7.4-1.4c-2.2-0.5-4.3-1.3-7.2-2.9c2.2,2.5,3.6,4.3,5.1,6.1C502.5,155.1,504,157,506.5,159.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M525.3,155.5c3.6,1.7,7.5,2.2,10.5,1.7c3-0.5,5.1-1.9,5.2-3.6c0.2-1.8-1.5-3.5-4.1-4.7c-2.6-1.2-6-2-9.7-2
							c-3.8,0.1-6.5,0.2-9.2,0.1c-2.7-0.1-5.5-0.3-9.4-1.3c3.5,1.9,5.9,3.4,8.3,4.9C519.2,152.2,521.6,153.7,525.3,155.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M549,150.1c6.4,2.5,13.2,0.2,13.4-3.3c0.2-3.5-6-6.6-12.5-5.4c-6.7,1.1-9.4,2.3-16.4,2.3
							C540.3,145.7,542.5,147.5,549,150.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M568.8,144.1c3.4,1.2,7.1,1.2,9.7,0.4c2.7-0.8,4.5-2.3,4.5-4.1c0-1.8-1.7-3.3-4.2-4.3c-2.5-1-5.8-1.4-9.2-0.8
							c-3.5,0.6-6,1.1-8.5,1.5c-2.5,0.4-5.1,0.6-8.8,0.4c3.5,1.2,5.9,2.3,8.2,3.4C562.9,141.7,565.2,142.8,568.8,144.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M580.9,135.9c1.6,1.6,3.5,2.2,5.3,2.2c1.8,0,3.5-0.8,4.4-2.3c0.9-1.5,0.7-3.5-0.5-5.1c-1.2-1.6-3.4-2.9-6.1-3
							c-2.7,0-4.6,0.3-6.4,0.5c-1.8,0.3-3.6,0.5-6,0.6c2.3,0.9,3.7,1.9,5,3C578.1,133,579.3,134.2,580.9,135.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M591.4,129.9c3.6,4.4,9.6,5,11.5,2c1.9-3-1.3-8.1-6.8-9.4c-5.7-1.4-8.2-1.2-13.6-3.3
							C586.8,123.1,587.7,125.4,591.4,129.9z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M191.4,346.1c2,10.5,5.5,20.9,10.2,29.9c4.7,9.1,10.8,16.7,16.8,22.3c6,5.6,11.9,9.2,16.3,11.1
							c4.4,1.9,7.2,2.3,7.5,1.5c0.9-1.7-8.1-7.1-17.8-18.4c-4.8-5.7-9.8-12.8-14-21.1c-4.2-8.3-7.8-17.6-10.5-27.4
							c-2.8-10-4.2-18.8-5.5-26.9c-1.3-8.1-2.4-15.4-3.8-22.7c-1.4-7.3-3.2-14.7-6-22.6c-1.4-4-3.2-8.1-5.4-12.3
							c-2.2-4.2-5.1-8.5-8.7-12.7c6.8,8.8,10.2,17.6,12.4,25.5c2.2,8,3.2,15.3,4,22.6c0.7,7.3,1,14.6,1.5,22.8
							C188.8,326.1,189.3,335.3,191.4,346.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M197,289.9c1.7,2.6,4.4,4.1,6.8,4.5c2.4,0.4,4.4-0.3,5.2-1.9c0.8-1.6,0.3-3.5-0.8-5.3c-1.2-1.7-3-3.3-5.5-4.1
							c-2.6-0.9-4.5-1.6-6.3-2.5c-1.8-0.9-3.6-1.9-5.7-3.9c1.3,2.7,2.1,4.6,3,6.6C194.4,285.3,195.3,287.3,197,289.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M204,306.5c1.5,2.2,3.9,3.3,5.9,3.4c2.1,0.1,3.8-0.9,4.5-2.5c0.7-1.6,0.3-3.5-0.6-4.9c-1-1.5-2.5-2.6-4.7-3.1
							c-2.2-0.5-3.9-0.8-5.6-1.3c-1.6-0.5-3.2-1.2-5.2-2.6c1.2,2.2,1.9,3.8,2.7,5.4C201.7,302.5,202.5,304.3,204,306.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M209.3,323.1c1.8,2.1,4.2,3.1,6.4,3.2c2.1,0.1,3.9-0.8,4.6-2.5c0.7-1.6,0.2-3.5-1-5.1c-1.2-1.6-3.1-2.8-5.6-3.2
							c-2.5-0.4-4.4-0.6-6.2-1c-1.8-0.4-3.6-0.9-6-2c1.7,2,2.8,3.6,3.9,5.2C206.4,319.3,207.5,321,209.3,323.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M212.5,342.8c2.4,2.2,5.6,3,8,2.7c2.5-0.3,4.1-1.7,4.3-3.4c0.2-1.8-0.9-3.4-2.4-4.7c-1.5-1.3-3.6-2.2-6.1-2.4
							c-2.6-0.3-4.5-0.6-6.4-1.1c-1.9-0.5-3.8-1.3-6.1-3.2c1.5,2.5,2.6,4.3,3.8,6.2C208.8,338.7,210.1,340.6,212.5,342.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M219.1,359c2.6,1.9,5.8,2.6,8.3,2.2c2.5-0.3,4.3-1.6,4.6-3.3c0.3-1.7-0.8-3.5-2.7-4.8c-1.8-1.3-4.4-2.3-7.3-2.4
							c-3-0.1-5.1-0.1-7.3-0.4c-2.2-0.2-4.3-0.6-7.3-1.8c2.4,2.1,4,3.6,5.6,5.2C214.7,355.4,216.3,357,219.1,359z"
                      />
                    </g>
                    <g>
                      <path
                        d="M223.9,370.7c2.2,1.9,5,2.5,7.2,2.2c2.2-0.3,3.9-1.5,4.3-3.2c0.4-1.7-0.5-3.5-2-4.8c-1.5-1.4-3.7-2.3-6.2-2.3
							c-2.6-0.1-4.6-0.1-6.5-0.2c-1.9-0.2-3.8-0.5-6.4-1.5c2,1.9,3.4,3.4,4.7,4.9C220.3,367.2,221.6,368.7,223.9,370.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M234,386.9c2.5,1.9,5.5,2.6,7.9,2.4c2.4-0.2,4.2-1.4,4.6-3.1c0.4-1.7-0.6-3.5-2.4-4.9c-1.8-1.4-4.2-2.4-7.1-2.5
							c-3-0.1-5.1-0.2-7.2-0.3c-2.1-0.2-4.2-0.5-7.2-1.6c2.4,2,4,3.5,5.6,5C229.9,383.4,231.4,385,234,386.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M241.6,400.7c2,2.1,4.3,3.2,6.5,3.5c2.2,0.3,4.2-0.2,5.2-1.7c1-1.5,0.6-3.6-0.8-5.5c-1.4-1.9-3.8-3.6-6.8-4.2
							c-3.1-0.6-5.3-0.7-7.4-0.9c-2.1-0.2-4.3-0.4-7.2-1c2.6,1.6,4.2,3,5.6,4.5C238.2,397,239.6,398.6,241.6,400.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M249.5,414.4c1.3,2.2,3.2,3.7,5.1,4.3c1.9,0.7,3.9,0.6,5.2-0.6c1.3-1.2,1.6-3.2,0.7-5.3c-0.8-2.1-2.8-4.2-5.5-5.3
							c-2.8-1.1-4.9-1.5-6.9-1.9c-2-0.4-3.9-0.9-6.6-1.7c2.2,1.8,3.5,3.3,4.6,4.9C247.1,410.4,248.1,412.1,249.5,414.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M244.4,430c-0.4,2.4,0.1,4.4,1.1,6c1,1.6,2.6,2.7,4.3,2.7c1.8,0,3.3-1.3,4-3.4c0.7-2.1,0.6-4.9-0.9-7.3
							c-1.5-2.5-2.9-4-4.2-5.4c-1.3-1.4-2.6-2.9-4.3-4.9c0.7,2.6,0.8,4.4,0.7,6.2C245.1,425.8,244.8,427.6,244.4,430z"
                      />
                    </g>
                    <g>
                      <path
                        d="M230.5,431.1c-1,2.2-1.2,4.3-0.7,6.2c0.4,1.9,1.4,3.5,3.1,4.2c1.6,0.6,3.6-0.1,5-2.1c1.4-1.9,2.2-4.9,1.4-7.9
							c-0.8-3-1.8-4.9-2.8-6.8c-1-1.9-2-3.7-3.3-6.1c0.3,2.8,0,4.7-0.4,6.6C232.2,427,231.5,428.8,230.5,431.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M217.3,428c-1.6,2.5-2.2,5.3-1.9,7.5c0.2,2.3,1.2,4.1,2.9,4.7c1.7,0.5,3.6-0.4,5.1-2.2c1.5-1.9,2.5-4.7,2.4-7.7
							c-0.3-6.3-1.2-8.8-1.1-15C222.4,421,220.5,422.8,217.3,428z"
                      />
                    </g>
                    <g>
                      <path
                        d="M168.4,289.1c-2.1,1.5-3.5,3.2-4.2,5.1c-0.7,1.9-0.9,3.9,0.2,5.3c1,1.4,3.2,1.8,5.5,0.9c2.3-0.9,4.7-3.2,5.8-6.3
							c1.1-3.2,1.3-5.4,1.5-7.6c0.2-2.2,0.3-4.3,0.4-7.3c-1.2,2.7-2.5,4.3-3.9,5.7C172.1,286.4,170.5,287.6,168.4,289.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M169.7,305.9c-2.3,1.3-4,2.7-5.5,4.3c-1.4,1.6-2.5,3.4-2.1,5.1c0.2,0.9,0.8,1.6,1.7,2c1,0.5,2.3,0.7,3.8,0.5
							c1.5-0.2,3.2-0.8,4.8-1.9c1.5-1.1,3-2.6,3.9-4.4c1-1.9,1.5-3.5,1.8-5c0.3-1.5,0.4-2.8,0.5-4.1c0-2.6-0.1-5.2-0.9-8.6
							c-0.3,3.5-1.4,5.6-2.7,7.4C173.7,303.1,172.1,304.6,169.7,305.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M171.5,321.1c-2.3,1.7-3.9,3.7-4.8,5.8c-0.9,2-1.2,4.1-0.1,5.5c1,1.4,3.3,1.7,5.8,0.6c2.5-1.1,5.1-3.6,6.3-6.9
							c1.2-3.4,1.6-5.9,1.8-8.3c0.3-2.4,0.5-4.7,0.7-8.1c-1.3,3.1-2.6,4.9-4.1,6.5C175.6,317.9,173.9,319.3,171.5,321.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M170.5,340.1c-2.1,2.7-2.9,6-2.6,8.5c0.2,2.6,1.4,4.5,3.2,4.8c1.7,0.3,3.5-0.8,4.9-2.6c1.4-1.9,2.4-4.4,2.7-7.4
							c0.3-3.1,0.4-5.3,0.8-7.5c0.4-2.2,0.9-4.4,2.3-7.4c-2.3,2.4-3.9,4-5.6,5.6C174.4,335.7,172.6,337.3,170.5,340.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M175.9,358.4c-1.4,3.7-1.5,7.7-0.8,10.6c0.7,3,2.3,4.9,4,4.9c1.8,0,3.3-1.8,4.3-4.5c1-2.7,1.5-6.2,1.1-9.9
							c-0.4-3.8-0.7-6.5-0.9-9.2c-0.2-2.7-0.2-5.5,0.4-9.4c-1.6,3.7-2.9,6.2-4.2,8.7C178.7,352.1,177.4,354.6,175.9,358.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M183.3,381.6c-1.9,6.6,0.9,13.2,4.4,13.1c3.5-0.1,6-6.5,4.4-12.9c-1.7-6.6-3.1-9.1-3.7-16.1
							C186.9,372.5,185.3,374.9,183.3,381.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M190.9,400.8c-0.9,3.5-0.6,7.1,0.4,9.7c1,2.6,2.7,4.3,4.5,4.1c1.8-0.1,3.2-2,3.9-4.5c0.8-2.6,0.9-5.9,0-9.3
							c-0.9-3.5-1.6-5.9-2.2-8.4c-0.6-2.5-1-5-1.2-8.7c-0.9,3.6-1.8,6-2.6,8.5C192.8,394.7,191.9,397.2,190.9,400.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M200.2,412.2c-1.4,1.7-1.9,3.7-1.8,5.5c0.2,1.8,1.1,3.4,2.7,4.2c1.6,0.8,3.5,0.4,5-1c1.5-1.3,2.6-3.7,2.5-6.3
							c-0.2-2.7-0.6-4.5-1.1-6.3c-0.4-1.8-0.8-3.5-1.1-6c-0.7,2.4-1.6,3.9-2.6,5.3C202.8,409.1,201.7,410.4,200.2,412.2z"
                      />
                    </g>
                    <g>
                      <path d="M207,422.2c-4,4-4.1,10-1,11.6c3.1,1.6,8-2,8.8-7.6c0.9-5.8,0.5-8.2,2.1-13.8C213.4,417,211.1,418.1,207,422.2z" />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M378.5,8.1c9.5-5,18.3-11.4,25.5-18.6c7.3-7.2,12.8-15.2,16.3-22.6c3.6-7.4,5.3-14.1,5.8-18.9c0.5-4.8,0-7.5-0.8-7.6
							c-1.9-0.3-4.3,9.8-12.3,22.5c-4,6.3-9.3,13.2-16,19.6c-6.6,6.5-14.6,12.7-23,18.1c-8.7,5.6-16.7,9.6-24,13.2
							c-7.3,3.6-14,6.8-20.6,10.4c-6.6,3.6-13.1,7.4-19.8,12.5c-3.4,2.5-6.8,5.4-10.1,8.8c-3.4,3.4-6.6,7.4-9.6,12
							c6.4-9.1,13.8-14.9,20.7-19.4c7-4.5,13.7-7.6,20.5-10.5c6.8-2.8,13.7-5.3,21.4-8.2C360.1,16.5,368.7,13.3,378.5,8.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M323.1,19.3c2-2.4,2.7-5.4,2.3-7.8c-0.3-2.4-1.6-4.1-3.3-4.4c-1.7-0.3-3.5,0.7-4.8,2.3c-1.3,1.6-2.2,3.9-2.3,6.5
							c-0.1,2.7-0.2,4.7-0.5,6.7c-0.3,2-0.8,4-2.1,6.6c2.2-2,3.7-3.4,5.4-4.8C319.4,23.2,321.1,21.8,323.1,19.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M336.9,7.8c1.6-2.1,2-4.7,1.5-6.6c-0.5-2-2-3.4-3.7-3.5c-1.8-0.2-3.4,0.7-4.5,2.1c-1.1,1.4-1.8,3.2-1.6,5.4
							c0.2,2.3,0.4,4,0.4,5.7c0,1.7-0.1,3.4-1,5.8c1.7-1.8,3-3,4.4-4.2C333.8,11.1,335.2,9.9,336.9,7.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M351.2-2.2c1.5-2.3,1.7-5,1.1-7c-0.6-2.1-2-3.5-3.7-3.7c-1.8-0.2-3.4,0.8-4.6,2.4c-1.1,1.6-1.8,3.8-1.4,6.3
							c0.4,2.6,0.7,4.4,0.9,6.3c0.2,1.9,0.3,3.7-0.1,6.4c1.4-2.3,2.6-3.8,3.8-5.3C348.4,1.6,349.7,0.1,351.2-2.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M369.1-11.1c1.4-2.9,1.2-6.2,0.2-8.4c-1-2.3-2.9-3.4-4.6-3.1c-1.7,0.3-3,1.8-3.8,3.7c-0.8,1.9-1,4.1-0.5,6.5
							c0.5,2.6,0.8,4.5,0.9,6.5c0,2-0.1,4-1.2,6.8c2-2.2,3.4-3.8,4.8-5.5C366.2-6.4,367.6-8.1,369.1-11.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M382.6-22.2c1-3.1,0.7-6.3-0.3-8.6c-1-2.3-2.8-3.6-4.5-3.4c-1.8,0.2-3.1,1.8-3.8,4c-0.7,2.1-0.9,4.8-0.1,7.6
							c0.8,2.9,1.4,5,1.8,7.1c0.4,2.1,0.7,4.3,0.4,7.5c1.3-2.9,2.3-4.9,3.3-6.9C380.4-16.9,381.5-19,382.6-22.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M392.3-30.2c1.1-2.7,1-5.5,0-7.5c-0.9-2-2.6-3.3-4.3-3.1c-1.8,0.1-3.2,1.5-4,3.3c-0.8,1.8-1.1,4.2-0.4,6.6
							c0.7,2.5,1.3,4.4,1.7,6.3c0.4,1.9,0.7,3.8,0.5,6.6c1.2-2.5,2.2-4.2,3.2-5.9C390-25.7,391.1-27.5,392.3-30.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M404.8-44.7c1.1-3,0.9-6-0.1-8.3c-1-2.2-2.6-3.6-4.4-3.5c-1.8,0.1-3.2,1.6-4,3.7c-0.8,2.1-1,4.7-0.3,7.5
							c0.8,2.9,1.4,4.9,1.8,7c0.4,2.1,0.8,4.2,0.6,7.3c1.2-2.9,2.1-4.8,3.1-6.8C402.6-39.7,403.7-41.7,404.8-44.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M415.7-56.1c1.4-2.5,1.8-5.1,1.4-7.3c-0.3-2.2-1.4-3.9-3.1-4.4c-1.7-0.5-3.6,0.4-5,2.3c-1.4,1.9-2.3,4.7-1.9,7.8
							c0.4,3.1,0.9,5.2,1.3,7.3c0.5,2.1,0.9,4.2,1.2,7.2c0.8-2.9,1.6-4.9,2.6-6.7C413.2-51.8,414.3-53.5,415.7-56.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M426.4-67.6c1.7-1.9,2.5-4.1,2.6-6.1c0.1-2-0.6-3.9-2.1-4.8c-1.5-0.9-3.5-0.6-5.3,0.9c-1.8,1.4-3.2,3.9-3.4,6.8
							c-0.2,3,0,5.1,0.2,7.1c0.2,2,0.3,4,0.3,6.9c1-2.6,2.1-4.3,3.3-5.8C423.3-64.2,424.7-65.6,426.4-67.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M442.9-67.4c2.4-0.3,4.2-1.4,5.4-2.9c1.2-1.4,1.9-3.2,1.3-4.9c-0.5-1.7-2.2-2.8-4.4-2.8c-2.2-0.1-4.8,0.9-6.7,3.1
							c-1.9,2.2-2.9,3.9-3.9,5.6c-1,1.7-2,3.3-3.4,5.6c2.2-1.5,4-2.1,5.7-2.6C438.6-66.8,440.4-67.1,442.9-67.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M448-54.4c2.4,0.3,4.5-0.2,6.2-1.1c1.7-1,3-2.4,3.1-4.2c0.1-1.7-1.2-3.4-3.4-4.2c-2.2-0.8-5.3-0.6-7.9,1
							c-2.7,1.7-4.2,3.2-5.7,4.6c-1.5,1.6-2.9,3-4.8,5c2.6-1.1,4.5-1.4,6.4-1.5C443.6-54.9,445.5-54.7,448-54.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M449-40.9c2.9,0.8,5.7,0.5,7.8-0.4c2.1-0.9,3.6-2.4,3.6-4.2c0-1.8-1.4-3.3-3.6-4.2c-2.2-0.9-5.2-1-8.1,0
							c-6,2.1-8,3.8-14,5.5C440.8-43.7,443.1-42.4,449-40.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M330.8,46.9c2,1.6,4.1,2.4,6.1,2.5c2,0.2,3.9-0.3,5-1.7c1-1.4,0.8-3.5-0.8-5.5c-1.5-1.9-4.4-3.6-7.7-3.6
							c-3.3-0.1-5.5,0.4-7.7,0.8c-2.1,0.5-4.2,1-7.1,1.7c3,0.3,4.9,1.1,6.6,2C327.1,44.2,328.7,45.3,330.8,46.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M346.5,40.6c1.9,1.8,3.8,3.1,5.8,3.9c1.9,0.8,3.9,1.4,5.5,0.5c0.8-0.4,1.3-1.2,1.4-2.3c0.2-1.1,0-2.4-0.7-3.8
							c-0.6-1.4-1.7-2.8-3.2-4c-1.5-1.2-3.3-2.1-5.3-2.4c-2.1-0.4-3.8-0.4-5.3-0.3c-1.5,0.1-2.8,0.4-4.1,0.8
							c-2.5,0.7-4.9,1.6-7.9,3.4c3.4-0.8,5.8-0.3,7.9,0.4C342.6,37.7,344.5,38.8,346.5,40.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M360.5,34.4c2.3,1.7,4.7,2.6,6.9,2.9c2.2,0.3,4.3-0.1,5.3-1.5c1-1.4,0.7-3.6-1.1-5.7c-1.8-2.1-4.9-3.8-8.5-4
							c-3.6-0.2-6.1,0.3-8.5,0.7c-2.4,0.5-4.7,1-7.9,1.7c3.3,0.3,5.5,1.1,7.4,2C356.2,31.5,358.1,32.7,360.5,34.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M378.8,29.8c3.2,1.2,6.5,1,8.9,0c2.4-1,3.8-2.7,3.7-4.4c-0.2-1.8-1.8-3.1-4-3.9c-2.2-0.8-5-1-7.9-0.4
							c-3,0.7-5.2,1.2-7.4,1.5c-2.2,0.3-4.5,0.4-7.8,0c3,1.5,5,2.6,7,3.7C373.5,27.4,375.6,28.6,378.8,29.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M394.8,19.2c4,0.3,7.8-0.8,10.4-2.4c2.6-1.6,4-3.6,3.5-5.3c-0.5-1.7-2.7-2.6-5.6-2.8c-2.9-0.2-6.4,0.4-9.8,1.9
							c-3.5,1.5-6,2.6-8.5,3.6c-2.5,1-5.2,1.8-9.2,2.4c4,0.5,6.7,0.9,9.5,1.4C387.9,18.4,390.7,19,394.8,19.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M414.8,5.3c6.9-0.1,12.4-4.8,11.2-8.1c-1.2-3.3-8-3.8-13.6-0.3c-5.8,3.6-7.8,5.6-14.3,8.3
							C405,4.5,407.7,5.3,414.8,5.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M430.8-7.7c3.7-0.2,7-1.5,9.2-3.3c2.2-1.7,3.3-3.8,2.6-5.5c-0.7-1.6-2.8-2.4-5.5-2.4c-2.7,0-5.9,0.9-8.8,2.7
							c-3,1.9-5.1,3.3-7.3,4.6c-2.2,1.3-4.5,2.5-8,3.7c3.7-0.2,6.3-0.1,8.9,0C424.5-7.7,427.1-7.5,430.8-7.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M439-19.9c2.1,0.9,4.1,0.8,5.8,0c1.6-0.7,3-2.1,3.3-3.8c0.3-1.7-0.7-3.5-2.4-4.5c-1.7-1.1-4.3-1.4-6.7-0.5
							c-2.5,1-4.1,2-5.7,2.9c-1.6,0.9-3.1,1.8-5.4,2.9c2.5,0,4.2,0.4,5.8,0.9C435.2-21.5,436.8-20.8,439-19.9z"
                      />
                    </g>
                    <g>
                      <path d="M446.5-29.3c5,2.7,10.8,1,11.4-2.5c0.6-3.5-4.3-7-9.9-6.2c-5.8,0.9-8,2-13.8,2C439.6-33.9,441.3-32.1,446.5-29.3z" />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M533.7,299.2c0.6-0.6,1.1-1.2,1.6-1.8c0.5-0.7,1-1.3,1.5-2c0.9-1.4,1.8-2.8,2.4-4.3c1.4-3,2.2-6.2,2.6-9.4
						c0.8-6.4-0.3-12.8-2.8-18.6c-1.2-2.9-3-5.7-5-8.1c-2-2.4-4.2-4.5-6.6-6.4c-4.8-3.6-10.2-6.1-15.6-7.6c-2.7-0.7-5.5-1.3-8.2-1.5
						c-2.7-0.3-5.5-0.3-8.1-0.2c-5.4,0.3-10.8,1.5-15.7,3.8c-5,2.3-9.4,5.8-12.6,10c-1.6,2.1-2.9,4.5-3.9,6.9
						c-0.9,2.4-1.5,5.1-1.6,7.7c-0.3,5.1,1,10,3.5,14.1c1.3,2,2.9,3.8,4.7,5.2c1.8,1.4,3.8,2.4,5.8,3.1c4,1.3,8,1.3,11.5,0.3
						c3.5-1,6.4-3.3,8-5.7c1.7-2.4,2.4-4.9,2.6-7c0.2-2.1-0.1-4-0.6-5.3c-0.5-1.4-1.2-2.3-1.7-2.8c-0.6-0.5-1-0.6-1.3-0.5
						c-0.6,0.3-0.4,1.7-0.7,3.8c-0.1,1-0.4,2.2-1.1,3.5c-0.6,1.2-1.5,2.6-2.7,3.6c-0.6,0.5-1.3,0.9-2,1.3c-0.7,0.3-1.6,0.5-2.5,0.6
						c-1.8,0.2-3.8-0.2-5.7-1.2c-0.9-0.5-1.8-1.1-2.5-1.9c-0.7-0.7-1.3-1.6-1.8-2.7c-1-2.1-1.4-4.7-1-7.3c0.2-1.3,0.6-2.5,1.2-3.8
						c0.6-1.2,1.4-2.4,2.4-3.6c1-1.1,2.1-2.2,3.4-3.1c1.3-0.9,2.7-1.7,4.3-2.3c6.2-2.5,14.7-2.8,22.4-0.6c3.8,1.1,7.5,2.8,10.6,5.1
						c1.5,1.2,3,2.5,4.2,3.9c0.6,0.7,1.1,1.5,1.7,2.2l0.7,1.2l0.6,1.3c1.6,3.4,2.4,7.4,2.2,11.1c-0.1,1.9-0.5,3.7-1.2,5.4
						c-0.3,0.9-0.7,1.6-1.2,2.4l-0.7,1.1c-0.3,0.4-0.5,0.7-0.9,1.1c-2.5,3-6.1,5.2-10.1,6.9c-2,0.9-4.1,1.6-6.1,2.2
						c-1.1,0.3-2.1,0.6-3.2,0.9c-1.1,0.3-2.1,0.6-3.1,0.8c-8.4,1.9-16.5,2.6-24.2,2.5c-7.7-0.1-15.1-1.1-22.2-2.7
						c-7.1-1.6-13.8-3.8-20.3-6.6c-13-5.7-25.3-13.9-36.8-25.5c-5.7-5.8-11.3-12.5-16.4-20.3c-5.2-7.7-9.9-16.7-13.9-26.8
						c3.7,10.3,8,19.4,12.8,27.5c4.8,8.1,10,15.2,15.6,21.4c11.1,12.5,23,21.8,36.3,28.8c6.6,3.5,13.5,6.4,20.9,8.7
						c7.4,2.3,15.2,3.9,23.5,4.7c8.4,0.8,17.3,0.7,26.8-0.7c1.2-0.1,2.4-0.4,3.6-0.6c1.2-0.2,2.4-0.5,3.6-0.7c2.5-0.6,5-1.2,7.6-2.1
						c2.6-0.9,5.2-2,7.8-3.5C528.7,303.5,531.3,301.6,533.7,299.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M454.1,257.7c3.8-5.9,5.1-13.2,3.9-20.1c-0.6-3.4-1.9-6.8-3.7-9.7c-1.8-2.9-4.1-5.5-6.7-7.6c-2.6-2.1-5.5-3.7-8.6-4.7
						c-3.1-1-6.3-1.5-9.5-1.3c-3.2,0.2-6.3,0.9-9.1,2.4c-0.7,0.3-1.4,0.8-2,1.2c-0.7,0.5-1.3,0.9-1.9,1.4c-1.2,1-2.2,2.1-3.1,3.3
						c-1.7,2.4-2.8,5-3.3,7.6c-0.5,2.6-0.3,5.3,0.4,7.7c0.8,2.5,2.1,4.4,3.6,5.7c1.5,1.4,3,2.3,4.5,2.9c1.5,0.6,2.9,0.8,4.1,0.8
						c0.6,0,1.2-0.1,1.7-0.2c0.5-0.1,0.9-0.3,1.3-0.4c0.8-0.4,1.3-0.8,1.5-1.2c0.3-0.4,0.4-0.7,0.3-1.1c-0.2-0.7-1-1-2-1.6
						c-1-0.6-2.2-1.3-3.3-2.6c-1.1-1.2-1.9-3.1-1.8-4.5c0.1-0.9,0.2-1.7,0.7-2.6c0.4-0.9,1.1-1.8,1.9-2.6c0.4-0.4,0.9-0.7,1.3-1
						c0.2-0.1,0.5-0.2,0.7-0.4c0.3-0.1,0.5-0.2,0.8-0.3c1.1-0.4,2.4-0.5,3.9-0.5c2.9,0.1,5.9,1.3,8.6,3.4c1.3,1.1,2.5,2.4,3.4,3.8
						c0.9,1.5,1.6,3,2,4.7c0.8,3.4,0.5,7.4-1.1,10.7c-1.6,3.5-4.6,6.3-7.9,8.3c-3.4,1.9-7,3-10.6,3.3c-3.7,0.4-7.3,0.1-10.9-0.6
						c-3.5-0.7-7-1.8-10.4-3.2c-3.4-1.4-6.6-3.1-9.9-5.2c-3.2-2.1-6.3-4.6-9.4-7.5c-3-2.9-6-6.3-8.7-10.3c-2.7-4-5.2-8.6-7.1-14
						c3.2,11,8.3,19.4,13.9,26.1c2.8,3.4,5.7,6.3,8.8,9c3.1,2.6,6.3,4.9,9.7,7c3.4,2.1,7,3.8,11,5.2c3.9,1.4,8.2,2.4,13,2.7
						c4.7,0.3,10-0.4,15.2-2.5c2.6-1,5.2-2.5,7.8-4.4C449.8,263.2,452.2,260.8,454.1,257.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M336.8,288.9c-1.6,6.2-2,12.8-1.5,18.8c0.5,6,2,11.6,3.9,16c1.9,4.5,4.2,7.8,6.2,9.9c2.1,2.1,3.9,2.9,5,2.3
						c1.2-0.6,1.7-2.5,1.6-5.1c0.1-2.7-0.4-6.2-0.8-10.4c-0.4-4.1-0.9-8.8-1.2-13.8c-0.3-5-0.4-10.4-0.3-15.8
						c0.1-5.6,0.8-10.4,1.7-14.7c0.9-4.3,2.2-8.3,3.4-12.5c1.2-4.1,2.4-8.3,3.2-13.2c0.4-2.4,0.6-5,0.6-7.8c0-2.8-0.4-5.7-1.3-8.7
						c0.5,3.1,0.5,6,0.1,8.6c-0.4,2.6-1.1,5-1.9,7.2c-1.6,4.4-3.7,8-5.9,11.6c-2.2,3.6-4.5,7.2-6.8,11.5
						C340.7,277.4,338.5,282.5,336.8,288.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M350.5,287.2c-2,4.9-4.1,10-5.6,15.3c-1.5,5.3-2.2,10.7-1.9,15.5c0.3,4.8,1.5,8.7,3,11.2c1.5,2.5,3.2,3.5,4.5,3.1
						c1.3-0.4,2-2,2.7-4.3c0.7-2.3,1.4-5.3,2.3-8.9c0.9-3.6,2.2-7.7,3.7-12.4c1.4-4.7,3-10.1,4-15.9c0.9-6,1.2-11.1,1.2-15.7
						c0-4.6-0.4-8.7-0.9-12.8c-0.5-4.1-1.2-8.1-2.1-12.5c-1-4.4-2.2-9.3-4.1-14.9c1.1,5.8,1.4,10.8,1.4,15.2c0,4.4-0.4,8.4-0.9,12.2
						c-0.6,3.8-1.4,7.6-2.5,11.6C354.1,277.9,352.6,282.2,350.5,287.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M393.3,330.8c-0.5,0.7-1,1.3-1.5,1.9c-0.5,0.6-1.1,1.1-1.6,1.6c-1.2,0.9-2.4,1.7-3.7,2.3c-1.3,0.6-2.5,0.8-3.7,0.9
						c-1.2,0.1-2.1,0-3.2-0.4c-2.1-0.6-3.9-2.2-4.8-3.6c-0.4-0.7-0.7-1.4-0.8-2.3c-0.1-0.9,0-1.8,0.2-2.8c0.5-1.9,1.6-3.7,2.7-4.9
						c1.2-1.2,2-1.6,2.4-2c0.3-0.1,0.8-0.1,1.2,0c0.4,0.1,0.8,0.3,1.1,0.5c0.7,0.4,1.1,1.1,1.7,1.4c0.3,0.1,0.7,0.1,1.1-0.1
						c0.4-0.3,0.8-0.8,1.1-1.6c0.2-0.8,0.2-2-0.2-3.1c-0.4-1.2-1.2-2.4-2.6-3.5c-1.6-1.1-3.4-1.5-5.4-1.5c-1.9,0-3.8,0.5-5.6,1.3
						c-3.5,1.6-6.7,4.5-9,8.7c-1.1,2.1-1.9,4.6-2.1,7.3c-0.2,2.7,0.2,5.8,1.4,8.5c1.1,2.8,2.9,5.2,5.1,7.2c2.2,2,4.8,3.6,7.7,4.6
						c2.9,1,6.3,1.4,9.3,1.1c3.1-0.3,6.1-1.2,8.7-2.6c2.7-1.4,5-3.2,7.1-5.3c1-1.1,1.9-2.2,2.8-3.4c0.8-1.2,1.5-2.4,2.1-3.7
						c2.5-5.3,3.2-10.4,3.2-14.9c0-4.6-0.8-8.7-1.8-12.3c-1-3.7-2.3-6.9-3.6-10c-1.3-3.1-2.7-6-4.2-8.9c-1.5-2.9-3-5.7-4.8-8.6
						c-1.7-2.9-3.5-5.8-5.6-8.7c-4.1-6-8.9-12.3-15.4-19.3c5.9,7.4,9.9,14.3,13.1,20.6c1.6,3.2,3,6.2,4.2,9.2c1.2,3,2.2,6,3.2,8.9
						c0.9,3,1.7,6,2.4,9c0.7,3.1,1.3,6.2,1.6,9.4c0.3,3.2,0.3,6.5-0.3,9.8C396.4,324.7,395.3,328,393.3,330.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M211.1,46.3c3.4-0.8,6.9-1,10.2-0.6c3.3,0.4,6.4,1.5,8.9,3.1c2.6,1.6,4.7,3.9,6.2,6.4c0.7,1.2,1.3,2.5,1.7,3.8
						c0.4,1.3,0.5,2.6,0.6,3.9c0.1,5.3-3,11-7.3,15.2c-1.1,1.1-2.2,2.1-3.4,2.9c-1.2,0.9-2.4,1.7-3.5,2.4c-2.4,1.4-4.7,2.1-6.6,2.3
						c-1,0.1-1.9,0-2.6-0.2c-0.8-0.2-1.6-0.5-2.3-1c-1.5-1-2.7-2.4-3.5-3.9c-0.4-0.8-0.6-1.5-0.8-2.3c-0.1-0.4-0.1-0.8-0.2-1.1
						c0-0.4,0-0.7,0-1.1c0.1-1.5,0.6-3,1.3-4.2c1.4-2.5,3.4-4.2,4.9-5.2c1.5-1,2.5-1.6,2.5-2.3c0-0.6-1.2-1.4-3.6-1.3
						c-1.2,0-2.7,0.3-4.3,0.9c-1.6,0.7-3.4,1.7-5.1,3.3c-1.7,1.6-3.3,3.8-4.3,6.8c-0.3,0.7-0.4,1.5-0.6,2.3
						c-0.1,0.8-0.2,1.6-0.2,2.5c-0.1,1.7,0.2,3.4,0.6,5.2c0.9,3.5,2.9,6.8,5.9,9.6c1.5,1.4,3.4,2.6,5.5,3.4c2.2,0.9,4.4,1.3,6.6,1.4
						c4.5,0.2,8.9-1,12.7-2.8c1.9-0.9,3.7-1.9,5.5-3.1c1.8-1.2,3.5-2.5,5.1-4c1.6-1.4,3.2-3.1,4.6-4.9c1.4-1.8,2.7-3.7,3.9-5.8
						c1.1-2.1,2.1-4.4,2.8-6.9c0.7-2.5,1.1-5.1,1.1-7.8c0-2.7-0.3-5.5-1.1-8.1c-0.8-2.6-1.9-5.1-3.3-7.4c-2.8-4.6-6.7-8.4-11.4-11.2
						c-4.7-2.8-9.9-4.2-15.1-4.6c-5.2-0.4-10.3,0.3-15.2,1.9c-5,1.6-9.3,4-13,6.7c-3.7,2.7-6.9,5.8-9.6,9
						c-5.3,6.4-9.1,13.2-11.6,19.9c-2.5,6.7-3.9,13.4-4.6,19.9c-0.7,6.5-0.7,12.8-0.1,19.1c0.6,6.3,1.8,12.4,3.6,18.6
						c1.8,6.2,4.1,12.4,6.9,18.7c5.7,12.7,13.8,25.8,24.4,40.6c-10-15.2-17.2-28.8-22-41.6c-2.4-6.4-4.2-12.6-5.4-18.7
						c-1.2-6.1-1.8-12-1.9-18c0-5.9,0.5-11.8,1.7-17.7c1.2-5.8,3-11.7,5.8-17.4c2.8-5.6,6.5-11.1,11.4-16c2.4-2.4,5.2-4.6,8.2-6.5
						C204.3,48.6,207.6,47.1,211.1,46.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M217.7,111.6c1.7-0.5,3.2-0.7,4.8-0.7c1.5,0,2.9,0.2,4,0.6c0.5,0.2,1,0.4,1.3,0.7c0.4,0.2,0.7,0.5,0.9,0.8
						c0.5,0.6,1,1.5,1.3,2.5c0.2,0.5,0.3,1.1,0.3,1.7c0.1,0.6,0.1,1.1,0,1.7c-0.1,1.1-0.4,2.2-0.7,3c-0.2,0.4-0.4,0.8-0.6,1.1
						c-0.2,0.3-0.4,0.6-0.7,0.8c-0.3,0.2-0.4,0.4-0.7,0.6c-0.3,0.2-0.6,0.3-1,0.4c-0.7,0.2-1.5,0.2-2.3,0.1c-0.7-0.1-1.4-0.3-2-0.7
						c-1.2-0.6-2.2-2-2.8-3.3c-0.6-1.3-0.9-2.6-1.1-3.6c-0.2-1-0.5-1.8-1-2.2c-0.5-0.3-1.4-0.2-2.5,0.7c-1,0.9-2.2,2.7-2.6,5.4
						c-0.2,1.3-0.2,2.9,0.1,4.5c0.3,1.7,1,3.5,2.3,5.2c2.5,3.5,7,6.2,12.4,6.3c1.3,0,2.7-0.1,4.2-0.4c1.4-0.3,2.9-0.9,4.2-1.7
						c1.3-0.8,2.5-1.7,3.6-2.8c1.1-1.1,2-2.3,2.8-3.5c1.5-2.5,2.5-5.3,3-8.3c0.2-1.5,0.3-3,0.2-4.5c-0.1-1.5-0.3-3-0.7-4.5
						c-0.8-3-2.3-6.1-4.8-8.7c-2.5-2.6-5.8-4.5-9-5.3c-3.2-0.9-6.4-1-9.4-0.6c-1.5,0.2-2.9,0.5-4.3,0.9c-1.4,0.4-2.7,1-4,1.5
						c-5.3,2.4-9.2,5.9-12.2,9.4c-1.5,1.8-2.8,3.6-3.9,5.4c-1.1,1.9-2,3.7-2.8,5.5c-3,7.5-3.6,14.5-3.3,21.3
						c0.3,6.8,1.7,13.4,4,20.4c2.2,7,5.4,14.5,9.3,23.3c-3.1-9.1-5.5-16.9-6.7-24c-1.3-7.1-1.6-13.5-0.8-19.6
						c0.7-6.1,2.5-12,5.9-17.3c0.8-1.3,1.8-2.6,2.9-3.9c1.1-1.2,2.3-2.4,3.5-3.5C211.2,114.4,214.2,112.5,217.7,111.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M252.1,32.8c3.2,0.6,6.4-0.3,8.5-1.8c2.1-1.5,3-3.4,2.5-5.1c-0.6-1.7-2.4-2.7-4.6-3c-2.2-0.3-4.8,0-7.4,1.2
						c-2.7,1.2-4.6,2.1-6.6,2.8c-2,0.7-4.2,1.2-7.3,1.3c3,1,5.1,1.7,7.3,2.5C246.6,31.4,248.8,32.2,252.1,32.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M267.3,45.6c1.8,2,3.7,3.3,5.6,4.1c1.9,0.8,4,1.1,5.5,0.2c1.5-0.9,1.9-3.2,0.7-5.8c-1.2-2.6-4-5.2-7.6-6.1
						c-3.7-0.9-6.2-0.8-8.7-0.6c-2.4,0.2-4.8,0.6-8,1.4c3.3,0.3,5.4,1.2,7.2,2.3C263.8,42.2,265.4,43.6,267.3,45.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M270,60.5c0.6,2.6,1.5,4.6,2.7,6.3c1.2,1.6,2.7,3,4.5,3.1c0.9,0,1.7-0.4,2.4-1.2c0.3-0.4,0.7-0.9,0.9-1.5
						c0.2-0.6,0.4-1.3,0.5-2c0.4-2.9-0.8-6.8-3.6-9.5c-2.9-2.7-5.2-3.8-7.5-4.7c-2.3-0.9-4.7-1.6-7.9-2.2c3,1.6,4.4,3.3,5.6,5.1
						C268.6,55.8,269.4,57.8,270,60.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M263.1,73.6c-0.7,2.7-0.6,5.1,0.1,7.2c0.7,2,1.9,3.7,3.7,4c1.7,0.3,3.5-0.9,4.7-3.2c1.1-2.3,1.5-5.6,0.3-8.6
						c-1.2-3.2-2.4-5.1-3.5-7.1c-1.2-1.9-2.3-3.8-3.9-6.5c0.6,3.1,0.5,5.2,0.2,7.3C264.3,68.8,263.9,70.8,263.1,73.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M251.6,86.6c-1.8,2-2.8,4.1-3.2,6.2c-0.4,2.1-0.1,4.1,1.2,5.3c1.3,1.2,3.5,1,5.7-0.5c2.1-1.5,4-4.4,4.4-7.7
						c0.4-3.4,0.1-5.8-0.2-8c-0.3-2.3-0.7-4.5-1.2-7.5c-0.5,3.1-1.4,5-2.5,6.8C254.7,82.9,253.5,84.5,251.6,86.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M241.2,20.8c6-2.2,9.5-7.9,7.4-10.8c-2.1-2.9-8.6-1.4-12.5,3.7c-2,2.6-3.3,4.5-4.6,6.4c-0.7,0.9-1.3,1.9-2.1,2.7
						c-0.4,0.4-0.9,0.8-1.5,1.1c-0.6,0.3-1.4,0.4-2.1,0.2c0.7,0.5,1.5,0.7,2.3,0.6c0.8,0,1.5-0.2,2.2-0.4c0.7-0.2,1.3-0.4,1.9-0.6
						c0.6-0.2,1.1-0.4,1.7-0.6C235.8,22.5,238.1,21.9,241.2,20.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M227.4,15.6c2.1-2.5,2.5-5.9,1.9-8.2c-0.7-2.4-2.4-3.8-4.1-3.6c-1.8,0.1-3.2,1.4-4.2,2.9c-1,1.5-1.6,3.3-1.6,5.5
						c0,2.4-0.2,4.2-0.7,5.9c-0.5,1.8-1.3,3.6-3.2,5.6c2.6-1.2,4.4-2.1,6.2-3.2C223.5,19.4,225.3,18.1,227.4,15.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M216.5,14.7c1.1-3.1,0.5-6.4-0.9-8.5c-1.4-2.1-3.4-2.9-5-2.3c-1.7,0.6-2.6,2.3-3.1,4.1c-0.5,1.8-0.5,3.9,0.3,6.1
						c0.8,2.4,1.2,4.2,1.4,6.1c0.2,1.9,0.1,3.9-1,6.6c1.9-2.1,3.3-3.8,4.6-5.5C214.1,19.6,215.4,17.8,216.5,14.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M204,15.5c-0.4-2.8-2.4-5-4.4-5.8c-2.1-0.8-4-0.4-5.1,1c-1.1,1.4-1.2,3.2-0.9,4.8c0.3,1.6,1.1,2.9,2.7,4.1
						c1.7,1.2,2.9,2.3,3.9,3.5c1,1.2,1.9,2.7,2.4,5.1c0.6-2.4,0.9-4.1,1.3-5.9C204.2,20.4,204.4,18.4,204,15.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M189.6,18.1c-1.2-0.9-2.6-1.3-4-1.3c-1.4,0-2.6,0.3-3.6,1c-1,0.6-1.6,1.4-1.9,2.3c-0.3,0.9-0.2,1.7,0.1,2.5
						c0.7,1.6,2.1,2.7,3.1,3.4c1.1,0.7,2,0.9,3.4,0.4c1.5-0.5,2.9-0.5,4.3-0.3c1.4,0.3,2.8,1,4.1,2.7c-0.3-2.2-0.8-3.8-1.5-5.5
						C193,21.7,192,19.9,189.6,18.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M174.9,28.6c-3.1,0-5.9,1.8-7.2,3.8c-1.3,2-1.2,4.2,0,5.4c1.2,1.3,3.1,1.6,4.8,1.4c1.8-0.2,3.4-0.8,5.1-2.3
						c1.7-1.5,3.2-2.6,4.8-3.4c1.6-0.8,3.4-1.4,6.1-1.4c-2.5-1.1-4.3-1.8-6.3-2.4C180.2,29.1,178.1,28.5,174.9,28.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M168.5,41.5c-2.5,0.6-4.3,2.4-4.8,4.3c-0.6,1.9-0.1,3.7,1.3,4.8c1.4,1.1,3.2,1.3,4.7,1c1.5-0.3,2.8-1.1,3.7-2.8
						c1-1.7,1.7-3,2.7-4.3c0.9-1.2,2.1-2.3,4.1-3.3c-2.2,0-3.8-0.1-5.6-0.2C172.8,41,171,40.9,168.5,41.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M158.7,62.1c-2.2,1.8-3.3,4.4-3.4,6.6c-0.1,2.2,0.9,4,2.5,4.7c1.6,0.7,3.5,0.1,5.1-1.1c1.6-1.2,2.8-3.1,3.3-5.6
						c0.5-2.6,0.8-4.4,1.3-6.3c0.5-1.8,1.1-3.6,2.6-6c-2.2,1.6-3.9,2.7-5.6,3.7C162.7,59.2,161,60.2,158.7,62.1z"
                    />
                  </g>
                  <g>
                    <path d="M155.7,90.1c-2.8,3.4-1.4,8,2,9c3.4,1,7.1-2.2,6.5-6.5c-0.6-4.5-1.6-6.4-1.4-10.8C160.4,85.5,158.6,86.6,155.7,90.1z" />
                  </g>
                  <g>
                    <path
                      d="M157,113.8c-1.1,1.8-0.9,3.7-0.1,5.2c0.8,1.4,2.4,2.4,4.1,2.4c1.8,0,3.3-0.9,4.2-2.1c0.9-1.3,1.2-2.9,0.5-4.7
						c-1.5-3.7-2.8-5.3-3.2-9.2C160.8,108.9,159.2,110.1,157,113.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M191.5,102.9c2.1-4.3,3.5-8.8,3.9-12.4c0.5-3.6,0.1-6.5-1.5-7.2c-1.6-0.7-4.1,0.9-6.3,4.1c-2.2,3.2-4.1,8-4.6,13.1
						c-0.5,5.3-0.4,8.9-0.3,12.5c0.1,3.6,0.2,7.2,0.2,12.4c1-5.1,2.2-8.5,3.5-11.8C187.9,110.5,189.4,107.4,191.5,102.9z"
                    />
                  </g>
                  <g>
                    <g>
                      <path
                        d="M6.8,333.4c-3-1.7-5.7-4-7.9-6.6c-2.1-2.6-3.6-5.5-4.4-8.4c-0.8-2.9-0.8-6.1-0.2-8.9c0.3-1.4,0.8-2.7,1.4-3.9
							c0.6-1.2,1.4-2.2,2.3-3.2c3.6-3.9,9.7-6,15.7-6.1c1.5-0.1,3,0,4.5,0.1c1.5,0.1,2.9,0.4,4.2,0.7c2.7,0.6,4.8,1.6,6.3,2.8
							c0.8,0.6,1.4,1.3,1.8,1.9c0.5,0.7,0.8,1.5,1,2.3c0.4,1.7,0.4,3.6-0.2,5.2c-0.2,0.8-0.6,1.6-1,2.2c-0.2,0.4-0.4,0.6-0.7,0.9
							c-0.3,0.3-0.5,0.6-0.8,0.8c-1.1,1-2.5,1.7-3.9,2.2c-2.8,0.9-5.4,0.7-7.1,0.5c-1.8-0.3-3-0.5-3.4,0c-0.4,0.5-0.1,1.8,1.7,3.4
							c0.9,0.8,2.1,1.6,3.8,2.3c1.6,0.6,3.7,1.1,6,1.1c2.3,0,5.1-0.5,7.7-2c0.7-0.3,1.3-0.8,2-1.3c0.6-0.5,1.3-1,1.9-1.6
							c1.2-1.2,2.2-2.6,3.1-4.2c1.7-3.1,2.6-6.9,2.2-11c-0.2-2-0.7-4.2-1.7-6.3c-1-2.1-2.3-4-3.9-5.6c-3.2-3.2-7.2-5.3-11.2-6.6
							c-2-0.7-4.1-1.2-6.1-1.5c-2.1-0.4-4.2-0.5-6.4-0.6c-2.2-0.1-4.4,0.1-6.7,0.4c-2.3,0.3-4.6,0.9-6.8,1.6
							c-2.3,0.8-4.6,1.8-6.8,3.1c-2.2,1.4-4.3,3-6.1,4.9c-1.8,2-3.5,4.2-4.7,6.7c-1.2,2.4-2.1,5-2.7,7.6c-1.1,5.3-0.8,10.7,0.7,15.9
							c1.5,5.2,4.4,9.9,7.9,13.7c3.5,3.8,7.8,6.8,12.3,9c4.7,2.2,9.5,3.4,14.1,4c4.6,0.6,9,0.5,13.1,0c8.3-1,15.6-3.4,22.1-6.6
							c6.4-3.2,12-7.1,17-11.4c4.9-4.3,9.2-8.9,13.1-13.9c3.8-5,7.2-10.3,10.1-16c2.9-5.7,5.5-11.8,7.7-18.4
							c4.5-13.2,7.6-28.3,9.9-46.4c-3.1,18-7.1,32.8-12.3,45.4c-2.6,6.3-5.6,12.1-8.8,17.3c-3.3,5.3-6.9,10-10.9,14.4
							c-4,4.3-8.4,8.3-13.3,11.8c-4.9,3.5-10.2,6.5-16.1,8.7c-5.9,2.2-12.4,3.7-19.2,3.9c-3.4,0.1-6.9-0.1-10.4-0.9
							C13.3,336.4,9.9,335.2,6.8,333.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M46.6,281.2c-1.6-0.8-2.8-1.7-4-2.7c-1.1-1-2-2.1-2.5-3.1c-0.3-0.5-0.4-1-0.5-1.4c-0.1-0.4-0.1-0.8-0.1-1.2
							c0-0.8,0.3-1.7,0.8-2.7c0.3-0.5,0.6-1,0.9-1.5c0.4-0.5,0.7-0.9,1.1-1.2c0.8-0.8,1.8-1.4,2.6-1.7c0.4-0.2,0.8-0.3,1.2-0.4
							c0.4-0.1,0.7-0.1,1-0.1c0.3,0,0.6,0,0.9,0.1c0.3,0.1,0.6,0.2,1,0.4c0.7,0.3,1.3,0.9,1.7,1.5c0.5,0.6,0.8,1.2,1,1.8
							c0.4,1.3,0.2,2.9-0.2,4.3c-0.5,1.4-1.1,2.5-1.7,3.4c-0.5,0.9-0.9,1.6-0.7,2.3c0.2,0.6,0.9,1.1,2.3,1.2c1.4,0,3.5-0.5,5.6-2.2
							c1.1-0.8,2.1-1.9,3-3.4c0.9-1.4,1.6-3.3,1.9-5.4c0.6-4.3-0.9-9.3-4.7-13c-1-0.9-2.1-1.8-3.3-2.5c-1.3-0.7-2.7-1.3-4.2-1.6
							c-1.5-0.3-3-0.5-4.5-0.5c-1.5,0.1-3,0.3-4.4,0.7c-2.9,0.8-5.5,2.2-7.8,4c-1.2,0.9-2.3,2-3.2,3.2c-1,1.2-1.8,2.4-2.6,3.8
							c-1.5,2.7-2.5,6-2.5,9.6c0,3.6,1.2,7.2,2.9,10c1.7,2.9,4,5.1,6.4,6.8c1.2,0.9,2.5,1.6,3.8,2.3c1.3,0.7,2.7,1.2,4,1.6
							c5.5,1.8,10.7,2,15.4,1.5c2.3-0.3,4.5-0.7,6.6-1.3c2.1-0.6,4-1.3,5.8-2.2c7.3-3.4,12.6-8.1,17-13.2c4.4-5.2,7.9-10.9,11-17.6
							c3.2-6.7,5.9-14.3,9.1-23.4c-4,8.8-7.5,16.1-11.4,22.1c-3.9,6.1-8.1,10.9-12.8,14.9c-4.7,3.9-10,7-16.1,8.6
							c-1.5,0.4-3.1,0.7-4.7,0.9c-1.6,0.2-3.3,0.2-5,0.1C53.2,283.5,49.7,282.8,46.6,281.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M-32.4,315.2c-2-2.6-4.9-4.2-7.4-4.5c-2.5-0.4-4.6,0.4-5.3,2c-0.7,1.6-0.1,3.6,1.3,5.3c1.4,1.7,3.5,3.3,6.2,4.2
							c2.8,0.9,4.8,1.6,6.8,2.5c2,0.9,3.9,1.9,6.2,4c-1.5-2.8-2.6-4.7-3.6-6.8C-29.3,320-30.4,317.9-32.4,315.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M-34.7,295.5c0-2.7-0.4-4.9-1.3-6.9c-0.9-1.9-2.1-3.5-3.9-3.8c-1.7-0.3-3.6,1.1-4.5,3.8c-0.9,2.7-0.6,6.6,1.4,9.7
							c2.1,3.2,4,4.8,5.9,6.3c1.9,1.5,3.9,2.8,6.8,4.4c-2.2-2.4-3.1-4.5-3.7-6.6C-34.5,300.4-34.8,298.3-34.7,295.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M-26.6,282.8c1.3-2.3,2-4.4,2.3-6.5c0.2-2,0.1-4.1-1.2-5.3c-0.6-0.6-1.5-0.9-2.5-0.8c-0.5,0.1-1.1,0.2-1.6,0.5
							c-0.5,0.3-1.2,0.6-1.7,1.1c-2.3,1.9-4.1,5.5-3.9,9.3c0.3,3.9,1.2,6.3,2.3,8.6c1.1,2.2,2.3,4.4,4.3,7c-1.1-3.2-1-5.5-0.6-7.6
							C-28.7,287.1-27.9,285.2-26.6,282.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M-12.6,277.9c2.3-1.5,3.9-3.4,4.9-5.3c0.9-1.9,1.1-4,0.1-5.4c-1-1.4-3.2-1.8-5.6-0.9c-2.4,0.9-4.9,3.1-6.2,6.1
							c-1.3,3.1-1.8,5.4-2.3,7.6c-0.4,2.2-0.9,4.4-1.5,7.4c1.7-2.6,3.2-4.2,4.8-5.5C-16.7,280.6-15,279.4-12.6,277.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M4.7,276.2c2.7-0.3,4.9-1.1,6.6-2.4c1.7-1.3,2.9-2.9,2.7-4.7c-0.2-1.7-1.9-3.2-4.4-3.5c-2.5-0.4-5.9,0.4-8.5,2.6
							c-2.6,2.3-4,4.2-5.4,6c-1.3,1.9-2.6,3.7-4.3,6.3c2.5-1.9,4.5-2.7,6.5-3.3C-0.1,276.8,1.9,276.5,4.7,276.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M-32.6,331.5c-5.9-2.5-12.3-0.7-12.8,2.8c-0.4,3.5,5.4,6.9,11.7,5.9c3.2-0.5,5.5-1.1,7.8-1.5c1.2-0.2,2.2-0.5,3.4-0.5
							c0.6,0,1.2,0,1.8,0.2c0.6,0.2,1.3,0.6,1.7,1.3c-0.2-0.8-0.7-1.5-1.2-2c-0.6-0.5-1.2-0.9-1.8-1.2c-0.6-0.3-1.2-0.6-1.8-0.8
							c-0.6-0.2-1.1-0.5-1.6-0.7C-27.5,333.9-29.6,332.8-32.6,331.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M-26.1,344.6c-3.2,0.4-5.9,2.5-7,4.8c-1.2,2.2-0.8,4.4,0.5,5.5c1.4,1.1,3.3,1.1,5.1,0.8c1.8-0.4,3.4-1.3,4.9-3
							c1.6-1.7,3-2.9,4.6-3.9c1.6-1,3.4-1.7,6.2-1.9c-2.7-0.9-4.6-1.4-6.7-1.9C-20.6,344.6-22.8,344.2-26.1,344.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M-18.7,352.8c-2.9,1.5-4.7,4.4-5.1,6.8c-0.4,2.5,0.5,4.5,2.1,5.1c1.7,0.7,3.5,0.1,5.1-0.9c1.6-1,3-2.5,4-4.7
							c1-2.2,2-3.9,3.1-5.4c1.2-1.5,2.6-2.9,5.2-4.2c-2.9,0.2-5,0.5-7.1,0.9C-13.6,350.8-15.8,351.3-18.7,352.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M-9,360.7c-1.6,2.3-1.7,5.3-0.7,7.3c0.9,2,2.7,3,4.4,2.7c1.7-0.3,3.1-1.5,4-2.9c0.9-1.4,1.2-2.9,0.8-4.8
							c-0.4-2-0.6-3.6-0.5-5.2c0.1-1.6,0.4-3.3,1.7-5.4c-2,1.3-3.5,2.4-5,3.5C-5.8,357-7.4,358.3-9,360.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M3.3,368.7c0.3,1.5,1,2.7,2,3.7c1,0.9,2.1,1.5,3.2,1.7c1.1,0.2,2.1,0,2.9-0.4c0.8-0.4,1.3-1.1,1.6-1.9
							c0.6-1.7,0.4-3.4,0.1-4.6c-0.3-1.3-0.8-2.1-2.2-2.7c-1.4-0.7-2.5-1.6-3.3-2.7c-0.8-1.2-1.4-2.6-1.1-4.8
							c-1.3,1.8-2,3.4-2.6,5.1C3.3,363.7,2.7,365.7,3.3,368.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M21.2,371c2.3,2.1,5.5,2.7,7.9,2.1c2.4-0.6,3.8-2.2,3.7-3.9c0-1.8-1.2-3.3-2.6-4.4c-1.4-1.1-3.1-1.7-5.3-1.8
							c-2.3-0.1-4.1-0.3-5.8-0.8c-1.7-0.5-3.4-1.3-5.4-3.2c1.1,2.5,1.9,4.3,2.9,6.1C17.7,367,18.8,368.9,21.2,371z"
                      />
                    </g>
                    <g>
                      <path
                        d="M34.6,366c2.2,1.3,4.7,1.2,6.4,0.2c1.7-1,2.6-2.7,2.3-4.4c-0.3-1.7-1.4-3.1-2.7-4c-1.3-0.8-2.8-1.1-4.7-0.5
							c-1.9,0.6-3.3,1-4.8,1.3c-1.5,0.2-3.1,0.3-5.2-0.4c1.6,1.5,2.7,2.7,3.9,3.9C31.1,363.4,32.4,364.7,34.6,366z"
                      />
                    </g>
                    <g>
                      <path
                        d="M55.9,357.6c2.9,0.2,5.5-1,7-2.5c1.6-1.6,2.1-3.5,1.4-5.1c-0.7-1.6-2.5-2.5-4.5-2.7c-2-0.2-4.2,0.4-6.2,1.9
							c-2.1,1.5-3.6,2.7-5.2,3.7c-1.6,1-3.3,1.9-6,2.6c2.7,0.3,4.7,0.7,6.6,1.1C51,357,52.9,357.4,55.9,357.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M77.2,339.2c4.4-0.5,6.5-4.9,4.7-7.9c-1.8-3-6.6-3.2-9.2,0.4c-2.6,3.7-3.2,5.7-6.3,8.9
							C70.6,339.3,72.7,339.8,77.2,339.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M92.4,321c2-0.6,3.2-2.1,3.6-3.7c0.4-1.6-0.1-3.4-1.4-4.6c-1.3-1.2-3-1.6-4.5-1.3c-1.5,0.3-2.9,1.3-3.6,3.1
							c-1.4,3.7-1.5,5.7-3.9,8.9C86.3,321.9,88.3,322.2,92.4,321z"
                      />
                    </g>
                    <g>
                      <path
                        d="M59.8,305.3c-4.5,1.7-8.5,4.1-11.4,6.4c-2.8,2.3-4.5,4.7-3.8,6.3c0.7,1.6,3.6,2.1,7.4,1.3c3.8-0.8,8.5-3,12.3-6.5
							c3.9-3.5,6.3-6.2,8.8-8.9c2.4-2.7,4.8-5.4,8.3-9.3c-4.2,3.1-7.4,4.7-10.6,6.2C67.6,302.3,64.4,303.6,59.8,305.3z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M363.6,398.2c3.2,1.3,6.2,3.2,8.6,5.5c2.4,2.3,4.3,5,5.4,7.7c1.1,2.8,1.6,5.9,1.3,8.8c-0.1,1.4-0.4,2.8-0.9,4.1
							c-0.4,1.3-1.1,2.4-1.8,3.5c-3,4.3-8.8,7.2-14.8,8.1c-1.5,0.3-3,0.4-4.4,0.4c-1.5,0.1-2.9,0-4.3-0.1c-2.7-0.3-5-1-6.7-2
							c-0.8-0.5-1.5-1.1-2-1.7c-0.5-0.6-1-1.4-1.3-2.2c-0.6-1.7-0.8-3.6-0.5-5.2c0.1-0.8,0.4-1.6,0.7-2.3c0.1-0.4,0.4-0.7,0.5-1
							c0.2-0.3,0.4-0.6,0.7-0.9c1-1.2,2.2-2.1,3.6-2.7c2.6-1.2,5.2-1.4,7-1.4c1.8,0,3,0.1,3.4-0.4c0.4-0.5-0.2-1.8-2.1-3.2
							c-1-0.7-2.3-1.3-4-1.8c-1.7-0.4-3.8-0.6-6.1-0.3c-2.3,0.3-4.9,1.2-7.4,3c-0.6,0.4-1.2,1-1.8,1.5c-0.6,0.6-1.2,1.2-1.6,1.9
							c-1,1.3-1.9,2.9-2.5,4.5c-1.3,3.3-1.6,7.2-0.8,11.2c0.4,2,1.2,4.1,2.4,6c1.3,2,2.8,3.6,4.6,5c3.6,2.8,7.8,4.3,11.9,5.1
							c2.1,0.4,4.2,0.6,6.3,0.7c2.1,0.1,4.3,0,6.4-0.3c2.2-0.2,4.4-0.7,6.6-1.3c2.2-0.6,4.4-1.4,6.6-2.5c2.2-1.1,4.3-2.4,6.3-4
							c2-1.6,3.8-3.5,5.5-5.7c1.6-2.2,2.9-4.6,3.8-7.2c0.9-2.6,1.5-5.2,1.7-7.9c0.4-5.4-0.6-10.7-2.7-15.7c-2.2-5-5.6-9.2-9.6-12.6
							c-4-3.3-8.6-5.8-13.4-7.3c-5-1.6-9.9-2.2-14.5-2.1c-4.6,0-9,0.7-13,1.7c-8.1,2.1-15,5.4-21,9.4c-6,4-11,8.6-15.3,13.5
							c-4.3,4.9-8,10-11.1,15.5c-3.1,5.4-5.8,11.2-8,17.2c-2.2,6.1-3.9,12.4-5.3,19.3c-2.7,13.7-3.8,29-3.8,47.2
							c0.7-18.2,2.8-33.4,6.3-46.6c1.8-6.6,3.9-12.7,6.5-18.3c2.6-5.7,5.5-10.9,8.9-15.7c3.4-4.8,7.3-9.3,11.6-13.4
							c4.4-4.1,9.3-7.7,14.8-10.7c5.5-3,11.8-5.2,18.5-6.4c3.4-0.5,6.9-0.8,10.4-0.5C356.7,396.1,360.3,396.8,363.6,398.2z"
                      />
                    </g>
                    <g>
                      <path
                        d="M331,455.1c1.7,0.6,3,1.3,4.3,2.2c1.2,0.9,2.2,1.9,2.9,2.8c0.3,0.5,0.5,0.9,0.7,1.3c0.2,0.4,0.2,0.8,0.3,1.2
							c0.1,0.8,0,1.8-0.4,2.8c-0.2,0.5-0.4,1.1-0.7,1.6c-0.3,0.5-0.6,0.9-1,1.4c-0.7,0.9-1.6,1.6-2.4,2.1c-0.4,0.2-0.8,0.4-1.1,0.6
							c-0.4,0.1-0.7,0.2-1,0.3c-0.3,0-0.6,0.1-0.9,0c-0.3,0-0.7-0.1-1-0.2c-0.7-0.3-1.4-0.7-1.9-1.2c-0.5-0.5-1-1.1-1.2-1.7
							c-0.6-1.2-0.6-2.9-0.3-4.3c0.3-1.4,0.8-2.6,1.2-3.6c0.4-1,0.7-1.7,0.4-2.4c-0.2-0.6-1-1-2.5-0.9c-1.4,0.2-3.4,0.9-5.3,2.9
							c-0.9,1-1.9,2.2-2.6,3.7c-0.7,1.6-1.2,3.4-1.2,5.6c0,4.3,2.1,9.1,6.4,12.3c1.1,0.8,2.3,1.5,3.6,2.1c1.3,0.5,2.9,0.9,4.4,1.1
							c1.5,0.1,3,0.1,4.5-0.1c1.5-0.3,2.9-0.7,4.3-1.2c2.7-1.2,5.2-2.9,7.2-5c1-1.1,2-2.3,2.8-3.6c0.8-1.3,1.5-2.6,2-4.1
							c1.1-2.9,1.7-6.3,1.2-9.9c-0.5-3.6-2.1-7-4.2-9.5c-2.1-2.6-4.6-4.5-7.3-5.9c-1.3-0.7-2.7-1.3-4.1-1.8
							c-1.4-0.5-2.8-0.8-4.1-1.1c-5.7-1.1-10.9-0.6-15.4,0.5c-2.3,0.6-4.4,1.3-6.3,2.2c-2,0.9-3.8,1.8-5.5,2.9
							c-6.8,4.3-11.4,9.7-15.1,15.3c-3.7,5.7-6.4,11.9-8.7,18.9c-2.3,7-4,15-6,24.4c2.8-9.2,5.4-16.9,8.5-23.4
							c3.1-6.5,6.6-11.9,10.7-16.4c4.1-4.5,9-8.3,14.8-10.6c1.5-0.6,3-1.1,4.6-1.5c1.6-0.4,3.2-0.6,4.9-0.8
							C324,453.6,327.6,453.9,331,455.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M404.9,411.1c2.3,2.3,5.4,3.5,7.9,3.5c2.5,0,4.5-1,5-2.7c0.5-1.7-0.4-3.6-2-5.1c-1.6-1.6-3.9-2.8-6.7-3.3
							c-2.9-0.6-5-1-7-1.6c-2.1-0.6-4.1-1.4-6.7-3.2c1.9,2.5,3.2,4.4,4.4,6.3C401.2,406.8,402.5,408.7,404.9,411.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M409.7,430.4c0.3,2.7,1.1,4.8,2.2,6.6c1.1,1.8,2.6,3.2,4.3,3.3c1.7,0.1,3.4-1.5,4-4.4c0.6-2.8-0.2-6.6-2.6-9.4
							c-2.5-2.9-4.6-4.3-6.7-5.5c-2.1-1.2-4.2-2.3-7.3-3.5c2.5,2.1,3.7,4.1,4.5,6.1C408.9,425.6,409.4,427.6,409.7,430.4z"
                      />
                    </g>
                    <g>
                      <path
                        d="M403.3,444.1c-1,2.4-1.5,4.6-1.4,6.7c0,2,0.4,4,1.8,5.1c0.7,0.5,1.6,0.7,2.6,0.4c0.5-0.1,1.1-0.3,1.6-0.7
							c0.5-0.3,1.1-0.8,1.6-1.3c2-2.1,3.4-6,2.6-9.8c-0.8-3.8-2.1-6.1-3.4-8.2c-1.4-2.1-2.8-4-5.2-6.4c1.5,3,1.7,5.3,1.5,7.4
							C404.8,439.5,404.3,441.5,403.3,444.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M390,450.7c-2.1,1.7-3.5,3.8-4.1,5.9c-0.6,2-0.6,4.1,0.6,5.4c1.2,1.3,3.4,1.3,5.6,0.1c2.2-1.2,4.4-3.7,5.3-6.8
							c0.9-3.3,1.1-5.6,1.3-7.8c0.2-2.3,0.3-4.5,0.6-7.6c-1.3,2.8-2.6,4.5-4.1,6.1C393.8,447.5,392.2,448.9,390,450.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M373.1,454.6c-2.6,0.6-4.7,1.8-6.2,3.2c-1.5,1.5-2.5,3.3-2.1,5c0.4,1.7,2.3,2.9,4.9,2.9c2.6,0,5.8-1.2,8.1-3.7
							c2.3-2.6,3.4-4.6,4.5-6.7c1.1-2,2.1-4,3.4-6.8c-2.2,2.2-4.1,3.3-6,4.1C377.8,453.4,375.8,453.9,373.1,454.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M402.9,395c6.1,1.8,12.3-0.9,12.3-4.5c0-3.5-6.2-6.1-12.3-4.3c-3.2,0.9-5.3,1.8-7.5,2.5c-1.1,0.4-2.2,0.8-3.3,0.9
							c-0.6,0.1-1.2,0.1-1.8,0c-0.6-0.1-1.3-0.5-1.8-1.1c0.3,0.8,0.9,1.4,1.5,1.9c0.6,0.4,1.3,0.7,2,1c0.6,0.2,1.3,0.4,1.9,0.6
							c0.6,0.2,1.1,0.3,1.7,0.5C397.6,393.2,399.8,394.1,402.9,395z"
                      />
                    </g>
                    <g>
                      <path
                        d="M394.7,382.8c3.1-0.8,5.5-3.3,6.3-5.6c0.9-2.4,0.2-4.4-1.3-5.3c-1.5-0.9-3.4-0.7-5.1-0.1c-1.7,0.6-3.2,1.7-4.5,3.6
							c-1.4,1.9-2.6,3.3-4,4.4c-1.4,1.1-3.1,2.2-5.9,2.7c2.8,0.5,4.8,0.8,6.9,1C389.3,383.5,391.5,383.6,394.7,382.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M386.4,375.6c2.7-1.9,4.1-4.9,4.2-7.4c0.1-2.5-1.1-4.4-2.8-4.8c-1.7-0.4-3.5,0.4-5,1.5c-1.5,1.2-2.6,2.9-3.3,5.2
							c-0.7,2.4-1.5,4.1-2.4,5.8c-1,1.6-2.2,3.2-4.6,4.8c2.8-0.6,4.8-1.2,6.9-1.8C381.5,378.3,383.7,377.5,386.4,375.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M375.7,369.1c1.3-2.5,1-5.5-0.2-7.3c-1.2-1.9-3.1-2.6-4.7-2.1c-1.7,0.5-2.9,1.9-3.6,3.3c-0.7,1.5-0.8,3-0.2,4.9
							c0.6,1.9,1,3.5,1.2,5.1c0.1,1.6,0,3.3-1,5.5c1.8-1.6,3.2-2.8,4.5-4.1C373.1,373.1,374.4,371.7,375.7,369.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M362.5,362.8c-0.5-1.4-1.4-2.6-2.5-3.4c-1.1-0.8-2.3-1.2-3.4-1.3c-1.1-0.1-2.1,0.2-2.8,0.8c-0.7,0.5-1.2,1.3-1.4,2.1
							c-0.4,1.7,0.1,3.4,0.5,4.6c0.5,1.2,1.1,1.9,2.5,2.4c1.5,0.5,2.7,1.2,3.6,2.3c0.9,1,1.7,2.4,1.8,4.6c1-2,1.6-3.6,2-5.4
							C363.2,367.7,363.5,365.7,362.5,362.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M344.5,362.7c-2.6-1.8-5.9-2-8.1-1.1c-2.3,0.9-3.4,2.7-3.2,4.4c0.3,1.8,1.6,3.1,3.1,4c1.5,0.9,3.3,1.3,5.5,1.1
							c2.3-0.2,4.1-0.2,5.8,0c1.8,0.3,3.6,0.8,5.8,2.5c-1.4-2.3-2.5-4-3.7-5.7C348.5,366.3,347.1,364.6,344.5,362.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M331.8,369.5c-2.3-1-4.8-0.5-6.4,0.6c-1.6,1.2-2.2,3-1.8,4.7c0.5,1.7,1.8,2.9,3.2,3.6c1.4,0.6,3,0.7,4.7-0.1
							c1.8-0.8,3.2-1.5,4.6-1.9c1.5-0.4,3-0.7,5.2-0.3c-1.8-1.3-3.1-2.3-4.4-3.4C335.6,371.6,334.2,370.5,331.8,369.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M311.8,380.6c-2.9,0.2-5.3,1.7-6.6,3.4c-1.4,1.8-1.7,3.8-0.7,5.3c0.9,1.5,2.8,2.1,4.8,2c2-0.1,4.1-0.9,5.9-2.7
							c1.9-1.8,3.2-3.1,4.7-4.3c1.5-1.2,3-2.3,5.6-3.4c-2.8,0-4.7-0.1-6.7-0.3C316.8,380.6,314.7,380.4,311.8,380.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M293.1,401.5c-4.3,1.1-5.8,5.7-3.6,8.4c2.2,2.8,7,2.4,9-1.5c2.1-4,2.4-6.1,5.1-9.6
							C299.6,400.6,297.5,400.4,293.1,401.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M280.3,421.6c-1.9,0.9-2.9,2.5-3.1,4.2c-0.2,1.7,0.5,3.3,2,4.4c1.4,1,3.2,1.2,4.7,0.7c1.5-0.5,2.7-1.7,3.1-3.6
							c0.9-3.9,0.8-5.9,2.7-9.4C286.3,419.9,284.3,419.9,280.3,421.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M314.7,432.9c4.2-2.3,7.9-5.1,10.4-7.8c2.5-2.7,3.8-5.2,2.9-6.7c-0.9-1.5-3.8-1.6-7.5-0.3c-3.7,1.3-8,4.1-11.4,8
							c-3.5,4-5.5,7-7.5,10c-2,3-4,6-7,10.3c3.8-3.6,6.7-5.7,9.7-7.5C307.3,436.9,310.4,435.2,314.7,432.9z"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M344.5,109.3c1.3-4.4,1.9-9.1,1.4-13.7c-0.4-4.6-1.8-9.3-4.3-13.4c-0.3-0.5-0.6-1-1-1.5c-0.4-0.5-0.7-1-1.1-1.5
						c-0.8-0.9-1.5-1.8-2.4-2.7c-1.7-1.7-3.6-3.1-5.6-4.3c-4-2.4-8.3-3.8-12.6-4.4c-4.3-0.6-8.7-0.6-12.9,0.4
						c-4.2,0.9-8.3,2.7-11.6,5.5c-3.3,2.7-5.9,6.2-7.2,10.2c-0.7,1.9-1.1,4.1-1.1,6.1c0,2,0.2,4,0.8,5.9c1.1,3.7,3.3,7,6.3,9.2
						c3,2.2,6.5,3.1,9.6,3c3.1-0.1,6.1-1.1,8.3-2.8c1.1-0.9,2-1.9,2.7-2.9c0.7-1.1,1.2-2.2,1.4-3.2c0.6-2.1,0.5-4.1,0.2-5.7
						c-0.3-1.6-1-2.9-1.6-3.8c-1.4-1.8-2.6-2.1-2.9-1.8c-0.3,0.3,0.2,1.3,0.6,2.9c0.2,0.8,0.3,1.8,0.2,3c-0.1,1.1-0.4,2.4-1,3.7
						c-0.3,0.6-0.7,1.2-1.2,1.7c-0.5,0.5-1.1,0.9-1.7,1.3c-1.3,0.7-3,1.1-4.8,0.9c-1.8-0.2-3.7-0.9-5-2.1c-1.4-1.3-2.5-3.2-2.9-5.3
						c-0.2-1.1-0.3-2.2-0.2-3.4c0.1-1.1,0.4-2.2,0.8-3.3c0.9-2.2,2.6-4.3,4.7-6c2.1-1.6,4.8-2.7,7.7-3.3c2.9-0.6,6.2-0.6,9.4-0.1
						c6.4,1,12.6,4.2,15.9,9.6c3.5,5.4,4.4,12.8,2.7,19.6c-0.4,1.7-1,3.4-1.8,5c-0.7,1.6-1.6,3.1-2.6,4.6c-2,2.9-4.2,5.4-6.7,7.6
						c-5,4.4-10.6,7.5-16.3,9.7c-5.7,2.3-11.4,3.6-17.1,4.6c-5.7,1-11.4,1.5-17.1,1.9c-5.7,0.4-11.5,0.5-17.5,0.9
						c-6,0.4-12.3,1.1-18.6,3c-3.2,0.9-6.4,2.1-9.5,3.8c-3.1,1.6-6.1,3.7-8.8,6.3c-2.7,2.6-5,5.8-6.7,9.4c-1.6,3.6-2.6,7.8-2.2,12.1
						c-0.2-4.3,0.9-8.4,2.6-12c1.8-3.5,4.1-6.6,6.9-9c2.7-2.4,5.7-4.3,8.8-5.8c3.1-1.4,6.2-2.5,9.4-3.2c6.3-1.5,12.4-1.9,18.3-1.9
						c5.9-0.1,11.7,0.2,17.5,0.2c5.8,0,11.6-0.2,17.6-0.8c6-0.7,12.2-1.7,18.5-3.8c6.3-2,12.9-5,19-9.8c3.1-2.4,6-5.2,8.6-8.6
						C340.9,117.8,343.1,113.9,344.5,109.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M278.5,96c-3.9-1.2-8.4-1.3-12.5,0.5c-2,0.9-3.8,2.1-5.3,3.7c-1.5,1.5-2.7,3.3-3.6,5.3c-0.8,2-1.2,4.2-1.1,6.4
						c0.1,2.2,0.7,4.2,1.6,6c1.8,3.5,4.9,6.1,8.4,7c3.5,1,6.9,0.3,9.4-1.4c1.2-0.8,2.2-1.9,2.8-3c0.6-1.1,0.9-2.2,1-3.1
						c0.2-1.9-0.3-3.4-0.9-4.2c-0.6-0.8-1.2-1-1.6-0.9c-0.8,0.3-0.9,2.1-2.2,3.6c-0.3,0.4-0.7,0.8-1.1,1c-0.4,0.3-0.8,0.4-1.3,0.5
						c-1,0.2-2.4,0.1-3.4-0.5c-1.1-0.6-2.2-1.8-2.7-3.2c-0.6-1.5-0.5-2.8,0.1-4.4c0.7-1.6,2.1-3.1,3.8-4c1.7-0.9,3.9-1.2,6.3-0.9
						c4.9,0.7,8.5,4.3,10.6,8.1c2.2,3.9,3,8.1,2.7,12.3c-0.2,2.1-0.6,4.2-1.5,6.2c-0.4,1-1,2-1.6,3c-0.7,0.9-1.4,1.8-2.4,2.7
						c-1.8,1.6-4.1,3-6.9,3.8c-2.7,0.7-6,0.9-9.2-0.3c3.1,1.4,6.5,1.6,9.4,1.1c3-0.5,5.6-1.7,7.8-3.3c1.1-0.8,2.1-1.7,3-2.6
						c0.9-1,1.7-2,2.3-3.1c1.4-2.1,2.2-4.5,2.9-6.9c1.1-4.8,1-10-0.9-15.4c-1-2.7-2.4-5.4-4.7-8C285.7,99.6,282.5,97.2,278.5,96z"
                    />
                  </g>
                  <g>
                    <path
                      d="M315.1,122.6c2.7-2.4,4.1-5.8,4.2-8.5c0.1-2.8-0.9-4.8-2.6-5.3c-1.7-0.5-3.6,0.5-5.2,2.2c-1.6,1.7-2.9,3.9-3.7,6.8
						c-0.8,2.9-1.5,5-2.5,7.1c-1,2-2.1,4-4.5,6.4c3-1.5,5.1-2.6,7.3-3.8C310.2,126.3,312.4,125.1,315.1,122.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M366,89.8c2.5-1.7,4.3-3.8,5.4-5.9c1.1-2.1,1.5-4.2,0.4-5.7c-1-1.4-3.3-1.7-5.9-0.7c-2.6,1.1-5.4,3.6-6.8,6.9
						c-1.5,3.5-2,6-2.5,8.4c-0.4,2.5-0.8,4.9-1.4,8.3c1.5-3.1,3.1-4.9,4.7-6.6C361.7,93,363.5,91.6,366,89.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M379.9,95.6c3-0.8,5.4-2.4,6.9-4.3c1.5-1.8,2.2-3.9,1.4-5.5c-0.8-1.6-2.9-2.2-5.4-1.9c-2.5,0.3-5.4,1.7-7.6,4.1
						c-2.3,2.5-3.6,4.4-5,6.2c-1.4,1.8-2.8,3.6-5.1,6c2.9-1.5,5.1-2.3,7.2-2.9C374.6,96.9,376.8,96.4,379.9,95.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M390.9,105.2c3.1,0.2,5.9-0.4,8-1.5c2.1-1.1,3.7-2.6,3.6-4.4c0-1.7-1.7-3.3-4.4-4c-2.6-0.7-6.2-0.4-9.3,1.3
						c-3.2,1.7-5.2,3.3-7.1,4.7c-1.9,1.5-3.8,3-6.6,5c3.3-1.1,5.6-1.4,7.9-1.5C385.4,104.9,387.7,105,390.9,105.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M360.3,116.8c2.7-0.8,4.8-2.3,6.1-4c1.3-1.7,2-3.7,1.2-5.3c-0.7-1.6-2.7-2.4-5.1-2.1c-2.4,0.3-5.2,1.7-7.2,4.2
						c-2,2.5-3.1,4.5-4.2,6.4c-1.1,1.9-2.2,3.8-3.9,6.3c2.5-1.8,4.4-2.7,6.3-3.5C355.5,118.1,357.5,117.6,360.3,116.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M373.4,121.3c2.6,0.1,4.9-0.6,6.6-1.8c1.7-1.2,2.8-2.9,2.6-4.6c-0.2-1.7-1.8-3.1-4.1-3.6c-2.3-0.5-5.2,0-7.7,1.7
						c-2.5,1.8-4.1,3.3-5.6,4.7c-1.5,1.5-3,2.9-5.3,4.7c2.8-0.9,4.8-1.1,6.7-1.2C368.7,121,370.7,121.2,373.4,121.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M293.2,147.9c-6.4,0.5-11.3,5.2-10.1,8.5c1.2,3.3,8,3.6,13.2-0.3c5.3-4,7-6.2,12.8-9.5
						C302.5,148,299.8,147.4,293.2,147.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M300.9,162.8c-1.7,2.4-2,5.5-1.2,7.6c0.7,2.2,2.4,3.5,4.1,3.4c1.8-0.1,3.2-1.3,4.2-2.8c1-1.5,1.5-3.4,1.3-5.6
						c-0.2-2.3-0.3-4.1-0.2-5.9c0.2-1.8,0.5-3.6,1.8-5.9c-2.1,1.6-3.6,2.8-5.1,4.1C304.2,159,302.7,160.4,300.9,162.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M250.7,158.6c5.2-0.2,10.2-0.8,14.1-1.6c3.9-0.8,6.7-2,7-3.7c0.2-1.7-2.4-3.7-6.9-4.6c-4.4-1-10.5-0.7-16.1,1.4
						c-5.7,2.2-9.2,4.4-12.7,6.7c-3.4,2.3-6.7,4.7-11.2,8.5c5.1-2.9,9-4.2,12.8-5.1C241.5,159.3,245.3,158.8,250.7,158.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M452.7,61.6c-0.5-3.2-1.3-6.4-2.5-9.5c-1.2-3.1-2.9-6.2-5.1-8.9c-2.2-2.7-5-5-8.1-6.7c-3.1-1.7-6.7-2.7-10.3-2.8
						l-1.4,0l-1.4,0.1c-0.9,0.1-1.8,0.3-2.7,0.5c-1.8,0.5-3.4,1.1-4.9,2c-3,1.7-5.6,4.2-7.3,7.3c-1.7,3-2.4,6.8-1.9,10
						c0.2,1.6,0.7,3.2,1.3,4.6c0.6,1.4,1.4,2.7,2.2,3.8c3.4,4.5,8.5,7,13.1,7.2c2.3,0.1,4.6-0.3,6.5-1.3c1-0.5,1.8-1.1,2.5-1.8
						c0.7-0.7,1.2-1.5,1.7-2.3c1.5-3.2,1-6,0-7.5c-0.5-0.8-1-1.2-1.4-1.4c-0.4-0.2-0.7-0.2-0.9,0c-0.3,0.3-0.1,1.1-0.1,2.2
						c0,0.6-0.1,1.2-0.4,1.9c-0.3,0.7-0.7,1.5-1.3,2.1c-0.3,0.3-0.7,0.6-1,0.8c-0.3,0.2-0.8,0.4-1.3,0.5c-1,0.2-2.2,0.2-3.5-0.1
						c-2.6-0.6-5.4-2.4-7.1-5.2c-0.4-0.7-0.8-1.4-1-2.2c-0.2-0.8-0.4-1.5-0.4-2.3c-0.1-0.8,0-1.5,0.2-2.2c0.2-0.7,0.5-1.4,0.9-2.1
						c0.8-1.4,2.2-2.6,3.9-3.5c0.8-0.4,1.7-0.8,2.6-1c0.4-0.1,0.9-0.2,1.4-0.2l0.7,0l0.7,0c1.9,0.1,4,0.6,5.9,1.7
						c1.9,1,3.8,2.5,5.3,4.2c3.1,3.5,5.2,8.4,6.4,13.7c1.2,5.5,1.6,10.9,1.4,15.9c-0.2,5-0.9,9.7-1.9,14.1
						c-2.1,8.8-5.4,16.4-9.6,23.5c-4.2,7.1-9.5,13.8-16.3,20.4c-6.8,6.6-15.2,13.2-26.1,19.6c11.2-6,20-12.1,27.3-18.3
						c7.3-6.2,13.3-12.6,18.3-19.6c5-7,9.2-14.7,12.3-23.9c1.5-4.6,2.7-9.6,3.4-15.1C453.5,74.1,453.6,68.1,452.7,61.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M420.9,70.1c-1.7-0.9-3.7-1.5-5.7-1.6c-2.1-0.2-4.2,0.2-6.2,0.9c-1.9,0.7-3.7,1.8-5.2,3.1c-1.5,1.4-2.7,3-3.5,5
						c-0.8,1.9-1.2,4.1-1.1,6c0.1,2,0.6,3.8,1.4,5.5c1.6,3.3,4.3,5.8,7.4,6.9c1.6,0.6,3.2,0.8,4.6,0.6c1.4-0.1,2.7-0.5,3.8-0.9
						c2.2-1,3.7-2.4,4.6-3.8c0.9-1.4,1.2-2.7,1.1-3.6c-0.1-0.9-0.4-1.4-0.8-1.5c-0.9-0.2-1.9,0.9-3.8,1.6c-0.9,0.4-2,0.7-3.2,0.8
						c-1.2,0-2.4-0.2-3.2-0.9c-0.9-0.6-1.7-1.7-2.1-3c-0.2-0.6-0.3-1.3-0.3-2c0-0.6,0.2-1.2,0.4-1.8c0.5-1.2,1.7-2.4,3.2-3
						c0.7-0.3,1.5-0.5,2.2-0.6c0.8,0,1.7,0.1,2.6,0.3c1.9,0.6,3.8,1.9,5.4,3.4c0.8,0.8,1.5,1.6,2.2,2.4c0.7,0.8,1.3,1.7,1.9,2.6
						c2.3,3.5,3.9,7.1,5,10.9c1.1,3.8,1.7,7.8,1.5,12.4c0,0.6-0.1,1.1-0.1,1.7c0,0.6-0.1,1.2-0.2,1.8c-0.1,1.2-0.4,2.4-0.6,3.7
						c-0.6,2.5-1.4,5.2-2.7,8c1.5-2.7,2.7-5.3,3.5-7.8c0.4-1.3,0.8-2.5,1.1-3.7c0.1-0.6,0.3-1.2,0.4-1.8c0.1-0.6,0.2-1.2,0.3-1.8
						c0.8-4.7,0.9-9,0.5-13.3c-0.5-4.3-1.4-8.6-3.3-13.1c-0.5-1.1-1-2.3-1.7-3.4c-0.6-1.2-1.4-2.4-2.2-3.5
						C426.7,74.4,424.4,72,420.9,70.1z"
                    />
                  </g>
                  <g>
                    <g>
                      <path
                        d="M503.8,68.5c5.7-0.7,11.4-1.1,16.9-1.7c5.5-0.5,10.7-1.1,15.1-2c4.5-0.8,8.2-1.9,10.7-2.9c2.5-1.1,3.9-2.2,3.7-3
							c-0.2-0.9-1.8-1.4-4.4-1.8c-2.6-0.4-6.3-0.5-10.8-0.6c-4.4-0.1-9.6,0-15.3,0.4c-5.6,0.4-11.8,1.3-17.7,2.9
							c-6.2,1.7-11.2,3.7-15.6,6c-4.3,2.3-8,4.8-11.3,7.8c-0.8,0.7-1.6,1.5-2.4,2.3c-0.8,0.8-1.5,1.6-2.3,2.5
							c-1.5,1.7-2.9,3.6-4.2,5.6c-2.6,4.1-4.9,9.2-5.6,15.5c1.2-6.2,4.1-10.9,7-14.5c1.5-1.8,3.1-3.4,4.7-4.7c0.8-0.7,1.7-1.3,2.5-2
							c0.9-0.6,1.7-1.2,2.6-1.7c3.5-2.2,7.2-3.8,11.4-5.1C493.2,70.2,498,69.2,503.8,68.5z"
                      />
                    </g>
                    <g>
                      <path
                        d="M512,85.7c5.6-1.4,11.1-3.6,15.9-6.1c4.8-2.5,9.1-5.5,12.4-8.3c6.7-5.7,9.8-10.9,8.6-12.1c-1.2-1.3-6.2,1.4-13.3,5.2
							c-7.1,3.9-16.5,8.8-26.3,13c-5.1,2.2-9.5,3.7-13.6,4.9c-4.1,1.2-8,2.1-11.9,3.3c-3.9,1.1-7.8,2.4-11.9,4.4
							c-2,1-4.2,2.2-6.2,3.8c-2.1,1.5-4.1,3.5-5.8,5.9c1.9-2.2,4.1-3.9,6.3-5.2c2.2-1.3,4.3-2.2,6.4-2.9c4.2-1.4,8.1-1.9,12-2.3
							c3.9-0.4,7.8-0.5,12.3-1C501.2,87.9,506.2,87.1,512,85.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M519.6,76.1c6.3-1,12.2-3.4,16.2-6c4.1-2.6,6.3-5.3,5.6-6.9c-0.7-1.6-4.1-1.8-8.7-0.9c-4.5,0.9-10.2,2.7-15.7,5.4
							c-5.7,2.7-9.6,4.6-13.6,6.3c-4.1,1.7-8.3,3.3-14.6,4.9c6.6-0.6,11-0.9,15.5-1.2C508.7,77.4,513.2,77.1,519.6,76.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M517.7,72.2c4.9-0.5,9.4-2.3,12.5-4.4c3.1-2.1,4.8-4.4,4.1-6c-0.7-1.6-3.4-2.2-7-1.8c-3.6,0.4-8,1.7-12.2,3.8
							c-4.3,2.3-7.3,3.9-10.4,5.4c-3.1,1.5-6.2,2.9-11.1,4.4c5-0.4,8.5-0.6,12-0.7C509.2,72.8,512.7,72.7,517.7,72.2z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M490.4,213.7c-5.6-1.1-11.2-2.4-16.6-3.5c-5.4-1.2-10.5-2.2-15-2.7c-4.5-0.6-8.4-0.7-11.1-0.5
							c-2.7,0.3-4.3,0.9-4.5,1.8c-0.1,0.9,1.2,1.9,3.6,3c2.4,1.1,5.9,2.4,10.1,3.8c4.2,1.4,9.2,2.9,14.7,4.2
							c5.5,1.3,11.6,2.3,17.8,2.6c6.4,0.2,11.8-0.1,16.7-1c4.8-0.9,9.1-2.1,13.1-4c1-0.4,2-1,3-1.4c1-0.5,2-1.1,2.9-1.7
							c1.9-1.2,3.8-2.5,5.7-4.1c3.7-3.1,7.4-7.3,10-13.1c-3.1,5.6-7.2,9.1-11.1,11.7c-2,1.3-3.9,2.3-5.9,3.1c-1,0.4-2,0.8-2.9,1.1
							c-1,0.3-2,0.6-3,0.8c-3.9,1-8,1.4-12.4,1.4C501.1,215.3,496.2,214.8,490.4,213.7z"
                      />
                    </g>
                    <g>
                      <path
                        d="M487.9,194.8c-5.8-0.4-11.6,0.1-17,1c-5.4,1-10.3,2.5-14.3,4.2c-8.1,3.4-12.6,7.4-11.8,8.9c0.8,1.6,6.3,0.6,14.3-0.9
							c8-1.5,18.4-3.4,29-4.4c5.5-0.6,10.2-0.7,14.5-0.6c4.3,0.1,8.3,0.4,12.3,0.5c4,0.1,8.2,0.1,12.7-0.6c2.3-0.4,4.6-0.9,7.1-1.7
							c2.4-0.9,4.9-2.1,7.3-3.9c-2.5,1.6-5.1,2.5-7.5,3.1c-2.4,0.6-4.8,0.8-7,0.8c-4.4,0-8.3-0.6-12.1-1.4c-3.8-0.8-7.6-1.9-12-2.8
							C498.8,196,493.9,195.2,487.9,194.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M477.7,201.6c-6.3-0.9-12.6-0.4-17.3,0.8c-4.7,1.2-7.6,3.1-7.4,4.9c0.2,1.8,3.4,2.9,8,3.5c4.6,0.5,10.6,0.5,16.6-0.4
							c6.2-0.9,10.5-1.5,14.9-1.9c4.4-0.4,8.9-0.6,15.4-0.3c-6.4-1.4-10.8-2.5-15.2-3.5C488.5,203.7,484.2,202.6,477.7,201.6z"
                      />
                    </g>
                    <g>
                      <path
                        d="M478.3,205.9c-4.8-1-9.7-0.7-13.3,0.4c-3.6,1-5.9,2.8-5.8,4.5c0.1,1.8,2.6,3.1,6.1,3.9c3.5,0.7,8.1,0.8,12.8,0
							c4.8-0.8,8.1-1.5,11.5-2c3.4-0.5,6.8-0.9,11.9-0.8c-4.9-1.1-8.3-2-11.6-3C486.6,207.9,483.3,206.9,478.3,205.9z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M295.7,319.1c4.1-3.9,8.4-7.8,12.5-11.5c4.1-3.7,7.9-7.3,10.9-10.7c3.1-3.3,5.4-6.4,6.8-8.8c1.4-2.4,1.8-4.1,1.2-4.7
							c-0.6-0.6-2.3-0.1-4.6,1.2c-2.3,1.3-5.4,3.4-9,6c-3.6,2.6-7.7,5.8-12,9.5c-4.2,3.7-8.6,8.1-12.4,13c-3.9,5.1-6.7,9.7-8.9,14.2
							c-2.1,4.4-3.5,8.7-4.4,13c-0.2,1.1-0.4,2.2-0.6,3.3c-0.1,1.1-0.3,2.2-0.3,3.3c-0.2,2.3-0.2,4.6,0,7c0.4,4.8,1.6,10.3,4.8,15.8
							c-2.8-5.7-3.3-11.1-3.1-15.8c0.1-2.3,0.4-4.5,0.9-6.6c0.2-1,0.5-2,0.8-3c0.3-1,0.6-2,1-2.9c1.5-3.8,3.5-7.3,6.1-11
							C288.2,326.9,291.4,323.2,295.7,319.1z"
                      />
                    </g>
                    <g>
                      <path
                        d="M312.5,328c3.7-4.5,6.7-9.5,9-14.5c2.3-4.9,4-9.8,4.9-14.1c1.9-8.6,1.3-14.5-0.4-14.8c-1.8-0.3-4.2,4.8-7.5,12.2
							c-3.4,7.4-7.9,16.9-13.3,26.2c-2.7,4.8-5.4,8.7-7.9,12.1c-2.6,3.4-5.1,6.5-7.5,9.7c-2.4,3.2-4.8,6.6-6.9,10.7
							c-1,2.1-2,4.3-2.7,6.8c-0.7,2.5-1.2,5.2-1.1,8.2c0.2-3,0.9-5.6,1.9-7.9c1-2.3,2.2-4.3,3.4-6.1c2.5-3.6,5.3-6.4,8.2-9
							c2.9-2.7,5.9-5.1,9.3-8.2C305.2,336.2,308.7,332.7,312.5,328z"
                      />
                    </g>
                    <g>
                      <path
                        d="M312.9,315.8c4.4-4.6,7.7-10,9.4-14.5c1.7-4.5,1.8-8,0.3-8.8c-1.6-0.9-4.4,1.1-7.5,4.5c-3.1,3.4-6.5,8.3-9.3,13.8
							c-2.9,5.6-4.9,9.4-7.1,13.3c-2.2,3.8-4.6,7.6-8.7,12.7c4.9-4.4,8.3-7.4,11.7-10.3C305,323.4,308.3,320.5,312.9,315.8z"
                      />
                    </g>
                    <g>
                      <path
                        d="M309,313.8c3.6-3.4,6.2-7.5,7.4-11c1.2-3.5,1.2-6.4-0.3-7.3c-1.5-0.9-4,0.3-6.7,2.7c-2.6,2.5-5.4,6.1-7.4,10.4
							c-2.1,4.4-3.5,7.5-5,10.5c-1.6,3-3.3,6.1-6.2,10.2c3.8-3.4,6.5-5.6,9.2-7.8C302.6,319.3,305.3,317.2,309,313.8z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M112.7,104.3c4.4,3.7,8.6,7.6,12.7,11.2c4.1,3.6,8.1,7,11.7,9.8c3.6,2.7,6.9,4.7,9.5,5.9c2.5,1.1,4.2,1.4,4.7,0.7
							c0.5-0.7-0.2-2.2-1.7-4.4c-1.5-2.2-3.9-5-6.9-8.3c-3-3.3-6.6-7.1-10.7-10.9c-4.2-3.8-8.9-7.7-14.2-11
							c-5.4-3.3-10.4-5.7-15-7.3c-4.6-1.6-9-2.6-13.4-3c-1.1-0.1-2.2-0.1-3.3-0.2c-1.1,0-2.2,0-3.4,0c-2.3,0.1-4.6,0.3-7,0.8
							c-4.8,0.9-10.1,2.7-15.2,6.4c5.4-3.3,10.7-4.4,15.4-4.7c2.3-0.1,4.6,0,6.7,0.2c1.1,0.1,2.1,0.3,3.1,0.5c1,0.2,2,0.4,3,0.7
							c3.9,1.1,7.6,2.7,11.5,4.9C104.2,97.6,108.2,100.5,112.7,104.3z"
                      />
                    </g>
                    <g>
                      <path
                        d="M105.7,121.9c4.9,3.2,10.2,5.7,15.3,7.5c5.2,1.8,10.2,2.9,14.5,3.4c8.7,1,14.6-0.3,14.7-2c0.1-1.8-5.2-3.6-12.9-6.2
							c-7.7-2.6-17.7-6.1-27.5-10.4c-5.1-2.2-9.2-4.4-12.9-6.6c-3.7-2.2-7-4.4-10.5-6.5C83,99,79.4,97,75.1,95.4
							c-2.1-0.8-4.5-1.5-7-2c-2.5-0.5-5.3-0.6-8.3-0.2c3-0.1,5.7,0.3,8.1,1c2.4,0.7,4.5,1.7,6.5,2.7c3.9,2.1,6.9,4.6,9.8,7.2
							c2.9,2.6,5.7,5.4,9.1,8.4C96.7,115.5,100.6,118.7,105.7,121.9z"
                      />
                    </g>
                    <g>
                      <path
                        d="M117.9,121c5,3.9,10.8,6.6,15.4,7.8c4.7,1.2,8.1,1,8.8-0.6c0.7-1.6-1.5-4.2-5.3-7c-3.7-2.7-9-5.6-14.7-7.8
							c-5.8-2.3-9.9-3.9-13.9-5.7c-4-1.8-8-3.8-13.6-7.4c4.9,4.4,8.2,7.5,11.5,10.5C109.5,113.9,112.7,117,117.9,121z"
                      />
                    </g>
                    <g>
                      <path
                        d="M119.5,117c3.7,3.2,8.1,5.3,11.7,6.2c3.6,0.9,6.5,0.5,7.2-1.1c0.8-1.6-0.7-4-3.4-6.4c-2.7-2.4-6.7-4.7-11.1-6.3
							c-4.6-1.6-7.8-2.7-11-3.9c-3.2-1.2-6.4-2.6-10.8-5.1c3.8,3.4,6.2,5.8,8.7,8.3C113.2,111.2,115.6,113.7,119.5,117z"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M46.9,120.2c-4.8-3.3-10.4-5.5-16.3-6.1c-2.9-0.3-5.9-0.2-8.8,0.3c-2.9,0.5-5.8,1.4-8.4,2.9c-2.6,1.5-5.1,3.5-6.9,6.1
						c-0.5,0.7-0.9,1.3-1.2,2c-0.3,0.7-0.7,1.5-0.9,2.2L4,128.7l-0.2,1.1c-0.2,0.8-0.2,1.5-0.3,2.3c-0.1,3,0.5,5.8,1.6,8.3
						c1.1,2.5,2.6,4.6,4.5,6.3c1.8,1.7,4,3,6.2,3.6c2.1,0.7,4.2,1,6.1,0.9c1.9-0.1,3.6-0.4,5.2-0.9c1.5-0.5,2.8-1.1,3.9-1.8
						c2.2-1.3,3.6-2.9,4.3-4c0.7-1.2,0.8-2,0.5-2.2c-0.3-0.3-1.1-0.1-2.2,0.3c-1.1,0.4-2.6,1.1-4.6,1.5c-1,0.2-2,0.4-3.2,0.5
						c-1.1,0.1-2.4,0-3.6-0.2c-1.2-0.2-2.5-0.6-3.6-1.2c-1.2-0.6-2.1-1.4-3-2.4c-1.8-2.1-2.9-5.2-2.6-8.1c0.1-0.3,0.1-0.7,0.2-1
						l0.1-0.5l0.2-0.5c0.1-0.3,0.3-0.6,0.4-0.9c0.2-0.3,0.4-0.6,0.6-0.9c0.9-1.2,2.2-2.2,3.7-3.1c1.6-0.9,3.4-1.5,5.4-1.8
						c2-0.4,4.1-0.5,6.2-0.3c4.2,0.3,8.6,1.6,12.4,3.9c4,2.3,7.2,5.4,9.7,8.7c2.5,3.3,4.3,6.9,5.7,10.4c2.6,7.1,3.3,14.2,2.9,21.3
						c-0.4,7.1-1.8,14.3-4.7,22c-3,7.6-7.4,15.8-14.9,23.8c7.8-7.7,12.8-15.6,16.5-23.2c3.6-7.6,5.7-14.8,6.9-22.3
						c0.6-3.7,0.9-7.5,0.8-11.4c-0.1-3.9-0.5-8-1.6-12.1c-1.1-4.2-2.8-8.5-5.4-12.7C55.4,127.8,51.9,123.6,46.9,120.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M34,153.7c-3,0.6-5.5,2.1-7.5,4c-2,1.9-3.5,4.3-4.3,7c-0.4,1.3-0.6,2.7-0.6,4.1c0,1.4,0.2,2.8,0.7,4.1
						c0.8,2.5,2.4,4.7,4.4,6.1c1,0.7,2,1.2,3.1,1.5c1.1,0.3,2.1,0.4,3,0.4c1.9-0.1,3.4-0.7,4.4-1.5c1-0.8,1.6-1.6,1.8-2.3
						c0.2-0.7,0-1.2-0.3-1.5c-0.7-0.6-1.9-0.4-3.2-0.7c-0.7-0.1-1.4-0.4-2-0.7c-0.3-0.2-0.6-0.4-0.8-0.6c-0.2-0.2-0.4-0.5-0.6-0.8
						c-0.4-0.6-0.7-1.4-0.8-2.3c-0.1-0.4-0.1-0.9,0-1.3c0-0.5,0.2-1,0.3-1.5c0.6-2.1,2.6-4.5,4.7-5.5c1.1-0.5,2.1-0.7,3.3-0.5
						c1.2,0.2,2.3,0.8,3.3,1.6c1,0.8,1.8,1.7,2.5,2.8c0.7,1.1,1.2,2.3,1.6,3.6c0.8,2.6,1,5.5,0.9,8.8c-0.1,3.3-0.7,7-0.7,11.4
						c0.6-4.4,1.7-7.9,2.4-11.2c0.8-3.3,1.3-6.4,1.2-9.6c-0.1-1.6-0.3-3.3-0.8-4.9c-0.5-1.7-1.3-3.4-2.5-5c-1.2-1.6-2.8-3.2-5.1-4.3
						c-1.1-0.6-2.4-1-3.8-1.2C37.1,153.3,35.5,153.4,34,153.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M80.9,129c2-0.8,3.3-2.1,4-3.7c0.7-1.6,0.9-3.4,0.1-5c-0.8-1.5-2.7-2.3-4.8-1.9c-2.1,0.4-4.4,2-5.6,4.5
						c-1.2,2.6-1.6,4.5-1.9,6.4c-0.3,1.9-0.7,3.6-1.1,6.1c1.5-2,2.9-3.1,4.3-4C77.3,130.6,78.8,129.9,80.9,129z"
                    />
                  </g>
                  <g>
                    <path
                      d="M87.6,140.1c1.9,0,3.2-0.8,4.1-2.1c0.9-1.2,1.4-2.9,1-4.7c-0.4-1.7-1.8-3-3.7-3.3c-1.9-0.3-4.2,0.4-5.7,2.3
						c-1.5,2-2.3,3.5-3,5c-0.7,1.5-1.3,2.9-2.3,4.8c1.8-1.2,3.2-1.6,4.7-1.9C84.1,140.2,85.6,140.1,87.6,140.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M69.7,113.9c0.7-3.1,0.1-6.2-1.2-8.3c-1.3-2.1-3.2-3.1-4.9-2.7c-1.7,0.5-2.8,2.2-3.3,4.3c-0.5,2.1-0.3,4.6,0.7,7.2
						c1.1,2.6,1.8,4.6,2.5,6.6c0.6,2,1,4.1,1,7.1c1.1-2.9,1.9-4.9,2.8-6.9C68.1,119.2,69,117.1,69.7,113.9z"
                    />
                  </g>
                  <g>
                    <path d="M51.6,99.8c-0.9-5.8-6-9.3-9.1-7.5c-3,1.8-2.7,7.7,1.3,11.5c4.1,4,6.3,5.3,9.7,10.1C52.1,108.1,52.6,105.7,51.6,99.8z" />
                  </g>
                  <g>
                    <path
                      d="M25.9,96.2c-2.9-2.2-6.5-3.1-9.2-2.9c-2.8,0.2-4.8,1.4-5.1,3.1c-0.3,1.7,0.9,3.6,3,5c2,1.5,4.9,2.6,8.1,2.9
						c3.3,0.4,5.7,0.6,8.1,1.1c2.4,0.5,4.7,1.1,7.9,2.7c-2.6-2.5-4.3-4.2-6.1-6C30.7,100.2,28.9,98.4,25.9,96.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-1.6,103.7c-2.7-0.3-5.2,0.9-6.5,2.5c-1.4,1.7-1.5,3.6-0.6,5.1c1,1.5,2.6,2.2,4.2,2.4c1.6,0.2,3.2-0.2,4.8-1.5
						c1.6-1.3,3-2.3,4.4-3c1.4-0.8,3-1.3,5.4-1.4c-2.1-0.9-3.7-1.6-5.5-2.4C3,104.7,1.2,104-1.6,103.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-13.1,124.3c-2.5,1.7-3.7,4.6-3.5,6.9c0.2,2.4,1.6,3.9,3.3,4.1c1.8,0.2,3.4-0.6,4.6-1.5c1.3-1,2.1-2.2,2.5-4.1
						c0.4-2,0.9-3.5,1.7-5c0.8-1.4,1.9-2.8,4.1-4c-2.5,0.2-4.3,0.5-6.2,0.9C-8.6,122.1-10.6,122.6-13.1,124.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-9.6,149.9c-0.9,2.6-0.3,5.4,1.1,7.1c1.4,1.7,3.3,2.2,4.9,1.5c1.6-0.7,2.7-2.2,3.2-3.7c0.5-1.5,0.5-3.1-0.4-4.8
						c-0.9-1.8-1.5-3.3-1.9-4.9c-0.3-1.6-0.5-3.2,0.1-5.5c-1.6,1.8-2.7,3.2-3.8,4.6C-7.5,145.6-8.6,147.2-9.6,149.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M3.1,168.8c0.4,2.9,2.4,5.2,4.4,6.2c2.1,1,4.1,0.7,5.2-0.6c1.2-1.3,1.4-3.2,1.1-4.9c-0.3-1.7-1.2-3.3-2.9-4.7
						c-1.7-1.4-3-2.6-4.1-3.9c-1.1-1.3-2-2.9-2.7-5.4c-0.4,2.6-0.7,4.4-1,6.4C2.9,163.8,2.7,165.8,3.1,168.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M75.4,229.7c2.3,1.4,4,3.2,4.8,5.2c0.9,2,0.9,4.3,0.3,5.8c-0.6,1.5-2,2.8-3.6,3.4c-0.8,0.3-1.5,0.4-2.3,0.4
						c-0.8,0-1.6-0.2-2.3-0.6c-1.5-0.6-2.7-1.8-3.4-2.9c-0.7-1.1-0.9-2.1-0.8-2.9c0.1-0.8,0.6-1.6,1.1-2c0.4-0.5,0.9-0.8,1-1.2
						c0.1-0.4-0.3-1-1.5-1.3c-1.1-0.2-3,0.1-4.7,1.8c-0.4,0.4-0.8,1-1.1,1.6c-0.3,0.6-0.5,1.2-0.7,1.9c-0.3,1.4-0.3,2.8,0.1,4.3
						c0.7,2.9,2.5,5.8,5.5,7.9c1.5,1,3.3,1.8,5.3,2.3c2,0.4,4.2,0.4,6.3-0.2c2.1-0.5,4.1-1.5,5.9-3c1.8-1.4,3.3-3.4,4.2-5.7
						c0.9-2.3,1.3-4.7,1.1-7c-0.1-2.3-0.8-4.6-1.8-6.7c-1-2.1-2.5-4-4.2-5.5c-1.7-1.5-3.6-2.6-5.6-3.4c-4.1-1.7-8.1-2-11.5-1.8
						c-3.5,0.2-6.5,0.9-9.3,1.7c-5.5,1.7-10.2,3.7-14.6,6.1c-4.5,2.5-8.8,5.2-13,9c-2.1,1.9-4.3,4-6.3,6.4c-2.1,2.5-4.1,5.2-5.8,8.5
						c2-3.1,4.2-5.7,6.4-7.9c2.2-2.2,4.5-4.1,6.8-5.6c4.5-3.2,9-5.2,13.5-6.9c4.5-1.7,9.2-2.8,14.4-3.6c2.6-0.3,5.2-0.5,7.9-0.3
						c1.3,0.1,2.7,0.3,4,0.7C72.9,228.5,74.2,229,75.4,229.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M55.5,215c4.5-2.5,8.1-6.3,10.1-9.7c2-3.4,2.4-6.3,1-7.5c-1.4-1.1-4.1-0.2-7.1,1.7c-3.1,2-6.5,5-9.6,8.6
						c-3.2,3.8-5.4,6.3-7.9,8.8c-2.5,2.5-5.2,4.8-9.6,7.6c4.8-1.8,8.2-3.1,11.7-4.4C47.5,218.9,50.9,217.5,55.5,215z"
                    />
                  </g>
                  <g>
                    <path
                      d="M75.4,216.2c2.3,0.5,4.3,0.1,5.9-0.8c1.6-1,2.7-2.5,2.7-4.3c0-1.8-1.2-3.3-3.1-4.1c-1.9-0.8-4.5-0.8-6.8,0.5
						c-2.4,1.3-3.8,2.5-5.3,3.6c-1.5,1.1-2.9,2.2-5.1,3.5c2.5-0.3,4.3-0.1,6,0.2C71.3,215.2,73,215.7,75.4,216.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M35.3,208.6c0.9-2.4,1.3-5,1.3-7.4c0-0.6,0-1.2-0.1-1.8c-0.1-0.6-0.1-1.1-0.2-1.7c-0.2-1.2-0.6-2.3-1.1-3.4
						c-0.5-1.1-1.1-2-1.9-2.8c-0.7-0.8-1.6-1.3-2.3-1.6c-1.6-0.5-2.6,0-3,0.7c-0.4,0.8-0.3,1.8-0.5,2.6c-0.1,0.9-0.3,1.8-0.4,3
						c-0.1,0.7-0.1,1.4-0.2,2.1c0,0.4-0.1,0.9-0.1,1.3c0,0.5,0,0.9-0.1,1.4c-0.1,1.9-0.1,3.9,0,6c0.1,4.3-0.2,7.5-0.8,10.6
						c-0.7,3.1-1.8,6.3-4.7,10.3c3.7-3.3,5.9-5.9,8.1-8.7c1.1-1.4,2.1-2.8,3.1-4.5C33.4,213,34.4,211.1,35.3,208.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M124.7,53.8c3.3-0.9,6.7-1.7,9-1.4c1.2,0.1,2,0.4,2.7,0.9c0.6,0.4,1.2,1.1,1.6,2c0.9,1.8,1,4.3,0.5,6.2
						c-0.1,0.5-0.3,1-0.5,1.5c-0.2,0.5-0.5,0.9-0.7,1.4c-0.5,0.8-1.2,1.5-1.8,2.1c-1.2,1-2.9,1.5-4.2,1.4c-0.7,0-1.3-0.2-1.8-0.4
						c-0.5-0.3-1-0.6-1.4-1c-1.7-1.6-1.8-3.6-2.6-3.9c-0.4-0.1-1,0.2-1.5,1.2c-0.5,1-0.8,2.8-0.1,4.9c0.3,1,0.9,2.2,1.9,3.2
						c1,1.1,2.2,2,3.8,2.6c3,1.2,6.9,1.2,10.4-0.7c1.7-0.9,3.3-2.2,4.6-3.7c0.7-0.8,1.2-1.6,1.8-2.5c0.5-0.9,1-1.8,1.4-2.9
						c0.8-2.1,1.1-4.3,1.1-6.5c-0.1-2.3-0.5-4.6-1.5-6.9c-1.1-2.3-2.8-4.5-5.2-6c-2.4-1.5-5.1-2.2-7.6-2.3c-2.5-0.1-4.7,0.3-6.8,0.9
						c-2.1,0.6-3.9,1.2-5.7,2c-3.8,1.6-7.6,3.8-10.3,7c-1.3,1.6-2.3,3.5-2.9,5.3c-0.6,1.8-0.9,3.6-0.9,5.3c-0.1,3.4,0.5,6.4,1.3,9.2
						c0.9,2.8,2,5.4,3.4,7.8c2.8,4.8,6.6,9.1,11.7,12.4c2.6,1.7,5.5,3.1,8.7,4.2c3.3,1.1,6.9,1.7,10.9,1.8
						c-3.9-0.3-7.5-1.3-10.6-2.6c-3.1-1.3-5.7-3-8-4.8c-4.5-3.7-7.5-8.1-9.4-12.7c-1-2.3-1.6-4.7-2-7.2c-0.4-2.5-0.5-5,0-7.5
						c0.2-1.2,0.6-2.4,1.1-3.4c0.5-1,1.2-2,2.1-2.8C118.7,56,121.3,54.8,124.7,53.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M86,72c-3-1.1-6.3-0.6-8.4,0.7c-2.1,1.3-3.1,3.2-2.6,4.9c0.5,1.7,2.2,2.8,4,3.3c1.9,0.6,4,0.6,6.4-0.2
						c2.5-0.8,4.3-1.2,6.3-1.4c1.9-0.2,4-0.2,6.8,0.7c-2.3-1.8-4-3.1-5.7-4.3C90.9,74.4,89.1,73.1,86,72z"
                    />
                  </g>
                  <g>
                    <path
                      d="M99.5,62.1c0.8-1.3,0.8-2.3,0.3-3.6c-0.5-1.2-1.3-2.8-2.7-3.8c-0.7-0.5-1.6-0.7-2.5-0.6c-0.9,0.1-1.8,0.5-2.6,1.3
						c-0.8,0.8-1.4,1.9-1.7,3.2c-0.3,1.3-0.2,2.8,0.4,4.1c1.2,2.8,2.7,4.1,4.1,5.3c1.4,1.1,2.9,2,4.9,2.9c-1.3-1.8-1.6-3.3-1.5-4.7
						C98.2,64.8,98.7,63.5,99.5,62.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M152.6,18.3c3.1-2.4,6-4.9,8.2-6.9c2.2-2,3.6-3.6,3.1-4.4c-0.4-0.8-2.6-0.5-5.5,1c-2.8,1.4-6.3,4-8.9,7.3
						c-2.7,3.4-4.2,5.9-5.6,8.5c-1.4,2.6-2.7,5.2-4.4,9.2c2.2-3.7,4-6,6-8.1C147.4,22.8,149.4,20.8,152.6,18.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M161.5,23.3c3.2-1.8,6.3-3.8,8.5-5.5c2.2-1.7,3.6-3.2,3.2-4c-0.4-0.8-2.5-0.7-5.2,0.3c-2.8,1-6.2,3-9.1,5.6
						c-2.9,2.7-4.7,4.8-6.5,6.9c-1.7,2.1-3.5,4.3-5.9,7.5c2.8-2.9,5-4.6,7.2-6.1C155.9,26.6,158.1,25.2,161.5,23.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M160,33.4c2.1-0.6,3.9-1.6,5.2-2.7c1.3-1,2-2.1,1.7-3c-0.3-0.8-1.6-1.2-3.4-0.9c-1.7,0.3-3.9,1.2-5.7,2.7
						c-1.8,1.6-2.9,2.8-4,4c-1.1,1.2-2.2,2.4-3.9,4.1c2-1.3,3.5-1.9,4.9-2.5C156.3,34.6,157.8,34.1,160,33.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M207.1,151.4c1.6-3.8,3.4-7.5,4.8-10.2c1.4-2.8,2.4-4.8,1.8-5.4c-0.6-0.6-2.8,0.4-5.2,3c-2.4,2.6-4.7,6.8-5.6,11.4
						c-0.8,4.7-0.7,8-0.3,11.2c0.4,3.2,1,6.4,2.6,10.8c-1.1-4.5-1.1-7.7-0.7-10.8C204.8,158.3,205.5,155.3,207.1,151.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M186.8,193.3c-0.7,3.1-1.2,5.4-1.8,8c-0.5,2.2-0.7,3.9,0,4.4c0.7,0.5,2.3-0.5,3.8-2.8c1.4-2.2,2.6-5.8,2.3-9.3
						c-0.2-3.6-1-6-1.8-8.2c-0.9-2.2-1.9-4.4-3.8-7.3c1.5,3.1,1.8,5.5,2,7.7C187.6,188,187.5,190.3,186.8,193.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M166,263.2c-0.7,3.5-2,6.9-3.1,9.4c-1.1,2.5-2,4.4-1.3,5c0.6,0.6,2.7-0.2,4.9-2.8c1.1-1.3,2.1-2.9,2.8-4.9
						c0.7-1.9,1.2-4.2,1.1-6.4c0-2.3-0.4-4.1-0.9-5.8c-0.5-1.6-1-3-1.7-4.3c-1.4-2.6-3-5.2-6.2-8.1c2.8,3.3,3.8,6,4.4,8.7
						c0.3,1.3,0.4,2.7,0.5,4.2C166.5,259.8,166.4,261.4,166,263.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M249.5,317.1c1.2-0.7,2.4-1.2,3.5-1.4c0.6-0.1,1.1-0.1,1.6-0.1c0.5,0,1,0.2,1.5,0.3c2,0.7,4,2.6,5.2,4.8
						c0.3,0.5,0.5,1.1,0.8,1.7c0.2,0.6,0.4,1.2,0.5,1.8c0.2,1.2,0.3,2.3,0.2,3.3c-0.2,1.9-1.3,3.4-2.5,4.1c-0.1,0.1-0.3,0.2-0.4,0.2
						c-0.1,0.1-0.3,0.1-0.4,0.2c-0.3,0.1-0.6,0.1-0.9,0.2c-0.7,0-1.3-0.1-1.8-0.4c-0.5-0.2-0.9-0.6-1.2-0.9
						c-0.3-0.4-0.5-0.9-0.5-1.4c-0.1-1.1,0.1-2,0.4-2.8c0.3-0.7,0.6-1.3,0.4-1.7c-0.1-0.4-0.8-0.7-2-0.3c-1.1,0.4-2.7,1.6-3.5,3.8
						c-0.4,1.1-0.6,2.5-0.3,4.1c0.3,1.6,1.1,3.2,2.4,4.5c1.3,1.3,3,2.2,4.9,2.7c1,0.2,2,0.3,3.1,0.3c0.5,0,1.1-0.1,1.7-0.2
						c0.5-0.1,1.1-0.3,1.6-0.4c4.2-1.5,7.4-5.3,8.5-10c0.5-2.3,0.6-4.6,0.2-6.8c-0.2-1.1-0.4-2.2-0.8-3.3c-0.4-1.1-0.8-2.2-1.4-3.2
						c-1.1-2.1-2.5-4-4.4-5.6c-1.8-1.6-4.1-3-6.7-3.8c-2.6-0.8-5.5-0.8-8.1-0.1c-2.6,0.7-4.8,2-6.7,3.5c-3.8,3.3-5.9,7.3-7.1,11
						c-1.1,3.8-1.4,7.4-1.1,10.7c0.2,3.3,1,6.4,1.9,9.1c1,2.8,2.1,5.4,3.5,7.8c1.3,2.5,2.7,4.9,4.3,7.3c1.5,2.4,3.1,4.8,4.9,7.4
						c3.6,5,7.8,10.5,13.5,16.3c-5.3-6.2-9-11.9-12.1-17.2c-1.5-2.7-2.8-5.2-4-7.8c-1.2-2.5-2.3-5-3.2-7.5c-0.9-2.5-1.7-5-2.2-7.6
						c-0.5-2.6-0.7-5.2-0.6-7.9c0.2-2.7,0.8-5.5,2-8.1c0.6-1.3,1.3-2.5,2.2-3.7C247.3,318.8,248.3,317.8,249.5,317.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M264.6,361c0.7-2.6,1-4.8,0.9-6.9c-0.1-2.1-0.6-4.1-2-5.2c-0.7-0.5-1.7-0.6-2.7-0.3c-1,0.3-2.1,1.1-3.1,2.3
						c-1,1.2-1.7,2.8-2.1,4.7c-0.4,1.9-0.3,3.9,0.3,5.9c0.6,2,1.4,3.6,2.2,4.9c0.8,1.3,1.7,2.3,2.6,3.3c1.8,1.9,3.7,3.6,6.7,5.5
						c-2.3-2.7-3-5-3.3-7.2C263.8,365.9,263.9,363.7,264.6,361z"
                    />
                  </g>
                  <g>
                    <path
                      d="M284.7,370c-3.2,6-5.2,12.1-7.6,17c-1.2,2.5-2.4,4.7-3.7,6.6c-0.7,0.9-1.4,1.8-2.1,2.6c-0.7,0.8-1.4,1.4-2.1,2
						c-1.4,1.1-2.5,1.5-3.5,1.7c-0.3,0.1-0.8,0-1.2-0.1c-0.5-0.2-0.9-0.4-1.2-0.7c-0.7-0.5-1.1-1.1-1.5-1.7
						c-0.3-0.6-0.5-1.1-0.9-1.3c-0.2-0.1-0.4-0.1-0.7,0c-0.3,0.1-0.6,0.4-0.9,0.8c-0.6,0.9-1,2.7-0.3,4.6c0.3,1,0.9,1.9,1.8,2.8
						c0.9,0.9,2.1,1.8,3.7,2.2c1.4,0.4,3,0.5,4.6,0.3c1.6-0.2,3.1-0.7,4.5-1.4c1.4-0.7,2.7-1.6,3.9-2.6c1.2-1,2.3-2.1,3.4-3.3
						c2.1-2.4,3.9-5.2,5.3-8.2c2.9-5.9,4.6-12.1,6.6-17.7c1.1-2.9,2.2-5.3,3.6-7.5c1.4-2.2,2.8-4.2,4.4-5.9c0.7-0.9,1.6-1.7,2.4-2.5
						c0.9-0.7,1.7-1.5,2.5-2.2c0.4-0.3,0.9-0.7,1.3-1c0.4-0.3,0.9-0.6,1.3-0.9c0.9-0.6,1.8-1.2,2.8-1.7c3.8-2.2,8-3.8,13-4.7
						c1.2-0.2,2.5-0.4,3.9-0.4c1.3-0.1,2.7-0.1,4.1,0.1c2.8,0.3,5.9,1.2,8.4,3.6c-2.3-2.6-5.4-3.8-8.3-4.4c-1.5-0.3-2.9-0.5-4.3-0.6
						c-1.4-0.1-2.7,0-4.1,0c-5.2,0.3-9.9,1.3-14.4,3c-1.1,0.4-2.2,0.9-3.3,1.4c-0.5,0.3-1.1,0.5-1.6,0.8c-0.5,0.3-1.1,0.6-1.6,0.9
						c-1.1,0.6-2.1,1.3-3.2,2c-1,0.8-2.1,1.5-3.2,2.3c-2.1,1.7-4.2,3.7-6.2,6C288.2,364.1,286.3,366.9,284.7,370z"
                    />
                  </g>
                  <g>
                    <path
                      d="M311.3,353.5c-2.3,1-4.3,2.4-5.9,4.2c-1.6,1.7-2.9,3.8-3.4,5.9c-0.6,2.1-0.3,4.2,0.4,5.5c0.7,1.3,1.7,1.7,2.5,1.5
						c0.9-0.2,1.6-0.8,2.3-1.4c0.7-0.6,1.5-1.2,2.3-1.9c0.9-0.7,1.8-1.6,3-2.7c1.2-1.1,2.4-2.4,3.6-3.7c2.6-2.8,4.9-4.5,7.5-5.8
						c1.3-0.6,2.7-1.3,4.3-1.7c1.6-0.4,3.5-0.7,5.7-0.7c-2.2-0.6-4.1-0.9-5.9-1.1c-1.8-0.2-3.4-0.3-5-0.3c-1.6,0-3.3,0.1-5.1,0.4
						C315.7,352,313.7,352.5,311.3,353.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M333.5,332.9c0.1-2-0.4-3.5-1.3-5c-0.9-1.4-2.2-2.8-3.9-3.1c-0.9-0.2-1.7,0-2.5,0.5c-0.8,0.5-1.5,1.4-1.9,2.6
						c-0.4,1.2-0.6,2.6-0.4,4.2c0.3,1.5,0.9,3.1,2,4.3c2.2,2.6,4.3,3.7,6.2,4.6c1.9,0.9,3.9,1.5,6.5,2.1c-2.2-1.6-3.2-3.2-3.8-4.8
						C333.7,336.7,333.4,335,333.5,332.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M523.9,354.8c0.8-4,0.7-8.3-0.4-12.2c-1-3.9-2.9-7.6-5.4-10.8c-2.5-3.2-5.7-5.8-9.2-7.6c-3.5-1.8-7.3-3-11-3.4
						c-3.8-0.4-7.6-0.2-11.2,0.8c-3.6,0.9-7.2,2.6-10.1,5.5c-1.4,1.4-2.6,3.2-3.4,5c-0.8,1.8-1.3,3.7-1.4,5.5
						c-0.3,3.7,0.5,7.3,2.3,10.3c1.8,3,4.4,5.2,7.1,6.5c2.7,1.3,5.5,1.8,8.1,1.8c2.6-0.1,5-0.8,7-1.8c2-1,3.5-2.4,4.6-3.7
						c2.3-2.7,3.1-5.3,3.4-7.1c0.2-1.8-0.1-2.8-0.5-2.8c-0.5-0.1-1,0.7-1.9,1.9c-0.9,1.2-2.2,3-4.3,4.5c-1,0.7-2.3,1.4-3.7,1.8
						c-1.4,0.4-2.9,0.6-4.5,0.4c-1.6-0.2-3.2-0.7-4.7-1.7c-1.5-0.9-2.7-2.2-3.4-3.7c-0.7-1.5-1-3.5-0.7-5.3c0.1-0.9,0.4-1.8,0.8-2.6
						c0.4-0.8,0.9-1.4,1.6-2.1c2.8-2.6,8.4-3.9,13.5-3.2c5.3,0.6,10.4,3.1,13.9,7.3c3.5,4.2,5.4,9.9,4.7,15.4
						c-0.8,5.7-4.1,10.7-8.1,14.1c-4.1,3.4-9,5.5-13.8,6.4c-4.9,1-9.8,1-14.5,0.4c-4.7-0.6-9.3-1.9-13.8-3.5
						c-4.5-1.6-8.9-3.6-13.5-5.8c-4.5-2.2-9.2-4.6-14.3-6.8c-5.1-2.2-10.8-4.1-17.1-4.8c-3.1-0.3-6.4-0.3-9.8,0.1
						c-1.7,0.3-3.4,0.7-5,1.2c-1.7,0.6-3.3,1.3-4.9,2.2c1.6-0.8,3.3-1.5,5-2c1.7-0.5,3.4-0.8,5-1c3.3-0.3,6.6-0.2,9.6,0.3
						c6.2,0.9,11.5,3.2,16.4,5.6c4.9,2.4,9.4,5.1,13.8,7.7c4.4,2.5,8.8,4.9,13.3,7c4.6,2,9.3,3.8,14.4,4.8
						c5.1,1.1,10.6,1.5,16.4,0.8c5.7-0.7,11.9-2.6,17.5-6.7c2.7-2,5.3-4.5,7.5-7.6C521.4,362.6,523.1,358.9,523.9,354.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M447.8,308.1c-3.2-3.2-7.6-5.5-12.3-6.1c-4.7-0.7-9.8,0.3-13.9,3.4c-2,1.5-3.5,3.4-4.6,5.6c-1.1,2.1-1.7,4.5-1.7,7
						c0,0.6,0.1,1.2,0.1,1.8c0.1,0.6,0.2,1.2,0.4,1.8c0.3,1.2,0.9,2.2,1.4,3.2c1.2,1.9,2.7,3.4,4.5,4.5c1.8,1.1,3.7,1.7,5.7,1.8
						c1.9,0.1,3.9-0.4,5.4-1.3c1.5-0.9,2.6-2,3.3-3.1c0.8-1.1,1.2-2.3,1.5-3.3c0.5-2.1,0-3.9-0.6-4.9c-0.3-0.5-0.6-0.8-0.9-1
						c-0.3-0.2-0.5-0.2-0.7-0.1c-0.2,0.1-0.4,0.3-0.5,0.6c-0.1,0.3-0.2,0.6-0.4,1c-0.3,0.8-0.6,1.7-1.4,2.6c-0.7,0.9-1.9,1.8-3.1,2
						c-1.1,0.2-2.3-0.1-3.5-1.2c-0.6-0.5-1.1-1.2-1.4-2c-0.1-0.4-0.3-0.7-0.3-1.1c0-0.2,0-0.3,0-0.5c0-0.2,0-0.4,0-0.6
						c0.1-1.6,1.2-3.6,2.9-4.8c1.6-1.2,4-1.9,6.7-1.6c2.7,0.2,5.3,1.3,7.7,3.1c2.4,1.8,4.2,4.3,5.3,6.8c1.1,2.5,1.5,5.1,1.3,7.6
						c-0.3,5-2.7,9.6-6.2,12.9c-3.5,3.3-8.6,5.3-14.4,5.7c-5.9,0.5-12.6-0.9-20.1-3.5c7.3,3.1,13.9,5,20.1,5.2
						c6.2,0.2,12-1.1,16.8-4.5c2.4-1.7,4.5-3.8,6.2-6.3c1.7-2.5,3.1-5.4,3.7-8.8C456.1,323.3,454.5,314.8,447.8,308.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M458.9,328.3c-2.4,2.5-3.5,5.9-3.3,8.5c0.2,2.7,1.6,4.5,3.3,4.7c1.8,0.2,3.4-0.9,4.8-2.5c1.3-1.6,2.3-3.7,2.8-6.3
						c0.5-2.7,1-4.7,1.8-6.7c0.8-1.9,1.8-3.8,4.1-6.1c-2.9,1.3-4.9,2.4-6.9,3.5C463.4,324.6,461.4,325.8,458.9,328.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M463.1,362.7c0.5,3.2,2.7,5.9,5,6.9c2.3,1.1,4.4,0.7,5.5-0.7c1-1.4,1-3.4,0.6-5.1c-0.5-1.8-1.4-3.3-3.1-4.8
						c-1.7-1.6-3-3-3.9-4.5c-1-1.6-1.8-3.4-1.9-6.2c-0.9,2.7-1.4,4.7-1.9,6.8C462.9,357.2,462.6,359.4,463.1,362.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M490.6,369c1.7,0.6,3.5,0.7,5.2,0.5c1.7-0.3,3.1-0.9,4.2-1.7c2.3-1.7,2.9-3.9,2-5.4c-0.9-1.5-2.7-2.2-4.6-2.6
						c-1.9-0.3-3.8-0.2-6.1,0.5c-2.3,0.7-4.3,0.9-6.3,0.8c-2-0.2-4-0.7-6.5-2.5c1.7,2.6,3.2,4.3,4.9,5.9
						C485.2,366,487.1,367.7,490.6,369z"
                    />
                  </g>
                  <g>
                    <path
                      d="M448.5,349.2c-1.6-0.6-2.7-0.5-4,0.1c-1.3,0.6-2.7,1.6-3.5,3.2c-0.4,0.8-0.5,1.7-0.2,2.5c0.2,0.9,0.8,1.7,1.7,2.4
						c0.9,0.7,2.1,1.2,3.5,1.3c1.3,0.1,2.8-0.1,4.1-0.8c2.7-1.5,3.9-3.1,5-4.5c1.1-1.5,1.9-3.1,2.9-5.2c-1.8,1.5-3.4,1.9-4.9,1.9
						C451.6,350.1,450.2,349.9,448.5,349.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M441,389.9c-1.2,1.3-3,2.2-4.8,2.4c-1.7,0.3-3.6,0-5.4-0.8c-1.8-0.7-3.5-2-4.7-3.3c-1.2-1.3-1.9-2.6-2-3.5
						c-0.2-1,0.1-2.1,0.4-2.7c0.3-0.6,0.9-1,1.7-1.1c0.8-0.1,1.5,0,2.1,0.2c0.6,0.2,1.1,0.4,1.5,0.3c0.4-0.1,0.8-0.6,0.6-1.7
						c-0.2-1-1-2.4-2.7-3.4c-0.8-0.5-1.9-0.8-3.2-0.9c-1.3-0.1-2.7,0.3-4,1.1c-2.7,1.6-4.5,4.5-4.8,8c-0.3,3.6,1.1,7,3.1,9.7
						c2,2.7,4.7,4.9,8,6.4c3.3,1.5,7.3,2.2,11.4,1.2c3.9-0.9,7.6-3.3,9.9-6.8c0.6-0.9,1.1-1.9,1.4-2.9c0.4-1,0.6-2,0.7-2.9
						c0.3-1.9,0.2-3.7-0.1-5.3c-0.6-3.2-1.9-5.9-3.3-8.1c-1.5-2.2-3.2-4.1-5-5.6c-1.8-1.5-3.7-2.8-5.7-3.8c-4-2.1-8.4-3.3-13.2-3.8
						c-4.9-0.5-10.3-0.3-16.5,0.5c12.6-0.7,21.4,1.7,27.8,6.5c3.2,2.4,5.8,5.4,7.4,9.2c0.8,1.9,1.3,3.9,1.3,5.9
						C442.8,386.8,442.3,388.7,441,389.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M457.3,391.4c0,2.2,1.1,3.9,2.5,4.7c1.5,0.9,3.3,0.9,4.8,0c1.5-0.9,2.4-2.4,2.6-4c0.2-1.5-0.4-3.1-1.9-4.3
						c-1.5-1.2-2.7-2.1-3.9-3c-1.1-0.9-2.1-2-3.1-3.7c0,2-0.2,3.5-0.4,5C457.7,387.6,457.4,389.2,457.3,391.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M431.7,410.6c-2.8,1.3-4.6,3.9-5.2,6.2c-0.6,2.3,0.1,4.3,1.6,5.2c1.5,0.9,3.5,0.6,5.2-0.3c1.7-0.9,3.3-2.4,4.4-4.7
						c1.1-2.3,2-4,3.1-5.6c1.1-1.6,2.4-3.1,4.8-4.7c-2.7,0.6-4.7,1-6.8,1.5C436.6,408.7,434.5,409.2,431.7,410.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M389.6,226.7c1.7,0.3,3.4,0.1,5-0.1c1.5-0.3,2.9-0.8,4-1.3c2.2-1.1,3.4-2.3,3.2-3.1c-0.3-0.9-2-1.1-4.3-0.9
						c-2.3,0.2-5.1,0.7-7.8,1.1c-2.8,0.4-4.9,0.2-6.8-0.5c-1-0.3-1.9-0.8-2.9-1.6c-0.9-0.7-1.9-1.8-2.6-3.3c0.4,1.6,1.1,2.9,1.9,3.9
						c0.8,1,1.7,1.9,2.6,2.6c0.9,0.7,2,1.4,3.2,1.9C386.4,226,387.9,226.5,389.6,226.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M392.5,238.2c1.9,2.5,3.7,4.8,5.3,6.6c1.6,1.7,2.9,2.9,3.7,2.5c0.8-0.3,0.8-2.2-0.1-4.7c-1-2.4-3-5.4-5.8-7.5
						c-2.9-2.2-5-3.2-7.2-4.2c-2.2-1-4.4-1.9-7.8-2.8c3.1,1.5,5,2.9,6.7,4.4C389.1,234,390.6,235.6,392.5,238.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M351.7,217.7c-0.7,2.4-1.3,4.7-1.7,6.6c-0.4,1.9-0.5,3.4,0.2,3.9c0.7,0.5,2.2-0.2,3.6-2.1c1.4-1.8,2.4-4.8,2.3-7.9
						c-0.2-3.1-0.8-5.1-1.4-7.1c-0.7-1.9-1.5-3.8-3.1-6.3c1,2.7,1.2,4.7,1.2,6.6C352.7,213.3,352.5,215.2,351.7,217.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M446.3,284.3c3.7-0.6,7.2-2.4,9.4-4.3c2.2-1.9,3-3.7,2.5-4.4c-0.6-0.7-2.4-0.1-4.7,0.8c-2.3,1-5.3,2.3-8.3,3.6
						c-3.2,1.4-5.5,2-7.9,2.5c-2.4,0.4-5,0.7-8.7,0.1c3.6,1.1,6.2,1.5,8.8,1.8C439.8,284.8,442.4,284.9,446.3,284.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M455.1,296.1c2.5,0.2,5-0.7,6.5-1.9c1.5-1.2,2-2.6,1.4-3.3c-0.6-0.7-1.8-0.7-3.3-0.4c-1.5,0.2-3.2,0.7-5.1,1.3
						c-1.9,0.6-3.4,0.9-4.9,0.9c-1.5,0-3.1-0.1-5.3-1.1c1.9,1.4,3.3,2.3,4.9,3C450.8,295.2,452.5,295.8,455.1,296.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M512.7,278.9c-0.8,1.7-2.2,3.2-3.7,4.4c-1.5,1.2-3.3,2.3-4.8,3.4c-1.5,1.1-2.8,2.1-3.5,3c-0.8,0.9-1,1.6-0.8,2
						c0.3,0.4,1,0.3,2,0.1c1.1-0.2,2.5-0.7,4.2-1.4c1.7-0.7,3.8-1.6,5.9-3.2c2-1.6,4-3.9,4.8-6.9c0.4-1.5,0.4-3,0.2-4.4
						c-0.3-1.3-0.8-2.5-1.4-3.4c-1.2-1.9-2.8-3.1-4.4-4c-3.3-1.8-7-2.2-12.2-2.6c2.6,0.5,4.7,0.9,6.5,1.6c1.8,0.7,3.3,1.5,4.5,2.6
						c1.2,1.1,2.2,2.4,2.8,3.9C513.5,275.5,513.5,277.2,512.7,278.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M421,118.9c2.1-3.8,2.7-8.3,2.3-11.5c-0.4-3.3-1.6-5.2-2.4-5c-0.9,0.2-1.3,2.2-1.8,4.9c-0.5,2.8-1.2,6.3-2.2,9.9
						c-1.1,3.7-2.3,6.2-3.8,8.5c-1.5,2.3-3.4,4.6-6.9,6.9c3.8-1.9,6.2-3.7,8.4-5.7C416.7,125,418.8,122.8,421,118.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M423,162.8c5.4,0.3,10.9,0.4,15,0.3c4.1-0.1,6.9-0.6,7-1.5c0.1-0.9-2.6-2-6.7-2.7c-4.2-0.7-9.8-1.1-15.4-0.5
						c-5.7,0.6-9.6,1.3-13.5,2.1c-3.9,0.8-7.8,1.6-13.5,3c5.8-0.8,9.8-1,13.7-1.1C413.6,162.4,417.4,162.5,423,162.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M428.4,172.9c6.4,1.8,13.2,1.4,13.5-0.3c0.2-1.7-6.2-4-12.9-4c-6.9,0-9.5,0.3-16.5-0.1
						C419.4,169.9,421.8,171,428.4,172.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M465.2,185.4c3.3-1.4,6.4-3,8.7-4.4c2.3-1.4,3.8-2.7,3.5-3.5c-0.3-0.8-2.3-1-5.1-0.3c-2.8,0.7-6.3,2.2-9.3,4.5
						c-3.1,2.4-5,4.3-6.8,6.1c-1.8,1.9-3.7,3.9-6.2,6.8c2.9-2.6,5.1-4,7.4-5.3C459.5,188,461.8,186.9,465.2,185.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M479.4,189.5c3.5-0.2,7-0.5,9.6-1c2.6-0.4,4.5-1,4.6-1.9c0.1-0.9-1.7-1.8-4.6-2.3c-2.8-0.5-6.7-0.3-10.4,0.8
						c-3.7,1.2-6.2,2.3-8.5,3.5c-2.4,1.2-4.7,2.5-8,4.6c3.5-1.6,6.1-2.3,8.6-2.8C473.3,190.1,475.8,189.7,479.4,189.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M496,177.4c5.3-1.4,9.4-5.2,8.5-6.8c-0.9-1.5-6-0.2-10.3,2.8c-2.2,1.5-3.8,2.6-5.3,3.7c-1.6,1-3.2,2-5.8,3.2
						c2.7-0.7,4.6-1.1,6.5-1.5C491.4,178.5,493.3,178.1,496,177.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M508.6,181.3c2.3,0.5,4.6,0.6,6.3,0.4c1.8-0.2,3.1-0.7,3.3-1.5c0.2-0.9-0.9-1.9-2.7-2.6c-1.8-0.7-4.4-1.1-6.9-0.6
						c-2.6,0.4-4.3,1-6,1.5c-1.8,0.6-3.6,1.2-5.8,2c2.6-0.3,4.3-0.3,6-0.1C504.5,180.5,506.2,180.8,508.6,181.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M515.5,191.4c5.5,1.8,11.5,1.5,11.8-0.3c0.3-1.7-5.2-4-11-4.1c-6-0.1-8.3,0.3-14.4-0.2
						C507.8,188.4,509.8,189.5,515.5,191.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M537.8,184.7c2.8,0.1,5.6-0.6,7.4-1.6c1.9-1,2.9-2.2,2.6-3c-0.3-0.8-1.8-1.1-3.7-1c-2,0.1-4.4,0.5-6.9,1.3
						c-2.5,0.8-4.3,1.3-6.2,1.7c-1.9,0.4-3.8,0.6-6.6,0.6c2.8,0.6,4.7,1,6.6,1.3C533,184.3,535,184.6,537.8,184.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M537.5,176.3c2.8-0.1,5.5-1.1,7.4-2.2c1.8-1.1,2.8-2.4,2.4-3.2c-0.4-0.8-1.9-1-3.8-0.7c-2,0.2-4.4,0.9-6.9,1.9
						c-2.5,1-4.3,1.6-6.1,2.2c-1.8,0.5-3.7,1-6.6,1.2c2.9,0.3,4.8,0.5,6.8,0.7C532.6,176.2,534.6,176.4,537.5,176.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M535.8,166.5c2.7-0.6,5.2-1.8,6.8-3c1.7-1.3,2.5-2.6,2.1-3.4c-0.8-1.6-6.1-0.4-10.6,2.3c-2.3,1.4-4,2.4-5.6,3.4
						c-1.7,0.9-3.4,1.8-6.1,2.9c2.8-0.5,4.7-0.8,6.7-1C531.1,167.3,533,167.1,535.8,166.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M557.3,160.8c1.9-0.2,3.7-0.8,5-1.5c1.3-0.7,2.2-1.5,2.2-2.4c-0.1-0.9-1.2-1.6-2.9-1.7c-1.7-0.2-3.9,0.3-5.8,1.5
						c-1.9,1.2-3.1,2.3-4.2,3.4c-1.1,1.1-2.2,2.1-3.7,3.7c1.8-1.2,3.2-1.7,4.6-2.1C553.9,161.3,555.3,161,557.3,160.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M366.2,478.6c3.4-1,6.8-2.1,9.3-3.2c2.5-1.1,4.2-2.1,4-3c-0.1-0.9-2.1-1.3-5-1.1c-2.9,0.3-6.7,1.3-10,3.2
						c-3.4,2-5.6,3.6-7.7,5.3c-2.1,1.7-4.2,3.4-7,6.1c3.2-2.3,5.6-3.5,8-4.5C360.2,480.5,362.6,479.6,366.2,478.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M377.5,483.6c2.3,0.1,4.5-0.2,6.2-0.7c1.7-0.5,2.9-1.2,2.9-2.1c0-0.9-1.2-1.7-3.1-2.1c-1.9-0.4-4.4-0.3-6.8,0.6
						c-2.4,0.9-4,1.7-5.6,2.5c-1.7,0.9-3.3,1.8-5.3,2.9c2.4-0.7,4.2-1,5.9-1.1C373.5,483.5,375.1,483.5,377.5,483.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M400.9,489.4c2.5,0.8,5,1.3,7,1.3c2,0.1,3.5-0.3,3.7-1.1c0.3-0.8-0.8-2-2.7-2.9c-1.9-0.9-4.6-1.6-7.4-1.6
						c-2.9,0-4.8,0.3-6.8,0.5c-2.1,0.2-4.1,0.5-6.7,0.8c2.8,0.2,4.7,0.6,6.6,1.1C396.6,487.9,398.4,488.5,400.9,489.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M404,480.2c5.8,1.9,12.1,1.7,12.4,0c0.3-1.7-5.5-4.1-11.6-4.3c-6.3-0.2-8.7,0.1-15.2-0.5
						C395.8,477.1,398,478.2,404,480.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M405.2,471.3c1.9,0,3.8-0.8,4.9-1.8c1.1-1,1.6-2.1,1.2-2.9c-0.4-0.8-1.5-1.1-2.8-1c-1.3,0.1-2.9,0.5-4.3,1.4
						c-1.5,0.9-2.6,1.5-3.8,2c-1.2,0.5-2.4,0.9-4.3,1.2c1.7,0.3,3,0.5,4.5,0.7C401.8,471.2,403.2,471.4,405.2,471.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M326.4,598.7c3.6-0.2,7.2-0.8,10.9-1.8c3.6-1,7.3-2.7,10.5-5.2c1.6-1.3,3.1-2.8,4.3-4.4c1.2-1.7,2.2-3.5,3-5.4
						c1.5-3.8,2.1-7.8,1.7-11.8c-0.2-2-0.7-3.9-1.4-5.8c-0.7-1.9-1.7-3.6-2.8-5.1c-2.2-3.1-5.1-5.5-8.2-7.3c-1.6-0.8-3.2-1.5-4.9-2
						c-1.7-0.5-3.4-0.7-5.1-0.8c-3.4-0.1-6.7,0.5-9.7,1.8c-2.9,1.4-5.5,3.4-7.2,6.1c-1.7,2.6-2.4,5.5-2.4,8.1c0,2.7,0.8,5.3,2.2,7.2
						c0.7,1,1.5,1.8,2.4,2.5c0.9,0.6,1.8,1.1,2.7,1.4c3.6,1.3,6.6,0.5,8.1-0.6c1.6-1.1,1.9-2.1,1.6-2.4c-0.3-0.4-1.2-0.1-2.5,0
						c-0.7,0-1.5,0-2.4-0.2c-0.9-0.2-1.9-0.6-2.8-1.2c-0.9-0.6-1.7-1.5-2.1-2.5c-0.5-1.1-0.6-2.4-0.4-3.8c0.2-1.4,0.8-2.9,1.8-4.1
						c0.9-1.1,2.4-2.1,4.1-2.7c1.7-0.6,3.8-0.8,5.8-0.6c1,0.1,2,0.3,3,0.7c1,0.4,2,0.8,2.9,1.4c1.9,1.2,3.6,2.7,4.9,4.6
						c1.3,1.9,2,4,2.3,6.3c0.2,2.3-0.1,4.8-1,7.1c-0.4,1.1-1,2.2-1.7,3.2c-0.7,1-1.5,1.8-2.5,2.7c-3.9,3.3-9.9,5-16.1,5.8
						c-6.3,0.8-12.3,0.4-17.8-0.8c-5.5-1.2-10.3-3.2-14.8-5.6c-9-4.9-16.6-10.8-25.2-15.5c-8.6-4.7-18.1-7.9-29-9.2
						c-5.4-0.6-11.2-0.8-17.4-0.2c-6.1,0.6-12.7,2-19.4,4.5c6.8-2.2,13.3-3.3,19.4-3.6c6.1-0.3,11.8,0.2,17.1,1.1
						c10.5,1.9,19.4,5.7,27.3,10.8c3.9,2.6,7.7,5.5,11.5,8.5c3.9,3,7.9,6.1,12.5,9.1c4.6,3,9.9,5.7,16,7.5
						C312.1,598.2,319,599.2,326.4,598.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M279.9,561.6c4.2-1.3,8.3-3.6,11.6-6.8c1.7-1.6,3.1-3.5,4.3-5.6c1.2-2.1,2-4.4,2.3-7c0.7-5.2-1.5-10.8-6.2-13.8
						c-2.4-1.4-4.9-1.9-7.1-1.8c-2.2,0.1-4.1,0.5-6,1.3c-1.8,0.8-3.5,2-4.9,3.6c-1.3,1.6-2.1,3.7-2.1,5.7c0,3.9,2.4,6.6,4.7,7.6
						c2.3,1,4.2,0.5,5.3-0.1c1.1-0.6,1.3-1.3,1.2-1.6c-0.2-0.4-0.8-0.6-1.5-0.9c-0.7-0.3-1.7-0.8-2.4-1.6c-0.3-0.4-0.5-0.8-0.6-1.4
						c-0.1-0.5-0.1-1.1,0.1-1.5c0.2-0.4,0.5-0.7,1-1.1c0.5-0.3,1.2-0.7,2.1-0.9c0.9-0.2,2-0.3,2.9-0.2c1,0.1,1.7,0.4,2.2,0.8
						c0.5,0.4,0.9,1,1.2,1.8c0.2,0.4,0.3,0.9,0.3,1.3c0,0.4,0,0.9,0,1.5c-0.3,2.1-1.7,4.8-3.7,7c-2,2.2-4.7,4.1-7.8,5.4
						c-3.1,1.4-6.4,2.2-9.5,2.6c-3.2,0.4-6.2,0.5-9.2,0.4c-5.9-0.1-11.4-0.8-17-1.1c-5.6-0.3-11.4-0.3-17.6,0.8
						c-6.2,1.1-13,3.1-20.3,7c7.5-3.4,14.4-4.8,20.5-5.3c6.2-0.4,11.6,0.2,17,1.3c5.4,1,10.7,2.4,16.9,3.4c3.1,0.5,6.5,0.9,10.2,0.9
						C271.4,563.3,275.5,563,279.9,561.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M303.9,501.7c1.1-5.3,2.3-10.4,3.1-14.4c0.8-4.1,1.2-7.3-0.3-8.3c-1.4-1-4.6,0.8-7.4,4.9c-1.4,2.1-2.6,4.7-3.4,7.7
						c-0.8,3-1.1,6.3-0.8,9.6c0.3,3.4,0.9,6.2,1.7,8.6c0.7,2.4,1.6,4.5,2.6,6.5c1,2,2,4,3.3,6.1c1.3,2.1,2.8,4.3,4.9,6.8
						c-1.6-2.8-2.6-5.2-3.3-7.5c-0.7-2.3-1.1-4.3-1.3-6.4c-0.2-2.1-0.2-4.1-0.1-6.3C303,506.9,303.3,504.5,303.9,501.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M320.9,499.7c0.3-3.4-0.1-6.9-1.1-10c-0.9-3.1-2.4-5.8-4.1-7.9c-1.6-2.1-3.4-3.4-4.9-4.1c-1.5-0.7-2.6-0.7-3.3-0.1
						c-1.3,1.3-0.3,4.3,0.8,8.2c0.6,2,1.2,4.2,1.9,6.6c0.6,2.4,1.3,5,1.8,7.7c0.6,2.8,0.8,5.2,0.9,7.5c0.1,2.3,0,4.4-0.3,6.5
						c-0.5,4.2-1.6,8.5-4.5,14.4c3.8-5.3,6.1-9.2,8.1-13.3c1-2.1,1.9-4.2,2.7-6.7C319.9,506,320.6,503.2,320.9,499.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M312.1,497.4c0.7-3.6,0.6-7.3,0-10.1c-0.6-2.7-1.6-4.4-2.5-4.3c-0.9,0.1-1.4,1.9-1.7,4.6c-0.3,2.6-0.4,6-0.2,9.5
						c0.2,3.6,0.2,6.1,0.2,8.6c-0.1,2.5-0.3,5.1-1.2,8.8c1.3-3.6,2.2-6,3-8.5C310.5,503.6,311.3,501.1,312.1,497.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M310,493.7c0.9-3.1,1-6.3,0.6-8.7c-0.4-2.4-1.2-4-2.1-3.9c-0.9,0-1.6,1.6-2.1,3.8c-0.5,2.3-0.8,5.2-0.8,8.3
						c0,3.1,0,5.3-0.2,7.5c-0.2,2.2-0.4,4.4-1.2,7.6c1.2-2.9,2.1-5,3.1-7.2C308.2,499,309,496.9,310,493.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M302.6,488.7c1.5-2.6,2.9-5.1,3.9-7.2c1-2,1.6-3.6,1-4.3c-0.6-0.6-2.4,0-4.3,1.7c-1.9,1.8-3.9,4.8-4.7,8.1
						c-0.8,3.5-0.9,5.9-0.8,8.3c0.1,2.4,0.3,4.7,1,8.1c-0.2-3.4,0.2-5.7,0.8-7.9C300.3,493.4,301.1,491.4,302.6,488.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M305.8,559.5c1.5-1.7,2.7-3.7,3.5-5.7c0.8-2,1.3-4,1.4-5.7c0.3-3.4-0.5-5.7-1.4-5.7c-0.9,0-1.6,2-2.7,4.8
						c-1.1,2.8-2.5,6.3-4.4,9.7c-2,3.5-3.9,5.6-6.1,7.6c-2.2,1.9-4.8,3.6-9.1,4.9c4.4-0.8,7.4-1.9,10.1-3.3c1.4-0.7,2.7-1.5,4.2-2.5
						C302.7,562.6,304.2,561.3,305.8,559.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M341,541.1c4.9-0.6,9.7-1.1,13.4-1.5c3.7-0.4,6.3-0.9,6.4-1.8c0.1-0.8-2.4-2-6.4-2.4c-3.9-0.5-9.3-0.1-14.4,1.4
						c-5.2,1.6-8.5,3.3-11.7,5c-3.2,1.8-6.4,3.7-10.8,6.9c4.6-2.8,8.1-4.1,11.5-5.2C332.6,542.5,336,541.7,341,541.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M361.1,546c3.3,0.2,6.6,0,9.1-0.3c2.5-0.4,4.3-1,4.3-1.9c0.1-0.9-1.6-1.8-4.2-2.3c-2.6-0.5-6.1-0.6-9.6,0.1
						c-3.5,0.7-5.9,1.5-8.2,2.3c-2.5,0.8-4.8,1.6-8.1,2.8c3.6-0.7,6-0.8,8.5-0.9C355.2,545.7,357.6,545.8,361.1,546z"
                    />
                  </g>
                  <g>
                    <path
                      d="M368.5,552.6c2.6,0.8,5.2,1.2,7.2,1.2c2,0,3.6-0.3,3.8-1.2c0.2-0.8-0.9-2-2.8-2.9c-2-0.9-4.8-1.6-7.6-1.5
						c-2.9,0.1-4.9,0.4-6.9,0.7c-2.1,0.3-4.2,0.7-6.9,1.1c2.9,0.1,4.9,0.4,6.8,0.8C364,551.3,365.9,551.8,368.5,552.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M371.3,563.5c1.5,1.5,2.9,2.8,4,3.8c1.2,1,2.2,1.7,3.1,1.5c0.8-0.3,1.2-1.8,0.4-3.8c-0.7-2-2.7-4.1-5.1-5.1
						c-2.5-1.1-4.2-1.3-6-1.6c-1.7-0.3-3.4-0.5-5.9-1c2.3,1,3.8,1.8,5.2,2.7C368.4,561,369.8,562,371.3,563.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M370.1,580.9c0.2,2.4,0.6,4.7,1.1,6.5c0.5,1.8,1.1,3.1,2,3.2c0.9,0.1,1.8-1.2,2.2-3.3c0.4-2.1,0.2-5-1-7.6
						c-1.2-2.6-2.4-4.2-3.6-5.8c-1.2-1.5-2.5-3-4.4-5c1.5,2.3,2.2,4.1,2.7,5.8C369.5,576.6,369.8,578.4,370.1,580.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M364.6,598.8c-0.5,1.7-0.6,3.5-0.3,4.8c0.2,1.4,0.8,2.4,1.7,2.6c0.9,0.2,1.8-0.6,2.5-2c0.6-1.4,1-3.4,0.6-5.3
						c-0.4-2-0.9-3.3-1.3-4.7c-0.5-1.3-0.9-2.6-1.5-4.5c0.1,2-0.1,3.3-0.4,4.7C365.5,595.7,365.1,597,364.6,598.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M353.3,601c-3.3,3.3-5.1,7.7-3.7,8.8c1.4,1.1,5.4-1.9,7.3-6.4c1.9-4.6,2.2-6.5,4.2-11.1
						C358.2,596.4,356.7,597.5,353.3,601z"
                    />
                  </g>
                  <g>
                    <path
                      d="M340.2,602.7c-2.6,1.1-5,2.5-6.7,3.8c-1.7,1.3-2.7,2.6-2.3,3.4c0.4,0.8,2,0.9,4.2,0.3c2.2-0.6,4.8-1.9,7.1-3.8
						c2.3-2,3.8-3.5,5.2-5c1.5-1.5,2.9-3,5.1-5.1c-2.5,1.7-4.4,2.7-6.2,3.6C344.8,600.8,342.9,601.6,340.2,602.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M316.4,606.9c-2.8,0-5.6,0.4-7.6,1c-2.1,0.6-3.5,1.4-3.5,2.2c0,0.9,1.5,1.6,3.7,1.9c2.2,0.3,5.2,0.1,8.1-0.9
						c2.9-1,4.8-1.9,6.7-2.8c2-1,4-1.9,6.6-3.1c-3,0.8-5,1.1-7.1,1.3C321.3,606.8,319.3,606.9,316.4,606.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M233,569.1c5.4,0.9,10.6,2.2,14.6,3.2c4,1,6.8,1.7,7.2,0.8c0.4-0.7-1.8-2.8-5.8-4.8c-4-1.9-9.8-3.5-15.7-3.6
						c-6,0-10.1,0.7-14.1,1.6c-4,0.9-8,2.1-13.5,4.5c5.7-1.9,9.8-2.4,13.7-2.6C223.5,568,227.4,568.2,233,569.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M251.9,584.4c1.8,1.9,3.6,3.7,5.1,5.1c1.5,1.3,2.8,2.2,3.6,1.8c0.8-0.4,0.8-2.1-0.2-4.2c-1-2.1-3.1-4.6-5.8-6.1
						c-2.8-1.5-4.8-2.2-6.9-2.7c-2.1-0.5-4.1-0.9-7.1-1.1c2.9,0.8,4.7,1.8,6.4,2.9C248.6,581.1,250.1,582.4,251.9,584.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M68,396c1.6-0.2,3.2-0.3,4.9-0.5c1.6-0.1,3.3-0.2,4.8-0.2c3.1,0,6.1,0.2,8.8,0.7c2.7,0.5,5.1,1.4,6.9,2.6
						c0.9,0.6,1.7,1.2,2.3,1.9c0.3,0.3,0.6,0.7,0.9,1.1c0.2,0.4,0.4,0.7,0.6,1.1c0.7,1.6,1.1,3.6,0.9,5.5c-0.1,1-0.3,1.9-0.6,2.8
						c-0.3,0.9-0.8,1.8-1.3,2.6c-1.1,1.7-2.5,3.2-4.2,4.4c-1.7,1.2-3.4,2.1-5.2,2.7c-1.8,0.6-3.7,0.8-5.3,0.7
						c-1.7-0.1-3.2-0.4-4.5-1c-2.5-1-4.3-3.3-5.3-5.4c-1-2.1-1.2-4.1-1.3-5.6c0-0.7-0.1-1.4-0.1-1.9c-0.1-0.5-0.2-0.9-0.5-1.1
						c-0.3-0.2-0.7-0.2-1.2,0c-0.5,0.2-1.1,0.7-1.8,1.5c-0.6,0.8-1.3,1.9-1.7,3.4c-0.4,1.4-0.7,3.2-0.5,5.1c0.1,1.9,0.7,4.1,1.7,6.3
						c0.3,0.5,0.6,1.1,0.9,1.6c0.4,0.5,0.7,1.1,1.2,1.6c0.5,0.5,0.9,1.1,1.4,1.5c0.3,0.2,0.5,0.5,0.8,0.7c0.3,0.2,0.6,0.4,0.8,0.7
						c2.3,1.8,5.2,3,8.2,3.7c3.1,0.7,6.4,0.7,9.7,0.2c3.3-0.6,6.8-1.8,9.9-3.6c3.1-1.9,6.1-4.4,8.5-7.6c1.2-1.6,2.3-3.4,3.1-5.4
						c0.8-2,1.4-4.1,1.7-6.3c0.6-4.4,0.1-9-1.8-13.5c-0.5-1.1-1.1-2.2-1.7-3.2c-0.7-1-1.4-2-2.2-2.9c-0.8-0.9-1.7-1.7-2.6-2.5
						c-0.5-0.4-0.9-0.7-1.4-1.1c-0.5-0.4-1-0.7-1.5-1c-4-2.5-8.2-3.7-12.3-4.3c-4.1-0.6-8.1-0.7-12-0.3c-1.9,0.2-3.8,0.4-5.6,0.7
						c-1.9,0.3-3.7,0.6-5.5,1c-7.6,1.7-14.2,4-20.2,6.7c-6,2.7-11.3,5.7-16.1,8.9c-4.8,3.2-9.1,6.5-13,10.1
						c-3.9,3.5-7.6,7.2-10.9,11c-6.7,7.7-12.6,16.1-18,26.2c-5.4,10-10.4,21.7-14.4,36.1c4.8-14.2,10.6-25.4,16.7-34.7
						c6.1-9.4,12.7-16.9,19.9-23.6c3.6-3.3,7.4-6.3,11.4-9.2c4-2.9,8.4-5.5,13.1-7.9c4.7-2.4,9.8-4.6,15.4-6.4
						C55.2,398.3,61.3,396.8,68,396z"
                    />
                  </g>
                  <g>
                    <path
                      d="M53,455.6c0.5,1.9,0.5,3.8,0.3,5.6c-0.3,1.9-0.8,3.7-1.7,5.4c-0.9,1.7-2,3.1-3.3,4.3c-0.6,0.6-1.3,1.1-2,1.6
						c-0.7,0.5-1.5,0.9-2.3,1.2c-3.2,1.4-6.9,1.6-10,0.9c-1.6-0.3-2.9-0.9-3.9-1.6c-1-0.7-1.6-1.3-2.1-2.1c-0.2-0.4-0.4-0.8-0.5-1.1
						c-0.1-0.3-0.2-0.6-0.1-0.9c0-0.6,0.3-1.5,1-2.3c0.7-0.9,1.6-1.7,2.6-2.4c0.5-0.3,1-0.7,1.5-1c0.5-0.3,0.9-0.5,1.3-0.8
						c0.5-0.3,1-0.6,1.4-0.9c0.2-0.2,0.7-0.2,1.1-0.2c0.4,0.1,0.7,0.2,1,0.4c0.3,0.2,0.5,0.5,0.8,0.6c0.3,0.1,0.7,0.1,1.1-0.4
						c0.4-0.4,0.7-1.4,0.4-2.5c-0.2-1.1-0.9-2.3-2.5-3.3c-1.5-0.9-3.3-1.1-5-1c-0.8,0.1-1.6,0.2-2.4,0.3c-0.8,0.1-1.6,0.3-2.5,0.6
						c-1.7,0.5-3.6,1.3-5.5,2.6c-1.9,1.3-4,3.4-5.2,6.4c-0.6,1.5-1,3.2-1.1,4.8c-0.1,1.7,0.1,3.3,0.6,5c0.5,1.6,1.2,3.2,2.1,4.6
						c0.9,1.5,2.1,2.7,3.3,3.8c2.5,2.2,5.4,3.8,8.4,4.8c6.1,2,13.1,1.9,19.6-0.7c3.2-1.3,6.4-3.2,9-5.8c2.6-2.5,4.7-5.6,6.2-8.9
						c1.5-3.3,2.4-6.9,2.5-10.6c0.2-3.7-0.5-7.5-1.8-10.8c-2.7-7-7.7-12-13.2-14.9c-5.6-2.9-11.3-3.4-16.2-3.1
						c-4.9,0.3-9.3,1.4-13.4,2.8c-4.1,1.3-7.9,3-11.5,4.9c-3.7,1.9-7.1,4.1-10.6,6.6c-3.4,2.5-6.7,5.3-9.9,8.6
						c-3.2,3.3-6.3,6.9-9.3,11.1c-2.9,4.2-5.7,9-8,14.5c2.6-5.3,5.7-9.8,9-13.7c3.2-3.9,6.6-7.1,10-9.9c3.4-2.8,6.9-5.1,10.4-7
						c3.5-1.9,7-3.5,10.6-4.8c3.6-1.2,7.3-2.3,11.1-2.9c3.8-0.7,7.7-1.1,11.5-0.8c3.8,0.3,7.6,1.4,10.5,3.5
						C49.6,448.2,52,451.7,53,455.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M121.8,361.1c10.9,1.6,21.5,3.9,29.6,5.9c0.5,0.1,1,0.2,1.5,0.3c0.5,0.1,1,0.2,1.4,0.4c0.9,0.2,1.8,0.4,2.6,0.6
						c0.8,0.2,1.6,0.3,2.4,0.5c0.7,0.2,1.4,0.3,2,0.4c2.5,0.4,4.2,0.3,4.6-0.5c0.4-0.8-0.5-2.1-2.5-3.9c-0.5-0.4-1.1-0.9-1.7-1.4
						c-0.6-0.5-1.4-0.9-2.1-1.4c-0.8-0.5-1.6-1-2.5-1.5c-0.5-0.2-0.9-0.5-1.4-0.8c-0.5-0.2-1-0.5-1.5-0.7
						c-8.1-3.8-19.9-6.9-31.8-6.7c-3.1,0.1-5.9,0.1-8.5,0.4c-1.3,0.1-2.6,0.2-3.8,0.4c-1.2,0.2-2.4,0.4-3.5,0.5
						c-1.1,0.2-2.3,0.3-3.3,0.5c-1.1,0.2-2.1,0.4-3.2,0.7c-1,0.2-2.1,0.4-3.1,0.7c-1,0.3-2,0.5-3,0.8c-1,0.3-2,0.5-3,0.8
						c-1,0.3-2,0.6-3,1c-1,0.3-2,0.6-3.1,1c-1,0.4-2.1,0.8-3.2,1.2c-1.1,0.4-2.2,0.8-3.3,1.3c-1.1,0.5-2.3,1-3.5,1.5
						c-0.6,0.3-1.2,0.5-1.8,0.8c-0.6,0.3-1.2,0.6-1.9,0.9c-1.3,0.6-2.6,1.2-4,1.9c1.4-0.5,2.8-1,4.1-1.5c0.7-0.2,1.3-0.5,1.9-0.7
						c0.6-0.2,1.3-0.4,1.9-0.6c1.2-0.4,2.4-0.7,3.6-1c1.2-0.3,2.3-0.5,3.4-0.8c1.1-0.2,2.2-0.5,3.3-0.7c1.1-0.2,2.1-0.3,3.2-0.5
						c1-0.1,2-0.3,3-0.4c1-0.1,2-0.2,3-0.3c1-0.1,2-0.2,3-0.2c1-0.1,2-0.1,3-0.1c1,0,2.1,0,3.1-0.1c1.1,0,2.1,0.1,3.2,0.1
						c1.1,0,2.2,0.1,3.4,0.1c1.2,0.1,2.3,0.2,3.6,0.3C116.4,360.4,119,360.8,121.8,361.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M120.9,378.3c10.8,0.9,21.8-0.5,29.7-2.7c7.9-2.2,12.8-5.1,12.3-6.8c-0.4-1.8-5.9-1.9-13.7-1.5
						c-7.8,0.4-18.1,1.3-28.4,2.2c-10.6,0.9-18,1.1-25.5,0.9c-7.5-0.3-15.1-1-26.1-3.4c10.7,3.5,18.2,5.4,25.6,7.2
						C102.3,375.9,109.8,377.4,120.9,378.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M132.4,371.1c6.3,1.3,12.7,1.4,17.5,0.7c4.8-0.7,8-2.3,8-4c0-1.8-3.1-3.3-7.8-4.4c-4.7-1-10.9-1.5-17.2-1
						c-6.5,0.5-10.9,0.9-15.5,1.2c-4.5,0.3-9.1,0.4-15.8,0c6.6,1.4,11,2.6,15.4,3.8C121.5,368.5,125.9,369.7,132.4,371.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M166.3,420.2c1,0,2.1,0,3.1-0.2c1-0.2,2.1-0.4,3.1-0.7c2-0.7,4-1.7,5.8-3.3c1.8-1.5,3.3-3.7,4-6.1
						c0.7-2.4,0.7-4.8,0.3-6.9c-0.4-2.1-1.2-4-2.1-5.7c-0.9-1.7-2-3.1-3.3-4.5c-1.3-1.3-2.8-2.4-4.5-3.3c-1.6-0.8-3.6-1.3-5.3-1.4
						c-3.5-0.2-6.9,1.1-9,3.3c-1.1,1.1-1.8,2.4-2.2,3.6c-0.4,1.2-0.5,2.3-0.4,3.3c0.2,2,1.1,3.4,1.9,4c0.8,0.7,1.5,0.7,1.8,0.4
						c0.3-0.3,0.4-0.9,0.5-1.6c0.1-0.7,0.2-1.6,0.7-2.4c0.4-0.9,1.2-1.8,2.1-2.1c0.9-0.4,2.3-0.6,3.6-0.2c1.3,0.4,2.6,1.3,3.9,2.8
						c1.2,1.5,2.3,3.6,2.6,5.5c0.2,0.9,0.1,1.7-0.1,2.3c-0.2,0.6-0.5,1.2-1.1,1.8c-0.6,0.6-1.5,1.2-2.5,1.7
						c-0.5,0.2-1.1,0.4-1.6,0.6c-0.6,0.2-1.1,0.3-1.8,0.4c-2.6,0.3-5.2,0-7.6-0.7c-2.4-0.7-4.7-1.7-6.8-2.9c-4.1-2.4-7.6-5.4-11-8.5
						c-3.5-3.1-6.9-6.5-11.2-9.7c-2.2-1.6-4.5-3.1-7.3-4.5c-2.7-1.3-5.9-2.4-9.3-2.6c3.5,0.5,6.4,1.9,8.9,3.4
						c2.5,1.5,4.7,3.3,6.5,5.1c3.8,3.6,6.6,7.3,9.5,11c3,3.7,6,7.4,10.3,11c2.2,1.8,4.6,3.4,7.6,4.8
						C158.6,419,162.2,420.1,166.3,420.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M130.6,425.7c-0.7,1.3-1.4,2.2-2.3,3c-0.9,0.7-1.7,1.2-2.5,1.4c-0.6,0.2-1.4,0.2-2.1,0c-0.7-0.2-1.2-0.4-1.5-0.7
						c-0.3-0.1-0.6-0.7-0.7-1.3c-0.1-0.6-0.1-1.3,0-1.9c0.1-0.6,0.3-1.1,0.4-1.6c0.2-0.5,0.3-1,0.2-1.4c-0.1-0.4-0.4-0.8-1.1-1
						c-0.7-0.2-1.8-0.1-2.9,0.5c-1.2,0.6-2.4,1.7-3.2,3.4c-0.8,1.6-1.3,4-0.4,6.6c0.8,2.4,2.7,4.6,5.1,5.9c2.4,1.3,5.3,1.8,8.3,1.2
						c2.9-0.6,5.4-2.2,7.2-4.2c1.8-2,3.1-4.6,3.7-7c0.6-2.5,0.6-4.8,0.4-6.8c-0.2-2-0.6-3.8-1-5.4c-0.9-3.3-2.2-6.1-3.7-8.6
						c-1.5-2.6-3.3-4.9-5.6-7.3c-1.1-1.2-2.4-2.3-3.8-3.5c-1.4-1.1-3.1-2.2-4.9-3.2c1.7,1.2,3.1,2.6,4.3,3.8c1.2,1.3,2.2,2.6,3,3.9
						c1.7,2.6,2.7,5.1,3.5,7.7c0.7,2.6,1.1,5.2,1.1,8C132.2,419.9,131.9,423.1,130.6,425.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M612.6,399.5c-0.7-3.6-1.9-7.1-3.5-10.4c-1.6-3.3-3.6-6.4-5.8-9.1c-2.2-2.7-4.6-4.8-6.8-6.8c-2.2-1.9-4.2-3.6-5.9-5.2
						c-3.5-3.1-6-6-7.6-8.2c-1.7-2.2-2.6-3.7-3.5-3.5c-0.8,0.1-1.5,2-0.9,5.4c0.3,1.6,0.9,3.6,1.9,5.6c1,2,2.4,4.2,4,6.3
						c3.3,4.3,7.7,8.3,11.3,12.7c3.5,4.3,6.4,9.6,8.4,15.4c1,3,1.7,5.9,2.1,8.6c0.4,2.7,0.7,5.4,0.7,7.9c0,5-0.5,9.6-1.6,14.1
						c-1.1,4.5-2.6,9-4.9,13.8c-2.3,4.8-5.4,9.9-9.8,15.6c4.8-5.3,8.4-10.1,11.3-14.7c2.9-4.6,5.2-9,7-13.5
						c1.8-4.6,3.3-9.3,4.1-14.8c0.4-2.8,0.6-5.7,0.6-8.9C613.7,406.6,613.4,403.2,612.6,399.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M580.2,404.8c-0.8-5.7-1-11.4-0.9-16.8c0.1-5.4,0.4-10.6,0.8-15c0.7-8.9,0.9-15.2-0.8-15.7c-1.7-0.4-4.9,5.1-7.5,14.3
						c-1.2,4.6-2.2,10.1-2.4,16.2c-0.2,6,0.4,12.6,2.2,18.9c1.9,6.5,4.3,11.6,7.1,15.9c1.4,2.2,2.8,4.1,4.3,5.9
						c1.4,1.8,2.9,3.4,4.2,5.1c2.6,3.3,5,6.9,5.9,11.6c0.2,1.2,0.4,2.4,0.4,3.7c0,1.3,0,2.6-0.2,4c-0.4,2.7-1.3,5.6-3,8.4
						c1.9-2.7,3.1-5.5,3.8-8.2c0.4-1.4,0.6-2.7,0.7-4.1c0.1-1.3,0.1-2.6,0.1-3.9c-0.1-2.5-0.7-5-1.5-7.2c-0.8-2.2-1.8-4.3-2.9-6.2
						c-2.2-3.9-4.5-7.5-6.2-11.7C582.4,415.5,581,410.7,580.2,404.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M593.6,391.6c-1-7.1-3.4-13.9-5.9-18.7c-2.5-4.8-4.9-7.5-5.7-7.1c-0.8,0.4,0.2,3.8,1.8,8.7c1.6,4.9,3.7,11.4,5.5,17.9
						c1.8,6.8,2.7,11.6,3.3,16.5c0.5,4.9,0.8,9.9,0.1,17.3c1.2-7.3,1.6-12.4,1.8-17.4C594.6,403.9,594.6,398.9,593.6,391.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M588.3,385.7c-0.4-5.3-1.8-10.5-3.4-14.1c-1.6-3.7-3.4-5.8-4.2-5.5c-0.8,0.3-0.6,3,0.2,6.7c0.7,3.8,1.9,8.6,3.1,13.5
						c1.2,5.1,1.9,8.6,2.3,12.2c0.4,3.6,0.7,7.4,0.3,12.8c0.9-5.4,1.3-9.2,1.6-12.9C588.5,394.8,588.7,391.1,588.3,385.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M583.8,385.6c0.2-8.5-1.7-16.9-3.5-16.8c-1.8,0.1-2.4,8.7-0.9,17.1c1.6,8.6,2.6,11.8,3.8,20.8
						C583,397.7,583.5,394.4,583.8,385.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M38.3,418.2c3.6-0.5,7.1-1.2,9.7-2c2.6-0.8,4.4-1.7,4.3-2.5c-0.1-0.9-2-1.5-4.9-1.6c-2.9-0.1-6.7,0.5-10.3,1.9
						c-3.7,1.5-6,2.8-8.4,4.1c-2.3,1.3-4.7,2.7-8,4.8c3.6-1.6,6.2-2.4,8.7-3C32.1,419.3,34.6,418.8,38.3,418.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M37.7,425.6c3.2-0.1,6.2-0.5,8.6-1.1c2.3-0.5,4-1.2,4-2.1c0-0.9-1.6-1.7-4.2-2.1c-2.6-0.4-6-0.1-9.3,0.9
						c-3.3,1.1-5.5,2.1-7.6,3.2c-2.1,1.1-4.2,2.2-7.3,3.9c3.3-1.2,5.6-1.8,7.9-2.1C32.2,425.9,34.5,425.7,37.7,425.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M59.5,432.6c2.4,0.8,4.7,1.4,6.6,1.6c1.9,0.2,3.4,0.1,3.8-0.7c0.4-0.8-0.5-2.1-2.3-3.2c-1.8-1.1-4.5-2-7.3-2
						c-2.9,0-4.8,0.3-6.8,0.7c-1.9,0.4-3.8,0.8-6.5,1.5c2.8-0.2,4.7,0,6.5,0.4C55.3,431.3,57.1,431.8,59.5,432.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M80.6,446.8c4,0.5,8.1-0.5,10.7-1.9c2.7-1.4,4-3.1,3.6-3.8c-0.4-0.8-2.4-0.5-5-0.1c-2.6,0.4-5.9,1-9.4,1.5
						c-3.6,0.4-6.1,0.3-8.7,0c-2.5-0.4-5.2-1.1-8.6-3.1c3.1,2.4,5.6,3.7,8.1,4.9C73.9,445.3,76.5,446.2,80.6,446.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M84.1,455.5c2.2,0,4.3-0.8,5.6-1.8c1.3-1,1.9-2.2,1.5-3c-0.4-0.8-1.6-1-3.1-0.9c-1.5,0.1-3.2,0.6-4.9,1.4
						c-1.8,0.8-3.1,1.4-4.4,1.8c-1.4,0.4-2.8,0.8-4.9,0.8c2.1,0.5,3.5,0.8,5,1.1C80.4,455.3,81.9,455.5,84.1,455.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-6.8,468.2c-3.8,3.9-6.3,9-7.3,13c-1,4-0.7,6.8,0.2,7c0.9,0.2,2.1-2.2,3.8-5.5c1.7-3.3,4-7.6,6.8-11.7
						c2.9-4.2,5.4-6.9,8.1-9.3c2.8-2.3,5.8-4.6,11-6.6c-5.3,1.5-8.8,3.2-12.1,5C0.3,462-2.9,464.1-6.8,468.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-0.6,480.6c-1,1.9-1.8,3.9-2.4,5.8c-0.3,1-0.5,1.9-0.7,2.8c-0.2,0.9-0.3,1.9-0.3,2.8c0,0.9,0.1,1.8,0.4,2.6
						c0.3,0.8,0.7,1.5,1.2,1.9c0.5,0.4,1,0.6,1.4,0.6c0.4,0,0.6-0.1,0.7-0.3c0.2-0.4-0.1-1,0-1.6c0-0.6,0.1-1.5,0.5-2.7
						c0.2-0.6,0.4-1.2,0.7-2.1c0.3-0.8,0.6-1.7,0.8-2.5c0.6-1.8,1.1-3.7,1.7-5.6c1.1-4,2.1-6.7,3.2-9.3c1.2-2.7,2.5-5.3,5-8.9
						c-2.9,3.2-4.8,5.6-6.7,7.9C3.3,474.3,1.5,476.7-0.6,480.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M7.5,492.9c-0.1,2.4,0.6,4.8,1.6,6.4c1,1.6,2.2,2.3,3,2c0.8-0.4,1.1-1.7,1-3.3c-0.1-1.6-0.5-3.6-1.2-5.6
						c-0.7-2.1-1.2-3.6-1.5-5.1c-0.3-1.6-0.5-3.2-0.3-5.6c-0.7,2.3-1.2,3.9-1.6,5.5C8.1,488.7,7.7,490.4,7.5,492.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M19.5,498.4c0.8,2,2.3,3.6,3.7,4.4c1.4,0.8,2.7,0.9,3.3,0.2c0.6-0.7,0.3-1.9-0.3-3.1c-0.6-1.3-1.7-2.7-3-3.9
						c-1.4-1.3-2.4-2.3-3.2-3.3c-0.9-1.1-1.7-2.2-2.5-4.2c0.3,2.1,0.5,3.5,0.7,4.9C18.5,494.9,18.7,496.4,19.5,498.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M36.3,500.3c1.6,0.8,3.4,0.8,4.7,0.4c1.3-0.4,2-1.3,2-2.2c-0.1-0.9-0.9-1.6-1.9-2c-1.1-0.5-2.4-0.7-3.8-0.5
						c-1.5,0.2-2.6,0.2-3.7,0.2c-1.1,0-2.3-0.2-3.8-0.8c1.1,0.9,2.1,1.7,3.2,2.6C33.7,498.7,34.7,499.5,36.3,500.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M173.3,453.4c1.6-3.6,2.3-7.6,2.2-11.5c-0.1-3.9-1-7.8-2.9-11.2c-1-1.7-2.2-3.2-3.6-4.5c-1.5-1.3-3.2-2.3-5.1-2.8
						c-3.8-1.1-7.5-0.3-10.2,1.3c-2.8,1.6-4.7,4.3-5.4,6.7c-0.8,2.5-0.6,4.7-0.2,6.4c0.5,1.7,1.2,2.9,1.8,3.6
						c0.6,0.7,1.1,0.9,1.2,0.8c0.4-0.3-0.5-1.9-0.7-4.8c0-1.4,0.2-3.2,1-4.9c0.8-1.7,2.2-3.3,4.2-4.2c2-1,4.6-1.3,6.8-0.5
						c2.3,0.8,4.4,2.7,5.8,5.3c1.4,2.6,2.2,5.7,2.4,8.9c0.2,3.2-0.3,6.6-1.4,9.7c-1.2,3.2-3,6-4.8,8.5c-1.9,2.5-3.8,4.8-5.6,7.1
						c-0.9,1.2-1.7,2.3-2.5,3.5c-0.4,0.6-0.7,1.2-1.1,1.8l-0.5,0.9l-0.5,0.9c-0.3,0.6-0.6,1.2-0.9,1.8c-0.3,0.6-0.5,1.2-0.8,1.8
						c-0.6,1.2-0.9,2.5-1.4,3.7c-0.8,2.5-1.4,5.1-1.8,7.8c-0.2,1.3-0.3,2.7-0.4,4.1c0,0.7,0,1.4,0,2.1c0,0.7,0,1.4,0,2.2
						c0.1,2.9,0.5,6,1.3,9.3c0.8,3.2,2,6.6,3.8,10c-1.7-3.5-2.7-6.9-3.4-10.1c-0.6-3.2-0.9-6.3-0.8-9.2c0-0.7,0-1.4,0.1-2.1
						c0.1-0.7,0.1-1.4,0.2-2.1c0.2-1.4,0.3-2.7,0.6-4c0.5-2.6,1.3-5,2.2-7.4c1.9-4.7,4.5-9,8.3-13.1c1.9-2.1,4-4.2,6.1-6.7
						C169.4,460,171.7,457.1,173.3,453.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M154.7,445.2c-2.1,1.9-3.9,4-5.1,5.8c-1.2,1.8-1.8,3.3-1.2,4c0.6,0.7,2.2,0.3,4.2-0.9c1.9-1.2,4.1-3.4,5.6-6.1
						c0.8-1.4,1.3-2.5,1.8-3.6c0.5-1.1,0.9-2.1,1.2-3.2c0.3-1.1,0.5-2.2,0.4-3.5c-0.1-1.2-0.5-2.6-1.7-3.7c0.9,1.3,1,2.6,0.8,3.7
						c-0.2,1.1-0.7,2-1.2,2.7c-0.6,0.8-1.2,1.5-2,2.3C156.6,443.3,155.7,444.2,154.7,445.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M157.3,453.9c-1.7,1.7-3.1,3.6-4,5.2c-0.9,1.6-1.2,3-0.6,3.7c0.6,0.6,2.1,0.3,3.7-0.9c1.6-1.1,3.4-3.1,4.4-5.5
						c1.1-2.4,1.7-4.2,2.2-6c0.6-1.9,1.1-3.7,1.8-6c-1.2,2.3-2.3,3.8-3.5,5.1C160.3,450.9,159.1,452.2,157.3,453.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M187.3,434.6c0.8-2.6,0.7-5.4,0.1-7.4c-0.6-2-1.7-3.1-2.5-2.9c-0.9,0.2-1.4,1.5-1.6,3.3c-0.3,1.8-0.4,4-0.3,6.3
						c0.1,2.4,0,4.2-0.2,5.9c-0.3,1.7-0.7,3.5-1.9,5.9c1.6-2.1,2.7-3.7,3.6-5.2C185.5,438.9,186.5,437.3,187.3,434.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M196,440.1c1.4-0.9,2.5-2.1,3.2-3.2c0.7-1.1,1-2.2,0.6-3c-0.5-0.7-1.7-0.9-3.1-0.4c-1.4,0.5-3,1.8-4,3.6
						c-1,1.8-1.4,3.2-1.8,4.5c-0.4,1.3-0.7,2.6-1.2,4.5c1-1.7,1.9-2.6,2.8-3.5C193.5,441.8,194.5,441.1,196,440.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M204.1,446c1.7-0.2,3.1-0.8,4.2-1.5c1.1-0.7,1.8-1.6,1.7-2.5c-0.1-0.9-1.2-1.5-2.7-1.6c-1.5-0.1-3.4,0.4-5,1.6
						c-1.6,1.2-2.5,2.2-3.5,3.2c-1,1.1-1.9,2.2-3,3.4c1.6-1,2.8-1.5,4-1.8C201.1,446.4,202.3,446.2,204.1,446z"
                    />
                  </g>
                  <g>
                    <path
                      d="M255.9,442.9c-2.8,2-5.5,3.9-7.6,5.6c-2,1.6-3.4,3-3,3.8c0.3,0.8,2.4,0.8,5.1-0.2c2.7-1,6-3.1,8.4-6
						c2.5-3,3.9-5.3,5.2-7.6c1.2-2.3,2.4-4.7,3.8-8.3c-1.9,3.4-3.5,5.4-5.3,7.2C260.7,439.2,258.8,440.8,255.9,442.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M258,452.6c-2.4,3.6-3.9,7.7-4.5,10.9c-0.5,3.2-0.2,5.5,0.6,5.6c0.9,0.2,2-1.7,3.4-4.4c1.3-2.7,2.9-6.4,4.3-10.1
						c1.5-3.8,2.8-6.4,4.3-9c1.5-2.5,3.1-5,6.1-8.3c-3.4,2.9-5.5,5-7.6,7.1C262.6,446.7,260.5,448.9,258,452.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M264.1,459.8c-1.9,3.8-3,8-3.2,11.2c-0.3,3.2,0.2,5.4,1,5.5c0.9,0.1,1.9-1.8,3-4.6c1.1-2.9,2.3-6.7,3.3-10.6
						c1-4,1.9-6.8,2.9-9.6c1-2.8,2.2-5.5,4.4-9.3c-2.7,3.5-4.4,6-6.1,8.5C267.7,453.4,266.1,455.9,264.1,459.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M266.4,475.3c-1.1,3.9-1.3,8-0.8,11c0.4,3,1.4,5,2.2,4.9c0.9-0.1,1.5-2,2-4.9c0.5-2.8,0.8-6.6,1-10.3
						c0.2-3.9,0.5-6.6,0.9-9.3c0.5-2.7,1.1-5.5,2.6-9.4c-2,3.6-3.2,6.2-4.4,8.8C268.6,468.7,267.5,471.3,266.4,475.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M332.1,491.8c-2.3,0.4-4.4,1.4-5.7,2.6c-1.3,1.2-1.9,2.4-1.4,3.2c0.5,0.8,1.8,0.9,3.4,0.5c1.6-0.3,3.5-1.1,5.3-2.2
						c1.9-1.2,3.2-2,4.6-2.7c1.4-0.7,2.9-1.4,5.1-2c-2.1,0-3.8,0.1-5.6,0.1C336.1,491.3,334.5,491.4,332.1,491.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M338.2,497.8c-2,0.5-2.5,2.8-1.3,4.1c1.2,1.3,3.4,1.1,4.2-0.8c0.8-1.9,0.9-3,2-4.7
						C341.2,497.3,340.2,497.3,338.2,497.8z"
                    />
                  </g>
                  <g>
                    <path d="M432.9,506.6c-3.6,0.1-6.5,2.6-5.9,4.2c0.7,1.6,4.1,1.7,7,0c3-1.8,4.1-2.8,7.6-4C438.1,507,436.6,506.6,432.9,506.6z" />
                  </g>
                  <g>
                    <path
                      d="M152,526.9c-2.4-3.5-5.6-6.8-9.9-9.1c-2.1-1.1-4.4-2-6.8-2.5c-2.4-0.5-5-0.6-7.6-0.1c-5.2,0.9-9.7,3.7-12.6,7.9
						c-0.7,1.1-1.3,2.2-1.8,3.4c-0.5,1.2-0.8,2.5-0.9,3.7c-0.3,2.4,0,4.7,0.7,6.8c0.7,2.1,1.8,4,3.3,5.5c1.5,1.5,3.3,2.6,5,3.2
						c3.6,1.1,6.6,0.4,8.6-0.7c2.1-1.1,3.2-2.7,3.5-3.9c0.2-0.6,0.2-1.1,0.1-1.5c-0.1-0.4-0.3-0.7-0.6-0.8c-0.3-0.2-0.6-0.3-1-0.3
						c-0.4,0-0.8,0-1.2-0.1c-0.8,0-1.8-0.1-2.7-0.4c-0.9-0.3-2-0.9-2.4-1.5c-0.2-0.3-0.3-0.7-0.4-1.1c-0.1-0.4-0.1-0.8-0.1-1.4
						c0-0.5,0.2-1,0.4-1.4c0.1-0.2,0.2-0.4,0.3-0.5c0.1-0.2,0.3-0.4,0.5-0.5c0.4-0.4,0.9-0.7,1.5-1c0.6-0.3,1.3-0.5,2-0.6
						c1.4-0.2,3.4,0.1,5.5,0.9c2,0.8,4.2,2.4,6.2,4.4c1.1,1,2.1,2.2,3,3.4c0.9,1.2,1.8,2.3,2.6,3.5c1.5,2.4,2.8,4.7,3.8,7.1
						c2,4.7,3.3,9.3,3.7,14.1c0.5,4.8,0.2,9.7-1.1,15.2c-1.3,5.4-3.8,11.3-8.4,17.4c5.2-5.5,8.6-11.1,10.9-16.5
						c2.3-5.4,3.7-10.6,4.3-15.9c0.6-5.3,0.5-10.8-0.6-16.8c-0.6-3-1.4-6.1-2.7-9.4c-0.7-1.6-1.4-3.3-2.3-5
						C154.2,530.4,153.2,528.7,152,526.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M180.5,567.9c3.9-1.9,7.3-4.1,9.8-6.3c2.5-2.1,4.1-4.2,3.6-5.9c-0.5-1.6-3.3-2.4-7.1-1.7c-3.7,0.7-8.4,3.2-11.7,7
						c-3.4,3.9-5.2,7-6.8,10c-1.6,3.1-3.1,6.2-4.9,10.7c2.8-4.1,5.3-6.3,7.8-8.3C173.8,571.6,176.5,569.9,180.5,567.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M174.5,542.4c0.4-3.8-1-7.4-2.8-9.6c-1.9-2.2-4.1-3-5.6-2.2c-1.6,0.9-2.2,2.9-2.2,5.3c0,2.4,0.6,5.1,1.9,7.8
						c1.3,2.9,2.2,5,2.8,7.3c0.6,2.3,1,4.7,0.6,8.2c1.4-3.3,2.3-5.6,3.2-8C173.2,548.8,174.1,546.3,174.5,542.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M59.8,573.1c-3.7,3.4-7.3,6.6-11.1,9.2c-3.7,2.5-7.5,4.5-10.6,4.9c-3.2,0.5-5.8-0.6-7.3-1.7c-1.6-1.1-2.1-2.3-2.6-2.2
						c-0.2,0.1-0.3,0.4-0.3,1.1c0.1,0.7,0.4,1.7,1.1,2.7c0.7,1.1,1.9,2.2,3.5,3c1.6,0.8,3.7,1.3,5.9,1.3c2.2-0.1,4.5-0.6,6.7-1.5
						c2.2-0.9,4.3-2.1,6.4-3.4c4.2-2.8,8-6.4,11.4-10.2c3.5-3.9,6.3-7.3,8.9-10.2c2.6-2.9,5.1-5.4,7.9-7.7c2.7-2.2,5.7-4.2,9.4-5.8
						c3.7-1.5,8.2-2.7,13.6-2c-5.3-1-10-0.1-13.9,1.2c-3.9,1.3-7.2,3.1-10.3,5.1c-3.1,2-5.9,4.3-8.9,7
						C66.7,566.4,63.5,569.6,59.8,573.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M73.8,568.7c-1.9,3-3.2,6.4-4,9.7c-0.8,3.3-0.9,6.4-0.8,9.1c0.4,5.4,2.1,8.6,2.9,8.4c0.9-0.2,0.8-3.6,1.3-8.4
						c0.3-2.4,0.7-5.1,1.4-8c0.7-2.9,1.7-5.9,3-8.8c1.4-3,2.8-5.4,4.4-7.4c1.6-2,3.2-3.7,4.9-5.3c1.8-1.5,3.7-3,6-4.3
						c2.3-1.3,5.1-2.5,8.6-3.4c-3.5,0.6-6.4,1.6-9,2.6c-2.5,1.1-4.7,2.2-6.8,3.5c-2.1,1.3-4,2.8-6.1,4.7
						C77.8,563.3,75.8,565.6,73.8,568.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M82.3,565.7c-1.2,2.3-2,4.8-2.4,7.2c-0.4,2.4-0.3,4.7,0,6.6c0.6,3.9,2.2,6.1,3,5.9c0.9-0.2,0.9-2.7,1.2-6
						c0.1-1.7,0.4-3.6,0.7-5.6c0.4-2,0.9-4.2,1.5-6.3c0.7-2.2,1.5-4,2.4-5.5c0.9-1.6,1.8-2.9,2.9-4.2c1.1-1.3,2.3-2.5,3.8-3.7
						c1.5-1.2,3.3-2.3,5.7-3.3c-2.4,0.8-4.4,1.7-6.1,2.6c-1.7,0.9-3.2,1.9-4.6,3c-1.4,1.1-2.7,2.3-4.1,3.8
						C84.9,561.6,83.5,563.4,82.3,565.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M126.8,555.4c3.6,0.7,7.5,0,9.9-1.3c2.5-1.3,3.7-2.8,3.3-3.6c-0.4-0.8-2.2-0.7-4.6-0.4c-2.4,0.3-5.3,0.6-8.5,0.9
						c-3.2,0.2-5.5,0-7.8-0.4c-2.3-0.5-4.6-1.3-7.6-3.4c2.6,2.5,4.7,3.9,6.9,5.1C120.8,553.5,123.1,554.6,126.8,555.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M138.6,563.1c1.7,1.4,3.4,2.4,4.9,3.1c1.5,0.7,2.8,0.9,3.4,0.3c0.6-0.6,0.4-2-0.8-3.5c-1.1-1.5-3.1-3.1-5.5-3.8
						c-2.4-0.7-4.2-0.9-5.9-1c-1.7-0.1-3.4-0.2-5.8-0.2c2.4,0.5,3.9,1.2,5.3,2C135.6,560.8,136.9,561.7,138.6,563.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M141,578.8c0.3,2.1,1,4.1,1.8,5.5c0.8,1.5,1.7,2.4,2.6,2.3c0.9-0.1,1.5-1.4,1.5-3.2c0.1-1.8-0.5-4.2-1.8-6.2
						c-1.3-2.1-2.4-3.4-3.5-4.6c-1.1-1.2-2.2-2.5-3.8-4.2c1.2,2.1,1.7,3.6,2.2,5.2C140.4,575.1,140.7,576.6,141,578.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M143.4,505.7c0.1-2,0.1-3.7-0.1-5.2c-0.2-1.5-0.5-2.7-1.3-3c-0.8-0.3-2,0.5-2.8,2.3c-0.8,1.7-1,4.4-0.2,6.7
						c0.9,2.4,1.9,3.8,3,5.1c1.1,1.3,2.2,2.5,4,4c-1.5-1.8-2-3.4-2.3-4.8C143.4,509.2,143.3,507.7,143.4,505.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M158.8,498.6c0.9-1.8,1.7-3.4,2.3-4.9c0.6-1.4,0.9-2.7,0.4-3.4c-0.5-0.7-2.1-0.5-3.6,0.8c-1.6,1.3-3.1,3.7-3.3,6.4
						c-0.2,2.8,0.3,4.6,0.9,6.3c0.6,1.7,1.4,3.3,2.8,5.3c-1-2.3-1.1-4-0.9-5.5C157.5,501.9,157.9,500.4,158.8,498.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M187.9,576c2.7-0.4,5.2-1.1,7.1-1.9c1.9-0.8,3.2-1.7,3.1-2.6c-0.1-0.9-1.6-1.5-3.8-1.5c-2.2,0-5.1,0.5-7.7,1.8
						c-2.7,1.3-4.4,2.5-6.1,3.6c-1.8,1.2-3.5,2.4-5.8,4c2.7-1.2,4.6-1.8,6.5-2.3C183.2,576.8,185.2,576.4,187.9,576z"
                    />
                  </g>
                  <g>
                    <path
                      d="M149,392.3c3,0.1,5.8-1,7.6-2.1c1.8-1.1,2.7-2.4,2.4-3.2c-0.3-0.8-1.9-1-3.9-0.7c-2,0.3-4.4,1-6.7,1.7
						c-2.4,0.8-4.2,1-6,0.9c-1.8-0.1-3.8-0.5-5.9-2.5c1.7,2.3,3.6,3.4,5.4,4.3C143.8,391.6,145.9,392.2,149,392.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M134,321c-1.8,2.8-3.2,6-3.8,9.2c-0.6,3.2-0.5,6.3,0.2,8.9c0.7,2.6,1.8,4.5,2.9,5.6c0.5,0.6,1,0.9,1.4,1.1
						c0.4,0.2,0.7,0.2,0.8,0.1c0.7-0.6-0.7-3.3-1-7.6c-0.1-2.1,0-4.6,0.5-7.2c0.5-2.6,1.5-5.3,2.8-8c1.3-2.8,2.7-5.1,4-7.1
						c1.3-2.1,2.6-3.9,3.9-5.7c1.3-1.9,2.6-3.7,4-5.8c1.4-2.1,2.9-4.5,4.3-7.6c-1.6,2.9-3.4,5.1-5,7.1c-1.6,1.9-3.2,3.5-4.8,5.2
						c-1.6,1.6-3.1,3.2-4.8,5.1C137.7,315.9,135.8,318.1,134,321z"
                    />
                  </g>
                  <g>
                    <path
                      d="M143.2,322.4c-0.7,5.5,0.4,11.2,2,15.1c1.6,3.9,3.6,6,4.4,5.6c0.8-0.4,0.3-3.1-0.4-6.8c-0.7-3.8-1.4-8.7-1.7-13.7
						c-0.2-5.2,0.2-8.9,1-12.5c0.8-3.6,2-7.3,5-12.1c-3.4,4.5-5.2,8-6.8,11.5C145.2,313,143.9,316.7,143.2,322.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M149.7,316.6c0,4,1.3,7.8,2.8,10.4c1.5,2.6,3.2,3.9,3.9,3.4c0.8-0.4,0.5-2.4-0.1-5c-0.6-2.6-1.5-6-2.3-9.4
						c-0.9-3.5-1.2-6.1-1.4-8.7c-0.1-2.6,0-5.3,1-9.2c-1.5,3.7-2.3,6.3-2.9,9C150.2,309.8,149.7,312.5,149.7,316.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M93.8,341.5c3.2-2.6,5.7-6,7.1-8.8c1.4-2.8,1.7-5,0.9-5.4c-0.8-0.4-2.4,0.9-4.4,3c-2,2.1-4.4,5-6.8,8.1
						c-2.4,3.1-4.3,5.2-6.3,7.2c-2,2-4.2,3.8-7.8,6.1c3.8-1.8,6.4-3.2,8.9-4.6C88,345.7,90.5,344.2,93.8,341.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M102.3,347.6c2.7-0.3,5.3-1,7.2-1.7c1.9-0.8,3.2-1.7,3.1-2.5c-0.1-0.9-1.6-1.5-3.8-1.6c-2.2-0.1-5.1,0.4-7.7,1.7
						c-2.7,1.3-4.4,2.4-6.2,3.5c-1.8,1.2-3.6,2.3-5.9,3.8c2.7-1.2,4.7-1.7,6.6-2.1C97.6,348.2,99.5,347.9,102.3,347.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M119.4,246.7c1.9-1.6,3.5-3.5,4.5-5.2c1-1.6,1.4-3,0.8-3.7c-0.6-0.6-2.1-0.3-3.8,0.8c-1.7,1.1-3.6,3-4.9,5.4
						c-1.3,2.4-2,4.2-2.8,5.9c-0.8,1.9-1.5,3.7-2.5,6c1.5-2.3,2.8-3.7,4.1-5C116.1,249.6,117.4,248.4,119.4,246.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M20.7,384.4c-7.8,3.2-14.9,7.9-19.7,12.1c-4.8,4.2-7.2,7.7-6.7,8.3c0.6,0.7,4.1-1.6,9.2-5c5.1-3.3,11.9-7.6,19-11.4
						c7.3-3.9,12.7-6.1,18.2-8c5.5-1.8,11.3-3.3,20-4.3c-8.8,0.4-14.7,1.3-20.5,2.4C34.5,379.7,28.7,381.1,20.7,384.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M27.1,386.9c-6.2,3.1-11.7,7.5-15.3,11.3c-3.6,3.8-5.4,6.9-4.8,7.4c0.7,0.6,3.5-1.3,7.5-4.3c4-3,9.3-6.9,14.8-10.6
						c5.7-3.8,9.8-6.1,14.1-8.2c4.3-2,8.9-3.9,15.8-5.7c-7.1,1.2-11.9,2.5-16.5,3.9C38.1,382.1,33.5,383.7,27.1,386.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-19.8,372.5c-3.2-2.4-6.3-4.7-8.9-6.3c-2.5-1.6-4.5-2.5-5.1-1.9c-0.6,0.6,0.3,2.7,2.4,5.1c2.1,2.4,5.5,5.1,9.3,6.9
						c4,1.8,6.8,2.7,9.6,3.6c2.9,0.8,5.7,1.6,10,2.5c-4.1-1.4-6.8-2.8-9.3-4.2C-14.2,376.7-16.6,375-19.8,372.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-14.5,384.4c-0.9,0-1.8,0.2-2.7,0.6c-0.9,0.3-1.9,1-2.5,2c-0.6,1-0.6,2.1-0.3,2.7c0.3,0.7,0.9,0.8,1.3,0.7
						c0.4-0.1,0.8-0.4,1.2-0.5c0.4-0.1,0.7-0.1,1-0.1c0.2,0.1,0.6,0,1.2-0.2c0.7-0.2,1.4-0.5,2.1-1c1.4-0.9,2.5-1.5,3.6-2
						c1.1-0.5,2.3-0.9,4.1-1.2c-1.6-0.2-2.9-0.5-4.4-0.7C-11.3,384.6-12.6,384.4-14.5,384.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-13.6,395.2c-2.1,1.6-3.6,3.7-4.4,5.6c-0.7,1.8-0.7,3.3,0,3.8c0.8,0.5,2-0.2,3.4-1.4c1.4-1.2,2.9-3,4.1-5
						c1.3-2.1,2.3-3.5,3.4-4.9c1.1-1.4,2.3-2.7,4.3-4.4c-2.3,1.2-4,2-5.6,2.9C-9.8,392.7-11.4,393.6-13.6,395.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-9.5,421.5c-1.3,2.9-0.3,6,1.4,6.3c1.7,0.2,3.5-2.6,2.9-5.7c-0.5-3.2-1.1-4.4-1.2-7.7C-7.4,417.5-8.2,418.6-9.5,421.5
						z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-2.1,415.6c-1.2,2.5-0.2,5.3,1.5,5.5c1.7,0.2,3.4-2.2,2.8-4.9c-0.6-2.8-1.2-3.9-1.4-6.7C0,412.2-0.9,413.1-2.1,415.6z
						"
                    />
                  </g>
                  <g>
                    <path
                      d="M578.1,305.9c3.3-4.2,6.4-8.7,8.9-13.6c2.5-4.9,4.2-10.3,4.9-15.7c0.7-5.4,0.4-10.9-1.2-15.8c-0.4-1.2-0.8-2.4-1.3-3.6
						c-0.5-1.2-1.1-2.3-1.8-3.4c-1.4-2.2-3.1-4.2-5.3-5.7c-1.1-0.8-2.3-1.3-3.6-1.8c-1.3-0.4-2.7-0.6-4-0.5
						c-2.5,0.1-4.9,0.9-6.8,2.5c-0.9,0.8-1.7,1.7-2.3,2.7c-0.6,1-0.8,2.1-1,3.1c-0.2,2,0.2,3.7,0.7,5c1.1,2.5,2.5,3.9,3.5,4.8
						c1,0.9,1.7,1.1,2.1,0.9c0.4-0.2,0.3-1,0.1-2.2c-0.2-1.2-0.8-2.9-1-4.7c-0.1-0.9,0-1.8,0.3-2.5c0.2-0.3,0.4-0.7,0.6-0.9
						c0.2-0.2,0.5-0.4,0.9-0.6c0.7-0.3,1.7-0.5,2.7-0.3c0.5,0.1,1,0.2,1.4,0.4c0.4,0.2,0.9,0.6,1.4,1c0.9,0.8,1.8,2,2.5,3.4
						c0.7,1.4,1.3,3.2,1.8,5c0.9,3.6,1,7.7,0.4,12c-0.6,4.2-1.9,8.6-3.8,12.8c-1.9,4.3-4.4,8.5-7.2,12.6c-2.9,4.3-5.8,8.2-8.5,11.9
						c-2.7,3.7-5.2,7.3-7.5,10.8c-2.2,3.5-4.2,7-5.8,10.6c-1.6,3.6-2.8,7.2-3.7,10.9c-1.7,7.4-2.1,15.1-0.8,23.5
						c0.6,4.2,1.7,8.5,3.4,13c1.7,4.4,4,9,7.3,13.5c-3-4.6-5.1-9.3-6.5-13.7c-1.4-4.5-2.1-8.8-2.4-12.9c-0.6-8.2,0.5-15.5,2.8-22.3
						c1.2-3.4,2.6-6.6,4.4-9.7c1.8-3.1,4-6.2,6.4-9.3c2.4-3.1,5.2-6.3,8.2-9.7C571.4,313.8,574.7,310.2,578.1,305.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M561.9,353.7c1-1.7,2-3.3,3.1-4.8c1.1-1.5,2.2-2.8,3.4-4.1c1.1-1.3,2.4-2.4,3.5-3.4c0.3-0.2,0.6-0.5,0.9-0.7
						c0.3-0.2,0.5-0.4,0.8-0.6c0.5-0.4,1.1-0.7,1.6-1c2.2-1.2,4-1.8,5.4-2c1.5-0.3,2.7-0.1,3.3-0.7c0.3-0.3,0.4-0.8,0.2-1.5
						c-0.2-0.7-0.8-1.5-1.9-2.3c-1-0.8-2.5-1.4-4.3-1.6c-1.7-0.2-3.7-0.1-5.6,0.3c-1,0.2-2,0.6-3,1c-0.5,0.2-1,0.5-1.5,0.7
						c-0.5,0.3-0.9,0.5-1.4,0.8c-1.8,1.1-3.7,2.5-5.3,4.2c-1.6,1.7-3.1,3.6-4.4,5.6c-1.2,2.1-2.2,4.3-3,6.5
						c-1.6,4.7-2.1,8.7-2.3,12.3c-0.2,3.6,0,6.8,0.3,9.9c0.7,6.3,1.9,12.4,3.8,21.4c-0.9-9.1-0.8-15.4,0-21.4c0.4-3,1-5.9,1.9-9
						C558.6,360.4,559.9,357.1,561.9,353.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M574.5,279.9c-0.6-1.9-1.5-3.7-2.6-5.3c-0.6-0.8-1.2-1.6-1.9-2.3c-0.7-0.7-1.5-1.3-2.3-1.9c-1.7-1.1-3.9-1.8-6.2-1.5
						c-1.1,0.2-2.1,0.5-3,1.1c-0.9,0.6-1.6,1.4-2.1,2.4c-0.4,0.9-0.6,1.9-0.6,2.8c0,0.9,0.2,1.6,0.5,2.3c0.5,1.3,1.2,2.1,1.8,2.7
						c1.2,1.2,2.1,1.6,2.4,1.4c0.4-0.3,0.1-1.2-0.4-2.6c-0.2-0.7-0.5-1.5-0.6-2.4c-0.1-0.9,0.1-1.5,0.5-2c0.4-0.4,1.1-0.8,2-0.9
						c0.8-0.1,1.9,0.3,2.9,1c1.1,0.7,2.1,1.8,3.1,2.9c0.9,1.2,1.7,2.5,2.4,3.9c1.3,3,1.8,5.9,1.9,8.5c0.1,2.6-0.2,5.1-0.8,7.5
						c-1.1,4.8-3.2,9.6-6.8,16.2c4.1-6.4,6.7-10.8,8.6-15.6c0.9-2.4,1.7-4.9,2-7.9C575.6,287.2,575.6,283.8,574.5,279.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M568.9,290c-0.3-0.5-0.5-1.1-0.8-1.6c-0.3-0.5-0.7-1.1-1.2-1.5c-0.9-1-1.9-1.8-3.4-2.3c-0.7-0.3-1.5-0.4-2.3-0.4
						c-0.8,0-1.6,0.3-2.3,0.6c-1.4,0.7-2.2,1.8-2.6,2.8c-0.5,1-0.6,2-0.6,2.7c0,0.8,0.1,1.4,0.3,1.9c0.3,1,0.7,1.5,1.2,1.5
						c0.4,0,0.9-0.5,1.3-1.4c0.4-0.8,0.9-2,1.5-2.7c0.3-0.4,0.7-0.6,1-0.7c0.3,0,0.5,0,0.9,0.1c0.4,0.2,1,0.5,1.6,1.1
						c0.3,0.3,0.6,0.5,0.8,0.9c0.3,0.3,0.5,0.7,0.8,1.1c1.1,1.5,1.8,3,2.3,4.5c0.5,1.4,0.8,2.8,0.9,4.2c0.1,1.4,0.1,2.9-0.3,4.5
						c-0.3,1.6-0.9,3.4-2.1,5.3c1.4-1.7,2.3-3.4,3-5c0.7-1.6,1.1-3.1,1.3-4.7c0.2-1.6,0.4-3.1,0.2-4.9
						C570.3,294.2,569.9,292.2,568.9,290z"
                    />
                  </g>
                  <g>
                    <path
                      d="M548.8,301.3c-1.9-0.3-3.8-0.3-5.2,0c-1.5,0.3-2.6,0.9-2.7,1.8c-0.2,0.9,0.7,1.8,2.3,2.4c1.6,0.6,3.8,0.8,5.9,0.2
						c2.2-0.6,3.6-1.2,5-1.9c1.5-0.7,2.9-1.4,4.7-2.2c-2.1,0.4-3.6,0.5-5.1,0.4C552.1,301.9,550.7,301.7,548.8,301.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M536.3,308.9c-5.8,0.2-11.1,2.8-10.6,4.5c0.4,1.7,6.2,1.7,11.5-0.2c5.5-1.9,7.5-3,13.4-4.3
						C544.5,309.2,542.3,308.8,536.3,308.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M525.6,316.8c-3.9-0.7-7.6,0.8-7.6,2.5c0.1,1.8,4,2.9,7.8,1.8c3.9-1.1,5.3-1.9,9.3-2.6
						C531,318.2,529.6,317.5,525.6,316.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M537.6,319c-2.2,1.1-3.9,2.9-4.8,4.6c-0.9,1.6-1.1,3.1-0.4,3.6c0.7,0.6,2,0.2,3.4-0.7c1.5-0.9,3.1-2.3,4.5-4
						c1.5-1.7,2.6-2.9,3.8-4.1c1.2-1.1,2.5-2.2,4.6-3.4c-2.4,0.7-4,1.3-5.6,1.8C541.5,317.3,539.9,317.8,537.6,319z"
                    />
                  </g>
                  <g>
                    <path
                      d="M545.3,322.1c-1.5,1.4-2.5,3.1-2.9,4.6c-0.4,1.5-0.2,2.7,0.6,3.2c0.8,0.4,1.9,0,3-1c1.1-1,2.2-2.5,2.9-4.3
						c1.5-3.7,1.8-5.3,4.1-8.8C549.9,318.6,548.4,319.3,545.3,322.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M554.7,308c-1.3,0.9-2.1,2.2-2.4,3.4c-0.3,1.2,0,2.2,0.7,2.7c0.7,0.5,1.8,0.3,2.7-0.3c1-0.6,1.9-1.7,2.4-3
						c1.1-2.8,1.3-4.1,3.2-6.7C558.7,305.8,557.4,306.2,554.7,308z"
                    />
                  </g>
                  <g>
                    <path
                      d="M532.3,341c-1.2,1.5-1.8,3.3-1.8,4.8c0,1.5,0.5,2.5,1.4,2.7c0.9,0.2,1.8-0.4,2.6-1.5c0.8-1,1.5-2.5,1.8-4.2
						c0.3-1.7,0.5-3,0.9-4.2c0.3-1.2,0.8-2.4,1.7-4.1c-1.4,1.3-2.3,2.2-3.3,3.1C534.5,338.6,533.5,339.5,532.3,341z"
                    />
                  </g>
                  <g>
                    <path
                      d="M533.2,356.5c-2.1,3.9-1.8,8.5-0.1,8.9c1.7,0.4,3.9-3.4,4.3-7.6c0.4-4.4,0.2-6.1,1.4-10.5
						C536.7,351.3,535.4,352.5,533.2,356.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M533.9,379.3c-0.4,2.4,0.2,4.9,1,6.6c0.8,1.7,2,2.6,2.8,2.3c0.8-0.3,1.2-1.6,1.3-3.2c0.1-1.7-0.2-3.7-0.7-5.8
						c-0.5-2.2-0.8-3.7-1-5.4c-0.2-1.6-0.2-3.3,0.2-5.7c-0.9,2.3-1.5,3.8-2.1,5.5C534.8,375.2,534.2,376.8,533.9,379.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M570.2,359.3c1.9-2.2,3.7-4.2,5-6c1.3-1.7,2.2-3.2,1.7-3.9c-0.5-0.7-2.3-0.5-4.4,0.8c-2.2,1.3-4.6,3.7-6,6.8
						c-1.4,3.1-1.9,5.4-2.3,7.6c-0.4,2.2-0.6,4.5-0.6,7.8c0.5-3.2,1.4-5.3,2.4-7.2C567.1,363.2,568.3,361.5,570.2,359.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M580.7,319.9c1.7-0.7,3-1.7,4-2.7c0.9-1,1.4-2.1,1.1-2.9c-0.4-0.8-1.6-1.1-3.1-0.8c-1.5,0.3-3.3,1.3-4.6,2.8
						c-1.3,1.6-2.1,2.8-2.8,4c-0.8,1.3-1.5,2.6-2.4,4.1c1.4-1.4,2.6-2.1,3.8-2.7C577.8,321.1,579,320.6,580.7,319.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M-6.5,258.1c1.9-0.4,3.5-1.1,4.7-2c1.2-0.9,1.9-1.9,1.6-2.7c-0.2-0.8-1.4-1.3-3-1.2c-1.6,0.1-3.5,0.7-5.2,2
						c-1.7,1.3-2.7,2.3-3.8,3.3c-1,1-2.1,2-3.7,3.4c1.9-0.9,3.2-1.4,4.6-1.7C-9.8,258.7-8.5,258.5-6.5,258.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M8.4,246.2c-1.5,2.6-2.1,5.6-2,7.8c0.1,2.3,0.8,3.7,1.7,3.8c0.9,0.1,1.7-1.2,2.5-3.1c0.8-1.9,1.5-4.3,2-6.9
						c0.5-2.7,1.1-4.5,1.7-6.4c0.7-1.8,1.5-3.7,3.3-6.1c-2.1,2-3.5,3.5-4.9,5.1C11.2,242,9.9,243.6,8.4,246.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M544.8,223.7c-1.8-1.5-3.8-2.7-6-3.5c-2.1-0.9-4.5-1.4-6.9-1.4c-1.2,0-2.4,0.2-3.5,0.6c-1.2,0.4-2.3,0.9-3.2,1.6
						c-1.9,1.4-3.2,3.3-3.7,5.3c-0.3,1-0.3,2-0.2,3.1c0.2,1,0.5,1.8,0.9,2.5c0.9,1.4,2.1,2.3,3.1,2.7c1.1,0.4,2,0.4,2.6,0.2
						c0.6-0.2,0.8-0.4,0.8-0.6c-0.1-0.5-1.1-0.6-2.2-1.7c-1.1-1-2.1-3.1-1.2-5c0.4-1,1.3-2,2.4-2.7c0.6-0.4,1.2-0.6,1.9-0.8
						c0.7-0.2,1.5-0.3,2.2-0.3c3.2,0,7,1.4,10.2,3.5c3.3,2.2,5.7,4.8,7.4,7.5c0.9,1.3,1.6,2.7,2.2,4c0.6,1.4,1.1,2.7,1.5,4.1
						c0.8,2.8,1.2,5.8,1.2,9.2c0,3.4-0.4,7.1-1.6,11.5c1.4-4.3,2.2-8,2.5-11.5c0.3-3.4,0.2-6.5-0.3-9.6c-0.3-1.5-0.6-3.1-1-4.6
						c-0.5-1.5-1.1-3.1-1.9-4.7C550.7,230.1,548.4,226.7,544.8,223.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M547.9,236.8c-0.9-1.3-2.2-2.4-3.6-3.2c-1.5-0.8-3.3-1.2-5-1c-1.8,0.2-3.4,1.2-4.5,2.4c-1.1,1.2-1.7,2.9-1.6,4.4
						c0.1,1.7,1,2.9,1.8,3.6c0.9,0.7,1.9,1.1,2.7,1.1c0.8,0,1.4-0.2,1.8-0.5c0.3-0.3,0.4-0.6,0.3-0.8c-0.2-0.4-0.9-0.6-1.4-1.1
						c-0.3-0.3-0.6-0.6-0.8-1.1c-0.2-0.4-0.3-0.9-0.1-1.2c0.1-0.4,0.4-0.8,0.8-1.2c0.5-0.4,1.1-0.7,1.7-0.7c1.3-0.2,3.2,0.6,4.7,2
						c1.6,1.5,2.6,3.5,3.6,5.3c0.9,1.8,1.7,3.4,2.3,5.1c0.6,1.7,1.2,3.5,1.6,5.5c0.4,2,0.7,4.4,0.8,7.1c0.2-2.8,0.2-5.1,0.1-7.2
						c-0.1-2.1-0.3-4-0.6-5.9c-0.3-1.9-0.7-3.7-1.3-5.8c-0.3-1-0.6-2.1-1.1-3.3C549.5,239.4,548.9,238.1,547.9,236.8z"
                    />
                  </g>
                  <g>
                    <path
                      d="M613.1,514.1c0.5,1.2,0.7,2.4,0.8,3.5c0,1.1-0.1,2-0.4,2.7c-0.3,0.7-0.7,1.4-1.3,2.1c-0.6,0.6-1.4,1.3-2.4,1.7
						c-1.9,1-4,1.4-6.1,1.3c-2-0.1-3.6-0.8-4.7-1.5c-1.2-0.9-2-2.1-2.3-3.2c-0.4-1-0.3-2.3,0.1-3.4c0.4-1.1,0.9-2,1.4-2.8
						c0.5-0.8,0.9-1.4,0.9-2.1c0-0.6-0.6-1.3-1.9-1.6c-1.3-0.3-3.3,0-5.5,1.3c-1.1,0.7-2.1,1.6-3.1,2.9c-0.5,0.7-0.9,1.4-1.2,2.2
						c-0.4,0.8-0.6,1.8-0.8,2.7c-0.7,3.8,0.2,8.2,2.9,11.9c2.8,3.9,7.2,6.5,12,7.4c4.7,1,10.1,0.5,14.9-1.9c2.4-1.2,4.7-2.8,6.7-5
						c2-2.2,3.6-4.9,4.4-8c0.8-3,0.8-6.2,0.3-9c-0.6-2.9-1.7-5.4-3.1-7.7c-1.5-2.3-3.3-4.3-5.2-5.9c-1.9-1.6-4.1-2.9-6.1-3.7
						c-4.1-1.7-8-2.3-11.5-2.4c-7.1-0.1-12.9,1.4-18.6,3.1c-5.6,1.8-11.1,4.1-16.8,7.4c-5.7,3.2-11.6,7.4-17.6,13.4
						c6.6-5.4,12.9-8.7,18.8-11c5.9-2.3,11.4-3.5,16.8-4.2c5.4-0.6,11-0.7,16.3,0.5c2.7,0.6,5.3,1.6,7.5,3.1c1.2,0.8,2,1.6,2.8,2.6
						C611.9,511.7,612.6,512.9,613.1,514.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M586.9,487.9c2.8-1.8,5.2-4.2,7.1-6.8c1.9-2.6,3.2-5.3,3.9-7.8c0.7-2.5,0.8-4.7,0.6-6.3c-0.3-1.6-0.9-2.5-1.8-2.7
						c-1.8-0.3-3.7,2.1-6.3,5.3c-1.3,1.7-2.7,3.5-4.3,5.4c-1.6,2-3.3,4.1-5.1,6.3c-1.8,2.3-3.6,4.1-5.2,5.7
						c-1.7,1.6-3.2,3.1-4.9,4.4c-3.3,2.7-6.9,5.3-12.6,8.5c6.1-2.3,10.3-3.7,14.5-5.2c2.1-0.8,4.2-1.6,6.5-2.7
						C581.6,491.1,584.1,489.8,586.9,487.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M574,520.4c-1.2,2.3-1.3,5.1-0.8,7c0.5,1.9,1.5,3,2.4,2.8c0.9-0.2,1.4-1.4,1.8-3c0.4-1.6,0.7-3.5,0.8-5.6
						c0.2-2.2,0.5-3.7,1-5.3c0.5-1.5,1.3-3,2.9-4.9c-2,1.4-3.3,2.6-4.5,3.9C576.4,516.6,575.3,518,574,520.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M580.2,543.9c0.4,2.6,1.7,5,3.2,6.4c1.5,1.4,2.9,1.9,3.6,1.3c0.7-0.6,0.5-2,0-3.6c-0.5-1.6-1.4-3.6-2.5-5.5
						c-1.1-2-1.8-3.6-2.3-5.2c-0.5-1.6-0.9-3.3-0.8-5.9c-0.6,2.5-0.9,4.3-1.1,6.1C580,539.4,579.8,541.2,580.2,543.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M599.3,558c1.3,1.4,3,2.4,4.7,2.8c1.7,0.5,3.4,0.5,4.8,0.2c1.4-0.3,2.4-0.9,3-1.4c0.6-0.6,0.8-1.1,0.7-1.5
						c-0.4-0.8-2-0.9-3.9-1.2c-2-0.3-4.3-0.9-6.5-2.2c-1.2-0.7-2.1-1.5-2.9-2.3c-0.8-0.8-1.4-1.7-2-2.6c-1.2-1.8-2.1-3.9-3.1-7.1
						c0.5,3.3,0.8,5.6,1.4,7.8c0.3,1.1,0.6,2.3,1.2,3.5C597.2,555.2,598,556.6,599.3,558z"
                    />
                  </g>
                  <g>
                    <path
                      d="M613.7,553.2c2.2,1.1,4.7,1.3,6.5,1c1.8-0.3,3-1.2,2.9-2.1c-0.1-0.9-1.2-1.6-2.7-2.1c-1.5-0.5-3.5-0.9-5.5-1
						c-2.2-0.2-3.7-0.4-5.2-0.7c-1.5-0.4-3.1-0.9-5.1-2.2c1.7,1.7,2.9,2.8,4.2,3.9C610.1,551,611.5,552.1,613.7,553.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M623.6,545.9c1.5,0.1,2.9-0.6,3.8-1.4c0.8-0.8,1.1-1.9,0.7-2.6c-0.4-0.8-1.3-1.2-2.4-1.2c-1,0-2.2,0.3-3.3,1
						c-1.1,0.8-1.9,1.3-2.8,1.8c-0.9,0.5-1.8,0.9-3.2,1.2c1.4,0.3,2.5,0.5,3.5,0.7C620.9,545.6,622,545.8,623.6,545.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M584.7,454.4c0.8-4,0.4-8.1-0.6-11.1c-0.9-2.9-2.3-4.6-3.1-4.4c-0.9,0.3-1.1,2.3-1,5c0,2.8,0.2,6.4,0.3,10.1
						c0.1,3.8,0,6.5-0.4,9.1c-0.4,2.7-1,5.4-2.7,9.1c2.2-3.5,3.4-6,4.5-8.6C582.9,461.2,583.9,458.5,584.7,454.4z"
                    />
                  </g>
                  <g>
                    <path
                      d="M175.8,236.9c-0.3,3.3,0.7,6.7,2.2,8.7c1.4,2.1,3.1,2.9,3.7,2.4c0.7-0.5,0.5-2.1,0.1-4.1c-0.5-2.3-1-4.4-1.6-7.2
						c-0.5-2.8-0.6-4.8-0.4-6.9c0.2-2,0.7-4.2,2.3-6.9c-2.1,2.4-3.2,4.4-4.2,6.4C177,231.3,176.2,233.5,175.8,236.9z"
                    />
                  </g>
                  <g>
                    <path
                      d="M316.4,56.6c3.3,0.8,6.5,1.5,9,2c2.5,0.5,4.4,0.7,4.8-0.1c0.4-0.7-0.8-2.4-3.3-3.8c-2.5-1.5-6.3-2.6-10.1-2.5
						c-3.9,0.1-6.5,0.7-9.1,1.5c-2.5,0.8-5,1.6-8.5,3.4c3.6-1.2,6.2-1.4,8.7-1.5C310.5,55.6,313,55.9,316.4,56.6z"
                    />
                  </g>
                  <g>
                    <path
                      d="M166.4,149.7c1.4,3.4,3.9,6.3,6.1,7.9c2.2,1.7,4.1,2.1,4.7,1.5c0.6-0.7-0.3-2.3-1.7-4.4c-1.4-2.1-3.3-4.6-5.3-7.2
						c-2.1-2.7-3.3-4.7-4.4-6.9c-1.1-2.2-2-4.5-2.6-8.1c0.1,3.7,0.4,6.2,0.8,8.7C164.4,143.7,165,146.2,166.4,149.7z"
                    />
                  </g>
                  <g>
                    <path
                      d="M79.1,161.1c1.1-2.5,1.1-5.4,0.4-7.4c-0.7-2-1.8-3-2.6-2.8c-0.9,0.3-1.3,1.6-1.5,3.2c-0.3,1.7-0.4,3.7-0.5,5.9
						c-0.1,2.3-0.4,3.9-0.9,5.5c-0.5,1.6-1.3,3.2-3.1,5.1c2.1-1.5,3.4-2.8,4.7-4.2C76.7,165.2,77.9,163.7,79.1,161.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M340.8,260.3c3.5-6.8,4.5-14.6,2.8-15.1c-1.7-0.5-5.1,6.3-6.9,13.5c-0.9,3.8-1.5,6.4-2.2,9c-0.7,2.6-1.5,5.2-2.9,9
						c1.9-3.5,3.3-5.9,4.7-8.3C337.6,266.1,339,263.8,340.8,260.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M485,235.3c3.3,0,6.5-0.3,9-0.8c2.4-0.5,4.1-1.2,4.2-2.1c0-0.9-1.7-1.7-4.2-2.1c-2.6-0.4-6.1-0.3-9.4,0.6
						c-3.4,0.9-5.7,1.8-8,2.7c-2.4,1-4.7,1.9-7.8,3.1c3.5-0.8,5.9-1.1,8.3-1.3C479.3,235.3,481.6,235.3,485,235.3z"
                    />
                  </g>
                  <g>
                    <path
                      d="M596.5,141.5c-2.7,0.3-5.3,1.3-7,2.5c-1.7,1.2-2.6,2.4-2.2,3.2c0.4,0.8,1.9,0.9,3.8,0.6c1.9-0.3,4.3-1,6.5-2.1
						c2.4-1.1,4-1.9,5.8-2.5c1.7-0.7,3.5-1.2,6.3-1.7c-2.6-0.1-4.5-0.1-6.6-0.2C601.2,141.3,599.3,141.2,596.5,141.5z"
                    />
                  </g>
                  <g>
                    <path
                      d="M548.5,79c-2.4,1.8-4.6,3.6-6.2,5.2c-1.6,1.6-2.6,2.9-2.2,3.7c0.4,0.8,2.2,0.8,4.5-0.2c2.3-1,5.1-3,7.1-5.6
						c2-2.7,3.1-4.8,4-6.9c0.9-2.1,1.8-4.2,2.9-7.4c-1.5,3-3,4.7-4.5,6.3C552.6,75.7,550.9,77.1,548.5,79z"
                    />
                  </g>
                  <g>
                    <path
                      d="M482.8,101.2c3.5-0.6,7-1.5,9.5-2.4c2.6-0.9,4.3-1.9,4.2-2.8c-0.1-0.9-2.1-1.4-4.9-1.3c-2.8,0.1-6.6,0.8-10,2.3
						c-3.6,1.5-5.9,2.8-8.2,4.1c-2.3,1.3-4.6,2.7-7.9,4.7c3.6-1.5,6.1-2.3,8.6-2.9C476.6,102.3,479.2,101.9,482.8,101.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M503.3,526.1c2.5-1.7,3.3-4.9,2-6c-1.4-1.1-4.3,0.4-5.4,3.3c-1.2,2.9-1.3,4.3-2.8,7.1
						C499.4,528.3,500.7,527.9,503.3,526.1z"
                    />
                  </g>
                  <g>
                    <path
                      d="M281.3,518.4c2,0.3,3.8,0.4,5.3,0.2c1.5-0.1,2.7-0.5,3-1.3c0.3-0.8-0.5-2-2.2-2.8c-1.6-0.8-4.1-1.2-6.5-0.5
						c-2.4,0.7-3.9,1.5-5.3,2.3c-1.4,0.9-2.8,1.8-4.6,3.2c2.1-1,3.6-1.3,5.1-1.4C277.8,518,279.3,518.1,281.3,518.4z"
                    />
                  </g>
                </g>
              </g>
              <g>
                <defs>
                  <path
                    id="SVGID_00000053542145060144774250000009758852655134998912_"
                    d="M346.1,138.5c1.8,41.6-16.7,220.4-48.9,221.8
					c-32.2,1.4-66-175.2-67.8-216.8c-1.8-41.6,25.9-106,53.8-107.2C311.2,35.1,344.3,96.9,346.1,138.5z"
                  />
                </defs>
                <clipPath id="SVGID_00000031175439380519879880000014868514133892269744_">
                  <use
                    xlinkHref="#SVGID_00000053542145060144774250000009758852655134998912_"
                    overflow="visible"
                  />
                </clipPath>
              </g>
            </g>
          </g>
          <g id="Bolt">
            <image
              overflow="visible"
              opacity="0.7"
              width="1184"
              height="1184"
              xlinkHref={Shadow2}
              transform="matrix(0.24 0 0 0.24 155.6693 155.6164)"
            ></image>
            <g>
              <g>
                <path
                  fill="#FFB6E7"
                  d="M266.2,382.8c-47-17.4-71.1-69.6-53.7-116.6c17.4-47,69.6-71.1,116.7-53.7c47,17.4,71.1,69.6,53.7,116.7
				C365.4,376.2,313.2,400.2,266.2,382.8z"
                />
                <g opacity="0.5">
                  <g>
                    <g>
                      <polygon
                        fill="#773577"
                        points="318.7,297.6 340.9,319.8 319.8,340.9 297.7,318.7 275.5,340.9 254.4,319.8 276.6,297.6 
							254.4,275.5 275.5,254.4 297.7,276.6 319.8,254.4 340.9,275.5 						"
                      />
                    </g>
                  </g>
                  <defs>
                    <filter
                      id="Adobe_OpacityMaskFilter"
                      filterUnits="userSpaceOnUse"
                      x="254.4"
                      y="254.4"
                      width="86.5"
                      height="86.5"
                    >
                      <feColorMatrix
                        type="matrix"
                        values="-1 0 0 0 1  0 -1 0 0 1  0 0 -1 0 1  0 0 0 1 0"
                        colorInterpolationFilters="sRGB"
                        result="source"
                      />
                    </filter>
                  </defs>

                  <mask
                    maskUnits="userSpaceOnUse"
                    x="254.4"
                    y="254.4"
                    width="86.5"
                    height="86.5"
                    id="SVGID_00000103223749707774062040000006840795564490313915_"
                  >
                    <g filter="url(#Adobe_OpacityMaskFilter)">
                      <image
                        overflow="visible"
                        width="378"
                        height="378"
                        xlinkHref={Shadow1}
                        transform="matrix(0.24 0 0 0.24 252.2929 252.24)"
                      ></image>
                    </g>
                  </mask>
                  <g
                    opacity="0.4"
                    mask="url(#SVGID_00000103223749707774062040000006840795564490313915_)"
                  >
                    <g>
                      <polygon
                        points="318.7,297.6 340.9,319.8 319.8,340.9 297.7,318.7 275.5,340.9 254.4,319.8 276.6,297.6 254.4,275.5 
							275.5,254.4 297.7,276.6 319.8,254.4 340.9,275.5 						"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
        {/* <div ></div> */}
      </div>
    </>
  );
}
